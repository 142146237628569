import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import CableIcon from "@icons/24/Cabling.svg?react";
import useTextInput from "hooks/useTextInput";
import { useState } from "react";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../../style";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import { organisationExportCableResourceState } from "components/Organisation/Library/state";
import { TableRowsPlaceholder } from "../../shared/TablePlaceholder";
import { CableType } from "services/cableTypeService";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { lmap } from "utils/jotai";

export default function ExportCableModal({
  existingCables,
  onSave,
  isSaving,
}: {
  existingCables: string[];
  onSave: (cables: CableType[]) => void;
  isSaving?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const exportCableInOrgLoadable = useAtomValue(
    loadable(
      organisationExportCableResourceState({
        organisationId,
      }),
    ),
  );
  const [name, onNameChange] = useTextInput("");
  const [selectedExportCable, setSelectedExportCable] = useState<CableType[]>(
    [],
  );

  const filteredExportCableLoadable = lmap(
    exportCableInOrgLoadable,
    (cableInOrg) => {
      const fuse = new Fuse(cableInOrg, {
        keys: ["name"],
        includeScore: true,
        threshold: 0.3,
      });

      const results = fuse.search(name).map((result) => result.item);
      return name.length > 0 ? results : cableInOrg;
    },
  );

  const toggleCableSelection = (cable: CableType) => {
    setSelectedExportCable((prevSelectedCable) => {
      if (prevSelectedCable.find((t) => t.id === cable.id)) {
        return prevSelectedCable.filter((g) => g.id !== cable.id);
      } else {
        return [...prevSelectedCable, cable];
      }
    });
  };
  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "22rem",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {exportCableInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredExportCableLoadable.state === "loading" ? (
            <TableRowsPlaceholder />
          ) : filteredExportCableLoadable.state === "hasError" ? null : (
            filteredExportCableLoadable.data.map((g: CableType) => {
              const selected = selectedExportCable.some((sc) => sc.id === g.id);
              const alreadyExist = existingCables.some((id) => id === g.id);
              return (
                <ContentTableRow
                  key={g.id}
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => !alreadyExist && toggleCableSelection(g)}
                  disabled={selected || alreadyExist}
                >
                  <SVGWrapper size={1.4}>
                    <CableIcon />
                  </SVGWrapper>
                  <TextEllipsis
                    style={{
                      margin: 0,
                      marginRight: "auto",
                    }}
                  >
                    {g.name}
                  </TextEllipsis>
                  {alreadyExist ? (
                    <SecondaryText
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      Already connected
                    </SecondaryText>
                  ) : selected ? (
                    <SecondaryText
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      Selected
                    </SecondaryText>
                  ) : (
                    <></>
                  )}
                  {!alreadyExist && !selected && (
                    <Button
                      buttonType="text"
                      text="Select"
                      icon={<Add />}
                      onClick={() => {}}
                      size="small"
                      style={{
                        padding: "0.2rem 1.2rem",
                      }}
                    />
                  )}
                </ContentTableRow>
              );
            })
          )}
        </ContentTableColumn>
      </ResultContainer>
      {selectedExportCable.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedExportCable.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name}
                    onDeselect={() => toggleCableSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedExportCable)}
              buttonType="primary"
              disabled={isSaving}
            />
          </Row>
        </>
      )}
      {isSaving && (
        <Row
          style={{
            padding: "0 1.2rem 1.2rem",
            alignItems: "center",
          }}
        >
          <SkeletonBlock
            style={{
              height: "2rem",
            }}
          />
        </Row>
      )}
    </SearchAndSelectContainer>
  );
}
