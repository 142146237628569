import styled from "styled-components";
import { colors } from "../../styles/colors";
import { borderRadiusMedium, spaceHuge, spaceLarge } from "../../styles/space";
import { typography } from "../../styles/typography";
import { Row } from "../General/Layout";

export const ProjectListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceHuge};

  overflow-y: auto;

  table {
    flex: 1;
    padding: ${spaceLarge} ${spaceHuge};
    thead {
      border-bottom: none;
    }
  }
`;

export const StarWrapper = styled.div<{ starred: boolean }>`
  display: flex;
  align-self: center;
  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      stroke: ${colors.iconDefault};
      fill: ${(p) => (p.starred ? colors.surfaceBrand : "transparent")};
    }
  }
`;

export const EmptyListMessageDiv = styled.div`
  margin: 0 auto;
  color: ${colors.primaryDisabled};
  ${typography.contentAndButtons};
`;

export const ProjectTitle = styled.div`
  ${typography.body};
  color: ${colors.textPrimary};
  &:hover {
    cursor: pointer;
    color: ${colors.blue600};
  }
`;

export const FolderTitle = styled.div`
  ${typography.body};
  color: ${colors.textPrimary};
  &:hover {
    cursor: pointer;
  }
`;

export const DotMenuWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  border-radius: ${borderRadiusMedium};
  ${(p) => p.isActive && `background: ${colors.surfaceSelectedLight};`}

  &:hover {
    cursor: pointer;
    background: ${colors.grey200};
    ${(p) => p.isActive && `background: ${colors.surfaceSelectedLight};`}
  }
`;

export const ProjectNameWrapper = styled(Row)<{ isOver?: boolean }>`
  align-items: center;
  border-radius: ${borderRadiusMedium};
  padding: 0 0.4rem;
  ${typography.body};
  ${(p) => p.isOver && `background: ${colors.hover};`}

  &:hover {
    ${ProjectTitle} {
      color: ${colors.blue600};
      cursor: pointer;
    }
  }
`;

export const FolderNameWrapper = styled(Row)<{ isOver?: boolean }>`
  align-items: center;
  ${(p) => p.isOver && `background: ${colors.hover};`}
  ${typography.body};
  color: ${colors.textPrimary};
  &:hover {
    cursor: pointer;
  }
`;
