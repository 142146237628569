import { getCableLoadsFamily } from "analysis/electrical";
import { getBranchId, getParkId, getTurbineCapacity } from "analysis/inputs";
import { amountAddId, amountId } from "./amountIds";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { atom } from "jotai";
import { DefaultMap } from "lib/DefaultMap";
import { CostType, isOperationsCost } from "services/costService";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { Amount, AmountUnit } from "types/financial";
import { atomFamily } from "utils/jotai";
import { dedup } from "utils/utils";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { vesselTypesFamily } from "state/jotai/vesselType";

export const substationAmountsSelectorFamily = atomFamily(
  (productionId: FinanceId) =>
    atom<Promise<Amount[]>>(async (get) => {
      const branchId = await get(getBranchId(productionId));
      const parkId = await get(getParkId(productionId));

      const substations = await get(
        substationsInParkWithTypeFamily({ parkId, branchId }),
      );
      const allSubstationTypesInPark = dedup(
        substations.map(([, typ]) => typ),
        (t) => t.type,
      );

      const onshoreSubstations = substations.filter(
        ([, typ]) => typ.type === "onshore",
      );
      const offshoreSubstations = substations.filter(
        ([, typ]) => typ.type === "offshore",
      );

      const subsOfTypeCount = new DefaultMap<string, number>(() => 0);
      for (const [, typ] of substations)
        subsOfTypeCount.update(typ.id, (c) => c + 1);

      const loadMap = await get(getCableLoadsFamily(productionId));
      const capacity = await get(getTurbineCapacity(productionId));

      const mwPerSubstation = substations.map(([sub]) => {
        const capacity = (loadMap.get(sub.id) ?? 0) / 1e6;

        return {
          unit: AmountUnit.MW,
          amount: capacity,
          category: CostType.Substation,
          id: amountId({
            unit: AmountUnit.MW,
            category: CostType.Substation,
            featureTypeId: sub.id,
          }),
        };
      });

      const unitTotal = amountAddId({
        unit: AmountUnit.unit,
        amount: substations.length,
        category: CostType.Substation,
      });

      const OSSTotal = amountAddId({
        unit: AmountUnit.OSS,
        amount: offshoreSubstations.length,
        category: CostType.Substation,
      });

      const ONSTotal = amountAddId({
        unit: AmountUnit.ONS,
        amount: onshoreSubstations.length,
        category: CostType.Substation,
      });

      const unitAmounts = allSubstationTypesInPark.map((st) => {
        const amount = subsOfTypeCount.get(st.id) ?? 0;
        const unit = AmountUnit.unit;
        const category = CostType.Substation;

        return {
          id: amountId({ unit, category, featureTypeId: st.id }),
          unit,
          amount,
          category,
        };
      });

      const perMwAmount = amountAddId({
        unit: AmountUnit.MW,
        amount: substations.length > 0 ? capacity ?? 0 : 0,
        category: CostType.Substation,
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.Substation,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.Substation,
      };

      let perDayAmount: Amount[] = [];

      const configuration = await get(getConfiguration(productionId));

      if (isOperationsCost(configuration.capex.installation.substation)) {
        const vesselTypes = await get(vesselTypesFamily(undefined));

        const installationTime =
          offshoreSubstations.length > 0
            ? await get(
                getTotalInstallationTime({
                  id: productionId,
                  type: InstallationType.Substation,
                }),
              )
            : 0;
        const operationsConfiguration = await get(
          getOperationsConfiguration(productionId),
        );

        const { installationVessel, feederVessel } =
          operationsConfiguration.ti.substation;
        perDayAmount = [installationVessel, feederVessel].map(
          ({ vesselId }) => {
            const mobilizationTime =
              vesselTypes.get(vesselId)?.mobilizationTime ?? 0;

            return {
              id: amountId({
                unit: AmountUnit.day,
                category: CostType.Substation,
                featureTypeId: vesselId,
              }),
              unit: AmountUnit.day,
              amount:
                offshoreSubstations.length > 0
                  ? (installationTime ?? 0) + mobilizationTime
                  : 0,
              category: CostType.Substation,
            };
          },
        );
      }

      return [
        ...mwPerSubstation,
        ...unitAmounts,
        unitTotal,
        OSSTotal,
        ONSTotal,
        perMwAmount,
        fixedAmount,
        ...perDayAmount,
      ];
    }),
);
