import { atomFamily } from "utils/jotai";
import { featuresFamily, featuresInParkFamily } from "./features";
import { atom } from "jotai";
import { AnchorFeature } from "types/feature";
import { parkFamily } from "./park";
import { pointInPolygon } from "utils/geometry";

export const anchorsFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<AnchorFeature[]>>(async (get) => {
      const features = await get(featuresFamily({ branchId }));
      return features.anchor;
    }),
);

export const anchorsInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<AnchorFeature[]>>(
      async (get) =>
        (await get(featuresInParkFamily({ parkId, branchId }))).anchor,
    ),
);

export const anchorsOutsideBoundsFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<AnchorFeature[]>>(async (get) => {
      const anchors = await get(anchorsInParkFamily({ parkId, branchId }));
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return [];
      return anchors.filter((t) => !pointInPolygon(t.geometry, park.geometry));
    }),
);
