import { ReactNode } from "react";
import styled from "styled-components";
import Close from "@icons/24/Close.svg?react";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import Tooltip from "./Tooltip";

const _Tag = styled.div<{
  color?: string;
  background?: string;
  backgroundHover?: string;
  interactive?: boolean;
  dark?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;

  color: ${(p) => (p.dark ? colors.textNegative : p.color ?? colors.textBrand)};
  background: ${(p) =>
    p.dark ? colors.blue700 : p.background ?? colors.surfaceInfo};

  border-radius: 4px;
  height: 2rem;
  padding: 0 0.8rem;

  ${(p) =>
    p.interactive &&
    `:hover {
    background: ${p.backgroundHover};
    cursor: pointer;
  }`}

  svg {
    overflow: visible;
    color: ${(p) => p.color ?? colors.textBrand};
  }

  svg:first-child {
    height: 1.2rem;
    width: 1.2rem;
    max-height: 1.2rem;
    max-width: 1.2rem;
    path[stroke] {
      stroke: ${(p) => p.color ?? colors.textBrand};
    }
    path:not([stroke]) {
      fill: ${(p) => p.color ?? colors.textBrand};
    }
  }

  span {
    ${typography.graphics};
    white-space: nowrap;
    text-transform: none; // Do not capitalize
  }

  svg:last-child {
    height: 0.8rem;
    width: 0.8rem;
    max-height: 0.8rem;
    max-width: 0.8rem;
    path {
      stroke: ${(p) => p.color ?? colors.textBrand};
    }
  }
`;

const style2colors = {
  subtle: {
    color: colors.textBrand,
    background: colors.surfaceSecondary,
    backgroundHover: undefined,
  },
  info: {
    color: colors.textBrand,
    background: colors.surfaceInfo,
    backgroundHover: undefined,
  },
  success: {
    color: colors.textSuccess,
    background: colors.surfaceSuccess,
    backgroundHover: undefined,
  },
  warning: {
    color: colors.textWarning,
    background: colors.surfaceWarning,
    backgroundHover: undefined,
  },
  error: {
    color: colors.textError,
    background: colors.surfaceError,
    backgroundHover: undefined,
  },
  indigo: {
    color: colors.textNegative,
    background: colors.indigo600,
    backgroundHover: colors.indigo700,
  },
  magenta: {
    color: colors.textNegative,
    background: colors.magenta700,
    backgroundHover: colors.magenta800,
  },
  seagreen: {
    color: colors.textNegative,
    background: colors.seagreen600,
    backgroundHover: colors.seagreen700,
  },
  fuschia: {
    color: colors.textNegative,
    background: colors.fuschia700,
    backgroundHover: colors.fuschia800,
  },
} as const;
type TagStyle = keyof typeof style2colors;

export const Tag = ({
  id,
  text,
  icon,
  cross,
  color,
  background,
  backgroundHover,
  style,
  onClick,
  tooltip,
  dark,
  maxWidth,
}: {
  id?: string;
  text: string | number;
  icon?: ReactNode;
  /** Add an X on the right side of the tag. */
  cross?: boolean;
  /** Text color */
  color?: string;
  /** Background color. */
  background?: string;
  /** Background color on hover. */
  backgroundHover?: string;
  style?: TagStyle;
  onClick?: React.ComponentProps<"div">["onClick"];
  tooltip?: string;
  dark?: boolean;
  maxWidth?: string;
}) => {
  if (style) {
    if (!color) color = style2colors[style].color;
    if (!background) background = style2colors[style].background;
    if (!backgroundHover) backgroundHover = style2colors[style].backgroundHover;
  }

  const tag = (
    <_Tag
      dark={dark}
      id={id}
      color={color}
      background={background}
      onClick={onClick}
      backgroundHover={backgroundHover}
      interactive={Boolean(onClick || cross)}
    >
      {icon}
      <span
        style={{
          maxWidth: maxWidth,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
      {cross && <Close />}
    </_Tag>
  );

  if (tooltip) return <Tooltip text={tooltip}>{tag}</Tooltip>;
  return tag;
};
