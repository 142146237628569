import React, { useMemo, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { Mixpanel, TrackingFeature, TrackingCapability } from "mixpanel";
import * as turf from "@turf/turf";
import RemoveIcon from "@icons/24/Remove.svg?react";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { MappedPortfolioItem } from "services/portfolioAPIService";
import { DashboardModalType } from "state/modal";
import Button from "components/General/Button";
import { getCenter } from "utils/turf";
import StaticMapboxProjectImage from "components/StaticMapboxImage/StaticMapboxProjectImage";
import { ButtonRow, ParkContent, PopupWrapper } from "./style";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import usePortfolioService from "hooks/usePortfolioService";
import { adminInOrganisationSelectorFamily } from "state/user";
import { Popup } from "components/Mapbox/Popup";
import { portfolioMapPopupBoundaryRefAtom } from "state/portfolio";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import { useAtomValue } from "jotai";
import { projectNodesInOrganisation } from "components/Projects/useOrganisationFolderCrud";

const ParkAttributeRow = ({
  attribute,
  value,
}: {
  attribute: React.ReactNode;
  value: React.ReactNode;
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "flex-end",
        width: "100%",
      }}
    >
      <p
        style={{
          flexGrow: 1,
          flexShrink: 0,
          ...typography.contentAndButtons,
        }}
      >
        {attribute}:
      </p>
      <p
        style={{
          flexGrow: 1,
          flexShrink: 0,
          textAlign: "end",
          ...typography.contentAndButtons,
        }}
      >
        {value}
      </p>
    </div>
  );
};

const OFFSET_PX: [number, number] = [20, -10];
const ClickedParkPopup = React.forwardRef<
  HTMLDivElement,
  {
    map?: mapboxgl.Map;
    portfolioItem: MappedPortfolioItem;
    organisationId: string;
    setShowAccessNeededModal: (show: boolean) => void;
  }
>(({ portfolioItem, organisationId, map, setShowAccessNeededModal }, _ref) => {
  const [searchParams] = useSearchParams();
  const popupContainer = useRef<HTMLDivElement>(null);
  const { remove: removeFromPortfolio } = usePortfolioService();
  const isOrgAdmin = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const projectNodes = useAtomValue(
    projectNodesInOrganisation({
      organisationId,
    }),
  );
  const hasAccessToProject = projectNodes.some(
    (node) => node.id === portfolioItem.project.id,
  );
  const portfolioMapWrapperRef = useAtomValue(portfolioMapPopupBoundaryRefAtom);

  const parkUrl = `/design/project/${organisationId}/${portfolioItem.project!.id}/${portfolioItem.branchId}/${portfolioItem.park.id}`;

  const goToDashboardSearchParams = new URLSearchParams(searchParams);
  goToDashboardSearchParams.delete("tab");
  goToDashboardSearchParams.set("id", "vind-preset-dashboard-overview");
  goToDashboardSearchParams.set("modal", DashboardModalType);
  goToDashboardSearchParams.set("parkId", portfolioItem.park.id);
  goToDashboardSearchParams.set("branchid", portfolioItem.branchId);

  const goToProjectSearchParams = new URLSearchParams(searchParams);
  goToProjectSearchParams.delete("tab");

  const latLng = useMemo(
    () => getCenter(portfolioItem.park),
    [portfolioItem.park],
  );

  return (
    <Popup
      map={map!}
      pos={latLng}
      mapRef={portfolioMapWrapperRef}
      place="topLeft"
      offsetPx={OFFSET_PX}
    >
      <PopupWrapper ref={popupContainer}>
        <DontRenderWhenCheckly>
          <StaticMapboxProjectImage
            project={portfolioItem.project}
            branchId={portfolioItem.branchId}
            parkId={portfolioItem.park.id}
            admin
            style={{
              height: "18rem",
              width: "18rem",
              borderRadius: "4px",
            }}
          />
        </DontRenderWhenCheckly>
        <ParkContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <h3
                style={{
                  ...typography.h3,
                  marginTop: 0,
                }}
              >
                {portfolioItem.park.properties.name}
              </h3>
              {isOrgAdmin && (
                <DotMenu
                  buttonStyle={{
                    minWidth: "unset",
                    height: "unset",
                  }}
                >
                  <MenuItem
                    name={"Remove from portfolio"}
                    onClick={() => {
                      removeFromPortfolio(
                        portfolioItem.park.id,
                        portfolioItem.project.id,
                        portfolioItem.branchId,
                      );
                      Mixpanel.track(
                        TrackingCapability.Portfolio,
                        TrackingFeature.Portfolio,
                        "remove portfolio park",
                        {},
                      );
                    }}
                    icon={<RemoveIcon />}
                    direction="right"
                  />
                </DotMenu>
              )}
            </div>
            <p
              style={{
                ...typography.contentAndButtons,
                color: colors.secondaryText,
              }}
            >
              {portfolioItem.project.name} - {portfolioItem.branch.title}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                flexGrow: 1,
              }}
            >
              <ParkAttributeRow
                attribute="Total capacity"
                value={`${portfolioItem.totalPower / 1000} MW`}
              />

              <ParkAttributeRow
                attribute="Total area"
                value={`${(turf.area(portfolioItem.park) / 1000 / 1000).toFixed(1)} km²`}
              />
              <ParkAttributeRow
                attribute="Number of turbines"
                value={portfolioItem.nrTurbines}
              />
            </div>
          </div>
          <ButtonRow>
            {hasAccessToProject ? (
              <Link
                to={{
                  pathname: parkUrl,
                  search: goToProjectSearchParams.toString(),
                }}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  buttonType="primary"
                  text="Go to project"
                  style={{
                    flexGrow: 1,
                    flexShrink: 0,
                  }}
                />
              </Link>
            ) : (
              <Button
                buttonType="primary"
                text="Go to project"
                onClick={() => setShowAccessNeededModal(true)}
              />
            )}
          </ButtonRow>
        </ParkContent>
      </PopupWrapper>
    </Popup>
  );
});

export default ClickedParkPopup;
