import { aset, useJotaiCallback } from "utils/jotai";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  setPublicProjectAccess,
  setPublicProjectGreeting,
  setPublicProjectSetting,
} from "services/projectDataAPIService";
import {
  publicMetadataAtomFamily,
  publicProjectSettingsAtomFamily,
} from "state/projectAPI";
import { scream } from "utils/sentry";

export const projectElementsVisibleKey = "projectElementsVisible";
export const analysisVisibleKey = "analysisVisible";
export const startZoomAreaKey = "startZoomArea";
export const publicCollectionsKey = "public_collections";
const publicCollectionsToggledByDefaultKey =
  "public_collections_toggled_by_default";
export const publicCollectionsRenamedLayers =
  "public_collections_renamed_layers";

const usePublicModeSettingsCrud = () => {
  const { error } = useToast();
  const [loading, setLoading] = useState(false);
  const updatePublicCollectionSettings = useJotaiCallback(
    async (get, set, nodeId: string, newListOfCollectionIds: string[]) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      set(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          [publicCollectionsKey]: newListOfCollectionIds,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, {
          [publicCollectionsKey]: newListOfCollectionIds,
        });
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating public collection");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating public collection",
          });
        } else {
          scream(
            new Error("Something went wrong when updating public collection"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const updatePublicStartZoomSettings = useJotaiCallback(
    async (get, set, nodeId: string, startZoomArea: number[][]) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      set(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          [startZoomAreaKey]: startZoomArea,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, {
          [startZoomAreaKey]: startZoomArea,
        });
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating public starting zoom");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating public starting zoom",
          });
        } else {
          scream(
            new Error(
              "Something went wrong when updating public starting zoom",
            ),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const updatePublicDefaultToggledCollections = useJotaiCallback(
    async (get, set, nodeId: string, newListOfCollectionIds: string[]) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      set(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          [publicCollectionsToggledByDefaultKey]: newListOfCollectionIds,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, {
          [publicCollectionsToggledByDefaultKey]: newListOfCollectionIds,
        });
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating default toggled collections");
        if (e instanceof Error) {
          scream(e, {
            message:
              "Something went wrong when updating default toggled collections",
          });
        } else {
          scream(
            new Error(
              "Something went wrong when updating default toggled collections",
            ),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const updatePublicGreeting = useJotaiCallback(
    async (
      get,
      set,
      nodeId: string,
      greetingTitle: string,
      greetingText: string,
    ) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      set(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          greeting_title: greetingTitle,
          greeting_text: greetingText,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectGreeting(nodeId, greetingTitle, greetingText);
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating greeting text");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating greeting text",
          });
        } else {
          scream(
            new Error("Something went wrong when updating greeting text"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const togglePublicSetting = useJotaiCallback(
    async (get, set, nodeId: string, key: string) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      const newVal = !(
        (((fallback ?? {}) as any)[key] as boolean | undefined) ?? false
      );
      set(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          [key]: newVal,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, {
          [key]: newVal,
        });
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating greeting text");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating greeting text",
          });
        } else {
          scream(
            new Error("Something went wrong when updating greeting text"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const setPublicExternalLayerName = useJotaiCallback(
    async (get, set, nodeId: string, layerId: string, layerName: string) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      aset(
        get,
        set,
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        (curr) => ({
          ...curr,
          [publicCollectionsRenamedLayers]: {
            ...((curr ?? {})[publicCollectionsRenamedLayers] ?? {}),
            [layerId]: layerName,
          },
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, {
          ...fallback,
          [publicCollectionsRenamedLayers]: {
            ...((fallback ?? {})[publicCollectionsRenamedLayers] ?? {}),
            [layerId]: layerName,
          },
        });
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating external layer name");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating external layer name",
          });
        } else {
          scream(
            new Error("Something went wrong when updating external layer name"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const resetPublicExternalLayerName = useJotaiCallback(
    async (get, set, nodeId: string, layerId: string) => {
      const fallback = await get(
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
      );
      const updatedSetting = {
        ...fallback,
        [publicCollectionsRenamedLayers]: Object.keys(
          ((fallback ?? {}) as any)[publicCollectionsRenamedLayers],
        ).reduce(
          (acc, key) =>
            key === layerId
              ? acc
              : {
                  ...acc,
                  [key]: ((acc ?? {}) as any)[key],
                },
          {},
        ),
      };
      aset(
        get,
        set,
        publicProjectSettingsAtomFamily({
          projectId: nodeId,
        }),
        () => updatedSetting,
      );
      try {
        setLoading(true);
        await setPublicProjectSetting(nodeId, updatedSetting);
      } catch (e) {
        aset(
          get,
          set,
          publicProjectSettingsAtomFamily({
            projectId: nodeId,
          }),
          () => fallback,
        );
        error("Something went wrong when updating external layer name");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when updating external layer name",
          });
        } else {
          scream(
            new Error("Something went wrong when updating external layer name"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  const setProjectAsPublic = useJotaiCallback(
    async (get, set, nodeId: string, branchId: string, publicMode: boolean) => {
      const fallback = await get(
        publicMetadataAtomFamily({
          nodeId,
          branchId,
        }),
      );
      aset(
        get,
        set,
        publicMetadataAtomFamily({
          nodeId,
          branchId,
        }),
        (curr) => ({
          ...curr,
          is_public: publicMode,
        }),
      );
      try {
        setLoading(true);
        await setPublicProjectAccess(nodeId, branchId, publicMode);
      } catch (e) {
        aset(
          get,
          set,
          publicMetadataAtomFamily({
            nodeId,
            branchId,
          }),
          () => fallback,
        );
        error("Something went wrong when setting project as public");
        if (e instanceof Error) {
          scream(e, {
            message: "Something went wrong when setting project as public",
          });
        } else {
          scream(
            new Error("Something went wrong when setting project as public"),
            {
              error: e,
            },
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [error, setLoading],
  );

  return {
    updatePublicCollectionSettings,
    updatePublicStartZoomSettings,
    updatePublicDefaultToggledCollections,
    updatePublicGreeting,
    togglePublicSetting,
    setPublicExternalLayerName,
    resetPublicExternalLayerName,
    setProjectAsPublic,
    loading,
  };
};

export default usePublicModeSettingsCrud;
