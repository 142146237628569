import React from "react";
import UserImageRound from "../UserImage/UserImageRound";
import { Row } from "../General/Layout";
import { OtherUserMetaInfo } from "types/user";
import { Name } from "./styles";

export const UserInfoRender = ({
  user,
  size,
  nameStyle,
}: {
  user: OtherUserMetaInfo | undefined;
  size?: number;
  nameStyle?: React.CSSProperties;
}) =>
  user ? (
    <Row
      style={{
        alignItems: "center",
      }}
    >
      <UserImageRound size={size ?? 2.4} user={user} />
      <Name style={nameStyle}>{user.nickname}</Name>
    </Row>
  ) : null;
