import * as turf from "@turf/turf";
import { calculateEllipseY, pointInPolygon } from "../../utils/geometry";
import { Units, Polygon, Feature, MultiPolygon, Point } from "@turf/turf";
import { RegularParameters } from "../../types/turbines";
import { fastMin } from "../../utils/utils";
import {
  isMultiPolygonFeature,
  isPolygonFeature,
} from "../../utils/predicates";
import { sendInfo } from "../../utils/sentry";

const EARTH_RADIUS = 6378100;

const multiPolygonToPolygonList = (
  f: Feature<Polygon | MultiPolygon>,
): undefined | Feature<Polygon>[] => {
  if (isPolygonFeature(f)) return [f];
  else if (isMultiPolygonFeature(f))
    return f.geometry.coordinates.map((coords) => turf.polygon(coords));
  sendInfo("multiPolygonToPolygonList was passed an illegal feature", { f });
  return;
};

export function edgeGeneration(
  inputPolygon: MultiPolygon | Polygon,
  diameter: number,
  layoutSettings: RegularParameters,
  pointIsOutsideExclusionZones: (p: Point) => boolean,
) {
  const spacing =
    0.5 * (layoutSettings.minorAxisSpacing + layoutSettings.majorAxisSpacing);
  const edgeSpacing = layoutSettings.edgeSpacing;
  const buffer = 0.5 * diameter + 10;
  const polygons =
    inputPolygon.type === "Polygon"
      ? [inputPolygon.coordinates]
      : inputPolygon.coordinates;
  return polygons.flatMap((polygonCoords) => {
    const polygon = turf.polygon(polygonCoords);
    const boundaryBufferedPolygon = turf.buffer(polygon, -buffer, {
      units: "meters",
    }) as Feature<Polygon | MultiPolygon>;

    // NOTE: The return type for `turf.buffer` is wrong in v6.5.0, because the buffer
    // operation can return a MultiPolygon. See https://github.com/Turfjs/turf/pull/2188
    // To work around this, we cast to a more appropriate type manually.
    const distanceToBoundary = Math.min(spacing, edgeSpacing);
    let innerTurbines = regularGeneration(
      polygon.geometry,
      diameter,
      layoutSettings,
      pointIsOutsideExclusionZones,
    ).filter(
      (p) =>
        distanceToBoundary <
        fastMin(
          polygon.geometry.coordinates.map((ring) =>
            turf.pointToLineDistance(p, turf.lineString(ring), {
              units: "meters",
            }),
          ),
        ),
    );

    const coords = multiPolygonToPolygonList(boundaryBufferedPolygon)?.map(
      (f) => f.geometry.coordinates[0],
    ) as [number, number][][];
    const outerTurbines = coords.flatMap((coord) =>
      edgeTurbines(
        coord.concat([coord[0]]),
        edgeSpacing,
        pointIsOutsideExclusionZones,
      ),
    );

    return [...innerTurbines, ...outerTurbines];
  });
}

function edgeTurbines(
  parkPolygon: [number, number][],
  spacing: number,
  pointIsOutsideExclusionZones: (p: Point) => boolean,
): [number, number][] {
  const step = 0.02 * spacing;
  const options = { units: "meters" as Units };
  const lineString = turf.lineString(parkPolygon);
  const length = turf.length(lineString, options);
  let f = 0;
  while (
    !pointIsOutsideExclusionZones(turf.along(lineString, f, options).geometry)
  )
    f += step;
  const firstPoint = turf.along(lineString, f, options);
  let points: number[][] = [firstPoint.geometry.coordinates];
  let lastPoint = firstPoint;
  while (f < length) {
    f += step;
    const p = turf.along(lineString, f, options);
    if (!pointIsOutsideExclusionZones(p.geometry)) continue;
    const distanceLastPoint = turf.distance(p, lastPoint, options);
    if (distanceLastPoint < spacing) continue;
    const distanceFirstPoint = turf.distance(p, firstPoint, options);
    if (distanceFirstPoint < spacing) continue;
    points.push(p.geometry.coordinates);
    lastPoint = p;
  }
  return points as [number, number][];
}

export const meterToCoords = (meter: number) =>
  (meter / EARTH_RADIUS) * (180 / Math.PI);

export const xDistAtLat = (lat: number, dist: number) =>
  dist / Math.cos((lat * Math.PI) / 180);

export function regularGeneration(
  inputPolygon: MultiPolygon | Polygon,
  diameter: number,
  layoutSettings: RegularParameters,
  pointIsOutsideExclusionZones: (p: Point) => boolean,
): [number, number][] {
  const multiPolygons =
    inputPolygon.type === "Polygon"
      ? [inputPolygon.coordinates]
      : inputPolygon.coordinates;
  const buffer = 0.5 * diameter + 10;
  return multiPolygons.flatMap((polygonCoords) => {
    const polygon = turf.polygon(polygonCoords);
    const boundaryBufferedPolygonMaybe = turf.buffer(polygon, -buffer, {
      units: "meters",
    }) as Feature<Polygon | MultiPolygon> | undefined | null;
    if (!boundaryBufferedPolygonMaybe) return [];
    const polygons = multiPolygonToPolygonList(boundaryBufferedPolygonMaybe);
    if (!polygons) return [];

    const obliquity = layoutSettings.obliquity;
    const shift =
      Math.tan((obliquity * Math.PI) / 180) * layoutSettings.minorAxisSpacing;
    const shiftX = layoutSettings.shiftX;
    const shiftY = layoutSettings.shiftY;
    const minorAxisSpacing = layoutSettings.minorAxisSpacing;
    const majorAxisSpacing = layoutSettings.majorAxisSpacing;

    const rotationDeg = layoutSettings.rotate;
    const centerOfMass = turf.centerOfMass(polygon);

    const distanceMinorAxis = calculateEllipseY(
      shift,
      majorAxisSpacing,
      minorAxisSpacing,
    );

    const latitudeStep = meterToCoords(majorAxisSpacing);
    const _longitudeStep = meterToCoords(distanceMinorAxis);

    const getLongitudeStep = (latitude: number) => {
      return _longitudeStep / Math.cos((latitude * Math.PI) / 180);
    };
    const [centerLon, centerLat] = centerOfMass.geometry.coordinates;
    const shiftStep = meterToCoords(shift);

    const shiftedCenterLon =
      centerLon + meterToCoords(xDistAtLat(centerLat, shiftX));
    const shiftedCenterLat = centerLat + meterToCoords(shiftY);

    const rotateOpts = { pivot: [shiftedCenterLon, shiftedCenterLat] };

    return polygons.flatMap((boundaryBufferedPolygon) => {
      const rotatedPolygon = turf.transformRotate(
        boundaryBufferedPolygon.geometry,
        -rotationDeg,
        rotateOpts,
      );

      const latitudes = rotatedPolygon.coordinates[0].map((v) => v[1]);
      const longitudes = rotatedPolygon.coordinates[0].map((v) => v[0]);

      const minLat = Math.min.apply(Math, latitudes);
      const maxLat = Math.max.apply(Math, latitudes);
      const minLon = Math.min.apply(Math, longitudes);
      const maxLon = Math.max.apply(Math, longitudes);

      const startLonI = Math.floor(
        (minLon - shiftedCenterLon) / getLongitudeStep(centerLat),
      );
      const stopLonI = Math.floor(
        (maxLon - shiftedCenterLon) / getLongitudeStep(centerLat),
      );

      const getStartLatI = (lonI: number) =>
        Math.floor(
          (minLat - shiftedCenterLat + lonI * shiftStep) / latitudeStep,
        );
      const getStopLatI = (lonI: number) =>
        Math.floor(
          (maxLat - shiftedCenterLat + lonI * shiftStep) / latitudeStep,
        );

      let turbines: [number, number][] = [];
      for (var lonI = startLonI; lonI <= stopLonI; lonI++) {
        const lon = shiftedCenterLon + lonI * getLongitudeStep(centerLat);
        const startLatI = getStartLatI(lonI);
        const stopLatI = getStopLatI(lonI);
        for (var latI = startLatI; latI <= stopLatI; latI++) {
          const latStep = latitudeStep;
          const lat = shiftedCenterLat + latI * latStep - lonI * shiftStep;
          const pt = turf.point([lon, lat]);
          if (pointInPolygon(pt.geometry, rotatedPolygon, 0)) {
            const rotatedPt = turf.transformRotate(pt, rotationDeg, rotateOpts);
            if (pointIsOutsideExclusionZones(rotatedPt.geometry))
              turbines.push([lon, lat]);
          }
        }
      }

      const rotatedTurbines = turf.transformRotate(
        turf.multiPoint(turbines),
        rotationDeg,
        rotateOpts,
      );
      return rotatedTurbines.geometry.coordinates as [number, number][];
    });
  });
}
