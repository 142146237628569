import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg?react";
import { IconREMSize, typography } from "../../../../styles/typography";
import { Layer, LayerType, PrivateDataSource } from "../../../../types/layers";
import { borderRadiusMedium, spaceSmall } from "../../../../styles/space";
import { TranslatedLayerName } from "../../../LayerList/LayerInformation/LayerInformation";
import { LayerIconMapper } from "../../../LayerList/utils";
import { SourceNameComponent } from "../../../LayerList/LayerListPane/ProjectLayerItem";
import { colors } from "../../../../styles/colors";
import {
  projectImageHeight,
  projectImageWidth,
} from "components/StaticMapboxImage/StaticMapboxProjectImage";
import CheckCircle from "@icons/14/CheckCircle.svg?react";
import { useDataLayerCellProps } from "hooks/useDataLayerCellProps";
import { Row } from "components/General/Layout";
import InfoIcon from "@icons/24/Information.svg?react";
import BinIcon from "@icons/24/Bin.svg?react";
import LazyLayerPreviewImage from "../LazyLayerPreviewImage";
import { useState } from "react";
import Tooltip, { WithTooltip } from "components/General/Tooltip";
import StarIcon from "@icons/24/Star.svg?react";
import { getSuggestedLayersSelector, useFavoriteLayers } from "state/layer";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import { useAtomValue } from "jotai";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { getLayerTypeTooltipText } from "utils/layer";

const OpacityLayer = styled.div<{
  isAdded: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.surfaceSelectedLight};
  opacity: 0.6;
  display: none;
  z-index: 2;

  ${({ isAdded }) =>
    isAdded &&
    `
    opacity: 0.3;
    display: block;
  `}
`;

const StarIconWrapper = styled.div<{ active: boolean }>`
  &:hover {
    svg {
      path {
        ${(p) => p.active && `stroke: none;`}
      }
    }
  }
  display: flex;
  z-index: 3;  
 
  opacity: ${(p) => (p.active ? "1 !important" : "inherit")};
  svg {
    width: 1.4rem;
    height: 1.4rem;
    path {
      stroke: ${(p) => (p.active ? colors.surfaceBrand : colors.surfaceBrand)};
      fill: ${(p) => (p.active ? colors.surfaceBrand : "transparent")};
    }
  }
}
`;

const IconWithBackground = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.surfacePrimary};
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: calc(50% + 1rem);
  transform: translate(-50%, -50%);

  svg path[stroke] {
    stroke: ${colors.white};
  }
  svg path:not([stroke]) {
    fill: ${colors.white};
  }
`;

const IconOverlay = styled(IconWithBackground)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Card = styled.div<{
  isSelected: boolean;
}>`
  position: relative;
  width: 30rem;
  height: 24rem;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.surfaceSelectedLight : colors.surfaceSecondary};
  border-radius: ${borderRadiusMedium};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${colors.surfaceHover};
  }
`;

const SuggestedLayerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: 100%;
  width: 100%;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  height: ${projectImageHeight};
  width: ${projectImageWidth};
  > img {
    border-radius: 6px;
    box-sizing: border-box;
    border: 2px solid ${colors.borderSubtle};
    border-radius: 0.6rem;
    object-fit: cover;
    object-position: center;
    margin: 0.8rem;
  }
  &:hover > img {
    opacity: 0.5;
  }
`;

const LayerInfo = styled.div`
  padding: 1rem 0.8rem;
`;

const LayerInfoHeader = styled.div`
  ${typography.sub1}
  color: ${colors.textPrimary};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LayerInfoSource = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  padding-top: 0.2rem;
`;

const LayerIconSection = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: ${spaceSmall};
  z-index: 3;
`;

const LayerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: ${spaceSmall};
  gap: ${spaceSmall};
`;

const HeaderAndInfoIconWrapper = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const ProjectIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.4rem;
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    padding: 0.5rem;
    path {
      stroke: ${colors.iconDefault};
    }
  }

  background: ${colors.grey200};
`;

interface GridCardProps {
  layer: Layer;
  setSelectedLayer: (layer: Layer | null) => void;
  handleLayerSelection: (layerId: string) => void;
  selectedLayersToAdd: string[];
  handleToggleFavorite?: (layerId: string) => void;
}

const GridCard = ({
  layer,
  setSelectedLayer,
  handleLayerSelection,
  selectedLayersToAdd,
  handleToggleFavorite,
}: GridCardProps) => {
  const cellProps = useDataLayerCellProps();
  const { showConfirm } = useConfirm();
  const [hoveredLayerId, setHoveredLayerId] = useState<string | null>(null);
  const suggestedLayersIds = useAtomValue(getSuggestedLayersSelector).map(
    (layer) => layer.id,
  );
  const isSuggested = suggestedLayersIds.includes(layer.id);

  const { isFavorite } = useFavoriteLayers();

  return (
    <Card
      onMouseEnter={() => setHoveredLayerId(layer.id)}
      onMouseLeave={() => setHoveredLayerId(null)}
      isSelected={selectedLayersToAdd.includes(layer.id)}
      onClick={() => setSelectedLayer(layer)}
    >
      <SuggestedLayerContent>
        <ImageContainer>
          <LazyLayerPreviewImage
            layer={layer}
            clickToEnlarge={false}
            width={projectImageWidth}
            height={projectImageHeight}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              margin: "0.8rem",
            }}
          />
          {selectedLayersToAdd?.includes(layer.id) ||
          cellProps.getIsAdded(layer.id) ? (
            <Tooltip
              text="Layer already added"
              disabled={!cellProps.getIsAdded(layer.id)}
            >
              <IconOverlay
                backgroundColor={colors.surfaceSelectedDark}
                onClick={(e) => {
                  e.stopPropagation();
                  !cellProps.getIsAdded(layer.id) &&
                    handleLayerSelection(layer.id);
                }}
              >
                <IconREMSize height={1.4} width={1.4}>
                  <CheckCircle />
                </IconREMSize>
              </IconOverlay>
            </Tooltip>
          ) : (
            hoveredLayerId === layer.id && (
              <IconOverlay
                backgroundColor={colors.surfaceButtonPrimary}
                onClick={(e) => {
                  e.stopPropagation();
                  handleLayerSelection(layer.id);
                }}
              >
                <IconREMSize height={1.4} width={1.4}>
                  <AddIcon />
                </IconREMSize>
              </IconOverlay>
            )
          )}
          <LayerIconSection>
            <WithTooltip
              text={getLayerTypeTooltipText(layer.type as LayerType)}
            >
              <LayerIconWrapper>
                <IconREMSize height={1.2} width={1.2}>
                  <LayerIconMapper layer={layer} />
                </IconREMSize>
                <span>{layer.sourceType}</span>
              </LayerIconWrapper>
            </WithTooltip>
            {isSuggested && (
              <WithTooltip text="Vind suggested layer">
                <LayerIconWrapper>
                  <IconREMSize height={1.2} width={1.2}>
                    <VindLogo />
                  </IconREMSize>
                </LayerIconWrapper>
              </WithTooltip>
            )}
          </LayerIconSection>
        </ImageContainer>

        <LayerInfo>
          <HeaderAndInfoIconWrapper>
            <LayerInfoHeader>
              <TranslatedLayerName layer={layer} />
            </LayerInfoHeader>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
            >
              {" "}
              <ProjectIconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleFavorite?.(layer.id);
                }}
              >
                <StarIconWrapper active={isFavorite(layer.id)}>
                  <StarIcon />
                </StarIconWrapper>
              </ProjectIconWrapper>
              <ProjectIconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedLayer(layer);
                }}
              >
                <IconREMSize height={1.2} width={1.2}>
                  <InfoIcon />
                </IconREMSize>
              </ProjectIconWrapper>
              {layer.source?.private && (
                <Tooltip
                  text="Delete private layer source"
                  disabled={!layer.source?.private}
                >
                  <ProjectIconWrapper
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (
                        await showConfirm({
                          title: "Delete private layer source",
                          message:
                            "This will delete the source and all layers belonging to this source.",
                          confirmButtonText: "Delete",
                        })
                      ) {
                        return cellProps.removePrivateSource(
                          layer.source as PrivateDataSource,
                        );
                      }
                    }}
                  >
                    <BinIcon />
                  </ProjectIconWrapper>
                </Tooltip>
              )}
            </div>
          </HeaderAndInfoIconWrapper>
          <LayerInfoSource>
            Source: <SourceNameComponent layer={layer} />
          </LayerInfoSource>
        </LayerInfo>
      </SuggestedLayerContent>
      <OpacityLayer isAdded={cellProps.getIsAdded(layer.id)} />
    </Card>
  );
};

export default GridCard;
