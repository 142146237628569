import { displayLabelPropertyName } from "@constants/canvas";
import * as turf from "@turf/turf";
import {
  useAugmentFeaturesWithColorValues,
  useUpdateLayerLayout,
  useUpdateLayerStyle,
} from "business/style/hooks";
import { stylingLabelSelector } from "business/style/state";
import {
  turbineEllipsesCustomFillLayerId,
  turbineEllipsesCustomFillSourceId,
  turbineEllipsesCustomLineLayerId,
  turbineEllipsesCustomLineSourceId,
} from "components/GenerateWindTurbines/constants";
import { Feature3DRender } from "components/MapFeatures/Feature3DRender";
import { EllipsesFeature } from "components/TurbineEllipsesSettings/state";
import { Angle, TurbineDistance } from "components/Units/units";
import { Feature } from "geojson";
import useIsCameraIn3D from "hooks/useIsCameraIn3D";
import { CirclePaint, FillPaint, LinePaint, SymbolLayout } from "mapbox-gl";
import { useCallback, useEffect, useMemo, useState } from "react";
import { colors } from "styles/colors";
import { TurbineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { isDefined } from "utils/predicates";
import { sendWarning } from "utils/sentry";
import LineString from "../MapFeatures/LineString";
import Polygon from "../MapFeatures/Polygon";
import {
  MIN_TURBINES_VISIBLE_ZOOM,
  turbineBufferLayerId,
  turbineDiameterLayerId,
  turbineDiameterSourceId,
  turbineLayerId,
  turbineSourceId,
  turbineSymbolLayerId,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { atom, useAtomValue } from "jotai";
import { selectedTurbineTypesMostFrequentAtom } from "state/jotai/selection";
import { useAtomUnwrap } from "utils/jotai";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { isOnshoreAtom } from "state/onshore";
import { turbineStyleAtom } from "business/style/feature/turbine";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const ellipseOutlinePaint: LinePaint = {
  "line-color": colors.seagreen700,
  "line-width": 1,
  "line-opacity": 0.7,
};

const ellipseFillPaint: FillPaint = {
  "fill-color": colors.seagreen700,
  "fill-opacity": 0.3,
};

const turbineLabel: SymbolLayout = {
  "text-offset": [0, -2],
  "text-size": 10,
  "text-anchor": "center",
};

export const RenderTurbines = ({
  turbines,
  map,
  paintOverride,
  showSelectionHighlighter,
}: {
  turbines: TurbineFeature[];
  map: mapboxgl.Map;
  paintOverride?: CirclePaint;
  showSelectionHighlighter?: boolean;
}) => {
  const circlePaint = useAtomUnwrap(turbineStyleAtom)?.circle;
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  const featureIds = useMemo(() => turbines.map((f) => f.id), [turbines]);

  const combinedPaint = useMemo(
    () => ({ ...circlePaint, ...paintOverride }),
    [paintOverride, circlePaint],
  );

  useEffect(() => {
    map.addSource(turbineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: turbineLayerId,
      type: "circle",
      source: turbineSourceId,
      paint: {},
      minzoom: MIN_TURBINES_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: turbineSymbolLayerId,
      source: turbineSourceId,
      type: "symbol",
      minzoom: 10,
      layout: turbineLabel,
      filter: ["boolean", ["get", displayLabelPropertyName], true],
      paint: defaultMapTextPaint,
    });

    return () => {
      safeRemoveLayer(map, turbineSymbolLayerId);
      safeRemoveLayer(map, turbineBufferLayerId);
      safeRemoveLayer(map, turbineLayerId);
      map.removeSource(turbineSourceId);
    };
  }, [map, defaultMapTextPaint]);

  useUpdateLayerStyle(map, turbineLayerId, combinedPaint);
  const layout = useAtomUnwrap(stylingLabelSelector("turbines"));
  useUpdateLayerLayout(map, turbineSymbolLayerId, layout?.layout);

  const augmented = useAugmentFeaturesWithColorValues("turbines", turbines);

  useEffect(() => {
    const source = map.getSource(turbineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(augmented),
    });
  }, [map, augmented]);

  return (
    <>
      <AblySelectionHighlighter
        map={map}
        sourceId={turbineSourceId}
        featureIds={featureIds}
        enabled={showSelectionHighlighter}
      />
    </>
  );
};

const turbineDiameterPaintAtom = atom<FillPaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "fill-color": [
      "case",
      ["boolean", ["feature-state", "overlap"], false],
      "#ff0000",
      onshore ? colors.onElTurbineBuffer : colors.blue900,
    ],
    // "fill-outline-color": [
    //   "case",
    //   ["boolean", ["feature-state", "overlap"], false],
    //   "#ff0000",
    //   "#000000",
    // ],
    "fill-opacity": onshore ? 0.3 : 0.4,
  };
});

export const RenderTurbineDiameterCircle = ({
  turbines,
  map,
}: {
  turbines: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const turbineTypeMap = useAtomValue(simpleTurbineTypesAtom);
  const turbineDiameterPaint = useAtomValue(turbineDiameterPaintAtom);

  const getOutlineFeatures = useCallback(() => {
    return turbines.map((f) => {
      const diameter =
        turbineTypeMap.get(f.properties.turbineTypeId)?.diameter ?? 0;
      const buffer: Feature | undefined | null = turf.circle(
        f.geometry,
        diameter / 2,
        {
          units: "meters",
          steps: 32,
        },
      );
      if (buffer == null) return undefined;
      return {
        ...buffer,
        id: f.id,
      };
    });
  }, [turbines, turbineTypeMap]);

  const [outlineFeatures, setOutlineFeatures] = useState<Feature[]>([]);
  useEffect(() => {
    if (!map) return;
    setOutlineFeatures(getOutlineFeatures().filter(isDefined));
  }, [map, getOutlineFeatures]);

  useEffect(() => {
    map.addSource(turbineDiameterSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, turbineDiameterLayerId);
      map.removeSource(turbineDiameterSourceId);
    };
  }, [map, turbineDiameterPaint]);

  useEffect(() => {
    addLayer(map, {
      id: turbineDiameterLayerId,
      type: "fill",
      source: turbineDiameterSourceId,
      paint: turbineDiameterPaint,
    });
  }, [map, turbineDiameterPaint]);

  useEffect(() => {
    const source = map.getSource(turbineDiameterSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: outlineFeatures,
    });
  }, [map, outlineFeatures]);

  const isCameraIn3DView = useIsCameraIn3D();

  if (isCameraIn3DView)
    return <Feature3DRender map={map} features={turbines} />;

  return null;
};

export const RenderTurbineEllipsis = ({
  turbines,
  visibleEllipses,
  map,
}: {
  turbines: TurbineFeature[];
  visibleEllipses: Record<string, EllipsesFeature>;
  map: mapboxgl.Map;
}) => {
  const mostUsedTurbineType = useAtomValue(
    selectedTurbineTypesMostFrequentAtom,
  );

  const turbineDistanceConvert = useMemo(
    () => TurbineDistance.makeConvert(mostUsedTurbineType?.diameter ?? 0),
    [mostUsedTurbineType],
  );

  const turbineDistCannonical = useMemo(() => {
    return ({ value, unit }: TurbineDistance.Of<number>): number =>
      turbineDistanceConvert(value, { from: unit });
  }, [turbineDistanceConvert]);

  const turbineEllipses = useMemo(() => {
    if (!turbines) return [];

    const turbinesWithEllipses = turbines.filter(
      (turbine) => visibleEllipses[turbine.id]?.show,
    );

    const turbineEllipses = turbinesWithEllipses
      .map((turbine) => {
        const { majorAxis, minorAxis, orientation } =
          visibleEllipses[turbine.id];
        const majorAxisCannonical = turbineDistCannonical(majorAxis);
        const minorAxisCannonical = turbineDistCannonical(minorAxis);
        const orientationCannonical = Angle.to(orientation, "deg");

        let sx, sy, angle;
        if (minorAxisCannonical < majorAxisCannonical) {
          sx = minorAxisCannonical / 2.0;
          sy = majorAxisCannonical / 2.0;
          angle = orientationCannonical;
        } else {
          sx = majorAxisCannonical / 2.0;
          sy = minorAxisCannonical / 2.0;
          angle = orientationCannonical + 90;
        }

        if (
          sx == null ||
          Math.abs(sx) < 1e-6 ||
          sy == null ||
          Math.abs(sy) < 1e-6
        )
          return undefined;

        try {
          return turf.ellipse(turbine.geometry.coordinates, sx, sy, {
            units: "meters",
            steps: 64,
            // @ts-ignore: Typing is wrong. See
            // https://github.com/Turfjs/turf/issues/1818
            angle,
          });
        } catch (err) {
          sendWarning(
            new Error(
              "TurbineEllipses: turf.ellipse error. Will not render turbine ellipse",
            ),
            {
              err,
            },
          );
          return undefined;
        }
      })
      .filter(isDefined);

    return turbineEllipses;
  }, [turbines, visibleEllipses, turbineDistCannonical]);

  return (
    <>
      <Polygon
        features={turbineEllipses}
        sourceId={turbineEllipsesCustomFillSourceId}
        layerId={turbineEllipsesCustomFillLayerId}
        map={map}
        paint={ellipseFillPaint}
      />
      <LineString
        features={turbineEllipses}
        sourceId={turbineEllipsesCustomLineSourceId}
        layerId={turbineEllipsesCustomLineLayerId}
        map={map}
        paint={ellipseOutlinePaint}
      />
    </>
  );
};
