import UploadIcon from "@icons/24/Upload.svg?react";
import Button from "components/General/Button";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Label } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { Column, Row } from "components/General/Layout";
import { RangeSlider } from "components/General/Slider";
import HelpTooltip, {
  ARTICLE_IMPORT_WIND_DATA,
  ARTICLE_WIND_DATASETS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { windChangelogInfo } from "components/InputChangelog/const";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { useSetAtom } from "jotai";
import { useMemo } from "react";
import {
  SingleSourceWindConfiguration,
  _SingleSourceWindConfiguration,
  isBestWindSourceConfiguration,
  isCustomWindSource,
} from "services/windSourceConfigurationService";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { UploadedWindData } from "state/windStatistics";
import styled from "styled-components";
import { colors } from "styles/colors";
import { WindDataSource, WindSpeedCalibrationType } from "types/metocean";
import { PreviewTimeseriesGraph, PreviewWindSource } from "./PreviewWindSource";
import { SecondaryText, Text } from "components/SettingsV2/Shared/styles";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
`;

const LeftContentWrapper = styled.div`
  width: 35rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: ${colors.borderSubtle};
  }
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
`;

const MAX_YEARS = {
  [WindDataSource.ERA5]: 2022,
  [WindDataSource.CERRA]: 2020,
  [WindDataSource.NORA3]: 2022,
  [WindDataSource.BEST]: 2022,
  [WindDataSource.CUSTOM]: 2022,
};

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

const SingleTimeSeries = ({
  dataSetDropdownItems,
  localWindConfig,
  originalWindConfig,
  disableActions,
  setLocalWindConfig,
  uploadedWindData,
  uploadedTimeSeries,
  nodeId,
}: {
  dataSetDropdownItems: DropDownItem[];
  localWindConfig: SingleSourceWindConfiguration;
  originalWindConfig?: SingleSourceWindConfiguration;
  disableActions: boolean;
  setLocalWindConfig: (w: SingleSourceWindConfiguration) => void;
  uploadedWindData: UploadedWindData | undefined;
  uploadedTimeSeries: UploadedWindData | undefined;
  nodeId: string;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const selectedDataSetName = useMemo(() => {
    return dataSetDropdownItems.find(
      (d) => d.value === localWindConfig.source.id,
    )?.name;
  }, [dataSetDropdownItems, localWindConfig]);

  const selectedUploadedWindSource = useMemo(() => {
    if (
      uploadedTimeSeries &&
      localWindConfig.source.type === WindDataSource.CUSTOM
    ) {
      return uploadedTimeSeries?.filter(
        (u) => u.id === localWindConfig.source.id,
      );
    } else {
      return undefined;
    }
  }, [uploadedTimeSeries, localWindConfig]);

  return (
    <ContentWrapper>
      <LeftContentWrapper>
        <Label htmlFor="not-help-link">
          <Row style={{ alignItems: "center" }}>
            <SecondaryText>Select dataset</SecondaryText>
            <HelpLink article={ARTICLE_WIND_DATASETS} />
          </Row>
          <Row
            style={{
              width: "fit-content",
            }}
          >
            <DropdownButton
              items={dataSetDropdownItems}
              style={{
                width: "18rem",
              }}
              disabled={disableActions}
              onSelectItem={(id) => {
                const uploaded = uploadedWindData?.find((d) => d.id === id);

                if (uploaded) {
                  setLocalWindConfig(
                    _SingleSourceWindConfiguration.parse({
                      ...localWindConfig,
                      source: {
                        id,
                        longitude: uploaded.lon,
                        latitude: uploaded.lat,
                      },
                    }),
                  );
                } else {
                  if (id === WindDataSource.BEST) {
                    setLocalWindConfig(
                      _SingleSourceWindConfiguration.parse({
                        ...localWindConfig,
                        calibration: null,
                        source: { id, type: "built_in" },
                      }),
                    );
                  } else {
                    setLocalWindConfig(
                      _SingleSourceWindConfiguration.parse({
                        ...localWindConfig,
                        source: { id, type: "built_in" },
                        time_range: {
                          from_year: localWindConfig.time_range.from_year,
                          to_year: Math.min(
                            MAX_YEARS[id as WindDataSource],
                            localWindConfig.time_range.to_year,
                          ),
                        },
                      }),
                    );
                  }
                }
              }}
              buttonText={selectedDataSetName ?? "Choose dataset"}
              selectedItemValue={localWindConfig.source.id}
            />
            <Button
              style={{ padding: "0 2rem" }}
              disabled={disableActions}
              buttonType="secondary"
              icon={<UploadIcon />}
              text="Upload"
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.WIND_DATA,
                  },
                });
              }}
            />
            <HelpLink article={ARTICLE_IMPORT_WIND_DATA} />
          </Row>
        </Label>
        {!isBestWindSourceConfiguration(localWindConfig) && (
          <Column style={{ gap: "0.4rem" }}>
            <Row style={{ alignItems: "center" }}>
              <p>Speed calibration</p>
              <HelpTooltip text="Adjust all speeds in the timeseries with this factor." />
              <InputDimensioned
                disabled={disableActions}
                validate={between(-20, 20)}
                step="0.1"
                unit="%"
                type="number"
                value={localWindConfig.calibration?.value ?? 0}
                initialValue={originalWindConfig?.calibration?.value}
                changelogInfo={windChangelogInfo(
                  localWindConfig.id,
                  nodeId,
                  "calibration.value",
                  "project",
                )}
                onChange={(val) => {
                  setLocalWindConfig(
                    _SingleSourceWindConfiguration.parse({
                      ...localWindConfig,
                      calibration: {
                        type: WindSpeedCalibrationType.PERCENT,
                        value: val,
                      },
                    }),
                  );
                }}
                style={{ width: "6rem" }}
              />
            </Row>
          </Column>
        )}
        {!isCustomWindSource(localWindConfig.source) && (
          <Label>
            <Text>Time range</Text>
            <ChangelogAndCommentWrapper
              changelogInfo={windChangelogInfo(
                localWindConfig.id,
                nodeId,
                "time_range",
                "project",
              )}
            >
              <RangeSlider
                disabled={disableActions}
                style={{ width: "30rem" }}
                min={1990}
                max={MAX_YEARS[localWindConfig.source.id]}
                values={[
                  localWindConfig.time_range.from_year,
                  localWindConfig.time_range.to_year,
                ]}
                onChange={(range) =>
                  setLocalWindConfig(
                    _SingleSourceWindConfiguration.parse({
                      ...localWindConfig,
                      time_range: { from_year: range[0], to_year: range[1] },
                    }),
                  )
                }
                labels
                inside
              />
            </ChangelogAndCommentWrapper>
          </Label>
        )}
      </LeftContentWrapper>
      <RightContentWrapper>
        <>
          <PreviewWindSource
            selectedDataSourceType={localWindConfig.source.type}
            selectedDataSourceId={localWindConfig.source.id}
            points={selectedUploadedWindSource?.map((u) => ({
              lon: u.data!.lon,
              lat: u.data!.lat,
            }))}
          />
          {localWindConfig.source.type === "custom" &&
            selectedUploadedWindSource &&
            selectedUploadedWindSource[0].data && (
              <PreviewTimeseriesGraph
                windData={selectedUploadedWindSource[0].data}
              />
            )}
        </>
      </RightContentWrapper>
    </ContentWrapper>
  );
};

export default SingleTimeSeries;
