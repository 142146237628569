import { z } from "zod";
import * as spec from "../api/folder-structures";

/**
 * Base schema for folder or resource items.
 */
const _BaseFolderOrResourceItem = z.object({
  type: z.enum(["FOLDER", "RESOURCE"]),
  id: z.string(),
  name: z.string().optional(),
  parentId: z.string().optional(),
  meta: z.any(),
  createdAt: z.number(),
  sortOrder: z.number(),
});

export const _FolderItem = spec.components.schemas.FolderItem;

export type FolderItem = z.infer<typeof _FolderItem>;

const _ResourceItem = _BaseFolderOrResourceItem.extend({
  type: z.literal("RESOURCE"),
});

export const _FolderOrResourceItem = z.union([_FolderItem, _ResourceItem]);
export type ResourceItem = z.infer<typeof _ResourceItem>;
export type FolderOrResourceItem = z.infer<typeof _FolderOrResourceItem>;
export type FolderTreeItem = z.infer<typeof _FolderOrResourceItem> & {
  children: FolderTreeItemOrResourceItem[];
};
export type FolderTreeItemOrResourceItem = ResourceItem | FolderTreeItem;
