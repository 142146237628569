import { useCallback } from "react";
import { useDrop } from "react-dnd";
import { FolderTreeItem } from "types/folderStructures";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import { isFolderItem } from "business/folderStructure/utils";

const flattenFolders = (
  folders: FolderTreeItem[],
  result: FolderTreeItem[] = [],
): FolderTreeItem[] => {
  folders.forEach((folder) => {
    if (folder.type === "FOLDER") {
      result.push(folder);
      if (folder.children?.length) {
        flattenFolders(folder.children.filter(isFolderItem), result);
      }
    }
  });
  return result;
};

const isDescendantFolder = (
  ancestorFolder: FolderTreeItem,
  potentialDescendant: FolderTreeItem,
  allFolders: FolderTreeItem[],
): boolean => {
  // First flatten the folder structure
  const flatFolders = flattenFolders(allFolders);

  let currentFolder = potentialDescendant;

  // Traverse up the tree using parentId
  while (currentFolder.parentId) {
    if (currentFolder.parentId === ancestorFolder.id) {
      return true;
    }

    currentFolder =
      flatFolders.find((f) => f.id === currentFolder.parentId) || currentFolder;

    // Break if we can't find the parent (shouldn't happen, but just in case)
    if (!currentFolder) break;
  }

  return false;
};

export const useLibraryDragAndDrop = (
  acceptTypes: string[],
  folders: FolderTreeItem[],
  libraryManageRole: string,
) => {
  const { moveItems, deleteResource } = useOrgFolderStructureCrud({
    libraryManageRole,
  });

  const onDropOnFolder = useCallback(
    (targetFolder: FolderTreeItem, resourceId: string) => {
      // First, find the original folder that's being dragged
      const draggedFolder = flattenFolders(folders).find(
        (f) => f.id === resourceId,
      );

      if (!draggedFolder || draggedFolder.type !== "FOLDER") {
        // If it's not a folder being dragged, proceed with normal resource drop
        const updatedItem = {
          type: "RESOURCE" as const,
          id: resourceId,
          parentId: targetFolder.id,
          sortOrder: targetFolder.children.length,
        };
        moveItems([updatedItem], targetFolder.id);
        return true;
      }

      // If we're here, we're dropping a folder
      if (targetFolder.id === draggedFolder.id) {
        return false;
      }

      // Check if target folder is a descendant of the dragged folder
      if (isDescendantFolder(draggedFolder, targetFolder, folders)) {
        console.warn("Cannot drop a folder into any of its descendant folders");
        return false;
      }

      // If we get here, it's safe to move the folder
      const updatedFolder = {
        ...draggedFolder,
        parentId: targetFolder.id,
        sortOrder: targetFolder.children.length,
      };

      moveItems([updatedFolder], targetFolder.id);
      return true;
    },
    [moveItems, folders],
  );

  const onFolderDropOnFolder = useCallback(
    async (targetFolder: FolderTreeItem, droppedFolder: FolderTreeItem) => {
      try {
        // Don't allow dropping on itself
        if (targetFolder.id === droppedFolder.id) {
          return false; // Return false to indicate drop was not successful
        }

        // Don't allow dropping a folder into any of its descendant folders
        if (isDescendantFolder(droppedFolder, targetFolder, folders)) {
          console.warn(
            "Cannot drop a folder into any of its descendant folders",
          );
          return false; // Return false to indicate drop was not successful
        }

        // Get current children of the target folder
        const currentChildren = targetFolder.children || [];
        const nrFoldersInFolder = currentChildren.filter(isFolderItem).length;

        // Create the updated folder item
        const updatedFolder = {
          ...droppedFolder,
          parentId: targetFolder.id,
          sortOrder: nrFoldersInFolder,
        };

        // Move the folder and its contents
        await moveItems([updatedFolder], targetFolder.id);
        return true; // Return true to indicate successful drop
      } catch (error) {
        console.error("Error moving folder:", error);
        return false; // Return false if there was an error
      }
    },
    [moveItems, folders],
  );

  const onDropOutsideFolder = useCallback(
    (resourceId: string) => {
      deleteResource(resourceId);
    },
    [deleteResource],
  );

  const onFolderDropOnResourceOutsideFolder = useCallback(
    (folder: FolderTreeItem) => {
      if ("type" in folder && folder.type === "FOLDER") {
        moveItems(
          [
            {
              ...folder,
              parentId: undefined,
              sortOrder: folders.length,
            },
          ],
          undefined,
        );
      }
    },
    [moveItems, folders.length],
  );

  const onFolderDropOnResource = useCallback(
    (resourceParentFolderId: string, draggedFolderId: string) => {
      // Find the folder containing the resource
      const resourceParentFolder = flattenFolders(folders).find(
        (f) => f.id === resourceParentFolderId,
      );

      // Find the folder being dragged
      const draggedFolder = flattenFolders(folders).find(
        (f) => f.id === draggedFolderId,
      );

      if (!resourceParentFolder || !draggedFolder) {
        return false;
      }

      // Don't allow dropping on itself
      if (resourceParentFolder.id === draggedFolder.id) {
        return false;
      }

      // Check if target folder is a descendant of the dragged folder
      if (isDescendantFolder(draggedFolder, resourceParentFolder, folders)) {
        console.warn("Cannot drop a folder into any of its descendant folders");
        return false;
      }

      // If we get here, it's safe to move the folder
      const updatedFolder = {
        ...draggedFolder,
        parentId: resourceParentFolder.id,
        sortOrder: resourceParentFolder.children.length,
      };

      moveItems([updatedFolder], resourceParentFolder.id);
      return true;
    },
    [folders, moveItems],
  );

  const [, dropRef] = useDrop(
    () => ({
      accept: acceptTypes,
      drop: (item: any, monitor) => {
        if (!monitor.didDrop()) {
          if ("type" in item && item.type === "FOLDER") {
            return moveItems(
              [
                {
                  ...item,
                  parentId: undefined,
                  sortOrder: folders.length,
                },
              ],
              undefined,
            );
          } else {
            return onDropOutsideFolder(item.id);
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    }),
    [onDropOutsideFolder, moveItems, folders.length, acceptTypes],
  );

  return {
    onDropOnFolder,
    onFolderDropOnFolder,
    onDropOutsideFolder,
    onFolderDropOnResource,
    dropRef,
    onFolderDropOnResourceOutsideFolder,
  };
};
