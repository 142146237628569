import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../General/FeatureFlag";
import ToggleableList from "../../ToggleableList/ToggleableList";
import { Map } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../General/Button";
import {
  getImagePreviewUrl,
  postCustomPreviewImage,
} from "../../../services/layerPreviewAPIService";
import { Layer } from "../../../types/layers";

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const AddManualPreviewImageDataCleaningInner = ({
  map,
  layer,
  afterRefresh,
}: {
  map: Map;
  layer: Layer;
  afterRefresh: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mapImageBase64Url, setMapImageBase64Url] = useState<
    string | undefined
  >(map.getCanvas().toDataURL("image/png"));

  const createSnapshot = useCallback(() => {
    setMapImageBase64Url(map.getCanvas().toDataURL("image/png"));
  }, [map]);

  useEffect(() => {
    map.on("mouseup", createSnapshot);
    map.on("zoomend", createSnapshot);
    return () => {
      map.off("zoomend", createSnapshot);
      map.off("mouseup", createSnapshot);
    };
  }, [createSnapshot, map]);

  const saveAndSetImage = useCallback(async () => {
    const imageUrl = getImagePreviewUrl(layer, (layer as any)["sourceLink"]);
    if (!mapImageBase64Url || !imageUrl) return;
    setIsLoading(true);

    const pureBase64Image = mapImageBase64Url.replace(
      "data:image/png;base64,",
      "",
    );

    await postCustomPreviewImage(imageUrl, pureBase64Image);

    setIsLoading(false);
    afterRefresh();
  }, [mapImageBase64Url, layer, setIsLoading, afterRefresh]);

  return (
    <ToggleableList title={"Manual image"} chevronSize={"1rem"}>
      <Image src={mapImageBase64Url} alt="map" />
      <Button
        disabled={isLoading}
        onClick={saveAndSetImage}
        text={isLoading ? "Loading..." : "Set image"}
      />
    </ToggleableList>
  );
};

const AddManualPreviewImageDataCleaning = ({
  layer,
  afterRefresh,
}: {
  layer: Layer;
  afterRefresh: () => void;
}) => {
  const map = useAtomValue(mapAtom);

  if (!map) return null;
  return (
    <FeatureFlag name={SHOW_DATA_CLEANING}>
      <AddManualPreviewImageDataCleaningInner
        map={map}
        layer={layer}
        afterRefresh={afterRefresh}
      />
    </FeatureFlag>
  );
};

export default AddManualPreviewImageDataCleaning;
