import { atom } from "jotai";
import { TouchdownPointFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { mooringLinesInParkFamily } from "./mooringLine";
import { turbinesInParkWithTypeAndFoundationFamily } from "./turbine";
import { foundationScale } from "./foundation";
import { isFloater, isMooringLineMultiple } from "utils/predicates";
import { mooringLineTypesAtom } from "./mooringLineType";
import * as turf from "@turf/turf";
import { anchorsInParkFamily } from "./anchor";
import { TOUCHDOWN_PROPERTY_TYPE } from "@constants/projectMapView";
import { v4 } from "uuid";
import { bathymetryWithRasterFamily } from "state/bathymetry";
import { touchdown } from "functions/mooring";

export const touchdownPointsInParkFamily = atomFamily(
  ({
    parkId,
    branchId,
    bathymetryId,
  }: {
    parkId: string;
    branchId: string | undefined;
    bathymetryId: string;
  }) =>
    atom<Promise<TouchdownPointFeature[]>>(async (get) => {
      const [mooringLines, turbines, anchors, lineTypes, { raster }] =
        await Promise.all([
          get(mooringLinesInParkFamily({ parkId, branchId })),
          get(turbinesInParkWithTypeAndFoundationFamily({ parkId, branchId })),
          get(anchorsInParkFamily({ parkId, branchId })),
          get(mooringLineTypesAtom),
          get(bathymetryWithRasterFamily(bathymetryId)),
        ]);

      const ret: TouchdownPointFeature[] = [];
      for (const line of mooringLines) {
        const tuple = turbines.find(([t]) => t.id === line.properties.target);
        if (!tuple) continue;

        const [turbine, typ, foundation] = tuple;
        if (!isFloater(foundation)) continue;

        const anchor = anchors.find((a) => a.id === line.properties.anchor);
        if (!anchor) continue;

        const scale = foundationScale({
          foundation: foundation,
          turbine: typ,
        });

        const fairRadius = (scale ?? 1) * (foundation.fairRadius ?? 0);
        const fairZ = (scale ?? 1) * (foundation.fairZ ?? 0);

        let lineLengths: number[] = [];
        let EAs: number[] = [];
        let wetWeights: number[] = [];
        let attachments: number[] = [];
        if (isMooringLineMultiple(line)) {
          for (let i = 0; i < line.properties["lineLengths"].length; i++) {
            lineLengths.push(1000 * line.properties["lineLengths"][i]);
            const lineType = lineTypes.get(line.properties["lineTypes"][i]);
            EAs.push(lineType?.EA ?? 1e2);
            wetWeights.push(lineType?.wetWeight ?? 1);
            attachments.push(line.properties["attachments"][i]);
          }
        } else if (line.properties.lineLength) {
          lineLengths.push(1000 * line.properties["lineLength"]);
          const lineType = lineTypes.get(line.properties["lineType"] ?? "");
          EAs.push(lineType?.EA ?? 1e2);
          wetWeights.push(lineType?.wetWeight ?? 1);
          attachments.push(0);
        }

        const bearing = turf.bearing(
          turbine.geometry.coordinates,
          anchor.geometry.coordinates,
        );

        const anchorRadius = turf.distance(
          turbine.geometry.coordinates,
          anchor.geometry.coordinates,
          { units: "meters" },
        );

        const waterDepth = -raster.latLngToValue(
          turbine.geometry.coordinates[0],
          turbine.geometry.coordinates[1],
        );

        const touchdownRadius = touchdown({
          lineLengths,
          anchorRadius,
          waterDepth,
          fairRadius,
          fairZ,
          EAs,
          wetWeights,
          attachments,
        });

        if (touchdownRadius >= anchorRadius) continue;

        const point = turf.destination(
          turbine.geometry.coordinates,
          touchdownRadius,
          bearing,
          { units: "meters" },
        );

        const id = v4();
        ret.push({
          type: "Feature",
          geometry: point.geometry,
          id: id,
          properties: {
            name: "Touchdown point",
            type: TOUCHDOWN_PROPERTY_TYPE,
            id,
            line: line.id,
            parentIds: [parkId],
          },
        } satisfies TouchdownPointFeature);
      }

      return ret;
    }),
);
