import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { getDataPackageUsage } from "components/Organisation/Library/dataLibrary/state";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import React, { useCallback, useMemo, useState } from "react";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { NotUsedInAnyProjectsYet, SingleNodeView, UsageGrid } from "./shared";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import useNodeDataPackageResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeDataPackageResourcesCrud";
import { nodesWithAccessToDataPackage } from "components/Organisation/Library/state";
import {
  adminInOrganisationSelectorFamily,
  orgDataPackagesManageAccessSelector,
} from "state/user";
import { Column, Row } from "components/General/Layout";
import Checkbox from "components/General/Checkbox";
import {
  ContentTableColumnFirstGrey,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg";
import {
  AdminNodeAccessModalWrapper,
  NodeAccessModalWrapper,
} from "../../shared/NodeAccessModal";
import { colors } from "styles/colors";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import Earth from "@icons/14/Earth.svg";
import { lunwrap } from "utils/jotai";

const AllProjectsDataPackageAccess = ({
  organisationId,
  resourceId,
}: {
  organisationId: string;
  resourceId: string;
}) => {
  const usageLoadable = useAtomValue(
    loadable(
      getDataPackageUsage({
        organisationId,
        packageId: resourceId,
      }),
    ),
  );

  const allNodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  const uniqueProjects = useMemo(() => {
    const value = lunwrap(usageLoadable);

    const totalUsage = value ? [...new Set(Object.values(value).flat())] : [];

    return allNodes.filter((n) => totalUsage.includes(n.id));
  }, [allNodes, usageLoadable]);

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  if (uniqueProjects.length === 0) {
    return <NotUsedInAnyProjectsYet />;
  }

  return uniqueProjects.map((project) => {
    return (
      <SingleNodeView
        key={project.id}
        nodeId={project.id}
        organisationId={organisationId}
        resourceId={resourceId}
        type={"DATAPACKAGE"}
        canRemove={false}
      />
    );
  });
};

export function DataPackageProjects({
  resource,
}: {
  resource: DataLibraryPackage;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { addOrUpdate: addOrUpdateDataPackage, remove } =
    useNodeDataPackageResourcesCrud();

  const nodesWithAccess = useAtomValue(
    nodesWithAccessToDataPackage({
      organisationId,
      resourceId: resource.id,
    }),
  );
  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );

  const hasOrgDataPackageAccess = useAtomValue(
    orgDataPackagesManageAccessSelector,
  );
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (
      nodes: {
        node_id: string;
      }[],
    ) => {
      setShowAddProject(false);
      setAddInProgress(nodes.length);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateDataPackage(node.node_id, resource).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [addOrUpdateDataPackage, resource],
  );

  const onRemoveAccess = useCallback(
    async (
      nodes: {
        node_id: string;
      }[],
    ) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column
      style={{
        gap: "2.4rem",
        paddingTop: "2.4rem",
        height: "100%",
      }}
    >
      <Row>
        <Checkbox
          label={
            <TextEllipsis>
              Make <strong>{resource.name}</strong> available in all projects
            </TextEllipsis>
          }
          labelPlacement="after"
          disabled={removeInProgress || addInProgress > 0}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([
                  {
                    node_id: organisationId,
                  },
                ])
              : onRemoveAccess([
                  {
                    node_id: organisationId,
                  },
                ]);
          }}
        />

        {hasOrgDataPackageAccess && (
          <div
            style={{
              marginLeft: "auto",
              position: "relative",
            }}
          >
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                icon={<AddIcon />}
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={addInProgress > 0 || entireOrgHasAccessToResource}
              />
            </Tooltip>
            {showAddProject && (
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                }}
              >
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>
      <ContentTableColumnFirstGrey>
        {nodesWithAccess.length > 0 ? (
          <>
            <UsageGrid
              style={{
                backgroundColor: colors.surfacePrimary,
              }}
            >
              <TabDescription>
                This GIS package is{" "}
                {entireOrgHasAccessToResource ? "used" : "available"} in:
              </TabDescription>
              <TabDescription></TabDescription>
            </UsageGrid>
            {entireOrgHasAccessToResource ? (
              <AllProjectsDataPackageAccess
                organisationId={organisationId}
                resourceId={resource.id}
              />
            ) : (
              nodesWithAccess.map((na) => {
                return (
                  <SingleNodeView
                    key={na.node_information_id}
                    nodeId={na.node_information_id}
                    resourceId={resource.id}
                    organisationId={organisationId}
                    canRemove={true}
                    type={"DATAPACKAGE"}
                  />
                );
              })
            )}
          </>
        ) : (
          <EmptyState
            title="Resource not available in any projects"
            description="Make this resource available in all projects or select specific projects that can use it by clicking the add button above."
            icon={<Earth />}
            withFill={true}
            style={{
              boxSizing: "border-box",
            }}
          />
        )}
        {Array.from(
          {
            length: addInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "0.8rem",
              }}
            />
          ),
        )}
      </ContentTableColumnFirstGrey>
    </Column>
  );
}
