import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useEffect } from "react";
import { customerProjectAtomFamily } from "../../state/timeline";
import { Mixpanel } from "../../mixpanel";

const TutorialOpenedMixpanelFlag = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  useEffect(() => {
    if (!project) return;
    const { tutorial_id } = project;
    if (!tutorial_id) return;
    Mixpanel.track_old("Opened tutorial project", {
      projectId: tutorial_id,
    });
  }, [project]);
  return null;
};

export default TutorialOpenedMixpanelFlag;
