import * as turf from "@turf/turf";
import { computeOtherLosses } from "components/ProductionV2/functions";
import { triggerAnalysis } from "functions/production";
import { Position } from "geojson";
import { atom } from "jotai";
import {
  AnalysisPrecision,
  AnalysisConfiguration,
  WakeAnalysisConfiguration,
  _WakeAnalysisConfiguration,
} from "services/configurationService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { bathymetryFamily } from "state/bathymetry";
import {
  isTurbineFeatureWithFoundation,
  turbineTypeAndFloatingFoundationCombinations,
} from "state/foundations";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { existingTurbinesFamily } from "state/jotai/existingTurbine";
import {
  foundationFixedTypesAtom,
  foundationFloaterTypesAtom,
  getFixedFoundationTotalsFamily,
  getFloatingFoundationDetailsFamily,
} from "state/jotai/foundation";
import { parkFamily } from "state/jotai/park";
import { subAreasInParkFamily } from "state/jotai/subArea";
import {
  surroundingTurbinesFamily,
  turbinesInParkFamily,
} from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import { branchIdAtom, parkIdAtomDef, projectIdAtom } from "state/pathParams";
import { SimpleWindRose, WindTimeseries } from "state/windStatistics";
import {
  ExistingTurbineFeature,
  ParkFeature,
  SubAreaFeature,
  TurbineFeature,
} from "types/feature";
import { FloaterType } from "types/foundations";
import { Raster } from "types/raster";
import { SimpleTurbineType } from "types/turbines";
import { pointInPolygon } from "utils/geometry";
import { atomFamily } from "utils/jotai";
import { getParkCenter } from "utils/parkUtils";
import { isDefined } from "utils/predicates";
import { scream } from "utils/sentry";
import { fastMax, fastMin, sum } from "utils/utils";
import {
  AnalysisError,
  AnalysisStoppedTypes,
  getParkTurbineTypeIsMissing,
} from "./warnings";
import { windRoseFromConfigFamily } from "state/jotai/windStatistics";
import { MaybePromise } from "types/utils";
import {
  AnalysisConfigNotFoundError,
  WindConfigNotFoundError,
} from "components/ProductionV2/ErrorUtils";

/**
 * Override input for the production analysis.
 * All fields are optional.
 * If a field is not set, it will be calculated from the project.
 */
export type AnalysisOverrideInput = {
  /** Defaults to current project. */
  projectId?: string;
  /** Defaults to current branch. */
  branchId?: string;
  /** Defaults to current park. */
  parkId?: string;
  /** Defaults to current organisation. */
  organisationId?: string;

  /** Only show analysis numbers for turbines in these zones. */
  selectedSubAreas?: SubAreaFeature[];

  turbines?: TurbineFeature[];
  otherTurbines?: TurbineFeature[];
  existingTurbines?: ExistingTurbineFeature[];

  customTurbineTypes?: SimpleTurbineType[];

  /** Override the hub height for the turbines with this vaule. If not set, use
   * the hub heights on the {@link SimpleTurbineType}. */
  hubHeightOverride?: number;

  /** Override the type of turbines in use.  Used in Compare. */
  turbineTypeOverride?: SimpleTurbineType;

  /** Override the type of export cable in use.  Used in Compare. */
  exportCableTypeOverrideId?: string;

  windRose?: SimpleWindRose;
  windTimeseries?: WindTimeseries;
  configuration?: AnalysisConfiguration;
  windConfiguration?: WindSourceConfiguration;
  parkCenter?: Position;
  precision?: AnalysisPrecision;

  /** Force a new analysis to run. */
  restart?: boolean;
};

export type ProdId = string & { readonly __tag: unique symbol };

export const analysisOverrideInputFamily = atomFamily((_: ProdId) =>
  atom<MaybePromise<AnalysisOverrideInput>>(
    new Promise<AnalysisOverrideInput>(() => {}),
  ),
);

export const getBranchId = atomFamily((id: ProdId) =>
  atom<Promise<string>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    const branchId = input.branchId ?? get(branchIdAtom);
    if (!branchId) throw new AnalysisError(AnalysisStoppedTypes.NoBranchId);
    return branchId;
  }),
);

export const getProjectId = atomFamily((id: ProdId) =>
  atom<Promise<string>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    const projectId = input.projectId ?? get(projectIdAtom);
    if (!projectId) throw new AnalysisError(AnalysisStoppedTypes.NoProjectId);
    return projectId;
  }),
);

export const getParkId = atomFamily((id: ProdId) =>
  atom<Promise<string>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    const parkId = input.parkId ?? get(parkIdAtomDef);
    if (!parkId) throw new AnalysisError(AnalysisStoppedTypes.NoParkId);
    return parkId;
  }),
);

export const getPark = atomFamily((id: ProdId) =>
  atom<Promise<ParkFeature>>(async (get, { signal }) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const park = await get(parkFamily({ parkId, branchId }));
    await Promise.any([
      new Promise((_, reject) => {
        signal.addEventListener("abort", () => {
          reject(new Error("Aborted"));
        });
      }),
      new Promise((resolve) => {
        if (park) {
          resolve(park);
        }
      }),
    ]);
    if (!park) throw new AnalysisError(AnalysisStoppedTypes.InvalidParkId);
    return park;
  }),
);

/**
 * If `selectedSubAreas` is set, this will return the turbines inside the zones.
 */
export const getTurbines = atomFamily((id: ProdId) =>
  atom(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    if (input.turbines) return input.turbines;

    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const turbines = (
      await get(
        turbinesInParkFamily({
          parkId,
          branchId,
        }),
      )
    ).map((t) => ({
      ...t,
      properties: {
        ...t.properties,
        turbineTypeId:
          input.turbineTypeOverride?.id ?? t.properties.turbineTypeId,
      },
    }));

    const zones = input.selectedSubAreas;
    if (zones)
      return turbines.filter((t) =>
        zones.some((z) => pointInPolygon(t.geometry, z.geometry)),
      );
    return turbines;
  }),
);

type FoundationStats = {
  totalPrimarySteelMass: number;
  minimumDepth: number;
  maximumDepth: number;
  averageDepth: number;
  minFoundationWeight: number;
  maxFoundationWeight: number;
  averageFoundationWeight: number;
};

export const getTurbinesWithFloatingFoundations = atomFamily((id: ProdId) =>
  atom(async (get) => {
    const turbines = await get(getTurbines(id));
    const allFloaters = await get(foundationFloaterTypesAtom);

    const turbinesWithFoundation = turbines.filter(
      isTurbineFeatureWithFoundation,
    );
    const floatingFoundationTypeIds = new Set(allFloaters.map(({ id }) => id));

    return turbinesWithFoundation.filter((turbineWithFoundation) =>
      floatingFoundationTypeIds.has(
        turbineWithFoundation.properties.foundationId,
      ),
    );
  }),
);

export const getTurbinesWithFixedFoundations = atomFamily((id: ProdId) =>
  atom(async (get) => {
    const turbines = await get(getTurbines(id));
    const allFixed = await get(foundationFixedTypesAtom);

    const turbinesWithFoundation = turbines.filter(
      isTurbineFeatureWithFoundation,
    );
    const fixedFoundationTypeIds = new Set(allFixed.map(({ id }) => id));

    return turbinesWithFoundation.filter((turbineWithFoundation) =>
      fixedFoundationTypeIds.has(turbineWithFoundation.properties.foundationId),
    );
  }),
);

const foundationScale = ({
  turbine,
  foundation,
}: {
  turbine: SimpleTurbineType | undefined;
  foundation: FloaterType | undefined;
}): number | undefined => {
  if (!turbine || !foundation) return;

  const scaledTowerMass =
    (((foundation.towerMass * turbine.diameter) / foundation.rotorDiameter) *
      turbine.hubHeight) /
    foundation.hubHeight;
  const scaledMass = turbine.rnaMass + scaledTowerMass;
  const baseMass = foundation.rnaMass + foundation.towerMass;

  return Math.cbrt(scaledMass / baseMass);
};

// TODO: move this
export const getScalesForTurbineTypeIdsAndFoundationIds = atomFamily(
  (idList: { turbineTypeId: string; foundationId: string }[]) =>
    atom<Promise<Record<string, number>>>(async (get) => {
      const allTurbineTypes = await get(simpleTurbineTypesAtom);
      const allFloaters = await get(foundationFloaterTypesAtom);

      const turbineFoundationScaleCombinations = Object.fromEntries(
        idList.map((c) => {
          const { turbineTypeId, foundationId } = c;
          const turbineType = allTurbineTypes.get(turbineTypeId);
          const foundation = allFloaters.find((f) => f.id === foundationId);
          const scale =
            foundationScale({
              foundation: foundation,
              turbine: turbineType,
            }) ?? 1.0;
          return [`${turbineTypeId},${foundationId}`, scale];
        }),
      );

      return turbineFoundationScaleCombinations;
    }),
);

export const getFoundationStats = atomFamily(
  ({ id, rasterId }: { id: ProdId; rasterId: string | undefined }) =>
    atom<Promise<FoundationStats | undefined>>(async (get) => {
      if (!rasterId) {
        return undefined;
      }

      const bathymetry = await get(bathymetryFamily(rasterId));
      const floatingFoundationTurbines = await get(
        getTurbinesWithFloatingFoundations(id),
      );
      const fixedFoundationTurbines = await get(
        getTurbinesWithFixedFoundations(id),
      );
      const allFloaters = await get(foundationFloaterTypesAtom);

      const turbineTypeIdAndFloatingFoundationIdCombinations =
        turbineTypeAndFloatingFoundationCombinations(
          floatingFoundationTurbines,
          allFloaters,
        );

      const scales = await get(
        getScalesForTurbineTypeIdsAndFoundationIds(
          turbineTypeIdAndFloatingFoundationIdCombinations,
        ),
      );

      const fixedTotalMaterial = await get(
        getFixedFoundationTotalsFamily({
          tempLayoutFoundations: fixedFoundationTurbines,
          rasterId: bathymetry?.id ?? "",
        }),
      );

      const floatingFoundationsTotalMaterials = await get(
        getFloatingFoundationDetailsFamily({
          tempLayoutFoundations: floatingFoundationTurbines,
          scales,
          turbineTypeIdAndFloatingFoundationIdCombinations,
        }),
      );

      const depthsPerTurbine = [
        ...floatingFoundationTurbines,
        ...fixedFoundationTurbines,
      ]
        .map((turbine) => {
          const [lon, lat] = turbine.geometry.coordinates;
          return (bathymetry?.raster as Raster | undefined)?.latLngToValue(
            lon,
            lat,
          );
        })
        .filter(isDefined);

      const minimumDepth = fastMax(depthsPerTurbine, 0);
      const maximumDepth = fastMin(depthsPerTurbine, 0);
      const averageDepth =
        depthsPerTurbine.length > 0
          ? sum(depthsPerTurbine) / depthsPerTurbine.length
          : 0;
      const allMasses = [
        ...fixedTotalMaterial.simpleFoundationMasses.map((a) => a.mass),
        ...fixedTotalMaterial.monopileDetails.map((a) => a.pileMass),
        ...fixedTotalMaterial.jacketDetails.map((a) => a.totalMass),
      ];

      if (floatingFoundationsTotalMaterials) {
        allMasses.push(
          ...floatingFoundationsTotalMaterials.map((total) =>
            sum(Object.values(total)),
          ),
        );
      }

      const totalPrimarySteelMass = sum([
        ...(floatingFoundationsTotalMaterials?.map(
          (f) => f.totalPrimarySteelMass,
        ) ?? []),
        fixedTotalMaterial.totalPrimarySteelMass,
      ]);

      const minFoundationWeight = fastMin(allMasses, 0);
      const maxFoundationWeight = fastMax(allMasses, 0);
      const averageFoundationWeight =
        allMasses.length > 0 ? sum(allMasses) / allMasses.length : 0;

      return {
        totalPrimarySteelMass,
        minimumDepth,
        maximumDepth,
        averageDepth,
        minFoundationWeight,
        maxFoundationWeight,
        averageFoundationWeight,
      };
    }),
);

const getTurbinesOutsideSelectedZones = atomFamily((id: ProdId) =>
  atom<Promise<TurbineFeature[]>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    if (input.turbines) return [];
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const turbines = await get(turbinesInParkFamily({ parkId, branchId }));

    const zones = input.selectedSubAreas;
    if (!zones) return [];
    return turbines.filter(
      (t) => !zones.some((z) => pointInPolygon(t.geometry, z.geometry)),
    );
  }),
);

export const getCloseExistingTurbines = atomFamily((id: ProdId) =>
  atom<Promise<ExistingTurbineFeature[]>>(async (get) => {
    const park = await get(getPark(id));

    const maxDistance = (await get(getConfiguration(id))).wakeAnalysis
      .neighbourWakeMaxDistance;

    const paddedPark = turf.buffer(park, maxDistance);
    if (!paddedPark) {
      scream(new Error("Failed to buffer park"), { park, maxDistance });
      return [];
    }

    const branchId = await get(getBranchId(id));
    const closeValidExistingTurbines = (
      await get(existingTurbinesFamily({ branchId }))
    )
      .filter((t) => t.properties.power)
      .filter((t) => turf.inside(t, paddedPark));

    return closeValidExistingTurbines;
  }),
);

export const getTurbineTypes = atomFamily((_: ProdId) =>
  atom<Promise<Map<string, SimpleTurbineType>>>(async (get) => {
    return get(simpleTurbineTypesAtom);
  }),
);

export const getSubAreas = atomFamily((id: ProdId) =>
  atom<Promise<SubAreaFeature[]>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const subAreas = get(subAreasInParkFamily({ parkId, branchId }));
    return subAreas;
  }),
);

export const getParkOrSubareaCenter = atomFamily((id: ProdId) =>
  atom<Promise<Position>>(async (get) => {
    const park = await get(getPark(id));
    const subAreas = await get(getSubAreas(id));
    return getParkCenter(park, subAreas);
  }),
);

/**
 * If any turbines have an illegal turbine type, return `100`.
 */
export const getAverageHubHeight = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const turbines = await get(getTurbines(id));
    if (turbines.length === 0) return 150;
    const turbineTypes = await get(getTurbineTypes(id));
    let sum = 0;
    for (const t of turbines) {
      const typ = turbineTypes.get(t.properties.turbineTypeId);
      if (!typ) return 100;
      sum += typ.hubHeight;
    }
    return Math.round(sum / turbines.length);
  }),
);

/**
 * Unit is MW.
 */
export const getTurbineCapacity = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const turbineTypes = await get(getTurbineTypes(id));
    const illegalTypes = await get(getParkTurbineTypeIsMissing(id));
    if (illegalTypes)
      throw new AnalysisError(AnalysisStoppedTypes.TurbinesNotFound);
    return sum(
      turbines,
      (t) => turbineTypes.get(t.properties.turbineTypeId)!.ratedPower / 1e3,
    );
  }),
);

export const getWakeSettings = atomFamily((id: ProdId) =>
  atom<Promise<WakeAnalysisConfiguration>>(async (get) => {
    const settings = (await get(getConfiguration(id)))?.wakeAnalysis;
    const { precision } = await get(analysisOverrideInputFamily(id));

    return _WakeAnalysisConfiguration.parse({
      ...settings,
      precision: precision || settings.precision,
    });
  }),
);

export const getWindSourceConfiguration = atomFamily((id: ProdId) =>
  atom<Promise<WindSourceConfiguration>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    if (input.windConfiguration) return input.windConfiguration;

    const projectId = await get(getProjectId(id));
    const branchId = await get(getBranchId(id));
    const cfg = await get(
      windConfigurationSelectedFamily({ projectId, branchId }),
    );
    if (cfg === undefined)
      throw new WindConfigNotFoundError(
        AnalysisStoppedTypes.InvalidWindConfigId,
      );
    return cfg;
  }),
);

export const isValidConfiguration = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    try {
      await get(getConfiguration(id));
      return true;
    } catch (e) {
      return false;
    }
  }),
);

export const getConfiguration = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisConfiguration>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    if (input.configuration) return input.configuration;

    const projectId = await get(getProjectId(id));
    const branchId = await get(getBranchId(id));
    const cfg = await get(
      analysisConfigurationSelectedFamily({ projectId, branchId }),
    );
    if (cfg === undefined)
      throw new AnalysisConfigNotFoundError(
        AnalysisStoppedTypes.InvalidAnalysisConfigId,
      );
    return cfg;
  }),
);

export const getWindRose = atomFamily((id: ProdId) =>
  atom<Promise<SimpleWindRose>>(async (get) => {
    const input = await get(analysisOverrideInputFamily(id));
    if (input.windRose) return input.windRose;

    const parkCenter = await get(getParkOrSubareaCenter(id));
    const height = await get(getAverageHubHeight(id));
    const config = await get(getWindSourceConfiguration(id));
    const projectId = await get(getProjectId(id));

    const windRose = await get(
      windRoseFromConfigFamily({
        lon: parkCenter ? parkCenter[0] : 0,
        lat: parkCenter ? parkCenter[1] : 0,
        height,
        config,
        projectId,
        numberOfDirections: undefined,
      }),
    );
    if (windRose === undefined)
      throw new AnalysisError(AnalysisStoppedTypes.WindRoseFailed);
    return windRose;
  }),
);

export const getOtherLosses = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    const otherLoss = computeOtherLosses(configuration.energyLosses);
    return otherLoss;
  }),
);

export const getSurroundingTurbines = atomFamily((id: ProdId) =>
  atom<Promise<TurbineFeature[]>>(async (get) => {
    const { otherTurbines, selectedSubAreas } = await get(
      analysisOverrideInputFamily(id),
    );
    if (otherTurbines) return otherTurbines;

    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const configuration = await get(getConfiguration(id));

    const surroundingTurbines = configuration.wakeAnalysis.neighbourWake
      ? await get(
          surroundingTurbinesFamily({
            parkId,
            branchId,
            maxDistanceKM: configuration.wakeAnalysis.neighbourWakeMaxDistance,
          }),
        )
      : [];

    if (selectedSubAreas) {
      const outsides = await get(getTurbinesOutsideSelectedZones(id));
      return outsides.concat(surroundingTurbines);
    }

    return surroundingTurbines;
  }),
);

export const getTriggerAnalysisRefresh = atomFamily((_: ProdId) => atom(0));

export const getTriggerAnalysisArgs = atomFamily((id: ProdId) =>
  atom<Promise<Parameters<typeof triggerAnalysis>[0]>>(async (get) => {
    const nodeId = await get(getProjectId(id));
    const turbines = await get(getTurbines(id));
    const park = await get(getPark(id));
    const parkCenter = await get(getParkOrSubareaCenter(id));

    const configuration = await get(getConfiguration(id));
    const neighbourTurbines = configuration.wakeAnalysis.neighbourWake
      ? await get(getSurroundingTurbines(id))
      : [];
    const existingTurbines = configuration.wakeAnalysis.neighbourWake
      ? await get(getCloseExistingTurbines(id))
      : [];
    const windConfiguration = await get(getWindSourceConfiguration(id));
    const wakeSettings = await get(getWakeSettings(id));
    const { hubHeightOverride, turbineTypeOverride, restart } = await get(
      analysisOverrideInputFamily(id),
    );

    return {
      nodeId,
      turbines,
      neighbourTurbines,
      existingTurbines,
      wakeSettings,
      hubHeightOverride,
      turbineTypeOverride,
      windConfiguration,
      park,
      parkCenter,
      restart,
    };
  }),
);

// const foo = atomFamily((id: ProdId) => atom<Promise<void>>(async (get) => {}));
// const foo = atomFamily((id: ProdId) => atom<Promise<void>>(async (get) => {}));
