import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback, useState } from "react";
import styled from "styled-components";
import ColorSelector, { DEFAULT_COLOR } from "../ColorSelector/ColorSelector";
import { Input } from "../General/Input";
import { Layer } from "../../types/layers";
import { layerSettingSelectorFamily } from "../LayerList/LayerSettings/state";
import { useLayerSettingsCrud } from "../LayerList/LayerSettings/useLayerSettingsCrud";
import { StandardBox } from "../../styles/boxes/Boxes";
import { RangeSlider, Slider } from "../General/Slider";
import Dropdown from "../Dropdown/Dropdown";
import { LayerStrokeStyle } from "../LayerList/LayerSettings/types";
import { arcgisLayerDrawingInfoSelector } from "../../state/arcgisRestAPI";
import Checkbox from "../General/Checkbox";

const DEFAULT_OPACITY = 1;

const Wrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  box-shadow: none;

  > :first-child {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
  > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  > :last-child {
    margin-bottom: 2rem;
  }
`;

const RowWrapper = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 5px 0;
  align-items: center;
  gap: 1rem;
`;

const RowItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const GridContainer = styled.div`
  font-size: 1.2rem;
  display: grid;
  grid-template-columns: fit-content(1ch) fit-content(1ch) 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
`;

const SliderWrappers = styled.div`
  margin-top: 1rem;
`;

const CircleColorStyling = ({
  tempColor,
  setTempColor,
  setColor,
}: {
  tempColor: string;
  setTempColor: React.Dispatch<React.SetStateAction<string>>;
  setColor: (colorEither: number | string) => void;
}) => {
  return (
    <>
      <RowWrapper>
        <RowItemWrapper>Fill:</RowItemWrapper>
        <RowItemWrapper>
          <ColorSelector
            color={tempColor}
            setColor={setColor}
            position={"left"}
          />
          <Input
            style={{
              width: "11rem",
            }}
            value={tempColor}
            onChange={(e) => {
              setTempColor(e.target.value);
              const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
              const match = re.exec(e.target.value);
              if (!match) return;
              setColor(e.target.value);
            }}
            type="string"
            placeholder="hex color code"
          />
        </RowItemWrapper>
      </RowWrapper>
    </>
  );
};

const PolygonColorStyling = ({
  tempColor,
  setTempColor,
  setColor,
  strokeStyle,
  setStrokeStyleCallback,
  tempStrokeColor,
  setTempStrokeColor,
  setStrokeColor,
}: {
  tempColor: string;
  setTempColor: React.Dispatch<React.SetStateAction<string>>;
  setColor: (colorEither: number | string) => void;
  setStrokeStyle: React.Dispatch<React.SetStateAction<LayerStrokeStyle>>;
  setStrokeStyleCallback: (newStrokeStyle: LayerStrokeStyle) => void;
  tempStrokeColor: string;
  setTempStrokeColor: React.Dispatch<React.SetStateAction<string>>;
  setStrokeColor: (colorEither: number | string) => void;
  strokeStyle: LayerStrokeStyle;
}) => {
  return (
    <>
      <GridContainer>
        <RowItemWrapper>Fill:</RowItemWrapper>
        <RowItemWrapper>
          <ColorSelector
            color={tempColor}
            setColor={setColor}
            position={"left"}
          />
          <Input
            style={{
              width: "11rem",
            }}
            value={tempColor}
            onChange={(e) => {
              setTempColor(e.target.value);
              const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
              const match = re.exec(e.target.value);
              if (!match) return;
              setColor(e.target.value);
            }}
            type="string"
            placeholder="hex color code"
          />
        </RowItemWrapper>
        <div />

        <RowItemWrapper>Stroke:</RowItemWrapper>
        <RowItemWrapper>
          <ColorSelector
            color={tempStrokeColor}
            setColor={setStrokeColor}
            position={"left"}
          />
          <Input
            style={{
              width: "11rem",
            }}
            value={tempStrokeColor}
            onChange={(e) => {
              setTempStrokeColor(e.target.value);
              const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
              const match = re.exec(e.target.value);
              if (!match) return;
              setStrokeColor(e.target.value);
            }}
            type="string"
            placeholder="hex color code"
          />
        </RowItemWrapper>
        <RowItemWrapper>
          <Dropdown
            value={strokeStyle}
            onChange={(e) => {
              setStrokeStyleCallback(e.target.value as LayerStrokeStyle);
            }}
          >
            {Object.values(LayerStrokeStyle).map((ss) => (
              <option key={ss} value={ss}>
                {ss}
              </option>
            ))}
          </Dropdown>
        </RowItemWrapper>
      </GridContainer>
    </>
  );
};

const LineColorStyling = ({
  tempColor,
  setTempColor,
  setColor,
  setStrokeStyleCallback,
  strokeStyle,
}: {
  tempColor: string;
  setTempColor: React.Dispatch<React.SetStateAction<string>>;
  setColor: (colorEither: number | string) => void;
  setStrokeStyleCallback: (newStrokeStyle: LayerStrokeStyle) => void;
  strokeStyle: LayerStrokeStyle;
}) => {
  return (
    <>
      <RowWrapper>
        <RowItemWrapper>Stroke:</RowItemWrapper>
        <RowItemWrapper>
          <ColorSelector
            color={tempColor}
            setColor={setColor}
            position={"left"}
          />
          <Input
            style={{
              width: "11rem",
            }}
            value={tempColor}
            onChange={(e) => {
              setTempColor(e.target.value);
              const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
              const match = re.exec(e.target.value);
              if (!match) return;
              setColor(e.target.value);
            }}
            type="string"
            placeholder="hex color code"
          />
        </RowItemWrapper>
        <RowItemWrapper>
          <Dropdown
            value={strokeStyle}
            onChange={(e) => {
              setStrokeStyleCallback(e.target.value as LayerStrokeStyle);
            }}
          >
            {Object.values(LayerStrokeStyle).map((ss) => (
              <option key={ss} value={ss}>
                {ss}
              </option>
            ))}
          </Dropdown>
        </RowItemWrapper>
      </RowWrapper>
    </>
  );
};

const LayerStyling = ({ layer }: { layer: Layer }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const settings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const drawingInfo = useAtomValue(
    arcgisLayerDrawingInfoSelector({
      layer,
    }),
  );

  const { put: putLayerSettings } = useLayerSettingsCrud();

  const setLayerOpacity = useCallback(
    (opacity: number) => {
      putLayerSettings(
        [
          {
            ...settings,
            id: settings.id,
            layerStyle: {
              ...settings.layerStyle,
              opacity,
            },
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings],
  );

  const [tempColor, setTempColor] = useState(
    settings.layerStyle?.color ?? DEFAULT_COLOR,
  );

  const setColor = useCallback(
    (colorEither: number | string) => {
      const color = colorEither.toString();
      setTempColor(color);
      putLayerSettings(
        [
          {
            ...settings,
            id: settings.id,
            layerStyle: {
              ...settings.layerStyle,
              color,
            },
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings, setTempColor],
  );

  const [strokeStyle, setStrokeStyle] = useState(
    settings.layerStyle?.strokeStyle ?? LayerStrokeStyle.Solid,
  );

  const setStrokeStyleCallback = useCallback(
    (newStrokeStyle: LayerStrokeStyle) => {
      setStrokeStyle(newStrokeStyle);
      putLayerSettings(
        [
          {
            ...settings,
            id: settings.id,
            layerStyle: {
              ...settings.layerStyle,
              strokeStyle: newStrokeStyle,
            },
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings, setStrokeStyle],
  );

  const [tempStrokeColor, setTempStrokeColor] = useState(
    settings.layerStyle?.strokeColor ?? DEFAULT_COLOR,
  );

  const setStrokeColor = useCallback(
    (colorEither: number | string) => {
      const strokeColor = colorEither.toString();
      setTempStrokeColor(strokeColor);
      putLayerSettings(
        [
          {
            ...settings,
            id: settings.id,
            layerStyle: {
              ...settings.layerStyle,
              strokeColor,
            },
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings, setTempStrokeColor],
  );

  const zoomLevels = settings.layerStyle?.zoomLevels ?? [0, 22];

  const setZoomLevels = useCallback(
    (zoomLevels: undefined | [number, number]) => {
      putLayerSettings(
        [
          {
            ...settings,
            id: settings.id,
            layerStyle: {
              ...settings.layerStyle,
              zoomLevels,
            },
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings],
  );

  const onOverrideDefaultStyleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      putLayerSettings(
        [
          {
            ...settings,
            overrideLayerStyle: event.target.checked,
          },
        ],
        true,
      );
    },
    [putLayerSettings, settings],
  );

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <RowWrapper>
        <h3
          style={{
            margin: 0,
          }}
        >
          Styling
        </h3>
      </RowWrapper>

      {drawingInfo && (
        <RowWrapper>
          <Checkbox
            label="Override default layer style"
            labelPlacement="after"
            checked={settings.overrideLayerStyle}
            onChange={onOverrideDefaultStyleChange}
          />
        </RowWrapper>
      )}
      {(!drawingInfo || settings.overrideLayerStyle) && (
        <>
          {(layer.type === "polygon" || layer.type === "featureCollection") && (
            <PolygonColorStyling
              tempColor={tempColor}
              setTempColor={setTempColor}
              setColor={setColor}
              setStrokeStyle={setStrokeStyle}
              setStrokeStyleCallback={setStrokeStyleCallback}
              setStrokeColor={setStrokeColor}
              tempStrokeColor={tempStrokeColor}
              setTempStrokeColor={setTempStrokeColor}
              strokeStyle={strokeStyle}
            />
          )}
          {layer.type === "circle" && (
            <CircleColorStyling
              tempColor={tempColor}
              setTempColor={setTempColor}
              setColor={setColor}
            />
          )}
          {layer.type === "line" && (
            <LineColorStyling
              tempColor={tempColor}
              setTempColor={setTempColor}
              setColor={setColor}
              setStrokeStyleCallback={setStrokeStyleCallback}
              strokeStyle={strokeStyle}
            />
          )}

          <SliderWrappers>
            <GridContainer>
              <RowItemWrapper>Opacity:</RowItemWrapper>
              <div />
              <RowItemWrapper>
                <Slider
                  min={0}
                  max={1}
                  step={0.025}
                  value={
                    settings.layerStyle?.opacity !== undefined
                      ? settings.layerStyle.opacity
                      : DEFAULT_OPACITY
                  }
                  onChange={setLayerOpacity}
                  style={{
                    width: "20rem",
                  }}
                />
              </RowItemWrapper>

              <RowItemWrapper>Zoom visibilty:</RowItemWrapper>
              <div />
              <RangeSlider
                min={0}
                max={22}
                values={zoomLevels}
                inside
                labels
                onChange={function (f: [number, number]): void {
                  setZoomLevels(f);
                }}
                style={{
                  flex: 1,
                  width: "20rem",
                }}
              />
            </GridContainer>
          </SliderWrappers>
        </>
      )}
    </Wrapper>
  );
};

export default LayerStyling;
