import { atom } from "jotai";
import { z } from "zod";
import { CableType, _CableType } from "../../../services/cableTypeService";
import { _CableLevel } from "types/cables";
import { cableTypesFamily } from "state/jotai/cableType";

export const DEFAULT_SUBSTATION_BUFFER = 350;
export const DEFAULT_TURBINE_BUFFER = 250;
export const DEFAULT_EXPORT_CABLE_BUFFER = 50;

export const _CableSettings = z.object({
  strategy: z.enum(["optimal", "greedy"]),
  maxChainLength: z.number(),
  routeAroundMooring: z.boolean().optional(),
  routeAroundTurbines: z.boolean().optional(),
  routeAroundExportCables: z.boolean().optional(),
  anchorBuffer: z.number().optional(),
  mooringLineBuffer: z.number().optional(),
  exportCableBuffer: z.number().optional(),
  turbineBuffer: z.number().optional(),
  touchdownBuffer: z.number().optional(),
  cableTypes: _CableType.array().optional(),
  optimizeSubstation: z.boolean().optional(),
  numSubstations: z.enum(["one", "two"]),
  replaceTurbines: z.boolean().optional(),
  substationBuffer: z.number().optional(),
  maxTurbinesToSubstation: z.number().optional(),

  fixedChains: z.boolean().optional(),
  fixedChainsNum: z.number().optional(),
  fixedChainsLen: z.number().optional(),
  /**
   * If the arrays should be connected in a cycle instead of a line. If `true`,
   * the longest of the two cables touching the substation will be marked as a
   * redundancy cable.
   */
  cycle: z.boolean().optional(),
});
export type CableSettings = z.infer<typeof _CableSettings>;

export const generateCablesSettingState = atom<CableSettings>({
  maxChainLength: 5,
  strategy: "optimal",
  routeAroundMooring: false,
  routeAroundExportCables: false,
  anchorBuffer: 100,
  mooringLineBuffer: 100,
  touchdownBuffer: 50,
  exportCableBuffer: DEFAULT_EXPORT_CABLE_BUFFER,
  numSubstations: "one",
  substationBuffer: DEFAULT_SUBSTATION_BUFFER,
});

export const libraryCableRefreshAtom = atom(1);

export const selectedCableIdsAtom = atom<string[]>([]);

export const filteredCableTypesAtom = atom<Promise<CableType[]>>(
  async (get) => {
    const selectedCableIds = get(selectedCableIdsAtom);
    const cableTypes = await get(
      cableTypesFamily({
        projectId: undefined,
      }),
    );
    return Array.from(cableTypes.values()).filter((ct) =>
      selectedCableIds.includes(ct.id),
    );
  },
);

export const showAnchorBufferZoneAtom = atom<boolean>(false);
export const showMooringBufferZoneAtom = atom<boolean>(false);
export const showTurbineBufferZoneAtom = atom<boolean>(false);
export const showTouchdownBufferZoneAtom = atom<boolean>(false);
export const showExportCableBufferZoneAtom = atom<boolean>(false);

export const generateCablesLoadingTimeAtom = atom<undefined | number>(
  undefined,
);

export const cableEditModeAtom = atom<boolean>(false);

type Submenu =
  | "mooring"
  | "turbines"
  | "substation"
  | "chains"
  | "substation-buffer"
  | "export-cable";
export const openSubmenuAtom = atom<Submenu | undefined>(undefined);
