import type useParkComparisonValues from "components/CompareParksModal/ParkComparisonView/useParkComparisonValues";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { _CableType } from "services/cableTypeService";
import { branchMetasFamily } from "state/jotai/branch";
import { parkFamily } from "state/jotai/park";
import { _SimpleTurbineType } from "types/turbines";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { z } from "zod";
import { ParkFeature } from "../../types/feature";
import { isDefined } from "../../utils/predicates";
import { sendInfo } from "../../utils/sentry";
import {
  ComparisonAttributeKey,
  _ComparisonAttributesCabling,
  _ComparisonAttributesFINANCIAL,
  _ComparisonAttributesFinancialInputs,
  _ComparisonAttributesFoundations,
  _ComparisonAttributesPark,
  _ComparisonAttributesProduction,
  _ComparisonAttributesWind,
} from "./types";
import { loggedInUserIdAtom } from "state/user";

export const baselineComparisonValuesAtom = atom<
  | { comparisonId: string; values: ReturnType<typeof useParkComparisonValues> }
  | undefined
>(undefined);

export const _SelectedParkCompare = z
  .object({
    id: z.string().optional(),
    comparisonId: z.string().optional(),
    parkId: z.string(),
    branchId: z.string(),
    selectedAnalysisConfigurationId: z.string().optional(),
    selectedWindConfigurationId: z.string().optional(),
    selectedCostConfigurationId: z.string().optional(),
    selectedOperationsConfigurationId: z.string().optional(),
    selectedHubHeight: z.number().optional(),
    selectedMaxPower: z.number().optional(),
    selectedTurbineType: _SimpleTurbineType.optional(),
    selectedExportCableType: _CableType.optional(),
  })

  // Added 2024-02-20 to handle old data saved in LS
  .transform((data, ctx) => {
    const comparisonId = data.id ?? data.comparisonId;
    if (!comparisonId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing comparisonId in _SelectedParkCompare`,
      });
      return z.NEVER;
    }

    return {
      ...data,
      comparisonId,
    };
  });

export type SelectedParkCompare = z.infer<typeof _SelectedParkCompare>;

const COMPARISON_PARKS_LS_KEY = "vind:selected-comparison-parks";
const getComparisonParksLocalStorageKey = (nodeId: string, userId: string) =>
  COMPARISON_PARKS_LS_KEY.concat("-", nodeId, "-", userId);

export const selectedParksAtom = atomFamily(
  ({ projectId }: { projectId: string }) => {
    const backing = atom<SelectedParkCompare[] | undefined>(undefined);
    return atom<Promise<SelectedParkCompare[]>, [SelectedParkCompare[]], void>(
      async (get) => {
        const userId = get(loggedInUserIdAtom);
        const existingBranches = await get(branchMetasFamily({ projectId }));

        const curr = get(backing);
        if (curr) return curr;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !userId
        ) {
          return [];
        }

        const key = getComparisonParksLocalStorageKey(projectId, userId);

        try {
          const storageItem = localStorage.getItem(key);
          if (storageItem) {
            const result = JSON.parse(storageItem);
            const res = _SelectedParkCompare
              .array()
              .parse(result)
              .filter((row) => {
                return existingBranches.get(row.branchId);
              });
            return res;
          }
        } catch (err) {
          sendInfo("Could not read local storage", {
            key: key,
            err,
          });
        }

        return [];
      },
      (get, set, update: SelectedParkCompare[]) => {
        set(backing, update);
        const userId = get(loggedInUserIdAtom);

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !userId
        ) {
          return;
        }

        const key = getComparisonParksLocalStorageKey(projectId, userId);
        try {
          const currentVal = get(backing);
          if (!currentVal || currentVal.length === 0) {
            localStorage.removeItem(key);
            return;
          }

          localStorage.setItem(key, JSON.stringify(currentVal));
        } catch (err) {
          sendInfo("Could not set local storage", {
            key: key,
            newValue: update,
          });
        }
      },
    );
  },
);

export type EnrichedSelectedParkCompare = {
  park: ParkFeature;
} & SelectedParkCompare;

export const getSelectedParksForComparisonSelector = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atom(async (get) => {
      const existingBranches = await get(
        branchMetasFamily({ projectId: nodeId }),
      );
      const selectedParks = await get(selectedParksAtom({ projectId: nodeId }));
      const parks = (
        await Promise.all(
          selectedParks
            .filter((selectedPark) => {
              return existingBranches.get(selectedPark.branchId);
            })
            .map(async (selectedPark) => {
              const park = await get(
                parkFamily({
                  parkId: selectedPark.parkId,
                  branchId: selectedPark.branchId,
                }),
              );

              if (park) {
                return {
                  park,
                  ...selectedPark,
                };
              }

              return undefined;
            }),
        )
      ).filter(isDefined);
      return parks;
    }),
);

export const compareIsLoading = atom<Record<string, boolean>>({});

const _ComparisonAttributes = z.object({
  [ComparisonAttributeKey.PARK]: _ComparisonAttributesPark.array(),
  [ComparisonAttributeKey.PRODUCTION]: _ComparisonAttributesProduction.array(),
  [ComparisonAttributeKey.WIND]: _ComparisonAttributesWind.array(),
  [ComparisonAttributeKey.CABLING]: _ComparisonAttributesCabling.array(),
  [ComparisonAttributeKey.FINANCIAL]: _ComparisonAttributesFINANCIAL.array(),
  [ComparisonAttributeKey.FOUNDATIONS]:
    _ComparisonAttributesFoundations.array(),
  [ComparisonAttributeKey.FINANCIAL_INPUTS]:
    _ComparisonAttributesFinancialInputs.array(),
});

type ComparisonAttributes = z.infer<typeof _ComparisonAttributes>;

const DEFAULT_COMPARISON_ATTRIBUTES: ComparisonAttributes =
  _ComparisonAttributes.parse({
    [ComparisonAttributeKey.PARK]: [
      "turbines",
      "turbineTypesString",
      "depthRange",
    ],
    [ComparisonAttributeKey.PRODUCTION]: [
      "capacity",
      "netEnergy",
      "capacityFactor",
      "totalWakeLoss",
      "otherLoss",
    ],
    [ComparisonAttributeKey.WIND]: ["avgSourceWindSpeed", "height"],
    [ComparisonAttributeKey.CABLING]: [
      "exportCableLength",
      "exportCableTypesString",
    ],
    [ComparisonAttributeKey.FINANCIAL]: ["lcoe"],
    [ComparisonAttributeKey.FOUNDATIONS]: ["primarySteelWeight"],
    [ComparisonAttributeKey.FINANCIAL_INPUTS]: [],
  });

const SELECTED_COMPARISON_ATTRIBUTES_LS_KEY =
  "vind:selectedComparisonAttributes";

export const selectedComparisonAttributesAtom =
  atomLocalStorage<ComparisonAttributes>(
    SELECTED_COMPARISON_ATTRIBUTES_LS_KEY,
    DEFAULT_COMPARISON_ATTRIBUTES,
    _ComparisonAttributes,
  );

export type ComparisonData = Record<
  ParkComparisonId,
  {
    branchId: string;
    parkId: string;
    costConfigurationId?: string;
    analysisConfigurationId?: string;
    windConfigurationId?: string;
    operationsConfigurationId?: string;
    analysisVersion?: string;
    comparisonData: Record<ComparisonAttributeKey, Record<string, unknown>>;
  }
>;
type ParkComparisonId = string;
export const shownCompareDataAtom = atom<ComparisonData>({});

export const visibleComparisonListRowsAtom = atomWithReset<
  Record<string, string[]>
>({});

const _ComparisonMode = z.enum(["absolute", "percentage"]);
export type ComparisonMode = z.infer<typeof _ComparisonMode>;
export const comparisonModeAtom = atomLocalStorage<ComparisonMode>(
  "vind:comparisonMode",
  "absolute",
  _ComparisonMode,
);
