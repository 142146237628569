import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { projectIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  allLayerSettingsAtomFamily,
  layersSettingSelectorFamily,
  layerVisibleAtomFamily,
  selectedSourceNameAtom,
  SourceName,
} from "../components/LayerList/LayerSettings/state";
import { useLayerSettingsCrud } from "../components/LayerList/LayerSettings/useLayerSettingsCrud";
import { scrollToLayerIdInListAtom } from "../components/LayerList/state";
import { privateGISSourceDataAPISelector } from "../state/privateGISSource";
import { deletePrivateDataSource } from "../services/gisDataAPIService";
import { ExternalDataSource, PrivateDataSource } from "../types/layers";

export const useDataLayerCellProps = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId,
    }),
  );
  const { put: putLayerSettings } = useLayerSettingsCrud();
  const setScrollToLayerId = useSetAtom(scrollToLayerIdInListAtom);
  const resetPrivateGISSourceDataAPISelector = useSetAtom(
    privateGISSourceDataAPISelector({
      projectId,
    }),
  );

  const getSourceName = useCallback(
    (source?: ExternalDataSource) => {
      const title =
        source &&
        selectedSourceName === SourceName.Original &&
        source.originalName
          ? source.originalName
          : source?.name ?? "";

      return title;
    },
    [selectedSourceName],
  );

  const setLayerVisible = useJotaiCallback(
    (_, set, id: string, visible: boolean) => {
      set(
        layerVisibleAtomFamily({
          projectId,
          layerId: id,
        }),
        Promise.resolve(visible),
      );
    },
    [projectId],
  );

  const addLayer = useJotaiCallback(
    async (get, _set, layerId: string) => {
      const settings = await get(
        layersSettingSelectorFamily({
          projectId,
          layerIds: [layerId],
        }),
      );
      setLayerVisible(layerId, true);
      setScrollToLayerId(layerId);
      await putLayerSettings(settings);
    },
    [projectId, putLayerSettings, setLayerVisible, setScrollToLayerId],
  );

  const addMultipleLayers = useJotaiCallback(
    async (get, _set, layerIds: string[]) => {
      // Get settings for all layers at once
      const settings = await get(
        layersSettingSelectorFamily({
          projectId,
          layerIds, // Pass array of IDs
        }),
      );

      await putLayerSettings(settings);

      // Set visibility for all layers
      for (const layerId of layerIds) {
        setLayerVisible(layerId, true);
      }

      // Scroll to last added layer
      setScrollToLayerId(layerIds[layerIds.length - 1]);
    },
    [projectId, putLayerSettings, setLayerVisible, setScrollToLayerId],
  );

  const removeLayer = useJotaiCallback(
    async (get, set, layerId: string) => {
      const settings = await get(
        layersSettingSelectorFamily({
          projectId,
          layerIds: [layerId],
        }),
      );

      const updatedSettings = settings.filter((s) => s.id !== layerId);

      setLayerVisible(layerId, false);
      await putLayerSettings(updatedSettings);

      aset(
        get,
        set,
        allLayerSettingsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter((l) => l.id !== layerId);
          return rest;
        },
      );
    },
    [projectId, putLayerSettings, setLayerVisible],
  );

  const removePrivateSource = useCallback(
    async (source: PrivateDataSource) => {
      const url = [...source.wms, ...source.wfs, ...source.arcgis][0]?.url;
      if (!url || !projectId) {
        return;
      }

      await deletePrivateDataSource(projectId, url);
      resetPrivateGISSourceDataAPISelector();
    },
    [projectId, resetPrivateGISSourceDataAPISelector],
  );

  const getIsAdded = useCallback(
    (layerId: string) => {
      return allLayerSettings.some((setting) => setting.id === layerId);
    },
    [allLayerSettings],
  );

  return useMemo(
    () => ({
      addLayer,
      addMultipleLayers,
      removeLayer,
      getIsAdded,
      getSourceName,
      selectedSourceName,
      removePrivateSource,
    }),
    [
      addLayer,
      addMultipleLayers,
      removeLayer,
      getIsAdded,
      getSourceName,
      selectedSourceName,
      removePrivateSource,
    ],
  );
};
