import { useCallback, useMemo, useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useDrop } from "react-dnd";
import { useDndScrolling } from "react-dnd-scrolling";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import {
  customerProjectAtomFamily,
  useArchiveBranch,
  useDeleteBranch,
} from "state/timeline";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { BranchMeta } from "types/api";
import { useToast } from "hooks/useToast";
import { editorAccessProjectSelector } from "state/user";
import useResetMapControls from "hooks/useResetMapControls";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { scream } from "utils/sentry";
import useNavigateToBranch from "../useNavigateToBranch";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
} from "../state";
import { ToplevelDropTargetDiv } from "./style";
import { isDefined } from "utils/predicates";
import useBranchFolderStructureCrud from "hooks/useBranchFolderStructureCrud";
import {
  buildFolderTree,
  findItemInTree,
  getFilteredFolderTree,
  getIsLegalMove,
  getOrphans,
  isFolderItem,
} from "business/folderStructure/utils";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { FolderTreeItem } from "types/folderStructures";
import { branchFolderStructureFamily } from "state/branchFolderStructure";
import { Mixpanel } from "mixpanel";
import {
  BRANCH_TABLE_FOLDER_ITEM,
  BRANCH_TABLE_LIST_ITEM,
  BranchTableItem,
  Folder,
} from "components/Design/BranchTabBar/components/table-items";
import { FolderListWrapper } from "components/FolderList/style";
import {
  ConfirmContentText,
  useConfirm,
} from "components/ConfirmDialog/ConfirmDialog";

export const useBranchFolderStructure = ({ nodeId }: { nodeId: string }) => {
  const folderStructure = useAtomValue(branchFolderStructureFamily({ nodeId }));
  const branchMetaObjects = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId,
    }),
  );

  const folderTree = useMemo(() => {
    const rootItems = folderStructure.filter((f) => !isDefined(f.parentId));
    return buildFolderTree(rootItems, folderStructure);
  }, [folderStructure]);

  const orphanItems = useMemo(() => {
    return getOrphans(folderStructure, branchMetaObjects, (b) => b.id);
  }, [branchMetaObjects, folderStructure]);

  const folders = useMemo(
    () => folderTree.filter((f) => isFolderItem(f)),
    [folderTree],
  );
  const orphansThatExistsInFolderStructure = useMemo(
    () => folderTree.filter((f) => f.type === "RESOURCE"),
    [folderTree],
  );

  const orphans = useMemo(() => {
    return [...orphansThatExistsInFolderStructure, ...orphanItems];
  }, [orphanItems, orphansThatExistsInFolderStructure]);

  return {
    folders,
    orphans,
  };
};

const BranchesTable = ({
  enableDrag,
  isSearching,
  branchesSearchResult,
  onCreateBranchInFolder,
  onCreateFolderInFolder,
  onDuplicateBranchClick,
}: {
  enableDrag: boolean;
  isSearching: boolean;
  branchesSearchResult: BranchMeta[];
  onCreateBranchInFolder(folderId: string): void;
  onCreateFolderInFolder(folderId: string): void;
  onDuplicateBranchClick(branch: BranchMeta): void;
}) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const setBranchFrameVisible = useSetAtom(allBranchesFrameOpenedAtom);
  const setIsLoading = useSetAtom(allBranchesFrameLoadingAtom);
  const { info: showInfo, error: showError } = useToast();
  useDndScrolling(scrollBodyRef, {});
  const navigateToBranch = useNavigateToBranch();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const activeBranchId = useAtomValue(branchIdAtom) ?? "";
  const branchMetaObjects = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId,
    }),
  );
  const { showConfirm } = useConfirm();
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const { moveItems, deleteResource } = useBranchFolderStructureCrud();
  const { folders: folderTree, orphans } = useBranchFolderStructure({ nodeId });
  const deleteBranch = useDeleteBranch();
  const archiveBranch = useArchiveBranch();
  const { update: updateNode } = useOrganisationNodeCrud();

  const folderTreeAfterSearching = useMemo(() => {
    if (!isSearching) {
      return folderTree;
    }

    const filteredBranchIds = branchesSearchResult.map((b) => b.id);
    return getFilteredFolderTree(folderTree, filteredBranchIds);
  }, [branchesSearchResult, folderTree, isSearching]);

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folderTree);
    },
    [folderTree],
  );

  const resetMapControls = useResetMapControls();

  const selectedBranch = useMemo(
    () => branchMetaObjects.find((b) => b.id === activeBranchId),
    [activeBranchId, branchMetaObjects],
  );

  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );

  const onArchiveBranch = useCallback(
    async (branchMeta: BranchMeta) => {
      if (branchMetaObjects.length < 2) {
        showInfo("Can not archive last branch", {
          timeout: 2000,
          showCountdown: false,
        });
        return;
      }

      if (
        await showConfirm({
          title: "Archive branch",
          message: `You are about to archive the branch "${branchMeta.title}".`,
          confirmButtonText: "Archive",
        })
      ) {
        setIsLoading(true);
        try {
          const promise = archiveBranch(branchMeta, nodeId);
          deleteResource(branchMeta.id);
          if (branchMeta.id === selectedBranch?.id) {
            resetMapControls();
            const selectedBranchId = branchMetaObjects.filter(
              (b) => b.id !== activeBranchId,
            )[0].id;

            // update main branch id if that is the one that was deleted
            if (project?.main_branch_id === branchMeta.id) {
              await updateNode(project.id, {
                main_branch_id: selectedBranchId,
              });
            }

            navigateToBranch(selectedBranchId, false);
          }

          await promise;
        } catch (err) {
          if (err instanceof Error) {
            scream(err, {
              nodeId,
            });
          } else {
            scream(new Error("Failed to archive branch"), {
              nodeId,
              err,
            });
          }
          showError("Failed to archive branch, please try again.");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      branchMetaObjects,
      showConfirm,
      showInfo,
      setIsLoading,
      archiveBranch,
      nodeId,
      deleteResource,
      selectedBranch?.id,
      resetMapControls,
      project?.main_branch_id,
      project?.id,
      navigateToBranch,
      activeBranchId,
      updateNode,
      showError,
    ],
  );

  const onDeleteBranch = useCallback(
    async (branchMeta: BranchMeta) => {
      if (branchMetaObjects.length < 2) {
        showInfo("Can not delete last branch", {
          timeout: 2000,
          showCountdown: false,
        });
        return;
      }

      if (
        await showConfirm({
          title: "Delete branch",
          message: (
            <ConfirmContentText>
              You are about to <b>permanently delete</b> the branch "
              {branchMeta.title}" with all its history. This action can not be
              undone.
            </ConfirmContentText>
          ),
          confirmButtonText: "Delete",
        })
      ) {
        setIsLoading(true);
        try {
          const promise = deleteBranch(branchMeta, nodeId);
          deleteResource(branchMeta.id);
          if (branchMeta.id === selectedBranch?.id) {
            resetMapControls();
            const selectedBranchId = branchMetaObjects.filter(
              (b) => b.id !== activeBranchId,
            )[0].id;

            // update main branch id if that is the one that was deleted
            if (project?.main_branch_id === branchMeta.id) {
              await updateNode(project.id, {
                main_branch_id: selectedBranchId,
              });
            }

            navigateToBranch(selectedBranchId, false);
          }

          await promise;
        } catch (err) {
          if (err instanceof Error) {
            scream(err, {
              nodeId,
            });
          } else {
            scream(new Error("Failed to remove branch"), {
              nodeId,
              err,
            });
          }
          showError("Failed to remove branch, please try again.");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      branchMetaObjects,
      showConfirm,
      showInfo,
      setIsLoading,
      deleteBranch,
      nodeId,
      deleteResource,
      selectedBranch?.id,
      resetMapControls,
      project?.main_branch_id,
      project?.id,
      navigateToBranch,
      activeBranchId,
      updateNode,
      showError,
    ],
  );

  const onDropOnFolder = useCallback(
    (folder: FolderTreeItem, branchItem: BranchMeta) => {
      Mixpanel.track_old(`Branch folders - Branch dropped on folder`, {});
      moveItems(
        [
          {
            type: "RESOURCE",
            parentId: folder.id,
            id: branchItem.id,
          },
        ],
        folder.id,
      );
    },
    [moveItems],
  );

  const onReorderFolder = useCallback(
    (
      droppedItem: FolderTreeItem,
      newIndex: number,
      parentFolder?: FolderTreeItem,
    ) => {
      Mixpanel.track_old(`Branch folders - Folder reordered`, {
        newIndex,
        hasParent: parentFolder !== undefined,
      });

      if (!parentFolder) {
        const currIndex = folderTree.findIndex((b) => b.id === droppedItem.id);
        const currItem = folderTree[currIndex];
        const newItems = [...folderTree];
        let indexToInsert = newIndex;
        if (currIndex !== -1) {
          newItems.splice(currIndex, 1);
          indexToInsert = newIndex > currIndex ? newIndex - 1 : newIndex;
        }
        newItems.splice(indexToInsert, 0, currItem ?? droppedItem);
        moveItems(
          newItems.map((b, i) => ({
            type: "FOLDER",
            name: b.name,
            id: b.id,
            sortOrder: i,
          })),
          undefined,
        );
      } else {
        const currIndex = parentFolder.children.findIndex(
          (b) => b.id === droppedItem.id,
        );
        const currItem = parentFolder.children[currIndex];
        const newItems = [...parentFolder.children];
        let indexToInsert = newIndex;
        if (currIndex !== -1) {
          newItems.splice(currIndex, 1);
          indexToInsert = newIndex > currIndex ? newIndex - 1 : newIndex;
        }
        newItems.splice(indexToInsert, 0, currItem ?? droppedItem);
        moveItems(
          newItems.map((b, i) => ({
            type: b.type ?? "FOLDER",
            parentId: parentFolder?.id,
            id: b.id,
            sortOrder: i,
            name: b.name,
          })),
          parentFolder?.id,
        );
      }
    },
    [folderTree, moveItems],
  );

  const onDropOnBranch = useCallback(
    (
      droppedItem: BranchMeta | FolderTreeItem,
      newIndex: number,
      parentFolder?: FolderTreeItem,
    ) => {
      const isFolder = isFolderItem(droppedItem);
      Mixpanel.track_old(`Branch folders - Item dropped on branch`, {
        isFolder,
        newIndex,
        hasParent: parentFolder !== undefined,
      });
      if (isFolder) {
        if (parentFolder) {
          const isAlreadyInSaidFolder = parentFolder.children.some(
            (f) => f.id === droppedItem.id,
          );
          if (isAlreadyInSaidFolder) {
            return {
              handled: true,
            };
          }
          const nrFoldersInFolder = parentFolder.children.filter(
            (f) => f.type === "FOLDER",
          ).length;

          moveItems(
            [
              {
                type: "FOLDER",
                parentId: parentFolder.id,
                id: droppedItem.id,
                name: droppedItem.name,
                sortOrder: nrFoldersInFolder,
              },
            ],
            parentFolder.id,
          );
          return;
        } else {
          const rootFolders = folderTree.filter(
            (f) => f.parentId === undefined,
          );
          const currIndex = rootFolders.findIndex(
            (b) => b.id === droppedItem.id,
          );
          const currItem = rootFolders[currIndex];
          const newItems = [...rootFolders];
          if (currIndex !== -1) {
            newItems.splice(currIndex, 1);
          }
          newItems.push(currItem ?? droppedItem);
          moveItems(
            newItems.map((b, i) => ({
              type: "FOLDER",
              name: b.name,
              id: b.id,
              sortOrder: i,
            })),
            undefined,
          );
          return;
        }
      }

      if (!parentFolder) {
        const currIndex = orphans.findIndex((b) => b.id === droppedItem.id);
        const currItem = orphans[currIndex];
        const newItems = [...orphans];
        if (currIndex !== -1) {
          newItems.splice(currIndex, 1);
        }
        const adjustedNewIndex = newIndex > currIndex ? newIndex - 1 : newIndex;
        newItems.splice(adjustedNewIndex, 0, currItem ?? droppedItem);
        moveItems(
          newItems.map((b, i) => ({
            type: "RESOURCE",
            id: b.id,
            sortOrder: i,
          })),
          undefined,
        );
      } else {
        const currIndex = parentFolder.children.findIndex(
          (b) => b.id === droppedItem.id,
        );
        const currItem = parentFolder.children[currIndex];
        const newItems = [...parentFolder.children];
        let indexToInsert = newIndex;
        if (currIndex !== -1) {
          newItems.splice(currIndex, 1);
          indexToInsert = newIndex > currIndex ? newIndex - 1 : newIndex;
        }
        newItems.splice(indexToInsert, 0, currItem ?? droppedItem);
        moveItems(
          newItems.map((b, i) => ({
            type: b.type ?? "RESOURCE",
            parentId: parentFolder?.id,
            id: b.id,
            sortOrder: i,
          })),
          parentFolder?.id,
        );
      }
    },
    [folderTree, moveItems, orphans],
  );

  const onFolderDropOnFolder = useCallback(
    (folder: FolderTreeItem, droppedFolder: FolderTreeItem) => {
      Mixpanel.track_old(`Branch folders - Folder dropped on folder`, {});
      const nrFoldersInFolder = folder.children.filter(isFolderItem).length;
      const newItems = [
        ...folder.children,
        { ...droppedFolder, sortIndex: nrFoldersInFolder },
      ]
        .sort((a, b) => {
          if (isFolderItem(a) && !isFolderItem(b)) {
            return -1;
          }
          if (isFolderItem(b) && !isFolderItem(a)) {
            return 1;
          }

          return (a.sortOrder ?? 0) - (b.sortOrder ?? 0);
        })
        .map((item, index) => ({
          ...item,
          sortOrder: index,
        }));

      moveItems(newItems, folder.id);
    },
    [moveItems],
  );

  return (
    <FolderListWrapper ref={scrollBodyRef}>
      {folderTreeAfterSearching.filter(isFolderItem).map((item) => {
        const containsActiveBranch = Boolean(
          findItemInTree(activeBranchId, item.children),
        );
        return (
          <Folder
            key={item.id}
            folder={item as FolderTreeItem}
            enableSorting={!isSearching && editorAccessProject && enableDrag}
            onBranchDropOnFolder={onDropOnFolder}
            onFolderDropOnFolder={onFolderDropOnFolder}
            onReorder={onReorderFolder}
            shouldForceOpen={isSearching || containsActiveBranch}
            depth={0}
            isLegalFolderMove={isLegalFolderMove}
            onCreateBranchInFolder={onCreateBranchInFolder}
            folderTreeContainsActiveFolder={containsActiveBranch}
            activeBranchId={activeBranchId}
            onCreateFolderInFolder={onCreateFolderInFolder}
            showControls={editorAccessProject}
          >
            {(resourceId, parentFolder, depth, index) => {
              const branch = branchesSearchResult.find(
                (b) => b.id === resourceId,
              );
              if (!branch) {
                return null;
              }

              return (
                <BranchTableItem
                  key={branch.id}
                  branch={branch}
                  active={branch.id === activeBranchId}
                  enableSorting={
                    !isSearching && editorAccessProject && enableDrag
                  }
                  onDeleteBranch={onDeleteBranch}
                  onArchiveBranch={onArchiveBranch}
                  onDuplicateBranchClick={onDuplicateBranchClick}
                  onBranchClick={(b) => {
                    navigateToBranch(b.id, true);
                    setBranchFrameVisible(false);
                  }}
                  index={index}
                  nodeId={nodeId}
                  onDropItem={onDropOnBranch}
                  canDeleteBranch={branchMetaObjects.length > 1}
                  organisationId={organisationId}
                  showControls={editorAccessProject}
                  depth={depth + 1}
                  isLegalFolderMove={isLegalFolderMove}
                  parentFolder={parentFolder}
                />
              );
            }}
          </Folder>
        );
      })}
      {orphans.map((item, index) => {
        const branch = branchesSearchResult.find((b) => b.id === item.id);
        if (!branch) {
          return null;
        }
        return (
          <BranchTableItem
            key={branch.id}
            branch={branch}
            active={branch.id === activeBranchId}
            enableSorting={!isSearching && editorAccessProject && enableDrag}
            onDeleteBranch={onDeleteBranch}
            onArchiveBranch={onArchiveBranch}
            onBranchClick={(b) => {
              navigateToBranch(b.id, true);
              setBranchFrameVisible(false);
            }}
            onDuplicateBranchClick={onDuplicateBranchClick}
            index={item.sortOrder ?? index}
            nodeId={nodeId}
            onDropItem={onDropOnBranch}
            canDeleteBranch={branchMetaObjects.length > 1}
            organisationId={organisationId}
            showControls={editorAccessProject}
            isLegalFolderMove={isLegalFolderMove}
            depth={0}
          />
        );
      })}
      <TopLevelDropTarget onDropItem={onDropOnBranch} />
    </FolderListWrapper>
  );
};

const TopLevelDropTarget = ({
  onDropItem,
}: {
  onDropItem(
    item: BranchMeta | FolderTreeItem,
    newIndex: number,
    parentFolder?: FolderTreeItem,
  ): void;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [dropCollection, dropRef] = useDrop<
    BranchMeta | FolderTreeItem,
    { handled: boolean },
    { isHovered: boolean }
  >(
    () => ({
      accept: [BRANCH_TABLE_LIST_ITEM, BRANCH_TABLE_FOLDER_ITEM],
      canDrop: () => true,
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem: BranchMeta | FolderTreeItem, monitor) => {
        if (monitor.getDropResult()?.handled) {
          return;
        }

        onDropItem(draggedItem, Number.MAX_SAFE_INTEGER);

        return {
          handled: true,
        };
      },
    }),
    [onDropItem],
  );

  dropRef(elementRef);

  return (
    <ToplevelDropTargetDiv
      ref={elementRef}
      enableDrag={true}
      isHoveredTop={dropCollection.isHovered}
    />
  );
};

export default BranchesTable;
