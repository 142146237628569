import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React from "react";
import UserImageRound from "../UserImage/UserImageRound";
import { Column, Row } from "../General/Layout";
import { typography } from "../../styles/typography";
import { usersInOrganisationState } from "components/Organisation/state";
import { spacing1 } from "styles/space";
import { TextEllipsis } from "components/Organisation/OrganisationRightSide/style";
import { allCollaboratorsInNodeSelectorFamily } from "state/customer";
import { UserInfoRender } from "./UserInfoRender";
import { MemberLink } from "./MemberLink";
import { Email } from "./styles";
import { userOrganisationAccessSelectorFamily } from "state/user";

export const UserInfoOrganisationWithMemberLink = ({
  userId,
  size,
  style,
  nameStyle,
}: {
  userId: string;
  size?: number;
  style?: React.CSSProperties;
  nameStyle?: React.CSSProperties;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const users = useAtomValue(usersInOrganisationState(organisationId ?? ""));
  const user = users?.find((cm) => cm.user_id === userId);

  if (!organisationId || !user) {
    return null;
  }

  return (
    <Row
      alignCenter
      style={{
        ...style,
        overflow: "hidden",
      }}
    >
      <MemberLink userId={user.user_id} organisationId={organisationId}>
        <UserImageRound size={size ?? 2.4} user={user} />
      </MemberLink>
      <MemberLink
        userId={user.user_id}
        organisationId={organisationId}
        style={{
          overflow: "hidden",
        }}
      >
        <Column
          style={{
            gap: spacing1,
            overflow: "hidden",
          }}
        >
          <TextEllipsis
            style={{
              ...typography.body,
              ...nameStyle,
            }}
          >
            {user.nickname}
          </TextEllipsis>
          <Email>{user.email}</Email>
        </Column>
      </MemberLink>
    </Row>
  );
};

// Will not work for guests as they don't have access to the org members
export default function UserInfoOrganisation({
  userId,
  size,
  nameStyle,
}: {
  userId: string;
  size?: number;
  nameStyle?: React.CSSProperties;
}) {
  const organisationId = useAtomValue(organisationIdAtom);
  const organisationRole = useAtomValue(
    userOrganisationAccessSelectorFamily({
      organisationId: organisationId ?? "",
    }),
  );

  if (!organisationId || organisationRole < 0) {
    return null;
  }

  return (
    <UserInfoOrganisationInner
      userId={userId}
      size={size}
      nameStyle={nameStyle}
    />
  );
}

// Do not use this directly, use UserInfoOrganisation instead
// which will check if the user is > member in the organisation
function UserInfoOrganisationInner({
  userId,
  size,
  nameStyle,
}: {
  userId: string;
  size?: number;
  nameStyle?: React.CSSProperties;
}) {
  const organisationId = useAtomValue(organisationIdAtom);
  const users = useAtomValue(usersInOrganisationState(organisationId ?? ""));
  const user = users?.find((cm) => cm.user_id === userId);

  if (!organisationId || !user) {
    return null;
  }

  return <UserInfoRender size={size} user={user} nameStyle={nameStyle} />;
}

export function UserInfoProject({
  projectId,
  userId,
  size,
  nameStyle,
}: {
  projectId: string;
  userId: string;
  size?: number;
  nameStyle?: React.CSSProperties;
}) {
  const collaboratorsInNode = useAtomValue(
    allCollaboratorsInNodeSelectorFamily(projectId),
  );
  const user = collaboratorsInNode?.find((cm) => cm.user_id === userId);

  return <UserInfoRender size={size} user={user} nameStyle={nameStyle} />;
}
