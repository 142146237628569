import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  getGroupProjectAccess,
  getGroupsAndUsersWithAccessToNode,
  getOrganisationGroups,
  listGroupsWithAccessToNodeTree,
  listOrganisationGroupMemberships,
} from "./service";
import {
  GroupMembership,
  Group,
  GroupNodeAccess,
  UserNodeAccess,
  AccessUnionSchema,
  isGroupNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
  GroupNodeAccessWithMeta,
} from "./types";
import {
  usersInOrganisationState,
  usersNodeAccessInNodeAndSubnodesAtomFamily,
} from "components/Organisation/state";
import { ACCESS_ROLE_TO_NUMBER } from "state/user";
import { isDefined } from "utils/predicates";
import { UserAccessRole } from "types/user";
import { atomWithRefresh } from "jotai/utils";

export const organisationGroupsState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<Group[]>>(async () => {
      if (!organisationId) return [];
      return getOrganisationGroups(organisationId);
    }),
);

export const groupMembersState = atomFamily(
  ({
    organisationId,
    groupId,
  }: {
    organisationId: string | undefined;
    groupId: string;
  }) =>
    atomFromFn<Promise<GroupMembership[]>>(async (get) => {
      if (!organisationId || !groupId) return [];
      const allMemberships = await get(
        allOrganisationGroupMembershipsAtomFamily({
          organisationId,
        }),
      );
      return allMemberships.filter((m) => m.group_id === groupId);
    }),
);

export const groupProjectsState = atomFamily(
  ({
    organisationId,
    groupId,
  }: {
    organisationId: string | undefined;
    groupId: string;
  }) =>
    atomFromFn<Promise<GroupNodeAccess[]>>(async () => {
      if (!organisationId || !groupId) return [];
      return getGroupProjectAccess(organisationId, groupId);
    }),
);

export const groupsProjectsState = atomFamily(
  ({
    organisationId,
    groupIds,
  }: {
    organisationId: string | undefined;
    groupIds: string[];
  }) =>
    atom<Promise<GroupNodeAccess[]>>(async (get) => {
      if (!organisationId) return [];
      return (
        await Promise.all(
          groupIds.map((groupId) =>
            get(
              groupProjectsState({
                organisationId,
                groupId,
              }),
            ),
          ),
        )
      ).flat();
    }),
);

export const userGroupsMembershipState = atomFamily(
  ({
    organisationId,
    userId,
  }: {
    organisationId: string | undefined;
    userId: string;
  }) =>
    atomWithRefresh<Promise<GroupMembership[]>>(async (get) => {
      if (!organisationId) return [];
      const allMemberships = await get(
        allOrganisationGroupMembershipsAtomFamily({
          organisationId,
        }),
      );

      return allMemberships.filter((m) => m.user_id === userId);
    }),
);

export const userNodeAccessState = atomFamily(
  ({
    organisationId,
    userId,
  }: {
    organisationId: string | undefined;
    userId: string;
  }) =>
    atom<Promise<UserNodeAccess[]>>(async (get) => {
      if (!organisationId) return [];
      const orgNodeAccesses = await get(
        usersNodeAccessInNodeAndSubnodesAtomFamily({
          nodeId: organisationId,
          organisationId,
        }),
      );
      return orgNodeAccesses.filter((access) => access.user_id === userId);
    }),
);

export const nodeGroupUserAccessSelector = atomFamily(
  ({
    organisationId,
    nodeId,
  }: {
    organisationId: string | undefined;
    nodeId: string;
  }) =>
    atomWithRefresh<Promise<AccessUnionSchema[]>>(async () => {
      if (!organisationId) return [];
      return getGroupsAndUsersWithAccessToNode(organisationId, nodeId);
    }),
);

export const groupsInNodeAndSubnodesAtomFamily = atomFamily(
  ({ organisationId, nodeId }: { organisationId: string; nodeId: string }) =>
    atomFromFn<Promise<GroupNodeAccessWithMeta[]>>(() => {
      return listGroupsWithAccessToNodeTree(organisationId, nodeId);
    }),
);

export type UserWithAllAccesses = {
  user_id: string;
  email: string;
  nickname: string;
  picture: string;
  accesses: Array<{
    resource_name: UserAccessRole;
    group_id?: string;
    node_id?: string;
  }>;
};

export const getUsersAndAllAccessesForNode = atomFamily(
  ({
    organisationId,
    nodeId,
  }: {
    organisationId: string | undefined;
    nodeId: string;
  }) =>
    atom<Promise<UserWithAllAccesses[]>>(async (get) => {
      if (!organisationId) return [];
      const [orgUsers, groupsAndUsers] = await Promise.all([
        get(usersInOrganisationState(organisationId)),
        get(
          nodeGroupUserAccessSelector({
            organisationId,
            nodeId,
          }),
        ),
      ]);

      // Users with explicit access to this node
      const users = groupsAndUsers.filter(isUserNodeAccessWithMeta);
      // Groups with explicit access to this node
      const groups = groupsAndUsers.filter(isGroupNodeAccessWithMeta);

      // Find all users in groups
      const groupUsers = (
        await Promise.all(
          groups.map(async (group) => {
            const groupMembers = await get(
              groupMembersState({
                organisationId,
                groupId: group.group_id,
              }),
            );
            return groupMembers
              .map((member) => {
                const user = orgUsers.find((u) => u.user_id === member.user_id);
                if (!user) {
                  return undefined;
                }
                return {
                  ...member,
                  resource_name: group.resource_name,
                  // node_id: group.node_id,
                  email: user.email,
                  nickname: user.nickname,
                  picture: user.picture,
                };
              })
              .filter(isDefined);
          }),
        )
      ).flat();

      // Combine users and groupUsers
      // Put all accesses to this node for a user in one object
      const combined = [...users, ...groupUsers].reduce<
        Record<string, UserWithAllAccesses>
      >((acc, curr) => {
        acc[curr.user_id] = acc[curr.user_id] ?? {
          user_id: curr.user_id,
          email: curr.email,
          nickname: curr.nickname,
          picture: curr.picture,
          accesses: [],
        };
        acc[curr.user_id].accesses.push(curr);
        return acc;
      }, {});

      const combinedArray = Object.values(combined);

      // Sort accesses by resource_name
      combinedArray.forEach((user) => {
        user.accesses = user.accesses.sort((a, b) => {
          if (
            ACCESS_ROLE_TO_NUMBER[b.resource_name] ===
            ACCESS_ROLE_TO_NUMBER[a.resource_name]
          ) {
            if (a.group_id && b.group_id) {
              return 0;
            }
            return a.group_id ? 1 : -1;
          }

          return (
            ACCESS_ROLE_TO_NUMBER[b.resource_name] -
            ACCESS_ROLE_TO_NUMBER[a.resource_name]
          );
        });
      });

      //Sort combinedArray by access level
      // Put group members in the bottom of each access level
      combinedArray.sort((a, b) => {
        if (
          ACCESS_ROLE_TO_NUMBER[a.accesses[0].resource_name] ===
          ACCESS_ROLE_TO_NUMBER[b.accesses[0].resource_name]
        ) {
          if (a.accesses[0].group_id && b.accesses[0].group_id) {
            return 0;
          }
          return a.accesses[0].group_id ? 1 : -1;
        }

        return (
          ACCESS_ROLE_TO_NUMBER[b.accesses[0].resource_name] -
          ACCESS_ROLE_TO_NUMBER[a.accesses[0].resource_name]
        );
      });

      return combinedArray;
    }),
);

/**
 * Gets all group memberships in this organisation
 */
export const allOrganisationGroupMembershipsAtomFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<GroupMembership[]>>(async () => {
      if (!organisationId) return [];
      const response = await listOrganisationGroupMemberships(organisationId);
      return response;
    }),
);
