export function bboxOverlaps(a: number[], b: number[]): boolean {
  // Widths and heights
  const aw = a[2] - a[0];
  const ah = a[3] - a[1];
  const bw = b[2] - b[0];
  const bh = b[3] - b[1];

  // Centers
  const axc = (a[0] + a[2]) / 2;
  const ayc = (a[1] + a[3]) / 2;
  const bxc = (b[0] + b[2]) / 2;
  const byc = (b[1] + b[3]) / 2;

  // Distance between centers
  const dx = Math.abs(axc - bxc);
  const dy = Math.abs(ayc - byc);

  // Dimensions separately.  The box overlaps iff. the distance between the
  // centers is <= the sum of the lengths of the boxes (in that dimension).
  return dx <= (aw + bw) / 2 && dy <= (ah + bh) / 2;
}
