import { Column, Row } from "components/General/Layout";
import { colors } from "styles/colors";
import DescriptionModal from "../../DescriptionModal";
import ComponentLastChanged from "../../SettingsUsage/ComponentLastChanged";
import { HeaderContainer, HeaderName } from "../../shared";
import { CableType, ExportSystemType } from "services/cableTypeService";
import { ChangelogCategory } from "components/InputChangelog/types";
import { Tag } from "components/General/Tag";
import ProjectResourceUsage from "components/ConfigurationModal/SettingsUsage/ProjectResourceUsage";

interface HeaderProps {
  cable: CableType;
  nodeId: string;
  disabled: boolean;
  description: string;
  handleUpdateCableDescription: (description: string) => void;
  changelogId: string;
  category: ChangelogCategory;
}

export const EXPORT_SYSTEM_TYPE_NAMES: Record<ExportSystemType, string> = {
  [ExportSystemType.HVAC]: "HVAC",
  [ExportSystemType.HVDC]: "HVDC",
};

const CableHeader = ({
  cable,
  nodeId,
  disabled,
  description,
  changelogId,
  category,
  handleUpdateCableDescription,
}: HeaderProps) => {
  return (
    <>
      <HeaderContainer>
        <Column
          style={{
            width: "100%",
          }}
        >
          <HeaderName>
            {cable.name}{" "}
            <span
              style={{
                color: colors.textDisabled,
              }}
            >
              ({cable.voltage}kV)
            </span>
          </HeaderName>
          <ComponentLastChanged
            resourceId={cable.id}
            changelogId={changelogId}
            nodeId={nodeId}
            category={category}
          />
          <Row>
            {cable.exportCableType && (
              <Tag
                background={colors.grey100}
                text={`Type: ${EXPORT_SYSTEM_TYPE_NAMES[cable.exportCableType]}`}
              />
            )}
            <ProjectResourceUsage
              resourceId={cable.id}
              resourceType={"CABLE"}
            />
          </Row>
        </Column>
        <DescriptionModal
          disabled={disabled}
          defaultValue={description}
          updateDescription={handleUpdateCableDescription}
          subtitle={
            <div>
              <p>
                The description will be visible for Admins and Editors in
                projects with access to this cable.
              </p>
            </div>
          }
        />
      </HeaderContainer>
    </>
  );
};

export default CableHeader;
