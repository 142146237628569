import { useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
} from "../../../../constants/cabling";
import { DEFAULT_COLOR } from "../../../ColorSelector/ColorSelector";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import {
  LineStringFeature,
  LineStringFeatureType,
} from "../../../../types/feature";
import {
  ANCHOR_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
} from "../../../../constants/projectMapView";
import { trackCanvasOption } from "../../MenuTracking";
import { useSnapFeatures } from "../../../MapControls/useUpdateSnapPoints";
import { isMooringLineSnapPoint } from "../../../../utils/predicates";
import { snapToClosestFeature } from "../../../MapControls/CustomModes/lib/snapping";
import { useProjectElementsCrud } from "../../../ProjectElements/useProjectElementsCrud";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../../../state/pathParams";
import { toastMessagesAtom } from "../../../../state/toast";
import { createNewAnchorLineFeatures, getReadableErrorMessage } from "./utils";
import { useSetPropertyOnProjectFeatures } from "../../../../hooks/useSetPropertyOnProjectFeatures";
import { MenuItem } from "../../../General/Menu";
import { MenuButton } from "../../../General/MenuButton";
import { removeHover, setHover } from "components/Mapbox/utils";
import { mapAtom } from "../../../../state/map";
import { colors } from "../../../../styles/colors";
import { getSomeCableColor } from "../../../Cabling/CablingMapController/Render";
import { parkSourceId } from "components/Mapbox/constants";
import Tooltip from "components/General/Tooltip";
import { TypeDot, TypeLineDot } from "components/General/Icons";
import useCreateCableSlicesBetweenTurbinesAndSubstations from "components/MapControls/Edit/Callbacks/useCreateCableSlicesBetweenTurbinesAndSubstations";
import { useAtomValue } from "jotai";
import { parksFamily } from "state/jotai/park";
import { useJotaiCallback } from "utils/jotai";
import { featuresListAtom } from "state/jotai/features";

const lineStringTypeToName: Record<LineStringFeatureType | "other", string> = {
  [EXPORT_CABLE_PROPERTY_TYPE]: "Export cable",
  [CABLE_PROPERTY_TYPE]: "Cable",
  [MOORING_LINE_PROPERTY_TYPE]: "Mooring line",
  other: "Other",
};

export default function LineStringTypeSelector({
  selections,
  disabled,
  selectedType,
}: {
  selections: LineStringFeature[];
  disabled: boolean;
  selectedType?: LineStringFeatureType | "other";
}) {
  const parkId = useAtomValue(parkIdAtom);

  return (
    <ParkLineStringTypeSelectorInner
      parkId={parkId}
      selections={selections}
      disabled={disabled}
      selectedType={selectedType}
    />
  );
}

function ParkLineStringTypeSelectorInner({
  parkId,
  selections,
  disabled,
  selectedType,
}: {
  parkId?: string;
  selections: LineStringFeature[];
  disabled: boolean;
  selectedType?: LineStringFeatureType | "other";
}) {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const map = useAtomValue(mapAtom);
  const parks = useAtomValue(
    parksFamily({
      branchId: branchId ?? "",
    }),
  );

  const createCableSlices = useCreateCableSlicesBetweenTurbinesAndSubstations();
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const snapPoints = useSnapFeatures();

  const setProperties = useSetPropertyOnProjectFeatures();

  const [hoveredParkId, setHoveredParkId] = useState<string | undefined>();

  useEffect(() => {
    if (map) {
      if (hoveredParkId) {
        setHover(map, parkSourceId, hoveredParkId);
        return () => {
          removeHover(map, parkSourceId, hoveredParkId);
        };
      }
    }
  }, [hoveredParkId, map]);

  const changeToCable = useCallback(
    async (parkId: string) => {
      const createdCableSliceResults = await createCableSlices(
        selections,
        parkId,
      );

      const createdCables = createdCableSliceResults[0];
      const updatedCables = createdCableSliceResults[1];

      updateFeatures({
        add: createdCables,
        update: updatedCables,
        remove: selections.map((s) => s.id),
      });
    },
    [createCableSlices, selections, updateFeatures],
  );

  const changeToMooring = useJotaiCallback(
    async (get, _, parkId: string) => {
      const parkSnapPoints = snapPoints.filter((sp) =>
        sp.parentIds?.includes(parkId),
      );

      const projectFeatures = await get(featuresListAtom);
      const parkFeatures = projectFeatures.filter((f) =>
        f.properties.parentIds?.includes(parkId),
      );

      const mooringLineSnapPoints = parkSnapPoints.filter(
        isMooringLineSnapPoint,
      );
      const connectionsToMooringCableCompatableFeatures = selections.map(
        (s) => {
          const fromSnap = snapToClosestFeature(
            mooringLineSnapPoints,
            s.geometry.coordinates[0],
            0.01,
            0.1,
          );
          const toSnap = snapToClosestFeature(
            mooringLineSnapPoints,
            s.geometry.coordinates[s.geometry.coordinates.length - 1],
            0.01,
            0.1,
          );

          if (
            fromSnap?.featureType &&
            toSnap?.featureType &&
            fromSnap.featureType !== toSnap.featureType
          ) {
            // either from or to should be an anchor ...
            const anchorId =
              fromSnap.featureType === ANCHOR_PROPERTY_TYPE
                ? fromSnap.featureId
                : toSnap.featureId;

            // ... the other should be a target (turbine)
            const targetId =
              fromSnap.featureId === anchorId
                ? toSnap.featureId
                : fromSnap.featureId;
            return {
              id: s.id,
              anchorId,
              targetId,
            };
          }
          return {
            id: s.id,
            anchorId: undefined,
            targetId: undefined,
          };
        },
      );

      if (
        !connectionsToMooringCableCompatableFeatures.every(
          (s) => s.anchorId && s.targetId,
        )
      ) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Not all lines can be connected to an anchor and a turbine",
            timeout: 5000,
            type: "error",
          },
        ]);
        return;
      }

      try {
        const updatedFeatures = createNewAnchorLineFeatures(
          parkId,
          parkFeatures,
          selections,
          connectionsToMooringCableCompatableFeatures,
        );
        updateFeatures({
          update: updatedFeatures,
        });
      } catch (e) {
        if (e instanceof Error) {
          const readableError = getReadableErrorMessage(e);
          setToastMessages((tm) => [
            ...tm,
            {
              text: readableError,
              timeout: 5000,
              type: "error",
            },
          ]);
        }
      }
    },
    [selections, setToastMessages, snapPoints, updateFeatures],
  );

  const ids = useMemo(() => selections.map((s) => s.id), [selections]);

  const onSelectItem = useCallback(
    (
      item:
        | {
            type: "other";
          }
        | {
            type: LineStringFeatureType;
            parkId: string;
          },
    ) => {
      trackCanvasOption("change-feature-type", {
        projectId,
        branchId,
      });
      switch (item.type) {
        case "other": {
          setProperties(ids, {
            type: undefined,
            color: DEFAULT_COLOR,
            name: "LineString",
          });
          return;
        }
        case EXPORT_CABLE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: EXPORT_CABLE_PROPERTY_TYPE,
            name: "Export cable",
            parentIds: [item.parkId],
          });
          return;
        }
        case CABLE_PROPERTY_TYPE: {
          changeToCable(item.parkId);
          return;
        }
        case MOORING_LINE_PROPERTY_TYPE: {
          changeToMooring(item.parkId);
          return;
        }
      }
    },
    [projectId, branchId, setProperties, ids, changeToCable, changeToMooring],
  );

  return (
    <MenuButton
      side="right"
      offset={[-12, 0]}
      icon={<ArrowRightIcon />}
      iconOpen={<ArrowDownIcon />}
      buttonStyle={{
        border: "none",
        flexDirection: "row-reverse",
        height: "fit-content",
        justifyContent: "space-between",
        padding: 0,
        gap: "1.2rem",
      }}
      buttonType="dropdown"
      buttonText={lineStringTypeToName[selectedType ?? "other"]}
      disabled={disabled}
    >
      <MenuItem
        name={"Other"}
        onClick={() =>
          onSelectItem({
            type: "other",
          })
        }
        icon={<TypeDot dotColor={colors.other} />}
      />
      {parkId ? (
        <MenuItem
          name={lineStringTypeToName[EXPORT_CABLE_PROPERTY_TYPE]}
          onClick={() =>
            onSelectItem({
              type: EXPORT_CABLE_PROPERTY_TYPE,
              parkId,
            })
          }
          icon={<TypeLineDot dotColor={colors.exportCableMissingType} />}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={lineStringTypeToName[EXPORT_CABLE_PROPERTY_TYPE]}
            icon={<TypeLineDot dotColor={colors.exportCableMissingType} />}
            disabled={parks.length === 0}
            style={{
              width: "100%",
            }}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: EXPORT_CABLE_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
      {parkId ? (
        <MenuItem
          name={lineStringTypeToName[CABLE_PROPERTY_TYPE]}
          onClick={() =>
            onSelectItem({
              type: CABLE_PROPERTY_TYPE,
              parkId,
            })
          }
          icon={<TypeLineDot dotColor={getSomeCableColor()} />}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={lineStringTypeToName[CABLE_PROPERTY_TYPE]}
            icon={<TypeLineDot dotColor={getSomeCableColor()} />}
            disabled={parks.length === 0}
            style={{
              width: "100%",
            }}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: CABLE_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
      {parkId ? (
        <MenuItem
          name={lineStringTypeToName[MOORING_LINE_PROPERTY_TYPE]}
          onClick={() =>
            onSelectItem({
              type: MOORING_LINE_PROPERTY_TYPE,
              parkId,
            })
          }
          icon={<TypeLineDot dotColor={colors.mooringLine} />}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={lineStringTypeToName[MOORING_LINE_PROPERTY_TYPE]}
            icon={<TypeLineDot dotColor={colors.mooringLine} />}
            disabled={parks.length === 0}
            style={{
              width: "100%",
            }}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: MOORING_LINE_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
    </MenuButton>
  );
}
