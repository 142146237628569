import React from "react";
import { Column } from "components/General/Layout";
import {
  TitleContainer,
  HorizontalLine,
  SecondaryText,
  Subtitle,
} from "components/SettingsV2/Shared/styles";
import { TextArea } from "components/General/Input";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg?react";

interface FreeTextProps {
  showFreeText: boolean;
  setShowFreeText: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const FreeText = ({
  showFreeText,
  setShowFreeText,
  value,
  onChange,
  disabled,
}: FreeTextProps) => {
  if (showFreeText) {
    return (
      <Column style={{ width: "100%" }}>
        <TitleContainer>
          <Subtitle>Free text</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <TextArea
          style={{
            resize: "vertical",
            width: "40rem",
          }}
          rows={5}
          disabled={disabled}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </Column>
    );
  }

  return (
    <>
      <Button
        buttonType="secondary"
        icon={<AddIcon />}
        disabled={disabled}
        onClick={() => setShowFreeText(true)}
      />
      <TitleContainer>
        <SecondaryText>Add free text</SecondaryText>
        <HorizontalLine />
      </TitleContainer>
    </>
  );
};

export default FreeText;
