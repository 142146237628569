import { displayLabelPropertyName } from "@constants/canvas";
import { FillPaint, LinePaint, SymbolLayerSpecification } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { colors } from "styles/colors";
import { ParkFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  parkSourceId,
  parkLayerSymbolId,
  parkLayerOutlineId,
  parkLayerFillId,
} from "./constants";
import { atom, useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { READ, caseexpr } from "./defaults";
import {
  useAugmentFeaturesWithColorValues,
  useUpdateLayerLayout,
  useUpdateLayerStyle,
} from "business/style/hooks";
import { useAtomUnwrap } from "utils/jotai";
import { parkStylesAtom } from "business/style/feature/park";
import { stylingLabelSelector } from "business/style/state";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const parkFillPaintAtom = atom<FillPaint>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore)
    return {
      "fill-color": colors.onElParkSurface,
      "fill-opacity": caseexpr({
        fallback: 0.2,
        selected: 0.3,
        hover: 0.3,
      }),
    };
  return {
    "fill-color": colors.park,
    "fill-opacity": caseexpr({
      fallback: 0.2,
      selected: 0.3,
      hover: 0.3,
    }),
  };
});

const parkLinePaintAtom = atom<LinePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "line-color": onshore
      ? caseexpr({
          locked: colors.lockedFeatureOutline,
          fallback: colors.onElParkBorder,
          selected: colors.onElParkBorderSel,
          active: colors.onElParkBorderSel,
          state: { borderColor: READ },
        })
      : caseexpr({
          locked: colors.lockedFeatureOutline,
          fallback: colors.blue800,
          selected: colors.white,
          state: { borderColor: READ },
        }),
    "line-width": caseexpr({
      fallback: 1,
      hover: 2,
      selected: 2,
      active: 2,
      state: { borderColor: 2 },
    }),
  };
});

const useParkNameSymbolLayer: () => Omit<
  SymbolLayerSpecification,
  "id" | "source"
> = () => {
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  return useMemo(
    () => ({
      type: "symbol",
      minzoom: 5,
      layout: {
        "symbol-placement": "point",
        "text-field": "{name}",
        "text-size": 12,
        "symbol-spacing": 300,
        "text-keep-upright": true,
      },
      paint: {
        ...defaultMapTextPaint,
        "text-opacity": 0.8,
        "text-halo-blur": 1,
        "text-halo-width": 2,
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
    }),
    [defaultMapTextPaint],
  );
};

export const RenderParks = ({
  parks,
  map,
  showSelectionHighlighter,
}: {
  parks: ParkFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const parkFillPaint = useAtomValue(parkFillPaintAtom);
  const parkLinePaint = useAtomValue(parkLinePaintAtom);
  const [sourceAdded, setSourceAdded] = useState<Boolean>(false);
  const parkNameSymbolLayer = useParkNameSymbolLayer();

  const featureIds = useMemo(() => parks.map((f) => f.id), [parks]);

  useEffect(() => {
    map.addSource(parkSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    setSourceAdded(true);
    return () => {
      safeRemoveLayer(map, parkLayerSymbolId);
      safeRemoveLayer(map, parkLayerOutlineId);
      safeRemoveLayer(map, parkLayerFillId);
      map.removeSource(parkSourceId);
      setSourceAdded(false);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: parkLayerFillId,
      type: "fill",
      source: parkSourceId,
      paint: parkFillPaint,
    });
    addLayer(map, {
      id: parkLayerOutlineId,
      type: "line",
      source: parkSourceId,
      paint: parkLinePaint,
    });
    addLayer(map, {
      ...parkNameSymbolLayer,
      id: parkLayerSymbolId,
      source: parkSourceId,
    });
    return () => {
      safeRemoveLayer(map, parkLayerSymbolId);
      safeRemoveLayer(map, parkLayerOutlineId);
      safeRemoveLayer(map, parkLayerFillId);
    };
  }, [map, parkFillPaint, parkLinePaint, parkNameSymbolLayer]);

  const paint = useAtomUnwrap(parkStylesAtom);
  useUpdateLayerStyle(map, parkLayerFillId, paint?.fill);
  useUpdateLayerStyle(map, parkLayerOutlineId, paint?.line);
  const layout = useAtomUnwrap(stylingLabelSelector("parks"));
  useUpdateLayerLayout(map, parkLayerSymbolId, layout?.layout);

  const augmented = useAugmentFeaturesWithColorValues("parks", parks);
  useEffect(() => {
    if (!sourceAdded) return;
    const source = map.getSource(parkSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(augmented),
    });
  }, [map, augmented, sourceAdded]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={parkSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
