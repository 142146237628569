import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { UserAccessRole } from "../../../types/user";
import { nodeGroupUserAccessSelector } from "../Groups/state";
import {
  addOrUpdateUserNodeAccess,
  deleteUserNodeAccess,
} from "services/userService";
import useRefreshUsersInNodeAndSubnodes from "components/Organisation/useRefreshUsersInNodeAndSubnodes";

const useUserNodeAccessCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshUsersInNodeAndSubnodes = useRefreshUsersInNodeAndSubnodes();

  const addOrUpdate = useJotaiCallback(
    async (_get, set, userId: string, nodeId: string, role: UserAccessRole) => {
      return addOrUpdateUserNodeAccess(organisationId, userId, nodeId, role)
        .then(() => {
          // refresh(admin_userNodeAccessState({ organisationId, userId }));
          set(
            nodeGroupUserAccessSelector({
              organisationId,
              nodeId,
            }),
          );
          refreshUsersInNodeAndSubnodes(organisationId, nodeId);
        })
        .catch(() => {});
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  const addOrUpdateMultiple = useJotaiCallback(
    async (
      _get,
      set,
      userIds: string[],
      nodeId: string,
      role: UserAccessRole,
    ) => {
      await Promise.all(
        userIds.map((userId) => {
          // refresh(admin_userNodeAccessState({ organisationId, userId }));
          return addOrUpdateUserNodeAccess(
            organisationId,
            userId,
            nodeId,
            role,
          );
        }),
      );
      set(
        nodeGroupUserAccessSelector({
          organisationId,
          nodeId,
        }),
      );
      refreshUsersInNodeAndSubnodes(organisationId, nodeId);
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  const remove = useJotaiCallback(
    async (_get, set, userId: string, nodeId: string) => {
      return deleteUserNodeAccess(organisationId, userId, nodeId)
        .then(() => {
          // refresh(admin_userNodeAccessState({ organisationId, userId }));
          set(
            nodeGroupUserAccessSelector({
              organisationId,
              nodeId,
            }),
          );
          refreshUsersInNodeAndSubnodes(organisationId, nodeId);
        })
        .catch(() => {});
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  return {
    addOrUpdate,
    addOrUpdateMultiple,
    remove,
  };
};

export default useUserNodeAccessCrud;
