import { BufferModalType } from "../components/BufferModal/BufferModal";
import { FilterExternalDataLayersModalType } from "../components/FilterExternalDataLayers/FilterExternalDataLayers";
import { UserProfileModalType } from "../components/UserProfileModal/UserProfileModal";
import { CompareParksModalType } from "../components/CompareParksModal/CompareParksModalType";
import { RenameParkModalType } from "../components/RenameModal/RenameParkModal";
import { CopyCollectionModalType } from "../components/CopyCollectionModal/CopyCollectionModal";
import {
  TeamConfigurationModalType,
  ConfigurationModalType,
  ProjectOverviewModalType,
  MenuItems,
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
  PublicModeModalTypeV2,
} from "./configuration";
import { LoggedInUser } from "../types/user";
import { CustomerPersona } from "../types/customer";
import { CustomDataEntry } from "./../types/layers";
import { FeatureType, ProjectFeature } from "../types/feature";
import { ValidationWarningModalType } from "../components/ValidationWarnings/constants";
import { reloadForNewVersionModalType } from "../components/ReloadForNewVersionModal/ReloadForNewVersionModal";
import { AddDataLayersModalType } from "../components/DataLayersModal/DataLayersModal";
import {
  UploadModalType,
  UploadFileType,
} from "../components/UploadModal/UploadModal";
import { OrgSettingsModalType } from "../components/Organisation/OrgSettings/OrgSettingsModal";
import { AddLayerSourceInternalModalType } from "../components/AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import { AddNewDataSourceModalToProjectType } from "../components/LayerList/AddNewSourceModal/AddNewSourceToProjectModal";
import { KeyboardShortcutsModalType } from "components/LearnVind/KeyboardShortcutsModal";
import { VideoModalType } from "components/LearnVind/VideoModal";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { AddCustomCRSModalType } from "components/CustomCRSDropdown/CustomCRSDropdown";
import { UploadOrganisationDataLayerModalType } from "components/Organisation/Library/dataLibrary/modals/UploadOrganisationLayerModal";
import { EditVectordataType } from "components/Organisation/Library/dataLibrary/Edit/EditVectordata";
import { UploadOrganisationLayerFromCoordinatesModalType } from "components/Organisation/Library/dataLibrary/modals/UploadOrganisationLayerFromCoordinatesModal";
import { AddLayersFromAllDataListModalType } from "components/Organisation/Library/dataLibrary/modals/AddLayersFromAllDataListModal";
import { NewArchivedVersionModalType } from "components/Design/ArchiveProjectVersion/Modal/NewModal";
import { ArchivedVersionSuccessModalType } from "components/Design/ArchiveProjectVersion/Modal/SuccessModal";
import { SetStateAction, atom } from "jotai";
import { ProcurementModalType } from "components/Organisation/Library/financial/ProcurementCosts/ProcurementCostsModal";
import { DeleteTurbineModalType } from "components/NotificationSystem/ReplaceTurbine/DeleteTurbineModal";
import { ExtractMapDataModalType } from "components/ExtractMapDataModal/ExtractMapDataModal";
import { GenerateNamesModalType } from "components/GenerateFeatureNamesModal/GenerateFeatureNamesModal";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import {
  ComponentsPreviewType,
  ComponentType,
} from "components/ComponentsPreview/state";
import { ResourceType } from "components/ConfigurationModal/Components/types";

export const DashboardModalType = "DashboardModal";

export type ModalType = (
  | ArchivedVersionSuccessModalType
  | NewArchivedVersionModalType
  | { modalType: typeof OrgSettingsModalType }
  | {
      modalType: typeof EditVectordataType;
      metadata: {
        layerId: string;
      };
    }
  | { modalType: typeof AddCustomCRSModalType }
  | { modalType: typeof reloadForNewVersionModalType }
  | {
      modalType: typeof AddNewDataSourceModalToProjectType;
      metadata?: CustomDataEntry;
    }
  | {
      modalType: typeof ExtractMapDataModalType;
      metadata: {
        selection: Feature<Polygon | MultiPolygon>;
      };
    }
  | { modalType: typeof ConfigurationModalType }
  | {
      modalType: typeof ProjectConfigModalTypeV2;
      metadata?: { selectedMenuId?: string; selectedConfigId?: string };
    }
  | {
      modalType: typeof FeatureSettingsModalTypeV2;
      metadata?: { selectedMenuId?: string };
    }
  | {
      modalType: typeof PublicModeModalTypeV2;
      metadata?: { selectedMenuId?: string };
    }
  | {
      modalType: typeof FilterExternalDataLayersModalType;
      metadata: {
        sourceId: string;
        sourceName: string;
        property: string;
      };
    }
  | { modalType: typeof ProjectOverviewModalType }
  | { modalType: typeof RenameParkModalType }
  | {
      modalType: typeof TeamConfigurationModalType;
      metadata?: {
        tab: MenuItems;
      };
    }
  | { modalType: typeof ValidationWarningModalType }
  | { modalType: typeof CompareParksModalType }
  | {
      modalType: typeof CopyCollectionModalType;
      metadata: {
        collectionId: string;
      };
    }
  | { modalType: typeof AddDataLayersModalType }
  | {
      modalType: typeof AddLayerSourceInternalModalType;
    }
  | {
      modalType: typeof DashboardModalType;
      metadata?: {
        id?: string;
        branchId?: string;
        parkId?: string;
        configurationId?: string;
        windConfigurationId?: string;
      };
    }
  | {
      modalType: typeof KeyboardShortcutsModalType;
    }
  | {
      modalType: typeof VideoModalType;
      metadata: {
        title: string;
        videoId: string;
      };
    }
  | {
      modalType: typeof DownloadCustomCRSModalType;
      metadata: {
        featureIds: string[];
        name: string;
        loadingId: string;
        types?: FeatureType[];
      };
    }
  | {
      modalType: typeof AddLayersFromAllDataListModalType;
      metadata: {
        packageId: string;
      };
    }
  | {
      modalType: typeof ProcurementModalType;
    }
  | {
      modalType: typeof GenerateNamesModalType;
      metadata: {
        featureIds: string[];
      };
    }
) & {
  backTo?: ModalType;
};

/** Convenience type wrapper to name a {@link ModalType} */
export type Modal<T> = ModalType & { modalType: T };
const _modalTypeOpenAtom = atom<ModalType | undefined>(undefined);
export const modalTypeOpenAtom = atom(
  (get) => get(_modalTypeOpenAtom),
  (get, set, update: SetStateAction<ModalType | undefined>) => {
    const upd =
      typeof update === "function" ? update(get(_modalTypeOpenAtom)) : update;
    // When trying to close the modal and it has a backTo value, set it to the backTo value
    if (!upd) {
      const old = get(_modalTypeOpenAtom);
      if (old && old.backTo) {
        set(_modalTypeOpenAtom, old.backTo);
        return;
      }
    }
    set(_modalTypeOpenAtom, upd);
  },
);

type MidScreenModalType = (
  | {
      modalType: typeof BufferModalType;
      metadata?: {
        selection: ProjectFeature | ProjectFeature[];
      };
    }
  | {
      modalType: typeof UserProfileModalType;
      metadata: {
        user: LoggedInUser | CustomerPersona;
        tab?: "settings" | "notifications";
      };
    }
  | {
      modalType: typeof UploadOrganisationDataLayerModalType;
      metadata?: {
        fileIdToReplace?: string;
        addToPackageId?: string;
      };
    }
  | {
      modalType: typeof UploadOrganisationLayerFromCoordinatesModalType;
      metadata?: {
        addToPackageId?: string;
      };
    }
  | {
      modalType: typeof UploadModalType;
      metadata?: {
        preSelectedFileType?: UploadFileType;
        preSelectedFeatureType?: ProjectFeature["properties"]["type"];
        droppedFiles?: File[];
      };
    }
  | {
      modalType: typeof ComponentsPreviewType;
      metadata?: {
        componentType: ComponentType;
        isLibraryResource: boolean;
        inLibraryContext: boolean;
      };
    }
  | {
      modalType: typeof DeleteTurbineModalType;
      metadata: {
        turbineTypeId: string;
        noUsage?: boolean;
        name: string;
        onConfirm: () => void;
      };
    }
  | {
      modalType: typeof DuplicateComponentModalType;
      metadata: {
        componentType: ResourceType;
        defaultName: string;
        description?: string;
        onDuplicate: (name: string) => void;
      };
    }
) & {
  backTo?: MidScreenModalType;
};

export type MidScreenModalTypeType = MidScreenModalType["modalType"];

/** Convenience type wrapper to name a {@link ModalType} */
export type MidScreenModal<T> = MidScreenModalType & { modalType: T };
const _midScreenModalTypeOpenAtom = atom<MidScreenModalType | undefined>(
  undefined,
);
export const midScreenModalTypeOpenAtom = atom(
  (get) => get(_midScreenModalTypeOpenAtom),
  (get, set, update: SetStateAction<MidScreenModalType | undefined>) => {
    const upd =
      typeof update === "function"
        ? update(get(_midScreenModalTypeOpenAtom))
        : update;
    // When trying to close the modal and it has a backTo value, set it to the backTo value
    if (!upd) {
      const old = get(_midScreenModalTypeOpenAtom);
      if (old && old.backTo) {
        set(_midScreenModalTypeOpenAtom, old.backTo);
        return;
      }
    }
    set(_midScreenModalTypeOpenAtom, upd);
  },
);
