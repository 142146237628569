import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useEffect } from "react";
import { Column, Row } from "components/General/Layout";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import ListViewIcon from "@icons/16/ListView.svg";
import UploadIcon from "@icons/24/Upload.svg";
import { spacing1, spacing6 } from "styles/space";
import { MenuItem } from "components/General/Menu";
import SplitButton from "components/General/SplitButton";
import { modalTypeOpenAtom, midScreenModalTypeOpenAtom } from "state/modal";
import { Mixpanel } from "mixpanel";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";

import { organisationLibraryPackageSelector } from "../state";
import { AddLayersFromAllDataListModalType } from "../modals/AddLayersFromAllDataListModal";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { UploadOrganisationLayerFromCoordinatesModalType } from "../modals/UploadOrganisationLayerFromCoordinatesModal";
import { DataLibraryNewEntry } from "../DataLibraryTab.style";
import PackageTable from "./PackageTable";
import { useSetAtom } from "jotai";
import LayersIcon from "@icons/24/Layers.svg";

const AddLayerToLibrarySplitButton = ({ packageId }: { packageId: string }) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  return (
    <SplitButton
      buttonType="secondary"
      text="Add"
      icon={<LayersIcon />}
      onClick={() => {
        Mixpanel.track_old("Add library layer to package click DEFAULT", {});
        setModalTypeOpen({
          modalType: AddLayersFromAllDataListModalType,
          metadata: {
            packageId,
          },
        });
      }}
    >
      <MenuItem
        name="From All data"
        defaultButton={true}
        onClick={() => {
          Mixpanel.track_old(
            "Add library layer to package click From all data",
            {},
          );
          setModalTypeOpen({
            modalType: AddLayersFromAllDataListModalType,
            metadata: {
              packageId,
            },
          });
        }}
        icon={<ListViewIcon />}
      />
      <MenuItem
        name="From file"
        defaultButton={false}
        onClick={() => {
          Mixpanel.track_old(
            "Add library layer to package click From file",
            {},
          );
          setMidScreenModalTypeOpen({
            modalType: UploadOrganisationDataLayerModalType,
            metadata: {
              addToPackageId: packageId,
            },
          });
        }}
        icon={<UploadIcon />}
      />
      {}
      {}
      <MenuItem
        name="From coordinates"
        onClick={() => {
          Mixpanel.track_old(
            "Add library layer to package click From coordinates",
            {},
          );
          setMidScreenModalTypeOpen({
            modalType: UploadOrganisationLayerFromCoordinatesModalType,
            metadata: {
              addToPackageId: packageId,
            },
          });
        }}
        icon={<CoordinatesAltIcon />}
      />
      {}
      {}
    </SplitButton>
  );
};

const PackageSection = ({
  packageId,
  setActivePackageId,
}: {
  packageId: string;
  setActivePackageId?(packageId: string | undefined): void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const pkg = useAtomValue(
    organisationLibraryPackageSelector({
      packageId,
    }),
  );

  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  // Reset active package id if package is not found
  useEffect(() => {
    if (!pkg) {
      setActivePackageId && setActivePackageId(undefined);
    } else {
      setContent((curr) => {
        if (curr?.type === "resource") {
          return {
            type: "resource",
            id: pkg.id,
          };
        }
        return curr;
      });
    }
  }, [pkg, setActivePackageId, setContent]);

  if (!pkg) {
    return null;
  }

  return (
    <Column
      style={{
        gap: "0.8rem",
        overflowY: "hidden",
        flexGrow: 1,
        flexShrink: 0,
        height: "100%",
      }}
    >
      <Row
        alignCenter
        style={{
          gap: spacing6,
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: spacing1,
          }}
        >
          <AddLayerToLibrarySplitButton packageId={packageId} />
        </div>
      </Row>
      {pkg.layerIds.length > 0 ? (
        <>
          <PackageTable packageId={pkg.id} layerIds={pkg.layerIds} />
        </>
      ) : (
        <div
          style={{
            width: "25rem",
          }}
        >
          <DataLibraryNewEntry
            icon={<UploadIcon />}
            title="Add files"
            text="Add data directly to a package"
            actionButton={
              <AddLayerToLibrarySplitButton packageId={packageId} />
            }
          />
        </div>
      )}
    </Column>
  );
};

export default PackageSection;
