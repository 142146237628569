import { useRef, useState, useEffect } from "react";
import { atom, useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import { z } from "zod";
import * as THREE from "three";
import { CenterContainer, SafeCard } from "./Base";
import { atomLocalStorage } from "utils/jotai";
import useResizeThree from "../../ViewAnalyses/ViewToPark/ThreeContext/useResizeThree";
import { ThreeCoreViewPark } from "../../ViewAnalyses/ViewToPark/ThreeContext/useCreateThreeCore";
import { With } from "../../../utils/utils";
import { Column, Row } from "../../General/Layout";
import Dropdown from "../../Dropdown/Dropdown";
import { spaceMedium } from "../../../styles/space";
import { Label } from "../../General/Form";
import { MenuItemDiv } from "../../General/Menu";
import { colors } from "../../../styles/colors";
import { Slider } from "../../General/Slider";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import {
  foundationMostCommonInParkFamily,
  foundationTypesInParkFamily,
} from "state/jotai/foundation";
import { useDashboardContext } from "../Dashboard";
import { createFoundationModel } from "3d/models";
import {
  defaultBladeMaterial,
  defaultNacelleMaterial,
  defaultTowerMaterial,
} from "3d/materials";
import {
  bladeTransform,
  nacelleTransform,
  towerTransform,
  turbineModelAtom,
} from "3d/turbine_glb";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { FoundationType } from "types/foundations";

const turbineMeshAtom = atom(async (get) => {
  const gltf = await get(turbineModelAtom);
  const group = new THREE.Group();
  group.name = "turbine.glb group";

  gltf.scene.traverse((object) => {
    if (object instanceof THREE.Mesh) {
      switch (object.name) {
        case "blade":
          {
            for (let k = 0; k < 3; k++) {
              const o = object.clone();
              o.material = defaultBladeMaterial();
              o.matrixAutoUpdate = false;
              o.matrix = bladeTransform({ angle: 0 + 120 * k });
              o.name = `blade-${k}`;
              group.add(o);
            }
          }
          break;
        case "tower":
          {
            const obj = object.clone();
            obj.material = defaultTowerMaterial();
            obj.matrixAutoUpdate = false;
            obj.matrix = towerTransform({});
            obj.name = "tower";
            group.add(obj);
          }
          break;
        case "nacelle":
          {
            const obj = object.clone();
            obj.material = defaultNacelleMaterial();
            obj.matrixAutoUpdate = false;
            obj.matrix = nacelleTransform({});
            obj.name = "nacelle";
            group.add(obj);
          }
          break;
      }
    }
  });

  return group;
});

type State = With<ThreeCoreViewPark, "camera" | "renderer" | "scene"> & {
  models: (THREE.Mesh | THREE.Group)[];
};
const ThreeDFoundation = () => {
  const { park, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const foundationTypesInPark = useAtomValue(
    foundationTypesInParkFamily({ parkId, branchId }),
  );
  const mostCommonFoundation = useAtomValue(
    foundationMostCommonInParkFamily({ parkId, branchId }),
  );

  const defaultFoundation = mostCommonFoundation ?? foundationTypesInPark[0];

  if (foundationTypesInPark.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={`No foundations in the park.`} type={"error"} />
      </CenterContainer>
    );
  }

  return (
    <ThreeDFoundationInner
      defaultFoundation={defaultFoundation}
      foundationTypesInPark={foundationTypesInPark}
    />
  );
};

const ThreeDFoundationInner = ({
  defaultFoundation,
  foundationTypesInPark,
}: {
  defaultFoundation: FoundationType;
  foundationTypesInPark: FoundationType[];
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [state, setState] = useState<State | undefined>(undefined);

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbine = [...turbineTypes.values()][0]; // dumb

  const [foundation, setFoundation] = useState(defaultFoundation);

  useEffect(() => {
    setFoundation(defaultFoundation);
  }, [defaultFoundation]);

  const mesh = useAtomValue(turbineMeshAtom);

  useEffect(() => {
    const nodeRef = ref.current;
    if (!nodeRef) return;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const { width, height } = nodeRef.getBoundingClientRect();

    const fov = 45;
    const nearPlane = 0.1;
    const farPlane = 1000;
    const camera = new THREE.PerspectiveCamera(
      fov,
      width / height,
      nearPlane,
      farPlane,
    );
    camera.position.z = 20;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    scene.fog = new THREE.Fog(0xffffff, 500, 800);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(0, 0, 100);
    scene.add(directionalLight);
    scene.add(new THREE.AmbientLight(0x808080));

    const models: State["models"] = createFoundationModel(turbine, foundation);
    for (const m of models) scene.add(m);

    const scale = turbine.hubHeight / 100;
    mesh.scale.set(scale, scale, scale);
    models.push(mesh);
    scene.add(mesh);

    setState({
      renderer,
      camera,
      scene,
      models,
    });

    const water = new THREE.Mesh(
      new THREE.CircleGeometry(900, 32),
      new THREE.MeshBasicMaterial({
        color: 0x023859,
        transparent: true,
        opacity: 0.5,
      }),
    );
    scene.add(water);
    models.push(water);

    renderer.setSize(width, height);
    renderer.setDrawingBufferSize(width, height, window.devicePixelRatio);
    nodeRef.replaceChildren(renderer.domElement);

    return () => {
      nodeRef.removeChild(renderer.domElement);
      renderer.dispose();
      models.forEach((o) => {
        if (o instanceof THREE.Mesh) {
          o.geometry.dispose();
          if (o.material instanceof Array) {
            o.material.forEach((m) => m.dispose());
          } else {
            o.material.dispose();
          }
          o.removeFromParent();
          scene.remove(o);
        }
      });
    };
  }, [foundation, mesh, turbine]);

  const timer = useRef<number>((Math.PI * 7) / 2);
  const animState = useAtomValue(animationStateAtom);

  useEffect(() => {
    let stop = false;
    const animateFn = () => {
      if (stop) return;
      window.requestAnimationFrame(animateFn);
      if (!state) return;

      const height = 60;
      const dist = animState.distance;
      const lookatHeight = (50 / 250) * animState.distance;

      timer.current += animState.speed;
      const f = timer.current;
      state.camera.position.set(
        Math.sin(f / 3) * dist,
        Math.cos(f / 3) * dist,
        height,
      );
      state.camera.up = new THREE.Vector3(0, 0, 1);
      state.camera.lookAt(new THREE.Vector3(0, 0, lookatHeight));
      state.renderer.render(state.scene, state.camera);
    };
    animateFn();
    return () => {
      stop = true;
    };
  }, [state, animState]);

  useResizeThree(state, ref);

  return (
    <Column style={{ height: "100%" }}>
      <Row style={{ padding: spaceMedium }}>
        <Label left>
          <p>Foundation type</p>
          <Dropdown
            value={foundation.id}
            onChange={(e) => {
              setFoundation(
                foundationTypesInPark.find((f) => f.id === e.target.value)!,
              );
            }}
          >
            {foundationTypesInPark.map((f) => (
              <option key={f.id} value={f.id}>
                {f.name}
              </option>
            ))}
          </Dropdown>
        </Label>
      </Row>
      <div
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
        ref={ref}
      />
    </Column>
  );
};

type AnimationState = {
  speed: number;
  distance: number;
};

const MAX_SPEED = 0.1;
const MAX_DISTANCE = 600;

const animationStateAtom = atomLocalStorage<AnimationState>(
  "vind:dashboard-3d-foundation-animation-state",
  {
    speed: 0.016,
    distance: 250.0,
  },
  z
    .object({
      speed: z.number(),
      distance: z.number(),
    })
    .transform((val) => {
      return {
        speed: Math.min(val.speed, MAX_SPEED),
        distance: Math.min(val.distance, MAX_DISTANCE),
      };
    }),
);

const ThreeDFoundationMenuItems = () => {
  const [state, setState] = useAtom(animationStateAtom);
  return (
    <>
      <MenuItemDiv nohover>
        <RestMenu>
          <Label left>
            <p>Rotation speed</p>
            <Slider
              min={0}
              max={MAX_SPEED}
              step={0.001}
              onChange={(e) => {
                setState((c) => ({
                  ...c,
                  speed: e,
                }));
              }}
              value={state.speed}
            />
          </Label>
          <Label left>
            <p>Camera distance</p>
            <Slider
              min={100}
              max={MAX_DISTANCE}
              step={10}
              onChange={(e) => {
                setState((c) => ({
                  ...c,
                  distance: e,
                }));
              }}
              value={state.distance}
            />
          </Label>
        </RestMenu>
      </MenuItemDiv>
    </>
  );
};

const RestMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${spaceMedium};
  gap: ${spaceMedium};

  width: 20rem;

  .divider {
    border-bottom: 1px solid ${colors.inputOutline};
    height: 1px;
    flex: 1;
  }
`;

export const ThreeDFoundationWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="3D Foundation"
      id="3D-foundation"
      menuItems={<ThreeDFoundationMenuItems />}
      resetKeys={errorBoundaryResetKeys}
    >
      <ThreeDFoundation />
    </SafeCard>
  );
};
