import { Style } from "business/style/types";
import {
  BucketSquare,
  CategoriesSquare,
  ColorSquare,
  GradientSquare,
} from "components/General/ColorSquare";

export const StylePreviewSquare = ({ styleObj }: { styleObj: Style }) => {
  if (styleObj.type === "single")
    return <ColorSquare $color={styleObj.color} className="colorsquare" />;
  if (styleObj.type === "gradient")
    return (
      <GradientSquare gradient={styleObj.gradient} className="colorsquare" />
    );
  if (styleObj.type === "bucket")
    return <BucketSquare buckets={styleObj.buckets} className="colorsquare" />;
  if (styleObj.type === "category")
    return (
      <CategoriesSquare
        categories={styleObj.categories}
        className="colorsquare"
      />
    );

  return null;
};
