import { useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import { PresenceMessage } from "ably";
import styled from "styled-components";
import Tooltip from "../General/Tooltip";
import { useMemo } from "react";
import {
  dateToDayMonthShortFormat,
  dedup,
  extractHHMMFromDate,
} from "../../utils/utils";
import UserImageRound, {
  MoreUsersImageRound,
} from "../UserImage/UserImageRound";
import { SkeletonRound } from "../Loading/Skeleton";
import { isDefined } from "../../utils/predicates";
import { nodeGroupUserAccessSelector } from "../Organisation/Groups/state";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "../Organisation/Groups/types";
import { Place } from "components/General/Anchor";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { loggedInUserIdAtom } from "state/user";
import { projectPresenceState } from "./ChannelProviders/Project/ProjectAll/state";
import { useJotaiCallback } from "utils/jotai";
import { liveCursorState } from "components/LiveCursor/state";
import { branchIdAtom } from "state/pathParams";
import { mapAtom } from "state/map";

const ProfileImageContainer = styled.div<{
  itemGap: string;
}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  > *:not(:last-child) {
    margin-left: ${(p) => p.itemGap};
  }
`;

export function ProjectPresenceWrapper({
  nodeId,
  organisationId,
  size,
  nrToShow = 3,
  itemGap = "-1.2rem",
  showSelf = true,
  tooltipTheme = "dark",
  tooltipPosition,
}: {
  nodeId: string;
  organisationId: string;
  size?: number;
  nrToShow?: number;
  itemGap?: string;
  showSelf?: boolean;
  tooltipTheme?: "dark" | "light";
  tooltipPosition?: Place;
}) {
  const loggedInUser = useAtomValue(loggedInUserIdAtom);
  const presenceData = useAtomValue(projectPresenceState(nodeId));
  const branchId = useAtomValue(branchIdAtom);

  const flyToUserPosition = useJotaiCallback(
    (get, _, message: PresenceMessage) => {
      const map = get(mapAtom);
      if (!map || !branchId) {
        return;
      }

      const cursorState = get(
        liveCursorState({
          projectId: nodeId,
          branchId: branchId,
          version: undefined,
        }),
      );

      const userPosition = cursorState.get(message.clientId);
      if (!userPosition) {
        return;
      }

      map.flyTo({ center: userPosition });
    },
    [nodeId, branchId],
  );

  const deduped = useMemo(
    () => dedup([...presenceData], (item) => item.clientId),
    [presenceData],
  );

  const dedupedMaybeWithoutCurrentUser = useMemo(() => {
    if (showSelf) {
      return deduped;
    }
    return deduped.filter((msg) => showSelf || msg.clientId !== loggedInUser);
  }, [deduped, loggedInUser, showSelf]);

  const peers = useMemo(() => {
    if (!organisationId) return [];
    return dedupedMaybeWithoutCurrentUser
      .sort((a, b) => a.timestamp - b.timestamp)
      .slice(0, nrToShow)
      .map((msg) => (
        <NodePresenceImage
          tooltipTheme={tooltipTheme}
          tooltipPosition={tooltipPosition}
          organisationId={organisationId}
          nodeId={nodeId}
          msg={msg}
          key={msg.id + msg.timestamp.toString()}
          size={size}
          onClick={
            msg.data.status === "active" && msg.data.branchId === branchId
              ? () => {
                  flyToUserPosition(msg);
                }
              : undefined
          }
        />
      ));
  }, [
    organisationId,
    dedupedMaybeWithoutCurrentUser,
    nrToShow,
    tooltipTheme,
    tooltipPosition,
    nodeId,
    size,
    branchId,
    flyToUserPosition,
  ]);

  const otherPeers = useMemo(() => {
    return dedupedMaybeWithoutCurrentUser.slice(nrToShow);
  }, [dedupedMaybeWithoutCurrentUser, nrToShow]);

  return (
    <ProfileImageContainer itemGap={itemGap}>
      {otherPeers.length > 0 && (
        <NotShownUsersInteractiveTooltip
          messages={otherPeers}
          size={size}
          nodeId={nodeId}
          organisationId={organisationId}
          onClick={flyToUserPosition}
          itemGap={itemGap}
          branchId={branchId}
        />
      )}
      {peers}
    </ProfileImageContainer>
  );
}

const ActiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.6rem; /* Match the size of div #3 */
  width: 0.6rem;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  border-radius: 0.6rem;
  background-color: #00ff00;
`;

function NodePresenceImage({
  organisationId,
  nodeId,
  msg,
  tooltipTheme,
  tooltipPosition = "right",
  size = 2.8,
  onClick,
}: {
  organisationId: string;
  nodeId: string;
  msg: PresenceMessage;
  tooltipTheme: "dark" | "light";
  tooltipPosition?: Place;
  size?: number;
  onClick?(): void;
}) {
  const users = useAtomValue(
    unwrap(
      nodeGroupUserAccessSelector({
        organisationId,
        nodeId,
      }),
    ),
  );
  const branchesInfo = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId,
    }),
  );

  const userInfo = users
    ?.filter(isUserNodeAccessWithMeta)
    .find((u: UserNodeAccessWithMeta) => u.user_id === msg.clientId);

  const lastActive = useMemo(() => {
    const date = new Date(msg.timestamp);
    return dateToDayMonthShortFormat(date) + ", " + extractHHMMFromDate(date);
  }, [msg.timestamp]);

  if (!isDefined(userInfo)) {
    return <SkeletonRound size={size * 10} />;
  }

  const branchName = branchesInfo.find(
    (b) => b.id === msg.data.branchId,
  )?.title;
  return (
    <Tooltip
      secondary
      theme={tooltipTheme}
      text={[
        userInfo.nickname,
        `${msg.data.status === "active" ? "Active now." : `Last active: ${lastActive}.`}`,
        branchName ? "In branch " + branchName : "",
      ]}
      position={tooltipPosition}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <UserImageRound user={userInfo} size={size} onClick={onClick} />
        {msg.data.status === "active" && <ActiveWrapper />}
      </div>
    </Tooltip>
  );
}

function NotShownUsersInteractiveTooltip({
  messages,
  size = 2.8,
  organisationId,
  nodeId,
  itemGap,
  branchId,
  onClick,
}: {
  messages: PresenceMessage[];
  size?: number;
  organisationId: string;
  nodeId: string;
  itemGap: string;
  branchId?: string;
  onClick(message: PresenceMessage): void;
}) {
  const users = useAtomValue(
    unwrap(
      nodeGroupUserAccessSelector({
        organisationId,
        nodeId,
      }),
    ),
  );

  return (
    <Tooltip
      secondary
      interactive={true}
      closeDelay={250}
      content={
        <ProfileImageContainer itemGap={itemGap}>
          {messages.map((message) => {
            const user = users
              ?.filter(isUserNodeAccessWithMeta)
              ?.find(
                (u: UserNodeAccessWithMeta) => u.user_id === message.clientId,
              );
            if (!user) {
              return null;
            }

            return (
              <NodePresenceImage
                key={message.clientId}
                tooltipTheme="dark"
                tooltipPosition="bottom"
                organisationId={organisationId}
                nodeId={nodeId}
                msg={message}
                size={size}
                onClick={
                  message.data.status === "active" &&
                  message.data.branchId === branchId
                    ? () => {
                        onClick(message);
                      }
                    : undefined
                }
              />
            );
          })}
        </ProfileImageContainer>
      }
      position="bottom"
      theme="light"
      offset={[0, 8]}
    >
      <MoreUsersImageRound
        size={size}
        title={`+ ${messages.length}`}
        style={{
          marginLeft: "0.8rem",
        }}
      />
    </Tooltip>
  );
}
