import {
  colorFromFeature,
  labelFromColorKey,
  labelFromColoringType,
} from "business/style/constants";
import {
  currentEditStyleIdAtom,
  styleSelectorFamily,
} from "business/style/state";
import {
  SingleStyle,
  Style,
  _ColoringType,
  _StyleFeatureOffshore,
  colorKeyType,
  isBucket,
  isCategory,
  isGradient,
  isSingle,
} from "business/style/types";
import Dropdown from "components/Dropdown/Dropdown";
import { Label, StyledLabel } from "components/General/Form";
import { Row } from "components/General/Layout";
import { ColorByDropdown } from "./ColorByDropdown";
import ResetIcon from "@icons/14/Reset.svg";
import ColorSelector from "components/ColorSelector/ColorSelector";
import { IconBtn } from "components/General/Icons";
import { Input } from "components/General/Input";
import Tooltip from "components/General/Tooltip";
import styled from "styled-components";
import { typography } from "styles/typography";
import { LabelDropdown } from "./LabelByDropdown";
import { isNever } from "utils/utils";
import { useAtom, useSetAtom } from "jotai";
import { Color } from "lib/colors";
import {
  changeStyleType,
  disableLabelOnStyle,
  enableLabelOnStyle,
} from "business/style/actions";
import { useEffect } from "react";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import Radio, { RadioGroup } from "components/General/Radio";
import { EditColorBucket } from "./EditColorBucket";
import { EditColorGradient } from "./EditColorRange";
import { EditColorCategories } from "./EditColorCategories";

const SingleColorOptions = ({
  style,
  setStyle,
}: {
  style: SingleStyle;
  setStyle: (s: Style | undefined) => void;
}) => {
  const disabled = style.defaultMarker;
  const rgb = style.color.toRGB();
  return (
    <Row>
      <p>Color:</p>
      <ColorSelector
        disabled={disabled}
        setColor={(c) => {
          setStyle({
            ...style,
            color: Color.fromHex(String(c)),
          });
        }}
        color={rgb}
      />
      <Input
        disabled={disabled}
        key={rgb}
        defaultValue={rgb}
        pattern="#[a-fA-F0-9]{6}"
        compact
        style={{ minWidth: "8rem" }}
        onBlur={(e) => {
          if (e.target.checkValidity())
            setStyle({
              ...style,
              source: "single",
              color: Color.fromHex(e.target.value),
            });
        }}
      />
      {!disabled && (
        <Tooltip text="Reset to default color">
          <IconBtn
            onClick={() => {
              setStyle({
                ...style,
                source: "single",
                color: colorFromFeature[style.feature],
              });
            }}
          >
            <ResetIcon />
          </IconBtn>
        </Tooltip>
      )}
    </Row>
  );
};

const EditStyle_ = styled.div`
  > h4 {
    ${typography.sub2}
    margin-top: 1.4rem;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.6rem;
  overflow-y: auto;
  padding-bottom: 1rem; // ????? need this for some reason to not get scrolling in small panels :css_is_awesome:

  .buttons {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .buttongrid {
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto;
    column-gap: 1.2rem;
    row-gap: 1.6rem;
    ${StyledLabel} {
      white-space: nowrap;
      ${typography.caption};
    }
  }
`;

export const EditStyle = ({ styleId }: { styleId: string }) => {
  const [style, setStyle] = useAtom(styleSelectorFamily(styleId));
  const setEditStyleId = useSetAtom(currentEditStyleIdAtom);
  const changeColoringType = useSetAtom(changeStyleType);
  const key = style ? style.name ?? labelFromColorKey[style.source] : "";

  const enableLabels = useSetAtom(enableLabelOnStyle);
  const disableLabels = useSetAtom(disableLabelOnStyle);

  useEffect(() => {
    if (!style) setEditStyleId(undefined);
  }, [setEditStyleId, style]);

  const valueType = colorKeyType(style?.source);

  if (!style) return null;
  return (
    <EditStyle_ className="editstyle">
      <SubtitleWithLine text="General" />

      <div className="buttongrid">
        <Label>Color by:</Label>
        <ColorByDropdown
          styleId={styleId}
          disabled={Boolean(style.defaultMarker)}
        />

        <Label>Name:</Label>
        <Input
          key={key}
          defaultValue={key}
          compact
          onBlur={(e) => {
            if (e.target.checkValidity())
              setStyle({
                ...style,
                name: e.target.value,
              });
          }}
          disabled={Boolean(style.defaultMarker)}
        />
      </div>

      <SubtitleWithLine text="Colors" />
      {style.source !== "single" && style.type && (
        <Label left>
          <p>Type:</p>
          <Dropdown
            small
            value={style.type}
            onChange={(e) => {
              const typ = _ColoringType.parse(e.target.value);
              return changeColoringType(style, typ);
            }}
            disabled={Boolean(style.defaultMarker)}
          >
            {valueType === "string" ? (
              <>
                <option value={"category"}>
                  {labelFromColoringType.category}
                </option>
              </>
            ) : valueType === "number" ? (
              <>
                <option value={"bucket"}>{labelFromColoringType.bucket}</option>
                <option value={"gradient"}>
                  {labelFromColoringType.gradient}
                </option>
              </>
            ) : (
              <>
                <option value={"bucket"}>{labelFromColoringType.bucket}</option>
                <option value={"gradient"}>
                  {labelFromColoringType.gradient}
                </option>
                <option value={"category"}>
                  {labelFromColoringType.category}
                </option>
              </>
            )}
          </Dropdown>
        </Label>
      )}

      {isSingle(style) ? (
        <SingleColorOptions style={style} setStyle={setStyle} />
      ) : isBucket(style) ? (
        <EditColorBucket style={style} />
      ) : isGradient(style) ? (
        <EditColorGradient style={style} />
      ) : isCategory(style) ? (
        <EditColorCategories style={style} />
      ) : (
        isNever(style) && null
      )}

      <SubtitleWithLine text="Labels" />
      <RadioGroup
        style={{ marginTop: "-0.8rem" }}
        disabled={Boolean(style.defaultMarker)}
      >
        <Radio
          label="No labels"
          checked={style.label === undefined}
          onChange={() => disableLabels(style.id)}
        />
        <Radio
          label="Add labels"
          checked={style.label !== undefined}
          onChange={() => enableLabels(style.id)}
        />
      </RadioGroup>

      {style.label && (
        <div className="buttongrid" style={{ marginTop: "0.4rem" }}>
          <Label>Label by:</Label>
          <LabelDropdown
            styleId={styleId}
            disabled={Boolean(style.defaultMarker)}
          />

          <Label>Label size:</Label>
          <Dropdown
            small
            value={style.labelSize ?? "small"}
            onChange={(e) => {
              setStyle({
                ...style,
                labelSize: e.target.value as "small" | "medium" | "large",
              });
            }}
            disabled={Boolean(style.defaultMarker)}
          >
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </Dropdown>
        </div>
      )}
    </EditStyle_>
  );
};
