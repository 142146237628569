import { LibraryManageRole } from "components/Organisation/Library/types";
import useAblyOrgFolderStructure from "hooks/useAblyOrgFolderStructure";
import { useAtomValue } from "jotai";
import {
  orgFinanicalManageAccessSelector,
  orgTurbineManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgExportcableManageAccessSelector,
  orgCableManageAccessSelector,
  orgAnalysisManageAccessSelector,
  orgSubstationManageAccessSelector,
} from "state/user";

// TODO: Should these messages arrive in the respective library channel?

export default function AblyOrgFolderStructure({
  organisationId,
}: {
  organisationId: string;
}) {
  const hasOrgFinancialAccess = useAtomValue(orgFinanicalManageAccessSelector);
  const hasOrgTurbineManageAccess = useAtomValue(
    orgTurbineManageAccessSelector,
  );
  const hasOrgFoundationManageAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const hasOrgExportCableManageAccess = useAtomValue(
    orgExportcableManageAccessSelector,
  );
  const hasOrgCableManageAccess = useAtomValue(orgCableManageAccessSelector);
  const hasOrgAnalysisManageAccess = useAtomValue(
    orgAnalysisManageAccessSelector,
  );
  const hasOrgSubstationManageAccess = useAtomValue(
    orgSubstationManageAccessSelector,
  );

  return (
    <>
      {hasOrgTurbineManageAccess && (
        <AblyInnerOrganisationTurbineLibrary organisationId={organisationId} />
      )}
      {hasOrgSubstationManageAccess && (
        <AblyInnerOrganisationSubstationLibrary
          organisationId={organisationId}
        />
      )}
      {hasOrgFinancialAccess && (
        <AblyInnerOrganisationFinancial organisationId={organisationId} />
      )}
      {hasOrgFoundationManageAccess && (
        <AblyInnerOrganisationFoundation organisationId={organisationId} />
      )}
      {hasOrgExportCableManageAccess && (
        <AblyInnerOrganisationExportCable organisationId={organisationId} />
      )}
      {hasOrgCableManageAccess && (
        <AblyInnerOrganisationCable organisationId={organisationId} />
      )}
      {hasOrgAnalysisManageAccess && (
        <AblyInnerOrganisationAnalysis organisationId={organisationId} />
      )}
    </>
  );
}

function AblyInnerOrganisationFinancial({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.FINANCIAL);
  return <></>;
}

function AblyInnerOrganisationTurbineLibrary({
  organisationId,
}: {
  organisationId: string;
}) {
  /* useAblyLibraryTurbine(organisationId); */
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.TURBINE);
  return <></>;
}

function AblyInnerOrganisationFoundation({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.FOUNDATION);
  return <></>;
}

function AblyInnerOrganisationExportCable({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.EXPORT_CABLE);
  return <></>;
}

function AblyInnerOrganisationCable({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.CABLE);
  return <></>;
}

function AblyInnerOrganisationAnalysis({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.ANALYSIS);
  return <></>;
}

function AblyInnerOrganisationSubstationLibrary({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyOrgFolderStructure(organisationId, LibraryManageRole.SUBSTATION);
  return <></>;
}
