import { atom } from "jotai";
import { atomFamily } from "utils/jotai";

// This is used to store the config name while still editing the config
// to avoid rendering the component and loose the values that is stored in LocalConfig
export const operationsConfigurationTempName = atomFamily(
  (__input: { nodeId: string }) =>
    atom<{ [key: string]: string | undefined }>({}),
);

export const savingOperationsConfigurationInProgressAtom = atom<boolean>(false);
