import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import {
  getNodesWithMissingParents,
  nodesInOrganisationAtom,
} from "./useOrganisationFolderCrud";
import { Node } from "../../services/customerAPI";
import {
  UserNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import { dedup } from "utils/utils";
import { isLoaded } from "types/Load";

export const allNodeUsersSelectorFamily = atomFamily(
  ({ organisationId, nodeId }: { organisationId: string; nodeId: string }) =>
    atom<Promise<UserNodeAccessWithMeta[]>>(async (get) => {
      const nodeAccess = await get(
        nodeGroupUserAccessSelector({
          organisationId,
          nodeId,
        }),
      );

      return dedup(
        nodeAccess.filter(isUserNodeAccessWithMeta),
        (u) => u.user_id,
      );
    }),
);

export type FolderTreeItem = Node & {
  type: "folder";
  isPersonalFolder: boolean;
  items: TreeItem[];
};
export type TreeItem = FolderTreeItem | Node;

export const folderTreeSelectorFamily = atomFamily(
  ({
    organisationId,
    folderId,
  }: {
    organisationId: string;
    folderId: string;
  }) =>
    atom<TreeItem[]>((get) => {
      const nodes = get(
        nodesInOrganisationAtom({
          organisationId,
        }),
      )
        .data.filter(isLoaded)
        .map((n) => n.value);

      const missingParentsNodes = get(
        getNodesWithMissingParents({
          organisationId,
        }),
      );

      const childrenNodes =
        folderId === organisationId
          ? missingParentsNodes
          : nodes.filter((n) => n.parent_id === folderId);

      function make(node: TreeItem): TreeItem {
        if (["personal_folder", "folder"].includes(node.type)) {
          return {
            ...node,
            type: "folder",
            isPersonalFolder: node.type === "personal_folder",
            items: nodes.filter((n) => n.parent_id === node.id).map(make),
          } as FolderTreeItem;
        }
        return node;
      }
      return childrenNodes.map(make);
    }),
);

/**
 * Make comparision function used for {@link Array.sort}.
 *  - Folders before projects.
 *  - Starred items first.
 *  - Alphabetical order.
 */
export const makeNodeCompare = (followIds: Set<string>) => {
  return (a: Node | FolderTreeItem, b: Node | FolderTreeItem): number => {
    if (a.type === "personal_folder" || a.isPersonalFolder) return -1;
    if (b.type === "personal_folder" || b.isPersonalFolder) return 1;
    if (a.type === "project" && b.type === "folder") return 1;
    if (a.type === "folder" && b.type === "project") return -1;

    const aId = a.id;
    const aTitle = a.name;

    const bId = b.id;
    const bTitle = b.name;

    const aFollow = followIds.has(aId);
    const bFollow = followIds.has(bId);
    if (aFollow && !bFollow) return -1;
    if (bFollow && !aFollow) return 1;
    return aTitle.localeCompare(bTitle);
  };
};
