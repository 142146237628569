import { atom, useAtom, useAtomValue } from "jotai";
import { branchIdAtom } from "state/pathParams";

import { useEffect, useRef } from "react";
import { usePresence } from "ably/react";

const TWO_MINUTES = 60 * 2 * 1000;
const IDLE_TIME = TWO_MINUTES;

const presenceIdleState = atom(false);

export function usePresenceEnter(projectId: string) {
  const branchId = useAtomValue(branchIdAtom);

  const { updateStatus, channelError, connectionError } = usePresence(
    {
      channelName: `${projectId}:all`,
      ablyId: projectId,
    },
    {
      status: "active",
      branchId: branchId,
    },
  );

  const [idle, setIdle] = useAtom(presenceIdleState);

  useEffect(() => {
    if (channelError || connectionError) return;
    updateStatus({
      status: idle ? "idle" : "active",
      branchId: branchId,
    });
  }, [branchId, channelError, connectionError, idle, updateStatus]);

  const activity = useRef<{
    curr: number;
    last: number;
  }>({
    curr: 0,
    last: 0,
  });
  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const actionHappened = () => {
      if (activity.current.curr === activity.current.last) setIdle(false);
      activity.current.curr += 1;
    };

    for (let i in events) {
      window.addEventListener(events[i], actionHappened);
    }

    const interval = setInterval(() => {
      const { curr, last } = activity.current;
      if (curr === last) setIdle(true);
      activity.current.last = curr;
    }, IDLE_TIME);

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], actionHappened);
      }
      clearInterval(interval);
    };
  }, [setIdle]);
}
