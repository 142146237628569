import styled from "styled-components";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { spacing1, spacing2, spacing3, spacing6 } from "styles/space";

const addSharedDragStyles = ({
  enableDrag,
  isHoveredBottom,
  isHoveredTop,
  isHoveredMiddle,
  depth = 0,
}: {
  enableDrag?: boolean;
  isHoveredBottom?: boolean;
  isHoveredMiddle?: boolean;
  isHoveredTop?: boolean;
  depth?: number;
}) => {
  if (!enableDrag) {
    return "";
  }

  if (isHoveredMiddle) {
    return `
      background: ${colors.blue100};
      transition: background 0.15s ease-in-out;
    `;
  }

  if (isHoveredBottom) {
    return `
       &::after {
          content: "";
          position: absolute;
          width: calc(100% - ${depth * 1.9}rem);
          height: 2px;
          background-color: ${colors.blue900};
          bottom: -1px;
          right: 0;
        }
      `;
  }

  if (isHoveredTop) {
    return `
       &::before {
          content: "";
          position: absolute;
          width: calc(100% - ${depth * 1.9}rem);
          height: 2px;
          background-color: ${colors.blue900};
          top: -1px;
          right: 0;
        }
      `;
  }
};

export const ToplevelDropTargetDiv = styled.div<{
  enableDrag: true;
  isHoveredTop: boolean;
}>`
  position: relative;
  min-height: 5rem;
  flex: 1;

  ${addSharedDragStyles};
`;

export const Ui13RegularOverflow = styled.p`
  ${typography.contentAndButtons}
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  color: inherit;
  flex: 1;
  margin: 0;
`;

export const Ui13RegularOverflowDiv = styled.div`
  ${typography.contentAndButtons}
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  color: inherit;
  flex: 1;
  margin: 0;
`;

export const ProjectElementsV2Wrapper = styled.div<{
  showBottomShadow: boolean;
}>`
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${({ showBottomShadow }) =>
    showBottomShadow &&
    `
    box-shadow: inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08);
    `}
`;

export const ProjectElementsList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  padding-left: ${spacing6};
  padding-right: ${spacing6};
`;

export const FlexCenterAligned = styled.div`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  * > p {
    overflow: hidden;
  }
`;

export const ProjectElementFolderWrapper = styled.div<{
  isSelected?: boolean;
  isDragging?: boolean;
  isDisabled?: boolean;
  isInFolder?: boolean;
  enableDrag?: boolean;
  isHoveredBottom?: boolean;
  isHoveredTop?: boolean;
  isHoveredMiddle?: boolean;
  depth?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition:
    all 0.4s,
    background-color 0s,
    border-color 0s,
    opacity 0s;
  position: relative;
  border-radius: 4px;

  ${({ isDisabled }) => isDisabled && `opacity: 0.6;`}

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${colors.selected};
  `}

  ${({ isDragging }) =>
    isDragging &&
    `
    opacity: 0.5;
  `}

  ${FlexCenterAligned} {
    overflow-x: hidden;
  }

  ${addSharedDragStyles};
`;

export const ProjectElementFolderTopRow = styled.div<{
  clickable: boolean;
  hoverable: boolean;
  allFeaturesVisible: boolean;
  allFeaturesSelected?: boolean;
  depth: number;
  isInFolder?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
  gap: 1rem;
  justify-content: space-between;
  height: 3.6rem;
  border-radius: 4px;

  ${({ depth }) =>
    `
    padding: 0 0 0 calc(${depth > 0 ? "0rem" : spacing2} + ${depth * 1.9}rem);
  `}

  &:hover {
    ${({ allFeaturesSelected, hoverable }) =>
      !allFeaturesSelected &&
      hoverable &&
      `
      background-color: ${colors.hover};
    `}
  }

  ${({ allFeaturesSelected }) =>
    allFeaturesSelected &&
    `
    background-color: ${colors.selected};
  `}

  ${HideIfNotHoverOrVisible}:not(.visible) {
    visibility: hidden;
  }

  &:hover {
    ${HideIfNotHoverOrVisible} {
      visibility: visible;
    }
  }
`;

export const ProjectElementItemWrapper = styled.div<{
  isSelected: boolean;
  isDragging?: boolean;
  depth: number;
  enableDrag?: boolean;
  isHoveredBottom?: boolean;
  isHoveredTop?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  cursor: pointer;
  height: 3.6rem;
  min-height: 3.6rem;
  justify-content: space-between;
  border-radius: 4px;

  ${({ depth }) =>
    `
    padding: 0 0 0 calc(${depth > 0 ? "0rem" : spacing2} + ${depth * 1.9}rem);
  `}

  &:hover {
    ${({ isSelected }) =>
      isSelected
        ? `
      background-color: ${colors.selected};
    `
        : `
      background-color: ${colors.hover};
    `}
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${colors.selected};
    color: ${colors.textSelected};
  `}

  ${({ isDragging }) =>
    isDragging &&
    `
    opacity: 0.5;
  `}

  ${HideIfNotHoverOrVisible}:not(.visible) {
    visibility: hidden;
  }

  &:hover {
    ${HideIfNotHoverOrVisible} {
      visibility: visible;
    }
  }

  ${FlexCenterAligned} {
    overflow-x: hidden;
  }

  ${addSharedDragStyles};
`;

export const ProjectElementEmptyFolderItemWrapper = styled.div<{
  depth: number;
  isHoveredBottom?: boolean;
  isHoveredTop?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  cursor: pointer;
  height: 3.6rem;
  min-height: 3.6rem;
  justify-content: space-between;
  border-radius: 4px;

  ${({ depth }) =>
    `
    padding: 0 0 0 calc(${depth > 0 ? "0rem" : spacing2} + 2.7rem + ${depth * 1.9}rem);
  `}

  ${addSharedDragStyles};

  span {
    ${typography.contentAndButtons}
    color: ${colors.textDisabled};
  }
`;

export const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

export const DnDIconWrapper = styled.div`
  position: absolute;
  left: -${spacing3};
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    path {
      stroke: ${colors.grey900};
    }
  }
`;

const TypeLine = styled.div<{
  lineColor: string;
  lineWidth?: string;
  lineLength?: string;
  rotationDeg?: number;
}>`
  height: ${({ lineWidth }) => lineWidth};
  width: ${({ lineLength }) => lineLength};
  transform: ${({ rotationDeg }) => `rotate(${rotationDeg}deg)`};
  background-color: ${({ lineColor }) => lineColor};
  border-radius: 2px;
`;

TypeLine.defaultProps = {
  lineWidth: "2px",
  lineLength: "22px",
  rotationDeg: -60,
};
