import { organisationIdAtom } from "state/pathParams";
import React from "react";
import pako from "pako";
import { Mixpanel } from "mixpanel";
import CloseIcon from "@icons/24/Close.svg";
import FileIcon from "@icons/24/File.svg";
import { IconBtn } from "components/General/Icons";
import { IconREMSize, typography } from "styles/typography";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import UploadFeaturesFromCoordinatesGeneral from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/UploadFeaturesFromCoordinatesGeneral";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { uploadCustomOrganisationLayer } from "services/customLayersAPIService";
import { dateToDateTime } from "utils/utils";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import { HeaderRow, Modal, Title } from "./shared";
import { useAtomValue, useSetAtom } from "jotai";

export const UploadOrganisationLayerFromCoordinatesModalType =
  "uploadOrganisationLayerFromCoordinatesModal" as const;

const UploadOrganisationLayerFromCoordinatesModalInner = ({
  addToPackageId,
}: {
  addToPackageId?: string;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { refreshLayers, addLayersToPackage } = useDataLibraryLayersCrud();

  const onClose = () => {
    setMidScreenModalTypeOpen(undefined);
  };

  return (
    <FullScreenModal placeOnTopOfOtherModals={true} onClick={onClose}>
      <Modal>
        <HeaderRow>
          <h2>
            <Title>Upload from coordinates</Title>
          </h2>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.8rem",
          }}
        >
          <IconREMSize height={1.4} width={1.4}>
            <FileIcon />
          </IconREMSize>
          <p style={typography.body}>
            Uploaded data will only be visible for projects with access to this
            layer
          </p>
        </div>

        <UploadFeaturesFromCoordinatesGeneral
          inContextOfProject={false}
          onDoneClick={onClose}
          onAddClick={async (tempFeatures) => {
            Mixpanel.track_old("Added organisation feature from coordinates", {
              numberOfFeatures: tempFeatures.length,
            });

            const newFeatureCollection = {
              type: "FeatureCollection",
              features: tempFeatures,
            };

            const compressedUint8Array = pako.gzip(
              new TextEncoder().encode(JSON.stringify(newFeatureCollection)),
            );
            const gzipFileToUpload = new File(
              [compressedUint8Array],
              `Uploaded layer from coordinates ${dateToDateTime(new Date())}.gz`,
            );

            try {
              const result = await uploadCustomOrganisationLayer(
                organisationId,
                gzipFileToUpload,
              );
              if (addToPackageId) {
                await addLayersToPackage(organisationId, addToPackageId, [
                  result.output_file_id,
                ]);
              }
              refreshLayers();
            } catch (err) {}
          }}
        />
      </Modal>
    </FullScreenModal>
  );
};

const UploadOrganisationLayerFromCoordinatesModal = () => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  if (
    midScreenModalTypeOpen?.modalType !==
    UploadOrganisationLayerFromCoordinatesModalType
  ) {
    return null;
  }

  return (
    <UploadOrganisationLayerFromCoordinatesModalInner
      addToPackageId={midScreenModalTypeOpen.metadata?.addToPackageId}
    />
  );
};

export default UploadOrganisationLayerFromCoordinatesModal;
