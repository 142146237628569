import React from "react";
import { Column, Row } from "components/General/Layout";
import CloseIcon from "@icons/24/Close.svg";
import EarthIcon from "@icons/24/Earth.svg";
import OrganisationIcon from "@icons/24/Organisation.svg";
import SinglePersonIcon from "@icons/24/SinglePerson.svg";
import { SVGWrapper } from "@icons/svgUtils";
import { useClickOutside } from "hooks/useClickOutside";
import {
  DescriptionContainer,
  EntryContainer,
  Label,
  ModalContainer,
  No,
  SecondaryText,
  SectionHeader,
  StyledLink,
  Text,
  Yes,
} from "./shared";

export default function OrganisationAccessOverviewModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  return (
    <ModalContainer ref={ref}>
      <Column style={{ gap: "0.8rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>Organisation role access</h3>
          <SVGWrapper
            size={1.4}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </SVGWrapper>
        </Row>
        <Text>
          Here's a breakdown of what each role means in terms of access.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={
              "https://help.vindtech.com/en/articles/7056300-access-management#h_850ca4c9f6"
            }
          >
            Read more
          </StyledLink>
        </Text>
      </Column>
      <Column style={{ gap: "1.2rem" }}>
        <Row>
          <DescriptionContainer>
            <div />
          </DescriptionContainer>
          <EntryContainer>
            <Label>Owner</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Admin</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Member</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Guest</Label>
          </EntryContainer>
        </Row>
        <SectionHeader
          style={{ marginTop: 0 }}
          icon={<OrganisationIcon />}
          text="Organisation"
        />
        <Row>
          <DescriptionContainer>
            <Text>Edit organisation settings</Text>
          </DescriptionContainer>
          <Yes />
          <No />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Manage portfolio</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Create and manage groups</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View groups</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Assign library access</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <SectionHeader icon={<EarthIcon />} text="Projects" />
        <Row>
          <DescriptionContainer>
            <Text>Manage access to all projects</Text>
          </DescriptionContainer>
          <Yes star />
          <Yes star />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Create projects</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>

        <SectionHeader icon={<SinglePersonIcon />} text="Users" />
        <Row>
          <DescriptionContainer>
            <Text>Invite to organisation</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Remove from organisation</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View organisation members</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>View project collaborators</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
          <Yes />
        </Row>
        <Row />
        <Row>
          <SecondaryText>
            * Owners and Admins does not have automatic access to all projects,
            but they can give themselves access to any project or any group.
          </SecondaryText>
        </Row>
      </Column>
    </ModalContainer>
  );
}
