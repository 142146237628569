import { useCallback, useMemo } from "react";
import { ABLY_WAKE_ANALYSIS_RESULT } from "../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { AnalysisStatus, _AnalysisStatus } from "../functions/production";
import { sendWarning } from "../utils/sentry";
import { useJotaiCallback } from "utils/jotai";
import { analysisAblyStatus } from "analysis/output";
import { _Progress } from "types/progress";
import { z } from "zod";
import { useSetAtom } from "jotai";
import {
  problemResultsAtom,
  registerProgress,
} from "components/GenerateWindTurbines/state";
import { toastAction } from "./useToast";
import { projectIdAtomDef2 } from "state/pathParams";
import { RESET } from "jotai/utils";

const _OptimizationStatusMessage = z.object({
  id: z.string(),
  status: z.enum([
    "running",
    "failed",
    "stopped",
    "completed",
    "started",
    "invalid",
  ]),
  progress: _Progress,
  reason: z.string().nullish().optional(),
  results: z
    .object({
      positions: z.tuple([z.number(), z.number()]).array(),
      gross_aep: z.number().array().array(),
      net_aep: z.number().array().array(),
      fail: z.boolean().optional().default(false),
    })
    .or(z.object({})),
});
export type OptimizationStatusMessage = z.output<
  typeof _OptimizationStatusMessage
>;

export function useAblyAnalysisStatus(projectId: string) {
  const setJotaiStatus = useJotaiCallback((_, set, update: AnalysisStatus) => {
    set(analysisAblyStatus({ analysisStatusId: update.id }), update);
  }, []);

  const onAnalysisUpdate = useCallback(
    (message: InboundMessage) => {
      const data = message.data;
      console.log("Received analysis status update", data);
      const ablyStatus = _AnalysisStatus.parse(data);
      setJotaiStatus(ablyStatus);
      if (ablyStatus.status === "failed") {
        sendWarning("Wake analysis failed", { ablyStatus });
      }
    },
    [setJotaiStatus],
  );

  const domain = import.meta.env.VITE_DEV_OCTOPUS === "true" ? "all" : "all";
  const channelName = useMemo(
    () => `${projectId}:${domain}`,
    [projectId, domain],
  );

  const toast = useSetAtom(toastAction);
  const record = useSetAtom(registerProgress);

  /** Re-fetch the results for an optimization by resetting the atom. */
  const resetProblemResults = useJotaiCallback(
    async (get, set, branchId: string, parkId: string, optId: string) => {
      const projectId = await get(projectIdAtomDef2);
      set(
        problemResultsAtom({
          nodeId: projectId,
          branchId,
          zoneId: parkId,
          id: optId,
        }),
        RESET,
      );
    },
    [],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_WAKE_ANALYSIS_RESULT,
        onMessageReceived: onAnalysisUpdate,
      },
      {
        eventName: "optimization_status",
        onMessageReceived: (msg) => {
          const res = _OptimizationStatusMessage.safeParse(msg.data);

          if (res.success) {
            const [_branch, branchId, _park, parkId, _opt, optId] =
              msg.data.id.split("#");

            res.data.id = optId;
            const p = record(res.data);
            // All of the workers send a "complete" event. Once the total progress reaches 1.0, we're officialy done.
            if (res.data.status === "completed" && p === 1.0)
              resetProblemResults(branchId, parkId, optId).then(() =>
                toast("info", "Optimization completed."),
              );
          } else {
            console.log(msg.data);
            console.warn(
              "Failed to parse OptimizationStatusMessage",
              res.error,
            );
            res.error;
          }
        },
      },
    ],
    [onAnalysisUpdate, record, resetProblemResults, toast],
  );

  useAblyGeneric(channelName, events, projectId);
}
