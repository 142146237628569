import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import ProjectAll from "./ProjectAll/ProjectAll";

export default function ProjectChannelProviders() {
  const projectId = useAtomValue(projectIdAtom);

  if (!projectId) return null;
  return <ProjectAll projectId={projectId} ablyId={projectId} />;
}
