import { atom } from "jotai";
import { atomFamily } from "utils/jotai";

import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  getTurbines,
  getTurbinesWithFloatingFoundations,
} from "analysis/inputs";
import { undefMap } from "utils/utils";
import { Amount, AmountUnit } from "types/financial";
import groupBy from "utils/groupBy";
import { CostType, isOperationsCost } from "services/costService";
import { amountId } from "./amountIds";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { turbineTypesInParkFamily } from "finance/inputs";

export const turbineAmountSelectorFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Amount[]>>(async (get) => {
    const turbineTypes = await get(turbineTypesInParkFamily(id));
    const turbines = await get(getTurbines(id));
    const turbinesByType = groupBy(turbines, (t) => t.properties.turbineTypeId);
    const turbinesInstalledAtPort = await get(
      getTurbinesWithFloatingFoundations(id),
    );
    const turbinesInstalledAtSite = turbines.filter(
      (turbine) =>
        !turbinesInstalledAtPort.some(
          (portTurbine) => portTurbine.id === turbine.id,
        ),
    );

    const perUnitAmount = Object.entries(turbinesByType).map(
      ([id, turbines]) => {
        const amount = turbines.length ?? 0;
        const unit = AmountUnit.unit;
        const category = CostType.Turbine;

        return {
          id: amountId({ unit, category, featureTypeId: id }),
          unit,
          amount,
          category,
        };
      },
    );

    const perMwAmount = turbineTypes.map((turbineType) => {
      const amount =
        undefMap(
          turbinesByType[turbineType.id]?.length,
          (len) => (len * turbineType.ratedPower) / 1000,
        ) ?? 0;
      const unit = AmountUnit.MW;
      const category = CostType.Turbine;

      return {
        id: amountId({ unit, category, featureTypeId: turbineType.id }),
        unit,
        amount,
        category,
      };
    });

    const fixedAmount = {
      id: amountId({
        unit: AmountUnit.fixed,
        category: CostType.Turbine,
      }),
      unit: AmountUnit.fixed,
      amount: 1,
      category: CostType.Turbine,
    };
    const amounts: Amount[] = [...perUnitAmount, ...perMwAmount, fixedAmount];

    const configuration = await get(getConfiguration(id));

    if (isOperationsCost(configuration.capex.installation.turbines)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));
      const { installationVessel } = operationsConfiguration.ti.turbines;

      const vesselTypes = await get(vesselTypesFamily(undefined));

      const mobilizationTime =
        vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

      const installationTime =
        turbinesInstalledAtSite.length > 0
          ? await get(
              getTotalInstallationTime({ id, type: InstallationType.Turbine }),
            )
          : 0;

      const perDayAmount = {
        id: amountId({
          unit: AmountUnit.day,
          category: CostType.Turbine,
          featureTypeId: installationVessel.vesselId,
        }),
        unit: AmountUnit.day,
        amount:
          turbinesInstalledAtSite.length > 0
            ? (installationTime ?? 0) + mobilizationTime
            : 0,
        category: CostType.Turbine,
      };
      amounts.push(perDayAmount);
    }
    return amounts;
  }),
);
