import { useMemo, Suspense } from "react";
import { TurbineFeatureWithFoundation } from "../../../../../state/foundations";
import { orLoader, SkeletonText } from "../../../../Loading/Skeleton";
import { isDefined, isDetailedJacket } from "../../../../../utils/predicates";
import { Column } from "../../../../General/Layout";
import { ColoredGrid } from "../../../../General/Form";
import {
  isDetailedMonopile,
  isSimpleJacket,
  isSimpleMonopile,
} from "utils/predicates";
import { valueRounding } from "../utils";
import groupBy from "../../../../../utils/groupBy";
import { ParkFeature } from "../../../../../types/feature";
import { SimpleFixedSingleStats } from "./SimpleFixedSingleStats";
import { DetailedMonopileGenerationInner } from "./MonopileFoundationGeneration";
import { dedup } from "../../../../../utils/utils";
import { ButtonWrapper } from "../../style";
import Button from "../../../../General/Button";
import {
  DashboardModalType,
  modalTypeOpenAtom,
} from "../../../../../state/modal";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { useBathymetry } from "hooks/bathymetry";
import {
  Divider,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { useAtomValue, useSetAtom } from "jotai";
import {
  foundationFixedTypesAtom,
  foundationTypesAtom,
  getFixedFoundationTotalsFamily,
  getSimpleFixedMassesFamily,
} from "state/jotai/foundation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { DetailedJacketGenerationInner } from "./JacketFoundationGeneration";
import { configurationMenuActiveAtom } from "../../ProjectFeatureInfoModal/state";

export const FixedFoundationGeneration = ({
  tempLayoutFoundations,
  park,
}: {
  tempLayoutFoundations: TurbineFeatureWithFoundation[];
  park: ParkFeature;
}) => {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const allFixed = useAtomValue(foundationFixedTypesAtom);
  const { scrollBodyRef } = useShowScrollShadow(true);

  const foundationTypes = useMemo<string[] | undefined>(() => {
    const foundationIds = [
      ...new Set(
        tempLayoutFoundations.map((turbine) => turbine.properties.foundationId),
      ).values(),
    ];

    return foundationIds
      .map((id) => allFixed.find((t) => t.id === id)?.name)
      .filter(isDefined);
  }, [tempLayoutFoundations, allFixed]);

  const foundationMaterials = useMemo<string[] | undefined>(() => {
    const foundationIds = [
      ...new Set(
        tempLayoutFoundations.map((turbine) => turbine.properties.foundationId),
      ).values(),
    ];

    return foundationIds
      .map((id) => allFixed.find((t) => t.id === id)?.material)
      .filter(isDefined)
      .filter((m, i, ar) => ar.indexOf(m) === i);
  }, [tempLayoutFoundations, allFixed]);

  const [bathymetry] = useBathymetry({
    projectId: undefined,
    branchId: undefined,
    featureId: park.id,
    bufferKm: undefined,
  });

  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  return (
    <Column>
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: `calc(100vh - 40rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
          overflowY: "auto",
        }}
      >
        <ColoredGrid>
          <ResultValue>Foundations</ResultValue>
          <ResultValue>
            <strong>{tempLayoutFoundations.length}</strong>
          </ResultValue>
          <ResultValue>Foundation type(s)</ResultValue>
          <ResultValue>
            {" "}
            <strong>{foundationTypes?.join(", ") ?? ""} </strong>
          </ResultValue>
          <ResultValue>Primary material</ResultValue>
          <ResultValue>
            {" "}
            <strong>{foundationMaterials?.join(", ") ?? ""}</strong>{" "}
          </ResultValue>
        </ColoredGrid>

        {orLoader(bathymetry, (bath) => (
          <Suspense
            fallback={
              <Column>
                <SkeletonText />
              </Column>
            }
          >
            <FixedFoundationGenerationInner
              tempLayoutFoundations={tempLayoutFoundations}
              rasterId={bath.id}
            />
          </Suspense>
        ))}
      </div>
      <Divider style={{ marginLeft: "-2rem", marginRight: "-2rem" }} />
      <ButtonWrapper style={{ paddingTop: "0.8rem" }}>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-foundation" },
            });
          }}
        />
      </ButtonWrapper>
    </Column>
  );
};

const FixedFoundationGenerationInner = ({
  tempLayoutFoundations,
  rasterId,
}: {
  tempLayoutFoundations: TurbineFeatureWithFoundation[];
  rasterId: string;
}) => {
  const turbineIdTypes = dedup(
    tempLayoutFoundations?.map((t) => t.properties.turbineTypeId) ?? [],
  );

  const allTurbineTypes = Array.from(
    useAtomValue(simpleTurbineTypesAtom).values(),
  );
  const turbineNames = Object.fromEntries(
    allTurbineTypes
      .filter((t) => turbineIdTypes.includes(t.id))
      .map((t) => [t.id, t.name]),
  );

  const foundations = useAtomValue(foundationTypesAtom);
  const turbinesWithDetailedMonopile = tempLayoutFoundations.filter((t) =>
    isDetailedMonopile(foundations.get(t.properties.foundationId)),
  );
  const turbinesWithDetailedJacket = tempLayoutFoundations.filter((t) =>
    isDetailedJacket(foundations.get(t.properties.foundationId)),
  );
  const turbinesWithSimpleFixed = tempLayoutFoundations.filter(
    (t) => !turbinesWithDetailedMonopile.includes(t),
  );

  const { foundationMasses } = useAtomValue(
    getSimpleFixedMassesFamily({
      turbinesWithSimpleFixed,
      rasterId,
    }),
  );

  const simpleFoundationDesignsByIds = groupBy(
    foundationMasses,
    ({ turbineTypeId, foundationId }) => `${turbineTypeId},${foundationId}`,
  );

  const allFixed = useAtomValue(foundationFixedTypesAtom);
  const foundationIds = dedup(
    tempLayoutFoundations.map((turbine) => turbine.properties.foundationId),
  );

  const foundationNames = Object.fromEntries(
    allFixed
      .filter((f) => foundationIds.includes(f.id))
      .map((f) => [f.id, f.name]),
  );

  const turbineFoundationCombinations = dedup(
    turbinesWithSimpleFixed.map(
      (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
    ),
  );

  const { totalPrimarySteelMass } = useAtomValue(
    getFixedFoundationTotalsFamily({
      tempLayoutFoundations,
      rasterId,
    }),
  );

  return (
    <>
      {turbinesWithDetailedMonopile.length > 0 && rasterId && (
        <DetailedMonopileGenerationInner
          turbines={turbinesWithDetailedMonopile}
          turbineNames={turbineNames}
          rasterId={rasterId}
        />
      )}
      {turbinesWithDetailedJacket.length > 0 && rasterId && (
        <DetailedJacketGenerationInner
          turbines={turbinesWithDetailedJacket}
          turbineNames={turbineNames}
          rasterId={rasterId}
        />
      )}
      {turbineFoundationCombinations.map((combination) => {
        const [t_id, f_id] = combination.split("-");
        const t_name = turbineNames[t_id];
        const f_name = foundationNames[f_id];
        const foundation = allFixed.find((f) => f.id === f_id);
        if (!isSimpleJacket(foundation) && !isSimpleMonopile(foundation))
          return null;
        return (
          <SimpleFixedSingleStats
            key={t_id + f_id}
            name={t_name + " - " + f_name}
            masses={simpleFoundationDesignsByIds[`${t_id},${f_id}`]}
          />
        );
      })}
      <SubtitleWithLine text={"Selected area totals"} />
      <ColoredGrid
        style={{
          gridTemplateColumns: "2.5fr 1.5fr",
        }}
      >
        {totalPrimarySteelMass > 0 && (
          <>
            <ResultValue>Primary steel weight</ResultValue>
            <ResultValue>
              <strong>
                {valueRounding(
                  totalPrimarySteelMass / 1000,
                  100,
                ).toLocaleString()}{" "}
              </strong>
              t
            </ResultValue>
          </>
        )}
      </ColoredGrid>
    </>
  );
};
