import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import { loggedInUserAtom, tokenAtom } from "../../state/user";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import Ably from "ably";
import { AblyProvider } from "ably/react";
import { ABLY_TIMEOUT } from "./utils/ablySizeLimit";
import { getAccessTokenGlobal } from "state/global";
import { PROJECT_SERVICE_API_PATH } from "components/ProjectElements/service";
import usePrevious from "hooks/usePrevious";
import OrganisationChannelProviders from "./ChannelProviders/Organisation/OrganisationChannelProviders";

export const OrganisationAblyProvider = ErrorBoundaryWrapper(
  ({ children }: { children: React.ReactNode }) => {
    const userData = useAtomValue(loggedInUserAtom);
    if (userData?.isTemporaryToken) return null;

    return <Inner>{children}</Inner>;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);
const Inner = ({ children }: { children: React.ReactNode }) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const token = useAtomValue(tokenAtom);
  const previousAccessToken = usePrevious(token);

  const orgClient = useMemo(() => {
    if (!organisationId) return undefined;
    const currentToken = getAccessTokenGlobal();
    if (!currentToken) return undefined;

    return new Ably.Realtime({
      autoConnect: true,
      disconnectedRetryTimeout: ABLY_TIMEOUT,
      suspendedRetryTimeout: ABLY_TIMEOUT,
      transportParams: {
        heartbeatInterval: ABLY_TIMEOUT,
      },
      queryTime: true,
      authMethod: "GET",
      authUrl: `${PROJECT_SERVICE_API_PATH}/ably/v4/organisation/${organisationId}`,
      authHeaders: {
        Authorization: `Bearer ${currentToken}`,
      },
    });
  }, [organisationId]);

  useEffect(() => {
    if (
      orgClient &&
      previousAccessToken &&
      token &&
      token !== previousAccessToken
    ) {
      console.log("Reauthorizing Ably client with new token");
      orgClient.auth.authorize(undefined, {
        authMethod: "GET",
        authUrl: `${PROJECT_SERVICE_API_PATH}/ably/v4/organisation/${organisationId}`,
        authHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [previousAccessToken, orgClient, organisationId, token]);

  useEffect(() => {
    return () => {
      if (orgClient) {
        console.log("Closing Ably client");
        orgClient.close();
      }
    };
  }, [orgClient]);

  if (!orgClient) return children;

  return (
    <AblyProvider client={orgClient} ablyId={organisationId}>
      {children}
      <OrganisationChannelProviders organisationId={organisationId} />
    </AblyProvider>
  );
};
