import { projectIdAtom } from "state/pathParams";
import {
  CostConfiguration,
  CostType,
  isFeatureCost,
  isOperationsCost,
  isOperationsOverrideCost,
  isOverrideCost,
} from "../../../../services/costService";
import { useAtomValue, useSetAtom } from "jotai";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import ExportCable from "@icons/24/ExportCable.svg?react";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import { GeneralCost } from "./General/GeneralCost";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import { DefaultCosts } from "../constants";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { FirstRowWrapper } from "../Capex";
import { Input, InputDimensioned, TextArea } from "components/General/Input";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { isDefined } from "utils/predicates";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import { IconBtn } from "components/General/Icons";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { ChangelogCategory } from "components/InputChangelog/types";
import TableIcon from "@icons/24/Table.svg";
import { midScreenModalTypeOpenAtom } from "state/modal";

const ExportCableSpecificCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;
  const category: ChangelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleExportCableCostTypeChange =
    (type: "feature_cost" | "override") => () => {
      if (type === "feature_cost") {
        updateFixedCapex({
          exportCable: type,
        });
      } else {
        updateFixedCapex({
          exportCable: {
            cost: DefaultCosts[CostType.ExportCable].exportCable[
              CostUnit.millionEuroPerKM
            ],
            unit: CostUnit.millionEuroPerKM,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Export cables"}
          disabled={true}
          locked={true}
          style={{
            width: "100%",
          }}
        />

        {isFeatureCost(localConfig?.capex.fixed.exportCable) ? (
          <Row
            style={{
              width: "100%",
            }}
          >
            <Tag>
              <TableIcon />
              From table
            </Tag>
            <IconBtn
              size={"1.4rem"}
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: ComponentsPreviewType,
                  metadata: {
                    componentType: EXPORT_CABLE_MENU_ID,
                    isLibraryResource,
                    inLibraryContext,
                  },
                });
              }}
            >
              <TableIcon />
            </IconBtn>
            {inLibraryContext && (
              <HelpTooltip
                text={"Using cost specified in the procurement table"}
              />
            )}
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOverrideCost(localConfig?.capex.fixed.exportCable) &&
                  localConfig?.capex.fixed.exportCable.unit) ||
                CostUnit.millionEuroPerKM
              }
              units={[CostUnit.millionEuroPerKM, CostUnit.millionEuroPerMW]}
              type="number"
              value={
                isOverrideCost(localConfig?.capex.fixed.exportCable)
                  ? localConfig.capex.fixed.exportCable.cost ??
                    DefaultCosts[CostType.ExportCable].exportCable[
                      CostUnit.millionEuroPerKM
                    ]
                  : undefined
              }
              initialValue={
                isOverrideCost(configuration?.capex.fixed.exportCable)
                  ? configuration.capex.fixed.exportCable.cost ??
                    DefaultCosts[CostType.ExportCable].exportCable[
                      CostUnit.millionEuroPerKM
                    ]
                  : undefined
              }
              changelogInfo={financialChangelogInfo(
                configuration.id,
                nodeId,
                "capex.fixed.exportCable.cost",
                category,
              )}
              onUnitChange={(unit: CostUnit) =>
                updateFixedCapex({
                  exportCable: {
                    cost: (
                      DefaultCosts[CostType.ExportCable].exportCable as any
                    )[unit],
                    unit,
                  },
                })
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.fixed.exportCable &&
                  isOverrideCost(localConfig?.capex.fixed.exportCable)
                )
              }
              onChange={(cost) =>
                updateFixedCapex(
                  {
                    cost,
                  },
                  "exportCable",
                )
              }
              style={{
                width: "100%",
              }}
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!isFeatureCost(localConfig?.capex.fixed.exportCable) ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (localConfig?.capex.fixed.exportCable === "feature_cost")
                  return;
                updateFixedCapex({
                  exportCable: {
                    ...localConfig.capex.fixed.exportCable,
                    confidenceLevel: confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig.capex.fixed.exportCable?.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from components"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.fixed.exportCableFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateFixedCapex({
                  exportCableFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}

        <Button
          size="small"
          icon={
            isFeatureCost(localConfig?.capex.fixed.exportCable) ? (
              <PencilIcon />
            ) : (
              <TableIcon />
            )
          }
          buttonType="secondary"
          text={
            isFeatureCost(localConfig?.capex.fixed.exportCable)
              ? "Override"
              : "Use table"
          }
          onClick={
            isFeatureCost(localConfig?.capex.fixed.exportCable)
              ? handleExportCableCostTypeChange("override")
              : handleExportCableCostTypeChange("feature_cost")
          }
          disabled={isReadOnly}
        />
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

const ExportCableInstallationCosts = ({
  isReadOnly,
  nodeId,
  category,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();
  const isOnshoreConfig = configuration.type === "onshore";

  const handleTurbineCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ exportCable: type });
      } else {
        updateInstallationCapex({
          exportCable: {
            unit: CostUnit.millionEuroPerKM,
            cost: DefaultCosts[CostType.ExportCable].installation[
              CostUnit.millionEuroPerKM
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.exportCable) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.exportCable,
                ) &&
                  localConfig?.capex.installation.exportCable.unit) ||
                CostUnit.millionEuroPerKM
              }
              units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
              type="number"
              value={
                isOperationsOverrideCost(
                  localConfig?.capex.installation.exportCable,
                )
                  ? localConfig.capex.installation.exportCable.cost ??
                    DefaultCosts[CostType.ExportCable].installation[
                      CostUnit.millionEuroPerKM
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.exportCable,
                )
                  ? configuration.capex.installation.exportCable.cost ??
                    DefaultCosts[CostType.ExportCable].installation[
                      CostUnit.millionEuroPerKM
                    ]
                  : undefined
              }
              onChange={(cost) =>
                updateInstallationCapex({ cost }, "exportCable")
              }
              onUnitChange={(unit: CostUnit) =>
                updateInstallationCapex({
                  exportCable: {
                    cost: (
                      DefaultCosts[CostType.ExportCable].installation as any
                    )[unit],
                    unit,
                  },
                })
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.exportCable.cost",
                category,
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.exportCable &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.exportCable,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(
            localConfig?.capex.installation.exportCable === "operations_cost"
          ) ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.exportCable ===
                  "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  exportCable: {
                    ...localConfig?.capex.installation.exportCable,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.exportCable.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.installation.exportCableFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  exportCableFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.exportCable) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.exportCable)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.exportCable)
                ? handleTurbineCostTypeChange("override")
                : handleTurbineCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const ExportCableCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Export cables"
      category={CostType.ExportCable}
      configuration={configuration}
      isReadOnly={isReadOnly}
      units={[
        CostUnit.millionEuroPerKM,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuro,
        CostUnit.millionEuroPerExportCable,
        CostUnit.millionEuroPerOffshoreKM,
        CostUnit.millionEuroPerOnshoreKM,
      ]}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
      icon={<ExportCable />}
    >
      <ExportCableSpecificCosts
        isReadOnly={isReadOnly}
        configuration={configuration}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <ExportCableInstallationCosts
        isReadOnly={isReadOnly}
        configuration={configuration}
        nodeId={nodeId}
        category={isLibraryResource ? "org_financial_manage" : "project"}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
