import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  ABLY_THREAD_REACTIONS_ADD_FILTER,
  ABLY_THREAD_REACTIONS_REMOVE_FILTER,
} from "../../../state/ably";
import { postThreadReaction, deleteThreadReaction } from "../service";
import {
  userReactionSaveInProgressState,
  threadsAtomFamily,
  threadReactionsCounterAtomFamily,
  userReactionsAtomFamily,
} from "../state";
import { Reaction } from "../types";
import { useAblyPublish } from "../../../hooks/useAblyPublish";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { aset } from "utils/jotai";

export const useThreadReactionCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const ablyPublish = useAblyPublish(projectNodeId);
  const setReactionSaveInProgress = useSetAtom(userReactionSaveInProgressState);

  const post = useAtomCallback(
    useCallback(
      async (get, set, threadId: string) => {
        if (!projectNodeId) return;
        setReactionSaveInProgress(threadId);
        const res = await postThreadReaction(
          projectNodeId,
          branchId,
          threadId,
          {
            reaction: "like",
          },
        ).finally(() => setReactionSaveInProgress(undefined));

        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) =>
            cur.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    reactions: c.reactions + 1,
                  }
                : c,
            ),
        );

        set(
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
        await aset(
          get,
          set,
          userReactionsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return [...cur, res];
          },
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_THREAD_REACTIONS_ADD_FILTER,
          res,
        );
        return res;
      },
      [ablyPublish, projectNodeId, branchId, setReactionSaveInProgress],
    ),
  );

  const remove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, reactionId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    reactions: c.reactions - 1,
                  }
                : c,
            );
          },
        );
        set(
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
        await aset(
          get,
          set,
          userReactionsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.filter((c) => c.reactionId !== reactionId);
          },
        );
        const res = await deleteThreadReaction(
          projectNodeId,
          branchId,
          threadId,
          reactionId,
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_THREAD_REACTIONS_REMOVE_FILTER,
          {
            threadId,
          },
        );
        return res;
      },
      [ablyPublish, branchId, projectNodeId],
    ),
  );

  const localPost = useAtomCallback(
    useCallback(
      async (get, set, reaction: Reaction) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === reaction.threadId
                ? {
                    ...c,
                    reactions: c.reactions + 1,
                  }
                : c,
            );
          },
        );

        set(
          threadReactionsCounterAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
            threadId: reaction.threadId,
          }),
          (cur) => cur + 1,
        );
      },
      [projectNodeId],
    ),
  );

  const localRemove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    reactions: c.reactions - 1,
                  }
                : c,
            );
          },
        );

        set(
          threadReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => cur + 1,
        );
      },
      [branchId, projectNodeId],
    ),
  );

  return {
    post,
    remove,
    localPost,
    localRemove,
  };
};
