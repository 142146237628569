import { useSetAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MeanSpeedGrid } from "state/windStatistics";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import SpeedGrid from "layers/speedGrid";
import { currentMapStyleIdAtom } from "state/map";
import { MapWrapper } from "components/Organisation/Portfolio/style";
import { toastMessagesAtom } from "state/toast";
import { useJotaiCallback } from "utils/jotai";

export const ShowGridFile = ({
  data,
}: {
  data?: Promise<MeanSpeedGrid | undefined>;
}) => {
  const [grid, setGrid] = useState<MeanSpeedGrid | undefined>(undefined);
  const setToastMessage = useSetAtom(toastMessagesAtom);

  useEffect(() => {
    if (!data) return;
    data
      .then((res) => setGrid(res))
      .catch((err) => {
        setToastMessage((tm) => [
          ...tm,
          {
            text: err.message,
            timeout: 5000,
            type: "error",
          },
        ]);
      });
    return () => setGrid(undefined);
  }, [data, setToastMessage]);

  return <ShowGridFileInner grid={grid} />;
};

export const ShowGridFileInner = ({
  grid,
}: {
  grid: MeanSpeedGrid | undefined;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  const getActiveMapStyleId = useJotaiCallback(
    (get) => get(currentMapStyleIdAtom),
    [],
  );

  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map>();

  const bounds: LngLatBoundsLike | undefined = useMemo(() => {
    if (!grid) return;
    return [
      grid.xllcorner,
      grid.yllcorner,
      grid.xllcorner + grid.dx * grid.ncols,
      grid.yllcorner + grid.dy * grid.nrows,
    ];
  }, [grid]);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: getActiveMapStyleId(),
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      dragRotate: false,
    });
    map.on("load", (e) => {
      setMapLoaded(e.target);
    });

    return () => {
      map.remove();
      setMapLoaded(undefined);
    };
  }, [getActiveMapStyleId]);

  useEffect(() => {
    if (!bounds) return;
    mapLoaded?.fitBounds(bounds, {
      padding: 30,
      animate: false,
    });
  }, [bounds, mapLoaded]);

  const minSpeed = useMemo(() => {
    if (!grid) return;
    return grid.grid
      .flatMap((v) => v)
      .reduce((acc, v) => (v > 0 ? Math.min(acc, v) : acc), 10);
  }, [grid]);

  const maxSpeed = useMemo(() => {
    if (!grid) return;
    return grid.grid
      .flatMap((v) => v)
      .reduce((acc, v) => (v > 0 ? Math.max(acc, v) : acc), 0);
  }, [grid]);

  const meanSpeedGrid: MeanSpeedGrid | undefined = useMemo(() => {
    if (!minSpeed || !maxSpeed || !grid) return;
    return {
      ncols: grid.ncols,
      nrows: grid.nrows,
      dx: grid.dx,
      dy: grid.dy,
      xllcorner: grid.xllcorner,
      yllcorner: grid.yllcorner,
      grid: grid.grid,
      //Not used, but required by SpeedGrid
      height: 100,
    };
  }, [grid, maxSpeed, minSpeed]);

  return (
    <>
      <div
        style={{
          height: "40vh",
        }}
      >
        <MapWrapper id="grid-map" ref={mapContainer} />
        {mapLoaded && meanSpeedGrid && (
          <SpeedGrid meanSpeedGrid={meanSpeedGrid} map={mapLoaded} />
        )}
      </div>
    </>
  );
};
