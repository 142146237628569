/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const TurbineIdentifiers = z
      .object({
        /** The uuid of the turbine */
        id: z.string(),
        /** The name of the turbine */
        name: z.string(),
        /** The rated power of the turbine */
        ratedPower: z.number(),
        /** The hub height of the turbine */
        hubHeight: z.number(),
        /** Diameter fo the rotor of the turbine */
        rotorDiameter: z.number(),
        /** The cost specified on the component */
        legacyCost: z.number().optional(),
      })
      .passthrough();
    export const IAVoltageType = z.number();
    export const CostUnit = z.union([
      z.literal("m€/unit"),
      z.literal("m€/OSS"),
      z.literal("m€/ONS"),
      z.literal("m€/MW"),
      z.literal("m€/km"),
      z.literal("m€/off.km"),
      z.literal("m€/ons.km"),
      z.literal("k€/MW"),
      z.literal("k€/MW/y"),
      z.literal("k€/day"),
      z.literal("€/km"),
      z.literal("m€/cable"),
      z.literal("m€/exp.cable"),
      z.literal("€/m³"),
      z.literal("€/t"),
      z.literal("€/MWh"),
      z.literal("m€/line"),
      z.literal("m€"),
      z.literal("GWh"),
      z.literal("%"),
      z.literal("years"),
    ]);
    export const TurbineLevel = z.union([
      z.literal("project"),
      z.literal("library"),
      z.literal("standard"),
    ]);
    export const TurbineLoss = z
      .object({
        name: z.string(),
        value: z.number(),
      })
      .passthrough();
    export const BaseTurbine = z
      .object({
        nodeId: z.string().optional(),
        id: z.string(),
        name: z.string(),
        description: z.string().nullable().optional(),
        note: z.string().nullable().optional(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        diameter: z.number(),
        ratedPower: z.number().optional(),
        peakThrustSpeed: z.number().optional(),
        peakThrustCt: z.number().nullable().optional(),
        archived: z.boolean().optional(),
        /** Component cost is moved to procurement table */
        cost: z.number().optional(),
        costUnit: CostUnit.optional(),
        voltage: z.array(IAVoltageType).optional().default([33, 66, 132]),
      })
      .passthrough();
    export const SimpleTurbineType = z
      .object({
        nodeId: z.string().optional(),
        id: z.string(),
        name: z.string(),
        description: z.string().nullable().optional(),
        note: z.string().nullable().optional(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        diameter: z.number(),
        ratedPower: z.number(),
        peakThrustSpeed: z.number().optional(),
        peakThrustCt: z.number().nullable().optional(),
        archived: z.boolean().optional(),
        /** Component cost is moved to procurement table */
        cost: z.number().optional(),
        costUnit: CostUnit.optional(),
        voltage: z.array(IAVoltageType).optional().default([33, 66, 132]),
      })
      .passthrough();
    export const SimpleTurbineTypeWithLevel = z
      .object({
        level: TurbineLevel,
        turbine: SimpleTurbineType,
      })
      .passthrough();
    export const TurbineType = z
      .object({
        nodeId: z.string().optional(),
        id: z.string(),
        name: z.string(),
        description: z.string().nullable().optional(),
        note: z.string().nullable().optional(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        diameter: z.number(),
        ratedPower: z.number().optional(),
        peakThrustSpeed: z.number().optional(),
        peakThrustCt: z.number().nullable().optional(),
        archived: z.boolean().optional(),
        /** Component cost is moved to procurement table */
        cost: z.number().optional(),
        costUnit: z
          .union([
            z.literal("m€/unit"),
            z.literal("m€/OSS"),
            z.literal("m€/ONS"),
            z.literal("m€/MW"),
            z.literal("m€/km"),
            z.literal("m€/off.km"),
            z.literal("m€/ons.km"),
            z.literal("k€/MW"),
            z.literal("k€/MW/y"),
            z.literal("k€/day"),
            z.literal("€/km"),
            z.literal("m€/cable"),
            z.literal("m€/exp.cable"),
            z.literal("€/m³"),
            z.literal("€/t"),
            z.literal("€/MWh"),
            z.literal("m€/line"),
            z.literal("m€"),
            z.literal("GWh"),
            z.literal("%"),
            z.literal("years"),
          ])
          .optional(),
        voltage: z.array(z.number()).optional().default([33, 66, 132]),
      })
      .passthrough()
      .and(
        z
          .object({
            windSpeed: z.array(z.number()),
            power: z.array(z.number()),
            powerUnit: z.string(),
            thrustCoefficient: z.array(z.number()),
            referenceAirDensity: z.number().default(1.225),
            losses: z
              .array(
                z
                  .object({
                    name: z.string(),
                    value: z.number(),
                  })
                  .passthrough(),
              )
              .optional(),
            author: z.string().optional(),
            createdAt: z.number().optional(),
          })
          .passthrough(),
      );
    export const ErrorResponse = z
      .object({
        error: z.string(),
      })
      .passthrough();
    export const ScalingLawType = z.literal("Mass scaling");
    export const FoundationTypeIds = z.union([
      z.literal("semi_central"),
      z.literal("semi_peripheral"),
      z.literal("spar"),
      z.literal("monopile"),
      z.literal("detailed_monopile"),
      z.literal("jacket"),
      z.literal("detailed_jacket"),
    ]);
    export const SimpleFoundationType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: FoundationTypeIds,
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough();
    export const SemiCentralType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            rotorDiameter: z.number(),
            ratedPower: z.number(),
            hubHeight: z.number(),
            rnaMass: z.number(),
            towerMass: z.number(),
            draft: z.number(),
            cornerColDiameter: z.number(),
            centralColDiameterTop: z.number(),
            centralColDiameterBase: z.number(),
            cornerColStart: z.number(),
            centralColStart: z.number(),
            cornerColHeight: z.number(),
            centralColHeight: z.number(),
            ponWidth: z.number(),
            ponHeight: z.number(),
            ponStart: z.number(),
            ccDistance: z.number(),
            primaryMass: z.number(),
            primaryDensity: z.number(),
            solidBallastMass: z.number(),
            liquidBallastMass: z.number(),
            displacement: z.number(),
            reinforceDensity: z.number().optional(),
            postTensDensity: z.number().optional(),
            scalingLaw: z.literal("Mass scaling"),
            fairZ: z.number().optional(),
            fairRadius: z.number().optional(),
          })
          .passthrough(),
      );
    export const SemiPeripheralType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            rotorDiameter: z.number(),
            ratedPower: z.number(),
            hubHeight: z.number(),
            rnaMass: z.number(),
            towerMass: z.number(),
            draft: z.number(),
            cornerColDiameter: z.number(),
            cornerColHeight: z.number(),
            cornerColStart: z.number(),
            ponWidth: z.number(),
            ponHeight: z.number(),
            ponStart: z.number(),
            deckWidth: z.number(),
            deckHeight: z.number(),
            deckStart: z.number(),
            ccDistance: z.number(),
            primaryMass: z.number(),
            primaryDensity: z.number(),
            solidBallastMass: z.number(),
            liquidBallastMass: z.number(),
            displacement: z.number(),
            reinforceDensity: z.number().optional(),
            postTensDensity: z.number().optional(),
            scalingLaw: z.literal("Mass scaling"),
            fairZ: z.number().optional(),
            fairRadius: z.number().optional(),
          })
          .passthrough(),
      );
    export const SparType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            rotorDiameter: z.number(),
            ratedPower: z.number(),
            hubHeight: z.number(),
            rnaMass: z.number(),
            towerMass: z.number(),
            draft: z.number(),
            colHeight: z.number(),
            topDiameter: z.number(),
            baseDiameter: z.number(),
            upperTaperLevel: z.number(),
            lowerTaperLevel: z.number(),
            primaryMass: z.number(),
            primaryDensity: z.number(),
            solidBallastMass: z.number(),
            liquidBallastMass: z.number(),
            displacement: z.number(),
            reinforceDensity: z.number().optional(),
            postTensDensity: z.number().optional(),
            scalingLaw: z.literal("Mass scaling"),
            fairZ: z.number().optional(),
            fairRadius: z.number().optional(),
          })
          .passthrough(),
      );
    export const SoilTypes = z.union([z.literal("sand"), z.literal("clay")]);
    export const PileData = z
      .object({
        pileDiameter: z.number(),
        avgPileThickness: z.number(),
        embedLength: z.number(),
        totalPileLength: z.number(),
      })
      .passthrough()
      .nullable();
    export const MonopileParameters = z
      .object({
        soilCoeffSubReact: z.number(),
        ratedPower: z.number(),
        rotorDiameter: z.number(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        waterDepth: z.number(),
        hs50Yr: z.number(),
      })
      .passthrough();
    export const JacketParameters = z
      .object({
        ratedPower: z.number(),
        rotorDiameter: z.number(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        soilType: SoilTypes,
        frictionAngle: z.number(),
        shearStrength: z.number(),
        unitWeight: z.number(),
        waterDepth: z.number(),
        hs50Yr: z.number(),
        numLegs: z.number(),
      })
      .passthrough();
    export const JacketDimensions = z
      .object({
        pileMass: z.number(),
        legDiameter: z.number(),
        legThickness: z.number(),
        braceDiameter: z.number(),
        braceThickness: z.number(),
        Ltop: z.number(),
        Lbottom: z.number(),
        jacketHeight: z.number(),
        numBays: z.number(),
        tpMass: z.number(),
      })
      .passthrough()
      .nullable();
    export const EquationType = z
      .object({
        scalingEquation: z.string(),
        minRatedPower: z.number().default(5000),
        maxRatedPower: z.number().default(30000),
        minWaterDepth: z.number().default(10),
        maxWaterDepth: z.number().default(70),
      })
      .passthrough();
    export const MonopileType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            scalingEquation: z.string(),
            minRatedPower: z.number().default(5000),
            maxRatedPower: z.number().default(30000),
            minWaterDepth: z.number().default(10),
            maxWaterDepth: z.number().default(70),
          })
          .passthrough(),
      );
    export const JacketType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            scalingEquation: z.string(),
            minRatedPower: z.number().default(5000),
            maxRatedPower: z.number().default(30000),
            minWaterDepth: z.number().default(10),
            maxWaterDepth: z.number().default(70),
          })
          .passthrough(),
      );
    export const DetailedMonopileType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            soilCoeffSubReact: z.number(),
            ratedPower: z.number(),
            rotorDiameter: z.number(),
            hubHeight: z.number(),
            rnaMass: z.number(),
            waterDepth: z.number(),
            hs50Yr: z.number(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            pileDiameter: z.number(),
            avgPileThickness: z.number(),
            embedLength: z.number(),
            totalPileLength: z.number(),
          })
          .passthrough()
          .nullable(),
      );
    export const DetailedJacketType = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.union([
          z.literal("semi_central"),
          z.literal("semi_peripheral"),
          z.literal("spar"),
          z.literal("monopile"),
          z.literal("detailed_monopile"),
          z.literal("jacket"),
          z.literal("detailed_jacket"),
        ]),
        material: z.string(),
        info: z.string(),
        description: z.string().optional(),
        archived: z.boolean().optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            ratedPower: z.number(),
            rotorDiameter: z.number(),
            hubHeight: z.number(),
            rnaMass: z.number(),
            soilType: z.union([z.literal("sand"), z.literal("clay")]),
            frictionAngle: z.number(),
            shearStrength: z.number(),
            unitWeight: z.number(),
            waterDepth: z.number(),
            hs50Yr: z.number(),
            numLegs: z.number(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            pileMass: z.number(),
            legDiameter: z.number(),
            legThickness: z.number(),
            braceDiameter: z.number(),
            braceThickness: z.number(),
            Ltop: z.number(),
            Lbottom: z.number(),
            jacketHeight: z.number(),
            numBays: z.number(),
            tpMass: z.number(),
          })
          .passthrough()
          .nullable(),
      );
    export const FloaterType = z.union([
      SemiCentralType,
      SemiPeripheralType,
      SparType,
    ]);
    export const FixedType = z.union([
      JacketType,
      MonopileType,
      DetailedMonopileType,
      DetailedJacketType,
    ]);
    export const FoundationType = z
      .union([
        z.union([
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  rotorDiameter: z.number(),
                  ratedPower: z.number(),
                  hubHeight: z.number(),
                  rnaMass: z.number(),
                  towerMass: z.number(),
                  draft: z.number(),
                  cornerColDiameter: z.number(),
                  centralColDiameterTop: z.number(),
                  centralColDiameterBase: z.number(),
                  cornerColStart: z.number(),
                  centralColStart: z.number(),
                  cornerColHeight: z.number(),
                  centralColHeight: z.number(),
                  ponWidth: z.number(),
                  ponHeight: z.number(),
                  ponStart: z.number(),
                  ccDistance: z.number(),
                  primaryMass: z.number(),
                  primaryDensity: z.number(),
                  solidBallastMass: z.number(),
                  liquidBallastMass: z.number(),
                  displacement: z.number(),
                  reinforceDensity: z.number().optional(),
                  postTensDensity: z.number().optional(),
                  scalingLaw: z.literal("Mass scaling"),
                  fairZ: z.number().optional(),
                  fairRadius: z.number().optional(),
                })
                .passthrough(),
            ),
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  rotorDiameter: z.number(),
                  ratedPower: z.number(),
                  hubHeight: z.number(),
                  rnaMass: z.number(),
                  towerMass: z.number(),
                  draft: z.number(),
                  cornerColDiameter: z.number(),
                  cornerColHeight: z.number(),
                  cornerColStart: z.number(),
                  ponWidth: z.number(),
                  ponHeight: z.number(),
                  ponStart: z.number(),
                  deckWidth: z.number(),
                  deckHeight: z.number(),
                  deckStart: z.number(),
                  ccDistance: z.number(),
                  primaryMass: z.number(),
                  primaryDensity: z.number(),
                  solidBallastMass: z.number(),
                  liquidBallastMass: z.number(),
                  displacement: z.number(),
                  reinforceDensity: z.number().optional(),
                  postTensDensity: z.number().optional(),
                  scalingLaw: z.literal("Mass scaling"),
                  fairZ: z.number().optional(),
                  fairRadius: z.number().optional(),
                })
                .passthrough(),
            ),
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  rotorDiameter: z.number(),
                  ratedPower: z.number(),
                  hubHeight: z.number(),
                  rnaMass: z.number(),
                  towerMass: z.number(),
                  draft: z.number(),
                  colHeight: z.number(),
                  topDiameter: z.number(),
                  baseDiameter: z.number(),
                  upperTaperLevel: z.number(),
                  lowerTaperLevel: z.number(),
                  primaryMass: z.number(),
                  primaryDensity: z.number(),
                  solidBallastMass: z.number(),
                  liquidBallastMass: z.number(),
                  displacement: z.number(),
                  reinforceDensity: z.number().optional(),
                  postTensDensity: z.number().optional(),
                  scalingLaw: z.literal("Mass scaling"),
                  fairZ: z.number().optional(),
                  fairRadius: z.number().optional(),
                })
                .passthrough(),
            ),
        ]),
        z.union([
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  scalingEquation: z.string(),
                  minRatedPower: z.number().default(5000),
                  maxRatedPower: z.number().default(30000),
                  minWaterDepth: z.number().default(10),
                  maxWaterDepth: z.number().default(70),
                })
                .passthrough(),
            ),
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  scalingEquation: z.string(),
                  minRatedPower: z.number().default(5000),
                  maxRatedPower: z.number().default(30000),
                  minWaterDepth: z.number().default(10),
                  maxWaterDepth: z.number().default(70),
                })
                .passthrough(),
            ),
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  soilCoeffSubReact: z.number(),
                  ratedPower: z.number(),
                  rotorDiameter: z.number(),
                  hubHeight: z.number(),
                  rnaMass: z.number(),
                  waterDepth: z.number(),
                  hs50Yr: z.number(),
                })
                .passthrough(),
            )
            .and(
              z
                .object({
                  pileDiameter: z.number(),
                  avgPileThickness: z.number(),
                  embedLength: z.number(),
                  totalPileLength: z.number(),
                })
                .passthrough()
                .nullable(),
            ),
          z
            .object({
              id: z.string(),
              name: z.string(),
              type: z.union([
                z.literal("semi_central"),
                z.literal("semi_peripheral"),
                z.literal("spar"),
                z.literal("monopile"),
                z.literal("detailed_monopile"),
                z.literal("jacket"),
                z.literal("detailed_jacket"),
              ]),
              material: z.string(),
              info: z.string(),
              description: z.string().optional(),
              archived: z.boolean().optional(),
            })
            .passthrough()
            .and(
              z
                .object({
                  ratedPower: z.number(),
                  rotorDiameter: z.number(),
                  hubHeight: z.number(),
                  rnaMass: z.number(),
                  soilType: z.union([z.literal("sand"), z.literal("clay")]),
                  frictionAngle: z.number(),
                  shearStrength: z.number(),
                  unitWeight: z.number(),
                  waterDepth: z.number(),
                  hs50Yr: z.number(),
                  numLegs: z.number(),
                })
                .passthrough(),
            )
            .and(
              z
                .object({
                  pileMass: z.number(),
                  legDiameter: z.number(),
                  legThickness: z.number(),
                  braceDiameter: z.number(),
                  braceThickness: z.number(),
                  Ltop: z.number(),
                  Lbottom: z.number(),
                  jacketHeight: z.number(),
                  numBays: z.number(),
                  tpMass: z.number(),
                })
                .passthrough()
                .nullable(),
            ),
        ]),
      ])
      .and(
        z
          .object({
            author: z.string().optional(),
            createdAt: z.number().optional(),
          })
          .passthrough(),
      );
  }
}
export const components = {
  schemas: {
    TurbineIdentifiers: Components.Schemas.TurbineIdentifiers,
    /** Voltage levels in kV */
    IAVoltageType: Components.Schemas.IAVoltageType,
    CostUnit: Components.Schemas.CostUnit,
    TurbineLevel: Components.Schemas.TurbineLevel,
    TurbineLoss: Components.Schemas.TurbineLoss,
    BaseTurbine: Components.Schemas.BaseTurbine,
    SimpleTurbineType: Components.Schemas.SimpleTurbineType,
    SimpleTurbineTypeWithLevel: Components.Schemas.SimpleTurbineTypeWithLevel,
    TurbineType: Components.Schemas.TurbineType,
    ErrorResponse: Components.Schemas.ErrorResponse,
    ScalingLawType: Components.Schemas.ScalingLawType,
    FoundationTypeIds: Components.Schemas.FoundationTypeIds,
    SimpleFoundationType: Components.Schemas.SimpleFoundationType,
    SemiCentralType: Components.Schemas.SemiCentralType,
    SemiPeripheralType: Components.Schemas.SemiPeripheralType,
    SparType: Components.Schemas.SparType,
    SoilTypes: Components.Schemas.SoilTypes,
    PileData: Components.Schemas.PileData,
    MonopileParameters: Components.Schemas.MonopileParameters,
    JacketParameters: Components.Schemas.JacketParameters,
    JacketDimensions: Components.Schemas.JacketDimensions,
    EquationType: Components.Schemas.EquationType,
    MonopileType: Components.Schemas.MonopileType,
    JacketType: Components.Schemas.JacketType,
    DetailedMonopileType: Components.Schemas.DetailedMonopileType,
    DetailedJacketType: Components.Schemas.DetailedJacketType,
    FloaterType: Components.Schemas.FloaterType,
    FixedType: Components.Schemas.FixedType,
    FoundationType: Components.Schemas.FoundationType,
  },
};

export const paths = {
  "/api/turbines/organisation/{organisationId}/{turbineId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        turbineId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            turbine: Components.Schemas.TurbineType,
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Turbine updated */
          "application/json": Components.Schemas.TurbineType,
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
        404: {
          /** Turbine not found */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/turbines/node/{nodeId}/{turbineId}": {
    put: {
      pathParams: z.object({
        nodeId: z.string(),
        turbineId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            turbine: Components.Schemas.TurbineType,
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Turbine updated */
          "application/json": Components.Schemas.TurbineType,
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
        404: {
          /** Turbine not found */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/turbines/organisation/{organisationId}/identifiers": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Successfully listed all library turbine identifiers */
          "application/json": z.array(Components.Schemas.TurbineIdentifiers),
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/turbines/organisation/{organisationId}/foundations/{foundationId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        foundationId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            foundation: Components.Schemas.FoundationType,
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Foundation updated */
          "application/json": Components.Schemas.FoundationType,
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
        404: {
          /** Foundation not found */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/turbines/node/{nodeId}/foundations/{foundationId}": {
    put: {
      pathParams: z.object({
        nodeId: z.string(),
        foundationId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            foundation: Components.Schemas.FoundationType,
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Foundation updated */
          "application/json": Components.Schemas.FoundationType,
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
        404: {
          /** Foundation not found */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/turbine-store-func-prod-getTurbinesInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** List of turbines */
          "application/json": z.array(Components.Schemas.SimpleTurbineType),
        },
      },
    },
  },
  "/invoke/turbine-store-func-prod-getTurbineInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            turbineId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Simple turbine */
          "application/json": Components.Schemas.SimpleTurbineType,
        },
        404: {
          /** Turbine not found */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/turbine-store-func-prod-getCompleteTurbines": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** List of turbines */
          "application/json": z.array(Components.Schemas.TurbineType),
        },
        404: {
          /** Turbine not found */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/turbine-store-func-prod-getFoundationCountInternal": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            orgId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Number of non-archived foundations */
          "application/json": z.number(),
        },
      },
    },
  },
  "/invoke/turbine-store-func-prod-getTurbineCountInternal": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            orgId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Number of non-archived turbines */
          "application/json": z.number(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
