import { useAtomValue } from "jotai";
import React from "react";
import { loggedInUserAtom } from "state/user";
import { IconButtonStyler } from "components/Design/TopbarNavigation/components/RightSide/style";
import { colors } from "styles/colors";
import { MenuButton } from "components/General/MenuButton";
import { IconREMSize } from "styles/typography";
import ArrowDownIcon from "@icons/24/ArrowDown.svg";
import UserImageRound from "components/UserImage/UserImageRound";
import { spacing4, spacing6 } from "styles/space";

const ProfileDropdownMenuButton = ({ children }: React.PropsWithChildren) => {
  const user = useAtomValue(loggedInUserAtom);

  if (!user) {
    return null;
  }

  return (
    <IconButtonStyler
      backgroundColor={colors.blue800}
      hoverBackgroundColor={colors.grey900}
    >
      <MenuButton
        icon={
          <IconREMSize height={1} width={1} stroke="white">
            <ArrowDownIcon />
          </IconREMSize>
        }
        iconPosition="right"
        buttonText={<UserImageRound size={2.2} user={user} />}
        divStyle={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        buttonType="primary"
        buttonStyle={{
          alignItems: "center",
          borderRadius: "2.5rem",
          gap: "1rem",
          border: "none",
          padding: `${spacing4} ${spacing6} ${spacing4} ${spacing4}`,
        }}
      >
        {children}
      </MenuButton>
    </IconButtonStyler>
  );
};

export default ProfileDropdownMenuButton;
