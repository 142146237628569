import { useMemo } from "react";
import * as turf from "@turf/turf";
import { FillPaint, LinePaint } from "mapbox-gl";
import {
  turbineEllipsesFillLayerId,
  turbineEllipsesFillSourceId,
  turbineEllipsesLineLayerId,
  turbineEllipsesLineSourceId,
} from "./constants";
import { previewTurbinesState } from "../../state/turbines";
import { colors } from "../../styles/colors";
import Polygon from "../MapFeatures/Polygon";
import LineString from "../MapFeatures/LineString";
import { SubAreaFeature } from "../../types/feature";
import { ParkFeature } from "../../types/feature";
import { distanceToZone, pointInPolygon } from "../../utils/geometry";
import { SimpleTurbineType } from "types/turbines";
import { partition } from "utils/utils";
import { sendWarning } from "utils/sentry";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { useAtomValue } from "jotai";

const linePaint: LinePaint = {
  "line-color": colors.primary,
  "line-width": 1,
  "line-opacity": 0.25,
};

const fillPaint: FillPaint = {
  "fill-color": colors.primary,
  "fill-opacity": 0.15,
};

export const TurbineEllipses = ({
  map,
  region,
  parkId,
  minorAxisSpacing,
  majorAxisSpacing,
  edgeSpacing,
  rotation,
  turbineType,
}: {
  map: mapboxgl.Map;
  region: ParkFeature | SubAreaFeature;
  parkId: string;
  minorAxisSpacing: number;
  majorAxisSpacing: number;
  rotation: number;
  edgeSpacing: number | undefined;
  turbineType: SimpleTurbineType | undefined;
}) => {
  const previewState = useAtomValue(previewTurbinesState);
  const turbines = useAtomValue(
    turbinesInParkFamily({ parkId, branchId: undefined }),
  );
  const usedTurbines = useMemo(() => {
    if (previewState) {
      return previewState.preview.filter((t) =>
        pointInPolygon(t.geometry, region.geometry),
      );
    } else {
      return turbines.filter((t) =>
        pointInPolygon(t.geometry, region.geometry),
      );
    }
  }, [previewState, region.geometry, turbines]);

  const { sx, sy, angle } = useMemo(() => {
    // Turf samples an ellipse in a pretty bad way; if the primary axis is a lot
    // longer than the secondary axis, it looks real blockly at the ends of the
    // primary.  However, the opposite is not too bad, but also not great. See #1767
    // for a bug report on this.
    //
    // Our fix here is to ensure that the first axis is the shorter of the two,
    // and adjust the angle accordingly.   This leads to nicer elilpses.
    //
    // https://github.com/Turfjs/turf/issues/1767
    if (minorAxisSpacing < majorAxisSpacing) {
      return {
        sx: minorAxisSpacing / 2.0,
        sy: majorAxisSpacing / 2.0,
        angle: rotation,
      };
    } else {
      return {
        sx: majorAxisSpacing / 2.0,
        sy: minorAxisSpacing / 2.0,
        angle: rotation + 90,
      };
    }
  }, [rotation, minorAxisSpacing, majorAxisSpacing]);

  const turbineEllipses = useMemo(() => {
    if (sx == null || Math.abs(sx) < 1e-6)
      sendWarning("TurbineEllipses: sx bad", { sx });
    if (sy == null || Math.abs(sy) < 1e-6)
      sendWarning("TurbineEllipses: sy bad", { sy });

    if (usedTurbines.length === 0) return [];
    const baseCoordinates = usedTurbines[0].geometry.coordinates;

    const baseEllipse = turf.ellipse(baseCoordinates, sx, sy, {
      units: "meters",
      steps: 64,
      // @ts-ignore: Typing is wrong. See
      // https://github.com/Turfjs/turf/issues/1818
      angle,
    });
    // If not turbineType or edgeSpacing, all turbines are treated as regular (not edge) turbines.
    if (!turbineType || !edgeSpacing) {
      return usedTurbines.map((turbine) => {
        const distance = turf.rhumbDistance(
          baseCoordinates,
          turbine.geometry.coordinates,
          {
            units: "meters",
          },
        );
        const direction = turf.rhumbBearing(
          baseCoordinates,
          turbine.geometry.coordinates,
        );
        return turf.transformTranslate(baseEllipse, distance, direction, {
          units: "meters",
        });
      });
    }

    const [turbinesAlongEdge, turbinesInsidePark] = partition(
      usedTurbines,
      (t) => distanceToZone(t, region) < turbineType.diameter / 2,
    );

    const regularTurbineEllipses = turbinesInsidePark.map((turbine) => {
      const distance = turf.rhumbDistance(
        baseCoordinates,
        turbine.geometry.coordinates,
        {
          units: "meters",
        },
      );
      const direction = turf.rhumbBearing(
        baseCoordinates,
        turbine.geometry.coordinates,
      );
      return turf.transformTranslate(baseEllipse, distance, direction, {
        units: "meters",
      });
    });

    const edgeTurbineEllipses = turbinesAlongEdge.map((turbine) =>
      turf.ellipse(
        turbine.geometry.coordinates,
        edgeSpacing / 2.0,
        edgeSpacing / 2.0,
        {
          units: "meters",
          steps: 64,
          // @ts-ignore: Typing is wrong. See
          // https://github.com/Turfjs/turf/issues/1818
          angle,
        },
      ),
    );

    return [...regularTurbineEllipses, ...edgeTurbineEllipses];
  }, [turbineType, edgeSpacing, usedTurbines, sx, sy, angle, region]);

  return (
    <>
      <Polygon
        features={turbineEllipses}
        sourceId={turbineEllipsesFillSourceId}
        layerId={turbineEllipsesFillLayerId}
        map={map}
        paint={fillPaint}
      />
      <LineString
        features={turbineEllipses}
        sourceId={turbineEllipsesLineSourceId}
        layerId={turbineEllipsesLineLayerId}
        map={map}
        paint={linePaint}
      />
    </>
  );
};
