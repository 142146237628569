import Button from "components/General/Button";
import Checkbox from "components/General/Checkbox";
import FeatureFlag, {
  SHOW_DATA_CLEANING,
} from "components/General/FeatureFlag";
import SaveDataCleaningChanges from "components/LayerList/LayerDataCleaning/SaveDataCleaningChanges";
import { useAtom } from "jotai";
import React from "react";
import { refreshLayerSources } from "services/layerSourceRefreshAPI";
import { showHiddenLayersAtom } from "state/internalDataCleaning";
import { ViewMode, DataLayersTableWrapper } from "../../DataLayersModal";
import ActiveFilterPills from "../DataLayerFilters/ActiveFilterPills";
import Fallback from "../Fallback";
import ListView from "../ListView/ListView";
import styled from "styled-components";
import { Layer } from "types/layers";
import DataLayersGrid from "../GridView/DataLayersGrid";
import { selectedLayersToAddAtom } from "components/LayerList/LayerSettings/state";

const BrowseLayersFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  flex-grow: 0;
  padding: 0 1rem 0 2rem;
  width: 120rem;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

interface ExternalDataLayersModalProps {
  viewMode: ViewMode;
  selectedLayer: Layer | null;
  setSelectedLayer: (layer: Layer | null) => void;
  handleToggleFavorite?: (layerId: string) => void;
}

const ExternalDataLayersModal = ({
  viewMode,
  selectedLayer,
  setSelectedLayer,
  handleToggleFavorite,
}: ExternalDataLayersModalProps) => {
  const [showHiddenLayers, setShowHiddenLayers] = useAtom(showHiddenLayersAtom);
  const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
    selectedLayersToAddAtom,
  );

  return (
    <TableContainer>
      <BrowseLayersFilterWrapper>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <SaveDataCleaningChanges />
          <FeatureFlag name={SHOW_DATA_CLEANING}>
            <Button
              text="Refresh sources"
              onClick={() => {
                if (
                  window.confirm(
                    "Sources will be refreshed. If there are any new layers they will be visible in a few minutes.",
                  )
                ) {
                  refreshLayerSources();
                }
              }}
            />
            <Checkbox
              label="Show hidden layers"
              labelPlacement="after"
              checked={showHiddenLayers}
              onChange={() => setShowHiddenLayers((curr) => !curr)}
            />
          </FeatureFlag>
        </div>
      </BrowseLayersFilterWrapper>

      <React.Suspense fallback={null}>
        <ActiveFilterPills />
      </React.Suspense>
      <React.Suspense fallback={<Fallback />}>
        <DataLayersTableWrapper>
          {viewMode === "list" ? (
            <ListView
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              setSelectedLayersToAdd={setSelectedLayersToAdd}
              selectedLayersToAdd={selectedLayersToAdd}
              handleToggleFavorite={handleToggleFavorite}
            />
          ) : (
            <DataLayersGrid
              setSelectedLayer={setSelectedLayer}
              setSelectedLayersToAdd={setSelectedLayersToAdd}
              selectedLayersToAdd={selectedLayersToAdd}
              handleToggleFavorite={handleToggleFavorite}
            />
          )}
        </DataLayersTableWrapper>
      </React.Suspense>
    </TableContainer>
  );
};

export default ExternalDataLayersModal;
