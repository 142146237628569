import { fetchSchemaWithToken } from "services/utils";
import { FoundationType, _FoundationType } from "types/foundations";
import { TurbineType, _TurbineType } from "types/turbines";
import {
  OrgResource,
  OrganisationResources,
  _OrgResource,
} from "../OrganisationRightSide/types";
import {
  NodeAnalysisAccess,
  NodeDataPackageAccess,
  NodeFinancialAccess,
  NodeFoundationAccess,
  NodeLibraryResource,
  NodeTurbineAccess,
  UploadedVectorDataLayer,
  _NodeAnalysisAccess,
  _NodeDataPackageAccess,
  _NodeFinancialAccess,
  _NodeFoundationAccess,
  _NodeLibraryResource,
  _NodeTurbineAccess,
  _UploadedVectorDataLayer,
} from "./types";
import { CableType, _CableType } from "services/cableTypeService";

// -------------------- General resourses -------------------------------

export const getNodesWithAccessToLibraryResource = async (
  organisationId: string,
  resourceId: string,
  resource: OrganisationResources,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/role/${resource}/${resourceId}`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateResourceOnNode = async (
  nodeId: string,
  resourceId: string,
  resource: OrganisationResources,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/role/${resource}`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resource_id: resourceId }),
    },
  );
};

export const deleteResourceOnNode = async (
  nodeId: string,
  resourceId: string,
  resource: OrganisationResources,
): Promise<NodeLibraryResource[]> => {
  return fetchSchemaWithToken(
    _NodeLibraryResource.array(),
    `/api/library/libraryaccess/node/${nodeId}/role/${resource}/${resourceId}`,
    {
      method: "delete",
    },
  );
};

export const getResourceManagers = async (
  organisationId: string,
  resource: OrganisationResources,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/role/${resource}`,
    {
      method: "get",
    },
  );
};

export const getResourceManagersAdmin = async (
  organisationId: string,
  resource: OrganisationResources,
): Promise<OrgResource[]> => {
  return fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/library/libraryaccess/organisation/${organisationId}/managers/role/admin/${resource}`,
    {
      method: "get",
    },
  );
};

// -------------------- Turbine resources -------------------------------
export const getOrganisationTurbineResources = async (
  organisationId: string,
): Promise<TurbineType[]> => {
  return fetchSchemaWithToken(
    _TurbineType.array(),
    `/api/turbines/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

export const getTurbineResourcesOnNode = async (
  nodeId: string,
): Promise<NodeTurbineAccess[]> => {
  return fetchSchemaWithToken(
    _NodeTurbineAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/turbine/v2`,
    {
      method: "get",
    },
  );
};

// -------------------- Foundation resources -------------------------------
export const getOrganisationFoundationResources = async (
  organisationId: string,
): Promise<FoundationType[]> => {
  return fetchSchemaWithToken(
    _FoundationType.array(),
    `/api/turbines/organisation/${organisationId}/foundations`,
    {
      method: "get",
    },
  );
};

export const getFoundationResourcesOnNode = async (
  nodeId: string,
): Promise<NodeFoundationAccess[]> => {
  return fetchSchemaWithToken(
    _NodeFoundationAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/foundation/v2`,
    {
      method: "get",
    },
  );
};

// -------------------- Analysis resources -------------------------------

export const getAnalysisResourcesOnNode = async (
  nodeId: string,
): Promise<NodeAnalysisAccess[]> => {
  return fetchSchemaWithToken(
    _NodeAnalysisAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/analysis`,
    {
      method: "get",
    },
  );
};

// -------------------- Cable resources -------------------------------
export const getOrganisationInterArrayCableResources = async (
  organisationId: string,
): Promise<CableType[]> => {
  return fetchSchemaWithToken(
    _CableType.array(),
    `/api/cable/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

export const getOrganisationExportCableResources = async (
  organisationId: string,
): Promise<CableType[]> => {
  return fetchSchemaWithToken(
    _CableType.array(),
    `/api/exportCable/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
};

// -------------------- Financial resources -------------------------------
export const getFinancialResourcesOnNode = async (
  nodeId: string,
): Promise<NodeFinancialAccess[]> => {
  return fetchSchemaWithToken(
    _NodeFinancialAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/financial`,
    {
      method: "get",
    },
  );
};

// -------------------- Data package resources -------------------------------
export const getDataPackageResourcesOnNode = async (
  nodeId: string,
): Promise<NodeDataPackageAccess[]> => {
  return fetchSchemaWithToken(
    _NodeDataPackageAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/datapackage`,
    {
      method: "get",
    },
  );
};

export const getDataLayerResourcesOnNode = async (
  nodeId: string,
): Promise<UploadedVectorDataLayer[]> => {
  return fetchSchemaWithToken(
    _UploadedVectorDataLayer.array(),
    `/api/library/libraryaccess/node/${nodeId}/datalayer`,
    {
      method: "get",
    },
  );
};
