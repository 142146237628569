import { useAtom, useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { Suspense, useMemo } from "react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceHuge } from "../../../styles/space";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  MemberTabName,
  _MemberTabName,
  selectedMemberTabState,
} from "../state";
import {
  adminInOrganisationSelectorFamily,
  memberInOrganisationSelectorFamily,
} from "state/user";
import Tabs, { TabData } from "components/General/Tabs";
import Groups from "../Groups/Groups";
import Admin_OrganisationMembers from "components/Organisation/Members/Admin_OrganisationMembers";
import Member_OrganisationMembers from "components/Organisation/Members/Member_OrganisationMembers";
import { allOrganisationInvitationsAtomFamily } from "state/customer";

const MembersTabs = ErrorBoundaryWrapper(
  () => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const isMemberInOrg = useAtomValue(
      memberInOrganisationSelectorFamily({
        organisationId,
      }),
    );
    const isAdminInOrg = useAtomValue(
      adminInOrganisationSelectorFamily({
        organisationId,
      }),
    );
    const [selectedMemberTab, setSelectedMemberTab] = useAtom(
      selectedMemberTabState,
    );
    const pendingInvitations = useAtomValue(
      unwrap(allOrganisationInvitationsAtomFamily(organisationId)),
    );

    const memberTabs = useMemo(() => {
      if (!organisationId) return [];

      const items: TabData[] = [
        {
          id: "members",
          name: _MemberTabName.Values["All members"],
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              {isAdminInOrg ? (
                <Admin_OrganisationMembers />
              ) : (
                <Member_OrganisationMembers />
              )}
            </Suspense>
          ),
        },
      ];
      if (isMemberInOrg) {
        items.push({
          id: "groups",
          name: _MemberTabName.Values.Groups,
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              <Groups />
            </Suspense>
          ),
        });
      }
      if (isMemberInOrg) {
        items.push({
          id: "pending",
          disabled: !pendingInvitations,
          name: _MemberTabName.Values["Pending Invitations"],
          tabNameContent: pendingInvitations
            ? _MemberTabName.Values["Pending Invitations"].concat(
                ` (${pendingInvitations.length})`,
              )
            : _MemberTabName.Values["Pending Invitations"],
          data: (
            <Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    margin: spaceHuge,
                    width: "inherit",
                    height: "5rem",
                  }}
                />
              }
            >
              {isAdminInOrg ? (
                <Admin_OrganisationMembers onlyPending={true} />
              ) : (
                <Member_OrganisationMembers onlyPending={true} />
              )}
            </Suspense>
          ),
        });
      }

      return items;
    }, [organisationId, isAdminInOrg, isMemberInOrg, pendingInvitations]);

    return (
      <Tabs
        menuStyle={{
          justifyContent: "start",
          gap: "5rem",
          display: "flex",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 2,
        }}
        tabs={memberTabs}
        wrapperStyle={{
          height: "100%",
        }}
        contentWrapperStyle={{
          height: "100%",
          paddingTop: 0,
          paddingBottom: 0,
          overflowY: "auto",
        }}
        initialTab={
          selectedMemberTab
            ? memberTabs.findIndex((t) => t.name === selectedMemberTab)
            : undefined
        }
        onAfterTabChange={(index) => {
          setSelectedMemberTab(
            memberTabs[index]?.name as MemberTabName | undefined,
          );
        }}
      />
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default MembersTabs;
