import { TurbineFeature } from "../../../../../types/feature";
import { fastMax, fastMin } from "../../../../../utils/utils";
import { Raster } from "../../../../../types/raster";

export const useGetTurbineDepths = ({
  turbineFeatures,
  raster,
}: {
  turbineFeatures: TurbineFeature[];
  raster: Raster;
}): { maxDepth: number; minDepth: number } => {
  const depths = turbineFeatures
    .map(
      ({
        geometry: {
          coordinates: [lon, lat],
        },
      }) => -raster.latLngToValue(lon, lat),
    )
    .filter((depth) => depth > 0);

  const maxTurbineDepth = depths.length ? fastMax(depths) : 0;
  const minTurbineDepth = depths.length ? fastMin(depths) : 0;

  return {
    maxDepth: maxTurbineDepth,
    minDepth: minTurbineDepth,
  };
};
