import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";
import {
  libraryCableTypesFamily,
  projectCableTypesFamily,
} from "state/jotai/cableType";
import {
  ColoredGridPreviewItem,
  ColoredGridProjectItems,
  Divider,
  GridKey,
  GridValue,
  GridValueLeft,
  HeaderRow,
  Modal,
  TableHeader,
  TableSubHeader,
  Title,
  Wrapper,
} from "./style";
import {
  CablePreviewItem,
  ComponentsPreviewType,
  LibraryCablePreviewItem,
} from "./state";
import { listProcurementCostForLibraryInterArrayCablesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { ComponentCost, CostType } from "services/costService";
import { CostUnit } from "types/financial";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { InputDimensioned } from "components/General/Input";
import { WithTooltip } from "components/General/Tooltip";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "components/ConfigurationModal/Cost/state";
import {
  interArrayCableIdentifierAtom,
  interArrayCableProcurementCostsAtom,
} from "components/Organisation/Library/financial/ProcurementCosts/state";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { GoToProcurementTableArrowIcon } from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";
import { midScreenModalTypeOpenAtom } from "state/modal";

const LibraryCablesTable = ({
  libraryCablesItems,
}: {
  libraryCablesItems: LibraryCablePreviewItem[];
}) => {
  return (
    <>
      <SubtitleWithLine textIcon={<LibraryIcon />} text={"Library cables"} />
      <ColoredGridPreviewItem>
        <>
          <TableHeader>Inter array cables</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader>Supply</TableHeader>
          <TableHeader>Shipping</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
        </>
        <>
          <TableSubHeader>NAME</TableSubHeader>
          <TableSubHeader>VOLTAGE</TableSubHeader>
          <TableSubHeader>COST</TableSubHeader>
          <TableSubHeader>EUROPE</TableSubHeader>
          <TableSubHeader>ASIA</TableSubHeader>
          <TableSubHeader>USA</TableSubHeader>
          <TableSubHeader>OTHER</TableSubHeader>
        </>

        {libraryCablesItems.map((cable: LibraryCablePreviewItem) => {
          const getCostInRegion = (region: string) =>
            cable.shippingCost.find((t) => t.region === region)?.cost || 0;
          const getUnitInRegion = (region: string) =>
            cable.shippingCost.find((t) => t.region === region)?.unit ||
            CostUnit.millionEuroPerUnit;

          return (
            <React.Fragment key={cable.id}>
              <WithTooltip text={cable.name}>
                <GridKey>{cable.name}</GridKey>
              </WithTooltip>
              <GridValueLeft>{cable.voltage}kV</GridValueLeft>
              <GridValueLeft>
                <strong>{cable.supplyCost}</strong> {cable.supplyCostUnit}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Europe")}</strong>{" "}
                {getUnitInRegion("Europe")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Asia")}</strong>{" "}
                {getUnitInRegion("Asia")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("USA")}</strong>{" "}
                {getUnitInRegion("USA")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Other")}</strong>{" "}
                {getUnitInRegion("Other")}
                <GoToProcurementTableArrowIcon activeProcurementType="interArrayCable" />
              </GridValueLeft>
            </React.Fragment>
          );
        })}
      </ColoredGridPreviewItem>
    </>
  );
};

const Library_CableComponentsPreviewV2Inner = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const libraryInterArrayCableCosts = useAtomValue(
    interArrayCableProcurementCostsAtom(organisationId),
  );

  const libraryCables = useAtomValue(
    interArrayCableIdentifierAtom(organisationId),
  );

  const libraryCablesItems = useMemo<LibraryCablePreviewItem[]>(
    () =>
      Array.from(libraryCables?.values() ?? []).map((c) => {
        const procurementCost = libraryInterArrayCableCosts.get(c.id);
        return {
          id: c.id,
          name: c.name,
          supplyCost: procurementCost?.supplyCost?.cost
            ? procurementCost.supplyCost.cost
            : DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM],
          supplyCostUnit:
            procurementCost?.supplyCost?.unit ?? CostUnit.millionEuroPerKM,
          shippingCost: procurementCost?.shippingCost ?? [],
          level: "library",
          voltage: c.voltage,
          costSource: procurementCost?.supplyCost ? "table" : "default",
        };
      }),
    [libraryCables, libraryInterArrayCableCosts],
  );

  return <LibraryCablesTable libraryCablesItems={libraryCablesItems} />;
};

const Project_CableComponentsPreviewV2Inner = ({
  temporaryProjectCableCosts,
  setTemporaryProjectCableCosts,
}: {
  temporaryProjectCableCosts: Record<string, ComponentCost>;
  setTemporaryProjectCableCosts: React.Dispatch<
    React.SetStateAction<Record<string, ComponentCost>>
  >;
}) => {
  const projectId = useAtomValue(projectIdAtom)!;
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);

  const readOnly =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  const libraryCables = useAtomValue(libraryCableTypesFamily(projectId ?? ""));

  const libraryInterArrayCableCosts = useAtomValue(
    listProcurementCostForLibraryInterArrayCablesOnNodeAtomFamily(projectId),
  );

  const projectCables = useAtomValue(projectCableTypesFamily(projectId ?? ""));

  const projectCablesItems = useMemo<CablePreviewItem[]>(() => {
    return Array.from(projectCables.values()).map((c) => {
      const thisCableCost = temporaryProjectCableCosts[c.id];
      return {
        id: c.id,
        name: c.name,
        cost: thisCableCost?.cost ?? c.cost,
        costUnit: thisCableCost?.unit ?? c.costUnit,
        level: "project",
        voltage: c.voltage,
      };
    });
  }, [projectCables, temporaryProjectCableCosts]);

  const libraryCablesItems = useMemo<LibraryCablePreviewItem[]>(
    () =>
      Array.from(libraryCables?.values() ?? []).map((c) => {
        const procurementCost = libraryInterArrayCableCosts.get(c.id);
        return {
          id: c.id,
          name: c.name,
          supplyCost: procurementCost?.supplyCost?.cost
            ? procurementCost.supplyCost.cost
            : DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM],
          supplyCostUnit:
            procurementCost?.supplyCost?.unit ?? CostUnit.millionEuroPerKM,
          shippingCost: procurementCost?.shippingCost ?? [],
          level: "library",
          voltage: c.voltage,
          costSource: procurementCost?.supplyCost ? "table" : "default",
        };
      }),
    [libraryCables, libraryInterArrayCableCosts],
  );

  return (
    <>
      <LibraryCablesTable libraryCablesItems={libraryCablesItems} />
      <SubtitleWithLine textIcon={<ProjectIcon />} text={"Project cables"} />
      <ColoredGridProjectItems nrColumns={3}>
        <React.Fragment key="header">
          <TableSubHeader>NAME</TableSubHeader>
          <TableSubHeader>VOLTAGE</TableSubHeader>
          <TableSubHeader>COST</TableSubHeader>
        </React.Fragment>
        {projectCablesItems.map((cable, index) => (
          <React.Fragment key={cable.id}>
            <GridKey showBg={index % 2 === 0}>{cable.name}</GridKey>
            <GridKey showBg={index % 2 === 0}>{cable.voltage}kV</GridKey>
            <GridValue showBg={index % 2 === 0}>
              <InputDimensioned
                disabled={readOnly}
                compact={true}
                unit={cable.costUnit}
                type="number"
                value={cable.cost}
                initialValue={cable.cost}
                onChange={(cost, _, unit) =>
                  setTemporaryProjectCableCosts((curr) => ({
                    ...curr,
                    [cable.id]: {
                      id: cable.id,
                      cost,
                      unit: unit as CostUnit,
                    },
                  }))
                }
              />
              <FeatureSettingsShortcut
                menuId={CABLE_MENU_ID}
                itemId={cable.id}
              />
            </GridValue>
          </React.Fragment>
        ))}
      </ColoredGridProjectItems>
    </>
  );
};

const CableComponentsPreviewV2Inner = ({ onClose }: { onClose(): void }) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const { replaceProjectComponentCosts } = useLocalCostConfigurationCrud();
  const localConfig = useAtomValue(localCostConfigurationAtom);
  const [temporaryProjectCableCosts, setTemporaryProjectCableCosts] = useState<
    typeof localConfig.capex.projectComponentCosts.cables
  >(localConfig.capex.projectComponentCosts.cables);

  const isInLibraryContext =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  return (
    <>
      <ScrollBody>
        {isInLibraryContext ? (
          <Library_CableComponentsPreviewV2Inner />
        ) : (
          <Project_CableComponentsPreviewV2Inner
            temporaryProjectCableCosts={temporaryProjectCableCosts}
            setTemporaryProjectCableCosts={(...args) => {
              setHasChanged(true);
              setTemporaryProjectCableCosts(...args);
            }}
          />
        )}
      </ScrollBody>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <Button buttonType="secondary" text="Close" onClick={onClose} />
        {!isInLibraryContext && (
          <Button
            buttonType="primary"
            text="Apply"
            disabled={!hasChanged}
            onClick={() => {
              replaceProjectComponentCosts(
                temporaryProjectCableCosts,
                "cables",
              );
              onClose();
            }}
          />
        )}
      </div>
    </>
  );
};

const CableComponentsPreviewV2 = () => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <Wrapper>
      <Modal
        ref={containerRef}
        style={{ width: "100rem", maxWidth: "80%", overflow: "hidden" }}
      >
        <HeaderRow>
          <div>
            <Title>Inter array cable costs</Title>
          </div>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <React.Suspense
          fallback={<SkeletonBlock style={{ height: "20rem" }} />}
        >
          <CableComponentsPreviewV2Inner onClose={onClose} />
        </React.Suspense>
      </Modal>
    </Wrapper>
  );
};

export default CableComponentsPreviewV2;
