import { _FeatureType, ProjectFeature } from "../types/feature";
import {
  isCable,
  isDefined,
  isExportCable,
  isMooringLine,
} from "../utils/predicates";
import { partition } from "../utils/utils";
import { DefaultMap } from "lib/DefaultMap";

/**
 * Check that the `child` feature "depends" on the `parentId`.
 *
 * For instance, a cable depends on the turbines and substations it is
 * connected to, because we cannot have a cable without endpoints.
 */
export function isDependentOfFeature(
  parentId: string,
  child: ProjectFeature,
  checkIfIsParent: boolean,
): boolean {
  if (isCable(child)) {
    return (
      child.properties.fromId === parentId ||
      child.properties.toId === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  } else if (isMooringLine(child)) {
    return (
      child.properties.anchor === parentId ||
      child.properties.target === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  } else if (isExportCable(child)) {
    return (
      child.properties.fromSubstationId === parentId ||
      child.properties.toSubstationId === parentId ||
      (checkIfIsParent &&
        (child.properties.parentIds?.includes(parentId) ?? false))
    );
  }

  return (
    checkIfIsParent &&
    (child.properties?.parentIds?.includes(parentId) ?? false)
  );
}

const findParentLessAnchors = (
  children: string[],
  projectData: ProjectFeature[],
) => {
  const potentialParentLessAnchorIds = projectData
    .filter((pd) => children.includes(pd.id))
    .filter(isMooringLine)
    .map((m) => m.properties.anchor);
  const allOtherMorringLines = projectData
    .filter((pd) => !children.includes(pd.id))
    .filter(isMooringLine);
  return potentialParentLessAnchorIds.filter((anchorId) =>
    allOtherMorringLines.every((line) => line.properties.anchor !== anchorId),
  );
};

/**
 * A {@link Map} where the key is a feature id and the value is an array of
 * feature ids that are dependent on the key feature. Dependent meaning for
 * instance a turbine has cables connected to it, so the cables are dependent
 * on the turbine.
 *
 *  ## Example
 *  ````
 *  { [turbineId]: [cableId1, cableId2] }
 *  ```
 */
export const createDependencyMap = (
  projectData: ProjectFeature[],
): Map<string, string[]> => {
  const map = new DefaultMap<string, string[]>(() => []);
  for (const curr of projectData) {
    if (isCable(curr)) {
      const fromId = curr.properties.fromId;
      const toId = curr.properties.toId;
      if (fromId) {
        //From-turbine has the cable as dependency
        map.get(fromId).push(curr.id);
      }
      if (toId) {
        // To-turbine has the cable as dependency
        map.get(toId).push(curr.id);
      }
    } else if (isMooringLine(curr)) {
      const anchor = curr.properties.anchor;
      const target = curr.properties.target;
      if (anchor) {
        // Anchor has a the mooring line as dependency
        map.get(anchor).push(curr.id);
        // The mooring line has the anchor as dependency
        // (This is necessary because we need to find anchors that is connected to a mooring line that is being deleted)
        map.get(curr.id).push(anchor);
      }
      if (target) {
        // Turbine has the mooring line as dependency
        map.get(target).push(curr.id);
      }
    } else if (isExportCable(curr)) {
      const fromSubstationId = curr.properties.fromSubstationId;
      const toSubstationId = curr.properties.toSubstationId;
      if (fromSubstationId) {
        // From-substation has the export cable as dependency
        map.get(fromSubstationId).push(curr.id);
      }
      if (toSubstationId) {
        // To-substation has the export cable as dependency
        map.get(toSubstationId).push(curr.id);
      }
    }
    if (
      curr.properties?.parentIds &&
      Array.isArray(curr.properties.parentIds)
    ) {
      curr.properties.parentIds.forEach((parentId) => {
        // The parent (park) has the child as dependency
        map.get(parentId).push(curr.id);
      });
    }
  }
  return map.inner;
};

export const findFeatureChildrenIdsUsingDependencyMap = (
  dependencyMap: ReturnType<typeof createDependencyMap>,
  featureIds: string[],
): string[] => {
  // Do this in a loop: find features that are dependent on the feature with the given featureId,
  // then find dependencies of the found dependencies, and so on.
  let children = featureIds;
  let i = 0;
  while (i < children.length) {
    const id = children[i++];
    if (dependencyMap.has(id)) {
      children = [...new Set(children.concat(dependencyMap.get(id)!))];
    }
  }

  return children;
};

export const findFeatureChildrenIds = (
  projectData: ProjectFeature[],
  featureId: string,
): string[] => {
  // Do this in a loop: for each iteration we get all features with a given parentId,
  // and remove these features from the list of all features.  Next iteration we only
  // look through the features that weren't removed in any previous iteration.  This
  // way we ensure that we don't loop.  The only edge case is if we don't find any
  // features with a given parentId, but this is not a problem because we will
  // eventually run out of feature ids to look for.
  let features = projectData;
  let children = [featureId];
  let i = 0;
  while (i < children.length) {
    const id = children[i];
    i++;
    const [newChildren, rest] = partition(features, (f) =>
      isDependentOfFeature(id, f, true),
    );
    features = rest;
    children = children.concat(newChildren.map((f) => f.id)).filter(isDefined);
  }

  const parentLessAnchorIds = findParentLessAnchors(children, projectData);

  // Don't include the provided featureId
  return Array.from(new Set([...children.slice(1), ...parentLessAnchorIds]));
};

export const findParkChildren = (
  projectData: ProjectFeature[],
  parkId: string,
): ProjectFeature[] => {
  return projectData.filter(
    (f) =>
      Array.isArray(f.properties.parentIds) &&
      f.properties.parentIds.includes(parkId),
  );
};

/**
 * Find all features that are children of the feature with id `featureId`. This
 * works recursively, so children of children is also included.
 *
 * A "child" means that the child feature is somehow dependent on the parent
 * feature. For instance, a `MooringLineFeature` depends on the features it is
 * connected to (the anchor and the turbine), and a `CableFeature` depends on
 * the endpoints it connects.
 */
export const findFeatureChildren = (
  projectData: ProjectFeature[],
  featureId: string,
): ProjectFeature[] => {
  const featureIds = findFeatureChildrenIds(projectData, featureId);
  return projectData.filter((p) => featureIds.includes(p.id));
};

export function hasNecessaryConnections(
  feature: ProjectFeature,
  projectData: ProjectFeature[],
): boolean {
  const findFeatureById = (id: string) => projectData.find((f) => f.id === id);

  if (isCable(feature)) {
    const fromExists = !!findFeatureById(feature.properties.fromId);
    const toExists = !!findFeatureById(feature.properties.toId);
    return fromExists && toExists;
  }

  if (isMooringLine(feature)) {
    const anchorExists = !!findFeatureById(feature.properties.anchor);
    const targetExists = !!findFeatureById(feature.properties.target);
    return anchorExists && targetExists;
  }

  if (isExportCable(feature)) {
    const fromSubstationExists = !!findFeatureById(
      feature.properties.fromSubstationId ?? "",
    );
    const toSubstationExists = !!findFeatureById(
      feature.properties.toSubstationId ?? "",
    );
    return fromSubstationExists && toSubstationExists;
  }

  // For other feature types, we'll consider them always valid
  // as they don't have specific connection requirements
  return true;
}
