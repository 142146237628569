import { fromBlob } from "geotiff";
import { compressGeotiffImage } from "./gdal";

export async function* lossyCompressGeotiffGenerator(
  file: File,
  maxSizeInBytes: number,
) {
  if (file.size < maxSizeInBytes) {
    return file;
  }

  yield "Bathymetry file is larger than 60 MB, will compress...";
  const geotiffMaybe = await fromBlob(file);
  const image = await geotiffMaybe.getImage();

  const sizeDiff = maxSizeInBytes / file.size;
  const shrinkPercentage = Math.sqrt(sizeDiff);

  const tr = image
    .getResolution()
    .map((r) => Math.abs(r) / shrinkPercentage)
    .slice(0, 2) as [number, number];

  const compressedGeotiff = await compressGeotiffImage(file, tr);

  return compressedGeotiff;
}
