import { projectIdAtom } from "state/pathParams";
import { Suspense, useCallback, useMemo } from "react";
import { SubstationFeature } from "../../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../../General/Tooltip";
import { isDefined, isSubstation } from "../../../utils/predicates";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "../MenuTracking";
import { Divider } from "components/General/Icons";
import { ProjectFeature } from "../../../types/feature";
import { MenuItem } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "../CanvasSelectOption.style";
import { dedup } from "utils/utils";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useAtomValue } from "jotai";
import { substationTypesWithLevelAtom } from "state/jotai/substationType";
import { isOnshoreAtom } from "state/onshore";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { useOpenComponentsConfig } from "../useOpenConfig";
import { colors } from "styles/colors";
import ComponentsIcon from "@icons/24/Components.svg?react";
import {
  TypeSelectorWrapper,
  SubTitle,
  DropdownDivider,
} from "./SubstationTypeSelector.style";
import { LevelIcon, TypeMenuItem } from "./TypeMenuItem";

const Inner = ({
  substations,
  editable,
}: {
  substations: SubstationFeature[];
  editable: boolean;
}) => {
  const onshore = useAtomValue(isOnshoreAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { update: updateFeatures } = useProjectElementsCrud();
  const openNewSubstationConfig = useOpenComponentsConfig(
    projectId,
    SUBSTATION_MENU_ID,
  );

  const allSubstationTypes = useAtomValue(substationTypesWithLevelAtom);

  const currentSubstationTypeIds = useMemo(
    () =>
      dedup(
        substations.map((s) => s.properties.substationTypeId).filter(isDefined),
      ),
    [substations],
  );

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-substation-type", {
        projectId,
      });
      const updatedFeatures = substations.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          substationTypeId: val,
        },
      }));

      updateFeatures({
        update: updatedFeatures,
      });
    },
    [projectId, substations, updateFeatures],
  );

  const items = useMemo(
    () =>
      Array.from(allSubstationTypes.values()).map((t) => ({
        icon: <LevelIcon level={t.level} />,
        level: t.level,
        value: t.substation.id,
        name: t.substation.name,
        type: t.substation.type,
      })),
    [allSubstationTypes],
  );

  const groupedItems = useMemo(() => {
    const filteredItems = onshore
      ? items.filter((item) => item.type === "onshore")
      : items;

    const projectItems = filteredItems.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = filteredItems.filter((ft) => ft.level === "library");
    const defaultItems = filteredItems.filter((ft) => ft.level === "default");

    return {
      project: projectItems,
      library: libraryItems,
      default: defaultItems,
    };
  }, [items, onshore]);

  const currentChoice = useMemo(() => {
    return currentSubstationTypeIds.length === 1
      ? allSubstationTypes.get(currentSubstationTypeIds[0])?.substation.name ??
          "..."
      : "...";
  }, [currentSubstationTypeIds, allSubstationTypes]);

  const libraryItems = groupedItems.library;
  const projectItems = groupedItems.project;
  const defaultItems = groupedItems.default;
  const hasLibraryItems = libraryItems.length > 0;
  const hasProjectItems = projectItems.length > 0;
  const hasDefaultItems = defaultItems.length > 0;

  const getSelectedItem = (item: { value: string }) => {
    return (
      currentSubstationTypeIds.length === 1 &&
      currentSubstationTypeIds.includes(item.value)
    );
  };

  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          minWidth: "11rem",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          maxWidth: "20rem",
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentChoice}
          </div>
        }
      >
        <>
          {hasLibraryItems && (
            <>
              <SubTitle>Library components</SubTitle>
              {libraryItems.map((item) => (
                <TypeMenuItem
                  key={item.value}
                  item={item}
                  isSelected={getSelectedItem(item)}
                  onSelect={onSelectItem}
                  showType={!onshore}
                />
              ))}
            </>
          )}
          {hasProjectItems && (
            <>
              <SubTitle>Project specific components</SubTitle>
              {projectItems.map((item) => (
                <TypeMenuItem
                  key={item.value}
                  item={item}
                  isSelected={getSelectedItem(item)}
                  onSelect={onSelectItem}
                  showType={!onshore}
                />
              ))}
            </>
          )}
          {hasDefaultItems && (
            <>
              <SubTitle>Vind AI components</SubTitle>
              {defaultItems.map((item) => (
                <TypeMenuItem
                  key={item.value}
                  item={item}
                  isSelected={getSelectedItem(item)}
                  onSelect={onSelectItem}
                  showType={!onshore}
                />
              ))}
            </>
          )}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          />
          <DropdownDivider />
          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewSubstationConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export function SubstationTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedSubstationFeatures = useMemo(
    () => selectedProjectFeatures.filter(isSubstation),
    [selectedProjectFeatures],
  );

  const onlySubstationsSelected =
    selectedSubstationFeatures.length === selectedProjectFeatures.length;

  if (selectedSubstationFeatures.length === 0 || !onlySubstationsSelected)
    return null;

  return (
    <>
      <Tooltip
        position="top"
        text="Change type"
        readonlyAware
        key={
          selectedSubstationFeatures.at(0)?.properties?.substationTypeId ?? ""
        }
      >
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Substation type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock
                style={{
                  height: "1rem",
                  width: "10rem",
                }}
              />
            }
          >
            <Inner
              substations={selectedSubstationFeatures}
              editable={editable}
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </>
  );
}
