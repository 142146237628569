import React, {
  CSSProperties,
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import Checkmark from "@icons/checkmark/checkmark.svg?react";
import Line from "@icons/checkmark/line.svg?react";
import { colors } from "../../styles/colors";
import { spacing6 } from "../../styles/space";
import { Comp } from "../../types/utils";
import { typography } from "styles/typography";

const CheckBoxLabel = styled.label<{
  disabled?: boolean;
}>`
  display: flex;
  gap: ${spacing6};
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;

  span {
    ${typography.caption}
    margin: 0;

    ${(p) => p.disabled && `color: ${colors.textDisabled};`}
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0;
`;

type StyledCheckboxProps = {
  checked: CheckboxProps["checked"];
  disabled: boolean;
};

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 1.6rem;
  height: 1.6rem;

  background: white;
  ${HiddenCheckbox}:checked + &,
  ${HiddenCheckbox}:indeterminate + & {
    background: ${colors.surfaceButtonPrimary};
  }

  border: 1px solid ${colors.surfaceButtonPrimary};
  border-radius: 2px;

  transition:
    all 200ms ease,
    visibility 0ms;

  path {
    stroke: white;
  }

  ${HiddenCheckbox}:hover + & {
    border: 1px solid ${colors.blue600};
  }
  ${HiddenCheckbox}:checked:hover + &,
  ${HiddenCheckbox}:indeterminate:hover + & {
    background: ${colors.blue600};
  }

  ${HiddenCheckbox}:active + & {
    border: 1px solid ${colors.blue700};
  }
  ${HiddenCheckbox}:checked:active + &,
  ${HiddenCheckbox}:indeterminate:active + & {
    background: ${colors.blue700};
  }

  ${HiddenCheckbox}:disabled + & {
    cursor: not-allowed;
    border: 1px solid ${colors.textDisabled};
    path {
      stroke: ${colors.surfaceDisabled};
    }
  }

  ${HiddenCheckbox}:checked:disabled + & {
    background: ${colors.textDisabled};
  }
`;

type CheckboxProps = Comp<
  "input",
  {
    label?: ReactNode;
    labelStyle?: CSSProperties;
    labelPlacement?: "above" | "after";
    checked?: boolean | "indeterminate";
    labelProps?: ComponentPropsWithoutRef<"label">;
  }
>;

export default function Checkbox({
  label,
  checked,
  disabled,
  labelProps,
  labelStyle,
  style,
  title,
  ...p
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) ref.current.indeterminate = checked === "indeterminate";
  }, [checked]);
  return (
    <CheckBoxLabel
      {...labelProps}
      title={title}
      style={style}
      disabled={disabled}
      data-type="checkbox"
    >
      <CheckboxContainer>
        <HiddenCheckbox
          ref={ref}
          type="checkbox"
          checked={checked === true}
          disabled={disabled}
          {...p}
        />
        <StyledCheckbox checked={checked ?? false} disabled={disabled ?? false}>
          {checked === true ? (
            <Checkmark />
          ) : (
            checked === "indeterminate" && <Line />
          )}
        </StyledCheckbox>
      </CheckboxContainer>
      {label && <span style={labelStyle}>{label}</span>}
    </CheckBoxLabel>
  );
}
