import { useAtomValue, useSetAtom } from "jotai";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import StackedUserImages from "components/UserImage/StackedUserImages";
import { memberInOrganisationSelectorFamily } from "state/user";
import { allNodeUsersSelectorFamily } from "./state";
import { useVisibleObserver } from "hooks/useVisibleObserver";

function NodeMembersListInner({
  organisationId,
  nodeId,
}: {
  organisationId: string;
  nodeId: string;
}) {
  const projectMembers = useAtomValue(
    allNodeUsersSelectorFamily({
      organisationId,
      nodeId,
    }),
  );
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  return (
    <StackedUserImages
      onClick={
        isMemberInOrg
          ? () =>
              setContent({
                type: "project",
                id: nodeId,
              })
          : undefined
      }
      users={projectMembers}
      size={2.5}
    />
  );
}
export function NodeMembersList({
  organisationId,
  nodeId,
}: {
  organisationId: string;
  nodeId: string;
}) {
  const { isVisible, elementRef } = useVisibleObserver(true);

  return (
    <div
      ref={elementRef}
      style={{
        minHeight: "2.5rem",
      }}
    >
      {isVisible && (
        <NodeMembersListInner organisationId={organisationId} nodeId={nodeId} />
      )}
    </div>
  );
}
