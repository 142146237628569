import SphericalMercator from "@mapbox/sphericalmercator";
import { Polygon } from "geojson";
import { atom } from "jotai";
import { getTerrainSlopeAnalysis } from "services/terrainAPIService";
import { SlopeResponseWithRaster } from "types/bathymetryTypes";
import { ProjectFeature } from "types/feature";
import { Raster } from "types/raster";
import { TileSamplingArgs } from "types/tile";
import { decodeMapboxTerrain } from "utils/bathymetry";
import { geotiffResponseToFloat32Array } from "utils/image";
import { atomFamily } from "utils/jotai";
import {
  addTileNumberToPoint,
  getXTileNumber,
  getYTileNumber,
} from "utils/tiles";
import { promiseWorker, typedWorker } from "utils/utils";
import * as turf from "@turf/turf";
import { mapboxTerrainDemTileUrlFamily } from "state/map";

const tileSize = 512;
const zoom = 11;
const merc = new SphericalMercator({
  size: tileSize,
  antimeridian: true,
});

const drawCanvas = async (x: number, y: number, z: number, url: string) => {
  const tile = await fetch(url);
  const blob = await tile.blob();

  const canvas = document.createElement("canvas");
  canvas.id = `${x}-${y}-${z}`;
  canvas.height = tileSize;
  canvas.width = tileSize;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = function () {
      res(img);
    };
  });
  if (!ctx) return canvas;
  ctx.drawImage(img, 0, 0);

  return canvas;
};

const getMapboxTerrainTileFamily = atomFamily(
  ({ x, y, z }: { x: number; y: number; z: number }) =>
    atom<Promise<{ imageData: ImageData; x: number; y: number }>>(
      async (get) => {
        const url = get(mapboxTerrainDemTileUrlFamily({ x, y, z }));
        const canvas = await drawCanvas(x, y, z, url);
        const ctx = canvas.getContext("2d")!; // TODO: Safety here
        const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
        return { imageData, x, y };
      },
    ),
);

export const getMapboxTerrainSampleFamily = atomFamily(
  ({ coords }: { coords: number[][] }) =>
    atom<Promise<number[]>>(async (get) => {
      const tileDatas = await Promise.all(
        coords.map(async (coord) =>
          get(
            getMapboxTerrainTileFamily({
              x: getXTileNumber(coord[0], zoom),
              y: getYTileNumber(coord[1], zoom),
              z: zoom,
            }),
          ),
        ),
      );

      const pointWithTileNumbers = coords.map((coord) =>
        addTileNumberToPoint(coord, zoom),
      );
      const worker = typedWorker<
        TileSamplingArgs,
        undefined | [number, number, number, number][]
      >(
        new Worker(
          new URL("../../hooks/depthProfileWebWorker.ts", import.meta.url),
          {
            type: "module",
          },
        ),
      );

      const samples = await promiseWorker(
        worker,
        [tileDatas, pointWithTileNumbers, zoom, tileSize, merc],
        "terrain/depthProfileWebWorker",
      );
      worker.terminate();

      if (typeof samples === "undefined") {
        return [];
      }

      return samples.map((sample) =>
        decodeMapboxTerrain({ r: sample[0], g: sample[1], b: sample[2] }),
      );
    }),
);

export const getTerrainFromMapboxFamily = atomFamily(
  ({ polygon, zoom }: { polygon: ProjectFeature<Polygon>; zoom: number }) =>
    atom<Promise<SlopeResponseWithRaster>>(async () => {
      const bbox = turf.bbox(polygon).slice(0, 4) as [
        number,
        number,
        number,
        number,
      ];
      const [viewshedBlob, terrainBlob] = await getTerrainSlopeAnalysis(
        zoom,
        bbox,
      );
      const [minLon, minLat, maxLon, maxLat] = bbox;

      const {
        width,
        height,
        array: values,
      } = await geotiffResponseToFloat32Array(viewshedBlob);
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const stepLat = sizeLatitude / height;
      const stepLon = sizeLongitude / width;

      return {
        id: `slope-analysis-${polygon.properties.name ?? polygon.id}`,
        status: "finished",
        url: window.URL.createObjectURL(viewshedBlob),
        terrainUrl: window.URL.createObjectURL(terrainBlob),
        usedCustomBathymetry: [],
        raster: new Raster(
          Array.from(values),
          width,
          height,
          minLon,
          maxLat,
          stepLon,
          stepLat,
        ),
      };
    }),
);
