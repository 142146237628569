import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { between } from "utils/validations";
import { kWtoMW } from "../../FoundationSettings";
import { ReferenceNumbersProps } from "./ReferenceNumberProps";

const ReferenceRatedPower = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceNumbersProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Reference rated power</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          validate={between(1, 30)}
          validationMessage={`Needs to be within 1 - 30 MW`}
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "ratedPower",
            commentCategory,
          )}
          step="1"
          unit="MW"
          type="number"
          scaleFactor={kWtoMW}
          value={tempFoundation.ratedPower}
          initialValue={
            originalFoundation ? originalFoundation.ratedPower : undefined
          }
          onChange={(ratedPowerMW) => {
            setTempFoundation({
              ...tempFoundation,
              ratedPower: ratedPowerMW,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default ReferenceRatedPower;
