import { useAtom, useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import Button from "../General/Button";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import { CostConfiguration } from "../../services/costService";
import { savingCostConfigurationInProgressAtom } from "../../state/costConfigurations";
import { inReadOnlyModeSelector } from "../../state/project";
import { toastMessagesAtom } from "../../state/toast";
import Tabs from "../General/Tabs";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import {
  ContentWrapper,
  ModalContainer,
  SettingButton,
} from "../SettingsV2/Shared/styles";
import { Capex } from "./Cost/Capex";
import { localCostConfigurationAtom } from "./Cost/state";
import { Lcoe } from "./Cost/LCOE/Lcoe";
import { CashFlows } from "./Cost/CashFlows";
import useEnterToSave from "./useEnterToSave";
import { OtherCosts } from "./Cost/OtherCosts/OtherCosts";
import { orgFinanicalManageAccessSelector } from "state/user";
import { EditableText } from "components/General/EditableText";
import {
  ContentContainer,
  HeaderContainer,
  HeaderWrapper,
  Row,
} from "./shared";
import { useSetAtom } from "jotai";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { Column } from "components/General/Layout";
import { FinancialProjects } from "components/Organisation/OrganisationRightSide/content/ResourceContent/availability/FinancialProjects";
import ComponentLastChanged from "./SettingsUsage/ComponentLastChanged";
import DescriptionModal from "./DescriptionModal";
import { idToFinancialChangelogId } from "components/InputChangelog/const";
import {
  HelpLink,
  ARTICLE_FINANCIAL_CONFIGURATIONS,
} from "components/HelpTooltip/HelpTooltip";
import ProjectResourceUsage from "./SettingsUsage/ProjectResourceUsage";
import usePrevious from "hooks/usePrevious";
import { mergePreservingChanges, objectEquals } from "utils/utils";
import LibraryResourceActions from "./Components/LibraryResourceActions";
import { useDuplicateCostToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";

export const CostSettingsForLibraryConfig = ({
  configuration,
}: {
  configuration: CostConfiguration;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  return (
    <CostSettingsInner
      key={configuration.id}
      configuration={configuration}
      readOnly={true}
      isLibraryResource={true}
      nodeId={organisationId}
    />
  );
};

const CostSettingsWithConfig = ({
  configuration,
  save,
  readOnly,
  isLibraryResource,
  nodeId,
}: {
  configuration: CostConfiguration;
  nodeId: string;
  save?: (config: CostConfiguration) => Promise<any>;
  readOnly?: boolean;
  isLibraryResource: boolean;
}) => {
  const isSaving = useAtomValue(savingCostConfigurationInProgressAtom);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector) || readOnly;
  const [localConfig, setLocalConfig] = useAtom(localCostConfigurationAtom);

  const { duplicateToProject, isLoading: isLoadingDuplicate } =
    useDuplicateCostToProject();

  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);
  const setToastMessages = useSetAtom(toastMessagesAtom);

  const isLoading = localConfig.id !== configuration.id;
  const hasChanged = useMemo(() => {
    if (!configuration || !localConfig) return false;
    return !objectEquals(configuration, localConfig);
  }, [configuration, localConfig]);

  const allChangesSaved = isReadOnly || !hasChanged;

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  const onEnterSaveCallback = useCallback(() => {
    if (!save) return;
    save(localConfig);
  }, [localConfig, save]);
  useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

  const renameConfig = useCallback(() => {
    if (!save) return;

    save({
      ...configuration,
      name: localConfig.name,
    });
  }, [save, configuration, localConfig.name]);

  const tabs = useMemo(() => {
    return [
      {
        name: "CAPEX",
        data: (
          <Capex
            isReadOnly={isReadOnly}
            isLibraryResource={isLibraryResource}
            nodeId={nodeId}
            configuration={configuration}
          />
        ),
      },
      {
        name: "Other expenditures",
        data: (
          <OtherCosts
            isReadOnly={isReadOnly}
            isLibraryResource={isLibraryResource}
            nodeId={nodeId}
            configuration={configuration}
          />
        ),
      },
      {
        name: "Cost model",
        data: (
          <Lcoe
            isReadOnly={isReadOnly}
            isLibraryResource={isLibraryResource}
            nodeId={nodeId}
            configuration={configuration}
          />
        ),
      },
      {
        name: "Cash flows",
        data: (
          <CashFlows
            isReadOnly={isReadOnly}
            isLibraryResource={isLibraryResource}
            nodeId={nodeId}
            configuration={configuration}
          />
        ),
      },
      ...(inLibraryContext
        ? [
            {
              name: "Usage and availability",
              data: (
                <Suspense fallback={<TablePlaceholder />}>
                  <FinancialProjects resource={configuration} />
                </Suspense>
              ),
            },
          ]
        : []),
    ];
  }, [isLibraryResource, inLibraryContext, isReadOnly, nodeId, configuration]);

  const handleUpdateCostConfigurationDescription = useCallback(
    (description: string) => {
      setLocalConfig({ ...localConfig, description });
      if (configuration && save) {
        save({
          ...configuration,
          description: description,
        });
      }
    },
    [configuration, localConfig, save, setLocalConfig],
  );

  if (objectEquals(localConfig, {})) return null;

  return (
    <ModalContainer>
      {!inLibraryContext && (
        <HeaderWrapper>
          <HeaderContainer>
            <Column
              style={{
                width: "100%",
              }}
            >
              <Row
                style={{
                  alignItems: "center",
                  gap: "0.4rem",
                  margin: "-0 0 0 -2.8rem",
                }}
              >
                <HelpLink article={ARTICLE_FINANCIAL_CONFIGURATIONS} />
                <EditableText
                  type="text"
                  smallInput={true}
                  value={localConfig.name}
                  onChange={(e) => {
                    setLocalConfig({ ...localConfig, name: e.target.value });
                  }}
                  onEnter={renameConfig}
                  onCancel={() => {
                    setLocalConfig({
                      ...localConfig,
                      name: configuration.name,
                    });
                  }}
                  textContainerStyle={{
                    maxWidth: "20vw",
                    padding: 0,
                  }}
                  renderText={(title) => (
                    <h3
                      style={{
                        margin: 0,
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        maxWidth: "60rem",
                      }}
                      title={title}
                    >
                      {title}
                    </h3>
                  )}
                  disabled={isReadOnly}
                />
              </Row>
              <Row
                style={{
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <ComponentLastChanged
                  resourceId={configuration.id}
                  changelogId={idToFinancialChangelogId(configuration.id)}
                  nodeId={nodeId}
                  category={projectId ? "project" : "org_financial_manage"}
                />
                <ProjectResourceUsage
                  resourceType={"COST_CONFIGURATION"}
                  resourceId={configuration.id}
                />
              </Row>
            </Column>
            <DescriptionModal
              disabled={isReadOnly}
              defaultValue={localConfig.description}
              updateDescription={handleUpdateCostConfigurationDescription}
              subtitle={
                <div>
                  <p>
                    The description will be visible for Admins and Editors in
                    projects with access to this configuration.
                  </p>
                </div>
              }
            />
          </HeaderContainer>

          <SettingButton
            style={{
              justifyContent: "flex-end",
              position: "absolute",
              right: "2.4rem",
              top: "14.4rem",
            }}
          >
            {readOnly && isLibraryResource && orgFinancialManageAccess && (
              <LibraryResourceActions
                resourceType="cost"
                resourceId={configuration.id}
                nodeId={nodeId}
                organisationId={nodeId}
                onDuplicate={() => duplicateToProject(configuration)}
                isLoadingDuplicate={isLoadingDuplicate}
              />
            )}

            {!allChangesSaved && !readOnly && save && (
              <>
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Cancel"
                  buttonType="secondary"
                  onClick={() => {
                    setLocalConfig(configuration);
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                />
                <Button
                  disabled={isLoading || allChangesSaved || isSaving}
                  text="Save changes"
                  onClick={() => {
                    setToastMessages((tm) => [
                      ...tm,
                      {
                        text: "Saving...",
                        timeout: 1000,
                      },
                    ]);
                    save({
                      ...localConfig,
                      name: localConfig.name,
                    });
                  }}
                />
              </>
            )}
          </SettingButton>
        </HeaderWrapper>
      )}

      <ContentWrapper
        style={{
          maxHeight: "84%",
          boxSizing: "border-box",
          paddingBottom: 0,
          padding: inLibraryContext ? "1.6rem 0rem" : "1.6rem 2.4rem",
        }}
      >
        <ContentContainer
          style={{
            maxHeight: "100%",
            boxSizing: "border-box",
          }}
        >
          <Tabs
            tabs={tabs}
            menuStyle={{
              justifyContent: "space-between",
              gap: "5rem",
            }}
            contentWrapperStyle={{
              minHeight: "40vh",
              overflow: "auto",
              height: "100%",
            }}
            buttonSection={
              <SettingButton>
                {inLibraryContext && !allChangesSaved && !readOnly && save && (
                  <>
                    <Button
                      disabled={isLoading || isSaving || allChangesSaved}
                      text="Cancel"
                      buttonType="text"
                      onClick={() => {
                        setLocalConfig(configuration);
                      }}
                      style={{
                        marginLeft: "auto",
                      }}
                    />
                    <Button
                      disabled={isLoading || allChangesSaved || isSaving}
                      text="Save changes"
                      onClick={() => {
                        setToastMessages((tm) => [
                          ...tm,
                          {
                            text: "Saving...",
                            timeout: 1000,
                          },
                        ]);
                        save(localConfig);
                      }}
                    />
                  </>
                )}
              </SettingButton>
            }
          />
        </ContentContainer>
      </ContentWrapper>
    </ModalContainer>
  );
};

export const CostSettingsInner = ({
  configuration,
  nodeId,
  save,
  readOnly,
  isLibraryResource,
}: {
  configuration: CostConfiguration;
  nodeId: string;
  save?: (config: CostConfiguration) => Promise<any>;
  readOnly?: boolean;
  isLibraryResource: boolean;
}) => {
  const [localConfig, setLocalConfig] = useAtom(localCostConfigurationAtom);

  const previousConfiguration = usePrevious(configuration);

  useEffect(() => {
    if (!previousConfiguration) {
      setLocalConfig(configuration);
    } else {
      setLocalConfig((currentLocalConfig) => {
        return mergePreservingChanges(
          previousConfiguration,
          configuration,
          currentLocalConfig,
        );
      });
    }
  }, [previousConfiguration, configuration, setLocalConfig]);

  if (objectEquals(localConfig, {})) return null;

  return (
    <CostSettingsWithConfig
      configuration={configuration}
      nodeId={nodeId}
      save={save}
      readOnly={readOnly}
      isLibraryResource={isLibraryResource}
    />
  );
};
