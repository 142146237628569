import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";
import { useMouseSampler } from "../../hooks/mouseSampler";
import { getWindTile } from "../../services/gwaService";
import { windLayerHeightAtom, windLayerSourceAtom } from "../../state/layer";
import { isDefined } from "../../utils/predicates";

const zoomLevel = 6;
const tileSize = 512;

export const useWindMouseSampler = () => {
  const windLayerHeight = useAtomValue(windLayerHeightAtom);
  const source = useAtomValue(windLayerSourceAtom);
  const getWeibullTileWithHeight = useCallback(
    (x: number, y: number, z: number) =>
      getWindTile(x, y, z, windLayerHeight, source),
    [windLayerHeight, source],
  );
  const sample = useMouseSampler(getWeibullTileWithHeight, zoomLevel, tileSize);
  const meanSpeed = useMemo(() => {
    if (!sample) return;
    return (sample[0] / 255) * 10 + 3;
  }, [sample]);

  if (!meanSpeed) return;

  if (!isDefined(sample) || sample[2] === 0) return;

  return meanSpeed;
};
