import HelpIcon from "@icons/24/Help.svg";
import { Icon } from "components/General/Icons";
import Tooltip from "components/General/Tooltip";
import { LayerControlWrapper } from "../RightSide/RightSide.style";
import { AnimatedBlueHelpPill } from "./LowerRight.style";

const LowerRightHelpOnly = () => {
  return (
    <LayerControlWrapper open={false}>
      <Tooltip text="Open help center" position="top" outerDivStyle={{}}>
        <AnimatedBlueHelpPill id="help_icon_link">
          <Icon stroke="white">
            <HelpIcon />
          </Icon>
          <p>Help</p>
        </AnimatedBlueHelpPill>
      </Tooltip>
    </LayerControlWrapper>
  );
};

export default LowerRightHelpOnly;
