import { useSetAtom } from "jotai";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import type { MapboxGeoJSONFeature, MapMouseEvent } from "mapbox-gl";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import { currentSelectionArrayAtom } from "../../state/selection";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import { useCheckWMSInfo } from "../../hooks/useCheckWMSInfo";
import { dedup } from "../../utils/utils";
import { mapAtom } from "../../state/map";
import { projectIdAtom } from "../../state/pathParams";
import { visibleWmsLayersSelector } from "../LayerList/LayerSettings/utils";
import NoSelectionMenu from "./SelectionMenu/NoSelectionMenu";
import OneOrMoreCanvasSelectionMenu from "./SelectionMenu/OneOrMoreCanvasSelectionMenu";
import OneOrMoreCanvasAndExternalSelectionMenu from "./SelectionMenu/OneOrMoreCanvasAndExternalSelectionMenu";
import SingleFeatureMenu from "./SelectionMenu/SingleFeatureMenu";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import useSelectionInMap from "hooks/useSelectionInMap";
import { turbineDiameterSourceId } from "components/Mapbox/constants";
import { useAtomValue } from "jotai";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import { featureMapFamily } from "state/jotai/features";
import { SkeletonText } from "components/Loading/Skeleton";

const SelectionMenu = ({
  closeMenu,
  mapMouseEvent,
}: {
  closeMenu: () => void;
  mapMouseEvent: MapMouseEvent | undefined;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const map = useAtomValue(mapAtom);
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );
  const { onProjectFeatureClick: onFeatureClick, onExternalLayerClick } =
    useSelectionInMap();
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const visibleWmsLayers = useAtomValue(
    visibleWmsLayersSelector({
      projectId,
    }),
  );
  const checkWMSInfo = useCheckWMSInfo(visibleWmsLayers);
  const dynamicLayerSelection = useAtomValue(currentExternalLayerSelection);
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;

  const sampleWmsCallback = useCallback(() => {
    if (!mapMouseEvent) {
      return;
    }

    checkWMSInfo(mapMouseEvent);
  }, [checkWMSInfo, mapMouseEvent]);

  const featuresOnPoint = useMemo<MapboxGeoJSONFeature[]>(() => {
    if (!mapMouseEvent || !map) {
      return [];
    }

    const features = map
      .queryRenderedFeatures(mapMouseEvent.point)
      .filter((feature) => !feature.source?.startsWith(turbineDiameterSourceId))
      .filter((f) => f.properties?.id);

    return dedup(features, (feature) => feature.properties?.id);
  }, [mapMouseEvent, map]);

  const rightClickedFeature = useMemo(() => {
    const mapFeature = featuresOnPoint.find(
      (feature) => feature.id && map?.getFeatureState(feature)?.hover,
    );
    const externalFeature = dynamicLayerSelection.find(
      (feature) => feature.id && map?.getFeatureState(feature)?.hover,
    );
    const projectFeature = featureMap.get(String(mapFeature?.id));
    return projectFeature ?? externalFeature ?? mapFeature;
  }, [featuresOnPoint, dynamicLayerSelection, featureMap, map]);

  const clickedFeatureIsProjectFeature = useMemo(() => {
    return (
      rightClickedFeature && featureMap.has(rightClickedFeature.properties!.id)
    );
  }, [rightClickedFeature, featureMap]);

  const clickedFeatureBelongsToCurrentSelection = useMemo(() => {
    return (
      rightClickedFeature &&
      selectedFeatures.some(
        (projectFeature) =>
          projectFeature.properties.id === rightClickedFeature.properties!.id,
      )
    );
  }, [rightClickedFeature, selectedFeatures]);

  const hasVisibleWmsLayers = useMemo(
    () => visibleWmsLayers.length > 0,
    [visibleWmsLayers],
  );

  const onSelectElement = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      const isExternalLayer = !featureMap.has(String(feature.id));
      if (isExternalLayer) {
        onExternalLayerClick([feature], false, false);
      } else {
        onFeatureClick([feature], false, false);
      }
    },
    [onExternalLayerClick, onFeatureClick, featureMap],
  );

  const onMouseEnterFeature = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      if (feature.id) {
        map!.setFeatureState(feature, {
          hover: true,
        });
      }
    },
    [map],
  );
  const onMouseLeaveFeature = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      if (feature.id) {
        map!.removeFeatureState(feature, "hover");
      }
    },
    [map],
  );

  useEffect(() => {
    if (rightClickedFeature && !clickedFeatureBelongsToCurrentSelection) {
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    }
  }, [
    clickedFeatureBelongsToCurrentSelection,
    rightClickedFeature,
    setCurrentSelectionArray,
  ]);

  const clickedAFeatureThatIsNotSelected =
    rightClickedFeature &&
    clickedFeatureIsProjectFeature &&
    (!clickedFeatureBelongsToCurrentSelection || selectedFeatures.length === 1);

  if (
    canEdit &&
    clickedFeatureIsProjectFeature &&
    clickedAFeatureThatIsNotSelected
  ) {
    return (
      <Suspense fallback={<SkeletonText />}>
        <SingleFeatureMenu
          feature={rightClickedFeature ?? selectedFeatures[0]}
          featuresOnPoint={featuresOnPoint}
          enableShowLayerInfo={hasVisibleWmsLayers}
          closeMenu={closeMenu}
          sampleWmsCallback={sampleWmsCallback}
          onSelectFeature={onSelectElement}
          onMouseEnterFeature={onMouseEnterFeature}
          onMouseLeaveFeature={onMouseLeaveFeature}
        />
      </Suspense>
    );
  }

  if (dynamicLayerSelection.length !== 0 && canEdit)
    return (
      <OneOrMoreCanvasAndExternalSelectionMenu
        closeMenu={closeMenu}
        selectedProjectFeatures={selectedFeatures}
        dynamicLayerSelection={dynamicLayerSelection}
        sampleWmsCallback={sampleWmsCallback}
        featuresOnPoint={featuresOnPoint}
        enableShowLayerInfo={hasVisibleWmsLayers}
        onSelectFeature={onSelectElement}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
      />
    );

  if (selectedFeatures.length !== 0 && canEdit)
    return (
      <OneOrMoreCanvasSelectionMenu
        closeMenu={closeMenu}
        selectedProjectFeatures={selectedFeatures}
        sampleWmsCallback={sampleWmsCallback}
        featuresOnPoint={featuresOnPoint}
        enableShowLayerInfo={hasVisibleWmsLayers}
        onSelectFeature={onSelectElement}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
      />
    );

  return (
    <NoSelectionMenu
      sampleWmsCallback={sampleWmsCallback}
      closeMenu={closeMenu}
      featuresOnPoint={featuresOnPoint}
      onSelectFeature={onSelectElement}
      enableShowLayerInfo={hasVisibleWmsLayers}
      onMouseEnterFeature={onMouseEnterFeature}
      onMouseLeaveFeature={onMouseLeaveFeature}
    />
  );
};

export default SelectionMenu;
