import { FeatureCollection } from "geojson";
import { Map } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../../../constants/division";
import {
  ANCHOR_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
  PORT_POINT_PROPERTY_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  SENSOR_POINT_PROPERTY_TYPE,
} from "../../../constants/projectMapView";
import { PARK_PROPERTY_TYPE } from "../../../constants/park";
import {
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../../constants/cabling";
import usePrevious from "../../../hooks/usePrevious";
import {
  addFeatureAtom,
  editFeaturesAtom as editFeatureIdsAtom,
  mapControlsAtom,
} from "../../../state/map";
import TopLevelFeatureCallbacks from "./Callbacks/TopLevelFeatureCallbacks";
import { isDefined } from "../../../utils/predicates";
import { VIEWPOINT_PROPERTY_TYPE } from "@constants/projectMapView";
import { useArrayDidChange } from "../../../hooks/useArrayDidChange";
import {
  mouseMoveHandlerAtom,
  clickHandlerAtom,
  doubleClickHandlerAtom,
} from "components/Mapbox/state";
import { useAtomValue, useSetAtom } from "jotai";
import { featureMapFamily } from "state/jotai/features";

export default function EditAnyFeature({ map }: { map: Map }) {
  const editFeatureIds = useAtomValue(editFeatureIdsAtom);
  const previousEditIds = usePrevious(editFeatureIds);
  const addFeature = useAtomValue(addFeatureAtom);
  const mapControls = useAtomValue(mapControlsAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );

  const featuresToEdit = useMemo(
    () => editFeatureIds.map((id) => featureMap.get(id)).filter(isDefined),
    [editFeatureIds, featureMap],
  );

  const featureToEditChanged = useArrayDidChange(featuresToEdit);
  const setMouseMoveHandler = useSetAtom(mouseMoveHandlerAtom);
  const setClickHandler = useSetAtom(clickHandlerAtom);
  const setDoubleClickHandler = useSetAtom(doubleClickHandlerAtom);

  useEffect(() => {
    if (!mapControls || !editFeatureIds || featuresToEdit.length === 0) return;
    setMouseMoveHandler(() => () => {});
    setClickHandler(() => () => {});
    setDoubleClickHandler(() => () => {});
    return () => {
      setMouseMoveHandler(undefined);
      setClickHandler(undefined);
      setDoubleClickHandler(undefined);
    };
  }, [
    editFeatureIds,
    featuresToEdit.length,
    mapControls,
    setClickHandler,
    setDoubleClickHandler,
    setMouseMoveHandler,
  ]);

  useEffect(() => {
    if (!mapControls || !editFeatureIds || featuresToEdit.length === 0) return;
    if (!featureToEditChanged) return;

    const fc: FeatureCollection = {
      type: "FeatureCollection",
      features: featuresToEdit,
    };

    let featureIds: string[] = mapControls.add(fc);
    if (
      featureIds.length === 1 &&
      fc.features.every((f) =>
        ["Polygon", "LineString"].includes(f.geometry.type),
      )
    ) {
      mapControls.changeMode("direct_select", {
        featureId: featureIds[0],
      });
    } else {
      mapControls.changeMode("simple_select", {
        featureIds: featureIds,
      });
    }
    map.getCanvas().style.cursor = "unset";
  }, [
    editFeatureIds,
    map,
    mapControls,
    featuresToEdit,
    previousEditIds,
    featureToEditChanged,
  ]);

  const drawCallbacks = useMemo(() => {
    if (addFeature?.mode.includes("draw"))
      return <TopLevelFeatureCallbacks map={map} />;
    if (
      featuresToEdit.every(
        (f) =>
          f?.properties?.type === undefined ||
          [
            SUBSTATION_PROPERTY_TYPE,
            EXPORT_CABLE_PROPERTY_TYPE,
            DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
            SUB_AREA_PROPERTY_TYPE,
            CABLE_CORRIDOR_PROPERTY_TYPE,
            CABLE_PROPERTY_TYPE,
            TURBINE_PROPERTY_TYPE,
            PARK_PROPERTY_TYPE,
            ANCHOR_PROPERTY_TYPE,
            MOORING_LINE_PROPERTY_TYPE,
            VIEWPOINT_PROPERTY_TYPE,
            SENSOR_POINT_PROPERTY_TYPE,
            GRID_CONNECTION_POINT_TYPE,
            PORT_POINT_PROPERTY_TYPE,
            EXISTING_TURBINE_PROPERTY_TYPE,
          ].includes(f?.properties?.type ?? ""),
      )
    )
      return <TopLevelFeatureCallbacks map={map} />;

    return null;
  }, [addFeature, map, featuresToEdit]);

  return <>{drawCallbacks}</>;
}
