import { CostType } from "services/costService";
import { Cost, unitToAmountUnit } from "types/financial";
import { isDefined } from "utils/predicates";
import { amountId } from "./amountIds";

export const costId = ({
  category,
  costId,
  featureTypeId = undefined,
}: {
  category: CostType;
  costId: string;
  featureTypeId?: string;
}) =>
  [category, featureTypeId, costId]
    .filter(isDefined)
    .join("#")
    .replace("_", "")
    .toLocaleLowerCase();

export const costAddIds = (
  costWithoutId: Omit<Cost, "id" | "amountId">,
): Cost => ({
  ...costWithoutId,
  id: costId({
    costId: costWithoutId.name.toLowerCase(),
    category: costWithoutId.category,
  }),
  amountId: amountId({
    unit: unitToAmountUnit[costWithoutId.unit],
    category: costWithoutId.category,
    featureTypeId: costWithoutId.name,
  }),
});
