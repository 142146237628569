import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { mapAtom } from "state/map";
import { roundToDecimal } from "utils/utils";

export const useMapZoomLevel = () => {
  const map = useAtomValue(mapAtom);
  const [zoomLevel, setZoomLevel] = useState(map?.getZoom() ?? 7);

  useEffect(() => {
    if (!map) {
      return;
    }
    const checkZoomLevel = () => {
      const zoom = map.getZoom();
      setZoomLevel(zoom);
    };
    map.on("moveend", checkZoomLevel);
    return () => {
      map.off("moveend", checkZoomLevel);
    };
  }, [map]);

  return roundToDecimal(zoomLevel, 1);
};
