import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { DEFAULT_MIN_SPACING } from "../constants/park";
import { State } from "../constants/webworker";
import { TurbineFeature } from "../types/feature";
import {
  OptimizeParameters,
  RegularParameters,
  RegularParametersWithUnit,
  TurbineNoiseSettings,
} from "../types/turbines";
import { parkFamily } from "./jotai/park";

export const DEFAULT_NOISE_PARAMS = {
  source: 110,
  red: 50,
  yellow: 40,
  opacity: 0.7,
  temperature: 10,
} as const;

export const showNoiseAtom = atom(false);

export const noiseLevelSelectorFamily = atomFamily(
  ({ parkId }: { parkId: string }) =>
    atom<Promise<TurbineNoiseSettings>>(async (get) => {
      if (!parkId) return DEFAULT_NOISE_PARAMS;
      const park = await get(
        parkFamily({
          parkId,
          branchId: undefined,
        }),
      );
      if (!park) return DEFAULT_NOISE_PARAMS;
      return park.properties?.noiseSettings ?? DEFAULT_NOISE_PARAMS;
    }),
);

/**
 * When generating turbines we store the "preview" turbines in this state. Since
 * generating new turbines for a zone replaces the existing ones, we also store
 * the existing turbines from *other* zones, in the same park.
 */
export const previewTurbinesState = atom<
  | undefined
  | {
      preview: TurbineFeature[];
      existing: TurbineFeature[];
    }
>(undefined);

const defaultRegularParameters: RegularParameters = {
  setNumberOfTurbines: false,
  numberOfTurbines: 0,
  minorAxisSpacing: 7,
  majorAxisSpacing: 7,
  obliquity: 0,
  shiftX: 0,
  shiftY: 0,
  rotate: 180,
  includeEdge: false,
  edgeSpacing: 7,
};
export const defaultRegularParamsWithUnits = (): RegularParametersWithUnit => {
  const p = defaultRegularParameters;
  return {
    setNumberOfTurbines: p.setNumberOfTurbines,
    numberOfTurbines: p.numberOfTurbines,
    minorAxisSpacing: {
      value: p.minorAxisSpacing,
      unit: "D",
    },
    majorAxisSpacing: {
      value: p.majorAxisSpacing,
      unit: "D",
    },
    obliquity: {
      value: p.obliquity,
      unit: "deg",
    },
    shiftX: {
      value: p.shiftX,
      unit: "m",
    },
    shiftY: {
      value: p.shiftY,
      unit: "m",
    },
    rotate: {
      value: p.rotate,
      unit: "deg",
    },
    includeEdge: false,
    edgeSpacing: {
      value: p.edgeSpacing,
      unit: "D",
    },
  };
};

export const defaultRegularOptimizeParameters = (): OptimizeParameters => {
  return {
    numberOfTurbines: 0,
    minSpacing: DEFAULT_MIN_SPACING,
    includeEdge: false,
    includeNeighbours: true,
    wakeModel: "jensen",
    seed: 0,
    runtime: "default",
    method: "regular",
    objective: "aep",
    costInput: {
      turbineCostPerMW: 1.5e6,
      foundationCostPerTon: 4000,
      cableCostPerMW: 0.2e6,
      substationCostPerMW: 0.4e6,
      exportCableCostPerMW: 0.3e6,
      devexPerMW: 0.15e6,
      decomPerMW: 1.1e6,
      opexPerYearPerMW: 50e3,
      lifetime: 25,
      discountRate: 0.05,
    },
  };
};

export const defaultIrregularOptimizeParameters = (): OptimizeParameters => {
  return {
    numberOfTurbines: 0,
    minSpacing: DEFAULT_MIN_SPACING,
    includeEdge: false,
    includeNeighbours: true,
    wakeModel: "jensen",
    seed: 0,
    runtime: "default",
    method: "irregular",
    objective: "aep",
  };
};

export const generationStateAtom = atom<State | undefined>(undefined);
