import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Mixpanel } from "mixpanel";
import { v4 as uuid } from "uuid";
import { projectIdAtom } from "state/pathParams";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { _FeatureParser, ProjectFeature } from "../../types/feature";
import BinIcon from "@icons/24/Bin.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import ColorBucketIcon from "@icons/24/ColorBucket.svg?react";
import CompareIcon from "@icons/24/Compare.svg";
import EditNamesIcon from "@icons/24/EditNames.svg";
import DownloadIcon from "@icons/24/Download.svg";
import LockIcon from "@icons/24/Lock.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SectionIcon from "@icons/24/Section.svg";
import SearchIcon from "@icons/24/Search.svg";
import UnlockIcon from "@icons/24/Unlock.svg";
import ViewIcon from "@icons/24/View.svg";
import ViewOffIcon from "@icons/24/ViewOff.svg";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize } from "../../styles/typography";
import { featureIsLocked, isPointFeature } from "../../utils/predicates";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import { modalTypeOpenAtom } from "../../state/modal";
import useTextInput from "../../hooks/useTextInput";
import { platformCtrlOrCommand } from "../../utils/utils";
import { EditableText } from "../General/EditableText";
import { DotMenu, MenuButtonRef } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import { selectedParksAtom } from "../CompareParksModal/state";
import { CompareParksModalType } from "../CompareParksModal/CompareParksModalType";
import {
  featuresToDownloadText,
  geotiffTypes,
  getDownloadMetadata,
} from "./utils";
import {
  ExpandArrowWrapper,
  FlexCenterAligned,
  ProjectElementItemWrapper,
  Ui13RegularOverflow,
} from "./ProjectElementsV2.style";
import { useProjectElementsContext } from "./ProjectElementsContext";
import { spaceMedium } from "../../styles/space";
import { lockedPropertyName } from "../../constants/canvas";
import { ProjectElementFolderType } from "./service";
import { LineIconWrapper } from "components/LayerList/LineIconWrapper";
import { ElementsToIcon } from "components/ElementsToIcon/ElementsToIcon";
import { StylingSelectorBox } from "components/StylingSelector/StylingSelector";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { elementsToIconStyle, lineIconStyle } from "./ParkElement";
import { GenerateNamesModalType } from "components/GenerateFeatureNamesModal/GenerateFeatureNamesModal";

/**
 * Element items that are under a park.
 */
export const ParkFeatureElement = ({
  features,
  parent,
  name,
  folder,
  isSelected,
  onDeleteFeatures,
  icon,
  expanded,
  onCollapseChange,
  depth = 0,
}: {
  features: ProjectFeature[];
  parent: ProjectFeature;
  name: string;
  isSelected: boolean;
  isMultiSelect?: boolean;
  folder?: ProjectElementFolderType;
  icon?: React.ReactNode;
  onAddToFolder?(folder: ProjectElementFolderType, featureIds: string[]): void;
  onCreateFolder?(featureIds: string[]): void;
  onDeleteFeatures?(featureIds: string[]): void;
  depth?: number;
  sortIndex?: number;
  expanded?: boolean;
  onCollapseChange?(): void;
}) => {
  const projectId = useAtomValue(projectIdAtom)!;

  const {
    selectedParks,
    branchId,
    navigateToPark,
    editorAccessProject,
    isReadOnly,
    goToFeatures,
    updateFeatures,
    isDownloading,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
    updateProjectElementsFolder,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    setCurrentSelectionArray,
    currentSelectionArray,
    toggleFeaturesSelected,
    deselectAllFeatures,
    shiftSelectFeatures,
  } = useProjectElementsContext();
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetAtom(
    selectedParksAtom({
      projectId,
    }),
  );
  const [disableDrag, setDisableDrag] = useState(false);
  const dotMenuRef = useRef<MenuButtonRef>(null);

  const memoizedFeatures = useMemo<ProjectFeature[]>(
    () => features,
    [features],
  );

  const stringToUnicode = useSystemSpecificUnicode();
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(name);

  useEffect(() => {
    setWrittenName(name);
  }, [name, setWrittenName]);

  const featureIds = useMemo(
    () => memoizedFeatures.map((feature) => feature.id),
    [memoizedFeatures],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(featureIds),
    [getAreAllFeaturesVisible, featureIds],
  );

  const onChangeNameSubmit = useCallback(() => {
    return updateFeatures({
      update: [
        _FeatureParser.parse({
          ...memoizedFeatures[0],
          properties: {
            ...memoizedFeatures[0].properties,
            name: writtenName,
          },
        }),
      ],
    });
  }, [memoizedFeatures, updateFeatures, writtenName]);

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        !currentSelectionArray ||
        currentSelectionArray.length === 0 ||
        !deselectAllFeatures ||
        platformCtrlOrCommand(e) ||
        e.shiftKey
      ) {
        return;
      }

      // Deselect elements if user has clicked on some other feature than the ones selected
      const isInSelected = featureIds.some((featureId) =>
        currentSelectionArray.includes(featureId),
      );
      if (!isInSelected) {
        deselectAllFeatures();
      }
    },
    [deselectAllFeatures, featureIds, currentSelectionArray],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.shiftKey) {
        if (parent) {
          toggleFeaturesSelected(featureIds);
        } else {
          shiftSelectFeatures(memoizedFeatures[0].id);
        }
      } else if (platformCtrlOrCommand(e)) {
        toggleFeaturesSelected(featureIds);
      } else {
        if (parent) {
          navigateToPark(parent.id);
        }

        goToFeatures(memoizedFeatures);
        setCurrentSelectionArray(featureIds);
      }
    },
    [
      featureIds,
      goToFeatures,
      memoizedFeatures,
      navigateToPark,
      parent,
      setCurrentSelectionArray,
      shiftSelectFeatures,
      toggleFeaturesSelected,
    ],
  );

  const allIsLocked = useMemo(
    () => memoizedFeatures.every(featureIsLocked),
    [memoizedFeatures],
  );

  const onLockFeaturesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      updateFeatureProperties(
        memoizedFeatures.map((s) => s.id),
        {
          [lockedPropertyName]: !allIsLocked,
        },
      );
    },
    [memoizedFeatures, updateFeatureProperties, allIsLocked],
  );

  const _depth = useMemo(
    () => depth ?? Number(Boolean(folder)) + Number(Boolean(parent)),
    [depth, folder, parent],
  );

  return (
    <ProjectElementItemWrapper
      isDragging={false}
      isSelected={isSelected}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        dotMenuRef.current?.setIsOpen(true);
      }}
      depth={_depth}
      enableDrag={editorAccessProject && !isReadOnly && !disableDrag}
      isHoveredTop={false}
      isHoveredBottom={false}
    >
      <FlexCenterAligned>
        {onCollapseChange ? (
          <ExpandArrowWrapper
            open={!!expanded}
            onClick={(event) => {
              event.stopPropagation();
              onCollapseChange();
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        ) : (
          <></>
        )}
        <LineIconWrapper style={lineIconStyle}>
          {icon ? (
            <IconREMSize height={1.4} width={1.4}>
              {icon}
            </IconREMSize>
          ) : (
            <ElementsToIcon
              elements={memoizedFeatures}
              style={elementsToIconStyle}
              fillPolygons={true}
              fillParks={true}
            />
          )}
        </LineIconWrapper>
        <EditableText
          type="text"
          onEditChange={setDisableDrag}
          disabled={
            !editorAccessProject || isReadOnly || Boolean(parent) || allIsLocked
          }
          onEnter={onChangeNameSubmit}
          onCancel={onChangeNameSubmit}
          onChange={onWrittenNameChange}
          value={writtenName}
        >
          <Ui13RegularOverflow
            title={writtenName}
            style={{
              fontWeight: isSelected ? "600" : undefined,
            }}
          >
            {writtenName}
          </Ui13RegularOverflow>
        </EditableText>
      </FlexCenterAligned>
      <FlexCenterAligned>
        {editorAccessProject && (
          <HideIfNotHoverOrVisible
            className={allIsLocked ? "visible" : undefined}
            style={{
              marginRight: spaceMedium,
            }}
          >
            <IconREMSize height={1.6} width={1.6} onClick={onLockFeaturesClick}>
              {allIsLocked ? <LockIcon /> : <UnlockIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>
        )}

        <HideIfNotHoverOrVisible
          className={!allFeaturesAreVisible ? "visible" : undefined}
        >
          <IconREMSize
            title="Toggle feature visibility"
            height={1.5}
            width={1.5}
            onClick={(e) => {
              e.stopPropagation();
              toggleFeaturesHidden(featureIds, false);
            }}
          >
            {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
          </IconREMSize>
        </HideIfNotHoverOrVisible>

        {isDownloading[memoizedFeatures[0].id] ? (
          <Spinner
            style={{
              width: "1rem",
              height: "1rem",
              margin: "0 1rem",
            }}
          />
        ) : (
          <HideIfNotHoverOrVisible>
            <DotMenu ref={dotMenuRef}>
              <MenuItem name="Download" icon={<DownloadIcon />}>
                <MenuItem
                  name={featuresToDownloadText(features, ".shp")}
                  icon={<DownloadIcon />}
                  onClick={() => {
                    const { ids, downloadedFeaturesFilename } =
                      getDownloadMetadata(
                        currentSelectionArray,
                        featureIds,
                        writtenName,
                        parent,
                      );
                    return downloadMultipleFeaturesShapeUsingId(
                      ids,
                      downloadedFeaturesFilename,
                      parent.id,
                    );
                  }}
                />
                {features.some(
                  (f) => !geotiffTypes.includes(f.properties.type ?? ""),
                ) && (
                  <>
                    <MenuItem
                      name={featuresToDownloadText(features, ".geojson")}
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        return downloadMultipleFeaturesGeojsonUsingId(
                          ids,
                          downloadedFeaturesFilename,
                          parent.id,
                        );
                      }}
                    />
                    <MenuItem
                      name={featuresToDownloadText(features, ".kml")}
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        return downloadMultipleFeaturesKMLUsingId(
                          ids,
                          downloadedFeaturesFilename,
                          parent.id,
                        );
                      }}
                    />
                    <MenuItem
                      name={featuresToDownloadText(features, ".dxf")}
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        return downloadMultipleFeaturesDXFUsingId(
                          ids,
                          downloadedFeaturesFilename,
                          parent.id,
                        );
                      }}
                    />
                    {features.some(isPointFeature) && (
                      <MenuItem
                        name="as .csv (Points only)"
                        icon={<DownloadIcon />}
                        onClick={() => {
                          const { ids, downloadedFeaturesFilename } =
                            getDownloadMetadata(
                              currentSelectionArray,
                              featureIds,
                              writtenName,
                              parent,
                            );
                          return downloadMultipleFeaturesCSVUsingId(
                            ids,
                            downloadedFeaturesFilename,
                            parent.id,
                          );
                        }}
                      />
                    )}
                    <MenuItem
                      name="Using custom coordinate system"
                      icon={<DownloadIcon />}
                      onClick={() => {
                        const { ids, downloadedFeaturesFilename } =
                          getDownloadMetadata(
                            currentSelectionArray,
                            featureIds,
                            writtenName,
                            parent,
                          );
                        setModalTypeOpen({
                          modalType: DownloadCustomCRSModalType,
                          metadata: {
                            featureIds: ids,
                            name: downloadedFeaturesFilename,
                            loadingId: parent.id,
                          },
                        });
                      }}
                    />
                  </>
                )}
              </MenuItem>
              <MenuItem
                name={isSelected ? "Deselect" : "Multiselect"}
                icon={<SectionIcon />}
                shortcut={`${stringToUnicode("command")} + Click`}
                onClick={() => {
                  toggleFeaturesSelected(featureIds);
                }}
              />
              {selectedParks.length > 0 && (
                <MenuItem
                  name={"Compare selected parks"}
                  icon={<CompareIcon />}
                  onClick={() => {
                    if (!branchId) {
                      return;
                    }

                    setSelectedCompareParks(
                      selectedParks.map((selectedPark) => ({
                        parkId: selectedPark.id,
                        branchId,
                        comparisonId: uuid(),
                      })),
                    );
                    setModalTypeOpen({
                      modalType: CompareParksModalType,
                    });
                  }}
                />
              )}
              <MenuItem
                name="Zoom to feature"
                icon={<SearchIcon />}
                onClick={() => goToFeatures(memoizedFeatures)}
              />
              {!isReadOnly && (
                <MenuItem
                  name="Set styling"
                  icon={<ColorBucketIcon />}
                  direction="right"
                  disabled={allIsLocked}
                >
                  <StylingSelectorBox
                    selectedProjectFeatures={memoizedFeatures}
                    direction="right"
                    enableColorStyling={false}
                    enableOpacityStyling={false}
                  />
                </MenuItem>
              )}
              {editorAccessProject && !isReadOnly && (
                <MenuItem
                  name={allIsLocked ? "Unlock elements" : "Lock elements"}
                  icon={allIsLocked ? <UnlockIcon /> : <LockIcon />}
                  onClick={onLockFeaturesClick}
                />
              )}
              {editorAccessProject &&
                !isReadOnly &&
                memoizedFeatures.length > 1 && (
                  <MenuItem
                    name="Create new names"
                    icon={<EditNamesIcon />}
                    onClick={() => {
                      Mixpanel.track_old("Generate new names click", {
                        nrFeatures: memoizedFeatures.length,
                      });
                      setModalTypeOpen({
                        modalType: GenerateNamesModalType,
                        metadata: {
                          featureIds: memoizedFeatures.map((f) => f.id),
                        },
                      });
                    }}
                  />
                )}
              {editorAccessProject && !isReadOnly && (
                <>
                  {folder && (
                    <MenuItem
                      name="Remove from folder"
                      icon={<RemoveIcon />}
                      onClick={() => {
                        const featureIdsToRemove =
                          currentSelectionArray &&
                          currentSelectionArray.length > 0
                            ? currentSelectionArray
                            : featureIds;

                        updateProjectElementsFolder?.({
                          ...folder,
                          featureIds: folder.featureIds.filter(
                            ({ id }) => !featureIdsToRemove.includes(id),
                          ),
                        });
                      }}
                    />
                  )}
                  <MenuItem
                    name="Delete"
                    disabled={allIsLocked}
                    icon={<BinIcon />}
                    onClick={() => {
                      onDeleteFeatures?.(featureIds);
                    }}
                  />
                </>
              )}
            </DotMenu>
          </HideIfNotHoverOrVisible>
        )}
      </FlexCenterAligned>
    </ProjectElementItemWrapper>
  );
};
