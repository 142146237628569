// This file has a bunch of numbers for the `turbine.glb` model that you
// probably need if you want to use it for anything, since model only contain
// one blade (so you need to copy it and handle rotation).

import { atom } from "jotai";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { deg2rad } from "utils/geometry";

/** Distance to move a blade along -x to be positioned correctly on the rotor. */
export const DIST_ROTOR_X = 5;

/** Distance up/down to move a blade so that it's rotor aligned.*/
export const DIST_ROTOR_Z = 3;

/** Move the blade out from the rotor center so that they don't all touch in
 * the center, but rather start at the corners of a triangle. */
export const DIST_BLADE_OFFSET_FROM_ROTOR = 2;

export async function fetchTurbineModel(): Promise<GLTF> {
  const url =
    "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/turbine.glb";
  const loader = new GLTFLoader();
  const gltf = await loader.loadAsync(url);
  return gltf;
}

export const turbineModelAtom = atom(() => fetchTurbineModel());

export function towerTransform({
  hubHeight = 100,
}: {
  hubHeight?: number;
}): THREE.Matrix4 {
  const modelScale = hubHeight / 100;

  return (
    new THREE.Matrix4()
      // Start tower from 0
      .multiply(new THREE.Matrix4().setPosition(0, 0, 0.5 * hubHeight))
      // Scale up
      .scale(new THREE.Vector3(modelScale, modelScale, modelScale))
      // Rotate to make Z upwards
      .multiply(new THREE.Matrix4().makeRotationX(deg2rad(90)))
  );
}

export function nacelleTransform({
  hubHeight = 100,
}: {
  hubHeight?: number;
}): THREE.Matrix4 {
  const modelScale = hubHeight / 100;

  return (
    new THREE.Matrix4()
      // Place nacelle at correct height
      .multiply(new THREE.Matrix4().setPosition(0, 0, hubHeight))
      // Scale up
      .scale(new THREE.Vector3(modelScale, modelScale, modelScale))
      // Rotate to make Z upwards
      .multiply(new THREE.Matrix4().makeRotationX(deg2rad(90)))
  );
}

/**
 * Construct a Matrix4 used for transforming the blade mesh.
 */
export function bladeTransform({
  angle,
  hubHeight = 100,
  radius = 80,
}: {
  angle: number;
  hubHeight?: number;
  radius?: number;
}): THREE.Matrix4 {
  const modelScale = hubHeight / 100;
  const bladeScale = radius / 80;

  return (
    new THREE.Matrix4()
      // Align vertically
      .multiply(
        new THREE.Matrix4().setPosition(
          0,
          0,
          DIST_ROTOR_Z * modelScale + hubHeight,
        ),
      )
      // Rotate around the rotor
      .multiply(new THREE.Matrix4().makeRotationX(deg2rad(angle)))
      // Shift the blade out to spread out the blades, instaed of rotating all around the origin.
      .multiply(
        new THREE.Matrix4().setPosition(
          -DIST_ROTOR_X * modelScale,
          DIST_BLADE_OFFSET_FROM_ROTOR * modelScale,
          0,
        ),
      )
      // Tilt the blades a little
      .multiply(new THREE.Matrix4().makeRotationY(deg2rad(-35)))
      // Scale to get the right radius
      .multiply(
        new THREE.Matrix4().makeScale(bladeScale, bladeScale, bladeScale),
      )
  );
}
