import styled from "styled-components";
import { SimpleTurbineType } from "../../../types/turbines";
import { Input, InputDimensioned } from "../../General/Input";
import { Column } from "../../General/Layout";
import { Label as _Label } from "../../General/Form";
import TableIcon from "@icons/24/Table.svg?react";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ColumnSection,
  ContentWrapper,
  HorizontalLine,
  ModalContainer,
  SecondaryText,
  Subtitle,
  TitleContainer,
} from "../../SettingsV2/Shared/styles";
import { spaceLarge } from "styles/space";
import Checkbox from "components/General/Checkbox";
import { IAVoltageType } from "services/cableTypeService";

import { HeaderContainer, HeaderWrapper } from "../shared";
import { Tag } from "components/General/Tag";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

const SimpleTurbineSettingsView = ErrorBoundaryWrapper(
  ({
    turbine,
    isLibraryTurbine,
  }: {
    turbine: SimpleTurbineType;
    isLibraryTurbine: boolean;
  }) => {
    const isDisabled = true;

    return (
      <ModalContainer>
        {" "}
        <HeaderWrapper>
          <HeaderContainer>
            <Column style={{ width: "100%" }}>
              <h3
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "60rem",
                }}
              >
                {turbine.name}
              </h3>
            </Column>
          </HeaderContainer>
        </HeaderWrapper>
        <ContentWrapper
          style={{
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent>
            <ColumnSection
              style={{
                padding: 0,
                gap: "1.6rem",
              }}
            >
              <TitleContainer>
                <Subtitle>General info</Subtitle>
                <HorizontalLine />
              </TitleContainer>
              <div
                style={{
                  display: "grid",
                  justifyContent: "start",
                  gridTemplateColumns: "auto auto auto",
                  gap: "2.4rem",
                }}
              >
                <Label>
                  <SecondaryText>Name</SecondaryText>
                  <Input
                    style={{
                      width: "42.4rem",
                    }}
                    disabled={isDisabled}
                    value={turbine.name}
                  />
                </Label>
                <Label>
                  <SecondaryText>Cost</SecondaryText>
                  <div style={{ display: "flex" }}>
                    <Tag text={"From procurement table"} icon={<TableIcon />} />
                    <div style={{ padding: "1rem 1rem" }}>
                      <HelpTooltip
                        style={{
                          display: "inline-flex",
                        }}
                        text={
                          isLibraryTurbine
                            ? "Component cost is now moved to Procurement table."
                            : "Component cost is now embedded within Financial configuration."
                        }
                        size={10}
                      />
                    </div>
                  </div>
                </Label>
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "start",
                  gridTemplateColumns: "auto auto auto auto auto",
                  gap: "2.4rem",
                }}
              >
                <Label>
                  <SecondaryText>Diameter</SecondaryText>
                  {turbine && (
                    <InputDimensioned
                      type="number"
                      disabled={isDisabled}
                      value={turbine.diameter}
                      unit={"m"}
                      style={{
                        width: "20rem",
                      }}
                    />
                  )}
                </Label>

                <Label>
                  <SecondaryText>Hub height</SecondaryText>
                  <InputDimensioned
                    style={{
                      width: "20rem",
                    }}
                    type="number"
                    disabled={isDisabled}
                    value={turbine?.hubHeight}
                    unit={"m"}
                  />
                </Label>

                <Label>
                  <SecondaryText>RNA Mass</SecondaryText>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={Math.round(turbine.rnaMass / 1000)}
                    unit={"t"}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>
              </div>
            </ColumnSection>
            <ColumnSection>
              <Label>
                <SecondaryText>Voltage</SecondaryText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: spaceLarge,
                  }}
                >
                  <Checkbox
                    value={IAVoltageType.kV66}
                    label={String(IAVoltageType.kV66).concat("kV")}
                    labelPlacement="after"
                    checked={turbine.voltage.includes(IAVoltageType.kV66)}
                    disabled={true}
                  />
                  <Checkbox
                    value={IAVoltageType.kV132}
                    label={String(IAVoltageType.kV132).concat("kV")}
                    labelPlacement="after"
                    checked={turbine.voltage.includes(IAVoltageType.kV132)}
                    disabled={true}
                  />
                </div>
              </Label>
            </ColumnSection>
            <ColumnSection>
              <div
                style={{
                  display: "grid",
                  justifyContent: "start",
                  gridTemplateColumns: "auto",
                  gap: "2.4rem",
                }}
              >
                <Label>
                  <SecondaryText>Rated power</SecondaryText>
                  <InputDimensioned
                    style={{
                      width: "20rem",
                    }}
                    type="number"
                    disabled={true}
                    value={turbine.ratedPower}
                    unit={"MW"}
                    scaleFactor={1 / 1000}
                    decimals={1}
                  />
                </Label>
              </div>
            </ColumnSection>
          </ColumnContent>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default SimpleTurbineSettingsView;
