import { atomFamily } from "utils/jotai";
import { featuresFamily, featuresInParkFamily } from "./features";
import { atom } from "jotai";
import { MooringLineFeature } from "types/feature";
import { replaceEndpoints } from "utils/replaceEndpoints";

export const mooringLinesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<MooringLineFeature[]>>(async (get) => {
      const { mooringLine, turbine, anchor } = await get(
        featuresFamily({ branchId }),
      );
      const turbineMap = new Map(turbine.map((t) => [t.id, t]));
      const anchorMap = new Map(anchor.map((a) => [a.id, a]));
      return mooringLine.map((ml) =>
        replaceEndpoints(
          ml,
          turbineMap.get(ml.properties.target),
          anchorMap.get(ml.properties.anchor),
        ),
      );
    }),
);

export const mooringLinesInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<MooringLineFeature[]>>(async (get) => {
      const { mooringLine, turbine, anchor } = await get(
        featuresInParkFamily({ parkId, branchId }),
      );

      const anchorMap = new Map(anchor.map((a) => [a.id, a]));
      const turbineMap = new Map(turbine.map((t) => [t.id, t]));
      return mooringLine.map((ml) =>
        replaceEndpoints(
          ml,
          anchorMap.get(ml.properties.anchor),
          turbineMap.get(ml.properties.target),
        ),
      );
    }),
);
