import { useAtom, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useMemo } from "react";
import {
  Column as ReactTableColumn,
  SortByFn,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import EarthIcon from "@icons/14/Earth.svg";
import ListViewIcon from "@icons/16/ListView.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import DirectionUpIcon from "@icons/24/DirectionUp.svg?react";
import DirectionDownIcon from "@icons/24/DirectionDown.svg?react";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { spacing4, spacing6, spacing8 } from "styles/space";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { SkeletonText } from "components/Loading/Skeleton";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { DataLibraryLayer } from "../types";
import {
  HeaderColumn,
  Table,
  StickyThead,
  ProjectInfo,
  UserInfoBigger,
} from "../shared";
import {
  activePackageIdAtom,
  getDataLayersUsage,
  organisationLibraryLayersSelector,
  scrollToOrganisationLayerIdAtom,
} from "../state";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import Tooltip from "components/General/Tooltip";
import { UsageContainerInner2 } from "components/ConfigurationModal/SettingsUsage/common";
import { UsageContainerPlacement } from "components/ConfigurationModal/SettingsUsage/style";
import { organisationResourceLastUpdateSelectorFamily } from "components/Changelog/state";
import { Mixpanel } from "mixpanel";
import { usersInOrganisationState } from "components/Organisation/state";
import { getSortTypes } from "components/Organisation/Library/dataLibrary/sortTypes";
import { idToCustomLayerChangelogId } from "components/InputChangelog/const";
import { loadable, unwrap } from "jotai/utils";
import { useAtomValue } from "jotai";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { ComponentLastChangedSimple } from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import useBooleanState from "hooks/useBooleanState";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";
import { useJotaiCallback } from "utils/jotai";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";

const columns: Array<ReactTableColumn<DataLibraryLayer>> = [
  {
    id: "name",
    width: "40%",
    // @ts-ignore
    canSort: true,
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Header: () => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
          }}
        >
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row }) => {
      return (
        <Row
          alignCenter
          style={{
            display: "flex",
            gap: spacing6,
            paddingLeft: spacing8,
          }}
        >
          <p>{row.original.name}</p>
        </Row>
      );
    },
  },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    sortType: "lastChanged",
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,
    Cell: ({ row, lastChanges }) =>
      typeof lastChanges === "undefined" ? (
        <SkeletonText />
      ) : (
        <ComponentLastChangedSimple
          changelogId={idToCustomLayerChangelogId(row.original.id)}
          category="org_data_package_manage"
        />
      ),
  },

  {
    id: "author",
    // @ts-ignore
    canSort: true,
    sortType: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    Cell: ({ row, organisationId }) => {
      return (
        <Row alignCenter>
          <UserInfoBigger
            organisationId={organisationId}
            userId={row.original.author}
          />
        </Row>
      );
    },
  },
  {
    id: "nrPackageUsages",
    width: 100,
    Header: <HeaderColumn>Usage</HeaderColumn>,
    Cell: ({ row, layerUsages }) => {
      const usage = (layerUsages[row.original.id] ?? []) as string[];
      return (
        <Row
          alignCenter
          style={{
            gap: "0",
          }}
        >
          <UsageContainerInner2
            icon={<EarthIcon />}
            wrapperStyle={{
              backgroundColor: "unset",
              alignItems: "center",
              gap: spacing4,
              padding: 0,
            }}
            text={""}
            loadable={{ state: "hasData", data: usage }}
            typeName=""
            placement={UsageContainerPlacement.BOTTOM}
          >
            {usage &&
              usage.map((projectId) => (
                <ProjectInfo key={projectId} projectId={projectId} />
              ))}
          </UsageContainerInner2>
        </Row>
      );
    },
  },
  {
    id: "actions",
    Header: <></>,
    width: 50,
    Cell: ({ row, onGoToLayerClick, onDeleteClick }) => {
      const { editLayerMetadata } = useDataLibraryLayersCrud();
      const [, toggleIsOpen] = useBooleanState(false);
      return (
        <Row
          alignCenter
          style={{
            gap: spacing6,
            justifyContent: "flex-end",
            paddingRight: "0.8rem",
          }}
        >
          <DescriptionModal
            size="small"
            updateDescription={(description: string) =>
              editLayerMetadata(row.original.id, {
                description,
              })
            }
            disabled={true} //Should not be able to edit layer description inside a package
            defaultValue={row.original.description ?? ""}
            close={toggleIsOpen}
            subtitle={<></>}
          />

          <Tooltip text="Show in All data list">
            <IconBtn
              hoverBackgroundColor={colors.grey200}
              style={{
                padding: "0.9rem",
              }}
              onClick={(e) => {
                e.stopPropagation();
                onGoToLayerClick(row.original.id);
              }}
              size="1.4rem"
            >
              <ListViewIcon />
            </IconBtn>
          </Tooltip>
          <Tooltip text="Remove from package">
            <IconBtn
              hoverBackgroundColor={colors.grey200}
              style={{
                padding: "0.9rem",
              }}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClick(row.original.id);
              }}
              size="1.4rem"
            >
              <RemoveIcon />
            </IconBtn>
          </Tooltip>
        </Row>
      );
    },
  },
];

const PackageTable = ({
  packageId,
  layerIds,
}: {
  packageId: string;
  layerIds: string[];
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { removeLayersFromPackage } = useDataLibraryLayersCrud();
  const layers = useAtomValue(
    organisationLibraryLayersSelector({
      layerIds,
    }),
  );
  const setActivePackageId = useSetAtom(activePackageIdAtom);
  const scrollToOrganisationLayerId = useSetAtom(
    scrollToOrganisationLayerIdAtom,
  );

  const allMembersRaw = useAtomValue(usersInOrganisationState(organisationId));

  const getUsername = useCallback(
    (userId: string) => {
      return allMembersRaw.find((m) => m.user_id === userId)?.nickname;
    },
    [allMembersRaw],
  );

  const layerUsages = useAtomValue(
    getDataLayersUsage({
      layerIds: layers.map((l) => l.id),
      nodeId: organisationId,
    }),
  );

  const lastChangesLoadable = loadable(
    organisationResourceLastUpdateSelectorFamily({
      organisationId,
      resourceIds: layers.map((l) => idToCustomLayerChangelogId(l.id)),
    }),
  );

  const [, setContent] = useAtom(organisationRightSideModal(organisationId));

  const onGoToLayerClick = useCallback(
    (layerId: string) => {
      Mixpanel.track_old("Go to library layer from library package", {});
      scrollToOrganisationLayerId(layerId);
      setActivePackageId("all-data");
      setContent({
        type: "all-data-layers",
      });
    },
    [setActivePackageId, scrollToOrganisationLayerId, setContent],
  );

  const getChangeLogForItem = useJotaiCallback(
    (get, _set, changelogId: string) => {
      const changes = get(
        unwrap(
          inputChangelogsAtomFamily({
            nodeId: organisationId,
            changelogId,
            category: "org_data_package_manage",
            organisationId,
          }),
        ),
      );

      if (changes) {
        return changes[0];
      }
      return undefined;
    },
    [organisationId],
  );

  const onDeleteClick = useCallback(
    (layerId: string) => {
      const usage = layerUsages[layerId];
      Mixpanel.track_old("Remove library layer from library package", {
        usageLength: usage?.length ?? 0,
      });

      return removeLayersFromPackage(organisationId, packageId, [layerId]);
    },
    [organisationId, packageId, removeLayersFromPackage, layerUsages],
  );

  const sortTypes = useMemo<Record<string, SortByFn<DataLibraryLayer>>>(
    () => getSortTypes(getUsername, getChangeLogForItem),
    [getUsername, getChangeLogForItem],
  );

  const { getTableProps, headerGroups, getTableBodyProps, page, prepareRow } =
    useTable<DataLibraryLayer>(
      {
        columns,
        data: layers,
        sortTypes,
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSortBy: false,
        initialState: {
          pageSize: 1000,
          pageIndex: 0,
          sortBy: [
            {
              id: "created_at",
              desc: true,
            },
          ],
        },
      },
      useSortBy,
      usePagination,
    );

  return (
    <ScrollBody>
      <Table
        {...getTableProps({
          style: {
            width: "100%",
            borderSpacing: 0,
          },
        })}
      >
        <StickyThead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      onClick: (e) => {
                        if (
                          !column.canSort ||
                          e.nativeEvent
                            .composedPath()
                            .find(
                              (e) =>
                                e instanceof HTMLElement &&
                                e.dataset.type === "checkbox",
                            )
                        ) {
                          return;
                        }

                        Mixpanel.track_old("Sort library packages", {
                          id: column.id,
                          desc: !column.isSortedDesc,
                        });
                        column.toggleSortBy(!column.isSortedDesc);
                      },
                      style: {
                        textAlign: "unset",
                        cursor: column.canSort ? "pointer" : "unset",
                        width: column.width,
                        padding: "2rem 0",
                      },
                    }),
                  )}
                >
                  <Row alignCenter>
                    {column.render("Header")}
                    {column.canSort && (
                      <span
                        style={{
                          marginLeft: "0.3rem",
                        }}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DirectionDownIcon height={12} width={12} />
                          ) : (
                            <DirectionUpIcon height={12} width={12} />
                          )
                        ) : (
                          <DirectionDownIcon height={12} width={12} />
                        )}
                      </span>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          ))}
        </StickyThead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <React.Fragment key={row.original.id}>
                <tr
                  {...row.getRowProps({
                    style: {
                      cursor: "pointer",
                      borderSpacing: 0,
                    },
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            padding: `${spacing4} 0`,
                          },
                        })}
                      >
                        <React.Suspense fallback={<SkeletonText />}>
                          {cell.render("Cell", {
                            organisationId,
                            onGoToLayerClick,
                            onDeleteClick,
                            layerUsages,
                            lastChanges: unwrap(lastChangesLoadable),
                          })}
                        </React.Suspense>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </ScrollBody>
  );
};

export default PackageTable;
