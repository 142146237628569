import {
  PostOptProblemArgs,
  deleteOptProblem,
  listOptProblems,
  listResultsForProblem,
  postOptProblem,
} from "../../functions/optimize";
import { scream, sendWarning } from "../../utils/sentry";
import {
  Ids,
  Pids,
  listOptProblemsAtom,
  problemResultsAtom,
  recentlyDeletedProblemAtom,
} from "./state";
import { useSetAtom } from "jotai";
import { aset, useJotaiCallback } from "utils/jotai";
import { useCallback } from "react";
import { useToast } from "hooks/useToast";

export const useOptimizationCrud = () => {
  const setRecentlyDeletedProblem = useSetAtom(recentlyDeletedProblemAtom);
  const { error: showError, warning: showWarning } = useToast();

  const list = useJotaiCallback(async (_, set, ids: Ids) => {
    const problems = await listOptProblems(ids);
    set(listOptProblemsAtom(ids), Promise.resolve(problems));
  }, []);

  const deleteProblem = useCallback(
    async (pids: Pids, recentlyDeletedProblem: string[]) => {
      const res = await deleteOptProblem(pids);
      if (res.success) {
        setRecentlyDeletedProblem([...recentlyDeletedProblem, pids.id]);
      }
      return res;
    },
    [setRecentlyDeletedProblem],
  );

  const create = useJotaiCallback(
    async (get, set, { ids, args }: { ids: Ids; args: PostOptProblemArgs }) => {
      const res = await postOptProblem({
        ids,
        args,
      }).catch((e) => {
        if (e instanceof TypeError && e.message === "Failed to fetch") {
          showError("Failed to start optimization, please try again.");
          return;
        } else if (e.status === 429) {
          showWarning(
            "Optimization limit of 5 running per project is reached, please try again later.",
          );
          sendWarning("User reached optimization limit", { e, ids });
          return;
        }
        showError("Failed to start optimization.");
        if (e instanceof Error) {
          scream(e, {
            ids,
            message: "postOptProblem endpoint failed",
            args: {
              numberOfTurbines: args.numberOfTurbines,
              includeEdge: args.includeEdge,
              method: args.method,
              runtime: args.runtime,
            },
          });
        } else {
          scream(new Error("postOptProblem endpoint failed"), {
            e,
            ids,
            args: {
              numberOfTurbines: args.numberOfTurbines,
              includeEdge: args.includeEdge,
              method: args.method,
              runtime: args.runtime,
            },
          });
        }
      });

      if (res) {
        aset(get, set, listOptProblemsAtom(ids), (curr) =>
          curr.concat({
            ...res,
            numberOfTurbines: args.numberOfTurbines,
          }),
        );
      }
      // If creating failed, it might have timed out.  List again to see if we
      // get back a new one.
      else await list(ids);

      return res;
    },
    [list, showError, showWarning],
  );

  const getResults = useJotaiCallback(async (_, set, pids: Pids) => {
    const promise = await listResultsForProblem(pids).catch(() => undefined);
    set(problemResultsAtom(pids), Promise.resolve(promise));
    return promise;
  }, []);

  return {
    create,
    list,
    getResults,
    deleteProblem,
  };
};
