import React from "react";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { DrawMooringLineMenuType } from "@constants/draw";

const DrawMooringLineHelp = () => {
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();

  if (leftMenuActiveMode !== DrawMooringLineMenuType) return null;

  return (
    <MenuFrame
      title="Draw mooring line"
      onExit={() => setLeftMenuActiveMode(undefined)}
    >
      <p>Mooring lines needs to be connected to an anchor and a turbine</p>
    </MenuFrame>
  );
};

export default DrawMooringLineHelp;
