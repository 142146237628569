const clr = {
  grey50: "#f8f8f8",
  grey100: "#f0f0f0",
  grey200: "#e6e6e6",
  grey300: "#d1d3d4",
  grey400: "#b1b1b1",
  grey500: "#8f949a",
  grey600: "#6d6d6d",
  grey700: "#515867",
  grey800: "#3f4451",
  grey900: "#33363f",

  blue10: "#fafbfd",
  blue50: "#f2f9ff",
  blue100: "#e4f3ff",
  blue200: "#cceaff",
  blue300: "#7ad6f6",
  blue400: "#13bbf6",
  blue500: "#08a0d5",
  blue600: "#0086b4",
  blue700: "#02698f",
  blue800: "#114d72",
  blue900: "#023859",

  green50: "#effaf5",
  green100: "#dff4ec",
  green200: "#adf4ce",
  green300: "#67dea8",
  green400: "#49cc93",
  green500: "#00a86b",
  green600: "#008f5d",
  green700: "#007a4d",
  green800: "#00653e",
  green900: "#005132",

  yellow100: "#fffdd6",
  yellow200: "#fffaa3",
  yellow300: "#fbf198",
  yellow400: "#fff75c",
  yellow500: "#fff104",
  yellow600: "#f8d904",

  red50: "#fff7f7",
  red100: "#ffebe7",
  red200: "#ffddd6",
  red300: "#ffb7a9",
  red400: "#ff7c65",
  red500: "#ff504f",
  red600: "#ea3829",
  red700: "#ca3535",
  red800: "#b40000",
  red900: "#8b0a00",

  indigo50: "#f4f6ff",
  indigo100: "#edefff",
  indigo200: "#e0e2ff",
  indigo300: "#c1c4ff",
  indigo400: "#acafff",
  indigo500: "#7e84fc",
  indigo600: "#4d63fe",
  indigo700: "#4046ca",
  indigo800: "#3236a8",
  indigo900: "#262986",

  seagreen100: "#cef7f3",
  seagreen200: "#aaf1ea",
  seagreen300: "#8ce9e2",
  seagreen400: "#65dad2",
  seagreen500: "#3fc9c1",
  seagreen600: "#0fb5ae",
  seagreen700: "#008179",
  seagreen800: "#00635f",
  seagreen900: "#123c3a",

  magenta100: "#ffeaf1",
  magenta200: "#ffcadd",
  magenta300: "#ffb2ce",
  magenta400: "#ff95bd",
  magenta500: "#ef5a98",
  magenta600: "#e91e62",
  magenta700: "#ad0955",
  magenta800: "#8e0045",
  magenta900: "#54032a",

  chartreuse100: "#dbfc6e",
  chartreuse200: "#cbf443",
  chartreuse300: "#bce92a",
  chartreuse400: "#98c50a",
  chartreuse500: "#769c00",
  chartreuse600: "#678800",
  chartreuse700: "#577400",
  chartreuse800: "#486000",
  chartreuse900: "#3a4d00",

  orange50: "#fffaeb",
  orange100: "#fff4d6",
  orange200: "#ffead6",
  orange300: "#ffbd80",
  orange400: "#fbb03b",
  orange500: "#ff9029",
  orange600: "#ff7a00",
  orange700: "#fc5500",

  fuschia100: "#fdf2fd",
  fuschia200: "#ffe9fc",
  fuschia300: "#fec7f8",
  fuschia400: "#fbaef6",
  fuschia500: "#ff75f1",
  fuschia600: "#e055e2",
  fuschia700: "#cd3ace",
  fuschia800: "#9d039e",
  fuschia900: "#730067",

  purple100: "#f6ebff",
  purple200: "#e6d0ff",
  purple300: "#dbbbfe",
  purple400: "#bd8bfc",
  purple500: "#ae72f9",
  purple600: "#794aff",
  purple700: "#7326d3",
  purple800: "#5d13b7",
  purple900: "#470c94",

  white: "#fefefe",
  black: "#091222",
} as const;

export const colors = {
  ...clr,

  backgroundDefault: clr.white,

  surfacePrimary: clr.white,
  surfaceSecondary: clr.grey50,
  surfaceBrand: clr.blue900,
  surfaceHover: clr.grey100,
  surfaceSelectedLight: clr.blue100,
  surfaceSelectedHover: clr.blue200,
  surfaceSelectedDark: clr.blue700,
  surfaceDisabled: clr.grey50,
  surfaceSubtle: clr.blue10,

  surfaceButtonPrimary: clr.blue500,
  surfaceButtonSecondary: clr.indigo50,
  surfaceSuccess: clr.green50,
  surfaceWarning: clr.orange50,
  surfaceError: clr.red50,
  surfaceInfo: clr.blue50,

  borderDefault: clr.grey300,
  borderSubtle: clr.grey100,
  borderSelected: clr.blue700,
  borderError: clr.red500,

  textPrimary: clr.grey900,
  textSecondary: clr.grey700,
  textNegative: clr.white,
  textBrand: clr.blue900,
  textHover: clr.blue600,
  textSelected: clr.blue700,
  textDisabled: clr.grey500,
  textSuccess: clr.green600,
  textWarning: clr.orange600,
  textError: clr.red700,

  iconDefault: clr.grey900,
  iconSubtle: clr.grey700,
  iconNegative: clr.white,
  iconBrand: clr.blue900,
  iconHover: clr.blue600,
  iconSecondaryHover: clr.grey200,
  iconSelected: clr.blue700,
  iconDisabled: clr.grey500,
  iconSuccess: clr.green600,
  iconWarning: clr.orange500,
  iconError: clr.red700,
  iconInfo: clr.blue600,

  other: clr.grey400,

  otherPoint: clr.grey700,
  otherPointBorder: clr.grey700,
  otherPointBorderSel: clr.white,

  otherLineString: clr.grey700,

  otherPolygon: clr.grey700,
  otherPolygonBorder: clr.grey400,
  otherPolygonBorderSel: clr.grey500,

  otherSatellite: clr.grey500,
  viewpoint: clr.indigo600,
  sensor: clr.magenta800,
  port: clr.fuschia500,
  viewFromShoreViewTowardsPoint: clr.fuschia600,
  turbine: clr.blue800,
  substation: clr.magenta600,
  anchor: clr.purple600,
  cable: clr.chartreuse200,
  exportCableOffshore: clr.orange500,
  exportCableOnshore: clr.orange700,
  mooringLine: clr.purple600,

  park: clr.blue800,
  parkSatellige: clr.blue300,

  inclusionZone: clr.green500,
  exclusionZone: clr.red700,
  cableCorridor: clr.blue300,

  existingTurbine: clr.blue600,
  existingTurbineBorder: clr.blue600,
  existingTurbineBorderSel: clr.blue800,

  existingTurbineIncomplete: clr.orange600,
  existingTurbineIncompleteBorderSel: clr.red800,

  featureEdit: clr.orange400,

  // onshore colors
  onElParkSurface: clr.blue300,
  onElParkBorder: clr.blue300,
  onElParkBorderSel: clr.blue600,

  onElTurbine: clr.indigo900,
  onElTurbineBorderHov: clr.indigo900,
  onElTurbineBorderSel: clr.white,

  onElTurbineBuffer: clr.fuschia900,
  onElExistingTurbine: clr.blue600,
  onElExistingTurbineIncomplete: clr.orange600,

  onElSubArea: clr.seagreen600,
  onElSubAreaBorder: clr.seagreen600,
  onElSubAreaBorderHov: clr.seagreen600,
  onElSubAreaBorderSel: clr.seagreen700,

  onElExclusionZone: clr.magenta600,
  onElExclusionZoneBorder: clr.magenta600,
  onElExclusionZoneBorderSel: clr.magenta700,

  onElCableCorridor: clr.yellow600,
  onElCableCorridorBorder: clr.yellow600,
  onElCableCorridorBorderHov: clr.yellow600,
  onElCableCorridorBorderSel: clr.orange400,

  onElSubstation: clr.magenta600,
  onElCable: clr.orange700,
  onElExportCable: clr.orange400,
  onElViewpoint: clr.indigo600,
  onElViewpointBorderSel: clr.indigo800,
  onElRoad: clr.grey600,

  onElOther: clr.grey400,
  onElOtherPointBorder: clr.grey400,
  onElOtherPointBorderSel: clr.grey500,

  onElOtherPolygon: clr.grey700,
  onElOtherPolygonBorder: clr.grey400,
  onElOtherPolygonBorderSel: clr.grey500,

  onElEdit: clr.orange400,

  // Placeholder colors
  exportCableMissingType: "#999",

  // New colors
  /** @deprecated */
  primaryText: "#333333",
  /** @deprecated */
  secondaryText: "#999999",
  /** @deprecated */
  lightText: "#ffffff",
  /** @deprecated */
  errorText: "#ff504f",
  /** @deprecated */
  lightYellow: "#fdf7e8",
  /** @deprecated */
  lightBlue: "#f7fbff",

  /** @deprecated */
  background: "#fefefe",

  /** @deprecated */
  brand: "#023859",
  /** @deprecated */
  primary: "#02698f",
  /** @deprecated */
  primaryHover: "#13BBF6",
  /** @deprecated */
  primaryDisabled: "#a9a9a9",

  /** @deprecated */
  notSelected: "#676F7B",

  /** @deprecated */
  secondary: "#ECECED",
  /** @deprecated */
  secondaryHover: "#D1D3D4",
  /** @deprecated */
  secondaryButtonText: "#333333",
  /** @deprecated */
  secondaryDisabledBorder: "#A9A9A9",
  /** @deprecated */
  secondaryDisabledText: "#A0A6AA",

  /** @deprecated */
  selected: "#E4F3FF",

  /** @deprecated */
  hover: "#f0f0f0",
  /** @deprecated */
  hoverSelected: "#049FC2",
  /** @deprecated */
  focusBackground: "#f8f8f8",

  /** @deprecated */
  textActionHover: "#08a0d5",
  /** @deprecated */
  borderActionHover: "#cceaff",

  /** @deprecated */
  inputOutline: "#d6d6d6",

  /** @deprecated */
  dropdownBorder: "#D6D6D6",
  /** @deprecated */
  dropdownBorderHover: "#D1D3D4",
  /** @deprecated */
  dropdownBackground: "#FEFEFE",

  /** @deprecated */
  success: "#03a356",
  /** @deprecated */
  alert: "#fcba01",
  /** @deprecated */
  warning: "#ff504f",
  /** @deprecated */
  info: "#ff7a00",

  /** @deprecated */
  defaultCanvasLayer: "#4D586A",
  /** @deprecated */
  defaultCanvasPoint: "#592302",

  // New warning colors
  /** @deprecated */
  errorValidationBackground: "#fff7f7",
  /** @deprecated */
  errorValidationIconBackground: "#FFEBE7",
  /** @deprecated */
  warningValidationBackground: "#FFFAEB",
  /** @deprecated */
  warningValidationIconBackground: "#FFF4D6",

  // Feature colors
  /** @deprecated */
  subArea: "#29ae29",
  /** @deprecated */
  exclusionZone2: "#8e2222",
  /** @deprecated */
  exclusionZone3: "#561111",
  /** @deprecated */
  anchorPreview: "#fcba03",
  /** @deprecated */
  mooringLinePreview: "#fcba03",
  /** @deprecated */
  slackRegionBreak: "#f7d9ff",
  /** @deprecated */
  slackRegion: "#b01fe3",
  /** @deprecated */
  staticOffsetRegion: "#b01fe3",
  /** @deprecated */
  externalLayerDefault: "#FF00E5",
  /** @deprecated */
  exportCable: "#cee000",
  /** @deprecated */
  viewPoint: "#008179",
  /** @deprecated */
  gridConnection: "#0021D0",
  /** @deprecated */
  point: "#5192c0",
  /** @deprecated */
  portPoint: "#E91E62",
  /** @deprecated */
  lockedFeatureOutline: "#a9a9a9",
  /** @deprecated */
  park2: "#508196", // formerly PARK_COLOR

  // Generic colors
  /** @deprecated */
  redAlert: "#FF504F",
  /** @deprecated */
  yellow: "#fcba03",
  /** @deprecated */
  orange: "#fc5500",
  /** @deprecated */
  rainbow: `linear-gradient(180deg,
    #FF4F4F 3.12%,
    rgba(251, 255, 79, 0.55) 54.17%,
    rgba(52, 239, 36, 0.38) 79%
  )`,
  /** @deprecated */
  purple: "#650275",
} as const;

/** @deprecated */
export const opacity = {
  park: 0.1,
  subArea: 0.4,
  cableCorridor: 0.4,
  externalLayer: 0.4,
  default: 0.4,
};

export const multiplayerBackgroundColors = {
  PinkFlamingo: "#FF7CE2",
  RoyalBlueberry: "#4B4BDF",
  LushMeadow: "#298D06",
  SunshineYellow: "#FFEA2B",
  VibrantOrange: "#FFA500",
  MajesticPurple: "#800080",
};
export const multiplayerFontColors = {
  PinkFlamingo: "#333333",
  RoyalBlueberry: "#fff",
  LushMeadow: "#fff",
  SunshineYellow: "#333333",
  VibrantOrange: "#333333",
  MajesticPurple: "#fff",
};

export function hexToRgb(hex: string): string | undefined {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result)
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16,
    )}, ${parseInt(result[3], 16)})`;
}
export function hexToRgbA(hex: string, a: number) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${a})`
    : null;
}
