import { Suspense, useCallback, useEffect, useState } from "react";
import { LibraryResourceHeader } from "./ResourceHeader";
import { idToFinancialChangelogId } from "components/InputChangelog/const";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import useOrgFinancialCrud from "components/Organisation/Library/financial/useOrgFinancialCrud";
import FinancialSettingInLibrary from "components/Organisation/Library/financial/FinancialSettingsInLibrary";
import { CostConfiguration } from "services/costService";
import { ShoreTypePill } from "../../style";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";

export const FinancialResourceContent = ({
  resource,
  organisationId,
}: {
  resource: CostConfiguration;
  organisationId: string;
}) => {
  const { update: updateFinancialConfig } = useOrgFinancialCrud();

  const [editResource, setEditResource] = useState(resource);
  useEffect(() => {
    setEditResource(resource);
  }, [resource, setEditResource]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<CostConfiguration>) => {
      setEditResource((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setEditResource],
  );

  if (!organisationId || !editResource) {
    return null;
  }

  return (
    <>
      <LibraryResourceHeader
        resourceType={"financial configuration"}
        description={editResource.description ?? ""}
        name={editResource.name ?? ""}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? editResource.name,
            description: description ?? editResource.description,
          })
        }
        onSave={({ name, description }) => {
          updateFinancialConfig({
            ...editResource,
            name: name ?? editResource.name,
            description: description ?? editResource.description,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            description: resource.description,
          });
        }}
        changelogData={{
          id: idToFinancialChangelogId(resource.id),
          category: "org_financial_manage",
        }}
      >
        <ShoreTypePill>
          {resource.type === "offshore" ? "Offshore" : "Onshore"}
        </ShoreTypePill>
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("financial")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <Suspense>
        <FinancialSettingInLibraryWrapper configuration={resource} />
      </Suspense>
    </>
  );
};

const FinancialSettingInLibraryWrapper = ({
  configuration,
}: {
  configuration: CostConfiguration;
}) => {
  const { update: updateFinancialConfig } = useOrgFinancialCrud();

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <FinancialSettingInLibrary
        configuration={configuration}
        onSave={updateFinancialConfig}
        isLoading={false}
      />
    </Suspense>
  );
};
