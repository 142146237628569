import { MapLayerMouseEvent } from "mapbox-gl";
import { atom } from "jotai";

type MouseHandler = (
  /**
   * The original event
   */
  e: MapLayerMouseEvent,
) => void;

export const mouseMoveHandlerAtom = atom<MouseHandler | undefined>(undefined);
export const clickHandlerAtom = atom<MouseHandler | undefined>(undefined);
export const doubleClickHandlerAtom = atom<MouseHandler | undefined>(undefined);
