/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Error = z
      .object({
        error: z.string().optional(),
      })
      .passthrough();
    export const SignedUrl = z
      .object({
        fileName: z.string(),
        presignedUrl: z.string(),
      })
      .strict();
    export const SourceType = z.union([
      z.literal("wfs"),
      z.literal("wms"),
      z.literal("wmts"),
      z.literal("arcgis"),
    ]);
    export const WMSLayer = z
      .object({
        name: z.string().optional(),
        title: z.string().optional(),
        abstract: z.string().optional(),
        subLayerNames: z.array(z.string()).optional(),
        parentLayerName: z.string().optional(),
      })
      .passthrough();
    export const ArcGISLayer = z
      .object({
        sourceId: z.string().optional(),
        name: z.string().optional(),
        title: z.string().optional(),
        abstract: z.string().optional(),
      })
      .passthrough();
    export const WFSLayer = z
      .object({
        sourceId: z.string().optional(),
        name: z.string().optional(),
        title: z.string().optional(),
        abstract: z.string().optional(),
        keywords: z.array(z.string()).optional(),
      })
      .passthrough();
    export const LLayer = z.union([WMSLayer, ArcGISLayer, WFSLayer]);
    export const Layer = z
      .object({
        id: z.string(),
        name: z.string(),
        /** The URL for the *source*. */
        url: z.string(),
        bbox: z.string(),
        /** The URL used to query for the layer. */
        path: z.string(),
        source: z.string(),
        type: z
          .union([
            z.literal("geojson"),
            z.literal("external"),
            z.literal("wms"),
            z.literal("wfs"),
            z.literal("arcgis"),
          ])
          .optional(),
        description: z.string().nullable().optional(),
        author: z.string(),
        nrPackageUsages: z.number().optional(),
        created_at: z.number(),
        updated_at: z.number().optional(),
        /** This shouldn't be here, but the frontend needs it right now. */
        outputValue: z.string().optional(),
      })
      .strict();
    export const DataPackagePartial = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        type: z.literal("package").optional(),
        description: z.string().optional(),
        layerIds: z.array(z.string()).optional(),
        author: z.string().optional(),
        created_at: z.number().optional(),
        isArchived: z.boolean().optional(),
      })
      .strict();
    export const DataPackage = z
      .object({
        id: z.string(),
        name: z.string(),
        type: z.literal("package").optional(),
        description: z.string(),
        layerIds: z.array(z.string()),
        author: z.string(),
        created_at: z.number(),
        isArchived: z.boolean(),
      })
      .strict();
    export const DataPackagePost = z
      .object({
        name: z.string().optional(),
        type: z.literal("package").optional(),
        description: z.string().optional(),
        layerIds: z.array(z.string()).optional(),
      })
      .strict();
    export const License = z
      .object({
        url: z.string().optional(),
        type: z.string().optional(),
        attribution: z.string().optional(),
      })
      .strict();
    export const SourcePartial = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        url: z.string().optional(),
        type: SourceType.optional(),
        addedLayers: z.array(z.string()).optional(),
        /** Inlined `Layer` objects, included if ?layers is passed in the query. */
        layers: z.array(Layer).optional(),
        /** All available layers. Only returned if ?allLayers is passed in the query. */
        allLayers: z.array(LLayer).optional(),
        author: z.string().optional(),
      })
      .strict();
    export const SourcePost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        url: z.string(),
        type: z.union([
          z.literal("wfs"),
          z.literal("wms"),
          z.literal("wmts"),
          z.literal("arcgis"),
        ]),
        addedLayers: z.array(z.string()).optional(),
        /** Inlined `Layer` objects, included if ?layers is passed in the query. */
        layers: z
          .array(
            z
              .object({
                id: z.string(),
                name: z.string(),
                /** The URL for the *source*. */
                url: z.string(),
                bbox: z.string(),
                /** The URL used to query for the layer. */
                path: z.string(),
                source: z.string(),
                type: z
                  .union([
                    z.literal("geojson"),
                    z.literal("external"),
                    z.literal("wms"),
                    z.literal("wfs"),
                    z.literal("arcgis"),
                  ])
                  .optional(),
                description: z.string().nullable().optional(),
                author: z.string(),
                nrPackageUsages: z.number().optional(),
                created_at: z.number(),
                updated_at: z.number().optional(),
                /** This shouldn't be here, but the frontend needs it right now. */
                outputValue: z.string().optional(),
              })
              .strict(),
          )
          .optional(),
        /** All available layers. Only returned if ?allLayers is passed in the query. */
        allLayers: z
          .array(
            z.union([
              z
                .object({
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                  subLayerNames: z.array(z.string()).optional(),
                  parentLayerName: z.string().optional(),
                })
                .passthrough(),
              z
                .object({
                  sourceId: z.string().optional(),
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                })
                .passthrough(),
              z
                .object({
                  sourceId: z.string().optional(),
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                  keywords: z.array(z.string()).optional(),
                })
                .passthrough(),
            ]),
          )
          .optional(),
        author: z.string().optional(),
      })
      .strict()
      .and(z.object({}).passthrough());
    export const Source = z
      .object({
        id: z.string(),
        name: z.string(),
        url: z.string(),
        type: z.union([
          z.literal("wfs"),
          z.literal("wms"),
          z.literal("wmts"),
          z.literal("arcgis"),
        ]),
        addedLayers: z.array(z.string()).optional(),
        /** Inlined `Layer` objects, included if ?layers is passed in the query. */
        layers: z
          .array(
            z
              .object({
                id: z.string(),
                name: z.string(),
                /** The URL for the *source*. */
                url: z.string(),
                bbox: z.string(),
                /** The URL used to query for the layer. */
                path: z.string(),
                source: z.string(),
                type: z
                  .union([
                    z.literal("geojson"),
                    z.literal("external"),
                    z.literal("wms"),
                    z.literal("wfs"),
                    z.literal("arcgis"),
                  ])
                  .optional(),
                description: z.string().nullable().optional(),
                author: z.string(),
                nrPackageUsages: z.number().optional(),
                created_at: z.number(),
                updated_at: z.number().optional(),
                /** This shouldn't be here, but the frontend needs it right now. */
                outputValue: z.string().optional(),
              })
              .strict(),
          )
          .optional(),
        /** All available layers. Only returned if ?allLayers is passed in the query. */
        allLayers: z
          .array(
            z.union([
              z
                .object({
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                  subLayerNames: z.array(z.string()).optional(),
                  parentLayerName: z.string().optional(),
                })
                .passthrough(),
              z
                .object({
                  sourceId: z.string().optional(),
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                })
                .passthrough(),
              z
                .object({
                  sourceId: z.string().optional(),
                  name: z.string().optional(),
                  title: z.string().optional(),
                  abstract: z.string().optional(),
                  keywords: z.array(z.string()).optional(),
                })
                .passthrough(),
            ]),
          )
          .optional(),
        author: z.string(),
      })
      .strict()
      .and(z.object({}).passthrough());
    export const SourceLayerList = z.object({}).and(z.record(z.array(Layer)));
  }
}
export const components = {
  schemas: {
    Error: Components.Schemas.Error,
    SignedUrl: Components.Schemas.SignedUrl,
    SourceType: Components.Schemas.SourceType,
    WMSLayer: Components.Schemas.WMSLayer,
    ArcGISLayer: Components.Schemas.ArcGISLayer,
    WFSLayer: Components.Schemas.WFSLayer,
    LLayer: Components.Schemas.LLayer,
    Layer: Components.Schemas.Layer,
    DataPackagePartial: Components.Schemas.DataPackagePartial,
    DataPackage: Components.Schemas.DataPackage,
    DataPackagePost: Components.Schemas.DataPackagePost,
    License: Components.Schemas.License,
    SourcePartial: Components.Schemas.SourcePartial,
    SourcePost: Components.Schemas.SourcePost,
    Source: Components.Schemas.Source,
    /** Map source id to list of Layer objects */
    SourceLayerList: Components.Schemas.SourceLayerList,
  },
};

export const paths = {
  "/api/datalayerlibrary/signedurl/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Get a signed url for file upload */
          "application/json": Components.Schemas.SignedUrl,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/packages": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Packages in the organisation. */
          "application/json": z.array(Components.Schemas.DataPackage),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Package data */
      requestBody: {
        "application/json": Components.Schemas.DataPackagePost,
      },
      responses: {
        201: {
          /** Newly created package */
          "application/json": Components.Schemas.DataPackage,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/packages/{packageId}": {
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        packageId: z.string(),
      }),
      responses: {
        204: z.never(),
        404: z.never(),
      },
    },
    patch: {
      pathParams: z.object({
        organisationId: z.string(),
        packageId: z.string(),
      }),
      /** Package data */
      requestBody: {
        "application/json": Components.Schemas.DataPackagePost,
      },
      responses: {
        200: {
          /** Ok */
          "application/json": Components.Schemas.DataPackage,
        },
        404: z.never(),
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/sources": {
    get: {
      searchParams: z.object({
        /** Inline the added layers in the response */
        layers: z.string().optional(),
        /** Return a list of all layers that the source provides */
        allLayers: z.string().optional(),
      }),
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Sources in the organisation. */
          "application/json": z.array(Components.Schemas.Source),
        },
        400: {
          /** Bad request */
          "application/json": Components.Schemas.Error,
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Source data */
      requestBody: {
        "application/json": Components.Schemas.SourcePost,
      },
      responses: {
        201: {
          /** Newly added source */
          "application/json": Components.Schemas.Source,
        },
        400: {
          /** Bad request */
          "application/json": Components.Schemas.Error,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.Error,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/sources/{sourceId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        sourceId: z.string(),
      }),
      /** List of layer names to be added to the source */
      requestBody: {
        "application/json": Components.Schemas.SourcePartial,
      },
      responses: {
        200: {
          /** Updated list of layer names that are added */
          "application/json": Components.Schemas.Source,
        },
        400: {
          /** Bad request */
          "application/json": Components.Schemas.Error,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.Error,
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        sourceId: z.string(),
      }),
      responses: {
        204: z.never(),
        404: z.never(),
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/source-layers": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Updated list of layer names that are added */
          "application/json": Components.Schemas.SourceLayerList,
        },
        400: {
          /** Bad request */
          "application/json": Components.Schemas.Error,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.Error,
        },
      },
    },
  },
  "/api/datalayerlibrary/{organisationId}/package-layers/{nodeId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Layers */
          "application/json": z.array(Components.Schemas.Layer),
        },
        400: {
          /** Layers */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
