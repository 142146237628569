import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, atomFamily, atomFromFn, useJotaiCallback } from "utils/jotai";
import {
  GroupOrgResource,
  OrgResource,
  OrganisationResources,
} from "../../types";
import {
  addLibraryResourceManager,
  getGroupOrganisationResources,
  removeGroupLibraryResourceManager,
} from "../../service";
import { userOrganisationResourcesAtomFamily } from "./useUserOrganisationResourcesCrud";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import {
  groupMembersState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import {
  cableManagersSelector,
  exportcableManagersSelector,
  foundationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import { isGroupOrgResource } from "utils/predicates";
import { loggedInUserIdAtom } from "state/user";
import { useUserLibraryAccessState } from "components/Projects/useUserLibraryAccessState";

export const groupOrgResourceManagersAtomFamily = atomFamily(
  ({ organisationId, groupId }: { organisationId: string; groupId: string }) =>
    atomFromFn<Promise<OrgResource[]>>(
      async () => await getGroupOrganisationResources(organisationId, groupId),
    ),
);

const useGroupOrganisationResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const { refresh: refreshUserLibraryAccess } = useUserLibraryAccessState({
    organisationId,
  });
  const currentUserId = useAtomValue(loggedInUserIdAtom);

  const add = useJotaiCallback(
    async (get, set, groupId: string, resourcesName: OrganisationResources) => {
      const group = groups.find((g) => g.id === groupId);
      if (!group) return;
      const prevState = await get(
        groupOrgResourceManagersAtomFamily({
          organisationId,
          groupId,
        }),
      );
      aset(
        get,
        set,
        groupOrgResourceManagersAtomFamily({
          organisationId,
          groupId,
        }),
        (curr) =>
          curr.some(
            (g) =>
              isGroupOrgResource(g) &&
              g.resource_name === resourcesName &&
              g.group_id === groupId,
          )
            ? curr
            : [
                ...curr,
                {
                  group_id: groupId,
                  group_name: group.name,
                  resource_name: resourcesName,
                  type: "group",
                } as GroupOrgResource,
              ],
      );
      try {
        const res = await addLibraryResourceManager(
          organisationId,
          groupId,
          resourcesName,
          "group",
        );
        set(
          turbineManagersSelector({
            organisationId,
          }),
        );
        set(
          foundationManagersSelector({
            organisationId,
          }),
        );
        set(
          cableManagersSelector({
            organisationId,
          }),
        );
        set(
          exportcableManagersSelector({
            organisationId,
          }),
        );
        const members = await get(
          groupMembersState({
            organisationId,
            groupId,
          }),
        );
        members.forEach((m) => {
          aset(
            get,
            set,
            userOrganisationResourcesAtomFamily({
              organisationId,
              userId: m.user_id,
            }),
            (curr) =>
              curr.some(
                (g) =>
                  isGroupOrgResource(g) &&
                  g.resource_name === resourcesName &&
                  g.group_id === groupId,
              )
                ? curr
                : [
                    ...curr,
                    {
                      group_id: groupId,
                      group_name: group.name,
                      resource_name: resourcesName,
                      type: "group",
                    } as GroupOrgResource,
                  ],
          );

          if (currentUserId === m.user_id) {
            refreshUserLibraryAccess(organisationId);
          }
        });

        return res;
      } catch (e) {
        scream("Failed to set manager", {
          e,
        });
        aset(
          get,
          set,
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
          () => prevState,
        );
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Error while trying to update",
            timeout: 5000,
            type: "error",
          },
        ]);
      }
    },
    [
      currentUserId,
      groups,
      organisationId,
      refreshUserLibraryAccess,
      setToastMessages,
    ],
  );
  const remove = useJotaiCallback(
    async (get, set, groupId: string, resourcesName: OrganisationResources) => {
      const prevState = await get(
        groupOrgResourceManagersAtomFamily({
          organisationId,
          groupId,
        }),
      );
      aset(
        get,
        set,
        groupOrgResourceManagersAtomFamily({
          organisationId,
          groupId,
        }),
        (curr) =>
          curr.filter(
            (g) =>
              isGroupOrgResource(g) &&
              (g.resource_name !== resourcesName || g.group_id !== groupId),
          ),
      );
      try {
        const res = await removeGroupLibraryResourceManager(
          organisationId,
          groupId,
          resourcesName,
        );
        set(
          turbineManagersSelector({
            organisationId,
          }),
        );
        set(
          foundationManagersSelector({
            organisationId,
          }),
        );
        set(
          cableManagersSelector({
            organisationId,
          }),
        );
        set(
          exportcableManagersSelector({
            organisationId,
          }),
        );
        const members = await get(
          groupMembersState({
            organisationId,
            groupId,
          }),
        );
        members.forEach((m) => {
          aset(
            get,
            set,
            userOrganisationResourcesAtomFamily({
              organisationId,
              userId: m.user_id,
            }),
            (curr) =>
              curr.filter((g) => {
                if (g.type === "personal") return true;
                return !(
                  isGroupOrgResource(g) &&
                  g.resource_name === resourcesName &&
                  g.group_id === groupId
                );
              }),
          );
          if (currentUserId === m.user_id) {
            refreshUserLibraryAccess(organisationId);
          }
        });

        return res;
      } catch (e) {
        scream("Failed to remove manager", {
          e,
        });
        aset(
          get,
          set,
          groupOrgResourceManagersAtomFamily({
            organisationId,
            groupId,
          }),
          () => prevState,
        );
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Error while trying to update",
            timeout: 5000,
            type: "error",
          },
        ]);
      }
    },
    [currentUserId, organisationId, refreshUserLibraryAccess, setToastMessages],
  );

  return {
    add,
    remove,
  };
};

export default useGroupOrganisationResourcesCrud;
