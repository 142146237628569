import { IconBtn } from "components/General/Icons";
import { Grid2, Label } from "../General/Form";
import { InputTitle } from "components/General/GeneralSideModals.style";
import OpenRight from "@icons/24/OpenWindowRight.svg";
import { useRef } from "react";
import { colors } from "styles/colors";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useClickOutside } from "hooks/useClickOutside";
import { InputDimensioned } from "components/General/Input";
import { between } from "utils/validations";
import { CostUnit } from "types/financial";
import { OptimizeCostInput } from "services/configurationService";
import { MILLION, THOUSAND } from "@constants/financialAnalysis";

export const LCOESettings = ({
  openSubmenu,
  setOpenSubmenu,
}: {
  openSubmenu: boolean;
  setOpenSubmenu: (v: boolean) => void;
}) => {
  const LCOEMenuRef = useRef<HTMLButtonElement>(null);

  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
        alignItems: "center",
      }}
    >
      <InputTitle>Cost input</InputTitle>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <IconBtn
          active={openSubmenu}
          ref={LCOEMenuRef}
          backgroundColor={colors.surfaceButtonSecondary}
          size="1.4rem"
          id="opt-submenu-open-cost"
          style={{ width: "100%", padding: "0.4rem 1rem" }}
          onClick={() => {
            setOpenSubmenu(!openSubmenu);
          }}
        >
          <OpenRight />
          <p style={{ fontSize: "1.4rem" }}>Change input</p>
        </IconBtn>
      </div>
    </Grid2>
  );
};

export const LCOESettingsMenu = ({
  setOpenSubmenu,
  isOpen,
  costInput,
  set,
}: {
  setOpenSubmenu: (v: boolean) => void;
  isOpen: boolean;
  costInput: OptimizeCostInput;
  set?: (v: OptimizeCostInput) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(
    ref,
    () => {
      setOpenSubmenu(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "opt-submenu-open-cost";
    },
  );

  return (
    <MenuFrame
      id={"optimize-submenu-cost"}
      title="LCoE input"
      onExit={() => setOpenSubmenu(false)}
      style={{
        display: isOpen ? "block" : "none",
        padding: "1.6rem 0rem 2rem 0rem",
        position: "absolute",
        left: "calc(100% + 1rem)",
        maxHeight: "40rem",
        overflowY: "auto",
        bottom: 0,
        backgroundColor: colors.background,
      }}
    >
      <LCOESettingsMenuInner costInput={costInput} set={set} disabled={false} />
    </MenuFrame>
  );
};

export const LCOESettingsMenuPreview = ({
  setOpenSubmenu,
  costInput,
}: {
  setOpenSubmenu: (v: boolean) => void;
  costInput: OptimizeCostInput;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(
    ref,
    () => {
      setOpenSubmenu(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "opt-submenu-open-cost";
    },
  );

  return (
    <MenuFrame
      title="LCoE input"
      onExit={() => setOpenSubmenu(false)}
      id="opt-submenu-cost"
      ref={ref}
      style={{ paddingBottom: "3rem" }}
    >
      <LCOESettingsMenuInner costInput={costInput} disabled={true} />
    </MenuFrame>
  );
};

export const LCOESettingsMenuInner = ({
  costInput,
  set,
  disabled,
}: {
  costInput: OptimizeCostInput;
  set?: (v: OptimizeCostInput) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2>
      <Label>
        <InputTitle>Turbine cost</InputTitle>
        <InputDimensioned
          style={{ width: "90%" }}
          scaleFactor={1 / MILLION}
          compact
          decimals={2}
          value={costInput?.turbineCostPerMW}
          initialValue={costInput?.turbineCostPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              turbineCostPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>DEVEX</InputTitle>
        <InputDimensioned
          compact
          scaleFactor={1 / MILLION}
          decimals={2}
          style={{ width: "90%" }}
          value={costInput?.devexPerMW}
          initialValue={costInput?.devexPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              devexPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Foundation cost</InputTitle>
        <InputDimensioned
          compact
          style={{ width: "90%" }}
          decimals={1}
          value={costInput?.foundationCostPerTon}
          initialValue={costInput?.foundationCostPerTon}
          validate={between(1000, 100000)}
          validationMessage={`Must be between 1000 and 100000`}
          unit={CostUnit.euroPerT}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              foundationCostPerTon: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>DECOM</InputTitle>
        <InputDimensioned
          compact
          style={{ width: "90%" }}
          decimals={2}
          scaleFactor={1 / MILLION}
          value={costInput?.decomPerMW}
          initialValue={costInput?.decomPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              decomPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Array cable cost</InputTitle>
        <InputDimensioned
          compact
          style={{ width: "90%" }}
          decimals={2}
          scaleFactor={1 / MILLION}
          value={costInput?.cableCostPerMW}
          initialValue={costInput?.cableCostPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              cableCostPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>OPEX</InputTitle>
        <InputDimensioned
          compact
          style={{ width: "90%" }}
          decimals={2}
          scaleFactor={1 / THOUSAND}
          value={costInput?.opexPerYearPerMW}
          initialValue={costInput?.opexPerYearPerMW}
          validate={between(1, 100)}
          validationMessage={`Must be between 1 and 100`}
          unit={CostUnit.thousandEuroPerMWPerYear}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              opexPerYearPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Substation cost</InputTitle>
        <InputDimensioned
          compact
          decimals={2}
          scaleFactor={1 / MILLION}
          style={{ width: "90%" }}
          value={costInput?.substationCostPerMW}
          initialValue={costInput?.substationCostPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              substationCostPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Lifetime</InputTitle>
        <InputDimensioned
          compact
          decimals={0}
          style={{ width: "90%" }}
          value={costInput?.lifetime}
          initialValue={costInput?.lifetime}
          validate={between(1, 50)}
          validationMessage={`Must be between 1 and 50`}
          unit={CostUnit.years}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              lifetime: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Export cable cost</InputTitle>
        <InputDimensioned
          compact
          decimals={2}
          scaleFactor={1 / MILLION}
          style={{ width: "90%" }}
          value={costInput?.exportCableCostPerMW}
          initialValue={costInput?.exportCableCostPerMW}
          validate={between(0.1, 10)}
          validationMessage={`Must be between 0.1 and 10`}
          unit={CostUnit.millionEuroPerMW}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              exportCableCostPerMW: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
      <Label>
        <InputTitle>Discount rate</InputTitle>
        <InputDimensioned
          compact
          decimals={1}
          scaleFactor={100}
          style={{ width: "90%" }}
          value={costInput?.discountRate}
          initialValue={costInput?.discountRate}
          validate={between(0, 20)}
          validationMessage={`Must be between 0 and 20`}
          unit={CostUnit.percent}
          onChange={(e) => {
            if (!set) throw new Error("set is required when disabled is false");
            set({
              ...costInput,
              discountRate: e,
            });
          }}
          disabled={disabled}
        />
      </Label>
    </Grid2>
  );
};
