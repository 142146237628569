import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback } from "react";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import {
  MooringLineType,
  createMooringLineType,
  updateMooringLineType,
  deleteMooringLineType,
} from "../services/mooringLineTypeService";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectMooringLineTypesFamily } from "state/jotai/mooringLineType";

const useMooringLineTypeCrud = () => {
  const projectId = useAtomValue(projectIdAtom);
  const setIsAutoSaving = useSetAtom(savingConfigurationInProgressAtom);

  const updateLocal = useJotaiCallback(
    async (get, set, mooringLine: MooringLineType) => {
      if (!projectId) throw new Error("projectId is undefined");
      return aset(
        get,
        set,
        projectMooringLineTypesFamily(projectId),
        (curr) => {
          const next = new Map(curr);
          next.set(mooringLine.id, mooringLine);
          return next;
        },
      );
    },
    [projectId],
  );

  const deleteLocal = useJotaiCallback(
    async (get, set, id: string) => {
      if (!projectId) throw new Error("projectId is undefined");
      return aset(
        get,
        set,
        projectMooringLineTypesFamily(projectId),
        (curr) => {
          const next = new Map(curr);
          next.delete(id);
          return next;
        },
      );
    },
    [projectId],
  );

  const create = useCallback(
    async (mooringLineType: Partial<MooringLineType>) => {
      if (!projectId) return;

      setIsAutoSaving(true);

      return createMooringLineType(projectId, mooringLineType)
        .then((ml) => {
          updateLocal(ml);
          return ml;
        })
        .finally(() => {
          setIsAutoSaving(false);
        });
    },
    [projectId, setIsAutoSaving, updateLocal],
  );

  const update = useCallback(
    async (mooringLineType: MooringLineType) => {
      if (!projectId) return;

      setIsAutoSaving(true);
      updateLocal(mooringLineType);
      return updateMooringLineType(projectId, mooringLineType).finally(() => {
        setIsAutoSaving(false);
      });
    },
    [projectId, updateLocal, setIsAutoSaving],
  );
  const deleteMooringLine = useCallback(
    async (mooringLineId: string) => {
      if (!projectId) return;

      setIsAutoSaving(true);
      deleteLocal(mooringLineId);
      return deleteMooringLineType(projectId, mooringLineId).finally(() => {
        setIsAutoSaving(false);
      });
    },
    [projectId, deleteLocal, setIsAutoSaving],
  );

  return {
    create,
    update,
    deleteMooringLine,
    updateLocal,
    deleteLocal,
  };
};

export default useMooringLineTypeCrud;
