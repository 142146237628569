import DeleteIcon from "@icons/24/Bin.svg?react";
import SubstationIcon from "@icons/24/Substation.svg?react";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_SUBSTATION, SubstationType } from "services/substationService";
import useProjectSubstationTypeCrud from "../../../../../hooks/useProjectSubstationTypeCrud";
import {
  organisationIdAtom,
  projectIdAtom,
} from "../../../../../state/pathParams";
import { inReadOnlyModeSelector } from "../../../../../state/project";
import { selectedMenuItemState } from "../../../Shared/state";
import {
  SettingsItem,
  SettingsSubMenuProp,
} from "components/SettingsV2/Shared/types";
import { useAtom, useAtomValue } from "jotai";
import {
  librarySubstationsFamily,
  substationTypesFamily,
} from "state/jotai/substationType";
import AddIcon from "@icons/24/Add.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { IconREMSize } from "styles/typography";
import Button from "components/General/Button";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useNavigate } from "react-router-dom";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import { Mixpanel } from "mixpanel";
import { orgSubstationManageAccessSelector } from "state/user";
import { editorAccessProjectSelector } from "state/user";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { fromProjectToLibraryTabState } from "components/Organisation/Library/state";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useSetAtom } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { useDuplicateSubstationToLibrary } from "../useDuplicateSubstationToLibrary";
import { ProjectSubstation } from "components/ConfigurationModal/SubstationSettings/variants/ProjectSubstation";
import { SubstationSettings } from "components/ConfigurationModal/SubstationSettings/SubstationSettings";
import { AddSubstationResourceToNode } from "./components/AddSubstationResourceToNode";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";

export const SUBSTATION_MENU_ID = "substation";

export default function useSubstationSettings() {
  const navigate = useNavigate();
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const {
    create,
    update: _onUpdate,
    deleteSubstation: _onDelete,
    duplicate,
    isLoading,
  } = useProjectSubstationTypeCrud();
  const { duplicateToLibrary } = useDuplicateSubstationToLibrary();

  const projectSubstationTypesWithDefaults = useAtomValue(
    substationTypesFamily({
      projectId,
    }),
  );

  const projectSubstationTypes = useMemo(
    () =>
      new Map(
        Array.from(projectSubstationTypesWithDefaults.entries()).filter(
          ([id]) => !id.startsWith("default"),
        ),
      ),
    [projectSubstationTypesWithDefaults],
  );

  const defaultSubstationTypes: SubstationType[] = useMemo(
    () =>
      Array.from(projectSubstationTypesWithDefaults.values()).filter(
        (substation) => substation.id.startsWith("default"),
      ),
    [projectSubstationTypesWithDefaults],
  );

  const librarySubstations = useAtomValue(librarySubstationsFamily(projectId));
  const orgSubstationManageAccess = useAtomValue(
    orgSubstationManageAccessSelector,
  );
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );

  const onshore = useAtomValue(isOnshoreAtom);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: SUBSTATION_MENU_ID,
      projectId,
    }),
  );

  useEffect(() => {
    const sub = Array.from(projectSubstationTypes.values())[0];
    if (!menuSelection && projectSubstationTypes.size > 0)
      setMenuSelection(sub.id);
  }, [menuSelection, setMenuSelection, projectSubstationTypes]);

  const [isProjectSubstationCollapsed, setIsProjectSubstationCollapsed] =
    useState<boolean>(false);
  const [isLibrarySubstationCollapsed, setIsLibrarySubstationCollapsed] =
    useState<boolean>(false);
  const [isDefaultSubstationCollapsed, setIsDefaultSubstationCollapsed] =
    useState<boolean>(false);

  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const addSubstation = useCallback(
    async (substation: Partial<SubstationType>) => {
      setSaveInProgress(true);
      try {
        const result = await create(substation);
        if (result) {
          setMenuSelection(result.id);
        }
      } finally {
        setSaveInProgress(false);
      }
    },
    [create, setMenuSelection],
  );

  const _onDuplicateFromProjectToProject = useCallback(
    async (substationId: string) => {
      const substationType =
        projectSubstationTypesWithDefaults.get(substationId);

      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "substation",
          defaultName: `${substationType?.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            if (!substationType)
              throw new Error("Tried to duplicate non existing substation");

            setSaveInProgress(true);
            try {
              const result = await duplicate(substationType, name);
              if (result) {
                setMenuSelection(result.id);
              }
            } finally {
              setSaveInProgress(false);
            }
          },
        },
      });
    },
    [
      duplicate,
      setMenuSelection,
      setMidScreenModalTypeOpen,
      projectSubstationTypesWithDefaults,
    ],
  );

  const _onDuplicateFromLibraryToProject = useCallback(
    async (substationId: string) => {
      const substationType = librarySubstations.get(substationId);
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "substation",
          defaultName: `${substationType?.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            if (!substationType)
              throw new Error("Tried to duplicate non existing substation");

            setSaveInProgress(true);
            try {
              const result = await duplicate(substationType, name);
              if (result) {
                setMenuSelection(result.id);
              }
            } finally {
              setSaveInProgress(false);
            }
          },
        },
      });
    },
    [
      duplicate,
      librarySubstations,
      setMenuSelection,
      setMidScreenModalTypeOpen,
    ],
  );

  const substationSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectSubstationList = Array.from(projectSubstationTypes.values())
      .filter((substation) => !onshore || substation.type === "onshore")
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((substation) => {
        let dotMenuOptions: {
          title: string;
          onSelect: (id: string) => void;
          icon: ReactElement;
        }[] = [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
          {
            title: "Duplicate",
            onSelect: () => _onDuplicateFromProjectToProject(substation.id),
            icon: <DuplicateIcon />,
          },
        ];

        if (orgSubstationManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => {
              setSaveInProgressLibrary(true);
              duplicateToLibrary(projectId, substation).then(() => {
                setSaveInProgressLibrary(false);
              });
            },
            icon: <DuplicateIcon />,
          });
        }

        return {
          id: substation.id,
          name: substation.name,
          label: substation.name,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <ProjectSubstation
                disabled={isReadOnly}
                key={substation.id}
                substation={substation}
                onSave={_onUpdate}
                isLoading={isLoading}
                nodeId={projectId}
              />
            </div>
          ),
          dotMenuOptions,
        };
      });

    const librarySubstationList = Array.from(librarySubstations?.values() ?? [])
      .filter((substation) => !onshore || substation.type === "onshore")
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((substation) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgSubstationManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library component",
            onSelect: (_id: string) => {
              let searchParams = new URLSearchParams(location.search);
              Mixpanel.track_old("Click edit in library", {
                resource: "substation",
              });
              let newSearchString = searchParams.toString();
              setContent({
                type: "resource",
                id: substation.id,
              });
              setFromProjectToLibraryTab(true);
              navigate(
                `/organisation/${organisationId}/library/substation/${substation.id}?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
        }

        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: (_id: string) => {
              _onDuplicateFromLibraryToProject(substation.id);
            },
            icon: <DuplicateIcon />,
          });
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: substation.id,
          name: substation.name,
          label: substation.name,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <SubstationSettings
                disabled={true}
                key={substation.id}
                substation={substation}
                onSave={_onUpdate}
                isLoading={isLoading}
                nodeId={organisationId}
                isLibraryContext={false}
                isLibrarySubstationInProjectView={true}
                orgSubstationManageAccess={orgSubstationManageAccess}
                organisationId={organisationId}
                duplicateToProject={_onDuplicateFromLibraryToProject}
              />
            </div>
          ),
          dotMenuOptions,
        };
      });

    const defaultSubstationList = defaultSubstationTypes.map((substation) => {
      let dotMenuOptions: {
        title: string;
        onSelect: (id: string) => void;
        icon: ReactElement;
      }[] = [];

      if (editorAccessProject) {
        dotMenuOptions.push({
          title: "Create a project specific duplicate",
          onSelect: () => {
            _onDuplicateFromProjectToProject(substation.id);
          },
          icon: <DuplicateIcon />,
        });
      }

      if (orgSubstationManageAccess) {
        dotMenuOptions.push({
          title: "Duplicate to Library",
          onSelect: () => {
            duplicateToLibrary(projectId, substation);
          },
          icon: <DuplicateIcon />,
        });
      }

      return {
        id: substation.id,
        name: substation.name,
        label: substation.name,
        loading: false,
        content: (
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <ProjectSubstation
              disabled={true}
              key={substation.id}
              substation={substation}
              onSave={_onUpdate}
              isLoading={false}
              nodeId={projectId}
            />
          </div>
        ),
        dotMenuOptions: dotMenuOptions.length > 0 ? dotMenuOptions : undefined,
      };
    });

    const defaultSubstationsMenu: SettingsSubMenuProp = {
      title: "Vind AI components",
      items: defaultSubstationList,
      isCollapsed: isDefaultSubstationCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isDefaultSubstationCollapsed}
            onClick={() =>
              setIsDefaultSubstationCollapsed(!isDefaultSubstationCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const librarySubstationsMenu: SettingsSubMenuProp = {
      title: "Library components",
      items: librarySubstationList,
      isCollapsed: isLibrarySubstationCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibrarySubstationCollapsed}
            onClick={() =>
              setIsLibrarySubstationCollapsed(!isLibrarySubstationCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectSubstationsMenu: SettingsSubMenuProp = {
      items: projectSubstationList,
      isCollapsed: isProjectSubstationCollapsed,
      title: "Project specific components",
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectSubstationCollapsed}
            onClick={() =>
              setIsProjectSubstationCollapsed(!isProjectSubstationCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    if (allLibraryAccess)
      return [
        librarySubstationsMenu,
        projectSubstationsMenu,
        defaultSubstationsMenu,
      ];

    return [projectSubstationsMenu, defaultSubstationsMenu];
  }, [
    projectSubstationTypes,
    librarySubstations,
    isLoading,
    isReadOnly,
    _onUpdate,
    projectId,
    _onDuplicateFromProjectToProject,
    _onDelete,
    isProjectSubstationCollapsed,
    isLibrarySubstationCollapsed,
    allLibraryAccess,
    orgSubstationManageAccess,
    editorAccessProject,
    organisationId,
    navigate,
    setContent,
    setFromProjectToLibraryTab,
    _onDuplicateFromLibraryToProject,
    duplicateToLibrary,
    isDefaultSubstationCollapsed,
    defaultSubstationTypes,
    onshore,
  ]);

  const createNewSubstation = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <Button
          disabled={isLoading}
          onClick={() =>
            addSubstation({
              ...DEFAULT_SUBSTATION,
              name: `Substation ${projectSubstationTypes.size + 1}`,
              type: onshore ? "onshore" : "offshore",
            })
          }
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [
    saveInProgress,
    isLoading,
    projectSubstationTypes,
    addSubstation,
    onshore,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgressLibrary,
      element: <AddSubstationResourceToNode nodeId={projectId} />,
    };
  }, [saveInProgressLibrary, projectId]);

  const topLevelMenu: SettingsItem = useMemo(() => {
    return {
      id: SUBSTATION_MENU_ID,
      label: "Substations",
      icon: <SubstationIcon />,
      submenus: substationSubMenus,
      createNew: createNewSubstation,
      addFromLibrary: addFromLibrary,
    };
  }, [substationSubMenus, createNewSubstation, addFromLibrary]);

  return topLevelMenu;
}
