import { ReactNode, useRef } from "react";
import styled from "styled-components";
import PositionHints from "./PositionHints";
import { activePositionHintAtom } from "../../../state/hint";
import { Anchor, Place, oppositePlace } from "../../General/Anchor";
import { useAtomValue } from "jotai";

const PositionHintContainer = styled.div`
  z-index: 10;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const PositionHintInner = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const PositionHintWrapper = styled.div`
  position: relative;
`;

export const PositionHintRef = ({
  allowedHints,
  position,
  around,
  offset,
}: {
  allowedHints: string[];
  position: Place;
  around: React.RefObject<HTMLElement>;
  offset?: Parameters<typeof Anchor>[0]["offset"];
}) => {
  const activeHint = useAtomValue(activePositionHintAtom);
  if (!activeHint || !allowedHints.includes(activeHint)) return null;
  return (
    <Anchor
      basePlace={position}
      floatPlace={oppositePlace(position)}
      baseRef={around}
      offset={offset}
    >
      <PositionHintContainer>
        <PositionHints position={position} />
      </PositionHintContainer>
    </Anchor>
  );
};

export default function PositionHint({
  children,
  allowedHints,
  position,
}: {
  children: ReactNode;
  allowedHints: string[];
  position: Place;
}) {
  const activeHint = useAtomValue(activePositionHintAtom);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <PositionHintWrapper ref={ref}>
      <PositionHintInner>{children}</PositionHintInner>
      {activeHint && allowedHints.includes(activeHint) && (
        <Anchor
          basePlace={position}
          floatPlace={oppositePlace(position)}
          baseRef={ref}
        >
          <PositionHintContainer>
            <PositionHints position={position} />
          </PositionHintContainer>
        </Anchor>
      )}
    </PositionHintWrapper>
  );
}
