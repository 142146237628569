/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const BranchMeta = z
      .object({
        id: z.string(),
        title: z.string(),
        createdAt: z.number().int(),
        isArchived: z.boolean(),
        author: z.string().optional(),
        analysisConfigurationId: z.string().optional(),
        windConfigurationId: z.string().optional(),
        costConfigurationId: z.string().optional(),
        operationsConfigurationId: z.string().optional(),
        sortOrder: z.number().int().optional(),
        description: z.string().optional(),
      })
      .strict();
    export const SnapshotMeta = z
      .object({
        id: z.string(),
        title: z.string(),
        body: z.string().optional(),
        branchId: z.string(),
        updatedAt: z.number(),
      })
      .strict();
    export const ProjectFeature = z
      .object({
        id: z.string(),
        type: z.string(),
        geometry: z.object({}).passthrough().nullable(),
        properties: z.object({}).passthrough(),
      })
      .passthrough();
    export const ErrorResponse = z
      .object({
        message: z.string(),
      })
      .strict();
    export const ResourceTypeUsage = z
      .object({
        projectId: z.string(),
        branchId: z.string(),
        featureId: z.string().optional(),
        resourceId: z.string(),
      })
      .passthrough();
    export const ResourceType = z.union([
      z.literal("TURBINE"),
      z.literal("FOUNDATION"),
      z.literal("CABLE"),
      z.literal("COST_CONFIGURATION"),
      z.literal("ANALYSIS_CONFIGURATION"),
      z.literal("SUBSTATION"),
      z.literal("WIND_CONFIGURATION"),
      z.literal("OPERATIONS_CONFIGURATION"),
    ]);
    export const ParkInfo = z
      .object({
        parkId: z.string(),
        parkName: z.string(),
      })
      .passthrough();
    export const BranchWithParks = z
      .object({
        branchId: z.string(),
        branchName: z.string(),
        parks: z.array(ParkInfo),
      })
      .passthrough();
    export const BranchesAndParksResponse = z
      .object({
        branches: z.array(BranchWithParks),
      })
      .passthrough();
    export const FeatureMeta = z
      .object({
        tags: z.array(z.string()).optional(),
        stages: z.array(z.string()).optional(),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    BranchMeta: Components.Schemas.BranchMeta,
    SnapshotMeta: Components.Schemas.SnapshotMeta,
    ProjectFeature: Components.Schemas.ProjectFeature,
    ErrorResponse: Components.Schemas.ErrorResponse,
    ResourceTypeUsage: Components.Schemas.ResourceTypeUsage,
    ResourceType: Components.Schemas.ResourceType,
    ParkInfo: Components.Schemas.ParkInfo,
    BranchWithParks: Components.Schemas.BranchWithParks,
    BranchesAndParksResponse: Components.Schemas.BranchesAndParksResponse,
    FeatureMeta: Components.Schemas.FeatureMeta,
  },
};

export const paths = {
  "/invoke/project-service-prod-listBranchesInvoke": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** List of BranchMeta objects */
          "application/json": z.array(Components.Schemas.BranchMeta),
        },
      },
    },
  },
  "/invoke/project-service-prod-branch_create_invoke_node2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            title: z.string(),
            author: z.string(),
            createdAt: z.number().int(),
            description: z.string().optional(),
            operationsConfigurationId: z.string().optional(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Created branch */
          "application/json": z
            .object({
              meta: Components.Schemas.BranchMeta.optional(),
              features: z.array(z.object({}).passthrough()).optional(),
              snapshots: z
                .array(
                  z
                    .object({
                      meta: Components.Schemas.SnapshotMeta.optional(),
                      body: z.string().optional(),
                      features: z.array(z.object({}).passthrough()).optional(),
                    })
                    .strict(),
                )
                .optional(),
            })
            .strict(),
        },
      },
    },
  },
  "/invoke/project-service-prod-branch_get_node_invoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            branchId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Object with meta and features */
          "application/json": z
            .object({
              meta: Components.Schemas.BranchMeta,
              features: z.array(Components.Schemas.ProjectFeature),
            })
            .strict(),
        },
        404: {
          /** Branch not found */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/project-service-prod-getParkForPortfolioInvoke": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            branchId: z.string(),
            parkId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Object with park feature and turbine features */
          "application/json": z
            .object({
              park: Components.Schemas.ProjectFeature,
              turbines: z.array(Components.Schemas.ProjectFeature),
            })
            .strict(),
        },
        404: {
          /** Park not found */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/project-service-prod-getParkBranchForPortfolioInvoke": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            branchId: z.string(),
            parkId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Object with park feature and turbine features */
          "application/json": z
            .object({
              park: Components.Schemas.ProjectFeature,
              branchMeta: Components.Schemas.BranchMeta,
              turbines: z.array(Components.Schemas.ProjectFeature),
            })
            .strict(),
        },
        404: {
          /** Park not found */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/project-service-prod-invokeResourceTypeUsage": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            resourceType: Components.Schemas.ResourceType,
            resourceId: z.string(),
            projectIds: z.array(z.string()),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Successful response */
          "application/json": z.array(Components.Schemas.ResourceTypeUsage),
        },
      },
    },
  },
  "/api/project-service/stats/organisation/{organisationId}/libraryManageRole/{libraryManageRole}/resource/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          libraryManageRole: z.string(),
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** Resource usage information */
            "application/json": z.array(Components.Schemas.ResourceTypeUsage),
          },
        },
      },
    },
  "/api/project-service/stats/node/{nodeId}/resource/{resourceType}/{resourceId}":
    {
      get: {
        pathParams: z.object({
          nodeId: z.string(),
          resourceType: Components.Schemas.ResourceType,
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** Resource usage information */
            "application/json": z.array(Components.Schemas.ResourceTypeUsage),
          },
        },
      },
    },
  "/api/project-service/branches-parks-meta/organisation/{organisationId}/project/{projectId}":
    {
      get: {
        pathParams: z.object({
          projectId: z.string(),
          organisationId: z.string(),
        }),
        responses: {
          200: {
            /** List of branches with their parks */
            "application/json": Components.Schemas.BranchesAndParksResponse,
          },
          400: {
            /** Missing parameters */
            "application/json": Components.Schemas.ErrorResponse,
          },
          500: {
            /** Internal server error */
            "application/json": Components.Schemas.ErrorResponse,
          },
        },
      },
    },
  "/api/project-service/node/{nodeId}/branch/{branchId}/feature/meta/{featureId}":
    {
      put: {
        pathParams: z.object({
          nodeId: z.string(),
          branchId: z.string(),
          featureId: z.string(),
        }),
        requestBody: {
          "application/json": z
            .object({
              meta: Components.Schemas.FeatureMeta.optional(),
            })
            .passthrough(),
        },
        responses: {
          200: {
            /** Feature meta updated */
            "application/json": Components.Schemas.FeatureMeta,
          },
          400: {
            /** Missing parameters */
            "application/json": Components.Schemas.ErrorResponse,
          },
          500: {
            /** Internal server error */
            "application/json": Components.Schemas.ErrorResponse,
          },
        },
      },
      get: {
        pathParams: z.object({
          nodeId: z.string(),
          branchId: z.string(),
          featureId: z.string(),
        }),
        responses: {
          200: {
            /** Feature metadata */
            "application/json": Components.Schemas.FeatureMeta,
          },
          400: {
            /** Bad request */
            "application/json": z
              .object({
                message: z.string().optional(),
              })
              .passthrough(),
          },
          404: {
            /** Feature meta not found */
            "application/json": z
              .object({
                message: z.string().optional(),
              })
              .passthrough(),
          },
          500: {
            /** Internal server error */
            "application/json": z
              .object({
                message: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
};

// Generated using openapi-to-zod v0.1.47
