import { parkIdAtom, projectIdAtom } from "state/pathParams";
import { modalTypeOpenAtom } from "../../state/modal";
import RenameFeatureModal from "./RenameModalGeneric";
import { useAtomValue } from "jotai";

export const RenameParkModalType = "RenameParkModal";

const RenameParkModal = () => {
  const projectId = useAtomValue(projectIdAtom);
  const parkId = useAtomValue(parkIdAtom);
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== RenameParkModalType || !projectId || !parkId)
    return null;

  return (
    <RenameFeatureModal
      featureId={parkId}
      defaultName={"Untitled park"}
      header={"Rename park"}
    />
  );
};

export default RenameParkModal;
