export class Color {
  /** R in [0, 255] */
  public r: number = 0;
  /** G in [0, 255] */
  public g: number = 0;
  /** B in [0, 255] */
  public b: number = 0;
  /** A in [0, 255] */
  public a: number = 255;

  constructor(r: number, g: number, b: number, a: number = 255) {
    this.r = r;
    if (this.r < 0 || 255 < this.r)
      throw new Error(`Color component should be in [0, 255]; was ${r}`);
    this.g = g;
    if (this.g < 0 || 255 < this.g)
      throw new Error(`Color component should be in [0, 255]; was ${g}`);
    this.b = b;
    if (this.b < 0 || 255 < this.b)
      throw new Error(`Color component should be in [0, 255]; was ${b}`);
    this.a = a;
    if (this.a < 0 || 255 < this.a)
      throw new Error(`Color component should be in [0, 255]; was ${a}`);
  }

  clone(): Color {
    return new Color(this.r, this.g, this.b, this.a);
  }

  /**
   * From hex code. `#` is supported, but not required.
   */
  static fromHex(hex: string) {
    let h = hex[0] === "#" ? hex.slice(1) : hex;
    const r = parseInt(h.slice(0, 2), 16);
    if (isNaN(r)) throw new Error(`Could not parse R component: "${hex}"`);
    const g = parseInt(h.slice(2, 4), 16);
    if (isNaN(g)) throw new Error(`Could not parse G component: "${hex}"`);
    const b = parseInt(h.slice(4, 6), 16);
    if (isNaN(b)) throw new Error(`Could not parse B component: "${hex}"`);
    const a = h.length === 8 ? parseInt(h.slice(6, 8), 16) : 255;
    if (isNaN(a)) throw new Error(`Could not parse A component: "${hex}"`);
    return new Color(r, g, b, a);
  }

  /** To RGB hex string, e.g. `#ff8020`.*/
  toRGB(): string {
    const r = this.r < 16 ? `0${this.r.toString(16)}` : this.r.toString(16);
    const g = this.g < 16 ? `0${this.g.toString(16)}` : this.g.toString(16);
    const b = this.b < 16 ? `0${this.b.toString(16)}` : this.b.toString(16);
    return `#${r}${g}${b}`;
  }

  /** To RGBA hex string.*/
  toRGBA(): string {
    const a = this.a < 16 ? `0${this.a.toString(16)}` : this.a.toString(16);
    return `${this.toRGB()}${a}`;
  }

  toHex(): number {
    return parseInt(this.toRGB().slice(1), 16);
  }

  /** Print the color as a CSS `rgba` expression */
  css(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a / 255})`;
  }

  /** Equality check.*/
  equals(other: Color): boolean {
    return (
      this.r === other.r &&
      this.g === other.g &&
      this.b === other.b &&
      this.a === other.a
    );
  }

  /**
   * Linearly interpolate between `this` and the given color.
   * `f === 0.0` will return `this`, and `1.0` will return `other`.
   *
   * Interpolates all components independently, in RGB space.
   */
  interpolate(f: number, other: Color): Color {
    if (f < 0.0 || 1.0 < f)
      throw new Error(
        `Interpolant is out of bounds: ${f} (should be in [0, 1])`,
      );
    const f1 = 1.0 - f;

    const r = Math.round(f1 * this.r + f * other.r);
    const g = Math.round(f1 * this.g + f * other.g);
    const b = Math.round(f1 * this.b + f * other.b);
    const a = Math.round(f1 * this.a + f * other.a);
    return new Color(r, g, b, a);
  }

  /** Create a transparent color */
  static Transparent(): Color {
    return new Color(0, 0, 0, 0);
  }
}

/**
 * Bucketed coloring.
 *
 * Each bucket is defined as a range of values. Any value is in exactly one
 * bucket. The value ranges are defined as the list of values where two buckets
 * meet.
 *
 * - The *first* bucket goes from `-Infinity` to `values.at(0)`.
 * - Buckets in the middle goes from `values.at(i)` to `values.at(i + 1)`.
 * - The *last* bucket goes from `values.at(-1)` to `Infinity`.
 */
export class Buckets {
  /** Color for each bucket. Length `n`. */
  colors: Color[];
  /** Values where two buckets meet. Length `n+1`. */
  values: number[];

  constructor(color: Color, init?: [Color, number][]) {
    if (init) {
      this.colors = [color].concat(init.map((t) => t[0]));
      this.values = init.map((t) => t[1]);
    } else {
      this.colors = [color];
      this.values = [];
    }
  }

  /**
   * Create a {@link Buckets} from a list of colors and an initial min/max range.
   */
  static fromBalanced(colors: Color[], min: number, max: number): Buckets {
    const b = new Buckets(
      colors[0],
      colors.slice(1).map((c, i) => [c, i]),
    );
    b.balance(min, max);
    return b;
  }

  clone(): Buckets {
    const b = new Buckets(new Color(0, 0, 0));
    b.colors = this.colors.map((c) => c.clone());
    b.values = [...this.values];
    return b;
  }

  /** Checks all invariants we have. Useful for debugging, if something's wrong.*/
  validate() {
    if (this.colors.length !== this.values.length + 1)
      throw new Error("There should be exactly one color more than values");
    for (let i = 0; i < this.values.length - 1; i++) {
      if (this.values[i + 1] < this.values[i]) {
        throw new Error(
          `'values' should be in-order, but ${this.values[i + 1]} < ${this.values[i]}`,
        );
      }
    }
  }

  /** Get the color associated with the given value. */
  get(value: number): Color {
    const i = this.values.findIndex((v) => value < v);
    // Safety: `values.length < colors.length`, so `i` is in range.
    return this.colors.at(i)!;
  }

  /**
   * Insert a new bucket **starting** at `value`.
   *
   * If there is already a bucket ending at this value, the new bucket will be
   * placed at the end of any such bucket.
   *
   * ```ts
   * const buckets = new Buckets();
   * buckets.insert(red, 0);
   * // [-Infinity, 0) is transparent, and [0, Infinity) is red
   * buckets.insert(green, 3);
   * // [-Infinity, 0) is transparent, [0, 3) is red, and [3, Infinity] is green.
   * ````
   */
  insert(color: Color, value: number) {
    const i = this.values.findIndex((v) => value < v);
    if (i === -1) {
      this.colors.push(color);
      this.values.push(value);
    } else {
      // NOTE: i+1 here since the first bucket is the lower-end color, which we never replace.
      this.colors.splice(i + 1, 0, color);
      this.values.splice(i, 0, value);
    }
  }

  /**
   * Move a boundary to another value.  Buckets we move over will be clamped to
   * the new value.
   */
  moveBoundary(index: number, value: number): Buckets {
    if (index < 0 || this.values.length <= index)
      throw new Error(
        `index out of bounds: index=${index} but length=${this.values.length}`,
      );
    for (let k = 0; k < index; k++) {
      if (value < this.values[k]) this.values[k] = value;
    }
    this.values[index] = value;
    for (let k = index + 1; k < this.values.length; k++) {
      if (this.values[k] < value) this.values[k] = value;
    }
    return this;
  }

  replaceColors(colors: Color[]): Buckets {
    if (colors.length !== this.colors.length)
      throw new Error(
        `need exactly the same number of colors: ${colors.length} !== ${this.colors.length}`,
      );
    this.colors = colors;
    return this;
  }

  pop(index: number): Buckets {
    if (index < 0 || this.values.length <= index)
      throw new Error(
        `index out of bounds: index=${index} but length=${this.values.length}`,
      );
    this.values.splice(index, 1);
    this.colors.splice(index + 1, 1);
    return this;
  }

  numBuckets(): number {
    return this.colors.length;
  }

  reverseColors(): Buckets {
    const rev = [];
    for (let i = this.colors.length - 1; 0 <= i; i--) rev.push(this.colors[i]);
    this.colors = rev;
    return this;
  }

  /**
   * Balance the bucket values so that the bucketed range is from `min` to
   * `max` and that the buckets are the same size.
   */
  balance(min: number, max: number): Buckets {
    const n = this.numBuckets() - 1; // -1 to remove the "background" bucket.
    const range = max - min;
    const step = range / n;
    this.values = new Array(n).fill(0).map((_, i) => min + step * i);
    return this;
  }

  /**
   * List the buckets with their color and range. The list is sorted, ascending.
   */
  buckets(): { from: number; to: number; color: Color }[] {
    return this.colors.map((color, i) => {
      const from = this.values[i - 1] ?? -Infinity;
      const to = this.values[i] ?? Infinity;
      return { from, to, color };
    });
  }

  // ------------------------------------------------------------

  static Pink(
    nBuckets: Exclude<keyof (typeof colorBrewer)["PiYG"], "type">,
  ): Buckets {
    const colors = colorBrewer.PiYG[nBuckets];
    return Buckets.fromBalanced(colors, 0, 1);
  }

  static Brewer(
    k: keyof typeof colorBrewer,
    n: 3 | 4 | 5 | 6 | 7 | 8 | 9,
  ): Buckets {
    return Buckets.fromBalanced(colorBrewer[k][n], 0, 1);
  }
}

/**
 * Gradient coloring.
 *
 * A continuous coloring range defined by at least two values with associated
 * colors. The color for a value is interpolated in between the closest colors.
 *
 * Values outside the range take on the color of the closer endpoint.
 */
export class Gradient {
  /** Color for each point. Length `n`. */
  colors: Color[];
  /** Values where the colors are defined. Length `n`. */
  values: number[];

  constructor(pairs: [Color, number][]) {
    if (pairs.length < 2) throw new Error("Need at least two pairs of colors");
    this.colors = pairs.map((p) => p[0]);
    this.values = pairs.map((p) => p[1]);
    this.validate();
  }

  /**
   * Create a {@link Buckets} from a list of colors and an initial min/max range.
   */
  static fromBalanced(colors: Color[], min: number, max: number): Gradient {
    const b = new Gradient(colors.map((c, i) => [c, i]));
    b.balance(min, max);
    return b;
  }

  clone(): Gradient {
    const b = new Gradient([
      [this.colors[0], this.values[0]],
      [this.colors[0], this.values[0]],
    ]);
    b.colors = this.colors.map((c) => c.clone());
    b.values = [...this.values];
    return b;
  }

  /** Checks all invariants we have. Useful for debugging, if something's wrong.*/
  validate() {
    if (this.colors.length !== this.values.length)
      throw new Error("Number of colors and values should be the same");
    for (let i = 0; i < this.values.length - 1; i++) {
      if (this.values[i + 1] < this.values[i]) {
        throw new Error(
          `'values' should be in-order, but ${this.values[i + 1]} < ${this.values[i]}`,
        );
      }
    }
  }

  /** Get the color associated with the given value. */
  get(value: number): Color {
    const i = this.values.findIndex((v) => value < v);
    // Clip to either endpoint if out of range:
    if (i < 1) return this.colors.at(i)!; // Safety: colors is not empty.
    // Interpolate between `i-1` and `i`:
    const lower = this.values[i - 1];
    const higher = this.values[i];
    const f = (value - lower) / (higher - lower);
    return this.colors[i - 1].interpolate(f, this.colors[i]);
  }

  /** Insert a new color at a value.*/
  insert(color: Color, value: number) {
    const i = this.values.findIndex((v) => value < v);
    if (i === -1) {
      this.colors.push(color);
      this.values.push(value);
    } else {
      this.colors.splice(i, 0, color);
      this.values.splice(i, 0, value);
    }
  }

  pop(index: number): Gradient {
    if (index < 0 || this.colors.length <= index)
      throw new Error(
        `index out of bounds: index=${index} but length=${this.colors.length}`,
      );
    this.values.splice(index, 1);
    this.colors.splice(index, 1);
    return this;
  }

  reverseColors(): Gradient {
    const rev = [];
    for (let i = this.numStops() - 1; 0 <= i; i--) rev.push(this.colors[i]);
    this.colors = rev;
    return this;
  }

  /**
   * Set the value of the given index.
   *
   * Values that would be out-of-order due to this change will be clamped to
   * this value.
   */
  setValue(index: number, value: number): Gradient {
    if (index < 0 || this.colors.length <= index)
      throw new Error(
        `index out of bounds: index=${index} but length=${this.colors.length}`,
      );
    for (let k = 0; k < index; k++) {
      if (value < this.values[k]) this.values[k] = value;
    }
    this.values[index] = value;
    for (let k = index + 1; k < this.values.length; k++) {
      if (this.values[k] < value) this.values[k] = value;
    }
    return this;
  }

  setColor(index: number, color: Color): Gradient {
    if (index < 0 || this.colors.length <= index)
      throw new Error(
        `index out of bounds: index=${index} but length=${this.colors.length}`,
      );
    this.colors[index] = color;
    return this;
  }

  replaceColors(colors: Color[]): Gradient {
    if (colors.length !== this.colors.length)
      throw new Error(
        `need exactly the same number of colors: ${colors.length} !== ${this.colors.length}`,
      );
    this.colors = colors;
    return this;
  }

  /**
   * List each individual gradient in the larger gradient.
   */
  ranges(): { from: number; to: number; fromColor: Color; toColor: Color }[] {
    const ret = [];
    for (let i = 0; i < this.colors.length - 1; i++) {
      const from = this.values[i];
      const to = this.values[i + 1];
      const fromColor = this.colors[i];
      const toColor = this.colors[i + 1];
      ret.push({ from, to, fromColor, toColor });
    }
    return ret;
  }

  stops(): { value: number; color: Color }[] {
    const ret = [];
    for (let i = 0; i < this.colors.length; i++)
      ret.push({
        value: this.values[i],
        color: this.colors[i],
      });
    return ret;
  }

  numStops(): number {
    return this.values.length;
  }

  empty(): boolean {
    return this.values.length === 0;
  }

  /** Return the difference from the max and min. */
  span(): number {
    if (this.empty()) return 0;
    return this.values.at(-1)! - this.values.at(0)!;
  }

  /**
   * Balance the bucket values so that the bucketed range is from `min` to
   * `max` and that the buckets are the same size.
   */
  balance(min: number, max: number): Gradient {
    const n = this.values.length;
    const range = max - min;
    const step = range / (n - 1); // n stops means n-1 jumps from min to max.
    this.values = new Array(n).fill(0).map((_, i) => min + step * i);
    return this;
  }

  /** Print the color as a CSS `rgba` expression */
  css(): string {
    const stops = this.stops();
    const min = stops.at(0)?.value ?? 0;
    const max = stops.at(-1)?.value ?? 1;
    const toPercent = (n: number) =>
      `${Math.round((100 * (n - min)) / (max - min))}%`;

    const str = this.stops().map(
      (s) => `${s.color.css()} ${toPercent(s.value)}`,
    );

    return `linear-gradient(90deg, ${str.join(", ")})`;
  }

  // ------------------------------------------------------------

  /**
   * Blue to yellow color gradient, which we've used for wind speed.
   */
  static WindSpeed(): Gradient {
    return new Gradient([
      [new Color(12, 44, 132, 255), 0],
      [new Color(34, 94, 168, 255), 1],
      [new Color(29, 145, 192, 255), 2],
      [new Color(65, 182, 196, 255), 3],
      [new Color(127, 205, 187, 255), 4],
      [new Color(199, 233, 180, 255), 5],
      [new Color(237, 248, 177, 255), 6],
      [new Color(255, 255, 217, 255), 7],
    ]);
  }

  /**
   * Blue color gradient used for bathymetry.
   */
  static Depth(): Gradient {
    return new Gradient([
      [new Color(163, 199, 235), 0],
      [new Color(115, 140, 179), 1],
      [new Color(97, 120, 153), 2],
      [new Color(79, 92, 102), 3],
      [new Color(31, 43, 54), 4],
    ]);
  }

  static Brewer(
    k: keyof typeof colorBrewer,
    n: 3 | 4 | 5 | 6 | 7 | 8 | 9,
  ): Gradient {
    return Gradient.fromBalanced(colorBrewer[k][n], 0, 1);
  }
}

const colorBrewer = {
  BrBG: {
    3: [
      new Color(216, 179, 101),
      new Color(245, 245, 245),
      new Color(90, 180, 172),
    ],
    4: [
      new Color(166, 97, 26),
      new Color(223, 194, 125),
      new Color(128, 205, 193),
      new Color(1, 133, 113),
    ],
    5: [
      new Color(166, 97, 26),
      new Color(223, 194, 125),
      new Color(245, 245, 245),
      new Color(128, 205, 193),
      new Color(1, 133, 113),
    ],
    6: [
      new Color(140, 81, 10),
      new Color(216, 179, 101),
      new Color(246, 232, 195),
      new Color(199, 234, 229),
      new Color(90, 180, 172),
      new Color(1, 102, 94),
    ],
    7: [
      new Color(140, 81, 10),
      new Color(216, 179, 101),
      new Color(246, 232, 195),
      new Color(245, 245, 245),
      new Color(199, 234, 229),
      new Color(90, 180, 172),
      new Color(1, 102, 94),
    ],
    8: [
      new Color(140, 81, 10),
      new Color(191, 129, 45),
      new Color(223, 194, 125),
      new Color(246, 232, 195),
      new Color(199, 234, 229),
      new Color(128, 205, 193),
      new Color(53, 151, 143),
      new Color(1, 102, 94),
    ],
    9: [
      new Color(140, 81, 10),
      new Color(191, 129, 45),
      new Color(223, 194, 125),
      new Color(246, 232, 195),
      new Color(245, 245, 245),
      new Color(199, 234, 229),
      new Color(128, 205, 193),
      new Color(53, 151, 143),
      new Color(1, 102, 94),
    ],
    10: [
      new Color(84, 48, 5),
      new Color(140, 81, 10),
      new Color(191, 129, 45),
      new Color(223, 194, 125),
      new Color(246, 232, 195),
      new Color(199, 234, 229),
      new Color(128, 205, 193),
      new Color(53, 151, 143),
      new Color(1, 102, 94),
      new Color(0, 60, 48),
    ],
    11: [
      new Color(84, 48, 5),
      new Color(140, 81, 10),
      new Color(191, 129, 45),
      new Color(223, 194, 125),
      new Color(246, 232, 195),
      new Color(245, 245, 245),
      new Color(199, 234, 229),
      new Color(128, 205, 193),
      new Color(53, 151, 143),
      new Color(1, 102, 94),
      new Color(0, 60, 48),
    ],
    type: "div",
  },
  PiYG: {
    3: [
      new Color(233, 163, 201),
      new Color(247, 247, 247),
      new Color(161, 215, 106),
    ],
    4: [
      new Color(208, 28, 139),
      new Color(241, 182, 218),
      new Color(184, 225, 134),
      new Color(77, 172, 38),
    ],
    5: [
      new Color(208, 28, 139),
      new Color(241, 182, 218),
      new Color(247, 247, 247),
      new Color(184, 225, 134),
      new Color(77, 172, 38),
    ],
    6: [
      new Color(197, 27, 125),
      new Color(233, 163, 201),
      new Color(253, 224, 239),
      new Color(230, 245, 208),
      new Color(161, 215, 106),
      new Color(77, 146, 33),
    ],
    7: [
      new Color(197, 27, 125),
      new Color(233, 163, 201),
      new Color(253, 224, 239),
      new Color(247, 247, 247),
      new Color(230, 245, 208),
      new Color(161, 215, 106),
      new Color(77, 146, 33),
    ],
    8: [
      new Color(197, 27, 125),
      new Color(222, 119, 174),
      new Color(241, 182, 218),
      new Color(253, 224, 239),
      new Color(230, 245, 208),
      new Color(184, 225, 134),
      new Color(127, 188, 65),
      new Color(77, 146, 33),
    ],
    9: [
      new Color(197, 27, 125),
      new Color(222, 119, 174),
      new Color(241, 182, 218),
      new Color(253, 224, 239),
      new Color(247, 247, 247),
      new Color(230, 245, 208),
      new Color(184, 225, 134),
      new Color(127, 188, 65),
      new Color(77, 146, 33),
    ],
    10: [
      new Color(142, 1, 82),
      new Color(197, 27, 125),
      new Color(222, 119, 174),
      new Color(241, 182, 218),
      new Color(253, 224, 239),
      new Color(230, 245, 208),
      new Color(184, 225, 134),
      new Color(127, 188, 65),
      new Color(77, 146, 33),
      new Color(39, 100, 25),
    ],
    11: [
      new Color(142, 1, 82),
      new Color(197, 27, 125),
      new Color(222, 119, 174),
      new Color(241, 182, 218),
      new Color(253, 224, 239),
      new Color(247, 247, 247),
      new Color(230, 245, 208),
      new Color(184, 225, 134),
      new Color(127, 188, 65),
      new Color(77, 146, 33),
      new Color(39, 100, 25),
    ],
    type: "div",
  },
  RdYlBu: {
    3: [
      new Color(252, 141, 89),
      new Color(255, 255, 191),
      new Color(145, 191, 219),
    ],
    4: [
      new Color(215, 25, 28),
      new Color(253, 174, 97),
      new Color(171, 217, 233),
      new Color(44, 123, 182),
    ],
    5: [
      new Color(215, 25, 28),
      new Color(253, 174, 97),
      new Color(255, 255, 191),
      new Color(171, 217, 233),
      new Color(44, 123, 182),
    ],
    6: [
      new Color(215, 48, 39),
      new Color(252, 141, 89),
      new Color(254, 224, 144),
      new Color(224, 243, 248),
      new Color(145, 191, 219),
      new Color(69, 117, 180),
    ],
    7: [
      new Color(215, 48, 39),
      new Color(252, 141, 89),
      new Color(254, 224, 144),
      new Color(255, 255, 191),
      new Color(224, 243, 248),
      new Color(145, 191, 219),
      new Color(69, 117, 180),
    ],
    8: [
      new Color(215, 48, 39),
      new Color(244, 109, 67),
      new Color(253, 174, 97),
      new Color(254, 224, 144),
      new Color(224, 243, 248),
      new Color(171, 217, 233),
      new Color(116, 173, 209),
      new Color(69, 117, 180),
    ],
    9: [
      new Color(215, 48, 39),
      new Color(244, 109, 67),
      new Color(253, 174, 97),
      new Color(254, 224, 144),
      new Color(255, 255, 191),
      new Color(224, 243, 248),
      new Color(171, 217, 233),
      new Color(116, 173, 209),
      new Color(69, 117, 180),
    ],
    10: [
      new Color(165, 0, 38),
      new Color(215, 48, 39),
      new Color(244, 109, 67),
      new Color(253, 174, 97),
      new Color(254, 224, 144),
      new Color(224, 243, 248),
      new Color(171, 217, 233),
      new Color(116, 173, 209),
      new Color(69, 117, 180),
      new Color(49, 54, 149),
    ],
    11: [
      new Color(165, 0, 38),
      new Color(215, 48, 39),
      new Color(244, 109, 67),
      new Color(253, 174, 97),
      new Color(254, 224, 144),
      new Color(255, 255, 191),
      new Color(224, 243, 248),
      new Color(171, 217, 233),
      new Color(116, 173, 209),
      new Color(69, 117, 180),
      new Color(49, 54, 149),
    ],
    type: "div",
  },
  RdPu: {
    3: [
      new Color(253, 224, 221),
      new Color(250, 159, 181),
      new Color(197, 27, 138),
    ],
    4: [
      new Color(254, 235, 226),
      new Color(251, 180, 185),
      new Color(247, 104, 161),
      new Color(174, 1, 126),
    ],
    5: [
      new Color(254, 235, 226),
      new Color(251, 180, 185),
      new Color(247, 104, 161),
      new Color(197, 27, 138),
      new Color(122, 1, 119),
    ],
    6: [
      new Color(254, 235, 226),
      new Color(252, 197, 192),
      new Color(250, 159, 181),
      new Color(247, 104, 161),
      new Color(197, 27, 138),
      new Color(122, 1, 119),
    ],
    7: [
      new Color(254, 235, 226),
      new Color(252, 197, 192),
      new Color(250, 159, 181),
      new Color(247, 104, 161),
      new Color(221, 52, 151),
      new Color(174, 1, 126),
      new Color(122, 1, 119),
    ],
    8: [
      new Color(255, 247, 243),
      new Color(253, 224, 221),
      new Color(252, 197, 192),
      new Color(250, 159, 181),
      new Color(247, 104, 161),
      new Color(221, 52, 151),
      new Color(174, 1, 126),
      new Color(122, 1, 119),
    ],
    9: [
      new Color(255, 247, 243),
      new Color(253, 224, 221),
      new Color(252, 197, 192),
      new Color(250, 159, 181),
      new Color(247, 104, 161),
      new Color(221, 52, 151),
      new Color(174, 1, 126),
      new Color(122, 1, 119),
      new Color(73, 0, 106),
    ],
    type: "seq",
  },
  BuPu: {
    3: [
      new Color(224, 236, 244),
      new Color(158, 188, 218),
      new Color(136, 86, 167),
    ],
    4: [
      new Color(237, 248, 251),
      new Color(179, 205, 227),
      new Color(140, 150, 198),
      new Color(136, 65, 157),
    ],
    5: [
      new Color(237, 248, 251),
      new Color(179, 205, 227),
      new Color(140, 150, 198),
      new Color(136, 86, 167),
      new Color(129, 15, 124),
    ],
    6: [
      new Color(237, 248, 251),
      new Color(191, 211, 230),
      new Color(158, 188, 218),
      new Color(140, 150, 198),
      new Color(136, 86, 167),
      new Color(129, 15, 124),
    ],
    7: [
      new Color(237, 248, 251),
      new Color(191, 211, 230),
      new Color(158, 188, 218),
      new Color(140, 150, 198),
      new Color(140, 107, 177),
      new Color(136, 65, 157),
      new Color(110, 1, 107),
    ],
    8: [
      new Color(247, 252, 253),
      new Color(224, 236, 244),
      new Color(191, 211, 230),
      new Color(158, 188, 218),
      new Color(140, 150, 198),
      new Color(140, 107, 177),
      new Color(136, 65, 157),
      new Color(110, 1, 107),
    ],
    9: [
      new Color(247, 252, 253),
      new Color(224, 236, 244),
      new Color(191, 211, 230),
      new Color(158, 188, 218),
      new Color(140, 150, 198),
      new Color(140, 107, 177),
      new Color(136, 65, 157),
      new Color(129, 15, 124),
      new Color(77, 0, 75),
    ],
    type: "seq",
  },
  BuGn: {
    3: [
      new Color(229, 245, 249),
      new Color(153, 216, 201),
      new Color(44, 162, 95),
    ],
    4: [
      new Color(237, 248, 251),
      new Color(178, 226, 226),
      new Color(102, 194, 164),
      new Color(35, 139, 69),
    ],
    5: [
      new Color(237, 248, 251),
      new Color(178, 226, 226),
      new Color(102, 194, 164),
      new Color(44, 162, 95),
      new Color(0, 109, 44),
    ],
    6: [
      new Color(237, 248, 251),
      new Color(204, 236, 230),
      new Color(153, 216, 201),
      new Color(102, 194, 164),
      new Color(44, 162, 95),
      new Color(0, 109, 44),
    ],
    7: [
      new Color(237, 248, 251),
      new Color(204, 236, 230),
      new Color(153, 216, 201),
      new Color(102, 194, 164),
      new Color(65, 174, 118),
      new Color(35, 139, 69),
      new Color(0, 88, 36),
    ],
    8: [
      new Color(247, 252, 253),
      new Color(229, 245, 249),
      new Color(204, 236, 230),
      new Color(153, 216, 201),
      new Color(102, 194, 164),
      new Color(65, 174, 118),
      new Color(35, 139, 69),
      new Color(0, 88, 36),
    ],
    9: [
      new Color(247, 252, 253),
      new Color(229, 245, 249),
      new Color(204, 236, 230),
      new Color(153, 216, 201),
      new Color(102, 194, 164),
      new Color(65, 174, 118),
      new Color(35, 139, 69),
      new Color(0, 109, 44),
      new Color(0, 68, 27),
    ],
    type: "seq",
  },
  GnBu: {
    3: [
      new Color(224, 243, 219),
      new Color(168, 221, 181),
      new Color(67, 162, 202),
    ],
    4: [
      new Color(240, 249, 232),
      new Color(186, 228, 188),
      new Color(123, 204, 196),
      new Color(43, 140, 190),
    ],
    5: [
      new Color(240, 249, 232),
      new Color(186, 228, 188),
      new Color(123, 204, 196),
      new Color(67, 162, 202),
      new Color(8, 104, 172),
    ],
    6: [
      new Color(240, 249, 232),
      new Color(204, 235, 197),
      new Color(168, 221, 181),
      new Color(123, 204, 196),
      new Color(67, 162, 202),
      new Color(8, 104, 172),
    ],
    7: [
      new Color(240, 249, 232),
      new Color(204, 235, 197),
      new Color(168, 221, 181),
      new Color(123, 204, 196),
      new Color(78, 179, 211),
      new Color(43, 140, 190),
      new Color(8, 88, 158),
    ],
    8: [
      new Color(247, 252, 240),
      new Color(224, 243, 219),
      new Color(204, 235, 197),
      new Color(168, 221, 181),
      new Color(123, 204, 196),
      new Color(78, 179, 211),
      new Color(43, 140, 190),
      new Color(8, 88, 158),
    ],
    9: [
      new Color(247, 252, 240),
      new Color(224, 243, 219),
      new Color(204, 235, 197),
      new Color(168, 221, 181),
      new Color(123, 204, 196),
      new Color(78, 179, 211),
      new Color(43, 140, 190),
      new Color(8, 104, 172),
      new Color(8, 64, 129),
    ],
    type: "seq",
  },
  YlOrRd: {
    3: [
      new Color(255, 237, 160),
      new Color(254, 178, 76),
      new Color(240, 59, 32),
    ],
    4: [
      new Color(255, 255, 178),
      new Color(254, 204, 92),
      new Color(253, 141, 60),
      new Color(227, 26, 28),
    ],
    5: [
      new Color(255, 255, 178),
      new Color(254, 204, 92),
      new Color(253, 141, 60),
      new Color(240, 59, 32),
      new Color(189, 0, 38),
    ],
    6: [
      new Color(255, 255, 178),
      new Color(254, 217, 118),
      new Color(254, 178, 76),
      new Color(253, 141, 60),
      new Color(240, 59, 32),
      new Color(189, 0, 38),
    ],
    7: [
      new Color(255, 255, 178),
      new Color(254, 217, 118),
      new Color(254, 178, 76),
      new Color(253, 141, 60),
      new Color(252, 78, 42),
      new Color(227, 26, 28),
      new Color(177, 0, 38),
    ],
    8: [
      new Color(255, 255, 204),
      new Color(255, 237, 160),
      new Color(254, 217, 118),
      new Color(254, 178, 76),
      new Color(253, 141, 60),
      new Color(252, 78, 42),
      new Color(227, 26, 28),
      new Color(177, 0, 38),
    ],
    9: [
      new Color(255, 255, 204),
      new Color(255, 237, 160),
      new Color(254, 217, 118),
      new Color(254, 178, 76),
      new Color(253, 141, 60),
      new Color(252, 78, 42),
      new Color(227, 26, 28),
      new Color(189, 0, 38),
      new Color(128, 0, 38),
    ],
    type: "seq",
  },
};
