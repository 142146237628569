import { useAtomValue } from "jotai";
import { orgAnalysisManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibraryAnalysis } from "components/Ably/ChannelProviders/Organisation/Library/Analysis/useAblyLibraryAnalysis";
interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function AnalysisLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_analysis_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyAnalysisChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyAnalysisChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibraryAnalysis(organisationId);

  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_analysis_manage"}
    />
  );
}
