import React, { useState } from "react";
import { selectedComparisonAttributesAtom } from "../state";
import { useColumnTemplates } from "../columnTemplates";
import { ComparisonAttributeKey } from "../types";
import { useAtom } from "jotai";
import { attributeKeyToIcon } from "../icons";
import SelectAttributesGeneral from "components/General/SelectAttributesGeneral";

const SelectAttributes = () => {
  const [selectedAttributes, setSelectedAttributes] = useAtom(
    selectedComparisonAttributesAtom,
  );
  const columnTemplates = useColumnTemplates();
  const [isRowsOpen, setIsRowsOpen] = useState(false);
  return (
    <SelectAttributesGeneral
      isOpen={isRowsOpen}
      setIsOpen={setIsRowsOpen}
      title="Select rows"
      categories={columnTemplates}
      selectedKeys={selectedAttributes}
      onSelectionChange={(categoryKey, newKeys) => {
        setSelectedAttributes((curr) => ({
          ...curr,
          [categoryKey]: newKeys,
        }));
      }}
      getCategoryIcon={(key) =>
        attributeKeyToIcon[key as ComparisonAttributeKey]
      }
      canEdit={true}
    />
  );
};

export default SelectAttributes;
