import {
  CONCRETE_DENSITY,
  scalingEquationTokens,
  STEEL_DENSITY,
} from "@constants/foundations";
import { getFoundationResourcesOnNode } from "components/Organisation/Library/service";
import { NodeFoundationAccess } from "components/Organisation/Library/types";
import { atom } from "jotai";
import { fetchSchemaWithToken } from "services/utils";
import { defaultFoundations } from "state/foundations";
import { projectIdAtom } from "state/pathParams";
import { TurbineFeature } from "types/feature";
import {
  DetailedJacketType,
  DetailedMonopileType,
  FloaterType,
  FoundationType,
  JacketType,
  MonopileType,
  SemiCentralType,
  SemiPeripheralType,
  SparType,
  _FoundationLevel,
  _FoundationType,
} from "types/foundations";
import { SimpleTurbineType } from "types/turbines";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  isConcreteFoundation,
  isDefined,
  isDetailedMonopile,
  isFixed,
  isFloater,
  isSimpleJacket,
  isSimpleMonopile,
  isSemiCentral,
  isSemiPeripheral,
  isSpar,
  isSteelFoundation,
  isDetailedJacket,
} from "utils/predicates";
import { maxBy, roundToDecimal } from "utils/utils";
import { turbinesInParkFamily } from "./turbine";
import { simpleTurbineTypesAtom } from "./turbineType";
import {
  JacketDesign,
  MonopileDesign,
} from "components/RightSide/InfoModal/FoundationModal/fixed/state";
import { bathymetryFamily } from "state/bathymetry";
import Mexp from "math-expression-evaluator";
import { monopileNaturalFrequency } from "functions/monopileSizing";
import { FloatingTotalMaterial } from "components/RightSide/InfoModal/FoundationModal/floating/FloatingFoundationGeneration";
import { DefaultMap } from "lib/DefaultMap";
import { calculateJacketMass } from "functions/jacketSizing";

const defaultFoundationTypesAtom = atom<Map<string, FoundationType>>(
  new Map(defaultFoundations.map((f) => [f.id, f])),
);

const projectFoundationsRefreshAtomJ = atom(0); // TODO: remove this
export const projectFoundationTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<FoundationType[]>>(async (get) => {
    get(projectFoundationsRefreshAtomJ);
    const res = await fetchSchemaWithToken(
      _FoundationType.array(),
      `/api/turbines/v2/node/${nodeId}/foundations`,
    );
    return res.filter((f) => !f.archived);
  }),
);

export const libraryFoundationsRefreshAtomJ = atom(0); // TODO: remove this
export const libraryFoundationTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<NodeFoundationAccess[]>>(async (get) => {
    get(libraryFoundationsRefreshAtomJ);
    const res = await getFoundationResourcesOnNode(nodeId);
    return res.filter((node) => !node.foundation.archived);
  }),
);

/**
 * All foundations for the current project.
 */
export const foundationTypesAtom = atom<Promise<Map<string, FoundationType>>>(
  async (get) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return new Map();
    const def = get(defaultFoundationTypesAtom);
    const proj = await get(projectFoundationTypesFamily(projectId));
    const lib = await get(libraryFoundationTypesFamily(projectId));
    const ret = new Map<string, FoundationType>();
    for (const [k, v] of def) ret.set(k, v);
    for (const f of proj) ret.set(f.id, f);
    for (const f of lib) ret.set(f.foundation.id, f.foundation);
    return ret;
  },
);

export const foundationTypesWithLevelAtom = atom(async (get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return [];
  const proj = await get(projectFoundationTypesFamily(projectId));
  const lib = await get(libraryFoundationTypesFamily(projectId));
  return [
    ...defaultFoundations.map((f) => ({
      level: _FoundationLevel.Values.standard,
      foundation: f,
    })),
    ...proj.map((f) => ({
      level: _FoundationLevel.Values.project,
      foundation: f,
    })),
    ...lib.map((f) => ({
      level: _FoundationLevel.Values.library,
      foundation: f.foundation,
    })),
  ];
});

export const foundationFloaterTypesAtom = atom<Promise<FloaterType[]>>(
  async (get) => {
    const types = await get(foundationTypesAtom);
    return [...types.values()].filter(
      (t): t is FloaterType => isFloater(t) && !t.archived,
    );
  },
);

type FoundationGroupedByType = {
  semi_central: Map<string, SemiCentralType>;
  semi_peripheral: Map<string, SemiPeripheralType>;
  spar: Map<string, SparType>;
  monopile: Map<string, MonopileType>;
  detailed_monopile: Map<string, DetailedMonopileType>;
  jacket: Map<string, JacketType>;
  detailed_jacket: Map<string, DetailedJacketType>;
};
const foundationTypesGroupTypeFamily = atom<Promise<FoundationGroupedByType>>(
  async (get) => {
    const types = await get(foundationTypesAtom);
    const ret = {
      semi_central: new Map<string, SemiCentralType>(),
      semi_peripheral: new Map<string, SemiPeripheralType>(),
      spar: new Map<string, SparType>(),
      monopile: new Map<string, MonopileType>(),
      detailed_monopile: new Map<string, DetailedMonopileType>(),
      jacket: new Map<string, JacketType>(),
      detailed_jacket: new Map<string, DetailedJacketType>(),
    } as const;
    for (const t of types.values()) {
      if (!(t.type in ret)) continue;
      ret[t.type].set(t.id, t as any); // Safety: this is the right type, because `type` is what it is
    }
    return ret;
  },
);

type TurbinesGroupedByFoundationType = {
  semi_central: { turbine: TurbineFeature; foundation: SemiCentralType }[];
  semi_peripheral: {
    turbine: TurbineFeature;
    foundation: SemiPeripheralType;
  }[];
  spar: { turbine: TurbineFeature; foundation: SparType }[];
  monopile: { turbine: TurbineFeature; foundation: MonopileType }[];
  detailed_monopile: {
    turbine: TurbineFeature;
    foundation: DetailedMonopileType;
  }[];
  detailed_jacket: {
    turbine: TurbineFeature;
    foundation: DetailedJacketType;
  }[];
  jacket: { turbine: TurbineFeature; foundation: JacketType }[];
  none: TurbineFeature[];
};
export const turbinesInParkGroupFoundationTypeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<TurbinesGroupedByFoundationType>>(async (get) => {
      const ftypes = await get(foundationTypesAtom);
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));

      const ret: TurbinesGroupedByFoundationType = {
        semi_central: [],
        semi_peripheral: [],
        spar: [],
        monopile: [],
        detailed_monopile: [],
        detailed_jacket: [],
        jacket: [],
        none: [],
      };

      for (const turbine of turbines) {
        const foundation = ftypes.get(turbine.properties.foundationId ?? "");
        if (isSemiCentral(foundation))
          ret.semi_central.push({ turbine, foundation });
        else if (isSemiPeripheral(foundation))
          ret.semi_peripheral.push({ turbine, foundation });
        else if (isSpar(foundation)) ret.spar.push({ turbine, foundation });
        else if (isSimpleMonopile(foundation))
          ret.monopile.push({ turbine, foundation });
        else if (isDetailedMonopile(foundation))
          ret.detailed_monopile.push({ turbine, foundation });
        else if (isDetailedJacket(foundation))
          ret.detailed_jacket.push({ turbine, foundation });
        else if (isSimpleJacket(foundation))
          ret.jacket.push({ turbine, foundation });
        else ret.none.push(turbine);
      }

      return ret;
    }),
);

export const foundationFixedTypesAtom = atom(async (get) => {
  const types = await get(foundationTypesAtom);
  return [...types.values()].filter((t) => isFixed(t) && !t.archived);
});

export type FloatingFoundationScaledStatistics = {
  turbineTypeId: string;
  foundationId: string;
  scaledConcreteVolume?: number;
  scaledConcreteWeight?: number;
  scaledPrimarySteelWeight?: number;
  scaledSolidBallastWeight: number;
  scaledLiquidBallastWeight: number;
  scaledReinforcementWeight?: number;
  scaledPostTensionCableWeight?: number;
  scaledDisplacementVolume: number;
  scaledDraft?: number;
  scaledFootprintLength?: number;
  scaledFootprintBreath?: number;
  scaledMainDiameter?: number;
};

const getScaledStatisticsForFloatingFoundation = ({
  currentFoundation,
  turbineTypeId,
  scale,
}: {
  currentFoundation: FloaterType;
  turbineTypeId: string;
  scale: number;
}): FloatingFoundationScaledStatistics => {
  const scaledConcreteVolume = isConcreteFoundation(currentFoundation)
    ? roundToDecimal(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / CONCRETE_DENSITY,
        1,
      )
    : undefined;

  const scaledConcreteWeight = isConcreteFoundation(currentFoundation)
    ? roundToDecimal(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        1,
      )
    : undefined;

  const scaledPrimarySteelWeight = isSteelFoundation(currentFoundation)
    ? roundToDecimal(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        1,
      )
    : undefined;

  const scaledSolidBallastWeight = roundToDecimal(
    (Math.pow(scale, 3) * currentFoundation.solidBallastMass) / 1000,
    1,
  );

  const scaledLiquidBallastWeight = roundToDecimal(
    (Math.pow(scale, 3) * currentFoundation.liquidBallastMass) / 1000,
    1,
  );

  const scaledReinforcementWeight =
    isConcreteFoundation(currentFoundation) &&
    currentFoundation.reinforceDensity
      ? roundToDecimal(
          (Math.pow(scale, 3) *
            currentFoundation.reinforceDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          1,
        )
      : undefined;

  const scaledPostTensionCableWeight =
    isConcreteFoundation(currentFoundation) && currentFoundation.postTensDensity
      ? roundToDecimal(
          (Math.pow(scale, 3) *
            currentFoundation.postTensDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          1,
        )
      : undefined;

  const scaledDisplacementVolume = roundToDecimal(
    Math.pow(scale, 3) * currentFoundation.displacement,
    1,
  );

  const scaledDraft = Math.round(scale * currentFoundation.draft);

  const scaledFootprintLength =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(1.5 * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledFootprintBreath =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(Math.sqrt(3) * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledMainDiameter = isSpar(currentFoundation)
    ? roundToDecimal(scale * currentFoundation.baseDiameter, 1)
    : undefined;

  return {
    turbineTypeId,
    foundationId: currentFoundation.id,
    scaledConcreteVolume,
    scaledConcreteWeight,
    scaledPrimarySteelWeight,
    scaledSolidBallastWeight,
    scaledLiquidBallastWeight,
    scaledReinforcementWeight,
    scaledPostTensionCableWeight,
    scaledDisplacementVolume,
    scaledDraft,
    scaledFootprintLength,
    scaledFootprintBreath,
    scaledMainDiameter,
  };
};

export const foundationScale = ({
  turbine,
  foundation,
}: {
  turbine: SimpleTurbineType;
  foundation: FloaterType;
}): number => {
  const scaledTowerMass =
    (((foundation.towerMass * turbine.diameter) / foundation.rotorDiameter) *
      turbine.hubHeight) /
    foundation.hubHeight;
  const scaledMass = turbine.rnaMass + scaledTowerMass;
  const baseMass = foundation.rnaMass + foundation.towerMass;

  return Math.cbrt(scaledMass / baseMass);
};

export const getAllScaledStatisticsForFloatingFoundationsInParkFamily =
  atomFamily((parkId: string) =>
    atom<Promise<FloatingFoundationScaledStatistics[]>>(async (get) => {
      const [turbines, floaters, turbineTypes] = await Promise.all([
        get(turbinesInParkFamily({ parkId, branchId: undefined })),
        get(foundationFloaterTypesAtom),
        get(simpleTurbineTypesAtom),
      ]);

      const map = new DefaultMap<
        string,
        Map<string, FloatingFoundationScaledStatistics>
      >(() => new Map());

      for (const turbine of turbines) {
        const { foundationId, turbineTypeId } = turbine.properties;
        if (!foundationId) continue;
        const turbineType = turbineTypes.get(turbineTypeId);
        if (!turbineType) continue;
        const floater = floaters.find((f) => f.id === foundationId);
        if (!floater) continue;

        const m = map.get(turbineTypeId);
        if (m.has(floater.id)) continue;

        const scale = foundationScale({
          foundation: floater,
          turbine: turbineType,
        });
        const stats = getScaledStatisticsForFloatingFoundation({
          currentFoundation: floater,
          turbineTypeId,
          scale,
        });
        m.set(floater.id, stats);
      }

      const ret: FloatingFoundationScaledStatistics[] = [];
      for (const m of map.inner.values())
        for (const s of m.values()) ret.push(s);
      return ret;
    }),
  );

export const getScalesForTurbineTypeIdsAndFoundationIdsFamily = atomFamily(
  (idList: { turbineTypeId: string; foundationId: string }[]) =>
    atom(async (get) => {
      const allTurbineTypes = await get(simpleTurbineTypesAtom);
      const allFloaters = await get(foundationFloaterTypesAtom);

      const turbineFoundationScaleCombinations = Object.fromEntries(
        idList.map((c) => {
          const { turbineTypeId, foundationId } = c;
          const turbineType = allTurbineTypes.get(turbineTypeId);
          if (!turbineType) throw new Error("not found");
          const foundation = allFloaters.find((f) => f.id === foundationId);
          if (!foundation) throw new Error("not found");
          const scale =
            foundationScale({
              foundation: foundation,
              turbine: turbineType,
            }) ?? 1.0;
          return [`${turbineTypeId},${foundationId}`, scale];
        }),
      );

      return turbineFoundationScaleCombinations;
    }),
);

export const getSimpleFixedMassesFamily = atomFamily(
  ({
    turbinesWithSimpleFixed,
    rasterId,
  }: {
    turbinesWithSimpleFixed: TurbineFeature[];
    rasterId: string;
  }) =>
    atom<
      Promise<{
        foundationMasses: {
          turbineId: string;
          turbineTypeId: string;
          foundationId: string;
          mass: number;
        }[];
        totalFoundationMass: number;
      }>
    >(async (get) => {
      const rasterResult = await get(bathymetryFamily(rasterId));
      if (rasterResult.status === "failed")
        throw new Error(
          "Cannot compute bottom fixed masses without bathymetry",
        );

      const [allTurbineTypes, allFixed] = await Promise.all([
        get(simpleTurbineTypesAtom),
        get(foundationFixedTypesAtom),
      ]);

      const foundationMasses = turbinesWithSimpleFixed.map((t) => {
        const turbineType = allTurbineTypes.get(t.properties.turbineTypeId);
        const ratedPower = turbineType?.ratedPower ?? 0;
        const rotorDiameter = turbineType?.diameter ?? 0;

        const depth = rasterResult
          ? -rasterResult.raster.latLngToValue(
              t.geometry.coordinates[0],
              t.geometry.coordinates[1],
            )
          : 0;

        const foundation = allFixed.find(
          (f) => f.id === t.properties.foundationId,
        );

        if (!isSimpleJacket(foundation) && !isSimpleMonopile(foundation))
          return {
            turbineId: t.id,
            turbineTypeId: t.properties.turbineTypeId,
            foundationId: t.properties.foundationId ?? "",
            mass: 0,
          };

        const tokenValues = {
          p: ratedPower / 1000,
          w: depth,
          d: rotorDiameter,
        };
        var mexp = new Mexp();
        const massKg =
          1000 *
          mexp.eval(
            foundation.scalingEquation,
            scalingEquationTokens,
            tokenValues,
          );

        return {
          turbineId: t.id,
          turbineTypeId: t.properties.turbineTypeId,
          foundationId: foundation.id,
          mass: massKg,
        };
      });

      const totalFoundationMass = foundationMasses.reduce(
        (acc, { mass }) => acc + mass,
        0,
      );

      return {
        totalFoundationMass,
        foundationMasses,
      };
    }),
);

export const getDetailedMonopileMassesFamily = atomFamily(
  ({
    turbinesWithDetailedMonopile,
    rasterId,
  }: {
    turbinesWithDetailedMonopile: TurbineFeature[];
    rasterId: string;
  }) =>
    atom<
      Promise<{
        monopileDetails: MonopileDesign[];
        totalFoundationMass: number;
      }>
    >(async (get) => {
      const rasterResult = await get(bathymetryFamily(rasterId));
      if (rasterResult.status === "failed")
        throw new Error(
          "Cannot compute bottom fixed masses without bathymetry",
        );

      const [allTurbineTypes, allFixed] = await Promise.all([
        get(simpleTurbineTypesAtom),
        get(foundationFixedTypesAtom),
      ]);

      const monopileDetails = turbinesWithDetailedMonopile.map((t) => {
        const turbineDepth = rasterResult
          ? -rasterResult.raster.latLngToValue(
              t.geometry.coordinates[0],
              t.geometry.coordinates[1],
            )
          : 0;

        const turbineType = allTurbineTypes.get(t.properties.turbineTypeId);

        const foundation = allFixed.find(
          (f) => f.id === t.properties.foundationId,
        );

        if (!isDetailedMonopile(foundation) || !turbineType)
          return {
            turbineId: t.id,
            turbineTypeId: t.properties.turbineTypeId,
            foundationId: t.properties.foundationId ?? "",
            pileMass: 0,
            pileDiameter: 0,
            avgPileThickness: 0,
            embedLength: 0,
            totalPileLength: 0,
            natFreq: 0,
          };

        const {
          embedLength,
          totalPileLength,
          pileDiameter,
          avgPileThickness,
          waterDepth: designWaterDepth,
          soilCoeffSubReact,
        } = foundation;

        const maxPileMass =
          (Math.PI / 4) *
          (Math.pow(pileDiameter, 2) -
            Math.pow(pileDiameter - 2 * avgPileThickness, 2)) *
          totalPileLength *
          STEEL_DENSITY;

        const depthDifference = designWaterDepth - turbineDepth;

        const scaleRatio =
          (totalPileLength - depthDifference) / totalPileLength;

        const scaledMass = maxPileMass * scaleRatio;
        const scaledLength = totalPileLength - depthDifference;

        const natFreq = monopileNaturalFrequency({
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength: scaledLength,
          waterDepth: turbineDepth,
          soilCoeffSubReact,
          turbineType,
        });

        return {
          turbineId: t.id,
          turbineTypeId: t.properties.turbineTypeId,
          foundationId: foundation.id,
          pileMass: scaledMass,
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength: scaledLength,
          natFreq,
        };
      });

      const totalFoundationMass = monopileDetails.reduce(
        (acc, { pileMass }) => acc + pileMass,
        0,
      );

      return {
        totalFoundationMass,
        monopileDetails,
      };
    }),
);

export const getDetailedJacketMassesFamily = atomFamily(
  ({
    turbinesWithDetailedJacket,
    rasterId,
  }: {
    turbinesWithDetailedJacket: TurbineFeature[];
    rasterId: string;
  }) =>
    atom<
      Promise<{
        jacketDetails: JacketDesign[];
        totalFoundationMass: number;
      }>
    >(async (get) => {
      const rasterResult = await get(bathymetryFamily(rasterId));
      if (rasterResult.status === "failed")
        throw new Error(
          "Cannot compute bottom fixed masses without bathymetry",
        );

      const [allTurbineTypes, allFixed] = await Promise.all([
        get(simpleTurbineTypesAtom),
        get(foundationFixedTypesAtom),
      ]);

      const jacketDetails = turbinesWithDetailedJacket.map((t) => {
        const turbineDepth = rasterResult
          ? -rasterResult.raster.latLngToValue(
              t.geometry.coordinates[0],
              t.geometry.coordinates[1],
            )
          : 0;

        const turbineType = allTurbineTypes.get(t.properties.turbineTypeId);

        const foundation = allFixed.find(
          (f) => f.id === t.properties.foundationId,
        );

        if (!isDetailedJacket(foundation) || !turbineType)
          return {
            turbineId: t.id,
            turbineTypeId: t.properties.turbineTypeId,
            foundationId: t.properties.foundationId ?? "",
            jacketMass: 0,
            tpMass: 0,
            pileMass: 0,
            jacketHeight: 0,
            numLegs: 0,
            totalMass: 0,
          };

        const {
          legDiameter,
          legThickness,
          braceDiameter,
          braceThickness,
          jacketHeight,
          numLegs,
          Ltop,
          Lbottom,
          numBays,
          waterDepth: designWaterDepth,
          tpMass,
          pileMass,
        } = foundation;

        const depthDifference = designWaterDepth - turbineDepth;
        const scaledHeight = jacketHeight - depthDifference;

        const jacketMass = calculateJacketMass({
          Ltop,
          Lbottom,
          jacketHeight: scaledHeight,
          numBays,
          legDiameter,
          legThickness,
          braceDiameter,
          braceThickness,
          numLegs,
        });

        const totalMass = jacketMass + tpMass + numLegs * pileMass;

        return {
          turbineId: t.id,
          turbineTypeId: t.properties.turbineTypeId,
          foundationId: foundation.id,
          jacketMass,
          tpMass,
          pileMass,
          jacketHeight: scaledHeight,
          numLegs,
          totalMass,
        };
      });

      const totalFoundationMass = jacketDetails.reduce(
        (acc, { totalMass }) => acc + totalMass,
        0,
      );

      return {
        totalFoundationMass,
        jacketDetails,
      };
    }),
);

export const getFixedFoundationTotalsFamily = atomFamily(
  ({
    tempLayoutFoundations,
    rasterId,
  }: {
    tempLayoutFoundations: TurbineFeature[];
    rasterId: string;
  }) =>
    atom<
      Promise<{
        totalPrimarySteelMass: number;
        simpleFoundationMasses: {
          turbineId: string;
          turbineTypeId: string;
          foundationId: string;
          mass: number;
        }[];
        monopileDetails: MonopileDesign[];
        jacketDetails: JacketDesign[];
      }>
    >(async (get) => {
      const typesByType = await get(foundationTypesGroupTypeFamily);
      const turbinesWithDetailedMonopile = tempLayoutFoundations.filter((t) =>
        typesByType.detailed_monopile.has(t.properties.foundationId ?? ""),
      );
      const turbinesWithDetailedJacket = tempLayoutFoundations.filter((t) =>
        typesByType.detailed_jacket.has(t.properties.foundationId ?? ""),
      );
      const turbinesWithSimpleFixed = tempLayoutFoundations.filter(
        (t) =>
          typesByType.monopile.has(t.properties.foundationId ?? "") ||
          typesByType.jacket.has(t.properties.foundationId ?? ""),
      );

      const {
        totalFoundationMass: totalSimpleFixedMass,
        foundationMasses: simpleFoundationMasses,
      } = await get(
        getSimpleFixedMassesFamily({
          turbinesWithSimpleFixed,
          rasterId,
        }),
      );

      const {
        totalFoundationMass: totalDetailedMonopileMass,
        monopileDetails,
      } = await get(
        getDetailedMonopileMassesFamily({
          turbinesWithDetailedMonopile,
          rasterId,
        }),
      );

      const { totalFoundationMass: totalDetailedJacketMass, jacketDetails } =
        await get(
          getDetailedJacketMassesFamily({
            turbinesWithDetailedJacket,
            rasterId,
          }),
        );

      return {
        totalPrimarySteelMass:
          totalDetailedMonopileMass +
          totalDetailedJacketMass +
          totalSimpleFixedMass,
        simpleFoundationMasses,
        monopileDetails,
        jacketDetails,
      };
    }),
);

export const getFloatingFoundationDetailsFamily = atomFamily(
  ({
    tempLayoutFoundations,
    scales,
    turbineTypeIdAndFloatingFoundationIdCombinations,
  }: {
    tempLayoutFoundations: TurbineFeature[];
    scales: Record<string, number>;
    turbineTypeIdAndFloatingFoundationIdCombinations: {
      turbineTypeId: string;
      foundationId: string;
    }[];
  }) =>
    atom<Promise<FloatingTotalMaterial[]>>(async (get) => {
      const allFloaters = await get(foundationFloaterTypesAtom);

      return tempLayoutFoundations
        .map((t) => {
          const turbine = turbineTypeIdAndFloatingFoundationIdCombinations.find(
            (type) =>
              type.turbineTypeId === t.properties.turbineTypeId &&
              type.foundationId === t.properties.foundationId,
          );

          const t_id = turbine?.turbineTypeId;
          const f_id = turbine?.foundationId;

          if (!t_id || !f_id) {
            return undefined;
          }

          const floater = allFloaters.find((f) => f.id === f_id);

          if (!floater) {
            return undefined;
          }

          const powScales = Math.pow(scales[`${t_id},${f_id}`], 3);

          let totalPrimarySteelMass = 0;
          let totalPrimaryConcreteMass = 0;
          let totalPrimaryConcreteVolume = 0;
          let totalRebarMass = 0;
          let totalPostTensMass = 0;
          let totalSolidBallastMass = 0;
          let totalLiquidBallastMass = 0;
          if (isSteelFoundation(floater)) {
            totalPrimarySteelMass += powScales * floater.primaryMass;
          } else if (isConcreteFoundation(floater)) {
            totalPrimaryConcreteMass += powScales * floater.primaryMass;
            totalPrimaryConcreteVolume +=
              (powScales * floater.primaryMass) / CONCRETE_DENSITY;
            if (floater.reinforceDensity) {
              totalRebarMass +=
                powScales *
                (floater.primaryMass / CONCRETE_DENSITY) *
                floater.reinforceDensity;
            }
            if (floater.postTensDensity) {
              totalPostTensMass +=
                powScales *
                (floater.primaryMass / CONCRETE_DENSITY) *
                floater.postTensDensity;
            }
          }

          totalSolidBallastMass += powScales * floater.solidBallastMass;
          totalLiquidBallastMass += powScales * floater.liquidBallastMass;

          return {
            totalPrimarySteelMass,
            totalPrimaryConcreteMass,
            totalPrimaryConcreteVolume,
            totalSolidBallastMass,
            totalLiquidBallastMass,
            totalRebarMass,
            totalPostTensMass,
          };
        })
        .filter(isDefined);
    }),
);

export const getFloatingFoundationTotalsFamily = atomFamily(
  ({
    tempLayoutFoundations,
    scales,
    turbineTypeIdAndFloatingFoundationIdCombinations,
  }: {
    tempLayoutFoundations: TurbineFeature[];
    scales: Record<string, number>;
    turbineTypeIdAndFloatingFoundationIdCombinations: {
      turbineTypeId: string;
      foundationId: string;
    }[];
  }) =>
    atom<Promise<FloatingTotalMaterial>>(async (get) => {
      const totalDetails = await get(
        getFloatingFoundationDetailsFamily({
          tempLayoutFoundations,
          scales,
          turbineTypeIdAndFloatingFoundationIdCombinations,
        }),
      );

      return totalDetails.reduce(
        (acc, t) => {
          acc.totalPrimarySteelMass += t.totalPrimarySteelMass;
          acc.totalPrimaryConcreteMass += t.totalPrimaryConcreteMass;
          acc.totalPrimaryConcreteVolume += t.totalPrimaryConcreteVolume;
          acc.totalSolidBallastMass += t.totalSolidBallastMass;
          acc.totalLiquidBallastMass += t.totalLiquidBallastMass;
          acc.totalRebarMass += t.totalRebarMass;
          acc.totalPostTensMass += t.totalPostTensMass;
          return acc;
        },
        {
          totalPrimarySteelMass: 0,
          totalPrimaryConcreteMass: 0,
          totalPrimaryConcreteVolume: 0,
          totalSolidBallastMass: 0,
          totalLiquidBallastMass: 0,
          totalRebarMass: 0,
          totalPostTensMass: 0,
        },
      );
    }),
);

export const foundationMostCommonInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<FoundationType | undefined>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const counts = new DefaultMap<string, number>(() => 0);
      for (const t of turbines)
        if (t.properties.foundationId)
          counts.update(t.properties.foundationId, (n) => n + 1);
      const [top] =
        maxBy(Array.from(counts.inner.entries()), (n) => n[1]) ?? [];
      if (!top) return;
      return (await get(foundationTypesAtom)).get(top);
    }),
);

export const foundationTypesInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<FoundationType[]>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const foundationTypes = await get(foundationTypesAtom);
      const uniqueFoundationIds = new Set(
        turbines.map((t) => t.properties.foundationId).filter(isDefined),
      );
      return Array.from(uniqueFoundationIds)
        .map((id) => foundationTypes.get(id))
        .filter(isDefined);
    }),
);
