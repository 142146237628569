import { atom } from "jotai";
import { CableType } from "services/cableTypeService";
import { ExportCableFeature } from "types/feature";
import { atomFamily, atomFromFn } from "utils/jotai";
import { cableLoadsFamily } from "./cable";
import { exportCableTypesFamily } from "./exportCableType";
import { featuresInParkFamily } from "./features";
import { substationsInParkFamily } from "./substation";
import { allSubstationsForProjectAtom } from "./substationType";
import { exportCableSplitsFamily } from "./landfall";
import * as turf from "@turf/turf";
import { MaybePromise } from "types/utils";
import { isDefined } from "utils/predicates";
import { SimpleTurbineType } from "types/turbines";

export const currentExportCableIdAtom = atomFromFn<
  MaybePromise<{ offshore: string; onshore: string } | undefined>
>(async (get) => {
  const exportCableTypes = Array.from(
    (await get(exportCableTypesFamily({ projectId: undefined }))).values(),
  );
  if (exportCableTypes.length === 0) return undefined;
  return {
    offshore: exportCableTypes[0].id,
    onshore: exportCableTypes[0].id,
  };
});

// Jotai state for the number of duplicate export cables to draw
export const drawNumberOfDuplicateExportCablesState = atom<number>(1);

export const exportCablesInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<ExportCableFeature[]>>(
      async (get) =>
        (await get(featuresInParkFamily({ parkId, branchId }))).exportCable,
    ),
);

/**
 * Return `[ExportCableFeature, OffshoreCableType, OnshoreCableType]`.
 */
export const exportCablesInParkWithTypeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const cables = await get(
        exportCablesInParkFamily({
          parkId,
          branchId,
        }),
      );
      const types = await get(exportCableTypesFamily({ projectId: undefined }));
      return cables
        .map((t) => {
          const off = types.get(t.properties.cableTypeId ?? "");
          const ons = types.get(t.properties.onshoreCableTypeId ?? "");
          if (!off || !ons) return;
          return [t, off, ons] satisfies [
            ExportCableFeature,
            CableType,
            CableType,
          ];
        })
        .filter(isDefined);
    }),
);

export const exportCableLengthsFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        Map<string, { straight: number; offshore?: number; onshore?: number }>
      >
    >(async (get) => {
      const splits = await get(
        exportCableSplitsFamily({
          parkId,
          branchId,
        }),
      );

      const ret = new Map<
        string,
        { straight: number; offshore?: number; onshore?: number }
      >();
      for (const sp of splits) {
        if ("error" in sp) {
          ret.set(sp.exportCable.id, {
            straight: turf.length(sp.exportCable),
          });
        } else {
          ret.set(sp.exportCable.id, {
            straight: turf.length(sp.exportCable),
            offshore: turf.length(sp.offshore),
            onshore: turf.length(sp.onshore),
          });
        }
      }
      return ret;
    }),
);

/**
 * Compute the export cable load for the electrical network.  Substation ids
 * maps to the total effect going into the substation from IA cables.  The IA
 * cables themselves are **not** in this map.
 *
 * Load is in Watts.
 *
 * `throw`s an `Error` if the park contains a cable loop, sice the load is not
 * well defined in that case.
 */
export const exportCableLoadMapFamily = atomFamily(
  ({
    parkId,
    branchId,
    turbineTypeOverride,
  }: {
    parkId: string;
    branchId: string | undefined;
    turbineTypeOverride: SimpleTurbineType | undefined;
  }) =>
    atom<Promise<Map<string, number>>>(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const substationTypes = await get(allSubstationsForProjectAtom);
      const onshoreIds = new Set();
      const offshoreIds = new Set();
      for (const s of substations) {
        const typ = substationTypes.get(s.properties.substationTypeId ?? "");
        if (!typ) continue;
        if (typ.type === "onshore") onshoreIds.add(s.id);
        if (typ.type === "offshore") offshoreIds.add(s.id);
      }

      const cableLoads = await get(
        cableLoadsFamily({ parkId, branchId, turbineTypeOverride }),
      );

      const ret = new Map<string, number>();
      // Substations are either connected to each other via an export cable,
      // directly to turbines, or both.  Here we hadle all cases.  Register
      // inter-array loads on all subs.
      for (const sub of substations) {
        const load = cableLoads.get(sub.id);
        if (load !== undefined) ret.set(sub.id, load);
      }

      // The load of an export cable is just the load of the "inner"
      // substation. The load of the "outer" substation is it's inter-array
      // load plus it's incoming export cables.
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const cablesPerSubstation = exportCables.reduce(
        (acc: Record<string, number>, c: ExportCableFeature) => {
          if (!c.properties.fromSubstationId) return acc;
          if (c.properties.fromSubstationId in acc) {
            acc[c.properties.fromSubstationId]++;
          } else {
            acc[c.properties.fromSubstationId] = 1;
          }

          return acc;
        },
        {} as Record<string, number>,
      );
      for (const c of exportCables) {
        const { fromSubstationId, toSubstationId } = c.properties;
        if (!fromSubstationId || !toSubstationId) continue;

        const cablesSplittingFromSubstation =
          cablesPerSubstation[fromSubstationId] ?? 1;

        if (
          onshoreIds.has(fromSubstationId) &&
          offshoreIds.has(toSubstationId)
        ) {
          const load =
            (ret.get(toSubstationId) ?? 0) / cablesSplittingFromSubstation;

          ret.set(fromSubstationId, (ret.get(fromSubstationId) ?? 0) + load);
          ret.set(c.id, load);
        } else if (
          onshoreIds.has(toSubstationId) &&
          offshoreIds.has(fromSubstationId)
        ) {
          const load =
            (ret.get(fromSubstationId) ?? 0) / cablesSplittingFromSubstation;
          ret.set(toSubstationId, (ret.get(toSubstationId) ?? 0) + load);
          ret.set(c.id, load);
        }
      }

      return ret;
    }),
);
