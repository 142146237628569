import { searchBarOpen } from "../state";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { modalTypeOpenAtom } from "state/modal";
import { useAtom, useSetAtom } from "jotai";
import SearchBarInner from "./SearchBar";
import { useSearchDataOrganisation } from "../searchHooks/useSearchDataOrganisation";

const SearchBarOrganisation = ({
  organisationId,
}: {
  organisationId: string;
}) => {
  const [open, setOpen] = useAtom(searchBarOpen);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setOpen(false));

  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setModalTypeOpen(undefined);
        setOpen((cur) => !cur);
      }
    },
    [setModalTypeOpen, setOpen],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);
    return () => window.removeEventListener("keydown", handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  if (!open) return null;

  return (
    <Suspense fallback={null}>
      <SearchBarOrganisationInner
        close={() => setOpen(false)}
        _ref={ref}
        organisationId={organisationId}
      />
    </Suspense>
  );
};

const SearchBarOrganisationInner = ({
  _ref,
  close,
  organisationId,
}: {
  _ref: React.RefObject<HTMLDivElement>;
  close: () => void;
  organisationId: string;
}) => {
  const [search, setSearch] = useState("");
  const { searchGroups, searchableItems, someItemsAreLoading } =
    useSearchDataOrganisation({
      organisationId,
      search,
    });

  return (
    <SearchBarInner
      searchGroups={searchGroups}
      searchableItems={searchableItems}
      close={close}
      _ref={_ref}
      search={search}
      setSearch={setSearch}
      page="organisation"
      someItemsAreLoading={someItemsAreLoading}
    />
  );
};
export default SearchBarOrganisation;
