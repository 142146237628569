import styled from "styled-components";
import { colors } from "styles/colors";
import BrowseLayersFilters from "./components/DataLayerFilters/BrowseLayersFilters";
import { DataLayerTab } from "./DataLayersModal";
import { IconREMSize, typography } from "styles/typography";
import LayersIcon from "@icons/24/Layers.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import { Row } from "components/General/Layout";
import { spaceLarge } from "styles/space";
import VindLogoIcon from "@icons/24/VindLogo.svg";
import ConnectExternal from "./components/ConnectExternal";
import { Mixpanel } from "mixpanel";
import StarIcon from "@icons/24/Star.svg?react";

const SidePanelContainer = styled.div`
  width: 20%;
  max-width: 20rem;
  padding: ${spaceLarge};
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background-color: ${colors.surfaceSubtle};
  padding-top: 2rem;
  border-right: 1px solid ${colors.borderSubtle};
`;

const SectionTitle = styled.h4`
  ${typography.sub2}
  padding-left: 1.2rem;
`;

const LibraryOption = styled(Row)<{ selected: boolean }>`
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => (props.selected ? typography.sub1 : typography.body)}
  color: ${(props) =>
    props.selected ? colors.textSelected : colors.textPrimary};
  background-color: ${(props) =>
    props.selected ? colors.surfaceSelectedLight : "transparent"};

  cursor: pointer;
  padding: 0.6rem 1.2rem;
  &:hover {
    background-color: ${(props) =>
      props.selected ? colors.surfaceSelectedHover : colors.surfaceHover};
  }
  border-radius: 4px;
`;

const SubtleDivider = styled.div`
  border-top: 1px solid ${colors.borderSubtle};
`;

interface SidePanelProps {
  selectedTab: DataLayerTab;
  setSelectedTab: (tab: DataLayerTab) => void;
}

const SidePanel = ({ selectedTab, setSelectedTab }: SidePanelProps) => {
  const handleSetSelectedTab = (tab: DataLayerTab) => {
    setSelectedTab(tab);
    Mixpanel.track_old("tab_selected", {
      tab: tab,
    });
  };

  return (
    <SidePanelContainer>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <LibraryOption
          selected={selectedTab === DataLayerTab.EXTERNAL}
          onClick={() => handleSetSelectedTab(DataLayerTab.EXTERNAL)}
        >
          <IconREMSize
            height={1.4}
            width={1.4}
            stroke={
              selectedTab === DataLayerTab.EXTERNAL
                ? colors.iconSelected
                : colors.iconDefault
            }
          >
            <LayersIcon />
          </IconREMSize>
          External
        </LibraryOption>

        <LibraryOption
          selected={selectedTab === DataLayerTab.DATA_PACKAGES}
          onClick={() => handleSetSelectedTab(DataLayerTab.DATA_PACKAGES)}
        >
          <IconREMSize
            height={1.4}
            width={1.4}
            stroke={
              selectedTab === DataLayerTab.DATA_PACKAGES
                ? colors.iconSelected
                : colors.iconDefault
            }
          >
            <DatabaseIcon />
          </IconREMSize>
          GIS packages
        </LibraryOption>

        <LibraryOption
          selected={selectedTab === DataLayerTab.FAVORITES}
          onClick={() => handleSetSelectedTab(DataLayerTab.FAVORITES)}
        >
          <IconREMSize
            height={1.4}
            width={1.4}
            stroke={colors.iconSelected}
            fill={colors.iconSelected}
          >
            <StarIcon />
          </IconREMSize>
          Favorites
        </LibraryOption>

        <LibraryOption
          selected={selectedTab === DataLayerTab.VIND_SUGGESTIONS}
          onClick={() => handleSetSelectedTab(DataLayerTab.VIND_SUGGESTIONS)}
        >
          <IconREMSize
            height={1.4}
            width={1.4}
            stroke={colors.iconSelected}
            fill={colors.iconSelected}
          >
            <VindLogoIcon />
          </IconREMSize>
          Vind suggestions
        </LibraryOption>
      </div>

      <SubtleDivider />

      {selectedTab === DataLayerTab.EXTERNAL && (
        <>
          <SectionTitle>Filter by</SectionTitle>
          <BrowseLayersFilters />
        </>
      )}

      <ConnectExternal />
    </SidePanelContainer>
  );
};

export default SidePanel;
