import { useMemo } from "react";
import useCableSettings from "./useCableSettings";
import useFoundationSettings from "./useFoundationSettings";
import useMooringSettings from "./useMooringSettings";
import useTurbineSettings from "./useTurbineSettings";
import useSubstationSettings from "./Substation/useSubstationSettings";
import useExportCableSettings from "./useExportCableSettings";
import { SettingsItem } from "components/SettingsV2/Shared/types";
import { useAtomValue } from "jotai";
import { designToolTypeAtom } from "state/map";
import { DesignToolMode } from "types/map";
import useVesselSettings from "./useVesselSettings";

function useFeatureSettingsOnshore() {
  const turbineSettings = useTurbineSettings();
  const cableSettings = useCableSettings();
  const exportCableSettings = useExportCableSettings();
  const substationSettings = useSubstationSettings();

  const projectConfiguration = useMemo(() => {
    const items: SettingsItem[] = [
      turbineSettings,
      cableSettings,
      substationSettings,
      exportCableSettings,
    ];
    return [{ items: items }];
  }, [turbineSettings, cableSettings, substationSettings, exportCableSettings]);

  return projectConfiguration;
}

function useFeatureSettingsOffshore() {
  const turbineSettings = useTurbineSettings();
  const foundationSettings = useFoundationSettings();
  const cableSettings = useCableSettings();
  const exportCableSettings = useExportCableSettings();
  const mooringSettings = useMooringSettings();
  const substationSettings = useSubstationSettings();
  const vesselSettings = useVesselSettings();

  const projectConfiguration = useMemo(() => {
    const items: SettingsItem[] = [
      turbineSettings,
      foundationSettings,
      cableSettings,
      exportCableSettings,
      substationSettings,
      vesselSettings,
      mooringSettings,
    ];
    return [{ items: items }];
  }, [
    turbineSettings,
    foundationSettings,
    cableSettings,
    mooringSettings,
    substationSettings,
    exportCableSettings,
    vesselSettings,
  ]);

  return projectConfiguration;
}

export default function useFeatureSettings() {
  const designToolType = useAtomValue(designToolTypeAtom);
  const featureSettingsOnshore = useFeatureSettingsOnshore();
  const featureSettingsOffshore = useFeatureSettingsOffshore();

  return designToolType === DesignToolMode.Onshore
    ? featureSettingsOnshore
    : featureSettingsOffshore;
}
