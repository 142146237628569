import { projectIdAtom } from "state/pathParams";
import BinIcon from "@icons/24/Bin.svg?react";
import Spinner from "@icons/spinner/Spinner";
import {
  ARTICLE_MULTIPLE_TIMESERIES,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { SecondaryText } from "components/SettingsV2/Shared/styles";
import { useAtomValue, useSetAtom } from "jotai";
import { unwrap } from "jotai/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  uploadedMeanSpeedGridFamily,
  uploadedWRGFileFamily,
  uploadedWindDataFamily,
  uploadedWindTimeseriesFamily,
} from "state/jotai/windStatistics";
import styled from "styled-components";
import {
  CustomWindFileType,
  spatialCalibrationName,
  SpatialCalibrationType,
  WindDataSource,
} from "types/metocean";
import { useToast } from "../../hooks/useToast";
import {
  WindSourceConfiguration,
  _MultipleSourceWindConfiguration,
  _SingleSourceSpatialCalibrationWindConfiguration,
  _SingleSourceWindConfiguration,
  _WindSourceConfiguration,
  isMultipleSourceWindConfiguration,
  isSingleSourceSpatialCalibrationWindConfiguration,
  isSingleSourceWindConfiguration,
} from "../../services/windSourceConfigurationService";
import { inReadOnlyModeSelector } from "../../state/project";
import { toastMessagesAtom } from "../../state/toast";
import {
  useDeleteUploadedWindData,
  useRenameUploadedWindData,
} from "../../state/uploadWindData";
import {
  savingWindConfigurationInProgressAtom,
  uploadedWindDataSourceUsageAtomFamily,
} from "../../state/windSourceConfiguration";
import { UploadedWindData } from "../../state/windStatistics";
import { spaceSmall } from "../../styles/space";
import { IconREMSize, TextSemi } from "../../styles/typography";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import Button from "../General/Button";
import DropdownButton from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import {
  EditableText,
  EditableTextInternalState,
} from "../General/EditableText";
import { Column, Row } from "../General/Layout";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import {
  ContentWrapper,
  ModalContainer,
  SettingButton,
} from "../SettingsV2/Shared/styles";
import MultipleTimeSeries from "./Wind/MultipleTimeSeries";
import SingleTimeSeries from "./Wind/SingleTimeSeries";
import SingleTimeSeriesWithCalibration from "./Wind/SingleTimeSeriesWithCalibration";
import { ContentContainer, HeaderContainer, HeaderWrapper } from "./shared";
import useEnterToSave from "./useEnterToSave";
import DescriptionModal from "./DescriptionModal";
import { idToWindChangelogId } from "components/InputChangelog/const";
import ComponentLastChanged from "./SettingsUsage/ComponentLastChanged";
import FreeText from "./Components/FreeText";
import ProjectResourceUsage from "./SettingsUsage/ProjectResourceUsage";

enum ConfigType {
  SingleTimeseries = "single_timeseries",
  SpatialCalibration = "spatial_calibration",
  MultipleTimeseries = "multiple_timeseries",
}

const CONFIG_TYPE_NAMES = {
  [ConfigType.SingleTimeseries]: "Single time series",
  [ConfigType.SpatialCalibration]: "Single time series + calibration",
  [ConfigType.MultipleTimeseries]: "Multiple time series",
};

const WindDatasetDropdownItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${IconREMSize} {
    visibility: hidden;
  }

  &:hover {
    ${IconREMSize} {
      visibility: visible;
    }
  }
`;

const WindDatasetDropdownItem = ({
  windData,
  onBeforeDeleteWindData,
}: {
  windData: UploadedWindData[0];
  onBeforeDeleteWindData(id: string): void;
}) => {
  const renameUploadedWindDataFile = useRenameUploadedWindData();
  const deleteUploadedWindDataFile = useDeleteUploadedWindData();
  const { error: showError, success: showSuccess } = useToast();
  const nrUsage = useAtomValue(
    uploadedWindDataSourceUsageAtomFamily({
      uploadedWindDataId: windData.id,
    }),
  );

  const changeWindDataName = useCallback(
    (id: string, newName: string) => {
      if (newName === "") {
        return;
      }

      renameUploadedWindDataFile(id, newName).catch(() => {
        showError("Could not rename dataset, please try again.");
      });
    },
    [renameUploadedWindDataFile, showError],
  );

  const deleteWindData = useCallback(
    (id: string) => {
      if (!window.confirm("Are you sure you want to delete this dataset?")) {
        return;
      }

      onBeforeDeleteWindData(id);
      deleteUploadedWindDataFile(id)
        .then(() => {
          showSuccess("Dataset deleted");
        })
        .catch(() => {
          showError("Could not delete dataset, please try again.");
        });
    },
    [
      deleteUploadedWindDataFile,
      onBeforeDeleteWindData,
      showError,
      showSuccess,
    ],
  );

  return (
    <WindDatasetDropdownItemWrapper>
      <EditableTextInternalState
        type="text"
        onEnter={(newName) => {
          changeWindDataName(windData.id, newName);
        }}
        value={windData.name}
        textContainerStyle={{
          maxWidth: "20vw",
          padding: 0,
        }}
        renderText={(title) => <TextSemi title={title}>{title}</TextSemi>}
      />
      <IconREMSize
        height={1.3}
        width={1.3}
        style={{
          cursor: "pointer",
          marginLeft: spaceSmall,
        }}
        title="Delete wind dataset"
        onClick={(e) => {
          e.stopPropagation();
          if (nrUsage > 0) {
            alert(
              `This dataset can not be delete since it is being used in ${nrUsage} wind configuration${nrUsage !== 1 ? "s" : ""}. Remove it from all wind configurations and then try again.`,
            );
            return;
          }
          deleteWindData(windData.id);
        }}
      >
        <BinIcon />
      </IconREMSize>
    </WindDatasetDropdownItemWrapper>
  );
};

const WindSettings = ErrorBoundaryWrapper(
  ({
    windConfiguration,
    windConfigurationName,
    onSave,
    onSaveName,
    onUnmount,
  }: {
    windConfiguration: WindSourceConfiguration;
    windConfigurationName: string;
    onSave: (newConfig: WindSourceConfiguration) => void;
    onSaveName?: (id: string, name: string) => Promise<any>;
    onUnmount?: () => void;
  }) => {
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const isSaving = useAtomValue(savingWindConfigurationInProgressAtom);
    const setUnsavedSettings = useSetAtom(unsavedSettingsState);
    const setToastMessages = useSetAtom(toastMessagesAtom);
    const [title, setTitle] = useState(windConfigurationName);
    const [showFreeText, setShowFreeText] = useState(
      !!windConfiguration.free_text,
    );

    const [description, setDescription] = useState(
      windConfiguration.note ?? "",
    );

    useEffect(() => {
      setTitle(windConfigurationName);
    }, [windConfigurationName]);

    useEffect(() => {
      setDescription(windConfiguration.note ?? "");
      setShowFreeText(!!windConfiguration.free_text);
    }, [windConfiguration]);

    const singleTimeSeriesParseResult = useMemo(() => {
      return _SingleSourceWindConfiguration.safeParse(windConfiguration);
    }, [windConfiguration]);
    const spatialCalibrationParseResult = useMemo(() => {
      return _SingleSourceSpatialCalibrationWindConfiguration.safeParse(
        windConfiguration,
      );
    }, [windConfiguration]);

    const [localWindConfig, setLocalWindConfig] =
      useState<WindSourceConfiguration>(windConfiguration);
    useEffect(() => {
      setLocalWindConfig(windConfiguration);
    }, [windConfiguration]);

    const isReadOnly = useAtomValue(inReadOnlyModeSelector);

    const uploadedWindData = useAtomValue(
      unwrap(
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
      ),
    );

    const uploadedWindTimeSeries = useAtomValue(
      unwrap(
        uploadedWindTimeseriesFamily({
          nodeId: projectId,
        }),
      ),
    );

    const uploadedWRGDataSet = useAtomValue(
      unwrap(
        uploadedWRGFileFamily({
          nodeId: projectId,
        }),
      ),
    );

    const uploadedMeanSpeedGridDataSet = useAtomValue(
      unwrap(
        uploadedMeanSpeedGridFamily({
          nodeId: projectId,
        }),
      ),
    );

    const disableActions = isReadOnly || isSaving;
    const isLoading = localWindConfig.id !== windConfiguration.id;
    const allChangesSaved =
      disableActions || localWindConfig === windConfiguration;
    const [useSpatialCalibration, setUseSpatialCalibration] = useState<boolean>(
      localWindConfig.spatial_calibration !== null,
    );
    const onEnterSaveCallback = useCallback(
      () => onSave(localWindConfig),
      [localWindConfig, onSave],
    );
    useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

    const renameConfig = useCallback(() => {
      if (!onSaveName) return;
      const renameConfigAsync = async () => {
        await onSaveName(localWindConfig.id, title);
      };

      renameConfigAsync();
    }, [onSaveName, localWindConfig, title]);

    const windConfigurationId = windConfiguration.id;

    const initializeWindConfiguration = useCallback(() => {
      if (windConfiguration && windConfigurationId && !localWindConfig) {
        setLocalWindConfig(windConfiguration);
      }
    }, [
      windConfiguration,
      windConfigurationId,
      localWindConfig,
      setLocalWindConfig,
    ]);

    useEffect(() => {
      initializeWindConfiguration();
    }, [windConfigurationId, initializeWindConfiguration]);

    useEffect(() => {
      setUnsavedSettings(!isLoading && !allChangesSaved);
    }, [allChangesSaved, isLoading, setUnsavedSettings]);

    useEffect(() => {
      setUseSpatialCalibration(localWindConfig.spatial_calibration !== null);
    }, [localWindConfig]);

    useEffect(() => {
      return () => {
        if (onUnmount) {
          onUnmount();
        }
      };
    }, [onUnmount]);

    const configType = useMemo(() => {
      return getConfigType(localWindConfig);
    }, [localWindConfig]);

    function getConfigType(windConfiguration: WindSourceConfiguration) {
      if (isMultipleSourceWindConfiguration(windConfiguration)) {
        return ConfigType.MultipleTimeseries;
      } else if (
        isSingleSourceSpatialCalibrationWindConfiguration(windConfiguration)
      ) {
        return ConfigType.SpatialCalibration;
      } else {
        return ConfigType.SingleTimeseries;
      }
    }

    function parseConfig(
      configType: ConfigType,
      localWindConfig: WindSourceConfiguration,
    ) {
      switch (configType) {
        case ConfigType.SingleTimeseries:
          return _SingleSourceWindConfiguration.parse(localWindConfig);
        case ConfigType.SpatialCalibration:
          return _SingleSourceSpatialCalibrationWindConfiguration.parse(
            localWindConfig,
          );
        case ConfigType.MultipleTimeseries:
          return _MultipleSourceWindConfiguration.parse(localWindConfig);
        default:
          throw new Error(`Config not mathcing the type: ${configType}`);
      }
    }

    // Change selected dataset to a built in dataset if user is removing the active dataset
    const onBeforeDeleteWindData = useCallback(
      (id: string) => {
        if (localWindConfig.source.id === id) {
          setLocalWindConfig(
            _WindSourceConfiguration.parse({
              ...localWindConfig,
              calibration: null,
              source: {
                id: WindDataSource.BEST,
                type: "built_in",
              },
            }),
          );
        }
      },
      [localWindConfig],
    );

    // Change selected spatial dataset to a built in dataset if user is removing the active dataset
    const onBeforeDeleteSpatialWindData = useCallback(
      (id: string) => {
        if ((localWindConfig.spatial_calibration as any)?.id === id) {
          setLocalWindConfig(
            _WindSourceConfiguration.parse({
              ...localWindConfig,
              calibration: null,
              spatial_calibration: {
                type: SpatialCalibrationType.GWA,
                value: "absolute",
              },
            }),
          );
        }
      },
      [localWindConfig, setLocalWindConfig],
    );

    const dataSetDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: WindDataSource.BEST,
          name: "Best available",
        },
        {
          value: WindDataSource.NORA3,
          name: "NORA3",
        },
        {
          value: WindDataSource.ERA5,
          name: "ERA5",
        },
        {
          value: WindDataSource.CERRA,
          name: "CERRA",
        },
        ...(uploadedWindData
          ? uploadedWindData
              .filter(
                (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
              )
              .map((d) => ({
                value: d.id,
                name: d.name,
                wrapperStyle: {
                  width: "20rem",
                  paddingRight: "1.2rem",
                },
                renderItem: () => {
                  return (
                    <WindDatasetDropdownItem
                      windData={d}
                      onBeforeDeleteWindData={onBeforeDeleteWindData}
                    />
                  );
                },
              }))
          : [
              {
                name: "Loading custom wind data...",
                icon: <Spinner size="1rem" />,
                value: "loading",
                disabled: true,
              },
            ]),
      ];
    }, [onBeforeDeleteWindData, uploadedWindData]);

    const spatialCalibrationDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: SpatialCalibrationType.GWA,
          name: spatialCalibrationName[SpatialCalibrationType.GWA],
        },
        {
          value: SpatialCalibrationType.NEWA,
          name: spatialCalibrationName[SpatialCalibrationType.NEWA],
        },
        ...(uploadedWindData
          ? uploadedWindData
              .filter(
                (d) =>
                  d.type === CustomWindFileType.MEAN_SPEED_GRID ||
                  d.type === CustomWindFileType.WRG,
              )
              .map((d) => ({
                value: d.id,
                name: d.name,
                wrapperStyle: {
                  width: "20rem",
                  paddingRight: "1.2rem",
                },
                renderItem: () => {
                  return (
                    <WindDatasetDropdownItem
                      windData={d}
                      onBeforeDeleteWindData={onBeforeDeleteSpatialWindData}
                    />
                  );
                },
              }))
          : [
              {
                name: "Loading custom wind data...",
                icon: <Spinner size="1rem" />,
                value: "loading",
                disabled: true,
              },
            ]),
      ];
    }, [onBeforeDeleteSpatialWindData, uploadedWindData]);

    const configTypeDropdownItems = useMemo<DropDownItem[]>(() => {
      return [
        {
          value: ConfigType.SingleTimeseries,
          name: CONFIG_TYPE_NAMES[ConfigType.SingleTimeseries],
        },
        {
          value: ConfigType.SpatialCalibration,
          name: CONFIG_TYPE_NAMES[ConfigType.SpatialCalibration],
        },
        {
          value: ConfigType.MultipleTimeseries,
          name: CONFIG_TYPE_NAMES[ConfigType.MultipleTimeseries],
        },
      ];
    }, []);

    const handleUpdateWindDescription = useCallback(
      (description: string) => {
        setDescription(description);
        onSave({
          ...windConfiguration,
          note: description,
        });
      },
      [windConfiguration, onSave],
    );

    return (
      <ModalContainer>
        <HeaderWrapper>
          <HeaderContainer>
            <Column
              style={{
                width: "100%",
              }}
            >
              <EditableText
                type="text"
                smallInput={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onEnter={renameConfig}
                onCancel={() => {
                  setTitle(windConfigurationName);
                }}
                textContainerStyle={{
                  maxWidth: "20vw",
                  padding: 0,
                }}
                renderText={(title) => (
                  <h3
                    style={{
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "60rem",
                    }}
                    title={title}
                  >
                    {title}
                  </h3>
                )}
                disabled={isReadOnly}
              />{" "}
              <Row
                style={{
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <ComponentLastChanged
                  changelogId={idToWindChangelogId(windConfiguration.id)}
                  nodeId={projectId}
                  category={"project"}
                />
                <ProjectResourceUsage
                  resourceType="WIND_CONFIGURATION"
                  resourceId={windConfiguration.id}
                />
              </Row>
            </Column>
            <DescriptionModal
              disabled={isReadOnly}
              defaultValue={description}
              updateDescription={handleUpdateWindDescription}
              subtitle={
                <div>
                  <p>
                    The description will be visible for Admins and Editors in
                    projects with access to this configuration.
                  </p>
                </div>
              }
            />
          </HeaderContainer>
          <SettingButton
            style={{
              justifyContent: "flex-end",
              position: "absolute",
              right: "2.4rem",
              top: "14.4rem",
            }}
          >
            {!allChangesSaved && (
              <>
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Cancel"
                  buttonType="secondary"
                  onClick={() => {
                    setLocalWindConfig(windConfiguration);
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                />
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Save changes"
                  onClick={() => {
                    if (
                      localWindConfig.source.type === "custom_multiple" &&
                      localWindConfig.source.data.length < 2
                    ) {
                      setToastMessages((tm) => [
                        ...tm,
                        {
                          text: "You need to add at least two datasets to create a configuration with multiple time series",
                          timeout: 5000,
                        },
                      ]);
                      return;
                    }
                    setToastMessages((tm) => [
                      ...tm,
                      {
                        text: "Saving...",
                        timeout: 1000,
                      },
                    ]);
                    try {
                      const parsedConfig = parseConfig(
                        configType,
                        localWindConfig,
                      );
                      onSave({
                        ...parsedConfig,
                        name: title,
                      });
                    } catch (error) {
                      setToastMessages((tm) => [
                        ...tm,
                        {
                          text: "Could not save. Invalid configuration type",
                          timeout: 5000,
                        },
                      ]);
                    }
                  }}
                />
              </>
            )}
          </SettingButton>
        </HeaderWrapper>

        <ContentWrapper
          style={{
            boxSizing: "border-box",
            overflowY: "auto",
          }}
        >
          <ContentContainer>
            <Column>
              <Row
                style={{
                  margin: 0,
                  alignItems: "center",
                }}
              >
                <SecondaryText>Configuration type</SecondaryText>
                {configType === ConfigType.MultipleTimeseries && (
                  <HelpLink article={ARTICLE_MULTIPLE_TIMESERIES} />
                )}
              </Row>

              <Row>
                <DropdownButton
                  items={configTypeDropdownItems}
                  style={{
                    height: "fit-content",
                    width: "32rem",
                  }}
                  disabled={disableActions}
                  onSelectItem={(id) => {
                    const t = id as ConfigType;
                    if (t === ConfigType.SingleTimeseries) {
                      setLocalWindConfig(
                        _SingleSourceWindConfiguration.parse({
                          ...localWindConfig,
                          spatial_calibration: null,
                          source: !isSingleSourceWindConfiguration(
                            localWindConfig,
                          )
                            ? {
                                id: WindDataSource.BEST,
                                type: "built_in",
                              }
                            : localWindConfig.source,
                        }),
                      );
                    } else if (t === ConfigType.SpatialCalibration) {
                      setLocalWindConfig(
                        _SingleSourceSpatialCalibrationWindConfiguration.parse({
                          ...localWindConfig,
                          source: !isSingleSourceWindConfiguration(
                            localWindConfig,
                          )
                            ? {
                                id: WindDataSource.BEST,
                                type: "built_in",
                              }
                            : localWindConfig.source,
                          spatial_calibration: {
                            type: SpatialCalibrationType.GWA,
                            value: "absolute",
                          },
                        }),
                      );
                    } else if (t === ConfigType.MultipleTimeseries) {
                      setLocalWindConfig(
                        _MultipleSourceWindConfiguration.parse({
                          ...localWindConfig,
                          spatial_calibration: null,
                          calibration: null,
                          source: {
                            type: "custom_multiple",
                            data: [],
                          },
                        }),
                      );
                    }
                  }}
                  buttonText={CONFIG_TYPE_NAMES[configType]}
                  selectedItemValue={configType}
                />
              </Row>
            </Column>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 1px 4px 0px #00000029",
                padding: "1.6rem",
              }}
            >
              {configType === ConfigType.SingleTimeseries && (
                <SingleTimeSeries
                  dataSetDropdownItems={dataSetDropdownItems}
                  localWindConfig={_SingleSourceWindConfiguration.parse(
                    localWindConfig,
                  )}
                  originalWindConfig={
                    singleTimeSeriesParseResult.success
                      ? singleTimeSeriesParseResult.data
                      : undefined
                  }
                  disableActions={disableActions}
                  setLocalWindConfig={setLocalWindConfig}
                  uploadedWindData={uploadedWindData}
                  uploadedTimeSeries={uploadedWindTimeSeries}
                  nodeId={projectId}
                />
              )}
              {configType === ConfigType.SpatialCalibration && (
                <SingleTimeSeriesWithCalibration
                  dataSetDropdownItems={dataSetDropdownItems}
                  spatialCalibrationDropdownItems={
                    spatialCalibrationDropdownItems
                  }
                  localWindConfig={_SingleSourceSpatialCalibrationWindConfiguration.parse(
                    localWindConfig,
                  )}
                  originalWindConfig={
                    spatialCalibrationParseResult.success
                      ? spatialCalibrationParseResult.data
                      : undefined
                  }
                  disableActions={disableActions}
                  setLocalWindConfig={setLocalWindConfig}
                  uploadedWindData={uploadedWindData}
                  uploadedWRGGrids={uploadedWRGDataSet}
                  uploadedMeanSpeedGrids={uploadedMeanSpeedGridDataSet}
                  useSpatialCalibration={useSpatialCalibration}
                  nodeId={projectId}
                />
              )}
              {configType === ConfigType.MultipleTimeseries && (
                <MultipleTimeSeries
                  disableActions={disableActions}
                  windDataSets={uploadedWindTimeSeries}
                  localWindConfig={_MultipleSourceWindConfiguration.parse(
                    localWindConfig,
                  )}
                  setLocalWindConfig={setLocalWindConfig}
                />
              )}
            </div>

            <Row alignCenter>
              <FreeText
                showFreeText={showFreeText}
                setShowFreeText={setShowFreeText}
                value={localWindConfig.free_text ?? ""}
                onChange={(value) => {
                  setLocalWindConfig({
                    ...localWindConfig,
                    free_text: value,
                  });
                }}
                disabled={disableActions}
              />
            </Row>
          </ContentContainer>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default WindSettings;
