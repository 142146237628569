import { z } from "zod";
import { Angle, TurbineDistance } from "components/Units/units";
import { atomLocalStorage } from "utils/jotai";

export const ELLIPSIS_KEY = "vind:turbine-ellipses";
export type EllipsesFeature = z.infer<typeof _EllipsesProps>;

const _EllipsesProps = z.object({
  show: z.boolean().default(true),
  minorAxis: TurbineDistance.zod.default({
    value: 100,
    unit: "m",
  }),
  majorAxis: TurbineDistance.zod.default({
    value: 100,
    unit: "m",
  }),
  orientation: Angle.zod.default({ value: 0, unit: "deg" }),
});

export const ellipsesPerTurbineAtom = atomLocalStorage(
  ELLIPSIS_KEY,
  undefined,
  z.record(z.string(), _EllipsesProps),
);
