import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useMemo, useState } from "react";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SecondaryText,
  TextEllipsis,
} from "../../../style";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import { organisationFoundationResourceState } from "components/Organisation/Library/state";
import { FoundationType } from "types/foundations";
import { TableRowsPlaceholder } from "../../shared/TablePlaceholder";
import { SkeletonBlock } from "components/Loading/Skeleton";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";

const WizardContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  height: fit-content;
  gap: 1.2rem;
  background: white;
`;

export default function FoundationModal({
  existingFoundations,
  onSave,
  isSaving,
}: {
  existingFoundations: string[];
  onSave: (foundations: FoundationType[]) => void;
  isSaving?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const foundationInOrgLoadable = useAtomValue(
    loadable(
      organisationFoundationResourceState({
        organisationId,
      }),
    ),
  );

  const foundationInOrg = useMemo(
    () =>
      foundationInOrgLoadable.state === "hasData"
        ? foundationInOrgLoadable.data
        : [],
    [foundationInOrgLoadable],
  );

  const [name, onNameChange] = useTextInput("");
  const [selectedFoundation, setSelectedFoundation] = useState<
    FoundationType[]
  >([]);
  const [filteredFoundation, setFilteredFoundation] = useState<
    FoundationType[]
  >([]);

  useEffect(() => {
    const fuse = new Fuse(foundationInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredFoundation(name.length > 0 ? results : foundationInOrg);
  }, [name, foundationInOrg]);

  const toggleFoundationSelection = (foundation: FoundationType) => {
    setSelectedFoundation((prevSelectedFoundation) => {
      if (prevSelectedFoundation.find((t) => t.id === foundation.id)) {
        return prevSelectedFoundation.filter((g) => g.id !== foundation.id);
      } else {
        return [...prevSelectedFoundation, foundation];
      }
    });
  };

  return (
    <WizardContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {foundationInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredFoundation.map((g) => {
            const selected = selectedFoundation.some((st) => st.id === g.id);
            const alreadyExist = existingFoundations.some((id) => id === g.id);
            return (
              <ContentTableRow
                key={g.id}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => !alreadyExist && toggleFoundationSelection(g)}
                disabled={selected || alreadyExist}
              >
                <SVGWrapper size={1.4}>
                  <FoundationIcon />
                </SVGWrapper>
                <TextEllipsis
                  style={{
                    margin: 0,
                    marginRight: "auto",
                  }}
                >
                  {g.name}
                </TextEllipsis>
                {alreadyExist ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Already connected
                  </SecondaryText>
                ) : selected ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Selected
                  </SecondaryText>
                ) : (
                  <></>
                )}
                {!alreadyExist && !selected && (
                  <Button
                    buttonType="text"
                    text="Select"
                    icon={<Add />}
                    onClick={() => {}}
                    size="small"
                    style={{
                      padding: "0.2rem 1.2rem",
                    }}
                  />
                )}
              </ContentTableRow>
            );
          })}
        </ContentTableColumn>
      </ResultContainer>
      {selectedFoundation.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedFoundation.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name}
                    onDeselect={() => toggleFoundationSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedFoundation)}
              disabled={isSaving}
              buttonType="primary"
            />
          </Row>
        </>
      )}
      {isSaving && (
        <Row
          style={{
            padding: "0 1.2rem 1.2rem",
            alignItems: "center",
          }}
        >
          <SkeletonBlock
            style={{
              height: "2rem",
            }}
          />
        </Row>
      )}
    </WizardContainer>
  );
}
