import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  listUsersInOrganisation,
  listUsersWithAccessToNodeTree,
  Organisation,
} from "./service";
import { OrganisationUser } from "../../types/customer";
import { organisationIdAtom } from "../../state/pathParams";
import { listOrganisations } from "services/customerAPI";
import { z } from "zod";
import {
  GroupMembership,
  GroupNodeAccess,
  UserNodeAccess,
} from "components/Organisation/Groups/types";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { isDefined } from "utils/predicates";
import { admin_usersOrganisationResourcesAtomFamily } from "components/Organisation/OrganisationRightSide/content/hooks/useUserOrganisationResourcesCrud";
import { OrgResource } from "components/Organisation/OrganisationRightSide/types";
import {
  allOrganisationGroupMembershipsAtomFamily,
  groupsInNodeAndSubnodesAtomFamily,
} from "components/Organisation/Groups/state";
import { dedup } from "utils/utils";
import { getFolderChildNodes } from "business/customer/utils";
import { userOrganisationAccessSelectorFamily } from "state/user";

export const organisationsState = atomFromFn<Promise<Organisation[]>>(() =>
  listOrganisations(),
);

export const currentOrganisationSelector = atom<
  Promise<Organisation | undefined>
>(async (get) => {
  const orgs = await get(organisationsState);
  const organisationId = get(organisationIdAtom);
  return orgs.find((org) => org.id === organisationId);
});

export const usersInOrganisationState = atomFamily((orgId: string) =>
  atomFromFn<Promise<OrganisationUser[]>>(async (get) => {
    const userAccess = get(
      userOrganisationAccessSelectorFamily({ organisationId: orgId }),
    );

    // Check if the user has at least member access
    if (userAccess && userAccess >= 1) {
      return listUsersInOrganisation(orgId);
    } else {
      // Return an empty array for guests or users without sufficient permissions
      return [];
    }
  }),
);

export const usersNodeAccessInNodeAndSubnodesAtomFamily = atomFamily(
  ({ organisationId, nodeId }: { organisationId: string; nodeId: string }) =>
    atomFromFn<Promise<UserNodeAccess[]>>(() => {
      return listUsersWithAccessToNodeTree(organisationId, nodeId);
    }),
);

export type Admin_MemberWithAccess = OrganisationUser & {
  userProjects: (UserNodeAccess | GroupNodeAccess)[];
  orgResourceAccess: OrgResource[];
  groupMemberships: GroupMembership[];
};

/**
 * Get all users in an organisation with their access to projects, resources and groups.
 * Projects will contain all projects the user has access to, including projects they have access to through groups and folders.
 */
export const admin_extendedUsersInOrganisationSelector = atom<
  Promise<Admin_MemberWithAccess[]>
>(async (get) => {
  const organisationId = get(organisationIdAtom);
  if (!organisationId) {
    return [];
  }

  const oid = { organisationId };
  const nid = { nodeId: organisationId, organisationId };
  const [
    users,
    allNodes,
    groupsInNodeAndSubnode,
    usersAccessInNodeAndSubnode,
    allUserAndGroupResourceAccesses,
    allGroupMemberships,
  ] = await Promise.all([
    get(usersInOrganisationState(organisationId)),
    get(admin_nodesInOrgSelectorFamily(oid)),
    get(groupsInNodeAndSubnodesAtomFamily(nid)),
    get(usersNodeAccessInNodeAndSubnodesAtomFamily(nid)),
    get(admin_usersOrganisationResourcesAtomFamily(oid)),
    get(allOrganisationGroupMembershipsAtomFamily(oid)),
  ]);

  return users.map((user) => {
    const userGroupMemberships = allGroupMemberships.filter(
      (m) => m.user_id === user.user_id,
    );

    const groupNodes = userGroupMemberships.flatMap((groupMembership) => {
      return groupsInNodeAndSubnode.filter(
        (g) => g.group_id === groupMembership.group_id,
      );
    });

    const thisUsersNodeAccesses = usersAccessInNodeAndSubnode.filter(
      (m) => m.user_id === user.user_id,
    );

    const allUserNodes = dedup(
      [...thisUsersNodeAccesses, ...groupNodes],
      (node) => node.node_id,
    )
      .flatMap((nodeMemberShip) => {
        const node = allNodes.find((n) => n.id === nodeMemberShip.node_id);
        if (!node) {
          return;
        }

        if (node.type === "project") {
          return {
            ...nodeMemberShip,
            node,
          };
        }

        if (node.type === "folder") {
          return getFolderChildNodes(allNodes, node).map((n) => ({
            ...nodeMemberShip,
            node: n,
          }));
        }
      })
      .filter(isDefined);
    const uniqueUserNodes = dedup(allUserNodes, (node) => node.node.id);

    const userExplicitResourceAccess = allUserAndGroupResourceAccesses.filter(
      (f) => f.user_id === user.user_id,
    );
    const groupResourceAccess = allUserAndGroupResourceAccesses.filter((f) =>
      userGroupMemberships.find(
        (groupMembership) => f.group_id === groupMembership.group_id,
      ),
    );

    const uniqueUserResourceAccess = dedup(
      userExplicitResourceAccess.concat(groupResourceAccess),
      (f) => f.resource_name,
    );
    return {
      ...user,
      userProjects: uniqueUserNodes,
      orgResourceAccess: uniqueUserResourceAccess,
      groupMemberships: userGroupMemberships,
    };
  });
});

export type Member_MemberWithGroupMemberships = OrganisationUser & {
  groupMemberships: GroupMembership[];
};

/**
 * Get all users in an organisation with their access to groups.
 */
export const member_extendedUsersInOrganisationSelector = atom<
  Promise<Member_MemberWithGroupMemberships[]>
>(async (get) => {
  const organisationId = get(organisationIdAtom);
  if (!organisationId) {
    return [];
  }

  const users = await get(usersInOrganisationState(organisationId));

  const allGroupMemberships = await get(
    allOrganisationGroupMembershipsAtomFamily({
      organisationId,
    }),
  );

  return users.map((user) => {
    const userGroupMemberships = allGroupMemberships.filter(
      (m) => m.user_id === user.user_id,
    );

    return {
      ...user,
      groupMemberships: userGroupMemberships,
    };
  });
});

const memberTabNames = [
  "All members",
  "Groups",
  "Pending Invitations",
] as const;

export const _MemberTabName = z.enum(memberTabNames);

export type MemberTabName = (typeof memberTabNames)[number];

export const selectedMemberTabState = atom<MemberTabName | undefined>(
  undefined,
);

export const inviteToOrganisationOpenAtom = atom<boolean>(false);
export const inviteToOrganisationDefaultEmailValue = atom<string>("");
export const inviteToOrganisationDefaultSelectedProjectIds = atom<string[]>([]);
