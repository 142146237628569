import DownloadIcon from "@icons/24/Download.svg?react";
import Spinner from "@icons/spinner/Spinner";
import { getConfiguration } from "analysis/inputs";
import {
  getAnalysisProgress,
  getAnalysisResponse,
  getAverageMonthlyEnergy,
} from "analysis/output";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { getThrowStoppedReason } from "analysis/warnings";
import { SkeletonText } from "components/Loading/Skeleton";
import { stringify } from "csv-stringify/sync";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "../../../hooks/useToast";
import { colors } from "../../../styles/colors";
import { downloadText } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { formatPercent } from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  fetchAnalysisTimeseriesFile,
  PresignedUrlResponse,
} from "functions/production";
import AnalysisOk from "components/Analysis/AnalysisOk";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const useNumbers = () => {
  const { triggerId } = useDashboardContext();
  const monthlyEnergy = useAtomValue(getAverageMonthlyEnergy(triggerId));
  const config = useAtomValue(getConfiguration(triggerId));
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  return useMemo(
    () => ({
      productionPerMonth: monthlyEnergy.map((v) => v / 1e3),
      precision: config.wakeAnalysis.precision,
      analysisProgress: analysisProgress,
    }),
    [analysisProgress, config.wakeAnalysis.precision, monthlyEnergy],
  );
};

const MonthlyGraph = () => {
  const { triggerId } = useDashboardContext();
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  useAtomValue(getThrowStoppedReason(triggerId));

  const stopped = stoppedReason ?? analysisStoppedReason;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return <MonthlyGraphInner />;
};

const MonthlyGraphInner = () => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { analysisProgress, productionPerMonth } = useNumbers();

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current || !productionPerMonth) return;
    setIsLoaded(false);

    const total = productionPerMonth.reduce((acc, n) => acc + n);
    const labels = productionPerMonth
      .map((n) => ({ production: Math.round(n), fraction: n / total }))
      .map(
        ({ production, fraction }) =>
          `${production} (${formatPercent(fraction)})`,
      );

    var data = [
      {
        x: MONTHS,
        y: productionPerMonth,
        type: "bar" as const,
        text: labels,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        opacity: 0.8,
        marker: {
          color: colors.hoverSelected,
          line: {
            color: colors.brand,
            width: 1.5,
          },
        },
      },
    ];
    var layout = {
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 200,
      yaxis: {
        title: "GWh",
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, productionPerMonth]);

  if (!productionPerMonth) {
    if (analysisProgress && isLoaded)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return <div ref={graphRef} />;
};

const MenuItems = () => {
  const { warning } = useToast();
  const { productionPerMonth, precision } = useNumbers();
  const { triggerId, projectId, park } = useDashboardContext();
  const analysis = useAtomValue(getAnalysisResponse(triggerId));
  const [timeseriesLoading, setTimeseriesLoading] = useState<boolean>(false);
  const [showDetailedDownload, setShowDetailedDownload] =
    useState<boolean>(false);

  const monthlyLoading = !productionPerMonth;

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === "d") {
        setShowDetailedDownload(true);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      setShowDetailedDownload(false);
    };
  }, [setShowDetailedDownload]);

  const downloadMonthly = useCallback(() => {
    if (!productionPerMonth) {
      warning("Data is still loading, please wait a few seconds.");
      return;
    }
    const header = ["month", "gwh"];
    const rows = productionPerMonth.map((v, i) => [MONTHS[i], v.toFixed(1)]);
    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "monthly_production.csv");
  }, [productionPerMonth, warning]);

  const downloadTimeseries = useCallback(
    (detailed: boolean) => {
      if (!analysis) {
        warning("Analysis is still loading, please wait a few seconds.");
      }
      const name = detailed
        ? `${park.properties.name}_timeseries_detailed.csv`
        : `${park.properties.name}_timeseries.csv`;
      setTimeseriesLoading(true);
      fetchAnalysisTimeseriesFile({
        nodeId: projectId,
        id: analysis.id,
        version: analysis.version,
        detailed,
      })
        .then((data: PresignedUrlResponse) => {
          const a = document.createElement("a");
          a.href = data.url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .finally(() => {
          setTimeseriesLoading(false);
        });
    },
    [warning, analysis, projectId, park],
  );
  return (
    <AnalysisOk pid={triggerId} fallback={null}>
      <MenuItem
        name={"Monthly energy"}
        disabled={monthlyLoading}
        icon={monthlyLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />}
        onClick={() => downloadMonthly()}
      />
      <MenuItem
        name={
          precision !== "high"
            ? "Full timeseries (High precision recommended)"
            : "Full timeseries"
        }
        icon={timeseriesLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />}
        disabled={precision === "fast" || timeseriesLoading || !analysis} // Only disable for "fast"
        onClick={() => downloadTimeseries(false)}
      />
      {showDetailedDownload && (
        <MenuItem
          name={"Detailed timeseries (for debugging)"}
          icon={
            timeseriesLoading ? <Spinner size="0.8rem" /> : <DownloadIcon />
          }
          disabled={precision === "fast" || timeseriesLoading || !analysis} // Only disable for "fast"
          onClick={() => downloadTimeseries(true)}
        />
      )}
    </AnalysisOk>
  );
};

export const MonthlyProductionWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Monthly production graph"
      id="Monthly graph"
      menuItems={<MenuItems />}
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <MonthlyGraph />
    </SafeCard>
  );
};
