import { useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import React, { useEffect, useMemo, useState } from "react";
import { FoundationType, FoundationTypeIds } from "../../../types/foundations";
import {
  isFloater,
  isSimpleMonopile,
  isSimpleJacket,
  isDetailedMonopile,
  isDetailedJacket,
} from "../../../utils/predicates";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { inReadOnlyModeSelector } from "../../../state/project";

import {
  ColumnSection,
  ContentWrapper,
  ModalContainer,
  SettingButton,
} from "../../SettingsV2/Shared/styles";
import Button from "../../General/Button";
import styled from "styled-components";
import { unsavedSettingsState } from "../../SettingsV2/Shared/state";

import { orgFoundationManageAccessSelector } from "state/user";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useSetAtom } from "jotai";
import { Row } from "../../General/Layout";
import { useProjectFoundationCrud } from "./useProjectFoundationCrud";
import BasicSettings from "./Components/BasicSettings";
import Header from "./Components/Header";
import DetailedMonopileSettings from "./Components/FoundationTypes/DetailedMonopileSettings";
import FloatingFoundationSettings from "./Components/FoundationTypes/FloatingFoundationSettings";
import DetailedJacketSettings from "./Components/FoundationTypes/DetailedJacketSettings";
import FreeText from "../Components/FreeText";
import DesignParameters from "./Components/DesignParameters";
import { objectEquals } from "utils/utils";
import { mergePreservingChanges } from "utils/utils";
import usePrevious from "hooks/usePrevious";

const FoundationGraph = React.lazy(() => import("../FoundationGraph"));

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  flex: 1;
`;

export const FOUNDATION_TYPE_NAMES: Record<FoundationTypeIds, string> = {
  [FoundationTypeIds.SemiCentralType]: "Semi, central turbine",
  [FoundationTypeIds.SemiPeripheralType]: "Semi, offset turbine",
  [FoundationTypeIds.SparType]: "Spar",
  [FoundationTypeIds.MonopileType]: "Simple monopile",
  [FoundationTypeIds.JacketType]: "Jacket",
  [FoundationTypeIds.DetailedMonopileType]: "Site-specific monopile",
  [FoundationTypeIds.DetailedJacketType]: "Site-specific jacket",
};

export const kWtoMW = 1 / 1000;
export const kgToTonne = 1 / 1000;

const excludedKeys = ["createdAt", "updatedAt", "description"];

type FoundationSettingsProps = {
  foundation: FoundationType;
  isDefault: boolean;
  onSave: (
    foundation: FoundationType,
    onlyUpdatingDescription?: boolean,
  ) => void;
  duplicate: (foundation: FoundationType) => void;
  nodeId: string;
  hasEditAccess: boolean;
  isLibraryFoundation?: boolean;
};

const FoundationSettings = ErrorBoundaryWrapper(
  ({
    foundation,
    isDefault,
    onSave,
    duplicate,
    nodeId,
    hasEditAccess,
    isLibraryFoundation,
  }: FoundationSettingsProps) => {
    const projectId = useAtomValue(projectIdAtom)!;
    const inLibraryContext = !projectId;
    const organisationId = useAtomValue(organisationIdAtom) ?? "";

    const commentCategory = isLibraryFoundation
      ? "org_foundation_manage"
      : "project";

    const isReadOnly = useAtomValue(inReadOnlyModeSelector);
    const setUnsavedSettings = useSetAtom(unsavedSettingsState);
    const hasOrgFoundationAccess = useAtomValue(
      orgFoundationManageAccessSelector,
    );
    const isDisabled =
      isReadOnly || isDefault || !hasEditAccess || !!isLibraryFoundation;

    const [tempFoundation, setTempFoundation] =
      useState<FoundationType>(foundation);

    const prevFoundation = usePrevious(foundation);
    useEffect(() => {
      if (foundation) {
        setTempFoundation((prevTempFoundation) => {
          if (!prevTempFoundation || !prevFoundation) return foundation;
          return mergePreservingChanges(
            prevFoundation,
            foundation,
            prevTempFoundation,
          );
        });
      }
    }, [foundation, setTempFoundation, prevFoundation]);

    const hasChanged = useMemo(() => {
      if (!foundation || !tempFoundation) return false;
      return !objectEquals(foundation, tempFoundation, excludedKeys);
    }, [foundation, tempFoundation]);

    const [isValid, setIsValid] = useState<boolean>(true);
    const [showFreeText, setShowFreeText] = useState(!!foundation.info);

    const { isLoadingProject } = useProjectFoundationCrud();

    useEffect(() => {
      setShowFreeText(!!foundation.info);
    }, [foundation]);

    useEffect(() => {
      setUnsavedSettings(hasChanged);
    }, [hasChanged, setUnsavedSettings]);

    return (
      <ModalContainer>
        {!inLibraryContext && (
          <Header
            foundation={foundation}
            tempFoundation={tempFoundation}
            isDefault={isDefault}
            isDisabled={isDisabled}
            nodeId={nodeId}
            organisationId={organisationId}
            projectId={projectId}
            isLibraryFoundation={isLibraryFoundation}
            duplicate={duplicate}
            isLoadingProject={isLoadingProject}
            hasEditAccess={hasEditAccess}
            hasChanged={hasChanged}
            setTempFoundation={setTempFoundation}
            onSave={onSave}
            hasOrgFoundationAccess={hasOrgFoundationAccess}
          />
        )}

        <ContentWrapper
          style={{
            overflowY: "auto",
            boxSizing: "border-box",
            padding: inLibraryContext ? "1.6rem 0" : undefined,
          }}
        >
          <ColumnContent>
            {inLibraryContext &&
              !isDefault &&
              !(!hasEditAccess || !hasChanged) && (
                <SettingButton
                  style={{
                    position: "absolute",
                    top: "19rem",
                    right: "2.4rem",
                  }}
                >
                  <Button
                    disabled={!hasEditAccess || !hasChanged}
                    text="Cancel"
                    buttonType="secondary"
                    onClick={() => {
                      setTempFoundation(foundation);
                    }}
                    style={{
                      marginLeft: "auto",
                    }}
                  />
                  <Button
                    disabled={!hasEditAccess || !hasChanged}
                    text="Save changes"
                    onClick={() => {
                      onSave(tempFoundation);
                    }}
                  />
                </SettingButton>
              )}
            <BasicSettings
              tempFoundation={tempFoundation}
              setTempFoundation={setTempFoundation}
              nodeId={nodeId}
              commentCategory={commentCategory}
              isDefault={isDefault}
              foundationName={foundation.name}
              disabled={isDisabled}
            />
            {(isSimpleJacket(tempFoundation) ||
              isSimpleMonopile(tempFoundation)) && (
              <DesignParameters
                tempFoundation={tempFoundation}
                setTempFoundation={setTempFoundation}
                isDisabled={isDisabled}
                nodeId={nodeId}
                commentCategory={commentCategory}
                setIsValid={setIsValid}
                foundation={foundation}
              />
            )}
            {isFloater(tempFoundation) && (
              <FloatingFoundationSettings
                originalFoundation={
                  isFloater(foundation) ? foundation : undefined
                }
                tempFoundation={tempFoundation}
                setTempFoundation={setTempFoundation}
                disabled={isDisabled}
                nodeId={nodeId}
              />
            )}
            {isDetailedMonopile(tempFoundation) && (
              <DetailedMonopileSettings
                originalFoundation={
                  isDetailedMonopile(foundation) ? foundation : undefined
                }
                tempFoundation={tempFoundation}
                setTempFoundation={setTempFoundation}
                disabled={isDisabled}
                nodeId={nodeId}
              />
            )}
            {isDetailedJacket(tempFoundation) && (
              <DetailedJacketSettings
                originalFoundation={
                  isDetailedJacket(foundation) ? foundation : undefined
                }
                tempFoundation={tempFoundation}
                setTempFoundation={setTempFoundation}
                disabled={isDisabled}
                nodeId={nodeId}
              />
            )}
            {(isSimpleJacket(tempFoundation) ||
              isSimpleMonopile(tempFoundation)) &&
              isValid && (
                <React.Suspense fallback={null}>
                  <FoundationGraph
                    scalingEquation={tempFoundation.scalingEquation}
                    minRatedPower={tempFoundation.minRatedPower}
                    maxRatedPower={tempFoundation.maxRatedPower}
                    minWaterDepth={tempFoundation.minWaterDepth}
                    maxWaterDepth={tempFoundation.maxWaterDepth}
                  />
                </React.Suspense>
              )}
            {!isValid && (
              <SimpleAlert text={"Invalid weight equation"} type={"error"} />
            )}
            <ColumnSection>
              <Row>
                <FreeText
                  showFreeText={showFreeText}
                  setShowFreeText={setShowFreeText}
                  value={tempFoundation.info ?? ""}
                  onChange={(value) => {
                    setTempFoundation({
                      ...tempFoundation,
                      info: value,
                    });
                  }}
                  disabled={isDisabled}
                />
              </Row>
            </ColumnSection>
          </ColumnContent>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default FoundationSettings;
