import { fetchEnhancerWithToken, fetchSchemaWithToken } from "services/utils";
import {
  AutosaveRequestEntry,
  SaveRequestEntry,
  _AutosaveRequestEntry,
  _SaveRequestEntry,
} from "./type";
import { ProjectFeature } from "types/feature";
import { z } from "zod";
import { _Feature, _FeatureCollection } from "utils/geojson/geojson";

export async function listAutoSavedFeatureHistory({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}): Promise<AutosaveRequestEntry[]> {
  const res = await fetchSchemaWithToken(
    _AutosaveRequestEntry.array(),
    `/api/autosave/${projectId}/${branchId}`,
    {
      method: "get",
    },
  );
  return res;
}

export async function listSavedFeatureHistory({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}): Promise<SaveRequestEntry[]> {
  const res = await fetchSchemaWithToken(
    _SaveRequestEntry.array(),
    `/api/autosave/save/${projectId}/${branchId}`,
    {
      method: "get",
    },
  );
  return res;
}

export async function postSaveFeatureHistory({
  projectId,
  branchId,
  name,
}: {
  projectId: string;
  branchId: string;
  name: string;
}): Promise<SaveRequestEntry> {
  const res = await fetchSchemaWithToken(
    _SaveRequestEntry,
    `/api/autosave/save/${projectId}/${branchId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    },
  );
  return res;
}

export async function deleteSaveFeatureHistory({
  projectId,
  branchId,
  timestamp,
}: {
  projectId: string;
  branchId: string;
  timestamp: number;
}): Promise<void> {
  await fetchEnhancerWithToken(
    `/api/autosave/save/${projectId}/${branchId}/${timestamp}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function editSaveFeatureHistory({
  projectId,
  branchId,
  name,
  timestamp,
}: {
  projectId: string;
  branchId: string;
  name: string;
  timestamp: number;
}): Promise<SaveRequestEntry> {
  const res = await fetchSchemaWithToken(
    _SaveRequestEntry,
    `/api/autosave/save/${projectId}/${branchId}/${timestamp}`,
    {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    },
  );
  return res;
}

export async function getSavedFeatureHistory(autoSave: {
  nodeId: string;
  branchId: string;
  timestamp: number;
}): Promise<ProjectFeature[]> {
  const signedUrl = await fetchSchemaWithToken(
    z.string(),
    `/api/autosave/${autoSave.nodeId}/${autoSave.branchId}/${autoSave.timestamp}`,
    {
      method: "get",
    },
  );

  const savedFeaturesRes = await fetch(signedUrl, {
    method: "get",
  });

  if (!savedFeaturesRes.ok) throw new Error("Not able to fetch saved features");

  const savedFeaturesJson = await savedFeaturesRes.json();

  const savedFeatures = _Feature
    .array()
    .parse(savedFeaturesJson) as ProjectFeature[];

  return savedFeatures;
}
