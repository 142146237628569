import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { LibraryResourceType } from "components/Organisation/Library/types";
import { getNodesWithMissingParents } from "./useOrganisationFolderCrud";
import {
  turbineResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  analysisResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import {
  isUserNodeAccessWithMeta,
  isGroupNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { isDefined } from "utils/predicates";
import { ACCESS_ROLE_TO_NUMBER } from "state/user";
import { UserAccessRole } from "types/user";
import { getGroupsAndUsersWithAccessToNode } from "components/Organisation/Groups/service";
import { RESET } from "jotai/utils";
import { useNodesInOrganisationState } from "./useNodesInOrganisationState";

interface LibraryResource {
  resource_id: string;
  resource_name: string;
  type: LibraryResourceType;
}

export type MoveNodeUserAccess = {
  from: UserAccessRole | "No access";
  to: UserAccessRole | "No access";
  user_id: string;
};

export type MoveNodeGroupAccess = {
  from: UserAccessRole | "No access";
  to: UserAccessRole | "No access";
  group_id: string;
  group_name: string;
};

export type LibraryResourceConsequences = {
  added: LibraryResource[];
  removed: LibraryResource[];
};

export interface MoveNodeConsequences {
  library_resources: LibraryResourceConsequences;
  group_accesses: MoveNodeGroupAccess[];
  user_accesses: MoveNodeUserAccess[];
}

function getHighestPriorityAccess<
  T extends { node_id: string; resource_name: UserAccessRole },
>(accesses: T[], nodeId: string): T {
  if (accesses.length === 0) throw new Error("User has no access to this node");

  const accessDirectlyOnNode = accesses.find((a) => a.node_id === nodeId);
  if (accessDirectlyOnNode) return accessDirectlyOnNode;

  // admin > editor > viewer
  const highestInheritedAccess = accesses.sort((a, b) => {
    if (a.resource_name === "admin") return -1;
    if (b.resource_name === "admin") return 1;
    if (a.resource_name === "editor") return -1;
    if (b.resource_name === "editor") return 1;
    return 0;
  })[0];
  return highestInheritedAccess;
}

export default function useExplainNodeMove() {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const { loadedState: storedNodes } =
    useNodesInOrganisationState(organisationId);

  const allTopLevelFolders = useAtomValue(
    getNodesWithMissingParents({
      organisationId: organisationId ?? "",
    }),
  );

  const explainResourceConsequences = useJotaiCallback(
    async (get, set, nodeId: string, parentId: string) => {
      if (!organisationId) throw new Error("organisationId is not set");

      const existingNode = storedNodes.find((f) => f.id === nodeId);
      if (!existingNode) throw new Error("id does not exist in stored nodes");

      const verifiedParentNodeId =
        storedNodes.find((f) => f.id === parentId)?.id ??
        allTopLevelFolders.find((f) => f.id === parentId)?.id ??
        (parentId === organisationId ? organisationId : undefined);
      if (!verifiedParentNodeId)
        throw new Error("Failed to find the parent node");

      // make sure we have the latest data
      set(
        turbineResourceWithAccessOnNodeState({
          nodeId,
        }),
        RESET,
      );
      set(
        foundationResourceWithAccessOnNodeState({
          nodeId,
        }),
        RESET,
      );
      set(
        cableResourceWithAccessOnNodeState({
          nodeId,
        }),
        RESET,
      );
      set(
        analysisResourceWithAccessOnNodeState({
          nodeId,
        }),
        RESET,
      );
      set(
        financialResourceWithAccessOnNodeState({
          nodeId,
        }),
        RESET,
      );

      set(
        turbineResourceWithAccessOnNodeState({
          nodeId: parentId,
        }),
        RESET,
      );
      set(
        foundationResourceWithAccessOnNodeState({
          nodeId: parentId,
        }),
        RESET,
      );
      set(
        cableResourceWithAccessOnNodeState({
          nodeId: parentId,
        }),
        RESET,
      );
      set(
        analysisResourceWithAccessOnNodeState({
          nodeId: parentId,
        }),
        RESET,
      );
      set(
        financialResourceWithAccessOnNodeState({
          nodeId: parentId,
        }),
        RESET,
      );

      const [
        turbineResources,
        foundationResources,
        cableResources,
        analysisResources,
        financialResources,
      ] = await Promise.all([
        get(
          turbineResourceWithAccessOnNodeState({
            nodeId,
          }),
        ),
        get(
          foundationResourceWithAccessOnNodeState({
            nodeId,
          }),
        ),
        get(
          cableResourceWithAccessOnNodeState({
            nodeId,
          }),
        ),
        get(
          analysisResourceWithAccessOnNodeState({
            nodeId,
          }),
        ),
        get(
          financialResourceWithAccessOnNodeState({
            nodeId,
          }),
        ),
      ]);
      const [
        parentTurbineResources,
        parentFoundationResources,
        parentCableResources,
        parentAnalysisResources,
        parentFinancialResources,
      ] = await Promise.all([
        get(
          turbineResourceWithAccessOnNodeState({
            nodeId: parentId,
          }),
        ),
        get(
          foundationResourceWithAccessOnNodeState({
            nodeId: parentId,
          }),
        ),
        get(
          cableResourceWithAccessOnNodeState({
            nodeId: parentId,
          }),
        ),
        get(
          analysisResourceWithAccessOnNodeState({
            nodeId: parentId,
          }),
        ),
        get(
          financialResourceWithAccessOnNodeState({
            nodeId: parentId,
          }),
        ),
      ]);

      const removedTurbineResources = turbineResources.filter(
        (r) =>
          r.nodeId !== nodeId &&
          !parentTurbineResources.some((pr) => pr.turbine.id === r.turbine.id),
      );
      const addedTurbineResources = parentTurbineResources.filter(
        (r) => !turbineResources.some((tr) => tr.turbine.id === r.turbine.id),
      );

      const removedFoundationResources = foundationResources.filter(
        (r) =>
          r.nodeId !== nodeId &&
          !parentFoundationResources.some(
            (pr) => pr.foundation.id === r.foundation.id,
          ),
      );
      const addedFoundationResources = parentFoundationResources.filter(
        (r) =>
          !foundationResources.some(
            (fr) => fr.foundation.id === r.foundation.id,
          ),
      );

      const removedCableResources = cableResources.filter(
        (r) =>
          r.nodeId !== nodeId &&
          !parentCableResources.some((pr) => pr.cable.id === r.cable.id),
      );
      const addedCableResources = parentCableResources.filter(
        (r) => !cableResources.some((cr) => cr.cable.id === r.cable.id),
      );

      const removedAnalysisResources = analysisResources.filter(
        (r) =>
          r.nodeId !== nodeId &&
          !parentAnalysisResources.some((pr) => pr.config.id === r.config.id),
      );
      const addedAnalysisResources = parentAnalysisResources.filter(
        (r) => !analysisResources.some((ar) => ar.config.id === r.config.id),
      );

      const removedFinancialResources = financialResources.filter(
        (r) =>
          r.nodeId !== nodeId &&
          !parentFinancialResources.some((pr) => pr.config.id === r.config.id),
      );

      const addedFinancialResources = parentFinancialResources.filter(
        (r) => !financialResources.some((fr) => fr.config.id === r.config.id),
      );

      const added: LibraryResource[] = [
        ...addedAnalysisResources.map((r) => ({
          resource_id: r.config.id,
          resource_name: r.config.name ?? "",
          type: "ANALYSIS" as LibraryResourceType,
        })),
        ...addedCableResources.map((r) => ({
          resource_id: r.cable.id,
          resource_name: r.cable.name ?? "",
          type: "CABLE" as LibraryResourceType,
        })),
        ...addedFinancialResources.map((r) => ({
          resource_id: r.config.id,
          resource_name: r.config.name ?? "",
          type: "FINANCIAL" as LibraryResourceType,
        })),
        ...addedFoundationResources.map((r) => ({
          resource_id: r.foundation.id,
          resource_name: r.foundation.name ?? "",
          type: "FOUNDATION" as LibraryResourceType,
        })),
        ...addedTurbineResources.map((r) => ({
          resource_id: r.turbine.id,
          resource_name: r.turbine.name ?? "",
          type: "TURBINE" as LibraryResourceType,
        })),
      ];

      const removed: LibraryResource[] = [
        ...removedAnalysisResources.map((r) => ({
          resource_id: r.config.id,
          resource_name: r.config.name ?? "",
          type: "ANALYSIS" as LibraryResourceType,
        })),
        ...removedCableResources.map((r) => ({
          resource_id: r.cable.id,
          resource_name: r.cable.name ?? "",
          type: "CABLE" as LibraryResourceType,
        })),
        ...removedFinancialResources.map((r) => ({
          resource_id: r.config.id,
          resource_name: r.config.name ?? "",
          type: "FINANCIAL" as LibraryResourceType,
        })),
        ...removedFoundationResources.map((r) => ({
          resource_id: r.foundation.id,
          resource_name: r.foundation.name ?? "",
          type: "FOUNDATION" as LibraryResourceType,
        })),
        ...removedTurbineResources.map((r) => ({
          resource_id: r.turbine.id,
          resource_name: r.turbine.name ?? "",
          type: "TURBINE" as LibraryResourceType,
        })),
      ];

      return {
        added,
        removed,
      };
    },
    [allTopLevelFolders, organisationId, storedNodes],
  );

  const explainUserAccessConsequences = useJotaiCallback(
    async (_get, _set, nodeId: string, parentId: string) => {
      if (!organisationId) throw new Error("organisationId is not set");

      const [groupsAndUsersWithAccess, parentGroupsAndUsersWithAccess] =
        await Promise.all([
          getGroupsAndUsersWithAccessToNode(organisationId, nodeId),
          getGroupsAndUsersWithAccessToNode(organisationId, parentId),
        ]);

      const usersWithAccess = groupsAndUsersWithAccess.filter(
        isUserNodeAccessWithMeta,
      );
      const parentUsersWithAccess = parentGroupsAndUsersWithAccess.filter(
        isUserNodeAccessWithMeta,
      );

      const allUniqueUsers = [
        ...new Set(
          [...usersWithAccess, ...parentUsersWithAccess].map((a) => a.user_id),
        ),
      ];

      const accessUpdates: MoveNodeUserAccess[] = allUniqueUsers
        .map((user_id) => {
          const thisUserAccess = usersWithAccess.filter(
            (ua) => ua.user_id === user_id,
          );

          const currentAccess =
            thisUserAccess.length === 0
              ? undefined
              : thisUserAccess.length === 1
                ? thisUserAccess[0]
                : getHighestPriorityAccess(thisUserAccess, nodeId);

          const currentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              currentAccess ? currentAccess.resource_name : "undefined"
            ];
          const currentAccessIsToNodeBeingMoved =
            currentAccess?.node_id === nodeId;

          const thisUserParentAccess = parentUsersWithAccess.filter(
            (ua) => ua.user_id === user_id,
          );

          const parentAccess =
            thisUserParentAccess.length === 0
              ? undefined
              : thisUserParentAccess.length === 1
                ? thisUserParentAccess[0]
                : getHighestPriorityAccess(thisUserParentAccess, parentId);

          const parentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              parentAccess ? parentAccess.resource_name : "undefined"
            ];

          if (currentAccessIsToNodeBeingMoved) {
            // current access will remain
            if (
              !parentAccess ||
              parentAccessLevel < 0 ||
              currentAccessLevel >= parentAccessLevel
            )
              return undefined;

            // new access will override current access
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              user_id,
            };
          } else if (currentAccess) {
            // current access will be removed
            if (!parentAccess || parentAccessLevel < 0)
              return {
                from: currentAccess.resource_name,
                to: "No access" as "No access" | UserAccessRole,
                user_id,
              };

            // access level remains the same
            if (currentAccessLevel == parentAccessLevel) return undefined;

            // Access will be updated
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              user_id,
            };
          } else {
            if (!parentAccess) return undefined;
            return {
              from: "No access" as "No access" | UserAccessRole,
              to: parentAccess.resource_name,
              user_id,
            };
          }
        })
        .filter(isDefined);

      return accessUpdates;
    },
    [organisationId],
  );
  const explainGroupAccessConsequences = useJotaiCallback(
    async (_get, _set, nodeId: string, parentId: string) => {
      if (!organisationId) throw new Error("organisationId is not set");

      const groupsAndUsersWithAccess = await getGroupsAndUsersWithAccessToNode(
        organisationId,
        nodeId,
      );
      const groupsWithAccess = groupsAndUsersWithAccess.filter(
        isGroupNodeAccessWithMeta,
      );

      const parentGroupsAndUsersWithAccess =
        await getGroupsAndUsersWithAccessToNode(organisationId, parentId);
      const parentGroupsWithAccess = parentGroupsAndUsersWithAccess.filter(
        isGroupNodeAccessWithMeta,
      );

      const allUniqueGroups = [
        ...new Set(
          [...groupsWithAccess, ...parentGroupsWithAccess].map(
            (a) => a.group_id,
          ),
        ),
      ];

      const accessUpdates: MoveNodeGroupAccess[] = allUniqueGroups
        .map((group_id) => {
          const thisGroupAccess = groupsWithAccess.filter(
            (ga) => ga.group_id === group_id,
          );

          const currentAccess =
            thisGroupAccess.length === 0
              ? undefined
              : thisGroupAccess.length === 1
                ? thisGroupAccess[0]
                : getHighestPriorityAccess(thisGroupAccess, nodeId);

          const currentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              currentAccess ? currentAccess.resource_name : "undefined"
            ];
          const currentAccessIsToNodeBeingMoved =
            currentAccess?.node_id === nodeId;

          const thisGroupParentAccess = parentGroupsWithAccess.filter(
            (ga) => ga.group_id === group_id,
          );

          const parentAccess =
            thisGroupParentAccess.length === 0
              ? undefined
              : thisGroupParentAccess.length === 1
                ? thisGroupParentAccess[0]
                : getHighestPriorityAccess(thisGroupParentAccess, parentId);

          const parentAccessLevel =
            ACCESS_ROLE_TO_NUMBER[
              parentAccess ? parentAccess.resource_name : "undefined"
            ];

          if (currentAccessIsToNodeBeingMoved) {
            // current access will remain
            if (
              !parentAccess ||
              parentAccessLevel < 0 ||
              currentAccessLevel >= parentAccessLevel
            )
              return undefined;

            // new access will override current access
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              group_id,
              group_name: currentAccess.group_name,
            };
          } else if (currentAccess) {
            // current access will be removed
            if (!parentAccess || parentAccessLevel < 0)
              return {
                from: currentAccess.resource_name,
                to: "No access" as "No access" | UserAccessRole,
                group_id,
                group_name: currentAccess.group_name,
              };

            // access level remains the same
            if (currentAccessLevel == parentAccessLevel) return undefined;

            // Access will be updated
            return {
              from: currentAccess.resource_name,
              to: parentAccess.resource_name,
              group_id,
              group_name: currentAccess.group_name,
            };
          } else {
            if (!parentAccess) return undefined;
            return {
              from: "No access" as "No access" | UserAccessRole,
              to: parentAccess.resource_name,
              group_id,
              group_name: parentAccess.group_name,
            };
          }
        })
        .filter(isDefined);

      return accessUpdates;
    },
    [organisationId],
  );

  const explain = useJotaiCallback(
    async (_get, _set, nodeId: string, parentId: string) => {
      const [libraryResourceConsequences, groupAccess, userAccess] =
        await Promise.all([
          explainResourceConsequences(nodeId, parentId),
          explainGroupAccessConsequences(nodeId, parentId),
          explainUserAccessConsequences(nodeId, parentId),
        ]);

      return {
        library_resources: libraryResourceConsequences,
        group_accesses: groupAccess,
        user_accesses: userAccess,
      };
    },
    [
      explainGroupAccessConsequences,
      explainResourceConsequences,
      explainUserAccessConsequences,
    ],
  );

  return {
    explain,
  };
}
