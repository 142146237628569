import styled from "styled-components";
import { spaceMedium, spacing4, spacing5, spacing6 } from "../../styles/space";
import { colors } from "../../styles/colors";

export const SIDEBAR_PADDING = "1.2rem";

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${colors.inputOutline};
  padding: 1rem 1rem 0;
  justify-content: flex-end;
  gap: ${spacing4};
  margin-top: ${spaceMedium};
  flex-shrink: 0;
  margin-left: -${spacing6};
`;

export const HideIfNotHoverOrVisible = styled.div`
  display: flex;
  align-items: center;
`;

export const LayerItemTopRow = styled.div`
  display: flex;
  align-items: center;
`;

export const LayerItemBottomRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
  //padding: 0 0.8rem;
`;

export const CollectionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition: 0.4s;
  min-height: 3.6rem;
`;

export const CollectionLayersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition: all 0.2s;
  gap: ${spacing4};
  padding-top: ${spacing4};
  margin-bottom: ${spacing5};
`;
