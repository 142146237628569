import {
  CostConfiguration,
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
  isSubstationParameterCost,
  isSubstationOverrideCost,
  SubstationCost,
} from "../../../../services/costService";
import { useAtomValue } from "jotai";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import Substation from "@icons/24/Substation.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import {
  _CostUnit,
  ConfidenceLevel,
  CostUnit,
} from "../../../../types/financial";
import { DefaultCosts } from "../constants";
import { FirstRowWrapper } from "../Capex";
import { Input, InputDimensioned, TextArea } from "components/General/Input";
import { isDefined } from "utils/predicates";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { financialChangelogInfo } from "components/InputChangelog/const";
import {
  ChangelogCategory,
  InputChangelogInfo,
} from "components/InputChangelog/types";
import useBooleanState from "hooks/useBooleanState";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import {
  DefaultOffshoreCosts,
  DefaultOnshoreCosts,
} from "@vind-ai/default-cost-library/lib/defaults";

const SubstationSpecificCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const category: ChangelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const changelogInfo = financialChangelogInfo(
    configuration.id,
    nodeId,
    "capex.fixed.substation.cost",
    category,
  );
  const units = [
    CostUnit.millionEuroPerMW,
    CostUnit.millionEuroPerUnit,
    CostUnit.thousandEuroPerMW,
  ];
  const localFixedCapexSubstationCosts = localConfig?.capex.fixed.substation;
  const isOnshoreConfig = configuration.type === "onshore";

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        {isSubstationParameterCost(localFixedCapexSubstationCosts) ? (
          <SubstationParameterCosts
            savedSubstationCosts={configuration.capex.fixed.substation}
            substationCostsLocal={localFixedCapexSubstationCosts}
            updateFixedCapex={updateFixedCapex}
            isReadOnly={isReadOnly ?? false}
            units={units}
            changelogInfo={changelogInfo}
          />
        ) : (
          <SubstationOverrideCosts
            savedSubstationCosts={configuration.capex.fixed.substation}
            substationCostsLocal={localFixedCapexSubstationCosts}
            updateFixedCapex={updateFixedCapex}
            isReadOnly={isReadOnly ?? false}
            units={units}
            changelogInfo={changelogInfo}
            isOnshoreConfig={isOnshoreConfig}
            useConfidenceLevel={!!useConfidenceLevel}
            showFreeTextCapex={!!showFreeTextCapex}
            substationFreeText={localConfig?.capex.fixed.substationFreeText}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

interface CostProps {
  savedSubstationCosts: SubstationCost;
  substationCostsLocal: SubstationCost;
  updateFixedCapex: (cost: Record<string, unknown>, key?: string) => void;
  isReadOnly: boolean;
  units: CostUnit[];
  changelogInfo: InputChangelogInfo;
  isOnshoreConfig?: boolean;
}

const SubstationParameterCosts = ({
  savedSubstationCosts,
  substationCostsLocal,
  updateFixedCapex,
  isReadOnly,
  units,
  changelogInfo,
}: CostProps) => {
  const [openHVDC, toggleOpenHVDC] = useBooleanState(true);
  const [openHVAC, toggleOpenHVAC] = useBooleanState(true);

  const handleSubstationCostTypeChange = () => () => {
    if (isSubstationOverrideCost(savedSubstationCosts)) {
      updateFixedCapex({
        substation: {
          ...savedSubstationCosts,
        },
      });
    } else {
      updateFixedCapex({
        substation: {
          cost: DefaultCosts[CostType.Substation].substation[
            CostUnit.millionEuroPerMW
          ],
          unit: CostUnit.millionEuroPerMW,
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
      });
    }
  };

  const handleUnitChange = (
    type: "HVAC" | "HVDC",
    location: "onshore" | "offshore",
    unit: CostUnit,
  ) => {
    if (!isSubstationParameterCost(substationCostsLocal)) return;

    updateFixedCapex({
      substation: {
        ...substationCostsLocal,
        [type]: {
          ...substationCostsLocal[type],
          [location]: {
            ...substationCostsLocal[type][location],
            unit,
            cost: (DefaultCosts[CostType.Substation].substation as any)[unit],
          },
        },
      },
    });
  };

  const handleCostChange = (
    type: "HVAC" | "HVDC",
    location: "onshore" | "offshore",
    cost: number,
  ) => {
    if (!isSubstationParameterCost(substationCostsLocal)) return;

    updateFixedCapex({
      substation: {
        ...substationCostsLocal,
        [type]: {
          ...substationCostsLocal[type],
          [location]: {
            ...substationCostsLocal[type][location],
            cost,
          },
        },
      },
    });
  };

  const getInitialValue = (
    cableType: "HVAC" | "HVDC",
    type: "offshore" | "onshore",
  ) => {
    return isSubstationParameterCost(savedSubstationCosts)
      ? savedSubstationCosts[cableType][type].cost
      : DefaultCosts[CostType.Substation].substation[CostUnit.millionEuroPerMW];
  };

  return (
    <>
      <Row onClick={toggleOpenHVAC}>
        <ExpandArrowWrapper open={openHVAC}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <p>HVAC</p>
      </Row>
      <div />
      <div />
      <div />
      <Button
        size="small"
        icon={<PencilIcon />}
        buttonType="secondary"
        text={"Override"}
        onClick={handleSubstationCostTypeChange()}
        disabled={isReadOnly}
      />
      {openHVAC && isSubstationParameterCost(substationCostsLocal) && (
        <>
          <Input
            value={"Onshore"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={_CostUnit.parse(substationCostsLocal.HVAC.onshore.unit)}
            units={units}
            type="number"
            value={
              substationCostsLocal.HVAC.onshore.cost ??
              DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ]
            }
            initialValue={getInitialValue("HVAC", "onshore")}
            changelogInfo={changelogInfo}
            onUnitChange={(unit) => handleUnitChange("HVAC", "onshore", unit)}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            onChange={(cost) => handleCostChange("HVAC", "onshore", cost)}
            style={{
              width: "100%",
            }}
          />
          <div />
          <div />
          <div />
          <Input
            value={"Offshore"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={_CostUnit.parse(substationCostsLocal.HVAC.offshore.unit)}
            units={units}
            type="number"
            value={
              substationCostsLocal.HVAC.offshore.cost ??
              DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ]
            }
            initialValue={getInitialValue("HVAC", "offshore")}
            changelogInfo={changelogInfo}
            onUnitChange={(unit) => handleUnitChange("HVAC", "offshore", unit)}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            onChange={(cost) => handleCostChange("HVAC", "offshore", cost)}
            style={{
              width: "100%",
            }}
          />
          <div />
          <div />
          <div />
        </>
      )}
      <Row onClick={toggleOpenHVDC}>
        <ExpandArrowWrapper open={openHVDC}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <p>HVDC</p>
      </Row>
      <div />
      <div />
      <div />
      <div />
      {openHVDC && isSubstationParameterCost(substationCostsLocal) && (
        <>
          <Input
            value={"Onshore"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={_CostUnit.parse(substationCostsLocal.HVDC.onshore.unit)}
            units={units}
            type="number"
            value={
              substationCostsLocal.HVDC.onshore.cost ??
              DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ]
            }
            initialValue={getInitialValue("HVDC", "onshore")}
            changelogInfo={changelogInfo}
            onUnitChange={(unit) => handleUnitChange("HVDC", "onshore", unit)}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            onChange={(cost) => handleCostChange("HVDC", "onshore", cost)}
            style={{
              width: "100%",
            }}
          />
          <div />
          <div />
          <div />
          <Input
            value={"Offshore"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={_CostUnit.parse(substationCostsLocal.HVDC.offshore.unit)}
            units={units}
            type="number"
            value={
              substationCostsLocal.HVDC.offshore.cost ??
              DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ]
            }
            initialValue={getInitialValue("HVDC", "offshore")}
            changelogInfo={changelogInfo}
            onUnitChange={(unit) => handleUnitChange("HVDC", "offshore", unit)}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            onChange={(cost) => handleCostChange("HVDC", "offshore", cost)}
            style={{
              width: "100%",
            }}
          />
          <div />
          <div />
          <div />
        </>
      )}
    </>
  );
};

interface SubstationOverrideCostsProps extends CostProps {
  useConfidenceLevel: boolean;
  showFreeTextCapex: boolean;
  substationFreeText: string | undefined;
}

const SubstationOverrideCosts = ({
  savedSubstationCosts,
  substationCostsLocal,
  updateFixedCapex,
  isReadOnly,
  units,
  changelogInfo,
  isOnshoreConfig,
  useConfidenceLevel,
  showFreeTextCapex,
  substationFreeText,
}: SubstationOverrideCostsProps) => {
  if (!isSubstationOverrideCost(substationCostsLocal)) return null;

  const handleSubstationCostTypeChange = () => {
    if (isSubstationParameterCost(savedSubstationCosts)) {
      updateFixedCapex({
        substation: {
          ...savedSubstationCosts,
        },
      });
    } else {
      updateFixedCapex({
        substation: {
          HVAC: {
            onshore: {
              cost: DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ],
              unit: CostUnit.millionEuroPerMW,
            },
            offshore: {
              cost: DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ],
              unit: CostUnit.millionEuroPerMW,
            },
          },
          HVDC: {
            onshore: {
              cost: DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ],
              unit: CostUnit.millionEuroPerMW,
            },
            offshore: {
              cost: DefaultCosts[CostType.Substation].substation[
                CostUnit.millionEuroPerMW
              ],
              unit: CostUnit.millionEuroPerMW,
            },
          },
        },
      });
    }
  };

  const getInitialValue = () => {
    if (isOnshoreConfig) {
      return DefaultOnshoreCosts[CostType.Substation].substation[
        CostUnit.millionEuroPerMW
      ];
    }
    return isSubstationOverrideCost(savedSubstationCosts)
      ? savedSubstationCosts.cost
      : DefaultCosts[CostType.Substation].substation[CostUnit.millionEuroPerMW];
  };

  const onUnitChange = (unit: CostUnit) => {
    const cost = isOnshoreConfig
      ? (DefaultOnshoreCosts[CostType.Substation].substation as any)[unit]
      : (DefaultOffshoreCosts[CostType.Substation].substation as any)[unit];
    updateFixedCapex({
      substation: {
        cost,
        unit,
      },
    });
  };

  return (
    <>
      <Input
        value={"Substations"}
        disabled={true}
        locked={true}
        style={{
          width: "100%",
        }}
      />

      <Row
        style={{
          width: "100%",
        }}
      >
        <InputDimensioned
          step="0.1"
          unit={substationCostsLocal.unit}
          units={units}
          type="number"
          value={
            substationCostsLocal.cost ??
            DefaultCosts[CostType.Substation].substation[
              CostUnit.millionEuroPerMW
            ]
          }
          initialValue={getInitialValue()}
          changelogInfo={changelogInfo}
          onUnitChange={onUnitChange}
          validate={(value) => isDefined(value) && value >= 0}
          validationMessage={"Must be greater than or equal to 0"}
          disabled={
            isReadOnly ||
            !(
              substationCostsLocal &&
              isSubstationOverrideCost(substationCostsLocal)
            )
          }
          onChange={(cost) =>
            updateFixedCapex(
              {
                cost,
              },
              "substation",
            )
          }
          style={{
            width: "100%",
          }}
        />
      </Row>
      {useConfidenceLevel ? (
        <ConfidenceLevelSelector
          disabled={isReadOnly}
          onConfidenceLevelChange={(confidenceLevel) => {
            updateFixedCapex({
              substation: {
                ...substationCostsLocal,
                confidenceLevel,
              },
            });
          }}
          confidenceLevel={substationCostsLocal.confidenceLevel}
        />
      ) : (
        <div />
      )}
      {showFreeTextCapex ? (
        <div>
          <TextArea
            rows={1}
            style={{
              resize: "vertical",
              width: "100%",
            }}
            value={substationFreeText ?? ""}
            disabled={isReadOnly}
            onChange={(e) => {
              updateFixedCapex({
                substationFreeText: e.target.value,
              });
            }}
          />
        </div>
      ) : (
        <div />
      )}
      {!isOnshoreConfig ? (
        <Button
          size="small"
          icon={<PencilIcon />}
          buttonType="secondary"
          text={"Use parameters"}
          onClick={handleSubstationCostTypeChange}
          disabled={isReadOnly}
        />
      ) : (
        <div />
      )}
    </>
  );
};

const SubstationInstallationCosts = ({
  isReadOnly,
  nodeId,
  category,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();
  const isOnshoreConfig = configuration.type === "onshore";

  const handleSubstationCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ substation: type });
      } else {
        updateInstallationCapex({
          substation: {
            unit: CostUnit.thousandEuroPerMW,
            cost: DefaultCosts[CostType.Substation].installation["k€/MW"],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.substation) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.substation,
                ) &&
                  localConfig?.capex.installation.substation.unit) ||
                CostUnit.thousandEuroPerMW
              }
              units={[CostUnit.thousandEuroPerMW]}
              type="number"
              value={
                isOperationsOverrideCost(
                  localConfig?.capex.installation.substation,
                )
                  ? localConfig.capex.installation.substation.cost ??
                    DefaultCosts[CostType.Substation].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.substation,
                )
                  ? configuration.capex.installation.substation.cost ??
                    DefaultCosts[CostType.Substation].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              onChange={(cost) =>
                updateInstallationCapex({ cost }, "substation")
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.substation.cost",
                category,
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.substation &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.substation,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(
            localConfig?.capex.installation.substation === "operations_cost"
          ) ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.substation ===
                  "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  substation: {
                    ...localConfig?.capex.installation.substation,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.substation.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.installation.substationFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  substationFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}

        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.substation) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.substation)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.substation)
                ? handleSubstationCostTypeChange("override")
                : handleSubstationCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const SubstationCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Substations"
      category={CostType.Substation}
      configuration={configuration}
      isReadOnly={isReadOnly}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
      units={[
        CostUnit.thousandEuroPerMW,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerUnit,
        CostUnit.millionEuro,
        CostUnit.millionEuroPerONS,
        CostUnit.millionEuroPerOSS,
      ]}
      icon={<Substation />}
    >
      <SubstationSpecificCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <SubstationInstallationCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={isLibraryResource ? "org_financial_manage" : "project"}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
