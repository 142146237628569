import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { _FoundationType } from "types/foundations";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import { useLibraryNodeFoundations } from "hooks/useLibraryNodeFoundations";
import { ABLY_ORG_FOUNDATION_LIBRARY_TYPE_UPDATE } from "state/ably";

export function useAblyLibraryFoundationForNode(projectId: string) {
  const { updateLocal } = useLibraryNodeFoundations(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const foundation = _FoundationType.parse(message.data.meta);
      updateLocal(foundation);
    },
    [updateLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ORG_FOUNDATION_LIBRARY_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
