import { atom } from "jotai";
import {
  AnalysisConfiguration,
  _AnalysisConfiguration,
  listAnalysisConfigurations,
} from "services/configurationService";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { dedup } from "utils/utils";
import { branchMetaFamily } from "./branch";
import { atomWithRefresh, RESET } from "jotai/utils";
import { analysisResourceWithAccessOnNodeState } from "components/Organisation/Library/state";

/**
 * Re-fetch analysis configurations for both project and library.
 */
export const analysisConfigurationsRefetch = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom(null, (_, set) => {
      set(fetchProjectAnalysisConfigurationFamily({ projectId }));
      set(analysisResourceWithAccessOnNodeState({ nodeId: projectId }), RESET);
    }),
);

// NOTE: this is only for caching
const fetchProjectAnalysisConfigurationFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomWithRefresh(async () => {
      const response = await listAnalysisConfigurations(projectId);
      if (!response.ok) throw new Error("Fetching configurations failed");
      const j = await response.json();
      return _AnalysisConfiguration.array().parse(j);
    }),
);
export const projectAnalysisConfigurationsFamily = atomFamily(
  (input: { projectId: string }) =>
    atomFromFn<Promise<Map<string, AnalysisConfiguration>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("analysisConfigurations require projectIdAtom");
      const configs = await get(
        fetchProjectAnalysisConfigurationFamily({ projectId }),
      );
      return new Map(configs.map((c) => [c.id, c]));
    }),
);

export const libraryAnalysisConfigurationsFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atomFromFn<Promise<Map<string, AnalysisConfiguration>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("analysisConfigurations require projectIdAtom");
      const analysis = await get(
        analysisResourceWithAccessOnNodeState({ nodeId: projectId }),
      ).catch(() => []);
      const unique = dedup(analysis, (a) => a.config.id);
      return new Map(unique.map(({ config }) => [config.id, config]));
    }),
);

export const analysisConfigurationsFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom(async (get) => {
      const [proj, lib] = await Promise.all([
        get(projectAnalysisConfigurationsFamily({ projectId })),
        get(libraryAnalysisConfigurationsFamily({ projectId })),
      ]);
      return new Map([...proj, ...lib]);
    }),
);

export const analysisConfigurationSelectedFamily = atomFamily(
  (input: { branchId: string | undefined; projectId: string }) =>
    atom(async (get) => {
      const { projectId } = input;
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId)
        throw new Error(
          "analysisConfigurationSelectedFamily requires branchIdAtom",
        );
      const [branch, configs] = await Promise.all([
        get(branchMetaFamily({ branchId, projectId })),
        get(analysisConfigurationsFamily({ projectId })),
      ]);
      return configs.get(branch?.analysisConfigurationId ?? "");
    }),
);
