// Estimated relative A-weighted sound power levels for turbines around 5MW

import { roundToDecimal } from "./utils";

// Source: Table III from https://vbn.aau.dk/ws/portalfiles/portal/53111081/Low_frequency_noise_from_large_wind_turbines.pdf
const AAU_RELATIVE_SOUND_POWER_LEVELS = [
  -15.3, -9.3, -6.5, -5.8, -6.5, -9.5, -16.1, -28.7,
];
const FREQUENCIES = [63, 125, 250, 500, 1000, 2000, 4000, 8000];

type Distribution = {
  frequencies: number[];
  levels: number[];
};

export const getOctaveBandLevels = (
  Lwa: number,
  options: {
    relativeLevels?: number[];
  } = {},
): Distribution => {
  const relativeLevels =
    options.relativeLevels ?? AAU_RELATIVE_SOUND_POWER_LEVELS;

  const levels = relativeLevels
    .map((level) => Lwa + level)
    .map((level) => roundToDecimal(level, 2));

  return { frequencies: FREQUENCIES, levels };
};
