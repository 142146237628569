import { projectPresenceState } from "components/Ably/ChannelProviders/Project/ProjectAll/state";
import { getUserColor } from "components/LiveCursor/utils";
import { useAtomValue } from "jotai";
import { Map } from "mapbox-gl";
import { useMemo, useEffect } from "react";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { otherUsersSelectionArrayAtomFamily } from "state/selection";

function AblySelectionHighlighter({
  map,
  sourceId,
  featureIds,
  enabled,
}: {
  map: Map;
  sourceId: string;
  featureIds: string[];
  enabled?: boolean;
}) {
  if (!enabled) return null;

  return (
    <AblySelectionHighlighterInner
      map={map}
      sourceId={sourceId}
      featureIds={featureIds}
    />
  );
}

function AblySelectionHighlighterInner({
  map,
  sourceId,
  featureIds,
}: {
  map: Map;
  sourceId: string;
  featureIds: string[];
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const otherUsersSelection = useAtomValue(
    otherUsersSelectionArrayAtomFamily({
      projectId: projectId,
      branchId,
    }),
  );

  const presenceData = useAtomValue(projectPresenceState(projectId));

  const filteredSelection = useMemo(() => {
    return otherUsersSelection.filter((s) =>
      s.selection.some((id) => featureIds.includes(id)),
    );
  }, [otherUsersSelection, featureIds]);

  useEffect(() => {
    if (!map || !filteredSelection || !branchId) return;

    filteredSelection.forEach((s) => {
      const branchPresence = presenceData.find(
        (p) => p.clientId === s.userId && p.data.branchId === branchId,
      );
      const userIsActive =
        branchPresence && branchPresence.data.status === "active";

      if (userIsActive) {
        s.selection.forEach((id) => {
          map.setFeatureState(
            {
              source: sourceId,
              id,
            },
            {
              borderColor: getUserColor(s.userId),
            },
          );
        });
      }
    });

    return () => {
      if (!map.getSource(sourceId)) return;

      filteredSelection.forEach((s) => {
        s.selection.forEach((id) => {
          map.setFeatureState(
            {
              source: sourceId,
              id,
            },
            {
              borderColor: null,
            },
          );
        });
      });
    };
  }, [map, sourceId, filteredSelection, branchId, presenceData]);

  return <></>;
}

export default AblySelectionHighlighter;
