import React, { useEffect, useMemo, useState } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import {
  DropDownActionItem,
  DropDownItem,
} from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import { colors } from "styles/colors";
import { useAtomValue } from "jotai";
import { useOpenComponentsConfig } from "components/CanvasSelectOption/useOpenConfig";
import Fuse from "fuse.js";
import { SearchInput } from "components/General/Input";
import ComponentsIcon from "@icons/24/Components.svg?react";
import { foundationTypesWithLevelAtom } from "state/jotai/foundation";
import { projectIdAtom } from "state/pathParams";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";
import { FoundationType, FoundationTypeWithLevel } from "types/foundations";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 17rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectFoundationDropDown = ({
  currentFoundation,
  onSelectItem,
  listContainerStyle,
  buttonStyle,
  className,
  disabled,
}: {
  currentFoundation: FoundationType | undefined;
  onSelectItem: (val: string) => void;
  listContainerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  className?: string;
  disabled?: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const allFoundationTypes = useAtomValue(foundationTypesWithLevelAtom);

  const openNewFoundationConfig = useOpenComponentsConfig(
    projectId ?? "",
    FOUNDATION_MENU_ID,
  );
  const [search, setSearch] = useState("");
  const [filteredResources, setFilteredResources] =
    useState<FoundationTypeWithLevel[]>(allFoundationTypes);

  useEffect(() => {
    if (!search) {
      setFilteredResources(allFoundationTypes);
      return;
    }

    const fuse = new Fuse(Array.from(allFoundationTypes.values()), {
      keys: ["foundation.name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(search).map((result) => result.item);

    setFilteredResources(results);
  }, [search, allFoundationTypes]);

  const items = useMemo(
    () =>
      Array.from(filteredResources.values()).map((t) => ({
        level: t.level,
        value: t.foundation.id,
        name: t.foundation.name,
        icon:
          t.level === "standard" ? (
            <VindLogo />
          ) : t.level === "project" ? (
            <ProjectIcon />
          ) : (
            <LibraryIcon />
          ),
      })),
    [filteredResources],
  );

  const actionItem: DropDownActionItem = useMemo(() => {
    return {
      value: "create-new",
      name: "Go to components to create new",
      onClick: () => openNewFoundationConfig(),
      icon: <ComponentsIcon />,
      disabled: false,
    };
  }, [openNewFoundationConfig]);

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");
    const standardItems = items.filter((ft) => ft.level === "standard");

    return {
      project: projectItems,
      library: libraryItems,
      standard: standardItems,
    };
  }, [items]);

  const configItems = useMemo<DropDownItem[]>(() => {
    const items: DropDownItem[] = [];

    // Project items
    if (groupedItems.project.length > 0) {
      items.push({
        value: "project-title", // unique value for the title
        name: "Project specific components",
        isTitle: true, // Add this to your DropDownItem type if needed
        disabled: true, // Make title non-clickable
      });
      items.push(
        ...groupedItems.project.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <ProjectIcon />
            </IconREMSize>
          ),
        })),
      );
    }

    // Library items
    if (groupedItems.library.length > 0) {
      items.push({
        value: "library-title",
        name: "Library components",
        isTitle: true,
        disabled: true,
      });
      items.push(
        ...groupedItems.library.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <LibraryIcon />
            </IconREMSize>
          ),
        })),
      );
    }

    // Standard items
    if (groupedItems.standard.length > 0) {
      items.push({
        value: "standard-title",
        name: "Vind AI components",
        isTitle: true,
        disabled: true,
      });
      items.push(
        ...groupedItems.standard.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <VindLogo />
            </IconREMSize>
          ),
        })),
      );
    }

    return items;
  }, [groupedItems]);

  return (
    <DropdownButton
      searchBar={
        <div style={{ padding: "1.2rem 1.2rem 0.8rem" }}>
          <SearchInput
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            autoFocus={false}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => {
              setSearch("");
            }}
            placeholder={`Search foundation components`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </div>
      }
      className={className}
      items={configItems}
      disabled={disabled}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={listContainerStyle}
      style={buttonStyle}
      onSelectItem={(val) => {
        onSelectItem(val);
        setSearch("");
      }}
      buttonText={currentFoundation?.name ?? "Select a foundation"}
      selectedItemValue={currentFoundation?.id}
      actionItem={actionItem}
    />
  );
};

export default SelectFoundationDropDown;
