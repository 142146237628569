import { useState, useRef, useCallback } from "react";
import Button from "components/General/Button";
import { Frame, Row } from "components/General/Layout";
import styled from "styled-components";
import {
  spaceHuge,
  spaceLarge,
  spaceMedium,
  spaceSmall,
} from "../../../styles/space";
import { typography } from "styles/typography";
import { InputDimensionedDistance } from "components/General/Input";
import { colors } from "styles/colors";
import { useAtomValue, useSetAtom } from "jotai";
import { branchIdAtom } from "state/pathParams";
import { parksFamily } from "state/jotai/park";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { bufferSingleFeature } from "utils/bufferSingleFeature";
import { featureMapFamily } from "state/jotai/features";
import { Label } from "components/General/Form";
import React from "react";
import Fallback from "./Fallback";
import { activeFiltersAtom } from "../layer-filter-state";
import { Distance } from "components/Units/units";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { Mixpanel } from "mixpanel";

const StyledFrame = styled(Frame)`
  position: absolute;
  right: 2rem;
  margin-top: ${spaceMedium};
  z-index: 10;
  background-color: ${colors.surfacePrimary};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  padding: ${spaceSmall};
`;

const OverlapAnalysisContent = styled.div`
  padding: ${spaceMedium};
`;

const Header = styled.h3`
  ${typography.h3}
  margin-bottom: ${spaceLarge};
`;

type DistanceUnit = "m" | "km" | "NM";

const OverlapAnalysis = () => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  const [selectedParkId, setSelectedParkId] = useState<string>("");

  const branchId = useAtomValue(branchIdAtom);
  const parks = useAtomValue(parksFamily({ branchId }));
  const feature = useAtomValue(featureMapFamily({ branchId })).get(
    selectedParkId,
  );

  const setActiveFilters = useSetAtom(activeFiltersAtom);

  const [bufferSize, setBufferSize] = useState<undefined | number>();
  const [bufferUnit, setBufferUnit] = useState<DistanceUnit>("m");

  const onClose = () => {
    setIsOpen(false);
  };

  const onContinueClick = useCallback(() => {
    if (!feature) {
      return;
    }

    Mixpanel.track_old("Ran overlap analysis", {
      buffer_size: bufferSize,
    });

    // Set loading state in the filters
    setActiveFilters((curr) => ({
      ...curr,
      isLoading: true,
    }));

    if (!bufferSize) {
      setActiveFilters((curr) => ({
        ...curr,
        overlappingGeometry: feature.geometry,
        isLoading: false,
      }));
      onClose();
      return;
    }

    const bufferInMeters = Distance.convert(bufferSize, { from: bufferUnit });

    const bufferedFeature = bufferSingleFeature(
      feature,
      bufferInMeters,
      false,
      1,
    );

    if (bufferedFeature) {
      setActiveFilters((curr) => ({
        ...curr,
        overlappingGeometry: bufferedFeature.geometry,
        isLoading: false,
      }));
    }
    onClose();
  }, [bufferSize, bufferUnit, feature, setActiveFilters]);

  const toggleOverlapAnalysis = () => {
    setIsOpen(!isOpen);
  };

  const parkItems: DropDownItem[] = parks.map((park) => ({
    value: park.id,
    name: park.properties.name || "Unnamed Park",
  }));

  const handleParkSelect = (value: string) => {
    setSelectedParkId(value);
  };

  return (
    <React.Suspense fallback={<Fallback />}>
      <div ref={anchorRef}>
        <Button
          tooltip="Find layers that overlap with a selected park"
          buttonType="text"
          text="Overlap analysis"
          onClick={toggleOverlapAnalysis}
          style={{
            backgroundColor: colors.surfaceButtonSecondary,
            color: colors.textBrand,
            padding: `0 ${spaceMedium}`,
          }}
        />
        {isOpen && (
          <StyledFrame ref={frameRef} width="300px">
            <OverlapAnalysisContent>
              <Header>Overlap analysis</Header>

              <Label>
                Select park
                <DropdownButton
                  buttonText={
                    parkItems.length === 0
                      ? "No parks available"
                      : selectedParkId
                        ? parkItems.find(
                            (item) => item.value === selectedParkId,
                          )?.name || "Select a park"
                        : "Select a park"
                  }
                  disabled={parkItems.length === 0}
                  items={parkItems}
                  onSelectItem={(item) => handleParkSelect(item.toString())}
                  keepOpen={false}
                />
              </Label>

              <Label style={{ marginTop: spaceMedium }}>
                Buffer feature (optional)
                <InputDimensionedDistance
                  placeholder="Buffer size"
                  onChange={(value) => setBufferSize(value)}
                  onUnitChange={(unit) => setBufferUnit(unit as DistanceUnit)}
                  value={bufferSize}
                  unit={bufferUnit}
                  style={{ width: "100%" }}
                  shouldConvert={false}
                />
              </Label>

              <Row
                style={{
                  justifyContent: "flex-end",
                  marginTop: spaceHuge,
                  marginBottom: spaceSmall,
                }}
              >
                <Button
                  text="Cancel"
                  buttonType="secondary"
                  onClick={onClose}
                />
                <Button
                  text="Continue"
                  buttonType="primary"
                  onClick={onContinueClick}
                  disabled={parkItems.length === 0 || selectedParkId === ""}
                />
              </Row>
            </OverlapAnalysisContent>
          </StyledFrame>
        )}
      </div>
    </React.Suspense>
  );
};

export default OverlapAnalysis;
