import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceMedium, borderRadiusMedium, spaceTiny } from "../../styles/space";
import { typography } from "../../styles/typography";
import { Row } from "../General/Layout";
import { projectImageHeight } from "../StaticMapboxImage/StaticMapboxProjectImage";

export const ProjectGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem 2.2rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const DotMenuWrapper = styled.div<{
  isFolderHovered?: boolean;
  isActive?: boolean;
}>`
  position: absolute;
  align-self: end;
  cursor: pointer;
  border-radius: 0.4rem;
  margin: 0.4rem;
  background: ${({ isActive }) => (isActive ? colors.surfaceSelectedLight : colors.grey200)};}
  &:hover {
    background: ${({ isFolderHovered, isActive }) =>
      isActive
        ? colors.surfaceSelectedLight
        : isFolderHovered
          ? colors.grey300
          : colors.grey200};
    }
  visibility: ${({ isFolderHovered, isActive }) =>
    isFolderHovered || isActive ? "visible" : "hidden"};
`;

export const ProjectIconWrapper = styled.div<{
  isProjectHovered?: boolean;
  isActive?: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.4rem;
    height: 1.4rem;
    padding: 0 0.8rem;
    path {
      stroke: ${colors.iconDefault};
    }
  }
  
  &:hover {
    background: ${({ isProjectHovered, isActive }) =>
      isActive
        ? colors.surfaceSelectedLight
        : isProjectHovered
          ? colors.grey300
          : colors.grey200};
    }
  background: ${({ isActive }) => (isActive ? colors.surfaceSelectedLight : colors.grey200)};}
  visibility: ${({ isProjectHovered, isActive }) =>
    isProjectHovered || isActive ? "visible" : "hidden"};
`;

export const StarIconWrapper = styled.div<{ active: boolean }>`
  &:hover {
    background: ${colors.indigo200};
    svg { 
      path { 
        ${(p) => p.active && `stroke: none;`}
      }
    }
  }
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  margin: ${spaceMedium};
  padding: ${spaceTiny};
  border-radius: ${borderRadiusMedium};
  background: ${(p) => (p.active ? "transparent" : colors.surfaceSubtle)};
  opacity: ${(p) => (p.active ? "1 !important" : "inherit")};
  svg {
    width: 1.4rem;
    height: 1.4rem;
    path {
      stroke: ${(p) => (p.active ? colors.surfaceBrand : colors.surfaceBrand)};
      fill: ${(p) => (p.active ? colors.surfaceBrand : "transparent")};
    }
  }
}
`;

export const ProjectPictureWrapper = styled.div<{ hoverStyle?: boolean }>`
  position: relative;
  height: ${projectImageHeight};
  > img {
    border-radius: 6px;
    box-sizing: border-box;
    border: 2px solid ${colors.borderSubtle};
  }

  &:hover {
    ${(p) => p.hoverStyle && `background: ${colors.surfaceHover};`}
  }

  cursor: ${(p) => p.hoverStyle && "pointer"};
`;

export const DashText = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

export const FolderNavBar = styled(Row)`
  border-radius: ${borderRadiusMedium};
  align-items: center;
  gap: 0.8rem;
  button.text {
    ${typography.caption};
    color: ${colors.textSecondary};
  }
`;

export const FolderCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 2rem ${spaceMedium};
  border-radius: ${borderRadiusMedium};
  width: 30rem;
  align-items: center;
  justify-items: left;
`;

export const ProjectNameWrapper = styled.div`
  ${typography.sub1};
  color: ${colors.textPrimary};
  padding: 0;
  margin: 0;
  grid-area: projectname;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FolderNameWrapper = styled.div`
  ${typography.sub1};
  color: ${colors.textPrimary};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16rem;
`;

export const AddIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0.4rem;
  border-radius: 50%;
  background: ${colors.surfaceSecondary};
  cursor: pointer;
`;

export const NewProjectPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22rem;
  width: 30rem;
  border-radius: ${borderRadiusMedium};
  border: 1px dashed ${colors.borderDefault};
  cursor: pointer;
  &:hover {
    border-color: ${colors.indigo400};

  &:hover ${AddIconWrapper} {
    background: ${colors.indigo100};
   
  }
`;

export const ProjectWrapper = styled.div<{
  disabled?: boolean;
  nohover?: boolean;
  isHovered?: boolean;
}>`
  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 30rem;
  background: ${colors.surfaceSecondary};

  cursor: ${(p) => !p.nohover && "pointer"};

  border-radius: ${borderRadiusMedium};

  ${StarIconWrapper} {
    opacity: 0;
  }
  &:hover {
    ${StarIconWrapper} {
      opacity: 1;
    }
  }

  &:hover {
    ${(p) => !p.nohover && `background: ${colors.surfaceHover};`}
  }

  > img {
    border-radius: ${borderRadiusMedium};
  }
`;

export const FolderWrapper = styled.div<{
  disabled?: boolean;
  isHovered?: boolean;
}>`
  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 30rem;
  background: ${colors.surfaceSecondary};

  cursor: "pointer";

  border-radius: ${borderRadiusMedium};
  &:hover {
    background: ${colors.surfaceHover};
  }
  &:hover ${DotMenuWrapper} {
    visibility: visible;
  }

  ${(p) => p.isHovered && `background: ${colors.surfaceHover};`}
`;

export const SecondaryText = styled.p`
  ${typography.caption}
  margin: 0;
  padding: 0 0.2rem;
  color: ${colors.textSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BlueCircleIcon = styled.div`
  background-color: ${colors.surfaceBrand};
  border-radius: 5rem;
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
`;
