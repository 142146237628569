import { atom, useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { atomFamily, useJotaiCallback } from "utils/jotai";
import {
  BranchAndParksInProject,
  InvalidateNode,
  admin_getBranchesAndParksInProject,
  admin_getNodesInOrganisation,
  createProjectNodeInOrganisation,
  postOrgNode,
} from "./../../services/customerAPI";
import {
  MoveNode,
  moveNode,
  createProjectNode,
  NodeMutable,
  ProjectNodeInformation,
  isProjectNodeInformation,
  _Node,
  PostNode,
  getTopLevelNodeForNodeId,
} from "../../services/customerAPI";
import { isLoaded, makeLoaded, makeLoading } from "../../types/Load";
import {
  LoadNode,
  LoadingFolder,
  Node,
  deleteNode,
  postNode,
  putNode,
} from "../../services/customerAPI";
import useAblyRefreshToken from "hooks/useAblyRefreshToken";
import {
  ACCESS_ROLE_TO_NUMBER,
  loggedInUserIdAtom,
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import useExplainNodeMove from "./useExplainNodeMove";
import { MoveNodeAccessWarningModalAtom } from "components/Organisation/MoveNodeAccessWarningModal/state";
import { toastMessagesAtom } from "state/toast";
import {
  createOffshoreTutorialProjectService,
  createOnshoreTutorialProjectService,
} from "components/ProjectElements/service";
import { groupsInNodeAndSubnodesAtomFamily } from "components/Organisation/Groups/state";
import { usersNodeAccessInNodeAndSubnodesAtomFamily } from "components/Organisation/state";
import { ProjectType } from "types/user";
import { atomWithDefault } from "jotai/utils";
import { useToast } from "hooks/useToast";
import { useNodesInOrganisationState } from "./useNodesInOrganisationState";
import { useUserAccessState } from "./useUserAccessState";

// ------------ Admin -----------------------
export const admin_nodesInOrgSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom<Promise<Node[]>>(async () => {
      try {
        return await admin_getNodesInOrganisation(organisationId);
      } catch {
        return [];
      }
    }),
);

export const admin_BranchesAndParksInProjectSelectorFamily = atomFamily(
  ({
    organisationId,
    projectId,
  }: {
    organisationId: string;
    projectId: string;
  }) =>
    atom<Promise<BranchAndParksInProject[]>>(async () => {
      return await admin_getBranchesAndParksInProject(
        organisationId,
        projectId,
      );
    }),
);

export const admin_getNodeSelectorFamily = atomFamily(
  ({ organisationId, nodeId }: { organisationId: string; nodeId: string }) =>
    atom<Promise<Node | undefined>>(async (get) => {
      const nodes = await get(
        admin_nodesInOrgSelectorFamily({
          organisationId,
        }),
      );
      return nodes.find((n) => n.id === nodeId);
    }),
);

// -------------------------------------

export const topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily = atomFamily(
  ({
    organisationId,
    projectId,
  }: {
    organisationId: string | undefined;
    projectId: string | undefined;
  }) =>
    atom<string | undefined>((get) => {
      if (projectId === organisationId) return projectId;
      const folder = get(
        topLevelNodeFromAllNodesInStateAtomFamily({
          organisationId,
          nodeId: projectId,
        }),
      );
      return folder?.id;
    }),
);

export const findTopLevelNode = (
  nodes: Node[],
  nodeId: string,
  organisationId: string,
): Node | undefined => {
  const node = nodes.find((n) => n.id === nodeId);
  if (!node || !node.parent_id) return;
  if (node.parent_id === organisationId) return node;
  return findTopLevelNode(nodes, node.parent_id, organisationId);
};

export const topLevelNodeFromAllNodesInStateAtomFamily = atomFamily(
  ({
    organisationId,
    nodeId,
  }: {
    organisationId: string | undefined;
    nodeId: string | undefined;
  }) =>
    atom<Node | undefined>((get) => {
      if (!organisationId || !nodeId) return undefined;
      const nodes = get(
        nodesInOrganisationAtom({
          organisationId,
        }),
      )
        .data.filter(isLoaded)
        .map((n) => n.value);
      const topLevelFromMyNodes = findTopLevelNode(
        nodes,
        nodeId,
        organisationId,
      );

      return topLevelFromMyNodes;
    }),
);

// ASYNC - will trigger suspense
// Consider using topLevelNodeFromAllNodesInStateAtomFamily instead of this
// This will do a fetch to the backend as a fallback if the top level node
// is not found in the users state
const rootLevelNodeFromBackendAtomFamily = atomFamily(
  ({
    organisationId,
    nodeId,
  }: {
    organisationId: string | undefined;
    nodeId: string | undefined;
  }) =>
    atom<Promise<Node | undefined>>(async (get) => {
      if (!organisationId || !nodeId) return undefined;
      const topLevelFromMyNodes = get(
        topLevelNodeFromAllNodesInStateAtomFamily({
          organisationId,
          nodeId,
        }),
      );

      return (
        topLevelFromMyNodes ?? getTopLevelNodeForNodeId(organisationId, nodeId)
      );
    }),
);

export const nodesInOrganisationSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom<Node[]>((get) =>
      organisationId
        ? get(
            nodesInOrganisationAtom({
              organisationId,
            }),
          )
            .data.filter(isLoaded)
            .map((n) => n.value)
        : [],
    ),
);

// Initialised by useStoredNodesForOrganisationState
export const nodesInOrganisationAtom = atomFamily<
  { organisationId: string },
  {
    data: LoadNode[];
    loading: boolean;
    initialised: boolean;
  }
>(() =>
  atomWithDefault<{
    data: LoadNode[];
    loading: boolean;
    initialised: boolean;
  }>(() => {
    return {
      data: [],
      loading: false,
      initialised: false,
    };
  }),
);

export const getNodeSelectorFamily = atomFamily(
  ({ organisationId, nodeId }: { organisationId: string; nodeId: string }) =>
    atom<Node | undefined>((get) => {
      const nodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );
      return nodes.find((n) => n.id === nodeId);
    }),
);

export const getNodesWithMissingParents = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom<Node[]>((get) => {
      const allNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );
      const topNodes = [] as Node[];
      const findTopNode = (nodeId: string, nodes: Node[]): void => {
        const node = nodes.find((n) => n.id === nodeId);
        if (!node || !node.parent_id) return;

        const parentNode = nodes.find((n) => n.id === node.parent_id);
        if (!parentNode) {
          if (topNodes.some((n) => n.id === nodeId)) return;
          topNodes.push(node);
          return;
        }

        return findTopNode(parentNode.id, nodes);
      };

      allNodes.forEach((n) => findTopNode(n.id, allNodes));

      return topNodes;
    }),
);

export const projectNodesInOrganisation = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom<ProjectNodeInformation[]>((get) => {
      if (!organisationId) return [];
      const childrenNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );
      return childrenNodes.filter(isProjectNodeInformation);
    }),
);

export type OrganisationNodeCrud = ReturnType<typeof useOrganisationNodeCrud>;
export const useOrganisationNodeCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const refreshAblyToken = useAblyRefreshToken(organisationId ?? "");
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const { error, warning } = useToast();
  const { refresh: refreshUserAccess } = useUserAccessState();

  const { state, setState, refresh } = useNodesInOrganisationState(
    organisationId ?? "",
  );

  const currentUserId = useAtomValue(loggedInUserIdAtom);
  const { explain } = useExplainNodeMove();

  const setMoveNodeAccessWarningModal = useSetAtom(
    MoveNodeAccessWarningModalAtom,
  );

  const update = useJotaiCallback(
    async (get, _, node_id: string, partialNode: Partial<NodeMutable>) => {
      if (!organisationId) return;
      const storedNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );
      const existingNode = storedNodes.find((f) => f.id === node_id);
      if (!existingNode) throw new Error("id does not exist in stored nodes");

      // NOTE: The different variants of Node has different fields, so if
      // `partialNode` changes `type` without these new fields, the type will
      // be wrong.  Play it safe here and check.
      const node = _Node.parse({
        ...existingNode,
        name: partialNode.name ?? existingNode.name,
        ...partialNode,
      });

      // Optimistic local update
      setState((curr) => {
        const f = curr.data.find((c) => c.value.id === node.id);
        if (!f || f.state === "loading") return curr;
        const updatedData = [
          ...curr.data.filter((c) => c.value.id !== node.id),
          makeLoaded({
            ...f.value,
            ...node,
          }),
        ];
        return {
          ...curr,
          data: updatedData,
        };
      });

      return putNode(node)
        .then((f) => {
          // NOTE: if the node has been updated twice since we started the
          // update, we don't want to overwrite the changes made by the second
          // update.

          setState((curr) => {
            const current = curr.data.find((c) => c.value.id === node.id);
            if (!current || current.state === "loading") return curr;

            const isTheSame = Object.keys(node).every(
              (k) => (f as any)[k] === (node as any)[k],
            );
            if (isTheSame) return curr;
            return {
              ...curr,
              data: [
                ...curr.data.filter((c) => c.value.id !== node.id),
                makeLoaded(f),
              ],
            };
          });
        })
        .catch((e) => {
          setState(() => state);
          throw e;
        })
        .finally(() => {
          console.log(`done update ${node.name}`);
        });
    },
    [organisationId, setState, state],
  );

  const move = useJotaiCallback(
    async (get, _, node_id: string, parent_id: string) => {
      if (!organisationId) return false;
      const userNodeAccess = get(
        userNodeAccessSelectorFamily({
          nodeId: node_id,
        }),
      );
      if (userNodeAccess < ACCESS_ROLE_TO_NUMBER["admin"]) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "You cannot move items without admin access to it",
            type: "warning",
            timeout: 5000,
          },
        ]);
        return;
      }

      if (parent_id === organisationId) {
        const isMemberInOrg = get(
          memberInOrganisationSelectorFamily({
            organisationId,
          }),
        );

        if (!isMemberInOrg) {
          warning(
            "You are not a member of this organisation so you cannot move items into the organisation root",
            {
              timeout: 5000,
            },
          );
          return;
        }
      } else {
        const userParentAccess = get(
          userNodeAccessSelectorFamily({
            nodeId: parent_id,
          }),
        );
        if (userParentAccess < ACCESS_ROLE_TO_NUMBER["admin"]) {
          warning(
            "You cannot move items into folders without admin access to it",
            {
              timeout: 5000,
            },
          );
          return;
        }
      }
      const storedNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );

      const allTopLevelFolders = get(
        getNodesWithMissingParents({
          organisationId,
        }),
      );
      const existingNode = storedNodes.find((f) => f.id === node_id);
      if (!existingNode) throw new Error("id does not exist in stored nodes");
      const verfiedParentNodeId =
        storedNodes.find((f) => f.id === parent_id)?.id ??
        allTopLevelFolders.find((f) => f.id === parent_id)?.id ??
        (parent_id === organisationId ? organisationId : undefined);

      if (!verfiedParentNodeId)
        throw new Error("Failed to find the parent node");

      const node = {
        ...existingNode,
        parent_id: verfiedParentNodeId,
      };

      const groupsWithAccessToNodeTree = await get(
        groupsInNodeAndSubnodesAtomFamily({
          organisationId,
          nodeId: node_id,
        }),
      );

      const usersWithAccessToNodeTree = await get(
        usersNodeAccessInNodeAndSubnodesAtomFamily({
          organisationId,
          nodeId: node_id,
        }),
      );

      const toplevelNodeLoadable = await get(
        rootLevelNodeFromBackendAtomFamily({
          organisationId,
          nodeId: parent_id,
        }),
      );

      if (
        toplevelNodeLoadable?.type === "personal_folder" &&
        (groupsWithAccessToNodeTree.length > 0 ||
          usersWithAccessToNodeTree.length > 0)
      ) {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "You cannot move items with collaborators to your personal folder",
            type: "warning",
            timeout: 5000,
          },
        ]);
        setMoveNodeAccessWarningModal(undefined);
        return false;
      }

      const performSave = async () => {
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Move complete",
            type: "success",
            timeout: 5000,
          },
        ]);
        setMoveNodeAccessWarningModal(undefined);

        // Optimistic local update
        setState((curr) => {
          const f = curr.data.find((c) => c.value.id === node.id);
          if (!f || f.state === "loading") return curr;
          const updated = [
            ...curr.data.filter((c) => c.value.id !== node.id),
            makeLoaded({
              ...f.value,
              ...node,
            }),
          ];
          return {
            ...curr,
            data: updated,
          };
        });

        return moveNode(existingNode.id, verfiedParentNodeId)
          .then((f) => {
            // NOTE: if the node has been updated twice since we started the
            // update, we don't want to overwrite the changes made by the second
            // update.

            setState((curr) => {
              const current = curr.data.find((c) => c.value.id === node.id);
              if (!current || current.state === "loading") return curr;
              const isTheSame = Object.keys(node).every(
                (k) => (f as any)[k] === (node as any)[k],
              );
              if (isTheSame) return curr;
              return {
                ...curr,
                data: [
                  ...curr.data.filter((c) => c.value.id !== node.id),
                  makeLoaded(f),
                ],
              };
            });
          })
          .catch((e) => {
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Move failed",
                type: "error",
                timeout: 5000,
              },
            ]);

            setState(() => state);
            throw e;
          })
          .finally(() => {
            console.log(`done update ${node.name}`);
          });
      };

      setMoveNodeAccessWarningModal({
        state: "searching",
        nodeId: node_id,
        parentId: parent_id,
        nodeType: existingNode.type,
      });
      const consequences = await explain(node_id, parent_id);
      if (
        parent_id === organisationId &&
        consequences.user_accesses.find((u) => u.user_id === currentUserId)
          ?.to === "No access"
      ) {
        error(
          "Moving this to the organisation root would remove your access to it — not allowed",
        );
        setMoveNodeAccessWarningModal(undefined);
        return false;
      }
      if (
        consequences.group_accesses.length > 0 ||
        consequences.user_accesses.length > 0 ||
        consequences.group_accesses.length > 0
      ) {
        setMoveNodeAccessWarningModal({
          state: "action-needed",
          consequences,
          confirm: performSave,
          cancel: () => {
            setState(() => state);
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Move cancelled",
                type: "info",
                timeout: 5000,
              },
            ]);
            setMoveNodeAccessWarningModal(undefined);
          },
          nodeId: node_id,
          nodeType: existingNode.type,
          parentId: parent_id,
        });
        return false;
      } else {
        performSave();
        return true;
      }
    },
    [
      organisationId,
      setMoveNodeAccessWarningModal,
      explain,
      setToastMessages,
      warning,
      setState,
      state,
      currentUserId,
      error,
    ],
  );

  const createFolder = useJotaiCallback(
    async (get, set, args: PostNode) => {
      if (!organisationId) return;

      const loading: LoadingFolder = {
        loading: true,
        type: "folder",
        id: Math.random().toString(),
        createdAt: Date.now(),
        parent: args.parent_id,
      };

      setState((curr) => ({
        ...curr,
        data: [...curr.data, makeLoading(loading)],
      }));

      try {
        const newNode =
          organisationId === args.parent_id
            ? await postOrgNode(args)
            : await postNode(args);

        await Promise.all([refreshAblyToken(), refreshUserAccess()]);

        setState((curr) => ({
          ...curr,
          data: [
            makeLoaded(newNode),
            ...curr.data.filter((c) => c.value.id !== loading.id),
          ],
        }));

        return newNode;
      } catch (e) {
        setState((curr) => ({
          ...curr,
          data: curr.data.filter((c) => c.value.id !== loading.id),
        }));
        throw e;
      }
    },
    [organisationId, refreshAblyToken, setState, refreshUserAccess],
  );
  const createProject = useJotaiCallback(
    async (
      get,
      set,
      args: {
        name: string;
        parent_id: string;
        type: "project";
        project_type: ProjectType;
      },
    ) => {
      if (!organisationId) return;

      const loading: LoadingFolder = {
        loading: true,
        type: "project",
        id: Math.random().toString(),
        createdAt: Date.now(),
        parent: args.parent_id,
      };

      setState((curr) => ({
        ...curr,
        data: [...curr.data, makeLoading(loading)],
      }));

      try {
        const newProNode =
          organisationId === args.parent_id
            ? await createProjectNodeInOrganisation(args)
            : await createProjectNode(args);

        await Promise.all([refreshAblyToken(), refreshUserAccess()]);

        setState((curr) => ({
          ...curr,
          data: [
            ...curr.data.filter(
              (c) => c.value.id !== loading.id && c.value.id !== newProNode.id,
            ),
            makeLoaded(newProNode),
          ],
        }));

        return newProNode;
      } catch (e) {
        setState((curr) => ({
          ...curr,
          data: curr.data.filter((c) => c.value.id !== loading.id),
        }));
        throw e;
      }
    },
    [organisationId, refreshAblyToken, setState, refreshUserAccess],
  );

  const createOffshoreTutorialProject = useJotaiCallback(
    async (get, set, parent_id: string) => {
      if (!organisationId) return;

      const loading: LoadingFolder = {
        loading: true,
        type: "project",
        id: Math.random().toString(),
        createdAt: Date.now(),
        parent: parent_id,
      };

      setState((curr) => ({
        ...curr,
        data: [...curr.data, makeLoading(loading)],
      }));

      try {
        const newProNode =
          await createOffshoreTutorialProjectService(parent_id);

        await Promise.all([refreshAblyToken(), refreshUserAccess()]);

        setState((curr) => ({
          ...curr,
          data: [
            ...curr.data.filter(
              (c) => c.value.id !== loading.id && c.value.id !== newProNode.id,
            ),
            makeLoaded(newProNode),
          ],
        }));

        return newProNode;
      } catch (e) {
        setState((curr) => ({
          ...curr,
          data: curr.data.filter((c) => c.value.id !== loading.id),
        }));
        throw e;
      }
    },
    [organisationId, refreshAblyToken, setState, refreshUserAccess],
  );

  const createOnshoreTutorialProject = useJotaiCallback(
    async (get, set, parent_id: string) => {
      if (!organisationId) return;

      const loading: LoadingFolder = {
        loading: true,
        type: "project",
        id: Math.random().toString(),
        createdAt: Date.now(),
        parent: parent_id,
      };

      setState((curr) => ({
        ...curr,
        data: [...curr.data, makeLoading(loading)],
      }));

      try {
        const newProNode = await createOnshoreTutorialProjectService(parent_id);

        await Promise.all([refreshAblyToken(), refreshUserAccess()]);

        setState((curr) => ({
          ...curr,
          data: [
            ...curr.data.filter(
              (c) => c.value.id !== loading.id && c.value.id !== newProNode.id,
            ),
            makeLoaded(newProNode),
          ],
        }));

        return newProNode;
      } catch (e) {
        setState((curr) => ({
          ...curr,
          data: curr.data.filter((c) => c.value.id !== loading.id),
        }));
        throw e;
      }
    },
    [organisationId, refreshAblyToken, setState, refreshUserAccess],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string) => {
      if (!organisationId) return;

      setState((curr) => ({
        ...curr,
        data: curr.data.filter((c) => c.value.id !== nodeId),
      }));

      try {
        await deleteNode(nodeId);
      } catch (error) {
        setState(() => state);
        throw error;
      }
    },
    [organisationId, setState, state],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, nodeId: string) => {
      if (!organisationId) return;

      const storedNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );

      const relevant = storedNodes.find((n) => n.id === nodeId);
      if (!relevant) return;

      setState((curr) => ({
        ...curr,
        data: curr.data.filter((c) => c.value.id !== nodeId),
      }));
    },
    [organisationId, setState],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, node: Node) => {
      if (!organisationId) return;

      const storedNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );

      const relevant = storedNodes.find((n) => n.id === node.id);
      if (!relevant) return;

      setState((curr) => {
        const f = curr.data.find((c) => c.value.id === node.id);
        if (!f || f.state === "loading") return curr;
        const updated = [
          ...curr.data.filter((c) => c.value.id !== node.id),
          makeLoaded({
            ...f.value,
            ...node,
          }),
        ];

        return {
          ...curr,
          data: updated,
        };
      });
    },
    [organisationId, setState],
  );

  const moveLocal = useJotaiCallback(
    async (get, set, moveNode: MoveNode) => {
      if (!organisationId) return;

      const allTopLevelFolders = await get(
        getNodesWithMissingParents({
          organisationId,
        }),
      );

      // no need to update locally for topLevelFolders we don't have access to
      if (!allTopLevelFolders.some((f) => f.id === moveNode.topLevelNodeId))
        return;

      setState((curr) => {
        const updated = curr.data.map((node) => {
          if (node.value.id !== moveNode.nodeId) return node;
          const loaded = node.getLoaded();
          if (loaded) {
            return makeLoaded({
              ...loaded,
              parent_id: moveNode.toNodeId,
            });
          } else {
            return makeLoading({
              // Safety: if it's not loaded, it's loading.
              ...node.getLoading()!,
              parent: moveNode.toNodeId,
            });
          }
        });

        return {
          ...curr,
          data: updated,
        };
      });
      await Promise.all([refreshAblyToken(), refreshUserAccess()]);
    },
    [organisationId, setState, refreshAblyToken, refreshUserAccess],
  );

  const invalidateLocal = useJotaiCallback(
    async (get, set, invalidateNode: InvalidateNode) => {
      if (!organisationId) return;

      const storedNodes = get(
        nodesInOrganisationSelectorFamily({
          organisationId,
        }),
      );

      // no need to update locally for nodeIds we don't have access to
      if (!storedNodes.some((n) => n.id === invalidateNode.nodeId)) return;

      // refresh the user access state
      // refresh the storedNodesForOrganisation state
      await Promise.all([
        refreshUserAccess(),
        refresh(organisationId),
        refreshAblyToken(),
      ]);
    },
    [organisationId, refresh, refreshUserAccess, refreshAblyToken],
  );

  return {
    create: createFolder,
    createProject,
    createOffshoreTutorialProject,
    createOnshoreTutorialProject,
    remove,
    removeLocal,
    update,
    updateLocal,
    move,
    moveLocal,
    invalidateLocal,
  };
};
