import { useAtom, useAtomValue } from "jotai";
import { showDuplicateBranchForBranchIdAtom } from "components/DuplicateBranchModal/state";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useDuplicateBranch } from "state/timeline";
import useNavigateToBranch from "components/Design/BranchTabBar/useNavigateToBranch";
import { useSetAtom } from "jotai/index";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
} from "components/Design/BranchTabBar/state";
import { useCallback, useEffect } from "react";
import { branchMetaFamily } from "state/jotai/branch";
import { projectIdAtom } from "state/pathParams";
import { useTrackEvent } from "components/OnboardingTours/state";

const useDuplicateBranchAndNavigate = ({ nodeId }: { nodeId: string }) => {
  const duplicateBranch = useDuplicateBranch();
  const navigateToBranch = useNavigateToBranch();
  const setBranchFrameVisible = useSetAtom(allBranchesFrameOpenedAtom);
  const setIsCreatingBranch = useSetAtom(allBranchesFrameLoadingAtom);

  const onDuplicateBranch = useCallback(
    async (branchId: string, title: string) => {
      setIsCreatingBranch(true);
      const res = await duplicateBranch(nodeId, branchId, title);
      setIsCreatingBranch(false);
      if (!res) return;
      const { meta } = res;
      navigateToBranch(meta.id, true);
      setBranchFrameVisible(false);
    },
    [
      setBranchFrameVisible,
      setIsCreatingBranch,
      duplicateBranch,
      nodeId,
      navigateToBranch,
    ],
  );

  return onDuplicateBranch;
};

const DuplicateBranchModalInner = ({
  branchId,
  onClose,
}: {
  branchId: string;
  onClose(): void;
}) => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const branch = useAtomValue(
    branchMetaFamily({ projectId: nodeId, branchId }),
  );
  const duplicateBranch = useDuplicateBranchAndNavigate({ nodeId });
  const trackEvent = useTrackEvent();

  if (!branch) {
    return null;
  }

  return (
    <NewItemModal
      title="Duplicate branch"
      placeholder="Enter branch name"
      defaultValue={`${branch.title} duplicate`}
      onSubmit={async (name) => {
        await duplicateBranch(branchId, name);
        trackEvent("duplicatedBranch");
      }}
      onClose={onClose}
    />
  );
};

const DuplicateBranchModal = () => {
  const [showDuplicateBranchForBranchId, setShowDuplicateBranchForBranchId] =
    useAtom(showDuplicateBranchForBranchIdAtom);

  // Unset value when unmounting = going to projects for instance
  useEffect(() => {
    return () => {
      setShowDuplicateBranchForBranchId(undefined);
    };
  }, [setShowDuplicateBranchForBranchId]);

  if (!showDuplicateBranchForBranchId) {
    return null;
  }

  return (
    <DuplicateBranchModalInner
      branchId={showDuplicateBranchForBranchId}
      onClose={() => {
        setShowDuplicateBranchForBranchId(undefined);
      }}
    />
  );
};

export default DuplicateBranchModal;
