import { useEffect, useRef, useState } from "react";
import LayerPreviewImageV2, {
  LayerPreviewImageV2Props,
} from "./LayerPreviewImageV2";
import styled from "styled-components";

const PlaceholderImage = styled.div<{ width?: string; height?: string }>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface LazyLayerPreviewImageProps extends LayerPreviewImageV2Props {
  width?: string;
  height?: string;
}

const LazyLayerPreviewImage = ({
  width,
  height,
  ...props
}: LazyLayerPreviewImageProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    if (placeholderRef.current) {
      observer.observe(placeholderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!isVisible) {
    return (
      <PlaceholderImage ref={placeholderRef} width={width} height={height} />
    );
  }

  return (
    <LayerPreviewImageV2 {...props} style={{ width, height, ...props.style }} />
  );
};

export default LazyLayerPreviewImage;
