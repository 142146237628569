import { useAtomValue } from "jotai";
import ChannelProviderWrapper from "../../ChannelProviderWrapper";
import { loggedInUserIdAtom } from "state/user";
import { useAblyUserAccess } from "./useAblyUserAccess";
import { useAblyBranchDuplicated } from "components/Ably/ChannelProviders/Organisation/UserAll/useAblyBranchDuplicated";

export default function UserAllProvider({
  ablyId,
  organisationId,
}: {
  ablyId: string;
  organisationId: string;
}) {
  const user = useAtomValue(loggedInUserIdAtom);

  return (
    <>
      {!!user && (
        <ChannelProviderWrapper channelName={`${user}:all`} ablyId={ablyId}>
          <UserAllChannelConsumers
            organisationId={organisationId}
            userId={user}
          />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function UserAllChannelConsumers({
  organisationId,
  userId,
}: {
  organisationId: string;
  userId: string;
}) {
  useAblyUserAccess(organisationId, userId);
  useAblyBranchDuplicated(organisationId);
  return <></>;
}
