import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { IconBtn } from "components/General/Icons";
import { Suspense, useMemo } from "react";
import { spacing6 } from "styles/space";
import styled from "styled-components";
import Close from "@icons/24/Close.svg";
import { colors } from "styles/colors";
import GroupContent from "./content/GroupContent/GroupContent";
import NodeContent from "./content/NodeContent/NodeContent";
import UserContent from "./content/UserContent/UserContent";
import ResourceContent from "./content/ResourceContent/ResourceContent";
import { SelectAnItem, NoInfoToShow } from "./content/empty-states";
import { organisationRightSideModal } from "./state";
import AllDataSection from "../Library/dataLibrary/AllDataSection/AllDataSection";
import { TablePlaceholder } from "./content/shared/TablePlaceholder";
import { useAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { selectedLibraryTabAtom } from "state/library";

const Container = styled.div`
  display: flex;
  height: 100%;
  background: white;
  padding: 3.6rem 2.4rem 0rem;
  box-sizing: border-box;
  transform: translateX(100%); /* Initially positioned off-screen */
  transition: transform 0.5s;
  border-left: 1px solid ${colors.dropdownBorder};
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default function OrganisationRightSide() {
  const organisationId = useAtomValue(organisationIdAtom);
  const navigate = useNavigate();
  const { showConfirm } = useConfirm();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const selectedTab = useAtomValue(selectedLibraryTabAtom);

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  const contentComponent = useMemo(() => {
    switch (content?.type) {
      case "group": {
        if (content.id) {
          return <GroupContent groupId={content.id} key={content.id} />;
        }
        break;
      }
      case "project": {
        if (content.id) {
          return <NodeContent nodeId={content.id} key={content.id} />;
        }
        break;
      }
      case "user": {
        if (content.id) {
          return <UserContent userId={content.id} key={content.id} />;
        }
        break;
      }
      case "resource": {
        if (content.id) {
          return <ResourceContent resourceId={content.id} key={content.id} />;
        }
        break;
      }
      case "no-item-selected": {
        return <SelectAnItem />;
      }

      case "no-info-to-show": {
        return <NoInfoToShow />;
      }

      case "all-data-layers": {
        return (
          <Suspense fallback={<TablePlaceholder />}>
            <AllDataSection />
          </Suspense>
        );
      }
      default:
        return <></>;
    }
  }, [content]);

  return (
    <Container
      style={{
        transform: content ? "translateX(0)" : "translateX(100%)",
        overflowY:
          content?.type === "all-data-layers" || content?.type === "resource"
            ? "hidden"
            : "auto",
        overflowX: content?.type === "resource" ? "hidden" : "auto",
      }}
    >
      {contentComponent}

      {selectedTab !== "datalayers" && (
        <IconBtn
          size="1.2rem"
          style={{
            position: "absolute",
            top: spacing6,
            right: spacing6,
            zIndex: 1,
          }}
          onClick={async () => {
            if (content?.type === "resource") {
              if (
                !unsavedSettings ||
                (await showConfirm({
                  title: "Unsaved settings",
                  message: "Are you sure you want to quit without saving?",
                }))
              ) {
                setUnsavedSettings(false);
                navigate(
                  `/organisation/${organisationId}/library/${selectedTab}`,
                );
              }
            } else {
              setContent(undefined);
            }
          }}
        >
          <Close />
        </IconBtn>
      )}
    </Container>
  );
}
