import { displayLabelPropertyName } from "@constants/canvas";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
} from "@constants/division";
import { FillPaint, LinePaint, SymbolLayerSpecification } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { DivisionFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  divisionSymbolLayerId,
  divisionSourceId,
  divisionOutlineLayerId,
  divisionLayerId,
  MIN_DIVISION_VISIBLE_ZOOM,
} from "./constants";
import { atom, useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { caseexpr } from "./defaults";
import { currentMapStyleAtom } from "state/map";
import { satelliteMapStyleId } from "@constants/availableMapStyles";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

// NOTE: see docs `_ExclusionDomain` in feature.ts for these magic numbers.
const exclusion_zone_color_expression = [
  "case",

  ["==", ["get", "domain"], 7],
  colors.exclusionZone,

  ["==", ["get", "domain"], 3],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 5],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 6],
  colors.exclusionZone2,

  ["==", ["get", "domain"], 1],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 2],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 4],
  colors.exclusionZone3,

  ["==", ["get", "domain"], 8],
  colors.exclusionZone3,

  EXCLUSION_ZONE_COLOR,
];

const divisionFillPaintAtom = atom<FillPaint>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore)
    return {
      "fill-color": [
        "case",
        ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
        colors.onElSubArea,
        colors.onElExclusionZone,
      ],
      "fill-opacity": caseexpr({
        selected: 0.8,
        hover: 0.3,
        fallback: 0.1,
      }),
    };
  return {
    "fill-color": [
      "case",
      ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
      colors.inclusionZone,
      exclusion_zone_color_expression,
    ],
    "fill-opacity": caseexpr({
      selected: 0.8,
      hover: 0.3,
      fallback: 0.15,
    }),
  };
});

const divisionLinePaintAtom = atom<LinePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore)
    return {
      "line-color": [
        "case",
        ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
        caseexpr({
          fallback: colors.onElSubArea,
          hover: colors.onElSubAreaBorderHov,
          selected: colors.onElSubAreaBorderSel,
        }),
        caseexpr({
          fallback: colors.onElExclusionZoneBorder,
          selected: colors.onElExclusionZoneBorderSel,
        }),
      ],
      "line-width": caseexpr({
        fallback: 2,
        hover: 3,
        selected: 3,
        active: 3,
      }),
      "line-opacity": 1.0,
    };
  return {
    "line-color": [
      "case",
      ["==", ["get", "type"], SUB_AREA_PROPERTY_TYPE],
      caseexpr({
        fallback: colors.inclusionZone,
        selected: colors.white,
      }),
      caseexpr({
        fallback: colors.exclusionZone,
        selected: colors.white,
      }),
    ],
    "line-width": caseexpr({
      fallback: 1,
      hover: 2,
      selected: 2,
      active: 2,
    }),
    "line-opacity": 1.0,
  };
});

const useParkNameSymbolLayer: () => SymbolLayerSpecification = () => {
  const currentMapStyle = useAtomValue(currentMapStyleAtom);
  const isSatellite = currentMapStyle.id === satelliteMapStyleId;

  return useMemo(
    () => ({
      id: divisionSymbolLayerId,
      source: divisionSourceId,
      type: "symbol",
      minzoom: 10,
      layout: {
        // prettier-ignore
        "text-field": [
      "case",
      ["==", ["get", "type"], DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE],
      [
        "match",
        ["get", "domain"],
        0, "Exclude nothing",
        1, "Exclude turbines",
        2, "Exclude cables",
        3, "Exclude turbines and cables",

        4, "Exclude substations",
        5, "Exclude turbines and substations",
        6, "Exclude cables and substations",
        7, "Exclude turbines, cables, and substations",

        8, "Exclude anchors",
        9, "Exclude turbines and anchors",
        10, "Exclude cables and anchors",
        11, "Exclude turbines, cables, and anchors",

        12, "Exclude substations and anchors",
        13, "Exclude turbines, substations, and anchors",
        14, "Exclude cables, substations, and anchors",
        15, "Exclude everything",
        "",
      ],
      "",
    ],
        "text-offset": [0, -2],
        "text-size": 10,
        "text-anchor": "center",
      },
      paint: {
        "text-color": [
          "case",
          ["==", ["feature-state", "selected"], true],
          isSatellite ? "#444" : "#ffffff",
          isSatellite ? "#fff" : "#444",
        ],

        "text-halo-width": [
          "case",
          ["==", ["feature-state", "selected"], true],
          1,
          0,
        ],
        "text-halo-blur": [
          "case",
          ["==", ["feature-state", "selected"], true],
          1,
          0,
        ],
        "text-halo-color": [
          "case",
          ["==", ["feature-state", "selected"], true],
          isSatellite ? "#fff" : "#000",
          isSatellite ? "#000" : "#fff",
        ],
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
    }),
    [isSatellite],
  );
};

export const RenderDivisions = ({
  divisions,
  map,
  showSelectionHighlighter,
}: {
  divisions: DivisionFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const divisionFillPaint = useAtomValue(divisionFillPaintAtom);
  const divisionLinePaint = useAtomValue(divisionLinePaintAtom);
  const divisionSymbolLayer = useParkNameSymbolLayer();

  const featureIds = useMemo(() => divisions.map((f) => f.id), [divisions]);

  useEffect(() => {
    map.addSource(divisionSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, divisionSymbolLayerId);
      safeRemoveLayer(map, divisionOutlineLayerId);
      safeRemoveLayer(map, divisionLayerId);
      map.removeSource(divisionSourceId);
    };
  }, [divisionFillPaint, divisionLinePaint, map]);

  useEffect(() => {
    addLayer(map, {
      id: divisionLayerId,
      type: "fill",
      source: divisionSourceId,
      paint: divisionFillPaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: divisionOutlineLayerId,
      type: "line",
      source: divisionSourceId,
      paint: divisionLinePaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, divisionSymbolLayer);
  }, [divisionFillPaint, divisionLinePaint, map, divisionSymbolLayer]);

  useEffect(() => {
    const source = map.getSource(divisionSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(divisions),
    });
  }, [map, divisions]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={divisionSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
