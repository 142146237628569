import { Modal, modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import Button from "../General/Button";
import { Column, ModalFrame } from "../General/Layout";
import { spaceLarge } from "../../styles/space";
import CustomCRSDropdown from "components/CustomCRSDropdown/CustomCRSDropdown";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { useState } from "react";
import useDownloadFeatures from "hooks/useDownloadFeaturesAsZipped";
import Dropdown from "components/Dropdown/Dropdown";
import * as z from "zod";
import { atomLocalStorage } from "utils/jotai";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

export const DownloadCustomCRSModalType = "DownloadCustomCRSModal";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const fileTypes: DropDownItem[] = [
  {
    value: ".shp",
    name: ".shp",
  },
  {
    value: ".geojson",
    name: ".geojson",
  },
  {
    value: ".kml",
    name: ".kml",
  },
  {
    value: ".dxf",
    name: ".dxf",
  },
  {
    value: ".csv",
    name: ".csv (Points only)",
  },
];

const selectedFileTypeAtom = atomLocalStorage<DropDownItem>(
  `vind:CRS:selected`,
  fileTypes[0],
  z.object({ value: z.string(), name: z.string() }),
);

const DownloadCustomCRSModalInner = ({
  modal,
}: {
  modal: Modal<typeof DownloadCustomCRSModalType>;
}) => {
  const { featureIds, name, loadingId, types } = modal.metadata;
  const [selectedFileType, setSelectedFileType] = useAtom(selectedFileTypeAtom);
  const [selectedCRS, setSelectedCRS] = useState<number | undefined>(undefined);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const {
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
  } = useDownloadFeatures();

  return (
    <FullScreenModal>
      <ModalFrame
        title={"Download feature with custom CRS"}
        style={{ width: "45rem" }}
      >
        <Column style={{ gap: spaceLarge }}>
          <p>Specify the wanted file type and CRS.</p>
          <DropdownRow>
            <Dropdown
              small
              style={{ width: "50%" }}
              value={selectedFileType.value}
              onChange={(e) => {
                const fileType = e.target.value;
                const selectedType = fileTypes.find(
                  (availableFileType) => availableFileType.value === fileType,
                );
                if (!selectedType) return;
                setSelectedFileType(selectedType);
              }}
            >
              {fileTypes.map((fileType) => (
                <option key={fileType.value} value={fileType.value}>
                  {fileType.name}
                </option>
              ))}
            </Dropdown>
            <CustomCRSDropdown
              disabled={selectedFileType.value === ".kml"}
              onSelectCRS={(epsg) => {
                setSelectedCRS(epsg.code);
              }}
              small
            />
          </DropdownRow>

          <ButtonRow>
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={() => {
                setModalTypeOpen(undefined);
              }}
            />
            <Button
              text="Download"
              buttonType="primary"
              onClick={() => {
                switch (selectedFileType.value) {
                  case ".shp":
                    downloadMultipleFeaturesShapeUsingId(
                      featureIds,
                      name,
                      loadingId,
                      types,
                      selectedCRS,
                    );
                    break;
                  case ".geojson":
                    downloadMultipleFeaturesGeojsonUsingId(
                      featureIds,
                      name,
                      loadingId,
                      types,
                      selectedCRS,
                    );
                    break;
                  case ".kml":
                    downloadMultipleFeaturesKMLUsingId(
                      featureIds,
                      name,
                      loadingId,
                      types,
                    );
                    break;
                  case ".dxf":
                    downloadMultipleFeaturesDXFUsingId(
                      featureIds,
                      name,
                      loadingId,
                      types,
                      selectedCRS,
                    );
                    break;
                  case ".csv":
                    downloadMultipleFeaturesCSVUsingId(
                      featureIds,
                      name,
                      loadingId,
                      types,
                      selectedCRS,
                    );
                    break;
                }

                setModalTypeOpen(undefined);
              }}
            />
          </ButtonRow>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

const DownloadCustomCRSModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  if (!modalTypeOpen || modalTypeOpen?.modalType !== DownloadCustomCRSModalType)
    return null;

  return <DownloadCustomCRSModalInner modal={modalTypeOpen} />;
};

export default DownloadCustomCRSModal;
