import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import MoveYourDataFreely from "assets/GIS_mode your data freely.mp4";
import DataAccessibleAndCollaborative from "assets/GIS_remove_bottlenecks_love_actually.mp4";
import DataAccessibleAndCollaborative_2 from "assets/GIS_Remove bottlenecks_modern.mp4";
import SingleSourceOfTruth from "assets/GIS Single source of truth_cropped.mp4";
import WindSpecificFeatures from "assets/GIS_Wind_Specific_Features.mp4";

const Step1 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Unified platform</TopHeaderAccent>
          <SubHeader>Make Data Accessible and Collaborative</SubHeader>
          <ContentText>
            Centralise all your GIS data in Vind, ensuring it’s easily
            accessible to your entire organisation.
          </ContentText>
          <ContentText>
            Retain control over who can make changes while empowering others to
            access the data they need—removing bottlenecks and improving
            collaboration.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={DataAccessibleAndCollaborative} />
        </video>
      }
      imagePosition="center"
    />
  );
};

const Step1_2 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Unified platform</TopHeaderAccent>
          <SubHeader>Make Data Accessible and Collaborative</SubHeader>
          <ContentText>
            Centralise all your GIS data in Vind, ensuring it’s easily
            accessible to your entire organisation.
          </ContentText>
          <ContentText>
            Retain control over who can make changes while empowering others to
            access the data they need—removing bottlenecks and improving
            collaboration.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={DataAccessibleAndCollaborative_2} />
        </video>
      }
      imagePosition="center"
    />
  );
};

const Step2 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Version control</TopHeaderAccent>
          <SubHeader>Single source of truth</SubHeader>
          <ContentText>
            Eliminate errors caused by outdated files. With Vind, everyone
            automatically accesses the most up-to-date version of your data.
            Built-in version control and a detailed change log let you track
            changes, see who made them, and restore previous versions, giving
            you complete control.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={SingleSourceOfTruth} />
        </video>
      }
      imagePosition="center"
    />
  );
};

const Step3 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Interoperability</TopHeaderAccent>
          <SubHeader>Move your data freely</SubHeader>
          <ContentText>
            Easily upload and download GIS data using standard formats, ensuring
            compatibility with your GIS tool of choice.
          </ContentText>
          <ContentText>
            No lock-ins, just the flexibility to move your data where it’s
            needed.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={MoveYourDataFreely} />
        </video>
      }
      imagePosition="center"
    />
  );
};

const Step4 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header</TopHeaderAccent>
          <SubHeader>Wind-Specific GIS Features</SubHeader>
          <ContentText>
            Add another dimension to your GIS features. Unlike generic GIS
            tools, Vind allows you to define wind-specific data types such as
            park polygons, exclusion zones, turbine positions, and grid
            connection points.
          </ContentText>
          <ContentText>
            Instantly generate statistics for your wind projects, conduct
            production analyses, and perform environmental assessments, all
            without additional setup.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={WindSpecificFeatures} />
        </video>
      }
      imagePosition="center"
    />
  );
};

export const GisSteps = [Step2, Step3, Step4, Step1, Step1_2];
