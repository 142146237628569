import { MultiPolygon, Point } from "geojson";
import { useCallback, useMemo } from "react";
import { makeExclusionDivisonToExclusionDivisionPolygon } from "../../state/division";
import { ProjectFeature } from "../../types/feature";
import { SubAreaFeature } from "../../types/feature";
import { branchIdAtom, projectIdAtom } from "../../state/pathParams";
import { previewTurbinesState } from "../../state/turbines";
import { ParkFeature } from "../../types/feature";
import * as turf from "@turf/turf";
import {
  DEFAULT_ONSHORE_OFFSHORE_TURBINES,
  GenerationMethodAndParameters,
  OptimizeParameters,
  SimpleTurbineType,
} from "../../types/turbines";
import { pointInPolygon } from "../../utils/geometry";
import { featureIsLocked, isPolygonFeature } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { TurbineFeature } from "../../types/feature";
import { edgeGeneration, regularGeneration } from "./generateLayout";
import { useOptimizationCrud } from "./useOptimizationCrud";
import { getCableIdsInPolygon, point2TurbineFeature } from "./utils";
import * as Sentry from "@sentry/react";
import { AIR_DENSITY } from "../../constants/metocean";
import { exclusionDomainUnpack } from "../../types/feature";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { MooringParameters } from "components/GenerateFoundationsAndAnchors/types";
import {
  makeAnchorPositions,
  mooringIntersectsPolygon,
} from "components/GenerateFoundationsAndAnchors/generate";
import { Raster } from "types/raster";
import { WakeModel } from "services/configurationService";
import { useAtomValue, useSetAtom } from "jotai";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesByDomainFamily } from "state/jotai/exclusionZone";
import {
  currentTurbineIdAtom,
  simpleTurbineTypesAtom,
} from "state/jotai/turbineType";
import {
  existingTurbinesCloseToParkFamily,
  surroundingTurbinesWithZonesFamily,
  turbinesInParkFamily,
} from "state/jotai/turbine";
import { useJotaiCallback } from "utils/jotai";
import { featuresListAtom } from "state/jotai/features";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { useBathymetry } from "hooks/bathymetry";
import { foundationFixedTypesAtom } from "state/jotai/foundation";
import { calculateFoundationWeightPerDepth } from "state/foundations";
import { depthRangeValidAreasFamily } from "./state";
import { isOnshoreAtom } from "state/onshore";
import { DEFAULT_MAX_NEIGHBOUR_DISTANCE_OPTIMIZE_KM } from "@constants/park";

/**
 * Hooks related to optimization.
 */
export function useOptimizeTurbines(
  park: ParkFeature,
  id: string,
  params: OptimizeParameters,
) {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const isOnshore = useAtomValue(isOnshoreAtom);

  const allFoundationTypes = useAtomValue(foundationFixedTypesAtom);

  const subAreas = useAtomValue(
    subAreasInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );

  // TODO: clean this up
  const exlusionZonesMultiPolygon = useAtomValue(
    exclusionZonesByDomainFamily({
      branchId: undefined,
    }),
  ).turbine;

  const depthRangeValidAreas = useAtomValue(
    depthRangeValidAreasFamily({
      _foundationTypeId: params.foundationTypeId ?? undefined,
      _regionId: id,
    }),
  );

  // Filter and reduce precision of zones
  const zone = useMemo(() => {
    const polygon =
      id === park.id
        ? park
        : (subAreas.find((f) => id === f.id)! as SubAreaFeature);
    return turf.truncate(polygon, { precision: 5 });
  }, [id, subAreas, park]);

  const exclusionZones = useMemo(() => {
    const polygons =
      zone.geometry.type === "Polygon"
        ? [zone.geometry.coordinates]
        : zone.geometry.coordinates;
    const relevantExclusionZones =
      makeExclusionDivisonToExclusionDivisionPolygon(
        exlusionZonesMultiPolygon,
      ).filter((p) =>
        polygons.some(
          (polygon) =>
            turf.booleanOverlap(turf.polygon(polygon), p) ||
            turf.booleanContains(turf.polygon(polygon), p),
        ),
      );
    return relevantExclusionZones.map((z) =>
      turf.truncate(z, { precision: 4 }),
    );
  }, [exlusionZonesMultiPolygon, zone]);

  const turbineId = useAtomValue(
    currentTurbineIdAtom({
      projectId,
    }),
  );
  const allTurbines = useAtomValue(simpleTurbineTypesAtom);

  const otherTurbines = useAtomValue(
    surroundingTurbinesWithZonesFamily({
      parkId: park.id,
      branchId,
      maxDistanceKM: DEFAULT_MAX_NEIGHBOUR_DISTANCE_OPTIMIZE_KM,
    }),
  );

  const existingTurbines = useAtomValue(
    existingTurbinesCloseToParkFamily({
      parkId: park.id,
      branchId,
      maxDistanceKM: DEFAULT_MAX_NEIGHBOUR_DISTANCE_OPTIMIZE_KM,
    }),
  );

  const parkTurbines = useAtomValue(
    turbinesInParkFamily({ parkId: park.id, branchId }),
  );

  const initialTurbines = useMemo(() => {
    if (params.method === "micrositing") {
      let turbines = parkTurbines;
      if (subAreas.length !== 0) {
        turbines = parkTurbines.filter((feature) =>
          subAreas.some((e) => pointInPolygon(feature.geometry, e.geometry)),
        );
      }
      return turbines
        .map((f) => turf.truncate(f, { precision: 5 }))
        .map((f) => {
          return {
            lon: f.geometry.coordinates[0],
            lat: f.geometry.coordinates[1],
            type: f.properties.turbineTypeId,
          };
        });
    } else {
      return [];
    }
  }, [params.method, parkTurbines, subAreas]);

  const refLon = isPolygonFeature(zone)
    ? zone.geometry.coordinates[0][0][0]
    : (zone as ProjectFeature<MultiPolygon>).geometry.coordinates[0][0][0][0];
  const refLat = isPolygonFeature(zone)
    ? zone.geometry.coordinates[0][0][1]
    : (zone as ProjectFeature<MultiPolygon>).geometry.coordinates[0][0][0][1];

  const neighbourTurbines = useMemo(() => {
    return otherTurbines
      .map((f) => turf.truncate(f, { precision: 5 }))
      .map((f) => {
        return {
          lon: f.geometry.coordinates[0],
          lat: f.geometry.coordinates[1],
          type: f.properties.turbineTypeId,
        };
      })
      .concat(
        existingTurbines
          .map((f) => turf.truncate(f, { precision: 5 }))
          .map((f) => {
            return {
              lon: f.geometry.coordinates[0],
              lat: f.geometry.coordinates[1],
              type: "generic",
              power: f.properties.power,
            };
          }),
      );
  }, [otherTurbines, existingTurbines]);

  const turbineTypes = useMemo(() => {
    const uniqueInitialTypes = new Set(initialTurbines.map((t) => t.type));
    const uniqueNeighbourTypes = new Set(neighbourTurbines.map((t) => t.type));
    const relevantTurbineTypes = DEFAULT_ONSHORE_OFFSHORE_TURBINES.filter(
      (t) =>
        t.id === turbineId ||
        uniqueInitialTypes.has(t.id) ||
        uniqueNeighbourTypes.has(t.id),
    );
    return Object.fromEntries(relevantTurbineTypes.map((t) => [t.id, t]));
  }, [initialTurbines, turbineId, neighbourTurbines]);

  const { create } = useOptimizationCrud();

  const [, , , bathymetryUrl] = useBathymetry({
    featureId: park.id,
    bufferKm: undefined,
    projectId: undefined,
    branchId: undefined,
  });

  return useCallback(
    (windConfiguration: WindSourceConfiguration | undefined) => {
      if (!projectId || !branchId) return;

      const turbineType = allTurbines.get(turbineId);
      if (!turbineType) return;

      const optZone = depthRangeValidAreas ?? zone;

      const polygons =
        optZone.geometry.type === "Polygon"
          ? [optZone.geometry.coordinates]
          : optZone.geometry.coordinates;
      const parkPolygon = polygons[0][0].map(
        (c) => [c[0], c[1]] as [number, number],
      );
      const constraints = exclusionZones.map((f) => ({
        type: "polygon",
        item: {
          polygon: f.geometry.coordinates as [number, number][][],
        },
      }));
      const wakeSettings = {
        wakeModel: "jensen" as WakeModel,
        turbulenceIntensity: 0.1,
        density: AIR_DENSITY,
        wakeExpansionFactor: isOnshore ? 0.09 : 0.05,
        precision:
          params.method === "exploration"
            ? ("default" as const)
            : ("fast" as const),
        blockage: false,
        neighbourWake: params.includeNeighbours,
        turboparkWakeExpansionCalibration: 0.04,
        neighbourWakeMaxDistance: 20,
      };

      const foundationType = allFoundationTypes.find(
        (f) => f.id === params.foundationTypeId,
      );

      const foundationWeightPerDepth = calculateFoundationWeightPerDepth({
        foundationType,
        turbineType,
      });

      return create({
        ids: {
          nodeId: projectId,
          branchId,
          zoneId: zone.id,
        },
        args: {
          polygons,
          parkPolygon,
          constraints,
          turbineId: turbineType.id,
          turbineTypes,
          windConfiguration,
          wakeSettings,
          neighbourTurbines: params.includeNeighbours ? neighbourTurbines : [],
          runtime: params.runtime,
          method: params.method,
          useFlowersAep: params.method === "exploration" ? true : false,
          foundationWeightPerDepth,
          bathymetryUrl,
          initialTurbines,
          refLonLat: {
            lon: refLon,
            lat: refLat,
          },
          ...params,
        },
      });
    },
    [
      allTurbines,
      branchId,
      create,
      projectId,
      exclusionZones,
      params,
      neighbourTurbines,
      refLat,
      refLon,
      turbineId,
      zone,
      initialTurbines,
      turbineTypes,
      bathymetryUrl,
      allFoundationTypes,
      depthRangeValidAreas,
      isOnshore,
    ],
  );
}

export const setTurbineNames = (ts: TurbineFeature[], n0: number = 1) => {
  ts.sort((a, b) => {
    let cmp = b.geometry.coordinates[1] - a.geometry.coordinates[1];
    if (Math.abs(cmp) < 1e-4)
      return a.geometry.coordinates[0] - b.geometry.coordinates[0];
    return cmp;
  });
  ts.forEach((t, i) => {
    t.properties.name = `#${n0 + i}`;
  });
};

export const useTurbineGeneration = (
  park: ParkFeature,
  selectedZone: ParkFeature | SubAreaFeature,
  currentTurbineType: SimpleTurbineType,
) => {
  const parkId = park.id;
  const branchId = undefined;
  const turbinesInPark = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId,
    }),
  );
  const exclusionZones = useAtomValue(
    exclusionZonesByDomainFamily({
      branchId,
    }),
  ).turbine;

  const { update: updateFeatures } = useProjectElementsCrud();
  const setPreviewState = useSetAtom(previewTurbinesState);

  const computeTurbines = useCallback(
    (
      parameters: GenerationMethodAndParameters,
      mooringParams?: MooringParameters & {
        raster: Raster;
      },
    ) => {
      return Sentry.startSpan(
        {
          name: "Generate turbines",
        },
        () => {
          const anchorExclZones = exclusionZones.filter(
            (e) => exclusionDomainUnpack(e.properties.domain).anchor,
          );

          const turbineExclZones = exclusionZones.filter(
            (e) => exclusionDomainUnpack(e.properties.domain).turbine,
          );
          const pointIsOutsideExclusionZones = (pt: Point): boolean =>
            !turbineExclZones.some((zone) => pointInPolygon(pt, zone.geometry));

          const turbineCanBePlaced = (pt: Point): boolean => {
            if (!mooringParams) return pointIsOutsideExclusionZones(pt);
            const turbineIllegal = !pointIsOutsideExclusionZones(pt);
            if (turbineIllegal) return false;

            let usedDistance = mooringParams.distance;
            if (mooringParams.distanceMode === "depth") {
              const depth = mooringParams.raster.latLngToValue(
                pt.coordinates[0],
                pt.coordinates[1],
              );
              if (depth !== undefined) usedDistance *= -depth / 1000;
            }

            const anchorPositions = makeAnchorPositions(
              {
                type: "Feature",
                geometry: pt,
                properties: {},
              },
              mooringParams.numberOfAnchors,
              usedDistance,
              mooringParams.bearing,
              mooringParams.doubleAnchors,
              mooringParams.doublingAngle,
            );
            const hasBadAnchor = anchorPositions.some((p) => {
              if (
                mooringParams.keepAnchorsInPark &&
                mooringIntersectsPolygon(pt, p.geometry, park.geometry)
              )
                return true;

              if (
                anchorExclZones.some((zone) =>
                  mooringIntersectsPolygon(pt, p.geometry, zone.geometry),
                )
              )
                return true;
              return false;
            });

            if (mooringParams.requireAllAnchors) return !hasBadAnchor;
            return true;
          };

          let turbinePositions: [number, number][] = [];
          if (
            parameters.method === "manual" &&
            !parameters.params.includeEdge
          ) {
            turbinePositions = regularGeneration(
              selectedZone.geometry,
              currentTurbineType.diameter,
              parameters.params,
              turbineCanBePlaced,
            );
          } else if (parameters.method === "manual") {
            turbinePositions = edgeGeneration(
              selectedZone.geometry,
              currentTurbineType.diameter,
              parameters.params,
              pointIsOutsideExclusionZones,
            );
          } else {
            return undefined;
          }

          const turbineFeatures = turbinePositions.map((p) =>
            point2TurbineFeature(p, park.id, currentTurbineType.id),
          );
          setTurbineNames(turbineFeatures);

          return turbineFeatures;
        },
      );
    },
    [currentTurbineType, exclusionZones, park, selectedZone.geometry],
  );

  const makeTurbineFeatures = useCallback(
    (
      turbinePositions: [number, number][],
      turbineTypes?: string[],
      foundationId?: string,
    ) => {
      const turbineFeatures = turbinePositions.map((p, i) =>
        point2TurbineFeature(
          p,
          park.id,
          turbineTypes?.[i] ?? currentTurbineType.id,
          foundationId,
        ),
      );
      return turbineFeatures;
    },
    [currentTurbineType.id, park.id],
  );

  const setPreviewTurbines = useCallback(
    (turbines: TurbineFeature[]) => {
      const notInSelectedZone = (f: TurbineFeature) =>
        !pointInPolygon(f.geometry, selectedZone.geometry, 1e-3);

      const outsideZoneTurbines = turbinesInPark.filter(notInSelectedZone);
      setPreviewState({
        preview: turbines,
        existing: outsideZoneTurbines,
      });
    },
    [selectedZone.geometry, setPreviewState, turbinesInPark],
  );

  const getFeaturesToSave = useJotaiCallback(
    async (get, _, turbineFeatures: TurbineFeature[]) => {
      const turbinesInPark = await get(
        turbinesInParkFamily({
          parkId: park.id,
          branchId: undefined,
        }),
      );
      const projectData = await get(featuresListAtom);
      const mooringLines = await get(
        mooringLinesInParkFamily({
          parkId: park.id,
          branchId: undefined,
        }),
      );
      const anchors = await get(
        anchorsInParkFamily({
          parkId: park.id,
          branchId: undefined,
        }),
      );

      const inSelectedZoneNonLocked = (f: TurbineFeature) =>
        pointInPolygon(f.geometry, selectedZone.geometry, 1e-3) &&
        !featureIsLocked(f);

      const insideZoneNonLockedTurbines = turbinesInPark.filter(
        inSelectedZoneNonLocked,
      );
      const cableIdsToRemove = getCableIdsInPolygon(projectData, selectedZone);
      const mooringLinesToRemove = mooringLines.filter((f) =>
        insideZoneNonLockedTurbines.find((t) => t.id === f.properties.target),
      );

      const anchorIdsToRemove = anchors
        .filter((f) =>
          mooringLinesToRemove.find((m) => m.properties.anchor === f.id),
        )
        .map((f) => f.id);

      return {
        remove: [
          ...insideZoneNonLockedTurbines.map((f) => f.id),
          ...cableIdsToRemove,
          ...mooringLinesToRemove.map((f) => f.id),
          ...anchorIdsToRemove,
        ],
        add: turbineFeatures,
      };
    },
    [park.id, selectedZone],
  );

  /**
   * Save the given turbines to the project, and remove any turbines that are
   * inside the selected zone but not in the given turbines.  Also remove
   * cables, mooring lines and anchors that are connected to the removed
   * turbines.
   */
  const saveTurbines = useCallback(
    async (turbineFeatures: TurbineFeature[]) => {
      const features = await getFeaturesToSave(turbineFeatures);
      updateFeatures(features);
    },
    [getFeaturesToSave, updateFeatures],
  );

  return {
    getFeaturesToSave,
    saveTurbines,
    setPreviewTurbines,
    computeTurbines,
    makeTurbineFeatures,
  };
};
