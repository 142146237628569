import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { MOORING_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useMooringSettings";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";

import { z } from "zod";
import { ExportSystemType } from "services/cableTypeService";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";

export const ComponentsPreviewType = "ComponentsPreview" as const;

export type ComponentType =
  | typeof TURBINE_MENU_ID
  | typeof MOORING_MENU_ID
  | typeof CABLE_MENU_ID
  | typeof SUBSTATION_MENU_ID
  | typeof EXPORT_CABLE_MENU_ID
  | typeof FOUNDATION_MENU_ID;

const _PreviewItem = z.object({
  id: z.string(),
  name: z.string(),
  cost: z.number(),
  costUnit: z.string(),
  level: z.enum(["project", "library", "standard"]).optional(),
});

export type PreviewItem = z.infer<typeof _PreviewItem>;

const _TurbinePreviewItem = _PreviewItem.extend({
  powerRating: z.number(),
});

export type TurbinePreviewItem = z.infer<typeof _TurbinePreviewItem>;

const _CablePreviewItem = _PreviewItem.extend({
  voltage: z.number(),
});
export type CablePreviewItem = z.infer<typeof _CablePreviewItem>;

const _ExportCablePreviewItem = _PreviewItem.extend({
  exportCableType: z
    .union([
      z.literal(ExportSystemType.HVAC.toString()),
      z.literal(ExportSystemType.HVDC.toString()),
    ])
    .optional(),
});
export type ExportCablePreviewItem = z.infer<typeof _ExportCablePreviewItem>;

const _ShippingPreviewItem = z.object({
  region: z.string(),
  cost: z.number(),
  unit: z.string(),
});

const _LibraryPreviewItem = z.object({
  id: z.string(),
  name: z.string(),
  supplyCost: z.number(),
  supplyCostUnit: z.string(),
  shippingCost: z.array(_ShippingPreviewItem),
  costSource: z.enum(["table", "default"]),
  level: z.enum(["project", "library", "standard"]).optional(),
});

const _LibraryTurbinePreviewItem = _LibraryPreviewItem.extend({
  powerRating: z.number(),
});

const _LibraryCablePreviewItem = _LibraryPreviewItem.extend({
  voltage: z.number(),
});

const _LibraryExportCablePreviewItem = _LibraryPreviewItem.extend({
  exportCableType: z
    .union([
      z.literal(ExportSystemType.HVAC.toString()),
      z.literal(ExportSystemType.HVDC.toString()),
    ])
    .optional(),
});

export type LibraryTurbinePreviewItem = z.infer<
  typeof _LibraryTurbinePreviewItem
>;
export type LibraryCablePreviewItem = z.infer<typeof _LibraryCablePreviewItem>;
export type LibraryExportCablePreviewItem = z.infer<
  typeof _LibraryExportCablePreviewItem
>;
