import React, { useMemo, useState } from "react";
import { ProjectFeature } from "../../types/feature";
import { useProjectElementsContext } from "./ProjectElementsContext";
import { ProjectElementFolderType } from "./service";
import { ParkFeatureElement } from "./ParkFeatureElement";

interface ExpandableFeatureElementsProps {
  park: ProjectFeature;
  folder?: ProjectElementFolderType;
  features: ProjectFeature[];
  onDeleteFeatures?(featureIds: string[]): void;
  depth: number;
  name: string;
  icon?: React.ReactNode;
  getFeatureName: (feature: ProjectFeature) => string;
}

export const ExpandableFeatureElements: React.FC<
  ExpandableFeatureElementsProps
> = ({
  park,
  folder,
  features,
  onDeleteFeatures,
  depth,
  name,
  icon,
  getFeatureName,
}) => {
  const { currentSelectionArray } = useProjectElementsContext();
  const [open, setOpen] = useState(false);
  const selected = useMemo(
    () =>
      features
        .map((d) => d.id)
        .every((id) => currentSelectionArray.includes(id)),
    [currentSelectionArray, features],
  );

  if (features.length === 0) return null;

  return (
    <>
      <ParkFeatureElement
        name={name}
        features={features}
        parent={park}
        folder={folder}
        icon={icon}
        isSelected={selected}
        onDeleteFeatures={onDeleteFeatures}
        depth={depth}
        expanded={open}
        onCollapseChange={() => {
          setOpen(!open);
        }}
      />
      {open &&
        features.map((feature) => (
          <ParkFeatureElement
            key={feature.id}
            name={getFeatureName(feature)}
            features={[feature]}
            parent={park}
            folder={folder}
            icon={icon}
            isSelected={!!currentSelectionArray.find((id) => id === feature.id)}
            depth={depth + 1}
            onDeleteFeatures={onDeleteFeatures}
          />
        ))}
    </>
  );
};
