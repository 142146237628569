import { z } from "zod";
import { isMultiPolygonFeature, isDefined } from "../predicates";
import { fastMax, fastMin, partition } from "../utils";
import { SentryExtras, sendInfo } from "../sentry";
import { Feature, Polygon, Position, MultiPolygon } from "geojson";
import * as turf from "@turf/turf";
import { GeometryNoCollection } from "./geojson";

/**
 * Get the "innermost" coordinate list of a GeoJSON geometry.
 * For a point, this is a list of one element, namely the point.
 * For a line string, this is the list of points
 * For a polygon, this is the list of points for the first ring in the list of rings.
 */
export const getCoordinates = (
  coordinates: number[] | number[][] | number[][][] | number[][][][],
): Position[] | null => {
  if (typeof coordinates[0] === "undefined") {
    return null;
  }
  if (typeof coordinates[0] === "number") {
    return [coordinates] as Position[];
  }
  if (typeof coordinates[0][0] === "undefined") return null;
  if (typeof coordinates[0][0] === "number") return coordinates as Position[];
  return getCoordinates(coordinates[0]);
};

/**
 * Order: `[lonMin, latMin, lonMax, latMax]`
 */
export type BBOX = [number, number, number, number];

export const getBBOXArrayFromFeatures = (
  features: Pick<Feature<GeometryNoCollection>, "geometry">[],
): BBOX => {
  const featuresWithNoCoordinates = features.filter(
    (feature) =>
      typeof feature.geometry.coordinates?.[0] === "undefined" ||
      feature.geometry.coordinates?.length === 0,
  );
  if (featuresWithNoCoordinates.length > 0) {
    sendInfo("Some features has no coordinates", {
      featuresWithNoCoordinates,
    });
  }
  const coords = features.flatMap((f) =>
    getCoordinates(f.geometry.coordinates),
  );
  const xCoords = coords
    .filter((c): c is Position => c != null)
    .map((c) => c[0]);
  const yCoords = coords
    .filter((c): c is Position => c != null)
    .map((c) => c[1]);

  return [
    fastMin(xCoords),
    fastMin(yCoords),
    fastMax(xCoords),
    fastMax(yCoords),
  ];
};

/**
 * Check that all elements in the given array can be parsed by the given parser.
 * `scream`s if some elements don't parse.  Returns an array of all parsed
 * elements.
 */
export const checkAllDoParse = <T>(
  array: unknown[],
  parser: z.ZodType<T, z.ZodTypeDef, unknown>,
  extras?: SentryExtras,
): T[] => {
  const [ok, bad] = partition(
    array,
    (e): e is T => parser.safeParse(e).success,
  );

  if (bad.length) {
    sendInfo(`${bad.length} elements did not parse`, { bad, ...extras });
  }

  return ok;
};

const featureIsValid = (feature: Feature) => {
  try {
    turf.cleanCoords(feature);
    return true;
  } catch {
    return false;
  }
};

export const getInvalidPolygonFeatures = (features: Feature[]) =>
  features.filter((f) => !featureIsValid(f)).map((f) => f.id);

export const getBBOXArrayFromCoordinates = (coordinates: Position[]): BBOX => {
  const xCoords = coordinates.map((c) => c[0]);
  const yCoords = coordinates.map((c) => c[1]);
  return [
    fastMin(xCoords),
    fastMin(yCoords),
    fastMax(xCoords),
    fastMax(yCoords),
  ];
};

const getPolygonsWithKinks = (polygons: Feature<Polygon | MultiPolygon>[]) =>
  polygons
    .filter((polygon) => featureIsValid(polygon))
    .flatMap((polygon) => {
      if (isMultiPolygonFeature(polygon)) {
        return polygon.geometry.coordinates.map((c) => {
          const coordinatePolygon = turf.cleanCoords(turf.polygon(c));
          const kinks = turf.kinks(coordinatePolygon);
          if (kinks.features.length === 0) return undefined;
          return { polygon, kinks };
        });
      }
      const kinks = turf.kinks(turf.cleanCoords(polygon));
      if (kinks.features.length === 0) return undefined;
      return { polygon, kinks };
    })
    .filter(isDefined);

export const getSelfIntersectingPolygons = (
  polygons: Feature<Polygon | MultiPolygon>[],
) => {
  const intersecting = getPolygonsWithKinks(polygons);

  const coordinates = intersecting.flatMap((t) =>
    t.kinks.features.map((k) => k.geometry.coordinates),
  );

  // The turf kinks function will return true for coordinates that are the same, but this are not self intersecting
  // so we want to filter out coordinates that appear more than once
  // [a,a,a,b,c] => [b,c]
  // First, create a map to count occurrences of each coordinate
  const coordinateCount = new Map<string, number>();
  coordinates.forEach((c) => {
    const key = `${c[0]},${c[1]}`; // Create a unique key for each coordinate
    coordinateCount.set(key, (coordinateCount.get(key) || 0) + 1);
  });
  // Then, filter out coordinates that appear more than once
  const uniqueCoordinates = coordinates.filter((c) => {
    const key = `${c[0]},${c[1]}`;
    return coordinateCount.get(key) === 1;
  });

  if (uniqueCoordinates.length === 0)
    return { featureIds: [], coordinates: [] };

  const featureIds = intersecting
    .filter(isDefined)
    .map((t) => `${t.polygon.id}`);
  return { featureIds, coordinates: uniqueCoordinates };
};
