import { useEffect } from "react";
import { mapAtom } from "../../state/map";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { anchorSourceId } from "components/Mapbox/constants";
import { atomFamily } from "utils/jotai";
import { anchorsOutsideBoundsFamily } from "state/jotai/anchor";
import { atom, useAtomValue } from "jotai";

export const anchorsOutsideParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const outside = await get(
        anchorsOutsideBoundsFamily({ parkId, branchId }),
      );
      if (outside.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.AnchorsOutsidePark,
        featureIds: outside.map((a) => a.id),
        parkId,
      } as const;
    }),
);

export const AnchorOutsideParkJotai = ({
  parkId,
  source = anchorSourceId,
}: {
  parkId: string;
  source?: string;
}) => {
  const map = useAtomValue(mapAtom);

  const warning = useAtomValue(
    anchorsOutsideParkFamily({ parkId, branchId: undefined }),
  );

  useEffect(() => {
    if (!warning || !map) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });

    return () => {
      if (map.getSource(source) && warning?.featureIds)
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [warning, source, map]);

  return null;
};
