import Fuse from "fuse.js";
import { Node } from "services/customerAPI";

export function searchNodesWithChildren(
  nodes: Node[],
  searchResults: Node[],
): Node[] {
  const matchingNodeIds = new Set<string>();

  // Helper function to add a node and all its descendants to the set
  function addNodeAndDescendants(nodeId: string) {
    if (matchingNodeIds.has(nodeId)) return;
    matchingNodeIds.add(nodeId);

    const children = nodes.filter((node) => node.parent_id === nodeId);
    children.forEach((child) => addNodeAndDescendants(child.id));
  }

  // Add all search results and their descendants
  searchResults.forEach((node) => addNodeAndDescendants(node.id));

  // Return all nodes that are in the matching set
  return nodes.filter((node) => matchingNodeIds.has(node.id));
}

export function setupFuseSearch(nodes: Node[]): Fuse<Node> {
  return new Fuse(nodes, {
    keys: ["name"],
    threshold: 0.3,
  });
}
