import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { addSharedDragStyles } from "components/FolderList/style";
import { Column } from "components/General/Layout";
import { spacing1 } from "styles/space";

export const FolderChildren = styled(Column)`
  gap: ${spacing1};
`;

export const FolderContainsActiveBranchDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  right: 0.5rem;
  background-color: ${colors.blue600};
`;

export const ToplevelDropTargetDiv = styled.div<{
  enableDrag: true;
  isHoveredTop: boolean;
}>`
  position: relative;
  min-height: 2rem;
  flex: 1;

  ${addSharedDragStyles};
`;

export const ImagePlaceholder = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: ${colors.grey300};
`;

export const Text = styled.p`
  ${typography.caption}
  color: ${colors.textPrimary};
  font-weight: 500;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const DateText = styled(Text)`
  color: ${colors.textDisabled};
`;
