import { z } from "zod";

export const _ProgressWorker = z.object({
  /** Zero indexed worker index. */
  i: z.number(),
  /** Total number of workers. */
  n: z.number(),
});
export type ProgressWorker = z.output<typeof _ProgressWorker>;

export const _Progress = z.object({
  /** Number in between 0 and 1, where `0` means "not started" and 1 means "finished".  */
  fraction: z.number(),
  /** Worker data. */
  worker: _ProgressWorker.optional(),
  /** Zero indexed stage, if there is a natural multi-stage problem. */
  stage: z.number().optional(),
});
/**
 * Progress update.
 * See the [Service Progress Messages][0] tech note.
 *
 * [0]: https://www.notion.so/vindai/Service-Progress-Messages-159ff15f8f7b8006899ec0d13acf5fac?pvs=4
 */
export type Progress = z.output<typeof _Progress>;
