import { SkeletonBlock } from "components/Loading/Skeleton";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { useMemo } from "react";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { UsageContainerInner } from "./common";
import {
  UsageContainerPlacement,
  UsageContainerLeftRightPlacement,
  UsageText,
} from "./style";
import { userOrganisationResourcesSyncAtom } from "state/user";
import { ExtraProjectUsageInfo } from "./common";
import { ResourceTypeUsage } from "services/usageService";
import { LibraryManageRoleType } from "components/Organisation/Library/types";

interface LibraryResourceUsageProps {
  organisationId: string;
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">;
  resourceId: string;
  noHoverOnUsage?: boolean;
}

function LibraryResourceUsageInner({
  organisationId,
  libraryManageRole,
  resourceId,
  noHoverOnUsage,
}: LibraryResourceUsageProps) {
  const usageAtom = libraryResourceUsageAtomFamily({
    organisationId,
    libraryManageRole,
    resourceId,
  });

  const usageLoadable = useAtomValue(loadable(usageAtom));

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "15rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="Used in "
      typeName={getResourceTypeNameFromLibraryManageRole(
        libraryManageRole,
        usageLoadable.data.length,
      )}
      placement={UsageContainerPlacement.BOTTOM}
      placementLeftRight={UsageContainerLeftRightPlacement.RIGHT}
      noHover={noHoverOnUsage}
    >
      <ExtraProjectUsageInfo usage={usageLoadable.data} />
    </UsageContainerInner>
  );
}

const getResourceTypeNameFromLibraryManageRole = (
  libraryManageRole: string,
  length: number,
) => {
  const type =
    libraryManageRole === "org_export_cable_manage"
      ? "export cable"
      : libraryManageRole.split("_")[1];
  if (type === "financial" || type === "analysis") {
    if (length === 1) {
      return "branch";
    }
    return "branches";
  }
  if (length === 1) {
    return type;
  }
  return `${type}s`;
};

export function LibraryResourceUsage({
  organisationId,
  libraryManageRole,
  resourceId,
  noHoverOnUsage,
}: LibraryResourceUsageProps) {
  const { initialised, loading, data } = useAtomValue(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const hasLibraryManageAccess = data.some(
    (r) => r["resource_name"] === libraryManageRole,
  );
  if (!organisationId || !initialised || loading || !hasLibraryManageAccess) {
    return null;
  }

  return (
    <LibraryResourceUsageInner
      organisationId={organisationId}
      libraryManageRole={libraryManageRole}
      resourceId={resourceId}
      noHoverOnUsage={noHoverOnUsage}
    />
  );
}

export function UsedInProjects({
  organisationId,
  libraryManageRole,
  resourceId,
}: LibraryResourceUsageProps) {
  const usageAtom = useMemo(
    () =>
      libraryResourceUsageAtomFamily({
        organisationId,
        libraryManageRole,
        resourceId,
      }),
    [organisationId, libraryManageRole, resourceId],
  );

  const usageLoadable = useAtomValue(loadable(usageAtom));

  const uniqueProjects = useMemo(() => {
    if (usageLoadable.state !== "hasData") {
      return [];
    }
    return usageLoadable.data.reduce(
      (pre: ResourceTypeUsage[], cur: ResourceTypeUsage) => {
        return pre.some((p) => p.projectId === cur.projectId)
          ? pre
          : [...pre, cur];
      },
      [],
    );
  }, [usageLoadable]);

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  return <UsageText>{uniqueProjects.length} projects</UsageText>;
}
