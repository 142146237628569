import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { Suspense } from "react";
import TopbarNavigation from "components/Design/TopbarNavigation/TopbarNavigation";
import BranchTabBar from "components/Design/BranchTabBar/BranchTabBar";
import ValidationWarnings from "components/ValidationWarnings/ValidationWarnings";

export default function DesignNavigation() {
  const projectId = useAtomValue(projectIdAtom);
  if (!projectId) return null;
  return (
    <>
      <TopbarNavigation />
      <BranchTabBar>
        <Suspense fallback={null}>
          <ValidationWarnings />
        </Suspense>
      </BranchTabBar>
    </>
  );
}
