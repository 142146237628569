import { ProjectFeature } from "types/feature";
import { featureTypeToReadableNameUpperCase } from "components/GenerateFoundationsAndAnchors/utils";
import { isNumber } from "utils/predicates";
import { isNever } from "utils/utils";

export const templateVariables: Array<{
  template: string;
  replace(
    name: string,
    template: string,
    feature: ProjectFeature,
    index: number,
  ): string;
}> = [
  {
    template: "{{type}}",
    replace: (name, template, feature) =>
      name.replaceAll(
        template,
        featureTypeToReadableNameUpperCase(feature.properties.type ?? ""),
      ),
  },
  {
    template: "{{number}}",
    replace: (name, template, _feature, index) =>
      name.replaceAll(template, index.toString()),
  },
  {
    template: "{{prevName}}",
    replace: (name, template, feature) =>
      name.replaceAll(template, feature.properties.name ?? ""),
  },
];

export enum SortOrder {
  AS_SEEN_IN_ELEMENTS_LIST = "AS_SEEN_IN_ELEMENTS_LIST",
  LATITUDE_ASC = "LATITUDE_ASC",
  LATITUDE_DESC = "LATITUDE_DESC",
  LONGITUDE_ASC = "LONGITUDE_ASC",
  LONGITUDE_DESC = "LONGITUDE_DESC",
}

export const sortFeatures = (
  features: ProjectFeature[],
  sortOrder: SortOrder,
) => {
  return features.sort((a, b) => {
    const aLat = a.geometry?.coordinates[1];
    const bLat = b.geometry?.coordinates[1];
    const aLng = a.geometry?.coordinates[0];
    const bLng = b.geometry?.coordinates[0];

    if (
      !isNumber(aLat) ||
      !isNumber(bLat) ||
      !isNumber(aLng) ||
      !isNumber(bLng)
    ) {
      return 0;
    }

    // We add the || cases here so when we have the same coordinate, we sort on the other coordinate
    // This is to make sure that the sorting is not random when the coordinates are the same
    switch (sortOrder) {
      case SortOrder.AS_SEEN_IN_ELEMENTS_LIST:
        return 0;
      case SortOrder.LATITUDE_ASC:
        return aLat - bLat || aLng - bLng;
      case SortOrder.LATITUDE_DESC:
        return bLat - aLat || aLng - bLng;
      case SortOrder.LONGITUDE_ASC:
        return aLng - bLng || aLat - bLat;
      case SortOrder.LONGITUDE_DESC:
        return bLng - aLng || aLat - bLat;
      default:
        throw isNever(sortOrder);
    }
  });
};
