import { atom } from "jotai";
import { CableType, _CableType } from "services/cableTypeService";
import { getExportCableResourcesOnNode } from "services/libraryService";
import { fetchSchemaWithToken } from "services/utils";
import { projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { _CableLevel, CableTypeWithLevel } from "types/cables";
import { isOnshoreAtom } from "state/onshore";
import { cableResourceWithAccessOnNodeState } from "state/cableType";

export const _projectArrayAndExportCableTypesFamily = atomFamily(
  (nodeId: string) =>
    atomFromFn<Promise<CableType[]>>(() =>
      fetchSchemaWithToken(_CableType.array(), `/api/cable/node/${nodeId}`),
    ),
);

export const projectCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    const cables = await get(_projectArrayAndExportCableTypesFamily(nodeId));
    let arrayCables = cables.filter((c) => !c.exportCableType);
    if (get(isOnshoreAtom)) {
      arrayCables = arrayCables.filter((c) => c.voltage < 66);
    } else arrayCables = arrayCables.filter((c) => c.voltage >= 66);

    return new Map(arrayCables.map((c) => [c.id, c]));
  }),
);

/** @deprecated: Duplicate state of `libraryCableTypesFamily` from `exportCableType.ts` */
export const projectExportCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    const cables = await get(_projectArrayAndExportCableTypesFamily(nodeId));
    const arrayCables = cables.filter((c) => c.exportCableType);
    return new Map(arrayCables.map((c) => [c.id, c]));
  }),
);

export const libraryCableTypesRefresh = atom(0); // TODO(1495): remove this
export const libraryCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    get(libraryCableTypesRefresh);
    let res = await get(cableResourceWithAccessOnNodeState({ nodeId }));
    if (get(isOnshoreAtom)) res = res.filter((c) => c.cable.voltage < 66);
    return new Map(res.map((c) => [c.cable.id, c.cable]));
  }),
);

/** @deprecated: This should be moved to `exportCableType.ts`. */
export const libraryExportCableTypesRefresh = atom(0); // TODO(1495): remove this
/** @deprecated: Duplicate state of `libraryCableTypesFamily` from `exportCableType.ts` */
export const libraryExportCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    get(libraryExportCableTypesRefresh);
    const res = await getExportCableResourcesOnNode(nodeId).catch(() => []);
    return new Map(res.map((c) => [c.cable.id, c.cable]));
  }),
);

export const cableTypesFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atom<Promise<Map<string, CableType>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("cableTypesFamily requires projectIdAtom");
      const [proj, lib] = await Promise.all([
        get(projectCableTypesFamily(projectId)),
        get(libraryCableTypesFamily(projectId)),
      ]);
      return new Map([...proj, ...lib]);
    }),
);

export const IAcableTypesFamily = atomFamily(
  ({ projectId }: { projectId: string | undefined }) =>
    atom<Promise<Map<string, CableType>>>(async (get) => {
      const types = await get(cableTypesFamily({ projectId }));
      const ret = new Map();
      for (const [k, v] of types) if (!v.exportCableType) ret.set(k, v);
      return ret;
    }),
);

export const IAcableTypesByAmpacityFamily = atomFamily(
  ({ projectId }: { projectId: string | undefined }) =>
    atom<Promise<CableType[]>>(async (get) => {
      const cables = await get(cableTypesFamily({ projectId }));
      return Array.from(cables.values()).sort(
        (a, b) => (a.ampacity ?? 0) - (b.ampacity ?? 0),
      );
    }),
);

export const IAcableTypesWithLevelAtom = atom<
  Promise<Map<string, CableTypeWithLevel>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  const ret = new Map<string, CableTypeWithLevel>();
  if (!projectId) return ret;
  const proj = get(projectCableTypesFamily(projectId));
  const lib = get(libraryCableTypesFamily(projectId));
  for (const [id, cable] of await proj)
    ret.set(id, { level: _CableLevel.Values.project, cable });
  for (const [id, cable] of await lib)
    ret.set(id, { level: _CableLevel.Values.library, cable });
  return ret;
});
