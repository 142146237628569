import { atom, useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMouseSampler } from "../../hooks/mouseSampler";
import { getSimpleGebcoBathymetryTile } from "../../services/bathymertyService";
import { fetchEnhancerWithToken } from "../../services/utils";
import { isDefined } from "../../utils/predicates";
import { BathymetryFeature } from "../../types/feature";
import { NO_DATA_VAL } from "./constants";
import { mapAtom } from "state/map";
import { MapMouseEvent } from "mapbox-gl";
import { atomFamily, useAtomUnwrap } from "utils/jotai";
import { Tile, lonLatToTile, tile2NWcorner, tile2SEcorner } from "types/tile";
import { Raster } from "types/raster";
import { pngResponseToDepthArray } from "state/bathymetry";

const SAMPLE_ZOOM = 11;

const decodeBathymetry = (sample: number[]): number => {
  return -32768 + (sample[0] * 256 + sample[1] + sample[2] / 256);
};

export const useCustomDataMouseSample = (
  layer: BathymetryFeature,
): {
  name?: string;
  depth?: number;
} => {
  const projectId = useAtomValue(projectIdAtom);
  const getCustomBathymetrySource = useCallback(
    (x: number, y: number, z: number, tileSize: number) =>
      fetchEnhancerWithToken(
        `/api/bathymetry/custom/${projectId}/${layer.properties.filename}/${x}/${y}/${z}?include_land=true&tilesize=${tileSize}`,
        {
          method: "get",
          headers: {},
        },
      ),
    [layer, projectId],
  );

  const sample = useMouseSampler(
    getCustomBathymetrySource,
    SAMPLE_ZOOM,
    1024,
    layer.geometry.coordinates,
  );

  const depth = useMemo(
    () => (isDefined(sample) ? decodeBathymetry(sample) : NO_DATA_VAL),
    [sample],
  );

  const d = depth <= NO_DATA_VAL ? undefined : depth;
  return {
    name: layer.properties.name,
    depth: d,
  };
};

/**
 * Track the geographical mouse position. Include the map zoom for convenience.
 *
 * Don't update when only zoom changes. This occurs if you zoom in the map,
 * Mapbox zooms * the view such that the cursor is at the same geographical
 * location.
 */
const useMousePosition = () => {
  const map = useAtomValue(mapAtom);

  const [pos, setPos] = useState<{ lon: number; lat: number; zoom: number }>({
    lon: 0,
    lat: 0,
    zoom: 0,
  });

  useEffect(() => {
    if (!map) return;
    const onMouseMove = (e: MapMouseEvent) => {
      setPos({
        lon: e.lngLat.lng,
        lat: e.lngLat.lat,
        zoom: Math.floor(map.getZoom()),
      });
    };
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [map]);

  return pos;
};

/**
 * NOTE: We only use an atom here for caching.
 */
const gebcoRasterTileFamily = atomFamily((t: Tile) =>
  atom<Promise<Raster>>(async () => {
    const res = await getSimpleGebcoBathymetryTile(t.x, t.y, t.z);
    let { depth, width, height } = await pngResponseToDepthArray(res);
    const { lon: minLon, lat: maxLat } = tile2NWcorner(t.x, t.y, t.z);
    const { lon: maxLon, lat: minLat } = tile2SEcorner(t.x, t.y, t.z);

    const sizeLatitude = maxLat - minLat;
    const sizeLongitude = maxLon - minLon;
    const stepLat = sizeLatitude / height;
    const stepLon = sizeLongitude / width;

    let arr = Array.from(depth.slice(0, width * height));
    return new Raster(arr, width, height, minLon, maxLat, stepLon, stepLat);
  }),
);

export const useDepthUnderMouseSampler = (): {
  depth?: number;
  source?: string;
  raster?: Raster;
} => {
  const mouse = useMousePosition();
  if (7 < mouse.zoom) mouse.zoom = 7;
  const tile = lonLatToTile(mouse.lon, mouse.lat, mouse.zoom);
  const raster = useAtomUnwrap(gebcoRasterTileFamily(tile));

  const depth = useMemo(() => {
    if (!raster) return;
    const value = raster.latLngToValue(mouse.lon, mouse.lat);
    return -value;
  }, [mouse.lat, mouse.lon, raster]);

  if (depth === undefined) return {};
  return {
    depth: Math.round(depth / 5) * 5,
    source: "Gebco",
    raster,
  };
};
