import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { LAYER_DEBUG_PRINT } from "../../state/debug";
import { dispose } from "../../utils/three";
import { scream } from "utils/sentry";
import { deg2rad } from "utils/geometry";
import {
  defaultBladeMaterial,
  defaultAmbientLight,
  defaultHemisphereLight,
  defaultNacelleMaterial,
  defaultTowerMaterial,
} from "3d/materials";
import { Point } from "geojson";
import {
  bladeTransform,
  nacelleTransform,
  towerTransform,
} from "3d/turbine_glb";
import { MAP_EXAGGERATION } from "components/MapNative/constants";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export const MIN_ZOOM_TURBINES_VISIBLE = 13;

export type ThreeDTurbineData = {
  lon: number;
  lat: number;
  elevation: number;
  diameter: number;
  hubHeight: number;
}[];

export class ThreeDTurbines2 implements CustomLayerInterface {
  // Mapbox required stuff
  type: "custom" = "custom";
  renderingMode: "2d" | "3d" | undefined = "3d";

  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;

  geometry?: {
    blade: THREE.BufferGeometry;
    tower: THREE.BufferGeometry;
    nacelle: THREE.BufferGeometry;
  };
  // Meshes for each turbine.  We instance one of these meshes per turbine.
  towers: THREE.InstancedMesh | undefined;
  nacelles: THREE.InstancedMesh | undefined;
  blades: THREE.InstancedMesh | undefined;

  // To avoid precision problems we do most things in a local coordinate system
  // centered at this coordinate.  This should just be reasonably close to the
  // park.
  refMerc: mapboxgl.MercatorCoordinate;

  constructor(
    public id: string,
    gltf: GLTF,
    refPt: Point,
  ) {
    const [lon, lat] = refPt.coordinates;
    this.refMerc = mapboxgl.MercatorCoordinate.fromLngLat([lon, lat]);

    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    const light = defaultHemisphereLight();
    light.position.set(0, 0, 1);
    this.scene.add(light);

    const ambient = defaultAmbientLight();
    ambient.name = "ambient";
    this.scene.add(ambient);

    let blade: undefined | THREE.Mesh;
    let tower: undefined | THREE.Mesh;
    let nacelle: undefined | THREE.Mesh;

    gltf.scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        switch (object.name) {
          case "blade":
            blade = object;
            blade.material = defaultBladeMaterial();
            break;
          case "tower":
            tower = object;
            tower.material = defaultTowerMaterial();
            break;
          case "nacelle":
            nacelle = object;
            nacelle.material = defaultNacelleMaterial();
            break;
        }
      }
    });
    if (!blade) throw scream(`Missing "blade" in glb`);
    if (!tower) throw scream(`Missing "tower" in glb`);
    if (!nacelle) throw scream(`Missing "nacelle" in glb`);

    if (!this.scene) throw scream(`Missing scene when adding models`);

    this.geometry = {
      blade: blade.geometry,
      tower: tower.geometry,
      nacelle: nacelle.geometry,
    };
  }

  setData(data: ThreeDTurbineData) {
    if (!this.geometry) throw new Error("Meshes should always be set");
    if (!this.scene) throw new Error("Scene should always be set");

    const n = data.length;

    if (this.towers) {
      this.scene.remove(this.towers);
      this.scene.remove(this.nacelles!);
      this.scene.remove(this.blades!);
      dispose(this.towers);
      dispose(this.nacelles!);
      dispose(this.blades!);
    }

    this.towers = new THREE.InstancedMesh(
      this.geometry.tower,
      new THREE.MeshLambertMaterial({
        color: 0xf4f4f4,
      }),
      n,
    );
    this.towers.name = "towers instancedmesh";

    this.nacelles = new THREE.InstancedMesh(
      this.geometry.nacelle,
      new THREE.MeshLambertMaterial({
        color: 0xcccccc,
      }),
      n,
    );
    this.nacelles.name = "nacelles instancedmesh";

    this.blades = new THREE.InstancedMesh(
      this.geometry.blade,
      new THREE.MeshLambertMaterial({
        color: 0xffffff,
      }),
      3 * n,
    );
    this.blades.name = "blades instancedmesh";

    this.scene.add(this.towers);
    this.scene.add(this.nacelles);
    this.scene.add(this.blades);

    data.forEach((d, i) => {
      const merc = mapboxgl.MercatorCoordinate.fromLngLat(
        [d.lon, d.lat],
        d.elevation * MAP_EXAGGERATION,
      );
      const scale = merc.meterInMercatorCoordinateUnits();

      const worldPos = new THREE.Matrix4().setPosition(
        merc.x - this.refMerc.x,
        merc.y - this.refMerc.y,
        (merc.z ?? 0) - (this.refMerc.z ?? 0),
      );

      const parkAngle = deg2rad(90);

      this.towers?.setMatrixAt(
        i,
        new THREE.Matrix4()
          .multiply(worldPos) // geo position
          .scale(new THREE.Vector3(scale, scale, scale)) // meter to map scale
          .multiply(towerTransform({ hubHeight: d.hubHeight })),
      );

      this.nacelles?.setMatrixAt(
        i,
        new THREE.Matrix4()
          .multiply(worldPos) // geo position
          .scale(new THREE.Vector3(scale, scale, scale)) // meter to map scale
          .multiply(new THREE.Matrix4().makeRotationZ(parkAngle))
          .multiply(
            nacelleTransform({
              hubHeight: d.hubHeight,
            }),
          ),
      );

      for (let k = 0; k < 3; k++) {
        const t0 = Math.floor(Math.abs((Math.sin(i + 1) % 1) * 120));
        const theta = t0 + 120 * k;
        this.blades!.setMatrixAt(
          3 * i + k,
          new THREE.Matrix4()
            .multiply(worldPos) // geo position
            .scale(new THREE.Vector3(scale, scale, scale)) // meter to map scale
            .multiply(new THREE.Matrix4().makeRotationZ(parkAngle))
            .multiply(
              bladeTransform({
                angle: theta,
                hubHeight: d.hubHeight,
                radius: d.diameter / 2,
              }),
            ),
        );
      }
    });
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    this.map = map;
    LAYER_DEBUG_PRINT && console.log("ThreeDTurbines.onAdd");
    map.setLayerZoomRange(this.id, MIN_ZOOM_TURBINES_VISIBLE, 24);
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  /**
   *
   */
  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    if (this.scene) dispose(this.scene);
    this.renderer?.dispose();
  }

  /**
   *
   */
  render(gl: WebGLRenderingContext, matrix: number[]) {
    LAYER_DEBUG_PRINT && console.time("ThreeDTurbines.render");
    if (!this.map || !this.renderer || !this.scene || !this.camera) return;

    this.renderer.resetState();

    const m = new THREE.Matrix4().fromArray(matrix);

    this.camera.projectionMatrix = m
      .clone()
      .multiply(
        new THREE.Matrix4().setPosition(
          this.refMerc.x,
          this.refMerc.y,
          this.refMerc.z ?? 0,
        ),
      );

    gl.enable(gl.CULL_FACE);
    gl.cullFace(gl.FRONT);
    this.renderer.render(this.scene, this.camera);
    gl.disable(gl.CULL_FACE);

    // this.map.triggerRepaint();
    LAYER_DEBUG_PRINT && console.timeEnd("ThreeDTurbines.render");
  }
}
