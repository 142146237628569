import { useCallback, useMemo } from "react";
import { ABLY_ORG_TURBINE_LIBRARY_TYPE_UPDATE } from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import { _SimpleTurbineType } from "types/turbines";
import { useLibraryNodeTurbines } from "../useLibraryNodeTurbines";

export function useAblyLibraryTurbinesForNode(projectId: string) {
  const { updateLocal } = useLibraryNodeTurbines(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const turbine = _SimpleTurbineType.parse(message.data.meta);
      updateLocal(turbine);
    },
    [updateLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ORG_TURBINE_LIBRARY_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
