export const maxResidentialFilterDistance = 5000;
export const maxResidentialFilterZoomLevel = 11.8;

export const availableResidentialBuildingType = [
  "residential",
  "house",
  "apartments",
  "cabin",
  "farm",
  "industrial",
  "building",
];
