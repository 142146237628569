import { projectIdAtom } from "state/pathParams";
import React, {
  ErrorInfo,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce } from "debounce";
import {
  diagonalDistanceWithinAllowedRange,
  getViewShedMultipleResultSelectorFamily,
} from "../../../../state/viewshedAnalysis";
import { Position } from "geojson";
import { mapAtom } from "../../../../state/map";
import { ColorBucket, GeotiffArray } from "../../../MapFeatures/Geotiff";
import { ColoredGrid, Label } from "../../../General/Form";
import { RangeSlider, Slider } from "../../../General/Slider";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "../../../Loading/Skeleton";
import { Column } from "../../../General/Layout";
import { ARTICLE_VIEWSHED, HelpLink } from "../../../HelpTooltip/HelpTooltip";
import DownloadIcon from "@icons/24/Download.svg?react";
import Tooltip from "../../../General/Tooltip";
import { scream } from "../../../../utils/sentry";
import { parkIdAtom } from "../../../../state/pathParams";
import { viewshedLayerId } from "components/Mapbox/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { fastMax, fastMin, roundToDecimal } from "utils/utils";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { rgbToHex } from "utils/image";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { selectedTurbinesAtom } from "state/jotai/selection";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { customerProjectAtomFamily } from "state/timeline";
import { DesignToolMode } from "types/map";
import { wgs84ToProjected } from "utils/proj4";
import * as utm from "utm";
import { sensorPointsFamily } from "state/jotai/sensorPoint";
import { Raster } from "types/raster";
import SensorStats, { SensorStatsType } from "./SensorStats";
import { Color } from "lib/colors";
import { configurationMenuActiveAtom } from "./state";

const MAX_SUPPORTED_TURBINES = 200;

type Props = {
  onClose?(): void;
};

type ErrorProps = {
  children: ReactNode;
};

class ViewShedErrorBoundary extends React.Component<
  ErrorProps,
  {
    hasError: boolean;
    error?: Error;
  }
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    scream(error, {
      errorInfo,
      message: "ViewShedErrorBoundary caught",
    });
    this.setState({
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      return <h5>Something went wrong when running viewshed analysis...</h5>;
    }

    return this.props.children;
  }
}

export const viewshedBucketsAtom = atom<
  {
    color: string;
    from: number;
    to: number;
  }[]
>([]);

const ViewshedSensorStats = ({
  viewshedRaster,
}: {
  viewshedRaster: Raster;
}) => {
  const colorBuckets = useAtomValue(viewshedBucketsAtom);
  const sensorPoints = useAtomValue(
    sensorPointsFamily({
      branchId: undefined,
    }),
  );

  const sensorStats = useMemo(() => {
    return Object.values(
      sensorPoints.reduce(
        (acc, p) => {
          if (
            !viewshedRaster.contains(
              p.geometry.coordinates[0],
              p.geometry.coordinates[1],
            )
          )
            return acc;

          const value = viewshedRaster.latLngToValue(
            p.geometry.coordinates[0],
            p.geometry.coordinates[1],
            0,
          );
          if (value === 0) return acc;
          const bucketIndex = colorBuckets.findIndex(
            (c) => value >= c.from && value <= c.to,
          );
          if (bucketIndex === -1) return acc;

          const bucket = colorBuckets[bucketIndex];
          const uniqueName = `${bucket?.from}-${bucket?.to}`;
          if (!(uniqueName in acc)) {
            acc[uniqueName] = {
              name: `${
                bucket?.from === 0 ? `1` : bucket?.from
              } to ${bucket?.to}`,
              sensors: [],
              color: Color.fromHex(bucket?.color ?? "#000000"),
              index: bucketIndex,
            };
          }
          acc[uniqueName].sensors.push(p);
          return acc;
        },
        {} as Record<string, SensorStatsType & { index: number }>,
      ),
    ).sort((a, b) => b.index - a.index);
  }, [sensorPoints, viewshedRaster, colorBuckets]);

  return <SensorStats sensorStats={sensorStats} />;
};

const ViewShedAnalysisResult = ({
  projectedCoordinates,
  observerHeight,
  maxTurbines,
  bbox,
  zoom,
  epsg,
}: {
  projectedCoordinates: Position[];
  observerHeight: number;
  maxTurbines: number;
  bbox: [number, number, number, number];
  zoom: number;
  epsg: number;
}) => {
  const {
    geotiff: viewshedResult,
    viewshedResultUrl,
    originalTerrainUrl,
    pixelSize,
    viewshedRaster,
  } = useAtomValue(
    getViewShedMultipleResultSelectorFamily({
      projectedCoordinates,
      observerHeight,
      bbox,
      zoom,
      epsg,
    }),
  );

  const map = useAtomValue(mapAtom);
  const [colorGradient, setColorGradient] = useState<ColorBucket[]>([
    {
      color: [255, 255, 190],
      threshold: Math.floor(maxTurbines / 3),
    },
    {
      color: [255, 211, 128],
      threshold: Math.floor((maxTurbines * 2) / 3),
    },
    {
      color: [243, 145, 54],
      threshold: Math.floor(maxTurbines),
    },
  ]);

  useEffect(() => {
    setColorGradient([
      {
        color: [255, 255, 190],
        threshold: Math.floor(maxTurbines / 3),
      },
      {
        color: [255, 211, 128],
        threshold: Math.floor((maxTurbines * 2) / 3),
      },
      {
        color: [243, 145, 54],
        threshold: Math.floor(maxTurbines),
      },
    ]);
  }, [maxTurbines, setColorGradient]);

  const setViewshedBuckets = useSetAtom(viewshedBucketsAtom);

  useEffect(() => {
    const newBuckets = [
      {
        color: rgbToHex(...colorGradient[2].color),
        from: colorGradient[1].threshold,
        to: maxTurbines,
      },
      {
        color: rgbToHex(...colorGradient[1].color),
        from: colorGradient[0].threshold,
        to: colorGradient[1].threshold - 1,
      },
      {
        color: rgbToHex(...colorGradient[0].color),
        from: 0,
        to: colorGradient[0].threshold - 1,
      },
    ];

    setViewshedBuckets(newBuckets);
  });

  const [opacity, setOpacity] = useState(1.0);

  if (!map) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <SubtitleWithLine
        style={{
          paddingTop: "0.8rem",
        }}
        text="Terrain data"
      />
      <ColoredGrid>
        <p>Number of turbines</p>
        <p>{projectedCoordinates.length}</p>

        <p>Method</p>
        <p>
          <a
            href={"https://gdal.org/programs/gdal_viewshed.html"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Gdal viewshed
          </a>
        </p>
        <p>Terrain source</p>

        <p>
          <a
            href={
              "https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/"
            }
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Mapbox
          </a>
        </p>

        <p>Source resolution</p>
        <p>{`${pixelSize[0]}x${pixelSize[1]}m`}</p>
      </ColoredGrid>
      <Tooltip text={"Download terrain as geotiff"}>
        <Button
          size="small"
          text="Terrain"
          icon={<DownloadIcon />}
          buttonType="secondary"
          onClick={() => {
            window.open(originalTerrainUrl, "_blank", "noopener");
          }}
        />
      </Tooltip>
      <SubtitleWithLine
        style={{
          paddingTop: "0.8rem",
        }}
        text="Visalisations"
      />
      <Label>
        <InputTitle>Visible turbines</InputTitle>

        <RangeSlider
          min={0}
          max={colorGradient[2].threshold}
          values={[colorGradient[0].threshold, colorGradient[1].threshold]}
          inside
          outside
          labels
          extremesLabels
          step={1}
          renderLabel={(v) => `${roundToDecimal(v, 1)}`}
          onChange={function (f: [number, number]): void {
            const newColorGradients = [...colorGradient];
            newColorGradients[0].threshold = f[0];
            newColorGradients[1].threshold = f[1];
            setColorGradient(newColorGradients);
          }}
          style={{
            flex: 1,
          }}
          inputColors={colorGradient.map((c) => `rgb(${c.color.join(",")})`)}
        />
      </Label>

      <Label>
        <InputTitle>Coloring opacity</InputTitle>
        <Slider
          min={0}
          max={1}
          step={0.01}
          value={opacity}
          onChange={(newOpacity) => {
            setOpacity(newOpacity);
          }}
          renderLabel={(opacity) => `${(opacity * 100).toFixed(0)}`}
          label={true}
        />
      </Label>

      <Tooltip text={"Download viewshed as geotiff"}>
        <Button
          size="small"
          text="Viewshed"
          icon={<DownloadIcon />}
          buttonType="secondary"
          onClick={() => {
            window.open(viewshedResultUrl, "_blank", "noopener");
          }}
        />
      </Tooltip>

      <ViewshedSensorStats viewshedRaster={viewshedRaster} />

      <GeotiffArray
        opacity={opacity}
        map={map}
        layerId={viewshedLayerId}
        geotiff={viewshedResult}
        colorBuckets={colorGradient}
      />
    </div>
  );
};

const ViewShedAnalysisInner = ({
  projectedCoordinates,
  maxTurbines,
  bbox,
  zoom,
  epsg,
}: {
  projectedCoordinates: Position[];
  maxTurbines: number;
  bbox: [number, number, number, number];
  zoom: number;
  epsg: number;
}) => {
  const map = useAtomValue(mapAtom);
  const [height, setHeight] = useState(1.75);
  const [analysisHeight, setAnalysisHeight] = useState(height);

  const debouncedSetAnalysisHeight = useMemo(
    () =>
      debounce((_height: number) => {
        setAnalysisHeight(_height);
      }, 500),
    [setAnalysisHeight],
  );

  if (!map) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <SubtitleWithLine text="View height" />
      <InputTitle>Height of observer</InputTitle>
      <RangeWithDimInput
        min={1}
        max={2}
        inputStep={0.25}
        value={height}
        onChange={(newHeight) => {
          setHeight(newHeight);
          debouncedSetAnalysisHeight(newHeight);
        }}
        unit={"m"}
      />
      <Suspense
        fallback={
          <Column>
            <SkeletonText text={"Loading terrain..."} />
          </Column>
        }
      >
        <ViewShedAnalysisResult
          maxTurbines={maxTurbines}
          projectedCoordinates={projectedCoordinates}
          observerHeight={analysisHeight}
          bbox={bbox}
          zoom={zoom}
          epsg={epsg}
        />
      </Suspense>
    </div>
  );
};

const ViewshedAnalysisOuter = () => {
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );
  const selectedTurbines = useAtomValue(selectedTurbinesAtom);
  const allTurbines = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const turbinesToUse = selectedTurbines.length
    ? selectedTurbines
    : allTurbines;

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const invalidTurbines = useAtomValue(
    invalidTypesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  ).turbines;

  const [projectedCoordinates, epsg] = useMemo(() => {
    const coords = turbinesToUse.map((t) => [
      ...t.geometry.coordinates.slice(0, 2),
      turbineTypes.get(t.properties.turbineTypeId)?.hubHeight ?? 0,
    ]);
    const Xs = coords.map((c) => c[0]);
    const Ys = coords.map((c) => c[1]);

    const [minX, minY, maxX, maxY] = [
      fastMin(Xs),
      fastMin(Ys),
      fastMax(Xs),
      fastMax(Ys),
    ];

    const origoUTM = utm.fromLatLon((maxY - minY) / 2, (maxX - minX) / 2);

    const projectedCoordinates = coords.map((c) => [
      ...wgs84ToProjected(
        c,
        `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`,
      ),
      c[2],
    ]);

    const epsg = parseInt(
      `32${origoUTM.zoneLetter === "N" ? 6 : 7}${origoUTM.zoneNum}`,
    );

    return [projectedCoordinates, epsg];
  }, [turbinesToUse, turbineTypes]);

  const bbox = useMemo(() => {
    if (!project) return undefined;

    const Xs = projectedCoordinates.map((c) => c[0]);
    const Ys = projectedCoordinates.map((c) => c[1]);

    const [minX, minY, maxX, maxY] = [
      fastMin(Xs),
      fastMin(Ys),
      fastMax(Xs),
      fastMax(Ys),
    ];

    if (project.project_type === DesignToolMode.Onshore) {
      const onshoreBufferMeter = 20_000;
      return [
        minX - onshoreBufferMeter,
        minY - onshoreBufferMeter,
        maxX + onshoreBufferMeter,
        maxY + onshoreBufferMeter,
      ] as [number, number, number, number];
    }

    const maxHeight = fastMax(projectedCoordinates.map((c) => c[2]));
    const radius_of_earth = 6371000;
    const distance_to_horizon =
      Math.sqrt(2 * radius_of_earth * maxHeight) * 1.2;

    return [
      minX - distance_to_horizon,
      minY - distance_to_horizon,
      maxX + distance_to_horizon,
      maxY + distance_to_horizon,
    ] as [number, number, number, number];
  }, [projectedCoordinates, project]);

  const zoom = useMemo(() => {
    if (!project || project.project_type !== DesignToolMode.Onshore)
      return projectedCoordinates.length > 80 ? 9 : 10;
    return 11;
  }, [project, projectedCoordinates]);

  const diagonalDistanceTooFar =
    !diagonalDistanceWithinAllowedRange(projectedCoordinates);

  if (!project || !bbox) {
    return (
      <div>
        <SimpleAlert text={"Not able to fetch project data"} type={"error"} />
      </div>
    );
  }

  if (projectedCoordinates.length >= MAX_SUPPORTED_TURBINES) {
    return (
      <div>
        <SimpleAlert
          text={`Viewshed is currently blocked for more than ${MAX_SUPPORTED_TURBINES} turbines, you are trying to analyze ${projectedCoordinates.length} turbines. You can contact us in the chat if you want to bump this limit.`}
          type={"error"}
        />
      </div>
    );
  }

  return (
    <div>
      {invalidTurbines && (
        <SimpleAlert
          text={
            "Some turbines are using a turbine type that does no longer exist"
          }
          type={"error"}
        />
      )}
      {projectedCoordinates.length === 0 ? (
        <SimpleAlert
          text={"Turbines needed for viewshed analysis"}
          type={"error"}
        />
      ) : diagonalDistanceTooFar ? (
        <SimpleAlert
          text={"Distances between turbines are too great"}
          type={"error"}
        />
      ) : (
        <ViewShedErrorBoundary>
          <ViewShedAnalysisInner
            projectedCoordinates={projectedCoordinates}
            maxTurbines={allTurbines.length}
            bbox={bbox}
            zoom={zoom}
            epsg={epsg}
          />
        </ViewShedErrorBoundary>
      )}
    </div>
  );
};

const ViewshedAnalysis = ({ onClose }: Props) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  return (
    <MenuFrame
      title={"Viewshed"}
      headerId={"viewShed"}
      onExit={onClose}
      icon={<HelpLink article={ARTICLE_VIEWSHED} />}
    >
      <Suspense
        fallback={
          <Column>
            <SkeletonText />
          </Column>
        }
      >
        <div
          ref={scrollBodyRef}
          style={{
            maxHeight: `calc(100vh - 26rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
            overflowY: "auto",
          }}
        >
          <ViewshedAnalysisOuter />
        </div>
      </Suspense>
    </MenuFrame>
  );
};

export default ViewshedAnalysis;
