import { CostUnit } from "types/financial";
import { InputDimensioned, InputNumber } from "components/General/Input";
import { Label, TableWrapper, Tag } from "./styles";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "./state";
import { between } from "utils/validations";
import { KeyValueGridWrapper } from "components/FinancialAnalysis/styles";
import { Column } from "components/General/Layout";
import { CostConfiguration } from "services/costService";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import {
  TitleContainer,
  HorizontalLine,
  Subtitle,
  SecondaryText,
} from "components/SettingsV2/Shared/styles";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { Row } from "../shared";

export const CashFlows = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const { cashFlows, inflation, lifeCycle } = useAtomValue(
    localCostConfigurationAtom,
  );
  const { updateCashFlows, updateInflation } = useLocalCostConfigurationCrud();

  const changelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  return (
    <TableWrapper>
      <Row>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputNumber
            compact
            value={inflation.referenceYearRevenue}
            style={{ width: "6rem", alignSelf: "center" }}
            initialValue={configuration.inflation.referenceYearRevenue}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            onChange={(year) => {
              const referenceYearRevenue = Math.round(year);
              updateInflation({
                referenceYearRevenue,
              });
            }}
            disabled={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "inflation.referenceYearRevenue",
              changelogCategory,
            )}
          />
          <Row style={{ whiteSpace: "nowrap" }}>
            <SecondaryText>Reference year</SecondaryText>
            <HelpTooltip
              size={10}
              text="Numbers in this pane will be inflated / deflated with respect to this year."
            />
          </Row>
        </Label>
      </Row>
      <Column style={{ gap: "1.2rem" }}>
        <TitleContainer>
          <Subtitle>Guaranteed price</Subtitle>
          <HelpTooltip size={10} text="The revenue prior to market price." />
          <HorizontalLine />
        </TitleContainer>
        <div
          style={{
            display: "grid",
            justifyContent: "start",
            gridTemplateColumns: "auto auto auto",
            gap: "2.4rem",
          }}
        >
          <Label>
            <SecondaryText>Price</SecondaryText>
            <InputDimensioned
              style={{ width: "20rem" }}
              value={cashFlows.guaranteedPrice.cost}
              initialValue={configuration.cashFlows.guaranteedPrice.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  guaranteedPrice: {
                    cost,
                    unit: cashFlows.guaranteedPrice.unit,
                  },
                })
              }
              unit={cashFlows.guaranteedPrice.unit}
              disabled={isReadOnly}
              changelogInfo={financialChangelogInfo(
                configuration.id,
                nodeId,
                "cashFlows.guaranteedPrice.cost",
                changelogCategory,
              )}
            />
          </Label>

          <Label>
            <SecondaryText>Years from operation start</SecondaryText>
            <InputDimensioned
              style={{ width: "20rem" }}
              value={cashFlows.guaranteedYears}
              initialValue={configuration.cashFlows.guaranteedYears}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  guaranteedYears: cost,
                })
              }
              unit={CostUnit.years}
              disabled={isReadOnly}
              changelogInfo={financialChangelogInfo(
                configuration.id,
                nodeId,
                "cashFlows.guaranteedYears",
                changelogCategory,
              )}
            />
          </Label>
          <Label style={{ alignSelf: "flex-end", padding: "0.4rem 1.6rem" }}>
            <Tag>
              {(cashFlows.guaranteedYears === 0 && "-") ||
                `${lifeCycle.operationStart} -
                  ${
                    lifeCycle.operationStart + cashFlows.guaranteedYears - 1
                  } inclusive`}
            </Tag>
          </Label>
        </div>
        <TitleContainer>
          <Subtitle>Market price</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <KeyValueGridWrapper>
          <Label>
            <SecondaryText>Price</SecondaryText>
            <InputDimensioned
              style={{ width: "20rem" }}
              value={cashFlows.marketPrice.cost}
              initialValue={configuration.cashFlows.marketPrice.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateCashFlows({
                  marketPrice: { cost, unit: cashFlows.marketPrice.unit },
                })
              }
              unit={cashFlows.marketPrice.unit}
              disabled={isReadOnly}
              changelogInfo={financialChangelogInfo(
                configuration.id,
                nodeId,
                "cashFlows.marketPrice.cost",
                changelogCategory,
              )}
            />
          </Label>
        </KeyValueGridWrapper>
      </Column>
    </TableWrapper>
  );
};
