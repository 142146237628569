import { searchObjectsAtomFamily } from "./state";
import { ReactNode, useEffect } from "react";
import { useSetAtom } from "jotai";

const SearchObject = ({
  title,
  id,
  onSelect,
  tags,
  icon,
  disabled,
}: React.PropsWithChildren<{
  title: string;
  id: string;
  onSelect: () => void;
  icon?: ReactNode;
  tags?: string[];
  disabled?: boolean;
}>) => {
  const setSearchObject = useSetAtom(searchObjectsAtomFamily);

  useEffect(() => {
    setSearchObject((cur) => {
      return [
        ...cur.filter((c) => c.id !== id),
        { title, id, tags, onSelect, icon, disabled },
      ];
    });

    return () => {
      setSearchObject((cur) => [...cur.filter((c) => c.id !== id)]);
    };
  }, [title, id, setSearchObject, tags, onSelect, icon, disabled]);

  return <></>;
};

export default SearchObject;
