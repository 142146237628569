import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import "nouislider/dist/nouislider.css";
import {
  shoreDistanceFilterAtomFamily,
  maxShorelineDistance,
} from "../../state/filter";
import { RangeSlider } from "../General/Slider";
import { useAtom } from "jotai";

const ShorelineDistanceSlider = ({ disabled }: { disabled?: boolean }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [shorelineDistance, setShorelineDistance] = useAtom(
    shoreDistanceFilterAtomFamily({
      projectId,
    }),
  );

  return (
    <label>
      <RangeSlider
        labels
        min={0}
        max={maxShorelineDistance}
        values={shorelineDistance as [number, number]}
        onChange={(range) =>
          setShorelineDistance(
            range.map((v) =>
              v >= maxShorelineDistance ? Number.MAX_SAFE_INTEGER : v,
            ),
          )
        }
        inside
        disabled={disabled}
      />
    </label>
  );
};

export default ShorelineDistanceSlider;
