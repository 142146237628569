import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useRef } from "react";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import { MenuDivider, MenuItem } from "components/General/Menu";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import {
  activePackageIdAtom,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import { organisationLibraryDataPackageResourceState } from "../../state";
import Tooltip from "components/General/Tooltip";
import { Frame } from "components/General/Layout";
import styled from "styled-components";
import { colors } from "styles/colors";
import { useClickOutside } from "hooks/useClickOutside";

const StyledFrame = styled(Frame)`
  max-height: 40rem;
  overflow-y: auto;
  background-color: ${colors.surfacePrimary};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 0;
`;

const AddToPackageFrame = ({
  layerIds,
  buttonRef,
  setIsOpen,
}: {
  layerIds: string[];
  buttonRef?: React.RefObject<HTMLButtonElement>;
  setIsOpen?: (isOpen: boolean) => void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const frameRef = useRef<HTMLDivElement>(null);
  const allPackages = useAtomValue(
    organisationLibraryDataPackageResourceState({
      organisationId,
    }),
  );
  const setSelectedLayers = useSetAtom(
    selectedOrganisationLayerIdsSelector({
      organisationId,
    }),
  );
  const setActivePackageId = useSetAtom(activePackageIdAtom);
  const { addLayersToPackage, createPackage } = useDataLibraryLayersCrud();

  useClickOutside(
    frameRef,
    () => {
      setIsOpen?.(false);
    },
    (target) => {
      if (
        !(target instanceof HTMLElement) &&
        !(target instanceof HTMLDivElement)
      ) {
        return false;
      }
      return buttonRef ? buttonRef.current?.contains(target) ?? false : false;
    },
  );

  const onAddToPackage = useCallback(
    (packageId: string) => {
      Mixpanel.track_old("Add library layer to package from MenuButton", {});
      const pkg = allPackages.find((p) => p.id === packageId);
      if (
        layerIds.length < 2 ||
        confirm(
          `Are you sure you want to add ${layerIds.length} layers to the package ${pkg?.name}?`,
        )
      ) {
        addLayersToPackage(organisationId, packageId, layerIds).then(() => {
          setSelectedLayers([]);
        });
      }
    },
    [
      addLayersToPackage,
      allPackages,
      layerIds,
      organisationId,
      setSelectedLayers,
    ],
  );

  const onCreateNewPackage = useCallback(async () => {
    Mixpanel.track_old(
      "Create organisation package and add layers to created package from MenuButton",
      {
        nrLayers: layerIds.length,
      },
    );
    const pkg = await createPackage(organisationId, {
      name: "Untitled",
    });
    if (pkg) {
      addLayersToPackage(organisationId, pkg.id, layerIds).then(() => {
        setSelectedLayers([]);
      });

      setActivePackageId(pkg.id);
    }
  }, [
    addLayersToPackage,
    createPackage,
    layerIds,
    organisationId,
    setActivePackageId,
    setSelectedLayers,
  ]);

  return (
    <StyledFrame ref={frameRef}>
      {allPackages.length > 0 ? (
        allPackages.map((pkg) => {
          const alreadyAddedToPackage =
            pkg.layerIds.length > 0 &&
            layerIds.every((layerId) => pkg.layerIds.includes(layerId));

          return (
            <Tooltip
              key={pkg.id}
              text="The layer is already added to this package"
              disabled={!alreadyAddedToPackage}
              innerDivStyle={{
                width: "100%",
              }}
              outerDivStyle={{
                padding: 0,
              }}
            >
              <MenuItem
                style={{
                  width: "100%",
                }}
                name={pkg.name}
                onClick={() => onAddToPackage(pkg.id)}
                disabled={alreadyAddedToPackage}
                icon={<DatabaseIcon />}
              />
            </Tooltip>
          );
        })
      ) : (
        <MenuItem name="No packages to show" />
      )}
      <MenuDivider />
      <MenuItem
        name="Create new package"
        onClick={onCreateNewPackage}
        icon={<AddIcon />}
      />
    </StyledFrame>
  );
};

export default AddToPackageFrame;
