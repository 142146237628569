import React, { CSSProperties, useCallback } from "react";
import styled from "styled-components";
import CheckIcon from "@icons/24/Check.svg";
import { colors } from "../../styles/colors";
import { IconREMSize } from "styles/typography";

export enum ToggleSize {
  TINY = 1.3,
  SMALL = 1.75,
  MEDIUM = 2.5,
  LARGE = 4,
}

const getSpacing = ({ size }: { size: ToggleSize }): number => {
  if (size === ToggleSize.TINY) return 0.2;
  if (size === ToggleSize.SMALL) return 0.3;
  if (size === ToggleSize.MEDIUM) return 0.3;
  if (size === ToggleSize.LARGE) return 0.4;
  return 0.0;
};

const getIconSize = (size: ToggleSize): number => {
  if (size === ToggleSize.TINY) return 0.6;
  if (size === ToggleSize.SMALL) return 0.8;
  if (size === ToggleSize.MEDIUM) return 1.2;
  if (size === ToggleSize.LARGE) return 2;
  return 0.0;
};

const Slider = styled.span<{ size: ToggleSize }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition:
    all 0.4s,
    visibility 0s;
  transition:
    all 0.4s,
    visibility 0s;
  border-radius: ${(p) => p.size}rem;
`;

const SliderBall = styled.div<{ size: ToggleSize }>`
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) => p.size - 2 * getSpacing(p)}rem;
  width: ${(p) => p.size - 2 * getSpacing(p)}rem;
  left: ${getSpacing}rem;
  top: ${getSpacing}rem;
  background-color: white;
  -webkit-transition:
    all 0.4s,
    visibility 0s;
  transition:
    all 0.4s,
    visibility 0s;

  ${IconREMSize} {
    path {
      stroke-width: 3;
    }
    transition: all 0.4s linear;
  }
`;

const SliderInput = styled.input<{ size: ToggleSize }>`
  &:checked + ${Slider} {
    background-color: ${colors.surfaceSelectedDark};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${colors.surfaceSelectedDark};
  }

  &:checked + ${Slider} ${SliderBall} {
    -webkit-transform: translateX(${(p) => p.size}rem);
    -ms-transform: translateX(${(p) => p.size}rem);
    transform: translateX(${(p) => p.size}rem);
  }
`;

const SliderLabel = styled.label<{ size: ToggleSize }>`
  position: relative;
  display: inline-block;
  width: ${(p) => p.size * 2}rem;
  height: ${(p) => p.size}rem;
  margin-top: 0;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const SliderBlocker = styled.div<{ disabled?: boolean }>`
  display: ${(p) => (p.disabled ? "inherit" : "none")};
  position: absolute;
  width: 100%;
  opacity: 0.5;
  height: 100%;
  background-color: white;
  cursor: not-allowed;
  z-index: 1;
`;

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

type Props = {
  disabled?: boolean;
  checked: boolean;
  size?: ToggleSize;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  style?: CSSProperties;
  id?: string;
  title?: string;
};

const Toggle = ({
  id,
  disabled,
  checked,
  onChange,
  size = ToggleSize.LARGE,
  style,
  title,
}: Props) => {
  const handleOnClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <SliderWrapper style={style} title={title} onClick={handleOnClick}>
      <SliderLabel size={size}>
        <SliderBlocker disabled={disabled} />
        <SliderInput
          id={id}
          checked={checked}
          type="checkbox"
          onChange={onChange ? onChange : () => {}}
          size={size}
          disabled={disabled}
        />
        <Slider size={size}>
          <SliderBall size={size}>
            <IconREMSize
              height={getIconSize(size)}
              width={getIconSize(size)}
              stroke={colors.surfaceSelectedDark}
              style={{ opacity: checked ? "1" : "0" }}
            >
              <CheckIcon />
            </IconREMSize>
          </SliderBall>
        </Slider>
      </SliderLabel>
    </SliderWrapper>
  );
};

export default Toggle;
