import { Link } from "react-router-dom";
import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";
import {
  borderRadiusMedium,
  spaceLarge,
  spaceSmall,
  spaceTiny,
} from "styles/space";

export enum UsageContainerPlacement {
  TOP = "top",
  BOTTOM = "bottom",
  MIDDLE = "middle",
}
export enum UsageContainerLeftRightPlacement {
  RIGHT = "right",
}

export const SuccessText = styled.div`
  ${typography.caption}
  color: ${colors.textSuccess};
  margin: 0;
`;

export const InfoText = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  margin: 0;
`;

export const Tag = styled.div<{ iconColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  white-space: nowrap;
  color: ${colors.textBrand};
  ${typography.caption}

  gap: ${spaceTiny};
  padding: ${spaceTiny} 0.8rem;
  background: ${colors.surfaceSelectedLight};
  border-radius: ${borderRadiusMedium};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    path {
      stroke-width: 0;
      stroke: ${({ iconColor }) => iconColor || colors.iconBrand};
    }
  }
`;

export const UsageContainer = styled.div<{ fillOrStroke?: "fill" | "stroke" }>`
  border-radius: 0.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  width: fit-content;
  white-space: nowrap;

  position: relative;

  svg {
    path {
      ${({ fillOrStroke }) =>
        fillOrStroke === "fill" ? "fill" : "stroke"}: ${colors.brand};
    }
  }
`;

export const NumberWrapper = styled.div`
  cursor: pointer;
  background: ${colors.blue100};
  gap: 0.4rem;
  padding: 0.3rem 0.8rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const Number = styled.div`
  ${typography.label}
  color: ${colors.textBrand};
  margin: 0;
  cursor: pointer;
  position: relative;
`;

export const ExtraContainer = styled(StandardBox)<{
  placement?: UsageContainerPlacement;
  placementLeftRight?: UsageContainerLeftRightPlacement;
}>`
  position: absolute;
  ${(p) =>
    p.placement === UsageContainerPlacement.TOP
      ? "bottom: 3rem"
      : p.placement === UsageContainerPlacement.MIDDLE
        ? "bottom: 50%; transform: translateY(50%)"
        : "top: 3rem"};
  ${(p) =>
    p.placementLeftRight === UsageContainerLeftRightPlacement.RIGHT
      ? "left: 3rem"
      : "right: 3rem"};
  padding: 1.2rem 1.6rem;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  max-height: 50rem;
  overflow: auto;
  z-index: 2;
`;

export const UsageText = styled.p`
  ${typography.label}
  color: ${colors.primaryText};
  margin: 0;
`;

export const Header = styled.p`
  ${typography.h4}
  margin: 0;
  white-space: nowrap;
`;
export const SubHeader = styled.p`
  ${typography.sub2}
  padding: 0.8rem 0rem;
  white-space: nowrap;
`;

export const NoAccess = styled.p`
  ${typography.label}
  margin: 0;
  white-space: nowrap;
`;

export const LimitAccessHeader = styled.p`
  ${typography.sub4}
  margin: 0;
  white-space: nowrap;
`;

export const LimitAccessText = styled.span`
  ${typography.sub2}
  margin: 0;
  white-space: nowrap;
`;

export const LimitAccessSecondaryText = styled.span`
  ${typography.caption}
  margin: 0;
  white-space: nowrap;
  color: colors.grey500;
`;

export const Name = styled(Link)`
  ${typography.label}
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
`;

export const LimitedAccessContainer = styled.div`
  svg path {
    stroke: none;
  }
  padding-top: ${spaceLarge};
  padding-bottom: ${spaceLarge};
  display: flex;
  gap: ${spaceSmall};
`;
