import Plain from "../assets/plain.jpg";
import Monochrome from "../assets/monochrome.jpg";
import Satellite from "../assets/satellite.jpg";
import Street from "../assets/street.jpg";
import BathBlank from "../assets/bath-blank.png";
import WindSpeed from "../assets/wind-speed.jpg";
import TerrainContour from "../assets/terrain-contour.jpg";
import TerrainHillshading from "../assets/hillshading.jpg";
import BathRaster from "../assets/bath-raster.png";
import BathContour from "../assets/bath-contour.png";
import { DesignToolMode } from "types/map";
import { atom } from "jotai";
import { isOnshoreAtom } from "state/onshore";

export const bathymetryStyles = [
  {
    id: "raster",
    name: "Raster",
    image: BathRaster,
  },
  {
    id: "contour",
    name: "Contours",
    image: BathContour,
  },
];
export type BathymetryStyle = (typeof bathymetryStyles)[number];
export type BathymetryStyleId = BathymetryStyle["id"];

export const getBathymetryStyle = (id: BathymetryStyleId | undefined) =>
  bathymetryStyles.find((style) => style.id === id);

export type MapStyle = {
  /** Mapbox URL. */
  id: string;
  /** .png for preview. */
  image: string;
  /** Human-readable style name. */
  text: string;
  /** If we support custom bathymetry styles */
  useBathymetry: boolean;
};

export enum MapType {
  FLAT = "flat",
  GLOBE = "globe",
}

export const satelliteMapStyleId =
  "mapbox://styles/vindai/clnvfzne8000z01qvbhzthyoj";

export const mapStylesAtom = atom<MapStyle[]>((get) => {
  const onshore = get(isOnshoreAtom);
  const ret = [
    {
      id: onshore
        ? "mapbox://styles/vindai/cm0z200vq018w01pj5h9v0t9f"
        : "mapbox://styles/vindai/clnucl45400nm01plhzq2eja6",
      image: Street,
      text: "Street",
      useBathymetry: !onshore,
    },
    {
      id: satelliteMapStyleId,
      image: Satellite,
      text: "Satellite",
      useBathymetry: false,
    },
  ];
  if (!onshore)
    ret.push({
      id: "mapbox://styles/vindai/cl7khk1rl000l16lnommr0cr3",
      image: Plain,
      text: "Plain",
      useBathymetry: true,
    });
  if (onshore)
    ret.push({
      id: "mapbox://styles/vindai/cm1apcx8f02du01pbgngvckf9",
      image: Monochrome,
      text: "Monochrome",
      useBathymetry: false,
    });
  return ret;
});

export const otherMap = [
  {
    id: "wind",
    name: "Wind",
    tooltip: "Meso scale wind maps",
    image: WindSpeed,
    modes: [DesignToolMode.Onshore, DesignToolMode.Offshore],
  },
  {
    id: "terrain-contour",
    name: "Contour",
    tooltip: "Overlay terrain contours",
    image: TerrainContour,
    modes: [DesignToolMode.Onshore],
  },
  {
    id: "hillshading",
    name: "Hillshading",
    tooltip: "Add hillshading to the terrain",
    image: TerrainHillshading,
    modes: [DesignToolMode.Onshore],
  },
  {
    id: "lcoe-map",
    name: "LCoE",
    image: BathBlank,
    modes: [DesignToolMode.Offshore],
  },
];

export type OtherMap = (typeof otherMap)[number];
export type OtherMapId = OtherMap["id"];

export const getOtherMap = (
  otherMapId: string | undefined,
): (typeof otherMap)[0] | undefined => {
  return otherMap.find((style) => style.id === otherMapId);
};
