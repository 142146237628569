import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { useSetAtom } from "jotai";

import { useCallback } from "react";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import { modalTypeOpenAtom } from "state/modal";

export const useOpenComponentsConfig = (
  projectId: string,
  componentConfigId: string,
) => {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const setSelectedItemId = useSetAtom(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );
  const openComponentConfig = useCallback(() => {
    setSelectedItemId(componentConfigId);
    setModalType({
      modalType: FeatureSettingsModalTypeV2,
    });
  }, [setSelectedItemId, setModalType, componentConfigId]);

  return openComponentConfig;
};
