import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useEffect } from "react";
import { projectVersionAtom } from "../../state/project";
import { versionQueryName } from "../../hooks/useSetVersion";
import { useSetAtom } from "jotai";
import { projectVersionFamily } from "state/jotai/project";

const VersionSync = () => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const version = new URLSearchParams(window.location.search).get(
    versionQueryName,
  );
  const setProjectVersionJAtom = useSetAtom(
    projectVersionFamily({
      projectId,
      branchId,
    }),
  );
  const setProjectVersionAtom = useSetAtom(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
  useEffect(() => {
    setProjectVersionAtom(version ? parseInt(version) : undefined);
    setProjectVersionJAtom(version ? parseInt(version) : undefined);
  }, [version, setProjectVersionJAtom, setProjectVersionAtom, branchId]);
  return null;
};

export default VersionSync;
