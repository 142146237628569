import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { BranchDataSourceEncoding, DataSource } from "./../../state/dataSource";
import { ProjectFeature } from "../../types/feature";
import { redoState, undoState } from "./state";
import { scream, sendWarning } from "../../utils/sentry";
import { v4 as uuid } from "uuid";
import { ABLY_PROJECT_ELEMENTS } from "../../state/ably";
import { prepareProjectUpdateMessagesGzip } from "../../utils/ably";
import { addChangedFeaturesToAckMap } from "./updateAck";
import { useAblyPublish } from "../../hooks/useAblyPublish";
import { loggedInUserMetaInfoAtom } from "../../state/user";
import { Mixpanel } from "../../mixpanel";
import { _FeatureParser } from "../../types/feature";
import { base64DecodeAndUnzip, wait } from "utils/utils";
import { useCallback } from "react";
import { UserMetaInfo } from "types/user";
import * as Sentry from "@sentry/react";
import { aset, useJotaiCallback } from "utils/jotai";
import { allFeaturesFamily } from "state/jotai/features";
import useAblyClient from "components/Ably/useAblyClient";
import { ABLY_SIZE_LIMIT } from "@constants/ably";
import { useToast } from "hooks/useToast";

const updateFeaturesInPlace = (
  changedFeatures: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
  cur: ProjectFeature[],
) => {
  if (
    changedFeatures.add?.length === 0 &&
    changedFeatures.remove?.length === 0 &&
    changedFeatures.update?.length === 0
  ) {
    return cur;
  }

  const currMap = new Map(cur.map((c) => [c.id, true]));
  const toAdd = (changedFeatures.add ?? []).concat(
    (changedFeatures.update ?? []).filter((uf) => !currMap.has(uf.id)),
  );
  const toRemove = [
    ...(changedFeatures.remove ?? []),
    ...(changedFeatures.add ?? []),
  ];
  const withoutRemoved = cur.filter((c) => !toRemove.includes(c.id));
  const toUpdate = changedFeatures.update ?? [];
  const toUpdateMap = new Map(toUpdate.map((f) => [f.id, f]));

  return withoutRemoved
    .map((c) => {
      return toUpdateMap.get(c.id) ?? c;
    })
    .concat(toAdd);
};

const RATE_LIMIT = 50; // messages per second rate limit in ably
const THRESHOLD = 45; // threshold below which throttling is not applied
const DELAY = 1000 / RATE_LIMIT; // milliseconds
const DELAY_BATCH = 500; // milliseconds

export const useProjectElementsCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "undefined-state-dumb";
  const branchId = useAtomValue(branchIdAtom);

  const user = useAtomValue(loggedInUserMetaInfoAtom);
  const client = useAblyClient(projectNodeId);
  const ablyPublish = useAblyPublish(projectNodeId);
  const { error, warning } = useToast();

  const publishMessageGzip = useCallback(
    (
      m: {
        add?: string[] | undefined;
        remove?: string[] | undefined;
        update?: string[] | undefined;
        dataSource: DataSource;
      },
      projectNodeId: string,
      source: {
        projectId: string;
        branchId: string;
      },
      user: UserMetaInfo,
      uniqueUpdateId: string,
    ) => {
      return new Promise((res) => {
        ablyPublish(
          `project-update:node:${projectNodeId}:branch:${source.branchId}`,
          ABLY_PROJECT_ELEMENTS,
          {
            ...m,
            dataSource: {
              ...m.dataSource,
              encoding: BranchDataSourceEncoding.GZIP,
            },
            id: uniqueUpdateId,
            author: user.user_id,
          },
          (err) => {
            if (err) {
              if (
                err.message.includes(
                  "Maximum size of messages that can be published",
                )
              ) {
                Mixpanel.track_old(
                  "Maximum size of messages that can be published",
                  {},
                );
                error(
                  `A single feature is above the maximum limit of ≈${Math.round(ABLY_SIZE_LIMIT / 1024)}KB, this is not supported at the moment.`,
                  {
                    timeout: 10000,
                  },
                );
              } else {
                scream(err.message, {
                  feature: {
                    ...m,
                    id: uniqueUpdateId,
                  },
                  error: err,
                });
                error(`Save failed: ${err.message}`, {
                  timeout: 10000,
                });
              }
            } else {
              const { add: addEncoded, remove, update: updateEncoded } = m;
              const add = addEncoded?.map((e) =>
                base64DecodeAndUnzip<ProjectFeature>(e),
              );
              const update = updateEncoded?.map((e) =>
                base64DecodeAndUnzip<ProjectFeature>(e),
              );
              addChangedFeaturesToAckMap(uniqueUpdateId, {
                add,
                remove,
                update,
              });
            }
            res(err);
          },
        ).then((d) => res(d));
      });
    },
    [ablyPublish, error],
  );

  const publishMessagesWithRateLimitGzip = useCallback(
    async (
      messages: {
        add?: string[] | undefined;
        remove?: string[] | undefined;
        update?: string[] | undefined;
        dataSource: DataSource;
      }[],
      projectNodeId: string,
      source: {
        projectId: string;
        branchId: string;
      },
      user: UserMetaInfo,
      uniqueUpdateId: string,
    ) => {
      let accumulatedSize = 0;

      // Ably have issues when we send too many messages at once. It will send them in one SQS message from their end which will go
      // above the 256kb limit. Therefore, we must split the messages into batches of ≈256kb and send them with a small time delay so ably groups them seperately.
      // The code below does this batching within the size limit
      const messagesBatches = messages.reduce(
        (acc, m) => {
          const messageSize = new Blob([
            JSON.stringify({
              ...m,
              dataSource: {
                ...m.dataSource,
                encoding: BranchDataSourceEncoding.GZIP,
              },
              id: uuid(), // Actual id doesn't matter, just to get size
              author: user.user_id,
            }),
          ]).size;

          accumulatedSize += messageSize;
          const messageIndex = Math.floor(accumulatedSize / ABLY_SIZE_LIMIT);

          // We passed the limit, create a new batch
          if (messageIndex > 0) {
            acc.push([]);
            accumulatedSize = messageSize;
          }

          // Always push to the last batch
          acc[acc.length - 1].push(m);
          return acc;
        },
        [[]] as {
          add?: string[] | undefined;
          remove?: string[] | undefined;
          update?: string[] | undefined;
          dataSource: DataSource;
        }[][],
      );

      for (
        let messagesBatchIndex = 0;
        messagesBatchIndex < messagesBatches.length;
        messagesBatchIndex++
      ) {
        if (messagesBatchIndex !== 0) {
          await wait(DELAY_BATCH); // Delay to respect the rate limit
        }
        const messagesBatch = messagesBatches[messagesBatchIndex];
        if (messagesBatch.length <= THRESHOLD) {
          // If number of messages is below the threshold, publish without delay
          await Promise.all(
            messagesBatch.map(async (m) =>
              publishMessageGzip(
                m,
                projectNodeId,
                source,
                user,
                uniqueUpdateId,
              ),
            ),
          );
        } else {
          // If number of messages exceeds the threshold, publish with delay
          await Promise.all(
            messagesBatch.map(async (m, i) => {
              await wait(DELAY * i); // Delay to respect the rate limit
              await publishMessageGzip(
                m,
                projectNodeId,
                source,
                user,
                uniqueUpdateId,
              );
            }),
          );
        }
      }
    },
    [publishMessageGzip],
  );

  const updateUndoRedo = useJotaiCallback(
    (
      _get,
      set,
      source: {
        projectId: string;
        branchId: string;
      },
      changedFeatures: {
        add?: ProjectFeature[];
        remove?: string[];
        update?: ProjectFeature[];
      },
      dataSourcefeatures: ProjectFeature[],
      uniqueUpdateId: string,
      removeIds: Map<string, boolean>,
      updateIds: Map<string, boolean>,
    ) => {
      set(undoState(source), (cur) => [
        ...cur,
        {
          action: {
            remove: changedFeatures.add?.map((f) => f.id),
            add: dataSourcefeatures.filter((f) => removeIds.has(f.id)),
            update: dataSourcefeatures.filter((f) => updateIds.has(f.id)),
            id: uniqueUpdateId,
          },
          originAction: changedFeatures,
        },
      ]);
      set(redoState(source), []);
    },
    [],
  );

  const updateDataSourceJotai = useJotaiCallback(
    (
      get,
      set,
      source: {
        projectId: string;
        branchId: string;
      },
      features: ProjectFeature[],
    ) => {
      set(
        allFeaturesFamily({
          ...source,
          version: undefined,
        }),
        features,
      );
    },
    [],
  );

  const updateDataSource = useJotaiCallback(
    async (
      get,
      set,
      ds: DataSource,
      changedFeatures: {
        add?: ProjectFeature[];
        remove?: string[];
        update?: ProjectFeature[];
      },
      isUndo?: boolean,
      isRedo?: boolean,
    ) => {
      if (!client) {
        error(
          "Failed to connect & save. Please refresh your webpage and try again.",
          {
            timeout: 10000,
          },
        );

        return;
      }
      const messages = prepareProjectUpdateMessagesGzip(ds, changedFeatures);
      const overSizeMessages = messages.filter(
        (m) => new Blob([JSON.stringify(m)]).size > ABLY_SIZE_LIMIT,
      );
      if (overSizeMessages.length > 0) {
        warning(
          `A single feature is above the maximum limit of ≈${Math.round(ABLY_SIZE_LIMIT / 1024)}KB, this is not supported at the moment.`,
          {
            timeout: 10000,
          },
        );
        return;
      }

      Sentry.addBreadcrumb({
        category: "project",
        message: "Updating datasource",
        data: {
          nrAddedFeatures: changedFeatures.add?.length,
          nrRemovedFeatures: changedFeatures.remove?.length,
          nrUpdatedFeatures: changedFeatures.update?.length,
        },
      });

      const uniqueUpdateId = uuid();
      const source = {
        projectId: ds.projectId,
        branchId: ds.id,
      };

      const featuresAtom = allFeaturesFamily({
        branchId: source.branchId,
        projectId: source.projectId,
        version: undefined,
      });
      const dataSourcefeatures = await get(featuresAtom);

      if (!isUndo && !isRedo) {
        const removeIds = new Map(
          changedFeatures.remove?.map((f) => [f, true]),
        );
        const updateIds = new Map(
          changedFeatures.update?.map((f) => [f.id, true]),
        );

        updateUndoRedo(
          source,
          changedFeatures,
          dataSourcefeatures,
          uniqueUpdateId,
          removeIds,
          updateIds,
        );
      }

      const oldFeatures = dataSourcefeatures;
      const newFeatures = updateFeaturesInPlace(changedFeatures, oldFeatures);
      set(featuresAtom, newFeatures);
      updateDataSourceJotai(source, newFeatures);

      await publishMessagesWithRateLimitGzip(
        messages,
        projectNodeId,
        source,
        user,
        uniqueUpdateId,
      );
    },
    [
      client,
      projectNodeId,
      publishMessagesWithRateLimitGzip,
      updateDataSourceJotai,
      updateUndoRedo,
      user,
      error,
      warning,
    ],
  );

  const update = useJotaiCallback(
    async (
      _get,
      _set,
      changedFeatures: {
        add?: ProjectFeature[];
        remove?: string[];
        update?: ProjectFeature[];
      },

      isUndo?: boolean,
      isRedo?: boolean,
      branchIdToUpdate?: string,
    ) => {
      if (!branchId) return;

      const safeAddFeatures = [];
      const safeUpdateFeatures = [];
      for (const f of changedFeatures.add ?? []) {
        const res = _FeatureParser.safeParse(f);
        if (res.success) {
          safeAddFeatures.push(f);
          continue;
        }
        sendWarning("Added feature doesn't parse", {
          f,
          error: res.error,
        });
      }
      for (const f of changedFeatures.update ?? []) {
        const res = _FeatureParser.safeParse(f);
        if (res.success) {
          safeUpdateFeatures.push(f);
          continue;
        }
        sendWarning("Updated feature doesn't parse", {
          f,
          error: res.error,
        });
      }

      return updateDataSource(
        {
          projectId: projectNodeId,
          id: branchIdToUpdate ?? branchId,
          type: "branch",
        },
        {
          ...changedFeatures,
          add: safeAddFeatures,
          update: safeUpdateFeatures,
        },
        isUndo,
        isRedo,
      );
    },
    [branchId, projectNodeId, updateDataSource],
  );

  const localUpdateJotai = useJotaiCallback(
    (
      get,
      set,
      dataSource: DataSource,
      changedFeatures: {
        add?: ProjectFeature[];
        remove?: string[];
        update?: ProjectFeature[];
      },
    ) => {
      return aset(
        get,
        set,
        allFeaturesFamily({
          projectId: dataSource.projectId,
          branchId: dataSource.id,
          version: undefined,
        }),
        (cur) => updateFeaturesInPlace(changedFeatures, cur),
      );
    },
    [],
  );

  return {
    localUpdate: localUpdateJotai,
    update,
    updateDataSource,
  };
};
