import { ChangeEvent, useState } from "react";
import { InputDimensioned } from "./Input";
import { Slider } from "./Slider";

export const RangeWithDimInput = <U extends string>({
  min = 1,
  max = 100,
  inputStep,
  rangeStep,
  disabled = false,
  defaultValue,
  value,
  onChange,
  onUnitChange,
  unit,
  units,
  onMouseUp,
  onMouseDown,
  format,
  datalist,
  compact = true,
  validationMessageStyle,
}: {
  min?: number;
  max?: number;
  defaultValue?: number;
  inputStep?: number;
  rangeStep?: number;
  disabled?: boolean;
  value?: number;
  onChange?: (f: number, e: ChangeEvent<HTMLInputElement>) => void;
  onUnitChange?: (u: U, e: ChangeEvent<HTMLSelectElement>) => void;
  unit?: U;
  units?: readonly U[];
  onMouseUp?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  format?: (f: number, unit?: U) => string;
  datalist?: Parameters<typeof Slider>[0]["datalist"];
  compact?: boolean;
  validationMessageStyle?: Parameters<
    typeof InputDimensioned
  >[0]["validationMessageStyle"];
}) => {
  const defaultt = defaultValue ?? (max - min) / 2;
  const [tmpValue, setTmpValue] = useState<number>(defaultt);
  const [valid, setValid] = useState<boolean>(true);
  const shownValue = valid ? value ?? tmpValue : tmpValue;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <InputDimensioned
        onChange={(value, e) => {
          setValid(true);
          setTmpValue(value);
          onChange?.(value, e);
        }}
        onUnitChange={(unit, e) => {
          onUnitChange?.(unit, e);
        }}
        step={inputStep}
        value={shownValue}
        unit={unit}
        units={units}
        style={{ flex: 3 }}
        validate={(n) =>
          (min === undefined || min <= n) && (max === undefined || n <= max)
        }
        validationMessage={
          min && max
            ? `Needs to be within ${min} - ${max}`
            : min
              ? `Needs to be larger than ${min}`
              : max
                ? `Needs to be smaller than ${max}`
                : undefined
        }
        validationMessageStyle={validationMessageStyle}
        format={format}
        disabled={disabled}
        compact={compact}
      />
      <Slider
        min={min}
        max={max}
        disabled={disabled}
        step={rangeStep ?? (max - min) / 100}
        value={value ?? tmpValue}
        onChange={(value, e) => {
          setValid(true);
          setTmpValue(value);
          onChange?.(value, e);
        }}
        style={{ flex: 4 }}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        datalist={datalist}
      />
    </div>
  );
};
