import { FolderTreeItem } from "types/folderStructures";
import { FolderRow } from "./FolderRow";
import { isFolderItem } from "business/folderStructure/utils";
import { useCallback, useEffect, useState } from "react";
import ResourceItem from "./ResourceItem/ResourceItem";
import { ResourceCrudOperations, ResourceMap } from "./types";

interface RecursiveFolderProps<T extends keyof ResourceMap> {
  folder: FolderTreeItem;
  depth: number;
  onDropOnFolder: (folder: FolderTreeItem, resourceId: string) => void;
  onFolderDropOnFolder: (
    folder: FolderTreeItem,
    droppedFolder: FolderTreeItem,
  ) => void;
  isLegalFolderMove: (
    folder: FolderTreeItem,
    targetFolder: FolderTreeItem,
  ) => boolean;
  onCreateFolderInFolder: (folderId: string) => void;
  collapsed: boolean;
  selectedItemId?: string;
  onClickResource: (id: string) => void;
  organisationId: string;
  isLoading: boolean;
  deleteInProgress?: string;
  setSelectedItemId: (id: string | undefined) => void;
  setContent: (content: any) => void;
  setSaveInProgress: (inProgress: boolean) => void;
  setDeleteInProgress: (id: string | undefined) => void;
  sortedResources: ResourceMap[T][];
  libraryManageRole: T;
  onCreateResourceInFolder: (folderId: string) => void;
  onFolderDropOnResource: (folderId: string, resourceId: string) => boolean;
  openDescriptionId: string | undefined;
  setOpenDescriptionId: (id: string | undefined) => void;
  searchTerm?: string;
  remove: ResourceCrudOperations<T>["remove"];
  duplicate: ResourceCrudOperations<T>["duplicate"];
  getDescription: (resource: ResourceMap[T]) => string | undefined | null;
  updateDescription: (
    resource: ResourceMap[T],
    newDescription: string,
  ) => Promise<ResourceMap[T]>;
  nameExtraInfo?: (resource: ResourceMap[T]) => string;
  extraColumn?: (resource: ResourceMap[T]) => React.ReactNode;
}

export const RecursiveFolder = <T extends keyof ResourceMap>({
  folder,
  depth,
  onDropOnFolder,
  onFolderDropOnFolder,
  isLegalFolderMove,
  onCreateFolderInFolder,
  collapsed,
  selectedItemId,
  onClickResource,
  organisationId,
  isLoading,
  deleteInProgress,
  setSelectedItemId,
  setContent,
  setSaveInProgress,
  setDeleteInProgress,
  sortedResources,
  libraryManageRole,
  onCreateResourceInFolder,
  onFolderDropOnResource,
  openDescriptionId,
  setOpenDescriptionId,
  searchTerm,
  remove,
  duplicate,
  getDescription,
  updateDescription,
  nameExtraInfo,
  extraColumn,
}: RecursiveFolderProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  const hasMatchingChild = useCallback(
    (folder: FolderTreeItem, searchTerm?: string): boolean => {
      if (!searchTerm) return false;

      return folder.children.some((child) => {
        if (isFolderItem(child)) {
          return hasMatchingChild(child, searchTerm);
        }

        const resource = sortedResources.find((r) => r.id === child.id);
        return resource?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      });
    },
    [sortedResources],
  );

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      setIsOpen(hasMatchingChild(folder, searchTerm));
      setSearchOpened(true);
    } else if (searchOpened) {
      // Close folder when search term is removed
      setIsOpen(false);
      setSearchOpened(false);
    }
  }, [searchTerm, folder, hasMatchingChild, searchOpened]);

  const onCreateResource = (folderId: string) => {
    onCreateResourceInFolder(folderId);
    setIsOpen(true);
  };

  return (
    <>
      <FolderRow
        key={folder.id}
        folder={folder}
        onDropOnFolder={onDropOnFolder}
        onFolderDropOnFolder={onFolderDropOnFolder}
        isLegalFolderMove={isLegalFolderMove}
        onCreateFolderInFolder={onCreateFolderInFolder}
        isOpen={isOpen}
        onToggleOpen={() => setIsOpen(!isOpen)}
        collapsed={collapsed}
        depth={depth}
        libraryManageRole={libraryManageRole}
        onCreateResourceInFolder={onCreateResource}
      />

      {isOpen &&
        folder.children.map((child) => {
          if (isFolderItem(child)) {
            return (
              <RecursiveFolder<T>
                key={child.id}
                folder={child}
                depth={depth + 1}
                onDropOnFolder={onDropOnFolder}
                onFolderDropOnFolder={onFolderDropOnFolder}
                isLegalFolderMove={isLegalFolderMove}
                onCreateFolderInFolder={onCreateFolderInFolder}
                collapsed={collapsed}
                selectedItemId={selectedItemId}
                onClickResource={onClickResource}
                organisationId={organisationId}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setSelectedItemId={setSelectedItemId}
                setContent={setContent}
                setSaveInProgress={setSaveInProgress}
                setDeleteInProgress={setDeleteInProgress}
                sortedResources={sortedResources}
                libraryManageRole={libraryManageRole}
                onCreateResourceInFolder={onCreateResourceInFolder}
                onFolderDropOnResource={onFolderDropOnResource}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                remove={remove}
                duplicate={duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                extraColumn={extraColumn}
              />
            );
          }

          const resource = sortedResources.find((r) => r.id === child.id);
          if (!resource) return null;

          return (
            <ResourceItem<T>
              key={resource.id}
              resource={resource}
              depth={depth + 1}
              collapsed={collapsed}
              isSelected={selectedItemId === resource.id}
              onClickResource={onClickResource}
              isLoading={isLoading}
              deleteInProgress={deleteInProgress}
              setDeleteInProgress={setDeleteInProgress}
              parentFolderId={folder.id}
              onDropOnFolder={(folderId: string, resourceId: string) =>
                onDropOnFolder({ ...folder, id: folderId }, resourceId)
              }
              openDescriptionId={openDescriptionId}
              setOpenDescriptionId={setOpenDescriptionId}
              searchTerm={searchTerm || ""}
              remove={remove}
              duplicate={duplicate}
              folders={[folder]}
              getDescription={getDescription}
              updateDescription={updateDescription}
              nameExtraInfo={
                nameExtraInfo ? nameExtraInfo(resource) : undefined
              }
              extraColumn={extraColumn}
              libraryManageRole={libraryManageRole}
            />
          );
        })}
    </>
  );
};
