import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Grid2, Label } from "components/General/Form";
import {
  InputTitle,
  InputTitleWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { SkeletonBlock } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import {
  newFoundationNodeId,
  showNewFoundationWizardAtom,
} from "state/foundations";
import {
  simpleTurbineTypesAtom,
  simpleTurbineTypesByRatingFamily,
} from "state/jotai/turbineType";
import { spaceLarge, spaceMedium } from "styles/space";
import {
  _JacketDimensions,
  DetailedJacketType,
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
  JacketDimensions,
  SoilTypes,
  SoilTypesEnum,
} from "types/foundations";
import { DEFAULT_OFFSHORE_TURBINES, SimpleTurbineType } from "types/turbines";
import { fetchSchemaWithToken } from "services/utils";
import { between } from "utils/validations";
import Spinner from "@icons/spinner/Spinner";
import { useProjectFoundationCrud } from "components/ConfigurationModal/FoundationSettings/useProjectFoundationCrud";

export const JacketWizard = ({
  defaultJacket,
  turbineType,
  parkMaxDepth,
}: {
  defaultJacket: DetailedJacketType;
  turbineType: SimpleTurbineType;
  parkMaxDepth: number;
}) => {
  const setShowNewFoundationWizard = useSetAtom(showNewFoundationWizardAtom);
  const foundationTypeNodeId = useAtomValue(newFoundationNodeId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [metoceanOverride, setMetoceanOverride] = useState<boolean>(false);
  const [turbineTypeId, setTurbineTypeId] = useState<string>(turbineType.id);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [tempFoundation, setTempFoundation] =
    useState<DetailedJacketType>(defaultJacket);

  useEffect(() => {
    setTempFoundation(defaultJacket);
    setHasRun(false);
  }, [defaultJacket]);

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbineTypesOrder = useAtomValue(simpleTurbineTypesByRatingFamily);

  const generateJacket = useCallback(
    async (tempFoundation: DetailedJacketType, turbineTypeId: string) => {
      const turbineType = allTurbineTypes.get(turbineTypeId)!;
      const {
        waterDepth,
        hs50Yr,
        soilType,
        frictionAngle,
        shearStrength,
        unitWeight,
        numLegs,
      } = tempFoundation;
      setIsLoading(true);

      const {
        hubHeight,
        diameter: rotorDiameter,
        peakThrustSpeed,
        peakThrustCt,
        rnaMass,
        ratedPower,
      } = turbineType;

      const ratedWindSpeed = peakThrustSpeed ?? 11;
      const ctRated = peakThrustCt ?? 0.8;

      const jacketData = await getSiteSpecificJacket({
        hubHeight,
        rotorDiameter,
        ratedWindSpeed,
        ctRated,
        rnaMass,
        ratedPower,
        soilType,
        frictionAngle,
        shearStrength,
        unitWeight,
        waterDepth,
        hs50Yr,
        numLegs,
      });

      if (jacketData) {
        const {
          pileMass,
          legDiameter,
          legThickness,
          braceDiameter,
          braceThickness,
          Ltop,
          Lbottom,
          jacketHeight,
          numBays,
          tpMass,
        } = jacketData;
        setTempFoundation({
          ...tempFoundation,
          pileMass,
          legDiameter,
          legThickness,
          braceDiameter,
          braceThickness,
          Ltop,
          Lbottom,
          jacketHeight,
          numBays,
          tpMass,
        });
      } else setHasError(true);
      setIsLoading(false);
      setHasRun(true);
    },
    [allTurbineTypes],
  );

  const { create, isLoadingProject } = useProjectFoundationCrud();

  const onSave = useCallback(
    async (newFoundation: FoundationType) => {
      if (!foundationTypeNodeId) return;
      await create({ foundation: newFoundation });
      setShowNewFoundationWizard(false);
    },
    [create, setShowNewFoundationWizard, foundationTypeNodeId],
  );

  return (
    <>
      {!hasRun && (
        <>
          <Grid2
            style={{
              paddingTop: "1.6rem",
              gridTemplateColumns: "23.5rem 10rem",
            }}
          >
            <InputTitle>Turbine type</InputTitle>
            <InputTitle>Number of legs</InputTitle>
            <Dropdown
              small
              id="turbine"
              value={turbineTypeId}
              onChange={(e) => {
                const newId = e.target.value;
                setTurbineTypeId(newId);
                const newType = allTurbineTypes.get(newId);
                if (newType)
                  setTempFoundation({
                    ...tempFoundation,
                    ratedPower: newType.ratedPower,
                    rotorDiameter: newType.diameter,
                    hubHeight: newType.hubHeight,
                    rnaMass: newType.rnaMass,
                  });
              }}
            >
              {turbineTypesOrder.map((t) => {
                const isDefaultTurbine = DEFAULT_OFFSHORE_TURBINES.some(
                  (defaultTurbine) => defaultTurbine.id === t.id,
                );

                return (
                  <option key={t.id} value={t.id} disabled={t.archived}>
                    {t.name} {isDefaultTurbine ? "(standard)" : ""}
                  </option>
                );
              })}
            </Dropdown>
            <Dropdown
              small
              id="foundation-num-legs"
              value={tempFoundation.numLegs}
              onChange={(e) => {
                const numLegs = parseInt(e.target.value);
                setTempFoundation({
                  ...tempFoundation,
                  numLegs,
                });
              }}
              style={{
                width: "100%",
              }}
            >
              <option value={3}>{"3"}</option>
              <option value={4}>{"4"}</option>
            </Dropdown>
          </Grid2>
          <SubtitleWithLine
            text={"Metocean parameters"}
            style={{
              paddingTop: "0.8rem",
              paddingBottom: "0.8rem",
            }}
          />
          <div
            style={{
              paddingBottom: "1.6rem",
            }}
          >
            <InputTitleWrapper>
              <InputTitle>Override park values</InputTitle>
              <Toggle
                size={ToggleSize.SMALL}
                checked={metoceanOverride}
                onChange={() => {
                  setMetoceanOverride(!metoceanOverride);
                }}
              />
            </InputTitleWrapper>
          </div>

          <Grid2>
            <InputTitle>Water depth</InputTitle>
            <InputTitle>50-year Hs</InputTitle>
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              decimals={1}
              validate={between(
                FoundationMinDepths.detailed_jacket,
                FoundationMaxDepths.detailed_jacket,
              )}
              validationMessage={`Needs to be within ${FoundationMinDepths.detailed_jacket} - ${FoundationMaxDepths.detailed_jacket} m`}
              step="0.1"
              unit="m"
              type="number"
              value={Math.min(
                tempFoundation.waterDepth,
                FoundationMaxDepths.detailed_jacket,
              )}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  waterDepth: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              decimals={1}
              validate={between(1, 20)}
              validationMessage={`Needs to be within 1 - 20 m`}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.hs50Yr}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  hs50Yr: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
          </Grid2>
          {parkMaxDepth > FoundationMaxDepths.detailed_jacket &&
            !metoceanOverride && (
              <SimpleAlert
                style={{
                  margin: "1.6rem",
                }}
                text={`Park depth is too large for jacket (> ${FoundationMaxDepths.detailed_jacket} m).`}
                type={"error"}
              ></SimpleAlert>
            )}
          <SubtitleWithLine text={"Soil characteristics"} />
          <Label
            style={{
              paddingBottom: "1.6rem",
            }}
          >
            <InputTitle>Soil type</InputTitle>
            <Dropdown
              small
              id="foundation-soil-type"
              value={tempFoundation.soilType}
              onChange={(e) => {
                setTempFoundation({
                  ...tempFoundation,
                  soilType: e.target.value as SoilTypesEnum,
                });
              }}
              style={{
                width: "100%",
              }}
            >
              <option value={SoilTypesEnum.Sand}>{"Sand"}</option>
              <option value={SoilTypesEnum.Clay}>{"Clay"}</option>
            </Dropdown>
          </Label>
          <Grid2>
            <InputTitle>Unit weight</InputTitle>
            {tempFoundation.soilType === SoilTypesEnum.Sand ? (
              <InputTitle>Soil-pile friction angle</InputTitle>
            ) : (
              <InputTitle>Undrained shear strength</InputTitle>
            )}
            <InputDimensioned
              compact
              decimals={1}
              scaleFactor={1e-3}
              validate={between(5, 30)}
              validationMessage={`Needs to be within 5 - 30 kN/m3`}
              step="0.1"
              unit="kN/m3"
              type="number"
              value={tempFoundation.unitWeight}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  unitWeight: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            {tempFoundation.soilType === SoilTypesEnum.Sand ? (
              <InputDimensioned
                compact
                decimals={1}
                scaleFactor={180 / Math.PI}
                validate={between(10, 40)}
                validationMessage={`Needs to be within 10 - 40 deg`}
                step="0.1"
                unit="deg"
                type="number"
                value={tempFoundation.frictionAngle}
                onChange={(val) => {
                  setTempFoundation({
                    ...tempFoundation,
                    frictionAngle: val,
                  });
                }}
                style={{
                  width: "100%",
                }}
              />
            ) : (
              <InputDimensioned
                compact
                decimals={1}
                scaleFactor={1e-3}
                validate={between(10, 200)}
                validationMessage={`Needs to be within 10 - 200 kPa`}
                step="0.1"
                unit="kPa"
                type="number"
                value={tempFoundation.shearStrength}
                onChange={(val) => {
                  setTempFoundation({
                    ...tempFoundation,
                    shearStrength: val,
                  });
                }}
                style={{
                  width: "100%",
                }}
              />
            )}
          </Grid2>
        </>
      )}
      {!hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading || isLoadingProject}
            style={{
              alignSelf: "end",
            }}
            title="Calculate jacket design."
            text="Calculate"
            onClick={() => {
              generateJacket(tempFoundation, turbineTypeId);
            }}
            icon={
              isLoading || isLoadingProject ? (
                <Spinner size="1rem" />
              ) : undefined
            }
          />
        </div>
      )}
      {hasRun && !hasError && (
        <Label
          style={{
            margin: `${spaceMedium} 0`,
          }}
        >
          <h4>Calculated jacket design</h4>
          <SubtitleWithLine text={"Jacket parameters"} />
          <Grid2>
            <p>Leg diameter</p>
            <p>Leg thickness</p>
            <InputDimensioned
              compact
              validate={between(0.5, 5)}
              decimals={1}
              validationMessage={`Needs to be within 0.5 - 5 m`}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.legDiameter}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  legDiameter: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              validate={between(0.01, 0.5)}
              decimals={3}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              step="0.01"
              unit="m"
              type="number"
              value={tempFoundation.legThickness}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  legThickness: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <p>Brace diameter</p>
            <p>Brace thickness</p>
            <InputDimensioned
              compact
              validate={between(0.1, 5)}
              decimals={1}
              validationMessage={`Needs to be within 0.1 -5 m`}
              step="1"
              unit="m"
              type="number"
              value={tempFoundation.braceDiameter}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  braceDiameter: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              validate={between(0.01, 0.5)}
              decimals={3}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              step="0.01"
              unit="m"
              type="number"
              value={tempFoundation.braceThickness}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  braceThickness: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <p>Leg distance, top</p>
            <p>Leg distance, bottom</p>
            <InputDimensioned
              compact
              validate={between(5, 30)}
              decimals={1}
              validationMessage={`Needs to be within 5 - 30 m`}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.Ltop}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  Ltop: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              validate={between(10, 50)}
              decimals={1}
              validationMessage={`Needs to be within 10 - 50 m`}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.Lbottom}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  Lbottom: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <p>Jacket height</p>
            <p>Number of bays</p>
            <InputDimensioned
              compact
              validate={between(20, 150)}
              decimals={1}
              validationMessage={`Needs to be within 20 - 150 m`}
              step="1"
              unit="m"
              type="number"
              value={tempFoundation.jacketHeight}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  jacketHeight: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <Dropdown
              small
              id="foundation-num-bays"
              value={tempFoundation.numBays}
              onChange={(e) => {
                const numBays = parseInt(e.target.value);
                setTempFoundation({
                  ...tempFoundation,
                  numBays,
                });
              }}
              style={{
                width: "100%",
              }}
            >
              <option value={3}>{"3"}</option>
              <option value={4}>{"4"}</option>
              <option value={5}>{"5"}</option>
              <option value={6}>{"6"}</option>
            </Dropdown>
          </Grid2>
          <SubtitleWithLine text={"Pile and transition piece parameters"} />
          <Grid2>
            <p>Pile weight</p>
            <p>TP weight</p>
            <InputDimensioned
              compact
              validate={between(1, 500)}
              scaleFactor={1 / 1000}
              decimals={0}
              validationMessage={`Needs to be within 1 - 500 m`}
              step="1"
              unit="tonnes"
              type="number"
              value={tempFoundation.pileMass}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  pileMass: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              validate={between(50, 750)}
              decimals={0}
              scaleFactor={1 / 1000}
              validationMessage={`Needs to be within 50 - 750 tonnes`}
              step="1"
              unit="tonnes"
              type="number"
              value={tempFoundation.tpMass}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  tpMass: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
          </Grid2>
        </Label>
      )}
      {hasRun && hasError && (
        <Label style={{ marginTop: spaceMedium }}>
          <SimpleAlert
            text={`Could not find a feasible jacket design for the selected input.`}
            type={"error"}
          ></SimpleAlert>
        </Label>
      )}
      {hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading || isLoadingProject}
            style={{
              alignSelf: "end",
            }}
            buttonType={"secondary"}
            text="Back"
            onClick={() => {
              setHasRun(false);
              setHasError(false);
            }}
          />
          <Button
            disabled={isLoading || isLoadingProject || hasError}
            style={{
              alignSelf: "end",
            }}
            title="Create foundation type."
            text="Create"
            onClick={() => {
              onSave(tempFoundation);
            }}
          />
        </div>
      )}
      {isLoading && (
        <SkeletonBlock
          style={{
            flex: 1,
          }}
        />
      )}
    </>
  );
};

async function getSiteSpecificJacket({
  hubHeight,
  rotorDiameter,
  ratedWindSpeed,
  ctRated,
  rnaMass,
  ratedPower,
  waterDepth,
  hs50Yr,
  soilType,
  frictionAngle,
  shearStrength,
  unitWeight,
  numLegs,
}: {
  hubHeight: number;
  rotorDiameter: number;
  ratedWindSpeed: number;
  ctRated: number;
  rnaMass: number;
  ratedPower: number;
  waterDepth: number;
  hs50Yr: number;
  soilType: SoilTypes;
  frictionAngle: number;
  shearStrength: number;
  unitWeight: number;
  numLegs: number;
}): Promise<JacketDimensions> {
  const options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      hubHeight,
      rotorDiameter,
      ratedWindSpeed,
      ctRated,
      rnaMass,
      ratedPower,
      waterDepth,
      hs50Yr,
      soilType,
      frictionAngle,
      shearStrength,
      unitWeight,
      numLegs,
    }),
  };
  return await fetchSchemaWithToken(
    _JacketDimensions,
    `/api/octopus/foundation/jacket`,
    options,
  ).catch(() => {
    return null;
  });
}
