import { SubstationType, _SubstationType } from "services/substationService";
import { MaybePromise } from "types/utils";
import { atomFamily, atomFromFn } from "utils/jotai";
import { projectIdAtom } from "state/pathParams";
import { fetchSchemaWithToken } from "services/utils";
import { atom } from "jotai";
import { safeArray } from "utils/zod";
import { isOnshoreAtom } from "state/onshore";
import { getSubstationResourcesOnNode } from "services/libraryService";
import { z } from "zod";
import { librarySubstationRefreshAtom } from "components/Organisation/Library/substation/useOrgSubstationCrud";

export const projectSubstationTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, SubstationType>>>(async () => {
    const substations = (await fetchSchemaWithToken(
      safeArray(_SubstationType),
      `/api/substation/node/${nodeId}`,
    )) as SubstationType[];
    return new Map(substations.map((c) => [c.id, c]));
  }),
);

export const substationTypesFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atom<Promise<Map<string, SubstationType>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("substationTypesFamily requires projectIdAtom");
      const proj = await get(projectSubstationTypesFamily(projectId));

      const onshore = get(isOnshoreAtom);
      if (onshore)
        for (const [k, v] of proj.entries())
          if (v.type === "offshore") proj.delete(k);

      return proj;
    }),
);

export const currentSubstationIdAtomJ = atomFromFn<
  MaybePromise<string | undefined>
>(async (get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return undefined;
  const substationTypes = Array.from(
    (await get(substationTypesFamily({ projectId }))).values(),
  );
  if (substationTypes.length === 0) return undefined;
  return substationTypes[0].id;
});

export const librarySubstationsFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, SubstationType>>>(async (get) => {
    get(librarySubstationRefreshAtom);
    const res = await getSubstationResourcesOnNode(nodeId).catch(() => []);
    const sortedRes = res.sort((a, b) =>
      a.substation.name.localeCompare(b.substation.name),
    );
    return new Map(sortedRes.map((r) => [r.substation.id, r.substation]));
  }),
);

export const allSubstationsForProjectAtom = atom<
  Promise<Map<string, SubstationType>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return new Map();

  const [proj, lib] = await Promise.all([
    get(projectSubstationTypesFamily(projectId)),
    get(librarySubstationsFamily(projectId)),
  ]);
  return new Map([...proj, ...lib]);
});

export const substationTypeLevel = ["project", "library", "default"] as const;
export const _SubstationTypeLevel = z.enum(substationTypeLevel);
type SubstationTypeLevel = z.infer<typeof _SubstationTypeLevel>;

type SubstationTypeWithLevel = {
  level: SubstationTypeLevel;
  substation: SubstationType;
};

export const substationTypesWithLevelAtom = atom<
  Promise<Map<string, SubstationTypeWithLevel>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  const substationsWithLevel = new Map<string, SubstationTypeWithLevel>();
  if (!projectId) return substationsWithLevel;

  for (const [id, substation] of await get(
    projectSubstationTypesFamily(projectId),
  ))
    if (substation.id.startsWith("default")) {
      substationsWithLevel.set(substation.id, {
        level: _SubstationTypeLevel.Values.default,
        substation,
      });
    } else {
      substationsWithLevel.set(id, {
        level: _SubstationTypeLevel.Values.project,
        substation,
      });
    }

  for (const [id, substation] of await get(librarySubstationsFamily(projectId)))
    substationsWithLevel.set(id, {
      level: _SubstationTypeLevel.Values.library,
      substation,
    });

  return substationsWithLevel;
});
