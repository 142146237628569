import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import {
  analysisManagersSelector,
  cableManagersSelector,
  dataPackageManagersSelector,
  exportcableManagersSelector,
  financialManagersSelector,
  foundationManagersSelector,
  substationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import { usersInOrganisationState } from "components/Organisation/state";
import { Suspense, useCallback, useMemo, useState } from "react";
import { adminInOrganisationSelectorFamily } from "state/user";
import {
  isDefined,
  isGroupOrgResource,
  isPersonalOrgResource,
} from "utils/predicates";
import {
  allOrganisationGroupMembershipsAtomFamily,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import {
  OrganisationResources,
  OrgResource,
} from "components/Organisation/OrganisationRightSide/types";
import { GroupAccess, UserAccess } from "../../UserGroupAccessModal";
import useUserOrganisationResourcesCrud from "../../hooks/useUserOrganisationResourcesCrud";
import useGroupOrganisationResourcesCrud from "../../hooks/useGroupOrganisationResourcesCrud";
import { SkeletonText } from "components/Loading/Skeleton";
import StackedUserImages from "components/UserImage/StackedUserImages";
import TwoPersonsIcon from "@icons/24/TwoPersons.svg";
import { IconREMSize } from "styles/typography";
import { OtherUserMetaInfo } from "types/user";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import ResourceAccessModal from "../modals/ResourceAccessModal";
import { dedup } from "utils/utils";
import GroupTag from "components/Organisation/OrganisationRightSide/content/UserContent/GroupTag";

export function AnalysisManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const analysisManagers = useAtomValue(
    analysisManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_analysis_manage"}
      resourceManagers={analysisManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function TurbineManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const turbineManagers = useAtomValue(
    turbineManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_turbine_manage"}
      resourceManagers={turbineManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function FoundationsManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const foundationsManagers = useAtomValue(
    foundationManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_foundation_manage"}
      resourceManagers={foundationsManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function CableManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const cableManagers = useAtomValue(
    cableManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_cable_manage"}
      resourceManagers={cableManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function ExportcableManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const exportcableManagers = useAtomValue(
    exportcableManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_export_cable_manage"}
      resourceManagers={exportcableManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function SubstationManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const substationManagers = useAtomValue(
    substationManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_substation_manage"}
      resourceManagers={substationManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function FinancialManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const financialManagers = useAtomValue(
    financialManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_financial_manage"}
      resourceManagers={financialManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

export function DataLayerManagersPreview({
  optionToAddManagers,
}: {
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const dataPackageManagers = useAtomValue(
    dataPackageManagersSelector({
      organisationId,
    }),
  );

  return (
    <ResourceManagersList
      type={"org_data_package_manage"}
      resourceManagers={dataPackageManagers}
      optionToAddManagers={optionToAddManagers ?? true}
    />
  );
}

function ResourceManagersList({
  type,
  resourceManagers,
  optionToAddManagers,
}: {
  type: OrganisationResources;
  resourceManagers: OrgResource[];
  optionToAddManagers?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { add: addUserManager } = useUserOrganisationResourcesCrud();
  const { add: addGroupManager } = useGroupOrganisationResourcesCrud();
  const users = useAtomValue(usersInOrganisationState(organisationId));
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const allGroupMemberships = useAtomValue(
    allOrganisationGroupMembershipsAtomFamily({
      organisationId,
    }),
  );
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );

  const userManagers = useMemo(
    () => resourceManagers.filter(isPersonalOrgResource),
    [resourceManagers],
  );
  const groupManagers = useMemo(
    () => resourceManagers.filter(isGroupOrgResource),
    [resourceManagers],
  );

  const [showAddManagers, setShowAddManagers] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);

  const onAddAccess = useCallback(
    async (access: { users: UserAccess[]; groups: GroupAccess[] }) => {
      setSaveInProgress(access.groups.length + access.users.length);
      setShowAddManagers(false);
      try {
        await Promise.all([
          ...access.users.map((userId) =>
            addUserManager(organisationId, userId, type).then(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
          ...access.groups.map((groupId) =>
            addGroupManager(groupId, type).then(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
        ]);
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addUserManager, organisationId, type, addGroupManager],
  );

  const usersWithAccess: OtherUserMetaInfo[] = useMemo(
    () =>
      userManagers
        .map((tm) => {
          const user = users.find((u) => u.user_id === tm.user_id);
          if (user) {
            return {
              user_id: user.user_id,
              nickname: user.nickname,
              picture: user.picture,
            };
          }
        })
        .filter(Boolean) as OtherUserMetaInfo[],
    [userManagers, users],
  );

  const usersWithAccessFromGroups = useMemo(() => {
    const allUsers = groupManagers
      .flatMap((group) => {
        return allGroupMemberships.filter(
          (groupMembership) => groupMembership.group_id === group.group_id,
        );
      })
      .map((membership) => {
        const user = users.find((user) => membership.user_id === user.user_id);
        if (!user) {
          return undefined;
        }
        return {
          ...user,
          group_id: membership.group_id,
        };
      })
      .filter(isDefined)
      .filter(
        (user) => !usersWithAccess.some((u) => u.user_id === user.user_id),
      );

    return dedup(allUsers, (u) => u.user_id);
  }, [allGroupMemberships, groupManagers, users, usersWithAccess]);

  const combinedUserList = useMemo(
    () => usersWithAccess.concat(usersWithAccessFromGroups),
    [usersWithAccess, usersWithAccessFromGroups],
  );

  return (
    <Suspense
      fallback={
        <SkeletonText
          style={{
            width: "15rem",
          }}
        />
      }
    >
      <Column
        style={{
          gap: "2.4rem",
          height: "100%",
        }}
      >
        <Row
          style={{
            alignItems: "center",
          }}
        >
          {usersWithAccess && (
            <StackedUserImages
              noUsersText=""
              onClick={
                isAdminInOrg
                  ? () => setShowAddManagers((cur) => !cur)
                  : undefined
              }
              users={combinedUserList}
              size={2.5}
              renderPopupRowRightSide={(user) => {
                if (!("group_id" in user)) {
                  return null;
                }

                const group = user.group_id
                  ? groups.find((g) => g.id === user.group_id)
                  : undefined;

                if (!group) {
                  return null;
                }

                return (
                  <GroupTag
                    organisationId={organisationId}
                    size="compact"
                    group={group}
                  />
                );
              }}
            />
          )}
          {isAdminInOrg && optionToAddManagers && (
            <div
              style={{
                marginLeft: "auto",
                position: "relative",
              }}
            >
              <Button
                tooltip="Add managers"
                buttonType="secondary"
                size={"small"}
                disabled={saveInProgress > 0}
                onClick={() => setShowAddManagers((cur) => !cur)}
                icon={
                  <IconREMSize height={1.4} width={1.4}>
                    <TwoPersonsIcon />
                  </IconREMSize>
                }
              />

              {showAddManagers && (
                <div
                  style={{
                    position: "absolute",
                    right: "0.8rem",
                  }}
                >
                  <FullScreenModal onEscape={() => setShowAddManagers(false)}>
                    <ResourceAccessModal
                      resource={type}
                      existingGroups={groupManagers.map((gm) => ({
                        group_id: gm.group_id,
                      }))}
                      existingUsers={userManagers.map((um) => ({
                        user_id: um.user_id,
                      }))}
                      onSave={onAddAccess}
                      onCancel={() => setShowAddManagers(false)}
                    />
                  </FullScreenModal>
                </div>
              )}
            </div>
          )}
        </Row>
      </Column>
    </Suspense>
  );
}
