import { atom } from "jotai";
import { SourceTypesLayer } from "../types/layers";

export type LayerForDataCleaning = {
  sourceId: string;
  endpointUrl: string;
  endpointId: string;
  layerId: string;
  tags: string[];
  isHidden: boolean;
  customMetadata: Record<string, string | undefined>;
};

export type SourceForDataCleaning = {
  sourceId: string;
  alternativeNames?: string[];
  countries?: string[];
  name?: string;
  originalName?: string;
  endpoint: {
    sourceType: SourceTypesLayer;
    endpointUrl: string;
    licenseType?: string;
    licenseUrl?: string;
    attribution?: string;
    urlWhereFound?: string;
  };
};

export const changedDataLayersAtom = atom<Record<string, LayerForDataCleaning>>(
  {},
);
export const changedDataSourcesAtom = atom<
  Record<string, SourceForDataCleaning>
>({});

export const showHiddenLayersAtom = atom<boolean>(false);

export const hasUnsavedChangesAtom = atom(false);
