import { colors } from "styles/colors";
import { keysMatch } from "types/utils";
import { Defined, isNever } from "utils/utils";
import { ColorKey, Style } from "./types";
import { parkColorKeys, parkLabels } from "./feature/park";
import { anchorColorKeys, anchorLabels } from "./feature/anchor";
import { cableColorKeys, cableLabels } from "./feature/cable";
import { turbineColorKeys, turbineLabels } from "./feature/turbine";
import { Color } from "lib/colors";

export const COLORVALUE = "vind:colorvalue";
export const LABELVALUE = "vind:labelvalue";
/** Property key used for the "Groups" styling. */
export const STYLE_GROUP_KEY = "vind:stylegroup";

export const labelFromFeature: Record<Style["feature"], string> = {
  turbines: "Turbines",
  cables: "Cables",
  anchors: "Anchors",
  parks: "Parks",
} as const;

export const colorFromFeature: Record<Style["feature"], Color> = {
  turbines: Color.fromHex(colors.turbine),
  cables: Color.fromHex(colors.cable),
  anchors: Color.fromHex(colors.anchor),
  parks: Color.fromHex(colors.park),
} as const;

export const labelFromColorKey: Record<ColorKey, string> = {
  "wake-loss": "Wake loss",
  "avg-wind-speed": "Avg wind speed",
  single: "Single color",
  length: "Length",
  depth: "Depth",
  name: "Name",
  capacity: "Capacity",
  load: "Load",
  "cable-type": "Cable type",
  aep: "AEP",
  group: "Group",
} as const;

export const labelFromColoringType: Record<Defined<Style["type"]>, string> = {
  single: "Single",
  bucket: "Bucket",
  gradient: "Gradient",
  category: "Category",
} as const;

export const colorKeysFromFeature = {
  turbines: turbineColorKeys,
  cables: cableColorKeys,
  anchors: anchorColorKeys,
  parks: parkColorKeys,
} as const;
keysMatch<keyof typeof colorKeysFromFeature, Style["feature"]>();

export const labelsFromFeature = {
  turbines: turbineLabels,
  cables: cableLabels,
  anchors: anchorLabels,
  parks: parkLabels,
} as const;
keysMatch<keyof typeof colorKeysFromFeature, Style["feature"]>();

/**
 * Render a `value` from the given {@link ColorKey}.
 */
export function renderValue(
  key: undefined | ColorKey,
  value: number | string,
): string {
  if (typeof value === "string") return value;
  switch (key) {
    case "wake-loss":
      return `${(value * 100).toFixed(1)}%`;
    case "avg-wind-speed":
      return `${value.toFixed(2)} m/s`;
    case "single":
      return "";
    case "capacity":
      return `${value / 1000000} MW`;
    case "load":
      return `${value} MW`;
    case "depth":
      return `${Math.round(value)} m`;
    case "length":
      return `${Math.round(value)} m`;
    case "name":
    case "cable-type":
    case undefined: // disabled labels go here
      return "";
    case "aep":
      return `${Math.round(value)} GWh`;
    case "group":
      return String(value);
    default:
      throw isNever(key);
  }
}

/**
 * Performs the reverse transformation of what {@link renderValue} does.  This
 * way we can read in user inputs in the rendered format and store them.
 */
export function parseValue(key: undefined | ColorKey, str: string): number {
  const f = parseFloat(str);
  switch (key) {
    case "wake-loss":
      return f / 100;
    case "capacity":
      return f * 1000000;
    default:
      return parseFloat(str);
  }
}

/**
 * Like {@link renderValue}, but for all values in a {@link Map}.
 */
export function renderValues(
  key: ColorKey | undefined,
  values: Map<string, number | string>,
): Map<string, string> {
  const map = new Map();
  for (const [k, v] of values.entries()) map.set(k, renderValue(key, v));
  return map;
}
