import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Input, InputDimensioned, TextArea } from "components/General/Input";
import Button from "components/General/Button";
import {
  AnalysisConfiguration,
  EnergyLoss,
} from "services/configurationService";
import { IconREMSize, TextIcon } from "styles/typography";
import { spaceTiny } from "styles/space";
import AddIcon from "@icons/24/Add.svg";
import Trashcan from "@icons/24/Bin.svg";
import { ElementsContainer, Row } from "../shared";
import { analysisChangelogInfo } from "components/InputChangelog/const";
import { getCustomArrayChangeLogValue } from "components/InputChangelog/utils";
import { Column } from "components/General/Layout";
import { CustomLossesTopRow } from "./CustomLossesTopRow";
import styled from "styled-components";
import { colors } from "styles/colors";

const MIN_LOSS = -5;
const MAX_LOSS = 20;

const fractionToPercentage = 100;

const ResponsiveTextArea = styled(TextArea)`
  resize: vertical;
  width: 60rem;
  max-width: 100%;

  @media (max-width: 1500px) {
    width: 40rem;
  }

  @media (max-width: 1200px) {
    width: 35rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`;

const DividerWrapper = styled.div`
  padding: 0 0 2rem 0;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault}};
`;

export const GridWithFourColumns = styled.div<{ showFreeText: boolean }>`
  display: grid;
  grid-template-columns: 40rem 10rem ${({ showFreeText }) =>
      showFreeText ? "auto auto" : "auto"};
  gap: 3.2rem;
  align-items: center;
  & > :nth-child(4) {
    justify-self: end;
  }
`;

const CustomLossesTab = ({
  isReadOnly,
  configuration,
  localConfig,
  setLocalConfig,
  nodeId,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  configuration: AnalysisConfiguration;
  localConfig: AnalysisConfiguration;
  setLocalConfig: React.Dispatch<React.SetStateAction<AnalysisConfiguration>>;
  nodeId: string;
  isLibraryResource: boolean;
}) => {
  const changelogCategory = isLibraryResource
    ? "org_analysis_manage"
    : "project";

  function updateName(name: string, index: number) {
    setLocalConfig((curr) => {
      const newLosses = curr.energyLosses.map((loss, i) =>
        i === index ? { ...loss, name } : loss,
      );
      return { ...curr, energyLosses: newLosses };
    });
  }

  function updateValue(value: number, index: number) {
    setLocalConfig((curr) => {
      const newLosses = curr.energyLosses.map((loss, i) =>
        i === index ? { ...loss, value } : loss,
      );
      return { ...curr, energyLosses: newLosses };
    });
  }

  function updateFreeText(freeText: string, index: number) {
    setLocalConfig((curr) => {
      const newLosses = curr.energyLosses.map((loss, i) =>
        i === index ? { ...loss, freeText } : loss,
      );
      return { ...curr, energyLosses: newLosses };
    });
  }

  function removeLoss(index: number) {
    setLocalConfig((curr) => {
      const newLosses = [
        ...curr.energyLosses.slice(0, index),
        ...curr.energyLosses.slice(index + 1),
      ];

      return { ...curr, energyLosses: newLosses };
    });
  }

  return (
    <ElementsContainer>
      <Column
        style={{
          gap: 0,
        }}
      >
        <CustomLossesTopRow
          showFreeTextCustomLosses={localConfig.showLossesFreeText ?? false}
          setShowFreeTextCustomLosses={(showFreeTextCustomLosses) => {
            setLocalConfig((curr) => ({
              ...curr,
              showLossesFreeText: showFreeTextCustomLosses,
            }));
          }}
        />
        {localConfig.energyLosses.map((loss, i) => {
          const originalLoss = configuration.energyLosses.find(
            (l) => l.id === loss.id,
          );
          return (
            <Wrapper key={loss.id}>
              <GridWithFourColumns
                showFreeText={localConfig.showLossesFreeText ?? false}
              >
                <Input
                  disabled={isReadOnly}
                  type="text"
                  value={loss.name}
                  onChange={(e) => updateName(e.target.value, i)}
                  style={{ width: "40rem" }}
                />

                <InputDimensioned
                  disabled={isReadOnly}
                  scaleFactor={fractionToPercentage}
                  decimals={2}
                  changelogInfo={analysisChangelogInfo(
                    localConfig.id,
                    nodeId,
                    loss.id,
                    changelogCategory,
                    (obj) =>
                      getCustomArrayChangeLogValue(
                        obj,
                        "energyLosses",
                        `id.${loss.id}`,
                        ["name", "value"],
                      ),
                  )}
                  value={loss.value}
                  initialValue={originalLoss ? originalLoss.value : undefined}
                  validate={(n) => MIN_LOSS <= n && n <= MAX_LOSS}
                  validationMessage={`Needs to be within ${MIN_LOSS}% and ${MAX_LOSS}%`}
                  step={0.5}
                  unit="%"
                  type="number"
                  onChange={(value) => {
                    updateValue(value, i);
                  }}
                  style={{ width: "8rem" }}
                />
                {localConfig.showLossesFreeText && (
                  <ResponsiveTextArea
                    rows={1}
                    placeholder={"Your text"}
                    value={loss.freeText}
                    onChange={(e) => {
                      updateFreeText(e.target.value, i);
                    }}
                  />
                )}
                {!isReadOnly && (
                  <TextIcon
                    onClick={() => removeLoss(i)}
                    scale={1.2}
                    style={{
                      padding: spaceTiny,
                      alignSelf: "center",
                      marginLeft: "auto",
                    }}
                  >
                    <Trashcan />
                  </TextIcon>
                )}
              </GridWithFourColumns>
              <DividerWrapper>
                <Divider />
              </DividerWrapper>
            </Wrapper>
          );
        })}

        <Row
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Button
            style={{
              padding: 0,
            }}
            icon={
              <IconREMSize width={1.2} height={1.2}>
                <AddIcon />
              </IconREMSize>
            }
            buttonType="text"
            text="Add loss"
            onClick={() => {
              const newLoss: EnergyLoss = {
                id: uuidv4(),
                name: "Unnamed loss",
                value: 0,
              };
              setLocalConfig((curr) => ({
                ...curr,
                energyLosses: [...curr.energyLosses, newLoss],
              }));
            }}
            disabled={isReadOnly}
          />
        </Row>
      </Column>
    </ElementsContainer>
  );
};

export default CustomLossesTab;
