/**
 * A Tree where each node has an ordered list of children.
 *
 * Each node in the tree has data of type `T` and a ordered list of children,
 * where each element is either a `T` (for simplicity) or a `Tree<T>`.
 */
export class Tree<T> {
  public parent: Tree<T> | undefined;
  public children: Tree<T>[];

  constructor(
    public data: T,
    children: (T | Tree<T>)[] = [],
  ) {
    this.parent = undefined;
    this.children = children.map((c) =>
      c instanceof Tree ? c : new Tree(c, []),
    );
    for (const c of this.children) c.parent = this;
  }

  /**
   * Add an item as a child of this node.
   */
  insert(child: T | Tree<T>, index?: number) {
    if (!(child instanceof Tree)) child = new Tree(child, []);
    child.parent = this;
    if (index === undefined) this.children.push(child);
    else this.children.splice(index, 0, child);
  }

  /**
   * Remove this node from the parent {@link Tree}, if any.
   * Return `true` if there was a parent, and `false` otherwise.
   */
  pop(): boolean {
    if (!this.parent) return false;
    const i = this.parent.children.indexOf(this);
    if (i === -1) throw new Error("Node was not in parent children array.");
    this.parent.children.splice(i, 1);
    this.parent = undefined;
    return true;
  }

  /**
   * Return `true` if some `.data` in the entire tree makes the predicate
   * `pred` return `true`. Uses DFS.
   */
  contains(pred: (t: T) => boolean): boolean {
    if (pred(this.data)) return true;
    for (const child of this.children) if (child.contains(pred)) return true;
    return false;
  }

  /**
   * Flatten all the values in the tree to one array.
   */
  flatten(): T[] {
    return [this.data].concat(this.children.flatMap((n) => n.flatten()));
  }

  /**
   * Iterate over the children of this node.
   * This allows you to write
   *
   * ```ts
   * for (const node of tree)
   *    console.log(node);
   * ```
   * to print out all direct children.
   */
  [Symbol.iterator]() {
    const children = this.children;
    let i = 0;
    return {
      next(): IteratorResult<T, any> {
        if (i < children.length) {
          const el = children[i++];
          return {
            value: el.data,
            done: false,
          };
        } else {
          return { done: true, value: undefined };
        }
      },
    };
  }

  /**
   * Traverses the tree in depth-first pre-order.
   * The depth is 0 at the root, and a child is at depth +1 of its parent.
   */
  traverseDepthFirst(visit: (t: T, depth: number) => void, depth?: number) {
    const d = depth ?? 0;
    visit(this.data, d);
    for (const c of this.children) {
      c.traverseDepthFirst(visit, d + 1);
    }
  }

  /**
   * Transforms the tree into another tree under the given function, with
   * access to already transformed children.
   */
  transformUp<U>(fn: (t: T, children: Tree<U>[]) => U): Tree<U> {
    const children = this.children.map((c) => c.transformUp(fn));
    const u = fn(this.data, children);
    return new Tree(u, children);
  }

  /**
   * Transforms the tree into another tree under the given function, with
   * access to already transformed parent.
   */
  transformDown<U>(fn: (t: T, parent?: Tree<U>) => U): Tree<U> {
    function inner(node: Tree<T>, parent?: Tree<U>): Tree<U> {
      const u = fn(node.data, parent);
      const newNode = new Tree(u, []);
      newNode.children = node.children.map((n) => inner(n, newNode));
      return newNode;
    }
    return inner(this, undefined);
  }
}
