import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { FoundationType } from "types/foundations";
import { projectFoundationTypesFamily } from "state/jotai/foundation";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";
import { Mixpanel } from "mixpanel";
import {
  createNewProjectFoundation,
  deleteProjectFoundation,
  updateProjectFoundation,
} from "services/turbineAPIService";
import { useState } from "react";
import { useToast } from "hooks/useToast";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DuplicateComponentModalType } from "../Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

export const useProjectFoundationCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { error } = useToast();
  const { showConfirm } = useConfirm();
  const [isLoadingProject, setIsLoadingProject] = useState<boolean>(false);
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const updateLocal = useJotaiCallback(
    async (get, set, foundation: FoundationType) => {
      set(
        projectFoundationTypesFamily(projectId),
        async (currentFoundationsPromise) => {
          const currentFoundations = await currentFoundationsPromise;
          const existing = currentFoundations.find(
            (f) => f.id === foundation.id,
          );
          return existing
            ? currentFoundations.map((f) =>
                f.id === foundation.id ? { ...f, ...foundation } : f,
              )
            : [...currentFoundations, foundation];
        },
      );
    },
    [projectId],
  );

  const create = useJotaiCallback(
    async (
      _,
      set,
      { foundation, name }: { foundation: FoundationType; name?: string },
    ) => {
      const newFoundation = {
        ...foundation,
        name: name ? name : `${foundation.name}`,
      };
      setIsLoadingProject(true);
      setToastMessages((tm) => [
        ...tm,
        {
          text: "Creating new foundation",
          timeout: 1000,
          type: "info",
        },
      ]);
      return createNewProjectFoundation(projectId, newFoundation)
        .then((res) => {
          if (res) {
            updateLocal(res);
            set(
              selectedMenuItemState({
                menuId: FOUNDATION_MENU_ID,
                projectId,
              }),
              res.id,
            );
            setIsLoadingProject(false);
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Saved",
                timeout: 3000,
                type: "success",
              },
            ]);
            Mixpanel.track_old("Created new foundation", {
              foundationId: foundation.id,
            });
          }
        })
        .catch((e) => {
          setIsLoadingProject(false);
          scream("Error when creating new foundation", {
            e,
          });
          error(
            "Something went wrong while trying to create new foundation, the Vind team has been notified",
          );
        });
    },
    [projectId, error, updateLocal, setToastMessages],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, foundationId: string) => {
      set(
        projectFoundationTypesFamily(projectId),
        async (currentFoundationsPromise) => {
          const currentFoundations = await currentFoundationsPromise;
          return currentFoundations.filter((f) => f.id !== foundationId);
        },
      );
    },
    [projectId],
  );

  const remove = useJotaiCallback(
    async (get, set, foundationId: string) => {
      setIsLoadingProject(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "FOUNDATION",
          resourceId: foundationId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "FOUNDATION",
          foundationId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete foundation",
          message: `Foundation type is currently being used in ${usage.length} foundations.`,
          confirmButtonText: "Delete",
        }))
      ) {
        return deleteProjectFoundation(projectId, foundationId).then(
          async () => {
            await removeLocal(foundationId);
            if (
              get(
                selectedMenuItemState({
                  menuId: FOUNDATION_MENU_ID,
                  projectId,
                }),
              ) === foundationId
            ) {
              set(
                selectedMenuItemState({
                  menuId: FOUNDATION_MENU_ID,
                  projectId,
                }),
                undefined,
              );
            }
            setIsLoadingProject(false);
            setToastMessages((tm) => [
              ...tm,
              {
                text: "Deleted",
                timeout: 3000,
                type: "success",
              },
            ]);
            Mixpanel.track_old("Delete project foundation", {
              foundationId,
            });
          },
        );
      }
    },
    [projectId, removeLocal, setToastMessages, showConfirm],
  );

  const onSave = useJotaiCallback(
    async (
      get,
      set,
      foundation: FoundationType,
      nodeId: string | undefined,
      onlyUpdatingDescription: boolean = false,
    ) => {
      if (!nodeId) return;
      setIsLoadingProject(true);
      setToastMessages((tm) => [
        ...tm,
        {
          text: "Saving...",
          timeout: 1000,
        },
      ]);
      const usage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "FOUNDATION",
          resourceId: foundation.id,
        }),
      );

      if (
        usage.length === 0 ||
        onlyUpdatingDescription ||
        (await showConfirm({
          title: "Update foundation",
          message: `Foundation type is currently being used in ${usage.length} foundations.`,
          confirmButtonText: "Update",
        }))
      ) {
        updateProjectFoundation(nodeId, foundation).then(() => {
          setIsLoadingProject(false);
          updateLocal(foundation);
          setIsLoadingProject(false);
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Saved",
              timeout: 3000,
              type: "success",
            },
          ]);
        });
      } else {
        setIsLoadingProject(false);
      }
    },
    [setToastMessages, showConfirm, updateLocal],
  );

  const duplicateToProject = useJotaiCallback(
    async (_, set, foundation: FoundationType) => {
      if (!projectId) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "foundation",
          defaultName: `${foundation.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const newFoundation = {
              ...foundation,
              name: name,
            };
            return await create({ foundation: newFoundation });
          },
        },
      });
    },
    [projectId, create, setMidScreenModalTypeOpen],
  );

  return {
    create,
    remove,
    updateLocal,
    removeLocal,
    duplicateToProject,
    onSave,
    isLoadingProject,
  };
};
