import useEnterToSave from "components/ConfigurationModal/useEnterToSave";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import usePrevious from "hooks/usePrevious";
import { useSetAtom } from "jotai";
import { useState, useEffect, useMemo, useCallback } from "react";
import { objectEquals } from "utils/utils";
import { mergePreservingChanges } from "utils/utils";
import { CableType } from "services/cableTypeService";

const excludedKeys = ["createdAt", "updatedAt"];

export function useCableEditing<T extends CableType>({
  cable,
  onSave,
  isLoading,
  disabled,
}: {
  cable: T;
  onSave?: (cable: T, onlyUpdatingDescription?: boolean) => void;
  isLoading: boolean;
  disabled: boolean;
}) {
  const [editedCable, setEditedCable] = useState(cable);
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);

  const prevCable = usePrevious(cable);
  useEffect(() => {
    if (cable && cable.id) {
      setEditedCable((prevEditedCable) => {
        if (!prevEditedCable || !prevCable) return cable;
        return mergePreservingChanges(prevCable, cable, prevEditedCable);
      });
    }
  }, [cable, setEditedCable, prevCable]);

  const hasChanged = useMemo(() => {
    if (!cable || !editedCable) return false;
    return !objectEquals(cable, editedCable, excludedKeys);
  }, [cable, editedCable]);

  const allChangesSaved = !hasChanged;

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  const onEnterSaveCallback = useCallback(() => {
    if (!onSave) return;
    return onSave(editedCable);
  }, [onSave, editedCable]);

  useEnterToSave(
    onEnterSaveCallback,
    !allChangesSaved && !isLoading && !disabled,
  );

  return {
    editedCable,
    setEditedCable,
    allChangesSaved,
  };
}
