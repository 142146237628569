import { useAtomValue } from "jotai";
import { useCallback } from "react";
import {
  _ExportCableVoltageType,
  CableType,
  ExportCableVoltageType,
  ExportSystemType,
} from "../../../services/cableTypeService";
import { Column } from "../../General/Layout";
import { ColumnSection } from "../../SettingsV2/Shared/styles";
import { defaultExportCables } from "../../../constants/cabling";
import { parseOr } from "utils/zod";
import { useDuplicateCableToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { orgExportcableManageAccessSelector } from "state/user";
import {
  exportCableChangelogInfo,
  idToExportCableChangelogId,
} from "components/InputChangelog/const";
import { HeaderWrapper } from "../shared";
import { isOnshoreAtom } from "state/onshore";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useCableEditing } from "./hooks/useCableEditing";
import { CableSettingsLayout } from "./Components/CableSettingsLayout";
import CableHeader from "./Components/Header";
import ProjectContextActions from "./Components/ProjectContextActions";
import GeneralInfo from "./Components/GeneralInfo";
import AdvancedSettings, {
  AdvancedSettingsHeader,
} from "./Components/AdvancedSettings";
import ElectricalProperties from "./Components/ElectricalProperties";
import AdvancedSettingsValidFor from "./Components/AdvancedSettingsValidFor";
import { SettingsFormActions } from "../Components/SettingsFormActions";

export function SingleExportCable({
  exportCable,
  onSave,
  isLoading,
  disabled,
  isLibraryExportCable,
  nodeId,
}: {
  exportCable: CableType;
  onSave: (exportCable: CableType, skipUsageCheck?: boolean) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
  isLibraryExportCable?: boolean;
}) {
  const onshore = useAtomValue(isOnshoreAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectId = useAtomValue(projectIdAtom);
  const inLibraryContext = !projectId;

  const {
    editedCable: editedExportCable,
    setEditedCable: setEditedExportCable,
    allChangesSaved,
  } = useCableEditing({
    cable: exportCable,
    onSave,
    isLoading,
    disabled,
  });

  const { duplicateToProject } = useDuplicateCableToProject();
  const orgExportCableManageAccess = useAtomValue(
    orgExportcableManageAccessSelector,
  );

  const currentDefaultCables =
    editedExportCable.exportCableType === ExportSystemType.HVAC
      ? defaultExportCables[
          parseOr(
            _ExportCableVoltageType,
            editedExportCable.voltage,
            ExportCableVoltageType.kV220,
          )
        ]
      : defaultExportCables[
          parseOr(
            _ExportCableVoltageType,
            editedExportCable.voltage,
            ExportCableVoltageType.kV320,
          )
        ];

  const isLibraryExportCableInProjectView = !!projectId && isLibraryExportCable;
  const changelogCategory = isLibraryExportCable
    ? "org_export_cable_manage"
    : "project";

  const handleUpdateExportCableDescription = useCallback(
    (description: string) => {
      if (!exportCable) return;
      setEditedExportCable((cur) => ({
        ...cur,
        note: description,
      }));
      if (onSave) {
        onSave(
          {
            ...exportCable,
            note: description,
          },
          true,
        );
      }
    },
    [exportCable, onSave, setEditedExportCable],
  );

  const onSelectCableFromTemplate = useCallback(
    (props: CableType) => {
      setEditedExportCable({
        ...editedExportCable,
        reactance: props.reactance,
        resistance: props.resistance,
        capacitance: props.capacitance,
        ampacity: props.ampacity,
        lambda1: props.lambda1,
        lambda2: props.lambda2,
        dielectricLossFactor: props.dielectricLossFactor,
        exportCableUsage: props.exportCableUsage,
      });
    },
    [editedExportCable, setEditedExportCable],
  );

  const handleSetCableAdvancedSettings = useCallback(
    (cable: CableType) =>
      setEditedExportCable({
        ...cable,
        exportCableUsage: editedExportCable.exportCableUsage ?? {
          offshore: true,
          onshore: true,
        },
      }),
    [editedExportCable.exportCableUsage, setEditedExportCable],
  );

  return (
    <CableSettingsLayout
      inLibraryContext={inLibraryContext}
      header={
        <HeaderWrapper>
          <CableHeader
            cable={editedExportCable}
            nodeId={nodeId}
            disabled={disabled}
            description={editedExportCable.note ?? ""}
            handleUpdateCableDescription={handleUpdateExportCableDescription}
            changelogId={idToExportCableChangelogId(exportCable.id)}
            category={changelogCategory}
          />
          <ProjectContextActions
            editedCable={editedExportCable}
            originalCable={exportCable}
            nodeId={nodeId}
            onSave={onSave}
            setCable={setEditedExportCable}
            duplicateToProject={() => duplicateToProject(exportCable)}
            allChangesSaved={allChangesSaved}
            isLibraryCableInProjectView={isLibraryExportCableInProjectView}
            orgCableManageAccess={orgExportCableManageAccess}
            organisationId={organisationId}
            isLoading={isLoading}
          />
        </HeaderWrapper>
      }
    >
      <Column>
        {inLibraryContext &&
          !allChangesSaved &&
          !isLibraryExportCableInProjectView && (
            <SettingsFormActions
              isLibraryContext={inLibraryContext}
              hasChanges={!allChangesSaved}
              isLoading={isLoading}
              onSave={() => {
                onSave?.(editedExportCable);
              }}
              onCancel={() => {
                setEditedExportCable(exportCable);
              }}
            />
          )}
        <ColumnSection
          style={{
            padding: 0,
          }}
        >
          <GeneralInfo
            cable={editedExportCable}
            setCable={setEditedExportCable}
            disabled={disabled}
            isLibraryCable={isLibraryExportCable}
            onshore={onshore}
            nodeId={nodeId}
            changelogCategory={changelogCategory}
            getChangelogInfo={exportCableChangelogInfo}
          />
          <ElectricalProperties
            editedCable={editedExportCable}
            originalCable={exportCable}
            setCable={setEditedExportCable}
            disabled={disabled}
            nodeId={nodeId}
            changelogCategory={changelogCategory}
            getChangelogInfo={exportCableChangelogInfo}
            onSelectCableFromTemplate={onSelectCableFromTemplate}
            currentDefaultCables={currentDefaultCables}
          />
          {!onshore &&
            editedExportCable.exportCableType === ExportSystemType.HVAC && (
              <>
                <AdvancedSettingsHeader
                  cable={editedExportCable}
                  setCable={handleSetCableAdvancedSettings}
                  disabled={disabled}
                  nodeId={nodeId}
                  changelogCategory={changelogCategory}
                  getChangelogInfo={exportCableChangelogInfo}
                />
              </>
            )}
          {!onshore && editedExportCable.useAdvancedSettings && (
            <div
              style={{
                display: "grid",
                justifyContent: "start",
                gridTemplateColumns: "auto auto auto auto",
                gap: "2.4rem",
              }}
            >
              <AdvancedSettings
                cable={editedExportCable}
                setCable={setEditedExportCable}
                disabled={disabled}
                nodeId={nodeId}
                changelogCategory={changelogCategory}
                getChangelogInfo={exportCableChangelogInfo}
              />
              <AdvancedSettingsValidFor
                editedExportCable={editedExportCable}
                setEditedExportCable={setEditedExportCable}
                disabled={disabled}
                nodeId={nodeId}
                changelogCategory={changelogCategory}
              />
            </div>
          )}
        </ColumnSection>
      </Column>
    </CableSettingsLayout>
  );
}
