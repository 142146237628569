import { getDistanceFromPortToPark } from "../utils";
import {
  getBranchId,
  getParkId,
  getTurbinesWithFloatingFoundations,
} from "analysis/inputs";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { positionOnSiteActivity, transitActivity } from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { isAnchorHandlingVessel } from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { FloaterActivitiesConfig } from "services/operationsConfigurationService";

export const floaterInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const turbinesWithFloater = await get(
      getTurbinesWithFloatingFoundations(id),
    );

    const mooringLines = await get(
      mooringLinesInParkFamily({ parkId, branchId }),
    );

    const numFloaters = turbinesWithFloater.length;
    const numMooringLines = mooringLines.length;
    const linesPerFloater = Math.round(numMooringLines / numFloaters);

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const { installationSeason, weatherLimits, floaters } =
      configuration.ti.foundations;
    const { installationVessel, towingVessel, activities } = floaters;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const installationVesselType = vesselTypes.get(installationVessel.vesselId);
    const towingVesselType = vesselTypes.get(towingVessel.vesselId);

    if (
      !installationVesselType ||
      !isAnchorHandlingVessel(installationVesselType)
    )
      return undefined;

    if (!towingVesselType || !isAnchorHandlingVessel(towingVesselType))
      return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const towingTimeTowingVessel = Math.ceil(
      portParkDistance / towingVesselType.towingSpeed,
    );
    const transitTimeTowingVessel = Math.ceil(
      portParkDistance / towingVesselType.transitSpeed,
    );

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numFloaters; i++) {
      floaterOperations(towingTimeTowingVessel, activities).forEach(
        (operation) => {
          totalInstallTime.push(operation);
        },
      );

      for (let j = 0; j < linesPerFloater; j++) {
        mooringLineOperations(activities).forEach((operation) => {
          totalInstallTime.push(operation);
        });
      }

      totalInstallTime.push(transitActivity(transitTimeTowingVessel));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const mooringLineOperations = (activities: FloaterActivitiesConfig) => [
  positionOnSiteActivity,
  {
    id: "Recover mooring line",
    duration: activities.recoverMooringLine,
    useWindLim: false,
    useWaveLim: true,
  },
  {
    id: "Connect and tension mooring line",
    duration: activities.connectAndTensionMooringLine,
    mileStone: true,
    useWindLim: false,
    useWaveLim: true,
  },
];

const floaterOperations = (
  towingTimeTowingVessel: number,
  activities: FloaterActivitiesConfig,
) => [
  {
    id: "Ballast to towing draft",
    duration: activities.ballastToTowingDraft,
    useWindLim: false,
    useWaveLim: false,
  },
  transitActivity(towingTimeTowingVessel),
  {
    id: "Ballast to operational draft",
    duration: activities.ballastToOperationalDraft,
    useWindLim: false,
    useWaveLim: false,
  },
];
