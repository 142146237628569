import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import {
  isMultipleSourceWindConfiguration,
  WindSourceConfiguration,
} from "services/windSourceConfigurationService";
import { colors } from "styles/colors";
import { projectWindConfigurationsFamily } from "state/jotai/windConfiguration";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectWindDropDownCustom = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  className,
  disabled,
  listContainerStyle,
  backgroundColor,
}: {
  selectedConfig?: WindSourceConfiguration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  listContainerStyle?: CSSProperties;
  backgroundColor?: string;
}) => {
  const windConfigs = useAtomValue(projectWindConfigurationsFamily(projectId));

  const items = useMemo<DropDownItem[]>(() => {
    return [...windConfigs.values()].map((c) => ({
      value: c.id,
      name: c.name ?? "Untitled",
      disabled: isMultipleSourceWindConfiguration(c),
      icon: (
        <IconREMSize width={1.4} height={1.4}>
          <ProjectIcon />
        </IconREMSize>
      ),
    }));
  }, [windConfigs]);

  return (
    <DropdownButton
      className={className}
      items={items}
      disabled={disabled}
      backgroundColor={backgroundColor}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={listContainerStyle}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        windConfigs.get(selectedConfig?.id ?? "")?.name ??
        "Select configuration"
      }
      selectedItemValue={selectedConfig?.id}
    />
  );
};

export default SelectWindDropDownCustom;
