import { useAtom, useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { LayerCollection } from "../LayerSettings/types";
import {
  postLayerCollection,
  putLayerCollection,
  deleteLayerCollection,
  BackendLayerCollection,
  copyCollectionToProject,
} from "./service";
import {
  allBackendLayerCollectionsAtomFamily,
  isCreatingNewCollectionAtom,
} from "./state";
import { createDefaultName } from "./utils";
import { Mixpanel } from "../../../mixpanel";
import { scream } from "utils/sentry";

export const useCollectionCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [isCreatingNewCollection, setIsCreatingNewCollection] = useAtom(
    isCreatingNewCollectionAtom,
  );

  const post = useJotaiCallback(
    async (get, set, data: Partial<LayerCollection>) => {
      if (!projectId) return;
      try {
        setIsCreatingNewCollection(true);
        const existingCollections = await get(
          allBackendLayerCollectionsAtomFamily({
            projectId,
          }),
        );
        const blc: Partial<BackendLayerCollection> = {
          alias: data.name ?? createDefaultName(existingCollections),
          layerIds: data.layers?.map((l) => l.id) ?? [],
          standard: data.isDefault ?? false,
        };
        const res = await postLayerCollection(projectId, blc);
        Mixpanel.track_old("Create collection", {
          nodeId: projectId,
        });

        aset(
          get,
          set,
          allBackendLayerCollectionsAtomFamily({
            projectId,
          }),
          (cur) => {
            const clone = [...cur];
            const index = clone.findIndex((c) => c.id === res.id);
            if (index !== -1) {
              clone[index] = res;
            } else {
              clone.push(res);
            }
            return clone;
          },
        );
        return res;
      } catch (error) {
        if (error instanceof Error) {
          scream(error, {
            message: "Failed to create new collection",
            error,
          });
        } else {
          scream("Failed to create new collection", {
            error,
          });
        }
      } finally {
        setIsCreatingNewCollection(false);
      }
    },
    [projectId, setIsCreatingNewCollection],
  );

  const put = useJotaiCallback(
    async (get, set, data: LayerCollection) => {
      if (!projectId) return;
      const blc: BackendLayerCollection = {
        id: data.id,
        alias: data.name,
        layerIds: data.layers.map((l) => l.id),
        standard: data.isDefault,
      };
      aset(
        get,
        set,
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
        (cur) => {
          const clone = [...cur];
          const index = clone.findIndex((c) => c.id === blc.id);
          if (index !== -1) {
            clone[index] = blc;
          }
          return clone;
        },
      );
      const res = await putLayerCollection(projectId, data);
      Mixpanel.track_old("Update collection", {
        projectId,
        isDefault: data.isDefault,
        collectionId: data.id,
      });
      return res;
    },
    [projectId],
  );

  const remove = useJotaiCallback(
    async (get, set, collection: LayerCollection) => {
      if (!projectId) return;
      aset(
        get,
        set,
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter((c) => c.id !== collection.id);
          return rest;
        },
      );
      return deleteLayerCollection(projectId, collection);
    },
    [projectId],
  );

  const copyCollection = useJotaiCallback(
    async (
      _get,
      _set,
      collectionId: string,
      toProjectId: string,
      customLayerIds: string[],
      excludeLayerIds: string[],
      privateSourceUrls: string[],
    ) => {
      if (!toProjectId) return;
      return copyCollectionToProject(
        collectionId,
        projectId,
        toProjectId,
        customLayerIds,
        excludeLayerIds,
        privateSourceUrls,
      );
    },
    [projectId],
  );

  const putLocal = useJotaiCallback(
    async (get, set, projectId: string, data: BackendLayerCollection) => {
      aset(
        get,
        set,
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
        (cur) => {
          const clone = [...cur];
          const index = clone.findIndex((c) => c.id === data.id);
          if (index !== -1) {
            clone[index] = data;
            return clone;
          }
          return clone.concat(data);
        },
      );
    },
    [],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, projectId: string, collectionId: string) => {
      aset(
        get,
        set,
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter((c) => c.id !== collectionId);
          return rest;
        },
      );
    },
    [],
  );

  return {
    post,
    put,
    remove,
    copyCollection,
    putLocal,
    removeLocal,
    isCreatingNewCollection,
  };
};
