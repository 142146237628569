import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import CableIcon from "@icons/24/ExportCable.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import useCableTypeCrud from "../../../../hooks/useCableTypeCrud";
import Button from "components/General/Button";
import { CableType } from "../../../../services/cableTypeService";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { selectedMenuItemState } from "../../Shared/state";
import { scream } from "utils/sentry";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { SingleExportCable } from "components/ConfigurationModal/CableSettings/ExportCableSettings";
import { useToast } from "hooks/useToast";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  editorAccessProjectSelector,
  orgCableManageAccessSelector,
} from "state/user";
import { useDuplicateExportCableToLibrary } from "./useDuplicateToLibrary";
import { useDuplicateCableToProject } from "./useDuplicateToProject";
import { IconREMSize } from "styles/typography";
import { libraryAllSelectorFamily } from "state/featureAccess";
import useNodeExportCableResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeExportCableResourcesCrud";
import { Anchor } from "components/General/Anchor";
import ExportCableModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/ExportCableModal";
import { useAtomUnwrap } from "utils/jotai";
import { useNavigate } from "react-router-dom";
import {
  libraryExportCableTypesFamily,
  projectExportCableTypesFamily,
} from "state/jotai/exportCableType";
import { ExportCableWizard } from "../ExportCableWizard";
import SearchIcon from "@icons/24/Search.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { fromProjectToLibraryTabState } from "components/Organisation/Library/state";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Mixpanel } from "mixpanel";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { exportCableResourceWithAccessOnNodeState } from "state/cableType";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

export const EXPORT_CABLE_MENU_ID = "export-cables";

export default function useExportCableSettings() {
  const navigate = useNavigate();
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const orgExportCableManageAccess = useAtomValue(orgCableManageAccessSelector);

  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const { showConfirm } = useConfirm();

  const { update, create, deleteCable } = useCableTypeCrud();
  const projectExportCables = useAtomValue(
    projectExportCableTypesFamily(projectId),
  );
  const { success } = useToast();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const { duplicateToLibrary } = useDuplicateExportCableToLibrary();
  const { duplicateToProject } = useDuplicateCableToProject(true);

  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: EXPORT_CABLE_MENU_ID,
      projectId,
    }),
  );
  useEffect(() => {
    const cable = Array.from(projectExportCables.values())[0];
    if (!menuSelection && cable) setMenuSelection(cable.id);
  }, [projectExportCables, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProjectCableCollapsed, setIsProjectCableCollapsed] =
    useState<boolean>(false);
  const [isLibraryCableCollapsed, setIsLibraryCableCollapsed] =
    useState<boolean>(false);
  const libraryExportCables = useAtomUnwrap(
    libraryExportCableTypesFamily(projectId),
  );

  const _onDelete = useJotaiCallback(
    async (get, set, cableId: string) => {
      setIsLoading(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "CABLE",
          resourceId: cableId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(projectId, "CABLE", cableId);
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete cable type",
          message: `Cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        await deleteCable(cableId)
          .catch(() => {})
          .finally(() => setMenuSelection(undefined));
      }
      setIsLoading(false);
    },
    [deleteCable, projectId, setMenuSelection, showConfirm],
  );

  const _onUpdate = useJotaiCallback(
    async (get, _, newCable: CableType, skipUsageCheck: boolean = false) => {
      setIsLoading(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "CABLE",
          resourceId: newCable.id,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "CABLE",
          newCable.id,
        );
      }

      if (
        usage.length === 0 ||
        skipUsageCheck ||
        (await showConfirm({
          title: "Update cable type",
          message: `Cable type is currently being used in ${usage.length} cables, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        await update(newCable).catch((error) => {
          scream("Unable to update cable", {
            error,
          });
        });
        success("Saved");
      }
      setIsLoading(false);
    },
    [projectId, showConfirm, success, update],
  );

  const exportCableSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectExportCableList = Array.from(projectExportCables.values())
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((exportCable) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        dotMenuOptions.push({
          title: "Delete",
          onSelect: _onDelete,
          icon: <DeleteIcon />,
        });
        dotMenuOptions.push({
          title: "Duplicate",
          onSelect: () => duplicateToProject(exportCable),
          icon: <DuplicateIcon />,
        });

        if (orgExportCableManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => {
              setSaveInProgressLibrary(true);
              duplicateToLibrary(projectId, exportCable).then(() => {
                setSaveInProgressLibrary(false);
              });
            },
            icon: <DuplicateIcon />,
          });
        }

        return {
          id: exportCable.id,
          name: exportCable.name,
          label: `${exportCable.name} (${exportCable.voltage}kV)`,
          loading: isLoading !== false,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <SingleExportCable
                nodeId={projectId}
                disabled={isReadOnly}
                key={exportCable.id}
                exportCable={exportCable}
                onSave={_onUpdate}
                isLoading={isLoading}
                isLibraryExportCable={false}
              />
            </div>
          ),
          onDuplicate: undefined,
          onChangeName: (newName: string) => {
            return _onUpdate({
              ...exportCable,
              name: newName,
            });
          },
          dotMenuOptions,
        };
      });

    const libraryExportCableList = Array.from(
      libraryExportCables?.values() ?? [],
    )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((exportCable) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgExportCableManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library component",
            onSelect: () => {
              let searchParams = new URLSearchParams(location.search);
              Mixpanel.track_old("Click edit in library", {
                resource: "exportcable",
              });
              let newSearchString = searchParams.toString();
              setContent({
                type: "resource",
                id: exportCable.id,
              });
              setFromProjectToLibraryTab(true);
              navigate(
                `/organisation/${organisationId}/library/exportcable/${exportCable.id}?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
        }
        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => {
              setSaveInProgress(true);
              duplicateToProject(exportCable).then(() =>
                setSaveInProgress(false),
              );
            },
            icon: <DuplicateIcon />,
          });
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: exportCable.id,
          name: exportCable.name,
          label: `${exportCable.name} (${exportCable.voltage}kV)`,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <SingleExportCable
                nodeId={organisationId}
                disabled={true}
                key={exportCable.id}
                exportCable={exportCable}
                onSave={_onUpdate}
                isLibraryExportCable={true}
                isLoading={isLoading}
              />
            </div>
          ),
          dotMenuOptions,
        };
      });

    const libraryExportCablesMenu: SettingsSubMenuProp = {
      title: "Library components",
      items: libraryExportCableList,
      isCollapsed: isLibraryCableCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryCableCollapsed}
            onClick={() => setIsLibraryCableCollapsed(!isLibraryCableCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectExportCablesMenu: SettingsSubMenuProp = {
      items: projectExportCableList,
      title: "Project specific components",
      isCollapsed: isProjectCableCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectCableCollapsed}
            onClick={() => setIsProjectCableCollapsed(!isProjectCableCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    if (allLibraryAccess)
      return [libraryExportCablesMenu, projectExportCablesMenu];
    return [projectExportCablesMenu];
  }, [
    setFromProjectToLibraryTab,
    setContent,
    duplicateToLibrary,
    duplicateToProject,
    editorAccessProject,
    isLibraryCableCollapsed,
    isProjectCableCollapsed,
    navigate,
    organisationId,
    projectExportCables,
    libraryExportCables,
    projectId,
    isLoading,
    allLibraryAccess,
    orgExportCableManageAccess,
    isReadOnly,
    _onUpdate,
    _onDelete,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgressLibrary,
      element: <AddExportCableResourceToNode nodeId={projectId} />,
    };
  }, [saveInProgressLibrary, projectId]);

  const createNewCable = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <ExportCableWizardModal
          create={create}
          setLoading={setIsLoading}
          isLoading={isLoading}
          disabled={!editorAccessProject || isLoading}
        />
      ),
    };
  }, [saveInProgress, editorAccessProject, isLoading, create]);

  const exportCableSettings = useMemo(() => {
    return {
      id: EXPORT_CABLE_MENU_ID,
      label: "Export cables",
      icon: <CableIcon />,
      submenus: exportCableSubMenus,
      createNew: createNewCable,
      addFromLibrary: allLibraryAccess ? addFromLibrary : undefined,
    };
  }, [exportCableSubMenus, allLibraryAccess, createNewCable, addFromLibrary]);

  return exportCableSettings;
}

const ExportCableWizardModal = ({
  create,
  setLoading,
  isLoading,
  disabled,
}: {
  create: (exportCable: Partial<CableType>) => Promise<any>;
  setLoading: (v: boolean) => void;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const setMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: EXPORT_CABLE_MENU_ID,
      projectId,
    }),
  );
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="primary"
        onClick={() => setShow(true)}
        icon={<AddIcon />}
        disabled={disabled || isLoading}
      />
      {show && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <ExportCableWizard
            close={() => setShow(false)}
            create={(exportCable) =>
              create(exportCable)
                .then((res) => setMenuSelection(res.id))
                .finally(() => {
                  setShow(false);
                })
            }
            setLoading={setLoading}
          />
        </Anchor>
      )}
    </div>
  );
};

function AddExportCableResourceToNode({ nodeId }: { nodeId: string }) {
  const exportCableResources = useAtomValue(
    exportCableResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateExportCable } =
    useNodeExportCableResourcesCrud();
  const [showAddCable, setShowAddCable] = useState(false);
  const orgCableManageAccess = useAtomValue(orgCableManageAccessSelector);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        onClick={() => setShowAddCable(true)}
        text="Add from library"
        icon={<SearchIcon />}
        disabled={!orgCableManageAccess}
      />
      {showAddCable && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <ExportCableModal
            onSave={async (cables) => {
              setSaveInProgress(true);
              await Promise.all(
                cables.map((c) => addOrUpdateExportCable(nodeId, c.id)),
              );
              setSaveInProgress(false);
              setShowAddCable(false);
            }}
            existingCables={exportCableResources.map((c) => c.cable.id)}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
