import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ProjectFeature } from "../../types/feature";
import KeyInformationGeneral from "./KeyInformationGeneral";
import Add from "@icons/24/Add.svg?react";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { colors } from "../../styles/colors";
import { editorAccessProjectSelector } from "../../state/user";
import Button from "../General/Button";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { useShowScrollShadow } from "../../hooks/useShowScrollShadow";
import { inReadOnlyModeSelector } from "../../state/project";
import { _FeatureParser } from "../../types/feature";
import eventEmitter from "utils/eventEmitter";
import { FEATURE_PROPERTY_ADDED_EVENT } from "./events";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-top: 1px solid ${colors.hover};
  padding-top: 2rem;
`;

const InformationWrapper = styled.div<{
  showBottomShadow: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  padding-bottom: 2rem;

  ${({ showBottomShadow }) =>
    showBottomShadow &&
    `
    box-shadow: inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08);
    `}
`;

export const FeaturePropertiesEditor = ({
  canvasFeature,
  isEditor,
  inReadOnlyMode,
  updateFeatures,
  nameEditable,
}: {
  canvasFeature: ProjectFeature;
  isEditor: boolean;
  inReadOnlyMode: boolean;
  nameEditable?: boolean;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const { showBottomShadow, scrollBodyRef, forceCheck } = useShowScrollShadow();

  useEffect(() => {
    forceCheck();
  }, [canvasFeature, forceCheck]);

  return (
    <>
      <InformationWrapper
        ref={scrollBodyRef}
        showBottomShadow={showBottomShadow}
      >
        <KeyInformationGeneral
          updateFeatures={updateFeatures}
          canvasFeature={canvasFeature}
          nameEditable={nameEditable}
        />
      </InformationWrapper>
      {isEditor && (
        <ButtonWrapper>
          <Button
            buttonType="secondary"
            disabled={inReadOnlyMode}
            text="New"
            size="small"
            icon={<Add />}
            style={{
              marginLeft: "auto",
            }}
            onClick={() => {
              const newKeyIndex = Object.keys(canvasFeature.properties).reduce(
                (acc, curr) => {
                  const keyNumberStr = /^newKey(\d+)$/g.exec(curr);
                  if (keyNumberStr?.[1]) {
                    const num = Number(keyNumberStr[1]);
                    return Math.max(acc, num + 1);
                  }
                  return acc;
                },
                0,
              );

              const updatedFeature = _FeatureParser.parse({
                ...canvasFeature,
                properties: {
                  ...canvasFeature.properties,
                  [`newKey${newKeyIndex}`]: "newValue",
                },
              });

              updateFeatures([updatedFeature]);
              eventEmitter.emit(FEATURE_PROPERTY_ADDED_EVENT);
            }}
          />
        </ButtonWrapper>
      )}
    </>
  );
};

export const FeaturePropertiesMenuFrame = ({
  canvasFeature,
  onClose,
}: {
  canvasFeature: ProjectFeature;
  onClose(): void;
}) => {
  const projectEditorAccess = useAtomValue(editorAccessProjectSelector);
  const { update } = useProjectElementsCrud();
  const inReadOnlyMode = useAtomValue(inReadOnlyModeSelector);

  return (
    <MenuFrame
      title={"Element properties"}
      style={{
        position: "absolute",
        top: "4.4rem",
        maxHeight: "40vh",
        overflowY: "hidden",
        justifyContent: "space-between",
      }}
      onExit={onClose}
    >
      <FeaturePropertiesEditor
        canvasFeature={canvasFeature}
        isEditor={projectEditorAccess}
        inReadOnlyMode={inReadOnlyMode}
        updateFeatures={(updatedFeatures: ProjectFeature[] | undefined) =>
          update({
            update: updatedFeatures,
          })
        }
      />
    </MenuFrame>
  );
};
