import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { organisationFoundationResourceState } from "../state";
import {
  HeaderRow,
  MainRow,
  SubHeader,
  TabContainer,
  TableHeader,
} from "../style";
import AddIcon from "@icons/24/Add.svg";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Row } from "components/General/Layout";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { SearchInput } from "components/General/Input";
import { VerticalDivider } from "components/General/VerticalDivider";
import { Field, LibraryManageRole, Order, SortState } from "../types";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { FoundationType } from "types/foundations";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import { getIsLegalMove } from "business/folderStructure/utils";
import { FolderItem, FolderTreeItem } from "types/folderStructures";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { IconREMSize, typography } from "styles/typography";
import { RecursiveFolder } from "../shared/RecursiveFolder";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import {
  FOUNDATION_TABLE_LIST_ITEM,
  FOUNDATION_TABLE_FOLDER_ITEM,
} from "./constants";
import { ResourceCrudOperations } from "../shared/types";
import Tooltip from "components/General/Tooltip";
import { useLibraryFolderStructure } from "../shared/hooks/useLibraryFolderStructure";
import { useLibrarySearchAndSort } from "../shared/hooks/useLibrarySearchAndSort";
import { useLibraryDragAndDrop } from "../shared/hooks/useLibraryDragAndDrop";
import { FoundationsManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { CreateFoundationMenuOrganisation } from "components/SettingsV2/FeatureSettings/FoundationSubMenu";
import { showNewFoundationWizardAtom } from "state/foundations";
import useOrgFoundationCrud from "./useOrgFoundationCrud";
import ResourceItem from "../shared/ResourceItem/ResourceItem";
import { ContentTableRow } from "components/Organisation/OrganisationRightSide/style";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { selectedLibraryTabAtom } from "state/library";
import { orgFoundationManageAccessSelector } from "state/user";
import { useSyncSelectedItemWithResource } from "../hook";

type FoundationOrFolderItem = FoundationType | FolderItem;

export default function FoundationTab() {
  const hasOrgFoundationAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  useEffect(() => {
    setSelectedLibraryTab("foundation");
    return () => {
      setSelectedLibraryTab(undefined);
    };
  }, [setSelectedLibraryTab]);

  if (!hasOrgFoundationAccess) return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <FoundationTabInner />;
    </Suspense>
  );
}
function FoundationTabInner() {
  useSyncSelectedItemWithResource();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const foundations = useAtomValue(
    organisationFoundationResourceState({
      organisationId,
    }),
  );
  const params = useParams();
  const selectedItemId = params.selectedItemId;
  const [createFoundationInFolderId, setCreateFoundationInFolderId] = useState<
    string | undefined
  >();
  const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
    string | undefined
  >();
  const navigate = useNavigate();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const { showConfirm } = useConfirm();

  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);
  const [showNewFoundationWizard, setShowNewFoundationWizard] = useAtom(
    showNewFoundationWizardAtom,
  );

  const [openDescriptionId, setOpenDescriptionId] = useState<
    string | undefined
  >(undefined);

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  const {
    name,
    onNameChange,
    sortedResources: sortedFoundations,
  } = useLibrarySearchAndSort(foundations, sortState);

  const { folders, orphans } = useLibraryFolderStructure({
    organisationId,
    resources: sortedFoundations,
    sortState,
    libraryManageRole: "org_foundation_manage",
    searchTerm: name,
  });

  const { moveItems, createFolder } = useOrgFolderStructureCrud({
    libraryManageRole: "org_foundation_manage",
  });

  const collapsed = !!content;

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  const onClickOnResource = useCallback(
    async (id: string) => {
      if (
        !unsavedSettings ||
        (await showConfirm({
          title: "Unsaved settings",
          message: "Are you sure you want to quit without saving?",
        }))
      ) {
        setUnsavedSettings(false);
        navigate(`/organisation/${organisationId}/library/foundation/${id}`);
      } else {
        navigate(`/organisation/${organisationId}/library/foundation/${id}`);
      }
    },
    [
      navigate,
      unsavedSettings,
      setUnsavedSettings,
      organisationId,
      showConfirm,
    ],
  );

  const onCreateFoundationInFolder = useCallback(
    (folderId: string) => {
      setCreateFoundationInFolderId(folderId);
      setShowNewFoundationWizard(true);
    },
    [setCreateFoundationInFolderId, setShowNewFoundationWizard],
  );

  const onCreateFolderInFolder = useCallback(
    (folderId: string) => {
      setCreateFolderInFolderId(folderId);
      setShowCreateNewFolder(true);
    },
    [setCreateFolderInFolderId, setShowCreateNewFolder],
  );

  const {
    onDropOnFolder,
    onFolderDropOnFolder,
    dropRef,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(
    [FOUNDATION_TABLE_LIST_ITEM, FOUNDATION_TABLE_FOLDER_ITEM],
    folders,
    "org_foundation_manage",
  );

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folders);
    },
    [folders],
  );

  const { remove, duplicate, update } = useOrgFoundationCrud();

  const crudOperations: ResourceCrudOperations<LibraryManageRole.FOUNDATION> =
    useMemo(
      () => ({
        remove: async (id: string) => {
          await remove(id);
          return;
        },
        duplicate: ({ resource }) =>
          duplicate({
            foundation: resource,
            onSuccess: (newFoundation) => {
              navigate(
                `/organisation/${organisationId}/library/foundation/${newFoundation.id}`,
              );
            },
          }),
      }),
      [remove, duplicate, navigate, organisationId],
    );

  const getDescription = (resource: FoundationType) => resource.description;
  const updateDescription = async (
    resource: FoundationType,
    newDescription: string,
  ) => {
    await update({
      ...resource,
      description: newDescription,
    });
    return resource;
  };

  return (
    <>
      {showCreateNewFolder && (
        <NewItemModal
          title="New folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onSubmit={async (name) => {
            await createFolder(
              name,
              LibraryManageRole.FOUNDATION,
              [],
              createFolderInFolderId,
            );
          }}
          onClose={() => {
            setShowCreateNewFolder(false);
            setCreateFolderInFolderId(undefined);
          }}
        />
      )}
      <TabContainer>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            Foundations
          </h2>

          <Row>
            <Tooltip text={"Create new folder"}>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setShowCreateNewFolder(true);
                }}
                icon={<FolderAddIcon />}
              />
            </Tooltip>
            <div style={{ position: "relative", display: "inline-block" }}>
              <Button
                text={collapsed ? "" : "New library foundation"}
                icon={collapsed && <AddIcon />}
                disabled={isLoading}
                onClick={() => setShowNewFoundationWizard(true)}
              />
              {showNewFoundationWizard && (
                <CreateFoundationMenuOrganisation
                  setSaveInProgess={setSaveInProgress}
                  onCreate={async (foundation: FoundationType) => {
                    navigate(
                      `/organisation/${organisationId}/library/foundation/${foundation.id}`,
                    );

                    if (createFoundationInFolderId) {
                      await moveItems(
                        [
                          {
                            type: "RESOURCE",
                            id: foundation.id,
                            parentId: createFoundationInFolderId,
                          },
                        ],
                        createFoundationInFolderId,
                      );
                      setCreateFoundationInFolderId(undefined);
                    }
                  }}
                />
              )}
            </div>
            <VerticalDivider height={3.2} />
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === selectedItemId) return undefined;
                  if (!selectedItemId) {
                    return {
                      type: "no-item-selected",
                    };
                  }
                  return {
                    type: "resource",
                    id: selectedItemId,
                  };
                });
              }}
            />
          </Row>
        </Row>
        <Row alignCenter>
          <SubHeader>Access: </SubHeader> <FoundationsManagersPreview />
        </Row>
        <Row
          style={{
            padding: "1.2rem 0",
          }}
        >
          <SearchInput
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            placeholder={`Search foundations`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </Row>
        <MainRow ref={dropRef}>
          <HeaderRow>
            <TableHeader
              onClick={() => toggleSort("name")}
              style={{
                cursor: "pointer",
                flex: 4,
              }}
            >
              Name
              <IconREMSize
                width={1.2}
                height={1.2}
                style={{ marginLeft: "0.4rem" }}
              >
                {sortState.field === "name" && sortState.order === "ASC" ? (
                  <DirectionDown />
                ) : sortState.field === "name" && sortState.order === "DESC" ? (
                  <DirectionUp />
                ) : (
                  <DirectionDown />
                )}
              </IconREMSize>
            </TableHeader>
            {!collapsed && (
              <>
                <TableHeader style={{ flex: 1 }}>Type</TableHeader>
                <TableHeader style={{ flex: 1 }}>Last edited</TableHeader>
                <TableHeader style={{ flex: 1 }}>Used in</TableHeader>
              </>
            )}
            <TableHeader style={{ flex: 1 }}></TableHeader>
          </HeaderRow>

          {folders.map((folder) => (
            <RecursiveFolder
              key={folder.id}
              folder={folder}
              depth={0}
              onDropOnFolder={onDropOnFolder}
              onFolderDropOnFolder={onFolderDropOnFolder}
              isLegalFolderMove={isLegalFolderMove}
              onCreateFolderInFolder={onCreateFolderInFolder}
              collapsed={collapsed}
              selectedItemId={selectedItemId}
              onClickResource={onClickOnResource}
              organisationId={organisationId}
              isLoading={isLoading}
              deleteInProgress={deleteInProgress}
              setSelectedItemId={(id) => {
                navigate(
                  `/organisation/${organisationId}/library/foundation/${id}`,
                );
              }}
              setContent={setContent}
              setSaveInProgress={setSaveInProgress}
              setDeleteInProgress={setDeleteInProgress}
              sortedResources={sortedFoundations}
              libraryManageRole={LibraryManageRole.FOUNDATION}
              onCreateResourceInFolder={onCreateFoundationInFolder}
              onFolderDropOnResource={onFolderDropOnResource}
              openDescriptionId={openDescriptionId}
              setOpenDescriptionId={setOpenDescriptionId}
              searchTerm={name}
              remove={crudOperations.remove}
              duplicate={crudOperations.duplicate}
              getDescription={getDescription}
              updateDescription={updateDescription}
              extraColumn={(resource) => (
                <ContentTableRow style={{ flex: 1, ...typography.caption }}>
                  {resource.type}
                </ContentTableRow>
              )}
            />
          ))}

          {orphans.map((item: FoundationOrFolderItem) => {
            const foundation =
              "name" in item
                ? item
                : sortedFoundations.find((f) => f.id === item.id);
            if (!foundation || !("name" in foundation)) return null;

            return (
              <ResourceItem
                key={item.id}
                resource={foundation as FoundationType}
                depth={0}
                collapsed={collapsed}
                isSelected={selectedItemId === item.id}
                onClickResource={onClickOnResource}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                libraryManageRole={LibraryManageRole.FOUNDATION}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                folders={folders}
                searchTerm={name}
                getDescription={getDescription}
                updateDescription={updateDescription}
                extraColumn={(resource) => (
                  <ContentTableRow style={{ flex: 1, ...typography.caption }}>
                    {resource.type}
                  </ContentTableRow>
                )}
              />
            );
          })}
        </MainRow>
      </TabContainer>
    </>
  );
}
