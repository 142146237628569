import { FoundationType } from "types/foundations";
import { SimpleTurbineType } from "types/turbines";
import { defaultTowerMaterial } from "./materials";
import {
  isFloater,
  isSimpleJacket,
  isDetailedJacket,
  isSimpleMonopile,
  isDetailedMonopile,
  isSemiCentral,
  isSemiPeripheral,
  isSpar,
} from "utils/predicates";
import * as THREE from "three";
import { foundationScale } from "state/jotai/foundation";
import { MooringCoords } from "functions/mooring";

export function createFoundationGeometries(
  foundation: FoundationType,
): THREE.BufferGeometry[] {
  const meshes: THREE.BufferGeometry[] = [];
  if (isSemiCentral(foundation)) {
    const cornerColGeometry1 = new THREE.CylinderGeometry(
      foundation.cornerColDiameter / 2,
      foundation.cornerColDiameter / 2,
      foundation.cornerColHeight,
      32,
    );
    const centralColGeometry = new THREE.CylinderGeometry(
      foundation.centralColDiameterTop / 2,
      foundation.centralColDiameterBase / 2,
      foundation.centralColHeight,
      32,
    );
    const pontoonGeometry1 = new THREE.BoxGeometry(
      foundation.ponWidth,
      foundation.ponHeight,
      foundation.ccDistance,
    );
    cornerColGeometry1.rotateX((90 * Math.PI) / 180);
    const cornerColGeometry2 = cornerColGeometry1.clone();
    const cornerColGeometry3 = cornerColGeometry1.clone();
    cornerColGeometry1.translate(
      foundation.ccDistance,
      0,
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry2.translate(
      foundation.ccDistance * Math.cos((120 * Math.PI) / 180),
      foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry3.translate(
      foundation.ccDistance * Math.cos((240 * Math.PI) / 180),
      foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    meshes.push(cornerColGeometry1, cornerColGeometry2, cornerColGeometry3);

    centralColGeometry.rotateX((90 * Math.PI) / 180);
    centralColGeometry.translate(
      0,
      0,
      foundation.centralColHeight / 2 + foundation.centralColStart,
    );
    meshes.push(centralColGeometry);

    pontoonGeometry1.rotateX((90 * Math.PI) / 180);
    pontoonGeometry1.rotateZ((90 * Math.PI) / 180);
    const pontoonGeometry2 = pontoonGeometry1.clone();
    const pontoonGeometry3 = pontoonGeometry1.clone();
    pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
    pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
    pontoonGeometry1.translate(
      foundation.ccDistance / 2,
      0,
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((120 * Math.PI) / 180),
      (foundation.ccDistance / 2) * Math.sin((120 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((240 * Math.PI) / 180),
      (foundation.ccDistance / 2) * Math.sin((240 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    meshes.push(pontoonGeometry1, pontoonGeometry2, pontoonGeometry3);
  } else if (isSemiPeripheral(foundation)) {
    const cornerColGeometry1 = new THREE.CylinderGeometry(
      foundation.cornerColDiameter / 2,
      foundation.cornerColDiameter / 2,
      foundation.cornerColHeight,
      32,
    );
    const pontoonGeometry1 = new THREE.BoxGeometry(
      foundation.ponWidth,
      foundation.ponHeight,
      foundation.ccDistance * Math.sqrt(3),
    );
    const deckGeometry1 = new THREE.BoxGeometry(
      foundation.deckWidth,
      foundation.deckHeight,
      foundation.ccDistance * Math.sqrt(3),
    );
    cornerColGeometry1.rotateX((90 * Math.PI) / 180);
    const cornerColGeometry2 = cornerColGeometry1.clone();
    const cornerColGeometry3 = cornerColGeometry1.clone();
    cornerColGeometry1.translate(
      foundation.ccDistance - foundation.ccDistance,
      0,
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry2.translate(
      foundation.ccDistance * Math.cos((120 * Math.PI) / 180) -
        foundation.ccDistance,
      foundation.ccDistance * Math.sin((120 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    cornerColGeometry3.translate(
      foundation.ccDistance * Math.cos((240 * Math.PI) / 180) -
        foundation.ccDistance,
      foundation.ccDistance * Math.sin((240 * Math.PI) / 180),
      foundation.cornerColHeight / 2 + foundation.cornerColStart,
    );
    meshes.push(cornerColGeometry1, cornerColGeometry2, cornerColGeometry3);

    pontoonGeometry1.rotateX((90 * Math.PI) / 180);
    const pontoonGeometry2 = pontoonGeometry1.clone();
    const pontoonGeometry3 = pontoonGeometry1.clone();
    pontoonGeometry2.rotateZ((120 * Math.PI) / 180);
    pontoonGeometry3.rotateZ((240 * Math.PI) / 180);
    pontoonGeometry1.translate(
      (foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    pontoonGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
      foundation.ponHeight / 2 + foundation.ponStart,
    );
    meshes.push(pontoonGeometry1, pontoonGeometry2, pontoonGeometry3);

    deckGeometry1.rotateX((90 * Math.PI) / 180);
    const deckGeometry2 = deckGeometry1.clone();
    const deckGeometry3 = deckGeometry1.clone();
    deckGeometry2.rotateZ((120 * Math.PI) / 180);
    deckGeometry3.rotateZ((240 * Math.PI) / 180);
    deckGeometry1.translate(
      (foundation.ccDistance / 2) * Math.cos((180 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((180 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    deckGeometry2.translate(
      (foundation.ccDistance / 2) * Math.cos((300 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((300 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    deckGeometry3.translate(
      (foundation.ccDistance / 2) * Math.cos((60 * Math.PI) / 180) -
        foundation.ccDistance,
      (foundation.ccDistance / 2) * Math.sin((60 * Math.PI) / 180),
      foundation.deckHeight / 2 + foundation.deckStart,
    );
    meshes.push(deckGeometry1, deckGeometry2, deckGeometry3);
  } else if (isSpar(foundation)) {
    const topGeometry = new THREE.CylinderGeometry(
      foundation.topDiameter / 2,
      foundation.topDiameter / 2,
      foundation.colHeight - foundation.draft - foundation.upperTaperLevel,
      32,
    );
    const taperGeometry = new THREE.CylinderGeometry(
      foundation.topDiameter / 2,
      foundation.baseDiameter / 2,
      foundation.upperTaperLevel - foundation.lowerTaperLevel,
      32,
    );
    const baseGeometry = new THREE.CylinderGeometry(
      foundation.baseDiameter / 2,
      foundation.baseDiameter / 2,
      foundation.draft + foundation.lowerTaperLevel,
      32,
    );
    topGeometry.rotateX((90 * Math.PI) / 180);
    taperGeometry.rotateX((90 * Math.PI) / 180);
    baseGeometry.rotateX((90 * Math.PI) / 180);
    topGeometry.translate(
      0,
      0,
      foundation.colHeight -
        foundation.draft -
        (foundation.colHeight - foundation.draft - foundation.upperTaperLevel) /
          2,
    );
    taperGeometry.translate(
      0,
      0,
      foundation.lowerTaperLevel +
        (foundation.upperTaperLevel - foundation.lowerTaperLevel) / 2,
    );
    baseGeometry.translate(
      0,
      0,
      -foundation.draft + (foundation.draft + foundation.lowerTaperLevel) / 2,
    );
    meshes.push(topGeometry, taperGeometry, baseGeometry);
  } else if (isSimpleMonopile(foundation) || isDetailedMonopile(foundation)) {
    const pileDiameter = 12;
    const pileLength = 50;
    const pileGeometry = new THREE.CylinderGeometry(
      pileDiameter / 2,
      pileDiameter / 2,
      pileLength,
      32,
    );
    pileGeometry.rotateX((90 * Math.PI) / 180);
    pileGeometry.translate(0, 0, -pileLength / 2 + 20);
    meshes.push(pileGeometry);
  } else if (isSimpleJacket(foundation)) {
    const waterDepth = 60;
    const lengthAboveWL = 10;
    const legDiameter = 2.6;
    const braceDiameter = 1.3;
    const deckHeight = 3.0;
    const h0 = 6.4,
      h1 = 16.6,
      h2 = 14.0,
      h3 = 11.64,
      h4 = 13.44,
      h5 = 7.92;
    const h = [h0, h1, h2, h3, h4, h5];
    const d0 = 25,
      d5 = 14;
    const d1 = d0 + ((d5 - d0) * h1) / (h1 + h2 + h3 + h4 + h5);
    const d2 = d0 + ((d5 - d0) * (h1 + h2)) / (h1 + h2 + h3 + h4 + h5);
    const d3 = d0 + ((d5 - d0) * (h1 + h2 + h3)) / (h1 + h2 + h3 + h4 + h5);
    const d4 =
      d0 + ((d5 - d0) * (h1 + h2 + h3 + h4)) / (h1 + h2 + h3 + h4 + h5);
    const d = [d0, d1, d2, d3, d4, d5];
    const l: number[] = [];
    for (let i = 0; i < 5; i++) {
      l.push(
        Math.sqrt(Math.pow(d[i] / 2 + d[i + 1] / 2, 2) + Math.pow(h[i + 1], 2)),
      );
    }
    const angle: number[] = [];
    for (let i = 0; i < 5; i++) {
      angle.push(Math.atan(h[i + 1] / (d[i] / 2 + d[i + 1] / 2)));
    }
    const legLength = Math.sqrt(
      Math.pow(d0 / 2 - d5 / 2, 2) +
        Math.pow(lengthAboveWL + waterDepth - h0, 2),
    );
    const legAngle = Math.atan(
      (d0 / 2 - d5 / 2) / (lengthAboveWL + waterDepth - h0),
    );

    for (let i = 0; i < 4; i++) {
      const legGeometry = new THREE.CylinderGeometry(
        legDiameter / 2,
        legDiameter / 2,
        legLength,
        32,
      );
      legGeometry.rotateX((90 * Math.PI) / 180 - legAngle);
      legGeometry.rotateY(legAngle);
      legGeometry.translate(
        -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
        -d0 / 2 + (legLength / 2) * Math.sin(legAngle),
        -waterDepth + legLength / 2 + h0,
      );
      legGeometry.rotateZ((90 * i * Math.PI) / 180);
      meshes.push(legGeometry);
    }

    const bGeometry: any[][] = [[], [], [], []];
    let braceHeight = 0;
    for (let j = 0; j < 5; j++) {
      braceHeight += h[j];
      const braceGeometry = new THREE.CylinderGeometry(
        braceDiameter / 2,
        braceDiameter / 2,
        l[j],
        32,
      );
      braceGeometry.rotateX(angle[j]);
      braceGeometry.rotateY(legAngle);
      braceGeometry.translate(
        -d[j] / 2 + (l[j] / 2) * Math.sin(legAngle),
        -d[j] / 2 + (l[j] / 2) * Math.cos(angle[j]),
        -waterDepth + braceHeight + (Math.tan(angle[j]) * d[j]) / 2,
      );
      bGeometry[0].push(braceGeometry);
    }

    for (let j = 0; j < 5; j++) {
      const braceGeometry = bGeometry[0][j].clone();
      braceGeometry.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
      bGeometry[0].push(braceGeometry);
    }

    for (let i = 1; i < 4; i++) {
      for (let j = 0; j < 10; j++) {
        const braceGeometry = bGeometry[0][j].clone();
        braceGeometry.rotateZ((90 * i * Math.PI) / 180);
        bGeometry[i].push(braceGeometry);
      }
    }

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 10; j++) {
        meshes.push(bGeometry[i][j]);
      }
    }

    const deckGeometry = new THREE.BoxGeometry(
      d5 + legDiameter,
      d5 + legDiameter,
      deckHeight,
    );
    deckGeometry.translate(0, 0, lengthAboveWL);

    meshes.push(deckGeometry);
  } else if (isDetailedJacket(foundation)) {
    const waterDepth = 60;
    const jacketHeight = waterDepth + 10;
    const lengthAboveWL = jacketHeight - waterDepth;
    const legDiameter = 2.6;
    const braceDiameter = 1.3;
    const deckHeight = 3.0;
    const m = Math.pow(
      foundation.Lbottom / foundation.Ltop,
      1 / foundation.numBays,
    );
    const h: number[] = [
      (jacketHeight * (m - 1)) / (Math.pow(m, foundation.numBays) - 1),
    ];
    for (let i = 1; i < foundation.numBays; i++) {
      h.push(h[i - 1] * m);
    }
    h.reverse();
    const d: number[] = [foundation.Ltop];
    for (let i = 1; i < foundation.numBays; i++) {
      d.push(d[i - 1] * m);
    }
    d.push(foundation.Lbottom);
    d.reverse();
    const l: number[] = [];
    for (let i = 0; i < foundation.numBays; i++) {
      l.push(
        Math.sqrt(Math.pow(d[i] / 2 + d[i + 1] / 2, 2) + Math.pow(h[i], 2)),
      );
    }
    const angle: number[] = [];
    for (let i = 0; i < foundation.numBays; i++) {
      angle.push(Math.atan(h[i] / (d[i] / 2 + d[i + 1] / 2)));
    }
    const legLength = Math.sqrt(
      Math.pow(d[0] / 2 - d[d.length - 1] / 2, 2) +
        Math.pow(lengthAboveWL + waterDepth, 2),
    );
    const legAngle = Math.atan(
      (d[0] / 2 - d[d.length - 1] / 2) / (lengthAboveWL + waterDepth),
    );

    const faceAngle = (2 * Math.PI) / foundation.numLegs;

    for (let i = 0; i < foundation.numLegs; i++) {
      const legGeometry = new THREE.CylinderGeometry(
        legDiameter / 2,
        legDiameter / 2,
        legLength,
        32,
      );
      legGeometry.rotateX((90 * Math.PI) / 180 - legAngle);
      legGeometry.rotateY(legAngle);
      legGeometry.translate(
        -d[0] / 2 +
          (legLength / 2) *
            Math.sin(legAngle) *
            (foundation.numLegs === 3 ? 1.5 : 1),
        -d[0] / 2 +
          (legLength / 2) *
            Math.sin(legAngle) *
            (foundation.numLegs === 3 ? 1.5 : 1),
        -waterDepth + legLength / 2,
      );
      legGeometry.rotateZ(i * faceAngle);
      meshes.push(legGeometry);
    }

    const bGeometry: any[][] = [[], [], [], []];
    let braceHeight = 0;
    for (let j = 0; j < foundation.numBays; j++) {
      const braceGeometry = new THREE.CylinderGeometry(
        braceDiameter / 2,
        braceDiameter / 2,
        l[j],
        32,
      );
      braceGeometry.rotateX(angle[j]);
      braceGeometry.rotateY(legAngle);
      if (foundation.numLegs === 3) {
        braceGeometry.rotateZ((-15 * Math.PI) / 180);
        braceGeometry.translate(
          (-0.15 * j + 1.5) * legDiameter,
          legDiameter / 1.5,
          -legDiameter / 4,
        );
      }
      braceGeometry.translate(
        -d[j] / 2 + (l[j] / 2) * Math.sin(legAngle),
        -d[j] / 2 + (l[j] / 2) * Math.cos(angle[j]),
        -waterDepth + braceHeight + (Math.tan(angle[j]) * d[j]) / 2,
      );
      bGeometry[0].push(braceGeometry);
      braceHeight += h[j];
    }

    braceHeight = 0;
    for (let j = 0; j < foundation.numBays; j++) {
      const braceGeometry = new THREE.CylinderGeometry(
        braceDiameter / 2,
        braceDiameter / 2,
        l[j],
        32,
      );
      braceGeometry.rotateX(-angle[j]);
      braceGeometry.rotateY(legAngle);
      if (foundation.numLegs === 3) {
        braceGeometry.rotateZ((-15 * Math.PI) / 180);
        braceGeometry.translate(
          (-0.15 * j + 1.5) * legDiameter,
          legDiameter / 1.5,
          -legDiameter / 4,
        );
      }
      braceGeometry.translate(
        -d[j] / 2 + (l[j] / 2) * Math.sin(legAngle),
        -d[j] / 2 + (l[j] / 2) * Math.cos(angle[j]) + braceDiameter,
        -waterDepth + braceHeight + (Math.tan(angle[j]) * d[j]) / 2,
      );
      bGeometry[0].push(braceGeometry);
      braceHeight += h[j];
    }

    for (let i = 1; i < foundation.numLegs; i++) {
      for (let j = 0; j < 2 * foundation.numBays; j++) {
        const braceGeometry = bGeometry[0][j].clone();
        braceGeometry.rotateZ(i * faceAngle);
        bGeometry[i].push(braceGeometry);
      }
    }

    for (let i = 0; i < foundation.numLegs; i++) {
      for (let j = 0; j < 2 * foundation.numBays; j++) {
        meshes.push(bGeometry[i][j]);
      }
    }

    let deckGeometry: THREE.BufferGeometry;

    if (foundation.numLegs === 3) {
      const sideLength = d[d.length - 1] + legDiameter;
      const triangleShape = createTriangularShape(sideLength);
      deckGeometry = new THREE.ExtrudeGeometry(triangleShape, {
        depth: deckHeight,
        bevelEnabled: false,
      });
      deckGeometry.rotateZ(Math.PI / 2);
      deckGeometry.rotateZ((-15 * Math.PI) / 180);
      deckGeometry.translate(legDiameter, -legDiameter / 4, -deckHeight / 2);
    } else {
      deckGeometry = new THREE.BoxGeometry(
        d[d.length - 1] + legDiameter,
        d[d.length - 1] + legDiameter,
        deckHeight,
      );
    }

    deckGeometry.translate(0, 0, lengthAboveWL);
    meshes.push(deckGeometry);
  }

  return meshes;
}

export const createFoundationModel = (
  turbine: SimpleTurbineType,
  foundation: FoundationType,
) => {
  const mat = defaultTowerMaterial();

  const geometries = createFoundationGeometries(foundation);
  const meshes = geometries.map((g) => new THREE.Mesh(g, mat));

  const scale = isFloater(foundation)
    ? foundationScale({
        foundation: foundation,
        turbine: turbine,
      }) ?? 1
    : turbine.diameter / 240;

  for (const m of meshes) {
    m.scale.set(scale, scale, scale);
  }

  return meshes;
};

export function createCableLineGeometry(
  cableCoords: MooringCoords,
): THREE.BufferGeometry {
  const points: THREE.Vector3[] = [];
  for (let i = 0; i < cableCoords.x.length; i++) {
    points.push(
      new THREE.Vector3(cableCoords.x[i], cableCoords.y[i], cableCoords.z[i]),
    );
  }
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  return geometry;
}

export function defaultCableMaterial() {
  return new THREE.LineBasicMaterial({
    color: 0x000000,
    depthWrite: false,
    depthTest: false,
  });
}

function createTriangularShape(sideLength: number): THREE.Shape {
  const shape = new THREE.Shape();
  const height = (Math.sqrt(3) / 2) * sideLength;

  shape.moveTo(0, -height / 2);
  shape.lineTo(sideLength / 2, height / 2);
  shape.lineTo(-sideLength / 2, height / 2);
  shape.lineTo(0, -height / 2);

  return shape;
}
