import {
  analysisOverrideInputFamily,
  getBranchId,
  getParkId,
  getScalesForTurbineTypeIdsAndFoundationIds,
  getTurbineCapacity,
  getTurbinesWithFixedFoundations,
} from "analysis/inputs";
import { amountAddId, amountId } from "./amountIds";
import { valueRounding } from "components/RightSide/InfoModal/FoundationModal/utils";
import {
  getConfiguration,
  getOperationsConfiguration,
  getRasterId,
} from "finance/inputs";
import { FinanceId } from "finance/types";
import { atom, Getter } from "jotai";
import { CostType, isOperationsCost } from "services/costService";
import { turbineTypeAndFloatingFoundationCombinations } from "state/foundations";
import {
  foundationFloaterTypesAtom,
  foundationTypesAtom,
  getDetailedJacketMassesFamily,
  getDetailedMonopileMassesFamily,
  getFloatingFoundationTotalsFamily,
  getSimpleFixedMassesFamily,
} from "state/jotai/foundation";
import { turbinesInParkWithFoundationFamily } from "state/jotai/turbine";
import { TurbineFeature } from "types/feature";
import { Amount, AmountUnit } from "types/financial";
import { atomFamily } from "utils/jotai";
import { isFloater } from "utils/predicates";
import { FoundationType } from "types/foundations";
import { HOURS_PER_DAY } from "@constants/production";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { quaysideTotalInstallationTimeFamily } from "components/Installation/foundations/quaysideInstallationTime";
import { vesselTypesFamily } from "state/jotai/vesselType";

const jacketAmountFamily = atomFamily((triggerId: FinanceId) =>
  atom(async (get) => {
    const parkId = await get(getParkId(triggerId));
    const branchId = await get(getBranchId(triggerId));
    const turbinesWithFnd = await get(
      turbinesInParkWithFoundationFamily({
        parkId,
        branchId,
      }),
    );
    const rasterId = await get(getRasterId(triggerId));

    const turbinesWithSimpleJacket = turbinesWithFnd
      .filter(([, f]) => f.type === "jacket")
      .map(([t]) => t);
    const turbinesWithDetailedJacket = turbinesWithFnd
      .filter(([, f]) => f.type === "detailed_jacket")
      .map(([t]) => t);

    const turbinesWithJacket = [
      ...turbinesWithSimpleJacket,
      ...turbinesWithDetailedJacket,
    ];

    const simpleJacketMasses = await get(
      getSimpleFixedMassesFamily({
        turbinesWithSimpleFixed: turbinesWithSimpleJacket,
        rasterId,
      }),
    );

    const detailedJacketMasses = await get(
      getDetailedJacketMassesFamily({
        turbinesWithDetailedJacket,
        rasterId,
      }),
    );

    const totalPrimarySteelMass =
      simpleJacketMasses.totalFoundationMass +
      detailedJacketMasses.totalFoundationMass;

    const totalsWithTonnes = {
      totalPrimarySteelMass: totalPrimarySteelMass / 1000,
    };

    const steel = amountAddId(
      {
        unit: AmountUnit.t,
        amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
        category: CostType.Foundation,
      },
      "Primary steel jacket",
    );

    const units = amountAddId(
      {
        unit: AmountUnit.unit,
        amount:
          turbinesWithSimpleJacket.length + turbinesWithDetailedJacket.length,
        category: CostType.Foundation,
      },
      "jacket",
    );

    let perDayAmount: Amount[] = [];

    const configuration = await get(getConfiguration(triggerId));
    if (isOperationsCost(configuration.capex.installation.foundations)) {
      const installationTime =
        turbinesWithJacket.length > 0
          ? await get(
              getTotalInstallationTime({
                id: triggerId,
                type: InstallationType.Jacket,
              }),
            )
          : 0;
      const operationsConfiguration = await get(
        getOperationsConfiguration(triggerId),
      );

      const { installationVessel, feederVessel } =
        operationsConfiguration.ti.foundations.jackets;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      perDayAmount = [installationVessel, feederVessel].map(({ vesselId }) => {
        const mobilizationTime =
          vesselTypes.get(vesselId)?.mobilizationTime ?? 0;

        return {
          id: amountId({
            unit: AmountUnit.day,
            category: CostType.Foundation,
            featureTypeId: `jacket_${vesselId}`,
          }),
          unit: AmountUnit.day,
          amount:
            turbinesWithJacket.length > 0
              ? (installationTime ?? 0) + mobilizationTime
              : 0,
          category: CostType.Foundation,
        };
      });
    }

    return [steel, units, ...perDayAmount];
  }),
);

const monopileAmountFamily = atomFamily((triggerId: FinanceId) =>
  atom(async (get) => {
    const parkId = await get(getParkId(triggerId));
    const branchId = await get(getBranchId(triggerId));
    const turbinesWithFnd = await get(
      turbinesInParkWithFoundationFamily({
        parkId,
        branchId,
      }),
    );
    const rasterId = await get(getRasterId(triggerId));

    const turbinesWithSimpleMonopile = turbinesWithFnd
      .filter(([, f]) => f.type === "monopile")
      .map(([t]) => t);

    const turbinesWithDetailedMonopile = turbinesWithFnd
      .filter(([, f]) => f.type === "detailed_monopile")
      .map(([t]) => t);

    const turbinesWithMonopile = [
      ...turbinesWithSimpleMonopile,
      ...turbinesWithDetailedMonopile,
    ];

    const simpleMonopileMasses = await get(
      getSimpleFixedMassesFamily({
        turbinesWithSimpleFixed: turbinesWithSimpleMonopile,
        rasterId,
      }),
    );

    const detailedMonopileMasses = await get(
      getDetailedMonopileMassesFamily({
        turbinesWithDetailedMonopile,
        rasterId,
      }),
    );

    const totalPrimarySteelMass =
      simpleMonopileMasses.totalFoundationMass +
      detailedMonopileMasses.totalFoundationMass;

    const totalsWithTonnes = {
      totalPrimarySteelMass: totalPrimarySteelMass / 1000,
    };

    const steel = amountAddId(
      {
        unit: AmountUnit.t,
        amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
        category: CostType.Foundation,
      },
      "Primary steel monopile",
    );

    const units = amountAddId(
      {
        unit: AmountUnit.unit,
        amount:
          turbinesWithSimpleMonopile.length +
          turbinesWithDetailedMonopile.length,
        category: CostType.Foundation,
      },
      "monopile",
    );

    const configuration = await get(getConfiguration(triggerId));
    let perDayAmount: Amount[] = [];
    if (isOperationsCost(configuration.capex.installation.foundations)) {
      const installationTime =
        turbinesWithMonopile.length > 0
          ? await get(
              getTotalInstallationTime({
                id: triggerId,
                type: InstallationType.Monopile,
              }),
            )
          : 0;
      const operationsConfiguration = await get(
        getOperationsConfiguration(triggerId),
      );

      const { installationVessel } =
        operationsConfiguration.ti.foundations.monopiles;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      const mobilizationTime =
        vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

      perDayAmount.push({
        id: amountId({
          unit: AmountUnit.day,
          category: CostType.Foundation,
          featureTypeId: `monopile_${installationVessel.vesselId}`,
        }),
        unit: AmountUnit.day,
        amount:
          turbinesWithMonopile.length > 0
            ? (installationTime ?? 0) + mobilizationTime
            : 0,
        category: CostType.Foundation,
      });
    }

    return [steel, units, ...perDayAmount];
  }),
);

async function floatingFoundationAmountSelectorFamily(
  get: Getter,
  turbines: TurbineFeature[],
  id: FinanceId,
): Promise<Amount[]> {
  const foundations = await get(foundationTypesAtom);

  const turbinesWithFloatingFoundation = turbines.filter((t) =>
    isFloater(foundations.get(t.properties.foundationId ?? "")),
  );

  const allFloaters = await get(foundationFloaterTypesAtom);

  const turbineTypeIdAndFloatingFoundationIdCombinations =
    turbineTypeAndFloatingFoundationCombinations(
      turbinesWithFloatingFoundation,
      allFloaters,
    );

  const scales = await get(
    getScalesForTurbineTypeIdsAndFoundationIds(
      turbineTypeIdAndFloatingFoundationIdCombinations,
    ),
  );

  const {
    totalPrimarySteelMass,
    totalPrimaryConcreteVolume,
    totalRebarMass,
    totalPostTensMass,
    totalSolidBallastMass,
  } = await get(
    getFloatingFoundationTotalsFamily({
      tempLayoutFoundations: turbinesWithFloatingFoundation,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }),
  );

  const totalsWithTonnes = {
    totalPrimarySteelMass: totalPrimarySteelMass / 1000,
    totalPrimaryConcreteVolume,
    totalRebarMass: totalRebarMass / 1000,
    totalPostTensMass: totalPostTensMass / 1000,
    totalSolidBallastMass: totalSolidBallastMass / 1000,
  };

  const steel = amountAddId(
    {
      unit: AmountUnit.t,
      amount: valueRounding(totalsWithTonnes.totalPrimarySteelMass, 100),
      category: CostType.Foundation,
    },
    "Primary steel floating",
  );

  const concrete = amountAddId(
    {
      unit: AmountUnit.m3,
      amount: valueRounding(totalsWithTonnes.totalPrimaryConcreteVolume, 100),
      category: CostType.Foundation,
    },
    "Concrete",
  );

  const reinforcement = amountAddId(
    {
      unit: AmountUnit.t,
      amount: valueRounding(totalsWithTonnes.totalRebarMass, 100),
      category: CostType.Foundation,
    },
    "Reinforcement",
  );

  const cables = amountAddId(
    {
      unit: AmountUnit.t,
      amount: valueRounding(totalsWithTonnes.totalPostTensMass, 100),
      category: CostType.Foundation,
    },
    "Post tension cables",
  );

  const ballast = amountAddId(
    {
      unit: AmountUnit.t,
      amount: valueRounding(totalsWithTonnes.totalSolidBallastMass, 100),
      category: CostType.Foundation,
    },
    "Solid ballast",
  );

  const units = amountAddId(
    {
      unit: AmountUnit.unit,
      amount: turbinesWithFloatingFoundation.length,
      category: CostType.Foundation,
    },
    "floating",
  );

  let installation: Amount[] = [];
  const configuration = await get(getConfiguration(id));
  if (isOperationsCost(configuration.capex.installation.foundations)) {
    const installationTime =
      turbinesWithFloatingFoundation.length > 0
        ? await get(
            getTotalInstallationTime({ id, type: InstallationType.Floater }),
          )
        : 0;
    const quaysideTimeHours =
      turbinesWithFloatingFoundation.length > 0
        ? await get(quaysideTotalInstallationTimeFamily(id))
        : 0;
    const quaysideTime = valueRounding(quaysideTimeHours / HOURS_PER_DAY, 0.1);
    const operationsConfiguration = await get(getOperationsConfiguration(id));

    const { installationVessel, towingVessel } =
      operationsConfiguration.ti.foundations.floaters;

    const vesselTypes = await get(vesselTypesFamily(undefined));

    const mobTimeInstallVessel =
      vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;
    const mobTimeTowingVessel =
      vesselTypes.get(towingVessel.vesselId)?.mobilizationTime ?? 0;

    installation = [
      {
        id: "floater_installation_install_vessel",
        unit: AmountUnit.day,
        amount:
          turbinesWithFloatingFoundation.length > 0
            ? (installationTime ?? 0) + mobTimeInstallVessel
            : 0,
        category: CostType.Foundation,
      },
      {
        id: "floater_installation_towing_vessel",
        unit: AmountUnit.day,
        amount:
          turbinesWithFloatingFoundation.length > 0
            ? (installationTime ?? 0) + mobTimeTowingVessel
            : 0,
        category: CostType.Foundation,
      },
      {
        id: "floater_installation_port_crane",
        unit: AmountUnit.day,
        amount: quaysideTime,
        category: CostType.Foundation,
      },
    ];
  }

  return [
    steel,
    concrete,
    reinforcement,
    cables,
    ballast,
    units,
    ...installation,
  ];
}

export const foundationAmountFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Amount[]>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const input = await get(analysisOverrideInputFamily(id));

    const rawTurbinesWithFoundation = await get(
      turbinesInParkWithFoundationFamily({ parkId, branchId }),
    );

    const turbinesWithFoundation = (
      input.turbineTypeOverride
        ? rawTurbinesWithFoundation.map(([t, f]) => [
            {
              ...t,
              properties: {
                ...t.properties,
                turbineTypeId: input.turbineTypeOverride!.id,
              },
            },
            f,
          ])
        : rawTurbinesWithFoundation
    ) as [TurbineFeature, FoundationType][];

    const turbinesWithFloatingFoundations = turbinesWithFoundation.flatMap(
      ([t, f]) => (isFloater(f) ? [t] : []),
    );

    const floatingAmounts = await floatingFoundationAmountSelectorFamily(
      get,
      turbinesWithFloatingFoundations,
      id,
    );

    const jacketAmounts = await get(jacketAmountFamily(id));
    const monopileAmounts = await get(monopileAmountFamily(id));

    const turbinesWithFixedFoundations = await get(
      getTurbinesWithFixedFoundations(id),
    );

    const capacity = await get(getTurbineCapacity(id));

    const perMwAmount = amountAddId({
      unit: AmountUnit.MW,
      amount: turbinesWithFoundation.length > 0 ? capacity ?? 0 : 0,
      category: CostType.Foundation,
    });

    const foundationCount = amountAddId({
      unit: AmountUnit.unit,
      amount: turbinesWithFoundation.length,
      category: CostType.Foundation,
    });

    const fixedAmount = {
      id: amountId({
        unit: AmountUnit.fixed,
        category: CostType.Foundation,
      }),
      unit: AmountUnit.fixed,
      amount: 1,
      category: CostType.Foundation,
    };

    let scourProtectionAmount: Amount[] = [];
    const configuration = await get(getConfiguration(id));
    if (isOperationsCost(configuration.capex.installation.foundations)) {
      const scourProtectionTime =
        turbinesWithFixedFoundations.length > 0
          ? await get(
              getTotalInstallationTime({
                id,
                type: InstallationType.ScourProtection,
              }),
            )
          : 0;
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } =
        operationsConfiguration.ti.foundations.scourProtection;

      const vesselTypes = await get(vesselTypesFamily(undefined));

      const mobilizationTime =
        vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

      scourProtectionAmount.push({
        id: amountId({
          unit: AmountUnit.day,
          category: CostType.Foundation,
          featureTypeId: installationVessel.vesselId,
        }),
        unit: AmountUnit.day,
        amount:
          turbinesWithFixedFoundations.length > 0
            ? (scourProtectionTime ?? 0) + mobilizationTime
            : 0,
        category: CostType.Foundation,
      });
    }

    return [
      ...floatingAmounts,
      ...jacketAmounts,
      ...monopileAmounts,
      ...scourProtectionAmount,
      perMwAmount,
      foundationCount,
      fixedAmount,
    ];
  }),
);
