import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import Edit from "@icons/24/Pencil.svg";
import { HiddenIconWrapper } from "../Design/styles";
import Button from "./Button";
import { Input, TextArea } from "./Input";
import Tooltip from "./Tooltip";
import { Place } from "components/General/Anchor";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { Comp } from "types/utils";

interface EditableTextProps
  extends PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>> {
  onEnter?: () => void;
  onCancel?: undefined | (() => void);
  onEditChange?: React.Dispatch<React.SetStateAction<boolean>>;
  renderText?: (text: string) => ReactNode;
  disabled?: boolean;
  smallInput?: boolean;
  textContainerStyle?: React.CSSProperties;
  editIconTitle?: string;
  tooltipTheme?: "dark" | "light";
  tooltipPosition?: Place;
  enableDoubleClick?: boolean;
  editIconStrokeColor?: string;
  editIconStrokeHoverColor?: string;
  initialEditing?: boolean;
}

const DefaultText = styled.p`
  margin: 0;
`;

export const TextContainer = styled.div`
  padding: 0.2rem 0.8rem 0.2rem 0.4rem;
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 0.8rem;
  overflow: hidden;
  &:hover {
    div {
      opacity: 1;
    }
  }
  word-break: break-word;
`;

const RenderContainer = styled.div`
  margin: 0;
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  &:hover {
    div {
      opacity: 1;
    }
  }
  word-break: break-word;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.2rem;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  justify-content: flex-end;
`;

export const EditableText = ({
  onEnter,
  onCancel,
  onEditChange,
  value,
  smallInput,
  renderText,
  children,
  disabled,
  textContainerStyle,
  tooltipTheme,
  tooltipPosition,
  enableDoubleClick = true,
  initialEditing = false,
  ...props
}: EditableTextProps) => {
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    onEditChange?.(editable);
  }, [editable, onEditChange]);

  useEffect(() => {
    if (initialEditing) {
      setEditable(true);
    }
  }, [initialEditing]);

  return (
    <>
      {editable ? (
        <Input
          smallInput={smallInput}
          type="text"
          value={value}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          {...props}
          onEnter={() => {
            setEditable(false);
            if (onEnter) onEnter();
          }}
          onBlur={() => {
            setEditable(false);
            if (onEnter) onEnter();
          }}
          onCancel={() => {
            setEditable(false);
            if (onCancel) onCancel();
          }}
          autoFocus
        />
      ) : (
        <TextContainer
          style={textContainerStyle}
          onDoubleClick={(e) => {
            if (!enableDoubleClick || disabled) {
              return;
            }

            e.stopPropagation();
            e.preventDefault();
            setEditable(true);
          }}
        >
          {renderText ? (
            renderText(String(value))
          ) : children ? (
            children
          ) : (
            <DefaultText style={{ color: textContainerStyle?.color }}>
              {value}
            </DefaultText>
          )}
          {!disabled && (
            <Tooltip
              text="Double-click to rename"
              theme={tooltipTheme}
              position={tooltipPosition}
            >
              <HiddenIconWrapper
                style={{ display: "flex" }}
                size={1.4}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                iconColor={props.editIconStrokeColor}
                iconHoverColor={props.editIconStrokeHoverColor}
              >
                <Edit />
              </HiddenIconWrapper>
            </Tooltip>
          )}
        </TextContainer>
      )}
    </>
  );
};

interface EditableTextInternalStateProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.PropsWithChildren {
  onEnter?: (newValue: string) => void;
  onAfter?: () => void;
  onEditChange?: (isEditing: boolean) => void;
  onCancel?: (newValue: string) => void;
  renderText?: (text: string) => ReactNode;
  selected?: boolean;
  overlap?: boolean;
  smallInput?: boolean;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  editTextColor?: string;
  editIconColor?: string;
  editIconHoverColor?: string;
  textContainerStyle?: React.CSSProperties;
  enableDoubleClick?: boolean;
  isEditing?: boolean;
  showEditIcon?: boolean;
}

export const EditableTextInternalState = ({
  onEnter,
  onEditChange,
  onAfter,
  onCancel,
  value,
  renderText,
  selected,
  overlap,
  smallInput,
  disabled,
  editTextColor,
  editIconColor,
  editIconHoverColor,
  textContainerStyle,
  inputStyle,
  enableDoubleClick = true,
  showEditIcon = true,
  isEditing = false,
  children,
  ...props
}: EditableTextInternalStateProps) => {
  const stringValue = String(value);
  const [editing, setEditing] = useState(isEditing);
  const [newValue, setNewValue] = useState<string>(stringValue);

  useEffect(() => {
    onEditChange?.(editing);
  }, [editing, onEditChange]);

  useEffect(() => {
    setNewValue(stringValue);
  }, [stringValue]);

  useEffect(() => {
    if (isEditing) {
      setEditing(isEditing);
    }
  }, [isEditing]);

  const cancel = useCallback(() => {
    setEditing(false);
    if (onCancel) {
      onCancel(newValue);
    }
    setNewValue(stringValue);
    onAfter?.();
  }, [setEditing, onCancel, onAfter, setNewValue, stringValue, newValue]);

  const setText = useCallback(() => {
    setEditing(false);
    if (onEnter && newValue !== stringValue) {
      onEnter(newValue);
      setNewValue(stringValue);
    }
    onAfter?.();
  }, [setEditing, onEnter, onAfter, setNewValue, newValue, stringValue]);

  return (
    <>
      {editing ? (
        <Input
          smallInput={smallInput}
          style={{
            ...typography.caption,
            ...inputStyle,
            padding: "0.15rem 0.3rem",

            color: editTextColor ?? colors.textPrimary,
          }}
          type="text"
          value={newValue}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          {...props}
          onCancel={cancel}
          onEnter={setText}
          onBlur={setText}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
          autoFocus
        />
      ) : (
        <TextContainer
          {...props}
          style={textContainerStyle}
          onDoubleClick={(e) => {
            if (!enableDoubleClick || disabled) {
              return;
            }

            e.stopPropagation();
            e.preventDefault();
            setEditing(true);
          }}
        >
          {renderText ? (
            renderText(stringValue)
          ) : children ? (
            children
          ) : (
            <DefaultText
              onClick={(e) => {
                const doubleClick = e.detail % 2 === 0;
                if (doubleClick) {
                  setEditing(true);
                }
              }}
            >
              {value}
            </DefaultText>
          )}
          {!disabled && showEditIcon && (
            <Tooltip text="Double-click to rename">
              <HiddenIconWrapper
                overlap={overlap}
                selected={selected}
                style={{ display: "flex" }}
                size={1.4}
                iconColor={editIconColor}
                iconHoverColor={editIconHoverColor}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Edit />
              </HiddenIconWrapper>
            </Tooltip>
          )}
        </TextContainer>
      )}
    </>
  );
};

type EditableTextAreaInternalStateProps = Comp<
  "textarea",
  {
    onEnter?: (newValue: string) => void;
    onCancel?: (newValue: string) => void;
    renderText?: (text: string) => ReactNode;
    disabled?: boolean;
    editIconColor?: string;
    editIconHoverColor?: string;
    textContainerStyle?: React.CSSProperties;
  }
>;

export const EditableTextAreaInternalState = ({
  onEnter,
  onCancel,
  value,
  renderText,
  disabled,
  editIconColor,
  editIconHoverColor,
  textContainerStyle,
  ...props
}: EditableTextAreaInternalStateProps) => {
  const stringValue = String(value);
  const [editable, setEditable] = useState(false);
  const [newValue, setNewValue] = useState<string>(stringValue);

  useEffect(() => {
    setNewValue(stringValue);
  }, [stringValue]);

  return (
    <>
      {editable ? (
        <Column className="editable-text-area-column">
          <TextArea
            autoFocus
            value={newValue}
            rows={5}
            {...props}
            onChange={(e) => {
              setNewValue(e.target.value);
            }}
          />
          <Row>
            <Button
              buttonType="secondary"
              text="Cancel"
              size="small"
              onClick={() => {
                setEditable(false);
                if (onCancel) {
                  onCancel(newValue);
                }
                setNewValue(stringValue);
              }}
            />
            <Button
              buttonType="primary"
              size="small"
              text="Save"
              onClick={(e) => {
                e.stopPropagation();
                setEditable(false);
                if (onEnter) {
                  onEnter(newValue);
                  setNewValue(stringValue);
                }
              }}
            />
          </Row>
        </Column>
      ) : (
        <RenderContainer>
          <TextContainer style={textContainerStyle}>
            {renderText ? (
              renderText(stringValue)
            ) : (
              <DefaultText
                onClick={(e) => {
                  const doubleClick = e.detail % 2 === 0;
                  if (doubleClick) {
                    setEditable(true);
                  }
                }}
              >
                {value}
              </DefaultText>
            )}
          </TextContainer>
          {!disabled && (
            <HiddenIconWrapper
              onDoubleClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditable(true);
              }}
              iconColor={editIconColor}
              iconHoverColor={editIconHoverColor}
            >
              <Edit />
            </HiddenIconWrapper>
          )}
        </RenderContainer>
      )}
    </>
  );
};
