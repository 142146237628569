import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import "nouislider/dist/nouislider.css";
import { windSpeedFilterAtomFamily } from "../../state/filter";
import { RangeSlider } from "../General/Slider";
import {
  maxWindSpeed,
  maxWindSpeedScalingFactor,
  minWindSpeed,
} from "../../types/filter";
import { windLayerHeightAtom } from "../../state/layer";
import { useAtom } from "jotai";

const WindSpeedFilterSlider = ({ disabled }: { disabled?: boolean }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [windSpeedFilter, setWindSpeedFilter] = useAtom(
    windSpeedFilterAtomFamily({
      projectId,
    }),
  );
  const windLayerHeight = useAtomValue(windLayerHeightAtom);

  return (
    <label>
      <p>Height {`(${windLayerHeight}m)`}</p>
      <RangeSlider
        labels
        renderLabel={(v) => `${v / maxWindSpeedScalingFactor}`}
        min={minWindSpeed}
        max={maxWindSpeed}
        values={windSpeedFilter as [number, number]}
        onChange={(range) =>
          setWindSpeedFilter(
            range.map((v) => (v >= maxWindSpeed ? maxWindSpeed : v)),
          )
        }
        inside
        disabled={disabled}
      />
    </label>
  );
};

export default WindSpeedFilterSlider;
