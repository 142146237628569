import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { projectIdAtom } from "state/pathParams";
import { ProjectTitle, UsageContainerInner } from "../SettingsUsage/common";
import { UsageContainerPlacement } from "../SettingsUsage/style";
import { UsageContainerLeftRightPlacement } from "../SettingsUsage/style";
import { vesselIdUsedInOperationsConfigs } from "state/jotai/operationsConfiguration";

import Button from "components/General/Button";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import { OPERATIONS_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useOperationsConfiguration";
import { ModalType, modalTypeOpenAtom } from "state/modal";

export function VesselUsageCurrentProject({ vesselId }: { vesselId: string }) {
  const projectId = useAtomValue(projectIdAtom);
  const operationsConfigurationsUsingVessel = useAtomValue(
    loadable(vesselIdUsedInOperationsConfigs(vesselId)),
  );
  const setModalType = useSetAtom(modalTypeOpenAtom);

  return (
    <UsageContainerInner
      loadable={operationsConfigurationsUsingVessel}
      text="Used in: "
      typeName="operations configurations"
      placementLeftRight={UsageContainerLeftRightPlacement.RIGHT}
      placement={UsageContainerPlacement.TOP}
    >
      <ExtraInfo
        projectId={projectId}
        setModalType={setModalType}
        usage={(operationsConfigurationsUsingVessel as any).data}
      />
    </UsageContainerInner>
  );
}

function ExtraInfo({
  usage,
  projectId,
  setModalType,
}: {
  usage: { id: string; name: string }[];
  projectId: string | undefined;
  setModalType: (modalType: ModalType) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
        }}
      >
        <ProjectTitle projectId={projectId ?? ""} />
        {usage.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            {usage.map((u) => (
              <Button
                key={u.id}
                text={u.name}
                buttonType="secondary"
                size="small"
                style={{ margin: "0.2rem" }}
                onClick={() => {
                  setModalType({
                    modalType: ProjectConfigModalTypeV2,
                    metadata: {
                      selectedMenuId: OPERATIONS_MENU_ID,
                      selectedConfigId: u.id,
                    },
                  });
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
