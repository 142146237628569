import { useEffect } from "react";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { BufferGeometry, Mesh, WebGLRenderer } from "three";
import { disposeObject } from "../../ViewToPark/utils";
import {
  createOrbitControls,
  createPerspectiveCamera,
  createRenderer,
  createScene,
  createSun,
  INITIAL_SUN_POS,
} from "./utils";
import { useAtom, useAtomValue } from "jotai";
import { threeCoreParkShadowAtom } from "state/viewParkShadow";
import { ThreeCoreBasic } from "components/ViewAnalyses/common";
import { viewTurbineCoordsAtom } from "state/viewToPark";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export type ThreeCoreParkShadow = ThreeCoreBasic & {
  setSunPosition: (elevation: number, azimuth: number) => void;
  controls: OrbitControls;
};

const minWidth = 40;
const minHeight = 40;

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

let _renderer: WebGLRenderer | undefined = undefined;
const getRenderer = () => {
  if (!_renderer) _renderer = createRenderer();
  return _renderer;
};

export default function useCreateThreeCore() {
  const [threeCore, setThreeCore] = useAtom(threeCoreParkShadowAtom);
  const turbineCoords = useAtomValue(viewTurbineCoordsAtom);

  useEffect(() => {
    if (!turbineCoords) return;

    const Xs = turbineCoords.map((coord) => coord[0]);
    const Ys = turbineCoords.map((coord) => coord[1]);
    const minX = Math.min(...Xs);
    const maxX = Math.max(...Xs);
    const minY = Math.min(...Ys);
    const maxY = Math.max(...Ys);

    const width = Math.max(maxX - minX, minWidth);
    const height = Math.max(maxY - minY, minHeight);

    const center: [number, number] = [(minX + maxX) / 2, (minY + maxY) / 2];

    const scene = createScene();
    const camera = createPerspectiveCamera();
    const renderer = getRenderer();
    const maxShadowMapSize = Math.min(
      8192,
      renderer.capabilities.maxTextureSize,
    );
    const { light, bulb, setSunPosition, ambientlight } = createSun(
      width,
      height,
      center,
      maxShadowMapSize,
    );

    const controls = createOrbitControls(camera, renderer);
    setSunPosition(INITIAL_SUN_POS.elevation, INITIAL_SUN_POS.azimuth);

    scene.add(light);

    scene.add(bulb);
    scene.add(ambientlight);

    setThreeCore({
      renderer,
      scene,
      camera,
      setSunPosition,
      controls,
    });

    return () => {
      setThreeCore(undefined);
      scene.remove(light);
      scene.remove(bulb);
      scene.remove(ambientlight);
      controls.dispose();
      disposeObject(light);
      disposeObject(bulb);
      disposeObject(ambientlight);
      disposeObject(scene);
    };
  }, [setThreeCore, turbineCoords]);

  return threeCore;
}
