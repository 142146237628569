import {
  DetailedMonopileType,
  FoundationType,
} from "../../../../../types/foundations";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Grid } from "../../style";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/InputChangelog/style";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { between } from "utils/validations";
import { kWtoMW } from "../../FoundationSettings";
import CommonReferenceNumbers, {
  ReferenceNumbersTitle,
} from "../ReferenceNumbers/CommonReferenceNumbers";
import ReferenceFiftyYearHs from "../ReferenceNumbers/ReferenceFiftyYearHs";
import ReferenceWaterDepth from "../ReferenceNumbers/ReferenceWaterDepth";
import {
  ColumnSection,
  HorizontalLine,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";

interface DetailedMonopileSettingsProps {
  originalFoundation?: DetailedMonopileType;
  tempFoundation: DetailedMonopileType;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  disabled: boolean;
  nodeId: string;
}

const DetailedMonopileSettings = ({
  originalFoundation,
  tempFoundation,
  setTempFoundation,
  disabled,
  nodeId,
}: DetailedMonopileSettingsProps) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const commentCategory =
    nodeId === organisationId ? "org_foundation_manage" : "project";

  return (
    <>
      <ColumnSection>
        <TitleContainer>
          <Subtitle>Geometry</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <Grid>
          <Label>
            <SecondaryText>Pile diameter</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              validate={between(1, 25)}
              validationMessage={`Needs to be within 1 - 25 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "pileDiameter",
                commentCategory,
              )}
              step="1"
              unit="m"
              type="number"
              decimals={1}
              value={tempFoundation.pileDiameter}
              initialValue={
                originalFoundation ? originalFoundation.pileDiameter : undefined
              }
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  pileDiameter: d,
                });
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Average pile thickness</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              validate={between(0.01, 0.5)}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "avgPileThickness",
                commentCategory,
              )}
              step="0.01"
              unit="m"
              type="number"
              decimals={3}
              value={tempFoundation.avgPileThickness}
              initialValue={
                originalFoundation
                  ? originalFoundation.avgPileThickness
                  : undefined
              }
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  avgPileThickness: d,
                });
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Embedded length</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              validate={between(10, 100)}
              validationMessage={`Needs to be within 10 - 100 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "embedLength",
                commentCategory,
              )}
              step="1"
              unit="m"
              type="number"
              decimals={1}
              value={tempFoundation.embedLength}
              initialValue={
                originalFoundation ? originalFoundation.embedLength : undefined
              }
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  embedLength: d,
                });
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Total pile length</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              validate={between(20, 150)}
              validationMessage={`Needs to be within 20 - 150 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "totalPileLength",
                commentCategory,
              )}
              step="1"
              unit="m"
              type="number"
              decimals={1}
              value={tempFoundation.totalPileLength}
              initialValue={
                originalFoundation
                  ? originalFoundation.totalPileLength
                  : undefined
              }
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  totalPileLength: d,
                });
              }}
            />
          </Label>
        </Grid>
      </ColumnSection>
      <ColumnSection>
        <ReferenceNumbersTitle />
        <Grid>
          <CommonReferenceNumbers
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <ReferenceWaterDepth
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <ReferenceFiftyYearHs
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <Label>
            <SecondaryText>Reference sub. reaction coeff.</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              validate={between(1000, 20_000)}
              validationMessage={`Needs to be within 1000 - 20 000 kN/m³`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "soilCoeffSubReact",
                commentCategory,
              )}
              step="100"
              unit="kN/m³"
              type="number"
              decimals={2}
              scaleFactor={kWtoMW}
              value={tempFoundation.soilCoeffSubReact}
              initialValue={
                originalFoundation
                  ? originalFoundation.soilCoeffSubReact
                  : undefined
              }
              onChange={(soilStiffnesskN) => {
                setTempFoundation({
                  ...tempFoundation,
                  soilCoeffSubReact: soilStiffnesskN,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
        </Grid>
      </ColumnSection>
    </>
  );
};

export default DetailedMonopileSettings;
