import { Label, TableWrapper } from "./styles";
import styled from "styled-components";

import { Row as _Row, Column as _Column } from "../../General/Layout";
import { TurbineCosts } from "./Capex/TurbineCosts";
import { CableCosts } from "./Capex/CableCosts";
import { FoundationCosts } from "./Capex/FoundationsCosts";
import { MooringCosts } from "./Capex/MooringCosts";
import { SubstationCosts } from "./Capex/SubstationCosts";
import { ExportCableCosts } from "./Capex/ExportCableCosts";
import { OtherCosts } from "./Capex/OtherCosts";
import { InputNumber } from "components/General/Input";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "./state";
import { between } from "utils/validations";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { CostConfiguration } from "services/costService";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { useAtom } from "jotai";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import FreeTextIcon from "@icons/24/FreeTextIcon.svg";
import ConfidenceLevelIcon from "@icons/24/ConfidenceLevelIcon.svg";
import { SecondaryText } from "components/SettingsV2/Shared/styles";

export const Column = styled(_Column)`
  margin-top: 2rem;
  gap: 0;
`;

export const FirstRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Row = styled(_Row)<{ justify?: string; marginTop?: string }>`
  justify-content: ${(p) => p.justify || "space-between"};
  margin-top: ${(p) => p.marginTop || 0};
  height: 3.2rem;
  align-items: center;
  margin-right: 0.2rem;
`;

export const Capex = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const { updateInflation } = useLocalCostConfigurationCrud();

  const [localConfig, setLocalConfig] = useAtom(localCostConfigurationAtom);
  const { inflation } = localConfig;
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const changelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  return (
    <TableWrapper style={{ width: "100%", overflowX: "hidden" }}>
      <Row>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputNumber
            compact
            style={{ width: "6rem", alignSelf: "center" }}
            value={inflation.referenceYearCapex}
            initialValue={configuration.inflation.referenceYearCapex}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            disabled={isReadOnly}
            changelogInfo={financialChangelogInfo(
              localConfig.id,
              nodeId,
              "inflation.referenceYearCapex",
              changelogCategory,
            )}
            onChange={(year) => {
              const referenceYearCapex = Math.round(year);
              updateInflation({
                referenceYearCapex,
              });
            }}
          />
          <Row style={{ whiteSpace: "nowrap" }}>
            <SecondaryText>Reference year</SecondaryText>
            <HelpTooltip
              size={10}
              text="Numbers in this pane will be inflated / deflated with respect to this year."
            />
          </Row>
        </Label>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Row>
            <DotMenu>
              <MenuItem
                disabled={isReadOnly}
                icon={<ConfidenceLevelIcon />}
                name={
                  useConfidenceLevel
                    ? "Hide confidence level"
                    : "Show confidence level"
                }
                onClick={() => {
                  setLocalConfig({
                    ...localConfig,
                    useConfidenceLevel: !useConfidenceLevel,
                  });
                }}
                tooltip="The confidence levels for cost inputs are intended only for visualization and communication; they do not affect the actual numbers in the analysis"
              />
              <MenuItem
                name={
                  showFreeTextCapex
                    ? "Hide free text column"
                    : "Show free text column"
                }
                icon={<FreeTextIcon />}
                disabled={isReadOnly}
                onClick={() => {
                  setLocalConfig({
                    ...localConfig,
                    showFreeTextCapex: !showFreeTextCapex,
                  });
                }}
              />
            </DotMenu>
          </Row>
        </Label>
      </Row>
      <TurbineCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <CableCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      {configuration.capex.fixed.foundations && (
        <FoundationCosts
          isReadOnly={isReadOnly}
          nodeId={nodeId}
          isLibraryResource={isLibraryResource}
          configuration={configuration}
        />
      )}
      {configuration.capex.fixed.mooring && (
        <MooringCosts
          isReadOnly={isReadOnly}
          nodeId={nodeId}
          isLibraryResource={isLibraryResource}
          configuration={configuration}
        />
      )}
      <SubstationCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <ExportCableCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <OtherCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
    </TableWrapper>
  );
};
