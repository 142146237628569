import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { useCallback, useMemo } from "react";
import Add from "@icons/24/Add.svg?react";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import { ExternalSelectionItem } from "../../state/externalLayerSelection";
import useAddFeaturesIntoElementsWithinLimits from "../../hooks/useAddFeaturesIntoElementsWithinLimits";
import { getCenterOfFeatures } from "../../utils/geometry";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { addExternalDataToProjectHelpHintType } from "../ActiveTips/PositionHints/AddExternalDataToProjectHelp";
import { useProjectElementsFoldersCrud } from "../ProjectElementsV2/useProjectElementsFoldersCrud";
import { Popup } from "components/Mapbox/Popup";
import { _OtherFeature, stripFeatureTypeSpecificFields } from "types/feature";
import { isDefined } from "utils/predicates";
import { sendWarning } from "utils/sentry";
import { useToast } from "hooks/useToast";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import { projectIdAtom } from "state/pathParams";
import { getPinnedPropertiesAsName } from "components/MapControls/CopyPaste/copy";
import { Menu, MenuItem } from "components/General/Menu";

const DynamicSelectOption = ({
  selections,
  addToFolderName,
  callback,
}: {
  selections: ExternalSelectionItem[];
  addToFolderName?: string;
  callback?: () => void;
}) => {
  const map = useAtomValue(mapAtom);
  const projectId = useAtomValue(projectIdAtom);
  const addFeaturesToElementsWithinLimits =
    useAddFeaturesIntoElementsWithinLimits();
  const {
    create: createFolder,
    update: updateFolder,
    items: folderItems,
  } = useProjectElementsFoldersCrud();
  const { warning: showWarning } = useToast();
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId: projectId ?? "",
    }),
  );

  const pos = useMemo(() => {
    const p = getCenterOfFeatures(selections);
    return {
      lng: p[0],
      lat: p[1],
    };
  }, [selections]);

  const onClickDuplicate = useCallback(async () => {
    const parsedFeatures = selections
      .map((selection) => {
        const name = getPinnedPropertiesAsName(allLayerSettings, selection);
        const paddName = name ? { name } : {};
        const parsed = _OtherFeature.safeParse({
          ...selection,
          properties: {
            ...selection.properties,
            parentIds: undefined,
            ...paddName,
          },
          id: String(selection.id),
        });
        return parsed.success ? parsed.data : undefined;
      })
      .filter(isDefined)
      .map((feature) => {
        return {
          ...feature,
          properties: stripFeatureTypeSpecificFields(feature),
        };
      });

    if (parsedFeatures.length !== selections.length) {
      showWarning(
        `Failed to parse ${selections.length - parsedFeatures.length} of ${selections.length} features from layer.`,
        {
          showCountdown: false,
          timeout: 5000,
        },
      );
      sendWarning(
        "Could not parse feature when trying to add feature to project from layer",
        {
          layerIdMaybe: selections[0]?.layer?.id,
          layerSourceMaybe: selections[0]?.source,
        },
      );

      if (parsedFeatures.length === 0) {
        return;
      }
    }

    const createdFeatures = await addFeaturesToElementsWithinLimits({
      add: parsedFeatures,
      successMessage: "Added to Elements",
      selectAfterCloning: true,
    });

    if (addToFolderName) {
      const createdFeatureIds = createdFeatures.map((feature) => feature.id);

      const existingFolder = folderItems.find(
        (folder) => folder.folderName === addToFolderName,
      );
      if (existingFolder) {
        updateFolder({
          ...existingFolder,
          featureIds: existingFolder.featureIds.concat(
            createdFeatureIds.map((id) => ({
              type: "feature",
              id,
            })),
          ),
        });
      } else {
        createFolder({
          featureIds: createdFeatures.map((feature) => ({
            type: "feature",
            id: feature.id,
          })),
          folderName: addToFolderName,
        });
      }
    }

    if (callback) callback();
  }, [
    selections,
    addFeaturesToElementsWithinLimits,
    addToFolderName,
    callback,
    showWarning,
    folderItems,
    updateFolder,
    createFolder,
    allLayerSettings,
  ]);

  if (!canEdit || !map) {
    return null;
  }

  return (
    <Popup map={map} pos={pos} offsetPx={[0, -100]}>
      <PositionHint
        allowedHints={[addExternalDataToProjectHelpHintType]}
        position={"top"}
      >
        <Menu>
          <MenuItem
            icon={<Add />}
            name="Add to Elements"
            onClick={onClickDuplicate}
          />
        </Menu>
      </PositionHint>
    </Popup>
  );
};

export default DynamicSelectOption;
