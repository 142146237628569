import { memberInOrganisationSelectorFamily } from "state/user";
import { useSearchAppObjects } from "./useSearchAppObjects";
import { useSearchGroups } from "./useSearchGroups";
import { useSearchIntercom } from "./useSearchIntercom";
import { useSearchLibraryAnalysis } from "./useSearchLibraryAnalysis";
import { useSearchLibraryCables } from "./useSearchLibraryCables";
import { useSearchLibraryDataPackage } from "./useSearchLibraryDataPackage";
import { useSearchLibraryExportCables } from "./useSearchLibraryExportCables";
import { useSearchLibraryFinancials } from "./useSearchLibraryFinancials";
import { useSearchLibraryFoundations } from "./useSearchLibraryFoundations";
import { useSearchLibrarySubstations } from "./useSearchLibrarySubstations";
import { useSearchLibraryTurbines } from "./useSearchLibraryTurbines";
import { useSearchMembers } from "./useSearchMembers";
import { useSearchProjects } from "./useSearchProjects";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

export function useSearchDataOrganisation({
  organisationId,
  search,
}: {
  organisationId: string;
  search: string;
}) {
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId: organisationId,
    }),
  );

  const { filteredAppObjects, searchableItems: appObjectItems } =
    useSearchAppObjects(true, search);

  const { filteredMembers, searchableItems: memberItems } = useSearchMembers(
    isMemberInOrg,
    organisationId,
    search,
  );
  const { filteredGroups, searchableItems: groupItems } = useSearchGroups(
    isMemberInOrg,
    organisationId,
    search,
  );
  const { filteredNodes, searchableItems: nodeItems } = useSearchProjects(
    organisationId,
    search,
  );
  const { filteredTurbines, searchableItems: turbineItems } =
    useSearchLibraryTurbines(organisationId, search);
  const { filteredFoundations, searchableItems: foundationItems } =
    useSearchLibraryFoundations(organisationId, search);
  const { filteredCables, searchableItems: cableItems } =
    useSearchLibraryCables(organisationId, search);
  const { filteredExportCables, searchableItems: exportCableItems } =
    useSearchLibraryExportCables(organisationId, search);
  const { filteredSubstations, searchableItems: substationItems } =
    useSearchLibrarySubstations(organisationId, search);
  const { filteredAnalysis, searchableItems: analysisItems } =
    useSearchLibraryAnalysis(organisationId, search);
  const { filteredFinancials, searchableItems: financialItems } =
    useSearchLibraryFinancials(organisationId, search);
  const { filteredDataPackages, searchableItems: dataPackageItems } =
    useSearchLibraryDataPackage(organisationId, search);

  const { articles, searchIntercomInProgress } = useSearchIntercom(search);

  const searchableItems = useMemo(() => {
    return [
      ...(memberItems ?? []),
      ...(groupItems ?? []),
      ...(nodeItems ?? []),
      ...(turbineItems ?? []),
      ...(foundationItems ?? []),
      ...(cableItems ?? []),
      ...(exportCableItems ?? []),
      ...(substationItems ?? []),
      ...(analysisItems ?? []),
      ...(financialItems ?? []),
      ...(dataPackageItems ?? []),
      ...(appObjectItems ?? []),
    ];
  }, [
    memberItems,
    groupItems,
    nodeItems,
    turbineItems,
    foundationItems,
    cableItems,
    exportCableItems,
    substationItems,
    analysisItems,
    financialItems,
    dataPackageItems,
    appObjectItems,
  ]);

  const searchGroups = useMemo(() => {
    const groupsConfig = [
      { title: "Members", items: filteredMembers },
      { title: "Groups", items: filteredGroups },
      { title: "Projects and folders", items: filteredNodes },
      { title: "Actions", items: filteredAppObjects },
      { title: "Turbines", items: filteredTurbines },
      { title: "Foundations", items: filteredFoundations },
      { title: "Cables", items: filteredCables },
      { title: "Export Cables", items: filteredExportCables },
      { title: "Substations", items: filteredSubstations },
      { title: "Analysis", items: filteredAnalysis },
      { title: "Financials", items: filteredFinancials },
      { title: "Data Packages", items: filteredDataPackages },
      {
        title: "Read more",
        items: articles,
        loading: searchIntercomInProgress,
      },
    ];

    const groups = groupsConfig
      .filter((group) => group.items.length > 0)
      .map(({ title, items, loading }) => {
        // Find min score (best match)
        let minScore = items[0].score;
        for (let i = 1; i < items.length; i++) {
          if (items[i].score < minScore) {
            minScore = items[i].score;
          }
        }

        return {
          title,
          items: items.sort((a, b) => a.score - b.score),
          bestScore: minScore,
          loading,
        };
      })
      .sort((a, b) => a.bestScore - b.bestScore);

    return groups;
  }, [
    filteredMembers,
    filteredGroups,
    filteredNodes,
    filteredAppObjects,
    filteredTurbines,
    filteredFoundations,
    filteredCables,
    filteredExportCables,
    filteredSubstations,
    filteredAnalysis,
    filteredFinancials,
    filteredDataPackages,
    articles,
    searchIntercomInProgress,
  ]);

  return {
    searchGroups,
    searchableItems,
    someItemsAreLoading: searchIntercomInProgress,
  };
}
