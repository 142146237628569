import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { substationsInParkFamily } from "state/jotai/substation";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { ValidationWarning } from "state/validationWarnings";

/** Substation is not connected to an export cable */
export const substationNotConnectedWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubstationNotConnected>
        | undefined
      >
    >(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      if (exportCables.length === 0) return;

      const touchedIds = new Set(
        exportCables.flatMap((c) => [
          c.properties.fromSubstationId,
          c.properties.toSubstationId,
        ]),
      );

      const disconnected = substations.filter((sub) => !touchedIds.has(sub.id));

      if (disconnected.length === 0) return;
      return {
        type: ValidationWarningTypes.SubstationNotConnected,
        featureIds: disconnected.map((s) => s.id),
        parkId: parkId,
      };
    }),
);
