import mapboxgl, { Map } from "mapbox-gl";

export function safeRemoveLayer(map: Map, layerId: string) {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
}

export const getRotationAndTiltIndependentViewportBounds = (
  map: mapboxgl.Map,
) => {
  const center = map.getCenter();
  const canvas = map.getCanvas();

  const screenRatio = window.devicePixelRatio;
  const viewportWidth = canvas.width / screenRatio;
  const viewportHeight = canvas.height / screenRatio;

  const lnglat = map.unproject([viewportWidth / 2, viewportHeight / 2]);
  const onePxDiff = map.unproject([viewportWidth / 2 + 1, viewportHeight / 2]); // 1 px diff in x from point above
  const metersPerPixel = lnglat.distanceTo(onePxDiff);

  const distanceX = (viewportWidth / 2) * metersPerPixel;
  const distanceY = (viewportHeight / 2) * metersPerPixel;

  // Use more precise conversion factors that vary with latitude
  const metersPerDegreeLat =
    111132.92 -
    559.82 * Math.cos((2 * center.lat * Math.PI) / 180) +
    1.175 * Math.cos((4 * center.lat * Math.PI) / 180);
  const metersPerDegreeLng =
    111412.84 * Math.cos((center.lat * Math.PI) / 180) -
    93.5 * Math.cos((3 * center.lat * Math.PI) / 180);

  const latOffset = distanceY / metersPerDegreeLat;
  const lngOffset = distanceX / metersPerDegreeLng;

  const xMin = center.lng - lngOffset;
  const xMax = center.lng + lngOffset;
  const yMin = center.lat - latOffset;
  const yMax = center.lat + latOffset;

  if (xMin < -180 || xMax > 180 || yMin < -90 || yMax > 90) {
    return undefined;
  }

  return new mapboxgl.LngLatBounds([
    [xMin, yMin],
    [xMax, yMax],
  ]);
};
