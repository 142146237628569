import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { z } from "zod";
import useOrgSubstationCrud from "components/Organisation/Library/substation/useOrgSubstationCrud";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { _SubstationType } from "services/substationService";
import { ABLY_SUBSTATION_TYPE_DELETE } from "state/ably";
import { ABLY_SUBSTATION_TYPE_UPDATE } from "state/ably";

export function useAblyLibrarySubstation(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgSubstationCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_substation_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const substation = _SubstationType.parse(message.data.meta);
      updateLocal(substation);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const substationId = z.string().parse(message.data.meta);
      removeLocal(substationId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SUBSTATION_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_SUBSTATION_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
