import { atom } from "jotai";
import {
  WindSourceConfiguration,
  _WindSourceConfiguration,
  listWindSourceConfigurations,
} from "services/windSourceConfigurationService";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { branchMetaFamily } from "./branch";
import { z } from "zod";

export const projectWindConfigurationsFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, WindSourceConfiguration>>>(async () => {
    const response = await listWindSourceConfigurations(nodeId);
    if (!response.ok) throw new Error("Fetching configurations failed");
    const j = await response.json();
    const parser = z.object({
      configurations: _WindSourceConfiguration.array(),
    });
    const configs = parser.parse(j);
    return new Map(configs.configurations.map((c) => [c.id, c]));
  }),
);

export const projectWindConfigurationsByNameFamily = atomFamily(
  (nodeId: string) =>
    atom<Promise<WindSourceConfiguration[]>>(async (get) => {
      const m = await get(projectWindConfigurationsFamily(nodeId));
      return [...m.values()].sort((a, b) =>
        (a.name ?? "").localeCompare(b.name ?? ""),
      );
    }),
);

// NOTE: this looks dumb now, but it's for when we add wind config to library too.
export const windConfigurationsFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atomFromFn<Promise<Map<string, WindSourceConfiguration>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("windConfigurationsFamily requires projectIdAtom");
      const proj = await get(projectWindConfigurationsFamily(projectId));
      return proj;
    }),
);

export const windConfigurationSelectedFamily = atomFamily(
  (input: { branchId: string | undefined; projectId: string }) =>
    atom(async (get) => {
      const { projectId } = input;
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId)
        throw new Error(
          "windConfigurationSelectedFamily requires branchIdAtom",
        );
      const [branch, configs] = await Promise.all([
        get(branchMetaFamily({ branchId, projectId })),
        get(windConfigurationsFamily({ projectId })),
      ]);
      return configs.get(branch?.windConfigurationId ?? "");
    }),
);
