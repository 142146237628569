import { Column, Row } from "components/General/Layout";
import { ReactNode } from "react";
import { EditableText } from "components/General/EditableText";
import ComponentLastChanged from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import { ChangelogCategory } from "components/InputChangelog/types";
import { colors } from "styles/colors";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";

export const LibraryResourceHeader = ({
  resourceType,
  description,
  name,
  onUpdate,
  onSave,
  onCancel,
  changelogData,
  disabled,
  organisationId,
  children,
}: {
  resourceType: string;
  description: string;
  name: string;
  organisationId: string;
  onUpdate: (updatedResource: { name?: string; description?: string }) => void;
  onSave: (updatedResource: { name?: string; description?: string }) => void;
  onCancel: () => void;
  changelogData?: {
    id: string;
    category: ChangelogCategory;
  };
  disabled?: boolean;
  children?: ReactNode;
}) => {
  return (
    <>
      <Row
        style={{
          gap: "2.4rem",
          padding: "2rem 3.2rem",
          alignItems: "center",
          backgroundColor: colors.surfaceSubtle,
          borderRadius: "0.8rem",
          border: `1px solid ${colors.borderSubtle}`,
        }}
      >
        <Column
          style={{
            width: "100%",
          }}
        >
          <EditableText
            type="text"
            smallInput={true}
            value={name}
            onChange={(e) => {
              onUpdate({ name: e.target.value });
            }}
            onEnter={() => {
              onSave({ name: name });
            }}
            onCancel={() => {
              onCancel();
            }}
            textContainerStyle={{
              maxWidth: "20vw",
              padding: 0,
            }}
            renderText={(text) => (
              <h3
                style={{
                  margin: 0,
                }}
              >
                {text}
              </h3>
            )}
            disabled={!!disabled}
          />

          <Column
            style={{
              alignItems: "start",
            }}
          >
            {changelogData ? (
              <ComponentLastChanged
                changelogId={changelogData.id}
                nodeId={organisationId}
                category={changelogData.category}
              />
            ) : (
              <div />
            )}
            <Row>{children}</Row>
          </Column>
        </Column>

        <DescriptionModal
          defaultValue={description ?? ""}
          updateDescription={(newDescription: string) => {
            onSave({ description: newDescription });
          }}
          subtitle={
            <div>
              <p>
                {`The description will be visible for Admins and Editors in
                  projects with access to this ${resourceType}.`}
              </p>
            </div>
          }
        />
      </Row>
    </>
  );
};
