import { useEffect } from "react";
import {
  activeTourState,
  activeTourStepState,
  lastEventState,
  stepOrderIndexMapState,
} from "./state";
import * as Tours from "./tours.json";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { useParams, useSearchParams } from "react-router-dom";
import { customerProjectAtomFamily } from "state/timeline";
import { usePutUserHintSettings } from "components/ActiveTips/CloseableHints/hooks";
import { userHintSettingsAtom } from "components/ActiveTips/CloseableHints/state";
import { Mixpanel } from "mixpanel";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { TourStep } from "./TourStep";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { featuresListAtom } from "state/jotai/features";

export const TUTORIAL_PROJECT_SEARCH_PARAM = "tutorialProject";
export const SANDBOX_TUTORIAL_ID = "2g5pfzNjvnfGFWkSxEudrHcBqQP";
export const ONSHORE_SANDBOX_TUTORIAL_ID = "2me3haTlwxqtWzfu5LJ5pPwmNmD";

const useOnboardingTourInitializer = () => {
  const activeTour = useAtomValue(activeTourState);
  const [activeStep, setActiveStep] = useAtom(activeTourStepState);
  const [lastEvent, setLastEvent] = useAtom(lastEventState);
  const setStepOrderIndexMap = useSetAtom(stepOrderIndexMapState);
  const setActiveTour = useSetAtom(activeTourState);

  useEffect(() => {
    if (!activeTour) return;

    const firstStep = activeTour.steps.find((s) => s.step === 0);
    if (firstStep) {
      setLastEvent(null); // reset state
      setActiveStep(firstStep.id);
      Mixpanel.track_old("Started tour", {
        tour: activeTour.tour_id,
        step: firstStep.id,
      });
    }
  }, [setActiveStep, activeTour, setLastEvent]);

  useEffect(() => {
    if (!activeTour) return;

    const steps = activeTour.steps;
    const map = new Map();
    let currentStepId = steps.find((step) => step.step === 0)?.id;
    let index = 0;

    while (currentStepId) {
      map.set(currentStepId, index);
      const nextStepId = steps.find(
        (step) => step.id === currentStepId,
      )?.nextStep;
      currentStepId = nextStepId;
      index++;
    }

    setStepOrderIndexMap(map);
  }, [activeTour, setStepOrderIndexMap]);

  useEffect(() => {
    if (!activeTour) return;

    const step = activeTour.steps.find((s) => s.id === activeStep);
    if (!step || !step.progressEvent) return;

    if (step.progressEvent === lastEvent) {
      setLastEvent(null); // reset state
      if (step.nextStep) {
        setActiveStep(step.nextStep);
        Mixpanel.track_old("Onboarding tour step", {
          step: step.id,
          tour: activeTour.tour_id,
          completed: false,
        });
      } else {
        // End of tour
        setActiveTour(undefined);
        Mixpanel.track_old("Onboarding tour step", {
          step: step.id,
          tour: activeTour.tour_id,
          completed: true,
        });
      }
    }
  }, [
    activeTour,
    activeStep,
    lastEvent,
    setLastEvent,
    setActiveStep,
    setActiveTour,
  ]);

  // Close modals on certain actions
  const [_, setLayoutControlActive] = useDrawMode();
  useEffect(() => {
    if (!activeTour) return;

    if (lastEvent === "optionSelected") setLayoutControlActive(undefined);
    if (lastEvent === "clickedApply") setLayoutControlActive(undefined);
  }, [lastEvent, setLayoutControlActive, activeTour]);
};

export const DEFAULT_ONBOARDING_TOUR = Tours.tours.find((t) => t.default);
export const ONSHORE_DEFAULT_ONBOARDING_TOUR = Tours.tours.find(
  (t) => t.tour_id === "onshore-intro-tour",
);
const SetTutorialProjectInURLInner = ({ projectId }: { projectId: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setActiveTour = useSetAtom(activeTourState);
  const putUserHintSettings = usePutUserHintSettings();
  const userSettings = useAtomValue(userHintSettingsAtom);
  const projectData = useAtomValue(featuresListAtom);
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  useEffect(() => {
    if (!project || projectData.length === 0) return;
    const { tutorial_id } = project;
    if (
      !tutorial_id ||
      ![SANDBOX_TUTORIAL_ID, ONSHORE_SANDBOX_TUTORIAL_ID].includes(tutorial_id)
    ) {
      searchParams.delete(TUTORIAL_PROJECT_SEARCH_PARAM);
      setSearchParams(searchParams);
      setActiveTour(undefined);
    }
    if (tutorial_id === SANDBOX_TUTORIAL_ID) {
      const activeTour = Tours.tours.find((t) => t.default);
      if (!activeTour) return;

      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(projectId)
      ) {
        setActiveTour(activeTour);
      }

      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(projectId)
      ) {
        const updatedSettings = [
          ...(userSettings.startedTours ?? []),
          projectId,
        ];
        putUserHintSettings({
          startedTours: [...updatedSettings],
        });
      }

      if (!searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM)) {
        searchParams.append(TUTORIAL_PROJECT_SEARCH_PARAM, "true");
        setSearchParams(searchParams);
      }
    }
    if (tutorial_id === ONSHORE_SANDBOX_TUTORIAL_ID) {
      const activeTour = Tours.tours.find(
        (t) => t.tour_id === "onshore-intro-tour",
      );
      if (!activeTour) return;
      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(projectId)
      ) {
        setActiveTour(activeTour);
      }

      if (
        !userSettings.startedTours ||
        !userSettings.startedTours.includes(projectId)
      ) {
        const updatedSettings = [
          ...(userSettings.startedTours ?? []),
          projectId,
        ];
        putUserHintSettings({
          startedTours: [...updatedSettings],
        });
      }

      if (!searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM)) {
        searchParams.append(TUTORIAL_PROJECT_SEARCH_PARAM, "true");
        setSearchParams(searchParams);
      }
    }
  }, [
    project,
    projectId,
    searchParams,
    setActiveTour,
    projectData,
    setSearchParams,
    putUserHintSettings,
    userSettings.startedTours,
  ]);
  return null;
};

const SetTutorialProjectInURL = () => {
  const { projectId } = useParams();
  if (!projectId) return null;
  return <SetTutorialProjectInURLInner projectId={projectId} />;
};

export function TutorialProjectOnboarding() {
  const tourId = "general-intro-tour";
  useOnboardingTourInitializer();

  const setLowerRightActiveMode = useSetAtom(lowerRightMenuActiveModeAtom);

  return (
    <>
      <TourStep
        tourId={tourId}
        stepId="welcome"
        onEnterStepAction={() => setLowerRightActiveMode(undefined)}
      />
      <SetTutorialProjectInURL />
    </>
  );
}

export function OnshoreTutorialProjectOnboarding() {
  const tourId = "onshore-intro-tour";
  useOnboardingTourInitializer();

  const setLowerRightActiveMode = useSetAtom(lowerRightMenuActiveModeAtom);

  return (
    <>
      <TourStep
        tourId={tourId}
        stepId="welcome"
        onEnterStepAction={() => {
          setLowerRightActiveMode(undefined);
        }}
      />
      <SetTutorialProjectInURL />
    </>
  );
}
