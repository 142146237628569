import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { spacing8, spacing9 } from "styles/space";

export const TRANSITION_MS = 500;

export type SliderAnimation = "right" | "left" | undefined;

const getBorderRadius = (imagePosition: "right" | "left" | "center") => {
  if (imagePosition === "right") {
    return "3.2rem 0 0 3.2rem";
  } else if (imagePosition === "left") {
    return "0 3.2rem 3.2rem 0";
  } else {
    return "3.2rem";
  }
};

export const ImageContainer = styled.div<{
  imagePosition: "right" | "left" | "center";
}>`
  display: flex;
  width: 100%;

  img,
  video {
    border-radius: ${({ imagePosition }) => getBorderRadius(imagePosition)};
  }

  video {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  ${({ imagePosition }) => {
    if (imagePosition === "right") {
      return `
          justify-content: flex-end;
        `;
    } else if (imagePosition === "left") {
      return `
          justify-content: flex-start;
        `;
    } else {
      return `
          justify-content: center;
          `;
    }
  }};
`;

export const SliderRight = styled.div<{
  imagePosition: "right" | "left" | "center";
  animation: SliderAnimation;
}>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;

  ${ImageContainer} {
    transition:
      opacity ${TRANSITION_MS}ms cubic-bezier(0, 0, 0.7, 1),
      transform ${TRANSITION_MS}ms cubic-bezier(0, 0, 0.5, 1);

    ${({ animation }) => {
      if (animation === "right") {
        return `
          transform: translateX(-10%);
          opacity: 0;
        `;
      } else if (animation === "left") {
        return `
          transform: translateX(10%);
          opacity: 0;
        `;
      }
    }};
  }
`;

export const SlideShowWrapper = styled.div<{
  bgColor: string;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${SliderRight} {
    background-color: ${({ bgColor }) => bgColor};
  }
`;

const SLIDER_LEFT_WIDTH = "50rem";

export const SliderLeft = styled.div`
  background-color: ${colors.surfacePrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SLIDER_LEFT_WIDTH};
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  //box-shadow: 10px 2px 24px 0px rgba(0, 0, 0, 0.21);
`;

export const SliderLeftInner = styled.div<{ animation: SliderAnimation }>`
  display: flex;
  flex-direction: column;
  width: 46rem;
  height: 100%;
  box-sizing: border-box;
  max-height: 600px;
  transition: opacity ${TRANSITION_MS}ms cubic-bezier(0, 0, 0.7, 1);
  ${({ animation }) => animation && `opacity: 0;`};
`;

export const TopHeaderAccent = styled.h1`
  ${typography.h1};
  font-weight: bold;
  color: ${colors.indigo600};
  margin: 0;
`;

export const ContentText = styled.p`
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0;

  // Sibling ContentText should have a margin-top
  & + & {
    margin-top: ${spacing9};
  }
'`;

export const SubHeader = styled.p`
  font-size: 4rem;
  line-height: 4.4rem;
  color: ${colors.textBrand};
  font-weight: bold;
  margin: 0;

  + ${ContentText} {
    margin-top: ${spacing9};
  }
`;

const PAGINATION_PADDING = "2rem";
export const PaginationWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  width: ${SLIDER_LEFT_WIDTH};
  padding: ${PAGINATION_PADDING};
  box-sizing: border-box;
  padding: ${spacing8};
  z-index: 1;
`;
