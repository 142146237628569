import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { estimatePowerRating } from "components/Cabling/CableWalk";
import { cableLoadsFamily, cablesInParkFamily } from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";

export const interArrayCapacityWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.InterArrayInsufficientCapacity>
        | undefined
      >
    >(async (get) => {
      const iaLoads = await get(
        cableLoadsFamily({ parkId, branchId, turbineTypeOverride: undefined }),
      );

      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));

      const overloaded = [];
      for (const c of cables) {
        const load = iaLoads.get(c.id);
        if (load === undefined) continue;
        const type = cableTypes.get(c.properties.cableTypeId ?? "");
        if (!type || !type.ampacity) continue;
        const capacity = estimatePowerRating(type);
        if (capacity < load) overloaded.push({ id: c.id, load, capacity });
      }

      if (overloaded.length === 0) return undefined;

      return {
        type: ValidationWarningTypes.InterArrayInsufficientCapacity,
        featureIds: overloaded.flatMap(({ id }) => id),
        parkId,
      };
    }),
);
