import { ConfidenceLevel, CostWithUnit, _CostUnit } from "types/financial";
import { ShippingCost } from "services/procurementCostService";
import { Position } from "geojson";
import * as turf from "@turf/turf";

enum Regions {
  EUROPE = "Europe",
  USA = "USA",
  ASIA = "Asia",
} // Asia is covering a larger area, which is why we add more points

const CENTRAL_POINTS = [
  {
    region: Regions.EUROPE,
    point: [15, 51],
  },
  {
    region: Regions.USA,
    point: [-100, 40],
  },
  {
    region: Regions.ASIA,
    point: [71, 29],
  },
  {
    region: Regions.ASIA,
    point: [122, 9.5],
  },
  {
    region: Regions.ASIA,
    point: [123, 53],
  },
];

const findRegionForPark = (parkCenter: Position): Regions | "Other" => {
  let closestRegion: Regions | "Other" = "Other";
  let minDistance = Infinity;

  CENTRAL_POINTS.forEach(({ region, point }) => {
    const distance = turf.distance(
      turf.point([parkCenter[0], parkCenter[1]]),
      turf.point([point[0], point[1]]),
      { units: "kilometers" },
    );
    if (distance < minDistance) {
      minDistance = distance;
      closestRegion = region;
    }
  });

  // If the closest region is more than 3000 km away, return "Other"
  return minDistance > 3000 ? "Other" : closestRegion;
};

export const pickLocation = (
  shippingCost: ShippingCost[],
  parkCenter: Position,
): CostWithUnit => {
  const region = findRegionForPark(parkCenter);

  const cost = shippingCost.find((t) => t.region === region);

  if (!cost)
    throw new Error("No shipping cost found for park, including 'Other'");

  return {
    cost: cost.cost,
    unit: _CostUnit.parse(cost.unit),
    confidenceLevel: ConfidenceLevel.notSpecified,
  };
};
