import OpenRight from "@icons/24/OpenWindowRight.svg";
import * as turf from "@turf/turf";
import {
  InputTitle,
  InputTitleWrapper,
  OverlineText,
  OverlineTextWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { orLoader } from "components/Loading/Skeleton";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable, unwrap } from "jotai/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { exclusionZonesFamily } from "state/jotai/exclusionZone";
import { foundationTypesAtom } from "state/jotai/foundation";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { mooringLineTypesAtom } from "state/jotai/mooringLineType";
import { currentParkAtom } from "state/jotai/park";
import { selectedTurbineTypesAtom } from "state/jotai/selection";
import { mostProbableWindDirectionFamily } from "state/jotai/windStatistics";
import { colors } from "styles/colors";
import { spaceLarge, spaceMedium } from "styles/space";
import { typography } from "styles/typography";
import { throttle } from "throttle-debounce";
import { getParkCenter } from "utils/parkUtils";
import { v4 as uuidv4 } from "uuid";
import { MOORING_LINE_PROPERTY_TYPE } from "../../constants/projectMapView";
import { estimateLineLengthFromParams } from "../../functions/mooring";
import { useClickOutside } from "../../hooks/useClickOutside";
import {
  ANCHOR_RADIUS_MAX_DEPTH,
  ANCHOR_RADIUS_MAX_KM,
  ANCHOR_RADIUS_MIN_DEPTH,
  ANCHOR_RADIUS_MIN_KM,
  MOORING_LINE_LENGTH_MIN_KM,
  TARGET_PRETENSION,
} from "../../services/mooringLineTypeService";
import {
  _MooringLineFeature,
  AnchorFeature,
  exclusionDomainUnpack,
  MooringLineFeature,
  ParkFeature,
  TurbineFeature,
} from "../../types/feature";
import { Raster } from "../../types/raster";
import { degreeNormalize, pointInPolygon } from "../../utils/geometry";
import { featureIsLocked, isDefined, isFloater } from "../../utils/predicates";
import { sendInfo } from "../../utils/sentry";
import {
  isNever,
  range,
  roundToDecimal,
  sum,
  undefMap,
} from "../../utils/utils";
import { ControlRow } from "../Cabling/Generate/GenerateCables.style";
import Dropdown from "../Dropdown/Dropdown";
import { Anchor } from "../General/Anchor";
import Button from "../General/Button";
import { Grid2, Label } from "../General/Form";
import { IconBtn } from "../General/Icons";
import { InputDimensioned } from "../General/Input";
import { Column, Row } from "../General/Layout";
import Radio, { RadioGroup } from "../General/Radio";
import { RangeWithDimInput } from "../General/RangeWithDimInput";
import Toggle, { ToggleSize } from "../General/Toggle";
import Tooltip from "../General/Tooltip";
import HelpTooltip, { ARTICLE_MOORING_GEN } from "../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useGetTurbineDepths } from "../RightSide/InfoModal/FoundationModal/fixed/hooks";
import { AnchorEditor } from "./AnchorEditor";
import { makeAnchors, mooringIntersectsPolygon } from "./generate";
import { Mode } from "./GenerateFoundationAnchorMenu";
import {
  getMooringParametersAtomFamily,
  previewMooringAndFoundationState,
} from "./state";
import { distanceUnits, MooringDistance, MooringParameters } from "./types";
import usePrevious from "hooks/usePrevious";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

type Setter<T> = (
  valOrUpdater: ((currVal: T | Promise<T>) => Promise<T> | T) | T,
) => void;

const DoubleAnchors = ({
  currentFoundationId,
  setOpenDoubleAnchorMenu,
  setAndGenerate,
}: {
  currentFoundationId: string;
  maxDepth: number;
  setOpenDoubleAnchorMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setAndGenerate: Setter<MooringParameters>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    () => {
      setOpenDoubleAnchorMenu(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) return false;
      return target.id === "double-anchors-submenu-open";
    },
  );
  const params = useAtomValue(
    getMooringParametersAtomFamily({
      foundationId: currentFoundationId,
    }),
  );

  return (
    <MenuFrame
      title="Doubling angle"
      onExit={() => setOpenDoubleAnchorMenu(false)}
      id="mooring-submenu-double-anchors"
      ref={ref}
      style={{
        overflow: "visible",
      }}
    >
      <Label>
        <p>Doubling angle</p>
        <RangeWithDimInput
          min={1}
          max={45}
          rangeStep={1}
          value={params.doublingAngle}
          unit={"degrees"}
          units={["degrees"]}
          onChange={(a) => {
            setAndGenerate(async (curr) => {
              const c = await curr;
              return { ...c, doublingAngle: a };
            });
          }}
        />
      </Label>
    </MenuFrame>
  );
};

const MooringAngles = ({
  setOpenMooringAngleMenu,
  setAndGenerate,
  currentFoundationId,
}: {
  setOpenMooringAngleMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setAndGenerate: Setter<MooringParameters>;
  currentFoundationId: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    () => {
      setOpenMooringAngleMenu(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) return false;
      return target.id === "mooring-angles-submenu-open";
    },
    { ignoreDragClicks: true },
  );
  const params = useAtomValue(
    getMooringParametersAtomFamily({
      foundationId: currentFoundationId,
    }),
  );

  const park = useAtomValue(currentParkAtom);
  const center = undefMap(park, (p) => getParkCenter(p, [])) ?? [0, 0];
  const turbineTypes = useAtomValue(selectedTurbineTypesAtom);
  const averageHubHeight =
    turbineTypes.length === 0
      ? 150
      : sum(turbineTypes, (t) => t.hubHeight) / turbineTypes.length;

  const mwd = useAtomValue(
    loadable(
      mostProbableWindDirectionFamily({
        lon: center[0],
        lat: center[1],
        height: averageHubHeight,
        fromYear: undefined,
        toYear: undefined,
      }),
    ),
  );

  return (
    <MenuFrame
      title="Set mooring angles"
      id="mooring-submenu-mooring-angles"
      ref={ref}
      style={{
        overflow: "visible",
      }}
    >
      <div>
        <p style={{ paddingBottom: "2rem" }}>
          Drag each anchor along the circle to adjust the angle or input
          manually.
        </p>
        <AnchorEditor
          numAngles={params.numberOfAnchors}
          initial={
            params.angles ??
            range(0, params.numberOfAnchors).map((i) =>
              degreeNormalize(
                params.bearing + (i * 360) / params.numberOfAnchors,
              ),
            )
          }
          onAngleChange={(angles) => {
            setAndGenerate(async (curr) => {
              const c = await curr;
              return { ...c, angles };
            });
          }}
        />
        <Row style={{ ...typography.label, alignItems: "center" }}>
          <span>Most probable wind direction: </span>
          {orLoader(
            mwd,
            (p) => (
              <span>{p}°</span>
            ),
            { height: "1.4rem", width: "3rem" },
          )}
        </Row>
      </div>
    </MenuFrame>
  );
};

const AnchorSettings = ({
  currentFoundationId,
  maxDepth,
  params,
  setAndGenerate,
  convertBetweenKmAndDepth,
}: {
  currentFoundationId: string;
  maxDepth: number;
  params: MooringParameters;
  setAndGenerate: Setter<MooringParameters>;
  convertBetweenKmAndDepth: (
    params: MooringParameters,
    unit: MooringDistance,
  ) => void;
}) => {
  const doubleAnchorMenuRef = useRef<HTMLButtonElement>(null);
  const [openDoubleAnchorMenu, setOpenDoubleAnchorMenu] = useState(false);
  const mooringAngleMenuRef = useRef<HTMLButtonElement>(null);
  const [openMooringAngleMenu, setOpenMooringAngleMenu] = useState(false);
  const [_mergeDistance, setMergeDistance] = useState<number>(0.15);

  return (
    <>
      <SubtitleWithLine text={"Anchor"} article={ARTICLE_MOORING_GEN} />
      <OverlineText style={{ paddingTop: 0 }}>Constraints</OverlineText>
      <Grid2
        style={{
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Toggle
          checked={params.keepAnchorsInPark}
          onChange={(e) => {
            setAndGenerate({ ...params, keepAnchorsInPark: e.target.checked });
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitleWrapper>
          <InputTitle> Constrain anchors to park</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="Don't place anchors or mooring lines outside the park boundary."
            size={10}
          />
        </InputTitleWrapper>
      </Grid2>

      <Grid2
        style={{
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Toggle
          checked={params.requireAllAnchors}
          onChange={(e) => {
            setAndGenerate({ ...params, requireAllAnchors: e.target.checked });
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitleWrapper>
          <InputTitle> Require all anchors</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="If any anchor cannot be placed around a turbine, don't generate any anchors for that turbine."
            size={10}
          />
        </InputTitleWrapper>
      </Grid2>

      <OverlineText>Anchor settings</OverlineText>

      <Grid2
        style={{
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Row>
          <Toggle
            checked={!!params.mergeDistance}
            onChange={(e) => {
              setAndGenerate({
                ...params,
                mergeDistance: e.target.checked ? _mergeDistance : undefined,
              });
            }}
            size={ToggleSize.SMALL}
          />
          {!!params.mergeDistance && (
            <InputDimensioned
              validate={between(10, 600)}
              style={{ width: "6rem" }}
              validationMessage={`Must be between 50 and 600`}
              step={10}
              value={_mergeDistance * 1000}
              onChange={(b) => {
                const inKilometeres = roundToDecimal(b / 1000, 3);
                setMergeDistance(inKilometeres);
                setAndGenerate({ ...params, mergeDistance: inKilometeres });
              }}
              compact
              unit="m"
            />
          )}
        </Row>
        <InputTitleWrapper>
          <InputTitle>Merge anchors</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="If any anchors are within N meters of each other, merge them as one and use the average position."
            size={10}
          />
        </InputTitleWrapper>
      </Grid2>
      <ControlRow enabled={params.doubleAnchors}>
        <Grid2
          style={{
            gridTemplateColumns: "auto 1fr",
          }}
        >
          <Toggle
            checked={params.doubleAnchors}
            onChange={(e) => {
              const g = e.target.checked;
              setAndGenerate({ ...params, doubleAnchors: g });
              setOpenDoubleAnchorMenu(g);
            }}
            size={ToggleSize.SMALL}
          />
          <InputTitle> Double anchors</InputTitle>
        </Grid2>

        <IconBtn
          backgroundColor={colors.surfaceButtonSecondary}
          size={"1.4rem"}
          id="double-anchors-submenu-open"
          ref={doubleAnchorMenuRef}
          onClick={() => {
            setOpenDoubleAnchorMenu(!openDoubleAnchorMenu);
          }}
        >
          <OpenRight />
        </IconBtn>
        {openDoubleAnchorMenu && (
          <Anchor
            baseRef={doubleAnchorMenuRef}
            floatPlace="bottomLeft"
            basePlace="topRight"
          >
            <DoubleAnchors
              currentFoundationId={currentFoundationId}
              maxDepth={maxDepth}
              setOpenDoubleAnchorMenu={setOpenDoubleAnchorMenu}
              setAndGenerate={setAndGenerate}
            />
          </Anchor>
        )}
      </ControlRow>

      <Label>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputTitle>Number of anchors</InputTitle>
          <InputDimensioned
            style={{ width: "13.5rem" }}
            validate={between(3, 6)}
            validationMessage={`Must be between 3 and 6`}
            step={1}
            value={params.numberOfAnchors}
            onChange={(b) => {
              setAndGenerate({ ...params, numberOfAnchors: b });
            }}
            compact
          />
        </Row>
      </Label>

      <Label>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip text="The distance from the anchor to the turbine. The 'depth' unit is a multiplier of the turbine depth.">
            <InputTitle>Anchor radius</InputTitle>
          </Tooltip>
          <InputDimensioned
            compact
            validate={
              params.distanceMode === "km"
                ? between(ANCHOR_RADIUS_MIN_KM, ANCHOR_RADIUS_MAX_KM)
                : between(ANCHOR_RADIUS_MIN_DEPTH, ANCHOR_RADIUS_MAX_DEPTH)
            }
            validationMessage={
              params.distanceMode === "km"
                ? `Must be between ${ANCHOR_RADIUS_MIN_KM} and ${ANCHOR_RADIUS_MAX_KM} km`
                : `Must be between ${ANCHOR_RADIUS_MIN_DEPTH} and ${ANCHOR_RADIUS_MAX_DEPTH} times the depth`
            }
            style={{ width: "13.5rem" }}
            unit={params.distanceMode}
            units={distanceUnits}
            step={0.1}
            value={params.distance}
            onChange={(d) => {
              setAndGenerate({ ...params, distance: d });
            }}
            onUnitChange={(u) => {
              convertBetweenKmAndDepth(params, u);
            }}
          />
        </Row>
      </Label>

      <Label>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center ",
          }}
        >
          <InputTitleWrapper>
            <InputTitle>Bearing</InputTitle>
            <HelpTooltip
              size={10}
              text="The bearing at which the first anchor is placed. The remaining anchors are placed evenly around the turbine."
            />
          </InputTitleWrapper>

          <Row style={{ gap: "0.8rem" }}>
            <InputDimensioned
              validate={between(-180, 180)}
              validationMessage={`Must be between -180 and 180`}
              step={1}
              value={isDefined(params.angles) ? undefined : params.bearing}
              placeholder={isDefined(params.angles) ? "Custom" : undefined}
              unit={isDefined(params.angles) ? "" : "deg"}
              style={{ width: "10.3rem" }} // 13.5rem (default width) - 2.4rem (button width) - 0.8rem (gap)
              onChange={(b) => {
                setAndGenerate({ ...params, bearing: b, angles: undefined });
              }}
              compact
            />
            <IconBtn
              backgroundColor={colors.surfaceButtonSecondary}
              size={"1.4rem"}
              id="mooring-angles-submenu-open"
              ref={mooringAngleMenuRef}
              onClick={() => {
                setOpenMooringAngleMenu(!openMooringAngleMenu);
              }}
            >
              <OpenRight />
            </IconBtn>
          </Row>
          {openMooringAngleMenu && (
            <Anchor
              baseRef={mooringAngleMenuRef}
              floatPlace="bottomLeft"
              basePlace="bottomRight"
              offset={["0.6rem", 0]}
            >
              <MooringAngles
                currentFoundationId={currentFoundationId}
                setOpenMooringAngleMenu={setOpenMooringAngleMenu}
                setAndGenerate={setAndGenerate}
              />
            </Anchor>
          )}
        </Row>
      </Label>
    </>
  );
};

const MooringLineSettings = ({
  params,
  maxDepth,
  currentFoundationId,
  setAndGenerate,
  convertBetweenKmAndDepth,
}: {
  params: MooringParameters;
  maxDepth: number;
  currentFoundationId: string;
  setAndGenerate: Setter<MooringParameters>;
  convertBetweenKmAndDepth: (
    params: MooringParameters,
    unit: MooringDistance,
  ) => void;
}) => {
  const lineTypes = useAtomValue(mooringLineTypesAtom);
  const foundations = useAtomValue(foundationTypesAtom);
  const allFloaterTypes = Array.from(foundations.values()).filter(isFloater);
  const currentFoundation = allFloaterTypes.find(
    (f) => f.id === currentFoundationId,
  );

  const [openSegment, setOpenSegment] = useState<number>(0);

  const verticalSpan = maxDepth + (currentFoundation?.fairZ ?? 0);
  const anchorRadius =
    params.distanceMode === "km"
      ? 1000 * params.distance
      : params.distance * maxDepth;
  const horizontalSpan = anchorRadius - (currentFoundation?.fairRadius ?? 0);
  const totalLineLength = params.multisegment
    ? params.lineLengths.reduce((acc, l) => (acc += l), 0)
    : params.lineLength;
  const minLineLength =
    params.distanceMode === "km"
      ? Math.round(100 * MOORING_LINE_LENGTH_MIN_KM) / 100
      : Math.round((100 * MOORING_LINE_LENGTH_MIN_KM * 1000) / maxDepth) / 100;
  const maxLineLength =
    params.distanceMode === "km"
      ? Math.round((100 * (verticalSpan + horizontalSpan)) / 1000) / 100
      : Math.round((100 * (verticalSpan + horizontalSpan)) / maxDepth) / 100;
  const minSegLength = Math.max(
    0,
    Math.round(
      100 *
        (minLineLength - (totalLineLength - params.lineLengths[openSegment])),
    ) / 100,
  );
  const maxSegLength =
    Math.round(
      100 *
        (maxLineLength - (totalLineLength - params.lineLengths[openSegment])),
    ) / 100;

  const [showLineLengthError, setShowLineLengthError] =
    useState<boolean>(false);

  const estimateLineLength = useCallback(
    (params: MooringParameters) => {
      const lineLength = estimateLineLengthFromParams(
        params,
        Array.from(lineTypes.values()),
        maxDepth,
        currentFoundation,
      );
      if (!lineLength) {
        setShowLineLengthError(true);
      } else {
        setShowLineLengthError(false);
        const unitLineLength =
          params.distanceMode === "km"
            ? lineLength / 1000
            : lineLength / maxDepth;
        if (params.multisegment) {
          const segTotalLineLength = params.lineLengths.reduce(
            (acc, l) => (acc += l),
            0,
          );
          const lineLengthFactor = unitLineLength / segTotalLineLength;
          const updatedLineLengths = params.lineLengths.map(
            (length) => Math.round(100 * length * lineLengthFactor) / 100,
          );
          setAndGenerate({
            ...params,
            lineLengths: updatedLineLengths,
          });
        } else {
          const updatedLineLength = Math.round(100 * unitLineLength) / 100;
          setAndGenerate({
            ...params,
            lineLength: updatedLineLength,
          });
        }
      }
    },
    [currentFoundation, lineTypes, maxDepth, setAndGenerate],
  );

  return (
    <>
      <SubtitleWithLine text={"Mooring line"} article={ARTICLE_MOORING_GEN} />

      <RadioGroup style={{ paddingBottom: "1rem" }}>
        <Radio
          label="Uniform line"
          checked={!params.multisegment}
          onChange={() => {
            setAndGenerate({ ...params, multisegment: false });
          }}
        />
        <InputTitleWrapper>
          <Radio
            label="Segmented line"
            checked={params.multisegment}
            onChange={() => {
              setAndGenerate({ ...params, multisegment: true });
            }}
          />
          <HelpTooltip
            size={10}
            text="Create a mooring line with three individual segments, starting from the anchor."
          />
        </InputTitleWrapper>
      </RadioGroup>

      {!params.multisegment && (
        <>
          <Label>
            <InputTitle>Line type</InputTitle>
            <DropdownButton
              buttonText={
                lineTypes.get(params.lineTypeId)?.name ?? "Select line type"
              }
              size={"small"}
              onSelectItem={(id) => {
                setAndGenerate({ ...params, lineTypeId: id });
              }}
              items={Array.from(lineTypes.values()).map((lt) => ({
                name: lt.name,
                value: lt.id,
              }))}
            />
          </Label>

          <Label>
            <Tooltip text="The unstretched mooring line length.">
              <InputTitle>Line length</InputTitle>
            </Tooltip>
          </Label>
          <Row>
            <Label style={{ flex: 1 }}>
              <InputDimensioned
                compact
                unit={params.distanceMode}
                units={distanceUnits}
                validate={between(minLineLength, maxLineLength)}
                validationMessage={
                  params.distanceMode === "km"
                    ? `Must be between ${minLineLength} and ${maxLineLength} km`
                    : `Must be between ${minLineLength} and ${maxLineLength} times the depth`
                }
                style={{ width: "100%" }}
                value={params.lineLength}
                step={0.1}
                onChange={(d) => {
                  setAndGenerate({ ...params, lineLength: d });
                }}
                onUnitChange={(u) => {
                  convertBetweenKmAndDepth(params, u);
                }}
              />
            </Label>
            <Tooltip
              text={
                maxDepth
                  ? `Calculate the required line length to achieve a realistic pretension (${Math.round(
                      TARGET_PRETENSION / 1000,
                    )} kN).`
                  : `Turbines must be generated before line length can be estimated.`
              }
            >
              <Button
                disabled={!maxDepth}
                text={"Estimate"}
                buttonType={"secondary"}
                size={"small"}
                onClick={() => {
                  estimateLineLength(params);
                }}
              />
            </Tooltip>
          </Row>

          {showLineLengthError && (
            <SimpleAlert
              text={"Could not find required line length"}
              type={"error"}
            />
          )}
        </>
      )}

      {params.multisegment && (
        <>
          {["Segment 1", "Segment 2", "Segment 3"].map((segment, index) => (
            <div key={index}>
              <OverlineTextWrapper
                onClick={() => {
                  if (openSegment === index) {
                    setOpenSegment(-1);
                    return;
                  }
                  setOpenSegment(index);
                }}
              >
                <OverlineText>{segment}</OverlineText>
                <ChevronIcon
                  open={openSegment === index}
                  chevronSize={"1rem"}
                  style={{
                    alignSelf: "end",
                    justifySelf: "end",
                  }}
                />
              </OverlineTextWrapper>
              {openSegment === index && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.6rem 0.8rem",
                    gap: "0.8rem",
                  }}
                >
                  <Label>
                    <InputTitle>Segment type</InputTitle>

                    <Dropdown
                      small
                      value={params.lineTypeIds[index]}
                      onChange={(e) => {
                        const updatedLineTypeIds = [...params.lineTypeIds];
                        updatedLineTypeIds[index] = e.target.value;
                        setAndGenerate({
                          ...params,
                          lineTypeIds: updatedLineTypeIds,
                        });
                      }}
                    >
                      {Array.from(lineTypes.values()).map((lt) => (
                        <option key={lt.id} value={lt.id}>
                          {lt.name}
                        </option>
                      ))}
                    </Dropdown>
                  </Label>
                  <Label style={{ flex: 1 }}>
                    <Tooltip text="The unstretched mooring segment length.">
                      <InputTitle>Segment length</InputTitle>
                    </Tooltip>
                  </Label>
                  <Row>
                    <Label style={{ flex: 1 }}>
                      <InputDimensioned
                        compact
                        unit={params.distanceMode}
                        units={distanceUnits}
                        style={{ width: "100%" }}
                        step={0.1}
                        validate={between(minSegLength, maxSegLength)}
                        validationMessage={
                          params.distanceMode === "km"
                            ? `Must be between ${minSegLength} and ${maxSegLength} km`
                            : `Must be between ${minSegLength} and ${maxSegLength} times the depth`
                        }
                        value={params.lineLengths[openSegment]}
                        onChange={(d) => {
                          const updatedLineLengths = [...params.lineLengths];
                          updatedLineLengths[openSegment] = d;
                          setAndGenerate({
                            ...params,
                            lineLengths: updatedLineLengths,
                          });
                        }}
                        onUnitChange={(u) => {
                          convertBetweenKmAndDepth(params, u);
                        }}
                      />
                    </Label>
                    <Tooltip
                      text={
                        maxDepth
                          ? `Calculate the required line length to achieve a realistic pretension (${Math.round(
                              TARGET_PRETENSION / 1000,
                            )} kN).`
                          : `Turbines must be generated before line length can be estimated.`
                      }
                    >
                      <Button
                        disabled={!maxDepth}
                        text={"Estimate"}
                        buttonType={"secondary"}
                        size={"small"}
                        onClick={() => {
                          estimateLineLength(params);
                        }}
                      />
                    </Tooltip>
                  </Row>
                  {showLineLengthError && (
                    <SimpleAlert
                      text={"Could not find required line length"}
                      type={"error"}
                    />
                  )}
                  {index < 2 && (
                    <Label>
                      <Tooltip text="Net weight in water of clump weight or buoy attached to upper end of segment.">
                        <InputTitle>Attachment net weight</InputTitle>
                      </Tooltip>
                      <InputDimensioned
                        compact
                        unit={"tonnes"}
                        style={{
                          maxHeight: "2.8rem",
                          width: "100%",
                        }}
                        validate={between(-20, 20)}
                        validationMessage={"Must be between -20 and 20 tonnes"}
                        value={params.attachments[openSegment] / 1000}
                        onChange={(d) => {
                          const updatedAttachments = [...params.attachments];
                          updatedAttachments[openSegment] = 1000 * d;
                          setAndGenerate({
                            ...params,
                            attachments: updatedAttachments,
                          });
                        }}
                      />
                    </Label>
                  )}
                  {totalLineLength > maxLineLength && (
                    <SimpleAlert
                      text={
                        "Total line length is too large for the current anchor radius and water depth"
                      }
                      type={"error"}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

const createMooringLine = (
  anchor: AnchorFeature,
  turbine: TurbineFeature,
  params: MooringParameters,
  parentId: string,
  raster: Raster,
) => {
  const id = uuidv4();
  if (params.multisegment) {
    const usedLineLengths = [...params.lineLengths];
    if (params.distanceMode === "depth") {
      const depth =
        raster.latLngToValue(
          turbine.geometry.coordinates[0],
          turbine.geometry.coordinates[1],
        ) ?? 0;
      for (let i = 0; i < usedLineLengths.length; i++) {
        usedLineLengths[i] *= -depth / 1000;
      }
    }
    return _MooringLineFeature.parse({
      type: "Feature",
      id,
      geometry: {
        type: "LineString",
        coordinates: [
          anchor.geometry.coordinates,
          turbine.geometry.coordinates,
        ],
      },
      properties: {
        id,
        name: "Mooring line",
        type: MOORING_LINE_PROPERTY_TYPE,
        anchor: anchor.id,
        target: turbine.id,
        parentIds: [parentId],
        slack: 0,
        lineLengths: usedLineLengths,
        lineTypes: params.lineTypeIds,
        attachments: params.attachments,
      },
    });
  } else {
    let usedLineLength = params.lineLength;
    if (params.distanceMode === "depth") {
      const depth =
        raster.latLngToValue(
          turbine.geometry.coordinates[0],
          turbine.geometry.coordinates[1],
        ) ?? 0;
      usedLineLength *= -depth / 1000;
    }
    return _MooringLineFeature.parse({
      type: "Feature",
      id,
      geometry: {
        type: "LineString",
        coordinates: [
          anchor.geometry.coordinates,
          turbine.geometry.coordinates,
        ],
      },
      properties: {
        id,
        name: "Mooring line",
        type: MOORING_LINE_PROPERTY_TYPE,
        anchor: anchor.id,
        target: turbine.id,
        parentIds: [parentId],
        slack: 0,
        lineLength: usedLineLength,
        lineType: params.lineTypeId,
      },
    });
  }
};

export const MooringInner = ({
  live,
  park,
  mode,
  raster,
  currentFoundationId,
  floatingTurbines,
  setLive,
  saveOnExitAndApplyCallback,
}: {
  live?: boolean;
  park: ParkFeature;
  mode: Mode;
  raster: Raster;
  currentFoundationId: string;
  floatingTurbines: TurbineFeature[];
  setLive?: React.Dispatch<React.SetStateAction<boolean>>;
  saveOnExitAndApplyCallback?: () => void;
}) => {
  const setPreviewMooringAndFoundationState = useSetAtom(
    previewMooringAndFoundationState,
  );

  const { showConfirm } = useConfirm();

  const anchors = useAtomValue(
    anchorsInParkFamily({ parkId: park.id, branchId: undefined }),
  );
  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId: park.id, branchId: undefined }),
  );

  const exclusionZones = useAtomValue(
    exclusionZonesFamily({ branchId: undefined }),
  );

  const hasLockedAnchors = useMemo(
    () => anchors.some(featureIsLocked),
    [anchors],
  );
  const hasLockedMooringLines = useMemo(
    () => mooringLines.some(featureIsLocked),
    [mooringLines],
  );

  const { maxDepth } = useGetTurbineDepths({
    raster,
    turbineFeatures: floatingTurbines,
  });

  const params = useAtomValue(
    unwrap(
      getMooringParametersAtomFamily({
        foundationId: currentFoundationId,
      }),
    ),
  );

  const previousParams = usePrevious(params);
  const paramsToUse = params ?? previousParams;

  const setParams = useSetAtom(
    getMooringParametersAtomFamily({
      foundationId: currentFoundationId,
    }),
  );

  const generate = useMemo(
    () =>
      throttle(50, (params: MooringParameters) => {
        if (mode.mode === "illegal") {
          sendInfo(
            "Tried to save with illegal state. Shouldn't be possible, but no biggie",
            { mode },
          );
          return;
        }

        const previewFoundations = floatingTurbines.map((t) => ({
          turbineId: t.id,
          foundationId: currentFoundationId,
        }));
        const previewMooringLines: MooringLineFeature[] = [];
        const previewAnchors: AnchorFeature[] = [];
        const partialTurbines: string[] = [];

        const anchorExclZones = exclusionZones.filter(
          (e) => exclusionDomainUnpack(e.properties.domain).anchor,
        );

        floatingTurbines.forEach((turbine) => {
          let usedDistance = params.distance;
          if (params.distanceMode === "depth") {
            const depth =
              raster.latLngToValue(
                turbine.geometry.coordinates[0],
                turbine.geometry.coordinates[1],
              ) ?? 0;
            usedDistance *= -depth / 1000;
          }

          let anchors = makeAnchors(
            turbine,
            params.numberOfAnchors,
            usedDistance,
            params.bearing,
            params.doubleAnchors,
            params.doublingAngle,
            params.angles,
            undefined,
          ).filter(
            (a) =>
              !anchorExclZones.some((z) =>
                mooringIntersectsPolygon(
                  turbine.geometry,
                  a.geometry,
                  z.geometry,
                ),
              ),
          );

          if (params.keepAnchorsInPark) {
            anchors = anchors.filter(
              (a) =>
                !mooringIntersectsPolygon(
                  turbine.geometry,
                  a.geometry,
                  park.geometry,
                ),
            );
          }
          if (
            anchors.length !==
            (params.doubleAnchors
              ? params.numberOfAnchors * 2
              : params.numberOfAnchors)
          ) {
            partialTurbines.push(turbine.id);
            if (params.requireAllAnchors) return;
          }

          const mergeDistance = params.mergeDistance;

          anchors.forEach((anchor) => {
            const existingAnchorIndex = mergeDistance
              ? previewAnchors.findIndex(
                  (a) =>
                    turf.distance(anchor.geometry, a.geometry) <= mergeDistance,
                )
              : -1;

            if (existingAnchorIndex >= 0) {
              const existingAnchor = previewAnchors[existingAnchorIndex];
              const newCoordinates = [
                (existingAnchor.geometry.coordinates[0] +
                  anchor.geometry.coordinates[0]) /
                  2,
                (existingAnchor.geometry.coordinates[1] +
                  anchor.geometry.coordinates[1]) /
                  2,
              ];
              const updatedAnchor = {
                ...existingAnchor,
                geometry: {
                  ...existingAnchor.geometry,
                  coordinates: newCoordinates,
                },
              };
              previewAnchors[existingAnchorIndex] = updatedAnchor;

              // Update existing mooring lines connected to this anchor
              previewMooringLines.forEach((line) => {
                if (line.properties.anchor === existingAnchor.id) {
                  line.geometry.coordinates[0] = newCoordinates;
                }
              });

              const line = createMooringLine(
                updatedAnchor,
                turbine,
                params,
                park.id,
                raster,
              );
              previewMooringLines.push(line);
            } else {
              previewAnchors.push(anchor);
              const line = createMooringLine(
                anchor,
                turbine,
                params,
                park.id,
                raster,
              );
              previewMooringLines.push(line);
            }
          });
        });

        // Depending on what mode we're in, we should keep some mooring lines and
        // anchors.
        //  - Any mooring line connected to the turbines that we've generated new
        //    mooring for should be removed.
        // -  Any anchor that is without a mooring line after this should be
        //    removed.

        const existingMooringLines: MooringLineFeature[] = [];
        const existingAnchors: AnchorFeature[] = [];

        if (mode.mode === "park") {
          const keptMooringLines = mooringLines.filter(
            (ml) =>
              !floatingTurbines.find((t) => t.id === ml.properties.target),
          );
          const keptAnchors = anchors.filter((a) =>
            keptMooringLines.find((ml) => ml.properties.anchor === a.id),
          );
          existingMooringLines.push(...keptMooringLines);
          existingAnchors.push(...keptAnchors);
        } else if (mode.mode === "zone") {
          const turbineIsInSelectedZone = (t: TurbineFeature) =>
            mode.subAreas.some((z) => pointInPolygon(t.geometry, z.geometry));

          const turbinesInZone = floatingTurbines.filter(
            turbineIsInSelectedZone,
          );
          const keptMooringLines = mooringLines.filter(
            (ml) => !turbinesInZone.find((t) => t.id === ml.properties.target),
          );
          const keptAnchors = anchors.filter((a) =>
            keptMooringLines.find((ml) => ml.properties.anchor === a.id),
          );
          existingMooringLines.push(...keptMooringLines);
          existingAnchors.push(...keptAnchors);
        } else if (mode.mode === "turbines") {
          const keptMooringLines = mooringLines.filter(
            (ml) => !mode.turbines.find((t) => t.id === ml.properties.target),
          );
          const keptAnchors = anchors.filter((a) =>
            keptMooringLines.find((ml) => ml.properties.anchor === a.id),
          );
          existingMooringLines.push(...keptMooringLines);
          existingAnchors.push(...keptAnchors);
        } else {
          isNever(mode);
        }

        setPreviewMooringAndFoundationState({
          preview: {
            foundations: previewFoundations,
            mooringLines: previewMooringLines,
            anchors: previewAnchors,
            partialTurbines,
          },
          existing: {
            mooringLines: existingMooringLines,
            anchors: existingAnchors,
          },
        });
      }),
    [
      anchors,
      exclusionZones,
      park,
      currentFoundationId,
      floatingTurbines,
      mode,
      mooringLines,
      raster,
      setPreviewMooringAndFoundationState,
    ],
  );

  useEffect(() => {
    if (!live || !params) return;
    generate(params);
  }, [params, generate, live]);

  useEffect(() => {
    return () => {
      setPreviewMooringAndFoundationState(undefined);
    };
  }, [setPreviewMooringAndFoundationState, live]);

  useEffect(() => {
    const previewFoundations = floatingTurbines.map((t) => ({
      turbineId: t.id,
      foundationId: currentFoundationId,
    }));

    setPreviewMooringAndFoundationState((curr) =>
      curr
        ? {
            preview: {
              ...curr.preview,
              foundations: previewFoundations,
            },
            existing: curr.existing,
          }
        : undefined,
    );
  }, [
    floatingTurbines,
    setPreviewMooringAndFoundationState,
    currentFoundationId,
  ]);

  const convertBetweenKmAndDepth = useCallback(
    (params: MooringParameters, unit: MooringDistance) => {
      let newDistance = params.distance / (maxDepth / 1000);
      let newLineLength = params.lineLength / (maxDepth / 1000);
      let newLineLengths: number[] = [];
      if (unit === "km") {
        newDistance = (params.distance * maxDepth) / 1000;
        newLineLength = (params.lineLength * maxDepth) / 1000;
        for (let i = 0; i < params.lineLengths.length; i++) {
          newLineLengths.push((params.lineLengths[i] * maxDepth) / 1000);
        }
      } else {
        for (let i = 0; i < params.lineLengths.length; i++) {
          newLineLengths.push(params.lineLengths[i] / (maxDepth / 1000));
        }
      }
      setParams({
        ...params,
        distanceMode: unit,
        distance: newDistance,
        lineLength: newLineLength,
        lineLengths: newLineLengths,
      });
    },
    [maxDepth, setParams],
  );

  if (!paramsToUse) {
    return null;
  }

  return (
    <Column>
      <>
        <AnchorSettings
          currentFoundationId={currentFoundationId}
          maxDepth={maxDepth}
          params={paramsToUse}
          setAndGenerate={setParams}
          convertBetweenKmAndDepth={convertBetweenKmAndDepth}
        />
        <MooringLineSettings
          params={paramsToUse}
          maxDepth={maxDepth}
          currentFoundationId={currentFoundationId}
          setAndGenerate={setParams}
          convertBetweenKmAndDepth={convertBetweenKmAndDepth}
        />
      </>
      {setLive && saveOnExitAndApplyCallback && (
        <div
          style={{
            backgroundColor: `${colors.surfacePrimary}`,
            display: "flex",
            justifyContent: "flex-end",
            position: "sticky",
            bottom: 0,
            padding: `${spaceLarge} ${spaceLarge} 0 ${spaceLarge}`,
            gap: spaceMedium,
            borderTop: `1px solid ${colors.borderSubtle}`,
            margin: `0 -${spaceLarge} `,
          }}
        >
          {live && (
            <>
              <Button
                id="reset-foundations-button"
                text={"Reset"}
                buttonType={"secondary"}
                onClick={async () => {
                  if (
                    !(hasLockedAnchors && hasLockedMooringLines) ||
                    (await showConfirm({
                      title: "Resetting mooring",
                      message:
                        "Existing locked anchors and mooring lines will be removed.",
                    }))
                  ) {
                    setLive?.((curr) => !curr);
                  }
                }}
              />
              <Button
                id="apply-foundations-button"
                text={"Apply"}
                buttonType={"primary"}
                onClick={() => {
                  saveOnExitAndApplyCallback();
                }}
              />
            </>
          )}

          {!live && (
            <>
              <Button
                id="generate-foundations-button"
                text={"Generate"}
                buttonType={"primary"}
                onClick={() => {
                  setLive?.((curr) => !curr);
                  generate(paramsToUse);
                }}
              />
            </>
          )}
        </div>
      )}
    </Column>
  );
};
