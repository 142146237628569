import { SubstationType } from "services/substationService";
import { Row } from "components/General/Layout";
import { ContentWrapper } from "components/NotificationSystem/style";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { ACSettings } from "./ACSettings";
import { BasicSettings } from "./BasicSettings";
import { DCSettings } from "./DCSettings";
import {
  ColumnContent,
  SettingsColumn,
} from "components/ConfigurationModal/shared";

type SubstationContentProps = {
  substation: SubstationType;
  onChange: (substation: SubstationType) => void;
  disabled?: boolean;
  nodeId: string;
  isLibrary?: boolean;
  libraryResourceInProjectContext?: boolean;
};

export const SubstationContent = ({
  substation,
  onChange,
  disabled,
  nodeId,
  isLibrary,
  libraryResourceInProjectContext,
}: SubstationContentProps) => {
  const inOnshoreProject = useAtomValue(isOnshoreAtom);

  const libraryResourceInOnshoreProjectContext =
    inOnshoreProject && libraryResourceInProjectContext;
  const projectContentInOnshoreProject =
    inOnshoreProject && !isLibrary && !libraryResourceInProjectContext;

  const isOnshoreProjectInProjectContext = inOnshoreProject && !isLibrary;

  return (
    <ContentWrapper
      style={{
        padding: isLibrary ? "1.6rem 0" : "1.6rem 2.4rem",
        maxHeight: "calc(100% - 7.3rem)",
        overflowY: "auto",
        boxSizing: "border-box",
      }}
    >
      <ColumnContent>
        <SettingsColumn style={{ paddingBottom: "10rem" }}>
          {!isOnshoreProjectInProjectContext && (
            <Row>
              <SimpleAlert
                text="Connected export cable type determines whether AC or DC substation is used."
                type="info"
              />
            </Row>
          )}

          <BasicSettings
            substation={substation}
            onChange={onChange}
            disabled={disabled}
            nodeId={nodeId}
            isLibrary={isLibrary}
            isOnshoreProject={inOnshoreProject}
          />

          <ACSettings
            substation={substation}
            onChange={onChange}
            disabled={disabled}
            nodeId={nodeId}
          />

          {!projectContentInOnshoreProject && (
            <DCSettings
              substation={substation}
              onChange={onChange}
              disabled={disabled}
              nodeId={nodeId}
              isLibraryResource={isLibrary || libraryResourceInProjectContext}
              libraryResourceInOnshoreProjectContext={
                libraryResourceInOnshoreProjectContext
              }
            />
          )}
        </SettingsColumn>
      </ColumnContent>
    </ContentWrapper>
  );
};
