import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { ABLY_FOLDER_STRUCTURE_UPDATED } from "state/ably";
import { useAblyGeneric } from "./useAblyGeneric";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import useBranchFolderStructureCrud from "hooks/useBranchFolderStructureCrud";

const AblyBranchFolderStructure = ErrorBoundaryWrapper(
  ({ projectId }: { projectId: string }) => {
    const { updateLocal } = useBranchFolderStructureCrud();

    const channelName = useMemo(
      () => projectId && `${projectId}:all`,
      [projectId],
    );

    const onUpdateMessageReceived = useCallback(
      (message: InboundMessage) => {
        if (message.data.resourceType !== "BRANCH") {
          return;
        }

        updateLocal(message.data.items);
      },
      [updateLocal],
    );

    const events = useMemo(
      () => [
        {
          eventName: ABLY_FOLDER_STRUCTURE_UPDATED,
          onMessageReceived: onUpdateMessageReceived,
        },
      ],
      [onUpdateMessageReceived],
    );

    useAblyGeneric(channelName, events, projectId);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default AblyBranchFolderStructure;
