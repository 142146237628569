import React, { useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";
import { TabItemWrapper, TabsWrapper } from "./style";
import { cursorIsInRightHalfOfElement } from "utils/dragNDropUtils";
import { WithTooltip } from "components/General/Tooltip";
import { isOnshoreAtom } from "state/onshore";
import { useAtomValue } from "jotai";

const tooltipOffset: [number, string] = [0, "0.5rem"];

interface TabItemComponentProps
  extends React.PropsWithChildren,
    Pick<
      React.HTMLAttributes<HTMLDivElement>,
      "onMouseEnter" | "onMouseLeave" | "onPointerLeave"
    > {
  selected?: boolean;
  index: number;
  style?: React.CSSProperties;
  title?: string;
  onClick(e: React.MouseEvent): void;
  onClose?(e: React.MouseEvent): void;
  onDrag?(oldIndex: number, newIndex: number): void;
  onRightClick?(e: React.MouseEvent): void;
}

const IconButtonOnshore = ({
  selected,
  onClose,
}: {
  selected: boolean | undefined;
  onClose?(e: React.MouseEvent): void;
}) => (
  <IconBtn
    className="visible-on-hover"
    onClick={onClose}
    size="1rem"
    iconColor={selected ? undefined : colors.white}
    hoverBackgroundColor={selected ? colors.seagreen300 : colors.seagreen700}
    hoverStroke={selected ? colors.iconSubtle : colors.white}
    title="Close tab"
  >
    <CloseIcon />
  </IconBtn>
);

const IconButtonOffshore = ({
  selected,
  onClose,
}: {
  selected: boolean | undefined;
  onClose?(e: React.MouseEvent): void;
}) => (
  <IconBtn
    className="visible-on-hover"
    onClick={onClose}
    size="1rem"
    iconColor={selected ? undefined : colors.white}
    hoverBackgroundColor={
      selected ? colors.red100 : "var(--branch-tab-bar-background)"
    }
    hoverStroke={selected ? colors.iconSubtle : colors.white}
    title="Close tab"
  >
    <CloseIcon />
  </IconBtn>
);

const DRAGGABLE_TAB_TYPE = "DRAGGABLE_TAB";
const TabItem = React.forwardRef<HTMLDivElement, TabItemComponentProps>(
  (
    {
      selected,
      children,
      style,
      index,
      title,
      onClose,
      onClick,
      onDrag,
      onRightClick,
      onMouseEnter,
      onMouseLeave,
      onPointerLeave,
    },
    ref,
  ) => {
    const isOnshore = useAtomValue(isOnshoreAtom);
    const [hoverState, setHoverState] = useState<undefined | "start" | "end">(
      undefined,
    );
    const [, dragRef] = useDrag(
      () => ({
        type: DRAGGABLE_TAB_TYPE,
        item: { index },
        canDrag: Boolean(onDrag),
      }),
      [index, onDrag],
    );
    const currentRef = ref && "current" in ref ? ref.current : null;

    const [dropCollection, dropRef] = useDrop(
      () => ({
        accept: DRAGGABLE_TAB_TYPE,
        canDrop: () => Boolean(onDrag),
        hover: (hoveredItem, monitor) => {
          if (!monitor.isOver() || hoveredItem.index === index) {
            return setHoverState(undefined);
          }
          const hoveringRight = cursorIsInRightHalfOfElement(
            currentRef,
            monitor.getClientOffset(),
          );
          setHoverState(hoveringRight ? "end" : "start");
        },
        collect: (monitor) => {
          const isHovered = monitor.isOver() && monitor.canDrop();
          return {
            isHovered,
          };
        },
        drop: (draggedItem: { index: number }, monitor: DropTargetMonitor) => {
          const isRight = cursorIsInRightHalfOfElement(
            currentRef,
            monitor.getClientOffset(),
          );
          const newIndex = isRight ? index + 1 : index;
          onDrag?.(draggedItem.index, newIndex);
        },
      }),
      [index, onDrag, currentRef],
    );

    // @ts-ignore Seems fine to ignore this
    dragRef(dropRef(ref));
    return (
      <TabItemWrapper
        ref={ref}
        style={style}
        selected={selected}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
        }}
        onContextMenu={onRightClick}
        title={title}
        onMouseUp={(e) => {
          // Clicking scroll button of mouse
          if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();
            onClose?.(e);
          }
        }}
        isHoveredStart={dropCollection.isHovered && hoverState === "start"}
        isHoveredEnd={dropCollection.isHovered && hoverState === "end"}
        staticSize={false}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onPointerLeave={onPointerLeave}
      >
        {children}
        {onClose &&
          (isOnshore ? (
            <IconButtonOnshore onClose={onClose} selected={selected} />
          ) : (
            <IconButtonOffshore onClose={onClose} selected={selected} />
          ))}
      </TabItemWrapper>
    );
  },
);

export type TabItemProps = {
  id: string;
  text: React.ReactNode;
  //createdAt: number;
  title?: string;
  tooltip?: React.ReactNode;
  disabled?: boolean;
};

const BrowserStyleTabs = ({
  selectedId,
  leftAction,
  items,
  rightAction,
  wrapperStyle,
  itemStyle,
  renderItem,
  onClickTab,
  onCloseTab,
  onDragTab,
  onRightClick,
}: {
  selectedId: string;
  leftAction?: React.ReactNode;
  items: TabItemProps[];
  rightAction?: React.ReactNode;
  wrapperStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  renderItem(item: TabItemProps, active: boolean): React.ReactNode;
  onClickTab(item: TabItemProps, e: React.MouseEvent): void;
  onCloseTab?(item: TabItemProps, e: React.MouseEvent): void;
  onDragTab?(oldIndex: number, newIndex: number): void;
  onRightClick?(item: TabItemProps, e: React.MouseEvent): void;
}) => {
  return (
    <TabsWrapper style={wrapperStyle}>
      {leftAction}

      {items.map((item, index) => (
        <WithTooltip
          key={item.id}
          theme="dark"
          text=""
          content={item.tooltip}
          position="bottom"
          disabled={!item.tooltip}
          offset={tooltipOffset}
        >
          <TabItem
            index={index}
            onDrag={onDragTab}
            onClick={(e) => {
              if (!item.disabled) {
                onClickTab(item, e);
              }
            }}
            title={item.tooltip ? undefined : item.title}
            style={itemStyle}
            selected={selectedId === item.id}
            onRightClick={(e) => {
              onRightClick?.(item, e);
            }}
            onClose={onCloseTab ? (e) => onCloseTab(item, e) : undefined}
          >
            {renderItem(item, selectedId === item.id)}
          </TabItem>
        </WithTooltip>
      ))}

      {rightAction}
    </TabsWrapper>
  );
};

export default BrowserStyleTabs;
