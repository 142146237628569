import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { addGroup, removeGroup, updateGroup } from "./service";
import { scream } from "utils/sentry";

const useGroupsCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const add = useJotaiCallback(
    async (get, set, name: string) => {
      const fallback = await get(
        organisationGroupsState({
          organisationId,
        }),
      );
      return addGroup(organisationId, name)
        .then((newGroup) => {
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            (cur) => [...cur, newGroup],
          );
          return newGroup;
        })
        .catch((e) => {
          scream("Failed add group", {
            e,
          });
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId],
  );

  const update = useJotaiCallback(
    async (get, set, groupId: string, name: string) => {
      const fallback = await get(
        organisationGroupsState({
          organisationId,
        }),
      );
      return await updateGroup(organisationId, groupId, name)
        .then((updatedGroup) => {
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            (cur) => [...cur].map((c) => (c.id === groupId ? updatedGroup : c)),
          );
        })
        .catch((e) => {
          scream("Failed to update group", {
            e,
          });
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, groupId: string) => {
      const fallback = await get(
        organisationGroupsState({
          organisationId,
        }),
      );
      return await removeGroup(organisationId, groupId)
        .then(() => {
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            (cur) => [...cur].filter((g) => g.id !== groupId),
          );
        })
        .catch((e) => {
          scream("Failed to remove group", {
            e,
          });
          aset(
            get,
            set,
            organisationGroupsState({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId],
  );

  return {
    add,
    remove,
    update,
  };
};

export default useGroupsCrud;
