import {
  categoryDomainSelectorFamily,
  styleSelectorFamily,
} from "business/style/state";
import { Style, isCategory } from "business/style/types";
import { IconBtn } from "components/General/Icons";
import { Column } from "components/General/Layout";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing7 } from "styles/space";
import { typography } from "styles/typography";
import ColorSelector from "components/ColorSelector/ColorSelector";
import SubtractCircleIcon from "@icons/24/SubtractCircle.svg";
import AddCircleIcon from "@icons/24/AddCircle.svg";
import { useAtomValue, useSetAtom } from "jotai";
import { Color } from "lib/colors";
import Button from "components/General/Button";
import {
  EditableTextInternalState,
  TextContainer,
} from "components/General/EditableText";
import { v4 as uuid4 } from "uuid";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem ${spacing7};

  > :first-child > div {
    height: 1.6rem;
    width: 1.6rem;
  }

  > span {
    ${typography.caption};
    min-width: 0;
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-wrap: nowrap;
  }

  ${IconBtn}:not(:first-child) {
    svg {
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    ${IconBtn} {
      svg {
        opacity: 1;
      }
    }
  }

  ${TextContainer} {
    flex: 1;
  }
`;

const ClickRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem ${spacing7};
  ${typography.caption};
  gap: 1rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-wrap: nowrap;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    overflow: visible;
  }

  &:hover {
    background: ${colors.surfaceHover} !important;
    svg {
      opacity: 1;
    }
  }
  cursor: pointer;
`;

const StyleCol = styled(Column)`
  .list {
    display: flex;
    flex-direction: column;
    > div:nth-child(2n + 2):not(:last-child) {
      background: ${colors.surfaceSecondary};
    }
  }

  & > button {
    align-self: end;
  }
`;

function EditColorCategoriesInner({ style }: { style: Style }) {
  const setStyle = useSetAtom(styleSelectorFamily(style.id));

  const domain = useAtomValue(categoryDomainSelectorFamily(style.source));

  if (!isCategory(style)) return null;
  return (
    <StyleCol>
      <div className="list">
        {style.categories.map((b, i) => (
          <Row key={i}>
            <ColorSelector
              color={b.color.toRGB()}
              setColor={(c) => {
                setStyle({
                  ...style,
                  categories: style.categories.map((cat, k) => {
                    if (i !== k) return cat;
                    return { ...cat, color: Color.fromHex(String(c)) };
                  }),
                });
              }}
            />
            <span>{b.label ?? b.id}</span>
            <IconBtn
              size="1.4rem"
              disabled={style.categories.length <= 1}
              onClick={() => {
                if (style.categories.length <= 1) return;
                const categories = [...style.categories];
                categories.splice(i, 1);
                setStyle({ ...style, categories });
              }}
            >
              <SubtractCircleIcon />
            </IconBtn>
          </Row>
        ))}
      </div>
      {domain && (
        <>
          <span style={typography.sub3}>Add categories:</span>
          <div className="list">
            {domain
              .filter((d) => !style.categories.find((c) => c.id === d.id))
              .map((b, i) => (
                <ClickRow
                  key={i}
                  onClick={() => {
                    setStyle({
                      ...style,
                      categories: style.categories.concat([
                        {
                          id: b.id,
                          label: b.label,
                          color: Color.fromHex(colors.blue600),
                        },
                      ]),
                    });
                  }}
                >
                  <AddCircleIcon />
                  <span key={i}>{b.label ?? b.id}</span>
                </ClickRow>
              ))}
          </div>
        </>
      )}
    </StyleCol>
  );
}

function EditGroupCategories({ style }: { style: Style }) {
  const setStyle = useSetAtom(styleSelectorFamily(style.id));

  if (!isCategory(style)) return null;
  return (
    <StyleCol>
      <div className="list">
        {style.categories.map((b, i) => (
          <Row key={i}>
            <ColorSelector
              color={b.color.toRGB()}
              setColor={(c) => {
                setStyle({
                  ...style,
                  categories: style.categories.map((cat, k) => {
                    if (i !== k) return cat;
                    return { ...cat, color: Color.fromHex(String(c)) };
                  }),
                });
              }}
            />
            <EditableTextInternalState
              value={b.label}
              onEnter={(label) => {
                setStyle({
                  ...style,
                  categories: style.categories.map((c, j) =>
                    i === j
                      ? {
                          ...c,
                          label,
                        }
                      : c,
                  ),
                });
              }}
            />
            <IconBtn
              size="1.4rem"
              disabled={style.categories.length <= 1}
              onClick={() => {
                if (style.categories.length <= 1) return;
                const categories = [...style.categories];
                categories.splice(i, 1);
                setStyle({ ...style, categories });
              }}
            >
              <SubtractCircleIcon />
            </IconBtn>
          </Row>
        ))}
        <Button
          icon={<AddCircleIcon />}
          buttonType="text"
          text="Add group"
          onClick={() => {
            setStyle({
              ...style,
              categories: style.categories.concat({
                id: uuid4(),
                label: "New group",
                color: Color.fromHex(colors.blue500),
              }),
            });
          }}
        />
      </div>
    </StyleCol>
  );
}

export function EditColorCategories({ style }: { style: Style }) {
  if (style.source === "group") return <EditGroupCategories style={style} />;
  return <EditColorCategoriesInner style={style} />;
}
