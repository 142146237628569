import { atom } from "jotai";

export enum LeftModalMenuTypes {
  Comment = "Comment",
  Elements = "Elements",
  Layers = "Layers",
  Styling = "Styling",
  ExistingTurbines = "ExistingTurbines",
}

export const leftModalMenuOpenStateAtom = atom<LeftModalMenuTypes | undefined>(
  undefined,
);
