import { activePositionHintAtom } from "../../../state/hint";
import { userHintSettingsAtom } from "../CloseableHints/state";
import { useCallback } from "react";
import { AllHints } from "../AllHints";
import { Place } from "../../General/Anchor";
import { usePutUserHintSettings } from "../CloseableHints/hooks";
import { useAtom, useAtomValue } from "jotai";
import { activeTourState } from "components/OnboardingTours/state";

const PositionHints = ({ position }: { position?: Place }) => {
  const [activeHint, setActiveHint] = useAtom(activePositionHintAtom);
  const userSettings = useAtomValue(userHintSettingsAtom);
  const putUserHintSettings = usePutUserHintSettings();

  const close = useCallback(() => {
    if (!activeHint) return;
    putUserHintSettings({
      ...userSettings,
      dontShowTipIds: [...(userSettings.dontShowTipIds ?? []), activeHint],
    });
    setActiveHint(undefined);
  }, [activeHint, putUserHintSettings, userSettings, setActiveHint]);

  const activeTour = useAtomValue(activeTourState);

  if (
    !activeHint ||
    !!activeTour ||
    userSettings.hideAllTips ||
    userSettings.dontShowTipIds?.includes(activeHint)
  )
    return null;

  return (
    <AllHints hintId={activeHint} closeCallback={close} position={position} />
  );
};

export default PositionHints;
