import styled from "styled-components";
import { COLORS as WINDSPEED_COLORS } from "../../layers/windSpeed";
import {
  lowerRightMenuActiveModeAtom,
  windLayerHeightAtom,
  windLayerSourceAtom,
} from "../../state/layer";
import {
  meanSpeedGridLimitsAtom,
  showMeanSpeedGridAtom,
} from "../../state/windStatistics";
import { SITELOCATOR_COLORS } from "../SiteLocator/style";
import {
  activeBathymetryStyleIdAtom,
  activeOtherMapIdAtom,
  contourStepSizeAtom,
  defaultDepthRasterMinMax,
  depthRasterMinMaxSelector,
  windSpeedRasterMinMaxAtom,
} from "state/map";
import { BATHYMETRY_COLORS } from "layers/depthContours";
import {
  useCustomDataMouseSample,
  useDepthUnderMouseSampler,
} from "components/MouseSampler/DepthMouseSampler";
import { BathymetryFeature } from "types/feature";
import { colors } from "styles/colors";
import { useState } from "react";
import { RangeSlider, Slider } from "components/General/Slider";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import Dropdown from "components/Dropdown/Dropdown";
import { useWindMouseSampler } from "components/MouseSampler/WindMouseSampler";
import { SiteLocatorMenuType } from "@constants/projectMapView";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { useAtom, useAtomValue } from "jotai";
import { bathymetryFeatureFamily } from "state/jotai/bathymetry";
import { MesoWindDataSource } from "types/metocean";
import { isOnshoreAtom } from "state/onshore";
import { useHeightUnderMouseSampler } from "components/MouseSampler/HeightMouseSampler";
import { IconREMSize } from "styles/typography";
import Bathymetryanalysis from "@icons/bathymetryanalysis/bathymetryanalysis.svg";
import { Row } from "components/General/Layout";

const Legend = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
  height: 4rem;
  border-radius: 5rem;
  border: 0.3rem solid #fff;
  box-sizing: border-box;
  padding: 0 1rem;
  user-select: none;
`;

const TerrainLegend = styled.div`
  position: relative;
  background: #fff;
  opacity: 0.8;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 6rem;
`;

const LegendTitle = styled.div<{
  active?: boolean;
}>`
  background-color: ${(p) =>
    p.active || p.active === undefined ? colors.background : colors.secondary};
  padding: 0.2rem 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: ${(p) => (p.active !== undefined ? "pointer" : "inherit")};
`;

const TabWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 1.3rem;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  translate: 0 -100%;
`;

const CostLegend = styled(Legend)`
  background: linear-gradient(
    90deg,
    //Green
    rgb(0, 255, 0),
    // Yellow
    rgb(255, 255, 0),
    // Red
    rgb(255, 0, 0)
  );
`;

const WindLegend = styled(Legend)`
  background: linear-gradient(
    90deg,
    ${WINDSPEED_COLORS.default
      .map(([r, g, b]) => `rgb(${r}, ${g}, ${b})`)
      .join(",")}
  );
`;

const BathymetryInfoStyle = styled(Legend)`
  background: linear-gradient(
    90deg,
    ${BATHYMETRY_COLORS.default
      .map(([r, g, b]) => `rgb(${r}, ${g}, ${b})`)
      .join(",")}
  );
`;

const ContourSettingsStyle = styled(Legend)`
  background-color: ${colors.background};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Indicator = styled.div`
  position: absolute;
  height: 100%;
  border-radius: 2.5rem;
  width: 0.2rem;
  background: #000;
  transform: translateX(-50%);
`;

const Label = styled.span<{
  withSource?: boolean;
}>`
  position: relative;
  display: inline-block;
  left: 0.2rem;
  top: ${(p) => (p.withSource ? "0.55rem" : "0.95rem")};
  ${(p) => p.withSource && "width: 2.7rem;"}
  font-size: 0.75rem;
  margin: 0.2rem;
  background: #fff;
  padding: 2px 2px;
  border-radius: 4px;
  transform: translateY(-0.5rem);
  line-height: 1.2rem;
`;

const SourceLabel = styled.span`
  color: ${colors.grey700};
`;

const IndicatorWrapper = styled.div`
  height: 100%;
  width: 95%;
  display: flex;
  position: relative;
`;

const LegendLeftValue = styled.span`
  position: absolute;
  left: 1rem;
`;

const LegendRightValue = styled.span`
  position: absolute;
  right: 1rem;
`;

const CustomDepthMouseSamplerSingle = ({
  layer,
}: {
  layer: BathymetryFeature;
}) => {
  const { depth } = useCustomDataMouseSample(layer);
  const showDepth = depth && `${-depth.toFixed(1)}`;
  return showDepth ? (
    <Label
      style={{
        backgroundColor: colors.cableCorridor,
      }}
    >
      {showDepth}
    </Label>
  ) : null;
};

const CustomDepthMouseSampler = () => {
  const canvasLayerBathymetryFeatures = useAtomValue(
    bathymetryFeatureFamily({
      branchId: undefined,
    }),
  );
  return (
    <>
      {canvasLayerBathymetryFeatures.map((layer) => (
        <CustomDepthMouseSamplerSingle key={layer.id} layer={layer} />
      ))}
    </>
  );
};

enum Tab {
  DEPTH = "depth",
  CONTOUR = "contour",
}

enum WindLegendTabs {
  SPEED = "windSpeed",
  SOURCE = "source",
  HEIGHT = "height",
  EXTENT = "extent",
}

enum RasterDepthLegendTabs {
  DEPTH = "depth",
  EXTENT = "extent",
}

const BathymetryDepthLegend = ({
  tab,
  setTab,
}: {
  tab: Tab;
  setTab: React.Dispatch<React.SetStateAction<Tab>>;
}) => {
  const contourStepSize = useAtomValue(contourStepSizeAtom);
  const { depth } = useDepthUnderMouseSampler();
  return (
    <BathymetryInfoStyle>
      <TabWrapper>
        <LegendTitle
          onClick={() => setTab(Tab.DEPTH)}
          active={tab === Tab.DEPTH}
        >
          Depth [m]
        </LegendTitle>
        <LegendTitle
          onClick={() => setTab(Tab.CONTOUR)}
          active={tab === Tab.CONTOUR}
        >
          Contour [{contourStepSize}m]
        </LegendTitle>
      </TabWrapper>
      <span>0</span>
      <span
        style={{
          color: "white",
        }}
      >
        700
      </span>
      {depth && depth <= 650 && depth >= 20 ? (
        <Indicator
          style={{
            left: `${depth / 7}%`,
          }}
        >
          <Label withSource={true}>
            <span>{depth.toFixed(0)}</span>
            <br />
            <SourceLabel>(Gebco)</SourceLabel>
          </Label>
          <CustomDepthMouseSampler />
        </Indicator>
      ) : null}
    </BathymetryInfoStyle>
  );
};

const ContourSettings = ({
  tab,
  setTab,
}: {
  tab: Tab;
  setTab: React.Dispatch<React.SetStateAction<Tab>>;
}) => {
  const [contourStepSize, setContourStepSize] = useAtom(contourStepSizeAtom);
  return (
    <ContourSettingsStyle>
      <TabWrapper>
        <LegendTitle
          onClick={() => setTab(Tab.DEPTH)}
          active={tab === Tab.DEPTH}
        >
          Depth [m]
        </LegendTitle>
        <LegendTitle
          onClick={() => setTab(Tab.CONTOUR)}
          active={tab === Tab.CONTOUR}
        >
          Contour [{contourStepSize}m]
        </LegendTitle>
      </TabWrapper>
      <Slider
        style={{
          width: "100%",
          paddingRight: "0.5rem",
        }}
        value={contourStepSize}
        min={5}
        max={100}
        onChange={(f) => setContourStepSize(f)}
      />
      <HelpTooltip text="Source: Gebco" size={10} />
    </ContourSettingsStyle>
  );
};

const BathymetryInfo = () => {
  const [tab, setTab] = useState<Tab>(Tab.DEPTH);

  switch (tab) {
    case Tab.DEPTH:
      return <BathymetryDepthLegend tab={tab} setTab={setTab} />;
    case Tab.CONTOUR:
      return <ContourSettings tab={tab} setTab={setTab} />;
    default:
      return <BathymetryDepthLegend tab={tab} setTab={setTab} />;
  }
};

const RasterBathymetryInfo = () => {
  const [tab, setTab] = useState<RasterDepthLegendTabs>(
    RasterDepthLegendTabs.DEPTH,
  );

  switch (tab) {
    case RasterDepthLegendTabs.DEPTH:
      return <RasterInfo tab={tab} setTab={setTab} />;
    case RasterDepthLegendTabs.EXTENT:
      return <DepthExtent tab={tab} setTab={setTab} />;
    default:
      return <RasterInfo tab={tab} setTab={setTab} />;
  }
};
const RasterInfo = ({
  tab,
  setTab,
}: {
  tab: RasterDepthLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<RasterDepthLegendTabs>>;
}) => {
  const depthRasterMinMax = useAtomValue(depthRasterMinMaxSelector);
  const { depth } = useDepthUnderMouseSampler();

  return (
    <BathymetryInfoStyle>
      <DepthRasterLegendTabsCommon tab={tab} setTab={setTab} />
      <LegendLeftValue>{depthRasterMinMax[0]}</LegendLeftValue>
      <LegendRightValue
        style={{
          color: "white",
        }}
      >
        {depthRasterMinMax[1]}
      </LegendRightValue>
      {depth && depth >= 0 && (
        <IndicatorWrapper>
          <Indicator
            style={{
              left: `calc(${Math.min(Math.max(depth - depthRasterMinMax[0], 0) / (depthRasterMinMax[1] - depthRasterMinMax[0]), 1) * 100}%)`,
            }}
          >
            <Label withSource={true}>
              <span>{depth.toFixed(0)}</span>
              <br />
              <SourceLabel>(Gebco)</SourceLabel>
            </Label>
            <CustomDepthMouseSampler />
          </Indicator>
        </IndicatorWrapper>
      )}
    </BathymetryInfoStyle>
  );
};

const HeightTerrainInfo = () => {
  const { height } = useHeightUnderMouseSampler();

  return (
    <TerrainLegend>
      <Row style={{ justifyContent: "space-between" }}>
        <IconREMSize height={1.5} width={1.5}>
          <Bathymetryanalysis />
        </IconREMSize>
        <span style={{ width: "5rem" }}>
          {height ? height.toFixed(0) + "m" : ""}
        </span>
      </Row>
      <Row>
        <SourceLabel>(Mapbox)</SourceLabel>
      </Row>
    </TerrainLegend>
  );
};

const DepthRasterLegendTabsCommon = ({
  tab,
  setTab,
}: {
  tab: RasterDepthLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<RasterDepthLegendTabs>>;
}) => {
  return (
    <TabWrapper>
      <LegendTitle
        onClick={() => setTab(RasterDepthLegendTabs.DEPTH)}
        active={tab === RasterDepthLegendTabs.DEPTH}
      >
        Depth [m]
      </LegendTitle>
      <LegendTitle
        onClick={() => setTab(RasterDepthLegendTabs.EXTENT)}
        active={tab === RasterDepthLegendTabs.EXTENT}
      >
        Extent
      </LegendTitle>
    </TabWrapper>
  );
};

const WindLegendTabsCommon = ({
  tab,
  setTab,
}: {
  tab: WindLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<WindLegendTabs>>;
}) => {
  return (
    <TabWrapper>
      <LegendTitle
        onClick={() => setTab(WindLegendTabs.SPEED)}
        active={tab === WindLegendTabs.SPEED}
      >
        Speed
      </LegendTitle>
      <LegendTitle
        onClick={() => setTab(WindLegendTabs.SOURCE)}
        active={tab === WindLegendTabs.SOURCE}
      >
        Source
      </LegendTitle>
      <LegendTitle
        onClick={() => setTab(WindLegendTabs.HEIGHT)}
        active={tab === WindLegendTabs.HEIGHT}
      >
        Height
      </LegendTitle>
      <LegendTitle
        onClick={() => setTab(WindLegendTabs.EXTENT)}
        active={tab === WindLegendTabs.EXTENT}
      >
        Extent
      </LegendTitle>
    </TabWrapper>
  );
};

const WindSpeedExtent = ({
  tab,
  setTab,
}: {
  tab: WindLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<WindLegendTabs>>;
}) => {
  const [windSpeedRasterMinMax, setWindSpeedRasterMinMax] = useAtom(
    windSpeedRasterMinMaxAtom,
  );
  return (
    <ContourSettingsStyle
      style={{
        paddingTop: "2rem",
      }}
    >
      <WindLegendTabsCommon tab={tab} setTab={setTab} />
      <RangeSlider
        min={5}
        max={15}
        values={[windSpeedRasterMinMax[0], windSpeedRasterMinMax[1]]}
        step={0.1}
        inside
        labels
        renderLabel={(v) => `${v}m/s`}
        onChange={function (f: [number, number]): void {
          setWindSpeedRasterMinMax(f);
        }}
        style={{
          flex: 1,
        }}
      />
    </ContourSettingsStyle>
  );
};

const DepthExtent = ({
  tab,
  setTab,
}: {
  tab: RasterDepthLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<RasterDepthLegendTabs>>;
}) => {
  const [depthRasterMinMax, setDepthRasterMinMax] = useAtom(
    depthRasterMinMaxSelector,
  );
  return (
    <ContourSettingsStyle
      style={{
        paddingTop: "2rem",
      }}
    >
      <DepthRasterLegendTabsCommon tab={tab} setTab={setTab} />
      <RangeSlider
        min={defaultDepthRasterMinMax[0]}
        max={defaultDepthRasterMinMax[1]}
        values={[depthRasterMinMax[0], depthRasterMinMax[1]]}
        step={10}
        inside
        labels
        renderLabel={(v) => `${v}m`}
        onChange={function (f: [number, number]): void {
          setDepthRasterMinMax(f);
        }}
        style={{
          flex: 1,
        }}
      />
    </ContourSettingsStyle>
  );
};

const WindSpeedLegend = ({
  tab,
  setTab,
}: {
  tab: WindLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<WindLegendTabs>>;
}) => {
  const meanSpeed = useWindMouseSampler();
  const windSpeedRasterMinMax = useAtomValue(windSpeedRasterMinMaxAtom);
  return (
    <WindLegend>
      <WindLegendTabsCommon tab={tab} setTab={setTab} />
      <LegendLeftValue
        style={{
          color: "white",
        }}
      >
        {Math.floor(windSpeedRasterMinMax[0])}
      </LegendLeftValue>
      {meanSpeed && (
        <IndicatorWrapper>
          <Indicator
            style={{
              left: `calc(${Math.min(Math.max(meanSpeed - windSpeedRasterMinMax[0], 0) / (windSpeedRasterMinMax[1] - windSpeedRasterMinMax[0]), 1) * 100}%)`,
            }}
          >
            <Label>{meanSpeed.toFixed(1)}</Label>
          </Indicator>
        </IndicatorWrapper>
      )}
      <LegendRightValue
        style={{
          color: "black",
        }}
      >
        {Math.ceil(windSpeedRasterMinMax[1])}
      </LegendRightValue>
    </WindLegend>
  );
};

const HeightSettings = ({
  tab,
  setTab,
}: {
  tab: WindLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<WindLegendTabs>>;
}) => {
  const [windLayerHeight, setWindLayerHeightAtom] =
    useAtom(windLayerHeightAtom);
  return (
    <ContourSettingsStyle>
      <WindLegendTabsCommon tab={tab} setTab={setTab} />
      <Dropdown
        style={{
          height: "3rem",
          fontSize: "1rem",
        }}
        value={windLayerHeight}
        onChange={(e) =>
          setWindLayerHeightAtom(Number.parseFloat(e.target.value))
        }
      >
        <option value={100}>{"100"}</option>
        <option value={150}>{"150"}</option>
        <option value={200}>{"200"}</option>
      </Dropdown>
    </ContourSettingsStyle>
  );
};

const SourceSettings = ({
  tab,
  setTab,
}: {
  tab: WindLegendTabs;
  setTab: React.Dispatch<React.SetStateAction<WindLegendTabs>>;
}) => {
  const [windLayerSource, setWindLayerSourceAtom] =
    useAtom(windLayerSourceAtom);
  return (
    <ContourSettingsStyle>
      <WindLegendTabsCommon tab={tab} setTab={setTab} />
      <Dropdown
        style={{
          height: "3rem",
          fontSize: "1rem",
        }}
        value={windLayerSource}
        onChange={(e) =>
          setWindLayerSourceAtom(e.target.value as MesoWindDataSource)
        }
      >
        <option value={MesoWindDataSource.GWA}>{"Global Wind Atlas"}</option>
        <option value={MesoWindDataSource.NEWA}>
          {"New European Wind Atlas"}
        </option>
      </Dropdown>
    </ContourSettingsStyle>
  );
};

const WindSpeedInfo = () => {
  const [windLegendTabs, setWindLegendTabs] = useState<WindLegendTabs>(
    WindLegendTabs.SPEED,
  );

  switch (windLegendTabs) {
    case WindLegendTabs.SPEED:
      return (
        <WindSpeedLegend tab={windLegendTabs} setTab={setWindLegendTabs} />
      );
    case WindLegendTabs.HEIGHT:
      return <HeightSettings tab={windLegendTabs} setTab={setWindLegendTabs} />;
    case WindLegendTabs.SOURCE:
      return <SourceSettings tab={windLegendTabs} setTab={setWindLegendTabs} />;
    case WindLegendTabs.EXTENT:
      return (
        <WindSpeedExtent tab={windLegendTabs} setTab={setWindLegendTabs} />
      );
    default:
      return (
        <WindSpeedLegend tab={windLegendTabs} setTab={setWindLegendTabs} />
      );
  }
};

const SiteLocatorLegend = styled(Legend)`
  background: linear-gradient(90deg, ${SITELOCATOR_COLORS.join(",")});
`;

const LegendsWrapperOffshore = () => {
  const lowerRightActiveMode = useAtomValue(lowerRightMenuActiveModeAtom);
  const [leftMenuActiveMode] = useDrawMode();
  const meanSpeedGridActive = useAtomValue(showMeanSpeedGridAtom);
  const speedLimits = useAtomValue(meanSpeedGridLimitsAtom);
  const bathymetryStyle = useAtomValue(activeBathymetryStyleIdAtom);
  const otherMapStyle = useAtomValue(activeOtherMapIdAtom);

  if (meanSpeedGridActive) {
    const lower = speedLimits ? `${speedLimits[0].toFixed(1)} m/s` : "Lower";
    const higher = speedLimits ? `${speedLimits[1].toFixed(1)} m/s` : "Higher";
    return (
      <WindLegend>
        <TabWrapper>
          <LegendTitle>Wind speed</LegendTitle>
        </TabWrapper>
        <span
          style={{
            color: "white",
          }}
        >
          {lower}
        </span>
        <span>{higher}</span>
      </WindLegend>
    );
  }

  if (leftMenuActiveMode === SiteLocatorMenuType) {
    return (
      <SiteLocatorLegend>
        <TabWrapper>
          <LegendTitle>Cost</LegendTitle>
        </TabWrapper>
        <span
          style={{
            color: "white",
          }}
        >
          Lower €/Mwh
        </span>
        <span>Higher €/Mwh</span>
      </SiteLocatorLegend>
    );
  }

  if (lowerRightActiveMode === "cost") {
    return (
      <CostLegend>
        <TabWrapper>
          <LegendTitle>Cost</LegendTitle>
        </TabWrapper>
        <span>Lower €/MWh</span>
        <span>Higher €/MWh</span>
      </CostLegend>
    );
  }

  if (otherMapStyle === "wind") {
    return <WindSpeedInfo />;
  }

  if (bathymetryStyle === "contour") {
    return <BathymetryInfo />;
  }

  if (bathymetryStyle === "raster") {
    return <RasterBathymetryInfo />;
  }
  return null;
};

const LegendsWrapperOnshore = () => {
  const otherMapStyle = useAtomValue(activeOtherMapIdAtom);

  if (otherMapStyle === "wind") {
    return <WindSpeedInfo />;
  }

  return <HeightTerrainInfo />;
};

const LegendsWrapper = () => {
  const onshore = useAtomValue(isOnshoreAtom);
  return onshore ? <LegendsWrapperOnshore /> : <LegendsWrapperOffshore />;
};

export default LegendsWrapper;
