import { useSearchAppObjects } from "./useSearchAppObjects";
import { useSearchIntercom } from "./useSearchIntercom";
import { useSearchLibraryAnalysis } from "./useSearchLibraryAnalysis";
import { useSearchLibraryCables } from "./useSearchLibraryCables";
import { useSearchLibraryDataPackage } from "./useSearchLibraryDataPackage";
import { useSearchLibraryExportCables } from "./useSearchLibraryExportCables";
import { useSearchLibraryFinancials } from "./useSearchLibraryFinancials";
import { useSearchLibraryFoundations } from "./useSearchLibraryFoundations";
import { useSearchLibrarySubstations } from "./useSearchLibrarySubstations";
import { useSearchLibraryTurbines } from "./useSearchLibraryTurbines";
import { useSearchProjects } from "./useSearchProjects";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useSearchFeatures } from "./useSearchFeatures";
import { useSearchMapbox } from "./useSearchMapbox";
import { mapAtom } from "state/map";
import { v4 as uuidv4 } from "uuid";

const sessionToken = uuidv4();

export function useSearchDataProject({
  organisationId,
  search,
}: {
  organisationId: string;
  search: string;
}) {
  const map = useAtomValue(mapAtom);

  const { filteredAppObjects, searchableItems: appObjectItems } =
    useSearchAppObjects(true, search);
  const { filteredNodes, searchableItems: nodeItems } = useSearchProjects(
    organisationId,
    search,
  );
  const { filteredTurbines, searchableItems: turbineItems } =
    useSearchLibraryTurbines(organisationId, search);
  const { filteredFoundations, searchableItems: foundationItems } =
    useSearchLibraryFoundations(organisationId, search);
  const { filteredCables, searchableItems: cableItems } =
    useSearchLibraryCables(organisationId, search);
  const { filteredExportCables, searchableItems: exportCableItems } =
    useSearchLibraryExportCables(organisationId, search);
  const { filteredSubstations } = useSearchLibrarySubstations(
    organisationId,
    search,
  );
  const { filteredAnalysis, searchableItems: analysisItems } =
    useSearchLibraryAnalysis(organisationId, search);
  const { filteredFinancials, searchableItems: financialItems } =
    useSearchLibraryFinancials(organisationId, search);
  const { filteredDataPackages, searchableItems: dataPackageItems } =
    useSearchLibraryDataPackage(organisationId, search);
  const { articles, searchIntercomInProgress } = useSearchIntercom(search);
  const { mapboxSuggestions, searchMapboxAddressInProgress } = useSearchMapbox(
    search,
    map,
    sessionToken,
  );

  const { filteredFeatures, searchableItems: featureItems } =
    useSearchFeatures(search);

  const searchableItems = useMemo(() => {
    return [
      ...(appObjectItems ?? []),
      ...(nodeItems ?? []),
      ...(turbineItems ?? []),
      ...(foundationItems ?? []),
      ...(cableItems ?? []),
      ...(exportCableItems ?? []),
      ...(featureItems ?? []),
      ...(analysisItems ?? []),
      ...(financialItems ?? []),
      ...(dataPackageItems ?? []),
    ];
  }, [
    appObjectItems,
    nodeItems,
    turbineItems,
    foundationItems,
    cableItems,
    exportCableItems,
    featureItems,
    analysisItems,
    financialItems,
    dataPackageItems,
  ]);

  const searchGroups = useMemo(() => {
    const groupsConfig = [
      { title: "Actions", items: filteredAppObjects },
      { title: "Projects and folders", items: filteredNodes },
      { title: "Library turbines", items: filteredTurbines },
      { title: "Library foundations", items: filteredFoundations },
      { title: "Library cables", items: filteredCables },
      { title: "Library export cables", items: filteredExportCables },
      { title: "Library substations", items: filteredSubstations },
      { title: "Library analysis", items: filteredAnalysis },
      { title: "Library financials", items: filteredFinancials },
      { title: "Library GIS packages", items: filteredDataPackages },
      { title: "Features", items: filteredFeatures },
      {
        title: "Read more",
        items: articles,
        loading: searchIntercomInProgress,
      },
      {
        title: "Mapbox",
        items: mapboxSuggestions,
        loading: searchMapboxAddressInProgress,
      },
    ];

    const groups = groupsConfig
      .filter((group) => group.items.length > 0)
      .map(({ title, items, loading }) => {
        // Find min score (best match)
        let minScore = items[0].score;
        for (let i = 1; i < items.length; i++) {
          if (items[i].score < minScore) {
            minScore = items[i].score;
          }
        }
        return {
          title,
          items: items.sort((a, b) => a.score - b.score),
          bestScore: minScore,
          loading,
        };
      })
      .sort((a, b) => a.bestScore - b.bestScore);

    return groups;
  }, [
    filteredAppObjects,
    filteredNodes,
    filteredTurbines,
    filteredFoundations,
    filteredCables,
    filteredExportCables,
    filteredSubstations,
    filteredAnalysis,
    filteredFinancials,
    filteredDataPackages,
    filteredFeatures,
    articles,
    searchIntercomInProgress,
    mapboxSuggestions,
    searchMapboxAddressInProgress,
  ]);

  return {
    searchGroups,
    searchableItems,
    someItemsAreLoading:
      searchIntercomInProgress || searchMapboxAddressInProgress,
  };
}
