import { useCallback, useMemo } from "react";
import {
  ABLY_PORTFOLIO_PARK_ADDED,
  ABLY_PORTFOLIO_PARK_REMOVED,
} from "state/ably";
import usePortfolioService from "hooks/usePortfolioService";
import { useAblyGeneric } from "./useAblyGeneric";

const useAblyPortfolio = (organisationId: string) => {
  const { refreshPortfolio } = usePortfolioService();

  const channelName = useMemo(
    () => organisationId && `${organisationId}:all`,
    [organisationId],
  );

  const onParkAddedMessage = useCallback(() => {
    refreshPortfolio();
  }, [refreshPortfolio]);

  const onParkRemovedMessage = useCallback(() => {
    refreshPortfolio();
  }, [refreshPortfolio]);

  const events = useMemo(
    () => [
      {
        eventName: ABLY_PORTFOLIO_PARK_ADDED,
        onMessageReceived: onParkAddedMessage,
      },
      {
        eventName: ABLY_PORTFOLIO_PARK_REMOVED,
        onMessageReceived: onParkRemovedMessage,
      },
    ],
    [onParkAddedMessage, onParkRemovedMessage],
  );

  useAblyGeneric(channelName, events, organisationId);
};

export default useAblyPortfolio;
