export const foundations3dLayerId = "foundations3d-layer";

export const TURBINE_PROPERTY_TYPE = "park-layout-turbine";
export const EXISTING_TURBINE_PROPERTY_TYPE = "existing-turbine";

export const ANCHOR_PROPERTY_TYPE = "anchor";
export const MOORING_LINE_PROPERTY_TYPE = "mooring-line";
export const SLACK_REGION_PROPERTY_TYPE = "slack-region";
export const STATIC_OFFSET_REGION_PROPERTY_TYPE = "static-offset-region";
export const TOUCHDOWN_PROPERTY_TYPE = "touchdown-point";
export const GRID_CONNECTION_POINT_TYPE = "grid-connection-point";
export const PORT_POINT_PROPERTY_TYPE = "port-point";
export const SENSOR_POINT_PROPERTY_TYPE = "sensor-point";
export const VIEWPOINT_PROPERTY_TYPE = "view-from-shore-view-point";

export const TurbinesMenuType = "turbines-menu-type";
export const GenerateWindTurbinesAndFoundationsMenuType =
  "GenerateWindTurbines";
export const GenerateAnchorsMenuType = "GenerateAnchors";
export const GenerateFoundationsMenuType = "GenerateFoundations";
export const AreaMenuType = "Area";
export const ConstraintsMenuType = "Constraints";
export const AddFeaturesMenuType = "AddFeatures";
export const SiteLocatorMenuType = "SiteLocator";

export const anchorsBufferZoneLayerId = "anchors-buffer-zone-layer-id";
export const anchorsBufferZoneSourceId = "anchors-buffer-zone-source-id";
export const mooringBufferZoneLayerId = "mooring-buffer-zone-layer-id";
export const mooringBufferZoneSourceId = "mooring-buffer-zone-source-id";
export const touchdownBufferZoneLayerId = "touchdown-buffer-zone-layer-id";
export const touchdownBufferZoneSourceId = "touchdown-buffer-zone-source-id";
export const turbineBufferZoneLayerId = "turbine-buffer-zone-layer-id";
export const turbineBufferZoneSourceId = "turbine-buffer-zone-source-id";
