import { useAtom, useSetAtom } from "jotai";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useGoToFeatures } from "../../hooks/map";
import ThreeDIcon from "@icons/24/SideBarIcons/3D.svg?react";
import HelpIcon from "@icons/24/Help.svg?react";
import Bulb from "@icons/24/Bulb.svg?react";
import Filter from "@icons/24/SideBarIcons/Filter.svg?react";
import Wind from "@icons/24/SideBarIcons/Wind.svg?react";
import Wave from "@icons/24/SideBarIcons/Wave.svg?react";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import {
  designToolTypeAtom,
  currentMapStyleAtom,
  mapAtom,
} from "../../state/map";
import { replaceOrUndefined } from "../ControlPanels/utils";
import Tooltip from "../General/Tooltip";
import FilterInput, { FilterMenuType } from "./FilterInput";
import { modalTypeOpenAtom } from "../../state/modal";
import LegendsWrapper from "./LegendsWrapper";
import Hints, { HintsMenuType } from "./Hints";
import {
  BlueHelpPill,
  HelpScaleBarWrapper,
  HelpText,
  HelpToolsWrapper,
  LowerRightColumnWrapperV2,
  RowWrapperV2,
} from "./LowerRight.style";
import { parkIdAtom } from "state/pathParams";
import { Divider, Icon, IconMenu } from "components/General/Icons";
import { MapStyleFrame } from "./MapStyle";
import SearchWrapper from "components/Search/SearchWrapper";
import { WindSources } from "components/WindWaveSource/WindSource";
import { WaveSources } from "components/WindWaveSource/WaveSource";
import { BottomMenuPositionWrapper } from "components/MenuPopup/CloseableMenuPopup";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import CostInput from "./CostLayer";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { typography } from "styles/typography";
import { spacing1 } from "styles/space";
import { TourStep } from "components/OnboardingTours/TourStep";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { rightModalOpenStateAtom } from "components/Design/state";
import { useAtomValue } from "jotai";
import { currentParkAtom } from "state/jotai/park";
import { DesignToolMode } from "types/map";
import { isOnshoreAtom } from "state/onshore";
import FilterOnshoreInput, {
  FilterOnshoreMenuType,
} from "./FilterOnshoreInput";
import ResetMapOrientation from "./ResetMapOrientation";
import { MIN_ZOOM_TURBINES_VISIBLE } from "components/MapFeatures/3Dturbines2";

const LowerRightTools = forwardRef<HTMLDivElement>((_prop, ref) => {
  const onshore = useAtomValue(isOnshoreAtom);
  const map = useAtomValue(mapAtom);
  const [lowerRightActiveMode, setLowerRightActiveMode] = useAtom(
    lowerRightMenuActiveModeAtom,
  );
  const setRightModalOpenState = useSetAtom(rightModalOpenStateAtom);
  const park = useAtomValue(currentParkAtom);
  const mode = useAtomValue(designToolTypeAtom);
  const goToFeatures = useGoToFeatures(map);
  const [isIn3d, setIsIn3d] = useState(false);

  useEffect(() => {
    if (isIn3d) {
      // When the automatic pitching to 3d is done, set once-cb to reset isIn3d
      map?.once("pitchend", () => {
        map.once("pitch", () => {
          setIsIn3d(false);
        });
      });
    }
  }, [isIn3d, map]);

  const activeMapStyle = useAtomValue(currentMapStyleAtom);

  const modalOpen = useAtomValue(modalTypeOpenAtom);
  useEffect(() => {
    if (modalOpen) setLowerRightActiveMode(undefined);
  }, [modalOpen, setLowerRightActiveMode]);

  return (
    <IconMenu
      id="lower-right-tools-menu"
      sideBar
      iconSize="2.4rem"
      ref={ref}
      style={{
        paddingBottom: 0,
        paddingTop: spacing1,
      }}
    >
      {park && (
        <SearchWrapper
          title="3D view"
          tags={["camera", "3d", "three"]}
          id="search-three-d"
          icon={<ThreeDIcon />}
          onSelect={() => {
            if (isIn3d) {
              map?.easeTo({
                pitch: 0,
                duration: 500,
              });
              return;
            }
            setIsIn3d(true);
          }}
        >
          <SideBarIconBtn
            id="button-three-d"
            active={isIn3d}
            onClick={(e) => {
              if (isIn3d) {
                map?.easeTo({
                  pitch: 0,
                  duration: 500,
                });
                return;
              }
              setIsIn3d(true);
              if (!park) return;
              goToFeatures([park], e, {
                zoom: Math.max(MIN_ZOOM_TURBINES_VISIBLE, map?.getZoom() ?? 0),
                pitch: 75,
                bearing: map?.getBearing(),
                duration: 500,
              });
            }}
            icon={<ThreeDIcon />}
          >
            <p style={typography.graphics}>3D</p>
          </SideBarIconBtn>
        </SearchWrapper>
      )}

      <ResetMapOrientation />
      <Divider />
      <SearchWrapper
        title="Map layers"
        tags={["map", "layers", "style", "bathymetry", "satellite"]}
        id="search-map-layers"
        icon={
          <img
            style={{
              borderRadius: "2px",
              width: "2.6rem",
              height: "2.6rem",
            }}
            src={activeMapStyle?.image}
            alt="Active bathymetry style"
          />
        }
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("mapLayers"));
          setRightModalOpenState(undefined);
        }}
      >
        <SideBarIconBtn
          id="button-map-layers"
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("mapLayers"));
            setRightModalOpenState(undefined);
          }}
          icon={
            <img
              style={{
                borderRadius: "2px",
                width: "2.6rem",
                height: "2.6rem",
              }}
              src={activeMapStyle?.image}
              alt="Active bathymetry style"
            />
          }
          active={
            lowerRightActiveMode === "mapLayers" ||
            lowerRightActiveMode === "cost"
          }
        >
          <p style={typography.graphics}>Map</p>
        </SideBarIconBtn>
      </SearchWrapper>
      <SearchWrapper
        title="Open wind measurement"
        tags={["waves", "nora3", "era5", "flag"]}
        id="search-wind-measurement"
        icon={<Wind />}
        onSelect={() => {
          setLowerRightActiveMode(replaceOrUndefined("windMeasurement"));
          setRightModalOpenState(undefined);
        }}
      >
        <SideBarIconBtn
          id="button-wind-measurement"
          active={lowerRightActiveMode === "windMeasurement"}
          onClick={() => {
            setLowerRightActiveMode(replaceOrUndefined("windMeasurement"));
            setRightModalOpenState(undefined);
          }}
          icon={<Wind />}
        >
          <p style={typography.graphics}>Wind</p>
        </SideBarIconBtn>
      </SearchWrapper>
      {mode === DesignToolMode.Offshore && (
        <SearchWrapper
          title="Open wave sampler"
          tags={["waves", "nora3", "era5", "flag"]}
          id="search-wave-sampler"
          icon={<Wave />}
          onSelect={() => {
            setLowerRightActiveMode(replaceOrUndefined("waveSampler"));
            setRightModalOpenState(undefined);
          }}
        >
          <SideBarIconBtn
            id="button-wave-sampler"
            active={lowerRightActiveMode === "waveSampler"}
            onClick={() => {
              setLowerRightActiveMode(replaceOrUndefined("waveSampler"));
              setRightModalOpenState(undefined);
            }}
            icon={<Wave />}
          >
            <p style={typography.graphics}>Wave</p>
          </SideBarIconBtn>
        </SearchWrapper>
      )}

      {!onshore && (
        <>
          <Divider />
          <SearchWrapper
            title="Open map filter"
            tags={[
              "shoreline",
              "depth filter",
              "windspeed filter",
              "map layer",
              "constraint",
              "distance",
              "wind",
              "speed",
            ]}
            id="search-map-filter"
            icon={<Filter />}
            onSelect={() => {
              setLowerRightActiveMode(replaceOrUndefined(FilterMenuType));
              setRightModalOpenState(undefined);
            }}
          >
            <SideBarIconBtn
              id="button-map-filter"
              active={lowerRightActiveMode === FilterMenuType}
              onClick={() => {
                setLowerRightActiveMode(replaceOrUndefined(FilterMenuType));
                setRightModalOpenState(undefined);
              }}
              icon={<Filter />}
            >
              <p style={typography.graphics}>Filter</p>
            </SideBarIconBtn>
          </SearchWrapper>
          <Divider />
        </>
      )}
      {onshore && (
        <>
          <Divider />
          <SearchWrapper
            title="Open map filter"
            tags={["residential", "house filter", "constraint", "distance"]}
            id="search-onshore-map-filter"
            icon={<Filter />}
            onSelect={() => {
              setLowerRightActiveMode(
                replaceOrUndefined(FilterOnshoreMenuType),
              );
              setRightModalOpenState(undefined);
            }}
          >
            <SideBarIconBtn
              id="button-onshore-map-filter"
              active={lowerRightActiveMode === FilterOnshoreMenuType}
              onClick={() => {
                setLowerRightActiveMode(
                  replaceOrUndefined(FilterOnshoreMenuType),
                );
                setRightModalOpenState(undefined);
              }}
              icon={<Filter />}
            >
              <p style={typography.graphics}>Filter</p>
            </SideBarIconBtn>
          </SearchWrapper>
          <Divider />
        </>
      )}
    </IconMenu>
  );
});

const LowerRightToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    const map = useAtomValue(mapAtom);
    const parkId = useAtomValue(parkIdAtom);
    const [lowerRightActiveMode, setLowerRightActiveMode] = useAtom(
      lowerRightMenuActiveModeAtom,
    );

    const trackEvent = useTrackEvent();

    const helpScaleBarWrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (!map || !helpScaleBarWrapperRef.current) return;
      const mapboxScaleElement = document.querySelector<HTMLDivElement>(
        ".mapboxgl-ctrl.mapboxgl-ctrl-scale",
      );

      if (!mapboxScaleElement) return;

      helpScaleBarWrapperRef.current.insertBefore(
        mapboxScaleElement,
        helpScaleBarWrapperRef.current.firstChild,
      );
    }, [map]);

    const { refs, floatingStyles, context } = useFloating({
      placement: "top",
      middleware: [
        offset({
          mainAxis: 20,
        }),
      ],
    });

    return (
      <LowerRightColumnWrapperV2>
        <RowWrapperV2>
          <LegendsWrapper />
          <FloatingFocusManager context={context} modal={false}>
            <TourStep
              tourId="general-intro-tour"
              stepId="beforeYouGo"
              innerRef={refs.setFloating}
              style={floatingStyles}
            />
          </FloatingFocusManager>
          <FloatingFocusManager context={context} modal={false}>
            <TourStep
              tourId="onshore-intro-tour"
              stepId="beforeYouGo"
              innerRef={refs.setFloating}
              style={floatingStyles}
            />
          </FloatingFocusManager>
          <HelpScaleBarWrapper ref={helpScaleBarWrapperRef}>
            <HelpToolsWrapper>
              <HighlightStep
                tourId="general-intro-tour"
                stepId="beforeYouGo"
                padding="4px"
              >
                <HighlightStep
                  tourId="onshore-intro-tour"
                  stepId="beforeYouGo"
                  padding="4px"
                >
                  <Tooltip text="Open help center" position="top">
                    <BlueHelpPill
                      id="help_icon_link"
                      ref={refs.setReference}
                      onClick={() => trackEvent("clickedHelp")}
                    >
                      <Icon size="1.8rem" stroke="white">
                        <HelpIcon />
                      </Icon>
                      <HelpText>Help</HelpText>
                    </BlueHelpPill>
                  </Tooltip>
                </HighlightStep>
              </HighlightStep>

              <Tooltip position="top" text="Hints">
                <SearchWrapper
                  title="Open Hints"
                  tags={[
                    "learn",
                    "discover",
                    "draw tips",
                    "tips and tricks",
                    "new",
                    "tutorial",
                    "beginner",
                    "onboarding",
                  ]}
                  id="search-hints"
                  icon={<Bulb />}
                  onSelect={() => {
                    setLowerRightActiveMode(replaceOrUndefined(HintsMenuType));
                  }}
                >
                  <BlueHelpPill
                    onClick={() =>
                      setLowerRightActiveMode(replaceOrUndefined(HintsMenuType))
                    }
                  >
                    <Icon size="1.8rem" fill="white">
                      <Bulb />
                    </Icon>
                  </BlueHelpPill>
                </SearchWrapper>
              </Tooltip>
            </HelpToolsWrapper>
          </HelpScaleBarWrapper>
          <LowerRightTools />
        </RowWrapperV2>
        <BottomMenuPositionWrapper parkSelected={!!parkId}>
          {lowerRightActiveMode === FilterMenuType && <FilterInput />}
          {lowerRightActiveMode === FilterOnshoreMenuType && (
            <FilterOnshoreInput />
          )}
          {lowerRightActiveMode === "windMeasurement" && (
            <WindSources onClose={() => setLowerRightActiveMode(undefined)} />
          )}
          {lowerRightActiveMode === "waveSampler" && (
            <WaveSources onClose={() => setLowerRightActiveMode(undefined)} />
          )}
          {lowerRightActiveMode === "cost" && <CostInput />}

          {lowerRightActiveMode === HintsMenuType && <Hints />}
          {lowerRightActiveMode === "mapLayers" && <MapStyleFrame />}
        </BottomMenuPositionWrapper>
      </LowerRightColumnWrapperV2>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default LowerRightToolsMenuV2;
