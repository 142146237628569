import { RenderTurbineCableZones } from "components/Mapbox/TurbineCableZone";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { featureMapFamily } from "state/jotai/features";
import {
  selectedSubstationsAtom,
  selectedTurbinesAtom,
} from "state/jotai/selection";
import { editFeaturesAtom, mapAtom } from "state/map";
import { SubstationFeature, TurbineFeature } from "types/feature";
import { isCable, isSubstation, isTurbine } from "utils/predicates";

/**
 * Render the cable free sectors for the selected turbines and substations.
 */
export const CableFreeSectorFromSelection = () => {
  const map = useAtomValue(mapAtom);
  const turbines = useAtomValue(selectedTurbinesAtom);
  const substations = useAtomValue(selectedSubstationsAtom);

  const features = useMemo(
    () =>
      (turbines as (TurbineFeature | SubstationFeature)[]).concat(substations),
    [substations, turbines],
  );

  if (!map) return null;
  return <RenderTurbineCableZones map={map} features={features} />;
};

export const CableFreeSectorWhenEditing = () => {
  const map = useAtomValue(mapAtom);
  const editFeatures = useAtomValue(editFeaturesAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );

  const features = useMemo(() => {
    const adjacent: (TurbineFeature | SubstationFeature)[] = [];
    for (const id of editFeatures) {
      const f = featureMap.get(id);
      if (!f) continue;
      if (isCable(f)) {
        const from = featureMap.get(f.properties.fromId);
        if (isTurbine(from) || isSubstation(from)) adjacent.push(from);
        const to = featureMap.get(f.properties.toId);
        if (isTurbine(to) || isSubstation(to)) adjacent.push(to);
      }
    }
    return adjacent;
  }, [editFeatures, featureMap]);

  if (!map || !features.length) return null;
  return <RenderTurbineCableZones map={map} features={features} />;
};
