import { fetchEnhancerWithToken } from "./utils";
import { MesoWindDataSource } from "types/metocean";

export const getWindTile = async (
  x: number,
  y: number,
  z: number,
  height: number,
  source: MesoWindDataSource,
) =>
  fetchEnhancerWithToken(
    `/tiles/${source}/speed/${height}/${z}/${x}/${y}.png`,
    {
      method: "get",
      headers: {},
    },
  );
