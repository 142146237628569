import Dropdown from "components/Dropdown/Dropdown";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { Input } from "components/General/Input";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import {
  ColumnSection,
  TitleContainer,
  HorizontalLine,
} from "components/SettingsV2/Shared/styles";
import {
  isSimpleJacket,
  isDetailedJacket,
  isSimpleMonopile,
  isDetailedMonopile,
} from "utils/predicates";
import { CommonReferenceNumbersProps } from "./ReferenceNumbers/ReferenceNumberProps";
import { FoundationType } from "types/foundations";

interface BasicSettingsProps extends CommonReferenceNumbersProps {
  tempFoundation: FoundationType;
  isDefault: boolean;
  foundationName: string;
}

const BasicSettings = ({
  tempFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
  isDefault,
  foundationName,
  disabled,
}: BasicSettingsProps) => {
  return (
    <ColumnSection
      style={{
        padding: 0,
      }}
    >
      <TitleContainer>
        <Subtitle>General info</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Name</SecondaryText>
          <ChangelogAndCommentWrapper
            changelogInfo={foundationChangelogInfo(
              tempFoundation.id,
              nodeId,
              "name",
              commentCategory,
            )}
            style={{
              width: "100%",
            }}
            disabled={disabled}
          >
            <Input
              type="string"
              disabled={disabled}
              value={isDefault ? `${foundationName}` : tempFoundation.name}
              onChange={(e) => {
                setTempFoundation((curr) => ({
                  ...curr,
                  name: e.target.value,
                }));
              }}
              style={{
                width: "42.4rem",
              }}
            />
          </ChangelogAndCommentWrapper>
        </Label>

        <Label>
          <SecondaryText>Primary material</SecondaryText>
          <ChangelogAndCommentWrapper
            changelogInfo={foundationChangelogInfo(
              tempFoundation.id,
              nodeId,
              "material",
              commentCategory,
            )}
            style={{
              width: "100%",
            }}
            disabled={disabled}
          >
            <Dropdown
              id="primary-material"
              disabled={
                disabled ||
                isSimpleJacket(tempFoundation) ||
                isDetailedJacket(tempFoundation) ||
                isSimpleMonopile(tempFoundation) ||
                isDetailedMonopile(tempFoundation)
              }
              value={tempFoundation.material}
              onChange={(e) => {
                setTempFoundation((curr) => ({
                  ...curr,
                  material: e.target.value,
                }));
              }}
              style={{
                width: "20rem",
              }}
            >
              <option value={"Steel"}>{"Steel"}</option>
              <option value={"Concrete"}>{"Concrete"}</option>
            </Dropdown>
          </ChangelogAndCommentWrapper>
        </Label>
      </div>
    </ColumnSection>
  );
};

export default BasicSettings;
