import InfoIcon from "@icons/24/Information.svg?react";
import { currentYear } from "utils/inflation";
import { colors } from "styles/colors";
import styled from "styled-components";
import { Tag } from "components/General/Tag";

const TagWrapper = styled.span`
  svg {
    path {
      fill: unset !important;
      stroke: ${colors.iconInfo};
    }
  }
`;

export const RealValueInfoTag = ({ text }: { text: string }) => (
  <TagWrapper>
    <Tag text="Real" icon={<InfoIcon />} tooltip={text} />
  </TagWrapper>
);

export const PVInfoTag = ({ text = "" }: { text?: string }) => {
  const year = currentYear();
  return (
    <TagWrapper>
      <Tag
        text="PV"
        icon={<InfoIcon />}
        tooltip={"Present value in " + year + " terms. " + text}
      />
    </TagWrapper>
  );
};

export const NominalInfoTag = () => (
  <TagWrapper>
    <Tag text="Nominal" icon={<InfoIcon />} />
  </TagWrapper>
);
