import { CostConfiguration } from "services/costService";
import { currentYear } from "./inflation";
import { OccuranceType } from "types/financial";
import { roundToDecimal, sum as utilSum } from "utils/utils";
import { scream } from "./sentry";
export type ComputationResult = { ok: number } | { error: string };
export type CashFlowAdjustment =
  | "raw"
  | "inflated"
  | "discounted"
  | "loanFinanced";

// Compute NPV given an array of numbers (multiple cash flows)
// representing net cash flow per year
export function npv(cashFlows: number[], discountRate: number): number {
  return cashFlows.reduce((acc, flow, year) => {
    return acc + flow / Math.pow(1 + discountRate, year);
  });
}

class Value {
  year: number;
  value: number;

  constructor(year: number, value: number) {
    this.year = year;
    this.value = value;
  }
}

export enum CostCategory {
  CAPEX = "capex",
  DEVEX = "devex",
  OPEX = "opex",
  DECOM = "decom",
  GUARANTEED_PRICE = "guaranteed_price",
  MARKET_PRICE = "market_price",
  PRODUCTION = "production",
}

type IrrParams = {
  lowerBound?: number;
  upperBound?: number;
  tolerance?: number;
  maxIterations?: number;
};

export type CashFlowType = {
  raw: () => Value[];
  inflated: () => Value[];
  discounted: () => Value[];
  loanFinanced: () => Value[];
  distributions: () => { id: string; name: string; percent: number }[];
};

export type OpexCost = {
  costPV: number;
  occurance: OccuranceType;
  occuranceYear?: number;
  name: string;
  id: string;
};

// Class to help compute metrics for a park
// with e.g. npv, inflated, etc.
export class ParkFinance {
  aepMWh: number;
  capacityMW: number;
  capexPV: number;
  devexPV: number;
  opexPV: OpexCost[];
  decomPV: number;
  guaranteedPricePV: number;
  marketPricePV: number;
  costConfig: CostConfiguration;
  parkLifeTime: number;

  private projectLifeTime: number;
  private presentYear: number;

  constructor({
    aepMWh,
    capacityMW,
    capexPV,
    devexPV,
    opexPV,
    decomPV,
    guaranteedPricePV,
    marketPricePV,
    costConfig,
  }: {
    aepMWh: number;
    capacityMW: number;
    capexPV: number;
    devexPV: number;
    opexPV: OpexCost[];
    decomPV: number;
    guaranteedPricePV: number;
    marketPricePV: number;
    costConfig: CostConfiguration;
  }) {
    this.aepMWh = aepMWh;
    this.capacityMW = capacityMW;
    this.costConfig = costConfig;
    this.capexPV = capexPV;
    this.devexPV = devexPV;
    this.opexPV = opexPV;
    this.decomPV = decomPV;
    this.guaranteedPricePV = guaranteedPricePV;
    this.marketPricePV = marketPricePV;

    this.parkLifeTime =
      this.costConfig.lifeCycle.decomissioning -
      this.costConfig.lifeCycle.operationStart;

    this.projectLifeTime =
      this.costConfig.lifeCycle.decomissioning -
      this.costConfig.lifeCycle.projectStart +
      1;

    this.presentYear = currentYear();
  }

  get guaranteedPrice() {
    return this.cost(CostCategory.GUARANTEED_PRICE);
  }
  get marketPrice() {
    return this.cost(CostCategory.MARKET_PRICE);
  }
  get capex() {
    return this.cost(CostCategory.CAPEX);
  }
  get devex() {
    return this.cost(CostCategory.DEVEX);
  }
  get opex() {
    return this.cost(CostCategory.OPEX);
  }
  get decom() {
    return this.cost(CostCategory.DECOM);
  }

  lcoe(): number | undefined {
    const npvDecom = this.cost(CostCategory.DECOM).npv();
    const npvCapex = this.cost(CostCategory.CAPEX).npv();
    const npvOpex = this.cost(CostCategory.OPEX).npv();
    const npvDevex = this.cost(CostCategory.DEVEX).npv();

    const npvTotalCosts = npvDevex + npvCapex + npvOpex + npvDecom;
    const npvProduction = this.cost(CostCategory.PRODUCTION).npv();

    return npvProduction !== 0 ? npvTotalCosts / npvProduction : undefined;
  }

  irr({
    lowerBound = 0,
    upperBound = 5,
    tolerance = 0.0001,
    maxIterations = 100000,
  }: IrrParams = {}): ComputationResult {
    const cashFlow = this.netCashFlow();
    const npvPartial = npv.bind(null, cashFlow);
    try {
      return {
        ok: brent(npvPartial, lowerBound, upperBound, tolerance, maxIterations),
      };
    } catch (error) {
      if (error instanceof BrentComputationError) {
        return {
          error: "Failed to compute IRR with error: " + error.message,
        };
      } else {
        throw error;
      }
    }
  }

  netOutflow(): number[] {
    const capex = this.cost(CostCategory.CAPEX).cashFlow().inflated();
    const devex = this.cost(CostCategory.DEVEX).cashFlow().inflated();
    const opex = this.cost(CostCategory.OPEX).cashFlow().inflated();
    const decom = this.cost(CostCategory.DECOM).cashFlow().inflated();

    return [capex, devex, opex, decom].reduce(
      (acc, arr) => acc.map((value, index) => value - arr[index].value),
      Array.from({ length: this.projectLifeTime }, () => 0),
    );
  }

  netInflow(): number[] {
    const guaranteedPrice = this.cost(CostCategory.GUARANTEED_PRICE)
      .cashFlow()
      .inflated();
    const marketPrice = this.cost(CostCategory.MARKET_PRICE)
      .cashFlow()
      .inflated();
    return [guaranteedPrice, marketPrice].reduce(
      (acc, arr) => acc.map((value, index) => value + arr[index].value),
      Array.from({ length: this.projectLifeTime }, () => 0),
    );
  }

  netCashFlow(): number[] {
    const inflow = this.netInflow();
    const outflow = this.netOutflow();

    return [inflow, outflow].reduce(
      (acc, arr) => acc.map((value, index) => value + arr[index]),
      Array.from({ length: this.projectLifeTime }, () => 0),
    );
  }

  sum(flow: "in" | "out"): number {
    if (flow === "in") {
      return this.netInflow().reduce((acc, val) => acc + val, 0);
    } else {
      return this.netOutflow().reduce((acc, val) => acc + val, 0);
    }
  }

  cost(category: CostCategory) {
    type CostAndPhase = {
      name: string;
      id: string;
      totalPV: number;
      phasing: number[];
    };

    const { projectStart, operationStart, decomissioning } =
      this.costConfig.lifeCycle;
    const inflationRate = this.costConfig.inflation.inflationRate;
    const discountRate = this.costConfig.lcoe.discountRate;
    const guaranteedYears = this.costConfig.cashFlows.guaranteedYears;

    let phasing = Array.from({ length: this.projectLifeTime }, () => 0);
    let costAndPhase: CostAndPhase[] = [];

    switch (category) {
      case CostCategory.CAPEX:
        costAndPhase = [
          {
            phasing: this.costConfig.lifeCycle.phasing[category],
            totalPV:
              this.capexPV * (1 + this.costConfig.contingency.capex.value),
            name: "capex",
            id: "capex",
          },
        ];
        break;
      case CostCategory.DEVEX:
        costAndPhase = [
          {
            phasing: this.costConfig.lifeCycle.phasing[category],
            totalPV: this.devexPV,
            name: "devex",
            id: "devex",
          },
        ];
        break;
      case CostCategory.OPEX:
        costAndPhase = this.opexPV.map((opexCost) => {
          return {
            phasing: generatePhasing(
              this.projectLifeTime,
              projectStart,
              operationStart,
              opexCost.occurance,
              opexCost.occuranceYear,
            ),
            totalPV:
              opexCost.costPV * (1 + this.costConfig.contingency.opex.value),
            name: opexCost.name,
            id: opexCost.id,
          };
        });
        break;
      case CostCategory.PRODUCTION:
        phasing.fill(
          1.0,
          operationStart - projectStart,
          this.projectLifeTime - 1,
        );
        costAndPhase = [
          {
            phasing,
            totalPV: this.aepMWh,
            name: "production",
            id: "production",
          },
        ];
        break;
      case CostCategory.DECOM:
        phasing[decomissioning - projectStart] = 1.0;
        costAndPhase = [
          {
            phasing,
            totalPV: this.decomPV,
            name: "decom",
            id: "decom",
          },
        ];
        break;
      case CostCategory.GUARANTEED_PRICE:
        phasing.fill(
          1.0,
          operationStart - projectStart,
          operationStart - projectStart + guaranteedYears,
        );
        costAndPhase = [
          {
            phasing,
            totalPV: this.guaranteedPricePV,
            name: "guaranteed_price",
            id: "guaranteed_price",
          },
        ];
        break;
      case CostCategory.MARKET_PRICE:
        phasing.fill(
          1.0,
          operationStart - projectStart + guaranteedYears,
          decomissioning - projectStart,
        );
        costAndPhase = [
          {
            phasing,
            totalPV: this.marketPricePV,
            name: "market_price",
            id: "market_price",
          },
        ];
        break;
    }

    const aggregatePerYear = (cashFlows: Value[][]) => {
      let totalPhasedCashFlow: Value[] = [];
      for (
        let year = projectStart;
        year < projectStart + this.projectLifeTime;
        year++
      ) {
        totalPhasedCashFlow.push({
          value: utilSum(
            cashFlows.map(
              (cashFlow) => cashFlow.find((c) => c.year === year)?.value ?? 0,
            ),
          ),
          year: year,
        });
      }
      return totalPhasedCashFlow;
    };
    return {
      npv: (): number => {
        // the sum of inflated and discounted cashflow
        return this.cost(category).sum("discounted");
      },

      sum: (method: CashFlowAdjustment = "raw"): number => {
        const cashFlow = this.cost(category).cashFlow()[method]();
        return cashFlow.reduce((acc, val) => acc + val.value, 0);
      },

      cashFlow: (filterPositive: boolean = false): CashFlowType => {
        const conditionalFilter = (values: Value[]): Value[] =>
          filterPositive ? values.filter(({ value }) => value > 0) : values;

        return {
          raw: (): Value[] => {
            const cashFlows = costAndPhase.map((cp) =>
              calculatePhasedValues(
                cp.phasing,
                projectStart,
                decomissioning,
                cp.totalPV,
              ),
            );

            return conditionalFilter(aggregatePerYear(cashFlows));
          },

          inflated: (): Value[] => {
            const cashFlows = costAndPhase.map((cp) =>
              calculatePhasedValues(
                cp.phasing,
                projectStart,
                decomissioning,
                cp.totalPV,
              ),
            );
            const totalPhasedCashFlow = aggregatePerYear(cashFlows);

            return conditionalFilter(
              inflateValues(
                totalPhasedCashFlow,
                inflationRate,
                this.presentYear,
              ),
            );
          },

          discounted: (): Value[] => {
            // inflated and discounted
            const cashFlows = costAndPhase.map((cp) =>
              calculatePhasedValues(
                cp.phasing,
                projectStart,
                decomissioning,
                cp.totalPV,
              ),
            );

            const totalPhasedCashFlow = aggregatePerYear(cashFlows);

            const inflatedValues = inflateValues(
              totalPhasedCashFlow,
              inflationRate,
              this.presentYear,
            );
            return conditionalFilter(
              discountValues(inflatedValues, discountRate, this.presentYear),
            );
          },

          distributions: (): {
            id: string;
            name: string;
            percent: number;
          }[] => {
            // inflated and discounted
            const cashFlows = costAndPhase.map((cp) => {
              return {
                name: cp.name,
                id: cp.id,
                flow: conditionalFilter(
                  calculatePhasedValues(
                    cp.phasing,
                    projectStart,
                    decomissioning,
                    cp.totalPV,
                  ),
                ),
              };
            });

            const totalPhasedCashFlow = aggregatePerYear(
              cashFlows.map((f) => f.flow),
            );

            const inflatedTotal = inflateValues(
              totalPhasedCashFlow,
              inflationRate,
              this.presentYear,
            );
            const discountedTotal = conditionalFilter(
              discountValues(inflatedTotal, discountRate, this.presentYear),
            );
            const total = discountedTotal.reduce(
              (acc, val) => acc + val.value,
              0,
            );

            return cashFlows.map((f) => {
              const inflatedValues = inflateValues(
                f.flow,
                inflationRate,
                this.presentYear,
              );
              const discountedValues = conditionalFilter(
                discountValues(inflatedValues, discountRate, this.presentYear),
              );
              const flowTotal = discountedValues.reduce(
                (acc, val) => acc + val.value,
                0,
              );
              return {
                name: f.name,
                id: f.id,
                percent: roundToDecimal((flowTotal / total) * 100, 1),
              };
            });
          },

          loanFinanced: (): Value[] => {
            if (category !== CostCategory.CAPEX) {
              throw new Error("Only CAPEX can be financed");
            }
            const capex = costAndPhase[0]; //Only one cost and phase value for CAPEX

            const { loanCapexFraction, loanInterestRate, loanRepaymentYears } =
              this.costConfig.cashFlows;

            const payments = calculateLoanPayments(
              capex.totalPV * loanCapexFraction,
              loanInterestRate,
              loanRepaymentYears,
            );

            // start the loan payment at the year of first CAPEX outflow
            const firstYear =
              projectStart + capex.phasing.findIndex((v) => v > 0);

            return conditionalFilter(
              padArrToValueArray(
                payments,
                this.projectLifeTime,
                projectStart,
                firstYear,
              ),
            );
          },
        };
      },
    };
  }
}

// Pad array to `length` with zeros
// Value years start at `offsetYear`,
// and array values start at `arrStartYear`
// e.g.
// >> arr = [1, 2, 3], length = 4, offsetYear = 2025, arrStartYear = 2026
// >> -> [Value(2025, 0), Value(2026, 1), Value(2027, 2), Value(2028, 3)]
function padArrToValueArray(
  arr: number[],
  length: number,
  offsetYear: number,
  arrStartYear: number,
) {
  return Array.from({ length }, (_, index) => {
    const currentYear = offsetYear + index;
    const value =
      currentYear >= arrStartYear && currentYear - arrStartYear < arr.length
        ? arr[currentYear - arrStartYear]
        : 0;
    return new Value(currentYear, value);
  });
}

function calculatePhasedValues(
  phasing: number[],
  projectStart: number,
  decomissioning: number,
  totalPV: number,
): Value[] {
  return Array.from(
    { length: decomissioning - projectStart + 1 },
    (_, index) => {
      const year = projectStart + index;
      const phaseValue = index < phasing.length ? phasing[index] : 0;
      const value = totalPV * phaseValue;
      return new Value(year, value);
    },
  );
}

function inflateValues(
  values: Value[],
  inflationRate: number,
  referenceYear: number,
): Value[] {
  return values.map((value) => {
    const { year, value: originalValue } = value;
    const inflatedValue =
      originalValue * Math.pow(1 + inflationRate, year - referenceYear);
    return new Value(year, inflatedValue);
  });
}

function discountValues(
  values: Value[],
  discountRate: number,
  referenceYear: number,
): Value[] {
  return values.map((value) => {
    const { year, value: originalValue } = value;
    const discountedValue =
      originalValue / Math.pow(1 + discountRate, year - referenceYear);
    return new Value(year, discountedValue);
  });
}

// Yearly payment; compunded monthly
export function calculateLoanPayments(
  principal: number,
  annualRate: number,
  years: number,
): number[] {
  let monthlyRate = annualRate / 12;
  let totalPayments = years * 12;
  let monthlyPayment =
    (principal * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -totalPayments));
  let balance = principal;
  let payments = [];

  for (let year = 1; year <= years; year++) {
    let annualPayment = 0;
    for (let month = 1; month <= 12; month++) {
      let interest = balance * monthlyRate;
      let principalPayment = monthlyPayment - interest;
      balance -= principalPayment;
      annualPayment += monthlyPayment;
    }
    payments.push(annualPayment);
  }
  return payments;
}

// Using Brent's method for finding an approximation to the IRR
// https://en.wikipedia.org/wiki/Brent%27s_method
//
// For our use case f will usually be a partial function
// that computes the net present value given a discount rate
export function brent(
  f: (x: number) => number,
  lowerBound: number,
  upperBound: number,
  tolerance: number,
  maxIterations: number,
): number {
  let a = lowerBound;
  let b = upperBound;
  let fa = f(a);
  let fb = f(b);

  if (fa * fb > 0) {
    throw new BrentComputationError(`Root is not bracketed: [${fa}, ${fb}].`);
  }

  if (Math.abs(fa) < Math.abs(fb)) {
    [a, b] = [b, a];
    [fa, fb] = [fb, fa];
  }

  let c = a;
  let fc = fa;
  let s = 0;
  let d = 0;
  let mflag = true;
  for (let i = 0; i < maxIterations; i++) {
    if (fb === 0 || Math.abs(b - a) <= tolerance) {
      return b;
    }

    if (fa !== fc && fb !== fc) {
      s =
        (a * fb * fc) / ((fa - fb) * (fa - fc)) +
        (b * fa * fc) / ((fb - fa) * (fb - fc)) +
        (c * fa * fb) / ((fc - fa) * (fc - fb));
    } else {
      s = b - fb * ((b - a) / (fb - fa));
    }

    if (
      (s - (3 * a + b) / 4) * (s - b) >= 0 ||
      (mflag && Math.abs(s - b) >= Math.abs(b - c) / 2) ||
      (!mflag && Math.abs(s - b) >= Math.abs(c - d) / 2) ||
      (mflag && Math.abs(b - c) < Math.abs(tolerance)) ||
      (!mflag && Math.abs(c - d) < Math.abs(tolerance))
    ) {
      s = (a + b) / 2;
      mflag = true;
    } else {
      mflag = false;
    }

    d = c;
    c = b;
    fc = fb;

    const fs = f(s);
    if (fa * fs < 0) {
      b = s;
      fb = fs;
    } else {
      a = s;
      fa = fs;
    }

    if (Math.abs(fa) < Math.abs(fb)) {
      [a, b] = [b, a];
      [fa, fb] = [fb, fa];
    }
  }

  throw new BrentComputationError(
    "Could not achieve required tolerance within iteration limit.",
  );
}

class BrentComputationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "BrentComputationError";
  }
}
function generatePhasing(
  projectLifeTime: number,
  projectStart: number,
  operationStart: number,
  occurance: OccuranceType,
  occuranceYear: number | undefined,
): number[] {
  const operationStartIndex = operationStart - projectStart;
  let phasing = Array.from({ length: projectLifeTime }, () => 0);

  if (occurance === OccuranceType.SingleEvent) {
    if (occuranceYear === undefined) throw new Error("Missing occuranceYear");
    phasing[operationStartIndex + occuranceYear - 1] = 1; // Note: occuranceYear is indexed 0, but we want the operationStart year to be year 1
    return phasing;
  }

  if (occurance === OccuranceType.Recurring) {
    if (occuranceYear === undefined) throw new Error("Missing occuranceYear");
    for (
      let i = operationStartIndex + occuranceYear - 1; // Note: occuranceYear is indexed 0, but we want the operationStart year to be year 1
      i < projectLifeTime - 1;
      i += occuranceYear
    ) {
      phasing[i] = 1;
    }
    return phasing;
  }

  if (occurance === OccuranceType.Yearly) {
    phasing.fill(1.0, operationStartIndex, projectLifeTime - 1);
    return phasing;
  }
  scream("Implement phasing for new occurance: ", occurance);
  throw Error("Implement phasing for new occurance");
}
