import DownloadIcon from "@icons/24/Download.svg?react";
import * as turf from "@turf/turf";
import { getAnalysisProgress, getStats } from "analysis/output";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import { SkeletonText } from "components/Loading/Skeleton";
import { binarySearchClosest } from "components/ProductionV2/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { stringify } from "csv-stringify/sync";
import { Feature, LineString, Point } from "geojson";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { CableType } from "services/cableTypeService";
import { cablesInParkFamily } from "state/jotai/cable";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";
import { ExportCableFeature } from "types/feature";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax, fastMin } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";

const computeCurrentNumbers = (
  exportCables: ExportCableFeature[],
  exportCableSegments: {
    exportCable: ExportCableFeature;
    offshore: Feature<LineString>;
    landfallPoint: Feature<Point>;
    onshore: Feature<LineString>;
  }[],
  exportCableTypes: Map<string, CableType>,
  exportCableCurrents: Record<string, number[]>,
  exportCableSegLengths: Record<string, number[]>,
  usedExportCableId: string,
) => {
  const selectedExportCableCurrents = exportCableCurrents[usedExportCableId];
  const selectedExportCableSegLengths =
    exportCableSegLengths[usedExportCableId];

  const completeCable = exportCables.find((c) => c.id === usedExportCableId);
  const offshoreCable = exportCableSegments.find(
    (c) => c.exportCable.id === usedExportCableId,
  )?.offshore;
  const cableHasLandfall = offshoreCable ? true : false;

  const offshoreCableTypeId = exportCables.find(
    (c) => c.id === usedExportCableId,
  )?.properties.cableTypeId;
  const onshoreCableTypeId = exportCables.find(
    (c) => c.id === usedExportCableId,
  )?.properties.onshoreCableTypeId;
  const offshoreRatedCurrent = exportCableTypes.get(
    offshoreCableTypeId ?? "",
  )?.ampacity;
  const onshoreRatedCurrent = exportCableTypes.get(
    onshoreCableTypeId ?? "",
  )?.ampacity;
  const exportSystemType = exportCableTypes.get(
    completeCable?.properties.cableTypeId ?? "",
  )?.exportCableType;

  if (
    !selectedExportCableCurrents ||
    !selectedExportCableSegLengths ||
    !completeCable ||
    offshoreRatedCurrent === undefined ||
    onshoreRatedCurrent === undefined ||
    !exportSystemType
  )
    return undefined;

  const offshoreLength = turf.length(offshoreCable ?? completeCable, {
    units: "kilometers",
  });

  const landfallIdx = binarySearchClosest(
    selectedExportCableSegLengths,
    offshoreLength,
  );
  const offshoreArrayLength = selectedExportCableSegLengths.slice(
    0,
    landfallIdx + 1,
  ).length;
  const onshoreArrayLength =
    selectedExportCableSegLengths.length - offshoreArrayLength;

  const offshoreRatedCurrentValues = offshoreRatedCurrent
    ? Array(offshoreArrayLength).fill(offshoreRatedCurrent * 1000)
    : null;
  const onshoreRatedCurrentValues = onshoreRatedCurrent
    ? Array(onshoreArrayLength).fill(onshoreRatedCurrent * 1000)
    : null;
  const ratedCurrentValues =
    offshoreRatedCurrentValues && onshoreRatedCurrentValues
      ? [...offshoreRatedCurrentValues, ...onshoreRatedCurrentValues]
      : null;

  return {
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
    ratedCurrentValues,
    offshoreLength,
    cableHasLandfall,
    exportSystemType,
  };
};

const ExportCableLoading = ({
  usedExportCableId,
  setUsedExportCableId,
}: {
  usedExportCableId: string | undefined;
  setUsedExportCableId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const { park, branch, configuration, triggerId } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId }),
  );
  const cables = useAtomValue(cablesInParkFamily({ parkId, branchId }));
  const exportCableSegments = useAtomValue(
    exportCableSplitsOkFamily({ parkId, branchId }),
  );
  const exportCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );

  const exportSystemResult = useAtomValue(
    getStats(triggerId),
  )?.exportSystemStats;

  const exportCableCurrents = exportSystemResult?.exportCableCurrents;
  const exportCableSegLengths = exportSystemResult?.exportCableSegLengths;
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const stopped = stoppedReason ?? analysisStoppedReason;
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No inter-array cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!configuration.electrical.exportSystemLoss) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Export system losses are not enabled for the selected analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!usedExportCableId) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (!exportCableCurrents || !exportCableSegLengths) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  const res = computeCurrentNumbers(
    exportCables,
    exportCableSegments,
    exportCableTypes,
    exportCableCurrents,
    exportCableSegLengths,
    usedExportCableId,
  );

  if (!res) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={"Cannot find results for the selected export cable."}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const {
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
    ratedCurrentValues,
    offshoreLength,
    cableHasLandfall,
    exportSystemType,
  } = res;

  return (
    <>
      <Label style={{ width: "30%", padding: spaceMedium }}>
        <p>Export cable</p>
        <Dropdown
          style={{ flex: 1 }}
          onChange={(e) => setUsedExportCableId(e.target.value)}
          value={usedExportCableId}
        >
          {exportCables.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {`${c.properties.name}`}
              </option>
            );
          })}
        </Dropdown>
      </Label>
      <ExportCableLoadingInner
        selectedExportCableCurrents={selectedExportCableCurrents}
        selectedExportCableSegLengths={selectedExportCableSegLengths}
        offshoreLength={offshoreLength}
        ratedCurrentValues={ratedCurrentValues}
        cableHasLandfall={cableHasLandfall}
        exportSystemType={exportSystemType}
      />
    </>
  );
};

const ExportCableLoadingInner = ({
  selectedExportCableCurrents,
  selectedExportCableSegLengths,
  offshoreLength,
  ratedCurrentValues,
  cableHasLandfall,
  exportSystemType,
}: {
  selectedExportCableCurrents: number[];
  selectedExportCableSegLengths: number[];
  offshoreLength: number;
  ratedCurrentValues: number[] | null;
  cableHasLandfall: boolean;
  exportSystemType: "HVAC" | "HVDC";
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const landfallX = [offshoreLength, offshoreLength];
    const landfallY = [0, 10000];

    const consideredYMaxMinValues =
      exportSystemType === "HVAC" && ratedCurrentValues
        ? [...selectedExportCableCurrents, ...ratedCurrentValues]
        : selectedExportCableCurrents;

    const data = [
      {
        name: "Current at full load",
        y: selectedExportCableCurrents,
        x: selectedExportCableSegLengths,
        type: "scatter" as const,
        line: { color: colors.primary } as const,
        hovertemplate: "Position: %{x:,.0f} km<br />Current: %{y:,.0f} A",
        mode: "lines+markers",
      } as const,
      exportSystemType === "HVAC" && ratedCurrentValues
        ? ({
            name: "Rated current",
            y: ratedCurrentValues,
            x: selectedExportCableSegLengths,
            type: "scatter" as const,
            line: { shape: "vh", dash: "dash", color: "black" } as const,
            mode: "lines",
          } as const)
        : undefined,
      cableHasLandfall
        ? ({
            name: "Landfall",
            y: landfallY,
            x: landfallX,
            type: "scatter" as const,
            line: { color: colors.primaryDisabled, width: 1 } as const,
            mode: "lines",
          } as const)
        : undefined,
    ].filter(isDefined);

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0.5, y: 1.1, xanchor: "center", orientation: "h" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Distance from offshore substation [km]",
        range: [
          0,
          1.01 *
            selectedExportCableSegLengths[
              selectedExportCableSegLengths.length - 1
            ],
        ],
        autotick: true,
      },
      yaxis: {
        title: "Export cable current [A]",
        range: [
          fastMin(consideredYMaxMinValues) - 100,
          fastMax(consideredYMaxMinValues) + 100,
        ],
        autotick: true,
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [
    cableHasLandfall,
    graphRef,
    offshoreLength,
    ratedCurrentValues,
    selectedExportCableCurrents,
    selectedExportCableSegLengths,
    exportSystemType,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const ExportCableLoadingMenu = ({
  usedExportCableId,
}: {
  usedExportCableId: string | undefined;
}) => {
  const { park, branch, triggerId } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId }),
  );
  const exportCableSegments = useAtomValue(
    exportCableSplitsOkFamily({ parkId, branchId }),
  );
  const exportCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );
  const exportSystemLoss = useAtomValue(getStats(triggerId))?.exportSystemStats;
  const exportCableCurrents = exportSystemLoss?.exportCableCurrents;
  const exportCableSegLengths = exportSystemLoss?.exportCableSegLengths;

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!exportCableCurrents || !exportCableSegLengths) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    if (!usedExportCableId) {
      warning("No export cables in park.");
      return;
    }

    const res = computeCurrentNumbers(
      exportCables,
      exportCableSegments,
      exportCableTypes,
      exportCableCurrents,
      exportCableSegLengths,
      usedExportCableId,
    );

    if (!res) {
      warning("Cannot find results for the selected export cable.");
      return;
    }

    const {
      selectedExportCableCurrents,
      selectedExportCableSegLengths,
      ratedCurrentValues,
      exportSystemType,
    } = res;

    const header = [
      "Distance from offshore substation [km]",
      "Export cable current at full load [A]",
      ...(exportSystemType === "HVAC" ? ["Rated current [A]"] : []),
    ];

    const rows = selectedExportCableSegLengths
      .map((_, i) => {
        const length = selectedExportCableSegLengths[i].toFixed(2);
        const current = selectedExportCableCurrents[i].toFixed(2);
        const ratedCurrent = ratedCurrentValues
          ? ratedCurrentValues[i].toFixed(2)
          : null;

        return [
          length,
          current,
          ...(exportSystemType === "HVAC" && ratedCurrent
            ? [ratedCurrent]
            : []),
        ];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "export_cable_current.csv");
  }, [
    exportCableCurrents,
    exportCableSegLengths,
    exportCableSegments,
    exportCableTypes,
    exportCables,
    usedExportCableId,
    warning,
  ]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const ExportCableLoadingWidget = () => {
  const { park, branch, errorBoundaryResetKeys } = useDashboardContext();
  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );

  const [usedExportCableId, setUsedExportCableId] = useState<
    string | undefined
  >(exportCables[0]?.id);

  useEffect(() => {
    if (exportCables.length > 0) {
      setUsedExportCableId(exportCables[0]?.id);
    }
  }, [exportCables]);

  return (
    <SafeCard
      title="Export cable loading"
      id="Export cable loading"
      menuItems={
        <ExportCableLoadingMenu usedExportCableId={usedExportCableId} />
      }
      resetKeys={errorBoundaryResetKeys}
    >
      <ExportCableLoading
        usedExportCableId={usedExportCableId}
        setUsedExportCableId={setUsedExportCableId}
      />
    </SafeCard>
  );
};
