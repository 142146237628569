import { MAX_TOTAL_TURBINES } from "@constants/park";
import * as turf from "@turf/turf";
import { MAX_WIND_ROSE_DISTANCE_KM } from "components/ProductionV2/constants";
import { checkExportCablesTooLong } from "components/ValidationWarnings/ExportCableTooLongWarningError";
import { atom } from "jotai";
import { MIN_CABLE_LENGTH_KM } from "state/cable";
import {
  cableForestFamily,
  cableLoadsFamily,
  cablesInParkFamily,
} from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";
import {
  exportCablesInParkFamily,
  exportCablesInParkWithTypeFamily,
} from "state/jotai/exportCable";
import {
  substationsInParkFamily,
  substationsInParkWithTypeFamily,
} from "state/jotai/substation";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { ExportCableFeature, SubstationFeature } from "types/feature";
import { pointInPolygon } from "utils/geometry";
import { getOverlappingTurbines as getOverlappingTurbines_ } from "utils/getOverlappingTurbines";
import { atomFamily } from "utils/jotai";
import { overlappingPointFeatures } from "utils/overlappingPointFeatures";
import {
  ProdId,
  getBranchId,
  getCloseExistingTurbines,
  getConfiguration,
  getPark,
  getParkId,
  getProjectId,
  getSurroundingTurbines,
  getTurbineTypes,
  getTurbines,
} from "./inputs";

import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { EXISTING_TURBINE_OVERLAP_DISTANCE } from "components/ValidationWarnings/ExistingTurbineOverlap";
import { getExportCableNotConnected } from "components/ValidationWarnings/ExportCableNotConnected";
import { cableChainsInParkFamily } from "state/jotai/cableChain";
import { getOverrideExportOffshoreCableTypeFamily } from "./electrical";
import { getStatsError } from "./output";
import { getAllExportSystemTypesInParkAndBranchFamily } from "state/jotai/electrical";
import { checkExportCablesTooLongToConverge } from "components/ValidationWarnings/ExportSystemWillNotConverge";
import { isOnshoreAtom } from "state/onshore";

/**
 * Error type that the analysis system can `throw`.
 */
export class AnalysisError extends Error {
  type: AnalysisStoppedTypes;
  name = "AnalysisError";
  constructor(type: AnalysisStoppedTypes) {
    super(type);
    this.type = type;
    this.message = analysisStoppedText[type];
  }
}

export enum AnalysisStoppedTypes {
  NoTurbines = "no_turbines",
  MultiPolygonPark = "multi_polygon_park",
  TooManyTurbines = "too_many_turbines",
  TurbinesNotFound = "turbines_not_found",
  NeighbourTurbinesNotFound = "neighbour_turbines_not_found",
  TurbinesOverlap = "turbines_overlap",
  ExistingTurbinesOverlap = "existing_turbines_overlap",
  TurbinesOutsidePark = "turbines_outside_park",
  GWAnotAvailable = "invalid_mean_grid",
  MissingTimestamps = "missing_timestamps",
  WindRoseFarAway = "windrose_far_away",
  TooLargeProblem = "too_large_problem",
  InterArrayFailed = "inter_array_failed",
  ExportSystemFailed = "export_system_failed",
  TooShortCables = "too_short_cables",
  CableMissingType = "cable_missing_type",
  TurbineNotConnected = "turbine_not_connected",
  SubstationNotConnected = "substation_not_connected",
  SubstationHasNoTurbines = "substation_has_no_turbines",
  SubstationMissingType = "substation_missing_type",
  CableNotConnected = "cable_not_connected",
  ExportCableNotConnected = "export_cable_not_connected",
  ExportCableMissingType = "export_cable_missing_type",
  ExportCableTooLongType = "export_cable_too_long_type",
  ExportCableDifferentType = "export_cable_different_type",
  ExportCableDifferentTypeInCable = "export_cable_different_type_in_cable",
  ExportSystemWillNotConverge = "export_system_will_not_converge",
  SubstationHasTooManyExportCables = "substation_has_too_many_export_cables",
  TurbinesOutsideSpeedGrid = "turbines_outside_speed_grid",

  NoBranchId = "no_branch_id",
  NoProjectId = "no_project_id",
  NoParkId = "no_park_id",
  InvalidParkId = "invalid_park_id",

  InvalidWindConfigId = "invalid_wind_config_id",
  InvalidAnalysisConfigId = "invalid_analysis_config_id",

  WindRoseFailed = "wind_rose_failed",

  Unknown = "unknown",

  IncompatibleTimeseries = "incompatible_timeseries",

  ProcurementTableDontExist = "procurement_table_dont_exist",
}

export const analysisStoppedText: Record<AnalysisStoppedTypes, string> = {
  [AnalysisStoppedTypes.MultiPolygonPark]:
    "Park is multipolygon which is unsupported.",
  [AnalysisStoppedTypes.NoTurbines]: "No turbines to analyze.",
  [AnalysisStoppedTypes.TooManyTurbines]: `Too many turbines (>${MAX_TOTAL_TURBINES}) to analyze.`,
  [AnalysisStoppedTypes.TooLargeProblem]:
    "The total analysis problem size exceeds our capacity per analysis. Try reducing the number of turbines and/or the analysis precision",
  [AnalysisStoppedTypes.TurbinesNotFound]:
    "Unknown turbines are detected in the park/zone.",
  [AnalysisStoppedTypes.NeighbourTurbinesNotFound]:
    "Unknown turbines are detected in neighbouring park/zone.",
  [AnalysisStoppedTypes.TurbinesOverlap]: "Turbines are overlapping.",
  [AnalysisStoppedTypes.ExistingTurbinesOverlap]:
    "Some existing turbines are overlapping.",
  [AnalysisStoppedTypes.TurbinesOutsidePark]:
    "Turbines are outside the park boundary.",
  [AnalysisStoppedTypes.GWAnotAvailable]:
    "The GWA speed correction is not available at the park location.",
  [AnalysisStoppedTypes.WindRoseFarAway]: `The selected wind dataset is more than ${MAX_WIND_ROSE_DISTANCE_KM}km from the park center.`,
  [AnalysisStoppedTypes.InterArrayFailed]: `The inter array loss analysis has failed, please check your park cable types.`,
  [AnalysisStoppedTypes.ExportSystemFailed]: `The export system loss analysis has failed, please check your park export cable types and substation settings, or switch to HVDC.`,
  [AnalysisStoppedTypes.TooShortCables]: `Some cables are too short ( < ${1000 * MIN_CABLE_LENGTH_KM} m) for the inter array loss analysis.`,
  [AnalysisStoppedTypes.CableMissingType]: `Inter array losses are activated, but some cables do not have a valid cable type.`,
  [AnalysisStoppedTypes.TurbineNotConnected]: `Inter array losses are activated, but some turbines are not connected to a substation.`,
  [AnalysisStoppedTypes.SubstationMissingType]: `Inter array losses are activated, but some substations do not have a valid substation type.`,
  [AnalysisStoppedTypes.SubstationNotConnected]: `Export system losses are activated, but some substations are not connected to an export cable.`,
  [AnalysisStoppedTypes.CableNotConnected]: `Inter array losses are activated, but some cables are not connected to turbines and/or substations.`,
  [AnalysisStoppedTypes.ExportCableNotConnected]: `Export system losses are activated, but some export cables are not connected to both an offshore and an onshore substation.`,
  [AnalysisStoppedTypes.ExportCableMissingType]: `Export system losses are activated, but some export cables do not have both an offshore and an onshore cable type defined.`,
  [AnalysisStoppedTypes.ExportCableTooLongType]: `Export system losses are activated, but some export cables are too long to calculate losses for.`,
  [AnalysisStoppedTypes.SubstationHasTooManyExportCables]: `Export system losses are activated, but some offshore substations have more export cables than inter-array cable chains connected to it.`,
  [AnalysisStoppedTypes.TurbinesOutsideSpeedGrid]:
    "Turbines are outside the spatial speed grid used in the wind configuration.",
  [AnalysisStoppedTypes.MissingTimestamps]:
    "Missing timestamps in the uploaded wind data.",
  [AnalysisStoppedTypes.SubstationHasNoTurbines]:
    "A substation is not connected to any turbines.",
  [AnalysisStoppedTypes.NoProjectId]: "Missing project id",
  [AnalysisStoppedTypes.NoBranchId]: "Missing branch id",
  [AnalysisStoppedTypes.NoParkId]: "Missing park id",
  [AnalysisStoppedTypes.InvalidParkId]: "Invalid park id",

  [AnalysisStoppedTypes.InvalidWindConfigId]: "todo",
  [AnalysisStoppedTypes.InvalidAnalysisConfigId]: "todo",
  [AnalysisStoppedTypes.WindRoseFailed]: "todo",

  [AnalysisStoppedTypes.Unknown]: "An unknown error occured.",

  [AnalysisStoppedTypes.IncompatibleTimeseries]:
    "The wind data time series are not matching in the time dimension.",

  [AnalysisStoppedTypes.ExportCableDifferentType]: `Export system losses are activated, but park contains export cables of both HVAC and HVDC type. Please verify that the all export cables have the same type.`,
  [AnalysisStoppedTypes.ExportCableDifferentTypeInCable]: `Export system losses are activated, but some export cables have more than one unique type (HVAC and HVDC). Please verify that the offshore and onshore export cable parts have the same type.`,
  [AnalysisStoppedTypes.ExportSystemWillNotConverge]: `Export system losses are activated, but some export cables are too long compared to the amount of power going into them, and the loss analysis will not converge. Please reduce the export cable length, try a different export cable voltage, or switch to HVDC.`,
  [AnalysisStoppedTypes.ProcurementTableDontExist]: `A foundation procurement table used in the financial configuration has been deleted, please update the financial configuration in Library.`,
};

const getHasDisconnectedTurbines = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const forest = await get(cableForestFamily({ parkId, branchId }));
    const numberOfCables = (await get(cablesInParkFamily({ parkId, branchId })))
      .length;
    if (numberOfCables === 0) return false;
    const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
    const loose = turbines.filter((t) => {
      for (const tree of forest)
        if (tree.contains((n) => "turbine" in n && n.turbine.id === t.id))
          return false;
      return true;
    });
    return loose.length > 0;
  }),
);

const getDisconnectedExportSubstations = atomFamily((id: ProdId) =>
  atom<Promise<SubstationFeature[]>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const subs = await get(substationsInParkFamily({ parkId, branchId }));
    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    if (exportCables.length === 0) return [];
    const touchedIds = new Set(
      exportCables.flatMap((ec) => [
        ec.properties.fromSubstationId,
        ec.properties.toSubstationId,
      ]),
    );
    return subs.filter((s) => !touchedIds.has(s.id));
  }),
);

/**
 * Check that all export cables have both kinds of substations at the two ends.
 */
const getDisconnectedExportCablesFromSubstation = atomFamily((id: ProdId) =>
  atom<Promise<ExportCableFeature[]>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const subs = await get(substationsInParkFamily({ parkId, branchId }));

    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const substationTypes = await get(allSubstationsForProjectAtom);

    const illegals = getExportCableNotConnected(
      exportCables,
      subs,
      substationTypes,
    );
    return exportCables.filter((ec) => illegals.includes(ec.id));
  }),
);

/**
 * Check that all cables have either a turbine or a substation at the ends.
 */
const getHasDisconnectedCables = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const substations = await get(
      substationsInParkFamily({ parkId, branchId }),
    );
    const turbines = await get(turbinesInParkFamily({ parkId, branchId }));

    const cables = await get(cablesInParkFamily({ parkId, branchId }));

    const featureIds = [...turbines, ...substations].map((f) => f.id);

    const hasDisconnectedCables = cables.some(
      (c) =>
        !featureIds.includes(c.properties.fromId) ||
        !featureIds.includes(c.properties.toId),
    );
    return hasDisconnectedCables;
  }),
);

const getHasExportCablesMissingTypes = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));

    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const exportCableTypes = await get(
      exportCableTypesFamily({ projectId: undefined }),
    );

    if (exportCables.length === 0) return false;

    const exportCablesMissingTypes = exportCables.filter((c) => {
      const offshoreCableType = exportCableTypes.get(
        c.properties.cableTypeId ?? "",
      );
      const onshoreCableType = exportCableTypes.get(
        c.properties.onshoreCableTypeId ?? "",
      );

      return !offshoreCableType || !onshoreCableType;
    });

    return exportCablesMissingTypes.length > 0;
  }),
);

const getHasSubstationsMissingTypes = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));

    const substations = await get(
      substationsInParkFamily({ parkId, branchId }),
    );
    const substationTypes = await get(allSubstationsForProjectAtom);

    if (substations.length === 0) return false;

    const substationsMissingTypes = substations.filter(
      (s) => !substationTypes.has(s.properties.substationTypeId ?? ""),
    );

    return substationsMissingTypes.length > 0;
  }),
);

const getHasCablesMissingTypes = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));

    const cables = await get(cablesInParkFamily({ parkId, branchId }));
    const cableTypes = await get(cableTypesFamily({ projectId: undefined }));

    if (cables.length === 0) return false;

    const cablesMissingTypes = cables.filter(
      (c) => !cableTypes.has(c.properties.cableTypeId ?? ""),
    );

    return cablesMissingTypes.length > 0;
  }),
);

const getSubstationHasTooManyExportCables = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const substations = await get(
      substationsInParkFamily({ parkId, branchId }),
    );
    const substationTypes = await get(allSubstationsForProjectAtom);
    const offshoreSubstations = substations.filter(
      (s) =>
        substationTypes.get(s.properties.substationTypeId ?? "")?.type ===
        "offshore",
    );
    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const cables = await get(cablesInParkFamily({ parkId, branchId }));

    if (
      exportCables.length === 0 ||
      offshoreSubstations.length === 0 ||
      cables.length === 0
    )
      return false;

    const invalidSubstations = offshoreSubstations.filter((s) => {
      const subCableChains = cables.filter(
        (c) => c.properties.fromId === s.id || c.properties.toId === s.id,
      );
      const subExportCables = exportCables.filter(
        (c) =>
          c.properties.fromSubstationId === s.id ||
          c.properties.toSubstationId === s.id,
      );

      return subExportCables.length > subCableChains.length;
    });

    return invalidSubstations.length > 0;
  }),
);

const getNumberOfTurbinesIsZero = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const turbines = await get(getTurbines(id));
    return turbines.length === 0;
  }),
);

const getTooShortCables = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const cables = await get(cablesInParkFamily({ parkId, branchId }));

    return cables.some(
      (c) => turf.length(c, { units: "kilometers" }) < MIN_CABLE_LENGTH_KM,
    );
  }),
);

const getTurbinesOutsidePark = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const park = await get(getPark(id));
    const turbines = await get(getTurbines(id));
    const outside = turbines.filter(
      (t) => !pointInPolygon(t.geometry, park.geometry),
    );
    return 0 < outside.length;
  }),
);

export const getParkTurbineTypeIsMissing = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const types = await get(getTurbineTypes(id));
    return !turbines.every((t) => types.has(t.properties.turbineTypeId));
  }),
);

const getOverlappingTurbines = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const otherTurbines = await get(getSurroundingTurbines(id));
    const allTurbines = turbines.concat(otherTurbines);
    const turbineTypes = await get(simpleTurbineTypesAtom);
    const overlaps = getOverlappingTurbines_(allTurbines, turbineTypes);
    return 0 < overlaps.length;
  }),
);

const getOverlappingExistingTurbines = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const turbines = await get(getCloseExistingTurbines(id));
    const overlaps = overlappingPointFeatures(
      turbines,
      EXISTING_TURBINE_OVERLAP_DISTANCE,
    );
    return 0 < overlaps.length;
  }),
);

const getNumberOfTurbinesIsTooHigh = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const otherTurbines = await get(getSurroundingTurbines(id));
    const existingTurbines = await get(getCloseExistingTurbines(id));
    const totalNumberOfTurbines =
      turbines.length + otherTurbines.length + existingTurbines.length;
    return MAX_TOTAL_TURBINES < totalNumberOfTurbines;
  }),
);

const getNeighbourTurbineTypeIsMissing = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const otherTurbines = await get(getSurroundingTurbines(id));
    const types = await get(getTurbineTypes(id));
    return !otherTurbines.every((t) => types.has(t.properties.turbineTypeId));
  }),
);

const getHasExportCablesTooLongTypes = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const projectId = await get(getProjectId(id));
    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const cableTypes = await get(exportCableTypesFamily({ projectId }));
    const longs = checkExportCablesTooLong(exportCables, cableTypes);
    return longs.tooLong.length > 0;
  }),
);

const getHasExportCableDifferentType = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const exportSystemTypes = await get(
      getAllExportSystemTypesInParkAndBranchFamily({
        parkId,
        branchId,
      }),
    );
    return exportSystemTypes.length > 1;
  }),
);

const getHasExportCableDifferentTypeInCable = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const projectId = await get(getProjectId(id));
    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const exportCableTypes = await get(exportCableTypesFamily({ projectId }));

    const cablesDifferentType = exportCables.filter((c) => {
      const offshoreCableType = exportCableTypes.get(
        c.properties.cableTypeId ?? "",
      )?.exportCableType;
      const onshoreCableType = exportCableTypes.get(
        c.properties.onshoreCableTypeId ?? "",
      )?.exportCableType;

      return (
        offshoreCableType &&
        onshoreCableType &&
        offshoreCableType !== onshoreCableType
      );
    });

    return cablesDifferentType.length > 0;
  }),
);

const getHasExportSystemWillNotConverge = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const configuration = await get(getConfiguration(id));

    if (!configuration.electrical.exportSystemLoss) return false;

    const cables = await get(
      cablesInParkFamily({
        parkId,
        branchId,
      }),
    );
    const exportCables = await get(
      exportCablesInParkWithTypeFamily({ parkId, branchId }),
    );
    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );
    const iaLoads = await get(
      cableLoadsFamily({ parkId, branchId, turbineTypeOverride: undefined }),
    );

    const exportOverrideType = await get(
      getOverrideExportOffshoreCableTypeFamily(id),
    );

    const errorExportCables = checkExportCablesTooLongToConverge(
      cables,
      exportCables,
      substations,
      iaLoads,
      exportOverrideType,
    );

    return errorExportCables.length > 0;
  }),
);

const getSubstationHasNoTurbines = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const cables = await get(cablesInParkFamily({ parkId, branchId }));
    if (cables.length === 0) return false;
    const chains = await get(cableChainsInParkFamily({ parkId, branchId }));

    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );

    const offshoreSubstations = substations
      .filter(([, s]) => s.type === "offshore")
      .map(([t]) => t);

    return offshoreSubstations.some((sub) => {
      const adjacentTurbines = chains
        .filter((c) => c.properties.substation === sub.id)
        .flatMap((c) => c.properties.turbines);
      return adjacentTurbines.length === 0;
    });
  }),
);

export const getAnalysisError = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | null>>(async (get) => {
    return await get(getStatsError(id));
  }),
);

export const getStoppedReason = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    const analysisStopped = await get(getWakeAnalysisStoppedReason(id));
    if (analysisStopped !== undefined) return analysisStopped;
    const interArrayStopped = await get(getInterArrayStoppedReason(id));
    if (interArrayStopped !== undefined) return interArrayStopped;
    const exportStopped = await get(getExportSystemStoppedReason(id));
    if (exportStopped !== undefined) return exportStopped;
    return undefined;
  }),
);

export const getThrowStoppedReason = atomFamily((id: ProdId) =>
  atom<Promise<void>>(async (get) => {
    const stoppedReason = await get(getStoppedReason(id));
    if (stoppedReason) throw new AnalysisError(stoppedReason);
  }),
);

/**
 * Reasons for stopping the wake analysis.
 */
export const getWakeAnalysisStoppedReason = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    if (await get(getOverlappingTurbines(id)))
      return AnalysisStoppedTypes.TurbinesOverlap;
    if (await get(getOverlappingExistingTurbines(id)))
      return AnalysisStoppedTypes.ExistingTurbinesOverlap;
    if (await get(getNumberOfTurbinesIsZero(id)))
      return AnalysisStoppedTypes.NoTurbines;
    if (await get(getNumberOfTurbinesIsTooHigh(id)))
      return AnalysisStoppedTypes.TooManyTurbines;
    if (await get(getTurbinesOutsidePark(id)))
      return AnalysisStoppedTypes.TurbinesOutsidePark;
    if (await get(getParkTurbineTypeIsMissing(id)))
      return AnalysisStoppedTypes.TurbinesNotFound;
    if (await get(getNeighbourTurbineTypeIsMissing(id)))
      return AnalysisStoppedTypes.NeighbourTurbinesNotFound;
  }),
);

/**
 * Reasons for stopping the inter-array analysis.
 */
export const getInterArrayStoppedReason = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    if (
      !configuration.electrical.turbineTrafoLoss &&
      !configuration.electrical.interArrayCableLoss
    )
      return undefined;
    if (await get(getHasDisconnectedTurbines(id)))
      return AnalysisStoppedTypes.TurbineNotConnected;
    if (await get(getHasCablesMissingTypes(id)))
      return AnalysisStoppedTypes.CableMissingType;
    if (await get(getHasSubstationsMissingTypes(id)))
      return AnalysisStoppedTypes.SubstationMissingType;
    if (await get(getHasDisconnectedCables(id)))
      return AnalysisStoppedTypes.CableNotConnected;
    if (await get(getTooShortCables(id)))
      return AnalysisStoppedTypes.TooShortCables;
  }),
);

/**
 * Reasons for stopping the export system analysis.
 */
export const getExportSystemStoppedReason = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    if (!configuration.electrical.exportSystemLoss) return undefined;
    const onshore = get(isOnshoreAtom);
    if ((await get(getDisconnectedExportSubstations(id))).length > 0)
      return AnalysisStoppedTypes.SubstationNotConnected;
    if (
      !onshore &&
      (await get(getDisconnectedExportCablesFromSubstation(id))).length > 0
    )
      return AnalysisStoppedTypes.ExportCableNotConnected;
    if (await get(getHasExportCablesMissingTypes(id)))
      return AnalysisStoppedTypes.ExportCableMissingType;
    if (await get(getHasExportCablesTooLongTypes(id)))
      return AnalysisStoppedTypes.ExportCableTooLongType;
    if (await get(getSubstationHasTooManyExportCables(id)))
      return AnalysisStoppedTypes.SubstationHasTooManyExportCables;
    if (await get(getSubstationHasNoTurbines(id)))
      return AnalysisStoppedTypes.SubstationHasNoTurbines;
    if (await get(getHasExportCableDifferentType(id)))
      return AnalysisStoppedTypes.ExportCableDifferentType;
    if (await get(getHasExportCableDifferentTypeInCable(id)))
      return AnalysisStoppedTypes.ExportCableDifferentTypeInCable;
    if (await get(getHasExportSystemWillNotConverge(id)))
      return AnalysisStoppedTypes.ExportSystemWillNotConverge;
  }),
);
