import { fetchEnhancerWithToken, fetchSchemaWithToken } from "services/utils";
import {
  BasicChangelogEntry,
  _BasicChangelogEntry,
  ChangelogComment,
  ChangelogEntry,
  _ChangelogComment,
  _ChangelogEntry,
  ChangelogCategory,
  _ChangelogMetadata,
  ChangelogMetadata,
} from "./types";

const categoryToSubUrl = (category: ChangelogCategory) => {
  switch (category) {
    case "org_analysis_manage":
    case "org_cable_manage":
    case "org_export_cable_manage":
    case "org_data_package_manage":
    case "org_financial_manage":
    case "org_foundation_manage":
    case "org_turbine_manage":
    case "org_substation_manage":
      return `/${category}`;
    default:
      return "";
  }
};

export const getNodeChangelogs = async (
  nodeId: string,
  id: string,
  category: ChangelogCategory,
): Promise<ChangelogEntry[]> => {
  return fetchSchemaWithToken(
    _ChangelogEntry.array(),
    `/api/timeline/changelog/node/${nodeId}${categoryToSubUrl(category)}/${encodeURIComponent(id)}`,
    {
      method: "get",
    },
  );
};

export const getOrganisationChangelogs = async (
  organisationId: string,
  id: string,
  category: ChangelogCategory,
): Promise<ChangelogEntry[]> => {
  return fetchSchemaWithToken(
    _ChangelogEntry.array(),
    `/api/timeline/changelog/organisation/${organisationId}${categoryToSubUrl(category)}/${encodeURIComponent(id)}`,
    {
      method: "get",
    },
  );
};

export const getOrganisationLatestUpdate = async (
  organisationId: string,
  resourceIds: string[],
): Promise<BasicChangelogEntry[]> => {
  return fetchSchemaWithToken(
    _BasicChangelogEntry.array(),
    `/api/timeline/changelog/organisation/${organisationId}`,
    {
      method: "post",
      body: JSON.stringify({ ids: resourceIds }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const putCommentToChangelogNode = async (
  url: string,
  comment: { comment: string; keyPath: string },
): Promise<ChangelogComment> => {
  return fetchSchemaWithToken(_ChangelogComment, url, {
    method: "put",
    body: JSON.stringify(comment),
    headers: {
      "content-type": "application/json",
    },
  });
};

export const deleteCommentFromChangelogNode = async (
  url: string,
): Promise<any> => {
  return fetchEnhancerWithToken(url, {
    method: "delete",
  });
};

export const getProjectResourcesChangelogMetadata = async (
  organisationId: string,
  projectId: string,
): Promise<ChangelogMetadata[]> => {
  return fetchSchemaWithToken(
    _ChangelogMetadata.array(),
    `/api/timeline/changelog/metadata/organisation/${organisationId}/node/${projectId}`,
    {
      method: "get",
    },
  );
};
