import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import Buffer2 from "@icons/24/Buffer2.svg";
import Bin from "@icons/24/Bin.svg";
import Cabling2 from "@icons/24/Cabling-2.svg";
import Cabling from "@icons/24/Cabling.svg";
import CommentIcon from "@icons/24/Chat.svg";
import MenuVertical from "@icons/24/MenuVertical.svg";
import Paragraph from "@icons/24/Paragraph.svg";
import MapItemsIcon from "@icons/24/MapItems.svg";
import Pen from "@icons/24/Pencil.svg";
import Split from "@icons/24/Split.svg";
import { Divider, IconBtn, IconMenu } from "components/General/Icons";
import { Menu } from "components/General/Menu";
import { Popup } from "components/Mapbox/Popup";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FeaturePropertiesMenuFrame } from "components/FeatureProperties/FeaturePropertiesMenuFrame";
import TurbineEllipsesSettings from "components/TurbineEllipsesSettings/TurbineEllipsesSettings";
import { currentSelectionArrayAtom } from "state/selection";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import { isMultiPolygonFeature, isPolygonFeature } from "utils/predicates";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
} from "../../constants/cabling";
import { useDeleteFeaturesCallback } from "../../hooks/deleteFeature";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { useToast } from "../../hooks/useToast";
import { DefaultMap } from "../../lib/DefaultMap";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import {
  editFeaturesAtom,
  mapInteractionSelector,
  mapAtom,
} from "../../state/map";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../state/modal";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../state/pathParams";
import { inReadOnlyModeSelector } from "../../state/project";
import { editorAccessProjectSelector } from "../../state/user";
import {
  CableChainFeature,
  CableFeature,
  ProjectFeature,
} from "../../types/feature";
import { keyEventTargetIsInput } from "../../utils/keyboard";
import {
  featureIsDerived,
  featureIsLocked,
  isCable,
  isCableChain,
  isCablePartition,
  isExclusionDivision,
  isExportCable,
  isMooringLine,
  isTurbine,
} from "../../utils/predicates";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { splitMultiFeatureHelpHintType } from "../ActiveTips/PositionHints/SplitMultiFeatureHelp";
import { BufferModalType } from "../BufferModal/BufferModal";
import {
  useConnectChains,
  useRecomputeChains,
  useSelectCableEdit,
} from "../Cabling/Generate/CableEdit";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import { newThreadAtomFamily } from "../Comments/state";
import { EditableText } from "../General/EditableText";
import Tooltip from "../General/Tooltip";
import StylingSelector from "../StylingSelector/StylingSelector";
import { CableRedundancy } from "./CableRedundancy";
import CableTypeSelector from "./CableTypeSelector";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
  ToolsWrapper,
} from "./CanvasSelectOption.style";
import ExistingTurbineTypeSelector from "./ExistingTurbineTypeSelector";
import FoundationTypeSelector from "./FoundationTypeSelector";
import { trackCanvasOption } from "./MenuTracking";
import MooringLineLengthEditor from "./MooringLineLengthEditor";
import MooringLineTypeSelector from "./MooringLineTypeSelector";
import SplitMultiPart from "./SplitMultiPart";
import { SubstationTypeSelector } from "./SubstationTypeSelector/SubstationTypeSelector";
import TurbineTypeSelector from "./TurbineTypeSelector";
import ExclusionTypeSelector from "./TypeSelector/ExclusionTypeSelector";
import { TypeSelector } from "./TypeSelector/TypeSelector";
import useMoveablePopup from "./useMoveablePopup";
import { cablesInParkFamily } from "state/jotai/cable";
import ExportCableTypeSelector from "./ExportCableTypeSelector";
import { isOnshoreAtom } from "state/onshore";
import { ExtractMapDataModalType } from "components/ExtractMapDataModal/ExtractMapDataModal";
import SingleFeatureMenu from "components/RightClickMenu/SelectionMenu/SingleFeatureMenu";
import { SkeletonText } from "components/Loading/Skeleton";

export const BufferSelector = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(projectIdAtom) ?? "";
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const openBufferModal = useCallback(() => {
    trackCanvasOption("buffer-feature", {
      projectId,
      branchId,
    });
    setMidScreenModalTypeOpen({
      modalType: BufferModalType,
    });
  }, [branchId, projectId, setMidScreenModalTypeOpen]);
  return (
    <IconBtn iconColor={colors.iconBrand} onClick={openBufferModal}>
      <Buffer2 />
    </IconBtn>
  );
};

const SplitCableChain = ({ chain }: { chain: CableChainFeature }) => {
  const parkId = chain.properties.parentIds[0];
  const allCables = useAtomValue(
    cablesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const connect = useConnectChains({
    parkId,
  });

  const onClick = useCallback(async () => {
    const isInChain = (c: CableFeature): boolean =>
      chain.properties.turbines.includes(c.properties.fromId) &&
      chain.properties.turbines.includes(c.properties.toId);
    const cables = allCables.filter(isInChain);
    const other = new DefaultMap<string, string[]>(() => []);
    const count = new DefaultMap<string, number>(() => 0);
    for (const {
      properties: { fromId, toId },
    } of cables) {
      other.get(fromId).push(toId);
      other.get(toId).push(fromId);
      count.update(fromId, (c) => c + 1);
      count.update(toId, (c) => c + 1);
    }

    let prev = "";
    let [endpoint] = [...count.entries()].find(([, c]) => c === 1) ?? [];
    let array: string[] = [];
    while (endpoint) {
      array.push(endpoint);
      const p = prev;
      const next = other.get(endpoint).filter((c) => c !== p)[0];
      prev = endpoint;
      endpoint = next;
    }
    const mid = Math.floor(array.length / 2);
    const left = array.slice(0, mid);
    const right = array.slice(mid);

    const chains = [left, right]
      .filter((a) => 0 < a.length)
      .map((a) => ({
        ...chain.properties,
        id: uuidv4(),
        turbines: a,
      }));

    await connect(chains);
  }, [allCables, chain.properties, connect]);

  return (
    <Tooltip position="top" text={"Split chain"}>
      <IconBtn iconColor={colors.iconBrand} onClick={onClick}>
        <Split />
      </IconBtn>
    </Tooltip>
  );
};

const CanvasSingleSelectOption = ({
  selectedProjectFeature,
}: {
  selectedProjectFeature: ProjectFeature;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom);
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const setEditFeature = useSetAtom(editFeaturesAtom);
  const map = useAtomValue(mapAtom);
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const isLocked = featureIsLocked(selectedProjectFeature);
  const isTurbineFeature = isTurbine(selectedProjectFeature);
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);
  const setNewThreadOpen = useSetAtom(
    newThreadAtomFamily({
      projectId,
    }),
  );
  const isOnshore = useAtomValue(isOnshoreAtom);

  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (
      selectedProjectFeature.properties.name === "Park A" ||
      selectedProjectFeature.properties.name === "Park 1"
    )
      trackEvent("clicked park");
  }, [trackEvent, selectedProjectFeature.properties.name]);

  const selectedProjectFeatureArr = useMemo(
    () => [selectedProjectFeature],
    [selectedProjectFeature],
  );
  const { setMouseDownPosition, isMoving, popupPlacement } = useMoveablePopup(
    selectedProjectFeatureArr,
    true,
    popupRef,
  );

  const deleteFeatureWithChildren = useDeleteFeaturesCallback();

  const deleteFeature = useCallback(() => {
    if (!selectedProjectFeature || !canEdit) return;
    deleteFeatureWithChildren([selectedProjectFeature.id]);
  }, [canEdit, selectedProjectFeature, deleteFeatureWithChildren]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!["Backspace", "Delete"].includes(e.key)) {
        return;
      }

      if (
        !mapInteraction.deleteFeatureKeyboardShortcut ||
        keyEventTargetIsInput(e) ||
        isLocked
      ) {
        return;
      }
      deleteFeature();
      return;
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [deleteFeature, mapInteraction.deleteFeatureKeyboardShortcut, isLocked]);

  const onClickEditFeature = useCallback(() => {
    trackCanvasOption("edit-feature", {
      projectId,
      branchId,
    });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    setEditFeature([selectedProjectFeature.id]);
  }, [
    branchId,
    projectId,
    selectedProjectFeature.id,
    setCurrentSelectionArray,
    setEditFeature,
  ]);

  const [name, setName] = useState("");
  useEffect(() => {
    if (!selectedProjectFeature) return;
    setName(selectedProjectFeature.properties?.name ?? "");
  }, [selectedProjectFeature, setName]);
  const updateFeatureProperties = useSetPropertyOnProjectFeatures(() =>
    setModalTypeOpen(undefined),
  );

  const isOfTypeOther = useMemo(
    () =>
      selectedProjectFeature.properties.type == null &&
      ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
        selectedProjectFeature.properties.type ?? "",
      ),
    [selectedProjectFeature],
  );
  const setCableEditMode = useSetAtom(cableEditModeAtom);
  const [showFeatureProperties, setShowFeatureProperties] = useState(false);
  const [showMoreFeatureActions, setShowMoreFeatureActions] = useState(false);

  // Close the feature properties menu when the feature is changed
  useEffect(() => {
    setShowMoreFeatureActions(false);
  }, [selectedProjectFeature]);

  const isPolygon =
    isPolygonFeature(selectedProjectFeature) ||
    isMultiPolygonFeature(selectedProjectFeature);

  const readonly =
    isCableChain(selectedProjectFeature) ||
    isCablePartition(selectedProjectFeature);

  const recomputeChains = useRecomputeChains({
    parkId: parkId ?? "",
  });
  const { selectPartitionWithTurbine } = useSelectCableEdit({
    parkId: parkId ?? "",
  });
  const { error } = useToast();

  const moreMenuRef = useRef<HTMLButtonElement>(null);
  const featurePropRef = useRef<HTMLButtonElement>(null);

  if (!map) return null;
  return (
    <Popup
      className={"single-select-popup"}
      map={map}
      pos={popupPlacement}
      offsetPx={[0, -20]}
      place="bottom"
      style={{
        pointerEvents: isMoving ? "none" : undefined,
      }}
    >
      <IconMenu iconSize="2.2rem" ref={popupRef}>
        <div
          style={{
            cursor: "move",
          }}
          onMouseDown={(e) => {
            setMouseDownPosition({
              x: e.clientX,
              y: e.clientY,
            });
          }}
        >
          <NamedTooltipWrapper>
            <IconREMSize height={1.2} width={1.2}>
              <DnDIconSmall />
            </IconREMSize>
          </NamedTooltipWrapper>
        </div>
        {selectedProjectFeature && (
          <>
            {!isCable(selectedProjectFeature) &&
              !isCableChain(selectedProjectFeature) &&
              !isCablePartition(selectedProjectFeature) && (
                <>
                  <NamedTooltipContainer>
                    <NamedTooltipWrapper>Name</NamedTooltipWrapper>
                    <EditableText
                      type="text"
                      smallInput={true}
                      value={name}
                      disabled={!canEdit || isLocked}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onEnter={() => {
                        updateFeatureProperties([selectedProjectFeature.id], {
                          name,
                        });
                        trackCanvasOption("change-feature-name", {
                          projectId,
                          branchId,
                        });
                      }}
                      onCancel={() => {
                        setName(selectedProjectFeature.properties?.name ?? "");
                      }}
                      textContainerStyle={{
                        maxWidth: "20vw",
                        padding: 0,
                      }}
                      editIconTitle="Rename"
                      renderText={(title) => (
                        <p
                          style={{
                            margin: 0,
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            display: "block",
                            fontSize: "1.3rem",
                          }}
                          title={title}
                        >
                          {title}
                        </p>
                      )}
                    />
                  </NamedTooltipContainer>
                  <Divider />
                </>
              )}
            {![
              BathymetryUserUploadedType,
              GeoTiffUserUploadedImageType,
            ].includes(selectedProjectFeature.properties.type ?? "") &&
              !isCableChain(selectedProjectFeature) &&
              !isCablePartition(selectedProjectFeature) && (
                <>
                  <TypeSelector
                    selections={selectedProjectFeatureArr}
                    setCurrentSelectionArray={setCurrentSelectionArray}
                    disabled={!canEdit || isLocked}
                  />
                </>
              )}
            {isCable(selectedProjectFeature) && (
              <>
                <CableTypeSelector
                  selectedProjectFeatures={selectedProjectFeatureArr}
                  editable={canEdit && !isLocked}
                />
                <Divider />
              </>
            )}
            {isMooringLine(selectedProjectFeature) && (
              <>
                <MooringLineTypeSelector
                  selectedProjectFeatures={selectedProjectFeatureArr}
                  editable={canEdit && !isLocked}
                />
                <Divider />
              </>
            )}
            <TurbineTypeSelector
              selectedProjectFeatures={selectedProjectFeatureArr}
              editable={canEdit && !isLocked}
            />
            <ExistingTurbineTypeSelector
              selectedProjectFeatures={selectedProjectFeatureArr}
              editable={canEdit && !isLocked}
            />
            <SubstationTypeSelector
              selectedProjectFeatures={selectedProjectFeatureArr}
              editable={canEdit && !isLocked}
            />
            {selectedProjectFeatureArr.every(isExportCable) && (
              <ExportCableTypeSelector
                cables={selectedProjectFeatureArr}
                editable={canEdit && !isLocked}
              />
            )}
            {selectedProjectFeatureArr.every(isCable) && (
              <CableRedundancy
                cables={selectedProjectFeatureArr}
                editable={canEdit && !isLocked}
              />
            )}
            {!isOnshore && (
              <FoundationTypeSelector
                selectedProjectFeatures={selectedProjectFeatureArr}
                editable={canEdit && !isLocked}
              />
            )}
            {canEdit &&
              !isLocked &&
              selectedProjectFeatureArr.every(isExclusionDivision) && (
                <>
                  <ExclusionTypeSelector
                    divisions={selectedProjectFeatureArr}
                    setCurrentSelectionArray={setCurrentSelectionArray}
                  />
                  <Divider />
                </>
              )}
            <ToolsWrapper>
              {canEdit &&
                !isLocked &&
                isCablePartition(selectedProjectFeature) && (
                  <>
                    <Tooltip position="top" text={`Recompute chains`}>
                      <IconBtn
                        onClick={async () => {
                          await recomputeChains(selectedProjectFeature).catch(
                            (e) => error(e.message ?? "Unknown error"),
                          );
                          setTimeout(() => {
                            selectPartitionWithTurbine(
                              selectedProjectFeature.properties.turbines[0],
                            );
                          }, 50);
                        }}
                      >
                        <Cabling2 />
                      </IconBtn>
                    </Tooltip>
                    <Divider />
                  </>
                )}
              {canEdit && !isLocked && isCableChain(selectedProjectFeature) && (
                <>
                  <SplitCableChain chain={selectedProjectFeature} />
                  <Divider />
                </>
              )}
              {canEdit &&
                !isLocked &&
                !readonly &&
                ![
                  BathymetryUserUploadedType,
                  GeoTiffUserUploadedImageType,
                ].includes(selectedProjectFeature.properties.type ?? "") && (
                  <>
                    <Tooltip position="top" text={`Edit element`}>
                      <IconBtn
                        iconColor={colors.iconBrand}
                        onClick={onClickEditFeature}
                      >
                        <Pen />
                      </IconBtn>
                    </Tooltip>
                  </>
                )}

              {canEdit && !isLocked && isCable(selectedProjectFeature) && (
                <>
                  <Tooltip position="top" text={`Change cabling`}>
                    <IconBtn
                      iconColor={colors.iconBrand}
                      onClick={() => {
                        setCableEditMode(true);
                        setCurrentSelectionArray([]);
                      }}
                    >
                      <Cabling />
                    </IconBtn>
                  </Tooltip>
                </>
              )}

              {canEdit &&
                !isLocked &&
                ![
                  BathymetryUserUploadedType,
                  GeoTiffUserUploadedImageType,
                  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
                  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
                ].includes(selectedProjectFeature.properties.type ?? "") && (
                  <>
                    <Tooltip position="top" text="Buffer">
                      <BufferSelector />
                    </Tooltip>
                  </>
                )}
              {canEdit &&
                !isLocked &&
                selectedProjectFeature.geometry.type.includes("Multi") && (
                  <>
                    <PositionHint
                      allowedHints={[splitMultiFeatureHelpHintType]}
                      position={"top"}
                    >
                      <Tooltip position="top" text="Split into parts">
                        <SplitMultiPart
                          projectDataFeatures={[selectedProjectFeature]}
                        />
                      </Tooltip>
                    </PositionHint>
                  </>
                )}
              {canEdit &&
                !isLocked &&
                isMooringLine(selectedProjectFeature) &&
                !readonly && (
                  <>
                    <MooringLineLengthEditor
                      feature={selectedProjectFeature}
                      parkId={parkId ?? ""}
                    />
                  </>
                )}
              {canEdit && isPolygon && (
                <Tooltip position="top" text="Extract map data">
                  <IconBtn
                    iconColor={colors.iconBrand}
                    active={
                      modalTypeOpen?.modalType === ExtractMapDataModalType
                    }
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: ExtractMapDataModalType,
                        metadata: {
                          selection: selectedProjectFeature,
                        },
                      });
                    }}
                  >
                    <MapItemsIcon />
                  </IconBtn>
                </Tooltip>
              )}
              {canEdit && !isLocked && (
                <Tooltip position="top" text="Set styling">
                  <StylingSelector
                    selectedProjectFeatures={selectedProjectFeatureArr}
                    enableColorStyling={isOfTypeOther}
                    enableOpacityStyling={
                      selectedProjectFeature.properties.type ===
                      GeoTiffUserUploadedImageType
                    }
                  />
                </Tooltip>
              )}
              {canEdit && isTurbineFeature && (
                <>
                  <Tooltip position="top" text="Display turbine ellipses">
                    <TurbineEllipsesSettings
                      selectedProjectFeatures={selectedProjectFeatureArr}
                    />
                  </Tooltip>
                </>
              )}
            </ToolsWrapper>

            <Divider />
            <ToolsWrapper>
              {!featureIsDerived(selectedProjectFeature) && (
                <>
                  <Tooltip
                    position="top"
                    text="Element properties"
                    readonlyAware
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                      }}
                    >
                      <IconBtn
                        active={showFeatureProperties}
                        ref={featurePropRef}
                        iconColor={colors.iconBrand}
                        onClick={() => {
                          setShowFeatureProperties(!showFeatureProperties);
                          setShowMoreFeatureActions(false);
                        }}
                      >
                        <Paragraph />
                      </IconBtn>
                      {showFeatureProperties && (
                        <FeaturePropertiesMenuFrame
                          canvasFeature={selectedProjectFeature}
                          onClose={() => setShowFeatureProperties(false)}
                        />
                      )}
                    </div>
                  </Tooltip>
                  <Tooltip position="top" text="Add comment" readonlyAware>
                    <IconBtn
                      iconColor={colors.iconBrand}
                      onClick={() => {
                        if (isReadOnly) return;
                        setNewThreadOpen({
                          featureId: selectedProjectFeature.id,
                        });
                        trackCanvasOption("comment", {
                          projectId,
                          branchId,
                        });
                        setCurrentSelectionArray([]);
                      }}
                    >
                      <CommentIcon />
                    </IconBtn>
                  </Tooltip>
                </>
              )}
            </ToolsWrapper>
            <Divider />
            {canEdit && !isLocked && !readonly && (
              <>
                <Tooltip position="top" text={`Delete element`}>
                  <IconBtn iconColor={colors.iconBrand} onClick={deleteFeature}>
                    <Bin />
                  </IconBtn>
                </Tooltip>
                <Divider />
              </>
            )}
            {!featureIsDerived(selectedProjectFeature) && (
              <>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                  }}
                >
                  <IconBtn
                    iconColor={colors.iconBrand}
                    active={showMoreFeatureActions}
                    disabled={!canEdit}
                    ref={moreMenuRef}
                    onClick={() => {
                      setShowMoreFeatureActions(!showMoreFeatureActions);
                      setShowFeatureProperties(false);
                    }}
                  >
                    <MenuVertical />
                  </IconBtn>
                  {showMoreFeatureActions && (
                    <Menu
                      style={{
                        position: "absolute",
                        top: "4.4rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Suspense fallback={<SkeletonText />}>
                        <SingleFeatureMenu
                          feature={selectedProjectFeature}
                          featuresOnPoint={[]}
                          enableShowLayerInfo={false}
                          closeMenu={() => {}}
                          sampleWmsCallback={() => {}}
                          onSelectFeature={() => {}}
                          onMouseEnterFeature={() => {}}
                          onMouseLeaveFeature={() => {}}
                        />
                      </Suspense>
                    </Menu>
                  )}
                </div>

                <Divider />
              </>
            )}
          </>
        )}
      </IconMenu>
    </Popup>
  );
};

export default CanvasSingleSelectOption;
