import { branchIdAtom, projectIdAtom } from "../../state/pathParams";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { useCallback } from "react";
import { trackCanvasOption } from "./MenuTracking";
import Split from "@icons/24/Split.svg?react";
import { multiFeatureToFeatures } from "../../utils/geojson/utils";
import { isMultiFeature } from "../../utils/predicates";
import { IconBtn } from "components/General/Icons";
import { useAtomValue } from "jotai";
import { colors } from "styles/colors";
import { ProjectFeature } from "types/feature";
import { projectFoldersAtomFamily } from "components/ProjectElementsV2/state";
import { useProjectElementsFoldersCrud } from "components/ProjectElementsV2/useProjectElementsFoldersCrud";
import { ProjectElementFolderType } from "components/ProjectElementsV2/service";

const SplitMultiPart = ({
  projectDataFeatures,
}: {
  projectDataFeatures: ProjectFeature[];
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const projectFolders = useAtomValue(
    projectFoldersAtomFamily({
      projectId,
      branchId,
      version: undefined,
    }),
  );
  const { update: updateFolder } = useProjectElementsFoldersCrud();
  const { update: updateFeatures } = useProjectElementsCrud();
  const splitMultiFeaturesIntoParts = useCallback(() => {
    trackCanvasOption("split-feature", {
      projectId,
      branchId,
    });
    const multiFeatures = projectDataFeatures.filter(isMultiFeature);
    if (multiFeatures.length === 0) return;

    const featureIdToFolderMap = projectFolders.reduce((acc, folder) => {
      folder.featureIds.forEach((featureId) => {
        acc.set(featureId.id, folder.folderId);
      });
      return acc;
    }, new Map<string, string>());

    const splitFeaturesAndFolder = multiFeatures.reduce(
      (acc, multiFeature) => {
        const folderId = featureIdToFolderMap.get(multiFeature.id);
        const folder = projectFolders.find((f) => folderId === f.folderId);
        const splitFeatures = multiFeatureToFeatures(multiFeature);
        acc.features.push(...splitFeatures);
        if (!folderId || !folder) return acc;

        if (!(folderId in acc.folders)) {
          acc.folders[folderId] = folder;
        }
        acc.folders[folderId].featureIds = [
          ...acc.folders[folderId].featureIds,
          ...splitFeatures.map((f) => ({
            type: "feature" as const,
            id: f.id,
          })),
        ];

        return acc;
      },
      {
        features: [] as ProjectFeature[],
        folders: {} as Record<string, ProjectElementFolderType>,
      },
    );

    updateFeatures({
      add: splitFeaturesAndFolder.features,
      remove: multiFeatures.map((f) => f.id),
    });

    Object.values(splitFeaturesAndFolder.folders).forEach((newFolder) => {
      updateFolder(newFolder);
    });
  }, [
    projectId,
    branchId,
    updateFeatures,
    projectDataFeatures,
    projectFolders,
    updateFolder,
  ]);
  return (
    <>
      <IconBtn
        iconColor={colors.iconBrand}
        onClick={splitMultiFeaturesIntoParts}
      >
        <Split />
      </IconBtn>
    </>
  );
};

export default SplitMultiPart;
