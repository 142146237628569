import React from "react";
import { SkeletonRound, SkeletonText } from "../Loading/Skeleton";
import { Row } from "../General/Layout";

export function UserInfoLoading() {
  return (
    <Row>
      <SkeletonRound size={24} />
      <SkeletonText
        style={{
          width: "6rem",
          height: "1.6rem",
        }}
      />
    </Row>
  );
}
