import { useAtom, useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import AlarmIcon from "@icons/24/Alarm.svg?react";
import { useAuth0 } from "@auth0/auth0-react";
import { Row } from "components/General/Layout";
import { MenuItem } from "components/General/Menu";
import VindLogo from "@icons/vindlogo/vindLogo.svg?react";
import SinglePersonIcon from "@icons/24/SinglePerson.svg?react";
import LogoutIcon from "@icons/14/Logout.svg?react";
import SettingsIcon from "@icons/24/Settings.svg?react";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import { TitleBarDiv, VindLogoWrapper } from "components/Projects/styles";
import { UserProfileModalType } from "components/UserProfileModal/UserProfileModal";
import { invalidateToken } from "services/invalidateTokenServiceAPI";
import { midScreenModalTypeOpenAtom, modalTypeOpenAtom } from "state/modal";
import {
  loggedInUserAtom,
  LOCAL_STORAGE_TOKEN_KEY,
  memberInOrganisationSelectorFamily,
} from "state/user";
import { organisationsState } from "./state";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import OrganisationImageRound from "./Image/OrganisationImageRound";
import { colors } from "styles/colors";
import { VerticalDivider } from "components/General/VerticalDivider";
import { OrgSettingsModalType } from "./OrgSettings/OrgSettingsModal";
import { MenuButton } from "components/General/MenuButton";
import { SVGStrokeWrapper } from "@icons/svgUtils";
import InviteToOrganisationButton from "./Invites/InviteToOrganisation/InviteToOrganisationButton";
import { IconREMSize } from "styles/typography";
import Notifications, {
  notificationOpenState,
} from "components/NotificationSystem/Notification";
import { useSetAtom } from "jotai";
import ProfileDropdownMenuButton from "components/ProfileDropdownMenuButton/ProfileDropdownMenuButton";
import SearchWrapper from "components/Search/SearchWrapper";
import SearchButtonV2 from "components/Design/TopbarNavigation/components/RightSide/SearchButtonV2";

export const ProjectPageTitleBar = () => {
  const { logout } = useAuth0();
  const organisationId = useAtomValue(organisationIdAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const user = useAtomValue(loggedInUserAtom);
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete("page");

  const setNotificationOpen = useSetAtom(notificationOpenState);

  const link = `/organisation/${organisationId}/projects`;

  const [, setContent] = useAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  return (
    <TitleBarDiv>
      <VindLogoWrapper>
        <Link
          onClick={() => {
            setContent(undefined);
            setModalTypeOpen(undefined);
            setMidScreenModalTypeOpen(undefined);
          }}
          to={{
            pathname: link,
            search: queryParams.toString(),
          }}
          style={{
            textDecoration: "none",
          }}
        >
          <IconREMSize height={2} width={11}>
            <VindLogo />
          </IconREMSize>
        </Link>
      </VindLogoWrapper>
      <Row
        style={{
          gap: "2.4rem",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            gap: "0.8rem",
            alignItems: "center",
          }}
        >
          <OrgSelection />
          {isMemberInOrg && (
            <InviteToOrganisationButton buttonType="secondary" />
          )}
        </Row>
        <VerticalDivider />
        <SearchButtonV2 />
        <SearchWrapper
          title="Notifications"
          tags={["notifications", "alerts", "updates"]}
          id="search-notifications"
          icon={<AlarmIcon />}
          onSelect={() => {
            setNotificationOpen(true);
          }}
        >
          <Notifications />
        </SearchWrapper>
        {user && (
          <ProfileDropdownMenuButton>
            <SearchWrapper
              title="Profile settings"
              tags={[
                "profile",
                "settings",
                "customise",
                "user",
                "me",
                "profile settings",
              ]}
              id="search-profile"
              icon={<SinglePersonIcon />}
              onSelect={() => {
                setMidScreenModalTypeOpen({
                  modalType: UserProfileModalType,
                  metadata: {
                    user,
                  },
                });
              }}
            >
              <MenuItem
                icon={<SinglePersonIcon />}
                name="Profile settings"
                onClick={() => {
                  setMidScreenModalTypeOpen({
                    modalType: UserProfileModalType,
                    metadata: {
                      user,
                    },
                  });
                }}
              />
            </SearchWrapper>
            <MenuItem
              icon={<LogoutIcon />}
              name="Log out"
              onClick={async () => {
                localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
                await invalidateToken();
                logout({
                  returnTo: "https://vind.ai",
                });
              }}
            />
          </ProfileDropdownMenuButton>
        )}
      </Row>
    </TitleBarDiv>
  );
};

function OrgSelection() {
  const organisations = useAtomValue(organisationsState);
  const organisationId = useAtomValue(organisationIdAtom);
  const navigate = useNavigate();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const currentOrg = organisations.find((org) => org.id === organisationId);
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  return (
    <Row
      style={{
        alignItems: "center",
        gap: "0",
      }}
    >
      {currentOrg && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchWrapper
            title="Organisation settings"
            tags={["organisation", "settings", "customise", "tags", "stages"]}
            id="search-organisation"
            icon={<SettingsIcon />}
            onSelect={() =>
              setModalTypeOpen({
                modalType: OrgSettingsModalType,
              })
            }
          >
            <OrganisationImageRound
              onClick={() =>
                setModalTypeOpen({
                  modalType: OrgSettingsModalType,
                })
              }
              organisation={currentOrg}
              size={3.2}
              thumbnailSize={256}
              style={{
                border: "1px solid white",
                borderRadius: "50%",
              }}
            />
          </SearchWrapper>
        </div>
      )}
      {organisations.length > 1 ? (
        <MenuButton
          icon={
            <SVGStrokeWrapper size={1}>
              <ArrowDownIcon />
            </SVGStrokeWrapper>
          }
          iconOpen={
            <SVGStrokeWrapper size={1}>
              <ArrowDownIcon />
            </SVGStrokeWrapper>
          }
          buttonStyle={{
            border: "none",
            flexDirection: "row-reverse",
            backgroundColor: "transparent",
            color: "white",
            gap: "1.2rem",
          }}
          buttonType="dropdown"
          buttonText={currentOrg?.name ?? ""}
          offset={[0, 5]}
        >
          {isMemberInOrg && (
            <>
              <MenuItem
                name={"Organisation settings"}
                onClick={() =>
                  setModalTypeOpen({
                    modalType: OrgSettingsModalType,
                  })
                }
                icon={<SettingsIcon />}
              />
              <div
                style={{
                  width: "100%",
                  borderTop: `1px solid ${colors.inputOutline}`,
                }}
              />
            </>
          )}
          {organisations.map((org) => (
            <MenuItem
              key={org.id}
              name={org.name ?? ""}
              onClick={() => {
                const newPath = `/organisation/${org.id}${location.search}`;
                navigate(newPath);
              }}
              icon={
                <OrganisationImageRound
                  organisation={org}
                  size={1.6}
                  thumbnailSize={256}
                />
              }
            />
          ))}
        </MenuButton>
      ) : (
        <h4
          style={{
            margin: "0 0.8rem",
            color: "white",
          }}
        >
          {currentOrg?.name}
        </h4>
      )}
    </Row>
  );
}
