import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import GeoTIFF, { fromBlob } from "geotiff";
import { getGeotiffImage } from "../services/projectDataAPIService";
import { GeoTiffUserUploadedImageType } from "../services/types";

export const imageToGeorefAtom = atom<undefined | File>(undefined);

export const getGeorefImageSelectorFamily = atomFamily(
  ({
    projectId,
    filename,
  }: {
    projectId: string;
    branchId: string;
    filename: string;
  }) =>
    atom<Promise<GeoTIFF | undefined>>(async () => {
      if (!projectId) return;

      const blob = await getGeotiffImage(
        projectId,
        filename,
        GeoTiffUserUploadedImageType,
      );
      return blob ? await fromBlob(blob) : undefined;
    }),
);
