import { atom } from "jotai";
import {
  CustomWindFileType,
  WindDataSource,
  WindDataset,
  _CustomWindSource,
  _WindSource,
} from "types/metocean";
import { haversineDistance } from "utils/geometry";
import { atomFamily, atomFromFn } from "utils/jotai";
import { isDefined } from "utils/predicates";
import { sendWarning } from "utils/sentry";
import { z } from "zod";
import { nDirectionsWindRoseVisualisation } from "../../components/ProductionV2/constants";
import {
  fetchAvailableWindDatasets,
  fetchCustomWindRose,
  fetchSimpleWindRose,
  fetchWindData,
  fetchWindRose,
} from "../../services/metoceanService";
import { fetchEnhancer, fetchSchemaWithToken } from "../../services/utils";
import {
  WindSourceConfiguration,
  isBestWindSourceConfiguration,
  isBuiltInWindSourceConfiguration,
  isCustomWindSource,
  isMultipleSourceWindConfiguration,
} from "../../services/windSourceConfigurationService";

export const _WRGSector = z.object({
  probability: z.number(),
  A: z.number(), // Weibull A parameter
  k: z.number(), // Weibull k parameter
});
export type WRGSector = z.infer<typeof _WRGSector>;

export const _WRGPoint = z.object({
  easting: z.number(),
  northing: z.number(),
  lon: z.number(),
  lat: z.number(),
  sectors: _WRGSector.array(),
  A: z.number(),
  k: z.number(),
  power: z.number(),
  elevation: z.number(),
  height: z.number(),
});
export type WRGPoint = z.infer<typeof _WRGPoint>;

export const _WRG = z.object({
  points: _WRGPoint.array(),
  easting: z.number(),
  northing: z.number(),
  lon: z.number(),
  lat: z.number(),
  height: z.number(),
});

export type WRG = z.infer<typeof _WRG>;

export const _MeanSpeedGrid = z.object({
  /** number of columns in the grid */
  ncols: z.number(),
  /** number of rows in the grid */
  nrows: z.number(),
  /** distance between rows/columns */
  dx: z.number(),
  dy: z.number(),
  /** x-coordinate of lower left corner */
  xllcorner: z.number(),
  /** y-coordinate of lower left corner */
  yllcorner: z.number(),
  /** grid values */
  grid: z.number().array().array(),
  height: z.number(),
});

const _SpatialCalibrationData = _MeanSpeedGrid.or(_WRG);

export type MeanSpeedGrid = z.infer<typeof _MeanSpeedGrid>;

export const _MeanSpeedGridCustom = _MeanSpeedGrid.extend({
  lon: z.number().optional(),
  lat: z.number().optional(),
});

export type MeanSpeedGridCustom = z.infer<typeof _MeanSpeedGridCustom>;

export const _WindTimeseries = z.object({
  speed: z.number().array(),
  direction: z.number().array(),
  alpha: z.number().array().or(z.number()),
  timestamps: z.string().array().optional(),
  longitude: z.number(),
  latitude: z.number(),
  height: z.number(),
  source: _WindSource,
});
export type WindTimeseries = z.infer<typeof _WindTimeseries>;

export const _WindData = z.object({
  timestamps: z.string().array(),
  speed: z.number().array(),
  direction: z.number().array(),
  alpha: z.number().array().or(z.number()),
  lon: z.number(),
  lat: z.number(),
  height: z.number(),
  uploaded: z.number().optional(),
});

export const _WindDataMultipleHeights = z.object({
  timestamps: z.string().array(),
  speed: z.record(z.array(z.number())),
  direction: z.record(z.array(z.number())),
  lon: z.number().default(0),
  lat: z.number().default(0),
  heights: z.number().array(),
  uploaded: z.number().optional(),
});

export const _WindDataVortex = _WindData.extend({
  density: z.number().array().optional(),
});
export type WindData = z.infer<typeof _WindData>;
export type WindDataVortex = z.infer<typeof _WindDataVortex>;
export type WindDataMultipleHeights = z.infer<typeof _WindDataMultipleHeights>;

export const isWindDataMultipleHeights = (
  data: WindData | WindDataVortex | WindDataMultipleHeights,
): data is WindDataMultipleHeights => {
  return "heights" in data;
};

export const _RawWindDirection = z.object({
  direction: z.number(),
  probability: z.number(),
  meanSpeed: z.number(),
  speedProbabilities: z.number().array(),
});

const _WindSpeed = z.object({
  from: z.number(),
  to: z.number(),
});

export const _RawWindRose = z.object({
  directions: _RawWindDirection.array(),
  speeds: _WindSpeed.array(),
  windSpeeds: z.number().array(),
  speedProbabilities: z.number().array(),
  meanWindSpeed: z.number(),
  height: z.number(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  source: z.union([_WindSource, _CustomWindSource]).optional(),
  alpha: z.number(),
});
export type RawWindRose = z.infer<typeof _RawWindRose>;

export const _SimpleWindRose = z.object({
  directions: z
    .object({
      direction: z.number(),
      probability: z.number(),
      meanSpeed: z.number(),
    })
    .array(),
  longitude: z.number(),
  latitude: z.number(),
  height: z.number(),
  alpha: z.number(),
  speeds: z.number().array(),
  speedProbabilities: z.number().array(),
});

export type SimpleWindRose = z.infer<typeof _SimpleWindRose>;

export const _YearlyMonthlyWindRoses = z.object({
  yearly: _RawWindRose,
});

export const _UploadedWindData = z
  .object({
    id: z.string(),
    name: z.string(),
    url: z.string().optional(),
    type: z.string().optional(),
    data: _WindData.or(_WindDataMultipleHeights).optional(),
    lon: z.number(),
    lat: z.number(),
  })
  .array();
export type UploadedWindData = z.infer<typeof _UploadedWindData>;

export const _WindStats = z.object({
  /** 1-year wind speed (1-hour value) */
  windSpeed1Yr: z.number(),
  /** dataset used to extract wind stats (NORA3, ERA5, ..) */
  windDataSource: z.string(),
});

export enum InstallationType {
  Turbine = "turbines",
  Cable = "array cables",
  Mooring = "mooring",
  Substation = "substations",
  ExportCable = "export cables",
  Floater = "floaters",
  Monopile = "monopiles",
  Jacket = "jackets",
  ScourProtection = "scour protection",
}

const _InstallationSequence = z
  .object({
    id: z.string(),
    mileStone: z.boolean().optional(),
    duration: z.number(),
    useWaveLim: z.boolean(),
    useWindLim: z.boolean(),
  })
  .array();

export type InstallationSequence = z.infer<typeof _InstallationSequence>;

const _InstallationAnalysisInput = z.object({
  weatherLimits: z.object({
    parkWaveLim: z.number(),
    parkWindLim: z.number(),
    transitWaveLim: z.number(),
    transitWindLim: z.number(),
  }),
  startMonth: z.number().min(1).max(12),
  endMonth: z.number().min(1).max(12),
  installationSequence: _InstallationSequence,
});

export type InstallationAnalysisInput = z.infer<
  typeof _InstallationAnalysisInput
>;

export const _InstallationAnalysisResult = z.object({
  installationTimePerStep: z
    .object({
      year: z.number(),
      duration: z.number().array(),
    })
    .array(),
  totalInstallationTimeDistribution: z.object({
    mean: z.number(),
    std: z.number(),
  }),
  totalInstallationTimePercentiles: z
    .object({
      percentile: z.number(),
      value: z.number(),
    })
    .array(),
});

export type InstallationAnalysisResult = z.infer<
  typeof _InstallationAnalysisResult
>;

export const uploadedWindDataFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomFromFn<Promise<UploadedWindData>>(async () => {
      if (!nodeId) return [];
      const uploadedData = await fetchSchemaWithToken(
        _UploadedWindData,
        `/api/configuration/wind/node/${nodeId}`,
        {
          method: "get",
        },
      );
      return uploadedData;
    }),
);

export const uploadedWindTimeseriesFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atom<Promise<UploadedWindData>>(async (get) => {
      if (!nodeId) return [];
      const uploadedWindData: UploadedWindData = await get(
        uploadedWindDataFamily({ nodeId }),
      );
      const windDataSets = uploadedWindData.filter(
        (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
      );
      const enrichedUploadedData = await Promise.all(
        windDataSets.map(async (windData) => {
          if (windData.url) {
            return fetchEnhancer(windData.url, {
              method: "get",
            }).then(async (response) => {
              if (!response.ok) {
                sendWarning("Failed to fetch custom wind data source", {
                  url: windData.url,
                  statusCode: response.status,
                });
                return undefined;
              }

              const body = await response.json();
              const fetchedData = _WindData
                .or(_WindDataMultipleHeights)
                .parse(body);

              return {
                ...windData,
                data: fetchedData,
              };
            });
          } else {
            return windData;
          }
        }),
      );
      return enrichedUploadedData.filter(isDefined);
    }),
);

export const uploadedWRGFileFamily = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atom<Promise<{ id: string; wrg: WRG }[]>>(async (get) => {
      if (!nodeId) return [];
      const uploadedWindData: UploadedWindData = await get(
        uploadedWindDataFamily({ nodeId }),
      );
      const wrgFiles = uploadedWindData.filter(
        (d) => d.type === CustomWindFileType.WRG,
      );
      return await Promise.all(
        wrgFiles.map(async (wrgFile) => {
          if (wrgFile.url) {
            return fetchEnhancer(wrgFile.url, {
              method: "get",
            }).then(async (response) => {
              if (!response.ok)
                throw new Error(
                  "Failed to fetch WRG file " +
                    wrgFile.id +
                    " for node " +
                    nodeId,
                );

              const body = await response.json();
              const wrg = _WRG.parse(body);

              return { id: wrgFile.id, wrg };
            });
          } else {
            throw new Error(
              "Failed to fetch WRG file " + wrgFile.id + " for node " + nodeId,
            );
          }
        }),
      );
    }),
);

export const uploadedMeanSpeedGridFamily = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atom<Promise<{ id: string; data: MeanSpeedGridCustom | undefined }[]>>(
      async (get) => {
        if (!nodeId) return [];
        const uploadedWindData: UploadedWindData = await get(
          uploadedWindDataFamily({ nodeId }),
        );
        const meanSpeedGridFiles = uploadedWindData.filter(
          (d) => d.type === CustomWindFileType.MEAN_SPEED_GRID,
        );
        return await Promise.all(
          meanSpeedGridFiles.map(async (file) => {
            if (file.url) {
              return fetchEnhancer(file.url, {
                method: "get",
              }).then(async (response) => {
                if (!response.ok)
                  throw new Error(
                    "Failed to fetch Mean speed grid file " +
                      file.id +
                      " for node " +
                      nodeId,
                  );

                const body = await response.json();
                const meanSpeedgrid = _MeanSpeedGridCustom.parse(body);

                return { id: file.id, data: meanSpeedgrid };
              });
            } else {
              throw new Error(
                "Failed to fetch Mean speed grid file " +
                  file.id +
                  " for node " +
                  nodeId,
              );
            }
          }),
        );
      },
    ),
);

export const mostProbableWindDirectionFamily = atomFamily(
  ({
    lon,
    lat,
    height,
    fromYear = 1990,
    toYear = 2022,
  }: {
    lon: number;
    lat: number;
    height: number;
    fromYear: undefined | number;
    toYear: undefined | number;
  }) =>
    atom<Promise<number>>(async (get) => {
      const { source } = await get(bestWindDatasetFamily({ lon, lat }));

      const data = await get(
        windRoseFamily({
          lon,
          lat,
          height,
          source: source.id,
          fromYear,
          toYear,
          numberOfDirections: undefined,
        }),
      );

      return data.directions.reduce(
        (md, d) => (md.probability > d.probability ? md : d),
        { probability: 0, direction: 0 },
      ).direction;
    }),
);

export const windRoseFromConfigFamily = atomFamily(
  ({
    lon,
    lat,
    height,
    numberOfDirections = nDirectionsWindRoseVisualisation,
    config,
    projectId,
  }: {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections: number | undefined;
    config: WindSourceConfiguration | undefined;
    projectId: string;
  }) =>
    atom<Promise<SimpleWindRose | undefined>>(async () => {
      if (!config) return;
      if (
        isBestWindSourceConfiguration(config) ||
        isBuiltInWindSourceConfiguration(config)
      ) {
        return await fetchSimpleWindRose({
          lon,
          lat,
          height,
          numberOfDirections,
          sourceId: config.source.id,
        });
      } else if (isMultipleSourceWindConfiguration(config)) {
        const closestSource = config.source.data.reduce((prev, curr) => {
          const prevDistance = haversineDistance(
            prev.latitude ?? 0,
            prev.longitude ?? 0,
            lat,
            lon,
          );
          const currDistance = haversineDistance(
            curr.latitude ?? 0,
            curr.longitude ?? 0,
            lat,
            lon,
          );
          return currDistance < prevDistance ? curr : prev;
        });

        return await fetchCustomWindRose({
          height,
          numberOfDirections,
          sourceId: closestSource.id,
          nodeId: projectId,
        });
      } else if (isCustomWindSource(config.source) && config.source.id) {
        return await fetchCustomWindRose({
          height,
          numberOfDirections,
          sourceId: config.source.id,
          nodeId: projectId,
        });
      }
    }),
);

const windRoseFamily = atomFamily(
  ({
    lon,
    lat,
    height,
    numberOfDirections = nDirectionsWindRoseVisualisation,
    source,
    fromYear = 1990,
    toYear = 2022,
  }: {
    lon: number;
    lat: number;
    height: number;
    numberOfDirections: undefined | number;
    source: WindDataSource;
    fromYear: undefined | number;
    toYear: undefined | number;
  }) =>
    atom<Promise<RawWindRose>>(async () => {
      return fetchWindRose({
        lon,
        lat,
        height,
        numberOfDirections,
        source,
        fromYear,
        toYear,
      });
    }),
);

export const simpleWindRoseFamily = atomFamily(
  ({
    lon,
    lat,
    height,
    source,
    numberOfDirections = nDirectionsWindRoseVisualisation,
  }: {
    lon: number;
    lat: number;
    height: number;
    source: WindDataSource;
    numberOfDirections: number | undefined;
  }) =>
    atom<Promise<SimpleWindRose>>(async () => {
      return fetchSimpleWindRose({
        lon,
        lat,
        height,
        sourceId: source,
        numberOfDirections,
      });
    }),
);

export const windTimeseriesFamily = atomFamily(
  ({
    lon,
    lat,
    height,
    source,
    fromYear = 1990,
    toYear = 2022,
  }: {
    lon: number;
    lat: number;
    height: number;
    source: WindDataSource;
    fromYear: undefined | number;
    toYear: undefined | number;
  }) => {
    return atom<Promise<WindTimeseries>>(async () => {
      return fetchWindData({
        source,
        lon,
        lat,
        height,
        fromYear,
        toYear,
      });
    });
  },
);

const bestWindDatasetFamily = atomFamily(
  ({ lon, lat }: { lon: number; lat: number }) =>
    atom<Promise<WindDataset>>(async (get) => {
      const datasets = await get(windDatasetsFamily({ lon, lat }));

      return datasets[0];
    }),
);

export const windDatasetsFamily = atomFamily(
  ({ lon, lat }: { lon: number; lat: number }) =>
    atom<Promise<WindDataset[]>>(async () => {
      return fetchAvailableWindDatasets({ lon, lat });
    }),
);
