import { useAtomValue } from "jotai";
import React from "react";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import DashboardIcon from "@icons/12/Dashboard.svg";
import SearchWrapper from "components/Search/SearchWrapper";
import { mapInteractionSelectorProjectControl } from "state/map";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { Mixpanel } from "mixpanel";
import { useAtom } from "jotai";

const DashboardModalButtonV2 = () => {
  const [modalTypeOpen, setModalType] = useAtom(modalTypeOpenAtom);
  const projectControl = useAtomValue(mapInteractionSelectorProjectControl);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);

  if (!projectControl) return null;

  return (
    <SearchWrapper
      title="Dashboard"
      id="search-dashboard"
      icon={<DashboardIcon />}
      tags={[
        "overview",
        "statistics",
        "download",
        "share",
        "export",
        "presentation",
      ]}
      onSelect={() => {
        if (readOnlyMode) {
          return;
        }
        setModalType({
          modalType: DashboardModalType,
        }),
          Mixpanel.track_old("Open full screen modal", {
            type: DashboardModalType,
          });
      }}
    >
      <Tooltip
        theme="light"
        position="bottom"
        text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Dashboard"}
        disabled={!readOnlyMode}
      >
        <MiddleItemWrapper
          className="middle-item-wrapper"
          active={modalTypeOpen?.modalType === DashboardModalType}
          disabled={readOnlyMode}
          onClick={() => {
            if (readOnlyMode) {
              return;
            }
            setModalType((curr) =>
              curr?.modalType !== DashboardModalType
                ? {
                    modalType: DashboardModalType,
                  }
                : undefined,
            );
            Mixpanel.track_old("Open full screen modal", {
              type: DashboardModalType,
            });
          }}
        >
          <RoundIcon fillOrStroke="fill">
            <DashboardIcon />
          </RoundIcon>
          Dashboard
        </MiddleItemWrapper>
        {}
      </Tooltip>
    </SearchWrapper>
  );
};

export default DashboardModalButtonV2;
