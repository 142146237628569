import { IconBtn } from "components/General/Icons";
import OpenRight from "@icons/24/OpenWindowRight.svg";
import { useRef, useState } from "react";
import { colors } from "styles/colors";
import {
  OptimizeCostInput,
  OptimizeMethod,
  OptimizeRuntime,
} from "services/configurationService";
import Tooltip from "components/General/Tooltip";
import { LCOESettingsMenuInner } from "./LCOESettings";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useClickOutside } from "hooks/useClickOutside";
import { Grid2, Label } from "components/General/Form";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { Input, InputDimensioned } from "components/General/Input";
import { useAtomValue } from "jotai";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { projectWindConfigurationsFamily } from "state/jotai/windConfiguration";
import { projectIdAtom } from "state/pathParams";

export const OptimizationInput = ({
  method,
  costInput,
  objective,
  turbineId,
  minSpacing,
  numberOfTurbines,
  minNumberOfTurbines,
  windConfigurationId,
  runtime,
}: {
  method: OptimizeMethod;
  costInput: OptimizeCostInput | undefined;
  objective: "lcoe" | "aep";
  turbineId: string;
  minSpacing: number | undefined;
  numberOfTurbines: number | undefined;
  minNumberOfTurbines: number | undefined | null;
  windConfigurationId: string | undefined | null;
  runtime: OptimizeRuntime | undefined;
}) => {
  const [openOptimizationInputSubmenu, setOpenOptimizationInputSubmenu] =
    useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(
    ref,
    () => {
      setOpenOptimizationInputSubmenu(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "opt-input-open";
    },
  );

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip text="See inputs">
          <IconBtn
            active={openOptimizationInputSubmenu}
            backgroundColor={colors.surfaceButtonSecondary}
            size="1.4rem"
            id="opt-submenu-open-opt-input"
            onClick={() => {
              setOpenOptimizationInputSubmenu(!openOptimizationInputSubmenu);
            }}
          >
            <OpenRight />
            <p style={{ marginLeft: "0.5rem" }}>See input</p>
          </IconBtn>
        </Tooltip>
      </div>
      {openOptimizationInputSubmenu && (
        <MenuFrame
          id={"optimize-input-frame"}
          title="Optimization inputs"
          style={{
            display: "flex",
            padding: "1.6rem 0rem 2rem 0rem",
            position: "absolute",
            left: "calc(100% + 0.8rem)",
            top: 0,
            width: "30rem",
          }}
        >
          <OptimizationInputInner
            method={method}
            costInput={costInput}
            objective={objective}
            turbineId={turbineId}
            minSpacing={minSpacing}
            numberOfTurbines={numberOfTurbines}
            minNumberOfTurbines={minNumberOfTurbines ?? undefined}
            windConfigurationId={windConfigurationId}
            runtime={runtime}
          />
        </MenuFrame>
      )}
    </>
  );
};

const OptimizationInputInner = ({
  method,
  costInput,
  objective,
  turbineId,
  minSpacing,
  numberOfTurbines,
  minNumberOfTurbines,
  windConfigurationId,
  runtime,
}: {
  method: OptimizeMethod;
  costInput: OptimizeCostInput | undefined;
  objective: "lcoe" | "aep";
  turbineId: string;
  minSpacing: number | undefined;
  numberOfTurbines: number | undefined;
  minNumberOfTurbines: number | undefined;
  windConfigurationId: string | undefined | null;
  runtime: OptimizeRuntime | undefined;
}) => {
  return (
    <>
      <Grid2>
        {method === "regular" && (
          <>
            <TurbineTypeInput turbineTypeId={turbineId} />
            <MinSpacingInput minSpacing={minSpacing} />
            <WindConfigInput windConfigId={windConfigurationId} />
          </>
        )}
        {method === "irregular" && (
          <>
            <TurbineTypeInput turbineTypeId={turbineId} />
            <MinSpacingInput minSpacing={minSpacing} />
            <WindConfigInput windConfigId={windConfigurationId} />
            <RuntimeInput runtime={runtime} />
          </>
        )}
        {method === "micrositing" && (
          <>
            <WindConfigInput windConfigId={windConfigurationId} />
            <RuntimeInput runtime={runtime} />
          </>
        )}
        {method === "exploration" && (
          <>
            <TurbineTypeInput turbineTypeId={turbineId} />
            <MinSpacingInput minSpacing={minSpacing} />
            <NumberOfTurbinesInput
              minNumberOfTurbines={minNumberOfTurbines}
              numberOfTurbines={numberOfTurbines}
            />
            <WindConfigInput windConfigId={windConfigurationId} />
          </>
        )}
      </Grid2>

      {objective === "lcoe" && costInput && (
        <>
          <h5 style={{ marginTop: "2rem", color: colors.grey700 }}>
            Cost input
          </h5>
          <LCOESettingsMenuInner costInput={costInput} disabled={true} />
        </>
      )}
    </>
  );
};

const NumberOfTurbinesInput = ({
  numberOfTurbines,
  minNumberOfTurbines,
}: {
  numberOfTurbines: number | undefined;
  minNumberOfTurbines: number | undefined;
}) => {
  return (
    <Label>
      <InputTitle>Number of turbines</InputTitle>
      <InputDimensioned
        style={{ width: "90%" }}
        compact
        value={minNumberOfTurbines}
        unit="min"
        disabled={true}
      />
      <InputDimensioned
        style={{ width: "90%" }}
        compact
        value={numberOfTurbines}
        unit="max"
        disabled={true}
      />
    </Label>
  );
};

const TurbineTypeInput = ({ turbineTypeId }: { turbineTypeId: string }) => {
  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);
  const turbineType = allTurbineTypes.get(turbineTypeId);
  if (!turbineType) {
    return null;
  }
  return (
    <Label>
      <InputTitle>Turbine type</InputTitle>
      <Tooltip text={turbineType.turbine.name}>
        <Input
          style={{ width: "90%" }}
          type="text"
          compact
          value={turbineType.turbine.name}
          disabled={true}
        />
      </Tooltip>
    </Label>
  );
};

const MinSpacingInput = ({
  minSpacing,
}: {
  minSpacing: number | undefined;
}) => {
  if (!minSpacing) {
    return null;
  }
  return (
    <Label>
      <InputTitle>Minimum spacing</InputTitle>
      <InputDimensioned
        style={{ width: "90%" }}
        compact
        value={minSpacing}
        unit="D"
        disabled={true}
      />
    </Label>
  );
};

const WindConfigInput = ({
  windConfigId,
}: {
  windConfigId: string | undefined | null;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const windConfigs = useAtomValue(
    projectWindConfigurationsFamily(projectId ?? ""),
  );
  const windConfig = windConfigs.get(windConfigId ?? "");
  if (!projectId || !windConfig) {
    return null;
  }
  return (
    <Label>
      <InputTitle>Wind configuration</InputTitle>
      <Tooltip text={windConfig.name ?? ""}>
        <Input
          style={{ width: "90%" }}
          type="text"
          compact
          value={windConfig.name}
          disabled={true}
        />
      </Tooltip>
    </Label>
  );
};

const RuntimeInput = ({
  runtime,
}: {
  runtime: OptimizeRuntime | undefined;
}) => {
  if (!runtime) {
    return null;
  }
  const runtimeText = runtime === "fast" ? "Fast" : "Medium";

  return (
    <Label>
      <InputTitle>Precision</InputTitle>
      <Input
        style={{ width: "90%" }}
        type="text"
        compact
        value={runtimeText}
        disabled={true}
      />
    </Label>
  );
};
