import { _UserAccessRole } from "types/user";
import * as spec from "../../../api/customer";
import { z } from "zod";

export const {
  Group: _Group,
  GroupNodeAccess: _GroupNodeAccess,
  UserNodeAccess: _UserNodeAccess,
  UserNodeAccessWithMeta: API_UserNodeAccessWithMeta,
  GroupNodeAccessWithMeta: API_GroupNodeAccessWithMeta,
} = spec.components.schemas;

export type Group = z.infer<typeof _Group>;
export type GroupNodeAccess = z.infer<typeof _GroupNodeAccess>;
export type UserNodeAccess = z.infer<typeof _UserNodeAccess>;

export const _GroupNodeAccessWithMeta = API_GroupNodeAccessWithMeta.and(
  z.object({ resource_name: _UserAccessRole }),
);
export type GroupNodeAccessWithMeta = z.infer<typeof _GroupNodeAccessWithMeta>;

const _UserNodeAccessWithMeta = API_UserNodeAccessWithMeta.and(
  z.object({ resource_name: _UserAccessRole }),
);
export type UserNodeAccessWithMeta = z.infer<typeof _UserNodeAccessWithMeta>;

export const _AccessUnionSchema = z.union([
  _GroupNodeAccessWithMeta,
  _UserNodeAccessWithMeta,
]);
export type AccessUnionSchema = z.infer<typeof _AccessUnionSchema>;

export const _GroupMembership = z.object({
  user_id: z.string(),
  group_id: z.string(),
  created_at: z.number(),
  author: z.string(),
  group_name: z.string(),
});

export type GroupMembership = z.infer<typeof _GroupMembership>;

export function isUserNodeAccessWithMeta(
  object: any,
): object is UserNodeAccessWithMeta {
  return "user_id" in object;
}

export function isGroupNodeAccessWithMeta(
  object: any,
): object is GroupNodeAccessWithMeta {
  return "group_id" in object;
}
