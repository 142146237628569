import { z } from "zod";
import { atomLocalStorage } from "utils/jotai";

export const slopeAnalysisThresholdsPerParkAtom = atomLocalStorage<{
  [key: string]: [number, number][];
}>(
  "vind:slope-analysis:thresholds-per-park",
  {},
  z.record(z.string(), z.tuple([z.number(), z.number()]).array()),
);
