import React, {
  ReactElement,
  ReactNode,
  Suspense,
  useCallback,
  useState,
} from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import DnDsmallIcon from "@icons/12/DnDsmall.svg?react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceLarge } from "../../../styles/space";
import { Comp } from "../../../types/utils";
import { ErrorBoundaryPrintOnly } from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { logError } from "../../ErrorBoundaries/utils";
import { IconBtn } from "../../General/Icons";
import { Column } from "../../General/Layout";
import { DotMenu } from "../../General/MenuButton";
import HelpTooltip from "../../HelpTooltip/HelpTooltip";
import { EditableTextH3Wrapper } from "../../Projects/styles";
import { useDashboardContext } from "../Dashboard";
import { WidgetId } from "../types";
import {
  CardContainer,
  CardDiv,
  DotMenuWrapper,
  Header,
  HoverCardCloseWrapper,
  Scroll,
} from "./Base.style";
import { AnalysisError } from "analysis/warnings";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import AnalysisOk from "components/Analysis/AnalysisOk";
import AnalysisErrorAlert from "components/Analysis/AnalysisErrorAlert";

type CardProps = {
  title: string | ReactNode;
  widgetId: WidgetId;
  menu?: ReactElement;
  helptext?: string;
};

const Card = ({
  title,
  children,
  widgetId,
  menu,
  helptext,
  ...props
}: React.PropsWithChildren<Comp<"div", CardProps>>) => {
  const { removeWidget, dashboard, canEdit, noMenus } = useDashboardContext();
  const edit = !dashboard.preset && canEdit;
  return (
    <CardDiv {...props} preset={dashboard.preset}>
      <Header>
        <DnDsmallIcon className="dnd-hand" />
        <EditableTextH3Wrapper>{title}</EditableTextH3Wrapper>
        {helptext && !noMenus && <HelpTooltip text={helptext} place="left" />}
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", alignItems: "center" }}>
          {menu && !noMenus && (
            <DotMenuWrapper>
              <DotMenu>{menu}</DotMenu>
            </DotMenuWrapper>
          )}
          {edit && (
            <IconBtn size="1.4rem" onClick={() => removeWidget([widgetId])}>
              <CloseIcon />
            </IconBtn>
          )}
        </div>
      </Header>
      <Scroll>{children}</Scroll>
    </CardDiv>
  );
};

const CornerExitButton = ({ id }: { id: WidgetId }) => {
  const { removeWidget, dashboard, canEdit } = useDashboardContext();
  const edit = !dashboard.preset;
  if (!edit || !canEdit) return null;
  return (
    <HoverCardCloseWrapper
      onClick={() => {
        removeWidget([id]);
      }}
    >
      <CloseIcon />
    </HoverCardCloseWrapper>
  );
};

export const LoadingState = () => (
  <Column style={{ padding: spaceLarge }}>
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  </Column>
);

interface SafeCardErrorFallbackProps
  extends FallbackProps,
    React.PropsWithChildren {
  style?: React.CSSProperties;
  errorMessage?: React.ReactNode;
  buttonText?: string;
  retryCallback?(): void;
}

const MaybeAnalysisErrorFallback = (props: SafeCardErrorFallbackProps) => {
  const { error } = props;
  if (error instanceof AnalysisError) {
    return (
      <CenterContainer>
        <SimpleAlert title="Analysis stopped" text={error.message ?? ""} />
      </CenterContainer>
    );
  }

  return <ErrorBoundaryPrintOnly {...props} />;
};

/**
 * A <Card /> that's both a Suspense- and Error boundary.
 * All widgets should be wrapped in this, so that a widget can crash or load or whatever without
 * crashing the whole dashboard.  Further, this allows the Card and its title to be shown when
 * the.data of the card is loading.
 *
 * If the widget wants to handle its own loading by e.g. `useRecoilStateLoadable` or something,
 * it should return `<LoadingState />` for the loading case.
 */
export const SafeCard = ({
  title,
  id,
  menuItems,
  children,
  fullscreen,
  helptext,
  resetKeys,
  needAnalysis,
}: React.PropsWithChildren<{
  title: string | ReactNode;
  id: WidgetId;
  menuItems?: React.ReactElement;
  /** A fullscreen card does not have a title bar. For instance, the map widgets. */
  fullscreen?: boolean;
  /** Help text that shows up on hover over an (i) icon to the right of the title. */
  helptext?: string;
  resetKeys?: string[];
  /** Set if the card should blank out when the analysis has an error. */
  needAnalysis?: boolean;
}>) => {
  const [hasError, setHasError] = useState(false);
  const onError = useCallback(
    (error: Error, info: React.ErrorInfo) => {
      setHasError(true);
      if (!(error instanceof AnalysisError)) {
        logError(id, error, info);
      }
    },
    [id],
  );
  const { triggerId } = useDashboardContext();

  const onReset = useCallback(() => {
    setHasError(false);
  }, []);

  if (fullscreen) {
    return (
      <CardContainer>
        <ErrorBoundary
          FallbackComponent={MaybeAnalysisErrorFallback}
          onError={onError}
          onReset={onReset}
          resetKeys={resetKeys}
        >
          <Suspense fallback={<LoadingState />}>
            <div style={{ display: "flex", position: "relative", flex: 1 }}>
              {needAnalysis ? (
                <AnalysisOk
                  pid={triggerId}
                  fallback={
                    <CenterContainer>
                      <AnalysisErrorAlert pid={triggerId} />
                    </CenterContainer>
                  }
                >
                  {children}
                </AnalysisOk>
              ) : (
                <>{children}</>
              )}
              <CornerExitButton id={id} />
            </div>
          </Suspense>
        </ErrorBoundary>
      </CardContainer>
    );
  }

  return (
    <Card
      title={title}
      widgetId={id}
      menu={hasError ? undefined : menuItems}
      helptext={helptext}
    >
      <ErrorBoundary
        FallbackComponent={MaybeAnalysisErrorFallback}
        onError={onError}
        onReset={onReset}
        resetKeys={resetKeys}
      >
        <Suspense fallback={<LoadingState />}>
          {needAnalysis ? (
            <AnalysisOk
              pid={triggerId}
              fallback={
                <CenterContainer>
                  <AnalysisErrorAlert pid={triggerId} />
                </CenterContainer>
              }
            >
              {children}
            </AnalysisOk>
          ) : (
            <>{children}</>
          )}
        </Suspense>
      </ErrorBoundary>
    </Card>
  );
};

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
