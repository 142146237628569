import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { cablesInParkFamily } from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";
import { dedup } from "utils/utils";
import { isOnshoreAtom } from "state/onshore";

export const turbineCableWrongVoltageTypeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes>
        | undefined
      >
    >(async (get) => {
      if (get(isOnshoreAtom)) return; // Onshore only have one voltage level.
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const turbineTypes = await get(simpleTurbineTypesAtom);
      const turbineMap = new Map(
        turbines.map((t) => [
          t.id,
          turbineTypes.get(t.properties.turbineTypeId ?? ""),
        ]),
      );

      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));

      const badIds = [];
      for (const c of cables) {
        const typ = cableTypes.get(c.properties.cableTypeId ?? "");
        if (!typ) continue;
        const from = turbineMap.get(c.properties.fromId);
        if (from && !from.voltage.includes(typ.voltage))
          badIds.push(c.properties.fromId);

        const to = turbineMap.get(c.properties.toId);
        if (to && !to.voltage.includes(typ.voltage))
          badIds.push(c.properties.toId);
      }

      if (badIds.length === 0) return;
      return {
        type: ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes,
        featureIds: dedup(badIds),
        parkId: parkId,
      };
    }),
);
