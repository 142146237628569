import Checkbox from "../General/Checkbox";
import {
  renderHouseDistanceFilterTilesLoadingAtom,
  renderHouseDistanceFilterTypeSetAtom,
  renderHouseDistanceFilterAtom as renderResidentialDistanceFilterAtom,
} from "../../state/filter";
import CloseIcon from "@icons/24/Close.svg";
import { Row } from "../General/Layout";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { useAtom, useAtomValue } from "jotai";
import ResidentialDistanceSlider from "components/ResidentialDistanceSlider/ResidentialDistanceSlider";
import Spinner from "@icons/spinner/Spinner";
import {
  maxResidentialFilterZoomLevel,
  availableResidentialBuildingType,
} from "@constants/onshoreFilter";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Button from "components/General/Button";
import Dropdown from "components/Dropdown/Dropdown";
import { useMapZoomLevel } from "hooks/useMapZoomLevel";

const ResidentialFilterEnabledControllers = () => {
  const renderHouseDistanceFilterTileLoading = useAtomValue(
    renderHouseDistanceFilterTilesLoadingAtom,
  );

  const [
    renderHouseDistanceFilterTypeSet,
    setRenderHouseDistanceFilterTypeSet,
  ] = useAtom(renderHouseDistanceFilterTypeSetAtom);
  return (
    <>
      <ResidentialDistanceSlider />

      <Dropdown
        style={{ marginLeft: "1.6rem", width: "fit-content" }}
        onChange={(e) => {
          const newTags = new Set(renderHouseDistanceFilterTypeSet);
          newTags.add(e.target.value);
          setRenderHouseDistanceFilterTypeSet(newTags);
        }}
        small
        value={""}
      >
        <option value="" disabled>
          Filter building type
        </option>
        {Object.values(availableResidentialBuildingType)
          .filter((type) => !renderHouseDistanceFilterTypeSet.has(type))
          .map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
      </Dropdown>
      <Row style={{ flexWrap: "wrap" }}>
        {Array.from(renderHouseDistanceFilterTypeSet).map((t) => (
          <Button
            buttonType="secondary"
            iconPosition="right"
            key={t}
            text={t}
            icon={<CloseIcon style={{ width: "8px" }} />}
            style={{ flexShrink: 0, flexGrow: 1 }}
            onClick={() => {
              const newTags = new Set(renderHouseDistanceFilterTypeSet);
              newTags.delete(t);
              setRenderHouseDistanceFilterTypeSet(newTags);
            }}
            size="tiny"
            title="Remove filter"
          />
        ))}
      </Row>
      <Row
        style={{
          height: "1rem",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        {renderHouseDistanceFilterTileLoading !== 0 && (
          <>
            {`${renderHouseDistanceFilterTileLoading} tiles waiting to process`}
            <Spinner size={"1.2rem"} />
          </>
        )}
      </Row>
    </>
  );
};

const ResidentialFilter = () => {
  const [showResidentialDistance, setResidentialDistance] = useAtom(
    renderResidentialDistanceFilterAtom,
  );
  const mapZoom = useMapZoomLevel();
  const zoomLevelTooLowForResidentialDistance =
    !mapZoom || mapZoom < maxResidentialFilterZoomLevel;

  return (
    <>
      <Row
        style={{
          paddingTop: "0.8rem",
        }}
      >
        <Checkbox
          label={"Distance from residential (m)"}
          labelPlacement="after"
          disabled={zoomLevelTooLowForResidentialDistance}
          checked={showResidentialDistance}
          onChange={(e) => setResidentialDistance(e.target.checked)}
        />
        <HelpTooltip
          text={`Source: Open street maps buildings from Mapbox`}
          size={10}
        />
      </Row>
      {showResidentialDistance && <ResidentialFilterEnabledControllers />}
      {zoomLevelTooLowForResidentialDistance && mapZoom && (
        <SimpleAlert
          type={"warning"}
          text={`Only available at zoom ${maxResidentialFilterZoomLevel}, current zoom ${mapZoom}`}
        />
      )}
    </>
  );
};

export default ResidentialFilter;
