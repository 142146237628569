import styled from "styled-components";
import { colors } from "styles/colors";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { activeFeatureStyles } from "business/style/state";
import {
  Part as CustomStylePart,
  SimplePart as CustomStyleSimplePart,
} from "components/StylingPanel/Legend";
import { useEffect, useMemo, useRef } from "react";
import { zipMerge } from "utils/utils";
import { Style } from "business/style/types";
import { useAtomValue } from "jotai";
import { Color } from "lib/colors";

const LegendItems = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  gap: 0.75rem;
  height: fit-content;
  max-height: 38rem;
  color: ${colors.textPrimary};
`;

const items = [
  { label: "Exclusion zone", color: colors.exclusionZone },
  { label: "Substation", color: colors.substation },
  { label: "Sub area", color: colors.subArea },
  { label: "Export cables", color: colors.exportCableOffshore },
];

export const MapLegend = () => {
  const styles = useAtomValue(activeFeatureStyles);

  // custom styles might be larger, layout wise it makes sense to combine them
  // into a single list using alternate items from each list
  const arraysCombinedMerge = useMemo(
    () => zipMerge<Style | (typeof items)[number]>(styles, items),
    [styles],
  );

  // Crazy deficiency in CSS flexbox: Apparently there's no proper way of
  // having a container with `flex-direction: column; flex-wrap: wrap;`
  // automatically resize its width based on wrapping elements. The row
  // direction works just fine (the height is automatically increased as new
  // rows are being wrapped), but this simply doesn't work with columns.
  // Crazy!
  //
  // https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
  //
  // Instead, use a ref and figure out how wide the container wants to be, and
  // set it to that width.
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const { scrollWidth } = ref.current;
    ref.current.style.width = `${scrollWidth}px`;
  }, []);

  return (
    <>
      <Subtitle>Project elements</Subtitle>
      <LegendItems ref={ref}>
        {arraysCombinedMerge.map((item, i) => {
          if ("id" in item) {
            return <CustomStylePart key={item.id} style={item} />;
          } else {
            return (
              <CustomStyleSimplePart
                key={i}
                name={item.label}
                color={Color.fromHex(item.color)}
              />
            );
          }
        })}
      </LegendItems>
    </>
  );
};
