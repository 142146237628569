import { useMemo } from "react";
import {
  ExclusionZoneFeature,
  ParkFeature,
  TurbineFeature,
} from "../../../types/feature";
import { SimpleTurbineType } from "../../../types/turbines";
import { isDefined } from "../../../utils/predicates";
import { dedup, partition, undefMap, undefMap2 } from "../../../utils/utils";
import useGetRasterStats from "../../Bathymetry/useGetRasterStats";
import * as turf from "@turf/turf";
import { SafeCard } from "./Base";
import { useDashboardContext } from "../Dashboard";
import React from "react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { BATHYMETRY_SOURCE_DESCRIPTION } from "business/bathymetry/utils";
import { useAtomValue } from "jotai";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesFamily } from "state/jotai/exclusionZone";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { useBathymetry } from "hooks/bathymetry";
import { isOnshoreAtom } from "state/onshore";
import { getMinDistanceBetweenTurbines } from "functions/turbines";

const turbineTypeString = (
  turbines: TurbineFeature[],
  allTurbineTypes: Map<string, SimpleTurbineType>,
): string | undefined => {
  const types = dedup(turbines.map((f) => f.properties.turbineTypeId))
    .map((id) => allTurbineTypes.get(id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const formatDepthRange = (min: number, max: number) =>
  `${Math.abs(Math.round(min))} - ${Math.abs(Math.round(max))} m`;

const formatDepth = (depth: number) => `${Math.abs(Math.round(depth))} m`;

const formatMinDistance = (minDistance: number) =>
  minDistance === Infinity ? "-" : `${minDistance} D`;

function getParkAreaWithoutExclusionZones(
  park: ParkFeature,
  exclusionZones: ExclusionZoneFeature[],
): number {
  return Math.round(
    turf.area(
      exclusionZones.reduce<turf.Feature<turf.Polygon | turf.MultiPolygon>>(
        (acc, f) => {
          const diff = turf.difference(acc, f);
          if (diff == null) return acc;
          return diff;
        },
        park,
      ),
    ) /
      (1000 * 1000),
  );
}

function getParkArea(park: ParkFeature): number {
  return Math.round(turf.area(park) / (1000 * 1000));
}

const ParkInner = () => {
  const { projectId, park, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const subAreas = useAtomValue(subAreasInParkFamily({ parkId, branchId }));
  const exclusionZones = useAtomValue(exclusionZonesFamily({ branchId }));
  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const substations = useAtomValue(
    substationsInParkWithTypeFamily({ parkId, branchId }),
  );
  const [onshoreSubstations, offshoreSubstations] = partition(
    substations,
    (s) => s[1].type === "onshore",
  );

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const minDistanceBetweenTurbines = getMinDistanceBetweenTurbines(
    turbines,
    allTurbineTypes,
  );

  const turbineTypesString = useMemo<string | undefined>(
    () => turbineTypeString(turbines, allTurbineTypes),
    [turbines, allTurbineTypes],
  );

  const [, raster] = useBathymetry({
    projectId,
    branchId,
    featureId: park.id,
    bufferKm: 1,
  });
  const bathymetry = useMemo(() => {
    if (!raster) return;
    const [minLon, minLat, maxLon, maxLat] = raster.bbox();
    return {
      raster: raster,
      minLon,
      minLat,
      maxLon,
      maxLat,
    };
  }, [raster]);

  const { minValue, maxValue, averageValue } = useGetRasterStats(
    park,
    bathymetry?.raster,
    bathymetry?.minLon ?? 0,
    bathymetry?.minLat ?? 0,
    bathymetry?.maxLon ?? 0,
    bathymetry?.maxLat ?? 0,
  );

  const area = getParkArea(park);

  const areaWithoutExclusionZones = useMemo(
    () => getParkAreaWithoutExclusionZones(park, exclusionZones),
    [park, exclusionZones],
  );

  const keyValues: [string, number | string | undefined, string?][] =
    useMemo(() => {
      const area_extra: [string, number | string | undefined][] =
        areaWithoutExclusionZones && exclusionZones.length !== 0
          ? [
              [
                "Total area without exlusion zones",
                `${areaWithoutExclusionZones}  km²`,
              ],
            ]
          : [];

      return [
        ["Total area", `${area}  km²`],
        ...area_extra,
        ["Sub area(s)", subAreas.length],
        ["No. Onshore substations", onshoreSubstations.length],
        ["No. Offshore substations", offshoreSubstations.length],
        ["Number of turbines", turbines.length],
        ["Turbine types", turbineTypesString],
        [
          "Min. distance between turbines",
          formatMinDistance(minDistanceBetweenTurbines),
        ],
        [
          "Average depth",
          undefMap(averageValue, formatDepth),
          BATHYMETRY_SOURCE_DESCRIPTION,
        ],
        [
          "Depth range",
          undefMap2(minValue, maxValue, formatDepthRange),
          BATHYMETRY_SOURCE_DESCRIPTION,
        ],
      ];
    }, [
      areaWithoutExclusionZones,
      exclusionZones.length,
      area,
      subAreas.length,
      onshoreSubstations.length,
      offshoreSubstations.length,
      turbines.length,
      turbineTypesString,
      minDistanceBetweenTurbines,
      averageValue,
      minValue,
      maxValue,
    ]);

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {keyValues.map(([key, value, tooltip]) => (
        <React.Fragment key={key}>
          <Row>
            <p style={{ display: "flex", gap: "0.8rem" }}>
              {key}
              {tooltip && <HelpTooltip text={tooltip} />}
            </p>
            {value === undefined ? (
              <SkeletonBlock style={{ width: "6rem", height: "1rem" }} />
            ) : (
              <p>{value}</p>
            )}
          </Row>
        </React.Fragment>
      ))}
    </FlexGrid2>
  );
};

const ParkInnerOnshore = () => {
  const { park, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const subAreas = useAtomValue(subAreasInParkFamily({ parkId, branchId }));
  const exclusionZones = useAtomValue(exclusionZonesFamily({ branchId }));
  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const minDistanceBetweenTurbines = getMinDistanceBetweenTurbines(
    turbines,
    allTurbineTypes,
  );

  const turbineTypesString = useMemo<string | undefined>(
    () => turbineTypeString(turbines, allTurbineTypes),
    [turbines, allTurbineTypes],
  );

  const area = getParkArea(park);

  const areaWithoutExclusionZones = useMemo(
    () => getParkAreaWithoutExclusionZones(park, exclusionZones),
    [park, exclusionZones],
  );

  const keyValues: [string, number | string | undefined, string?][] =
    useMemo(() => {
      const area_extra: [string, number | string | undefined][] =
        areaWithoutExclusionZones && exclusionZones.length !== 0
          ? [
              [
                "Total area without exlusion zones",
                `${areaWithoutExclusionZones}  km²`,
              ],
            ]
          : [];

      return [
        ["Total area", `${area}  km²`],
        ...area_extra,
        ["Sub area(s)", subAreas.length],
        ["Number of turbines", turbines.length],
        ["Turbine types", turbineTypesString],
        [
          "Min. distance between turbines",
          formatMinDistance(minDistanceBetweenTurbines),
        ],
      ];
    }, [
      areaWithoutExclusionZones,
      exclusionZones.length,
      area,
      subAreas.length,
      turbines.length,
      turbineTypesString,
      minDistanceBetweenTurbines,
    ]);

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {keyValues.map(([key, value, tooltip]) => (
        <React.Fragment key={key}>
          <Row>
            <p style={{ display: "flex", gap: "0.8rem" }}>
              {key}
              {tooltip && <HelpTooltip text={tooltip} />}
            </p>
            {value === undefined ? (
              <SkeletonBlock style={{ width: "6rem", height: "1rem" }} />
            ) : (
              <p>{value}</p>
            )}
          </Row>
        </React.Fragment>
      ))}
    </FlexGrid2>
  );
};

export const ParkWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();
  const isOnshore = useAtomValue(isOnshoreAtom);

  return (
    <SafeCard title="Park" id="Park" resetKeys={errorBoundaryResetKeys}>
      {isOnshore ? <ParkInnerOnshore /> : <ParkInner />}
    </SafeCard>
  );
};
