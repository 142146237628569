import IconWindTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import IconSubstation from "@icons/24/Substation.svg?react";
import IconExportCable from "@icons/24/ExportCable.svg?react";
import { ExportSystemType } from "services/cableTypeService";
import { Tag } from "components/General/Tag";

export const NumberOfTurbinesTag = ({ n }: { n: number | string }) => {
  const text =
    "Number of turbines" +
    (typeof n === "number" ? "" : " (neighbouring turbines)");
  return <Tag text={String(n)} icon={<IconWindTurbine />} tooltip={text} />;
};

export const NumberOfSubstationsTag = ({ n }: { n: number }) => (
  <Tag
    text={String(n)}
    icon={<IconSubstation />}
    tooltip="Number of substations"
  />
);

export const ExportSystemTypeTag = ({ n }: { n: ExportSystemType }) => (
  <Tag
    text={String(n)}
    icon={<IconExportCable />}
    tooltip="Export system type"
  />
);
