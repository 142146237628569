import mapboxgl, { CirclePaint, LngLatBoundsLike } from "mapbox-gl";
import { useRef, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { BOUNDS_BUFFER_OFFSHORE } from "../../../constants/misc";
import { currentMapStyleIdAtom } from "../../../state/map";
import { colors } from "../../../styles/colors";
import { DivisionFeature, ParkFeature } from "../../../types/feature";
import { getBBOXArrayFromFeatures } from "../../../utils/geojson/validate";
import MapLegend, { LegendItem } from "./MapLegend";
import { isPrintingAtom } from "../state";
import { SafeCard } from "./Base";
import { useDashboardContext } from "../Dashboard";
import { MapboxMoveLogoToUpperLeft } from "./Base.style";
import { RenderCables } from "components/Mapbox/Cables";
import { RenderDivisions } from "components/Mapbox/Divisions";
import { RenderExportCables } from "components/Mapbox/ExportCables";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderSubstations } from "components/Mapbox/Substations";
import { RenderTurbines } from "components/Mapbox/Turbines";
import { overrideUseDefaultStylesAtom } from "business/style/state";
import { useAtomValue, useSetAtom } from "jotai";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesFamily } from "state/jotai/exclusionZone";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { cablesInParkFamily } from "state/jotai/cable";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { substationsInParkFamily } from "state/jotai/substation";

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

const turbinePaintOverride: CirclePaint = {
  "circle-radius": 2,
};
const Map = ({ map, park }: { map: mapboxgl.Map; park: ParkFeature }) => {
  const parkId = park.id;
  const branchId = undefined;
  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const cables = useAtomValue(cablesInParkFamily({ parkId, branchId }));
  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId }),
  );
  const subStations = useAtomValue(
    substationsInParkFamily({ parkId, branchId }),
  );

  const subAreas = useAtomValue(subAreasInParkFamily({ parkId, branchId }));
  const exclusionZones = useAtomValue(exclusionZonesFamily({ branchId }));

  const parks = useMemo(() => [park], [park]);
  const zones = useMemo(() => {
    const ret: DivisionFeature[] = subAreas;
    return ret.concat(exclusionZones);
  }, [subAreas, exclusionZones]);

  const setOverride = useSetAtom(overrideUseDefaultStylesAtom);
  useEffect(() => {
    setOverride(true);
    return () => {
      setOverride(false);
    };
  }, [setOverride]);

  return (
    <>
      <RenderParks map={map} parks={parks} />
      <RenderDivisions map={map} divisions={zones} />
      <RenderTurbines
        map={map}
        turbines={turbines}
        paintOverride={turbinePaintOverride}
      />
      <RenderSubstations map={map} substations={subStations} />
      <RenderExportCables map={map} exportCables={exportCables} />
      <RenderCables map={map} cables={cables} minVisibleZoom={0} />
    </>
  );
};

const legendItems: LegendItem[] = [
  { name: "Exclusion zone", style: { backgroundColor: colors.exclusionZone } },
  { name: "Substation", style: { backgroundColor: colors.substation } },
  { name: "Sub area", style: { backgroundColor: colors.subArea } },
  { name: "Cables", style: { background: colors.rainbow } },
  { name: "Turbine", style: { backgroundColor: colors.turbine } },
];

const ProjectOverviewMap = () => {
  const { park } = useDashboardContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map | null>(null);
  const isPrinting = useAtomValue(isPrintingAtom);
  const activeMapStyleId = useAtomValue(currentMapStyleIdAtom);

  const [_boundsCount, refreshBounds] = useState(0);
  const bounds: LngLatBoundsLike = useMemo(() => {
    const bbox = getBBOXArrayFromFeatures([park]);
    const buffer = BOUNDS_BUFFER_OFFSHORE * 0.6;
    return [
      [bbox[0] - buffer, bbox[1] - buffer],
      [bbox[2] + buffer, bbox[3] + buffer],
    ];
  }, [park]);

  useEffect(() => {
    if (map || !mapContainer.current) return;
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      bounds,
      interactive: false,
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
    });
    setMap(newMap);
    newMap.on("load", () => {
      newMap.resize();
      setMapLoaded(newMap);
    });
  }, [map, mapContainer, bounds, setMapLoaded, activeMapStyleId]);

  useEffect(() => {
    if (!map) return;
    map.fitBounds(bounds);
  }, [bounds, map, _boundsCount]);

  useEffect(() => {
    if (!map) return;
    const observer = new ResizeObserver(() => {
      refreshBounds((c) => c + 1);
      setTimeout(() => map.resize(), 0);
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => observer.disconnect();
  }, [map]);

  const [mapImageUrl, setImageUrl] = useState<string | undefined>(
    map ? map.getCanvas().toDataURL() : undefined,
  );

  useEffect(() => {
    if (!isPrinting || !map) return;
    setImageUrl(map.getCanvas().toDataURL());
    return () => {
      setImageUrl(undefined);
    };
  }, [isPrinting, map, setImageUrl]);

  return (
    <div
      ref={boxRef}
      style={{
        position: "relative",
        flex: 1,
        display: "flex",
      }}
    >
      <MapboxMoveLogoToUpperLeft
        ref={mapContainer}
        style={{ borderRadius: "5px" }}
      />
      {!isPrinting && mapLoaded && <Map map={mapLoaded} park={park} />}
      {isPrinting && mapImageUrl && <Image alt={"Park"} src={mapImageUrl} />}
      <MapLegend items={legendItems} />
    </div>
  );
};

export const MapWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Map"
      id="Map"
      fullscreen
      resetKeys={errorBoundaryResetKeys}
    >
      <ProjectOverviewMap />
    </SafeCard>
  );
};
