import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { organisationAnalysisResourceState } from "../state";
import {
  HeaderRow,
  MainRow,
  SubHeader,
  TabContainer,
  TableHeader,
} from "../style";
import AddIcon from "@icons/24/Add.svg";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Row } from "components/General/Layout";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { AnalysisManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { SearchInput } from "components/General/Input";
import { VerticalDivider } from "components/General/VerticalDivider";
import { Field, LibraryManageRole, Order, SortState } from "../types";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { AnalysisConfiguration } from "services/configurationService";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import { getIsLegalMove } from "business/folderStructure/utils";
import { FolderItem, FolderTreeItem } from "types/folderStructures";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { IconREMSize } from "styles/typography";
import { RecursiveFolder } from "../shared/RecursiveFolder";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import {
  ANALYSIS_TABLE_LIST_ITEM,
  ANALYSIS_TABLE_FOLDER_ITEM,
} from "./constants";
import ResourceItem from "../shared/ResourceItem/ResourceItem";
import { ResourceCrudOperations } from "../shared/types";
import useOrgAnalysisCrud from "./useOrgAnalysisCrud";
import Tooltip from "components/General/Tooltip";
import { useLibraryFolderStructure } from "../shared/hooks/useLibraryFolderStructure";
import { useLibrarySearchAndSort } from "../shared/hooks/useLibrarySearchAndSort";
import { useLibraryDragAndDrop } from "../shared/hooks/useLibraryDragAndDrop";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { orgAnalysisManageAccessSelector } from "state/user";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { selectedLibraryTabAtom } from "state/library";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { useSyncSelectedItemWithResource } from "../hook";

type AnalysisOrFolderItem = AnalysisConfiguration | FolderItem;

export default function AnalysisTab() {
  const hasOrgAnalysisAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  useEffect(() => {
    setSelectedLibraryTab("analysis");
    return () => {
      setSelectedLibraryTab(undefined);
    };
  }, [setSelectedLibraryTab]);

  if (!hasOrgAnalysisAccess || !allLibraryAccess) return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <AnalysisTabInner />
    </Suspense>
  );
}

function AnalysisTabInner() {
  useSyncSelectedItemWithResource();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const analysis = useAtomValue(
    organisationAnalysisResourceState({
      organisationId,
    }),
  );
  const [showNewAnalysisModal, setShowNewAnalysisModal] = useState(false);
  const params = useParams();
  const selectedItemId = params.selectedItemId;
  const navigate = useNavigate();
  const [createAnalysisInFolderId, setCreateAnalysisInFolderId] = useState<
    string | undefined
  >();
  const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
    string | undefined
  >();
  const { showConfirm } = useConfirm();
  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );

  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  const {
    name,
    onNameChange,
    sortedResources: sortedAnalysis,
  } = useLibrarySearchAndSort(analysis, sortState);

  const { folders, orphans } = useLibraryFolderStructure({
    organisationId,
    resources: sortedAnalysis,
    sortState,
    libraryManageRole: "org_analysis_manage",
    searchTerm: name,
  });

  const { moveItems, createFolder } = useOrgFolderStructureCrud({
    libraryManageRole: "org_analysis_manage",
  });

  const { create, remove, duplicate, update } = useOrgAnalysisCrud();

  const crudOperations: ResourceCrudOperations<LibraryManageRole.ANALYSIS> =
    useMemo(
      () => ({
        remove: async (id: string) => {
          await remove(id);
          return;
        },
        duplicate: ({ resource }) =>
          duplicate({
            analysis: resource,
            onSuccess: (newAnalysis) => {
              navigate(
                `/organisation/${organisationId}/library/analysis/${newAnalysis.id}`,
              );
            },
          }),
      }),
      [remove, duplicate, navigate, organisationId],
    );

  const updateDescription = async (
    resource: AnalysisConfiguration,
    newDescription: string,
  ) => {
    await update({ ...resource, description: newDescription }, true);
    return resource;
  };
  const getDescription = (resource: AnalysisConfiguration) =>
    resource.description;

  const collapsed = !!content;

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  const onClickOnResource = useCallback(
    async (id: string) => {
      if (
        !unsavedSettings ||
        (await showConfirm({
          title: "Unsaved changes",
          message: "Are you sure you want to quit without saving?",
        }))
      ) {
        setUnsavedSettings(false);
        navigate(`/organisation/${organisationId}/library/analysis/${id}`);
      } else {
        navigate(`/organisation/${organisationId}/library/analysis/${id}`);
      }
    },
    [
      navigate,
      showConfirm,
      unsavedSettings,
      setUnsavedSettings,
      organisationId,
    ],
  );

  const onCreateAnalysisInFolder = useCallback(
    (folderId: string) => {
      setCreateAnalysisInFolderId(folderId);
      setShowNewAnalysisModal(true);
    },
    [setCreateAnalysisInFolderId, setShowNewAnalysisModal],
  );

  const onCreateFolderInFolder = useCallback(
    (folderId: string) => {
      setCreateFolderInFolderId(folderId);
      setShowCreateNewFolder(true);
    },
    [setCreateFolderInFolderId, setShowCreateNewFolder],
  );

  const {
    onDropOnFolder,
    onFolderDropOnFolder,
    dropRef,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(
    [ANALYSIS_TABLE_LIST_ITEM, ANALYSIS_TABLE_FOLDER_ITEM],
    folders,
    "org_analysis_manage",
  );

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folders);
    },
    [folders],
  );

  const [openDescriptionId, setOpenDescriptionId] = useState<
    string | undefined
  >(undefined);

  return (
    <>
      {showCreateNewFolder && (
        <NewItemModal
          title="New folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onSubmit={async (name) => {
            await createFolder(
              name,
              LibraryManageRole.ANALYSIS,
              [],
              createFolderInFolderId,
            );
          }}
          onClose={() => {
            setShowCreateNewFolder(false);
            setCreateFolderInFolderId(undefined);
          }}
        />
      )}
      {showNewAnalysisModal && (
        <NewItemModal
          title="Create new analysis configuration"
          placeholder="Enter configuration name"
          defaultValue="Untitled"
          onClose={() => {
            setShowNewAnalysisModal(false);
          }}
          onSubmit={async (name: string) => {
            setSaveInProgress(true);
            navigate(`/organisation/${organisationId}/library/analysis`);
            const res = await create({
              name,
            });
            if (res) {
              navigate(
                `/organisation/${organisationId}/library/analysis/${res.id}`,
              );
              setSaveInProgress(false);
              setContent({
                type: "resource",
                id: res.id,
              });
              if (createAnalysisInFolderId) {
                await moveItems(
                  [
                    {
                      type: "RESOURCE",
                      id: res.id,
                      parentId: createAnalysisInFolderId,
                    },
                  ],
                  createAnalysisInFolderId,
                );
                setCreateAnalysisInFolderId(undefined);
              }
            }
          }}
        />
      )}
      <TabContainer>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            Analysis
          </h2>

          <Row>
            <Tooltip text={"Create new folder"}>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setShowCreateNewFolder(true);
                }}
                icon={<FolderAddIcon />}
              />
            </Tooltip>
            <Button
              text={collapsed ? "" : "New analysis configuration"}
              icon={collapsed && <AddIcon />}
              disabled={isLoading}
              onClick={() => setShowNewAnalysisModal(true)}
            />
            <VerticalDivider height={3.2} />
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === selectedItemId) return undefined;
                  if (!selectedItemId) {
                    return {
                      type: "no-item-selected",
                    };
                  }
                  return {
                    type: "resource",
                    id: selectedItemId,
                  };
                });
              }}
            />
          </Row>
        </Row>
        <Row alignCenter>
          <SubHeader>Access: </SubHeader> <AnalysisManagersPreview />
        </Row>
        <Row
          style={{
            padding: "1.2rem 0",
          }}
        >
          <SearchInput
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            placeholder={`Search analysis configurations`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </Row>
        <MainRow ref={dropRef}>
          <HeaderRow>
            <TableHeader
              onClick={() => toggleSort("name")}
              style={{
                cursor: "pointer",
                flex: 4,
              }}
            >
              Name
              <IconREMSize
                width={1.2}
                height={1.2}
                style={{ marginLeft: "0.4rem" }}
              >
                {sortState.field === "name" && sortState.order === "ASC" ? (
                  <DirectionDown />
                ) : sortState.field === "name" && sortState.order === "DESC" ? (
                  <DirectionUp />
                ) : (
                  <DirectionDown />
                )}
              </IconREMSize>
            </TableHeader>
            {!collapsed && (
              <>
                <TableHeader style={{ flex: 1 }}>Last edited</TableHeader>
                <TableHeader style={{ flex: 1 }}>Projects</TableHeader>
              </>
            )}
            <TableHeader style={{ flex: 1 }}></TableHeader>
          </HeaderRow>

          {folders.map((folder) => (
            <RecursiveFolder
              key={folder.id}
              folder={folder}
              depth={0}
              onDropOnFolder={onDropOnFolder}
              onFolderDropOnFolder={onFolderDropOnFolder}
              isLegalFolderMove={isLegalFolderMove}
              onCreateFolderInFolder={onCreateFolderInFolder}
              collapsed={collapsed}
              selectedItemId={selectedItemId}
              onClickResource={onClickOnResource}
              organisationId={organisationId}
              isLoading={isLoading}
              deleteInProgress={deleteInProgress}
              setSelectedItemId={(id) => {
                navigate(
                  `/organisation/${organisationId}/library/analysis/${id}`,
                );
              }}
              setContent={setContent}
              setSaveInProgress={setSaveInProgress}
              setDeleteInProgress={setDeleteInProgress}
              sortedResources={sortedAnalysis}
              libraryManageRole={LibraryManageRole.ANALYSIS}
              onCreateResourceInFolder={onCreateAnalysisInFolder}
              onFolderDropOnResource={onFolderDropOnResource}
              openDescriptionId={openDescriptionId}
              setOpenDescriptionId={setOpenDescriptionId}
              searchTerm={name}
              remove={crudOperations.remove}
              duplicate={crudOperations.duplicate}
              getDescription={getDescription}
              updateDescription={updateDescription}
            />
          ))}

          {orphans.map((item: AnalysisOrFolderItem) => {
            const analysis =
              "name" in item
                ? item
                : sortedAnalysis.find((a) => a.id === item.id);
            if (!analysis || !("name" in analysis)) return null;

            return (
              <ResourceItem<LibraryManageRole.ANALYSIS>
                key={item.id}
                resource={analysis as AnalysisConfiguration}
                depth={0}
                collapsed={collapsed}
                isSelected={selectedItemId === item.id}
                onClickResource={onClickOnResource}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                libraryManageRole={LibraryManageRole.ANALYSIS}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                folders={folders}
                searchTerm={name}
              />
            );
          })}
        </MainRow>
      </TabContainer>
    </>
  );
}
