import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { useAtom, useSetAtom } from "jotai";
import {
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
} from "../../../../state/configuration";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../../state/modal";
import ArrowTopRight from "@icons/24/ArrowTopRight.svg?react";
import { CABLE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useCableSettings";
import { MOORING_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useMooringSettings";
import { TURBINE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { COST_MENU_ID } from "../../../SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import {
  selectedMenuItemState,
  unsavedSettingsState,
} from "../../../SettingsV2/Shared/state";
import { OpenPreviewButton } from "components/ComponentsPreview/style";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import { useCallback } from "react";
import Tooltip from "components/General/Tooltip";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export const FeatureSettingsShortcut = ({
  menuId,
  itemId,
}: {
  menuId:
    | typeof TURBINE_MENU_ID
    | typeof MOORING_MENU_ID
    | typeof CABLE_MENU_ID
    | typeof SUBSTATION_MENU_ID
    | typeof EXPORT_CABLE_MENU_ID;
  itemId: string;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const [midScreenModalTypeOpen, setMidScreenModalTypeOpen] = useAtom(
    midScreenModalTypeOpenAtom,
  );
  const { showConfirm } = useConfirm();

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);
  const setSelectedItemId = useSetAtom(
    selectedMenuItemState({
      menuId:
        midScreenModalTypeOpen?.modalType === ComponentsPreviewType
          ? midScreenModalTypeOpen.metadata?.componentType ?? ""
          : "",
      projectId: projectId ?? "",
    }),
  );
  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  return (
    <OpenPreviewButton
      onClick={async () => {
        if (
          !unsavedSettings ||
          (await showConfirm({
            title: "Unsaved changes",
            message:
              "You have unsaved changes. Continuing will discard any changes.",
          }))
        ) {
          setUnsavedSettings(false);
          setModalType({
            modalType: FeatureSettingsModalTypeV2,
            metadata: {
              selectedMenuId: menuId,
            },
            backTo: {
              modalType: ProjectConfigModalTypeV2,
              metadata: {
                selectedMenuId: COST_MENU_ID,
              },
            },
          });
          setSelectedItemId(itemId);
          onClose();
        }
      }}
    >
      {" "}
      <Tooltip text={"Go to component"}>
        <ArrowTopRight />
      </Tooltip>
    </OpenPreviewButton>
  );
};
