import { fetchSchemaWithToken } from "./utils";
import {
  NoiseAnalysisStatus,
  _NoiseAnalysisStatus,
} from "components/Noise/Onshore/types";
import { _PolygonFeatureType } from "types/feature";
import { _MultiPolygon, _Polygon } from "utils/geojson/geojson";
import { z } from "zod";

export const _NoiseAnalysisParams = z.object({
  turbines: z.array(
    z.object({ lon: z.number(), lat: z.number(), type: z.string() }),
  ),
  polygons: z.array(z.array(z.array(z.array(z.number())))),
  points: z.array(z.array(z.number())),
  turbineTypes: z.record(
    z.string(),
    z.object({
      height: z.number(),
      freqs: z.array(z.number()),
      soundPowerLevel: z.array(z.number()),
    }),
  ),
  receiverHeight: z.number().optional(),
  temperature: z.number().optional(),
  relativeHumidity: z.number().optional(),
  alternativeGroundEffect: z.boolean().optional(),
  groundType: z.number().min(0).max(1).optional(),
  step: z.number(),
});
export type NoiseAnalysisParams = z.infer<typeof _NoiseAnalysisParams>;

export async function triggerNoiseAnalysis({
  projectId,
  params,
}: {
  projectId: string;
  params: NoiseAnalysisParams;
}): Promise<NoiseAnalysisStatus> {
  const url = `/api/octopus/noise/${projectId}`;
  return await fetchSchemaWithToken(_NoiseAnalysisStatus, url, {
    method: "post",
    headers: {},
    body: JSON.stringify(params),
  });
}
