import { useAtom, useSetAtom } from "jotai";
import { ChangeEvent, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import {
  changedDataLayersAtom,
  changedDataSourcesAtom,
  hasUnsavedChangesAtom,
  LayerForDataCleaning,
  SourceForDataCleaning,
} from "../../../state/internalDataCleaning";
import Checkbox from "../../General/Checkbox";
import TaggingField from "../../TaggingField/TaggingField";
import { Input, TextArea } from "../../General/Input";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../General/FeatureFlag";
import { isCustomLayer } from "../utils";
import {
  ExternalDataSource,
  ExternalDataSourceLink,
  ExternalLayer,
  SourceTypesLayer,
} from "../../../types/layers";
import ToggleableRow from "../../ToggleableRow/ToggleableRow";
import Toggle, { ToggleSize } from "../../General/Toggle";

const Wrapper = styled.div`
  font-size: 1.2rem;
  width: 100%;
  border-top: 1px solid #cfcfcf;
  margin-top: 1rem;
`;

const DataCleaningControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

const createNewLayer = (inputLayer: ExternalLayer): LayerForDataCleaning => {
  return {
    layerId: inputLayer.id,
    endpointId: inputLayer.sourceLink.id,
    isHidden: inputLayer.isHidden ?? false,
    customMetadata: {
      deprecated: inputLayer.deprecated,
    },
    tags: inputLayer.tags ?? [],
    endpointUrl: inputLayer.sourceLink.url,
    sourceId: inputLayer.sourceId,
  };
};

const createNewSource = (
  source: ExternalDataSource,
  sourceLink: ExternalDataSourceLink,
): SourceForDataCleaning => {
  return {
    sourceId: source.id,
    alternativeNames: source.alternativeNames ?? undefined,
    countries: source.countries ?? undefined,
    name: source.name,
    endpoint: {
      endpointUrl: sourceLink.url,
      attribution: sourceLink.attribution ?? undefined,
      licenseType: sourceLink.licenseType ?? undefined,
      licenseUrl: sourceLink.licenseUrl ?? undefined,
      sourceType: SourceTypesLayer.wms,
      urlWhereFound: sourceLink.urlWhereFound ?? undefined,
    },
  };
};

const getSourceIndexKey = (layer: ExternalLayer) => {
  return `${layer.source.id}-${layer.sourceLink.url}`;
};

const LayerDataCleaningViewInner = ({
  inputLayer,
}: {
  inputLayer: ExternalLayer;
}) => {
  const [changedDataLayers, setChangedDataLayers] = useAtom(
    changedDataLayersAtom,
  );
  const [changedDataSources, setChangedDataSources] = useAtom(
    changedDataSourcesAtom,
  );

  useEffect(() => {
    console.log(inputLayer);
  }, [inputLayer]);

  const setHasUnsavedChanges = useSetAtom(hasUnsavedChangesAtom);

  const sourceIndexKey = getSourceIndexKey(inputLayer);

  const layerToUse = useMemo(
    () =>
      changedDataLayers[inputLayer.id]
        ? {
            ...changedDataLayers[inputLayer.id],
          }
        : createNewLayer(inputLayer),
    [changedDataLayers, inputLayer],
  );

  const sourceToUse = useMemo(
    () =>
      changedDataSources[sourceIndexKey]
        ? {
            ...changedDataSources[sourceIndexKey],
          }
        : createNewSource(inputLayer.source, inputLayer.sourceLink),
    [
      changedDataSources,
      inputLayer.source,
      inputLayer.sourceLink,
      sourceIndexKey,
    ],
  );

  const setLayerField = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const field = e.target.dataset["id"];
      if (!field) {
        return;
      }

      setChangedDataLayers((curr) => ({
        ...curr,
        [inputLayer.id]: {
          ...layerToUse,
          [field]: (e.target as HTMLInputElement)["checked"] ?? e.target.value,
        },
      }));
      setHasUnsavedChanges(true);
    },
    [inputLayer.id, layerToUse, setChangedDataLayers, setHasUnsavedChanges],
  );

  const setCustomMetadata = useCallback(
    (
      e: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const field = e.target.dataset["id"];
      if (!field) {
        return;
      }

      setChangedDataLayers((curr) => ({
        ...curr,
        [inputLayer.id]: {
          ...layerToUse,
          customMetadata: {
            ...layerToUse.customMetadata,
            [field]: e.target.value,
          },
        },
      }));
      setHasUnsavedChanges(true);
    },
    [inputLayer.id, layerToUse, setChangedDataLayers, setHasUnsavedChanges],
  );

  const setSourceField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const field = e.target.dataset["id"];
      if (!field) {
        return;
      }

      setChangedDataSources((curr) => ({
        ...curr,
        [sourceIndexKey]: {
          ...sourceToUse,
          [field]: e.target.value,
        },
      }));
      setHasUnsavedChanges(true);
    },
    [setChangedDataSources, setHasUnsavedChanges, sourceIndexKey, sourceToUse],
  );

  const setSourceEndpointField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const field = e.target.dataset["id"];
      if (!field) {
        return;
      }

      setChangedDataSources((curr) => ({
        ...curr,
        [sourceIndexKey]: {
          ...sourceToUse,
          endpoint: {
            ...sourceToUse.endpoint,
            [field]: e.target.value,
          },
        },
      }));
      setHasUnsavedChanges(true);
    },
    [setChangedDataSources, setHasUnsavedChanges, sourceIndexKey, sourceToUse],
  );

  if (isCustomLayer(inputLayer)) {
    return <Wrapper>Uploaded layer not supported</Wrapper>;
  }

  return (
    <Wrapper>
      <DataCleaningControls>
        <Checkbox
          data-id="isHidden"
          label="Hide layer for everyone"
          checked={layerToUse.isHidden}
          onChange={setLayerField}
          labelPlacement="after"
        />
        <InputWrapper>Source url: {inputLayer.sourceLink.url}</InputWrapper>
        <InputWrapper>LayerId: {inputLayer.id}</InputWrapper>
      </DataCleaningControls>
      <DataCleaningControls>
        <InputWrapper>
          <b>Original name:</b>
          <span>{inputLayer.name}</span>
        </InputWrapper>

        <InputWrapper>
          <b>Alias:</b>
          <Input
            type="text"
            data-id="name"
            value={layerToUse.customMetadata.name ?? ""}
            onChange={setCustomMetadata}
            placeholder="Use original name"
          />
        </InputWrapper>

        <InputWrapper>
          <b>Tags:</b>
          <TaggingField
            placeholder={'Write tag and press "Enter"'}
            tags={new Set<string>(layerToUse.tags)}
            setTags={(set: Set<string>) => {
              const updatedLayer = {
                ...layerToUse,
                tags: Array.from(set),
              };

              setChangedDataLayers((curr) => ({
                ...curr,
                [inputLayer.id]: updatedLayer,
              }));
              setHasUnsavedChanges(true);
            }}
          />
        </InputWrapper>
      </DataCleaningControls>
      <DataCleaningControls>
        <InputWrapper>
          <b>Description</b>
          <TextArea
            placeholder="Use default value"
            data-id="abstract"
            value={layerToUse.customMetadata.abstract ?? ""}
            onChange={setCustomMetadata}
          />
        </InputWrapper>
      </DataCleaningControls>
      <DataCleaningControls>
        <InputWrapper>
          <b>Deprecated</b>
          <Toggle
            size={ToggleSize.SMALL}
            checked={layerToUse.customMetadata.deprecated != null}
            onChange={() => {
              const updatedLayer = {
                ...layerToUse,
                customMetadata: {
                  ...layerToUse.customMetadata,
                  deprecated:
                    layerToUse.customMetadata.deprecated != null
                      ? undefined
                      : "",
                },
              };

              setChangedDataLayers((curr) => ({
                ...curr,
                [inputLayer.id]: updatedLayer,
              }));
              setHasUnsavedChanges(true);
            }}
          />
          {layerToUse.customMetadata.deprecated != null && (
            <TextArea
              rows={5}
              placeholder={"Deprecated text"}
              value={layerToUse.customMetadata.deprecated}
              onChange={(e) => {
                const deprecated = e.target.value;
                const updatedLayer = {
                  ...layerToUse,
                  customMetadata: {
                    ...layerToUse.customMetadata,
                    deprecated: deprecated,
                  },
                };

                setChangedDataLayers((curr) => ({
                  ...curr,
                  [inputLayer.id]: updatedLayer,
                }));
                setHasUnsavedChanges(true);
              }}
            />
          )}
        </InputWrapper>
      </DataCleaningControls>
      <ToggleableRow title="Edit Source information">
        <DataCleaningControls>
          <InputWrapper>
            <b>SourceId</b>
            <Input
              disabled
              type="text"
              value={sourceToUse.sourceId}
              placeholder="Source id"
            />
          </InputWrapper>
          <InputWrapper>
            <b>EndpointId</b>
            <Input
              disabled
              type="text"
              value={layerToUse.endpointId}
              placeholder="Endpoint id"
            />
          </InputWrapper>
        </DataCleaningControls>
        <DataCleaningControls>
          <InputWrapper>
            <b>English source name</b>
            <Input
              type="text"
              data-id="name"
              value={sourceToUse.name ?? ""}
              onChange={setSourceField}
              placeholder="Source name"
            />
          </InputWrapper>
          <InputWrapper>
            <b>Original source name</b>
            <Input
              type="text"
              data-id="originalName"
              value={sourceToUse.originalName ?? ""}
              onChange={setSourceField}
              placeholder="Original source name"
            />
          </InputWrapper>
          <InputWrapper>
            <b>Alternative source names</b>
            <TaggingField
              placeholder={'Write alternative name and press "Enter"'}
              tags={new Set<string>(sourceToUse.alternativeNames ?? [])}
              setTags={(set: Set<string>) => {
                setChangedDataSources((curr) => ({
                  ...curr,
                  [sourceIndexKey]: {
                    ...sourceToUse,
                    alternativeNames: Array.from(set),
                  },
                }));
                setHasUnsavedChanges(true);
              }}
            />
            <b>Countries</b>
            <TaggingField
              placeholder={'Write countries names and press "Enter"'}
              tags={new Set<string>(sourceToUse.countries ?? [])}
              setTags={(countries: Set<string>) => {
                setChangedDataSources((curr) => ({
                  ...curr,
                  [sourceIndexKey]: {
                    ...sourceToUse,
                    countries: Array.from(countries),
                  },
                }));
                setHasUnsavedChanges(true);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <b>Url to where the source was found</b>
            <Input
              type="text"
              data-id="urlWhereFound"
              value={sourceToUse.endpoint.urlWhereFound ?? ""}
              onChange={setSourceEndpointField}
              placeholder="Url to where the source was found"
            />
            <b>License type</b>
            <Input
              type="text"
              data-id="licenseType"
              value={sourceToUse.endpoint.licenseType ?? ""}
              onChange={setSourceEndpointField}
              placeholder="License type"
            />
          </InputWrapper>
          <InputWrapper>
            <b>License url</b>
            <Input
              type="text"
              data-id="licenseUrl"
              value={sourceToUse.endpoint.licenseUrl ?? ""}
              onChange={setSourceEndpointField}
              placeholder="License url"
            />
          </InputWrapper>
          <InputWrapper>
            <b>Attribution</b>
            <Input
              type="text"
              data-id="attribution"
              value={sourceToUse.endpoint.attribution ?? ""}
              onChange={setSourceEndpointField}
              placeholder="Attribution"
            />
          </InputWrapper>
        </DataCleaningControls>
      </ToggleableRow>
    </Wrapper>
  );
};

const LayerDataCleaningView = ({
  inputLayer,
}: {
  inputLayer: ExternalLayer;
}) => (
  <FeatureFlag name={SHOW_DATA_CLEANING}>
    <LayerDataCleaningViewInner inputLayer={inputLayer} />
  </FeatureFlag>
);

export default LayerDataCleaningView;
