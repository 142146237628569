import { useToast } from "./useToast";
import { Tag, updateTags } from "services/tagsService";
import { useJotaiCallback } from "utils/jotai";
import { tagsFamily } from "state/jotai/tags";
import { aset } from "utils/jotai";
import { scream } from "utils/sentry";

export function useTagCrud(organisationId: string) {
  const { error: showError } = useToast();

  const updateLocal = useJotaiCallback(
    (get, set, tags: Tag[]) => {
      aset(get, set, tagsFamily({ organisationId }), () => {
        return tags;
      });
    },
    [organisationId],
  );

  const update = useJotaiCallback(
    async (get, set, tags: Tag[]) => {
      const fallback = await get(tagsFamily({ organisationId }));
      try {
        await updateTags(organisationId, tags);
        updateLocal(tags);
      } catch (e) {
        aset(get, set, tagsFamily({ organisationId }), () => fallback);
        showError("Failed to update tags");
        scream(e as Error);
      }
    },
    [organisationId, updateLocal, showError],
  );

  return {
    update,
  };
}
