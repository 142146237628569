import { colors } from "../../../styles/colors";
import styled from "styled-components";
import SuccessIcon from "@icons/24/Success.svg?react";
import { Thread } from "../types";
import Tooltip from "components/General/Tooltip";
import { IconREMSize } from "styles/typography";

const ResolveIconWrapper = styled.div<{
  active: boolean;
  threadIsSelected: boolean;
}>`
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  justify-content: center;
  border-radius: 0.4rem;
  cursor: pointer;
  align-items: center;
  background-color: ${(p) => (p.active ? colors.green100 : "transparent")};
  &:hover {
    background-color: ${(p) =>
      p.active
        ? colors.green50
        : p.threadIsSelected
          ? colors.blue200
          : colors.grey200};
  }
  > svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      stroke: ${(p) => (p.active ? colors.green100 : colors.iconBrand)};
    }
  }
`;

const ResolvedLabel = ({
  thread,
  threadIsSelected,
  onResolve,
}: {
  thread: Thread;
  threadIsSelected: boolean;
  onResolve: () => void;
}) => {
  return (
    <ResolveIconWrapper
      threadIsSelected={threadIsSelected}
      active={thread.resolved}
      onClick={(event) => {
        event.stopPropagation();
        onResolve();
      }}
    >
      <Tooltip
        text={thread.resolved ? "Click to unresolve" : "Click to resolve"}
      >
        <IconREMSize width={1.6} height={1.6}>
          <SuccessIcon />
        </IconREMSize>
      </Tooltip>
    </ResolveIconWrapper>
  );
};

export default ResolvedLabel;
