import {
  TurbineProcurementCost,
  listProcurementCostForLibraryTurbinesOnNode,
  InterArrayCableProcurementCost,
  listProcurementCostForLibraryInterArrayCablesOnNode,
  listProcurementCostForLibraryExportCablesOnNode,
  ExportCableProcurementCost,
  FoundationProcurementCost,
  listProcurementCostForLibraryFoundationOnNode,
} from "services/procurementCostService";
import { atomFamily, atomFromFn } from "utils/jotai";
import { atom, useSetAtom } from "jotai";
import { useCallback } from "react";

const refreshTurbineProcurementCostsAtom = atom(0);
const refreshInterArrayCableProcurementCostsAtom = atom(0);
const refreshExportCableProcurementCostsAtom = atom(0);
const refreshFoundationProcurementCostsAtom = atom(0);

export const useRefreshNodeSpecificProcurementCosts = () => {
  const setRefreshTurbineProcurementCosts = useSetAtom(
    refreshTurbineProcurementCostsAtom,
  );
  const setRefreshInterArrayProcurementCosts = useSetAtom(
    refreshInterArrayCableProcurementCostsAtom,
  );
  const setRefreshExportCableProcurementCosts = useSetAtom(
    refreshExportCableProcurementCostsAtom,
  );
  const setRefreshFoundationProcurementCosts = useSetAtom(
    refreshFoundationProcurementCostsAtom,
  );

  const refreshTurbines = useCallback(
    () => setRefreshTurbineProcurementCosts((v) => v + 1),
    [setRefreshTurbineProcurementCosts],
  );
  const refreshInterArrayCables = useCallback(
    () => setRefreshInterArrayProcurementCosts((v) => v + 1),
    [setRefreshInterArrayProcurementCosts],
  );
  const refreshExportCables = useCallback(
    () => setRefreshExportCableProcurementCosts((v) => v + 1),
    [setRefreshExportCableProcurementCosts],
  );
  const refreshFoundations = useCallback(
    () => setRefreshFoundationProcurementCosts((v) => v + 1),
    [setRefreshFoundationProcurementCosts],
  );

  return {
    refreshTurbines,
    refreshInterArrayCables,
    refreshExportCables,
    refreshFoundations,
  };
};

export const listProcurementCostForLibraryTurbinesOnNodeAtomFamily = atomFamily(
  (nodeId: string) =>
    atomFromFn<Promise<Map<string, TurbineProcurementCost>>>(async (get) => {
      get(refreshTurbineProcurementCostsAtom);
      const resources =
        await listProcurementCostForLibraryTurbinesOnNode(nodeId);
      return new Map(resources.map((t) => [t.componentId, t]));
    }),
);

export const listProcurementCostForLibraryInterArrayCablesOnNodeAtomFamily =
  atomFamily((nodeId: string) =>
    atomFromFn<Promise<Map<string, InterArrayCableProcurementCost>>>(
      async (get) => {
        get(refreshInterArrayCableProcurementCostsAtom);
        const resources =
          await listProcurementCostForLibraryInterArrayCablesOnNode(nodeId);
        return new Map(resources.map((t) => [t.componentId, t]));
      },
    ),
  );

export const listProcurementCostForLibraryExportCablesOnNodeAtomFamily =
  atomFamily((nodeId: string) =>
    atomFromFn<Promise<Map<string, ExportCableProcurementCost>>>(
      async (get) => {
        get(refreshExportCableProcurementCostsAtom);
        const resources =
          await listProcurementCostForLibraryExportCablesOnNode(nodeId);
        return new Map(resources.map((t) => [t.componentId, t]));
      },
    ),
  );

export const listProcurementCostForLibraryFoundationsOnNodeAtomFamily =
  atomFamily((nodeId: string) =>
    atomFromFn<Promise<FoundationProcurementCost[]>>(async (get) => {
      get(refreshFoundationProcurementCostsAtom);
      const resources =
        await listProcurementCostForLibraryFoundationOnNode(nodeId);
      return resources;
    }),
  );
