import mapboxgl, { AnyLayer, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import GeoJsonFeature from "./GeoJsonFeature";
import { Feature } from "geojson";
import { fillOpacityWithCallback, outlineLayer } from "./utils";
import { getZoomLevels } from "../../layers/ExternalLayers/utils";
import { useAtomValue } from "jotai";
import { defaultMapTextPaintAtom } from "state/map";

const DEFAULT_COLOR = "#77bb77";

const Polygon = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  color,
  linePaint,
  lineOpacity,
  lineColor,
  opacity,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  filter,
  beforeLayer,
  symbolsBeforeLayer,
  zoomLevels,
  showText,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.FillPaint;
  color?: string;
  linePaint?: mapboxgl.LinePaint;
  lineColor?: string;
  opacity?: number;
  lineOpacity?: number;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean,
  ) => void;
  onDbClickCallback?: (features: MapboxGeoJSONFeature[]) => void;
  selectedIds?: (string | number)[];
  filter?: any[];
  beforeLayer?: string;
  symbolsBeforeLayer?: string;
  zoomLevels?: [number, number];
  showText?: boolean;
}) => {
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);
  const layers = useMemo(() => {
    return [
      {
        ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
        id: layerId,
        type: "fill",
        source: sourceId,
        paint: {
          "fill-color": "rgba(0,0,0,0)",
          ...(onClickCallback
            ? {
                "fill-opacity": fillOpacityWithCallback(0.4, 1.0),
              }
            : {}),
          ...paint,
        },
      },
      {
        ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
        id: outlineLayer(layerId),
        type: "line",
        source: sourceId,
        paint: {
          "line-color": "#000",
          "line-width": 3,
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            1,
            0.0,
          ],
          ...linePaint,
        },
      },
    ];
  }, [
    layerId,
    sourceId,
    onClickCallback,
    paint,
    linePaint,
    zoomLevels,
  ]) as mapboxgl.AnyLayer[];

  const symbolsLayer: undefined | AnyLayer = useMemo(() => {
    return symbols
      ? {
          ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
          ...symbols,
          id: `${layerId}-symbolid`,
          source: sourceId,
          paint: {
            ...defaultMapTextPaint,
            ...symbols.paint,
          },
        }
      : undefined;
  }, [layerId, sourceId, symbols, zoomLevels, defaultMapTextPaint]);

  const textLayer: undefined | AnyLayer = useMemo(() => {
    return showText
      ? {
          ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
          id: `${layerId}-text`,
          type: "symbol",
          source: sourceId,
          layout: {
            "text-field": ["get", "name"],
            "text-size": 16,
          },
        }
      : undefined;
  }, [layerId, sourceId, zoomLevels, showText]);

  useEffect(() => {
    if (!map || (paint && "fill-color" in paint)) return;
    map.setPaintProperty(layerId, "fill-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(
        layerId,
        "fill-opacity",
        fillOpacityWithCallback(opacity, opacity + 0.2),
      );
    }
  }, [layerId, map, opacity]);

  useEffect(() => {
    if (!map || !lineColor) return;
    map.setPaintProperty(outlineLayer(layerId), "line-color", lineColor);
    return () => {
      map.setPaintProperty(outlineLayer(layerId), "line-color", DEFAULT_COLOR);
    };
  }, [layerId, map, lineColor, paint, linePaint]);

  useEffect(() => {
    if (!map) return;
    if (lineOpacity !== undefined) {
      map.setPaintProperty(outlineLayer(layerId), "line-opacity", lineOpacity);
      return () => {
        map.setPaintProperty(outlineLayer(layerId), "line-opacity", [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          1,
          0.1,
        ]);
      };
    }
  }, [layerId, map, lineOpacity]);

  return (
    <GeoJsonFeature
      layers={layers}
      textLayer={textLayer}
      symbolsLayer={symbolsLayer}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      onClickCallback={onClickCallback}
      onDbClickCallback={onDbClickCallback}
      selectedIds={selectedIds}
      filter={filter}
      beforeLayer={beforeLayer}
      symbolsBeforeLayer={symbolsBeforeLayer}
    />
  );
};

export default Polygon;
