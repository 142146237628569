import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useMemo, useState } from "react";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../../style";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import { organisationSubstationResourceState } from "components/Organisation/Library/state";
import { TableRowsPlaceholder } from "../../shared/TablePlaceholder";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { SubstationType } from "services/substationService";
import SubstationIcon from "@icons/24/Substation.svg?react";
import { isOnshoreAtom } from "state/onshore";

export default function SubstationModal({
  existingSubstations,
  onSave,
  isSaving,
}: {
  existingSubstations: string[];
  onSave: (substations: SubstationType[]) => void;
  isSaving?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const inOnshoreProject = useAtomValue(isOnshoreAtom);
  const substationInOrgLoadable = useAtomValue(
    loadable(organisationSubstationResourceState(organisationId)),
  );

  const substationInOrg = useMemo(() => {
    if (substationInOrgLoadable.state !== "hasData") {
      return [];
    }

    const substations = substationInOrgLoadable.data;
    return inOnshoreProject
      ? substations.filter((s) => s.type === "onshore")
      : substations;
  }, [substationInOrgLoadable, inOnshoreProject]);

  const [name, onNameChange] = useTextInput("");
  const [selectedSubstation, setSelectedSubstation] = useState<
    SubstationType[]
  >([]);
  const [filteredSubstation, setFilteredSubstation] = useState<
    SubstationType[]
  >([]);

  useEffect(() => {
    const fuse = new Fuse(substationInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredSubstation(name.length > 0 ? results : substationInOrg);
  }, [name, substationInOrg]);

  const toggleSubstationSelection = (substation: SubstationType) => {
    setSelectedSubstation((prevSelectedSubstation) => {
      if (prevSelectedSubstation.find((t) => t.id === substation.id)) {
        return prevSelectedSubstation.filter((g) => g.id !== substation.id);
      } else {
        return [...prevSelectedSubstation, substation];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {substationInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredSubstation.map((g) => {
            const selected = selectedSubstation.some((st) => st.id === g.id);
            const alreadyExist = existingSubstations.some((id) => id === g.id);
            return (
              <ContentTableRow
                key={g.id}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => !alreadyExist && toggleSubstationSelection(g)}
                disabled={selected || alreadyExist}
              >
                <SVGWrapper size={1.4}>
                  <SubstationIcon />
                </SVGWrapper>
                <TextEllipsis
                  style={{
                    margin: 0,
                    marginRight: "auto",
                  }}
                >
                  {g.name}
                </TextEllipsis>
                {alreadyExist ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Already connected
                  </SecondaryText>
                ) : selected ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Selected
                  </SecondaryText>
                ) : (
                  <></>
                )}
                {!alreadyExist && !selected && (
                  <Button
                    buttonType="text"
                    text="Select"
                    icon={<Add />}
                    onClick={() => {}}
                    size="small"
                    style={{
                      padding: "0.2rem 1.2rem",
                    }}
                  />
                )}
              </ContentTableRow>
            );
          })}
        </ContentTableColumn>
      </ResultContainer>
      {selectedSubstation.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedSubstation.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name}
                    onDeselect={() => toggleSubstationSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedSubstation)}
              disabled={isSaving}
              buttonType="primary"
            />
          </Row>
        </>
      )}
      {isSaving && (
        <Row
          style={{
            padding: "0 1.2rem 1.2rem",
            alignItems: "center",
          }}
        >
          <SkeletonBlock
            style={{
              height: "2rem",
            }}
          />
        </Row>
      )}
    </SearchAndSelectContainer>
  );
}
