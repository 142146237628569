import { useAtomValue } from "jotai";
import { orgExportcableManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibraryExportCable } from "components/Ably/ChannelProviders/Organisation/Library/ExportCable/useAblyLibraryExportCable";

interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function ExportCableLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgExportcableManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_export_cable_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyExportCableChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyExportCableChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibraryExportCable(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_export_cable_manage"}
    />
  );
}
