import { useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { CABLE_CHAIN_POLYGON_PROPERTY_TYPE } from "../../../constants/cabling";
import { useToast } from "../../../hooks/useToast";
import Add from "@icons/24/Add.svg?react";
import { cableFeature } from "../../../state/cable";
import { mapAtom } from "../../../state/map";
import { currentSelectionArrayAtom } from "../../../state/selection";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { spaceTiny } from "../../../styles/space";
import {
  CableChainFeature,
  CablePartitionFeature,
  ParkFeature,
  SubstationFeature,
  TurbineFeature,
} from "../../../types/feature";
import {
  featureIsLocked,
  isCableChain,
  isCablePartition,
  isDefined,
  notUndefinedOrNull,
} from "../../../utils/predicates";
import { sendWarning } from "../../../utils/sentry";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { addCableLoads, addCableTypes } from "../CableWalk";
import { cableChainColor } from "../CablingMapController/Render";
import { cw } from "../Worker/cableWorker";
import { computeImportFeaturesJsonString } from "../Worker/utils";
import { KeyTag, MenuPosition, TextWrapper } from "./CableEdit.style";
import {
  cableEditModeAtom,
  filteredCableTypesAtom,
  generateCablesSettingState,
} from "./state";
import { maxChainLengthFromTypes } from "./utils";
import { RenderCablePartitions } from "components/Mapbox/CablePartition";
import { RenderCableChains } from "components/Mapbox/CableChains";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { Popup } from "components/Mapbox/Popup";
import { useBathymetry } from "hooks/bathymetry";
import { useAtomValue } from "jotai";
import { parkFamily } from "state/jotai/park";
import { cablePartitionsInParkFamily } from "state/jotai/cablePartition";
import { cableChainsInParkFamily } from "state/jotai/cableChain";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesForParkFamily } from "state/jotai/exclusionZone";
import { substationsInParkFamily } from "state/jotai/substation";
import { cableCorridorsInParkFamily } from "state/jotai/cableCorridor";
import { cablesInParkFamily } from "state/jotai/cable";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import { touchdownPointsInParkFamily } from "state/jotai/touchdown";
import { useAtomUnwrap, useJotaiCallback } from "utils/jotai";
import { branchIdAtom } from "state/pathParams";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import {
  makeExclusionDivisonToExclusionDivisionPolygon,
  makeExclusionZone,
} from "state/division";
import { makeSector } from "components/CableFreeSector/types";
import { Mixpanel } from "mixpanel";
import { StandardBox } from "styles/boxes/Boxes";

type CableChain = CableChainFeature["properties"];

const IconWrapper = styled.div<{
  showCursor?: boolean;
}>`
  display: flex;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    ${({ showCursor }) => showCursor && "cursor: pointer"}
  }
`;
const MenuPopup = ({
  feature,
  onClick,
  icon,
}: {
  feature: TurbineFeature | SubstationFeature;
  onClick: () => Promise<void>;
  icon?: JSX.Element;
}) => {
  const map = useAtomValue(mapAtom);
  const [loading, setLoading] = useState<boolean>(false);

  const popupPlacement = {
    lng: feature.geometry.coordinates[0],
    lat: feature.geometry.coordinates[1],
  };

  if (!map) return null;

  return (
    <Popup map={map} pos={popupPlacement}>
      <StandardBox
        style={{
          padding: spaceTiny,
        }}
      >
        <IconWrapper
          showCursor={!loading}
          onClick={() => {
            if (loading) return;
            setLoading(true);
            onClick().finally(() => {
              setLoading(false);
            });
          }}
        >
          {loading ? (
            <SkeletonBlock
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          ) : (
            icon
          )}
        </IconWrapper>
      </StandardBox>
    </Popup>
  );
};

export const useConnectChains = ({ parkId }: { parkId: string }) => {
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const cableTypes = useAtomValue(filteredCableTypesAtom);
  const cables = useAtomValue(
    cablesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const { update: updateFeatures } = useProjectElementsCrud();
  const { error, warning } = useToast();

  return useCallback(
    async (chains: CableChain[]) => {
      const arrays = chains
        .map((c) => {
          if (!substations.find((s) => s.id === c.substation)) return undefined;
          const turbineIds = c.turbines.filter((t) =>
            turbines.some((turbine) => turbine.id === t),
          );
          return {
            turbine_ids: turbineIds,
            substation_id: c.substation,
          };
        })
        .filter(isDefined);
      const newCableData = await cw.connect_arrays(arrays, {});

      if (!newCableData) return;
      if ("error" in newCableData) {
        error(newCableData.error);
        return;
      }

      const touchedTurbines = new Set();
      for (const c of chains)
        for (const t of c.turbines) touchedTurbines.add(t);

      const cableFeatures = newCableData.map((c) =>
        cableFeature(
          {
            fromId: c.from_id,
            toId: c.to_id,
          },
          {
            type: "LineString",
            coordinates: c.coordinates.map(({ x, y }) => [x, y]),
          },
          parkId,
        ),
      );

      const withLoads = addCableLoads(
        cableFeatures,
        substations,
        turbines,
        Array.from(turbineTypes.values()),
      );
      const newCables = addCableTypes(withLoads, cableTypes);

      if (newCables.some((c) => c.properties.cableTypeId === undefined))
        warning(
          "Selected cables don't have the capacity to support this chain length.",
        );

      const oldCableIds = cables
        .filter((f) => {
          const { fromId, toId } = f.properties;
          const remove =
            touchedTurbines.has(fromId) || touchedTurbines.has(toId);
          return remove;
        })
        .map((f) => f.id);

      updateFeatures({
        add: newCables,
        remove: oldCableIds,
      });
    },
    [
      cableTypes,
      cables,
      error,
      parkId,
      substations,
      turbineTypes,
      turbines,
      updateFeatures,
      warning,
    ],
  );
};

export const useSelectCableEdit = ({ parkId }: { parkId: string }) => {
  const setRawSelection = useSetAtom(currentSelectionArrayAtom);
  const selectChainWithTurbine = useJotaiCallback(
    async (get, _, turbineId: string) => {
      const branchId = get(branchIdAtom) ?? "";
      const chains = await get(
        cableChainsInParkFamily({
          parkId,
          branchId,
        }),
      );
      const chain = chains.find((c) =>
        c.properties.turbines.includes(turbineId),
      );
      if (chain) {
        setRawSelection((curr) => curr.concat([chain.id]));
      }
    },
    [parkId, setRawSelection],
  );

  const selectPartitionWithTurbine = useJotaiCallback(
    async (get, _, turbineId: string) => {
      const branchId = get(branchIdAtom) ?? "";
      const partitions = await get(
        cablePartitionsInParkFamily({
          parkId,
          branchId,
        }),
      );
      const partition = partitions.find((c) =>
        c.properties.turbines.includes(turbineId),
      );
      if (partition) {
        setRawSelection((curr) => curr.concat([partition.id]));
      }
    },
    [parkId, setRawSelection],
  );

  return {
    selectChainWithTurbine,
    selectPartitionWithTurbine,
  };
};

const useCableControls = ({ parkId }: { parkId: string }) => {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const currentChains = useAtomValue(
    cableChainsInParkFamily({
      parkId,
      branchId,
    }),
  );
  const cables = useAtomValue(
    cablesInParkFamily({
      parkId,
      branchId,
    }),
  );
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId,
    }),
  );
  const { update: updateFeatures } = useProjectElementsCrud();
  const connect = useConnectChains({
    parkId,
  });
  const { selectChainWithTurbine } = useSelectCableEdit({
    parkId,
  });
  const { error } = useToast();

  const moveTurbineToPartition = useCallback(
    async (turbine: TurbineFeature, partition: CablePartitionFeature) => {
      const removeCableIds = cables
        .filter(
          (c) =>
            c.properties.fromId === turbine.id ||
            c.properties.toId === turbine.id,
        )
        .map((c) => c.id);
      const substation = substations.find(
        (f) => f.id === partition.properties.substation,
      );
      if (!substation) return;

      let newChains: CableChain[] = [
        {
          type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
          parentIds: [parkId],
          id: uuidv4(),
          turbines: [turbine.id],
          substation: substation.id,
          color: cableChainColor,
        },
      ];

      const oldChain = currentChains.find((c) =>
        c.properties.turbines.includes(turbine.id),
      );

      if (oldChain)
        newChains.push({
          ...oldChain.properties,
          turbines: oldChain.properties.turbines.filter(
            (t) => t !== turbine.id,
          ),
        });

      await connect(newChains).catch(() => {
        error("Failed to connect turbine to new substation");
      });
      updateFeatures({
        remove: removeCableIds,
      });
      setTimeout(() => selectChainWithTurbine(turbine.id), 50);

      Mixpanel.track_old("usage:moveTurbineToPartition", {});
    },
    [
      cables,
      connect,
      currentChains,
      error,
      parkId,
      selectChainWithTurbine,
      substations,
      updateFeatures,
    ],
  );

  const moveTurbineToChain = useCallback(
    async (turbine: TurbineFeature, chain: CableChain) => {
      let newChains: CableChain[] = [
        {
          ...chain,
          turbines: chain.turbines.concat([turbine.id]),
        },
      ];

      const oldChain = currentChains.find((c) =>
        c.properties.turbines.includes(turbine.id),
      );
      if (oldChain) {
        const remainingTurbines = oldChain.properties.turbines.filter(
          (t) => t !== turbine.id,
        );
        if (remainingTurbines.length !== 0) {
          newChains.push({
            ...oldChain.properties,
            turbines: remainingTurbines,
          });
        }
      }

      await connect(newChains);
      setTimeout(() => selectChainWithTurbine(turbine.id), 50);
      Mixpanel.track_old("usage:moveTurbineToChain", {});
    },
    [connect, currentChains, selectChainWithTurbine],
  );

  const moveChainToSubstation = useCallback(
    async (chain: CableChain, substation: SubstationFeature) => {
      await connect([
        {
          ...chain,
          substation: substation.id,
        },
      ]);
      setTimeout(() => selectChainWithTurbine(chain.turbines[0]), 50);
      Mixpanel.track_old("usage:moveChainToSubstation", {});
    },
    [connect, selectChainWithTurbine],
  );

  return {
    moveTurbineToChain,
    moveTurbineToPartition,
    moveChainToSubstation,
  };
};

const CablePartitionControls = ({
  partition,
  park,
}: {
  partition: CablePartitionFeature;
  park: ParkFeature;
}) => {
  const { moveTurbineToPartition } = useCableControls({
    parkId: park.id,
  });
  const allTurbines = useAtomValue(
    turbinesInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );

  const outsideTurbines = useMemo(
    () =>
      allTurbines.filter((t) => !partition.properties.turbines.includes(t.id)),
    [allTurbines, partition.properties.turbines],
  );

  return (
    <>
      {outsideTurbines.map((t) => (
        <MenuPopup
          key={t.id}
          feature={t}
          onClick={() => moveTurbineToPartition(t, partition)}
          icon={<Add />}
        />
      ))}
    </>
  );
};

const CableChainsControls = ({
  chain,
  park,
}: {
  chain: CableChainFeature;
  park: ParkFeature;
}) => {
  const { moveTurbineToChain, moveChainToSubstation } = useCableControls({
    parkId: park.id,
  });
  const allTurbines = useAtomValue(
    turbinesInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );

  const outsideTurbines = useMemo(
    () => allTurbines.filter((t) => !chain.properties.turbines.includes(t.id)),
    [allTurbines, chain.properties.turbines],
  );

  const _moveTurbineToChain = useCallback(
    (t: TurbineFeature) => () => moveTurbineToChain(t, chain.properties),
    [chain, moveTurbineToChain],
  );

  return (
    <>
      {outsideTurbines.map((t) => (
        <MenuPopup
          key={t.id}
          feature={t}
          onClick={_moveTurbineToChain(t)}
          icon={<Add />}
        />
      ))}
      {substations
        .filter((s) => s.id !== chain.properties.substation)
        .map((s) => (
          <MenuPopup
            key={s.id}
            feature={s}
            onClick={() => moveChainToSubstation(chain.properties, s)}
            icon={<Add />}
          />
        ))}
    </>
  );
};

export const useRecomputeChains = ({ parkId }: { parkId: string }) => {
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const connect = useConnectChains({
    parkId,
  });
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const allCableTypes = useAtomValue(filteredCableTypesAtom);
  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const { error } = useToast();

  const recompute = useCallback(
    async (partition: CablePartitionFeature) => {
      const subInd = substations.findIndex(
        (f) => f.id === partition.properties.substation,
      );
      if (subInd === undefined)
        return sendWarning("No substation index found", {
          partition,
          substations,
        });

      const usedTurbineTypes = turbines
        .map((t) => allTurbineTypes.get(t.properties.turbineTypeId))
        .filter(isDefined);

      const maxChainLength = maxChainLengthFromTypes(
        usedTurbineTypes,
        allCableTypes,
      );

      const arrays = await cw.split_into_arrays(
        [
          {
            substation_id: partition.properties.substation,
            turbine_ids: partition.properties.turbines,
          },
        ],
        {
          max_chain_length: maxChainLength,
        },
      );

      if ("error" in arrays) {
        error(arrays.error);
        return;
      }

      let chains: CableChain[] = [];
      for (const [subId, turbineIds] of arrays) {
        const id = uuidv4();
        chains.push({
          id,
          parentIds: [parkId],
          turbines: turbineIds,
          substation: subId,
          type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
          color: cableChainColor,
        });
      }
      connect(chains);
    },
    [
      allCableTypes,
      allTurbineTypes,
      connect,
      error,
      parkId,
      substations,
      turbines,
    ],
  );

  return recompute;
};

export const CableEdit = ({ parkId }: { parkId: string }) => {
  const map = useAtomValue(mapAtom);
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const park = useAtomValue(
    parkFamily({
      parkId,
      branchId,
    }),
  );
  const { showConfirm } = useConfirm();
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const selProjFeatures = useAtomValue(selectedProjectFeaturesAtom);

  const cablePartitionFeatures = useAtomValue(
    cablePartitionsInParkFamily({
      parkId,
      branchId,
    }),
  );
  const cableChainFeatures = useAtomValue(
    cableChainsInParkFamily({
      parkId,
      branchId,
    }),
  );

  const singlePartition =
    selProjFeatures.length === 1 && isCablePartition(selProjFeatures[0])
      ? selProjFeatures[0]
      : undefined;
  const singleChain =
    selProjFeatures.length === 1 && isCableChain(selProjFeatures[0])
      ? selProjFeatures[0]
      : undefined;

  const setCableEditMode = useSetAtom(cableEditModeAtom);

  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId,
    }),
  );
  const subAreas = useAtomValue(
    subAreasInParkFamily({
      parkId,
      branchId,
    }),
  );
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId,
    }),
  );

  const exclusionZonesMP = useAtomValue(
    exclusionZonesForParkFamily({
      branchId,
      parkId,
    }),
  );
  const exclusionZones = useMemo(
    () => makeExclusionDivisonToExclusionDivisionPolygon(exclusionZonesMP),
    [exclusionZonesMP],
  );
  // This sucks and is really error prone.  Mama mia.
  const exclusionZonesWithSectors = useMemo(() => {
    const fs = (turbines as (TurbineFeature | SubstationFeature)[]).concat(
      substations,
    );
    const sectors = fs
      .flatMap((f) =>
        (f.properties.cableFreeSectors ?? []).map(
          ({ middle, span, distanceM }) =>
            makeSector({ feature: f, middle, span, distanceM }),
        ),
      )
      .filter(notUndefinedOrNull)
      .map((f) => makeExclusionZone(f.geometry));

    return exclusionZones.concat(sectors);
  }, [exclusionZones, turbines, substations]);

  const exportCables = useAtomValue(
    exportCablesInParkFamily({
      parkId,
      branchId,
    }),
  );

  const cableCorridors = useAtomValue(
    cableCorridorsInParkFamily({
      parkId,
      branchId,
    }),
  );
  const allCables = useAtomValue(
    cablesInParkFamily({
      parkId,
      branchId,
    }),
  );
  const anchors = useAtomValue(
    anchorsInParkFamily({
      parkId,
      branchId,
    }),
  );
  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({
      parkId,
      branchId,
    }),
  );

  const [, , bathymetryId] = useBathymetry({
    featureId: parkId,
    projectId: undefined,
    branchId,
    bufferKm: undefined,
  });

  const touchdownPoints = useAtomUnwrap(
    touchdownPointsInParkFamily({
      parkId,
      branchId: undefined,
      bathymetryId: bathymetryId ?? "",
    }),
  );

  const settings = useAtomValue(generateCablesSettingState);
  const hasLockedCables = useMemo(
    () => allCables.some(featureIsLocked),
    [allCables],
  );

  useEffect(() => {
    if (!park) return;
    const str = computeImportFeaturesJsonString({
      turbines,
      substations,
      exclusionZones: exclusionZonesWithSectors,
      subAreas,
      exportCables,
      mooringLines,
      anchors,
      touchdownPoints: touchdownPoints ?? [],
      park,
      cableCorridors,
      settings,
    });
    cw.newContext(str);
  }, [
    anchors,
    cableCorridors,
    exclusionZonesWithSectors,
    subAreas,
    mooringLines,
    park,
    settings,
    substations,
    touchdownPoints,
    turbines,
    exportCables,
  ]);

  const exit = useCallback(() => {
    setCableEditMode(false);
    setCurrentSelectionArray([]);
  }, [setCableEditMode, setCurrentSelectionArray]);

  useEffect(() => {
    if (hasLockedCables) {
      showConfirm({
        title: "Locked cables",
        message:
          "The park has locked cables. The cable editor might unlock and change these cables.",
      }).then((confirmed) => {
        if (!confirmed) {
          exit();
        }
      });
    }
  }, [hasLockedCables, exit, showConfirm]);

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Enter") {
        e.stopPropagation();
        e.preventDefault();
        exit();
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [exit]);

  if (!map) return null;

  return (
    <>
      {singlePartition && park && (
        <CablePartitionControls partition={singlePartition} park={park} />
      )}
      {singleChain && park && (
        <CableChainsControls chain={singleChain} park={park} />
      )}
      <RenderCablePartitions map={map} partitions={cablePartitionFeatures} />
      <RenderCableChains map={map} chains={cableChainFeatures} />

      <MenuPosition>
        <MenuFrame
          style={{
            minWidth: "10rem",
            maxWidth: "30rem",
            marginBottom: "5rem",
          }}
          title="Cable edit mode"
          onExit={() => exit()}
        >
          <TextWrapper>
            Exit with <KeyTag>Escape</KeyTag> or <KeyTag>Enter</KeyTag>.
          </TextWrapper>
        </MenuFrame>
      </MenuPosition>
    </>
  );
};
