import LibraryResourceActions from "components/ConfigurationModal/Components/LibraryResourceActions";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";
import ComponentLastChanged from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import {
  HeaderWrapper,
  HeaderContainer,
} from "components/ConfigurationModal/shared";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { Tag } from "components/General/Tag";
import {
  HelpLink,
  ARTICLE_FOUNDATION_STATS,
} from "components/HelpTooltip/HelpTooltip";
import { idToFoundationChangelogId } from "components/InputChangelog/const";
import { SettingButton } from "components/SettingsV2/Shared/styles";
import { colors } from "styles/colors";
import { FOUNDATION_TYPE_NAMES } from "../FoundationSettings";
import { FoundationType } from "types/foundations";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import ProjectResourceUsage from "components/ConfigurationModal/SettingsUsage/ProjectResourceUsage";

interface HeaderProps {
  foundation: FoundationType;
  tempFoundation: FoundationType;
  isDefault: boolean;
  isDisabled: boolean;
  nodeId: string;
  organisationId: string;
  projectId: string;
  isLibraryFoundation?: boolean;
  hasOrgFoundationAccess: boolean;
  duplicate: (foundation: FoundationType) => void;
  isLoadingProject: boolean;
  hasEditAccess: boolean;
  hasChanged: boolean;
  setTempFoundation: (foundation: FoundationType) => void;
  onSave: (
    foundation: FoundationType,
    onlyUpdatingDescription?: boolean,
  ) => void;
}

const Header = ({
  foundation,
  tempFoundation,
  isDefault,
  isDisabled,
  nodeId,
  organisationId,
  projectId,
  isLibraryFoundation,
  hasOrgFoundationAccess,
  duplicate,
  isLoadingProject,
  hasEditAccess,
  hasChanged,
  setTempFoundation,
  onSave,
}: HeaderProps) => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <Column
            style={{
              width: "100%",
            }}
          >
            <Row
              style={{
                alignItems: "center",
                gap: "0.4rem",
                margin: "-0 0 0 -2.8rem",
              }}
            >
              <HelpLink article={ARTICLE_FOUNDATION_STATS} />
              <h3
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "60rem",
                }}
              >
                {isDefault ? `${foundation.name}` : tempFoundation.name}
              </h3>
            </Row>
            <Row
              style={{
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <ComponentLastChanged
                resourceId={foundation.id}
                changelogId={idToFoundationChangelogId(foundation.id)}
                nodeId={nodeId}
                category={projectId ? "project" : "org_foundation_manage"}
              />
              <Row>
                <Tag
                  background={colors.grey100}
                  text={`Type: ${FOUNDATION_TYPE_NAMES[foundation.type]}`}
                />

                <ProjectResourceUsage
                  resourceType={"FOUNDATION"}
                  resourceId={foundation.id}
                />
              </Row>
            </Row>
          </Column>
          <DescriptionModal
            disabled={isDisabled}
            defaultValue={tempFoundation.description}
            updateDescription={(description) => {
              onSave(
                {
                  ...foundation,
                  description: description,
                },
                true,
              );
            }}
            subtitle={
              <div>
                <p>
                  The description will be visible for Admins and Editors in
                  projects with access to this foundation.
                </p>
              </div>
            }
          />
        </HeaderContainer>
        <SettingButton
          style={{
            justifyContent: "flex-end",
            position: "absolute",
            right: "2.4rem",
            top: "14.4rem",
          }}
        >
          {isLibraryFoundation && hasOrgFoundationAccess && (
            <LibraryResourceActions
              resourceType="foundation"
              resourceId={foundation.id}
              nodeId={nodeId}
              organisationId={organisationId ?? ""}
              onDuplicate={() => duplicate(foundation)}
              isLoadingDuplicate={isLoadingProject}
            />
          )}

          {!isDefault && !(!hasEditAccess || !hasChanged) && (
            <>
              <Button
                disabled={!hasEditAccess || !hasChanged}
                text="Cancel"
                buttonType="secondary"
                onClick={() => {
                  setTempFoundation(foundation);
                }}
                style={{
                  marginLeft: "auto",
                }}
              />
              <Button
                disabled={!hasEditAccess || !hasChanged}
                text="Save changes"
                onClick={() => {
                  onSave(tempFoundation);
                }}
              />
            </>
          )}
          {isDefault && hasEditAccess && (
            <Row
              style={{
                marginLeft: "auto",
              }}
            >
              <Button
                text="Duplicate to edit"
                icon={<DuplicateIcon />}
                onClick={() => duplicate(foundation)}
              />
            </Row>
          )}
        </SettingButton>
      </HeaderWrapper>
    </>
  );
};

export default Header;
