import {
  VIEW_MODE,
  viewCameraAspectAtom,
  viewFovAtom,
  viewFromShoreTerrainColorActiveAtom,
  viewFromShoreTerrainColorAtom,
  viewOrigoSelector,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTowardsSelector,
  viewTowardsWGS84Atom,
  viewViewModeAtom,
} from "state/viewToPark";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import { useEffect } from "react";
import { mapboxAccessToken } from "components/MapNative/constants";
import { Tile, lonLatToTile, lonLatToTileFloat } from "types/tile";
import {
  BufferGeometry,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  MathUtils,
  Mesh,
  MeshStandardMaterial,
  PlaneGeometry,
  Texture,
} from "three";
import { disposeObject, verticalFovToHorizontalFov } from "../utils";
import { boundingBoxToPolygonTiles, getFOVLines, getTileBBox } from "./utils";
import { MeshBVH, SerializedBVH } from "three-mesh-bvh";
import { hexToRgb } from "styles/colors";
import { promiseWorker, typedWorker } from "utils/utils";
import { Position } from "@turf/turf";
import * as turf from "@turf/turf";
import { useToast } from "hooks/useToast";
import { getDistanceFromLatLonInM } from "utils/proj4";
import * as Sentry from "@sentry/react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { atomFamily } from "utils/jotai";
import { currentParkAtom } from "state/jotai/park";
import { designToolTypeAtom, mapboxTerrainDemTileUrlFamily } from "state/map";
import { DesignToolMode } from "types/map";
import { isDefined } from "utils/predicates";
import { getRGBAArray } from "utils/image";
import { MergeTerrainTilesArgs } from "./mergeTextureTilesWorker";

const TERRAIN_ZOOM_LEVEL = 11;

const EXTRA_TEXTURE_Z_RESOLUTION = 3;
const EXTRA_TEXTURE_Z_RESOLUTION_ONSHORE = 4;

const HIGH_RESOLUTION_INCLUSION_SLACK = 0.2;
const HIGH_RESOLUTION_INCLUSION_SLACK_ONSHORE = 0.3;

const MAX_PARALLELL_NEW_TILES_TO_ADD = 5;
const MAX_TILES = 200;
const PARK_SURROUNDING_TILES = 1;
const MAX_DISTANCE_FROM_VIEW_POINT = 100;

const pixelSize = 514;

const tileEquality = (a: Tile, b: Tile) =>
  a.x === b.x && a.y === b.y && a.z === b.z;

const tileToString = (t: Tile) => `${t.x},${t.y},${t.z}`;

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}

const getTerrainGeometryTileFromMapbox = atomFamily(({ x, y, z }: Tile) =>
  atom<Promise<BufferGeometry | undefined>>(async (get) => {
    const proj4String = get(viewProj4StringAtom);
    const origo = await get(viewOrigoSelector);
    if (!proj4String || !origo) return;

    const image = await loadImage(
      get(mapboxTerrainDemTileUrlFamily({ z, x, y })),
    );
    const rgbaArray = getRGBAArray(image);

    const parseTerrainTileWorker = typedWorker<
      [
        [number[], number[], number[], number[]],
        [number, number, number, number],
        [number, number],
        string,
        Position,
      ],
      [ArrayLike<number>, ArrayLike<number>, SerializedBVH, ArrayLike<number>]
    >(
      new Worker(new URL("./parseTerrainTileWorker.ts", import.meta.url), {
        type: "module",
      }),
    );

    const tileBBOX = getTileBBox(x, y, z);

    const [position, normal, bvhSerialised, uvs] = await promiseWorker(
      parseTerrainTileWorker,
      [rgbaArray, tileBBOX, [pixelSize, pixelSize], proj4String, origo],
      "useDynamicTerrain",
    );
    parseTerrainTileWorker.terminate();

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute(position, 3));
    geometry.setAttribute("normal", new Float32BufferAttribute(normal, 3));
    geometry.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
    const deserializedBVH = MeshBVH.deserialize(bvhSerialised, geometry);
    geometry.boundsTree = deserializedBVH;

    geometry.computeBoundingBox();
    const max = geometry.boundingBox?.max;
    const min = geometry.boundingBox?.min;

    if (max?.x === min?.x && max?.y === min?.y && max?.z === min?.z) return;

    return geometry;
  }),
);

const getTerrainTextureTileFromMapbox = atomFamily(
  ({ tile, extraResolution }: { tile: Tile; extraResolution: number }) =>
    atom(async (get) => {
      {
        const { x, y, z } = tile;
        const proj4String = get(viewProj4StringAtom);
        const origo = get(viewOrigoSelector);
        if (!proj4String || !origo) return;

        const textureZoom = z + extraResolution;
        const seCorner2 = {
          x:
            x * Math.pow(2, extraResolution) + Math.pow(2, extraResolution) - 1,
          y:
            y * Math.pow(2, extraResolution) + Math.pow(2, extraResolution) - 1,
        };
        const nwCorner2 = {
          x: x * Math.pow(2, extraResolution),
          y: y * Math.pow(2, extraResolution),
        };

        const tilesToRequest = [];
        for (let y = nwCorner2.y; y <= seCorner2.y; y++) {
          for (let x = nwCorner2.x; x <= seCorner2.x; x++) {
            tilesToRequest.push(
              `https://api.mapbox.com/v4/mapbox.satellite/${textureZoom}/${x}/${y}@2x.webp?access_token=${mapboxAccessToken}`,
            );
          }
        }

        const mergeTextureTileWorker = typedWorker<
          MergeTerrainTilesArgs,
          ImageBitmap
        >(
          new Worker(new URL("./mergeTextureTilesWorker.ts", import.meta.url), {
            type: "module",
          }),
        );

        const imageBitmap = await promiseWorker(
          mergeTextureTileWorker,
          [tilesToRequest, seCorner2.x - nwCorner2.x + 1, 0],
          "useDynamicTerrain",
        );
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        ctx.drawImage(imageBitmap, 0, 0);

        const texture = new Texture(canvas);
        texture.needsUpdate = true;
        texture.anisotropy = 8;

        return texture;
      }
    }),
);

const getTerrainTileFromMapbox = atomFamily(({ x, y, z }: Tile) =>
  atom<Promise<Mesh<BufferGeometry, MeshStandardMaterial> | undefined>>(
    async (get) => {
      const resolution =
        get(designToolTypeAtom) === DesignToolMode.Onshore
          ? EXTRA_TEXTURE_Z_RESOLUTION_ONSHORE
          : EXTRA_TEXTURE_Z_RESOLUTION;
      const geometry = await get(getTerrainGeometryTileFromMapbox({ x, y, z }));
      const extraResolution = Object.values(
        get(highResolutionPatchesRequestedAtom),
      ).some((hrt) => hrt.x === x && hrt.y === y && hrt.z === z)
        ? resolution
        : 0;
      const textureMaybe = get(
        loadable(
          getTerrainTextureTileFromMapbox({
            tile: { x, y, z },
            extraResolution,
          }),
        ),
      );

      if (!geometry) return;

      const materialCurvature = new MeshStandardMaterial({
        transparent: true,
        side: DoubleSide,
        ...(textureMaybe.state === "hasData" && textureMaybe.data != null
          ? { map: textureMaybe.data }
          : { color: 0x77bb77 }),
      });
      const mesh = new Mesh(geometry, materialCurvature);

      mesh.rotateX(MathUtils.degToRad(-90));
      mesh.rotateZ(MathUtils.degToRad(-180));

      mesh.matrixAutoUpdate = false;
      mesh.updateMatrix();

      return mesh;
    },
  ),
);

export const terrainPatchesAddedToSceneRefresherAtom = atom<number>(0);
export const terrainPatchesRequestedAtom = atom<Record<string, Tile>>({});
const highResolutionPatchesRequestedAtom = atom<Record<string, Tile>>({});

export const getTerrainTilesFromMapbox = atom<
  Mesh<BufferGeometry, MeshStandardMaterial>[]
>((get) => {
  const requested = get(terrainPatchesRequestedAtom);
  const ret: Mesh<BufferGeometry, MeshStandardMaterial>[] = [];
  for (const tile of Object.values(requested)) {
    const l = get(loadable(getTerrainTileFromMapbox(tile)));
    if (l.state === "hasData" && l.data) ret.push(l.data);
  }
  return ret;
});

export const getLoadingTerrainTilesFromMapbox = atom<Tile[]>((get) => {
  const requested = get(terrainPatchesRequestedAtom);
  const ret: Tile[] = [];
  for (const tile of Object.values(requested)) {
    const l = get(loadable(getTerrainTileFromMapbox(tile)));
    if (l.state === "loading") ret.push(tile);
  }
  return ret;
});

export default function DynamicTerrain({
  threeCore,
}: {
  threeCore: ThreeCoreViewPark | undefined;
}) {
  const viewPosition = useAtomValue(viewPositionAtom);
  const [terrainPatchesRequested, setTerrainPatchesRequested] = useAtom(
    terrainPatchesRequestedAtom,
  );
  const [highResolutionPatchesRequested, setHighResolutionPatchesRequested] =
    useAtom(highResolutionPatchesRequestedAtom);
  const terrainPatches = useAtomValue(getTerrainTilesFromMapbox);
  const viewMode = useAtomValue(viewViewModeAtom);
  const terrainColorActive = useAtomValue(viewFromShoreTerrainColorActiveAtom);
  const terrainColor = useAtomValue(viewFromShoreTerrainColorAtom);
  const setTerrainPatchesAddedToSceneRefresher = useSetAtom(
    terrainPatchesAddedToSceneRefresherAtom,
  );
  const viewTowards = useAtomValue(viewTowardsSelector);
  const cameraAspect = useAtomValue(viewCameraAspectAtom);
  const fov = useAtomValue(viewFovAtom);
  const proj4String = useAtomValue(viewProj4StringAtom);
  const loadingTiles = useAtomValue(getLoadingTerrainTilesFromMapbox);
  const { info } = useToast();
  const park = useAtomValue(currentParkAtom);
  const viewTowardsWGS84 = useAtomValue(viewTowardsWGS84Atom);
  const onshoreMode =
    useAtomValue(designToolTypeAtom) === DesignToolMode.Onshore;

  useEffect(() => {
    return () => {
      setTerrainPatchesRequested({});
      setHighResolutionPatchesRequested({});
    };
  }, [setTerrainPatchesRequested, setHighResolutionPatchesRequested]);

  useEffect(() => {
    if (!park) return;
    const centerCoords = turf.center(park).geometry.coordinates;
    const highResolutionTile = lonLatToTile(
      centerCoords[0],
      centerCoords[1],
      TERRAIN_ZOOM_LEVEL,
    );
    const tilesToRequest = [];
    for (
      let x = highResolutionTile.x - PARK_SURROUNDING_TILES;
      x <= highResolutionTile.x + PARK_SURROUNDING_TILES;
      x++
    ) {
      for (
        let y = highResolutionTile.y - PARK_SURROUNDING_TILES;
        y <= highResolutionTile.y + PARK_SURROUNDING_TILES;
        y++
      ) {
        tilesToRequest.push({ x, y, z: TERRAIN_ZOOM_LEVEL });
      }
    }
    const tilesToAdd = tilesToRequest.filter(
      (tile) =>
        !Object.values(terrainPatchesRequested).some(
          (tp) =>
            tp.x === tile.x && tp.y === tile.y && TERRAIN_ZOOM_LEVEL === tile.z,
        ),
    );

    if (tilesToAdd.length !== 0) {
      setTerrainPatchesRequested((tpr) => ({
        ...tpr,
        ...tilesToAdd.reduce(
          (acc, t) => ({ ...acc, [tileToString(t)]: t }),
          {},
        ),
      }));
    }
  }, [park, terrainPatchesRequested, setTerrainPatchesRequested]);

  useEffect(() => {
    if (!viewPosition || !cameraAspect || !viewTowards || !viewTowardsWGS84)
      return;

    if (Object.values(terrainPatchesRequested).length >= MAX_TILES) {
      info(
        `Maximum number of ${MAX_TILES} terrain tiles reached, please close analysis and open again to restart`,
      );
      return;
    }

    const distanceKm =
      getDistanceFromLatLonInM(viewTowardsWGS84.geometry.coordinates, [
        viewPosition.lng,
        viewPosition.lat,
      ]) / 1000;

    if (distanceKm > MAX_DISTANCE_FROM_VIEW_POINT) {
      info(
        `Camera too far away from view point to load terrain, please stay within ${MAX_DISTANCE_FROM_VIEW_POINT}km to trigger terrain fetching`,
      );
      return;
    }

    const verticalFOV = verticalFovToHorizontalFov(cameraAspect, fov);
    const fovLines = getFOVLines(
      viewTowards,
      verticalFOV,
      proj4String,
      viewPosition,
    );

    if (fovLines.length === 0) return;

    const highResolutionTile = lonLatToTileFloat(
      viewPosition.lng,
      viewPosition.lat,
      TERRAIN_ZOOM_LEVEL,
    );
    const highResolutionSlack = onshoreMode
      ? HIGH_RESOLUTION_INCLUSION_SLACK_ONSHORE
      : HIGH_RESOLUTION_INCLUSION_SLACK;
    const highResolutionTilesToRequest = [];
    for (
      let x = Math.floor(highResolutionTile.x - highResolutionSlack);
      x <= Math.floor(highResolutionTile.x + highResolutionSlack);
      x++
    ) {
      for (
        let y = Math.floor(highResolutionTile.y - highResolutionSlack);
        y <= Math.floor(highResolutionTile.y + highResolutionSlack);
        y++
      ) {
        highResolutionTilesToRequest.push({ x, y, z: TERRAIN_ZOOM_LEVEL });
      }
    }

    const triangle = turf.polygon([
      [
        ...fovLines[0].geometry.coordinates,
        ...[...fovLines[1].geometry.coordinates].reverse(),
        fovLines[0].geometry.coordinates[0],
      ],
    ]);
    const bboxLines = fovLines
      .map((line) => turf.bbox(line))
      .reduce(
        (finalBBOX, bbox) => [
          Math.min(finalBBOX[0], bbox[0]),
          Math.min(finalBBOX[1], bbox[1]),
          Math.max(finalBBOX[2], bbox[2]),
          Math.max(finalBBOX[3], bbox[3]),
        ],
        [
          Number.MAX_VALUE,
          Number.MAX_VALUE,
          Number.MIN_VALUE,
          Number.MIN_VALUE,
        ],
      );

    const tilesToRequest = boundingBoxToPolygonTiles(
      bboxLines,
      TERRAIN_ZOOM_LEVEL,
    )
      .filter(
        (polygon) =>
          turf.booleanOverlap(triangle, polygon) ||
          turf.booleanContains(triangle, polygon),
      )
      .map((polygon) => polygon.properties!.tile as Tile);

    const loadingTilesNr = loadingTiles.length;

    const tilesToAdd = tilesToRequest
      .filter(
        (tile) =>
          !Object.values(terrainPatchesRequested).some((tp) =>
            tileEquality(tp, tile),
          ),
      )
      .slice(0, MAX_PARALLELL_NEW_TILES_TO_ADD - loadingTilesNr);

    if (tilesToAdd.length !== 0) {
      setTerrainPatchesRequested((tpr) => ({
        ...tpr,
        ...tilesToAdd.reduce(
          (acc, t) => ({ ...acc, [tileToString(t)]: t }),
          {},
        ),
      }));
    }

    const highResolutionTilesToAdd = highResolutionTilesToRequest.filter(
      (tile) =>
        !Object.values(highResolutionPatchesRequested).some((tp) =>
          tileEquality(tp, tile),
        ),
    );
    if (highResolutionTilesToAdd.length !== 0) {
      setHighResolutionPatchesRequested((hrp) => ({
        ...hrp,
        ...highResolutionTilesToAdd.reduce(
          (acc, t) => ({ ...acc, [tileToString(t)]: t }),
          {},
        ),
      }));
    }
  }, [
    viewPosition,
    viewTowards,
    viewTowardsWGS84,
    cameraAspect,
    fov,
    proj4String,
    setTerrainPatchesRequested,
    terrainPatchesRequested,
    highResolutionPatchesRequested,
    setHighResolutionPatchesRequested,
    loadingTiles,
    info,
    onshoreMode,
  ]);

  useEffect(() => {
    terrainPatches.forEach((terrain) => {
      if (viewMode === VIEW_MODE.NATURAL_MODE) {
        terrain.material.wireframe = false;
        terrain.material.transparent = true;
      } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
        terrain.material.wireframe = true;
        terrain.material.transparent = false;
      }
    });
  }, [terrainPatches, viewMode]);

  useEffect(() => {
    if (!terrainColorActive) return;
    const maps = terrainPatches.map((terrain) => terrain.material.map);
    const rgb = hexToRgb(terrainColor);
    const color = new Color(rgb);
    terrainPatches.forEach((terrain) => {
      terrain.material.color.set(color);
      terrain.material.map = null;
    });
    return () => {
      terrainPatches.forEach((terrain, i) => {
        terrain.material.color.set(new Color(0xffffff));
        terrain.material.map = maps[i];
      });
    };
  }, [terrainColorActive, terrainColor, terrainPatches]);

  useEffect(() => {
    if (!threeCore) return;
    const { scene } = threeCore;
    const terrainChanges = terrainPatches
      .map((terrain) => {
        const sceneChildren = scene.children;
        const sceneTerrain = sceneChildren.find(
          (child) =>
            child.type === "Mesh" &&
            child instanceof Mesh &&
            child.geometry.uuid === terrain.geometry.uuid,
        ) as Mesh<PlaneGeometry, MeshStandardMaterial> | undefined;
        if (!sceneTerrain) {
          scene.add(terrain);
          return terrain;
        } else if (sceneTerrain.material.uuid !== terrain.material.uuid) {
          scene.remove(sceneTerrain);
          disposeObject(sceneTerrain);
          scene.add(terrain);
          return terrain;
        }
        return undefined;
      })
      .filter(isDefined);

    Sentry.addBreadcrumb({
      category: "viewfromshore",
      level: "debug",
      data: { scene: threeCore.scene },
    });

    if (terrainChanges.length !== 0) {
      threeCore.renderer.render(threeCore.scene, threeCore.camera);
      setTerrainPatchesAddedToSceneRefresher((r) => r + 1);
    }
  }, [threeCore, terrainPatches, setTerrainPatchesAddedToSceneRefresher]);
  return null;
}
