import {
  bboxToCoords,
  geotiffGeojsonEntry,
  GeotiffType,
} from "../utils/geojson/utils";
import { fromBlob } from "geotiff";
import { fetchEnhancerWithToken } from "./utils";
import { SetterOrUpdater } from "types/utils";
import { SignedUrlResponse } from "../types/api";
import {
  THIRTY_MEGABYTES,
  PROJECT_DATA_API_PATH_V3,
  PROJECT_DATA_API_VERSION,
} from "./projectDataAPIService";
import { GeotiffFeature } from "types/feature";
import { convertGeotiffFileToEPSG } from "components/UploadModal/utils";

export const uploadGeoTiff = async (
  blob: Blob,
  nodeId: string,
  setToastMessage: SetterOrUpdater<any[]>,
  geotiffType: GeotiffType,
  maxFileSize = THIRTY_MEGABYTES,
  name?: string,
) => {
  if (blob.size > maxFileSize) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: `The size of the georeferenced image is ${Math.ceil(
          blob.size / (1024 * 1024),
        )}MB which is larger than the maximum allowed ${Math.floor(
          maxFileSize / (1024 * 1024),
        )}MB`,
        timeout: 10000,
        type: "error",
      },
    ]);
    return;
  }
  const signedUrlResponse = await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/${geotiffType}/${nodeId}/new`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  );

  if (signedUrlResponse.status !== 200) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: "Something went wrong when trying to save the image...",
        timeout: 2000,
        type: "error",
      },
    ]);
    return;
  }

  const signedUrl = (await signedUrlResponse.json()) as SignedUrlResponse;

  const geotiff4326 = await convertGeotiffFileToEPSG(
    new File([blob], name ?? "geotiff.tiff"),
    4326,
    true,
  );

  const formData = new FormData();
  Object.entries(signedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", geotiff4326);

  setToastMessage((tm) => [
    ...tm,
    {
      text: "Uploading image...",
      timeout: 2000,
    },
  ]);

  const uploadFileResponse = await fetch(signedUrl.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    setToastMessage((tm) => [
      ...tm,
      {
        text: "Something went wrong when trying to save the image...",
        timeout: 2000,
        type: "error",
      },
    ]);
    return;
  }

  const filename = signedUrl.fields.key.split("/").at(-1) ?? "";
  const geotiff = await fromBlob(geotiff4326);
  const geotiffImage = await geotiff.getImage();
  const bbox = geotiffImage.getBoundingBox();

  return geotiffGeojsonEntry(filename, bboxToCoords(bbox), geotiffType, name);
};

export async function* uploadGeoTiffGenerator(
  geotiff4326: Blob,
  nodeId: string,
  // setToastMessage: SetterOrUpdater<any[]>,
  geotiffType: GeotiffType,
  maxFileSize = THIRTY_MEGABYTES,
  name?: string,
): AsyncGenerator<string, GeotiffFeature, string> {
  if (geotiff4326.size > maxFileSize) {
    throw new Error(
      `The size of the georeferenced image is ${Math.ceil(
        geotiff4326.size / (1024 * 1024),
      )}MB which is larger than the maximum allowed ${Math.floor(
        maxFileSize / (1024 * 1024),
      )}MB`,
    );
  }
  const signedUrlResponse = await fetchEnhancerWithToken(
    `${PROJECT_DATA_API_PATH_V3}/${geotiffType}/${nodeId}/new`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    },
  );

  if (signedUrlResponse.status !== 200) {
    throw new Error("Something went wrong when trying to save the image...");
  }

  const signedUrl = (await signedUrlResponse.json()) as SignedUrlResponse;

  const formData = new FormData();
  Object.entries(signedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", geotiff4326);

  yield "Uploading image";

  const uploadFileResponse = await fetch(signedUrl.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    throw new Error("Something went wrong when trying to save the image...");
  }

  const filename = signedUrl.fields.key.split("/").at(-1) ?? "";
  const geotiff = await fromBlob(geotiff4326);
  const geotiffImage = await geotiff.getImage();
  const bbox = geotiffImage.getBoundingBox();

  return geotiffGeojsonEntry(filename, bboxToCoords(bbox), geotiffType, name);
}
