import { atom } from "jotai";
import React from "react";

export type ToastMessage = {
  text: React.ReactNode;
  timeout: number;
  id?: string;
  type?: "info" | "success" | "warning" | "error";
  link?: string;
  showCountdown?: boolean;
  /**
   * If provided, all other toasts with the same groupId will be cleared before showing
   */
  groupId?: string;
};

export const toastMessagesAtom = atom<ToastMessage[]>([]);
