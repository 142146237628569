import { atom } from "jotai";
import { Style } from "../types";
import { isOnshoreAtom } from "state/onshore";
import { Color } from "lib/colors";
import { colors } from "styles/colors";
import { atomFamily } from "utils/jotai";
import { cableLoadsFamily, cablesInParkFamily } from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";
import { isNever, roundToDecimal } from "utils/utils";
import { parkIdAtom } from "state/pathParams";
import * as turf from "@turf/turf";
import { scream } from "utils/sentry";
import { z } from "zod";
import { Coloring } from "../types";
import { LinePaint } from "mapbox-gl";
import { colorValuesSelectorFamily } from "../state";
import { READ, caseexpr } from "components/Mapbox/defaults";
import { lockedPropertyName } from "@constants/canvas";
import { COLORVALUE } from "../constants";

export const _CableColorKey = z.enum(["single", "cable-type"]);
export const _CableLabel = z.enum(["capacity", "load", "length", "cable-type"]);
type CableColorKey = z.infer<typeof _CableColorKey>;
export const cableColorKeys = _CableColorKey.options;
type CableLabel = z.infer<typeof _CableLabel>;
export const cableLabels = _CableLabel.options;
export type StyleCable = {
  feature: "cables";
} & Coloring<CableColorKey> & { label?: CableLabel };

export const defaultCableStyle = atom<Style>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    id: "default-style-cables",
    defaultMarker: true,
    feature: "cables",
    source: "single",
    name: "Single color",
    type: "single",
    color: Color.fromHex(onshore ? colors.onElCable : colors.cable),
    createdAt: 0,
  };
});

export const cableValuesSelectorFamily = atomFamily(
  (colorKey: CableColorKey | CableLabel) =>
    atom<Promise<Map<string, number | string> | undefined>>(async (get) => {
      const parkId = get(parkIdAtom);
      const branchId = undefined;
      if (colorKey === "capacity") {
        if (!parkId) return undefined;
        const cables = await get(cablesInParkFamily({ parkId, branchId }));
        const cableTypes = await get(
          cableTypesFamily({ projectId: undefined }),
        );
        return new Map(
          cables.map((c) => {
            const type = cableTypes.get(c.properties.cableTypeId ?? "");
            return [c.id, type?.ampacity ?? 0];
          }),
        );
      } else if (colorKey === "single") {
        if (!parkId) return undefined;
        const cables = await get(cablesInParkFamily({ parkId, branchId }));
        return new Map(cables.map((c) => [c.id, 0]));
      } else if (colorKey === "load") {
        if (!parkId) return undefined;
        const loadMap = await get(
          cableLoadsFamily({
            parkId,
            branchId,
            turbineTypeOverride: undefined,
          }),
        );
        return new Map(
          [...loadMap.entries()].map(([id, load]) => [
            id,
            roundToDecimal(load / 1e6, 1),
          ]),
        );
      } else if (colorKey === "length") {
        if (!parkId) return undefined;
        const cables = await get(cablesInParkFamily({ parkId, branchId }));
        return new Map<string, number>(
          cables.map((c) => [c.id, turf.length(c, { units: "meters" })]),
        );
      } else if (colorKey === "cable-type") {
        if (!parkId) return undefined;
        const cables = await get(cablesInParkFamily({ parkId, branchId }));
        return new Map<string, string>(
          cables.map((c) => [
            c.id,
            c.properties.cableTypeId ?? "[missing cable type]",
          ]),
        );
      }
      isNever(colorKey);
      throw scream(new Error(`Illegal \`valueName\`: "${colorKey}"`));
    }),
);

export const cableStyleAtom = atom<
  Promise<{
    line: LinePaint;
  }>
>(async (get) => {
  const onshore = get(isOnshoreAtom);
  const { color } = await get(colorValuesSelectorFamily("cables"));

  return {
    line: {
      "line-width": caseexpr({
        get: {
          error: 6,
          [lockedPropertyName]: 2,
        },
        state: {
          error: caseexpr({
            hover: 4,
            selected: 4,
            fallback: 2,
          }),
          borderColor: 4,
        },
        hover: 4,
        selected: 4,
        fallback: 2,
      }),
      "line-opacity": caseexpr({
        fallback: 0.3,
        hover: 0.8,
        selected: 1,
        active: 1,
        get: {
          error: 1,
        },
        state: {
          error: 1,
        },
      }),
      "line-dasharray": [
        "case",
        ["boolean", ["get", "redundancy"], false],
        ["literal", [1.5, 1.5]],
        ["literal", [1, 0]],
      ],
      "line-color": [
        "case",
        ["boolean", ["get", "redundancy"], false],
        colors.grey200,
        caseexpr({
          state: {
            borderColor: READ,
            error: colors.red500,
          },
          fallback: [
            "case",
            ["!=", ["get", COLORVALUE], null],
            color,
            onshore ? colors.onElCable : colors.cable,
          ],
          get: {
            error: colors.red500,
          },
        }),
      ],
    },
  };
});
