import { useAtomValue } from "jotai";
import React from "react";
import Tooltip, { TooltipText } from "components/General/Tooltip";
import { organisationGroupsState } from "components/Organisation/Groups/state";

export const GroupsTooltip = ({
  groupIds,
  organisationId,
  children,
}: React.PropsWithChildren<{
  organisationId: string;
  groupIds: string[];
}>) => {
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  return (
    <Tooltip
      text=""
      content={groupIds.map((groupId) => {
        const group = groups.find((g) => g.id === groupId);
        if (!group) {
          return null;
        }

        return (
          <TooltipText key={group.id} secondary={false} theme="dark">
            {group.name}
          </TooltipText>
        );
      })}
    >
      {children}
    </Tooltip>
  );
};
