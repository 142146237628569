import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const UnreadCommentDot = styled.div<{ size?: string }>`
  width: ${(props) => props.size || "1rem"};
  min-width: ${(props) => props.size || "1rem"};
  max-width: ${(props) => props.size || "1rem"};
  height: ${(props) => props.size || "1rem"};
  min-height: ${(props) => props.size || "1rem"};
  max-height: ${(props) => props.size || "1rem"};
  border-radius: ${(props) => props.size || "1rem"};
  background-color: ${colors.iconError};
  box-shadow: 0px 2px 4px 0px #0000004d;
`;
