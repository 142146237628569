import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { fetchSchemaWithToken } from "../services/utils";
import { z } from "zod";
import { gumbelPercentileValue } from "../functions/met";
import {
  fetchAvailableWaveSources,
  fetchBestWaveSource,
  fetchWaveGumbelParameters,
} from "../services/metoceanService";
import { Position } from "@turf/turf";

export enum WaveDataSource {
  ERA5 = "era5",
  NORA3 = "nora3",
  BEST = "best_available",
}

const _WaveData = z.object({
  hs: z.number().array(),
  tp: z.number().array(),
  direction: z.number().array(),
  timestamps: z.string().array(),
  longitude: z.number(),
  latitude: z.number(),
  source: z.string(),
});

export type WaveData = z.infer<typeof _WaveData>;

const _WaveRose = z.object({
  directions: z
    .object({
      direction: z.number(),
      probability: z.number(),
      hs: z.number(),
    })
    .array(),
  longitude: z.number(),
  latitude: z.number(),
});

export type WaveRoseType = z.infer<typeof _WaveRose>;

const _WaveStats = z.object({
  /** 1-year significant wave height (1-hour value) */
  hs1Yr: z.number(),
  /** 50-year significant wave height (1-hour value) */
  hs50Yr: z.number(),
  /** dataset used to extract wave stats (NORA3, ERA5, ..) */
  waveDataSource: z.string(),
});

export type WaveStats = z.infer<typeof _WaveStats>;

export const getClosestWaveDataSelector = atomFamily(
  ({
    lon,
    lat,
    source = "best_available",
  }: {
    lon: number | undefined;
    lat: number | undefined;
    source: string | undefined;
  }) =>
    atom<Promise<WaveData | undefined>>(async () => {
      if (!lon || !lat) return;
      const url = `/api/octopus/metocean/wave/${source}/${lon}/${lat}`;
      return await fetchSchemaWithToken(_WaveData, url, {
        method: "get",
      });
    }),
);

export const getWaveStatsSelectorFamily = atomFamily(
  ({ parkCenter }: { parkCenter: Position | undefined }) =>
    atom<Promise<WaveStats | undefined>>(async () => {
      if (!parkCenter) return undefined;

      const [lon, lat] = parkCenter;

      const waveParams = await fetchWaveGumbelParameters({
        source: WaveDataSource.BEST,
        lon,
        lat,
      });

      const waveSource = await fetchBestWaveSource({
        lon,
        lat,
      });

      if (!waveParams.loc || !waveParams.scale) {
        return undefined;
      }

      const hs1Yr = gumbelPercentileValue(
        waveParams.loc,
        waveParams.scale,
        Math.exp(1),
      );

      const hs50Yr = gumbelPercentileValue(
        waveParams.loc,
        waveParams.scale,
        0.98,
      );

      return {
        hs1Yr,
        hs50Yr,
        waveDataSource: waveSource.toUpperCase(),
      };
    }),
);

export const getAvailableWaveSourcesSelectorFamily = atomFamily(
  ({ parkCenter }: { parkCenter: Position }) =>
    atom<Promise<string[]>>(async () => {
      const [lon, lat] = parkCenter;

      return await fetchAvailableWaveSources({
        lon,
        lat,
      });
    }),
);
