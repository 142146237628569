import { mapAtom } from "state/map";
import { getActiveOtherMapSelector } from "../state/map";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

const TerrainContourLayer = () => {
  const activeOtherMap = useAtomValue(getActiveOtherMapSelector);
  return (
    <>
      {activeOtherMap?.id === "terrain-contour" && (
        <ActiveTerrainContourLayer />
      )}
    </>
  );
};

const ActiveTerrainContourLayer = () => {
  const map = useAtomValue(mapAtom);

  useEffect(() => {
    if (!map) return;

    // Add the terrain source
    map.addSource("mapbox-terrain", {
      type: "vector",
      url: "mapbox://mapbox.mapbox-terrain-v2",
    });

    map.addLayer({
      id: "terrain-contour-lines",
      type: "line",
      source: "mapbox-terrain",
      "source-layer": "contour",
      paint: {
        "line-color": "#B07C4A",
        "line-width": 1,
        "line-opacity": 0.5,
      },
    });

    map.addLayer({
      id: "terrain-contour-index-lines",
      type: "line",
      source: "mapbox-terrain",
      "source-layer": "contour",
      filter: [
        "any",
        ["==", ["get", "index"], 5],
        ["==", ["get", "index"], 10],
      ],
      paint: {
        "line-color": "#B07C4A",
        "line-width": 1.5,
        "line-opacity": 0.8,
      },
    });

    map.addLayer({
      id: "terrain-contour-labels",
      type: "symbol",
      source: "mapbox-terrain",
      "source-layer": "contour",
      filter: [
        "any",
        ["==", ["get", "index"], 5],
        ["==", ["get", "index"], 10],
      ],
      layout: {
        "symbol-placement": "line",
        "text-field": ["concat", ["number-format", ["get", "ele"], {}], "m"],
        "text-font": ["Open Sans Regular"],
        "text-size": 12,
      },
      paint: {
        "text-color": "#B07C4A",
        "text-halo-color": "rgba(255,255,255,0.8)",
        "text-halo-width": 1.5,
      },
    });

    return () => {
      if (map.getLayer("terrain-contour-labels"))
        map.removeLayer("terrain-contour-labels");
      if (map.getLayer("terrain-contour-index-lines"))
        map.removeLayer("terrain-contour-index-lines");
      if (map.getLayer("terrain-contour-lines"))
        map.removeLayer("terrain-contour-lines");
      if (map.getSource("mapbox-terrain")) map.removeSource("mapbox-terrain");
    };
  }, [map]);

  return null;
};

export default TerrainContourLayer;
