import { useCallback, useEffect, useMemo, useState } from "react";
import { SUBSTATION_PROPERTY_TYPE } from "../../../constants/cabling";
import { DEFAULT_CANVAS_POINT_COLOR } from "../../../constants/canvas";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  PORT_POINT_PROPERTY_TYPE,
  SENSOR_POINT_PROPERTY_TYPE,
  TOUCHDOWN_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "../../../constants/projectMapView";
import { VIEWPOINT_PROPERTY_TYPE } from "@constants/projectMapView";
import { useSetPropertyOnProjectFeatures } from "../../../hooks/useSetPropertyOnProjectFeatures";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import { designToolTypeAtom, mapAtom } from "../../../state/map";
import { colors } from "../../../styles/colors";
import { PointFeatureType, ProjectFeature } from "../../../types/feature";
import { isCable } from "../../../utils/predicates";
import { removeHover, setHover } from "components/Mapbox/utils";
import { MenuItem } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import { parkSourceId } from "../../Mapbox/constants";
import { useProjectElementsCrud } from "../../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "../MenuTracking";
import { branchIdAtom, parkIdAtom, projectIdAtom } from "state/pathParams";
import Tooltip from "components/General/Tooltip";
import { TypeDot } from "components/General/Icons";
import { parksFamily } from "state/jotai/park";
import { useAtomValue } from "jotai";
import { featuresListAtom } from "state/jotai/features";
import { currentTurbineIdAtom } from "state/jotai/turbineType";
import { DesignToolMode } from "types/map";

const pointTypeToName = {
  [TURBINE_PROPERTY_TYPE]: "Turbine",
  [SUBSTATION_PROPERTY_TYPE]: "Substation",
  [ANCHOR_PROPERTY_TYPE]: "Anchor",
  [VIEWPOINT_PROPERTY_TYPE]: "Viewpoint",
  [EXISTING_TURBINE_PROPERTY_TYPE]: "Existing turbine",
  [PORT_POINT_PROPERTY_TYPE]: "Port",
  [SENSOR_POINT_PROPERTY_TYPE]: "Sensor",
  [GRID_CONNECTION_POINT_TYPE]: "Grid connection",
  [TOUCHDOWN_PROPERTY_TYPE]: "Touchdown point",
  other: "Other",
};

export default function PointTypeSelector({
  selections,
  disabled,
  selectedType,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  selectedType?: PointFeatureType | "other";
}) {
  const projectId = useAtomValue(projectIdAtom);
  const designToolType = useAtomValue(designToolTypeAtom);
  const branchId = useAtomValue(branchIdAtom);
  const parkId = useAtomValue(parkIdAtom);

  const map = useAtomValue(mapAtom);
  const parks = useAtomValue(parksFamily({ branchId: branchId ?? "" }));
  const [hoveredParkId, setHoveredParkId] = useState<string | undefined>();

  useEffect(() => {
    if (map) {
      if (hoveredParkId) {
        setHover(map, parkSourceId, hoveredParkId);
        return () => {
          removeHover(map, parkSourceId, hoveredParkId);
        };
      }
    }
  }, [hoveredParkId, map]);

  const currentTurbineType = useAtomValue(currentTurbineIdAtom({ projectId }));

  const projectFeatures = useAtomValue(featuresListAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const setProperties = useSetPropertyOnProjectFeatures();

  const ids = useMemo(() => selections.map((s) => s.id), [selections]);

  const onSelectItem = useCallback(
    (
      item:
        | {
            type:
              | "other"
              | typeof VIEWPOINT_PROPERTY_TYPE
              | typeof PORT_POINT_PROPERTY_TYPE
              | typeof EXISTING_TURBINE_PROPERTY_TYPE
              | typeof GRID_CONNECTION_POINT_TYPE
              | typeof SENSOR_POINT_PROPERTY_TYPE;
          }
        | { type: PointFeatureType; parkId: string },
    ) => {
      trackCanvasOption("change-feature-type", {
        projectId,
        branchId,
      });
      switch (item.type) {
        case "other": {
          setProperties(ids, {
            type: undefined,
            color: DEFAULT_CANVAS_POINT_COLOR,
            parentIds: undefined,
          });
          return;
        }
        case TURBINE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: TURBINE_PROPERTY_TYPE,
            turbineTypeId: currentTurbineType ?? "unknown",
            parentIds: [item.parkId],
          });
          return;
        }
        case SENSOR_POINT_PROPERTY_TYPE: {
          setProperties(ids, {
            type: SENSOR_POINT_PROPERTY_TYPE,
          });
          return;
        }
        case VIEWPOINT_PROPERTY_TYPE: {
          setProperties(ids, {
            type: VIEWPOINT_PROPERTY_TYPE,
          });
          return;
        }
        case EXISTING_TURBINE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: EXISTING_TURBINE_PROPERTY_TYPE,
            name: "Existing turbine",
          });
          return;
        }
        case PORT_POINT_PROPERTY_TYPE: {
          setProperties(ids, {
            type: PORT_POINT_PROPERTY_TYPE,
          });
          return;
        }
        case GRID_CONNECTION_POINT_TYPE: {
          setProperties(ids, {
            type: GRID_CONNECTION_POINT_TYPE,
          });
          return;
        }
        case SUBSTATION_PROPERTY_TYPE: {
          // remove connected cables if changing to substattion
          updateFeatures({
            remove: projectFeatures
              .filter(
                (f) =>
                  isCable(f) &&
                  (ids.includes(f.properties.fromId) ||
                    ids.includes(f.properties.toId)),
              )
              .map((f) => f.id),
          });
          setProperties(ids, {
            type: SUBSTATION_PROPERTY_TYPE,
            parentIds: [item.parkId],
            color: colors.substation,
          });
          return;
        }
        case ANCHOR_PROPERTY_TYPE: {
          setProperties(ids, {
            type: ANCHOR_PROPERTY_TYPE,
            parentIds: [item.parkId],
          });
          return;
        }
      }
    },
    [
      projectId,
      branchId,
      setProperties,
      ids,
      currentTurbineType,
      updateFeatures,
      projectFeatures,
    ],
  );

  return (
    <MenuButton
      side="right"
      offset={[-12, 0]}
      icon={<ArrowRightIcon />}
      iconOpen={<ArrowDownIcon />}
      buttonStyle={{
        border: "none",
        flexDirection: "row-reverse",
        height: "fit-content",
        justifyContent: "space-between",
        padding: 0,
        gap: "1.2rem",
      }}
      buttonType="dropdown"
      buttonText={pointTypeToName[selectedType ?? "other"]}
      disabled={disabled}
    >
      <MenuItem
        name={"Other"}
        onClick={() => onSelectItem({ type: "other" })}
        icon={<TypeDot dotColor={colors.other} />}
      />
      <MenuItem
        name={pointTypeToName[VIEWPOINT_PROPERTY_TYPE]}
        onClick={() => onSelectItem({ type: VIEWPOINT_PROPERTY_TYPE })}
        icon={<TypeDot dotColor={colors.viewpoint} />}
      />
      {designToolType === DesignToolMode.Onshore && (
        <MenuItem
          name={pointTypeToName[SENSOR_POINT_PROPERTY_TYPE]}
          onClick={() => onSelectItem({ type: SENSOR_POINT_PROPERTY_TYPE })}
          icon={<TypeDot dotColor={colors.sensor} />}
        />
      )}
      <MenuItem
        name={pointTypeToName[PORT_POINT_PROPERTY_TYPE]}
        onClick={() => onSelectItem({ type: PORT_POINT_PROPERTY_TYPE })}
        icon={<TypeDot dotColor={colors.port} />}
      />
      <MenuItem
        name={pointTypeToName[EXISTING_TURBINE_PROPERTY_TYPE]}
        onClick={() => onSelectItem({ type: EXISTING_TURBINE_PROPERTY_TYPE })}
        icon={<TypeDot dotColor={colors.existingTurbine} />}
      />
      {parkId ? (
        <MenuItem
          name={pointTypeToName[TURBINE_PROPERTY_TYPE]}
          onClick={() => onSelectItem({ type: TURBINE_PROPERTY_TYPE, parkId })}
          icon={<TypeDot dotColor={colors.turbine} />}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={pointTypeToName[TURBINE_PROPERTY_TYPE]}
            icon={<TypeDot dotColor={colors.turbine} />}
            disabled={parks.length === 0}
            style={{
              width: "100%",
            }}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: TURBINE_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
      {parkId ? (
        <MenuItem
          name={pointTypeToName[SUBSTATION_PROPERTY_TYPE]}
          onClick={() =>
            onSelectItem({ type: SUBSTATION_PROPERTY_TYPE, parkId })
          }
          icon={<TypeDot dotColor={colors.substation} />}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={pointTypeToName[SUBSTATION_PROPERTY_TYPE]}
            icon={<TypeDot dotColor={colors.substation} />}
            disabled={parks.length === 0}
            style={{
              width: "100%",
            }}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: SUBSTATION_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
      {designToolType === DesignToolMode.Offshore && (
        <>
          {parkId && designToolType === DesignToolMode.Offshore ? (
            <MenuItem
              name={pointTypeToName[ANCHOR_PROPERTY_TYPE]}
              onClick={() =>
                onSelectItem({ type: ANCHOR_PROPERTY_TYPE, parkId })
              }
              icon={<TypeDot dotColor={colors.anchor} />}
            />
          ) : (
            <Tooltip
              text="You need at least one park to be able to convert the feature to this type"
              disabled={parks.length > 0}
              innerDivStyle={{
                width: "100%",
              }}
              position="right"
            >
              <MenuItem
                name={pointTypeToName[ANCHOR_PROPERTY_TYPE]}
                icon={<TypeDot dotColor={colors.anchor} />}
                disabled={parks.length === 0}
                style={{
                  width: "100%",
                }}
              >
                {parks.map((park) => (
                  <MenuItem
                    key={park.id}
                    name={park.properties.name ?? ""}
                    onClick={() =>
                      onSelectItem({
                        type: ANCHOR_PROPERTY_TYPE,
                        parkId: park.id,
                      })
                    }
                    onMouseEnter={() => setHoveredParkId(park.id)}
                    onMouseLeave={() => setHoveredParkId(undefined)}
                  />
                ))}
              </MenuItem>
            </Tooltip>
          )}
        </>
      )}
    </MenuButton>
  );
}
