import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceLarge, spaceMedium } from "styles/space";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { UserAccessRole } from "types/user";

export const FrameLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spaceLarge,
        padding: `0 ${spaceLarge}`,
      }}
    >
      <SkeletonBlock style={{ height: "3rem" }} />
      <SkeletonBlock style={{ height: "3rem" }} />
      <SkeletonBlock style={{ height: "3rem" }} />
    </div>
  );
};

const RoundIconWrapper = styled.div`
  padding: ${spaceMedium};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.errorText}33;

  svg {
    path {
      stroke: ${colors.errorText};
    }
  }
`;

export type InvitationNodeAccess = {
  nodeId: string;
  role?: UserAccessRole;
};
