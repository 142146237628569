import { useAtom, useSetAtom } from "jotai";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg";
import GlobeIcon from "@icons/24/Globe.svg";
import WindowsIcon from "@icons/24/Windows.svg";
import { modalTypeOpenAtom } from "state/modal";
import { StandardBox } from "styles/boxes/Boxes";
import { Divider, IconBtn } from "components/General/Icons";
import Windowed from "components/WindowedHOC/WindowedHOC";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryLocalFallback,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import Tooltip from "../General/Tooltip";
import {
  filteredLibraryDataLayersIdsAtom,
  selectedLayersToAddAtom,
  selectedSourceNameAtom,
  SourceName,
} from "../LayerList/LayerSettings/state";
import { ModalHeader, Row } from "../RightSide/InfoModal/InfoModal.style";
import SearchBox from "./components/DataLayerFilters/SearchBox";
import {
  activeFiltersAtom,
  filteredLayerListWithOverlapSelector,
} from "./layer-filter-state";
import { colors } from "styles/colors";
import LibraryDataLayersModal from "./components/LibraryDataLayersModal/LibraryDataLayersModal";
import { useAtomValue } from "jotai";
import SidePanel from "./SidePanel";
import VindDataLayerSuggestions from "./components/VindDataLayerSuggestions/VindDataLayerSuggestions";
import FavoriteLayers from "./components/FavoriteLayers/FavoriteLayers";
import OverlapAnalysis from "./components/OverlapAnalysis";
import { borderRadiusMedium, spaceSmall } from "styles/space";
import SectionIcon from "@icons/24/Section.svg?react";
import ListViewIcon from "@icons/16/ListView.svg?react";
import DataLayerErrorBoundaryComponent from "./components/DataLayerErrorBoundary";
import ExternalDataLayersModal from "./components/ExternalDataLayers/ExternalDataLayersModal";
import DataLayerSideModal from "./components/DataLayerSideModal";
import { Layer } from "types/layers";
import Footer from "./components/Footer";
import { useDataLayerCellProps } from "hooks/useDataLayerCellProps";
import { projectIdAtom } from "state/pathParams";
import { typography } from "styles/typography";
import {
  favoriteLayersAtom,
  getFavoriteLayersFromStorage,
  getSuggestedLayersSelector,
  useFavoriteLayers,
} from "state/layer";
import { LoadingBarWrapper } from "components/MenuPopup/CloseableMenuPopup";
import { atomWithStorage, loadable } from "jotai/utils";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export enum DataLayerTab {
  EXTERNAL = "External",
  DATA_PACKAGES = "GIS packages",
  VIND_SUGGESTIONS = "Vind suggestions",
  FAVORITES = "Favorites",
}

const SelectedDataLayerTabContext = React.createContext<DataLayerTab>(
  DataLayerTab.EXTERNAL,
);

export const AddDataLayersModalType = "AddDataLayersModalType" as const;

const LoadingArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-top: 0.8rem;
  margin: 0 2rem;
`;

const LoadingBarContainer = styled.div`
  height: 5px;
  position: relative;
  max-width: 30rem;
`;

export const Modal = styled(StandardBox)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 2.4rem 1.4rem 2.4rem;
  background-color: ${colors.blue700};
`;

export const DataLayersTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem 2.2rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  color: ${colors.white} !important;
  && svg path[stroke] {
    stroke: ${colors.white} !important;
  }

  &&:hover svg path[stroke] {
    stroke: ${colors.white} !important;
  }

  &&.text {
    &:hover svg path[stroke] {
      stroke: ${colors.white} !important;
    }
    &:active svg path[stroke] {
      stroke: ${colors.white} !important;
    }
  }
`;

const IconWrapper = styled.div<{
  selected?: boolean;
  type: "grid" | "list";
}>`
  &:hover {
    background: ${colors.surfaceHover};
    cursor: pointer;
  }

  background: ${(p) =>
    p.selected ? colors.surfaceSelectedLight : colors.surfaceSecondary};
  border-radius: ${(p) =>
    p.type === "grid"
      ? `${borderRadiusMedium} 0 0 ${borderRadiusMedium}`
      : `0 ${borderRadiusMedium} ${borderRadiusMedium} 0`};
  padding: ${spaceSmall};

  display: flex;
  flex-direction: column;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    path {
      stroke: ${(p) =>
        p.type === "grid"
          ? p.selected
            ? colors.iconSelected
            : colors.iconDisabled
          : "none"};
      fill: ${(p) =>
        p.type === "list"
          ? p.selected
            ? colors.iconSelected
            : colors.iconDisabled
          : "none"};
    }
  }
`;

export type ViewMode = "list" | "grid";

const SearchBoxContainer = styled.div`
  width: 100%;
  padding-right: 2rem;
`;

const SideModalWrapper = styled.div`
  flex: 0 0 450px;
  overflow-y: auto;
  border-left: 1px solid ${colors.borderSubtle};
`;

const TableAndSideModalWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

const GRID_LIST_VIEW_MODE = "vind:grid-list-view-mode";

const viewModeAtom = atomWithStorage<ViewMode>(GRID_LIST_VIEW_MODE, "grid");

const LoadingOverlay = styled.p`
  ${typography.caption};
`;

const LoadingText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const DataLayersModalInner = ErrorBoundaryWrapper(
  () => {
    const [selectedTab, setSelectedTab] = useState(DataLayerTab.EXTERNAL);
    const [activeFilters, setActiveFilters] = useAtom(activeFiltersAtom);
    const [favoriteLayerIds, setFavoriteLayerIds] = useAtom(favoriteLayersAtom);

    useEffect(() => {
      const storedFavorites = getFavoriteLayersFromStorage();
      setFavoriteLayerIds(storedFavorites);
    }, [setFavoriteLayerIds]);

    const [viewMode, setViewMode] = useAtom(viewModeAtom);
    const [selectedLayer, setSelectedLayer] = useState<Layer | null>(null);
    const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
      selectedLayersToAddAtom,
    );
    const filteredDataPackageLayersIds = useAtomValue(
      filteredLibraryDataLayersIdsAtom,
    );

    const projectId = useAtomValue(projectIdAtom) ?? "";

    const layersWithLoadState = useAtomValue(
      loadable(
        filteredLayerListWithOverlapSelector({
          projectId,
        }),
      ),
    );

    const filteredLayerList: Layer[] =
      layersWithLoadState.state === "hasData"
        ? layersWithLoadState.data?.layers ?? []
        : [];

    const loadingState =
      layersWithLoadState.state === "hasData"
        ? layersWithLoadState.data?.loadingState
        : undefined;

    const { handleToggleFavorite } = useFavoriteLayers();
    const suggestedLayers = useAtomValue(getSuggestedLayersSelector);

    const getLayersForCurrentTab = () => {
      switch (selectedTab) {
        case DataLayerTab.EXTERNAL:
          return filteredLayerList.map((layer) => layer.id);
        case DataLayerTab.VIND_SUGGESTIONS:
          return suggestedLayers.map((layer) => layer.id);
        case DataLayerTab.DATA_PACKAGES:
          return filteredDataPackageLayersIds;
        case DataLayerTab.FAVORITES:
          return favoriteLayerIds;
        default:
          return [];
      }
    };

    const currentLayersIds = getLayersForCurrentTab();

    const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
    const toggleViewMode = useCallback(() => {
      setViewMode((prevMode) => (prevMode === "list" ? "grid" : "list"));
    }, [setViewMode]);

    const handleSearchChange = useCallback(
      (searchString: string) => {
        setActiveFilters((state) => ({
          ...state,
          searchString,
        }));
      },
      [setActiveFilters],
    );

    const handleTabChange = (tab: DataLayerTab) => {
      setSelectedTab(tab);
      setActiveFilters((prev) => ({ ...prev, searchString: "" }));
    };

    const cellProps = useDataLayerCellProps();

    const handleAddSelectedToMap = useCallback(async () => {
      // Add all layers in a single operation
      await cellProps.addMultipleLayers(selectedLayersToAdd);

      setSelectedLayersToAdd([]); // Clear selection after adding
      setModalTypeOpen(undefined);
    }, [
      selectedLayersToAdd,
      cellProps,
      setSelectedLayersToAdd,
      setModalTypeOpen,
    ]);

    const memoizedSearchBox = useMemo(
      () => (
        <SearchBox
          onChange={handleSearchChange}
          value={activeFilters.searchString}
        />
      ),
      [handleSearchChange, activeFilters.searchString],
    );

    return (
      <ModalContent>
        <div style={{ display: "flex", overflow: "hidden", height: "100%" }}>
          <SidePanel
            selectedTab={selectedTab}
            setSelectedTab={handleTabChange}
          />
          <TableAndSideModalWrapper>
            <MainContent>
              <div>
                <Row
                  style={{
                    justifyContent: "space-between",
                    padding: "2rem",
                  }}
                >
                  <h4>{selectedTab}</h4>
                  {selectedTab !== DataLayerTab.DATA_PACKAGES && (
                    <Row
                      style={{
                        gap: 0,
                      }}
                    >
                      <Tooltip text="Grid view">
                        <IconWrapper
                          type="grid"
                          id="button-project-view-grid"
                          selected={viewMode === "grid"}
                          onClick={toggleViewMode}
                        >
                          <SectionIcon />
                        </IconWrapper>
                      </Tooltip>
                      <Tooltip text="List view">
                        <IconWrapper
                          type="list"
                          id="button-project-view-list"
                          selected={viewMode === "list"}
                          onClick={toggleViewMode}
                        >
                          <ListViewIcon />
                        </IconWrapper>
                      </Tooltip>
                    </Row>
                  )}
                </Row>
                <Row style={{ padding: "0 2rem" }}>
                  <SearchBoxContainer>{memoizedSearchBox}</SearchBoxContainer>
                  <OverlapAnalysis />
                </Row>
                {loadingState?.isLoading && (
                  <LoadingArea>
                    <LoadingText>
                      <LoadingOverlay>
                        Loading overlapping layers... (
                        {loadingState?.completedCount ?? 0}/
                        {loadingState?.maxLayers ?? 0})
                      </LoadingOverlay>
                    </LoadingText>
                    <LoadingBarContainer>
                      <LoadingBarWrapper
                        block={false}
                        progress={
                          loadingState
                            ? (loadingState.completedCount /
                                loadingState.maxLayers) *
                              100
                            : undefined
                        }
                      />
                    </LoadingBarContainer>
                  </LoadingArea>
                )}
              </div>
              {selectedTab === DataLayerTab.EXTERNAL && (
                <ExternalDataLayersModal
                  selectedLayer={selectedLayer}
                  setSelectedLayer={setSelectedLayer}
                  viewMode={viewMode}
                  handleToggleFavorite={handleToggleFavorite}
                />
              )}
              {selectedTab === DataLayerTab.DATA_PACKAGES && (
                <WrappedLibraryDataLayersModal
                  searchString={activeFilters.searchString}
                />
              )}
              {selectedTab === DataLayerTab.FAVORITES && (
                <FavoriteLayers
                  selectedLayer={selectedLayer}
                  setSelectedLayer={setSelectedLayer}
                  viewMode={viewMode}
                  handleToggleFavorite={handleToggleFavorite}
                  layerIds={favoriteLayerIds}
                  handleSetSelectedTab={handleTabChange}
                />
              )}
              {selectedTab === DataLayerTab.VIND_SUGGESTIONS && (
                <VindDataLayerSuggestions
                  selectedLayer={selectedLayer}
                  setSelectedLayer={setSelectedLayer}
                  viewMode={viewMode}
                  handleToggleFavorite={handleToggleFavorite}
                />
              )}
            </MainContent>
            {selectedLayer && (
              <SideModalWrapper>
                <DataLayerSideModal
                  layer={selectedLayer}
                  onClose={() => setSelectedLayer(null)}
                />
              </SideModalWrapper>
            )}
          </TableAndSideModalWrapper>
        </div>
        <Footer
          handleAddSelectedToMap={handleAddSelectedToMap}
          currentLayersIds={currentLayersIds}
        />
      </ModalContent>
    );
  },
  DataLayerErrorBoundaryComponent,
  ScreamOnError,
);

const WrappedLibraryDataLayersModal = ErrorBoundaryWrapper(
  LibraryDataLayersModal,
  ErrorBoundaryLocalFallback,
  ScreamOnError,
);

const WindowedDataLayersModal = Windowed(
  DataLayersModalInner,
  ({ children, setOpenInNewWindow, closeModal }) => {
    const [selectedSourceName, setSelectedSourceName] = useAtom(
      selectedSourceNameAtom,
    );
    const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
      selectedLayersToAddAtom,
    );
    const { showConfirm } = useConfirm();
    return (
      <FullScreenModal disableClose={selectedLayersToAdd.length > 0}>
        <Modal>
          <HeaderRow>
            <ModalHeader>Browse GIS layers</ModalHeader>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Tooltip text="Change language" position="bottom">
                <ButtonWrapper>
                  <MenuButton
                    buttonStyle={{ color: colors.white }}
                    icon={<GlobeIcon />}
                    buttonType="text"
                    buttonText={selectedSourceName.language}
                  >
                    {Object.values(SourceName).map((sourceName) => (
                      <MenuItem
                        key={sourceName}
                        name={sourceName}
                        onClick={() => {
                          setSelectedSourceName({
                            language: sourceName,
                          });
                        }}
                      />
                    ))}
                  </MenuButton>
                </ButtonWrapper>
              </Tooltip>

              <Divider />
              <Tooltip text="Open in new window">
                <IconBtn
                  size="1.5rem"
                  onClick={setOpenInNewWindow}
                  iconColor={colors.white}
                  hoverBackgroundColor="unset"
                >
                  <WindowsIcon />
                </IconBtn>
              </Tooltip>
              <IconBtn
                size="1.5rem"
                onClick={async () => {
                  if (selectedLayersToAdd.length > 0) {
                    if (
                      await showConfirm({
                        title: "Unsaved changes",
                        message:
                          "Are you sure you want to quit without adding the selected layers to the map?",
                      })
                    ) {
                      setSelectedLayersToAdd([]);
                      closeModal();
                    }
                  } else {
                    closeModal();
                  }
                }}
                iconColor={colors.white}
                hoverBackgroundColor="unset"
              >
                <CloseIcon />
              </IconBtn>
            </div>
          </HeaderRow>
          <SelectedDataLayerTabContext.Provider value={DataLayerTab.EXTERNAL}>
            {children}
          </SelectedDataLayerTabContext.Provider>
        </Modal>
      </FullScreenModal>
    );
  },
  ({ children }) => {
    const [selectedSourceName, setSelectedSourceName] = useAtom(
      selectedSourceNameAtom,
    );

    return (
      <div
        style={{
          gap: "2rem",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
          height: "100%",
          backgroundColor: colors.background,
        }}
      >
        <HeaderRow>
          <ModalHeader>Browse GIS layers</ModalHeader>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Tooltip text="Change language" position="bottom">
              <ButtonWrapper>
                <MenuButton
                  buttonStyle={{ color: colors.white }}
                  icon={<GlobeIcon />}
                  buttonType="text"
                  buttonText={selectedSourceName.language}
                >
                  {Object.values(SourceName).map((sourceName) => (
                    <MenuItem
                      key={sourceName}
                      name={sourceName}
                      onClick={() => {
                        setSelectedSourceName({
                          language: sourceName,
                        });
                      }}
                    />
                  ))}
                </MenuButton>
              </ButtonWrapper>
            </Tooltip>
          </div>
        </HeaderRow>
        {children}
      </div>
    );
  },
  AddDataLayersModalType,
  {
    width: 1400,
    height: 810,
    title: "GIS layers",
  },
);

const DataLayersModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const openInNewWindow = useAtomValue(openInNewWindowAtom);

  if (
    modalTypeOpen?.modalType !== AddDataLayersModalType &&
    !openInNewWindow[AddDataLayersModalType]
  ) {
    return null;
  }

  return <WindowedDataLayersModal />;
};

export default DataLayersModal;
