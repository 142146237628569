import * as turf from "@turf/turf";
import {
  ProdId,
  getCloseExistingTurbines,
  getPark,
  getSubAreas,
  getSurroundingTurbines,
  getTurbineTypes,
  getTurbines,
} from "analysis/inputs";
import { getAnalysisVersion } from "analysis/output";
import { Anchor } from "components/General/Anchor";
import { Row } from "components/General/Layout";
import { Tag } from "components/General/Tag";
import { NumberOfTurbinesTag } from "components/GenerateWindTurbines/Tags";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { useMemo, useRef, useState } from "react";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import { getHumanReadableArea } from "utils/geometry";
import { lunwrap } from "utils/jotai";
import { isSubArea } from "utils/predicates";
import { sum } from "utils/utils";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";

const ExpandArrowWrapper = styled.div<{
  open: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0.6rem 0.6rem;
  cursor: pointer;
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const Text = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 2.5rem;
`;

const Subtitle = styled.div`
  ${typography.sub2}
`;

const Modal = styled(StandardBox)`
  padding: 1.2rem 1.6rem;

  display: flex;

  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  max-height: 40rem;
  overflow-y: auto;

  max-width: 40rem;
  min-width: 20rem;
  /* Prevent content from collapsing */
  > * {
    flex-shrink: 0; /* Prevent children from shrinking */
  }
`;

const AnalysisVersion = ({ id }: { id: ProdId }) => {
  const analysisVersion = lunwrap(
    useAtomValue(loadable(getAnalysisVersion(id))),
  );
  if (!analysisVersion) return null;
  return <Tag text={`v${analysisVersion}`} />;
};

export const ProductionTagList = ({ id }: { id: ProdId }) => {
  const selectedRawFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const selectedRawSubAreas = useMemo(
    () => selectedRawFeatures.filter(isSubArea),
    [selectedRawFeatures],
  );

  const selectedSubAreaIds = useMemo(
    () => selectedRawSubAreas.map((f) => f.id).map(String),
    [selectedRawSubAreas],
  );

  const subAreas = useAtomValue(getSubAreas(id));
  const selectedSubAreas = subAreas.filter((f) =>
    selectedSubAreaIds.includes(f.id),
  );

  const subArea = useMemo(() => {
    const zones = selectedSubAreas.length > 0 ? selectedSubAreas : subAreas;
    return getHumanReadableArea(sum(zones, (f) => turf.area(f)));
  }, [subAreas, selectedSubAreas]);

  const park = useAtomValue(getPark(id));
  const parkAreaString = useMemo(
    () => getHumanReadableArea(turf.area(park)),
    [park],
  );

  const turbines = useAtomValue(getTurbines(id));
  const neighbourTurbines = useAtomValue(getSurroundingTurbines(id));
  const existingTurbines = useAtomValue(getCloseExistingTurbines(id));
  const turbineTypes = useAtomValue(getTurbineTypes(id));

  const turbineTypesAndUsage = useMemo(() => {
    const result = Array.from(turbineTypes.entries())
      .map(([id, value]) => ({
        name: value.name,
        count: turbines.filter((t) => t.properties.turbineTypeId === id).length,
      }))
      .filter(({ count }) => count > 0);

    return result;
  }, [turbineTypes, turbines]);

  const neighbourTurbineTypesAndUsage = useMemo(() => {
    return Array.from(turbineTypes.entries())
      .map(([id, value]) => ({
        name: value.name,
        count: neighbourTurbines.filter(
          (t) => t.properties.turbineTypeId === id,
        ).length,
      }))
      .filter(({ count }) => count > 0);
  }, [neighbourTurbines, turbineTypes]);

  const totalNeighoburs = neighbourTurbines.length + existingTurbines.length;
  const numberOfTurbinesString =
    `${turbines.length}` +
    (totalNeighoburs > 0 ? ` (+${totalNeighoburs})` : "");

  const [isModalVisible, setModalVisible] = useState(false);

  const handleMouseEnter = () => setModalVisible(true);
  const handleMouseLeave = () => setModalVisible(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showNeighbourTurbineTypes, setShowNeighbourTurbineTypes] =
    useState(false);
  const [showTurbineTypes, setShowTurbineTypes] = useState(true);

  return (
    <Row
      style={{
        justifyContent: "left",
        flexWrap: "wrap",
      }}
    >
      {0 < selectedSubAreaIds.length ? (
        <Tag
          text="Selected sub area(s)"
          tooltip="These analysis numbers are only for the selected zone."
          style="success"
        />
      ) : (
        <Tag text="Park" />
      )}
      <Tag text={selectedSubAreaIds.length ? subArea : parkAreaString} />
      <div
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NumberOfTurbinesTag n={numberOfTurbinesString} />
        {isModalVisible && (
          <Anchor baseRef={buttonRef} floatPlace="right" offset={[-22, 0]}>
            <Modal>
              <Row onClick={() => setShowTurbineTypes(!showTurbineTypes)}>
                <IconREMSize height={0.6} width={0.6}>
                  <ExpandArrowWrapper open={showTurbineTypes}>
                    <ChevronDownIcon />
                  </ExpandArrowWrapper>
                </IconREMSize>
                <Subtitle>Turbine types</Subtitle>
              </Row>
              {showTurbineTypes && (
                <>
                  {turbineTypesAndUsage.map(({ name, count }) => (
                    <Text key={name}>
                      {count}x {name}
                    </Text>
                  ))}
                </>
              )}
              {neighbourTurbines.length > 0 && (
                <>
                  <Row
                    style={{ paddingTop: "0.8rem" }}
                    onClick={() =>
                      setShowNeighbourTurbineTypes(!showNeighbourTurbineTypes)
                    }
                  >
                    <IconREMSize height={0.6} width={0.6}>
                      <ExpandArrowWrapper open={showNeighbourTurbineTypes}>
                        <ChevronDownIcon />
                      </ExpandArrowWrapper>
                    </IconREMSize>
                    <Subtitle>Neighbour turbine types</Subtitle>
                  </Row>
                  {showNeighbourTurbineTypes && (
                    <>
                      {neighbourTurbineTypesAndUsage.map(({ name, count }) => (
                        <Text key={name}>
                          {count}x {name}
                        </Text>
                      ))}
                    </>
                  )}
                </>
              )}
            </Modal>
          </Anchor>
        )}
      </div>
      <AnalysisVersion id={id} />
    </Row>
  );
};
