import { useMemo } from "react";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import CostIcon from "@icons/24/Dollar.svg?react";
import { CostConfiguration } from "../../services/costService";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import {
  libraryCostConfigurationsFamily,
  projectCostConfigurationsFamily,
} from "state/jotai/costConfiguration";
import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";

export const CostConfigurationSelector = ({
  configuration,
  setConfiguration,
}: {
  configuration?: CostConfiguration;
  setConfiguration: (cfg: CostConfiguration) => void;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const projectConfigs = useAtomValue(
    projectCostConfigurationsFamily({ projectId }),
  );
  const libraryConfigs = useAtomValue(
    libraryCostConfigurationsFamily({ projectId }),
  );
  const allConfigs = new Map([...projectConfigs, ...libraryConfigs]);

  const items = useMemo(() => {
    const items: DropDownItem[] = [];
    for (const cfg of libraryConfigs.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
        icon: (
          <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
            <LibraryIcon />
          </IconREMSize>
        ),
      });
    for (const cfg of projectConfigs.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
        icon: (
          <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
            <ProjectIcon />
          </IconREMSize>
        ),
      });
    return items;
  }, [projectConfigs, libraryConfigs]);

  const selected = items.find((i) => i.value === configuration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = allConfigs.get(e);
        if (!cfg) throw new Error("Configuration not found");
        setConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select cost configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <CostIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
