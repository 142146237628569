import { z } from "zod";
import {
  _CustomLayer,
  _ExternalDataSourceLinkLayerWithSourceArcgis,
  _ExternalDataSourceLinkLayerWithSourceHosted,
  _ExternalDataSourceLinkLayerWithSourceTileJSON,
  _ExternalDataSourceLinkLayerWithSourceWFS,
  _ExternalDataSourceLinkLayerWithSourceWMS,
  _ExternalDataSourceLinkLayerWithSourceWMTS,
  _ExternalDataSourceLinkLayerWithSourceXYZ,
} from "../../../types/layers";

export enum LayerStrokeStyle {
  Dashed = "dashed",
  Solid = "solid",
  Dotted = "dotted",
}

export const _LayerSettings = z.object({
  id: z.string(),
  standard: z.boolean(),
  visible: z.boolean().optional(),
  alias: z.string().optional(),
  overrideLayerStyle: z.boolean().optional(),
  layerStyle: z
    .object({
      /** In [0, 1] */
      opacity: z.number().optional(),
      /** Hex */
      color: z.string().optional(),
      strokeColor: z.string().optional(),
      strokeStyle: z.nativeEnum(LayerStrokeStyle).optional(),
      pinnedProperties: z.string().array().optional(),
      zoomLevels: z.tuple([z.number(), z.number()]).optional(),
    })
    .optional(),
});
export type LayerSettings = z.infer<typeof _LayerSettings>;

const _LayerCollectionSettings = z.object({
  standard: z.boolean(),
  visible: z.boolean().optional(),
});

const _LayerCollectionLayer = _CustomLayer
  .or(_ExternalDataSourceLinkLayerWithSourceTileJSON)
  .or(_ExternalDataSourceLinkLayerWithSourceHosted)
  .or(_ExternalDataSourceLinkLayerWithSourceArcgis)
  .or(_ExternalDataSourceLinkLayerWithSourceWFS)
  .or(_ExternalDataSourceLinkLayerWithSourceWMS)
  .or(_ExternalDataSourceLinkLayerWithSourceWMTS)
  .or(_ExternalDataSourceLinkLayerWithSourceXYZ);

const _LayerCollection = z.object({
  id: z.string(),
  name: z.string(),
  layers: _LayerCollectionLayer.array(),
  isDefault: z.boolean(),
  settings: _LayerCollectionSettings.optional(),
});
export type LayerCollection = z.infer<typeof _LayerCollection>;
export type LayerCollectionLayer = z.infer<typeof _LayerCollectionLayer>;

const _PublicLayerCollectionSetting = z.object({
  collectionId: z.string(),
  toggledByDefault: z.boolean(),
});
