import DownloadIcon from "@icons/24/Download.svg?react";
import { getConfiguration, getTurbineCapacity } from "analysis/inputs";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { FinanceId } from "finance/types";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { stringify } from "csv-stringify/sync";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { colors } from "styles/colors";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { getStats } from "analysis/output";

const useNumbers = (triggerId: FinanceId) => {
  const analysis = useAtomValue(getStats(triggerId));
  const interArrayResult = analysis.interArrayStats;

  const config = useAtomValue(getConfiguration(triggerId)).electrical;
  const powerBins = interArrayResult?.powerBins;
  const capacityMW = useAtomValue(getTurbineCapacity(triggerId));
  const capacity = capacityMW / 1000;

  const cableLossPerCase = useMemo(() => {
    if (!interArrayResult) return undefined;
    if (!config.interArrayCableLoss) return null;
    return interArrayResult.interArrayCableLossPerBin;
  }, [config, interArrayResult]);

  const turbineTrafoLossPerCase = useMemo(() => {
    if (!interArrayResult) return undefined;
    if (!config.turbineTrafoLoss) return null;
    return interArrayResult.interArrayTrafoLossPerBin;
  }, [config, interArrayResult]);

  const totalLossPerCase = useMemo(() => {
    if (cableLossPerCase === undefined || turbineTrafoLossPerCase === undefined)
      return undefined;
    if (cableLossPerCase === null && turbineTrafoLossPerCase === null)
      return null;

    if (cableLossPerCase && turbineTrafoLossPerCase) {
      return cableLossPerCase.map((v, i) => v + turbineTrafoLossPerCase[i]);
    }
    if (cableLossPerCase === null) {
      return turbineTrafoLossPerCase;
    }
    if (turbineTrafoLossPerCase === null) {
      return cableLossPerCase;
    }
  }, [cableLossPerCase, turbineTrafoLossPerCase]);

  const powerBinsMidPoints = useMemo(() => {
    if (!powerBins || !capacity) return undefined;

    return powerBins.slice(0, -1).map((b, i) => (b + powerBins[i + 1]) / 2);
  }, [powerBins, capacity]);

  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  return {
    cableLossPerCase,
    turbineTrafoLossPerCase,
    totalLossPerCase,
    powerBinsMidPoints,
    powerBins,
    stoppedReason,
    analysisStoppedReason,
    capacity: capacityMW,
  };
};

const InterArrayLossGraph = () => {
  const { configuration, park, branch, triggerId } = useDashboardContext();

  const {
    totalLossPerCase,
    turbineTrafoLossPerCase,
    cableLossPerCase,
    powerBinsMidPoints,
    stoppedReason,
    analysisStoppedReason,
    capacity,
  } = useNumbers(triggerId);

  const { cables: invalidCables } = useAtomValue(
    invalidTypesInParkFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  if (invalidCables)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some cables in the park have invalid cable types."}
        />
      </CenterContainer>
    );

  if (stoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }
  if (analysisStoppedReason) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={
          analysisStoppedText[analysisStoppedReason as AnalysisStoppedTypes]
        }
        type={"error"}
      />
    );
  }

  if (
    (!configuration.electrical.interArrayCableLoss &&
      !configuration.electrical.turbineTrafoLoss) ||
    totalLossPerCase === null
  ) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Inter-array cable loss and turbine trafo loss are disabled in the current analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (
    totalLossPerCase === undefined ||
    !powerBinsMidPoints ||
    !capacity ||
    turbineTrafoLossPerCase === undefined ||
    cableLossPerCase === undefined
  )
    return <LoadingState />;

  return (
    <>
      <InterArrayLossGraphInner
        totalLossPerCase={totalLossPerCase}
        turbineTrafoLossPerCase={turbineTrafoLossPerCase}
        cableLossPerCase={cableLossPerCase}
        powerBinsMidPoints={powerBinsMidPoints}
        capacity={capacity}
      />
    </>
  );
};

const InterArrayLossGraphInner = ({
  totalLossPerCase,
  turbineTrafoLossPerCase,
  cableLossPerCase,
  powerBinsMidPoints,
  capacity,
}: {
  totalLossPerCase: number[];
  cableLossPerCase: number[] | null;
  turbineTrafoLossPerCase: number[] | null;
  powerBinsMidPoints: number[];
  capacity: number;
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);
    const x = powerBinsMidPoints.map((v) => v);
    let data: any[] = [
      {
        name: "Total",
        y: totalLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue700 } as const,
        customdata: totalLossPerCase.map((v, i) => [
          (100 * v) / powerBinsMidPoints[i],
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the total inter array loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "lines",
        type: "scatter" as const,
      } as const,
    ];

    if (turbineTrafoLossPerCase) {
      data.push({
        name: "Turbine trafo loss",
        y: turbineTrafoLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue500 } as const,
        customdata: turbineTrafoLossPerCase.map((v, i) => [
          (100 * v) / powerBinsMidPoints[i],
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the trafo-related loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "dashed",
        type: "scatter" as const,
      } as const);
    }
    if (cableLossPerCase) {
      data.push({
        name: "Inter array cable loss",
        y: cableLossPerCase,
        x,
        line: { shape: "vh", color: colors.blue300 } as const,
        customdata: cableLossPerCase.map((v, i) => [
          (100 * v) / powerBinsMidPoints[i],
          powerBinsMidPoints[i] * 100,
        ]),
        hovertemplate:
          "At a park production rate of %{x:,.0f}MW (%{customdata[1]:,.0f}%),<br>the cable-related loss is %{y:,.2f}MW (%{customdata[0]:,.2f}%)",
        mode: "dashed",
        type: "scatter" as const,
      } as const);
    }

    const layout = {
      hovermode: "closest" as const,
      legend: {
        x: 0.02,
        y: 1.1,
        orientation: "h",
      } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 250,
      bargap: 0.05,
      xaxis: {
        title: "Park production [MW]",
      },
      yaxis: {
        title: "Electrical loss [MW]",
        range: [0, 1.1 * fastMax(totalLossPerCase)],
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
    }).then(() => setIsLoaded(true));
  }, [
    graphRef,
    powerBinsMidPoints,
    totalLossPerCase,
    capacity,
    cableLossPerCase,
    turbineTrafoLossPerCase,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const InterArrayLossGraphMenu = () => {
  const { triggerId } = useDashboardContext();
  const {
    totalLossPerCase,
    powerBins,
    capacity,
    cableLossPerCase,
    turbineTrafoLossPerCase,
  } = useNumbers(triggerId);

  const { warning } = useToast();

  const download = useCallback(() => {
    if (
      !totalLossPerCase ||
      !powerBins ||
      !capacity ||
      !cableLossPerCase ||
      !turbineTrafoLossPerCase
    ) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const header = [
      "Production bin start [MW]",
      "Production bin end [MW]",
      "Cable loss [MW]",
      "Trafo loss [MW]",
      "Total inter array loss [MW]",
      "Probability [%]",
    ];

    const rows = totalLossPerCase
      .map((_, i) => {
        const binStart = (powerBins[i] * capacity).toFixed(1);
        const binStop = Math.min(powerBins[i + 1] * capacity, capacity).toFixed(
          1,
        );
        const cableLoss = cableLossPerCase[i].toFixed(2);
        const trafoLoss = turbineTrafoLossPerCase[i].toFixed(2);
        const loss = totalLossPerCase[i].toFixed(2);

        return [binStart, binStop, cableLoss, trafoLoss, loss];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "inter_array_loss.csv");
  }, [
    powerBins,
    capacity,
    totalLossPerCase,
    warning,
    cableLossPerCase,
    turbineTrafoLossPerCase,
  ]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const InterArrayLossGraphWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Inter array loss graph"
      id="Inter array loss graph"
      menuItems={<InterArrayLossGraphMenu />}
      resetKeys={errorBoundaryResetKeys}
    >
      <InterArrayLossGraph />
    </SafeCard>
  );
};
