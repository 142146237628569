import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { groupMembersState } from "components/Organisation/Groups/state";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  TableHeader,
  TabSubtitle,
} from "components/Organisation/OrganisationRightSide/style";
import { usersInOrganisationState } from "components/Organisation/state";
import React, { useState, useCallback } from "react";
import { adminInOrganisationSelectorFamily } from "state/user";
import { OrganisationUser } from "types/customer";
import GroupMemberModal from "../../GroupMemberModal";
import RemoveIcon from "@icons/24/Remove.svg";
import { Group } from "components/Organisation/Groups/types";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { StyledLink } from "components/Organisation/Library/style";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export function GroupMembers({ group }: { group: Group }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { add, remove } = useMemberInGroupCrud();
  const groupId = group.id;
  const { showConfirm } = useConfirm();

  const users = useAtomValue(usersInOrganisationState(organisationId));
  const members = useAtomValue(
    groupMembersState({
      organisationId,
      groupId,
    }),
  );
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);
  const onSaveMultiple = useCallback(
    async (users: OrganisationUser[]) => {
      setShowUserSearch(false);
      setAddInProgress(users.length);
      try {
        await Promise.all(
          users.map((u) =>
            add(groupId, u.user_id).then(() =>
              setAddInProgress((cur) => cur - 1),
            ),
          ),
        );
      } catch {
      } finally {
        setAddInProgress(0);
      }
    },
    [add, groupId],
  );
  const onRemove = useCallback(
    async (user_id: string) => {
      setRemoveInProgress(true);
      try {
        await remove(groupId, user_id);
      } catch {
      } finally {
        setRemoveInProgress(false);
      }
    },
    [groupId, remove],
  );

  const [showUserSearch, setShowUserSearch] = useState(false);

  return (
    <Column
      style={{
        gap: "1.6rem",
        height: "100%",
      }}
    >
      <Row
        style={{
          alignItems: "center",
        }}
      >
        <TabSubtitle>
          Overview of collaborators in {group.name}.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={"https://help.vindtech.com/en/articles/8871123-user-groups"}
          >
            Read more.
          </StyledLink>
        </TabSubtitle>
        <div
          style={{
            marginLeft: "auto",
            position: "relative",
          }}
        >
          <Tooltip
            text="Organisation admin can add members to the group"
            disabled={isAdminInOrg}
          >
            <Button
              text="Add members"
              buttonType="secondary"
              onClick={() => setShowUserSearch((cur) => !cur)}
              disabled={addInProgress > 0 || removeInProgress || !isAdminInOrg}
            />
          </Tooltip>
          {showUserSearch && (
            <div
              style={{
                position: "absolute",
                right: "0.8rem",
              }}
            >
              <GroupMemberModal members={members} onSave={onSaveMultiple} />
            </div>
          )}
        </div>
      </Row>
      <Row
        style={{
          gap: 0,
        }}
      >
        <TableHeader
          style={{
            flex: "0 0 40%",
          }}
        >
          Members
        </TableHeader>
      </Row>
      <ContentTableColumn>
        {members.map((m) => {
          const user = users.find((u) => u.user_id === m.user_id);
          if (!user) return null;
          return (
            <ContentTableRow
              key={m.user_id}
              style={{
                justifyContent: "space-between",
              }}
            >
              <UserInfoOrganisationWithMemberLink userId={m.user_id} />
              {isAdminInOrg && (
                <Tooltip text="Remove access">
                  <IconBtn
                    disabled={removeInProgress}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (
                        await showConfirm({
                          title: "Remove user from group",
                          message: `Are you sure you want to remove the user from the group?`,
                          confirmButtonText: "Remove",
                        })
                      ) {
                        onRemove(m.user_id);
                      }
                    }}
                    size={"1.4rem"}
                    style={{
                      opacity: 1,
                    }}
                  >
                    <RemoveIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </ContentTableRow>
          );
        })}
        {Array.from(
          {
            length: addInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "0.8rem",
              }}
            />
          ),
        )}
      </ContentTableColumn>
    </Column>
  );
}
