import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { substationsFamily } from "state/jotai/substation";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";

export const substationTypeInvalidWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubstationTypeInvalid>
        | undefined
      >
    >(async (get) => {
      const substationTypes = await get(allSubstationsForProjectAtom);
      if (!substationTypes) return;
      const substations = await get(substationsFamily({ branchId }));
      const invalids = substations.filter(
        (t) => !substationTypes.has(t.properties.substationTypeId ?? ""),
      );

      if (invalids.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.SubstationTypeInvalid,
        featureIds: invalids.map((t) => t.id),
      };
    }),
);
