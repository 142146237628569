import {
  UserNodeAccess,
  _UserNodeAccess,
} from "./../components/Organisation/Groups/types";
import {
  LoggedInUser,
  UserAccessRole,
  UserMetaInfoUpdate,
  _UserNodesAccess,
} from "./../types/user";
import { _CustomerPersona } from "../types/customer";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
  presignedUpload,
} from "./utils";
import { z } from "zod";

const _UserNotificationSettings = z.object({
  email_on_snapshot_created: z.boolean().optional(),
  email_on_branch_created: z.boolean().optional(),
  email_on_project_created: z.boolean().optional(),
  email_on_mention: z.boolean().optional(),
  email_on_comment_in_followed_thread: z.boolean().optional(),
  email_on_added_to_node: z.boolean().optional(),
});
export type UserNotificationSettings = z.infer<
  typeof _UserNotificationSettings
>;

const _NotificationSettingsObject = z.object({
  notification_settings: _UserNotificationSettings,
});

export type UserThumbnailSize = 32 | 64 | 128 | 256;

export const makeUserPictureUrl = async (
  file: string | Blob,
): Promise<{ url: string }> => {
  const { fileName } = await presignedUpload("/api/user/picture", file);

  return {
    url: `https://vind-ai-user-profile-picture.s3.amazonaws.com/${fileName}`,
  };
};

export const makeThumbnailUrl = (
  user: Pick<LoggedInUser, "picture">,
  size?: UserThumbnailSize,
): undefined | string => {
  if (user.picture === undefined) return undefined;
  let url = user.picture;
  if (size && url.includes("vind-ai-user-profile-picture"))
    url += `-${size}thumb.jpeg`;
  return url;
};

export function fetchUserAllNodesAccess() {
  return fetchSchemaWithToken(_UserNodesAccess, `/api/user/access`, {
    method: "get",
    headers: {},
  });
}

export function putUserMetaInfo(meta: UserMetaInfoUpdate) {
  return fetchEnhancerWithToken(`/api/user/metadata`, {
    method: "put",
    body: JSON.stringify(meta),
  });
}

export function updateUserNotificationSettings(
  settings: Partial<UserNotificationSettings>,
) {
  const obj = { notification_settings: settings };
  return fetchSchemaWithToken(
    _NotificationSettingsObject,
    `/api/user/notification_settings`,
    {
      method: "put",
      body: JSON.stringify(obj),
      headers: {},
    },
  );
}

export function getUserNotificationSettings() {
  return fetchSchemaWithToken(
    _UserNotificationSettings,
    `/api/user/notification_settings`,
    {
      method: "get",
      headers: {},
    },
  );
}

export async function leaveNode(nodeId: string) {
  const res = await fetchEnhancerWithToken(`/api/user/node/${nodeId}/leave`, {
    method: "delete",
    headers: {},
  });
  if (![200, 204].includes(res.status))
    throw Error("failed to remove user access");
}

export async function addOrUpdateUserNodeAccess(
  organisationId: string,
  userId: string,
  nodeId: string,
  role: UserAccessRole,
): Promise<UserNodeAccess> {
  return fetchSchemaWithToken(
    _UserNodeAccess,
    `/api/user/${userId}/organisation/${organisationId}/node/${nodeId}`,
    {
      method: "put",
      headers: {},
      body: JSON.stringify({ role }),
    },
  );
}

export async function deleteUserNodeAccess(
  organisationId: string,
  userId: string,
  nodeId: string,
) {
  const res = await fetchEnhancerWithToken(
    `/api/user/${userId}/organisation/${organisationId}/node/${nodeId}`,
    {
      method: "delete",
      headers: {},
    },
  );
  if (![200, 204].includes(res.status))
    throw Error("failed to remove user access");
}
