import { typography } from "../../../styles/typography";
import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { colors } from "../../../styles/colors";
import { Column } from "../../General/Layout";
import { IconContainer } from "./SettingsMenuItem";
import { spacing2, spacing4 } from "styles/space";

export const Modal = styled(StandardBox)`
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 90vw;
  max-width: 140rem;
  height: 85vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const ExpandArrowWrapper = styled.div<{
  open: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0.6rem 0.6rem;
  cursor: pointer;
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

export const HeaderContainer = styled.div`
  height: 5.2rem;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0 1.6rem;
`;

export const ItemContainer = styled.div<{
  selected: boolean;
  expanded: boolean;
}>`
  ${typography.sub2}
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem 1.2rem;
  min-width: 3.6rem;
  max-height: 3.6rem;
  box-sizing: border-box;
  border-radius: 0.4rem;

  background-color: ${(p) =>
    p.selected ? colors.surfaceSelectedLight : "inherit"};

  > svg {
    width: 1.4rem;
    min-width: 1.4rem;
    height: 1.4rem;
    min-height: 1.4rem;
  }

  ${({ selected }) =>
    selected &&
    `
     ${IconContainer} {
        visibility: visible !important;
        transition: all 0.1s linear;
      }
   `}

  &:hover {
    background-color: ${(p) => (p.selected ? colors.selected : colors.hover)};

    ${IconContainer} {
      visibility: visible;
      transition: all 0.1s linear;
    }
  }

  transition: all 0.1s linear;
`;

export const FirstLevelItemContainer = styled(ItemContainer)`
  width: 100%;
  ${typography.body}
  background-color: ${(p) =>
    p.selected ? colors.surfaceSelectedLight : "inherit"};
  border-radius: 0.4rem;

  svg {
    path[stroke] {
      stroke: ${({ selected }) =>
        selected ? colors.textSelected : colors.textPrimary};
    }
    path:not([stroke]) {
      fill: ${({ selected }) =>
        selected ? colors.textSelected : colors.textPrimary};
    }
  }

  > p {
    color: ${(p) => (p.selected ? colors.textSelected : colors.textPrimary)};
  }

  &:hover {
    background-color: ${(p) =>
      p.selected ? colors.surfaceSelectedHover : colors.surfaceHover};
    div {
      opacity: 1 !important;
      transition: all 0.1s linear;
    }
  }
`;

export const ItemText = styled.p<{ expanded: boolean; selected?: boolean }>`
  color: ${(p) => (p.selected ? colors.textSelected : colors.textPrimary)};
  font-weight: ${(p) => (p.selected ? 600 : 400)};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  opacity: ${(p) => (p.expanded ? 1 : 0)};
  transition: all 0.1s linear;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 0;
`;

export const ColumnSection = styled(Column)`
  padding-top: 1.6rem;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  ${typography.sub1};
  color: ${colors.textPrimary};
  gap: 0.8rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
  width: 100%;
  padding: 2.4rem 0rem 1.6rem 0rem;
`;

export const HorizontalLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${colors.grey200};
  margin-left: ${spacing2};
`;

export const SecondaryText = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

export const SubMenuTitle = styled(ItemText)<{
  expanded: boolean;
}>`
  ${typography.sub2}
  color: ${colors.textPrimary};
  font-weight: 600;

  opacity: ${(p) => (p.expanded ? 1 : 0)};
  transition: width 0.1s linear;
`;

export const NoItemsWrapper = styled.div`
  ${typography.caption}
  color: ${colors.textDisabled};
  padding: 0 1.6rem;
`;

export const SubMenuColumn = styled(Column)<{
  opagueness: number;
  expanded: boolean;
  isFirstLevel?: boolean;
  wide?: boolean;
  widthVariable?: string;
}>`
  width: ${(p) =>
    p.expanded
      ? p.isFirstLevel && !p.wide
        ? "16rem"
        : `var(${p.widthVariable}, 23rem)`
      : "7rem"};
  min-width: ${(p) =>
    p.expanded ? (p.isFirstLevel && !p.wide ? "18rem" : "23rem") : "7rem"};
  max-width: 50rem;
  height: 100%;
  position: relative;
  background-color: ${(p) =>
    p.isFirstLevel ? colors.surfaceSubtle : colors.surfacePrimary};
  box-sizing: border-box;
  border-right: 1px solid ${colors.borderSubtle};
  padding: ${(p) => (p.isFirstLevel ? "1.2rem" : "2.4rem")} 1.6rem 0;
  transition: width 0.1s linear;
  gap: ${(p) => (p.isFirstLevel ? "0.8rem" : "1.2rem")};
`;

export const ContentWrapper = styled.div`
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const SettingButton = styled.div`
  display: flex;
  gap: 1.6rem;
  z-index: 1;
`;

export const Text = styled.p`
  ${typography.caption}
  margin: 0;
`;

export const OpenOrCollapseContainer = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  button {
    ${(p) =>
      p.collapsed
        ? `
    background-color: white;
    border: 1px solid ${colors.inputOutline};
    `
        : "background-color: transparent;"}
  }
  div {
    box-shadow: none;
  }
`;
