import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import * as turf from "@turf/turf";
import { getCoordinates } from "../../utils/geojson/validate";
import { wgs84ToPsuedoMercator } from "../../utils/proj4";
import { ProjectFeature } from "../../types/feature";
import {
  isAnchor,
  isCable,
  isCableCorridor,
  isDefined,
  isExportCable,
  isLineStringGeometry,
  isMooringLine,
  isPark,
  isPointFeature,
  isPolygonFeature,
  isSubstation,
} from "../../utils/predicates";
import { DEFAULT_CANVAS_LAYER_COLOR } from "../../constants/canvas";
import { fastMax, fastMin } from "../../utils/utils";
import { colors } from "../../styles/colors";
import { multiFeatureToFeatures } from "../../utils/geojson/utils";

const drawArc = (
  ctx: CanvasRenderingContext2D,
  normalizedCoords: number[][],
  radius: number,
) => {
  normalizedCoords.forEach((c) => {
    ctx.beginPath();
    ctx.arc(c[0], c[1], radius, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();
  });
};

const FeatureCanvas = styled.canvas`
  width: 2.4rem;
  height: 2.4rem;
`;

const useFeatureToCanvas = (
  features: ProjectFeature[],
  canvasRef: React.RefObject<HTMLCanvasElement>,
  fillPolygons: boolean,
  fillParks: boolean,
) => {
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const singleFeatures: ProjectFeature[] = features
      .flatMap((f) => multiFeatureToFeatures(f))
      .sort((a, b) => {
        const aIsPoint = isPointFeature(a);
        const bIsPoint = isPointFeature(b);
        if (aIsPoint && !bIsPoint) return -1;
        if (!aIsPoint && bIsPoint) return 1;
        if (aIsPoint && bIsPoint) return 0;
        return turf.area(a) - turf.area(b);
      })
      .reverse(); //??

    const featureCoordsPsuedoMercator = singleFeatures
      .filter(
        (f) => f.geometry.coordinates && f.geometry.coordinates.length !== 0,
      )
      .map((f) => ({
        feature: f,
        coords: getCoordinates(f.geometry.coordinates),
      }))
      .filter((c) => isDefined(c.coords) && c.coords !== null)
      .map((c) => ({
        ...c,
        coords: c.coords!.map((c) => wgs84ToPsuedoMercator(c.slice(0, 2))), // safety: checked against !== null above.
      }));

    const Xs = featureCoordsPsuedoMercator.flatMap((c) =>
      c.coords.map((c) => c[0]),
    );
    const Ys = featureCoordsPsuedoMercator.flatMap((c) =>
      c.coords.map((c) => c[1]),
    );

    const minX = fastMin(Xs);
    const maxX = fastMax(Xs) - minX;

    const minY = fastMin(Ys);
    const maxY = fastMax(Ys) - minY;

    const widthHeightRatio = maxX / maxY;

    featureCoordsPsuedoMercator.forEach(({ coords, feature }) => {
      const xOffset =
        (canvas.width / 2) *
        (widthHeightRatio < 1 ? widthHeightRatio - 1.0 : 0.0);
      const yOffset =
        (canvas.height / 2) *
        (1 / widthHeightRatio < 1 ? 1 / widthHeightRatio - 1.0 : 0.0);

      const xStretch = widthHeightRatio < 1 ? widthHeightRatio : 1.0;
      const yStretch = 1 / widthHeightRatio < 1 ? 1 / widthHeightRatio : 1.0;

      // to range [5,95] (avoid any cut off of the line that happens in the range [0,100])
      const normalizedCoords: number[][] = coords.map((c) => [
        ((c[0] - minX) / maxX) * canvas.width * xStretch - xOffset,
        canvas.height -
          ((c[1] - minY) / maxY) * canvas.height * yStretch +
          yOffset,
      ]);

      if (isAnchor(feature)) {
        ctx.fillStyle = colors.anchor;
        ctx.strokeStyle = colors.anchor;
        drawArc(ctx, normalizedCoords, 4);
      } else if (isSubstation(feature)) {
        ctx.fillStyle = colors.substation;
        ctx.strokeStyle = colors.substation;
        drawArc(ctx, normalizedCoords, 4);
      } else if (isPointFeature(feature)) {
        ctx.strokeStyle = "#000000";
        ctx.fillStyle = "#000000";
        drawArc(ctx, normalizedCoords, 4);
      } else {
        if (isMooringLine(feature)) {
          ctx.lineWidth = 4;
          ctx.strokeStyle = colors.mooringLine;
        } else if (isCable(feature)) {
          ctx.lineWidth = 8;
          ctx.strokeStyle = "#00ff00";
        } else if (isExportCable(feature)) {
          ctx.lineWidth = 8;
          ctx.strokeStyle = colors.exportCableOffshore;
        } else if (isCableCorridor(feature)) {
          ctx.fillStyle = colors.cableCorridor.concat("cc");
          ctx.strokeStyle = DEFAULT_CANVAS_LAYER_COLOR;
          ctx.lineWidth = 8;
        } else {
          ctx.fillStyle = (
            String(feature.properties?.["color"]) || DEFAULT_CANVAS_LAYER_COLOR
          ).concat("cc");
          ctx.lineWidth = 8;

          if (isPark(feature)) {
            ctx.strokeStyle = "#000000";
          } else {
            ctx.strokeStyle =
              String(feature.properties?.["color"]) ||
              DEFAULT_CANVAS_LAYER_COLOR;
          }
        }
        ctx.beginPath();
        ctx.moveTo(normalizedCoords[0][0], normalizedCoords[0][1]);
        normalizedCoords.slice(1).forEach((c) => ctx.lineTo(c[0], c[1]));
        if (!isLineStringGeometry(feature.geometry)) {
          ctx.closePath();
        }
        ctx.stroke();
        const featureIsPark = isPark(feature);
        if (
          (fillPolygons && isPolygonFeature(feature) && !featureIsPark) ||
          (fillParks && featureIsPark)
        ) {
          ctx.fill();
        }
      }
    });
  }, [features, canvasRef, fillPolygons, fillParks]);
};

const FeatureToCanvas = ({
  features,
  fillPolygons,
  fillParks,
  style,
  resolution,
}: {
  features: ProjectFeature[];
  resolution?: string;
  fillPolygons: boolean;
  fillParks: boolean;
  style?: React.CSSProperties;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useFeatureToCanvas(features, canvasRef, fillPolygons, fillParks);
  return (
    <FeatureCanvas
      width={resolution}
      height={resolution}
      ref={canvasRef}
      style={style}
    />
  );
};

export default FeatureToCanvas;
