import Cabling from "@icons/24/Cabling.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { useAtomValue } from "jotai";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { Row } from "components/General/Layout";

const CableSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  if (!localConfig) return null;
  const { activities } = localConfig.ti.cables;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Pull in cable to turbine</SubHeader>
        <SubHeader>Pull in cable to substation</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.cables.activities.pullInCableToTurbine`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.pullInCableToTurbine}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              cables: {
                ...localConfig.ti.cables,
                activities: {
                  ...activities,
                  pullInCableToTurbine: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.cables.activities.pullInCableToSubstation`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.pullInCableToSubstation}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              cables: {
                ...localConfig.ti.cables,
                activities: {
                  ...activities,
                  pullInCableToSubstation: Math.ceil(duration),
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const CableActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  return (
    <InstallationCategoryEntry
      title="Inter array cables"
      icon={<Cabling />}
      defaultOpen={true}
    >
      <CableSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
