import { colors } from "styles/colors";
import { FilterType } from "../layer-filter-state";
import styled from "styled-components";

export const Pill = styled.div<{
  filterType: FilterType;
}>`
  display: flex;
  align-items: center;
  padding: 0.2rem 1.5rem;
  gap: 1rem;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  svg {
    path {
      stroke: white;
    }
  }

  ${({ filterType }) => {
    if (filterType === "source") {
      return `
        background-color: ${colors.seagreen600};
      `;
    }

    if (filterType === "tags") {
      return `
        background-color: ${colors.purple600};
      `;
    }

    if (filterType === "country") {
      return `
        background-color: ${colors.magenta700};   
        text-transform: capitalize;  
      `;
    }

    if (filterType === "sourceTypes") {
      return `
        background-color: ${colors.indigo600};
      `;
    }

    if (filterType === "overlappingGeometry") {
      return `
        background-color: ${colors.fuschia700};
      `;
    }
  }}
`;
