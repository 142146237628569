import { amountAddId, amountId } from "finance/amounts/amountIds";
import { Amount, AmountUnit } from "types/financial";

import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { getBranchId, getParkId, getTurbineCapacity } from "analysis/inputs";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { mooringLineStatsInParkSelector } from "state/jotai/mooringLineType";
import { CostType, isOperationsCost } from "services/costService";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { vesselTypesFamily } from "state/jotai/vesselType";

export const mooringAmountsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Amount[]>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const anchors = await get(anchorsInParkFamily({ parkId, branchId }));
    const mooringLines = await get(
      mooringLinesInParkFamily({ parkId, branchId }),
    );
    const {
      mooringLineTypes,
      lengthPerMooringLineType,
      clumpWeightTonnes,
      buoyTonnes,
    } = await get(mooringLineStatsInParkSelector({ branchId, parkId }));

    const mooringLengthAmount = mooringLineTypes.map((line) => {
      const amount = lengthPerMooringLineType[line.id] ?? 0;
      const unit = AmountUnit.km;
      const category = CostType.Mooring;

      return amountAddId(
        {
          unit,
          amount,
          category,
        },
        line.id,
      );
    });

    const anchorAmount = amountAddId(
      {
        unit: AmountUnit.unit,
        amount: anchors.length,
        category: CostType.Mooring,
      },
      "anchors",
    );

    const mooringLinesAmount = amountAddId({
      unit: AmountUnit.line,
      amount: mooringLines.length,
      category: CostType.Mooring,
    });

    const mooringLinesKmAmount = amountAddId({
      unit: AmountUnit.km,
      amount: mooringLineTypes
        .map(({ id }) => lengthPerMooringLineType[id] ?? 0)
        .reduce((acc, n) => acc + n, 0),
      category: CostType.Mooring,
    });

    const clumpWeightAmount = amountAddId(
      {
        unit: AmountUnit.t,
        amount: clumpWeightTonnes,
        category: CostType.Mooring,
      },
      "clump_weights",
    );

    const buoyAmount = amountAddId(
      {
        unit: AmountUnit.t,
        amount: buoyTonnes,
        category: CostType.Mooring,
      },
      "buoys",
    );

    const capacity = await get(getTurbineCapacity(id));

    const perMwAmount = amountAddId({
      unit: AmountUnit.MW,
      amount: mooringLines.length > 0 ? capacity ?? 0 : 0,
      category: CostType.Mooring,
    });

    const fixedAmount = {
      id: amountId({
        unit: AmountUnit.fixed,
        category: CostType.Mooring,
      }),
      unit: AmountUnit.fixed,
      amount: 1,
      category: CostType.Mooring,
    };

    let perDayAmount: Amount[] = [];

    const configuration = await get(getConfiguration(id));
    if (isOperationsCost(configuration.capex.installation.mooring)) {
      const installationTime =
        anchors.length > 0
          ? await get(
              getTotalInstallationTime({ id, type: InstallationType.Mooring }),
            )
          : 0;
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.mooring;

      const vesselTypes = await get(vesselTypesFamily(undefined));

      const mobilizationTime =
        vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

      perDayAmount.push({
        id: amountId({
          unit: AmountUnit.day,
          category: CostType.Mooring,
          featureTypeId: installationVessel.vesselId,
        }),
        unit: AmountUnit.day,
        amount:
          anchors.length > 0 ? (installationTime ?? 0) + mobilizationTime : 0,
        category: CostType.Mooring,
      });
    }

    return [
      anchorAmount,
      ...mooringLengthAmount,
      mooringLinesAmount,
      mooringLinesKmAmount,
      perMwAmount,
      clumpWeightAmount,
      buoyAmount,
      fixedAmount,
      ...perDayAmount,
    ];
  }),
);
