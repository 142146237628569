import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  parkIdAtom,
  parkIdAtomDef2,
  projectIdAtomDef2,
} from "state/pathParams";
import { Label, StyledLabel } from "components/General/Form";
import {
  InputDimensioned,
  StyledDimensionedInput,
} from "components/General/Input";
import Button from "components/General/Button";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import styled from "styled-components";
import { RadioGroup } from "components/General/Radio";
import { Column } from "components/General/Layout";
import React, { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import { colors } from "styles/colors";
import { mapAtom } from "state/map";
import { MapboxRaster } from "components/MapFeatures/Geotiff";
import { RESET, loadable } from "jotai/utils";
import { Job, _Job, _Response } from "./types";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import DownloadIcon from "@icons/24/Download.svg?react";
import ShadowFlickerIcon from "@icons/24/ShadowFlicker.svg";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import {
  allJobsAtom,
  colorBucketsAtom,
  configAtom,
  currentJobsAtom,
  fetchJobsAtom,
  flickerOpacity,
  totalShadowProgress,
  triggerPostShadowAnalysis,
} from "./state";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { dateToDateTime, downloadAppFile, range } from "utils/utils";
import { Tag } from "components/General/Tag";
import { Comp } from "types/utils";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import RulerIcon from "@icons/24/Ruler.svg";
import { borderRadius } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { fetchRaster } from "./fetchRaster";
import { ColorSquare } from "components/General/ColorSquare";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { turbinesInParkWithIllegalTypeFamily } from "state/jotai/turbine";
import { scream } from "utils/sentry";
import { Slider } from "components/General/Slider";
import { HelpLink, articleMap } from "components/HelpTooltip/HelpTooltip";
import { ProjectFeature } from "types/feature";
import { MultiPolygon, Polygon } from "geojson";
import SensorStats, {
  SensorStatsType,
} from "components/RightSide/InfoModal/ProjectFeatureInfoModal/SensorStats";
import { geotiffToRaster } from "utils/gdal";
import { sensorPointsFamily } from "state/jotai/sensorPoint";
import { Color } from "lib/colors";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { configurationMenuActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { geotiffShadowFlickerLayerId } from "components/Mapbox/constants";

const DistanceTag = ({ j }: { j: Job }) => (
  <Tag
    icon={
      <IconREMSize height={1.4} width={1.4}>
        <RulerIcon />
      </IconREMSize>
    }
    text={`${j.distance_cutoff ?? 1500}m`}
    tooltip="Maximal shadow distance"
  />
);

const _SettingsForm = styled.form`
  display: flex;
  flex-direction: column;

  ${StyledLabel} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;

    svg {
      height: 1.2rem;
      width: 1.2rem;
    }

    ${StyledDimensionedInput} {
      max-width: 10rem;
    }
  }

  ${StyledLabel}:not(:first-child) {
    margin-top: 1.6rem;
  }

  ${RadioGroup} {
    margin-top: 0.8rem;
    justify-content: space-between;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.6rem;
`;

const SettingsForm = () => {
  const [settings, setSettings] = useAtom(configAtom);

  return (
    <_SettingsForm onSubmit={(e) => e.preventDefault()}>
      <Label>
        <RulerIcon />
        <p>Maximal shadow length</p>
        <InputDimensioned
          compact
          unit="m"
          value={settings.maxShadowLength}
          placeholder="1500"
          max={2500}
          validate={(n) => 0 < n && n <= 2500}
          validationMessage="Distance can be at most 2500m"
          onChange={(n) => {
            setSettings({
              ...settings,
              maxShadowLength: n,
            });
          }}
        />
      </Label>
    </_SettingsForm>
  );
};

const selectedJobIdAtom = atom<string | undefined>(undefined);
const selectedJob2Atom = atom(async (get) => {
  const id = get(selectedJobIdAtom);
  if (!id) return;
  const jobs = await get(currentJobsAtom);

  const selected = jobs.find((j) => j.job === id);
  if (!selected) return;
  if (!selected.url) return;
  return fetchRaster(selected.url, get);
});
const selectedJobRaster = atom(async (get) => {
  const job = await get(selectedJob2Atom);
  if (!job) return;
  const shadowRaster = await geotiffToRaster(
    new File([job.blob], "shadowflicker.tif"),
  );
  return shadowRaster;
});

const _JobItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  flex-wrap: wrap;
  border-radius: ${borderRadius.small};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex: 1;

    ${typography.sub3};
    color: ${colors.textSecondary};
  }

  &:nth-child(2n + 2) {
    background: ${colors.surfaceSecondary};
  }

  :hover {
    background: ${colors.surfaceHover};
  }

  &[data-selected="true"] {
    background: ${colors.surfaceSelectedLight};
  }
  &[data-selected="false"] {
    .taglist {
      height: 0;
    }
  }

  .taglist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    overflow: hidden;
  }
`;

const JobItem = ({
  job,
  selected,
  parkName,
  ...props
}: Comp<
  "li",
  {
    job: Job;
    selected: boolean;
    parkName?: string;
  }
>) => {
  // `created_at` is UTC, but we want to show the local time here.
  const localDate = useMemo(() => {
    const y = job.created_at.getFullYear();
    const m = job.created_at.getMonth();
    const d = job.created_at.getDate();
    const hh = job.created_at.getHours();
    const mm = job.created_at.getMinutes();
    const ss = job.created_at.getSeconds();
    return new Date(Date.UTC(y, m, d, hh, mm, ss));
  }, [job.created_at]);

  const progress = useAtomValue(totalShadowProgress(job.job));

  if (job.status === "failed") {
    return (
      <_JobItem {...props}>
        <span>Failed</span>
      </_JobItem>
    );
  }

  return (
    <_JobItem {...props} data-selected={selected}>
      <div>
        <span>{dateToDateTime(localDate)}</span>
        <DistanceTag j={job} />
        {(job.status === "running" || job.status === "started") && (
          <SkeletonText
            style={{ flex: 1, height: "2rem" }}
            text={`${(progress * 100).toFixed()}%`}
          />
        )}
      </div>
      {selected && (
        <div className="taglist">
          {job.url && (
            <Button
              style={{ margin: "0.5rem 0" }}
              size="tiny"
              text="Download"
              icon={<DownloadIcon />}
              buttonType="secondary"
              onClick={() => {
                if (!job.url)
                  throw new Error("Tried to download shadow job without url");
                downloadAppFile(
                  job.url,
                  `${parkName ?? "park"}_shadowflicker.tif`,
                );
              }}
            />
          )}
        </div>
      )}
    </_JobItem>
  );
};

const _JobList = styled.ul`
  padding: 0;
  list-style: none;
  li {
    padding: 0.4rem 1rem;
    &:hover {
      cursor: pointer;
    }
    margin-top: 2px;
  }
`;
const JobList = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon | MultiPolygon>;
}) => {
  const jobs = useAtomValue(currentJobsAtom);
  const [selectedId, setSelectedId] = useAtom(selectedJobIdAtom);

  if (jobs.length === 0)
    return (
      <EmptyState
        title="No previous analyses"
        icon={
          <IconREMSize height={2} width={2} iconColor={colors.iconNegative}>
            <ShadowFlickerIcon />
          </IconREMSize>
        }
        style={{
          boxSizing: "border-box",
        }}
      />
    );

  return (
    <Column>
      <_JobList>
        {jobs.map((j) => (
          <JobItem
            key={j.job}
            selected={selectedId === j.job}
            job={j}
            data-status={j.status}
            onClick={() => setSelectedId(replaceOrUndefined(j.job))}
            parkName={canvasFeature.properties.name}
          />
        ))}
      </_JobList>
    </Column>
  );
};

const JobListPlaceholder = () => (
  <_JobList>
    {range(0, 3).map((i) => (
      <li key={i}>
        <SkeletonBlock style={{ height: "1.4rem" }} />
      </li>
    ))}
  </_JobList>
);

const SelectedRaster = () => {
  const map = useAtomValue(mapAtom);
  const geotiff = useAtomValue(loadable(selectedJob2Atom));
  const opacity = useAtomValue(flickerOpacity);

  if (!geotiff || !map) return null;

  if (geotiff.state !== "hasData" || !geotiff.data) {
    return null;
  }

  return (
    <MapboxRaster
      layerId={geotiffShadowFlickerLayerId}
      map={map}
      imageUrl={geotiff.data.dataURL}
      bbox={geotiff.data.bbox}
      opacity={opacity}
    />
  );
};

const BucketList_ = styled.div`
  display: grid;
  grid-template-columns: min-content max-content 2rem max-content auto;

  > :nth-child(10n-9),
  > :nth-child(10n-8),
  > :nth-child(10n-7),
  > :nth-child(10n-6),
  > :nth-child(10n-5) {
    background: ${colors.surfaceSecondary};
  }

  > * {
    padding: 0.6rem 0.8rem;
  }
  ${typography.caption}

  > span {
    justify-content: end;
    display: flex;
    align-items: center;
    padding: 0.4rem 0.4rem;
    gap: 0.4rem;
  }
  span.line {
    color: ${colors.textDisabled};
  }

  ${ColorSquare} {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
const Style = () => {
  const buckets = useAtomValue(colorBucketsAtom);
  const [opacity, setOpacity] = useAtom(flickerOpacity);
  return (
    <>
      <Label
        left
        style={{
          alignItems: "start",
          marginRight: "1rem",
        }}
      >
        <p>Opacity:</p>
        <Slider
          min={0}
          max={1}
          step={0.01}
          value={opacity}
          onChange={(e) => setOpacity(e)}
          label
          renderLabel={(n) => `${(n * 100).toFixed()}%`}
        />
      </Label>

      <span style={typography.sub3}>Legend</span>

      <BucketList_>
        {buckets.buckets().map((b, i) => {
          const left = isFinite(b.from) ? `${b.from} hours` : "0";
          const right = isFinite(b.to) ? `${b.to} hours` : "";
          const color = b.color.clone();
          if (i !== 0) {
            color.a = opacity * 255;
          }
          return (
            <Fragment key={i}>
              <div>
                <ColorSquare $color={color} />
              </div>
              <span>{left}</span>
              <span className="line">&mdash;</span>
              <span>{right}</span>
              <span />
            </Fragment>
          );
        })}
      </BucketList_>
    </>
  );
};

const ErrorAlert = () => {
  const projectId = useAtomValue(projectIdAtomDef2);
  const parkId = useAtomValue(parkIdAtomDef2);
  const setCurrent = useSetAtom(
    allJobsAtom({
      projectId,
      parkId,
    }),
  );

  useEffect(() => {
    setCurrent(RESET);
  }, [setCurrent]);

  return <SimpleAlert text="Shadow flicker failed" />;
};

const ShadowFlickerSensorStats = ErrorBoundaryWrapper(
  () => {
    const raster = useAtomValue(selectedJobRaster);
    const sensorPoints = useAtomValue(
      sensorPointsFamily({
        branchId: undefined,
      }),
    );
    const buckets = useAtomValue(colorBucketsAtom);

    const sensorStats = useMemo(() => {
      if (!raster) return [];

      return Object.values(
        sensorPoints.reduce(
          (acc, p) => {
            if (
              !raster.contains(
                p.geometry.coordinates[0],
                p.geometry.coordinates[1],
              )
            )
              return acc;

            const value = raster.latLngToValue(
              p.geometry.coordinates[0],
              p.geometry.coordinates[1],
              0,
            );

            if (value === 0) return acc;

            const bucketIndex = buckets
              .buckets()
              .findIndex((c) => value >= c.from && value < c.to);

            if (bucketIndex === -1) return acc;

            const bucket = buckets.buckets()[bucketIndex];

            const uniqueName = `${bucket?.from}-${bucket?.to}`;
            if (!(uniqueName in acc)) {
              acc[uniqueName] = {
                name:
                  bucket?.to === Infinity
                    ? `${bucket?.from}+ h`
                    : `${Math.max(0, bucket?.from)}h to ${bucket?.to}h`,
                sensors: [],
                color: bucket?.color ?? Color.Transparent(),
                index: bucketIndex,
              };
            }
            acc[uniqueName].sensors.push(p);
            return acc;
          },
          {} as Record<string, SensorStatsType & { index: number }>,
        ),
      ).sort((a, b) => a.index - b.index);
    }, [raster, sensorPoints, buckets]);

    return <SensorStats sensorStats={sensorStats} />;
  },
  ErrorAlert,
  ScreamOnError,
);

const Inner = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon | MultiPolygon>;
}) => {
  const triggerPOST = useSetAtom(triggerPostShadowAnalysis);
  const parkId = useAtomValue(parkIdAtom);

  const setAllJobs = useSetAtom(currentJobsAtom);
  const fetchJobs = useSetAtom(fetchJobsAtom);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchJobs().then(setAllJobs);
  }, [fetchJobs, setAllJobs, parkId]);

  return (
    <>
      <Column style={{ overflow: "auto" }}>
        <p>See the regions most affected by turbine shadows in a year.</p>

        <SubtitleWithLine text="Settings" />
        <SettingsForm />

        <SubtitleWithLine text="Style" />
        <Column>
          <Style />
        </Column>

        <React.Suspense fallback={<JobListPlaceholder />}>
          <ShadowFlickerSensorStats />
        </React.Suspense>

        <SelectedRaster />
        <SubtitleWithLine text="Analyses" />
        <SimpleAlert
          type="info"
          text="Analyses are stored for 30 days."
          style={{ background: "unset", padding: "0.8rem 1.6rem" }}
        />
        <Column>
          <Suspense fallback={<JobListPlaceholder />}>
            <JobList canvasFeature={canvasFeature} />
          </Suspense>
        </Column>
      </Column>
      <ButtonWrapper>
        <Button
          disabled={loading}
          text="Calculate"
          onClick={() => {
            setLoading(true);
            triggerPOST().finally(() => setLoading(false));
          }}
          style={{ alignSelf: "end" }}
        />
      </ButtonWrapper>
    </>
  );
};

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  // Likely error is that a turbine has an illegal turbine type.

  const parkId = useAtomValue(parkIdAtomDef2);
  const turbinesWithoutType = useAtomValue(
    turbinesInParkWithIllegalTypeFamily({ parkId, branchId: undefined }),
  );

  useEffect(() => {
    turbinesWithoutType; // when this change, check again.
    resetErrorBoundary();
  }, [resetErrorBoundary, turbinesWithoutType]);

  if (turbinesWithoutType.length > 0) {
    return (
      <SimpleAlert
        style={{ margin: "0 1rem" }}
        type="error"
        title="Turbines without type"
        text="Some turbines in the park don't have a valid turbine type.  This is required for shadow flicker analysis."
      />
    );
  }

  scream("Shadow flicker fallback", { error });
  throw error;
}

export const ShadowFlicker = ({
  canvasFeature,
  onClose,
}: {
  canvasFeature: ProjectFeature<Polygon | MultiPolygon>;
  onClose?: () => void;
}) => {
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);
  return (
    <MenuFrame
      title="Shadow flicker "
      onExit={onClose}
      style={{
        maxHeight: `calc(84vh - 7.5rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
      }}
      icon={<HelpLink article={articleMap.shadowFlicker} />}
    >
      <ErrorBoundary FallbackComponent={Fallback}>
        <Inner canvasFeature={canvasFeature} />
      </ErrorBoundary>
    </MenuFrame>
  );
};
