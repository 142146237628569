import { useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { useRefreshLibraryTurbines } from "components/ConfigurationModal/TurbineSettings/useRefreshCustomTurbines";
import { Mixpanel } from "mixpanel";
import {
  createNewOrgTurbine,
  deleteOrgTurbine,
  updateOrgTurbine,
} from "services/turbineAPIService";
import { TurbineType } from "types/turbines";
import { turbineIdentifierAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { organisationTurbineResourceState } from "../state";
import { useToast } from "hooks/useToast";
import { createSuccessMessage } from "components/Toast/ToastMessages";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DeleteTurbineModalType } from "components/NotificationSystem/ReplaceTurbine/DeleteTurbineModal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

const useOrgTurbineCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { success: showSuccess } = useToast();

  const refreshLibraryTurbines = useRefreshLibraryTurbines();

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        turbine,
        name,
        projectAccess,
      }: {
        turbine: TurbineType;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const newTurbine = {
        ...turbine,
        name: name ? name : `${turbine.name} copy`,
      };

      const fallback = await get(
        organisationTurbineResourceState({
          organisationId,
        }),
      );

      try {
        const res = await createNewOrgTurbine(
          organisationId,
          newTurbine,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationTurbineResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );
        refreshLibraryTurbines();
        set(turbineIdentifierAtom(organisationId), RESET);

        Mixpanel.track_old("Created org turbine", {
          turbineId: turbine.id,
          organisationId,
          projectAccess,
        });
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationTurbineResourceState({
            organisationId,
          }),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId, refreshLibraryTurbines],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      turbine: TurbineType,
      onlyUpdatingDescription: boolean = false,
    ) => {
      const fallback = await get(
        organisationTurbineResourceState({
          organisationId,
        }),
      );

      return updateOrgTurbine(organisationId, turbine)
        .then((res) => {
          if (res) {
            aset(
              get,
              set,
              organisationTurbineResourceState({
                organisationId,
              }),
              (cur) => cur.map((c) => (c.id === turbine.id ? res : c)),
            );
            if (!onlyUpdatingDescription) {
              refreshLibraryTurbines();
            }
            set(turbineIdentifierAtom(organisationId), RESET);
            Mixpanel.track_old("Update org turbine", {
              turbineId: turbine.id,
              organisationId,
            });
          }
        })
        .catch(() => {
          aset(
            get,
            set,
            organisationTurbineResourceState({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId, refreshLibraryTurbines],
  );

  const remove = useJotaiCallback(
    async (get, set, turbineId: string) => {
      const fallback = await get(
        organisationTurbineResourceState({
          organisationId,
        }),
      );

      const turbines = await get(
        organisationTurbineResourceState({
          organisationId,
        }),
      );

      const turbine = turbines.find((t) => t.id === turbineId);

      if (!turbine) {
        return;
      }

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.TURBINE,
          resourceId: turbineId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.TURBINE,
          turbineId,
        );
      }

      if (usage.length > 0) {
        setMidScreenModalTypeOpen({
          modalType: DeleteTurbineModalType,
          metadata: {
            turbineTypeId: turbineId,
            name: turbine.name,
            onConfirm: () => {
              deleteOrgTurbine(organisationId, turbineId)
                .then((res) => {
                  if (res) {
                    aset(
                      get,
                      set,
                      organisationTurbineResourceState({
                        organisationId,
                      }),
                      (cur) => [...cur].filter((c) => c.id !== turbineId),
                    );
                    showSuccess(createSuccessMessage("Turbine type deleted"), {
                      showCountdown: false,
                    });
                    refreshLibraryTurbines();
                    set(turbineIdentifierAtom(organisationId), RESET);

                    Mixpanel.track_old("Delete org turbine", {
                      turbineId,
                      organisationId,
                    });
                  }
                })
                .catch(() => {
                  aset(
                    get,
                    set,
                    organisationTurbineResourceState({
                      organisationId,
                    }),
                    () => fallback,
                  );
                });
            },
          },
        });
      } else {
        setMidScreenModalTypeOpen({
          modalType: DeleteTurbineModalType,
          metadata: {
            turbineTypeId: turbineId,
            name: turbine.name,
            noUsage: true,
            onConfirm: () => {
              deleteOrgTurbine(organisationId, turbineId)
                .then((res) => {
                  if (res) {
                    aset(
                      get,
                      set,
                      organisationTurbineResourceState({
                        organisationId,
                      }),
                      (cur) => [...cur].filter((c) => c.id !== turbineId),
                    );
                    showSuccess(createSuccessMessage("Turbine type deleted"), {
                      showCountdown: false,
                    });
                    refreshLibraryTurbines();
                    set(turbineIdentifierAtom(organisationId), RESET);

                    Mixpanel.track_old("Delete org turbine", {
                      turbineId,
                      organisationId,
                    });
                  }
                })
                .catch(() => {
                  aset(
                    get,
                    set,
                    organisationTurbineResourceState({
                      organisationId,
                    }),
                    () => fallback,
                  );
                });
            },
          },
        });
      }
    },
    [
      organisationId,
      refreshLibraryTurbines,
      showSuccess,
      setMidScreenModalTypeOpen,
    ],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        turbine,
        projectAccess,
        onSuccess,
      }: {
        turbine: TurbineType;
        projectAccess?: string[];
        onSuccess?: (newTurbine: TurbineType) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "turbine",
          defaultName: `${turbine.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const newTurbine = await create({
              turbine,
              name: name,
              projectAccess,
            });
            onSuccess?.(newTurbine);
            return newTurbine;
          },
        },
      });
    },
    [create, setMidScreenModalTypeOpen],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, turbine: TurbineType) => {
      aset(
        get,
        set,
        organisationTurbineResourceState({ organisationId }),
        (currentTurbines) => {
          const turbineIndex = currentTurbines.findIndex(
            (t) => t.id === turbine.id,
          );
          if (turbineIndex !== -1) {
            // Update existing turbine
            return currentTurbines.map((t, index) =>
              index === turbineIndex ? { ...t, ...turbine } : t,
            );
          } else {
            // Add new turbine
            return [...currentTurbines, turbine];
          }
        },
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, turbineId: string) => {
      aset(
        get,
        set,
        organisationTurbineResourceState({ organisationId }),
        (currentTurbines) => {
          return currentTurbines.filter((t) => t.id !== turbineId);
        },
      );
    },
    [organisationId],
  );

  return {
    create,
    remove,
    update,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgTurbineCrud;
