import {
  CableType,
  ExportSystemType,
  IAVoltageType,
} from "services/cableTypeService";
import { atomFamily } from "utils/jotai";
import { cablesInParkWithType } from "./cable";
import { DefaultMap } from "lib/DefaultMap";
import { atom } from "jotai";
import * as turf from "@turf/turf";
import { maxBy } from "utils/utils";
import { isDefined } from "utils/predicates";
import { exportCablesInParkFamily } from "./exportCable";
import { exportCableTypesFamily } from "./exportCableType";

/**
 * Get the most used IA voltage in the park by length.
 * Defaults to {@link IAVoltageType.kV66}.
 */
export const IAVoltageInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<IAVoltageType>>(async (get) => {
      const cables = await get(cablesInParkWithType({ parkId, branchId }));
      if (!cables.length) return IAVoltageType.kV66;
      const lengths = new DefaultMap<CableType, number>(() => 0);
      for (const [c, t] of cables)
        lengths.update(t, (n) => n + turf.length(c, { units: "kilometers" }));
      const [longest] = maxBy([...lengths.entries()], ([_, v]) => v)!; // Safety: cables is not empty.
      return longest.voltage;
    }),
);

export const getExportSystemTypeInParkAndBranchFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<ExportSystemType>>(async (get) => {
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const allExportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );
      const currentExportCableTypes = exportCables
        .map((c) => allExportCableTypes.get(c.properties.cableTypeId ?? ""))
        .filter(isDefined);

      let exportSystemType: ExportSystemType = ExportSystemType.HVAC;
      if (
        currentExportCableTypes.length > 0 &&
        currentExportCableTypes[0].exportCableType === ExportSystemType.HVDC
      )
        exportSystemType = ExportSystemType.HVDC;

      return exportSystemType;
    }),
);

export const getAllExportSystemTypesInParkAndBranchFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string }) =>
    atom<Promise<("HVAC" | "HVDC")[]>>(async (get) => {
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const allExportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );
      const currentExportCableTypes = exportCables
        .map((c) => allExportCableTypes.get(c.properties.cableTypeId ?? ""))
        .filter(isDefined);

      return [
        ...new Set(
          currentExportCableTypes
            .map((ct) => ct.exportCableType)
            .filter(isDefined),
        ).values(),
      ];
    }),
);
