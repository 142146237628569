import { typography } from "styles/typography";
import ProjectGlobeIcon from "@icons/24/ProjectGlobe.svg";
import { useLocalStorage } from "hooks/useBrowserStorage";
import { Column } from "components/General/Layout";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import NoItemsGeneric from "components/General/NoItemsGeneric";

export const NoProjectsInRootMessage_Member = () => {
  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>(
    "vind:expand-no-projects-message",
    true,
  );

  return (
    <ValidationAlert
      type="info"
      title="It looks like there are no shared projects here. You can:"
      _isExpanded={isExpanded}
      showTitleWhenCollapsed={false}
      onHeaderClick={setIsExpanded}
      renderDecription={() => (
        <Column
          style={{
            padding: "0 1.6rem 0.8rem 4.2rem",
          }}
        >
          <p style={typography.caption}>
            1. Create a <b>New project</b> and get started.
          </p>
          <p style={typography.caption}>
            2. Contact your Organisation <b>Admin</b> to join existing projects.
          </p>
        </Column>
      )}
    />
  );
};

export const NoProjectsInPersonalFolderMessage = () => {
  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>(
    "vind:expand-no-personal-projects-message",
    true,
  );

  return (
    <ValidationAlert
      type="info"
      title="Only you have access to your personal folder"
      _isExpanded={isExpanded}
      showTitleWhenCollapsed={false}
      onHeaderClick={setIsExpanded}
      renderDecription={() => (
        <Column
          style={{
            padding: "0 1.6rem 0.8rem 4.2rem",
          }}
        >
          <p style={typography.caption}>
            1. Create a <b>New personal project</b> and get started.
          </p>
          <p style={typography.caption}>
            2. Contact your Organisation <b>Admin</b> to join existing projects.
          </p>
        </Column>
      )}
    />
  );
};

export const NoProjectsInRootMessage_Guest = () => {
  return (
    <NoItemsGeneric
      Icon={<ProjectGlobeIcon />}
      style={{
        padding: "8rem",
      }}
      headerText="It looks like there are no projects here yet!"
      subText={
        <p>
          Contact your Organisation <b>Admin</b> to join existing projects.
        </p>
      }
    />
  );
};
