import { useState, useRef, useMemo } from "react";
import { Column } from "../../General/Layout";
import Button from "../../General/Button";
import AddIcon from "@icons/24/Add.svg";
import { IconREMSize, typography } from "styles/typography";
import { EditableText } from "components/General/EditableText";
import { colors } from "styles/colors";
import styled from "styled-components";
import StagesColorSelector from "./ColorSelector/StagesColorSelector";
import { DeleteButton, SettingsRowWithHover } from "./shared.style";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import { Stage } from "services/stagesService";
import TrashIcon from "@icons/24/Bin.svg";
import { EmptyStateDescription } from "./EmptyStateDescription";
import StagesIcon from "@icons/24/Stages.svg";
import { v4 as uuidv4 } from "uuid";
import { useClickOutside } from "hooks/useClickOutside";

interface StagesSettingsProps {
  isAdmin: boolean;
  stages: Stage[];
  setStages: (stages: Stage[]) => void;
  organisationId: string;
}

const ColorBox = styled.div<{ color: string }>`
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  background-color: ${({ color }) => color};
  text-align: center;
  cursor: pointer;
  align-content: center;
`;

const ColorBoxText = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  ${typography.sub2}
`;

export const stageBackgroundColorToTextColor = (color: string) => {
  switch (color) {
    case colors.grey200:
      return colors.textBrand;
    case colors.orange200:
      return colors.red900;
    case colors.yellow100:
      return colors.chartreuse900;
    case colors.chartreuse100:
      return colors.chartreuse900;
    case colors.seagreen100:
      return colors.seagreen900;
    case colors.purple100:
      return colors.purple900;
    case colors.magenta100:
      return colors.magenta900;
    default:
      return colors.textBrand;
  }
};

const DEFAULT_STAGE_COLOR = colors.grey200;

const StageColorBox = styled.div<{ color: string; isFocused: boolean }>`
  height: 2.4rem;
  padding-left: ${({ isFocused }) => (isFocused ? "0" : "0.8rem")};
  border-radius: 0.4rem;
  background-color: ${({ color, isFocused }) =>
    isFocused ? "transparent" : color};
  text-align: center;
  cursor: pointer;
`;

export const StagesSettings = ({
  isAdmin,
  stages,
  setStages,
  organisationId,
}: StagesSettingsProps) => {
  const [focusedStageId, setFocusedStageId] = useState<string | null>(null);
  const [colorSelectorStageId, setColorSelectorStageId] = useState<
    string | null
  >(null);
  const [prevName, setPrevName] = useState<string | null>(null);
  const stagesContainerRef = useRef<HTMLDivElement>(null);

  const extraSpace = useMemo(() => {
    if (!focusedStageId) return 0;
    const focusedIndex = stages.findIndex((s) => s.id === focusedStageId);
    if (focusedIndex >= stages.length - 1) {
      return 4;
    }
    return 0;
  }, [focusedStageId, stages]);

  useClickOutside(
    stagesContainerRef,
    () => {
      if (focusedStageId) {
        const stage = stages.find((s) => s.id === focusedStageId);
        if (stage && (!stage.name || stage.name.trim() === "")) {
          setStages(
            stages.map((s) =>
              s.id === focusedStageId
                ? { ...s, name: prevName ?? "New stage" }
                : s,
            ),
          );
        }
        setFocusedStageId(null);
        setColorSelectorStageId(null);
        setPrevName(null);
      }
    },
    undefined,
    { runCheckOnMouseDown: true },
  );

  const handleCreateStage = () => {
    const newStageId = uuidv4();
    setStages([
      {
        id: newStageId,
        name: "New stage",
        color: DEFAULT_STAGE_COLOR,
      },
      ...stages,
    ]);
    setFocusedStageId(newStageId);
  };

  const handleNameChange = (stageId: string, name: string) => {
    setStages(stages.map((s) => (s.id === stageId ? { ...s, name } : s)));
  };

  const handleStageRemove = (stageId: string) => {
    setStages(stages.filter((s) => s.id !== stageId));
  };

  const handleColorSelect = (stageId: string, color: string) => {
    setStages(stages.map((s) => (s.id === stageId ? { ...s, color } : s)));
    setColorSelectorStageId(null);
  };

  const handleStageClick = (stageId: string) => {
    if (focusedStageId !== stageId) {
      const stage = stages.find((s) => s.id === stageId);
      setPrevName(stage?.name ?? "New stage");
      setFocusedStageId(stageId);
      setColorSelectorStageId(null);
    }
  };

  return (
    <>
      <Column style={{ width: "100%", height: "100%" }}>
        {stages.length === 0 ? (
          <EmptyState
            style={{
              width: "initial",
            }}
            title="No stages yet"
            descriptionComponent={
              <EmptyStateDescription
                organisationId={organisationId}
                type="stages"
                isOrgAdmin={isAdmin}
              />
            }
            icon={
              <IconREMSize height={1.4} width={1.4} iconColor={colors.white}>
                <StagesIcon />
              </IconREMSize>
            }
            actionButton={
              isAdmin ? (
                <Button
                  text="Create stage"
                  buttonType="primary"
                  onClick={handleCreateStage}
                />
              ) : undefined
            }
          />
        ) : (
          <>
            {isAdmin && (
              <Button
                text="New"
                buttonType="secondary"
                icon={<AddIcon />}
                onClick={handleCreateStage}
                style={{ marginBottom: "1.2rem", marginLeft: "auto" }}
              />
            )}
            <Column
              ref={stagesContainerRef}
              style={{
                gap: "0.2rem",
                overflowY: "auto",
                height: "fit-content",
                minHeight: `${
                  stages.length < 6
                    ? `calc(${stages.length * 3.6}rem + ${extraSpace}rem)`
                    : undefined
                }`,
                maxHeight: `21rem`,
              }}
            >
              {stages.map((stage) => (
                <SettingsRowWithHover
                  key={stage.id}
                  isFocused={focusedStageId === stage.id}
                  onClick={() => handleStageClick(stage.id)}
                >
                  {focusedStageId === stage.id && isAdmin && (
                    <div style={{ position: "relative" }}>
                      <ColorBox
                        color={stage.color}
                        onClick={() => {
                          setColorSelectorStageId(stage.id);
                        }}
                      >
                        <ColorBoxText
                          color={stageBackgroundColorToTextColor(stage.color)}
                        >
                          A
                        </ColorBoxText>
                      </ColorBox>
                      {colorSelectorStageId === stage.id && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            zIndex: 1,
                          }}
                        >
                          <StagesColorSelector
                            selectedColor={stage.color}
                            onSelect={(color: string) =>
                              handleColorSelect(stage.id, color)
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <StageColorBox
                    color={stage.color}
                    isFocused={focusedStageId === stage.id}
                  >
                    <EditableText
                      disabled={!isAdmin}
                      smallInput={true}
                      value={stage.name}
                      onChange={(e) =>
                        handleNameChange(stage.id, e.target.value)
                      }
                      style={{
                        width: "100%",
                      }}
                      textContainerStyle={{
                        width: "100%",
                        color: stageBackgroundColorToTextColor(stage.color),
                      }}
                      onEnter={() => {
                        if (!stage.name || stage.name.trim() === "") {
                          handleNameChange(stage.id, prevName ?? "New stage");
                        }
                        setFocusedStageId(null);
                        setPrevName(null);
                      }}
                      onClick={() => setFocusedStageId(stage.id)}
                      onEditChange={(isEditing) => {
                        if (isEditing) {
                          setFocusedStageId(stage.id);
                        }
                      }}
                    />
                  </StageColorBox>
                  {isAdmin && (
                    <DeleteButton onClick={() => handleStageRemove(stage.id)}>
                      <IconREMSize height={1.4} width={1.4}>
                        <TrashIcon />
                      </IconREMSize>
                    </DeleteButton>
                  )}
                </SettingsRowWithHover>
              ))}
            </Column>
          </>
        )}
      </Column>
    </>
  );
};
