import { atom } from "jotai";
import { listBranches } from "services/projectDataAPIService";
import { BranchMeta } from "types/api";
import { ValOrUpdate } from "types/utils";
import { atomFamily, atomFromFn } from "utils/jotai";

export const branchMetasFamily = atomFamily((input: { projectId: string }) =>
  atomFromFn<Promise<Map<string, BranchMeta>>>(async () => {
    const projectId = input.projectId;
    if (!projectId) throw new Error("branchMetasFamily require projectIdAtom");
    const { branches } = await listBranches(projectId);
    return new Map(branches.map((b) => [b.id, b]));
  }),
);

export const archivedBranchMetasFamily = atomFamily(
  (input: { projectId: string }) =>
    atomFromFn<Promise<Map<string, BranchMeta>>>(async () => {
      const projectId = input.projectId;
      if (!projectId)
        throw new Error("archivedBranchMetasFamily require projectIdAtom");
      const { branches } = await listBranches(projectId, true);
      return new Map(branches.map((b) => [b.id, b]));
    }),
);

export const branchMetasBySortOrderFamily = atomFamily(
  ({ nodeId: projectId }: { nodeId: string }) =>
    atom<Promise<BranchMeta[]>, [ValOrUpdate<BranchMeta[]>], Promise<void>>(
      async (get) => {
        const metas = await get(branchMetasFamily({ projectId }));
        const array = [...metas.values()];
        return array.sort((a, b) => {
          return (
            (a.sortOrder ?? Number.MAX_SAFE_INTEGER) -
            (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
          );
        });
      },
      async (get, set, update) => {
        const a = branchMetasFamily({ projectId });
        if (typeof update === "function") {
          const val = await get(
            branchMetasBySortOrderFamily({ nodeId: projectId }),
          );
          const next = update(val);
          set(a, Promise.resolve(new Map(next.map((b) => [b.id, b]))));
        } else {
          set(a, Promise.resolve(new Map(update.map((b) => [b.id, b]))));
        }
      },
    ),
);

export const branchMetaFamily = atomFamily(
  ({ branchId, projectId }: { branchId: string; projectId: string }) =>
    atomFromFn<Promise<BranchMeta | undefined>>(async (get) =>
      get(branchMetasFamily({ projectId })).then((m) => m.get(branchId)),
    ),
);
