import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { TextInput } from "components/General/Input";
import Button from "components/General/Button";
import useTextInput from "hooks/useTextInput";
import { Column, Row } from "components/General/Layout";
import Spinner from "@icons/spinner/Spinner";
import { typography } from "styles/typography";
import { DesignToolMode } from "types/map";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  offshoreAccessSelectorFamily,
  onshoreAccessSelectorFamily,
} from "state/featureAccess";
import { borderRadius, spacing2, spacing4, spacing8 } from "styles/space";
import OnshoreImage from "@icons/onshore-project-type.jpeg";
import OffshoreImage from "@icons/offshore-project-type.jpeg";

const ModalHeader = styled.h2`
  margin: 0;
`;

const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 40rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Label = styled.label`
  ${typography.caption};
  color: ${colors.textSecondary};
`;

const TypeLabel = styled.label<{ selected?: boolean }>`
  ${typography.caption};
  font-weight: ${({ selected }) => (selected ? "600" : "inherit")};
  color: ${({ selected }) =>
    selected ? colors.textSelected : colors.textPrimary};
`;

const ImageWrapper = styled.div<{ selected?: boolean }>`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: ${borderRadius.small};
  border: 3px solid
    ${({ selected }) => (selected ? colors.fuschia700 : "transparent")};
  overflow: hidden;
  cursor: pointer;
`;

const ColumnLargeSpacing = styled(Column)`
  gap: ${spacing8};
`;

const ColumnSmallSpacing = styled(Column)`
  gap: ${spacing4};
`;

const ColumnTinySpacing = styled(Column)`
  gap: ${spacing2};
  align-items: center;
`;

const Subtext = styled.p`
  ${typography.caption};
  color: ${colors.textSecondary};
`;

const NewItemProjectTypeModal = ({
  title,
  placeholder,
  defaultValue,
  onSubmit,
  onClose,
  subtitle,
  choice,
}: {
  title: string;
  placeholder: string;
  defaultValue: string;
  onSubmit: (
    newValue: string,
    projectType: DesignToolMode,
  ) => Promise<void> | void;
  onClose(): void;
  subtitle?: string;
  choice?: string;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const offshoreAccess = useAtomValue(
    offshoreAccessSelectorFamily({ organisationId }),
  );
  const onshoreAccess = useAtomValue(
    onshoreAccessSelectorFamily({ organisationId }),
  );
  const fullShoreAccess = onshoreAccess && offshoreAccess;
  const [modalElem, setModalElem] = useState<HTMLDivElement | null>(null);
  const inputElem = useRef<HTMLInputElement>(null);
  const [value, onValueChange] = useTextInput(defaultValue);
  const formRef = useRef<HTMLFormElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [projectType, setProjectType] = useState<DesignToolMode>(
    offshoreAccess ? DesignToolMode.Offshore : DesignToolMode.Onshore,
  );

  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setModalElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  useEffect(() => {
    // Hack needed to wait for next frame to select the input
    setTimeout(() => {
      inputElem.current?.select();
    }, 0);
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        onClose();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClose]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.currentTarget.reportValidity()) {
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit(value, projectType);
    } finally {
      setIsLoading(false);
    }
    onClose();
  };

  if (!modalElem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal
      onEscape={onClose}
      closeOnWrapperClick={true}
      onClick={onClose}
    >
      <Modal>
        <ModalHeader>{title}</ModalHeader>
        {subtitle && <Subtext>{subtitle}</Subtext>}
        <form ref={formRef} onSubmit={handleSubmit}>
          <ColumnLargeSpacing>
            <ColumnSmallSpacing>
              <TextInput
                id={"new-project-input"}
                ref={inputElem}
                autoFocus
                required
                placeholder={placeholder}
                minLength={1}
                value={value}
                onChange={onValueChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submitButtonRef.current?.click();
                  }
                }}
                style={{
                  width: "100%",
                }}
                disabled={isLoading}
              />
            </ColumnSmallSpacing>

            {fullShoreAccess && (
              <ColumnSmallSpacing style={{ marginTop: "0.4rem" }}>
                <Label>{choice || "Select project type"}</Label>
                <Row style={{ gap: spacing4 }}>
                  <ColumnTinySpacing>
                    <ImageWrapper
                      selected={projectType === DesignToolMode.Offshore}
                      onClick={() => setProjectType(DesignToolMode.Offshore)}
                    >
                      <img src={OffshoreImage} alt="Offshore" height={"100%"} />
                    </ImageWrapper>
                    <TypeLabel
                      selected={projectType === DesignToolMode.Offshore}
                    >
                      Offshore
                    </TypeLabel>
                  </ColumnTinySpacing>
                  <ColumnTinySpacing>
                    <ImageWrapper
                      selected={projectType === DesignToolMode.Onshore}
                      onClick={() => setProjectType(DesignToolMode.Onshore)}
                    >
                      <img src={OnshoreImage} alt="Onshore" height={"100%"} />
                    </ImageWrapper>
                    <TypeLabel
                      selected={projectType === DesignToolMode.Onshore}
                    >
                      Onshore
                    </TypeLabel>
                  </ColumnTinySpacing>
                </Row>
              </ColumnSmallSpacing>
            )}

            <Row
              style={{
                justifyContent: "flex-end",
                gap: "1.2rem",
              }}
            >
              <Button text="Cancel" buttonType="secondary" onClick={onClose} />
              <Button
                ref={submitButtonRef}
                id={"new-project-submit-button"}
                text="Create"
                type="submit"
                disabled={isLoading}
                icon={isLoading ? <Spinner size="1rem" /> : undefined}
              />
            </Row>
          </ColumnLargeSpacing>
        </form>
      </Modal>
    </FullScreenModal>,
    modalElem,
  );
};

export default NewItemProjectTypeModal;
