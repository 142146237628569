import CloseableHint from "../CloseableHints/CloseableHint";
import resetOrientationHelpVideo from "./resetOrientationHelpVideo.mp4";
import North from "@icons/24/Compass.svg?react";
import styled from "styled-components";

export const resetOrientationHelpHelpHintType =
  "resetOrientationHelpHelpHintType";

const TextSvgWrapper = styled.div`
  > svg {
    margin: 0 0.3rem;
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const ResetOrientationHelp = ({
  closeCallback,
  gotItCallback,
}: {
  closeCallback: () => void;
  gotItCallback?: () => void;
}) => {
  return (
    <CloseableHint
      title={"Reset orientation"}
      closeCallback={closeCallback}
      gotItCallback={gotItCallback}
      timeToDissapearMS={10000}
      videosrc={resetOrientationHelpVideo}
    >
      <TextSvgWrapper>
        Use the <North /> button to reset the camera view
      </TextSvgWrapper>
    </CloseableHint>
  );
};

export default ResetOrientationHelp;
