/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Error = z
      .object({
        error: z.string().optional(),
      })
      .passthrough();
    export const AutosaveRequestEntry = z
      .object({
        nodeId: z.string(),
        branchId: z.string(),
        timestamp: z.number(),
        authors: z.array(z.string()),
      })
      .strict();
    export const SaveRequestEntry = z
      .object({
        nodeId: z.string(),
        branchId: z.string(),
        timestamp: z.number(),
        name: z.string(),
        authors: z.array(z.string()),
      })
      .strict();
    export const EditSaveRequestEntry = z
      .object({
        name: z.string(),
      })
      .strict();
  }
}
export const components = {
  schemas: {
    Error: Components.Schemas.Error,
    AutosaveRequestEntry: Components.Schemas.AutosaveRequestEntry,
    SaveRequestEntry: Components.Schemas.SaveRequestEntry,
    EditSaveRequestEntry: Components.Schemas.EditSaveRequestEntry,
  },
};

export const paths = {
  "/api/autosave/{nodeId}/{branchId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
      }),
      responses: {
        200: {
          /** Layers */
          "application/json": z.array(Components.Schemas.AutosaveRequestEntry),
        },
      },
    },
  },
  "/api/autosave/{nodeId}/{branchId}/{timestamp}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
        timestamp: z.string(),
      }),
      responses: {
        200: {
          /** Signed url for autosave */
          "application/json": z.string(),
        },
        404: {
          /** Layers */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/autosave/save/{nodeId}/{branchId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
      }),
      responses: {
        200: {
          /** Layers */
          "application/json": z.array(Components.Schemas.SaveRequestEntry),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
      }),
      /** New save features */
      requestBody: {
        "application/json": Components.Schemas.EditSaveRequestEntry,
      },
      responses: {
        201: {
          /** created new save */
          "application/json": Components.Schemas.SaveRequestEntry,
        },
        404: {
          /** Not found */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/autosave/save/{nodeId}/{branchId}/{timestamp}": {
    put: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
        timestamp: z.string(),
      }),
      /** Edit save features */
      requestBody: {
        "application/json": Components.Schemas.EditSaveRequestEntry,
      },
      responses: {
        200: {
          /** edit save */
          "application/json": Components.Schemas.SaveRequestEntry,
        },
        404: {
          /** Not found */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        branchId: z.string(),
        timestamp: z.string(),
      }),
      responses: {
        200: {
          /** edit save */
          "application/json": z.string(),
        },
        404: {
          /** Not found */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
