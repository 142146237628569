import { analysisStoppedText } from "analysis/warnings";
import Dropdown from "components/Dropdown/Dropdown";
import { Row } from "components/General/Layout";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import Plotly from "plotly.js-dist-min";
import { useEffect, useMemo, useRef, useState } from "react";
import { spaceMedium } from "styles/space";
import { CashFlowType } from "utils/finances";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useAtomValue } from "jotai";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useCashFlow } from "finance/hooks/useCashflow";
import { getFinancialStoppedReason } from "finance/warnings";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";

export const ParkLifeCashFlowWidget = () => {
  const { park, branch, errorBoundaryResetKeys } = useDashboardContext();

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );
  if (Object.values(invalidTypes).some((e) => e))
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some elements are using a type that is invalid"}
          type={"error"}
        />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="Park life cash flow"
      id="Park life cash flow"
      helptext="This widget shows the phased cash flow for a park. The inflation and discounting rates depend on the currently chosen financial configuration."
      resetKeys={errorBoundaryResetKeys}
    >
      <ParkLifeCashFlowWidgetInner />
    </SafeCard>
  );
};

const ParkLifeCashFlowWidgetInner = () => {
  const { triggerId } = useDashboardContext();
  const graphRef = useRef<HTMLDivElement>(null);

  const {
    inputs: { discountRate, inflationRate },
    results: { devex, capex, opex, decom, guaranteed, market },
  } = useCashFlow(triggerId);

  enum cashFlowType {
    Raw = "raw",
    Inflated = "inflated",
    Discounted = "discounted",
  }

  const [cashFlowTypeSelect, setCashFlowTypeSelect] = useState<string>(
    cashFlowType.Inflated,
  );

  const traces = useMemo(() => {
    if (!devex) return [];
    if (!capex) return [];
    if (!opex) return [];
    if (!decom) return [];
    if (!guaranteed) return [];
    if (!market) return [];

    const createTrace = (
      name: string,
      cashFlow: CashFlowType,
      type: string,
      isNegative = false,
    ) => {
      if (type === cashFlowType.Discounted) {
        return {
          x: cashFlow.discounted().map(({ year }) => year.toString()),
          y: cashFlow
            .discounted()
            .map(({ value }) => (isNegative ? -value : value)),
          name,
          type: "bar" as const,
        };
      } else if (type === cashFlowType.Inflated) {
        return {
          x: cashFlow.inflated().map(({ year }) => year.toString()),
          y: cashFlow
            .inflated()
            .map(({ value }) => (isNegative ? -value : value)),
          name,
          type: "bar" as const,
        };
      } else {
        // not inflated, not discounted
        return {
          x: cashFlow.raw().map(({ year }) => year.toString()),
          y: cashFlow.raw().map(({ value }) => (isNegative ? -value : value)),
          name,
          type: "bar" as const,
        };
      }
    };

    return [
      createTrace("DEVEX", devex, cashFlowTypeSelect, true),
      createTrace("CAPEX", capex, cashFlowTypeSelect, true),
      createTrace("OPEX", opex, cashFlowTypeSelect, true),
      createTrace("DECOM", decom, cashFlowTypeSelect, true),
      createTrace("Guaranteed price", guaranteed, cashFlowTypeSelect),
      createTrace("Market price", market, cashFlowTypeSelect),
    ];
  }, [
    devex,
    capex,
    opex,
    decom,
    cashFlowTypeSelect,
    guaranteed,
    market,
    cashFlowType.Discounted,
    cashFlowType.Inflated,
  ]);

  useEffect(() => {
    if (!graphRef.current || traces.length === 0) return;

    var layout = {
      autosize: true,
      height: 235,
      font: { size: 10, family: "Open Sans" },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 0,
      },
      xaxis: { title: "Year", fixedrange: true },
      yaxis: { title: "Cash flow (€)", fixedrange: true },
      barmode: "relative" as const,
    };

    Plotly.newPlot(graphRef.current, traces, layout, {
      responsive: true,
      modeBarButtons: [["toImage"]],
      displaylogo: false,
      toImageButtonOptions: { format: "svg" },
    });
  }, [graphRef, traces]);

  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(triggerId),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  if (!devex || !capex || !opex || !decom || !guaranteed || !market)
    return <LoadingState />;

  return (
    <>
      <Row style={{ padding: spaceMedium }}>
        <Dropdown
          value={cashFlowTypeSelect}
          onChange={(e) => setCashFlowTypeSelect(e.target.value)}
        >
          <option key={cashFlowType.Raw} value={cashFlowType.Raw}>
            {"No inflation or discounting"}
          </option>
          <option key={cashFlowType.Inflated} value={cashFlowType.Inflated}>
            {`Inflated (${inflationRate})`}
          </option>
          <option key={cashFlowType.Discounted} value={cashFlowType.Discounted}>
            {`Inflated (${inflationRate}) and discounted (${discountRate})`}
          </option>
        </Dropdown>
      </Row>
      <div ref={graphRef} />
    </>
  );
};
