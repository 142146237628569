import { analysisStoppedText, getAnalysisError } from "analysis/warnings";
import { GeneralCost } from "types/financial";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { CostType } from "services/costService";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { Stat, StatWrapper, Stats } from "./Base.style";
import { useAtomValue } from "jotai";
import { CAPEX } from "finance/outputs";
import { getFinancialStoppedReason } from "finance/warnings";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { isOnshoreAtom } from "state/onshore";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getFinancialStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );

  const stopped = stoppedReason ?? analysisStoppedReason;

  const turbineCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Turbine }),
  );

  const cableCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Cable }),
  );

  const foundationCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Foundation }),
  );

  const substationCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Substation }),
  );

  const exportCableCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({
      id: triggerId,
      type: CostType.ExportCable,
    }),
  );

  const mooringCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Mooring }),
  );

  const otherCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Other }),
  );

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stopped]} type={"error"} />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={installationStoppedText[installationStoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <Stat
        title="CAPEX reference year"
        value={costConfiguration?.inflation.referenceYearCapex}
        bold={false}
      />

      <h4>Turbines</h4>
      {turbineCosts.length > 0 &&
        turbineCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Inter array cables</h4>
      {cableCosts.length > 0 &&
        cableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Foundations</h4>
      {foundationCosts.length > 0 &&
        foundationCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Mooring system</h4>
      {mooringCosts.length > 0 &&
        mooringCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Substations</h4>
      {substationCosts.length > 0 &&
        substationCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Export cable</h4>
      {exportCableCosts.length > 0 &&
        exportCableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Other</h4>
      {otherCosts.length > 0 &&
        otherCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}
    </Stats>
  );
};

const InputDetailsOnshore = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getFinancialStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const stopped = stoppedReason ?? analysisStoppedReason;

  const turbineCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Turbine }),
  );

  const cableCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Cable }),
  );

  const substationCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Substation }),
  );

  const exportCableCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({
      id: triggerId,
      type: CostType.ExportCable,
    }),
  );

  const otherCosts: GeneralCost[] = useAtomValue(
    CAPEX.getRealValueCostsOfType({ id: triggerId, type: CostType.Other }),
  );

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stopped]} type={"error"} />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <Stat
        title="CAPEX reference year"
        value={costConfiguration?.inflation.referenceYearCapex}
        bold={false}
      />

      <h4>Turbines</h4>
      {turbineCosts.length > 0 &&
        turbineCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Inter array cables</h4>
      {cableCosts.length > 0 &&
        cableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Substations</h4>
      {substationCosts.length > 0 &&
        substationCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Export cable</h4>
      {exportCableCosts.length > 0 &&
        exportCableCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}

      <h4>Other</h4>
      {otherCosts.length > 0 &&
        otherCosts
          .filter(({ amount }) => amount > 0)
          .map((c) => {
            return (
              <StatWrapper key={c.id}>
                <p>{c.name}</p>
                <p>
                  {c.costPerAmount} {c.unit}
                </p>
              </StatWrapper>
            );
          })}
    </Stats>
  );
};

export const CapexInputWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();
  const isOnshore = useAtomValue(isOnshoreAtom);

  return (
    <SafeCard
      title="Capex input"
      id="Capex input"
      resetKeys={errorBoundaryResetKeys}
    >
      {isOnshore ? <InputDetailsOnshore /> : <InputDetails />}
    </SafeCard>
  );
};
