import { FinanceId } from "finance/types";
import { getDiscountRate, getInflationRate } from "finance/outputs";
import { CAPEX, DECOM, DEVEX, IRR, OPEX } from "finance/outputs";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { lunwrap } from "utils/jotai";

export const useCashFlow = (id: FinanceId) => {
  const devexCashFlow = useAtomValue(loadable(DEVEX.getCashFlow(id)));
  const capexCashFlow = useAtomValue(loadable(CAPEX.getCashFlow(id)));
  const opexCashFlow = useAtomValue(loadable(OPEX.getCashFlow(id)));
  const decomCashFlow = useAtomValue(loadable(DECOM.getCashFlow(id)));
  const guaranteedCashFlow = useAtomValue(
    loadable(IRR.getGuaranteedCashFlow(id)),
  );
  const marketCashFlow = useAtomValue(loadable(IRR.getMarketCashFlow(id)));
  const discountRate = useAtomValue(loadable(getDiscountRate(id)));
  const inflationRate = useAtomValue(loadable(getInflationRate(id)));

  return {
    inputs: {
      discountRate: lunwrap(discountRate),
      inflationRate: lunwrap(inflationRate),
    },
    results: {
      devex: lunwrap(devexCashFlow),
      capex: lunwrap(capexCashFlow),
      opex: lunwrap(opexCashFlow),
      decom: lunwrap(decomCashFlow),
      guaranteed: lunwrap(guaranteedCashFlow),
      market: lunwrap(marketCashFlow),
    },
  };
};
