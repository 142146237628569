import {
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import minMax from "../../../../../utils/minMax";
import { ColoredGrid } from "../../../../General/Form";
import { valueRounding } from "../utils";
import { JacketDesign } from "./state";

export const JacketSingleStats = ({
  name,
  designs,
}: {
  name: string;
  designs: JacketDesign[];
}) => {
  const numberOfTurbines = designs.length;

  const totalMassStats = minMax(designs, (d) => d.totalMass);
  const totalMassMin =
    totalMassStats && valueRounding(totalMassStats.min / 1000, 10);
  const totalMassMax =
    totalMassStats && valueRounding(totalMassStats.max / 1000, 10);

  const pileMass = valueRounding(designs[0].pileMass / 1000, 10);
  const tpMass = valueRounding(designs[0].tpMass / 1000, 10);
  const numLegs = designs[0].numLegs;

  const jacketMassStats = minMax(designs, (d) => d.jacketMass);
  const jacketMassMin =
    jacketMassStats && valueRounding(jacketMassStats.min / 1000, 10);
  const jacketMassMax =
    jacketMassStats && valueRounding(jacketMassStats.max / 1000, 10);

  const jacketHeightStats = minMax(designs, (d) => d.jacketHeight);
  const jacketHeightMin =
    jacketHeightStats && valueRounding(jacketHeightStats.min, 1);
  const jacketHeightMax =
    jacketHeightStats && valueRounding(jacketHeightStats.max, 1);

  return (
    <>
      <SubtitleWithLine text={name} />
      <ColoredGrid>
        <ResultValue>Jacket weight(s)</ResultValue>
        {numberOfTurbines !== 1 && jacketMassStats && (
          <ResultValue>
            <strong>
              {jacketMassMin?.toLocaleString()} -{" "}
              {jacketMassMax?.toLocaleString()}
            </strong>{" "}
            t
          </ResultValue>
        )}
        {numberOfTurbines === 1 && jacketMassStats && (
          <ResultValue>
            <strong>{jacketMassMax}</strong> t
          </ResultValue>
        )}
        <ResultValue>TP weight</ResultValue>
        <ResultValue>
          <strong>{tpMass.toLocaleString()}</strong> t
        </ResultValue>
        <ResultValue>Pile weight (per pile)</ResultValue>
        <ResultValue>
          <strong>{pileMass.toLocaleString()}</strong> t
        </ResultValue>
        <ResultValue>Total steel weight(s)</ResultValue>
        {numberOfTurbines !== 1 && totalMassStats && (
          <ResultValue>
            <strong>
              {totalMassMin?.toLocaleString()} -{" "}
              {totalMassMax?.toLocaleString()}
            </strong>{" "}
            t
          </ResultValue>
        )}
        {numberOfTurbines === 1 && totalMassStats && (
          <ResultValue>
            <strong>{totalMassMax}</strong> t
          </ResultValue>
        )}
        <ResultValue>Number of legs</ResultValue>
        <ResultValue>
          <strong>{numLegs.toLocaleString()}</strong>
        </ResultValue>
        <ResultValue>Jacket height(s)</ResultValue>
        {numberOfTurbines !== 1 && jacketHeightStats && (
          <ResultValue>
            <strong>
              {jacketHeightMin?.toLocaleString()} -{" "}
              {jacketHeightMax?.toLocaleString()}
            </strong>{" "}
            m
          </ResultValue>
        )}
        {numberOfTurbines === 1 && jacketHeightStats && (
          <ResultValue>
            {" "}
            <strong>{jacketHeightMax} </strong> m
          </ResultValue>
        )}
      </ColoredGrid>
    </>
  );
};
