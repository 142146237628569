import { aset, useJotaiCallback } from "utils/jotai";
import useOrgAnalysisCrud from "components/Organisation/Library/analysis/useOrgAnalysisCrud";
import {
  analysisResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import useOrgCableCrud from "components/Organisation/Library/cable/useOrgCableCrud";
import useOrgTurbineCrud from "components/Organisation/Library/turbine/useOrgTurbineCrud";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { AnalysisConfiguration } from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { getAdvancedProjectTurbine } from "services/turbineAPIService";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import {
  cableResourceWithAccessOnNodeState,
  exportCableResourceWithAccessOnNodeState,
} from "state/cableType";
import { SimpleTurbineType } from "types/turbines";
import { scream } from "utils/sentry";
import { useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import useOrgExportCableCrud from "components/Organisation/Library/exportcable/useOrgExportCableCrud";
import useOrgFinancialCrud from "components/Organisation/Library/financial/useOrgFinancialCrud";
import {
  CostType,
  CostConfiguration,
  isFoundationMaterialCost,
} from "services/costService";
import { libraryFinancialConfigRefreshAtom } from "state/costConfigurations";
import { TURBINE_MENU_ID } from "./useTurbineSettings";
import { CABLE_MENU_ID } from "./useCableSettings";
import { EXPORT_CABLE_MENU_ID } from "./useExportCableSettings";
import { ANALYSIS_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useAnalysisConfiguration";
import { COST_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { projectIdAtom } from "state/pathParams";
import { ConfidenceLevel } from "types/financial";
import { CostUnit } from "types/financial";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";

export function useDuplicateTurbineToLibrary() {
  const orgTurbineCrud = useOrgTurbineCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, simpleTurbine: SimpleTurbineType) => {
      setIsLoading(true);
      try {
        const turbine = await getAdvancedProjectTurbine(
          nodeId,
          simpleTurbine.id,
        );
        await orgTurbineCrud.duplicate({
          turbine: turbine,
          projectAccess: [nodeId],
          onSuccess: (newTurbine) => {
            set(
              selectedMenuItemState({
                menuId: TURBINE_MENU_ID,
                projectId: nodeId,
              }),
              newTurbine.id,
            );
            aset(
              get,
              set,
              turbineResourceWithAccessOnNodeState({
                nodeId: nodeId,
              }),
              (curr) => [
                ...curr,
                {
                  nodeId,
                  turbine: {
                    ...simpleTurbine,
                    id: newTurbine.id,
                  },
                },
              ],
            );
            console.log(newTurbine);
          },
        });
      } catch (e) {
        scream("Error when duplicating project turbine to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project turbine to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [error, orgTurbineCrud],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
}

export function useDuplicateCableToLibrary() {
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const projectId = useAtomValue(projectIdAtom);

  const setSelectedMenuItemState = useSetAtom(
    selectedMenuItemState({
      menuId: CABLE_MENU_ID,
      projectId: projectId ?? "",
    }),
  );

  const { duplicate } = useOrgCableCrud();

  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, cable: CableType) => {
      setIsLoading(true);
      try {
        await duplicate({
          cable: cable,
          projectAccess: [nodeId],
          onSuccess: (newCable) => {
            aset(
              get,
              set,
              cableResourceWithAccessOnNodeState({
                nodeId: nodeId,
              }),
              (curr) => [
                ...curr,
                {
                  nodeId,
                  cable: newCable,
                },
              ],
            );

            setSelectedMenuItemState(newCable.id);
          },
        });
      } catch (e) {
        scream("Error when duplicating project cable to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project cable to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [error, duplicate, setSelectedMenuItemState],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
}

export function useDuplicateExportCableToLibrary() {
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const projectId = useAtomValue(projectIdAtom);

  const setSelectedMenuItemState = useSetAtom(
    selectedMenuItemState({
      menuId: EXPORT_CABLE_MENU_ID,
      projectId: projectId ?? "",
    }),
  );

  const { duplicate } = useOrgExportCableCrud();

  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, cable: CableType) => {
      setIsLoading(true);
      try {
        await duplicate({
          cable: cable,
          projectAccess: [nodeId],
          onSuccess: (newCable) => {
            aset(
              get,
              set,
              exportCableResourceWithAccessOnNodeState({
                nodeId: nodeId,
              }),
              (curr) => [
                ...curr,
                {
                  nodeId,
                  cable: newCable,
                },
              ],
            );

            setSelectedMenuItemState(newCable.id);
          },
        });
      } catch (e) {
        scream("Error when duplicating project export cable to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project export cable to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [error, duplicate, setSelectedMenuItemState],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
}

export const useDuplicateCostToLibrary = () => {
  const { duplicate } = useOrgFinancialCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, configuration: CostConfiguration) => {
      const isMaterialFoundationCost = isFoundationMaterialCost(
        configuration.capex.fixed.foundations,
      );

      const foundationCost = isMaterialFoundationCost
        ? {
            cost: DefaultCosts[CostType.Foundation].foundation[
              CostUnit.millionEuroPerUnit
            ],
            unit: CostUnit.millionEuroPerUnit,
            confidenceLevel: ConfidenceLevel.notSpecified,
          }
        : configuration.capex.fixed.foundations;

      setIsLoading(true);
      try {
        await duplicate({
          config: {
            ...configuration,
            capex: {
              ...configuration.capex,
              fixed: {
                ...configuration.capex.fixed,
                foundations: foundationCost,
              },
              projectComponentCosts: {
                cables: {},
                exportCable: {},
                turbines: {},
              },
            },
          },
          projectAccess: [nodeId],
          isMaterialFoundationCost,
          onSuccess: () => {
            set(
              selectedMenuItemState({
                menuId: COST_MENU_ID,
                projectId: nodeId,
              }),
              configuration.id,
            );
            set(
              financialResourceWithAccessOnNodeState({
                nodeId,
              }),
              RESET,
            );
          },
        });
      } catch (e) {
        scream("Error when duplicating project cost to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project cost to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, error, duplicate],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
};

export const useDuplicateAnalysisToLibrary = () => {
  const { duplicate } = useOrgAnalysisCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, configuration: AnalysisConfiguration) => {
      setIsLoading(true);
      try {
        await duplicate({
          analysis: configuration,
          projectAccess: [nodeId],
          onSuccess: () => {
            set(
              selectedMenuItemState({
                menuId: ANALYSIS_MENU_ID,
                projectId: nodeId,
              }),
              configuration.id,
            );
            set(
              analysisResourceWithAccessOnNodeState({
                nodeId,
              }),
              RESET,
            );
          },
        });
      } catch (e) {
        scream("Error when duplicating project analysis to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project analysis to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, error, duplicate],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
};

export const useDuplicateFinancialToLibrary = () => {
  const { duplicate } = useOrgFinancialCrud();
  const { error } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const duplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, configuration: CostConfiguration) => {
      setIsLoading(true);
      try {
        await duplicate({
          config: configuration,
          projectAccess: [nodeId],
          onSuccess: (newConfig) => {
            set(
              selectedMenuItemState({
                menuId: COST_MENU_ID,
                projectId: nodeId,
              }),
              newConfig.id,
            );
            aset(
              get,
              set,
              financialResourceWithAccessOnNodeState({
                nodeId: nodeId,
              }),
              (curr) => [
                ...curr,
                {
                  nodeId,
                  config: newConfig,
                },
              ],
            );
            set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
          },
        });
      } catch (e) {
        scream("Error when duplicating project financial to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project financial to library, the Vind team has been notified",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, error, duplicate],
  );

  return {
    duplicateToLibrary,
    isLoading,
  };
};
