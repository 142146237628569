import {
  VesselType,
  VesselTypePostBody,
  createVesselType,
  deleteVesselType,
  updateVesselType,
} from "services/vesselService";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import { projectIdAtom } from "../state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { VESSEL_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useVesselSettings";
import { Mixpanel } from "mixpanel";
import {
  projectVesselRefreshAtom,
  _projectVesselTypeFamily,
} from "state/jotai/vesselType";
import { useAtomValue, useSetAtom } from "jotai";

const useVesselTypeCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const setIsAutoSaving = useSetAtom(savingConfigurationInProgressAtom);

  const updateLocal = useJotaiCallback(
    async (get, set, vessel: VesselType) => {
      if (!projectId) throw new Error("projectId is undefined");
      return aset(get, set, _projectVesselTypeFamily(projectId), (curr) => {
        const exists = curr.some((v) => v.id === vessel.id);
        if (exists) {
          return curr.map((v) => (v.id === vessel.id ? vessel : v));
        } else {
          return [...curr, vessel];
        }
      });
    },
    [projectId],
  );

  const deleteLocal = useJotaiCallback(
    async (get, set, id: string) => {
      if (!projectId) throw new Error("projectId is undefined");
      return aset(get, set, _projectVesselTypeFamily(projectId), (curr) => {
        return curr.filter((v) => v.id !== id);
      });
    },
    [projectId],
  );

  const create = useJotaiCallback(
    (_, set, { vessel }: { vessel: VesselTypePostBody; name?: string }) => {
      const newTurbine = {
        ...vessel,
      };
      return createVesselType(projectId, newTurbine).then((res) => {
        set(
          selectedMenuItemState({ menuId: VESSEL_MENU_ID, projectId }),
          res.id,
        );
        Mixpanel.track_old("Created new vessel", {
          vesselId: vessel.id,
          vesselType: vessel.type,
        });
        set(projectVesselRefreshAtom, Symbol());
        return res;
      });
    },
    [projectId],
  );

  const update = useJotaiCallback(
    (_, set, { vessel }: { vessel: VesselType }) => {
      setIsAutoSaving(true);
      updateLocal(vessel);
      return updateVesselType(projectId, vessel).finally(() => {
        setIsAutoSaving(false);
        set(projectVesselRefreshAtom, Symbol());
      });
    },
    [projectId, setIsAutoSaving, updateLocal],
  );

  const deleteVessel = useJotaiCallback(
    (_, set, { vesselId }: { vesselId: string }) => {
      setIsAutoSaving(true);
      deleteLocal(vesselId);
      return deleteVesselType(projectId, vesselId).finally(() => {
        setIsAutoSaving(false);
        set(projectVesselRefreshAtom, Symbol());
      });
    },
    [projectId, setIsAutoSaving, deleteLocal],
  );

  return { create, update, deleteVessel, updateLocal, deleteLocal };
};

export default useVesselTypeCrud;
