import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { TurbineType } from "./../../../types/turbines";
import { FoundationType } from "types/foundations";
import { OrgResource } from "../OrganisationRightSide/types";
import {
  getAnalysisResourcesOnNode,
  getFoundationResourcesOnNode,
  getOrganisationInterArrayCableResources,
  getOrganisationFoundationResources,
  getOrganisationTurbineResources,
  getTurbineResourcesOnNode,
  getFinancialResourcesOnNode,
  getDataPackageResourcesOnNode,
  getDataLayerResourcesOnNode,
  getNodesWithAccessToLibraryResource,
  getResourceManagers,
  getResourceManagersAdmin,
  getOrganisationExportCableResources,
} from "./service";
import {
  NodeAnalysisAccess,
  NodeDataPackageAccess,
  NodeFinancialAccess,
  NodeFoundationAccess,
  NodeLibraryResource,
  NodeTurbineAccess,
  UploadedVectorDataLayer,
} from "./types";
import {
  AnalysisConfiguration,
  listOrgAnalysisConfigurations,
} from "services/configurationService";
import { CableType } from "services/cableTypeService";
import { scream } from "utils/sentry";
import { dedup } from "utils/utils";
import {
  CostConfiguration,
  listOrgFinancialConfigurations,
} from "services/costService";
import { DataLibraryPackage } from "./dataLibrary/types";
import { listOrganisationLibraryPackages } from "./dataLibrary/service";
import {
  adminInOrganisationSelectorFamily,
  userOrganisationResourcesSyncAtom,
} from "state/user";
import { atomWithRefresh } from "jotai/utils";
import { listOrgSubstations, SubstationType } from "services/substationService";
import { z } from "zod";

// --------- Navigation state ---------------------

export type LibraryTab =
  | "turbine"
  | "foundation"
  | "analysis"
  | "cable"
  | "exportcable"
  | "financial"
  | "datalayers"
  | "substation"
  | undefined;

export const _LibraryTabSchema = z
  .enum([
    "turbine",
    "foundation",
    "analysis",
    "cable",
    "exportcable",
    "financial",
    "datalayers",
    "substation",
  ])
  .optional();

export const fromProjectToLibraryTabState = atom<boolean>(false);

// ------------ Org resources ------------------------

export const organisationTurbineResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<TurbineType[]>>(async () => {
      if (!organisationId) return [];
      try {
        const allTurbines =
          await getOrganisationTurbineResources(organisationId);
        return allTurbines
          .filter((t) => !t.archived)
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          );
      } catch (e) {
        if (e instanceof Error) {
          scream(e, {
            message: "Failed to get turbines",
          });
        } else {
          scream(new Error("Failed to get turbines"), {
            e,
          });
        }
        return [];
      }
    }),
);

export const organisationFoundationResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<FoundationType[]>>(async () => {
      if (!organisationId) return [];
      try {
        const foundations =
          await getOrganisationFoundationResources(organisationId);
        return foundations
          .filter((t) => !t.archived)
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          );
      } catch (e) {
        return [];
      }
    }),
);

export const organisationAnalysisResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<AnalysisConfiguration[]>>(async () => {
      if (!organisationId) return [];
      return await listOrgAnalysisConfigurations(organisationId).catch(
        (error) => {
          scream("Error when listing configurations", {
            error,
          });
          return [];
        },
      );
    }),
);

export const organisationInterArrayCableResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<CableType[]>>(async () => {
      if (!organisationId) return [];
      return await getOrganisationInterArrayCableResources(organisationId)
        .then((res) => {
          return res.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          );
        })
        .catch(() => []);
    }),
);

export const organisationExportCableResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<CableType[]>>(async () => {
      if (!organisationId) return [];
      return await getOrganisationExportCableResources(organisationId)
        .then((res) =>
          res.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          ),
        )
        .catch(() => []);
    }),
);

export const organisationFinancialResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<CostConfiguration[]>>(async () => {
      if (!organisationId) return [];
      return await listOrgFinancialConfigurations(organisationId)
        .then((res) => {
          const { items } = res;
          return items.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          );
        })
        .catch(() => []);
    }),
);

export const organisationLibraryDataPackageResourceState = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atomFromFn<Promise<DataLibraryPackage[]>>(async () => {
      if (!organisationId) return [];
      return await listOrganisationLibraryPackages(organisationId)
        .then((res) => {
          const items = res;
          return items.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: "accent",
            }),
          );
        })
        .catch(() => []);
    }),
);

export const libraryResourceSelector = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atom<
      Promise<
        | {
            type: "foundation";
            resource: FoundationType;
          }
        | {
            type: "turbine";
            resource: TurbineType;
          }
        | {
            type: "analysis";
            resource: AnalysisConfiguration;
          }
        | {
            type: "cable";
            resource: CableType;
          }
        | {
            type: "exportcable";
            resource: CableType;
          }
        | {
            type: "financial";
            resource: CostConfiguration;
          }
        | {
            type: "datapackage";
            resource: DataLibraryPackage;
          }
        | {
            type: "substation";
            resource: SubstationType;
          }
        | undefined
      >
    >(async (get) => {
      const currentUser = get(
        userOrganisationResourcesSyncAtom({
          organisationId,
        }),
      ).data;

      const [
        turbines,
        foundations,
        analysis,
        cables,
        exportcables,
        dataPackages,
        financial,
        substations,
      ] = await Promise.all([
        currentUser.some((r) => r.resource_name === "org_turbine_manage")
          ? get(
              organisationTurbineResourceState({
                organisationId,
              }),
            )
          : [],
        currentUser.some((r) => r.resource_name === "org_foundation_manage")
          ? get(
              organisationFoundationResourceState({
                organisationId,
              }),
            )
          : [],
        currentUser.some((r) => r.resource_name === "org_analysis_manage")
          ? get(
              organisationAnalysisResourceState({
                organisationId,
              }),
            )
          : [],

        currentUser.some((r) => r.resource_name === "org_cable_manage")
          ? get(
              organisationInterArrayCableResourceState({
                organisationId,
              }),
            )
          : [],

        currentUser.some((r) => r.resource_name === "org_export_cable_manage")
          ? get(
              organisationExportCableResourceState({
                organisationId,
              }),
            )
          : [],

        currentUser.some((r) => r.resource_name === "org_data_package_manage")
          ? get(
              organisationLibraryDataPackageResourceState({
                organisationId,
              }),
            )
          : [],

        currentUser.some((r) => r.resource_name === "org_financial_manage")
          ? get(
              organisationFinancialResourceState({
                organisationId,
              }),
            )
          : [],

        currentUser.some((r) => r.resource_name === "org_substation_manage")
          ? get(organisationSubstationResourceState(organisationId))
          : [],
      ]);

      const turbineResource = turbines.find((t) => t.id === resourceId);
      if (turbineResource)
        return {
          type: "turbine",
          resource: turbineResource,
        };

      const foundationResource = foundations.find((f) => f.id === resourceId);
      if (foundationResource)
        return {
          type: "foundation",
          resource: foundationResource,
        };

      const analysisResource = analysis.find((f) => f.id === resourceId);
      if (analysisResource)
        return {
          type: "analysis",
          resource: analysisResource,
        };

      const cableResource = cables.find((f) => f.id === resourceId);
      if (cableResource)
        return {
          type: "cable",
          resource: cableResource,
        };

      const exportcableResource = exportcables.find((f) => f.id === resourceId);
      if (exportcableResource)
        return {
          type: "exportcable",
          resource: exportcableResource,
        };

      const dataPackage = dataPackages.find((t) => t.id === resourceId);
      if (dataPackage)
        return {
          type: "datapackage",
          resource: dataPackage,
        };

      const financialResource = financial.find((f) => f.id === resourceId);
      if (financialResource)
        return {
          type: "financial",
          resource: financialResource,
        };

      const substationResource = substations.find((f) => f.id === resourceId);
      if (substationResource)
        return {
          type: "substation",
          resource: substationResource,
        };

      return undefined;
    }),
);

// jotai: libraryTurbineTypesFamily
export const turbineResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeTurbineAccess[]>>(async () => {
      if (!nodeId) return [];
      return await getTurbineResourcesOnNode(nodeId).catch(() => []);
    }),
);

// jotai:
export const foundationResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeFoundationAccess[]>>(async () => {
      if (!nodeId) return [];
      try {
        return (await getFoundationResourcesOnNode(nodeId))
          .filter((f) => !f.foundation.archived)
          .sort((a, b) =>
            a.foundation.name.localeCompare(b.foundation.name, undefined, {
              sensitivity: "accent",
            }),
          );
      } catch {
        return [];
      }
    }),
);

export const analysisResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeAnalysisAccess[]>>(async () => {
      if (!nodeId) return [];
      const analysis = await getAnalysisResourcesOnNode(nodeId).catch(() => []);
      const uniqueAnalysisResourcesOnNode = dedup(analysis, (a) => a.config.id);
      return uniqueAnalysisResourcesOnNode;
    }),
);

export const dataPackageResourceWithAccessOnNodeStateRefreshAtom =
  atom<number>(1);

export const dataPackageResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeDataPackageAccess[]>>(async (get) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      if (!nodeId) return [];
      const config = await getDataPackageResourcesOnNode(nodeId).catch(
        () => [],
      );
      const uniqueDataPackageResourcesOnNode = dedup(
        config,
        (a) => a.config.id,
      );
      return uniqueDataPackageResourcesOnNode;
    }),
);

export const dataLayerAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<UploadedVectorDataLayer[]>>(async (get) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      if (!nodeId) return [];
      return await getDataLayerResourcesOnNode(nodeId).catch(() => []);
    }),
);

export const financialResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeFinancialAccess[]>>(async () => {
      if (!nodeId) return [];
      const config = await getFinancialResourcesOnNode(nodeId).catch(() => []);
      const uniqueFinancialResourcesOnNode = dedup(config, (a) => a.config.id);
      return uniqueFinancialResourcesOnNode;
    }),
);

export const nodesWithAccessToTurbine = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(async () =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_turbine_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToFoundation = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(async () =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_foundation_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToAnalysis = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(async () =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_analysis_manage",
      ).catch((_) => []),
    ),
);
export const nodesWithAccessToCable = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(() =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_cable_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToExportCable = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(() =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_export_cable_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToFinancial = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(() =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_financial_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToDataPackage = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(() =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_data_package_manage",
      ).catch(() => []),
    ),
);

export const nodesWithAccessToSubstation = atomFamily(
  ({
    organisationId,
    resourceId,
  }: {
    organisationId: string;
    resourceId: string;
  }) =>
    atomWithRefresh<Promise<NodeLibraryResource[]>>(() =>
      getNodesWithAccessToLibraryResource(
        organisationId,
        resourceId,
        "org_substation_manage",
      ).catch(() => []),
    ),
);

// ---------------- Resource managers -------------------------

export const turbineManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(organisationId, "org_turbine_manage").catch(
            (_) => [],
          )
        : getResourceManagers(organisationId, "org_turbine_manage").catch(
            (_) => [],
          ),
    ),
);

export const foundationManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(
            organisationId,
            "org_foundation_manage",
          ).catch((_) => [])
        : getResourceManagers(organisationId, "org_foundation_manage").catch(
            (_) => [],
          ),
    ),
);

export const analysisManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(organisationId, "org_analysis_manage").catch(
            (_) => [],
          )
        : getResourceManagers(organisationId, "org_analysis_manage").catch(
            (_) => [],
          ),
    ),
);

export const cableManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(organisationId, "org_cable_manage").catch(
            (_) => [],
          )
        : getResourceManagers(organisationId, "org_cable_manage").catch(
            (_) => [],
          ),
    ),
);

export const exportcableManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(
            organisationId,
            "org_export_cable_manage",
          ).catch((_) => [])
        : getResourceManagers(organisationId, "org_export_cable_manage").catch(
            (_) => [],
          ),
    ),
);

export const financialManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(
            organisationId,
            "org_financial_manage",
          ).catch((_) => [])
        : getResourceManagers(organisationId, "org_financial_manage").catch(
            (_) => [],
          ),
    ),
);

export const dataPackageManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(
            organisationId,
            "org_data_package_manage",
          ).catch((_) => [])
        : getResourceManagers(organisationId, "org_data_package_manage").catch(
            (_) => [],
          ),
    ),
);

export const substationManagersSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomWithRefresh<Promise<OrgResource[]>>(async (get) =>
      (await get(
        adminInOrganisationSelectorFamily({
          organisationId,
        }),
      ))
        ? getResourceManagersAdmin(
            organisationId,
            "org_substation_manage",
          ).catch((_) => [])
        : getResourceManagers(organisationId, "org_substation_manage").catch(
            (_) => [],
          ),
    ),
);

export const organisationSubstationResourceState = atomFamily(
  (organisationId: string) =>
    atomFromFn<Promise<SubstationType[]>>(async () => {
      try {
        const substations = await listOrgSubstations(organisationId);
        if (!substations) return [];
        return substations.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            sensitivity: "accent",
          }),
        );
      } catch (e) {
        console.error("Failed to fetch substations:", e);
        return [];
      }
    }),
);
