import React from "react";
import {
  AnalysisPrecision,
  AnalysisConfiguration,
  WAKE_MODEL_NAME,
  WakeModel,
} from "services/configurationService";
import { InputDimensioned, InputNumber } from "components/General/Input";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { Column } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import { isNumber } from "utils/predicates";
import { AIR_DENSITY } from "@constants/metocean";
import { Grid2, Label } from "components/General/Form";
import {
  HorizontalLine,
  SecondaryText,
  Subtitle,
  Text,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import HelpTooltip, {
  ARTICLE_AIR_DENSITY,
  ARTICLE_WAKE_MODELS,
  ARTICLE_YIELD_ASSESSMENT,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import Dropdown from "components/Dropdown/Dropdown";
import { ElementsContainer, Row } from "../shared";
import { between } from "utils/validations";
import { analysisChangelogInfo } from "components/InputChangelog/const";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";

const MAX_DISTANCE_NEIGHBOURS = 75;

const WakeSettingsTab = ({
  isReadOnly,
  configuration,
  localConfig,
  setLocalConfig,
  nodeId,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  configuration: AnalysisConfiguration;
  localConfig: AnalysisConfiguration;
  setLocalConfig: React.Dispatch<React.SetStateAction<AnalysisConfiguration>>;
  nodeId: string;
  isLibraryResource: boolean;
}) => {
  const density = isNumber(localConfig.wakeAnalysis.density)
    ? localConfig.wakeAnalysis.density
    : AIR_DENSITY;
  const originalDensity = isNumber(configuration.wakeAnalysis.density)
    ? configuration.wakeAnalysis.density
    : AIR_DENSITY;

  const changelogCategory = isLibraryResource
    ? "org_analysis_manage"
    : "project";

  return (
    <ElementsContainer>
      <TitleContainer>
        <Subtitle>General info</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label htmlFor="not-help-link">
          <Row>
            <SecondaryText>Wake model</SecondaryText>
            <HelpLink article={ARTICLE_WAKE_MODELS} style={{ padding: 0 }} />
          </Row>

          <ChangelogAndCommentWrapper
            changelogInfo={analysisChangelogInfo(
              localConfig.id,
              nodeId,
              "wakeAnalysis.wakeModel",
              changelogCategory,
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              disabled={isReadOnly}
              id="wake-model"
              value={localConfig.wakeAnalysis.wakeModel}
              onChange={(e) => {
                setLocalConfig((curr) => ({
                  ...curr,
                  wakeAnalysis: {
                    ...curr.wakeAnalysis,
                    wakeModel: e.target.value as WakeModel,
                  },
                }));
              }}
              style={{ width: "20rem" }}
            >
              <option key={"jensen"} value={"jensen"}>
                {WAKE_MODEL_NAME.jensen}
              </option>
              <option key={"turbopark"} value={"turbopark"}>
                {WAKE_MODEL_NAME.turbopark}
              </option>
            </Dropdown>
          </ChangelogAndCommentWrapper>
        </Label>

        {["jensen"].includes(localConfig.wakeAnalysis.wakeModel) && (
          <Label>
            <SecondaryText>Wake decay constant</SecondaryText>
            <InputNumber
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              blurOnEnter={false}
              value={localConfig.wakeAnalysis.wakeExpansionFactor}
              initialValue={configuration.wakeAnalysis.wakeExpansionFactor}
              validate={(n) => 0.01 <= n && n <= 0.2}
              validationMessage={`Needs to be within 0.01 - 0.2`}
              step={0.01}
              changelogInfo={analysisChangelogInfo(
                localConfig.id,
                nodeId,
                "wakeAnalysis.wakeExpansionFactor",
                changelogCategory,
              )}
              onChange={(n) => {
                setLocalConfig((curr) => ({
                  ...curr,
                  wakeAnalysis: {
                    ...curr.wakeAnalysis,
                    wakeExpansionFactor: n,
                  },
                }));
              }}
            />
          </Label>
        )}
        {["turbopark"].includes(localConfig.wakeAnalysis.wakeModel) && (
          <Label>
            <SecondaryText>Turbulence intensity</SecondaryText>
            <InputNumber
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              blurOnEnter={false}
              value={localConfig.wakeAnalysis.turbulenceIntensity}
              initialValue={configuration.wakeAnalysis.turbulenceIntensity}
              validate={(n) => 0.01 <= n && n <= 0.2}
              validationMessage={`Needs to be within 0.01 - 0.2`}
              step={0.01}
              changelogInfo={analysisChangelogInfo(
                localConfig.id,
                nodeId,
                "wakeAnalysis.turbulenceIntensity",
                changelogCategory,
              )}
              onChange={(n) => {
                setLocalConfig((curr) => ({
                  ...curr,
                  wakeAnalysis: {
                    ...curr.wakeAnalysis,
                    turbulenceIntensity: n,
                  },
                }));
              }}
            />
          </Label>
        )}
        {["turbopark"].includes(localConfig.wakeAnalysis.wakeModel) && (
          <Label>
            <SecondaryText>Calibration parameter (A)</SecondaryText>
            <InputNumber
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              blurOnEnter={false}
              value={localConfig.wakeAnalysis.turboparkWakeExpansionCalibration}
              initialValue={
                configuration.wakeAnalysis.turboparkWakeExpansionCalibration
              }
              validate={(n) => 0.01 <= n && n <= 0.1}
              validationMessage={`Needs to be within 0.01 - 0.1`}
              step={0.01}
              changelogInfo={analysisChangelogInfo(
                localConfig.id,
                nodeId,
                "wakeAnalysis.turboparkWakeExpansionCalibration",
                changelogCategory,
              )}
              onChange={(n) => {
                setLocalConfig((curr) => ({
                  ...curr,
                  wakeAnalysis: {
                    ...curr.wakeAnalysis,
                    turboparkWakeExpansionCalibration: n,
                  },
                }));
              }}
            />
          </Label>
        )}
        <Label htmlFor="not-help-link">
          <Column>
            <Row>
              <SecondaryText>Analysis precision</SecondaryText>
              <HelpLink
                article={ARTICLE_YIELD_ASSESSMENT}
                style={{ padding: 0 }}
              />
            </Row>
            <ChangelogAndCommentWrapper
              changelogInfo={analysisChangelogInfo(
                localConfig.id,
                nodeId,
                "wakeAnalysis.precision",
                changelogCategory,
              )}
              disabled={isReadOnly}
            >
              <Dropdown
                disabled={isReadOnly}
                id="analysis-precision"
                value={localConfig.wakeAnalysis.precision}
                onChange={(e) => {
                  setLocalConfig((curr) => ({
                    ...curr,
                    wakeAnalysis: {
                      ...curr.wakeAnalysis,
                      precision: e.target.value as AnalysisPrecision,
                    },
                  }));
                }}
                style={{ width: "20rem" }}
              >
                <option key={"fast"} value={"fast"}>
                  {"Fast"}
                </option>
                <option key={"default"} value={"default"}>
                  {"Default"}
                </option>
                <option key={"high"} value={"high"}>
                  {"High"}
                </option>
              </Dropdown>
            </ChangelogAndCommentWrapper>
          </Column>
        </Label>
      </div>

      <ChangelogAndCommentWrapper
        changelogInfo={analysisChangelogInfo(
          localConfig.id,
          nodeId,
          "wakeAnalysis.blockage",
          changelogCategory,
        )}
        disabled={isReadOnly}
      >
        <Row
          style={{
            width: "20rem",
            justifyContent: "left",
            marginTop: "2.4rem",
          }}
        >
          <Toggle
            checked={localConfig.wakeAnalysis.blockage}
            disabled={isReadOnly}
            onChange={() => {
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  blockage: !curr.wakeAnalysis.blockage,
                },
              }));
            }}
            size={ToggleSize.SMALL}
          />

          <Text>Include blockage</Text>
          <HelpLink article={ARTICLE_WAKE_MODELS} />
        </Row>
      </ChangelogAndCommentWrapper>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "left",
          gap: "2.4rem",
        }}
      >
        <ChangelogAndCommentWrapper
          changelogInfo={analysisChangelogInfo(
            localConfig.id,
            nodeId,
            "wakeAnalysis.neighbourWake",
            changelogCategory,
          )}
          disabled={isReadOnly}
        >
          <Row style={{ justifyContent: "left" }}>
            <Toggle
              checked={localConfig.wakeAnalysis.neighbourWake}
              disabled={isReadOnly}
              onChange={() =>
                setLocalConfig((curr) => ({
                  ...curr,
                  wakeAnalysis: {
                    ...curr.wakeAnalysis,
                    neighbourWake: !curr.wakeAnalysis.neighbourWake,
                  },
                }))
              }
              size={ToggleSize.SMALL}
            />
            <Text>Neighbour wake loss</Text>
            <HelpTooltip
              text="The maximum distance between the park and neighbour/existing turbines"
              size={10}
            />
          </Row>
        </ChangelogAndCommentWrapper>
        <Row>
          <InputDimensioned
            disabled={isReadOnly || !localConfig.wakeAnalysis.neighbourWake}
            validate={between(0, MAX_DISTANCE_NEIGHBOURS)}
            validationMessage={`Needs to be within 0 - ${MAX_DISTANCE_NEIGHBOURS}`}
            step="5"
            unit="km"
            type="number"
            value={localConfig.wakeAnalysis.neighbourWakeMaxDistance}
            changelogInfo={analysisChangelogInfo(
              localConfig.id,
              nodeId,
              "wakeAnalysis.neighbourWakeMaxDistance",
              changelogCategory,
            )}
            initialValue={configuration.wakeAnalysis.neighbourWakeMaxDistance}
            onChange={(val) =>
              setLocalConfig((curr) => ({
                ...curr,
                wakeAnalysis: {
                  ...curr.wakeAnalysis,
                  neighbourWakeMaxDistance: val,
                },
              }))
            }
            style={{ width: "12rem" }}
          />
        </Row>
      </Grid2>
      <Label htmlFor="not-help-link">
        <Column style={{ gap: "0.4rem", paddingTop: "2.4rem" }}>
          <Row>
            <SecondaryText>Air density</SecondaryText>
            <HelpLink article={ARTICLE_AIR_DENSITY} />
          </Row>
          <ChangelogAndCommentWrapper
            changelogInfo={analysisChangelogInfo(
              localConfig.id,
              nodeId,
              "wakeAnalysis.density",
              changelogCategory,
            )}
            disabled={isReadOnly}
          >
            <Row style={{ gap: "2.4rem" }}>
              <RadioGroup>
                <Radio
                  name="density_compensation"
                  value="mean_at_location"
                  disabled={isReadOnly}
                  checked={
                    localConfig.wakeAnalysis.density === "mean_at_location"
                  }
                  onChange={() =>
                    setLocalConfig((curr) => ({
                      ...curr,
                      wakeAnalysis: {
                        ...curr.wakeAnalysis,
                        density: "mean_at_location",
                      },
                    }))
                  }
                />
                <Text>Closest mean ERA5</Text>
              </RadioGroup>
              <RadioGroup>
                <Radio
                  name="density_compensation"
                  value=""
                  disabled={isReadOnly}
                  checked={isNumber(localConfig.wakeAnalysis.density)}
                  onChange={() =>
                    setLocalConfig((curr) => ({
                      ...curr,
                      wakeAnalysis: {
                        ...curr.wakeAnalysis,
                        density: density,
                      },
                    }))
                  }
                />
                <Text style={{ alignSelf: "center" }}>Custom</Text>
                <InputDimensioned
                  disabled={isReadOnly}
                  validate={between(0.7, 1.8)}
                  step="0.001"
                  unit="kg/m3"
                  type="number"
                  value={density}
                  initialValue={originalDensity}
                  onChange={(val) =>
                    setLocalConfig((curr) => ({
                      ...curr,
                      wakeAnalysis: {
                        ...curr.wakeAnalysis,
                        density: val,
                      },
                    }))
                  }
                  style={{ width: "12rem" }}
                />
              </RadioGroup>
            </Row>
          </ChangelogAndCommentWrapper>
        </Column>
      </Label>
    </ElementsContainer>
  );
};

export default WakeSettingsTab;
