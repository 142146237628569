import { getBranchId, getProjectId, getTurbines } from "analysis/inputs";
import { atom } from "jotai";
import { CostConfiguration, CostType } from "services/costService";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import {
  costConfigurationSelectedFamily,
  costConfigurationsFamily,
} from "state/jotai/costConfiguration";
import {
  operationsConfigurationSelectedFamily,
  operationsConfigurationsFamily,
} from "state/jotai/operationsConfiguration";
import { MaybePromise } from "types/utils";
import { atomFamily } from "utils/jotai";
import { turbineAmountSelectorFamily } from "./amounts/turbineAmounts";
import { cableAmountsSelectorFamily } from "./amounts/cableAmounts";
import { mooringAmountsFamily } from "./amounts/mooringAmounts";
import { substationAmountsSelectorFamily } from "./amounts/substationAmounts";
import { exportCableAmountsSelectorFamily } from "./amounts/exportCableAmounts";
import { foundationAmountFamily } from "./amounts/foundationAmounts";
import { otherAmountsFamily } from "./amounts/otherAmounts";
import { Amount } from "types/financial";
import { FinanceId, FinanceOverrideInput } from "./types";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { SimpleTurbineType } from "types/turbines";
import { isDefined } from "utils/predicates";
import { dedup } from "utils/utils";

export const financeOverrideInputFamily = atomFamily((_: FinanceId) =>
  atom<MaybePromise<FinanceOverrideInput>>(
    new Promise<FinanceOverrideInput>(() => {}),
  ),
);

export const getConfiguration = atomFamily((id: FinanceId) =>
  atom<Promise<CostConfiguration>>(async (get) => {
    const input = await get(financeOverrideInputFamily(id));
    const projectId = await get(getProjectId(id));

    if (input.costConfigurationId) {
      const costConfigurations = await get(
        costConfigurationsFamily({ projectId }),
      );
      const config = costConfigurations.get(input.costConfigurationId);
      if (!config) {
        throw new Error("Cost configuration not found.");
      }
      return config;
    }

    const branchId = await get(getBranchId(id));

    const config = await get(
      costConfigurationSelectedFamily({
        projectId,
        branchId,
      }),
    );

    if (!config) {
      throw new Error("Cost configuration not found.");
    }

    return config;
  }),
);

export const getOperationsConfiguration = atomFamily((id: FinanceId) =>
  atom<Promise<OperationsConfiguration>>(async (get) => {
    const input = await get(financeOverrideInputFamily(id));
    const projectId = await get(getProjectId(id));

    if (input.operationsConfigurationId) {
      const operationsConfigurations = await get(
        operationsConfigurationsFamily({
          projectId,
        }),
      );

      const config = operationsConfigurations.get(
        input.operationsConfigurationId,
      );
      if (!config) {
        throw new Error("Operations configuration not found.");
      }

      return config;
    }

    const branchId = await get(getBranchId(id));

    const config = await get(
      operationsConfigurationSelectedFamily({
        projectId,
        branchId,
      }),
    );

    if (!config) {
      throw new Error("Operations configuration not found.");
    }

    return config;
  }),
);

export const getRasterId = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    return (await get(financeOverrideInputFamily(id))).rasterId;
  }),
);

type Amounts = {
  [type in CostType]: Amount[];
};

export const getAmounts = atomFamily((id: FinanceId) =>
  atom<Promise<Amounts>>(async (get) => {
    const turbineAmounts = get(turbineAmountSelectorFamily(id));
    const cableAmounts = get(cableAmountsSelectorFamily(id));
    const mooringAmounts = get(mooringAmountsFamily(id));
    const substationAmounts = get(substationAmountsSelectorFamily(id));
    const exportCableAmounts = get(exportCableAmountsSelectorFamily(id));
    const foundationAmounts = get(foundationAmountFamily(id));
    const otherAmounts = get(otherAmountsFamily(id));

    return {
      [CostType.Turbine]: await turbineAmounts,
      [CostType.Cable]: await cableAmounts,
      [CostType.Mooring]: await mooringAmounts,
      [CostType.Substation]: await substationAmounts,
      [CostType.ExportCable]: await exportCableAmounts,
      [CostType.Foundation]: await foundationAmounts,
      [CostType.Other]: await otherAmounts,
    };
  }),
);
export const turbineTypesInParkFamily = atomFamily((id: FinanceId) =>
  atom<Promise<SimpleTurbineType[]>>(async (get) => {
    const turbines = await get(getTurbines(id));

    const allTurbineTypes = await get(simpleTurbineTypesAtom);

    const turbineTypeIdsInPark = dedup(
      turbines.map((t) => t.properties.turbineTypeId).filter(isDefined),
    );

    const turbineTypes: SimpleTurbineType[] = turbineTypeIdsInPark
      .map((id) => allTurbineTypes.get(id))
      .filter(isDefined);

    return turbineTypes;
  }),
);
