import workerUrl from "gdal3.js/dist/package/gdal3.js?url";
import dataUrl from "gdal3.js/dist/package/gdal3WebAssembly.data?url";
import wasmUrl from "gdal3.js/dist/package/gdal3WebAssembly.wasm?url";
import initGdalJs from "gdal3.js";
import { v4 as uuid4 } from "uuid";
import {
  Feature,
  FeatureCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from "geojson";
import { _Feature } from "./geojson/geojson";
import { fromBlob } from "geotiff";
import { Raster } from "types/raster";
import { GeoRefPoint } from "types/gis";
import { isDefined } from "./predicates";

const paths = {
  wasm: wasmUrl,
  data: dataUrl,
  js: workerUrl,
};

export const getSelfIntersectingPolygons = async <
  T extends Feature<Polygon | MultiPolygon>,
>(
  features: T[],
) => {
  const featureCollection: FeatureCollection<Polygon | MultiPolygon> = {
    type: "FeatureCollection",
    features: features,
  };
  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "GeoJSON",
    "-skipfailures",
  ]);
  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/vnd. application/geo+json",
  });

  const geojsonText = await blob.text();
  const validFeatures = JSON.parse(geojsonText) as FeatureCollection<
    Polygon | MultiPolygon
  >;

  const originalFeatureIds = features
    .map((f) => f.id)
    .filter(isDefined)
    .map((id) => id.toString());
  const validFeatureIds = validFeatures.features
    .map((f) => f.properties?.id)
    .filter(isDefined)
    .map((id) => id.toString());

  const invalidFeatureIds = originalFeatureIds.filter(
    (id) => !validFeatureIds.includes(id),
  );

  return invalidFeatureIds;
};

export const unkinkFeature = async <T extends Feature<Polygon | MultiPolygon>>(
  feature: T,
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features: [feature],
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, ["-f", "GeoJSON", "-makevalid"]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/vnd. application/geo+json",
  });

  const geojsonText = await blob.text();
  const unkinkedGeojson = JSON.parse(geojsonText) as FeatureCollection<
    Polygon | MultiPolygon
  >;
  return _Feature
    .array()
    .parse(
      unkinkedGeojson.features.map((f, i) =>
        i === 0
          ? { ...feature, ...f }
          : { ...feature, ...f, ...("id" in feature ? { id: uuid4() } : {}) },
      ),
    );
};

const polygonCoordsToWKT = (coordinates: Position[][]) =>
  coordinates[0].map((c) => c.join(" ")).join(", ");

const PolygonToWKT = (polygon: Polygon) => {
  return "POLYGON ((" + polygonCoordsToWKT(polygon.coordinates) + "))";
};

const MultiPolygonToWKT = (multiPolygon: MultiPolygon) => {
  const polygonWKTCoords = multiPolygon.coordinates.map(polygonCoordsToWKT);
  return "MULTIPOLYGON (((" + polygonWKTCoords.join(")),((") + ")))";
};

export const intersectFeatures = async <
  T extends Feature<Polygon | MultiPolygon>,
>(
  featureToIntersect: T,
  features: Feature<
    Polygon | MultiPolygon | LineString | MultiLineString | Point | MultiPoint
  >[],
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features: features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const wktPolygon =
    featureToIntersect.geometry.type === "Polygon"
      ? PolygonToWKT(featureToIntersect.geometry as Polygon)
      : MultiPolygonToWKT(featureToIntersect.geometry as MultiPolygon);

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "GeoJSON",
    "-clipsrc",
    wktPolygon,
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/vnd. application/geo+json",
  });

  const geojsonText = await blob.text();
  const clippedGeojson = JSON.parse(geojsonText) as FeatureCollection<
    Polygon | MultiPolygon
  >;
  return _Feature.array().parse(clippedGeojson.features);
};

export const geotiffToRaster = async (geotiffFile: File) => {
  const Gdal = await initGdalJs({ paths });

  const result = await Gdal.open(geotiffFile);
  const tiffData = result.datasets[0];

  const output = await Gdal.gdalwarp(tiffData, [
    "-of",
    "GTiff",
    "-t_srs",
    "EPSG:4326",
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "image/tiff",
  });

  const geotiff = await fromBlob(blob);
  const image = await geotiff.getImage();
  const [xMin, yMin, xMax, yMax] = image.getBoundingBox();
  const height = image.getHeight();
  const width = image.getWidth();
  const values = (await image.readRasters())[0] as Float32Array;

  const sizeLatitude = yMax - yMin;
  const sizeLongitude = xMax - xMin;
  const stepLat = sizeLatitude / height;
  const stepLon = sizeLongitude / width;

  return new Raster(
    Array.from(values),
    width,
    height,
    xMin,
    yMax,
    stepLon,
    stepLat,
  );
};

export const georefImage = async (
  imageToGeoref: File,
  coords: GeoRefPoint[],
) => {
  const Gdal = await initGdalJs({ paths });

  const result = await Gdal.open(imageToGeoref);
  const tiffData = result.datasets[0];

  const cmd = [
    "-of",
    "GTiff",
    "-co",
    "COMPRESS=LZW",
    ...coords
      .map((c) => [
        "-gcp",
        `${c.imgCoords.x}`,
        `${c.imgCoords.y}`,
        `${c.mapCoords?.lng}`,
        `${c.mapCoords?.lat}`,
      ])
      .flat(),
  ];

  const output = await Gdal.gdal_translate(tiffData, cmd);

  const bytes = await Gdal.getFileBytes(output);
  const geotiffBlob = new Blob([bytes], {
    type: "image/tiff",
  });

  return geotiffBlob;
};

export const compressGeotiffImage = async (
  imageToGeoref: File,
  tr: [number, number],
) => {
  const Gdal = await initGdalJs({ paths });

  const result = await Gdal.open(imageToGeoref);
  const tiffData = result.datasets[0];

  const cmd = [
    "-of",
    "GTiff",
    "-co",
    "COMPRESS=LZW",
    "-tr",
    `${tr[0]}`,
    `${tr[1]}`,
  ];

  const output = await Gdal.gdal_translate(tiffData, cmd);

  const bytes = await Gdal.getFileBytes(output);
  const geotiffBlob = new Blob([bytes], {
    type: "image/tiff",
  });

  return geotiffBlob;
};
