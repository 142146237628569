import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { z } from "zod";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import { _FoundationType } from "types/foundations";
import { ABLY_FOUNDATION_TYPE_DELETE } from "state/ably";
import { ABLY_FOUNDATION_TYPE_UPDATE } from "state/ably";

export function useAblyLibraryFoundation(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgFoundationCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_foundation_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const foundation = _FoundationType.parse(message.data.meta);
      updateLocal(foundation);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const foundationId = z.string().parse(message.data.meta);
      removeLocal(foundationId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_FOUNDATION_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_FOUNDATION_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
