import { unwrap } from "jotai/utils";
import { useRefreshLibraryFoundation } from "components/ConfigurationModal/useRefreshCustomFoundations";
import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Label } from "components/General/Form";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { TextInput } from "components/General/Input";
import { Column } from "components/General/Layout";
import { SkeletonText } from "components/Loading/Skeleton";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useBathymetry } from "hooks/bathymetry";
import { useClickOutside } from "hooks/useClickOutside";
import useTextInput from "hooks/useTextInput";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { DefaultMap } from "lib/DefaultMap";
import { useCallback, useMemo, useRef, useState } from "react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  defaultFoundations,
  newFoundationNodeId,
  showNewFoundationWizardAtom,
} from "state/foundations";
import { parksFamily } from "state/jotai/park";
import { selectedTurbinesAtom } from "state/jotai/selection";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { inPark } from "state/park";
import { projectIdAtom } from "state/pathParams";
import {
  getAvailableWaveSourcesSelectorFamily,
  getWaveStatsSelectorFamily,
} from "state/waveStatistics";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { spaceLarge, spaceMedium } from "styles/space";
import { ParkFeature } from "types/feature";
import {
  defaultDetailedJacketParameters,
  defaultDetailedMonopileParameters,
  FoundationType,
  FoundationTypeIds,
} from "types/foundations";
import { Raster } from "types/raster";
import { atomFamily } from "utils/jotai";
import { getParkCenter } from "utils/parkUtils";
import { scream } from "utils/sentry";
import { fastMin, maxBy } from "utils/utils";
import { JacketWizard } from "./JacketWizard";
import { MonopileWizard } from "./MonopileWizard";
import { Anchor } from "components/General/Anchor";
import Tooltip from "components/General/Tooltip";
import { useProjectFoundationCrud } from "components/ConfigurationModal/FoundationSettings/useProjectFoundationCrud";

const FoundationSubMenuWrapperOrg = styled(StandardBox)`
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 3rem;
  right: 0;
  background-color: white;
  z-index: 2;
  width: 30rem;
`;

export const CreateFoundationMenuOrganisation = ({
  onCreate,
  setSaveInProgess,
}: {
  onCreate: (foundation: FoundationType) => void;
  setSaveInProgess?: (saveInProgress: boolean) => void;
}) => {
  const { create } = useOrgFoundationCrud();

  const refresh = useRefreshLibraryFoundation();

  const _onSave = useCallback(
    async (newFoundationType: FoundationTypeIds, name: string) => {
      const baseFoundation = defaultFoundations.find(
        (f) => f.type === newFoundationType,
      );
      if (!baseFoundation) return;
      const newFoundation = {
        ...baseFoundation,
      };
      if (setSaveInProgess) setSaveInProgess(true);
      return create({
        name,
        foundation: newFoundation,
      })
        .then((res) => {
          if (res) {
            refresh();
            if (setSaveInProgess) setSaveInProgess(false);
            onCreate(res);
          }
        })
        .catch((e) => {
          scream("Failed to create new foundation", e);
          throw Error("Could not create a foundation with the selected input.");
        });
    },
    [create, onCreate, refresh, setSaveInProgess],
  );

  return (
    <FoundationSubMenuWrapperOrg>
      <CreateFoundationMenu onSave={_onSave} inProject={false} />
    </FoundationSubMenuWrapperOrg>
  );
};

const CreateFoundationMenuProject = () => {
  const foundationTypeNodeId = useAtomValue(newFoundationNodeId);

  const { create } = useProjectFoundationCrud();

  const _onCreate = useCallback(
    async (newFoundationType: FoundationTypeIds, name: string) => {
      const baseFoundation = defaultFoundations.find(
        (f) => f.type === newFoundationType,
      );
      if (!foundationTypeNodeId || !baseFoundation) return;
      return create({
        name,
        foundation: {
          ...baseFoundation,
        },
      });
    },
    [create, foundationTypeNodeId],
  );

  return <CreateFoundationMenu onSave={_onCreate} inProject={true} />;
};

export const FoundationWizardModal = ({
  isLoading,
  disabled,
  tooltipText,
}: {
  isLoading: boolean;
  disabled?: boolean;
  tooltipText?: string | undefined;
}) => {
  const [showNewFoundationWizard, setShowNewFoundationWizard] = useAtom(
    showNewFoundationWizardAtom,
  );
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const setNewFoundationNodeId = useSetAtom(newFoundationNodeId);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Tooltip text={tooltipText ?? ""} disabled={!tooltipText}>
        <Button
          buttonType="primary"
          onClick={() => {
            setShowNewFoundationWizard(true);
            setNewFoundationNodeId(projectId);
          }}
          icon={<AddIcon />}
          disabled={disabled || isLoading}
        />
        {showNewFoundationWizard && (
          <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
            <CreateFoundationMenuProject />
          </Anchor>
        )}
      </Tooltip>
    </div>
  );
};

const CreateFoundationMenu = ({
  onSave,
  inProject,
}: {
  onSave: (newFoundationType: FoundationTypeIds, name: string) => Promise<any>;
  inProject: boolean;
}) => {
  const setShowNewFoundationWizard = useSetAtom(showNewFoundationWizardAtom);
  const [nameValue, onNameValueChange] = useTextInput("New foundation");

  const [foundationType, setFoundationType] = useState<FoundationTypeIds>(
    FoundationTypeIds.SemiCentralType,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const _onSave = useCallback(
    async (newFoundationType: FoundationTypeIds, name: string) => {
      setIsLoading(true);
      await onSave(newFoundationType, name);
      setIsLoading(false);
      setShowNewFoundationWizard(false);
    },
    [onSave, setShowNewFoundationWizard],
  );

  useClickOutside(ref, () => setShowNewFoundationWizard(false));

  return (
    <MenuFrame
      title="Create new foundation"
      onExit={() => {
        setShowNewFoundationWizard(false);
      }}
      id="create-foundation-submenu"
      ref={ref}
      style={{
        overflowY: "auto",
        width: "38rem",
      }}
    >
      <Label>
        <InputTitle>Name</InputTitle>
        <TextInput autoFocus value={nameValue} onChange={onNameValueChange} />
        <InputTitle>Foundation type</InputTitle>
        <Dropdown
          small
          id="foundation-type"
          value={foundationType}
          onChange={(e) => {
            setFoundationType(e.target.value as FoundationTypeIds);
          }}
        >
          <option value={FoundationTypeIds.SemiCentralType}>
            {"Semi, central turbine"}
          </option>
          <option value={FoundationTypeIds.SemiPeripheralType}>
            {"Semi, offset turbine"}
          </option>
          <option value={FoundationTypeIds.SparType}>{"Spar"}</option>
          <option value={FoundationTypeIds.JacketType}>
            {"Simple jacket"}
          </option>
          <option value={FoundationTypeIds.MonopileType}>
            {"Simple monopile"}
          </option>
          {inProject && (
            <option value={FoundationTypeIds.DetailedMonopileType}>
              {"Site-specific monopile wizard..."}
            </option>
          )}
          {inProject && (
            <option value={FoundationTypeIds.DetailedJacketType}>
              {"Site-specific jacket wizard..."}
            </option>
          )}
        </Dropdown>
      </Label>
      {(foundationType === FoundationTypeIds.DetailedMonopileType ||
        foundationType === FoundationTypeIds.DetailedJacketType) && (
        <SiteSpecificWizard foundationType={foundationType} name={nameValue} />
      )}
      {foundationType !== FoundationTypeIds.DetailedMonopileType &&
        foundationType !== FoundationTypeIds.DetailedJacketType && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "baseline",
              gap: spaceMedium,
              paddingTop: spaceLarge,
            }}
          >
            <Button
              disabled={isLoading}
              style={{
                alignSelf: "end",
              }}
              text="Create"
              onClick={() => {
                _onSave(foundationType, nameValue);
              }}
            />
          </div>
        )}
    </MenuFrame>
  );
};

const SiteSpecificWizard = ({
  foundationType,
  name,
}: {
  foundationType: FoundationTypeIds;
  name: string;
}) => {
  const parks = useAtomValue(
    parksFamily({
      branchId: undefined,
    }),
  );
  const [park, setPark] = useState<undefined | ParkFeature>();

  const items: DropDownItem[] = useMemo(() => {
    return parks.map((park) => {
      return {
        value: park.id,
        name: park.properties.name ?? "unnamed park",
      };
    });
  }, [parks]);

  const [, raster] = useBathymetry({
    featureId: park?.id ?? "",
    projectId: undefined,
    branchId: undefined,
    bufferKm: undefined,
  });

  return (
    <>
      <Label
        style={{
          paddingTop: "1.6rem",
        }}
      >
        <InputTitle>Park</InputTitle>
        <Dropdown
          small
          value={park?.id}
          onChange={(e) => {
            const park = parks.find((p) => p.id === e.target.value);
            if (!park)
              throw scream("Selected impossible park", {
                parks,
                e,
              });
            setPark?.(park);
          }}
          defaultValue=""
        >
          <option value="" disabled>
            Select park
          </option>
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </Dropdown>
      </Label>
      {park && !raster && (
        <Column
          style={{
            marginTop: "0.6rem",
            marginBottom: "0.6rem",
          }}
        >
          <SkeletonText text="Loading bathymetry" />
        </Column>
      )}
      {park && raster && (
        <SiteSpecificWizardInner
          park={park}
          raster={raster}
          foundationType={foundationType}
          name={name}
        />
      )}
    </>
  );
};

const mostCommonTurbineType = atomFamily(({ parkId }: { parkId: string }) =>
  atom<Promise<string | undefined>>(async (get) => {
    const turbines = (await get(selectedTurbinesAtom)).filter(inPark(parkId));
    const turbineTypes = await get(simpleTurbineTypesAtom);
    const counts = new DefaultMap<string, number>(() => 0);
    for (const t of turbines) {
      const typ = turbineTypes.get(t.properties.turbineTypeId);
      if (!typ) continue;
      counts.update(typ.id, (c) => c + 1);
    }
    const res = maxBy(
      Array.from(counts.inner.entries()),
      ([_, count]) => count,
    );
    return res?.[0];
  }),
);

const SiteSpecificWizardInner = ({
  park,
  raster,
  foundationType,
  name,
}: {
  park: ParkFeature;
  raster: Raster;
  foundationType: FoundationTypeIds;
  name: string;
}) => {
  const currentTurbineTypeId = useAtomValue(
    mostCommonTurbineType({
      parkId: park.id,
    }),
  );

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbineType =
    allTurbineTypes.get(currentTurbineTypeId ?? "") ??
    Array.from(allTurbineTypes.values())[0];

  const parkCenter = getParkCenter(park, []);
  const waveSources = useAtomValue(
    unwrap(
      getAvailableWaveSourcesSelectorFamily({
        parkCenter,
      }),
    ),
  );
  const waveStats = useAtomValue(
    unwrap(
      getWaveStatsSelectorFamily({
        parkCenter,
      }),
    ),
  );

  const parkMaxDepth = -fastMin(raster.values);

  if (waveSources && waveSources.length === 0) {
    return (
      <SimpleAlert
        text={"Wave data not available at the selected location."}
        type={"error"}
      ></SimpleAlert>
    );
  }

  if (!waveStats) {
    return (
      <Column
        style={{
          marginTop: "0.6rem",
          marginBottom: "0.6rem",
        }}
      >
        <SkeletonText text="Loading wave data" />
      </Column>
    );
  }

  if (foundationType === FoundationTypeIds.DetailedMonopileType) {
    const defaultMonopile = defaultDetailedMonopileParameters(
      name,
      park,
      turbineType,
      parkMaxDepth,
      waveStats,
    );
    return (
      <MonopileWizard
        defaultMonopile={defaultMonopile}
        turbineType={turbineType}
        parkMaxDepth={parkMaxDepth}
      />
    );
  } else if (foundationType === FoundationTypeIds.DetailedJacketType) {
    const defaultJacket = defaultDetailedJacketParameters(
      name,
      park,
      turbineType,
      parkMaxDepth,
      waveStats,
    );

    return (
      <JacketWizard
        defaultJacket={defaultJacket}
        turbineType={turbineType}
        parkMaxDepth={parkMaxDepth}
      />
    );
  }
  return null;
};
