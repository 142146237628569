import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { ValidationWarning } from "state/validationWarnings";
import { atom } from "jotai";
import { parksFamily } from "state/jotai/park";

export const overlappingParksWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.OverlappingParks> | undefined
      >
    >(async (get) => {
      const parks = await get(parksFamily({ branchId }));
      const overlapIds: string[] = [];
      const BUFFER_DISTANCE = -10; // Check for overlap after shrinking the parks by 10 meters
      for (let i = 0; i < parks.length; i++) {
        const a = parks[i];
        const bufferedA = turf.buffer(a, BUFFER_DISTANCE, { units: "meters" });

        for (let j = i + 1; j < parks.length; j++) {
          const b = parks[j];
          const bufferedB = turf.buffer(b, BUFFER_DISTANCE, {
            units: "meters",
          });

          const result =
            bufferedA && bufferedB && turf.intersect(bufferedA, bufferedB);
          if (result) overlapIds.push(a.id, b.id);
        }
      }

      if (overlapIds.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.OverlappingParks,
        featureIds: overlapIds,
      };
    }),
);
