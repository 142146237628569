import React, { useMemo } from "react";
import styled from "styled-components";
import { ParkFeature } from "../../types/feature";
import { getBBOXArrayFromFeatures } from "../../utils/geojson/validate";
import { ElementsToIcon } from "../ElementsToIcon/ElementsToIcon";
import {
  mapboxAccessToken,
  mapboxAccount,
  mapboxStyleId,
} from "../MapNative/constants";
import { ProjectFeature } from "../../types/feature";
import { useAtomValue } from "jotai";
import { featuresInParkFamily } from "state/jotai/features";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ParkBackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ElementWrapper = styled.div`
  z-index: 1;
  position: relative;
  opacity: 0.8;
`;

const ParkWithChildrenImage = ({
  park,
  branchId,
  mapboxImageSize,
  wrapperStyle,
  style,
  noExportCable,
  onImageLoad,
}: {
  park: ParkFeature;
  branchId: string;
  mapboxImageSize: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  noExportCable?: boolean;
  onImageLoad?(): void;
}) => {
  const projectFeatures = useAtomValue(
    featuresInParkFamily({ parkId: park.id, branchId }),
  );

  const parkAndChildren = useMemo(() => {
    const features: ProjectFeature[] = ([] as ProjectFeature[])
      .concat(projectFeatures.turbine)
      .concat(projectFeatures.cable)
      .concat(projectFeatures.substation)
      .concat(projectFeatures.cableCorridor)
      .concat(projectFeatures.anchor)
      .concat(projectFeatures.mooringLine)
      .concat(projectFeatures.subArea);
    if (!noExportCable) features.push(...projectFeatures.exportCable);
    return [park, ...features];
  }, [noExportCable, park, projectFeatures]);

  const mapboxUrl = useMemo(() => {
    const bbox = getBBOXArrayFromFeatures(parkAndChildren);
    return `https://api.mapbox.com/styles/v1/${mapboxAccount}/${mapboxStyleId}/static/[${bbox}]/${mapboxImageSize}?access_token=${mapboxAccessToken}`;
  }, [mapboxImageSize, parkAndChildren]);

  return (
    <Wrapper style={wrapperStyle}>
      <ElementWrapper>
        <ElementsToIcon
          elements={parkAndChildren}
          resolution="800"
          style={style}
          fillPolygons={true}
          fillParks={false}
        />
      </ElementWrapper>
      <ParkBackgroundImage
        src={mapboxUrl}
        referrerPolicy="origin"
        onLoad={onImageLoad}
      />
    </Wrapper>
  );
};

export default React.memo(ParkWithChildrenImage);
