import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
} from "components/Organisation/Library/service";
import {
  analysisResourceWithAccessOnNodeState,
  nodesWithAccessToAnalysis,
} from "components/Organisation/Library/state";
import { AnalysisConfiguration } from "services/configurationService";
import { userNodesAccessSyncAtom } from "state/user";
import { RESET } from "jotai/utils";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeAnalysisResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, config: AnalysisConfiguration) => {
      const fallback = await get(
        analysisResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const allNodeIds = get(userNodesAccessSyncAtom).data;
      return addOrUpdateResourceOnNode(nodeId, config.id, "org_analysis_manage")
        .then(() => {
          Object.keys(allNodeIds.node_access).map((nodeId) =>
            set(
              analysisResourceWithAccessOnNodeState({
                nodeId,
              }),
              RESET,
            ),
          );
          set(
            nodesWithAccessToAnalysis({
              organisationId,
              resourceId: config.id,
            }),
          );
        })
        .catch((e) => {
          aset(
            get,
            set,
            analysisResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        analysisResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "ANALYSIS_CONFIGURATION",
          resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          nodeId,
          "ANALYSIS_CONFIGURATION",
          resourceId,
        );
      }
      const userAccess = get(userNodesAccessSyncAtom).data;

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Analysis is currently being used in ${usage.length} projects, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(nodeId, resourceId, "org_analysis_manage")
          .then(() => {
            set(
              nodesWithAccessToAnalysis({
                organisationId,
                resourceId,
              }),
            );
            Object.keys(userAccess.node_access).map((nodeId) =>
              set(
                analysisResourceWithAccessOnNodeState({
                  nodeId,
                }),
                RESET,
              ),
            );
          })
          .catch((e) => {
            aset(
              get,
              set,
              analysisResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeAnalysisResourcesCrud;
