import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useEffect, useState, useCallback, useMemo, Suspense } from "react";
import { AnalysisConfiguration } from "../../services/configurationService";
import { savingConfigurationInProgressAtom } from "../../state/configuration";
import Button from "../General/Button";
import { inReadOnlyModeSelector } from "../../state/project";
import {
  ContentWrapper,
  ModalContainer,
  SettingButton,
} from "../SettingsV2/Shared/styles";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import useEnterToSave from "./useEnterToSave";
import Tabs from "components/General/Tabs";
import WakeSettingsTab from "./AnalysisSettings/WakeSettingsTab";
import ElectricalSettingsTab from "./AnalysisSettings/ElectricalSettingsTab";
import CustomLossesTab from "./AnalysisSettings/CustomLossesTab";
import { ContentContainer, HeaderContainer, HeaderWrapper } from "./shared";
import { EditableText } from "components/General/EditableText";
import { useSetAtom } from "jotai";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { useDuplicateAnalysisToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { orgAnalysisManageAccessSelector } from "state/user";
import { Column, Row } from "components/General/Layout";
import ComponentLastChanged from "./SettingsUsage/ComponentLastChanged";
import { idToAnalysisChangelogId } from "components/InputChangelog/const";
import { AnalysisProjects } from "components/Organisation/OrganisationRightSide/content/ResourceContent/availability/AnalysisProjects";
import DescriptionModal from "./DescriptionModal";
import LibraryResourceActions from "./Components/LibraryResourceActions";
import ProjectResourceUsage from "./SettingsUsage/ProjectResourceUsage";
import usePrevious from "hooks/usePrevious";
import { mergePreservingChanges, objectEquals } from "utils/utils";

export const AnalysisSettingsInner = ({
  configuration,
  save,
  readOnly,
  isLibraryResource,
  nodeId,
}: {
  configuration: AnalysisConfiguration;
  save?: (
    config: AnalysisConfiguration,
    skipUsageCheck?: boolean,
  ) => Promise<any>;
  readOnly?: boolean;
  isLibraryResource: boolean;
  nodeId: string;
}) => {
  const { duplicateToProject, isLoading: isLoadingDuplicate } =
    useDuplicateAnalysisToProject();

  const orgAnalysisManageAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;
  const isSaving = useAtomValue(savingConfigurationInProgressAtom);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector) || readOnly;

  const prevConfiguration = usePrevious(configuration);
  const [localConfig, setLocalConfig] = useState(configuration);
  useEffect(() => {
    if (!prevConfiguration) {
      setLocalConfig(configuration);
    } else {
      setLocalConfig((currentLocalConfig) =>
        mergePreservingChanges(
          prevConfiguration,
          configuration,
          currentLocalConfig,
        ),
      );
    }
  }, [configuration, prevConfiguration]);

  const hasChanged = useMemo(() => {
    if (!configuration || !localConfig) return false;
    return !objectEquals(configuration, localConfig);
  }, [configuration, localConfig]);

  const isLoading = localConfig.id !== configuration.id;
  const allChangesSaved = isReadOnly || !hasChanged;

  const setUnsavedSettings = useSetAtom(unsavedSettingsState);

  useEffect(() => {
    setUnsavedSettings(!isLoading && !allChangesSaved);
  }, [allChangesSaved, isLoading, setUnsavedSettings]);

  const onEnterSaveCallback = useCallback(() => {
    if (!save) return;
    save(localConfig);
  }, [localConfig, save]);
  useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

  const renameConfig = useCallback(() => {
    if (!save) return;
    save(
      {
        ...configuration,
        name: localConfig.name,
      },
      true,
    );
  }, [configuration, save, localConfig]);

  const tabs = useMemo(() => {
    return [
      {
        name: "Wake settings",
        data: (
          <WakeSettingsTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            localConfig={localConfig}
            configuration={configuration}
            nodeId={nodeId}
            isLibraryResource={isLibraryResource}
          />
        ),
      },
      {
        name: "Electrical settings",
        data: (
          <ElectricalSettingsTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            configuration={configuration}
            localConfig={localConfig}
            nodeId={nodeId}
            isLibraryResource={isLibraryResource}
          />
        ),
      },
      {
        name: "Custom losses",
        data: (
          <CustomLossesTab
            isReadOnly={isReadOnly}
            setLocalConfig={setLocalConfig}
            configuration={configuration}
            localConfig={localConfig}
            nodeId={nodeId}
            isLibraryResource={isLibraryResource}
          />
        ),
      },
      ...(inLibraryContext
        ? [
            {
              name: "Usage and availability",
              data: (
                <Suspense fallback={<TablePlaceholder />}>
                  <AnalysisProjects resource={configuration} />
                </Suspense>
              ),
            },
          ]
        : []),
    ];
  }, [
    isLibraryResource,
    inLibraryContext,
    isReadOnly,
    localConfig,
    nodeId,
    configuration,
  ]);

  const handleUpdateAnalysisDescription = useCallback(
    (description: string) => {
      if (configuration && save) {
        save(
          {
            ...configuration,
            description: description,
          },
          true,
        );
      }
    },
    [configuration, save],
  );

  return (
    <ModalContainer>
      {!inLibraryContext && (
        <HeaderWrapper>
          <HeaderContainer>
            <Column
              style={{
                width: "100%",
              }}
            >
              <EditableText
                type="text"
                smallInput={true}
                value={localConfig.name}
                onChange={(e) => {
                  setLocalConfig((currentLocalConfig) => ({
                    ...currentLocalConfig,
                    name: e.target.value,
                  }));
                }}
                onEnter={renameConfig}
                onCancel={() => {
                  setLocalConfig((currentLocalConfig) => ({
                    ...currentLocalConfig,
                    name: configuration.name,
                  }));
                }}
                textContainerStyle={{
                  maxWidth: "20vw",
                  padding: 0,
                }}
                renderText={(title) => (
                  <h3
                    style={{
                      margin: 0,
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "60rem",
                    }}
                    title={title}
                  >
                    {title}
                  </h3>
                )}
                disabled={isReadOnly}
              />
              <Row
                style={{
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <ComponentLastChanged
                  resourceId={configuration.id}
                  changelogId={idToAnalysisChangelogId(configuration.id)}
                  nodeId={nodeId}
                  category={projectId ? "project" : "org_analysis_manage"}
                />
                <ProjectResourceUsage
                  resourceType="ANALYSIS_CONFIGURATION"
                  resourceId={configuration.id}
                />
              </Row>
            </Column>
            <DescriptionModal
              disabled={isReadOnly}
              defaultValue={localConfig.description}
              updateDescription={handleUpdateAnalysisDescription}
              subtitle={
                <div>
                  <p>
                    The description will be visible for Admins and Editors in
                    projects with access to this configuration.
                  </p>
                </div>
              }
            />
          </HeaderContainer>

          <SettingButton
            style={{
              justifyContent: "flex-end",
              position: "absolute",
              right: "2.4rem",
              top: "14.4rem",
            }}
          >
            {readOnly && isLibraryResource && orgAnalysisManageAccess && (
              <LibraryResourceActions
                resourceType="analysis"
                resourceId={configuration.id}
                nodeId={nodeId}
                organisationId={nodeId}
                onDuplicate={() => duplicateToProject(configuration)}
                isLoadingDuplicate={isLoadingDuplicate}
              />
            )}
            {!allChangesSaved && !readOnly && save && (
              <>
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Cancel"
                  buttonType="text"
                  onClick={() => {
                    setLocalConfig(configuration);
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                />
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Save changes"
                  onClick={() => {
                    save(localConfig);
                  }}
                />
              </>
            )}
          </SettingButton>
        </HeaderWrapper>
      )}
      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 8rem)",
          boxSizing: "border-box",
          paddingBottom: 0,
          padding: inLibraryContext ? 0 : "1.6rem 2.4rem",
        }}
      >
        <ContentContainer
          style={{
            maxHeight: "100%",
            boxSizing: "border-box",
          }}
        >
          <Tabs
            tabs={tabs}
            initialTab={0}
            menuStyle={{
              justifyContent: "space-between",
              gap: "5rem",
            }}
            contentWrapperStyle={{
              minHeight: "40vh",
              overflow: "auto",
              height: "100%",
            }}
            buttonSection={
              <SettingButton>
                {inLibraryContext && !allChangesSaved && !readOnly && save && (
                  <>
                    <Button
                      disabled={isLoading || isSaving || allChangesSaved}
                      text="Cancel"
                      buttonType="text"
                      onClick={() => {
                        setLocalConfig(configuration);
                      }}
                      style={{
                        marginLeft: "auto",
                      }}
                    />
                    <Button
                      disabled={isLoading || isSaving || allChangesSaved}
                      text="Save changes"
                      onClick={() => {
                        save(localConfig);
                      }}
                    />
                  </>
                )}
              </SettingButton>
            }
          />
        </ContentContainer>
      </ContentWrapper>
    </ModalContainer>
  );
};
