import { mapAtom } from "state/map";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as turf from "@turf/turf";
import Bin from "@icons/24/Bin.svg?react";
import { FillPaint, LinePaint, Map, SymbolLayer } from "mapbox-gl";
import { BBox, Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { MultiPolygon } from "geojson";
import MapPolygon from "../../../MapFeatures/Polygon";
import { generateHSLGradientColors } from "../../../Cabling/CablingMapController/utils";
import { ColoredGrid } from "../../../General/Form";
import { IconREMSize, typography } from "../../../../styles/typography";
import { SkeletonText, orLoader } from "../../../Loading/Skeleton";
import { Column, Row } from "../../../General/Layout";
import { depthThresholdLayerId } from "../../../../constants/bathymetry";
import styled from "styled-components";
import DynamicSelectOption from "../../../DynamicSelectOption/DynamicSelectOption";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ExternalSelectionItem } from "../../../../state/externalLayerSelection";
import { getRasterStats } from "../../../Bathymetry/useGetRasterStats";
import { MapColorIndicator } from "../../../General/MapColorIndicator";
import { RangeSlider } from "../../../General/Slider";
import Button from "../../../General/Button";
import { spaceMedium, spaceSmall, spaceTiny } from "../../../../styles/space";
import { zip } from "../../../../utils/utils";
import { colors } from "../../../../styles/colors";
import { scream } from "../../../../utils/sentry";
import { ProjectFeature } from "../../../../types/feature";
import { displayLabelPropertyName } from "../../../../constants/canvas";
import { z } from "zod";
import { useBathymetry } from "hooks/bathymetry";
import { TopRightModeActiveAtom } from "./state";
import { AddAnalysisButton } from "./BathymetryAnalysis";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import { isDefined, isNumber } from "utils/predicates";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  OverflowEllipsis,
  OverlineText,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import DownloadIcon from "@icons/24/Download.svg?react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { bathymetryFamily } from "state/bathymetry";
import { bathymetryFeatureFamily } from "state/jotai/bathymetry";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { WithTooltip } from "components/General/Tooltip";

export const bathymetryDefaultName = "Unnamed bathymetry";

type ContourFeatures = {
  properties: {
    depth: number;
  };
  type: "Feature";
  geometry: MultiPolygon | Polygon;
  id?: string | number | undefined;
  bbox?: BBox | undefined;
}[];

const depthThresholdSourceId = "depth-threshold-source-id";

const depthAnalysisSymbols: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "[{depths}) m",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": 0.6,
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const depthPolygonPaint: FillPaint = {
  "fill-color": ["string", ["get", "color"], "#0051c2"],
  "fill-opacity": 0.5,
};

const depthPolygonLinePaint: LinePaint = {
  "line-opacity": 1,
};

export const SourceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const ErrorTextWrapper = styled.div`
  ${typography.h4}
  color: ${colors.errorText}
`;

export const getFileName = (filePath: string) =>
  filePath.split("/").slice(-1)[0];

const depthRangesAtomFamily = atomFamily((projectId: string) =>
  atomLocalStorage(
    `vind:depth-analysis:ranges:${projectId}`,
    [],
    z.tuple([z.number(), z.number()]).array(),
  ),
);

const currentDepthRangeAtom = atomLocalStorage<undefined | [number, number]>(
  "vind:depth-analysis-range",
  undefined,
  z.tuple([z.number(), z.number()]).optional(),
);

const DepthAnalysisInner = ({
  canvasFeature,
  bathymetryId,
}: {
  canvasFeature: ProjectFeature<Polygon>;
  bathymetryId: string;
}) => {
  const polygonArea = useMemo(() => {
    return Math.round(turf.area(canvasFeature) / (1000 * 1000));
  }, [canvasFeature]);

  const [contourFeatureCollection, setContourFeatureCollection] = useState<
    | FeatureCollection<
        Geometry,
        {
          depth: number;
        }
      >
    | undefined
  >();
  const map = useAtomValue(mapAtom) as Map | undefined;

  const [depthRanges, setDepthRanges] = useAtom(
    depthRangesAtomFamily(canvasFeature.id),
  );
  const [depthRange, setDepthRange] = useAtom(currentDepthRangeAtom);

  const canvasLayerBathymetryFeatures = useAtomValue(
    bathymetryFeatureFamily({
      branchId: undefined,
    }),
  );
  const canvasLayerBathymetryFilenameToName = useMemo(
    () =>
      Object.fromEntries(
        canvasLayerBathymetryFeatures.map((b) => [
          b.properties.filename,
          b.properties.name ?? bathymetryDefaultName,
        ]),
      ),
    [canvasLayerBathymetryFeatures],
  );

  const bathResponse = useAtomValue(bathymetryFamily(bathymetryId));
  const raster =
    bathResponse.status === "finished" ? bathResponse.raster : undefined;

  const [rasterStats, setRasterStats] =
    useState<Loadable<Awaited<ReturnType<typeof getRasterStats>>>>();
  useEffect(() => {
    if (!raster) return;
    let stop = false;
    setRasterStats({ state: "loading" });
    getRasterStats(canvasFeature, raster).then((d) => {
      if (!stop) setRasterStats({ state: "hasData", data: d });
    });
    return () => {
      stop = true;
    };
  }, [canvasFeature, raster]);

  const [workerRunning, setWorkerRunning] = useState<boolean>(true);
  useEffect(() => {
    if (!raster || rasterStats?.state !== "hasData") return;
    const [minLon, minLat, maxLon, maxLat] = raster.bbox();
    let isSubmitted = false;
    const asyncMethod = async () => {
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const values = raster.values;
      const m = raster.height;
      const n = raster.width;
      const minValue = Math.floor(rasterStats.data.minValue);

      setWorkerRunning(true);
      const depthCountourWorker = new Worker(
        new URL("./depthAnalysisContourWorker.ts", import.meta.url),
        {
          type: "module",
        },
      );

      const features: ContourFeatures = [];
      for (const depthRange of depthRanges) {
        const passedRange = [-depthRange[0], -depthRange[1]];
        // Weirdness with contours() where we wouldn't include the entire park
        // polygon if we request the whole range. I think this happens when the
        // sampled shallowest point in the part isn't actually the shallowest
        // point, for instance when the park is on a slope, and the shallowest
        // point should be on the edge, but the sampled point is inside the park.
        //
        // The solution is to special case this: if we ask for the bottom-range
        // of the depths, start at 0.
        if (passedRange[0] === -minValue) passedRange[0] = 0;

        const contourFeatures = await new Promise<ContourFeatures>(
          (res, rej) => {
            depthCountourWorker.postMessage([
              values,
              m,
              n,
              sizeLatitude,
              sizeLongitude,
              minLon,
              maxLat,
              passedRange,
              canvasFeature,
              minValue,
            ]);
            depthCountourWorker.onmessage = function (e) {
              res(e.data);
            };
            depthCountourWorker.onerror = function (e) {
              scream("depthCountourWorker.onerror", {
                e,
              });
              rej(e);
            };
          },
        );
        features.push(...contourFeatures.filter(isDefined));
      }

      setWorkerRunning(false);
      depthCountourWorker.terminate();

      if (!isSubmitted) {
        setContourFeatureCollection({
          type: "FeatureCollection",
          features,
        });
      }
    };
    asyncMethod();
    return () => {
      isSubmitted = true;
    };
  }, [
    setContourFeatureCollection,
    canvasFeature,
    depthRanges,
    rasterStats,
    raster,
  ]);

  const sortedRegions:
    | Feature<
        Geometry,
        {
          depths: [number, number];
          area: number;
          color: string;
        }
      >[]
    | undefined = useMemo(() => {
    if (!contourFeatureCollection) return;

    const sorted = zip(
      contourFeatureCollection?.features ?? [],
      depthRanges,
    ).sort(([, [a0, a1]], [, [b0, b1]]) => {
      const cmp = a0 - b0;
      if (cmp === 0) return a1 - b1;
      return cmp;
    });

    const colors = generateHSLGradientColors({
      n: sorted.length,
      startHue: 179,
      endHue: 234,
      saturation: 100,
      lightness: 50,
    });
    return sorted.map(([f, range], i) => {
      const ret = {
        ...f,
        properties: {
          ...f.properties,
          depths: range,
          area: turf.area(f),
          color: colors[i],
          name: `Depth analysis ${Math.abs(range[0])}m `,
        },
      };
      return ret;
    });
  }, [contourFeatureCollection, depthRanges]);

  const [selections, setSelections] = useState<ExternalSelectionItem[]>([]);

  const onClick = useCallback(
    (features: MapboxGeoJSONFeature[]) => {
      if (features.length === 0) {
        setSelections([]);
      }
      setSelections([
        {
          ...features[0],
          properties: {
            id: features[0].id,
            color: features[0].properties?.color,
          },
        },
      ]);
    },
    [setSelections],
  );

  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);
  const selectedIds: string[] = useMemo(
    () => selections.filter((s) => s.id).map((s) => String(s.id)),
    [selections],
  );

  if (bathResponse.status === "failed")
    return (
      <>
        <ErrorTextWrapper>Error fetching bathymetry</ErrorTextWrapper>
      </>
    );

  if (rasterStats === undefined)
    return <ErrorTextWrapper>Error computing raster stats</ErrorTextWrapper>;

  if (!sortedRegions || !map) return null;

  return (
    <>
      <MapPolygon
        features={sortedRegions}
        sourceId={depthThresholdSourceId}
        layerId={depthThresholdLayerId}
        symbols={depthAnalysisSymbols}
        map={map}
        paint={depthPolygonPaint}
        linePaint={depthPolygonLinePaint}
        onClickCallback={onClick}
        selectedIds={selectedIds}
      />
      {selections.length !== 0 && (
        <DynamicSelectOption
          selections={selections}
          addToFolderName={`Depth analysis from ${canvasFeature.properties.name}`}
          callback={() => setTopRightModeActive(undefined)}
        />
      )}
      <ColoredGrid>
        <ResultValue>Min depth</ResultValue>
        {orLoader(
          rasterStats,
          (r) => (
            <ResultValue>
              {isNumber(r.minValue) && r.minValue !== Infinity ? (
                <>
                  <strong>{Math.abs(Math.round(r.minValue))}</strong> m
                </>
              ) : (
                <>No min depth found</>
              )}
            </ResultValue>
          ),
          {
            justifySelf: "flex-end",
          },
        )}

        <ResultValue>Max depth</ResultValue>
        {orLoader(
          rasterStats,
          (r) => (
            <ResultValue>
              {isNumber(r.maxValue) && r.maxValue !== Infinity ? (
                <>
                  <strong>{Math.abs(Math.round(r.maxValue))}</strong> m
                </>
              ) : (
                <>No max depth found</>
              )}
            </ResultValue>
          ),
          {
            justifySelf: "flex-end",
          },
        )}

        <ResultValue>Average depth</ResultValue>
        {orLoader(
          rasterStats,
          (r) => (
            <ResultValue>
              {isNumber(r.averageValue) && r.averageValue !== Infinity ? (
                <>
                  <strong>{Math.abs(Math.round(r.averageValue))}</strong> m
                </>
              ) : (
                <>No average value found</>
              )}
            </ResultValue>
          ),
          {
            justifySelf: "flex-end",
          },
        )}

        <Row
          style={{
            alignItems: "baseline",
          }}
        >
          <ResultValue>Source(s)</ResultValue>
          {bathResponse.usedCustomBathymetry.length !== 0 && (
            <HelpTooltip
              size={10}
              text="Custom bathymetry prioritized in analysis"
            />
          )}
        </Row>
        <SourceListWrapper>
          {bathResponse.usedCustomBathymetry
            .map(getFileName)
            .map((fileName) => (
              <WithTooltip
                key={fileName}
                text={
                  canvasLayerBathymetryFilenameToName[fileName] ??
                  bathymetryDefaultName
                }
              >
                <OverflowEllipsis>
                  {canvasLayerBathymetryFilenameToName[fileName] ??
                    bathymetryDefaultName}
                </OverflowEllipsis>
              </WithTooltip>
            ))}
          <ResultValue>
            <a
              href={"https://emodnet.ec.europa.eu/en"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Emodnet
            </a>
            /
            <a
              href={"https://www.gebco.net/"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Gebco
            </a>
          </ResultValue>
        </SourceListWrapper>
      </ColoredGrid>
      <Button
        size="small"
        text="Bathymetry"
        icon={<DownloadIcon />}
        buttonType="secondary"
        onClick={() => {
          window.open(bathResponse.url, "_blank", "noopener");
        }}
      />

      <OverlineText
        style={{
          paddingTop: "1.6rem",
          paddingBottom: "0",
        }}
      >
        Thresholds
      </OverlineText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          gap: spaceMedium,
        }}
      >
        {orLoader(rasterStats, (rs) => (
          <>
            <RangeSlider
              min={Math.floor(rs.minValue)}
              max={Math.ceil(rs.maxValue)}
              values={
                depthRange ?? [Math.floor(rs.minValue), Math.ceil(rs.maxValue)]
              }
              step={bathResponse.usedCustomBathymetry.length !== 0 ? 0.1 : 1}
              inside
              labels
              renderLabel={(v) => `${Math.abs(v)}m`}
              onChange={function (f: [number, number]): void {
                setDepthRange(f);
              }}
              style={{
                flex: 1,
              }}
            />
            <Button
              text="Add"
              size="small"
              onClick={() => {
                setDepthRanges((dr) => {
                  const val1 = depthRange ? depthRange[0] : rs.minValue;
                  const val2 = depthRange ? depthRange[1] : rs.maxValue;
                  const alreadyExists = dr?.some(
                    (oldThreshold) =>
                      oldThreshold[0] === val1 && oldThreshold[1] === val2,
                  );

                  if (alreadyExists) {
                    return dr;
                  }
                  return [...dr, [val1, val2]];
                });
              }}
            />
          </>
        ))}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 1fr 1fr 1fr 1fr",
          columnGap: spaceSmall,
          rowGap: spaceTiny,
        }}
      >
        {sortedRegions?.map((region) => {
          const {
            properties: {
              depths: [from, to],
              area,
              color,
            },
          } = region;
          return (
            <Fragment key={`${from}-${to}`}>
              <Row>
                <MapColorIndicator opacity={1} color={color} />
                <p>
                  {Math.abs(from)}m to {Math.abs(to)}m
                </p>
              </Row>
              <p
                style={{
                  textAlign: "end",
                }}
              >
                {Math.round(area / (1000 * 1000))}km²
              </p>
              <p
                style={{
                  textAlign: "end",
                }}
              >
                {Math.round((area / (1000 * 1000) / polygonArea) * 100)}%
              </p>
              <Button
                buttonType="secondary"
                icon={
                  <IconREMSize width={1.4} height={1.4}>
                    <Bin title={"Delete threshold"} />
                  </IconREMSize>
                }
                size="small"
                onClick={() => {
                  setDepthRanges((dr) =>
                    dr.filter(([f, t]) => !(f === from && t === to)),
                  );
                }}
              />

              <AddAnalysisButton
                region={region as ProjectFeature<Polygon | MultiPolygon>}
                parkId={
                  canvasFeature.properties.type === PARK_PROPERTY_TYPE
                    ? canvasFeature.id
                    : undefined
                }
              />
            </Fragment>
          );
        })}
        {workerRunning && (
          <SkeletonText
            style={{
              gridColumn: "span 4",
            }}
          />
        )}
      </div>
    </>
  );
};

const DepthAnalysis = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  const [, , bathymetryId] = useBathymetry({
    featureId: canvasFeature.id,
    bufferKm: 1,
    projectId: undefined,
    branchId: undefined,
  });

  return (
    <>
      <SubtitleWithLine text={"Depth"} />
      <Column>
        <React.Suspense fallback={<SkeletonText text="Loading bathymetry" />}>
          <DepthAnalysisInner
            canvasFeature={canvasFeature}
            bathymetryId={
              // "" will suspend, which is caught
              bathymetryId ?? ""
            }
          />
        </React.Suspense>
      </Column>
    </>
  );
};

export default DepthAnalysis;
