import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { z } from "zod";
import { ABLY_ANALYSIS_UPDATE, ABLY_ANALYSIS_DELETE } from "state/ably";
import { _AnalysisConfiguration } from "services/configurationService";
import useOrgAnalysisCrud from "components/Organisation/Library/analysis/useOrgAnalysisCrud";

export function useAblyLibraryAnalysis(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgAnalysisCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_analysis_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const analysis = _AnalysisConfiguration.parse(message.data.meta);
      updateLocal(analysis);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const analysisId = z.string().parse(message.data.meta);
      removeLocal(analysisId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ANALYSIS_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_ANALYSIS_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
