import { useEffect } from "react";
import { turbineSourceId } from "components/Mapbox/constants";
import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { turbinesFamily } from "state/jotai/turbine";

export const turbineTypeInvalidWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.TurbineTypeInvalid> | undefined
      >
    >(async (get) => {
      const turbineTypes = await get(simpleTurbineTypesAtom);
      const turbines = await get(turbinesFamily({ branchId }));
      const invalids = turbines.filter(
        (t) => !turbineTypes.has(t.properties.turbineTypeId),
      );

      if (invalids.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.TurbineTypeInvalid,
        featureIds: invalids.map((t) => t.id),
      };
    }),
);

export const TurbineTypeInvalidJotai = ({
  map,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const warning = useAtomValue(
    turbineTypeInvalidWarningFamily({ branchId: undefined }),
  );

  useEffect(() => {
    if (!warning) return;

    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });
    return () => {
      if (map.getSource(source))
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [warning, map, source]);

  return null;
};
