import { UserAccessRole } from "./../../../types/user";
import {
  AccessUnionSchema,
  Group,
  GroupMembership,
  GroupNodeAccess,
  _AccessUnionSchema,
  _Group,
  _GroupMembership,
  _GroupNodeAccess,
  _UserNodeAccess,
  _GroupNodeAccessWithMeta,
  GroupNodeAccessWithMeta,
} from "./types";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "services/utils";

// ----------------- GROUPS -----------------------------

export const getOrganisationGroups = async (
  organisationId: string,
): Promise<Group[]> => {
  return fetchSchemaWithToken(
    _Group.array(),
    `/api/customer/organisation/${organisationId}/groups`,
    {
      method: "get",
    },
  );
};

export const addGroup = async (
  organisationId: string,
  name: string,
): Promise<Group> => {
  return fetchSchemaWithToken(
    _Group,
    `/api/customer/organisation/${organisationId}/group`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ name }),
    },
  );
};

export const updateGroup = async (
  organisationId: string,
  groupId: string,
  name: string,
): Promise<Group> => {
  return fetchSchemaWithToken(
    _Group,
    `/api/customer/organisation/${organisationId}/group/${groupId}`,
    {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ name }),
    },
  );
};

export const removeGroup = async (
  organisationId: string,
  groupId: string,
): Promise<boolean> => {
  try {
    await fetchEnhancerWithToken(
      `/api/customer/organisation/${organisationId}/group/${groupId}`,
      {
        method: "delete",
      },
    );
    return true;
  } catch {
    return false;
  }
};

// ----------------- GROUP PROJECT -----------------------------

export const getGroupsAndUsersWithAccessToNode = async (
  organisationId: string,
  nodeId: string,
): Promise<AccessUnionSchema[]> => {
  return fetchSchemaWithToken(
    _AccessUnionSchema.array(),
    `/api/customer/organisation/${organisationId}/node/${nodeId}/access`,
    {
      method: "get",
    },
  );
};

export const listGroupsWithAccessToNodeTree = async (
  organisationId: string,
  nodeId: string,
): Promise<GroupNodeAccessWithMeta[]> => {
  return fetchSchemaWithToken(
    _GroupNodeAccessWithMeta.array(),
    `/api/customer/organisation/${organisationId}/node/${nodeId}/groups/deep-access`,
    {
      method: "get",
    },
  );
};

export const getGroupProjectAccess = async (
  organisationId: string,
  groupId: string,
): Promise<GroupNodeAccess[]> => {
  return fetchSchemaWithToken(
    _GroupNodeAccess.array(),
    `/api/customer/organisation/${organisationId}/group/${groupId}/nodes`,
    {
      method: "get",
    },
  );
};

export const addOrUpdateNodeAccessInGroup = async (
  organisationId: string,
  groupId: string,
  nodeId: string,
  role: UserAccessRole,
): Promise<GroupNodeAccess> => {
  return fetchSchemaWithToken(
    _GroupNodeAccess,
    `/api/customer/organisation/${organisationId}/group/${groupId}/node/${nodeId}`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ resourceName: role }),
    },
  );
};

export const removeNodeAccessFromGroup = async (
  organisationId: string,
  groupId: string,
  nodeId: string,
): Promise<boolean> => {
  try {
    await fetchEnhancerWithToken(
      `/api/customer/organisation/${organisationId}/group/${groupId}/node/${nodeId}`,
      {
        method: "delete",
      },
    );
    return true;
  } catch {
    return false;
  }
};

// ----------------- GROUP MEMBERS -----------------------------

export const listOrganisationGroupMemberships = async (
  organisationId: string,
): Promise<GroupMembership[]> => {
  return fetchSchemaWithToken(
    _GroupMembership.array(),
    `/api/customer/organisation/${organisationId}/groups/members`,
    {
      method: "get",
    },
  );
};

export const addGroupMember = async (
  organisationId: string,
  groupId: string,
  userId: string,
): Promise<GroupMembership> => {
  return fetchSchemaWithToken(
    _GroupMembership,
    `/api/customer/organisation/${organisationId}/group/${groupId}/member`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ userId }),
    },
  );
};

export const removeGroupMember = async (
  organisationId: string,
  groupId: string,
  userId: string,
): Promise<boolean> => {
  await fetchEnhancerWithToken(
    `/api/customer/organisation/${organisationId}/group/${groupId}/member/${encodeURIComponent(userId)}`,
    {
      method: "delete",
    },
  );
  return true;
};
