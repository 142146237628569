import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { UserAccessRole, UserAccessRoles, _UserAccessRole } from "types/user";
import { List } from "./style";
import FolderIcon from "@icons/14/Folder.svg?react";
import EarthIcon from "@icons/24/Earth.svg?react";
import RemoveIcon from "@icons/24/Remove.svg?react";
import Dropdown from "components/Dropdown/Dropdown";
import {
  admin_getNodeSelectorFamily,
  getNodeSelectorFamily,
} from "components/Projects/useOrganisationFolderCrud";
import { useAtomValue } from "jotai";
import { IconREMSize, typography } from "styles/typography";
import { capitalize } from "utils/utils";
import { loadable } from "jotai/utils";
import { InvitationNodeAccess } from "../shared";
import { spaceSmall } from "styles/space";
import { NodeType } from "services/customerAPI";
import { SkeletonText } from "components/Loading/Skeleton";
import { adminInOrganisationSelectorFamily } from "state/user";

interface ProjectListProps {
  organisationId: string;
  nodesAccess: InvitationNodeAccess[];
  onChangeRole: (nodeId: string, role: UserAccessRole) => void;
  onRemove: (nodeId: string) => void;
}

export const ProjectList = ({
  organisationId,
  nodesAccess,
  onChangeRole,
  onRemove,
}: ProjectListProps) => {
  const { scrollBodyRef } = useShowScrollShadow(true);

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  return (
    <List ref={scrollBodyRef}>
      {nodesAccess.map((node) =>
        isAdminInOrg ? (
          <AdminNodeRow
            key={node.nodeId}
            nodeAccess={node}
            organisationId={organisationId}
            onChangeRole={(role) => onChangeRole(node.nodeId, role)}
            onRemove={() => onRemove(node.nodeId)}
          />
        ) : (
          <NodeRow
            key={node.nodeId}
            nodeAccess={node}
            organisationId={organisationId}
            onChangeRole={(role) => onChangeRole(node.nodeId, role)}
            onRemove={() => onRemove(node.nodeId)}
          />
        ),
      )}
    </List>
  );
};

interface BaseNodeRowProps {
  name: string;
  type: NodeType;
  role: UserAccessRole | null;
  onChangeRole(role: UserAccessRole): void;
  onRemove(): void;
}

const BaseNodeRow = ({
  name,
  type,
  role,
  onChangeRole,
  onRemove,
}: BaseNodeRowProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: spaceSmall,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: spaceSmall,
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          {type === "project" ? <EarthIcon /> : <FolderIcon />}
        </IconREMSize>
        <p
          style={{
            ...typography.body,
          }}
        >
          {name}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          gap: spaceSmall,
        }}
      >
        <Dropdown
          kind="hidden"
          value={role ?? ""}
          onChange={async (e) => {
            const newRole = _UserAccessRole.parse(e.target.value);
            onChangeRole(newRole);
          }}
        >
          <option value="" disabled hidden>
            Select access
          </option>
          {UserAccessRoles.map((role) => (
            <option key={role} value={role}>
              {capitalize(role)}
            </option>
          ))}
        </Dropdown>
        <IconREMSize
          height={1.4}
          width={1.4}
          style={{
            cursor: "pointer",
          }}
          onClick={onRemove}
        >
          <RemoveIcon />
        </IconREMSize>
      </div>
    </div>
  );
};

const NodeRow = ({
  organisationId,
  nodeAccess,
  onChangeRole,
  onRemove,
}: {
  organisationId: string;
  nodeAccess: InvitationNodeAccess;
  onChangeRole(role: UserAccessRole): void;
  onRemove(): void;
}) => {
  const node = useAtomValue(
    getNodeSelectorFamily({
      organisationId,
      nodeId: nodeAccess.nodeId,
    }),
  );

  if (!node) {
    return <span>Error: Node not found</span>;
  }

  return (
    <BaseNodeRow
      name={node.name}
      type={node.type}
      role={nodeAccess.role ?? null}
      onChangeRole={onChangeRole}
      onRemove={onRemove}
    />
  );
};

// You can create an AdminNodeRow that uses different data sources but the same base component
const AdminNodeRow = ({
  organisationId,
  nodeAccess,
  onChangeRole,
  onRemove,
}: {
  organisationId: string;
  nodeAccess: InvitationNodeAccess;
  onChangeRole(role: UserAccessRole): void;
  onRemove(): void;
}) => {
  // Use a different selector or data source for admin
  const nodeLoadable = useAtomValue(
    loadable(
      admin_getNodeSelectorFamily({
        organisationId,
        nodeId: nodeAccess.nodeId,
      }),
    ),
  );

  if (nodeLoadable.state === "loading") {
    return <SkeletonText />;
  }

  if (nodeLoadable.state === "hasError") {
    return <span>Error: Node not found</span>;
  }

  const node = nodeLoadable.data;

  if (!node) {
    return <span>Error: Node not found</span>;
  }

  return (
    <BaseNodeRow
      name={node.name}
      type={node.type}
      role={nodeAccess.role ?? null}
      onChangeRole={onChangeRole}
      onRemove={onRemove}
    />
  );
};
