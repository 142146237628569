import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { getViewShedAnalysis } from "../services/terrainAPIService";
import { fastMax, fastMin } from "../utils/utils";
import { scream } from "../utils/sentry";
import { Position } from "geojson";
import GeoTIFF, { fromBlob } from "geotiff";
import { z } from "zod";
import { Raster } from "types/raster";
import { geotiffToRaster } from "utils/gdal";

const MAXIMUM_ALLOWED_DISTANCE = 100_000;

export const diagonalDistanceWithinAllowedRange = (coords: Position[]) => {
  const Xs = coords.map((c) => c[0]);
  const Ys = coords.map((c) => c[1]);

  const [minX, minY, maxX, maxY] = [
    fastMin(Xs),
    fastMin(Ys),
    fastMax(Xs),
    fastMax(Ys),
  ];

  const deltaX = maxX - minX;
  const deltaY = maxY - minY;
  const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

  return dist < MAXIMUM_ALLOWED_DISTANCE;
};

export const getViewShedMultipleResultSelectorFamily = atomFamily(
  ({
    projectedCoordinates,
    epsg,
    observerHeight,
    bbox,
    zoom,
  }: {
    projectedCoordinates: Position[];
    observerHeight: number;
    bbox: [number, number, number, number];
    zoom: number;
    epsg: number;
  }) =>
    atom<
      Promise<{
        geotiff: GeoTIFF;
        originalTerrainUrl: string;
        viewshedResultUrl: string;
        pixelSize: [number, number];
        viewshedRaster: Raster;
      }>
    >(async () => {
      if (!diagonalDistanceWithinAllowedRange(projectedCoordinates))
        throw new Error("Distance between turbines are too great");

      const res = await getViewShedAnalysis(
        projectedCoordinates,
        epsg,
        zoom,
        observerHeight,
        bbox,
      );

      if (!res.ok) {
        throw scream("Unable to read viewshed tiff", {
          res,
        });
      }

      const parser = z.object({
        originalterrain: z.string(),
        viewshed: z.string(),
      });
      const j = await res.json();
      const jsonRes = parser.parse(j);

      const viewShedRes = await fetch(jsonRes.viewshed);

      if (!viewShedRes.ok) {
        throw scream("Unable to read viewshed tiff", {
          res,
        });
      }

      const blob = await viewShedRes.blob();
      const viewshedRaster = await geotiffToRaster(
        new File([blob], "viewshed.tif"),
      );
      const geotiff = await fromBlob(blob);
      const image = await geotiff.getImage();
      const [xMin, yMin, xMax, yMax] = image.getBoundingBox();
      const height = image.getHeight();
      const width = image.getWidth();
      const pixelSize: [number, number] = [
        Math.round((xMax - xMin) / width),
        Math.round((yMax - yMin) / height),
      ];

      return {
        geotiff,
        pixelSize,
        originalTerrainUrl: jsonRes.originalterrain,
        viewshedResultUrl: jsonRes.viewshed,
        viewshedRaster,
      };
    }),
);
