import {
  CableType,
  ExportCableVoltageType,
  ExportSystemType,
  IAVoltageType,
} from "../services/cableTypeService";

export const SUBSTATION_PROPERTY_TYPE = "park-substation";
export const EXPORT_CABLE_PROPERTY_TYPE = "park-external-wiring";
export const CABLE_PROPERTY_TYPE = "park-wiring-connection";
export const CABLE_CORRIDOR_PROPERTY_TYPE = "park-cable-corridor";
export const CABLE_PARTITION_POLYGON_PROPERTY_TYPE =
  "park-cable-partition-polygon";
export const CABLE_CHAIN_POLYGON_PROPERTY_TYPE = "park-cable-chain-polygon";

export const CablesMenuType = "cables-menu-type";
export const AddSubStationMenuType = "add-substation-menu-type";
export const GenerateCablesMenuType = "generate-cables-menu-type";
export const DrawExportCableMenuType = "draw-export-cable-menu-type";
export const DrawCableCorridorMenuType = "draw-cable-corridor-menu-type";
export const DrawCableMenuType = "draw-cable-menu-type";
export const CableFreeZoneMenuType = "cable-free-zone-menu-type";

export const defaultIACables: Record<
  IAVoltageType,
  Record<
    string,
    Pick<
      CableType,
      | "resistance"
      | "capacitance"
      | "reactance"
      | "ampacity"
      | "lambda1"
      | "lambda2"
      | "dielectricLossFactor"
    >
  >
> = {
  [IAVoltageType.kV33]: {
    "Cu 3x95mm2 33kV": {
      resistance: 0.194, // 0.247 at 90 degrees R20=R90 / (1+0.00393*70)=0.247/(1+0.00393*70)=0.194
      capacitance: 170,
      reactance: 0.127,
      ampacity: 0.295,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 3x185mm2 33kV": {
      resistance: 0.1, // 0.128 at 90 degrees = 0.100 at 20 degrees
      capacitance: 207,
      reactance: 0.115,
      ampacity: 0.42,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 3x300mm2 33kV": {
      resistance: 0.061, // 0.128 at 90 degrees = 0.100 at 20 degrees
      capacitance: 252,
      reactance: 0.105,
      ampacity: 0.55,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
  },
  [IAVoltageType.kV66]: {
    "Cu 300mm2 66kV": {
      resistance: 0.0615,
      capacitance: 240,
      reactance: 0.1162,
      ampacity: 0.53,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 400mm2 66kV": {
      resistance: 0.0489,
      capacitance: 260,
      reactance: 0.11,
      ampacity: 0.59,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 500mm2 66kV": {
      resistance: 0.039,
      capacitance: 290,
      reactance: 0.1068,
      ampacity: 0.655,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 630mm2 66kV": {
      resistance: 0.0315,
      capacitance: 320,
      reactance: 0.1037,
      ampacity: 0.715,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 800mm2 66kV": {
      resistance: 0.0261,
      capacitance: 350,
      reactance: 0.1005,
      ampacity: 0.775,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 1000mm2 66kV": {
      resistance: 0.0223,
      capacitance: 380,
      reactance: 0.0974,
      ampacity: 0.825,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
  },
  [IAVoltageType.kV132]: {
    "Cu 300mm2 132kV": {
      resistance: 0.0614,
      capacitance: 160,
      reactance: 0.1319,
      ampacity: 0.53,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 400mm2 132kV": {
      resistance: 0.0487,
      capacitance: 180,
      reactance: 0.1257,
      ampacity: 0.59,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 500mm2 132kV": {
      resistance: 0.0388,
      capacitance: 200,
      reactance: 0.1194,
      ampacity: 0.655,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 630mm2 132kV": {
      resistance: 0.0311,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 0.715,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 800mm2 132kV": {
      resistance: 0.0257,
      capacitance: 230,
      reactance: 0.1131,
      ampacity: 0.775,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
    "Cu 1000mm2 132kV": {
      resistance: 0.0219,
      capacitance: 250,
      reactance: 0.11,
      ampacity: 0.825,
      lambda1: 0.3,
      lambda2: 0,
      dielectricLossFactor: 0.001,
    },
  },
};

export const defaultExportCables: Record<
  ExportCableVoltageType,
  Record<
    string,
    Pick<
      CableType,
      | "resistance"
      | "capacitance"
      | "reactance"
      | "ampacity"
      | "exportCableType"
      | "lambda1"
      | "lambda2"
      | "dielectricLossFactor"
      | "exportCableUsage"
    >
  >
> = {
  [ExportCableVoltageType.kV110]: {
    // defaults for 110kV are taken from pandapower basic standard types
    "122-AL1/20-ST1A 110kV overhead line": {
      resistance: 0.2376,
      capacitance: 8.5,
      reactance: 0.43,
      ampacity: 0.41,
      exportCableType: ExportSystemType.HVAC,
    },
    "184-AL1/30-ST1A 110kV overhead line": {
      resistance: 0.1571,
      capacitance: 8.8,
      reactance: 0.4,
      ampacity: 0.535,
      exportCableType: ExportSystemType.HVAC,
    },
    "305-AL1/39-ST1A 110kV overhead line": {
      resistance: 0.0949,
      capacitance: 9.2,
      reactance: 0.38,
      ampacity: 0.74,
      exportCableType: ExportSystemType.HVAC,
    },
    "490-AL1/64-ST1A 110kV overhead line": {
      resistance: 0.059,
      capacitance: 9.75,
      reactance: 0.37,
      ampacity: 0.96,
      exportCableType: ExportSystemType.HVAC,
    },
    "679-AL1/86-ST1A 110kV overhead line": {
      resistance: 0.042,
      capacitance: 9.95,
      reactance: 0.36,
      ampacity: 1.15,
      exportCableType: ExportSystemType.HVAC,
    },
  },
  [ExportCableVoltageType.kV150]: {
    "Offshore 800mm2 Al 150kV AC": {
      resistance: 0.0393,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 0.8,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Offshore 800mm2 Cu 150kV AC": {
      resistance: 0.0264,
      capacitance: 210,
      reactance: 0.1162,
      ampacity: 1,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Onshore 1000mm2 Al 150kV AC": {
      resistance: 0.0318,
      capacitance: 205,
      reactance: 0.175,
      ampacity: 0.98,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1200mm2 Al 150kV AC": {
      resistance: 0.0249,
      capacitance: 230,
      reactance: 0.175,
      ampacity: 1.11,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1400mm2 Al 150kV AC": {
      resistance: 0.0214,
      capacitance: 235,
      reactance: 0.17,
      ampacity: 1.205,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
  },
  [ExportCableVoltageType.kV220]: {
    "Offshore 1000mm2 Cu 220kV AC": {
      resistance: 0.022,
      capacitance: 190,
      reactance: 0.1194,
      ampacity: 1.134,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Offshore 1200mm2 Al 220kV AC": {
      resistance: 0.028,
      capacitance: 200,
      reactance: 0.1162,
      ampacity: 0.99,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Offshore 1200mm2 Cu 220kV AC": {
      resistance: 0.0199,
      capacitance: 200,
      reactance: 0.1162,
      ampacity: 1.2,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.45,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Onshore 1000mm2 Al 220kV AC": {
      resistance: 0.0318,
      capacitance: 190,
      reactance: 0.18,
      ampacity: 0.965,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1200mm2 Al 220kV AC": {
      resistance: 0.0249,
      capacitance: 210,
      reactance: 0.175,
      ampacity: 1.09,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1400mm2 Al 220kV AC": {
      resistance: 0.0214,
      capacitance: 220,
      reactance: 0.175,
      ampacity: 1.185,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1600mm2 Al 220kV AC": {
      resistance: 0.0189,
      capacitance: 230,
      reactance: 0.17,
      ampacity: 1.275,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1800mm2 Al 220kV AC": {
      resistance: 0.0168,
      capacitance: 245,
      reactance: 0.17,
      ampacity: 1.365,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 2000mm2 Al 220kV AC": {
      resistance: 0.0152,
      capacitance: 250,
      reactance: 0.17,
      ampacity: 1.44,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
  },
  [ExportCableVoltageType.kV275]: {
    "Offshore 1000mm2 Cu 275kV AC": {
      resistance: 0.0218,
      capacitance: 180,
      reactance: 0.1194,
      ampacity: 1.1,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Offshore 1200mm2 Al 275kV AC": {
      resistance: 0.0279,
      capacitance: 190,
      reactance: 0.1162,
      ampacity: 0.95,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Offshore 1200mm2 Cu 275kV AC": {
      resistance: 0.0191,
      capacitance: 190,
      reactance: 0.1162,
      ampacity: 1.17,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: true, onshore: false },
    },
    "Onshore 1000mm2 Al 275kV AC": {
      resistance: 0.0318,
      capacitance: 175,
      reactance: 0.1875,
      ampacity: 0.925,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1200mm2 Al 275kV AC": {
      resistance: 0.0249,
      capacitance: 190,
      reactance: 0.18,
      ampacity: 1.045,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1400mm2 Al 275kV AC": {
      resistance: 0.0214,
      capacitance: 200,
      reactance: 0.1775,
      ampacity: 1.135,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1600mm2 Al 275kV AC": {
      resistance: 0.0189,
      capacitance: 210,
      reactance: 0.175,
      ampacity: 1.215,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 1800mm2 Al 275kV AC": {
      resistance: 0.0168,
      capacitance: 220,
      reactance: 0.1725,
      ampacity: 1.3,
      exportCableType: ExportSystemType.HVAC,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
    },
    "Onshore 2000mm2 Al 275kV AC": {
      resistance: 0.0152,
      capacitance: 230,
      reactance: 0.1725,
      ampacity: 1.37,
      lambda1: 0.1,
      lambda2: 0,
      dielectricLossFactor: 0.001,
      exportCableUsage: { offshore: false, onshore: true },
      exportCableType: ExportSystemType.HVAC,
    },
  },
  [ExportCableVoltageType.kV320]: {
    "Offshore 2000mm2 Cu 320kV DC": {
      resistance: 0.009,
      capacitance: null,
      reactance: null,
      ampacity: null,
      lambda1: undefined,
      lambda2: undefined,
      dielectricLossFactor: undefined,
      exportCableUsage: undefined,
      exportCableType: ExportSystemType.HVDC,
    },
  },
  [ExportCableVoltageType.kV345]: {},
  [ExportCableVoltageType.kV380]: {},
  [ExportCableVoltageType.kV420]: {},
  [ExportCableVoltageType.kV525]: {
    "Offshore 2000mm2 Cu 525kV DC": {
      resistance: 0.009,
      capacitance: null,
      reactance: null,
      ampacity: null,
      lambda1: undefined,
      lambda2: undefined,
      dielectricLossFactor: undefined,
      exportCableUsage: undefined,
      exportCableType: ExportSystemType.HVDC,
    },
  },
};

export const EXPORT_VOLTAGE_DEFAULT: Record<ExportSystemType, number> = {
  [ExportSystemType.HVAC]: ExportCableVoltageType.kV220,
  [ExportSystemType.HVDC]: ExportCableVoltageType.kV320,
};
export const EXPORT_RESISTANCE_DEFAULT: Record<ExportSystemType, number> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].resistance,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].resistance,
};
export const EXPORT_REACTANCE_DEFAULT: Record<ExportSystemType, number | null> =
  {
    [ExportSystemType.HVAC]:
      defaultExportCables[ExportCableVoltageType.kV220][
        "Offshore 1000mm2 Cu 220kV AC"
      ].reactance,
    [ExportSystemType.HVDC]:
      defaultExportCables[ExportCableVoltageType.kV320][
        "Offshore 2000mm2 Cu 320kV DC"
      ].reactance,
  };
export const EXPORT_CAPACITANCE_DEFAULT: Record<
  ExportSystemType,
  number | null
> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].capacitance,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].capacitance,
};
export const EXPORT_AMPACITY_DEFAULT: Record<ExportSystemType, number | null> =
  {
    [ExportSystemType.HVAC]:
      defaultExportCables[ExportCableVoltageType.kV220][
        "Offshore 1000mm2 Cu 220kV AC"
      ].ampacity,
    [ExportSystemType.HVDC]:
      defaultExportCables[ExportCableVoltageType.kV320][
        "Offshore 2000mm2 Cu 320kV DC"
      ].ampacity,
  };
export const EXPORT_LAMBDA1_DEFAULT: Record<
  ExportSystemType,
  number | undefined
> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].lambda1,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].lambda1,
};
export const EXPORT_LAMBDA2_DEFAULT: Record<
  ExportSystemType,
  number | undefined
> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].lambda2,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].lambda2,
};
export const EXPORT_DIELECTRIC_LOSS_FACTOR_DEFAULT: Record<
  ExportSystemType,
  number | undefined
> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].dielectricLossFactor,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].dielectricLossFactor,
};
export const EXPORT_USAGE_DEFAULT: Record<
  ExportSystemType,
  { offshore?: boolean; onshore?: boolean } | undefined
> = {
  [ExportSystemType.HVAC]:
    defaultExportCables[ExportCableVoltageType.kV220][
      "Offshore 1000mm2 Cu 220kV AC"
    ].exportCableUsage,
  [ExportSystemType.HVDC]:
    defaultExportCables[ExportCableVoltageType.kV320][
      "Offshore 2000mm2 Cu 320kV DC"
    ].exportCableUsage,
};
