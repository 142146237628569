import { ValidationWarningTypes } from "./utils";
import { pointInPolygon } from "utils/geometry";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { substationsInParkFamily } from "state/jotai/substation";
import { exclusionZonesForParkByDomainFamily } from "state/jotai/exclusionZone";
import { ValidationWarning } from "state/validationWarnings";

export const substationInsideNoCableExclusionZoneWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubstationInsideNoCableExclusionZone>
        | undefined
      >
    >(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const exclusionZones = (
        await get(exclusionZonesForParkByDomainFamily({ parkId, branchId }))
      ).cable;

      const subsInBadZones = substations.filter((sub) =>
        exclusionZones.some((zone) =>
          pointInPolygon(sub.geometry, zone.geometry),
        ),
      );

      if (subsInBadZones.length === 0) return;
      return {
        type: ValidationWarningTypes.SubstationInsideNoCableExclusionZone,
        featureIds: subsInBadZones.map((s) => s.id),
        parkId: parkId,
      };
    }),
);
