import { Vector3, Euler, MathUtils } from "three";

const PI_2 = Math.PI / 2;

/**
 * Chunks taken from PointerLockControls and PointerLockControls demo.
 *
 * @see https://github.com/mrdoob/three.js/blob/master/examples/js/controls/PointerLockControls.js
 * @see https://threejs.org/examples/#misc_controls_pointerlock
 */
export default class FirstPersonControls {
  constructor(object, onMouseMoveCallback) {
    this.object = object;
    this.enabled = true;
    this.lookSpeedX = 0.0005;
    this.lookSpeedY = 0.0005;
    this.onMouseMoveCallback = onMouseMoveCallback;

    this.euler = new Euler(0, 0, 0, "YXZ"); // YXZ order is crucial for FPS
    this.velocity = new Vector3();
    this.direction = new Vector3();

    this.onMouseMove = this.onMouseMove.bind(this);

    this.isLeftMouseDown = false;
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);

    document.addEventListener("mousedown", this.onMouseDown, false);
    document.addEventListener("mouseup", this.onMouseUp, false);
    document.addEventListener("mousemove", this.onMouseMove, false);
  }

  getObject() {
    return this.object;
  }

  dispose() {
    document.removeEventListener("mousemove", this.onMouseMove, false);
    document.removeEventListener("mousedown", this.onMouseDown, false);
    document.removeEventListener("mouseup", this.onMouseUp, false);
  }

  onMouseDown(e) {
    if (
      e.target.tagName !== "CANVAS" ||
      e.target.className === "mapboxgl-canvas"
    ) {
      return;
    }
    if (e.button === 0) {
      // Left mouse button
      this.isLeftMouseDown = true;
    }
  }

  onMouseUp(e) {
    if (e.button === 0) {
      // Left mouse button
      this.isLeftMouseDown = false;
    }
  }

  onMouseMove(e) {
    if (!this.enabled || !this.isLeftMouseDown) {
      return false;
    }

    let movementX = e.movementX || e.mozMovementX || e.webkitMovementX || 0;
    let movementY = e.movementY || e.mozMovementY || e.webkitMovementY || 0;

    this.euler.setFromQuaternion(this.object.quaternion);
    this.euler.y += movementX * this.lookSpeedX;
    this.euler.x += movementY * this.lookSpeedY;

    this.euler.x = Math.max(-PI_2, Math.min(PI_2, this.euler.x));
    this.object.quaternion.setFromEuler(this.euler);

    if (this.onMouseMoveCallback) {
      const degrees = MathUtils.radToDeg(-1 * this.euler.y + Math.PI) % 360;
      this.onMouseMoveCallback(degrees);
    }
  }

  update(delta) {
    // Update velocity and movement similar to before
    this.velocity.x -= this.velocity.x * 10.0 * delta;
    this.velocity.z -= this.velocity.z * 10.0 * delta;

    // Move the camera directly in its local space
    if (this.velocity.lengthSq() > 0) {
      this.object.translateX(this.velocity.x * delta);
      this.object.translateZ(this.velocity.z * delta);
    }
  }
}
