import ExportCable from "@icons/24/ExportCable.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import { Row } from "components/General/Layout";

const ExportCableSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { activities } = localConfig.ti.exportCable;
  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Pull in export cable at landfall</SubHeader>
        <SubHeader>Pull in export cable to substation</SubHeader>
        <SubHeader>Join export cables</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.exportCable.activities.pullInExportCableAtLandfall`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.pullInExportCableAtLandfall}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              exportCable: {
                ...localConfig.ti.exportCable,
                activities: {
                  ...activities,
                  pullInExportCableAtLandfall: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.exportCable.activities.pullInExportCableToSubstation`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.pullInExportCableToSubstation}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              exportCable: {
                ...localConfig.ti.exportCable,
                activities: {
                  ...activities,
                  pullInExportCableToSubstation: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.exportCable.activities.joinExportCables`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.joinExportCables}
          step={1}
          validate={(e) => e >= 1 && e <= 100}
          validationMessage={`Must be between 1 and 100`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              exportCable: {
                ...localConfig.ti.exportCable,
                activities: {
                  ...activities,
                  joinExportCables: Math.round(duration),
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const ExportCableActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  return (
    <InstallationCategoryEntry
      title="Export cables"
      icon={<ExportCable />}
      defaultOpen={true}
    >
      <ExportCableSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
