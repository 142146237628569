import { inReadOnlyModeSelector } from "../../../../state/project";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { InputDimensioned, InputNumber } from "../../../General/Input";
import PhasingTable from "./PhasingTable";
import styled from "styled-components";
import { Label, RowItem, RowWrapper, Tag } from "../styles";
import { SpecificLcoeFormula } from "components/FinancialAnalysis/Lcoe/LcoeFormula";
import { currentYear } from "utils/inflation";
import HelpTooltip, {
  ARTICLE_FINANCIAL_ANALYSIS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { Column, Row } from "components/General/Layout";
import { CostConfiguration } from "services/costService";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import {
  HorizontalLine,
  Subtitle,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import { SecondaryText } from "components/Projects/ProjectGrid.style";

const Container = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;
  max-width: 120rem;
`;

export const Lcoe = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const inReadOnlyMode = useAtomValue(inReadOnlyModeSelector) || isReadOnly;

  const changelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  const {
    lcoe: { discountRate },
    lifeCycle: { projectStart, operationStart, decomissioning },
    inflation: { inflationRate },
    contingency: {
      capex: { value: capexContigency },
      opex: { value: opexContigency },
    },
  } = useAtomValue(localCostConfigurationAtom);
  const { updateLCOE, updateInflation, updateLifeCycle, updateContingency } =
    useLocalCostConfigurationCrud();

  const lifetime = decomissioning - operationStart;

  return (
    <Container>
      <TitleContainer>
        <Subtitle>Project lifetime</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Project start</SecondaryText>
          <InputNumber
            style={{ width: "20rem" }}
            disabled={inReadOnlyMode}
            value={projectStart}
            initialValue={configuration.lifeCycle.projectStart}
            onChange={(projectStart) => {
              updateLifeCycle({
                projectStart,
              });
            }}
            validate={(projectStart) =>
              projectStart >= 1950 && projectStart <= 2050
            }
            validationMessage="Please enter a project start year between 1950 and 2050"
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "lifeCycle.projectStart",
              changelogCategory,
            )}
          />
        </Label>

        <Label>
          <SecondaryText>Operation start</SecondaryText>
          <InputNumber
            style={{ width: "20rem" }}
            disabled={inReadOnlyMode}
            value={operationStart}
            initialValue={configuration.lifeCycle.operationStart}
            onChange={(operationStart) =>
              updateLifeCycle({
                operationStart,
              })
            }
            validate={(operationStart) =>
              operationStart >= projectStart &&
              operationStart < projectStart + 10
            }
            validationMessage={`Please enter an operation start year that is no earlier than the project start year of ${projectStart} and no later than ${projectStart + 9}.`}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "lifeCycle.operationStart",
              changelogCategory,
            )}
          />
        </Label>

        <Label>
          <SecondaryText>Decomissioning</SecondaryText>
          <InputNumber
            style={{ width: "20rem" }}
            disabled={inReadOnlyMode}
            value={decomissioning}
            initialValue={configuration.lifeCycle.decomissioning}
            onChange={(decomissioning) =>
              updateLifeCycle({
                decomissioning,
              })
            }
            validate={(decomissioning) =>
              decomissioning - operationStart >= 10 &&
              decomissioning - operationStart <= 50
            }
            validationMessage={`Please enter a decomissioning year between ${operationStart + 10} and ${operationStart + 50}, for a life time in the expected range of 10 to 50 years.`}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "lifeCycle.decomissioning",
              changelogCategory,
            )}
          />
        </Label>

        <Label style={{ alignSelf: "flex-end", padding: "0.4rem 1.6rem" }}>
          <Tag>Lifetime: {lifetime} years</Tag>
        </Label>
      </div>
      <TitleContainer>
        <Subtitle>Phasing</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <PhasingTable
        projectStart={projectStart}
        operationStart={operationStart}
        isReadOnly={isReadOnly}
      />
      <TitleContainer>
        <Subtitle>Contingency</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <Row>
            <SecondaryText>CAPEX</SecondaryText>
            <HelpTooltip
              size={10}
              text="Added to the CAPEX when performing LCoE and IRR analysis."
            />
          </Row>
          <InputDimensioned
            disabled={inReadOnlyMode}
            type={"number"}
            style={{
              width: "20rem",
            }}
            unit="%"
            value={capexContigency}
            scaleFactor={100}
            decimals={1}
            initialValue={configuration.contingency.capex.value}
            validate={(value) => value >= 0 && value <= 50}
            validationMessage={"CAPEX contingency must be between 0% and 50%"}
            onChange={(v) =>
              updateContingency({
                capex: {
                  type: "fraction",
                  value: v,
                },
              })
            }
            step={0.1}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "contingency.capex.value",
              changelogCategory,
            )}
          />
        </Label>

        <Label>
          <Row>
            <SecondaryText>OPEX</SecondaryText>
            <HelpTooltip
              text="Added to the OPEX when performing LCoE and IRR analysis."
              size={10}
            />
          </Row>
          <InputDimensioned
            disabled={inReadOnlyMode}
            type={"number"}
            style={{
              width: "20rem",
            }}
            unit="%"
            value={opexContigency}
            scaleFactor={100}
            decimals={1}
            initialValue={configuration.contingency.opex.value}
            validate={(value) => value >= 0 && value <= 50}
            validationMessage={"OPEX contingency must be between 0% and 50%"}
            onChange={(v) =>
              updateContingency({
                opex: {
                  type: "fraction",
                  value: v,
                },
              })
            }
            step={0.1}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "contingency.opex.value",
              changelogCategory,
            )}
          />
        </Label>
      </div>
      <TitleContainer>
        <Subtitle>Rates</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <Row>
            <SecondaryText>Discount rate (nominal)</SecondaryText>
            <HelpTooltip
              text="Also referred to as the nominal WACC."
              size={10}
            />
          </Row>
          <InputDimensioned
            disabled={inReadOnlyMode}
            type={"number"}
            style={{
              width: "20rem",
            }}
            unit="%"
            value={discountRate}
            scaleFactor={100}
            decimals={1}
            initialValue={configuration.lcoe.discountRate}
            validate={(value) => value >= 0 && value <= 20}
            validationMessage={"Discount rate must be between 0% and 20%"}
            onChange={(v) =>
              updateLCOE({
                discountRate: v,
              })
            }
            step={0.1}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "lcoe.discountRate",
              changelogCategory,
            )}
          />
        </Label>

        <Label>
          <SecondaryText>Inflation rate</SecondaryText>

          <InputDimensioned
            disabled={inReadOnlyMode}
            type={"number"}
            style={{
              width: "20rem",
            }}
            unit="%"
            scaleFactor={100}
            decimals={2}
            value={inflationRate}
            initialValue={configuration.inflation.inflationRate}
            validate={(value) => value >= 0 && value <= 20}
            validationMessage={"Inflation rate must be between 0% and 20%"}
            onChange={(v) => {
              updateInflation({
                inflationRate: v,
              });
            }}
            step={0.1}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "inflation.inflationRate",
              changelogCategory,
            )}
          />
        </Label>

        <Label style={{ alignSelf: "flex-end", padding: "0.4rem 1.6rem" }}>
          <Tag>
            Real discount rate:{" "}
            {`${(((1 + discountRate) / (1 + inflationRate) - 1) * 100).toFixed(2)}%`}
          </Tag>
        </Label>
      </div>

      <TitleContainer>
        <Subtitle>LCoE formula</Subtitle>{" "}
        <HelpLink article={ARTICLE_FINANCIAL_ANALYSIS}></HelpLink>
        <HorizontalLine />
      </TitleContainer>

      <RowWrapper>
        <RowItem>
          <SpecificLcoeFormula
            discountRate={discountRate}
            inflationRate={inflationRate}
            projectStart={projectStart}
            operationStart={operationStart}
            decomissioning={decomissioning}
            currentYear={currentYear()}
          />
        </RowItem>
      </RowWrapper>
    </Container>
  );
};
