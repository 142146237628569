import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { ProjectFeature } from "../../types/feature";
import BinIcon from "@icons/24/Bin.svg?react";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import ColorBucketIcon from "@icons/24/ColorBucket.svg?react";
import CompareIcon from "@icons/24/Compare.svg?react";
import DnDIconSmall from "@icons/12/DnDsmall.svg?react";
import DownloadIcon from "@icons/24/Download.svg?react";
import FolderOutlineIcon from "@icons/24/Folder-outline.svg?react";
import LockIcon from "@icons/24/Lock.svg?react";
import RemoveIcon from "@icons/24/Remove.svg?react";
import SectionIcon from "@icons/24/Section.svg?react";
import SearchIcon from "@icons/24/Search.svg?react";
import UnlockIcon from "@icons/24/Unlock.svg?react";
import ViewIcon from "@icons/24/View.svg?react";
import ViewOffIcon from "@icons/24/ViewOff.svg?react";
import {
  featureIsLocked,
  isDefined,
  isPointFeature,
} from "../../utils/predicates";
import { LineIconWrapper } from "../LayerList/LineIconWrapper";
import { ElementsToIcon } from "../ElementsToIcon/ElementsToIcon";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import { DotMenu, MenuButtonRef } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize } from "../../styles/typography";
import { modalTypeOpenAtom } from "../../state/modal";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "services/types";
import { CompareParksModalType } from "../CompareParksModal/CompareParksModalType";
import { selectedParksAtom } from "../CompareParksModal/state";
import { EditableText } from "../General/EditableText";
import useTextInput from "../../hooks/useTextInput";
import { dedup, platformCtrlOrCommand } from "../../utils/utils";
import {
  generateSafeElementId,
  PROJECT_ELEMENT_ORPHAN_ITEM,
  PROJECT_ELEMENT_FOLDER_ITEM,
  featuresToDownloadText,
  geotiffTypes,
  PROJECT_ELEMENT_PARK,
  PROJECT_ELEMENT_FOLDER,
  getTreeRoot,
  getDownloadMetadata,
  elementTreeFindAll,
} from "./utils";
import {
  ExpandArrowWrapper,
  FlexCenterAligned,
  ProjectElementItemWrapper,
  Ui13RegularOverflow,
  DnDIconWrapper,
} from "./ProjectElementsV2.style";
import { useProjectElementsContext } from "./ProjectElementsContext";
import { StylingSelectorBox } from "../StylingSelector/StylingSelector";
import { spaceMedium } from "../../styles/space";
import { lockedPropertyName } from "../../constants/canvas";
import { _FeatureParser } from "../../types/feature";
import { ProjectElementFolderType } from "./service";
import {
  DropCollectedProps,
  DropOnFeatureResults,
  ElementTreeNode,
} from "./types";
import { getDropCollect } from "./shared-dnd-callbacks";
import { useDnDLeafHooks } from "./hooks";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { FolderTreeMenuItem } from "./FolderTreeMenuItem";
import { useSetAtom } from "jotai";

const elementsToIconStyle: React.CSSProperties = {
  width: "1.4rem",
  height: "1.4rem",
};

const lineIconStyle: React.CSSProperties = {
  padding: "0 0.5rem 0 0",
  width: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const ProjectFeatureItem = ({
  node,
  feature,
  name,
  folder,
  isSelected,
  onAddToFolder,
  onCreateFolder,
  onDeleteFeatures,
  draggable,
  icon,
  expanded,
  onCollapseChange,
  depth = 0,
}: {
  node: ElementTreeNode;
  feature: ProjectFeature;
  name: string;
  isSelected: boolean;
  isMultiSelect?: boolean;
  draggable: boolean;
  folder?: ProjectElementFolderType;
  icon?: React.ReactNode;
  onAddToFolder?(folder: ProjectElementFolderType, featureIds: string[]): void;
  onCreateFolder?(featureIds: string[]): void;
  onDeleteFeatures?(featureIds: string[]): void;
  depth?: number;
  sortIndex?: number;
  expanded?: boolean;
  onCollapseChange?(): void;
}) => {
  const projectId = useAtomValue(projectIdAtom)!;
  const elementRef = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState<undefined | "bottom" | "top">(
    undefined,
  );
  const {
    selectedParks,
    branchId,
    editorAccessProject,
    isReadOnly,
    goToFeatures,
    updateFeatures,
    isDownloading,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
    updateProjectElementsFolder,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    setCurrentSelectionArray,
    currentSelectionArray,
    reorderTopLevel,
    toggleFeaturesSelected,
    deselectAllFeatures,
    shiftSelectFeatures,
    tree,
  } = useProjectElementsContext();
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetAtom(
    selectedParksAtom({
      projectId,
    }),
  );
  const dotMenuRef = useRef<MenuButtonRef>(null);

  const stringToUnicode = useSystemSpecificUnicode();
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(name);

  useEffect(() => {
    setWrittenName(name);
  }, [name, setWrittenName]);

  const dnd = useDnDLeafHooks({
    node,
    divRef: elementRef,
    setHoverState,
    reorderTopLevel,
    updateFolder: updateProjectElementsFolder,
  });

  const [dragCollection, dragRef] = useDrag<
    ElementTreeNode[],
    unknown,
    {
      isDragging: boolean;
    }
  >(() => {
    return {
      type: folder ? PROJECT_ELEMENT_FOLDER_ITEM : PROJECT_ELEMENT_ORPHAN_ITEM,
      item: () => {
        const root = getTreeRoot(node);
        const ids = new Set(currentSelectionArray);
        const nodes = elementTreeFindAll(root, (n) => ids.has(n.id));
        nodes.push(node);
        return dedup(nodes, (n) => n.id);
      },
      canDrag: () =>
        editorAccessProject &&
        !isReadOnly &&
        draggable &&
        !featureIsLocked(feature),
      collect: (m) => ({
        isDragging: m.isDragging(),
      }),
      end: deselectAllFeatures,
    };
  }, [
    node,
    folder,
    draggable,
    editorAccessProject,
    isReadOnly,
    feature,
    deselectAllFeatures,
    currentSelectionArray,
  ]);

  const [dropCollection, dropRef] = useDrop<
    ElementTreeNode[],
    DropOnFeatureResults,
    DropCollectedProps
  >(
    () => ({
      accept: [
        PROJECT_ELEMENT_ORPHAN_ITEM,
        PROJECT_ELEMENT_FOLDER_ITEM,
        PROJECT_ELEMENT_FOLDER,
        PROJECT_ELEMENT_PARK,
      ],
      collect: getDropCollect(),
      hover: dnd.hover,
      drop: dnd.drop,
    }),
    [dnd.drop, dnd.hover],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible([feature.id]),
    [getAreAllFeaturesVisible, feature.id],
  );

  const onChangeNameSubmit = useCallback(() => {
    return updateFeatures({
      update: [
        _FeatureParser.parse({
          ...feature,
          properties: {
            ...feature.properties,
            name: writtenName,
          },
        }),
      ],
    });
  }, [feature, updateFeatures, writtenName]);

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        !currentSelectionArray ||
        currentSelectionArray.length === 0 ||
        !deselectAllFeatures ||
        platformCtrlOrCommand(e) ||
        e.shiftKey
      ) {
        return;
      }

      // Deselect elements if user has clicked on some other feature than the ones selected
      const isInSelected = currentSelectionArray.includes(feature.id);
      if (!isInSelected) {
        deselectAllFeatures();
      }
    },
    [deselectAllFeatures, feature.id, currentSelectionArray],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.shiftKey) {
        shiftSelectFeatures(feature.id);
      } else if (platformCtrlOrCommand(e)) {
        toggleFeaturesSelected([feature.id]);
      } else {
        goToFeatures([feature]);
        setCurrentSelectionArray([feature.id]);
      }
    },
    [
      goToFeatures,
      feature,
      setCurrentSelectionArray,
      shiftSelectFeatures,
      toggleFeaturesSelected,
    ],
  );

  const allIsLocked = featureIsLocked(feature);

  const onLockFeaturesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      updateFeatureProperties([feature.id], {
        [lockedPropertyName]: !allIsLocked,
      });
    },
    [feature.id, updateFeatureProperties, allIsLocked],
  );

  const elementId = useMemo(
    () => (feature ? generateSafeElementId(feature.id) : undefined),
    [feature],
  );
  const _depth = depth ?? Number(Boolean(folder));

  dragRef(dropRef(elementRef));
  return (
    <ProjectElementItemWrapper
      ref={elementRef}
      id={elementId}
      isDragging={dragCollection.isDragging}
      isSelected={isSelected}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        dotMenuRef.current?.setIsOpen(true);
      }}
      depth={_depth}
      enableDrag={editorAccessProject && !isReadOnly}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
    >
      <DnDIconWrapper>
        <HideIfNotHoverOrVisible>
          <DnDIconSmall />
        </HideIfNotHoverOrVisible>
      </DnDIconWrapper>
      <FlexCenterAligned>
        {onCollapseChange ? (
          <ExpandArrowWrapper
            open={!!expanded}
            onClick={(event) => {
              event.stopPropagation();
              onCollapseChange();
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        ) : (
          <></>
        )}
        <LineIconWrapper style={lineIconStyle}>
          {icon ? (
            <IconREMSize height={1.4} width={1.4}>
              {icon}
            </IconREMSize>
          ) : (
            <ElementsToIcon
              elements={[feature]}
              style={elementsToIconStyle}
              fillPolygons={true}
              fillParks={true}
            />
          )}
        </LineIconWrapper>
        <EditableText
          type="text"
          disabled={!editorAccessProject || isReadOnly || allIsLocked}
          onEnter={onChangeNameSubmit}
          onCancel={onChangeNameSubmit}
          onChange={onWrittenNameChange}
          value={writtenName}
        >
          <Ui13RegularOverflow
            title={writtenName}
            style={{
              fontWeight: isSelected ? "600" : undefined,
            }}
          >
            {writtenName}
          </Ui13RegularOverflow>
        </EditableText>
      </FlexCenterAligned>
      <FlexCenterAligned>
        {editorAccessProject && (
          <HideIfNotHoverOrVisible
            className={allIsLocked ? "visible" : undefined}
            style={{
              marginRight: spaceMedium,
            }}
          >
            <IconREMSize height={1.6} width={1.6} onClick={onLockFeaturesClick}>
              {allIsLocked ? <LockIcon /> : <UnlockIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>
        )}

        <HideIfNotHoverOrVisible
          className={!allFeaturesAreVisible ? "visible" : undefined}
        >
          <IconREMSize
            title="Toggle feature visibility"
            height={1.5}
            width={1.5}
            onClick={(e) => {
              e.stopPropagation();
              toggleFeaturesHidden([feature.id], false);
            }}
          >
            {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
          </IconREMSize>
        </HideIfNotHoverOrVisible>

        {isDownloading[feature.id] ? (
          <Spinner
            style={{
              width: "1rem",
              height: "1rem",
              margin: "0 1rem",
            }}
          />
        ) : (
          <HideIfNotHoverOrVisible>
            <DotMenu ref={dotMenuRef}>
              <MenuItem name="Download" icon={<DownloadIcon />}>
                <MenuItem
                  name={featuresToDownloadText([feature], ".shp")}
                  icon={<DownloadIcon />}
                  onClick={() => {
                    const { ids, downloadedFeaturesFilename } =
                      getDownloadMetadata(
                        currentSelectionArray,
                        [feature.id],
                        writtenName,
                      );
                    return downloadMultipleFeaturesShapeUsingId(
                      ids,
                      downloadedFeaturesFilename,
                      feature.id,
                    );
                  }}
                />
                {!geotiffTypes.includes(feature.properties.type ?? "") && (
                  <MenuItem
                    name={featuresToDownloadText([feature], ".geojson")}
                    icon={<DownloadIcon />}
                    onClick={() => {
                      const { ids, downloadedFeaturesFilename } =
                        getDownloadMetadata(
                          currentSelectionArray,
                          [feature.id],
                          writtenName,
                        );
                      return downloadMultipleFeaturesGeojsonUsingId(
                        ids,
                        downloadedFeaturesFilename,
                        feature.id,
                      );
                    }}
                  />
                )}
                {!geotiffTypes.includes(feature.properties.type ?? "") && (
                  <MenuItem
                    name={featuresToDownloadText([feature], ".kml")}
                    icon={<DownloadIcon />}
                    onClick={() => {
                      const { ids, downloadedFeaturesFilename } =
                        getDownloadMetadata(
                          currentSelectionArray,
                          [feature.id],
                          writtenName,
                        );
                      return downloadMultipleFeaturesKMLUsingId(
                        ids,
                        downloadedFeaturesFilename,
                        feature.id,
                      );
                    }}
                  />
                )}
                {!geotiffTypes.includes(feature.properties.type ?? "") && (
                  <MenuItem
                    name={featuresToDownloadText([feature], ".dxf")}
                    icon={<DownloadIcon />}
                    onClick={() => {
                      const { ids, downloadedFeaturesFilename } =
                        getDownloadMetadata(
                          currentSelectionArray,
                          [feature.id],
                          writtenName,
                        );
                      return downloadMultipleFeaturesDXFUsingId(
                        ids,
                        downloadedFeaturesFilename,
                        feature.id,
                      );
                    }}
                  />
                )}
                {isPointFeature(feature) && (
                  <MenuItem
                    name="as .csv (Points only)"
                    icon={<DownloadIcon />}
                    onClick={() => {
                      const { ids, downloadedFeaturesFilename } =
                        getDownloadMetadata(
                          currentSelectionArray,
                          [feature.id],
                          writtenName,
                        );
                      return downloadMultipleFeaturesCSVUsingId(
                        ids,
                        downloadedFeaturesFilename,
                        feature.id,
                      );
                    }}
                  />
                )}
                {!geotiffTypes.includes(feature.properties.type ?? "") && (
                  <MenuItem
                    name="Using custom coordinate system"
                    icon={<DownloadIcon />}
                    onClick={() => {
                      const { ids, downloadedFeaturesFilename } =
                        getDownloadMetadata(
                          currentSelectionArray,
                          [feature.id],
                          writtenName,
                        );
                      setModalTypeOpen({
                        modalType: DownloadCustomCRSModalType,
                        metadata: {
                          featureIds: ids,
                          name: downloadedFeaturesFilename,
                          loadingId: feature.id,
                        },
                      });
                    }}
                  />
                )}
              </MenuItem>
              <MenuItem
                name={isSelected ? "Deselect" : "Multiselect"}
                icon={<SectionIcon />}
                shortcut={`${stringToUnicode("command")} + Click`}
                onClick={() => {
                  toggleFeaturesSelected([feature.id]);
                }}
              />
              {selectedParks.length > 0 && (
                <MenuItem
                  name={"Compare selected parks"}
                  icon={<CompareIcon />}
                  onClick={() => {
                    if (!branchId) {
                      return;
                    }

                    setSelectedCompareParks(
                      selectedParks.map((selectedPark) => ({
                        parkId: selectedPark.id,
                        branchId,
                        comparisonId: uuid(),
                      })),
                    );
                    setModalTypeOpen({
                      modalType: CompareParksModalType,
                    });
                  }}
                />
              )}
              <MenuItem
                name="Zoom to feature"
                icon={<SearchIcon />}
                onClick={() => goToFeatures([feature])}
              />
              {!isReadOnly && (
                <MenuItem
                  name="Set styling"
                  icon={<ColorBucketIcon />}
                  direction="right"
                  disabled={allIsLocked}
                >
                  <StylingSelectorBox
                    selectedProjectFeatures={[feature]}
                    direction="right"
                    enableColorStyling={
                      isDefined(feature) &&
                      feature.properties.type == null &&
                      ![
                        BathymetryUserUploadedType,
                        GeoTiffUserUploadedImageType,
                      ].includes(feature.properties.type ?? "")
                    }
                    enableOpacityStyling={
                      isDefined(feature) &&
                      feature.properties.type === GeoTiffUserUploadedImageType
                    }
                  />
                </MenuItem>
              )}
              {editorAccessProject && !isReadOnly && (
                <MenuItem name="Add to folder" icon={<FolderOutlineIcon />}>
                  <FolderTreeMenuItem
                    node={tree}
                    onFolderClick={(f) =>
                      onAddToFolder?.(f.folder, [feature.id])
                    }
                    onNewClick={() => onCreateFolder?.([feature.id])}
                  />
                </MenuItem>
              )}
              {editorAccessProject && !isReadOnly && (
                <>
                  {folder && (
                    <MenuItem
                      name="Remove from folder"
                      icon={<RemoveIcon />}
                      onClick={() => {
                        const featureIdsToRemove =
                          currentSelectionArray &&
                          currentSelectionArray.length > 0
                            ? currentSelectionArray
                            : [feature.id];

                        updateProjectElementsFolder?.({
                          ...folder,
                          featureIds: folder.featureIds.filter(
                            ({ id }) => !featureIdsToRemove.includes(id),
                          ),
                        });
                      }}
                    />
                  )}
                  <MenuItem
                    name="Delete"
                    disabled={allIsLocked}
                    icon={<BinIcon />}
                    onClick={() => {
                      onDeleteFeatures?.([feature.id]);
                    }}
                  />
                </>
              )}
            </DotMenu>
          </HideIfNotHoverOrVisible>
        )}
      </FlexCenterAligned>
    </ProjectElementItemWrapper>
  );
};

export default ProjectFeatureItem;
