import { useAtom } from "jotai";
import { CableFreeZoneMenuType } from "@constants/cabling";
import SubstationIcon from "@icons/24/Substation.svg";
import TurbineIcon from "@icons/24/WindTurbine.svg";
import * as turf from "@turf/turf";
import { Label } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { Column, Row } from "components/General/Layout";
import { Slider } from "components/General/Slider";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { RenderTurbineCableZones } from "components/Mapbox/TurbineCableZone";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { DefaultMap } from "lib/DefaultMap";
import { Sector } from "lib/sector";
import { useMemo, useState } from "react";
import { leftMenuModeActiveAtom } from "state/filter";
import { mapAtom } from "state/map";
import { spacing7 } from "styles/space";
import { typography } from "styles/typography";
import { throttle } from "throttle-debounce";
import {
  AnchorFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { deg2rad, degreeNormalize } from "utils/geometry";
import { dedup, roundToDecimal } from "utils/utils";
import { Editor } from "./Editor";
import { CableFreeSector } from "./types";
import {
  selectedAnchorsAtom,
  selectedMooringLinesAtom,
  selectedSubstationsAtom,
  selectedTurbinesAtom,
} from "state/jotai/selection";
import { useAtomValue } from "jotai";
import { Tag } from "components/General/Tag";
import { isOnshoreAtom } from "state/onshore";

/** Get all mooring line angles around the given turbines */
const useMooringAngles = (turbines: (TurbineFeature | SubstationFeature)[]) => {
  const mooringLines = useAtomValue(selectedMooringLinesAtom);
  const anchors = useAtomValue(selectedAnchorsAtom);

  const mooringAngles = useMemo(() => {
    const anchorMap = new Map(anchors.map((a) => [a.id, a]));
    const map = new DefaultMap<string, AnchorFeature[]>(() => []);
    for (const l of mooringLines) {
      const a = anchorMap.get(l.properties.anchor);
      if (a) map.get(l.properties.target).push(a);
    }
    const angles = turbines.flatMap((t) => {
      const anchors = map.get(t.id);
      return anchors.map((a) => {
        const b = turf.bearing(t, a);
        return deg2rad(degreeNormalize(b));
      });
    });
    return dedup(angles.map((a) => roundToDecimal(a, 2)));
  }, [anchors, mooringLines, turbines]);

  return mooringAngles;
};

const Inner = () => {
  const map = useAtomValue(mapAtom);
  const onshore = useAtomValue(isOnshoreAtom);
  const [showMooring, setShowMooring] = useState(false);
  const { update: updateFeatures } = useProjectElementsCrud();

  const turbines = useAtomValue(selectedTurbinesAtom);
  const substations = useAtomValue(selectedSubstationsAtom);
  const mooringAngles = useMooringAngles(turbines);

  type Ft = TurbineFeature | SubstationFeature;

  const features = useMemo(
    () => (turbines as Ft[]).concat(substations),
    [substations, turbines],
  );

  const hasDifferentSectors = useMemo(() => {
    if (features.length === 0) return false;
    const key = (s: CableFreeSector): string =>
      `${s.middle.toFixed(5)}-${s.span.toFixed(5)}-${s.distanceM.toFixed(5)}`;
    const seen = new Set(
      (features[0].properties.cableFreeSectors ?? []).map(key),
    );
    for (const f of features.slice(1))
      for (const s of f.properties.cableFreeSectors ?? [])
        if (!seen.has(key(s))) return true;
    return false;
  }, [features]);

  const [length, setLength] = useState<number>(() => {
    const secs = features.flatMap((f) => f.properties.cableFreeSectors ?? []);
    return dedup(secs.map((s) => s.distanceM))[0] ?? 100;
  });

  const [sectors, setSectors] = useState<Sector[]>(() => {
    const sectors = features.flatMap(
      (f) => f.properties.cableFreeSectors ?? [],
    );
    const deduped = dedup(
      sectors,
      (s) => `${s.middle.toFixed(5)}-${s.span.toFixed(5)}`,
    );
    return deduped.map((s) => new Sector(s.middle, s.span));
  });

  const cableSectors = useMemo(
    () =>
      sectors.map((s) => ({
        ...s,
        distanceM: length,
      })),
    [length, sectors],
  );

  const save = useMemo(
    () =>
      throttle(
        500,
        (features: Ft[], sectors: Sector[], distanceM: number) => {
          updateFeatures({
            update: features.map((f) => ({
              ...f,
              properties: {
                ...f.properties,
                cableFreeSectors: sectors.map((s) => ({
                  ...s,
                  distanceM,
                })),
              },
            })),
          });
        },
        {
          noLeading: true,
        },
      ),
    [updateFeatures],
  );

  return (
    <>
      <Column
        style={{
          gap: spacing7,
        }}
      >
        <Row>
          {0 < turbines.length && (
            <Tag text={turbines.length} icon={<TurbineIcon />} />
          )}
          {0 < substations.length && (
            <Tag text={substations.length} icon={<SubstationIcon />} />
          )}
          {turbines.length === 0 && substations.length === 0 && (
            <SimpleAlert type="warning" text="Selection is empty" />
          )}
        </Row>
        <p style={typography.caption}>
          Create cable-free sectors around turbines and substations.
        </p>
        {!onshore && (
          <>
            <Label left>
              <Toggle
                checked={showMooring}
                onChange={(e) => setShowMooring(e.target.checked)}
                size={ToggleSize.SMALL}
              />
              <p>Show mooring lines</p>
            </Label>
          </>
        )}
        <Row
          style={{
            gap: spacing7,
            alignItems: "center",
          }}
        >
          <InputDimensioned
            value={length}
            unit="m"
            validate={(n) => !isNaN(n) && 0 < n}
            onChange={(e) => {
              setLength(e);
              save(features, sectors, e);
            }}
          />
          <Slider
            value={length}
            min={100}
            max={500}
            step={1}
            onChange={(e) => {
              setLength(e);
              save(features, sectors, e);
            }}
          />
        </Row>

        <Editor
          sectors={sectors}
          allGhost={hasDifferentSectors}
          setSectors={(s) => {
            setSectors(s);
            save(features, s, length);
          }}
          ghostAngles={showMooring ? mooringAngles : undefined}
        />

        {sectors.length === 0 ? (
          <SimpleAlert
            type="info"
            title="No cable sectors"
            text="To add a sector, shift+click on the circle above, and drag to control the size. "
          />
        ) : hasDifferentSectors ? (
          <SimpleAlert
            type="info"
            title="Different sectors"
            text="Selection contains different sectors.  Any new sectors will overwrite existing sectors."
          />
        ) : null}
      </Column>
      {map && (
        <RenderTurbineCableZones
          map={map}
          sectors={hasDifferentSectors ? undefined : cableSectors}
          features={features}
        />
      )}
    </>
  );
};

export const CableFreeSectorsPanel = () => {
  const [menu, setMenu] = useAtom(leftMenuModeActiveAtom);
  if (menu !== CableFreeZoneMenuType) return null;
  return (
    <MenuFrame
      title="Cable sectors"
      onExit={() => {
        setMenu(undefined);
      }}
    >
      <Inner />
    </MenuFrame>
  );
};
