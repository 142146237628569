import SphericalMercator from "@mapbox/sphericalmercator";
import { PointWithLngLag as PointWithLngLat } from "types/tile";

export const calculateSample = (
  lng: number,
  lat: number,
  zoom: number,
  x: number,
  y: number,
  tileSize: number,
  sample: ImageData,
  merc: SphericalMercator,
): [number, number, number, number] => {
  const pixelCoords = merc.px([lng, lat], zoom);

  const localPixelCoords = [
    Math.min(pixelCoords[0] - x * tileSize, tileSize - 1), // Unsure why some local coordinates are larger than tile size, should be investigated
    Math.min(pixelCoords[1] - y * tileSize, tileSize - 1),
  ];

  const startPoint = (localPixelCoords[0] + localPixelCoords[1] * tileSize) * 4;

  return [
    sample.data[startPoint],
    sample.data[startPoint + 1],
    sample.data[startPoint + 2],
    sample.data[startPoint + 3],
  ];
};

export const getXTileNumber = (lng: number, zoom: number) =>
  Math.floor(((lng + 180) / 360) * Math.pow(2, zoom));
export const getYTileNumber = (lat: number, zoom: number) =>
  Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180),
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom),
  );

export const addTileNumberToPoint = (
  point: number[],
  zoom: number,
): PointWithLngLat => {
  const lng = point[0];
  const lat = point[1];
  return {
    x: getXTileNumber(lng, zoom),
    y: getYTileNumber(lat, zoom),
    lng,
    lat,
  };
};
