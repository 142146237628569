import { useSearch } from "./useSearch";
import { organisationFoundationResourceState } from "components/Organisation/Library/state";
import { orgFoundationManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FoundationType } from "types/foundations";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";

const keys = ["name", "description"];

export function useSearchLibraryFoundations(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgFoundationManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredFoundations, rawData } =
    useSearch<FoundationType>(
      enabled,
      searchTerm,
      keys,
      organisationFoundationResourceState({
        organisationId,
      }),
    );

  const filteredSearchItems: SearchResultItem<FoundationType>[] =
    useMemo(() => {
      return filteredFoundations.map((result) => ({
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: getIcon(LibraryManageRole.FOUNDATION),
        onSelect: () => {
          navigate(
            `/organisation/${organisationId}/library/foundation/${result.item.id}`,
          );
        },
      }));
    }, [filteredFoundations, navigate, organisationId]);

  const searchableItems: SearchItem<FoundationType>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/foundation/${item.id}`,
        );
      },
      icon: getIcon(LibraryManageRole.FOUNDATION),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredFoundations: filteredSearchItems,
    searchableItems,
  };
}
