import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useState } from "react";
import MembersTabs from "components/Organisation/Members/MembersTabs";
import { Row } from "components/General/Layout";
import Button from "components/General/Button";
import Spinner from "@icons/spinner/Spinner";
import InviteIcon from "@icons/24/Invite.svg";
import { adminInOrganisationSelectorFamily } from "state/user";
import useGroupsCrud from "components/Organisation/Groups/useGroupsCrud";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { inviteToOrganisationOpenAtom } from "components/Organisation/state";
import { TabHeader } from "components/Organisation/style";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { VerticalDivider } from "components/General/VerticalDivider";
import { spacing4 } from "styles/space";
import NewItemModal from "components/NewItemModal/NewItemModal";

const MembersPage = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const setInviteToOrganisationOpen = useSetAtom(inviteToOrganisationOpenAtom);
  const [showNewGroupModal, setShowNewGroupModal] = useState(false);
  const { add } = useGroupsCrud();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );

  const [createGroupInProgress, setCreateGroupInProgress] = useState(false);

  const onCreate = useCallback(
    async (name: string) => {
      try {
        setCreateGroupInProgress(true);
        const group = await add(name);
        if (group) {
          setContent({
            type: "group",
            id: group.id,
          });
        }
      } catch {
      } finally {
        setCreateGroupInProgress(false);
      }
    },
    [add, setContent],
  );

  return (
    <>
      <Row
        style={{
          justifyContent: "space-between",
          padding: "0 0 2.4rem",
        }}
      >
        <TabHeader>Members</TabHeader>
        {showNewGroupModal && (
          <NewItemModal
            title="Create new group"
            placeholder="Enter group name"
            defaultValue={`Group ${groups.length + 1}`}
            onClose={() => {
              setShowNewGroupModal(false);
            }}
            onSubmit={onCreate}
          />
        )}

        <Row alignCenter>
          {isAdminInOrg && (
            <Button
              buttonType="secondary"
              text="New group"
              onClick={() => setShowNewGroupModal(true)}
              disabled={createGroupInProgress}
              icon={createGroupInProgress ? <Spinner size="1rem" /> : undefined}
            />
          )}
          <Button
            buttonType="primary"
            text="Invite members"
            onClick={() => setInviteToOrganisationOpen(true)}
            icon={<InviteIcon />}
          />
          <VerticalDivider
            height={3}
            style={{
              margin: `0 ${spacing4}`,
            }}
          />
          <OpenMoreInfo
            onClick={() => {
              setContent((curr) =>
                curr
                  ? undefined
                  : {
                      type: "no-item-selected",
                    },
              );
            }}
            isOpen={!!content}
          />
        </Row>
      </Row>
      <MembersTabs />
    </>
  );
};

export default MembersPage;
