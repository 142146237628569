import { branchIdAtom, projectIdAtom } from "state/pathParams";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import AddIcon from "@icons/24/Add.svg";
import DuplicateIcon from "@icons/24/Duplicate.svg";
import OpenIcon from "@icons/24/Open.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import StackedIcon from "@icons/24/Stacked.svg";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { scream } from "utils/sentry";
import BrowserStyleTabs, {
  TabItemProps,
} from "components/General/BrowserStyleTabs/BrowserStyleTabs";
import {
  TabItemText,
  TabItemWrapper,
} from "components/General/BrowserStyleTabs/style";
import { editorAccessProjectSelector } from "state/user";
import { useCreateBranch, useUpdateBranch } from "state/timeline";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { useToast } from "hooks/useToast";
import AllBranchesAndArchivedBranchesFrame from "components/Design/BranchTabBar/components/AllBranchesAndArchivedBranchesFrame";
import useNavigateToBranch, { useGetPathToBranch } from "./useNavigateToBranch";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
  openedExistingBranchesTabsAtomFamily,
} from "./state";
import { BranchMeta } from "types/api";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { designToolTypeAtom } from "state/map";
import { DesignToolMode } from "types/map";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { Column } from "components/General/Layout";
import { spacing5 } from "styles/space";
import { Menu, MenuDivider, MenuItem } from "components/General/Menu";
import { useClickOutside } from "hooks/useClickOutside";
import { EditableTextInternalState } from "components/General/EditableText";
import eventEmitter from "utils/eventEmitter";
import { showDuplicateBranchForBranchIdAtom } from "components/DuplicateBranchModal/state";
import { TourStep } from "components/OnboardingTours/TourStep";
import { FloatingFocusManager, offset, useFloating } from "@floating-ui/react";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useJotaiCallback } from "utils/jotai";

const BranchTabBarWrapper = styled.div<{
  mode: DesignToolMode;
}>`
  --branch-tab-bar-background: ${(p) =>
    p.mode === DesignToolMode.Onshore ? colors.seagreen700 : colors.blue800};
  --branch-tab-bar-item-hover: ${(p) =>
    p.mode === DesignToolMode.Onshore ? colors.seagreen800 : colors.blue900};
  --branch-tab-bar-item-selected: ${(p) =>
    p.mode === DesignToolMode.Onshore ? colors.seagreen100 : colors.blue200};
  --branch-tab-bar-item-highlight-text: ${(p) =>
    // This is used for the "All branches" button when the panel is open.
    p.mode === DesignToolMode.Onshore ? colors.blue300 : colors.blue300};

  display: flex;
  position: relative;
  background-color: var(--branch-tab-bar-background);
  z-index: 6;
`;

const getRenameBranchEventName = (branchId: string) =>
  "SET_RENAME_BRANCH".concat("-", branchId);

const TabBranchItem = ({
  item,
  active,
  renameBranch,
}: {
  item: TabItemProps;
  active: boolean;
  renameBranch(item: TabItemProps, name: string): void;
}) => {
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);

  useEffect(() => {
    const onRenameEvent = () => {
      setIsRenaming(true);
    };
    eventEmitter.on(getRenameBranchEventName(item.id), onRenameEvent);
    return () => {
      eventEmitter.off(getRenameBranchEventName(item.id), onRenameEvent);
    };
  }, [item.id]);

  return (
    <EditableTextInternalState
      disabled={true}
      smallInput={true}
      overlap={true}
      selected={active}
      value={item.title}
      renderText={(text) => (
        <span
          style={{
            ...typography.contentAndButtons,
            textDecoration: "none",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: active ? colors.textBrand : colors.textNegative,
          }}
          onClick={() => {
            setTopRightModeActive(undefined);
          }}
        >
          <TabItemText>{text}</TabItemText>
        </span>
      )}
      editTextColor={active ? colors.textBrand : colors.textNegative}
      editIconHoverColor={colors.blue600}
      editIconColor={active ? colors.blue800 : colors.white}
      textContainerStyle={{
        padding: 0,
      }}
      isEditing={isRenaming}
      onEnter={(newName) => {
        renameBranch(item, newName);
      }}
      onAfter={() => {
        setIsRenaming(false);
      }}
    />
  );
};

const BranchRightClickMenu = ({
  item,
  x,
  y,
  hasMultipleBranchesOpen,
  editorAccessProject,
  onCloseTab,
  onClose,
}: {
  item: TabItemProps;
  x: number;
  y: number;
  hasMultipleBranchesOpen: boolean;
  editorAccessProject: boolean;
  onCloseTab(item: TabItemProps, e: React.MouseEvent): void;
  onClose(): void;
}) => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const getPathToBranch = useGetPathToBranch();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const setShowDuplicateBranchForBranchId = useSetAtom(
    showDuplicateBranchForBranchIdAtom,
  );
  const setOpenedBranchIds = useSetAtom(
    openedExistingBranchesTabsAtomFamily({
      nodeId,
    }),
  );
  const navigateToBranch = useNavigateToBranch();

  useClickOutside(elementRef, onClose, undefined, {
    runCheckOnClick: true,
  });

  return (
    <Menu
      submenuRef={elementRef}
      style={{
        position: "fixed",
        top: y + 10,
        left: x + 10,
        zIndex: 3,
      }}
    >
      {editorAccessProject && (
        <>
          <MenuItem
            name="Rename"
            icon={<PencilIcon />}
            onClick={() => {
              eventEmitter.emit(getRenameBranchEventName(item.id));
              onClose();
            }}
          />
          <HighlightStep
            tourId="general-intro-tour"
            stepId="duplicateBranch"
            style={{
              position: "relative",
              zIndex: 10,
            }}
          >
            <HighlightStep
              tourId="onshore-intro-tour"
              stepId="duplicateBranch"
              style={{
                position: "relative",
                zIndex: 10,
              }}
            >
              <MenuItem
                name="Duplicate"
                icon={<DuplicateIcon />}
                onClick={() => {
                  setShowDuplicateBranchForBranchId(item.id);
                  onClose();
                }}
              />
            </HighlightStep>
          </HighlightStep>
        </>
      )}
      <MenuItem
        name="Open in new browser tab"
        icon={<OpenIcon />}
        onClick={() => {
          window.open(getPathToBranch(item.id));
          onClose();
        }}
      />
      {hasMultipleBranchesOpen && (
        <>
          <MenuDivider />
          <MenuItem
            name="Close"
            onClick={(e) => {
              onCloseTab(item, e);
              onClose();
            }}
          />
          <MenuItem
            name="Close other branches"
            onClick={async () => {
              setOpenedBranchIds([item.id]);
              await navigateToBranch(item.id, true);
              onClose();
            }}
          />
        </>
      )}
    </Menu>
  );
};

const BranchTabBar = ({ children }: React.PropsWithChildren) => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const [openedBranches, setOpenedBranchIds] = useAtom(
    openedExistingBranchesTabsAtomFamily({
      nodeId,
    }),
  );

  // Set openedExistingBranchesTabAtomFamily once to write what is in the selector to LS
  // This is a workaround to ensure that the current branch is set to the local storage
  // since openedExistingBranchesTabsAtomFamily is not directly a localStorage Atom
  const writeCurrentTabBarBranchesToLS = useJotaiCallback(
    (get, set) => {
      get(openedExistingBranchesTabsAtomFamily({ nodeId })).then((branches) => {
        set(openedExistingBranchesTabsAtomFamily({ nodeId }), () =>
          branches.map((f) => f.id),
        );
      });
    },
    [nodeId],
  );

  useEffect(() => {
    writeCurrentTabBarBranchesToLS();
  }, [writeCurrentTabBarBranchesToLS]);

  const allProjectBranches = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId,
    }),
  );
  const [showContextMenu, setShowContextMenu] = useState<
    | {
        item: TabItemProps;
        postition: {
          x: number;
          y: number;
        };
      }
    | undefined
  >(undefined);

  const [isCreatingBranch, setIsCreatingBranch] = useAtom(
    allBranchesFrameLoadingAtom,
  );
  const [showCreateNewBranch, setShowCreateNewBranch] = useState(false);
  const [branchFrameVisible, setBranchFrameVisible] = useAtom(
    allBranchesFrameOpenedAtom,
  );

  const { error: showError } = useToast();
  const { create: createBranch } = useCreateBranch();
  const navigateToBranch = useNavigateToBranch();
  const updateBranch = useUpdateBranch();
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const tabs = useMemo<TabItemProps[]>(() => {
    return openedBranches.map((branch) => ({
      id: branch.id,
      text: branch.title,
      title: branch.title,
      tooltip: (
        <Column style={{ gap: spacing5 }}>
          <p
            style={{
              ...typography.sub2,
              color: colors.white,
              fontWeight: 600,
            }}
          >
            {branch.title}
          </p>
          {branch.description && (
            <p style={{ ...typography.caption, color: colors.white }}>
              {branch.description}
            </p>
          )}
        </Column>
      ),
    }));
  }, [openedBranches]);

  const onCreateBranch = useCallback(
    async (name: string) => {
      setIsCreatingBranch(true);
      try {
        const { meta } = await createBranch(nodeId, {
          title: name,
        });
        navigateToBranch(meta.id, true);
      } catch (err) {
        if (err instanceof Error) {
          scream(err, {
            nodeId,
          });
        } else {
          scream(new Error("Failed to create branch"), {
            nodeId,
            err,
          });
        }
        showError("Failed to create branch, please try again.");
      } finally {
        setIsCreatingBranch(false);
      }
    },
    [createBranch, navigateToBranch, nodeId, showError, setIsCreatingBranch],
  );

  const renameBranch = useCallback(
    async (item: TabItemProps, name: string) => {
      if (name === item.text || name.length === 0) {
        return;
      }
      await updateBranch({
        id: item.id,
        title: name,
      });
    },
    [updateBranch],
  );

  const mode = useAtomValue(designToolTypeAtom);
  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);

  const setOpenedTabsAndNavigateIfIndexIsSet = useCallback(
    (tab: TabItemProps, newIndex?: number) => {
      setOpenedBranchIds((curr) => {
        return curr.filter((id) => id !== tab.id);
      });
      if (newIndex !== undefined && tabs[newIndex]) {
        navigateToBranch(tabs[newIndex].id, false);
      }
    },
    [navigateToBranch, setOpenedBranchIds, tabs],
  );

  const onCloseTab = useCallback(
    (item: TabItemProps, e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const closedItem = item;

      const selectedIndex = tabs.findIndex((item) => item.id === branchId);
      const closedItemIndex = tabs.findIndex(
        (item) => item.id === closedItem.id,
      );
      const selectedItem = tabs[selectedIndex];

      const didCloseSelectedItem =
        selectedItem && closedItem.id === selectedItem.id;

      const didCloseLastItem = closedItemIndex === tabs.length - 1;

      if (didCloseSelectedItem) {
        const indexBeforeThisOne = selectedIndex - 1;

        if (didCloseLastItem) {
          const newIndex = Math.min(indexBeforeThisOne, tabs.length);
          if (tabs[newIndex]) {
            setOpenedTabsAndNavigateIfIndexIsSet(closedItem, newIndex);
          }
        } else {
          setOpenedTabsAndNavigateIfIndexIsSet(closedItem, closedItemIndex + 1);
        }
      } else {
        setOpenedTabsAndNavigateIfIndexIsSet(closedItem, undefined);
      }
    },
    [branchId, setOpenedTabsAndNavigateIfIndexIsSet, tabs],
  );

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    middleware: [
      offset({
        mainAxis: 80,
        crossAxis: 100,
      }),
    ],
  });

  const handleStepAction = useCallback(() => {
    if (!showContextMenu) {
      setShowContextMenu({
        item: tabs[0],
        postition: {
          x: (refs.reference.current?.getBoundingClientRect().x ?? 0) + 10,
          y: (refs.reference.current?.getBoundingClientRect().y ?? 0) + 10,
        },
      });
      setTopRightModeActive(undefined);
    }
  }, [showContextMenu, tabs, refs.reference, setTopRightModeActive]);

  const handleExitStepAction = useCallback(() => {
    setShowContextMenu(undefined);
  }, []);

  return (
    <>
      <BranchTabBarWrapper id="branch-bar" mode={mode}>
        <FloatingFocusManager context={context} modal={false}>
          <TourStep
            tourId="general-intro-tour"
            stepId="duplicateBranch"
            innerRef={refs.setFloating}
            style={floatingStyles}
            onEnterStepAction={handleStepAction}
            onExitStepAction={handleExitStepAction}
          />
        </FloatingFocusManager>
        <FloatingFocusManager context={context} modal={false}>
          <TourStep
            tourId="onshore-intro-tour"
            stepId="duplicateBranch"
            innerRef={refs.setFloating}
            style={floatingStyles}
            onEnterStepAction={handleStepAction}
            onExitStepAction={handleExitStepAction}
          />
        </FloatingFocusManager>
        {showCreateNewBranch && (
          <NewItemModal
            title="New branch"
            placeholder="Enter branch name"
            defaultValue={`Branch ${allProjectBranches.length + 1}`}
            onSubmit={onCreateBranch}
            onClose={() => setShowCreateNewBranch(false)}
          />
        )}
        {branchFrameVisible && (
          <React.Suspense fallback={null}>
            <AllBranchesAndArchivedBranchesFrame
              onExit={() => setBranchFrameVisible(false)}
            />
          </React.Suspense>
        )}
        <BrowserStyleTabs
          wrapperStyle={{
            width: "unset",
            flexGrow: 1,
          }}
          itemStyle={{ maxWidth: "30rem" }}
          items={tabs}
          selectedId={branchId}
          onClickTab={(tab) => {
            navigateToBranch(tab.id, false);
          }}
          onCloseTab={tabs.length > 1 ? onCloseTab : undefined}
          onDragTab={(oldIndex, newIndex) => {
            const item = openedBranches[oldIndex] as BranchMeta;
            const newBranchIds = [...openedBranches.map((b) => b.id)];
            newBranchIds.splice(oldIndex, 1);
            const adjustedNewIndex =
              newIndex > oldIndex ? newIndex - 1 : newIndex;
            newBranchIds.splice(adjustedNewIndex, 0, item.id);
            return setOpenedBranchIds(() => newBranchIds);
          }}
          leftAction={
            <TabItemWrapper
              onClick={() => setBranchFrameVisible((curr) => !curr)}
              staticSize={true}
            >
              <IconREMSize
                height={1.2}
                width={1.2}
                fill={
                  branchFrameVisible
                    ? "var(--branch-tab-bar-item-highlight-text)"
                    : undefined
                }
              >
                <StackedIcon />
              </IconREMSize>
              <TabItemText
                ref={refs.setReference}
                style={{
                  color: branchFrameVisible
                    ? "var(--branch-tab-bar-item-highlight-text)"
                    : undefined,
                }}
              >
                All
              </TabItemText>
            </TabItemWrapper>
          }
          renderItem={(item, active) => {
            return (
              <TabBranchItem
                item={item}
                active={active}
                renameBranch={renameBranch}
              />
            );
          }}
          onRightClick={(item, e) => {
            e.preventDefault();
            setShowContextMenu({
              item,
              postition: {
                x: e.clientX,
                y: e.clientY,
              },
            });
          }}
          rightAction={
            editorAccessProject ? (
              <TabItemWrapper
                staticSize={true}
                onClick={() => setShowCreateNewBranch(true)}
                disabled={isCreatingBranch}
              >
                <IconREMSize height={1.2} width={1.2} stroke={colors.white}>
                  {isCreatingBranch ? (
                    <Spinner color={colors.grey300} size="1rem" />
                  ) : (
                    <AddIcon />
                  )}
                </IconREMSize>
                <TabItemText>New branch</TabItemText>
              </TabItemWrapper>
            ) : null
          }
        />
        {children}
        {showContextMenu && (
          <BranchRightClickMenu
            item={showContextMenu.item}
            x={showContextMenu.postition.x}
            y={showContextMenu.postition.y}
            onCloseTab={onCloseTab}
            onClose={() => setShowContextMenu(undefined)}
            editorAccessProject={editorAccessProject}
            hasMultipleBranchesOpen={openedBranches.length > 1}
          />
        )}
      </BranchTabBarWrapper>
    </>
  );
};

export default BranchTabBar;
