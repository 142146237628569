import { fromBlob } from "geotiff";
import { colors } from "./../styles/colors";
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// https://gist.github.com/robertnsharp/49fd46a071a267d9e5dd
function randomPastelColor(input_str: string) {
  const baseRed = 128;
  const baseGreen = 128;
  const baseBlue = 128;

  let seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
  const rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256;

  const red = Math.round((rand_1 + baseRed) / 2);
  const green = Math.round((rand_2 + baseGreen) / 2);
  const blue = Math.round((rand_3 + baseBlue) / 2);

  const backgroundColor = rgbToHex(red, green, blue);
  const textColor = getContrastingTextColor(red, green, blue);

  return { backgroundColor, textColor };
}

function getContrastingTextColor(r: number, g: number, b: number) {
  // Calculate the relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  // Standard threshold for deciding between black and white text
  return luminance > 128 ? colors.primaryText : colors.lightText;
}

const getInitials = (name: string) => {
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  const initials =
    nameLength > 1
      ? nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1)
      : nameSplit[0].substring(0, 1);

  return initials.toUpperCase();
};

const size = 128;
export const createImageFromInitials = (name: string, color?: string) => {
  name = getInitials(name);

  const _color = color ?? randomPastelColor(name).backgroundColor;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) return "";
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${_color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = _color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `500 8rem Open Sans`;
  context.fillText(name, size / 2, size / 1.85);

  return canvas.toDataURL();
};

export function getRGBAArray(
  img: HTMLImageElement,
): [number[], number[], number[], number[]] {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const rArray: number[] = [];
  const gArray: number[] = [];
  const bArray: number[] = [];
  const aArray: number[] = [];
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    const a = imageData.data[i + 3];
    rArray.push(r);
    gArray.push(g);
    bArray.push(b);
    aArray.push(a);
  }

  return [rArray, gArray, bArray, aArray];
}

async function loadImageBitmap(url: string): Promise<ImageBitmap> {
  const response = await fetch(url);
  const blob = await response.blob();
  return await createImageBitmap(blob);
}

export async function mergeImagesOffscreenCanvas(
  urls: string[],
  cols: number,
  removeFramePixels = 0,
): Promise<OffscreenCanvas> {
  const images = await Promise.all(urls.map((url) => loadImageBitmap(url)));
  const rows = Math.ceil(images.length / cols);
  const canvas = new OffscreenCanvas(1, 1); // Initial size will be updated
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Canvas context could not be retrieved");
  }

  const imgWidth = images[0].width - removeFramePixels * 2;
  const imgHeight = images[0].height - removeFramePixels * 2;

  canvas.width = imgWidth * cols;
  canvas.height = imgHeight * rows;

  images.forEach((img, index) => {
    const x = (index % cols) * imgWidth;
    const y = Math.floor(index / cols) * imgHeight;

    ctx.drawImage(
      img,
      removeFramePixels,
      removeFramePixels,
      imgWidth,
      imgHeight,
      x,
      y,
      imgWidth,
      imgHeight,
    );
  });

  return canvas;
}

export async function mergeImages(
  urls: string[],
  cols: number,
  removeFramePixels = 0,
): Promise<HTMLCanvasElement> {
  const offscreenCanvas = await mergeImagesOffscreenCanvas(
    urls,
    cols,
    removeFramePixels,
  );
  const canvas = document.createElement("canvas");
  canvas.width = offscreenCanvas.width;
  canvas.height = offscreenCanvas.height;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(offscreenCanvas, 0, 0);

  return canvas;
}

export const geotiffResponseToFloat32Array = async (
  blob: Blob,
): Promise<{
  array: Float32Array;
  width: number;
  height: number;
}> => {
  const tiff = await fromBlob(blob);
  const image = await tiff.getImage();
  const values = (await image.readRasters())[0] as Float32Array;
  const height = image.getHeight();
  const width = image.getWidth();
  return {
    array: values,
    width,
    height,
  };
};

export async function canvasToImage(
  canvas: HTMLCanvasElement,
): Promise<HTMLImageElement> {
  const img = new Image();
  img.src = canvas.toDataURL();
  return await new Promise((resolve) => {
    img.onload = function () {
      resolve(img);
    };
  });
}
