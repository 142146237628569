import { ReactNode, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import LikeIcon from "@icons/24/Like.svg?react";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;
const Content = styled.div<{
  like: boolean;
  disabled?: boolean;
  threadIsSelected?: boolean;
  parentIsHovered?: boolean;
  hasLikes?: boolean;
}>`
  ${(p) => p.disabled && "pointer-events: none;"}
  padding: 0.2rem 0.8rem;
  width: fit-content;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  opacity: ${(p) => (p.like || p.parentIsHovered || p.hasLikes ? 1 : 0)};

  > svg {
    height: 1.2rem;
    width: 1.2rem;
    path {
      stroke: ${(p) => (p.like ? colors.iconSelected : colors.iconBrand)};
      fill: ${(p) => (p.like ? colors.iconSelected : "none")};
    }
  }

  &:hover {
    background: ${(p) => (p.threadIsSelected ? colors.blue200 : colors.grey200)};
}
  }
`;

const Number = styled.p<{ like: boolean }>`
  margin: 0;
  color: ${(p) => (p.like ? colors.textSelected : colors.textBrand)};
`;

const ReactionContainer = styled(StandardBox)`
  position: absolute;
  top: 125%;
  right: 0%;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  z-index: 2;
  width: fit-content;
`;

export default function Like({
  like,
  onClick,
  totalLikes,
  reactionList,
  disabled,
  threadIsSelected,
  parentIsHovered,
}: {
  like: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  totalLikes: number;
  reactionList: ReactNode;
  disabled?: boolean;
  threadIsSelected?: boolean;
  parentIsHovered?: boolean;
}) {
  const [showReactions, setShowReaction] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const refPos = ref.current?.getBoundingClientRect();

  let hoverTimeout = useRef<NodeJS.Timeout | string | number | undefined>();

  return (
    <Wrapper
      ref={ref}
      onMouseEnter={() => {
        if (totalLikes > 0 && !disabled) {
          setShowReaction(true);
          clearTimeout(hoverTimeout.current);
        }
      }}
      onMouseLeave={() => {
        hoverTimeout.current = setTimeout(() => {
          setShowReaction(false);
        }, 80);
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <Content
        parentIsHovered={parentIsHovered}
        disabled={disabled}
        like={like}
        hasLikes={totalLikes > 0}
        threadIsSelected={threadIsSelected}
      >
        <LikeIcon />
        <Number like={like}>{totalLikes}</Number>
      </Content>
      {showReactions &&
        refPos &&
        createPortal(
          <ReactionContainer
            style={{
              top: `${refPos.top + refPos.height}px`,
              left: `${refPos.left}px`,
            }}
          >
            {reactionList}
          </ReactionContainer>,
          document.body,
        )}
    </Wrapper>
  );
}
