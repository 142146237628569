import IconPolygon from "@icons/24/Polygon.svg?react";
import IconTurbine from "@icons/24/Turbine.svg?react";
import { Tag } from "components/General/Tag";

export const TargetParkCapacityTag = ({ n }: { n: number }) => (
  <Tag text={`${n} MW`} icon={<IconPolygon />} tooltip="Target park capacity" />
);

export const TargetTurbineTag = ({ n }: { n: number }) => (
  <Tag icon={<IconTurbine />} text={`${n} MW`} tooltip="Turbine power rating" />
);

export const TargetDensityTag = ({ n }: { n: number }) => (
  <Tag text={`${n} MW/km2`} icon={<IconTurbine />} tooltip="Density" />
);

export const SubAreaTag = ({ tooltip }: { tooltip?: string }) => {
  return <Tag style="success" text="Selected sub area(s)" tooltip={tooltip} />;
};
