import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import {
  followThreadsAtomFamily,
  threadsAtomFamily,
  userReactionsAtomFamily,
} from "components/Comments/state";
import { dashboardsAtom } from "components/Dashboard/state";
import {
  allBackendLayerCollectionsAtomFamily,
  getAllLayerCollections,
} from "components/LayerList/Collections/state";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import {
  analysisResourceWithAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import {
  getProjectElementsSortOrderAtomFamily,
  projectFoldersAtomFamily,
} from "components/ProjectElementsV2/state";
import { RESET, useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import {
  branchMetasBySortOrderFamily,
  branchMetasFamily,
} from "state/jotai/branch";
import { useJotaiCallback } from "utils/jotai";
import { allFeaturesFamily } from "state/jotai/features";
import { analysisConfigurationsRefetch } from "state/jotai/analysisConfiguration";
import { costConfigurationsRefetch } from "state/jotai/costConfiguration";

export const useRefreshBranchState = () => {
  const refreshBranchState = useAtomCallback(
    useCallback((_, set, projectId: string, branchId: string) => {
      set(
        threadsAtomFamily({
          nodeId: projectId,
          branchId,
        }),
        RESET,
      );
      set(
        userReactionsAtomFamily({
          nodeId: projectId,
          branchId,
        }),
        RESET,
      );
      set(
        followThreadsAtomFamily({
          nodeId: projectId,
          branchId,
        }),
        RESET,
      );
      set(
        projectFoldersAtomFamily({
          projectId,
          branchId,
          version: undefined,
        }),
        RESET,
      );
      set(
        getProjectElementsSortOrderAtomFamily({
          nodeId: projectId,
          branchId,
        }),
        RESET,
      );
      set(
        allFeaturesFamily({
          projectId: projectId,
          branchId,
          version: undefined,
        }),
        RESET,
      );
    }, []),
  );

  return refreshBranchState;
};

export default function useRefreshProjectState() {
  const projectId = useAtomValue(projectIdAtom);
  const refreshBranchState = useRefreshBranchState();

  const refreshProjectStateJ = useJotaiCallback((_, set, projectId: string) => {
    set(
      dashboardsAtom({
        nodeId: projectId,
      }),
      RESET,
    );
  }, []);

  const refreshProjectState = useJotaiCallback(
    async (get, set, projectId: string) => {
      const branches = await get(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
      );
      branches.forEach((branch) => refreshBranchState(projectId, branch.id));

      set(
        allLayerSettingsAtomFamily({
          projectId,
        }),
        RESET,
      );
      set(
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
        RESET,
      );
      set(
        costConfigurationsRefetch({
          projectId,
        }),
      );
      set(
        analysisConfigurationsRefetch({
          projectId,
        }),
      );
      set(
        branchMetasFamily({
          projectId,
        }),
        RESET,
      );
      set(
        turbineResourceWithAccessOnNodeState({
          nodeId: projectId,
        }),
        RESET,
      );
      set(
        foundationResourceWithAccessOnNodeState({
          nodeId: projectId,
        }),
        RESET,
      );
      set(
        analysisResourceWithAccessOnNodeState({
          nodeId: projectId,
        }),
        RESET,
      );
      set(
        dataPackageResourceWithAccessOnNodeState({
          nodeId: projectId,
        }),
        RESET,
      );
      set(
        getAllLayerCollections({
          projectId,
        }),
      );

      refreshProjectStateJ(projectId);
    },
    [refreshBranchState, refreshProjectStateJ],
  );

  useEffect(() => {
    if (projectId) {
      refreshProjectState(projectId);
    }
  }, [projectId, refreshProjectState]);

  return refreshBranchState;
}
