import { AddLayerSourceInternalModalType } from "components/AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import { MenuItem } from "components/General/Menu";
import { MenuButton } from "components/General/MenuButton";
import {
  UploadModalType,
  UploadFileType,
} from "components/UploadModal/UploadModal";
import { useAtomValue, useSetAtom } from "jotai";
import {
  editorAccessProjectSelector,
  loggedInUserIsInternalSelector,
} from "state/user";
import DatabaseIcon from "@icons/24/Database.svg?react";
import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import BulbIcon from "@icons/24/Bulb.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { modalTypeOpenAtom, midScreenModalTypeOpenAtom } from "state/modal";
import { useCallback } from "react";

const ConnectExternal = () => {
  const canEditProject = useAtomValue(editorAccessProjectSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const isInternal = useAtomValue(loggedInUserIsInternalSelector);
  const openChat = useCallback(() => window.Intercom("showNewMessage"), []);

  return (
    <div>
      {" "}
      {canEditProject ? (
        <>
          <MenuButton
            icon={<DatabaseIcon />}
            buttonType="secondary"
            side="right"
            buttonText="Connect external server"
          >
            <MenuItem
              name="From URL"
              icon={<HyperlinkIcon />}
              onClick={() => {
                setMidScreenModalTypeOpen((curr) => ({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.ADD_DATA_LAYER_SOURCE,
                  },
                  backTo: curr,
                }));
              }}
            />
            <MenuItem
              name="Request new source"
              icon={<BulbIcon />}
              onClick={openChat}
            />
            {isInternal && (
              <MenuItem
                name="Add new source (Internal)"
                icon={<AddIcon />}
                onClick={() => {
                  setModalTypeOpen((curr) => ({
                    modalType: AddLayerSourceInternalModalType,
                    backTo: curr,
                  }));
                }}
              />
            )}
          </MenuButton>
        </>
      ) : null}
    </div>
  );
};

export default ConnectExternal;
