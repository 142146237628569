import { z } from "zod";
import { fetchSchemaWithToken } from "../../../services/utils";

const _UserHintSettings = z.object({
  dontShowTipIds: z.string().array().optional(),
  hideAllTips: z.boolean().optional(),
  hideLearningSessions: z.string().array().optional(),
  startedTours: z.string().array().optional(),
});
export type UserHintSettings = z.infer<typeof _UserHintSettings>;

export async function getUserHintSettings(): Promise<UserHintSettings> {
  return fetchSchemaWithToken(
    _UserHintSettings,
    `/api/hints/user`,
    {
      method: "get",
    },
    3,
  );
}

export async function postUserHintSettings(
  settings: UserHintSettings,
): Promise<UserHintSettings> {
  return await fetchSchemaWithToken(_UserHintSettings, `/api/hints/user`, {
    method: "post",
    body: JSON.stringify(settings),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
