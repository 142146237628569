import { ColumnContent } from "components/ConfigurationModal/shared";
import { ModalContainer } from "components/SettingsV2/Shared/styles";
import { ContentWrapper } from "components/SettingsV2/Shared/styles";

type CableSettingsLayoutProps = {
  inLibraryContext: boolean;
  header?: React.ReactNode;
  children: React.ReactNode;
};

export function CableSettingsLayout({
  inLibraryContext,
  header,
  children,
}: CableSettingsLayoutProps) {
  return (
    <ModalContainer>
      {!inLibraryContext && header}
      <ContentWrapper
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          padding: inLibraryContext ? "1.6rem 0rem" : undefined,
        }}
      >
        <ColumnContent>{children}</ColumnContent>
      </ContentWrapper>
    </ModalContainer>
  );
}
