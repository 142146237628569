export type ResourceType =
  | "substation"
  | "cable"
  | "exportcable"
  | "turbine"
  | "foundation"
  | "analysis"
  | "cost"
  | "operations"
  | "mooring"
  | "vessel"
  | "wind";

export const resourceTypeToName: Record<ResourceType, string> = {
  substation: "substation",
  cable: "cable",
  exportcable: "export cable",
  turbine: "turbine",
  foundation: "foundation",
  analysis: "analysis configuration",
  cost: "financial configuration",
  operations: "operations configuration",
  mooring: "mooring line",
  vessel: "vessel",
  wind: "wind configuration",
};
