import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { featuresFamily } from "state/jotai/features";
import { isMultiPolygonFeature, isPolygonFeature } from "utils/predicates";
import { getSelfIntersectingPolygons } from "utils/geojson/validate";
import { Feature } from "geojson";
import { MultiPolygon, Polygon } from "@turf/turf";

export const selfIntersectingPolygonsWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SelfIntersectingPolygon>
        | undefined
      >
    >(async (get) => {
      const split = await get(featuresFamily({ branchId }));
      const features = Object.values(split).flat();
      const polygonFeatures = features
        .filter((f) => f?.properties?.type)
        .filter((f) => isMultiPolygonFeature(f) || isPolygonFeature(f));

      const { featureIds, coordinates } = getSelfIntersectingPolygons(
        polygonFeatures as Feature<Polygon | MultiPolygon>[],
      );

      if (featureIds.length === 0) return;
      return {
        type: ValidationWarningTypes.SelfIntersectingPolygon,
        featureIds,
        coordinates,
      };
    }),
);
