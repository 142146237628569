import { mapAtom } from "state/map";
import { useEffect, useMemo } from "react";
import {
  defaultDepthRasterMinMax,
  depthRasterMinMaxSelector,
  currentMapStyleAtom,
} from "../state/map";
import mapboxgl, { MercatorCoordinate } from "mapbox-gl";
import { Map as MapboxMap } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { useAtomValue } from "jotai";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { MaybePromise } from "types/utils";
import { wrapMapboxTile } from "types/tile";

const MAP_SOURCE_DEPTH = "bathymetry-source";
export const DepthLayerId = "depth-layer";

class DepthLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  minMax: [number, number] = defaultDepthRasterMinMax;
  program: any;
  vertexBuffer: any;
  map: MapboxMap | undefined;
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;
  isSubmitted: boolean;

  urlFunc = (z: number, x: number, y: number) =>
    `/tiles/gebco-terrarium-2023/${z}/${x}/${y}.png`;

  constructor(
    id: string,
    minMax: [number, number],
    maxZoom: number,
    tileSize: number,
  ) {
    this.id = id;
    this.type = "custom";
    this.renderingMode = "3d";
    this.minMax = minMax;
    this.textureCache = new Map();
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.isSubmitted = false;
  }

  setMinMax = (minMax: [number, number]) => {
    this.minMax = minMax;
  };

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;

    const vertexSource = `
      attribute vec2 a_pos;
      varying vec2 v_pos;
      uniform vec2 u_minMax;
      void main() {
        v_pos = vec2(a_pos / 1.);
        gl_Position = vec4(a_pos, 1.0, 1.0);
      }`;

    const fragmentSource = `
  precision highp float;
  uniform sampler2D u_depth_raster;
  uniform vec2 u_minMax;
  varying vec2 v_pos;
  uniform vec4 u_uv_offsets;

  vec3 depthToColor(float depth) {
    float c = clamp((depth-u_minMax.x) / (u_minMax.y-u_minMax.x), 0., 1.);
    return mix(
      vec3(0.64, 0.78, 0.92),
      vec3(0.38, 0.47, 0.55),
      c
    );
  }


float terrariumDepth(sampler2D depth_raster, vec2 texCoord) {
  vec4 color = texture2D(depth_raster, texCoord);
  float R = color.r * 255.0;
  float G = color.g * 255.0;
  float B = color.b * 255.0;
  return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
}

  void main() {
    vec2 uv = 0.5 * v_pos + 0.5;
    uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
    uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

    vec4 sample = texture2D(u_depth_raster, uv);
    float depth = terrariumDepth(u_depth_raster, uv);
    float alpha = clamp(sample.a, 0.0, 1.0);
    vec3 color = depthToColor(depth);
    gl_FragColor = vec4(color, alpha * step(10., depth));
  }`;

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader)
      throw scream("bathymetry: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("bathymetry: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS))
      throw scream("Failed to create program", {
        program: this.program,
        vertexShader,
        fragmentShader,
        programInfo: gl.getProgramInfoLog(this.program),
        vertexInfo: gl.getShaderInfoLog(vertexShader),
        fragmentInfo: gl.getShaderInfoLog(fragmentShader),
      });

    this.program.aPos = gl.getAttribLocation(this.program, "a_pos");
    this.program.uColor = gl.getUniformLocation(this.program, "u_color");

    const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);
  };

  shouldRerenderTiles = () => {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  };

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.map) return;
    const texture = getBestAvailableTexture(
      gl,
      tileId,
      this.textureCache,
      this.map,
      this.urlFunc,
      this.maxZoom,
      this.tileSize,
      false,
      () => this.isSubmitted,
    );
    gl.useProgram(this.program);

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_minMax"), this.minMax);
    gl.uniform4fv(
      gl.getUniformLocation(this.program, "u_uv_offsets"),
      texture.uvOffsets,
    );
    // Set up the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // Draw
    this.draw(gl);
  };

  draw(gl: WebGL2RenderingContext) {
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.program.aPos);
    gl.vertexAttribPointer(this.program.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const Bathymetry = () => {
  const mapStyle = useAtomValue(currentMapStyleAtom);
  return <> {mapStyle?.useBathymetry && <BathymetryActive />} </>;
};

const BathymetryActive = () => {
  const depthRasterMinMax = useAtomValue(depthRasterMinMaxSelector);
  const map = useAtomValue(mapAtom);

  const depthLayer = useMemo(
    () => new DepthLayer(MAP_SOURCE_DEPTH, defaultDepthRasterMinMax, 7, 512),
    [],
  );

  useEffect(() => {
    depthLayer.setMinMax(depthRasterMinMax);
    if (!map) return;
    map.triggerRepaint();
  }, [map, depthRasterMinMax, depthLayer]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(depthLayer, "building");
    map.triggerRepaint();

    return () => {
      if (!map) return;
      map.removeLayer(depthLayer.id);
    };
  }, [map, depthLayer]);

  return null;
};

export default Bathymetry;
