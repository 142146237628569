import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import { Node } from "services/customerAPI";
import Tooltip, { TooltipText } from "components/General/Tooltip";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { Row } from "components/General/Layout";
import { spacing2 } from "styles/space";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import Earth from "@icons/14/Earth.svg";
import { getFolderChildNodes } from "business/customer/utils";
import { VerticalDivider } from "components/General/VerticalDivider";

const ProjectNamesTooltip = ({
  nodes,
  children,
}: React.PropsWithChildren<{
  nodes: Node[];
}>) => {
  return (
    <Tooltip
      text=""
      secondary={false}
      position="left"
      theme="dark"
      disabled={nodes.length === 0}
      content={nodes.map((node) => (
        <TooltipText secondary={false} key={node.id} theme="dark">
          {node.name}
        </TooltipText>
      ))}
    >
      {children}
    </Tooltip>
  );
};

const Admin_ChildNodesGlobe = ({
  node,
  organisationId,
}: {
  node: Node;
  organisationId: string;
}) => {
  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  const childNodes = useMemo<Node[] | undefined>(() => {
    if (!node) {
      return;
    }
    if (node.type !== "folder") {
      return;
    }

    return getFolderChildNodes(nodes, node);
  }, [node, nodes]);

  if (!childNodes) {
    return null;
  }

  return (
    <>
      <VerticalDivider />
      <ProjectNamesTooltip nodes={childNodes}>
        <Row
          alignCenter
          style={{
            gap: spacing2,
            color: colors.iconSubtle,
          }}
        >
          <IconREMSize height={1.4} width={1.4} stroke={colors.iconSubtle}>
            <Earth />
          </IconREMSize>
          {childNodes.length}
        </Row>
      </ProjectNamesTooltip>
    </>
  );
};

export default Admin_ChildNodesGlobe;
