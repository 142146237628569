import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { TurbineFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import ComponentsIcon from "@icons/24/Components.svg?react";
import Tooltip from "../General/Tooltip";
import {
  isDefined,
  isSimpleMonopile,
  isTurbine,
  isSimpleJacket,
  isFloater,
  isFixed,
} from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { MAX_DEPTH_FIXED, MIN_DEPTH_FLOATING } from "../../constants/park";
import { SkeletonText } from "../Loading/Skeleton";
import { Raster } from "../../types/raster";
import { Divider } from "components/General/Icons";
import { ParkFeature } from "../../types/feature";
import {
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationTypeWithLevel,
} from "../../types/foundations";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { pointInPolygon } from "utils/geometry";
import { useBathymetry } from "hooks/bathymetry";
import { dedup, fastMax, fastMin } from "utils/utils";
import { parkFamily } from "state/jotai/park";
import { useAtomValue } from "jotai";
import { foundationTypesWithLevelAtom } from "state/jotai/foundation";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";
import { useOpenComponentsConfig } from "./useOpenConfig";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import { SearchInput } from "components/General/Input";
import Fuse from "fuse.js";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const FallbackWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;
const DividerWrapper = styled.div`
  background-color: ${colors.borderDefault}};
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
`;

const TypeSelector = ({
  selectedTurbines,
  editable,
  raster,
}: {
  selectedTurbines: TurbineFeature[];
  editable: boolean;
  raster: undefined | Raster;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const { update: updateFeatures } = useProjectElementsCrud();
  const foundationTypes = useAtomValue(foundationTypesWithLevelAtom);
  const turbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);
  const openNewFoundationConfig = useOpenComponentsConfig(
    projectId,
    FOUNDATION_MENU_ID,
  );
  const [search, setSearch] = useState("");
  const [filteredResources, setFilteredResources] =
    useState<FoundationTypeWithLevel[]>(foundationTypes);

  const currentFoundationTypeIds = [
    ...new Set(selectedTurbines.map((t) => t.properties.foundationId)).values(),
  ];

  const { minDepth, maxDepth } = useMemo(() => {
    if (!raster)
      return {
        minDepth: 0,
        maxDepth: 0,
      };
    const depths = selectedTurbines
      .map((t) => {
        const [lon, lat] = t.geometry.coordinates;
        return -raster.latLngToValue(lon, lat);
      })
      .filter((depth) => depth > 0);
    return {
      maxDepth: fastMax(depths),
      minDepth: fastMin(depths),
    };
  }, [raster, selectedTurbines]);

  const { minPower, maxPower } = useMemo(() => {
    const powerRatings = selectedTurbines
      .map((t) => {
        const type = turbineTypes.get(t.properties.turbineTypeId);
        if (!type) return;
        return type.turbine.ratedPower;
      })
      .filter(isDefined);
    return {
      minPower: fastMax(powerRatings),
      maxPower: fastMin(powerRatings),
    };
  }, [selectedTurbines, turbineTypes]);

  useEffect(() => {
    if (!search) {
      setFilteredResources(foundationTypes);
      return;
    }

    const fuse = new Fuse(Array.from(foundationTypes.values()), {
      keys: ["foundation.name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(search).map((result) => result.item);

    setFilteredResources(results);
  }, [search, foundationTypes]);

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-foundation-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedTurbines.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          foundationId: val,
        },
      }));

      updateFeatures({
        update: updatedFeatures,
      });
    },
    [branchId, projectId, selectedTurbines, updateFeatures],
  );

  const items = useMemo(
    () =>
      filteredResources.map((ft) => {
        let disabled = false;

        if (
          isFloater(ft.foundation) &&
          minDepth <
            (FoundationMinDepths[ft.foundation.type] ?? MIN_DEPTH_FLOATING)
        ) {
          disabled = true;
        } else if (
          isFixed(ft.foundation) &&
          maxDepth >
            (FoundationMaxDepths[ft.foundation.type] ?? MAX_DEPTH_FIXED)
        ) {
          disabled = true;
        }
        if (
          (isSimpleMonopile(ft.foundation) || isSimpleJacket(ft.foundation)) &&
          (minDepth < ft.foundation.minWaterDepth ||
            maxDepth > ft.foundation.maxWaterDepth ||
            minPower < ft.foundation.minRatedPower ||
            maxPower > ft.foundation.maxRatedPower)
        ) {
          disabled = true;
        }

        return {
          level: ft.level,
          value: ft.foundation.id,
          name: ft.foundation.name,
          disabled,
        };
      }),
    [filteredResources, maxDepth, minDepth, maxPower, minPower],
  );

  const selectedFoundationTypeId =
    currentFoundationTypeIds.length === 1
      ? currentFoundationTypeIds[0]
      : undefined;

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");
    const standardItems = items.filter((ft) => ft.level === "standard");

    return {
      project: projectItems,
      library: libraryItems,
      standard: standardItems,
    };
  }, [items]);

  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        buttonContainerStyle={{ backgroundColor: colors.surfacePrimary }}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {selectedFoundationTypeId
              ? foundationTypes.find(
                  (t) => t.foundation.id === selectedFoundationTypeId,
                )?.foundation.name ?? "..."
              : "Foundation type"}
          </div>
        }
      >
        <>
          <div style={{ padding: "1.2rem 1.2rem 0.8rem" }}>
            <SearchInput
              autoFocus={false}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => {
                setSearch("");
              }}
              placeholder={`Search foundation components`}
              style={{
                flexGrow: 1,
                width: "30rem",
              }}
            />
          </div>
          {groupedItems.library.length > 0 && (
            <>
              <SubTitle>Library components</SubTitle>
              {groupedItems.library.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentFoundationTypeIds.length === 1 &&
                    currentFoundationTypeIds.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  icon={<LibraryIcon />}
                  onClick={() => onSelectItem(item.value)}
                  disabled={item.disabled}
                />
              ))}
            </>
          )}
          {groupedItems.project.length > 0 && (
            <>
              <SubTitle>Project specific components</SubTitle>
              {groupedItems.project.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentFoundationTypeIds.length === 1 &&
                    currentFoundationTypeIds.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelectItem(item.value)}
                  icon={<ProjectIcon />}
                  disabled={item.disabled}
                />
              ))}
            </>
          )}
          {groupedItems.standard.length > 0 && (
            <>
              <SubTitle>Vind AI components</SubTitle>
              {groupedItems.standard.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentFoundationTypeIds.length === 1 &&
                    currentFoundationTypeIds.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  icon={<VindLogo />}
                  onClick={() => onSelectItem(item.value)}
                  disabled={item.disabled}
                />
              ))}
            </>
          )}

          <DividerWrapper>
            <DropdownDivider />
          </DividerWrapper>

          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewFoundationConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

function FoundationTypeSelectorInner({
  editable,
  park,
  selectedTurbineFeatures,
}: {
  editable: boolean;
  park: ParkFeature;
  selectedTurbineFeatures: TurbineFeature[];
}) {
  const [, raster] = useBathymetry({
    featureId: park.id,
    projectId: undefined,
    branchId: undefined,
    bufferKm: undefined,
  });

  return (
    <Suspense
      fallback={
        <FallbackWrapper>
          <SkeletonText
            style={{
              width: "3rem",
            }}
          />
        </FallbackWrapper>
      }
    >
      <Tooltip position="top" text="Change foundation type" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Foundation type</NamedTooltipWrapper>
          <TypeSelector
            raster={raster}
            selectedTurbines={selectedTurbineFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
    </Suspense>
  );
}

export default function FoundationTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedTurbineFeatures = useMemo(
    () => selectedProjectFeatures.filter(isTurbine),
    [selectedProjectFeatures],
  );

  const parkIds = dedup(
    selectedTurbineFeatures
      .flatMap((t) => t.properties.parentIds)
      .filter(isDefined),
  );

  const park = useAtomValue(
    parkFamily({
      parkId: parkIds[0] ?? "",
      branchId: undefined,
    }),
  );

  if (!park || parkIds.length > 1) return null;

  const onlyTurbinesSelected =
    selectedTurbineFeatures.length === selectedProjectFeatures.length;

  if (selectedTurbineFeatures.length === 0 || !onlyTurbinesSelected)
    return null;

  const turbinesOutsidePark = selectedTurbineFeatures.filter(
    (t) => !pointInPolygon(t.geometry, park.geometry),
  );

  if (turbinesOutsidePark.length > 0) return null;

  return (
    <>
      <FoundationTypeSelectorInner
        selectedTurbineFeatures={selectedTurbineFeatures}
        park={park}
        editable={editable}
      />
      <Divider />
    </>
  );
}
