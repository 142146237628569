import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import {
  filteredThreadsSelectorFamily,
  showCommentIconsInMapState,
  showOnlyUsersOwnCommentsAtom,
  showResolvedCommentsAtom,
  showUnreadCommentsAtom,
} from "../state";
import Comment from "./Comment";
import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  ANIMATION_DURATION,
  LeftModalContainer,
  CONTAINER_MIN_WIDTH,
} from "../../Design/styles";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "../../Loading/Skeleton";
import { spaceLarge } from "../../../styles/space";
import { Column } from "../../General/Layout";
import { useHorizontalResize } from "../../ResizeBar/ResizeBarVertical";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";
import { useAtom, useAtomValue } from "jotai";
import { DotMenu } from "components/General/MenuButton";
import { colors } from "styles/colors";
import Checkbox from "components/General/Checkbox";
import { NotificationSettingsAtom, loggedInUserIdAtom } from "state/user";
import { UserNotificationSettings } from "services/userService";
import { useUpdateUserNotifications } from "hooks/useUser";
import { typography } from "styles/typography";
import { useReadComments } from "../UnreadComments/useReadComments";

const Subtitle = styled.div`
  ${typography.sub2}
  color: ${colors.seagreen700};
`;

const CheckboxAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.2rem;
  background-color: ${colors.surfacePrimary};
`;

export default function CommentsLeftModal() {
  const projectNodeId = useAtomValue(projectIdAtom);
  const [hide, setHide] = useState(true);
  const frameRef = useRef<HTMLDivElement>(null);
  useHorizontalResize(frameRef, "--left-menu-width");
  const [showSmall, setShowSmall] = useState(true);
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );
  const [showResolved, setShowResolved] = useAtom(showResolvedCommentsAtom);
  const [showOnlyUsersOwnComments, setShowOnlyUsersOwnComments] = useAtom(
    showOnlyUsersOwnCommentsAtom,
  );
  const [showUnreadComments, setShowUnreadComments] = useAtom(
    showUnreadCommentsAtom,
  );
  const [showCommentsInMap, setShowCommentsInMap] = useAtom(
    showCommentIconsInMapState,
  );
  const settings = useAtomValue(NotificationSettingsAtom) ?? {};
  const [loadingSettings, setLoadingSettings] = useState<Set<string>>(
    new Set(),
  );
  const updateSettings = useUpdateUserNotifications();

  const _onChange = useCallback(
    async (setting: Partial<UserNotificationSettings>) => {
      setLoadingSettings((curr) => {
        const s = new Set(curr);
        for (const k of Object.keys(setting)) s.add(k);
        return s;
      });
      await updateSettings(setting);
      setLoadingSettings((curr) => {
        const s = new Set(curr);
        for (const k of Object.keys(setting)) s.delete(k);
        return s;
      });
    },
    [updateSettings],
  );

  useEffect(() => {
    if (leftModalMenuOpen === LeftModalMenuTypes.Comment) {
      setHide(false);
      const delay = setTimeout(() => setShowSmall(false), 30);
      return () => clearTimeout(delay);
    } else {
      const delay = setTimeout(() => {
        setHide(true);
        setShowSmall(true);
      }, ANIMATION_DURATION * 985);
      return () => clearTimeout(delay);
    }
  }, [leftModalMenuOpen]);

  if (hide) return null;

  return (
    <LeftModalContainer
      open={leftModalMenuOpen === LeftModalMenuTypes.Comment && !showSmall}
    >
      <MenuFrame
        title="Comments"
        onExit={() => {
          setLeftModalMenuOpen(undefined);
        }}
        style={{
          flex: 1,
          width: "var(--left-menu-width)",
          minWidth: CONTAINER_MIN_WIDTH,
          maxWidth: "40vw",
          resize: "horizontal",
        }}
        ref={frameRef}
        settingsMenu={
          <DotMenu
            buttonStyle={{
              alignItems: "center",
            }}
          >
            <CheckboxAreaWrapper>
              <Subtitle>Map settings</Subtitle>
              <Checkbox
                checked={showCommentsInMap}
                label={"Show comments in map"}
                onChange={() => setShowCommentsInMap(!showCommentsInMap)}
                labelPlacement="after"
              />
            </CheckboxAreaWrapper>
            <CheckboxAreaWrapper>
              <Subtitle>Filters</Subtitle>
              <Checkbox
                checked={showResolved}
                onChange={() => setShowResolved(!showResolved)}
                label={"Show resolved threads"}
                labelPlacement="after"
              />
              <Checkbox
                checked={showUnreadComments}
                label={"Show unread comments only"}
                onChange={() => setShowUnreadComments(!showUnreadComments)}
                labelPlacement="after"
              />
              <Checkbox
                checked={showOnlyUsersOwnComments}
                label={"Show my own comments only"}
                onChange={() =>
                  setShowOnlyUsersOwnComments(!showOnlyUsersOwnComments)
                }
                labelPlacement="after"
              />
            </CheckboxAreaWrapper>
            <CheckboxAreaWrapper>
              <Subtitle>Comment notifications</Subtitle>
              <Checkbox
                checked={settings.email_on_mention}
                disabled={loadingSettings.has("email_on_mention")}
                onChange={() =>
                  _onChange({
                    email_on_mention: settings.email_on_mention
                      ? !settings.email_on_mention
                      : true,
                  })
                }
                label={"Someone @mentions me"}
                labelPlacement="after"
              />
              <Checkbox
                checked={settings.email_on_comment_in_followed_thread}
                disabled={loadingSettings.has(
                  "email_on_comment_in_followed_thread",
                )}
                onChange={() =>
                  _onChange({
                    email_on_comment_in_followed_thread:
                      !settings.email_on_comment_in_followed_thread,
                  })
                }
                label={"Comments in threads I'm following"}
                labelPlacement="after"
              />
            </CheckboxAreaWrapper>
          </DotMenu>
        }
      >
        <Suspense
          fallback={
            <SkeletonText
              style={{
                margin: spaceLarge,
              }}
            />
          }
        >
          {projectNodeId && <CommentsLeftModalInner nodeId={projectNodeId} />}
        </Suspense>
      </MenuFrame>
    </LeftModalContainer>
  );
}

function CommentsLeftModalInner({ nodeId }: { nodeId: string }) {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const loggedInUser = useAtomValue(loggedInUserIdAtom);
  const { threads, resolvedAndNotResolvedThreads } = useAtomValue(
    filteredThreadsSelectorFamily({
      nodeId,
      branchId,
    }),
  );
  const { isRead } = useReadComments();

  const showResolved = useAtomValue(showResolvedCommentsAtom);

  const showOnlyUsersOwnComments = useAtomValue(showOnlyUsersOwnCommentsAtom);

  const showUnreadCommentsOnly = useAtomValue(showUnreadCommentsAtom);

  const commentsToRender = useMemo(() => {
    let threadsToRender = showResolved
      ? resolvedAndNotResolvedThreads
      : threads;

    if (showOnlyUsersOwnComments && loggedInUser) {
      threadsToRender = threadsToRender.filter(
        (thread) => thread.userId === loggedInUser,
      );
    }

    if (showUnreadCommentsOnly) {
      threadsToRender = threadsToRender.filter((thread) => isRead(thread));
    }

    return threadsToRender;
  }, [
    showResolved,
    resolvedAndNotResolvedThreads,
    threads,
    showOnlyUsersOwnComments,
    showUnreadCommentsOnly,
    loggedInUser,
    isRead,
  ]);

  return (
    <>
      <Column
        style={{
          flex: 1,
          gap: spaceLarge,
          overflowY: "auto",
        }}
      >
        {commentsToRender.length === 0 ? (
          <p>
            No comments to show — click on a feature in the map to add a
            comment.
          </p>
        ) : (
          commentsToRender.map((t) => (
            <Comment nodeId={nodeId} key={t.threadId} thread={t} />
          ))
        )}
      </Column>
    </>
  );
}
