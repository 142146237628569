import type { Node } from "services/customerAPI";

export const getNodeName = (node?: Node): string => {
  if (!node) {
    return "";
  }
  const isPersonalFolder = node?.type === "personal_folder";
  if (isPersonalFolder) {
    if (node.name !== "Personal") {
      return `Personal (${node.name})`;
    }
    return "Personal";
  }
  return node.name;
};

// Recursively fetch parent_ids
export const getParentNodeIds = (
  allNodes: Node[],
  nodeId: string | null | undefined,
): string[] => {
  if (!nodeId) {
    return [];
  }
  const thisNode = allNodes.find((node) => node.id === nodeId);
  if (!thisNode) {
    return [];
  }
  return [thisNode.id, ...getParentNodeIds(allNodes, thisNode.parent_id)];
};
