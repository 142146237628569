import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";

export const Admin_MemberGrid = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 1.5fr fit-content(10rem) fit-content(
      10rem
    );

  /* Every 3 rows (each guest occupies 3 cells in your grid), starting from the 4th row (first guest) */
  & > :nth-child(12n+7),  /* Targets the Name cell of every second row */
  & > :nth-child(12n+8),  /* Targets the Org access cell of every second row */
  & > :nth-child(12n+9),  /* Targets the Library access cell on every second row */
  & > :nth-child(12n+10),   /* Targets the Groups cell of every second row */ 
  & > :nth-child(12n+11),   /* Targets the Last active cell of every second row */ 
  & > :nth-child(12n+12)   /* Targets the delete button of every second row */ {
    background-color: ${colors.focusBackground};
  }
`;

export const Admin_InvitesGrid = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr fit-content(10rem);

  .gray-background {
    background-color: ${colors.focusBackground};
  }
  /* Every 3 rows (each guest occupies 3 cells in your grid), starting from the 4th row (first guest) */
  & > :nth-child(12n+7),  /* Targets the Name cell of every second row */
  & > :nth-child(12n+8),  /* Targets the Org access cell of every second row */
  & > :nth-child(12n+9),  /* Targets the Project access cell of every second row */
  & > :nth-child(12n+10),  /* Targets the Groups cell of every second row */ 
  & > :nth-child(12n+11),  /* Targets the Invitation expires cell on every second row */
  & > :nth-child(12n+12)   /* Targets the delete button cell on every second row */ {
    background-color: ${colors.focusBackground};

    .gray-background {
      background-color: ${colors.grey100};
    }
  }
`;

export const Member_MemberGrid = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr fit-content(10rem);

  /* Every 3 rows (each guest occupies 3 cells in your grid), starting from the 4th row (first guest) */
  & > :nth-child(8n+5), /* Targets the Name cell of every second row */                
  & > :nth-child(8n+6), /* Targets the Org access cell of every second row */         
  & > :nth-child(8n+7), /* Targets the Groups cell of every second row */             
  & > :nth-child(8n+8)  /* Targets the delete button cell */ {
    /* Targets the Library access cell on every second row */
    background-color: ${colors.focusBackground};

    .gray-background {
      background-color: ${colors.grey100};
    }
  }
`;

export const Member_InvitesGrid = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr fit-content(10rem);

  .gray-background {
    background-color: ${colors.focusBackground};
  }

  & > :nth-child(10n+6),  /* Targets the Name cell of every second row */
    & > :nth-child(10n+7), /* Targets the Org access cell of every second row */
    & > :nth-child(10n+8), /* Targets the Groups cell of every second row */
    & > :nth-child(10n+9), /* Targets the Invitation expires cell of every second row */
    & > :nth-child(10n+10) /* Targets the delete button cell */ {
    background-color: ${colors.focusBackground};

    .gray-background {
      background-color: ${colors.grey100};
    }
  }
`;

export const TableText = styled.p<{ capitalize?: boolean }>`
  ${typography.contentAndButtons}
  display: flex;
  align-items: center;
  ${({ capitalize }) => capitalize && "text-transform: capitalize;"}
`;
