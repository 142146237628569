import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { libraryFoundationsRefreshAtom } from "state/foundations";
import { libraryFoundationsRefreshAtomJ } from "state/jotai/foundation";

export const useRefreshLibraryFoundation = () => {
  const setRefresh = useSetAtom(libraryFoundationsRefreshAtom);
  const setJotai = useSetAtom(libraryFoundationsRefreshAtomJ);
  return useCallback(() => {
    setRefresh((currVal) => currVal + 1);
    setJotai((c) => c + 1);
  }, [setRefresh, setJotai]);
};
