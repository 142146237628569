import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { useEffect, useMemo, useState } from "react";
import SphericalMercator from "@mapbox/sphericalmercator";
import * as turf from "@turf/turf";
import { Position } from "@turf/turf";
import {
  calculateSample,
  getXTileNumber,
  getYTileNumber,
} from "../utils/tiles";
import { scream } from "../utils/sentry";
import { MapMouseEvent } from "mapbox-gl";

const drawCanvas = async (
  tileFetchFunc: TileFetchFunc,
  x: number,
  y: number,
  z: number,
  tileSize: number,
) => {
  const tile = await tileFetchFunc(x, y, z, tileSize);
  const blob = await tile.blob();

  const canvas = document.createElement("canvas");
  canvas.id = `${x}-${y}-${z}`;
  canvas.height = tileSize;
  canvas.width = tileSize;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = function () {
      res(img);
    };
  });
  if (!ctx) return canvas;
  ctx.drawImage(img, 0, 0);

  return canvas;
};

type TileFetchFunc = (
  x: number,
  y: number,
  z: number,
  tileSize: number,
) => Promise<Response>;

export const useMouseSampler = (
  tileFetchFunc: TileFetchFunc,
  maxZoom?: number,
  tileSize = 256,
  limits?: Position[][],
) => {
  const merc = useMemo(
    () =>
      new SphericalMercator({
        size: tileSize,
        antimeridian: true,
      }),
    [tileSize],
  );
  const map = useAtomValue(mapAtom);
  const [mousePos, setMousePos] = useState<
    | undefined
    | {
        zoom: number;
        lat: number;
        lng: number;
      }
  >();
  const [canvas, setCanvas] = useState<undefined | HTMLCanvasElement>();
  const [sample, setSample] = useState<
    undefined | [number, number, number, number]
  >();

  useEffect(() => {
    if (!map) return;
    const onMouseMove = (e: MapMouseEvent) => {
      if (
        limits &&
        !turf.booleanPointInPolygon(
          turf.point(Object.values(e.lngLat)),
          turf.polygon(limits),
        )
      ) {
        setMousePos(undefined);
        setSample(undefined);
        return;
      }
      setMousePos({
        ...e.lngLat,
        zoom: !maxZoom
          ? Math.floor(map.getZoom())
          : Math.min(Math.floor(map.getZoom()), maxZoom),
      });
    };
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [limits, map, setMousePos, maxZoom, setSample]);

  const [x, y, z] = useMemo(() => {
    if (!mousePos) return [0, 0, 0];
    const normalizeLongitude = (lng: number): number => {
      return ((lng + 540) % 360) - 180;
    };
    const { lat, lng, zoom } = mousePos;
    const normalizedLng = normalizeLongitude(lng);
    const x = getXTileNumber(normalizedLng, zoom);
    const y = getYTileNumber(lat, zoom);
    return [x, y, zoom];
  }, [mousePos]);

  useEffect(() => {
    if (x === 0 && y === 0 && z === 0) return;

    let isSubmitted = false;
    const setTileAsync = async () => {
      const canvas = await drawCanvas(tileFetchFunc, x, y, z, tileSize);
      if (!isSubmitted) setCanvas(canvas);
    };
    setTileAsync();
    return () => {
      setCanvas(undefined);
      isSubmitted = true;
    };
  }, [x, y, z, setCanvas, tileSize, tileFetchFunc]);

  useEffect(() => {
    if (!canvas || !mousePos) return;

    const setSampleAsync = async () => {
      const { lat, lng, zoom } = mousePos;
      var ctx = canvas.getContext("2d", {
        willReadFrequently: true,
      });
      if (!ctx) return;
      const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
      setSample(
        calculateSample(lng, lat, zoom, x, y, tileSize, imageData, merc),
      );
    };
    setSampleAsync();
  }, [mousePos, canvas, setSample, x, y, tileSize, merc]);

  return sample;
};

export const fetchTileAsync = async (
  x: number,
  y: number,
  z: number,
  tileSize: number,
  tileFetchFunc: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
) => {
  const canvas = await drawCanvas(tileFetchFunc, x, y, z, tileSize).catch(
    (e) => {
      if (e instanceof Response) {
        scream("failed to fetch depth tile", {
          url: e.url,
          status: e.status,
          text: e.statusText,
        });
      } else {
        scream("failed to fetch depth tile", {
          error: e,
        });
      }
      return;
    },
  );
  if (!canvas) return;
  const ctx = canvas.getContext("2d")!; // TODO: Safety here
  const imageData = ctx.getImageData(0, 0, tileSize, tileSize);
  return {
    imageData,
    x,
    y,
  };
};
