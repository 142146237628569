import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { addGroupMember, removeGroupMember } from "./service";
import { allOrganisationGroupMembershipsAtomFamily } from "./state";
import { loggedInUserIdAtom } from "state/user";
import { userOrganisationResourcesAtomFamily } from "components/Organisation/OrganisationRightSide/content/hooks/useUserOrganisationResourcesCrud";
import { RESET } from "jotai/utils";
import { useUserLibraryAccessState } from "components/Projects/useUserLibraryAccessState";

const useMemberInGroupCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const currentUserId = useAtomValue(loggedInUserIdAtom);
  const { refresh: refreshUserLibraryAccess } = useUserLibraryAccessState({
    organisationId,
  });

  const add = useJotaiCallback(
    async (get, set, groupId: string, userId: string) => {
      const fallback = await get(
        allOrganisationGroupMembershipsAtomFamily({
          organisationId,
        }),
      );
      return addGroupMember(organisationId, groupId, userId)
        .then((newMember) => {
          aset(
            get,
            set,
            allOrganisationGroupMembershipsAtomFamily({
              organisationId,
            }),
            (cur) => [...cur, newMember],
          );
          // refresh(userGroupsMembershipState({ organisationId, userId }));
          if (currentUserId === userId) {
            refreshUserLibraryAccess(organisationId);
          }
        })
        .catch(() => {
          aset(
            get,
            set,
            allOrganisationGroupMembershipsAtomFamily({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [currentUserId, organisationId, refreshUserLibraryAccess],
  );

  const remove = useJotaiCallback(
    async (get, set, groupId: string, userId: string) => {
      const fallback = await get(
        allOrganisationGroupMembershipsAtomFamily({
          organisationId,
        }),
      );
      return removeGroupMember(organisationId, groupId, userId)
        .then(() => {
          aset(
            get,
            set,
            allOrganisationGroupMembershipsAtomFamily({
              organisationId,
            }),
            (cur) =>
              cur.filter(
                (u) => !(u.user_id === userId && u.group_id === groupId),
              ),
          );
          set(
            allOrganisationGroupMembershipsAtomFamily({
              organisationId,
            }),
            RESET,
          );
          set(
            userOrganisationResourcesAtomFamily({
              organisationId,
              userId,
            }),
            RESET,
          );

          if (currentUserId === userId) {
            refreshUserLibraryAccess(organisationId);
          }
        })
        .catch(() => {
          aset(
            get,
            set,
            allOrganisationGroupMembershipsAtomFamily({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [currentUserId, organisationId, refreshUserLibraryAccess],
  );

  return {
    add,
    remove,
  };
};

export default useMemberInGroupCrud;
