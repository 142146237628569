import { AnalysisStatus, fetchAnalysisWindData } from "functions/production";
import { useCallback, useEffect, useState } from "react";
import { ArchiveProjectVersionAnalysisId } from "./utils";
import { getAnalysisResponse } from "analysis/output";
import { useAtomUnwrap } from "utils/jotai";

export default function useWindDataUrl(projectId: string) {
  const analysis = useAtomUnwrap(
    getAnalysisResponse(ArchiveProjectVersionAnalysisId),
  );

  const [url, setUrl] = useState<string | null>(null);
  const fetchDataWithRetry = useCallback(
    async (analysis: AnalysisStatus, retries: number = 0) => {
      try {
        const data = await fetchAnalysisWindData({
          nodeId: projectId,
          id: analysis.id,
          version: analysis.version,
        });
        setUrl(data.url);
      } catch (e) {
        if (retries < 3) {
          setTimeout(() => fetchDataWithRetry(analysis, retries + 1), 1000);
        }
      }
    },
    [projectId],
  );

  useEffect(() => {
    if (!url && analysis) {
      fetchDataWithRetry(analysis);
    }
  }, [analysis, fetchDataWithRetry, projectId, url]);

  return url;
}
