import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useMemo } from "react";
import { DefaultMap } from "lib/DefaultMap";
import { isDefined } from "../../../utils/predicates";
import { undefMap } from "../../../utils/utils";
import { useDashboardContext } from "../Dashboard";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";
import { useAtomValue } from "jotai";
import { cable3DLengthsFamily, cablesInParkFamily } from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import {
  exportCableTypeLengthsFamily,
  exportCableTypesFamily,
} from "state/jotai/exportCableType";
import { loadable } from "jotai/utils";
import { estimatePowerRating } from "components/Cabling/CableWalk";

const CablingDetails = () => {
  const { park, branch, configuration } = useDashboardContext();
  const cables = useAtomValue(
    cablesInParkFamily({ parkId: park.id, branchId: undefined }),
  );
  const cableTypes = useAtomValue(cableTypesFamily({ projectId: undefined }));

  const parkId = park.id;
  const branchId = branch.id;

  const cableLengthsMaybe = useAtomValue(
    loadable(
      cable3DLengthsFamily({
        parkId,
        branchId,
        analysisConfigurationId: configuration.id,
      }),
    ),
  );

  const exportCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );
  const exportCableLengths = useAtomValue(
    exportCableTypeLengthsFamily({ parkId, branchId }),
  );

  const items = useMemo(() => {
    if (!cableLengthsMaybe || cableLengthsMaybe.state !== "hasData") return;
    const cableLengths = cableLengthsMaybe.data;

    let lengthByCableId = new DefaultMap(() => 0);
    let costByCableId = new DefaultMap(() => 0);

    for (const cable of cables) {
      const typeId = cable.properties.cableTypeId ?? "other";
      const type = cableTypes.get(typeId);

      const length = cableLengths.get(cable.id)?.contingent ?? 0;
      lengthByCableId.update(typeId, (n) => n + length);

      if (type && isDefined(type.costPerKilometer)) {
        const cost = type.costPerKilometer * length;
        costByCableId.update(typeId, (n) => n + cost);
      }
    }

    type Item = {
      id: string;
      name: string;
      powerRating?: number;
      length: number;
      cost?: number;
      voltage?: number;
    };
    const items: Item[] = [...cableTypes.values()]
      .map<Item>((t) => ({
        id: t.id,
        name: t.name,
        powerRating: t.ampacity ? estimatePowerRating(t) / 1e6 : undefined,
        length: lengthByCableId.get(t.id),
        cost: costByCableId.get(t.id),
        voltage: t.voltage,
      }))
      .concat(
        [...exportCableTypes.values()].map<Item>((t) => ({
          id: t.id,
          name: t.name,
          powerRating: t.ampacity ? estimatePowerRating(t) / 1e6 : undefined,
          length: exportCableLengths.get(t.id)?.straightKM ?? 0,
          voltage: t.voltage,
        })),
      )
      .concat([
        {
          id: "__other",
          name: "Other",
          length: lengthByCableId.get("other"),
        },
      ]);

    items.sort((a, b) => a.name.localeCompare(b.name));

    return items
      .filter((i) => i.length > 0)
      .map((item) => ({
        id: item.id,
        name: item.name,
        powerRating: undefMap(item.powerRating, (pr) => pr.toFixed(0)) ?? "-",
        length: item.length.toFixed(2),
        cost: undefMap(item.cost, (c) => c.toFixed(0)) ?? "-",
        voltage: item.voltage ?? "-",
      }));
  }, [
    cableLengthsMaybe,
    cableTypes,
    cables,
    exportCableLengths,
    exportCableTypes,
  ]);

  const invalidCables = useAtomValue(
    invalidTypesInParkFamily({ parkId, branchId }),
  ).cables;

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No cables in park."} />
      </CenterContainer>
    );
  }

  if (invalidCables)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some cables in the park have invalid cable types."}
        />
      </CenterContainer>
    );

  if (!items) {
    return <LoadingState />;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Estimated power rating (MW)</th>
          <th>Total length (km)</th>
          <th>Voltage (kV)</th>
        </tr>
      </thead>
      <tbody>
        {items.map((i) => (
          <tr key={i.id}>
            <td>{i.name}</td>
            <td>{i.powerRating}</td>
            <td>{i.length}</td>
            <td>{i.voltage}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const CableUsageWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Cables usage"
      id="Cabling"
      resetKeys={errorBoundaryResetKeys}
    >
      <CablingDetails />
    </SafeCard>
  );
};
