import {
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import { CirclePaint, SymbolLayout } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { PortPointFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_MOORING_VISIBLE_ZOOM,
  portLayerId,
  portSourceId,
  portSymbolLayerId,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { READ, caseexpr, pointOpacityOutsidePark } from "./defaults";
import { useAtomValue } from "jotai";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const portPaint: CirclePaint = {
  "circle-color": colors.port,
  "circle-opacity": pointOpacityOutsidePark,
  "circle-radius": defaultPointCircleRadius,
  "circle-stroke-color": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    colors.lockedFeatureOutline,
    caseexpr({
      state: { borderColor: READ },
      selected: colors.white,
      fallback: colors.port,
    }),
  ],
  "circle-stroke-width": [
    "case",
    ["==", ["get", lockedPropertyName], true],
    2.0,
    ["!=", ["feature-state", "borderColor"], null],
    2.0,
    caseexpr({
      selected: 2,
      hover: 2,
      fallback: 0,
    }),
  ],
};

const portLabel: SymbolLayout = {
  "text-offset": [0, -1.4],
  "text-size": 10,
  "text-anchor": "center",
};

export const RenderPorts = ({
  ports,
  map,
  showSelectionHighlighter,
}: {
  ports: PortPointFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => ports.map((f) => f.id), [ports]);
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  useEffect(() => {
    map.addSource(portSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, portSymbolLayerId);
      safeRemoveLayer(map, portLayerId);
      map.removeSource(portSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: portLayerId,
      type: "circle",
      source: portSourceId,
      paint: portPaint,
      minzoom: MIN_MOORING_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: portSymbolLayerId,
      source: portSourceId,
      type: "symbol",
      minzoom: 11,
      layout: portLabel,
      filter: ["boolean", ["get", displayLabelPropertyName], true],
      paint: defaultMapTextPaint,
    });
  }, [map, defaultMapTextPaint]);

  useEffect(() => {
    const source = map.getSource(portSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(ports),
    });
  }, [map, ports]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={portSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
