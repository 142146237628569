import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { CableCorridorFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer } from "./utils";
import {
  cableCorridorSourceId,
  cableCorridorOutlineLayerId,
  cableCorridorLayerId,
  MIN_DIVISION_VISIBLE_ZOOM,
} from "./constants";
import { READ, caseexpr } from "./defaults";
import { FillPaint, LinePaint } from "mapbox-gl";
import { isOnshoreAtom } from "state/onshore";
import { atom, useAtomValue } from "jotai";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const cableCorridorFillPaintAtom = atom<FillPaint>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore)
    return {
      "fill-color": colors.onElCableCorridor,
      "fill-opacity": caseexpr({
        fallback: 0.3,
        hover: 0.4,
        selected: 0.8,
      }),
    };
  return {
    "fill-color": colors.cableCorridor,
    "fill-opacity": caseexpr({
      fallback: 0.3,
      hover: 0.5,
      selected: 0.8,
    }),
  };
});

const cableCorridorOutlinePaintAtom = atom<LinePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore)
    return {
      "line-color": caseexpr({
        fallback: colors.onElCableCorridorBorder,
        selected: colors.onElCableCorridorBorderSel,
        state: { borderColor: READ },
      }),
      "line-opacity": 1.0,
      "line-width": caseexpr({
        fallback: 1,
        hover: 2,
        selected: 2,
        active: 2,
      }),
    };
  return {
    "line-color": caseexpr({
      fallback: colors.cableCorridor,
      selected: colors.white,
      state: { borderColor: READ },
    }),
    "line-opacity": 1.0,
    "line-width": caseexpr({
      fallback: 1,
      hover: 2,
      selected: 2,
      active: 2,
    }),
  };
});

export const RenderCableCorridor = ({
  cableCorridors: features,
  map,
  showSelectionHighlighter,
}: {
  cableCorridors: CableCorridorFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const cableCorridorFillPaint = useAtomValue(cableCorridorFillPaintAtom);
  const cableCorridorOutlinePaint = useAtomValue(cableCorridorOutlinePaintAtom);
  useEffect(() => {
    map.addSource(cableCorridorSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cableCorridorOutlineLayerId);
      safeRemoveLayer(map, cableCorridorLayerId);
      map.removeSource(cableCorridorSourceId);
    };
  }, [map, cableCorridorFillPaint, cableCorridorOutlinePaint]);

  useEffect(() => {
    addLayer(map, {
      id: cableCorridorLayerId,
      type: "fill",
      source: cableCorridorSourceId,
      paint: cableCorridorFillPaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: cableCorridorOutlineLayerId,
      type: "line",
      source: cableCorridorSourceId,
      paint: cableCorridorOutlinePaint,
    });
  }, [map, cableCorridorFillPaint, cableCorridorOutlinePaint]);

  useEffect(() => {
    const source = map.getSource(cableCorridorSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features,
    });
  }, [map, features]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={cableCorridorSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
