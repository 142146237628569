import React from "react";
import styled from "styled-components";
import { spacing2, spacing4 } from "styles/space";
import TeamMeetingIcon from "@icons/24/TeamMeeting.svg";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import Tooltip, { TooltipText } from "components/General/Tooltip";
import { Group } from "components/Organisation/Groups/types";
import GroupLink from "components/Organisation/OrganisationRightSide/content/NodeContent/tabs/GroupLink";

const GroupTagWrapper = styled.div`
  display: flex;
  padding: ${spacing2} ${spacing4};
  background-color: ${colors.blue100};
  gap: ${spacing2};
  border-radius: 4px;
  overflow: hidden;
`;

const GroupTooltipContent = ({
  group,
  organisationId,
}: {
  group?: Pick<Group, "name" | "id">;
  organisationId?: string;
}) => {
  if (!group || !organisationId) {
    return null;
  }

  return (
    <TooltipText secondary={false} theme="dark">
      Access given through group{" "}
      <GroupLink
        organisationId={organisationId}
        groupId={group.id}
        style={{ color: colors.blue300 }}
      >
        {group.name}
      </GroupLink>
    </TooltipText>
  );
};

const GroupTag = ({
  organisationId,
  size,
  group,
  style,
}: {
  organisationId: string;
  size: "full" | "compact";
  group?: Pick<Group, "name" | "id">;
  style?: React.CSSProperties;
}) => {
  return (
    <Tooltip
      text=""
      delay={100}
      interactive={true}
      closeDelay={100}
      content={
        <GroupTooltipContent group={group} organisationId={organisationId} />
      }
      disabled={!group}
      innerDivStyle={{
        overflow: "hidden",
      }}
      outerDivStyle={{
        overflow: "hidden",
      }}
    >
      <GroupTagWrapper style={style}>
        <IconREMSize height={1.2} width={1.2}>
          <TeamMeetingIcon />
        </IconREMSize>
        {size === "full" && (
          <p
            style={{
              ...typography.graphics,
              color: colors.textBrand,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
            }}
          >
            {group?.name}
          </p>
        )}
      </GroupTagWrapper>
    </Tooltip>
  );
};

export default GroupTag;
