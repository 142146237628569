import styled from "styled-components";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { isDefined, isMooringLineSingle } from "../../../utils/predicates";
import { ReactNode, useCallback } from "react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import Table from "components/Dashboard/Table";
import {
  MooringLineFeature,
  MooringLineSingleFeature,
  TurbineFeature,
} from "types/feature";
import { MooringLineType } from "services/mooringLineTypeService";
import Tooltip from "components/General/Tooltip";
import InfoIcon from "@icons/24/Information.svg?react";
import { Position } from "geojson";
import { colors } from "styles/colors";
import * as turf from "@turf/turf";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { MenuItem } from "components/General/Menu";
import DownloadIcon from "@icons/24/Download.svg?react";
import { downloadText } from "utils/utils";
import { useAtomValue } from "jotai";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { mooringLineTypesInParkAtom } from "state/jotai/mooringLineType";

const InfoIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 12px;
    height: 12px;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

const useMooringLinesData = () => {
  const { park, branch } = useDashboardContext();
  const branchId = branch.id;
  const parkId = park.id;

  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId, branchId }),
  );

  const mooringLineTypes = useAtomValue(
    mooringLineTypesInParkAtom({ branchId, parkId }),
  );
  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const turbineNames = new Map<string, string | undefined>(
    turbines.map((t) => [t.id, t.properties.name]),
  );

  // Sort mooring lines by target turbine to make the list more readable
  const mooringLinesSorted =
    mooringLines.length === 0
      ? []
      : [...mooringLines].sort((a, b) => {
          const turbineNameA = turbineNames.get(a.properties.target);
          const turbineNameB = turbineNames.get(b.properties.target);
          const regex = /^#(\d+)$/; // Regex to match strings like #1, #2, etc.
          if (turbineNameA === turbineNameB) {
            return (
              calculateOrientation(
                a.geometry.coordinates[0],
                a.geometry.coordinates[1],
              ) -
              calculateOrientation(
                b.geometry.coordinates[0],
                b.geometry.coordinates[1],
              )
            );
          } else if (
            turbineNameA &&
            turbineNameB &&
            turbineNameA.match(regex) &&
            turbineNameB.match(regex)
          ) {
            return (
              parseInt(turbineNameA.split("#")[1]) -
              parseInt(turbineNameB.split("#")[1])
            );
          } else {
            return (turbineNameA as string).localeCompare(
              turbineNameB as string,
            );
          }
        });

  return { mooringLinesSorted, turbines, mooringLineTypes };
};

function calculateOrientation(start: Position, end: Position) {
  const startTurf = turf.point([start[0], start[1]]);
  const endTurf = turf.point([end[0], end[1]]);

  let angle = turf.bearing(startTurf, endTurf);

  angle = (angle + 180) % 360;

  return Math.round(angle);
}

const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const MooringLinesListDetails = () => {
  const { mooringLinesSorted, turbines, mooringLineTypes } =
    useMooringLinesData();

  if (mooringLinesSorted.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No mooring lines in park."} />
      </CenterContainer>
    );
  }

  const someLinesSegmented = mooringLinesSorted.some((line) =>
    Array.isArray(line.properties.lineLengths),
  );

  return (
    <>
      {someLinesSegmented ? (
        <MooringLinesSegmentedDetails
          mooringLines={mooringLinesSorted}
          mooringLineTypes={mooringLineTypes}
          turbines={turbines}
        />
      ) : (
        <MooringLinesUniformDetails
          mooringLines={mooringLinesSorted}
          mooringLineTypes={mooringLineTypes}
          turbines={turbines}
        />
      )}
    </>
  );
};

const MooringLinesUniformDetails = ({
  mooringLines,
  mooringLineTypes,
  turbines,
}: {
  mooringLines: MooringLineFeature[];
  mooringLineTypes: Map<string, MooringLineType>;
  turbines: TurbineFeature[];
}) => {
  const mooringLinesFormatted = mooringLines.map((line) => {
    const turbine = turbines.find((t) => t.id === line.properties.target);
    const lineType = mooringLineTypes.get(String(line.properties.lineType));

    const lineLength =
      typeof line.properties.lineLength === "number"
        ? `${line.properties.lineLength.toFixed(2)} km`
        : "Unknown";

    const orientation = `${calculateOrientation(
      line.geometry.coordinates[0],
      line.geometry.coordinates[1],
    )} deg`;

    return {
      turbine: turbine ? turbine.properties.name : "Unknown",
      lineType: lineType ? lineType.name : "Unknown",
      lineLength,
      orientation,
    };
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>
            <InfoIconWrapper>
              <>Orientation</>
              <Tooltip text={`Clockwise relative to north`}>
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>Type</th>
          <th>Line length</th>
        </tr>
      </thead>
      <tbody>
        {mooringLinesFormatted.map(
          ({ turbine, lineType, orientation, lineLength }, index) => (
            <tr key={index}>
              <td>{undefToSkeleton(turbine)}</td>
              <td>{undefToSkeleton(orientation)}</td>
              <td>{undefToSkeleton(lineType)}</td>
              <td>{undefToSkeleton(lineLength)}</td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const MooringLinesSegmentedDetails = ({
  mooringLines,
  mooringLineTypes,
  turbines,
}: {
  mooringLines: MooringLineFeature[];
  mooringLineTypes: Map<string, MooringLineType>;
  turbines: TurbineFeature[];
}) => {
  const segmentedMooringLinesFormatted = mooringLines
    .filter((line) => Array.isArray(line.properties.lineLengths))
    .map((line) => {
      const turbine = turbines.find((t) => t.id === line.properties.target);
      const lineTypes = (line.properties.lineTypes as string[]).map(
        (lineTypeId) => {
          const lineType = mooringLineTypes.get(lineTypeId);
          return lineType ? lineType.name : "Unknown";
        },
      );

      const lineLengths = (line.properties.lineLengths as number[]).map(
        (length) => {
          return typeof length === "number"
            ? `${length.toFixed(2)} km`
            : "Unknown";
        },
      );
      const attachments = (line.properties.attachments as number[]).map(
        (attachment) => {
          return typeof attachment === "number"
            ? `${attachment / 1000} tonnes`
            : "Unknown";
        },
      );
      const orientation = `${calculateOrientation(
        line.geometry.coordinates[0],
        line.geometry.coordinates[1],
      )} deg`;

      return {
        turbine: turbine ? turbine.properties.name : "Unknown",
        lineTypes,
        lineLengths,
        orientation,
        attachments: attachments,
      };
    });

  const uniformMooringLinesFormatted = mooringLines
    .filter(isMooringLineSingle)
    .map((line: MooringLineSingleFeature) => {
      const turbine = turbines.find((t) => t.id === line.properties.target);
      const lineType = mooringLineTypes.get(line.properties.lineType ?? "");

      const lineLength =
        typeof line.properties.lineLength === "number"
          ? `${line.properties.lineLength.toFixed(4)} km`
          : "Unknown";

      const orientation = `${calculateOrientation(
        line.geometry.coordinates[0],
        line.geometry.coordinates[1],
      )} deg`;

      return {
        turbine: turbine ? turbine.properties.name : "Unknown",
        lineType: lineType ? lineType.name : "Unknown",
        lineLength,
        orientation,
      };
    });

  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>
            <InfoIconWrapper>
              <>Orientation</>
              <Tooltip text={`Clockwise relative to north`}>
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>Type</th>
          <th>Segment length</th>
          <th>Type</th>
          <th>Segment length</th>
          <th>Type</th>
          <th>Segment length</th>
          <th>
            <InfoIconWrapper>
              <>Attachment 1</>
              <Tooltip text={`Attachment net weight on upper end of segment 1`}>
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>
            <InfoIconWrapper>
              <>Attachment 2</>
              <Tooltip text={`Attachment net weight on upper end of segment 2`}>
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
        </tr>
      </thead>
      <tbody>
        {segmentedMooringLinesFormatted.map(
          (
            { turbine, lineTypes, orientation, lineLengths, attachments },
            index,
          ) => (
            <tr key={index}>
              <td>{undefToSkeleton(turbine)}</td>
              <td>{undefToSkeleton(orientation)}</td>
              <td>{undefToSkeleton(lineTypes[0])}</td>
              <td>{undefToSkeleton(lineLengths[0])}</td>
              <td>{undefToSkeleton(lineTypes[1])}</td>
              <td>{undefToSkeleton(lineLengths[1])}</td>
              <td>{undefToSkeleton(lineTypes[2])}</td>
              <td>{undefToSkeleton(lineLengths[2])}</td>
              <td>{undefToSkeleton(attachments[0])}</td>
              <td>{undefToSkeleton(attachments[1])}</td>
            </tr>
          ),
        )}
        {uniformMooringLinesFormatted.map(
          // Unusual case to have segmented AND uniform lines in a park, but let's handle it anyway
          ({ turbine, lineType, orientation, lineLength }, index) => (
            <tr key={index}>
              <td>{undefToSkeleton(turbine)}</td>
              <td>{undefToSkeleton(orientation)}</td>
              <td>{undefToSkeleton(lineType)}</td>
              <td>{undefToSkeleton(lineLength)}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const LinesMenu = () => {
  const { mooringLinesSorted, turbines, mooringLineTypes } =
    useMooringLinesData();
  const { park } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const segmentedFields = [
      "turbine",
      "orientation",
      "lineType1",
      "lineLength1",
      "lineType2",
      "lineLength2",
      "lineType3",
      "lineLength3",
      "attachment1",
      "attachment2",
    ];
    const uniformFields = ["turbine", "orientation", "lineType", "lineLength"];
    type SegmentedField = (typeof segmentedFields)[number];
    type UniformField = (typeof uniformFields)[number];

    let csvSegmented = "Turbine";
    let csvUniform = "Turbine";

    const delimiter = ",";
    const removeDelimiter = (s: string) => s.replace(delimiter, "");

    const segmentedMooringLinesFormatted = mooringLinesSorted
      .filter((line) => Array.isArray(line.properties.lineLengths))
      .map((line) => {
        const turbine = turbines.find((t) => t.id === line.properties.target);
        const lineTypes = (line.properties.lineTypes as string[]).map(
          (lineTypeId) => {
            const lineType = mooringLineTypes.get(lineTypeId);
            return lineType ? lineType.name : "Unknown";
          },
        );

        const lineLengths = (line.properties.lineLengths as number[]).map(
          (length) => {
            return typeof length === "number"
              ? `${length.toFixed(2)} km`
              : "Unknown";
          },
        );
        const attachments = (line.properties.attachments as number[]).map(
          (attachment) => {
            return typeof attachment === "number"
              ? `${attachment / 1000} tonnes`
              : "Unknown";
          },
        );
        const orientation = `${calculateOrientation(
          line.geometry.coordinates[0],
          line.geometry.coordinates[1],
        )} deg`;

        return {
          turbine: turbine ? turbine.properties.name : "Unknown",
          lineType1: lineTypes[0],
          lineLength1: lineLengths[0],
          lineType2: lineTypes[1],
          lineLength2: lineLengths[1],
          lineType3: lineTypes[2],
          lineLength3: lineLengths[2],
          orientation,
          attachment1: attachments[0],
          attachment2: attachments[1],
        };
      });

    const uniformMooringLinesFormatted = mooringLinesSorted
      .filter((line) => !Array.isArray(line.properties.lineLengths))
      .map((line) => {
        const turbine = turbines.find((t) => t.id === line.properties.target);
        const lineType = mooringLineTypes.get(String(line.properties.lineType));

        const lineLength =
          typeof line.properties.lineLength === "number"
            ? `${line.properties.lineLength.toFixed(2)} km`
            : "Unknown";

        const orientation = `${calculateOrientation(
          line.geometry.coordinates[0],
          line.geometry.coordinates[1],
        )} deg`;

        return {
          turbine: turbine ? turbine.properties.name : "Unknown",
          lineType: lineType ? lineType.name : "Unknown",
          lineLength,
          orientation,
        };
      });

    // Generate CSV for segmented lines
    if (segmentedMooringLinesFormatted.length > 0) {
      const segmentedHeaderNames: Record<SegmentedField, string> = {
        turbine: "Turbine",
        orientation: "Orientation",
        lineType1: "Type",
        lineLength1: "Segment length",
        lineType2: "Type",
        lineLength2: "Segment length",
        lineType3: "Type",
        lineLength3: "Segment length",
        attachment1: "Attachment 1",
        attachment2: "Attachment 2",
      };

      for (const f of segmentedFields.slice(1)) {
        csvSegmented += delimiter + segmentedHeaderNames[f];
      }

      for (const d of segmentedMooringLinesFormatted) {
        csvSegmented += "\n" + removeDelimiter(String(d.turbine));
        for (const f of segmentedFields.slice(1)) {
          csvSegmented +=
            delimiter + removeDelimiter(String(d[f as keyof typeof d]));
        }
      }
    }
    // Generate CSV for uniform lines
    if (uniformMooringLinesFormatted.length > 0) {
      const uniformHeaderNames: Record<UniformField, string> = {
        turbine: "Turbine",
        orientation: "Orientation",
        lineType: "Type",
        lineLength: "Line length",
      };
      for (const f of uniformFields.slice(1)) {
        csvUniform += delimiter + uniformHeaderNames[f];
      }

      for (const d of uniformMooringLinesFormatted) {
        csvUniform += "\n" + removeDelimiter(String(d.turbine));
        for (const f of uniformFields.slice(1)) {
          csvUniform +=
            delimiter + removeDelimiter(String(d[f as keyof typeof d]));
        }
      }
    }

    // Concatenate the two CSV strings with a couple of newlines in between
    let csv = csvSegmented + "\n\n" + csvUniform;

    downloadText(
      csv,
      `mooring-lines-${park.properties.name}-${new Date().toISOString()}.csv`,
    );
  }, [mooringLinesSorted, park.properties.name, mooringLineTypes, turbines]);

  return (
    <MenuItem
      disabled={mooringLinesSorted.length === 0}
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      onClick={() => downloadCSV()}
    />
  );
};

export const MooringLinesListWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Mooring lines list"
      id="Mooring lines list"
      helptext="Mooring line segments are ordered from anchor to fairlead"
      menuItems={<LinesMenu />}
      resetKeys={errorBoundaryResetKeys}
    >
      <MooringLinesListDetails />
    </SafeCard>
  );
};
