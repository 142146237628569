import styled from "styled-components";
import GridCard from "../GridView/GridCard";
import ListView from "../ListView/ListView";
import { ViewMode } from "components/DataLayersModal/DataLayersModal";
import { useAtom, useAtomValue } from "jotai";
import { getSuggestedLayersSelector } from "state/layer";
import { Layer } from "types/layers";
import { useCallback } from "react";
import { selectedLayersToAddAtom } from "components/LayerList/LayerSettings/state";
import { Mixpanel } from "mixpanel";

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem 2.2rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface VindDataLayerSuggestionsProps {
  viewMode: ViewMode;
  selectedLayer: Layer | null;
  setSelectedLayer: (layer: Layer | null) => void;
  handleToggleFavorite?: (layerId: string) => void;
}

const VindDataLayerSuggestions = ({
  viewMode,
  selectedLayer,
  setSelectedLayer,
  handleToggleFavorite,
}: VindDataLayerSuggestionsProps) => {
  const filteredLayers = useAtomValue(getSuggestedLayersSelector);
  const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
    selectedLayersToAddAtom,
  );

  const handleLayerSelection = useCallback(
    (layerId: string) => {
      setSelectedLayersToAdd((prev: string[]) => {
        if (prev.includes(layerId)) {
          return prev.filter((id) => id !== layerId);
        }
        return [...prev, layerId];
      });
      Mixpanel.track_old("layer_selected", {
        layer_type: "vind-suggested",
        layer_id: layerId,
      });
    },
    [setSelectedLayersToAdd],
  );

  return (
    <div style={{ padding: "1rem 2rem", height: "100%" }}>
      {viewMode === "grid" ? (
        <GridContainer>
          {filteredLayers.map((layer) => (
            <GridCard
              key={layer.id}
              layer={layer}
              setSelectedLayer={setSelectedLayer}
              handleLayerSelection={handleLayerSelection}
              selectedLayersToAdd={selectedLayersToAdd}
              handleToggleFavorite={handleToggleFavorite}
            />
          ))}
        </GridContainer>
      ) : (
        <ListView
          layers={filteredLayers}
          selectedLayer={selectedLayer}
          setSelectedLayer={setSelectedLayer}
          setSelectedLayersToAdd={setSelectedLayersToAdd}
          selectedLayersToAdd={selectedLayersToAdd}
          handleToggleFavorite={handleToggleFavorite}
        />
      )}
    </div>
  );
};

export default VindDataLayerSuggestions;
