import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
  getFinancialResourcesOnNode,
} from "components/Organisation/Library/service";
import {
  financialResourceWithAccessOnNodeState,
  nodesWithAccessToFinancial,
} from "components/Organisation/Library/state";
import { CostConfiguration } from "services/costService";
import { libraryFinancialConfigRefreshAtom } from "state/costConfigurations";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeFinancialResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, config: CostConfiguration) => {
      const fallback = await get(
        financialResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      return addOrUpdateResourceOnNode(
        nodeId,
        config.id,
        "org_financial_manage",
      )
        .then(async () => {
          const updatedConfigs = await getFinancialResourcesOnNode(nodeId);
          aset(
            get,
            set,
            financialResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => updatedConfigs,
          );
          set(
            nodesWithAccessToFinancial({
              organisationId,
              resourceId: config.id,
            }),
          );
          set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
        })
        .catch((e) => {
          aset(
            get,
            set,
            financialResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        financialResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "COST_CONFIGURATION",
          resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          nodeId,
          "COST_CONFIGURATION",
          resourceId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Financial configuration is currently being used in ${usage.length} projects, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(nodeId, resourceId, "org_financial_manage")
          .then(() => {
            aset(
              get,
              set,
              financialResourceWithAccessOnNodeState({
                nodeId,
              }),
              (cur) => cur.filter((c) => c.config.id !== resourceId),
            );
            set(
              nodesWithAccessToFinancial({
                organisationId,
                resourceId,
              }),
            );
            set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
          })
          .catch((e) => {
            aset(
              get,
              set,
              financialResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeFinancialResourcesCrud;
