import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { parkFamily } from "state/jotai/park";
import { getAllExportSystemTypesInParkAndBranchFamily } from "state/jotai/electrical";

export const differentExportSystemTypesFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string }) =>
    atom<
      Promise<
        | {
            type: ValidationWarningTypes.ExportCableDifferentTypes;
            featureIds: string[];
            parkId: string;
          }
        | undefined
      >
    >(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;

      const exportCables = await get(
        exportCablesInParkFamily({
          parkId,
          branchId,
        }),
      );
      const exportSystemTypes = await get(
        getAllExportSystemTypesInParkAndBranchFamily({
          parkId: park.id,
          branchId: branchId,
        }),
      );
      return exportSystemTypes.length <= 1
        ? undefined
        : {
            type: ValidationWarningTypes.ExportCableDifferentTypes,
            featureIds: exportCables.map((c) => c.id),
            parkId: parkId,
          };
    }),
);
