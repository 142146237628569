import { z } from "zod";
import { fetchSchemaWithToken } from "./utils";
import { components } from "api/organisation-settings";

export const _Stage = components.schemas.Stage;
export type Stage = z.infer<typeof _Stage>;

export async function getStages(organisationId: string) {
  return fetchSchemaWithToken(
    z.object({
      stages: z.array(_Stage),
    }),
    `/api/organisation-settings/organisation/${organisationId}/stages`,
    {
      method: "get",
    },
  );
}

export async function updateStages(
  organisationId: string,
  stages: { name: string; color: string }[],
): Promise<Stage[]> {
  const response = await fetchSchemaWithToken(
    z.object({
      stages: z.array(_Stage),
    }),
    `/api/organisation-settings/organisation/${organisationId}/stages`,
    {
      method: "put",
      body: JSON.stringify({ stages }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response.stages;
}
