import { useAbly } from "ably/react";
import { RealtimeClient } from "ably";
import * as Sentry from "@sentry/react";

// default hook from ably/react might throw an error if the ablyId is not valid
// this hook will catch the error and return null
export default function useAblyClient(ablyId: string): RealtimeClient | null {
  try {
    const client = useAbly(ablyId);
    return client;
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        ablyId,
        component: "useAblyWithErrorHandling",
      },
    });
    console.error("Failed to get Ably client:", error);
    return null;
  }
}
