import DownloadIcon from "@icons/24/Download.svg?react";
import Tooltip from "components/General/Tooltip";
import { TextIcon } from "styles/typography";
import { MenuItem } from "components/General/Menu";
import { colors } from "styles/colors";
import { downloadText } from "utils/utils";
import { useEffect, useState } from "react";
import { stringify } from "csv-stringify/sync";
import { useToast } from "hooks/useToast";
import Button from "components/General/Button";

type InputData = Record<string, unknown>[];

const csvOptions = {
  header: true,
  escape: '"',
  quoted: true,
  quoted_empty: true,
  escape_formulas: true,
} as const;

export const DownloadCSV = ({
  filename,
  data: dataPromise,
  columns,
  buttonType,
  loading,
}: {
  filename: string;
  data: () => Promise<InputData | undefined>;
  columns?: { header: string; field: string }[];
  buttonType: "text" | "menu";
  loading?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const { error } = useToast();

  useEffect(() => {
    if (!isLoading) return;
    let stop = false;
    dataPromise()
      .then((data) => {
        if (!stop && data) setIsLoading(false);
      })
      .catch(() => {});
    return () => {
      stop = true;
    };
  }, [dataPromise, isLoading]);

  const handleDownload = async () => {
    setIsLoading(true);
    const d = await dataPromise().catch((e) => {
      setIsLoading(false);
      throw e;
    });
    if (!d) return;
    setIsLoading(false);
    const csv = stringify(d, {
      ...csvOptions,
      columns: columns?.map(({ field, header }) => ({ key: field, header })),
    });
    downloadText(csv, filename);
  };

  if (isLoading) {
    return <Fallback buttonType={buttonType} />;
  }

  return buttonType === "text" ? (
    <Tooltip text="Download as .csv">
      <Button
        text={"Download"}
        buttonType="text"
        icon={<DownloadIcon />}
        onClick={() =>
          handleDownload().catch((e) =>
            error(`Error downloading CSV:\n${e.message}`),
          )
        }
      ></Button>
    </Tooltip>
  ) : (
    <MenuItem
      title={"Download"}
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      disabled={isLoading}
      onClick={() =>
        handleDownload().catch((e) =>
          error(`Error downloading CSV:\n${e.message}`),
        )
      }
    />
  );
};

const Fallback = ({ buttonType }: { buttonType: "text" | "menu" }) => {
  return buttonType === "text" ? (
    <Tooltip text="Download as .csv">
      <TextIcon
        stroke={colors.primaryDisabled}
        style={{
          marginLeft: "1rem",
          scale: 0.6,
        }}
      >
        <DownloadIcon />
      </TextIcon>
    </Tooltip>
  ) : (
    <MenuItem
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      disabled={true}
    />
  );
};
