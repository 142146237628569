import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { CableType, _IAVoltageType } from "../../../services/cableTypeService";
import { ColumnSection } from "../../SettingsV2/Shared/styles";
import { Column } from "../../General/Layout";
import { spaceMedium } from "../../../styles/space";
import { defaultIACables } from "../../../constants/cabling";
import { parseOr } from "utils/zod";
import { VOLTAGE_DEFAULT } from "types/cables";
import { useDuplicateCableToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { orgCableManageAccessSelector } from "state/user";
import {
  cableChangelogInfo,
  idToCableChangelogId,
} from "components/InputChangelog/const";
import { HeaderWrapper } from "../shared";
import CableHeader from "./Components/Header";
import ProjectContextActions from "./Components/ProjectContextActions";
import GeneralInfo from "./Components/GeneralInfo";
import AdvancedSettings, {
  AdvancedSettingsHeader,
} from "./Components/AdvancedSettings";
import ElectricalProperties from "./Components/ElectricalProperties";
import { SettingsFormActions } from "../Components/SettingsFormActions";
import { CableSettingsLayout } from "./Components/CableSettingsLayout";
import { isOnshoreAtom } from "state/onshore";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useCableEditing } from "./hooks/useCableEditing";

export function SingleCable({
  cable,
  onSave,
  isLoading,
  disabled,
  isLibraryCable,
}: {
  cable: CableType;
  onSave?: (cable: CableType, skipUsageCheck?: boolean) => void;
  isLoading: boolean;
  disabled: boolean;
  isLibraryCable?: boolean;
}) {
  const onshore = useAtomValue(isOnshoreAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectId = useAtomValue(projectIdAtom);
  const nodeId = isLibraryCable ? organisationId : projectId!;
  const inLibraryContext = !projectId;

  const { editedCable, setEditedCable, allChangesSaved } = useCableEditing({
    cable,
    onSave,
    isLoading,
    disabled,
  });

  const { duplicateToProject } = useDuplicateCableToProject();
  const orgCableManageAccess = useAtomValue(orgCableManageAccessSelector);

  const currentDefaultCables =
    defaultIACables[
      parseOr(_IAVoltageType, editedCable.voltage, VOLTAGE_DEFAULT)
    ];

  const isLibraryCableInProjectView = !!projectId && isLibraryCable;
  const changelogCategory = isLibraryCable ? "org_cable_manage" : "project";

  const handleUpdateCableDescription = useCallback(
    (description: string) => {
      if (!cable) return;
      setEditedCable((prevCable) => ({
        ...prevCable,
        note: description,
      }));
      if (onSave) {
        onSave(
          {
            ...cable,
            note: description,
          },
          true,
        );
      }
    },
    [cable, onSave, setEditedCable],
  );

  const onSelectCableFromTemplate = useCallback(
    (props: CableType) => {
      setEditedCable({
        ...editedCable,
        reactance: props.reactance,
        resistance: props.resistance,
        capacitance: props.capacitance,
        ampacity: props.ampacity,
        lambda1: props.lambda1,
        lambda2: props.lambda2,
        dielectricLossFactor: props.dielectricLossFactor,
      });
    },
    [editedCable, setEditedCable],
  );

  return (
    <CableSettingsLayout
      inLibraryContext={inLibraryContext}
      header={
        <HeaderWrapper>
          <CableHeader
            cable={editedCable}
            nodeId={nodeId}
            disabled={disabled}
            description={editedCable.note ?? ""}
            handleUpdateCableDescription={handleUpdateCableDescription}
            changelogId={idToCableChangelogId(cable.id)}
            category={changelogCategory}
          />
          <ProjectContextActions
            editedCable={editedCable}
            originalCable={cable}
            nodeId={nodeId}
            onSave={onSave}
            setCable={setEditedCable}
            duplicateToProject={() => duplicateToProject(cable)}
            allChangesSaved={allChangesSaved}
            isLibraryCableInProjectView={isLibraryCableInProjectView}
            orgCableManageAccess={orgCableManageAccess}
            organisationId={organisationId}
            isLoading={isLoading}
          />
        </HeaderWrapper>
      }
    >
      <Column>
        {inLibraryContext &&
          !allChangesSaved &&
          !isLibraryCableInProjectView && (
            <SettingsFormActions
              isLibraryContext={inLibraryContext}
              hasChanges={!allChangesSaved}
              isLoading={isLoading}
              onSave={() => {
                onSave?.(editedCable);
              }}
              onCancel={() => {
                setEditedCable(cable);
              }}
            />
          )}
        <ColumnSection
          style={{
            padding: 0,
          }}
        >
          <GeneralInfo
            cable={editedCable}
            setCable={setEditedCable}
            disabled={disabled}
            isLibraryCable={isLibraryCable}
            onshore={onshore}
            nodeId={nodeId}
            changelogCategory={changelogCategory}
            getChangelogInfo={cableChangelogInfo}
          />
          <ElectricalProperties
            editedCable={editedCable}
            originalCable={cable}
            setCable={setEditedCable}
            disabled={disabled}
            nodeId={nodeId}
            changelogCategory={changelogCategory}
            getChangelogInfo={cableChangelogInfo}
            onSelectCableFromTemplate={onSelectCableFromTemplate}
            currentDefaultCables={currentDefaultCables}
          />
          <AdvancedSettingsHeader
            cable={editedCable}
            setCable={setEditedCable}
            disabled={disabled}
            nodeId={nodeId}
            changelogCategory={changelogCategory}
            getChangelogInfo={cableChangelogInfo}
          />
          {editedCable.useAdvancedSettings && (
            <div
              style={{
                paddingTop: spaceMedium,
                display: "grid",
                justifyContent: "start",
                gridTemplateColumns: "auto auto auto ",
                gap: spaceMedium,
              }}
            >
              <AdvancedSettings
                cable={editedCable}
                setCable={setEditedCable}
                disabled={disabled}
                nodeId={nodeId}
                changelogCategory={changelogCategory}
                getChangelogInfo={cableChangelogInfo}
              />
            </div>
          )}
        </ColumnSection>
      </Column>
    </CableSettingsLayout>
  );
}
