import {
  Feature,
  FeatureCollection,
  Geometry,
  GeometryCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from "geojson";
import { v4 as uuidv4 } from "uuid";
import { GeotiffFeature, ProjectFeature } from "../../types/feature";
import { isMultiFeature, isMultiPolygonFeature } from "../predicates";
import { scream } from "../sentry";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "./../../services/types";
import { funcOnCoords } from "../utils";
import { multiToSingleGeometryType } from "./geojson";
import * as turf from "@turf/turf";
import { ABLY_SIZE_LIMIT } from "@constants/ably";

export const DECIMAL_PRECISION = 8;
export const SIMPLIFY_TOLERANCE = 0.00001;

const simplifyPolygonWithHoles = <T extends Feature<Polygon>>(
  feature: T,
  tolerance: number,
): T => {
  if (feature.geometry.coordinates.length === 1)
    return turf.simplify(feature, {
      tolerance,
      highQuality: false,
    });

  const simplifiedGeometries = feature.geometry.coordinates
    .map((c) => ({
      ...feature.geometry,
      coordinates: [c],
    }))
    .map((g) => turf.simplify(g, { tolerance, highQuality: false }));

  const outline = simplifiedGeometries[0].coordinates[0];
  const holes = simplifiedGeometries.slice(1).map((g) => g.coordinates[0]);

  const simplifiedPolygonWithHoles = {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [outline, ...holes],
    },
  } as Feature;

  return simplifiedPolygonWithHoles as T;
};

export const simplifyTakingCareOfPolygonsWithHoles = <
  T extends Feature<
    | Point
    | MultiPoint
    | LineString
    | MultiLineString
    | Polygon
    | MultiPolygon
    | GeometryCollection
  >,
>(
  feature: T,
  tolerance: number,
): T => {
  if (
    ["GeometryCollection", "Point", "MultiPoint"].includes(
      feature.geometry.type,
    )
  )
    return feature;

  if (["LineString", "MultiLineString"].includes(feature.geometry.type)) {
    return turf.simplify(feature as Feature<LineString | MultiLineString>, {
      tolerance,
      highQuality: false,
    }) as T;
  }

  if (feature.geometry.type === "Polygon") {
    return simplifyPolygonWithHoles(
      feature as Feature<Polygon>,
      tolerance,
    ) as T;
  }

  const multiPolygonFeature = feature as Feature<MultiPolygon>;
  const simplifiedPolygons = multiPolygonFeature.geometry.coordinates.map(
    (polygon) =>
      simplifyPolygonWithHoles(
        {
          ...feature,
          geometry: { type: "Polygon", coordinates: polygon },
        } as Feature<Polygon>,
        tolerance,
      ),
  ) as Feature<Polygon>[];
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: simplifiedPolygons.map((p) => p.geometry.coordinates),
    },
  };
};

export enum GeotiffType {
  georefimage = "georefimage",
  bathymetry = "bathymetry",
}

const uuid4RegEx =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const findUuid4sInString = (str: string): string[] | undefined => {
  const results = str.match(
    /([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/gi,
  );

  if (results) {
    return Array.from(results);
  }
};

const geotiffTypeToFeatureType: Record<
  GeotiffType,
  typeof BathymetryUserUploadedType | typeof GeoTiffUserUploadedImageType
> = {
  [GeotiffType.bathymetry]: BathymetryUserUploadedType,
  [GeotiffType.georefimage]: GeoTiffUserUploadedImageType,
};

const geotiffTypeToName: Record<GeotiffType, string> = {
  [GeotiffType.bathymetry]: "Bathymetry",
  [GeotiffType.georefimage]: "Image",
};

export const addIdOnFeaturesIfNotUUID4 = <
  F extends Feature<G, P>,
  G extends Geometry,
  P,
>(
  features: F[],
): (F & Feature<G, P & { id: string }> & { id: string })[] => {
  if (!features) return [];
  return features
    .filter((f) => f != null)
    .map((f) => {
      if (!("properties" in f)) {
        scream("Illegal geojson feature without a properties entry", {
          feature: f,
        });
      }
      const id =
        String(f?.id ?? "").match(uuid4RegEx) != null ? String(f.id) : uuidv4();
      return {
        ...f,
        id,
        properties: { ...f?.properties, id },
      };
    });
};

export const addIdOnFeatureCollectionIfNotUUID4 = (
  featureCollection: FeatureCollection,
): FeatureCollection => {
  return {
    ...featureCollection,
    features: addIdOnFeaturesIfNotUUID4(featureCollection.features),
  };
};

export const DEFAULT_PARK_NAME = "Untitled park";

export const bboxToCoords = (bbox: number[]) => [
  [bbox[0], bbox[1]],
  [bbox[2], bbox[1]],
  [bbox[2], bbox[3]],
  [bbox[0], bbox[3]],
  [bbox[0], bbox[1]],
];

export const geotiffGeojsonEntry = (
  filename: string,
  coords: number[][],
  geotiffType: GeotiffType,
  name?: string,
): GeotiffFeature => {
  const id: string = uuidv4();
  return {
    type: "Feature",
    id,
    properties: {
      type: geotiffTypeToFeatureType[geotiffType],
      filename,
      name: name ?? geotiffTypeToName[geotiffType],
      id,
    },
    geometry: {
      type: "Polygon",
      coordinates: [coords],
    },
  };
};

export function ToEastLatLng(
  positon: { lng: number; lat: number },
  eastDistance: number,
) {
  const r_earth = 6378;
  const pi = Math.PI;
  const new_longitude =
    positon.lng +
    ((eastDistance / r_earth) * (180 / pi)) /
      Math.cos((positon.lat * pi) / 180);
  return { lat: positon.lat, lng: new_longitude };
}

export function multiFeatureToFeatures(
  maybeMultiFeature: ProjectFeature,
): ProjectFeature[];
export function multiFeatureToFeatures(maybeMultiFeature: Feature): Feature[];
export function multiFeatureToFeatures(maybeMultiFeature: Feature): Feature[] {
  if (!isMultiFeature(maybeMultiFeature)) return [maybeMultiFeature];
  return maybeMultiFeature.geometry.coordinates.map((coordinates) => {
    const newId = uuidv4();
    const f: Feature = {
      ...maybeMultiFeature,
      id: newId,
      properties: {
        ...maybeMultiFeature.properties,
        id: newId,
      },
      geometry: {
        ...maybeMultiFeature.geometry,
        type: multiToSingleGeometryType(maybeMultiFeature.geometry.type),
        coordinates: coordinates as any, // safety: multi->single means peeling off one layer of coordinates.
      },
    };
    return f;
  });
}

export function expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons(
  maybeMultiFeature: ProjectFeature,
): ProjectFeature[] {
  if (
    (isMultiPolygonFeature(maybeMultiFeature) &&
      maybeMultiFeature.geometry.coordinates.length === 1) ||
    (!isMultiPolygonFeature(maybeMultiFeature) &&
      isMultiFeature(maybeMultiFeature))
  ) {
    return multiFeatureToFeatures(maybeMultiFeature);
  }
  return [maybeMultiFeature];
}

export const reduceCoordinatePrecision = (
  coordinate: Position | Position[] | Position[][] | Position[][][],
  precision: number,
) => {
  return funcOnCoords(coordinate, (coords) =>
    coords.map(
      (c) => Math.round(c * Math.pow(10, precision)) / Math.pow(10, precision),
    ),
  );
};

export const removeLineStringFeatureWithOnlyOneCoordinate = <
  T extends Feature<
    | Point
    | MultiPoint
    | LineString
    | MultiLineString
    | Polygon
    | MultiPolygon
    | GeometryCollection
  >,
>(
  feature: T,
): boolean => {
  if (feature.geometry.type === "LineString") {
    const firstCoordinate = feature.geometry.coordinates[0];
    return !feature.geometry.coordinates
      .slice(1)
      .every((c) => c[0] === firstCoordinate[0] && c[1] === firstCoordinate[1]);
  } else if (feature.geometry.type === "MultiLineString") {
    return feature.geometry.coordinates.every((ls) => {
      const firstCoordinate = ls[0];
      return !ls
        .slice(1)
        .every(
          (c) => c[0] === firstCoordinate[0] && c[1] === firstCoordinate[1],
        );
    });
  }
  return true;
};

export const reduceCoordinatePrecisionFeature = <
  T extends Feature<
    | Point
    | MultiPoint
    | LineString
    | MultiLineString
    | Polygon
    | MultiPolygon
    | GeometryCollection
  >,
>(
  feature: T,
  precision: number,
): T => {
  if (feature.geometry.type === "GeometryCollection") return feature;

  const reduceCoordinatePrecisionInner = (
    coordinate: Position | Position[] | Position[][] | Position[][][],
    precision: number,
  ) => {
    return funcOnCoords(coordinate, (coords) =>
      coords.map(
        (c) =>
          Math.round(c * Math.pow(10, precision)) / Math.pow(10, precision),
      ),
    );
  };

  const reducedPrecisionCoords = reduceCoordinatePrecisionInner(
    feature.geometry.coordinates,
    precision,
  );
  return {
    ...feature,
    geometry: { ...feature.geometry, coordinates: reducedPrecisionCoords },
  };
};

export const simplifyToSize = <
  T extends Feature<
    | Point
    | MultiPoint
    | LineString
    | MultiLineString
    | Polygon
    | MultiPolygon
    | GeometryCollection
  >,
>(
  feature: T,
  maxSize: number,
  tolerance: number,
  sizeFunction = (obj: T) => new Blob([JSON.stringify(obj)]).size,
): [T, number] => {
  return tolerance >= 0.01 || sizeFunction(feature) < maxSize
    ? [feature, tolerance]
    : simplifyToSize(
        simplifyTakingCareOfPolygonsWithHoles<T>(feature, tolerance),
        maxSize,
        tolerance * 10,
        sizeFunction,
      );
};

export const validWGS84Coords = ([longitude, latitude]: number[]) =>
  longitude >= -180 && longitude <= 180 && latitude >= -90 && latitude <= 90;

export function splitMultiPolygon(
  region: ProjectFeature,
  getSize: (feature: Record<string, any>) => number,
): ProjectFeature[] {
  if (region.geometry.type !== "MultiPolygon") {
    return [region];
  }

  const multiPolygon = region as ProjectFeature<MultiPolygon>;

  const result: ProjectFeature<MultiPolygon>[] = [];
  let newId = uuidv4();
  let currentMultiPolygon = {
    ...multiPolygon,
    id: newId,
    properties: {
      ...multiPolygon.properties,
      id: newId,
    },
    geometry: {
      ...region.geometry,
      coordinates: [] as Position[][][],
    },
  };

  for (const polygon of region.geometry.coordinates) {
    if (
      currentMultiPolygon.geometry.coordinates.length === 0 ||
      getSize(currentMultiPolygon) + getSize(polygon) <= ABLY_SIZE_LIMIT
    ) {
      currentMultiPolygon.geometry.coordinates.push(polygon);
    } else {
      result.push(currentMultiPolygon);
      newId = uuidv4();
      currentMultiPolygon = {
        ...multiPolygon,
        id: newId,
        geometry: {
          ...region.geometry,
          coordinates: [polygon],
        },
        properties: {
          ...multiPolygon.properties,
          id: newId,
        },
      };
    }
  }

  if (currentMultiPolygon.geometry.coordinates.length > 0) {
    result.push(currentMultiPolygon);
  }

  return result;
}
