import { useJotaiCallback } from "utils/jotai";
import { TopRightMenuOptions } from "@constants/canvas";
import { analysisStoppedText } from "analysis/warnings";
import {
  TriggerCurrentFinance,
  currentParkTriggerId,
} from "components/Finance/Triggers";
import {
  configurationMenuActiveAtom,
  TopRightModeActiveAtom,
} from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { ParkHasAnyValidationError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { ErrorInfo, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { spacing4 } from "styles/space";
import {
  branchIdAtom,
  branchIdAtomDef,
  organisationIdAtom,
  parkIdAtom,
  parkIdAtomDef,
  projectIdAtom,
  projectIdAtomDef,
} from "../../state/pathParams";
import { useUpdateBranch } from "../../state/timeline";
import { editorAccessProjectSelector } from "../../state/user";
import { ParkFeature } from "../../types/feature";
import { ErrorBoundaryPrintOnly } from "../ErrorBoundaries/ErrorBoundaryLocal";
import { logError } from "../ErrorBoundaries/utils";
import Button from "../General/Button";
import { Label } from "../General/Form";
import { Column, Row } from "../General/Layout";
import Tabs from "../General/Tabs";
import {
  ARTICLE_FINANCIAL_ANALYSIS,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { SkeletonBlock, SkeletonText } from "../Loading/Skeleton";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { CAPEX } from "./Capex/Capex";
import { IRR } from "./IRR/IRR";
import { Lcoe } from "./Lcoe/Lcoe";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import { costConfigurationSelectedFamily } from "state/jotai/costConfiguration";
import { branchMetaFamily } from "state/jotai/branch";
import { ExportSystemTypeTag } from "components/GenerateWindTurbines/Tags";
import { getExportSystemTypeInParkAndBranchFamily } from "state/jotai/electrical";
import { RESET } from "jotai/utils";
import { atomFamily } from "utils/jotai";
import { Tag } from "components/General/Tag";
import { operationsConfigurationSelectedFamily } from "state/jotai/operationsConfiguration";
import SelectFinancialConfigDropDown from "./SelectFinancialDropDown";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { AnalysisConfigNotFoundErrorBoundaryFallbackRender } from "components/ProductionV2/ErrorUtils";
import { getFinancialStoppedReason } from "finance/warnings";
import SelectOperationsConfigDropDown from "./SelectOperationsDropDown";
import { containsOperationsCost } from "services/costService";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { LibraryManageRole } from "components/Organisation/Library/types";
import { DarkSubtitle } from "components/RightSide/InfoModal/style";
import { colors } from "styles/colors";
import DollarIcon from "@icons/24/Dollar.svg?react";
import { IconREMSize } from "styles/typography";
import VesselIcon from "@icons/24/Vessel.svg?react";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import WindIcon from "@icons/24/Wind.svg?react";

const _onError = (error: Error, info: ErrorInfo) => {
  logError("Financial analysis", error, info);
};

const _allConfigNeeded = atomFamily(
  ({ projectId, branchId }: { projectId: string; branchId: string }) =>
    atom((get) => {
      const selectedAnalysisConfig = get(
        analysisConfigurationSelectedFamily({ projectId, branchId }),
      );
      const selectedWindConfig = get(
        windConfigurationSelectedFamily({ projectId, branchId }),
      );
      const selectedCostConfig = get(
        costConfigurationSelectedFamily({ projectId, branchId }),
      );
      const selectedOperationsConfig = get(
        operationsConfigurationSelectedFamily({ projectId, branchId }),
      );
      return Promise.all([
        selectedAnalysisConfig,
        selectedWindConfig,
        selectedCostConfig,
        selectedOperationsConfig,
      ]);
    }),
);

const FinancialAnalysisInner = ({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}) => {
  const [
    selectedAnalysisConfig,
    selectedWindConfig,
    selectedCostConfig,
    selectedOperationsConfig,
  ] = useAtomValue(_allConfigNeeded({ projectId, branchId }));

  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);

  if (!selectedAnalysisConfig) {
    return (
      <div
        style={{
          padding: "1.6rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <SimpleAlert text={"No analysis config selected"} type={"error"} />

        <Button
          text={"Select in Production analysis"}
          onClick={() =>
            setTopRightModeActive(TopRightMenuOptions.productionStatistics)
          }
        />
      </div>
    );
  } else if (!selectedWindConfig) {
    return (
      <div
        style={{
          padding: "1.6rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <SimpleAlert text={"No wind config selected"} type={"error"} />
        <Button
          style={{
            marginLeft: "1.6rem",
          }}
          text={"Select in Production analysis"}
          onClick={() =>
            setTopRightModeActive(TopRightMenuOptions.productionStatistics)
          }
        />
      </div>
    );
  } else if (!selectedCostConfig) {
    return <SimpleAlert text={"No cost config selected"} type={"error"} />;
  } else if (
    containsOperationsCost(selectedCostConfig.capex) &&
    !selectedOperationsConfig
  ) {
    return (
      <SimpleAlert text={"No operations config selected"} type={"error"} />
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryPrintOnly}
      onError={_onError}
    >
      <FinancialAnalysisForSelectedConfigs />
    </ErrorBoundary>
  );
};

const FinancialAnalysisForSelectedConfigs = () => {
  const id = currentParkTriggerId;

  const financialStoppedReason = useAtomValue(getFinancialStoppedReason(id));
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(id),
  );

  if (financialStoppedReason)
    return (
      <div>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[financialStoppedReason]}
        />
      </div>
    );

  if (installationStoppedReason)
    return (
      <div>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        overflowY: "hidden",
        paddingTop: "1.6rem",
      }}
    >
      <Tabs
        tabs={[
          {
            name: "CAPEX",
            data: <CAPEX id={id} />,
          },
          {
            name: "LCoE",
            data: <Lcoe id={id} />,
          },
          {
            name: "IRR",
            data: <IRR id={id} />,
          },
        ]}
        menuStyle={{
          justifyContent: "flex-start",
        }}
        wrapperStyle={{
          minHeight: 0,
        }}
        contentWrapperStyle={{
          padding: 0,
        }}
      />
    </div>
  );
};

export function SelectCostConfiguration() {
  return (
    <Row
      style={{
        margin: 0,
        alignItems: "end",
      }}
    >
      <Label style={{ flex: 1, width: "100%", gap: "0.4rem" }}>
        <Row style={{ gap: "0.4rem" }}>
          <IconREMSize height={1.4} width={1.4} iconColor={colors.iconNegative}>
            <DollarIcon />
          </IconREMSize>
          <DarkSubtitle>Financial</DarkSubtitle>
        </Row>
        <Suspense fallback={<SkeletonBlock style={{ minHeight: "2.6rem" }} />}>
          <SelectFinancialConfigInner />
        </Suspense>
      </Label>
    </Row>
  );
}

const SelectFinancialConfigInner = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const refreshCostUsage = useJotaiCallback(
    (_get, set, costConfigId: string) => {
      set(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "COST_CONFIGURATION",
          resourceId: costConfigId,
        }),
        RESET,
      );
      organisationId &&
        set(
          libraryResourceUsageAtomFamily({
            organisationId,
            libraryManageRole: LibraryManageRole.FINANCIAL,
            resourceId: costConfigId,
          }),
          RESET,
        );
    },
    [organisationId, projectId],
  );

  const liveBranchMeta = useAtomValue(
    branchMetaFamily({
      projectId,
      branchId,
    }),
  );

  const selectedCostConfig = useAtomValue(
    costConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const updateBranch = useUpdateBranch();

  return (
    <SelectFinancialConfigDropDown
      selectedConfig={selectedCostConfig}
      projectId={projectId}
      style={{
        flex: 1,
      }}
      backgroundColor={colors.surfacePrimary}
      onSelectItem={(id) => {
        if (!liveBranchMeta) return;
        updateBranch({
          ...liveBranchMeta,
          costConfigurationId: id,
        }).then(() => {
          selectedCostConfig && refreshCostUsage(selectedCostConfig.id);
          refreshCostUsage(id);
        });
      }}
      disabled={!editorAccessProject}
    />
  );
};

export function SelectOperationsConfiguration() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const selectedCostConfig = useAtomValue(
    costConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  if (selectedCostConfig && !containsOperationsCost(selectedCostConfig.capex)) {
    return null;
  }

  return (
    <Row
      style={{
        margin: 0,
        alignItems: "end",
      }}
    >
      <Label style={{ flex: 1, width: "100%", gap: "0.4rem" }}>
        <Row style={{ gap: "0.4rem" }}>
          <IconREMSize height={1.4} width={1.4} iconColor={colors.iconNegative}>
            <VesselIcon />
          </IconREMSize>
          <DarkSubtitle>Operations</DarkSubtitle>
        </Row>
        <Suspense fallback={<SkeletonBlock style={{ minHeight: "2.6rem" }} />}>
          <SelectOperationsConfigInner />
        </Suspense>
      </Label>
    </Row>
  );
}

const SelectOperationsConfigInner = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const refreshOperationsUsage = useJotaiCallback(
    (_get, set, operationsConfigId: string) => {
      set(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "OPERATIONS_CONFIGURATION",
          resourceId: operationsConfigId,
        }),
        RESET,
      );
    },
    [projectId],
  );

  const liveBranchMeta = useAtomValue(
    branchMetaFamily({
      projectId,
      branchId,
    }),
  );

  const selectedOperationsConfig = useAtomValue(
    operationsConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const updateBranch = useUpdateBranch();

  return (
    <SelectOperationsConfigDropDown
      selectedConfig={selectedOperationsConfig}
      projectId={projectId}
      backgroundColor={colors.surfacePrimary}
      style={{
        flex: 1,
      }}
      onSelectItem={(id) => {
        if (!liveBranchMeta) return;
        updateBranch({
          ...liveBranchMeta,
          operationsConfigurationId: id,
        }).then(() => {
          selectedOperationsConfig &&
            refreshOperationsUsage(selectedOperationsConfig.id);
          refreshOperationsUsage(id);
        });
      }}
      disabled={!editorAccessProject}
    />
  );
};

const TagRow = () => {
  const projectId = useAtomValue(projectIdAtomDef);
  const branchId = useAtomValue(branchIdAtomDef);
  const parkId = useAtomValue(parkIdAtomDef);
  const setConfigurationMenuActive = useSetAtom(configurationMenuActiveAtom);

  const selectedAnalysisConfig = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const selectedWindConfig = useAtomValue(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const exportSystemType = useAtomValue(
    getExportSystemTypeInParkAndBranchFamily({
      parkId,
      branchId,
    }),
  );
  const selectedCostConfig = useAtomValue(
    costConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  return (
    <Row
      style={{
        justifyContent: "left",
        flexWrap: "wrap",
        paddingBottom: "0.8rem",
      }}
    >
      {selectedAnalysisConfig && (
        <Tag
          maxWidth="20rem"
          onClick={() => {
            setConfigurationMenuActive(true);
          }}
          icon={
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={colors.iconNegative}
            >
              <StatisticsIcon />
            </IconREMSize>
          }
          dark
          text={`${selectedAnalysisConfig?.name}`}
          tooltip="Selected analysis configuration"
        />
      )}
      {selectedWindConfig && (
        <Tag
          maxWidth="20rem"
          onClick={() => {
            setConfigurationMenuActive(true);
          }}
          icon={
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={colors.iconNegative}
            >
              <WindIcon />
            </IconREMSize>
          }
          dark
          text={`${selectedWindConfig?.name}`}
          tooltip="Selected wind configuration"
        />
      )}
      {selectedCostConfig && (
        <Tag
          maxWidth="20rem"
          onClick={() => {
            setConfigurationMenuActive(true);
          }}
          icon={
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={colors.iconNegative}
            >
              <DollarIcon />
            </IconREMSize>
          }
          dark
          text={`${selectedCostConfig?.name}`}
          tooltip="Selected financial configuration"
        />
      )}
      <Tag text="Park" />
      <ExportSystemTypeTag n={exportSystemType} />
    </Row>
  );
};

export const FinancialAnalysisV2 = ({
  park,
  onClose,
}: {
  park: ParkFeature;
  onClose(): void;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const parkId = useAtomValue(parkIdAtom);

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({
      parkId: park.id,
      branchId,
    }),
  );

  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  return (
    <MenuFrame
      style={{
        width: "36rem",
        maxHeight: `calc(100vh - 22rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
        gap: spacing4,
      }}
      icon={
        <>
          <HelpLink article={ARTICLE_FINANCIAL_ANALYSIS} />
        </>
      }
      title="Financial"
      onExit={onClose}
      validationError={
        <ParkHasAnyValidationError parkId={park.id} branchId={branchId ?? ""} />
      }
    >
      {Object.values(invalidTypes).some((e) => e) ? (
        <SimpleAlert
          text={"Some elements are using a type that is invalid"}
          type={"error"}
        />
      ) : (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryPrintOnly}
            onError={_onError}
          >
            <ErrorBoundary
              fallbackRender={AnalysisConfigNotFoundErrorBoundaryFallbackRender}
            >
              <Suspense
                fallback={
                  <Column>
                    <SkeletonText />
                  </Column>
                }
              >
                <TagRow />

                {projectId && branchId && parkId && (
                  <TriggerCurrentFinance
                    fallback={
                      <Column>
                        <SkeletonText />
                      </Column>
                    }
                    projectId={projectId}
                    branchId={branchId}
                    parkId={parkId}
                  >
                    <FinancialAnalysisInner
                      projectId={projectId}
                      branchId={branchId}
                    />
                  </TriggerCurrentFinance>
                )}
              </Suspense>
            </ErrorBoundary>
          </ErrorBoundary>
        </>
      )}
    </MenuFrame>
  );
};
