import { useEffect } from "react";
import { ValidationWarningTypes } from "./utils";
import { turbineSourceId } from "components/Mapbox/constants";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { getOverlappingTurbines } from "utils/getOverlappingTurbines";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";

export const turbineOverlapWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.TurbineOverlap> | undefined
      >
    >(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const turbineTypes = await get(simpleTurbineTypesAtom);
      const overlapping = getOverlappingTurbines(turbines, turbineTypes).filter(
        (t) => t.properties.parentIds?.includes(parkId),
      );

      if (overlapping.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.TurbineOverlap,
        featureIds: overlapping.map((t) => t.id),
        parkId: parkId,
      };
    }),
);

export const TurbineOverlapJotai = ({
  parkId,
  map,
  source = turbineSourceId,
}: {
  parkId: string;
  map: mapboxgl.Map;
  source?: string;
}) => {
  const warning = useAtomValue(
    turbineOverlapWarningFamily({
      parkId,
      branchId: undefined,
    }),
  );

  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });

    return () => {
      if (map.getSource(source) && warning?.featureIds)
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [warning, map, source]);

  return null;
};
