import { projectIdAtom } from "state/pathParams";
import React from "react";
import { Grid2 } from "../General/Form";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { useAtomValue } from "jotai";
import SelectTurbineDropDown from "./SelectTurbineDropDown";

export const TurbineSettingsActiveType = "TurbineSettingsActiveType";

export const TurbineControl = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
        gap: "1.2rem",
        justifyContent: "space-between",
      }}
    >
      <InputTitle>Turbine</InputTitle>
      <SelectTurbineDropDown
        projectId={projectId}
        buttonStyle={{
          width: "13.5rem",
        }}
      />
    </Grid2>
  );
};
