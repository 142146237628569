import { useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoIcon from "@icons/24/VindLogo.svg?react";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useLocalStorage } from "hooks/useBrowserStorage";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { userNodeAccessSelectorFamily } from "state/user";

const searchParamsToDelete = ["version"];

const VindLogo = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom);

  const toplevelNodeId = useAtomValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId,
    }),
  );

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: toplevelNodeId,
    }),
  );

  const goToFolder = nodeAccess >= 0 && toplevelNodeId;

  const [lastVisitedOrg] = useLocalStorage<string>(
    "vind:last-visited-organisation",
  );
  const { search } = useLocation();

  const searchString = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    searchParamsToDelete.forEach((key) => searchParams.delete(key));
    if (!goToFolder) {
      searchParams.set("page", "Projects");
    } else {
      searchParams.delete("page");
    }
    return searchParams.toString();
  }, [search, goToFolder]);

  const link =
    lastVisitedOrg && toplevelNodeId
      ? `/organisation/${lastVisitedOrg}/projects${goToFolder ? `/${toplevelNodeId}` : ""}`
      : "/organisation";

  return (
    <Link
      to={{
        pathname: link,
        search: searchString,
      }}
      style={{
        textDecoration: "none",
      }}
    >
      <IconREMSize height={2.8} width={2.8} fill={colors.blue200}>
        <LogoIcon />
      </IconREMSize>
    </Link>
  );
};

export default VindLogo;
