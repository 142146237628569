import { InboundMessage } from "ably";
import useProcurementCostCrud from "components/Organisation/Library/financial/ProcurementCosts/useProcurementCostCrud";
import { useMemo, useCallback } from "react";
import { useAblyGeneric } from "./useAblyGeneric";
import {
  ABLY_PROCUREMENT_COST_DELETE,
  ABLY_PROCUREMENT_COST_UPDATE,
} from "state/ably";
import { z } from "zod";
import { _ProcurementCost } from "services/procurementCostService";

const useAblyProcurementCost = (organisationId: string) => {
  const { updateLocal, removeLocal } = useProcurementCostCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_financial_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const costs = z.array(_ProcurementCost).parse(message.data.updatedItems);
      updateLocal(costs);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const procurementCostId = z.string().parse(message.data.id);
      removeLocal(procurementCostId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_PROCUREMENT_COST_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_PROCUREMENT_COST_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
};

export default useAblyProcurementCost;
