import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CableIcon from "@icons/24/Cabling-2.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import useCableTypeCrud from "../../../../hooks/useCableTypeCrud";
import { CableType } from "../../../../services/cableTypeService";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { SingleCable } from "../../../ConfigurationModal/CableSettings/CableSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { useToast } from "hooks/useToast";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { DEFAULT_OFFSHORE_CABLE, DEFAULT_ONSHORE_CABLE } from "types/cables";
import { IconREMSize } from "styles/typography";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import {
  editorAccessProjectSelector,
  orgCableManageAccessSelector,
} from "state/user";
import { useLocation, useNavigate } from "react-router-dom";
import { useDuplicateCableToProject } from "./useDuplicateToProject";
import { useDuplicateCableToLibrary } from "./useDuplicateToLibrary";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg?react";
import { Anchor } from "components/General/Anchor";
import CableModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/CableModal";
import { cableResourceWithAccessOnNodeState } from "state/cableType";
import useNodeCableResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeCableResourcesCrud";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  libraryCableTypesFamily,
  projectCableTypesFamily,
} from "state/jotai/cableType";
import { unwrap } from "jotai/utils";
import { isOnshoreAtom } from "state/onshore";
import SearchIcon from "@icons/24/Search.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { fromProjectToLibraryTabState } from "components/Organisation/Library/state";
import { Mixpanel } from "mixpanel";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

export const CABLE_MENU_ID = "cables";

export default function useCableSettings() {
  const onshore = useAtomValue(isOnshoreAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const orgCableManageAccess = useAtomValue(orgCableManageAccessSelector);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const { showConfirm } = useConfirm();

  const navigate = useNavigate();
  const location = useLocation();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const { update, create, deleteCable } = useCableTypeCrud();
  const projectCables = useAtomValue(projectCableTypesFamily(projectId));
  const { success } = useToast();

  const { duplicateToLibrary } = useDuplicateCableToLibrary();
  const { duplicateToProject } = useDuplicateCableToProject();

  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: CABLE_MENU_ID,
      projectId,
    }),
  );

  useEffect(() => {
    const cable = Array.from(projectCables.values())[0];
    if (!menuSelection && cable) setMenuSelection(cable.id);
  }, [projectCables, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProjectCableCollapsed, setIsProjectCableCollapsed] =
    useState<boolean>(false);
  const [isLibraryCableCollapsed, setIsLibraryCableCollapsed] =
    useState<boolean>(false);

  const availableLibraryCables = useAtomValue(
    unwrap(libraryCableTypesFamily(projectId)),
  );

  const addCable = useCallback(
    async (cable: Partial<CableType>) => {
      setSaveInProgress(true);
      setIsLoading(true);
      const newCable = {
        ...cable,
        name: `${cable.name} copy`,
      };
      return create(newCable)
        .then((res) => setMenuSelection(res.id))
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDelete = useJotaiCallback(
    async (get, set, cableId: string) => {
      if (!projectId) return;
      setIsLoading(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "CABLE",
          resourceId: cableId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(projectId, "CABLE", cableId);
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete cable type",
          message: `Cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        try {
          await deleteCable(cableId);
          setMenuSelection(undefined);
        } catch {}
      }
      setIsLoading(false);
    },
    [deleteCable, projectId, setMenuSelection, showConfirm],
  );

  const _onUpdate = useJotaiCallback(
    async (get, _, newCable: CableType, skipUsageCheck?: boolean) => {
      setIsLoading(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "CABLE",
          resourceId: newCable.id,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "CABLE",
          newCable.id,
        );
      }

      if (
        usage.length === 0 ||
        skipUsageCheck ||
        (await showConfirm({
          title: "Update cable type",
          message: `Cable type is currently being used in ${usage.length} cables, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        await update(newCable).catch(() => {});
        success("Saved");
      }
      setIsLoading(false);
    },
    [projectId, showConfirm, success, update],
  );

  const cableSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const projectCableList = Array.from(projectCables.values())
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((cable) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        dotMenuOptions.push({
          title: "Delete",
          onSelect: _onDelete,
          icon: <DeleteIcon />,
        });
        dotMenuOptions.push({
          title: "Duplicate",
          onSelect: () => duplicateToProject(cable),
          icon: <DuplicateIcon />,
        });

        if (orgCableManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => {
              setSaveInProgressLibrary(true);
              duplicateToLibrary(projectId, cable).then(() =>
                setSaveInProgressLibrary(false),
              );
            },
            icon: <DuplicateIcon />,
          });
        }

        return {
          id: cable.id,
          name: cable.name,
          label: `${cable.name} (${cable.voltage}kV)`,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <SingleCable
                disabled={isReadOnly}
                key={cable.id}
                cable={cable}
                onSave={_onUpdate}
                isLoading={isLoading}
                isLibraryCable={false}
              />
            </div>
          ),
          onDuplicate: undefined,
          onChangeName: (newName: string) => {
            return _onUpdate({
              ...cable,
              name: newName,
            });
          },
          dotMenuOptions,
        };
      });

    const libraryCableList = Array.from(availableLibraryCables?.values() ?? [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((cable) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgCableManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library component",
            onSelect: () => {
              let searchParams = new URLSearchParams(location.search);
              Mixpanel.track_old("Click edit in library", {
                resource: "cable",
              });
              let newSearchString = searchParams.toString();
              setFromProjectToLibraryTab(true);
              setContent({
                type: "resource",
                id: cable.id,
              });

              navigate(
                `/organisation/${organisationId}/library/cable/${cable.id}?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
        }
        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => {
              setSaveInProgress(true);
              duplicateToProject(cable).then(() => setSaveInProgress(false));
            },
            icon: <DuplicateIcon />,
          });
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: cable.id,
          name: cable.name,
          label: `${cable.name} (${cable.voltage}kV)`,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <SingleCable
                disabled={true}
                key={cable.id}
                cable={cable}
                isLibraryCable={true}
                isLoading={isLoading}
              />
            </div>
          ),
          dotMenuOptions,
        };
      });

    const libraryCablesMenu: SettingsSubMenuProp = {
      title: "Library components",
      items: libraryCableList,
      isCollapsed: isLibraryCableCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryCableCollapsed}
            onClick={() => setIsLibraryCableCollapsed(!isLibraryCableCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectCablesMenu: SettingsSubMenuProp = {
      items: projectCableList,
      title: "Project specific components",
      isCollapsed: isProjectCableCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectCableCollapsed}
            onClick={() => setIsProjectCableCollapsed(!isProjectCableCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    if (allLibraryAccess) return [libraryCablesMenu, projectCablesMenu];
    return [projectCablesMenu];
  }, [
    projectCables,
    availableLibraryCables,
    isLibraryCableCollapsed,
    isProjectCableCollapsed,
    setFromProjectToLibraryTab,
    setContent,
    projectId,
    isLoading,
    allLibraryAccess,
    _onDelete,
    orgCableManageAccess,
    isReadOnly,
    _onUpdate,
    duplicateToLibrary,
    editorAccessProject,
    location.search,
    navigate,
    organisationId,
    duplicateToProject,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgressLibrary,
      element: <AddCableResourceToNode nodeId={projectId} />,
    };
  }, [saveInProgressLibrary, projectId]);

  const createNewCable = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <Button
          disabled={!editorAccessProject || isLoading}
          onClick={() => {
            const cable = {
              ...(onshore ? DEFAULT_ONSHORE_CABLE : DEFAULT_OFFSHORE_CABLE),
            };
            return addCable(cable);
          }}
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [onshore, editorAccessProject, isLoading, addCable, saveInProgress]);

  const cableSettings = useMemo(() => {
    return {
      id: CABLE_MENU_ID,
      label: "Inter array cables",
      icon: <CableIcon />,
      submenus: cableSubMenus,
      createNew: createNewCable,
      addFromLibrary: addFromLibrary,
    };
  }, [cableSubMenus, createNewCable, addFromLibrary]);

  return cableSettings;
}

function AddCableResourceToNode({ nodeId }: { nodeId: string }) {
  const cableResources = useAtomValue(
    cableResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateCable } = useNodeCableResourcesCrud();
  const [showAddCable, setShowAddCable] = useState(false);
  const orgCableManageAccess = useAtomValue(orgCableManageAccessSelector);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddCable(true)}
        icon={<SearchIcon />}
        disabled={!orgCableManageAccess}
      />
      {showAddCable && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <CableModal
            onSave={async (cables) => {
              setSaveInProgress(true);
              await Promise.all(
                cables.map((c) => addOrUpdateCable(nodeId, c.id)),
              );
              setSaveInProgress(false);
              setShowAddCable(false);
            }}
            existingCables={cableResources.map((c) => c.cable.id)}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
