import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { atom, useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import styled from "styled-components";
import { spacing4 } from "styles/space";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { Column } from "components/ConfigurationModal/Cost/Capex";
import { turbinesFamily } from "state/jotai/turbine";
import { ReplaceTurbineSelector } from "./ReplaceTurbineSelector";

import { editorAccessProjectSelector } from "state/user";
import { inReadOnlyModeSelector } from "state/project";
import Trashcan from "@icons/24/Bin.svg?react";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import { atomWithStorage } from "jotai/utils";
import { dateToYearDateTime } from "utils/utils";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { deletedTurbineActionChangelogMetadataAtom } from "../state";
import { atomFromFn } from "utils/jotai";
import { listBranches } from "services/projectDataAPIService";
import ProjectResourceUsage from "components/ConfigurationModal/SettingsUsage/ProjectResourceUsage";

export const hideReplaceModalAtom = atomWithStorage<string[]>(
  "hide-replace-modal-ids",
  [],
);

const LastEditerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-top: -1.6rem;
  align-items: center;
  color: ${colors.textPrimary};
  ${typography.caption}
`;

const Container = styled.div`
  position: fixed;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spacing4}
  );
  right: ${spacing4};
  z-index: 6;
`;

const Text = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  margin: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 3.2rem;
  height: 3.2rem;
  cursor: auto;
  border-radius: 50%;
  background-color: ${colors.surfaceError};
  svg {
    path {
      stroke: ${colors.iconError};
    }
  }
`;

export const allBranchTurbinesAtom = atom(async (get) => {
  const projectId = get(projectIdAtom);
  const branchIds = await get(listAllBranchIds(projectId ?? ""));

  const turbinesByBranch = await Promise.all(
    branchIds.map(async (branchId) => ({
      branchId,
      turbines: await get(turbinesFamily({ branchId })),
    })),
  );

  return turbinesByBranch;
});

const useAllInvalidTurbinesInBranch = (
  deletedTurbineIds: string[],
  branchId: string,
) => {
  const turbinesInBranch = useAtomValue(turbinesFamily({ branchId }));
  return turbinesInBranch.filter((turbine) =>
    deletedTurbineIds.includes(turbine.properties.turbineTypeId),
  );
};

const listAllBranchIds = (projectId: string) =>
  atomFromFn<Promise<string[]>>(async () => {
    const { branches } = await listBranches(projectId);
    return branches.map((branch) => branch.id);
  });

export default function ReplaceModal() {
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = useMemo(
    () => isCustomerEditor && !isReadOnly,
    [isCustomerEditor, isReadOnly],
  );
  const branchId = useAtomValue(branchIdAtom);
  const [hiddenModalIds, setHiddenModalIds] = useAtom(hideReplaceModalAtom);
  const deletedTurbineActionChangelogMetadata = useAtomValue(
    deletedTurbineActionChangelogMetadataAtom,
  );

  const allInvalidTurbineFeaturesInBranch = useAllInvalidTurbinesInBranch(
    deletedTurbineActionChangelogMetadata.map((meta) => meta.resourceId),
    branchId ?? "",
  );

  const visibleAndUsedDeletedTurbineTypesInBranch = useMemo(
    () =>
      deletedTurbineActionChangelogMetadata.filter(
        (meta) =>
          // Filter out hidden IDs
          !hiddenModalIds.includes(meta.resourceId) &&
          // Filter for used turbine types
          allInvalidTurbineFeaturesInBranch.some(
            (turbine) => turbine.properties.turbineTypeId === meta.resourceId,
          ),
      ),
    [
      deletedTurbineActionChangelogMetadata,
      allInvalidTurbineFeaturesInBranch,
      hiddenModalIds,
    ],
  );

  // Handle one turbine type at a time (for now)
  const deletedTurbineTypeId =
    visibleAndUsedDeletedTurbineTypesInBranch[0]?.resourceId;

  const turbineFeaturesInBranch = useAtomValue(
    turbinesFamily({ branchId: branchId ?? "" }),
  );
  const branchSelectedInvalidTurbines = turbineFeaturesInBranch.filter(
    (turbine) => deletedTurbineTypeId === turbine.properties.turbineTypeId,
  );

  if (
    !deletedTurbineTypeId ||
    !canEdit ||
    allInvalidTurbineFeaturesInBranch.length === 0
  )
    return null;

  return (
    <Container>
      <MenuFrame
        id="replace-turbine"
        title="Library turbine deleted"
        onExit={() => {
          setHiddenModalIds([
            ...hiddenModalIds,
            deletedTurbineActionChangelogMetadata[0].resourceId,
          ]);
        }}
        style={{ width: "35rem" }}
        iconLeftSide={
          <IconContainer>
            <IconREMSize height={2} width={2}>
              <Trashcan />
            </IconREMSize>
          </IconContainer>
        }
      >
        <Column
          style={{ paddingLeft: "5.6rem", gap: "1.6rem", marginTop: "0" }}
        >
          <LastEditerWrapper>
            {dateToYearDateTime(
              new Date(
                visibleAndUsedDeletedTurbineTypesInBranch[0].lastModified,
              ),
            )}
            <UserInfoOrganisation
              userId={
                visibleAndUsedDeletedTurbineTypesInBranch[0].lastModifiedBy
              }
              size={2}
            />
          </LastEditerWrapper>

          <ProjectResourceUsage
            resourceType="TURBINE"
            resourceId={visibleAndUsedDeletedTurbineTypesInBranch[0].resourceId}
          />
          <Text>
            Choose a replacement turbine to make the analysis on parks with the
            deleted turbine type run again.
          </Text>
          <Column style={{ gap: "0.8rem", marginTop: "0rem" }}>
            <Text>Replacement turbine</Text>
            <ReplaceTurbineSelector
              deletedTurbineTypeId={deletedTurbineTypeId}
              branchInvalidTurbines={branchSelectedInvalidTurbines}
              editable={true} // Always editable when modals shows
            />
          </Column>
        </Column>
      </MenuFrame>
    </Container>
  );
}
