import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtomValue, useSetAtom } from "jotai";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import { LibraryTurbinePreviewItem, TurbinePreviewItem } from "./state";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import {
  ColoredGridPreviewItem,
  ColoredGridProjectItems,
  Divider,
  GridKey,
  GridValue,
  GridValueLeft,
  HeaderRow,
  Modal,
  TableHeader,
  TableSubHeader,
  Title,
  Wrapper,
} from "./style";
import { ComponentsPreviewType } from "./state";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { ComponentCost } from "services/costService";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "components/ConfigurationModal/Cost/state";
import { InputDimensioned } from "components/General/Input";
import { listProcurementCostForLibraryTurbinesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { calculateTurbineDefaultCosts } from "utils/turbineCost";
import { CostUnit } from "types/financial";
import { WithTooltip } from "components/General/Tooltip";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { roundToDecimal } from "utils/utils";
import {
  turbineIdentifierAtom,
  turbineProcurementCostsAtom,
} from "components/Organisation/Library/financial/ProcurementCosts/state";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { GoToProcurementTableArrowIcon } from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";

const LibraryTurbineTable = ({
  libraryTurbineItems,
}: {
  libraryTurbineItems: LibraryTurbinePreviewItem[];
}) => {
  return (
    <>
      <SubtitleWithLine textIcon={<LibraryIcon />} text={"Library turbines"} />
      <ColoredGridPreviewItem>
        <React.Fragment key="header">
          <TableHeader>Turbines</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader>Supply</TableHeader>
          <TableHeader>Shipping</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
        </React.Fragment>
        <React.Fragment key="sub-header">
          <TableSubHeader>NAME</TableSubHeader>
          <TableSubHeader>POWER RATING</TableSubHeader>
          <TableSubHeader>COST</TableSubHeader>
          <TableSubHeader>EUROPE</TableSubHeader>
          <TableSubHeader>ASIA</TableSubHeader>
          <TableSubHeader>USA</TableSubHeader>
          <TableSubHeader>OTHER</TableSubHeader>
        </React.Fragment>

        {libraryTurbineItems.map((t: LibraryTurbinePreviewItem) => {
          const getCostInRegion = (region: string) =>
            t.shippingCost.find((t) => t.region === region)?.cost || 0;
          const getUnitInRegion = (region: string) =>
            t.shippingCost.find((t) => t.region === region)?.unit ||
            CostUnit.millionEuroPerUnit;

          return (
            <React.Fragment key={t.id}>
              <WithTooltip text={t.name}>
                <GridKey>{t.name}</GridKey>
              </WithTooltip>
              <GridValueLeft>{t.powerRating} MW</GridValueLeft>
              <GridValueLeft>
                <strong>{t.supplyCost}</strong> {t.supplyCostUnit}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Europe")}</strong>{" "}
                {getUnitInRegion("Europe")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Asia")}</strong>{" "}
                {getUnitInRegion("Asia")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("USA")}</strong>{" "}
                {getUnitInRegion("USA")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Other")}</strong>{" "}
                {getUnitInRegion("Other")}
                <GoToProcurementTableArrowIcon activeProcurementType="turbine" />
              </GridValueLeft>
            </React.Fragment>
          );
        })}
      </ColoredGridPreviewItem>
    </>
  );
};

const Library_TurbineComponentsPreviewInner = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const libraryTurbineCosts = useAtomValue(
    turbineProcurementCostsAtom(organisationId),
  );

  const libraryTurbineTypes = useAtomValue(
    turbineIdentifierAtom(organisationId),
  );

  const libraryTurbineItems: LibraryTurbinePreviewItem[] = useMemo(() => {
    return Array.from(libraryTurbineTypes.values()).map((t) => {
      const libraryCost = libraryTurbineCosts.get(t.id);
      return {
        id: t.id,
        name: t.name,
        supplyCost:
          libraryCost && libraryCost.supplyCost
            ? libraryCost.supplyCost.cost
            : calculateTurbineDefaultCosts({
                diameter: t.rotorDiameter,
                ratedPower: t.ratedPower ?? 0,
                hubHeight: t.hubHeight,
              }),
        supplyCostUnit:
          libraryCost && libraryCost.supplyCost
            ? libraryCost.supplyCost.unit
            : CostUnit.millionEuroPerUnit,
        shippingCost: libraryCost ? libraryCost.shippingCost : [],
        costSource: libraryCost && libraryCost.supplyCost ? "table" : "default",
        powerRating: roundToDecimal((t.ratedPower ?? 0) / 1e3, 1),
      };
    });
  }, [libraryTurbineTypes, libraryTurbineCosts]);

  return <LibraryTurbineTable libraryTurbineItems={libraryTurbineItems} />;
};

const Project_TurbineComponentsPreviewInner = ({
  temporaryProjectTurbineCosts,
  setTemporaryProjectTurbineCosts,
}: {
  temporaryProjectTurbineCosts: Record<string, ComponentCost>;
  setTemporaryProjectTurbineCosts: React.Dispatch<
    React.SetStateAction<Record<string, ComponentCost>>
  >;
}) => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const readOnly =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;
  const projectId = useAtomValue(projectIdAtom)!;

  const isLibraryConfig =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  const libraryTurbineCosts = useAtomValue(
    listProcurementCostForLibraryTurbinesOnNodeAtomFamily(projectId),
  );

  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);

  const allLibraryTurbines = useMemo(() => {
    return Array.from(allTurbineTypes.values()).filter((t) =>
      ["library"].includes(t.level),
    );
  }, [allTurbineTypes]);

  const allProjectTurbines = useMemo(() => {
    return Array.from(allTurbineTypes.values()).filter((t) =>
      ["project", "standard"].includes(t.level),
    );
  }, [allTurbineTypes]);

  const projectTurbineItems: TurbinePreviewItem[] = useMemo(() => {
    return allProjectTurbines.map((t) => ({
      id: t.turbine.id,
      name: t.turbine.name,
      cost:
        t.turbine.id in temporaryProjectTurbineCosts
          ? temporaryProjectTurbineCosts[t.turbine.id]!.cost
          : calculateTurbineDefaultCosts({
              diameter: t.turbine.diameter,
              ratedPower: t.turbine.ratedPower,
              hubHeight: t.turbine.hubHeight,
            }),
      costUnit: t.turbine.costUnit ?? CostUnit.millionEuroPerUnit,
      level: t.level,
      powerRating: t.turbine.ratedPower / 1000,
    }));
  }, [allProjectTurbines, temporaryProjectTurbineCosts]);

  const libraryTurbineItems: LibraryTurbinePreviewItem[] = useMemo(() => {
    return allLibraryTurbines.map((t) => {
      const libraryCost = libraryTurbineCosts.get(t.turbine.id);
      return {
        id: t.turbine.id,
        name: t.turbine.name,
        supplyCost:
          libraryCost && libraryCost.supplyCost
            ? libraryCost.supplyCost.cost
            : calculateTurbineDefaultCosts({
                diameter: t.turbine.diameter,
                ratedPower: t.turbine.ratedPower,
                hubHeight: t.turbine.hubHeight,
              }),
        supplyCostUnit:
          libraryCost && libraryCost.supplyCost
            ? libraryCost.supplyCost.unit
            : CostUnit.millionEuroPerUnit,
        shippingCost: libraryCost ? libraryCost.shippingCost : [],
        costSource: libraryCost && libraryCost.supplyCost ? "table" : "default",
        powerRating: roundToDecimal(t.turbine.ratedPower / 1e3, 1),
        level: t.level,
      };
    });
  }, [allLibraryTurbines, libraryTurbineCosts]);

  return (
    <>
      <LibraryTurbineTable libraryTurbineItems={libraryTurbineItems} />
      {!isLibraryConfig && (
        <>
          <SubtitleWithLine
            textIcon={<ProjectIcon />}
            text={"Project turbines"}
          />
          <ColoredGridProjectItems nrColumns={3}>
            <React.Fragment key="header">
              <TableSubHeader>NAME</TableSubHeader>
              <TableSubHeader>POWER RATING</TableSubHeader>
              <TableSubHeader>COST</TableSubHeader>
            </React.Fragment>
            {projectTurbineItems.map((t, index) => (
              <React.Fragment key={t.id}>
                <WithTooltip text={t.name}>
                  <GridKey showBg={index % 2 === 0}>{t.name}</GridKey>
                </WithTooltip>
                <GridKey showBg={index % 2 === 0}>
                  {Math.round(t.powerRating * 10) / 10} MW
                </GridKey>
                <GridValue showBg={index % 2 === 0}>
                  <InputDimensioned
                    disabled={readOnly}
                    compact={true}
                    unit={t.costUnit}
                    type="number"
                    value={t.cost}
                    initialValue={t.cost}
                    onChange={(cost, _, unit) =>
                      setTemporaryProjectTurbineCosts((curr) => ({
                        ...curr,
                        [t.id]: {
                          id: t.id,
                          cost,
                          unit: unit as CostUnit,
                        },
                      }))
                    }
                  />
                  <FeatureSettingsShortcut
                    menuId={TURBINE_MENU_ID}
                    itemId={t.id}
                  />
                </GridValue>
              </React.Fragment>
            ))}
          </ColoredGridProjectItems>
        </>
      )}
    </>
  );
};

const TurbineComponentsPreviewInner = ({ onClose }: { onClose(): void }) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);

  const { replaceProjectComponentCosts } = useLocalCostConfigurationCrud();
  const localConfig = useAtomValue(localCostConfigurationAtom);
  const [temporaryProjectTurbineCosts, setTemporaryProjectTurbineCosts] =
    useState<typeof localConfig.capex.projectComponentCosts.turbines>(
      localConfig.capex.projectComponentCosts.turbines,
    );
  const projectId = useAtomValue(projectIdAtom);
  const inLibraryContext = !projectId;
  const isLibraryConfig =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  return (
    <>
      <ScrollBody>
        {inLibraryContext ? (
          <Library_TurbineComponentsPreviewInner />
        ) : (
          <Project_TurbineComponentsPreviewInner
            temporaryProjectTurbineCosts={temporaryProjectTurbineCosts}
            setTemporaryProjectTurbineCosts={(...args) => {
              setHasChanged(true);
              setTemporaryProjectTurbineCosts(...args);
            }}
          />
        )}
      </ScrollBody>

      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <Button buttonType="secondary" text="Close" onClick={onClose} />
        {!isLibraryConfig && (
          <Button
            buttonType="primary"
            text="Apply"
            disabled={!hasChanged}
            onClick={() => {
              replaceProjectComponentCosts(
                temporaryProjectTurbineCosts,
                "turbines",
              );
              onClose();
            }}
          />
        )}
      </div>
    </>
  );
};

const TurbineComponentsPreviewV2 = () => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <Wrapper>
      <Modal ref={containerRef} style={{ width: "80rem", overflow: "hidden" }}>
        <HeaderRow>
          <div>
            <Title>Turbine costs</Title>
          </div>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>

        <React.Suspense
          fallback={<SkeletonBlock style={{ height: "20rem" }} />}
        >
          <TurbineComponentsPreviewInner onClose={onClose} />
        </React.Suspense>
      </Modal>
    </Wrapper>
  );
};

export default TurbineComponentsPreviewV2;
