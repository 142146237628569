import { Column } from "components/General/Layout";
import { ProjectPageTitleBar } from "components/Organisation/ProjectPageTitleBar";

export default function OrganisationIsDeactivated() {
  return (
    <Column
      style={{
        height: "100vh",
        gap: 0,
      }}
    >
      <ProjectPageTitleBar />
      <Column style={{ padding: "1.6rem" }}>
        <h1>Organisation is deactivated</h1>
        <p>
          Your organisations account has been deactivated. Please contact your
          administrator for more information.
        </p>
      </Column>
    </Column>
  );
}
