import * as turf from "@turf/turf";
import { Feature, Polygon } from "@turf/turf";
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceDecent, spaceLarge, spaceMedium } from "../../styles/space";
import OpenRight from "@icons/24/OpenWindowRight.svg?react";
import { promiseWorker, typedWorker } from "../../utils/utils";
import Button from "../General/Button";
import { InputDimensioned, InputNumber } from "../General/Input";
import { Column, Row } from "../General/Layout";
import { RangeSlider } from "../General/Slider";
import Tooltip from "../General/Tooltip";
import { TurbineControl } from "../GenerateWindTurbines/TurbineSettings";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { OptimizeArgs, parkFeature } from "../SiteLocator/utils";
import HeatmapWrapper from "../SiteLocator/SiteLocatorHeatmap";
import CollectionsResultsList from "../SiteLocator/SiteLocatorResults";
import {
  candidateGridAtom,
  computingSitesState,
  createdCandidateIDsState,
  gridStatisticsState,
  lastParkIdState,
  lcoeSliderState,
  openLCOESubmenuAtom,
  openSiteLocatorListProblem,
  SelectedCandidate,
  selectedCandidateState,
  SelectedCell,
  selectedPolygonIdsAtom,
  showAEPLayerAtom,
  showDepthLayerAtom,
  showDistLayerAtom,
  showLCOELayerAtom,
  showMeanSpeedAtom,
  siteLocatorProblemsAtom,
  siteLocatorProblemsMetaAtom,
  siteLocatorSettingState,
  siteLocatorLoadingTimeAtom,
  comparisonCellState,
} from "../SiteLocator/state";
import {
  LCoEParamRow,
  SelectedUnitsWrapper,
  Wrapper,
} from "../SiteLocator/style";
import { v4 as uuid4 } from "uuid";
import { Mixpanel } from "../../mixpanel";
import { IconBtn } from "../General/Icons";
import { replaceOrUndefined } from "../ControlPanels/utils";
import { Anchor } from "../General/Anchor";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Grid2 } from "../LowerRight/styles";
import HelpTooltip, {
  ARTICLE_SITE_LOCATOR,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { parkIdAtom, projectIdAtom } from "state/pathParams";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import {
  InputTitle,
  InputTitleWrapper,
  SubtitleWithLine,
  Divider,
} from "components/General/GeneralSideModals.style";
import Toggle, { ToggleSize } from "components/General/Toggle";

import { SiteLocatorMenuType } from "@constants/projectMapView";
import { colors } from "styles/colors";
import { useToast } from "hooks/useToast";
import { Label } from "components/General/Form";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { currentParkAtom } from "state/jotai/park";
import {
  currentTurbineIdAtom,
  simpleTurbineTypesAtom,
} from "state/jotai/turbineType";

const wrapperStyle: CSSProperties = {
  maxHeight: "calc(100vh - 20rem)",
  boxSizing: "border-box",
};

const SiteLocator = () => {
  const projectId = useAtomValue(projectIdAtom);
  const parkId = useAtomValue(parkIdAtom);
  const park = useAtomValue(currentParkAtom);

  const [lastParkId, setLastParkId] = useAtom(lastParkIdState);

  const setSiteLocatorProblems = useSetAtom(siteLocatorProblemsAtom);
  const [siteLocatorProblemsMeta, setSiteLocatorProblemsMeta] = useAtom(
    siteLocatorProblemsMetaAtom,
  );
  const setSelectedCandidate = useSetAtom(selectedCandidateState);

  const lcoeSlider = useAtomValue(lcoeSliderState);

  const showDepthLayer = useAtomValue(showDepthLayerAtom);
  const showDistLayer = useAtomValue(showDistLayerAtom);
  const [showMeanSpeedLayer] = useAtom(showMeanSpeedAtom);
  const showAEPLayer = useAtomValue(showAEPLayerAtom);
  const showLCOELayer = useAtomValue(showLCOELayerAtom);
  const setComparisonCell = useSetAtom(comparisonCellState);
  const setCreatedCandidateIDs = useSetAtom(createdCandidateIDsState);

  const [loadingTime, setLoadingTime] = useAtom(siteLocatorLoadingTimeAtom);

  const setOpen = useSetAtom(openSiteLocatorListProblem);
  const computing = useAtomValue(computingSitesState);
  const setLCOEMenuOpen = useSetAtom(openLCOESubmenuAtom);
  const [settings, setSettings] = useAtom(siteLocatorSettingState);
  const setSelectedPolygonIds = useSetAtom(selectedPolygonIdsAtom);
  const allTurbines = useAtomValue(simpleTurbineTypesAtom);
  const turbineId = useAtomValue(currentTurbineIdAtom({ projectId }));
  const turbineType = allTurbines.get(turbineId)!; // Safety: none

  useEffect(
    () =>
      setSettings((prevSettings) => ({
        ...prevSettings,
        turbineType: turbineType,
      })),
    [setSettings, turbineType],
  );

  const candidateGrid = useAtomValue(candidateGridAtom);

  const { error } = useToast();

  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();

  const clearResults = useCallback(() => {
    setComparisonCell(undefined);
    setSelectedPolygonIds([]);
    setSiteLocatorProblems([]);
    setSiteLocatorProblemsMeta([]);
    setCreatedCandidateIDs({});
    setSelectedCandidate(undefined);
  }, [
    setComparisonCell,
    setSelectedPolygonIds,
    setSiteLocatorProblems,
    setSiteLocatorProblemsMeta,
    setCreatedCandidateIDs,
    setSelectedCandidate,
  ]);

  useEffect(() => {
    if (parkId !== undefined && parkId !== lastParkId) {
      clearResults();
      setLastParkId(parkId);
    }
  }, [parkId, clearResults, lastParkId, setLastParkId]);

  useEffect(() => {
    const numChecked = [
      showDepthLayer,
      showDistLayer,
      showMeanSpeedLayer,
      showAEPLayer,
      showLCOELayer,
    ].filter(Boolean).length;
    const alpha = 1.0 / numChecked;

    setSettings((prevSettings) => ({
      ...prevSettings,
      layers: {
        depth: { alpha: showDepthLayer ? alpha : 0 },
        shoreDistance: { alpha: showDistLayer ? alpha : 0 },
        meanSpeed: { alpha: showMeanSpeedLayer ? alpha : 0 },
        aep: { alpha: showAEPLayer ? alpha : 0 },
        lcoe: { alpha: showLCOELayer ? alpha : 0 },
      },
    }));
  }, [
    showDepthLayer,
    showDistLayer,
    showMeanSpeedLayer,
    showAEPLayer,
    showLCOELayer,
    setSettings,
  ]);

  if (leftMenuActiveMode !== SiteLocatorMenuType) return null;

  const clickGenerate = async () => {
    if (!park) return;
    if (candidateGrid === undefined || !candidateGrid.features) return;

    if (settings.maxParkCapacity < settings.turbineType.ratedPower / 1000) {
      alert("Target park capacity must be larger than turbine power.");
      return;
    }

    const isRunning = loadingTime !== undefined;
    if (isRunning) {
      setLoadingTime(undefined);
    } else {
      setLoadingTime(Date.now());

      const newWorker = typedWorker<
        OptimizeArgs,
        [Feature<turf.Polygon | turf.MultiPolygon>[], number] | undefined
      >(
        new Worker(
          new URL(
            "../SiteLocator/workers/locateSitesWorker.ts",
            import.meta.url,
          ),
          {
            type: "module",
          },
        ),
      );

      const previouslySelectedCollections: SelectedCell[][] = [];
      const powerRating = settings.turbineType.ratedPower / 1000;
      const problemCandidates: SelectedCandidate[] = [];

      setSiteLocatorProblemsMeta((prev) => [
        ...prev,
        {
          turbinePower: settings.turbineType.ratedPower / 1000,
          maxParkCapacity: settings.maxParkCapacity,
          density: settings.density,
          turbineType: settings.turbineType,
          lcoeSlider: { min: lcoeSlider.min, max: lcoeSlider.max },
          natura2000filter: settings.natura2000filter,
        },
      ]);

      let numSolutions = 0;
      for (let i = 0; i < settings.generation.maxIter; i++) {
        if (candidateGrid === undefined) break;
        const candidateGridFilt = candidateGrid.features
          .filter(
            (candCell) =>
              !previouslySelectedCollections
                .flat()
                .map((cell) => cell.polygonId)
                .some((id) => id === candCell.id),
          )
          .filter(
            (candCell) =>
              candCell.properties &&
              candCell.properties.avgLCOE !== Infinity &&
              candCell.properties.avgLCOE >= lcoeSlider.min &&
              candCell.properties.avgLCOE <= lcoeSlider.max &&
              !(
                settings.natura2000filter &&
                candCell.properties.naturaScore === 1
              ),
          );

        if (
          candidateGridFilt.length <
          Math.round(settings.maxParkCapacity / powerRating)
        ) {
          break;
        }

        const result:
          | [Feature<turf.Polygon | turf.MultiPolygon>[], number]
          | undefined = await promiseWorker(
          newWorker,
          [
            candidateGridFilt as turf.Feature<turf.Polygon>[],
            powerRating,
            settings.maxParkCapacity,
            settings.density,
          ],
          "SiteLocator",
        );

        if (result === undefined) {
          setSiteLocatorProblems((prev) => [...prev, { candidates: [] }]);
          // TODO: handle failure
          return;
        }
        const [cells, capacity] = result;

        const newCollection = cells.map((feat: Feature): SelectedCell => {
          return {
            polygonId: feat.id as number,
            feature: parkFeature(feat.geometry as Polygon),
            depth: feat.properties?.avgDepth,
            meanSpeed: feat.properties?.avgMeanSpeed,
            shoreDist: feat.properties?.avgShoreDistance,
            aep: feat.properties?.avgCapacity,
            lcoe: feat.properties?.avgLCOE,
          };
        });

        previouslySelectedCollections.push(newCollection);
        // TODO: This may discard the best options in favor of more circular shapes
        //       need to instead tweak algorithm to serve better shapes at the get-go
        // const complexityFactor = calculateComplexityScore(cells);
        // if (
        //   capacity < settings.maxParkCapacity - powerRating ||
        //   complexityFactor < settings.generation.maxComplexity
        if (capacity < settings.maxParkCapacity - powerRating) {
          continue;
        }

        let hull;
        if (settings.hull == "concave") {
          let unionHexagons = cells[0];

          cells.forEach((cell) => {
            const unionResult = turf.union(unionHexagons, cell);
            if (unionResult) {
              unionHexagons = unionResult;
            }
          });

          hull = unionHexagons;
        } else {
          hull = turf.convex(turf.featureCollection(cells));
        }

        if (hull === null) {
          error("Failed to create hull");
          newWorker.terminate();
          return;
        }

        const lcoe =
          newCollection.reduce((acc, cell) => acc + cell.lcoe, 0) /
          newCollection.length;
        setSelectedPolygonIds(newCollection.map((c) => c.polygonId));
        const candidate: SelectedCandidate = {
          collection: newCollection,
          hull: hull!,
          lcoe: lcoe,
          id: uuid4(),
        };
        setSelectedCandidate(candidate);
        problemCandidates.push(candidate);
        numSolutions += 1;
        if (numSolutions >= settings.generation.maxSolutions) break;
      }

      setSiteLocatorProblems((prev) => [
        ...prev,
        {
          candidates: problemCandidates,
        },
      ]);

      newWorker.terminate();

      setLoadingTime(undefined);
    }
  };

  return (
    <MenuFrame
      title="Locate site"
      onExit={() => {
        setLeftMenuActiveMode(undefined), setLCOEMenuOpen(undefined);
      }}
      style={wrapperStyle}
      icon={<HelpLink article={ARTICLE_SITE_LOCATOR} />}
    >
      {park && (
        <HeatmapWrapper
          park={park}
          lcoeParams={settings.lcoeParams}
          density={settings.density}
          turbinePower={settings.turbineType.ratedPower / 1000}
        />
      )}

      {park === undefined && (
        <div>
          <SimpleAlert
            text={"Select or create a park polygon to start."}
            type={"error"}
          />
        </div>
      )}
      <SubtitleWithLine text="Constraints" />

      <LCOESlider />
      <Column style={{ paddingBottom: spaceDecent, paddingTop: spaceLarge }}>
        <TurbineControl />
      </Column>
      <Label style={{ paddingBottom: "0.8rem" }}>
        <InputTitle>Density</InputTitle>
        <InputDimensioned
          compact
          value={settings.density ?? 5}
          onChange={(e) => {
            setOpen(undefined); // close results list
            setSettings({ ...settings, density: e.valueOf() });
          }}
          readOnly={false}
          validate={(n) => 3 <= n && n <= 10}
          unit={"MW/KM2"}
          style={{ width: "100%" }}
        />
      </Label>
      <Label style={{ paddingBottom: "0.8rem" }}>
        <InputTitleWrapper>
          <InputTitle>Target park capacity</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="Determines the size of the park in the optimization given turbine power ratings and the given density."
            size={10}
          />
        </InputTitleWrapper>

        <InputDimensioned
          compact
          value={settings.maxParkCapacity ?? 150}
          onChange={(e) => {
            setSettings({ ...settings, maxParkCapacity: e.valueOf() });
          }}
          readOnly={false}
          validate={(n) =>
            1 <= n && n <= 2000 && n >= settings.turbineType.ratedPower / 1000
          }
          unit={"MW"}
          style={{ width: "100%" }}
        />
      </Label>
      <Row style={{ paddingTop: "0.8rem", paddingBottom: "1.6rem" }}>
        <Toggle
          checked={settings.natura2000filter}
          onChange={(e) => {
            setSettings({ ...settings, natura2000filter: e.target.checked });
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitleWrapper>
          <InputTitle> Exclude Natura 2000 sites</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="Use sites from the Natura 2000 dataset as exclusion zones."
            size={10}
          />
        </InputTitleWrapper>
      </Row>
      <Row style={{ paddingTop: "0.8rem", paddingBottom: "1.6rem" }}>
        <Toggle
          checked={settings.hull === "convex"}
          onChange={(e) => {
            setSettings({
              ...settings,
              hull: e.target.checked ? "convex" : "concave",
            });
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitleWrapper>
          <InputTitle> Create loose park boundaries</InputTitle>
          <HelpTooltip
            style={{ display: "inline-flex" }}
            text="Create park areas that loosely wrap the hexagons (convex hulls). If not selecteed, then the resulting park boundaries will compactly wrap the hexagons."
            size={10}
          />
        </InputTitleWrapper>
      </Row>

      <CollectionsResultsList />
      <Divider />

      <Row style={{ justifyContent: "end", paddingTop: "10px" }}>
        {loadingTime !== undefined ? <SecondTimer from={loadingTime} /> : null}
        <Button
          text="Clear results"
          buttonType={"secondary"}
          disabled={
            siteLocatorProblemsMeta.length && !loadingTime ? false : true
          }
          onClick={clearResults}
        />
        <Button
          text={loadingTime === undefined ? "Compute areas" : "Computing"}
          buttonType={loadingTime === undefined ? "primary" : "secondary"}
          disabled={
            loadingTime === undefined && !computing && park !== undefined
              ? false
              : true
          }
          skeleton={computing}
          onClick={() => {
            Mixpanel.track_old("Triggered site locator", { ...settings });
            clickGenerate();
          }}
          title={"Compute areas"}
        />
      </Row>
    </MenuFrame>
  );
};

const LCOESlider = ({ disabled }: { disabled?: boolean }) => {
  const [lcoeSlider, setLcoeSlider] = useAtom(lcoeSliderState);
  const gridStatistics = useAtomValue(gridStatisticsState);
  const lcoeMenuRef = useRef<HTMLButtonElement>(null);
  const [openSubmenu, setOpenSubmenu] = useAtom(openLCOESubmenuAtom);

  useEffect(() => {
    if (gridStatistics === undefined) return;
    setLcoeSlider({
      min: Math.floor(gridStatistics.lcoeMin * 10) / 10,
      max: Math.ceil(gridStatistics.lcoeMax * 10) / 10,
    });
  }, [setLcoeSlider, gridStatistics]);

  useEffect(() => {
    if (
      lcoeSlider.min === 0 &&
      lcoeSlider.max === 0 &&
      gridStatistics !== undefined
    ) {
      setLcoeSlider({
        min: Math.floor(gridStatistics.lcoeMin * 10) / 10,
        max: Math.ceil(gridStatistics.lcoeMax * 10) / 10,
      });
    }
  });
  return (
    <Wrapper>
      <label>
        <LCoEParamRow>
          <p>LCoE</p>
          <Tooltip text="Configure LCoE parameters">
            <IconBtn
              backgroundColor={colors.surfaceButtonSecondary}
              size="1.4rem"
              id="site-locator-submenu-lcoe"
              ref={lcoeMenuRef}
              onClick={() => {
                setOpenSubmenu(replaceOrUndefined("lcoe"));
              }}
            >
              <OpenRight />
            </IconBtn>
          </Tooltip>
        </LCoEParamRow>
        {openSubmenu === "lcoe" && (
          <Anchor
            baseRef={lcoeMenuRef}
            floatPlace="left"
            basePlace="right"
            offset={[16, 72]}
          >
            <LCoEParams />
          </Anchor>
        )}
        <Suspense fallback={<SkeletonBlock />}>
          {gridStatistics !== undefined && (
            <>
              <RangeSlider
                min={Math.floor(gridStatistics.lcoeMin * 10) / 10}
                max={Math.ceil(gridStatistics.lcoeMax * 10) / 10}
                step={0.1}
                values={[lcoeSlider.min, lcoeSlider.max]}
                onChange={(range) =>
                  setLcoeSlider({ min: range[0], max: range[1] })
                }
                inside
                disabled={disabled}
              />
              <SelectedUnitsWrapper>
                <div>{lcoeSlider.min} m€/MWh</div>
                <div>{lcoeSlider.max} m€/Mwh</div>
              </SelectedUnitsWrapper>
            </>
          )}
        </Suspense>
      </label>
    </Wrapper>
  );
};

const SecondTimer = ({ from }: { from: number }) => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    let run = true;
    const interval = setInterval(() => {
      if (run) setTime(Date.now());
    }, 1000);
    return () => {
      run = false;
      clearInterval(interval);
    };
  }, [from]);

  const diff = time - from;
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
  const seconds = Math.floor(diff / 1000) - minutes * 60 - hours * 60 * 60;

  return (
    <p>
      {hours > 0 && `${hours}:`}
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </p>
  );
};

const LCoEParams = () => {
  const ref = useRef<HTMLDivElement>(null);
  const setMenu = useSetAtom(openLCOESubmenuAtom);
  const [settings, setSettings] = useAtom(siteLocatorSettingState);

  useClickOutside(
    ref,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "site-locator-submenu-lcoe";
    },
  );

  return (
    <MenuFrame
      title="LCoE parameters"
      onExit={() => setMenu(undefined)}
      id="site-locator-submenu-lcoe"
      ref={ref}
      style={{ width: "35rem" }}
    >
      <Column style={{ overflowY: "auto", height: "100%" }}>
        <p>
          {
            "The production is calculated using capacity factors from Global Wind Atlas (IEC Class II), DEVEX of 140 k€/MW, DECOM of 330 k€/MW, 25 years of operation and discount rate of 5%. Floating foundations are assumed for >60m depth. "
          }
        </p>

        <SubtitleWithLine text={"CAPEX"} />
        <SimpleAlert type="info">
          <p>
            WD = Water depth <strong>(m)</strong>. Source: Gebco.
            <br />
            SD = Shore distance <strong>(km)</strong>
            <br />
            Input unit = <strong>k€/MW</strong>
          </p>
        </SimpleAlert>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 0.8fr 0.8fr 1fr",
            gap: spaceMedium,
            paddingTop: "0.8rem",
          }}
        >
          <InputTitle style={{ gridColumn: "span 3" }}>Turbines</InputTitle>
          <InputNumber
            compact
            value={settings.lcoeParams.turbines}
            validate={(n) => 0 <= n}
            validationMessage={`Must be a positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: { ...settings.lcoeParams, turbines: n },
              })
            }
            step={10}
          />
          <InputTitle>Fixed foundations</InputTitle>
          <InputNumber
            compact
            value={settings.lcoeParams.fixedFoundationsConstant}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  fixedFoundationsConstant: n,
                },
              })
            }
            step={10}
          />
          <p style={{ textAlign: "end" }}>+ WD ×</p>

          <InputNumber
            compact
            value={settings.lcoeParams.fixedFoundationsMultiplier}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  fixedFoundationsMultiplier: n,
                },
              })
            }
            step={0.5}
          />
          <InputTitle>Floating foundations</InputTitle>
          <InputNumber
            compact
            value={settings.lcoeParams.floatingFoundationsConstant}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  floatingFoundationsConstant: n,
                },
              })
            }
            step={10}
          />
          <p style={{ textAlign: "end" }}>+ WD ×</p>
          <InputNumber
            compact
            value={settings.lcoeParams.floatingFoundationsMultiplier}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  floatingFoundationsMultiplier: n,
                },
              })
            }
            step={0.5}
          />

          <InputTitle>Export cable</InputTitle>
          <p />
          <p style={{ textAlign: "end" }}>SD ×</p>
          <InputNumber
            compact
            value={settings.lcoeParams.exportCableMultiplier}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            style={{ height: "2rem", width: "6rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  exportCableMultiplier: n,
                },
              })
            }
            step={0.1}
          />
        </div>
        <SubtitleWithLine text={"OPEX"} />
        <Grid2 style={{ gridTemplateColumns: "3.6fr 1fr" }}>
          <InputTitle>Service and other</InputTitle>
          <InputDimensioned
            compact
            value={settings.lcoeParams.opex}
            unit={"k€/MW/yr"}
            validate={(n) => 0 <= n}
            style={{ height: "2rem" }}
            onChange={(n) =>
              setSettings({
                ...settings,
                lcoeParams: {
                  ...settings.lcoeParams,
                  opex: n,
                },
              })
            }
          />
        </Grid2>
      </Column>
    </MenuFrame>
  );
};

export default SiteLocator;
