import SphericalMercator from "@mapbox/sphericalmercator";
import { Feature, FeatureCollection, Polygon } from "geojson";
import { FillLayer, LineLayer, MapEventOf } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { fetchTileAsync } from "../../hooks/mouseSampler";
import { fetchEnhancerWithToken } from "../../services/utils";
import { isDefined } from "../../utils/predicates";
import { scream } from "../../utils/sentry";
import { promiseWorker, typedWorker } from "../../utils/utils";
import { Args, Bbox, getBbox } from "./utils";
import { SITELOCATOR_COLORS } from "./style";
import {
  candidateGridAtom,
  computingSitesState,
  gridStatisticsState,
  siteLocatorSettingState,
  lcoeSliderState,
  selectedPolygonIdsAtom,
  selectedCandidateState,
  comparisonCellState,
  LCOEParams,
  GridStatistics,
  CandidateGrid,
  hoverPolygonAtom,
} from "./state";
import { useArrayDidChange } from "../../hooks/useArrayDidChange";
import { ProjectFeature } from "../../types/feature";
import {
  addLayer,
  siteLocatorHullLayerId,
  siteLocatorHullSourceId,
  siteLocatorLayerId,
  siteLocatorSourceId,
} from "components/Mapbox/utils";
import {
  clickHandlerAtom,
  mouseMoveHandlerAtom,
} from "components/Mapbox/state";
import {
  getSmallestFeature,
  useSyncFeatureFlag,
  useSyncFeatureFlags,
} from "components/Mapbox/MapboxSyncEffects";
import { safeRemoveLayer } from "utils/map";
import { getSimpleGebcoBathymetryTile } from "services/bathymertyService";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { exclusionZonesForParkFamily } from "state/jotai/exclusionZone";
import { featureMapFamily } from "state/jotai/features";
import { getXTileNumber, getYTileNumber } from "utils/tiles";
import { mapAtom } from "state/map";

const getShoreDistanceTile = async (x: number, y: number, z: number) =>
  fetchEnhancerWithToken(`/tiles/shore/${z}/${x}/${y}.png`, {
    method: "get",
    headers: {},
  });

const getMeanSpeedTile = async (x: number, y: number, z: number) =>
  fetchEnhancerWithToken(`/tiles/gwa/speed/150/${z}/${x}/${y}.png`, {
    method: "get",
    headers: {},
  });

const getCapacityTile = async (x: number, y: number, z: number) =>
  fetchEnhancerWithToken(`/tiles/gwa/capacity-iec2/${z}/${x}/${y}.png`, {
    method: "get",
    headers: {},
  });

const getTiles = async (
  tileFetchFunction: (
    x: number,
    y: number,
    z: number,
    tileSize: number,
  ) => Promise<Response>,
  bbox: Bbox,
  tileSize: number,
  zoom: number,
): Promise<{ imageData: ImageData; x: number; y: number }[]> => {
  const imageDatas: { imageData: ImageData; x: number; y: number }[] = [];
  for (
    let x = getXTileNumber(bbox.xmin, zoom);
    x <= getXTileNumber(bbox.xmax, zoom);
    x++
  ) {
    // ytiles reverse order
    for (
      let y = getYTileNumber(bbox.ymax, zoom);
      y <= getYTileNumber(bbox.ymin, zoom);
      y++
    ) {
      const imageData = await fetchTileAsync(
        x,
        y,
        zoom,
        tileSize,
        tileFetchFunction,
      );

      if (!isDefined(imageData)) {
        continue;
      } else {
        imageDatas.push(imageData);
      }
    }
  }
  return imageDatas;
};

const drawCanvas = async (url: string, width: number, height: number) => {
  const response = await fetch(url);
  const blob = await response.blob();

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  let img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = function () {
      res(img);
    };
  });

  if (!ctx) return canvas;
  ctx.drawImage(img, 0, 0);

  return canvas;
};

const getNaturaTile = async (
  bbox: Bbox,
  tileSize: number,
  merc: SphericalMercator,
  zoom: number,
) => {
  const startXTile = getXTileNumber(bbox.xmin, zoom);
  const startYTile = getYTileNumber(bbox.ymax, zoom);
  const endXTile = getXTileNumber(bbox.xmax, zoom);
  const endYTile = getYTileNumber(bbox.ymin, zoom);

  const [xmin, ymin] = merc.ll(
    [tileSize * startXTile, tileSize * (+endYTile + 1)],
    zoom,
  );
  const [xmax, ymax] = merc.ll(
    [tileSize * (+endXTile + 1), tileSize * startYTile],
    zoom,
  );

  const bigBbox = merc.convert([xmin, ymin, xmax, ymax], "900913").join(","); // 4326 -> 3857

  const numYTiles = endYTile - startYTile + 1;
  const numXTiles = endXTile - startXTile + 1;
  const width = tileSize * numXTiles;
  const height = tileSize * numYTiles;

  const sourceLink = "https://ows.emodnet-humanactivities.eu/wms?";
  const formatString = `format=image/png&service=WMS&version=1.1.0&request=GetMap&srs=EPSG:3857&width=${width}&height=${height}`;
  const layerName = "natura2000areas";
  const url = `${sourceLink}${formatString}&layers=${layerName}&bbox=${bigBbox}`;

  const canvas = await drawCanvas(url, width, height).catch((e) => {
    if (!(e instanceof Response)) return;
    const res = e;
    scream("failed to fetch natura2000 tile", {
      url: res.url,
      status: res.status,
      text: res.statusText,
    });
  });

  if (!canvas) return;
  const ctx = canvas.getContext("2d");
  const imageData = ctx?.getImageData(0, 0, width, height);
  if (!imageData) return;

  return imageData;
};

// Responsible for fetching tiles
const HeatmapWrapper = ({
  park,
  lcoeParams,
  density,
  turbinePower,
}: {
  park: ProjectFeature<Polygon>;
  lcoeParams: LCOEParams;
  density: number;
  turbinePower: number;
}) => {
  const tileSize = 512;
  const zoom = 7;

  const [candidateGrid, setCandidateGrid] = useAtom(candidateGridAtom);
  const setComputing = useSetAtom(computingSitesState);
  const setGridStatistics = useSetAtom(gridStatisticsState);
  const exclusionZones = useAtomValue(
    exclusionZonesForParkFamily({ parkId: park.id, branchId: undefined }),
  );
  const [memoizedExclusionZones, setMemoizedExclusionZones] =
    useState(exclusionZones);

  const exclusionZonesChanged = useArrayDidChange(exclusionZones);
  useEffect(() => {
    if (exclusionZonesChanged) {
      setMemoizedExclusionZones(exclusionZones);
    }
  }, [setMemoizedExclusionZones, exclusionZonesChanged, exclusionZones]);

  const merc: SphericalMercator = useMemo(
    () =>
      new SphericalMercator({
        size: tileSize,
        antimeridian: true,
      }),
    [tileSize],
  );

  const bbox: Bbox = useMemo(() => getBbox(park.geometry), [park]);

  useEffect(() => {
    const data = (): Promise<{
      shoreDistanceTiles: { imageData: ImageData; x: number; y: number }[];
      depthTiles: { imageData: ImageData; x: number; y: number }[];
      meanSpeedTiles: { imageData: ImageData; x: number; y: number }[];
      capacityTiles: { imageData: ImageData; x: number; y: number }[];
      naturaTile: ImageData | undefined;
    }> =>
      Promise.all([
        getTiles(getShoreDistanceTile, bbox, tileSize, zoom),
        getTiles(getSimpleGebcoBathymetryTile, bbox, tileSize, zoom),
        getTiles(getMeanSpeedTile, bbox, tileSize, zoom),
        getTiles(getCapacityTile, bbox, tileSize, zoom),
        getNaturaTile(bbox, tileSize, merc, zoom),
      ]).then(
        ([
          shoreDistanceTiles,
          depthTiles,
          meanSpeedTiles,
          capacityTiles,
          naturaTile,
        ]) => ({
          shoreDistanceTiles,
          depthTiles,
          meanSpeedTiles,
          capacityTiles,
          naturaTile,
        }),
      );

    const createCandidates = async () => {
      try {
        setComputing(true);
        const newWorker = typedWorker<
          Args,
          [GridStatistics, FeatureCollection]
        >(
          new Worker(
            new URL(
              "../SiteLocator/workers/createHexagonsWorker.ts",
              import.meta.url,
            ),
            {
              type: "module",
            },
          ),
        );

        const {
          shoreDistanceTiles,
          depthTiles,
          meanSpeedTiles,
          capacityTiles,
          naturaTile,
        } = await data();

        if (!naturaTile) return;

        const [gridStatistics, candidateGrid]: [
          GridStatistics,
          FeatureCollection,
        ] = await promiseWorker(
          newWorker,
          [
            park,
            memoizedExclusionZones,
            shoreDistanceTiles,
            depthTiles,
            meanSpeedTiles,
            capacityTiles,
            naturaTile,
            zoom,
            tileSize,
            turbinePower,
            density,
            lcoeParams,
            merc,
          ],
          "SiteLocatorHeatmap",
        );
        newWorker.terminate();

        setGridStatistics(gridStatistics);

        candidateGrid.features.forEach((feat: Feature) => {
          if (feat.properties) feat.properties.selected = false;
        });

        setCandidateGrid(candidateGrid);
      } catch (error) {
        console.error("Error fetching tiles:", error);
      } finally {
        setComputing(false);
      }
    };

    createCandidates();
  }, [
    bbox,
    park,
    density,
    turbinePower,
    memoizedExclusionZones,
    merc,
    setCandidateGrid,
    setComputing,
    setGridStatistics,
    lcoeParams,
  ]);

  return <Heatmap candidateGrid={candidateGrid} />;
};

const hexagonGridLayer: FillLayer = {
  id: siteLocatorLayerId,
  type: "fill",
  source: siteLocatorSourceId,
  paint: {
    "fill-color": {
      property: "currentScore",
      stops: SITELOCATOR_COLORS.map((d, i) => [i / 10, d]),
    },
    "fill-opacity": [
      "case",
      ["to-boolean", ["feature-state", "hover"]],
      1,
      ["to-boolean", ["feature-state", "selected"]],
      1,
      0.6,
    ],
  },
  filter: ["!=", "disableFeature", true],
};

const hullLayer: LineLayer = {
  id: siteLocatorHullLayerId,
  type: "line",
  source: siteLocatorHullSourceId,
  paint: {
    "line-color": "black",
    "line-width": 3,
  },
};

const Heatmap = ({ candidateGrid }: { candidateGrid: CandidateGrid }) => {
  const map = useAtomValue(mapAtom);
  const settings = useAtomValue(siteLocatorSettingState);
  const lcoeConstraint = useAtomValue(lcoeSliderState);
  const setHoverPolygon = useSetAtom(hoverPolygonAtom);
  const [hoverId, setHoverId] = useState<undefined | string | number>(
    undefined,
  );
  const [comparisonCell, setComparisonCell] = useAtom(comparisonCellState);
  const selectedPolygonIds = useAtomValue(selectedPolygonIdsAtom);

  const selectedCandidate = useAtomValue(selectedCandidateState);

  // Honeycomb source and layer
  useEffect(() => {
    if (!map) return;
    map.addSource(siteLocatorSourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    addLayer(map, hexagonGridLayer);
    return () => {
      safeRemoveLayer(map, hexagonGridLayer.id);
      map.removeSource(siteLocatorSourceId);
      setHoverPolygon((curVal) => ({ ...curVal, show: false }));
    };
  }, [map, setHoverPolygon]);

  // Honeycomb data. Separate effect so that live updates are possible without flashing.
  useEffect(() => {
    if (!map || !candidateGrid) return;
    const source = map.getSource(siteLocatorSourceId);
    if (source?.type !== "geojson") return;

    for (const feat of candidateGrid.features) {
      if (feat.properties) {
        feat.properties.currentScore =
          settings.layers.depth.alpha * feat.properties.currentDepthScore +
          settings.layers.shoreDistance.alpha *
            feat.properties.currentShoreDistanceScore +
          settings.layers.meanSpeed.alpha *
            feat.properties.currentMeanSpeedScore +
          settings.layers.aep.alpha * feat.properties.currentAEPScore +
          settings.layers.lcoe.alpha * feat.properties.currentLCOEScore;

        feat.properties.disableFeature = false;
        const avgLCOE = feat.properties.avgLCOE;
        if (avgLCOE < lcoeConstraint.min || avgLCOE > lcoeConstraint.max) {
          feat.properties.disableFeature = true;
        }
        if (settings.natura2000filter && feat.properties.naturaScore === 1) {
          feat.properties.disableFeature = true;
        }
      }
    }

    source.setData(candidateGrid);
  }, [candidateGrid, lcoeConstraint, map, settings]);

  // Hull source and layer
  useEffect(() => {
    if (!map || !selectedCandidate) return;
    map.addSource(siteLocatorHullSourceId, {
      type: "geojson",
      data: selectedCandidate.hull,
    });
    addLayer(map, hullLayer);

    return () => {
      safeRemoveLayer(map, siteLocatorHullLayerId);
      map.removeSource(siteLocatorHullSourceId);
    };
  }, [map, selectedCandidate]);

  // Sync selection and hover
  useSyncFeatureFlags(map, siteLocatorSourceId, selectedPolygonIds, "selected");
  useSyncFeatureFlag(map, siteLocatorSourceId, hoverId, "hover");
  useSyncFeatureFlag(
    map,
    siteLocatorSourceId,
    comparisonCell?.polygonId,
    "selected",
  );

  // Mouse handlers
  const setMouseMoveHandler = useSetAtom(mouseMoveHandlerAtom);
  const setMouseClickHandler = useSetAtom(clickHandlerAtom);
  const featureMap = useAtomValue(featureMapFamily({ branchId: undefined }));
  useEffect(() => {
    if (!map) return;

    const mousemoveListener = (e: MapEventOf<"mousemove">) => {
      if (!map.getLayer(siteLocatorLayerId)) return;
      const features = getSmallestFeature(e, map, featureMap, {
        layers: [siteLocatorLayerId],
      });
      if (e && features && features.length > 0) {
        const feature = features[0];
        setHoverPolygon({
          show: true,
          depth: feature.properties?.avgDepth,
          shoreDist: feature.properties?.avgShoreDistance,
          meanSpeed: feature.properties?.avgMeanSpeed,
          capacity: feature.properties?.avgCapacity,
          lcoe: feature.properties?.avgLCOE,
        });
        setHoverId(feature.id);
        map.getCanvas().style.cursor = "pointer";
      } else {
        setHoverId(undefined);
        setHoverPolygon({
          show: false,
          depth: 0,
          shoreDist: 0,
          meanSpeed: 0,
          capacity: 0,
          lcoe: 0,
        });
        map.getCanvas().style.cursor = "unset";
      }
    };

    const mouseclickListener = (e: MapEventOf<"mousedown">) => {
      if (!map.getLayer(siteLocatorLayerId)) return;
      const features = getSmallestFeature(e, map, featureMap, {
        layers: [siteLocatorLayerId],
      });
      if (e && features && features.length) {
        const feature = features[0];
        setComparisonCell({
          polygonId: Number(feature.id),
          depth: feature.properties?.avgDepth,
          shoreDist: feature.properties?.avgShoreDistance,
          meanSpeed: feature.properties?.avgMeanSpeed,
          aep: feature.properties?.avgCapacity,
          lcoe: feature.properties?.avgLCOE,
        });
      } else {
        setComparisonCell(undefined);
      }
    };

    setMouseMoveHandler(() => mousemoveListener);
    setMouseClickHandler(() => mouseclickListener);

    return () => {
      setMouseMoveHandler(undefined);
      setMouseClickHandler(undefined);
    };
  }, [
    candidateGrid?.features,
    featureMap,
    lcoeConstraint,
    map,
    setComparisonCell,
    setHoverPolygon,
    setMouseClickHandler,
    setMouseMoveHandler,
    settings,
  ]);

  return null;
};

export default HeatmapWrapper;
