import * as turf from "@turf/turf";
import { Map, Point } from "mapbox-gl";

export function getDragDirection(bbox: [mapboxgl.Point, mapboxgl.Point]) {
  const directionRight = bbox[0].x < bbox[1].x;
  return directionRight ? "right" : "left";
}

export const normaliseBBOX = (bbox: [mapboxgl.Point, mapboxgl.Point]) => {
  const Xs = [bbox[0].x, bbox[1].x];
  const Ys = [bbox[0].y, bbox[1].y];
  return [
    new Point(Math.min(...Xs), Math.min(...Ys)),
    new Point(Math.max(...Xs), Math.max(...Ys)),
  ] as [mapboxgl.Point, mapboxgl.Point];
};

function isFeatureInsideBbox(
  feature: mapboxgl.MapboxGeoJSONFeature,
  bboxPolygon: turf.helpers.Feature<
    turf.helpers.Polygon,
    turf.helpers.Properties
  >,
) {
  switch (feature.geometry.type) {
    case "Point":
      return turf.booleanPointInPolygon(
        feature.geometry.coordinates,
        bboxPolygon,
      );
    case "LineString":
    case "Polygon":
      return turf.booleanContains(bboxPolygon, feature);
    case "MultiPolygon":
      return feature.geometry.coordinates.every((polygon) =>
        turf.booleanContains(bboxPolygon, turf.polygon(polygon)),
      );
    default:
      console.warn("Unhandled geometry type:", feature.geometry.type);
      return false;
  }
}

export function completelyInsideBboxFilter(
  map: Map,
  bbox: [mapboxgl.Point, mapboxgl.Point],
  features: mapboxgl.MapboxGeoJSONFeature[],
) {
  const Xs = [bbox[0].x, bbox[1].x];
  const Ys = [bbox[0].y, bbox[1].y];

  const bboxLocalCoords = [
    Math.min(...Xs),
    Math.min(...Ys),
    Math.max(...Xs),
    Math.max(...Ys),
  ];
  const bboxCoords = [
    [bboxLocalCoords[0], bboxLocalCoords[1]],
    [bboxLocalCoords[2], bboxLocalCoords[1]],
    [bboxLocalCoords[2], bboxLocalCoords[3]],
    [bboxLocalCoords[0], bboxLocalCoords[3]],
    [bboxLocalCoords[0], bboxLocalCoords[1]],
  ]
    .map((point) => map.unproject(new Point(point[0], point[1])))
    .map((point) => [point.lng, point.lat]);

  const bboxFeature = turf.polygon([bboxCoords]);

  return features.filter((feature) => {
    return isFeatureInsideBbox(feature, bboxFeature);
  });
}
