import {
  WebGLRenderer,
  ACESFilmicToneMapping,
  Scene,
  Color,
  PerspectiveCamera,
  PlaneGeometry,
  RepeatWrapping,
  TextureLoader,
  Vector3,
  Mesh,
  MeshBasicMaterial,
  Shader,
  SphereGeometry,
  PMREMGenerator,
  MathUtils,
  WebGLRenderTarget,
  DoubleSide,
  MeshStandardMaterial,
  Raycaster,
  BufferGeometry,
} from "three";
import { Color as VindColor } from "lib/colors";
import { Sky } from "three/examples/jsm/objects/Sky";
import { Water } from "three/examples/jsm/objects/Water";
import {
  DIVISION_FACTOR,
  FOG_OPACITY_START,
  FOG_OPACITY_END,
  WIRE_FRAME_SUN_RADIUS,
} from "../constants";
import WaterNormals from "../waternormals.jpg";
import { Position } from "@turf/turf";
import { projectedToWGS84, wgs84ToProjected } from "utils/proj4";
import { Feature, LineString, Polygon } from "geojson";
import { Tile, lonLatToTile, tile2bbox } from "types/tile";
import * as turf from "@turf/turf";
import { LngLat } from "types/gis";
import { Gradient } from "lib/colors";

export function createRenderer() {
  const renderer = new WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.toneMapping = ACESFilmicToneMapping;
  return renderer;
}

export function createScene() {
  const scene = new Scene();
  scene.background = new Color(0xeeeeee);
  return scene;
}

export function createPerspectiveCamera() {
  const camera = new PerspectiveCamera(
    30,
    window.innerWidth / window.innerHeight,
    1 / DIVISION_FACTOR,
    70_000 / DIVISION_FACTOR,
  );

  return camera;
}

const waterNormals = new TextureLoader().load(WaterNormals, function (texture) {
  texture.wrapS = texture.wrapT = RepeatWrapping;
});

export function createWater() {
  const waterGeometry = new PlaneGeometry(10000, 10000, 1000, 1000);

  const water = new Water(waterGeometry, {
    textureWidth: 512,
    textureHeight: 512,
    waterNormals: waterNormals,
    sunDirection: new Vector3(),
    sunColor: 0xffffff,
    waterColor: 0x001e0f,
    distortionScale: 3.7,
    fog: true,
    clipBias: 2,
  });

  water.rotation.x = -Math.PI / 2;

  water.material.onBeforeCompile = (shader: Shader) => {
    shader.vertexShader = `
        uniform mat4 textureMatrix;
				uniform float time;

				varying vec4 mirrorCoord;
				varying vec4 worldPosition;

				#include <common>
				#include <fog_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>

				void main() {
          mirrorCoord = modelMatrix * vec4( position, 1.0 );
          float division_factor = ${DIVISION_FACTOR}.;
          float earth_radius = 6371000./division_factor;
          float earth_circumference = 40030000./division_factor;
          float earth_angle = (2. * PI) /  earth_circumference;
          float dist = length(cameraPosition.xz - mirrorCoord.xz);
          float a = earth_angle * dist;
          float h = earth_radius * (1. - cos(a));

          mirrorCoord = vec4(mirrorCoord.x, mirrorCoord.y - h, mirrorCoord.z, mirrorCoord.w);					
					worldPosition = mirrorCoord.xyzw;
					mirrorCoord = textureMatrix * mirrorCoord;
					vec4 mvPosition = viewMatrix * worldPosition;
					gl_Position = projectionMatrix * mvPosition;

          #include <beginnormal_vertex>
          #include <defaultnormal_vertex>
          #include <logdepthbuf_vertex>
          #include <fog_vertex>
          #include <shadowmap_vertex>
        }
      `;
  };

  const waterUniforms = water.material.uniforms;
  waterUniforms.distortionScale = { value: 3.7 };
  waterUniforms.size = { value: 20.0 };

  return water;
}

export function createSun() {
  const sunSphere = new Mesh(
    new SphereGeometry(WIRE_FRAME_SUN_RADIUS, 14, 14),
    new MeshBasicMaterial({ color: 0xffff00 }),
  );
  sunSphere.material.wireframe = true;
  return sunSphere;
}

const gradientMin = new VindColor(0, 4, 9, 1);
const gradient1 = new VindColor(55, 61, 71, 1);
const gradient2 = new VindColor(150, 157, 165, 1);
const gradient3 = new VindColor(179, 186, 195, 1);
const skyGradient = new Gradient([
  [gradientMin, 0],
  [gradient1, 0.08],
  [gradient2, 0.4],
  [gradient3, 0.8],
  [gradient1, 1],
]);

export function getSkyGradientColor(altitude: number) {
  const horizonColor = skyGradient.get(MathUtils.radToDeg(altitude) / 70);
  return new VindColor(horizonColor.r, horizonColor.g, horizonColor.b, 1);
}

export function createSky() {
  const sky = new Sky();
  sky.scale.setScalar(450000);

  const skyUniforms = sky.material.uniforms;

  // These can not be changed without re-calculating the
  // skyGradient
  skyUniforms["turbidity"].value = 100;
  skyUniforms["rayleigh"].value = 1.5;
  skyUniforms["mieCoefficient"].value = 0.005;
  skyUniforms["mieDirectionalG"].value = 0.8;
  return sky;
}

export function createBlinkingLight(
  coords: [number, number],
  turbineHeight: number,
) {
  const geometry = new SphereGeometry(
    (10 * turbineHeight) / 100 / DIVISION_FACTOR,
    16,
    16,
  );
  const material = new MeshStandardMaterial({
    color: 0xffffff,
  });
  material.onBeforeCompile = (shader: Shader) => {
    shader.vertexShader = injectCurvatureIntoVertexShader(shader.vertexShader);
    shader.fragmentShader = `void main() {
          gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
        }`;
  };
  const sphere = new Mesh(geometry, material);
  sphere.translateX(-coords[0]);
  sphere.translateZ(coords[1]);
  sphere.translateY(turbineHeight / DIVISION_FACTOR);
  return sphere;
}

export function createLowIntensityLight(
  coords: [number, number],
  turbineHeight: number,
) {
  const geometry = new SphereGeometry(
    (5 * turbineHeight) / 100 / DIVISION_FACTOR,
    16,
    16,
  );
  const material = new MeshStandardMaterial({
    color: 0xff0000,
  });
  material.onBeforeCompile = (shader: Shader) => {
    shader.vertexShader = injectCurvatureIntoVertexShader(shader.vertexShader);
    shader.fragmentShader = `void main() {
          gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
        }`;
  };
  const sphere = new Mesh(geometry, material);

  sphere.translateX(-coords[0]);
  sphere.translateZ(coords[1]);
  sphere.translateY(turbineHeight / 2 / DIVISION_FACTOR);
  return sphere;
}

function createPmremGenerator(renderer: WebGLRenderer) {
  const pmremGenerator = new PMREMGenerator(renderer);
  return pmremGenerator;
}

function createRenderTarget(scene: Scene, renderer: WebGLRenderer) {
  const pmremGenerator = createPmremGenerator(renderer);
  // seems to work with a Mesh as well, not sure why. But performance is much approved if we send in a single mesh (sky) instead of the scene
  const renderTarget = pmremGenerator.fromScene(scene);
  pmremGenerator.dispose();
  return renderTarget;
}

export const INITIAL_SUN_POS = {
  elevation: 2,
  azimuth: 180,
};

const sunPosition = new Vector3();
let _renderTarget: WebGLRenderTarget | undefined = undefined;

export function updateSun(
  parameters: { elevation: number; azimuth: number },
  sky: Sky,
  water: Water,
  renderer: WebGLRenderer,
  scene: Scene,
) {
  const phi = MathUtils.degToRad(90 - parameters.elevation);
  const theta = MathUtils.degToRad(180 - parameters.azimuth);

  sunPosition.setFromSphericalCoords(1, phi, theta);

  sky.material.uniforms["sunPosition"].value.copy(sunPosition);
  water.material.uniforms["sunDirection"].value.copy(sunPosition).normalize();

  if (_renderTarget) {
    _renderTarget.dispose();
  }

  // @ts-ignore: weird that this works, but it seems to.
  _renderTarget = createRenderTarget(sky, renderer);
  scene.environment = _renderTarget.texture;
}

export const sampleTerrainMeshHeight = (
  coords: number[],
  terrainMesh?: Mesh<BufferGeometry>,
): number | undefined => {
  if (!terrainMesh || !terrainMesh.geometry.boundingSphere?.radius) return;
  const ray = new Raycaster();
  const rayPos = new Vector3();

  rayPos.set(-coords[0], 100, coords[1]);
  const rayDir = new Vector3(0, -1, 0); // Ray points down
  ray.set(rayPos, rayDir);
  const intersect = ray.intersectObject(terrainMesh);

  if (intersect.length !== 1) return;

  return Math.max(intersect[0].point.y * DIVISION_FACTOR, 0);
};

const materialCurvature = new MeshStandardMaterial({
  transparent: true,
  side: DoubleSide,
  color: 0x77bb77,
});

export const injectCurvatureIntoVertexShader = (vertexShader: string) =>
  `
  varying vec3 v_pos;
  varying vec3 vWorldPosition;
  varying vec4 vertex_world_position;
    ` +
  vertexShader.split("}")[0] +
  `
      vertex_world_position = modelMatrix * vec4(position, 1.0);
  
      float division_factor = ${DIVISION_FACTOR}.;
      float earth_radius = 6371000./division_factor;
      float earth_circumference = 40030000./division_factor;
      float earth_angle = (2. * PI) /  earth_circumference;
      float dist = length(cameraPosition.xz - vertex_world_position.xz);
      float a = earth_angle * dist;
      float h = earth_radius * (1. - cos(a));
  
      gl_Position = projectionMatrix * viewMatrix * vec4(vertex_world_position.x, vertex_world_position.y - h, vertex_world_position.z, vertex_world_position.w);
      v_pos = position;
      vWorldPosition = transformed;
  }
    `;

const injectDistanceTransparencyIntoFragmentShader = (
  fragmentShader: string,
) => {
  // Add our uniforms to fragment shader
  let injectedFragmentShader =
    `uniform float fadeStart;
uniform float fadeEnd;
varying vec4 vertex_world_position;
` + fragmentShader;

  // Modify the output_fragment include to apply our distance-based opacity
  injectedFragmentShader = injectedFragmentShader.replace(
    "}",
    `
float dist = length(cameraPosition - vertex_world_position.xyz);
float distanceOpacity = 1.0 - smoothstep(fadeStart, fadeEnd, dist);
distanceOpacity = clamp(distanceOpacity, 0.0, 1.0);

gl_FragColor.a = gl_FragColor.a * distanceOpacity;
}`,
  );
  return injectedFragmentShader;
};

export const injectFogOpacityAndCurvatureIntoShader = (shader: Shader) => {
  shader.uniforms.fadeStart = { value: FOG_OPACITY_START };
  shader.uniforms.fadeEnd = { value: FOG_OPACITY_END };
  shader.vertexShader = injectCurvatureIntoVertexShader(shader.vertexShader);
  shader.fragmentShader = injectDistanceTransparencyIntoFragmentShader(
    shader.fragmentShader,
  );
};

materialCurvature.onBeforeCompile = (shader: Shader) => {
  shader.vertexShader = injectCurvatureIntoVertexShader(shader.vertexShader);

  const fragmentShader =
    `
  varying vec3 v_pos;
  ` +
    shader.fragmentShader.split("}")[0] +
    `
      if(v_pos.z < 0.0) {
        gl_FragColor = vec4(1.0, 0.0, 0.0, 0.0);
      } else {
        gl_FragColor = gl_FragColor;
      }
    }
    `;

  shader.fragmentShader = fragmentShader;
};

export const getFOVLinesFromAngle = (
  viewTowards: Position | undefined,
  viewAngle: number,
  horizontalFOV: number | undefined,
  proj4String: string | undefined,
  viewPosition: LngLat,
  extensionPseudoMercator = 1,
): Feature<LineString>[] => {
  if (!horizontalFOV || !proj4String || !viewTowards) return [];

  const halfFov = MathUtils.degToRad(horizontalFOV / 2);
  const viewAngleRad = MathUtils.degToRad(viewAngle);

  const viewPositionCoords = [viewPosition.lng, viewPosition.lat];
  const viewPositionPsuedoMercator = wgs84ToProjected(
    viewPositionCoords,
    proj4String,
  );

  const length = Math.sqrt(
    (viewTowards[0] - viewPositionPsuedoMercator[0]) ** 2 +
      (viewTowards[1] - viewPositionPsuedoMercator[1]) ** 2,
  );

  const vector = [
    Math.sin(viewAngleRad) * length,
    Math.cos(viewAngleRad) * length,
  ];

  const fov1 = [
    (vector[0] * Math.cos(halfFov) - vector[1] * Math.sin(halfFov)) *
      extensionPseudoMercator,
    (vector[0] * Math.sin(halfFov) + vector[1] * Math.cos(halfFov)) *
      extensionPseudoMercator,
  ];
  const fov2 = [
    (vector[0] * Math.cos(-halfFov) - vector[1] * Math.sin(-halfFov)) *
      extensionPseudoMercator,
    (vector[0] * Math.sin(-halfFov) + vector[1] * Math.cos(-halfFov)) *
      extensionPseudoMercator,
  ];

  return [fov1, fov2]
    .map((c) => [
      c[0] + viewPositionPsuedoMercator[0],
      c[1] + viewPositionPsuedoMercator[1],
    ])
    .map((c) => projectedToWGS84(c, proj4String))
    .map((f) => ({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [viewPositionCoords, f],
      },
    }));
};

export const getFOVLines = (
  viewTowards: Position | undefined,
  horizontalFOV: number | undefined,
  proj4String: string | undefined,
  viewPosition: LngLat,
  extensionPseudoMercator = 1,
): Feature<LineString>[] => {
  if (!viewTowards || !horizontalFOV || !proj4String) return [];

  const halfFov = MathUtils.degToRad(horizontalFOV / 2);
  const viewPositionCoords = [viewPosition.lng, viewPosition.lat];
  const viewPositionPsuedoMercator = wgs84ToProjected(
    viewPositionCoords,
    proj4String,
  );
  const vector = [
    viewTowards[0] - viewPositionPsuedoMercator[0],
    viewTowards[1] - viewPositionPsuedoMercator[1],
  ];

  const fov1 = [
    (vector[0] * Math.cos(halfFov) - vector[1] * Math.sin(halfFov)) *
      extensionPseudoMercator,
    (vector[0] * Math.sin(halfFov) + vector[1] * Math.cos(halfFov)) *
      extensionPseudoMercator,
  ];
  const fov2 = [
    (vector[0] * Math.cos(-halfFov) - vector[1] * Math.sin(-halfFov)) *
      extensionPseudoMercator,
    (vector[0] * Math.sin(-halfFov) + vector[1] * Math.cos(-halfFov)) *
      extensionPseudoMercator,
  ];

  return [fov1, fov2]
    .map((c) => [
      c[0] + viewPositionPsuedoMercator[0],
      c[1] + viewPositionPsuedoMercator[1],
    ])
    .map((c) => projectedToWGS84(c, proj4String))
    .map((f) => ({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [viewPositionCoords, f],
      },
    }));
};

export function boundingBoxToPolygonTiles(
  bbox: number[],
  zoom: number,
): Feature<Polygon>[] {
  const minTile = lonLatToTile(bbox[0], bbox[1], zoom);
  const maxTile = lonLatToTile(bbox[2], bbox[3], zoom);

  const tiles: Tile[] = [];
  for (let x = minTile.x; x <= maxTile.x; x++) {
    for (let y = minTile.y; y >= maxTile.y; y--) {
      tiles.push({ x, y, z: zoom });
    }
  }

  return tiles.map((tile) => {
    const bbox = tile2bbox(tile);
    const polygon = turf.bboxPolygon(bbox);
    return { ...polygon, properties: { tile } };
  });
}

export function getTileBBox(
  x: number,
  y: number,
  z: number,
): [number, number, number, number] {
  const tileSize = (20037508.342789244 * 2) / 2 ** z;

  const minx = x * tileSize - 20037508.342789244;
  const maxx = (x + 1) * tileSize - 20037508.342789244;
  const miny = 20037508.342789244 - (y + 1) * tileSize;
  const maxy = 20037508.342789244 - y * tileSize;

  return [minx, miny, maxx, maxy];
}
