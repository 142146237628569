import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg";
import { modalTypeOpenAtom } from "state/modal";
import { spacing13, spacing6, spacing7 } from "styles/space";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useSetAtom } from "jotai";

const Wrapper = styled.div`
  position: fixed;
  top: var(--top-bar-menu-height);
  left: 0;
  z-index: 7;
  width: 100%;
  height: calc(100% - var(--top-bar-menu-height));
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const ModalHeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing7};
  background-color: ${colors.blue700};
  height: ${spacing13};
  box-sizing: border-box;

  h3 {
    color: white;
    margin: 0;
  }
`;

export const TopBarModalHeader = ({
  title,
  rightSide,
  onClose,
}: {
  title: React.ReactNode;
  rightSide?: React.ReactNode;
  onClose?(): void;
}) => {
  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-end",
    middleware: [offset({ mainAxis: 20 })],
  });

  return (
    <ModalHeaderWrapper>
      {typeof title === "string" ? <h3>{title}</h3> : title}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {rightSide}
        {onClose && (
          <>
            <HighlightStep
              tourId="general-intro-tour"
              stepId="closeCompare"
              color={colors.blue100}
            >
              <HighlightStep
                tourId="onshore-intro-tour"
                stepId="closeCompare"
                color={colors.blue100}
              >
                <IconBtn
                  id="top-bar-modal-close"
                  onClick={onClose}
                  iconColor={colors.white}
                  size={spacing6}
                  ref={refs.setReference}
                  hoverBackgroundColor="transparent"
                >
                  <CloseIcon />
                </IconBtn>
              </HighlightStep>
            </HighlightStep>
            <FloatingFocusManager context={context} modal={false}>
              <TourStep
                tourId="general-intro-tour"
                stepId="closeCompare"
                innerRef={refs.setFloating}
                style={floatingStyles}
              />
            </FloatingFocusManager>
            <FloatingFocusManager context={context} modal={false}>
              <TourStep
                tourId="onshore-intro-tour"
                stepId="closeCompare"
                innerRef={refs.setFloating}
                style={floatingStyles}
              />
            </FloatingFocusManager>
          </>
        )}
      </div>
    </ModalHeaderWrapper>
  );
};

const TopBarModal = ({
  children,
  disableClose,
  onClose,
  style,
}: {
  children: ReactNode;
  disableClose?: boolean;
  style?: React.CSSProperties;
  onClose?(): void;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  useEffect(() => {
    if (disableClose) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onClose) {
          onClose();
        } else {
          setModalTypeOpen(undefined);
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [setModalTypeOpen, disableClose, onClose]);

  return (
    <Wrapper data-is-modal={1} style={style}>
      {children}
    </Wrapper>
  );
};

export default TopBarModal;
