import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";
import BinIcon from "@icons/24/Bin.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import CompareIcon from "@icons/24/Compare.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import DownloadIcon from "@icons/24/Download.svg";
import FolderMenuOpen from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosed from "@icons/20/FolderMenuClosed.svg";
import SectionIcon from "@icons/24/Section.svg";
import ViewIcon from "@icons/24/View.svg";
import ViewOffIcon from "@icons/24/ViewOff.svg";
import useTextInput from "../../hooks/useTextInput";
import { isDefined, isPark, isPointFeature } from "../../utils/predicates";
import Spinner from "@icons/spinner/Spinner";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import { modalTypeOpenAtom } from "../../state/modal";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import { EditableText } from "../General/EditableText";
import { DotMenu, MenuButtonRef } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import { dedup, platformCtrlOrCommand } from "../../utils/utils";
import { IconREMSize } from "../../styles/typography";
import { selectedParksAtom } from "../CompareParksModal/state";
import { CompareParksModalType } from "../CompareParksModal/CompareParksModalType";
import {
  ExpandArrowWrapper,
  FlexCenterAligned,
  ProjectElementFolderTopRow,
  ProjectElementFolderWrapper,
  Ui13RegularOverflow,
  DnDIconWrapper,
  ProjectElementEmptyFolderItemWrapper,
} from "./ProjectElementsV2.style";
import {
  elementTreeFind,
  elementTreeFindAll,
  elementTreeFlatten,
  featuresToDownloadText,
  generateSafeFolderId,
  getTreeRoot,
  PROJECT_ELEMENT_FOLDER,
  PROJECT_ELEMENT_FOLDER_ITEM,
  PROJECT_ELEMENT_ORPHAN_ITEM,
  PROJECT_ELEMENT_PARK,
} from "./utils";
import ProjectFeatureItem from "./ProjectFeatureItem";
import ParkElement from "./ParkElement";
import { useProjectElementsContext } from "./ProjectElementsContext";
import {
  DropCollectedProps,
  DropOnFeatureResults,
  ElementTreeFolder,
  ElementTreeNode,
  isFolder,
} from "./types";
import { getDropCollect, dragCollect } from "./shared-dnd-callbacks";
import { moveToFolder, useDnDNodeHooks } from "./hooks";
import { useToast } from "hooks/useToast";
import { useSetAtom } from "jotai";
import { ProjectElementFolderType } from "components/ProjectElementsV2/service";
import { Mixpanel } from "mixpanel";
import { resetListIfNotAlreadyEmpty } from "utils/resetList";

const ProjectFolder = ({
  folderNode,
  defaultOpen,
  onDeleteFeatures,
  depth = 0,
}: {
  folderNode: ElementTreeFolder;
  defaultOpen: boolean;
  isMultiSelect?: boolean;
  onDeleteFeatures?(featureIds: string[]): void;
  depth?: number;
}) => {
  const folder = folderNode.folder;
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const {
    branchId,
    selectedParks,
    editorAccessProject,
    isReadOnly,
    mapSelectedId,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    currentSelectionArray,
    toggleFeaturesSelected,
    shiftSelectFeatures,
    updateProjectElementsFolder,
    removeProjectElementsFolder,
    isDownloading,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    downloadMultipleFeaturesDXF,
    downloadMultipleFeaturesCSV,
    tree,
    setCurrentSelectionArray,
    deselectAllFeatures,
    onOpenedChange,
    getIsOpened,
    reorderTopLevel,
  } = useProjectElementsContext();
  const [isOpen, setIsOpen] = useState<boolean>(
    defaultOpen || getIsOpened(folder.folderId),
  );
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(
    folder.folderName,
  );
  const [disableDrag, setDisableDrag] = useState(false);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetAtom(
    selectedParksAtom({
      projectId,
    }),
  );
  const dotMenuRef = useRef<MenuButtonRef>(null);
  const [hoverState, setHoverState] = useState<
    "bottom" | "top" | "middle" | undefined
  >();
  const elementRef = useRef<HTMLDivElement>(null);
  const topRowRef = useRef<HTMLDivElement>(null);
  const stringToUnicode = useSystemSpecificUnicode();

  const childFeatures = useMemo(
    () =>
      folderNode.children.flatMap((n) =>
        n.type === "feature" ? [n.feature] : [],
      ),
    [folderNode.children],
  );

  const childFeaturesRecursive = useMemo(
    () => elementTreeFlatten(folderNode).map((n) => n.feature),
    [folderNode],
  );

  useEffect(() => {
    setWrittenName(folder.folderName);
  }, [folder.folderName, setWrittenName]);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(defaultOpen);
    }
  }, [defaultOpen]);

  const changeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);
      onOpenedChange(folder.folderId, newIsOpen);
    },
    [onOpenedChange, folder.folderId],
  );

  const featureIds = useMemo(
    () => childFeatures.map((c) => c.id),
    [childFeatures],
  );

  const featureIdsRecursive = useMemo(
    () => childFeaturesRecursive.map((n) => n.id),
    [childFeaturesRecursive],
  );

  const { error } = useToast();

  const dnd = useDnDNodeHooks({
    node: folderNode,
    divRef: elementRef,
    setHoverState,
    reorderTopLevel,
    updateFolder: updateProjectElementsFolder,
    onError: (e) => {
      error(e.message);
    },
  });

  const [dragCollection, dragRef] = useDrag<
    ElementTreeNode[],
    void,
    {
      isDragging: boolean;
    }
  >(
    () => ({
      type: PROJECT_ELEMENT_FOLDER,
      item: () => {
        const root = getTreeRoot(folderNode);
        const ids = new Set(currentSelectionArray);
        const nodes = elementTreeFindAll(root, (n) => ids.has(n.id));
        nodes.push(folderNode);
        return dedup(nodes, (n) => n.id);
      },
      canDrag: () => {
        return (
          editorAccessProject &&
          !isReadOnly &&
          !folder.isTemporary &&
          !disableDrag
        );
      },
      collect: dragCollect,
    }),
    [
      folderNode,
      currentSelectionArray,
      editorAccessProject,
      isReadOnly,
      folder.isTemporary,
      disableDrag,
    ],
  );

  const [dropCollection, dropRef] = useDrop<
    ElementTreeNode[],
    DropOnFeatureResults,
    DropCollectedProps
  >(
    () => ({
      accept: [
        PROJECT_ELEMENT_ORPHAN_ITEM,
        PROJECT_ELEMENT_FOLDER_ITEM,
        PROJECT_ELEMENT_FOLDER,
        PROJECT_ELEMENT_PARK,
      ],
      collect: getDropCollect(),
      hover: dnd.hover,
      canDrop: () => {
        return editorAccessProject && !isReadOnly && !folder.isTemporary;
      },
      drop: dnd.drop,
    }),
    [editorAccessProject, isReadOnly, folder, dnd.drop, dnd.hover],
  );

  const containsSelectedFeatureFromMap = useMemo(() => {
    return featureIds.some((id) => id === mapSelectedId);
  }, [featureIds, mapSelectedId]);

  useEffect(() => {
    if (containsSelectedFeatureFromMap) {
      changeIsOpen(true);
    }
  }, [containsSelectedFeatureFromMap, changeIsOpen]);

  const onChangeNameSubmit = () =>
    updateProjectElementsFolder({
      ...folder,
      folderName: writtenName,
    });

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(featureIds),
    [getAreAllFeaturesVisible, featureIds],
  );

  const isSelected = currentSelectionArray.includes(folderNode.id);

  const allFeaturesAreSelected = useMemo(
    () =>
      featureIds.length > 0 &&
      featureIds.every((featureId) =>
        currentSelectionArray.includes(featureId),
      ),
    [featureIds, currentSelectionArray],
  );

  const onFolderClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.shiftKey) {
        shiftSelectFeatures(folderNode.id);
      } else if (platformCtrlOrCommand(e)) {
        toggleFeaturesSelected([folderNode.id]);
      } else {
        changeIsOpen(!isOpen);
      }
    },
    [
      changeIsOpen,
      folderNode.id,
      isOpen,
      shiftSelectFeatures,
      toggleFeaturesSelected,
    ],
  );

  const onAddFeatureToFolder = useCallback(
    (folder: ProjectElementFolderType, featureIds: string[]) => {
      const nodes = featureIds
        .map((id) => elementTreeFind(tree, (n) => n.id === id))
        .filter(isDefined);
      const target = elementTreeFind(tree, (n) => n.id === folder.folderId);
      if (!target || !isFolder(target)) return;

      moveToFolder(nodes, target, updateProjectElementsFolder);
      Mixpanel.track_old("Add feature to ProjectElement folder", {
        numberFeatures: nodes.length,
      });
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    },
    [setCurrentSelectionArray, tree, updateProjectElementsFolder],
  );

  dropRef(elementRef);
  dragRef(topRowRef);

  return (
    <ProjectElementFolderWrapper
      id={generateSafeFolderId(folder.folderId)}
      ref={elementRef}
      isDragging={dragCollection.isDragging}
      enableDrag={editorAccessProject && !isReadOnly && !disableDrag}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredMiddle={dropCollection.isHovered && hoverState === "middle"}
      isDisabled={folder.isTemporary}
      depth={depth}
    >
      <ProjectElementFolderTopRow
        clickable
        hoverable
        ref={topRowRef}
        allFeaturesVisible={allFeaturesAreVisible}
        allFeaturesSelected={isSelected}
        onClick={onFolderClick}
        onMouseDown={(e) => {
          if (
            !currentSelectionArray ||
            currentSelectionArray.length === 0 ||
            !deselectAllFeatures ||
            platformCtrlOrCommand(e) ||
            e.shiftKey ||
            isSelected
          ) {
            return;
          }

          deselectAllFeatures();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          dotMenuRef.current?.setIsOpen(true);
        }}
        depth={depth}
      >
        <DnDIconWrapper>
          <HideIfNotHoverOrVisible>
            <DnDIconSmall />
          </HideIfNotHoverOrVisible>
        </DnDIconWrapper>
        <FlexCenterAligned>
          <ExpandArrowWrapper open={isOpen}>
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          <IconREMSize height={2} width={2}>
            {isOpen ? (
              <FolderMenuOpen
                style={{
                  marginRight: "0.5rem",
                }}
              />
            ) : (
              <FolderMenuClosed
                style={{
                  marginRight: "0.5rem",
                }}
              />
            )}
          </IconREMSize>
          <EditableText
            type="text"
            disabled={!editorAccessProject || isReadOnly}
            onEnter={onChangeNameSubmit}
            onCancel={onChangeNameSubmit}
            onChange={onWrittenNameChange}
            onEditChange={setDisableDrag}
            value={writtenName}
          >
            <Ui13RegularOverflow title={writtenName}>
              {writtenName}
            </Ui13RegularOverflow>
          </EditableText>
        </FlexCenterAligned>
        <FlexCenterAligned>
          <HideIfNotHoverOrVisible
            className={!allFeaturesAreVisible ? "visible" : undefined}
          >
            <IconREMSize
              title="Toggle feature visibility"
              height={1.5}
              width={1.5}
              onClick={(e) => {
                e.stopPropagation();
                toggleFeaturesHidden(featureIdsRecursive, true);
              }}
            >
              {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>

          {isDownloading[folder.folderId] || folder.isTemporary ? (
            <Spinner
              style={{
                width: "1rem",
                height: "1rem",
                margin: "0 1rem",
              }}
            />
          ) : (
            <HideIfNotHoverOrVisible>
              <DotMenu ref={dotMenuRef}>
                {childFeatures.length > 0 && (
                  <MenuItem name="Download" icon={<DownloadIcon />}>
                    <MenuItem
                      name={featuresToDownloadText(childFeatures, ".shp")}
                      icon={<DownloadIcon />}
                      onClick={() =>
                        downloadMultipleFeaturesShape(
                          childFeaturesRecursive,
                          `${folder.folderName}`,
                          folder.folderId,
                        )
                      }
                    />
                    <MenuItem
                      name={featuresToDownloadText(childFeatures, ".geojson")}
                      icon={<DownloadIcon />}
                      onClick={() =>
                        downloadMultipleFeaturesGeojson(
                          childFeaturesRecursive,
                          `${folder.folderName}`,
                          folder.folderId,
                        )
                      }
                    />
                    <MenuItem
                      name={featuresToDownloadText(childFeatures, ".kml")}
                      icon={<DownloadIcon />}
                      onClick={() =>
                        downloadMultipleFeaturesKML(
                          childFeaturesRecursive,
                          `${folder.folderName}`,
                          folder.folderId,
                        )
                      }
                    />
                    <MenuItem
                      name={featuresToDownloadText(childFeatures, ".dxf")}
                      icon={<DownloadIcon />}
                      onClick={() =>
                        downloadMultipleFeaturesDXF(
                          childFeaturesRecursive,
                          `${folder.folderName}`,
                          folder.folderId,
                        )
                      }
                    />
                    {childFeatures.some(isPointFeature) && (
                      <MenuItem
                        name={".csv (Points only)"}
                        icon={<DownloadIcon />}
                        onClick={() =>
                          downloadMultipleFeaturesCSV(
                            childFeaturesRecursive,
                            `${folder.folderName}`,
                            folder.folderId,
                          )
                        }
                      />
                    )}
                  </MenuItem>
                )}
                <MenuItem
                  name={allFeaturesAreSelected ? "Deselect" : "Select"}
                  icon={<SectionIcon />}
                  shortcut={`${stringToUnicode("command")} + Click`}
                  onClick={() => {
                    toggleFeaturesSelected([folderNode.id]);
                  }}
                />
                <MenuItem
                  name={allFeaturesAreSelected ? "Deselect all" : "Select all"}
                  icon={<SectionIcon />}
                  onClick={() => {
                    toggleFeaturesSelected(featureIdsRecursive);
                  }}
                />
                {selectedParks.length > 0 && (
                  <MenuItem
                    name={"Compare selected parks"}
                    icon={<CompareIcon />}
                    onClick={() => {
                      if (!branchId) {
                        return;
                      }

                      setSelectedCompareParks(
                        selectedParks.map((selectedPark) => ({
                          parkId: selectedPark.id,
                          branchId,
                          comparisonId: uuid(),
                        })),
                      );
                      setModalTypeOpen({
                        modalType: CompareParksModalType,
                      });
                    }}
                  />
                )}
                {editorAccessProject && !isReadOnly && (
                  <MenuItem
                    name="Delete"
                    icon={<BinIcon />}
                    disabled={folderNode.children.length !== 0}
                    title={
                      folderNode.children.length === 0
                        ? undefined
                        : "Only empty folders can be deleted"
                    }
                    onClick={() => removeProjectElementsFolder(folder.folderId)}
                  />
                )}
              </DotMenu>
            </HideIfNotHoverOrVisible>
          )}
        </FlexCenterAligned>
      </ProjectElementFolderTopRow>

      {isOpen && (
        <>
          {folderNode.children.length === 0 && (
            <ProjectElementEmptyFolderItemWrapper depth={depth + 1}>
              <span>Folder is empty</span>
            </ProjectElementEmptyFolderItemWrapper>
          )}
          {folderNode.children.map((node) => {
            if (node.type === "folder")
              return (
                <ProjectFolder
                  key={node.id}
                  folderNode={node}
                  defaultOpen={false}
                  onDeleteFeatures={onDeleteFeatures}
                  depth={depth + 1}
                />
              );
            if (isPark(node.feature))
              return (
                <ParkElement
                  node={node}
                  key={node.id}
                  park={node.feature}
                  folder={folder}
                  onDeleteFeatures={onDeleteFeatures}
                  depth={depth + 1}
                />
              );
            return (
              <ProjectFeatureItem
                node={node}
                key={node.id}
                name={node.feature.properties.name ?? ""}
                feature={node.feature}
                folder={folder}
                draggable={true}
                isSelected={Boolean(currentSelectionArray?.includes(node.id))}
                onDeleteFeatures={onDeleteFeatures}
                depth={depth + 1}
                onAddToFolder={onAddFeatureToFolder}
              />
            );
          })}
        </>
      )}
    </ProjectElementFolderWrapper>
  );
};

export default ProjectFolder;
