import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import ModelLCOEOutcomes from "assets/Yield_Compare cost.mov";
import SlideShowPlaceholder from "assets/slideshow-placeholder.jpg";

const Step1 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Optimise</TopHeaderAccent>
          <SubHeader>Maximise energy output</SubHeader>
          <ContentText>
            Identify wind farm layouts that deliver the highest energy yield
            based on your site’s unique conditions.
          </ContentText>
          <ContentText>
            Get optimised layouts in minutes, and easily explore various design
            options. Pinpoint and reduce energy losses, and get detailed
            insights into local wind conditions and wake effects.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

const Step2 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 2</TopHeaderAccent>
          <SubHeader>Evaluate new sites and layouts - in minutes</SubHeader>
          <ContentText>
            Explore new areas and designs quickly using pre-integrated datasets
            and powerful analysis tools. Test and compare different built-in
            wake settings and wind data, or upload your own calibrated wind time
            series to refine the analysis.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="left"
    />
  );
};

const Step3 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Compare</TopHeaderAccent>
          <SubHeader>Model LCoE outcomes</SubHeader>
          <ContentText>
            Test multiple yield scenarios to understand the financial viability
            of your project under different market and environmental conditions.
          </ContentText>
        </>
      }
      image={
        <video
          autoPlay
          loop
          muted
          style={{
            width: "80%",
          }}
        >
          <source src={ModelLCOEOutcomes} />
        </video>
      }
      imagePosition="center"
    />
  );
};

const Step4Offshore = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 4</TopHeaderAccent>
          <SubHeader>Access trusted metocean data instantly</SubHeader>
          <ContentText>
            Start your analysis with ready-to-use datasets like ERA5, NORA3,
            CERRA and GWA to study seasonal and long-term variability and gain
            accurate insights for any location worldwide.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

const Step4Onshore = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 4</TopHeaderAccent>
          <SubHeader>Access trusted wind data instantly</SubHeader>
          <ContentText>
            Start your analysis with ready-to-use datasets like ERA5, NORA3,
            CERRA and GWA to study seasonal and long-term variability and gain
            accurate insights for any location worldwide.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

export const getYieldSteps = (
  isOffshore: boolean,
): Array<React.ElementType<{ animation: SliderAnimation }>> => {
  return [Step1, Step2, Step3, isOffshore ? Step4Offshore : Step4Onshore];
};
