import AnimatedLoading from "@icons/AnimatedLoading/AnimatedLoading";
import { Row } from "components/General/Layout";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import SuccessIcon from "@icons/24/Success.svg";

export const createLoadingMessage = (text: string) => (
  <Row alignCenter>
    <AnimatedLoading
      $size="1.6rem"
      $baseColor={colors.white}
      $highlightColor={colors.grey700}
    />
    <p
      style={{
        color: colors.white,
      }}
    >
      {text}
    </p>
  </Row>
);

export const createSuccessMessage = (text: string) => (
  <Row alignCenter>
    <IconREMSize height={1.6} width={1.6} fill={colors.white}>
      <SuccessIcon />
    </IconREMSize>
    <p style={{ color: colors.white }}>{text}</p>
  </Row>
);
