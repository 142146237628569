import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  getExternalDataSourcesSelector,
  getAllNonDeletedLayersSelector,
  isHostedLayer,
  getAllPrivateSourcesSelector,
} from "state/layer";
import { LayerWithSearchRelevance, SourceTypesLayer } from "types/layers";
import { ProjectFeature } from "types/feature";
import { isWfsLayer } from "state/wfs";
import { isWMSLayer } from "state/wms";
import { isArcgisLayer } from "state/arcgisRestAPI";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import {
  bboxOverlaps,
  getFeatureLayersDataSelectorFamily,
  getTileJSONLayersOverlapSelectorFamily,
  getTileJSONMetadataLayersWithinBBOX,
  getWMSLayersDataSelectorFamily,
  TileJSONLayerWithMetadata,
} from "./overlap/overlap-selectors";
import {
  filterByCountryWeighted,
  filterByDebugMissingCountriesWeighted,
  filterByDebugMissingTagsWeighted,
  filterBySearchWeighted,
  filterBySourceTypeWeighted,
  filterBySourceWeighted,
  filterByTagsWeighted,
  getUniqueCountries,
  getUniqueSortedTags,
  getUniqueSourceNames,
} from "./layer-filter-utils";
import { atomWithReset } from "jotai/utils";

interface AvailableLayerFilterState {
  source: string[];
  tags: string[];
  country: string[];
  sourceTypes: string[];
}

export type LayerFilterState = {
  source: string[];
  tags: string[];
  sourceTypes: string[];
  overlappingGeometry?: ProjectFeature["geometry"];
  debug_missing_tags: boolean;
  debug_missing_countries: boolean;
  country: string[];
  searchString: string;
  isLoading?: boolean;
};

export type FilterType = keyof AvailableLayerFilterState;

export const availableLayerListFiltersAtom = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomFromFn<Promise<AvailableLayerFilterState>>(async (get) => {
      const layers = await get(
        getAllNonDeletedLayersSelector({
          projectId,
        }),
      );
      const activeFilters = get(activeFiltersAtom);

      const sources = await get(getExternalDataSourcesSelector);
      const privateSources = await get(
        getAllPrivateSourcesSelector({
          projectId,
        }),
      );
      const countries = getUniqueCountries(sources);

      // We only want to return filters that are relevant after country filter
      const filteredLayersOnlyOnCountry = layers.filter((layer) => {
        const filterResult = filterByCountryWeighted(
          layer.source,
          activeFilters,
        );
        return !filterResult.active || filterResult.match;
      });

      const filteredSourceNames = getUniqueSourceNames(
        sources
          .concat(privateSources)
          .filter((source) =>
            filteredLayersOnlyOnCountry.some(
              (layer) => layer.source && layer.source.id === source.id,
            ),
          ),
      );

      const filteredTags = getUniqueSortedTags(filteredLayersOnlyOnCountry);
      const filteredSourceTypes = Array.from(
        new Set(filteredLayersOnlyOnCountry.map((layer) => layer.sourceType)),
      ).filter(Boolean);

      return {
        country: countries,
        source: filteredSourceNames,
        tags: filteredTags,
        sourceTypes: filteredSourceTypes,
      };
    }),
);

export const activeFiltersAtom = atomWithReset<LayerFilterState>({
  source: [],
  tags: [],
  sourceTypes: [],
  debug_missing_tags: false,
  debug_missing_countries: false,
  overlappingGeometry: undefined,
  country: [],
  searchString: "",
  isLoading: false,
});

export const nrActiveFiltersSelector = atom<number>((get) => {
  const filters = get(activeFiltersAtom);
  return Object.values(filters)
    .filter(
      (val) => Array.isArray(val) || typeof val === "object" || val === true,
    )
    .flat().length;
});

export const filteredLayerListSelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<LayerWithSearchRelevance[]>>(async (get) => {
      const layers = await get(
        getAllNonDeletedLayersSelector({
          projectId,
        }),
      );

      const activeFilters = get(activeFiltersAtom);

      const result = layers.reduce<LayerWithSearchRelevance[]>((acc, layer) => {
        const searchResults = [
          filterBySourceWeighted(layer, activeFilters),
          filterByTagsWeighted(layer.tags, activeFilters),
          filterBySourceTypeWeighted(
            layer.sourceType as SourceTypesLayer,
            activeFilters,
          ),
          filterBySearchWeighted(layer, activeFilters),
          filterByCountryWeighted(layer.source, activeFilters),
          filterByDebugMissingTagsWeighted(layer.tags, activeFilters),
          filterByDebugMissingCountriesWeighted(layer.source, activeFilters),
        ];

        if (!searchResults.every((result) => !result.active || result.match)) {
          return acc;
        }

        const collectiveWeight = searchResults.reduce((accWeight, curr) => {
          if (curr.active && curr.match) {
            return accWeight + curr.weight;
          }
          return accWeight;
        }, 0);

        acc.push({
          ...layer,
          searchRelevance: collectiveWeight,
        });
        return acc;
      }, []);

      return result;
    }),
);

interface LayerGroups {
  featureLayerIds: string[];
  wmsLayerIds: string[];
  tileJSONLayers: TileJSONLayerWithMetadata[];
}

interface OverlapState {
  layers: LayerWithSearchRelevance[];
  loadingState: {
    isLoading: boolean;
    maxLayers: number;
    completedCount: number;
  } | null;
}

interface LoadableSuccess<T> {
  state: "hasData";
  data: T;
}

interface LoadableLoading {
  state: "loading";
}

interface LoadableError {
  state: "hasError";
  error: unknown;
}

type LoadableState<T> = LoadableSuccess<T> | LoadableLoading | LoadableError;

interface LayerResult {
  result: boolean;
  layer: LayerWithSearchRelevance;
}

interface LayerProcessingResult {
  completedLayers: LayerWithSearchRelevance[];
  loadingCount: number;
  completedCount: number;
}

// Helper function to process layer results
const processLayerResults = (
  results: LoadableState<LayerResult>[],
): LayerProcessingResult => {
  const completedLayers = results.flatMap((result) =>
    result.state === "hasData" && result.data.result ? [result.data.layer] : [],
  );

  return {
    completedLayers,
    loadingCount: results.filter((result) => result.state === "loading").length,
    completedCount: results.filter((result) => result.state === "hasData")
      .length,
  };
};

// Helper function to get layer groups
const getLayerGroups = (
  filteredLayers: LayerWithSearchRelevance[],
  filterBbox: number[],
): LayerGroups => {
  return filteredLayers.reduce<LayerGroups>(
    (acc, layer) => {
      if (
        (isHostedLayer(layer) || isWfsLayer(layer) || isArcgisLayer(layer)) &&
        layer.bbox &&
        bboxOverlaps(filterBbox, layer.bbox)
      ) {
        return {
          ...acc,
          featureLayerIds: [...acc.featureLayerIds, layer.id],
        };
      } else if (
        isWMSLayer(layer) &&
        (!layer.bbox || bboxOverlaps(filterBbox, layer.bbox))
      ) {
        return {
          ...acc,
          wmsLayerIds: [...acc.wmsLayerIds, layer.id],
        };
      }
      return acc;
    },
    { featureLayerIds: [], wmsLayerIds: [], tileJSONLayers: [] },
  );
};

export const filteredLayerListWithOverlapSelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<OverlapState>>(async (get) => {
      const filteredLayers = await get(
        filteredLayerListSelector({
          projectId,
        }),
      );
      const filters = get(activeFiltersAtom);

      if (!filters.overlappingGeometry) {
        return {
          layers: filteredLayers,
          loadingState: null,
        };
      }

      const filterBbox = getBBOXArrayFromFeatures([
        {
          geometry: filters.overlappingGeometry,
        },
      ]);

      const [{ featureLayerIds, wmsLayerIds }, tileJSONLayers] =
        await Promise.all([
          getLayerGroups(filteredLayers, filterBbox),
          get(
            getTileJSONMetadataLayersWithinBBOX({
              bbox: filterBbox,
              projectId,
            }),
          ) as Promise<TileJSONLayerWithMetadata[]>,
        ]);

      const maxNumberLayers =
        featureLayerIds.length + wmsLayerIds.length + tileJSONLayers.length;

      const [featureLayers, wmsLayers, tileJSONLayersOverlap] =
        await Promise.all([
          get(
            getFeatureLayersDataSelectorFamily({
              layerIds: featureLayerIds,
              geometry: filters.overlappingGeometry,
              projectId,
              bbox: filterBbox,
            }),
          ) as Promise<LoadableState<LayerResult>[]>,
          get(
            getWMSLayersDataSelectorFamily({
              layerIds: wmsLayerIds,
              projectId,
              bbox: filterBbox,
            }),
          ) as Promise<LoadableState<LayerResult>[]>,
          get(
            getTileJSONLayersOverlapSelectorFamily({
              tileJSONLayers,
              geometry: filters.overlappingGeometry,
            }),
          ) as Promise<LoadableState<LayerResult>[]>,
        ]);

      const featureResults = processLayerResults(featureLayers);
      const wmsResults = processLayerResults(wmsLayers);
      const tileJSONResults = processLayerResults(tileJSONLayersOverlap);

      const allCompletedLayers = [
        ...featureResults.completedLayers,
        ...wmsResults.completedLayers,
        ...tileJSONResults.completedLayers,
      ];

      const isLoading =
        featureResults.loadingCount > 0 ||
        wmsResults.loadingCount > 0 ||
        tileJSONResults.loadingCount > 0;

      const completedCount =
        featureResults.completedCount +
        wmsResults.completedCount +
        tileJSONResults.completedCount;

      return {
        layers: allCompletedLayers,
        loadingState: {
          isLoading,
          maxLayers: maxNumberLayers,
          completedCount,
        },
      };
    }),
);

type SortConfig = {
  key: "name" | "searchRelevance";
  direction: "asc" | "desc";
};

export const layerSortConfigAtom = atom<SortConfig>({
  key: "searchRelevance",
  direction: "desc",
});
