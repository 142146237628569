import { atom } from "jotai";

export enum RightModalNames {
  Changelog = "Changelog",
  FeatureHistory = "FeatureHistory",
  ArchiveProject = "ArchiveProject",
}

export const rightModalOpenStateAtom = atom<RightModalNames | undefined>(
  undefined,
);
