import React from "react";
import { Column, Row } from "components/General/Layout";
import CloseIcon from "@icons/24/Close.svg";
import EarthIcon from "@icons/24/Earth.svg";
import WindTurbineIcon from "@icons/24/WindTurbine.svg";
import { SVGWrapper } from "@icons/svgUtils";
import { useClickOutside } from "hooks/useClickOutside";
import {
  DescriptionContainer,
  EntryContainer,
  Label,
  ModalContainer,
  No,
  SecondaryText,
  SectionHeader,
  StyledLink,
  Text,
  Yes,
} from "./shared";
import styled from "styled-components";

// first child flex 3, rest flex 2
const LibrarySpecificRow = styled(Row)`
  ${EntryContainer}:first-child {
    flex: 3;
  }
  ${EntryContainer}:not(:first-child) {
    flex: 2;
  }
`;

export default function LibraryAccessOverviewModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  return (
    <ModalContainer ref={ref}>
      <Column style={{ gap: "0.8rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>Library access</h3>
          <SVGWrapper
            size={1.4}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </SVGWrapper>
        </Row>
        <Text>
          Full access allows the user(s) to create, edit, see all details and
          assign resources within the selected category to projects.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={
              "https://help.vindtech.com/en/articles/8842345-how-to-build-up-your-organisation-library"
            }
          >
            Read more
          </StyledLink>
        </Text>
      </Column>
      <Column style={{ gap: "1.2rem" }}>
        <LibrarySpecificRow>
          <DescriptionContainer>
            <div />
          </DescriptionContainer>
          <EntryContainer>
            <Label>Full library access</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>No library access</Label>
          </EntryContainer>
        </LibrarySpecificRow>
        <SectionHeader
          style={{ marginTop: 0 }}
          icon={<EarthIcon />}
          text="General"
        />
        <LibrarySpecificRow>
          <DescriptionContainer>
            <Text>Create library resources</Text>
          </DescriptionContainer>
          <Yes />
          <No />
        </LibrarySpecificRow>
        <LibrarySpecificRow>
          <DescriptionContainer>
            <Text>Edit library resources</Text>
          </DescriptionContainer>
          <Yes />
          <No />
        </LibrarySpecificRow>
        <LibrarySpecificRow>
          <DescriptionContainer>
            <Text>Assign resources to projects</Text>
          </DescriptionContainer>
          <Yes />
          <No />
        </LibrarySpecificRow>
        <LibrarySpecificRow>
          <DescriptionContainer>
            <Text>See limited information *</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
        </LibrarySpecificRow>
        <SectionHeader icon={<WindTurbineIcon />} text="Library turbines" />
        <LibrarySpecificRow>
          <DescriptionContainer>
            <Text>See power and thrust curves</Text>
          </DescriptionContainer>
          <Yes />
          <No />
        </LibrarySpecificRow>

        <Row />
        <Row>
          <SecondaryText>
            * If a library resource is assigned to a project, the project
            members can use the resources and see all information except power
            and thrust curves on turbines.
          </SecondaryText>
        </Row>
      </Column>
    </ModalContainer>
  );
}
