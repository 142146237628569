import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { CirclePaint } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { ExistingTurbineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_EXISTING_TURBINE_VISIBLE_ZOOM,
  editmodePropertyName,
  existingTurbineLayerId,
  existingTurbineSourceId,
  existingTurbineSymbolLayerId,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { READ, caseexpr } from "./defaults";
import { atom, useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const existingTurbinePaintAtom = atom<CirclePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "circle-radius": defaultPointCircleRadius,
    "circle-color": [
      "case",
      ["!=", ["typeof", ["get", "power"]], "number"],
      colors.existingTurbineIncomplete,
      ["==", ["get", editmodePropertyName], true],
      colors.anchorPreview,
      ["boolean", ["feature-state", "error"], false],
      colors.existingTurbineIncomplete,
      colors.existingTurbine,
    ],
    "circle-opacity": [
      "case",
      ["boolean", ["feature-state", "error"], false],
      1.0,
      ["==", ["get", editmodePropertyName], true],
      1.0,
      1.0,
    ],
    "circle-stroke-color": [
      "case",
      ["!=", ["typeof", ["get", "power"]], "number"],
      caseexpr({
        selected: onshore
          ? colors.existingTurbineIncompleteBorderSel
          : colors.white,
        fallback: colors.existingTurbineIncomplete,
      }),
      ["==", ["get", editmodePropertyName], true],
      colors.lightText,
      ["==", ["get", lockedPropertyName], true],
      colors.lockedFeatureOutline,
      caseexpr({
        fallback: colors.existingTurbineBorder,
        selected: onshore ? colors.existingTurbineBorderSel : colors.white,
        state: { borderColor: READ },
      }),
    ],
    "circle-stroke-width": [
      "case",
      ["==", ["get", editmodePropertyName], true],
      3,
      ["==", ["get", lockedPropertyName], true],
      2,
      ["!=", ["feature-state", "borderColor"], null],
      2,
      caseexpr({
        fallback: 0,
        hover: 2,
        selected: 2,
      }),
    ],
  };
});

export const RenderExistingTurbines = ({
  turbines,
  map,
  showSelectionHighlighter,
}: {
  turbines: ExistingTurbineFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const existingTurbinePaint = useAtomValue(existingTurbinePaintAtom);
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);
  const featureIds = useMemo(() => turbines.map((f) => f.id), [turbines]);

  useEffect(() => {
    map.addSource(existingTurbineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, existingTurbineLayerId);
      safeRemoveLayer(map, existingTurbineSymbolLayerId);
      map.removeSource(existingTurbineSourceId);
    };
  }, [map, existingTurbinePaint]);

  useEffect(() => {
    addLayer(map, {
      id: existingTurbineLayerId,
      type: "circle",
      source: existingTurbineSourceId,
      paint: existingTurbinePaint,
      minzoom: MIN_EXISTING_TURBINE_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: existingTurbineSymbolLayerId,
      source: existingTurbineSourceId,
      type: "symbol",
      minzoom: 11.5,
      layout: {
        "text-field": [
          "case",
          ["==", ["typeof", ["get", "power"]], "number"],
          ["concat", ["get", "power"], "MW"],
          "Unknown",
        ],
        "text-offset": [0, 1],
        "text-size": 9,
        "text-anchor": "top",
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
      paint: defaultMapTextPaint,
    });
  }, [map, existingTurbinePaint, defaultMapTextPaint]);

  useEffect(() => {
    const source = map.getSource(existingTurbineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(turbines),
    });
  }, [map, turbines]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={existingTurbineSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
