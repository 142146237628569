import { organisationIdAtom } from "state/pathParams";
import Changelog from "@icons/24/Changelog.svg?react";
import { Suspense, useMemo, useRef, useState } from "react";
import { Column } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { InputChangelogInfo } from "./types";
import { Anchor } from "components/General/Anchor";
import { Mixpanel } from "mixpanel";
import { inputChangelogsAtomFamily } from "./state";
import { CommentDot, IconContainer } from "./style";
import { ChangelogPopup } from "./ChangelogPopup";
import { ExistingChangelogContent } from "./ExistingChangelogContent";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { findChanges } from "./utils";
import { colors } from "styles/colors";
import { useAtomValue } from "jotai";
import { IconREMSize } from "styles/typography";
import { useUserLibraryAccessState } from "components/Projects/useUserLibraryAccessState";

const InputChangelog = ErrorBoundaryWrapper(
  ({
    changelogInfo,
    size = "1.6rem",
    showBtn,
    disabled,
    suffix,
    scaleFactor = 1,
    decimals,
  }: {
    changelogInfo: InputChangelogInfo;
    size?: string;
    showBtn?: boolean;
    disabled?: boolean;
    suffix?: string;
    scaleFactor?: number;
    decimals?: number;
  }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const { data: orgLibraryResourceAccess } = useUserLibraryAccessState({
      organisationId,
    });

    if (
      changelogInfo.category !== "project" &&
      !orgLibraryResourceAccess.some(
        (ra) => ra.resource_name === changelogInfo.category,
      )
    )
      return null;

    return (
      <Suspense fallback={<LoadingIcon size={size} showBtn={!!showBtn} />}>
        <InputChangelogInner
          changelogInfo={changelogInfo}
          size={size}
          showBtn={showBtn}
          disabled={disabled}
          suffix={suffix}
          scaleFactor={scaleFactor}
          decimals={decimals}
        />
      </Suspense>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

function InputChangelogInner({
  changelogInfo,
  size = "1.6rem",
  showBtn,
  disabled,
  suffix,
  scaleFactor,
  decimals,
}: {
  changelogInfo: InputChangelogInfo;
  size?: string;
  showBtn?: boolean;
  disabled?: boolean;
  suffix?: string;
  scaleFactor: number;
  decimals?: number;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const organisationId = useAtomValue(organisationIdAtom);
  const changeLog = useAtomValue(
    inputChangelogsAtomFamily({
      nodeId: changelogInfo.nodeId,
      changelogId: changelogInfo.changelogId,
      category: changelogInfo.category,
      organisationId,
    }),
  );

  const [showInputChangelog, setShowInputChangelog] = useState(false);

  const changes = useMemo(() => {
    return findChanges(
      changeLog,
      changelogInfo.changelogEntryKeyId,
      changelogInfo.getValue,
      scaleFactor,
      decimals,
    ).sort((a, b) => b.version - a.version);
  }, [
    changeLog,
    changelogInfo.changelogEntryKeyId,
    changelogInfo.getValue,
    decimals,
    scaleFactor,
  ]);

  if (!changes || changes.length === 0 || !changeLog) {
    return null;
  }

  const [latestChange] = changes;

  return (
    <Column
      ref={ref}
      style={{
        cursor: "pointer",
      }}
    >
      <ChangelogIcon
        size={size}
        hasComment={!!latestChange.comment}
        showBtn={!!showBtn}
        onClick={() => {
          if (!showInputChangelog) {
            Mixpanel.track_old("View changelog", {
              nodeId: changelogInfo.nodeId,
              category: changelogInfo.changelogId,
            });
          }
          setShowInputChangelog(!showInputChangelog);
        }}
        showInputChangelog={showInputChangelog}
      />
      {showInputChangelog && (
        <Anchor baseRef={ref} basePlace="bottomLeft" floatPlace="topLeft">
          <ChangelogPopup close={() => setShowInputChangelog(false)}>
            <ExistingChangelogContent
              changelogInfo={changelogInfo}
              changes={changes}
              suffix={suffix}
              disabled={disabled}
            />
          </ChangelogPopup>
        </Anchor>
      )}
    </Column>
  );
}

const LoadingIcon = ({ size, showBtn }: { size: string; showBtn: boolean }) => (
  <IconContainer size={size}>
    <IconBtn
      style={{
        padding: "0.35rem",
        opacity: showBtn ? 1 : 0,
        transition: "all 0.3s",
      }}
      tabIndex={-1}
      size={showBtn ? size : "0.4rem"}
      pathStrokeWidth={0.1}
      onClick={() => {}}
    >
      <Changelog />
    </IconBtn>
  </IconContainer>
);

const ChangelogIcon = ({
  size,
  showBtn,
  hasComment,
  onClick,
  showInputChangelog,
}: {
  size: string;
  showBtn: boolean;
  hasComment: boolean;
  onClick: () => void;
  showInputChangelog?: boolean;
}) => (
  <div
    style={{
      position: "relative",
    }}
  >
    <IconContainer
      size={size}
      style={{
        transition: "all 0.3s",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {(showBtn || showInputChangelog) && (
        <IconBtn
          style={{
            padding: "0.35rem",
            position: "absolute",
            top: "-0.4rem",
            right: "-0.4rem",
          }}
          backgroundColor={
            showInputChangelog ? colors.surfaceSelectedLight : "white"
          }
          tabIndex={-1}
          size={size}
          pathStrokeWidth={0.6}
          iconColor={colors.iconDefault}
        >
          <Changelog />
        </IconBtn>
      )}

      {hasComment &&
        (showBtn || showInputChangelog ? (
          <IconREMSize
            style={{
              position: "absolute",
              opacity: 1,
              top: "-0.1rem",
              right: "-0.1rem",
              transition: "all 0.3s",
            }}
            height={1.4}
            width={1.4}
          >
            <CommentDot />
          </IconREMSize>
        ) : (
          <CommentDot
            style={{
              opacity: "0.5",
            }}
          />
        ))}
    </IconContainer>
  </div>
);

export default InputChangelog;
