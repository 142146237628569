import { ReactNode, useCallback, useMemo } from "react";
import { useToast } from "../../../hooks/useToast";
import DownloadIcon from "@icons/24/Download.svg?react";
import { TurbineFeature } from "../../../types/feature";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { FoundationType } from "../../../types/foundations";
import { Raster } from "../../../types/raster";
import { SimpleTurbineType } from "../../../types/turbines";
import {
  isDefined,
  isDetailedJacket,
  isDetailedMonopile,
  isFloater,
} from "../../../utils/predicates";
import { downloadText, roundToDecimal, undefMap } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import { pointInPolygon } from "utils/geometry";
import { TileResponseWithRaster } from "types/bathymetryTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";
import { useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { turbinesInParkByLabelFamily } from "state/jotai/turbine";
import {
  FloatingFoundationScaledStatistics,
  foundationTypesAtom,
  getAllScaledStatisticsForFloatingFoundationsInParkFamily,
  getDetailedJacketMassesFamily,
  getDetailedMonopileMassesFamily,
  getSimpleFixedMassesFamily,
} from "state/jotai/foundation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import {
  TurbineFeatureWithFoundation,
  isTurbineFeatureWithFoundation,
} from "state/foundations";
import {
  JacketDesign,
  MonopileDesign,
} from "components/RightSide/InfoModal/FoundationModal/fixed/state";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { bathymetryWithRasterFamily } from "state/bathymetry";

const getDepth = (turbine: TurbineFeature, raster: Raster): number => {
  const [lon, lat] = turbine.geometry.coordinates;

  return raster.latLngToValue(lon, lat);
};

/**
 * Source for the data we're showing. The same hook is used to show the data in
 * the table, as well as for downloading the .csv.
 */
const useFoundationData = () => {
  const { park, branch, parkBathymetryId } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const bath = useAtomValue(bathymetryWithRasterFamily(parkBathymetryId));

  const hasCustomBathymetry = bath.usedCustomBathymetry.length > 0;

  const getData = useJotaiCallback(
    async (get) => {
      if (!bath || bath.status !== "finished") return;

      const { raster } = bath;
      const rasterId = bath.id;

      const turbines: TurbineFeature[] = await get(
        turbinesInParkByLabelFamily({ parkId, branchId }),
      );
      const validTurbines = turbines.filter((t) =>
        pointInPolygon(t.geometry, park.geometry),
      );

      const foundations = await get(foundationTypesAtom);

      const turbinesWithFoundation = validTurbines.filter(
        isTurbineFeatureWithFoundation,
      );

      const floatingFoundationScaledStatistics = await get(
        getAllScaledStatisticsForFloatingFoundationsInParkFamily(parkId),
      );

      const turbinesWithDetailedMonopile = turbinesWithFoundation.filter(
        (t) =>
          foundations.get(t.properties.foundationId ?? "")?.type ===
          "detailed_monopile",
      );
      const turbinesWithDetailedJacket = turbinesWithFoundation.filter(
        (t) =>
          foundations.get(t.properties.foundationId ?? "")?.type ===
          "detailed_jacket",
      );
      const turbinesWithSimpleFixed = turbinesWithFoundation.filter((t) => {
        const foundationType = foundations.get(
          t.properties.foundationId ?? "",
        )?.type;
        return foundationType === "monopile" || foundationType === "jacket";
      });

      const monopileStats = await get(
        getDetailedMonopileMassesFamily({
          turbinesWithDetailedMonopile,
          rasterId,
        }),
      );

      const jacketStats = await get(
        getDetailedJacketMassesFamily({
          turbinesWithDetailedJacket,
          rasterId,
        }),
      );

      const { foundationMasses } = await get(
        getSimpleFixedMassesFamily({
          turbinesWithSimpleFixed,
          rasterId,
        }),
      );

      const turbineTypes = await get(simpleTurbineTypesAtom);
      const depthPerTurbine = turbines.map((turbine) =>
        getDepth(turbine, raster),
      );
      const data = turbinesWithFoundation.map((turbine, i) => {
        const foundationId = turbine.properties.foundationId;
        const turbineTypeId = turbine.properties.turbineTypeId;
        const foundationWeight = isFloater(foundations.get(foundationId))
          ? getFloatingFoundationWeight(
              turbineTypeId,
              foundationId,
              floatingFoundationScaledStatistics,
            )
          : isDetailedMonopile(foundations.get(foundationId))
            ? getDetailedMonopileWeight(
                turbine.id,
                monopileStats.monopileDetails,
              )
            : isDetailedJacket(foundations.get(foundationId))
              ? getDetailedJacketWeight(turbine.id, jacketStats.jacketDetails)
              : getFixedSimpleWeight(turbine.id, foundationMasses);
        return {
          turbineName: turbine.properties.name ?? i + 1,
          turbineType: turbineTypes.get(turbineTypeId),
          foundation: foundationId ? foundations.get(foundationId) : undefined,
          foundationWeight: foundationWeight,
          depth: depthPerTurbine?.[i],
          longitude: turbine.geometry.coordinates[0],
          latitude: turbine.geometry.coordinates[1],
        };
      });
      return data;
    },
    [bath, parkId, branchId, park.geometry],
  );

  return { getData, hasCustomBathymetry };
};

/** Replaces the argument with a `Skeleton` if it is `undefined`.
 *
 * When we're requeting for production numbers, we immediately get back some
 * numbers, but not all. The ones that aren't ready yet are given as
 * `undefined`.  This is so that we can show as much data as possible eary on,
 * without having to wait for the analysis.
 *
 * This function just renders these values "correctly", so that the loading ones
 * are shown as a loader, and the ready ones are shown as-is.
 */
const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const FoundationDetails = () => {
  const { park, branch, parkBathymetryId } = useDashboardContext();
  const branchId = branch.id;
  const parkId = park.id;

  const turbines = useAtomValue(
    turbinesInParkByLabelFamily({ parkId, branchId }),
  );
  const validTurbines = turbines.filter((t) =>
    pointInPolygon(t.geometry, park.geometry),
  );

  const turbinesWithFoundation =
    validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const allFoundationTypes = useAtomValue(foundationTypesAtom);
  const bathymetry = useAtomValue(bathymetryWithRasterFamily(parkBathymetryId));

  return (
    <FoundationListDetailsInner
      parkId={parkId}
      turbinesWithFoundation={turbinesWithFoundation}
      turbineTypes={allTurbineTypes}
      foundationTypes={allFoundationTypes}
      bathymetry={bathymetry}
    />
  );
};

const getFloatingFoundationWeight = (
  turbineTypeId: string,
  foundationId: string,
  scaledStatistics: FloatingFoundationScaledStatistics[],
): number | undefined => {
  const currentStatistics = scaledStatistics.find(
    (s) => s.foundationId === foundationId && s.turbineTypeId === turbineTypeId,
  );

  return currentStatistics?.scaledConcreteWeight &&
    currentStatistics?.scaledConcreteWeight > 0
    ? currentStatistics.scaledConcreteWeight
    : currentStatistics?.scaledPrimarySteelWeight;
};

const getDetailedMonopileWeight = (
  turbineId: string,
  monopileDesigns: MonopileDesign[],
): number => {
  const currentStatistics = monopileDesigns.find(
    (s) => s.turbineId === turbineId,
  );

  return (currentStatistics?.pileMass ?? 0) / 1000;
};

const getDetailedJacketWeight = (
  turbineId: string,
  jacketDesigns: JacketDesign[],
): number => {
  const currentStatistics = jacketDesigns.find(
    (s) => s.turbineId === turbineId,
  );

  return (currentStatistics?.totalMass ?? 0) / 1000;
};

const getFixedSimpleWeight = (
  turbineId: string,
  foundationMasses:
    | {
        turbineId: string;
        turbineTypeId: string;
        foundationId: string;
        mass: number;
      }[]
    | undefined,
): number => {
  if (!foundationMasses) return 0;
  const currentStatistics = foundationMasses.find(
    (s) => s.turbineId === turbineId,
  );

  return (currentStatistics?.mass ?? 0) / 1000;
};

const FoundationListDetailsInner = ({
  parkId,
  turbinesWithFoundation,
  turbineTypes,
  foundationTypes,
  bathymetry,
}: {
  parkId: string;
  turbinesWithFoundation: TurbineFeatureWithFoundation[];
  turbineTypes: Map<string, SimpleTurbineType>;
  foundationTypes: Map<string, FoundationType>;
  bathymetry: TileResponseWithRaster;
}) => {
  const { branch } = useDashboardContext();
  const branchId = branch.id;
  const depthsPerTurbine = useMemo(() => {
    return turbinesWithFoundation.map((turbine) =>
      getDepth(turbine, bathymetry.raster),
    );
  }, [bathymetry, turbinesWithFoundation]);

  const floatingFoundationScaledStatistics = useAtomValue(
    getAllScaledStatisticsForFloatingFoundationsInParkFamily(parkId),
  );

  const rasterId = bathymetry.id;
  const turbinesWithDetailedMonopile = turbinesWithFoundation.filter((t) =>
    isDetailedMonopile(foundationTypes.get(t.properties.foundationId)),
  );
  const turbinesWithDetailedJacket = turbinesWithFoundation.filter((t) =>
    isDetailedJacket(foundationTypes.get(t.properties.foundationId)),
  );
  const turbinesWithSimpleFixed = turbinesWithFoundation.filter(
    (t) =>
      !turbinesWithDetailedMonopile.includes(t) &&
      !turbinesWithDetailedJacket.includes(t),
  );

  const monopileStats = useAtomValue(
    getDetailedMonopileMassesFamily({
      turbinesWithDetailedMonopile,
      rasterId,
    }),
  );

  const jacketStats = useAtomValue(
    getDetailedJacketMassesFamily({
      turbinesWithDetailedJacket,
      rasterId,
    }),
  );

  const { foundationMasses: fixedSimpleMasses } = useAtomValue(
    getSimpleFixedMassesFamily({
      turbinesWithSimpleFixed,
      rasterId,
    }),
  );
  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId, branchId }),
  );

  const items = useMemo(
    () =>
      turbinesWithFoundation.map((turbine, i) => {
        const { foundationId, turbineTypeId } = turbine.properties;
        const foundationWeight = isFloater(foundationTypes.get(foundationId))
          ? getFloatingFoundationWeight(
              turbineTypeId,
              foundationId,
              floatingFoundationScaledStatistics,
            )
          : isDetailedMonopile(foundationTypes.get(foundationId))
            ? getDetailedMonopileWeight(
                turbine.id,
                monopileStats.monopileDetails,
              )
            : isDetailedJacket(foundationTypes.get(foundationId))
              ? getDetailedJacketWeight(turbine.id, jacketStats.jacketDetails)
              : getFixedSimpleWeight(turbine.id, fixedSimpleMasses);
        return {
          turbine,
          turbineType: turbineTypes.get(turbineTypeId)?.name,
          foundation: foundationId
            ? foundationTypes.get(foundationId)?.name
            : "-",
          weight: undefMap(
            foundationWeight,
            (w) => `${valueRounding(w, 10).toLocaleString()} t`,
          ),
          depth: undefMap(
            depthsPerTurbine?.at(i),
            (d) => `${(-d).toFixed(0)} m`,
          ),
          longitude: turbine.geometry.coordinates[0].toFixed(4),
          latitude: turbine.geometry.coordinates[1].toFixed(4),
        };
      }),
    [
      turbinesWithFoundation,
      floatingFoundationScaledStatistics,
      monopileStats.monopileDetails,
      jacketStats.jacketDetails,
      fixedSimpleMasses,
      turbineTypes,
      foundationTypes,
      depthsPerTurbine,
    ],
  );

  if (items.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (invalidTypes.turbines) {
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some turbines in the park have invalid turbine types."}
        />
      </CenterContainer>
    );
  }

  if (invalidTypes.foundations) {
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some turbines in the park have invalid foundation types."}
        />
      </CenterContainer>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Foundation</th>
          <th>Turbine type</th>
          <th>Foundation weight</th>
          <th>Depth</th>
          <th>Longitude</th>
          <th>Latitude</th>
        </tr>
      </thead>
      <tbody>
        {items.map(
          ({
            turbine,
            turbineType,
            foundation,
            weight,
            depth,
            longitude,
            latitude,
          }) => (
            <tr key={turbine.id}>
              <td>{undefToSkeleton(foundation)}</td>
              <td>{undefToSkeleton(turbineType)}</td>
              <td>{undefToSkeleton(weight)}</td>
              <td>{undefToSkeleton(depth)}</td>
              <td>{undefToSkeleton(longitude)}</td>
              <td>{undefToSkeleton(latitude)}</td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const delimiter = ",";
const FoundationMenu = () => {
  const { error, warning } = useToast();
  const { hasCustomBathymetry, getData } = useFoundationData();
  const { park } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    if (!data) {
      error("Cannot download turbine data: missing bathymetry data.");
      return;
    }

    const depthDecimals = hasCustomBathymetry ? 1 : 0;

    const isLoading =
      data.find((d) => d.depth === undefined) ||
      data.find((d) => d.foundationWeight === undefined);

    if (isLoading) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const fields = [
      "turbineName",
      "turbineType",
      "foundation",
      "foundationWeight",
      "depth",
      "longitude",
      "latitude",
    ];

    const headerNames: Record<(typeof fields)[number], string> = {
      turbineName: "Turbine name",
      turbineType: "Turbine type",
      foundation: "Foundation",
      foundationWeight: "Foundation weight (t)",
      depth: "Depth (m)",
      longitude: "Longitude",
      latitude: "Latitude",
    };

    const formatted: Record<
      (typeof fields)[number],
      string | number | undefined
    >[] = data.map((d) => ({
      turbineName: `#${d.turbineName}`,
      turbineType: d.turbineType?.name ?? "-",
      foundation: d.foundation?.name,
      foundationWeight: undefMap(d.foundationWeight, (w) =>
        valueRounding(w, 10),
      ),
      depth: undefMap(d.depth, (d) => roundToDecimal(-d, depthDecimals)),
      longitude: d.longitude.toFixed(4),
      latitude: d.latitude.toFixed(4),
    }));

    let csv = "#";
    for (const f of fields.slice(1)) {
      csv += `${delimiter}${headerNames[f]}`;
    }

    for (const d of formatted) {
      csv += "\n" + String(d.turbineName).replace(delimiter, "");
      for (const f of fields.slice(1)) {
        csv += `${delimiter}${d[f]}`;
      }
    }

    downloadText(
      csv,
      `foundations-${park.properties.name}-${new Date().toISOString()}.csv`,
    );
  }, [error, getData, hasCustomBathymetry, park.properties.name, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => downloadCSV()}
      />
    </>
  );
};

export const FoundationListWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Foundations"
      id="Foundations"
      menuItems={<FoundationMenu />}
      resetKeys={errorBoundaryResetKeys}
    >
      <FoundationDetails />
    </SafeCard>
  );
};
