import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { substationsInParkFamily } from "state/jotai/substation";
import { ValidationWarning } from "state/validationWarnings";
import { cablesInParkFamily } from "state/jotai/cable";
import { turbinesInParkFamily } from "state/jotai/turbine";

/** Cable is not connected to a turbine or substation */
export const cableNotConnectedWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.CableNotConnected> | undefined
      >
    >(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      if (cables.length === 0) return;

      const featureIds = [...turbines, ...substations].map((f) => f.id);

      const disconnectedCables = cables.filter(
        (c) =>
          !featureIds.includes(c.properties.fromId) ||
          !featureIds.includes(c.properties.toId),
      );

      if (disconnectedCables.length === 0) return;
      return {
        type: ValidationWarningTypes.CableNotConnected,
        featureIds: disconnectedCables.map((c) => c.id),
        parkId: parkId,
      };
    }),
);
