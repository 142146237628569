import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { typography } from "styles/typography";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import CloseIcon from "@icons/24/Close.svg";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import { spacing7, spacing8 } from "styles/space";

type ConfirmOptions = {
  title: React.ReactNode;
  message: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

type ConfirmContextType = {
  showConfirm: (options: ConfirmOptions) => Promise<boolean>;
};

const ConfirmContext = createContext<undefined | ConfirmContextType>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }

  return context;
};

export const ConfirmDialogProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>("");
  const [message, setMessage] = useState<React.ReactNode>("");
  const [confirmButtonText, setConfirmButtonText] = useState<
    string | undefined
  >();
  const [cancelButtonText, setCancelButtonText] = useState<
    string | undefined
  >();
  const [resolveConfirm, setResolveConfirm] = useState<
    ((value: boolean) => void) | undefined
  >();

  const showConfirm = useCallback(
    ({
      title,
      message,
      confirmButtonText,
      cancelButtonText,
    }: ConfirmOptions) => {
      return new Promise<boolean>((resolve) => {
        setTitle(title);
        setMessage(message);
        setConfirmButtonText(confirmButtonText);
        setCancelButtonText(cancelButtonText);
        setResolveConfirm(() => resolve);
        setIsOpen(true);
      });
    },
    [],
  );

  const onConfirm = useCallback(() => {
    setIsOpen(false);
    resolveConfirm?.(true);
  }, [resolveConfirm]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    resolveConfirm?.(false);
  }, [resolveConfirm]);

  return (
    <ConfirmContext.Provider value={{ showConfirm }}>
      {children}
      {isOpen && (
        <ConfirmDialog
          title={title}
          content={message}
          onClose={onClose}
          onConfirm={onConfirm}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
        />
      )}
    </ConfirmContext.Provider>
  );
};

const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${spacing7} ${spacing8};
  min-width: 30rem;
  max-width: 40rem;
  gap: ${spacing7};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.8rem;
`;

export const ConfirmContentText = styled.p`
  ${typography.body};
`;

const ConfirmDialog = ({
  onClose,
  onConfirm,
  title,
  content,
  confirmButtonText = "OK",
  cancelButtonText = "Cancel",
}: {
  onClose(): void;
  onConfirm(): void;
  title: React.ReactNode;
  content: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
}) => {
  const [modalElem, setModalElem] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setModalElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        onClose();
      }

      if (e.key === "Enter") {
        e.stopPropagation();
        onConfirm();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClose, onConfirm]);

  if (!modalElem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal data-disable-clicks-outside closeOnWrapperClick={false}>
      <Modal>
        <HeaderRow>
          {typeof title === "string" ? (
            <h3 style={{ ...typography.h3, margin: 0 }}>{title}</h3>
          ) : (
            title
          )}
          <IconBtn size="1.2rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        {typeof content === "string" ? (
          <ConfirmContentText>{content}</ConfirmContentText>
        ) : (
          content
        )}
        <ButtonRow>
          <Button
            buttonType="secondary"
            onClick={onClose}
            text={cancelButtonText}
          />
          <Button onClick={onConfirm} text={confirmButtonText} />
        </ButtonRow>
      </Modal>
    </FullScreenModal>,
    modalElem,
  );
};
