import { colors } from "styles/colors";

import { MenuButton } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import { useCallback, useMemo, useState } from "react";
import { branchIdAtom } from "state/pathParams";
import { projectIdAtom } from "state/pathParams";
import { useAtom, useAtomValue } from "jotai";
import { TurbineFeature } from "types/feature";
import styled from "styled-components";
import { typography } from "styles/typography";
import { useOpenComponentsConfig } from "components/CanvasSelectOption/useOpenConfig";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "components/CanvasSelectOption/MenuTracking";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowUpIcon from "@icons/24/ArrowUp.svg?react";
import ComponentsIcon from "@icons/24/Components.svg?react";
import SplitButton from "components/General/SplitButton";
import { allBranchTurbinesAtom, hideReplaceModalAtom } from "./ReplaceModal";
import { Mixpanel } from "mixpanel";

const TypeSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.6rem;
  gap: 2rem;
  font-weight: 500;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;

const useAllInvalidTurbines = (deletedTurbineIds: string[]) => {
  const allTurbines = useAtomValue(allBranchTurbinesAtom);
  return useMemo(
    () =>
      allTurbines.map((branchTurbines) => ({
        branchId: branchTurbines.branchId,
        turbines: branchTurbines.turbines.filter((turbine) =>
          deletedTurbineIds.includes(turbine.properties.turbineTypeId),
        ),
      })),
    [allTurbines, deletedTurbineIds],
  );
};

export const ReplaceTurbineSelector = ({
  deletedTurbineTypeId,
  editable,
  branchInvalidTurbines,
}: {
  deletedTurbineTypeId: string;
  editable: boolean;
  branchInvalidTurbines: TurbineFeature[];
}) => {
  const allInvalidTurbines = useAllInvalidTurbines([deletedTurbineTypeId]);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const currentBranchId = useAtomValue(branchIdAtom) ?? "";
  const { update: updateFeatures } = useProjectElementsCrud();
  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);
  const openNewTurbineConfig = useOpenComponentsConfig(
    projectId,
    TURBINE_MENU_ID,
  );
  const [hiddenModalIds, setHiddenModalIds] = useAtom(hideReplaceModalAtom);

  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined,
  );

  const onReplaceInBranch = useCallback(
    (
      val: string,
      branchId: string,
      branchSpecificTurbines?: TurbineFeature[],
    ) => {
      trackCanvasOption("change-turbine-type", {
        projectId,
        branchId,
      });

      // Get turbines for the specific branch when needed
      const turbines = branchSpecificTurbines ?? branchInvalidTurbines;
      const updatedFeatures = turbines.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          turbineTypeId: val,
        },
      }));

      updateFeatures(
        {
          update: updatedFeatures,
        },
        undefined,
        undefined,
        branchSpecificTurbines ? branchId : undefined,
      );
    },
    [projectId, updateFeatures, branchInvalidTurbines],
  );

  const onReplaceInAllBranches = useCallback(
    (val: string) => {
      trackCanvasOption("change-turbine-type", {
        projectId,
      });

      allInvalidTurbines.forEach((branchData) => {
        onReplaceInBranch(val, branchData.branchId, branchData.turbines);
      });
    },
    [projectId, onReplaceInBranch, allInvalidTurbines],
  );
  const items = useMemo(
    () =>
      Array.from(allTurbineTypes.values()).map((t) => ({
        level: t.level,
        value: t.turbine.id,
        name: t.turbine.name,
        icon:
          t.level === "standard" ? (
            <VindLogo />
          ) : t.level === "project" ? (
            <ProjectIcon />
          ) : (
            <LibraryIcon />
          ),
      })),
    [allTurbineTypes],
  );

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");
    const standardItems = items.filter((ft) => ft.level === "standard");

    return {
      project: projectItems,
      library: libraryItems,
      standard: standardItems,
    };
  }, [items]);
  return (
    <TypeSelectorWrapper>
      <MenuButton
        disabled={!editable}
        iconOpen={<ArrowUpIcon />}
        icon={<ArrowDownIcon />}
        buttonStyle={{
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: " 0.4rem 0.8rem",
          gap: "1.6rem",
        }}
        buttonType="dropdown"
        buttonText={
          selectedItem
            ? allTurbineTypes.get(selectedItem)?.turbine.name ??
              "Select turbine type"
            : "Select turbine type"
        }
      >
        <>
          {groupedItems.library.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Library component</SubTitle>
              {groupedItems.library.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  key={item.value}
                  name={item.name}
                  onClick={() => setSelectedItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          {groupedItems.project.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Project specific components</SubTitle>
              {groupedItems.project.map((item) => (
                <MenuItem
                  key={item.value}
                  name={item.name}
                  onClick={() => setSelectedItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          {groupedItems.standard.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Vind AI components</SubTitle>
              {groupedItems.standard.map((item) => (
                <MenuItem
                  key={item.value}
                  name={item.name}
                  onClick={() => setSelectedItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          ></div>
          <DropdownDivider />
          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewTurbineConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
      <SplitButton
        text="Replace in all branches"
        buttonType="primary"
        disabled={!selectedItem}
        onClick={() => {
          Mixpanel.track_old("Replace turbine in all branches", {
            projectId,
            branchId: currentBranchId,
            turbineTypeId: selectedItem,
          });
          selectedItem && onReplaceInAllBranches(selectedItem);
        }}
      >
        <MenuItem
          name="Replace in all branches"
          defaultButton
          disabled={!selectedItem}
          onClick={() => {
            Mixpanel.track_old("Replace turbine in all branches", {
              projectId,
              branchId: currentBranchId,
              turbineTypeId: selectedItem,
            });
            selectedItem && onReplaceInAllBranches(selectedItem);
          }}
        />
        <MenuItem
          name="Replace turbine in only this branch"
          disabled={!selectedItem || branchInvalidTurbines.length === 0}
          onClick={() => {
            Mixpanel.track_old("Replace turbine in only this branch", {
              projectId,
              branchId: currentBranchId,
              turbineTypeId: selectedItem,
            });

            selectedItem && onReplaceInBranch(selectedItem, currentBranchId);
            setHiddenModalIds([...hiddenModalIds, deletedTurbineTypeId]);
          }}
        />
      </SplitButton>
    </TypeSelectorWrapper>
  );
};
