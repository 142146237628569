import UploadIcon from "@icons/24/Upload.svg?react";
import Button from "components/General/Button";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Label } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { Column, Row } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import { RangeSlider } from "components/General/Slider";
import HelpTooltip, {
  ARTICLE_IMPORT_WIND_DATA,
  ARTICLE_WIND_DATASETS,
  ARTICLE_WIND_MEAN_SPEED_CALIBRATION,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { windChangelogInfo } from "components/InputChangelog/const";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { useSetAtom } from "jotai";
import { useMemo } from "react";
import {
  SingleSourceSpatialCalibrationWindConfiguration,
  _SingleSourceSpatialCalibrationWindConfiguration,
  isBestWindSourceConfiguration,
  isCustomWindSource,
  isMultipleSourceWindConfiguration,
} from "services/windSourceConfigurationService";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { MeanSpeedGrid, UploadedWindData, WRG } from "state/windStatistics";
import styled from "styled-components";
import { colors } from "styles/colors";
import {
  spatialCalibrationName,
  SpatialCalibrationType,
  WindDataSource,
  WindSpeedCalibrationType,
} from "types/metocean";
import { Text } from "../../SettingsV2/Shared/styles";
import { PreviewGrid, PreviewGwa, PreviewNewa } from "./PreviewWindSource";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
`;

const LeftContentWrapper = styled.div`
  width: 35rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: ${colors.borderSubtle};
  }
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
`;

const MAX_YEARS = {
  [WindDataSource.ERA5]: 2022,
  [WindDataSource.CERRA]: 2020,
  [WindDataSource.NORA3]: 2022,
  [WindDataSource.BEST]: 2022,
  [WindDataSource.CUSTOM]: 2022,
};

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

const SingleTimeSeriesWithCalibration = ({
  dataSetDropdownItems,
  spatialCalibrationDropdownItems,
  localWindConfig,
  originalWindConfig,
  disableActions,
  setLocalWindConfig,
  uploadedWindData,
  uploadedWRGGrids,
  uploadedMeanSpeedGrids,
  useSpatialCalibration,
  nodeId,
}: {
  dataSetDropdownItems: DropDownItem[];
  spatialCalibrationDropdownItems: DropDownItem[];
  localWindConfig: SingleSourceSpatialCalibrationWindConfiguration;
  originalWindConfig?: SingleSourceSpatialCalibrationWindConfiguration;
  disableActions: boolean;
  setLocalWindConfig: (
    w: SingleSourceSpatialCalibrationWindConfiguration,
  ) => void;
  uploadedWindData: UploadedWindData | undefined;
  uploadedWRGGrids: { id: string; wrg: WRG }[] | undefined;
  uploadedMeanSpeedGrids:
    | { id: string; data: MeanSpeedGrid | undefined }[]
    | undefined;
  useSpatialCalibration: boolean;
  nodeId: string;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const selectedDataSetName = useMemo(() => {
    return dataSetDropdownItems.find(
      (d) => d.value === localWindConfig.source.id,
    )?.name;
  }, [dataSetDropdownItems, localWindConfig]);

  const selectedUploadedWRGFiles = useMemo(() => {
    if (
      uploadedWRGGrids &&
      localWindConfig.spatial_calibration &&
      localWindConfig.spatial_calibration.type === "custom" &&
      localWindConfig.spatial_calibration.id
    ) {
      const selectedSpatialCalibrationId =
        localWindConfig.spatial_calibration.id;

      return uploadedWRGGrids.filter(
        (u) => u.id === selectedSpatialCalibrationId,
      );
    } else {
      return undefined;
    }
  }, [uploadedWRGGrids, localWindConfig.spatial_calibration]);

  const selectedUploadedMeanSpeedFiles = useMemo(() => {
    if (
      uploadedMeanSpeedGrids &&
      localWindConfig.spatial_calibration &&
      localWindConfig.spatial_calibration.type === "custom" &&
      localWindConfig.spatial_calibration.id
    ) {
      const selectedSpatialCalibrationId =
        localWindConfig.spatial_calibration.id;

      return uploadedMeanSpeedGrids.filter(
        (u) => u.id === selectedSpatialCalibrationId,
      );
    } else {
      return undefined;
    }
  }, [uploadedMeanSpeedGrids, localWindConfig.spatial_calibration]);

  const selectedSpatialCalibrationName = useMemo(() => {
    const type = localWindConfig.spatial_calibration?.type;
    if (
      localWindConfig.spatial_calibration?.type ===
      SpatialCalibrationType.CUSTOM
    ) {
      return spatialCalibrationDropdownItems.find(
        (d) => d.value === (localWindConfig.spatial_calibration as any)?.["id"],
      )?.name;
    } else {
      return spatialCalibrationName[type as SpatialCalibrationType];
    }
  }, [localWindConfig.spatial_calibration, spatialCalibrationDropdownItems]);

  return (
    <ContentWrapper>
      <LeftContentWrapper>
        <Label htmlFor="not-help-link">
          <Row style={{ alignItems: "center" }}>
            <p>Select dataset</p>
            <HelpLink article={ARTICLE_WIND_DATASETS} />
          </Row>
          <Row
            style={{
              width: "fit-content",
            }}
          >
            <ChangelogAndCommentWrapper
              changelogInfo={windChangelogInfo(
                localWindConfig.id,
                nodeId,
                "source",
                "project",
              )}
            >
              <DropdownButton
                items={dataSetDropdownItems}
                style={{
                  width: "18rem",
                }}
                disabled={disableActions}
                onSelectItem={(id) => {
                  const uploaded = uploadedWindData?.find((d) => d.id === id);

                  if (uploaded) {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        source: {
                          id,
                          longitude: uploaded.lon,
                          latitude: uploaded.lat,
                        },
                      }),
                    );
                  } else {
                    if (id === WindDataSource.BEST) {
                      setLocalWindConfig(
                        _SingleSourceSpatialCalibrationWindConfiguration.parse({
                          ...localWindConfig,
                          calibration: null,
                          source: { id, type: "built_in" },
                        }),
                      );
                    } else {
                      setLocalWindConfig(
                        _SingleSourceSpatialCalibrationWindConfiguration.parse({
                          ...localWindConfig,
                          source: { id, type: "built_in" },
                          time_range: {
                            from_year: localWindConfig.time_range.from_year,
                            to_year: Math.min(
                              MAX_YEARS[id as WindDataSource],
                              localWindConfig.time_range.to_year,
                            ),
                          },
                        }),
                      );
                    }
                  }
                }}
                buttonText={selectedDataSetName ?? "Choose dataset"}
                selectedItemValue={
                  !isMultipleSourceWindConfiguration(localWindConfig)
                    ? localWindConfig.source.id
                    : ""
                }
              />
            </ChangelogAndCommentWrapper>
            <Button
              style={{ padding: "0 2rem" }}
              disabled={disableActions}
              buttonType="secondary"
              icon={<UploadIcon />}
              text="Upload"
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.WIND_DATA,
                  },
                });
              }}
            />
            <HelpLink article={ARTICLE_IMPORT_WIND_DATA} />
          </Row>
        </Label>

        {!isBestWindSourceConfiguration(localWindConfig) && (
          <Column style={{ gap: "0.4rem" }}>
            <Row style={{ alignItems: "center" }}>
              <p>Speed calibration</p>
              <HelpTooltip text="Adjust all speeds in the timeseries with this factor." />
              <InputDimensioned
                disabled={disableActions}
                validate={between(-20, 20)}
                step="0.1"
                unit="%"
                type="number"
                value={localWindConfig.calibration?.value ?? 0}
                initialValue={originalWindConfig?.calibration?.value}
                changelogInfo={windChangelogInfo(
                  localWindConfig.id,
                  nodeId,
                  "calibration.value",
                  "project",
                )}
                onChange={(val) => {
                  setLocalWindConfig(
                    _SingleSourceSpatialCalibrationWindConfiguration.parse({
                      ...localWindConfig,
                      calibration: {
                        type: WindSpeedCalibrationType.PERCENT,
                        value: val,
                      },
                    }),
                  );
                }}
                style={{ width: "6rem" }}
              />
            </Row>
          </Column>
        )}

        <Column style={{ gap: "0.4rem" }}>
          <Row style={{ alignItems: "center" }}>
            <Text>Spatial calibration</Text>
            <HelpLink article={ARTICLE_WIND_MEAN_SPEED_CALIBRATION} />
          </Row>
          {useSpatialCalibration && (
            <Row
              style={{
                width: "fit-content",
              }}
            >
              <DropdownButton
                items={spatialCalibrationDropdownItems}
                style={{
                  width: "18rem",
                }}
                disabled={disableActions}
                onSelectItem={(id) => {
                  setLocalWindConfig(
                    _SingleSourceSpatialCalibrationWindConfiguration.parse({
                      ...localWindConfig,
                      spatial_calibration: {
                        type:
                          id !== SpatialCalibrationType.GWA &&
                          id !== SpatialCalibrationType.NEWA
                            ? SpatialCalibrationType.CUSTOM
                            : (id as SpatialCalibrationType),
                        id: id,
                        value: "absolute",
                      },
                    }),
                  );
                }}
                buttonText={selectedSpatialCalibrationName ?? ""}
                selectedItemValue={
                  localWindConfig.spatial_calibration?.type ===
                  SpatialCalibrationType.CUSTOM
                    ? localWindConfig.spatial_calibration?.id
                    : localWindConfig.spatial_calibration?.type
                }
              />

              <Button
                style={{ padding: "0 2rem" }}
                disabled={disableActions}
                buttonType="secondary"
                icon={<UploadIcon />}
                text="Upload"
                onClick={() => {
                  setMidScreenModalTypeOpen({
                    modalType: UploadModalType,
                    metadata: {
                      preSelectedFileType: UploadFileType.WIND_DATA,
                    },
                  });
                }}
              />
            </Row>
          )}
          {useSpatialCalibration && (
            <Row>
              <RadioGroup>
                <Radio
                  name="spatial_calibration_absolute"
                  disabled={disableActions}
                  checked={
                    localWindConfig.spatial_calibration?.value === "absolute"
                  }
                  onChange={() => {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        spatial_calibration: {
                          ...localWindConfig.spatial_calibration,
                          value: "absolute",
                        },
                      }),
                    );
                  }}
                />
                <p>Absolute</p>
              </RadioGroup>
              <RadioGroup>
                <Radio
                  name="spatial_calibration_relative"
                  disabled={disableActions}
                  checked={
                    localWindConfig.spatial_calibration?.value === "relative"
                  }
                  onChange={() => {
                    setLocalWindConfig(
                      _SingleSourceSpatialCalibrationWindConfiguration.parse({
                        ...localWindConfig,
                        spatial_calibration: {
                          ...localWindConfig.spatial_calibration,
                          value: "relative",
                        },
                      }),
                    );
                  }}
                />
                <p>Relative</p>
              </RadioGroup>
            </Row>
          )}
        </Column>

        {!isMultipleSourceWindConfiguration(localWindConfig) &&
          !isCustomWindSource(localWindConfig.source) && (
            <Label>
              <p>Time range</p>
              <ChangelogAndCommentWrapper
                changelogInfo={windChangelogInfo(
                  localWindConfig.id,
                  nodeId,
                  "time_range",
                  "project",
                )}
              >
                <RangeSlider
                  disabled={disableActions}
                  style={{ width: "30rem" }}
                  min={1990}
                  max={MAX_YEARS[localWindConfig.source.id]}
                  values={[
                    localWindConfig.time_range.from_year,
                    localWindConfig.time_range.to_year,
                  ]}
                  onChange={(range) =>
                    setLocalWindConfig({
                      ...localWindConfig,
                      time_range: { from_year: range[0], to_year: range[1] },
                    })
                  }
                  labels
                  inside
                />
              </ChangelogAndCommentWrapper>
            </Label>
          )}
      </LeftContentWrapper>
      <RightContentWrapper>
        {localWindConfig.spatial_calibration &&
          localWindConfig.spatial_calibration.type === "custom" && (
            <PreviewGrid
              customWRGFiles={selectedUploadedWRGFiles}
              customMeanSpeedGridFiles={selectedUploadedMeanSpeedFiles}
            />
          )}
        {localWindConfig.spatial_calibration &&
          localWindConfig.spatial_calibration.type === "global_wind_atlas" && (
            <PreviewGwa />
          )}
        {localWindConfig.spatial_calibration &&
          localWindConfig.spatial_calibration.type ===
            "new_european_wind_atlas" && <PreviewNewa />}
      </RightContentWrapper>
    </ContentWrapper>
  );
};

export default SingleTimeSeriesWithCalibration;
