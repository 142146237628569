import { branchIdAtom, parkIdAtomDef2, projectIdAtom } from "state/pathParams";
("use client");
import { FloatingFocusManager, offset, useFloating } from "@floating-ui/react";
import Dashboard from "@icons/24/Dashboard.svg?react";
import {
  ProdId,
  analysisOverrideInputFamily,
  getConfiguration,
  getOtherLosses,
  getPark,
  getTriggerAnalysisArgs,
  getTurbineCapacity,
  getWindSourceConfiguration,
  isValidConfiguration,
} from "analysis/inputs";
import {
  AnalysisStoppedTypes,
  analysisStoppedText,
  getStoppedReason,
} from "analysis/warnings";
import { benchmarkingArticleHintType } from "components/ActiveTips/PositionHints/AnalysisBenchmarking";
import { PositionHintRef } from "components/ActiveTips/PositionHints/PositionHint";
import Button from "components/General/Button";
import { ColoredGrid, Grid2 } from "components/General/Form";
import {
  Divider,
  OverlineText,
  OverlineTextWrapper,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import HelpTooltip, {
  ARTICLE_YIELD_ASSESSMENT,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import {
  SkeletonBlock,
  orLoader,
  orTextLoader,
  SkeletonText,
} from "components/Loading/Skeleton";
import { TourStep } from "components/OnboardingTours/TourStep";
import {
  formatDecimal,
  formatElectricalLossToPercentDecimal,
  formatGWhConditional,
  formatLossToPercent,
  formatPercent,
} from "components/ProductionV2/format";
import { ButtonWrapper } from "components/RightSide/InfoModal/style";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { ProductionError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { triggerAnalysis } from "functions/production";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import { editorAccessProjectSelector } from "state/user";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceLarge, spaceSmall } from "styles/space";
import { undefMap } from "utils/utils";
import { Column, Row } from "../General/Layout";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import {
  AnalysisConfigNotFoundError,
  AnalysisConfigNotFoundErrorBoundaryFallbackRender,
} from "./ErrorUtils";
import { ProductionTagList } from "./ProductionTagList";
import { currentSelectionProduction } from "./Triggers";
import { Visualizations } from "./Visualizations";
import { WindRoseWidget } from "./WindRose";
import { WindStatistics } from "./WindStatistics";
import {
  getAEP,
  getAnalysisResponse,
  getCapacityFactor,
  getGrossEnergy,
  getIsOutdated,
  getNeighbourWakeLoss,
  getPostWakeEnergy,
  getStatsError,
  getTotalWakeLoss,
  getTurbineSpecificLoss,
} from "analysis/output";
import {
  getTotalExportSystemLoss,
  getTotalInterArrayLoss,
} from "analysis/electrical";
import { loadable, unwrap } from "jotai/utils";
import { lunwrap } from "utils/jotai";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { scream } from "utils/sentry";
import { turbinesInParkWithIllegalTypeFamily } from "state/jotai/turbine";
import { configurationMenuActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { Tag } from "components/General/Tag";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import WindIcon from "@icons/24/Wind.svg?react";
import { IconREMSize } from "styles/typography";

const DisabledRow = styled(Row)`
  gap: ${spaceSmall};
  p {
    color: ${colors.primaryDisabled};
  }
  * > svg {
    height: 1.4rem;
    width: 1.4rem;
    padding: 0.1rem;
    path {
      stroke: ${colors.primaryDisabled};
    }
  }
`;

const GridRow = styled(Row)`
  * > svg {
    height: 1.3rem;
    width: 1.3rem;
  }
`;

const Disabled = ({ text }: { text: string }) => (
  <DisabledRow>
    <ResultValue
      style={{
        color: colors.textDisabled,
      }}
    >
      Disabled
    </ResultValue>
    <HelpTooltip text={text} />
  </DisabledRow>
);

const Loader = () => (
  <>
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "inherit",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "inherit",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "inherit",
      }}
    />
  </>
);

const ShowResults = ({ id }: { id: ProdId }) => {
  const [isProductionOpen, setProductionOpen] = useState<boolean>(true);
  const [isLossesOpen, setLossesOpen] = useState<boolean>(true);
  const { selectedSubAreas } = useAtomValue(analysisOverrideInputFamily(id));
  const hasSelectedZones = (selectedSubAreas?.length ?? 0) !== 0;
  const capacity = useAtomValue(getTurbineCapacity(id));
  const grossEnergy = useAtomValue(loadable(getGrossEnergy(id)));
  const postWakeEnergy = useAtomValue(loadable(getPostWakeEnergy(id)));
  const netEnergy = useAtomValue(loadable(getAEP(id)));
  const capacityFactor = useAtomValue(loadable(getCapacityFactor(id)));
  const totalWakeLoss = useAtomValue(loadable(getTotalWakeLoss(id)));
  const turbineSpecificLoss = useAtomValue(
    loadable(getTurbineSpecificLoss(id)),
  );
  const neighbourWakeLoss = useAtomValue(loadable(getNeighbourWakeLoss(id)));
  const totalInterArrayLoss = useAtomValue(
    loadable(getTotalInterArrayLoss(id)),
  );
  const totalExportSystemLoss = useAtomValue(
    loadable(getTotalExportSystemLoss(id)),
  );
  const otherLosses = useAtomValue(loadable(getOtherLosses(id)));
  const analysis = lunwrap(useAtomValue(loadable(getAnalysisResponse(id))));
  const stopped = useAtomValue(getStoppedReason(id));
  const error = useAtomValue(unwrap(getStatsError(id)));

  const configuration = useAtomValue(unwrap(getConfiguration(id)));
  const showNeighbourWakeLoss =
    configuration?.wakeAnalysis.neighbourWake ?? false;

  const interArrayLossEnabled =
    (configuration?.electrical.interArrayCableLoss ?? false) ||
    (configuration?.electrical.turbineTrafoLoss ?? false);

  const exportSystemEnabled =
    configuration?.electrical.exportSystemLoss ?? false;
  const progress = analysis?.progress;

  const gridRef = useRef<HTMLDivElement>(null);

  if (stopped) {
    return (
      <SimpleAlert
        title="Analysis stopped"
        text={analysisStoppedText[stopped]}
        type={"error"}
      />
    );
  }

  if (error) {
    return (
      <SimpleAlert
        title="Analysis failed"
        text={analysisStoppedText[error]}
        type={"error"}
      />
    );
  }

  if (!configuration) return null;

  return (
    <>
      <PositionHintRef
        around={gridRef}
        allowedHints={[benchmarkingArticleHintType]}
        position={"left"}
        offset={[`calc(-${spaceLarge} / 2)`, 0]}
      />
      <OverlineTextWrapper onClick={() => setProductionOpen(!isProductionOpen)}>
        <OverlineText>Production</OverlineText>
        <ChevronIcon
          open={isProductionOpen}
          chevronSize={"1rem"}
          style={{
            alignSelf: "center",
          }}
        />
      </OverlineTextWrapper>
      {isProductionOpen && (
        <ColoredGrid
          style={{
            gridTemplateColumns: "4fr 2fr",
          }}
        >
          <ResultValue>Capacity</ResultValue>
          <ResultValue>
            <strong>{formatDecimal(capacity, 1)}</strong> MW
          </ResultValue>

          <ResultValue>Gross energy</ResultValue>
          {orTextLoader(
            grossEnergy,
            undefMap(progress, (p) => `${Math.round(p * 100)} %`) ?? "",
            (c) => (
              <ResultValue id="gross-energy">
                <strong>{formatGWhConditional(c, true)}</strong> GWh
              </ResultValue>
            ),
          )}

          <ResultValue>Post-wake energy</ResultValue>
          {orTextLoader(
            postWakeEnergy,
            undefMap(progress, (p) => `${Math.round(p * 100)} %`) ?? "",
            (c) => (
              <ResultValue id="post-wake-energy">
                <strong>{formatGWhConditional(c, true)}</strong> GWh
              </ResultValue>
            ),
          )}

          <ResultValue>Net energy</ResultValue>
          {orLoader(netEnergy, (c) => (
            <ResultValue id="net-energy">
              <strong>{formatGWhConditional(c, true)}</strong> GWh
            </ResultValue>
          ))}

          <ResultValue>Capacity factor</ResultValue>
          {orLoader(capacityFactor, (c) => (
            <ResultValue>
              <strong>{formatPercent(c, 1, true)}</strong> %
            </ResultValue>
          ))}
        </ColoredGrid>
      )}
      <OverlineTextWrapper onClick={() => setLossesOpen(!isLossesOpen)}>
        <OverlineText>Losses</OverlineText>

        <ChevronIcon
          open={isLossesOpen}
          chevronSize={"1rem"}
          style={{
            alignSelf: "center",
          }}
        />
      </OverlineTextWrapper>
      {isLossesOpen && (
        <ColoredGrid
          style={{
            gridTemplateColumns: "4fr 2fr",
          }}
        >
          <ResultValue>Total wake loss</ResultValue>
          {orLoader(totalWakeLoss, (c) => (
            <ResultValue>
              <strong>{formatLossToPercent(c)}</strong> %
            </ResultValue>
          ))}
          <GridRow>
            <ResultValue>Turbine-specific loss</ResultValue>
            <HelpTooltip
              text={
                "Proportion of the gross output that is lost to turbine-specific losses, which is specific on the turbine type."
              }
            />
          </GridRow>
          {orLoader(turbineSpecificLoss, (c) => (
            <ResultValue>
              <strong>{formatLossToPercent(c)}</strong> %
            </ResultValue>
          ))}

          {!hasSelectedZones && (
            <>
              <ResultValue>Neighbour wake loss</ResultValue>

              {showNeighbourWakeLoss ? (
                orLoader(neighbourWakeLoss, (c) => (
                  <ResultValue>
                    <strong>{formatLossToPercent(c)}</strong> %
                  </ResultValue>
                ))
              ) : (
                <Disabled
                  text={
                    "Neighbour wake loss is disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Total inter array loss</ResultValue>
              {interArrayLossEnabled ? (
                orLoader(totalInterArrayLoss, (c) => (
                  <ResultValue>
                    <strong>
                      {formatElectricalLossToPercentDecimal(
                        Number(c?.totalInterArrayLoss),
                        true,
                      )}
                    </strong>{" "}
                    %
                  </ResultValue>
                ))
              ) : (
                <Disabled
                  text={
                    "Inter-array cable loss and turbine trafo loss are disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Total export system loss</ResultValue>
              {exportSystemEnabled ? (
                orLoader(totalExportSystemLoss, (c) => (
                  <ResultValue>
                    <strong>
                      {formatElectricalLossToPercentDecimal(
                        Number(c?.totalExportSystemLoss),
                        true,
                      )}{" "}
                    </strong>{" "}
                    %
                  </ResultValue>
                ))
              ) : (
                <Disabled
                  text={
                    "Export system loss is disabled in the current analysis configuration."
                  }
                />
              )}

              <ResultValue>Other losses</ResultValue>
              {orLoader(otherLosses, (c) => (
                <ResultValue>
                  <strong>{formatPercent(c, 1, true)} </strong> %
                </ResultValue>
              ))}
            </>
          )}
        </ColoredGrid>
      )}
    </>
  );
};

const ReloadAnalysisButton = () => {
  const analysisTriggerArgs = useAtomValue(
    unwrap(getTriggerAnalysisArgs(currentSelectionProduction)),
  );
  const analysis = useAtomValue(
    loadable(getAnalysisResponse(currentSelectionProduction)),
  );

  const outdated = useAtomValue(
    unwrap(getIsOutdated(currentSelectionProduction)),
  );

  const isProjectEditor = useAtomValue(editorAccessProjectSelector);

  const [restarted, setRestarted] = useState<boolean>(false);
  const [rerunWithKey, setRerunWithKey] = useState(false);

  const hasFailed = lunwrap(analysis)?.status === "failed";

  useEffect(() => {
    const show = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "r"
      ) {
        return;
      }
      setRerunWithKey(true);
    };
    const hide = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "r"
      ) {
        return;
      }
      setRerunWithKey(false);
    };

    window.addEventListener("keydown", show);
    window.addEventListener("keyup", hide);

    return () => {
      window.removeEventListener("keydown", show);
      window.removeEventListener("keyup", hide);
    };
  }, [setRerunWithKey]);

  if (!(outdated || rerunWithKey || hasFailed)) return null;
  return (
    <Grid2
      style={{
        gridTemplateColumns: "4fr 2fr",
      }}
    >
      <p
        style={{
          color: colors.brand,
        }}
      >
        {rerunWithKey
          ? "Force rerunning analysis"
          : hasFailed
            ? "You may try rerunning"
            : "New version available"}
      </p>
      <Button
        text={"Rerun"}
        style={{
          height: "2rem",
        }}
        onClick={() => {
          if (!analysisTriggerArgs) return;
          setRestarted(true);
          triggerAnalysis({
            ...analysisTriggerArgs,
            restart: true,
          });
        }}
        disabled={!analysisTriggerArgs || restarted || !isProjectEditor}
      />
    </Grid2>
  );
};

const ProductionV2Inner = ({
  floatingRefs,
}: {
  floatingRefs: ReturnType<typeof useFloating>["refs"];
}) => {
  const park = useAtomValue(getPark(currentSelectionProduction));
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const selectedAnalysisConfig = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const selectedWindConfig = useAtomValue(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const isValidConfig = useAtomValue(
    isValidConfiguration(currentSelectionProduction),
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const currentWindSourceConfigurationLoadable = useAtomValue(
    loadable(getWindSourceConfiguration(currentSelectionProduction)),
  );
  const resetKeys = useMemo(
    () => [isValidConfig, currentWindSourceConfigurationLoadable.state],
    [isValidConfig, currentWindSourceConfigurationLoadable],
  );
  const lowerRightModeOpen = !!useAtomValue(lowerRightMenuActiveModeAtom);
  const [configurationMenuActive, setConfigurationMenuActive] = useAtom(
    configurationMenuActiveAtom,
  );

  if (!park) return null;

  return (
    <Column
      style={{
        padding: 0,
        gap: 0,
      }}
    >
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: `calc(100vh - ${
            lowerRightModeOpen ? "58rem" : "32rem"
          } - ${configurationMenuActive ? "18rem" : "0rem"} )`,
          overflowY: "auto",
          padding: "0 1.6rem 1.6rem 1.6rem",
        }}
      >
        <Row
          style={{
            justifyContent: "left",
            flexWrap: "wrap",
            paddingBottom: "0.8rem",
          }}
        >
          {selectedAnalysisConfig && (
            <Tag
              maxWidth="20rem"
              onClick={() => {
                setConfigurationMenuActive(true);
              }}
              icon={
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  iconColor={colors.iconNegative}
                >
                  <StatisticsIcon />
                </IconREMSize>
              }
              dark
              text={`${selectedAnalysisConfig?.name}`}
              tooltip="Selected analysis configuration"
            />
          )}
          {selectedWindConfig && (
            <Tag
              maxWidth="20rem"
              onClick={() => {
                setConfigurationMenuActive(true);
              }}
              icon={
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  iconColor={colors.iconNegative}
                >
                  <WindIcon />
                </IconREMSize>
              }
              dark
              text={`${selectedWindConfig?.name}`}
              tooltip="Selected wind configuration"
            />
          )}
        </Row>
        {!isValidConfig && (
          <AnalysisConfigNotFoundErrorBoundaryFallbackRender
            error={
              new AnalysisConfigNotFoundError(
                AnalysisStoppedTypes.InvalidAnalysisConfigId,
              )
            }
          />
        )}
        {isValidConfig && (
          <ErrorBoundary
            fallbackRender={AnalysisConfigNotFoundErrorBoundaryFallbackRender}
            resetKeys={resetKeys}
          >
            <Column>
              <SubtitleWithLine
                text="Annual production statistics"
                innerRef={floatingRefs.setReference}
              />

              <Suspense fallback={<Loader />}>
                <ProductionTagList id={currentSelectionProduction} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <ReloadAnalysisButton />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <ShowResults id={currentSelectionProduction} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <WindRoseWidget id={currentSelectionProduction} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <WindStatistics id={currentSelectionProduction} />
              </Suspense>

              <Suspense fallback={<Loader />}>
                <Visualizations id={currentSelectionProduction} />
              </Suspense>
            </Column>
          </ErrorBoundary>
        )}
      </div>
      <Divider
        style={{
          marginLeft: "-2rem",
          marginRight: "-2rem",
        }}
      />
      <ButtonWrapper
        style={{
          paddingRight: "1.6rem",
        }}
      >
        <Button
          text="View in dashboard"
          icon={<Dashboard />}
          buttonType="secondary"
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: {
                id: "vind-preset-dashboard-yield",
              },
            });
          }}
        />
      </ButtonWrapper>
    </Column>
  );
};

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  // Likely error is that a turbine has an illegal turbine type.

  const parkId = useAtomValue(parkIdAtomDef2);
  const turbinesWithoutType = useAtomValue(
    turbinesInParkWithIllegalTypeFamily({ parkId, branchId: undefined }),
  );

  useEffect(() => {
    turbinesWithoutType; // when this change, check again.
    resetErrorBoundary();
  }, [resetErrorBoundary, turbinesWithoutType]);

  if (turbinesWithoutType.length > 0) {
    return (
      <SimpleAlert
        style={{ margin: "0 1rem" }}
        type="error"
        title="Turbines without type"
        text="Some turbines in the park don't have a valid turbine type.  This is required for production numbers."
      />
    );
  }

  if (error instanceof Error) {
    scream(error, { message: "Production fallback" });
  } else {
    scream(
      new Error("Production fallback", {
        cause: error,
      }),
      { error },
    );
  }
  return (
    <SimpleAlert
      style={{ margin: "0 1rem" }}
      type="error"
      text={error.toString()}
    />
  );
}

export const ProductionV2 = ({ onClose }: { onClose(): void }) => {
  const park = useAtomValue(getPark(currentSelectionProduction));
  const branchId = useAtomValue(branchIdAtom);

  const { refs, floatingStyles, context } = useFloating({
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 20,
        crossAxis: 20,
      }),
    ],
  });

  if (!park) return null;

  return (
    <>
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="general-intro-tour"
          stepId="productionStatistics"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="onshore-intro-tour"
          stepId="productionStatistics"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <MenuFrame
        icon={<HelpLink article={ARTICLE_YIELD_ASSESSMENT} />}
        title={"Production"}
        validationError={
          <ProductionError parkId={park.id} branchId={branchId ?? ""} />
        }
        onExit={onClose}
        style={{
          overflowY: "visible",
        }}
      >
        <ErrorBoundary FallbackComponent={Fallback}>
          <Suspense
            fallback={
              <Column>
                <SkeletonText />
              </Column>
            }
          >
            <ProductionV2Inner floatingRefs={refs} />
          </Suspense>
        </ErrorBoundary>
      </MenuFrame>
    </>
  );
};
