import { InputDimensioned } from "../../General/Input";
import { Label } from "../../General/Form";
import { VesselType } from "services/vesselService";
import { vesselChangelogInfo } from "components/InputChangelog/const";
import { SecondaryText } from "components/SettingsV2/Shared/styles";

const kmPerHrToMPerMin = 1000 / 60;

export function CableLayVesselSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType & { type: "cableLay" };
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  return (
    <>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Array cable capacity</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.arrayCableCapacity}
            unit={"km"}
            step={1}
            validate={(e) => e >= 1 && e <= 300}
            validationMessage={`Must be between 1 and 300`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "arrayCableCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                arrayCableCapacity: n,
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Export cable capacity </SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.exportCableCapacity}
            unit={"km"}
            step={1}
            validate={(e) => e >= 1 && e <= 200}
            validationMessage={`Must be between 1 and 200`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "exportCableCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                exportCableCapacity: n,
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Cable lay/bury speed</SecondaryText>
          <InputDimensioned
            decimals={2}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.cableLayBurySpeed}
            unit={"km/h"}
            step={0.1}
            validate={(e) => e >= 0.1 && e <= 10}
            validationMessage={`Must be between 0.1 and 10`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "cableLayBurySpeed",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                cableLayBurySpeed: n,
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Cable loading speed</SecondaryText>
          <InputDimensioned
            decimals={1}
            style={{ width: "20rem" }}
            disabled={disabled}
            scaleFactor={kmPerHrToMPerMin}
            value={vessel.cableLoadingSpeed}
            unit={"m/min"}
            step={0.1}
            validate={(e) => e >= 1 && e <= 100}
            validationMessage={`Must be between 1 and 100`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "cableLoadingSpeed",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                cableLoadingSpeed: n,
              });
            }}
          />
        </Label>
      </div>
    </>
  );
}
