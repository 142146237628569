import { useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { Label } from "components/General/Form";
import { Row } from "components/General/Layout";
import { branchIdAtomDef, projectIdAtomDef } from "state/pathParams";
import { getBranchSelectorFamily, useUpdateBranch } from "state/timeline";
import SelectAnalysisDropDown from "./SelectAnalysisDropDown";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { editorAccessProjectSelector } from "state/user";
import { projectResourceUsageRefresh } from "state/resourceUsageAtoms";
import { DarkSubtitle } from "components/RightSide/InfoModal/style";
import { Suspense } from "react";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import StatisticsIcon from "@icons/24/Statistics.svg?react";

export const SelectAnalysisConfiguration = () => {
  return (
    <Row
      style={{
        margin: 0,
        alignItems: "end",
      }}
    >
      <Label style={{ flex: 1, width: "100%", gap: "0.4rem" }}>
        <Row>
          <IconREMSize height={1.4} width={1.4} iconColor={colors.iconNegative}>
            <StatisticsIcon />
          </IconREMSize>
          <DarkSubtitle>Analysis</DarkSubtitle>
        </Row>
        <Suspense
          fallback={
            <SkeletonBlock
              style={{
                minHeight: "2.6rem",
              }}
            />
          }
        >
          <SelectAnalysisDropDownWrapper />
        </Suspense>
      </Label>
    </Row>
  );
};

const SelectAnalysisDropDownWrapper = () => {
  const projectId = useAtomValue(projectIdAtomDef);
  const branchId = useAtomValue(branchIdAtomDef);
  const projectEditorAccess = useAtomValue(editorAccessProjectSelector);
  const selectedConfig = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const resetAnalysisConfUsage = useJotaiCallback(
    async (_get, set, analysisConfigurationId: string) => {
      if (projectId) {
        set(
          projectResourceUsageRefresh({
            nodeId: projectId,
            resourceType: "ANALYSIS_CONFIGURATION",
            resourceId: analysisConfigurationId,
          }),
          (curr) => curr + 1,
        );
      }
    },
    [projectId],
  );
  const updateBranch = useUpdateBranch();
  const liveBranchMeta = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );

  return (
    <SelectAnalysisDropDown
      selectedConfig={selectedConfig}
      projectId={projectId}
      disabled={!projectEditorAccess}
      style={{
        flex: 1,
      }}
      backgroundColor={colors.surfacePrimary}
      onSelectItem={(id) => {
        if (!liveBranchMeta) return;
        liveBranchMeta.analysisConfigurationId &&
          resetAnalysisConfUsage(liveBranchMeta.analysisConfigurationId);
        resetAnalysisConfUsage(id);
        updateBranch({
          ...liveBranchMeta,
          analysisConfigurationId: id,
        });
      }}
    />
  );
};
