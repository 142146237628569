import { z } from "zod";
import * as turf from "@turf/turf";
import { Sector } from "lib/sector";
import { rad2deg } from "utils/geometry";

/** Mirror lib/sector but also include the raduis. */
const _CableFreeSector = z.object({
  middle: z.number(),
  span: z.number(),
  distanceM: z.number(),
});

export type CableFreeSector = z.infer<typeof _CableFreeSector>;

export const _CableFreeSectors = _CableFreeSector.array();

export const makeSector = ({
  feature,
  middle,
  span,
  distanceM,
}: {
  feature: turf.Feature<turf.Point>;
  middle: number;
  span: number;
  distanceM: number;
}): turf.Feature<turf.Polygon> | undefined | null => {
  const sec = new Sector(middle, span);
  const from = rad2deg(sec.from());
  const to = rad2deg(sec.to()) + 0.001; // NOTE: bug in turf.sector
  return turf.sector(feature, distanceM / 1000, from, to);
};
