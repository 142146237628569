import { atom } from "jotai";
import { SubAreaFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily, featuresInParkFamily } from "./features";
import { constrainFeatureToParkLayout } from "state/division";
import { parkFamily } from "./park";

const subAreasFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<SubAreaFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).subArea,
    ),
);

export const subAreaFamily = atomFamily(
  ({
    subAreaId,
    branchId,
  }: {
    subAreaId: string;
    branchId: string | undefined;
  }) =>
    atom<Promise<SubAreaFeature | undefined>>(async (get) =>
      (await get(subAreasFamily({ branchId }))).find((s) => s.id === subAreaId),
    ),
);

/**
 * List all sub-areas constrained to the park polygon they're in.
 */
export const subAreasConstrainedFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<SubAreaFeature[]>>(async (get) => {
      const { subArea, park } = await get(featuresFamily({ branchId }));
      const parkMap = new Map(park.map((p) => [p.id, p]));
      return subArea.flatMap((sub) => {
        const parkId = sub.properties.parentIds?.[0] ?? "";
        const p = parkMap.get(parkId);
        if (!p) return sub;
        return constrainFeatureToParkLayout(sub, p);
      });
    }),
);

export const subAreasInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<SubAreaFeature[]>>(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      const { subArea } = await get(featuresInParkFamily({ parkId, branchId }));
      if (!park) return subArea;
      return subArea.flatMap((sub) => constrainFeatureToParkLayout(sub, park));
    }),
);
