import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { useToast } from "hooks/useToast";
import { useState, useCallback, useMemo } from "react";
import {
  adminInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import { UserAccessRole, _UserAccessRole } from "types/user";
import { scream } from "utils/sentry";
import { Text } from "../../style";
import { spacing6 } from "styles/space";
import { typography } from "styles/typography";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";

const dropdownItems: DropDownItem[] = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Editor",
    value: "editor",
  },
  {
    name: "Viewer",
    value: "viewer",
  },
];

export const UserNodeAccessDropdown = ({
  role,
  userId,
  nodeId,
}: {
  role: UserAccessRole;
  userId: string;
  nodeId: string;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const { addOrUpdate } = useUserNodeAccessCrud();
  const [loading, setLoading] = useState(false);
  const { error } = useToast();
  const _updateMemberRole = useCallback(
    async (userId: string, newRole: UserAccessRole) => {
      try {
        setLoading(true);
        await addOrUpdate(userId, nodeId, newRole);
      } catch (e) {
        scream("Failed to update access resource", {
          e,
        });
        error(
          "Something went wrong when updating access, the Vind team has been notified",
        );
      } finally {
        setLoading(false);
      }
    },
    [addOrUpdate, nodeId, error],
  );

  const selectedText = useMemo(
    () => dropdownItems.find((i) => i.value === role)?.name,
    [role],
  );

  if (!isNodeAdmin && !isAdminInOrg)
    return (
      <Text
        style={{
          ...typography.contentAndButtons,
          textTransform: "capitalize",
          paddingLeft: spacing6,
        }}
      >
        {role}
      </Text>
    );

  return (
    <DropdownButton
      style={{
        border: "none",
        width: "auto",
      }}
      buttonText={selectedText ?? ""}
      disabled={loading}
      onSelectItem={(_role) => {
        const newRole = _UserAccessRole.parse(_role);
        _updateMemberRole(userId, newRole);
      }}
      selectedItemValue={role}
      items={dropdownItems}
    />
  );
};
