import { lockedPropertyName } from "@constants/canvas";
import { DataDrivenPropertyValueSpecification, Expression } from "mapbox-gl";

/** Set value in {@link Inputs['get']} use the read value from the property.*/
export const READ = Symbol();

type Inputs = {
  /** Default vaule. */
  fallback: any;
  /** Value when the feature is selected. */
  selected?: any;
  /** Value when the feature is hovered. */
  hover?: any;
  /** Value when the feature is active (or `inFocus`) */
  active?: any;
  /** Value when the feature is locked. */
  locked?: any;

  /**
   * Record for `["get", ...]` expressions.  For a key,value pair, if the
   * feature has a non-null state with that key, use the value. If the value is
   * `READ`, use the value read from the feature.
   */
  get?: {
    [prop: string]: typeof READ | any;
  };
  /**
   * Record for `["feature-state", ...]` expressions.  For a key,value pair,
   * if the feature has a non-null state with that key, use the value.
   * If the value is `READ`, use the value read from the feature.
   */
  state?: {
    [prop: string]: typeof READ | any;
  };
};

/**
 * Convenience function to create `{"case", ...}` mapbox expressions. Using
 * this will ensure that the precedense of states are consistent for all
 * feature types.  It's also less typing when you define the Paints.
 */
export const caseexpr = (lo: Inputs): Expression => {
  const ret: Expression = ["case"];
  if (lo.state) {
    for (const [k, v] of Object.entries(lo.state)) {
      ret.push(["!=", ["feature-state", k], null]);
      if (v === READ) ret.push(["feature-state", k]);
      else ret.push(v);
    }
  }
  if (lo.get) {
    for (const [k, v] of Object.entries(lo.get)) {
      ret.push(["!=", ["get", k], null]);
      if (v === READ) ret.push(["get", k]);
      else ret.push(v);
    }
  }
  if (lo.selected)
    ret.push(["boolean", ["feature-state", "selected"], false], lo.selected);
  if (lo.hover)
    ret.push(["boolean", ["feature-state", "hover"], false], lo.hover);
  if (lo.locked) ret.push(["==", ["get", lockedPropertyName], true], lo.locked);
  if (lo.active)
    ret.push(["boolean", ["feature-state", "inFocus"], false], lo.active);

  if (ret.length === 1) return lo.fallback;
  ret.push(lo.fallback);
  return ret;
};

/** Default opacity rules for LineString features that are in a park. */
export const pointOpacityInPark: DataDrivenPropertyValueSpecification<number> =
  caseexpr({
    selected: 1,
    hover: 1,
    active: 1,
    get: { [lockedPropertyName]: 0.5 },
    fallback: 0.3,
  });

/** Default opacity rules for LineString features that are outside parks. */
export const pointOpacityOutsidePark: DataDrivenPropertyValueSpecification<number> =
  [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    1.0,
    ["boolean", ["feature-state", "hover"], false],
    1.0,
    ["boolean", ["feature-state", "inFocus"], false],
    1.0,
    ["==", ["get", lockedPropertyName], true],
    0.8,
    1.0,
  ];
