import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import Point from "../../components/MapFeatures/Point";
import {
  mapboxBridgeRail,
  externalWFSAnchorId,
  externalWMSAnchorId,
} from "../../components/Mapbox/utils";

const ExternalLayerOrderAnchorLayers = ErrorBoundaryWrapper(
  () => {
    const map = useAtomValue(mapAtom);

    if (!map) return null;

    return (
      <>
        <Point
          features={[]}
          sourceId={externalWFSAnchorId}
          layerId={externalWFSAnchorId}
          map={map}
          beforeLayer={mapboxBridgeRail}
        />
        <Point
          features={[]}
          sourceId={externalWMSAnchorId}
          layerId={externalWMSAnchorId}
          map={map}
          beforeLayer={mapboxBridgeRail}
        />
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default ExternalLayerOrderAnchorLayers;
