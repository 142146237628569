import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { Cost, CostUnit, _CostUnit, unitToAmountUnit } from "types/financial";
import { amountId } from "finance/amounts/amountIds";
import {
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
} from "services/costService";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { costId } from "../amounts/costIds";
import { mooringLineStatsInParkSelector } from "state/jotai/mooringLineType";
import { getBranchId, getParkId } from "analysis/inputs";
import { vesselTypesFamily } from "state/jotai/vesselType";

export const mooringCostsAtomFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const {
      capex: { custom, fixed, installation },
    } = await get(getConfiguration(id));

    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const { mooringLineTypes } = await get(
      mooringLineStatsInParkSelector({ branchId, parkId }),
    );

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Mooring)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.Foundation,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Mooring)
      .filter((c) => c.unit !== CostUnit.millionEuro)
      .flatMap((custom) => ({
        ...custom,
        name: `${custom.name}`,
        id: costId({
          category: CostType.Mooring,
          costId: custom.id,
          featureTypeId: CostType.Mooring,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[custom.unit],
          category: custom.category,
        }),
      }));

    let installationCosts: Cost[] = [];

    if (isOperationsCost(installation.mooring)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.mooring;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      const { vesselId, dayRate } = installationVessel;
      const { cost, unit: costUnit } = dayRate;
      const unit = _CostUnit.parse(costUnit);
      const name = vesselTypes.get(vesselId)?.name ?? "";

      installationCosts.push({
        id: `mooring_installation_${vesselId}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
          featureTypeId: vesselId,
        }),
        category: CostType.Mooring,
        name,
        cost,
        unit,
        confidenceLevel: undefined,
      });
    } else if (isOperationsOverrideCost(installation.mooring)) {
      const { cost, unit, confidenceLevel } = installation.mooring;
      installationCosts.push({
        id: `mooring_installation`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
        }),
        category: CostType.Mooring,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    let fixedCosts: Cost[] = [];

    if (fixed.mooring?.lines !== undefined) {
      const {
        lines: { cost, unit, confidenceLevel },
      } = fixed.mooring;

      fixedCosts.push({
        id: `mooring_lines_${unit}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
        }),
        category: CostType.Mooring,
        name: "Mooring lines",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    } else {
      fixedCosts = mooringLineTypes.map(
        ({ id, name, cost, costUnit: _costUnit }) => {
          const costUnit = _CostUnit.parse(_costUnit);
          return {
            id: `mooring_lines_${id}`,
            amountId: amountId({
              unit: unitToAmountUnit[costUnit],
              category: CostType.Mooring,
              featureTypeId: id,
            }),
            category: CostType.Mooring,
            name,
            cost,
            unit: costUnit,
            confidenceLevel: undefined,
          };
        },
      );
    }

    if (fixed.mooring?.anchors !== undefined) {
      const {
        anchors: { cost, unit, confidenceLevel },
      } = fixed.mooring;

      fixedCosts.push({
        id: `mooring_anchors_${unit}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
          featureTypeId: "anchors",
        }),
        category: CostType.Mooring,
        name: "Anchors",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    if (fixed.mooring?.clumpWeights !== undefined) {
      const {
        clumpWeights: { cost, unit, confidenceLevel },
      } = fixed.mooring;

      fixedCosts.push({
        id: `mooring_clump_weights_${unit}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
          featureTypeId: "clump_weights",
        }),
        category: CostType.Mooring,
        name: "Clump weights",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    if (fixed.mooring?.buoys !== undefined) {
      const {
        buoys: { cost, unit, confidenceLevel },
      } = fixed.mooring;

      fixedCosts.push({
        id: `mooring_buoys_${unit}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Mooring,
          featureTypeId: "buoys",
        }),
        category: CostType.Mooring,
        name: "Buoys",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    return [...fixedCosts, ...customCosts, ...flatCosts, ...installationCosts];
  }),
);
