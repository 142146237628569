import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
} from "components/Organisation/Library/service";
import { useRefreshLibrarySubstation } from "components/Organisation/Library/substation/useOrgSubstationCrud";
import { substationResourceWithAccessOnNodeState } from "state/substationType";
import { getSubstationResourcesOnNode } from "services/libraryService";
import { nodesWithAccessToSubstation } from "components/Organisation/Library/state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeSubstationResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshLibrarySubstation = useRefreshLibrarySubstation();
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, substationId: string) => {
      const fallback = await get(
        substationResourceWithAccessOnNodeState({
          nodeId,
        }),
      );

      return addOrUpdateResourceOnNode(
        nodeId,
        substationId,
        "org_substation_manage",
      )
        .then(async () => {
          const updatedSubstations = await getSubstationResourcesOnNode(nodeId);
          aset(
            get,
            set,
            substationResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => updatedSubstations,
          );
          set(
            nodesWithAccessToSubstation({
              organisationId,
              resourceId: substationId,
            }),
          );
          refreshLibrarySubstation();
        })
        .catch((e) => {
          aset(
            get,
            set,
            substationResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId, refreshLibrarySubstation],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        substationResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "SUBSTATION",
          resourceId: resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          nodeId,
          "SUBSTATION",
          resourceId,
        );
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Substation type is currently being used in ${usage.length} substations, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(nodeId, resourceId, "org_substation_manage")
          .then(() => {
            aset(
              get,
              set,
              substationResourceWithAccessOnNodeState({
                nodeId,
              }),
              (cur) => cur.filter((c) => c.substation.id !== resourceId),
            );
            set(
              nodesWithAccessToSubstation({
                organisationId,
                resourceId,
              }),
            );
            refreshLibrarySubstation();
          })
          .catch((e) => {
            aset(
              get,
              set,
              substationResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, refreshLibrarySubstation, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeSubstationResourcesCrud;
