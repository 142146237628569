import { analysisStoppedText } from "analysis/warnings";
import { DownloadLcoeDataButton } from "components/FinancialAnalysis/Lcoe/DownloadLcoeData";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { useLcoe } from "finance/hooks/useLcoe";
import { useAtomValue } from "jotai";
import { colors } from "../../../styles/colors";
import { spaceMedium, spaceTiny } from "../../../styles/space";
import { CostUnit, unitToScalingMap } from "../../../types/financial";
import { formatGWhConditional } from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { Stat2, StatHeader, Stats } from "./Base.style";
import { PVInfoTag } from "components/FinancialAnalysis/costUtils";
import { getFinancialStoppedReason } from "finance/warnings";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";

export const LCoEWidget = () => {
  const { park, branch, triggerId, errorBoundaryResetKeys } =
    useDashboardContext();

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  if (invalidTypes.turbines)
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some turbines in the park have invalid turbine types."}
        />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="LCoE"
      id="Lcoe table"
      menuItems={<DownloadLcoeDataButton id={triggerId} buttonType="menu" />}
      resetKeys={errorBoundaryResetKeys}
    >
      <LCOETableInnerV2 />
    </SafeCard>
  );
};

const LCOETableInnerV2 = () => {
  const { triggerId } = useDashboardContext();

  const {
    inputs,
    results: { devex, capex, opex, decom, lcoe },
  } = useLcoe(triggerId);

  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(triggerId),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }
  const ExpendituresTitle = () => (
    <span style={{ display: "flex", gap: "1rem", alignItems: "baseline" }}>
      Expenditures
      <PVInfoTag />
    </span>
  );

  return (
    <Stats>
      <StatHeader title={<ExpendituresTitle />} value="" />
      <Stat2 title={"DEVEX"} value={devex.npv} unit={CostUnit.millionEuro} />
      <Stat2 title={"CAPEX"} value={capex.npv} unit={CostUnit.millionEuro} />
      <Stat2 title={"OPEX"} value={opex.npv} unit={CostUnit.millionEuro} />
      <Stat2 title={"DECOM"} value={decom.npv} unit={CostUnit.millionEuro} />
      <StatHeader title={"Other parameters"} value="" />
      <Stat2
        title={"AEP"}
        value={inputs.netAEP}
        format={formatGWhConditional}
      />
      <Stat2
        title={"Discount rate"}
        value={inputs.discountRate}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      />
      <Stat2
        title={"Inflation rate"}
        value={inputs.inflationRate}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      />
      <Stat2
        title={"CAPEX contingency"}
        value={inputs.capexContingency}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      />
      <Stat2
        title={"OPEX contingency"}
        value={inputs.opexContingency}
        format={(v: number) =>
          `${(v / unitToScalingMap[CostUnit.percent]).toFixed(1)} ${CostUnit.percent}`
        }
      />
      <Stat2 title={"Life time"} value={inputs.lifeTime} unit="years" />
      <div
        style={{
          borderTop: `1px solid ${colors.inputOutline}`,
          margin: `${spaceTiny} ${spaceMedium}`,
        }}
      />
      <Stat2
        bold={true}
        title={"LCoE"}
        value={lcoe}
        format={(v: number) => `${v.toFixed(1)} ${CostUnit.euroPerMWh}`}
      />
    </Stats>
  );
};
