import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtomValue } from "jotai";
import { v4 as uuid } from "uuid";
import { customerProjectAtomFamily } from "state/timeline";
import { getPathToBranch } from "components/Design/BranchTabBar/useNavigateToBranch";
import { BranchMeta } from "types/api";
import { FolderTreeItem } from "types/folderStructures";
import {
  findItemInTree,
  getAllResourcesInFolderTree,
  isFolderItem,
} from "business/folderStructure/utils";
import { Column, Row } from "components/General/Layout";
import { getPathPrefix } from "utils/utils";
import { IconREMSize, typography } from "styles/typography";
import { spacing1, spacing2, spacing3 } from "styles/space";
import { colors } from "styles/colors";
import Spinner from "@icons/spinner/Spinner";
import AddIcon from "@icons/24/Add.svg";
import BranchIcon from "@icons/24/Branch.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import FolderMenuOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import useBooleanState from "hooks/useBooleanState";
import { elementTreeSelectorFamily } from "components/ProjectElementsV2/state";
import { isPark } from "utils/predicates";
import { ElementTreeFolder } from "components/ProjectElementsV2/types";
import FolderMenuOpen from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosed from "@icons/20/FolderMenuClosed.svg";
import Button from "components/General/Button";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { elementTreeFlatten } from "components/ProjectElementsV2/utils";
import { useJotaiCallback } from "utils/jotai";
import { branchMetaFamily } from "state/jotai/branch";
import { parksFamily } from "state/jotai/park";
import {
  SelectedParkCompare,
  selectedParksAtom,
} from "components/CompareParksModal/state";
import { useSearchParams } from "react-router-dom";
import { TUTORIAL_PROJECT_SEARCH_PARAM } from "components/OnboardingTours/Tour";
import styled from "styled-components";
import {
  ExpandArrowWrapper,
  ListItemTopRow,
  ListItemWrapper,
  VisibleOnHover,
} from "components/FolderList/style";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";

const FolderChildren = styled(Column)`
  gap: ${spacing1};
`;

const FolderContainsActiveBranchDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  right: 0.5rem;
  background-color: ${colors.blue600};
`;

const EmptyMessage = ({ message }: { message: string }) => {
  return (
    <p
      style={{
        ...typography.contentAndButtons,
        color: colors.textDisabled,
      }}
    >
      {message}
    </p>
  );
};

const ItemName = styled.a`
  ${typography.contentAndButtons};
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: clip;
  white-space: nowrap;
  color: unset;
`;

const ParkItem = ({
  depth,
  name,
  onAddParkClick,
}: {
  parkId: string;
  name: string;
  depth: number;
  onAddParkClick(): void;
}) => {
  return (
    <ListItemWrapper
      enableDrag={false}
      isHoveredBottom={false}
      isHoveredTop={false}
    >
      <ListItemTopRow
        alignCenter
        hasIcon={true}
        clickable={true}
        hasExpandIcon={false}
        depth={depth}
      >
        <IconREMSize height={1.6} width={1.6} iconColor={colors.grey700}>
          <ParkIcon />
        </IconREMSize>
        <ItemName
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {name}
        </ItemName>
        <VisibleOnHover>
          <Button
            buttonType="primary"
            size="small"
            text="Add"
            icon={<AddIcon />}
            onClick={onAddParkClick}
          />
        </VisibleOnHover>
      </ListItemTopRow>
    </ListItemWrapper>
  );
};

const ProjectFolder = ({
  folderNode,
  defaultOpen,
  depth = 0,
  onAddParkClick,
}: {
  folderNode: ElementTreeFolder;
  defaultOpen: boolean;
  depth?: number;
  onAddParkClick(parkId: string): void;
}) => {
  const folder = folderNode.folder;
  const folderParks = useMemo(
    () => elementTreeFlatten(folderNode),
    [folderNode],
  );
  const hideAddAllButton = folderParks.length === 0;

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(defaultOpen);
    }
  }, [defaultOpen]);

  const onFolderClick = useCallback(() => {
    setIsOpen((curr) => !curr);
  }, []);

  return (
    <ListItemWrapper
      ref={elementRef}
      isHoveredBottom={false}
      isHoveredTop={false}
      enableDrag={false}
    >
      <ListItemTopRow
        alignCenter
        onClick={onFolderClick}
        hasIcon={true}
        hasExpandIcon={true}
        clickable={true}
        depth={depth}
      >
        <ExpandArrowWrapper open={isOpen}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <IconREMSize
          height={2}
          width={2}
          style={{
            position: "relative",
          }}
        >
          {isOpen ? <FolderMenuOpen /> : <FolderMenuClosed />}
        </IconREMSize>
        <ItemName
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {folder.folderName}
        </ItemName>
        <VisibleOnHover className={hideAddAllButton ? "hidden" : undefined}>
          <Button
            size="small"
            text="Add all"
            icon={<AddIcon />}
            onClick={(e) => {
              e.stopPropagation();
              for (const park of folderParks) {
                onAddParkClick(park.feature.id);
              }
            }}
          />
        </VisibleOnHover>
      </ListItemTopRow>

      {isOpen && (
        <>
          {folderNode.children.length === 0 && (
            <ListItemTopRow
              alignCenter
              depth={depth + 1}
              hasIcon={false}
              hasExpandIcon={false}
            >
              <EmptyMessage message="Folder is empty" />
            </ListItemTopRow>
          )}
          {folderNode.children.map((node) => {
            if (node.type === "folder")
              return (
                <ProjectFolder
                  key={node.id}
                  folderNode={node}
                  defaultOpen={false}
                  depth={depth + 1}
                  onAddParkClick={onAddParkClick}
                />
              );
            if (isPark(node.feature)) {
              return (
                <ParkItem
                  key={node.id}
                  parkId={node.feature.id}
                  depth={depth + 1}
                  onAddParkClick={() => {
                    onAddParkClick(node.feature.id);
                  }}
                  name={node.feature.properties.name!}
                />
              );
            }
          })}
        </>
      )}
    </ListItemWrapper>
  );
};

const BranchFoldersAndParks = ({
  nodeId,
  branchId,
  depth,
  onAddParkClick,
  setHideAllButton,
}: {
  nodeId: string;
  branchId: string;
  depth: number;
  onAddParkClick(branchId: string, parkId: string): void;
  setHideAllButton(hide: boolean): void;
}) => {
  const branchTreeContent = useAtomValue(
    elementTreeSelectorFamily({
      nodeId: nodeId,
      branchId: branchId,
    }),
  );

  const foldersAndParks = useMemo(
    () =>
      branchTreeContent.children.filter(
        (child) => child.type === "folder" || isPark(child.feature),
      ),
    [branchTreeContent.children],
  );

  useEffect(() => {
    setHideAllButton(foldersAndParks.length === 0);
  }, [foldersAndParks, setHideAllButton]);

  if (foldersAndParks.length === 0) {
    return (
      <ListItemWrapper enableDrag={false}>
        <ListItemTopRow
          alignCenter
          depth={depth}
          hasIcon={false}
          hasExpandIcon={false}
        >
          <EmptyMessage message="Branch is empty" />
        </ListItemTopRow>
      </ListItemWrapper>
    );
  }

  return (
    <>
      {foldersAndParks.map((node) => {
        if (node.type === "folder") {
          return (
            <ProjectFolder
              key={node.id}
              folderNode={node}
              defaultOpen={false}
              depth={depth}
              onAddParkClick={(parkId: string) => {
                onAddParkClick(branchId, parkId);
              }}
            />
          );
        }
        return (
          <ParkItem
            key={node.id}
            parkId={node.feature.id}
            depth={depth}
            onAddParkClick={() => {
              onAddParkClick(branchId, node.feature.id);
            }}
            name={node.feature.properties.name!}
          />
        );
      })}
    </>
  );
};

export const BranchRow = ({
  branch,
  organisationId,
  nodeId,
  active,
  depth,
  onAddParkClick,
}: {
  branch: BranchMeta;
  organisationId: string;
  nodeId: string;
  active: boolean;
  depth: number;
  onAddParkClick(branchId: string, parkId: string): void;
}) => {
  const [searchParams] = useSearchParams();
  const isTutorialProject = searchParams.get(TUTORIAL_PROJECT_SEARCH_PARAM);
  const [isOpen, toggleIsOpen] = useBooleanState(
    Boolean(isTutorialProject) || active,
  );
  const [hideAddAllButton, setHideAllButton] = useState(false);
  const [isLoadingParks, toggleIsLoadingParks] = useBooleanState(false);
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );

  const elementRef = useRef<HTMLDivElement>(null);

  const onAddAllClick = useJotaiCallback(
    async (get, set, branchId: string) => {
      toggleIsLoadingParks();
      const branch = await get(
        branchMetaFamily({ projectId: nodeId, branchId }),
      );
      const parks = await get(parksFamily({ branchId }));
      toggleIsLoadingParks();
      setHideAllButton(parks.length === 0);

      const newItems = parks.map<SelectedParkCompare>((park) => ({
        comparisonId: uuid(),
        parkId: park.id,
        branchId: branchId,
        selectedAnalysisConfigurationId: branch?.analysisConfigurationId,
        selectedCostConfigurationId: branch?.costConfigurationId,
        selectedWindConfigurationId: branch?.windConfigurationId,
        selectedOperationsConfigurationId: branch?.operationsConfigurationId,
      }));

      // @ts-ignore I dont know why this is not working
      set(selectedParksAtom({ projectId: nodeId }), (curr) => {
        return (curr ?? []).concat(newItems);
      });
    },
    [nodeId, toggleIsLoadingParks],
  );

  return (
    <ListItemWrapper
      key={branch.id}
      ref={elementRef}
      enableDrag={false}
      isHoveredTop={false}
      isHoveredBottom={false}
    >
      <ListItemTopRow
        alignCenter
        hasIcon={true}
        hasExpandIcon={true}
        clickable={true}
        depth={depth}
        onClick={toggleIsOpen}
      >
        <ExpandArrowWrapper open={isOpen}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <IconREMSize height={1.4} width={1.4} iconColor={colors.grey700}>
          <BranchIcon />
        </IconREMSize>
        <ItemName
          href={getPathToBranch(
            getPathPrefix(project),
            organisationId,
            nodeId,
            branch.id,
          )}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {branch.title}
        </ItemName>
        <Row
          style={{
            gap: spacing3,
          }}
          alignCenter
        >
          <VisibleOnHover className={hideAddAllButton ? "hidden" : undefined}>
            <Button
              className={isLoadingParks ? "is-loading" : undefined}
              buttonType="primary"
              size="small"
              text="Add all"
              icon={isLoadingParks ? <Spinner size="1rem" /> : <AddIcon />}
              disabled={isLoadingParks}
              onClick={(e) => {
                e.stopPropagation();
                onAddAllClick(branch.id);
              }}
            />
          </VisibleOnHover>
          {active && (
            <div
              style={{
                padding: `${spacing1} ${spacing2}`,
                borderRadius: "4px",
                backgroundColor: colors.blue200,
              }}
            >
              <p style={typography.graphics}>Current branch</p>
            </div>
          )}

          <DescriptionModal
            size="small"
            disabled={true}
            defaultValue={branch.description}
            updateDescription={() => {}}
            subtitle={<div></div>}
          />
        </Row>
      </ListItemTopRow>
      {isOpen && (
        <React.Suspense
          fallback={
            <ListItemWrapper enableDrag={false}>
              <SkeletonBlock style={{ height: "4.2rem" }} />
            </ListItemWrapper>
          }
        >
          <BranchFoldersAndParks
            nodeId={nodeId}
            branchId={branch.id}
            depth={depth + 1}
            onAddParkClick={onAddParkClick}
            setHideAllButton={setHideAllButton}
          />
        </React.Suspense>
      )}
    </ListItemWrapper>
  );
};

export const BranchFolder = ({
  folder,
  showControls,
  depth,
  activeBranchId,
  folderTreeContainsActiveFolder,
  shouldForceOpen,
  children,
  addPark,
}: {
  folder: FolderTreeItem;
  showControls: boolean;
  depth: number;
  shouldForceOpen: boolean;
  activeBranchId: string;
  folderTreeContainsActiveFolder: boolean;
  children: (
    resourceId: string,
    parentFolder: FolderTreeItem,
    depth: number,
    index: number,
  ) => React.ReactNode;
  addPark(branchId: string, parkId: string): void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isForcedOpen, setIsForcedOpen] = useState(shouldForceOpen);
  const [isLoadingParks, toggleIsLoadingParks] = useBooleanState(false);
  const allBranchesInTree = useMemo(
    () => getAllResourcesInFolderTree(folder.children),
    [folder.children],
  );

  useEffect(() => {
    setIsForcedOpen(shouldForceOpen);
  }, [shouldForceOpen]);

  const handleOnAddAllClick = useJotaiCallback(
    async (get) => {
      const promises = [];
      toggleIsLoadingParks();
      for (const branch of allBranchesInTree) {
        promises.push(
          get(parksFamily({ branchId: branch.id })).then((parks) => {
            return {
              parks,
              branchId: branch.id,
            };
          }),
        );
      }
      const results = await Promise.all(promises);
      for (const result of results) {
        for (const park of result.parks) {
          addPark(result.branchId, park.id);
        }
      }
      toggleIsLoadingParks();
    },
    [addPark, toggleIsLoadingParks, allBranchesInTree],
  );

  const shouldShowAsOpen = isOpen || isForcedOpen;
  return (
    <ListItemWrapper
      isHoveredBottom={false}
      isHoveredTop={false}
      isHoveredMiddle={false}
      enableDrag={true}
    >
      <ListItemTopRow
        alignCenter
        hasIcon={true}
        hasExpandIcon={true}
        clickable={true}
        onClick={() => {
          if (shouldShowAsOpen) {
            setIsOpen(false);
            setIsForcedOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        depth={depth}
      >
        <ExpandArrowWrapper open={shouldShowAsOpen}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <IconREMSize height={2} width={2}>
          {shouldShowAsOpen ? <FolderMenuOpenIcon /> : <FolderMenuClosedIcon />}
        </IconREMSize>
        <ItemName>{folder.name}</ItemName>
        <Row alignCenter>
          <VisibleOnHover
            className={allBranchesInTree.length === 0 ? "hidden" : undefined}
          >
            <Button
              buttonType="primary"
              size="small"
              text="Add all"
              className={isLoadingParks ? "is-loading" : undefined}
              disabled={isLoadingParks}
              icon={isLoadingParks ? <Spinner size="1rem" /> : <AddIcon />}
              onClick={(e) => {
                e.stopPropagation();
                handleOnAddAllClick();
              }}
            />
          </VisibleOnHover>
          {folderTreeContainsActiveFolder && !shouldShowAsOpen && (
            <FolderContainsActiveBranchDot />
          )}
        </Row>
      </ListItemTopRow>
      {shouldShowAsOpen && (
        <FolderChildren>
          {folder.children.length === 0 ? (
            <ListItemWrapper enableDrag={false}>
              <ListItemTopRow
                alignCenter
                depth={depth + 1}
                hasIcon={false}
                hasExpandIcon={false}
              >
                <EmptyMessage message="Folder is empty" />
              </ListItemTopRow>
            </ListItemWrapper>
          ) : (
            folder.children.map((child, index) => {
              if (isFolderItem(child)) {
                const containsActiveBranch = Boolean(
                  findItemInTree(activeBranchId, child.children),
                );
                return (
                  <BranchFolder
                    key={child.id}
                    folder={child}
                    showControls={showControls}
                    depth={depth + 1}
                    shouldForceOpen={shouldForceOpen || containsActiveBranch}
                    activeBranchId={activeBranchId}
                    folderTreeContainsActiveFolder={containsActiveBranch}
                    addPark={addPark}
                  >
                    {children}
                  </BranchFolder>
                );
              }
              return children(child.id, folder, depth, index);
            })
          )}
        </FolderChildren>
      )}
    </ListItemWrapper>
  );
};
