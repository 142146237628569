import { useSetAtom } from "jotai";
import React, { useEffect, useRef, useMemo } from "react";
import Spinner from "@icons/spinner/Spinner";
import { fetchEnhancer } from "../../../../services/utils";
import ToggleableRow from "../../../ToggleableRow/ToggleableRow";
import {
  currentSelectionWMSAtom,
  WMSSelection,
} from "../../../../state/selection";
import { ModalFrame } from "../../../General/Layout";
import { spacing4 } from "styles/space";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { isDefined } from "utils/predicates";

const getWmsLayerInfoSelectorFamily = atomFamily(
  (wmsInfo: Omit<WMSSelection, "html">) =>
    atom<Promise<WMSSelection>>(async () => {
      const res = await fetchEnhancer(wmsInfo.url, {
        method: "get",
      });
      const html = await res.text();
      return {
        ...wmsInfo,
        html,
      };
    }),
);

const getWmsLayerInfosSelectorFamily = atomFamily(
  (wmsInfos: Omit<WMSSelection, "html">[]) =>
    atom(async (get) => {
      return wmsInfos.map((i) =>
        get(loadable(getWmsLayerInfoSelectorFamily(i))),
      );
    }),
);

const WMSInfos = ({ selections }: { selections: WMSSelection[] }) => {
  const wmsLayerInfos = useAtomValue(
    getWmsLayerInfosSelectorFamily(selections),
  );

  const isLoading = useMemo(
    () => !!wmsLayerInfos.find((l) => l.state === "loading"),
    [wmsLayerInfos],
  );
  const finishedData = useMemo(
    () =>
      wmsLayerInfos
        .map((l) => (l.state === "hasData" ? l.data : undefined))
        .filter(isDefined)
        .filter((l) => {
          var el = document.createElement("html");
          el.innerHTML = l.html ?? "";
          const body = el.getElementsByTagName("body")[0];
          return body.childElementCount !== 0;
        }),
    [wmsLayerInfos],
  );

  return (
    <>
      <div>{isLoading && <Spinner size="2rem" />}</div>
      {!isLoading && finishedData.length === 0 && (
        <div>No data found in this area...</div>
      )}
      {finishedData.map((s) => (
        <WMSInfo
          key={s.type}
          layerName={s.type}
          html={s.html}
          legendGraphicUrl={s.legendGraphicUrl}
        />
      ))}
    </>
  );
};

const WMSInfo = ({
  layerName,
  legendGraphicUrl,
  html,
}: {
  layerName: string;
  legendGraphicUrl: string;
  html: string | undefined;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframeRef?.current) return;
    iframeRef.current.src =
      "data:text/html;charset=utf-8," + encodeURIComponent(html ?? "");
  }, [iframeRef, html]);

  return (
    <>
      <h4>{layerName}</h4>
      <iframe ref={iframeRef} title="WMS layer info" />
      <ToggleableRow title={"Legend graphic"} defaultOpen={true}>
        <img src={legendGraphicUrl} alt={"Legend graphic"} />
      </ToggleableRow>
    </>
  );
};

const WMSInfoModal = ({ selections }: { selections: WMSSelection[] }) => {
  const setSelection = useSetAtom(currentSelectionWMSAtom);
  return (
    <React.Suspense fallback={null}>
      <ModalFrame
        title="Info"
        onExit={() => setSelection([])}
        style={{
          position: "fixed",
          top: `calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height) + ${spacing4})`,
          right: spacing4,
        }}
      >
        <WMSInfos selections={selections} />
      </ModalFrame>
    </React.Suspense>
  );
};

export default WMSInfoModal;
