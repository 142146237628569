import { SnapFeature } from "./CustomModes/lib/snapping";
import { Position } from "geojson";
import { useEffect, useMemo } from "react";
import { movingWindow } from "../../utils/utils";
import { useAtomValue } from "jotai";
import { featuresListAtom } from "state/jotai/features";

export let currentSnapPoints: Position[] = [];
export let currentSnapLines: [Position, Position][] = [];

export default function useUpdateSnapPoints() {
  const projectFeatures = useAtomValue(featuresListAtom);

  useEffect(() => {
    const snapPoints: Position[] = projectFeatures.flatMap((f) => {
      const type = f.geometry?.type;
      if (type === "Point") return [f.geometry.coordinates];
      else if (type === "LineString") return f.geometry.coordinates;
      else if (type === "Polygon") return f.geometry.coordinates.flat();
      return [];
    });
    currentSnapPoints = snapPoints;

    const snapLines: [Position, Position][] = projectFeatures.flatMap((f) => {
      const type = f.geometry?.type;
      if (type === "LineString") return movingWindow(f.geometry.coordinates);
      else if (type === "Polygon")
        return f.geometry.coordinates.map((c) => movingWindow(c)).flat();
      return [];
    });
    currentSnapLines = snapLines;
  }, [projectFeatures]);
}

export function useSnapFeatures() {
  const projectFeatures = useAtomValue(featuresListAtom);
  const snapFeatures = useMemo(() => {
    const newSF: SnapFeature[] = projectFeatures.flatMap((f) => {
      if (f.geometry.type === "Point")
        return {
          featureId: f.id,
          point: f.geometry.coordinates,
          type: "Point",
          featureType: f.properties.type,
          parentIds: f.properties.parentIds,
        };
      return [];
    });
    return newSF;
  }, [projectFeatures]);

  return snapFeatures;
}
