import { isFolderItem } from "business/folderStructure/utils";
import { EditableTextInternalState } from "components/General/EditableText";
import { Row } from "components/General/Layout";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import { useRef } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import { spacing1 } from "styles/space";
import { typography, IconREMSize } from "styles/typography";
import { BranchMeta } from "types/api";
import {
  FolderTreeItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import { findCursorPositionInElement } from "utils/dragNDropUtils";
import { LibraryManageRole } from "../types";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import FolderMenuOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import { getAcceptableTypes } from "./utils";

import { colors } from "styles/colors";
const FolderName = styled.p`
  ${typography.body};
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: clip;
  white-space: nowrap;
`;

const FolderTopRow = styled(Row)<{ depth: number }>`
  cursor: pointer;
  min-height: 5rem;
  border-radius: 4px;
  padding-left: ${(p) => `${p.depth * 2.6}rem`};
`;

const FolderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing1};
  flex: 4;
  margin: 0.2rem 1.5rem;
  overflow: hidden;
`;

const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
  svg {
    path {
      fill: ${colors.grey500};
    }
  }`};
`;

const TOP_BOTTOM_MARGIN = 12;

export const Folder = ({
  folder,
  parentFolder,
  showControls,
  depth,
  shouldForceOpen,
  onDropOnFolder,
  onFolderDropOnFolder,
  isLegalFolderMove,
  onReorder,
  onToggleOpen,
  libraryManageRole,
}: {
  folder: FolderTreeItem;
  parentFolder?: FolderTreeItem;
  showControls: boolean;
  depth: number;
  shouldForceOpen: boolean;
  onDropOnFolder(folder: FolderTreeItem, droppedItemId: string): void;
  onFolderDropOnFolder(
    folder: FolderTreeItem,
    droppedFolder: FolderTreeItem,
    parentFolder?: FolderTreeItem,
  ): void;
  onReorder(
    folder: FolderTreeItem,
    index: number,
    parentFolder?: FolderTreeItem,
  ): void;
  isLegalFolderMove(
    folder: FolderTreeItem,
    targetFolder: FolderTreeItem,
  ): boolean;
  onToggleOpen?: () => void;
  libraryManageRole: LibraryManageRole;
}) => {
  const { editFolderName } = useOrgFolderStructureCrud({
    libraryManageRole,
  });

  const dropElementRef = useRef<HTMLDivElement>(null);

  const [, dropRef] = useDrop<
    BranchMeta | FolderTreeItemOrResourceItem,
    {
      handled: boolean;
    },
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: getAcceptableTypes(libraryManageRole),
      canDrop: (draggedItem, _monitor) => {
        if (isFolderItem(draggedItem)) {
          return isLegalFolderMove(draggedItem, folder);
        }
        return true;
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem, monitor) => {
        if (monitor.getDropResult()?.handled) {
          return;
        }

        if (draggedItem.id === folder.id) {
          return {
            handled: true,
          };
        }

        const draggedItemIsFolder = isFolderItem(draggedItem);

        if (!draggedItemIsFolder) {
          onDropOnFolder(folder, draggedItem.id);
          return {
            handled: true,
          };
        }

        const zone = findCursorPositionInElement(
          dropElementRef.current,
          monitor.getClientOffset(),
          TOP_BOTTOM_MARGIN,
        );

        if (zone === "middle") {
          if (draggedItem.parentId === folder.id) {
            return {
              handled: true,
            };
          }
          onFolderDropOnFolder(folder, draggedItem, parentFolder);
        } else {
          const newIndex =
            zone === "bottom"
              ? (folder.sortOrder ?? 0) + 1
              : folder.sortOrder ?? 0;
          onReorder(draggedItem, newIndex, parentFolder);
        }
        return {
          handled: true,
        };
      },
    }),
    [
      folder,
      isLegalFolderMove,
      onDropOnFolder,
      onFolderDropOnFolder,
      onReorder,
      parentFolder,
      libraryManageRole,
    ],
  );

  dropRef(dropElementRef);

  const shouldShowAsOpen = shouldForceOpen;
  return (
    <FolderWrapper ref={dropElementRef}>
      <FolderTopRow
        alignCenter
        onClick={() => {
          onToggleOpen?.();
        }}
        depth={depth}
      >
        <ExpandArrowWrapper open={shouldShowAsOpen}>
          <IconREMSize height={0.8} width={0.8}>
            <ChevronDownIcon />
          </IconREMSize>
        </ExpandArrowWrapper>
        <IconREMSize height={2} width={2}>
          {shouldShowAsOpen ? <FolderMenuOpenIcon /> : <FolderMenuClosedIcon />}
        </IconREMSize>
        <EditableTextInternalState
          value={folder.name}
          renderText={(text) => <FolderName>{text}</FolderName>}
          style={{
            overflow: "hidden",
          }}
          onEnter={(newValue) => {
            return editFolderName(folder.id, newValue);
          }}
          disabled={!showControls}
        />
      </FolderTopRow>
    </FolderWrapper>
  );
};
