import { useCallback, useEffect, useMemo, useState } from "react";
import { CABLE_CORRIDOR_PROPERTY_TYPE } from "../../../constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../../../constants/division";
import { PARK_PROPERTY_TYPE } from "../../../constants/park";
import { useSetPropertyOnProjectFeatures } from "../../../hooks/useSetPropertyOnProjectFeatures";
import ArrowDownIcon from "@icons/24/ArrowDown.svg";
import ArrowRightIcon from "@icons/24/ArrowRight.svg";
import SubAreaIcon from "@icons/24/SideBarIcons/SubArea.svg";
import { colors } from "../../../styles/colors";
import {
  ExclusionDomainDefault,
  PolygonFeatureType,
  ProjectFeature,
} from "../../../types/feature";
import { isPark } from "../../../utils/predicates";
import { MenuItem } from "../../General/Menu";
import { MenuButton } from "../../General/MenuButton";
import { trackCanvasOption } from "../MenuTracking";
import { findFeatureChildren } from "../../../state/projectLayers";
import { branchIdAtom, parkIdAtom, projectIdAtom } from "state/pathParams";
import Tooltip from "components/General/Tooltip";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { TypeAreaDot, TypeDot } from "components/General/Icons";
import { featuresListAtom } from "state/jotai/features";
import { useAtomValue } from "jotai";
import { parksFamily } from "state/jotai/park";
import { mapAtom } from "state/map";
import { removeHover, setHover } from "components/Mapbox/utils";
import { parkSourceId } from "components/Mapbox/constants";

const polygonTypeToName: Record<PolygonFeatureType | "other", string> = {
  [PARK_PROPERTY_TYPE]: "Park",
  [SUB_AREA_PROPERTY_TYPE]: "Sub area",
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: "Exclusion zone",
  [CABLE_CORRIDOR_PROPERTY_TYPE]: "Cable corridor",
  other: "Other",
  "slack-region": "Slack Region",
  "park-cable-partition-polygon": "Cable partition",
  "park-cable-chain-polygon": "Cable chain",
  BathymetryUserUploadedType: "Bathymetry",
  GeoTiffUserUploadedImageType: "Geotiff",
};

export default function MultiPolygonTypeSelector({
  selections,
  disabled,
  selectedType,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  selectedType?: PolygonFeatureType | "other";
}) {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const parkId = useAtomValue(parkIdAtom);
  const { showConfirm } = useConfirm();

  const map = useAtomValue(mapAtom);
  const projectFeatures = useAtomValue(featuresListAtom);
  const parks = useAtomValue(parksFamily({ branchId: branchId ?? "" }));

  const [hoveredParkId, setHoveredParkId] = useState<string | undefined>();

  useEffect(() => {
    if (!map) return;
    if (hoveredParkId) {
      setHover(map, parkSourceId, hoveredParkId);
      return () => {
        removeHover(map, parkSourceId, hoveredParkId);
      };
    }
  }, [hoveredParkId, map]);

  const setProperties = useSetPropertyOnProjectFeatures();

  const ids = useMemo(() => selections.map((s) => s.id), [selections]);
  const onSelectItem = useCallback(
    async (
      item:
        | { type: "other" }
        | { type: "park-polygon" }
        | { type: typeof DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE }
        | { type: PolygonFeatureType; parkId: string },
    ) => {
      trackCanvasOption("change-feature-type", {
        projectId,
        branchId,
      });
      switch (item.type) {
        case "other": {
          const selectedParks = selections.filter(isPark);

          if (
            selectedParks.some(
              (park) =>
                findFeatureChildren(projectFeatures, park.id).length > 0,
            ) &&
            !(await showConfirm({
              title: "Remove park features",
              message:
                "When converting a park to Other, all features related to the park (turbines etc.) will be removed. Continue?",
            }))
          ) {
            return;
          }

          setProperties(ids, {
            type: undefined,
            color: colors.defaultCanvasLayer,
            parentIds: undefined,
          });
          return;
        }
        case SUB_AREA_PROPERTY_TYPE: {
          setProperties(ids, {
            type: SUB_AREA_PROPERTY_TYPE,
            color: colors.subArea,
            parentIds: [item.parkId],
          });
          return;
        }
        case DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE: {
          setProperties(ids, {
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
            color: colors.exclusionZone,
            domain: ExclusionDomainDefault,
          });
          return;
        }
      }
    },
    [
      showConfirm,
      projectId,
      branchId,
      setProperties,
      ids,
      selections,
      projectFeatures,
    ],
  );

  return (
    <MenuButton
      side="right"
      offset={[-12, 0]}
      icon={<ArrowRightIcon />}
      iconOpen={<ArrowDownIcon />}
      iconPosition="right"
      buttonStyle={{
        border: "none",
        height: "fit-content",
        justifyContent: "space-between",
        padding: 0,
        gap: "1.2rem",
      }}
      buttonType="dropdown"
      buttonText={polygonTypeToName[selectedType ?? "other"]}
      disabled={disabled}
    >
      <MenuItem
        name={"Other"}
        onClick={() => onSelectItem({ type: "other" })}
        icon={<TypeDot dotColor={colors.other} />}
      />
      <Tooltip
        text={`Split multipolygon into polygons to convert it to parks`}
        position="right"
        innerDivStyle={{
          width: "100%",
        }}
      >
        <MenuItem
          disabled
          name={polygonTypeToName[PARK_PROPERTY_TYPE]}
          icon={<TypeDot dotColor={colors.park} />}
          style={{
            width: "100%",
          }}
        />
      </Tooltip>
      <MenuItem
        name={polygonTypeToName[DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]}
        onClick={() =>
          onSelectItem({
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          })
        }
        icon={<TypeDot dotColor={colors.exclusionZone} />}
        disabled={selectedType === PARK_PROPERTY_TYPE}
      />
      {parkId ? (
        <MenuItem
          name={polygonTypeToName[SUB_AREA_PROPERTY_TYPE]}
          onClick={() =>
            onSelectItem({
              type: SUB_AREA_PROPERTY_TYPE,
              parkId,
            })
          }
          icon={<SubAreaIcon />}
          disabled={selectedType === PARK_PROPERTY_TYPE}
        />
      ) : (
        <Tooltip
          text="You need at least one park to be able to convert the feature to this type"
          disabled={parks.length > 0}
          innerDivStyle={{
            width: "100%",
          }}
          position="right"
        >
          <MenuItem
            name={polygonTypeToName[SUB_AREA_PROPERTY_TYPE]}
            icon={<SubAreaIcon />}
            style={{
              width: "100%",
            }}
            disabled={selectedType === PARK_PROPERTY_TYPE || parks.length === 0}
          >
            {parks.map((park) => (
              <MenuItem
                key={park.id}
                name={park.properties.name ?? ""}
                onClick={() =>
                  onSelectItem({
                    type: SUB_AREA_PROPERTY_TYPE,
                    parkId: park.id,
                  })
                }
                onMouseEnter={() => setHoveredParkId(park.id)}
                onMouseLeave={() => setHoveredParkId(undefined)}
              />
            ))}
          </MenuItem>
        </Tooltip>
      )}
      <Tooltip
        text={`Split multipolygon into polygons to convert it to cable corridors`}
        position="right"
        innerDivStyle={{
          width: "100%",
        }}
      >
        <MenuItem
          style={{
            width: "100%",
          }}
          disabled
          name={polygonTypeToName[CABLE_CORRIDOR_PROPERTY_TYPE]}
          icon={<TypeAreaDot dotColor={colors.cableCorridor} />}
        />
      </Tooltip>
    </MenuButton>
  );
}
