import { analysisStoppedText } from "analysis/warnings";
import { NominalInfoTag } from "components/FinancialAnalysis/costUtils";
import { DownloadIRRData } from "components/FinancialAnalysis/IRR/DownloadIRRData";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { orLoader } from "components/Loading/Skeleton";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { useIRR } from "finance/hooks/useIrr";
import { useAtomValue } from "jotai";
import { lunwrap } from "utils/jotai";
import { colors } from "../../../styles/colors";
import { spaceMedium, spaceTiny } from "../../../styles/space";
import { CostUnit } from "../../../types/financial";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { Stat, Stat2, StatHeaderLoadable, Stats } from "./Base.style";
import { getFinancialStoppedReason } from "finance/warnings";

export const IRRWidget = () => {
  const { park, branch, triggerId, errorBoundaryResetKeys } =
    useDashboardContext();

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );
  if (Object.values(invalidTypes).some((e) => e))
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some elements are using a type that is invalid"}
          type={"error"}
        />
      </CenterContainer>
    );

  return (
    <SafeCard
      title="IRR"
      id="IRR table"
      menuItems={<DownloadIRRData id={triggerId} buttonType={"menu"} />}
      resetKeys={errorBoundaryResetKeys}
    >
      <IRRTableInner />
    </SafeCard>
  );
};

const IRRTableInner = () => {
  const { triggerId } = useDashboardContext();

  const {
    results: { irr, revenues, costs },
  } = useIRR(triggerId);

  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(triggerId),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  const irrValueMaybe = lunwrap(irr);

  let irrValue = undefined;
  if (irrValueMaybe && "ok" in irrValueMaybe) {
    irrValue = `${Math.round(irrValueMaybe.ok * 10000) / 100} %`;
  } else if (irrValueMaybe && "error" in irrValueMaybe) {
    irrValue = "-";
  }

  return (
    <>
      <Stats>
        <StatHeaderLoadable
          title="Revenues"
          tag={<NominalInfoTag />}
          value={revenues.total}
          unit={CostUnit.millionEuro}
        />
        {orLoader(revenues.guaranteed.years, (years) => (
          <>
            {years.startYear && years.endYear && (
              <Stat2
                bold={false}
                key={"guaranteed"}
                title={`${years.startYear}-${years.endYear}`}
                value={revenues.guaranteed.total}
                unit={CostUnit.millionEuro}
              />
            )}
          </>
        ))}
        {orLoader(revenues.market.years, (years) => (
          <>
            {years.startYear && years.endYear && (
              <Stat2
                bold={false}
                key={"market"}
                title={`${years.startYear}-${years.endYear}`}
                value={revenues.market.total}
                unit={CostUnit.millionEuro}
              />
            )}
          </>
        ))}

        <StatHeaderLoadable
          title={"Expenditures"}
          tag={<NominalInfoTag />}
          value={costs.total}
          unit={CostUnit.millionEuro}
        />
        <Stat2
          bold={false}
          key={"CAPEX"}
          title={"CAPEX"}
          value={costs.capex}
          unit={CostUnit.millionEuro}
        />
        <Stat2
          bold={false}
          key={"DEVEX"}
          title={"DEVEX"}
          value={costs.devex}
          unit={CostUnit.millionEuro}
        />
        <Stat2
          bold={false}
          key={"OPEX"}
          title={"OPEX"}
          value={costs.opex}
          unit={CostUnit.millionEuro}
        />
        <Stat2
          bold={false}
          key={"DECOM"}
          title={"DECOM"}
          value={costs.decom}
          unit={CostUnit.millionEuro}
        />

        <div
          style={{
            borderTop: `1px solid ${colors.inputOutline}`,
            margin: `${spaceTiny} ${spaceMedium}`,
          }}
        />

        <Stat bold={true} title={"IRR"} value={irrValue} />
      </Stats>
    </>
  );
};
