import { useSearch } from "./useSearch";
import { organisationSubstationResourceState } from "components/Organisation/Library/state";
import { orgSubstationManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SubstationType } from "services/substationService";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";

const keys = ["name", "description"];

export function useSearchLibrarySubstations(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgSubstationManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredSubstations, rawData } =
    useSearch<SubstationType>(
      enabled,
      searchTerm,
      keys,
      organisationSubstationResourceState(organisationId),
    );

  const filteredSearchItems: SearchResultItem<SubstationType>[] =
    useMemo(() => {
      return filteredSubstations.map((result) => ({
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: getIcon(LibraryManageRole.SUBSTATION),
        onSelect: () => {
          navigate(
            `/organisation/${organisationId}/library/substation/${result.item.id}`,
          );
        },
      }));
    }, [filteredSubstations, navigate, organisationId]);

  const searchableItems: SearchItem<SubstationType>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/substation/${item.id}`,
        );
      },
      icon: getIcon(LibraryManageRole.SUBSTATION),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredSubstations: filteredSearchItems,
    searchableItems,
  };
}
