import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { mapControlsAtom, mapInteractionSelector } from "../../../state/map";
import { toastMessagesAtom } from "../../../state/toast";
import useAddFeaturesIntoElementsWithinLimits from "../../../hooks/useAddFeaturesIntoElementsWithinLimits";
import { currentExternalLayerSelection } from "../../../state/externalLayerSelection";
import { useAtomValue } from "jotai";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import { featuresListAtom } from "state/jotai/features";
import { copy } from "./copy";
import usePasteFeatures from "./usePasteFeatures";
import { _VindFeaturesParser } from "./utils";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import { projectIdAtom } from "state/pathParams";
import { UploadModalType } from "components/UploadModal/UploadModal";
import { UploadFileType } from "components/UploadModal/components/NewUploadTab/types";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { uploadFeaturesFromCoordinatesStateAtom } from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/UploadFeaturesFromCoordinatesGeneral";
import { isCoordinateString } from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/utils";

const CopyPasteSelectedFeature = () => {
  const mapControls = useAtomValue(mapControlsAtom);
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const projectFeatures = useAtomValue(featuresListAtom);
  const addFeaturesToElementsWithinLimits =
    useAddFeaturesIntoElementsWithinLimits();
  const { pasteFeatures } = usePasteFeatures();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setUploadFeaturesFromCoordinatesState = useSetAtom(
    uploadFeaturesFromCoordinatesStateAtom,
  );

  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);
  const currentFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const externalLayerSelection = useAtomValue(currentExternalLayerSelection);
  const projectId = useAtomValue(projectIdAtom);
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (
      !mapInteraction.copy ||
      (currentFeatures.length === 0 && externalLayerSelection.length === 0)
    ) {
      return;
    }

    const onCopy = () => {
      if (window.getSelection()?.toString() !== "") {
        return;
      }

      return copy(
        currentFeatures,
        externalLayerSelection,
        projectFeatures,
        setToastMessagesAtom,
        allLayerSettings,
      );
    };
    document.body.addEventListener("copy", onCopy);
    return () => document.body.removeEventListener("copy", onCopy);
  }, [
    mapControls,
    currentFeatures,
    externalLayerSelection,
    setToastMessagesAtom,
    mapInteraction,
    projectFeatures,
    allLayerSettings,
  ]);

  useEffect(() => {
    if (!mapInteraction.paste) {
      return;
    }

    const onPaste = (e: Event) => {
      if (!(e instanceof ClipboardEvent)) {
        return;
      }

      if (
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLInputElement
      ) {
        return true;
      }

      const text = e.clipboardData?.getData("text/plain");
      if (!text) {
        return;
      }

      // Check if the text is coordinate data
      if (isCoordinateString(text)) {
        const coordinates = text;
        setUploadFeaturesFromCoordinatesState(coordinates);
        setMidScreenModalTypeOpen({
          modalType: UploadModalType,
          metadata: {
            preSelectedFileType: UploadFileType.FEATURE_FROM_COORDINATES,
          },
        });
        return;
      }

      // Existing JSON parsing logic
      let json: any;
      try {
        json = JSON.parse(text);
      } catch (er) {
        console.log("Could not parse pasted data", er);
        return;
      }

      const parse = _VindFeaturesParser.safeParse(json);
      if (!parse.success) {
        return;
      }
      return pasteFeatures(parse.data, addFeaturesToElementsWithinLimits);
    };
    window.addEventListener("paste", onPaste);
    return () => window.removeEventListener("paste", onPaste);
  }, [
    mapInteraction,
    addFeaturesToElementsWithinLimits,
    currentFeatures,
    projectFeatures,
    pasteFeatures,
    setUploadFeaturesFromCoordinatesState,
    setMidScreenModalTypeOpen,
  ]);

  return null;
};

export default CopyPasteSelectedFeature;
