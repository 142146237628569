import { useState, useCallback, useEffect } from "react";
import { Map } from "mapbox-gl";
import {
  MapboxSuggestion,
  getMapboxAddressSuggestions,
  retrieveMapboxAddress,
} from "../service";
import EarthIcon from "@icons/24/Earth.svg";
import { SearchResultItem } from "../state";

export function useSearchMapbox(
  searchTerm: string,
  map: Map | null | undefined,
  sessionToken: string,
) {
  const [mapboxSuggestions, setMapboxSuggestions] = useState<
    SearchResultItem<MapboxSuggestion>[]
  >([]);
  const [searchMapboxAddressInProgress, setSearchMapboxAddressInProgress] =
    useState(false);

  const goToAddress = useCallback(
    async (suggestion: MapboxSuggestion) => {
      if (!map) return;
      const suggestionAddress = await retrieveMapboxAddress(
        suggestion.mapbox_id,
        sessionToken,
      );
      const features = suggestionAddress.features;
      if (features.length === 0) {
        return;
      }

      const point = features[0];
      if (!point.geometry || point.geometry.type !== "Point") return;

      map.flyTo({
        center: point.geometry.coordinates as [number, number],
        zoom: 10,
      });
    },
    [map, sessionToken],
  );

  const searchMapboxAddress = useCallback(
    async (phrase: string) => {
      if (phrase.length === 0 || !map) return [];
      const center = map.getCenter();
      setSearchMapboxAddressInProgress(true);
      const data = await getMapboxAddressSuggestions(
        phrase,
        [center.lng, center.lat],
        sessionToken,
      ).finally(() => setSearchMapboxAddressInProgress(false));

      const suggestionsWithOnSelect = data.map((suggestion) => ({
        id: suggestion.mapbox_id,
        item: suggestion,
        name: suggestion.name,
        score: 0.99,
        icon: <EarthIcon />,
        onSelect: () => goToAddress(suggestion),
      }));

      setMapboxSuggestions(suggestionsWithOnSelect);
    },
    [map, sessionToken, goToAddress],
  );

  useEffect(() => {
    const timeout = setTimeout(() => searchMapboxAddress(searchTerm), 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTerm, searchMapboxAddress]);

  return {
    mapboxSuggestions,
    searchMapboxAddressInProgress,
  };
}
