import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  usersNodeAccessInNodeAndSubnodesAtomFamily,
  usersInOrganisationState,
} from "components/Organisation/state";
import {
  GroupNodeAccess,
  GroupNodeAccessWithMeta,
  UserNodeAccess,
  UserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { Column, Row } from "components/General/Layout";
import { typography } from "styles/typography";
import { spacing1, spacing8 } from "styles/space";
import {
  ContentTableColumn,
  ContentTableRow,
  SecondaryText,
  Text,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { IconBtn } from "components/General/Icons";
import InformationIcon from "@icons/24/Information.svg";
import {
  groupsInNodeAndSubnodesAtomFamily,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import Tooltip, { TooltipText } from "components/General/Tooltip";
import { colors } from "styles/colors";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import GroupLink from "./GroupLink";
import RoundGroupIcon from "./RoundGroupIcon";
import { NumberOfGroupMembers } from "../../ResourceContent/modals/ResourceAccessModal";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";

type UserNodeAccessWithNodeType = {
  user_id: string;
  nodes: (UserNodeAccess & {
    type: string | undefined;
  })[];
};

type GroupNodeAccessWithNodeType = {
  group_id: string;
  nodes: (GroupNodeAccess & {
    type: string | undefined;
  })[];
};

const getAccessToText = (nrProjects: number, nrFolders: number): string => {
  const accessToSentance: string[] = [];
  if (nrProjects > 0 && nrFolders > 0) {
    accessToSentance.push(
      `${nrProjects} project${nrProjects !== 1 ? "s" : ""}`,
      "and",
      `${nrFolders} folder${nrFolders !== 1 ? "s" : ""}`,
    );
  } else if (nrProjects > 0) {
    accessToSentance.push(
      `${nrProjects} project${nrProjects !== 1 ? "s" : ""}`,
    );
  } else if (nrFolders > 0) {
    accessToSentance.push(`${nrFolders} folder${nrFolders !== 1 ? "s" : ""}`);
  }

  return accessToSentance.join(" ");
};

const AccessTooltipContent = ({
  nodes,
}: {
  nodes:
    | UserNodeAccessWithNodeType["nodes"]
    | GroupNodeAccessWithNodeType["nodes"];
}) => {
  const organisationId = useAtomValue(organisationIdAtom);

  const { loadedState: orgNodes } = useNodesInOrganisationState(
    organisationId!,
  );

  const navigate = useNavigate();
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  if (!organisationId) {
    return null;
  }

  return (
    <>
      {nodes.map((node) => {
        const orgNode = orgNodes.find((n) => n.id === node.node_id);
        const isFolder = node.type === "folder";
        if (!orgNode) return null;
        return (
          <Row
            key={node.node_id}
            style={{
              wordSpacing: "unset",
              wordBreak: "unset",
              wordWrap: "unset",
            }}
          >
            <TooltipText secondary={false} theme="dark">
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {node.resource_name}
              </span>
              {isFolder ? (
                <>
                  {" "}
                  in entire folder{" "}
                  <a
                    href={`/organisation/${organisationId}/projects/${orgNode.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate({
                        pathname: `/organisation/${organisationId}/projects/${orgNode.id}`,
                      });
                      setContent({
                        type: "project",
                        id: orgNode.id,
                      });
                    }}
                    style={{
                      color: colors.blue300,
                    }}
                  >
                    {orgNode.name}
                  </a>
                </>
              ) : (
                <>
                  {" "}
                  in project{" "}
                  <a
                    href={`/organisation/${organisationId}/projects/${orgNode.parent_id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate({
                        pathname: `/organisation/${organisationId}/projects/${orgNode.parent_id}`,
                      });
                      setContent({
                        type: "project",
                        id: orgNode.id,
                      });
                    }}
                    style={{
                      color: colors.blue300,
                    }}
                  >
                    {orgNode.name}
                  </a>
                </>
              )}
            </TooltipText>
          </Row>
        );
      })}
    </>
  );
};

const UserAccessColumn = ({
  nodes,
}: {
  nodes:
    | UserNodeAccessWithNodeType["nodes"]
    | GroupNodeAccessWithNodeType["nodes"];
}) => {
  const allUniqueAccessTypesForNodes = useMemo(
    () => [...new Set(nodes.map((n) => n.resource_name))],
    [nodes],
  );
  const nrProjects = nodes.filter((node) => node.type === "project").length;
  const nrFolders = nodes.filter((node) => node.type === "folder").length;

  const accessToText = getAccessToText(nrProjects, nrFolders);

  return (
    <Column
      style={{
        flex: "0 0 50%",
        gap: spacing1,
        position: "relative",
      }}
    >
      {allUniqueAccessTypesForNodes.length === 1 ? (
        <>
          <Text
            style={{
              ...typography.body,
              margin: 0,
              textTransform: "capitalize",
            }}
          >
            {allUniqueAccessTypesForNodes[0]}
          </Text>
          <Row
            alignCenter
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <SecondaryText
              style={{
                ...typography.caption,
                margin: 0,
              }}
            >
              {allUniqueAccessTypesForNodes[0] === "admin" ? (
                <>Is admin in 1 {nodes[0].type}</>
              ) : allUniqueAccessTypesForNodes[0] === "editor" ? (
                <>Is editor in 1 {nodes[0].type}</>
              ) : (
                allUniqueAccessTypesForNodes[0] === "viewer" && (
                  <>Can view 1 {nodes[0].type}</>
                )
              )}
            </SecondaryText>
            <Tooltip
              text=""
              content={<AccessTooltipContent nodes={nodes} />}
              interactive={true}
              closeDelay={100}
              delay={100}
            >
              <IconBtn size="1.2rem" iconColor={colors.blue400}>
                <InformationIcon />
              </IconBtn>
            </Tooltip>
          </Row>
        </>
      ) : (
        <>
          <Text
            style={{
              ...typography.body,
              margin: 0,
            }}
          >
            Mixed
          </Text>
          <Row
            alignCenter
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <SecondaryText
              style={{
                ...typography.caption,
                margin: 0,
                flexWrap: "wrap",
                wordWrap: "break-word",
                textWrap: "wrap",
                wordSpacing: "normal",
              }}
            >
              Access to {accessToText}
            </SecondaryText>
            <Tooltip
              text=""
              content={<AccessTooltipContent nodes={nodes} />}
              interactive={true}
              closeDelay={100}
              delay={100}
            >
              <IconBtn size="1.2rem" iconColor={colors.blue400}>
                <InformationIcon />
              </IconBtn>
            </Tooltip>
          </Row>
        </>
      )}
    </Column>
  );
};

const useAllUsersAndGroupsWithAccessToNodeTree = ({
  organisationId,
  nodeId,
  ignoreUserIds,
  ignoreGroupIds,
}: {
  organisationId: string;
  nodeId: string;
  ignoreUserIds: string[];
  ignoreGroupIds: string[];
}): {
  users: UserNodeAccessWithNodeType[];
  groups: GroupNodeAccessWithNodeType[];
} => {
  const usersInNodeTree = useAtomValue(
    usersNodeAccessInNodeAndSubnodesAtomFamily({
      organisationId,
      nodeId,
    }),
  );
  const groupsInNodeTree = useAtomValue(
    groupsInNodeAndSubnodesAtomFamily({
      organisationId,
      nodeId,
    }),
  );

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);

  const usersInNodeTreeFiltered = useMemo(() => {
    const usersInNodeTreeRecord = usersInNodeTree
      .filter(
        (user) =>
          !ignoreUserIds.includes(user.user_id) && user.node_id !== nodeId,
      )
      .reduce<Record<string, UserNodeAccessWithNodeType>>((acc, curr) => {
        acc[curr.user_id] = acc[curr.user_id] ?? {
          user_id: curr.user_id,
          nodes: [],
        };
        acc[curr.user_id].nodes.push({
          ...curr,
          type: nodes.find((node) => node.id === curr.node_id)?.type,
        });
        return acc;
      }, {});

    return Object.values(usersInNodeTreeRecord);
  }, [ignoreUserIds, nodeId, nodes, usersInNodeTree]);

  const groupsInNodeTreeFiltered = useMemo(() => {
    const groupsInNodeTreeRecord = groupsInNodeTree
      .filter(
        (group) =>
          !ignoreGroupIds.includes(group.group_id) && group.node_id !== nodeId,
      )
      .reduce<Record<string, GroupNodeAccessWithNodeType>>((acc, curr) => {
        acc[curr.group_id] = acc[curr.group_id] ?? {
          group_id: curr.group_id,
          nodes: [],
        };
        acc[curr.group_id].nodes.push({
          ...curr,
          type: nodes.find((node) => node.id === curr.node_id)?.type,
        });
        return acc;
      }, {});

    return Object.values(groupsInNodeTreeRecord);
  }, [groupsInNodeTree, ignoreGroupIds, nodeId, nodes]);

  return {
    users: usersInNodeTreeFiltered,
    groups: groupsInNodeTreeFiltered,
  };
};

const UsersAndGroupsInNodeTree = ({
  organisationId,
  nodeId,
  usersWithAccessToNode,
  groupsWithAccessToNode,
}: {
  organisationId: string;
  nodeId: string;
  usersWithAccessToNode: UserNodeAccessWithMeta[];
  groupsWithAccessToNode: GroupNodeAccessWithMeta[];
}) => {
  const usersInOrg = useAtomValue(usersInOrganisationState(organisationId));
  const groupsInOrg = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const { users: usersInNodeTree, groups: groupsInNodeTree } =
    useAllUsersAndGroupsWithAccessToNodeTree({
      organisationId,
      nodeId,
      ignoreUserIds: usersWithAccessToNode.map((user) => user.user_id),
      ignoreGroupIds: groupsWithAccessToNode.map((group) => group.group_id),
    });

  return (
    <>
      {(usersInNodeTree.length > 0 || groupsInNodeTree.length > 0) && (
        <>
          <p
            style={{
              ...typography.caption,
              marginTop: spacing8,
            }}
          >
            Access to some of the items within this folder
          </p>
          <ContentTableColumn>
            {usersInNodeTree.map((userInNodeTree) => {
              const user = usersInOrg.find(
                (u) => u.user_id === userInNodeTree.user_id,
              );
              if (!user) {
                return null;
              }

              return (
                <ContentTableRow
                  key={userInNodeTree.user_id}
                  style={{
                    justifyContent: "space-between",
                    cursor: "unset",
                  }}
                >
                  <UserInfoOrganisationWithMemberLink
                    style={{
                      flex: "1 0 40%",
                    }}
                    userId={userInNodeTree.user_id}
                    size={3.2}
                  />
                  <UserAccessColumn nodes={userInNodeTree.nodes} />
                </ContentTableRow>
              );
            })}
            {groupsInNodeTree.map((groupInNodeTree) => {
              const group = groupsInOrg.find(
                (g) => g.id === groupInNodeTree.group_id,
              );
              if (!group) {
                return null;
              }

              return (
                <ContentTableRow
                  key={groupInNodeTree.group_id}
                  style={{
                    justifyContent: "space-between",
                    cursor: "unset",
                  }}
                >
                  <Row
                    style={{
                      gap: "0.8rem",
                      alignItems: "center",
                      flex: "1 0 40%",
                      overflow: "hidden",
                    }}
                  >
                    <GroupLink
                      organisationId={organisationId}
                      groupId={group.id}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <RoundGroupIcon />
                    </GroupLink>
                    <GroupLink
                      organisationId={organisationId}
                      groupId={group.id}
                      style={{
                        textDecoration: "none",
                        overflow: "hidden",
                      }}
                    >
                      <TextEllipsis
                        style={{
                          ...typography.body,
                          margin: 0,
                        }}
                      >
                        {group.name}
                        <NumberOfGroupMembers
                          groupId={group.id}
                          organisationId={organisationId}
                        ></NumberOfGroupMembers>
                      </TextEllipsis>
                    </GroupLink>
                  </Row>
                  <UserAccessColumn nodes={groupInNodeTree.nodes} />
                </ContentTableRow>
              );
            })}
          </ContentTableColumn>
        </>
      )}
    </>
  );
};

export default UsersAndGroupsInNodeTree;
