import { atom } from "jotai";
import { CableType, ExportCableVoltageType } from "services/cableTypeService";
import { projectIdAtom } from "state/pathParams";
import { CableTypeWithLevel, _CableLevel } from "types/cables";
import { atomFamily, atomFromFn } from "utils/jotai";
import { exportCableSplitsOkFamily } from "./landfall";
import { DefaultMap } from "lib/DefaultMap";
import * as turf from "@turf/turf";
import {
  _projectArrayAndExportCableTypesFamily,
  libraryCableTypesRefresh,
} from "./cableType";
import { isOnshoreAtom } from "state/onshore";
import { exportCableResourceWithAccessOnNodeState } from "state/cableType";

export const projectExportCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    const cables = await get(_projectArrayAndExportCableTypesFamily(nodeId));
    let exportCables = cables.filter((c) => c.exportCableType);
    if (get(isOnshoreAtom))
      exportCables = exportCables.filter(
        (c) => c.voltage === ExportCableVoltageType.kV110,
      );
    return new Map(exportCables.map((c) => [c.id, c]));
  }),
);

export const libraryExportCableTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, CableType>>>(async (get) => {
    get(libraryCableTypesRefresh);
    let exportCables = await get(
      exportCableResourceWithAccessOnNodeState({ nodeId }),
    );
    if (get(isOnshoreAtom))
      exportCables = exportCables.filter(
        (c) => c.cable.voltage === ExportCableVoltageType.kV110,
      );
    return new Map(exportCables.map((c) => [c.cable.id, c.cable]));
  }),
);

export const exportCableTypesFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atom<Promise<Map<string, CableType>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      const ret = new Map<string, CableType>();
      if (!projectId) return ret;
      for (const [k, v] of await get(projectExportCableTypesFamily(projectId)))
        ret.set(k, v);
      for (const [k, v] of await get(libraryExportCableTypesFamily(projectId)))
        ret.set(k, v);
      return ret;
    }),
);

export const exportCableTypesWithLevelAtom = atom<
  Promise<Map<string, CableTypeWithLevel>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  const ret = new Map<string, CableTypeWithLevel>();
  if (!projectId) return ret;
  for (const [id, cable] of await get(exportCableTypesFamily({ projectId })))
    ret.set(id, { level: _CableLevel.Values.project, cable });
  for (const [id, cable] of await get(libraryExportCableTypesFamily(projectId)))
    ret.set(id, { level: _CableLevel.Values.library, cable });
  return ret;
});

export const exportCableTypesByRatingFamily = atomFamily(
  ({ projectId }: { projectId: string | undefined }) =>
    atom<Promise<CableType[]>>(async (get) => {
      const cables = await get(exportCableTypesFamily({ projectId }));
      return Array.from(cables.values()).sort(
        (a, b) => (a.ampacity ?? 0) - (b.ampacity ?? 0),
      );
    }),
);

export const exportCableTypeLengthsFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<Map<string, { straightKM: number }>>>(async (get) => {
      const types = await get(exportCableTypesFamily({ projectId: undefined }));
      const splits = await get(exportCableSplitsOkFamily({ parkId, branchId }));

      const ret = new DefaultMap<string, { straightKM: number }>(() => ({
        straightKM: 0,
      }));
      for (const sp of splits) {
        const ons = types.get(
          sp.exportCable.properties.onshoreCableTypeId ?? "",
        );
        if (ons) {
          ret.update(ons.id, (o) => ({
            straightKM:
              o.straightKM + turf.length(sp.onshore, { units: "kilometers" }),
          }));
        }
        const off = types.get(sp.exportCable.properties.cableTypeId ?? "");
        if (off) {
          ret.update(off.id, (o) => ({
            straightKM:
              o.straightKM + turf.length(sp.offshore, { units: "kilometers" }),
          }));
        }
      }

      return ret.inner;
    }),
);
