import { useAtomValue } from "jotai";
import {
  organisationIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "state/pathParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { versionQueryName } from "hooks/useSetVersion";
import useResetMapControls from "hooks/useResetMapControls";
import { dedup, getPathPrefix } from "utils/utils";
import { openedExistingBranchesTabsAtomFamily } from "./state";
import { customerProjectAtomFamily } from "state/timeline";
import { useJotaiCallback } from "utils/jotai";
import { useCallback } from "react";

export const useGetPathToBranch = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );

  return useCallback(
    (branchId: string, parkId?: string) => {
      return getPathToBranch(
        getPathPrefix(project),
        organisationId,
        nodeId,
        branchId,
        parkId,
      );
    },
    [nodeId, organisationId, project],
  );
};

export const getPathToBranch = (
  designPrefix: string,
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId?: string,
) => {
  return `/${designPrefix}/project/${organisationId}/${projectId}/${branchId}${parkId ? `/${parkId}` : ``}`;
};

const useNavigateToBranch = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom);
  const resetMapControls = useResetMapControls();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useJotaiCallback(
    (get, set, newBranchId: string, appendToBranchTabBar: boolean) => {
      const project = get(
        customerProjectAtomFamily({
          nodeId,
        }),
      );
      resetMapControls();
      searchParams.delete(versionQueryName);
      navigate({
        pathname: getPathToBranch(
          getPathPrefix(project),
          organisationId,
          nodeId,
          newBranchId,
          parkId,
        ),
        search: searchParams.toString(),
      });

      if (appendToBranchTabBar) {
        set(openedExistingBranchesTabsAtomFamily({ nodeId }), (curr) => {
          return dedup([...curr, newBranchId]);
        });
      }
    },
    [navigate, nodeId, organisationId, parkId, resetMapControls, searchParams],
  );
};

export default useNavigateToBranch;
