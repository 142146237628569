import { colors } from "../styles/colors";

export const opacityPropertyName = "vind_hidden_property_opacity";
export const strokeWidthPropertyName = "vind_hidden_property_stroke_width";
export const zoomPropertyName = "vind_hidden_property_zoom";
export const lockedPropertyName = "vind_locked" as const;
export const displayLabelPropertyName = "vind_display_label" as const;
export const typePropertyName = "type" as const;

export const canvasPolygonLayerId = `canvas-polygon-layer-id`;
export const canvasPointLayerId = `canvas-point-layer-id`;
export const canvasLineLayerId = `canvas-line-layer-id`;

export const canvasPolygonLayerSource = "canvas-layer-polygon-source-id";
export const canvasPointLayerSource = "canvas-layer-point-source-id";
export const canvasLineLayerSource = "canvas-layer-line-source-id";

export const turbineEllipsisMajorAxisPropertyName = "majorAxis";
export const turbineEllipsisMinorAxisPropertyName = "minorAxis";
export const turbineEllipsisOrientationPropertyName = "orientation";
export const turbineEllipsesShowPropertyName = "show";

export const DEFAULT_CANVAS_LAYER_COLOR = colors.defaultCanvasLayer;
export const DEFAULT_CANVAS_LINE_STROKE_WIDTH = 5;
export const DEFAULT_CANVAS_POLYGON_STROKE_WIDTH = 1;
export const DEFAULT_CANVAS_DOTTED_POLYGON_STROKE_WIDTH = 1;
export const DEFAULT_CANVAS_LAYER_OPACITY = 0.4;
export const DEFAULT_CANVAS_GEOTIFF_OPACITY = 1;
export const DEFAULT_CANVAS_POINT_COLOR = colors.defaultCanvasPoint;

export const TopRightMenuOptions = {
  featureProperties: "featureProperties",
  productionStatistics: "production-statistics",
  cableStatistics: "cable-statistics",
  turbineStatistics: "turbine-statistics",
  mooringStatistics: "mooring-statistics",
  foundationStatistics: "foundation-statistics",
  noise: "noise",
  onshoreNoise: "onshore-noise",
  view: "view",
  parkShadow: "park-shadow",
  viewshed: "viewshed",
  bathymetry: "bathymetry",
  terrainanalysis: "terrainanalysis",
  shadowFlicker: "shadowFlicker",
  windSample: "windSample",
  waveSample: "waveSample",
  cost: "cost",
  cableEntrance: "cable-entrance",
} as const;

export const DEFAULT_SELECTED_OPACITY = 1.0;
export const DEFAULT_IN_FOCUS_OPACITY = 0.8;
export const DEFAULT_NOT_IN_FOCUS_OPACITY = 0.3;
