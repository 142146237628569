import { FeatureFlagName } from "components/General/FeatureFlag";
import { z } from "zod";
import { DnDRow } from "../General/DnDGrid/types";
import { DesignToolMode } from "types/map";
import { designToolTypeAtom } from "state/map";
import { atom } from "jotai";
import { DefaultMap } from "lib/DefaultMap";

/**
 * State associated with a widget.
 * For widget that doesn't have a state (this is most of them), the type is `never`.
 */
export type WidgetState = {
  "3D-foundation"?: never;
  Text?: {
    title: string;
    body: string;
  };
  "Cable matrix map"?: never;
  "Cable matrix"?: never;
  Cabling?: never;
  "Capex input"?: never;
  "Capex pie chart"?: never;
  "Capex table"?: never;
  "Cost input"?: never;
  "Cumulative graph"?: never;
  "Export cable loading"?: never;
  "Export cables"?: never;
  "Export system voltage"?: never;
  "Inter array loss graph"?: never;
  "Electrical losses"?: never;
  Energy?: never;
  Foundations?: never;
  "Foundation key statistics"?: never;
  "Foundation totals"?: never;
  Input?: never;
  "Installation time breakdown chart"?: never;
  "Installation time variability"?: never;
  "Installation table"?: never;
  "Installation S curve"?: never;
  "Lcoe table"?: never;
  "IRR table"?: never;
  "Park life cash flow"?: never;
  Map?: never;
  "Metocean map"?: never;
  "Monthly graph"?: never;
  "Annual variability"?: never;
  "Wind speed distribution"?: never;
  "Wave height distribution"?: never;
  "Mooring lines"?: never;
  "Mooring line anchors"?: never;
  "Mooring line attachments"?: never;
  "Mooring lines list"?: never;
  "Other expenditures input"?: never;
  Losses?: never;
  Park?: never;
  Turbines?: never;
  "Wind rose"?: never;
  "Wind details"?: never;
  "Wave details"?: never;
  "Wave rose"?: never;
  "Turbine capacity factors"?: never;
};

const _DnDElement = z.object({
  id: z.string(),
  widthShare: z.number().optional(),
  component: z.never().optional(), // NOTE: trick to make it not typecheck when this is something else.
});

const _DnDRow = z.object({
  id: z.string(),
  height: z.number().optional(),
  elements: _DnDElement.array(),
});

const _DesignToolMode = z.nativeEnum(DesignToolMode);

export const _DashboardSerialize = z.object({
  id: z.string(),
  name: z.string(),
  preset: z.boolean().optional(),
  applicableMode: _DesignToolMode.array().optional(),
  branchId: z.string().optional(),
  parkId: z.string().optional(),
  configurationId: z.string().optional(),
  windConfigurationId: z.string().optional(),
  costConfigurationId: z.string().optional(),
  operationsConfigurationId: z.string().optional(),
  rows: _DnDRow.array().optional(),
  widgetState: z.record(z.any()).optional(),
});
export type DashboardSerialize = z.infer<typeof _DashboardSerialize>;

export type Dashboard = {
  id: string;
  name: string;

  /** True if this dashboard is a "preset Vind dashboard". */
  preset?: boolean;
  applicableMode?: DesignToolMode[];

  branchId?: string;
  parkId?: string;
  configurationId?: string;
  windConfigurationId?: string;
  costConfigurationId?: string;
  operationsConfigurationId?: string;

  rows: DnDRow[];
  widgetState: WidgetState;
};

const widgetIds = [
  "3D-foundation",
  "Cable matrix map",
  "Cable matrix",
  "Cabling",
  "Capex input",
  "Capex pie chart",
  "Capex table",
  "Cost input",
  "Cumulative graph",
  "Energy",
  "Export cables",
  "Foundations",
  "Foundation key statistics",
  "Foundation totals",
  "Input",
  "Lcoe table",
  "IRR table",
  "Installation time breakdown chart",
  "Installation time variability",
  "Installation table",
  "Installation S curve",
  "Annual variability",
  "Park life cash flow",
  "Map",
  "Metocean map",
  "Monthly graph",
  "Wind speed distribution",
  "Wave height distribution",
  "Mooring lines",
  "Mooring line anchors",
  "Mooring line attachments",
  "Mooring lines list",
  "Other expenditures input",
  "Losses",
  "Park",
  "Turbines",
  "Wind rose",
  "Wind details",
  "Wave details",
  "Wave rose",
  "Text",
  "Export cable loading",
  "Export system voltage",
  "Inter array loss graph",
  "Turbine capacity factors",
  "Electrical losses",
] as const;
export type WidgetId = (typeof widgetIds)[number];

export const widgetCategories = [
  "Park",
  "Yield",
  "Electrical",
  "Cost",
  "Mooring",
  "Foundation",
  "Other",
] as const;
export type WidgetCategory = (typeof widgetCategories)[number];

export const categoryData: Record<
  WidgetCategory,
  {
    exclude?: DesignToolMode[];
  }
> = {
  ["Park"]: {},
  ["Yield"]: {},
  ["Electrical"]: {},
  ["Cost"]: {},
  ["Mooring"]: { exclude: [DesignToolMode.Onshore] },
  ["Foundation"]: { exclude: [DesignToolMode.Onshore] },
  ["Other"]: {},
};

export const widgetData: Record<
  WidgetId,
  {
    category: WidgetCategory;
    /**
     * Modes in which the widget should *not* be shown, if any.
     * This is kinda backwards so that we can write
     * ```ts
     * if (widgetData[id].exclude?.includes(mode))
     *     // ...
     * ````
     * and have it not enter the branch if `exclude` is `undefined`.
     */
    exclude?: DesignToolMode[];
  }
> = {
  ["3D-foundation"]: {
    category: "Foundation",
    exclude: [DesignToolMode.Onshore],
  },
  ["Cable matrix map"]: {
    category: "Electrical",
  },
  ["Cable matrix"]: {
    category: "Electrical",
  },
  ["Cabling"]: {
    category: "Electrical",
  },
  ["Capex input"]: {
    category: "Cost",
  },
  ["Capex pie chart"]: {
    category: "Cost",
  },
  ["Capex table"]: {
    category: "Cost",
  },
  ["Cost input"]: {
    category: "Cost",
  },
  ["Cumulative graph"]: {
    category: "Yield",
  },
  ["Energy"]: {
    category: "Yield",
  },
  ["Export cables"]: {
    category: "Electrical",
  },
  ["Foundations"]: {
    category: "Foundation",
    exclude: [DesignToolMode.Onshore],
  },
  ["Foundation key statistics"]: {
    category: "Foundation",
    exclude: [DesignToolMode.Onshore],
  },
  ["Foundation totals"]: {
    category: "Foundation",
    exclude: [DesignToolMode.Onshore],
  },
  ["Input"]: {
    category: "Yield",
  },
  ["Lcoe table"]: {
    category: "Cost",
  },
  ["IRR table"]: {
    category: "Cost",
  },
  ["Installation time breakdown chart"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Installation time variability"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Installation table"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Installation S curve"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Annual variability"]: {
    category: "Yield",
  },
  ["Park life cash flow"]: {
    category: "Cost",
  },
  ["Map"]: {
    category: "Park",
  },
  ["Metocean map"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Monthly graph"]: {
    category: "Yield",
  },
  ["Wind speed distribution"]: {
    category: "Yield",
  },
  ["Wave height distribution"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Mooring lines"]: {
    category: "Mooring",
    exclude: [DesignToolMode.Onshore],
  },
  ["Mooring line anchors"]: {
    category: "Mooring",
    exclude: [DesignToolMode.Onshore],
  },
  ["Mooring line attachments"]: {
    category: "Mooring",
    exclude: [DesignToolMode.Onshore],
  },
  ["Mooring lines list"]: {
    category: "Mooring",
    exclude: [DesignToolMode.Onshore],
  },
  ["Other expenditures input"]: {
    category: "Cost",
  },
  ["Losses"]: {
    category: "Yield",
  },
  ["Park"]: {
    category: "Park",
  },
  ["Turbines"]: {
    category: "Yield",
  },
  ["Wind rose"]: {
    category: "Yield",
  },
  ["Wind details"]: {
    category: "Yield",
  },
  ["Wave details"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Wave rose"]: {
    category: "Other",
    exclude: [DesignToolMode.Onshore],
  },
  ["Text"]: {
    category: "Other",
  },
  ["Export cable loading"]: {
    category: "Electrical",
  },
  ["Export system voltage"]: {
    category: "Electrical",
  },
  ["Inter array loss graph"]: {
    category: "Electrical",
  },
  ["Turbine capacity factors"]: {
    category: "Electrical",
  },
  ["Electrical losses"]: {
    category: "Electrical",
  },
} as const;

export const widgetIdsByCategoryAtom = atom<Map<WidgetCategory, WidgetId[]>>(
  (get) => {
    const mode = get(designToolTypeAtom);

    const map = new DefaultMap<WidgetCategory, WidgetId[]>(() => []);

    // Group widgets we should show by category
    for (const wid of widgetIds) {
      const { category, exclude } = widgetData[wid];
      if (exclude?.includes(mode)) continue;
      map.get(category).push(wid);
    }

    // Remove categories that should not be there
    for (const cat of widgetCategories) {
      if (categoryData[cat].exclude?.includes(mode)) map.delete(cat);
    }

    return map.inner;
  },
);

export const widgetIdRequireFeatureFlag: Partial<
  Record<WidgetId, FeatureFlagName>
> = {};
