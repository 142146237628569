import styled from "styled-components";
import { InputDimensioned } from "components/General/Input";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { FoundationProcurementCost } from "services/procurementCostService";
import { Fragment, useState, useEffect } from "react";
import { EditableTextInternalState } from "components/General/EditableText";
import { spacing3, spacing4, spacing6 } from "styles/space";
import Tooltip from "components/General/Tooltip";
import {
  DEFAULT_FOUNDATION_TABLE_NAME,
  getDefaultFoundationCost,
} from "./FoundationProcurementCosts";
import Trashcan from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import { useAtomValue } from "jotai";
import { organisationFinancialResourceState } from "components/Organisation/Library/state";
import { organisationIdAtom } from "state/pathParams";
import {
  CostConfiguration,
  isLibraryFoundationReferenceCost,
} from "services/costService";
import { Cost } from "types/financial";
import { Row } from "components/General/Layout";
import InformationIcon from "@icons/24/Information.svg?react";
import { procurementCostsChangelogInfo } from "components/InputChangelog/const";
import { getCustomArrayChangeLogValue } from "components/InputChangelog/utils";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
const TableContainer = styled.div`
  width: 100%;
  &:last-of-type {
    padding-bottom: 4rem;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: ${typography.body};
`;

const Thead = styled.thead`
  background-color: ${colors.surfaceInfo};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const MainHeader = styled.th`
  text-align: left;
  color: ${colors.textPrimary};
  font-weight: bold;
  ${typography.sub2}
  padding: ${spacing4} ${spacing6};
`;

const SubHeader = styled.th`
  padding: ${spacing4} ${spacing6};
  color: ${colors.textPrimary};
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  ${typography.sub3};
  background-color: ${colors.surfacePrimary};
  border-bottom: 1px solid ${colors.blue500};
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: ${colors.surfaceSecondary};
  }
`;

const Td = styled.td`
  padding: ${spacing3} ${spacing6};
  text-align: left;
  color: ${colors.textPrimary};
  ${typography.caption};
`;

const FoundationTypeTd = styled(Td)`
  ${typography.sub2};
`;

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatMaterialName = (name: string) => {
  return name
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const DividerTd = styled.td`
  width: 1px;
  padding: 0;
  background-color: ${colors.borderDefault};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface FoundationCostTableProps {
  data: FoundationProcurementCost;
  onDataChange: (
    foundationType: keyof FoundationProcurementCost["costs"],
    material: string,
    field: string,
    value: number,
    tableId: string | number,
  ) => void;
  handleTableNameChange: (
    newTableName: string,
    tableId: string | number,
  ) => void;
  tableId: string | number;
  onDeleteTable: (tableId: string | number) => void;
  onDuplicateTable: (tableId: string | number) => void;
  isDeleteDisabled: boolean;
  isUpdating: boolean;
}

const FoundationCostTable = ({
  data,
  tableId,
  onDataChange,
  handleTableNameChange,
  onDeleteTable,
  onDuplicateTable,
  isDeleteDisabled,
  isUpdating,
}: FoundationCostTableProps) => {
  const [localTableName, setLocalTableName] = useState(data.tableName);
  const { showConfirm } = useConfirm();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const financialConfigs = useAtomValue(
    organisationFinancialResourceState({
      organisationId,
    }),
  );

  useEffect(() => {
    if (data.tableName !== localTableName) {
      setLocalTableName(data.tableName);
    }
  }, [data.tableName, localTableName]);

  const foundationTypes = Object.keys(data.costs) as Array<
    keyof FoundationProcurementCost["costs"]
  >;

  return (
    <TableContainer>
      <StyledTable>
        <Thead>
          <tr>
            <MainHeader>
              <div style={{ width: "180px" }}>
                <EditableTextInternalState
                  value={localTableName}
                  type="text"
                  smallInput={true}
                  style={{
                    width: "100%",
                  }}
                  onEnter={(newValue) => {
                    const trimmedValue = newValue.trim();
                    if (trimmedValue === "") {
                      setLocalTableName(DEFAULT_FOUNDATION_TABLE_NAME);
                      handleTableNameChange(
                        DEFAULT_FOUNDATION_TABLE_NAME,
                        data.id ?? tableId,
                      );
                    } else if (trimmedValue !== localTableName) {
                      setLocalTableName(trimmedValue);
                      handleTableNameChange(trimmedValue, tableId);
                    }
                  }}
                  placeholder="Enter table name"
                  renderText={(text) => (
                    <p style={{ ...typography.sub2 }}>{text}</p>
                  )}
                />
              </div>
            </MainHeader>
            <DividerTd rowSpan={2} />
            <MainHeader>
              {" "}
              <Row>
                Supply
                <Tooltip text="Cost from supplier">
                  <InformationIcon
                    style={{
                      height: "1.3rem",
                    }}
                  />
                </Tooltip>
              </Row>
            </MainHeader>
            <DividerTd rowSpan={2} />
            <MainHeader colSpan={3}>
              <Row>
                Shipping
                <Tooltip text="Shipping from manufacturer to marshalling port">
                  <InformationIcon
                    style={{
                      height: "1.3rem",
                    }}
                  />
                </Tooltip>
              </Row>
            </MainHeader>
            <MainHeader colSpan={1}>
              {!isUpdating && (
                <DotMenu
                  divStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MenuItem
                    disabled={isUpdating}
                    name="Duplicate"
                    onClick={() => onDuplicateTable(tableId)}
                    icon={<DuplicateIcon />}
                  />
                  <MenuItem
                    name="Delete"
                    onClick={async () => {
                      const usage = financialConfigs.filter(
                        (c: CostConfiguration) => {
                          const foundationCost = c.capex.fixed
                            .foundations as Cost;
                          return (
                            isLibraryFoundationReferenceCost(foundationCost) &&
                            [
                              foundationCost.floatingCostReference,
                              foundationCost.monopileCostReference,
                              foundationCost.jacketCostReference,
                            ].includes(tableId.toString())
                          );
                        },
                      );

                      if (
                        usage.length === 0 ||
                        (await showConfirm({
                          title: "Delete table",
                          message: `Foundation table is currently being used in ${usage.length} financial configurations, are you sure you want to delete it?`,
                          confirmButtonText: "Delete",
                        }))
                      ) {
                        onDeleteTable(tableId);
                      }
                    }}
                    icon={<Trashcan />}
                    disabled={isDeleteDisabled || isUpdating}
                    tooltip={
                      isDeleteDisabled
                        ? "You need at least one table"
                        : undefined
                    }
                  />
                </DotMenu>
              )}
            </MainHeader>
          </tr>
          <tr>
            <SubHeader>MATERIAL</SubHeader>
            <SubHeader>COST</SubHeader>
            <SubHeader>EUROPE</SubHeader>
            <SubHeader>ASIA</SubHeader>
            <SubHeader>USA</SubHeader>
            <SubHeader>
              <Row>
                OTHER
                <Tooltip text="This cost is applied if the park location is outside the predefined regions">
                  <InformationIcon
                    style={{
                      height: "1.3rem",
                    }}
                  />
                </Tooltip>
              </Row>
            </SubHeader>
          </tr>
        </Thead>
        <tbody>
          {foundationTypes.map((foundationType) => {
            const foundationData = data.costs[foundationType];
            if (!foundationData) return null;

            return (
              <Fragment key={foundationType}>
                <Tr>
                  <FoundationTypeTd>
                    {capitalizeFirstLetter(foundationType.toString())}
                  </FoundationTypeTd>
                  <DividerTd />
                  <Td></Td>
                  <DividerTd />
                  {foundationData.shippingCost.map((shippingCost) => (
                    <Td key={shippingCost.region}>
                      <InputDimensioned
                        value={shippingCost.cost}
                        unit={shippingCost.unit}
                        onChange={(newValue) =>
                          onDataChange(
                            foundationType,
                            "",
                            shippingCost.region,
                            newValue === undefined || isNaN(newValue)
                              ? 0
                              : newValue,
                            tableId,
                          )
                        }
                        changelogInfo={procurementCostsChangelogInfo(
                          tableId.toString(),
                          organisationId,
                          `${foundationType}.shippingCost.${shippingCost.region}`,
                          "org_financial_manage",
                          (obj: any) =>
                            getCustomArrayChangeLogValue(
                              obj,
                              `costs.${foundationType}.shippingCost`,
                              `region.${shippingCost.region}`,
                              ["cost", "unit"],
                            ),
                        )}
                      />
                    </Td>
                  ))}
                </Tr>
                {Object.entries(foundationData.material).map(
                  ([material, supplyCost]) => {
                    return (
                      <Tr key={`${foundationType}-${material}`}>
                        <Td>{formatMaterialName(material)}</Td>
                        <DividerTd />
                        <Td>
                          <IconWrapper>
                            <InputDimensioned
                              value={supplyCost.cost}
                              unit={supplyCost.unit}
                              onChange={(newValue) =>
                                onDataChange(
                                  foundationType,
                                  material,
                                  "supplyCost",
                                  newValue === undefined || isNaN(newValue)
                                    ? getDefaultFoundationCost(material)
                                    : newValue,
                                  tableId,
                                )
                              }
                              changelogInfo={procurementCostsChangelogInfo(
                                tableId.toString(),
                                organisationId,
                                `costs.${foundationType}.material.${material}`,
                                "org_financial_manage",
                              )}
                            />
                          </IconWrapper>
                        </Td>
                        <DividerTd />
                        <Td colSpan={4}></Td>
                      </Tr>
                    );
                  },
                )}
              </Fragment>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default FoundationCostTable;
