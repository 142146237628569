import { useJotaiCallback } from "utils/jotai";
import { organisationIdAtom, projectIdAtomDef } from "./../state/pathParams";
import { useLocation, useNavigate } from "react-router-dom";
import { branchIdAtom, parkIdAtom } from "state/pathParams";
import { customerProjectAtomFamily } from "state/timeline";
import { getPathPrefix } from "utils/utils";

const useNavigateToPark = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPark = useJotaiCallback(
    (get, _set, parkId: string | undefined, _branchId?: string) => {
      const currentParkId = get(parkIdAtom);
      const currentBranchId = get(branchIdAtom);

      const branchId = _branchId ?? currentBranchId;
      if (parkId === currentParkId && currentBranchId === branchId) {
        return;
      }
      const organisationId = get(organisationIdAtom);
      const projectId = get(projectIdAtomDef);
      const project = get(
        customerProjectAtomFamily({
          nodeId: projectId,
        }),
      );

      if (parkId) {
        const navigateURL = `/${getPathPrefix(project)}/project/${organisationId}/${projectId}/${branchId}/${parkId}`;
        navigate(
          {
            pathname: navigateURL,
            search: location.search,
            hash: document.location.hash,
          },
          {
            replace: true,
          },
        );
      } else {
        const navigateURL = `/${getPathPrefix(project)}/project/${organisationId}/${projectId}/${branchId}`;
        navigate(
          {
            pathname: navigateURL,
            search: location.search,
            hash: document.location.hash,
          },
          {
            replace: true,
          },
        );
      }
    },
    [location.search, navigate],
  );

  return {
    navigateToPark,
    navigate,
  };
};

export default useNavigateToPark;
