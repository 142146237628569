import { useEffect } from "react";
import { CableChainFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer } from "./utils";
import { cableChainSourceId, cableChainLayerId } from "./constants";

const cableChainPaint: mapboxgl.FillPaint = {
  "fill-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    0.6,
    0.2,
  ],
  "fill-color": "yellow",
};

export const RenderCableChains = ({
  map,
  chains,
}: {
  map: mapboxgl.Map;
  chains: CableChainFeature[];
}) => {
  useEffect(() => {
    map.addSource(cableChainSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cableChainLayerId);
      map.removeSource(cableChainSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: cableChainLayerId,
      type: "fill",
      source: cableChainSourceId,
      paint: cableChainPaint,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(cableChainSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: chains,
    });
  }, [map, chains]);

  return null;
};
