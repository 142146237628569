import styled from "styled-components";
import { typography } from "../../styles/typography";
import { colors } from "styles/colors";
import { TextEllipsis } from "components/Organisation/OrganisationRightSide/style";

export const Name = styled.p`
  ${typography.sub2}
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
`;
export const Email = styled(TextEllipsis)`
  ${typography.caption}
  margin: 0;
  color: ${colors.textSecondary};
`;
