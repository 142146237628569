import { mapAtom } from "state/map";
import { useEffect, useMemo, useState } from "react";
import { measureWavePointAtom, wavePointsSource } from "../state/map";
import { SourceSpecification, SymbolLayerSpecification } from "mapbox-gl";
import { getBeforeLayer, wavePointsLayerId } from "../components/Mapbox/utils";
import GenericFeature from "../components/MapFeatures/GenericFeature";
import { WaveDataSource } from "../state/waveStatistics";
import { displayLabelPropertyName } from "../constants/canvas";
import { useAtomValue, useSetAtom } from "jotai";

const url = {
  [WaveDataSource.ERA5]: "mapbox://vindai.era5-wave-points-ocean-only",
  [WaveDataSource.NORA3]: "mapbox://vindai.nora3-wave-points",
};

const sourceLayer = {
  [WaveDataSource.ERA5]: "era5-wave-points-ocean-only",
  [WaveDataSource.NORA3]: "nora3-wave-points",
};

function getLayerFromSource(
  source: WaveDataSource,
): SymbolLayerSpecification | undefined {
  if (source === WaveDataSource.BEST) return;
  return {
    id: wavePointsLayerId,
    type: "symbol",
    minzoom: 2,
    maxzoom: 14,
    source: sourceLayer[source],
    "source-layer": sourceLayer[source],
    layout: {
      "icon-image": "embassy",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        7,
        0.7,
        10,
        1.2,
      ],
      "icon-allow-overlap": false,
    },
    paint: {
      "icon-opacity": [
        "case",
        [
          "boolean",
          ["feature-state", "hover"],
          ["feature-state", "selected"],
          false,
        ],
        1,
        0.5,
      ],
    },
    filter: ["boolean", ["get", displayLabelPropertyName], true],
  };
}

const WavePoints = () => {
  const map = useAtomValue(mapAtom);
  const [id, setId] = useState<number | string | undefined>(undefined);
  const setMeasurePoint = useSetAtom(measureWavePointAtom);
  const selectedSource = useAtomValue(wavePointsSource);

  const source = useMemo(() => {
    if (selectedSource === WaveDataSource.BEST) return;
    return {
      type: "vector",
      url: url[selectedSource],
    } as SourceSpecification;
  }, [selectedSource]);
  const layer = useMemo(() => {
    return getLayerFromSource(selectedSource);
  }, [selectedSource]);

  useEffect(() => {
    if (!map) return;
    return () => {
      setId(undefined);
    };
  }, [map]);
  if (!map || !layer || !source) return null;
  return (
    <>
      <GenericFeature
        features={[]}
        sourceId={layer["source-layer"] as string}
        source={source}
        layerId={wavePointsLayerId}
        map={map}
        layers={[layer]}
        onClickCallback={(features) => {
          if (features.length === 0) return;
          const coords = features[0].geometry as any;
          setMeasurePoint({
            source: WaveDataSource.ERA5,
            position: coords.coordinates,
          });
          setId(features[0].id);
        }}
        beforeLayer={getBeforeLayer(map, wavePointsLayerId)}
        sourceLayer={layer["source-layer"] as string}
        selectedIds={id ? [id] : undefined}
        featureFilter={(selectedFeatures) =>
          selectedFeatures.filter((f) => f.layer?.id === wavePointsLayerId)
        }
      />
    </>
  );
};

export default WavePoints;
