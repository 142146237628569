import { searchBarOpen } from "../state";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { modalTypeOpenAtom } from "state/modal";
import { useAtom, useSetAtom } from "jotai";
import SearchBarInner from "./SearchBar";
import { useSearchDataProject } from "../searchHooks/useSearchDataProject";

const SearchBarProject = ({
  organisationId,
  projectId,
}: {
  organisationId: string;
  projectId: string;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [open, setOpen] = useAtom(searchBarOpen);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => setOpen(false));

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setModalTypeOpen(undefined);
        setOpen((cur) => !cur);
      }
    },
    [setModalTypeOpen, setOpen],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);
    return () => window.removeEventListener("keydown", handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  if (!open) return null;

  return (
    <Suspense fallback={null}>
      <SearchBarProjectInner
        _ref={ref}
        close={() => setOpen(false)}
        organisationId={organisationId}
        projectId={projectId}
      />
    </Suspense>
  );
};

const SearchBarProjectInner = ({
  _ref,
  close,
  organisationId,
}: {
  _ref: React.RefObject<HTMLDivElement>;
  close: () => void;
  organisationId: string;
  projectId: string;
}) => {
  const [search, setSearch] = useState("");

  const { searchGroups, searchableItems, someItemsAreLoading } =
    useSearchDataProject({
      organisationId,
      search,
    });

  return (
    <SearchBarInner
      searchGroups={searchGroups}
      searchableItems={searchableItems}
      close={close}
      _ref={_ref}
      search={search}
      setSearch={setSearch}
      page="project"
      someItemsAreLoading={someItemsAreLoading}
    />
  );
};

export default SearchBarProject;
