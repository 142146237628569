import {
  labelFromColorKey,
  labelFromFeature,
  renderValue,
} from "business/style/constants";
import { activeFeatureStyles } from "business/style/state";
import {
  BucketStyle,
  CategoryStyle,
  GradientStyle,
  SingleStyle,
  Style,
  isBucket,
  isCategory,
  isGradient,
  isSingle,
} from "business/style/types";
import { Column } from "components/General/Layout";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import StylingIcon from "icons/14/Styling.svg";
import ArrowRightIcon from "icons/24/KeyboardArrowRight.svg";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { GradientComp } from "./ColorRange";
import { styleLegendOpenAtom } from "./state";
import { useAtomValue, useSetAtom } from "jotai";
import { ColorSquare } from "components/General/ColorSquare";
import { Color } from "lib/colors";

const SingleColor_ = styled.div`
  display: flex;

  > * {
    padding: 0.6rem 0.8rem;
    display: flex;
    align-items: center;
  }

  ${ColorSquare} {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
const SingleColor = ({ style }: { style: SingleStyle }) => {
  return (
    <SingleColor_>
      <div>
        <ColorSquare $color={style.color} />
      </div>
      <span>{labelFromFeature[style.feature]}</span>
    </SingleColor_>
  );
};

const BucketList_ = styled.div`
  display: grid;
  grid-template-columns: min-content max-content 2rem max-content auto;

  > :nth-child(10n-9),
  > :nth-child(10n-8),
  > :nth-child(10n-7),
  > :nth-child(10n-6),
  > :nth-child(10n-5) {
    background: ${colors.surfaceSecondary};
  }

  > * {
    padding: 0.6rem 0.8rem;
  }

  > span {
    justify-content: end;
    display: flex;
    align-items: center;
    padding: 0.4rem 0.4rem;
    gap: 0.4rem;
  }
  span.line {
    color: ${colors.textDisabled};
  }

  ${ColorSquare} {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
const BucketList = ({ style }: { style: BucketStyle }) => {
  const k = style.source;
  return (
    <BucketList_>
      {style.buckets.buckets().map((b, i) => {
        return (
          <Fragment key={i}>
            <div>
              <ColorSquare $color={b.color} />
            </div>
            <span className="left">{renderValue(k, b.from)}</span>
            <span className="line">&mdash;</span>
            <span>{renderValue(k, b.to)}</span>
            <span />
          </Fragment>
        );
      })}
    </BucketList_>
  );
};

const RangeLegend_ = styled.div`
  display: flex;
  padding: 1rem;
  > div {
    position: relative;
  }

  .label {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;

    transform: translateY(-50%);

    .marker {
      background: ${colors.borderDefault};
      height: 1px;
      width: 1rem;
    }

    span {
      padding: 0 0 3px 3px;
      text-align: end;
      white-space: nowrap;
    }
  }
`;
const RangeLegend = ({ style }: { style: GradientStyle }) => {
  const ref = useRef<HTMLDivElement>(null);
  // We need to track both the height of the div, as well as how much to offset
  // the first label, due to scaling problems. See comment below.
  const [size, setSize] = useState<{ height: number; offset: number }>({
    height: 190,
    offset: 2,
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        const n = ref.current;
        const height = n.getBoundingClientRect().height;
        // In the screenshot legend we are in `transform: scale(0.5)`, and this
        // messes up `position: absolute`. To detect this, compare `height` to
        // `offsetHeight` (from https://stackoverflow.com/a/26893663).
        const scale = 1 / (height / n.offsetHeight);
        setSize({ height: scale * (height - 4), offset: 2 * scale });
      }
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  if (style.gradient.numStops() === 0) return null;

  const min = style.gradient.values[0];
  const range = style.gradient.span();

  return (
    <RangeLegend_>
      <GradientComp ref={ref} gradient={style.gradient} vertical dynamic />
      <div>
        {style.gradient.stops().map((l, i) => {
          const y = ((l.value - min) / range) * size.height;
          return (
            <div
              key={i}
              className="label"
              style={{
                top: y + size.offset,
              }}
            >
              <div className="marker" />
              <span>{renderValue(style.source, l.value)}</span>
            </div>
          );
        })}
      </div>
    </RangeLegend_>
  );
};

const CategoriesList_ = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(0, auto);
  overflow-x: hidden;

  > :nth-child(4n-3),
  > :nth-child(4n-2) {
    background: ${colors.surfaceSecondary};
  }

  > * {
    padding: 0.6rem 0.8rem;
  }

  > span {
    align-self: center;
    align-items: center;
    padding: 0.4rem 0.4rem;
    gap: 0.4rem;

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${ColorSquare} {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
const CategoriesList = ({ style }: { style: CategoryStyle }) => {
  return (
    <CategoriesList_>
      {style.categories.map((b, i) => {
        return (
          <Fragment key={i}>
            <div>
              <ColorSquare $color={b.color} />
            </div>
            <span>{b.label ?? b.id}</span>
          </Fragment>
        );
      })}
    </CategoriesList_>
  );
};

const Part_ = styled.div`
  > span {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.4rem;
    gap: 0.4rem;
    > span {
      ${typography.sub3};
    }
    svg {
      height: 1rem;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    .skeleton {
      min-height: 1rem;
      flex: 1;
      margin: 1rem 0;
      width: auto;
    }
  }
`;
export const Part = ({ style }: { style: Style }) => {
  return (
    <Part_>
      <span>
        <span>{labelFromFeature[style.feature]}</span>
        {style.source !== "single" && (
          <>
            <ArrowRightIcon />
            <span>{labelFromColorKey[style.source]}</span>
          </>
        )}
      </span>
      <div>
        <Suspense fallback={<SkeletonBlock />}>
          {isBucket(style) ? (
            <BucketList style={style} />
          ) : isSingle(style) ? (
            <SingleColor style={style} />
          ) : isGradient(style) ? (
            <RangeLegend style={style} />
          ) : isCategory(style) ? (
            <CategoriesList style={style} />
          ) : null}
        </Suspense>
      </div>
    </Part_>
  );
};
/** {@link Part} but not for styles, but rather color-label pairs, in the same style as Part. */
export const SimplePart = ({ color, name }: { color: Color; name: string }) => {
  return (
    <Part_>
      <span>
        <span>{name}</span>
      </span>
      <div>
        <SingleColor_>
          <div>
            <ColorSquare $color={color} />
          </div>
          <span>{name}</span>
        </SingleColor_>
      </div>
    </Part_>
  );
};

export const CustomStyleLegend = () => {
  const setLegendOpen = useSetAtom(styleLegendOpenAtom);
  const styles = useAtomValue(activeFeatureStyles);

  return createPortal(
    <MenuFrame
      title="Legend"
      style={{
        marginLeft: "1rem",
        alignSelf: "start",
        minWidth: "20rem",
        width: "25rem",
        resize: "both",
        maxHeight: "74rem",
        position: "absolute",
        top: "calc(calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) + 0.8rem )",
        right: "calc(var(--side-bars-width) + 1.6rem)",
      }}
      onExit={() => setLegendOpen(false)}
    >
      <Column style={{ gap: "1.2rem", overflowY: "auto" }}>
        {styles.map((st) => (
          <Part key={st.id} style={st} />
        ))}
        {styles.length === 0 && (
          <EmptyState
            title="No active styles"
            icon={<StylingIcon />}
            description="Enable one or more custom styles to see their legend."
            style={{ width: "unset" }}
          />
        )}
      </Column>
    </MenuFrame>,
    document.body,
  );
};
