import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { Mixpanel } from "mixpanel";
import {
  CostConfiguration,
  CostConfigurationInput,
  _CostConfigurationInput,
  createDefaultOrgFinancialConfiguration,
  createOrgFinancialConfiguration,
  deleteOrgFinancialConfiguration,
  updateOrgFinancialConfiguration,
} from "services/costService";
import { libraryFinancialConfigRefreshAtom } from "state/costConfigurations";
import { organisationFinancialResourceState } from "../state";
import { v4 as uuid } from "uuid";
import { ProjectType } from "types/user";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

const useOrgFinancialCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { showConfirm } = useConfirm();

  const createDefault = useJotaiCallback(
    async (
      get,
      set,
      {
        name,
        projectAccess,
        projectType,
        foundationProcurementCostTableReference,
      }: {
        name: string;
        projectAccess?: string[];
        projectType: ProjectType;
        foundationProcurementCostTableReference?: string;
      },
    ) => {
      const fallback = await get(
        organisationFinancialResourceState({
          organisationId,
        }),
      );

      try {
        const res = await createDefaultOrgFinancialConfiguration(
          organisationId,
          projectType,
          name,
          foundationProcurementCostTableReference,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationFinancialResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );

        Mixpanel.track_old("Created org financial config", {
          configId: res.id,
          organisationId,
        });
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationFinancialResourceState({
            organisationId,
          }),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId],
  );

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        config,
        name,
        projectAccess,
      }: {
        config: CostConfigurationInput;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const newConfig = {
        ...config,
        name: name ? name : `${config.name} copy`,
      };

      const fallback = await get(
        organisationFinancialResourceState({
          organisationId,
        }),
      );

      try {
        const res = await createOrgFinancialConfiguration(
          organisationId,
          newConfig,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationFinancialResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );

        Mixpanel.track_old("Created org financial config", {
          configId: res.id,
          organisationId,
        });
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationFinancialResourceState({
            organisationId,
          }),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId],
  );

  const update = useJotaiCallback(
    async (get, set, config: CostConfiguration) => {
      const fallback = await get(
        organisationFinancialResourceState({
          organisationId,
        }),
      );

      const update = _CostConfigurationInput.parse(config);

      return updateOrgFinancialConfiguration(organisationId, config.id, update)
        .then((res) => {
          if (res) {
            aset(
              get,
              set,
              organisationFinancialResourceState({
                organisationId,
              }),
              (cur) => cur.map((c) => (c.id === config.id ? res : c)),
            );

            set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);

            Mixpanel.track_old("Update org financial config", {
              configId: config.id,
              organisationId,
            });
          }
        })
        .catch((e) => {
          aset(
            get,
            set,
            organisationFinancialResourceState({
              organisationId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, configId: string) => {
      const fallback = await get(
        organisationFinancialResourceState({
          organisationId,
        }),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.FINANCIAL,
          resourceId: configId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.FINANCIAL,
          configId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete configuration",
          message: `Financial configuration is currently being used in ${usage.length} branches, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return deleteOrgFinancialConfiguration(organisationId, configId)
          .then((res) => {
            if (res) {
              aset(
                get,
                set,
                organisationFinancialResourceState({
                  organisationId,
                }),
                (cur) => [...cur].filter((c) => c.id !== configId),
              );
              set(libraryFinancialConfigRefreshAtom, (cur) => cur + 1);
              Mixpanel.track_old("Delete org financial config", {
                configId: configId,
                organisationId,
              });
            }
            return {
              status: "success",
            };
          })
          .catch((e) => {
            aset(
              get,
              set,
              organisationFinancialResourceState({
                organisationId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, showConfirm],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        config,
        projectAccess,
        isMaterialFoundationCost,
        onSuccess,
      }: {
        config: CostConfiguration;
        projectAccess?: string[];
        isMaterialFoundationCost?: boolean;
        onSuccess?: (newConfig: CostConfiguration) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "cost",
          defaultName: `${config.name} (duplicate)`,
          description: isMaterialFoundationCost
            ? "Foundation material costs will be replaced with an override value. You can edit this in the new library configuration."
            : undefined,
          onDuplicate: async (name: string) => {
            const clonedConfig = _CostConfigurationInput.parse({
              ...config,
              name: name,
              capex: {
                ...config.capex,
                custom: config.capex.custom.map((entry) => ({
                  ...entry,
                  id: uuid(),
                })),
              },
              opex: {
                ...config.opex,
                custom: config.opex.custom.map((entry) => ({
                  ...entry,
                  id: uuid(),
                })),
              },
            });
            const newConfig = await create({
              config: clonedConfig,
              name,
              projectAccess,
            });
            onSuccess?.(newConfig);
            return newConfig;
          },
        },
      });
    },
    [create, setMidScreenModalTypeOpen],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, config: CostConfiguration) => {
      aset(
        get,
        set,
        organisationFinancialResourceState({ organisationId }),
        (currentConfigs) => {
          const existing = currentConfigs.find((c) => c.id === config.id);
          return existing
            ? currentConfigs.map((c) =>
                c.id === config.id ? { ...c, ...config } : c,
              )
            : [...currentConfigs, config];
        },
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, configId: string) => {
      aset(
        get,
        set,
        organisationFinancialResourceState({ organisationId }),
        (currentConfigs) => currentConfigs.filter((c) => c.id !== configId),
      );
    },
    [organisationId],
  );

  return {
    create,
    createDefault,
    update,
    remove,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgFinancialCrud;
