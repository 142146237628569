import {
  selectedMemberTabState,
  usersInOrganisationState,
} from "components/Organisation/state";
import { OrganisationUser } from "types/customer";
import { useSearch } from "./useSearch";
import { SearchItem, SearchResultItem } from "../state";
import UserImageRound from "components/UserImage/UserImageRound";
import { useMemo } from "react";
import { useSetAtom } from "jotai";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useNavigate } from "react-router-dom";
import { SecondaryText } from "../style";
import { MainText } from "../style";
import { Row } from "components/General/Layout";

const keys = ["nickname", "email"];

export function useSearchMembers(
  enabled: boolean,
  organisationId: string,
  searchTerm: string,
) {
  const navigate = useNavigate();
  const setSelectedMemberTab = useSetAtom(selectedMemberTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const { filteredResults, rawData } = useSearch<OrganisationUser>(
    enabled,
    searchTerm,
    keys,
    usersInOrganisationState(organisationId),
  );

  const filteredSearchItems: SearchResultItem<OrganisationUser>[] =
    useMemo(() => {
      return filteredResults.map((result) => ({
        id: result.item.user_id,
        name: (
          <Row alignCenter style={{ gap: "0.4rem" }}>
            <MainText>{result.item.nickname}</MainText>
            <SecondaryText>({result.item.email})</SecondaryText>
          </Row>
        ),
        score: result.score,
        item: result.item,
        onSelect: () => {
          setSelectedMemberTab("All members");
          setContent({
            type: "user",
            id: result.item.user_id,
          });
          navigate(`/organisation/${organisationId}/members`);
        },
        icon: <UserImageRound user={result.item} size={2} />,
      }));
    }, [
      filteredResults,
      navigate,
      organisationId,
      setContent,
      setSelectedMemberTab,
    ]);

  const searchableItems: SearchItem<OrganisationUser>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.user_id,
      name: item.nickname,
      item: item,
      onSelect: () => {
        setSelectedMemberTab("All members");
        setContent({
          type: "user",
          id: item.user_id,
        });
        navigate(`/organisation/${organisationId}/members`);
      },
      icon: <UserImageRound user={item} size={2} />,
    }));
  }, [rawData, navigate, organisationId, setContent, setSelectedMemberTab]);

  return { filteredMembers: filteredSearchItems, searchableItems };
}
