import Dashboard from "@icons/24/Dashboard.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import { ConfidenceLevelDot } from "components/ConfigurationModal/Cost/styles";
import Button from "components/General/Button";
import { ColoredGridFour } from "components/General/Form";
import {
  Divider,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { Row } from "components/General/Layout";
import Toggle, { ToggleSize } from "components/General/Toggle";
import Tooltip from "components/General/Tooltip";
import { orLoader, orTextLoader } from "components/Loading/Skeleton";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { ButtonWrapper } from "components/UploadModal/components/shared";
import { useRealValueCapexMillion } from "finance/hooks/useCapex";
import { FinanceId } from "finance/types";
import useBooleanState from "hooks/useBooleanState";
import { useAtomValue, useSetAtom } from "jotai";
import { costConfigurationSelectedFamily } from "state/jotai/costConfiguration";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { GeneralCost } from "types/financial";
import { currentYear } from "utils/inflation";
import {
  ScrollBody,
  useShowScrollShadow,
} from "../../../hooks/useShowScrollShadow";
import { CostType } from "../../../services/costService";
import { CostUnit, amountUnitPrefixSuffix } from "../../../types/financial";
import GeneralCostConfidenceBar from "../ConfidenceBar";
import { RealValueInfoTag } from "../costUtils";
import {
  BottomTableWrapper,
  IconWrapper,
  InfoRow,
  ResultWrapper,
  SumTotalValue,
  TableWrapper,
} from "../styles";
import { DownloadCapexData } from "./DownloadCapexData";
import { isOnshoreAtom } from "state/onshore";

const DotRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const GeneralCostRow = ({
  generalCost,
  index,
  useConfidenceLevel,
}: {
  generalCost: GeneralCost;
  index: number;
  useConfidenceLevel: boolean | undefined;
}) => {
  const helpText = generalCost.confidenceLevel
    ? `Confidence level: ${generalCost.confidenceLevel}`
    : "From components. Confidence level: Not specified";
  return (
    <ColoredGridFour
      style={{ gridTemplateColumns: "3fr 4fr 3fr 2fr" }}
      noColor={index % 2 !== 0}
    >
      <ResultValue>
        <DotRow>
          {useConfidenceLevel && (
            <Tooltip text={helpText}>
              <ConfidenceLevelDot
                confidenceLevel={generalCost.confidenceLevel}
              />
            </Tooltip>
          )}
          {`${
            amountUnitPrefixSuffix[generalCost.amountUnit]?.prefix ?? ""
          }${generalCost.amount} ${
            amountUnitPrefixSuffix[generalCost.amountUnit]?.suffix ?? ""
          }`}
        </DotRow>
      </ResultValue>
      <ResultValue title={generalCost.name}>{generalCost.name}</ResultValue>
      <ResultValue>{`${generalCost.costPerAmount} ${generalCost.unit}`}</ResultValue>
      <ResultValue>
        <strong>{`${generalCost.cost} `}</strong>
      </ResultValue>
    </ColoredGridFour>
  );
};

const GeneralCostPerCategory = ({
  id,
  type,
  title,
  viewSumPerMW,
}: {
  id: FinanceId;
  type: CostType;
  title: string;
  viewSumPerMW: boolean;
}) => {
  const { useCosts } = useRealValueCapexMillion(id);
  const { costs, sum, costsPerMW, sumPerMW } = useCosts(type);

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom);
  const selectedCostConfig = useAtomValue(
    costConfigurationSelectedFamily({ projectId, branchId }),
  );

  const useConfidenceLevel = selectedCostConfig?.useConfidenceLevel;

  const sumValueToUse = viewSumPerMW ? sumPerMW : sum;
  const sumUnitToUse = viewSumPerMW
    ? CostUnit.millionEuroPerMW
    : CostUnit.millionEuro;

  const costsToUse = viewSumPerMW ? costsPerMW : costs;

  return (
    <NewToggleableList
      title={title}
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <ResultValue>
          {orTextLoader(sumValueToUse, "-", (s) => (
            <>
              <strong>{s}</strong> {sumUnitToUse}
            </>
          ))}
        </ResultValue>
      }
      loading={costsToUse.state === "loading"}
    >
      {orLoader(costsToUse, (costs) => (
        <>
          {useConfidenceLevel && (
            <GeneralCostConfidenceBar key={type} generalCosts={costs} />
          )}
          {costs.map((c, index) => (
            <GeneralCostRow
              key={c.id}
              generalCost={c}
              index={index}
              useConfidenceLevel={useConfidenceLevel}
            />
          ))}
        </>
      ))}
    </NewToggleableList>
  );
};

const TurbinesCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Turbine}
      title={"Turbines"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const InterArrayCablingCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Cable}
      title={"Inter array cabling"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const MooringCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Mooring}
      title={"Mooring"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const SubstationCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Substation}
      title={"Substations"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const ExportCableCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.ExportCable}
      title={"Export cables"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const FoundationsCAPEX = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Foundation}
      title={"Foundations"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

const OtherCapex = ({
  id,
  viewSumPerMW,
}: {
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  return (
    <GeneralCostPerCategory
      id={id}
      type={CostType.Other}
      title={"Other"}
      viewSumPerMW={viewSumPerMW}
    />
  );
};

export const CAPEX = ({ id }: { id: FinanceId }) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const isOnshore = useAtomValue(isOnshoreAtom);

  const [viewSumPerMW, toggleViewSumPerMW] = useBooleanState(false);

  const year = currentYear();

  const { total, totalPerMW } = useRealValueCapexMillion(id);
  const totalToUse = viewSumPerMW ? totalPerMW : total;
  const totalUnit = viewSumPerMW
    ? CostUnit.millionEuroPerMW
    : CostUnit.millionEuro;

  return (
    <>
      <ScrollBody ref={scrollBodyRef}>
        <TableWrapper>
          <InfoRow>
            <IconWrapper>
              <IconREMSize height={1.4} width={1.4}>
                <InfoIcon />
              </IconREMSize>
            </IconWrapper>

            <Row alignCenter style={{ width: "100%" }}>
              <p>{year} values in real terms</p>
              <Row alignCenter style={{ marginLeft: "auto" }}>
                <label>Show m€/MW</label>
                <Toggle
                  size={ToggleSize.TINY}
                  checked={viewSumPerMW}
                  onChange={toggleViewSumPerMW}
                />
              </Row>
            </Row>
          </InfoRow>
          <TurbinesCAPEX id={id} viewSumPerMW={viewSumPerMW} />
          <InterArrayCablingCAPEX id={id} viewSumPerMW={viewSumPerMW} />
          {!isOnshore && (
            <>
              <FoundationsCAPEX id={id} viewSumPerMW={viewSumPerMW} />
              <MooringCAPEX id={id} viewSumPerMW={viewSumPerMW} />
            </>
          )}
          <SubstationCAPEX id={id} viewSumPerMW={viewSumPerMW} />
          <ExportCableCAPEX id={id} viewSumPerMW={viewSumPerMW} />
          <OtherCapex id={id} viewSumPerMW={viewSumPerMW} />
        </TableWrapper>
      </ScrollBody>
      <Divider />
      <BottomTableWrapper>
        <ResultWrapper>
          <span style={{ display: "flex", alignItems: "baseline" }}>
            <span style={{ ...typography.sub1, paddingRight: "0.8rem" }}>
              Total CAPEX
            </span>
            <RealValueInfoTag text="The total CAPEX inflation adjusted to the current year. Excluding contingency." />
          </span>

          {orLoader(totalToUse, (t) => (
            <SumTotalValue>
              <strong id={"lcoe-value"}>{t ?? "-"}</strong> {totalUnit}
            </SumTotalValue>
          ))}
        </ResultWrapper>
        <ButtonWrapper style={{ paddingTop: 0 }}>
          <DownloadCapexData id={id} buttonType={"text"} />
          <Button
            text="View in dashboard"
            buttonType="secondary"
            icon={<Dashboard />}
            onClick={() => {
              setModalType({
                modalType: DashboardModalType,
                metadata: { id: "vind-preset-dashboard-cost" },
              });
            }}
          />
        </ButtonWrapper>
      </BottomTableWrapper>
    </>
  );
};
