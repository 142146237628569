export function newtonRaphson({
  guess,
  increment,
  iterations,
  eps,
  f,
}: {
  guess: number;
  increment: number;
  iterations: number;
  eps: number;
  f: (n: number) => number;
}): number {
  let rootFound = false;

  for (let i = 0; i < iterations + 1; i++) {
    let fPrime =
      (f(guess + increment / 2) - f(guess - increment / 2)) / increment;
    guess += -f(guess) / fPrime;
    if (Math.abs(f(guess)) <= eps) {
      rootFound = true;
      break;
    }
  }

  if (rootFound) {
    return guess;
  } else {
    return 0;
  }
}
