import React, { useMemo } from "react";
import styled from "styled-components";
import type { Node } from "services/customerAPI";
import { Row } from "components/General/Layout";
import { getFolderParentChain } from "business/customer/utils";
import { getNodeName } from "components/Organisation/utils";

const BreadcrumbsRow = styled(Row)`
  gap: 0.4rem;
  white-space: nowrap;

  > * + * {
    :before {
      content: "/";
      font-weight: normal;
      margin-right: 0.4rem;
    }
  }
`;

const NodeBreadCrumbs = ({
  allNodes,
  currentFolder,
  orgNode,
  onBreadcrumbClick,
}: {
  allNodes: Node[];
  currentFolder: Node;
  orgNode?: Node;
  onBreadcrumbClick(node: Node): void;
}) => {
  const parentChain = useMemo(
    () => getFolderParentChain(allNodes, currentFolder),
    [allNodes, currentFolder],
  );

  return (
    <BreadcrumbsRow>
      <p
        style={{
          cursor: orgNode ? "pointer" : undefined,
          fontWeight: currentFolder.id === orgNode?.id ? 600 : undefined,
        }}
        onClick={() => {
          if (orgNode) {
            onBreadcrumbClick(orgNode);
          }
        }}
      >
        All projects
      </p>
      {parentChain.map((node) => (
        <p
          key={node.id}
          style={{
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => onBreadcrumbClick(node)}
          title={getNodeName(node)}
        >
          {getNodeName(node)}
        </p>
      ))}
      {currentFolder.id !== orgNode?.id && (
        <p
          style={{
            fontWeight: 600,
          }}
        >
          {getNodeName(currentFolder)}
        </p>
      )}
    </BreadcrumbsRow>
  );
};

export default NodeBreadCrumbs;
