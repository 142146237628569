import { atom } from "jotai";
import {
  ValidationWarning,
  ValidationWarningOrganisationStateType,
} from "state/validationWarnings";
import { ValidationWarningTypes } from "./utils";

export const validationFatalAblyLost = atom<
  ValidationWarningOrganisationStateType | undefined
>(undefined);

export const validationFatalGeneral = atom<
  ValidationWarning<ValidationWarningTypes.FatalGeneral> | undefined
>();
