import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IDEAL_MW_PER_AREA,
  MAX_MIN_SPACING,
  MAX_MW_PER_AREA,
  MAX_MW_PER_AREA_ONSHORE,
  MAX_PARK_TURBINES,
  MAX_TURBINES_OPTIMIZE,
  MIN_MIN_SPACING,
  MIN_MW_PER_AREA,
  MIN_TURBINES_OPTIMIZE,
} from "../../constants/park";
import { State } from "../../constants/webworker";
import { Mixpanel } from "../../mixpanel";
import { modalTypeOpenAtom } from "../../state/modal";
import { branchIdAtom, projectIdAtom } from "../../state/pathParams";
import {
  defaultIrregularOptimizeParameters,
  defaultRegularOptimizeParameters,
  defaultRegularParamsWithUnits,
  generationStateAtom,
  previewTurbinesState,
} from "../../state/turbines";
import { colors } from "../../styles/colors";
import { spaceLarge, spaceSmall, spacing6 } from "../../styles/space";
import {
  ParkFeature,
  ProjectFeature,
  SubAreaFeature,
  TurbineFeature,
} from "../../types/feature";
import {
  GenerationMethod,
  GenerationMethodAndParameters,
  GenerationMethodAndParametersWithUnit,
  OptimizeParameters,
  RegularParameters,
  RegularParametersWithUnit,
  SimpleTurbineType,
  _GenerationMethod,
} from "../../types/turbines";
import { pointInPolygon, xy2lonlat } from "../../utils/geometry";
import Button from "../General/Button";
import { Grid2 } from "../General/Form";
import { Column, Row } from "../General/Layout";
import { SkeletonText } from "../Loading/Skeleton";
import { Angle, Convert, TurbineDistance } from "../Units/units";
import { AxisLines } from "./AxisLines";
import { PreviousOptimizationsList, openOptListItem } from "./OptimizeList";
import { TurbineControl } from "./TurbineSettings";
import inferTurbineParameters, { inferResultToObj } from "./infer";
import { isCompleted } from "./predicates";
import {
  setTurbineNames,
  useOptimizeTurbines,
  useTurbineGeneration,
} from "./useGenerateAndSave";
import InfoIcon from "@icons/24/Information.svg?react";
import WindMeasure from "@icons/24/WindMeasure.svg?react";
import * as turf from "@turf/turf";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import React from "react";
import {
  OptimizeMethod,
  OptimizeObjective,
  OptimizeRuntime,
} from "services/configurationService";
import { windConfigForOptimization } from "state/windConfigForOptimizationAtom";
import styled from "styled-components";
import { throttle } from "throttle-debounce";
import { OptProblem, OptResult } from "../../functions/optimize";
import { ProjectConfigModalTypeV2 } from "../../state/configuration";
import { designToolTypeAtom, mapAtom } from "../../state/map";
import { isDefined } from "../../utils/predicates";
import { clamp, fastMax, fastMin, isNever } from "../../utils/utils";
import { RangeWithDimInput } from "../General/RangeWithDimInput";
import Toggle, { ToggleSize } from "../General/Toggle";
import Tooltip from "../General/Tooltip";
import { TurbineEllipses } from "./TurbineEllipses";
import { meterToCoords, xDistAtLat } from "./generateLayout";
import { ReferenceType } from "@floating-ui/react";
import {
  Ids,
  combinedGenMooringParameters,
  depthRangeValidAreasFamily,
  methodAndParametersForRegionAtomFamily,
  turbinesAndFoundationsGenerationIsLiveAtom,
} from "./state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { windConfigurationsFamily } from "state/jotai/windConfiguration";
import {
  mostProbableWindDirectionFamily,
  windDatasetsFamily,
} from "state/jotai/windStatistics";
import { loadable, unwrap } from "jotai/utils";
import { turbinesInParkFamily } from "state/jotai/turbine";
import {
  currentTurbineIdAtom,
  simpleTurbineTypesAtom,
} from "state/jotai/turbineType";
import { selectedTurbineTypesMostFrequentAtom } from "state/jotai/selection";
import { isMultipleSourceWindConfiguration } from "services/windSourceConfigurationService";
import {
  ARTICLE_LAYOUT_GEN,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import useDefaultTurbines from "hooks/useDefaultTurbines";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { foundationFixedTypesAtom } from "state/jotai/foundation";
import { LCOESettings, LCOESettingsMenu } from "./LCOESettings";
import { useBathymetry } from "hooks/bathymetry";
import { bathymetryFamily } from "state/bathymetry";
import { BBox } from "geojson";
import { MultiPolygon } from "geojson";
import { Polygon } from "geojson";
import { getFoundationDepthRange } from "state/foundations";
import { isOnshoreAtom } from "state/onshore";
import { DesignToolMode } from "types/map";
import { Raster } from "types/raster";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import SelectWindDropDownCustom from "components/ProductionV2/SelectWindDropDown";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { WindDataSource } from "types/metocean";

type OptimizeTurbinesContextType = {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  coordsToFeatures: (points: [number, number][]) => TurbineFeature[];
};

export const OptimizeTurbinesContext = React.createContext<
  OptimizeTurbinesContextType | undefined
>(undefined);

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${spaceSmall};
`;

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0 0 0 1rem;
  svg {
    width: 1.3rem;
    height: 1.3rem;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const columnStyle: CSSProperties = {
  overflowY: "auto",
  rowGap: spacing6,
};

const TurbineMinorAxisSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>Minor axis spacing</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const TurbineMajorAxisSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>Major axis spacing</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const TurbineEdgeSpacing = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <p>Perimeter turbine spacing</p>
      <RangeWithDimInput
        min={unit === "m" ? 500 : 2}
        max={unit === "m" ? 4000 : 16}
        rangeStep={unit === "m" ? 50 : 0.01}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const LayoutRotation = ({
  value: { value, unit },
  set,
  convert,
  meanWindDirection,
}: {
  value: Angle.Of<number>;
  set: (n: Angle.Of<number>) => void;
  convert: Convert<Angle.Unit>;
  meanWindDirection?: number;
}) => {
  return (
    <InputBox>
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <InputTitle>Major axis direction</InputTitle>
        {isDefined(meanWindDirection) && (
          <Button
            icon={<WindMeasure />}
            onClick={() =>
              set({
                value: meanWindDirection,
                unit,
              })
            }
            tooltip="Set to most probable direction (ERA5)"
            buttonType="text"
            text={`${meanWindDirection} deg`}
            style={{
              fontSize: "1rem",
              height: "1rem",
              textDecoration: "underline",
            }}
          />
        )}
      </div>
      <RangeWithDimInput
        min={
          Angle.range(unit)[0] +
          convert(180, {
            to: unit,
          })
        }
        max={
          Angle.range(unit)[1] +
          convert(180, {
            to: unit,
          })
        }
        value={value}
        unit={unit}
        units={Angle.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={Angle.format}
      />
    </InputBox>
  );
};

const Obliquity = ({
  value: { value, unit },
  set,
  convert,
  min,
  max,
}: {
  value: Angle.Of<number>;
  set: (n: Angle.Of<number>) => void;
  convert: Convert<Angle.Unit>;
  min: number;
  max: number;
}) => {
  return (
    <InputBox>
      <InputTitle>Obliquity</InputTitle>
      <RangeWithDimInput
        min={convert(min, {
          to: unit,
        })}
        max={convert(max, {
          to: unit,
        })}
        value={value}
        unit={unit}
        units={Angle.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={Angle.format}
      />
    </InputBox>
  );
};

const ShiftParkX = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>East/West offset</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? -4000 : -14}
        max={unit === "m" ? 4000 : 14}
        rangeStep={unit === "m" ? 100 : 0.1}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const ShiftParkY = ({
  value: { value, unit },
  set,
  convert,
}: {
  value: TurbineDistance.Of<number>;
  set: (n: TurbineDistance.Of<number>) => void;
  convert: Convert<TurbineDistance.Unit>;
}) => {
  return (
    <InputBox>
      <InputTitle>North/South offset</InputTitle>
      <RangeWithDimInput
        min={unit === "m" ? -4000 : -14}
        max={unit === "m" ? 4000 : 14}
        rangeStep={unit === "m" ? 100 : 0.1}
        value={value}
        unit={unit}
        units={TurbineDistance.units}
        onChange={(n) => {
          set({
            value: n,
            unit,
          });
        }}
        onUnitChange={(u) => {
          set({
            value: convert(
              convert(value, {
                from: unit,
              }),
              {
                to: u,
              },
            ),
            unit: u,
          });
        }}
        format={TurbineDistance.format}
      />
    </InputBox>
  );
};

const NumberOfTurbines = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
        gap: "1.2rem",
        justifyContent: "space-between",
      }}
    >
      <InputTitle>Number of turbines</InputTitle>
      <InputDimensioned
        compact
        min={min}
        max={max}
        validate={(n) => n <= max && n >= min}
        validationMessage={`Must be between ${min} and ${max}`}
        step={1}
        value={value}
        disabled={disabled}
        onChange={(n) => {
          set(n);
        }}
        validationMessageStyle={{
          transform: "translateY(-80%) translateX(-25%)",
        }}
      />
    </Grid2>
  );
};

const ObjectiveOptimize = ({
  value,
  set,
  disabled,
}: {
  value: OptimizeObjective;
  set: (n: OptimizeObjective) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
        paddingTop: "1rem",
      }}
    >
      <InputTitle>Objective</InputTitle>
      <DropdownButton
        size="small"
        itemWidth="small"
        disabled={disabled}
        items={[
          { name: "AEP", value: "aep" },
          { name: "LCoE", value: "lcoe" },
        ]}
        selectedItemValue={value}
        style={{ width: "100%" }}
        buttonText={value === "aep" ? "AEP" : "LCoE"}
        onSelectItem={(val) => set(val as OptimizeObjective)}
      />
    </Grid2>
  );
};

type ContourFeature = {
  properties: {
    depth: number;
  };
  type: "Feature";
  geometry: MultiPolygon | Polygon;
  id?: string | number | undefined;
  bbox?: BBox | undefined;
};

const FoundationTypeOptimize = ({
  value,
  set,
  disabled,
  region,
}: {
  value: string;
  set: (n: string) => void;
  disabled?: boolean;
  region: ParkFeature | SubAreaFeature;
}) => {
  const [depthRange, setDepthRange] = useState<[number, number] | undefined>();
  const [parkDepths, setParkDepths] = useState<{
    minDepth: number;
    maxDepth: number;
  }>({
    minDepth: 0,
    maxDepth: 0,
  });
  const [raster, setRaster] = useState<Raster | undefined>();

  const map = useAtomValue(mapAtom);
  const allFoundationTypes = useAtomValue(foundationFixedTypesAtom);

  const sourceId = "depth-range-source-id";
  const layerId = "depth-range-layer-id";

  const [, setValidDepthAreas] = useAtom(
    depthRangeValidAreasFamily({
      _foundationTypeId: value,
      _regionId: region.id,
    }),
  );

  useEffect(() => {
    if (value) {
      const foundationType = allFoundationTypes.find((f) => f.id === value)!;
      const { minDepth, maxDepth } = getFoundationDepthRange(foundationType);
      setDepthRange([minDepth, maxDepth]);
    }
  }, [value, allFoundationTypes]);

  const [bathymetryIdLoadable] = useBathymetry({
    featureId: region.id,
    bufferKm: undefined,
    projectId: undefined,
    branchId: undefined,
  });

  const bathymetryAtom = useMemo(
    () =>
      bathymetryFamily(
        bathymetryIdLoadable.state === "hasData"
          ? bathymetryIdLoadable.data.id
          : "",
      ),
    [bathymetryIdLoadable],
  );

  const bathymetryLoadable = useAtomValue(loadable(bathymetryAtom));

  useEffect(() => {
    if (
      bathymetryLoadable.state === "hasData" &&
      bathymetryLoadable.data.status === "finished" &&
      bathymetryLoadable.data.raster
    ) {
      const raster = bathymetryLoadable.data.raster;
      setRaster(raster);
      const minDepth = -fastMax(raster.values ?? []);
      const maxDepth = -fastMin(raster.values ?? []);
      setParkDepths({ minDepth, maxDepth });
    }
  }, [bathymetryLoadable, setRaster, setParkDepths]);

  useEffect(() => {
    if (!raster || !depthRange || !map) return;

    const [minLon, minLat, maxLon, maxLat] = raster.bbox();
    const sizeLatitude = maxLat - minLat;
    const sizeLongitude = maxLon - minLon;
    const values = raster.values;
    const m = raster.height;
    const n = raster.width;

    const depthCountourWorker = new Worker(
      new URL(
        "../RightSide/InfoModal/ProjectFeatureInfoModal/depthAnalysisContourWorker.ts",
        import.meta.url,
      ),
      { type: "module" },
    );

    depthCountourWorker.postMessage([
      values,
      m,
      n,
      sizeLatitude,
      sizeLongitude,
      minLon,
      maxLat,
      [-depthRange[0], -depthRange[1]],
      region,
      -Infinity,
    ]);

    depthCountourWorker.onmessage = function (e) {
      const features = e.data;

      if (features && features.length > 0) {
        const coordinates = features.flatMap((f: any) => {
          if (f.geometry.type === "Polygon") {
            return [f.geometry.coordinates];
          } else if (f.geometry.type === "MultiPolygon") {
            return f.geometry.coordinates;
          }
          return [];
        });

        const validDepthArea = turf.multiPolygon(coordinates);
        setValidDepthAreas(validDepthArea);
      } else {
        setValidDepthAreas(undefined);
      }

      if (!map.getSource(sourceId)) {
        map.addSource(sourceId, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features.map((f: ContourFeature) => ({
              ...f,
              properties: {
                ...f.properties,
                color: colors.inclusionZone,
              },
            })),
          },
        });

        map.addLayer({
          id: layerId,
          type: "fill",
          source: sourceId,
          paint: {
            "fill-color": ["get", "color"],
            "fill-opacity": 0.3,
          },
        });
      } else {
        const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
        source.setData({
          type: "FeatureCollection",
          features: features,
        });
      }
    };

    return () => {
      depthCountourWorker.terminate();
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
      setValidDepthAreas(undefined);
    };
  }, [raster, depthRange, map, region, value, setValidDepthAreas]);
  const currentFoundation = allFoundationTypes.find((f) => f.id === value);
  return (
    <>
      <Grid2
        style={{
          gridTemplateColumns: "0.8fr 0.9fr",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <InputTitle>Foundation</InputTitle>
          <Tooltip text="Only available for bottom fixed foundation.">
            <Wrapper>
              <InfoIcon />
            </Wrapper>
          </Tooltip>
        </div>
        <DropdownButton
          buttonText={currentFoundation?.name ?? "Select foundation type"}
          size={"small"}
          disabled={disabled}
          items={allFoundationTypes.map((f) => {
            const { minDepth, maxDepth } = getFoundationDepthRange(f);
            return {
              name: f.name,
              value: f.id,
              disabled:
                minDepth >= parkDepths.maxDepth ||
                maxDepth <= parkDepths.minDepth,
            };
          })}
          selectedItemValue={value}
          style={{ width: "13.5rem" }}
          onSelectItem={(value) => {
            const foundationTypeId = value;
            const foundationType = allFoundationTypes.find(
              (f) => f.id === foundationTypeId,
            )!;
            set(foundationTypeId);
            setDepthRange(() => {
              const { minDepth, maxDepth } =
                getFoundationDepthRange(foundationType);
              return [minDepth, maxDepth];
            });
          }}
        />
      </Grid2>
      {depthRange && (
        <p style={{ color: colors.textDisabled }}>
          {`Valid depth range: ${depthRange[1] === Infinity ? ">" : ""} ${depthRange[0]}m ${depthRange[1] === Infinity ? "" : `- ${depthRange[1]}m`}`}
        </p>
      )}
    </>
  );
};

const NumberOfTurbinesOptimize = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
      }}
    >
      <InputTitle>Number of turbines</InputTitle>
      <InputDimensioned
        style={{
          width: "100%",
        }}
        validate={(n) => (max > min ? n <= max && n >= min : n <= max)}
        validationMessage={
          max > min
            ? `Must be between ${min} and ${max}`
            : `Must be equal to or less than ${max}`
        }
        compact
        decimals={0}
        min={min}
        max={max}
        step={1}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          set(Math.round(e));
        }}
      />
    </Grid2>
  );
};

const NumberOfTurbinesExploration = ({
  minValue,
  maxValue,
  setMin,
  setMax,
  disabled,
  maxValidation,
  minValidation,
}: {
  minValue: number;
  maxValue: number;
  setMin: (n: number) => void;
  setMax: (n: number) => void;
  disabled?: boolean;
  minValidation: number;
  maxValidation: number;
}) => {
  return (
    <>
      <InputTitle>Number of turbines</InputTitle>
      <Row style={{ marginTop: "-0.4rem" }}>
        <InputDimensioned
          style={{
            width: "100%",
          }}
          validate={(n) =>
            n <= Math.min(maxValidation, maxValue) && n >= minValidation
          }
          validationMessage={`Must be between ${minValidation} and ${Math.min(maxValidation, maxValue)}`}
          compact
          unit={"min"}
          decimals={0}
          min={minValidation}
          max={Math.min(maxValidation, maxValue)}
          step={1}
          value={minValue}
          disabled={disabled}
          onChange={(e) => {
            setMin(Math.round(e));
          }}
        />
        <InputDimensioned
          style={{
            width: "100%",
          }}
          validate={(n) =>
            n <= maxValidation && n >= Math.max(minValidation, minValue)
          }
          validationMessage={`Must be between ${Math.max(minValidation, minValue)} and ${maxValidation}`}
          compact
          unit={"max"}
          decimals={0}
          min={Math.max(minValidation, minValue)}
          max={maxValidation}
          step={1}
          value={maxValue}
          disabled={disabled}
          onChange={(e) => {
            setMax(Math.round(e));
          }}
        />
      </Row>
    </>
  );
};

const IncludeNumberOfTurbines = ({
  value,
  set,
  disabled,
  numTurbines,
  capacityDensity,
}: {
  value: boolean;
  set: (n: boolean) => void;
  disabled?: boolean;
  numTurbines: number;
  capacityDensity: number;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "auto 5fr",
      }}
    >
      <Toggle
        checked={value}
        onChange={() => !disabled && set(!value)}
        size={ToggleSize.SMALL}
      />
      <div
        style={{
          alignContent: "start",
        }}
      >
        <InputTitle>Limit number of turbines</InputTitle>
        {value && (
          <p
            style={{
              color: colors.textDisabled,
            }}
          >
            {`Turbines: ${numTurbines}, density: ${capacityDensity.toFixed(1)} MW/km²`}
          </p>
        )}
      </div>
    </Grid2>
  );
};

const OptimizeMinSpacing = ({
  value,
  set,
  disabled,
  max,
  min,
}: {
  value: number;
  set: (n: number) => void;
  max: number;
  min: number;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
      }}
    >
      <InputTitle>Minimum spacing</InputTitle>
      <InputDimensioned
        style={{
          width: "100%",
        }}
        compact={true}
        unit={"D"}
        value={value}
        step={0.1}
        disabled={disabled}
        validate={(n) => n <= max && n >= min}
        validationMessage={`Must be between ${min} and ${max}D`}
        onChange={(n) => {
          set(n);
        }}
      />
    </Grid2>
  );
};

const IncludeEdge = ({
  value,
  set,
}: {
  value: boolean;
  set: (n: boolean) => void;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "auto 5fr",
      }}
    >
      <Toggle
        checked={value}
        onChange={() => set(!value)}
        size={ToggleSize.SMALL}
      />
      <p>Add perimeter turbines</p>
    </Grid2>
  );
};

const OptimizeIncludeNeighbours = ({
  value,
  set,
}: {
  value: boolean;
  set: (n: boolean) => void;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "auto 5fr",
        alignItems: "center",
      }}
    >
      <Toggle
        checked={value}
        onChange={() => set(!value)}
        size={ToggleSize.SMALL}
      />
      <div
        style={{
          display: "flex",
        }}
      >
        <p>Neighbour wake loss</p>
        <Tooltip text="Account for neighbour wake loss during the optimization. This will include neighbour turbines within 20km of the park boundary.">
          <Wrapper>
            <InfoIcon />
          </Wrapper>
        </Tooltip>
      </div>
    </Grid2>
  );
};

const ChooseOptimizeRuntime = ({
  value,
  set,
  disabled,
}: {
  value?: OptimizeRuntime;
  set: (v: OptimizeRuntime) => void;
  disabled?: boolean;
}) => {
  return (
    <Grid2
      style={{
        gridTemplateColumns: "0.8fr 0.9fr",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputTitle>Precision</InputTitle>
        <Tooltip text="A proxy for how long the optimization will iterate to find a solution. The Fast option should give you results in less than a minute. The Medium option will run for up to 10 minutes, and give slightly higher AEP.">
          <Wrapper>
            <InfoIcon />
          </Wrapper>
        </Tooltip>
      </div>
      <DropdownButton
        size={"small"}
        itemWidth="small"
        buttonText={value === "fast" ? "Fast" : "Medium"}
        items={[
          { name: "Fast", value: "fast" },
          { name: "Medium", value: "default" },
        ]}
        selectedItemValue={value}
        disabled={disabled}
        onSelectItem={(value) => {
          set(value as OptimizeRuntime);
        }}
      />
    </Grid2>
  );
};

type Props = {
  setGenParam: (field: string) => <T>(value: T) => void;
  convertD: Convert<TurbineDistance.Unit>;
  region: ParkFeature | SubAreaFeature;
  turbineType: SimpleTurbineType;
  capacityDensity: number;
};

const OptimizeSelectDataSource = () => {
  const projectId = useAtomValue(projectIdAtom);
  const windConfigs = useAtomValue(
    windConfigurationsFamily({
      projectId,
    }),
  );

  const singleTimeSeriesWindConfigs = Array.from(windConfigs.values()).filter(
    (c) => !isMultipleSourceWindConfiguration(c),
  );
  const [selectedWindConfigForOpt, setWindConfigForOpt] = useAtom(
    windConfigForOptimization,
  );
  const setModalType = useSetAtom(modalTypeOpenAtom);
  useEffect(() => {
    if (singleTimeSeriesWindConfigs.length > 0 && !selectedWindConfigForOpt) {
      setWindConfigForOpt(singleTimeSeriesWindConfigs[0]);
    }
  }, [
    singleTimeSeriesWindConfigs,
    setWindConfigForOpt,
    selectedWindConfigForOpt,
  ]);

  return (
    <>
      <Grid2
        style={{
          gridTemplateColumns: "0.8fr 0.9fr",
          gap: spacing6,
          justifyContent: "space-between",
        }}
      >
        <InputTitle>Wind configuration</InputTitle>
        <SelectWindDropDownCustom
          projectId={projectId ?? ""}
          aria-label="wind-config"
          listContainerStyle={{
            maxWidth: "40rem",
          }}
          style={{
            width: "13.5rem",
          }}
          onSelectItem={(item) => {
            const selectedId = item;
            if (selectedId === "edit") {
              setModalType({
                modalType: ProjectConfigModalTypeV2,
                metadata: {
                  selectedMenuId: "wind",
                  selectedConfigId: selectedWindConfigForOpt?.id,
                },
              });
              return;
            }
            const selectedWindConfig = windConfigs.get(selectedId);
            setWindConfigForOpt(selectedWindConfig);
          }}
          selectedConfig={selectedWindConfigForOpt}
        />
      </Grid2>
    </>
  );
};

const RegularGenerationSettings = ({
  params,
  setGenParam,
  convertD,
  region,
  turbineType,
  maxNumberOfTurbines,
  capacityDensity,
  area,
}: {
  params: RegularParametersWithUnit;
  maxNumberOfTurbines: number;
  area: number;
} & Props) => {
  const lonlat = useMemo(
    () => turf.centerOfMass(region).geometry.coordinates,
    [region],
  );
  const mwd = useAtomValue(
    unwrap(
      mostProbableWindDirectionFamily({
        lon: lonlat[0],
        lat: lonlat[1],
        height: turbineType.hubHeight,
        fromYear: undefined,
        toYear: undefined,
      }),
    ),
  );
  const isOnshore = useAtomValue(isOnshoreAtom);

  const maxDensity = isOnshore ? MAX_MW_PER_AREA_ONSHORE : MAX_MW_PER_AREA;

  const max = useMemo(
    () =>
      Math.min(
        Math.ceil((area * maxDensity) / (turbineType.ratedPower / 1e3)),
        MAX_PARK_TURBINES,
      ),
    [turbineType, area, maxDensity],
  );

  const numTurbines = params.setNumberOfTurbines
    ? params.numberOfTurbines
    : Math.min(max, maxNumberOfTurbines);

  const obliquityMax = useMemo(() => {
    const shiftMax = params.majorAxisSpacing.value / 2;
    return Math.ceil(
      Math.atan(shiftMax / params.minorAxisSpacing.value) * (180 / Math.PI),
    );
  }, [params.majorAxisSpacing, params.minorAxisSpacing]);

  useEffect(() => {
    if (params.obliquity.value > obliquityMax) {
      setGenParam("obliquity")({
        value: obliquityMax,
        unit: "deg",
      });
    } else if (params.obliquity.value < -obliquityMax) {
      setGenParam("obliquity")({
        value: -obliquityMax,
        unit: "deg",
      });
    }
  }, [obliquityMax, params.obliquity, setGenParam]);

  const initialTurbines = useMemo(
    () =>
      clamp(
        1,
        Math.floor((area * IDEAL_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        MAX_PARK_TURBINES,
      ),
    [area, turbineType.ratedPower],
  );

  if (params.numberOfTurbines === 0) {
    setTimeout(() => setGenParam("numberOfTurbines")(initialTurbines), 0);
  }

  return (
    <Column style={columnStyle}>
      <div style={{ padding: "0.8rem 0" }}>
        <TurbineControl />
      </div>

      <LayoutRotation
        value={params.rotate}
        set={setGenParam("rotate")}
        convert={Angle.convert}
        meanWindDirection={mwd}
      />
      <TurbineMajorAxisSpacing
        value={params.majorAxisSpacing}
        set={setGenParam("majorAxisSpacing")}
        convert={convertD}
      />
      <TurbineMinorAxisSpacing
        value={params.minorAxisSpacing}
        set={setGenParam("minorAxisSpacing")}
        convert={convertD}
      />
      {params.includeEdge && params.edgeSpacing && (
        <TurbineEdgeSpacing
          value={params.edgeSpacing}
          set={setGenParam("edgeSpacing")}
          convert={convertD}
        />
      )}
      <Obliquity
        value={params.obliquity}
        set={setGenParam("obliquity")}
        convert={Angle.convert}
        min={-obliquityMax}
        max={obliquityMax}
      />
      <ShiftParkX
        value={params.shiftX}
        set={setGenParam("shiftX")}
        convert={convertD}
      />
      <ShiftParkY
        value={params.shiftY}
        set={setGenParam("shiftY")}
        convert={convertD}
      />

      <Row
        style={{
          marginTop: "2rem",
        }}
      >
        <IncludeNumberOfTurbines
          value={params.setNumberOfTurbines}
          set={(v) => {
            setGenParam("setNumberOfTurbines")(v);
          }}
          numTurbines={numTurbines}
          capacityDensity={capacityDensity}
        />
      </Row>

      {params.setNumberOfTurbines && (
        <NumberOfTurbines
          disabled={!params.setNumberOfTurbines}
          value={numTurbines}
          set={(v) => {
            setGenParam("numberOfTurbines")(v);
          }}
          max={max}
          min={1}
        />
      )}
      {numTurbines > maxNumberOfTurbines && (
        <SimpleAlert
          text={`Cannot fit more than ${maxNumberOfTurbines} turbines`}
        />
      )}
    </Column>
  );
};

const OptimizeGenerationSettings = ({
  params,
  turbineType,
  park,
  region,
  setGenParam,
  isResultsOpen,
  setIsResultsOpen,
  makeTurbineFeatures,
  selectedZoneSize,
  selectedRegion,
  capacityDensity,
}: {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  params: OptimizeParameters;
  turbineType: SimpleTurbineType;
  isResultsOpen: boolean;
  setIsResultsOpen: (value: boolean) => void;
  selectedZoneSize: number | undefined;
  selectedRegion: ParkFeature | SubAreaFeature | undefined;
  makeTurbineFeatures: (
    lonlats: [number, number][],
    turbineTypes?: string[],
    foundationId?: string,
  ) => TurbineFeature[];
} & Props) => {
  const defaultTurbines = useDefaultTurbines();
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const isOnshore = useAtomValue(isOnshoreAtom);
  const selectedWindConfigForOpt = useAtomValue(windConfigForOptimization);
  const [openLCOESubmenu, setOpenLCOESubmenu] = useState<boolean>(false);
  const { showConfirm } = useConfirm();
  const shouldCheckForAvailability =
    selectedWindConfigForOpt?.source.id === WindDataSource.NORA3 ||
    selectedWindConfigForOpt?.source.id === WindDataSource.CERRA;

  const lonlat = useMemo(() => {
    return turf.centroid(region).geometry.coordinates;
  }, [region]);

  const availableDatasetsAtLocation = useAtomValue(
    loadable(
      windDatasetsFamily({
        lon: lonlat[0],
        lat: lonlat[1],
      }),
    ),
  );

  const selectedDatasetIsAvailable = useMemo(() => {
    if (!shouldCheckForAvailability) return true;

    if (
      availableDatasetsAtLocation.state !== "hasData" ||
      !selectedWindConfigForOpt
    )
      return undefined;
    return availableDatasetsAtLocation.data.some(
      (dataset: any) =>
        dataset.source.id === selectedWindConfigForOpt.source.id,
    );
  }, [
    availableDatasetsAtLocation,
    selectedWindConfigForOpt,
    shouldCheckForAvailability,
  ]);

  const createOptProblem = useOptimizeTurbines(park, region.id, params);
  const setOpenOptItem = useSetAtom(openOptListItem);
  const [buttonLoads, setButtonLoads] = useState(false);

  const depthRangeValidAreas = useAtomValue(
    depthRangeValidAreasFamily({
      _foundationTypeId: params.foundationTypeId ?? undefined,
      _regionId: region.id,
    }),
  );
  const depthRangeValidAreasSize = useMemo(() => {
    if (!depthRangeValidAreas) return undefined;
    return turf.area(depthRangeValidAreas) / 1e6;
  }, [depthRangeValidAreas]);

  const regionSize = useMemo(() => turf.area(region) / 1e6, [region]);
  const zoneArea = selectedZoneSize ?? regionSize;
  const area = depthRangeValidAreasSize ?? zoneArea;

  const ids: Ids | undefined = useMemo(
    () =>
      projectId && branchId
        ? {
            nodeId: projectId,
            branchId: branchId,
            zoneId: region.id,
          }
        : undefined,
    [projectId, branchId, region.id],
  );

  const maxDensity = isOnshore ? MAX_MW_PER_AREA_ONSHORE : MAX_MW_PER_AREA;
  const maxTurbines = useMemo(
    () =>
      Math.min(
        Math.ceil((area * maxDensity) / (turbineType.ratedPower / 1e3)),
        MAX_TURBINES_OPTIMIZE,
        Math.ceil(
          (4 * area) /
            (Math.PI * ((turbineType.diameter / 1e3) * params.minSpacing) ** 2),
        ),
      ),
    [turbineType, area, params.minSpacing, maxDensity],
  );

  const minTurbines = useMemo(
    () =>
      params.method === "exploration"
        ? MIN_TURBINES_OPTIMIZE
        : clamp(
            MIN_TURBINES_OPTIMIZE,
            Math.floor(
              (area * MIN_MW_PER_AREA) / (turbineType.ratedPower / 1e3),
            ),
            maxTurbines,
          ),
    [area, turbineType.ratedPower, maxTurbines, params?.method],
  );

  const optCapacityDensityMax = capacityDensity * (zoneArea / area);
  const capacityDensityMin =
    ((params.minNumberOfTurbines ?? minTurbines) * turbineType.ratedPower) /
    1e3 /
    area;
  const optCapacityDensityMin = capacityDensityMin * (zoneArea / area);

  const initialTurbines = useMemo(
    () =>
      clamp(
        MIN_TURBINES_OPTIMIZE,
        Math.floor((area * IDEAL_MW_PER_AREA) / (turbineType.ratedPower / 1e3)),
        maxTurbines,
      ),
    [area, turbineType.ratedPower, maxTurbines],
  );

  if (params.numberOfTurbines === 0) {
    setTimeout(() => setGenParam("numberOfTurbines")(initialTurbines), 0);
  }
  useEffect(() => {
    if (params.minNumberOfTurbines === undefined) {
      setGenParam("minNumberOfTurbines")(
        Math.max(initialTurbines - 10, minTurbines),
      );
    }
  }, [setGenParam, minTurbines, initialTurbines, params.minNumberOfTurbines]);

  const { update: updateFeatures } = useProjectElementsCrud();

  const { getFeaturesToSave } = useTurbineGeneration(
    park,
    selectedRegion ?? park,
    defaultTurbines[0] as SimpleTurbineType,
  );

  const coordsToFeatures = useCallback(
    (points: [number, number][]) => {
      const polygons =
        region.geometry.type === "Polygon"
          ? [region.geometry.coordinates]
          : region.geometry.coordinates;

      const features = polygons.flatMap((coordinates) => {
        const [reflon, reflat] = coordinates[0][0];
        const lonlats = points.map<[number, number]>(([x, y]) =>
          xy2lonlat(x, y, reflon, reflat),
        );
        return makeTurbineFeatures(lonlats);
      });

      setTurbineNames(features);
      return features;
    },
    [makeTurbineFeatures, region.geometry.coordinates, region.geometry.type],
  );

  const onOptionClick: Parameters<
    typeof PreviousOptimizationsList
  >[0]["onOptionClick"] = useCallback(
    async (res: OptResult, _: OptProblem, isOutdated: boolean) => {
      if (!isCompleted(res)) return;

      if (
        isOutdated &&
        !(await showConfirm({
          title: "Outdated optimization",
          message:
            "This optimization was run on another version of the park.  Do you still want to use the resulting turbines?",
        }))
      ) {
        return;
      }

      const lonlats =
        res.turbines?.map((t) => [t.lon, t.lat] as [number, number]) ?? [];
      const turbineTypes = res.turbines?.map((t) => t.type) ?? [];
      const foundationId = res.foundationTypeId ?? undefined;
      const features = makeTurbineFeatures(lonlats, turbineTypes, foundationId);

      setTurbineNames(features);

      let featuresToSave: {
        add: ProjectFeature[];
        remove: string[];
      } = await getFeaturesToSave(features);

      await updateFeatures(featuresToSave);
    },
    [makeTurbineFeatures, updateFeatures, getFeaturesToSave, showConfirm],
  );
  const currentTurbines = useAtomValue(
    turbinesInParkFamily({ parkId: park.id, branchId }),
  );

  const trackEvent = useTrackEvent();
  return (
    <>
      <OptimizeTurbinesContext.Provider
        value={{
          region,
          park,
          coordsToFeatures,
        }}
      >
        <Column
          style={{
            rowGap: spacing6,
            padding: "0 0 1.2rem 0",
          }}
        >
          {params.method !== "micrositing" &&
            params.method !== "exploration" &&
            !isOnshore && (
              <ObjectiveOptimize
                value={params.objective}
                set={setGenParam("objective")}
              />
            )}
          {params.method !== "micrositing" && <TurbineControl />}
          {(params.method === "regular" || params.method === "irregular") &&
            params.objective === "lcoe" && (
              <FoundationTypeOptimize
                value={params.foundationTypeId ?? ""}
                set={setGenParam("foundationTypeId")}
                region={region}
              />
            )}
          {(params.method === "regular" || params.method === "irregular") &&
            params.objective === "lcoe" &&
            params.costInput && (
              <LCOESettings
                openSubmenu={openLCOESubmenu}
                setOpenSubmenu={setOpenLCOESubmenu}
              />
            )}
          {params.method === "micrositing" && (
            <SimpleAlert
              text={
                currentTurbines.length === 0
                  ? "Micrositing requires at least one turbine in the park. Use another method to generate initial turbine positions."
                  : "Micrositing uses the current turbines as the initial positions, including the turbine types."
              }
              type={"info"}
            />
          )}
          {params.method !== "micrositing" &&
            maxTurbines >= MIN_TURBINES_OPTIMIZE &&
            (params.method === "exploration" ? (
              <>
                <NumberOfTurbinesExploration
                  maxValue={params.numberOfTurbines}
                  minValue={params.minNumberOfTurbines ?? minTurbines}
                  setMax={(v) => {
                    setGenParam("numberOfTurbines")(v);
                  }}
                  setMin={(v) => {
                    setGenParam("minNumberOfTurbines")(v);
                  }}
                  maxValidation={maxTurbines}
                  minValidation={minTurbines}
                />
                <p
                  style={{
                    color: colors.textDisabled,
                  }}
                >
                  {`Capacity density: ${optCapacityDensityMin.toFixed(1)} MW/km² — ${capacityDensity.toFixed(1)} MW/km²`}
                </p>
              </>
            ) : (
              <>
                <NumberOfTurbinesOptimize
                  value={params.numberOfTurbines}
                  set={(v) => {
                    setGenParam("numberOfTurbines")(v);
                  }}
                  max={maxTurbines}
                  min={minTurbines}
                />
                <p
                  style={{
                    color: colors.textDisabled,
                  }}
                >
                  {`Capacity density: ${optCapacityDensityMax.toFixed(1)} MW/km²`}
                </p>
              </>
            ))}
          {maxTurbines < MIN_TURBINES_OPTIMIZE && (
            <SimpleAlert
              text={`Area is too small to perform optimization`}
              type={"error"}
            />
          )}
          {maxTurbines >= MIN_TURBINES_OPTIMIZE &&
            params.numberOfTurbines > maxTurbines && (
              <SimpleAlert
                text={`Cannot fit more than ${maxTurbines} turbines`}
                type={"error"}
              />
            )}
        </Column>
        <Suspense
          fallback={<SkeletonText style={{ width: "100%", height: "2rem" }} />}
        >
          <Column
            style={{
              rowGap: spacing6,
              padding: "1.2rem 0 2rem 0",
            }}
          >
            <OptimizeSelectDataSource />
            {params.method !== "regular" && params.method !== "exploration" && (
              <ChooseOptimizeRuntime
                value={params.runtime}
                set={(v) => {
                  setGenParam("runtime")(v);
                }}
              />
            )}

            {params.method !== "micrositing" && (
              <OptimizeMinSpacing
                value={params.minSpacing}
                set={setGenParam("minSpacing")}
                max={MAX_MIN_SPACING}
                min={MIN_MIN_SPACING}
              />
            )}
          </Column>
          <Column
            style={{
              rowGap: spacing6,
              padding: "1.2rem 0 2rem 0",
            }}
          >
            {params.method === "regular" && (
              <IncludeEdge
                value={params.includeEdge}
                set={(v) => {
                  setGenParam("includeEdge")(v);
                }}
              />
            )}
            <OptimizeIncludeNeighbours
              value={params.includeNeighbours}
              set={(v) => {
                setGenParam("includeNeighbours")(v);
              }}
            />
          </Column>
        </Suspense>
        {selectedDatasetIsAvailable === false && (
          <SimpleAlert
            text={"Wind config available at this location"}
            type={"error"}
          />
        )}
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "2rem",
          }}
        >
          <Button
            buttonType="text"
            text={isResultsOpen ? "Hide results" : "Show results"}
            onClick={async () => {
              setIsResultsOpen(!isResultsOpen);
            }}
          />
          <HighlightStep stepId="optimise" tourId="general-intro-tour">
            <Button
              text={"Run optimization"}
              tooltip={
                selectedDatasetIsAvailable === undefined
                  ? "Checking for wind config availability"
                  : selectedWindConfigForOpt === undefined
                    ? "Fetching wind configuration"
                    : params.objective === "lcoe" &&
                        params.foundationTypeId === undefined
                      ? "Select foundation type"
                      : undefined
              }
              onClick={async () => {
                setIsResultsOpen(true);
                trackEvent("optimisationStarted");
                Mixpanel.track_old("Triggered optimization", {
                  projectId,
                  branchId,
                });
                setButtonLoads(true);
                const result = await createOptProblem(selectedWindConfigForOpt);
                setButtonLoads(false);
                if (!result) return;
                setOpenOptItem(result.id);
              }}
              disabled={
                !selectedDatasetIsAvailable ||
                buttonLoads ||
                params.numberOfTurbines < minTurbines ||
                selectedWindConfigForOpt === undefined ||
                (params.method === "micrositing" &&
                  currentTurbines?.length === 0) ||
                (params.objective === "lcoe" &&
                  params.foundationTypeId === undefined) ||
                params.numberOfTurbines > maxTurbines
              }
            />
          </HighlightStep>
        </Row>
        <MenuFrame
          id={"optimize-results-frame"}
          title="Results"
          onExit={() => setIsResultsOpen(false)}
          style={{
            display: isResultsOpen ? "flex" : "none",
            padding: "1.6rem 0rem 2rem 0rem",
            position: "absolute",
            left: "calc(100% + 0.8rem)",
            top: 0,
            width: "unset",
            resize: "both",
            minWidth: "42rem",
            maxWidth: "calc(90vw - 65rem)",
            maxHeight: "calc(90vh - 15rem)",
            overflow: "visible",
          }}
        >
          <Suspense
            fallback={
              <SkeletonText
                style={{
                  width: "initial",
                  height: "2rem",
                  margin: `${spaceSmall} ${spaceLarge}`,
                }}
              />
            }
          >
            {ids && (
              <div style={{ overflowY: "auto" }}>
                <PreviousOptimizationsList
                  ids={ids}
                  selectedRegion={depthRangeValidAreas ?? region}
                  onOptionClick={onOptionClick}
                  method={params.method}
                  showLoadingItem={buttonLoads}
                />
              </div>
            )}
          </Suspense>
        </MenuFrame>

        {params.method !== "micrositing" &&
          params.objective === "lcoe" &&
          params.costInput && (
            <LCOESettingsMenu
              setOpenSubmenu={setOpenLCOESubmenu}
              isOpen={openLCOESubmenu}
              costInput={params.costInput}
              set={setGenParam("costInput")}
            />
          )}
      </OptimizeTurbinesContext.Provider>
    </>
  );
};

function removeFarthestTurbines(
  turbineFeatures: TurbineFeature[],
  layoutSettings: RegularParameters,
  regionGeometry: SubAreaFeature["geometry"],
): TurbineFeature[] {
  const polygons =
    regionGeometry.type === "Polygon"
      ? [regionGeometry.coordinates]
      : regionGeometry.coordinates;
  const centerOfMasses = polygons.map((polygon) =>
    turf.centerOfMass(turf.polygon(polygon)),
  );
  const shiftedCenters = centerOfMasses.map((centerOfMass) => {
    const [centerLon, centerLat] = centerOfMass.geometry.coordinates;
    const shiftedCenterLon =
      centerLon + meterToCoords(xDistAtLat(centerLat, layoutSettings.shiftX));
    const shiftedCenterLat = centerLat + meterToCoords(layoutSettings.shiftY);
    return [shiftedCenterLon, shiftedCenterLat];
  });

  const distances: Map<number, number> = new Map();

  turbineFeatures.forEach((turbine, index) => {
    const distance = fastMin(
      shiftedCenters.map((center) =>
        turf.distance(center, turbine.geometry.coordinates),
      ),
    );
    distances.set(index, distance);
  });

  const sortedTurbines = turbineFeatures.slice().sort((a, b) => {
    const distanceA = distances.get(turbineFeatures.indexOf(a)) || 0;
    const distanceB = distances.get(turbineFeatures.indexOf(b)) || 0;
    return distanceA - distanceB;
  });

  const slicedTurbineFeatures = sortedTurbines.slice(
    0,
    layoutSettings.numberOfTurbines,
  );

  return slicedTurbineFeatures;
}

export const GenerationSettings = ({
  park,
  region,
  selectedZoneSize,
  selectedRegion,
  live,
  innerRef,
}: {
  park: ParkFeature;
  region: ParkFeature | SubAreaFeature;
  selectedZoneSize: number | undefined;
  selectedRegion: ParkFeature | SubAreaFeature | undefined;
  live: boolean;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  const map = useAtomValue(mapAtom);
  const projectId = useAtomValue(projectIdAtom);
  const designToolType = useAtomValue(designToolTypeAtom);

  const generationState = useAtomValue(generationStateAtom);
  const setLive = useSetAtom(turbinesAndFoundationsGenerationIsLiveAtom);
  const [methodAndParameters, setMethodAndParameters] = useAtom(
    methodAndParametersForRegionAtomFamily(region.id),
  );
  const defaultTurbines = useDefaultTurbines();
  const [isResultsOpen, setIsResultsOpen] = useState(false);

  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );

  const turbinesInRegion = turbines.filter((t) =>
    pointInPolygon(t.geometry, region.geometry),
  );

  const [currentTurbineTypeId, setCurrentTurbineTypeId] = useAtom(
    currentTurbineIdAtom({
      projectId,
    }),
  );

  const mostUsedTurbineType =
    useAtomValue(selectedTurbineTypesMostFrequentAtom) ??
    (defaultTurbines[0] as SimpleTurbineType);

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const turbineType = useMemo(
    () =>
      allTurbineTypes.get(currentTurbineTypeId) ??
      (defaultTurbines[0] as SimpleTurbineType),
    [allTurbineTypes, currentTurbineTypeId, defaultTurbines],
  );

  const turbineDistanceConvert = useMemo(() => {
    return TurbineDistance.makeConvert(turbineType?.diameter ?? 0);
  }, [turbineType]);
  const turbineDistCannonical = useMemo(() => {
    return ({ value, unit }: TurbineDistance.Of<number>): number =>
      turbineDistanceConvert(value, {
        from: unit,
      });
  }, [turbineDistanceConvert]);
  const setPreviewState = useSetAtom(previewTurbinesState);

  const toCannonical = useCallback(
    (
      settings: GenerationMethodAndParametersWithUnit,
    ): GenerationMethodAndParameters => {
      switch (settings.method) {
        case "manual":
          return {
            method: "manual",
            params: {
              setNumberOfTurbines: settings.params.setNumberOfTurbines,
              numberOfTurbines: settings.params.numberOfTurbines,
              minorAxisSpacing: turbineDistCannonical(
                settings.params.minorAxisSpacing,
              ),
              majorAxisSpacing: turbineDistCannonical(
                settings.params.majorAxisSpacing,
              ),
              rotate: Angle.to(settings.params.rotate, "deg"),
              obliquity: Angle.to(settings.params.obliquity, "deg"),
              shiftX: turbineDistCannonical(settings.params.shiftX),
              shiftY: turbineDistCannonical(settings.params.shiftY),
              includeEdge: settings.params.includeEdge,
              edgeSpacing: turbineDistCannonical(settings.params.edgeSpacing),
            },
          };
        case "optimize":
          return settings;
      }
    },
    [turbineDistCannonical],
  );

  const { computeTurbines, makeTurbineFeatures, setPreviewTurbines } =
    useTurbineGeneration(park, region ?? park, turbineType);

  const [maxNumberOfTurbines, setMaxNumberOfTurbines] =
    useState<number>(MAX_PARK_TURBINES);

  const mooringParams = useAtomValue(combinedGenMooringParameters);
  const generateFromUnitSettings = useMemo(() => {
    return throttle(50, (withUnit: GenerationMethodAndParametersWithUnit) => {
      if (!live) return;
      const noUnit = toCannonical(withUnit);
      const turbineFeatures = computeTurbines(noUnit, mooringParams);

      if (turbineFeatures) {
        setMaxNumberOfTurbines(turbineFeatures.length);
      }

      if (
        noUnit.method === "manual" &&
        noUnit.params.setNumberOfTurbines &&
        turbineFeatures &&
        noUnit.params.numberOfTurbines < turbineFeatures.length &&
        !noUnit.params.includeEdge
      ) {
        const newTurbineFeatures = removeFarthestTurbines(
          turbineFeatures,
          noUnit.params,
          region.geometry,
        );

        setPreviewTurbines(newTurbineFeatures);
        return;
      } else if (
        noUnit.method === "manual" &&
        noUnit.params.setNumberOfTurbines &&
        turbineFeatures &&
        noUnit.params.numberOfTurbines < turbineFeatures.length &&
        noUnit.params.includeEdge
      ) {
        const spacing =
          0.5 *
          (noUnit.params.minorAxisSpacing + noUnit.params.majorAxisSpacing);
        const distanceToBoundary = Math.min(spacing, noUnit.params.edgeSpacing);
        const polygons =
          region.geometry.type === "Polygon"
            ? [region.geometry.coordinates]
            : region.geometry.coordinates;
        const innerTurbines = turbineFeatures.filter(
          (t) =>
            distanceToBoundary <
            fastMin(
              polygons.flatMap((coordinates) =>
                coordinates.map((ring) =>
                  turf.pointToLineDistance(
                    t.geometry.coordinates,
                    turf.lineString(ring),
                    {
                      units: "meters",
                    },
                  ),
                ),
              ),
            ),
        );
        const outerTurbines = turbineFeatures.filter(
          (t) => !innerTurbines.map((it) => it.id).includes(t.id),
        );

        if (noUnit.params.numberOfTurbines <= outerTurbines.length) {
          const newTurbineFeatures = outerTurbines.slice(
            0,
            noUnit.params.numberOfTurbines,
          );
          setPreviewTurbines(newTurbineFeatures);
          return;
        } else {
          const remainingTurbines =
            noUnit.params.numberOfTurbines - outerTurbines.length;

          const newTurbineFeatures = removeFarthestTurbines(
            innerTurbines,
            {
              ...noUnit.params,
              numberOfTurbines: remainingTurbines,
            },
            region.geometry,
          );
          setPreviewTurbines([...newTurbineFeatures, ...outerTurbines]);
          return;
        }
      }

      if (turbineFeatures) {
        setPreviewTurbines(turbineFeatures);
      }
    });
  }, [
    computeTurbines,
    live,
    mooringParams,
    region.geometry,
    setPreviewTurbines,
    toCannonical,
  ]);

  useEffect(() => {
    if (live && methodAndParameters) {
      generateFromUnitSettings(methodAndParameters);
    } else setPreviewState(undefined);
  }, [generateFromUnitSettings, methodAndParameters, live, setPreviewState]);

  /** Set a single field for the generation parameters. This is what's called
   * when a slider is dragged.
   */
  const setGenParam = useCallback(
    (field: string) =>
      <T,>(value: T) => {
        if (!methodAndParameters) {
          return;
        }
        let cfg = methodAndParameters;
        // Weird TS things here: removeing the `if`s doesn't typecheck, even
        // though all of the bodies are the same.
        let next: GenerationMethodAndParametersWithUnit;
        if (cfg.method === "manual")
          next = {
            ...cfg,
            params: {
              ...cfg.params,
              [field]: value,
            },
          };
        else if (cfg.method === "optimize") {
          const params = {
            ...cfg.params,
            [field]: value,
          };
          next = {
            ...cfg,
            params,
          };
        } else {
          throw isNever(cfg);
        }

        setMethodAndParameters(next);
        generateFromUnitSettings(next);
      },
    [generateFromUnitSettings, methodAndParameters, setMethodAndParameters],
  );

  /** Change the method of generation. Tries to transfer over generation
   * settings at a best effort.  */
  const changeGenerationMethod = useCallback(
    (newMethod: GenerationMethod) => {
      if (!methodAndParameters) {
        return;
      }
      setLive(false);
      // NOTE: mp.method is the current (to-be previous) method.
      const nextMethodAndParam = ((): GenerationMethodAndParametersWithUnit => {
        if (methodAndParameters.method === "optimize") {
          if (newMethod === "manual") {
            return {
              method: "manual",
              params:
                (inferTurbineParameters(turbines, region) as any)["value"] ??
                defaultRegularParamsWithUnits(),
            };
          } else {
            return methodAndParameters;
          }
        } else if (newMethod === "manual") {
          return {
            method: "manual",
            params: defaultRegularParamsWithUnits(),
          };
        } else if (newMethod === "optimize") {
          setPreviewState(undefined);
          return {
            method: newMethod,
            params:
              designToolType === DesignToolMode.Offshore
                ? defaultRegularOptimizeParameters()
                : defaultIrregularOptimizeParameters(),
          };
        } else {
          throw isNever(newMethod);
        }
      })();

      setMethodAndParameters(nextMethodAndParam);
    },
    [
      setLive,
      methodAndParameters,
      region,
      setMethodAndParameters,
      setPreviewState,
      turbines,
      designToolType,
    ],
  );
  const regionSize = useMemo(() => turf.area(region) / 1e6, [region]);
  const area = selectedZoneSize ?? regionSize;
  const numTurbines =
    methodAndParameters?.method === "optimize" ||
    methodAndParameters?.params.setNumberOfTurbines
      ? methodAndParameters.params.numberOfTurbines
      : maxNumberOfTurbines;
  const capacityDensity = (numTurbines * turbineType.ratedPower) / 1e3 / area;

  return (
    <>
      <>
        <Column style={{ marginTop: "1rem" }}>
          <Grid2 style={{ gridTemplateColumns: "0.8fr 0.9fr" }}>
            <InputTitle id="tour-generate-layout-method">Method</InputTitle>
            <DropdownButton
              id="generate-layout-method"
              size="small"
              itemWidth="small"
              items={[
                { name: "Manual", value: "manual" },
                { name: "Optimize", value: "optimize" },
              ]}
              onSelectItem={(val) => {
                changeGenerationMethod(val as GenerationMethod);
              }}
              selectedItemValue={methodAndParameters?.method}
              buttonText={capitalizeFirstLetter(
                methodAndParameters?.method ?? "Select a method",
              )}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputTitle>Type</InputTitle>
              <HelpLink article={ARTICLE_LAYOUT_GEN} />
            </div>
            {methodAndParameters?.method === "optimize" && (
              <>
                <DropdownButton
                  id="generate-layout-optimize-type"
                  size="small"
                  itemWidth="small"
                  items={[
                    ...(designToolType === DesignToolMode.Offshore
                      ? [{ name: "Regular", value: "regular" }]
                      : []),
                    { name: "Irregular", value: "irregular" },
                    { name: "Micrositing", value: "micrositing" },
                    { name: "Exploration", value: "exploration" },
                  ]}
                  onSelectItem={(val) => {
                    setGenParam("method")(val as OptimizeMethod);
                  }}
                  selectedItemValue={methodAndParameters?.params.method}
                  buttonText={capitalizeFirstLetter(
                    methodAndParameters?.params.method ?? "Select a method",
                  )}
                />
              </>
            )}
            {methodAndParameters?.method === "manual" && (
              <DropdownButton
                id="generate-layout-type"
                size="small"
                itemWidth="small"
                items={[
                  { name: "Regular", value: "regular" },
                  { name: "Perimeter", value: "perimeter" },
                ]}
                onSelectItem={(val) => {
                  setGenParam("includeEdge")(val === "perimeter");
                }}
                selectedItemValue={
                  methodAndParameters?.params.includeEdge
                    ? "perimeter"
                    : "regular"
                }
                buttonText={
                  methodAndParameters?.params.includeEdge
                    ? "Perimeter"
                    : "Regular"
                }
              />
            )}
          </Grid2>
        </Column>
        {!turbineType ? (
          <div>
            <SimpleAlert text={"Missing turbine type"} type={"error"} />
          </div>
        ) : (
          <>
            <SubtitleWithLine
              style={{
                paddingTop: "1rem",
              }}
              text={"Controls"}
              innerRef={innerRef}
              expandButton={
                turbinesInRegion.length > 0 &&
                methodAndParameters?.method === "manual" ? (
                  <Button
                    size="small"
                    text="Estimate"
                    buttonType="secondary"
                    tooltip={
                      "Use this button to estimate spacing based on existing solution."
                    }
                    onClick={() => {
                      setCurrentTurbineTypeId(mostUsedTurbineType.id);
                      const params = inferTurbineParameters(
                        turbinesInRegion,
                        region,
                      );
                      if (params.ok) {
                        const obj = inferResultToObj(params.value);
                        setMethodAndParameters(obj);
                      }
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />
            <div>
              {generationState === State.Failed && (
                <SimpleAlert
                  text={"Generation failed - the Vind team is notified"}
                  type={"error"}
                />
              )}
              {generationState === State.Stopped && (
                <SimpleAlert text={"Generation was stopped"} type={"error"} />
              )}
            </div>

            {methodAndParameters?.method === "manual" ? (
              <RegularGenerationSettings
                region={region}
                params={methodAndParameters.params}
                turbineType={turbineType}
                setGenParam={setGenParam}
                convertD={turbineDistanceConvert}
                maxNumberOfTurbines={maxNumberOfTurbines}
                capacityDensity={capacityDensity}
                area={area}
              />
            ) : (
              methodAndParameters && (
                <OptimizeGenerationSettings
                  park={park}
                  region={region}
                  selectedZoneSize={selectedZoneSize}
                  selectedRegion={selectedRegion}
                  params={methodAndParameters.params}
                  turbineType={turbineType}
                  setGenParam={setGenParam}
                  isResultsOpen={isResultsOpen}
                  setIsResultsOpen={setIsResultsOpen}
                  convertD={turbineDistanceConvert}
                  makeTurbineFeatures={makeTurbineFeatures}
                  capacityDensity={capacityDensity}
                />
              )
            )}
          </>
        )}
        {region &&
          methodAndParameters &&
          methodAndParameters.method === "manual" && (
            <>
              <AxisLines
                region={region}
                minorAxisSpacing={turbineDistCannonical(
                  methodAndParameters.params.minorAxisSpacing,
                )}
                majorAxisSpacing={turbineDistCannonical(
                  methodAndParameters.params.majorAxisSpacing,
                )}
                rotation={Angle.toCannonical(methodAndParameters.params.rotate)}
                obliquity={Angle.toCannonical(
                  methodAndParameters.params.obliquity,
                )}
                shiftX={turbineDistCannonical(
                  methodAndParameters.params.shiftX,
                )}
                shiftY={turbineDistCannonical(
                  methodAndParameters.params.shiftY,
                )}
              />
              {map && (
                <TurbineEllipses
                  map={map}
                  region={region}
                  parkId={park.id}
                  minorAxisSpacing={turbineDistCannonical(
                    methodAndParameters.params.minorAxisSpacing,
                  )}
                  majorAxisSpacing={turbineDistCannonical(
                    methodAndParameters.params.majorAxisSpacing,
                  )}
                  rotation={Angle.toCannonical(
                    methodAndParameters.params.rotate,
                  )}
                  edgeSpacing={turbineDistCannonical(
                    methodAndParameters.params.edgeSpacing,
                  )}
                  turbineType={turbineType}
                />
              )}
            </>
          )}
      </>
    </>
  );
};
