import { Row } from "components/General/Layout";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const PopupContainer = styled(StandardBox)`
  padding: 0.2rem 0;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  overflow: auto;
  z-index: 1;
  width: fit-content;
`;

export const UnreadDot = styled.div<{ active: boolean }>`
  height: 0.8rem;
  width: 0.8rem;
  min-height: 0.8rem;
  min-width: 0.8rem;
  opacity: ${(p) => (p.active ? 1 : 0)};
  background: ${colors.red700};
  box-shadow: 0 0 12px ${colors.red300};
  transition: opacity 0.15s ease-in-out;
  border-radius: 0.8rem;
`;

export const FloatingUnreadNotification = styled(UnreadDot)`
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
`;

export const NotificationItem = styled(Row)`
  cursor: pointer;
  gap: 0.6rem;
  alignitems: center;
  padding: 0.6rem 1.2rem 0.6rem 0.6rem;

  &:hover {
    background: ${colors.surfaceHover};
  }
`;

export const NotificationText = styled.p`
  ${typography.caption};
  margin: 0;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;

export const Title = styled.h3`
  ${typography.sub1};
  margin: 0;
`;

export const Message = styled.p`
  ${typography.caption};
  margin: 0.4rem 0 0 0;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${colors.grey500};
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${colors.grey700};
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
`;

export const IconWrapper = styled.div`
  color: ${colors.blue500};
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: ${colors.surfaceInfo};
  align-content: center;
`;
