import React, { useMemo, Fragment, useCallback } from "react";
import { CableFeature } from "../../../types/feature";
import { SubstationFeature } from "../../../types/feature";
import { CableChainFeature } from "../../../types/feature";
import { getBBOXArrayFromFeatures } from "../../../utils/geojson/validate";
import { downloadText, fastMax, range } from "../../../utils/utils";
import { getCableColorsMap } from "../../Cabling/CablingMapController/Render";
import {
  setHover,
  removeHover,
  setHoverMultiple,
  clearHover,
} from "components/Mapbox/utils";
import { Column } from "../../General/Layout";
import {
  HoverRow,
  CableColorBar,
  CableTable,
  TableHeader,
} from "./CableMatrixWidget.style";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useDashboardContext } from "../Dashboard";
import { cableMatrixMap } from "./CableMatrixMapWidget";
import { MenuItem } from "../../General/Menu";
import DownloadIcon from "@icons/24/Download.svg?react";
import { isDefined, isSubstation } from "../../../utils/predicates";
import { cableSourceId } from "components/Mapbox/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { kmStyle } from "components/Dashboard/utils";
import { atomFamily, useJotaiCallback } from "utils/jotai";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { cableTypesFamily } from "state/jotai/cableType";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { cable3DLengthsFamily, cablesInParkFamily } from "state/jotai/cable";
import { cableChainsInParkFamily } from "state/jotai/cableChain";
import { substationsInParkFamily } from "state/jotai/substation";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { cablesFromCableChain } from "utils/cablesFromCableChain";
import { loadable, unwrap } from "jotai/utils";
import { branchIdAtom } from "state/pathParams";

const cableMatrixItemsAtom = atomFamily((_subId: string) =>
  atom<
    {
      chain: CableChainFeature;
      orderedCables: CableFeature[];
      lengths: number[];
    }[]
  >([]),
);

const selectedRowAtom = atom<undefined | string>(undefined);

const CableMatrixTable = ({
  substation,
  cables,
  chains,
  analysisConfigurationId,
}: {
  substation: SubstationFeature;
  cables: CableFeature[];
  chains: CableChainFeature[];
  analysisConfigurationId: string;
}) => {
  const cableTypes = useAtomValue(cableTypesFamily({ projectId: undefined }));
  const cableColors = getCableColorsMap(cableTypes);
  const setItems = useSetAtom(cableMatrixItemsAtom(substation.id));
  const { park, branch } = useDashboardContext();

  const deepestChain = useMemo(
    () =>
      fastMax(
        chains.map((c) => c.properties.turbines.length),
        0,
      ),
    [chains],
  );

  const cableLengthsMaybe = useAtomValue(
    loadable(
      cable3DLengthsFamily({
        parkId: park.id,
        branchId: branch.id,
        analysisConfigurationId,
      }),
    ),
  );

  const rowData = useMemo(() => {
    if (!cableLengthsMaybe || cableLengthsMaybe.state !== "hasData") return;
    const cableLengths = cableLengthsMaybe.data;
    const data = chains
      .map((chain) => {
        const orderedCables = cablesFromCableChain(chain.properties, cables);
        if (!orderedCables) return;

        const lengths = orderedCables.map((c) => {
          return (cableLengths.get(c.id)?.contingent ?? 0) * 1000;
        });
        return {
          chain,
          orderedCables,
          lengths,
        };
      })
      .filter(isDefined);

    setTimeout(() => {
      // Can't update the state when mounting this and running for the first time.
      // Add setTimeout to delay the call.
      setItems(data);
    }, 0);

    return data;
  }, [cableLengthsMaybe, cables, chains, setItems]);

  const map = useAtomValue(cableMatrixMap);

  const selectedRow = useAtomValue(selectedRowAtom);
  const select = useJotaiCallback(
    (get, set, chainId: string | undefined) => {
      const map = get(cableMatrixMap);
      if (!chainId) {
        set(selectedRowAtom, undefined);
        const bbox = getBBOXArrayFromFeatures([park]);
        map?.fitBounds(bbox);
        return;
      }
      set(selectedRowAtom, chainId);
      const chain = chains.find((ch) => ch.id === chainId);
      if (!chain) throw new Error("Chain not found");
      const bbox = getBBOXArrayFromFeatures([chain, substation]);
      map?.fitBounds(bbox);
    },
    [chains, park, substation],
  );

  const rowNodes = useMemo(() => {
    if (!rowData) return;
    return rowData.map((r, ri) => {
      const isSelected = r.chain.id === selectedRow;
      const cableIds = r.orderedCables.map((c) => c.id);
      return (
        <React.Fragment key={ri}>
          <HoverRow
            onClick={() => {
              select(isSelected ? undefined : r.chain.id);
            }}
            selected={isSelected}
            onMouseEnter={() => {
              if (map) {
                setHoverMultiple(
                  map,
                  cableIds.map((id) => ({
                    source: cableSourceId,
                    id,
                  })),
                );
              }
            }}
            onMouseLeave={() => {
              if (map) {
                clearHover(map);
              }
            }}
          >
            {ri + 1}
          </HoverRow>
          {range(0, deepestChain).map((i) => {
            const l = r.lengths.at(i);
            if (l === undefined) return <div key={i} />;
            const km = kmStyle.format(l / 1000);
            const cable = r.orderedCables[i];
            const color =
              cableColors.get(
                r.orderedCables[i].properties.cableTypeId ?? "",
              ) ?? "#111111";
            return (
              <HoverRow
                key={i}
                onMouseEnter={() => {
                  if (cable && map) setHover(map, cableSourceId, cable.id);
                }}
                onMouseLeave={() => {
                  if (cable && map) removeHover(map, cableSourceId, cable.id);
                }}
              >
                <CableColorBar color={color} />
                {`${km}`}
              </HoverRow>
            );
          })}
          <div>
            {kmStyle.format(r.lengths.reduce((a, e) => a + e, 0) / 1000)} km
          </div>
        </React.Fragment>
      );
    });
  }, [cableColors, deepestChain, map, rowData, select, selectedRow]);

  if (!rowNodes) {
    return <LoadingState />;
  }

  return (
    <CableTable columns={2 + deepestChain}>
      <div>Chain #</div>
      {range(0, deepestChain).map((i) => (
        <div key={i}>{i + 1}</div>
      ))}
      <div>Total</div>
      {rowNodes}
    </CableTable>
  );
};

const useData = () => {
  const { park } = useDashboardContext();
  const parkId = park.id;
  return useJotaiCallback(
    (get) => {
      const branchId = get(branchIdAtom) ?? "";
      const chains =
        get(unwrap(cableChainsInParkFamily({ parkId, branchId }))) ?? [];
      const cables =
        get(unwrap(cablesInParkFamily({ parkId, branchId }))) ?? [];
      const substations =
        get(unwrap(substationsInParkFamily({ parkId, branchId }))) ?? [];

      const connectedSubstations = substations.filter((s) =>
        chains.some((c) => c.properties.substation === s.id),
      );

      return connectedSubstations.map((sub) => {
        const chains_ = chains.filter(
          (c) => c.properties.substation === sub.id,
        );
        const cables_ = cables.filter((cbl) =>
          chains_.find(
            (ch) =>
              ch.properties.turbines.includes(cbl.properties.fromId) ||
              ch.properties.turbines.includes(cbl.properties.toId),
          ),
        );
        return { substation: sub, chains: chains_, cables: cables_ };
      });
    },
    [parkId],
  );
};

const CableMatrix = () => {
  const { park, branch, configuration } = useDashboardContext();
  const dataPerSubstation = useData()();

  const invalidCableTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId: branch.id }),
  ).cables;

  if (invalidCableTypes)
    return (
      <CenterContainer>
        <SimpleAlert
          text={"Some cables in the park have invalid cable types."}
        />
      </CenterContainer>
    );

  if (!dataPerSubstation)
    return (
      <CenterContainer>
        <SimpleAlert text={"No substation with cables in park."} />
      </CenterContainer>
    );
  if (dataPerSubstation instanceof Error) throw dataPerSubstation;

  return (
    <Column>
      {dataPerSubstation.map((d, di) => (
        <Fragment key={d.substation.id}>
          {1 < dataPerSubstation.length && (
            <TableHeader>
              {`Substation ${di + 1}: "${d.substation.properties.name}"`}
            </TableHeader>
          )}
          <CableMatrixTable
            substation={d.substation}
            cables={d.cables.filter((c) => !c.properties.redundancy)}
            chains={d.chains}
            analysisConfigurationId={configuration.id}
          />
        </Fragment>
      ))}
    </Column>
  );
};

const delimiter = ",";

const CableMatrixMenu = () => {
  const getData = useData();
  const { park, branch, configuration } = useDashboardContext();
  const allTurbines = useAtomValue(
    turbinesInParkFamily({ parkId: park.id, branchId: undefined }),
  );
  const cableTypes = useAtomValue(cableTypesFamily({ projectId: undefined }));

  const cableLengths = useAtomValue(
    cable3DLengthsFamily({
      parkId: park.id,
      branchId: branch.id,
      analysisConfigurationId: configuration.id,
    }),
  );

  const downloadCSV = useJotaiCallback(
    async (get) => {
      let si = 0;
      const data = await getData();
      const substations = data.map((d) => d.substation);

      for (const sub of substations) {
        si += 1;
        if (!sub) {
          throw new Error("Substation not found");
        }

        if (!isSubstation(sub)) {
          throw new Error("Expected substation");
        }

        const ourItems = get(cableMatrixItemsAtom(sub.id));

        const maxChainLength = fastMax(
          ourItems.map((i) => i.orderedCables.length),
        );

        let csv = "chain";
        for (const i of range(1, maxChainLength + 1)) {
          csv += `${delimiter}${i}`;
        }

        csv += `${delimiter}total\n`;

        ourItems.forEach((item, i) => {
          const lengths = item.lengths;
          csv += `${i + 1}`;
          for (const li of range(0, maxChainLength)) {
            const len = lengths[li];
            if (len === undefined) {
              csv += `${delimiter}`;
            } else {
              csv += `${delimiter}${(len / 1000).toFixed(2)}`;
            }
          }
          const total = lengths.reduce((a, e) => a + e, 0);
          csv += `${delimiter}${(total / 1000).toFixed(2)}`;
          csv += "\n";
        });

        const subName = `substation-${si}-${sub.properties.name}`;
        const fileName = `${park.properties.name}_cable_matrix_${subName}.csv`;
        downloadText(csv, fileName);
      }
    },
    [getData, park.properties.name],
  );

  // Download info about connected turbines for each chain
  // The format is:
  //  Chain Name:
  //    Substation name - Turbine 1 name
  //    Turbine 1 name - Turbine 2 name
  //    ...
  //    Turbine N-1 name - Turbine N name
  const downloadChainInfo = useCallback(async () => {
    const data = await getData();
    const substations = data.map((d) => d.substation);
    if (!cableLengths) return;

    const perSub = data.map((d) => {
      const chainDescription = d.chains.map((c) => {
        const cables = d.cables.filter((c) => !c.properties.redundancy);
        const orderedCables = cablesFromCableChain(c.properties, cables);
        if (!orderedCables) return;

        const cableData = orderedCables.map((cbl, i) => {
          const fromFeature =
            i === 0
              ? substations.find((s) => s.id === cbl.properties.fromId)
              : allTurbines.find((t) => t.id === cbl.properties.fromId);
          const fromFeatureName = fromFeature?.properties.name;

          const toTurbineName = allTurbines.find(
            (t) => t.id === cbl.properties.toId,
          )?.properties.name;

          const cableTypeId = cbl.properties.cableTypeId;
          const cableTypeName = cableTypes.get(cableTypeId ?? "")?.name;

          const length = cableLengths.get(cbl.id)?.contingent.toFixed(2) ?? 0;
          return [fromFeatureName, toTurbineName, cableTypeName, length];
        });

        return `${cableData.join("\n")}`;
      });

      return {
        substation: d.substation,
        chainDescription: chainDescription,
      };
    });

    // Have each substation start a new column in the csv with each chain in the substation listed below each other
    let csv = "";
    const header = `From, To, Cable type, Length [km]`;
    perSub.forEach((subData) => {
      csv += `Substation: ${subData.substation.properties.name}\n`;
      subData.chainDescription.forEach((chain, j) => {
        csv += `Chain ${j + 1}:\n${header}\n${chain}\n\n`;
      });
    });

    const fileName = `${park.properties.name}_chain_info.csv`;
    downloadText(csv, fileName);
  }, [allTurbines, getData, park.properties.name, cableTypes, cableLengths]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => downloadCSV()}
      />
      <MenuItem
        name={"Download chain info as .csv"}
        icon={<DownloadIcon />}
        onClick={() => downloadChainInfo()}
      />
    </>
  );
};

export const CableMatrixWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Cable matrix"
      id="Cable matrix"
      menuItems={<CableMatrixMenu />}
      helptext={
        "Tables with cable chains for each substation, with total length in km. Each row in the table lists the length of the cables in a chain. The first cable connects to the substation.\n\n" +
        "Hover over a table cell to highlight the cable in the map.\n" +
        "Click on a chain to zoom in on the chain in the map."
      }
      resetKeys={errorBoundaryResetKeys}
    >
      <CableMatrix />
    </SafeCard>
  );
};
