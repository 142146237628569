import { Gradient } from "lib/colors";
import { forwardRef } from "react";
import styled from "styled-components";
import { borderRadius } from "styles/space";

const Block = styled.div<
  {
    span: number;
    vertical: boolean;
  } & ReturnType<Gradient["ranges"]>[number]
>`
  height: 2rem;
  background: linear-gradient(
    ${(p) => (p.vertical ? "180deg" : "90deg")},
    ${(p) => p.fromColor.css()},
    ${(p) => p.toColor.css()}
  );
  flex: ${(p) => (p.to - p.from) / p.span};
`;

const ColorRange_ = styled.div<{ height: number }>`
  display: flex;

  &[data-vertical="true"] {
    flex-direction: column;
    width: 2rem;
    height: ${(p) => p.height}rem;

    > :first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    > :last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &:not([data-vertical="true"]) {
    height: 2rem;
    width: ${(p) => p.height}rem;
    > :first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    > :last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  border: 1px solid #00000020;
  border-radius: ${borderRadius.small};
`;

export const GradientComp = forwardRef<
  HTMLDivElement,
  {
    gradient: Gradient;
    vertical?: boolean;
    dynamic?: boolean;
  }
>(({ gradient, vertical, dynamic }, ref) => {
  if (gradient.empty()) return null;
  const span = gradient.span();

  return (
    <ColorRange_
      ref={ref}
      data-vertical={!!vertical}
      height={dynamic ? gradient.numStops() * (19 / 7) : 19}
    >
      {gradient.ranges().map((to, i) => (
        <Block key={i} {...to} span={span} vertical={!!vertical} />
      ))}
    </ColorRange_>
  );
});
