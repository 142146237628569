import { useAtomValue, useSetAtom } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { getBranchSelectorFamily } from "../../../state/timeline";
import {
  branchMetasBySortOrderFamily,
  branchMetasFamily,
} from "state/jotai/branch";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { borderRadius, spaceMedium } from "styles/space";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { DateText, EntryHeader } from "./styles";
import { dateToYearDateTime } from "utils/utils";
import { splitFeatureHistoryAtomFamily } from "./state";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { projectVersionAtom } from "state/project";
import { useSetVersion } from "hooks/useSetVersion";
import { RightModalNames, rightModalOpenStateAtom } from "../state";
import { StandardBox } from "styles/boxes/Boxes";
import { UserInfoProject } from "components/UserInfo/UserInfo";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Button from "components/General/Button";
import Spinner from "@icons/spinner/Spinner";
import FeatureSave from "@icons/14/FeatureSave.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import FeatureAutosave from "@icons/14/FeatureAutosave.svg?react";
import { scream } from "utils/sentry";
import {
  deleteSaveFeatureHistory,
  editSaveFeatureHistory,
  getSavedFeatureHistory,
  postSaveFeatureHistory,
} from "./service";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import Trashcan from "@icons/24/Bin.svg?react";
import { AutosaveRequestEntry, SaveRequestEntry } from "./type";
import { EditableText } from "components/General/EditableText";
import { createBranch } from "services/projectDataAPIService";
import useNavigateToBranch from "../BranchTabBar/useNavigateToBranch";
import { Icon } from "components/General/Icons";
import { RESET } from "jotai/utils";

const FeatureHistoryContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 6;
  right: ${spaceMedium};
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  height: calc(
    100vh - calc(
        var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
          var(--side-bars-width)
      ) - 2.4rem
  );
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    height: calc(
      100vh - calc(
          var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
            var(--side-bars-width)
        ) - 6.8rem
    );
  }
`;

const ListWrapper = styled.div`
  overflow: auto;
  position: relative;
  display: flex;
  padding: 1.6rem 2rem;
  flex-direction: column;
  height: 100%;
  & > *:nth-child(odd) {
    background-color: ${colors.surfaceSecondary};
  }
`;

const Entry = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  cursor: pointer;
  border-radius: ${borderRadius.small};
  padding: 0.8rem 0.4rem 0.8rem 1.2rem;
  box-sizing: border-box;
  background-color: ${(p) =>
    p.selected ? `${colors.blue100} !important` : "inherit"};
  &:hover {
    background-color: ${(p) => (p.selected ? colors.blue100 : colors.grey100)};
  }
`;

const EntryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;

const DeleteNotification = styled.div`
  border-top: 1px solid ${colors.grey100};
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  padding: 0rem 0;
`;

const NoSavedFeaturesWrapper = styled(StandardBox)`
  background-color: ${colors.surfaceSelectedLight};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

const NoSavedFeaturesTitle = styled.div`
  ${typography.sub2};
  color: ${colors.textPrimary};
`;

const NoSavedFeaturesText = styled.div`
  ${typography.caption};
`;

const SaveWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1.6rem;
`;

const FeatureHistorySaveEvent = ({
  projectId,
  branchId,
  entry,
}: {
  entry: SaveRequestEntry;
  projectId: string;
  branchId: string;
}) => {
  const navigateToBranch = useNavigateToBranch();
  const deleteCallback = useCallback(
    async (saveRequestEntry: SaveRequestEntry) => {
      await deleteSaveFeatureHistory({
        projectId,
        branchId,
        timestamp: saveRequestEntry.timestamp,
      });
    },
    [projectId, branchId],
  );
  const projectVersion = useAtomValue(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
  const setVersion = useSetVersion();

  const [localName, setLocalName] = useState(entry.name);
  const [duplicate, setDuplicate] = useState(false);
  const duplicateToBranch = useJotaiCallback(
    async (_get, set) => {
      try {
        setDuplicate(true);
        const features = await getSavedFeatureHistory({
          nodeId: projectId,
          branchId,
          timestamp: entry.timestamp,
        });
        const branch = await createBranch(projectId, entry.name, features);
        set(branchMetasFamily({ projectId }), RESET);
        navigateToBranch(branch.meta.id, true);
      } catch (error) {
        if (error instanceof Error) {
          scream(error, {
            projectId,
            message: "Failed to duplicate branch",
          });
        } else {
          scream(new Error("Failed to duplicate branch"), {
            error,
          });
        }
      } finally {
        setDuplicate(false);
      }
    },
    [projectId, branchId, entry, setDuplicate, navigateToBranch],
  );

  return (
    <Entry
      key={entry.timestamp}
      selected={projectVersion === entry.timestamp}
      onClick={() => setVersion(entry.timestamp)}
    >
      <EntryRow>
        <Icon size="1.4rem">
          <FeatureSave />
        </Icon>
        <EditableText
          textContainerStyle={{
            padding: "0",
          }}
          renderText={(text) => <EntryHeader>{text}</EntryHeader>}
          value={localName}
          onEnter={() => {
            editSaveFeatureHistory({
              projectId,
              branchId,
              name: localName,
              timestamp: entry.timestamp,
            });
          }}
          onChange={(a) => {
            setLocalName(a.target.value);
          }}
        />
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          {duplicate ? (
            <Spinner size={"1.2rem"} />
          ) : (
            <DotMenu>
              <MenuItem
                name={"Duplicate as new branch"}
                icon={<DuplicateIcon />}
                onClick={duplicateToBranch}
              />
              <MenuItem
                name={"Delete"}
                icon={<Trashcan />}
                onClick={() => {
                  deleteCallback(entry);
                }}
              />
            </DotMenu>
          )}
        </div>
      </EntryRow>
      <EntryRow
        style={{
          gap: "1.2rem",
        }}
      >
        <DateText>{dateToYearDateTime(new Date(entry.timestamp))}</DateText>
        {entry.authors.length !== 0 && (
          <UserInfoProject
            projectId={projectId}
            size={1.6}
            userId={entry.authors[0]}
            nameStyle={{
              fontWeight: "400",
            }}
          />
        )}
      </EntryRow>
    </Entry>
  );
};

const FeatureHistoryAutoSaveEvent = ({
  entry,
  projectId,
  branchId,
}: {
  entry: AutosaveRequestEntry;
  projectId: string;
  branchId: string;
}) => {
  const navigateToBranch = useNavigateToBranch();
  const projectVersion = useAtomValue(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
  const setVersion = useSetVersion();
  const [duplicate, setDuplicate] = useState(false);
  const duplicateToBranch = useJotaiCallback(
    async (_get, set) => {
      try {
        setDuplicate(true);
        const features = await getSavedFeatureHistory({
          nodeId: projectId,
          branchId,
          timestamp: entry.timestamp,
        });
        const branch = await createBranch(
          projectId,
          `Autosave ${dateToYearDateTime(new Date(entry.timestamp))}`,
          features,
        );
        set(
          branchMetasBySortOrderFamily({
            nodeId: projectId,
          }),
          (curr) => [...curr, branch.meta],
        );
        navigateToBranch(branch.meta.id, true);
      } catch (error) {
        if (error instanceof Error) {
          scream(error, {
            projectId,
            message: "Failed to duplicate branch",
          });
        } else {
          scream(new Error("Failed to duplicate branch"), {
            error,
          });
        }
      } finally {
        setDuplicate(false);
      }
    },
    [projectId, branchId, entry, setDuplicate, navigateToBranch],
  );
  return (
    <Entry
      key={entry.timestamp}
      selected={projectVersion === entry.timestamp}
      onClick={() => setVersion(entry.timestamp)}
    >
      <EntryRow>
        <Icon size="1.4rem">
          <FeatureAutosave />
        </Icon>
        <EntryHeader>Autosave</EntryHeader>
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          {duplicate ? (
            <Spinner size={"1.2rem"} />
          ) : (
            <DotMenu>
              <MenuItem
                name={"Duplicate as new branch"}
                icon={<DuplicateIcon />}
                onClick={duplicateToBranch}
              />
            </DotMenu>
          )}
        </div>
      </EntryRow>
      <EntryRow
        style={{
          gap: "1.2rem",
        }}
      >
        <DateText>{dateToYearDateTime(new Date(entry.timestamp))}</DateText>
        {entry.authors.length !== 0 && (
          <UserInfoProject
            size={1.6}
            userId={entry.authors[0]}
            projectId={projectId}
            nameStyle={{
              fontWeight: "400",
            }}
          />
        )}
      </EntryRow>
    </Entry>
  );
};

const ElementsHistoryEntries = ({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}) => {
  const entries = useAtomValue(
    splitFeatureHistoryAtomFamily({
      projectId,
      branchId,
    }),
  );

  if (entries.length === 0) {
    return (
      <div
        style={{
          padding: "0 1.6rem",
        }}
      >
        <NoSavedFeaturesWrapper>
          <NoSavedFeaturesTitle>No saved features</NoSavedFeaturesTitle>
          <NoSavedFeaturesText>
            Features are stored automatically after 2 minutes of inactivity
          </NoSavedFeaturesText>
        </NoSavedFeaturesWrapper>
      </div>
    );
  }

  return (
    <>
      <DeleteNotification
        style={{
          padding: "1.6rem 2rem 0",
        }}
      >
        <SimpleAlert
          type="info"
          text="An autosave is created after 2 minutes of inactivity. Autosaves will be deleted after 30 days."
          style={{
            alignItems: "flex-start",
          }}
        />
      </DeleteNotification>
      <ListWrapper>
        {[...entries].map((entry) =>
          entry.type === "save" ? (
            <FeatureHistorySaveEvent
              key={entry.request.timestamp + entry.type}
              projectId={projectId}
              branchId={branchId}
              entry={entry.request}
            />
          ) : (
            <FeatureHistoryAutoSaveEvent
              key={entry.request.timestamp + entry.type}
              projectId={projectId}
              branchId={branchId}
              entry={entry.request}
            />
          ),
        )}
      </ListWrapper>
    </>
  );
};

const FeatureHistoryInner = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const branch = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const [loadingSave, setLoadingSave] = useState(false);

  const saveFeatures = useCallback(async () => {
    if (loadingSave) return;
    try {
      setLoadingSave(true);
      await postSaveFeatureHistory({
        projectId,
        branchId,
        name: `Untitled save ${dateToYearDateTime(new Date())}`,
      });
    } catch (error) {
      if (error instanceof Error) {
        scream(error, {
          message: "Failed to save features",
        });
      } else {
        scream(new Error("Failed to save features"), {
          error,
        });
      }
    } finally {
      setLoadingSave(false);
    }
  }, [setLoadingSave, loadingSave, projectId, branchId]);
  const branchName = branch?.title ?? "Banch";

  const setRightModalOpenState = useSetAtom(rightModalOpenStateAtom);

  return (
    <FeatureHistoryContainer>
      <MenuFrame
        ref={frameRef}
        title="Elements history"
        subtitle={branchName}
        onExit={() => setRightModalOpenState(undefined)}
        style={{
          padding: "1.6rem 0 0 0",
        }}
      >
        <HistoryWrapper>
          <React.Suspense fallback={<SkeletonBlock />}>
            <ElementsHistoryEntries projectId={projectId} branchId={branchId} />
          </React.Suspense>
          <SaveWrapper>
            <Button
              disabled={loadingSave}
              icon={loadingSave ? <Spinner size={"1.2rem"} /> : null}
              text={"Save"}
              onClick={saveFeatures}
            />
          </SaveWrapper>
        </HistoryWrapper>
      </MenuFrame>
    </FeatureHistoryContainer>
  );
};

export default function FeatureHistory() {
  const rightModalOpenState = useAtomValue(rightModalOpenStateAtom);
  if (rightModalOpenState !== RightModalNames.FeatureHistory) {
    return null;
  }

  return <FeatureHistoryInner />;
}
