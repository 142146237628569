import { CenterContainer, SafeCard } from "./Base";
import {
  TurbineFeatureWithFoundation,
  isTurbineFeatureWithFoundation,
  turbineTypeAndFloatingFoundationCombinations,
} from "../../../state/foundations";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import {
  isDefined,
  isFixed,
  isFloater,
  isNumber,
} from "../../../utils/predicates";
import { pointInPolygon } from "utils/geometry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ResultValue } from "components/General/GeneralSideModals.style";
import { useAtomValue } from "jotai";
import {
  foundationFloaterTypesAtom,
  foundationTypesAtom,
  getFixedFoundationTotalsFamily,
  getFloatingFoundationTotalsFamily,
} from "state/jotai/foundation";
import { getScalesForTurbineTypeIdsAndFoundationIds } from "analysis/inputs";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";

const FloatingFoundationTotals = ({
  turbinesWithFloatingFoundation,
}: {
  turbinesWithFloatingFoundation: TurbineFeatureWithFoundation[];
}) => {
  const allFloaters = useAtomValue(foundationFloaterTypesAtom);

  const turbineTypeIdAndFloatingFoundationIdCombinations =
    turbineTypeAndFloatingFoundationCombinations(
      turbinesWithFloatingFoundation,
      allFloaters,
    );

  const scales = useAtomValue(
    getScalesForTurbineTypeIdsAndFoundationIds(
      turbineTypeIdAndFloatingFoundationIdCombinations,
    ),
  );

  const { totalPrimarySteelMass, totalPrimaryConcreteMass } = useAtomValue(
    getFloatingFoundationTotalsFamily({
      tempLayoutFoundations: turbinesWithFloatingFoundation,
      scales,
      turbineTypeIdAndFloatingFoundationIdCombinations,
    }),
  );

  const foundationIds = [
    ...new Set(
      turbinesWithFloatingFoundation.map(
        (turbine) => turbine.properties.foundationId,
      ),
    ).values(),
  ];
  const foundationMaterials = foundationIds
    .map((id) => allFloaters.find((t) => t.id === id)?.material)
    .filter(isDefined)
    .filter((m, i, ar) => ar.indexOf(m) === i);

  const primarySteelWeight =
    foundationMaterials?.includes("Steel") && isNumber(totalPrimarySteelMass)
      ? valueRounding(totalPrimarySteelMass / 1000, 100)
      : undefined;

  const concreteWeight =
    foundationMaterials?.includes("Concrete") &&
    isNumber(totalPrimaryConcreteMass)
      ? valueRounding(totalPrimaryConcreteMass / 1000, 100)
      : undefined;

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {isDefined(concreteWeight) && (
        <Row>
          <p>Total concrete weight</p>
          <ResultValue>
            <strong>{concreteWeight.toLocaleString()}</strong> t
          </ResultValue>
        </Row>
      )}
      {isDefined(primarySteelWeight) && (
        <Row>
          <p>Total primary steel weight</p>
          <ResultValue>
            <strong>{primarySteelWeight.toLocaleString()}</strong> t
          </ResultValue>
        </Row>
      )}
    </FlexGrid2>
  );
};

const FixedFoundationTotals = ({
  rasterId,
  turbinesWithFixedFoundation,
}: {
  rasterId: string;
  turbinesWithFixedFoundation: TurbineFeatureWithFoundation[];
}) => {
  const { totalPrimarySteelMass } = useAtomValue(
    getFixedFoundationTotalsFamily({
      tempLayoutFoundations: turbinesWithFixedFoundation,
      rasterId,
    }),
  );

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      {totalPrimarySteelMass && (
        <Row>
          <p>Primary steel weight</p>
          <ResultValue>
            <strong>
              {valueRounding(
                totalPrimarySteelMass / 1000,
                100,
              ).toLocaleString()}
            </strong>
            t
          </ResultValue>
        </Row>
      )}
    </FlexGrid2>
  );
};

const FoundationTotalsDetails = () => {
  const { park, branch, parkBathymetryId } = useDashboardContext();
  const branchId = branch.id;
  const parkId = park.id;

  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const validTurbines = turbines.filter((t) =>
    pointInPolygon(t.geometry, park.geometry),
  );
  const turbinesWithFoundation =
    validTurbines?.filter(isTurbineFeatureWithFoundation) ?? [];

  const foundations = useAtomValue(foundationTypesAtom);

  const turbinesWithFloatingFoundation = turbinesWithFoundation.filter((t) =>
    isFloater(foundations.get(t.properties.foundationId)),
  );
  const turbinesWithFixedFoundation = turbinesWithFoundation.filter((t) =>
    isFixed(foundations.get(t.properties.foundationId)),
  );

  const includesFloaters = turbinesWithFloatingFoundation.length > 0;
  const includesFixed = turbinesWithFixedFoundation.length > 0;

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId, branchId }),
  );

  if (!turbinesWithFoundation || turbinesWithFoundation.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );

  if (invalidTypes.turbines) {
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some turbines in the park have invalid turbine types."}
        />
      </CenterContainer>
    );
  }

  if (invalidTypes.foundations) {
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some turbines in the park have invalid foundation types."}
        />
      </CenterContainer>
    );
  }

  return (
    <>
      {includesFixed && (
        <FixedFoundationTotals
          rasterId={parkBathymetryId}
          turbinesWithFixedFoundation={turbinesWithFixedFoundation}
        />
      )}
      {includesFloaters && (
        <FloatingFoundationTotals
          turbinesWithFloatingFoundation={turbinesWithFloatingFoundation}
        />
      )}
    </>
  );
};

export const FoundationTotalsWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Foundation totals"
      id="Foundation totals"
      resetKeys={errorBoundaryResetKeys}
    >
      <FoundationTotalsDetails />
    </SafeCard>
  );
};
