import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import {
  Layer,
  ExternalDataSourceLinkLayerWithSourceWMS,
} from "../../../types/layers";
import { dedup } from "../../../utils/utils";
import { visibleCollectionLayersSelectorFamily } from "../Collections/state";
import {
  visibleCustomLayersSelector,
  visibleSelectedLayersSelectorFamily,
} from "./state";
import { isWMSLayer } from "../../../state/wms";

/**
 * A layer is visible if it is selected and either
 * 1. Explicitly made visible
 * 2. Standard, and not explicitly made invisible
 * 3. Contained in a collection that is visible (same rules).
 */
export const getVisibleLayers = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<Layer[]>>(async (get) => {
      const enabledLayers = await get(
        visibleSelectedLayersSelectorFamily({
          projectId,
        }),
      );
      const enabledCollectionLayers = await get(
        visibleCollectionLayersSelectorFamily({
          projectId,
        }),
      );

      const enabledCustomLayers = await get(
        visibleCustomLayersSelector({
          projectId,
        }),
      );

      const visibleLayers = dedup(
        [...enabledLayers, ...enabledCollectionLayers, ...enabledCustomLayers],
        (l) => l.id,
      );

      return visibleLayers;
    }),
);

export const visibleWmsLayersSelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<ExternalDataSourceLinkLayerWithSourceWMS[]>>(async (get) => {
      const allLayers = await get(
        getVisibleLayers({
          projectId,
        }),
      );
      return allLayers.filter(isWMSLayer);
    }),
);
