import { z } from "zod";
import { isNever } from "../../utils/utils";

/**
 * Conversion function used to convert a number **from** the given unit to the
 * cannonical unit, or from the canninocal unit **to** the given unit.
 */
export type Convert<U> = (
  n: number,
  option:
    | { to: U; minValue?: number; maxValue?: number }
    | { from: U; minValue?: number; maxValue?: number },
) => number;

// The cannonical unit is 'm'.
export namespace Distance {
  export const units = ["m", "km", "NM"] as const;
  export const cannonical: Unit = "m";
  export const _Unit = z.enum(units);
  export type Unit = (typeof units)[number];
  export type Of<T> = { value: T; unit: Unit };

  export const format = (n: number, u?: Unit): string => {
    if (u === "m") return n.toFixed(0);
    if (u === "km") return n.toFixed(3);
    if (u === "NM") return n.toFixed(3);
    return n.toString();
  };

  export const convert: Convert<Unit> = (n, opt) => {
    if ("from" in opt) {
      if (opt.from === "km") return n * 1000;
      if (opt.from === "NM") return n * 1852;
      if (opt.from === "m") return n;
      throw isNever(opt.from);
    }
    if ("to" in opt) {
      if (opt.to === "km") return n / 1000;
      if (opt.to === "NM") return n / 1852;
      if (opt.to === "m") return n;
      throw isNever(opt.to);
    }
    throw isNever(opt);
  };

  export const toCannonical = ({ value, unit }: Of<number>): number =>
    convert(value, { from: unit });
}

export namespace TurbineDistance {
  export const units = ["m", "D"] as const;
  export const cannonical: Unit = "m";
  export const _Unit = z.enum(units);
  export type Unit = (typeof units)[number];
  export type Of<T> = { value: T; unit: Unit };
  export const zod = z.object({
    value: z.number(),
    unit: _Unit,
  });

  export const format = (n: number, u?: Unit): string => {
    switch (u) {
      case "m":
        return n.toFixed(0);
      case "D":
        return n.toFixed(1);
      case undefined:
        return n.toString();
      default:
        throw isNever(u);
    }
  };

  export const makeConvert: (diameter: number) => Convert<Unit> =
    (diameter: number) => (n, opt) => {
      if ("from" in opt) {
        if (opt.from === "D")
          return Math.min(
            Math.max(n * diameter, opt.minValue ?? Number.MIN_SAFE_INTEGER),
            opt.maxValue ?? Number.MAX_SAFE_INTEGER,
          );
        if (opt.from === "m")
          return Math.min(
            Math.max(n, opt.minValue ?? Number.MIN_SAFE_INTEGER),
            opt.maxValue ?? Number.MAX_SAFE_INTEGER,
          );
        throw isNever(opt.from);
      }
      if ("to" in opt) {
        if (opt.to === "D")
          return Math.min(
            Math.max(n / diameter, opt.minValue ?? Number.MIN_SAFE_INTEGER),
            opt.maxValue ?? Number.MAX_SAFE_INTEGER,
          );
        if (opt.to === "m")
          return Math.min(
            Math.max(n, opt.minValue ?? Number.MIN_SAFE_INTEGER),
            opt.maxValue ?? Number.MAX_SAFE_INTEGER,
          );
        throw isNever(opt.to);
      }
      throw isNever(opt);
    };
}

/** Cannonical unit is degree. */
export namespace Angle {
  export const units = ["deg", "rad", "turn"] as const;
  export const cannonical: Unit = "deg";
  export const _Unit = z.enum(units);
  export type Unit = (typeof units)[number];
  export type Of<T> = { value: T; unit: Unit };
  export const zod = z.object({
    value: z.number(),
    unit: _Unit,
  });

  export const format = (n: number, u?: Unit): string => {
    if (u === "deg") return n.toFixed(0);
    if (u === "rad") return n.toFixed(3);
    if (u === "turn") return n.toFixed(2);
    return n.toString();
  };

  /** Default range is [-180, 180]. */
  export const range = (u: Unit): [number, number] => [
    convert(-180, { to: u }),
    convert(180, { to: u }),
  ];

  export const convert: Convert<Unit> = (n: number, opt) => {
    if ("from" in opt) {
      if (opt.from === "deg") return n;
      if (opt.from === "rad")
        return parseFloat(((n * 180) / Math.PI).toFixed(4));
      if (opt.from === "turn") return n * 360;
      throw isNever(opt.from);
    }
    if ("to" in opt) {
      if (opt.to === "deg") return n;
      if (opt.to === "rad") return parseFloat(((n / 180) * Math.PI).toFixed(4));
      if (opt.to === "turn") return n / 360;
      throw isNever(opt.to);
    }
    throw isNever(opt);
  };

  /** Try to parse the given object as this unit.  Return the cannonical unit if parsing fails. */
  export const orDefault = (obj: unknown): Unit => {
    const parse = _Unit.safeParse(obj);
    if (parse.success) return parse.data;
    return cannonical;
  };

  export const toCannonical = ({ value, unit }: Of<number>): number =>
    convert(value, { from: unit });

  export const to = ({ value, unit }: Of<number>, u: Unit): number =>
    convert(convert(value, { from: unit }), { to: u });
}
