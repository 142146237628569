import { atom } from "jotai";
import { atomFamily } from "utils/jotai";

type OrganisationRightSideModalContent = {
  type:
    | "group"
    | "user"
    | "project"
    | "resource"
    | "no-item-selected"
    | "no-info-to-show"
    | "all-data-layers";
  id?: string;
  meta?: Record<string, unknown>;
};

export const organisationRightSideModal = atomFamily((_: string) =>
  atom<undefined | OrganisationRightSideModalContent>(undefined),
);
