import { organisationIdAtom } from "state/pathParams";
import { useMemo } from "react";
import {
  ContentTableColumn,
  GaplessRow,
  GaplessRowPadding,
  Text,
  TableHeader,
  TabSubtitle,
} from "../../../style";
import { Column } from "components/General/Layout";
import { OrgResource, OrganisationResources } from "../../../types";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import useGroupOrganisationResourcesCrud, {
  groupOrgResourceManagersAtomFamily,
} from "../../hooks/useGroupOrganisationResourcesCrud";
import { resourcesToNameMap } from "../../UserContent/tabs/OrganisationUserResourceTable";
import {
  groupMembersState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import { StyledLink } from "components/Organisation/Library/style";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { useAtomValue } from "jotai";
import { usersInOrganisationState } from "components/Organisation/state";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

function OrgResourceRow({
  groupId,
  resources,
  resourceName,
}: {
  groupId: string;
  resources: OrgResource[];
  resourceName: OrganisationResources;
}) {
  const { add, remove } = useGroupOrganisationResourcesCrud();
  const resourcesRaw = useMemo(
    () => resources.map((r) => r.resource_name),
    [resources],
  );

  const editSelected = useMemo(
    () => resourcesRaw.includes(resourceName),
    [resourcesRaw, resourceName],
  );
  return (
    <GaplessRowPadding>
      <Text>{resourcesToNameMap[resourceName]} access</Text>
      <Toggle
        checked={editSelected}
        onChange={() =>
          editSelected
            ? remove(groupId, resourceName)
            : add(groupId, resourceName)
        }
        size={ToggleSize.SMALL}
      />
    </GaplessRowPadding>
  );
}

export const OrganisationResourceGroupTable = ErrorBoundaryWrapper(
  ({ groupId }: { groupId: string }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const groups = useAtomValue(
      organisationGroupsState({
        organisationId,
      }),
    );
    const group = groups.find((g) => g.id === groupId);

    const resources = useAtomValue(
      groupOrgResourceManagersAtomFamily({
        organisationId,
        groupId,
      }),
    );

    const orgUsers = useAtomValue(usersInOrganisationState(organisationId));
    const members = useAtomValue(
      groupMembersState({
        organisationId,
        groupId,
      }),
    );
    const someMemberIsGuest = members.some(
      (m) =>
        orgUsers.find((u) => u.user_id === m.user_id)?.org_role === "guest",
    );

    const allLibraryAccess = useAtomValue(
      libraryAllSelectorFamily({
        organisationId,
      }),
    );

    const organisationalResources = allLibraryAccess
      ? ([
          "org_turbine_manage",
          "org_foundation_manage",
          "org_cable_manage",
          "org_export_cable_manage",
          "org_analysis_manage",
          "org_financial_manage",
          "org_data_package_manage",
          "org_substation_manage",
        ] as OrganisationResources[])
      : ([
          "org_turbine_manage",
          "org_foundation_manage",
        ] as OrganisationResources[]);

    return (
      <Column
        style={{
          gap: "1.6rem",
        }}
      >
        <Column
          style={{
            gap: "1.2rem",
          }}
        >
          <TabSubtitle>
            Below is an overview of the library resources that are available for{" "}
            {`${group?.name ?? "this group"}`}.{" "}
            <StyledLink
              target="_blank"
              rel="noreferrer"
              href={"https://help.vindtech.com/en/articles/8842345-library"}
            >
              Read more.
            </StyledLink>
          </TabSubtitle>
        </Column>
        {someMemberIsGuest && (
          <SimpleAlert type="warning">
            This group has one or more members that is a guest in the
            organisation. Even with Library access they won't be able to access
            the Library.
          </SimpleAlert>
        )}
        <GaplessRow>
          <TableHeader>Access</TableHeader>
          <div />
        </GaplessRow>
        <ContentTableColumn>
          {organisationalResources.map((resourceName) => {
            return (
              <OrgResourceRow
                key={resourceName}
                groupId={groupId}
                resourceName={resourceName}
                resources={resources}
              />
            );
          })}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);
