import { useEffect } from "react";
import { viewViewModeAtom, VIEW_MODE } from "../../../../state/viewToPark";
import { disposeObject } from "../utils";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import { useAtomValue } from "jotai";

export default function useEnvironment(
  threeCore: ThreeCoreViewPark | undefined,
) {
  const viewMode = useAtomValue(viewViewModeAtom);

  useEffect(() => {
    if (!threeCore) return;
    const { scene, water, sky, sun } = threeCore;
    if (viewMode === VIEW_MODE.NATURAL_MODE) {
      scene.add(water);
      scene.add(sky);
      return () => {
        scene.remove(water);
        disposeObject(water);
        scene.remove(sky);
        disposeObject(sky);
      };
    } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
      scene.add(sun);
      return () => {
        scene.remove(sun);
        disposeObject(sun);
      };
    }
  }, [viewMode, threeCore]);
}
