import { mapAtom } from "state/map";
import { getActiveOtherMapSelector } from "../state/map";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

const HillshadingLayer = () => {
  const activeOtherMap = useAtomValue(getActiveOtherMapSelector);
  return (
    <>{activeOtherMap?.id === "hillshading" && <ActiveHillshadingLayer />}</>
  );
};

const ActiveHillshadingLayer = () => {
  const map = useAtomValue(mapAtom);

  useEffect(() => {
    if (!map) return;

    // Add the terrain source
    map.addSource("dem", {
      type: "raster-dem",
      url: "mapbox://mapbox.mapbox-terrain-dem-v1",
    });
    map.addLayer({
      id: "hillshading",
      source: "dem",
      type: "hillshade",
    });

    return () => {
      if (map.getLayer("hillshading")) map.removeLayer("hillshading");
      if (map.getSource("dem")) map.removeSource("dem");
    };
  }, [map]);

  return null;
};

export default HillshadingLayer;
