import { Row } from "components/General/Layout";
import { CSSProperties } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const Container = styled.div`
  position: fixed;
  top: 20rem;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  padding: 2.4rem 1.6rem;
  height: fit-content;
  width: 60rem;
  border-radius: 4px;
  background: white;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
`;
export const ListContainer = styled.div`
  padding: 1.2rem 0;
  display: flex;
  flex-direction: column;

  max-height: 40vh;
  box-sizing: border-box;
  overflow-y: scroll;
`;
export const ElementRow = styled(Row)<{
  selected: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  ${(p) => p.selected && `background-color: ${colors.selected};`}
  padding: 0.8rem 0.4rem;
  border-radius: 0.4rem;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${colors.hover};
  }
  p {
    ${(p) => p.disabled && `color: ${colors.primaryDisabled};`}
  }
  svg {
    ${(p) => p.disabled && `opacity: 50%;`}
  }
`;
export const ElementTitle = styled.p<{ disabled?: boolean }>`
  ${typography.contentAndButtons}
  margin: 0;
`;
export const inputStyle: CSSProperties = {
  background: "white",
  paddingRight: "4rem",
  width: "100%",
  borderColor: colors.dropdownBorder,
};
export const DividerHeader = styled.p`
  ${typography.graphics}
  color: ${colors.secondaryText}
`;
export const Divider = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.inputOutline};
`;

export const MainText = styled.p`
  ${typography.body}
  margin: 0;
`;

export const SecondaryText = styled.p`
  ${typography.caption}
  color: ${colors.textDisabled};
  margin: 0;
`;
