import React, { useMemo } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { AnalysisConfiguration } from "services/configurationService";
import { colors } from "styles/colors";
import { useAtomValue } from "jotai";
import {
  libraryAnalysisConfigurationsFamily,
  projectAnalysisConfigurationsFamily,
} from "state/jotai/analysisConfiguration";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 10rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectAnalysisDropDownCustom = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  className,
  disabled,
  backgroundColor,
}: {
  selectedConfig?: AnalysisConfiguration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  backgroundColor?: string;
}) => {
  const libraryAnalysis = useAtomValue(
    libraryAnalysisConfigurationsFamily({ projectId }),
  );
  const projectAnalysis = useAtomValue(
    projectAnalysisConfigurationsFamily({ projectId }),
  );

  const configItems = useMemo<DropDownItem[]>(() => {
    return [
      ...Array.from(projectAnalysis.values())
        .sort((a, b) => a.name!.localeCompare(b.name!))
        .map((c) => ({
          value: c.id,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <ProjectIcon />
            </IconREMSize>
          ),
        })),
      ...Array.from(libraryAnalysis.values())
        .sort((a, b) => a.name!.localeCompare(b.name!))
        .map((c) => ({
          value: c.id,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <LibraryIcon />
            </IconREMSize>
          ),
        })),
    ];
  }, [libraryAnalysis, projectAnalysis]);

  return (
    <DropdownButton
      className={className}
      items={configItems}
      disabled={disabled}
      backgroundColor={backgroundColor}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={style}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        (
          projectAnalysis.get(selectedConfig?.id ?? "") ??
          libraryAnalysis.get(selectedConfig?.id ?? "")
        )?.name ?? "Select configuration"
      }
      selectedItemValue={selectedConfig?.id}
    />
  );
};

export default SelectAnalysisDropDownCustom;
