import { MooringParameters } from "components/GenerateFoundationsAndAnchors/types";
import { FloaterType } from "types/foundations";
import { z } from "zod";
import {
  MooringLineType,
  TARGET_FAIRLEAD_ANGLE,
  TARGET_PRETENSION,
} from "../services/mooringLineTypeService";
import { newtonRaphson } from "./newtonRapson";

const _MooringCoords = z.object({
  x: z.array(z.number()),
  y: z.array(z.number()),
  z: z.array(z.number()),
});
export type MooringCoords = z.infer<typeof _MooringCoords>;

const calcSegmentSpan = ({
  H,
  V0,
  Lb,
  L,
  EA,
  w,
}: {
  H: number;
  V0: number;
  Lb: number;
  L: number;
  EA: number;
  w: number;
}): [number, number] => {
  let X: number, Z: number;
  if (Lb > 0) {
    if (L <= Lb) {
      X = L;
      Z = 0;
    } else {
      X = Lb + (H / w) * Math.asinh((w * (L - Lb)) / H) + (H * L) / EA;
      Z =
        (H / w) * (Math.sqrt(1 + Math.pow((w * (L - Lb)) / H, 2)) - 1) +
        (w * Math.pow(L - Lb, 2)) / (2 * EA);
    }
  } else {
    X =
      (H / w) * (Math.asinh((V0 + w * L) / H) - Math.asinh(V0 / H)) +
      (H * L) / EA;
    Z =
      (H / w) *
        (Math.sqrt(1 + Math.pow((V0 + w * L) / H, 2)) -
          Math.sqrt(1 + Math.pow(V0 / H, 2))) +
      (1 / EA) * (V0 * L + (w * Math.pow(L, 2)) / 2);
  }

  return [X, Z];
};

const calcLocalLineCoords = ({
  H,
  V0,
  Lb,
  L,
  EA,
  w,
}: {
  H: number;
  V0: number;
  Lb: number;
  L: number;
  EA: number;
  w: number;
}): [number[], number[]] => {
  const x: number[] = [];
  const z: number[] = [];

  for (let s = 0; s < L; s += 5) {
    if (Lb > 0) {
      if (s <= Lb) {
        x.push(s);
        z.push(0);
      } else {
        x.push(Lb + (H / w) * Math.asinh((w * (s - Lb)) / H) + (H * s) / EA);
        z.push(
          (H / w) * (Math.sqrt(1 + Math.pow((w * (s - Lb)) / H, 2)) - 1) +
            (w * Math.pow(s - Lb, 2)) / (2 * EA),
        );
      }
    } else {
      x.push(
        (H / w) * (Math.asinh((V0 + w * s) / H) - Math.asinh(V0 / H)) +
          (H * s) / EA,
      );
      z.push(
        (H / w) *
          (Math.sqrt(1 + Math.pow((V0 + w * s) / H, 2)) -
            Math.sqrt(1 + Math.pow(V0 / H, 2))) +
          (1 / EA) * (V0 * s + (w * Math.pow(s, 2)) / 2),
      );
    }
  }
  if (Lb > 0) {
    if (L <= Lb) {
      x.push(L);
      z.push(0);
    } else {
      x.push(Lb + (H / w) * Math.asinh((w * (L - Lb)) / H) + (H * L) / EA);
      z.push(
        (H / w) * (Math.sqrt(1 + Math.pow((w * (L - Lb)) / H, 2)) - 1) +
          (w * Math.pow(L - Lb, 2)) / (2 * EA),
      );
    }
  } else {
    x.push(
      (H / w) * (Math.asinh((V0 + w * L) / H) - Math.asinh(V0 / H)) +
        (H * L) / EA,
    );
    z.push(
      (H / w) *
        (Math.sqrt(1 + Math.pow((V0 + w * L) / H, 2)) -
          Math.sqrt(1 + Math.pow(V0 / H, 2))) +
        (1 / EA) * (V0 * L + (w * Math.pow(L, 2)) / 2),
    );
  }

  return [x, z];
};

const calcTotalLineSpan = ({
  H,
  b,
  lineLengths,
  EAs,
  wetWeights,
  attachments,
  anchorRadius,
  fairRadius,
  waterDepth,
  fairZ,
  maxIter,
  eps,
}: {
  H: number;
  b: number;
  lineLengths: number[];
  EAs: number[];
  wetWeights: number[];
  attachments: number[];
  anchorRadius: number;
  fairRadius: number;
  waterDepth: number;
  fairZ: number;
  maxIter: number;
  eps: number;
}): [number, number, number, number[], number[], boolean] => {
  let fX = 1e3,
    fY = 1e3;
  let HOld = 0,
    fXOld = 0;
  let V0Seg = Array<number>(lineLengths.length).fill(0);
  let LbSeg = Array<number>(lineLengths.length).fill(0);
  let solutionFound = false;
  const forwardWeight = 0.5;
  for (let iter = 0; iter < maxIter; iter++) {
    let XTot = 0;
    let YTot = 0;
    const alpha = (-b * Math.PI) / 180;

    let s = lineLengths[0];
    let w = wetWeights[0];
    let EA = EAs[0];

    const R = b > 0 ? 0 : H * Math.tan(alpha);
    const L0 = R;
    const Lb0 = b > 0 ? (b > s ? s : b) : 0;

    let bAcc = b - s;

    let accWeight = bAcc > 0 ? -s * w : -b * w;
    let accAttachment = bAcc > 0 ? 0 : attachments[0];
    let [XSeg, ZSeg] = calcSegmentSpan({
      L: s,
      w: w,
      EA: EA,
      H: H,
      V0: L0,
      Lb: Lb0,
    });
    V0Seg[0] = L0;
    LbSeg[0] = Lb0;
    XTot += XSeg;
    YTot += ZSeg;
    for (let i = 1; i < lineLengths.length; i++) {
      s = lineLengths[i];
      w = wetWeights[i];
      EA = EAs[i];

      let L = L0;
      accWeight += lineLengths[i - 1] * wetWeights[i - 1];
      if (bAcc >= s) {
        let [XSeg, ZSeg] = calcSegmentSpan({
          L: s,
          w: w,
          EA: EA,
          H: H,
          V0: L,
          Lb: s,
        });
        V0Seg[i] = L;
        LbSeg[i] = s;
        XTot += XSeg;
        YTot += ZSeg;
        accWeight += -s * w;
      } else if (bAcc > 0 && bAcc < s) {
        let [XSeg, ZSeg] = calcSegmentSpan({
          L: s,
          w: w,
          EA: EA,
          H: H,
          V0: L,
          Lb: bAcc,
        });
        V0Seg[i] = L;
        LbSeg[i] = bAcc;
        XTot += XSeg;
        YTot += ZSeg;
        accWeight += -bAcc * w;
        accAttachment += attachments[i];
      } else {
        L = accWeight + R + 9.81 * accAttachment;

        accAttachment += attachments[i];
        let [XSeg, ZSeg] = calcSegmentSpan({
          L: s,
          w: w,
          EA: EA,
          H: H,
          V0: L,
          Lb: 0,
        });
        V0Seg[i] = L;
        LbSeg[i] = 0;

        XTot += XSeg;
        YTot += ZSeg;
      }

      bAcc += bAcc > s ? -s : -bAcc;
    }

    fX = anchorRadius - fairRadius - XTot;
    fY = waterDepth + fairZ - YTot;
    if (Math.abs(fX) < eps) {
      solutionFound = true;
      break;
    }
    //If first iteration, make a small move in the correct direction. If not, use weighted secant method
    if (iter === 0) {
      HOld = H;
      fXOld = fX;
      H += -1e-3 * Math.sign(fX);
    } else {
      const dfX = fX - fXOld;
      const dH = H - HOld;
      HOld = H;
      fXOld = fX;
      H = Math.max(
        (1 - forwardWeight) * HOld + forwardWeight * (HOld - fX / (dfX / dH)),
        1,
      );
    }
  }
  return [H, fX, fY, V0Seg, LbSeg, solutionFound];
};

const mooringSegmentSolver = ({
  lineLengths,
  anchorRadius,
  waterDepth,
  fairRadius,
  fairZ,
  EAs,
  wetWeights,
  attachments,
}: {
  lineLengths: number[];
  anchorRadius: number;
  waterDepth: number;
  fairRadius: number;
  fairZ: number;
  EAs: number[];
  wetWeights: number[];
  attachments: number[];
}):
  | {
      H: number;
      b: number;
      V0Line: number[];
      LbLine: number[];
    }
  | undefined => {
  //Note: maxIter and maxOutIter are extremely unlikely to be reached (or even close to) at the same time. However, for some mooring line configurations, either the inner or the outer iteration scheme may need a large number of iterations to converge, making these relative large max values necessary.
  const maxIter = 300;
  const maxOutIter = 150;
  const eps = 10.0;
  const forwardWeight = 0.15;

  let b = 0.1,
    H = 1e3;
  const [, , fYMin] = calcTotalLineSpan({
    H,
    b,
    lineLengths,
    EAs,
    wetWeights,
    attachments,
    anchorRadius,
    fairRadius,
    waterDepth,
    fairZ,
    maxIter,
    eps,
  });

  const totalLineLength = lineLengths.reduce((acc, l) => {
    return acc + l;
  }, 0);
  if (fYMin > 0) {
    b = (-fYMin / totalLineLength) * 90;
  } else {
    b = 0.5 * (totalLineLength - waterDepth);
  }

  let bOld = 0;
  let fYOld = 0;
  let HX, fY, V0Line, LbLine, solutionFound;
  const bSign = Math.sign(b);
  let outSolutionFound = false;

  for (let outIter = 0; outIter < maxOutIter; outIter++) {
    [HX, , fY, V0Line, LbLine, solutionFound] = calcTotalLineSpan({
      H: H,
      b,
      lineLengths,
      EAs,
      wetWeights,
      attachments,
      anchorRadius,
      fairRadius,
      waterDepth,
      fairZ,
      maxIter,
      eps,
    });
    H = HX;
    if (Math.abs(fY) < eps) {
      outSolutionFound = true;
      break;
    }
    //If first iteration, make a small move in the correct direction. If not, use weighted secant method
    if (outIter === 0) {
      bOld = b;
      fYOld = fY;
      b += -1e-3 * Math.sign(fY);
    } else {
      const dfY = fY - fYOld;
      const db = b - bOld;
      bOld = b;
      fYOld = fY;
      b = Math.max(
        (1 - forwardWeight) * bOld + forwardWeight * (bOld - fY / (dfY / db)),
        bSign > 0 ? 0 : -90,
      );
    }
  }

  if (!solutionFound || !outSolutionFound) {
    return undefined;
  }

  return { H, b, V0Line: V0Line ?? [], LbLine: LbLine ?? [] };
};

export const mooringSegmentVisualization = ({
  lineLengths,
  anchorRadius,
  bearing,
  waterDepth,
  fairRadius,
  fairZ,
  EAs,
  wetWeights,
  attachments,
}: {
  lineLengths: number[];
  anchorRadius: number;
  bearing: number;
  waterDepth: number;
  fairRadius: number;
  fairZ: number;
  EAs: number[];
  wetWeights: number[];
  attachments: number[];
}): {
  mooringCoords: MooringCoords;
  clumpWeightCoords: MooringCoords;
  buoyCoords: MooringCoords;
} => {
  const lineSol = mooringSegmentSolver({
    lineLengths,
    anchorRadius,
    waterDepth,
    fairRadius,
    fairZ,
    EAs,
    wetWeights,
    attachments,
  });

  const xMoor: number[] = [];
  const yMoor: number[] = [];
  const zMoor: number[] = [];

  const xClumpWeight: number[] = [];
  const yClumpWeight: number[] = [];
  const zClumpWeight: number[] = [];

  const xBuoy: number[] = [];
  const yBuoy: number[] = [];
  const zBuoy: number[] = [];

  if (!lineSol) {
    return {
      mooringCoords: { x: xMoor, y: yMoor, z: zMoor },
      clumpWeightCoords: { x: xClumpWeight, y: yClumpWeight, z: zClumpWeight },
      buoyCoords: { x: xBuoy, y: yBuoy, z: zBuoy },
    };
  }

  const { H, V0Line, LbLine } = lineSol;

  let x0 = -anchorRadius;
  let z0 = -waterDepth;
  for (let i = 0; i < lineLengths.length; i++) {
    const [xSeg, zSeg] = calcLocalLineCoords({
      L: lineLengths[i],
      w: wetWeights[i],
      EA: EAs[i],
      H: H,
      V0: V0Line[i],
      Lb: LbLine[i],
    });

    for (let j = 0; j < xSeg.length; j++) {
      const xUnrot = xSeg[j] + x0;
      xMoor.push(xUnrot * Math.cos((bearing * Math.PI) / 180));
      yMoor.push(xUnrot * Math.sin((bearing * Math.PI) / 180));
      zMoor.push(zSeg[j] + z0 > -waterDepth ? zSeg[j] + z0 : -waterDepth);

      if (j === xSeg.length - 1) {
        x0 += xSeg[j];
        z0 += zSeg[j];
      }
    }
    if (attachments[i] > 0) {
      xClumpWeight.push(xMoor[xMoor.length - 1]);
      yClumpWeight.push(yMoor[yMoor.length - 1]);
      zClumpWeight.push(zMoor[zMoor.length - 1]);
    }
    if (attachments[i] < 0) {
      xBuoy.push(xMoor[xMoor.length - 1]);
      yBuoy.push(yMoor[yMoor.length - 1]);
      zBuoy.push(zMoor[zMoor.length - 1]);
    }
  }
  return {
    mooringCoords: { x: xMoor, y: yMoor, z: zMoor },
    clumpWeightCoords: { x: xClumpWeight, y: yClumpWeight, z: zClumpWeight },
    buoyCoords: { x: xBuoy, y: yBuoy, z: zBuoy },
  };
};

export const touchdown = ({
  lineLengths,
  anchorRadius,
  waterDepth,
  fairRadius,
  fairZ,
  EAs,
  wetWeights,
  attachments,
}: {
  lineLengths: number[];
  anchorRadius: number;
  waterDepth: number;
  fairRadius: number;
  fairZ: number;
  EAs: number[];
  wetWeights: number[];
  attachments: number[];
}): number => {
  const lineSol = mooringSegmentSolver({
    lineLengths,
    anchorRadius,
    waterDepth,
    fairRadius,
    fairZ,
    EAs,
    wetWeights,
    attachments,
  });

  if (!lineSol) {
    return anchorRadius;
  }

  const { b } = lineSol;

  const seabedLength = b > 0 ? b : 0;

  return anchorRadius - seabedLength;
};

const findRequiredLineLength = ({
  anchorRadius,
  waterDepth,
  fairRadius,
  fairZ,
  EA,
  wetWeight,
}: {
  anchorRadius: number;
  waterDepth: number;
  fairRadius: number;
  fairZ: number;
  EA: number;
  wetWeight: number;
}): number | undefined => {
  const T = TARGET_PRETENSION;
  const theta = TARGET_FAIRLEAD_ANGLE;
  const Th = T * Math.cos(theta);

  function effectiveLength(L: number) {
    return (
      waterDepth +
      fairZ -
      (wetWeight * L) / (2 * EA) -
      (Th / wetWeight) * (Math.sqrt(1 + Math.pow(L / (Th / wetWeight), 2)) - 1)
    );
  }

  const effLength = newtonRaphson({
    guess: anchorRadius - fairRadius,
    increment: 1e-6,
    iterations: 50,
    eps: 1e-3,
    f: effectiveLength,
  });

  if (effLength <= 0) return;

  const seabedLength =
    anchorRadius -
    fairRadius -
    (Th / wetWeight) * Math.asinh(effLength / (Th / wetWeight)) -
    (Th * effLength) / EA;

  let totalLength = effLength + seabedLength;

  // Taut mooring line
  if (seabedLength < 0) {
    const dist = Math.sqrt(
      Math.pow(waterDepth + fairZ, 2) + Math.pow(anchorRadius - fairRadius, 2),
    );
    totalLength = dist / (TARGET_PRETENSION / EA + 1);
  }

  return totalLength;
};

export const estimateLineLengthFromParams = (
  params: MooringParameters,
  lineTypes: MooringLineType[],
  waterDepth: number,
  foundation: FloaterType | undefined,
): number | undefined => {
  const lineType = lineTypes.find((lt) => lt.id === params.lineTypeId);
  const anchorRadius =
    params.distanceMode === "km"
      ? 1000 * params.distance
      : params.distance * waterDepth;
  const EA = lineType?.EA;
  const wetWeight = lineType?.wetWeight;

  const segLineTypes = params.lineTypeIds.map((id) =>
    lineTypes.find((lt) => lt.id === id),
  );
  const segTotalLineLength = params.lineLengths.reduce(
    (acc, l) => (acc += l),
    0,
  );
  const segAvgEA = params.lineLengths.reduce(
    (acc, length, i) =>
      (acc += ((segLineTypes[i]?.EA ?? 0) * length) / segTotalLineLength),
  );
  const segAvgAttN = params.lineLengths.map(
    (length, i) =>
      (params.attachments[i] * 9.81) /
      (params.distanceMode === "km" ? 1000 * length : waterDepth * length),
  );
  const segAvgWetWeight = params.lineLengths.reduce(
    (acc, length, i) =>
      (acc +=
        (((segLineTypes[i]?.wetWeight ?? 0) + segAvgAttN[i]) * length) /
        segTotalLineLength),
  );
  const lineLength = findRequiredLineLength({
    anchorRadius,
    waterDepth,
    fairRadius: foundation?.fairRadius ?? 0,
    fairZ: foundation?.fairZ ?? 0,
    EA: params.multisegment ? segAvgEA : EA ?? 1e9,
    wetWeight: params.multisegment ? segAvgWetWeight : wetWeight ?? 1e3,
  });

  return lineLength;
};
