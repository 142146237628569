import React from "react";

const WindowContext = React.createContext<{
  context: Window | undefined;
}>({
  context: window,
});

export const WindowContextProvider = ({
  context,
  children,
}: React.PropsWithChildren<{ context: Window | undefined }>) => {
  return (
    <WindowContext.Provider value={{ context }}>
      {children}
    </WindowContext.Provider>
  );
};
