import { useEffect } from "react";
import { ValidationWarningTypes } from "./utils";
import { turbineSourceId } from "components/Mapbox/constants";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { parkFamily } from "state/jotai/park";
import { pointInPolygon } from "utils/geometry";

export const turbineOutsideParkWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.TurbineOutsidePark> | undefined
      >
    >(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;

      const outside = turbines.filter(
        (t) => !pointInPolygon(t.geometry, park.geometry),
      );

      if (outside.length === 0) return;
      return {
        type: ValidationWarningTypes.TurbineOutsidePark,
        featureIds: outside.map((t) => t.id),
        parkId: parkId,
      };
    }),
);

export const TurbineOutsidePark = ({
  map,
  parkId,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  parkId: string;
  source?: string;
}) => {
  const warning = useAtomValue(
    turbineOutsideParkWarningFamily({
      parkId: parkId,
      branchId: undefined,
    }),
  );
  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });

    return () => {
      if (map.getSource(source))
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [map, warning, source]);

  return null;
};
