import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { SVGWrapper } from "@icons/svgUtils";
import {
  userGroupsMembershipState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  NoGroupMember,
  NoGroupMemberWrapper,
  TableHeader,
  TabSubtitle,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { adminInOrganisationSelectorFamily } from "state/user";
import { Text, TextSemi } from "styles/typography";
import RemoveIcon from "@icons/24/Remove.svg";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import { Column, Row } from "components/General/Layout";
import Button from "components/General/Button";
import React, { useCallback, useState } from "react";
import { Group } from "components/Organisation/Groups/types";
import UserGroupModal from "../../UserGroupModal";
import { OrganisationUser } from "types/customer";
import { ContentImageWrapper } from "components/GroupImageRound/GroupImageRound";
import { colors } from "styles/colors";
import Tooltip from "components/General/Tooltip";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import useRefreshUsersInNodeAndSubnodes from "components/Organisation/useRefreshUsersInNodeAndSubnodes";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export function UserGroups({ user }: { user: OrganisationUser }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const userId = user.user_id;
  const { showConfirm } = useConfirm();
  const refreshUsersInNodeAndSubnodes = useRefreshUsersInNodeAndSubnodes();
  const memberships = useAtomValue(
    userGroupsMembershipState({
      organisationId,
      userId,
    }),
  );
  const { remove, add } = useMemberInGroupCrud();
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showGroupSearch, setShowGroupSearch] = useState(false);
  const onSaveMultiple = useCallback(
    async (groups: Group[]) => {
      setShowGroupSearch(false);
      setSaveInProgress(groups.length);
      try {
        await Promise.all(
          groups.map((g) =>
            add(g.id, userId).then(() => setSaveInProgress((cur) => cur - 1)),
          ),
        );
        if (groups.length > 0) {
          refreshUsersInNodeAndSubnodes(organisationId, organisationId);
        }
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [add, organisationId, refreshUsersInNodeAndSubnodes, userId],
  );

  return (
    <Column
      style={{
        height: "100%",
        gap: "1.6rem",
      }}
    >
      <Row
        style={{
          alignItems: "center",
        }}
      >
        <TabSubtitle>
          {user.nickname} is a member of the groups below.
        </TabSubtitle>
        <div
          style={{
            marginLeft: "auto",
            position: "relative",
          }}
        >
          <Tooltip
            text={`Organisation admin can add ${user.nickname} to groups`}
            disabled={isAdminInOrg}
          >
            <Button
              text="Add to group"
              buttonType="secondary"
              onClick={() => setShowGroupSearch((cur) => !cur)}
              disabled={saveInProgress > 0 || !isAdminInOrg}
            />
          </Tooltip>
          {showGroupSearch && (
            <div
              style={{
                position: "absolute",
                right: "0.8rem",
              }}
            >
              <UserGroupModal
                existingMemberships={memberships}
                onSave={onSaveMultiple}
              />
            </div>
          )}
        </div>
      </Row>
      {memberships.length > 0 && (
        <Row>
          <TableHeader>Group</TableHeader>
        </Row>
      )}
      <ContentTableColumn>
        {memberships.map((m) => {
          const group = groups.find((g) => g.id === m.group_id);
          return (
            <ContentTableRow
              key={m.group_id}
              disabled={deleteInProgress === m.group_id}
              style={{
                justifyContent: "space-between",
                cursor: "unset",
              }}
            >
              <Row>
                <SVGWrapper size={1.4}>
                  <TeamMeeting />
                </SVGWrapper>
                <TextEllipsis
                  style={{
                    margin: 0,
                  }}
                >
                  {group?.name}
                </TextEllipsis>
              </Row>
              {isAdminInOrg && (
                <Tooltip text="Remove access">
                  <IconBtn
                    disabled={deleteInProgress === m.group_id}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (
                        !(await showConfirm({
                          title: "Remove user from group",
                          message: `Are you sure you want to remove the user from the group?`,
                          confirmButtonText: "Remove",
                        }))
                      ) {
                        return;
                      }

                      setDeleteInProgress(m.group_id);
                      remove(m.group_id, m.user_id).finally(() =>
                        setDeleteInProgress(undefined),
                      );
                    }}
                    size={"1.4rem"}
                    style={{
                      opacity: 1,
                    }}
                  >
                    <RemoveIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </ContentTableRow>
          );
        })}
        {Array.from(
          {
            length: saveInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "0.8rem",
              }}
            />
          ),
        )}
      </ContentTableColumn>
      {isAdminInOrg && memberships.length === 0 && (
        <NoGroupMemberWrapper>
          <NoGroupMember>
            <ContentImageWrapper
              size={4}
              color={colors.brand}
              svgColor={"white"}
              style={{
                marginBottom: "1.2rem",
              }}
            >
              <TeamMeeting />
            </ContentImageWrapper>
            <TextSemi>No groups added yet</TextSemi>
            <Text>{"Click ‘Add to Group' above to start adding"}</Text>
          </NoGroupMember>
        </NoGroupMemberWrapper>
      )}
      {!isAdminInOrg && memberships.length === 0 && (
        <NoGroupMemberWrapper>
          <NoGroupMember>
            <ContentImageWrapper
              size={4}
              color={colors.brand}
              svgColor={"white"}
              style={{
                marginBottom: "1.2rem",
              }}
            >
              <TeamMeeting />
            </ContentImageWrapper>
            <TextSemi>No groups added yet</TextSemi>
            <Text>
              {"Organisation Owner or Admins can add users to Groups."}
            </Text>
          </NoGroupMember>
        </NoGroupMemberWrapper>
      )}
    </Column>
  );
}
