import Tooltip from "components/General/Tooltip";
import { colors } from "styles/colors";
import {
  ResourceItemTitle,
  NameWithPadding,
  ResourceNameText,
} from "../../style";
import { Resource } from "../types";
import { highlightSearchTerm } from "../utils";

interface ResourceNameProps {
  resource: Resource;
  collapsed: boolean;
  searchTerm: string;
  depth: number;
  nameExtraInfo?: string;
}

const ResourceName = ({
  resource,
  collapsed,
  searchTerm,
  depth,
  nameExtraInfo,
}: ResourceNameProps) => {
  return (
    <>
      <ResourceItemTitle>
        <NameWithPadding depth={depth}>
          <Tooltip text={resource.name} content={undefined}>
            <ResourceNameText collapsed={collapsed}>
              {highlightSearchTerm(resource.name ?? "", searchTerm)}
              {nameExtraInfo && (
                <span
                  style={{
                    color: colors.textDisabled,
                  }}
                >
                  {" "}
                  ({nameExtraInfo})
                </span>
              )}
            </ResourceNameText>
          </Tooltip>
        </NameWithPadding>
      </ResourceItemTitle>
    </>
  );
};

export default ResourceName;
