import { useCallback, useMemo } from "react";
import { ABLY_ORG_CABLE_TYPE_UPDATE } from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import { _CableType } from "services/cableTypeService";
import { useLibraryNodeCables } from "../useLibraryNodeCables";

export function useAblyLibraryCablesForNode(projectId: string) {
  const { updateExportCablesLocal, updateInterArrayCablesLocal } =
    useLibraryNodeCables(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const cable = _CableType.parse(message.data.meta);
      if (cable.exportCableType) {
        updateExportCablesLocal(cable);
      } else {
        updateInterArrayCablesLocal(cable);
      }
    },
    [updateExportCablesLocal, updateInterArrayCablesLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ORG_CABLE_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
