import { atomFamily, suspendAtom } from "utils/jotai";
import * as turf from "@turf/turf";
import { _MultiPolygon } from "utils/geojson/geojson";
import {
  NoiseAnalysisParams,
  _NoiseAnalysisParams,
  triggerNoiseAnalysis,
} from "services/noiseService";
import { parkIdAtom, projectIdAtomDef2 } from "../../../state/pathParams";
import { fetchEnhancer } from "services/utils";
import GeoTIFF, { TypedArray, fromBlob } from "geotiff";
import { parkFamily } from "../../../state/jotai/park";
import { simpleTurbineTypesAtom } from "../../../state/jotai/turbineType";
import { turbinesInParkFamily } from "../../../state/jotai/turbine";
import { dedup, fastMax, roundToDecimal } from "utils/utils";
import { isDefined } from "utils/predicates";
import { getProj4StringForEPSGSelectorFamily } from "../../../state/epsg";
import { scream } from "utils/sentry";
import { transformBBOXToWGS84Square } from "utils/proj4";
import { Buckets, Color } from "lib/colors";
import { getOctaveBandLevels } from "utils/noise";
import { sensorPointsFamily } from "state/jotai/sensorPoint";
import { PolygonFeature, SensorFeature } from "types/feature";
import {
  MaxValueType,
  SensorStatsType,
} from "components/RightSide/InfoModal/ProjectFeatureInfoModal/SensorStats";
import { atom } from "jotai";
import md5 from "md5";
import {
  NoiseAnalysisSettings,
  _NoiseAnalysisInput,
  GROUND_TYPES,
  COLORS,
  NoiseAnalysisStatus,
  isAnalysisStatusComplete,
  NoiseAnalysisComplete,
  noiseDisplaySettingsAtom,
  isAnalysisStatusFailed,
  isAnalysisStatusStopped,
  NoiseAnalysisFailed,
  NoiseAnalysisStopped,
} from "./types";
import { NoiseAnalysisResults } from "./types";

export const noiseAnalysisSettingsAtom = atom<NoiseAnalysisSettings>({
  source: 100,
  sensorHeight: 5,
  humidity: 70,
  temperature: 15,
  groundModel: {
    type: "alternativeGround",
  },
});

export const updateNoiseAnalysisSettingsAtom = atom(
  null,
  (_, set, settings: Partial<NoiseAnalysisSettings>) => {
    set(noiseAnalysisSettingsAtom, (curr) =>
      _NoiseAnalysisInput.parse({ ...curr, ...settings }),
    );
  },
);

const noiseAnalysisStatus = atomFamily((_: { id: string | undefined }) =>
  atom<Promise<NoiseAnalysisStatus> | undefined>(),
);

export const getNoiseAnalysisStatus = atom(async (get) => {
  const id = await get(noiseIdAtom);

  return get(noiseAnalysisStatus({ id }));
});

export const setNoiseAnalysisStatus = atom(
  null,
  async (_, set, update: NoiseAnalysisStatus) => {
    set(noiseAnalysisStatus({ id: update.id }), Promise.resolve(update));
  },
);

export const noiseIdAtom = atom<Promise<string> | undefined>(undefined);

const boundaryAtom = atomFamily((_: { id: string }) =>
  atom<Promise<PolygonFeature> | undefined>(),
);

const sensorPointsInBoundaryAtom = atomFamily((_: { id: string }) =>
  atom<Promise<SensorFeature[]> | undefined>(),
);

const noiseAnalysisInputsAtom = atom<
  | {
      params: NoiseAnalysisParams;
      features: {
        bufferedPark: PolygonFeature;
        sensorPoints: SensorFeature[];
      };
      hash: string;
    }
  | undefined
>(undefined);

export const noiseInputsChanged = atom(async (get) => {
  const inputs = get(noiseAnalysisInputsAtom);
  const currentInputs = await get(currrentNoiseAnalysisInputsAtom);

  return inputs ? inputs.hash !== currentInputs?.hash : false;
});

const currrentNoiseAnalysisInputsAtom = atom(async (get) => {
  const settings = get(noiseAnalysisSettingsAtom);
  const parkId = await get(parkIdAtom);

  if (!parkId) {
    return undefined;
  }

  const park = await get(parkFamily({ parkId, branchId: undefined }));
  const allTurbineTypes = await get(simpleTurbineTypesAtom);
  const turbineFeatures = await get(
    turbinesInParkFamily({ parkId, branchId: undefined }),
  );
  const sensorPoints = await get(sensorPointsFamily({ branchId: undefined }));

  if (!park) {
    throw new Error("Could not find the park");
  }

  const bufferedPark = turf.buffer(park, 1.5, { units: "kilometers" });
  if (!bufferedPark) {
    throw new Error("Failed to buffer park");
  }

  const pointsInBoundary = sensorPoints.filter((point) =>
    turf.booleanPointInPolygon(point.geometry.coordinates, bufferedPark),
  );

  const turbineTypeIds = dedup(
    turbineFeatures.map((t) => t.properties.turbineTypeId).filter(isDefined),
  );

  const turbines = turbineFeatures.map((t) => ({
    type: t.properties.turbineTypeId,
    lon: t.geometry.coordinates[0],
    lat: t.geometry.coordinates[1],
  }));

  const { levels, frequencies } = getOctaveBandLevels(settings.source);

  const alternativeGroundEffect =
    settings.groundModel.type === "alternativeGround";
  const groundType =
    settings.groundModel.type === "standard"
      ? GROUND_TYPES[settings.groundModel.groundType]
      : undefined;

  const turbineTypes = Object.fromEntries(
    turbineTypeIds
      .map((id) => allTurbineTypes.get(id))
      .filter(isDefined)
      .map((t) => [
        t.id,
        {
          height: t.hubHeight,
          freqs: frequencies,
          soundPowerLevel: levels,
        },
      ]),
  );

  const polygons = [bufferedPark.geometry.coordinates];

  const inputs = {
    features: {
      bufferedPark: bufferedPark as PolygonFeature,
      sensorPoints: pointsInBoundary,
    },
    params: {
      turbines,
      polygons,
      points: pointsInBoundary.map((p) => p.geometry.coordinates),
      turbineTypes,
      alternativeGroundEffect,
      groundType,
      receiverHeight: settings.sensorHeight,
      temperature: settings.temperature,
      relativeHumidity: settings.humidity,
      step: 50,
    } satisfies NoiseAnalysisParams,
  };
  const hash = md5(JSON.stringify(inputs));
  return { ...inputs, hash };
});

export const resetAnalysisAtom = atom(null, async (_, set) => {
  set(noiseIdAtom, undefined);
  set(noiseAnalysisInputsAtom, undefined);
});

export const triggerAnalysisAtom = atom(null, async (get, set) => {
  set(noiseIdAtom, get(suspendAtom));

  const projectId = await get(projectIdAtomDef2);
  const currentInputs = await get(currrentNoiseAnalysisInputsAtom);
  if (!currentInputs) return;

  const { features, params, hash } = currentInputs;
  set(noiseAnalysisInputsAtom, { features, params, hash });

  try {
    const res = await triggerNoiseAnalysis({
      projectId,
      params: _NoiseAnalysisParams.parse(params),
    });

    console.log({ res });

    if (
      isAnalysisStatusComplete(res) ||
      isAnalysisStatusStopped(res) ||
      isAnalysisStatusFailed(res)
    ) {
      set(setNoiseAnalysisFinished, res);
    } else {
      set(noiseIdAtom, Promise.resolve(res.id));
    }

    set(boundaryAtom({ id: res.id }), Promise.resolve(features.bufferedPark));

    set(
      sensorPointsInBoundaryAtom({ id: res.id }),
      Promise.resolve(features.sensorPoints),
    );
  } catch (e) {
    if (e instanceof Error) {
      scream(e);
    } else {
      scream(new Error("Failed to trigger noise analysis"), {
        error: e,
      });
    }
  }
});

const setNoiseAnalysisFinished = atom(
  null,
  async (
    _,
    set,
    res: NoiseAnalysisComplete | NoiseAnalysisFailed | NoiseAnalysisStopped,
  ) => {
    set(noiseIdAtom, Promise.resolve(res.id));
    set(noiseAnalysisStatus({ id: res.id }), Promise.resolve(res));
  },
);

const getNoiseAnalysisComplete = atomFamily(({ id }: { id: string }) =>
  atom<Promise<NoiseAnalysisComplete>>(async (get) => {
    const status = await get(noiseAnalysisStatus({ id }));

    if (status && isAnalysisStatusComplete(status)) {
      return Promise.resolve(status);
    }

    return get(suspendAtom);
  }),
);

export const noiseAnalysisResults = atom<Promise<NoiseAnalysisResults>>(
  async (get) => {
    const id = await get(noiseIdAtom);
    if (!id) return get(suspendAtom);

    const sensorPoints = await get(sensorPointsInBoundaryAtom({ id }));
    if (!sensorPoints) return get(suspendAtom);

    const { points: sensorValues } = await get(
      getNoiseAnalysisComplete({ id }),
    );
    const colorBuckets = await get(colorBucketsAtom);

    let maxSensorValue: MaxValueType | undefined;
    let sensorStats: SensorStatsType[] = [];

    const hasSensors = sensorValues.length > 0;
    if (hasSensors) {
      const maxIndex = sensorValues.indexOf(fastMax(sensorValues));
      const maxValue = roundToDecimal(sensorValues[maxIndex], 1);

      maxSensorValue = {
        value: maxValue,
        unit: "dB(A)",
        sensor: sensorPoints[maxIndex],
      };

      sensorStats = Object.values(
        sensorPoints.reduce(
          (acc, p, i) => {
            const value = sensorValues[i];

            const bucketIndex = colorBuckets
              .buckets()
              .findIndex((c) => value > c.from && value <= c.to);

            if (bucketIndex === -1) return acc;

            const bucket = colorBuckets.buckets()[bucketIndex];

            const uniqueName = `${bucket.from}-${bucket.to}`;
            if (!(uniqueName in acc)) {
              acc[uniqueName] = {
                name:
                  bucket.to === Infinity
                    ? `${bucket.from} - ${maxValue} dB(A)`
                    : `${Math.max(0, bucket.from)} to ${bucket.to} dB(A)`,
                sensors: [],
                color: bucket.color,
                index: bucketIndex,
              };
            }
            acc[uniqueName].sensors.push(p);
            return acc;
          },
          {} as Record<string, SensorStatsType & { index: number }>,
        ),
      ).sort((a, b) => a.index - b.index);
    }

    return { sensorStats, maxValue: maxSensorValue };
  },
);

export const noiseAnalysisBoundary = atom<Promise<PolygonFeature>>(
  async (get) => {
    const id = await get(noiseIdAtom);
    if (!id) return get(suspendAtom);

    const boundary = await get(boundaryAtom({ id }));
    if (!boundary) return get(suspendAtom);

    return boundary;
  },
);

export const noiseAnalysisGeotiff = atom(async (get) => {
  const id = await get(noiseIdAtom);
  if (!id) return get(suspendAtom);

  const { presignedUrl } = await get(getNoiseAnalysisComplete({ id }));

  const geotiff = await get(fetchRasterCacheAtom({ url: presignedUrl }));

  const image = await geotiff.getImage();
  const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
  const epsg =
    image.geoKeys.ProjectedCSTypeGeoKey || image.geoKeys.GeographicTypeGeoKey;

  let square = [
    { lng: bbox[0], lat: bbox[1] },
    { lng: bbox[2], lat: bbox[1] },
    { lng: bbox[2], lat: bbox[3] },
    { lng: bbox[0], lat: bbox[3] },
  ];

  if (epsg !== 4326) {
    const proj4String = await get(getProj4StringForEPSGSelectorFamily(epsg));
    if (!proj4String) throw scream("Unknown epsg", { epsg });
    square = transformBBOXToWGS84Square(bbox, proj4String);
  }

  const data = await image.readRasters();

  const canvas = document.createElement("canvas");
  canvas.width = data.width;
  canvas.height = data.height;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  var imgData = ctx.createImageData(data.width, data.height);

  const array = data[0] as TypedArray;

  const buckets = await get(colorBucketsAtom);

  let offset = 0;
  for (var i = 0; i < array.length; i++) {
    let color = buckets.get(array[i]);
    if (isNaN(array[i])) {
      color = Color.Transparent();
    }
    imgData.data[i + 0 + offset] = color.r;
    imgData.data[i + 1 + offset] = color.g;
    imgData.data[i + 2 + offset] = color.b;
    imgData.data[i + 3 + offset] = color.a;
    offset += 3;
  }

  ctx.putImageData(imgData, 0, 0);

  const rotatedImage = canvas.toDataURL("image/png");

  return {
    dataURL: rotatedImage,
    bbox: [...square.map((point) => [point.lng, point.lat]).reverse()],
  };
});

const colorBucketsAtom = atom<Promise<Buckets>>(async (get) => {
  const { redBoundary, yellowBoundary } = await get(noiseDisplaySettingsAtom);

  return new Buckets(Color.Transparent(), [
    [COLORS.yellow, yellowBoundary],
    [COLORS.red, redBoundary],
  ]);
});

const fetchRasterCacheAtom = atomFamily(({ url }: { url: string }) =>
  atom<Promise<GeoTIFF>>(() => {
    return fetchRaster(url);
  }),
);

async function fetchRaster(url: string) {
  const res = await fetchEnhancer(url);
  if (!res.ok) {
    throw scream("Unable to read noise analysis tiff", { res });
  }

  const blob = await res.blob();

  return fromBlob(blob);
}
