import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, {
  Suspense,
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Fuse from "fuse.js";
import Bin from "@icons/24/Bin.svg";
import { spaceMedium } from "styles/space";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  member_extendedUsersInOrganisationSelector,
  Member_MemberWithGroupMemberships,
} from "../state";
import OrgRoleDropdown from "./OrgRoleDropdown";
import { SortState, Field, Order } from "./types";
import { loggedInUserIdAtom } from "state/user";
import { useOrgMemberCrud } from "./useOrgMemberCrud";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { SortWrapper, TableHeader } from "../OrganisationRightSide/style";
import { Anchor } from "components/General/Anchor";
import OrganisationAccessOverviewModal from "components/AccessOverview/OrganisationAccessOverviewModal";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { allOrganisationInvitationsAtomFamily } from "state/customer";
import { dateToDateTime } from "utils/utils";
import { useDeleteInvitation } from "hooks/useUser";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import { SearchInput } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import { OrganisationInvite } from "types/customer";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { TableText, Member_MemberGrid, Member_InvitesGrid } from "./style";
import { QuestionIconComp } from "components/AccessOverview/shared";
import { GroupsTooltip } from "./GroupsTooltip";
import { LoadingUsersSkeletons, RoundHourglassIcon, TableCell } from "./shared";
import { IconBtn } from "components/General/Icons";
import { IconREMSize } from "styles/typography";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useScrollSelectedIntoView } from "./hooks/useScrollSelectedIntoView";

const Member_MembersTable = ({ searchValue }: { searchValue: string }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const orgRoleRef = useRef<HTMLDivElement>(null);
  const [sortState, setSortState] = useState<SortState>({
    field: "nickname",
    order: "ASC",
  });

  const [showOrgRoleAccessModal, setOrgRoleAccessModal] = useState(false);

  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  useEffect(() => {
    setContent((cur) => (cur?.type === "user" ? cur : undefined));
  }, [setContent]);

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  return (
    <Member_MemberGrid>
      <TableHeader
        onClick={() => toggleSort("nickname")}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Name
        <IconREMSize width={1.2} height={1.2}>
          {sortState.field === "nickname" && sortState.order === "ASC" ? (
            <DirectionDown />
          ) : sortState.field === "nickname" && sortState.order === "DESC" ? (
            <DirectionUp />
          ) : (
            <SortWrapper>
              <DirectionDown />
            </SortWrapper>
          )}
        </IconREMSize>
      </TableHeader>
      <TableHeader
        ref={orgRoleRef}
        onClick={() => toggleSort("org_access")}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Organisation role
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setOrgRoleAccessModal((cur) => !cur);
          }}
        />
        <IconREMSize width={1.2} height={1.2}>
          {sortState.field === "org_access" && sortState.order === "ASC" ? (
            <DirectionDown />
          ) : sortState.field === "org_access" && sortState.order === "DESC" ? (
            <DirectionUp />
          ) : (
            <SortWrapper>
              <DirectionDown />
            </SortWrapper>
          )}
        </IconREMSize>
      </TableHeader>
      {showOrgRoleAccessModal && (
        <Anchor
          baseRef={orgRoleRef}
          floatPlace="topLeft"
          basePlace="bottomLeft"
        >
          <OrganisationAccessOverviewModal
            onClose={() => setOrgRoleAccessModal(false)}
          />
        </Anchor>
      )}

      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Groups
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      />

      <Suspense fallback={<LoadingUsersSkeletons nrRows={4} nrColumns={5} />}>
        <Member_MemberRows sortState={sortState} searchValue={searchValue} />
      </Suspense>
    </Member_MemberGrid>
  );
};

const Member_InvitesTable = ({ searchValue }: { searchValue: string }) => {
  const orgRoleRef = useRef<HTMLDivElement>(null);
  const [showOrgRoleAccessModal, setOrgRoleAccessModal] = useState(false);

  return (
    <Member_InvitesGrid>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
        }}
      >
        Name
      </TableHeader>
      <TableHeader
        ref={orgRoleRef}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
        }}
      >
        Organisation role
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setOrgRoleAccessModal((cur) => !cur);
          }}
        />
      </TableHeader>
      {showOrgRoleAccessModal && (
        <Anchor
          baseRef={orgRoleRef}
          floatPlace="topLeft"
          basePlace="bottomLeft"
        >
          <OrganisationAccessOverviewModal
            onClose={() => setOrgRoleAccessModal(false)}
          />
        </Anchor>
      )}

      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
        }}
      >
        Groups
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
        }}
      >
        Invitation expires
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
        }}
      />

      <Suspense fallback={<LoadingUsersSkeletons nrRows={4} nrColumns={5} />}>
        <Member_InvitesRows searchValue={searchValue} />
      </Suspense>
    </Member_InvitesGrid>
  );
};

const Member_InvitesRows = ({ searchValue }: { searchValue: string }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const customerInvites = useAtomValue(
    allOrganisationInvitationsAtomFamily(organisationId),
  );
  const deleteInvite = useDeleteInvitation(organisationId);
  const { showConfirm } = useConfirm();
  const invitesFuse = useMemo(
    () =>
      new Fuse(customerInvites, {
        keys: ["email"],
        includeScore: true,
        threshold: 0.3,
      }),
    [customerInvites],
  );

  const invitesSearchResult = useMemo<OrganisationInvite[]>(() => {
    return searchValue.length > 0
      ? invitesFuse.search(searchValue).map((result) => result.item)
      : customerInvites;
  }, [invitesFuse, searchValue, customerInvites]);

  return invitesSearchResult.map((invite) => (
    <Fragment key={invite.invitationId}>
      <TableCell
        style={{
          minHeight: "4.4rem",
          display: "flex",
          alignItems: "center",
          gap: spaceMedium,
        }}
      >
        <RoundHourglassIcon />
        <TableText>{invite.email}</TableText>
      </TableCell>
      <TableCell
        style={{
          textTransform: "capitalize",
        }}
      >
        <TableText>{invite.organisationRole}</TableText>
      </TableCell>
      <TableCell>
        {invite.groupIds.length > 0 ? (
          <GroupsTooltip
            groupIds={invite.groupIds}
            organisationId={organisationId}
          >
            <TableText>
              {invite.groupIds.length} group
              {invite.groupIds.length !== 1 ? "s" : ""}
            </TableText>
          </GroupsTooltip>
        ) : (
          <TableText>None</TableText>
        )}
      </TableCell>
      <TableCell>
        <TableText>
          Expires {dateToDateTime(new Date(invite.expiration * 1000))}
        </TableText>
      </TableCell>
      <TableCell
        style={{
          width: "100%",
          justifyContent: "flex-end",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <IconBtn
          size="1.4rem"
          onClick={async () => {
            if (
              await showConfirm({
                title: "Remove invitation",
                message: `Are you sure you want to remove the invitation from your organisation? The user will lose access to all resources and projects of this organisation.`,
                confirmButtonText: "Remove",
              })
            ) {
              deleteInvite(invite.invitationId);
            }
          }}
        >
          <Bin />
        </IconBtn>
      </TableCell>
    </Fragment>
  ));
};

function Member_MemberRows({
  sortState,
  searchValue,
}: {
  sortState: SortState;
  searchValue: string;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const members = useAtomValue(member_extendedUsersInOrganisationSelector);
  const { showConfirm } = useConfirm();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const currentUser = useAtomValue(loggedInUserIdAtom);
  const { leaveOrganisation } = useOrgMemberCrud(organisationId);

  const sortedMembers = useMemo(() => {
    let allMembers = [...members];
    const field = sortState.field;
    if (sortState.order === "NONE" || !field) {
      return [...allMembers];
    }
    return [...allMembers].sort((a, b) => {
      const fieldA = a[field]?.toLowerCase();
      const fieldB = b[field]?.toLowerCase();
      if (!fieldA) return -1;
      if (!fieldB) return 1;
      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [members, sortState.field, sortState.order]);

  const membersFuse = useMemo(
    () =>
      new Fuse(members, {
        keys: ["nickname", "email"],
        includeScore: true,
        threshold: 0.3,
      }),
    [members],
  );

  const usersSearchResult = useMemo<Member_MemberWithGroupMemberships[]>(() => {
    return searchValue.length > 0
      ? membersFuse.search(searchValue).map((result) => result.item)
      : sortedMembers;
  }, [membersFuse, searchValue, sortedMembers]);

  const selectedRowRef = useRef<HTMLDivElement>(null);
  useScrollSelectedIntoView(selectedRowRef, content?.type === "user");

  return (
    <>
      {usersSearchResult.map((u) => {
        const isSelected = content?.id === u.user_id;
        return (
          <Fragment key={u.user_id}>
            <TableCell
              ref={isSelected ? selectedRowRef : undefined}
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: spaceMedium,
              }}
            >
              <UserInfoOrganisationWithMemberLink userId={u.user_id} />
            </TableCell>
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <OrgRoleDropdown user={u} />
            </TableCell>
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {u.groupMemberships.length > 0 ? (
                <GroupsTooltip
                  groupIds={u.groupMemberships.map(
                    (membership) => membership.group_id,
                  )}
                  organisationId={organisationId}
                >
                  <TableText>
                    {u.groupMemberships.length} group
                    {u.groupMemberships.length !== 1 ? "s" : ""}
                  </TableText>
                </GroupsTooltip>
              ) : (
                <TableText>None</TableText>
              )}
            </TableCell>
            {u.user_id === currentUser ? (
              <TableCell
                selected={isSelected}
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  boxSizing: "border-box",
                  alignItems: "center",
                }}
              >
                <Button
                  text="Leave"
                  onClick={async () => {
                    if (
                      await showConfirm({
                        title: "Leave organisation",
                        message: `Are you sure you want to leave the organisation? You will lose access to all resources and projects of this organisation.`,
                        confirmButtonText: "Leave",
                      })
                    ) {
                      leaveOrganisation();
                    }
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                  size="small"
                />
              </TableCell>
            ) : (
              <TableCell selected={isSelected} />
            )}
          </Fragment>
        );
      })}
    </>
  );
}

const Member_OrganisationMembers = ErrorBoundaryWrapper(
  ({ onlyPending }: { onlyPending?: boolean }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");

    const setContent = useSetAtom(organisationRightSideModal(organisationId));
    useEffect(() => {
      setContent((cur) => (cur?.type === "user" ? cur : undefined));
    }, [setContent]);

    return (
      <Column
        style={{
          padding: "2.4rem 0 8rem",
          gap: "2.4rem",
        }}
      >
        <Row>
          <SearchInput
            placeholder="Search members"
            value={searchValue}
            onChange={onSearchValueChange}
            onClear={() => setSearchValue("")}
            style={{
              width: "40rem",
            }}
          />
        </Row>
        {!onlyPending && <Member_MembersTable searchValue={searchValue} />}
        {onlyPending && <Member_InvitesTable searchValue={searchValue} />}
      </Column>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default Member_OrganisationMembers;
