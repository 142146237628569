import { useEffect } from "react";
import { cableSourceId } from "components/Mapbox/constants";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atom, useAtomValue } from "jotai";
import { atomFamily } from "utils/jotai";
import { cableTypesFamily } from "state/jotai/cableType";
import { cablesFamily } from "state/jotai/cable";
import { ValidationWarning } from "state/validationWarnings";

export const cableTypeInvalidWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.CableTypeInvalid> | undefined
      >
    >(async (get) => {
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));

      const cables = await get(cablesFamily({ branchId }));
      const invalids = cables.filter(
        (c) => !cableTypes.has(c.properties.cableTypeId ?? ""),
      );
      if (invalids.length === 0) return;
      return {
        type: ValidationWarningTypes.CableTypeInvalid,
        featureIds: invalids.map((c) => c.id),
      };
    }),
);

export const CableTypeInvalidJotai = ({
  map,
  source = cableSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const warning = useAtomValue(
    cableTypeInvalidWarningFamily({ branchId: undefined }),
  );
  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds) {
        map.setFeatureState({ source, id }, { error: true });
      }
    return () => {
      if (map.getSource(source))
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "error");
    };
  }, [warning, map, source]);

  return null;
};
