import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Grid2, Label } from "components/General/Form";
import {
  InputTitle,
  InputTitleWrapper,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { Row } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { SkeletonBlock } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import {
  newFoundationNodeId,
  showNewFoundationWizardAtom,
} from "state/foundations";
import {
  simpleTurbineTypesAtom,
  simpleTurbineTypesByRatingFamily,
} from "state/jotai/turbineType";
import { spaceLarge, spaceMedium } from "styles/space";
import {
  _PileData,
  DetailedMonopileType,
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
  SoilStiffnessLevels,
} from "types/foundations";
import { DEFAULT_OFFSHORE_TURBINES, SimpleTurbineType } from "types/turbines";
import { between } from "utils/validations";
import { PileData } from "types/foundations";
import { fetchSchemaWithToken } from "services/utils";
import Spinner from "@icons/spinner/Spinner";
import { useProjectFoundationCrud } from "components/ConfigurationModal/FoundationSettings/useProjectFoundationCrud";

export const MonopileWizard = ({
  defaultMonopile,
  turbineType,
  parkMaxDepth,
}: {
  defaultMonopile: DetailedMonopileType;
  turbineType: SimpleTurbineType;
  parkMaxDepth: number;
}) => {
  const setShowNewFoundationWizard = useSetAtom(showNewFoundationWizardAtom);
  const foundationTypeNodeId = useAtomValue(newFoundationNodeId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [metoceanOverride, setMetoceanOverride] = useState<boolean>(false);
  const [turbineTypeId, setTurbineTypeId] = useState<string>(turbineType.id);
  const [soilStiffnessOverride, setSoilStiffnessOverride] =
    useState<boolean>(false);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [tempFoundation, setTempFoundation] =
    useState<DetailedMonopileType>(defaultMonopile);

  useEffect(() => {
    setTempFoundation(defaultMonopile);
    setHasRun(false);
  }, [defaultMonopile]);

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbineTypesOrder = useAtomValue(simpleTurbineTypesByRatingFamily);

  const generateMonopile = useCallback(
    async (tempFoundation: DetailedMonopileType, turbineTypeId: string) => {
      const turbineType = allTurbineTypes.get(turbineTypeId)!;
      const { soilCoeffSubReact, waterDepth, hs50Yr } = tempFoundation;
      setIsLoading(true);

      const {
        hubHeight,
        diameter: rotorDiameter,
        peakThrustSpeed,
        peakThrustCt,
        rnaMass,
      } = turbineType;

      const ratedWindSpeed = peakThrustSpeed ?? 11;
      const ctRated = peakThrustCt ?? 0.8;

      const pileData = await getSiteSpecificMonopile({
        hubHeight,
        rotorDiameter,
        ratedWindSpeed,
        ctRated,
        rnaMass,
        soilCoeffSubReact,
        waterDepth,
        hs50Yr,
      });

      if (pileData) {
        const { pileDiameter, avgPileThickness, embedLength, totalPileLength } =
          pileData;
        setTempFoundation({
          ...tempFoundation,
          pileDiameter,
          avgPileThickness,
          embedLength,
          totalPileLength,
        });
      } else setHasError(true);
      setIsLoading(false);
      setHasRun(true);
    },
    [allTurbineTypes],
  );

  const { create, isLoadingProject } = useProjectFoundationCrud();

  const onSave = useCallback(
    async (newFoundation: FoundationType) => {
      if (!foundationTypeNodeId) return;
      setIsLoading(true);
      setShowNewFoundationWizard(false);
      await create({ foundation: newFoundation });
    },
    [foundationTypeNodeId, create, setIsLoading, setShowNewFoundationWizard],
  );

  return (
    <>
      {!hasRun && (
        <>
          <Label
            style={{
              paddingTop: "1.6rem",
            }}
          >
            <InputTitle>Turbine type</InputTitle>
            <Dropdown
              small
              id="turbine"
              value={turbineTypeId}
              onChange={(e) => {
                const newId = e.target.value;
                setTurbineTypeId(newId);
                const newType = allTurbineTypes.get(newId);
                if (newType)
                  setTempFoundation({
                    ...tempFoundation,
                    ratedPower: newType.ratedPower,
                    rotorDiameter: newType.diameter,
                    hubHeight: newType.hubHeight,
                    rnaMass: newType.rnaMass,
                  });
              }}
            >
              {turbineTypesOrder.map((t) => {
                const isDefaultTurbine = DEFAULT_OFFSHORE_TURBINES.some(
                  (defaultTurbine) => defaultTurbine.id === t.id,
                );

                return (
                  <option key={t.id} value={t.id} disabled={t.archived}>
                    {t.name} {isDefaultTurbine ? "(standard)" : ""}
                  </option>
                );
              })}
            </Dropdown>
          </Label>

          <SubtitleWithLine
            text={"Metocean parameters"}
            style={{
              paddingTop: "0.8rem",
              paddingBottom: "0.8rem",
            }}
          />
          <div
            style={{
              paddingBottom: "1.6rem",
            }}
          >
            <InputTitleWrapper>
              <InputTitle>Override park values</InputTitle>
              <Toggle
                size={ToggleSize.SMALL}
                checked={metoceanOverride}
                onChange={() => {
                  setMetoceanOverride(!metoceanOverride);
                }}
              />
            </InputTitleWrapper>
          </div>

          <Grid2>
            <InputTitle>Water depth</InputTitle>
            <InputTitle>50-year Hs</InputTitle>
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              validate={between(
                FoundationMinDepths.detailed_monopile,
                FoundationMaxDepths.detailed_monopile,
              )}
              validationMessage={`Needs to be within ${FoundationMinDepths.detailed_monopile} - ${FoundationMaxDepths.detailed_monopile} m`}
              step="1"
              unit="m"
              type="number"
              value={
                Math.round(
                  10 *
                    Math.min(
                      tempFoundation.waterDepth,
                      FoundationMaxDepths.detailed_monopile,
                    ),
                ) / 10
              }
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  waterDepth: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              compact
              disabled={!metoceanOverride}
              validate={between(1, 20)}
              validationMessage={`Needs to be within 1 - 20 m`}
              step="0.1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.hs50Yr) / 10}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  hs50Yr: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
          </Grid2>
          {parkMaxDepth > FoundationMaxDepths.detailed_monopile &&
            !metoceanOverride && (
              <SimpleAlert
                style={{
                  margin: "1.6rem",
                }}
                text={`Park depth is too large for monopile (> ${FoundationMaxDepths.detailed_monopile} m).`}
                type={"error"}
              ></SimpleAlert>
            )}
          <SubtitleWithLine text={"Soil stiffness"} />
          <Label>
            <RadioGroup
              style={{
                padding: `${spaceMedium} 0 ${spaceMedium} 0`,
              }}
            >
              <Radio
                label="Use general levels"
                checked={!soilStiffnessOverride}
                onChange={() => {
                  setSoilStiffnessOverride(false);
                  setTempFoundation({
                    ...tempFoundation,
                    soilCoeffSubReact: SoilStiffnessLevels.medium,
                  });
                }}
              />
              <Radio
                label="Override"
                style={{
                  marginLeft: 10,
                }}
                checked={soilStiffnessOverride}
                onChange={() => {
                  setSoilStiffnessOverride(true);
                }}
              />
            </RadioGroup>

            <Grid2>
              <InputTitle>Soil stiffness</InputTitle>
              <Row>
                <InputTitle>Sub. reaction coeff.</InputTitle>
                <HelpTooltip
                  size={10}
                  text="Coefficient of subgrade reaction for the soil."
                />
              </Row>
              <Dropdown
                small
                disabled={soilStiffnessOverride}
                id="foundation-soil-stiffness"
                value={
                  soilStiffnessOverride
                    ? SoilStiffnessLevels.medium
                    : tempFoundation.soilCoeffSubReact
                }
                onChange={(e) => {
                  setTempFoundation({
                    ...tempFoundation,
                    soilCoeffSubReact: parseFloat(e.target.value),
                  });
                }}
                style={{
                  width: "100%",
                }}
              >
                <option value={SoilStiffnessLevels.soft}>{"Soft"}</option>
                <option value={SoilStiffnessLevels.medium}>{"Medium"}</option>
                <option value={SoilStiffnessLevels.stiff}>{"Stiff"}</option>
              </Dropdown>
              <InputDimensioned
                compact
                disabled={!soilStiffnessOverride}
                validate={between(1000, 20_000)}
                validationMessage={`Needs to be within 1000 - 20 000 kN/m³`}
                step="100"
                unit="kN/m³"
                type="number"
                value={
                  ((1 / 1000) *
                    Math.round(0.01 * tempFoundation.soilCoeffSubReact)) /
                  0.01
                }
                onChange={(soilStiffnesskN) => {
                  setTempFoundation({
                    ...tempFoundation,
                    soilCoeffSubReact: soilStiffnesskN * 1000,
                  });
                }}
                style={{
                  width: "100%",
                }}
              />
            </Grid2>
          </Label>
        </>
      )}
      {!hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading || isLoadingProject}
            style={{
              alignSelf: "end",
            }}
            title="Calculate monopile design."
            text="Calculate"
            onClick={() => {
              generateMonopile(tempFoundation, turbineTypeId);
            }}
            icon={
              isLoading || isLoadingProject ? (
                <Spinner size="1rem" />
              ) : undefined
            }
          />
        </div>
      )}
      {hasRun && !hasError && (
        <Label>
          <h4
            style={{
              margin: `${spaceMedium} 0`,
            }}
          >
            Calculated monopile design
          </h4>
          <Grid2
            style={{
              padding: `${spaceMedium} 0 ${spaceMedium} 0`,
            }}
          >
            <p>Pile diameter</p>
            <p>Avg. pile thickness</p>
            <InputDimensioned
              validate={between(1, 25)}
              validationMessage={`Needs to be within 1 - 25 m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.pileDiameter) / 10}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  pileDiameter: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              validate={between(0.01, 0.5)}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              step="0.01"
              unit="m"
              type="number"
              value={Math.round(1000 * tempFoundation.avgPileThickness) / 1000}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  avgPileThickness: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <p>Embedded length</p>
            <p>Total pile length</p>
            <InputDimensioned
              validate={between(10, 100)}
              validationMessage={`Needs to be within 10 - 100 m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.embedLength) / 10}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  embedLength: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
            <InputDimensioned
              validate={between(20, 150)}
              validationMessage={`Needs to be within 20 - 150 m`}
              step="1"
              unit="m"
              type="number"
              value={Math.round(10 * tempFoundation.totalPileLength) / 10}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  totalPileLength: val,
                });
              }}
              style={{
                width: "100%",
              }}
            />
          </Grid2>
        </Label>
      )}
      {hasRun && hasError && (
        <Label style={{ marginTop: spaceMedium }}>
          <SimpleAlert
            text={`Could not find a feasible monopile design for the selected input.`}
            type={"error"}
          ></SimpleAlert>
        </Label>
      )}
      {hasRun && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "baseline",
            gap: spaceMedium,
            paddingTop: spaceLarge,
          }}
        >
          <Button
            disabled={isLoading || isLoadingProject}
            style={{
              alignSelf: "end",
            }}
            buttonType={"secondary"}
            text="Back"
            onClick={() => {
              setHasRun(false);
              setHasError(false);
            }}
          />
          <Button
            disabled={isLoading || isLoadingProject || hasError}
            style={{
              alignSelf: "end",
            }}
            title="Create foundation type."
            text="Create"
            onClick={() => {
              onSave(tempFoundation);
            }}
          />
        </div>
      )}
      {isLoading && (
        <SkeletonBlock
          style={{
            flex: 1,
          }}
        />
      )}
    </>
  );
};

async function getSiteSpecificMonopile({
  hubHeight,
  rotorDiameter,
  ratedWindSpeed,
  ctRated,
  rnaMass,
  soilCoeffSubReact,
  waterDepth,
  hs50Yr,
}: {
  hubHeight: number;
  rotorDiameter: number;
  ratedWindSpeed: number;
  ctRated: number;
  rnaMass: number;
  soilCoeffSubReact: number;
  waterDepth: number;
  hs50Yr: number;
}): Promise<PileData> {
  const options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      hubHeight,
      rotorDiameter,
      ratedWindSpeed,
      ctRated,
      rnaMass,
      soilCoeffSubReact,
      waterDepth,
      hs50Yr,
    }),
  };
  return await fetchSchemaWithToken(
    _PileData,
    `/api/octopus/foundation/monopile`,
    options,
  ).catch(() => {
    return null;
  });
}
