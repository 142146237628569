import { Row } from "components/General/Layout";
import GoBackIcon from "@icons/24/GoBack.svg?react";
import { IconREMSize, typography } from "styles/typography";
import styled from "styled-components";
import { colors } from "styles/colors";

const GoToPageTitle = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary}
`;

export default function GoBack({
  onClick,
  text,
}: {
  onClick: () => void;
  text?: string;
}) {
  return (
    <Row
      style={{ alignItems: "center", cursor: "pointer", width: "fit-content" }}
      onClick={onClick}
    >
      <IconREMSize height={1.2} width={1.2}>
        <GoBackIcon />
      </IconREMSize>
      <GoToPageTitle style={{ margin: 0 }}>
        {text ? text : "Go back"}
      </GoToPageTitle>
    </Row>
  );
}
