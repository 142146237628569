import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { z } from "zod";
import { ABLY_COST_CONFIG_DELETE, ABLY_COST_CONFIG_UPDATE } from "state/ably";
import useOrgFinancialCrud from "components/Organisation/Library/financial/useOrgFinancialCrud";
import { _CostConfiguration } from "services/costService";

export function useAblyLibraryCostConfig(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgFinancialCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_financial_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const costConfig = _CostConfiguration.parse(message.data.configuration);
      updateLocal(costConfig);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const costConfigId = z.string().parse(message.data.meta);
      removeLocal(costConfigId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_COST_CONFIG_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_COST_CONFIG_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
