import { PopupContainer } from "./style";
import { CSSProperties, ReactNode, useRef } from "react";
import { AnchorClassName } from "components/General/Anchor";
import { useClickOutside } from "hooks/useClickOutside";

export function ChangelogPopup({
  close,
  children,
  style,
}: {
  close: () => void;
  children: ReactNode;
  style?: CSSProperties;
}) {
  const markerContentRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    markerContentRef,
    () => {
      close();
    },
    (target) =>
      target instanceof HTMLElement &&
      target.classList.contains(AnchorClassName),
    { ignoreDragClicks: true },
  );

  return (
    <PopupContainer style={style} ref={markerContentRef}>
      {children}
    </PopupContainer>
  );
}

export function CommentPopup({
  close,
  children,
  style,
  className,
}: {
  close: () => void;
  children: ReactNode;
  style?: CSSProperties;
  className: string;
}) {
  const markerContentRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    markerContentRef,
    () => {
      close();
    },
    (target) =>
      target instanceof HTMLElement && target.classList.contains(className),

    { ignoreDragClicks: true },
  );

  return (
    <PopupContainer style={style} ref={markerContentRef}>
      {children}
    </PopupContainer>
  );
}
