import { lockedPropertyName } from "@constants/canvas";
import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { MooringLineFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_MOORING_VISIBLE_ZOOM,
  editmodePropertyName,
  ghostPropertyName,
} from "./constants";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import { mooringLineSourceId, mooringLineLayerId } from "./constants";
import { READ, caseexpr } from "./defaults";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const mooringLinePaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    colors.mooringLinePreview,
    caseexpr({
      state: {
        borderColor: READ,
      },
      fallback: colors.mooringLine,
    }),
  ],
  "line-width": caseexpr({
    fallback: 2,
    hover: 4,
    selected: 4,
    get: {
      [editmodePropertyName]: 4,
      [lockedPropertyName]: 2,
      borderColor: 4,
    },
  }),
  "line-dasharray": [
    "case",
    ["==", ["get", editmodePropertyName], true],
    ["literal", [1.25, 2]],
    ["literal", [1, 0]],
  ],
  "line-opacity": caseexpr({
    fallback: 0.3,
    hover: 0.8,
    active: 1,
    selected: 1,
    get: {
      [editmodePropertyName]: 1,
      [ghostPropertyName]: 0.1,
    },
  }),
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  mooringLineLayerId,
  mooringLineSourceId,
  MIN_MOORING_VISIBLE_ZOOM,
);

export const RenderMooringLines = ({
  mooringLines,
  map,
  showSelectionHighlighter,
}: {
  mooringLines: MooringLineFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(
    () => mooringLines.map((f) => f.id),
    [mooringLines],
  );

  useEffect(() => {
    map.addSource(mooringLineSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, mooringLineLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(mooringLineSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: mooringLineLayerId,
      type: "line",
      source: mooringLineSourceId,
      paint: mooringLinePaint,
      minzoom: MIN_MOORING_VISIBLE_ZOOM,
    });
    addLayer(map, hiddenClickLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(mooringLineSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(mooringLines),
    });
  }, [map, mooringLines]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={mooringLineSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
