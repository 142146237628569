import { getOrganisationLatestUpdate } from "components/InputChangelog/service";
import { ChangelogEntry } from "./type";
import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";

const organisationResourceLastUpdateRefresherAtom = atom<number>(0);

export const organisationResourceLastUpdateSelectorFamily = atomFamily(
  ({
    organisationId,
    resourceIds,
  }: {
    organisationId: string;
    resourceIds: string[];
  }) =>
    atomFromFn<Promise<Record<string, ChangelogEntry>>>(async (get) => {
      get(organisationResourceLastUpdateRefresherAtom);

      const items = await getOrganisationLatestUpdate(
        organisationId,
        resourceIds,
      );

      return items.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      );
    }),
);
