import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { TurbineLoss, TurbineType } from "../../../types/turbines";
import { Input, InputDimensioned, TextArea } from "../../General/Input";
import { TextIcon } from "../../../styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { Column, Row } from "../../General/Layout";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { Label as _Label } from "../../General/Form";
import {
  validCurves,
  formatCurveToText,
  textToCurve,
  formatInput,
} from "../../SettingsV2/FeatureSettings/Data/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ColumnSection,
  ContentWrapper,
  HorizontalLine,
  ModalContainer,
  SecondaryText,
  SettingButton,
  Subtitle,
  TitleContainer,
} from "../../SettingsV2/Shared/styles";
import Button from "../../General/Button";
import { inReadOnlyModeSelector } from "../../../state/project";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { isApproxBetween } from "../../../utils/geometry";
import { unsavedSettingsState } from "../../SettingsV2/Shared/state";
import { IAVoltageType } from "../../../services/cableTypeService";
import Checkbox from "../../General/Checkbox";
import {
  dedup,
  mergePreservingChanges,
  objectEquals,
} from "../../../utils/utils";
import { spaceLarge, spacing16 } from "../../../styles/space";
import { spaceTiny } from "../../../styles/space";
import { rotorTowerSizing } from "../../../functions/rotorTowerSizing";
import { toastMessagesAtom } from "../../../state/toast";
import useEnterToSave from "../useEnterToSave";
import {
  idToTurbineChangelogId,
  turbineChangelogInfo,
} from "components/InputChangelog/const";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import ComponentLastChanged from "../SettingsUsage/ComponentLastChanged";
import { ChangelogCategory } from "components/InputChangelog/types";
import { useDuplicateTurbineToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useSetAtom } from "jotai";
import {
  DetailSecondary,
  HeaderContainer,
  Detail,
  HeaderWrapper,
} from "../shared";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  advancedProjectTurbineTypesFamily,
  advancedTurbineTypesFamily,
} from "state/jotai/turbineType";
import {
  RedirectToProjectFinancialConfig,
  TurbineCostElement,
} from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { isOnshoreAtom } from "state/onshore";
import DescriptionModal from "../DescriptionModal";
import { loadable } from "jotai/utils";
import LibraryResourceActions from "../Components/LibraryResourceActions";
import FreeText from "../Components/FreeText";
import ProjectResourceUsage from "../SettingsUsage/ProjectResourceUsage";
import usePrevious from "hooks/usePrevious";

// Lazy load to prevent plotly being in main.js
const TurbineGraph = React.lazy(() => import("../TurbineGraph"));
const MAX_LOSS = 0.2;
const MAX_NUMBER_OF_LOSSES = 3;

const scaleTonne = 1 / 1000;

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

export const validateRnaMass = ({
  rnaMass,
  diameter,
}: {
  rnaMass: number;
  diameter: number;
}) => {
  const { rnaMass: estimation } = rotorTowerSizing(diameter);
  const [from, to] = [estimation * 0.5, estimation * 1.5];

  return isApproxBetween(from, rnaMass, to);
};

export const TurbineSettingAdvancedProjectAdmin = ErrorBoundaryWrapper(
  ({
    turbineId,
    onSave,
    nodeId,
  }: {
    turbineId: string;
    onSave?: (
      turbine: TurbineType,
      onlyUpdatingDescription?: boolean,
    ) => Promise<any>;
    nodeId: string;
  }) => {
    const advancedTurbine = useAtomValue(
      loadable(advancedProjectTurbineTypesFamily({ nodeId, turbineId })),
    );

    const turbine =
      advancedTurbine.state === "hasData" ? advancedTurbine.data : undefined;

    return (
      <TurbineSettingCustomAdmin
        key={turbine?.id}
        turbine={turbine}
        isLoading={advancedTurbine.state === "loading"}
        onSave={onSave}
        nodeId={nodeId}
        readOnly={false}
        commentCategory="project"
        turbineCostElement={
          <RedirectToProjectFinancialConfig menuId={TURBINE_MENU_ID} />
        }
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export const TurbineSettingAdvancedLibraryManage = ErrorBoundaryWrapper(
  ({ turbineId, nodeId }: { turbineId: string; nodeId: string }) => {
    const { duplicateToProject, isLoading: isLoadingDuplicate } =
      useDuplicateTurbineToProject();

    const advancedTurbine = useAtomValue(
      loadable(advancedTurbineTypesFamily({ nodeId, turbineId })),
    );

    const turbine =
      advancedTurbine.state === "hasData" ? advancedTurbine.data : undefined;

    return (
      <TurbineSettingCustomAdmin
        key={turbine?.id}
        turbine={turbine}
        isLoading={advancedTurbine.state === "loading"}
        nodeId={nodeId}
        readOnly={true}
        commentCategory="org_turbine_manage"
        duplicateToProject={duplicateToProject}
        isLoadingDuplicate={isLoadingDuplicate}
        turbineCostElement={
          advancedTurbine.state === "hasData" && advancedTurbine.data ? (
            <TurbineCostElement turbineType={advancedTurbine.data} />
          ) : (
            <></>
          )
        }
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

const excludedKeys = ["createdAt", "updatedAt", "description"];

const TurbineSettingCustomAdmin = ErrorBoundaryWrapper(
  ({
    turbine,
    onSave,
    isLoading,
    nodeId,
    readOnly,
    commentCategory,
    duplicateToProject,
    isLoadingDuplicate,
    turbineCostElement,
  }: {
    turbine: TurbineType | undefined;
    onSave?: (
      turbine: TurbineType,
      onlyUpdatingDescription?: boolean,
    ) => Promise<any>;
    isLoading: boolean;
    nodeId: string;
    readOnly: boolean;
    commentCategory: ChangelogCategory;
    duplicateToProject?: (turbineId: string, turbineName: string) => void;
    isLoadingDuplicate?: boolean;
    turbineCostElement: JSX.Element;
  }) => {
    const onshore = useAtomValue(isOnshoreAtom);

    const projectId = useAtomValue(projectIdAtom)!;
    const inLibraryContext = !projectId;

    const isReadOnly = useAtomValue(inReadOnlyModeSelector) || readOnly;

    const [isValid, setIsValid] = useState<boolean>(true);
    const [values, setValues] = useState<string>("");

    const [showFreeText, setShowFreeText] = useState<boolean>(!!turbine?.note);

    const [tempTurbine, setTempTurbine] = useState(turbine);
    const prevTurbine = usePrevious(turbine);
    useEffect(() => {
      if (turbine) {
        setTempTurbine((prevTempTurbine) => {
          if (!prevTempTurbine || !prevTurbine) return turbine;
          return mergePreservingChanges(prevTurbine, turbine, prevTempTurbine);
        });
      }
    }, [turbine, setTempTurbine, prevTurbine]);

    const hasChanged = useMemo(() => {
      if (!turbine || !tempTurbine) return false;
      return !objectEquals(turbine, tempTurbine, excludedKeys);
    }, [turbine, tempTurbine]);

    useEffect(() => {
      setShowFreeText(!!turbine?.note);
    }, [turbine]);

    const setUnsavedSettings = useSetAtom(unsavedSettingsState);
    const setToastMessages = useSetAtom(toastMessagesAtom);
    const allChangesSaved = isReadOnly || !hasChanged || !isValid;

    useEffect(() => {
      setUnsavedSettings(!isLoading && !allChangesSaved);
    }, [allChangesSaved, isLoading, setUnsavedSettings]);

    const fractionToPercentage = (fraction: number) => fraction * 100;
    const percentageToFraction = (percentage: number) => percentage / 100;

    function removeLoss(index: number) {
      if (!tempTurbine?.losses) return;
      const newLosses = [
        ...tempTurbine.losses.slice(0, index),
        ...tempTurbine.losses.slice(index + 1),
      ];
      setTempTurbine({
        ...tempTurbine,
        losses: newLosses,
      });
    }
    function updateLoss(value: number, index: number) {
      if (!tempTurbine?.losses) return;
      const newLosses = tempTurbine.losses.map((loss, i) =>
        i === index
          ? {
              ...loss,
              value,
            }
          : loss,
      );
      setTempTurbine({
        ...tempTurbine,
        losses: newLosses,
      });
    }

    useEffect(() => {
      const isValidCurves = tempTurbine ? validCurves(tempTurbine) : false;
      setIsValid(isValidCurves);
    }, [tempTurbine]);

    const _onSave = useCallback(async () => {
      if (onSave) {
        await onSave(tempTurbine!);
      }
    }, [tempTurbine, onSave]);

    useEnterToSave(
      _onSave,
      !allChangesSaved && !isLoading && Boolean(tempTurbine),
    );

    useEffect(() => {
      if (turbine) {
        setValues(
          formatCurveToText(
            turbine.windSpeed,
            turbine.power,
            turbine.thrustCoefficient,
          ),
        );
      }
    }, [setValues, turbine]);

    const onVoltageChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setTempTurbine((turbine) => {
          if (!turbine) {
            return turbine;
          }
          const currentVoltage = turbine.voltage ?? [];
          const currValue = Number(ev.target.value) as IAVoltageType;

          if (currentVoltage.includes(currValue)) {
            return {
              ...turbine,
              voltage: currentVoltage.filter((v) => v !== currValue),
            };
          }
          return {
            ...turbine,
            voltage: dedup([...currentVoltage, currValue]),
          };
        });
      },
      [setTempTurbine],
    );

    const handleUpdateTurbineDescription = useCallback(
      (description: string) => {
        if (!turbine) return;
        setTempTurbine({
          ...(tempTurbine ?? turbine),
          description,
        });

        onSave?.(
          {
            ...turbine,
            description: description,
          },
          true,
        );
      },
      [turbine, tempTurbine, onSave],
    );

    if (!tempTurbine || !turbine)
      return (
        <>
          <HeaderWrapper>
            <HeaderContainer>
              <h4
                style={{
                  margin: 0,
                }}
              >
                Loading data...
              </h4>
            </HeaderContainer>
          </HeaderWrapper>
          <SkeletonBlock
            style={{
              width: "100%",
              height: "40rem",
            }}
          />
        </>
      );

    return (
      <ModalContainer>
        {!inLibraryContext && (
          <HeaderWrapper>
            <HeaderContainer>
              <Column>
                <h3
                  style={{
                    margin: 0,
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    maxWidth: "60rem",
                  }}
                >
                  {tempTurbine.name}
                </h3>

                <Row
                  style={{
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <ComponentLastChanged
                    resourceId={turbine.id}
                    changelogId={idToTurbineChangelogId(turbine.id)}
                    nodeId={nodeId}
                    category={projectId ? "project" : "org_turbine_manage"}
                  />

                  <ProjectResourceUsage
                    resourceType="TURBINE"
                    resourceId={turbine.id}
                  />
                </Row>
              </Column>

              <DescriptionModal
                disabled={isReadOnly}
                defaultValue={tempTurbine.description ?? ""}
                updateDescription={handleUpdateTurbineDescription}
                subtitle={
                  <div>
                    <p>
                      The description will be visible for Admins and Editors in
                      projects with access to this turbine.
                    </p>
                  </div>
                }
              />
            </HeaderContainer>

            <SettingButton
              style={{
                justifyContent: "flex-end",
                position: "absolute",
                right: "2.4rem",
                top: "14.4rem",
              }}
            >
              {readOnly && duplicateToProject && (
                <LibraryResourceActions
                  resourceType="turbine"
                  resourceId={turbine.id}
                  nodeId={nodeId}
                  organisationId={nodeId}
                  onDuplicate={() =>
                    duplicateToProject(turbine.id, turbine.name)
                  }
                  isLoadingDuplicate={isLoadingDuplicate}
                />
              )}

              {!allChangesSaved && (
                <>
                  <Button
                    disabled={allChangesSaved || isLoading}
                    buttonType="text"
                    text="Cancel"
                    onClick={() => {
                      setValues(
                        formatCurveToText(
                          turbine.windSpeed,
                          turbine.power,
                          turbine.thrustCoefficient,
                        ),
                      );
                      console.log({ turbine, tempTurbine });
                      setTempTurbine(turbine);
                    }}
                    style={{
                      marginLeft: "auto",
                    }}
                  />
                  <Button
                    disabled={allChangesSaved || isLoading}
                    text="Save changes"
                    onClick={() => {
                      setToastMessages((tm) => [
                        ...tm,
                        {
                          text: "Saving...",
                          timeout: 2000,
                        },
                      ]);
                      _onSave();
                    }}
                  />
                </>
              )}
            </SettingButton>
          </HeaderWrapper>
        )}
        <ContentWrapper
          style={{
            overflowY: "auto",
            boxSizing: "border-box",
            padding: inLibraryContext ? "1.6rem 0rem" : undefined,
          }}
        >
          <ColumnContent>
            <Column
              style={{
                paddingBottom: spacing16,
              }}
            >
              {inLibraryContext && !allChangesSaved && (
                <SettingButton
                  style={{
                    position: "absolute",
                    top: "19rem",
                    right: "2.4rem",
                  }}
                >
                  <Button
                    disabled={allChangesSaved || isLoading}
                    buttonType="text"
                    text="Cancel"
                    onClick={() => {
                      setValues(
                        formatCurveToText(
                          turbine.windSpeed,
                          turbine.power,
                          turbine.thrustCoefficient,
                        ),
                      );
                      setTempTurbine(turbine);
                    }}
                    style={{
                      marginLeft: "auto",
                    }}
                  />
                  <Button
                    disabled={allChangesSaved || isLoading}
                    text="Save changes"
                    onClick={() => {
                      setToastMessages((tm) => [
                        ...tm,
                        {
                          text: "Saving...",
                          timeout: 2000,
                        },
                      ]);
                      _onSave();
                    }}
                  />
                </SettingButton>
              )}
              <ColumnSection
                style={{
                  padding: 0,
                }}
              >
                <TitleContainer>
                  <Subtitle>General info</Subtitle>
                  <HorizontalLine />
                </TitleContainer>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "start",
                    gridTemplateColumns: "auto auto",
                    gap: "2.4rem",
                  }}
                >
                  <Label>
                    <SecondaryText>Name</SecondaryText>
                    <ChangelogAndCommentWrapper
                      changelogInfo={turbineChangelogInfo(
                        turbine.id,
                        nodeId,
                        "name",
                        commentCategory,
                      )}
                      disabled={isReadOnly}
                    >
                      <Input
                        style={{
                          width: "42.4rem",
                        }}
                        type="string"
                        disabled={isReadOnly}
                        value={tempTurbine.name}
                        onChange={(e) => {
                          setTempTurbine({
                            ...tempTurbine,
                            name: e.target.value,
                          });
                        }}
                      />
                    </ChangelogAndCommentWrapper>
                  </Label>
                  <Label>
                    <SecondaryText>Cost</SecondaryText>
                    <Row alignCenter>
                      <>{turbineCostElement}</>
                    </Row>
                  </Label>
                </div>
                <div
                  style={{
                    paddingTop: "0.8rem",
                    display: "grid",
                    justifyContent: "start",
                    gridTemplateColumns: "auto auto auto auto",
                    gap: "2.4rem",
                  }}
                >
                  <Label>
                    <SecondaryText>Diameter</SecondaryText>
                    {tempTurbine && (
                      <InputDimensioned
                        style={{
                          width: "20rem",
                        }}
                        type="number"
                        disabled={isReadOnly}
                        value={tempTurbine.diameter}
                        initialValue={turbine.diameter}
                        unit={"m"}
                        changelogInfo={turbineChangelogInfo(
                          turbine.id,
                          nodeId,
                          "diameter",
                          commentCategory,
                        )}
                        validate={(v) => isApproxBetween(50, v, 350)}
                        onChange={(diameter) => {
                          setTempTurbine({
                            ...tempTurbine,
                            diameter,
                          });
                        }}
                      />
                    )}
                  </Label>

                  <Label>
                    <SecondaryText>Hub height</SecondaryText>
                    <InputDimensioned
                      type="number"
                      disabled={isReadOnly}
                      value={tempTurbine?.hubHeight}
                      initialValue={turbine?.hubHeight}
                      changelogInfo={turbineChangelogInfo(
                        turbine.id,
                        nodeId,
                        "hubHeight",
                        commentCategory,
                      )}
                      style={{
                        width: "20rem",
                      }}
                      validate={(v) => isApproxBetween(50, v, 300)}
                      unit={"m"}
                      onChange={(hubHeight) => {
                        setTempTurbine({
                          ...tempTurbine,
                          hubHeight,
                        });
                      }}
                    />
                  </Label>

                  <Label>
                    <SecondaryText>RNA weight</SecondaryText>
                    <InputDimensioned
                      type="number"
                      disabled={isReadOnly}
                      scaleFactor={scaleTonne}
                      decimals={0}
                      style={{
                        width: "20rem",
                      }}
                      value={Math.round(tempTurbine.rnaMass)}
                      initialValue={turbine.rnaMass}
                      changelogInfo={turbineChangelogInfo(
                        turbine.id,
                        nodeId,
                        "rnaMass",
                        commentCategory,
                      )}
                      validate={(v) => isApproxBetween(50, v, 3000)}
                      step={1}
                      unit={"t"}
                      onChange={(rnaMassKilorams) => {
                        setTempTurbine({
                          ...tempTurbine,
                          rnaMass: Math.round(rnaMassKilorams),
                        });
                      }}
                    />
                  </Label>

                  <Label>
                    <SecondaryText>Reference air density</SecondaryText>
                    <InputDimensioned
                      type="number"
                      disabled={isReadOnly}
                      value={tempTurbine.referenceAirDensity}
                      initialValue={turbine.referenceAirDensity}
                      validate={(v) => isApproxBetween(0.7, v, 1.5)}
                      unit={"kg/m3"}
                      style={{
                        width: "20rem",
                      }}
                      step={0.001}
                      onChange={(referenceAirDensity) => {
                        setTempTurbine({
                          ...tempTurbine,
                          referenceAirDensity,
                        });
                      }}
                    />
                  </Label>
                </div>
              </ColumnSection>
              <ColumnSection style={{ paddingTop: "0.8rem" }}>
                <ChangelogAndCommentWrapper
                  changelogInfo={turbineChangelogInfo(
                    turbine.id,
                    nodeId,
                    "voltage",
                    commentCategory,
                    (object: any) => {
                      const voltages = (object["voltage"] ?? []) as any[];
                      const values = {
                        [IAVoltageType.kV132]: voltages.some(
                          (v) => v === IAVoltageType.kV132,
                        )
                          ? "Yes"
                          : "No",
                        [IAVoltageType.kV66]: voltages.some(
                          (v) => v === IAVoltageType.kV66,
                        )
                          ? "Yes"
                          : "No",
                        [IAVoltageType.kV33]: voltages.some(
                          (v) => v === IAVoltageType.kV33,
                        )
                          ? "Yes"
                          : "No",
                      };
                      return values as any;
                    },
                  )}
                  suffix={"kV"}
                  disabled={isReadOnly}
                >
                  <Label>
                    <SecondaryText>Voltage</SecondaryText>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: spaceLarge,
                      }}
                    >
                      {onshore ? (
                        <Checkbox
                          value={IAVoltageType.kV33}
                          label={String(IAVoltageType.kV33).concat("kV")}
                          labelPlacement="after"
                          checked={tempTurbine.voltage.includes(
                            IAVoltageType.kV33,
                          )}
                          disabled={
                            (tempTurbine.voltage.length === 1 &&
                              tempTurbine.voltage.includes(
                                IAVoltageType.kV33,
                              )) ||
                            isReadOnly
                          }
                          onChange={onVoltageChange}
                        />
                      ) : (
                        <>
                          <Checkbox
                            value={IAVoltageType.kV66}
                            label={String(IAVoltageType.kV66).concat("kV")}
                            labelPlacement="after"
                            checked={tempTurbine.voltage.includes(
                              IAVoltageType.kV66,
                            )}
                            disabled={
                              (tempTurbine.voltage.length === 1 &&
                                tempTurbine.voltage.includes(
                                  IAVoltageType.kV66,
                                )) ||
                              isReadOnly
                            }
                            onChange={onVoltageChange}
                          />
                          <Checkbox
                            value={IAVoltageType.kV132}
                            label={String(IAVoltageType.kV132).concat("kV")}
                            labelPlacement="after"
                            checked={tempTurbine.voltage.includes(
                              IAVoltageType.kV132,
                            )}
                            disabled={
                              (tempTurbine.voltage.length === 1 &&
                                tempTurbine.voltage.includes(
                                  IAVoltageType.kV132,
                                )) ||
                              isReadOnly
                            }
                            onChange={onVoltageChange}
                          />
                        </>
                      )}
                    </div>
                  </Label>
                </ChangelogAndCommentWrapper>
              </ColumnSection>
              <ColumnSection>
                <Row>
                  <TitleContainer>
                    <Subtitle>
                      Losses{" "}
                      <HelpTooltip
                        style={{
                          display: "inline-flex",
                        }}
                        text="Turbine-specific losses"
                        size={12}
                      />
                    </Subtitle>
                    <HorizontalLine />
                  </TitleContainer>
                </Row>
                {(!tempTurbine.losses || tempTurbine.losses.length === 0) && (
                  <Row>
                    <SimpleAlert
                      text={`No turbine-specific losses added yet`}
                      type={"info"}
                    />
                  </Row>
                )}
                {tempTurbine?.losses &&
                  tempTurbine.losses.map((loss, i) => (
                    <Row key={i}>
                      <Input
                        disabled={isReadOnly}
                        type="text"
                        value={loss.name}
                        onChange={(e) => {
                          setTempTurbine((cur) => {
                            if (!cur?.losses) return cur;
                            const newLosses = [...cur.losses];
                            newLosses[i].name = e.target.value;
                            return {
                              ...cur,
                              losses: newLosses,
                            };
                          });
                        }}
                        style={{
                          width: "16rem",
                        }}
                      />

                      <InputDimensioned
                        disabled={isReadOnly}
                        value={fractionToPercentage(loss.value)}
                        validate={(n) => 0 <= n && n <= 100 * MAX_LOSS}
                        validationMessage={`Needs to be within 0 - ${100 * MAX_LOSS}%`}
                        step={0.5}
                        unit="%"
                        type="number"
                        onChange={(value) => {
                          updateLoss(percentageToFraction(value), i);
                        }}
                        style={{
                          width: "7rem",
                        }}
                      />
                      {!isReadOnly && (
                        <TextIcon
                          onClick={() => removeLoss(i)}
                          scale={1.2}
                          style={{
                            padding: spaceTiny,
                            alignSelf: "center",
                          }}
                        >
                          <Trashcan />
                        </TextIcon>
                      )}
                    </Row>
                  ))}
                <Button
                  disabled={
                    isReadOnly ||
                    (tempTurbine?.losses?.length ?? 0) >= MAX_NUMBER_OF_LOSSES
                  }
                  buttonType="text"
                  text="Add loss"
                  icon={<AddIcon />}
                  onClick={() => {
                    const newLoss: TurbineLoss = {
                      name: "Unnamed loss",
                      value: 0,
                    };
                    setTempTurbine({
                      ...tempTurbine,
                      losses: [...(tempTurbine?.losses ?? []), newLoss],
                    });
                  }}
                />
              </ColumnSection>
              <ColumnSection>
                <TitleContainer>
                  <Subtitle>Power curves</Subtitle>
                  <HorizontalLine />
                </TitleContainer>
                <Label>
                  <Row
                    style={{
                      gap: "1.8rem",
                      paddingRight: "3rem",
                    }}
                  >
                    <Column>
                      <Row
                        style={{
                          flex: 0,
                          justifyContent: "space-between",
                          marginRight: "1rem",
                        }}
                      >
                        <Column
                          style={{
                            gap: 0,
                          }}
                        >
                          <DetailSecondary>(m/s)</DetailSecondary>
                          <Detail>Speed</Detail>
                        </Column>
                        <Column
                          style={{
                            gap: 0,
                          }}
                        >
                          <DetailSecondary>(kW)</DetailSecondary>
                          <Detail>Power</Detail>
                        </Column>
                        <Column
                          style={{
                            gap: 0,
                          }}
                        >
                          <DetailSecondary>(-)</DetailSecondary>
                          <Detail>Thrust</Detail>
                        </Column>
                      </Row>
                      <TextArea
                        cols={25}
                        rows={15}
                        style={{
                          resize: "vertical",
                        }}
                        disabled={isReadOnly}
                        placeholder={"Speed Power Thrust\n[m/s] [kW] [-]"}
                        value={values}
                        onChange={(e) => {
                          const { speed, power, thrust } = textToCurve(
                            e.target.value,
                          );
                          const turbine = {
                            ...tempTurbine,
                            power,
                            windSpeed: speed,
                            thrustCoefficient: thrust,
                          } as TurbineType;
                          setValues(formatInput(e.target.value));
                          setTempTurbine(turbine);
                        }}
                      />
                    </Column>
                    {tempTurbine && (
                      <React.Suspense fallback={null}>
                        <TurbineGraph turbine={tempTurbine} />
                      </React.Suspense>
                    )}
                  </Row>
                </Label>
                {!isValid && (
                  <SimpleAlert text={"Invalid input curves"} type={"error"} />
                )}
                <Label>
                  <Row style={{ paddingTop: "1.2rem" }}>
                    Rated power
                    <HelpTooltip
                      style={{
                        display: "inline-flex",
                      }}
                      text="Maximum power output of the turbine, taken from the maximum value in the power curve"
                      size={12}
                    />
                  </Row>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={Math.max(...tempTurbine.power)}
                    unit={"MW"}
                    scaleFactor={1 / 1000}
                    decimals={1}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>
              </ColumnSection>
              <ColumnSection>
                <Row alignCenter>
                  <FreeText
                    showFreeText={showFreeText}
                    setShowFreeText={setShowFreeText}
                    value={tempTurbine.note ?? ""}
                    onChange={(value) => {
                      setTempTurbine({
                        ...tempTurbine,
                        note: value,
                      });
                    }}
                    disabled={isReadOnly}
                  />
                </Row>
              </ColumnSection>
            </Column>
          </ColumnContent>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default TurbineSettingCustomAdmin;
