import { Input } from "components/General/Input";
import { DateText, Form, Text } from "./style";
import Send from "@icons/24/Send.svg?react";
import Edit from "@icons/24/Pencil.svg";
import Trashcan from "@icons/24/Bin.svg";
import { Column, Row } from "components/General/Layout";
import Button from "components/General/Button";
import { useState, useCallback, Suspense, useRef, useEffect } from "react";
import { ChangelogRenderEntry, InputChangelogInfo } from "./types";
import { useChangelogCommentCrud } from "./useChangelogCommentCrud";
import { MenuItem } from "components/General/Menu";
import { DotMenu } from "components/General/MenuButton";

import { SkeletonBlock } from "components/Loading/Skeleton";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { timeAgoOrFormattedDate } from "utils/utils";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";
import { Anchor } from "components/General/Anchor";
import { CommentPopup } from "./ChangelogPopup";
import AddedNoteIcon from "@icons/24/AddedNote.svg?react";
import NoteIcon from "@icons/24/Note.svg?react";
import styled from "styled-components";
import { atom, useAtom } from "jotai";
import { atomFamily } from "utils/jotai";

const userImageSize = 2.4;
const CommentHeader = styled.h3``;
const CommentDescription = styled.div`
  margin-bottom: 1.5rem;
`;

// used to store temp text for a changelog comment
// if the component is unmounted, then mounted again, then the temp text is not lost
const tempTextState = atomFamily<
  string,
  { text: string; createdAt: number } | null
>(() => atom<{ text: string; createdAt: number } | null>(null));

function ExistingChangelogCommentHighlight({
  entry,
  changelogInfo,
  disabled,
  className,
  commentTitle,
}: {
  entry: ChangelogRenderEntry;
  changelogInfo: InputChangelogInfo;
  disabled?: boolean;
  className?: string;
  commentTitle?: string;
}) {
  const comment = entry.comment;

  const { put, _delete } = useChangelogCommentCrud(changelogInfo.nodeId);

  const [edit, setEdit] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [tempText, setTempText] = useAtom(
    tempTextState(
      `${changelogInfo.changelogId}-${changelogInfo.changelogEntryKeyId}`,
    ),
  );
  const [text, setText] = useState(comment?.comment ?? "");

  // Load temp text only on mount. Use temp text if !comment and <10 minutes old
  useEffect(() => {
    if (!comment && tempText?.text) {
      const tenMinutesInMs = 10 * 60 * 1000;
      const timeSinceCreated = new Date().getTime() - tempText.createdAt;

      if (timeSinceCreated < tenMinutesInMs) {
        setText(tempText.text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on mount

  const handleSubmit = useCallback(
    (newText: string) => {
      if (disabled) return;
      if (newText.length === 0) return;
      setSaveInProgress(true);
      put(entry.id, entry.version, changelogInfo.category, {
        comment: newText,
        keyPath: changelogInfo.changelogEntryKeyId,
      })
        .finally(() => {
          setSaveInProgress(false);
        })
        .then(() => {
          setEdit(false);
          // Clear temp text after successful submission
          setTempText(null);
        });
    },
    [disabled, put, entry.id, entry.version, changelogInfo, setTempText],
  );

  return (
    <Column>
      {commentTitle && (edit || !comment) && (
        <>
          <Row>
            <CommentHeader>Add note</CommentHeader>
          </Row>
          <Row>
            <CommentDescription>{commentTitle}</CommentDescription>
          </Row>
        </>
      )}
      {comment && (
        <Row style={{ alignItems: "center", whiteSpace: "nowrap" }}>
          <Suspense
            fallback={
              <SkeletonBlock style={{ width: "5rem", height: "1.4rem" }} />
            }
          >
            <UserInfoOrganisation
              userId={comment.author}
              size={userImageSize}
            />
          </Suspense>
          <DateText style={{ marginRight: "auto" }}>
            {timeAgoOrFormattedDate(comment.createdAt / 1000)}
          </DateText>
          {!disabled && (
            <DotMenu className={className}>
              <MenuItem
                name="Edit"
                icon={<Edit />}
                disabled={disabled}
                onClick={() => {
                  setEdit(true);
                }}
              />
              <MenuItem
                name="Delete"
                icon={<Trashcan />}
                disabled={disabled}
                onClick={async () => {
                  _delete(
                    changelogInfo.changelogId,
                    entry.version,
                    comment.keyPath,
                    changelogInfo.category,
                  );
                }}
              />
            </DotMenu>
          )}
        </Row>
      )}
      <Row>
        {edit || !comment ? (
          <Form style={{ width: "100%" }}>
            <Input
              autoFocus
              value={text}
              disabled={saveInProgress || disabled}
              onEnter={() => handleSubmit(text)}
              onChange={(e) => {
                setText(e.target.value);
                setTempText({
                  text: e.target.value,
                  createdAt: new Date().getTime(),
                });
              }}
              placeholder="Write a note..."
              onCancel={() => {
                setText(comment?.comment ?? "");
                setEdit(false);
              }}
              style={{ border: "none", flex: 1, height: "2.8rem" }}
            />
            <Button
              type="submit"
              icon={<Send />}
              buttonType="text"
              disabled={disabled || text.length === 0 || saveInProgress}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit(text);
              }}
            />
          </Form>
        ) : (
          <Row style={{ alignItems: "center" }}>
            <Text>{comment?.comment ?? ""}</Text>
          </Row>
        )}
      </Row>
    </Column>
  );
}
export function ChangelogCommentSmallView({
  openByDefault,
  entry,
  changelogInfo,
  disabled,
  commentTitle,
}: {
  openByDefault?: boolean;
  entry: ChangelogRenderEntry;
  changelogInfo: InputChangelogInfo;
  disabled?: boolean;
  commentTitle?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const comment = entry.comment;

  const [showComment, setShowComment] = useState(false);

  useEffect(() => {
    if (openByDefault && !!comment) {
      setShowComment(true);
    }
  }, [openByDefault, comment, setShowComment]);

  return (
    <Column ref={ref} style={{ position: "relative", cursor: "pointer" }}>
      <CommentIcon
        isExistingComment={!!comment}
        onClick={() => {
          setShowComment(!showComment);
        }}
        showComment={showComment}
      />

      {showComment && (
        <Anchor baseRef={ref} basePlace="bottomLeft" floatPlace="topLeft">
          <CommentPopup
            style={{ width: "25rem" }}
            close={() => setShowComment(false)}
            className="comment-popup"
          >
            <ExistingChangelogCommentHighlight
              entry={entry}
              changelogInfo={changelogInfo}
              disabled={disabled}
              className={"comment-popup"}
              commentTitle={commentTitle}
            />
          </CommentPopup>
        </Anchor>
      )}
    </Column>
  );
}

const CommentIcon = ({
  isExistingComment,
  onClick,
  showComment,
}: {
  isExistingComment: boolean;
  onClick: () => void;
  showComment?: boolean;
}) => (
  <IconBtn
    style={{
      padding: "0.35rem",
    }}
    tabIndex={-1}
    size={"1.4rem"}
    pathStrokeWidth={0.1}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    backgroundColor={showComment ? colors.surfaceSelectedLight : "transparent"}
  >
    {isExistingComment ? <AddedNoteIcon /> : <NoteIcon />}
  </IconBtn>
);
