import { aset, useJotaiCallback } from "utils/jotai";
import { editOrgName } from "../service";
import { organisationsState } from "../state";

export const useEditOrganisation = () => {
  return useJotaiCallback(async (get, set, orgId: string, newName: string) => {
    const fallback = await get(organisationsState);

    aset(get, set, organisationsState, (curr) =>
      [...curr].map((org) =>
        org.id === orgId
          ? {
              ...org,
              name: newName,
            }
          : org,
      ),
    );

    return editOrgName(orgId, newName).catch(() => {
      set(organisationsState, async () => fallback);
    });
  }, []);
};
