import { _MonopileParameters } from "components/GenerateFoundationsAndAnchors/types";
import { WaveStats } from "state/waveStatistics";
import { dateToDateTime } from "utils/utils";
import { z } from "zod";
import { ParkFeature } from "./feature";
import { SimpleTurbineType } from "./turbines";
import * as spec from "api/turbines";

export enum FoundationTypeIds {
  SemiCentralType = "semi_central",
  SemiPeripheralType = "semi_peripheral",
  SparType = "spar",
  MonopileType = "monopile",
  DetailedMonopileType = "detailed_monopile",
  JacketType = "jacket",
  DetailedJacketType = "detailed_jacket",
}

const _SimpleFoundationType = spec.components.schemas.SimpleFoundationType;

const _ScalingLawType = z.enum(["Mass scaling"]);

export type ScalingLawType = z.infer<typeof _ScalingLawType>;

const _SemiCentralType = spec.components.schemas.SemiCentralType;

export type SemiCentralType = z.infer<typeof _SemiCentralType>;

const _SemiPeripheralType = spec.components.schemas.SemiPeripheralType;
export type SemiPeripheralType = z.infer<typeof _SemiPeripheralType>;

const _SparType = spec.components.schemas.SparType;
export type SparType = z.infer<typeof _SparType>;

export const _PileData = spec.components.schemas.PileData;
export type PileData = z.infer<typeof _PileData>;

const _DetailedMonopileType = _SimpleFoundationType
  .and(_MonopileParameters)
  .and(_PileData);

export type DetailedMonopileType = z.infer<typeof _DetailedMonopileType>;

export const SoilStiffnessLevels = {
  soft: 2000e3,
  medium: 4000e3,
  stiff: 8000e3,
};

export enum SoilTypesEnum {
  Sand = "sand",
  Clay = "clay",
}

const _SoilTypes = spec.components.schemas.SoilTypes;
export type SoilTypes = z.infer<typeof _SoilTypes>;

const _JacketParameters = spec.components.schemas.JacketParameters;

export const _JacketDimensions = spec.components.schemas.JacketDimensions;
export type JacketDimensions = z.infer<typeof _JacketDimensions>;

const _DetailedJacketType = spec.components.schemas.DetailedJacketType;
export type DetailedJacketType = z.infer<typeof _DetailedJacketType>;

export const defaultDetailedMonopileParameters = (
  name: string,
  park: ParkFeature,
  turbineType: SimpleTurbineType,
  waterDepth: number,
  waveStats: WaveStats | undefined,
): DetailedMonopileType => {
  const parkName = park.properties.name ?? "unnamed park";
  const createdAt = new Date(Date.now());
  const {
    ratedPower,
    diameter: rotorDiameter,
    hubHeight,
    rnaMass,
  } = turbineType;

  return {
    name,
    id: "",
    type: FoundationTypeIds.DetailedMonopileType,
    material: "Steel",
    info: `Monopile generated for ${parkName} on ${dateToDateTime(createdAt)}.`,
    soilCoeffSubReact: SoilStiffnessLevels.medium,
    ratedPower,
    rotorDiameter,
    hubHeight,
    rnaMass,
    waterDepth: Math.min(waterDepth, FoundationMaxDepths.detailed_monopile),
    hs50Yr: waveStats?.hs50Yr ?? 0,
    pileDiameter: 0,
    avgPileThickness: 0,
    embedLength: 0,
    totalPileLength: 0,
  };
};

export const defaultDetailedJacketParameters = (
  name: string,
  park: ParkFeature,
  turbineType: SimpleTurbineType,
  waterDepth: number,
  waveStats: WaveStats | undefined,
): DetailedJacketType => {
  const parkName = park.properties.name ?? "unnamed park";
  const createdAt = new Date(Date.now());
  const {
    ratedPower,
    diameter: rotorDiameter,
    hubHeight,
    rnaMass,
  } = turbineType;

  return {
    name,
    id: "",
    type: FoundationTypeIds.DetailedJacketType,
    material: "Steel",
    info: `Jacket generated for ${parkName} on ${dateToDateTime(createdAt)}.`,
    ratedPower,
    rotorDiameter,
    hubHeight,
    rnaMass,
    waterDepth: Math.min(waterDepth, FoundationMaxDepths.detailed_jacket),
    hs50Yr: waveStats?.hs50Yr ?? 0,
    numLegs: 4,
    soilType: SoilTypesEnum.Sand,
    frictionAngle: (25 * Math.PI) / 180,
    shearStrength: 100e3,
    unitWeight: 10e3,
    pileMass: 0,
    legDiameter: 0,
    legThickness: 0,
    braceDiameter: 0,
    braceThickness: 0,
    Ltop: 0,
    Lbottom: 0,
    jacketHeight: 0,
    numBays: 0,
    tpMass: 0,
  };
};

export type ScalingVariable = "p" | "d" | "w";
export const SCALING_VARIABLES: ScalingVariable[] = ["p", "d", "w"];

const _MonopileType = spec.components.schemas.MonopileType;
export type MonopileType = z.infer<typeof _MonopileType>;

const _JacketType = spec.components.schemas.JacketType;
export type JacketType = z.infer<typeof _JacketType>;

const _FloaterType = spec.components.schemas.FloaterType;
export type FloaterType = z.infer<typeof _FloaterType>;

const _FixedType = spec.components.schemas.FixedType;
export type FixedType = z.infer<typeof _FixedType>;

export const _FoundationType = spec.components.schemas.FoundationType;
export type FoundationType = z.infer<typeof _FoundationType>;

const foundationLevel = ["project", "team", "library", "standard"] as const;

export const _FoundationLevel = z.enum(foundationLevel);

type FoundationLevel = z.infer<typeof _FoundationLevel>;
export type FoundationTypeWithLevel = {
  level: FoundationLevel;
  foundation: FoundationType;
};

export const FoundationMinDepths: Record<FoundationTypeIds, number> = {
  semi_central: 50,
  semi_peripheral: 50,
  spar: 100,
  monopile: 0,
  detailed_monopile: 0,
  jacket: 0,
  detailed_jacket: 0,
};

export const FoundationMaxDepths: Record<FoundationTypeIds, number> = {
  semi_central: Infinity,
  semi_peripheral: Infinity,
  spar: Infinity,
  monopile: 75,
  detailed_monopile: 75,
  jacket: 90,
  detailed_jacket: 90,
};

export const DEFAULT_SEMI_CENTRAL_FLOATERS: SemiCentralType[] = [
  {
    id: "umaine_15MW",
    name: "VolturnUS-S semi",
    type: FoundationTypeIds.SemiCentralType,
    material: "Steel",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 150,
    rnaMass: 991e3,
    towerMass: 1263e3,
    draft: 20,
    cornerColDiameter: 12.5,
    centralColDiameterTop: 10,
    centralColDiameterBase: 10,
    cornerColHeight: 35,
    centralColHeight: 35,
    cornerColStart: -20,
    centralColStart: -20,
    ponWidth: 12.5,
    ponHeight: 7,
    ponStart: -20,
    ccDistance: 51.75,
    primaryMass: 4014e3,
    primaryDensity: 7850,
    solidBallastMass: 2540e3,
    liquidBallastMass: 11300e3,
    displacement: 20206,
    info: "Based on the public definition of the UMaine VolturnUS-S steel semi, originally designed for the IEA 15MW turbine.",
    scalingLaw: "Mass scaling",
    fairRadius: 58,
    fairZ: -14,
  },
  {
    id: "activefloat_15MW",
    name: "ActiveFloat semi",
    type: FoundationTypeIds.SemiCentralType,
    material: "Concrete",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 135,
    rnaMass: 1016.5e3,
    towerMass: 1088.5e3,
    draft: 26.5,
    cornerColDiameter: 17,
    centralColDiameterTop: 11,
    centralColDiameterBase: 19.6,
    cornerColHeight: 35.5,
    centralColHeight: 24,
    cornerColStart: -26.5,
    centralColStart: -15,
    ponWidth: 17,
    ponHeight: 11.5,
    ponStart: -26.5,
    ccDistance: 34,
    primaryMass: 21027e3,
    primaryDensity: 2700,
    solidBallastMass: 0,
    liquidBallastMass: 13360e3,
    displacement: 36431,
    reinforceDensity: 350,
    postTensDensity: 40,
    info: "Based on the public definition of the ActiveFloat concrete semi, originally designed for the IEA 15MW turbine with modified hub height. The concrete weight is estimated from available info.",
    scalingLaw: "Mass scaling",
    fairRadius: 42.5,
    fairZ: -15,
  },
];
export const DEFAULT_SEMI_PERIPHERAL_FLOATERS: SemiPeripheralType[] = [
  {
    id: "windmoor_12MW",
    name: "WINDMOOR semi",
    type: FoundationTypeIds.SemiPeripheralType,
    material: "Steel",
    rotorDiameter: 216.9,
    ratedPower: 12000,
    hubHeight: 131.7,
    rnaMass: 849.1e3,
    towerMass: 1161.6e3,
    draft: 15.5,
    cornerColDiameter: 15,
    cornerColHeight: 31,
    cornerColStart: -15.5,
    ponWidth: 10,
    ponHeight: 4,
    ponStart: -15.5,
    deckWidth: 3.5,
    deckHeight: 3.5,
    deckStart: 12,
    ccDistance: 61 / Math.sqrt(3),
    primaryMass: 3950e3,
    primaryDensity: 7850,
    solidBallastMass: 0,
    liquidBallastMass: 8024e3,
    displacement: 14176,
    info: "Based on the public definition of the floater from the WINDMOOR project, originally designed for a 12MW turbine. The steel weight is estimated from available info.",
    scalingLaw: "Mass scaling",
    fairRadius: 42.7,
    fairZ: 0,
  },
];
export const DEFAULT_SPAR_FLOATERS: SparType[] = [
  {
    id: "spar_15MW",
    name: "Generic spar",
    type: FoundationTypeIds.SparType,
    material: "Concrete",
    rotorDiameter: 240,
    ratedPower: 15000,
    hubHeight: 143,
    rnaMass: 1017e3,
    towerMass: 1500e3,
    draft: 100,
    colHeight: 115,
    topDiameter: 10,
    baseDiameter: 23.5,
    upperTaperLevel: -5,
    lowerTaperLevel: -25,
    primaryMass: 15580e3,
    primaryDensity: 2700,
    solidBallastMass: 17000e3,
    liquidBallastMass: 5000e3,
    displacement: 39118,
    reinforceDensity: 250,
    postTensDensity: 30,
    info: "A generic concrete spar, originally sized for a 15MW turbine.",
    scalingLaw: "Mass scaling",
    fairRadius: 12,
    fairZ: -30,
  },
];

const DEFAULT_MONOPILE_EQUATION =
  "1432 - 77.9 * w - 85.3 * p  + 3.5 * pow(p,2) + 1.4 * pow(w,2) + 4.5 * p * w";
const DEFAULT_JACKET_EQUATION =
  "507 + 45.7 * p - 29.0 * w + 2.7 * pow(p,2) + 0.9 * pow(w,2) - 0.8 * p * w";

export const DEFAULT_MONOPILES: MonopileType[] = [
  {
    id: "site_monopile",
    name: "Generic monopile",
    type: FoundationTypeIds.MonopileType,
    material: "Steel",
    info: "A generic monopile, sized based on a provided weight equation. The default equation gives realistic weights for TP-less monopiles in water depths between 20-50 m.",
    scalingEquation: DEFAULT_MONOPILE_EQUATION,
    minRatedPower: 5000,
    maxRatedPower: 25000,
    minWaterDepth: 20,
    maxWaterDepth: 50,
  },
];

export const DEFAULT_JACKETS: JacketType[] = [
  {
    id: "generic_jacket",
    name: "Generic jacket",
    type: FoundationTypeIds.JacketType,
    material: "Steel",
    info: "A generic jacket, sized based on a provided weight equation. The default equation gives realistic weights for jackets (including piles) in water depths between 20-60 m.",
    scalingEquation: DEFAULT_JACKET_EQUATION,
    minRatedPower: 5000,
    maxRatedPower: 25000,
    minWaterDepth: 20,
    maxWaterDepth: 60,
  },
];
