import { useState } from "react";
import InputChangelog from "./InputChangelog";
import { ChangelogIconWrapper, WrapperChangelogParent } from "./style";
import { InputChangelogInfo } from "./types";

const ChangelogAndCommentWrapper = ({
  changelogInfo,
  children,
  suffix,
  disabled,
  style,
  scaleFactor,
  decimals,
}: {
  changelogInfo?: InputChangelogInfo;
  children: React.ReactNode;
  suffix?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  scaleFactor?: number;
  decimals?: number;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <WrapperChangelogParent
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {changelogInfo && (
        <ChangelogIconWrapper inFocus={isHovered}>
          <InputChangelog
            showBtn={isHovered}
            changelogInfo={changelogInfo}
            suffix={suffix}
            scaleFactor={scaleFactor}
            decimals={decimals}
            disabled={disabled}
          />
        </ChangelogIconWrapper>
      )}
    </WrapperChangelogParent>
  );
};

export default ChangelogAndCommentWrapper;
