import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { _AnalysisConfiguration } from "services/configurationService";
import { useLibraryNodeAnalysis } from "hooks/useLibraryNodeAnalysis";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import { ABLY_ORG_ANALYSIS_CONFIGURATION_LIBRARY_TYPE_UPDATE } from "state/ably";

export function useAblyLibraryAnalysisForNode(projectId: string) {
  const { updateAnalysisLocal } = useLibraryNodeAnalysis(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const analysis = _AnalysisConfiguration.parse(message.data.meta);
      updateAnalysisLocal(analysis);
    },
    [updateAnalysisLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ORG_ANALYSIS_CONFIGURATION_LIBRARY_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
