import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import FilterIcon from "@icons/12/Filter.svg";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import SearchWrapper from "components/Search/SearchWrapper";
import { modalTypeOpenAtom } from "state/modal";
import RoundIcon from "./RoundIcon";
import { MiddleItemWrapper } from "./style";
import { Mixpanel } from "mixpanel";
import { useAtom } from "jotai";
import SearchObject from "components/Search/SearchObject";

function ConfigSearchWrappers({ children }: { children: React.ReactNode }) {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);

  return (
    <>
      <SearchObject
        title="Wind configuration"
        tags={["wind", "wind data", "time series", "nora3", "era5", "cerra"]}
        id="search-wind-configuration"
        icon={<FilterIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: ProjectConfigModalTypeV2,
            metadata: {
              selectedMenuId: "wind",
            },
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: ProjectConfigModalTypeV2,
            });
        }}
      />
      <SearchObject
        title="Analysis configuration"
        tags={[
          "analysis",
          "analysis data",
          "wake settings",
          "wake model",
          "blockage",
          "air density",
          "cable length contingency",
          "electrical losses",
          "custom losses",
        ]}
        id="search-analysis-configuration"
        icon={<FilterIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: ProjectConfigModalTypeV2,
            metadata: {
              selectedMenuId: "analysis",
            },
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: ProjectConfigModalTypeV2,
            });
        }}
      />
      <SearchObject
        title="Financial configuration"
        tags={[
          "financial",
          "financial data",
          "cost",
          "capex",
          "opex",
          "discount rate",
          "inflation",
          "tax",
          "cash flow",
          "market price",
          "lcoe",
        ]}
        id="search-financial-configuration"
        icon={<FilterIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: ProjectConfigModalTypeV2,
            metadata: {
              selectedMenuId: "cost",
            },
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: ProjectConfigModalTypeV2,
            });
        }}
      />
      <SearchWrapper
        title="Configuration"
        tags={[
          "projects",
          "list",
          "project list",
          "feature list",
          "turbine",
          "foundation",
          "inter array",
          "cable",
          "mooring line",
          "substation",
          "export cable",
          "cost",
          "diameter",
          "hub height",
          "loss",
          "voltage",
          "thrust",
          "power",
          "material",
          "steel",
          "balast",
          "resistance",
          "reactor",
        ]}
        id="search-configuration"
        icon={<FilterIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: ProjectConfigModalTypeV2,
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: ProjectConfigModalTypeV2,
            });
        }}
      >
        {children}
      </SearchWrapper>
    </>
  );
}

const ConfigurationsModalButton = () => {
  const [modalTypeOpen, setModalType] = useAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);

  return (
    <ConfigSearchWrappers>
      <Tooltip
        theme="light"
        text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Settings"}
        position="bottom"
        disabled={!readOnlyMode}
      >
        <MiddleItemWrapper
          className="middle-item-wrapper"
          active={modalTypeOpen?.modalType === ProjectConfigModalTypeV2}
          disabled={readOnlyMode}
          onClick={() => {
            if (readOnlyMode) {
              return;
            }
            setModalType((curr) =>
              curr?.modalType !== ProjectConfigModalTypeV2
                ? {
                    modalType: ProjectConfigModalTypeV2,
                  }
                : undefined,
            );
            Mixpanel.track_old("Open full screen modal", {
              type: ProjectConfigModalTypeV2,
            });
          }}
        >
          <RoundIcon fillOrStroke="fill">
            <FilterIcon />
          </RoundIcon>
          Configuration
        </MiddleItemWrapper>
      </Tooltip>
    </ConfigSearchWrappers>
  );
};

export default ConfigurationsModalButton;
