import styled from "styled-components";
import { Container } from "../../styles/misc/Misc";

const LayerWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

export const LayerControlWrapper = styled(LayerWrapper)<{ open?: boolean }>`
  right: ${(p) => (p.open ? `calc(var(--left-menu-width) + 2rem)` : "0.8rem")};
  bottom: 0.8rem;
  z-index: 4;
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    bottom: 5rem;
    right: 0.8rem;
  }
`;
