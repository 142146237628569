import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { useRef } from "react";
import { IconREMSize, typography } from "../../../../styles/typography";
import Button from "../../../General/Button";
import { v4 as uuid } from "uuid";
import AddIcon from "../../../../icons/24/Add.svg?react";
import OpexRow from "./OpexRow";
import { CostUnit, OccuranceType } from "../../../../types/financial";
import {
  CostDivider,
  DividerWrapper,
  Text,
  Label,
  TableWrapper,
} from "../styles";
import { InputDimensioned, InputNumber } from "../../../General/Input";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { Column, Row } from "../../../General/Layout";
import { between } from "utils/validations";
import { CostTopRow } from "./CostTopRow";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import styled from "styled-components";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { DefaultCosts } from "../constants";
import { spaceMedium } from "styles/space";
import { colors } from "styles/colors";
import { CostConfiguration } from "services/costService";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import {
  HorizontalLine,
  SecondaryText,
} from "components/SettingsV2/Shared/styles";
import { Subtitle } from "components/SettingsV2/Shared/styles";
import { TitleContainer } from "components/SettingsV2/Shared/styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleWrapper = styled.div`
  ${typography.caption}
  color: ${colors.textPrimary};
  padding: 0 2rem;
  display: flex;
  gap: ${spaceMedium};
  align-items: center;
`;

export const OtherCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const inReadOnlyMode = useAtomValue(inReadOnlyModeSelector) || isReadOnly;

  const changelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  const {
    inflation: referenceYears,
    devex,
    decom,
    opex,
    id,
  } = useAtomValue(localCostConfigurationAtom);
  const { updateDEVEX, updateDECOM, updateOPEX, updateInflation } =
    useLocalCostConfigurationCrud();

  const recentlyAddedId = useRef<string>();

  const handleAddCost = () => {
    const newId = uuid();
    recentlyAddedId.current = newId;
    updateOPEX({
      id: newId,
      name: "Untitled",
      unit: CostUnit.thousandEuroPerMWPerYear,
      cost: 0,
      occurance: OccuranceType.Yearly,
    });
  };

  return (
    <TableWrapper>
      <Row>
        <Label
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InputNumber
            compact
            value={referenceYears.referenceYearOtherExpenditures}
            initialValue={
              configuration.inflation.referenceYearOtherExpenditures
            }
            style={{
              width: "6rem",
            }}
            validate={between(2010, 2050)}
            validationMessage={"Expected to be in the range 2010 to 2050"}
            disabled={isReadOnly}
            changelogInfo={financialChangelogInfo(
              id,
              nodeId,
              "inflation.referenceYearOtherExpenditures",
              changelogCategory,
            )}
            onChange={(year) => {
              const referenceYearOtherExpenditures = Math.round(year);
              updateInflation({
                referenceYearOtherExpenditures,
              });
            }}
          />
          <Row
            style={{
              whiteSpace: "nowrap",
              alignItems: "center",
            }}
          >
            <SecondaryText>Reference year</SecondaryText>
            <HelpTooltip
              size={10}
              text="Numbers in this pane will be inflated / deflated with respect to this year."
            />
          </Row>
        </Label>
      </Row>

      <Column
        style={{
          gap: "1.2rem",
        }}
      >
        <TitleContainer>
          <Subtitle>DEVEX</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <Row>
          <Label>
            <InputDimensioned
              disabled={inReadOnlyMode}
              value={devex.cost}
              initialValue={configuration.devex.cost}
              validate={(value) => value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateDEVEX({
                  cost,
                })
              }
              unit={devex.unit}
              changelogInfo={financialChangelogInfo(
                id,
                nodeId,
                "devex",
                changelogCategory,
              )}
            />
          </Label>
          <ToggleWrapper>
            <Toggle
              disabled={inReadOnlyMode}
              size={ToggleSize.SMALL}
              checked={devex.unit === CostUnit.percent}
              onChange={() =>
                updateDEVEX(
                  devex.unit !== CostUnit.percent
                    ? {
                        cost: DefaultCosts.devex[CostUnit.percent],
                        unit: CostUnit.percent,
                      }
                    : {
                        cost: DefaultCosts.devex[CostUnit.thousandEuroPerMW],
                        unit: CostUnit.thousandEuroPerMW,
                      },
                )
              }
            />
            <Text>Set to % of CAPEX</Text>
            <HelpTooltip
              text={`DEVEX as a percentage of CAPEX in the reference year (${referenceYears.referenceYearOtherExpenditures})`}
              size={10}
            ></HelpTooltip>
          </ToggleWrapper>
        </Row>
      </Column>
      <Column
        style={{
          gap: "1.2rem",
        }}
      >
        <TitleContainer>
          <Subtitle>DECOM</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <Row>
          <Label>
            <InputDimensioned
              disabled={inReadOnlyMode}
              value={decom.cost}
              initialValue={configuration.decom.cost}
              validate={(value) => value >= 0}
              decimals={1}
              validationMessage={"Must be greater than or equal to 0"}
              onChange={(cost) =>
                updateDECOM({
                  cost,
                })
              }
              unit={decom.unit}
              changelogInfo={financialChangelogInfo(
                id,
                nodeId,
                "decom",
                changelogCategory,
              )}
            />
          </Label>
          <ToggleWrapper>
            <Toggle
              disabled={inReadOnlyMode}
              size={ToggleSize.SMALL}
              checked={decom.unit === CostUnit.percent}
              onChange={() =>
                updateDECOM(
                  decom.unit !== CostUnit.percent
                    ? {
                        cost: DefaultCosts.decom[CostUnit.percent],
                        unit: CostUnit.percent,
                      }
                    : {
                        cost: DefaultCosts.decom[CostUnit.thousandEuroPerMW],
                        unit: CostUnit.thousandEuroPerMW,
                      },
                )
              }
            />
            <Text>Set to % of CAPEX</Text>
            <HelpTooltip
              text={`DEVEX as a percentage of CAPEX in the reference year (${referenceYears.referenceYearOtherExpenditures})`}
              size={10}
            ></HelpTooltip>
          </ToggleWrapper>
        </Row>
      </Column>

      <Column
        style={{
          gap: 0,
        }}
      >
        <TitleContainer>
          <Subtitle>OPEX</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <CostTopRow />
        {opex.custom.map((entry) => {
          const originalEntry = configuration.opex.custom.find(
            (c) => c.id === entry.id,
          );
          return (
            <Wrapper key={entry.id}>
              <OpexRow
                key={entry.id}
                entry={entry}
                originalEntry={originalEntry}
                autoFocus={recentlyAddedId.current === entry.id}
                deletable={opex.custom.length > 1}
                isReadOnly={isReadOnly}
                nodeId={nodeId}
                configId={id}
                isLibraryResource={isLibraryResource}
              />
              <DividerWrapper>
                <CostDivider />
              </DividerWrapper>
            </Wrapper>
          );
        })}

        <Row
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Button
            style={{
              padding: 0,
            }}
            icon={
              <IconREMSize width={1.2} height={1.2}>
                <AddIcon />
              </IconREMSize>
            }
            buttonType="text"
            text="Add cost"
            onClick={handleAddCost}
            disabled={isReadOnly}
          />
        </Row>
      </Column>
    </TableWrapper>
  );
};
