import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  ABLY_ADD_REPLIES_FILTER,
  ABLY_REMOVE_REPLIES_FILTER,
} from "../../../state/ably";
import {
  deleteReply,
  postConditionalThreadFollow,
  postReply,
} from "../service";
import {
  followThreadsAtomFamily,
  followsThreadAtom,
  replySaveInProgressState,
  threadRepliesAtomFamily,
  threadsAtomFamily,
} from "../state";
import { Reply } from "../types";
import { useAblyPublish } from "../../../hooks/useAblyPublish";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { aset } from "utils/jotai";

export const useReplyCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const ablyPublish = useAblyPublish(projectNodeId);
  const setReplySaveInProgress = useSetAtom(replySaveInProgressState);

  const post = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        threadId: string,
        data: {
          message: string;
        },
      ) => {
        if (!projectNodeId) return;
        setReplySaveInProgress({
          threadId,
          message: data.message,
        });
        const res = await postReply(
          projectNodeId,
          branchId,
          threadId,
          data,
        ).finally(() => setReplySaveInProgress(undefined));

        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (curr) =>
            curr.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    replies: c.replies + 1,
                  }
                : c,
            ),
        );
        await aset(
          get,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (curr) => curr.concat([res]),
        );

        ablyPublish(`${projectNodeId}:all`, ABLY_ADD_REPLIES_FILTER, res);
        const follows = await get(
          followsThreadAtom({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
        );
        if (!follows) {
          try {
            const followResponse = await postConditionalThreadFollow(
              projectNodeId,
              branchId,
              threadId,
            );
            await aset(
              get,
              set,
              followThreadsAtomFamily({
                nodeId: projectNodeId,
                branchId,
              }),
              (curr) => curr.concat([followResponse]),
            );
          } catch (e) {
            console.log(e);
          }
        }
        return res;
      },
      [ablyPublish, branchId, projectNodeId, setReplySaveInProgress],
    ),
  );

  const remove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (curr) =>
            curr.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    replies: c.replies - 1,
                  }
                : c,
            ),
        );

        await aset(
          get,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (curr) => curr.filter((c) => c.replyId !== replyId),
        );

        const res = await deleteReply(
          projectNodeId,
          branchId,
          threadId,
          replyId,
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_REMOVE_REPLIES_FILTER, {
          threadId,
          replyId,
        });

        return res;
      },
      [ablyPublish, projectNodeId, branchId],
    ),
  );

  const localPost = useAtomCallback(
    useCallback(async (get, set, reply: Reply) => {
      await aset(
        get,
        set,
        threadsAtomFamily({
          nodeId: reply.nodeId,
          branchId: reply.branchId,
        }),
        (curr) =>
          curr.map((c) =>
            c.threadId === reply.threadId
              ? {
                  ...c,
                  replies: c.replies + 1,
                }
              : c,
          ),
      );

      await aset(
        get,
        set,
        threadRepliesAtomFamily({
          nodeId: reply.nodeId,
          branchId: reply.branchId,
          threadId: reply.threadId,
        }),
        (curr) => curr.concat([reply]),
      );
    }, []),
  );

  const localRemove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        const threadsAtom = threadsAtomFamily({
          nodeId: projectNodeId,
          branchId,
        });
        await aset(get, set, threadsAtom, (curr) =>
          curr.map((c) =>
            c.threadId === threadId
              ? {
                  ...c,
                  replies: c.replies - 1,
                }
              : c,
          ),
        );

        const repliesAtom = threadRepliesAtomFamily({
          nodeId: projectNodeId,
          branchId,
          threadId: threadId,
        });
        await aset(get, set, repliesAtom, (curr) =>
          curr.filter((c) => c.replyId !== replyId),
        );
      },
      [branchId, projectNodeId],
    ),
  );

  return {
    post,
    remove,
    localPost,
    localRemove,
  };
};
