import {
  getInstallationTimeStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import {
  getInstallationStartAndEndDate,
  getNumberOfInstalledFeaturesPerType,
  getTotalInstallationTime,
  getTotalWaitingTimeAndPercent,
  getTotalWorkingTime,
} from "components/Installation/installation";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { FinanceId } from "finance/types";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { InstallationType } from "state/jotai/windStatistics";
import { orLoader } from "../../Loading/Skeleton";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { Stat, StatHeader, StatHeaderLoadable, Stats } from "./Base.style";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

export const InstallationTableWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title={"Installation table"}
      id="Installation table"
      resetKeys={errorBoundaryResetKeys}
    >
      <InstallationTableInner />
    </SafeCard>
  );
};

const InstallationCategoryPrinter = ({
  title,
  type,
  id,
}: {
  title: string;
  type: InstallationType;
  id: FinanceId;
}) => {
  const totalInstallationTime = useAtomValue(
    loadable(getTotalInstallationTime({ id, type })),
  );
  const totalWorkingTime = useAtomValue(
    loadable(getTotalWorkingTime({ id, type })),
  );
  const totalWaitingTime = useAtomValue(
    loadable(getTotalWaitingTimeAndPercent({ id, type })),
  );
  const installationDates = useAtomValue(
    loadable(getInstallationStartAndEndDate({ id, type })),
  );

  const numFeatures = useAtomValue(
    getNumberOfInstalledFeaturesPerType({ id, type }),
  );

  if (numFeatures === 0) return null;

  if (
    (totalInstallationTime.state === "hasData" &&
      totalInstallationTime.data === undefined) ||
    (totalWorkingTime.state === "hasData" &&
      totalWorkingTime.data === undefined) ||
    (totalWaitingTime.state === "hasData" &&
      totalWaitingTime.data === undefined) ||
    (installationDates.state === "hasData" &&
      installationDates.data === undefined)
  )
    return (
      <>
        <StatHeader title={title} value={`-`} />
        <SimpleAlert
          text={`Installation analysis has failed, please check your input data.`}
          type={"error"}
        />
      </>
    );

  return (
    <>
      <StatHeaderLoadable
        title={title}
        value={totalInstallationTime}
        unit={"days"}
      />
      {orLoader(totalWorkingTime, (time) => (
        <>
          <Stat
            bold={false}
            key={`${type}#workingTime`}
            title={"Working time"}
            value={`${time?.toFixed(1)} days`}
          />
        </>
      ))}
      {orLoader(totalWaitingTime, (time) => (
        <>
          <Stat
            bold={false}
            key={`${type}#waitingTime`}
            title={"Weather waiting time (P50)"}
            value={`${time?.totalWaitingTimeDays.toFixed(1)} days (${time?.totalWaitingTimePercent?.toFixed(1)} % )`}
          />
        </>
      ))}
      {orLoader(totalInstallationTime, (time) => (
        <>
          <Stat
            bold={false}
            key={`${type}#avgTimePerComponent`}
            title={"Average installation time per component"}
            value={`${time ? (time / numFeatures).toFixed(1) : undefined} days`}
          />
        </>
      ))}
      {orLoader(installationDates, (dates) => (
        <>
          <Stat
            bold={false}
            key={`${type}#startDate`}
            title={"Installation start date"}
            value={`${dates?.installationStartDate}`}
          />
          <Stat
            bold={false}
            key={`${type}#endDate`}
            title={"Installation end date (P50)"}
            value={`${dates?.installationEndDate} ${dates?.installationYears && dates.installationYears > 0 ? `+${dates.installationYears} year(s)` : ""}`}
          />
        </>
      ))}
    </>
  );
};

const InstallationTableInner = () => {
  const { triggerId } = useDashboardContext();
  const installationStoppedReason = useAtomValue(
    getInstallationTimeStoppedReason(triggerId),
  );

  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <InstallationCategoryPrinter
        title={"Turbines"}
        type={InstallationType.Turbine}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Floaters"}
        type={InstallationType.Floater}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Monopiles"}
        type={InstallationType.Monopile}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Jackets"}
        type={InstallationType.Jacket}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Scour protection"}
        type={InstallationType.ScourProtection}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Mooring"}
        type={InstallationType.Mooring}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Inter array cables"}
        type={InstallationType.Cable}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Export cables"}
        type={InstallationType.ExportCable}
        id={triggerId}
      />
      <InstallationCategoryPrinter
        title={"Substations"}
        type={InstallationType.Substation}
        id={triggerId}
      />
    </Stats>
  );
};
