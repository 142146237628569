import { TypedArray, fromBlob } from "geotiff";
import { fetchEnhancer } from "services/utils";
import { getProj4StringForEPSGSelectorFamily } from "state/epsg";
import { transformBBOXToWGS84Square } from "utils/proj4";
import { scream } from "utils/sentry";
import { colorBucketsAtom } from "./state";
import { Getter } from "jotai";
import { MapboxBbox4 } from "types/mapbox";

export async function fetchRaster(url: string, get: Getter) {
  const res = await fetchEnhancer(url);

  if (!res.ok) throw scream("Failed to fetch shadow flicker URL", { res, url });

  const blob = await res.blob();
  const geotiff = await fromBlob(blob);

  const image = await geotiff.getImage();
  const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
  const epsg =
    image.geoKeys.ProjectedCSTypeGeoKey || image.geoKeys.GeographicTypeGeoKey;

  let square = [
    { lng: bbox[0], lat: bbox[1] },
    { lng: bbox[2], lat: bbox[1] },
    { lng: bbox[2], lat: bbox[3] },
    { lng: bbox[0], lat: bbox[3] },
  ];

  if (epsg !== 4326) {
    const proj4String = await get(getProj4StringForEPSGSelectorFamily(epsg));
    if (!proj4String) throw scream("Unknown epsg", { epsg });
    square = transformBBOXToWGS84Square(bbox, proj4String);
  }

  const data = await image.readRasters();

  const canvas = document.createElement("canvas");
  canvas.width = data.width;
  canvas.height = data.height;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  var imgData = ctx.createImageData(data.width, data.height);

  const array = data[0] as TypedArray;

  const buckets = get(colorBucketsAtom);

  for (let i = 0, w = 0; i < array.length; i++) {
    let color = buckets.get(array[i]);
    imgData.data[w + 0] = color.r;
    imgData.data[w + 1] = color.g;
    imgData.data[w + 2] = color.b;
    imgData.data[w + 3] = color.a;
    w += 4;
  }

  ctx.putImageData(imgData, 0, 0);

  const rotatedImage = canvas.toDataURL("image/png");

  return {
    blob,
    dataURL: rotatedImage,
    bbox: [
      ...square.map((point) => [point.lng, point.lat]).reverse(),
    ] as MapboxBbox4,
  };
}
