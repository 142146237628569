import { useAtomValue } from "jotai";
import { branchIdAtom } from "state/pathParams";
import { useCallback } from "react";
import { hiddenFeatureIdsFamily } from "state/jotai/hidden";
import { useJotaiCallback } from "utils/jotai";
import { parkChildrenFamily } from "state/jotai/park";
import { useAtom } from "jotai";

const useToggleFeaturesHidden = () => {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const [hiddenCanvasLayerFeatures, setCanvasLayerFeatureHidden] = useAtom(
    hiddenFeatureIdsFamily({
      branchId,
    }),
  );

  const getAreAllFeaturesVisible = useCallback(
    (featureIds: string[]) =>
      featureIds.every(
        (featureId) => !hiddenCanvasLayerFeatures.includes(featureId),
      ),
    [hiddenCanvasLayerFeatures],
  );

  const toggleFeaturesHidden = useJotaiCallback(
    async (get, _, idsToToggle: string[], includeChildren: boolean) => {
      let allIds: string[];
      if (includeChildren) {
        allIds = (
          await Promise.all(
            idsToToggle.flatMap(async (id) => {
              const childIds = (
                await get(
                  parkChildrenFamily({
                    parkId: id,
                    branchId: undefined,
                  }),
                )
              ).map((f) => f.id);
              return [id, ...childIds];
            }),
          )
        ).flat();
      } else {
        allIds = idsToToggle;
      }

      const allFeaturesAreVisible = getAreAllFeaturesVisible(allIds);
      setCanvasLayerFeatureHidden(
        !allFeaturesAreVisible
          ? hiddenCanvasLayerFeatures.filter(
              (hiddenId) => !allIds.includes(hiddenId),
            )
          : hiddenCanvasLayerFeatures.concat(allIds),
      );
    },
    [
      getAreAllFeaturesVisible,
      hiddenCanvasLayerFeatures,
      setCanvasLayerFeatureHidden,
    ],
  );

  return {
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    hiddenCanvasLayerFeatures,
  };
};

export default useToggleFeaturesHidden;
