import { useAtomValue } from "jotai";
import { orgCableManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibraryCable } from "components/Ably/ChannelProviders/Organisation/Library/Cable/useAblyLibraryCable";
interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function CableLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgCableManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_cable_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyCableChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyCableChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibraryCable(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_cable_manage"}
    />
  );
}
