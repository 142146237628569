import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { GridWithFiveSmallerColumns } from "../styles";

const Title = styled.div`
  ${typography.sub3}
  color: ${colors.textSecondary};
`;

const BlueDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.blue500}};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  padding: 2rem 0;
`;

export const CostTopRow = () => {
  return (
    <Wrapper>
      <GridWithFiveSmallerColumns style={{ padding: 0 }}>
        <Title>Type</Title>
        <Title>Cost</Title>
        <Title>Occurance</Title>
        <Title>Frequency</Title>
      </GridWithFiveSmallerColumns>
      <BlueDivider />
    </Wrapper>
  );
};
