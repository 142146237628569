import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { clamp } from "../../utils/utils";
import { Comp } from "../../types/utils";
import { Detail } from "../../styles/typography";
import { spaceLarge, spaceMedium, spaceTiny } from "../../styles/space";
import { Input } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import { isNumber } from "utils/predicates";

const trackHeight = 0.25;
const thumbHeight = 1.25;
const notchHeight = 0.75;

const transition = "background 200ms ease;";

const SliderInput = styled.input<{ trackColor: string; inputColors?: boolean }>`
  appearance: none;
  background: transparent;
  cursor: pointer;

  // Chrome
  &::-webkit-slider-runnable-track {
    height: ${trackHeight}rem;
    border-radius: ${trackHeight / 2}rem;
    background: ${(p) => (p.inputColors ? "none" : p.trackColor)};
    transition: ${transition};
    margin: ${(thumbHeight - trackHeight) / 2}rem 0;
    ${(p) => p.inputColors && `z-index: 1;`}
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: ${thumbHeight}rem;
    width: ${thumbHeight}rem;
    margin-top: ${trackHeight / 2 - thumbHeight / 2}rem;
    background: ${colors.brand};
    transition: ${transition};
    border-radius: 1rem;
  }

  &:disabled {
    cursor: initial;

    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
      background: ${colors.primaryDisabled};
    }

    &::-moz-range-track,
    &::-moz-range-thumb,
    &::-moz-range-progress {
      background: ${colors.primaryDisabled};
    }

    &::-ms-track,
    &::-ms-thumb {
      background: ${colors.primaryDisabled};
    }
  }

  // Firefox
  &::-moz-range-track {
    height: ${trackHeight}rem;
    border-radius: ${trackHeight / 2}rem;
    background: ${colors.primaryDisabled}80;
    transition: ${transition};
  }

  &::-moz-range-progress {
    height: ${trackHeight}rem;
    border-radius: ${trackHeight}rem;
    background: ${(p) => p.trackColor};
    transition: ${transition};
  }

  &::-moz-range-thumb {
    appearance: none;
    height: ${thumbHeight}rem;
    width: ${thumbHeight}rem;
    margin-top: ${trackHeight / 2 - thumbHeight / 2}rem;
    background: ${colors.brand};
    transition: ${transition};
    border-radius: 1rem;
    border: none;
  }

  // Edge
  &::-ms-track {
    height: ${trackHeight}rem;
    border-radius: ${trackHeight / 2}rem;
    background: ${(p) => p.trackColor};
    transition: ${transition};
    margin: ${(thumbHeight - trackHeight) / 2}rem 0;
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight}rem;
    width: ${thumbHeight}rem;
    margin-top: ${trackHeight / 2 - thumbHeight / 2}rem;
    background: ${colors.brand};
    transition: ${transition};
    border-radius: 1rem;
  }
`;

const computeDataListLeft = ({
  min,
  max,
  value,
}: {
  min: number;
  max: number;
  value: number;
}): string => {
  // Match up data list notches to be perfectly aligned with the slider.
  // 0.5px is half notch width.
  const farLeft = `calc(${thumbHeight / 2}rem - 0.5px)`;
  const farRight = `calc(100% - ${thumbHeight / 2}rem - 0.5px)`;
  const f = (value - min) / (max - min);
  const pos = `calc(${f} * calc(${farRight} - ${farLeft}) + ${farLeft})`;
  return pos;
};

const DataList = styled.div<{
  min: number;
  max: number;
  value: number;
  disabled?: boolean;
}>`
  border-radius: 1px;
  pointer-events: none;
  position: absolute;
  width: 1px;
  height: ${notchHeight}rem;
  background: ${(p) => (p.disabled ? colors.primaryDisabled : colors.brand)};
  color: red;
  top: calc(50% - ${notchHeight / 2}rem);
  left: ${computeDataListLeft};
  z-index: -1;
`;

const WrapDiv = styled.div<{ hasLabel?: boolean }>`
  height: ${thumbHeight}rem;
  ${(p) => p.hasLabel && `margin-bottom: calc(${spaceLarge} + 1.6rem);`}
  position: relative;
  display: flex;
  z-index: 1;

  input {
    height: inherit;
  }

  > * {
    align-self: start;
  }
`;

type SliderProps = {
  value: number;
  min?: number;
  max?: number;
  datalist?: number[];
  onChange: (f: number, e: ChangeEvent<HTMLInputElement>) => void;
  label?: boolean;
  renderLabel?: (f: number) => ReactNode;
  enableInput?: boolean;
};

const SliderLabelDiv = styled.div.attrs<{
  min: number;
  max: number;
  value: number;
  editable?: boolean;
}>((props) => ({
  style: {
    left: `calc(${computeThumbLeft(props)} + ${thumbHeight / 2}rem)`,
  },
}))<
  DummyThumbAttrs & {
    clickable?: boolean;
  }
>`
  position: absolute;
  top: calc(${thumbHeight}rem + ${spaceTiny});
  transform: translateX(-50%);
  white-space: nowrap;
  padding: ${spaceTiny} ${spaceMedium};
  border-radius: 0.4rem;
  background: ${colors.surfaceInfo};
  color: ${colors.textBrand};
  ${({ clickable }) =>
    clickable &&
    `cursor: pointer; &:hover {
    background-color: ${colors.surfaceHover};
  }`};
`;

export const Slider = ({
  value,
  min = 0,
  max = 100,
  onChange = () => {},
  datalist = [],
  label,
  renderLabel = (f) => f.toString(),
  enableInput = true,
  ...props
}: Comp<"input", SliderProps>) => {
  return (
    <WrapDiv style={props.style} hasLabel={!!label}>
      {datalist.map((num) => (
        <DataList
          min={min}
          max={max}
          value={num}
          key={num}
          disabled={props.disabled}
        />
      ))}
      <SliderInput
        trackColor={colors.brand}
        type="range"
        value={value}
        min={min}
        max={max}
        onChange={(e) => {
          onChange(parseFloat(e.target.value), e);
        }}
        {...props}
      />
      {label && (
        <EditableSliderLabelDiv
          min={min}
          max={max}
          value={value}
          enableTextInput={enableInput}
          onAfterEdit={(newMin) => {
            const newValue = Math.min(Math.max(newMin, min), max);
            onChange(
              newValue,
              // `onChange` is supposed to also return the event that triggered the change,
              // but in this case we don't have the right event type.  Maybe it's okay to return
              // a dummy event, didn't look like we were using it anywhere anyways?
              //
              // Add a random UUID to make it searchable if the event is ever looked at.
              new Event("539f7696-bd76-4f99-9317-220754674619") as any,
            );
          }}
        >
          {renderLabel(value)}
        </EditableSliderLabelDiv>
      )}
    </WrapDiv>
  );
};

const computeThumbLeft = ({
  min,
  max,
  value,
  size = thumbHeight,
}: {
  min: number;
  max: number;
  value: number;
  size?: number;
}) => {
  const f = (value - min) / (max - min);
  const pos = `calc(${f} * calc(100% - ${thumbHeight}rem) + ${
    thumbHeight / 2
  }rem - ${size / 2}rem)`;
  return pos;
};

type DummyThumbAttrs = {
  min: number;
  max: number;
  value: number;
  disabled?: boolean;
};
const DummyThumb = styled.div.attrs<DummyThumbAttrs>((props) => ({
  style: {
    left: computeThumbLeft(props),
    zIndex: 1,
  },
}))<DummyThumbAttrs>`
  position: absolute;
  width: ${thumbHeight}rem;
  height: ${thumbHeight}rem;
  border-radius: ${thumbHeight / 2}rem;
  background: ${(p) => (p.disabled ? colors.primaryDisabled : colors.brand)};
  pointer-events: none;
  transition: ${transition};
`;

type DummyTrackAttrs = {
  min: number;
  max: number;
  from: number;
  to: number;
  trackColor?: string | undefined;
  disabled?: boolean;
};
const DummyTrack = styled.div.attrs<DummyTrackAttrs>(
  ({ min, max, from, to }) => ({
    style: {
      width: `${(100 * (to - from)) / (max - min)}%`,
      left: `${(100 * (from - min)) / (max - min)}%`,
    },
  }),
)<DummyTrackAttrs>`
  height: ${(p) => (p.trackColor ? "0.6rem" : `${trackHeight}rem`)};
  top: ${thumbHeight / 2 - trackHeight / 2}rem;
  border-radius: ${trackHeight / 2}rem;
  position: absolute;
  background: ${(p) =>
    p.trackColor
      ? p.trackColor
      : p.disabled
        ? colors.primaryDisabled
        : colors.brand};
  pointer-events: none;
  transition: ${transition};
`;

const EditableSliderLabelDiv = ({
  min,
  max,
  value,
  enableTextInput,
  onAfterEdit,
  children,
}: Pick<DummyTrackAttrs, "min" | "max"> & {
  value: number;
  enableTextInput: boolean;
  onAfterEdit(newValue: number): void;
} & React.PropsWithChildren) => {
  const [isEditing, setIsEditing] = useState(false);
  const [state, onChange, setState] = useTextInput(value.toString());

  // Set the state to the value when we start editing
  useEffect(() => {
    if (isEditing) {
      setState(value.toString());
    }
  }, [isEditing, setState, value]);

  const onBlurOrEnter = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLInputElement>,
    ) => {
      setIsEditing(false);
      const numValue = parseFloat(e.currentTarget.value);
      if (!isNumber(numValue)) {
        return;
      }

      onAfterEdit(numValue);
    },
    [onAfterEdit],
  );

  const valueIsValid = () => {
    const numValue = parseFloat(state);
    return isNumber(numValue) && numValue >= min && numValue <= max;
  };

  return (
    <SliderLabelDiv
      min={min}
      max={max}
      value={value}
      clickable={enableTextInput}
      style={{
        zIndex: 1,
      }}
      onClick={
        enableTextInput
          ? (e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsEditing(true);
            }
          : undefined
      }
    >
      {isEditing ? (
        <Input
          autoFocus
          style={{
            width: "30px",
            fontSize: "0.8rem",
            padding: "0.1rem",
          }}
          invalid={!valueIsValid()}
          value={state}
          onChange={onChange}
          onEnter={onBlurOrEnter}
          onBlur={onBlurOrEnter}
          onCancel={() => {
            setIsEditing(false);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ) : (
        <Detail style={{ margin: 0 }}>{children}</Detail>
      )}
    </SliderLabelDiv>
  );
};

type RangeSliderProps = {
  values: [number, number];
  min?: number;
  max?: number;
  datalist?: number[];
  onChange: (f: [number, number], e?: ChangeEvent<HTMLInputElement>) => void;
  enableInput?: boolean;
  /** Highlight the inside of the interval. */
  inside?: boolean;
  /** Highlight the outside of the interval. */
  outside?: boolean;
  labels?: boolean;
  extremesLabels?: boolean;
  inputColors?: string[];
  renderLabel?: (number: number, thumbIndex: number) => ReactNode;
};

export const RangeSlider = ({
  values,
  min = 0,
  max = 100,
  onChange = () => {},
  datalist = [],
  inside,
  outside,
  labels,
  extremesLabels,
  inputColors,
  enableInput = true,
  renderLabel = (f) => f.toString(),
  ...props
}: Comp<"input", RangeSliderProps>) => {
  const _values = values.map((v) => clamp(min, v, max));
  // Register this so that we only check the closest slider when the mouse is clicked
  // and not if we move one slider over the other.
  const [isFirstEvent, setIsFirstEvent] = useState(true);
  const [control, setControl] = useState(0);
  const from = Math.min(..._values);
  const to = Math.max(..._values);

  return (
    <WrapDiv style={props.style} hasLabel={labels}>
      {datalist.map((num) => (
        <DataList
          min={min}
          max={max}
          value={num}
          key={num}
          disabled={props.disabled}
        />
      ))}
      <SliderInput
        type="range"
        trackColor={`${colors.primaryDisabled}80`}
        inputColors={!!inputColors}
        value={_values[control]}
        min={min}
        max={max}
        // It's not clear which of the sliders to move with key presses, so we disable both
        onKeyDown={(e) => e.preventDefault()}
        onMouseDown={() => {
          setIsFirstEvent(true);
        }}
        onChange={(e) => {
          if (e.nativeEvent.type === "change") {
            // Weird behaviour here.  For some reason, *sometimes*, an event like this
            // is triggered when releasing the mouse, and the value sent is the *other*
            // value of the double slider.
            // That is, if the slider is [10, 20], and I click on 12 indenting to move
            // the left slider, I first get an event with value 12 and with nativeEvent.type
            // "input", and then an event with value 20 and with nativeEvent.type "change".
            //
            // This only happened with the double slider in "Slope Analysis" and not in
            // "Depth Analysis", but I don't know why.
            return;
          }
          setIsFirstEvent(false);
          const val = parseFloat(e.target.value);
          let ctrl = control;
          if (isFirstEvent) {
            // If this is the first time we handle an event, we need to figure out which
            // thumb to control.  For consequent events, we use the same thumb, so that dragging works
            // as expected.
            const distToLow = Math.abs(val - _values[0]);
            const distToHigh = Math.abs(val - _values[1]);
            const newControl = distToLow < distToHigh ? 1 : 0;
            setControl(newControl);
            ctrl = newControl;
          }

          onChange(
            [_values[ctrl], val].sort((a, b) => a - b) as [number, number],
            e,
          );
        }}
        {...props}
      />
      <DummyThumb
        min={min}
        max={max}
        value={_values[1 - control]}
        disabled={props.disabled}
      />

      {inside && (
        <DummyTrack
          min={min}
          max={max}
          from={from}
          to={to}
          trackColor={inputColors ? inputColors[1] : undefined}
          disabled={props.disabled}
        />
      )}
      {outside && (
        <>
          <DummyTrack
            min={min}
            max={max}
            from={min}
            to={from}
            trackColor={inputColors ? inputColors[0] : undefined}
            disabled={props.disabled}
          />
          <DummyTrack
            min={min}
            max={max}
            from={to}
            to={max}
            trackColor={inputColors ? inputColors[2] : undefined}
            disabled={props.disabled}
          />
        </>
      )}
      {labels && (
        <>
          <EditableSliderLabelDiv
            min={min}
            max={max}
            value={_values[control]}
            enableTextInput={enableInput}
            onAfterEdit={(newMin) => {
              const newValue = Math.min(Math.max(newMin, min), max);

              onChange(
                [_values[1 - control], newValue].sort((a, b) => a - b) as [
                  number,
                  number,
                ],
              );
            }}
          >
            {renderLabel(_values[control], control)}
          </EditableSliderLabelDiv>
          <EditableSliderLabelDiv
            min={min}
            max={max}
            value={_values[1 - control]}
            enableTextInput={enableInput}
            onAfterEdit={(newMax) => {
              const newValue = Math.min(Math.max(newMax, min), max);
              onChange(
                [_values[control], newValue].sort((a, b) => a - b) as [
                  number,
                  number,
                ],
              );
            }}
          >
            {renderLabel(_values[1 - control], 1 - control)}
          </EditableSliderLabelDiv>
        </>
      )}
      {extremesLabels && (
        <>
          <SliderLabelDiv
            min={min}
            max={max}
            value={min}
            style={{ background: "none" }}
          >
            {min}
          </SliderLabelDiv>
          <SliderLabelDiv
            min={min}
            max={max}
            value={max}
            style={{ background: "none" }}
          >
            {max}
          </SliderLabelDiv>
        </>
      )}
    </WrapDiv>
  );
};
