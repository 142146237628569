import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import React, {
  ErrorInfo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createVerticalStrength, useDndScrolling } from "react-dnd-scrolling";
import { ErrorBoundary } from "react-error-boundary";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { useAtom, useSetAtom } from "jotai";
import { useShowScrollShadow } from "../../hooks/useShowScrollShadow";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import UploadIcon from "@icons/24/Upload.svg";
import { Mixpanel } from "../../mixpanel";
import { midScreenModalTypeOpenAtom } from "../../state/modal";
import { inReadOnlyModeSelector } from "../../state/project";
import { editorAccessProjectSelector } from "../../state/user";
import { SkeletonBlock } from "../Loading/Skeleton";
import { isPark, featureIsLocked, isDefined } from "../../utils/predicates";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import {
  ANIMATION_DURATION,
  LeftModalContainer,
  CONTAINER_MIN_WIDTH,
} from "../Design/styles";
import { ErrorBoundaryLocalFallback } from "../ErrorBoundaries/ErrorBoundaryLocal";
import { logError } from "../ErrorBoundaries/utils";
import Button from "../General/Button";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { UploadFileType, UploadModalType } from "../UploadModal/UploadModal";
import { useHorizontalResize } from "../ResizeBar/ResizeBarVertical";
import ParkElement from "./ParkElement";
import ProjectFolder from "./ProjectFolder";
import ProjectFeatureItem from "./ProjectFeatureItem";
import {
  ProjectElementsContextProvider,
  useProjectElementsContext,
} from "./ProjectElementsContext";
import {
  ProjectElementsV2Wrapper,
  ProjectElementsList,
  ToplevelDropTargetDiv,
} from "./ProjectElementsV2.style";
import {
  useProjectElementsFoldersCrud,
  useRefreshProjectElementsFolders,
} from "./useProjectElementsFoldersCrud";
import { ProjectElementFolderType } from "./service";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";
import { getBranchSelectorFamily } from "state/timeline";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceMedium, spacing4 } from "styles/space";
import { moveToFolder, useDnDRootHooks } from "./hooks";
import { useDrop } from "react-dnd";
import {
  DropCollectedProps,
  DropOnFeatureResults,
  ElementTreeNode,
  isFolder,
} from "./types";
import {
  PROJECT_ELEMENT_FOLDER,
  PROJECT_ELEMENT_FOLDER_ITEM,
  PROJECT_ELEMENT_ORPHAN_ITEM,
  PROJECT_ELEMENT_PARK,
  elementTreeFind,
  elementTreeFlatten,
} from "./utils";
import { getDropCollect } from "./shared-dnd-callbacks";

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${colors.inputOutline};
  padding: 1rem 1rem 0;
  justify-content: flex-end;
  gap: ${spacing4};
  margin-top: ${spaceMedium};
  flex-shrink: 0;
`;

/**
 * A small box at the bottom of the elements list where you can drop things to
 * place them on the outer level.
 */
const ToplevelDropTarget = () => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    reorderTopLevel,
    updateProjectElementsFolder,
    editorAccessProject,
    isReadOnly,
  } = useProjectElementsContext();

  const [hover, setHover] = useState<boolean>(false);

  const dnd = useDnDRootHooks({
    setHoverState: setHover,
    reorderTopLevel,
    updateFolder: updateProjectElementsFolder,
  });

  const [dropCollection, dropRef] = useDrop<
    ElementTreeNode[],
    DropOnFeatureResults,
    DropCollectedProps
  >(
    () => ({
      accept: [
        PROJECT_ELEMENT_ORPHAN_ITEM,
        PROJECT_ELEMENT_FOLDER_ITEM,
        PROJECT_ELEMENT_FOLDER,
        PROJECT_ELEMENT_PARK,
      ],
      collect: getDropCollect(),
      hover: dnd.hover,
      canDrop: () => editorAccessProject && !isReadOnly,
      drop: dnd.drop,
    }),
    [editorAccessProject, isReadOnly, dnd.drop, dnd.hover],
  );

  dropRef(ref);

  return (
    <ToplevelDropTargetDiv
      enableDrag={true}
      ref={ref}
      isHoveredTop={dropCollection.isHovered && hover}
    />
  );
};

const ProjectElementsInner = ({
  outsideScrollToFolderId,
}: {
  outsideScrollToFolderId?: string;
}) => {
  const {
    editorAccessProject,
    isReadOnly,
    projectFeatures,
    updateFeatures,
    updateProjectElementsFolder,
    createProjectElementsFolder,
    setCurrentSelectionArray,
    currentSelectionArray,
    deselectAllFeatures,
    toggleFeaturesHidden,
    getAreAllFeaturesVisible,
    tree,
  } = useProjectElementsContext();
  const [scrollToFolderId, setScrollToFolderId] = useState<
    string | undefined
  >();
  const scrollBodyRef = useRef<ViewportListRef>(null);
  const { showBottomShadow, scrollBodyRef: orphanListRef } =
    useShowScrollShadow<HTMLDivElement>();
  useDndScrolling(orphanListRef, {
    verticalStrength: createVerticalStrength(200),
  });

  useEffect(() => {
    setScrollToFolderId(outsideScrollToFolderId);
  }, [outsideScrollToFolderId]);

  const onAddFeatureToFolder = useCallback(
    (folder: ProjectElementFolderType, featureIds: string[]) => {
      const nodes = featureIds
        .map((id) => elementTreeFind(tree, (n) => n.id === id))
        .filter(isDefined);
      const target = elementTreeFind(tree, (n) => n.id === folder.folderId);
      if (!target || !isFolder(target)) return;

      moveToFolder(nodes, target, updateProjectElementsFolder);
      Mixpanel.track_old("Add feature to ProjectElement folder", {
        numberFeatures: nodes.length,
      });
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    },
    [setCurrentSelectionArray, tree, updateProjectElementsFolder],
  );

  const onCreateFolder = useCallback(
    async (featureIds: string[]) => {
      const idsToAdd =
        currentSelectionArray.length > 1 ? currentSelectionArray : featureIds;

      Mixpanel.track_old("Create ProjectElement folder", {
        numberFeatures: idsToAdd.length,
      });
      const newFolder = await createProjectElementsFolder(
        {
          featureIds: idsToAdd.map((id) => ({
            type: "feature",
            id,
          })),
          folderName: "Untitled folder",
        },
        (tempFolderId) => {
          setScrollToFolderId(tempFolderId);
          setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
        },
      );
      if (!newFolder) return;
      setScrollToFolderId(newFolder.folderId);
      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    },
    [
      createProjectElementsFolder,
      currentSelectionArray,
      setCurrentSelectionArray,
    ],
  );

  const onDeleteFeatures = useCallback(
    (featureIds: string[]) => {
      const selectedFeaturesOrFeatureIds =
        currentSelectionArray.length > 0 ? currentSelectionArray : featureIds;

      const toBeRemoved = projectFeatures
        .filter(
          (f) =>
            selectedFeaturesOrFeatureIds.includes(f.id) ||
            f.properties.parentIds?.some((parentId) =>
              selectedFeaturesOrFeatureIds.includes(parentId),
            ),
        )
        .filter((f) => !featureIsLocked(f))
        .map((f) => f.id);
      updateFeatures({
        remove: toBeRemoved,
      });
      deselectAllFeatures();
    },
    [
      projectFeatures,
      updateFeatures,
      deselectAllFeatures,
      currentSelectionArray,
    ],
  );

  const allFeaturesRecursive = useMemo(
    () => elementTreeFlatten(tree).map((n) => n.feature),
    [tree],
  );

  const allFeatureIdsRecursive = useMemo(
    () => allFeaturesRecursive.map((n) => n.id),
    [allFeaturesRecursive],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(allFeatureIdsRecursive),
    [getAreAllFeaturesVisible, allFeatureIdsRecursive],
  );

  return (
    <ProjectElementsV2Wrapper showBottomShadow={showBottomShadow}>
      {tree.children.length === 0 ? (
        <div
          style={{
            padding: "0 1.6rem",
          }}
        >
          <p id={"no-elements-message"}>
            Elements you draw in the map will appear here, like turbines and
            cables.
          </p>
        </div>
      ) : (
        <ProjectElementsList id={"element-list"} ref={orphanListRef}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: spacing4,
            }}
          >
            <Button
              buttonType="text"
              size="small"
              text={
                allFeaturesAreVisible
                  ? "Hide all elements"
                  : "Show all elements"
              }
              onClick={(e) => {
                e.stopPropagation();
                toggleFeaturesHidden(allFeatureIdsRecursive, true);
              }}
            />
          </div>
          <ViewportList
            ref={scrollBodyRef}
            viewportRef={orphanListRef}
            items={tree.children}
            itemSize={36}
          >
            {(node, index) => {
              if (node.type === "folder") {
                return (
                  <ProjectFolder
                    key={node.id}
                    folderNode={node}
                    defaultOpen={
                      scrollToFolderId === node.folder.folderId &&
                      node.folder.featureIds.length < 50
                    }
                    onDeleteFeatures={onDeleteFeatures}
                  />
                );
              }
              if (isPark(node.feature))
                return (
                  <ParkElement
                    node={node}
                    key={node.id}
                    park={node.feature}
                    onAddToFolder={onAddFeatureToFolder}
                    onCreateFolder={onCreateFolder}
                    onDeleteFeatures={onDeleteFeatures}
                    depth={0}
                  />
                );
              return (
                <ProjectFeatureItem
                  node={node}
                  key={node.id}
                  feature={node.feature}
                  name={node.feature.properties.name ?? ""}
                  draggable={editorAccessProject && !isReadOnly}
                  isSelected={currentSelectionArray.includes(node.feature.id)}
                  isMultiSelect={currentSelectionArray.length > 1}
                  onAddToFolder={onAddFeatureToFolder}
                  onCreateFolder={onCreateFolder}
                  onDeleteFeatures={onDeleteFeatures}
                  sortIndex={index}
                />
              );
            }}
          </ViewportList>
          <ToplevelDropTarget />
        </ProjectElementsList>
      )}
    </ProjectElementsV2Wrapper>
  );
};

const menuFrameStyle: React.CSSProperties = {
  color: "black",
  flex: 1,
  minWidth: CONTAINER_MIN_WIDTH,
  width: "var(--left-menu-width)",
  maxWidth: "40vw",
  resize: "horizontal",
};

const CreateFolderButton = ({
  onAfterCreateFolder,
}: {
  onAfterCreateFolder(createdFolderId: string): void;
}) => {
  const { create: createProjectElementsFolder } =
    useProjectElementsFoldersCrud();

  const handleOnClick = useCallback(async () => {
    const folder = await createProjectElementsFolder(
      {
        featureIds: [],
        folderName: "Untitled folder",
      },
      (tempFolderId) => {
        onAfterCreateFolder(tempFolderId);
      },
    );
    if (!folder) return;
    onAfterCreateFolder(folder.folderId);
  }, [createProjectElementsFolder, onAfterCreateFolder]);

  return (
    <Button
      buttonType="secondary"
      icon={<FolderAddIcon />}
      onClick={handleOnClick}
    />
  );
};

const ProjectElementsV2 = () => {
  const refreshProjectElementsFolders = useRefreshProjectElementsFolders();
  const frameRef = useRef<HTMLDivElement>(null);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const branch = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const branchName = branch?.title ?? "Banch";
  useHorizontalResize(frameRef, "--left-menu-width");

  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const [scrollToFolderId, setScrollToFolderId] = useState<
    string | undefined
  >();
  const [hide, setHide] = useState(true);
  const [showSmall, setShowSmall] = useState(true);

  const _onError = useCallback((error: Error, info: ErrorInfo) => {
    logError("project_elements", error, info);
  }, []);

  useEffect(() => {
    let delay: NodeJS.Timeout;
    if (leftModalMenuOpen === LeftModalMenuTypes.Elements) {
      setHide(false);
      delay = setTimeout(() => setShowSmall(false), 30);
    } else {
      delay = setTimeout(() => {
        setHide(true);
        setShowSmall(true);
      }, ANIMATION_DURATION * 985);
    }
    return () => clearTimeout(delay);
  }, [leftModalMenuOpen]);

  const closeMenu = useCallback(() => {
    setLeftModalMenuOpen(undefined);
  }, [setLeftModalMenuOpen]);

  const onUploadClick = useCallback(() => {
    setMidScreenModalTypeOpen({
      modalType: UploadModalType,
      metadata: {
        preSelectedFileType: UploadFileType.PROJECT_FEATURE,
      },
    });
  }, [setMidScreenModalTypeOpen]);

  if (hide) {
    return null;
  }

  return (
    <LeftModalContainer
      open={leftModalMenuOpen === LeftModalMenuTypes.Elements && !showSmall}
    >
      <MenuFrame
        ref={frameRef}
        title="Elements"
        subtitle={branchName}
        style={menuFrameStyle}
        onExit={closeMenu}
      >
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryLocalFallback}
          onReset={refreshProjectElementsFolders}
          onError={_onError}
        >
          <React.Suspense
            fallback={
              <SkeletonBlock
                style={{
                  margin: "1rem",
                  boxSizing: "border-box",
                  width: "calc(100% - 2rem)",
                }}
              />
            }
          >
            <ProjectElementsContextProvider>
              <ProjectElementsInner
                outsideScrollToFolderId={scrollToFolderId}
              />
            </ProjectElementsContextProvider>
            {editorAccessProject && !isReadOnly && (
              <BottomButtonsContainer>
                <CreateFolderButton onAfterCreateFolder={setScrollToFolderId} />
                <Button
                  buttonType="secondary"
                  text="Upload"
                  icon={<UploadIcon />}
                  onClick={onUploadClick}
                />
              </BottomButtonsContainer>
            )}
          </React.Suspense>
        </ErrorBoundary>
      </MenuFrame>
    </LeftModalContainer>
  );
};

export default ProjectElementsV2;
