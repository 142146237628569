import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { useEffect, useMemo } from "react";
import {
  costLayerVariablesAtom,
  costLayerRangeAtom,
  costLayerFilterAtom,
  lowerRightMenuActiveModeAtom,
  CostLayerVariables,
} from "../state/layer";
import { Map as MapboxMap, MercatorCoordinate } from "mapbox-gl";
import { scream } from "../utils/sentry";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { MaybePromise } from "types/utils";
import { wrapMapboxTile } from "types/tile";

const tileSize = 512;
const maxzoom = 6;

const CostLayerId = "custom-combination-layer";

class Layer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  program: any;
  vertexBuffer: any;
  map: MapboxMap | undefined;
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;

  input: CostLayerVariables | undefined;
  range: number[];
  vindFilter: number[];
  isSubmitted: boolean;
  urlFuncDepth = (z: number, x: number, y: number) =>
    `/tiles/gebco-terrarium-2023/${z}/${x}/${y}.png`;

  urlFuncShoreDistance = (z: number, x: number, y: number) =>
    `/tiles/shore/${z}/${x}/${y}.png`;

  urlFuncWeibull = (z: number, x: number, y: number) =>
    `/tiles/gwa/capacity-iec2/${z}/${x}/${y}.png`;

  constructor(maxZoom: number, tileSize: number) {
    this.id = CostLayerId;
    this.type = "custom";
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.textureCache = new Map();

    this.range = [0, 100];
    this.vindFilter = [0, 100];
    this.isSubmitted = false;
  }

  updateVariables(
    input: CostLayerVariables,
    filter: number[],
    range: number[],
  ) {
    this.input = input;
    this.range = range;
    this.vindFilter = filter;
  }

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;

    const vertexSource = `
      attribute vec2 a_pos;
      varying vec2 v_pos;
      uniform vec2 u_minMax;
      void main() {
        v_pos = vec2(a_pos / 1.);
        gl_Position = vec4(a_pos, 1.0, 1.0);
      }`;

    var fragmentSource = `
      precision highp float;
      const float years = 25.;
      const float MwPerTurbine = 15.;
      const float discount_rate = 0.05;

      uniform sampler2D u_depth_raster;
      uniform sampler2D u_shoreline_distance_raster;
      uniform sampler2D u_weibull_raster;

      uniform float u_turbine_cost;
      uniform float u_fixed_cost;
      uniform float u_fixed_cost_depth;
      uniform float u_floating_cost;
      uniform float u_floating_cost_depth;
      uniform float u_fixed_to_floating_depth;
      uniform float u_export_cable_cost_shore_distance;
      uniform float u_opex_per_mw;
      uniform vec4 u_uv_offsets;
      uniform vec2 u_range;
      uniform vec2 u_filter;
      varying vec2 v_pos;

      float getDepth(vec2 coord) {
          vec4 color = texture2D(u_depth_raster, coord);
          float R = color.r * 255.0;
          float G = color.g * 255.0;
          float B = color.b * 255.0;
          return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
      }

      float getShorelineDistanceKM(vec2 coord) {
          return texture2D(u_shoreline_distance_raster, coord).r * 256.;
      }

      void main() {
          vec2 uv = 0.5 * v_pos + 0.5;
          uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
          uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

          float depth = getDepth(uv);

          float shoreLineDistance = getShorelineDistanceKM(uv);

          vec4 gwa = texture2D(u_weibull_raster, uv);

          float capacity = gwa.r * 0.8;
          float power = capacity * 15.;

          float capex = u_turbine_cost +
            (u_fixed_cost + u_fixed_cost_depth * depth) * step(depth, u_fixed_to_floating_depth) +
            (u_floating_cost + u_floating_cost_depth * depth) * step(u_fixed_to_floating_depth,depth) +
            u_export_cable_cost_shore_distance * shoreLineDistance;

          float opex_per_year = u_opex_per_mw;
          float opex = opex_per_year * (1. - pow(1. + discount_rate, -years)) / discount_rate;
          float eur = 1000. * (capex + opex);
          float mwh = power * 8766. * years;
          float y = MwPerTurbine * eur / mwh;

          float green_limit = u_range.x;
          float red_limit = u_range.y;

          float x = (y - green_limit) / (red_limit - green_limit);

          float r = 2.* x;
          float g = 2. * (1.-x);
          float b = 0.;

          float alpha = step(1., shoreLineDistance) * gwa.a;
          alpha *= step(u_filter.x, y);
          alpha *= 1. - step(u_filter.y, y);

          gl_FragColor = vec4(r, g, b, alpha);
      }`;

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader)
      throw scream("bathymetry: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("bathymetry: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS))
      throw scream("Failed to create program", {
        program: this.program,
        vertexShader,
        fragmentShader,
        programInfo: gl.getProgramInfoLog(this.program),
        vertexInfo: gl.getShaderInfoLog(vertexShader),
        fragmentInfo: gl.getShaderInfoLog(fragmentShader),
      });

    this.program.aPos = gl.getAttribLocation(this.program, "a_pos");
    this.program.uColor = gl.getUniformLocation(this.program, "u_color");

    const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);
  };

  shouldRerenderTiles = () => {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  };

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.input || !this.map) return;
    const map = this.map;
    const urlFuncs = [
      this.urlFuncDepth,
      this.urlFuncShoreDistance,
      this.urlFuncWeibull,
    ];
    const [depthTexture, shoreDistanceTexture, weibullTexture] = urlFuncs.map(
      (urlFunc) =>
        getBestAvailableTexture(
          gl,
          tileId,
          this.textureCache,
          map,
          urlFunc,
          this.maxZoom,
          this.tileSize,
          false,
          () => this.isSubmitted,
        ),
    );

    gl.useProgram(this.program);

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_range"), this.range);
    gl.uniform2fv(
      gl.getUniformLocation(this.program, "u_filter"),
      this.vindFilter,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_turbine_cost"),
      this.input.turbinesPerMw,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_fixed_cost"),
      this.input.fixedFoundationPerMw,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_fixed_cost_depth"),
      this.input.fixedFoundationPerMwDepth,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_floating_cost"),
      this.input.floatingFoundationPerMw,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_floating_cost_depth"),
      this.input.floatingFoundationPerMwDepth,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_fixed_to_floating_depth"),
      this.input.fixedFoundationMaxDepth,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_export_cable_cost_shore_distance"),
      this.input.exportCablePerShoreDistance,
    );
    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_opex_per_mw"),
      this.input.opexPerMw,
    );
    gl.uniform4fv(
      gl.getUniformLocation(this.program, "u_uv_offsets"),
      depthTexture.uvOffsets,
    );

    // Set up the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, depthTexture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);

    // Set up the texture
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, shoreDistanceTexture.texture);
    gl.uniform1i(
      gl.getUniformLocation(this.program, "u_shoreline_distance_raster"),
      1,
    );

    // Set up the texture
    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, weibullTexture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_weibull_raster"), 2);

    // Draw
    this.draw(gl, this.map);
  };

  draw(gl: WebGL2RenderingContext, map: MapboxMap | undefined) {
    if (!map) return;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.program.aPos);
    gl.vertexAttribPointer(this.program.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const CostLayer = () => {
  const lowerRightActiveMode = useAtomValue(lowerRightMenuActiveModeAtom);
  if (lowerRightActiveMode === "cost") return <CostLayerActive />;
  return null;
};

const CostLayerActive = () => {
  const map = useAtomValue(mapAtom);
  const costLayerVariables = useAtomValue(costLayerVariablesAtom);
  const costFilter = useAtomValue(costLayerFilterAtom);
  const costRange = useAtomValue(costLayerRangeAtom);

  const layer = useMemo(() => new Layer(maxzoom, tileSize), []);

  useEffect(() => {
    if (!map || !layer) return;
    layer.updateVariables(costLayerVariables, costFilter, costRange);
    map.triggerRepaint();
  }, [map, costLayerVariables, layer, costFilter, costRange]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(layer, "building");

    return () => {
      if (!map) return;
      map.removeLayer(layer.id);
    };
  }, [map, layer]);

  return null;
};

export default CostLayer;
