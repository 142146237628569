import styled from "styled-components";

export const ColorCircle = styled.div<{ disabled?: boolean }>`
  background-color: ${(p) => p.color ?? "transparent"};
  height: 2rem;
  width: 2rem;
  border-radius: 0.4rem;
  flex-grow: 1;
  ${(p) => !p.disabled && `cursor: pointer;`}
  border: 1px solid #00000020;
`;

export const ColorSelectorWrapper = styled.div`
  position: relative;
`;

export const FeatureColorPickerContainer = styled.div<{
  open?: boolean;
  position?: "left" | "right";
}>`
  position: fixed;
  transform: ${(p) =>
    p.position === "left" ? "translateX(-100%)" : "translateX(0px)"};
  display: ${(p) => (p.open ? "inherit" : "none")};
  z-index: 2;
`;
