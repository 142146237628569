import { atom } from "jotai";
import {
  currentEditStyleIdAtom,
  sourceValueMinMaxSelector,
  sourceValueSelectorFamily,
  styleSelectorFamily,
} from "./state";
import { Defined, dedup, isNever } from "utils/utils";
import {
  BucketStyle,
  CategoryStyle,
  ColorCategory,
  Coloring,
  GradientStyle,
  SingleStyle,
  Style,
  colorKeyType,
  isBucket,
  isCategory,
  isGradient,
  isSingle,
} from "./types";
import { _ParkColorKey, parkLabels } from "./feature/park";
import { _AnchorColorKey, anchorLabels } from "./feature/anchor";
import { _CableColorKey, cableLabels } from "./feature/cable";
import { _TurbineColorKey, turbineLabels } from "./feature/turbine";
import { v4 } from "uuid";
import { Buckets, Color, Gradient } from "lib/colors";
import { labelFromColorKey } from "./constants";
import { RESET, loadable } from "jotai/utils";
import { colors } from "styles/colors";
import { cableTypesFamily } from "state/jotai/cableType";
import { lunwrap } from "utils/jotai";
import { isOnshoreAtom } from "state/onshore";

const setter = atom(null, (_, set, s: Style) => {
  return set(styleSelectorFamily(s.id), s);
});

/**
 * Balance the colors of the style and save it.
 */
export const balanceStyle = atom(null, async (get, set, style: Style) => {
  if (style.source === "single") return;
  const vals = await get(sourceValueMinMaxSelector(style.id));
  if (!vals) return;

  if (style.type === "bucket") {
    const buckets = style.buckets.clone();
    buckets.balance(vals.min, vals.max);
    set(styleSelectorFamily(style.id), {
      ...style,
      type: "bucket",
      buckets,
    });
  } else if (style.type === "gradient") {
    const gradient = style.gradient.clone();
    gradient.balance(vals.min, vals.max);
    set(styleSelectorFamily(style.id), {
      ...style,
      type: "gradient",
      gradient,
    });
  } else if (style.type === "category") {
    /* Ignore, doesn't make sense. */
  } else {
    throw isNever(style);
  }
});

export const createNewFeatureStyle = atom(
  null,
  async (get, set, type: Style["feature"]) => {
    const id = v4();
    let newStyle: Style;
    if (type === "turbines")
      newStyle = {
        id,
        feature: "turbines",
        name: "New style",
        source: "wake-loss",
        type: "gradient",
        gradient: Gradient.Brewer("RdYlBu", 7),
        defaultMarker: false,
        label: "name",
        createdAt: Date.now(),
      };
    else if (type === "cables") {
      const colors = Buckets.Brewer("PiYG", 5)
        .buckets()
        .map((b) => b.color);
      const cableTypes = Array.from(
        (await get(cableTypesFamily({ projectId: undefined }))).values(),
      );
      cableTypes.sort((a, b) => (a.ampacity ?? 0) - (b.ampacity ?? 0));

      const categories: ColorCategory[] = cableTypes.map((ct, i) => ({
        color: colors[i % colors.length],
        id: ct.id,
        label: ct.name,
      }));

      newStyle = {
        id,
        feature: "cables",
        name: "New style",
        source: "cable-type",
        type: "category",
        defaultMarker: false,
        categories,
        createdAt: Date.now(),
      };
    } else if (type === "anchors")
      newStyle = {
        id,
        feature: "anchors",
        name: "New style",
        source: "depth",
        type: "bucket",
        buckets: Buckets.Brewer("BuPu", 5),
        defaultMarker: false,
        createdAt: Date.now(),
      };
    else if (type === "parks") {
      const onshore = get(isOnshoreAtom);
      newStyle = {
        id,
        feature: "parks",
        name: "New style",
        source: "single",
        type: "single",
        color: Color.fromHex(onshore ? colors.onElParkSurface : colors.park),
        defaultMarker: false,
        createdAt: Date.now(),
      };
    } else throw isNever(type);

    set(styleSelectorFamily(id), newStyle);
    // We need some delay here so that the effect that closes "Edit Style" when
    // its given an illegal ID doesn't fire too soon.
    setTimeout(() => {
      set(currentEditStyleIdAtom, id);
    }, 10);
  },
);

/**
 * Enable labels for a style, setting it to a valid value.
 */
export const enableLabelOnStyle = atom(null, (get, set, styleId: string) => {
  const style = get(styleSelectorFamily(styleId));
  if (!style) throw new Error("Illegal style id");
  let newStyle: Style;
  if (style.feature === "turbines")
    newStyle = { ...style, label: turbineLabels[0] };
  else if (style.feature === "cables")
    newStyle = { ...style, label: cableLabels[0] };
  else if (style.feature === "anchors")
    newStyle = { ...style, label: anchorLabels[0] };
  else if (style.feature === "parks")
    newStyle = { ...style, label: parkLabels[0] };
  else throw isNever(style);
  set(styleSelectorFamily(styleId), newStyle);
});

export const disableLabelOnStyle = atom(null, (get, set, styleId: string) => {
  const style = get(styleSelectorFamily(styleId));
  if (!style) throw new Error("Illegal style id");
  set(styleSelectorFamily(styleId), {
    ...style,
    label: undefined,
  });
});

export const duplicateStyle = atom(null, (_, set, style: Style) => {
  const id = v4();
  const newStyle: Style = {
    ...style,
    defaultMarker: false,
    id,
    createdAt: Date.now(),
    name: `${style.name ?? labelFromColorKey[style.source]} duplicate`,
  };
  set(styleSelectorFamily(id), newStyle);
  set(currentEditStyleIdAtom, id);
});

export const removeStyle = atom(null, (_, set, styleId: string) => {
  set(styleSelectorFamily(styleId), RESET);
});

export const changeStyleColorKey = atom(
  null,
  (_, set, style: Style, key: string) => {
    if (key === "single")
      return set(setter, {
        ...style,
        source: "single",
        type: "single",
        color: Color.fromHex(colors.blue500),
      });

    if (style.feature === "turbines") {
      const source = _TurbineColorKey.exclude(["single"]).parse(key);
      const ktype = colorKeyType(source);
      if (ktype === "string") {
        set(setter, {
          ...style,
          source,
          type: "category",
          categories: [],
        });
      } else {
        set(setter, {
          ...style,
          source,
          type: "bucket",
          buckets: Buckets.Pink(7),
        });
      }
    } else if (style.feature === "cables") {
      const source = _CableColorKey.exclude(["single"]).parse(key);
      const ktype = colorKeyType(source);
      if (ktype === "string") {
        set(setter, {
          ...style,
          source,
          type: "category",
          categories: [],
        });
      } else {
        set(setter, {
          ...style,
          source,
          type: "bucket",
          buckets: Buckets.Pink(7),
        });
      }
    } else if (style.feature === "anchors") {
      const source = _AnchorColorKey.exclude(["single"]).parse(key);
      const ktype = colorKeyType(source);
      if (ktype === "string") {
        set(setter, {
          ...style,
          source,
          type: "category",
          categories: [],
        });
      } else {
        set(setter, {
          ...style,
          source,
          type: "bucket",
          buckets: Buckets.Pink(7),
        });
      }
    } else if (style.feature === "parks") {
      if (key === "group")
        return set(setter, {
          ...style,
          source: "group",
          type: "category",
          categories: [],
        });
      const source = _ParkColorKey.exclude(["single"]).parse(key);
      const ktype = colorKeyType(source);
      if (ktype === "string") {
        set(setter, {
          ...style,
          source,
          type: "category",
          categories: [],
        });
      } else {
        set(setter, {
          ...style,
          source,
          type: "bucket",
          buckets: Buckets.Pink(7),
        });
      }
    } else throw isNever(style);
  },
);

/** Set the type, e.g. `bucket` or `single`. */
export const changeStyleType = atom(
  null,
  async (
    get,
    set,
    style: BucketStyle | GradientStyle | CategoryStyle | SingleStyle,
    typ: Defined<Coloring<any>["type"]>,
  ) => {
    const colors = isBucket(style)
      ? style.buckets.buckets().map((b) => b.color)
      : isGradient(style)
        ? style.gradient.colors
        : isCategory(style)
          ? style.categories.map((c) => c.color)
          : isSingle(style)
            ? [style.color]
            : isNever(style);
    if (!colors) return;

    let st: Style;
    if (typ === "single") {
      st = {
        ...style,
        type: typ,
        source: "single",
        color: colors.at(Math.floor(colors.length / 2))!,
      };
    } else if (typ === "bucket") {
      const { min, max } = lunwrap(
        get(loadable(sourceValueMinMaxSelector(style.id))),
      ) ?? {
        min: 0,
        max: 1,
      };
      st = {
        ...style,
        type: typ,
        buckets: Buckets.Brewer("PiYG", 5).balance(min, max),
      } as Style; // ???
    } else if (typ === "gradient") {
      const { min, max } = lunwrap(
        get(loadable(sourceValueMinMaxSelector(style.id))),
      ) ?? {
        min: 0,
        max: 1,
      };
      st = {
        ...style,
        type: typ,
        gradient: Gradient.Brewer("RdYlBu", 9).balance(min, max),
      } as Style; // ???
    } else if (typ === "category") {
      const values = await get(
        sourceValueSelectorFamily({
          styleId: style.id,
          colorKey: style.source,
        }),
      );
      if (!values) throw new Error("Failed to get style source values");

      const catIds = dedup([...values.values()].map(String));
      const categories: ColorCategory[] = catIds.map((id, i) => ({
        color: colors[i % colors.length],
        id,
      }));

      if (style.source === "cable-type") {
        const cableTypes = await get(
          cableTypesFamily({ projectId: undefined }),
        );
        for (const c of categories) c.label = cableTypes.get(c.id)?.name;
      }

      st = {
        ...style,
        type: typ,
        categories,
      } as Style; // ???
    } else {
      throw isNever(typ);
    }

    set(setter, st);
    return st;
  },
);
