import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";

export enum TimelineContainerPlacement {
  TOP = "top",
  BOTTOM = "bottom",
}

export const TimelineContainer = styled.div`
  position: relative;
`;

export const ExtraContainer = styled(StandardBox)<{
  placement?: TimelineContainerPlacement;
}>`
  position: absolute;
  ${(p) =>
    p.placement === TimelineContainerPlacement.TOP
      ? "bottom: 3rem"
      : "top: 3rem"};
  padding: 1.2rem 1.6rem;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  max-height: 50rem;
  overflow: auto;
  z-index: 3;
`;

export const ChangelogEntryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const DateWrapper = styled.div`
  white-space: nowrap;
`;

export const ChangelogWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
`;
