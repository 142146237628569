import { getDistanceFromPortToPark } from "../utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getTurbinesWithFixedFoundations } from "analysis/inputs";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { positionOnSiteActivity, transitActivity } from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  isScourProtectionVessel,
  ScourProtectionVesselType,
} from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";

export const scourProtectionTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const turbinesWithFixedFoundation = await get(
      getTurbinesWithFixedFoundations(id),
    );

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const { installationSeason, weatherLimits } = configuration.ti.foundations;
    const { installationVessel } = configuration.ti.foundations.scourProtection;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isScourProtectionVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const numFoundations = turbinesWithFixedFoundation.length;

    const numRounds = Math.ceil(
      numFoundations / vessel.scourProtectionCapacity,
    );
    const numFoundationsLastRound =
      numFoundations - (numRounds - 1) * vessel.scourProtectionCapacity;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numRounds; i++) {
      let foundationsInRound = vessel.scourProtectionCapacity;
      if (i === numRounds - 1) {
        foundationsInRound = numFoundationsLastRound;
      }
      totalInstallTime.push(loadRocksActivity(vessel));
      totalInstallTime.push(transitActivity(transitTime));

      for (let j = 0; j < foundationsInRound; j++) {
        totalInstallTime.push(positionOnSiteActivity);
        totalInstallTime.push(dumpRocksActivity(vessel));
      }

      totalInstallTime.push(transitActivity(transitTime));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const loadRocksActivity = (vessel: ScourProtectionVesselType) => ({
  id: "Load rocks",
  duration: Math.ceil(vessel.rockLoadingTime),
  useWindLim: false,
  useWaveLim: true,
});

const dumpRocksActivity = (vessel: ScourProtectionVesselType) => ({
  id: "Dump rocks",
  mileStone: true,
  duration: Math.ceil(vessel.rockDumpingTime),
  useWindLim: true,
  useWaveLim: true,
});
