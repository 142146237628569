import ResetIcon from "@icons/14/Reset.svg?react";
import Button from "components/General/Button";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { colors } from "styles/colors";
import {
  compareIsLoading,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  shownCompareDataAtom,
  visibleComparisonListRowsAtom,
} from "../state";
import Tooltip from "components/General/Tooltip";
import { Suspense, useCallback } from "react";
import { projectIdAtom } from "state/pathParams";
import { useColumnTemplates } from "../columnTemplates";
import { useToast } from "hooks/useToast";
import { range } from "utils/utils";
import { makeCSVString } from "components/CompareParksModal/CompareDownloadButton";
import { useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { parkFamily } from "state/jotai/park";
import { branchMetasFamily } from "state/jotai/branch";
import { analysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { windConfigurationsFamily } from "state/jotai/windConfiguration";
import { costConfigurationsFamily } from "state/jotai/costConfiguration";
import { operationsConfigurationsFamily } from "state/jotai/operationsConfiguration";

const Inner = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const selectedParkIds = useAtomValue(selectedParksAtom({ projectId }));
  const shownData = useAtomValue(shownCompareDataAtom);
  const selectedAttributes = useAtomValue(selectedComparisonAttributesAtom);
  const columnTemplates = useColumnTemplates();
  const configurations = useAtomValue(
    analysisConfigurationsFamily({ projectId }),
  );
  const visibleComparisonListRows = useAtomValue(visibleComparisonListRowsAtom);
  const costConfigurations = useAtomValue(
    costConfigurationsFamily({ projectId }),
  );
  const windConfigurations = useAtomValue(
    windConfigurationsFamily({ projectId }),
  );
  const operationsConfigurations = useAtomValue(
    operationsConfigurationsFamily({ projectId }),
  );

  const getPark = useJotaiCallback(async (get, _, { parkId, branchId }) => {
    return get(parkFamily({ parkId, branchId }));
  }, []);

  const branches = useAtomValue(branchMetasFamily({ projectId }));
  const getBranch = useCallback(
    (branchId: string) => branches.get(branchId),
    [branches],
  );

  const getSortOrder = useCallback(
    (comparisonId: string) => {
      return selectedParkIds.findIndex(
        (park) => park.comparisonId === comparisonId,
      );
    },
    [selectedParkIds],
  );

  const { success } = useToast();

  return (
    <Tooltip text="Download data for the compare production number playwright test">
      <Button
        style={{ background: `${colors.orange}80` }}
        icon={<ResetIcon />}
        buttonType="secondary"
        onClick={async () => {
          const csv = await makeCSVString(
            shownData,
            selectedAttributes,
            columnTemplates,
            getPark,
            getSortOrder,
            getBranch,
            configurations,
            costConfigurations,
            windConfigurations,
            operationsConfigurations,
            visibleComparisonListRows,
          );

          const lines = csv.split("\n");
          const rows = lines.map((line) => line.split(","));
          const numParks = rows[0].length - 1;
          const data = range(0, numParks).map((i) =>
            Object.fromEntries(rows.map((row) => [row[0], row[i + 1]])),
          );
          console.log(data);
          success("Logged to console");
        }}
      />
    </Tooltip>
  );
};

const Loader = () => (
  <SkeletonBlock style={{ height: "3.2rem", width: "3.2rem" }} />
);

export const UpdateCompareTestData = () => {
  const isLoadingValues = useAtomValue(compareIsLoading);
  const isLoading = Object.values(isLoadingValues).some((v) => v);
  if (isLoading) return <Loader />;
  return (
    <Suspense fallback={<Loader />}>
      <Inner />
    </Suspense>
  );
};
