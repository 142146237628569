import { useAtomValue } from "jotai";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import DesignNavigation from "./DesignNavigation/DesignNavigation";
import { LayerControlWrapper } from "../RightSide/RightSide.style";
import { libraryLayersOpenAtom } from "../../state/layer";
import LowerRightToolsMenuV2 from "../LowerRight/LowerRightToolsMenuV2";
import MapNative, { waitWithCreatingTheMap } from "../MapNative/MapNative";
import DynamicPolygonLayers from "../../layers/ExternalLayers/dynamicPolygonLayers";
import DynamicTileJSONLayers from "../../layers/ExternalLayers/dynamicTileJSONLayers";
import DynamicCircleLayers from "../../layers/ExternalLayers/dynamicCircleLayers";
import DynamicPointLayers from "../../layers/ExternalLayers/dynamicPointLayers";
import DynamicLineLayers from "../../layers/ExternalLayers/dynamicLineLayers";
import DynamicWMSLayers from "../../layers/ExternalLayers/dynamicWMSLayers";
import HasLoaded from "../HasLoaded/HasLoaded";
import Distance from "../Distance/Distance";
import Comments from "../Comments/Comments";
import ZoomToObject from "./ZoomTo/ZoomToObject";
import ExternalLayerOrderAnchorLayers from "../../layers/ExternalLayers/ExternalHandleLayers";
import DynamicArcgisRasterLayers from "../../layers/ExternalLayers/dynamicArcgisRasterLayers";
import { SaveSelectedBranch } from "../SelectCorrectBranch/SelectCorrectBranch";
import RightClickMenu from "../RightClickMenu/RightClickMenu";
import SelectionMenu from "../RightClickMenu/SelectionMenu";
import MapControls from "../MapControls/MapControls";
import useOpenModal from "../../hooks/useOpenModal";
import useOpenUploadModalOnDragFile from "../UploadModal/useOpenUploadModalOnDragFile";
import VersionSync from "../VersionSync/VersionSync";
import TutorialOpenedMixpanelFlag from "../TutorialOpenedMixpanelFlag/TutorialOpenedMixpanelFlag";
import DynamicWMTSLayers from "../../layers/ExternalLayers/dynamicWMTSLayers";
import DynamicXYZLayers from "../../layers/ExternalLayers/dynamicXYZLayers";
import DataLayersModal from "components/DataLayersModal/DataLayersModal";
import RefreshProjectState from "./RefreshProjectState";
import SearchBarProject from "components/Search/SearchBar/SearchBarProject";
import LowerLeftToolsMenuV2 from "components/LowerLeftV2/LowerLeftToolsMenuV2";
import { ReadOnlyInfo } from "components/ReadOnlyInfo/ReadOnlyInfo";
import UndoRedo from "components/MapControls/UndoRedo";
import { TourStep } from "components/OnboardingTours/TourStep";
import {
  OnshoreTutorialProjectOnboarding,
  TutorialProjectOnboarding,
} from "components/OnboardingTours/Tour";
import { UploadModalType } from "components/UploadModal/UploadModal";
import { currentParkTriggerId } from "components/Finance/Triggers";
import ArchiveProjectModal from "./ArchiveProjectVersion/Modal/ArchiveProjectModal";
import NewArchivedProjectVersionModal from "./ArchiveProjectVersion/Modal/NewModal";
import SuccessModal from "./ArchiveProjectVersion/Modal/SuccessModal";
import {
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import Changelog from "./Changelog/Changelog";
import FeatureHistory from "./FeatureHistory/FeatureHistory";
import { useSetAtom } from "jotai";
import { analysisOverrideInputFamily } from "analysis/inputs";
import ViewToParkContextProvider from "components/ViewAnalyses/ViewToPark/ThreeContext/ViewToParkContextProvider";
import ViewToParkThree from "components/ViewAnalyses/ViewToPark/ViewToParkThree";
import { viewFromShoreVisibleAtom } from "state/viewToPark";
import { DesignToolMode } from "types/map";
import { designToolTypeAtom } from "state/map";
import { viewParkShadowVisibleAtom } from "state/viewParkShadow";
import ViewParkShadowContextProvider from "components/ViewAnalyses/ViewParkShadow/ThreeContext/ViewParkShadowContextProvider";
import ViewParkShadowThree from "components/ViewAnalyses/ViewParkShadow/ViewParkShadowThree";
import ActiveTips from "components/ActiveTips/ActiveTips";
import DuplicateBranchModal from "components/DuplicateBranchModal/DuplicateBranchModal";
import { Mixpanel } from "../../mixpanel";
import GenerateNamesModal from "components/GenerateFeatureNamesModal/GenerateFeatureNamesModal";
import LayerDataPropertiesTable from "components/LayerDataPropertiesTable/LayerDataPropertiesTable";

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ExternalLayers = () => {
  return (
    <>
      <ExternalLayerOrderAnchorLayers />
      <DynamicPolygonLayers />
      <DynamicTileJSONLayers />
      <DynamicCircleLayers />
      <DynamicPointLayers />
      <DynamicLineLayers />
      <DynamicWMSLayers />
      <DynamicWMTSLayers />
      <DynamicXYZLayers />
      <DynamicArcgisRasterLayers />
    </>
  );
};

// Reset analysisOverrideInputAtomFamily on unmount to avoid caching issue between projets
const ResetAnalysisOverrideConfigOnUnmount = () => {
  const resetAnalysisConfig = useSetAtom(
    analysisOverrideInputFamily(currentParkTriggerId),
  );

  useEffect(() => {
    return () => {
      resetAnalysisConfig(new Promise(() => {}));
    };
  }, [resetAnalysisConfig]);

  return null;
};

const DesignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default function Design({ mode }: { mode: DesignToolMode }) {
  const setProjectType = useSetAtom(designToolTypeAtom);
  const setReadyCreateMap = useSetAtom(waitWithCreatingTheMap);
  useEffect(() => {
    setProjectType(mode);
    setReadyCreateMap(new Promise((res) => res(0)));
    Mixpanel.project_mode.set(mode);
    return () => {
      setProjectType(DesignToolMode.Offshore);
      Mixpanel.project_mode.remove();
    };
  }, [mode, setProjectType, setReadyCreateMap]);
  const open = useAtomValue(libraryLayersOpenAtom);
  const { openUploadModal } = useOpenUploadModalOnDragFile({
    modalType: UploadModalType,
  });
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: projectId,
    }),
  );
  const isAdminForNode = nodeAccess >= 2;
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const showArchivedVersions = isAdminForNode && isMemberInOrg;

  useOpenModal();
  const viewFromShoreVisible = useAtomValue(viewFromShoreVisibleAtom);
  const viewParkShadowVisible = useAtomValue(viewParkShadowVisibleAtom);

  if (!branchId || !projectId || !organisationId) return <></>;

  return (
    <>
      <DesignWrapper>
        <React.Suspense fallback={null}>
          {viewFromShoreVisible && (
            <ViewToParkContextProvider>
              <ViewToParkThree />
            </ViewToParkContextProvider>
          )}
        </React.Suspense>
        <React.Suspense fallback={null}>
          {viewParkShadowVisible && (
            <ViewParkShadowContextProvider>
              <ViewParkShadowThree />
            </ViewParkShadowContextProvider>
          )}
        </React.Suspense>
        <ResetAnalysisOverrideConfigOnUnmount />
        <React.Suspense fallback={null}>
          <TutorialProjectOnboarding />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <OnshoreTutorialProjectOnboarding />
        </React.Suspense>

        <GenerateNamesModal />
        <DesignNavigation />
        <RefreshProjectState />
        <React.Suspense fallback={null}>
          <ActiveTips />
        </React.Suspense>

        <React.Suspense fallback={null}>
          <VersionSync />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <MapControls />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <SaveSelectedBranch />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <UndoRedo />
          <TutorialOpenedMixpanelFlag />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <RightClickMenu>
            {({ mapMouseEvent, closeMenu }) => (
              <SelectionMenu
                closeMenu={closeMenu}
                mapMouseEvent={mapMouseEvent}
              />
            )}
          </RightClickMenu>
        </React.Suspense>
        <MapContainer onDragOver={openUploadModal}>
          <TourStep
            tourId="general-intro-tour"
            stepId="clickPolygon"
            style={{
              top: "6rem",
              left: "70rem",
            }}
          />
          <MapNative />
          <LayerDataPropertiesTable />
        </MapContainer>
        <React.Suspense fallback={null}>
          <Comments />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <Changelog />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <FeatureHistory />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <DuplicateBranchModal />
        </React.Suspense>
        {showArchivedVersions && (
          <>
            <React.Suspense fallback={null}>
              <ArchiveProjectModal />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <NewArchivedProjectVersionModal />
            </React.Suspense>
            <React.Suspense fallback={null}>
              <SuccessModal />
            </React.Suspense>
          </>
        )}
        <React.Suspense fallback={null}>
          <ReadOnlyInfo />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <LowerLeftToolsMenuV2 />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <LayerControlWrapper open={open}>
            <LowerRightToolsMenuV2 />
          </LayerControlWrapper>
          <Distance />
          <DataLayersModal />
          <Outlet />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <ZoomToObject />
          <ExternalLayers />
          <HasLoaded />
        </React.Suspense>
        <SearchBarProject
          organisationId={organisationId}
          projectId={projectId}
        />
      </DesignWrapper>
    </>
  );
}
