import { useAtom, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { Mixpanel } from "mixpanel";
import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import DataPackageIcon from "@icons/24/DataPackage.svg";
import LayersIcon from "@icons/24/Layers.svg";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { Column, Row } from "components/General/Layout";
import useOpenUploadModalOnDragFile from "components/UploadModal/useOpenUploadModalOnDragFile";
import Button from "components/General/Button";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { SkeletonBlock } from "components/Loading/Skeleton";
import Spinner from "@icons/spinner/Spinner";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import AddLayersFromAllDataListModal from "./modals/AddLayersFromAllDataListModal";
import ChatIcon from "@icons/24/Chat.svg";
import {
  TabContainer,
  ResourceColumn,
  MainRow,
  ResourceNameText,
  SubHeader,
  ResourceItemTitle,
  SubTitle,
} from "../style";
import { organisationLibraryDataPackageResourceState } from "../state";
import ConnectExternalModal from "./ConnectExternal/Modal";
import { DataLibraryNewEntry } from "./DataLibraryTab.style";
import UploadOrganisationLayerModal, {
  UploadOrganisationDataLayerModalType,
} from "./modals/UploadOrganisationLayerModal";
import UploadOrganisationLayerFromCoordinatesModal from "./modals/UploadOrganisationLayerFromCoordinatesModal";
import EditVectordata, { EditVectordataType } from "./Edit/EditVectordata";
import { DraggedLayersProps } from "./AllDataSection/DataTable";
import { useDataLibraryLayersCrud } from "./useDataLibraryLayersCrud";
import { activePackageIdAtom } from "./state";
import { modalTypeOpenAtom } from "state/modal";
import { useAtomValue } from "jotai";
import { DataLayerManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { ContentTableRow } from "components/Organisation/OrganisationRightSide/style";
import Tooltip from "components/General/Tooltip";
import styled from "styled-components";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { selectedLibraryTabAtom } from "state/library";
import { orgDataPackagesManageAccessSelector } from "state/user";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { useSyncSelectedItemWithResource } from "../hook";

const DataPackageGrid = styled.div<{
  collapsed: boolean;
  oneColumnOnly?: boolean;
}>`
  padding: 0;
  display: grid;
  grid-auto-rows: 6rem;
  overflow-y: auto;

  grid-template-columns: 1fr;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.blue500};
`;

const PackageResourceItem = ({
  packageId,
  setActivePackageId,
  name,
  selected,
  onClick,
  description,
}: {
  packageId: string;
  setActivePackageId(packageId: string | undefined): void;
  name: string;
  selected: boolean;
  onClick(): void;
  description: string;
}) => {
  const { loading, deletePackage, addLayersToPackage, isDeletingPackage } =
    useDataLibraryLayersCrud();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { updatePackage } = useDataLibraryLayersCrud();
  const ref = useRef<HTMLDivElement>(null);
  const [dropCollection, dropRef] = useDrop<
    DraggedLayersProps,
    void,
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: "ORGANISATION_LAYER",
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem) => {
        const { layerIds } = draggedItem;
        addLayersToPackage(organisationId, packageId, layerIds);
      },
    }),
    [addLayersToPackage, organisationId, packageId],
  );

  dropRef(ref);
  const [, setContent] = useAtom(organisationRightSideModal(organisationId));
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ResourceItemTitle
      height="6rem"
      style={{
        padding: "0",
        marginRight: "0rem",
        marginLeft: "0rem",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        ...(selected || dropCollection.isHovered
          ? {
              backgroundColor: colors.surfaceSelectedLight,
            }
          : {}),
      }}
      ref={ref}
      onClick={onClick}
    >
      <ContentTableRow
        style={{
          overflow: "hidden",
          ...(selected
            ? {
                backgroundColor: colors.surfaceSelectedLight,
              }
            : {}),
        }}
      >
        <IconREMSize
          height={1.6}
          width={1.6}
          fill={selected ? colors.iconSelected : undefined}
        >
          <DataPackageIcon />
        </IconREMSize>
        <Tooltip text={name}>
          <ResourceNameText selected={selected} collapsed={true}>
            {name}
          </ResourceNameText>
        </Tooltip>
      </ContentTableRow>
      <Row>
        <DescriptionModal
          size="small"
          updateDescription={(description: string) => {
            Mixpanel.track_old("Update library package description", {
              descriptionLength: description.length,
            });
            return updatePackage(organisationId, packageId, {
              description,
            });
          }}
          defaultValue={description}
          close={() => setIsOpen(false)}
          subtitle={
            <div>
              <p>Add a short description to the package.</p>
              <p>
                The description will be visible for members and projects with
                access to this package.
              </p>
            </div>
          }
          editByDefault={isOpen}
        />

        {isDeletingPackage ? (
          <Spinner
            size={"1rem"}
            style={{
              marginLeft: "auto",
            }}
          />
        ) : (
          <DotMenu hoverBackgroundColor={colors.grey200}>
            <MenuItem
              name={description ? "Edit description" : "Add description"}
              onClick={() => setIsOpen(true)}
              icon={<ChatIcon />}
            />
            <MenuItem
              name={"Delete package"}
              disabled={isDeletingPackage || loading}
              onClick={(e) => {
                e.stopPropagation();
                deletePackage(organisationId, packageId);
                setActivePackageId("all-data");
                setContent({
                  type: "all-data-layers",
                });
              }}
              icon={loading ? <Spinner size={"1rem"} /> : <BinIcon />}
            />
          </DotMenu>
        )}
      </Row>
    </ResourceItemTitle>
  );
};

const PackagesResourceItems = ({
  activePackageId,
  setActivePackageId,
}: {
  activePackageId: string | undefined;
  setActivePackageId(packageId: string | undefined): void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const packages = useAtomValue(
    organisationLibraryDataPackageResourceState({
      organisationId,
    }),
  );
  const [, setContent] = useAtom(organisationRightSideModal(organisationId));
  const addPackageButtonWrapperRef = useRef<HTMLDivElement>(null);
  const [showNewPackageModal, setShowNewPackageModal] = useState(false);

  const { loading, createPackage, creatingNewPackage, addLayersToPackage } =
    useDataLibraryLayersCrud();

  const [, dropRef] = useDrop<
    DraggedLayersProps,
    void,
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: "ORGANISATION_LAYER",
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem) => {
        const { layerIds } = draggedItem;

        Mixpanel.track_old(
          "Drag N drop layerIds to Create new package button",
          {
            nrLayers: layerIds.length,
          },
        );
        createPackage(organisationId, {
          name: "Untitled",
        }).then((pkg) => {
          if (pkg) {
            addLayersToPackage(organisationId, pkg.id, layerIds).then(() => {
              setActivePackageId(pkg.id);
            });
          }
        });
      },
    }),
    [addLayersToPackage, createPackage, organisationId, setActivePackageId],
  );

  dropRef(addPackageButtonWrapperRef);

  useEffect(() => {
    setActivePackageId("all-data");
    setContent({
      type: "all-data-layers",
    });
  }, [setActivePackageId, setContent]);

  const isAllDataSelected = activePackageId === "all-data";

  return (
    <>
      {showNewPackageModal && (
        <NewItemModal
          title="Create new package"
          placeholder="Enter package name"
          defaultValue="Untitled"
          onClose={() => {
            setShowNewPackageModal(false);
          }}
          onSubmit={async (name: string) => {
            const result = await createPackage(organisationId, {
              name,
            });
            if (result) {
              setActivePackageId(result.id);
              setContent({
                type: "resource",
                id: result.id,
              });
            }
          }}
        />
      )}
      <div
        style={{
          padding: "1.2rem 0",
        }}
      >
        <ResourceItemTitle
          style={{
            justifyContent: "space-between",
            marginLeft: "0",
            minHeight: "4rem",
            alignItems: "center",
            backgroundColor: isAllDataSelected
              ? colors.surfaceSelectedLight
              : colors.surfaceSecondary,
          }}
          onClick={() => {
            setActivePackageId("all-data");
            setContent({
              type: "all-data-layers",
            });
          }}
        >
          <ContentTableRow>
            <IconREMSize
              style={{
                paddingLeft: "1.1rem",
              }}
              height={1.6}
              width={1.6}
              stroke={
                isAllDataSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <LayersIcon />
            </IconREMSize>

            <ResourceNameText selected={isAllDataSelected} collapsed={true}>
              {"All GIS layers"}
            </ResourceNameText>
          </ContentTableRow>
        </ResourceItemTitle>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: colors.grey200,
        }}
      ></div>
      <Row
        style={{
          padding: "3.2rem 1.5rem 1.2rem 1.5rem",
          justifyContent: "space-between",
        }}
        alignCenter
      >
        <SubTitle>Packages</SubTitle>
        <Button
          text="New "
          tooltip="Create new package"
          disabled={loading || creatingNewPackage}
          icon={<DataPackageIcon />}
          buttonType="secondary"
          onClick={() => setShowNewPackageModal(true)}
        />
      </Row>
      <Divider />

      <DataPackageGrid collapsed={true} oneColumnOnly>
        {packages.length > 0
          ? packages.map((pkg) => (
              <PackageResourceItem
                description={pkg.description}
                key={pkg.id}
                packageId={pkg.id}
                name={pkg.name}
                setActivePackageId={setActivePackageId}
                selected={activePackageId === pkg.id}
                onClick={() => {
                  setActivePackageId(pkg.id);
                  setContent({
                    type: "resource",
                    id: pkg.id,
                  });
                }}
              />
            ))
          : null}
      </DataPackageGrid>
      {packages.length === 0 && (
        <DataLibraryNewEntry
          icon={<DataPackageIcon />}
          title="Create packages"
          text="Share the packages across projects"
          actionButton={
            <Button
              disabled={loading}
              text="New package"
              icon={loading ? <Spinner size={"1.2rem"} /> : <AddIcon />}
              onClick={async () => {
                const result = await createPackage(organisationId, {
                  name: "Untitled",
                });
                if (result) {
                  setActivePackageId(result.id);
                  setContent({
                    type: "resource",
                    id: result.id,
                  });
                }
              }}
            />
          }
        />
      )}
    </>
  );
};

export default function DataLibraryTab() {
  const hasOrgDataLayersAccess = useAtomValue(
    orgDataPackagesManageAccessSelector,
  );
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  const setActivePackageId = useSetAtom(activePackageIdAtom);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  useEffect(() => {
    setSelectedLibraryTab("datalayers");
    setActivePackageId("all-data");
    setContent({
      type: "all-data-layers",
    });

    return () => {
      setSelectedLibraryTab(undefined);
      setActivePackageId(undefined);
      setContent(undefined);
    };
  }, [setSelectedLibraryTab, setActivePackageId, setContent]);

  if (!hasOrgDataLayersAccess || !allLibraryAccess) return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <DataLibraryTabInner />
    </Suspense>
  );
}

function DataLibraryTabInner() {
  useSyncSelectedItemWithResource();
  const [activePackageId, setActivePackageId] = useAtom(activePackageIdAtom);

  const { openUploadModal } = useOpenUploadModalOnDragFile({
    modalType: UploadOrganisationDataLayerModalType,
  });
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  const elem = useRef<HTMLDivElement>(null);
  useHorizontalResize(elem, "--data-library-resource-list-width");

  return (
    <>
      <UploadOrganisationLayerModal />
      <UploadOrganisationLayerFromCoordinatesModal />
      <AddLayersFromAllDataListModal />
      <TabContainer
        onDragOver={(e) => {
          if (modalTypeOpen?.modalType === EditVectordataType) return;
          openUploadModal(e);
        }}
      >
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            GIS packages
          </h2>
        </Row>
        <Row
          alignCenter
          style={{
            paddingBottom: "1.2rem",
          }}
        >
          <SubHeader>Access: </SubHeader> <DataLayerManagersPreview />
        </Row>

        <MainRow style={{ overflow: "hidden" }}>
          <ResourceColumn style={{ gap: "0rem" }}>
            <React.Suspense
              fallback={
                <>
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                    }}
                  />
                </>
              }
            >
              <PackagesResourceItems
                setActivePackageId={setActivePackageId}
                activePackageId={activePackageId}
              />
            </React.Suspense>
          </ResourceColumn>

          <Column>
            <React.Suspense
              fallback={
                <SkeletonBlock
                  style={{
                    width: "100%",
                    height: "30rem",
                  }}
                />
              }
            ></React.Suspense>
          </Column>
          <ConnectExternalModal />
          <EditVectordata />
        </MainRow>
      </TabContainer>
    </>
  );
}
