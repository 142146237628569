import { useState, useRef, useEffect, Suspense } from "react";
import { editorAccessProjectSelector } from "../../../state/user";
import { Label } from "../../General/Form";
import { Input, InputDimensioned } from "../../General/Input";
import {
  ARTICLE_NOISE_ANALYSIS,
  HelpLink,
} from "../../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { Slider } from "components/General/Slider";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  triggerAnalysisAtom,
  noiseAnalysisGeotiff,
  noiseAnalysisSettingsAtom,
  noiseAnalysisResults,
  noiseAnalysisBoundary,
  noiseIdAtom,
  updateNoiseAnalysisSettingsAtom,
  noiseInputsChanged,
  getNoiseAnalysisStatus,
  resetAnalysisAtom,
} from "components/Noise/Onshore/state";
import {
  noiseDisplaySettingsAtom,
  isAnalysisRunning,
  isAnalysisStatusFailed,
  isAnalysisStatusStopped,
  isAnalysisStatusComplete,
} from "./types";
import {
  COLORS,
  _NoiseAnalysisInput,
  GROUND_TYPES,
  NoiseAnalysisSettings,
} from "./types";
import { loadable, unwrap } from "jotai/utils";
import { mapAtom } from "state/map";
import { MapboxRaster } from "components/MapFeatures/Geotiff";
import {
  geotiffNoiseLayerId,
  noiseBoundaryLayerId,
} from "components/Mapbox/constants";
import Button from "components/General/Button";
import { getOctaveBandLevels } from "utils/noise";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import Frequency from "@icons/24/Frequency.svg";
import OpenLeft from "@icons/24/OpenLeft.svg";
import Noise from "@icons/24/Noise.svg";
import { useClickOutside } from "hooks/useClickOutside";
import { spacing6, spacing7 } from "styles/space";
import { Anchor } from "components/General/Anchor";
import styled from "styled-components";
import { Column, Row } from "components/General/Layout";
import Radio from "components/General/Radio";
import Dropdown from "components/Dropdown/Dropdown";
import { SkeletonText } from "components/Loading/Skeleton";
import { PolygonFeature } from "types/feature";
import SensorStats from "../../RightSide/InfoModal/ProjectFeatureInfoModal/SensorStats";
import { IconREMSize } from "styles/typography";
import { lunwrap, useJotaiCallback } from "utils/jotai";
import LineString from "components/MapFeatures/LineString";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Checkbox from "components/General/Checkbox";
import { LoadingState } from "components/Dashboard/Widgets/Base";
import Spinner from "@icons/spinner/Spinner";
import { roundToDecimal } from "utils/utils";
import { MapboxBbox4 } from "types/mapbox";
import { getErrorText } from "./errors";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { configurationMenuActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { EmptyState } from "components/ValidationWarnings/EmptyState";

const ResultsEmptyState = () => {
  return (
    <EmptyState
      title="Calculate to show results"
      icon={
        <IconREMSize iconColor={colors.iconNegative} height={2} width={2}>
          <Noise />
        </IconREMSize>
      }
      style={{
        boxSizing: "border-box",
      }}
    />
  );
};

const ResultsLoadingState = () => {
  return <SkeletonText />;
};

type Submenu = "sound-levels" | "advanced-settings";

const openSubmenuAtom = atom<Submenu | undefined>(undefined);

const SelectedBoundary = () => {
  const map = useAtomValue(mapAtom);
  const boundary = useAtomValue(loadable(noiseAnalysisBoundary));

  if (!map || boundary.state !== "hasData") return null;

  const feature: PolygonFeature = boundary.data;

  return (
    <LineString
      map={map}
      features={[feature]}
      sourceId={noiseBoundaryLayerId}
      layerId={noiseBoundaryLayerId}
      color="#000000"
      paint={{ "line-width": 2 }}
      opacity={1}
    />
  );
};

const SelectedRaster = () => {
  const { opacity } = useAtomValue(noiseDisplaySettingsAtom);
  const map = useAtomValue(mapAtom);
  const geotiff = useAtomValue(loadable(noiseAnalysisGeotiff));
  const [geotiffToUse, setGeotiffToUse] = useState(geotiff);
  const [bboxToUse, setBboxToUse] = useState<MapboxBbox4 | undefined>(
    undefined,
  );

  // Set local state when geotiff is loaded to prevent flickering when changing opacity
  useEffect(() => {
    if (geotiff.state === "hasData") {
      setGeotiffToUse(geotiff);
      setBboxToUse((curr) => {
        if (
          !curr ||
          JSON.stringify(curr) !== JSON.stringify(geotiff.data.bbox)
        ) {
          return geotiff.data.bbox;
        }
        return curr;
      });
    }
  }, [geotiff]);

  if (!map || !geotiffToUse || geotiffToUse.state !== "hasData" || !bboxToUse) {
    return null;
  }

  return (
    <MapboxRaster
      layerId={geotiffNoiseLayerId}
      map={map}
      imageUrl={geotiffToUse.data.dataURL}
      bbox={bboxToUse}
      opacity={opacity}
      interpolation="linear"
    />
  );
};

const FrequencyGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  gap: 0.4rem;
  padding: 1.6rem;
`;

const FrequencyRow = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 8rem);
  gap: 0.8rem;
  align-items: center;
`;

const FrequencyLabel = styled.div`
  color: ${colors.textSecondary};
  font-size: 0.9rem;
  text-align: center;
`;

const FrequencyValue = styled(Input)`
  width: 8rem;
`;

const SoundLevels = ({
  buttonRef,
}: {
  buttonRef: React.RefObject<HTMLButtonElement>;
}) => {
  const setMenu = useSetAtom(openSubmenuAtom);
  const analysisInput = useAtomValue(noiseAnalysisSettingsAtom);

  useClickOutside(
    buttonRef,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return (
        target.id === "sound-levels" ||
        target.id === "turbine-sound-level-input" ||
        target.closest("#turbine-sound-level-input") !== null
      );
    },
  );

  const { frequencies, levels } = getOctaveBandLevels(analysisInput.source);

  return (
    <Anchor
      baseRef={buttonRef}
      basePlace="bottomRight"
      floatPlace="topRight"
      offset={[0, 6]}
      allowOutside
    >
      <MenuFrame
        title="Sound level at each frequency band"
        onExit={() => setMenu(undefined)}
        id="noise-submenu-sound-levels"
        style={{
          width: "100%",
          backgroundColor: colors.background,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Checkbox label="A-weighted octave data" disabled checked={true} />
        <FrequencyGrid>
          <FrequencyRow>
            {frequencies.map((freq) => (
              <FrequencyLabel key={freq}>{freq} Hz</FrequencyLabel>
            ))}
          </FrequencyRow>
          <FrequencyRow>
            {levels.map((level, index) => (
              <FrequencyValue key={frequencies[index]} value={level} disabled />
            ))}
          </FrequencyRow>
        </FrequencyGrid>
      </MenuFrame>
    </Anchor>
  );
};

const AdvancedSettings = ({
  buttonRef,
}: {
  buttonRef: React.RefObject<HTMLButtonElement>;
}) => {
  const setMenu = useSetAtom(openSubmenuAtom);
  const analysisInput = useAtomValue(noiseAnalysisSettingsAtom);

  const updateAnalysisInput = useJotaiCallback(
    (_, set, input: Partial<NoiseAnalysisSettings>) => {
      set(noiseAnalysisSettingsAtom, (curr) =>
        _NoiseAnalysisInput.parse({ ...curr, ...input }),
      );
      set(noiseIdAtom, undefined);
    },
    [],
  );

  useClickOutside(
    buttonRef,
    () => {
      setMenu(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return (
        target.closest("#noise-submenu-advanced-settings") !== null ||
        target.id === "noise-submenu-open-advanced-settings"
      );
    },
  );

  return (
    <Anchor
      baseRef={buttonRef}
      floatPlace="topRight"
      basePlace="topLeft"
      offset={[-6, 0]}
    >
      <MenuFrame
        title="Advanced settings"
        onExit={() => setMenu(undefined)}
        id="noise-submenu-advanced-settings"
        icon={<HelpLink article={ARTICLE_NOISE_ANALYSIS} />}
      >
        <Column style={{ gap: spacing6 }}>
          <Row alignCenter style={{ justifyContent: "space-between" }}>
            <InputTitle>Sensor height</InputTitle>
            <InputDimensioned
              compact
              id="noise-sensor-height-input"
              value={analysisInput.sensorHeight || 5}
              onChange={(n) => updateAnalysisInput({ sensorHeight: n })}
              unit="m"
              style={{ width: "15.8rem" }}
            />
          </Row>
          <Row alignCenter style={{ justifyContent: "space-between" }}>
            <InputTitle>Temperature</InputTitle>
            <InputDimensioned
              compact
              id="noise-temperature-input"
              value={analysisInput.temperature || 10}
              onChange={(n) => updateAnalysisInput({ temperature: n })}
              unit="°C"
              style={{ width: "15.8rem" }}
            />
          </Row>
          <Row alignCenter style={{ justifyContent: "space-between" }}>
            <InputTitle>Humidity</InputTitle>
            <InputDimensioned
              compact
              id="noise-humidity-input"
              value={analysisInput.humidity}
              onChange={(n) => updateAnalysisInput({ humidity: n })}
              unit="%"
              style={{ width: "15.8rem" }}
            />
          </Row>
          <Row alignCenter style={{ justifyContent: "space-between" }}>
            <InputTitle>Ground model</InputTitle>
          </Row>
          <Row alignCenter style={{ justifyContent: "space-between" }}>
            <Radio
              label="Alternative ground"
              name="groundModel"
              value="alternative"
              checked={analysisInput.groundModel.type === "alternativeGround"}
              onChange={() =>
                updateAnalysisInput({
                  groundModel: { type: "alternativeGround" },
                })
              }
            />
          </Row>
          <Row
            alignCenter
            style={{ justifyContent: "space-between", height: "2.4rem" }}
          >
            <Radio
              label="Standard"
              checked={analysisInput.groundModel.type === "standard"}
              onChange={() =>
                updateAnalysisInput({
                  groundModel: {
                    type: "standard",
                    groundType: "medium",
                  },
                })
              }
            />

            {analysisInput.groundModel.type === "standard" && (
              <Dropdown
                small
                value={analysisInput.groundModel.groundType || "medium"}
                onChange={(e) => {
                  updateAnalysisInput({
                    groundModel: {
                      type: "standard",
                      groundType: e.target.value as "soft" | "medium" | "hard",
                    },
                  });
                }}
                style={{ width: "15.8rem" }}
              >
                <option value="soft">Soft (G={GROUND_TYPES.soft})</option>
                <option value="medium">Medium (G={GROUND_TYPES.medium})</option>
                <option value="hard">Hard (G={GROUND_TYPES.hard})</option>
              </Dropdown>
            )}
          </Row>
        </Column>
      </MenuFrame>
    </Anchor>
  );
};

const ColorCircle = styled.div`
  background-color: ${(p) => p.color ?? "transparent"};
  height: 2rem;
  width: 2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #00000020;
`;

const VisualizationSettings = () => {
  const [visualizationsOpen, setVisualizationsOpen] = useState(true);
  const [displaySettings, setDisplaySettings] = useAtom(
    noiseDisplaySettingsAtom,
  );

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setVisualizationsOpen(!visualizationsOpen);
        }}
      >
        <SubtitleWithLine
          text={"Visualizations"}
          expandButton={
            <ChevronIcon
              open={visualizationsOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {visualizationsOpen && (
        <Column style={{ gap: spacing6 }}>
          <Row alignCenter>
            <Label style={{ flex: 1 }}>
              Coloring opacity (%)
              <Slider
                onChange={(n) =>
                  setDisplaySettings((curr) => ({ ...curr, opacity: n }))
                }
                min={0}
                step={0.1}
                value={displaySettings.opacity}
                max={1}
                label
                renderLabel={(n) => `${(n * 100).toFixed(0)}`}
                style={{ width: "100%" }}
              />
            </Label>
          </Row>
          <Row alignCenter>
            <ColorCircle color={COLORS.yellow.toRGB()} />
            <InputTitle style={{ flex: 1 }}>Yellow boundary</InputTitle>
            <InputDimensioned
              compact
              id="noise-yellow-boundary-input"
              value={displaySettings.yellowBoundary}
              validate={(n) => n < displaySettings.redBoundary}
              validationMessage="Yellow boundary must be lower than red boundary"
              validationMessageStyle={{ transform: "translate(-80%, 110%)" }}
              onChange={(n) =>
                setDisplaySettings((curr) => ({ ...curr, yellowBoundary: n }))
              }
              unit="dB(A)"
              style={{ width: "10rem" }}
            />
          </Row>
          <Row alignCenter>
            <ColorCircle color={COLORS.red.toRGB()} />
            <InputTitle style={{ flex: 1 }}>Red boundary</InputTitle>
            <InputDimensioned
              compact
              id="noise-red-boundary-input"
              value={displaySettings.redBoundary}
              validate={(n) => n > displaySettings.yellowBoundary}
              validationMessage="Red boundary must be higher than yellow boundary"
              validationMessageStyle={{ transform: "translate(-80%, 110%)" }}
              onChange={(n) =>
                setDisplaySettings((curr) => ({ ...curr, redBoundary: n }))
              }
              unit="dB(A)"
              style={{ width: "10rem" }}
            />
          </Row>
        </Column>
      )}
    </>
  );
};

const ResultsInner = () => {
  const { sensorStats, maxValue } = useAtomValue(noiseAnalysisResults);

  if (sensorStats.length === 0)
    return (
      <SimpleAlert
        type="info"
        title="No sensor results"
        text="Place sensor point within the noise boundary to see sensor results."
      />
    );

  return (
    <Column style={{ gap: spacing6 }}>
      <SensorStats
        sensorStats={sensorStats}
        title={false}
        maxValue={maxValue}
      />
    </Column>
  );
};

const Results = () => {
  const analysis = lunwrap(useAtomValue(loadable(getNoiseAnalysisStatus)));
  const id = useAtomValue(noiseIdAtom);

  if (isAnalysisStatusFailed(analysis)) {
    return (
      <SimpleAlert
        type="error"
        title="Analysis failed - the Vind team is notified"
      />
    );
  } else if (isAnalysisStatusStopped(analysis)) {
    return (
      <SimpleAlert
        type="error"
        title="Analysis stopped"
        text={getErrorText(analysis.reason)}
      />
    );
  } else if (isAnalysisRunning(analysis)) {
    return (
      <SkeletonText
        text={
          analysis.progress
            ? `${roundToDecimal(analysis.progress * 100, 0)}%`
            : undefined
        }
      />
    );
  } else if (id === undefined) {
    return <ResultsEmptyState />;
  }

  return <ResultsInner />;
};

const Inner = () => {
  const [isLoading, setIsLoading] = useState(false);

  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const soundLevelsRef = useRef<HTMLButtonElement>(null);
  const advancedSettingsRef = useRef<HTMLButtonElement>(null);

  const analysisSettings = useAtomValue(noiseAnalysisSettingsAtom);
  const inputsChanged = useAtomValue(unwrap(noiseInputsChanged));

  const resetAnalysis = useSetAtom(resetAnalysisAtom);
  const triggerAnalysis = useSetAtom(triggerAnalysisAtom);
  const [openSubmenu, setOpenSubmenu] = useAtom(openSubmenuAtom);

  const updateAnalysisInput = useSetAtom(updateNoiseAnalysisSettingsAtom);

  const lowerRightModeOpen = !!useAtomValue(lowerRightMenuActiveModeAtom);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  const analysisStatus = lunwrap(
    useAtomValue(loadable(getNoiseAnalysisStatus)),
  );

  useEffect(() => {
    if (
      isAnalysisStatusFailed(analysisStatus) ||
      isAnalysisStatusStopped(analysisStatus) ||
      isAnalysisStatusComplete(analysisStatus)
    ) {
      setIsLoading(false);
    }
  }, [analysisStatus]);

  useEffect(() => {
    if (inputsChanged) {
      resetAnalysis();
      setIsLoading(false);
    }
  }, [inputsChanged, resetAnalysis]);

  return (
    <>
      <Column
        style={{
          gap: spacing7,
          padding: `0 ${spacing7}  ${spacing7} ${spacing7} `,
          maxHeight: `calc(100vh - 15rem - ${
            lowerRightModeOpen ? "58rem" : "32rem"
          } - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
          overflowY: "auto",
        }}
      >
        <Suspense fallback={<ResultsLoadingState />}>
          <SubtitleWithLine text="Settings" />
          <Row alignCenter>
            <InputTitle>Sound level at turbine</InputTitle>
            <InputDimensioned
              compact
              id="turbine-sound-level-input"
              value={analysisSettings.source}
              onChange={(n) => updateAnalysisInput({ source: n })}
              readOnly={!editorAccessProject}
              validate={(n) => 90 <= n && n <= 120}
              unit={"dB"}
              style={{ width: "9.7rem" }}
            />
            <IconBtn
              active={openSubmenu === "sound-levels"}
              backgroundColor={colors.surfaceButtonSecondary}
              size="1.4rem"
              id="noise-submenu-open-sound-levels"
              ref={soundLevelsRef}
              onClick={() => {
                setOpenSubmenu(replaceOrUndefined("sound-levels"));
              }}
            >
              <Frequency />
            </IconBtn>
          </Row>

          <Row alignCenter>
            <IconBtn
              active={openSubmenu === "advanced-settings"}
              backgroundColor={colors.surfaceButtonSecondary}
              size="1.4rem"
              id="noise-submenu-open-advanced-settings"
              ref={advancedSettingsRef}
              onClick={() => {
                setOpenSubmenu(replaceOrUndefined("advanced-settings"));
              }}
            >
              <OpenLeft />
            </IconBtn>
            <InputTitle style={{ flex: 1 }}>Advanced settings</InputTitle>
          </Row>

          <Row>
            <Button
              text={"Calculate"}
              icon={isLoading ? <Spinner size="1rem" /> : undefined}
              onClick={() => {
                setIsLoading(true);
                triggerAnalysis();
              }}
              style={{ alignSelf: "end" }}
              disabled={isLoading}
            />
          </Row>
        </Suspense>

        <VisualizationSettings />
      </Column>
      {openSubmenu === "sound-levels" && (
        <SoundLevels buttonRef={soundLevelsRef} />
      )}
      <Column>
        <SubtitleWithLine text="Results" />
        <Suspense fallback={<LoadingState />}>
          <Results />
        </Suspense>
      </Column>
      {openSubmenu === "advanced-settings" && (
        <AdvancedSettings buttonRef={advancedSettingsRef} />
      )}
    </>
  );
};

export const OnshoreNoiseAnalysis = ({ onClose }: { onClose(): void }) => {
  return (
    <>
      <MenuFrame
        title={"Noise"}
        icon={<HelpLink article={ARTICLE_NOISE_ANALYSIS} />}
        onExit={onClose}
        style={{
          overflow: "visible",
        }}
      >
        <Suspense fallback={<SkeletonText />}>
          <Inner />
        </Suspense>
        <SelectedRaster />
        <SelectedBoundary />
      </MenuFrame>
    </>
  );
};
