import Fuse, { FuseOptionKey, IFuseOptions } from "fuse.js";
import { Atom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useAtomValueConditional } from "utils/jotai";

export function useSearch<T>(
  enabled: boolean,
  searchTerm: string,
  fuseKeys: FuseOptionKey<T>[],
  atom: Atom<Promise<T[]> | T[]>,
  fuseOptions?: Omit<IFuseOptions<T>, "keys">,
) {
  const _dataPromise = useAtomValueConditional(enabled, atom);
  const [data, setData] = useState<T[] | null>();
  const [filteredResults, setFilteredResults] = useState<
    { item: T; score: number }[]
  >([]);

  const fuseObject = useMemo(() => {
    if (!data || !enabled) return;
    return new Fuse(data, {
      keys: fuseKeys,
      includeScore: true,
      threshold: 0.3,
      ...fuseOptions,
    });
  }, [data, fuseKeys, fuseOptions, enabled]);

  useEffect(() => {
    let mounted = true;

    if (!enabled) {
      setData(null);
      setFilteredResults([]);
      return;
    } else if (_dataPromise) {
      if (_dataPromise instanceof Promise) {
        _dataPromise.then((items: T[]) => {
          if (mounted) {
            setData(items);
          }
        });
      } else {
        setData(_dataPromise);
      }
    }

    return () => {
      mounted = false;
    };
  }, [enabled, _dataPromise]);

  useEffect(() => {
    if (!fuseObject) return;
    if (searchTerm.length > 0) {
      const results = fuseObject.search(searchTerm);
      const items = results.map((result) => ({
        item: result.item,
        score: result.score ?? 0,
      }));
      setFilteredResults(items);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm, fuseObject]);

  return { filteredResults, rawData: data ?? undefined };
}
