import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React from "react";
import type { Node } from "services/customerAPI";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import MoveNodeModal from "./shared/MoveNodeModal";

const MoveProjectModal = ({
  nodeId,
  onMoveClick,
  onClose,
}: {
  nodeId: string;
  onMoveClick(toFolderNode: Node): Promise<void>;
  onClose(): void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const node = useAtomValue(
    getNodeSelectorFamily({
      organisationId,
      nodeId,
    }),
  );

  if (!node) {
    return null;
  }

  return (
    <MoveNodeModal
      nodeId={nodeId}
      title={`Move project "${node?.name}"`}
      onMoveClick={onMoveClick}
      type="project"
      onClose={onClose}
    />
  );
};

export default MoveProjectModal;
