import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { cablesInParkFamily } from "state/jotai/cable";
import { cableTypesFamily } from "state/jotai/cableType";
import { ValidationWarning } from "state/validationWarnings";

export const differentIAVoltageTypesFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.CablesDifferentIAVoltageTypes>
        | undefined
      >
    >(async (get) => {
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));

      let ia = undefined;
      for (const c of cables) {
        if (!c.properties.cableTypeId) continue;
        const typ = cableTypes.get(c.properties.cableTypeId);
        if (!typ) continue;
        if (!ia) ia = typ.voltage;
        if (ia !== typ.voltage) {
          return {
            type: ValidationWarningTypes.CablesDifferentIAVoltageTypes,
            parkId: parkId,
          };
        }
      }
    }),
);
