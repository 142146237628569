import { Atom } from "jotai";
import styled from "styled-components";
import { MaybePromise } from "types/utils";
import { useAtomUnwrap } from "utils/jotai";
import AnimatedLoading from "./AnimatedLoading";
import { colors } from "styles/colors";

const _LoadingIndicator = styled.div`
  pointer-events: none;
  svg {
    overflow: visible;
    path {
      stroke-width: 3px;
    }
  }
`;
export const LoadingIndicator = ({
  atom,
}: {
  atom: Atom<MaybePromise<boolean>>;
}) => {
  const hasRunningProblems = useAtomUnwrap(atom);
  if (!hasRunningProblems) return null;
  return (
    <_LoadingIndicator>
      <AnimatedLoading
        $highlightColor={colors.indigo600}
        $baseColor={colors.indigo100}
        $size="14px"
      />
    </_LoadingIndicator>
  );
};
