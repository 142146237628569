import { useJotaiCallback } from "utils/jotai";
import useAblyClient from "components/Ably/useAblyClient";

export default function useAblyRefreshToken(clientId: string) {
  const client = useAblyClient(clientId);
  const callback = useJotaiCallback(async () => {
    if (!client) return;

    return client.auth.authorize();
  }, [client]);

  return callback;
}
