import { useAtomValue, useSetAtom } from "jotai";
import { mapAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import mapboxgl, { ImageSource } from "mapbox-gl";
import {
  ExternalDataSourceLinkLayerWithSourceArcgis,
  LayerType,
} from "../../types/layers";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import {
  getRotationAndTiltIndependentViewportBounds,
  safeRemoveLayer,
} from "../../utils/map";
import { isArcgisLayer } from "../../state/arcgisRestAPI";
import { layerToSourceId } from "./utils";
import {
  addCorsAndCacheProxyURL,
  CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
} from "../../state/gisSourceCorsProxy";
import { externalWFSAnchorId } from "../../components/Mapbox/utils";
import { NATIVE_MAP_ID } from "components/MapNative/MapNative";
import { dynamicVectorLayerFeaturesAtomFamily } from "state/layer";
import { fetchWithToken } from "services/utils";
import { minArcgisRasterZoomLevel } from "@constants/layer";
import { useMapZoomLevel } from "hooks/useMapZoomLevel";

const getUrl = (
  layer: ExternalDataSourceLinkLayerWithSourceArcgis,
  bbox: number[],
) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;
  const mapElement = document.getElementById(NATIVE_MAP_ID);
  if (!mapElement) throw new Error("Could not find the map element");
  const windowWidth = mapElement.clientWidth;
  const windowHeight = mapElement.clientHeight;

  return `${addCorsAndCacheProxyURL(layer.sourceLink.url, isPrivate)}/export?dpi=96&transparent=true&format=png32&layers=show%3A${layer.sourceLayerId}&bbox=${bbox}&bboxSR=4326&imageSR=3857&size=${windowWidth}%2C${windowHeight}&f=image`;
};

const testURL = (layer: ExternalDataSourceLinkLayerWithSourceArcgis) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;
  const mapElement = document.getElementById(NATIVE_MAP_ID);
  if (!mapElement) throw new Error("Could not find the map element");

  return `${addCorsAndCacheProxyURL(layer.sourceLink.url, isPrivate)}/export?f=json`;
};

const getBBOXes = (bounds: mapboxgl.LngLatBounds) => {
  let [xMin, yMin] = bounds.getSouthWest().toArray();
  let [xMax, yMax] = bounds.getNorthEast().toArray();

  const width = xMax - xMin;
  if (xMin < -180) {
    xMin = -180;
    xMax = Math.min(180, xMin + width);
  } else if (xMax > 180) {
    xMax = 180;
    xMin = Math.max(-180, xMax - width);
  }

  const xyMinMaxBBOX = [xMin, yMin, xMax, yMax];
  const bbox = [
    [xMin, yMax],
    [xMax, yMax],
    [xMax, yMin],
    [xMin, yMin],
  ] as [[number, number], [number, number], [number, number], [number, number]];

  return {
    bbox,
    xyMinMaxBBOX,
  };
};

const DynamicArcgisRasterLayer = ErrorBoundaryWrapper(
  ({ layer }: { layer: ExternalDataSourceLinkLayerWithSourceArcgis }) => {
    const map = useAtomValue(mapAtom);
    const sourceId = useMemo(() => layerToSourceId(layer, "-raster"), [layer]);
    const setDynamicVectorLayerFeatures = useSetAtom(
      dynamicVectorLayerFeaturesAtomFamily(layer.id),
    );

    useEffect(() => {
      const checkLayerError = async () => {
        const url = testURL(layer);
        const urlNeedsToken = url.includes(
          CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX,
        );
        const fetchResult = await (urlNeedsToken
          ? fetchWithToken(url, {})
          : fetch(url));

        const data = (await fetchResult.json()) as Record<string, any>;
        if (
          data.error &&
          data.error.code &&
          [499, 498, 403, 401].includes(data.error.code)
        ) {
          setDynamicVectorLayerFeatures((prev) => ({
            ...prev,
            hasError: true,
          }));
        }
      };
      checkLayerError();
    }, [setDynamicVectorLayerFeatures, layer]);

    useEffect(() => {
      if (!map) {
        return;
      }
      const bounds =
        getRotationAndTiltIndependentViewportBounds(map) ?? map.getBounds();
      if (!bounds) return;
      const { bbox, xyMinMaxBBOX } = getBBOXes(bounds);

      map.addSource(sourceId, {
        type: "image",
        url: getUrl(layer, xyMinMaxBBOX),
        coordinates: bbox,
      });

      map.addLayer(
        {
          id: sourceId,
          type: "raster",
          source: sourceId,
          paint: {
            "raster-fade-duration": 0,
          },
        },
        map.getLayer(externalWFSAnchorId) ? externalWFSAnchorId : undefined,
      );

      const redefineUrl = ({
        target: currentMap,
      }: mapboxgl.MapEventOf<"moveend">) => {
        const bounds = getRotationAndTiltIndependentViewportBounds(currentMap);
        if (!bounds) return;
        const { bbox, xyMinMaxBBOX } = getBBOXes(bounds);

        const source = currentMap.getSource(sourceId) as
          | ImageSource
          | undefined;
        if (!source) {
          return;
        }

        source.updateImage({
          url: getUrl(layer, xyMinMaxBBOX),
          coordinates: bbox,
        });
      };

      map.on("moveend", redefineUrl);

      return () => {
        map.off("moveend", redefineUrl);
        safeRemoveLayer(map, sourceId);
        map.removeSource(sourceId);
      };
    }, [layer, map, sourceId]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicArcgisRasterLayers = ErrorBoundaryWrapper(
  () => {
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const visibleLayers = useAtomValue(
      getVisibleLayers({
        projectId,
      }),
    );
    const selectedDynamicArcgisLayers = visibleLayers
      .filter(isArcgisLayer)
      .filter((layer) => layer.type === LayerType.Raster);

    const zoomLevel = useMapZoomLevel();

    if (zoomLevel < minArcgisRasterZoomLevel) {
      return null;
    }

    return (
      <>
        {selectedDynamicArcgisLayers.map((layer) => {
          return <DynamicArcgisRasterLayer key={layer.id} layer={layer} />;
        })}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default DynamicArcgisRasterLayers;
