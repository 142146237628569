import {
  advancedTurbineTypesFamily,
  libraryTurbineTypesFamily,
} from "state/jotai/turbineType";
import { useJotaiCallback } from "utils/jotai";
import { SimpleTurbineType } from "types/turbines";
import { organisationIdAtom } from "state/pathParams";

export function useLibraryNodeTurbines(projectId: string) {
  const updateLocal = useJotaiCallback(
    async (get, set, turbine: SimpleTurbineType) => {
      set(
        libraryTurbineTypesFamily(projectId),
        async (currentTurbinesPromise) => {
          const currentTurbines = await currentTurbinesPromise;
          const newTurbines = new Map(currentTurbines);
          newTurbines.set(turbine.id, turbine);
          return newTurbines;
        },
      );

      const orgId = get(organisationIdAtom) ?? "";

      set(
        advancedTurbineTypesFamily({
          nodeId: orgId,
          turbineId: turbine.id,
        }),
        async (currentTurbinePromise) => {
          const currentTurbine = await currentTurbinePromise;
          if (!currentTurbine) return currentTurbinePromise;

          return {
            ...currentTurbine,
            ...turbine,
          };
        },
      );
    },
    [projectId],
  );

  return {
    updateLocal,
  };
}
