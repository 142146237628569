import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  DEFAULT_CANVAS_LAYER_OPACITY,
  lockedPropertyName,
  opacityPropertyName,
  zoomPropertyName,
  displayLabelPropertyName,
} from "@constants/canvas";
import { MultiPoint, Point } from "geojson";
import { CirclePaint, SymbolLayer } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "services/types";
import { colors } from "styles/colors";
import { OtherFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  otherPointSymbolLayerId,
  otherPointSourceId,
  otherPointLayerId,
  DEFAULT_ZOOM_THRESHOLD,
} from "./constants";
import { isOnshoreAtom } from "state/onshore";
import { atom, useAtomValue } from "jotai";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const pointPaintAtom = atom<CirclePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "circle-color": [
      "case",
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      ["string", ["get", "color"], colors.otherPoint],
    ],
    "circle-radius": defaultPointCircleRadius,
    "circle-opacity": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      DEFAULT_CANVAS_LAYER_OPACITY,
      [
        "boolean",
        ["feature-state", "hover"],
        ["feature-state", "selected"],
        false,
      ],
      [
        "+",
        ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
        0.2,
      ],
      ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
    ],
    "circle-stroke-color": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      colors.lockedFeatureOutline,
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      ["!=", ["feature-state", "color"], null],
      [
        "let",
        "rgba",
        ["to-rgba", ["to-color", ["get", "color"]]],
        [
          "let",
          "r",
          ["number", ["*", 0.7, ["at", 0, ["var", "rgba"]]]],
          "g",
          ["number", ["*", 0.7, ["at", 1, ["var", "rgba"]]]],
          "b",
          ["number", ["*", 0.7, ["at", 2, ["var", "rgba"]]]],
          "a",
          ["number", ["at", 3, ["var", "rgba"]]],
          ["rgba", ["var", "r"], ["var", "g"], ["var", "b"], ["var", "a"]],
        ],
      ],
      ["boolean", ["feature-state", "selected"], false],
      onshore ? colors.onElOtherPointBorderSel : colors.otherPointBorderSel,
      onshore ? colors.onElOtherPointBorder : colors.otherPointBorder,
    ],
    "circle-stroke-width": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      2.0,
      ["!=", ["feature-state", "borderColor"], null],
      2.0,
      ["boolean", ["feature-state", "hover"], false],
      ["case", ["boolean", ["feature-state", "selected"], false], 2.0, 1.0],
      ["boolean", ["feature-state", "selected"], false],
      2.0,
      0.0,
    ],
  };
});

const symbolLayer: SymbolLayer = {
  id: otherPointSymbolLayerId,
  source: otherPointSourceId,
  type: "symbol",
  minzoom: 8,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
    "text-offset": [0, 1],
  },
  paint: {
    "text-opacity": [
      "step",
      ["zoom"],
      ["case", ["==", ["get", zoomPropertyName], true], 0, 0],
      DEFAULT_ZOOM_THRESHOLD,
      [
        "case",
        ["boolean", ["feature-state", "editing"], false],
        0.0,
        ["==", ["get", "type"], BathymetryUserUploadedType],
        0.0,
        ["==", ["get", "type"], GeoTiffUserUploadedImageType],
        0.0,
        0.6,
      ],
    ],
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderOtherPoints = ({
  features,
  map,
  showSelectionHighlighter,
}: {
  features: OtherFeature<Point | MultiPoint>[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const pointPaint = useAtomValue(pointPaintAtom);

  useEffect(() => {
    map.addSource(otherPointSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, symbolLayer.id);
      safeRemoveLayer(map, otherPointLayerId);
      map.removeSource(otherPointSourceId);
    };
  }, [map, pointPaint]);

  useEffect(() => {
    addLayer(map, {
      id: otherPointLayerId,
      type: "circle",
      source: otherPointSourceId,
      paint: pointPaint,
    });
    addLayer(map, symbolLayer);
  }, [map, pointPaint]);

  useEffect(() => {
    const source = map.getSource(otherPointSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={otherPointSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
