import { useAtomValue } from "jotai";
import * as Sentry from "@sentry/react";
import { IAcableTypesFamily } from "state/jotai/cableType";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { foundationTypesAtom } from "state/jotai/foundation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { ProjectFeature } from "types/feature";
import { sendWarning } from "utils/sentry";
import { VindFeaturesParser } from "./utils";
import { AddFeaturesToElementsReturnType } from "hooks/useAddFeaturesIntoElementsWithinLimits";
import { cleanGeojsonFeatures } from "components/UploadFile/fileUtils";
import { useCallback } from "react";
import {
  isCable,
  isExportCable,
  isSubstation,
  isTurbine,
} from "utils/predicates";
import { useToast } from "hooks/useToast";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";
import { isOnshoreAtom } from "state/onshore";

export default function usePasteFeatures() {
  const { info } = useToast();

  const onshore = useAtomValue(isOnshoreAtom);
  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const allCableTypes = useAtomValue(
    IAcableTypesFamily({ projectId: undefined }),
  );
  const allFoundationTypes = useAtomValue(foundationTypesAtom);
  const allSubStations = useAtomValue(allSubstationsForProjectAtom);
  const allExportCables = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );

  const cleanResourceIdFromFeatures = useCallback(
    (features: ProjectFeature[]) => {
      const cleanedResourcesTypes = new Set<string>();

      // Check the feature types of the pasted features to see if they're still valid.
      // For instance, if you copy a turbine from project A to project B, but it
      // used a turbine type defined in project A, we don't want to keep that.
      // However, if it was a library turbine that's accessible in both
      // projects, we do want to keep it.
      const cleanedFeatures = features.map((f) => {
        if (isTurbine(f)) {
          const turbineTypeId = allTurbineTypes.get(
            f.properties.turbineTypeId,
          )?.id;
          const foundationId = f.properties.foundationId
            ? allFoundationTypes.get(f.properties.foundationId)?.id
            : undefined;

          if (!turbineTypeId) {
            cleanedResourcesTypes.add("turbine(s)");
          }
          if (!foundationId && !onshore) {
            cleanedResourcesTypes.add("foundation(s)");
          }

          return {
            ...f,
            properties: {
              ...f.properties,
              turbineTypeId,
              foundationId,
            },
          };
        } else if (isCable(f)) {
          const cableTypeId = f.properties.cableTypeId
            ? allCableTypes.get(f.properties.cableTypeId)?.id
            : undefined;

          if (!cableTypeId) {
            cleanedResourcesTypes.add("cable(s)");
          }

          return {
            ...f,
            properties: {
              ...f.properties,
              cableTypeId,
            },
          };
        } else if (isExportCable(f)) {
          const exportCableTypeId = f.properties.cableTypeId
            ? allExportCables.get(f.properties.cableTypeId)?.id
            : undefined;
          const onshoreCableTypeId = f.properties.onshoreCableTypeId
            ? allExportCables.get(f.properties.onshoreCableTypeId)?.id
            : undefined;

          if (!exportCableTypeId || !onshoreCableTypeId) {
            cleanedResourcesTypes.add("export cable(s)");
          }

          return {
            ...f,
            properties: {
              ...f.properties,
              cableTypeId: exportCableTypeId,
              onshoreCableTypeId,
            },
          };
        } else if (isSubstation(f)) {
          const substationTypeId = f.properties.substationTypeId
            ? allSubStations.get(f.properties.substationTypeId)?.id
            : undefined;

          if (!substationTypeId) {
            cleanedResourcesTypes.add("substation(s)");
          }

          return {
            ...f,
            properties: {
              ...f.properties,
              substationTypeId,
            },
          };
        } else return f;
      });

      // Create toast message
      const cleanedResourcesMessage = Array.from(cleanedResourcesTypes)
        .map((t, i, arr) => (i === arr.length - 1 && i > 0 ? `and ${t}` : t))
        .join(", ");

      if (cleanedResourcesMessage) {
        info(
          `Some ${cleanedResourcesMessage} were pasted without a resource id because the resource was not found in this project.`,
        );
      }

      return cleanedFeatures;
    },
    [
      allCableTypes,
      allExportCables,
      allFoundationTypes,
      allSubStations,
      allTurbineTypes,
      info,
      onshore,
    ],
  );

  const pasteFeatures = useCallback(
    (
      obj: VindFeaturesParser,
      cloneFeatures: AddFeaturesToElementsReturnType,
    ) => {
      try {
        const cleanedJson = {
          ...obj,
          features: cleanGeojsonFeatures(obj.features).cleaned,
        };

        const cleanedFeatures = cleanResourceIdFromFeatures(
          cleanedJson.features,
        );

        Sentry.addBreadcrumb({
          category: "project",
          message: "Pasting features from clipboard",
          data: {
            nrFeatures: cleanedFeatures.length,
          },
        });

        cloneFeatures({
          add: cleanedFeatures,
          successMessage: "Pasted",
          selectAfterCloning: true,
        });
      } catch (err) {
        if (!(err instanceof SyntaxError)) {
          sendWarning("Something went wrong when trying to paste features", {
            obj,
            err,
          });
        }
      }
    },
    [cleanResourceIdFromFeatures],
  );

  return {
    pasteFeatures,
  };
}
