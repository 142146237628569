import { atom } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { branchMetaFamily } from "./branch";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { listOperationsConfigurations } from "services/operationsConfigurationService";

export const projectOperationsConfigurationsFamily = atomFamily(
  (nodeId: string) =>
    atomFromFn<Promise<Map<string, OperationsConfiguration>>>(async () => {
      const configs = await listOperationsConfigurations(nodeId);
      return new Map(configs.map((c) => [c.id, c]));
    }),
);

export const projectOperationsConfigurationsByNameFamily = atomFamily(
  (nodeId: string) =>
    atom<Promise<OperationsConfiguration[]>>(async (get) => {
      const m = await get(projectOperationsConfigurationsFamily(nodeId));
      return [...m.values()].sort((a, b) =>
        (a.name ?? "").localeCompare(b.name ?? ""),
      );
    }),
);

export const operationsConfigurationsFamily = atomFamily(
  (input: { projectId: string | undefined }) =>
    atomFromFn<Promise<Map<string, OperationsConfiguration>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      if (!projectId)
        throw new Error(
          "operationsConfigurationsFamily requires projectIdAtom",
        );
      const proj = await get(projectOperationsConfigurationsFamily(projectId));
      return proj;
    }),
);

/**
 * This is all done at client side.
 * The relationship present here is not the same a our typical other "usage" endpoints.
 * This will require some thinking when this feature ends up at library level.
 */
export const vesselsUsedInOperationsConfig = atom(async (get) => {
  const projectId = get(projectIdAtom);
  const operationsConfigurations = await get(
    operationsConfigurationsFamily({ projectId }),
  );
  const vesselIdToConfig = new Map<string, { id: string; name: string }[]>();
  Array.from(operationsConfigurations.values()).forEach(
    (config: OperationsConfiguration) => {
      const { ti } = config;
      const vesselIds = [
        ti.turbines.installationVessel.vesselId,
        ti.substation.installationVessel.vesselId,
        ti.substation.feederVessel.vesselId,
        ti.mooring.installationVessel.vesselId,
        ti.foundations.floaters.installationVessel.vesselId,
        ti.foundations.floaters.towingVessel.vesselId,
        ti.foundations.monopiles.installationVessel.vesselId,
        ti.foundations.jackets.installationVessel.vesselId,
        ti.foundations.jackets.feederVessel.vesselId,
        ti.foundations.scourProtection.installationVessel.vesselId,
        ti.exportCable.installationVessel.vesselId,
        ti.cables.installationVessel.vesselId,
      ];
      vesselIds.forEach((vesselId) => {
        if (vesselIdToConfig.has(vesselId)) {
          if (
            !vesselIdToConfig.get(vesselId)?.some((v) => v.id === config.id)
          ) {
            vesselIdToConfig
              .get(vesselId)
              ?.push({ id: config.id, name: config.name });
          }
        } else {
          vesselIdToConfig.set(vesselId, [
            { id: config.id, name: config.name },
          ]);
        }
      });
    },
  );
  return vesselIdToConfig;
});

export const vesselIdUsedInOperationsConfigs = atomFamily((vesselId: string) =>
  atom(async (get) => {
    const vesselIdToConfig = await get(vesselsUsedInOperationsConfig);
    return vesselIdToConfig.get(vesselId) ?? [];
  }),
);

export const operationsConfigurationSelectedFamily = atomFamily(
  (input: { branchId: string | undefined; projectId: string }) =>
    atom(async (get) => {
      const { projectId } = input;
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId)
        throw new Error(
          "windConfigurationSelectedFamily requires branchIdAtom",
        );
      const [branch, configs] = await Promise.all([
        get(branchMetaFamily({ branchId, projectId })),
        get(operationsConfigurationsFamily({ projectId })),
      ]);
      return configs.get(branch?.operationsConfigurationId ?? "");
    }),
);
