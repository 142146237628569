import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { ModalFrame, Column, Row } from "components/General/Layout";
import { modalTypeOpenAtom } from "state/modal";
import { ArchivedProjectVersion } from "../types";
import styled from "styled-components";
import { typography } from "styles/typography";
import { useDownloadFile } from "./useDownloadFile";
import DownloadIcon from "@icons/24/Download.svg?react";
import CheckCircle from "@icons/14/CheckCircle.svg?react";
import { Icon } from "components/General/Icons";
import { colors } from "styles/colors";
import { useAtom, useAtomValue } from "jotai";

export const ArchivedVersionSuccessModalTypeName =
  "ArchivedVersionSuccessModal";
export type ArchivedVersionSuccessModalType = {
  modalType: typeof ArchivedVersionSuccessModalTypeName;
  metadata: ArchivedProjectVersion;
};

const SuccessModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== ArchivedVersionSuccessModalTypeName)
    return null;

  return <SuccessModalInner />;
};

const Text = styled.p`
  ${typography.caption};
  margin: 0;
`;

const SuccessModalInner = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);

  const { download, inProgress } = useDownloadFile();

  if (modalTypeOpen?.modalType !== ArchivedVersionSuccessModalTypeName)
    return null;

  return (
    <FullScreenModal>
      <ModalFrame
        style={{ width: "35rem" }}
        title="Data export complete"
        onExit={() => setModalTypeOpen(undefined)}
        icon={
          <Icon
            fill={colors.green600}
            size={"1.4rem"}
            style={{
              padding: "0.5rem",
              borderRadius: "2rem",
              backgroundColor: colors.green50,
              justifyContent: "center",
              alignItems: "center",
              width: "2.4rem",
              height: "2.4rem",
            }}
          >
            <CheckCircle />
          </Icon>
        }
      >
        <Column style={{ gap: "2.4rem", alignItems: "center" }}>
          <Text>
            You can now download the data as a zip file. The data can also be
            downloaded from the ‘Saved project data’ list whenever you like.
          </Text>
          <Row style={{ marginLeft: "auto" }}>
            <Button
              buttonType="secondary"
              text="Close"
              onClick={() => setModalTypeOpen(undefined)}
            />
            <Button
              icon={<DownloadIcon />}
              text="Download data"
              disabled={inProgress}
              onClick={async () => {
                await download(modalTypeOpen.metadata);
                setModalTypeOpen(undefined);
              }}
            />
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default SuccessModal;
