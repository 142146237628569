import {
  currentSelectionArrayAtom,
  currentSelectionWMSAtom,
} from "../state/selection";
import { currentExternalLayerSelection } from "../state/externalLayerSelection";
import { useCallback } from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ProjectFeature } from "../types/feature";
import { resetListIfNotAlreadyEmpty } from "../utils/resetList";
import useNavigateToPark from "./useNavigateToPark";
import { useAtom } from "jotai";

const useSelectionInMap = () => {
  const { navigateToPark } = useNavigateToPark();
  const [currentSelectionArray, setCurrentSelectionArray] = useAtom(
    currentSelectionArrayAtom,
  );
  const [externalLayerSelection, setExternalLayerSelection] = useAtom(
    currentExternalLayerSelection,
  );
  const [WMSSelection, setWMSSelection] = useAtom(currentSelectionWMSAtom);

  const resetCurrentSelectionArray = useCallback(() => {
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [setCurrentSelectionArray]);

  const resetExternalLayerSelection = useCallback(() => {
    setExternalLayerSelection(resetListIfNotAlreadyEmpty);
  }, [setExternalLayerSelection]);

  const resetWMSLayerSelection = useCallback(() => {
    setWMSSelection(resetListIfNotAlreadyEmpty);
  }, [setWMSSelection]);

  const onProjectFeatureClick = useCallback(
    (
      features: MapboxGeoJSONFeature[],
      shiftClicked: boolean,
      deselectIfAlreadySelected = true,
    ) => {
      if (!shiftClicked) {
        resetExternalLayerSelection();
        resetWMSLayerSelection();
      }

      setCurrentSelectionArray((currentlySelectedIds) => {
        const firstClicked = features[0];
        if (!firstClicked) return currentlySelectedIds;

        const id = firstClicked.id;
        if (!id) {
          return currentlySelectedIds;
        }

        const isAlreadySelected = currentlySelectedIds.find(
          (selectedId) => selectedId === id,
        );

        const newSelection = String(id);
        if (shiftClicked) {
          if (isAlreadySelected) {
            return currentlySelectedIds.filter(
              (selectedId) => selectedId !== id,
            );
          }
          return [...currentlySelectedIds, newSelection];
        } else {
          if (
            isAlreadySelected &&
            currentlySelectedIds.length === 1 &&
            deselectIfAlreadySelected
          ) {
            return [];
          }
          return [newSelection];
        }
      });
      const parentIds = features[0]?.properties?.parentIds
        ? (JSON.parse(features[0].properties.parentIds) as string[])
        : undefined;
      if (parentIds && parentIds.length > 0) {
        navigateToPark(parentIds[0]);
      }
    },
    [
      navigateToPark,
      resetExternalLayerSelection,
      resetWMSLayerSelection,
      setCurrentSelectionArray,
    ],
  );

  const onExternalLayerClick = useCallback(
    (
      features: MapboxGeoJSONFeature[],
      shiftClicked: boolean,
      deselectIfAlreadySelected = true,
    ) => {
      if (!shiftClicked) {
        resetCurrentSelectionArray();
        resetWMSLayerSelection();
      }

      setExternalLayerSelection((currentlySelected) => {
        const id = features[0].id;
        if (!id) {
          return currentlySelected;
        }

        const isAlreadySelected = currentlySelected.find(
          (selectedId) => selectedId.id === id,
        );

        if (shiftClicked) {
          if (isAlreadySelected) {
            return currentlySelected.filter(
              (selectedId) => selectedId.id !== id,
            );
          }
          return [...currentlySelected, features[0]];
        } else {
          if (
            isAlreadySelected &&
            currentlySelected.length === 1 &&
            deselectIfAlreadySelected
          ) {
            return [];
          }
          return [features[0]];
        }
      });
    },
    [
      resetCurrentSelectionArray,
      resetWMSLayerSelection,
      setExternalLayerSelection,
    ],
  );

  const getIdsOfSelectedFeatures = useCallback(
    (features: ProjectFeature[]): string[] => {
      return currentSelectionArray.filter((selectedId) =>
        features.find((feature) => feature.id === selectedId),
      );
    },
    [currentSelectionArray],
  );

  const getIdsOfSelectedExternalLayers = useCallback(
    (features: ProjectFeature[]): string[] => {
      return externalLayerSelection
        .filter((selected) =>
          features.find((feature) => feature.id === selected.id),
        )
        .map((selected) => String(selected.id));
    },
    [externalLayerSelection],
  );

  return {
    currentSelectionArray,
    externalLayerSelection,
    WMSSelection,
    onProjectFeatureClick,
    onExternalLayerClick,
    setCurrentSelectionArray,
    getIdsOfSelectedFeatures,
    getIdsOfSelectedExternalLayers,
    resetCurrentSelectionArray,
    resetExternalLayerSelection,
  };
};

export default useSelectionInMap;
