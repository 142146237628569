import { Buckets, Color, Gradient } from "lib/colors";
import { keysMatch } from "types/utils";
import { Defined, isNever } from "utils/utils";
import { safeArray } from "utils/zod";
import { z } from "zod";
import { StyleTurbine } from "./feature/turbine";
import { StyleCable } from "./feature/cable";
import { StyleAnchor } from "./feature/anchor";
import { StylePark } from "./feature/park";

export type Style = {
  id: string;
  /** Human readable name for the style */
  name?: string;
  /** Marker for the builtin default styles. These are immutable. */
  defaultMarker?: boolean;
  /** Default is small */
  labelSize?: "small" | "medium" | "large";
  /** Epoch in ms */
  createdAt: number;
} & (StyleTurbine | StyleCable | StyleAnchor | StylePark);

export const _StyleFeatureOffshore = z.enum([
  "anchors",
  "cables",
  "parks",
  "turbines",
]);
export const styleFeaturesOffshore =
  _StyleFeatureOffshore.options satisfies Style["feature"][];
const _StyleFeatureOnshore = z.enum([
  _StyleFeatureOffshore.Enum.cables,
  _StyleFeatureOffshore.Enum.parks,
  _StyleFeatureOffshore.Enum.turbines,
]);
export const styleFeaturesOnshore =
  _StyleFeatureOnshore.options satisfies Style["feature"][];
keysMatch<(typeof styleFeaturesOffshore)[number], Style["feature"]>(); // stay in sync with {@link Style}

/**
 * Return the type of the values for a color key.  This is used to ensure we
 * don't set e.g. gradient coloring for "cable type", since that doesn't make
 * sense.
 */
export function colorKeyType(
  k: ColorKey | undefined,
): "string" | "number" | undefined {
  switch (k) {
    case "aep":
    case "avg-wind-speed":
    case "capacity":
    case "depth":
    case "length":
    case "load":
    case "wake-loss":
      return "number";

    case "cable-type":
    case "group":
    case "name":
    case "single":
      return "string";

    case undefined:
      return undefined;
    default: {
      throw isNever(k);
    }
  }
}

export const _ColoringType = z.enum([
  "single",
  "bucket",
  "gradient",
  "category",
]);
const coloringTypes = _ColoringType.options;
keysMatch<(typeof coloringTypes)[number], Defined<Style["type"]>>(); // stay in sync with {@link Style}

export type BucketStyle = Style & { type: "bucket" };
export const isBucket = (s: Style | undefined): s is BucketStyle =>
  s?.type === "bucket";

export type GradientStyle = Style & { type: "gradient" };
export const isGradient = (s: Style | undefined): s is GradientStyle =>
  s?.type === "gradient";

export type SingleStyle = Style & { type: "single" };
export const isSingle = (s: Style | undefined): s is SingleStyle =>
  s?.type === "single";

export type CategoryStyle = Style & { type: "category" };
export const isCategory = (s: Style | undefined): s is CategoryStyle =>
  s?.type === "category";

/**
 * Strategy for coloring.  This is either a constant color, or color from a
 * given source, which is the generic parameter {@link Src}.
 */
export type Coloring<Src> =
  | { source: "single"; type: "single"; color: Color }
  | { source: Exclude<Src, "single">; type: "bucket"; buckets: Buckets }
  | { source: Exclude<Src, "single">; type: "gradient"; gradient: Gradient }
  | {
      source: Exclude<Src, "single">;
      type: "category";
      categories: ColorCategory[];
    };

/**
 * The set of values that can be the source for a color or a label, across
 * features types.
 */
export type ColorKey = Style["source"] | Defined<Style["label"]>;

export const _Color = z
  .object({
    r: z.number(),
    g: z.number(),
    b: z.number(),
    a: z.number(),
  })
  .transform((o) => new Color(o.r, o.g, o.b, o.a));

export const _Buckets = z
  .object({
    colors: _Color.array(),
    values: z.number().array(),
  })
  .transform<Buckets>((o) => {
    const b = new Buckets(Color.Transparent());
    b.colors = o.colors;
    b.values = o.values;
    return b;
  });

export const _Gradient = z
  .object({
    colors: _Color.array(),
    values: z.number().array(),
  })
  .transform<Gradient>((o) => {
    const g = new Gradient([
      [Color.Transparent(), 0],
      [Color.Transparent(), 1],
    ]);
    g.colors = o.colors;
    g.values = o.values;
    return g;
  });

const _ColorCategory = z.object({
  color: _Color,
  /** Key used for this category. */
  id: z.string(),
  /** Human readable name for the category */
  label: z.string().optional(),
});
export type ColorCategory = z.output<typeof _ColorCategory>;

export const _Styles = safeArray(
  z.union([
    z
      .object({ gradient: _Gradient, type: z.literal("gradient") })
      .passthrough(),
    z.object({ buckets: _Buckets, type: z.literal("bucket") }).passthrough(),
    z.object({ color: _Color, type: z.literal("single") }).passthrough(),
    z
      .object({
        categories: _ColorCategory.array(),
        type: z.literal("category"),
      })
      .passthrough(),
  ]),
);
