import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { UserAccessRole } from "../../../types/user";
import {
  addOrUpdateNodeAccessInGroup,
  removeNodeAccessFromGroup,
} from "./service";
import { groupProjectsState, nodeGroupUserAccessSelector } from "./state";
import useRefreshUsersInNodeAndSubnodes from "components/Organisation/useRefreshUsersInNodeAndSubnodes";

const useGroupNodeAccessCrud = () => {
  const refreshUsersInNodeAndSubnodes = useRefreshUsersInNodeAndSubnodes();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const addOrUpdate = useJotaiCallback(
    async (get, set, groupId: string, nodeId: string, role: UserAccessRole) => {
      const fallback = await get(
        groupProjectsState({
          organisationId,
          groupId,
        }),
      );
      return addOrUpdateNodeAccessInGroup(organisationId, groupId, nodeId, role)
        .then((projectAccess) => {
          aset(
            get,
            set,
            groupProjectsState({
              organisationId,
              groupId,
            }),
            (cur) => {
              const match = cur.find((c) => c.node_id === nodeId);
              if (match) {
                return cur.map((c) =>
                  c.node_id === nodeId ? projectAccess : c,
                );
              } else {
                return [...cur, projectAccess];
              }
            },
          );
          set(
            nodeGroupUserAccessSelector({
              organisationId,
              nodeId,
            }),
          );
          refreshUsersInNodeAndSubnodes(organisationId, nodeId);
        })
        .catch(() => {
          aset(
            get,
            set,
            groupProjectsState({
              organisationId,
              groupId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  const addOrUpdateMultiple = useJotaiCallback(
    async (
      get,
      set,
      groupIds: string[],
      nodeId: string,
      role: UserAccessRole,
    ) => {
      await Promise.all(
        groupIds.map((groupId) => {
          return addOrUpdateNodeAccessInGroup(
            organisationId,
            groupId,
            nodeId,
            role,
          ).then((projectAccess) => {
            return aset(
              get,
              set,
              groupProjectsState({
                organisationId,
                groupId,
              }),
              (cur) => {
                const match = cur.find((c) => c.node_id === nodeId);
                if (match) {
                  return cur.map((c) =>
                    c.node_id === nodeId ? projectAccess : c,
                  );
                } else {
                  return [...cur, projectAccess];
                }
              },
            );
          });
        }),
      );
      set(
        nodeGroupUserAccessSelector({
          organisationId,
          nodeId,
        }),
      );
      refreshUsersInNodeAndSubnodes(organisationId, nodeId);
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  const remove = useJotaiCallback(
    async (get, set, groupId: string, nodeId: string) => {
      const fallback = await get(
        groupProjectsState({
          organisationId,
          groupId,
        }),
      );
      return removeNodeAccessFromGroup(organisationId, groupId, nodeId)
        .then(() => {
          aset(
            get,
            set,
            groupProjectsState({
              organisationId,
              groupId,
            }),
            (cur) => [...cur].filter((p) => p.node_id !== nodeId),
          );
          set(
            nodeGroupUserAccessSelector({
              organisationId,
              nodeId,
            }),
          );
          refreshUsersInNodeAndSubnodes(organisationId, nodeId);
        })
        .catch(() => {
          aset(
            get,
            set,
            groupProjectsState({
              organisationId,
              groupId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId, refreshUsersInNodeAndSubnodes],
  );

  return {
    addOrUpdate,
    addOrUpdateMultiple,
    remove,
  };
};

export default useGroupNodeAccessCrud;
