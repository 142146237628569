import { organisationIdAtom } from "state/pathParams";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { Column, Row } from "components/General/Layout";
import {
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgExportcableManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgTurbineManageAccessSelector,
  orgSubstationManageAccessSelector,
} from "state/user";
import FoundationImage from "./foundation/FoundationImage";
import TurbineImage from "./turbine/TurbineImage";
import { _LibraryTabSchema } from "./state";
import AnalysisImage from "./analysis/AnalysisImage";
import CableImage from "./cable/CableImage";
import { libraryAllSelectorFamily } from "state/featureAccess";
import FinancialImage from "./financial/FinancialImage";
import DataLibraryImage from "./dataLibrary/DataLibraryImage";
import { TabHeader } from "../style";
import { useAtomValue } from "jotai";
import ExportCableImage from "./exportcable/ExportcableImage";
import SubstationImage from "./substation/SubstationImage";
import { typography } from "styles/typography";
import { spacing4 } from "styles/space";
import { spacing2 } from "styles/space";
import styled from "styled-components";
import { colors } from "styles/colors";
import { useNavigate } from "react-router-dom";

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  ${typography.sub1};
  color: ${colors.textPrimary};
  gap: 0.8rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
  width: 100%;
  padding: 1.2rem 0rem 0rem 0rem;
`;

const HorizontalLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${colors.grey200};
  margin-left: ${spacing2};
`;

const Library = ErrorBoundaryWrapper(
  () => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const navigate = useNavigate();

    const hasOrgTurbineAccess = useAtomValue(orgTurbineManageAccessSelector);
    const hasOrgFoundationAccess = useAtomValue(
      orgFoundationManageAccessSelector,
    );
    const hasOrgAnalysisAccess = useAtomValue(orgAnalysisManageAccessSelector);
    const hasOrgCableAccess = useAtomValue(orgCableManageAccessSelector);
    const hasOrgExportcableAccess = useAtomValue(
      orgExportcableManageAccessSelector,
    );
    const hasOrgFinancialAccess = useAtomValue(
      orgFinanicalManageAccessSelector,
    );
    const hasOrgDataLayersAccess = useAtomValue(
      orgDataPackagesManageAccessSelector,
    );
    const hasOrgSubstationAccess = useAtomValue(
      orgSubstationManageAccessSelector,
    );

    const allLibraryAccess = useAtomValue(
      libraryAllSelectorFamily({
        organisationId,
      }),
    );

    return (
      <>
        <TabHeader>Library</TabHeader>
        <Column
          style={{
            gap: "1.2rem",
            padding: "0.8rem 0",
            height: "calc(100% - 5rem)",
            overflow: "auto",
          }}
        >
          <TitleContainer>
            <Subtitle>Components</Subtitle>
            <HorizontalLine />
          </TitleContainer>
          <Row
            style={{
              alignItems: "center",
              gap: "2.4rem",
              flexWrap: "wrap",
            }}
          >
            <TurbineImage
              onClick={() => navigate(`turbine`)}
              disabled={!hasOrgTurbineAccess}
            />
            <FoundationImage
              onClick={() => navigate(`foundation`)}
              disabled={!hasOrgFoundationAccess}
            />
            {allLibraryAccess && (
              <>
                <CableImage
                  onClick={() => navigate(`cable`)}
                  disabled={!hasOrgCableAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Cable library access needed"
                  }
                />
                <ExportCableImage
                  onClick={() => navigate(`exportcable`)}
                  disabled={!hasOrgExportcableAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Export cable library access needed"
                  }
                />
                <SubstationImage
                  onClick={() => navigate(`substation`)}
                  disabled={!hasOrgSubstationAccess || !allLibraryAccess}
                />
              </>
            )}
          </Row>
          {allLibraryAccess && (
            <>
              <TitleContainer>
                <Subtitle>Configuration</Subtitle>
                <HorizontalLine />
              </TitleContainer>
              <Row
                style={{
                  gap: "2.4rem",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <AnalysisImage
                  onClick={() => navigate(`analysis`)}
                  disabled={!hasOrgAnalysisAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Analysis library access needed"
                  }
                />
                <FinancialImage
                  onClick={() => navigate(`financial`)}
                  disabled={!hasOrgFinancialAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "Fincancial configuration library access needed"
                  }
                />
              </Row>
              <TitleContainer>
                <Subtitle>GIS</Subtitle>
                <HorizontalLine />
              </TitleContainer>
              <Row>
                <DataLibraryImage
                  onClick={() => navigate(`datalayers`)}
                  disabled={!hasOrgDataLayersAccess || !allLibraryAccess}
                  disabledText={
                    !allLibraryAccess
                      ? "Upgrade your subscription"
                      : "GIS packages library access needed"
                  }
                />
              </Row>
            </>
          )}
        </Column>
      </>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default Library;
