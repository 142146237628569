import {
  getTotalExportSystemLoss,
  getTotalExportSystemLossGWh,
  getTotalInterArrayLoss,
  getTotalInterArrayLossGWh,
} from "analysis/electrical";
import { getConfiguration } from "analysis/inputs";
import { orTextLoader } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { colors } from "styles/colors";
import { undefMap } from "utils/utils";
import { spaceMedium, spaceSmall } from "../../../styles/space";
import { TurbineFeature } from "../../../types/feature";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import {
  formatElectricalLossToGWhDecimal,
  formatElectricalLossToPercentDecimal,
} from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { getAnalysisProgress } from "analysis/output";
import useBooleanState from "hooks/useBooleanState";
import { ToggleSize } from "components/General/Toggle";
import Toggle from "components/General/Toggle";
import { Text } from "styles/typography";

const Disabled = () => (
  <p style={{ color: colors.primaryDisabled }}>Disabled</p>
);

const Loss = () => {
  const { park, triggerId, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const turbines: TurbineFeature[] = useAtomValue(
    turbinesInParkFamily({ parkId, branchId }),
  );

  const [viewLossesInGWh, toggleViewLossesInGWh] = useBooleanState(false);

  const totalInterArrayLoss = useAtomValue(
    loadable(getTotalInterArrayLoss(triggerId)),
  );
  const totalInterArrayLossGWh = useAtomValue(
    loadable(getTotalInterArrayLossGWh(triggerId)),
  );

  const totalExportSystemLoss = useAtomValue(
    loadable(getTotalExportSystemLoss(triggerId)),
  );
  const totalExportSystemLossGWh = useAtomValue(
    loadable(getTotalExportSystemLossGWh(triggerId)),
  );

  const interArrayLossesToUse = viewLossesInGWh
    ? totalInterArrayLossGWh
    : totalInterArrayLoss;
  const exportSystemLossesToUse = viewLossesInGWh
    ? totalExportSystemLossGWh
    : totalExportSystemLoss;
  const formatFunction = viewLossesInGWh
    ? formatElectricalLossToGWhDecimal
    : formatElectricalLossToPercentDecimal;

  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  const configuration = useAtomValue(getConfiguration(triggerId));

  const showInterArrayLoss =
    (configuration?.electrical.interArrayCableLoss ?? false) ||
    (configuration?.electrical.turbineTrafoLoss ?? false);

  const showExportSystemLoss =
    configuration?.electrical.exportSystemLoss ?? false;

  if (turbines.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  return (
    <>
      <Row
        alignCenter
        style={{
          marginLeft: "auto",
          marginTop: spaceSmall,
          marginRight: spaceSmall,
        }}
      >
        <label>Show GWh</label>
        <Toggle
          size={ToggleSize.TINY}
          checked={viewLossesInGWh}
          onChange={toggleViewLossesInGWh}
        />
      </Row>
      <FlexGrid2
        style={{
          padding: spaceMedium,
        }}
      >
        <Row>
          <h4>Total inter array loss</h4>
          {showInterArrayLoss ? (
            orTextLoader(
              interArrayLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <h4>{formatFunction(ia?.totalInterArrayLoss ?? 0)}</h4>,
            )
          ) : (
            <Disabled />
          )}
        </Row>
        <Row>
          <Text style={{ fontWeight: "normal" }}>Inter array cable loss</Text>
          {showInterArrayLoss ? (
            orTextLoader(
              interArrayLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => (
                <p>{formatFunction(ia?.totalInterArrayCableLoss ?? 0)}</p>
              ),
            )
          ) : (
            <Disabled />
          )}
        </Row>
        <Row>
          <Text style={{ fontWeight: "normal" }}>Turbine transformer loss</Text>
          {showInterArrayLoss ? (
            orTextLoader(
              interArrayLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <p>{formatFunction(ia?.totalTurbineTrafoLoss ?? 0)}</p>,
            )
          ) : (
            <Disabled />
          )}
        </Row>

        <Row>
          <h4>Total export system loss</h4>
          {showExportSystemLoss ? (
            orTextLoader(
              exportSystemLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <h4>{formatFunction(ia?.totalExportSystemLoss ?? 0)}</h4>,
            )
          ) : (
            <Disabled />
          )}
        </Row>
        <Row>
          <Text style={{ fontWeight: "normal" }}>Export cable loss</Text>
          {showExportSystemLoss ? (
            orTextLoader(
              exportSystemLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <p>{formatFunction(ia?.totalExportCableLoss ?? 0)}</p>,
            )
          ) : (
            <Disabled />
          )}
        </Row>
        <Row>
          <Text style={{ fontWeight: "normal" }}>
            Offshore transformer loss
          </Text>
          {showExportSystemLoss ? (
            orTextLoader(
              exportSystemLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <p>{formatFunction(ia?.totalOffshoreTrafoLoss ?? 0)}</p>,
            )
          ) : (
            <Disabled />
          )}
        </Row>
        <Row>
          <Text style={{ fontWeight: "normal" }}>Onshore transformer loss</Text>
          {showExportSystemLoss ? (
            orTextLoader(
              exportSystemLossesToUse,
              undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ??
                "",
              (ia) => <p>{formatFunction(ia?.totalOnshoreTrafoLoss ?? 0)}</p>,
            )
          ) : (
            <Disabled />
          )}
        </Row>
      </FlexGrid2>
    </>
  );
};

export const ElectricalLossWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Electrical losses"
      id="Electrical losses"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <Loss />
    </SafeCard>
  );
};
