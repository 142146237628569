import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { mapAtom } from "state/map";

export const useMapboxTerrainDemTileUrl = () => {
  const map = useAtomValue(mapAtom);
  return useCallback(
    ({ x, y, z }: { x: number; y: number; z: number }) => {
      if (!map) return;
      return (map as any)._requestManager.normalizeTileURL(
        `mapbox://raster/mapbox.mapbox-terrain-dem-v1/${z}/${x}/${y}.webp`,
      );
    },
    [map],
  );
};
