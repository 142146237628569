import {
  ABLY_INVALIDATE_NODE,
  ABLY_MOVE_NODE,
  ABLY_REMOVE_NODE,
} from "../../../../../state/ably";
import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../hooks/useAblyGeneric";
import { useOrganisationNodeCrud } from "../../../../Projects/useOrganisationFolderCrud";
import {
  _InvalidateNode,
  _MoveNode,
  _RemoveNode,
} from "../../../../../services/customerAPI";

export function useAblyNodes(organisationId: string) {
  const {
    moveLocal: moveLocalNode,
    invalidateLocal,
    removeLocal,
  } = useOrganisationNodeCrud();

  const channelName = useMemo(() => `${organisationId}:all`, [organisationId]);

  const onMessageReceivedMove = useCallback(
    (message: InboundMessage) => {
      const moveNode = _MoveNode.parse(message.data.meta);
      moveLocalNode(moveNode);
    },
    [moveLocalNode],
  );

  const onMessageReceivedInvalidate = useCallback(
    (message: InboundMessage) => {
      const invalidateNode = _InvalidateNode.parse(message.data.meta);
      invalidateLocal(invalidateNode);
    },
    [invalidateLocal],
  );

  const onMessageReceivedRemove = useCallback(
    (message: InboundMessage) => {
      const { nodeId } = _RemoveNode.parse(message.data.meta);
      removeLocal(nodeId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () =>
      organisationId
        ? [
            {
              eventName: ABLY_MOVE_NODE,
              onMessageReceived: onMessageReceivedMove,
            },
            {
              eventName: ABLY_INVALIDATE_NODE,
              onMessageReceived: onMessageReceivedInvalidate,
            },
            {
              eventName: ABLY_REMOVE_NODE,
              onMessageReceived: onMessageReceivedRemove,
            },
          ]
        : [],
    [
      onMessageReceivedMove,
      onMessageReceivedInvalidate,
      onMessageReceivedRemove,
      organisationId,
    ],
  );

  useAblyGeneric(channelName, events, organisationId);
}
