import Spinner from "@icons/spinner/Spinner";
import Dropdown from "components/Dropdown/Dropdown";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { ShowWRGFileInMap } from "components/UploadModal/components/NewUploadTab/UploadWindData/PreviewWRG";
import debounce from "debounce-promise";
import {
  fetchCustomCalibrationFile,
  isMeanSpeedGrid,
  isWRG,
} from "functions/met";
import { useAtom, useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import SpeedGrid from "layers/speedGrid";
import React, { useEffect, useMemo, useState } from "react";
import { uploadedWindDataFamily } from "state/jotai/windStatistics";
import { projectIdAtom } from "state/pathParams";
import { MeanSpeedGrid, WRG } from "state/windStatistics";
import { WindDataSource } from "types/metocean";
import WindPoints from "../../layers/windMeasurements";
import {
  mapAtom,
  measureWindPointAtom,
  windPointHeightAtom,
  windPointsSource,
} from "../../state/map";
import { Grid2, Label } from "../General/Form";
import { InputDimensioned } from "../General/Input";
import { Column } from "../General/Layout";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { MapMouseEvent } from "mapbox-gl";
import { COLORS } from "../../layers/windSpeed";
import styled from "styled-components";

const WindLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const WindLegendSpeedWrapper = styled.div`
  width: 100%;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-size: 0.9rem;
  }
`;

const WindLegend = styled.div`
  width: 100%;
  height: 1rem;
  background: linear-gradient(
    90deg,
    ${COLORS.default.map(([r, g, b]) => `rgb(${r}, ${g}, ${b})`).join(",")}
  );
`;

// Lazy load to prevent plotly being in main.js
const WindStatistics = React.lazy(
  () => import("../WindWaveMeasurementInfoModal/WindStatistics"),
);

export const WindSources = ({ onClose }: { onClose(): void }) => {
  const [measureWindPoint, setMeasureWindPoint] = useAtom(measureWindPointAtom);
  const [selectedSource, setSelectedSource] = useAtom(windPointsSource);
  const [selected, setSelected] = useState<string | WindDataSource>(
    selectedSource,
  );
  const [loading, setIsloading] = useState<boolean>(false);
  const [globalHeight, _setGlobalHeight] = useAtom(windPointHeightAtom);
  const setGlobalHeight = useMemo(
    () => debounce(_setGlobalHeight, 500),
    [_setGlobalHeight],
  );

  const [meanSpeedGrid, setMeanSpeedGrid] = useState<MeanSpeedGrid>();
  const [wrg, setWRG] = useState<WRG>();
  const map = useAtomValue(mapAtom);

  const [samplePoint, setSamplePoint] = useState<number | undefined>();
  useEffect(() => {
    if (!meanSpeedGrid || !map) return;
    const samplePointFunc = (e: MapMouseEvent) => {
      const lng = e.lngLat.lng;
      const lat = e.lngLat.lat;
      const gridXllCorner = meanSpeedGrid.xllcorner;
      const gridYllCorner = meanSpeedGrid.yllcorner;
      const gridDx = meanSpeedGrid.dx;
      const gridDy = meanSpeedGrid.dy;
      const gridRow = Math.floor((lat - gridYllCorner) / gridDy);
      const gridCol = Math.floor((lng - gridXllCorner) / gridDx);

      if (
        gridRow < 0 ||
        gridRow >= meanSpeedGrid.nrows ||
        gridCol < 0 ||
        gridCol >= meanSpeedGrid.ncols
      ) {
        setSamplePoint(undefined);
        return;
      }

      const sample = meanSpeedGrid.grid[gridRow][gridCol];

      if (sample < 0) {
        setSamplePoint(undefined);
        return;
      }
      setSamplePoint(sample);
    };
    map?.on("mousemove", samplePointFunc);
    return () => {
      map?.off("mousemove", samplePointFunc);
    };
  }, [meanSpeedGrid, map]);

  const [minSpeed, maxSpeed] = useMemo(() => {
    if (!meanSpeedGrid) return [undefined, undefined, undefined];
    const meanSpeedPositive = meanSpeedGrid.grid.flat().filter((v) => v > 0);
    const min = Math.min(...meanSpeedPositive);
    const max = Math.max(...meanSpeedPositive);
    return [min.toFixed(2), max.toFixed(2)];
  }, [meanSpeedGrid]);

  const projectId = useAtomValue(projectIdAtom);
  const customWindData = useAtomValue(
    unwrap(uploadedWindDataFamily({ nodeId: projectId ?? "" })),
  );

  useEffect(() => {
    return () => setMeasureWindPoint(undefined);
  }, [setMeasureWindPoint]);

  useEffect(() => {
    if (
      selectedSource !== WindDataSource.CUSTOM ||
      !customWindData ||
      !projectId ||
      !map
    )
      return;
    const windData = customWindData?.find((d) => d.id === selected);
    if (windData?.type === "meanSpeedGrid") {
      setIsloading(true);
      fetchCustomCalibrationFile({ nodeId: projectId, id: windData.id }).then(
        (res) => {
          if (isMeanSpeedGrid(res)) {
            setMeanSpeedGrid(res);
            const buffer = 0.01;
            map.fitBounds([
              res.xllcorner - buffer,
              res.yllcorner - buffer,
              res.xllcorner + res.dx * res.ncols + buffer,
              res.yllcorner + res.dy * res.nrows + buffer,
            ]);
          }
          setIsloading(false);
        },
      );
    } else if (windData?.type === "wrg") {
      setIsloading(true);
      fetchCustomCalibrationFile({ nodeId: projectId, id: windData.id }).then(
        (res) => {
          if (isWRG(res)) {
            setWRG(res);
            const buffer = 0.03;
            const minLon = res.points.reduce(
              (acc, p) => Math.min(acc, p.lon),
              res.lon,
            );
            const maxLon = res.points.reduce(
              (acc, p) => Math.max(acc, p.lon),
              res.lon,
            );
            const minLat = res.points.reduce(
              (acc, p) => Math.min(acc, p.lat),
              res.lat,
            );
            const maxLat = res.points.reduce(
              (acc, p) => Math.max(acc, p.lat),
              res.lat,
            );
            map.fitBounds([
              minLon - buffer,
              minLat - buffer,
              maxLon + buffer,
              maxLat + buffer,
            ]);
          }
          setIsloading(false);
        },
      );
    }
    return () => {
      setMeanSpeedGrid(undefined);
      setWRG(undefined);
    };
  }, [
    selected,
    selectedSource,
    customWindData,
    projectId,
    map,
    setMeasureWindPoint,
  ]);

  const isBuiltin = useMemo(() => {
    if (!selected) return false;
    return Object.values(WindDataSource).includes(selected as WindDataSource);
  }, [selected]);

  const gridItems = useMemo(() => {
    if (!customWindData || customWindData.length === 0) return;
    return customWindData
      .filter((d) => d.type === "meanSpeedGrid")
      .map((i) => (
        <option key={i.id} value={i.id}>
          {i.name}
        </option>
      ));
  }, [customWindData]);

  const wrgItems = useMemo(() => {
    if (!customWindData || customWindData.length === 0) return;
    return customWindData
      .filter((d) => d.type === "wrg")
      .map((i) => (
        <option key={i.id} value={i.id}>
          {i.name}
        </option>
      ));
  }, [customWindData]);

  return (
    <MenuFrame title={"Wind data"} onExit={onClose}>
      <Column style={{ maxHeight: "calc(100vh - 30rem)", overflow: "auto" }}>
        <Label>
          <InputTitle>Source</InputTitle>
          <Dropdown
            small
            id="wind-data-source"
            value={selected}
            onChange={(e: any) => {
              const source = e.target.value;
              setSelected(source);
              if (Object.values(WindDataSource).includes(source)) {
                setSelectedSource(source);
              } else {
                setSelectedSource(WindDataSource.CUSTOM);
              }
            }}
          >
            <option key={"era5"} value={WindDataSource.ERA5}>
              ERA5
            </option>
            <option key={"nora3"} value={WindDataSource.NORA3}>
              NORA3
            </option>
            <option key={"cerra"} value={WindDataSource.CERRA}>
              CERRA
            </option>
            {gridItems && <option disabled={true}>Custom speed grids:</option>}
            {gridItems && gridItems}
            {wrgItems && <option disabled={true}>WRG:</option>}
            {wrgItems && wrgItems}
          </Dropdown>
        </Label>
        {isBuiltin && (
          <Label>
            <InputTitle>Meters above sealevel</InputTitle>
            <InputDimensioned
              style={{ width: "100%" }}
              compact
              value={globalHeight}
              validate={(h) => 10 <= h && h <= 500}
              unit={"m"}
              onChange={(h) => setGlobalHeight(h)}
              validationMessage="Needs to be within 10 - 500 m"
            />
          </Label>
        )}
        {measureWindPoint?.source === selectedSource && (
          <React.Suspense fallback={null}>
            <WindStatistics
              source={selectedSource}
              lon={measureWindPoint.position[0]}
              lat={measureWindPoint.position[1]}
            />
          </React.Suspense>
        )}
        <WindPoints />
        {meanSpeedGrid && <SpeedGrid meanSpeedGrid={meanSpeedGrid} map={map} />}
        {wrg && <ShowWRGFileInMap wrg={wrg} />}
        {isBuiltin && <p>Zoom in to see more data locations</p>}
        {loading && (
          <div style={{ height: "6rem" }}>
            <Spinner />
          </div>
        )}
        {wrg && (
          <Label>
            <InputTitle>WRG content</InputTitle>
            <Grid2>
              <p>Number of points</p>
              <p>{wrg.points.length}</p>
              <p>Number of sectors</p>
              <p>{wrg.points[0].sectors.length}</p>
              <p>Approx. resolution</p>
              <p>
                {Math.round(
                  Math.sqrt(
                    (wrg.points[0].easting - wrg.points[1].easting) ** 2 +
                      (wrg.points[0].northing - wrg.points[1].northing) ** 2,
                  ),
                )}
                m
              </p>
            </Grid2>
          </Label>
        )}
        {meanSpeedGrid && (
          <Label>
            <InputTitle>Grid content</InputTitle>
            <Grid2>
              <p>Height</p>
              <p>{`${meanSpeedGrid.height}m`}</p>
              <p>Dimensions (row,col)</p>
              <p>{`${meanSpeedGrid.nrows}, ${meanSpeedGrid.ncols}`}</p>
              <p>Resolution (dx,dy)</p>
              <p>{`${meanSpeedGrid.dx.toFixed(4)}, ${meanSpeedGrid.dy.toFixed(4)}`}</p>
              <p>Legend</p>
              <WindLegendWrapper>
                <WindLegend />
                <WindLegendSpeedWrapper>
                  <p>{minSpeed} m/s</p>
                  <p>{maxSpeed} m/s</p>
                </WindLegendSpeedWrapper>
              </WindLegendWrapper>

              <p>Sample at mouse position</p>
              <p>{samplePoint ? `${samplePoint.toFixed(2)} m/s` : "N/A"}</p>
            </Grid2>
          </Label>
        )}
      </Column>
    </MenuFrame>
  );
};
