import { RefObject, useEffect } from "react";

export function useScrollSelectedIntoView(
  ref: RefObject<HTMLElement>,
  shouldScroll: boolean,
) {
  useEffect(() => {
    if (shouldScroll && ref.current) {
      // Check if element is in viewport
      const rect = ref.current.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      // Only scroll if not in viewport, and use appropriate scroll behavior
      if (!isInViewport) {
        ref.current.scrollIntoView({
          behavior: "instant",
          block: "center",
        });
      }
    }
  }, [ref, shouldScroll]);
}
