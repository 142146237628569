import React, { useState } from "react";
import SlideShow from "components/SlideShow/SlideShow";
import { ElectricalSteps } from "./steps/ElectricalSteps";
import { FinancialSteps } from "./steps/FinancialSteps";
import { GisSteps } from "./steps/GisSteps";
import { ProjectManagerSteps } from "./steps/ProjectManagerSteps";
import { ScreeningAndBusinessSteps } from "./steps/ScreeningAndBusinessSteps";
import { getYieldSteps } from "./steps/YieldSteps";
import { SliderAnimation } from "components/SlideShow/style";

const debugStates: Array<
  [string, Array<React.ElementType<{ animation: SliderAnimation }>>]
> = [
  ["Electrical", ElectricalSteps],
  ["Financial", FinancialSteps],
  ["Gis", GisSteps],
  ["ProjectManager", ProjectManagerSteps],
  ["ScreeningAndBusiness", ScreeningAndBusinessSteps],
  ["Yield", getYieldSteps(true)],
  [
    "Full screen video",
    [
      () => {
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "70vh",
                width: "70vw",
              }}
            >
              <video
                autoPlay
                muted
                loop
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <source
                  src="https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/vind.ai/hero-video.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>
        );
      },
    ],
  ],
];

const OnboardingSlideShow = () => {
  //Todo: Decide what slideshow the user should see

  // Temporary debug state
  const [chosenSteps, setChosenSteps] = useState<
    Array<React.ElementType<{ animation: SliderAnimation }>>
  >(debugStates[0][1]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "2rem",
          left: "2rem",
          zIndex: 2,
          display: "flex",
          gap: "1rem",
          width: "40rem",
          flexWrap: "wrap",
        }}
      >
        {debugStates.map((state, index) => (
          <button key={index} onClick={() => setChosenSteps(state[1])}>
            {state[0]}
          </button>
        ))}
      </div>
      <SlideShow steps={chosenSteps} bgColor="#CDE4E1" />
    </>
  );
};

export default OnboardingSlideShow;
