import { useEffect } from "react";
import { ArchiveProjectVersionAnalysisId } from "./utils";
import { ParkFeature } from "types/feature";
import { useColumnTemplates } from "components/CompareParksModal/columnTemplates";
import useParkComparisonValues from "components/CompareParksModal/ParkComparisonView/useParkComparisonValues";
import { useSetAtom } from "jotai";
import { analysisOverrideInputFamily } from "analysis/inputs";

function combineDataWithMetadata(data: any, templates: any): any[] {
  return templates.attributes.map((attr: any) => {
    const value = data[attr.key];
    return {
      name: attr.name,
      value: attr.csvFormat && value ? attr.csvFormat(value) : value,
      unit: attr.unit || "",
    };
  });
}

export default function useProductionNumbers(
  projectId: string,
  branchId: string,
  parkId: string,
  park: ParkFeature,
):
  | { status: "loading" }
  | {
      status: "complete";
      data: {
        productionData: any[];
        windData: any[];
        cablingData: any[];
        financialData: any[];
        financialInputData: any[];
        foundationData: any[];
        parkData: any[];
      };
    } {
  const setAnalysisArgs = useSetAtom(
    analysisOverrideInputFamily(ArchiveProjectVersionAnalysisId),
  );

  useEffect(() => {
    setAnalysisArgs(
      Promise.resolve({
        selectedSubAreas: undefined,
        projectId,
        branchId,
        parkId,
      }),
    );
  }, [branchId, parkId, projectId, setAnalysisArgs]);

  const compareData = useParkComparisonValues(
    ArchiveProjectVersionAnalysisId,
    park,
    branchId,
  );
  const columnTemplates = useColumnTemplates();

  if (compareData.PRODUCTION.status !== "complete") {
    return { status: "loading" };
  }

  // Combine the production data with the column templates
  const productionData = combineDataWithMetadata(
    compareData.PRODUCTION,
    columnTemplates.PRODUCTION,
  );
  const windData = combineDataWithMetadata(
    compareData.WIND,
    columnTemplates.WIND,
  );
  const cablingData = combineDataWithMetadata(
    compareData.CABLING,
    columnTemplates.CABLING,
  );
  const financialData = combineDataWithMetadata(
    compareData.FINANCIAL,
    columnTemplates.FINANCIAL,
  );

  const financialInputData = combineDataWithMetadata(
    compareData.FINANCIAL_INPUTS,
    columnTemplates.FINANCIAL_INPUTS,
  );
  const foundationData = combineDataWithMetadata(
    compareData.FOUNDATIONS,
    columnTemplates.FOUNDATIONS,
  );
  const parkData = combineDataWithMetadata(
    compareData.PARK,
    columnTemplates.PARK,
  );

  return {
    status: "complete",
    data: {
      productionData,
      windData,
      cablingData,
      financialData,
      financialInputData,
      foundationData,
      parkData,
    },
  };
}
