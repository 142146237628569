import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { GridWithFourColumns } from "./CustomLossesTab";
import { MenuItem } from "components/General/Menu";
import { DotMenu } from "components/General/MenuButton";
import FreeTextIcon from "@icons/24/FreeText.svg";

const Title = styled.div`
  ${typography.sub3}
  color: ${colors.textSecondary};
`;

const BlueDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.blue500}};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  padding: 0 0 2rem 0;
`;

export const CustomLossesTopRow = ({
  showFreeTextCustomLosses,
  setShowFreeTextCustomLosses,
}: {
  showFreeTextCustomLosses: boolean;
  setShowFreeTextCustomLosses: (showFreeTextCustomLosses: boolean) => void;
}) => {
  return (
    <Wrapper>
      <GridWithFourColumns showFreeText={showFreeTextCustomLosses}>
        <Title>Other losses</Title>
        <Title>Loss</Title>
        {showFreeTextCustomLosses && <Title>Free text</Title>}
        <DotMenu divStyle={{ marginLeft: "auto" }}>
          <MenuItem
            icon={<FreeTextIcon />}
            name={
              showFreeTextCustomLosses ? "Hide free text" : "Show free text"
            }
            onClick={() => {
              setShowFreeTextCustomLosses(!showFreeTextCustomLosses);
            }}
          />
        </DotMenu>
      </GridWithFourColumns>
      <BlueDivider />
    </Wrapper>
  );
};
