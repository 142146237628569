import { useAtom, useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, { useCallback, useMemo, useRef, useState, Suspense } from "react";
import styled from "styled-components";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import DatabaseIcon from "@icons/24/Database.svg";
import LineIcon from "@icons/24/Line.svg";
import { SkeletonText } from "../../../Loading/Skeleton";
import { spacing1, spacing3, spacing8 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";
import {
  dataLayerAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import {
  Column as ReactTableColumn,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
  UseGlobalFiltersOptions,
  UseTableOptions,
  UseSortByOptions,
  UseExpandedOptions,
  UsePaginationOptions,
} from "react-table";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import { Frame, Row } from "components/General/Layout";
import { dateToYearDateTime } from "utils/utils";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { useLayerSettingsCrud } from "components/LayerList/LayerSettings/useLayerSettingsCrud";
import { defaultLayerSettings } from "components/LayerList/LayerSettings/const";
import { libraryLayersAddedToProject } from "components/Organisation/Library/dataLibrary/state";
import { DataLibraryNewEntry } from "components/Organisation/Library/dataLibrary/DataLibraryTab.style";
import ChatFilledIcon from "@icons/24/ChatFilled.svg";
import { Anchor } from "components/General/Anchor";
import useBooleanState from "hooks/useBooleanState";
import { useClickOutside } from "hooks/useClickOutside";
import { isDefined } from "utils/predicates";
import { Mixpanel } from "mixpanel";
import DirectionDownIcon from "@icons/24/DirectionDown.svg?react";
import DirectionUpIcon from "@icons/24/DirectionUp.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import Fallback from "../Fallback";
import CheckCircle from "@icons/14/CheckCircle.svg?react";
import Tooltip from "components/General/Tooltip";
import {
  filteredLibraryDataLayersIdsAtom,
  selectedLayersToAddAtom,
} from "components/LayerList/LayerSettings/state";

const IconWithBackground = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.surfacePrimary};
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path[stroke] {
    stroke: ${colors.white};
  }
  svg path:not([stroke]) {
    fill: ${colors.white};
  }
`;

const HeaderColumn = styled.p`
  ${typography.sub2};
`;

const HiddenIfNotChecked = styled.div`
  display: flex;
  align-items: center;
`;

const Table = styled.table`
  ${HiddenIfNotChecked}:not([data-checked=true]) {
    visibility: hidden;
  }

  tbody {
    tr:hover {
      ${HiddenIfNotChecked} {
        visibility: visible;
      }
    }
  }
`;

const StickyThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
`;

const ExpandArrowWrapper = styled.div<{
  open: boolean;
}>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const DescriptionIconButton = ({
  description,
}: {
  description?: string | null;
}) => {
  const [isOpen, toggleIsOpen, setIsOpen] = useBooleanState(false);
  const editDescriptionBtnRef = useRef<HTMLButtonElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  useClickOutside(
    frameRef,
    () => setIsOpen(false),
    (target) => target === editDescriptionBtnRef.current,
  );

  if (!description) {
    return null;
  }

  return (
    <>
      <IconBtn
        ref={editDescriptionBtnRef}
        size="1.4rem"
        onClick={(e) => {
          e.stopPropagation();
          toggleIsOpen();
        }}
        iconColor={colors.indigo500}
        backgroundColor={"transparent"}
      >
        <ChatFilledIcon />
      </IconBtn>
      {isOpen && (
        <Anchor baseRef={editDescriptionBtnRef} floatPlace={"topRight"}>
          <Frame ref={frameRef} onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {description}
            </p>
          </Frame>
        </Anchor>
      )}
    </>
  );
};

const LayerRow = styled.tr`
  td:first-child {
    padding-left: 4rem;
  }
  /* Apply rounded corners to first and last cells */
  td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const columns: Array<ReactTableColumn<SearchableDataLibraryPackage>> = [
  {
    id: "name",
    width: "40%",
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    // @ts-ignore
    canSort: true,
    Header: () => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
            position: "relative",
          }}
        >
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({
      row,
      getPackageSelectionStatus,
      getPackageIsAddedToProject,
      onTogglePackageSelected,
    }) => {
      const isSelected = getPackageSelectionStatus(row.original.id);
      const [isHovered, setIsHovered] = useState(false);
      const isAdded = getPackageIsAddedToProject(row.original.id);

      return (
        <Row
          alignCenter
          style={{
            display: "flex",
            paddingLeft: spacing8,
            position: "relative",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ExpandArrowWrapper open={row.isExpanded}>
            <ChevronDownIcon />
          </ExpandArrowWrapper>

          <div
            key={row.original.id}
            style={{
              display: "flex",
              height: "3.6rem",
              width: "3.6rem",
              borderRadius: "4px",
              backgroundColor: colors.surfaceSubtle,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
              !isAdded && onTogglePackageSelected(row.original.id);
            }}
          >
            {isSelected || isAdded ? (
              <Tooltip text="Layer already added" disabled={!isAdded}>
                <IconWithBackground
                  backgroundColor={colors.surfaceSelectedDark}
                >
                  {" "}
                  <IconREMSize height={0.8} width={0.8}>
                    <CheckCircle />
                  </IconREMSize>
                </IconWithBackground>
              </Tooltip>
            ) : isHovered ? (
              <IconWithBackground backgroundColor={colors.surfaceButtonPrimary}>
                <IconREMSize height={0.8} width={0.8}>
                  <AddIcon />
                </IconREMSize>
              </IconWithBackground>
            ) : (
              <IconREMSize height={1.6} width={1.6}>
                <DatabaseIcon />
              </IconREMSize>
            )}
          </div>

          <p>{row.original.name}</p>
        </Row>
      );
    },
  },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    disableFilters: true,
    sortType: (a, b) => {
      return a.original.created_at - b.original.created_at;
    },
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,
    Cell: ({ row }) => (
      <p>{dateToYearDateTime(new Date(row.original.created_at))}</p>
    ),
    width: 100,
  },
  {
    id: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    Cell: ({ row }) => {
      return (
        <Row alignCenter>
          <UserInfoOrganisation userId={row.original.author} />
        </Row>
      );
    },
  },
  {
    id: "description",
    // @ts-ignore
    disableFilters: false,
    Header: <HeaderColumn>Description</HeaderColumn>,
    Cell: ({ row }) => {
      return (
        <Row alignCenter>
          <DescriptionIconButton description={row.original.description} />
        </Row>
      );
    },
  },
];

type SearchableDataLibraryPackage = DataLibraryPackage & {
  layerNames: string[];
};

// Combine all the option types we need
type CombinedTableOptions<D extends object> = UseTableOptions<D> &
  UseGlobalFiltersOptions<D> &
  UseSortByOptions<D> &
  UseExpandedOptions<D> &
  UsePaginationOptions<D>;

// Extend the combined options with our custom properties
interface SearchableDataLibraryPackageTableOptions
  extends CombinedTableOptions<SearchableDataLibraryPackage> {
  initialState: {
    pageSize: number;
    pageIndex: number;
    sortBy: Array<{
      id: string;
      desc: boolean;
    }>;
    globalFilter: string;
  };
  globalFilter: UseGlobalFiltersOptions<SearchableDataLibraryPackage>["globalFilter"];
  autoResetPage: boolean;
  autoResetExpanded: boolean;
  autoResetSortBy: boolean;
}

interface LibraryDataLayersModalProps {
  searchString: string;
}

const LibraryDataLayersModal = ({
  searchString,
}: LibraryDataLayersModalProps) => {
  return (
    <ScrollBody
      style={{
        gap: 0,
      }}
    >
      <Suspense fallback={<Fallback />}>
        <LibraryDataLayersModalInner searchString={searchString} />
      </Suspense>
    </ScrollBody>
  );
};

interface LibraryDataLayersModalInnerProps {
  searchString: string;
}

const LibraryDataLayersModalInner = ({
  searchString,
}: LibraryDataLayersModalInnerProps) => {
  const projectId = useAtomValue(projectIdAtom);
  const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
    selectedLayersToAddAtom,
  );
  const [, setFilteredDataPackageLayersIds] = useAtom(
    filteredLibraryDataLayersIdsAtom,
  );
  const dataPackagesAvailableRaw = useAtomValue(
    dataPackageResourceWithAccessOnNodeState({
      nodeId: projectId,
    }),
  );
  const dataLayersAvailable = useAtomValue(
    dataLayerAccessOnNodeState({
      nodeId: projectId,
    }),
  );
  const dataPackagesAvailable = useMemo<SearchableDataLibraryPackage[]>(
    () =>
      dataPackagesAvailableRaw
        .map((p) => p.config)
        .map((f) => ({
          ...f,
          layerNames: f.layerIds
            .map((layerId) => dataLayersAvailable.find((d) => d.id === layerId))
            .filter(isDefined)
            .map((layer) => layer.name),
        })),
    [dataLayersAvailable, dataPackagesAvailableRaw],
  );

  const { put: putLayerSettings } = useLayerSettingsCrud();
  const addedDataLayers = useAtomValue(
    libraryLayersAddedToProject({
      projectId,
    }),
  );

  const addLibraryLayersToProject = useCallback(
    async (layerIds: string[]) => {
      await putLayerSettings(layerIds.map(defaultLayerSettings));
    },
    [putLayerSettings],
  );

  const onAddLibraryPackageToProject = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (pkg) {
        return addLibraryLayersToProject(pkg.layerIds);
      }
    },
    [addLibraryLayersToProject, dataPackagesAvailable],
  );

  const allIsSelected =
    selectedLayersToAdd.length >= dataLayersAvailable.length;
  const someIsSelected = selectedLayersToAdd.length > 0;

  const getPackageSelectionStatus = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (!pkg || pkg.layerIds.length === 0) {
        return false;
      }

      const allIsSelected = pkg.layerIds.every((layerId) =>
        selectedLayersToAdd.includes(layerId),
      );

      return allIsSelected;
    },
    [dataPackagesAvailable, selectedLayersToAdd],
  );

  const getLayerIsAddedToProject = useCallback(
    (layerId: string) => addedDataLayers.some((l) => l.id === layerId),
    [addedDataLayers],
  );

  const onTogglePackageSelected = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (!pkg) {
        return;
      }

      const layerIds = pkg.layerIds;
      setSelectedLayersToAdd((curr) => {
        if (layerIds.every((layerId) => curr.includes(layerId))) {
          return curr.filter((id) => !layerIds.includes(id));
        } else {
          return [...curr, ...layerIds];
        }
      });
    },
    [dataPackagesAvailable, setSelectedLayersToAdd],
  );

  const onToggleLayerSelected = useCallback(
    (layerId: string) => {
      setSelectedLayersToAdd((curr) => {
        if (curr.includes(layerId)) {
          return curr.filter((id) => id !== layerId);
        } else {
          return [...curr, layerId];
        }
      });
    },
    [setSelectedLayersToAdd],
  );

  const getPackageIsAddedToProject = useCallback(
    (packageId: string) => {
      const pkg = dataPackagesAvailable.find((p) => p.id === packageId);
      if (!pkg) return false;
      return pkg.layerIds.every((layerId) => getLayerIsAddedToProject(layerId));
    },
    [dataPackagesAvailable, getLayerIsAddedToProject],
  );

  const globalFilterFunc = useMemo<
    UseGlobalFiltersOptions<SearchableDataLibraryPackage>["globalFilter"]
  >(
    () => (rows, _indexes, searchValue) => {
      return rows.filter((row) => {
        return (
          row.original.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          row.original.layerNames.some((name) =>
            name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        );
      });
    },
    [],
  );

  const tableOptions: SearchableDataLibraryPackageTableOptions = {
    columns,
    data: dataPackagesAvailable,
    globalFilter: globalFilterFunc,
    autoResetPage: false,
    autoResetExpanded: false,
    autoResetSortBy: false,
    initialState: {
      pageSize: 1000,
      pageIndex: 0,
      sortBy: [
        {
          id: "created_at",
          desc: true,
        },
      ],
      globalFilter: searchString,
    },
  };

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable<SearchableDataLibraryPackage>(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  // Update global filter when searchString changes
  React.useEffect(() => {
    setGlobalFilter(searchString);
    const filteredLayers = dataLayersAvailable.filter((layer) =>
      layer.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    setFilteredDataPackageLayersIds(filteredLayers.map((layer) => layer.id));
  }, [
    searchString,
    setGlobalFilter,
    dataLayersAvailable,
    setFilteredDataPackageLayersIds,
  ]);

  const globalFilterValue = (
    "globalFilter" in state ? state.globalFilter : ""
  ) as string;

  const [hoveredLayerId, setHoveredLayerId] = useState<string | null>(null);

  return (
    <React.Suspense fallback={<Fallback />}>
      {dataPackagesAvailable.length === 0 ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <DataLibraryNewEntry
              icon={<DatabaseIcon />}
              text="Your project doesn't have any Library packages"
              title="No library packages"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: `${spacing8}`,
          }}
        >
          <Table
            {...getTableProps({
              style: {
                width: "100%",
                borderSpacing: 0,
              },
            })}
          >
            <StickyThead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          onClick: (e) => {
                            if (
                              !column.canSort ||
                              e.nativeEvent
                                .composedPath()
                                .find(
                                  (e) =>
                                    e instanceof HTMLElement &&
                                    e.dataset.type === "checkbox",
                                )
                            ) {
                              return;
                            }

                            Mixpanel.track_old(
                              "Sort library layers in Layers modal",
                              {
                                id: column.id,
                                desc: !column.isSortedDesc,
                              },
                            );
                            column.toggleSortBy(!column.isSortedDesc);
                          },
                          style: {
                            textAlign: "unset",
                            width: column.width,
                            padding: "0.5rem 0",
                            borderBottom: `1px solid ${colors.blue400}`,
                          },
                        }),
                      )}
                    >
                      <Row alignCenter>
                        {column.render("Header", {
                          allIsSelected,
                          someIsSelected,
                          getLayerIsAddedToProject,
                        })}
                        {column.canSort && (
                          <span
                            style={{
                              marginLeft: "0.3rem",
                            }}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <DirectionDownIcon height={14} width={14} />
                              ) : (
                                <DirectionUpIcon height={14} width={14} />
                              )
                            ) : (
                              <DirectionDownIcon height={14} width={14} />
                            )}
                          </span>
                        )}
                      </Row>
                    </th>
                  ))}
                </tr>
              ))}
            </StickyThead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const selectionStatus = getPackageSelectionStatus(
                  row.original.id,
                );

                return (
                  <React.Fragment key={row.original.id}>
                    <tr
                      {...row.getRowProps({
                        style: {
                          cursor: "pointer",
                          borderSpacing: 0,
                          backgroundColor:
                            selectionStatus === true
                              ? colors.blue100
                              : undefined,
                        },
                      })}
                      onClick={() => row.toggleRowExpanded()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            {...cell.getCellProps({
                              style: {
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                padding: `${spacing3} 0`,
                              },
                            })}
                          >
                            <React.Suspense fallback={<SkeletonText />}>
                              {cell.render("Cell", {
                                onAddLibraryPackageToProject,
                                getPackageSelectionStatus,
                                getPackageIsAddedToProject,
                                onTogglePackageSelected,
                              })}
                            </React.Suspense>
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded &&
                      row.original.layerIds.map((layerId) => {
                        const layer = dataLayersAvailable.find(
                          (l) => l.id === layerId,
                        );

                        if (!layer) {
                          return null;
                        }

                        if (
                          globalFilterValue &&
                          !layer.name
                            .toLowerCase()
                            .includes(globalFilterValue.toLowerCase())
                        ) {
                          return null;
                        }

                        const isSelected =
                          selectedLayersToAdd.includes(layerId);
                        const isAdded = getLayerIsAddedToProject(layerId);
                        return (
                          <LayerRow
                            key={layerId}
                            style={{
                              cursor: "pointer",
                              backgroundColor: isSelected
                                ? colors.blue200
                                : undefined,
                            }}
                          >
                            <td
                              style={{
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                padding: `${spacing3} 0 ${spacing3} 8rem`,
                                position: "relative",
                              }}
                            >
                              <Row alignCenter>
                                <div
                                  key={layerId}
                                  style={{
                                    display: "flex",
                                    height: "3.6rem",
                                    width: "3.6rem",
                                    borderRadius: "4px",
                                    backgroundColor: colors.surfaceSubtle,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={() => {
                                    !isAdded && onToggleLayerSelected(layerId);
                                  }}
                                  onMouseEnter={() =>
                                    setHoveredLayerId(layerId)
                                  }
                                  onMouseLeave={() => setHoveredLayerId(null)}
                                >
                                  {isAdded || isSelected ? (
                                    <Tooltip
                                      text="Layer already added"
                                      disabled={!isAdded}
                                    >
                                      <IconWithBackground
                                        backgroundColor={
                                          colors.surfaceSelectedDark
                                        }
                                      >
                                        {" "}
                                        <IconREMSize height={0.8} width={0.8}>
                                          <CheckCircle />
                                        </IconREMSize>
                                      </IconWithBackground>
                                    </Tooltip>
                                  ) : hoveredLayerId === layerId ? (
                                    <IconWithBackground
                                      backgroundColor={
                                        colors.surfaceButtonPrimary
                                      }
                                    >
                                      <IconREMSize height={0.8} width={0.8}>
                                        <AddIcon />
                                      </IconREMSize>
                                    </IconWithBackground>
                                  ) : (
                                    <IconREMSize height={1.6} width={1.6}>
                                      <LineIcon />
                                    </IconREMSize>
                                  )}
                                </div>
                                <p>{layer.name}</p>
                              </Row>
                            </td>
                            {layer.created_at && (
                              <td style={{ padding: `${spacing3} 0` }}>
                                <p>
                                  {dateToYearDateTime(
                                    new Date(layer.created_at),
                                  )}
                                </p>
                              </td>
                            )}
                            {layer.author && (
                              <td style={{ padding: `${spacing3} 0` }}>
                                <Row alignCenter>
                                  <UserInfoOrganisation userId={layer.author} />
                                </Row>
                              </td>
                            )}
                            <td style={{ padding: `${spacing3} 0` }}>
                              <DescriptionIconButton
                                description={layer.description}
                              />
                            </td>
                          </LayerRow>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </React.Suspense>
  );
};

export default LibraryDataLayersModal;
