import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import {
  CableFeature,
  ExportCableFeature,
  SubstationFeature,
} from "../../types/feature";
import { exportCableErrorKilometerPerVoltage } from "@constants/production";
import * as turf from "@turf/turf";
import {
  CableType,
  ExportCableVoltageType,
} from "../../services/cableTypeService";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { exportCablesInParkWithTypeFamily } from "state/jotai/exportCable";
import { SubstationType } from "services/substationService";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { cableLoadsFamily, cablesInParkFamily } from "state/jotai/cable";
import { ValidationWarning } from "state/validationWarnings";
import { sum } from "utils/utils";

//coeffs relating length of HVAC export cable to minimum power going into the cable [a + b * length + c * length**2]. Found from quadratic fit of non-converging cases from extensive test case matrix.
const MIN_POWER_MW_LENGTH: Record<
  ExportCableVoltageType,
  number[] | undefined
> = {
  [ExportCableVoltageType.kV110]: [10, 0.1, 0.0025],
  [ExportCableVoltageType.kV150]: [30, -0.2, 0.008],
  [ExportCableVoltageType.kV220]: [20, 0.9, 0.008],
  [ExportCableVoltageType.kV275]: [20, 1.9, 0.008],
  [ExportCableVoltageType.kV320]: undefined,
  [ExportCableVoltageType.kV345]: [20, 3.2, 0.01],
  [ExportCableVoltageType.kV380]: [20, 4, 0.01],
  [ExportCableVoltageType.kV420]: [25, 5.2, 0.01],
  [ExportCableVoltageType.kV525]: undefined,
};

//coeffs relating length of HVAC export cable to maximum power going into the cable [a + b * length + c * length**2]. Found from quadratic fit of non-converging cases from extensive test case matrix.
const MAX_POWER_MW_LENGTH: Record<
  ExportCableVoltageType,
  number[] | undefined
> = {
  [ExportCableVoltageType.kV110]: [2850, -33, 0.11],
  [ExportCableVoltageType.kV150]: [5700, -66, 0.2],
  [ExportCableVoltageType.kV220]: undefined,
  [ExportCableVoltageType.kV275]: undefined,
  [ExportCableVoltageType.kV320]: undefined,
  [ExportCableVoltageType.kV345]: undefined,
  [ExportCableVoltageType.kV380]: undefined,
  [ExportCableVoltageType.kV420]: undefined,
  [ExportCableVoltageType.kV525]: undefined,
};

function calcMinPowerMWForExportCableLength(
  exportCableVoltage: ExportCableVoltageType,
  length: number,
): number {
  const coefficients = MIN_POWER_MW_LENGTH[exportCableVoltage];
  if (!coefficients) return 0;

  const [a, b, c] = coefficients;
  return a + b * length + c * Math.pow(length, 2);
}

function calcMaxPowerMWForExportCableLength(
  exportCableVoltage: ExportCableVoltageType,
  length: number,
): number {
  const coefficients = MAX_POWER_MW_LENGTH[exportCableVoltage];
  if (!coefficients) return Infinity;

  const [a, b, c] = coefficients;
  return a + b * length + c * Math.pow(length, 2);
}

export function checkExportCablesTooLongToConverge(
  cables: CableFeature[],
  exportCables: [ExportCableFeature, CableType, CableType][],
  substations: [SubstationFeature, SubstationType][],
  iaLoads: Map<string, number>,
  exportOverrideType?: CableType,
): ExportCableFeature[] {
  const errorExportCables = [];

  const offshoreSubstations = substations.filter(
    ([_, subType]) => subType.type === "offshore",
  );

  for (const [cable, _off] of exportCables) {
    const off = exportOverrideType ?? _off;
    const cableVoltage: ExportCableVoltageType = off.voltage;
    const cableLength = turf.length(cable, { units: "kilometers" });

    if (cableLength >= exportCableErrorKilometerPerVoltage[cableVoltage])
      continue;

    const offshoreSub = offshoreSubstations.find(
      ([s]) =>
        s.id === cable.properties.fromSubstationId ||
        s.id === cable.properties.toSubstationId,
    );

    if (!offshoreSub) continue;

    const numCablesAtSub = exportCables.filter(
      ([c]) =>
        offshoreSub[0].id === c.properties.fromSubstationId ||
        offshoreSub[0].id === c.properties.toSubstationId,
    ).length;

    if (numCablesAtSub === 0) continue;

    const powerCableStrings = cables
      .filter(
        (c) =>
          c.properties.fromId === offshoreSub[0].id ||
          c.properties.toId === offshoreSub[0].id,
      )
      .map((c) => (iaLoads.get(c.id) ?? 0) / 1e6); // W to MW

    const totalSubstationPower = sum(powerCableStrings);

    const powerInCable = totalSubstationPower / numCablesAtSub;

    const minPowerInCable = calcMinPowerMWForExportCableLength(
      cableVoltage,
      cableLength,
    );

    const maxPowerInCable = calcMaxPowerMWForExportCableLength(
      cableVoltage,
      cableLength,
    );

    if (powerInCable < minPowerInCable || powerInCable > maxPowerInCable)
      errorExportCables.push(cable);
  }

  return errorExportCables;
}

export const exportSystemWillNotConvergeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExportSystemWillNotConverge>
        | undefined
      >
    >(async (get) => {
      const cables = await get(
        cablesInParkFamily({
          parkId,
          branchId,
        }),
      );
      const exportCables = await get(
        exportCablesInParkWithTypeFamily({
          parkId,
          branchId,
        }),
      );
      const substations = await get(
        substationsInParkWithTypeFamily({ parkId, branchId }),
      );
      const iaLoads = await get(
        cableLoadsFamily({ parkId, branchId, turbineTypeOverride: undefined }),
      );

      const errorExportCables = checkExportCablesTooLongToConverge(
        cables,
        exportCables,
        substations,
        iaLoads,
      );

      if (errorExportCables.length === 0) return;
      return {
        type: ValidationWarningTypes.ExportSystemWillNotConverge,
        featureIds: errorExportCables.map((c) => c.id),
        parkId: parkId,
      };
    }),
);
