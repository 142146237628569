import {
  DetailedJacketType,
  FoundationType,
  SoilTypesEnum,
} from "../../../../../types/foundations";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Grid } from "../../style";
import Dropdown from "components/Dropdown/Dropdown";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/SettingsV2/Shared/styles";
import {
  TitleContainer,
  HorizontalLine,
  ColumnSection,
} from "components/SettingsV2/Shared/styles";
import { between } from "utils/validations";
import { kgToTonne } from "../../FoundationSettings";
import CommonReferenceNumbers, {
  ReferenceNumbersTitle,
} from "../ReferenceNumbers/CommonReferenceNumbers";
import ReferenceFiftyYearHs from "../ReferenceNumbers/ReferenceFiftyYearHs";
import ReferenceWaterDepth from "../ReferenceNumbers/ReferenceWaterDepth";

interface DetailedJacketSettingsProps {
  originalFoundation?: DetailedJacketType;
  tempFoundation: DetailedJacketType;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  disabled: boolean;
  nodeId: string;
}

const DetailedJacketSettings = ({
  originalFoundation,
  tempFoundation,
  setTempFoundation,
  disabled,
  nodeId,
}: DetailedJacketSettingsProps) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const commentCategory =
    nodeId === organisationId ? "org_foundation_manage" : "project";

  return (
    <>
      <ColumnSection>
        <TitleContainer>
          <Subtitle>Geometry</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <Grid>
          <Label>
            <SecondaryText>Leg diameter</SecondaryText>
            <InputDimensioned
              validate={between(0.5, 5)}
              decimals={1}
              validationMessage={`Needs to be within 0.5 - 5 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "legDiameter",
                commentCategory,
              )}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.legDiameter}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  legDiameter: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Leg thickness</SecondaryText>
            <InputDimensioned
              validate={between(0.01, 0.5)}
              decimals={3}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "legThickness",
                commentCategory,
              )}
              step="0.01"
              unit="m"
              type="number"
              value={tempFoundation.legThickness}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  legThickness: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Brace diameter</SecondaryText>
            <InputDimensioned
              validate={between(0.1, 5)}
              decimals={1}
              validationMessage={`Needs to be within 0.1 -5 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "braceDiameter",
                commentCategory,
              )}
              step="1"
              unit="m"
              type="number"
              value={tempFoundation.braceDiameter}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  braceDiameter: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Brace thickness</SecondaryText>
            <InputDimensioned
              validate={between(0.01, 0.5)}
              decimals={3}
              validationMessage={`Needs to be within 0.01 - 0.5 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "braceThickness",
                commentCategory,
              )}
              step="0.01"
              unit="m"
              type="number"
              value={tempFoundation.braceThickness}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  braceThickness: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Leg distance, top</SecondaryText>
            <InputDimensioned
              validate={between(5, 30)}
              decimals={1}
              validationMessage={`Needs to be within 5 - 30 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "Ltop",
                commentCategory,
              )}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.Ltop}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  Ltop: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Leg distance, bottom</SecondaryText>
            <InputDimensioned
              validate={between(10, 50)}
              decimals={1}
              validationMessage={`Needs to be within 10 - 50 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "Lbottom",
                commentCategory,
              )}
              step="0.1"
              unit="m"
              type="number"
              value={tempFoundation.Lbottom}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  Lbottom: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Jacket height</SecondaryText>
            <InputDimensioned
              validate={between(20, 150)}
              decimals={1}
              validationMessage={`Needs to be within 20 - 150 m`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "jacketHeight",
                commentCategory,
              )}
              step="1"
              unit="m"
              type="number"
              value={tempFoundation.jacketHeight}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  jacketHeight: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Number of legs</SecondaryText>
            <Dropdown
              id="foundation-num-legs"
              disabled={disabled}
              value={tempFoundation.numLegs}
              onChange={(e) => {
                const numLegs = parseInt(e.target.value);
                setTempFoundation((curr) => ({
                  ...curr,
                  numLegs,
                }));
              }}
            >
              <option value={3}>3</option>
              <option value={4}>4</option>
            </Dropdown>
          </Label>
          <Label>
            <SecondaryText>Number of bays</SecondaryText>
            <Dropdown
              id="foundation-num-bays"
              disabled={disabled}
              value={tempFoundation.numBays}
              onChange={(e) => {
                const numBays = parseInt(e.target.value);
                setTempFoundation({
                  ...tempFoundation,
                  numBays,
                });
              }}
            >
              <option value={3}>{"3"}</option>
              <option value={4}>{"4"}</option>
              <option value={5}>{"5"}</option>
              <option value={6}>{"6"}</option>
            </Dropdown>
          </Label>
          <Label>
            <SecondaryText>Pile weight (single pile)</SecondaryText>
            <InputDimensioned
              validate={between(1, 500)}
              decimals={0}
              scaleFactor={kgToTonne}
              validationMessage={`Needs to be within 1 - 500 tonnes`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "pileMass",
                commentCategory,
              )}
              step="1"
              unit="tonnes"
              type="number"
              value={tempFoundation.pileMass}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  pileMass: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>TP weight</SecondaryText>
            <InputDimensioned
              validate={between(50, 1000)}
              decimals={0}
              scaleFactor={kgToTonne}
              validationMessage={`Needs to be within 50 - 750 tonnes`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "tpMass",
                commentCategory,
              )}
              step="1"
              unit="tonnes"
              type="number"
              value={tempFoundation.tpMass}
              onChange={(val) => {
                setTempFoundation({
                  ...tempFoundation,
                  tpMass: val,
                });
              }}
              disabled={disabled}
              style={{
                width: "20rem",
              }}
            />
          </Label>
        </Grid>
      </ColumnSection>
      <ColumnSection>
        <ReferenceNumbersTitle />
        <Grid>
          <CommonReferenceNumbers
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <ReferenceWaterDepth
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <ReferenceFiftyYearHs
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
          <Label>
            <SecondaryText>Reference soil type</SecondaryText>
            <Dropdown
              id="foundation-soil-type"
              disabled={disabled}
              value={tempFoundation.soilType}
              onChange={(e) => {
                setTempFoundation((curr) => ({
                  ...curr,
                  soilType: e.target.value as SoilTypesEnum,
                }));
              }}
            >
              <option value={SoilTypesEnum.Sand}>{"Sand"}</option>
              <option value={SoilTypesEnum.Clay}>{"Clay"}</option>
            </Dropdown>
          </Label>
          <Label>
            <SecondaryText>Reference unit weight</SecondaryText>
            <InputDimensioned
              disabled={disabled}
              scaleFactor={1e-3}
              validate={between(5, 30)}
              validationMessage={`Needs to be within 5 - 30 kN/m3`}
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "unitWeight",
                commentCategory,
              )}
              step="0.1"
              unit="kN/m3"
              type="number"
              decimals={1}
              value={tempFoundation.unitWeight}
              initialValue={
                originalFoundation ? originalFoundation.unitWeight : undefined
              }
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  unitWeight: d,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          {tempFoundation.soilType === SoilTypesEnum.Sand && (
            <Label>
              <SecondaryText>Reference soil-pile friction angle</SecondaryText>
              <InputDimensioned
                disabled={disabled}
                scaleFactor={180 / Math.PI}
                validate={between(10, 40)}
                validationMessage={`Needs to be within 10 - 40 deg`}
                changelogInfo={foundationChangelogInfo(
                  tempFoundation.id,
                  nodeId,
                  "frictionAngle",
                  commentCategory,
                )}
                step="0.1"
                unit="deg"
                type="number"
                decimals={1}
                value={tempFoundation.frictionAngle}
                initialValue={
                  originalFoundation
                    ? originalFoundation.frictionAngle
                    : undefined
                }
                onChange={(d) => {
                  setTempFoundation({
                    ...tempFoundation,
                    frictionAngle: d,
                  });
                }}
                style={{
                  width: "20rem",
                }}
              />
            </Label>
          )}
          {tempFoundation.soilType === SoilTypesEnum.Clay && (
            <Label>
              <SecondaryText>Reference undrained shear strength</SecondaryText>
              <InputDimensioned
                disabled={disabled}
                scaleFactor={1e-3}
                validate={between(10, 200)}
                validationMessage={`Needs to be within 10 - 200 kPa`}
                changelogInfo={foundationChangelogInfo(
                  tempFoundation.id,
                  nodeId,
                  "shearStrength",
                  commentCategory,
                )}
                step="0.1"
                unit="kPa"
                type="number"
                decimals={1}
                value={tempFoundation.shearStrength}
                initialValue={
                  originalFoundation
                    ? originalFoundation.shearStrength
                    : undefined
                }
                onChange={(d) => {
                  setTempFoundation({
                    ...tempFoundation,
                    shearStrength: d,
                  });
                }}
              />
            </Label>
          )}
        </Grid>
      </ColumnSection>
    </>
  );
};

export default DetailedJacketSettings;
