import SubtractCircleIcon from "@icons/24/SubtractCircle.svg";
import ReverseIcon from "@icons/14/Reverse.svg";
import StylingIcon from "@icons/14/Styling.svg";
import {
  sourceValueMinMaxSelector,
  sourceValueSelectorFamily,
  styleSelectorFamily,
} from "business/style/state";
import { GradientStyle } from "business/style/types";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import { Input, TextInput } from "components/General/Input";
import { Column } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { orLoader } from "components/Loading/Skeleton";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing7 } from "styles/space";
import { typography } from "styles/typography";
import { parseValue, renderValue } from "business/style/constants";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { ColorSquare } from "components/General/ColorSquare";
import { balanceStyle } from "business/style/actions";
import { MenuButton, MenuButtonRef } from "components/General/MenuButton";
import { ColorRangeMenu } from "./ColorRangeDropdown";
import { Gradient } from "lib/colors";
import { useRef } from "react";
import { useAtomUnwrap } from "utils/jotai";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem ${spacing7};

  > ${ColorSquare} {
    height: 2rem;
    width: 2rem;
    margin: 0.2rem;
  }

  > ${TextInput} {
    ${typography.caption};
    min-width: 0;
    flex: 1;
  }

  .hide {
    svg {
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    .hide {
      svg {
        opacity: 1;
      }
    }
  }

  .btn {
    height: 2rem;
    width: 2rem;
    padding: 0.3rem;
    svg {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
`;

const _EditColorGradient = styled(Column)`
  .list {
    display: flex;
    flex-direction: column;
    > div:nth-child(2n + 2):not(:last-child) {
      background: ${colors.surfaceSecondary};
    }
  }

  & > button {
    align-self: end;
  }
`;

export function EditColorGradient({ style }: { style: GradientStyle }) {
  const disabled = style.defaultMarker;
  const setStyle = useSetAtom(styleSelectorFamily(style.id));

  const balance = useSetAtom(balanceStyle);
  const minmax = useAtomUnwrap(sourceValueMinMaxSelector(style.id));

  const updateNumber = (i: number, n: number) => {
    const gradient = style.gradient.clone().setValue(i, n);
    setStyle({ ...style, gradient });
  };

  const sourceValues = useAtomValue(
    loadable(
      sourceValueSelectorFamily({ styleId: style.id, colorKey: style.source }),
    ),
  );
  const mbRef = useRef<MenuButtonRef>(null);

  return (
    <_EditColorGradient>
      <div className="list">
        <Row>
          <MenuButton
            ref={mbRef}
            icon={<StylingIcon />}
            size="small"
            buttonType="secondary"
            disabled={disabled}
          >
            <ColorRangeMenu
              onClick={(gradient: Gradient) => {
                mbRef.current?.setIsOpen(false);
                balance({ ...style, gradient });
              }}
            />
          </MenuButton>
        </Row>
        {style.gradient.stops().map((b, i) => {
          const val = renderValue(style.source, b.value);
          return (
            <Row key={i}>
              <ColorSquare $color={b.color} />
              <Input
                key={"f" + val}
                defaultValue={val}
                compact
                disabled={disabled}
                onBlur={(e) =>
                  updateNumber(i, parseValue(style.source, e.target.value))
                }
              />
              {!disabled && (
                <IconBtn
                  disabled={style.gradient.numStops() <= 3}
                  className="hide"
                  size="1.4rem"
                  onClick={() => {
                    setStyle({
                      ...style,
                      gradient: style.gradient.clone().pop(i),
                    });
                  }}
                >
                  <SubtractCircleIcon />
                </IconBtn>
              )}
            </Row>
          );
        })}
        <Row>
          <Button
            icon={<ReverseIcon />}
            size="small"
            buttonType="secondary"
            disabled={style.gradient.numStops() <= 3 || disabled}
            onClick={() => {
              if (style.gradient.numStops() <= 3) return;
              setStyle({
                ...style,
                gradient: style.gradient.clone().reverseColors(),
              });
            }}
          />
          <Button
            size="small"
            buttonType="secondary"
            text="Balance interval"
            disabled={minmax === undefined || disabled}
            onClick={() => balance(style)}
          />
        </Row>
      </div>

      <Row style={{ paddingInline: "unset", justifyContent: "space-between" }}>
        {orLoader(sourceValues, () => {
          return (
            <Tooltip
              text="Balance the buckets to be of equal size."
              outerDivStyle={{ alignSelf: "end" }}
            >
              <Button
                text="Balance"
                buttonType="secondary"
                onClick={() => balance(style)}
                disabled={disabled}
              />
            </Tooltip>
          );
        })}
      </Row>
    </_EditColorGradient>
  );
}
