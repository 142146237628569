import {
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "../constants/division";
import { PARK_PROPERTY_TYPE } from "../constants/park";
import { TURBINE_PROPERTY_TYPE } from "../constants/projectMapView";
import {
  CableCorridorFeature,
  CableFeature,
  CommonProps,
  ExclusionZoneFeature,
  ExportCableFeature,
  FeatureType,
  SubAreaFeature,
  ParkFeature,
  SubstationFeature,
  TurbineFeature,
  SensorFeature,
} from "./feature";

export enum DesignToolMode {
  Onshore = "onshore",
  Offshore = "offshore",
}

type OtherTypes = Exclude<
  FeatureType,
  | typeof TURBINE_PROPERTY_TYPE
  | typeof CABLE_PROPERTY_TYPE
  | typeof SUB_AREA_PROPERTY_TYPE
  | typeof DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE
  | typeof PARK_PROPERTY_TYPE
  | typeof EXPORT_CABLE_PROPERTY_TYPE
  | typeof CABLE_CORRIDOR_PROPERTY_TYPE
  | typeof SUBSTATION_PROPERTY_TYPE
>;
// Remove the "id" field from the feature and add source.
type W<T> = Omit<T, "id"> & { source?: string };
export type AddFeatureOptions =
  | W<SensorFeature["properties"]>
  | W<TurbineFeature["properties"]>
  | W<CableFeature["properties"]>
  | W<ExclusionZoneFeature["properties"]>
  | W<SubAreaFeature["properties"]>
  | W<ParkFeature["properties"]>
  | W<ExportCableFeature["properties"]>
  | W<CableCorridorFeature["properties"]>
  | W<SubstationFeature["properties"]>
  | W<Partial<CommonProps & { type: OtherTypes }>>
  | W<{ type?: never }>;
