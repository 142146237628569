import { CableChainFeature, CableFeature } from "types/feature";
import { partition } from "./utils";
import { sendInfo } from "./sentry";
import { CablesNotLinearError } from "types/cables";

/**
 * Figure out which order the cables are in the given cable chain.
 * The first cable goes out from the substation, and so on.
 */
export const cablesFromCableChain = (
  chain: CableChainFeature["properties"],
  allCables: CableFeature[],
): CableFeature[] | undefined => {
  const endpointsInChain = new Set([chain.substation, ...chain.turbines]);

  let cables = allCables.filter(
    (c) =>
      endpointsInChain.has(c.properties.toId) &&
      endpointsInChain.has(c.properties.fromId),
  );
  const _cables = cables;

  // Find one cable at a time; there should be exactly one cable in `cables` that has
  // either endpoint in the `set`.  The first one is the cable going to the substation,
  // the second one (once the first turbine is in `set`), is the second, and so on.
  const set = new Set([chain.substation]);
  let ret: CableFeature[] = [];

  for (let i = 0; i < chain.turbines.length; i++) {
    const [frontier, rest] = partition(
      cables,
      ({ properties: { fromId, toId } }) => set.has(fromId) || set.has(toId),
    );
    if (frontier.length !== 1) {
      sendInfo(new CablesNotLinearError(), {
        _cables,
        cables,
        frontier,
        rest,
      });
      return;
    }
    const cable = frontier[0];
    if (set.has(cable.properties.fromId)) set.add(cable.properties.toId);
    else if (set.has(cable.properties.toId)) set.add(cable.properties.fromId);
    ret.push(cable);
    cables = rest;
  }

  return ret;
};
