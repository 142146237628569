import * as turf from "@turf/turf";
import {
  getExportCablesUtilizationFamily,
  getTotalExportSystemLoss,
  getTotalExportSystemLossGWh,
  getTotalInterArrayLoss,
  getTotalInterArrayLossGWh,
} from "analysis/electrical";
import {
  getAverageHubHeight,
  getBranchId,
  getConfiguration,
  getFoundationStats,
  getOtherLosses,
  getParkId,
  getProjectId,
  getTurbineCapacity,
  getTurbines,
  getWakeSettings,
} from "analysis/inputs";
import {
  getAEP,
  getAnalysisResponse,
  getAnalysisWindStats,
  getAverageTurbineSpeed,
  getCapacityFactor,
  getGrossEnergy,
  getMaxPower,
  getNeighbourWakeLoss,
  getTotalWakeLoss,
  getTurbineSpecificLoss,
} from "analysis/output";
import { getStoppedReason, getAnalysisError } from "analysis/warnings";
import { useRealValueCapexMillion } from "finance/hooks/useCapex";
import { useIRR } from "finance/hooks/useIrr";
import { useLcoe } from "finance/hooks/useLcoe";
import { FinanceId } from "finance/types";
import { useBathymetry } from "hooks/bathymetry";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { loadable, unwrap } from "jotai/utils";
import { DefaultMap } from "lib/DefaultMap";
import { useEffect, useMemo, useState } from "react";
import { CableType } from "services/cableTypeService";
import { WAKE_MODEL_NAME } from "services/configurationService";
import { CostType } from "services/costService";
import { cable3DLengthsFamily, cablesInParkFamily } from "state/jotai/cable";
import { IAcableTypesFamily } from "state/jotai/cableType";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { atomFamily, lunwrap, useAtomUnwrap } from "utils/jotai";
import {
  ExportCableFeature,
  ParkFeature,
  SubAreaFeature,
  TurbineFeature,
} from "../../../types/feature";
import { SimpleTurbineType } from "../../../types/turbines";
import { isDefined } from "../../../utils/predicates";
import { dedup, sum } from "../../../utils/utils";
import { getRasterStats } from "../../Bathymetry/useGetRasterStats";
import { compareIsLoading } from "../state";
import { ComparisonAttributeKey } from "../types";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { getMinDistanceBetweenTurbines } from "functions/turbines";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { Polygon, MultiPolygon } from "@turf/turf";
import { Feature } from "@turf/turf";

const turbineTypeString = (
  turbines: TurbineFeature[],
  allTurbineTypes: SimpleTurbineType[],
): string | undefined => {
  const types = dedup(turbines.map((f) => f.properties.turbineTypeId))
    .map((id) => allTurbineTypes.find((t) => t.id === id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const exportCableTypeString = (
  exportCables: ExportCableFeature[],
  allExportCableTypes: CableType[],
  getCableType: (feature: ExportCableFeature) => string | undefined,
): string | undefined => {
  const types = dedup(exportCables.map(getCableType))
    .map((id) => allExportCableTypes.find((e) => e.id === id)?.name)
    .filter(isDefined)
    .sort();
  if (types.length === 0) return "-";
  return types.join(", ");
};

const calculateTotalSubArea = (subareas: SubAreaFeature[]) => {
  // If no subareas, return 0
  if (!subareas || subareas.length === 0) return 0;

  const subareasUnion = subareas.reduce<Feature<Polygon | MultiPolygon> | null>(
    (union, subarea) => {
      if (!union) return subarea;
      const result = turf.union(union, subarea);
      return result as Feature<Polygon | MultiPolygon> | null;
    },
    null,
  );

  // If union was not successful, return 0
  if (!subareasUnion) return 0;

  return turf.area(subareasUnion) / (1000 * 1000); // Convert to km²
};

const loadComparisonDataFamily = atomFamily((triggerId: FinanceId) =>
  atom(async (get) => {
    const [projectId, branchId, parkId] = await Promise.all([
      get(getProjectId(triggerId)),
      get(getBranchId(triggerId)),
      get(getParkId(triggerId)),
    ]);

    const analysisConfig = get(getConfiguration(triggerId));
    const turbines = get(getTurbines(triggerId));
    const turbineTypes = get(simpleTurbineTypesAtom);

    const cables = get(
      cablesInParkFamily({
        parkId: parkId,
        branchId: branchId,
      }),
    );
    const cableTypes = get(IAcableTypesFamily({ projectId }));

    const exportCables = get(
      exportCablesInParkFamily({
        parkId,
        branchId,
      }),
    );
    const exportCableTypes = get(exportCableTypesFamily({ projectId }));
    const subAreas = get(subAreasInParkFamily({ parkId, branchId }));

    return {
      projectId,
      branchId,
      parkId,
      analysisConfig: await analysisConfig,
      turbines: await turbines,
      allTurbineTypes: await turbineTypes,
      cables: await cables,
      cableTypes: await cableTypes,
      exportCables: await exportCables,
      exportCableTypes: await exportCableTypes,
      subAreas: await subAreas,
    };
  }),
);

const useParkComparisonValues = (
  triggerId: FinanceId,
  park: ParkFeature,
  branchId: string,
  selectedHubHeight?: number,
  selectedMaxPower?: number,
  selectedTurbineType?: SimpleTurbineType,
  selectedExportCableType?: CableType,
): Record<ComparisonAttributeKey, Record<string, unknown>> => {
  const {
    projectId,
    analysisConfig,
    turbines,
    allTurbineTypes,
    cables,
    cableTypes,
    exportCables,
    exportCableTypes,
    subAreas,
  } = useAtomValue(loadComparisonDataFamily(triggerId));

  const numberExportCables = exportCables.length;

  // Park
  const area = useMemo(
    () => Math.round(turf.area(park) / (1000 * 1000)),
    [park],
  );

  const subArea = useMemo(
    () => Math.round(calculateTotalSubArea(subAreas)),
    [subAreas],
  );

  const parkLocation = useMemo(() => turf.centerOfMass(park), [park]);

  const [bathymetryJ] = useBathymetry({
    projectId,
    branchId,
    featureId: park.id,
    bufferKm: undefined,
  });
  const bathId =
    bathymetryJ.state === "hasData" ? bathymetryJ.data.id : undefined;

  const foundationTotals = useAtomValue(
    loadable(getFoundationStats({ id: triggerId, rasterId: bathId })),
  );

  const [rasterStatsMaybe, setRasterStats] = useState<
    Awaited<ReturnType<typeof getRasterStats>> | undefined
  >();

  useEffect(() => {
    if (bathymetryJ.state !== "hasData") return;
    if (bathymetryJ.data.status !== "finished") return;
    const { raster } = bathymetryJ.data;
    const abortController = new AbortController();

    setRasterStats(undefined);
    getRasterStats(park, raster, abortController.signal).then((d) => {
      setRasterStats(d);
    });
    return () => {
      abortController.abort("unmount");
    };
  }, [park, bathymetryJ]);

  const minValue = rasterStatsMaybe?.minValue ?? 0;
  const maxValue = rasterStatsMaybe?.maxValue ?? 0;
  const averageDepth = rasterStatsMaybe?.averageValue ?? 0;

  const turbineTypesString = useMemo<string | undefined>(() => {
    if (selectedTurbineType) return selectedTurbineType.name;
    return turbineTypeString(turbines, [...allTurbineTypes.values()]);
  }, [turbines, allTurbineTypes, selectedTurbineType]);

  const avgTurbineHeight = useAtomValue(getAverageHubHeight(triggerId));
  const _maxPower = useAtomUnwrap(getMaxPower(triggerId));

  const averageTurbineHeight = useMemo<number>(() => {
    if (selectedHubHeight) return selectedHubHeight;
    return avgTurbineHeight;
  }, [avgTurbineHeight, selectedHubHeight]);

  const maxPower = useMemo<number | undefined>(() => {
    if (selectedMaxPower) return selectedMaxPower;
    return _maxPower;
  }, [_maxPower, selectedMaxPower]);

  const windStats = useAtomUnwrap(getAnalysisWindStats(triggerId));
  const aep = useAtomUnwrap(getAEP(triggerId));
  const gross = useAtomUnwrap(getGrossEnergy(triggerId));

  const totalWakeLoss = useAtomUnwrap(getTotalWakeLoss(triggerId));
  const turbineSpecificLoss = useAtomUnwrap(getTurbineSpecificLoss(triggerId));
  const neighbourWakeLoss = useAtomUnwrap(getNeighbourWakeLoss(triggerId));
  const internalWakeLoss =
    isDefined(totalWakeLoss) && isDefined(neighbourWakeLoss)
      ? totalWakeLoss - neighbourWakeLoss
      : undefined;
  const averageTurbineSpeed = useAtomUnwrap(getAverageTurbineSpeed(triggerId));
  const otherLoss = useAtomUnwrap(getOtherLosses(triggerId));
  const capacityFactor = useAtomUnwrap(getCapacityFactor(triggerId));
  const totalInterArrayLoss = useAtomUnwrap(
    getTotalInterArrayLoss(triggerId),
  )?.totalInterArrayLoss;
  const totalInterArrayLossGWh = useAtomUnwrap(
    getTotalInterArrayLossGWh(triggerId),
  )?.totalInterArrayLoss;
  const analysis = useAtomUnwrap(getAnalysisResponse(triggerId));
  const progress = analysis?.progress;
  const status = analysis?.status;
  const analysisVersion = analysis?.version;

  const stoppedReasonFrontEnd = useAtomValue(
    unwrap(getStoppedReason(triggerId)),
  );
  const stoppedReasonBackEnd = useAtomUnwrap(getAnalysisError(triggerId));
  const stoppedReason = stoppedReasonFrontEnd ?? stoppedReasonBackEnd;

  const capacity = useAtomValue(getTurbineCapacity(triggerId));

  const totalExportSystemLoss = useAtomUnwrap(
    getTotalExportSystemLoss(triggerId),
  )?.totalExportSystemLoss;
  const totalExportSystemLossGWh = useAtomUnwrap(
    getTotalExportSystemLossGWh(triggerId),
  )?.totalExportSystemLoss;

  const capacityDensity = capacity
    ? Number((capacity / area).toPrecision(3))
    : undefined;

  // Cabling
  const exportCableLength = useMemo(
    () => sum(exportCables, (f) => turf.length(f, { units: "kilometers" })),
    [exportCables],
  );

  const cableLengths = useAtomValue(
    unwrap(
      cable3DLengthsFamily({
        parkId: park.id,
        branchId,
        analysisConfigurationId: analysisConfig.id,
      }),
    ),
  );

  const interArrayCableLength = useMemo(() => {
    return sum(Array.from(cableLengths?.values() ?? []), (l) => l.contingent);
  }, [cableLengths]);

  const cableTypeMap = useAtomValue(
    IAcableTypesFamily({ projectId: undefined }),
  );

  const cablesToAdd = useMemo(() => {
    if (!cableLengths)
      return Object.fromEntries(
        Object.keys(cableTypes).map((id) => [id, undefined]),
      );

    const ret = new DefaultMap<string, number>(() => 0);
    for (const cable of cables) {
      const len = cableLengths.get(cable.id)?.contingent;
      if (len === undefined) continue;
      const { cableTypeId } = cable.properties;
      if (!cableTypeId || !cableTypeMap.has(cableTypeId)) continue;
      ret.update(cableTypeId, (n) => n + len);
    }

    return Array.from(cableTypes.values()).reduce<Record<string, number>>(
      (acc, curr) => {
        acc[curr.id] = ret.get(curr.id) ?? 0;
        return acc;
      },
      {},
    );
  }, [cableLengths, cableTypeMap, cableTypes, cables]);

  const interArrayCableVoltages = useMemo(() => {
    return dedup(
      cables
        .map(
          (cable) =>
            cableTypes.get(cable.properties.cableTypeId ?? "")?.voltage,
        )
        .filter(isDefined),
    );
  }, [cables, cableTypes]);

  const exportCableVoltages = useMemo(() => {
    return dedup(
      exportCables
        .map(
          (cable) =>
            exportCableTypes.get(cable.properties.cableTypeId ?? "")?.voltage,
        )
        .filter(isDefined),
    );
  }, [exportCables, exportCableTypes]);

  const exportCableTypesString = useMemo<string | undefined>(() => {
    if (selectedExportCableType) return selectedExportCableType.name;
    return exportCableTypeString(
      exportCables,
      [...exportCableTypes.values()],
      (f: ExportCableFeature) => f.properties.cableTypeId,
    );
  }, [exportCables, exportCableTypes, selectedExportCableType]);

  const exportCableTypesOnshoreString = useMemo<string | undefined>(() => {
    return exportCableTypeString(
      exportCables,
      [...exportCableTypes.values()],
      (f: ExportCableFeature) => f.properties.onshoreCableTypeId,
    );
  }, [exportCables, exportCableTypes]);

  const offShoreSubstations = useAtomValue(
    substationsInParkWithTypeFamily({ parkId: park.id, branchId }),
  )
    .filter(([, s]) => s.type === "offshore")
    .map(([t]) => t);

  const numberOffShoreSubstations = offShoreSubstations.length;

  const exportCableSegments = useAtomValue(
    exportCableSplitsOkFamily({ parkId: park.id, branchId }),
  );

  const wakeSettings = useAtomValue(getWakeSettings(triggerId));

  const offshoreCableLength = useMemo(
    () =>
      exportCableSegments
        .map((f) => f.offshore)
        .reduce((acc, f) => {
          const thisLength = turf.length(f, { units: "kilometers" });
          return acc + thisLength;
        }, 0),
    [exportCableSegments],
  );

  const onshoreCableLength = useMemo(
    () =>
      exportCableSegments
        .map((f) => f.onshore)
        .reduce((acc, f) => {
          const thisLength = turf.length(f, { units: "kilometers" });
          return acc + thisLength;
        }, 0),
    [exportCableSegments],
  );

  const maxExportCablesUtilization = useAtomUnwrap(
    getExportCablesUtilizationFamily(triggerId),
  );

  // LCOE
  const { inputs: lcoeInputs, results: lcoeResults } = useLcoe(triggerId);
  const { results: irrResults } = useIRR(triggerId);

  const setIsLoading = useSetAtom(compareIsLoading);
  useEffect(() => {
    if (
      lcoeResults.lcoe.state !== "loading" &&
      aep &&
      cableLengths !== undefined &&
      averageTurbineSpeed !== undefined &&
      averageDepth !== undefined &&
      windStats !== undefined
    ) {
      setIsLoading((c) => ({ ...c, [triggerId]: false }));
    } else {
      setIsLoading((c) => ({ ...c, [triggerId]: true }));
    }
  }, [
    lcoeResults.lcoe.state,
    aep,
    windStats,
    setIsLoading,
    averageTurbineSpeed,
    triggerId,
    averageDepth,
    cableLengths,
  ]);

  const windSource = useMemo(() => {
    if (!windStats) return;
    const name =
      windStats.source === "custom" ? "Custom" : windStats.source.toUpperCase();
    return `${name} (${windStats?.fromYear}-${windStats?.toYear})`;
  }, [windStats]);

  const lcoe = lunwrap(lcoeResults.lcoe);
  const netAEP = lunwrap(lcoeInputs.netAEP);
  const devexNPV = lunwrap(lcoeResults.devex.npv);
  const capexNPV = lunwrap(lcoeResults.capex.npv);
  const opexNPV = lunwrap(lcoeResults.opex.npv);
  const decomNPV = lunwrap(lcoeResults.decom.npv);
  const lifeTime = lunwrap(lcoeInputs.lifeTime);
  const irr = lunwrap(irrResults.irr);
  const { useCosts } = useRealValueCapexMillion(triggerId);
  const { costs: _turbineCosts, sum: turbineSum } = useCosts(CostType.Turbine);
  const totalTurbineCost = lunwrap(turbineSum);
  const { costs: _cableCosts, sum: cableSum } = useCosts(CostType.Cable);
  const totalCableCost = lunwrap(cableSum);
  const { costs: _foundationCosts, sum: foundationSum } = useCosts(
    CostType.Foundation,
  );
  const totalFoundationCost = lunwrap(foundationSum);
  const { costs: _mooringCosts, sum: mooringSum } = useCosts(CostType.Mooring);
  const totalMooringCost = lunwrap(mooringSum);
  const { costs: _substationCosts, sum: substationSum } = useCosts(
    CostType.Substation,
  );
  const totalSubstationCost = lunwrap(substationSum);
  const { costs: _exportCableCosts, sum: exportCableSum } = useCosts(
    CostType.ExportCable,
  );
  const totalExportCableCost = lunwrap(exportCableSum);
  const { costs: _otherCapexCosts, sum: otherSum } = useCosts(CostType.Other);

  const totalOtherCapexCost = lunwrap(otherSum);

  const contingency = lunwrap(lcoeInputs.capexContingency);
  const opexContingency = lunwrap(lcoeInputs.opexContingency);
  const discountRate = lunwrap(lcoeInputs.discountRate);
  const inflationRate = lunwrap(lcoeInputs.inflationRate);

  const foundationsTotalsValueMaybe =
    foundationTotals.state === "hasData" ? foundationTotals.data : undefined;
  const primarySteelWeight = foundationsTotalsValueMaybe?.totalPrimarySteelMass;
  const minFoundationWeight = foundationsTotalsValueMaybe?.minFoundationWeight;
  const avgFoundationWeight =
    foundationsTotalsValueMaybe?.averageFoundationWeight;
  const maxFoundationWeight = foundationsTotalsValueMaybe?.maxFoundationWeight;
  const minFoundationDepth = foundationsTotalsValueMaybe?.minimumDepth;
  const avgFoundationDepth = foundationsTotalsValueMaybe?.averageDepth;
  const maxFoundationDepth = foundationsTotalsValueMaybe?.maximumDepth;

  const nrTurbines = turbines.length;
  const cableLengthContingency = analysisConfig.electrical
    .cableLengthContingencyEnabled
    ? analysisConfig.electrical.cableLengthContingency
    : 0;
  // If you add any new fields here, remember to add the rows in /components/CompareParksModal/columnTemplates.tsx
  const minDistance = getMinDistanceBetweenTurbines(turbines, allTurbineTypes);
  const toReturn = useMemo(
    () => ({
      [ComparisonAttributeKey.PARK]: {
        nrTurbines: nrTurbines,
        minDistance,
        turbineTypesString,
        parkLocation: {
          lon: parkLocation.geometry.coordinates[0],
          lat: parkLocation.geometry.coordinates[1],
        },
        minValue,
        maxValue,
        depthRange: [minValue, maxValue],
        averageDepth,
        area,
        subArea,
        averageTurbineHeight,
      },
      [ComparisonAttributeKey.WIND]: {
        avgPowerLawCoefficient: windStats?.meanAlpha,
        height: windStats?.height,
        windSource,
        location: windStats
          ? {
              lat: windStats.latitude,
              lon: windStats.longitude,
            }
          : undefined,
        avgTurbineWindSpeed: averageTurbineSpeed,
        avgSourceWindSpeed: windStats?.meanSpeed,
        airDensity: windStats?.meanDensity,
      },
      [ComparisonAttributeKey.PRODUCTION]: {
        capacity,
        otherLoss,
        capacityDensity,
        netEnergy: aep,
        maxPower,
        grossEnergy: gross,
        capacityFactor,
        totalWakeLoss,
        neighbourWakeLoss,
        internalWakeLoss,
        turbineSpecificLoss,
        status,
        stoppedReason,
        progress,
        analysisVersion,
        wakeModel: WAKE_MODEL_NAME[wakeSettings.wakeModel],
      },
      [ComparisonAttributeKey.CABLING]: {
        exportCableLength,
        interArrayCableLength,
        totalInterArrayLoss,
        totalInterArrayLossGWh,
        totalExportSystemLoss,
        totalExportSystemLossGWh,
        numberOffShoreSubstations,
        numberExportCables,
        offshoreCableLength,
        onshoreCableLength,
        cableTypes: cablesToAdd,
        interArrayCableVoltages,
        exportCableVoltages,
        cableLengthContingency,
        exportCableTypesString,
        exportCableTypesOnshoreString,
        maxExportCableUtilizationOnshore:
          maxExportCablesUtilization?.onshoreUtilization || null,
        maxExportCableUtilizationOffshore:
          maxExportCablesUtilization?.offshoreUtilization || null,
      },
      [ComparisonAttributeKey.FINANCIAL]: {
        lcoe: lcoe,
        devex: devexNPV,
        capex: capexNPV,
        opex: opexNPV,
        decommissioning: decomNPV,
        irr: irr && "ok" in irr ? irr.ok : undefined,
        turbineCost: totalTurbineCost,
        cableCost: totalCableCost,
        foundationCost: totalFoundationCost,
        mooringCost: totalMooringCost,
        substationCost: totalSubstationCost,
        exportCableCost: totalExportCableCost,
        otherCapexCost: totalOtherCapexCost,
      },
      [ComparisonAttributeKey.FOUNDATIONS]: {
        primarySteelWeight,
        minFoundationWeight,
        avgFoundationWeight,
        maxFoundationWeight,
        minFoundationDepth,
        avgFoundationDepth,
        maxFoundationDepth,
      },
      [ComparisonAttributeKey.FINANCIAL_INPUTS]: {
        lifeTime: lifeTime,
        aep: netAEP,
        contingency,
        opexContingency,
        discountRate,
        inflationRate,
      },
    }),
    [
      subArea,
      nrTurbines,
      minDistance,
      turbineTypesString,
      parkLocation.geometry.coordinates,
      minValue,
      maxValue,
      averageDepth,
      area,
      averageTurbineHeight,
      windStats,
      windSource,
      averageTurbineSpeed,
      capacity,
      otherLoss,
      capacityDensity,
      aep,
      maxPower,
      gross,
      capacityFactor,
      totalWakeLoss,
      neighbourWakeLoss,
      internalWakeLoss,
      turbineSpecificLoss,
      status,
      stoppedReason,
      progress,
      analysisVersion,
      exportCableLength,
      interArrayCableLength,
      totalInterArrayLoss,
      totalInterArrayLossGWh,
      totalExportSystemLoss,
      totalExportSystemLossGWh,
      numberOffShoreSubstations,
      numberExportCables,
      offshoreCableLength,
      onshoreCableLength,
      cablesToAdd,
      cableLengthContingency,
      lcoe,
      netAEP,
      devexNPV,
      capexNPV,
      opexNPV,
      decomNPV,
      lifeTime,
      irr,
      primarySteelWeight,
      minFoundationWeight,
      avgFoundationWeight,
      maxFoundationWeight,
      minFoundationDepth,
      avgFoundationDepth,
      maxFoundationDepth,
      wakeSettings.wakeModel,
      contingency,
      opexContingency,
      discountRate,
      inflationRate,
      interArrayCableVoltages,
      exportCableVoltages,
      totalTurbineCost,
      totalCableCost,
      totalFoundationCost,
      totalMooringCost,
      totalSubstationCost,
      totalExportCableCost,
      totalOtherCapexCost,
      exportCableTypesString,
      exportCableTypesOnshoreString,
      maxExportCablesUtilization?.offshoreUtilization,
      maxExportCablesUtilization?.onshoreUtilization,
    ],
  );

  return toReturn;
};

export default useParkComparisonValues;
