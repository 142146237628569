import { useAtomValue } from "jotai";
import { ComponentsPreviewType } from "./state";
import TurbineComponentsPreviewV2 from "./TurbineComponentsPreviewV2";
import CableComponentsPreviewV2 from "./CableComponentsPreviewV2";
import ExportCableComponentsPreviewV2 from "./ExportCableComponentsPreviewV2";
import MooringComponentsPreview from "./MooringComponentsPreview";
import { FoundationComponentsPreviewV2 } from "./FoundationComponentsPreviewV2";
import { midScreenModalTypeOpenAtom } from "state/modal";

const ComponentsPreview = () => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);

  if (
    !midScreenModalTypeOpen ||
    midScreenModalTypeOpen.modalType !== ComponentsPreviewType
  ) {
    return null;
  }

  switch (midScreenModalTypeOpen.metadata?.componentType) {
    case "turbines":
      return <TurbineComponentsPreviewV2 />;
    case "cables":
      return <CableComponentsPreviewV2 />;
    case "export-cables":
      return <ExportCableComponentsPreviewV2 />;
    case "mooring":
      return <MooringComponentsPreview />;
    case "foundations":
      return <FoundationComponentsPreviewV2 />;
    default:
      return null;
  }
};

export default ComponentsPreview;
