import { atom } from "jotai";
import { Layer } from "types/layers";
import { projectIdAtom } from "state/pathParams";
import { getAllLayersSelector } from "state/layer";
import { Pane } from "./LayerListV2";
import { customLayersMetadataSelectorAsync } from "state/customLayers";

export const LayerListPaneAtom = atom<Pane>({
  which: "list",
});

export const selectedLayerForLayerInformationSelector = atom<
  Promise<Layer | undefined>
>(async (get) => {
  const selectedLayers = get(selectedLayerIdsWithCollectionIdAtom);
  const projectId = get(projectIdAtom);

  if (selectedLayers.length === 1 && projectId) {
    const allExternalLayers = await get(
      getAllLayersSelector({
        projectId,
      }),
    );
    const allCustomLayers = await get(
      customLayersMetadataSelectorAsync({
        nodeId: projectId,
      }),
    );

    const allLayers = [...allExternalLayers, ...allCustomLayers];
    return allLayers.find((layer) => layer.id === selectedLayers[0].layerId);
  }
});

export const scrollToLayerIdInListAtom = atom<string | undefined>(undefined);

export const selectedLayerIdsWithCollectionIdAtom = atom<
  Array<{
    layerId: string;
    collectionId?: string;
  }>
>([]);
