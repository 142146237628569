import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import React, { useCallback, useMemo } from "react";
import { colors } from "styles/colors";
import { spacing1, spacing6, spacing7 } from "styles/space";
import { RoundIconWrapper } from "components/Design/BranchTabBar/components/shared";
import { IconREMSize, typography } from "styles/typography";
import ArchiveIcon from "@icons/24/Archive.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai/index";
import { projectIdAtom } from "state/pathParams";
import { archivedBranchMetasFamily } from "state/jotai/branch";
import { editorAccessProjectSelector } from "state/user";
import { useDeleteBranch, useRestoreBranch } from "state/timeline";
import useBranchFolderStructureCrud from "hooks/useBranchFolderStructureCrud";
import {
  allBranchesFrameLoadingAtom,
  archivedBranchesFrameSearchValueAtom,
} from "components/Design/BranchTabBar/state";
import { useToast } from "hooks/useToast";
import Fuse from "fuse.js";
import { BranchMeta } from "types/api";
import { scream } from "utils/sentry";
import { SearchInput } from "components/General/Input";
import { ArchivedBranchTableItem } from "components/Design/BranchTabBar/components/table-items";
import NoItemsGeneric from "components/General/NoItemsGeneric";
import { FolderListWrapper } from "components/FolderList/style";
import {
  ConfirmContentText,
  useConfirm,
} from "components/ConfirmDialog/ConfirmDialog";

const NoArchivedBranchesFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.blue50,
        textAlign: "center",
        padding: spacing7,
      }}
    >
      <RoundIconWrapper
        style={{
          marginBottom: spacing6,
        }}
      >
        <IconREMSize height={1.6} width={1.6} stroke={colors.white}>
          <ArchiveIcon />
        </IconREMSize>
      </RoundIconWrapper>
      <p
        style={{
          ...typography.sub2,
        }}
      >
        No archived branches
      </p>
      <p
        style={{
          ...typography.caption,
        }}
      >
        There are no archived branches in this project at this moment.
      </p>
    </div>
  );
};

const ArchivedBranchesTabContent = () => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const archivedBranchesMap = useAtomValue(
    archivedBranchMetasFamily({
      projectId: nodeId,
    }),
  );
  const { showConfirm } = useConfirm();
  const archivedBranchesList = useMemo(
    () => Array.from(archivedBranchesMap.values()),
    [archivedBranchesMap],
  );
  const [searchValue, setSearchValue] = useAtom(
    archivedBranchesFrameSearchValueAtom,
  );

  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const deleteBranch = useDeleteBranch();
  const restoreBranch = useRestoreBranch();
  const { deleteResource } = useBranchFolderStructureCrud();
  const setIsLoading = useSetAtom(allBranchesFrameLoadingAtom);
  const { error: showError } = useToast();

  const fuse = useMemo(
    () =>
      new Fuse(archivedBranchesList, {
        keys: ["title"],
        includeScore: true,
        threshold: 0.3,
      }),
    [archivedBranchesList],
  );

  const branchesSearchResult = useMemo<BranchMeta[]>(() => {
    return searchValue.length > 0
      ? fuse.search(searchValue).map((result) => result.item)
      : archivedBranchesList;
  }, [fuse, searchValue, archivedBranchesList]);

  const onDeleteBranch = useCallback(
    async (branchMeta: BranchMeta) => {
      if (
        !(await showConfirm({
          title: "Delete branch",
          message: (
            <ConfirmContentText>
              You are about to <b>permanently delete</b> the branch "
              {branchMeta.title}" with all its history. This action can not be
              undone.
            </ConfirmContentText>
          ),
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      }

      setIsLoading(true);
      try {
        const promise = deleteBranch(branchMeta, nodeId);
        deleteResource(branchMeta.id);
        await promise;
      } catch (err) {
        if (err instanceof Error) {
          scream(err, {
            nodeId,
          });
        } else {
          scream(new Error("Failed to remove branch"), {
            nodeId,
            err,
          });
        }
        showError("Failed to remove branch, please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [
      showConfirm,
      setIsLoading,
      deleteBranch,
      nodeId,
      deleteResource,
      showError,
    ],
  );

  const onRestoreBranch = useCallback(
    async (branchMeta: BranchMeta) => {
      setIsLoading(true);
      try {
        const promise = restoreBranch(branchMeta, nodeId);
        await promise;
      } catch (err) {
        if (err instanceof Error) {
          scream(err, {
            nodeId,
          });
        } else {
          scream(new Error("Failed to unarchive branch"), {
            nodeId,
            err,
          });
        }
        showError("Failed to unarchive branch, please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, restoreBranch, nodeId, showError],
  );

  return (
    <>
      <SearchInput
        style={{ width: "100%" }}
        wrapperDivStyle={{ marginBottom: "" }}
        placeholder="Search for archived branch"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onClear={() => {
          setSearchValue("");
        }}
      />
      {archivedBranchesList.length === 0 ? (
        <NoArchivedBranchesFound />
      ) : searchValue !== "" && branchesSearchResult.length === 0 ? (
        <NoItemsGeneric
          headerText="No results"
          subText="We couldn't find any branches matching your search."
        />
      ) : (
        <FolderListWrapper
          style={{
            gap: spacing1,
          }}
        >
          {branchesSearchResult.map((branch) => (
            <ArchivedBranchTableItem
              key={branch.id}
              branch={branch}
              nodeId={nodeId}
              showControls={editorAccessProject}
              onDeleteBranch={onDeleteBranch}
              onRestoreBranch={onRestoreBranch}
            />
          ))}
        </FolderListWrapper>
      )}
    </>
  );
};

export const ArchivedBranchesTabContentOuter = ErrorBoundaryWrapper(
  () => {
    return <ArchivedBranchesTabContent />;
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);
