import { useAtomValue } from "jotai";
import { branchIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { otherUsersSelectionArrayAtomFamily } from "./../state/selection";
import { ABLY_FEATURE_SELECTION } from "../state/ably";
import { useCallback, useEffect, useState } from "react";
import { Message } from "ably";
import { loggedInUserIdAtom } from "../state/user";
import { getCurrentTokenUserId } from "../state/global";
import { currentSelectionArrayAtom } from "../state/selection";
import { SelectionType } from "../services/types";
import usePrevious from "./usePrevious";
import { areArraysEqualSets } from "../utils/utils";
import * as Sentry from "@sentry/react";
import { useChannel } from "ably/react";

export function useAblySelection(projectId: string) {
  const branchId = useAtomValue(branchIdAtom);
  const currentUserId = useAtomValue(loggedInUserIdAtom);
  const currentSelection = useAtomValue(currentSelectionArrayAtom);
  const previousSelection = usePrevious(currentSelection);

  const [initialSync, setInitialSync] = useState(false);

  const onSelectionMessageReceived = useJotaiCallback(
    async (_, set, message: Message) => {
      const match = message.name === ABLY_FEATURE_SELECTION;
      if (!match) return;
      const { userId, selection, source } = message.data;
      if (userId === getCurrentTokenUserId()) return;
      set(otherUsersSelectionArrayAtomFamily(source), (cur) => {
        const withoutUser = cur.filter((s) => s.userId !== userId);
        return selection.length === 0
          ? withoutUser
          : [...withoutUser, { userId, selection }];
      });
    },
    [],
  );

  const { channel, publish, channelError, connectionError } = useChannel(
    {
      channelName: `${projectId}:all`,
      ablyId: projectId,
      onChannelError: () => {},
      onConnectionError: () => {},
    },
    onSelectionMessageReceived,
  );

  const onSelectionChange = useCallback(
    (selection: SelectionType[]) => {
      if (channelError || connectionError) return;

      setInitialSync(true);

      publish(ABLY_FEATURE_SELECTION, {
        userId: currentUserId,
        selection: selection,
        source: {
          projectId,
          branchId,
        },
      }).catch((error) => {
        console.debug("Error publishing selection:", error);
        Sentry.captureException(error, {
          tags: {
            channelName: channel.name,
            channelState: channel.state,
            messageType: "feature_selection",
          },
        });
      });
    },
    [
      channelError,
      connectionError,
      publish,
      currentUserId,
      projectId,
      branchId,
      channel.name,
      channel.state,
    ],
  );

  useEffect(() => {
    if (!initialSync) {
      onSelectionChange(currentSelection);
    } else if (
      previousSelection &&
      !areArraysEqualSets(previousSelection, currentSelection)
    ) {
      onSelectionChange(currentSelection);
    }
  }, [currentSelection, initialSync, onSelectionChange, previousSelection]);

  useEffect(() => {
    if (channelError || connectionError) return;
    return () => {
      publish(ABLY_FEATURE_SELECTION, {
        userId: currentUserId,
        selection: [],
        source: { projectId, branchId },
      }).catch((error) => {
        console.debug("Error clearing selection:", error);
        Sentry.captureException(error, {
          tags: {
            channelName: channel.name,
            channelState: channel.state,
            messageType: "feature_selection_cleanup",
          },
        });
      });
    };
  }, [
    channel.name,
    channel.state,
    channelError,
    connectionError,
    currentUserId,
    projectId,
    branchId,
    publish,
  ]);
}
