import { useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAtom, useAtomValue } from "jotai";
import { appendQueryParamsSign } from "../../utils/utils";
import {
  LayerListPaneAtom,
  selectedLayerIdsWithCollectionIdAtom,
} from "./state";
import useSyncVisibleLayerWithUrl from "./useSyncVisibleLayerWithUrl";
import { Mixpanel } from "../../mixpanel";
import LayerListPane from "./LayerListPane/LayerListPane";
import { useHorizontalResize } from "../ResizeBar/ResizeBarVertical";
import {
  ANIMATION_DURATION,
  CONTAINER_MIN_WIDTH,
  LeftModalContainer,
} from "components/Design/styles";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { SkeletonText } from "components/Loading/Skeleton";
import { spaceLarge } from "styles/space";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";
import { customerProjectAtomFamily } from "state/timeline";
import { customLayersMetadataSelectorAsync } from "state/customLayers";
import LayerInformation from "components/LayerList/LayerInformation/LayerInformation";
import { loadable } from "jotai/utils";

export type Pane = {
  which: "list";
  backTo?: "list";
};

export const getLegendGraphicsUrl = (sourceLayerId: string, url: string) =>
  `${url}${appendQueryParamsSign(url)}service=WMS&version=1.1.1&request=GetLegendGraphic&format=image/png&layer=${sourceLayerId}`;

const Body = () => {
  useSyncVisibleLayerWithUrl();
  const pane = useAtomValue(LayerListPaneAtom);

  useEffect(
    () =>
      Mixpanel.track_old("View layer list", {
        ...pane,
      }),
    [pane],
  );

  return <LayerListPane />;
};

const menuFrameStyle: React.CSSProperties = {
  color: "black",
  flex: 1,
  minWidth: CONTAINER_MIN_WIDTH,
  width: "var(--left-menu-width)",
  maxWidth: "40vw",
  resize: "horizontal",
};

const LayerList = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );
  const projectId = useAtomValue(projectIdAtom);
  const projectMeta = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId ?? "",
    }),
  );
  const setSelectedLayers = useSetAtom(selectedLayerIdsWithCollectionIdAtom);
  useHorizontalResize(frameRef, "--left-menu-width");
  const projectName = projectMeta?.name;
  const [hide, setHide] = useState(true);
  const [showSmall, setShowSmall] = useState(true);
  const ldba = useAtomValue(
    loadable(
      customLayersMetadataSelectorAsync({
        nodeId: projectId,
      }),
    ),
  );
  const isLoadingCustomLayers = ldba.state === "loading";
  const open = leftModalMenuOpen === LeftModalMenuTypes.Layers && !showSmall;

  useEffect(() => {
    let delay: NodeJS.Timeout;
    if (leftModalMenuOpen === LeftModalMenuTypes.Layers) {
      setHide(false);
      delay = setTimeout(() => setShowSmall(false), 30);
    } else {
      delay = setTimeout(() => {
        setHide(true);
        setShowSmall(true);
      }, ANIMATION_DURATION * 985);
    }
    return () => clearTimeout(delay);
  }, [leftModalMenuOpen]);

  useEffect(() => {
    if (!open) {
      setSelectedLayers([]);
    }
  }, [open, setSelectedLayers]);

  const closeMenu = useCallback(() => {
    setLeftModalMenuOpen(undefined);
  }, [setLeftModalMenuOpen]);

  if (hide) {
    return null;
  }

  return (
    <LeftModalContainer open={open}>
      <MenuFrame
        isLoading={isLoadingCustomLayers}
        blockWhenLoading={false}
        title="External GIS layers"
        subtitle={projectName}
        onExit={closeMenu}
        style={menuFrameStyle}
        ref={frameRef}
        headerStyle={{
          paddingBottom: 0,
        }}
      >
        <Suspense
          fallback={
            <SkeletonText
              style={{
                margin: spaceLarge,
              }}
            />
          }
        >
          <Body />
        </Suspense>
      </MenuFrame>
      <LayerInformation />
    </LeftModalContainer>
  );
};

export default LayerList;
