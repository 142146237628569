import { SubstationType } from "services/substationService";
import { ProjectSubstation } from "./variants/ProjectSubstation";
import { LibrarySubstation } from "./variants/LibrarySubstation";

interface SubstationSettingsProps {
  substation: SubstationType;
  onSave: (substation: SubstationType) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
  isLibraryContext?: boolean;
  isLibrarySubstationInProjectView?: boolean;
  orgSubstationManageAccess?: boolean;
  organisationId?: string;
  duplicateToProject?: (substationId: string) => void;
}

export function SubstationSettings(props: SubstationSettingsProps) {
  const { isLibraryContext, isLibrarySubstationInProjectView, ...commonProps } =
    props;

  if (isLibraryContext) {
    return <LibrarySubstation {...commonProps} />;
  }

  if (isLibrarySubstationInProjectView) {
    return <ProjectSubstation {...commonProps} variant="libraryInProject" />;
  }

  return <ProjectSubstation {...commonProps} />;
}
