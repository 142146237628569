import ShorelineDistanceSlider from "../ShorelineDistanceSlider/ShorelineDistanceSlider";
import DepthSlider from "../DepthSlider/DepthSlider";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import Checkbox from "../General/Checkbox";
import {
  renderDepthFilterAtom,
  renderShoreDistanceAtom,
  renderWindSpeedAtom,
} from "../../state/filter";
import WindSpeedFilterSlider from "../WindSpeedFilterSlider/WindSpeedFilterSlider";
import { Row } from "../General/Layout";
import { InputTitleWrapper } from "components/General/GeneralSideModals.style";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { useAtom, useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";

export const FilterMenuType = "FilterMenu";

const FilterInput = () => {
  const onshore = useAtomValue(isOnshoreAtom);
  const [lowerRightActiveMode, setLowerRightActiveMode] = useAtom(
    lowerRightMenuActiveModeAtom,
  );

  const [showDepth, setShowDepth] = useAtom(renderDepthFilterAtom);
  const [showShoreline, setShowShoreline] = useAtom(renderShoreDistanceAtom);
  const [showWindSpeed, setShowWindSpeed] = useAtom(renderWindSpeedAtom);

  if (lowerRightActiveMode !== FilterMenuType || onshore) return null;

  return (
    <MenuFrame
      title="Filters"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{
        gap: "1.2rem",
      }}
    >
      <Row
        style={{
          paddingTop: "0.8rem",
        }}
      >
        <Checkbox
          label="Depth (m)"
          labelPlacement="after"
          checked={showDepth}
          onChange={(e) => setShowDepth(e.target.checked)}
        />
        <HelpTooltip text={"Source: Gebco"} size={10} />
      </Row>
      <DepthSlider disabled={!showDepth} />

      <Checkbox
        style={{
          paddingTop: "0.8rem",
        }}
        label="Distance to shore (km)"
        labelPlacement="after"
        checked={showShoreline}
        onChange={(e) => setShowShoreline(e.target.checked)}
      />

      <ShorelineDistanceSlider disabled={!showShoreline} />
      <InputTitleWrapper>
        <Checkbox
          style={{
            paddingTop: "0.8rem",
          }}
          label="Windspeed (m/s)"
          labelPlacement="after"
          checked={showWindSpeed}
          onChange={(e) => setShowWindSpeed(e.target.checked)}
        />
      </InputTitleWrapper>

      <WindSpeedFilterSlider disabled={!showWindSpeed} />
    </MenuFrame>
  );
};

export default FilterInput;
