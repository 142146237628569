import { Dashboard, DashboardSerialize, WidgetId, WidgetState } from "./types";
import { VIND_DASHBOARDS, dashboardAtom, dashboardsAtom } from "./state";
import {
  deserializeDashboardRows,
  serializeDashboard,
  serializeDashboardRows,
} from "./utils";
import { DnDRow } from "../General/DnDGrid/types";
import { isDefined } from "../../utils/predicates";
import { useCallback } from "react";
import { With, omitFields } from "../../utils/utils";
import {
  fetchCreateDashboard,
  fetchDeleteDashboard,
  fetchUpdateDashboardDebounce,
} from "./service";
import { projectIdAtom } from "../../state/pathParams";
import { Getter, Setter, useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";

export const useDashboard = (dashboardId: string) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const dashboard = useAtomValue(
    dashboardAtom({ nodeId: projectId, dashboardId }),
  );

  const update = useJotaiCallback(
    async (get, set, update: With<DashboardSerialize, "id">) => {
      if (!dashboard) return;
      if (dashboard.preset) return;

      const updateDashboard = {
        ...dashboard,
        ...update,
        rows:
          "rows" in update
            ? deserializeDashboardRows(update.rows)
            : dashboard.rows,
      };

      const atom = dashboardAtom({
        nodeId: projectId,
        dashboardId: dashboard.id,
      });

      const fallback = await get(atom);
      set(atom, updateDashboard);
      return fetchUpdateDashboardDebounce(projectId, update).catch((e) => {
        set(atom, fallback);
        throw e;
      });
    },
    [projectId, dashboard],
  );

  const create = useJotaiCallback(
    async (
      get,
      set,
      ds: With<Omit<DashboardSerialize, "id" | "author">, "name">,
    ) => {
      const db: Dashboard = await fetchCreateDashboard(projectId, ds);
      await get(dashboardsAtom({ nodeId: projectId }));
      set(
        dashboardAtom({
          nodeId: projectId,
          dashboardId: db.id,
        }),
        db,
      );
      return db;
    },
    [projectId],
  );

  const duplicate = useJotaiCallback(
    async (get, _, id: string) => {
      const dashboard =
        (await get(
          dashboardAtom({
            nodeId: projectId,
            dashboardId: id,
          }),
        )) ?? VIND_DASHBOARDS.find((d) => d.id === id);
      if (!dashboard) throw new Error("No dashboard to duplicate");

      const sd = serializeDashboard(dashboard);
      const newDashboard = await create({
        ...omitFields(sd, "id", "preset", "name", "applicableMode"),
        name: `${sd.name} duplicate`,
      });
      return newDashboard;
    },
    [create, projectId],
  );

  const remove = useJotaiCallback(
    async (get, set, dashboardId: string) => {
      const atom = dashboardAtom({
        nodeId: projectId,
        dashboardId,
      });
      const fallback = await get(atom);
      set(atom, undefined);
      return fetchDeleteDashboard(projectId, dashboardId).catch((e) => {
        set(atom, fallback);
        throw e;
      });
    },
    [projectId],
  );

  const setRows: React.Dispatch<React.SetStateAction<DnDRow[]>> =
    useJotaiCallback(
      async (get, _, dispatch) => {
        const dashboard = await get(
          dashboardAtom({
            nodeId: projectId,
            dashboardId,
          }),
        );
        if (!dashboard) throw new Error("No dashboard to update");

        const newRows = Array.isArray(dispatch)
          ? dispatch
          : dispatch(dashboard.rows);
        return update({
          id: dashboard.id,
          rows: serializeDashboardRows(newRows),
        });
      },
      [dashboardId, projectId, update],
    );

  const updateWidget = useJotaiCallback(
    async <W extends WidgetId>(
      get: Getter,
      _: Setter,
      widgetId: W,
      updateWidget: Partial<WidgetState[W]>,
    ) => {
      const dashboard = await get(
        dashboardAtom({ nodeId: projectId, dashboardId }),
      );
      if (!dashboard) throw new Error("No dashboard to update");
      return update({
        id: dashboardId,
        widgetState: {
          ...dashboard?.widgetState,
          [widgetId]: {
            ...dashboard?.widgetState?.[widgetId],
            ...updateWidget,
          },
        },
      });
    },
    [projectId, dashboardId, update],
  );

  const addWidget = useCallback(
    async (ids: WidgetId[]) => {
      if (!dashboard) return;
      const ds = serializeDashboard(dashboard);

      let newRows: DashboardSerialize["rows"] = (() => {
        let rows = ds.rows ?? [];

        // Process each ID one by one
        for (const id of ids) {
          if (3 <= (rows.at(-1)?.elements?.length ?? 999)) {
            // Create new row if last row is full
            const newRow = {
              id: Math.random().toString(),
              height: 100,
              elements: [{ id }],
            };
            rows = [...rows, newRow];
          } else {
            // Add to existing last row
            const last = rows.at(-1)!;
            rows = [
              ...rows.slice(0, -1),
              { ...last, elements: [...last.elements, { id }] },
            ];
          }
        }

        return rows;
      })();

      return update({ id: dashboardId, rows: newRows });
    },
    [dashboard, dashboardId, update],
  );

  const removeWidget = useCallback(
    async (ids: WidgetId[]) => {
      if (!dashboard) return;
      const ds = serializeDashboard(dashboard);

      // Ensure ids is always an array
      const idsToRemove = Array.isArray(ids) ? ids : [ids];

      let newRows: DashboardSerialize["rows"] = ds.rows
        ?.map((r) => {
          let elements = r.elements.filter(
            (e) => !idsToRemove.includes(e.id as WidgetId),
          );
          if (elements.length === 0) return undefined;
          return {
            ...r,
            elements,
          };
        })
        .filter(isDefined);

      return update({ id: dashboardId, rows: newRows });
    },
    [dashboard, dashboardId, update],
  );

  return {
    addWidget,
    removeWidget,
    setRows,
    update,
    create,
    remove,
    updateWidget,
    duplicate,
  };
};
