import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import Bin from "@icons/24/Bin.svg";
import CablingIcon from "@icons/24/Cabling.svg";
import DollarIcon from "@icons/24/Dollar.svg";
import FoundationIcon from "@icons/24/Foundation.svg";
import LayersIcon from "@icons/24/Layers.svg";
import ProductionIcon from "@icons/24/SideBarIcons/Production.svg";
import SubstationIcon from "@icons/24/Substation.svg";
import WindTurbineIcon from "@icons/24/WindTurbine.svg";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { spaceMedium, spacing4 } from "styles/space";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  admin_extendedUsersInOrganisationSelector,
  Admin_MemberWithAccess,
} from "../state";
import OrgRoleDropdown from "./OrgRoleDropdown";
import { Field, Order, SortState } from "./types";
import { IconREMSize, typography } from "styles/typography";
import {
  loggedInUserAtom,
  ownerInOrganisationSelectorFamily,
} from "state/user";
import { useOrgMemberCrud } from "./useOrgMemberCrud";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { SortWrapper, TableHeader } from "../OrganisationRightSide/style";
import { Anchor } from "components/General/Anchor";
import OrganisationAccessOverviewModal from "components/AccessOverview/OrganisationAccessOverviewModal";
import Button from "components/General/Button";
import Tooltip, { TooltipText, WithTooltip } from "components/General/Tooltip";
import { colors } from "styles/colors";
import { Column, Row } from "components/General/Layout";
import {
  admin_allOrganisationInvitationsSelectorFamily,
  OrganisationInvite_WithUserProjects,
} from "state/customer";
import { dateToDateTime, downloadText, isNever } from "utils/utils";
import { useDeleteInvitation } from "hooks/useUser";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import { SearchInput } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import { Admin_InvitesGrid, Admin_MemberGrid, TableText } from "./style";
import { OrgResource } from "components/Organisation/OrganisationRightSide/types";
import { components } from "api/customer";
import { sendWarning } from "utils/sentry";
import {
  GroupNodeAccess,
  UserNodeAccess,
} from "components/Organisation/Groups/types";
import { VerticalDivider } from "components/General/VerticalDivider";
import { QuestionIconComp } from "components/AccessOverview/shared";
import ProjectAccessOverviewModal from "components/AccessOverview/ProjectAccessOverviewModal";
import { GroupsTooltip } from "./GroupsTooltip";
import { LoadingUsersSkeletons, RoundHourglassIcon, TableCell } from "./shared";
import { IconBtn } from "components/General/Icons";
import { DotMenu } from "components/General/MenuButton";
import DownloadIcon from "@icons/24/Download.svg?react";
import { MenuItem } from "components/General/Menu";
import LibraryAccessOverviewModal from "components/AccessOverview/LibraryAccessOverviewModal";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useScrollSelectedIntoView } from "./hooks/useScrollSelectedIntoView";

const AccessIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing4} ${spacing4};
  border-radius: 4px;
  background-color: ${colors.surfaceSelectedLight};
`;

const Admin_MembersTable = ({ searchValue }: { searchValue: string }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const orgRoleRef = useRef<HTMLDivElement>(null);
  const projectRoleRef = useRef<HTMLDivElement>(null);
  const libraryRoleRef = useRef<HTMLDivElement>(null);
  const [sortState, setSortState] = useState<SortState>({
    field: "nickname",
    order: "ASC",
  });
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const [showOrgRoleAccessModal, setOrgRoleAccessModal] = useState(false);
  const [showProjectAccessModal, setProjectAccessModal] = useState(false);
  const [showLibraryAccessModal, setLibraryAccessModal] = useState(false);

  useEffect(() => {
    setContent((curr) =>
      typeof curr !== "undefined"
        ? curr.type === "user"
          ? curr
          : {
              type: "no-item-selected",
            }
        : undefined,
    );
  }, [setContent]);

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  return (
    <Admin_MemberGrid>
      <TableHeader
        onClick={() => toggleSort("nickname")}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Name
        <IconREMSize width={1.2} height={1.2}>
          {sortState.field === "nickname" && sortState.order === "ASC" ? (
            <DirectionDown />
          ) : sortState.field === "nickname" && sortState.order === "DESC" ? (
            <DirectionUp />
          ) : (
            <SortWrapper>
              <DirectionDown />
            </SortWrapper>
          )}
        </IconREMSize>
      </TableHeader>
      <TableHeader
        ref={orgRoleRef}
        onClick={() => toggleSort("org_access")}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Organisation role
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setOrgRoleAccessModal((cur) => !cur);
          }}
        />
        <IconREMSize width={1.2} height={1.2}>
          {sortState.field === "org_access" && sortState.order === "ASC" ? (
            <DirectionDown />
          ) : sortState.field === "org_access" && sortState.order === "DESC" ? (
            <DirectionUp />
          ) : (
            <SortWrapper>
              <DirectionDown />
            </SortWrapper>
          )}
        </IconREMSize>
      </TableHeader>
      {showOrgRoleAccessModal && (
        <Anchor
          baseRef={orgRoleRef}
          floatPlace="topLeft"
          basePlace="bottomLeft"
        >
          <OrganisationAccessOverviewModal
            onClose={() => setOrgRoleAccessModal(false)}
          />
        </Anchor>
      )}

      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
        ref={projectRoleRef}
      >
        Project access
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setProjectAccessModal((cur) => !cur);
          }}
        />
      </TableHeader>
      {showProjectAccessModal && (
        <Anchor
          baseRef={projectRoleRef}
          floatPlace="top"
          basePlace="bottomLeft"
        >
          <ProjectAccessOverviewModal
            onClose={() => setProjectAccessModal(false)}
          />
        </Anchor>
      )}
      <TableHeader
        ref={libraryRoleRef}
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Library access
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setLibraryAccessModal((cur) => !cur);
          }}
        />
      </TableHeader>
      {showLibraryAccessModal && (
        <Anchor
          baseRef={libraryRoleRef}
          floatPlace="top"
          basePlace="bottomLeft"
        >
          <LibraryAccessOverviewModal
            onClose={() => setLibraryAccessModal(false)}
          />
        </Anchor>
      )}
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      >
        Groups
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.2rem",
          cursor: "pointer",
        }}
      />

      <Suspense fallback={<LoadingUsersSkeletons nrColumns={6} nrRows={4} />}>
        <Admin_MemberRows sortState={sortState} searchValue={searchValue} />
      </Suspense>
    </Admin_MemberGrid>
  );
};

const Admin_InvitesTable = ({ searchValue }: { searchValue: string }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const orgRoleRef = useRef<HTMLDivElement>(null);
  const [showOrgRoleAccessModal, setOrgRoleAccessModal] = useState(false);
  const [showProjectAccessModal, setProjectAccessModal] = useState(false);

  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  useEffect(() => {
    setContent((curr) =>
      typeof curr !== "undefined"
        ? {
            type: "no-info-to-show",
          }
        : undefined,
    );
  }, [setContent]);

  return (
    <Admin_InvitesGrid>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem 1.1rem",
        }}
      >
        Email
      </TableHeader>
      <TableHeader
        ref={orgRoleRef}
        style={{
          margin: "0.8rem 0 3rem",
        }}
      >
        Organisation role
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setOrgRoleAccessModal((cur) => !cur);
          }}
        />
      </TableHeader>
      {showOrgRoleAccessModal && (
        <Anchor
          baseRef={orgRoleRef}
          floatPlace="topLeft"
          basePlace="bottomLeft"
        >
          <OrganisationAccessOverviewModal
            onClose={() => setOrgRoleAccessModal(false)}
          />
        </Anchor>
      )}

      <TableHeader
        style={{
          margin: "0.8rem 0 3rem",
        }}
      >
        Project access
        <QuestionIconComp
          onClick={(e) => {
            e.stopPropagation();
            setProjectAccessModal((cur) => !cur);
          }}
        />
      </TableHeader>
      {showProjectAccessModal && (
        <Anchor
          baseRef={orgRoleRef}
          floatPlace="topLeft"
          basePlace="bottomLeft"
        >
          <ProjectAccessOverviewModal
            onClose={() => setProjectAccessModal(false)}
          />
        </Anchor>
      )}
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem",
        }}
      >
        Groups
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem",
        }}
      >
        Invitation expires
      </TableHeader>
      <TableHeader
        style={{
          margin: "0.8rem 0 3rem",
        }}
      />

      <React.Suspense
        fallback={<LoadingUsersSkeletons nrColumns={6} nrRows={4} />}
      >
        <Admin_InvitationRows searchValue={searchValue} />
      </React.Suspense>
    </Admin_InvitesGrid>
  );
};

const Admin_InvitationRows = ({ searchValue }: { searchValue: string }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const deleteInvite = useDeleteInvitation(organisationId);
  const customerInvites = useAtomValue(
    admin_allOrganisationInvitationsSelectorFamily(organisationId),
  );
  const { showConfirm } = useConfirm();

  const invitesFuse = useMemo(
    () =>
      new Fuse(customerInvites, {
        keys: ["email"],
        includeScore: true,
        threshold: 0.3,
      }),
    [customerInvites],
  );

  const invitesSearchResult = useMemo<
    OrganisationInvite_WithUserProjects[]
  >(() => {
    return searchValue.length > 0
      ? invitesFuse.search(searchValue).map((result) => result.item)
      : customerInvites;
  }, [invitesFuse, searchValue, customerInvites]);

  return invitesSearchResult.map((invite) => (
    <Fragment key={invite.invitationId}>
      <TableCell
        style={{
          minHeight: "6.2rem",
          display: "flex",
          alignItems: "center",
          gap: spaceMedium,
          boxSizing: "border-box",
        }}
      >
        <RoundHourglassIcon />
        <TableText>{invite.email}</TableText>
      </TableCell>
      <TableCell
        style={{
          textTransform: "capitalize",
        }}
      >
        <TableText>{invite.organisationRole}</TableText>
      </TableCell>
      <TableCell
        style={{
          textTransform: "capitalize",
        }}
      >
        {invite.userProjects.length > 0 ? (
          <UserProjects
            hasAccessToAllProject={false}
            projects={invite.userProjects}
          />
        ) : (
          <TableText>None</TableText>
        )}
      </TableCell>
      <TableCell>
        {invite.groupIds.length > 0 ? (
          <GroupsTooltip
            groupIds={invite.groupIds}
            organisationId={organisationId}
          >
            <TableText>
              {invite.groupIds.length} group
              {invite.groupIds.length !== 1 ? "s" : ""}
            </TableText>
          </GroupsTooltip>
        ) : (
          <TableText>None</TableText>
        )}
      </TableCell>
      <TableCell>
        <TableText>
          Expires {dateToDateTime(new Date(invite.expiration * 1000))}
        </TableText>
      </TableCell>
      <TableCell
        style={{
          width: "100%",
          justifyContent: "flex-end",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <IconBtn
          size={"1.4rem"}
          onClick={async () => {
            if (
              await showConfirm({
                title: "Remove invitation",
                message: `Are you sure you want to remove the invitation from your organisation? The user will lose access to all resources and projects of this organisation.`,
                confirmButtonText: "Remove",
              })
            ) {
              deleteInvite(invite.invitationId);
            }
          }}
        >
          <Bin />
        </IconBtn>
      </TableCell>
    </Fragment>
  ));
};

const IconMapper = ({
  resourceName,
}: {
  resourceName: OrgResource["resource_name"];
}) => {
  switch (resourceName) {
    case "org_data_package_manage":
      return (
        <WithTooltip text="Can edit and manage GIS packages">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <LayersIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_analysis_manage":
      return (
        <WithTooltip text="Can edit and manage analysis configuration">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <ProductionIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_cable_manage":
      return (
        <WithTooltip text="Can edit and manage cables">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <CablingIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_export_cable_manage":
      return (
        <WithTooltip text="Can edit and manage export cables">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <CablingIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_financial_manage":
      return (
        <WithTooltip text="Can edit and manage financial configurations">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <DollarIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_turbine_manage":
      return (
        <WithTooltip text="Can edit and manage turbines">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <WindTurbineIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_foundation_manage":
      return (
        <WithTooltip text="Can edit and manage foundations">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <FoundationIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    case "org_substation_manage":
      return (
        <WithTooltip text="Can edit and manage substations">
          <AccessIconWrapper className="blue-background">
            <IconREMSize height={1.2} width={1.2} iconColor={colors.blue900}>
              <SubstationIcon />
            </IconREMSize>
          </AccessIconWrapper>
        </WithTooltip>
      );
    default:
      isNever(resourceName);
      sendWarning("Tried to render an unknown resource access icon", {
        resourceName,
      });
      return null;
  }
};

const AccessIcons = ({ access }: { access: OrgResource[] }) => {
  return (
    <Row alignCenter>
      {access.map((a) => (
        <IconMapper key={a.resource_name} resourceName={a.resource_name} />
      ))}
    </Row>
  );
};

const ProjectsTooltip = ({
  projects,
  children,
}: React.PropsWithChildren<{
  projects: (UserNodeAccess | GroupNodeAccess)[];
}>) => {
  const uniqueRoles = useMemo(
    () => new Set(projects.map((project) => project.resource_name)),
    [projects],
  );
  const projectsSeparatedByRole = useMemo(
    () =>
      Array.from(uniqueRoles).map((role) => ({
        role,
        projects: projects.filter((project) => project.resource_name === role),
      })),
    [projects, uniqueRoles],
  );

  return (
    <Tooltip
      text=""
      content={projectsSeparatedByRole.map((m) => {
        return (
          <Row
            key={m.role}
            style={{
              gap: "0.3rem",
            }}
          >
            <TooltipText
              secondary={false}
              theme="dark"
              style={{
                textTransform: "capitalize",
              }}
            >
              {m.role}
            </TooltipText>
            <TooltipText key={m.role} secondary={false} theme="dark">
              in {m.projects.length} project
              {m.projects.length !== 1 ? "s" : ""}
            </TooltipText>
          </Row>
        );
      })}
    >
      {children}
    </Tooltip>
  );
};

const UserProjects = ({
  hasAccessToAllProject,
  projects,
}: React.PropsWithChildren<{
  hasAccessToAllProject: boolean;
  projects: (UserNodeAccess | GroupNodeAccess)[];
}>) => {
  const uniqueRoles = new Set(projects.map((project) => project.resource_name));

  return (
    <ProjectsTooltip projects={projects}>
      <Row alignCenter>
        {hasAccessToAllProject ? (
          <TableText>All projects</TableText>
        ) : (
          <TableText
            style={{
              wordSpacing: "nowrap",
              whiteSpace: "nowrap",
            }}
          >
            {projects.length} project
            {projects.length !== 1 ? "s" : ""}
          </TableText>
        )}
        <VerticalDivider />
        {uniqueRoles.size === 1 ? (
          <TableText capitalize>{projects[0].resource_name}</TableText>
        ) : (
          <TableText>Mixed</TableText>
        )}
      </Row>
    </ProjectsTooltip>
  );
};

function Admin_MemberRows({
  sortState,
  searchValue,
}: {
  sortState: SortState;
  searchValue: string;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const membersExtended = useAtomValue(
    admin_extendedUsersInOrganisationSelector,
  );
  const { showConfirm } = useConfirm();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const currentUser = useAtomValue(loggedInUserAtom);
  const { remove, leaveOrganisation } = useOrgMemberCrud(organisationId);

  const isOwnerInOrg = useAtomValue(
    ownerInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const sortedMembers = useMemo(() => {
    let allMembers = [...membersExtended];
    const field = sortState.field;
    if (sortState.order === "NONE" || !field) {
      return [...allMembers];
    }
    return [...allMembers].sort((a, b) => {
      const fieldA = a[field]?.toLowerCase();
      const fieldB = b[field]?.toLowerCase();
      if (!fieldA) return -1;
      if (!fieldB) return 1;
      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [membersExtended, sortState.field, sortState.order]);

  const membersFuse = useMemo(
    () =>
      new Fuse(sortedMembers, {
        keys: ["nickname", "email"],
        includeScore: true,
        threshold: 0.3,
      }),
    [sortedMembers],
  );

  const usersSearchResult = useMemo<Admin_MemberWithAccess[]>(() => {
    return searchValue.length > 0
      ? membersFuse.search(searchValue).map((result) => result.item)
      : sortedMembers;
  }, [membersFuse, searchValue, sortedMembers]);

  const selectedRowRef = useRef<HTMLDivElement>(null);
  useScrollSelectedIntoView(selectedRowRef, content?.type === "user");

  return (
    <>
      {usersSearchResult.map((u) => {
        const isSelected = content?.id === u.user_id;
        return (
          <Fragment key={u.user_id}>
            <TableCell
              ref={isSelected ? selectedRowRef : undefined}
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: spaceMedium,
              }}
            >
              <UserInfoOrganisationWithMemberLink userId={u.user_id} />
            </TableCell>
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <OrgRoleDropdown user={u} />
            </TableCell>

            {}
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {u.userProjects.length > 0 ? (
                <UserProjects
                  hasAccessToAllProject={false}
                  projects={u.userProjects}
                />
              ) : (
                <TableText>None</TableText>
              )}
            </TableCell>
            {}
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {u.orgResourceAccess.length ===
              components.schemas.OrganisationResources.options.length ? (
                <TableText>All library resources</TableText>
              ) : u.orgResourceAccess.length > 0 ? (
                <AccessIcons access={u.orgResourceAccess} />
              ) : (
                <TableText>None</TableText>
              )}
            </TableCell>
            {}
            <TableCell
              selected={isSelected}
              onClick={() => {
                setContent({
                  type: "user",
                  id: u.user_id,
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {u.groupMemberships.length > 0 ? (
                <GroupsTooltip
                  groupIds={u.groupMemberships.map(
                    (membership) => membership.group_id,
                  )}
                  organisationId={organisationId}
                >
                  <TableText>
                    {u.groupMemberships.length} group
                    {u.groupMemberships.length !== 1 ? "s" : ""}
                  </TableText>
                </GroupsTooltip>
              ) : (
                <TableText>None</TableText>
              )}
            </TableCell>
            {u.user_id !== currentUser?.user_id ? (
              <TableCell
                selected={isSelected}
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  boxSizing: "border-box",
                  alignItems: "center",
                }}
              >
                {u.org_role !== "owner" ? (
                  <Tooltip text="Remove user from organisation">
                    <IconBtn
                      onClick={async () => {
                        if (
                          !(await showConfirm({
                            title: "Remove user",
                            message: `Are you sure you want to remove the user ${u.nickname} (${u.email}) from your organisation? The user will lose access to all resources and projects of this organisation.`,
                            confirmButtonText: "Remove",
                          }))
                        ) {
                          return;
                        }
                        await remove(u.user_id);
                        setContent((curr) =>
                          curr?.type === "user" && curr.id === u.user_id
                            ? undefined
                            : curr,
                        );
                      }}
                      size={"1.4rem"}
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <Bin />
                    </IconBtn>
                  </Tooltip>
                ) : (
                  <p
                    style={{
                      ...typography.body,
                      color: colors.textDisabled,
                    }}
                  >
                    Owner
                  </p>
                )}
              </TableCell>
            ) : (
              <TableCell
                selected={isSelected}
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  boxSizing: "border-box",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  disabled={!isOwnerInOrg}
                  text={"Owner can not leave the organisation"}
                >
                  <Button
                    text="Leave"
                    onClick={async () => {
                      if (
                        await showConfirm({
                          title: "Leave organisation",
                          message: `Are you sure you want to leave the organisation? You will lose access to all resources and projects of this organisation.`,
                          confirmButtonText: "Leave",
                        })
                      ) {
                        leaveOrganisation();
                      }
                    }}
                    disabled={isOwnerInOrg}
                    style={{
                      marginLeft: "auto",
                    }}
                    size="small"
                  />
                </Tooltip>
              </TableCell>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

const Admin_OrganisationMembers = ErrorBoundaryWrapper(
  ({ onlyPending }: { onlyPending?: boolean }) => {
    const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
    const membersExtended = useAtomValue(
      loadable(admin_extendedUsersInOrganisationSelector),
    );

    return (
      <Column
        style={{
          padding: "2.4rem 0 8rem",
          gap: "2.4rem",
        }}
      >
        <Row>
          <SearchInput
            placeholder="Search members"
            value={searchValue}
            onChange={onSearchValueChange}
            onClear={() => setSearchValue("")}
            style={{
              width: "40rem",
            }}
          />
          <DotMenu>
            <MenuItem
              name={"Download as .csv"}
              icon={<DownloadIcon />}
              disabled={membersExtended.state !== "hasData"}
              onClick={() => {
                if (membersExtended.state !== "hasData") return;
                const members = membersExtended.data;
                let csv = "Name, Email, Role";
                members.forEach((member) => {
                  csv += `\n${member.nickname}, ${member.email}, ${member.org_role}`;
                });

                const fileName = `Vind_users.csv`;
                downloadText(csv, fileName);
              }}
            />
          </DotMenu>
        </Row>
        {!onlyPending && <Admin_MembersTable searchValue={searchValue} />}
        {onlyPending && <Admin_InvitesTable searchValue={searchValue} />}
      </Column>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

export default Admin_OrganisationMembers;
