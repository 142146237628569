import styled from "styled-components";
import { LibraryManageRole } from "../types";
import { ContentTableRow } from "components/Organisation/OrganisationRightSide/style";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { useDrop } from "react-dnd";
import { getAcceptableTypes } from "./utils";
import { FolderTreeItem } from "types/folderStructures";
import { useEffect } from "react";

interface EmptyFolderRowProps {
  collapsed: boolean;
  libraryManageRole: LibraryManageRole;
  depth: number;
  onDropItem: (folder: FolderTreeItem, itemId: string) => void;
  onDropFolder?: (
    targetFolder: FolderTreeItem,
    droppedFolder: FolderTreeItem,
  ) => void;
  folder: FolderTreeItem;
  setIsDraggingOverEmptyFolder: (isDraggingOver: boolean) => void;
}

const EmptyFolderRowStyle = styled.div<{ depth: number; isOver?: boolean }>`
  display: flex;
  width: 100%;
  margin: 0.4rem 1.5rem;
  padding-left: ${(p) => `${p.depth * 2.6}rem`};
  ${typography.body};
  color: ${colors.textDisabled};
  background-color: ${(p) => (p.isOver ? colors.surfaceHover : "transparent")};
  border-radius: 4px;
`;

const EmptyFolderRow = ({
  collapsed,
  libraryManageRole,
  depth,
  onDropItem,
  onDropFolder,
  folder,
  setIsDraggingOverEmptyFolder,
}: EmptyFolderRowProps) => {
  const acceptableTypes = getAcceptableTypes(libraryManageRole);

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: acceptableTypes,
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
      drop: (item: any, monitor) => {
        if (monitor.didDrop()) {
          return;
        }

        if ("type" in item && item.type === "FOLDER") {
          onDropFolder?.(folder, item);
        } else {
          onDropItem(folder, item.id);
        }
      },
    }),
    [folder, onDropItem, onDropFolder, acceptableTypes],
  );

  useEffect(() => {
    setIsDraggingOverEmptyFolder(isOver);
  }, [isOver, setIsDraggingOverEmptyFolder]);

  return (
    <EmptyFolderRowStyle depth={depth} ref={dropRef} isOver={isOver}>
      <ContentTableRow style={{ flex: 4 }}>Folder is empty</ContentTableRow>
      <ContentTableRow style={{ flex: 1 }}></ContentTableRow>
      {!collapsed && (
        <>
          <ContentTableRow style={{ flex: 1 }}></ContentTableRow>
          <ContentTableRow style={{ flex: 1 }}></ContentTableRow>
          {(libraryManageRole === LibraryManageRole.TURBINE ||
            libraryManageRole === LibraryManageRole.FOUNDATION) && (
            <ContentTableRow style={{ flex: 1 }}></ContentTableRow>
          )}
        </>
      )}
    </EmptyFolderRowStyle>
  );
};

export default EmptyFolderRow;
