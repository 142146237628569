import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { foundations3dLayerId } from "../../constants/projectMapView";
import { foundationScale } from "../../state/foundations";
import { FoundationType } from "../../types/foundations";
import { LAYER_DEBUG_PRINT } from "../../state/debug";
import { SimpleTurbineType } from "../../types/turbines";
import { isFloater } from "../../utils/predicates";
import { dispose } from "../../utils/three";
import {
  defaultAmbientLight,
  defaultHemisphereLight,
  defaultTowerMaterial,
} from "3d/materials";
import { createFoundationGeometries } from "3d/models";

export class ThreeDFoundations implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  turbineLonlats: [number, number][];
  turbines: SimpleTurbineType[];
  waterDepth: number;
  foundation: FoundationType | undefined;
  parkId: string;
  direction: number = 0;
  modelTransforms:
    | {
        translateX: number;
        translateY: number;
        translateZ: number;
        rotateX: number;
        rotateY: number;
        rotateZ: number;
        scale: number;
      }[]
    | undefined;
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;
  constructor(
    turbineLonlats: [number, number][],
    turbines: SimpleTurbineType[],
    waterDepth: number,
    foundation: FoundationType | undefined,
    parkId: string,
  ) {
    this.parkId = parkId;
    this.id = foundations3dLayerId + parkId + (foundation?.id ?? "");
    this.type = "custom";
    this.renderingMode = "3d";
    this.turbineLonlats = turbineLonlats;
    this.turbines = turbines;
    this.waterDepth = waterDepth;
    this.foundation = foundation;
    this.direction = 0;
  }
  onAdd(map: Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("ThreeDFoundations.onAdd");
    // parameters to ensure the model is georeferenced correctly on the map
    if (this.turbineLonlats.length === 0) return;
    if (!this.foundation) return;
    const modelOrigins = this.turbineLonlats;
    const modelAltitude = 0;
    map.setLayerZoomRange(this.id, 13, 24);

    const modelAsMercatorCoordinates = modelOrigins.map((modelOrigin) =>
      mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude),
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    this.modelTransforms = modelAsMercatorCoordinates.map(
      (modelAsMercatorCoordinate, i) => {
        const conversion =
          modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();
        const scale = isFloater(this.foundation)
          ? foundationScale({
              foundation: this.foundation,
              turbine: this.turbines[i],
            }) ?? 1
          : this.turbines[i].diameter / 240;
        return {
          translateX: modelAsMercatorCoordinate.x ?? 0,
          translateY: modelAsMercatorCoordinate.y ?? 0,
          translateZ: modelAsMercatorCoordinate.z ?? 0,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          /* Since the 3D model is in real world meters, a scale transform needs to be
           * applied since the CustomLayerInterface expects units in MercatorCoordinates.
           */
          scale: scale * conversion,
        };
      },
    );
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    const light = defaultHemisphereLight();
    light.position.set(0, 0, 1);
    this.scene.add(light);

    const ambient = defaultAmbientLight();
    ambient.name = "ambient";
    this.scene.add(ambient);

    const mat = defaultTowerMaterial();
    const geometries = createFoundationGeometries(this.foundation);
    for (const g of geometries) {
      this.scene.add(new THREE.Mesh(g, mat));
    }

    this.map = map;

    this.scene.fog = new THREE.Fog(0x023869, 0, 20);

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  renderSingle(gl: WebGLRenderingContext, matrix: number[], index: number) {
    if (
      !this.camera ||
      !this.modelTransforms ||
      !this.renderer ||
      !this.map ||
      !this.scene
    )
      return;
    const transform = this.modelTransforms[index];
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      transform.rotateX,
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      transform.rotateY,
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      ((-90 - this.direction) * Math.PI) / 180,
    );
    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        transform.translateX,
        transform.translateY,
        transform.translateZ,
      )
      .scale(
        new THREE.Vector3(transform.scale, -transform.scale, transform.scale),
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    LAYER_DEBUG_PRINT && console.time("ThreeDFoundations.render");

    gl.clear(gl.DEPTH_BUFFER_BIT);

    if (this.turbineLonlats.length > 0) {
      for (let i = 0; i < (this.modelTransforms ?? []).length; i++) {
        this.renderSingle(gl, matrix, i);
      }
    }
    LAYER_DEBUG_PRINT && console.timeEnd("ThreeDFoundations.render");
  }
}
