import React, { useCallback } from "react";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { AddSubStationMenuType } from "@constants/cabling";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column } from "components/General/Layout";
import { SkeletonText } from "components/Loading/Skeleton";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import { modalTypeOpenAtom } from "state/modal";
import { projectIdAtom } from "state/pathParams";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import {
  keepCabelGenModalOpenAtom,
  keepExportModalOpenAtom,
} from "state/cable";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { InputTitle } from "components/General/GeneralSideModals.style";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  allSubstationsForProjectAtom,
  currentSubstationIdAtomJ,
} from "state/jotai/substationType";

const AddSubstation = () => {
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();
  const [, setKeepExportModalOpen] = useAtom(keepExportModalOpenAtom);
  const [, setKeepCabelGenModalOpen] = useAtom(keepCabelGenModalOpenAtom);

  if (layoutControlActive !== AddSubStationMenuType) return null;

  return (
    <MenuFrame
      id={AddSubStationMenuType}
      title="Place substation"
      onExit={() => {
        setLayoutControlActive(undefined);
        setKeepExportModalOpen(false);
        setKeepCabelGenModalOpen(false);
      }}
    >
      <Column>
        <React.Suspense fallback={<SkeletonText />}>
          <p>Place substation by clicking in the map.</p>
          <SubstationControl />
        </React.Suspense>
      </Column>
    </MenuFrame>
  );
};

const NewSubstationValue = "addNewSubstationConfiguration";

const SubstationControl = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [currentSubstationId, setCurrentSubstationId] = useAtom(
    currentSubstationIdAtomJ,
  );
  const substationTypes = Array.from(
    useAtomValue(allSubstationsForProjectAtom).values(),
  );
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const setSelectedItemId = useSetAtom(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );

  const openNewSubstationConfig = useCallback(() => {
    setSelectedItemId(SUBSTATION_MENU_ID);
    setModalType({
      modalType: FeatureSettingsModalTypeV2,
    });
  }, [setSelectedItemId, setModalType]);

  if (!currentSubstationId || substationTypes.length === 0)
    return (
      <SimpleAlert
        text={
          "There are no substation types in this project. Open feature settings to add substation types."
        }
        type={"error"}
      ></SimpleAlert>
    );

  return (
    <Label>
      <InputTitle>Type</InputTitle>
      <Dropdown
        small
        id="substation"
        value={currentSubstationId}
        onChange={(e) => {
          setCurrentSubstationId(e.target.value);
          if (e.target.value === NewSubstationValue) {
            openNewSubstationConfig();
            return;
          }
        }}
      >
        {substationTypes.map((s) => (
          <option key={s.id} value={s.id}>
            {`${s.name}`}
          </option>
        ))}
        <option
          value={NewSubstationValue}
        >{`+ Add new substation type`}</option>
      </Dropdown>
    </Label>
  );
};

export default AddSubstation;
