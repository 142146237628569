import { z } from "zod";

const _Status = z.union([
  z.literal("started"),
  z.literal("running"),
  z.literal("failed"),
  z.literal("succeeded"),
]);

const _StartedResponse = z.object({
  job: z.string(),
  status: z.literal("started"),
});

export const _RunningResponse = z.object({
  job: z.string(),
  status: z.literal("running"),
  worker_index: z.number().nullish(),
  worker_progress: z.number().nullish(),
  worker_count: z.number().nullish(),
});
export type RunningResponse = z.output<typeof _RunningResponse>;

const _FailedResponse = z.object({
  job: z.string(),
  status: z.literal("failed"),
});

export const _SucceededResponse = z.object({
  job: z.string(),
  status: z.literal("succeeded"),
  url: z.string(),
});
export type SucceededResponse = z.output<typeof _SucceededResponse>;

export const _Response = z.union([
  _StartedResponse,
  _RunningResponse,
  _FailedResponse,
  _SucceededResponse,
]);

export const _Job = z.object({
  job: z.string(),
  project_id: z.string(),
  park_id: z.string(),
  status: _Status,
  created_at: z.coerce.date(),

  height: z.number().optional(),
  radius: z.number().optional(),
  distance_cutoff: z.number().optional(),
  start_datetime: z.coerce.date().optional(),
  end_datetime: z.coerce.date().optional(),
  url: z.string().optional(),
});

export type Job = z.infer<typeof _Job>;

export type Settings = {
  /**
   * Maximal length for cast shadows we include, in meters.
   * Common value is 1500m.
   * */
  maxShadowLength: number;
  /** Distance around the park we fetch terrain, in meters. */
  terrainDistance: number;

  /** Override the turbine height */
  overrideHeight: number;
  overrideHeightEnable: boolean;

  /** Override the turbine radius */
  overrideRadius: number;
  overrideRadiusEnable: boolean;

  startDatetime: Date;
  endDatetime: Date;
};
