import { getDistanceFromPortToPark } from "../utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { getBranchId, getParkId } from "analysis/inputs";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { parkOperationsJacket } from "../foundations/jacketInstallationTime";
import { isDefined } from "utils/predicates";
import {
  jackUpDownActivity,
  loadJacketOnDeckActivity,
  positionOnSiteActivity,
  transitActivity,
} from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  InstallationVesselType,
  isFeederBargeVessel,
  isInstallationVessel,
} from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { SubstationActivitiesConfig } from "services/operationsConfigurationService";

//Note: we assume jacket foundation for the substation

export const substationInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const substations = await get(
      substationsInParkWithTypeFamily({
        parkId,
        branchId,
      }),
    );
    const offshoreSubs = substations.filter(
      ([_, subType]) => subType.type === "offshore",
    );

    const configuration = await get(getOperationsConfiguration(id));
    const {
      installationVessel,
      feederVessel,
      installationSeason,
      weatherLimits,
      activities,
    } = configuration.ti.substation;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const installationVesselType = vesselTypes.get(installationVessel.vesselId);
    const feederVesselType = vesselTypes.get(feederVessel.vesselId);

    if (
      !installationVesselType ||
      !isInstallationVessel(installationVesselType)
    )
      return undefined;

    if (!feederVesselType || !isFeederBargeVessel(feederVesselType))
      return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const numOffshoreSubstations = offshoreSubs.length;

    const transitTimeFeeder = Math.ceil(
      portParkDistance / feederVesselType.transitSpeed,
    );

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numOffshoreSubstations; i++) {
      totalInstallTime.push(loadJacketOnDeckActivity(activities));

      totalInstallTime.push(transitActivity(transitTimeFeeder));

      parkOperationsJacket(installationVesselType, activities).forEach(
        (operation) => {
          totalInstallTime.push(operation);
        },
      );

      totalInstallTime.push(transitActivity(transitTimeFeeder));
      totalInstallTime.push(loadTopsideOnDeckActivity(activities));
      totalInstallTime.push(transitActivity(transitTimeFeeder));

      parkOperationsTopside(installationVesselType, activities).forEach(
        (operation) => {
          totalInstallTime.push(operation);
        },
      );

      totalInstallTime.push(transitActivity(transitTimeFeeder));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const loadTopsideOnDeckActivity = (activities: SubstationActivitiesConfig) => ({
  id: "Load topside on vessel",
  duration: activities.loadTopsideOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

const liftAttachTopsideActivity = (activities: SubstationActivitiesConfig) => ({
  id: "Lift and attach topside",
  mileStone: true,
  duration: activities.liftAndAttachTopside,
  useWindLim: true,
  useWaveLim: true,
});

const parkOperationsTopside = (
  vessel: InstallationVesselType,
  activities: SubstationActivitiesConfig,
) =>
  [
    positionOnSiteActivity,
    jackUpDownActivity(vessel),
    liftAttachTopsideActivity(activities),
    jackUpDownActivity(vessel),
  ].filter(isDefined);
