import { Map } from "mapbox-gl";
import {
  depthThresholdLayerId,
  slopeLayerId,
} from "../../constants/bathymetry";
import {
  canvasLineLayerId,
  canvasPointLayerId,
  canvasPolygonLayerId,
} from "../../constants/canvas";
import {
  anchorsBufferZoneLayerId,
  foundations3dLayerId,
  mooringBufferZoneLayerId,
  turbineBufferZoneLayerId,
} from "../../constants/projectMapView";
import { DepthLayerId } from "../../layers/depth";
import {
  axisLinesLayerId,
  turbineEllipsesCustomFillLayerId,
  turbineEllipsesCustomLineLayerId,
  turbineEllipsesFillLayerId,
  turbineEllipsesLineLayerId,
} from "../GenerateWindTurbines/constants";
import {
  HIDDEN_CLICK_LAYER_SUFFIX,
  anchorLayerId,
  anchorSymbolLayerId,
  cableChainLayerId,
  cableCorridorLayerId,
  cableCorridorOutlineLayerId,
  cableFreeSectorLayerId,
  cableFreeSectorOutlineLayerId,
  cableLayerId,
  cablePartitionLayerId,
  depthProfilePointLayerPrefix,
  divisionLayerId,
  divisionOutlineLayerId,
  existingTurbineLayerId,
  existingTurbineSymbolLayerId,
  exportCableLandfallLayerId,
  exportCableLandfallSegmentLayerId,
  exportCableLayerId,
  hiddenLineClickPaint,
  hiddenPointClickPaint,
  mooringLineLayerId,
  geotiffNoiseLayerId,
  parkLayerFillId,
  parkLayerOutlineId,
  substationLayerId,
  threedCableLayerId,
  threedMooringLayerId,
  threedTurbineLayerId,
  turbineDiameterLayerId,
  turbineLayerId,
  turbineSymbolLayerId,
  viewshedLayerId,
  noiseBoundaryLayerId,
  geotiffShadowFlickerLayerId,
} from "./constants";
import { measureLineLayerId, measurePointLayerId } from "./constants";
import { noiseLayerId } from "./constants";
import { undefMap } from "utils/utils";

// Mapbox studio layers, a bit of a hack to get wms behind map labels
export const mapboxBridgeRail = "bridge-rail";
const mapboxWaterwayLabel = "waterway-label";

export const geotifBathymetryLayerId = "vind:layer:geotiff-bathymetry-layer";
export const geotifImageLayerId = "vind:layer:geotiff-image-layer";

const turbineWakeSymbolLayerId = "vind:layer:turbine-wake-loss-layer";
export const parkWindRoseLayerId = "vind:layer:park-wind-rose-layer";
export const wakePaddedParkLayerId = "vind:layer:wake-padded-park-layer";

export const externalWFSAnchorId = "vind:layer:external-wfs-anchor-id";
export const externalWMSAnchorId = "vind:layer:external-wms-anchor-id";
export const externalWMTSAnchorId = "vind:layer:external-wmts-anchor-id";
export const externalXYZAnchorId = "vind:layer:external-xyz-anchor-id";
const openSeaMapAnchorId = "open-sea-map-anchor-id";

export const filterLayerId = "vind:layer:filter-layer-id";
export const filterOnshoreLayerId = "vind:layer:filter-onshore-layer-id";
export const nora3LayerId = "vind:layer:nora3-points-layer";
export const windPointsLayerId = "vind:layer:wind-points-layer";
export const wavePointsLayerId = "vind:layer:wave-points-layer";

export const siteLocatorLayerId = "vind:layer:site-locator:grid";
export const siteLocatorSourceId = "vind:source:site-locator:grid";
export const siteLocatorHullLayerId = "vind:layer:site-locator:hulls";
export const siteLocatorHullSourceId = "vind:source:site-locator:hulls";

export const waterAnalysisLayerId = "waterAnalysisLayerId";

export const bathymetryContourLayerPrefix = "vind:layer:bathymetry-contour:";

export const existingTurbinesExternalLayerId =
  "vind:layer:existing-turbines-external";

/** Farther down this list means higher up in the render stack. */
const layerOrder = [
  // "water",

  openSeaMapAnchorId,

  slopeLayerId,
  depthThresholdLayerId,
  waterAnalysisLayerId,

  externalWMSAnchorId,
  externalWMTSAnchorId,
  externalXYZAnchorId,

  mapboxBridgeRail,
  mapboxWaterwayLabel,

  DepthLayerId,
  filterOnshoreLayerId,
  filterLayerId,

  bathymetryContourLayerPrefix,

  externalWFSAnchorId,
  windPointsLayerId,
  wavePointsLayerId,
  nora3LayerId,
  wakePaddedParkLayerId,
  noiseLayerId,
  noiseBoundaryLayerId,

  existingTurbinesExternalLayerId,

  geotifImageLayerId,
  geotifBathymetryLayerId,
  geotiffNoiseLayerId,
  geotiffShadowFlickerLayerId,
  viewshedLayerId,

  canvasPolygonLayerId,
  canvasPointLayerId,
  canvasLineLayerId,

  parkLayerFillId,

  divisionLayerId,

  cableCorridorLayerId,

  anchorsBufferZoneLayerId,
  mooringBufferZoneLayerId,
  turbineBufferZoneLayerId,

  cableFreeSectorLayerId,
  cableFreeSectorOutlineLayerId,

  cablePartitionLayerId,
  cableChainLayerId,

  existingTurbineLayerId,
  existingTurbineSymbolLayerId,

  parkLayerOutlineId,
  divisionOutlineLayerId,
  cableCorridorOutlineLayerId,

  cableLayerId,
  exportCableLayerId,
  exportCableLandfallSegmentLayerId,
  exportCableLandfallLayerId,
  substationLayerId,

  mooringLineLayerId,
  anchorLayerId,
  anchorSymbolLayerId,

  foundations3dLayerId,
  parkWindRoseLayerId,

  threedTurbineLayerId,

  turbineDiameterLayerId,
  turbineLayerId,
  turbineWakeSymbolLayerId,
  turbineSymbolLayerId,

  turbineEllipsesCustomFillLayerId,
  turbineEllipsesCustomLineLayerId,
  turbineEllipsesFillLayerId,
  turbineEllipsesLineLayerId,

  depthProfilePointLayerPrefix,

  axisLinesLayerId,

  siteLocatorLayerId,
  siteLocatorHullLayerId,

  measureLineLayerId,
  measurePointLayerId,

  threedMooringLayerId,
  threedCableLayerId,

  "last",
];

export const getBeforeLayer = (map: Map, id: string): string | undefined => {
  let i = layerOrder.findIndex((layer) => id === layer);
  if (i === -1) i = layerOrder.findIndex((layer) => id.startsWith(layer));
  const beforeId = layerOrder.slice(i + 1).find((layer) => map.getLayer(layer));
  return beforeId;
};

export const getHiddenLargeLineClickLayer = (
  layerId: string,
  sourceId: string,
  minzoom?: number,
) =>
  ({
    id: layerId + HIDDEN_CLICK_LAYER_SUFFIX,
    type: "line",
    source: sourceId,
    paint: hiddenLineClickPaint,
    ...(typeof minzoom === "number" ? { minzoom } : {}),
  }) as mapboxgl.LineLayer;

export const getHiddenLargeCircleClickLayer = (
  layerId: string,
  sourceId: string,
) =>
  ({
    id: layerId + HIDDEN_CLICK_LAYER_SUFFIX,
    type: "circle",
    source: sourceId,
    paint: hiddenPointClickPaint,
  }) as mapboxgl.CircleLayer;

/**
 * Adds the layer to the map, above the layer returned by `getBeforeLayer`.
 */
export const addLayer = (map: Map, obj: Parameters<Map["addLayer"]>[0]) => {
  map.addLayer(obj, getBeforeLayer(map, obj.id));
};

/**
 * The features currently with the feature state `hover`.  We store this here
 * becuase Mapbox has to API to query this, and we'd like to be able to clear
 * the state from all features without looping over every single feature ever.
 */
let hover: { source: string; id: string }[] = [];

/**
 * Same as `hover`, but for `selected` state.
 */
let _selected: { source: string; id: string }[] = [];

// NOTE: we don't need to track `inFocus` in the same way, because `inFocus`
// applies to all features in a park.

/**
 * Set hover for a single feature. This clears hover for all other features.
 */
export const setHover = (map: Map, source: string, featureId: string) => {
  if (!map || !map.getSource(source)) return;
  clearHover(map);
  map.setFeatureState({ source, id: featureId }, { hover: true });
  hover = [{ source, id: featureId }];
};

/**
 * Set hover for multiple features. This clears hover for all other features.
 */
export const setHoverMultiple = (
  map: Map,
  features: { source: string; id: string }[],
) => {
  if (!map) return;
  clearHover(map);
  features.forEach((item) => {
    if (!map.getSource(item.source)) return;
    map.setFeatureState(item, { hover: true });
  });
  hover = features;
};

/**
 * Remove hover for a single feature.
 */
export const removeHover = (
  map: Map,
  source: string,
  featureId: string | undefined,
): (() => void) | undefined => {
  if (!map || featureId === undefined || !map.getSource(source)) return;
  const hoverItem = hover?.find((item) => item.id === featureId);
  if (hoverItem) {
    if ("hover" in (map.getFeatureState({ source, id: featureId }) ?? {}))
      map.removeFeatureState({ source, id: featureId }, "hover");
    hover = hover.filter((item) => item.id !== featureId);
  }
};

/**
 * Clears the hover state for all features.
 */
export const clearHover = (map: Map) => {
  if (!map || hover.length === 0) return;
  hover.forEach((item) => {
    if (!map.getSource(item.source)) return;
    if ("hover" in (map.getFeatureState(item) ?? {}))
      map.removeFeatureState(item, "hover");
  });
  hover = [];
};

export const setAllSelected = (
  map: Map,
  items: { source: string; featureId: string }[],
) => {
  if (!map) return;
  clearSelected(map);
  for (const { source, featureId } of items) {
    const selected = { source, id: featureId };
    if (!map.getSource(selected.source)) continue;
    map.setFeatureState(selected, { selected: true });
    _selected.push(selected);
  }
};

const clearSelected = (map: Map) => {
  for (const selected of _selected) {
    if (!map.getSource(selected.source)) continue;
    if ("selected" in (map.getFeatureState(selected) ?? {}))
      map.removeFeatureState(selected, "selected");
  }
  _selected = [];
};

export const setInFocus = (map: Map, source: string, featureId: string) => {
  if (!map || !map.getSource(source)) return;
  const feature = { source, id: featureId };
  map.setFeatureState(feature, { inFocus: true });
};

export const removeInFocus = (map: Map, source: string, featureId: string) => {
  const feature = { source, id: featureId };
  if (!map || !feature || !map.getSource(feature.source)) return;
  if ("inFocus" in (map.getFeatureState(feature) ?? {})) {
    map.removeFeatureState(feature, "inFocus");
  }
};

/**
 * `0xffff` is Mapbox' upper limit for code points in labels.
 */
const codepointFilter = (str: string): string => {
  let ret = "";
  for (const codePoint of str) {
    const num = codePoint.codePointAt(0);
    if (num === undefined || 0xffff < num) continue;
    ret += codePoint;
  }
  return ret;
};

/**
 * Look through `.properties.name` and remove any codepoints that are not
 * too large for Mapbox to handle.  This is a workaround for a Mapbox problem
 * where instead of not showing the graphemes that are too large, it just
 * hides the entire label of a feature if it contains any such graphemes.
 */
export const removeCodepointsFromFeatures = (features: GeoJSON.Feature[]) =>
  features.map((f) => ({
    ...f,
    properties: {
      ...f.properties,
      name: undefMap(f.properties?.name, codepointFilter),
    },
  }));
