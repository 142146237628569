import React, { useMemo } from "react";
import { useAtomValue } from "jotai";
import { getAnalysisWindStats } from "analysis/output";
import { SimpleWindRose } from "state/windStatistics";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";

const WindRose = React.lazy(
  () => import("components/WindWaveMeasurementInfoModal/WindRose"),
);

const WindDetails = () => {
  const { triggerId } = useDashboardContext();
  const stats = useAtomValue(getAnalysisWindStats(triggerId));
  const windRose = useMemo(() => {
    if (!stats) {
      return;
    }

    return {
      directions: stats.directionBins.map((d, i) => ({
        direction: d,
        probability: stats.directionProbabilities[i],
        meanSpeed: stats.meanSpeedPerDirection[i],
      })),
      longitude: stats.longitude,
      latitude: stats.latitude,
      height: stats.height,
      alpha: stats.meanAlpha,
      speeds: stats.speedBins,
      speedProbabilities: stats.speedBinProbabilities,
    } satisfies SimpleWindRose;
  }, [stats]);

  if (!windRose) return null;

  return <WindRose windRose={windRose} />;
};

export const WindRoseWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Wind rose"
      id="Wind rose"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <WindDetails />
    </SafeCard>
  );
};
