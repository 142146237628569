import { useEffect } from "react";
import { ValidationWarningTypes } from "./utils";
import { existingTurbineSourceId } from "components/Mapbox/constants";
import { existingTurbinesFamily } from "state/jotai/existingTurbine";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";

export const existingTurbinesWithoutPowerWarningAtom = atom<
  Promise<
    | ValidationWarning<ValidationWarningTypes.ExistingTurbinesWithoutPower>
    | undefined
  >
>(async (get) => {
  const existing = await get(existingTurbinesFamily({ branchId: undefined }));
  const incomplete = existing.filter((t) => !t.properties?.power);

  if (incomplete.length === 0) return;
  return {
    type: ValidationWarningTypes.ExistingTurbinesWithoutPower,
    featureIds: incomplete.map((t) => t.id),
  };
});

export const ExistingTurbinesWithoutPowerJotai = ({
  map,
  source = existingTurbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const warning = useAtomValue(existingTurbinesWithoutPowerWarningAtom);

  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { error: true });

    return () => {
      if (map.getSource(source))
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "error");
    };
  }, [warning, map, source]);

  return null;
};
