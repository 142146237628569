import { ProdId } from "analysis/inputs";
import { getAnalysisResponse } from "analysis/output";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { PropsWithChildren } from "react";

/**
 * Show a {@link SimpleAlert} for the given {@link ProdId}, if the analysis errors.
 * If not, return `null.
 */
export default function AnalysisErrorAlert({
  pid,
}: PropsWithChildren<{ pid: ProdId }>) {
  const analysis = useAtomValue(loadable(getAnalysisResponse(pid)));
  let reason = undefined;
  if (analysis.state === "hasError") {
    if (analysis.error instanceof Error)
      reason = String(analysis.error.message);
    else reason = String(analysis.error);
  } else if (analysis.state === "hasData") {
    const d = analysis.data;
    if (d.status === "failed" || d.status === "stopped")
      reason = d.reason ?? "Unknown reason";
  }

  if (!reason) return null;
  return <SimpleAlert title="Analysis stopped" text={reason} />;
}
