import React from "react";
import { HeaderProps } from "./types";
import { HeaderContainer } from "./styles";
import CloseIcon from "@icons/24/Close.svg?react";
import { IconBtn } from "components/General/Icons";

const SettingsModalHeader: React.FC<HeaderProps> = ({ title, onClose }) => {
  return (
    <HeaderContainer>
      <h2>{title}</h2>
      <IconBtn size="1.4rem" onClick={onClose}>
        <CloseIcon />
      </IconBtn>
    </HeaderContainer>
  );
};

export default SettingsModalHeader;
