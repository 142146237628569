import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { PROCUREMENT_COST_ITEMS } from "./ProcurementCostsModal";
import TurbineProcurementCosts from "./Turbine/TurbineProcurementCosts";
import InterArrayCableProcurementCosts from "./InterArrayCable/InterArrayCableProcurementCosts";
import useOrgProcurementCostsCrud from "./useProcurementCostCrud";
import { InitialsImageRound } from "components/UserImage/UserImageRound";
import { Suspense, useState, useCallback } from "react";
import {
  ProcurementCost,
  ProcurementCostComponentType,
  FoundationProcurementCost,
  Regions,
} from "services/procurementCostService";
import { TablePlaceholder } from "../../../OrganisationRightSide/content/shared/TablePlaceholder";
import { ContentWrapper } from "../../../OrganisationRightSide/style";
import { CostUnit } from "types/financial";
import { ScrollBody } from "hooks/useShowScrollShadow";
import FoundationProcurementCosts from "./Foundation/FoundationProcurementCosts";
import ExportCableProcurementCosts from "./ExportCable/ExportCableProcurementCosts";

export type Costs = {
  [key in Regions | "supplyCost"]?: {
    value: number | undefined;
    unit: CostUnit;
  };
};

export type TableData = {
  id: string;
  name: { value: string; unit: CostUnit | "" };
  extraValue?: string | number;
  costs: Costs;
};

export default function ProcurementCostsContent({
  componentId,
}: {
  componentId: ProcurementCostComponentType;
}) {
  const [foundationTableData, setFoundationTableData] = useState<
    FoundationProcurementCost[]
  >([]);
  const [localFoundationTableData, setLocalFoundationTableData] = useState<
    FoundationProcurementCost[]
  >([]);
  const [tableData, setTableData] = useState<TableData[]>([]);

  const { update, isSaving } = useOrgProcurementCostsCrud();

  const [localTableData, setLocalTableData] = useState<TableData[]>([]);
  const [dataToBeSaved, setDataToBeSaved] = useState<ProcurementCost[]>([]);
  const [changedRows, setChangedRows] = useState<Set<string>>(new Set());

  const save = useCallback(
    async (dataToSave: ProcurementCost[]) => {
      if (!componentId || !dataToSave.length) return;

      try {
        await update(componentId, dataToSave);
        if (componentId === "foundation") {
          setFoundationTableData(localFoundationTableData);
        } else {
          setTableData(localTableData);
        }
        setDataToBeSaved([]);
        setChangedRows(new Set());
      } catch (error) {
        console.error("Failed to update procurement costs:", error);
      }
    },
    [
      componentId,
      update,
      setFoundationTableData,
      setTableData,
      localFoundationTableData,
      localTableData,
    ],
  );

  const activeComponentType = PROCUREMENT_COST_ITEMS.find(
    (item) => item.id === componentId,
  );
  const hasUnsavedChanges =
    (componentId === "foundation" &&
      localFoundationTableData !== foundationTableData) ||
    (componentId !== "foundation" && localTableData !== tableData);

  const renderProcurementCosts = () => {
    switch (componentId) {
      case "turbine":
        return (
          <TurbineProcurementCosts
            localTableData={localTableData}
            setLocalTableData={setLocalTableData}
            setTableData={setTableData}
            setDataToBeSaved={setDataToBeSaved}
            setChangedRows={setChangedRows}
            changedRows={changedRows}
          />
        );
      case "foundation":
        return (
          <FoundationProcurementCosts
            localTableData={localFoundationTableData}
            setLocalTableData={setLocalFoundationTableData}
            setTableData={setFoundationTableData}
            setDataToBeSaved={setDataToBeSaved}
            save={save}
            isSaving={isSaving}
          />
        );
      case "interArrayCable":
        return (
          <InterArrayCableProcurementCosts
            localTableData={localTableData}
            setLocalTableData={setLocalTableData}
            setTableData={setTableData}
            setDataToBeSaved={setDataToBeSaved}
            setChangedRows={setChangedRows}
            changedRows={changedRows}
          />
        );
      case "exportCable":
        return (
          <ExportCableProcurementCosts
            localTableData={localTableData}
            setLocalTableData={setLocalTableData}
            setTableData={setTableData}
            setDataToBeSaved={setDataToBeSaved}
            setChangedRows={setChangedRows}
            changedRows={changedRows}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContentWrapper>
      <Suspense fallback={<TablePlaceholder />}>
        <Row style={{ gap: "2.4rem", alignItems: "center" }}>
          <InitialsImageRound
            name={activeComponentType?.title ?? ""}
            size={8}
          />
          <Column style={{ width: "100%" }}>
            <h3 style={{ margin: 0 }}>{activeComponentType?.title}</h3>
          </Column>
          <Column>
            {hasUnsavedChanges && (
              <Row>
                <Button
                  text="Cancel"
                  buttonType="secondary"
                  onClick={() => {
                    if (componentId === "foundation") {
                      setLocalFoundationTableData(foundationTableData);
                    } else {
                      setLocalTableData(tableData);
                    }
                  }}
                  style={{ marginLeft: "auto" }}
                />
                <Button
                  text="Save changes"
                  onClick={() => save(dataToBeSaved)}
                  disabled={isSaving}
                />
              </Row>
            )}
          </Column>
        </Row>
        <ScrollBody>{renderProcurementCosts()}</ScrollBody>
      </Suspense>
    </ContentWrapper>
  );
}
