import React, {
  ErrorInfo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { scream } from "../../../../utils/sentry";
import { ProjectFeature } from "../../../../types/feature";
import { MultiPolygon, Polygon } from "geojson";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { mapboxAccessToken } from "components/MapNative/constants";
import { lonLatToTile } from "types/tile";
import Bin from "@icons/24/Bin.svg?react";
import * as turf from "@turf/turf";
import {
  OverlineText,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { Column, Row } from "components/General/Layout";
import { SkeletonText } from "components/Loading/Skeleton";
import { ColoredGrid } from "components/General/Form";
import { SourceListWrapper } from "./DepthAnalysis";
import {
  downloadBlob,
  promiseWorker,
  roundToDecimal,
  typedWorker,
} from "utils/utils";
import { AddAnalysisButton } from "./BathymetryAnalysis";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import { isDefined } from "utils/predicates";
import Button from "components/General/Button";
import { RangeSlider } from "components/General/Slider";
import { spaceMedium, spaceSmall, spaceTiny } from "styles/space";
import DownloadIcon from "@icons/24/Download.svg?react";
import { mapAtom } from "state/map";
import MapPolygon from "../../../MapFeatures/Polygon";
import { waterAnalysisLayerId } from "components/Mapbox/utils";
import {
  FillPaint,
  LinePaint,
  MapboxGeoJSONFeature,
  SymbolLayer,
} from "mapbox-gl";
import { displayLabelPropertyName } from "@constants/canvas";
import { ExternalSelectionItem } from "state/externalLayerSelection";
import { z } from "zod";
import { IconREMSize } from "styles/typography";
import stc from "string-to-color";
import { MapColorIndicator } from "components/General/MapColorIndicator";
import DynamicSelectOption from "components/DynamicSelectOption/DynamicSelectOption";
import { TopRightModeActiveAtom } from "./state";
import { listOfPolygonsToMultipolygon } from "utils/turf";
import { AREA_TO_KM_SQ } from "@constants/misc";
import { useToast } from "hooks/useToast";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import * as Sentry from "@sentry/react";

const waterAnalysisSymbols: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "{between}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": 0.6,
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const waterPolygonPaint: FillPaint = {
  "fill-color": ["string", ["get", "color"], "#5100c2"],
  "fill-opacity": 0.5,
};

const waterPolygonLinePaint: LinePaint = {
  "line-opacity": 1,
};
type ErrorProps = {
  children: ReactNode;
};

const waterSourceId = "waterSourceId";

const getWaterRasterFromMapboxFamily = atomFamily(
  ({ zoom, polygon }: { zoom: number; polygon: ProjectFeature<Polygon> }) =>
    atom<Promise<[ProjectFeature<Polygon>[], number, number, number]>>(
      async () => {
        const bbox = turf.bbox(polygon).slice(0, 4) as [
          number,
          number,
          number,
          number,
        ];
        const swLonLat = lonLatToTile(bbox[0], bbox[1], zoom);
        const neLonLat = lonLatToTile(bbox[2], bbox[3], zoom);

        const yMin = neLonLat.y;
        const yMax = swLonLat.y;
        const xMin = swLonLat.x;
        const xMax = neLonLat.x;

        const textureTilesToRequest = [];
        for (let y = yMin; y <= yMax; y++) {
          for (let x = xMin; x <= xMax; x++) {
            textureTilesToRequest.push(
              (async () => {
                const res = await fetch(
                  `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2/${zoom}/${x}/${y}.vector.pbf?access_token=${mapboxAccessToken}`,
                );
                if (!res.ok) return;
                const blob = await res.blob();
                return new File([blob], `${zoom}-${x}-${y}.pbf`);
              })(),
            );
          }
        }

        Sentry.addBreadcrumb({
          category: "mapbox streets tiles",
          message: "Requested tile",
          level: "info",
          data: {
            textureTilesToRequest,
          },
        });

        const pbfFiles = (await Promise.all(textureTilesToRequest)).filter(
          isDefined,
        );

        const waterFeaturesFromPbfFilesWorker = typedWorker<
          [ProjectFeature<Polygon>, File[]],
          [ProjectFeature<Polygon>[], number, number, number]
        >(
          new Worker(
            new URL("./waterFeaturesFromPbfFilesWorker.ts", import.meta.url),
            {
              type: "module",
            },
          ),
        );
        const [waterPolygonFeatures, minArea, maxArea, totalArea] =
          await promiseWorker(
            waterFeaturesFromPbfFilesWorker,
            [polygon, pbfFiles],
            "WaterAreaAnalysis",
          );
        waterFeaturesFromPbfFilesWorker.terminate();

        return [waterPolygonFeatures, minArea, maxArea, totalArea];
      },
    ),
);

const currentThresholdAtom = atomLocalStorage<undefined | [number, number]>(
  "vind:water-analysis:range",
  undefined,
  z.tuple([z.number(), z.number()]).optional(),
);

class WaterErrorBoundary extends React.Component<
  ErrorProps,
  { hasError: boolean; error?: Error }
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    scream(error, { message: "WaterErrorBoundary caught", errorInfo });
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <SimpleAlert
            text={"Something went wrong when running water analysis..."}
            type={"error"}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

const WaterAnalysisInner = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  const map = useAtomValue(mapAtom);
  const { info } = useToast();
  const [waterPolygonFeatures, minArea, maxArea, totalArea] = useAtomValue(
    getWaterRasterFromMapboxFamily({ polygon: canvasFeature, zoom: 12 }),
  );
  const [thresholdRegions, setThresholdRegions] = useState<
    ProjectFeature<Polygon | MultiPolygon>[]
  >([]);

  const [threshold, setThreshold] = useAtom(currentThresholdAtom);
  const [thresholds, setThresholds] = useState<[number, number][]>([]);

  const maxAreaAvailable = Math.ceil(maxArea);
  const minAreaAvailable = Math.floor(minArea);

  const [selections, setSelections] = useState<ExternalSelectionItem[]>([]);

  const onClick = useCallback(
    (features: MapboxGeoJSONFeature[]) => {
      if (features.length === 0) {
        setSelections([]);
      }
      setSelections([{ ...features[0], properties: { id: features[0].id } }]);
    },
    [setSelections],
  );

  const selectedIds: string[] = useMemo(
    () => selections.filter(isDefined).map((s) => String(s.id)),
    [selections],
  );

  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);
  const trackEvent = useTrackEvent();

  if (totalArea === 0) return <div>No water found within the park polygon</div>;

  return (
    <>
      {map && (
        <MapPolygon
          features={thresholdRegions}
          sourceId={waterSourceId}
          layerId={waterAnalysisLayerId}
          symbols={waterAnalysisSymbols}
          map={map}
          paint={waterPolygonPaint}
          linePaint={waterPolygonLinePaint}
          selectedIds={selectedIds}
          onClickCallback={onClick}
        />
      )}
      {selections.length !== 0 && (
        <DynamicSelectOption
          selections={selections}
          addToFolderName={`Water analysis from ${canvasFeature.properties.name}`}
          callback={() => setTopRightModeActive(undefined)}
        />
      )}
      <ColoredGrid>
        <ResultValue>Area</ResultValue>
        <p>
          <strong>{roundToDecimal(totalArea, 2)} </strong>km²
        </p>

        <Row>
          <ResultValue>Source</ResultValue>
        </Row>
        <SourceListWrapper>
          <ResultValue>
            <a
              href={
                "https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8/"
              }
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Mapbox streets v8 tileset
            </a>
          </ResultValue>
        </SourceListWrapper>
      </ColoredGrid>
      <Button
        size="small"
        text="Water"
        icon={<DownloadIcon />}
        buttonType="secondary"
        onClick={() => {
          const content = JSON.stringify({
            type: "FeatureCollection",
            features: waterPolygonFeatures,
          });
          const blob = new Blob([content]);
          const name = `${canvasFeature.properties.name ?? canvasFeature.id}-water_features.geojson`;
          const file = new File([blob], name, { type: "application/geo+json" });
          downloadBlob(file, file.name);
        }}
      />

      <OverlineText style={{ paddingTop: "1.6rem", paddingBottom: "0" }}>
        Thresholds
      </OverlineText>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          gap: spaceMedium,
        }}
      >
        <>
          <RangeSlider
            min={minAreaAvailable}
            max={maxAreaAvailable}
            values={threshold ?? [minAreaAvailable, maxAreaAvailable]}
            inside
            labels
            step={0.1}
            renderLabel={(v) => `${roundToDecimal(v, 1)} km²`}
            onChange={function (f: [number, number]): void {
              setThreshold(f);
            }}
            style={{ flex: 1 }}
          />
          <HighlightStep tourId="onshore-intro-tour" stepId="addWaterbodies">
            <Button
              text="Add"
              size="small"
              onClick={() => {
                trackEvent("addedWaterbodies");
                if (!threshold) return;
                const featuresWithinThreshold = waterPolygonFeatures.filter(
                  (f) => {
                    const area = turf.area(f) / AREA_TO_KM_SQ;
                    return area >= threshold[0] && area <= threshold[1];
                  },
                );
                if (featuresWithinThreshold.length === 0) {
                  info(
                    "This range does not contain any features, so nothing was added",
                  );
                  return;
                }
                const unionedFeatures = listOfPolygonsToMultipolygon(
                  featuresWithinThreshold,
                ) as ProjectFeature<MultiPolygon>;
                setThresholds((t) => [...t, threshold]);
                setThresholdRegions((r) => [
                  ...r,
                  {
                    ...unionedFeatures,
                    properties: {
                      ...unionedFeatures.properties,
                      between: `${threshold[0]}km² to ${threshold[1]}km²`,
                      color: stc(threshold.toString()),
                    },
                  },
                ]);
              }}
            />
          </HighlightStep>
        </>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 1fr 1fr 1fr 1fr",
          columnGap: spaceSmall,
          rowGap: spaceTiny,
        }}
      >
        {thresholds?.map((threshold, i) => {
          const [from, to] = threshold;
          const region = thresholdRegions[i];
          const regionArea = turf.area(region) / AREA_TO_KM_SQ;
          const percentageAllWater = (regionArea / totalArea) * 100;
          return (
            <React.Fragment key={`${from}-${to}`}>
              <Row>
                <MapColorIndicator
                  opacity={1}
                  color={stc(threshold.toString())}
                />
                <p>
                  {Math.abs(from)}km² to {Math.abs(to)}km²
                </p>
              </Row>
              <p style={{ textAlign: "end" }}>
                {roundToDecimal(regionArea / AREA_TO_KM_SQ, 1)}km²
              </p>
              <p style={{ textAlign: "end" }}>
                {Math.round(percentageAllWater)}%
              </p>
              <Button
                buttonType="secondary"
                icon={
                  <IconREMSize width={1.4} height={1.4}>
                    <Bin title={"Delete threshold"} />
                  </IconREMSize>
                }
                size="small"
                onClick={() => {
                  setThresholdRegions((r) => r.filter((_, j) => j !== i));
                  setThresholds((t) => t.filter((_, j) => j !== i));
                }}
              />

              <AddAnalysisButton
                region={
                  thresholdRegions[i] as ProjectFeature<Polygon | MultiPolygon>
                }
                parkId={
                  canvasFeature.properties.type === PARK_PROPERTY_TYPE
                    ? canvasFeature.id
                    : undefined
                }
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

const WaterAreaAnalysis = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  return (
    <WaterErrorBoundary>
      <SubtitleWithLine text={"Water"} />
      <Column>
        <React.Suspense
          fallback={<SkeletonText text="Loading water vectors" />}
        >
          <WaterAnalysisInner canvasFeature={canvasFeature} />
        </React.Suspense>
      </Column>
    </WaterErrorBoundary>
  );
};

export default WaterAreaAnalysis;
