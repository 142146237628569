import { costId } from "../amounts/costIds";
import { amountId } from "../amounts/amountIds";
import { atom } from "jotai";
import {
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
  isSubstationOverrideCost,
  isSubstationParameterCost,
} from "services/costService";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { _CostUnit, Cost, CostUnit, unitToAmountUnit } from "types/financial";
import { atomFamily } from "utils/jotai";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { getBranchId, getParkId } from "analysis/inputs";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { exportCablesInParkWithTypeFamily } from "state/jotai/exportCable";

export const substationCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const configuration = await get(getConfiguration(id));

    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );

    const {
      capex: { custom, fixed, installation },
    } = configuration;

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Substation)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.Substation,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] =
      custom
        .filter((c) => c.category === CostType.Substation)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) => ({
          ...custom,
          name: `${custom.name}`,
          id: costId({
            category: CostType.Substation,
            costId: custom.id,
            featureTypeId: CostType.Substation,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
          }),
        })) ?? [];

    let installationCosts: Cost[] = [];

    if (isOperationsCost(installation.substation)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel, feederVessel } =
        operationsConfiguration.ti.substation;

      const vesselTypes = await get(vesselTypesFamily(undefined));

      installationCosts = [installationVessel, feederVessel].map(
        ({ dayRate, vesselId }) => {
          const { cost, unit: costUnit } = dayRate;
          const unit = _CostUnit.parse(costUnit);
          const name = vesselTypes.get(vesselId)?.name ?? "";

          return {
            id: `substation_installation_${vesselId}`,
            amountId: amountId({
              unit: unitToAmountUnit[unit],
              category: CostType.Substation,
              featureTypeId: vesselId,
            }),
            category: CostType.Substation,
            name,
            cost,
            unit,
          };
        },
      );
    } else if (isOperationsOverrideCost(installation.substation)) {
      const { cost, unit, confidenceLevel } = installation.substation;
      installationCosts.push({
        id: `substation_installation`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Substation,
        }),
        category: CostType.Substation,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    let fixedCosts: Cost[] = [];
    const substationCosts = fixed.substation;

    if (isSubstationOverrideCost(substationCosts)) {
      const { cost, unit, confidenceLevel } = substationCosts;

      fixedCosts.push({
        id: "substation",
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Substation,
        }),
        category: CostType.Substation,
        name: "Substations",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    } else if (isSubstationParameterCost(substationCosts)) {
      const exportCablesUsedInPark = await get(
        exportCablesInParkWithTypeFamily({ parkId, branchId }),
      );

      const defaultCableTypeIfNotConnected = "HVAC";

      substations.forEach(([sub, type]) => {
        let unit: string;
        let cost: number;
        const connectionType =
          exportCablesUsedInPark.find(
            (cable) => cable[0].properties.fromSubstationId === sub.id,
          )?.[1].exportCableType ??
          exportCablesUsedInPark.find(
            (cable) => cable[0].properties.toSubstationId === sub.id,
          )?.[1].exportCableType ??
          defaultCableTypeIfNotConnected;

        if (type.type === "onshore") {
          ({ unit, cost } = substationCosts[connectionType].onshore);
        } else {
          ({ unit, cost } = substationCosts[connectionType].offshore);
        }

        if (unit === CostUnit.millionEuroPerUnit) {
          fixedCosts.push({
            id: costId({
              category: CostType.Substation,
              costId: type.id,
              featureTypeId: type.id,
            }),
            amountId: amountId({
              unit: unitToAmountUnit[_CostUnit.parse(unit)],
              category: CostType.Substation,
              featureTypeId: type.id,
            }),
            category: CostType.Substation,
            name: `${type.name}`,
            cost,
            unit: _CostUnit.parse(unit),
            confidenceLevel: undefined,
          });
        } else {
          fixedCosts.push({
            id: costId({
              category: CostType.Substation,
              costId: type.id,
              featureTypeId: type.id,
            }),
            amountId: amountId({
              unit: unitToAmountUnit[_CostUnit.parse(unit)],
              category: CostType.Substation,
              featureTypeId: sub.id,
            }),
            category: CostType.Substation,
            name: `${type.name} (${sub.properties.name})`,
            cost,
            unit: _CostUnit.parse(unit),
            confidenceLevel: undefined,
          });
        }
      });
    }

    return [...fixedCosts, ...customCosts, ...flatCosts, ...installationCosts];
  }),
);
