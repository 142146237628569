import { branchIdAtom, parkIdAtom } from "state/pathParams";
import { useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import { min } from "utils/utils";
import { isDefined } from "utils/predicates";
import useSelectionInMap from "hooks/useSelectionInMap";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { useAtomValue } from "jotai";
import { substationsInParkFamily } from "state/jotai/substation";

const SNAP_DISTANCE_M = 50;

/**
 * NOTE: This is a pretty ugly solution to this problem. Better would be to
 * have post-create and post-edit hooks to features, but we don't have that.
 */
export const useSnapExportCableToChangedSubstations = () => {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const { currentSelectionArray } = useSelectionInMap();
  const alreadyWarnedForExportCableIds = useRef<string[]>([]);

  const exportCables = useAtomValue(
    exportCablesInParkFamily({
      branchId,
      parkId,
    }),
  );

  const substations = useAtomValue(
    substationsInParkFamily({
      branchId,
      parkId,
    }),
  );

  const { update } = useProjectElementsCrud();

  useEffect(() => {
    const selectedSubstations = currentSelectionArray
      .map((id) => substations.find((s) => s.id === id))
      .filter(isDefined);

    // If we have an export cable that lacks either a onshore or offshore substation
    // that it is connected to, see if we can connect the endpoints to a substation.

    const subIds = new Set(substations.map((s) => s.id));

    const looseCables = exportCables.filter(
      (ec) =>
        !subIds.has(ec.properties.fromSubstationId ?? "") ||
        !subIds.has(ec.properties.toSubstationId ?? ""),
    );

    for (const cable of looseCables) {
      const p = cable.geometry.coordinates.at(0);
      const q = cable.geometry.coordinates.at(-1);
      if (!p || !q) continue;

      const points = substations
        .filter(
          (s) =>
            s.id !== cable.properties.fromSubstationId &&
            s.id !== cable.properties.toSubstationId,
        )
        .filter(
          (sub) => !alreadyWarnedForExportCableIds.current.includes(sub.id),
        )
        .map((sub) => {
          const dp = turf.distance(sub, p, {
            units: "meters",
          });
          const dq = turf.distance(sub, q, {
            units: "meters",
          });
          return dp < dq
            ? {
                dist: dp,
                which: "p",
                sub,
              }
            : {
                dist: dq,
                which: "q",
                sub,
              };
        })
        .filter(({ sub }) =>
          selectedSubstations.some(
            (selectedSubstation) => selectedSubstation.id === sub.id,
          ),
        );

      const snapTo = min(points, (p) => p.dist);
      if (!snapTo) continue;

      if (snapTo.dist < SNAP_DISTANCE_M) {
        alreadyWarnedForExportCableIds.current.push(snapTo.sub.id);
        if (
          window.confirm(
            "A substation is very close to a detached export cable. Do you want to connect?",
          )
        ) {
          const coordinates =
            snapTo.which === "p"
              ? [snapTo.sub.geometry.coordinates].concat(
                  cable.geometry.coordinates.slice(1),
                )
              : cable.geometry.coordinates
                  .slice(0, -1)
                  .concat([snapTo.sub.geometry.coordinates]);

          const whichProp = !subIds.has(cable.properties.fromSubstationId ?? "")
            ? "fromSubstationId"
            : "toSubstationId";

          const feature = {
            ...cable,
            geometry: {
              ...cable.geometry,
              coordinates,
            },
            properties: {
              ...cable.properties,
              [whichProp]: snapTo.sub.id,
            },
          };
          update({
            update: [feature],
          });
        }
      }
    }
  }, [exportCables, substations, update, currentSelectionArray]);
};
