import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  createAnalysisConfiguration,
  deleteAnalysisConfiguration,
  updateAnalysisConfiguration,
  AnalysisConfiguration,
  createAnalysisConfigurationWithValues,
} from "../../../../services/configurationService";
import { savingConfigurationInProgressAtom } from "../../../../state/configuration";
import { AnalysisSettingsInner } from "../../../ConfigurationModal/AnalysisSettings";
import { selectedMenuItemState } from "../../Shared/state";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../../state/modal";
import { useToast } from "hooks/useToast";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import useConfigurationCrud from "hooks/useConfigurationCrud";
import { IconREMSize } from "styles/typography";
import {
  analysisResourceWithAccessOnNodeState,
  fromProjectToLibraryTabState,
} from "components/Organisation/Library/state";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import {
  editorAccessProjectSelector,
  orgAnalysisManageAccessSelector,
} from "state/user";
import { useLocation, useNavigate } from "react-router-dom";
import { useDuplicateAnalysisToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { useDuplicateAnalysisToLibrary } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToLibrary";
import Button from "components/General/Button";
import { Anchor } from "components/General/Anchor";
import AnalysisModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/AnalysisModal";
import useNodeAnalysisResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeAnalysisResourcesCrud";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import {
  libraryAnalysisConfigurationsFamily,
  projectAnalysisConfigurationsFamily,
} from "state/jotai/analysisConfiguration";
import { firstValueInMap } from "utils/utils";
import { unwrap } from "jotai/utils";
import { designToolTypeAtom } from "state/map";
import SearchIcon from "@icons/24/Search.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

export const ANALYSIS_MENU_ID = "analysis";

const AnalysisSettingLibrary = ({
  configuration,
}: {
  configuration: AnalysisConfiguration;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  return (
    <>
      <AnalysisSettingsInner
        key={configuration.id}
        configuration={configuration}
        readOnly={true}
        isLibraryResource={true}
        nodeId={organisationId}
      />
    </>
  );
};

export default function useAnalysisConfiguration() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const projectType = useAtomValue(designToolTypeAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { error: showErrorToast } = useToast();
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  const setProjectConfigurations = useSetAtom(
    projectAnalysisConfigurationsFamily({ projectId }),
  );
  const projectConfigurations = useAtomValue(
    unwrap(projectAnalysisConfigurationsFamily({ projectId })),
  );
  const [isAutoSaving, setIsAutoSaving] = useAtom(
    savingConfigurationInProgressAtom,
  );
  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: ANALYSIS_MENU_ID,
      projectId,
    }),
  );
  const libraryAnalysis = useAtomValue(
    unwrap(
      libraryAnalysisConfigurationsFamily({
        projectId,
      }),
    ),
  );
  const orgAnalysisManageAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { duplicateToProject } = useDuplicateAnalysisToProject();
  const { duplicateToLibrary } = useDuplicateAnalysisToLibrary();
  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const [isProjectAnalysisCollapsed, setIsProjectAnalysisCollapsed] =
    useState<boolean>(false);
  const [isLibraryAnalysisCollapsed, setIsLibraryAnalysisCollapsed] =
    useState<boolean>(false);

  useEffect(() => {
    if (projectConfigurations && !menuSelection) {
      const firstItem = firstValueInMap(projectConfigurations);
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [projectConfigurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "analysis" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsAutoSaving(true);
    try {
      const newConfig = await createAnalysisConfiguration(
        projectId,
        projectType,
      );

      setProjectConfigurations(async (cur) => {
        const m = new Map(await cur);
        m.set(newConfig.id, newConfig);
        return m;
      });
      setMenuSelection(newConfig.id);
    } catch {}
    setIsAutoSaving(false);
  }, [
    projectId,
    setProjectConfigurations,
    setIsAutoSaving,
    setMenuSelection,
    projectType,
  ]);

  const _delete = useJotaiCallback(
    async (get, set, id: string) => {
      if (!projectId) return;
      setIsAutoSaving(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "ANALYSIS_CONFIGURATION",
          resourceId: id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "ANALYSIS_CONFIGURATION",
          id,
        );
      }
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        try {
          const res = await deleteAnalysisConfiguration(projectId, id);
          if ([204, 409].includes(res.status)) {
            setProjectConfigurations(async (cur) => {
              let m = new Map(await cur);
              m.delete(id);
              return m;
            });
          } else {
            const body = await res.text();
            throw new Error(body);
          }
        } catch (error) {
          if (error instanceof Error) {
            const text = error.message;
            showErrorToast(text, {
              timeout: 4000,
            });
          }
        }
      }

      setIsAutoSaving(false);
    },
    [projectId, setProjectConfigurations, setIsAutoSaving, showErrorToast],
  );

  const _duplicate = useCallback(
    async (id: string, name: string) => {
      if (!projectId || !projectConfigurations) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "analysis",
          defaultName: `${name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const configToDuplicate = projectConfigurations.get(id);
            if (!configToDuplicate) {
              return;
            }
            const configClone = JSON.parse(
              JSON.stringify(configToDuplicate),
            ) as AnalysisConfiguration;

            setIsAutoSaving(true);
            try {
              const updatedConfig = {
                ...configClone,
                name: name,
              };
              const newConfig = await createAnalysisConfigurationWithValues(
                projectId,
                projectType,
                updatedConfig,
              );
              setProjectConfigurations(async (cur) => {
                const m = new Map(await cur);
                m.set(newConfig.id, newConfig);
                return m;
              });
              setMenuSelection(updatedConfig.id);
            } catch {
            } finally {
              setIsAutoSaving(false);
            }
          },
        },
      });
    },
    [
      projectId,
      projectType,
      projectConfigurations,
      setIsAutoSaving,
      setProjectConfigurations,
      setMenuSelection,
      setMidScreenModalTypeOpen,
    ],
  );

  const { save } = useConfigurationCrud();

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const loadedProjectConfigurations = projectConfigurations
      ? Array.from(projectConfigurations.values())
      : [];

    const projectConfigurationList = loadedProjectConfigurations
      .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
      .map((c) => {
        const configName = c.name;

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Delete",
            onSelect: _delete,
            icon: <DeleteIcon />,
          });
          dotMenuOptions.push({
            title: "Duplicate",
            onSelect: () => _duplicate(c.id, c.name ?? ""),
            icon: <DuplicateIcon />,
          });
        }
        if (orgAnalysisManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => duplicateToLibrary(projectId, c),
            icon: <DuplicateIcon />,
          });
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: c.id,
          label: configName ?? "Default configuration",
          loading: isAutoSaving,
          content: (
            <div
              style={{
                display: "flex",
                height: "100%",
                position: "relative",
              }}
            >
              <AnalysisSettingsInner
                key={c.id}
                save={save}
                configuration={c}
                nodeId={projectId}
                isLibraryResource={false}
              />
            </div>
          ),
          onDuplicate: undefined,
          onChangeName: async (newName: string) => {
            setIsAutoSaving(true);
            try {
              await updateAnalysisConfiguration(projectId, projectType, {
                ...c,
                name: newName,
              });
              setProjectConfigurations(async (curr) => {
                const m = new Map(await curr);
                const old = m.get(c.id);
                if (old) {
                  m.set(c.id, { ...old, name: newName });
                }
                return m;
              });
            } catch {
              showErrorToast("Could not save configuration, please try again", {
                timeout: 4000,
              });
            } finally {
              setIsAutoSaving(false);
            }
          },
          dotMenuOptions,
        };
      });

    const loadedLibraryConfigurations = libraryAnalysis
      ? Array.from(libraryAnalysis.values())
      : [];

    const libraryConfigurationList = loadedLibraryConfigurations
      .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
      .map((c) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgAnalysisManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library configuration",
            onSelect: () => {
              let searchParams = new URLSearchParams(location.search);
              let newSearchString = searchParams.toString();
              setFromProjectToLibraryTab(true);
              setContent({
                type: "resource",
                id: c.id,
              });

              navigate(
                `/organisation/${organisationId}/library?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
        }
        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => duplicateToProject(c),
            icon: <DuplicateIcon />,
          });
        }
        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: c.id,
          label: c.name ?? "Default configuration",
          loading: isAutoSaving,
          content: (
            <div
              style={{
                display: "flex",
                height: "100%",
                position: "relative",
              }}
            >
              <AnalysisSettingLibrary configuration={c} />
            </div>
          ),
          onChangeName: async (newName: string) => {
            setIsAutoSaving(true);
            try {
              await updateAnalysisConfiguration(projectId, projectType, {
                ...c,
                name: newName,
              });
              setProjectConfigurations(async (curr) => {
                const m = new Map(await curr);
                const old = m.get(c.id);
                if (old) {
                  m.set(c.id, { ...old, name: newName });
                }
                return m;
              });
            } catch {
              showErrorToast("Could not save configuration, please try again", {
                timeout: 4000,
              });
            } finally {
              setIsAutoSaving(false);
            }
          },
          dotMenuOptions,
        };
      });

    const libraryAnalysisMenu: SettingsSubMenuProp = {
      title: "Library configs",
      items: libraryConfigurationList,
      isCollapsed: isLibraryAnalysisCollapsed,
      loading: libraryAnalysis == null,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryAnalysisCollapsed}
            onClick={() => setIsLibraryAnalysisCollapsed((curr) => !curr)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectAnalysisMenu: SettingsSubMenuProp = {
      title: "Project specific configs",
      items: projectConfigurationList,
      loading: projectConfigurations == null,
      isCollapsed: isProjectAnalysisCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectAnalysisCollapsed}
            onClick={() => setIsProjectAnalysisCollapsed((curr) => !curr)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    return [libraryAnalysisMenu, projectAnalysisMenu];
  }, [
    setFromProjectToLibraryTab,
    setContent,
    isLibraryAnalysisCollapsed,
    isProjectAnalysisCollapsed,
    projectConfigurations,
    libraryAnalysis,
    projectId,
    projectType,
    isAutoSaving,
    editorAccessProject,
    orgAnalysisManageAccess,
    save,
    _duplicate,
    _delete,
    duplicateToLibrary,
    setIsAutoSaving,
    setProjectConfigurations,
    showErrorToast,
    location.search,
    navigate,
    organisationId,
    duplicateToProject,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: isAutoSaving,
      element: <AddAnalysisResourceToNode nodeId={projectId} />,
    };
  }, [isAutoSaving, projectId]);

  const createNewAnalysis = useMemo(() => {
    return {
      type: "element",
      saveInProgress: isAutoSaving,
      element: (
        <Button
          disabled={isAutoSaving}
          onClick={(e) => {
            e.stopPropagation();
            _create();
          }}
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [isAutoSaving, _create]);

  const analysisConfiguration = useMemo(() => {
    return {
      id: ANALYSIS_MENU_ID,
      label: "Analysis",
      icon: <StatisticsIcon />,
      submenus: configSubMenus,
      createNew: createNewAnalysis,
      addFromLibrary: addFromLibrary,
    };
  }, [configSubMenus, addFromLibrary, createNewAnalysis]);

  return analysisConfiguration;
}

function AddAnalysisResourceToNode({ nodeId }: { nodeId: string }) {
  const analysisResources = useAtomValue(
    analysisResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateAnalysis } = useNodeAnalysisResourcesCrud();
  const [showAddAnalysis, setShowAddAnalysis] = useState(false);
  const orgAnalysisManageAccess = useAtomValue(orgAnalysisManageAccessSelector);

  if (!orgAnalysisManageAccess) return <></>;

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddAnalysis(true)}
        icon={<SearchIcon />}
      />
      {showAddAnalysis && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <AnalysisModal
            onSave={async (analysis) => {
              await Promise.all(
                analysis.map((t) => addOrUpdateAnalysis(nodeId, t)),
              );

              setShowAddAnalysis(false);
            }}
            existingAnalysis={analysisResources.map((c) => c.config.id)}
          />
        </Anchor>
      )}
    </div>
  );
}
