import AddIcon from "@icons/24/Add.svg";
import BinIcon from "@icons/24/Bin.svg";
import DuplicateIcon from "@icons/24/Duplicate.svg";
import OptimizeIcon from "@icons/24/Optimize.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import AlertCircleIcon from "@icons/24/AlertCircle.svg";
import Spinner from "@icons/spinner/Spinner";
import { labelFromColorKey } from "business/style/constants";
import {
  currentEditStyleIdAtom,
  enabledStyleAtomFamily,
  sourceValueSelectorFamily,
  stylesPerFeatureSelector,
} from "business/style/state";
import {
  Style,
  isBucket,
  isGradient,
  styleFeaturesOffshore,
  styleFeaturesOnshore,
} from "business/style/types";
import { CONTAINER_MIN_WIDTH } from "components/Design/styles";
import Button from "components/General/Button";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { Row } from "components/General/Layout";
import { Menu, MenuDivider, MenuItem } from "components/General/Menu";
import { MenuButton } from "components/General/MenuButton";
import Radio, { RadioGroup, RadioLabel } from "components/General/Radio";
import {
  LeftModalMenuTypes,
  leftModalMenuOpenStateAtom,
} from "components/LowerLeftV2/state";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing4, spacing7 } from "styles/space";
import { typography } from "styles/typography";
import { capitalize } from "utils/utils";
import { EditStyle } from "./EditStyle";
import { CustomStyleLegend } from "./Legend";
import { styleLegendOpenAtom } from "./state";
import { parkIdAtom, projectIdAtom } from "state/pathParams";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { loadable } from "jotai/utils";
import { isOnshoreAtom } from "state/onshore";
import {
  balanceStyle,
  createNewFeatureStyle,
  duplicateStyle,
  removeStyle,
} from "business/style/actions";
import { StylePreviewSquare } from "./StylePreviewSquare";
import VindIcon from "@icons/24/VindLogo.svg";
import { Anchor } from "components/General/Anchor";
import TurbineIcon from "@icons/24/WindTurbine.svg";
import CableIcon from "@icons/24/Cabling-2.svg";
import AnchorIcon from "@icons/24/Anchor.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import MenuVertical from "@icons/24/MenuVertical.svg";
import OpenWindowRight from "@icons/24/OpenWindowRight.svg";
import { editorAccessProjectSelector } from "state/user";

const StyleListElement_ = styled.div`
  position: relative;
  height: 2.6rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  border-radius: 2px;

  ${typography.caption};

  &&:hover {
    background: ${colors.surfaceHover};
  }

  &&[data-selected="true"] {
    background: ${colors.surfaceSelectedLight};
  }

  ${RadioLabel} {
    min-width: 0;
    flex: 0 auto;
    padding-right: 0.6rem;
  }

  ${RadioLabel} > p,
  .radiodiv > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .radiodiv {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 3.2rem;
    padding-right: 0.6rem;

    ${Spinner} {
      width: 0.8rem;
      height: 0.8rem;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    span {
      margin-left: 1.2rem;
      ${typography.caption};
    }
  }

  .colorsquare {
    width: 1.6rem;
    height: 1.6rem;
  }

  > svg {
    height: 1.3rem;
    width: 1.8rem;
    path {
      fill: ${colors.blue700};
    }
  }

  .dotmenudiv {
    margin: 0.2rem;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    opacity: 0;
    border-radius: 2px;
    transition: opacity 0.15s ease-in-out;

    height: 2.2rem;

    button {
      & > svg {
        padding: 0 !important;
      }
      height: 2.2rem;
      min-width: 2.2rem;
      padding: 0;
    }

    div {
      transition: background 0.15s ease-in-out;
      border-radius: 2px;
      :hover {
        background: ${colors.grey200};
      }
    }
  }
  &:hover .dotmenudiv,
  &[data-selected="true"] .dotmenudiv {
    opacity: 1;
  }
`;

const StyleListElement = ({ style }: { style: Style }) => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const enabled =
    useAtomValue(enabledStyleAtomFamily({ nodeId, feature: style.feature })) ===
    style.id;
  const [editStyleId, setEditStyleId] = useAtom(currentEditStyleIdAtom);
  const setEnabled = useSetAtom(
    enabledStyleAtomFamily({ feature: style.feature, nodeId }),
  );

  const balance = useSetAtom(balanceStyle);
  const duplicate = useSetAtom(duplicateStyle);
  const remove = useSetAtom(removeStyle);
  const canEdit = useAtomValue(editorAccessProjectSelector);

  const hasPark = useAtomValue(parkIdAtom) !== undefined;

  const loadablee = useAtomValue(
    loadable(
      sourceValueSelectorFamily({ styleId: style.id, colorKey: style.source }),
    ),
  );

  // It's okay that we're loading if we don't have selected a park.
  let state = loadablee.state;
  if (state === "loading" && !hasPark) state = "hasData";

  const showName = style.name ?? labelFromColorKey[style.source];

  return (
    <StyleListElement_
      key={style.id}
      onClick={() => setEnabled(style.id)}
      data-selected={editStyleId === style.id}
    >
      {state === "hasData" ? (
        <Radio checked={enabled} onChange={() => {}} name={style.id} />
      ) : state === "loading" ? (
        <div className="radiodiv">
          <Spinner />
        </div>
      ) : (
        <div className="radiodiv">
          <AlertCircleIcon />
        </div>
      )}

      <StylePreviewSquare styleObj={style} />
      <span>{showName}</span>
      {style.defaultMarker && <VindIcon />}

      <div className="dotmenudiv">
        {canEdit && (
          <>
            <MenuButton side="right" direction="down" icon={<MenuVertical />}>
              {style.defaultMarker ? (
                <>
                  <MenuItem
                    icon={<DuplicateIcon />}
                    name="Duplicate"
                    onClick={() => duplicate(style)}
                  />
                </>
              ) : (
                <>
                  <MenuItem
                    icon={<PencilIcon />}
                    name="Edit"
                    disabled={!hasPark}
                    title={
                      !hasPark ? "Select a park to edit a style" : undefined
                    }
                    onClick={() => setEditStyleId(style.id)}
                  />
                  <MenuItem
                    icon={<DuplicateIcon />}
                    name="Duplicate"
                    onClick={() => duplicate(style)}
                  />
                  <MenuItem
                    icon={<BinIcon />}
                    name="Delete"
                    onClick={() => remove(style.id)}
                  />
                  {(isBucket(style) || isGradient(style)) && (
                    <>
                      <MenuDivider />
                      <MenuItem
                        disabled={state !== "hasData"}
                        icon={
                          state === "hasData" ? (
                            <OptimizeIcon />
                          ) : (
                            <Spinner size="0.7rem" />
                          )
                        }
                        name="Balance"
                        onClick={() => balance(style)}
                      />
                    </>
                  )}
                </>
              )}
            </MenuButton>

            <Button
              buttonType="secondary"
              icon={<OpenWindowRight />}
              onClick={(e) => {
                e.stopPropagation();
                setEditStyleId(replaceOrUndefined(style.id));
              }}
            />
          </>
        )}
      </div>
    </StyleListElement_>
  );
};

const StyleGroup_ = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 0;
  min-width: 0;

  & > div {
    display: flex;
    padding: 0 ${spacing4};

    & > label {
      flex: 1;
    }
  }

  ${RadioLabel} {
    input:checked ~ p {
      font-weight: 600;
    }
  }
`;

const StyleGroup = ({ feature }: { feature: Style["feature"] }) => {
  const stylePerFeature = useAtomValue(stylesPerFeatureSelector);
  const styles = stylePerFeature[feature];
  const [open, setOpen] = useState(true);
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const setEnabled = useSetAtom(enabledStyleAtomFamily({ feature, nodeId }));

  return (
    <div>
      <SubtitleWithLine
        text={capitalize(feature)}
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}
        expandButton={
          <ChevronIcon
            open={open}
            chevronSize={"1.4rem"}
            style={{
              alignSelf: "center",
            }}
          />
        }
      />
      {open && (
        <StyleGroup_
          onChange={(e) => {
            if ("name" in e.target && typeof e.target.name === "string")
              setEnabled(e.target.name);
          }}
        >
          {styles.map((s) => (
            <StyleListElement key={s.id} style={s} />
          ))}
        </StyleGroup_>
      )}
    </div>
  );
};

const StyleList_ = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  flex-direction: column;
  overflow-y: auto;
`;
const StyleList = () => {
  const parkId = useAtomValue(parkIdAtom);
  const isOnshore = useAtomValue(isOnshoreAtom);

  const styleFeatures = isOnshore
    ? styleFeaturesOnshore
    : styleFeaturesOffshore;

  return (
    <StyleList_>
      <p>Edit or add new styles to your elements.</p>
      {parkId === undefined && (
        <ValidationAlert
          type="warning"
          title="Styles require a park"
          description={
            "Styles use data that is only available in the context of a park. To see the custom styles in the map, select a park."
          }
        />
      )}
      {styleFeatures.map((sf) => (
        <StyleGroup key={sf} feature={sf} />
      ))}
    </StyleList_>
  );
};

const StyleDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${spacing7};
  overflow: hidden;
  padding-right: unset;
  > * {
    padding-right: 1.6rem;
  }

  > ${Row} {
    margin-top: auto;
    justify-content: space-between;
  }
`;
export const StylingPanel = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  useHorizontalResize(frameRef, "--left-menu-width");
  const [leftModalMenuOpenState, setLeftModalMenuOpenState] = useAtom(
    leftModalMenuOpenStateAtom,
  );
  const open = leftModalMenuOpenState === LeftModalMenuTypes.Styling;
  const [editStyleId, setEditStyleId] = useAtom(currentEditStyleIdAtom);

  const [legendOpen, setLegendOpen] = useAtom(styleLegendOpenAtom);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [btnMenuOpen, setBtnMenuOpen] = useState(false);
  const createNewStyle = useSetAtom(createNewFeatureStyle);

  useEffect(() => {
    if (!open) setEditStyleId(undefined);
  }, [open, setEditStyleId]);
  const onshore = useAtomValue(isOnshoreAtom);
  const canEdit = useAtomValue(editorAccessProjectSelector);

  return (
    <>
      <MenuFrame
        ref={frameRef}
        title="Style"
        style={{
          flex: 1,
          width: "var(--left-menu-width)",
          minWidth: CONTAINER_MIN_WIDTH,
          maxWidth: "40vw",
          resize: "horizontal",
        }}
        onExit={() => setLeftModalMenuOpenState(undefined)}
      >
        <StyleDiv>
          <StyleList />
          <Row>
            <Button
              text={`${legendOpen ? "Close" : "Open"} legend`}
              buttonType="text"
              onClick={() => setLegendOpen((c) => !c)}
            />
            {canEdit && (
              <Button
                ref={buttonRef}
                icon={<AddIcon />}
                text="New style"
                onClick={() => setBtnMenuOpen(true)}
              />
            )}
            {btnMenuOpen && (
              <Anchor
                baseRef={buttonRef}
                floatPlace="bottomLeft"
                basePlace="bottomRight"
              >
                <Menu setOpen={(o) => setBtnMenuOpen(o)} parentRef={buttonRef}>
                  {!onshore && (
                    <MenuItem
                      name="Anchors"
                      icon={<AnchorIcon />}
                      onClick={() => createNewStyle("anchors")}
                    />
                  )}
                  <MenuItem
                    name="Cables"
                    icon={<CableIcon />}
                    onClick={() => createNewStyle("cables")}
                  />
                  <MenuItem
                    name="Parks"
                    icon={<ParkIcon />}
                    onClick={() => createNewStyle("parks")}
                  />
                  <MenuItem
                    name="Turbines"
                    icon={<TurbineIcon />}
                    onClick={() => createNewStyle("turbines")}
                  />
                </Menu>
              </Anchor>
            )}
          </Row>
        </StyleDiv>
      </MenuFrame>
      {editStyleId && (
        <MenuFrame
          title="Edit style"
          style={{ alignSelf: "start", maxHeight: "calc(100% - 3.2rem)" }}
          onExit={() => setEditStyleId(undefined)}
        >
          <EditStyle styleId={editStyleId} />
        </MenuFrame>
      )}
      {open && legendOpen && <CustomStyleLegend />}
    </>
  );
};
