import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { isDetailedJacket, isDetailedMonopile } from "utils/predicates";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { foundationFixedTypesAtom } from "state/jotai/foundation";

export const MAX_RELATIVE_TURBINE_DATA_ERROR = 0.2; //if some of the turbine data (rotor diameter, hub height, RNA weight) differs more than this from the reference values for the monopile, a warning is given

export const turbineAndMonopileFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.TurbineAndMonopileTypeError>
        | undefined
      >
    >(async (get) => {
      const turbines = await get(
        turbinesInParkFamily({
          parkId: parkId,
          branchId,
        }),
      );
      const allTurbineTypes = await get(simpleTurbineTypesAtom);
      const allFixedFoundations = await get(foundationFixedTypesAtom);
      const allDetailedFoundations = allFixedFoundations.filter(
        (f) => isDetailedMonopile(f) || isDetailedJacket(f),
      );
      const detailedFoundationIds = new Set<string | undefined>(
        allDetailedFoundations.map((t) => t.id),
      );

      const turbinesWithDetailedFoundation = turbines.filter((t) =>
        detailedFoundationIds.has(t.properties.foundationId),
      );

      const turbineMonopileError = turbinesWithDetailedFoundation.filter(
        (t) => {
          const turbineType = allTurbineTypes.get(t.properties.turbineTypeId);
          const foundationType = allDetailedFoundations.find(
            (f) => f.id === t.properties.foundationId,
          );

          if (
            !turbineType ||
            (!isDetailedMonopile(foundationType) &&
              !isDetailedJacket(foundationType))
          )
            return false;

          const { diameter, hubHeight, rnaMass } = turbineType;
          const diameterError = Math.abs(
            diameter / foundationType.rotorDiameter - 1,
          );
          const hubHeightError = Math.abs(
            hubHeight / foundationType.hubHeight - 1,
          );
          const rnaMassError = Math.abs(rnaMass / foundationType.rnaMass - 1);

          return (
            Math.max(diameterError, hubHeightError, rnaMassError) >
            MAX_RELATIVE_TURBINE_DATA_ERROR
          );
        },
      );

      return turbineMonopileError.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.TurbineAndMonopileTypeError,
            featureIds: turbineMonopileError.map((t) => t.id),
            parkId: parkId,
          };
    }),
);
