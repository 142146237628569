import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { z } from "zod";
import { ABLY_BRANCH_DUPLICATED } from "state/ably";
import { getCurrentTokenUserId } from "state/global";
import SuccessIcon from "@icons/24/Success.svg";
import { useRefreshBranchState } from "components/Design/useRefreshProjectState";
import { useToast } from "hooks/useToast";
import { Row } from "components/General/Layout";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import { useAblyGeneric } from "../../../../../hooks/useAblyGeneric";

const _BranchFeaturesSuccessfullyDuplicatedMessage = z.object({
  nodeId: z.string(),
  fromBranchId: z.string(),
  newBranchTitle: z.string(),
  newBranchId: z.string(),
  nrFeatures: z.number(),
});

export function useAblyBranchDuplicated(organisationId: string) {
  const refreshBranchState = useRefreshBranchState();
  const { success: showSuccess } = useToast();

  const userPersonalChannelName = `${getCurrentTokenUserId()}:all`;

  const onBranchDuplicatedMessage = useCallback(
    (message: InboundMessage) => {
      const parsedMessage = _BranchFeaturesSuccessfullyDuplicatedMessage.parse(
        message.data,
      );

      showSuccess(
        <Row alignCenter>
          <IconREMSize height={1.6} width={1.6} fill={colors.white}>
            <SuccessIcon />
          </IconREMSize>
          <p style={{ color: colors.white }}>
            "{parsedMessage.newBranchTitle}" feature duplication complete
          </p>
        </Row>,
        {
          groupId: ABLY_BRANCH_DUPLICATED,
          showCountdown: false,
        },
      );
      refreshBranchState(parsedMessage.nodeId, parsedMessage.newBranchId);
    },
    [refreshBranchState, showSuccess],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_BRANCH_DUPLICATED,
        onMessageReceived: onBranchDuplicatedMessage,
      },
    ],
    [onBranchDuplicatedMessage],
  );

  useAblyGeneric(userPersonalChannelName, events, organisationId);
}
