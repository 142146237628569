import { useCallback } from "react";
import { ErrorCallback, ErrorInfo } from "ably";
import useAblyClient from "components/Ably/useAblyClient";
import * as Sentry from "@sentry/react";

// Consider using the ably provided useChannel hook instead of this.
// This can be used if there are instances where your code that publishes to ably
// is not inside the channel provider wrapper.
export const useAblyPublish = (clientId: string) => {
  const client = useAblyClient(clientId);

  const publishWithConnectionId = useCallback(
    async (
      channelName: string,
      eventName: string,
      message: Record<any, any>,
      errorCallback?: ErrorCallback,
      transient: boolean = false,
    ) => {
      if (!client) {
        return;
      }

      const channel = client.channels.get(channelName);

      if (transient) {
        if (channel.state !== "initialized" && channel.state !== "attached") {
          console.debug(
            `Not publishing transient message - channel in ${channel.state} state`,
          );
          return;
        }
      }

      try {
        const messageWithConnectionId = {
          ...message,
          _connectionId: client.connection.id,
        };

        await channel.publish(eventName, messageWithConnectionId);
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            channelName: channel.name,
            channelState: channel.state,
            eventName,
            transient,
          },
        });
        if (error instanceof Error) {
          errorCallback?.({
            code: 0,
            statusCode: 500,
            message: error.message,
          } as ErrorInfo);
        }
      }
    },
    [client],
  );

  return publishWithConnectionId;
};
