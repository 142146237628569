import styled from "styled-components";
import ChevronIcon from "@icons/24/ArrowRight.svg?react";
import { colors } from "../../styles/colors";
import { With } from "../../utils/utils";
import { borderRadiusMedium, spaceMedium } from "../../styles/space";

type Props = {
  open: boolean;
  size?: string;
  toggle?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const ChevronWrapper = styled.div<With<Props, "open" | "size">>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${(p) => p.onClick && `background: ${colors.hover};`}
  }

  ${(p) => p.onClick && `cursor: pointer;`}

  width: ${(p) => `calc(${p.size} + ${spaceMedium})`};
  height: ${(p) => `calc(${p.size} + ${spaceMedium})`};
  border-radius: ${borderRadiusMedium};

  svg {
    transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
    transition: 0.1s ease-in-out;

    width: ${(p) => p.size};
    height: ${(p) => p.size};
  }
`;

export const Chevron = (props: Props) => {
  return (
    <ChevronWrapper
      open={props.open}
      size={props.size ?? "1.6rem"}
      onClick={(e) => {
        props.toggle?.(e);
      }}
    >
      <ChevronIcon />
    </ChevronWrapper>
  );
};
