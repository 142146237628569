import { useCallback, useMemo } from "react";
import { useMouseSampler } from "../../hooks/mouseSampler";
import { isDefined } from "../../utils/predicates";
import { NO_DATA_VAL } from "./constants";
import { decodeMapboxTerrain } from "utils/bathymetry";
import { useMapboxTerrainDemTileUrl } from "hooks/useMapboxTerrainDemTileUrl";

export const useHeightUnderMouseSampler = (): {
  height?: number;
  source?: string;
} => {
  const getMapboxTerrainDemTileUrl = useMapboxTerrainDemTileUrl();
  const fetchTile = useCallback(
    (x: number, y: number, z: number, _: number) =>
      fetch(getMapboxTerrainDemTileUrl({ x, y, z })),
    [getMapboxTerrainDemTileUrl],
  );
  const sample = useMouseSampler(fetchTile, 10, 514);
  const height = useMemo(
    () =>
      isDefined(sample)
        ? decodeMapboxTerrain({
            r: sample[0],
            g: sample[1],
            b: sample[2],
          })
        : NO_DATA_VAL,
    [sample],
  );

  if (!sample) return {};
  return {
    height: Math.round(height),
    source: "Mapbox",
  };
};
