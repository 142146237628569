import React, { useEffect, useState } from "react";
import { SetterOrUpdater } from "types/utils";
import styled from "styled-components";
import { addFeatureAtom, editFeaturesAtom, mapAtom } from "../../state/map";
import CloseableHints from "./CloseableHints/CloseableHints";
import { activeHintAtom, activePositionHintAtom } from "../../state/hint";
import { drawCableCorridorHelpHintType } from "./CloseableHints/DrawCableCorridorHelp";
import { drawLineStringHelpHintType } from "./CloseableHints/DrawLineStringHelp";
import { drawPointHelpHintType } from "./CloseableHints/DrawPointHelp";
import { editGeneralHelpHintType } from "./CloseableHints/EditGeneralHelp";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import { shiftDragSelectHelpHintType } from "./CloseableHints/ShiftDragSelectHelp";
import { changeFeatureHelpHintType } from "./PositionHints/ChangeFeatureTypeHelp";
import { addExternalDataToProjectHelpHintType } from "./PositionHints/AddExternalDataToProjectHelp";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import { splitMultiFeatureHelpHintType } from "./PositionHints/SplitMultiFeatureHelp";
import { resetOrientationHelpHelpHintType } from "./PositionHints/ResetOrientationHelp";
import { avoidSnappingHintType } from "./CloseableHints/AvoidSnapping";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { drawPolygonHelpHintType } from "./CloseableHints/DrawPolygonHelp";
import { userHintSettingsAtom } from "./CloseableHints/state";
import { cableCorridorSourceId } from "components/Mapbox/constants";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomFamily } from "utils/jotai";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";

const hintsPriorityAscending = [
  changeFeatureHelpHintType,
  splitMultiFeatureHelpHintType,
];

const hasBeenShownAtomFamily = atomFamily((_: string) => atom(false));

const hasPriority = (hintType: string, currentHint: string | undefined) =>
  !hintsPriorityAscending.includes(hintType)
    ? true
    : hintsPriorityAscending.indexOf(hintType) >
      hintsPriorityAscending.indexOf(currentHint ?? "");

const setIfPriority = (
  setActiveHint: SetterOrUpdater<string | undefined>,
  hintType: string,
) => {
  setActiveHint((activeHint) =>
    hasPriority(hintType, activeHint) ? hintType : activeHint,
  );
};

const ActiveTipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  bottom: 7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 1.6rem;
  font-weight: 500;
`;

const DrawingAndEditingStuff = () => {
  const addFeature = useAtomValue(addFeatureAtom);
  const editFeature = useAtomValue(editFeaturesAtom);
  const userSettings = useAtomValue(userHintSettingsAtom);
  const setActiveHint = useSetAtom(activeHintAtom);

  useEffect(() => {
    if (addFeature) {
      if (addFeature.options?.source === cableCorridorSourceId) {
        setActiveHint(drawCableCorridorHelpHintType);
      } else if (addFeature.mode === "draw_line_string") {
        setActiveHint(drawLineStringHelpHintType);
      } else if (addFeature.mode === "draw_point") {
        setActiveHint(drawPointHelpHintType);
      } else if (
        !userSettings.dontShowTipIds?.includes(drawPolygonHelpHintType)
      ) {
        setActiveHint(drawPolygonHelpHintType);
      } else {
        setActiveHint(avoidSnappingHintType);
      }
    }

    if (editFeature.length > 0) {
      setActiveHint(editGeneralHelpHintType);
    }

    return () => {
      setActiveHint(undefined);
    };
  }, [addFeature, editFeature, setActiveHint, userSettings.dontShowTipIds]);

  return null;
};

const ResetOrientationTripWire = () => {
  const map = useAtomValue(mapAtom);
  const [activeHint, setActiveHint] = useAtom(activePositionHintAtom);
  const [hasBeenShown, setHasBeenShown] = useAtom(
    hasBeenShownAtomFamily(resetOrientationHelpHelpHintType),
  );

  useEffect(() => {
    if (
      !map ||
      hasBeenShown ||
      activeHint === resetOrientationHelpHelpHintType
    ) {
      return;
    }

    const onStartRotate = () => {
      setIfPriority(setActiveHint, resetOrientationHelpHelpHintType);
      setHasBeenShown(true);
    };
    map.on("rotatestart", onStartRotate);
    return () => {
      map.off("rotatestart", onStartRotate);
    };
  }, [map, setActiveHint, activeHint, setHasBeenShown, hasBeenShown]);

  return null;
};

const ShiftDragTripWire = () => {
  const map = useAtomValue(mapAtom);
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const dynamicLayerSelection = useAtomValue(currentExternalLayerSelection);
  const [numberOfSelectedFeatures, setNumberOfSelectedFeatures] = useState(0);
  const [shiftClickedFeature, setShiftClickedFeature] = useState(0);
  const setActiveHint = useSetAtom(activeHintAtom);
  const [hasBeenShown, setHasBeenShown] = useAtom(
    hasBeenShownAtomFamily(shiftDragSelectHelpHintType),
  );

  useEffect(() => {
    if (!map) return;
    const container = map.getCanvasContainer();
    const shiftClickOnMapFourTimes = (e: MouseEvent) => {
      const amountOfFeaturesSelected =
        dynamicLayerSelection.length + selectedFeatures.length;
      if (
        !e.shiftKey ||
        e.button !== 0 ||
        amountOfFeaturesSelected === numberOfSelectedFeatures
      ) {
        setShiftClickedFeature(0);
        return;
      }

      setNumberOfSelectedFeatures(amountOfFeaturesSelected);
      setShiftClickedFeature((cf) => ++cf);
    };
    container.addEventListener("mouseup", shiftClickOnMapFourTimes);
    return () => {
      container.removeEventListener("mouseup", shiftClickOnMapFourTimes);
    };
  }, [
    map,
    setShiftClickedFeature,
    dynamicLayerSelection,
    selectedFeatures,
    numberOfSelectedFeatures,
  ]);

  useEffect(() => {
    if (shiftClickedFeature > 2 && !hasBeenShown) {
      setActiveHint(shiftDragSelectHelpHintType);
      setHasBeenShown(true);
    }
  }, [shiftClickedFeature, setActiveHint, setHasBeenShown, hasBeenShown]);

  return null;
};

const ChangeTypeHintTripWire = () => {
  const [activeHint, setActiveHint] = useAtom(activePositionHintAtom);
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const [hasBeenShown, setHasBeenShown] = useAtom(
    hasBeenShownAtomFamily(changeFeatureHelpHintType),
  );

  useEffect(() => {
    if (
      hasBeenShown ||
      activeHint === changeFeatureHelpHintType ||
      selectedFeatures.length === 0
    ) {
      return;
    }

    setIfPriority(setActiveHint, changeFeatureHelpHintType);
    setHasBeenShown(true);
  }, [
    setActiveHint,
    activeHint,
    selectedFeatures,
    setHasBeenShown,
    hasBeenShown,
  ]);

  return null;
};

const SplitMultiPartHelpTripWire = () => {
  const [activeHint, setActiveHint] = useAtom(activePositionHintAtom);
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const [hasBeenShown, setHasBeenShown] = useAtom(
    hasBeenShownAtomFamily(splitMultiFeatureHelpHintType),
  );

  useEffect(() => {
    if (
      hasBeenShown ||
      activeHint === splitMultiFeatureHelpHintType ||
      selectedFeatures.length !== 1 ||
      !selectedFeatures[0].geometry.type.includes("Multi")
    )
      return;

    setIfPriority(setActiveHint, splitMultiFeatureHelpHintType);
    setHasBeenShown(true);
  }, [
    setActiveHint,
    activeHint,
    selectedFeatures,
    setHasBeenShown,
    hasBeenShown,
  ]);

  return null;
};

const AddExternalDataToProjectTripWire = () => {
  const [activeHint, setActiveHint] = useAtom(activePositionHintAtom);
  const dynamicLayerSelection = useAtomValue(currentExternalLayerSelection);
  const [hasBeenShown, setHasBeenShown] = useAtom(
    hasBeenShownAtomFamily(addExternalDataToProjectHelpHintType),
  );

  useEffect(() => {
    if (
      hasBeenShown ||
      activeHint === addExternalDataToProjectHelpHintType ||
      dynamicLayerSelection.length === 0
    )
      return;

    setIfPriority(setActiveHint, addExternalDataToProjectHelpHintType);
    setHasBeenShown(true);
  }, [
    setActiveHint,
    activeHint,
    dynamicLayerSelection,
    setHasBeenShown,
    hasBeenShown,
  ]);

  return null;
};

const GeneralTipsTripWires = () => {
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;

  if (!canEdit) return null;

  return (
    <>
      <SplitMultiPartHelpTripWire />
      <DrawingAndEditingStuff />
      <ShiftDragTripWire />
      <ChangeTypeHintTripWire />
      <AddExternalDataToProjectTripWire />
      <ResetOrientationTripWire />
    </>
  );
};

const ActiveTips = ErrorBoundaryWrapper(
  () => (
    <>
      <React.Suspense fallback={null}>
        <ActiveTipsWrapper>
          <CloseableHints />
        </ActiveTipsWrapper>
        <GeneralTipsTripWires />
      </React.Suspense>
    </>
  ),
  ErrorBoundarySilent,
  ScreamOnError,
);

export default ActiveTips;
