import { useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import {
  projectNodesInOrganisation,
  topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily,
} from "../Projects/useOrganisationFolderCrud";
import { scream } from "../../utils/sentry";
import { BranchNotFoundError, NotAuthorizedError } from "./types";
import { ReactNode, Suspense } from "react";
import {
  BulletPointWrapper,
  Container,
  ErrorText,
  ErrorTextHeader,
  ErrorTextWrapper,
  Row,
  WEBGL_ERROR_MESSAGES,
  Wrapper,
} from "./ErrorBoundaryGlobal.styles";
import { loggedInUserAtom } from "../../state/user";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { unwrap } from "jotai/utils";

export const HandleError = ({
  error,
  children,
}: {
  error: Error;
  children: ReactNode;
}) => {
  const userData = useAtomValue(loggedInUserAtom);

  if (WEBGL_ERROR_MESSAGES.includes(error.message)) {
    return (
      <Wrapper>
        <ErrorTextWrapper>
          Something went wrong when trying to initialize a 3d scene using your
          graphics card. Annoyingly this can be because of many reasons but we
          recommend to do the following.
          <BulletPointWrapper>
            <ul>
              <li>Update your browser</li>
              <li>
                Update your graphic drivers{" "}
                {"(select the one appropriate for you)"}
              </li>
              <ul>
                <li>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.nvidia.com/download/index.aspx"
                  >
                    Nvidia
                  </a>
                </li>
                <li>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.amd.com/en/support"
                  >
                    Amd
                  </a>
                </li>
                <li>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.intel.com/content/www/us/en/download-center/home.html"
                  >
                    Intel
                  </a>
                </li>
              </ul>
            </ul>
          </BulletPointWrapper>
        </ErrorTextWrapper>
      </Wrapper>
    );
  }

  if (error instanceof NotAuthorizedError) {
    return (
      <Wrapper>
        <Container>
          <ErrorTextHeader>Not authorized</ErrorTextHeader>
          <Row>
            <ErrorText>
              {`You ${userData?.email ? `(${userData.email})` : ""} don't have access to this page. Return to the`}
            </ErrorText>
            <a
              href={`/organisation`}
              style={{
                textDecoration: "none",
              }}
            >
              home page
            </a>
          </Row>
        </Container>
      </Wrapper>
    );
  }

  return (
    <HandleSilentErrors error={error}>
      <div>{children}</div>
    </HandleSilentErrors>
  );
};

export const HandleSilentErrors = ({
  error,
  children,
}: {
  error: Error;
  children: ReactNode;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom);
  const toplevelNodeId = useAtomValue(
    unwrap(
      topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
        organisationId,
        projectId,
      }),
    ),
  );
  if (
    error instanceof BranchNotFoundError &&
    projectId &&
    toplevelNodeId &&
    organisationId &&
    projectId
  ) {
    return (
      <Suspense fallback={"HandleBranchNotFoundError suspense"}>
        <HandleBranchNotFoundError
          projectId={projectId}
          organisationId={organisationId}
        />
      </Suspense>
    );
  }

  return <div>{children}</div>;
};

const HandleBranchNotFoundError = ({
  projectId,
  organisationId,
}: {
  projectId: string;
  organisationId: string;
}) => {
  const branches = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId: projectId,
    }),
  );

  const nodes = useAtomValue(
    projectNodesInOrganisation({
      organisationId,
    }),
  );
  const node = nodes.find((n) => n.id === projectId);
  const location = window.location;
  const homeUrl = location.origin;
  if (!node || !node.main_branch_id) {
    const err = new Error(`Can not find project node`);
    scream(err, {
      organisationId,
      projectId,
    });
    window.location.href = homeUrl;
    throw err;
  }
  const mainBranchId = node.main_branch_id;
  const mainBranch = branches.find((b) => b.id === mainBranchId);
  if (!mainBranch) {
    const err = new Error(
      `Main branch id is set to '${mainBranchId} but can not be found in branches'`,
    );
    scream(err, {
      organisationId,
      projectId,
    });
    window.location.href = homeUrl;
    throw err;
  }

  const splitPathName = location.pathname.split("/");
  window.location.href = `${location.origin}${splitPathName.slice(0, 5).join("/")}/${mainBranch.id}`;

  return null;
};
