import Fuse, { FuseResult } from "fuse.js";
import { SearchInput, SearchInputProps } from "./Input";
import { Anchor } from "./Anchor";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { colors } from "styles/colors";
import styled from "styled-components";
import { typography } from "styles/typography";
import { useClickOutside } from "hooks/useClickOutside";

const ResultList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.6rem 0 0.4rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background: ${colors.white};
  border-radius: 0.4rem;
  list-style: none;
`;
const Result = styled.li`
  margin: 0 0.4rem;
  padding: 0.4rem 0;
  border-radius: 0.4rem;
  :hover {
    background: ${colors.surfaceHover};
    cursor: pointer;
  }
`;

const _NoResults = styled.li`
  ${typography.body};
  color: ${colors.textDisabled};
  margin: 0.4rem;
  padding-left: 1rem;
  border-radius: 0.4rem;
`;

const NoResults = () => {
  return <_NoResults>No results</_NoResults>;
};

/**
 * A search input backed by a `Fuse` instance.
 */
function FuseSearchInput<T>({
  fuse,
  renderItem,
  maxRows,
  onSelect,
  ...props
}: {
  fuse: Fuse<T>;
  renderItem: (t: FuseResult<T>) => ReactNode;
  onSelect: (e: FuseResult<T>) => void;
  maxRows?: number;
} & Omit<SearchInputProps, "onSelect">) {
  const ref = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState<FuseResult<T>[] | undefined>(
    undefined,
  );
  const search = useCallback(
    (input: string) => {
      setResults(fuse.search(input));
    },
    [fuse],
  );

  useClickOutside(ref, () => setTimeout(() => setFocused(false), 0), undefined);

  useLayoutEffect(() => {
    if (!ref.current || !menuRef.current) return;
    const { width } = ref.current.getBoundingClientRect();
    menuRef.current.style["width"] = `${width}px`;
  }, [focused, results]);

  return (
    <>
      <SearchInput
        ref={ref}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === "") setResults(undefined);
          else search(e.target.value);
        }}
        onFocus={() => setFocused(true)}
        {...props}
      />
      {results !== undefined && focused && (
        <Anchor baseRef={ref} basePlace="bottom" floatPlace="top">
          <ResultList ref={menuRef}>
            {0 < results.length ? (
              (maxRows ? results.slice(0, maxRows) : results).map((r) => (
                <Result key={r.refIndex} onClick={() => onSelect(r)}>
                  {renderItem(r)}
                </Result>
              ))
            ) : (
              <NoResults />
            )}
          </ResultList>
        </Anchor>
      )}
    </>
  );
}

export default FuseSearchInput;
