import { atom } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { OperationsConfiguration } from "services/operationsConfigurationService";

export const localOperationsConfigurationAtom = atom<
  OperationsConfiguration | undefined
>();

export const useLocalOperationsConfigurationCrud = () => {
  const updateTIVessels = useJotaiCallback(
    (_, set, update: Record<string, unknown>, key?: string) => {
      set(
        localOperationsConfigurationAtom,
        (cur: OperationsConfiguration | undefined) => {
          if (!cur) {
            return cur;
          }
          if (key) {
            return {
              ...cur,
              ti: {
                ...cur.ti,
                [key]: {
                  ...(cur.ti as any)[key],
                  ...update,
                },
              },
            };
          }

          return {
            ...cur,
            ti: {
              ...cur.ti,
              ...update,
            },
          };
        },
      );
    },
    [],
  );

  return {
    updateTIVessels,
  };
};
