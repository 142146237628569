import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { mapAtom } from "../../../../state/map";
import Bin from "@icons/24/Bin.svg?react";
import * as turf from "@turf/turf";
import {
  FillPaint,
  LinePaint,
  MapboxGeoJSONFeature,
  SymbolLayer,
} from "mapbox-gl";
import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { MultiPolygon } from "geojson";
import MapPolygon from "../../../MapFeatures/Polygon";
import { slopeAnalysisThresholdsPerParkAtom } from "../../../../state/slopeAnalysis";
import { IconREMSize } from "../../../../styles/typography";
import { generateHSLGradientColors } from "../../../Cabling/CablingMapController/utils";
import { SkeletonBlock, SkeletonText } from "../../../Loading/Skeleton";
import { ColoredGrid } from "../../../General/Form";
import { Column, Row } from "../../../General/Layout";
import { slopeLayerId } from "../../../../constants/bathymetry";
import { ExternalSelectionItem } from "../../../../state/externalLayerSelection";
import DynamicSelectOption from "../../../DynamicSelectOption/DynamicSelectOption";
import { MapColorIndicator } from "../../../General/MapColorIndicator";
import {
  bathymetryDefaultName,
  getFileName,
  SourceListWrapper,
} from "./DepthAnalysis";
import { spaceMedium, spaceSmall, spaceTiny } from "../../../../styles/space";
import Button from "../../../General/Button";
import { RangeSlider } from "../../../General/Slider";
import {
  downloadAppFile,
  promiseWorker,
  typedWorker,
  zip,
} from "../../../../utils/utils";
import { scream } from "../../../../utils/sentry";
import { ProjectFeature } from "../../../../types/feature";
import { z } from "zod";
import { _Feature } from "../../../../utils/geojson/geojson";
import { displayLabelPropertyName } from "../../../../constants/canvas";
import { TopRightModeActiveAtom } from "./state";
import { AddAnalysisButton } from "./BathymetryAnalysis";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import { isDefined } from "utils/predicates";
import { Raster } from "types/raster";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  OverflowEllipsis,
  OverlineText,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import DownloadIcon from "@icons/24/Download.svg?react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomLocalStorage } from "utils/jotai";
import { bathymetryFeatureFamily } from "state/jotai/bathymetry";
import { useSlopeBathymetry } from "hooks/bathymetry";
import { bathymetrySlopeFamily } from "state/bathymetry";
import {
  SlopeResponseWithRaster,
  TileResponseError,
} from "types/bathymetryTypes";
import { getTerrainFromMapboxFamily } from "state/jotai/terrain";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { WithTooltip } from "components/General/Tooltip";

const MIN_AREA_SQM_FOR_SLOPE_ANALYSIS = 50 * 50;

type ContourFeatures = Feature<Polygon | MultiPolygon, { slope: number }>[];

const slopeSourceId = "slopeSourceId";

const slopeAnalysisSymbols: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "[{slopes}] °",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": 0.6,
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const slopePolygonPaint: FillPaint = {
  "fill-color": ["string", ["get", "color"], "#5100c2"],
  "fill-opacity": 0.5,
};

const slopePolygonLinePaint: LinePaint = {
  "line-opacity": 1,
};

type Props = {
  canvasFeature: ProjectFeature<Polygon>;
  bathymetryId: string;
};

const currentThresholdAtom = atomLocalStorage<undefined | [number, number]>(
  "vind:slope-analysis:range",
  undefined,
  z.tuple([z.number(), z.number()]).optional(),
);

const BathymetrySlopeAnalysisInner = ({
  bathymetryId,
  canvasFeature,
}: Props) => {
  const slopeResponse = useAtomValue(bathymetrySlopeFamily(bathymetryId));
  return (
    <SlopeAnalysisInner
      canvasFeature={canvasFeature}
      slopeResponse={slopeResponse}
      mainSource={{ name: "Emodnet", url: "https://emodnet.ec.europa.eu/en" }}
    />
  );
};

const SlopeAnalysisInner = ({
  canvasFeature,
  slopeResponse,
  mainSource,
}: {
  canvasFeature: ProjectFeature<Polygon>;
  slopeResponse: SlopeResponseWithRaster | TileResponseError;
  mainSource: { name: string; url: string };
}) => {
  const polygonArea = useMemo(() => {
    return Math.round(turf.area(canvasFeature) / (1000 * 1000));
  }, [canvasFeature]);
  const [slopeAnalysis, setSlopeAnalysis] = useState<
    undefined | { minValue: number; maxValue: number }
  >(undefined);
  const [thresholdsPerPark, setThresholdsPerPark] = useAtom(
    slopeAnalysisThresholdsPerParkAtom,
  );
  const contourThresholds = useMemo(
    () => thresholdsPerPark[canvasFeature.id] || [],
    [canvasFeature, thresholdsPerPark],
  );

  const [threshold, setThreshold] = useAtom(currentThresholdAtom);

  const [contourFeatureCollection, setContourFeatureCollection] =
    useState<FeatureCollection<Geometry, { slope: number }>>();
  const map = useAtomValue(mapAtom);

  const canvasLayerBathymetryFeatures = useAtomValue(
    bathymetryFeatureFamily({ branchId: undefined }),
  );
  const canvasLayerBathymetryFilenameToName = useMemo(
    () =>
      Object.fromEntries(
        canvasLayerBathymetryFeatures.map((b) => [
          b.properties.filename,
          b.properties.name ?? bathymetryDefaultName,
        ]),
      ),
    [canvasLayerBathymetryFeatures],
  );

  const slopeRaster =
    slopeResponse.status === "finished" ? slopeResponse.raster : undefined;

  useEffect(() => {
    let stop = false;
    (async () => {
      if (!slopeRaster) return;
      const slopeDataWorker = new Worker(
        new URL("./slopeAnalysisDataWorker.ts", import.meta.url),
        { type: "module" },
      );
      const [minValue, maxValue] = await new Promise<[number, number]>(
        (res, rej) => {
          slopeDataWorker.postMessage([slopeRaster, canvasFeature]);
          slopeDataWorker.onmessage = function (e) {
            res(e.data);
          };
          slopeDataWorker.onerror = function (e) {
            scream("slopeDataWorker.onerror", { e });
            rej(e);
          };
        },
      );
      slopeDataWorker.terminate();
      if (stop) return;

      setSlopeAnalysis({
        minValue: parseFloat(minValue.toFixed(3)),
        maxValue: parseFloat(maxValue.toFixed(3)),
      });
    })();
    return () => {
      setSlopeAnalysis(undefined);
      stop = true;
    };
  }, [canvasFeature, slopeRaster]);

  const [workersRunning, setWorkersRunning] = useState<number>(0);
  useEffect(() => {
    let stop = false;

    (async () => {
      if (!slopeResponse || slopeResponse.status === "failed") return;
      setWorkersRunning(contourThresholds.length);
      const slopeCountourWorker = typedWorker<
        [Raster, [number, number], Feature<Polygon>, number],
        [Feature<Polygon | MultiPolygon>]
      >(
        new Worker(
          new URL("./slopeAnalysisContourWorker.ts", import.meta.url),
          {
            type: "module",
          },
        ),
      );
      const features: ContourFeatures = [];

      const parseAndPushFeature = (feature: unknown) => {
        const fs = _Feature.array().parse(feature);
        for (const f of fs) {
          const hasSlope = z.object({
            properties: z.object({ slope: z.number() }),
          });
          // Check that the feature has the slope property
          hasSlope.parse(f);
          // Safety: A ContourFeature is a project feature with the slope property, and this is parsed out now.
          features.push(f as ContourFeatures[number]);
        }
      };

      for (const range of contourThresholds) {
        await promiseWorker(
          slopeCountourWorker,
          [
            slopeResponse.raster,
            range,
            canvasFeature,
            MIN_AREA_SQM_FOR_SLOPE_ANALYSIS,
          ],
          "SlopeAnalysis",
        )
          .then((data) => {
            parseAndPushFeature(data);
            if (!stop) setWorkersRunning((c) => c - 1);
          })
          .catch((e) => {
            scream("slopeCountourWorker.onerror", { e });
            if (!stop) setWorkersRunning((c) => c - 1);
          });
      }
      slopeCountourWorker.terminate();

      if (!stop) {
        setContourFeatureCollection({
          type: "FeatureCollection",
          features,
        });
      }
    })();

    return () => {
      stop = true;
    };
  }, [canvasFeature, contourThresholds, slopeResponse]);

  const maxSlope = slopeAnalysis?.maxValue ?? 90;
  const minSlope = slopeAnalysis?.minValue ?? 0;

  const sortedRegions:
    | Feature<
        Geometry,
        {
          slopes: [number, number];
          area: number;
          color: string;
        }
      >[]
    | undefined = useMemo(() => {
    if (!contourFeatureCollection) return;
    const features = zip(
      contourFeatureCollection?.features ?? [],
      thresholdsPerPark[canvasFeature.id] ?? [],
    ).sort(([, [a0, a1]], [, [b0, b1]]) => {
      const cmp = a0 - b0;
      if (cmp === 0) return a1 - b1;
      return cmp;
    });

    const colors = generateHSLGradientColors({
      n: features.length,
      startHue: 265,
      endHue: 316,
      saturation: 100,
      lightness: 50,
    });
    return features.map(([f, range], i) => {
      const ret = {
        ...f,
        properties: {
          ...f.properties,
          color: colors[i],
          slopes: range,
          area: turf.area(f),
          name: `Slope analysis ${Math.abs(range[0])} deg`,
        },
      };
      return ret;
    });
  }, [canvasFeature.id, contourFeatureCollection, thresholdsPerPark]) as
    | any[]
    | undefined;

  const [selections, setSelections] = useState<ExternalSelectionItem[]>([]);

  const onClick = useCallback(
    (features: MapboxGeoJSONFeature[]) => {
      if (features.length === 0) {
        setSelections([]);
      }
      setSelections([{ ...features[0], properties: { id: features[0].id } }]);
    },
    [setSelections],
  );

  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);

  const selectedIds: string[] = useMemo(
    () => selections.filter(isDefined).map((s) => String(s.id)),
    [selections],
  );

  if (slopeResponse.status === "failed" && slopeResponse.message === "nodata")
    return <div>Data not available in this location</div>;

  if (slopeResponse.status === "failed")
    return <div>{slopeResponse.message}</div>;

  return (
    <>
      {sortedRegions && map && (
        <MapPolygon
          features={sortedRegions}
          sourceId={slopeSourceId}
          layerId={slopeLayerId}
          symbols={slopeAnalysisSymbols}
          map={map}
          paint={slopePolygonPaint}
          linePaint={slopePolygonLinePaint}
          selectedIds={selectedIds}
          onClickCallback={onClick}
        />
      )}
      {selections.length !== 0 && (
        <DynamicSelectOption
          selections={selections}
          addToFolderName={`Slope analysis from ${canvasFeature.properties.name}`}
          callback={() => setTopRightModeActive(undefined)}
        />
      )}
      <ColoredGrid>
        <ResultValue>Method</ResultValue>
        <ResultValue>
          <a
            href={"https://gdal.org/programs/gdaldem.html"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Gdal gdaldem slope
          </a>
        </ResultValue>
        <ResultValue>Max angle</ResultValue>
        {slopeAnalysis === undefined ? (
          <SkeletonBlock style={{ height: "1rem" }} />
        ) : (
          <p>
            <strong>{Math.abs(maxSlope)}</strong>°
          </p>
        )}

        <Row
          style={{
            alignItems: "baseline",
          }}
        >
          <ResultValue>Source(s)</ResultValue>
          {slopeResponse.usedCustomBathymetry.length !== 0 && (
            <HelpTooltip
              size={10}
              text="Custom bathymetry prioritized in analysis"
            />
          )}
        </Row>
        <SourceListWrapper>
          {slopeResponse.usedCustomBathymetry
            .map(getFileName)
            .map((fileName) => (
              <WithTooltip
                key={fileName}
                text={
                  canvasLayerBathymetryFilenameToName[fileName] ??
                  bathymetryDefaultName
                }
              >
                <OverflowEllipsis>
                  {canvasLayerBathymetryFilenameToName[fileName] ??
                    bathymetryDefaultName}
                </OverflowEllipsis>
              </WithTooltip>
            ))}
          <ResultValue>
            <a
              href={mainSource.url}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {mainSource.name}
            </a>
          </ResultValue>
        </SourceListWrapper>
      </ColoredGrid>
      <Row>
        <Button
          size="small"
          text="Slope"
          icon={<DownloadIcon />}
          buttonType="secondary"
          onClick={() => {
            downloadAppFile(
              slopeResponse.url,
              (canvasFeature.properties.name ?? slopeResponse.id) +
                "_slope.tif",
            );
          }}
        />
        {slopeResponse.terrainUrl && (
          <Button
            size="small"
            text="Terrain"
            icon={<DownloadIcon />}
            buttonType="secondary"
            onClick={() => {
              downloadAppFile(
                slopeResponse.terrainUrl,
                (canvasFeature.properties.name ?? slopeResponse.id) +
                  "_terrain.tif",
              );
            }}
          />
        )}
      </Row>

      <OverlineText style={{ paddingTop: "1.6rem", paddingBottom: "0" }}>
        Thresholds
      </OverlineText>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          gap: spaceMedium,
        }}
      >
        {slopeAnalysis === undefined ? (
          <SkeletonBlock style={{ height: "1rem" }} />
        ) : (
          <>
            <RangeSlider
              min={0}
              max={maxSlope + 0.1}
              values={threshold ?? [minSlope, maxSlope]}
              inside
              labels
              step={0.1}
              renderLabel={(v) => v.toFixed(1)}
              onChange={function (f: [number, number]): void {
                setThreshold(f);
              }}
              style={{ flex: 1 }}
            />
            <Button
              text="Add"
              size="small"
              onClick={() => {
                setThresholdsPerPark((tv) => {
                  const val1 = threshold ? threshold[0] : minSlope;
                  const val2 = threshold ? threshold[1] : maxSlope;

                  const alreadyExists = tv[canvasFeature.id]?.some(
                    (oldThreshold) =>
                      oldThreshold[0] === val1 && oldThreshold[1] === val2,
                  );
                  if (alreadyExists) {
                    return tv;
                  }

                  return {
                    ...tv,
                    [canvasFeature.id]: [
                      ...(tv[canvasFeature.id] || []),
                      [val1, val2],
                    ],
                  };
                });
              }}
            />
          </>
        )}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 1fr 1fr 1fr 1fr",
          columnGap: spaceSmall,
          rowGap: spaceTiny,
        }}
      >
        {sortedRegions?.map((region) => {
          const {
            properties: {
              slopes: [from, to],
              area,
              color,
            },
          } = region;
          return (
            <Fragment key={`${from}-${to}`}>
              <Row>
                <MapColorIndicator opacity={1} color={color} />
                <p>
                  {Math.abs(from).toFixed(1)}° to {Math.abs(to).toFixed(1)}°
                </p>
              </Row>
              <p style={{ textAlign: "end" }}>
                {Math.round(area / (1000 * 1000))}km²
              </p>
              <p style={{ textAlign: "end" }}>
                {Math.max(
                  0,
                  Math.min(
                    100,
                    Math.round((area / (1000 * 1000) / polygonArea) * 100),
                  ),
                )}
                %
              </p>
              <Button
                buttonType="secondary"
                icon={
                  <IconREMSize width={1.4} height={1.4}>
                    <Bin title={"Delete threshold"} />
                  </IconREMSize>
                }
                size="small"
                onClick={() =>
                  setThresholdsPerPark((tv) => ({
                    ...tv,
                    [canvasFeature.id]: (tv[canvasFeature.id] || []).filter(
                      ([f, t]) => !(f === from && t === to),
                    ),
                  }))
                }
              />

              <AddAnalysisButton
                region={region as ProjectFeature<Polygon | MultiPolygon>}
                parkId={
                  canvasFeature.properties.type === PARK_PROPERTY_TYPE
                    ? canvasFeature.id
                    : undefined
                }
              />
            </Fragment>
          );
        })}
        {0 < workersRunning && (
          <SkeletonText
            text={`Computing contours ${contourThresholds.length - workersRunning}/${
              contourThresholds.length
            }`}
            style={{ gridColumn: "span 4" }}
          />
        )}
      </div>
    </>
  );
};

const BathymetrySlopeAnalysis = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  const [res] = useSlopeBathymetry({
    featureId: canvasFeature.id,
    projectId: undefined,
    branchId: undefined,
    bufferKm: 1,
  });

  const rasterId = res.state === "hasData" ? res.data.id : undefined;

  return (
    <>
      <SubtitleWithLine text={"Slope"} />
      <Column>
        {res.state === "loading" ? (
          <SkeletonText text="Loading slopes" />
        ) : (
          <React.Suspense fallback={<SkeletonText text="Loading slopes" />}>
            {rasterId && (
              <BathymetrySlopeAnalysisInner
                bathymetryId={rasterId}
                canvasFeature={canvasFeature}
              />
            )}
          </React.Suspense>
        )}
      </Column>
    </>
  );
};

const TerrainSlopeAnalysisInner = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  const slopeResponse = useAtomValue(
    getTerrainFromMapboxFamily({ polygon: canvasFeature, zoom: 12 }),
  );
  return (
    <SlopeAnalysisInner
      slopeResponse={slopeResponse}
      canvasFeature={canvasFeature}
      mainSource={{
        name: "Mapbox",
        url: "https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/",
      }}
    />
  );
};

export const TerrainSlopeAnalysis = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Polygon>;
}) => {
  const MAX_AREA_SQM_ALLOWED = 2200 * 1000 * 1000;
  const formatArea = (area: number) =>
    Math.round(area / (1000 * 1000)) + " km²";

  const areaOfFeature = useMemo(
    () => turf.area(canvasFeature),
    [canvasFeature],
  );

  if (areaOfFeature > MAX_AREA_SQM_ALLOWED) {
    return (
      <div>
        <SimpleAlert
          text={`The selected area of ${formatArea(areaOfFeature)} is too large
          for our terrain analysis, which has a limit of
           ${formatArea(MAX_AREA_SQM_ALLOWED)}`}
          type={"error"}
        />
      </div>
    );
  }
  return (
    <>
      <SubtitleWithLine text={"Slope"} />
      <Column>
        <React.Suspense fallback={<SkeletonText text="Loading slopes" />}>
          <TerrainSlopeAnalysisInner canvasFeature={canvasFeature} />
        </React.Suspense>
      </Column>
    </>
  );
};
export default BathymetrySlopeAnalysis;
