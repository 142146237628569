import { useCallback, useMemo } from "react";
import {
  ABLY_NODE_ACCESS_USER_UPDATE,
  ABLY_UPDATE_GROUP_ACCESS,
  ABLY_USER_LIBRARY_ACCESS,
} from "../../../../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../hooks/useAblyGeneric";
import { _TurbineType } from "types/turbines";
import { _OrganisationResources } from "components/Organisation/OrganisationRightSide/types";
import { z } from "zod";
import { useUserAccessState } from "components/Projects/useUserAccessState";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import { useUserLibraryAccessState } from "components/Projects/useUserLibraryAccessState";
import useAblyRefreshToken from "hooks/useAblyRefreshToken";

const nodeAccessUpdateSchema = z.record(
  z.string(),
  z.enum(["admin", "viewer", "editor"]).nullable(),
);
const groupAccessUpdateSchema = z.record(z.string(), z.string());

export function useAblyUserAccess(organisationId: string, userId: string) {
  const channelName = useMemo(() => `${userId}:all`, [userId]);

  const refreshAblyToken = useAblyRefreshToken(organisationId);
  const { data: userAccess } = useUserAccessState();
  const { refresh: refreshUserAccess } = useUserAccessState();
  const { refresh: refreshNodesInOrganisation } =
    useNodesInOrganisationState(organisationId);
  const { refresh: refreshUserLibraryAccess } = useUserLibraryAccessState({
    organisationId,
  });

  // This updates the user-node access state, this state is what determines
  // what the user is alllowed to see and do, so is is the most important state to update.
  // It also updates the list of nodes if the user has access to a new node or loses access to a node

  // TODO:
  // Any other state that e.g. lists out all users with access to a specific node
  // are not currently updated by this hook, ideally they should be, but it is a harder problem
  // to solve.
  const onMessageReceivedNodeAccess = useCallback(
    async (message: InboundMessage) => {
      const updates = nodeAccessUpdateSchema.parse(message.data);
      // If access change means removing or adding a node, we need to refresh the nodes in the organisation
      let nodeRefreshNeeded = false;
      Object.keys(updates).forEach((nodeId) => {
        const accessLevel = updates[nodeId];
        if (accessLevel === null) {
          nodeRefreshNeeded = true;
        } else if (!userAccess.node_access[nodeId]) {
          nodeRefreshNeeded = true;
        }
      });

      await refreshAblyToken();

      const promises = [refreshUserAccess()];
      if (nodeRefreshNeeded) {
        promises.push(refreshNodesInOrganisation(organisationId));
      }
      await Promise.all(promises);
    },
    [
      refreshUserAccess,
      userAccess.node_access,
      refreshNodesInOrganisation,
      organisationId,
      refreshAblyToken,
    ],
  );

  const onMessageReceivedGroupAccess = useCallback(
    async (message: InboundMessage) => {
      groupAccessUpdateSchema.parse(message.data);

      // TODO: make notification system accept general messages
      // That way we can display a message to the user when group changes happen
      /*  Object.keys(updates).forEach((groupId) => {
        const message = updates[groupId];
        console.log(groupId);
        info(message);
        }); */

      await refreshAblyToken();

      await Promise.all([
        refreshUserAccess(),
        refreshNodesInOrganisation(organisationId),
        refreshUserLibraryAccess(organisationId),
      ]);
    },
    [
      refreshUserAccess,
      refreshNodesInOrganisation,
      refreshUserLibraryAccess,
      organisationId,
      refreshAblyToken,
    ],
  );

  const onMessageReceivedUserLibraryAccess = useCallback(
    async (_: InboundMessage) => {
      await refreshUserLibraryAccess(organisationId, refreshAblyToken);
    },
    [refreshUserLibraryAccess, organisationId, refreshAblyToken],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_NODE_ACCESS_USER_UPDATE,
        onMessageReceived: onMessageReceivedNodeAccess,
      },
      {
        eventName: ABLY_UPDATE_GROUP_ACCESS,
        onMessageReceived: onMessageReceivedGroupAccess,
      },
      {
        eventName: ABLY_USER_LIBRARY_ACCESS,
        onMessageReceived: onMessageReceivedUserLibraryAccess,
      },
    ],
    [
      onMessageReceivedNodeAccess,
      onMessageReceivedGroupAccess,
      onMessageReceivedUserLibraryAccess,
    ],
  );

  useAblyGeneric(channelName, events, organisationId);
}
