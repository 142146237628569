import React from "react";
import { spacing4 } from "styles/space";
import { SVGWrapper } from "@icons/svgUtils";
import TeamMeeting from "@icons/24/TeamMeeting.svg";

const RoundGroupIcon = () => {
  return (
    <div
      className="gray-background"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: spacing4,
        borderRadius: "50%",
      }}
    >
      <SVGWrapper size={1.6}>
        <TeamMeeting />
      </SVGWrapper>
    </div>
  );
};

export default RoundGroupIcon;
