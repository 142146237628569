import Plotly, { Layout } from "plotly.js-dist-min";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { prefixSum, range, sum, zip } from "utils/utils";
import { Color } from "lib/colors";
import { colors } from "styles/colors";
import styled from "styled-components";
import { CompletedOptItem, OptProblem } from "functions/optimize";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { previewTurbinesState } from "state/turbines";
import { point2TurbineFeature } from "./utils";
import { parkIdAtomDef2 } from "state/pathParams";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { Label } from "components/General/Form";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { atomLocalStorage } from "utils/jotai";
import { z } from "zod";
import { Column, Row } from "components/General/Layout";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { computeCapacityFactor } from "components/ProductionV2/functions";
import { IconREMSize, TextIcon } from "styles/typography";
import WarningTriangle from "@icons/24/WarningTriangle.svg?react";
import { spaceTiny } from "styles/space";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const WarningContainer = styled.div`
  padding: 0.4rem 0.3rem;
  border-radius: 0.4rem;
  background-color: ${colors.orange50};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: ${colors.orange100};
`;

const ScaledWarningTriangle = () => {
  return (
    <IconContainer>
      <IconREMSize height={1.4} width={1.4} stroke={colors.orange500}>
        <WarningTriangle />
      </IconREMSize>
    </IconContainer>
  );
};

const setPreviewAction = atom(
  null,
  async (get, set, turbines: CompletedOptItem["turbines"]) => {
    if (!turbines) {
      set(previewTurbinesState, undefined);
      return;
    }
    const parkId = await get(parkIdAtomDef2);
    const existing = await get(
      turbinesInParkFamily({ parkId, branchId: undefined }),
    );

    const preview = turbines.map((t) =>
      point2TurbineFeature([t.lon, t.lat], parkId, t.type),
    );
    set(previewTurbinesState, { existing, preview });
  },
);

const combineBestItems = (
  items: CompletedOptItem[],
): {
  netAep: CompletedOptItem["netAep"];
  grossAep: CompletedOptItem["grossAep"];
  turbines: CompletedOptItem["turbines"][];
} => {
  const maxTurbines = items.reduce((maxLength, current) => {
    if (current.turbines && current.turbines.length > maxLength) {
      return current.turbines.length;
    }
    return maxLength;
  }, 0);
  const fromCount = items[0].netAep?.[0]?.length ?? 0;
  const nrOfLayouts = maxTurbines - fromCount + 1;
  const bestNetAep = [];
  const bestGrossAep = [];
  const bestTurbines = [];

  for (let layout = 0; layout < nrOfLayouts; layout++) {
    let bestItemIndex = 0;
    let maxNetAep = -Infinity;

    for (let j = 0; j < items.length; j++) {
      const netAepSum = sum(items[j].netAep?.[layout] ?? []);
      if (netAepSum > maxNetAep) {
        maxNetAep = netAepSum;
        bestItemIndex = j;
      }
    }

    bestNetAep.push(items[bestItemIndex].netAep?.[layout] ?? []);
    bestGrossAep.push(items[bestItemIndex].grossAep?.[layout] ?? []);
    bestTurbines.push(
      items[bestItemIndex].turbines?.slice(0, fromCount + layout) ?? [],
    );
  }
  return {
    netAep: bestNetAep,
    grossAep: bestGrossAep,
    turbines: bestTurbines,
  };
};

const _Graph = styled.div``;
const Graph = ({
  item,
  graphKey,
  graphKeyLabel,
  onSelect,
}: {
  item: {
    netAep: CompletedOptItem["netAep"];
    grossAep: CompletedOptItem["grossAep"];
    turbines: CompletedOptItem["turbines"][];
  };
  graphKey: z.output<typeof _GraphKey>;
  graphKeyLabel: Record<z.output<typeof _GraphKey>, string>;
  onSelect(x: number): void;
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const relative = useAtomValue(relativeAtom);

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const capacities = useMemo(() => {
    const powers = item.turbines[item.turbines.length - 1]?.map((t) => {
      const tt = turbineTypes.get(t.type);
      return (tt?.ratedPower ?? 0) / 1e3;
    });
    if (!powers) return;
    const prefix = prefixSum(powers);
    return prefix.slice(-(item.netAep?.length ?? 0));
  }, [item, turbineTypes]);

  // yolo typing
  const series: any[] = useMemo(() => {
    switch (graphKey) {
      case "capacity-factor": {
        if (!item.netAep || !capacities) {
          console.error(
            "reqested capacity-factor but item is missing fields",
            item,
          );
          return [];
        }
        const factors = zip(item.netAep, capacities).map(
          ([n, c]) => computeCapacityFactor(sum(n), c) / 10,
        );
        return [
          {
            y: factors,
            name: "Capacity",
            hovertemplate:
              "Turbines: %{x}<br>Capacity Factor: ~%{y:.1f}%<extra></extra>",
          },
        ];
      }
      case "wake-loss": {
        if (!item.netAep || !item.grossAep) {
          console.error("reqested wake-loss but item is missing fields", item);
          return [];
        }

        const losses = zip(item.grossAep, item.netAep).map(
          ([grossPerTurbine, netPerTurbine]) => {
            if (grossPerTurbine.length !== netPerTurbine.length) {
              throw new Error("gross and net aep must have the same length");
            }

            const lossPerTurbine = zip(grossPerTurbine, netPerTurbine).map(
              ([gross, net]) => (1 - net / gross) * 100.0,
            );
            const lossSum =
              (1 - sum(netPerTurbine) / sum(grossPerTurbine)) * 100.0;
            return {
              lossPerTurbine,
              lossSum,
            };
          },
        );

        const maxLoss = losses.map((l) => Math.max(...l.lossPerTurbine));
        const avgLoss = losses.map((l) => l.lossSum);

        return [
          {
            y: avgLoss,
            name: "Avg. loss",
            hovertemplate:
              "Turbines: %{x}<br>Avg. loss: ~%{y:.1f}%<extra></extra>",
          },
          {
            y: maxLoss,
            name: "Max. turbine loss",
            fill: "tonexty",
            hovertemplate:
              "Turbines: %{x}<br>Max. turbine loss: ~%{y:.1f}%<extra></extra>",
          },
        ];
      }
      case "gross-energy": {
        if (!item.grossAep) {
          console.error(
            "reqested gross energy but item is missing fields",
            item,
          );
          return [];
        }
        const gross = item.grossAep.map((n) => sum(n) / 1000);
        return [
          {
            y: gross,
            name: "Gross energy",
            hovertemplate:
              "Turbines: %{x}<br>Gross energy: ~%{y:.0f} GWh<extra></extra>",
          },
        ];
      }
    }
  }, [graphKey, item, capacities]);

  const [traces, setTraces] = useState<Plotly.ScatterData[]>([]);

  const initialTraces: Plotly.ScatterData[] | undefined = useMemo(() => {
    if (!item.grossAep || item.grossAep.length === 0) return;

    const xs = range(
      item.grossAep?.at(0)!.length,
      item.grossAep?.at(-1)!.length + 1,
    );

    const traces: Plotly.ScatterData[] = series.map((ys, i) => {
      const y = relative
        ? ys.y.map((y: any, i: number) => Number(y) / xs[i])
        : ys.y;

      const color = {
        0: colors.blue800,
        1: colors.blue500,
        2: colors.blue800,
        3: colors.blue300,
      }[i]!;

      return {
        ...{ ...ys, y },
        x: xs,
        name: ys.name,
        mode: "markers+lines",
        marker: {
          size: i === 0 || i === 2 ? 7.0 : 4.0,
          color: Color.fromHex(color).css(),
          opacity: 0.8,
        },
      } as any; // yolo
    });
    return traces;
  }, [item.grossAep, relative, series]);

  useEffect(() => {
    if (!initialTraces) return;
    setTraces(initialTraces);
  }, [initialTraces]);

  const [layoutTick, setLayoutTick] = useState(0);
  const layout = useMemo(() => {
    const _ = layoutTick; // update on change
    const layout: Partial<Layout> = {
      font: { size: 8 },
      paper_bgcolor: "rgba(0,0,0,0)",
      xaxis: { title: "Number of turbines" },
      yaxis: {
        title:
          `${graphKeyLabel[graphKey]}` +
          (graphKey === "gross-energy" ? " [GWh]" : " [%]"),
      },
      bargap: 0.1,
      height: 200,
      margin: { l: 40, r: 0, b: 25, t: 0 },
      legend: {
        xanchor: "center",
        yanchor: "top",
        x: 0.5,
        y: -0.3,
        orientation: "h",
      },
    } as const;

    return layout;
  }, [graphKey, graphKeyLabel, layoutTick]);

  useEffect(() => {
    if (!divRef.current) return;

    let bail = false;
    let cleanup = () => {};
    Plotly.newPlot(divRef.current, traces, layout, {
      displayModeBar: false,
      responsive: true,
    })
      .then((plt) => {
        if (bail) return;

        plt.on("plotly_click", (data) => {
          if (!initialTraces) return;
          const { pointIndex, curveNumber, x } = data.points[0];

          if (typeof x !== "number") return;
          onSelect(x);

          const updatedTraces = initialTraces.map((trace, traceIndex) => {
            if (traceIndex === curveNumber) {
              const newMarker = { ...trace.marker } as Plotly.ScatterMarker;
              const sizes = Array.isArray(newMarker.size)
                ? [...newMarker.size]
                : Array(trace.x?.length).fill(newMarker.size || 10);

              sizes[pointIndex] = 10;

              return {
                ...trace,
                marker: {
                  ...newMarker,
                  size: sizes,
                },
              };
            }
            return trace;
          });
          setTraces(updatedTraces);
        });
        cleanup = () => {
          plt.removeAllListeners("plotly_click");
        };
      })
      .catch((e) => {
        console.error("Failed to plot graph: ", e);
      });

    return () => {
      bail = true;
      cleanup();
    };
  }, [layout, traces, onSelect, initialTraces]);

  // Force re-eval `layout` when the container is resized so that we actually
  // get a responsive graph.  Ugly, yes, but `Plotly.redraw` doesn't work for
  // whatever reason. 🤷
  useEffect(() => {
    if (!divRef.current) return;
    const resizeObserver = new ResizeObserver(() =>
      setLayoutTick((c) => c + 1),
    );
    resizeObserver.observe(divRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <_Graph>
        <div ref={divRef} />
      </_Graph>
    </div>
  );
};

const _GraphKey = z.union([
  z.literal("capacity-factor"),
  z.literal("wake-loss"),
  z.literal("gross-energy"),
]);
const graphKeyLabel: Record<z.output<typeof _GraphKey>, string> = {
  "capacity-factor": "Capacity factor",
  "wake-loss": "Wake loss",
  "gross-energy": "Gross energy",
};
const graphKeyAtom = atomLocalStorage(
  "vind:exploration:graph-key",
  _GraphKey.options[0].value,
  _GraphKey,
);
const relativeAtom = atomLocalStorage(
  "vind:exploration:relative",
  false,
  z.boolean(),
);

export const Exploration = ({
  items,
  problem,
}: {
  items: CompletedOptItem[];
  problem: OptProblem;
}) => {
  const setPreview = useSetAtom(setPreviewAction);
  const fromTurbineCount = items[0].netAep?.at(0)?.length ?? 0;
  const combinedBestItem = combineBestItems(items);

  const layoutWithMostTurbines = combinedBestItem.turbines.at(-1);

  const onSelect = useCallback(
    async (x: number) => {
      if (!combinedBestItem.turbines || x === undefined) return;
      await setPreview(combinedBestItem.turbines[x - fromTurbineCount]);
    },
    [combinedBestItem, fromTurbineCount, setPreview],
  );
  useEffect(() => {
    return () => {
      setPreview(undefined);
    };
  }, [setPreview]);

  const [graphKey, setGraphKey] = useAtom(graphKeyAtom);
  const dropdownItems = useMemo(() => {
    return Object.entries(graphKeyLabel).map(([value, name]) => ({
      value,
      name,
    }));
  }, []);

  return (
    <Column>
      <Label left>
        <p>Show</p>
        <DropdownButton
          items={dropdownItems}
          onSelectItem={(val) => setGraphKey(_GraphKey.parse(val))}
          buttonText={graphKeyLabel[graphKey]}
          size="small"
        />
      </Label>
      {!!layoutWithMostTurbines &&
        layoutWithMostTurbines.length < problem.numberOfTurbines && (
          <WarningContainer
            style={{
              maxWidth: "36rem",
            }}
          >
            <Row
              style={{
                gap: spaceTiny,
              }}
            >
              <TextIcon
                style={{
                  color: colors.yellow300,
                }}
              >
                <ScaledWarningTriangle />
              </TextIcon>
              <p style={{ display: "flex", alignItems: "center" }}>
                Up to {problem.numberOfTurbines} requested, but only&nbsp;
                <b>{combinedBestItem.turbines.at(-1)?.length} turbines</b> fit.
              </p>
            </Row>
          </WarningContainer>
        )}

      <Graph
        onSelect={onSelect}
        item={combinedBestItem}
        graphKey={graphKey}
        graphKeyLabel={graphKeyLabel}
      />
      <SimpleAlert
        text={`Click on a point in the graph to preview layout.`}
        type={"info"}
      />
    </Column>
  );
};
