import { aset, useJotaiCallback } from "utils/jotai";
import { putProjectFollow } from "../../services/projectDataAPIService";
import { followProjectsAtom } from "../../state/project";

export const useFollowProjectCrud = () => {
  const put = useJotaiCallback(
    async (get, set, nodeId: string, follow: boolean) => {
      aset(get, set, followProjectsAtom, (cur) => {
        const filtered = cur.filter((c) => c.nodeId !== nodeId);
        return [
          ...filtered,
          {
            nodeId,
            follow,
          },
        ];
      });
      const res = await putProjectFollow(nodeId, {
        follow,
      });

      return res;
    },
    [],
  );

  return {
    put,
  };
};
