import { TableWrapper } from "../styles";
import { CableActivities } from "./CableActivities";
import { ExportCableActivities } from "./ExportCableActivities";
import { FoundationActivities } from "./FoundationActivities";
import { MooringActivities } from "./MooringActivities";
import { SubstationActivities } from "./SubstationActivities";
import { TurbineActivities } from "./TurbineActivities";
import { useAtomValue } from "jotai";
import { localOperationsConfigurationAtom } from "../state";

export const TransportInstallationActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  return (
    <TableWrapper>
      <TurbineActivities isReadOnly={isReadOnly} nodeId={nodeId} />
      <CableActivities isReadOnly={isReadOnly} nodeId={nodeId} />
      <FoundationActivities isReadOnly={isReadOnly} nodeId={nodeId} />
      <MooringActivities isReadOnly={isReadOnly} nodeId={nodeId} />
      <SubstationActivities isReadOnly={isReadOnly} nodeId={nodeId} />
      <ExportCableActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </TableWrapper>
  );
};
