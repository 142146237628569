import { useCallback, useState } from "react";
import {
  LineStringFeatureType,
  PointFeatureType,
  PolygonFeatureType,
  ProjectFeature,
} from "../types/feature";
import { GeotiffFeature } from "../types/feature";
import { dedup, downloadBlob } from "../utils/utils";
import { getGeotiffImage } from "../services/projectDataAPIService";
import { projectIdAtom } from "../state/pathParams";
import { geotiffTypes } from "../components/ProjectElementsV2/utils";
import {
  isAnchor,
  isCable,
  isDefined,
  isMooringLine,
  isPointFeature,
} from "../utils/predicates";
import { replaceEndpointsLineString } from "../types/turbines";
import { FeatureType, _FeatureType } from "../types/feature";
import { parseOr } from "../utils/zod";
import {
  convertGeojsonFeaturesUsingGDAL,
  geojsonFileToZippedShapeFiles,
  geojsonToCSVFile,
  geojsonToDXFFile,
  geojsonToKMLFile,
} from "components/UploadModal/utils";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "@constants/division";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "@constants/cabling";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  GRID_CONNECTION_POINT_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_PROPERTY_TYPE,
  SENSOR_POINT_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "@constants/projectMapView";
import { VIEWPOINT_PROPERTY_TYPE } from "@constants/projectMapView";
import { featureMapFamily } from "state/jotai/features";
import { useAtomValue } from "jotai";

/**
 * Soem features, like {@link MooringLineFeature} and {@link CableFeature} implicitly
 * use the coordinates of the endpoints of the line strings of the features they reference.
 * This is a problem when downloading features, because the coordinates of the endpoints
 * might be wrong, if the endpoints have been updated but the cable feature's has not.
 */
const updateCoordinates = (
  featureMap: Map<string, ProjectFeature>,
  features: ProjectFeature[],
): ProjectFeature[] => {
  return features.map((feature) => {
    if (isMooringLine(feature)) {
      const anchor = featureMap.get(feature.properties.anchor);
      const target = featureMap.get(feature.properties.target);
      if (isAnchor(anchor) && isPointFeature(target)) {
        return replaceEndpointsLineString(
          feature,
          anchor.geometry.coordinates,
          target.geometry.coordinates,
        );
      }
      return feature;
    }
    if (isCable(feature)) {
      const from = featureMap.get(feature.properties.fromId);
      const to = featureMap.get(feature.properties.toId);
      if (isPointFeature(from) && isPointFeature(to)) {
        return replaceEndpointsLineString(
          feature,
          from.geometry.coordinates,
          to.geometry.coordinates,
        );
      }
      return feature;
    }
    return feature;
  });
};

const geoTiffType = (feature: ProjectFeature) =>
  geotiffTypes.includes(feature.properties.type ?? "");
const notGeoTiffType = (feature: ProjectFeature) => !geoTiffType(feature);
const getUniqueFeatureTypes = (features: ProjectFeature[]) =>
  dedup(features.map((f) => f.properties.type).filter(isDefined));

enum FeatureTypes {
  Point = "Point",
  MultiPoint = "MultiPoint",
  Polygon = "Polygon",
  MultiPolygon = "MultiPolygon",
  LineString = "LineString",
  MultiLineString = "MultiLineString",
}

const featureTypeToName: Record<
  | PolygonFeatureType
  | LineStringFeatureType
  | PointFeatureType
  | string
  | "other",
  string
> = {
  [PARK_PROPERTY_TYPE]: "Park-polygon",
  [SUB_AREA_PROPERTY_TYPE]: "Sub-area",
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: "Exclusion-zone",
  [CABLE_CORRIDOR_PROPERTY_TYPE]: "Cable-corridor",
  other: "Other",
  "slack-region": "Slack-region",
  [CABLE_PARTITION_POLYGON_PROPERTY_TYPE]: "Cable-partition",
  [CABLE_CHAIN_POLYGON_PROPERTY_TYPE]: "Cable-chain",
  BathymetryUserUploadedType: "Bathymetry",
  GeoTiffUserUploadedImageType: "Geotiff",
  [EXISTING_TURBINE_PROPERTY_TYPE]: "Existing-turbine",
  [TURBINE_PROPERTY_TYPE]: "Park-turbine-layout",
  [CABLE_PROPERTY_TYPE]: "Park-wiring-connection",
  [SUBSTATION_PROPERTY_TYPE]: "Substation",
  [ANCHOR_PROPERTY_TYPE]: "Anchor",
  [MOORING_LINE_PROPERTY_TYPE]: "Mooring-line",
  [VIEWPOINT_PROPERTY_TYPE]: "View-from-shore-view-point",
  [SENSOR_POINT_PROPERTY_TYPE]: "Sensor",
  [PORT_POINT_PROPERTY_TYPE]: "Port-point",
  [GRID_CONNECTION_POINT_TYPE]: "Grid-connection-point",
};

const getDownloadTypes = (
  features: ProjectFeature[],
  uniqueFeatureTypes: string[],
  name: string,
  getBlobFunc: (
    features: ProjectFeature[],
    fileName: string,
  ) => Promise<Blob | void>,
): Promise<Blob | void>[] =>
  [
    FeatureTypes.Point,
    FeatureTypes.MultiPoint,
    FeatureTypes.LineString,
    FeatureTypes.MultiLineString,
    FeatureTypes.Polygon,
    FeatureTypes.MultiPolygon,
  ]
    .map((type) => {
      const featuresOfType = features.filter(
        (feature) => feature.geometry.type === type,
      );

      if (featuresOfType.length === 0) return [];

      const uniqueFeaturesPerType = Object.fromEntries(
        uniqueFeatureTypes
          .map((featureType) => {
            const filteredFeatures = featuresOfType.filter(
              (f) => f.properties.type === featureType,
            );
            if (filteredFeatures.length === 0) return undefined;
            return [featureType, filteredFeatures];
          })
          .filter(isDefined),
      );

      const generalFeatures = featuresOfType.filter((f) => !f.properties.type);
      if (generalFeatures.length !== 0) {
        uniqueFeaturesPerType["feature"] = generalFeatures;
      }

      return Object.keys(uniqueFeaturesPerType).map((featureType) => {
        const featureTypeName =
          featureType in featureTypeToName
            ? featureTypeToName[featureType]
            : featureType;
        return getBlobFunc(
          uniqueFeaturesPerType[featureType],
          `${name}_${featureTypeName}`,
        );
      });
    })
    .flat();

const useDownloadFeatures = () => {
  const projectId = useAtomValue(projectIdAtom);
  const featureMap = useAtomValue(featureMapFamily({ branchId: undefined }));
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});

  const downloadGeotiff = useCallback(
    async (geotiffFeature: GeotiffFeature) => {
      if (!projectId) return;
      const e = geotiffFeature as GeotiffFeature;
      const blob = await getGeotiffImage(
        projectId,
        e.properties.filename,
        e.properties.type,
      );
      if (!blob) return;
      downloadBlob(blob, `${e.properties.name}.tif`);
    },
    [projectId],
  );

  const downloadMultipleFeaturesShape = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = parseOr(_FeatureType, f.properties.type, undefined);
        if (!type) return false;
        return types.includes(type);
      });
      typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        typeFeatures,
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          geojsonFileToZippedShapeFiles(features, epsg, fileName).then((b) =>
            downloadBlob(b, b.name),
          ),
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff, featureMap],
  );

  const downloadMultipleFeaturesGeojson = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = parseOr(_FeatureType, f.properties.type, undefined);
        if (!type) return false;
        return types.includes(type);
      });
      typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const convertedTypeFeatureCollection =
        await convertGeojsonFeaturesUsingGDAL(typeFeatures, epsg);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        convertedTypeFeatureCollection.features as ProjectFeature[],
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          new Promise<Blob>((res) =>
            res(
              new Blob(
                [
                  JSON.stringify({
                    ...convertedTypeFeatureCollection,
                    features: features,
                  }),
                ],
                {
                  type: "text/plain",
                },
              ),
            ),
          ).then((blob) => downloadBlob(blob, fileName + ".geojson")),
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff, featureMap],
  );

  const downloadMultipleFeaturesKML = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureType[],
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = parseOr(_FeatureType, f.properties.type, undefined);
        if (!type) return false;
        return types.includes(type);
      });
      typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        typeFeatures,
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          geojsonToKMLFile(features).then((b) =>
            downloadBlob(b, fileName + ".kml"),
          ),
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff, featureMap],
  );

  const downloadMultipleFeaturesDXF = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = parseOr(_FeatureType, f.properties.type, undefined);
        if (!type) return false;
        return types.includes(type);
      });
      typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        typeFeatures,
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          geojsonToDXFFile(features, epsg).then((b) =>
            downloadBlob(b, fileName + ".dxf"),
          ),
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff, featureMap],
  );

  const downloadMultipleFeaturesCSV = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = parseOr(_FeatureType, f.properties.type, undefined);
        if (!type) return false;
        return types.includes(type);
      });
      typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        typeFeatures,
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          geojsonToCSVFile(features, epsg).then((b) =>
            downloadBlob(b, fileName + ".csv"),
          ),
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff, featureMap],
  );

  const downloadMultipleFeaturesShapeUsingId = useCallback(
    async (
      featureIds: string[],
      name: string,
      loadingId: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      const features = featureIds
        .map((id) => featureMap.get(id))
        .filter(isDefined);
      return downloadMultipleFeaturesShape(
        features,
        name,
        loadingId,
        types,
        epsg,
      );
    },
    [featureMap, downloadMultipleFeaturesShape],
  );

  const downloadMultipleFeaturesGeojsonUsingId = useCallback(
    async (
      featureIds: string[],
      name: string,
      loadingId: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      const features = featureIds
        .map((id) => featureMap.get(id))
        .filter(isDefined);

      return downloadMultipleFeaturesGeojson(
        features,
        name,
        loadingId,
        types,
        epsg,
      );
    },
    [featureMap, downloadMultipleFeaturesGeojson],
  );

  const downloadMultipleFeaturesKMLUsingId = useCallback(
    async (
      featureIds: string[],
      name: string,
      loadingId: string,
      types?: FeatureType[],
    ) => {
      const features = featureIds
        .map((id) => featureMap.get(id))
        .filter(isDefined);
      return downloadMultipleFeaturesKML(features, name, loadingId, types);
    },
    [featureMap, downloadMultipleFeaturesKML],
  );

  const downloadMultipleFeaturesDXFUsingId = useCallback(
    async (
      featureIds: string[],
      name: string,
      loadingId: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      const features = featureIds
        .map((id) => featureMap.get(id))
        .filter(isDefined);
      return downloadMultipleFeaturesDXF(
        features,
        name,
        loadingId,
        types,
        epsg,
      );
    },
    [featureMap, downloadMultipleFeaturesDXF],
  );

  const downloadMultipleFeaturesCSVUsingId = useCallback(
    async (
      featureIds: string[],
      name: string,
      loadingId: string,
      types?: FeatureType[],
      epsg?: number,
    ) => {
      const features = featureIds
        .map((id) => featureMap.get(id))
        .filter(isDefined);
      return downloadMultipleFeaturesCSV(
        features,
        name,
        loadingId,
        types,
        epsg,
      );
    },
    [featureMap, downloadMultipleFeaturesCSV],
  );

  return {
    isLoading,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    downloadMultipleFeaturesDXF,
    downloadMultipleFeaturesCSV,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
  };
};

export default useDownloadFeatures;
