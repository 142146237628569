import { atom } from "jotai";
import { SensorFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";

export const sensorPointsFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<SensorFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).sensor,
    ),
);
