import {
  CableChainFeature,
  ProjectFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { cableForestFamily } from "./cable";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { bufferPointsForChainOrPartition } from "utils/bufferPointsForChainOrPartition";
import { v4 } from "uuid";
import { cableChainColor } from "components/Cabling/CablingMapController/Render";
import { CABLE_CHAIN_POLYGON_PROPERTY_TYPE } from "@constants/cabling";
import { Position } from "geojson";
import { isPointFeature } from "utils/predicates";
import { scream } from "utils/sentry";
import { listCompare, movingWindow } from "utils/utils";
import { featureMapFamily } from "./features";

/**
 * Order the cable chains consistently so that we can show labels in e.g.
 * Dashboard.
 *
 * Sort order is first by substation-id, then by angle list.
 */
function orderChainsConsistently(
  chains: CableChainFeature[],
  featureMap: Map<string, ProjectFeature>,
) {
  function getFeaturePos(id: string): Position {
    const f = featureMap.get(id);
    if (!isPointFeature(f))
      throw scream("feature id was not a point feature", {
        id,
        typ: f?.geometry?.type,
        f,
      });
    return f.geometry.coordinates;
  }

  const sortables = chains.map<{
    sub: string;
    angles: number[];
    chain: CableChainFeature;
  }>((chain) => {
    const coords = [getFeaturePos(chain.properties.substation)].concat(
      chain.properties.turbines.map(getFeaturePos),
    );
    const angles = movingWindow(coords).map(([p, q]) => {
      return Math.atan2(q[1] - p[1], q[0] - p[0]);
    });

    return {
      sub: chain.properties.substation,
      angles,
      chain,
    };
  });

  const cmp = listCompare((a: number, b: number) => a - b);
  sortables.sort((a, b) => {
    if (a.sub === b.sub) return cmp(a.angles, b.angles);
    return a.sub.localeCompare(b.sub);
  });

  return sortables.map((s, i) => ({
    ...s.chain,
    properties: {
      ...s.chain.properties,
      label: (i + 1).toString(),
    },
  }));
}

export const cableChainsInParkFamily = atomFamily(
  ({ branchId, parkId }: { branchId: string; parkId: string }) =>
    atom<Promise<CableChainFeature[]>>(async (get) => {
      const forest = await get(cableForestFamily({ parkId, branchId }));
      const chains: CableChainFeature[] = [];
      for (const tree of forest) {
        if (!("id" in tree.data)) continue; // TS dumb, the first layer is always substations.
        let substation: SubstationFeature = tree.data;

        for (const turbineNode of tree.children) {
          let turbines: TurbineFeature[] = [];

          turbineNode.traverseDepthFirst((node) => {
            if (!("turbine" in node))
              throw new Error(
                "All remaining nodes should be turbine,cable nodes.",
              );
            turbines.push(node.turbine);
          });
          if (!substation) continue;

          const id = v4();
          const geometry = bufferPointsForChainOrPartition(
            [substation, ...turbines],
            250,
          );

          const feature: CableChainFeature = {
            type: "Feature",
            id,
            geometry,
            properties: {
              type: CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
              parentIds: [parkId],
              substation: substation.id,
              turbines: turbines.map((t) => t.id),
              id,
              color: cableChainColor,
            },
          };
          chains.push(feature);
        }
      }

      const featureMap = await get(featureMapFamily({ branchId }));
      return orderChainsConsistently(chains, featureMap);
    }),
);
