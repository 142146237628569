import InfoIcon from "@icons/24/Information.svg?react";
import Tooltip from "components/General/Tooltip";
import { SkeletonText, orLoader } from "components/Loading/Skeleton";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { ScrollBody, useShowScrollShadow } from "hooks/useShowScrollShadow";
import { Mixpanel } from "mixpanel";
import {
  BottomTableWrapper,
  IconWrapper,
  InfoRow,
  ResultWrapper,
  SumTotalValue,
  TableWrapper,
} from "../styles";
import { FinanceId } from "finance/types";
import { CostUnit } from "types/financial";
import { DownloadIRRData } from "./DownloadIRRData";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { IconREMSize, typography } from "styles/typography";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import Button from "components/General/Button";
import { ButtonWrapper } from "components/UploadModal/components/shared";
import {
  Divider,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { ColoredGrid } from "components/General/Form";
import { useSetAtom } from "jotai";
import { useIRR } from "finance/hooks/useIrr";
import { lunwrap } from "utils/jotai";

export const IRR = ({ id }: { id: FinanceId }) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const setModalType = useSetAtom(modalTypeOpenAtom);

  const { inputs, results } = useIRR(id);

  const projectStart = lunwrap(inputs.projectStart);
  const operationStart = lunwrap(inputs.operationStart);

  const irr = lunwrap(results.irr);

  const { costs, revenues } = results;

  const totalRevenue = lunwrap(revenues.total);

  const totalGuaranteedRevenues = revenues.guaranteed.total;
  const guaranteedRevenuesYears = revenues.guaranteed.years;

  const totalMarketRevenues = revenues.market.total;
  const marketRevenuesYears = revenues.market.years;

  const totalCost = costs.total;

  const totalCostDevex = costs.devex;
  const totalCostCapex = costs.capex;
  const totalCostOpex = costs.opex;
  const totalCostDecom = costs.decom;

  let irrDisplay = <SkeletonText style={{ width: "50%" }} />;
  if (irr && "ok" in irr) {
    irrDisplay = (
      <SumTotalValue>
        <span id={"irr-value"}>
          <strong>{`${Math.round(irr.ok * 10000) / 100}`}</strong> %
        </span>
      </SumTotalValue>
    );
  } else if (irr && "error" in irr) {
    Mixpanel.track_old("IRR computation failed", irr);
    irrDisplay = (
      <SimpleAlert
        text={`Failed to compute IRR. This can happen if the net cash flow never changes sign over time, or if the changes in cash flow suggest a very high IRR. ${
          projectStart === operationStart
            ? "For the currently chosen configuration the project start year matches the operation start year, which may result in an overly optimistic IRR."
            : ""
        }`}
        type={"error"}
      />
    );
  }

  return (
    <>
      <ScrollBody ref={scrollBodyRef}>
        <TableWrapper>
          <InfoRow>
            <IconWrapper>
              <IconREMSize height={1.4} width={1.4}>
                <InfoIcon />
              </IconREMSize>
            </IconWrapper>
            <p>Nominal</p>
          </InfoRow>

          <NewToggleableList
            title="Revenue"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                {totalRevenue ? (
                  <>
                    <strong>{totalRevenue}</strong> {CostUnit.millionEuro}
                  </>
                ) : (
                  "-"
                )}
              </ResultValue>
            }
            loading={totalRevenue === undefined}
          >
            <ColoredGrid>
              {orLoader(guaranteedRevenuesYears, (years) => (
                <>
                  {years.startYear && years.endYear && (
                    <>
                      <ResultValue>
                        {years.startYear}-{years.endYear}
                      </ResultValue>

                      <p>
                        {orLoader(totalGuaranteedRevenues, (rev) => (
                          <ResultValue>
                            <strong>{rev}</strong> {CostUnit.millionEuro}
                          </ResultValue>
                        ))}
                      </p>
                    </>
                  )}
                </>
              ))}

              {orLoader(marketRevenuesYears, (years) => (
                <>
                  {years.startYear && years.endYear && (
                    <>
                      <p>
                        {years.startYear}-{years.endYear}
                      </p>

                      <p>
                        {orLoader(totalMarketRevenues, (rev) => (
                          <ResultValue>
                            <strong>{rev}</strong> {CostUnit.millionEuro}
                          </ResultValue>
                        ))}
                      </p>
                    </>
                  )}
                </>
              ))}
            </ColoredGrid>
          </NewToggleableList>
          <NewToggleableList
            title="Total cost"
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                {totalCost.state === "hasData" ? (
                  <>
                    <strong>{totalCost.data}</strong> {CostUnit.millionEuro}
                  </>
                ) : (
                  "-"
                )}
              </ResultValue>
            }
            loading={totalCost.state === "loading"}
          >
            <ColoredGrid style={{ gridTemplateColumns: "1fr 1fr" }}>
              <ResultValue>CAPEX</ResultValue>
              <p>
                {orLoader(totalCostCapex, (cost) => (
                  <ResultValue>
                    <strong>{cost}</strong> {CostUnit.millionEuro}
                  </ResultValue>
                ))}
              </p>

              <ResultValue>DEVEX</ResultValue>
              <p>
                {orLoader(totalCostDevex, (cost) => (
                  <ResultValue>
                    <strong>{cost}</strong> {CostUnit.millionEuro}
                  </ResultValue>
                ))}
              </p>

              <ResultValue>OPEX</ResultValue>
              <p>
                {orLoader(totalCostOpex, (cost) => (
                  <ResultValue>
                    <strong>{cost}</strong> {CostUnit.millionEuro}
                  </ResultValue>
                ))}
              </p>

              <ResultValue>DECOM</ResultValue>
              <p>
                {orLoader(totalCostDecom, (cost) => (
                  <ResultValue>
                    <strong>{cost}</strong> {CostUnit.millionEuro}
                  </ResultValue>
                ))}
              </p>
            </ColoredGrid>
          </NewToggleableList>
        </TableWrapper>
      </ScrollBody>
      <Divider />
      <BottomTableWrapper>
        <ResultWrapper>
          <Tooltip
            text="The internal rate of return, i.e. the break-even point when the net present value (NPV) of cash flows is equal to zero."
            position="bottomLeft"
            maxWidth="40rem"
          >
            <span style={{ ...typography.sub1, paddingRight: "0.8rem" }}>
              IRR
            </span>
            <IconREMSize height={1.4} width={1.4}>
              <InfoIcon />
            </IconREMSize>
          </Tooltip>
          {irrDisplay}
        </ResultWrapper>
        <ButtonWrapper style={{ paddingTop: 0 }}>
          <DownloadIRRData id={id} buttonType="text" />
          <Button
            text="View in dashboard"
            buttonType="secondary"
            icon={<Dashboard />}
            onClick={() => {
              setModalType({
                modalType: DashboardModalType,
                metadata: { id: "vind-preset-dashboard-cost" },
              });
            }}
          />
        </ButtonWrapper>
      </BottomTableWrapper>
    </>
  );
};
