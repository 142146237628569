import { InputDimensioned } from "components/General/Input";
import { Label } from "components/General/Form";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { DetailedJacketType, DetailedMonopileType } from "types/foundations";
import { between } from "utils/validations";
import { CommonReferenceNumbersProps } from "./ReferenceNumberProps";

interface ReferenceFiftyYearHsProps extends CommonReferenceNumbersProps {
  tempFoundation: DetailedMonopileType | DetailedJacketType;
  originalFoundation: DetailedMonopileType | DetailedJacketType | undefined;
}

const ReferenceFiftyYearHs = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceFiftyYearHsProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Reference 50-year Hs</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          validate={between(1, 20)}
          validationMessage={`Needs to be within 1 - 20 m`}
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "hs50Yr",
            commentCategory,
          )}
          step="0.1"
          unit="m"
          type="number"
          decimals={1}
          value={tempFoundation.hs50Yr}
          initialValue={
            originalFoundation ? originalFoundation.hs50Yr : undefined
          }
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              hs50Yr: d,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default ReferenceFiftyYearHs;
