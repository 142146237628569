import {
  TotalExportSystemLoss,
  TotalInterArrayLoss,
} from "components/ProductionV2/functions";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { getStats } from "./output";
import {
  ProdId,
  analysisOverrideInputFamily,
  getBranchId,
  getConfiguration,
  getParkId,
  getProjectId,
  getTurbineCapacity,
} from "./inputs";
import { NUM_ELECTRICAL_POWER_BINS } from "state/cable";
import {
  exportCableLoadMapFamily,
  exportCablesInParkWithTypeFamily,
} from "state/jotai/exportCable";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { cableLoadsFamily, cablesInParkWithType } from "state/jotai/cable";
import { turbinesInParkWithTypesFamily } from "state/jotai/turbine";
import {
  getExportSystemTypeInParkAndBranchFamily,
  IAVoltageInParkFamily,
} from "state/jotai/electrical";
import * as turf from "@turf/turf";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import {
  CableType,
  ExportSystemType,
  IAVoltageType,
} from "services/cableTypeService";
import { isDefined } from "utils/predicates";
import {
  AnalysisError,
  AnalysisStoppedTypes,
  getExportSystemStoppedReason,
  getInterArrayStoppedReason,
  getWakeAnalysisStoppedReason,
} from "./warnings";
import { binarySearchClosest } from "components/ProductionV2/utils";
import { fastMax } from "utils/utils";
import { fetchSchemaWithToken } from "services/utils";
import { z } from "zod";
import { isOnshoreAtom } from "state/onshore";
import {
  COMPENSATION_SHARE_DEFAULT,
  SubstationType,
} from "services/substationService";
import { SubstationFeature } from "types/feature";
import { ElectricalConfig } from "services/configurationService";

export enum LossStatusType {
  Complete = "complete",
  Failed = "failed",
}

export enum ElectricalLossUnit {
  MW = "MW",
  GWh = "GWh",
  Percent = "%",
}

const _InterArrayAnalysisSuccessResult = z.object({
  id: z.string(),
  version: z.string(),
  status: z.literal(LossStatusType.Complete),
});

const _InterArrayAnalysisFailureResult = z.object({
  status: z.literal(LossStatusType.Failed),
  reason: z.string(),
});

const _InterArrayAnalysisResult = z.discriminatedUnion("status", [
  _InterArrayAnalysisSuccessResult,
  _InterArrayAnalysisFailureResult,
]);

export type InterArrayAnalysisSuccessResult = z.infer<
  typeof _InterArrayAnalysisSuccessResult
>;
type InterArrayAnalysisResult = z.infer<typeof _InterArrayAnalysisResult>;

const _ExportSystemAnalysisSuccessResult = z.object({
  id: z.string(),
  version: z.string(),
  status: z.literal(LossStatusType.Complete),
});

const _ExportSystemAnalysisFailureResult = z.object({
  status: z.literal(LossStatusType.Failed),
  reason: z.string(),
});

const _ExportSystemAnalysisResult = z.discriminatedUnion("status", [
  _ExportSystemAnalysisSuccessResult,
  _ExportSystemAnalysisFailureResult,
]);

export type ExportSystemAnalysisSuccessResult = z.infer<
  typeof _ExportSystemAnalysisSuccessResult
>;
type ExportSystemAnalysisResult = z.infer<typeof _ExportSystemAnalysisResult>;

type ExportCable = {
  fromId: string;
  toId: string;
  offshoreLength: number;
  onshoreLength: number;
  offshoreResistance: number;
  offshoreReactance: number | null;
  offshoreCapacitance: number | null;
  offshoreAmpacity: number | null;
  offshoreVoltage: number;
  offshoreUseAdvancedSettings: boolean;
  offshoreLambda1?: number;
  offshoreLambda2?: number;
  offshoreDielectricLossFactor?: number;
  onshoreResistance: number;
  onshoreReactance: number | null;
  onshoreCapacitance: number | null;
  onshoreAmpacity: number | null;
  onshoreVoltage: number;
  onshoreUseAdvancedSettings: boolean;
  onshoreLambda1?: number;
  onshoreLambda2?: number;
  onshoreDielectricLossFactor?: number;
};

type OffshoreSubstation = {
  impedance: number;
  resistance: number;
  ironLosses: number;
  noLoadCurrent: number;
  reactor: boolean;
  compensationShare: number;
  dcResistance: number;
  dcIronLosses: number;
  lossFactorA: number;
  lossFactorB: number;
  lossFactorC: number;
  exportCableIds: string[];
  MVWindings: number;
  powerCableStrings: number[];
};

type OnshoreSubstation = {
  impedance: number;
  resistance: number;
  ironLosses: number;
  noLoadCurrent: number;
  exportCableIds: string[];
};

type ElectricalTurbine = {
  ratedPowerMW: number;
};

type InterArrayCable = {
  fromId: string;
  toId: string;
  length: number;
  resistance: number;
  reactance: number | null;
  capacitance: number | null;
  ampacity: number | null;
  useAdvancedSettings: boolean;
  lambda1?: number;
  lambda2?: number;
  dielectricLossFactor?: number;
};

type ElectricalArgs = {
  interArrayCables: Record<string, InterArrayCable>;
  turbines: Record<string, ElectricalTurbine>;
  offshoreSubstations: Record<string, OffshoreSubstation>;
  interArrayVoltage: number;
  electricalSettings: ElectricalConfig;
  exportCables?: Record<string, ExportCable>;
  onshoreSubstations?: Record<string, OnshoreSubstation>;
  exportSystemType?: ExportSystemType;
};

async function fetchInterArrayAnalysis({
  nodeId,
  powerBins,
  input,
}: {
  nodeId: string;
  powerBins: number[];
  input: ElectricalArgs;
}): Promise<InterArrayAnalysisResult> {
  const options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...input,
      electricalSettings: {
        ...input.electricalSettings,
        cableLengthContingencyEnabled: false,
      },
      powerBins,
    }),
  };
  return await fetchSchemaWithToken(
    _InterArrayAnalysisResult,
    `/api/octopus/electrical/${nodeId}/inter-array`,
    options,
  );
}

async function fetchExportSystemAnalysis({
  nodeId,
  powerBins,
  input,
}: {
  nodeId: string;
  powerBins: number[];
  input: ElectricalArgs;
}): Promise<ExportSystemAnalysisResult> {
  const options = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...input,
      powerBins,
    }),
  };
  return await fetchSchemaWithToken(
    _ExportSystemAnalysisResult,
    `/api/octopus/electrical/${nodeId}/export-system`,
    options,
  );
}

export const getOverrideExportOffshoreCableTypeFamily = atomFamily(
  (id: ProdId) =>
    atom<Promise<CableType | undefined>>(async (get) => {
      const { exportCableTypeOverrideId } = await get(
        analysisOverrideInputFamily(id),
      );
      if (!exportCableTypeOverrideId) return undefined;
      const cableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );
      return cableTypes.get(exportCableTypeOverrideId);
    }),
);

const getExportSystemAnalysisArgs = atomFamily((id: ProdId) =>
  atom<Promise<ElectricalArgs | null>>(async (get) => {
    const electricalConfig = (await get(getConfiguration(id))).electrical;
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const { turbineTypeOverride } = await get(analysisOverrideInputFamily(id));

    const cables = (
      await get(cablesInParkWithType({ parkId, branchId }))
    ).filter(([c]) => !c.properties.redundancy);

    if (!electricalConfig || cables.length === 0) return null;

    const turbines = await get(
      turbinesInParkWithTypesFamily({ parkId, branchId }),
    ); // NOTE: we apparently don't want to get the same turbines as the analysis is getting.  Should clean up the whole system here at some point.
    const turbineObj = Object.fromEntries(
      turbines.map((t) => [t[0].id, { ratedPowerMW: t[1].ratedPower / 1000 }]),
    );

    const interArrayVoltage = await get(
      IAVoltageInParkFamily({ parkId, branchId }),
    );

    const cableLengthContingencyFactor =
      electricalConfig.cableLengthContingencyEnabled
        ? 1 + electricalConfig.cableLengthContingency
        : 1;

    const interArrayCables: Record<string, InterArrayCable> =
      Object.fromEntries(
        cables.map(([cable, type]) => {
          const { id, fromId, toId } = cable.properties;
          const length =
            turf.length(cable, { units: "kilometers" }) *
            cableLengthContingencyFactor;
          const {
            resistance,
            reactance,
            capacitance,
            ampacity,
            useAdvancedSettings,
            lambda1,
            lambda2,
            dielectricLossFactor,
          } = type;
          return [
            id,
            {
              fromId,
              toId,
              length,
              resistance,
              reactance,
              capacitance,
              ampacity,
              useAdvancedSettings,
              lambda1: useAdvancedSettings ? lambda1 : undefined,
              lambda2: useAdvancedSettings ? lambda2 : undefined,
              dielectricLossFactor: useAdvancedSettings
                ? dielectricLossFactor
                : undefined,
            },
          ];
        }),
      );

    const exportCables = await get(
      exportCablesInParkWithTypeFamily({ parkId, branchId }),
    );

    if (exportCables.length === 0) return null;

    const exportOverrideType = await get(
      getOverrideExportOffshoreCableTypeFamily(id),
    );
    const exportCableSplits = await get(
      exportCableSplitsOkFamily({ parkId, branchId }),
    );
    const exportCableData: Record<string, ExportCable> = Object.fromEntries(
      exportCables
        .map(([c, _off, ons]) => {
          const fromId = c.properties.fromSubstationId;
          const toId = c.properties.toSubstationId;
          if (!fromId || !toId) return;
          const split = exportCableSplits.find(
            (seg) => seg.exportCable.id === c.id,
          );
          const onshoreLength = split
            ? turf.length(split.onshore, { units: "kilometers" })
            : 0;
          const offshoreLength = split
            ? turf.length(split.offshore, { units: "kilometers" })
            : turf.length(c, { units: "kilometers" });

          const off = exportOverrideType ?? _off;

          const {
            resistance: offshoreResistance,
            reactance: offshoreReactance,
            capacitance: offshoreCapacitance,
            ampacity: offshoreAmpacity,
            voltage: offshoreVoltage,
            useAdvancedSettings: offshoreUseAdvancedSettings,
            lambda1: offshoreLambda1,
            lambda2: offshoreLambda2,
            dielectricLossFactor: offshoreDielectricLossFactor,
          } = off;

          const {
            resistance: onshoreResistance,
            reactance: onshoreReactance,
            capacitance: onshoreCapacitance,
            ampacity: onshoreAmpacity,
            voltage: onshoreVoltage,
            useAdvancedSettings: onshoreUseAdvancedSettings,
            lambda1: onshoreLambda1,
            lambda2: onshoreLambda2,
            dielectricLossFactor: onshoreDielectricLossFactor,
          } = ons;

          return [
            c.id,
            {
              fromId,
              toId,
              offshoreLength,
              onshoreLength,
              offshoreResistance,
              offshoreReactance,
              offshoreCapacitance,
              offshoreAmpacity,
              offshoreVoltage,
              offshoreUseAdvancedSettings,
              offshoreLambda1: offshoreUseAdvancedSettings
                ? offshoreLambda1
                : undefined,
              offshoreLambda2: offshoreUseAdvancedSettings
                ? offshoreLambda2
                : undefined,
              offshoreDielectricLossFactor: offshoreUseAdvancedSettings
                ? offshoreDielectricLossFactor
                : undefined,
              onshoreResistance,
              onshoreReactance,
              onshoreCapacitance,
              onshoreAmpacity,
              onshoreUseAdvancedSettings,
              onshoreVoltage,
              onshoreLambda1: onshoreUseAdvancedSettings
                ? onshoreLambda1
                : undefined,
              onshoreLambda2: onshoreUseAdvancedSettings
                ? onshoreLambda2
                : undefined,
              onshoreDielectricLossFactor: onshoreUseAdvancedSettings
                ? onshoreDielectricLossFactor
                : undefined,
            },
          ];
        })
        .filter(isDefined),
    );

    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );

    const exportSystemType = await get(
      getExportSystemTypeInParkAndBranchFamily({ parkId, branchId }),
    );

    const exportLoadMap = await get(
      exportCableLoadMapFamily({
        parkId,
        branchId,
        turbineTypeOverride: undefined,
      }),
    );

    // For offshore mode we just read off the sub types on the substations.
    // For onshore mode, all subs are "onshore" type, so we check the load map
    // instead. This doesn't work if we have inter-array to both subs though,
    // but that's not realistic.
    const onshore = get(isOnshoreAtom);
    let onshoreSubs: [SubstationFeature, SubstationType][] = [],
      offshoreSubs: [SubstationFeature, SubstationType][] = [];
    if (onshore) {
      for (const [sub, type] of substations) {
        const load = exportLoadMap.get(sub.id);
        if (!load || load === 0) onshoreSubs.push([sub, type]);
        else offshoreSubs.push([sub, type]);
      }
    } else {
      for (const [sub, type] of substations) {
        if (type.type === "onshore") onshoreSubs.push([sub, type]);
        else offshoreSubs.push([sub, type]);
      }
    }

    const iaLoads = await get(
      cableLoadsFamily({ parkId, branchId, turbineTypeOverride }),
    );
    const offshoreSubstations: Record<string, OffshoreSubstation> =
      Object.fromEntries(
        offshoreSubs
          .map(([sub, subType]) => {
            const {
              impedance,
              resistance,
              ironLosses,
              noLoadCurrent,
              dcResistance,
              dcIronLosses,
              lossFactorA,
              lossFactorB,
              lossFactorC,
            } = subType;
            const compensationShare =
              subType.compensationShare ?? COMPENSATION_SHARE_DEFAULT;
            const reactor = subType.reactor ?? false;

            const totalSubstationPowerMW =
              (exportLoadMap.get(sub.id) ?? 0) / 1e6;

            const adjacentExportCables = exportCables.filter(
              ([e]) =>
                e.properties.fromSubstationId === sub.id ||
                e.properties.toSubstationId === sub.id,
            );

            let MVWindings = 1;
            const busBarCurrent =
              (1000 * totalSubstationPowerMW) /
              (adjacentExportCables.length || 1) /
              (interArrayVoltage * Math.sqrt(3)) /
              0.9;

            if (interArrayVoltage === IAVoltageType.kV66) {
              if (busBarCurrent > 2500) MVWindings = 2; // TODO: add warning for very high busBarCurrent, in which the system is infeasible
            } else {
              if (busBarCurrent > 3150) MVWindings = 2;
            }

            const powerCableStrings = cables
              .filter(
                ([c]) =>
                  c.properties.fromId === sub.id ||
                  c.properties.toId === sub.id,
              )
              .map(([c]) => (iaLoads.get(c.id) ?? 0) / 1e6); // W to MW

            return [
              sub.id,
              {
                impedance,
                resistance,
                ironLosses,
                noLoadCurrent,
                reactor,
                compensationShare,
                dcResistance,
                dcIronLosses,
                lossFactorA,
                lossFactorB,
                lossFactorC,
                exportCableIds: adjacentExportCables.map((e) => e[0].id),
                MVWindings,
                powerCableStrings,
              },
            ];
          })
          .filter(isDefined),
      );

    const onshoreSubstations: Record<
      string,
      {
        impedance: number;
        resistance: number;
        ironLosses: number;
        noLoadCurrent: number;
        dcResistance: number;
        dcIronLosses: number;
        lossFactorA: number;
        lossFactorB: number;
        lossFactorC: number;
        exportCableIds: string[];
      }
    > = Object.fromEntries(
      onshoreSubs
        .map(([sub, subType]) => {
          const {
            impedance,
            resistance,
            ironLosses,
            noLoadCurrent,
            dcResistance,
            dcIronLosses,
            lossFactorA,
            lossFactorB,
            lossFactorC,
          } = subType;

          const exportCableIds = exportCables
            .filter(
              ([e]) =>
                e.properties.fromSubstationId === sub.id ||
                e.properties.toSubstationId === sub.id,
            )
            .map((e) => e[0].id);

          return [
            sub.id,
            {
              impedance,
              resistance,
              ironLosses,
              noLoadCurrent,
              dcResistance,
              dcIronLosses,
              lossFactorA,
              lossFactorB,
              lossFactorC,
              exportCableIds,
            },
          ];
        })
        .filter(isDefined),
    );

    return {
      interArrayVoltage,
      turbines: turbineObj,
      interArrayCables,
      offshoreSubstations,
      exportCables: exportCableData,
      onshoreSubstations,
      exportSystemType,
      electricalSettings: electricalConfig,
    };
  }),
);

const getInterArrayAnalysisArgs = atomFamily((id: ProdId) =>
  atom<Promise<ElectricalArgs | null>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));
    const electricalConfig = (await get(getConfiguration(id))).electrical;
    const { turbineTypeOverride } = await get(analysisOverrideInputFamily(id));

    const cables = (
      await get(cablesInParkWithType({ parkId, branchId }))
    ).filter(([c]) => !c.properties.redundancy);

    if (!electricalConfig || cables.length === 0) return null;

    const turbines = await get(
      turbinesInParkWithTypesFamily({ parkId, branchId }),
    ); // NOTE: we apparently don't want to get the same turbines as the analysis is getting.  Should clean up the whole system here at some point.
    const turbineObj = Object.fromEntries(
      turbines.map((t) => [t[0].id, { ratedPowerMW: t[1].ratedPower / 1000 }]),
    );

    const interArrayVoltage = await get(
      IAVoltageInParkFamily({ parkId, branchId }),
    );

    const cableLengthContingencyFactor =
      electricalConfig.cableLengthContingencyEnabled
        ? 1 + electricalConfig.cableLengthContingency
        : 1;

    const interArrayCables: Record<string, InterArrayCable> =
      Object.fromEntries(
        cables.map(([cable, type]) => {
          const { id, fromId, toId } = cable.properties;
          const length =
            turf.length(cable, { units: "kilometers" }) *
            cableLengthContingencyFactor;
          const {
            resistance,
            reactance,
            capacitance,
            ampacity,
            useAdvancedSettings,
            lambda1,
            lambda2,
            dielectricLossFactor,
          } = type;
          return [
            id,
            {
              fromId,
              toId,
              length,
              resistance,
              reactance,
              capacitance,
              ampacity,
              useAdvancedSettings,
              lambda1,
              lambda2,
              dielectricLossFactor,
            },
          ];
        }),
      );

    const exportCables = await get(
      exportCablesInParkWithTypeFamily({ parkId, branchId }),
    );

    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );

    const exportLoadMap = await get(
      exportCableLoadMapFamily({
        parkId,
        branchId,
        turbineTypeOverride: undefined,
      }),
    );
    const iaLoads = await get(
      cableLoadsFamily({ parkId, branchId, turbineTypeOverride }),
    );
    const offshoreSubstations: Record<string, OffshoreSubstation> =
      Object.fromEntries(
        substations
          .map(([sub, subType]) => {
            const noCables = (iaLoads.get(sub.id) ?? 0) === 0;
            if (noCables) return;

            const { impedance, resistance, ironLosses, noLoadCurrent } =
              subType;
            const compensationShare =
              subType.compensationShare ?? COMPENSATION_SHARE_DEFAULT;
            const reactor = subType.reactor ?? false;

            const totalSubstationPowerMW =
              (exportLoadMap.get(sub.id) ?? 0) / 1e6;

            const adjacentExportCables = exportCables.filter(
              ([e]) =>
                e.properties.fromSubstationId === sub.id ||
                e.properties.toSubstationId === sub.id,
            );

            let MVWindings = 1;
            const busBarCurrent =
              (1000 * totalSubstationPowerMW) /
              (adjacentExportCables.length || 1) /
              (interArrayVoltage * Math.sqrt(3)) /
              0.9;

            if (interArrayVoltage === IAVoltageType.kV66) {
              if (busBarCurrent > 2500) MVWindings = 2; // TODO: add warning for very high busBarCurrent, in which the system is infeasible
            } else {
              if (busBarCurrent > 3150) MVWindings = 2;
            }

            const powerCableStrings = cables
              .filter(
                ([c]) =>
                  c.properties.fromId === sub.id ||
                  c.properties.toId === sub.id,
              )
              .map(([c]) => (iaLoads.get(c.id) ?? 0) / 1e6); // W to MW

            return [
              sub.id,
              {
                impedance,
                resistance,
                ironLosses,
                noLoadCurrent,
                reactor,
                compensationShare,
                exportCableIds: adjacentExportCables.map((e) => e[0].id),
                MVWindings,
                powerCableStrings,
              },
            ];
          })
          .filter(isDefined),
      );

    return {
      interArrayVoltage,
      turbines: turbineObj,
      interArrayCables,
      offshoreSubstations,
      electricalSettings: electricalConfig,
    };
  }),
);

export const getTriggerInterArrayAnalysis = atomFamily((id: ProdId) =>
  atom<Promise<InterArrayAnalysisSuccessResult | null>>(async (get) => {
    const stop = await get(getInterArrayStoppedReason(id));
    if (stop) throw new AnalysisError(stop);
    const args = await get(getInterArrayAnalysisArgs(id));

    if (args === null) return null;

    const powerBins = await get(getElectricalPowerBins(id));
    const projectId = await get(getProjectId(id));

    const response = await fetchInterArrayAnalysis({
      nodeId: projectId,
      input: args,
      powerBins,
    });
    if (response.status === LossStatusType.Failed) {
      throw new AnalysisError(AnalysisStoppedTypes.InterArrayFailed);
    }
    return response;
  }),
);

export const getTriggerExportSystemAnalysis = atomFamily((id: ProdId) =>
  atom<Promise<ExportSystemAnalysisSuccessResult | null>>(async (get) => {
    const stop = await get(getExportSystemStoppedReason(id));
    if (stop) throw new AnalysisError(stop);
    const analysisStoppedReason = await get(getWakeAnalysisStoppedReason(id));
    if (analysisStoppedReason) throw new AnalysisError(analysisStoppedReason);
    const args = await get(getExportSystemAnalysisArgs(id));

    if (args === null) return null;

    const powerBins = await get(getElectricalPowerBins(id));
    const projectId = await get(getProjectId(id));
    const response = await fetchExportSystemAnalysis({
      nodeId: projectId,
      input: args,
      powerBins,
    });

    if (response.status === LossStatusType.Failed) {
      throw new AnalysisError(AnalysisStoppedTypes.ExportSystemFailed);
    }
    return response;
  }),
);

export const getExportCablesUtilizationFamily = atomFamily((id: ProdId) =>
  atom<
    Promise<
      | {
          onshoreUtilization: number | null;
          offshoreUtilization: number | null;
        }
      | undefined
    >
  >(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const exportSystemLoss = await get(getStats(id));
    const exportResults = exportSystemLoss?.exportSystemStats;
    const exportCableCurrents = exportResults?.exportCableCurrents;
    const exportCableSegLengths = exportResults?.exportCableSegLengths;

    const exportOverrideType = await get(
      getOverrideExportOffshoreCableTypeFamily(id),
    );

    const exportCables = await get(
      exportCablesInParkWithTypeFamily({ parkId, branchId }),
    );
    const exportCableSegments = await get(
      exportCableSplitsOkFamily({ parkId, branchId }),
    );

    if (!exportCableCurrents) return undefined;
    if (!exportCableSegLengths) return undefined;

    let onshoreUtilizations: number[] = [];
    let offshoreUtilizations: number[] = [];

    exportCables.forEach(([e, _off, ons]) => {
      const off = exportOverrideType ?? _off;

      if (off.ampacity === null || ons.ampacity === null) {
        return {
          onshoreUtilization: null,
          offshoreUtilization: null,
        };
      }

      const offshoreRatedCurrent = off.ampacity * 1000;
      const onshoreRatedCurrent = ons.ampacity * 1000;

      const offshoreCable = exportCableSegments.find(
        (c) => c.exportCable.id === e.id,
      )?.offshore;

      const selectedExportCableSegLengths = exportCableSegLengths[e.id];
      const offshoreLength = turf.length(offshoreCable ?? e, {
        units: "kilometers",
      });

      const landfallIdx = binarySearchClosest(
        selectedExportCableSegLengths,
        offshoreLength,
      );

      const selectedExportCableCurrents = exportCableCurrents[e.id];
      const offshoreCurrentValues = selectedExportCableCurrents.slice(
        0,
        landfallIdx + 1,
      );
      if (offshoreCurrentValues.length > 0) {
        const maxOffshore = fastMax(offshoreCurrentValues);
        offshoreUtilizations.push(maxOffshore / offshoreRatedCurrent);
      }

      const onshoreCurrentValues = selectedExportCableCurrents.slice(
        landfallIdx + 1,
        selectedExportCableCurrents.length + 1,
      );
      if (onshoreCurrentValues.length > 0) {
        const maxOnshore = fastMax(onshoreCurrentValues);
        onshoreUtilizations.push(maxOnshore / onshoreRatedCurrent);
      }
    });
    return {
      onshoreUtilization:
        onshoreUtilizations.length === 0 ? null : fastMax(onshoreUtilizations),
      offshoreUtilization:
        offshoreUtilizations.length === 0
          ? null
          : fastMax(offshoreUtilizations),
    };
  }),
);

export const getElectricalPowerBins = atomFamily((id: ProdId) =>
  atom<Promise<number[]>>(async (get) => {
    const capacityKW = (await get(getTurbineCapacity(id))) / 1000;
    const powerBins = Array.from(
      { length: NUM_ELECTRICAL_POWER_BINS + 1 },
      (_, i) => (i / NUM_ELECTRICAL_POWER_BINS) * capacityKW,
    );
    return powerBins;
  }),
);

export const getTotalInterArrayLossMW = atomFamily((id: ProdId) =>
  atom<Promise<TotalInterArrayLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.interArrayStats)
      return {
        totalInterArrayLoss: null,
        totalInterArrayCableLoss: null,
        totalTurbineTrafoLoss: null,
      };
    return {
      totalInterArrayLoss: stats.interArrayStats.interArrayLoss.mw,
      totalInterArrayCableLoss: stats.interArrayStats.interArrayCableLoss.mw,
      totalTurbineTrafoLoss: stats.interArrayStats.interArrayTrafoLoss.mw,
    };
  }),
);

export const getTotalInterArrayLossGWh = atomFamily((id: ProdId) =>
  atom<Promise<TotalInterArrayLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.interArrayStats)
      return {
        totalInterArrayLoss: null,
        totalInterArrayCableLoss: null,
        totalTurbineTrafoLoss: null,
      };
    return {
      totalInterArrayLoss: stats.interArrayStats.interArrayLoss.gwh,
      totalInterArrayCableLoss: stats.interArrayStats.interArrayCableLoss.gwh,
      totalTurbineTrafoLoss: stats.interArrayStats.interArrayTrafoLoss.gwh,
    };
  }),
);

export const getTotalInterArrayLoss = atomFamily((id: ProdId) =>
  atom<Promise<TotalInterArrayLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.interArrayStats)
      return {
        totalInterArrayLoss: null,
        totalInterArrayCableLoss: null,
        totalTurbineTrafoLoss: null,
      };
    return {
      totalInterArrayLoss: stats.interArrayStats.interArrayLoss.fraction,
      totalInterArrayCableLoss:
        stats.interArrayStats.interArrayCableLoss.fraction,
      totalTurbineTrafoLoss: stats.interArrayStats.interArrayTrafoLoss.fraction,
    };
  }),
);

export const getInterArrayAnalysis = atomFamily((id: ProdId) =>
  atom<Promise<InterArrayAnalysisSuccessResult | null>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    if (
      !configuration.electrical.interArrayCableLoss &&
      !configuration.electrical.turbineTrafoLoss
    )
      return null;

    return await get(getTriggerInterArrayAnalysis(id));
  }),
);

export const getTotalExportSystemLossMW = atomFamily((id: ProdId) =>
  atom<Promise<TotalExportSystemLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.exportSystemStats)
      return {
        totalExportSystemLoss: null,
        totalExportCableLoss: null,
        totalOffshoreTrafoLoss: null,
        totalOnshoreTrafoLoss: null,
      };
    return {
      totalExportSystemLoss: stats.exportSystemStats.exportSystemLoss.mw,
      totalExportCableLoss: stats.exportSystemStats.exportSystemCableLoss.mw,
      totalOffshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOffshoreTrafoLoss.mw,
      totalOnshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOnshoreTrafoLoss.mw,
    };
  }),
);

export const getExportSystemAnalysis = atomFamily((id: ProdId) =>
  atom<Promise<ExportSystemAnalysisSuccessResult | null>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    const electricalConfig = configuration.electrical;
    if (!electricalConfig.exportSystemLoss) return null;
    return await get(getTriggerExportSystemAnalysis(id));
  }),
);

export const getTotalExportSystemLossGWh = atomFamily((id: ProdId) =>
  atom<Promise<TotalExportSystemLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.exportSystemStats)
      return {
        totalExportSystemLoss: null,
        totalExportCableLoss: null,
        totalOffshoreTrafoLoss: null,
        totalOnshoreTrafoLoss: null,
      };
    return {
      totalExportSystemLoss: stats.exportSystemStats.exportSystemLoss.gwh,
      totalExportCableLoss: stats.exportSystemStats.exportSystemCableLoss.gwh,
      totalOffshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOffshoreTrafoLoss.gwh,
      totalOnshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOnshoreTrafoLoss.gwh,
    };
  }),
);

export const getTotalExportSystemLoss = atomFamily((id: ProdId) =>
  atom<Promise<TotalExportSystemLoss>>(async (get) => {
    const stats = await get(getStats(id));
    if (!stats?.exportSystemStats)
      return {
        totalExportSystemLoss: null,
        totalExportCableLoss: null,
        totalOffshoreTrafoLoss: null,
        totalOnshoreTrafoLoss: null,
      };
    return {
      totalExportSystemLoss: stats.exportSystemStats.exportSystemLoss.fraction,
      totalExportCableLoss:
        stats.exportSystemStats.exportSystemCableLoss.fraction,
      totalOffshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOffshoreTrafoLoss.fraction,
      totalOnshoreTrafoLoss:
        stats.exportSystemStats.exportSystemOnshoreTrafoLoss.fraction,
    };
  }),
);

/**
 * Create a {@link Map} from cable ids to the power that flows through the
 * cable. In addition, the substation ids in the map contain the total power
 * into the substation.
 *
 * Output is in Watts.
 */
export const getCableLoadsFamily = atomFamily((id: ProdId) =>
  atom<Promise<Map<string, number>>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));

    const { turbineTypeOverride } = await get(analysisOverrideInputFamily(id));

    return get(
      exportCableLoadMapFamily({ parkId, branchId, turbineTypeOverride }),
    );
  }),
);
