import { useAtom } from "jotai";
import Spinner from "@icons/spinner/Spinner";
import Members from "@icons/24/Persons.svg?react";
import Button from "components/General/Button";
import { Suspense, useCallback } from "react";
import InviteToOrganisationFrame from "./InviteToOrganisationFrame";
import { inviteToOrganisationOpenAtom } from "components/Organisation/state";
import SearchWrapper from "components/Search/SearchWrapper";

export default function InviteToOrganisationButton({
  buttonType = "secondary",
}: {
  buttonType: "primary" | "secondary";
}) {
  const [inviteOrganisationOpen, setInviteToOrganisationOpen] = useAtom(
    inviteToOrganisationOpenAtom,
  );

  const toggleOpen = useCallback(
    () => setInviteToOrganisationOpen((curr) => !curr),
    [setInviteToOrganisationOpen],
  );

  const close = useCallback(
    () => setInviteToOrganisationOpen(false),
    [setInviteToOrganisationOpen],
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Suspense
        fallback={
          <Button
            text="Invite"
            disabled={true}
            icon={<Spinner size="1rem" />}
            buttonType={buttonType}
          />
        }
      >
        <SearchWrapper
          title="Invite to organisation"
          tags={[
            "invite",
            "users",
            "user list",
            "new member",
            "new user",
            "share",
            "members",
          ]}
          id="search-invite"
          icon={<Members />}
          onSelect={toggleOpen}
        >
          <Button text="Invite" onClick={toggleOpen} buttonType={buttonType} />
        </SearchWrapper>
        {inviteOrganisationOpen && (
          <InviteToOrganisationFrame onClose={close} />
        )}
      </Suspense>
    </div>
  );
}
