import { MenuItem } from "components/General/Menu";
import { MenuButton } from "components/General/MenuButton";
import { Mixpanel } from "mixpanel";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { fromProjectToLibraryTabState } from "components/Organisation/Library/state";
import EditIcon from "@icons/24/Pencil.svg?react";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useToast } from "hooks/useToast";
import { ResourceType } from "./types";

type LibraryResourceActionsProps = {
  resourceType: ResourceType;
  resourceId: string;
  nodeId: string;
  organisationId: string;
  onDuplicate?: () => void | Promise<void> | Promise<any>;
  isLoadingDuplicate?: boolean;
};

const LibraryResourceActions = ({
  resourceType,
  resourceId,
  nodeId,
  organisationId,
  onDuplicate,
  isLoadingDuplicate,
}: LibraryResourceActionsProps) => {
  const navigate = useNavigate();
  const setContent = useSetAtom(organisationRightSideModal(nodeId));

  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const { error: showErrorToast } = useToast();

  const handleDuplicate = async () => {
    if (!onDuplicate) return;

    try {
      await onDuplicate();
    } catch (error) {
      showErrorToast("Failed to duplicate");
    }
  };

  return (
    <MenuButton
      buttonText="Edit"
      icon={<EditIcon />}
      buttonType="primary"
      divStyle={{
        display: "flex",
        alignSelf: "flex-end",
        marginRight: "2.4rem",
      }}
    >
      <MenuItem
        name="Edit Library component"
        onClick={() => {
          Mixpanel.track_old("Click edit in library", {
            resource: resourceType,
          });

          setContent({
            type: "resource",
            id: resourceId,
          });
          setFromProjectToLibraryTab(true);
          navigate(
            `/organisation/${organisationId}/library/${resourceType}/${resourceId}`,
          );
        }}
        icon={<ArrowTopRightIcon />}
      />
      {onDuplicate && (
        <MenuItem
          name="Create a project specific duplicate"
          onClick={handleDuplicate}
          icon={<DuplicateIcon />}
          disabled={isLoadingDuplicate}
        />
      )}
    </MenuButton>
  );
};

export default LibraryResourceActions;
