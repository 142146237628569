import { VesselType } from "services/vesselService";
import { AnchorHandlingVesselSettings } from "./AnchorHandlingVesselSettings";
import { CableLayVesselSettings } from "./CableLayVesselSettings";
import { FeederBargeVesselSettings } from "./FeederBargeVesselSettings";
import { InstallationVesselSettings } from "./InstallationVesselSettings";
import { ScourProtectionVesselSettings } from "./ScourProtectionVesselSettings";

export function VesselTypeSpecificSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType;
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  if (vessel.type === "anchorHandling") {
    return (
      <AnchorHandlingVesselSettings
        vessel={vessel}
        setVessel={setVessel}
        disabled={disabled}
        nodeId={nodeId}
      />
    );
  } else if (vessel.type === "cableLay") {
    return (
      <CableLayVesselSettings
        vessel={vessel}
        setVessel={setVessel}
        disabled={disabled}
        nodeId={nodeId}
      />
    );
  } else if (vessel.type === "feederBarge") {
    return (
      <FeederBargeVesselSettings
        vessel={vessel}
        setVessel={setVessel}
        disabled={disabled}
        nodeId={nodeId}
      />
    );
  } else if (vessel.type === "installation") {
    return (
      <InstallationVesselSettings
        vessel={vessel}
        setVessel={setVessel}
        disabled={disabled}
        nodeId={nodeId}
      />
    );
  } else if (vessel.type === "scourProtection") {
    return (
      <ScourProtectionVesselSettings
        vessel={vessel}
        setVessel={setVessel}
        disabled={disabled}
        nodeId={nodeId}
      />
    );
  } else {
    vessel satisfies never;
  }
}
