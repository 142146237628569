import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import {
  activeFiltersAtom,
  FilterType,
  LayerFilterState,
  nrActiveFiltersSelector,
} from "../../layer-filter-state";
import {
  selectedSourceNameAtom,
  SourceName,
} from "../../../LayerList/LayerSettings/state";
import {
  findSourceWithName,
  getExternalDataSourcesSelector,
} from "../../../../state/layer";
import Button from "../../../General/Button";
import { RESET } from "jotai/utils";
import { Pill } from "../Pill";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

const ActiveFilterPillsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem;
`;

const Title = styled.div`
  ${typography.caption}
  color: ${colors.textNegative};
`;

const ActiveFilterPills = () => {
  const [activeFilters, setActiveFilters] = useAtom(activeFiltersAtom);
  const resetActiveFilters = useSetAtom(activeFiltersAtom);
  const nrActiveFilters = useAtomValue(nrActiveFiltersSelector);
  const sources = useAtomValue(getExternalDataSourcesSelector);
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);
  const onClick = useCallback(
    (
      filter:
        | string
        | {
            id: string;
            name: string;
          },
      category: FilterType,
    ) => {
      setActiveFilters((curr) => {
        return {
          ...curr,
          [category]: curr[category].filter((f) => f !== filter),
        };
      });
    },
    [setActiveFilters],
  );

  const onClearFilterCategory = useCallback(
    (category: FilterType, value: any) => {
      setActiveFilters((curr) => {
        return {
          ...curr,
          [category]: value,
        };
      });
    },
    [setActiveFilters],
  );

  if (nrActiveFilters === 0) {
    return null;
  }

  return (
    <ActiveFilterPillsWrapper>
      {Object.entries<LayerFilterState[keyof LayerFilterState]>(
        activeFilters,
      ).map(([category, filters]) => {
        if (Array.isArray(filters)) {
          return filters.map((filter) => {
            const source =
              category === "source"
                ? findSourceWithName(filter, sources)
                : undefined;

            const title = source
              ? selectedSourceName === SourceName.Original &&
                source.originalName
                ? source.originalName
                : filter
              : filter;

            const key = source ? source.id : filter;
            return (
              <Pill
                key={key}
                filterType={category as FilterType}
                title="Remove filter"
                onClick={() => {
                  onClick(filter, category as FilterType);
                }}
              >
                <Title>{title}</Title>
                <CloseIcon
                  style={{
                    width: "8px",
                    height: "8px",
                  }}
                />
              </Pill>
            );
          });
        } else if (category === "overlappingGeometry" && Boolean(filters)) {
          return (
            <Pill
              key={category}
              filterType={category as FilterType}
              title="Remove filter"
              onClick={() => {
                onClearFilterCategory(category as FilterType, undefined);
              }}
            >
              <Title>Overlap</Title>
              <CloseIcon
                style={{
                  width: "8px",
                  height: "8px",
                }}
              />
            </Pill>
          );
        } else {
          return null;
        }
      })}
      <Button
        size="small"
        buttonType="text"
        text="Clear all"
        onClick={() => resetActiveFilters(RESET)}
        style={{
          marginLeft: "1.5rem",
        }}
      />
    </ActiveFilterPillsWrapper>
  );
};

export default ActiveFilterPills;
