import { _LayerSettings } from "./../components/LayerList/LayerSettings/types";
import { useLayerSettingsCrud } from "../components/LayerList/LayerSettings/useLayerSettingsCrud";
import { _BackendLayerCollection } from "../components/LayerList/Collections/service";
import {
  ABLY_COLLECTION_UPDATE,
  ABLY_COLLECTION_DELETE,
  ABLY_LAYER_UPDATE,
  ABLY_LAYER_DELETE,
} from "../state/ably";
import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { useCollectionCrud } from "../components/LayerList/Collections/useCollectionCrud";

export function useAblyLayer(projectId: string) {
  const { removeLocal, putLocal } = useCollectionCrud();
  const { putLocal: putLocalLayerSettings, delLocal: delLocalLayerSettings } =
    useLayerSettingsCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onUpdateLayerSettings = useCallback(
    (message: InboundMessage) => {
      const layers = _LayerSettings.array().parse(message.data.layers);
      putLocalLayerSettings(projectId, layers);
    },
    [putLocalLayerSettings, projectId],
  );
  const onDeleteLayerSettings = useCallback(
    (message: InboundMessage) => {
      const layers = _LayerSettings.array().parse(message.data.layers);
      delLocalLayerSettings(
        projectId,
        layers.map((l) => l.id),
      );
    },
    [delLocalLayerSettings, projectId],
  );
  const onUpdateCollection = useCallback(
    (message: InboundMessage) => {
      const collection = _BackendLayerCollection.parse(message.data.collection);
      putLocal(projectId, collection);
    },
    [putLocal, projectId],
  );
  const onDeleteCollection = useCallback(
    (message: InboundMessage) => {
      const { collectionId } = message.data;
      removeLocal(projectId, collectionId);
    },
    [removeLocal, projectId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_LAYER_UPDATE,
        onMessageReceived: onUpdateLayerSettings,
      },
      {
        eventName: ABLY_LAYER_DELETE,
        onMessageReceived: onDeleteLayerSettings,
      },
      {
        eventName: ABLY_COLLECTION_UPDATE,
        onMessageReceived: onUpdateCollection,
      },
      {
        eventName: ABLY_COLLECTION_DELETE,
        onMessageReceived: onDeleteCollection,
      },
    ],
    [
      onDeleteCollection,
      onUpdateCollection,
      onUpdateLayerSettings,
      onDeleteLayerSettings,
    ],
  );

  useAblyGeneric(channelName, events, projectId);
}
