import { useAtom } from "jotai";
import {
  lowerRightMenuActiveModeAtom,
  costLayerVariablesAtom,
} from "../../state/layer";
import CostSlider from "../CostSlider/CostSlider";
import { InputDimensioned } from "../General/Input";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { Grid2 } from "./styles";
import { Column } from "../General/Layout";
import { spaceMedium, spaceSmall } from "../../styles/space";
import HelpTooltip, {
  ARTICLE_LCOE_MAP,
  HelpLink,
} from "../HelpTooltip/HelpTooltip";
import { activeOtherMapIdAtom } from "state/map";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useSetAtom } from "jotai";

const CostInput = () => {
  const [costInput, setCostInput] = useAtom(costLayerVariablesAtom);
  const [lowerRightActiveMode, setLowerRightActiveMode] = useAtom(
    lowerRightMenuActiveModeAtom,
  );
  const setOtherMap = useSetAtom(activeOtherMapIdAtom);

  if (lowerRightActiveMode !== "cost") return null;

  return (
    <MenuFrame
      title="LCoE map"
      onExit={() => {
        setLowerRightActiveMode(undefined), setOtherMap(undefined);
      }}
      style={{
        width: "35rem",
        maxHeight:
          "calc(100vh - var(--top-bar-menu-height) - var(--branch-tab-bar-height) - var(--side-bars-width) - 5.6rem)",
      }}
      icon={<HelpLink article={ARTICLE_LCOE_MAP} />}
    >
      <Column
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        <p>
          {
            "The production is calculated using capacity factors from Global Wind Atlas (IEC Class II), no losses, 25 years of operation and discount rate of 5%."
          }
        </p>

        <SubtitleWithLine text="Options" />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2.2fr 0.6fr 0.8fr 1fr",
            gap: spaceSmall,
          }}
        >
          <p
            style={{
              display: "flex",
              gap: "0.5rem",
              gridColumn: "span 3",
            }}
          >
            Transition depth from fixed to floating
            <HelpTooltip text={"Source: Gebco"} size={8} />
          </p>
          <InputDimensioned
            compact
            value={costInput.fixedFoundationMaxDepth}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            unit={"m"}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                fixedFoundationMaxDepth: n,
              })
            }
            style={{
              width: "8rem",
            }}
            step={1}
          />
        </div>
        <SubtitleWithLine text="CAPEX" />
        <SimpleAlert type="info">
          <p>
            WD = Water depth <strong>(m)</strong>. Source: Gebco.
            <br />
            SD = Shore distance <strong>(km)</strong>
            <br />
            Input unit = <strong>k€/MW</strong>
          </p>
        </SimpleAlert>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 0.8fr 0.8fr 1fr",
            gap: spaceMedium,
          }}
        >
          <p
            style={{
              gridColumn: "span 3",
            }}
          >
            Turbines
          </p>
          <InputDimensioned
            compact
            value={costInput.turbinesPerMw}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                turbinesPerMw: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={10}
          />
          <p>Fixed foundations</p>
          <InputDimensioned
            compact
            value={costInput.fixedFoundationPerMw}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                fixedFoundationPerMw: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={10}
          />
          <p
            style={{
              textAlign: "end",
            }}
          >
            + WD ×
          </p>
          <InputDimensioned
            compact
            value={costInput.fixedFoundationPerMwDepth}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                fixedFoundationPerMwDepth: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={0.5}
          />
          <p>Floating foundations</p>
          <InputDimensioned
            compact
            value={costInput.floatingFoundationPerMw}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                floatingFoundationPerMw: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={10}
          />
          <p
            style={{
              textAlign: "end",
            }}
          >
            + WD ×
          </p>
          <InputDimensioned
            compact
            value={costInput.floatingFoundationPerMwDepth}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                floatingFoundationPerMwDepth: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={0.5}
          />

          <p>Export cable</p>
          <p />
          <p
            style={{
              textAlign: "end",
            }}
          >
            SD ×
          </p>
          <InputDimensioned
            compact
            value={costInput.exportCablePerShoreDistance}
            validate={(n) => 0 <= n}
            validationMessage={`Must be positive number`}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                exportCablePerShoreDistance: n,
              })
            }
            style={{
              width: "6rem",
            }}
            step={0.1}
          />
        </div>
        <SubtitleWithLine text="OPEX" />
        <Grid2
          style={{
            gridTemplateColumns: "3.6fr 1fr",
          }}
        >
          <p>Service and other</p>
          <InputDimensioned
            compact
            unit={"k€/MW/yr"}
            value={costInput.opexPerMw}
            validate={(n) => 0 <= n}
            onChange={(n) =>
              setCostInput({
                ...costInput,
                opexPerMw: n,
              })
            }
          />
        </Grid2>

        <SubtitleWithLine text="Filter result" />
        <CostSlider />
      </Column>
    </MenuFrame>
  );
};

export default CostInput;
