import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import {
  _FoundationProcurementCost,
  _ExportCableProcurementCost,
  _InterArrayCableProcurementCost,
  _TurbineProcurementCost,
  ExportCableProcurementCost,
  InterArrayCableProcurementCost,
  listProcurementCosts,
  ProcurementCost,
  TurbineProcurementCost,
  ProcurementCostComponentType,
} from "services/procurementCostService";
import { getLibraryTurbineIdentifiers } from "services/turbineAPIService";
import { TurbineIdentifiers } from "types/turbines";
import { atomFromFn } from "utils/jotai";
import { scream } from "utils/sentry";
import { z } from "zod";
import {
  ExportSystemType,
  getLibraryCableIdentifiers,
} from "services/cableTypeService";
import { getSubstationIdentifiers } from "services/substationService";
import { SubstationIdentifier } from "types/substation";

export const organisationProcurementCostsAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<Promise<ProcurementCost[]>>(async () => {
      return await listProcurementCosts(organisationId)
        .then((res) => res)
        .catch((error) => {
          scream("Failed to fetch procurement costs", {
            organisationId,
            error: error as Error,
          });
          throw error;
        });
    }),
  (a, b) => a === b,
);

export const turbineIdentifierAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<Promise<TurbineIdentifiers[]>>(async () => {
      if (!organisationId) return [];
      try {
        const turbineIdentifiers =
          await getLibraryTurbineIdentifiers(organisationId);
        return turbineIdentifiers.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        scream("Failed to fetch turbine identifiers", {
          organisationId,
          error: error as Error,
        });
        throw error;
      }
    }),
  (a, b) => a === b,
);

export const turbineProcurementCostsAtom = atomFamily(
  (organisationId: string) =>
    atom<Promise<Map<string, TurbineProcurementCost>>>(async (get) => {
      const procurementCosts = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      const turbineCosts = procurementCosts.filter(
        (cost) => cost.componentType === "turbine",
      );
      const turbines = z.array(_TurbineProcurementCost).parse(turbineCosts);
      return new Map(turbines.map((turbine) => [turbine.componentId, turbine]));
    }),
  (a, b) => a === b,
);

export const foundationProcurementCostsAtom = atomFamily(
  (organisationId: string | undefined) =>
    atom(async (get) => {
      if (!organisationId) return [];

      const procurementCosts = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      return z.array(_FoundationProcurementCost).parse(
        procurementCosts
          .filter((cost) => cost.componentType === "foundation")
          .sort((a, b) => {
            if (
              typeof a?.createdAt !== "number" ||
              typeof b?.createdAt !== "number"
            ) {
              return 0;
            }
            return b.createdAt - a.createdAt;
          }),
      );
    }),
  (a, b) => a === b,
);

// Identifiers for export cables and inter array cables
// Exported for possibility to reset
export const cableIdentifierAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<
      Promise<
        Map<string, { name: string; voltage: number; exportCableType?: string }>
      >
    >(async () => {
      if (!organisationId) return new Map();

      try {
        const cableIdentifiers =
          await getLibraryCableIdentifiers(organisationId);
        const sortedCableIdentifiers = cableIdentifiers.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        return new Map(
          sortedCableIdentifiers.map((cable) => [cable.id, cable]),
        );
      } catch (error) {
        scream(new Error("Failed to fetch cable identifiers"), {
          organisationId,
          error: error as Error,
        });
        return new Map();
      }
    }),
  (a, b) => a === b,
);

export const interArrayCableIdentifierAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<
      Promise<Map<string, { id: string; name: string; voltage: number }>>
    >(async (get) => {
      if (!organisationId) return new Map();
      const cableIdentifiers = await get(cableIdentifierAtom(organisationId));

      //Clone cableIdentifiers map and filter out export cables
      const interArrayCableIdentifiers = new Map(cableIdentifiers);
      for (const [key, value] of cableIdentifiers) {
        if (value.exportCableType) {
          interArrayCableIdentifiers.delete(key);
        }
      }
      return interArrayCableIdentifiers;
    }),
  (a, b) => a === b,
);

export const interArrayCableProcurementCostsAtom = atomFamily(
  (organisationId: string) =>
    atom<Promise<Map<string, InterArrayCableProcurementCost>>>(async (get) => {
      const procurementCosts = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      const cableCosts = procurementCosts.filter(
        (cost) => cost.componentType === "interArrayCable",
      );
      const parsedCosts = _InterArrayCableProcurementCost
        .array()
        .parse(cableCosts);
      return new Map(parsedCosts.map((cost) => [cost.componentId, cost]));
    }),
  (a, b) => a === b,
);

export const exportCableIdentifierAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<
      Promise<
        Map<
          string,
          {
            id: string;
            name: string;
            exportCableType: ExportSystemType;
            voltage: number;
          }
        >
      >
    >(async (get) => {
      if (!organisationId) return new Map();
      const cableIdentifiers = await get(cableIdentifierAtom(organisationId));

      //Clone cableIdentifiers map and filter out inter array cables
      const exportCableIdentifiers = new Map(cableIdentifiers);
      for (const [key, value] of cableIdentifiers) {
        if (!value.exportCableType) {
          exportCableIdentifiers.delete(key);
        }
      }
      return exportCableIdentifiers;
    }),
  (a, b) => a === b,
);

export const exportCableProcurementCostsAtom = atomFamily(
  (organisationId: string) =>
    atom<Promise<Map<string, ExportCableProcurementCost>>>(async (get) => {
      const procurementCosts = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      const cableCosts = procurementCosts.filter(
        (cost) => cost.componentType === "exportCable",
      );
      const parsedCosts = _ExportCableProcurementCost.array().parse(cableCosts);

      return new Map(parsedCosts.map((cost) => [cost.componentId, cost]));
    }),
  (a, b) => a === b,
);

export const substationIdentifierAtom = atomFamily(
  (organisationId: string) =>
    atomFromFn<Promise<SubstationIdentifier[]>>(async () => {
      if (!organisationId) return [];
      try {
        const substationIdentifiers =
          await getSubstationIdentifiers(organisationId);
        return substationIdentifiers.sort((a, b) =>
          (a.name ?? "").localeCompare(b.name ?? ""),
        );
      } catch (error) {
        scream("Failed to fetch substation identifiers", {
          organisationId,
          error: error as Error,
        });
        return [];
      }
    }),
  (a, b) => a === b,
);

export const activeProcurementCostIdAtom =
  atom<ProcurementCostComponentType>("turbine");
