import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useEffect } from "react";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { customerProjectAtomFamily } from "../../state/timeline";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";

const LOCAL_STORAGE_PREVIOUSLY_SELECTED_BRANCH =
  "previouslySelectedBranchForProject";

const saveBranchSelectionInLocalStorage = (
  projectId: string,
  branchId: string,
) =>
  localStorage.setItem(
    LOCAL_STORAGE_PREVIOUSLY_SELECTED_BRANCH + projectId,
    branchId,
  );

const readPreviouslySelectedBranchForProjectFromLocalStorage = (
  projectId: string,
) => localStorage.getItem(LOCAL_STORAGE_PREVIOUSLY_SELECTED_BRANCH + projectId);

const navigateToBranch = (
  navigate: NavigateFunction,
  location: Location,
  branchId: string,
) =>
  navigate(
    {
      pathname: `${location.pathname}/${branchId}`,
      search: location.search,
    },
    {
      replace: true,
    },
  );

export const SaveSelectedBranch = () => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  useEffect(() => {
    if (!projectId || !branchId) return;
    saveBranchSelectionInLocalStorage(projectId, branchId);
  }, [branchId, projectId]);

  return null;
};

const SelectCorrectBranchInner = ({ nodeId }: { nodeId: string }) => {
  const branches = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId,
    }),
  );

  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const previousSelectedBranchForProject =
      readPreviouslySelectedBranchForProjectFromLocalStorage(nodeId);

    if (
      previousSelectedBranchForProject &&
      branches.find((b) => b.id === previousSelectedBranchForProject)
    ) {
      navigateToBranch(navigate, location, previousSelectedBranchForProject);
      return;
    }

    if (
      project &&
      project.main_branch_id &&
      branches.find((b) => b.id === project.main_branch_id)
    ) {
      navigateToBranch(navigate, location, project.main_branch_id);
    }

    if (branches.length > 0) {
      navigateToBranch(navigate, location, branches[0].id);
    }
  }, [nodeId, branches, project, navigate, location]);

  return null;
};

const SelectCorrectBranch = () => {
  const projectId = useAtomValue(projectIdAtom);

  if (!projectId) return null;

  return <SelectCorrectBranchInner nodeId={projectId} />;
};

export default SelectCorrectBranch;
