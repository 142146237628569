import { atom } from "jotai";
import { TurbineSettingsActiveType } from "../components/GenerateWindTurbines/TurbineSettings";
import { PlaceWindTurbinesMenuType } from "../components/GenerateWindTurbines/PlaceWindTurbines";
import { MeasureDistanceMode } from "../components/Distance/Distance";
import {
  PlaceGoZoneType,
  PlaceNoGoZoneType,
  PlaceZoneType,
} from "../components/DivisionDesign/DivisionConst";
import {
  AreaMenuType,
  ConstraintsMenuType,
  AddFeaturesMenuType,
  GenerateAnchorsMenuType,
  GenerateFoundationsMenuType,
  GenerateWindTurbinesAndFoundationsMenuType,
  TurbinesMenuType,
  SiteLocatorMenuType,
} from "../constants/projectMapView";
import {
  AddSubStationMenuType,
  GenerateCablesMenuType,
  DrawExportCableMenuType,
  DrawCableCorridorMenuType,
  CablesMenuType,
  DrawCableMenuType,
  CableFreeZoneMenuType,
} from "../constants/cabling";
import {
  DrawLineStringMenuType,
  DrawParkMenuType,
  DrawPolygonMenuType,
  DrawPointMenuType,
  DrawMooringLineMenuType,
  DrawViewPointMenuType,
  DrawPortMenuType,
  DrawAnchorMenuType,
  DrawCircleMenuType,
  DrawRectangleMenuType,
  DrawExistingTurbineMenuType,
  DrawSensorPointMenuType,
} from "../constants/draw";
import { ProjectElementMenuType, UploadMenuParkType } from "../constants/park";
import {
  WaveSourceMenuType,
  WindSourceMenuType,
} from "../components/WindWaveSource/utils";
import { maxWindSpeed, minWindSpeed } from "../types/filter";
import { z } from "zod";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { Feature, Point } from "geojson";

export type TileStatus = "pending" | "processing" | "complete" | "error";

export type LeftMenuModeType =
  | undefined
  | typeof WindSourceMenuType
  | typeof WaveSourceMenuType
  | typeof ProjectElementMenuType
  | typeof TurbineSettingsActiveType
  | typeof TurbinesMenuType
  | typeof GenerateWindTurbinesAndFoundationsMenuType
  | typeof GenerateAnchorsMenuType
  | typeof GenerateFoundationsMenuType
  | typeof DrawLineStringMenuType
  | typeof DrawParkMenuType
  | typeof DrawPolygonMenuType
  | typeof DrawCircleMenuType
  | typeof DrawRectangleMenuType
  | typeof DrawPointMenuType
  | typeof PlaceZoneType
  | typeof PlaceGoZoneType
  | typeof PlaceNoGoZoneType
  | typeof PlaceWindTurbinesMenuType
  | typeof AddSubStationMenuType
  | typeof GenerateCablesMenuType
  | typeof DrawExportCableMenuType
  | typeof DrawCableCorridorMenuType
  | typeof CablesMenuType
  | typeof MeasureDistanceMode
  | typeof UploadMenuParkType
  | typeof AreaMenuType
  | typeof ConstraintsMenuType
  | typeof AddFeaturesMenuType
  | typeof DrawMooringLineMenuType
  | typeof DrawViewPointMenuType
  | typeof DrawSensorPointMenuType
  | typeof DrawPortMenuType
  | typeof DrawAnchorMenuType
  | typeof DrawExistingTurbineMenuType
  | typeof DrawCableMenuType
  | typeof CableFreeZoneMenuType
  | typeof SiteLocatorMenuType;

/**
 * NOTE: Unless you only want to read this atom, you probably want to use
 * {@link useDrawMode} instead, because this hook handles the logic for
 * transitioning between different states.
 */
export const leftMenuModeActiveAtom = atom<LeftMenuModeType>(undefined);

export const renderDepthFilterAtom = atom<boolean>(true);

const maxDepth = 30_000;

export const depthFilterAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomLocalStorage(
      `vind:depth-filter:${projectId}`,
      [100, maxDepth],
      z.number().array(),
    ),
);

export const renderShoreDistanceAtom = atom<boolean>(true);

export const maxShorelineDistance = 250;
export const maxShoreline = 30_000;

export const shoreDistanceFilterAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomLocalStorage(
      `vind:shore-distance-filter:${projectId}`,
      [0, maxShorelineDistance],
      z.number().array(),
    ),
);

export const renderWindSpeedAtom = atom<boolean>(true);

export const windSpeedFilterAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomLocalStorage(
      `vind:wind-speed-filter:${projectId}`,
      [minWindSpeed, maxWindSpeed],
      z.number().array(),
    ),
);

export const renderHouseDistanceFilterAtom = atom<boolean>(true);

export const renderHouseDistanceFilterTypeSetAtom = atom<Set<string>>(
  new Set<string>(),
);

export const tilesToRequestAtom = atom<
  Record<string, { status: TileStatus; result?: Feature<Point>[] }>
>({});

export const renderHouseDistanceFilterTilesLoadingAtom = atom<number>(
  (get) =>
    Object.values(get(tilesToRequestAtom)).filter((t) =>
      ["pending", "processing"].includes(t.status),
    ).length,
);

export const houseDistanceFilterAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomLocalStorage(
      `vind:residential-distance-filter:${projectId}`,
      800,
      z.number(),
    ),
);
