import { useState, useRef } from "react";
import { Column } from "../../General/Layout";
import Button from "../../General/Button";
import TrashIcon from "@icons/24/Bin.svg";
import AddIcon from "@icons/24/Add.svg";
import { IconREMSize } from "styles/typography";
import { EditableText } from "components/General/EditableText";
import { DeleteButton, SettingsRowWithHover } from "./shared.style";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import { Tag } from "services/tagsService";
import { EmptyStateDescription } from "./EmptyStateDescription";
import TagsIcon from "@icons/24/Tags.svg";
import { colors } from "styles/colors";
import { v4 as uuidv4 } from "uuid";
import { useClickOutside } from "hooks/useClickOutside";

interface TagsSettingsProps {
  isAdmin: boolean;
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  organisationId: string;
}

export const TagsSettings = ({
  isAdmin,
  tags,
  setTags,
  organisationId,
}: TagsSettingsProps) => {
  const [focusedTagId, setFocusedTagId] = useState<string | null>(null);
  const [prevName, setPrevName] = useState<string | null>(null);
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  useClickOutside(
    tagsContainerRef,
    () => {
      if (focusedTagId) {
        const tag = tags.find((t) => t.id === focusedTagId);
        if (tag && (!tag.name || tag.name.trim() === "")) {
          setTags(
            tags.map((t) =>
              t.id === focusedTagId ? { ...t, name: prevName ?? "New tag" } : t,
            ),
          );
        }
        setFocusedTagId(null);
        setPrevName(null);
      }
    },
    undefined,
    { runCheckOnMouseDown: true },
  );

  const handleCreateTag = () => {
    const newTagId = uuidv4();
    setTags([{ id: newTagId, name: "New tag" }, ...tags]);
    setFocusedTagId(newTagId);
  };

  const handleTagClick = (tagId: string) => {
    if (!isAdmin) return;
    if (focusedTagId !== tagId) {
      const tag = tags.find((t) => t.id === tagId);
      setPrevName(tag?.name ?? "New tag");
      setFocusedTagId(tagId);
    }
  };

  const handleNameChange = (tagId: string, name: string) => {
    setTags(tags.map((t) => (t.id === tagId ? { ...t, name } : t)));
  };

  return (
    <Column style={{ width: "100%", height: "100%" }}>
      {tags.length === 0 ? (
        <EmptyState
          style={{
            width: "initial",
          }}
          title="No tags yet"
          descriptionComponent={
            <EmptyStateDescription
              organisationId={organisationId}
              type="tags"
              isOrgAdmin={isAdmin}
            />
          }
          icon={
            <IconREMSize height={1.4} width={1.4} iconColor={colors.white}>
              <TagsIcon />
            </IconREMSize>
          }
          actionButton={
            isAdmin ? (
              <Button
                text="Create tag"
                buttonType="primary"
                onClick={handleCreateTag}
              />
            ) : undefined
          }
        />
      ) : (
        <>
          {isAdmin && (
            <Button
              text="New"
              buttonType="secondary"
              icon={<AddIcon />}
              onClick={handleCreateTag}
              style={{ marginBottom: "1.2rem", marginLeft: "auto" }}
            />
          )}
          <Column
            ref={tagsContainerRef}
            style={{
              maxHeight: "21rem",
              overflowY: "auto",
              gap: "0.2rem",
            }}
          >
            {tags.map((tag) => (
              <SettingsRowWithHover
                key={tag.id}
                isFocused={focusedTagId === tag.id}
                withEveryOtherGray={true}
                onClick={() => handleTagClick(tag.id)}
              >
                <EditableText
                  disabled={!isAdmin}
                  smallInput={true}
                  value={tag.name}
                  initialEditing={focusedTagId === tag.id}
                  onChange={(e) => handleNameChange(tag.id, e.target.value)}
                  style={{
                    width: "100%",
                  }}
                  textContainerStyle={{ width: "100%" }}
                  onEnter={() => {
                    if (!tag.name || tag.name.trim() === "") {
                      handleNameChange(tag.id, prevName ?? "New tag");
                    }
                    setFocusedTagId(null);
                    setPrevName(null);
                  }}
                  onFocus={() => handleTagClick(tag.id)}
                  onClick={(e) => e.stopPropagation()}
                />
                {isAdmin && (
                  <DeleteButton
                    onClick={() => setTags(tags.filter((t) => t.id !== tag.id))}
                  >
                    <IconREMSize height={1.4} width={1.4}>
                      <TrashIcon />
                    </IconREMSize>
                  </DeleteButton>
                )}
              </SettingsRowWithHover>
            ))}
          </Column>
        </>
      )}
    </Column>
  );
};
