import { z } from "zod";
import { fetchSchemaWithToken } from "./utils";
import { components } from "api/organisation-settings";

export const _Tag = components.schemas.Tag;
export type Tag = z.infer<typeof _Tag>;

export async function getTags(organisationId: string) {
  return fetchSchemaWithToken(
    z.object({
      tags: z.array(_Tag),
    }),
    `/api/organisation-settings/organisation/${organisationId}/tags`,
    {
      method: "get",
    },
  );
}

export async function updateTags(
  organisationId: string,
  tags: Tag[],
): Promise<Tag[]> {
  const response = await fetchSchemaWithToken(
    z.object({
      tags: z.array(_Tag),
    }),
    `/api/organisation-settings/organisation/${organisationId}/tags`,
    {
      method: "put",
      body: JSON.stringify({ tags }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response.tags;
}
