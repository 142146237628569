import { createContext, ReactNode, useRef, useState } from "react";
import useAnimation from "./useAnimation";

import useCreateThreeCore, { ThreeCoreParkShadow } from "./useCreateThreeCore";
import useResizeThree from "../../ViewToPark/ThreeContext/useResizeThree";
import useTurbines from "./useTurbines";
import useParkShadowTerrain, { ParkShadowTerrain } from "./useParkShadowMeshes";
import useUpdateSunPos from "./useUpdateSunPos";
import useParkShadowOSMBuildings, {
  ParkShadowOSMBuildings,
} from "./useParkShadowOSMBuildings";
import { useUpdateTerrainTurbineRadiusSize } from "./useUpdateTerrainTurbineRadiusSize";

export const ViewParkShadowContext = createContext<{
  threeCore: ThreeCoreParkShadow | undefined;
  threeSceneRef: React.RefObject<HTMLDivElement> | undefined;
}>({
  threeCore: undefined,
  threeSceneRef: undefined,
});

export default function ViewParkShadowContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [terrainLoaded, setTerrainLoaded] = useState(false);
  const threeCore = useCreateThreeCore();
  const terrainLoadable = useParkShadowTerrain();
  const parkShadowTerrain =
    terrainLoadable.state === "hasData" ? terrainLoadable.data.mesh : undefined;
  const parkShadowShadowMesh =
    terrainLoadable.state === "hasData"
      ? terrainLoadable.data.shadowMesh
      : undefined;
  const parkShadowOSMBuildings = useParkShadowOSMBuildings(
    parkShadowTerrain,
    terrainLoaded,
  );
  const threeSceneRef = useRef<HTMLDivElement>(null);

  const { turbineBladeGroup } = useTurbines(
    threeCore,
    parkShadowTerrain,
    terrainLoaded,
  );
  useAnimation(threeCore, turbineBladeGroup);
  useUpdateSunPos(threeCore);
  useResizeThree(threeCore, threeSceneRef);
  useUpdateTerrainTurbineRadiusSize(parkShadowTerrain);

  return (
    <ViewParkShadowContext.Provider value={{ threeCore, threeSceneRef }}>
      <ParkShadowTerrain
        threeCore={threeCore}
        parkShadowTerrain={parkShadowTerrain}
        parkShadowShadowMesh={parkShadowShadowMesh}
        setTerrainLoaded={setTerrainLoaded}
      />
      <ParkShadowOSMBuildings
        threeCore={threeCore}
        parkShadowOSMBuildings={parkShadowOSMBuildings}
      />
      {children}
    </ViewParkShadowContext.Provider>
  );
}
