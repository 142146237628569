import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { AutosaveRequestEntry, SaveRequestEntry } from "./type";
import {
  listAutoSavedFeatureHistory,
  listSavedFeatureHistory,
} from "./service";

export const autosaveFeatureHistoryAtomFamily = atomFamily(
  ({ projectId, branchId }: { projectId: string; branchId: string }) =>
    atomFromFn<Promise<AutosaveRequestEntry[]>>(() =>
      listAutoSavedFeatureHistory({
        projectId,
        branchId,
      }),
    ),
);

export const saveFeatureHistoryAtomFamily = atomFamily(
  ({ projectId, branchId }: { projectId: string; branchId: string }) =>
    atomFromFn<Promise<SaveRequestEntry[]>>(() =>
      listSavedFeatureHistory({
        projectId,
        branchId,
      }),
    ),
);

type SplitFeatureType =
  | {
      type: "autosave";
      request: AutosaveRequestEntry;
    }
  | {
      type: "save";
      request: SaveRequestEntry;
    };

export const splitFeatureHistoryAtomFamily = atomFamily(
  ({ projectId, branchId }: { projectId: string; branchId: string }) =>
    atom<Promise<SplitFeatureType[]>>(async (get) => {
      const autosaves = await get(
        autosaveFeatureHistoryAtomFamily({
          projectId,
          branchId,
        }),
      );
      const saves = await get(
        saveFeatureHistoryAtomFamily({
          projectId,
          branchId,
        }),
      );

      return autosaves
        .map((request) => ({
          type: "autosave",
          request,
        }))
        .concat(
          saves.map((request) => ({
            type: "save",
            request,
          })),
        )
        .sort(
          (a, b) => b.request.timestamp - a.request.timestamp,
        ) as SplitFeatureType[];
    }),
);
