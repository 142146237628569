import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import { spaceMedium, spaceSmall } from "../../styles/space";

export const ValidationContainer = styled(StandardBox)`
  text-align: center;
  // border: 1px solid ${colors.warning};
  padding: ${spaceSmall} ${spaceMedium};
  white-space: nowrap;
  p {
    color: ${colors.warning};
    margin: 0;
  }
`;
