import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { Suspense, useMemo } from "react";
import styled from "styled-components";
import Like from "../../General/Like";
import { UserInfoProject } from "../../UserInfo/UserInfo";
import { UserInfoLoading } from "components/UserInfo/UserInfoLoading";
import { useReplyReactionCrud } from "../hooks/useReplyReactionCrud";
import {
  replyReactionsAtomFamily,
  userReactionsAtomFamily,
  userReactionSaveInProgressState,
} from "../state";
import { Reply } from "../types";
import { useAtomValue } from "jotai";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default function ReplyReactions({
  reply,
  nodeId,
  parentIsHovered,
  threadIsSelected,
}: {
  reply: Reply;
  nodeId: string;
  parentIsHovered?: boolean;
  threadIsSelected?: boolean;
}) {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const currentUserReactions = useAtomValue(
    userReactionsAtomFamily({
      nodeId,
      branchId,
    }),
  );
  const replyReactionSaveInProgress = useAtomValue(
    userReactionSaveInProgressState,
  );
  const { post, remove } = useReplyReactionCrud();

  const currentUserReplyReaction = useMemo(
    () => currentUserReactions.find((r) => r.replyId === reply.replyId),
    [currentUserReactions, reply.replyId],
  );

  return (
    <Container>
      <Like
        parentIsHovered={parentIsHovered}
        threadIsSelected={threadIsSelected}
        like={
          !!currentUserReplyReaction ||
          replyReactionSaveInProgress === reply.replyId
        }
        disabled={replyReactionSaveInProgress === reply.replyId}
        totalLikes={reply.reactions}
        onClick={() =>
          currentUserReplyReaction
            ? remove(
                reply.threadId,
                reply.replyId,
                currentUserReplyReaction.reactionId,
              )
            : post(reply.threadId, reply.replyId)
        }
        reactionList={
          <Suspense
            fallback={
              <Column>
                {Array.from(Array(reply.reactions).keys()).map((_, i) => (
                  <UserInfoLoading key={i} />
                ))}
              </Column>
            }
          >
            <ReactionsList reply={reply} />
          </Suspense>
        }
      />
    </Container>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
function ReactionsList({ reply }: { reply: Reply }) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const reactions = useAtomValue(
    replyReactionsAtomFamily({
      nodeId: reply.nodeId,
      branchId: reply.branchId,
      threadId: reply.threadId,
      replyId: reply.replyId,
    }),
  );
  return (
    <Column
      style={{
        pointerEvents: "none",
      }}
    >
      {reactions.map((r) => (
        <UserInfoProject
          key={r.userId}
          userId={r.userId}
          projectId={projectId}
        />
      ))}
    </Column>
  );
}
