import Foundations from "@icons/24/Foundation.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { useAtom, useAtomValue } from "jotai";
import {
  foundationInstallationCategoriesToDisplayAtom,
  Title,
} from "./FoundationsVessels";
import { FoundationInstallationCategorySelector } from "./FoundationInstallationCategorySelector";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { Row } from "components/General/Layout";

const JacketSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { activities } = localConfig.ti.foundations.jackets;
  return (
    <>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "20rem 20rem 20rem 20rem",
          }}
        >
          <SubHeader>Load jacket on vessel</SubHeader>
          <SubHeader>Lay pin template</SubHeader>
          <SubHeader>Position pile</SubHeader>
          <SubHeader>Drive pile</SubHeader>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem  " }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.loadJacketOnVessel`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.loadJacketOnVessel}
            step={1}
            validate={(e) => e >= 1 && e <= 30}
            validationMessage={`Must be between 1 and 30`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      loadJacketOnVessel: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.layPinTemplate`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.layPinTemplate}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      layPinTemplate: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.positionPile`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.positionPile}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      positionPile: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.drivePile`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.drivePile}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      drivePile: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
        </Grid2>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Lift and lower jacket</SubHeader>
          <SubHeader>Grout jacket</SubHeader>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.liftAndLowerJacket`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.liftAndLowerJacket}
            step={1}
            validate={(e) => e >= 1 && e <= 30}
            validationMessage={`Must be between 1 and 30`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      liftAndLowerJacket: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.jackets.activities.groutJacket`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.groutJacket}
            step={1}
            validate={(e) => e >= 1 && e <= 30}
            validationMessage={`Must be between 1 and 30`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    activities: {
                      ...activities,
                      groutJacket: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
    </>
  );
};

const MonopileSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;

  const { activities } = localConfig.ti.foundations.monopiles;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Load monopile on vessel</SubHeader>
        <SubHeader>Load TP on vessel</SubHeader>
        <SubHeader>Upend and position monopile</SubHeader>
        <SubHeader>Drive monopile</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.foundations.monopiles.activities.loadMonopileOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadMonopileOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  activities: {
                    ...activities,
                    loadMonopileOnVessel: Math.round(duration),
                  },
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.foundations.monopiles.activities.loadTPOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadTPOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  activities: {
                    ...activities,
                    loadTPOnVessel: Math.round(duration),
                  },
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.foundations.monopiles.activities.upendAndPositionMonopile`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.upendAndPositionMonopile}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  activities: {
                    ...activities,
                    upendAndPositionMonopile: Math.round(duration),
                  },
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.foundations.monopiles.activities.driveMonopile`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.driveMonopile}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  activities: {
                    ...activities,
                    driveMonopile: Math.round(duration),
                  },
                },
              },
            })
          }
        />
      </Grid2>
      <Grid2
        style={{
          gridTemplateColumns: "auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Lift and bolt TP</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.foundations.monopiles.activities.liftAndBoltTP`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndBoltTP}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  activities: {
                    ...activities,
                    liftAndBoltTP: Math.round(duration),
                  },
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

const FloaterSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;

  const { activities } = localConfig.ti.foundations.floaters;

  return (
    <>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Assemble turbine on floater</SubHeader>
          <SubHeader>Ballast to towing draft</SubHeader>
          <SubHeader>Ballast to operational draft</SubHeader>
          <SubHeader>Recover mooring line</SubHeader>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.floaters.activities.assembleTurbineOnFloater`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.assembleTurbineOnFloater}
            step={1}
            validate={(e) => e >= 1 && e <= 200}
            validationMessage={`Must be between 1 and 200`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    activities: {
                      ...activities,
                      assembleTurbineOnFloater: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.floaters.activities.ballastToTowingDraft`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.ballastToTowingDraft}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    activities: {
                      ...activities,
                      ballastToTowingDraft: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.floaters.activities.ballastToOperationalDraft`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.ballastToOperationalDraft}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    activities: {
                      ...activities,
                      ballastToOperationalDraft: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.floaters.activities.recoverMooringLine`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.recoverMooringLine}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    activities: {
                      ...activities,
                      recoverMooringLine: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
        </Grid2>
        <Grid2
          style={{
            gridTemplateColumns: "auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Connect and tension mooring line</SubHeader>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              `ti.foundations.floaters.activities.connectAndTensionMooringLine`,
              "project",
            )}
            disabled={isReadOnly}
            unit={"hours"}
            value={activities.connectAndTensionMooringLine}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            initialValue={10}
            onChange={(duration) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    activities: {
                      ...activities,
                      connectAndTensionMooringLine: Math.round(duration),
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
    </>
  );
};

const FoundationTypeActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const foundationCategoriesToDisplay = useAtomValue(
    foundationInstallationCategoriesToDisplayAtom,
  );

  return (
    <>
      {foundationCategoriesToDisplay.includes("Jacket") && (
        <>
          <Title>Jacket</Title>
          <JacketSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Monopile") && (
        <>
          <Title>Monopile</Title>
          <MonopileSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Floating") && (
        <>
          <Title>Floating</Title>
          <FloaterSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
    </>
  );
};

export const FoundationActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const [, setFoundationCategoriesToDisplay] = useAtom(
    foundationInstallationCategoriesToDisplayAtom,
  );

  return (
    <InstallationCategoryEntry
      title="Foundations"
      icon={<Foundations />}
      defaultOpen={true}
    >
      <FoundationInstallationCategorySelector
        onSelectionChange={(foundationCategory) => {
          setFoundationCategoriesToDisplay((prev) => {
            if (prev.includes(foundationCategory)) {
              return prev.filter((f) => f !== foundationCategory);
            }
            return [...prev, foundationCategory];
          });
        }}
      />
      <FoundationTypeActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
