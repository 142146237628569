import { useAtomValue } from "jotai";
import { IAcableTypesFamily } from "state/jotai/cableType";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { featuresListAtom } from "state/jotai/features";
import { foundationTypesAtom } from "state/jotai/foundation";
import { mooringLineTypesAtom } from "state/jotai/mooringLineType";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";

export default function useComponentsInUse() {
  const allBranchFeatures = useAtomValue(featuresListAtom);
  const allCableTypesInUse = Array.from(
    useAtomValue(IAcableTypesFamily({ projectId: undefined })).values(),
  ).filter((c) =>
    allBranchFeatures.some((f) => f.properties.cableTypeId === c.id),
  );
  const allTurbineTypesInUse = Array.from(
    useAtomValue(simpleTurbineTypesAtom).values(),
  ).filter((t) =>
    allBranchFeatures.some((f) => f.properties.turbineTypeId === t.id),
  );
  const allFoundationTypesInUse = Array.from(
    useAtomValue(foundationTypesAtom).values(),
  ).filter((f) =>
    allBranchFeatures.some((feat) => feat.properties.foundationId === f.id),
  );
  const allMooringLinesInUse = Array.from(
    useAtomValue(mooringLineTypesAtom).values(),
  ).filter((m) =>
    allBranchFeatures.some((f) => f.properties.lineType === m.id),
  );
  const allSubStationsInUse = Array.from(
    useAtomValue(allSubstationsForProjectAtom).values(),
  ).filter((s) =>
    allBranchFeatures.some((f) => f.properties.substationTypeId === s.id),
  );

  const allExportCablesInUse = Array.from(
    useAtomValue(exportCableTypesFamily({ projectId: undefined })).values(),
  ).filter((c) =>
    allBranchFeatures.some(
      (f) =>
        f.properties.cableTypeId === c.id ||
        f.properties.onshoreCableTypeId === c.id,
    ),
  );

  return {
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
  };
}
