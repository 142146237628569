import mapboxgl, { MercatorCoordinate } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { mapAtom } from "../state/map";
import {
  showNoiseAtom,
  noiseLevelSelectorFamily,
  DEFAULT_NOISE_PARAMS,
} from "../state/turbines";
import { parkIdAtom } from "../state/pathParams";
import { TurbineNoiseSettings } from "../types/turbines";
import { LAYER_DEBUG_PRINT } from "../state/debug";
import { getBeforeLayer } from "../components/Mapbox/utils";
import { noiseLayerId } from "components/Mapbox/constants";
import { scream } from "../utils/sentry";
import { useAtomValue } from "jotai";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { createGlProgram } from "utils/gl";
import * as turf from "@turf/turf";
import { tile2bbox, wrapMapboxTile } from "types/tile";
import { bboxOverlaps } from "utils/bboxOverlaps";

const alphaPerCelcius = {
  10: [0.1, 0.4, 1, 1.9, 3.7, 9.7, 32.8, 117],
  20: [0.1, 0.3, 1.1, 2.8, 5, 9, 22.9, 76.6],
  30: [0.1, 0.3, 1, 3.1, 7.4, 12.7, 23.1, 59.3],
}; // https://puc.sd.gov/commission/dockets/electric/2019/el19-003/KMExhibit9.pdf, table 2
const A_weights = [-26.2, -16.1, -8.6, -3.2, 0, 1.2, 1.0, -1.1]; // https://dsp.stackexchange.com/questions/68767/a-weighted-digital-filter-for-1-3rd-octave-band-analysis

class NoiseLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom" = "custom";
  renderingMode?: "3d" = "3d";
  alpha: number[];

  gl?: {
    program: WebGLProgram;
    vertexBuffer: WebGLBuffer;
    aPos: GLint;
    loc: {
      A: WebGLUniformLocation;
      alpha: WebGLUniformLocation;
      db: WebGLUniformLocation;
      number: WebGLUniformLocation;
      opacity: WebGLUniformLocation;
      red: WebGLUniformLocation;
      scale: WebGLUniformLocation;
      turbines: WebGLUniformLocation;
      yellow: WebGLUniformLocation;
      bbox: WebGLUniformLocation;
    };
  };

  constructor(
    public scale: number,
    public turbinePos: [number, number][],
    public parkBBOX: number[],
    public settings: TurbineNoiseSettings,
  ) {
    this.id = noiseLayerId;
    this.alpha = alphaPerCelcius[settings.temperature];
  }

  onAdd(_: mapboxgl.Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("NoiseLayer.onAdd");
    // create GLSL source for vertex shader
    const vertexSource = `
              precision highp float;
              uniform vec4  u_bbox;

              attribute vec2 a_pos;
              varying vec2 mercpos;
              varying vec2 glpos;

              // Mapbox has a weird coordinate system.
              vec2 transform(vec2 v) {
                return vec2(v.x, 1.0 - v.y);
              }

              void main() {
                  vec2 uv = transform(0.5 * a_pos + 0.5);
                  vec2 ll = u_bbox.xy;
                  vec2 ur = u_bbox.zw;
                  mercpos = ll + (ur - ll) * uv;
                  glpos = uv;
                  gl_Position = vec4(a_pos, 0.0, 1.0);
              }`;

    // create GLSL source for fragment shader
    const fragmentSource = `
              // Useful for implementation: https://github.com/michkowalczuk/IsoNoise/blob/master/src/calc.py

              precision highp float;
              const int max_turbines = 1000;
              const float As = -1.5;
              const float Ar = -1.5;

              varying vec2 mercpos;
              varying vec2 glpos;

              uniform int u_number_of_turbines;
              uniform vec2 u_turbines[max_turbines];
              uniform float u_scale;
              uniform float u_opacity;
              uniform float u_db;
              uniform float u_red;
              uniform float u_yellow;
              uniform float u_alpha[8];
              uniform float u_A[8];

              float a = 1. / log(10.);

              float log10(float x) {
                return a * log(x);
              }

              float Noise(vec2 turbine, vec2 point) {
                vec2 vector = (point - turbine) / u_scale;
                float distance = length(vector);
                float geometrical_divergence = 20. * log10(distance) + 11.;

                if (distance > 50000.) {
                  return 0.;
                }

                float octave_sum = 0.;
                float atmospheric_absorption = 0.;
                float b = u_db - geometrical_divergence - As - Ar;
                for(int i=0;i<8;i++) {
                  atmospheric_absorption = u_alpha[i] * distance / 1000.;
                  float total = b - atmospheric_absorption + u_A[i];
                  octave_sum += pow(10., 0.1 * total);
                }
                return octave_sum;
              }

              void main() {
                float noise = 0.;
                for(int i=0;i<max_turbines;i++) {
                  noise += Noise(u_turbines[i], mercpos);
                  if (i >= u_number_of_turbines) break;
                }
                float total = 10.0 * log10(noise);
                bool red_zone = u_red < total;
                bool yellow_zone = u_yellow < total;

                vec3 color = vec3(0., 0., 0.);
                float alpha = 0.;
                if ( red_zone ) {
                  color = vec3(1., 0., 0.);
                  alpha = u_opacity;
                } else if ( yellow_zone ) {
                  color = vec3(1.,1.,0.);
                  alpha = u_opacity;
                }

                gl_FragColor = vec4(color * alpha, alpha);
              }`;

    const program = createGlProgram(gl, vertexSource, fragmentSource, {
      label: "noise",
    }).program;

    const vertexBuffer = gl.createBuffer();
    if (!vertexBuffer) throw scream("noise: failed to create buffer");
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([-1, -1, 1, -1, 1, 1, -1, 1]),
      gl.STATIC_DRAW,
    );

    const aPos = gl.getAttribLocation(program, "a_pos");

    const A = gl.getUniformLocation(program, "u_A");
    if (!A) throw new Error(`No uniform "u_A"`);
    const alpha = gl.getUniformLocation(program, "u_alpha");
    if (!alpha) throw new Error(`No uniform "u_alpha"`);
    const db = gl.getUniformLocation(program, "u_db");
    if (!db) throw new Error(`No uniform "u_db"`);
    const number = gl.getUniformLocation(program, "u_number_of_turbines");
    if (!number) throw new Error(`No uniform "u_number"`);
    const opacity = gl.getUniformLocation(program, "u_opacity");
    if (!opacity) throw new Error(`No uniform "u_opacity"`);
    const red = gl.getUniformLocation(program, "u_red");
    if (!red) throw new Error(`No uniform "u_red"`);
    const scale = gl.getUniformLocation(program, "u_scale");
    if (!scale) throw new Error(`No uniform "u_scale"`);
    const turbines = gl.getUniformLocation(program, "u_turbines");
    if (!turbines) throw new Error(`No uniform "u_turbines"`);
    const yellow = gl.getUniformLocation(program, "u_yellow");
    if (!yellow) throw new Error(`No uniform "u_yellow"`);
    const bbox = gl.getUniformLocation(program, "u_bbox");
    if (!bbox) throw new Error(`No uniform "u_bbox"`);

    this.gl = {
      program,
      vertexBuffer,
      aPos,
      loc: {
        A,
        alpha,
        scale,
        opacity,
        db,
        red,
        yellow,
        number,
        turbines,
        bbox,
      },
    };
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.gl || !tileId.z) return;

    const tilebbox = tile2bbox({
      x: tileId.x,
      y: tileId.y,
      z: tileId.z,
    });
    if (!bboxOverlaps(tilebbox, this.parkBBOX)) return;

    const ll = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: tilebbox[0],
      lat: tilebbox[1],
    });
    const ur = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: tilebbox[2],
      lat: tilebbox[3],
    });

    const scale =
      (ll.meterInMercatorCoordinateUnits() +
        ur.meterInMercatorCoordinateUnits()) /
      2.0;
    const mercBbox = [ll.x, ll.y, ur.x, ur.y];

    gl.useProgram(this.gl.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.uniform1fv(this.gl.loc.alpha, this.alpha);
    gl.uniform1fv(this.gl.loc.A, A_weights);
    gl.uniform1f(this.gl.loc.scale, scale);
    gl.uniform1f(this.gl.loc.opacity, this.settings.opacity);
    gl.uniform1f(this.gl.loc.db, this.settings.source);
    gl.uniform1f(this.gl.loc.red, this.settings.red);
    gl.uniform1f(this.gl.loc.yellow, this.settings.yellow);
    gl.uniform1i(this.gl.loc.number, this.turbinePos.length);
    gl.uniform2fv(this.gl.loc.turbines, this.turbinePos.flat());
    gl.uniform4fv(this.gl.loc.bbox, mercBbox);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.gl.vertexBuffer);
    gl.enableVertexAttribArray(this.gl.aPos);
    gl.vertexAttribPointer(this.gl.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  };

  shouldRerenderTiles() {
    return false;
  }

  render(_gl: WebGLRenderingContext, _matrix: number[]) {}
}

const Noise = () => {
  const showNoise = useAtomValue(showNoiseAtom);
  const parkId = useAtomValue(parkIdAtom);
  if (!showNoise || !parkId) return null;
  return <NoiseActive parkId={parkId} />;
};

const NoiseActive = ({ parkId }: { parkId: string }) => {
  const features = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const map = useAtomValue(mapAtom);

  const noiseLayer = useMemo(() => {
    if (features.length === 0) return;
    const bbox = turf.bbox(turf.featureCollection(features));
    const buffer = 0.3; // TODO: get a reasonable buffer from somewhere
    const bufferedBBOX = [
      bbox[0] - buffer,
      bbox[1] - buffer,
      bbox[2] + buffer,
      bbox[3] + buffer,
    ];

    const centerCoord = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: (bbox[0] + bbox[2]) / 2,
      lat: (bbox[1] + bbox[3]) / 2,
    });

    const turbineLonlats = features.map<[number, number]>((f) => {
      const m = mapboxgl.MercatorCoordinate.fromLngLat({
        lng: f.geometry.coordinates[0],
        lat: f.geometry.coordinates[1],
      });
      return [m.x, m.y];
    });

    const scale = centerCoord.meterInMercatorCoordinateUnits();

    return new NoiseLayer(
      scale,
      turbineLonlats,
      bufferedBBOX,
      DEFAULT_NOISE_PARAMS,
    );
  }, [features]);

  useEffect(() => {
    if (!map || !noiseLayer) return;
    map.addLayer(noiseLayer, getBeforeLayer(map, noiseLayerId));

    return () => {
      map.removeLayer(noiseLayer.id);
    };
  }, [map, noiseLayer]);

  const noiseSettings = useAtomValue(
    noiseLevelSelectorFamily({
      parkId,
    }),
  );

  useEffect(() => {
    if (!noiseLayer) return;
    noiseLayer.settings = noiseSettings;
    noiseLayer.alpha = alphaPerCelcius[noiseSettings.temperature];
  }, [noiseSettings, noiseLayer]);

  return null;
};

export default Noise;
