import { useAtomValue } from "jotai";
import { orgTurbineManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { useAblyLibraryTurbine } from "components/Ably/ChannelProviders/Organisation/Library/Turbine/useAblyLibraryTurbine";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";

interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function TurbineLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgTurbineManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_turbine_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyTurbineChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyTurbineChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibraryTurbine(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_turbine_manage"}
    />
  );
}
