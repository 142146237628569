import React, { useCallback, useEffect, useMemo } from "react";
import Fuse from "fuse.js";
import CostTable from "../CostTable";
import { Costs, TableData } from "../ProcurementCostsContent";
import {
  _InterArrayCableProcurementCost,
  InterArrayCableProcurementCost,
  ProcurementCost,
  Regions,
} from "services/procurementCostService";
import {
  interArrayCableIdentifierAtom,
  interArrayCableProcurementCostsAtom,
} from "../state";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { CostUnit } from "types/financial";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { CostType } from "services/costService";
import { Row } from "components/General/Layout";
import { SearchInput } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import InterArrayCablingIcon from "@icons/24/InterArrayCablingWhite.svg?react";

const DEFAULT_SUPPLY_UNIT = CostUnit.millionEuroPerKM;
const DEFAULT_SHIPPING_UNIT = CostUnit.millionEuroPerUnit;
const REGIONS: Regions[] = ["Europe", "Asia", "USA", "Other"];
const COMPONENT_TYPE = "interArrayCable";
const TABLE_HEADERS = ["Inter array cables", "Supply", "Shipping"];
const TABLE_SUB_HEADERS = {
  "Inter array cables": ["Name", "Voltage"],
  Supply: ["Cost"],
  Shipping: ["Europe", "Asia", "USA", "Other"],
};

const defaultCableCost =
  DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM];

interface InterArrayCableProcurementCostsProps {
  localTableData: TableData[];
  setLocalTableData: (costs: TableData[]) => void;
  setTableData: (costs: TableData[]) => void;
  setDataToBeSaved: (costs: ProcurementCost[]) => void;
  setChangedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
  changedRows: Set<string>;
}

export default function InterArrayCableProcurementCosts({
  localTableData,
  setLocalTableData,
  setTableData,
  setDataToBeSaved,
  changedRows,
  setChangedRows,
}: InterArrayCableProcurementCostsProps) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const [searchInput, onSearchInputChange, setSearchInput] = useTextInput("");
  const interArrayCableMap = useAtomValue(
    interArrayCableIdentifierAtom(organisationId),
  );

  const cableProcurementCosts = useAtomValue(
    interArrayCableProcurementCostsAtom(organisationId),
  );

  const formattedCosts = useMemo(() => {
    if (interArrayCableMap.size === 0) {
      return [];
    }

    const tableData: TableData[] = Array.from(interArrayCableMap.entries()).map(
      ([id, cableInfo]) => {
        const procurementCost = cableProcurementCosts.get(id);

        let costs: Costs;
        if (!procurementCost) {
          costs = {
            supplyCost: {
              value: defaultCableCost,
              unit: DEFAULT_SUPPLY_UNIT,
            },
            Europe: { value: 0, unit: DEFAULT_SHIPPING_UNIT },
            Asia: { value: 0, unit: DEFAULT_SHIPPING_UNIT },
            USA: { value: 0, unit: DEFAULT_SHIPPING_UNIT },
            Other: { value: 0, unit: DEFAULT_SHIPPING_UNIT },
          };
        } else {
          const shippingCosts: Costs = procurementCost.shippingCost.reduce(
            (acc, curr) => {
              acc[curr.region] = {
                value: curr.cost || 0,
                unit: curr.unit as CostUnit,
              };
              return acc;
            },
            {} as Costs,
          );

          costs = {
            supplyCost: {
              value: procurementCost.supplyCost?.cost ?? defaultCableCost,
              unit:
                (procurementCost.supplyCost?.unit as CostUnit) ||
                DEFAULT_SUPPLY_UNIT,
            },
            ...shippingCosts,
          };
        }

        return {
          id,
          name: { value: cableInfo.name, unit: "" },
          extraValue:
            typeof cableInfo.voltage !== "undefined"
              ? `${cableInfo.voltage}kV`
              : "",
          costs,
        };
      },
    );
    return tableData;
  }, [cableProcurementCosts, interArrayCableMap]);

  useEffect(() => {
    setTableData(formattedCosts);
    setLocalTableData(formattedCosts);
  }, [setTableData, setLocalTableData, formattedCosts]);

  const fuse = useMemo(
    () =>
      new Fuse(localTableData, {
        keys: ["name.value"],
        includeScore: true,
      }),
    [localTableData],
  );

  const searchResults = useMemo(() => {
    if (searchInput === "") {
      return localTableData;
    }
    return fuse.search(searchInput).map((result) => result.item);
  }, [localTableData, fuse, searchInput]);

  const handleDataChange = useCallback(
    (field: string, newValue: number | undefined, id: string) => {
      const updatedCableData = localTableData.map((item) => {
        if (item.id === id) {
          if (field === "supplyCost" && typeof newValue === "undefined") {
            return {
              ...item,
              costs: {
                ...item.costs,
                supplyCost: {
                  unit: DEFAULT_SUPPLY_UNIT,
                  value: defaultCableCost,
                },
              },
            };
          } else {
            return {
              ...item,
              costs: {
                ...item.costs,
                [field]: {
                  ...item.costs[field as keyof typeof item.costs],
                  value: typeof newValue === "undefined" ? 0 : newValue,
                },
              },
            };
          }
        }
        return item;
      });

      const newChangedRows = new Set(changedRows);
      newChangedRows.add(id);
      setChangedRows(newChangedRows);
      setLocalTableData(updatedCableData);
      const updatedCosts = updatedCableData.filter((cost) =>
        newChangedRows.has(cost.id),
      );
      const transformedData = transformRowToProcurementCost(updatedCosts);
      setDataToBeSaved(transformedData);
    },
    [
      localTableData,
      setChangedRows,
      setLocalTableData,
      setDataToBeSaved,
      changedRows,
    ],
  );

  return (
    <>
      <Row>
        <SearchInput
          placeholder="Search cables"
          value={searchInput}
          onChange={onSearchInputChange}
          onClear={() => setSearchInput("")}
        />
      </Row>
      <CostTable
        headers={TABLE_HEADERS}
        subHeaders={TABLE_SUB_HEADERS}
        data={searchResults}
        onDataChange={handleDataChange}
        emptyStateIcon={<InterArrayCablingIcon />}
        emptyStateDescription="No Library inter-array cables yet. Once you have inter-array cables in Library, they will appear here and you can associate a cost."
      />
    </>
  );
}

const transformRowToProcurementCost = (
  costData: TableData[],
): InterArrayCableProcurementCost[] => {
  const costs = costData.map((cost) => {
    const shippingCost = REGIONS.map((region) => ({
      region,
      cost: cost.costs[region]?.value ?? 0,
      unit: (cost.costs[region]?.unit ?? DEFAULT_SUPPLY_UNIT) as CostUnit,
    }));

    const supplyCost =
      cost.costs.supplyCost && cost.costs.supplyCost.value !== undefined
        ? {
            cost: cost.costs.supplyCost.value,
            unit: cost.costs.supplyCost.unit as CostUnit,
          }
        : undefined;

    return {
      componentType: COMPONENT_TYPE,
      componentId: cost.id,
      supplyCost: supplyCost,
      shippingCost,
    };
  });

  return _InterArrayCableProcurementCost.array().parse(costs);
};
