import { useAtom } from "jotai";
import { mapAtom } from "state/map";
import { useEffect, useMemo, useState } from "react";
import { measureWindPointAtom, windPointsSource } from "../state/map";
import { AnySourceData, SymbolLayer } from "mapbox-gl";
import { windPointsLayerId, getBeforeLayer } from "../components/Mapbox/utils";
import GenericFeature from "../components/MapFeatures/GenericFeature";
import { TopRightModeActiveAtom } from "../components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import {
  TopRightMenuOptions,
  displayLabelPropertyName,
} from "../constants/canvas";
import { ProjectFeature } from "../types/feature";
import { isPointGeometry } from "utils/predicates";
import { currentSelectionArrayAtom } from "state/selection";
import { useAtomValue, useSetAtom } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { WindDataSource } from "types/metocean";

const url = {
  [WindDataSource.ERA5]: "mapbox://vindai.era5-points",
  [WindDataSource.NORA3]: "mapbox://vindai.nora3-points",
  [WindDataSource.CERRA]: "mapbox://vindai.cerra-lonlats",
};

const sourceLayer = {
  [WindDataSource.ERA5]: "era5-points",
  [WindDataSource.NORA3]: "nora3-points",
  [WindDataSource.CERRA]: "cerra-lonlats",
};

function getLayerFromSource(
  windDataSource: WindDataSource,
  onshore: boolean,
): SymbolLayer | undefined {
  if (
    windDataSource === WindDataSource.CUSTOM ||
    windDataSource === WindDataSource.BEST
  )
    return;
  return {
    id: windPointsLayerId,
    type: "symbol",
    minzoom: 2,
    maxzoom: 14,
    source: sourceLayer[windDataSource],
    "source-layer": sourceLayer[windDataSource],
    layout: {
      "icon-image": "embassy",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        7,
        0.7,
        10,
        1.2,
      ],
      "icon-allow-overlap": false,
    },
    paint: {
      "icon-opacity": [
        "case",
        [
          "boolean",
          ["feature-state", "hover"],
          ["feature-state", "selected"],
          false,
        ],
        1,
        onshore ? 1.0 : 0.5,
      ],
    },
    filter: ["boolean", ["get", displayLabelPropertyName], true],
  };
}

const emptyList: ProjectFeature[] = [];
const WindPoints = () => {
  const map = useAtomValue(mapAtom);
  const [id, setId] = useState<number | string | undefined>(undefined);
  const setMeasurePoint = useSetAtom(measureWindPointAtom);
  const selectedSource = useAtomValue(windPointsSource);
  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);
  const [currentSelectionArray, setCurrentSelectionArray] = useAtom(
    currentSelectionArrayAtom,
  );

  const onClick = (features: any) => {
    if (features.length === 0) return;
    const geometry = features[0].geometry;
    if (!isPointGeometry(geometry)) return;
    setTopRightModeActive(TopRightMenuOptions.windSample);
    setCurrentSelectionArray([]);
    setMeasurePoint({
      source: selectedSource,
      position: geometry.coordinates,
    });
    setId(features[0].id);
  };

  const source = useMemo(() => {
    if (
      selectedSource === WindDataSource.CUSTOM ||
      selectedSource === WindDataSource.BEST
    )
      return;
    return {
      type: "vector",
      url: url[selectedSource],
    } as AnySourceData;
  }, [selectedSource]);

  useEffect(() => {
    if (!map) return;
    return () => {
      setId(undefined);

      setMeasurePoint(undefined);
    };
  }, [map, setMeasurePoint]);

  useEffect(() => {
    if (currentSelectionArray.length > 0) setMeasurePoint(undefined);
  }, [currentSelectionArray, setMeasurePoint]);

  if (!map || !source) return null;
  return (
    <WindPointsInner
      map={map}
      onClick={onClick}
      id={id}
      selectedSource={selectedSource}
    />
  );
};

export const WindPointsInner = ({
  map,
  onClick,
  id,
  selectedSource,
}: {
  map: any;
  onClick: (features: any) => void;
  id: number | string | undefined;
  selectedSource: WindDataSource;
}) => {
  const source = useMemo(() => {
    if (
      selectedSource === WindDataSource.CUSTOM ||
      selectedSource === WindDataSource.BEST
    )
      return;
    return {
      type: "vector",
      url: url[selectedSource],
    } as AnySourceData;
  }, [selectedSource]);

  const onshore = useAtomValue(isOnshoreAtom);

  const layer = useMemo(() => {
    return getLayerFromSource(selectedSource, onshore);
  }, [selectedSource, onshore]);

  if (!map || !layer || !source) return null;
  return (
    <GenericFeature
      features={emptyList}
      sourceId={layer["source-layer"] as string}
      source={source}
      layerId={windPointsLayerId}
      map={map}
      layers={[layer]}
      onClickCallback={onClick}
      beforeLayer={getBeforeLayer(map, windPointsLayerId)}
      sourceLayer={layer["source-layer"] as string}
      selectedIds={id ? [id] : undefined}
      featureFilter={(selectedFeatures) =>
        selectedFeatures.filter((f) => f.layer?.id === windPointsLayerId)
      }
    />
  );
};

export default WindPoints;
