import { Feature, MultiPolygon, Point, Position } from "geojson";
import { featureWGS84ToProjected } from "../utils/proj4";
import { getOSMBuildingsFromTile } from "../services/terrainAPIService";
import { DateTime } from "luxon";
import { ThreeCoreViewPark } from "../components/ViewAnalyses/ViewToPark/ThreeContext/useCreateThreeCore";
import { z } from "zod";
import { atom } from "jotai";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { atomWithReset, loadable } from "jotai/utils";
import { isDefined } from "utils/predicates";
import * as Sentry from "@sentry/react";
import { LngLat } from "types/gis";

export const OSM_BUILDING_TILE_ZOOM = 16;

export enum VIEW_MODE {
  NATURAL_MODE = "natural",
  WIRE_FRAME_MODE = "wireframe",
}

export enum VISIBILITY_MODE {
  FULL_VISIBILITY_MODE = "full_visibility",
  REDUCED_VISIBILITY_MODE = "reduced_visibility",
}

const _VisibilityMode = z.nativeEnum(VISIBILITY_MODE);

export const viewFromShoreFreeCameraAtom = atom<boolean>(false);
export const viewFromShoreFirstPersonCameraAngleAtom = atom<number | undefined>(
  undefined,
);
export const viewFromShoreVisibleAtom = atom<boolean>(false);

export const threeCoreAtom = atom<ThreeCoreViewPark | undefined>(undefined);
export const cameraPositionAtom = atom<[number, number] | undefined>(undefined);

export const viewFovAtom = atomLocalStorage<number>(
  "vind:view-from-shore:fov",
  30,
  z.number().min(25).max(35),
);
export const viewHeightAtom = atomLocalStorage<number>(
  "vind:view-from-shore:view-height",
  2,
  z.number().min(1).max(100),
);
export const viewFromShoreTerrainColorAtom = atom<string>("#77bb77");
export const viewFromShoreTerrainColorActiveAtom = atom<boolean>(false);
export const viewFromShoreOSMBuildingsActiveAtom = atom<boolean>(false);
export const viewFromShoreOSMBuildingTilesRequestedAtom = atom<
  [number, number][]
>([]);

export const viewTowardsWGS84Atom = atom<Feature<Point> | null>(null);
export const viewCameraAspectAtom = atom<any>(undefined);
export const viewViewModeAtom = atom<VIEW_MODE>(VIEW_MODE.NATURAL_MODE);
export const viewTurbineLightsAtom = atom<boolean>(false);
export const heightAboveGroundAtom = atom<number>(0);

export const viewTowardsSelector = atom<Position | undefined>((get) => {
  const viewTowardsWGS84 = get(viewTowardsWGS84Atom);
  const proj4String = get(viewProj4StringAtom);
  if (!viewTowardsWGS84 || !proj4String) return;
  return featureWGS84ToProjected(viewTowardsWGS84, proj4String).geometry
    .coordinates as Position; // safety: This is a point.
});

export const osmBuildingTileSelector = atomFamily(
  ({ x, y }: { x: number; y: number }) =>
    atom(() => getOSMBuildingsFromTile(x, y, OSM_BUILDING_TILE_ZOOM)),
);

export const osmBuildingTileStatusSelector = atom<{
  loading: boolean;
  error: boolean;
  finished: Feature<MultiPolygon>[];
}>((get) => {
  const viewFromShoreOSMBuildingTilesRequested = get(
    viewFromShoreOSMBuildingTilesRequestedAtom,
  );

  Sentry.addBreadcrumb({
    category: "mapbox streets tiles",
    message: "Requested tile",
    level: "info",
    data: {
      viewFromShoreOSMBuildingTilesRequested,
    },
  });
  const requestsLoadable = viewFromShoreOSMBuildingTilesRequested.map(
    ([x, y]) => get(loadable(osmBuildingTileSelector({ x, y }))),
  );

  return {
    loading: requestsLoadable.some((r) => r.state === "loading"),
    error: requestsLoadable.some((r) => r.state === "hasError"),
    finished: requestsLoadable
      .map((r) => (r.state === "hasData" ? r.data : undefined))
      .filter(isDefined)
      .flat(),
  };
});

// Common to view to park and view park shadow
export const viewTurbineHeightsAtom = atom<number[]>([]);
export const viewTurbineCoordsAtom = atom<undefined | [number, number][]>(
  undefined,
);
export const turbineScalingAtom = atomLocalStorage<number>(
  "vind:view-from-shore:turbine-scaling",
  1,
  z.number().min(0.5).max(1.5),
);
export const viewParkFogStyleAtom = atomLocalStorage<VISIBILITY_MODE>(
  "vind:view-from-shore:view-park-fog-style",
  VISIBILITY_MODE.FULL_VISIBILITY_MODE,
  _VisibilityMode,
);
export const viewDateAtom = atom<DateTime>(
  DateTime.fromISO("2022-08-01 18:00"),
);
export const viewProj4StringAtom = atom<undefined | string>(undefined);
export const viewOrigoWGS84Atom = atom<Feature<Point> | null>(null);
export const viewFromShoreParkIdAtom = atom<string | undefined>(undefined);
export const viewOrigoSelector = atom(async (get) => {
  const origoWGS84 = get(viewOrigoWGS84Atom);
  const proj4String = get(viewProj4StringAtom);
  if (!origoWGS84 || !proj4String) return;
  return featureWGS84ToProjected(origoWGS84, proj4String).geometry
    .coordinates as Position; // safety: This is a point.
});

export const viewParkRotationAtom = atomLocalStorage<number>(
  "vind:view-from-shore:park-rotation",
  0,
  z.number().min(0).max(360),
);
export const viewPositionAtom = atom<LngLat | undefined>(undefined);

export const visibleParksAtom = atomFamily((parkId: string) =>
  atomWithReset({ [parkId]: true }),
);
export const viewTurbineDiameterAtom = atom<number[]>([]);
