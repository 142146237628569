import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../hooks/useClickOutside";
import SendIcon from "@icons/24/Send.svg?react";
import Button from "../../General/Button";
import { newThreadAtomFamily, selectedThreadAtomFamily } from "../state";
import { useThreadCrud } from "../hooks/useThreadCrud";
import TextareaWithMentions from "./TextareaWithMentions";
import { Frame } from "../../General/Layout";
import { topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { mapAtom } from "state/map";
import { Popup } from "components/Mapbox/Popup";
import { getTopCenter } from "utils/turf";
import { useAtomValue, useSetAtom } from "jotai";
import { featureMapFamily } from "state/jotai/features";

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: flex-end;
  margin-top: auto;
`;

export function NewCommentModalInner({
  newThreadMeta,
  nodeId,
}: {
  newThreadMeta: {
    featureId?: string;
    mapPosition?: [number, number];
  };
  nodeId: string;
}) {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const toplevelNodeId = useAtomValue(
    topLevelFolderIdFromOrgIdAndProjectIdSelectorFamily({
      organisationId,
      projectId,
    }),
  );
  const { post } = useThreadCrud();
  const setNewThreadOpen = useSetAtom(
    newThreadAtomFamily({
      projectId,
    }),
  );
  const selectThread = useSetAtom(
    selectedThreadAtomFamily({
      nodeId,
    }),
  );
  const [text, setText] = useState("");

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (text.length === 0) return;
      setSaveInProgress(true);
      const thread = await post({
        message: text,
        ...newThreadMeta,
      }).finally(() => setSaveInProgress(false));
      setText("");
      setNewThreadOpen(undefined);
      selectThread(thread?.threadId);
    },
    [newThreadMeta, post, selectThread, setNewThreadOpen, text],
  );

  const handleCancel = useCallback(() => {
    setText("");
    setNewThreadOpen(undefined);
  }, [setNewThreadOpen]);

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, handleCancel, () => false, {
    ignoreDragClicks: true,
  });

  const map = useAtomValue(mapAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );
  const featurePos = useMemo(() => {
    const { featureId } = newThreadMeta;
    if (!featureId) return;
    const f = featureMap.get(featureId);
    if (!f) return;
    return getTopCenter(f);
  }, [featureMap, newThreadMeta]);

  if (!newThreadMeta.featureId || !toplevelNodeId || !featurePos || !map)
    return null;

  return (
    <Popup map={map} pos={featurePos} place="bottom">
      <Frame
        ref={containerRef}
        style={{
          overflow: "visible",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <TextareaWithMentions
            text={text}
            onChange={setText}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            nodeId={nodeId}
          />

          <ButtonContainer>
            <Button
              type="submit"
              icon={<SendIcon />}
              buttonType="text"
              disabled={saveInProgress || text.length === 0}
            />
          </ButtonContainer>
        </Form>
      </Frame>
    </Popup>
  );
}
