import { Group } from "components/Organisation/Groups/types";
import TeamMeetingIcon from "@icons/24/TeamMeeting.svg?react";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import { useSearch } from "./useSearch";
import { useMemo } from "react";
import { SearchItem, SearchResultItem } from "../state";
import { useSetAtom } from "jotai";
import { selectedMemberTabState } from "components/Organisation/state";
import { useNavigate } from "react-router-dom";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";

const keys = ["name"];
export function useSearchGroups(
  enabled: boolean,
  organisationId: string,
  searchTerm: string,
) {
  const navigate = useNavigate();
  const setSelectedMemberTab = useSetAtom(selectedMemberTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const { filteredResults, rawData } = useSearch<Group>(
    enabled,
    searchTerm,
    keys,
    organisationGroupsState({
      organisationId,
    }),
  );

  const filteredSearchItems: SearchResultItem<Group>[] = useMemo(() => {
    return filteredResults.map((result) => ({
      id: result.item.id,
      name: result.item.name,
      score: result.score,
      item: result.item,
      onSelect: () => {
        setSelectedMemberTab("Groups");
        setContent({
          type: "group",
          id: result.item.id,
        });
        navigate(`/organisation/${organisationId}/members`);
      },
      icon: <TeamMeetingIcon />,
    }));
  }, [
    filteredResults,
    navigate,
    organisationId,
    setContent,
    setSelectedMemberTab,
  ]);

  const searchableItems: SearchItem<Group>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        setSelectedMemberTab("Groups");
        setContent({
          type: "group",
          id: item.id,
        });
        navigate(`/organisation/${organisationId}/members`);
      },
      icon: <TeamMeetingIcon />,
    }));
  }, [rawData, setSelectedMemberTab, setContent, navigate, organisationId]);

  return {
    filteredGroups: filteredSearchItems,
    searchableItems,
  };
}
