import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import TurbineIcon from "@icons/24/Turbine.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useMemo, useState } from "react";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../../style";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import { organisationTurbineResourceState } from "components/Organisation/Library/state";
import { TurbineType } from "types/turbines";
import { TableRowsPlaceholder } from "../../shared/TablePlaceholder";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { lunwrap } from "utils/jotai";

export default function TurbineModal({
  existingTurbines,
  onSave,
  isSaving,
}: {
  existingTurbines: string[];
  onSave: (turbines: TurbineType[]) => void;
  isSaving?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const turbinesInOrgLoadable = useAtomValue(
    loadable(
      organisationTurbineResourceState({
        organisationId,
      }),
    ),
  );

  const turbinesInOrg = useMemo(
    () => lunwrap(turbinesInOrgLoadable) ?? [],
    [turbinesInOrgLoadable],
  );

  const [name, onNameChange] = useTextInput("");
  const [selectedTurbines, setSelectedTurbines] = useState<TurbineType[]>([]);
  const [filteredTurbines, setFilteredTurbines] = useState<TurbineType[]>([]);

  useEffect(() => {
    const fuse = new Fuse(turbinesInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredTurbines(name.length > 0 ? results : turbinesInOrg);
  }, [name, turbinesInOrg]);

  const toggleTurbineSelection = (turbine: TurbineType) => {
    setSelectedTurbines((prevSelectedTurbines) => {
      if (prevSelectedTurbines.find((t) => t.id === turbine.id)) {
        return prevSelectedTurbines.filter((g) => g.id !== turbine.id);
      } else {
        return [...prevSelectedTurbines, turbine];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={() => onSave([])}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        <ContentTableColumn
          style={{
            overflowY: "auto",
            padding: "1.2rem 0",
          }}
        >
          {turbinesInOrgLoadable.state === "loading" && (
            <TableRowsPlaceholder />
          )}
          {filteredTurbines.map((g) => {
            const selected = selectedTurbines.some((st) => st.id === g.id);
            const alreadyExist = existingTurbines.some((id) => id === g.id);
            return (
              <ContentTableRow
                key={g.id}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => !alreadyExist && toggleTurbineSelection(g)}
                disabled={selected || alreadyExist}
              >
                <SVGWrapper size={1.4}>
                  <TurbineIcon />
                </SVGWrapper>
                <TextEllipsis
                  style={{
                    margin: 0,
                    marginRight: "auto",
                  }}
                >
                  {g.name}
                </TextEllipsis>
                {alreadyExist ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Already connected
                  </SecondaryText>
                ) : selected ? (
                  <SecondaryText
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    Selected
                  </SecondaryText>
                ) : (
                  <></>
                )}
                {!alreadyExist && !selected && (
                  <Button
                    buttonType="text"
                    text="Select"
                    icon={<Add />}
                    onClick={() => {}}
                    size="small"
                    style={{
                      padding: "0.2rem 1.2rem",
                    }}
                  />
                )}
              </ContentTableRow>
            );
          })}
        </ContentTableColumn>
      </ResultContainer>
      {selectedTurbines.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "0 1.2rem 1.2rem",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedTurbines.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name}
                    onDeselect={() => toggleTurbineSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedTurbines)}
              disabled={isSaving}
              buttonType="primary"
            />
          </Row>
        </>
      )}
      {isSaving && (
        <Row
          style={{
            padding: "0 1.2rem 1.2rem",
            alignItems: "center",
          }}
        >
          <SkeletonBlock
            style={{
              height: "2rem",
            }}
          />
        </Row>
      )}
    </SearchAndSelectContainer>
  );
}
