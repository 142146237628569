import { atomFamily, atomFromFn } from "utils/jotai";
import { _SubstationType } from "services/substationService";
import { NodeSubstationAccess } from "components/Organisation/Library/types";
import { getSubstationResourcesOnNode } from "services/libraryService";

export const substationResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeSubstationAccess[]>>(async () => {
      if (!nodeId) return [];
      return await getSubstationResourcesOnNode(nodeId).catch(() => []);
    }),
);
