import React from "react";
import { AnalysisConfiguration } from "services/configurationService";
import { Grid2 } from "components/General/Form";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { InputDimensioned } from "components/General/Input";
import {
  HorizontalLine,
  TitleContainer,
  Subtitle,
  Text,
} from "components/SettingsV2/Shared/styles";
import HelpTooltip, {
  ARTICLE_INTER_ARRAY_LOSS,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { ElementsContainer, Row } from "../shared";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { analysisChangelogInfo } from "components/InputChangelog/const";

const ElectricalSettingsTab = ({
  isReadOnly,
  configuration,
  localConfig,
  setLocalConfig,
  nodeId,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  configuration: AnalysisConfiguration;
  localConfig: AnalysisConfiguration;
  setLocalConfig: React.Dispatch<React.SetStateAction<AnalysisConfiguration>>;
  nodeId: string;
  isLibraryResource: boolean;
}) => {
  const changelogCategory = isLibraryResource
    ? "org_analysis_manage"
    : "project";

  return (
    <ElementsContainer>
      <TitleContainer>
        <Subtitle>Inter array settings</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "left",
          gap: "2.4rem",
        }}
      >
        <ChangelogAndCommentWrapper
          changelogInfo={analysisChangelogInfo(
            localConfig.id,
            nodeId,
            "electrical.cableLengthContingencyEnabled",
            changelogCategory,
          )}
          disabled={isReadOnly}
        >
          <Row>
            <Toggle
              checked={localConfig.electrical.cableLengthContingencyEnabled}
              disabled={isReadOnly}
              onChange={() => {
                setLocalConfig((curr) => ({
                  ...curr,
                  electrical: {
                    ...curr.electrical,
                    cableLengthContingencyEnabled:
                      !curr.electrical.cableLengthContingencyEnabled,
                  },
                }));
              }}
              size={ToggleSize.SMALL}
            />
            <Text>Cable length contingency</Text>
            <HelpTooltip
              text="Adds additional cable length to each inter-array cable segment. Will be included in CAPEX and electrical loss calculation."
              size={10}
            />
          </Row>
        </ChangelogAndCommentWrapper>
        <Row>
          <InputDimensioned
            disabled={
              isReadOnly ||
              !localConfig.electrical.cableLengthContingencyEnabled
            }
            validate={(n) => n >= 0 && n <= 20}
            validationMessage="Value must be between 0 and 20"
            unit="%"
            min={0}
            max={20}
            type="number"
            scaleFactor={100}
            decimals={2}
            value={localConfig.electrical.cableLengthContingency}
            changelogInfo={analysisChangelogInfo(
              localConfig.id,
              nodeId,
              "electrical.cableLengthContingency",
              changelogCategory,
            )}
            initialValue={configuration.electrical.cableLengthContingency}
            onChange={(val) =>
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  cableLengthContingency: val,
                },
              }))
            }
            style={{ width: "12rem" }}
          />
        </Row>
      </Grid2>
      <TitleContainer>
        <Subtitle>Electrical losses</Subtitle>
        <HelpLink article={ARTICLE_INTER_ARRAY_LOSS} />
        <HorizontalLine />
      </TitleContainer>
      <ChangelogAndCommentWrapper
        changelogInfo={analysisChangelogInfo(
          localConfig.id,
          nodeId,
          "electrical.turbineTrafoLoss",
          changelogCategory,
        )}
        disabled={isReadOnly}
      >
        <Row>
          <Toggle
            checked={localConfig.electrical.turbineTrafoLoss}
            disabled={isReadOnly}
            onChange={() => {
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  turbineTrafoLoss: !curr.electrical.turbineTrafoLoss,
                },
              }));
            }}
            size={ToggleSize.SMALL}
          />
          <Text> Turbine trafo loss</Text>
        </Row>
      </ChangelogAndCommentWrapper>
      <ChangelogAndCommentWrapper
        changelogInfo={analysisChangelogInfo(
          localConfig.id,
          nodeId,
          "electrical.interArrayCableLoss",
          changelogCategory,
        )}
        disabled={isReadOnly}
      >
        <Row>
          <Toggle
            checked={localConfig.electrical.interArrayCableLoss}
            disabled={isReadOnly}
            onChange={() => {
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  interArrayCableLoss: !curr.electrical.interArrayCableLoss,
                },
              }));
            }}
            size={ToggleSize.SMALL}
          />
          <Text> Inter array cable loss</Text>
        </Row>
      </ChangelogAndCommentWrapper>
      <ChangelogAndCommentWrapper
        changelogInfo={analysisChangelogInfo(
          localConfig.id,
          nodeId,
          "electrical.exportSystemLoss",
          changelogCategory,
        )}
        disabled={isReadOnly}
      >
        <Row>
          <Toggle
            checked={localConfig.electrical.exportSystemLoss}
            disabled={isReadOnly}
            onChange={() => {
              setLocalConfig((curr) => ({
                ...curr,
                electrical: {
                  ...curr.electrical,
                  exportSystemLoss: !curr.electrical.exportSystemLoss,
                },
              }));
            }}
            size={ToggleSize.SMALL}
          />
          <Text> Export system loss</Text>
        </Row>
      </ChangelogAndCommentWrapper>
    </ElementsContainer>
  );
};

export default ElectricalSettingsTab;
