import { mapAtom } from "./../state/map";
import { FilterSpecification, Map } from "mapbox-gl";
import { useCallback, useMemo } from "react";
import {
  getBBOXArrayFromCoordinates,
  getBBOXArrayFromFeatures,
} from "../utils/geojson/validate";
import {
  BOUNDS_BUFFER_OFFSHORE,
  BOUNDS_BUFFER_ONSHORE,
} from "../constants/misc";
import { ProjectFeature } from "../types/feature";
import { externalLayerFilterPropertyAtom } from "../state/layer";
import { FilterOperator } from "../components/FilterExternalDataLayers/FilterExternalDataLayers";
import { isDefined } from "utils/predicates";
import * as Sentry from "@sentry/react";
import { useAtomValue } from "jotai";
import { featureMapFamily } from "state/jotai/features";
import { Feature, Position } from "geojson";
import { GeometryNoCollection } from "utils/geojson/geojson";
import { isOnshoreAtom } from "state/onshore";

const goToFeatures = (
  features: Pick<Feature<GeometryNoCollection>, "geometry">[],
  map: Map,
  options?: mapboxgl.FitBoundsOptions,
  boundBuffer?: number,
) => {
  const bbox = getBBOXArrayFromFeatures(features);
  const bounds = [
    [
      bbox[0] - (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
      bbox[1] - (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
    ],
    [
      bbox[2] + (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
      bbox[3] + (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
    ],
  ] as mapboxgl.LngLatBoundsLike;
  Sentry.addBreadcrumb({
    category: "map",
    message: "Bounds",
    level: "info",
    data: {
      bounds,
    },
  });
  map.fitBounds(bounds, options);
};

export const goToCoordinates = (
  coordinates: Position[],
  map: Map,
  options?: mapboxgl.FitBoundsOptions,
  boundBuffer?: number,
) => {
  const bbox = getBBOXArrayFromCoordinates(coordinates);

  const bounds = [
    [
      bbox[0] - (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
      bbox[1] - (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
    ],
    [
      bbox[2] + (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
      bbox[3] + (boundBuffer ?? BOUNDS_BUFFER_OFFSHORE),
    ],
  ] as mapboxgl.LngLatBoundsLike;

  map.fitBounds(bounds, options);
};

export const useGoToFeatureCenter = () => {
  const map = useAtomValue(mapAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );
  const onshore = useAtomValue(isOnshoreAtom);

  return useCallback(
    (featureId: string, options?: mapboxgl.FitBoundsOptions) => {
      const feature = featureMap.get(featureId);
      if (!feature || !map) return;
      goToFeatures(
        [feature],
        map,
        options,
        onshore ? BOUNDS_BUFFER_ONSHORE : BOUNDS_BUFFER_OFFSHORE,
      );
    },
    [featureMap, map, onshore],
  );
};
export const useGoToFeatures = (map?: Map) => {
  const onshore = useAtomValue(isOnshoreAtom);

  return useCallback(
    (
      features: Pick<ProjectFeature, "geometry">[],
      e?: any,
      options?: mapboxgl.FitBoundsOptions,
      boundBuffer?: number,
    ) => {
      if (e) e.stopPropagation();
      if (features.length === 0 || !map) return;

      goToFeatures(
        features,
        map,
        options,
        boundBuffer
          ? boundBuffer
          : onshore
            ? BOUNDS_BUFFER_ONSHORE
            : BOUNDS_BUFFER_OFFSHORE,
      );
    },
    [map, onshore],
  );
};
export const useGoToFeaturesIds = () => {
  const map = useAtomValue(mapAtom);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );
  const onshore = useAtomValue(isOnshoreAtom);
  return useCallback(
    (
      featureIds: string[],
      e?: any,
      options?: mapboxgl.FitBoundsOptions,
      boundBuffer?: number,
    ) => {
      const features = featureIds
        .map((fId) => featureMap.get(fId))
        .filter(isDefined);
      if (e) e.stopPropagation();
      if (features.length === 0 || !map) return;
      goToFeatures(
        features,
        map,
        options,
        boundBuffer
          ? boundBuffer
          : onshore
            ? BOUNDS_BUFFER_ONSHORE
            : BOUNDS_BUFFER_OFFSHORE,
      );
    },
    [featureMap, map, onshore],
  );
};

export const useLayoutFilter = (
  sourceId: string,
): FilterSpecification | undefined => {
  const externalLayerFilterProperty = useAtomValue(
    externalLayerFilterPropertyAtom,
  );

  return useMemo(() => {
    if (!(sourceId in externalLayerFilterProperty)) return undefined;

    const keyValues = externalLayerFilterProperty[sourceId];
    const equalCases = Object.keys(keyValues)
      .filter((key) => keyValues[key].operator === FilterOperator.Equal)
      .map((key) => [
        [
          FilterOperator.Equal,
          ["get", key],
          parseInt(keyValues[key].value)
            ? parseInt(keyValues[key].value)
            : false,
        ],
        true,
        [FilterOperator.Equal, ["get", key], keyValues[key].value],
        true,
      ])
      .flat();
    const otherCases = Object.keys(keyValues)
      .filter((key) => keyValues[key].operator !== FilterOperator.Equal)
      .map((key) => [
        [
          keyValues[key].operator,
          ["to-number", ["get", key]],
          parseInt(keyValues[key].value)
            ? parseInt(keyValues[key].value)
            : false,
        ],
        true,
      ])
      .flat();
    return ["case", ...equalCases, ...otherCases, false];
  }, [sourceId, externalLayerFilterProperty]);
};
