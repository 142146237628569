import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import moment from "moment";
import { useCallback } from "react";
import { CustomWindFileType } from "types/metocean";
import { aset, useJotaiCallback } from "utils/jotai";
import { toWGS84 } from "utils/proj4";
import { readFileAsTextOrArrayBuffer } from "../components/UploadFile/fileUtils";
import {
  deleteWindData,
  renameWindData,
  saveWindData,
} from "../services/configurationService";
import { isDefined } from "../utils/predicates";
import {
  MeanSpeedGridCustom,
  UploadedWindData,
  WRG,
  WRGPoint,
  WRGSector,
  WindData,
  WindDataMultipleHeights,
  WindDataVortex,
  uploadedWindDataFamily,
} from "./jotai/windStatistics";

export class UploadWindDataFileSizeTooLargeError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
  }
}

const MAXBYTES = 20e6;
const MAXBYTESGRID = 20e6;

const MAX_BYTES_WRG = 40e6;
const MIN_VALUES = 24;

function validSpeed(s: number) {
  return isFinite(s) && s >= 0 && s <= 100;
}

function validDirection(d: number) {
  return isFinite(d) && d >= 0 && d <= 360;
}

function validAlpha(a: number) {
  return isFinite(a) && a >= 0 && a <= 1;
}

function validLon(a: number) {
  return isFinite(a) && a >= -180 && a <= 180;
}

function validLat(a: number) {
  return isFinite(a) && a >= -90 && a <= 90;
}

function validHeight(a: number) {
  return isFinite(a) && a >= 0 && a <= 1000;
}

function validTimestamp(s: string) {
  return !isNaN(Date.parse(s));
}

function convertDateFormat(input: string, inputFormat: string) {
  const parsedDate = moment(input, inputFormat, true); // The 'true' flag enforces strict parsing
  if (parsedDate.isValid()) {
    return parsedDate.format("YYYY-MM-DD HH:mm");
  } else {
    return "Invalid input format";
  }
}

const DATE_EXPRESSIONS: [RegExp, string][] = [
  [/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/, "DD/MM/YYYY HH:mm"],
];

function maybeConvertDateFormat(input: string) {
  for (const [reg, format] of DATE_EXPRESSIONS) {
    if (reg.test(input)) {
      return convertDateFormat(input, format);
    }
  }
  return input;
}

export const parseCsvWindData = (csv: string): WindData => {
  const csvLines = csv
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  const allLines = csvLines.map((line) => line.replace("\t", ","));
  const metadata = {};
  const knownKeys = {
    height: validHeight,
    lon: validLon,
    lat: validLat,
    alpha: validAlpha,
  };

  const headerIndex = allLines.reduce((acc, l) => {
    if (l[0] !== "#") {
      return acc;
    }
    const [key, v] = l.replace("#", "").replace("\t", "").trim().split(/[,;]/);
    if (!Object.keys(knownKeys).includes(key)) {
      throw new Error(`Unknown metadata: ${key}`);
    }
    const value = parseFloat(v);

    if (!(knownKeys as any)[key](value)) {
      throw new Error(`Invalid metadata: ${key}`);
    }
    (metadata as any)[key] = value;
    return acc + 1;
  }, 0);

  const requiredMetadata = ["lat", "lon", "height"];
  const metadataLines = allLines.slice(0, headerIndex);

  for (const required of requiredMetadata) {
    if (!metadataLines.some((row) => row.startsWith(`#${required}`))) {
      throw new Error(`Missing metadata row #${required}`);
    }
  }

  const headers = csvLines[headerIndex].trim().split(/[,;]/);
  const rest = allLines.slice(headerIndex + 1);
  const n = rest.length;
  if (n < MIN_VALUES) {
    throw new Error(
      `Invalid CSV. Must have at least ${MIN_VALUES} records. Check the HelpCenter for supported format.`,
    );
  }
  const required = ["speed", "direction"];
  const validHeader = required.reduce(
    (acc, v) => headers.includes(v) && acc,
    true,
  );

  if (!validHeader) {
    throw new Error(
      "Invalid CSV. Must have at least 'speed' and 'direction' columns. Check the HelpCenter for supported format.",
    );
  }

  const timestampIndex = headers.indexOf("timestamp");
  const speedIndex = headers.indexOf("speed");
  const directionIndex = headers.indexOf("direction");

  const hasTimestamps = timestampIndex >= 0;
  if (!hasTimestamps) {
    throw new Error(
      "Invalid CSV. The 'timestamp' column is missing. Check the HelpCenter for supported format.",
    );
  }

  const columns = rest.map((v: string) => {
    const list = v.replace("\r", "").split(/[,;]/);
    const speed = parseFloat(list[speedIndex]);
    const direction = parseFloat(list[directionIndex]);
    const timestamp = maybeConvertDateFormat(list[timestampIndex]);
    const isValidSpeed = validSpeed(speed);
    const isValidDirection = validDirection(direction);
    const isValidTimestamp = validTimestamp(timestamp);

    if (!isValidSpeed || !isValidDirection || !isValidTimestamp) {
      console.error({
        speed,
        direction,
        list,
        directionIndex,
        speedIndex,
        v,
      });
      const invalidValues = [
        !isValidSpeed ? "speed" : undefined,
        !isValidDirection ? "direction" : undefined,
        !isValidTimestamp ? "timestamp" : undefined,
      ].filter(Boolean);
      const allLinesTrimmed = csv.split("\n").map((line) => line.trim());
      const lineNumber = allLinesTrimmed.findIndex((l) => l === v);

      throw new Error(
        `Invalid data on row ${lineNumber + 1} in file. Invalid keys: ${invalidValues.join(", ")}. Invalid row value: ${v}`,
      );
    }
    return {
      timestamp,
      speed,
      direction,
    };
  });
  const timestamps = columns.map((v) => v.timestamp);
  const speed = columns.map((v) => v.speed);
  const direction = columns.map((v) => v.direction);
  const data: WindData = {
    timestamps,
    speed,
    direction,
    height: (metadata as any)["height"],
    lon: (metadata as any)["lon"],
    lat: (metadata as any)["lat"],
    alpha: (metadata as any)["alpha"] ?? 0.1,
  };

  return data;
};

const isWindDataCsvFormat = (text: string) => {
  return /^#\w+[,;]\d/.test(text);
};

const isVortexFormat = (text: string) => {
  return text.includes("VORTEX");
};

const isWindProFormat = (text: string) => {
  return text.includes("WindPRO");
};

export const isVortexGridFormat = (text: string) => {
  return text.includes("xllcorner");
};

const parseVortexData = (text: string): WindDataVortex => {
  const [, lat] = /Lat=(-?[\d.]+)/gi.exec(text) ?? [];
  const [, lon] = /Lon=(-?[\d.]+)/gi.exec(text) ?? [];
  const [, hubHeight] = /Hub-Height=([\d.]+)/gi.exec(text) ?? [];

  if (!isDefined(lat) || !isDefined(lon)) {
    throw new Error("File is not in correct format (Lat/Lon not found)");
  }

  if (!isDefined(hubHeight)) {
    throw new Error("File is not in correct format (Hub-Height not found)");
  }

  const latNumber = Number(lat);
  const lonNumber = Number(lon);
  const hubHeightNumber = Number(hubHeight);

  if (!validHeight(hubHeightNumber)) {
    throw new Error("Invalid hub height");
  }

  if (!validLat(latNumber) || !validLon(lonNumber)) {
    throw new Error("Invalid latitude or longitude");
  }

  const windDataResult: WindDataVortex = {
    lat: latNumber,
    lon: lonNumber,
    alpha: 0.1,
    height: hubHeightNumber,
    direction: [],
    timestamps: [],
    speed: [],
    density: [],
  };

  const rows = text.split("\n");
  for (const rowNr in rows) {
    const row = rows[rowNr];
    if (!/^\d{8}/g.test(row)) {
      continue;
    }

    const columns = row.split(" ").filter(Boolean);
    const [dateYMD, time, windspeed, degrees, _tc, density] = columns;
    // dateTime = YYYYMMDDHHII
    const dateTime = dateYMD.concat(time);

    const [, year, month, date, hour, minutes] =
      /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/g.exec(dateTime) ?? [];

    const timelineObj = {
      year,
      month,
      date,
      hour,
      minutes,
    };
    const invalidTimelineValues = Object.entries(timelineObj).filter(
      ([_, val]) => !isDefined(val),
    );

    if (invalidTimelineValues.length > 0) {
      throw new Error(
        `Could not parse timeline, invalid/missing values on row ${Number(rowNr) + 1} for following keys: ` +
          invalidTimelineValues.map(([key, _]) => key).join(", "),
      );
    }

    const timestampString = `${year}-${month}-${date}T${hour}:${minutes}Z`;
    windDataResult.timestamps!.push(timestampString);
    windDataResult.direction.push(Number(degrees));
    windDataResult.speed.push(Number(windspeed));
    windDataResult.density!.push(Number(density));
  }
  return windDataResult;
};

const parseWindProData = (text: string): WindDataMultipleHeights => {
  const [, _lat] = /Latitude:\s+([\d.,]+)/gi.exec(text) ?? [];
  const [, _lon] = /Longitude:\s+([\d.,]+)/gi.exec(text) ?? [];

  const lat = _lat ? _lat.replace(",", ".") : undefined;
  const lon = _lon ? _lon.replace(",", ".") : undefined;
  const latNumber = Number(lat);
  const lonNumber = Number(lon);

  const heigthStrings =
    text.match(/DataStatus_MeanWindSpeedUID_([\d.,]+)m/g) || [];
  if (heigthStrings.length > 3) {
    throw new Error("Have surpassed the limit of two different heights");
  }
  const heights = heigthStrings.map((s) =>
    parseFloat(s.replace(",", ".").split("_")[2].replace("m", "")),
  );

  if (!isDefined(lat) || !isDefined(lon)) {
    throw new Error(
      "File is not in correct format (Latitude/Longitude not found)",
    );
  }

  if (!validLat(latNumber) || !validLon(lonNumber)) {
    throw new Error("Invalid latitude or longitude");
  }

  const windDataResult: WindDataMultipleHeights = {
    lat: latNumber,
    lon: lonNumber,
    heights,
    timestamps: [],
    direction: {},
    speed: {},
  };

  for (let i = 0; i < heights.length; i++) {
    windDataResult.direction[heights[i]] = [];
    windDataResult.speed[heights[i]] = [];
  }

  for (const row of text.split("\n")) {
    if (
      /^TimeStamp.*/.test(row) &&
      !/^TimeStamp.*MeanWindSpeedUID.*DirectionUID/.test(row)
    ) {
      throw new Error(
        "Cannot read format. Make sure the Mean Wind Speed and Direction is inlcuded, in that order.",
      );
    }
    const columns = row.split(/\s/).filter(Boolean);
    let dateYMD, dateDMY, time;
    if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}.*/.test(row)) {
      [dateYMD, time] = columns.slice(0, 2);
    } else if (/^\d{2}[-/]\d{2}[-/]\d{4} \d{2}:\d{2}.*/.test(row)) {
      [dateDMY, time] = columns.slice(0, 2);
      const [day, month, year] = dateDMY.split(/[-/]/);
      dateYMD = `${year}-${month}-${day}`;
    } else {
      continue;
    }

    const rawData = columns.slice(2);

    if (rawData.length !== 6 * heights.length) {
      throw new Error("Contains unknow amounts of data");
    }
    const step = 6;
    let count = 0;
    for (let i = 0; i < heights.length; i++) {
      const thisHeight = rawData.slice(count * step, count * step + step);
      const [s1, s2, s3, s4] = thisHeight.slice(2);
      if (s1 !== "0" || s2 !== "0" || s3 !== "0" || s4 !== "0") {
        throw new Error("Detected StatusValues not equal 0 in the dataset");
      }
      count += 1;
      const [_ws, _wd] = thisHeight.slice(0, 2);
      const ws = parseFloat(_ws);
      const wd = parseFloat(_wd);
      if (!validDirection(wd)) {
        throw new Error(`Found invalid direction value: ${wd}`);
      }
      if (!validSpeed(ws)) {
        throw new Error(`Found invalid speed value: ${ws}`);
      }
      windDataResult.direction[heights[i]]!.push(wd);
      windDataResult.speed[heights[i]]!.push(ws);
    }

    const timestampString = `${dateYMD}T${time}`;
    const testTime = new Date(timestampString);
    if (isNaN(testTime.valueOf())) {
      throw new Error("Could not parse time data");
    }
    windDataResult.timestamps!.push(timestampString);
  }

  if (windDataResult.direction[heights[0]].length < 24 * 31) {
    throw new Error("Too few datapoints in the file");
  }
  return windDataResult;
};

const parseVortexGridData = (
  text: string,
  gridHeight: number,
): MeanSpeedGridCustom => {
  const [, lon] = /xllcorner\s+(-?[\d.]+)/gi.exec(text) ?? [];
  const [, lat] = /yllcorner\s+(-?[\d.]+)/gi.exec(text) ?? [];
  const [, cellsize] = /cellsize\s+([\d.]+)/gi.exec(text) ?? [];
  let [, dx] = /dx\s+([\d.]+)/gi.exec(text) ?? [];
  let [, dy] = /dy\s+([\d.]+)/gi.exec(text) ?? [];
  const [, ncols] = /ncols\s+([\d.]+)/gi.exec(text) ?? [];
  const [, nrows] = /nrows\s+([\d.]+)/gi.exec(text) ?? [];

  if (!isDefined(lat) || !isDefined(lon)) {
    throw new Error(
      "File is not in correct format (xllcorner/yllcorner not found)",
    );
  }

  if (!isDefined(cellsize) && (!isDefined(dx) || !isDefined(dy))) {
    throw new Error("File is not in correct format (cellsize/dx/dy not found)");
  }

  if (isDefined(cellsize)) {
    dx = cellsize;
    dy = cellsize;
  }

  const latNumber = Number(lat);
  const lonNumber = Number(lon);
  const ncolsNumber = Number(ncols);
  const nrowsNumber = Number(nrows);

  if (!validLat(latNumber) || !validLon(lonNumber)) {
    throw new Error("Invalid latitude or longitude");
  }

  const tmpGrid: number[][] = [];
  for (const row of text.split("\n")) {
    if (!/^-?\d+(\.\d+)?(?=\s|$)/.test(row.trimStart())) {
      continue;
    }
    const columns = row.split(" ").filter(Boolean);
    if (columns.length !== ncolsNumber) {
      throw new Error(
        `Size of grid (${columns.length}) does now match nrows (${ncolsNumber})`,
      );
    }
    tmpGrid.push(columns.map(Number));
  }
  // Use 3 decimants for values in the grid
  const roundedGrid = tmpGrid.map((row) =>
    row.map((num) => +Number(num).toFixed(3)),
  );

  if (JSON.stringify(roundedGrid).length > MAXBYTESGRID) {
    throw new UploadWindDataFileSizeTooLargeError(
      `Max ${MAX_BYTES_WRG / 1e3}kB file supported.`,
    );
  }
  // Flip grid to match storage format
  const grid = roundedGrid.map((_, i) => roundedGrid[nrowsNumber - i - 1]);
  if (grid.length !== nrowsNumber || grid[0].length !== ncolsNumber) {
    throw new Error(
      `Size of grid (${grid.length}, ${grid[0].length}) does now match nrows (${nrowsNumber}, ${ncolsNumber})`,
    );
  }
  return {
    ncols: ncolsNumber,
    nrows: nrowsNumber,
    dx: Number(dx),
    dy: Number(dy),
    xllcorner: lonNumber,
    yllcorner: latNumber,
    grid: grid,
    lon: lonNumber,
    lat: latNumber,
    height: gridHeight,
  };
};

function decipherRow(row: string) {
  const [
    pointEasting,
    pointNorthing,
    pointElevation,
    pointHeight,
    pointA,
    pointk,
    pointPower,
    nSectors,
    ...sectorData
  ] = row
    .replace("GridPoint", "")
    .trimStart()
    .trimEnd()
    .split(/\s+/)
    .map(Number);
  return {
    pointEasting,
    pointNorthing,
    pointElevation,
    pointHeight,
    pointA,
    pointk,
    pointPower,
    nSectors,
    sectorData,
  };
}

export const parseWRGData = (
  text: string,
  utmZone: number,
  fileSizeMB: number,
  error: (message: string) => void,
  setInfo?: (message: string) => void,
): WRG => {
  let i = 0;
  let points: WRGPoint[] = [];
  const rows = text.split("\n");
  const [ncols, _, easting, northing, height] = rows[0]
    .trimStart()
    .split(/\s+/)
    .map(Number);
  const firstRow = decipherRow(rows[1]);
  const secondRow = decipherRow(rows[2]);
  const distance = Math.sqrt(
    (firstRow.pointEasting - secondRow.pointEasting) ** 2 +
      (firstRow.pointNorthing - secondRow.pointNorthing) ** 2,
  );
  if (distance < 10) {
    error(`Too short distance between datapoints detected: ${distance}`);
  }
  const sampler = fileSizeMB < 10 ? 1 : fileSizeMB < 20 ? 2 : 3;

  let irow = 1;
  let icol = 1;
  for (const row of rows) {
    i += 1;

    if (icol === ncols) {
      icol = 1;
      irow += 1;
    } else {
      icol += 1;
    }
    if (i == 1) continue;
    if (irow % sampler !== 0 || icol % sampler !== 0) continue;
    const {
      pointEasting,
      pointNorthing,
      pointElevation,
      pointHeight,
      pointA,
      pointk,
      pointPower,
      nSectors,
      sectorData,
    } = decipherRow(row);
    if (!isDefined(pointEasting) || !isDefined(pointNorthing)) continue;
    if (sectorData.length !== 3 * nSectors) {
      error("Mismatching input for number of sectors");
    }

    let sectors: WRGSector[] = [];
    for (let i = 0; i < nSectors; i += 1) {
      const index = i * 3;
      const [sectorP, sectorA, sectork] = sectorData
        .slice(index, index + 3)
        .map(Number);
      if (!isDefined(sectorP)) {
        error(`Unknown data in sector probability: ${sectorP}`);
      }
      const sector: WRGSector = {
        probability: sectorP / 1000,
        A: sectorA / 10,
        k: sectork / 100,
      };
      sectors.push(sector);
    }

    const isDummySectors = sectors.every(({ A, k }) => A === 1.0 && k === 1.5);

    if (isDummySectors) {
      continue;
    }

    const [pointLon, pointLat] = toWGS84(
      [pointEasting, pointNorthing],
      `+proj=utm +zone=${utmZone} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    const point: WRGPoint = {
      easting: pointEasting,
      northing: pointNorthing,
      lon: pointLon,
      lat: pointLat,
      power: pointPower,
      elevation: pointElevation,
      height: pointHeight,
      sectors,
      A: pointA,
      k: pointk,
    };
    points.push(point);
  }
  if (!isDefined(height) || !isDefined(northing) || !isDefined(easting)) {
    error("Failed to detect height and easting/northing");
  }

  // Convert to lon/lat
  const [lon, lat] = toWGS84(
    [easting, northing],
    `+proj=utm +zone=${utmZone} +datum=WGS84 +units=m +no_defs +type=crs`,
  );
  if (setInfo && sampler > 1) {
    setInfo(
      `Large file detected. It was downsampled from ${rows.length} to ${points.length} points. From approx. ${Math.round(distance)} to ${Math.round(distance * sampler)} meters between points.`,
    );
  }
  return {
    height,
    northing,
    easting,
    lon,
    lat,
    points,
  };
};

export function useUploadWindTimeseriesData() {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const processTimeseriesFile = useCallback(
    async (file: File): Promise<WindData | WindDataMultipleHeights> => {
      if (!projectId) {
        throw new Error("No project selected");
      }
      if (file.size > MAXBYTES) {
        throw new UploadWindDataFileSizeTooLargeError(
          `Max ${(MAXBYTES / 1e6).toFixed(1)}MB file supported.`,
        );
      }

      const fileContents = await readFileAsTextOrArrayBuffer(file, "text");

      let windData: WindData | WindDataVortex | WindDataMultipleHeights;
      if (isVortexFormat(fileContents)) {
        windData = parseVortexData(fileContents);
      } else if (isWindDataCsvFormat(fileContents)) {
        windData = parseCsvWindData(fileContents);
      } else if (isWindProFormat(fileContents)) {
        windData = parseWindProData(fileContents);
      } else {
        throw new Error(
          "Unknown file format. Try downloading one of the example files to see supported file formats.",
        );
      }

      return windData;
    },
    [projectId],
  );
  const uploadTimeseriesFile = useCallback(
    async (file: File) => {
      if (!projectId) return;
      if (file.size > MAXBYTES) {
        throw new UploadWindDataFileSizeTooLargeError(
          `Max ${(MAXBYTES / 1e6).toFixed(1)}MB file supported.`,
        );
      }

      let fileName = file.name.replace(/(\.\w+)$/g, "");
      let fileType = CustomWindFileType.TIMESERIES;

      const data = await processTimeseriesFile(file);

      return saveWindData(projectId, data, fileName, fileType);
    },
    [processTimeseriesFile, projectId],
  );
  return {
    uploadTimeseriesFile,
    processTimeseriesFile,
  };
}

export function useUploadWRGData() {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const processWRGFile = useCallback(
    async (
      file: File,
      utmZone: number,
      error: (message: string) => void,
      setInfo: (message: string) => void,
    ) => {
      if (!projectId) return;
      if (file.size > MAX_BYTES_WRG) {
        throw new UploadWindDataFileSizeTooLargeError(
          `Max ${MAX_BYTES_WRG / 1e6}MB file supported.`,
        );
      }

      const fileContents = await readFileAsTextOrArrayBuffer(file, "text");
      let errorCount = 0;
      const windData = parseWRGData(
        fileContents,
        utmZone,
        file.size / 1e6,
        (s) => {
          errorCount++;
          if (5 <= errorCount) throw new Error("Failed to parse WRG file");
          error(s);
        },
        setInfo,
      );

      return windData;
    },
    [projectId],
  );

  const uploadWRGFile = useCallback(
    async (file: File, utmZone: number, error: (message: string) => void) => {
      if (!projectId) return;
      if (file.size > MAX_BYTES_WRG) {
        throw new UploadWindDataFileSizeTooLargeError(
          `Max ${MAX_BYTES_WRG / 1e6}MB file supported.`,
        );
      }

      const fileContents = await readFileAsTextOrArrayBuffer(file, "text");
      let errorCount = 0;
      const data = parseWRGData(fileContents, utmZone, file.size / 1e6, (s) => {
        errorCount++;
        if (5 <= errorCount) throw new Error("Failed to parse WRG file");
        error(s);
      });

      return saveWindData(projectId, data, file.name, CustomWindFileType.WRG);
    },
    [projectId],
  );

  return {
    processWRGFile,
    uploadWRGFile,
  };
}

export function useUploadWindGridData() {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const processGridFile = useCallback(
    async (file: File, gridHeightNumber: number) => {
      if (!projectId) return;

      const fileContents = await readFileAsTextOrArrayBuffer(file, "text");
      const windData: MeanSpeedGridCustom = parseVortexGridData(
        fileContents,
        gridHeightNumber,
      );
      return windData;
    },
    [projectId],
  );

  const uploadGridFile = useCallback(
    async (file: File, gridHeightNumber: number) => {
      if (!projectId) return;
      if (file.size > MAXBYTESGRID) {
        throw new UploadWindDataFileSizeTooLargeError(
          `Max ${MAXBYTESGRID / 1e3}kB file supported.`,
        );
      }

      const fileContents = await readFileAsTextOrArrayBuffer(file, "text");
      const fileName = file.name.replace(/(\.\w+)$/g, "");
      const fileNameWithHeight = `${fileName}_${gridHeightNumber}m`;
      const windData: MeanSpeedGridCustom = parseVortexGridData(
        fileContents,
        gridHeightNumber,
      );

      const fileType = CustomWindFileType.MEAN_SPEED_GRID;

      return saveWindData(projectId, windData, fileNameWithHeight, fileType);
    },
    [projectId],
  );
  return {
    uploadGridFile,
    processGridFile,
  };
}

export function useRenameUploadedWindData() {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const getCurrentName = useJotaiCallback(
    async (get, _, fileId: string) => {
      const wd = await get(
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
      );
      return wd.find((row) => row.id === fileId)?.name;
    },
    [projectId],
  );

  const updateLocal = useJotaiCallback(
    (get, set, fileId: string, newName: string) => {
      return aset(
        get,
        set,
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
        (customWindData) => {
          return customWindData.map((row) => {
            if (row.id === fileId) {
              return {
                ...row,
                name: newName,
              };
            }
            return row;
          });
        },
      );
    },
    [projectId],
  );

  const renameWindDataFile = useCallback(
    async (fileId: string, name: string) => {
      if (!projectId) return;
      const oldName = await getCurrentName(fileId);
      updateLocal(fileId, name);

      try {
        const response = await renameWindData(projectId, fileId, name);
        return response;
      } catch (error) {
        if (oldName) {
          updateLocal(fileId, oldName);
        }
        throw error;
      }
    },
    [getCurrentName, projectId, updateLocal],
  );

  return renameWindDataFile;
}

export function useDeleteUploadedWindData() {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const getCurrentRow = useJotaiCallback(
    async (get, _, fileId: string) => {
      const wd = await get(
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
      );
      return wd.find((row) => row.id === fileId);
    },
    [projectId],
  );

  const removeLocal = useJotaiCallback(
    (get, set, fileId: string) => {
      return aset(
        get,
        set,
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
        (customWindData) => {
          return customWindData.filter((row) => row.id !== fileId);
        },
      );
    },
    [projectId],
  );

  const addLocal = useJotaiCallback(
    (get, set, windData: UploadedWindData[0]) => {
      return aset(
        get,
        set,
        uploadedWindDataFamily({
          nodeId: projectId,
        }),
        (customWindData) => {
          return [...customWindData, windData];
        },
      );
    },
    [projectId],
  );

  const deleteWindDataFile = useCallback(
    async (fileId: string) => {
      if (!projectId) return;
      const oldRow = await getCurrentRow(fileId);
      removeLocal(fileId);
      try {
        return await deleteWindData(projectId, fileId);
      } catch (error) {
        if (oldRow) {
          addLocal(oldRow);
        }
        throw error;
      }
    },
    [addLocal, getCurrentRow, projectId, removeLocal],
  );

  return deleteWindDataFile;
}
