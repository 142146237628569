import { useAtomValue } from "jotai";
import { Row } from "components/General/Layout";
import { currentVersionSelector } from "state/project";
import styled from "styled-components";
import Close from "@icons/24/Close.svg?react";
import { dateToDateTime } from "utils/utils";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { useSetVersion } from "hooks/useSetVersion";
import { IconBtn } from "components/General/Icons";
import { spacing4 } from "styles/space";

const Wrapper = styled.div`
  display: flex;
  gap: 6rem;
  position: absolute;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) + 2rem
  );

  left: 50%;
  color: ${colors.textNegative};
  background-color: ${colors.surfaceBrand};
  border-radius: 0.4rem;
  padding: 0.6rem 1.6rem;
  transform: translateX(-50%);
  ${typography.body}
`;

export const ReadOnlyInfo = () => {
  const projectVersion = useAtomValue(currentVersionSelector);
  const setVersion = useSetVersion();
  if (!projectVersion) return null;

  return (
    <Wrapper>
      <Row
        style={{
          alignItems: "center",
        }}
      >
        Viewing {dateToDateTime(new Date(projectVersion))}
        <IconBtn
          hoverBackgroundColor={colors.grey900}
          hoverStroke={colors.blue300}
          iconColor="white"
          size="1.2rem"
          style={{
            padding: spacing4,
          }}
          onClick={() => setVersion(undefined)}
        >
          <Close />
        </IconBtn>
      </Row>
    </Wrapper>
  );
};
