import { organisationIdAtom } from "state/pathParams";
import { Column, Row } from "components/General/Layout";
import { TabSubtitle } from "components/Organisation/OrganisationRightSide/style";
import { StyledLink } from "components/Organisation/Library/style";
import { useAtomValue } from "jotai";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { ResourceSection } from "./ResourceSection";
import {
  analysisResourceWithAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeState,
  financialResourceWithAccessOnNodeState,
  foundationResourceWithAccessOnNodeState,
  turbineResourceWithAccessOnNodeState,
} from "components/Organisation/Library/state";
import {
  orgAnalysisManageAccessSelector,
  orgCableManageAccessSelector,
  orgDataPackagesManageAccessSelector,
  orgExportcableManageAccessSelector,
  orgFinanicalManageAccessSelector,
  orgFoundationManageAccessSelector,
  orgSubstationManageAccessSelector,
  orgTurbineManageAccessSelector,
} from "state/user";
import useNodeTurbineResourcesCrud from "../../useNodeTurbineResourcesCrud";
import useNodeFoundationResourcesCrud from "../../useNodeFoundationResourcesCrud";
import { Node } from "services/customerAPI";
import {
  cableResourceWithAccessOnNodeState,
  exportCableResourceWithAccessOnNodeState,
} from "state/cableType";
import { SingleTurbineResource } from "./Resources/SingleTurbineResource";
import { SingleFoundationResource } from "./Resources/SingleFoundationResource";
import { SingleCableResource } from "./Resources/SingleCableResource";
import useNodeDataPackageResourcesCrud from "../../useNodeDataPackageResourcesCrud";
import useNodeAnalysisResourcesCrud from "../../useNodeAnalysisResourcesCrud";
import useNodeFinancialResourcesCrud from "../../useNodeFinancialResourcesCrud";
import useNodeSubstationResourcesCrud from "../../useNodeSubstationResourcesCrud";
import { substationResourceWithAccessOnNodeState } from "state/substationType";
import useNodeExportCableResourcesCrud from "../../useNodeExportCableResourcesCrud";
import useNodeCableResourcesCrud from "../../useNodeCableResourcesCrud";
import { SingleAnalysisResource } from "./Resources/SingleAnalysisResource";
import { SingleDataPackageResource } from "./Resources/SingleDataPackageResource";
import { SingleFinancialResource } from "./Resources/SingleFinancialResource";
import { SingleSubstationResource } from "./Resources/SingleSubstationResource";
import { FoundationType } from "types/foundations";
import { AnalysisConfiguration } from "services/configurationService";
import { CostConfiguration } from "services/costService";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import {
  DataPackageModalWrapper,
  AnalysisModalWrapper,
  FinancialModalWrapper,
  TurbineModalWrapper,
  FoundationModalWrapper,
  CableModalWrapper,
  ExportCableModalWrapper,
  SubstationModalWrapper,
} from "./ModalWrappers";

export function LibraryResources({ node }: { node: Node }) {
  const nodeId = node.id;
  const organisationId = useAtomValue(organisationIdAtom);
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );

  const { addOrUpdate: addOrUpdateTurbine } = useNodeTurbineResourcesCrud();
  const { addOrUpdate: addOrUpdateFoundation } =
    useNodeFoundationResourcesCrud();
  const { addOrUpdate: addOrUpdateCable } = useNodeCableResourcesCrud();
  const { addOrUpdate: addOrUpdateExportCable } =
    useNodeExportCableResourcesCrud();
  const { addOrUpdate: addOrUpdateAnalysis } = useNodeAnalysisResourcesCrud();
  const { addOrUpdate: addOrUpdateFinancial } = useNodeFinancialResourcesCrud();
  const { addOrUpdate: addOrUpdateDataPackage } =
    useNodeDataPackageResourcesCrud();
  const { addOrUpdate: addOrUpdateSubstation } =
    useNodeSubstationResourcesCrud();

  const turbineResources = useAtomValue(
    turbineResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const foundationResources = useAtomValue(
    foundationResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const cableResources = useAtomValue(
    cableResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const exportCableResources = useAtomValue(
    exportCableResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const analysisResources = useAtomValue(
    analysisResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const financialResources = useAtomValue(
    financialResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const dataPackageResources = useAtomValue(
    dataPackageResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const substationResources = useAtomValue(
    substationResourceWithAccessOnNodeState({
      nodeId,
    }),
  );

  const orgTurbineManageAccess = useAtomValue(orgTurbineManageAccessSelector);
  const orgFoundationManageAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const orgCableManageAccess = useAtomValue(orgCableManageAccessSelector);
  const orgExportCableManageAccess = useAtomValue(
    orgExportcableManageAccessSelector,
  );
  const orgAnalysisManageAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );
  const orgDataPackageManageAccess = useAtomValue(
    orgDataPackagesManageAccessSelector,
  );
  const orgSubstationManageAccess = useAtomValue(
    orgSubstationManageAccessSelector,
  );

  return (
    <Column style={{ gap: "2.4rem", height: "100%" }}>
      <Row style={{ alignItems: "center" }}>
        <TabSubtitle>
          Below is an overview of the library resources that are available for{" "}
          {node.name}.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={"https://help.vindtech.com/en/articles/8842345-library"}
          >
            Read more.
          </StyledLink>
        </TabSubtitle>
      </Row>

      <ResourceSection
        title="Turbines"
        tooltipText="Users with library turbine access can give the project access to turbines"
        buttonText="Add turbine"
        hasAccess={orgTurbineManageAccess}
        resources={turbineResources}
        onAdd={(turbineId: string) => addOrUpdateTurbine(nodeId, turbineId)}
        renderResource={(sr) => (
          <SingleTurbineResource
            key={sr.turbine.id + sr.nodeId}
            access={sr}
            nodeId={nodeId}
          />
        )}
        Modal={TurbineModalWrapper}
        existingItems={turbineResources.map((t) => t.turbine.id)}
      />

      <ResourceSection
        title="Foundations"
        tooltipText="Users with library foundation access can give the project access to foundations"
        buttonText="Add foundation"
        hasAccess={orgFoundationManageAccess}
        resources={foundationResources}
        onAdd={(foundation: FoundationType) =>
          addOrUpdateFoundation(nodeId, foundation)
        }
        renderResource={(sr) => (
          <SingleFoundationResource
            key={sr.foundation.id}
            access={sr}
            nodeId={nodeId}
          />
        )}
        Modal={FoundationModalWrapper}
        existingItems={foundationResources.map((f) => f.foundation.id)}
        useFullResource={true}
      />

      {allLibraryAccess && (
        <>
          <ResourceSection
            title="Cables"
            tooltipText="Users with library cable access can give the project access to cables"
            buttonText="Add cable"
            hasAccess={orgCableManageAccess}
            resources={cableResources}
            onAdd={(cableId: string) => addOrUpdateCable(nodeId, cableId)}
            renderResource={(sr) => (
              <SingleCableResource
                key={sr.cable.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={CableModalWrapper}
            existingItems={cableResources.map((c) => c.cable.id)}
          />

          <ResourceSection
            title="Export Cables"
            tooltipText="Users with library export cable access can give the project access to export cables"
            buttonText="Add export cable"
            hasAccess={orgExportCableManageAccess}
            resources={exportCableResources}
            onAdd={(cableId: string) => addOrUpdateExportCable(nodeId, cableId)}
            renderResource={(sr) => (
              <SingleCableResource
                key={sr.cable.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={ExportCableModalWrapper}
            existingItems={exportCableResources.map((c) => c.cable.id)}
          />

          <ResourceSection
            title="Substations"
            tooltipText="Users with library substation access can give the project access to substations"
            buttonText="Add substation"
            hasAccess={orgSubstationManageAccess}
            resources={substationResources}
            onAdd={(substationId: string) =>
              addOrUpdateSubstation(nodeId, substationId)
            }
            renderResource={(sr) => (
              <SingleSubstationResource
                key={sr.substation.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={SubstationModalWrapper}
            existingItems={substationResources.map((s) => s.substation.id)}
          />

          <ResourceSection
            title="Analysis configurations"
            tooltipText="Users with library analysis access can give the project access to analysis"
            buttonText="Add analysis"
            hasAccess={orgAnalysisManageAccess}
            resources={analysisResources}
            onAdd={(config: AnalysisConfiguration) =>
              addOrUpdateAnalysis(nodeId, config)
            }
            renderResource={(sr) => (
              <SingleAnalysisResource
                key={sr.config.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={AnalysisModalWrapper}
            existingItems={analysisResources.map((a) => a.config.id)}
            useFullResource={true}
          />

          <ResourceSection
            title="Financial configurations"
            tooltipText="Users with library financial configuration access can give the project access to financial configurations"
            buttonText="Add financial"
            hasAccess={orgFinancialManageAccess}
            resources={financialResources}
            onAdd={(financialConfig: CostConfiguration) =>
              addOrUpdateFinancial(nodeId, financialConfig)
            }
            renderResource={(sr) => (
              <SingleFinancialResource
                key={sr.config.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={FinancialModalWrapper}
            existingItems={financialResources.map((f) => f.config.id)}
          />

          <ResourceSection
            title="GIS packages"
            tooltipText="Users with library GIS package access can give the project access to GIS packages"
            buttonText="Add GIS package"
            hasAccess={orgDataPackageManageAccess}
            resources={dataPackageResources}
            onAdd={(dataPackage: DataLibraryPackage) =>
              addOrUpdateDataPackage(nodeId, dataPackage)
            }
            renderResource={(sr) => (
              <SingleDataPackageResource
                key={sr.config.id + sr.nodeId}
                access={sr}
                nodeId={nodeId}
              />
            )}
            Modal={DataPackageModalWrapper}
            existingItems={dataPackageResources.map((d) => d.config.id)}
          />
        </>
      )}
    </Column>
  );
}
