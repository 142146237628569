import { TurbineType } from "types/turbines";
import { useSearch } from "./useSearch";
import { organisationTurbineResourceState } from "components/Organisation/Library/state";
import { orgTurbineManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LibraryManageRole } from "components/Organisation/Library/types";
import { getIcon } from "components/General/getIconFromRole";

const keys = ["name", "note"];

export function useSearchLibraryTurbines(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgTurbineManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredTurbines, rawData } = useSearch<TurbineType>(
    enabled,
    searchTerm,
    keys,
    organisationTurbineResourceState({
      organisationId,
    }),
  );

  const filteredSearchItems: SearchResultItem<TurbineType>[] = useMemo(() => {
    return filteredTurbines.map((result) => ({
      id: result.item.id,
      name: result.item.name,
      score: result.score,
      item: result.item,
      icon: getIcon(LibraryManageRole.TURBINE),
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/turbine/${result.item.id}`,
        );
      },
    }));
  }, [filteredTurbines, navigate, organisationId]);

  const searchableItems: SearchItem<TurbineType>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(`/organisation/${organisationId}/library/turbine/${item.id}`);
      },
      icon: getIcon(LibraryManageRole.TURBINE),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredTurbines: filteredSearchItems,
    searchableItems,
  };
}
