import Substation from "@icons/24/Substation.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import { SubHeader } from "components/ConfigurationModal/shared";
import Dropdown from "components/Dropdown/Dropdown";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { useAtomValue } from "jotai";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { DividerWrapper, InstallationDivider } from "../styles";
import InstallationSeason from "./InstallationSeason";
import { projectIdAtom } from "state/pathParams";
import { vesselTypesFamily } from "state/jotai/vesselType";
import {
  isFeederBargeVessel,
  isInstallationVessel,
} from "services/vesselService";
import WeatherLimits from "./WeatherLimits";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { getValue } from "components/InputChangelog/utils";
import { Row } from "components/General/Layout";
const SubstationSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantInstallationVesselTypes = Array.from(
    vesselTypes.values(),
  ).filter(isInstallationVessel);
  const relevantTransportVesselTypes = Array.from(vesselTypes.values()).filter(
    isFeederBargeVessel,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;

  const { installationVessel, feederVessel } = localConfig.ti.substation;
  return (
    <>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Installation vessel</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.substation.installationVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.substation.installationVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={installationVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  substation: {
                    ...localConfig.ti.substation,
                    installationVessel: {
                      ...installationVessel,
                      vesselId: e.target.value,
                    },
                  },
                })
              }
            >
              {relevantInstallationVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.substation.installationVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={installationVessel.dayRate.unit}
            value={installationVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                substation: {
                  ...localConfig.ti.substation,
                  installationVessel: {
                    ...installationVessel,
                    dayRate: {
                      ...installationVessel.dayRate,
                      cost,
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Transport vessel</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.substation.feederVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.substation.feederVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={feederVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  substation: {
                    ...localConfig.ti.substation,
                    feederVessel: {
                      ...feederVessel,
                      vesselId: e.target.value,
                    },
                  },
                })
              }
            >
              {relevantTransportVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.substation.feederVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={feederVessel.dayRate.unit}
            value={feederVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                substation: {
                  ...localConfig.ti.substation,
                  feederVessel: {
                    ...feederVessel,
                    dayRate: {
                      ...feederVessel.dayRate,
                      cost,
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
    </>
  );
};

export const SubstationVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { startMonth, endMonth } = localConfig.ti.substation.installationSeason;

  return (
    <InstallationCategoryEntry
      title="Substations"
      icon={<Substation />}
      defaultOpen={false}
    >
      <SubstationSpecificVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <WeatherLimits
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={"substation"}
        weatherLimits={localConfig.ti.substation.weatherLimits}
      />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <InstallationSeason
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        startMonth={startMonth}
        endMonth={endMonth}
        category={"substation"}
      />
    </InstallationCategoryEntry>
  );
};
