import { SubstationType } from "services/substationService";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/SettingsV2/Shared/styles";
import { Input } from "components/General/Input";
import { substationChangelogInfo } from "components/InputChangelog/const";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import Dropdown from "components/Dropdown/Dropdown";
import { z } from "zod";
import {
  RedirectToLibraryFinancialConfig,
  RedirectToProjectFinancialConfig,
} from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { Row } from "components/General/Layout";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: auto auto auto;
  gap: 2.4rem;
`;

type BasicSettingsProps = {
  substation: SubstationType;
  onChange: (substation: SubstationType) => void;
  disabled?: boolean;
  nodeId: string;
  isLibrary?: boolean;
  isOnshoreProject?: boolean;
};

export const BasicSettings = ({
  substation,
  onChange,
  disabled,
  nodeId,
  isLibrary,
  isOnshoreProject,
}: BasicSettingsProps) => {
  return (
    <Grid>
      <Label>
        <SecondaryText>Name</SecondaryText>
        <ChangelogAndCommentWrapper
          changelogInfo={substationChangelogInfo(
            substation.id,
            nodeId,
            "name",
            "project",
          )}
          style={{ width: "42.4rem" }}
          disabled={disabled}
        >
          <Input
            disabled={disabled}
            value={substation.name}
            onChange={(e) => {
              onChange({
                ...substation,
                name: e.target.value,
              });
            }}
            style={{ width: "100%" }}
          />
        </ChangelogAndCommentWrapper>
      </Label>

      {!isOnshoreProject && (
        <Label>
          <SecondaryText>Type</SecondaryText>
          <ChangelogAndCommentWrapper
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "type",
              "project",
            )}
            style={{ width: "20rem" }}
            disabled={disabled}
          >
            <Dropdown
              style={{ width: "100%" }}
              value={substation.type ?? "offshore"}
              onChange={(e) => {
                const type = z
                  .enum(["offshore", "onshore"])
                  .parse(e.target.value);
                onChange({
                  ...substation,
                  type,
                });
              }}
              disabled={disabled}
            >
              <option value="offshore">Offshore</option>
              <option value="onshore">Onshore</option>
            </Dropdown>
          </ChangelogAndCommentWrapper>
        </Label>
      )}

      <Label>
        <SecondaryText>Cost</SecondaryText>
        <Row>
          {isLibrary ? (
            <RedirectToLibraryFinancialConfig />
          ) : (
            <RedirectToProjectFinancialConfig menuId={SUBSTATION_MENU_ID} />
          )}
        </Row>
      </Label>
    </Grid>
  );
};
