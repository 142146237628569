/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Error = z
      .object({
        error: z.string().optional(),
      })
      .passthrough();
    export const UpdateComment = z
      .object({
        comment: z.string(),
        keyPath: z.string(),
      })
      .strict();
    export const Comment = z
      .object({
        keyPath: z.string(),
        comment: z.string(),
        createdAt: z.number(),
        author: z.string(),
      })
      .strict();
    export const LibraryManageRole = z.union([
      z.literal("org_turbine_manage"),
      z.literal("org_foundation_manage"),
      z.literal("org_cable_manage"),
      z.literal("org_export_cable_manage"),
      z.literal("org_analysis_manage"),
      z.literal("org_financial_manage"),
      z.literal("org_data_package_manage"),
      z.literal("org_substation_manage"),
    ]);
    export const RequiredAccessRole = LibraryManageRole;
    export const EventActions = z.union([
      z.literal("CREATE"),
      z.literal("UPDATE"),
      z.literal("DELETE"),
    ]);
    export const ChangelogEntry = z
      .object({
        id: z.string(),
        branchId: z.string().optional(),
        nodeId: z.string(),
        requiredAccessRole: RequiredAccessRole.optional(),
        author: z.string(),
        deleteLogs: z.boolean().optional(),
        action: EventActions,
        meta: z.object({}).passthrough().optional(),
        comments: z.array(Comment).optional(),
        version: z.number(),
        sqsMessageId: z.string(),
      })
      .strict();
    export const ChangelogMetadataItem = z
      .object({
        resourceId: z.string(),
        libraryType: LibraryManageRole,
        lastModified: z.number(),
        lastModifiedBy: z.string(),
        action: EventActions,
      })
      .strict();
  }
}
export const components = {
  schemas: {
    Error: Components.Schemas.Error,
    UpdateComment: Components.Schemas.UpdateComment,
    Comment: Components.Schemas.Comment,
    LibraryManageRole: Components.Schemas.LibraryManageRole,
    RequiredAccessRole: Components.Schemas.RequiredAccessRole,
    EventActions: Components.Schemas.EventActions,
    ChangelogEntry: Components.Schemas.ChangelogEntry,
    ChangelogMetadataItem: Components.Schemas.ChangelogMetadataItem,
  },
};

export const paths = {
  "/api/timeline/changelog/node/{nodeId}/{id}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        id: z.string(),
      }),
      responses: {
        200: {
          /** Get changelogs */
          "application/json": z.array(Components.Schemas.ChangelogEntry),
        },
      },
    },
  },
  "/api/timeline/changelog/organisation/{organisationId}/{id}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
        id: z.string(),
      }),
      responses: {
        200: {
          /** Get changelogs */
          "application/json": z.array(Components.Schemas.ChangelogEntry),
        },
      },
    },
  },
  "/api/timeline/changelog/node/{nodeId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** ids */
      requestBody: {
        "application/json": z
          .object({
            ids: z.array(z.string()),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Latest changelogs for ids */
          "application/json": z.array(Components.Schemas.ChangelogEntry),
        },
      },
    },
  },
  "/api/timeline/changelog/organisation/{organisationId}": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** ids */
      requestBody: {
        "application/json": z
          .object({
            ids: z.array(z.string()),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Latest changelogs for ids */
          "application/json": z.array(Components.Schemas.ChangelogEntry),
        },
      },
    },
  },
  "/api/timeline/changelog/node/{nodeId}/entry/{changelogId}/version/{version}/comment":
    {
      put: {
        pathParams: z.object({
          nodeId: z.string(),
          changelogId: z.string(),
          version: z.string(),
        }),
        /** Comment */
        requestBody: {
          "application/json": Components.Schemas.UpdateComment,
        },
        responses: {
          200: {
            /** Add new comment */
            "application/json": Components.Schemas.Comment,
          },
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
          409: {
            /** Comment alreay exists */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/node/{nodeId}/entry/{changelogId}/version/{version}/comment/{keyPath}":
    {
      delete: {
        pathParams: z.object({
          nodeId: z.string(),
          changelogId: z.string(),
          version: z.string(),
          keyPath: z.string(),
        }),
        responses: {
          204: z.never(),
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/node/{nodeId}/admin/entry/{changelogId}/version/{version}/comment":
    {
      put: {
        pathParams: z.object({
          nodeId: z.string(),
          changelogId: z.string(),
          version: z.string(),
        }),
        /** Comment */
        requestBody: {
          "application/json": Components.Schemas.UpdateComment,
        },
        responses: {
          200: {
            /** Add new comment */
            "application/json": Components.Schemas.Comment,
          },
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
          409: {
            /** Comment alreay exists */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/node/{nodeId}/admin/entry/{changelogId}/version/{version}/comment/{keyPath}":
    {
      delete: {
        pathParams: z.object({
          nodeId: z.string(),
          changelogId: z.string(),
          version: z.string(),
          keyPath: z.string(),
        }),
        responses: {
          204: z.never(),
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/organisation/{organisationId}/{libraryManageRole}/entry/{changelogId}/version/{version}/comment":
    {
      put: {
        pathParams: z.object({
          organisationId: z.string(),
          libraryManageRole: Components.Schemas.LibraryManageRole,
          changelogId: z.string(),
          version: z.string(),
        }),
        /** Comment */
        requestBody: {
          "application/json": Components.Schemas.UpdateComment,
        },
        responses: {
          200: {
            /** Add new comment */
            "application/json": Components.Schemas.Comment,
          },
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
          409: {
            /** Comment alreay exists */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/organisation/{organisationId}/{libraryManageRole}/entry/{changelogId}/version/{version}/comment/{keyPath}":
    {
      delete: {
        pathParams: z.object({
          organisationId: z.string(),
          libraryManageRole: Components.Schemas.LibraryManageRole,
          changelogId: z.string(),
          version: z.string(),
          keyPath: z.string(),
        }),
        responses: {
          204: z.never(),
          400: {
            /** Bad request */
            "application/json": z.string(),
          },
          404: {
            /** Changelog not found */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/timeline/changelog/metadata/organisation/{organisationId}/node/{nodeId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          nodeId: z.string(),
        }),
        responses: {
          200: {
            /** Get changelog metadata */
            "application/json": z.array(
              Components.Schemas.ChangelogMetadataItem,
            ),
          },
        },
      },
    },
};

// Generated using openapi-to-zod v0.1.47
