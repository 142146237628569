import {
  AnalysisConfigSelector,
  WindConfigSelector,
} from "components/CompareParksModal/ParkComparisonView/ComparisonParkV2/config-selector-dropdowns";
import {
  AttributeValue,
  ColumnTemplate,
} from "components/CompareParksModal/ParkComparisonView/ParkComparisonView.style";
import {
  AnalysisConfigNotFoundError,
  WindConfigNotFoundError,
} from "components/ProductionV2/ErrorUtils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { AnalysisConfiguration } from "services/configurationService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";

export const AnalysisConfigNotFoundCompareErrorBoundaryFallbackRender = ({
  error,
  onSelectAnalysisConfig,
  onSelectWindConfig,
}: {
  error: Error;
  onSelectAnalysisConfig: (config: AnalysisConfiguration) => void;
  onSelectWindConfig: (config: WindSourceConfiguration) => void;
}) => {
  if (error instanceof WindConfigNotFoundError) {
    return (
      <>
        <SimpleAlert
          text={"Please select a wind configuration"}
          type={"error"}
        />

        <AttributeValue>
          <WindConfigSelector
            selectedConfig={undefined}
            setSelectedConfig={onSelectWindConfig}
          />
        </AttributeValue>
      </>
    );
  }

  if (error instanceof AnalysisConfigNotFoundError) {
    return (
      <ColumnTemplate>
        <SimpleAlert
          text={"Please select an analysis configuration"}
          type={"error"}
        />
        <AttributeValue>
          <AnalysisConfigSelector
            selectedConfig={undefined}
            setSelectedConfig={onSelectAnalysisConfig}
          />
        </AttributeValue>
      </ColumnTemplate>
    );
  }

  throw error;
};
