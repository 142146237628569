import styled from "styled-components";
import {
  ValidationWarningOrganisationStateType,
  ValidationWarningStateType,
} from "../../state/validationWarnings";
import { Row } from "../General/Layout";
import Close from "@icons/24/Close.svg?react";
import { IconREMSize, TextIcon } from "../../styles/typography";
import { colors } from "../../styles/colors";
import { parkIdAtom } from "../../state/pathParams";
import { ValidationAlert } from "./ValidationAlert";
import { useEffect, useState } from "react";
import ArrowDown from "@icons/24/ArrowDown.svg?react";
import ArrowRight from "@icons/24/ArrowRight.svg?react";
import { useGoToFeatureCenter, useGoToFeaturesIds } from "hooks/map";
import {
  ValidationSeverity,
  ValidationWarningButton,
  getOnclickHandler,
  validationWarningDetailsText,
  validationWarningSeverity,
  validationWarningTitleText,
} from "./utils";
import { ElementToIcon } from "components/ElementsToIcon/ElementsToIcon";
import HeaderWithBorder from "components/General/HeaderWithBorder";
import { spacing4 } from "styles/space";
import { Comp } from "types/utils";
import { useAtomValue } from "jotai";
import { parksFamily } from "state/jotai/park";
import { DefaultMap } from "lib/DefaultMap";
import { mapAtom } from "state/map";

const Container = styled.div`
  position: fixed;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spacing4}
  );
  right: ${spacing4};
  border-radius: 0.4rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 1.8rem 0;
  width: 35rem;
  cursor: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 4;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: calc(100vh - 26rem);
  overflow-y: auto;
  padding: 0 1.8rem;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  margin: 0.6rem 0;
  svg {
    stroke: ${colors.iconSubtle};
    width: 1.4rem;
    height: 1.4rem;
    overflow: visible;
    margin-right: 0.7rem;
  }
`;

const ValidationWarningBox = ({
  close,
  warnings,
  ...props
}: Comp<
  "div",
  {
    close: () => void;

    warnings: (
      | ValidationWarningOrganisationStateType
      | ValidationWarningStateType
    )[];
  }
>) => {
  const selectedParkId = useAtomValue(parkIdAtom);
  const parks = useAtomValue(parksFamily({ branchId: undefined }));

  const warningsByPark = new DefaultMap<string, typeof warnings>(() => []);
  warnings.forEach((warn) => {
    const parkId = "parkId" in warn ? warn.parkId : "General";
    warningsByPark.get(parkId).push(warn);
  });

  const uniqueParkIds = Object.keys(warningsByPark);
  const initialExpandedState = uniqueParkIds.reduce(
    (acc: Record<string, boolean>, curr: string) => {
      acc[curr] = true;
      return acc;
    },
    {},
  );
  const zoomToFeature = useGoToFeatureCenter();
  const zoomToFeatureIds = useGoToFeaturesIds();
  const map = useAtomValue(mapAtom);

  const [isExpanded, setIsExpanded] =
    useState<Record<string, boolean>>(initialExpandedState);

  useEffect(() => {
    if (selectedParkId) {
      setIsExpanded((prevState) => ({
        ...prevState,
        [selectedParkId]: true,
      }));
    }
  }, [selectedParkId]);

  return (
    <Container {...props} onClick={(e) => e.stopPropagation()}>
      <Row
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1.8rem",
        }}
      >
        <h3 style={{ margin: 0 }}>Validation warnings</h3>
        <IconREMSize width={1.2} height={1.2}>
          <TextIcon onClick={close}>
            <Close />
          </TextIcon>
        </IconREMSize>
      </Row>
      <ContentContainer>
        {Array.from(warningsByPark.entries()).map(([parkId, warnings]) => {
          const park = parks.find((park) => park.id === parkId);
          const parkName =
            parks.find((park) => park.id === parkId)?.properties.name ??
            "General";
          if (warnings.length === 0) return null;
          return (
            <div key={parkId}>
              <HeaderRow
                onClick={() =>
                  setIsExpanded((prevState) => ({
                    ...prevState,
                    [parkId]: !prevState[parkId],
                  }))
                }
              >
                {park && (
                  <ElementToIcon
                    element={park}
                    fillPolygons={true}
                    fillParks={true}
                    style={{
                      width: "1.4rem",
                      height: "1.4rem",
                    }}
                  />
                )}
                <HeaderWithBorder title={parkName}></HeaderWithBorder>
                {isExpanded[parkId] ? <ArrowDown /> : <ArrowRight />}
              </HeaderRow>

              {isExpanded[parkId] &&
                warnings.flatMap((warn, index) => {
                  const doOnClick = getOnclickHandler(
                    warn,
                    zoomToFeature,
                    zoomToFeatureIds,
                  );

                  const onClickHandler = (isExpanded: boolean) => {
                    if (isExpanded) {
                      doOnClick?.();
                    }
                  };

                  const isError =
                    validationWarningSeverity[warn.type] ===
                    ValidationSeverity.Error;

                  const title = validationWarningTitleText[warn.type];

                  const description =
                    "detailsText" in warn
                      ? warn.detailsText
                      : validationWarningDetailsText[warn.type];

                  const actionButton = ValidationWarningButton({
                    warn,
                    map,
                  })[warn.type];
                  const type = isError ? "error" : "warning";
                  return (
                    <ElementWrapper key={`${warn.type}-${index}`}>
                      <ValidationAlert
                        title={title}
                        description={description}
                        type={type}
                        actionButton={actionButton}
                        onHeaderClick={onClickHandler}
                        _isExpanded={selectedParkId === parkId}
                        disableColorOnCollapsed={true}
                      />
                    </ElementWrapper>
                  );
                })}
            </div>
          );
        })}
      </ContentContainer>
    </Container>
  );
};

export default ValidationWarningBox;
