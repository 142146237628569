import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  ABLY_REPLY_REACTIONS_ADD_FILTER,
  ABLY_REPLY_REACTIONS_REMOVE_FILTER,
} from "../../../state/ably";
import { postReplyReaction, deleteReplyReaction } from "../service";
import {
  userReactionSaveInProgressState,
  threadRepliesAtomFamily,
  replyReactionsCounterAtomFamily,
  userReactionsAtomFamily,
} from "../state";
import { Reaction } from "../types";
import { useAblyPublish } from "../../../hooks/useAblyPublish";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { aset } from "utils/jotai";

export const useReplyReactionCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const ablyPublish = useAblyPublish(projectNodeId);

  const setReactionSaveInProgress = useSetAtom(userReactionSaveInProgressState);

  const post = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        setReactionSaveInProgress(replyId);
        const res = await postReplyReaction(
          projectNodeId,
          branchId,
          threadId,
          replyId,
          {
            reaction: "like",
          },
        ).finally(() => setReactionSaveInProgress(undefined));

        await aset(
          get,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (curr) =>
            curr.map((c) =>
              c.replyId === replyId
                ? {
                    ...c,
                    reactions: c.reactions + 1,
                  }
                : c,
            ),
        );
        set(
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (c) => c + 1,
        );

        await aset(
          get,
          set,
          userReactionsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (curr) => curr.concat([res]),
        );

        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_REPLY_REACTIONS_ADD_FILTER,
          res,
        );
        return res;
      },
      [ablyPublish, branchId, projectNodeId, setReactionSaveInProgress],
    ),
  );

  const remove = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        threadId: string,
        replyId: string,
        reactionId: string,
      ) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === replyId
                ? {
                    ...c,
                    reactions: c.reactions - 1,
                  }
                : c,
            );
          },
        );
        await aset(
          get,
          set,
          userReactionsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.filter((c) => c.reactionId !== reactionId);
          },
        );
        const res = await deleteReplyReaction(
          projectNodeId,
          branchId,
          threadId,
          replyId,
          reactionId,
        );
        set(
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (cur) => {
            return cur + 1;
          },
        );
        ablyPublish(
          `${projectNodeId}:all`,
          ABLY_REPLY_REACTIONS_REMOVE_FILTER,
          {
            threadId,
            replyId,
            reactionId,
          },
        );
        return res;
      },
      [ablyPublish, projectNodeId, branchId],
    ),
  );

  const localPost = useAtomCallback(
    useCallback(async (get, set, reaction: Reaction) => {
      await aset(
        get,
        set,
        threadRepliesAtomFamily({
          nodeId: reaction.nodeId,
          branchId: reaction.branchId,
          threadId: reaction.threadId,
        }),
        (cur) => {
          return cur.map((c) =>
            c.replyId === reaction.replyId
              ? {
                  ...c,
                  reactions: c.reactions + 1,
                }
              : c,
          );
        },
      );

      if (reaction.replyId) {
        set(
          replyReactionsCounterAtomFamily({
            nodeId: reaction.nodeId,
            branchId: reaction.branchId,
            threadId: reaction.threadId,
            replyId: reaction.replyId,
          }),
          (cur) => cur + 1,
        );
      }
    }, []),
  );

  const localRemove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, replyId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadRepliesAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.replyId === replyId
                ? {
                    ...c,
                    reactions: c.reactions - 1,
                  }
                : c,
            );
          },
        );
        set(
          replyReactionsCounterAtomFamily({
            nodeId: projectNodeId,
            branchId,
            threadId,
            replyId,
          }),
          (cur) => cur + 1,
        );
      },
      [branchId, projectNodeId],
    ),
  );

  return {
    post,
    remove,
    localPost,
    localRemove,
  };
};
