import { useSetPathParams } from "App";
import { Suspense, useEffect, useState, useRef } from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";
import { setAccessTokenGlobal } from "state/global";

export default function ArchiveProjectAccess() {
  useSetPathParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState<boolean | null>(null);
  const tokenProcessed = useRef(false);

  useEffect(() => {
    if (tokenProcessed.current) return;

    const accessToken = searchParams.get("access_token");
    if (accessToken) {
      setAccessTokenGlobal(accessToken);
      setIsValidToken(true);

      // Remove the access_token from the URL
      searchParams.delete("access_token");
      setSearchParams(searchParams, { replace: true });

      // Use navigate to remove the query parameter from the browser's address bar
      navigate(window.location.pathname, { replace: true });

      tokenProcessed.current = true;
    } else if (!tokenProcessed.current) {
      setIsValidToken(false);
      tokenProcessed.current = true;
    }
  }, [searchParams, setSearchParams, navigate]);

  if (isValidToken === null) {
    return <div>Loading...</div>;
  }

  if (isValidToken === false) {
    return <div>Invalid access token</div>;
  }

  return (
    <Suspense fallback={"ArchiveProjectAccess loading..."}>
      <Outlet />
    </Suspense>
  );
}
