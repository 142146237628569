import { archivedProjectVersionsAtomFamily } from "../state";
import { ArchivedProjectVersion } from "../types";
import InformationIcon from "@icons/24/Information.svg?react";
import styled from "styled-components";
import { Column, Row } from "components/General/Layout";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { dateToYearDateTime } from "utils/utils";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { Menu, MenuItem } from "components/General/Menu";
import Trashcan from "@icons/24/Bin.svg?react";
import { useRef, useState } from "react";
import { Anchor } from "components/General/Anchor";
import MenuVertical from "@icons/24/MenuVertical.svg?react";
import DownloadIcon from "@icons/24/Download.svg?react";
import LoadingCircle from "@icons/24/Loading-Circle.svg?react";
import AlertCircle from "@icons/24/AlertCircle.svg?react";
import ProjectData from "@icons/24/ArchivedProjectVersions.svg";
import { Icon, IconBtn } from "components/General/Icons";
import Button from "components/General/Button";
import { modalTypeOpenAtom } from "state/modal";
import { NewArchivedVersionModalTypeName } from "./NewModal";
import { useDownloadFile } from "./useDownloadFile";
import { useDeleteArchivedVersions } from "../useArchivedVersions";
import { useAtomValue, useSetAtom } from "jotai";
import Tooltip from "components/General/Tooltip";
import NoItemsGeneric from "components/General/NoItemsGeneric";

export function Inner({
  organisationId,
  projectId,
  branchId,
  parkId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
}) {
  const versions = useAtomValue(
    archivedProjectVersionsAtomFamily({ organisationId, projectId, branchId }),
  );
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  return (
    <Column style={{ gap: "1.2rem", height: "100%", position: "relative" }}>
      {versions.length === 0 && (
        <NoItemsGeneric
          headerText="No data exports"
          subText="Create a new export to download all input and output data for a branch at that specific point in time."
          Icon={<ProjectData />}
        />
      )}
      <Column style={{ overflow: "auto", flex: 1, paddingBottom: "4.6rem" }}>
        {versions.map((version) => (
          <VersionItem
            key={version.createdAt + version.author}
            version={version}
          />
        ))}
      </Column>
      <Row
        style={{
          position: "sticky",
          bottom: "-0.2rem",
          padding: "0.8rem 0 0.4rem",
          backgroundColor: "white",
        }}
      >
        <Button
          text="New data export"
          onClick={() =>
            setModalTypeOpen({
              modalType: NewArchivedVersionModalTypeName,
              metadata: { organisationId, projectId, branchId, parkId },
            })
          }
          style={{ marginLeft: "auto" }}
        />
      </Row>
    </Column>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div<{
  status: "started" | "completed" | "failed";
}>`
  display: flex;
  flex-direction: column;
  padding: ${(p) =>
    p.status === "completed" ? "1.2rem" : "0.8rem 1.2rem 1.2rem 1.2rem"};
  background-color: ${(p) =>
    p.status === "completed"
      ? colors.surfaceSecondary
      : p.status === "failed"
        ? colors.red50
        : colors.blue50};
  gap: 0.8rem;
  border-radius: ${(p) =>
    p.status === "completed" ? "0.4rem" : "0 0 0.4rem 0.4rem"};
`;

const Name = styled.p`
  ${typography.sub2}
  margin: 0;
`;

const Description = styled.p`
  ${typography.caption}
  margin: 0;
`;

const TextSecondary = styled.p`
  ${typography.caption}
  margin: 0;
  color: ${colors.textSecondary};
`;

function VersionItem({ version }: { version: ArchivedProjectVersion }) {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { download, inProgress } = useDownloadFile();
  const { _delete } = useDeleteArchivedVersions();

  return (
    <Container>
      {version.status === "started" && (
        <Row
          style={{
            alignItems: "center",
            backgroundColor: colors.blue100,
            borderRadius: "0.4rem 0.4rem 0 0",
            padding: "0.4rem 1.2rem",
          }}
        >
          <Icon
            style={{
              padding: "0.4rem",
              borderRadius: "50%",
              backgroundColor: colors.blue200,
            }}
            size={"1.2rem"}
          >
            <LoadingCircle />
          </Icon>
          <TextSecondary>Export in progress (Est. 1-2min)</TextSecondary>
        </Row>
      )}
      {version.status === "failed" && (
        <Row
          style={{
            alignItems: "center",
            backgroundColor: colors.red100,
            borderRadius: "0.4rem 0.4rem 0 0",
            padding: "0.4rem 1.2rem",
          }}
        >
          <Icon
            style={{
              padding: "0.4rem",
              borderRadius: "50%",
              backgroundColor: colors.red200,
            }}
            size={"1.2rem"}
          >
            <AlertCircle />
          </Icon>
          <TextSecondary>Export failed</TextSecondary>
          <IconBtn
            onClick={() =>
              _delete(
                version.organisationId,
                version.nodeId,
                version.branchId,
                version.id,
              )
            }
            size={"1.4rem"}
            style={{ marginLeft: "auto" }}
            iconColor={colors.iconDefault}
          >
            <Trashcan />
          </IconBtn>
        </Row>
      )}
      <InnerContainer status={version.status}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Name>{version.name}</Name>
          {version.status === "completed" && (
            <IconBtn
              onClick={() => !menuOpen && setMenuOpen(true)}
              size={"1.4rem"}
              //@ts-ignore
              ref={ref}
            >
              <MenuVertical />
            </IconBtn>
          )}
          {menuOpen && (
            <Anchor
              baseRef={ref}
              basePlace="bottomRight"
              floatPlace="topRight"
              anchorStyle={{ zIndex: 1000000 }}
            >
              <Menu
                submenuRef={menuRef}
                setOpen={setMenuOpen}
                style={{ minWidth: "15rem" }}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  name="Download"
                  disabled={inProgress}
                  onClick={() => download(version)}
                />
                <MenuItem
                  icon={<Trashcan />}
                  name="Delete"
                  onClick={() => {
                    _delete(
                      version.organisationId,
                      version.nodeId,
                      version.branchId,
                      version.id,
                    );
                  }}
                />
              </Menu>
            </Anchor>
          )}
        </Row>
        <Description>{version.description ?? ""}</Description>
        <Row style={{ alignItems: "center" }}>
          <TextSecondary>
            {dateToYearDateTime(new Date(version.createdAt))}
          </TextSecondary>
          <UserInfoOrganisation userId={version.author} size={1.6} />
        </Row>
        {version.isLegacySnapshot && (
          <Tooltip
            outerDivStyle={{ marginLeft: "auto" }}
            text="This export is based on a previous version history event (deprecated) and only contains the branch features."
          >
            <Row alignCenter style={{ gap: "0.4rem", marginLeft: "auto" }}>
              <Icon size="1.4rem" stroke={colors.textSecondary}>
                <InformationIcon />
              </Icon>
              <TextSecondary>Features only</TextSecondary>
            </Row>
          </Tooltip>
        )}
      </InnerContainer>
    </Container>
  );
}
