import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { SimpleWindRose, WindTimeseries } from "../../state/windStatistics";
import Button from "../General/Button";
import { downloadText } from "../../utils/utils";
import { Mixpanel } from "../../mixpanel";
import DownloadIcon from "@icons/24/Download.svg?react";
import Tooltip from "../General/Tooltip";
import { useCallback, useState } from "react";
import {
  PresignedUrlResponse,
  fetchAnalysisWindData,
} from "functions/production";
import SplitButton from "components/General/SplitButton";
import { MenuItem } from "components/General/Menu";

const createWindDataCSV = (windTimeseries: WindTimeseries): string => {
  const speeds = windTimeseries.speed.map((s) => s.toFixed(2));

  const alpha = windTimeseries.alpha
    ? typeof windTimeseries.alpha === "number"
      ? windTimeseries.alpha
      : windTimeseries.alpha.reduce((acc, a) => (acc += a), 0) /
        windTimeseries.alpha.length
    : 0;
  const directions = windTimeseries.direction.map(
    (d) => Math.round(d * 10) / 10,
  );
  if (!windTimeseries.timestamps) {
    return (
      `#height,${windTimeseries.height}\n#lon,${windTimeseries.longitude}\n#lat,${windTimeseries.latitude}\n#alpha,${alpha}\n` +
      "speed,direction\r\n" +
      speeds
        .map((s, i) => {
          return [s, directions[i]].join(",");
        })
        .join("\r\n")
    );
  }

  return (
    `#height,${windTimeseries.height}\n#lon,${windTimeseries.longitude}\n#lat,${windTimeseries.latitude}\n#alpha,${alpha}\n` +
    "timestamp,speed,direction\r\n" +
    speeds
      .map((s, i) => {
        return [windTimeseries.timestamps![i], s, directions[i]].join(",");
      })
      .join("\r\n")
  );
};

const createWindRoseCSV = (windRose: SimpleWindRose) => {
  const probabilities = windRose.speedProbabilities;

  return (
    `speed,probability\r\n` +
    windRose.speeds
      .map((s, i) => [s.toFixed(2), probabilities[i].toFixed(5)])
      .join("\r\n")
  );
};

export const DownloadWindDataButton = ({
  windData,
  windRose,
}: {
  windData?: WindTimeseries;
  windRose: SimpleWindRose;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const onWindDataClick = useCallback(() => {
    if (!windData) return;
    downloadText(
      createWindDataCSV(windData),
      `${windData.source.name}_${windData.height}m_lon_${windData.longitude?.toFixed(2)}_lat_${windData.latitude?.toFixed(2)}.csv`,
    );
    Mixpanel.track_old("Downloaded wind data", {
      projectId,
    });
  }, [windData, projectId]);

  const onWindRoseClick = useCallback(() => {
    const lon = windRose.longitude?.toFixed(2);
    const lat = windRose.latitude?.toFixed(2);
    const filename = `Windrose_${windRose.height}m_lon_${lon}_lat_${lat}_speed_distribution.csv`;
    downloadText(createWindRoseCSV(windRose), filename);
    Mixpanel.track_old("Downloaded wind distribution", {
      projectId,
    });
  }, [projectId, windRose]);

  const textDownload = windData ? "Download" : "Loading...";

  return (
    <SplitButton
      buttonType="secondary"
      text={textDownload}
      style={{
        alignSelf: "flex-end",
      }}
      disabled={!windData}
      onClick={onWindDataClick}
    >
      <MenuItem
        defaultButton
        name={"Wind data"}
        style={{
          alignSelf: "flex-end",
        }}
        disabled={!windData}
        onClick={onWindDataClick}
      />
      <MenuItem
        name={"Wind distribution"}
        onClick={onWindRoseClick}
        disabled={!windRose}
      />
    </SplitButton>
  );
};

export const DownloadAnalysisWindDataButton = ({
  projectId,
  analysisId,
  analysisVersion,
}: {
  projectId: string;
  analysisId: string;
  analysisVersion: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onClick = () => {
    setLoading(true);
    fetchAnalysisWindData({
      nodeId: projectId,
      id: analysisId,
      version: analysisVersion,
    })
      .then((data: PresignedUrlResponse) => {
        const url = data.url;
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => {
        setLoading(false); // Re-enable the button
      });
  };

  const text = loading ? "Downloading.." : "Download wind data";

  return (
    <Tooltip text={text}>
      <Button
        buttonType="secondary"
        size="small"
        onClick={loading ? undefined : onClick}
        icon={<DownloadIcon />}
      />
    </Tooltip>
  );
};
