import { radiusAtZoom10, radiusAtZoom5 } from "./utils";
import { typePropertyName } from "../../constants/canvas";
import { Expression } from "mapbox-gl";

export const defaultPointCircleRadius: Expression = [
  "interpolate",
  ["exponential", 2],
  ["zoom"],
  5,
  ["case", ["has", typePropertyName], radiusAtZoom5, 5],
  10,
  ["case", ["has", typePropertyName], radiusAtZoom10, 5],
  15,
  ["case", ["has", typePropertyName], 7, 5],
];

export const smallMapCircleRadius: Expression = [
  "interpolate",
  ["exponential", 2],
  ["zoom"],
  5,
  2,
  10,
  4,
];
