import React, { useState } from "react";
import { useAtomValue } from "jotai";
import ArchiveIcon from "@icons/24/Archive.svg";
import BranchIcon from "@icons/24/Branch.svg";
import { spacing4, spacing7, spacing8 } from "styles/space";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { WithTooltip } from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { isNever } from "utils/utils";
import { allBranchesFrameLoadingAtom } from "../state";
import { BranchesTabContent } from "./BranchesTabContent";
import { ArchivedBranchesTabContentOuter } from "./ArchivedBranchesTabContent";
import IconFlipSwitch from "components/General/IconFlipSwitch";

const frameStyle: React.CSSProperties = {
  position: "fixed",
  top: `calc(calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) + ${spacing4})`,
  left: spacing4,
  overflow: "hidden",
  maxHeight: "80vh",
  minWidth: "30rem",
  maxWidth: "60rem",
  width: "var(--all-branches-frame-width, 30rem)",
  resize: "horizontal",
};

enum ContentType {
  BRANCHES = "BRANCHES",
  ARCHIVE = "ARCHIVE",
}

const ContentSwitch = ({
  activeContent,
  setActiveContent,
}: {
  activeContent: ContentType;
  setActiveContent: (content: ContentType) => void;
}) => {
  return (
    <IconFlipSwitch>
      <WithTooltip text="All branches">
        <IconBtn
          data-active={activeContent === ContentType.BRANCHES}
          size="1.2rem"
          active={activeContent === ContentType.BRANCHES}
          onClick={() => setActiveContent(ContentType.BRANCHES)}
        >
          <BranchIcon />
        </IconBtn>
      </WithTooltip>
      <WithTooltip text="Archived branches">
        <IconBtn
          data-active={activeContent === ContentType.ARCHIVE}
          size="1.2rem"
          active={activeContent === ContentType.ARCHIVE}
          onClick={() => setActiveContent(ContentType.ARCHIVE)}
        >
          <ArchiveIcon />
        </IconBtn>
      </WithTooltip>
    </IconFlipSwitch>
  );
};

const getTitle = (contentType: ContentType) => {
  switch (contentType) {
    case ContentType.BRANCHES:
      return "All branches";
    case ContentType.ARCHIVE:
      return "Archived branches";
    default:
      throw isNever(contentType);
  }
};

const AllBranchesAndArchivedBranchesFrame = ({
  onExit,
}: {
  onExit(): void;
}) => {
  const [activeContent, setActiveContent] = useState<ContentType>(
    ContentType.BRANCHES,
  );
  const elementRef = React.useRef<HTMLDivElement>(null);
  useHorizontalResize(elementRef, "--all-branches-frame-width");
  const isLoading = useAtomValue(allBranchesFrameLoadingAtom);

  return (
    <MenuFrame
      ref={elementRef}
      title={getTitle(activeContent)}
      onExit={onExit}
      isLoading={isLoading}
      style={frameStyle}
      settingsMenu={
        <ContentSwitch
          activeContent={activeContent}
          setActiveContent={setActiveContent}
        />
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: `0 ${spacing8}`,
          gap: spacing7,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <React.Suspense
          fallback={
            <SkeletonBlock
              style={{
                height: "3rem",
              }}
            />
          }
        >
          {activeContent === ContentType.BRANCHES ? (
            <BranchesTabContent />
          ) : activeContent === ContentType.ARCHIVE ? (
            <ArchivedBranchesTabContentOuter />
          ) : null}
        </React.Suspense>
      </div>
    </MenuFrame>
  );
};

export default AllBranchesAndArchivedBranchesFrame;
