import { useMemo } from "react";
import DropdownButton, { ButtonText } from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import StatisticsIcon from "@icons/24/Statistics.svg?react";
import { AnalysisConfiguration } from "../../services/configurationService";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { useAtomValue } from "jotai";
import {
  libraryAnalysisConfigurationsFamily,
  projectAnalysisConfigurationsFamily,
} from "state/jotai/analysisConfiguration";
import { projectIdAtomDef } from "state/pathParams";

export const ConfigurationSelector = ({
  configuration,
  setConfiguration,
}: {
  configuration?: AnalysisConfiguration;
  setConfiguration: (cfg: AnalysisConfiguration) => void;
}) => {
  const projectId = useAtomValue(projectIdAtomDef);
  const projectConfigs = useAtomValue(
    projectAnalysisConfigurationsFamily({ projectId }),
  );
  const libraryConfigs = useAtomValue(
    libraryAnalysisConfigurationsFamily({ projectId: undefined }),
  );
  const allConfigs = new Map([...projectConfigs, ...libraryConfigs]);

  const items = useMemo(() => {
    const items: DropDownItem[] = [];
    for (const cfg of libraryConfigs.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
        icon: (
          <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
            <LibraryIcon />
          </IconREMSize>
        ),
      });
    for (const cfg of projectConfigs.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
        icon: (
          <IconREMSize fill={colors.textPrimary} width={1.4} height={1.4}>
            <ProjectIcon />
          </IconREMSize>
        ),
      });
    return items;
  }, [projectConfigs, libraryConfigs]);

  const selected = items.find((i) => i.value === configuration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = allConfigs.get(e);
        if (!cfg) throw new Error("Configuration not found");
        setConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <>
          <StatisticsIcon />
          <ButtonText>{e}</ButtonText>
        </>
      )}
    />
  );
};
