import { atom } from "jotai";
import { featuresFamily } from "state/jotai/features";
import { BathymetryFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { hiddenFeatureIdsFamily } from "./hidden";
import { branchIdAtom } from "state/pathParams";

export const bathymetryFeatureFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<BathymetryFeature[]>>(async (get) => {
      const currentBranchId = get(branchIdAtom);
      if (!currentBranchId) return [];
      const canvasLayerFeatureHidden = get(
        hiddenFeatureIdsFamily({ branchId: branchId ?? currentBranchId }),
      );
      return (await get(featuresFamily({ branchId }))).bathymetry.filter(
        (f) => !canvasLayerFeatureHidden.includes(f.id),
      );
    }),
);

export const canvasLayerBathymetryFilenamesAtom = atom(async (get) => {
  const bathymetryFeatures = await get(
    bathymetryFeatureFamily({ branchId: undefined }),
  );
  return bathymetryFeatures.map((b) => b.properties.filename);
});

export const currentParkBathymetryIdAtom = atom<string | undefined>(undefined);
