import {
  AddSubStationMenuType,
  DrawExportCableMenuType,
} from "@constants/cabling";
import SubstationIcon from "@icons/24/Substation.svg?react";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { Column } from "components/General/Layout";
import { SkeletonText } from "components/Loading/Skeleton";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback } from "react";
import { keepExportModalOpenAtom } from "state/cable";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import { exportCableTypesByRatingFamily } from "state/jotai/exportCableType";
import {
  currentExportCableIdAtom,
  drawNumberOfDuplicateExportCablesState,
} from "state/jotai/exportCable";
import { substationsInParkFamily } from "state/jotai/substation";
import {
  allSubstationsForProjectAtom,
  currentSubstationIdAtomJ,
} from "state/jotai/substationType";
import { modalTypeOpenAtom } from "state/modal";
import {
  parkIdAtom,
  parkIdAtomDef2,
  projectIdAtom,
  projectIdAtomDef2,
} from "state/pathParams";
import { Input } from "components/General/Input";
import { isOnshoreAtom } from "state/onshore";

const AddExportCable = () => {
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();
  const [, setKeepExportModalOpen] = useAtom(keepExportModalOpenAtom);
  const onshore = useAtomValue(isOnshoreAtom);

  if (layoutControlActive !== DrawExportCableMenuType) return null;

  return (
    <MenuFrame
      id={DrawExportCableMenuType}
      title="Export cable"
      onExit={() => {
        setLayoutControlActive(undefined), setKeepExportModalOpen(false);
      }}
    >
      <Column>
        <React.Suspense fallback={<SkeletonText />}>
          {onshore ? <ExportCableControlOnshore /> : <ExportCableControl />}
        </React.Suspense>
      </Column>
    </MenuFrame>
  );
};

const NewExportCableValue = "addNewExportCableConfiguration";

const ExportCableControlOnshore = () => {
  const [_, setLeftMenuActiveMode] = useDrawMode();
  const projectId = useAtomValue(projectIdAtomDef2);
  const parkId = useAtomValue(parkIdAtomDef2);
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const [
    drawNumberOfDuplicateExportCables,
    setDrawNumberOfDuplicateExportCables,
  ] = useAtom(drawNumberOfDuplicateExportCablesState);

  const [currentExportCableId, setCurrentExportCableId] = useAtom(
    currentExportCableIdAtom,
  );
  const [, setKeepExportModalOpen] = useAtom(keepExportModalOpenAtom);
  const exportCableTypes = useAtomValue(
    exportCableTypesByRatingFamily({
      projectId: undefined,
    }),
  );
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const setSelectedItemId = useSetAtom(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );

  const openNewExportCableConfig = useCallback(() => {
    setSelectedItemId(EXPORT_CABLE_MENU_ID);
    setModalType({
      modalType: FeatureSettingsModalTypeV2,
    });
  }, [setSelectedItemId, setModalType]);

  if (!currentExportCableId || exportCableTypes.length === 0)
    return (
      <SimpleAlert
        text={
          "There are no export cable types in this project. Open feature settings to add export cable types."
        }
        type={"error"}
      ></SimpleAlert>
    );

  if (substations.length < 2) {
    return (
      <Column>
        <p>You need to place at least two substations to draw export cables.</p>
        <Button
          style={{
            alignSelf: "end",
          }}
          icon={<SubstationIcon />}
          text={"Place substations"}
          buttonType={"primary"}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );
  }

  return (
    <>
      <p>Draw export cable by clicking in the map.</p>
      <SubtitleWithLine text="Draw settings" />
      <Label>
        <InputTitle>Number of cables in parallel</InputTitle>
        <Input
          type="number"
          value={drawNumberOfDuplicateExportCables}
          onChange={(e) => {
            setDrawNumberOfDuplicateExportCables(Number(e.target.value));
          }}
          min={1}
          max={5}
        />
      </Label>
      <SubtitleWithLine text="Type" />
      <Label left>
        <InputTitle>Type</InputTitle>
        <Dropdown
          style={{ width: "100%" }}
          small
          id="onshore_export_cable"
          value={currentExportCableId.onshore}
          onChange={(e) => {
            setCurrentExportCableId({
              ...currentExportCableId,
              // NOTE: this is not a typo! The onshore part of the export cable is optional, so we need to use the offshore type as the "main" type.
              offshore: e.target.value,
            });
            if (e.target.value === NewExportCableValue) {
              openNewExportCableConfig();
              return;
            }
          }}
        >
          {exportCableTypes
            .filter((c) =>
              c.useAdvancedSettings && c.exportCableUsage
                ? c.exportCableUsage.onshore
                : true,
            )
            .map((c) => (
              <option key={c.id} value={c.id}>
                {`${c.name}`}
              </option>
            ))}
          <option
            value={NewExportCableValue}
          >{`+ Add export cable type`}</option>
        </Dropdown>
      </Label>
    </>
  );
};

const ExportCableControl = () => {
  const [_, setLeftMenuActiveMode] = useDrawMode();
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const substations = useAtomValue(
    substationsInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const substationTypes = useAtomValue(allSubstationsForProjectAtom);
  const setSelectedSubstationId = useSetAtom(currentSubstationIdAtomJ);
  const [
    drawNumberOfDuplicateExportCables,
    setDrawNumberOfDuplicateExportCables,
  ] = useAtom(drawNumberOfDuplicateExportCablesState);
  const offshoreSubstations = substations.filter(
    (s) =>
      substationTypes.get(s.properties.substationTypeId ?? "")?.type ===
      "offshore",
  );
  const onshoreSubstations = substations.filter(
    (s) =>
      substationTypes.get(s.properties.substationTypeId ?? "")?.type ===
      "onshore",
  );
  const [currentExportCableId, setCurrentExportCableId] = useAtom(
    currentExportCableIdAtom,
  );
  const [, setKeepExportModalOpen] = useAtom(keepExportModalOpenAtom);
  const exportCableTypes = useAtomValue(
    exportCableTypesByRatingFamily({
      projectId: undefined,
    }),
  );
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const setSelectedItemId = useSetAtom(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId,
    }),
  );

  const openNewExportCableConfig = useCallback(() => {
    setSelectedItemId(EXPORT_CABLE_MENU_ID);
    setModalType({
      modalType: FeatureSettingsModalTypeV2,
    });
  }, [setSelectedItemId, setModalType]);

  if (!currentExportCableId || exportCableTypes.length === 0)
    return (
      <SimpleAlert
        text={
          "There are no export cable types in this project. Open feature settings to add export cable types."
        }
        type={"error"}
      ></SimpleAlert>
    );

  if (onshoreSubstations.length === 0 && offshoreSubstations.length > 0)
    return (
      <Column>
        <p>You need an onshore type substation to draw export cables.</p>
        <Button
          style={{
            alignSelf: "end",
          }}
          icon={<SubstationIcon />}
          text={"Place substation"}
          buttonType={"primary"}
          onClick={() => {
            const defaultOnshore = Array.from(substationTypes.values()).find(
              (s) => s.type === "onshore",
            );
            if (defaultOnshore) setSelectedSubstationId(defaultOnshore.id);
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  if (onshoreSubstations.length > 0 && offshoreSubstations.length === 0)
    return (
      <Column>
        <p>You need an offshore type substation to draw export cables.</p>
        <Button
          style={{
            alignSelf: "end",
          }}
          icon={<SubstationIcon />}
          text={"Place substation"}
          buttonType={"primary"}
          onClick={() => {
            const defaultOffshore = Array.from(substationTypes.values()).find(
              (s) => s.type === "offshore",
            );
            if (defaultOffshore) setSelectedSubstationId(defaultOffshore.id);
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  if (onshoreSubstations.length === 0 && offshoreSubstations.length === 0)
    return (
      <Column>
        <p>
          You need both an offshore type and an onshore type substation to draw
          export cables.
        </p>
        <Button
          style={{
            alignSelf: "end",
          }}
          icon={<SubstationIcon />}
          text={"Place substations"}
          buttonType={"primary"}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(AddSubStationMenuType));
            setKeepExportModalOpen(true);
          }}
        />
      </Column>
    );

  return (
    <>
      <p>Draw export cable by clicking in the map.</p>
      <SubtitleWithLine text="Draw settings" />
      <Label>
        <InputTitle>Number of cables in parallel</InputTitle>
        <Input
          type="number"
          value={drawNumberOfDuplicateExportCables}
          onChange={(e) => {
            setDrawNumberOfDuplicateExportCables(Number(e.target.value));
          }}
          min={1}
          max={5}
        />
      </Label>
      <SubtitleWithLine text="Type" />
      <Label left>
        <InputTitle>Offshore</InputTitle>
        <Dropdown
          style={{ width: "100%" }}
          small
          id="offshore_export_cable"
          value={currentExportCableId.offshore}
          onChange={(e) => {
            setCurrentExportCableId({
              ...currentExportCableId,
              offshore: e.target.value,
            });
            if (e.target.value === NewExportCableValue) {
              openNewExportCableConfig();
              return;
            }
          }}
        >
          {exportCableTypes
            .filter((c) =>
              c.useAdvancedSettings && c.exportCableUsage
                ? c.exportCableUsage.offshore
                : true,
            )
            .map((c) => (
              <option key={c.id} value={c.id}>
                {`${c.name}`}
              </option>
            ))}
          <option
            value={NewExportCableValue}
          >{`+ Add export cable type`}</option>
        </Dropdown>
      </Label>
      <Label left>
        <InputTitle>Onshore</InputTitle>
        <Dropdown
          small
          style={{ width: "100%" }}
          id="onshore_export_cable"
          value={currentExportCableId.onshore}
          onChange={(e) => {
            setCurrentExportCableId({
              ...currentExportCableId,
              onshore: e.target.value,
            });
            if (e.target.value === NewExportCableValue) {
              openNewExportCableConfig();
              return;
            }
          }}
        >
          {exportCableTypes
            .filter((c) =>
              c.useAdvancedSettings && c.exportCableUsage
                ? c.exportCableUsage.onshore
                : true,
            )
            .map((c) => (
              <option key={c.id} value={c.id}>
                {`${c.name}`}
              </option>
            ))}
          <option
            value={NewExportCableValue}
          >{`+ Add export cable type`}</option>
        </Dropdown>
      </Label>
    </>
  );
};

export default AddExportCable;
