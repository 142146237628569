import { useMemo } from "react";
import { CostUnit } from "types/financial";
import { roundToDecimal } from "utils/utils";
import { MILLION } from "@constants/financialAnalysis";
import { FinanceId } from "finance/types";
import {
  getCapexContingency,
  getDiscountRate,
  getInflationRate,
  getOpexContingency,
  getParkLifeTime,
} from "finance/outputs";
import { loadable } from "jotai/utils";
import { atom, useAtomValue } from "jotai";
import { getAEP } from "analysis/output";
import { LCoE } from "finance/outputs";
import { atomFamily } from "utils/jotai";

export const useLcoe = (id: FinanceId) => {
  const aep = useAtomValue(loadable(getAEP(id)));

  const discountRate = useAtomValue(loadable(getDiscountRate(id)));
  const capexContingency = useAtomValue(loadable(getCapexContingency(id)));
  const opexContingency = useAtomValue(loadable(getOpexContingency(id)));
  const inflationRate = useAtomValue(loadable(getInflationRate(id)));
  const lifeTime = useAtomValue(loadable(getParkLifeTime(id)));

  const devex = useAtomValue(loadable(LCoE.getNetPresentValueDevexMillion(id)));
  const devexCashFlow = useAtomValue(
    loadable(LCoE.getPresentValueCashflowDEVEX(id)),
  );

  const capex = useAtomValue(loadable(LCoE.getNetPresentValueCapexMillion(id)));
  const capexCashFlow = useAtomValue(
    loadable(LCoE.getPresentValueCashflowCAPEX(id)),
  );

  const opex = useAtomValue(loadable(LCoE.getNetPresentValueOpexMillion(id)));
  const opexCashFlow = useAtomValue(
    loadable(LCoE.getPresentValueCashflowOPEX(id)),
  );
  const opexDistribution = useAtomValue(loadable(LCoE.getOpexDistribution(id)));

  const decom = useAtomValue(loadable(LCoE.getNetPresentValueDecomMillion(id)));
  const decomCashFlow = useAtomValue(
    loadable(LCoE.getPresentValueCashflowDECOM(id)),
  );

  const cashFlow = useAtomValue(loadable(lcoeCashFlow(id)));
  const lcoe = useAtomValue(loadable(LCoE.getLCoE(id)));

  return useMemo(
    () => ({
      inputs: {
        netAEP: aep,
        discountRate,
        inflationRate,
        capexContingency,
        opexContingency,
        lifeTime,
      },
      results: {
        devex: {
          npv: devex,
          cashFlow: devexCashFlow,
        },
        capex: {
          npv: capex,
          cashFlow: capexCashFlow,
        },
        opex: {
          npv: opex,
          cashFlow: opexCashFlow,
          distribution: opexDistribution,
        },
        decom: {
          npv: decom,
          cashFlow: decomCashFlow,
        },
        cashFlow,
        lcoe,
      },
    }),
    [
      aep,
      discountRate,
      inflationRate,
      capexContingency,
      opexContingency,
      lifeTime,
      devex,
      devexCashFlow,
      capex,
      capexCashFlow,
      opex,
      opexCashFlow,
      opexDistribution,
      decom,
      decomCashFlow,
      cashFlow,
      lcoe,
    ],
  );
};

const lcoeCashFlow = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    const devex = await get(LCoE.getPresentValueCashflowDEVEX(id));
    const capex = await get(LCoE.getPresentValueCashflowCAPEX(id));
    const opex = await get(LCoE.getPresentValueCashflowOPEX(id));
    const decom = await get(LCoE.getPresentValueCashflowDECOM(id));

    return [
      ...devex.map(({ year, value }) => ({
        year,
        value: roundToDecimal(value / MILLION, 2),
        type: "DEVEX",
        unit: CostUnit.millionEuro,
      })),
      ...capex.map(({ year, value }) => ({
        year,
        value: roundToDecimal(value / MILLION, 2),
        type: "CAPEX",
        unit: CostUnit.millionEuro,
      })),
      ...opex.map(({ year, value }) => ({
        year,
        value: roundToDecimal(value / MILLION, 2),
        type: "OPEX",
        unit: CostUnit.millionEuro,
      })),
      ...decom.map(({ year, value }) => ({
        year,
        value: roundToDecimal(value / MILLION, 2),
        type: "DECOM",
        unit: CostUnit.millionEuro,
      })),
    ];
  }),
);
