import { z } from "zod";
import { sendWarning } from "utils/sentry";
import {
  fetchEnhancer,
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "./utils";

const _GenerateUrlResponse = z.object({
  presignedUrl: z.string(),
  fileName: z.string(),
  fields: z.object({
    AWSAccessKeyId: z.string(),
    "Content-Type": z.string(),
    "Content-Encoding": z.string(),
    key: z.string(),
    policy: z.string(),
    signature: z.string(),
    "x-amz-meta-original-filename": z.string(),
    "x-amz-security-token": z.string().optional(),
  }),
});

export const _CustomLayerMetadata = z.object({
  id: z.string(),
  url: z.string(),
  name: z.string(),
  path: z.string(),
  bbox: z.string().nullish(),
  author: z.string().nullish(),
  created_at: z.number().nullish(),
  description: z.string().nullable().optional(),
});
export type CustomLayerMetadata = z.infer<typeof _CustomLayerMetadata>;

const _CustomLayerAddResponse = z.object({
  status: z.string(),
  fileId: z.string(),
  output_file_id: z.string(),
  bbox: z.number().array(),
});
export type CustomLayerAddResponse = z.infer<typeof _CustomLayerAddResponse>;

export const uploadCustomLayer = async (
  nodeId: string,
  file: File,
): Promise<CustomLayerAddResponse> => {
  try {
    const { presignedUrl, fileName, fields } = await fetchSchemaWithToken(
      _GenerateUrlResponse,
      `/api/upload-custom-layer/generate-url/v2/${nodeId}`,
      {
        method: "POST",
        body: JSON.stringify({
          fileName: file.name,
        }),
      },
    );

    const formData = new FormData();
    Object.entries(fields).forEach(([key, val]) => {
      formData.append(key, val);
    });
    formData.append("file", file);

    await fetchEnhancer(`${presignedUrl}`, {
      method: "POST",
      body: formData,
    });

    return await processUploadedFile(nodeId, fileName);
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

const processUploadedFile = async (
  nodeId: string,
  fileName: string,
): Promise<CustomLayerAddResponse> => {
  try {
    const res = await fetchEnhancerWithToken(
      `/api/upload-custom-layer/process-uploaded-file/${nodeId}/${fileName}`,
      {
        method: "GET",
      },
    );
    const resJson = await res.json();
    return _CustomLayerAddResponse.parse(resJson);
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

const generateOrganisationPresignedUrlAndUploadFile = async (
  organisationId: string,
  file: File,
) => {
  const { presignedUrl, fileName, fields } = await fetchSchemaWithToken(
    _GenerateUrlResponse,
    `/api/upload-custom-layer/generate-url/organisation/${organisationId}`,
    {
      method: "POST",
      body: JSON.stringify({
        fileName: file.name,
      }),
    },
  );

  const formData = new FormData();
  Object.entries(fields).forEach(([key, val]) => {
    formData.append(key, val);
  });
  formData.append("file", file);

  await fetchEnhancer(`${presignedUrl}`, {
    method: "POST",
    body: formData,
  });

  return {
    presignedUrl,
    fileName,
    fields,
  };
};

export const uploadCustomOrganisationLayer = async (
  organisationId: string,
  file: File,
): Promise<CustomLayerAddResponse> => {
  try {
    const { fileName } = await generateOrganisationPresignedUrlAndUploadFile(
      organisationId,
      file,
    );

    return await processUploadedOrganisationFile(organisationId, fileName);
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

const processUploadedOrganisationFile = async (
  organisationId: string,
  fileName: string,
): Promise<CustomLayerAddResponse> => {
  const res = await fetchEnhancerWithToken(
    `/api/upload-custom-layer/process-uploaded-file/organisation/${organisationId}/${fileName}`,
    {
      method: "GET",
    },
  );
  const resJson = await res.json();
  return _CustomLayerAddResponse.parse(resJson);
};

export const replaceCustomOrganisationLayer = async (
  organisationId: string,
  file: File,
  fileIdToReplace: string,
): Promise<CustomLayerAddResponse> => {
  try {
    const { fileName: srcFileId } =
      await generateOrganisationPresignedUrlAndUploadFile(organisationId, file);

    return await processAndReplaceUploadedOrganisationFile(
      organisationId,
      srcFileId,
      fileIdToReplace,
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

const processAndReplaceUploadedOrganisationFile = async (
  organisationId: string,
  srcFileId: string,
  fileIdToReplace: string,
): Promise<CustomLayerAddResponse> => {
  const res = await fetchEnhancerWithToken(
    `/api/upload-custom-layer/process-and-replace-uploaded-file/organisation/${organisationId}/${srcFileId}/${fileIdToReplace}`,
    {
      method: "GET",
    },
  );
  const resJson = await res.json();
  return _CustomLayerAddResponse.parse(resJson);
};

export const getCustomLayerMetadata = async (
  nodeId: string,
): Promise<CustomLayerMetadata[]> => {
  try {
    return await fetchSchemaWithToken(
      _CustomLayerMetadata.array(),
      `/api/upload-custom-layer/custom-external-layers/${nodeId}`,
      {
        method: "GET",
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const deleteCustomLayer = async (
  nodeId: string,
  fileId: string,
): Promise<void> => {
  try {
    await fetchEnhancerWithToken(
      `/api/upload-custom-layer/delete-custom-layer/${nodeId}/${fileId}`,
      {
        method: "DELETE",
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const deleteCustomLayers = async (
  nodeId: string,
  fileIds: string[],
): Promise<void> => {
  try {
    await fetchEnhancerWithToken(
      `/api/upload-custom-layer/delete-custom-layers/${nodeId}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          fileIds,
        }),
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const downloadCustomLayerAsBlob = async (
  presignedPath: string,
): Promise<Blob | undefined> => {
  try {
    const res = await fetchEnhancer(presignedPath, {
      method: "GET",
    });
    return res.blob();
  } catch (err) {
    sendWarning("Could not download custom layer", {
      presignedPath,
      err,
    });
  }
};

export const editCustomLayerName = async (
  nodeId: string,
  fileId: string,
  newName: string,
): Promise<void> => {
  try {
    await fetchEnhancerWithToken(
      `/api/upload-custom-layer/edit-layer-name/${nodeId}/${fileId}`,
      {
        method: "POST",
        body: JSON.stringify({
          newName,
        }),
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const editCustomOrganisationLayerMetadata = async (
  organisationId: string,
  fileId: string,
  newData: {
    name?: string;
    description?: string;
  },
): Promise<void> => {
  try {
    await fetchEnhancerWithToken(
      `/api/upload-custom-layer/organisation/${organisationId}/${fileId}`,
      {
        method: "PUT",
        body: JSON.stringify(newData),
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const deleteCustomOrganisationLayers = async (
  organisationId: string,
  fileIds: string[],
): Promise<void> => {
  try {
    await fetchEnhancerWithToken(
      `/api/upload-custom-layer/delete-custom-layers/organisation/${organisationId}`,
      {
        method: "DELETE",
        body: JSON.stringify({ fileIds }),
      },
    );
  } catch (err) {
    console.log({ err });
    throw err;
  }
};
