import { atomFamily, atomFromFn } from "utils/jotai";
import { getFeatureMeta } from "services/projectDataAPIService";
import { FeatureMeta } from "services/projectDataAPIService";

export const featureMetaFamily = atomFamily(
  ({
    nodeId,
    branchId,
    featureId,
  }: {
    nodeId: string;
    branchId: string;
    featureId: string;
  }) =>
    atomFromFn<Promise<FeatureMeta>>(async () => {
      const response = await getFeatureMeta(nodeId, branchId, featureId);
      return response;
    }),
);
