import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectOverviewModalType } from "../state/configuration";
import {
  DashboardModalType,
  Modal,
  ModalType,
  modalTypeOpenAtom,
} from "../state/modal";
import { CompareParksModalType } from "../components/CompareParksModal/CompareParksModalType";
import { sendInfo } from "../utils/sentry";
import { useSetAtom } from "jotai";

const openableModals: ModalType[] = [
  { modalType: ProjectOverviewModalType },
  { modalType: CompareParksModalType },
  { modalType: DashboardModalType },
];

const searchParamModalName = "modal" as const;

export function getShareUrl(
  openModal: ModalType,
  metadata?: Record<string, any>,
) {
  if (!openableModals.find((m) => m.modalType === openModal.modalType))
    throw sendInfo("Invalid modal type", openModal);

  const url = new URL(window.location.href);
  url.searchParams.set(searchParamModalName, openModal.modalType);
  const meta =
    metadata || ("metadata" in openModal ? openModal.metadata : undefined);
  if (meta) {
    for (const [key, value] of Object.entries(meta)) {
      url.searchParams.set(key, value);
    }
  }
  return url.toString();
}

export default function useOpenModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  useEffect(() => {
    const paramValue = searchParams.get(searchParamModalName);
    if (!paramValue) return;
    const modal = openableModals.find(
      (modal) => modal.modalType === paramValue,
    );
    if (!modal) return;

    if (paramValue === DashboardModalType) {
      const metadata: Modal<typeof DashboardModalType>["metadata"] = {};
      metadata["id"] = searchParams.get("id") ?? undefined;
      metadata["branchId"] = searchParams.get("branchId") ?? undefined;
      metadata["parkId"] = searchParams.get("parkId") ?? undefined;
      metadata["configurationId"] =
        searchParams.get("configurationId") ?? undefined;
      metadata["windConfigurationId"] =
        searchParams.get("windConfigurationId") ?? undefined;
      setModalTypeOpen({ modalType: DashboardModalType, metadata });
    } else {
      setModalTypeOpen(modal);
    }

    searchParams.delete(searchParamModalName);
    setSearchParams(searchParams, {
      replace: true,
    });
  }, [searchParams, setModalTypeOpen, setSearchParams]);
}
