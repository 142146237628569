import { useSetAtom } from "jotai";
import React, { useCallback } from "react";
import {
  MidScreenModalTypeType,
  midScreenModalTypeOpenAtom,
} from "state/modal";

const useOpenUploadModalOnDragFile = ({
  modalType,
}: {
  modalType: MidScreenModalTypeType;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const openUploadModal = useCallback(
    (e: React.DragEvent) => {
      if (e.dataTransfer.types.includes("Files")) {
        e.preventDefault();
        setMidScreenModalTypeOpen({
          modalType: modalType as any,
        });
      }
    },
    [setMidScreenModalTypeOpen, modalType],
  );

  return { openUploadModal };
};

export default useOpenUploadModalOnDragFile;
