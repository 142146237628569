import { useCallback, useState } from "react";
import { useAtomValue } from "jotai";
import { z } from "zod";
import {
  _ProcurementCost,
  ProcurementCost,
  ProcurementCostComponentType,
  updateProcurementCost,
  deleteProcurementCost,
} from "services/procurementCostService";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { organisationProcurementCostsAtom } from "./state";
import { useToast } from "hooks/useToast";
import { useRefreshNodeSpecificProcurementCosts } from "state/jotai/procurementCosts";

const updateProcurementCostItems = (
  currentItems: ProcurementCost[],
  updatedItems: ProcurementCost[],
): ProcurementCost[] => {
  const parsedUpdatedItems = z.array(_ProcurementCost).parse(updatedItems);

  // Update existing items
  const updatedCur = currentItems.map((c) => {
    const updatedItem = parsedUpdatedItems.find(
      (item) =>
        ("componentId" in item &&
          "componentId" in c &&
          item.componentId === c.componentId) ||
        ("id" in item && "id" in c && item.id === c.id),
    );
    return updatedItem ? updatedItem : c;
  });

  // Add new items
  const newItems = parsedUpdatedItems.filter(
    (item) =>
      !currentItems.some(
        (c) =>
          ("componentId" in item &&
            "componentId" in c &&
            item.componentId === c.componentId) ||
          ("id" in item && "id" in c && item.id === c.id),
      ),
  );

  return [...updatedCur, ...newItems];
};

const useProcurementCostCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { info: showInfo, success: showSuccess, error: showError } = useToast();
  const {
    refreshInterArrayCables,
    refreshExportCables,
    refreshTurbines,
    refreshFoundations,
  } = useRefreshNodeSpecificProcurementCosts();

  const refreshComponentType = useCallback(
    (componentType: ProcurementCostComponentType) => {
      switch (componentType) {
        case "turbine":
          refreshTurbines();
          break;
        case "interArrayCable":
          refreshInterArrayCables();
          break;
        case "exportCable":
          refreshExportCables();
          break;
        case "foundation":
          refreshFoundations();
          break;
      }
    },
    [
      refreshExportCables,
      refreshFoundations,
      refreshInterArrayCables,
      refreshTurbines,
    ],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      componentType: ProcurementCostComponentType,
      updatedProcurementCosts: ProcurementCost[],
    ) => {
      const fallback = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      setIsSaving(true);
      const hideToast = showInfo("Saving...");

      try {
        const res: ProcurementCost[] = await updateProcurementCost(
          organisationId,
          componentType,
          updatedProcurementCosts,
        );

        refreshComponentType(componentType);

        if (res) {
          hideToast();
          showSuccess("Procurement costs updated successfully", {
            showCountdown: false,
            timeout: 5000,
          });
          aset(
            get,
            set,
            organisationProcurementCostsAtom(organisationId),
            (cur) => updateProcurementCostItems(cur, res),
          );
        }
      } catch (error) {
        console.error("Error updating procurement costs:", error);
        showError("Failed to update procurement costs. Please try again.");
        aset(
          get,
          set,
          organisationProcurementCostsAtom(organisationId),
          () => fallback,
        );
      } finally {
        setIsSaving(false);
      }
    },
    [organisationId, showInfo, refreshComponentType, showSuccess, showError],
  );

  const remove = useJotaiCallback(
    async (
      get,
      set,
      procurementCostId: string,
      componentType: ProcurementCostComponentType,
    ) => {
      const fallback = await get(
        organisationProcurementCostsAtom(organisationId),
      );
      setIsSaving(true);

      try {
        const response = await deleteProcurementCost(
          organisationId,
          procurementCostId,
          componentType,
        );
        if (response.status === 204) {
          aset(
            get,
            set,
            organisationProcurementCostsAtom(organisationId),
            (cur) => cur.filter((cost) => cost.id !== procurementCostId),
          );
          refreshComponentType(componentType);
          return { success: true };
        } else {
          return { success: false, error: "Unexpected response from server" };
        }
      } catch (error) {
        console.error("Error deleting procurement cost:", error);
        aset(
          get,
          set,
          organisationProcurementCostsAtom(organisationId),
          () => fallback,
        );
        return { success: false, error: "Failed to delete procurement cost" };
      } finally {
        setIsSaving(false);
      }
    },
    [organisationId, refreshComponentType],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, procurementCosts: ProcurementCost[]) => {
      aset(get, set, organisationProcurementCostsAtom(organisationId), (cur) =>
        updateProcurementCostItems(cur, procurementCosts),
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, procurementCostId: string) => {
      aset(get, set, organisationProcurementCostsAtom(organisationId), (cur) =>
        cur.filter((cost) => cost.id !== procurementCostId),
      );
    },
    [organisationId],
  );

  return { update, remove, updateLocal, removeLocal, isSaving };
};

export default useProcurementCostCrud;
