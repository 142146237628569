import { SkeletonBlock } from "components/Loading/Skeleton";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { useMemo } from "react";
import { lunwrap } from "utils/jotai";
import { NotUsedInAnyProjectsYet, SingleNodeView } from "./shared";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import {
  LibraryManageRoleType,
  LibraryResourceType,
} from "components/Organisation/Library/types";

export const AllProjectsResourceAccess = ({
  organisationId,
  resourceId,
  libraryManageRole,
  resourceType,
}: {
  organisationId: string;
  resourceId: string;
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">;
  resourceType: LibraryResourceType;
}) => {
  const usageLoadable = useAtomValue(
    loadable(
      libraryResourceUsageAtomFamily({
        organisationId,
        libraryManageRole,
        resourceId,
      }),
    ),
  );
  const allNodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  const uniqueProjects = useMemo(() => {
    const value = lunwrap(usageLoadable);
    const uniqueProjectIds = value ? value.map((na) => na.projectId) : [];

    return allNodes.filter((n) => uniqueProjectIds.includes(n.id));
  }, [allNodes, usageLoadable]);

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  if (uniqueProjects.length === 0) {
    return <NotUsedInAnyProjectsYet />;
  }

  return uniqueProjects.map((project) => {
    return (
      <SingleNodeView
        key={project.id}
        nodeId={project.id}
        organisationId={organisationId}
        resourceId={resourceId}
        type={resourceType}
        canRemove={false}
      />
    );
  });
};
