import { getParkCenter } from "utils/parkUtils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getBranchId, getPark, getTurbines } from "analysis/inputs";
import { portPointsFamily } from "state/jotai/ports";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { isOperationsCost } from "services/costService";
import { getAvailableWaveSourcesSelectorFamily } from "state/waveStatistics";
import {
  MAX_DISTANCE_FROM_PORT_TO_PARK_KM,
  MAX_NUMBER_OF_TI_TURBINES,
} from "./constants";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { getDistanceFromPortToPark } from "./utils";

enum InstallationStoppedTypes {
  NoPortInBranch = "no_port_in_branch",
  ParkHasNoWaveData = "park_has_no_wave_data",
  PortIsTooFarFromPark = "port_is_too_far_from_park",
  InvalidVesselsInConfig = "invalid_vessels_in_config",
  TooManyTurbines = "too_many_turbines",
}

export const installationStoppedText: Record<InstallationStoppedTypes, string> =
  {
    [InstallationStoppedTypes.NoPortInBranch]: "No ports exist in branch.",
    [InstallationStoppedTypes.ParkHasNoWaveData]:
      "No wave data exists for the park area.",
    [InstallationStoppedTypes.PortIsTooFarFromPark]: `The closest port is too far from the park (>${MAX_DISTANCE_FROM_PORT_TO_PARK_KM} km).`,
    [InstallationStoppedTypes.TooManyTurbines]: `Too many turbines (>${MAX_NUMBER_OF_TI_TURBINES}) to run installation analysis.`,
    [InstallationStoppedTypes.InvalidVesselsInConfig]:
      "At least one component type uses installation costs from operations, but some vessels used in the configuration are invalid.",
  };

//check if financial analysis uses installation costs from operations config, in which case we should stop the analysis if the installation analysis is invalid (no ports exists, no wave data for park, invalid vessel id)
const getOperationsConfigIsUsed = atomFamily((id: FinanceId) =>
  atom<Promise<boolean>>(async (get) => {
    const costConfiguration = await get(getConfiguration(id));

    const { turbines, cables, mooring, foundations, substation, exportCable } =
      costConfiguration.capex.installation;

    return [
      turbines,
      cables,
      mooring,
      foundations,
      substation,
      exportCable,
    ].some(isOperationsCost);
  }),
);

const getNoPortsExistInBranch = atomFamily((id: FinanceId) =>
  atom<Promise<boolean | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));

    const ports = await get(portPointsFamily({ branchId }));

    return ports.length === 0;
  }),
);

const getParkHasNoWaveData = atomFamily((id: FinanceId) =>
  atom<Promise<boolean | undefined>>(async (get) => {
    const park = await get(getPark(id));
    const parkCenter = getParkCenter(park, []);
    const waveSources = await get(
      getAvailableWaveSourcesSelectorFamily({
        parkCenter,
      }),
    );

    return waveSources.length === 0;
  }),
);

const getPortIsTooFarFromPark = atomFamily((id: FinanceId) =>
  atom<Promise<boolean | undefined>>(async (get) => {
    const distance = await get(getDistanceFromPortToPark(id));

    if (!distance) return;

    return distance > MAX_DISTANCE_FROM_PORT_TO_PARK_KM;
  }),
);

const getTooManyTurbinesForInstallationAnalysis = atomFamily((id: FinanceId) =>
  atom<Promise<boolean | undefined>>(async (get) => {
    const turbines = await get(getTurbines(id));

    return turbines.length > MAX_NUMBER_OF_TI_TURBINES;
  }),
);

const getInvalidVesselsInConfig = atomFamily((id: FinanceId) =>
  atom<Promise<boolean>>(async (get) => {
    const costConfiguration = await get(getConfiguration(id));
    const operationsConfiguration = await get(getOperationsConfiguration(id));
    const vesselTypes = await get(vesselTypesFamily(undefined));

    const {
      turbines: costTurbines,
      cables: costCables,
      mooring: costMooring,
      foundations: costFoundations,
      substation: costSubstation,
      exportCable: costExportCable,
    } = costConfiguration.capex.installation;

    const { turbines, cables, mooring, foundations, substation, exportCable } =
      operationsConfiguration.ti;

    const usedVesselIds: string[] = [];

    if (isOperationsCost(costTurbines)) {
      usedVesselIds.push(turbines.installationVessel.vesselId);
    }
    if (isOperationsCost(costCables)) {
      usedVesselIds.push(cables.installationVessel.vesselId);
    }
    if (isOperationsCost(costMooring)) {
      usedVesselIds.push(mooring.installationVessel.vesselId);
    }
    if (isOperationsCost(costFoundations)) {
      usedVesselIds.push(foundations.monopiles.installationVessel.vesselId);
      usedVesselIds.push(foundations.jackets.installationVessel.vesselId);
      usedVesselIds.push(foundations.jackets.feederVessel.vesselId);
      usedVesselIds.push(foundations.floaters.installationVessel.vesselId);
      usedVesselIds.push(foundations.floaters.towingVessel.vesselId);
      usedVesselIds.push(
        foundations.scourProtection.installationVessel.vesselId,
      );
    }
    if (isOperationsCost(costSubstation)) {
      usedVesselIds.push(substation.installationVessel.vesselId);
      usedVesselIds.push(substation.feederVessel.vesselId);
    }
    if (isOperationsCost(costExportCable)) {
      usedVesselIds.push(exportCable.installationVessel.vesselId);
    }

    const invalidVesselIds = usedVesselIds.some((id) => !vesselTypes.has(id));

    return invalidVesselIds;
  }),
);

//we stop installation _cost_ calcs only if the operations config is used in the applied cost config (not if all installation costs are set to "override")
export const getInstallationCostStoppedReason = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationStoppedTypes | undefined>>(async (get) => {
    const operationsConfigIsUsed = await get(getOperationsConfigIsUsed(id));
    if (!operationsConfigIsUsed) return undefined;
    const stoppedReason = await get(getInstallationTimeStoppedReason(id));
    return stoppedReason;
  }),
);

//we stop installation _time_ calcs no matter what the cost config uses as installation cost
export const getInstallationTimeStoppedReason = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationStoppedTypes | undefined>>(async (get) => {
    if (await get(getNoPortsExistInBranch(id)))
      return InstallationStoppedTypes.NoPortInBranch;
    if (await get(getParkHasNoWaveData(id)))
      return InstallationStoppedTypes.ParkHasNoWaveData;
    if (await get(getPortIsTooFarFromPark(id)))
      return InstallationStoppedTypes.PortIsTooFarFromPark;
    if (await get(getTooManyTurbinesForInstallationAnalysis(id)))
      return InstallationStoppedTypes.TooManyTurbines;
    if (await get(getInvalidVesselsInConfig(id)))
      return InstallationStoppedTypes.InvalidVesselsInConfig;

    return undefined;
  }),
);
