import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import { FeatureSettingsShortcut } from "components/ConfigurationModal/Cost/Capex/FeatureSettingsShortcut";
import { useClickOutside } from "hooks/useClickOutside";
import {
  libraryExportCableTypesFamily,
  projectExportCableTypesFamily,
} from "state/jotai/cableType";
import {
  ColoredGridPreviewItem,
  ColoredGridProjectItems,
  Divider,
  GridKey,
  GridValue,
  GridValueLeft,
  HeaderRow,
  Modal,
  TableHeader,
  TableSubHeader,
  Title,
  Wrapper,
} from "./style";
import { ExportCablePreviewItem, LibraryExportCablePreviewItem } from "./state";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { ComponentsPreviewType } from "./state";
import { listProcurementCostForLibraryExportCablesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { ComponentCost, CostType } from "services/costService";
import { CostUnit } from "types/financial";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { InputDimensioned } from "components/General/Input";
import { WithTooltip } from "components/General/Tooltip";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "components/ConfigurationModal/Cost/state";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import {
  exportCableIdentifierAtom,
  exportCableProcurementCostsAtom,
} from "components/Organisation/Library/financial/ProcurementCosts/state";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { GoToProcurementTableArrowIcon } from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";

const LibraryExportCabesTable = ({
  libraryExportCableItems,
}: {
  libraryExportCableItems: LibraryExportCablePreviewItem[];
}) => {
  return (
    <>
      <SubtitleWithLine textIcon={<LibraryIcon />} text={"Library cables"} />
      <ColoredGridPreviewItem>
        <>
          <TableHeader>Export cables</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader>Supply</TableHeader>
          <TableHeader>Shipping</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
        </>
        <>
          <TableSubHeader>NAME</TableSubHeader>
          <TableSubHeader>SYSTEM</TableSubHeader>
          <TableSubHeader>COST</TableSubHeader>
          <TableSubHeader>EUROPE</TableSubHeader>
          <TableSubHeader>ASIA</TableSubHeader>
          <TableSubHeader>USA</TableSubHeader>
          <TableSubHeader>OTHER</TableSubHeader>
        </>

        {libraryExportCableItems.map((cable) => {
          const getCostInRegion = (region: string) =>
            cable.shippingCost.find((t) => t.region === region)?.cost || 0;
          const getUnitInRegion = (region: string) =>
            cable.shippingCost.find((t) => t.region === region)?.unit ||
            CostUnit.millionEuroPerUnit;

          return (
            <React.Fragment key={cable.id}>
              <WithTooltip text={cable.name}>
                <GridKey>{cable.name}</GridKey>
              </WithTooltip>
              <GridValueLeft>{cable.exportCableType}</GridValueLeft>
              <GridValueLeft>
                <strong>{cable.supplyCost}</strong> {cable.supplyCostUnit}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Europe")}</strong>{" "}
                {getUnitInRegion("Europe")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Asia")}</strong>{" "}
                {getUnitInRegion("Asia")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("USA")}</strong>{" "}
                {getUnitInRegion("USA")}
              </GridValueLeft>
              <GridValueLeft>
                <strong>{getCostInRegion("Other")}</strong>{" "}
                {getUnitInRegion("Other")}
                <GoToProcurementTableArrowIcon activeProcurementType="exportCable" />
              </GridValueLeft>
            </React.Fragment>
          );
        })}
      </ColoredGridPreviewItem>
    </>
  );
};

const Project_ExportCableComponentsPreviewV2Inner = ({
  temporaryProjectExportCableCosts,
  setTemporaryProjectExportCableCosts,
}: {
  temporaryProjectExportCableCosts: Record<string, ComponentCost>;
  setTemporaryProjectExportCableCosts: React.Dispatch<
    React.SetStateAction<Record<string, ComponentCost>>
  >;
}) => {
  const projectId = useAtomValue(projectIdAtom)!;
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);

  const readOnly =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  const libraryExportCableCosts = useAtomValue(
    listProcurementCostForLibraryExportCablesOnNodeAtomFamily(projectId),
  );

  const projectExportCables = useAtomValue(
    projectExportCableTypesFamily(projectId ?? ""),
  );

  const projectExportCablesItems = useMemo<ExportCablePreviewItem[]>(() => {
    return Array.from(projectExportCables.values()).map((c) => {
      const thisCableCost = temporaryProjectExportCableCosts[c.id];
      return {
        id: c.id,
        name: `${c.name} (${c.voltage}kV)`,
        cost: thisCableCost?.cost ?? c.cost,
        costUnit: thisCableCost?.unit ?? c.costUnit,
        level: "project",
        exportCableType: c.exportCableType,
      };
    });
  }, [temporaryProjectExportCableCosts, projectExportCables]);

  const libraryExportCables = useAtomValue(
    libraryExportCableTypesFamily(projectId ?? ""),
  );

  const libraryExportCableItems = useMemo<LibraryExportCablePreviewItem[]>(
    () =>
      Array.from(libraryExportCables?.values() ?? []).map((c) => {
        const procurementCost = libraryExportCableCosts.get(c.id);
        return {
          id: c.id,
          name: `${c.name} (${c.voltage}kV)`,
          supplyCost: procurementCost?.supplyCost?.cost
            ? procurementCost.supplyCost.cost
            : DefaultCosts[CostType.ExportCable].exportCable[
                CostUnit.millionEuroPerKM
              ],
          supplyCostUnit:
            procurementCost?.supplyCost?.unit ?? CostUnit.millionEuroPerKM,
          shippingCost: procurementCost?.shippingCost ?? [],
          level: "library",
          exportCableType: c.exportCableType,
          costSource: procurementCost?.supplyCost ? "table" : "default",
        };
      }),
    [libraryExportCables, libraryExportCableCosts],
  );

  return (
    <>
      <LibraryExportCabesTable
        libraryExportCableItems={libraryExportCableItems}
      />

      <SubtitleWithLine textIcon={<ProjectIcon />} text={"Project cables"} />
      <ColoredGridProjectItems nrColumns={3}>
        <React.Fragment key="header">
          <TableSubHeader>NAME</TableSubHeader>
          <TableSubHeader>SYSTEM</TableSubHeader>
          <TableSubHeader>COST</TableSubHeader>
        </React.Fragment>
        {projectExportCablesItems.map((cable, index) => (
          <React.Fragment key={cable.id}>
            <GridKey showBg={index % 2 === 0}>{cable.name}</GridKey>
            <GridKey showBg={index % 2 === 0}>{cable.exportCableType}</GridKey>
            <GridValue showBg={index % 2 === 0}>
              <InputDimensioned
                disabled={readOnly}
                compact={true}
                unit={cable.costUnit}
                type="number"
                value={cable.cost}
                initialValue={cable.cost}
                onChange={(cost, _, unit) =>
                  setTemporaryProjectExportCableCosts((curr) => ({
                    ...curr,
                    [cable.id]: {
                      id: cable.id,
                      cost,
                      unit: unit as CostUnit,
                    },
                  }))
                }
              />
              <FeatureSettingsShortcut
                menuId={EXPORT_CABLE_MENU_ID}
                itemId={cable.id}
              />
            </GridValue>
          </React.Fragment>
        ))}
      </ColoredGridProjectItems>
    </>
  );
};

const Library_ExportCableComponentsPreviewV2Inner = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const libraryExportCableCosts = useAtomValue(
    exportCableProcurementCostsAtom(organisationId),
  );

  const libraryExportCables = useAtomValue(
    exportCableIdentifierAtom(organisationId),
  );

  const libraryExportCableItems = useMemo<LibraryExportCablePreviewItem[]>(
    () =>
      Array.from(libraryExportCables?.values() ?? []).map((c) => {
        const procurementCost = libraryExportCableCosts.get(c.id);
        return {
          id: c.id,
          name: `${c.name} (${c.voltage}kV)`,
          supplyCost: procurementCost?.supplyCost?.cost
            ? procurementCost.supplyCost.cost
            : DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM],
          supplyCostUnit:
            procurementCost?.supplyCost?.unit ?? CostUnit.millionEuroPerKM,
          shippingCost: procurementCost?.shippingCost ?? [],
          level: "library",
          exportCableType: c.exportCableType,
          costSource: procurementCost?.supplyCost ? "table" : "default",
        };
      }),
    [libraryExportCables, libraryExportCableCosts],
  );

  return (
    <LibraryExportCabesTable
      libraryExportCableItems={libraryExportCableItems}
    />
  );
};

const ExportCableComponentsPreviewV2Inner = ({
  onClose,
}: {
  onClose(): void;
}) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const { replaceProjectComponentCosts } = useLocalCostConfigurationCrud();
  const localConfig = useAtomValue(localCostConfigurationAtom);
  const [
    temporaryProjectExportCableCosts,
    setTemporaryProjectExportCableCosts,
  ] = useState<typeof localConfig.capex.projectComponentCosts.exportCable>(
    localConfig.capex.projectComponentCosts.exportCable,
  );

  const isInLibraryContext =
    midScreenModalTypeOpen?.modalType === ComponentsPreviewType &&
    midScreenModalTypeOpen.metadata?.isLibraryResource;

  return (
    <>
      <ScrollBody>
        {isInLibraryContext ? (
          <Library_ExportCableComponentsPreviewV2Inner />
        ) : (
          <Project_ExportCableComponentsPreviewV2Inner
            temporaryProjectExportCableCosts={temporaryProjectExportCableCosts}
            setTemporaryProjectExportCableCosts={(...args) => {
              setHasChanged(true);
              setTemporaryProjectExportCableCosts(...args);
            }}
          />
        )}
      </ScrollBody>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <Button buttonType="secondary" text="Close" onClick={onClose} />
        {!isInLibraryContext && (
          <Button
            buttonType="primary"
            text="Apply"
            disabled={!hasChanged}
            onClick={() => {
              replaceProjectComponentCosts(
                temporaryProjectExportCableCosts,
                "exportCable",
              );
              onClose();
            }}
          />
        )}
      </div>
    </>
  );
};

const ExportCableComponentsPreviewV2 = () => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <Wrapper>
      <Modal
        ref={containerRef}
        style={{ width: "100rem", maxWidth: "80%", overflow: "hidden" }}
      >
        <HeaderRow>
          <div>
            <Title>Export cable costs</Title>
          </div>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <React.Suspense
          fallback={<SkeletonBlock style={{ height: "20rem" }} />}
        >
          <ExportCableComponentsPreviewV2Inner onClose={onClose} />
        </React.Suspense>
      </Modal>
    </Wrapper>
  );
};

export default ExportCableComponentsPreviewV2;
