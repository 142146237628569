import React, { useMemo } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { useAtomValue } from "jotai";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { operationsConfigurationsFamily } from "state/jotai/operationsConfiguration";
import { colors } from "styles/colors";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  white-space: nowrap;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectOperationsConfigDropDown = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  disabled,
  className,
  backgroundColor,
}: {
  selectedConfig?: OperationsConfiguration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
  className?: string;
  backgroundColor?: string;
}) => {
  const configs = useAtomValue(operationsConfigurationsFamily({ projectId }));

  const configItems = useMemo<DropDownItem[]>(() => {
    return Array.from(configs.values()).map((c) => ({
      value: c.id,
      name: c.name ?? "Untitled",
      icon: (
        <IconREMSize width={1.4} height={1.4}>
          <ProjectIcon />
        </IconREMSize>
      ),
    }));
  }, [configs]);

  return (
    <DropdownButton
      className={className}
      items={configItems}
      backgroundColor={backgroundColor}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={style}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        Array.from(configs.values()).find((c) => c.id === selectedConfig?.id)
          ?.name ?? "Select configuration"
      }
      selectedItemValue={selectedConfig?.id}
      disabled={disabled}
    />
  );
};

export default SelectOperationsConfigDropDown;
