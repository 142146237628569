import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { featuresFamily } from "state/jotai/features";
import { getInvalidPolygonFeatures } from "utils/geojson/validate";

export const invalidFeaturesWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.InvalidFeatures> | undefined
      >
    >(async (get) => {
      const split = await get(featuresFamily({ branchId }));
      const featureIds = getInvalidPolygonFeatures(
        Object.values(split).flat(),
      ) as string[];

      if (featureIds.length === 0) return;
      return {
        type: ValidationWarningTypes.InvalidFeatures,
        featureIds,
      };
    }),
);
