import { InputDimensioned } from "../../General/Input";
import { Label } from "../../General/Form";
import { VesselType } from "services/vesselService";
import { vesselChangelogInfo } from "components/InputChangelog/const";
import { SecondaryText } from "components/SettingsV2/Shared/styles";

export function ScourProtectionVesselSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType & { type: "scourProtection" };
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  return (
    <>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Scour protection capacity</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.scourProtectionCapacity}
            unit={"turbines"}
            step={1}
            validate={(e) => e >= 1 && e <= 50}
            validationMessage={`Must be between 1 and 50`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "scourProtectionCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                scourProtectionCapacity: Math.round(n),
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Rock loading time</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.rockLoadingTime}
            unit={"hours"}
            step={1}
            validate={(e) => e >= 1 && e <= 30}
            validationMessage={`Must be between 1 and 30`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "rockLoadingTime",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                rockLoadingTime: Math.round(n),
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Rock dumping time</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.rockDumpingTime}
            unit={"hours"}
            step={1}
            validate={(e) => e >= 1 && e <= 10}
            validationMessage={`Must be between 1 and 10`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "rockDumpingTime",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                rockDumpingTime: Math.round(n),
              });
            }}
          />
        </Label>
      </div>
    </>
  );
}
