import {
  Atom,
  Getter,
  SetStateAction,
  Setter,
  WritableAtom,
  atom,
  useAtomValue,
} from "jotai";
import {
  atomWithStorage,
  createJSONStorage,
  unstable_withStorageValidator,
  atomFamily as _atomFamily,
  RESET,
  useAtomCallback,
} from "jotai/utils";
import { ZodType } from "zod";
import deepEqual from "fast-deep-equal";
import { useCallback } from "react";
import { type Loadable } from "jotai/vanilla/utils/loadable";
import { loadable } from "jotai/utils";
import { MaybePromise } from "types/utils";

class LocalStorageNotAvailableError extends Error {
  name = "LocalStorageNotAvailableError";
  extra: any;
  constructor(msg: string, _extra?: Record<any, any>) {
    super(msg);
    this.extra = _extra;
  }
}

/**
 * Convenience function to create an atom backed by `localStorage`.
 */
export const atomLocalStorage = <T>(
  key: string,
  initial: T,
  parser: ZodType<T>,
  options?: { getOnInit?: boolean },
) => {
  if (typeof window === "undefined" || !("localStorage" in window)) {
    throw new LocalStorageNotAvailableError(
      "Cannot use localStorage in this environment",
      {
        atom: "atomLocalStorage",
        key,
      },
    );
  }

  // The validator checks if a type can be parsed, but it doesn't return the
  // parsed type.  This means that parsers that use `z.transform` doesn't work,
  // since the output value is discarded.  To fix this we don't use the
  // atomWithStorage directly, but wrap it in a second atom that returns the
  // parsed output (if parsing succeeds).
  const inner = atomWithStorage(
    key,
    initial,
    unstable_withStorageValidator<T>(
      (v: unknown): v is T => parser.safeParse(v).success,
    )(createJSONStorage()),
    { getOnInit: true, ...options },
  );

  return atom<T, [SetStateAction<T>], void>(
    (get) => {
      const value = get(inner);
      const parsed = parser.safeParse(value);
      if (parsed.success) return parsed.data;
      console.warn(`LocalStorage: bad data at "${key}"`, value);
      return initial;
    },
    (_, set, update) => set(inner, update),
  );
};

/**
 * Dumb convenience function to get `set(atom, curr => f(curr))` behavior for async atoms.
 */
export const aset = async <T>(
  get: Getter,
  set: Setter,
  atom:
    | WritableAtom<Promise<T>, [typeof RESET | SetStateAction<Promise<T>>], any>
    | WritableAtom<
        MaybePromise<T>,
        [typeof RESET | SetStateAction<MaybePromise<T>>],
        any
      >,
  update: (t: T) => T,
) => {
  const val = await get(atom);
  set(atom, Promise.resolve(update(val)));
};

/**
 * Jotai's `atomFamily`, but with `deepEqual` as the comparison function. If
 * you want to use another comparison function, use {@link atomFamily} from
 * 'jotai/utils'.
 */
export const atomFamily = <
  In,
  Out,
  At extends Atom<Out> = WritableAtom<Out, [SetStateAction<Out>], void>,
>(
  fn: (i: In) => At,
) => {
  const af = _atomFamily(fn, deepEqual);
  return af;
};

/**
 * Wrapper around {@link useAtomCallback} where the function is wrapped in a
 * {@link useCallback}, which is basically always what you want.
 */
export function useJotaiCallback<Result, Args extends unknown[]>(
  callback: (get: Getter, set: Setter, ...arg: Args) => Result,
  deps: any[],
): (...args: Args) => Result {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useAtomCallback(useCallback(callback, deps));
}

/**
 * Applies the function on a {@link Loadable}, transforming the data iff. the
 * `.state` is `"hasData"`.
 */
export function lmap<T, U>(
  loadable: Loadable<T>,
  fn: (t: Awaited<T>) => Awaited<U>,
): Loadable<U> {
  if (loadable.state === "hasData")
    return {
      state: "hasData",
      data: fn(loadable.data),
    };
  return loadable;
}

/**
 * Similar to `unwrap`, but used on a {@link Loadable} instead on an {@link atom}.
 *
 * Corresponds to Recoil's `.valueMaybe()`.
 */
export const lunwrap = <T>(loadable: Loadable<T>): Awaited<T> | undefined => {
  if (loadable.state === "hasData") return loadable.data;
  return undefined;
};

/**
 * Convenience method to `unwrap` an atom while returning `undefined` when the
 * atom errors.
 */
export function useAtomUnwrap<T>(atom: Atom<T>): Awaited<T> | undefined {
  return lunwrap(useAtomValue(loadable(atom)));
}

/**
 * `get` this selector if you want your selector to never resolve.
 *
 * @deprecated: Typing is wrong. Use `suspendAtom`
 */
export const jotaiSuspendThisAtom = atom<any>(async () => {
  await new Promise(() => {});
  throw undefined;
});

export const suspendAtom = atom<Promise<any>>(async () => {
  await new Promise(() => {});
  return "IF YOU EVER SEE THIS SOMETHING HAS GONE TERRIBLY WRONG" as never;
});

type Read<Value, Args extends unknown[], Result> = WritableAtom<
  Value,
  Args,
  Result
>["read"];

/**
 * Atom that is initialized from a function and that can be set as well as refreshed.
 *
 * When refreshed, the initializing function is ran again.
 *
 * ## Difference from `atomWithDefault`
 *
 * Jotai already has `atomWithDefault`. However, resetting this atom will only
 * re-run the initializer if the atom has been set in the meantime.  That is,
 * calling `setAtom(RESET)` twice in succession will only run the initializer
 * once.
 *
 * Usually, we use this atom around a `fetch` to a backend service, and would
 * like to re-fetch at will. Using `atomWithDefault` requires us to first set
 * the atom to some value, and then `RESET` it, which is really awkward.
 *
 * See https://github.com/pmndrs/jotai/discussions/2737
 */
export function atomFromFn<Value>(
  getDefault: Read<Value, [SetStateAction<Value> | typeof RESET], void>,
): WritableAtom<Value, [SetStateAction<Value> | typeof RESET], void> {
  // Use two symbols, alternating between the two. This causes resetting twice
  // to work as intended.
  const S1 = Symbol();
  const S2 = Symbol();
  type S = typeof S1 | typeof S2;

  const overwrittenAtom = atom<Value | S>(S1);

  if (import.meta.env?.MODE !== "production") {
    overwrittenAtom.debugPrivate = true;
  }

  const anAtom: WritableAtom<
    Value,
    [SetStateAction<Value> | typeof RESET],
    void
  > = atom(
    (get, options) => {
      const overwritten = get(overwrittenAtom);
      if (overwritten === S1 || overwritten === S2)
        return getDefault(get, options);
      return overwritten;
    },
    (get, set, update) => {
      if (update === RESET) {
        set(overwrittenAtom, (c) => (c === S1 ? S2 : S1));
      } else if (typeof update === "function") {
        const prevValue = get(anAtom);
        set(overwrittenAtom, (update as (prev: Value) => Value)(prevValue));
      } else {
        set(overwrittenAtom, update);
      }
    },
  );
  return anAtom;
}

/**
 * Wrapper around {@link useAtomValue} that conditionally returns the atom value
 * if the `enabled` flag is true.
 */
export function useAtomValueConditional<T>(
  enabled: boolean,
  atom: Atom<T>,
): T | null {
  const getValue = useJotaiCallback(
    (get) => {
      return get(atom);
    },
    [atom],
  );

  return enabled ? getValue() : null;
}
