import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { InboundMessage } from "ably";
import { ABLY_CHANGELOG_UPDATE } from "state/ably";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import { useCallback, useMemo } from "react";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";
import {
  ChangelogEntry,
  _ChangelogEntry,
  _UpdateCommentAblyMessage,
} from "components/InputChangelog/types";
import { OrganisationResources } from "components/Organisation/OrganisationRightSide/types";
import { aset, useJotaiCallback } from "utils/jotai";

export function useAblyChangelogProjectUpdate(projectId: string) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const onMessageReceived = useJotaiCallback(
    async (_, set, msg: InboundMessage) => {
      const changelogEntry = _ChangelogEntry.parse(msg.data);
      if (changelogEntry.nodeId === projectId) {
        aset(
          _,
          set,
          inputChangelogsAtomFamily({
            nodeId: projectId,
            changelogId: changelogEntry.id,
            category: "project",
            organisationId,
          }),
          (curr) => {
            const matchingIndex = curr.findIndex(
              (item) => item.version === changelogEntry.version,
            );
            if (matchingIndex !== -1) {
              return [
                ...curr.slice(0, matchingIndex),
                changelogEntry,
                ...curr.slice(matchingIndex + 1),
              ];
            } else {
              return [...curr, changelogEntry].sort(
                (a, b) => b.version - a.version,
              );
            }
          },
        );
        aset(
          _,
          set,
          inputChangelogsAtomFamily({
            nodeId: projectId,
            changelogId: "",
            category: "project",
            organisationId,
          }),
          (curr) => {
            const matchingIndex = curr.findIndex(
              (item) => item.version === changelogEntry.version,
            );
            if (matchingIndex !== -1) {
              return [
                ...curr.slice(0, matchingIndex),
                changelogEntry,
                ...curr.slice(matchingIndex + 1),
              ];
            } else {
              return [...curr, changelogEntry].sort(
                (a, b) => b.version - a.version,
              );
            }
          },
        );
      }
    },
    [projectId, organisationId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_CHANGELOG_UPDATE,
        onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}

// Library
export function AblyChangelogCommentLibraryInner({
  organisationId,
  libraryType,
}: {
  organisationId: string;
  libraryType: OrganisationResources;
}) {
  const updateChangelog = useJotaiCallback(
    (_, set, changelogEntry: ChangelogEntry) => {
      aset(
        _,
        set,
        inputChangelogsAtomFamily({
          nodeId: changelogEntry.nodeId,
          changelogId: changelogEntry.id,
          category: libraryType,
          organisationId,
        }),
        (curr) =>
          [
            ...curr.filter((item) => item.version !== changelogEntry.version),
            changelogEntry,
          ].sort((a, b) => b.version - a.version),
      );
    },
    [libraryType, organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const changelogEntry = _ChangelogEntry.parse(message.data);
      updateChangelog(changelogEntry);
    },
    [updateChangelog],
  );

  const channelName = useMemo(
    () => `${organisationId}:${libraryType}`,
    [libraryType, organisationId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_CHANGELOG_UPDATE,
        onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, organisationId);

  return <></>;
}
