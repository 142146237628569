import React, { useCallback, useEffect, useRef, useState } from "react";
import { Mixpanel, TrackingCapability, TrackingFeature } from "mixpanel";
import {
  ImageContainer,
  PaginationWrapper,
  SliderAnimation,
  SliderLeft,
  SliderLeftInner,
  SliderRight,
  SlideShowWrapper,
  TRANSITION_MS,
} from "components/SlideShow/style";
import ArrowLeftIcon from "@icons/24/ArrowLeft.svg";
import ArrowRightIcon from "@icons/24/ArrowRight.svg";
import { IconBtn } from "components/General/Icons";

export const Step = ({
  animation,
  leftSide,
  imagePosition,
  image,
}: {
  animation: SliderAnimation;
  leftSide: React.ReactNode;
  imagePosition: "right" | "left" | "center";
  image: React.ReactNode;
}) => {
  return (
    <>
      <SliderLeft>
        <SliderLeftInner animation={animation}>{leftSide}</SliderLeftInner>
      </SliderLeft>
      <SliderRight imagePosition={imagePosition} animation={animation}>
        <ImageContainer imagePosition={imagePosition}>
          {typeof image === "string" ? (
            <img
              src={image}
              style={{
                maxHeight: "100%",
                maxWidth: "80%",
                objectFit: "contain",
              }}
            />
          ) : (
            image
          )}
        </ImageContainer>
      </SliderRight>
    </>
  );
};

const SlideShowInner = ({
  step,
  steps,
}: {
  step: number;
  steps: Array<React.ElementType<{ animation: SliderAnimation }>>;
}) => {
  const [animation, setAnimation] = useState<SliderAnimation>(undefined);
  // Use prevStep to be able to animate the slides out and in
  const prevStep = useRef<number>(step);

  // When the step changes, animate the slides
  // First we animate the current step, then set the next step to be active
  // and animate that in
  useEffect(() => {
    // No animation on load & when the step is the same as the previous step
    if (prevStep.current === step) {
      setAnimation(undefined);
      return;
    }

    // Start the animation of the current slide
    if (prevStep.current < step) {
      setAnimation("right");
    } else if (prevStep.current > step) {
      setAnimation("left");
    }

    // Start the animation of the next slide
    const afterOutAnimationTimeout = setTimeout(() => {
      prevStep.current = step;
      setAnimation((curr) => (curr === "right" ? "left" : "right"));
    }, TRANSITION_MS);

    // Stop animations after next slide is slidd
    const afterInAnimationTimout = setTimeout(() => {
      setAnimation(undefined);
    }, TRANSITION_MS * 2);

    return () => {
      clearTimeout(afterOutAnimationTimeout);
      clearTimeout(afterInAnimationTimout);
    };
  }, [step]);

  const CurrentStep = steps[prevStep.current - 1];
  if (!CurrentStep) {
    return null;
  }

  return <CurrentStep animation={animation} />;
};

const SlideShow = ({
  steps,
  bgColor,
}: {
  steps: Array<React.ElementType<{ animation: SliderAnimation }>>;
  bgColor: string;
}) => {
  const [step, setStep] = useState(1);
  const [steppingEnabled, setSteppingEnabled] = useState(true);

  // Debug, todo remove
  useEffect(() => {
    return () => {
      setStep(1);
    };
  }, [steps]);

  const goToPrev = useCallback(() => {
    Mixpanel.track(
      TrackingCapability.Navigation,
      TrackingFeature.SlideShow,
      "previous slide",
      {
        steppingEnabled,
      },
    );
    if (!steppingEnabled) {
      return;
    }

    setStep((curr) => {
      const nextPage = curr - 1;
      return nextPage > 0 ? nextPage : curr;
    });
  }, [steppingEnabled]);

  const goToNext = useCallback(() => {
    if (!steppingEnabled) {
      return;
    }
    Mixpanel.track(
      TrackingCapability.Navigation,
      TrackingFeature.SlideShow,
      "next slide",
      {
        steppingEnabled,
      },
    );

    setStep((curr) => {
      const nextPage = curr + 1;
      return nextPage <= steps.length ? nextPage : curr;
    });
  }, [steps.length, steppingEnabled]);

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        goToNext();
      }
      if (e.key === "ArrowLeft") {
        goToPrev();
      }
    };

    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [goToNext, goToPrev]);

  useEffect(() => {
    setSteppingEnabled(false);
    const timeout = setTimeout(() => {
      setSteppingEnabled(true);
    }, TRANSITION_MS * 2);

    return () => {
      clearTimeout(timeout);
    };
  }, [step]);

  const canGoPrev = step > 1;
  const canGoNext = step < steps.length;

  return (
    <SlideShowWrapper bgColor={bgColor}>
      <SlideShowInner step={step} steps={steps} />
      <PaginationWrapper>
        <IconBtn onClick={goToPrev} disabled={!canGoPrev}>
          <ArrowLeftIcon />
        </IconBtn>
        <p>
          Step {step} of {steps.length}
        </p>
        <IconBtn onClick={goToNext} disabled={!canGoNext}>
          <ArrowRightIcon />
        </IconBtn>
      </PaginationWrapper>
    </SlideShowWrapper>
  );
};

export default SlideShow;
