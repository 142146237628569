import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React from "react";
import Spinner from "@icons/spinner/Spinner";
import { allCollaboratorsInNodeSelectorFamily } from "../../state/customer";
import UserImageRound from "./UserImageRound";

const UserImageRoundFromIdInner = ({
  userId,
  nodeId,
  size,
  onClick,
}: {
  userId: string;
  nodeId: string;
  size?: number;
  onClick?: () => void;
}) => {
  const usersInNode = useAtomValue(
    allCollaboratorsInNodeSelectorFamily(nodeId),
  );
  const user = usersInNode?.find((cm) => cm.user_id === userId);

  if (!user) return null;

  return <UserImageRound size={size} onClick={onClick} user={user} />;
};

const UserImageRoundFromId = ({
  userId,
  size,
  onClick,
}: {
  userId: string;
  size?: number;
  onClick?: () => void;
}) => {
  const projectId = useAtomValue(projectIdAtom);

  if (!projectId) return null;
  return (
    <React.Suspense fallback={<Spinner size={`${size}rem`} />}>
      <UserImageRoundFromIdInner
        nodeId={projectId}
        userId={userId}
        onClick={onClick}
        size={size}
      />
    </React.Suspense>
  );
};

export default UserImageRoundFromId;
