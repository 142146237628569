import React, { ChangeEvent, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import LayersIcon from "@icons/24/Layers.svg?react";
import ListIcon from "@icons/24/PaperList.svg?react";
import UploadIcon from "@icons/24/Upload.svg?react";
import WindIcon from "@icons/24/Wind.svg?react";
import { colors } from "styles/colors";
import { spaceLarge } from "styles/space";
import { IconREMSize, Text, typography } from "styles/typography";
import Button from "../../General/Button";
import { UploadFileType } from "./NewUploadTab/types";

export const TextNoMargin = styled(Text)`
  margin: 0;
`;

export const DropdownText = styled.span`
  ${typography.contentAndButtons};
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: ${spaceLarge};
  gap: ${spaceLarge};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const ActionButtonWrapper = styled.div<{ nrChildren: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  ${({ nrChildren }) =>
    // Center the buttons (sorry)
    nrChildren > 0
      ? `
    padding-right: 4%;
  `
      : ``}
`;

const DropFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px dashed ${colors.inputOutline};
  gap: 1.5rem;
  padding: 4rem 0;
  cursor: pointer;
  position: relative;
`;

export const DropFileToUpload = ({
  acceptedFileTypes,
  handleNewFiles,
  folderSupport,
  children,
}: {
  acceptedFileTypes: string[];
  handleNewFiles(files: File[]): void;
  folderSupport?: boolean;
} & React.PropsWithChildren) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (folderInputRef.current !== null) {
      folderInputRef.current.setAttribute("directory", "");
      folderInputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [folderInputRef]);

  const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.style.backgroundColor = "unset";
  }, []);

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      return handleNewFiles(Array.from(e.target.files ?? []));
    },
    [handleNewFiles],
  );

  useEffect(() => {
    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
    };

    const onDrop = async (e: DragEvent) => {
      const readDirectory = async (
        directoryEntry: FileSystemDirectoryEntry,
      ) => {
        const files: File[] = [];
        const reader = directoryEntry.createReader();

        const readEntriesAsync = () =>
          new Promise<FileSystemEntry[]>((resolve, reject) => {
            reader.readEntries((entries) => {
              if (entries.length === 0) {
                resolve([]);
              } else {
                resolve(entries);
              }
            }, reject);
          });

        let entries;
        do {
          entries = await readEntriesAsync();

          for (const entry of entries) {
            if (entry.isFile) {
              const fileEntry = entry as FileSystemFileEntry;
              const file = await new Promise<File>((resolve, reject) => {
                fileEntry.file(resolve, reject);
              });
              files.push(file);
            } else if (entry.isDirectory) {
              const subdirectoryFiles = await readDirectory(
                entry as FileSystemDirectoryEntry,
              );
              files.push(...subdirectoryFiles); // Recursively add files from subdirectories
            }
          }
        } while (entries.length > 0);

        return files;
      };

      const items = e.dataTransfer?.items ?? [];
      if (!e.dataTransfer || items.length === 0) {
        return;
      }
      e.preventDefault();

      let allFiles: File[] = [];

      if (e.dataTransfer.types.includes("Files") && fileInputRef.current) {
        allFiles = [...allFiles, ...Array.from(e.dataTransfer.files)];
      }

      for (let i = 0; i < items.length; i++) {
        const item = items[i].webkitGetAsEntry();
        if (item) {
          if (item.isDirectory && folderSupport) {
            const files = await readDirectory(item as FileSystemDirectoryEntry);
            allFiles = [...allFiles, ...files];
          }
        }
      }

      if (allFiles.length === 0) return;

      return handleNewFiles(allFiles);
    };

    document.addEventListener("dragover", preventDefault);
    document.addEventListener("dragleave", preventDefault);
    document.addEventListener("drop", onDrop);
    return () => {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("dragleave", preventDefault);
      document.removeEventListener("drop", onDrop);
    };
  }, [handleNewFiles, folderSupport]);

  const triggerFileInput = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  }, []);

  const triggerFolderInput = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    folderInputRef.current?.click();
  }, []);

  return (
    <>
      <input
        multiple
        ref={fileInputRef}
        onChange={onFileChange}
        type="file"
        accept={acceptedFileTypes.join(",")}
        style={{ display: "none" }}
      />
      <input
        multiple
        ref={folderInputRef}
        onChange={onFileChange}
        type="file"
        style={{ display: "none" }}
      />
      <DropFile
        onClick={triggerFileInput}
        onDragOver={(e) => {
          e.preventDefault();
          if (!e.dataTransfer.types.includes("Files")) {
            return;
          }

          e.currentTarget.style.backgroundColor = `${colors.selected}`;
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.currentTarget.style.backgroundColor = "unset";
        }}
        onDrop={handleDrop}
      >
        <UploadIcon />
        <TextNoMargin>Drag and drop files here</TextNoMargin>
        <TextNoMargin>OR</TextNoMargin>
        <ActionButtonWrapper nrChildren={React.Children.count(children)}>
          {!!folderSupport && (
            <Button
              buttonType="secondary"
              text="Choose folder"
              onClick={triggerFolderInput}
              size="small"
            />
          )}

          <Button
            buttonType="secondary"
            text="Choose file"
            onClick={triggerFileInput}
            size="small"
          />
          {children}
        </ActionButtonWrapper>
      </DropFile>
    </>
  );
};

export const ModalTitleMapper = ({
  fileType,
}: {
  fileType?: UploadFileType;
}) => {
  if (fileType === UploadFileType.PROJECT_FEATURE) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <ListIcon />
        </IconREMSize>
        <h2>Elements</h2>
      </>
    );
  }
  if (fileType === UploadFileType.FEATURE_FROM_COORDINATES) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <ListIcon />
        </IconREMSize>
        <h2>Element from coordinates</h2>
      </>
    );
  }

  if (fileType === UploadFileType.DATA_LAYER) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <LayersIcon />
        </IconREMSize>
        <h2>GIS layers</h2>
      </>
    );
  }

  if (fileType === UploadFileType.ADD_DATA_LAYER_SOURCE) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <LayersIcon />
        </IconREMSize>
        <h2>GIS layer source</h2>
      </>
    );
  }

  if (fileType === UploadFileType.WIND_DATA) {
    return (
      <>
        <IconREMSize height={1.7} width={1.7}>
          <WindIcon />
        </IconREMSize>
        <h2>Wind data</h2>
      </>
    );
  }

  return <h2>Upload</h2>;
};
