import { useAtomValue } from "jotai";
import {
  _BranchDeleteMessage,
  _BranchMeta,
  _BranchesSortMessage,
} from "./../types/api";
import { useCreateBranch, useSortBranches } from "./../state/timeline";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { useCallback, useMemo } from "react";
import {
  ABLY_BRANCH_DELETE,
  ABLY_BRANCH_UPDATE,
  ABLY_BRANCH_SORT,
} from "../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import * as Sentry from "@sentry/react";

export function useAblyBranch(projectId: string) {
  const { updateLocal } = useCreateBranch();
  const { updateLocal: sortLocal } = useSortBranches();

  const branches = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId: projectId,
    }),
  );

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const meta = _BranchMeta.parse(message.data.meta);
      updateLocal(meta, projectId);
    },
    [updateLocal, projectId],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const meta = _BranchDeleteMessage.parse(message.data.meta);
      Sentry.addBreadcrumb({
        category: "ably",
        message: "Received branch delete message",
        data: {
          branchId: meta.branchId,
        },
      });
      const branch = branches.find((b) => b.id === meta.branchId);

      if (!branch) return;

      updateLocal(
        {
          ...branch,
          isArchived: true,
        },
        projectId,
      );
    },
    [updateLocal, branches, projectId],
  );

  const onMessageReceivedSort = useCallback(
    (message: InboundMessage) => {
      const { sortOrder } = _BranchesSortMessage.parse(message.data);

      sortLocal(sortOrder);
    },
    [sortLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_BRANCH_UPDATE,
        onMessageReceived,
      },
      {
        eventName: ABLY_BRANCH_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
      {
        eventName: ABLY_BRANCH_SORT,
        onMessageReceived: onMessageReceivedSort,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete, onMessageReceivedSort],
  );

  useAblyGeneric(channelName, events, projectId);
}
