import { MILLION } from "@constants/financialAnalysis";
import { getTurbineCapacity } from "analysis/inputs";
import { FinanceId } from "finance/types";
import { CAPEX } from "finance/outputs";
import { atom, useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { CostType } from "services/costService";
import { colors } from "styles/colors";
import { ConfidenceLevel } from "types/financial";
import { atomFamily } from "utils/jotai";
import { roundToDecimal, sum } from "utils/utils";

export const useRealValueCapexMillion = (id: FinanceId) => {
  const total = useAtomValue(loadable(getRealValueTotalInMillion(id)));
  const totalPerMW = useAtomValue(
    loadable(getRealValueTotalInMillionPerMW(id)),
  );
  const costs = useAtomValue(loadable(getRealValueCostsInMillion(id)));

  const useCosts = (type: CostType) => {
    const costs = useAtomValue(
      loadable(getRealValueCostsPerTypeInMillion({ id, type })),
    );
    const costsPerMW = useAtomValue(
      loadable(getCostsOfTypeInMillionPerMW({ id, type })),
    );
    const sum = useAtomValue(loadable(getRealValueSumInMillion({ id, type })));
    const sumPerMW = useAtomValue(
      loadable(getRealValueSumInMillionPerMW({ id, type })),
    );

    return {
      costs,
      costsPerMW,
      sum,
      sumPerMW,
    };
  };

  return {
    total,
    totalPerMW,
    costs,
    useCosts,
  };
};

const getRealValueCostsPerTypeInMillion = atomFamily(
  ({ id, type }: { id: FinanceId; type: CostType }) =>
    atom(async (get) => {
      const costs = await get(CAPEX.getRealValueCostsOfType({ id, type }));

      return costs
        .filter(({ amount }) => amount > 0)
        .map((c) => ({
          ...c,
          amount: roundToDecimal(c.amount, 2),
          costPerAmount: roundToDecimal(c.costPerAmount, 2),
          cost: Math.round(c.cost / MILLION),
        }));
    }),
);

const getRealValueCostsInMillion = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    const costs = await get(CAPEX.getRealValueCosts(id));

    return costs
      .filter(({ amount }) => amount > 0)
      .map((c) => ({
        ...c,
        amount: roundToDecimal(c.amount, 2),
        costPerAmount: roundToDecimal(c.costPerAmount, 2),
        cost: Math.round(c.cost / MILLION),
      }));
  }),
);

const getRealValueSumInMillion = atomFamily(
  ({ id, type }: { id: FinanceId; type: CostType }) =>
    atom(async (get) => {
      const costs = await get(CAPEX.getRealValueCostsOfType({ id, type }));
      const total = sum(costs.map(({ cost }) => cost));

      return Math.round(total / MILLION);
    }),
);

const getRealValueSumInMillionPerMW = atomFamily(
  ({ id, type }: { id: FinanceId; type: CostType }) =>
    atom<Promise<number | undefined>>(async (get) => {
      const capacity = await get(getTurbineCapacity(id));
      const costs = await get(CAPEX.getRealValueCostsOfType({ id, type }));

      if (capacity == 0) {
        return undefined;
      }

      const total = sum(costs.map(({ cost }) => cost));

      return roundToDecimal(total / capacity / MILLION, 2);
    }),
);

const getCostsOfTypeInMillionPerMW = atomFamily(
  ({ id, type }: { id: FinanceId; type: CostType }) =>
    atom(async (get) => {
      const costs = await get(CAPEX.getRealValueCostsOfType({ id, type }));
      const capacity = await get(getTurbineCapacity(id));
      if (capacity == 0) {
        return [];
      }

      return costs
        .filter(({ amount }) => amount > 0)
        .map((c) => ({
          ...c,
          amount: roundToDecimal(c.amount, 2),
          costPerAmount: roundToDecimal(c.costPerAmount, 2),
          cost: roundToDecimal(c.cost / MILLION / capacity, 2),
        }));
    }),
);

const getRealValueTotalInMillion = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    const total = await get(CAPEX.getRealValueTotal(id));

    return Math.round(total / MILLION);
  }),
);

const getRealValueTotalInMillionPerMW = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    const total = await get(CAPEX.getRealValueTotal(id));
    const capacity = await get(getTurbineCapacity(id));

    return roundToDecimal(total / MILLION / capacity, 2);
  }),
);

export const getColorFromConfidenceLevel = (
  confidenceLevel: ConfidenceLevel | undefined,
) => {
  switch (confidenceLevel) {
    case ConfidenceLevel.verified:
      return colors.seagreen600;
    case ConfidenceLevel.uncertain:
      return colors.orange400;
    case ConfidenceLevel.notSpecified:
      return colors.grey500;
    default: //from components
      return colors.indigo400;
  }
};
