import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import {
  ExportCableSplitErr,
  exportCableSplitsFamily,
} from "state/jotai/landfall";
import { isOnshoreAtom } from "state/onshore";

export const exportCableLandFallFailedWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExportCableLandfallFailed>
        | undefined
      >
    >(async (get) => {
      if (get(isOnshoreAtom)) return;
      const splits = await get(exportCableSplitsFamily({ parkId, branchId }));
      const failed = splits
        .filter((s): s is ExportCableSplitErr => "error" in s)
        .map((s) => ({
          exportCableId: s.exportCable.id,
          error: s.error,
        }));

      if (failed.length === 0) return;
      return {
        type: ValidationWarningTypes.ExportCableLandfallFailed,
        data: failed,
        parkId: parkId,
      };
    }),
);
