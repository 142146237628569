import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import {
  HeaderRow,
  MainRow,
  SubHeader,
  TabContainer,
  TableHeader,
} from "../style";
import AddIcon from "@icons/24/Add.svg";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Row } from "components/General/Layout";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { SubstationManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { SearchInput } from "components/General/Input";
import { VerticalDivider } from "components/General/VerticalDivider";
import { Field, LibraryManageRole, Order, SortState } from "../types";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useAtom } from "jotai";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { IconREMSize } from "styles/typography";
import { RecursiveFolder } from "../shared/RecursiveFolder";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import { useLibraryFolderStructure } from "../shared/hooks/useLibraryFolderStructure";
import { useLibrarySearchAndSort } from "../shared/hooks/useLibrarySearchAndSort";
import { useLibraryDragAndDrop } from "../shared/hooks/useLibraryDragAndDrop";
import { organisationSubstationResourceState } from "../state";
import useOrgSubstationCrud from "./useOrgSubstationCrud";
import Tooltip from "components/General/Tooltip";
import { getIsLegalMove } from "business/folderStructure/utils";
import { FolderTreeItem } from "types/folderStructures";
import {
  _SubstationType,
  DEFAULT_SUBSTATION,
  SubstationType,
} from "services/substationService";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { isOnshoreAtom } from "state/onshore";
import ResourceItem from "../shared/ResourceItem/ResourceItem";
import { ResourceCrudOperations } from "../shared/types";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { selectedLibraryTabAtom } from "state/library";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { orgSubstationManageAccessSelector } from "state/user";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { useSyncSelectedItemWithResource } from "../hook";

const SUBSTATION_TABLE_LIST_ITEM = "SUBSTATION_TABLE_LIST_ITEM";
const SUBSTATION_TABLE_FOLDER_ITEM = "SUBSTATION_TABLE_FOLDER_ITEM";

export default function SubstationTab() {
  const hasOrgSubstationAccess = useAtomValue(
    orgSubstationManageAccessSelector,
  );
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  useEffect(() => {
    setSelectedLibraryTab("substation");
    return () => {
      setSelectedLibraryTab(undefined);
    };
  }, [setSelectedLibraryTab]);

  if (!hasOrgSubstationAccess || !allLibraryAccess) return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <SubstationTabInner />
    </Suspense>
  );
}

function SubstationTabInner() {
  useSyncSelectedItemWithResource();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const substations = useAtomValue(
    organisationSubstationResourceState(organisationId),
  );
  const onshore = useAtomValue(isOnshoreAtom);

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const navigate = useNavigate();
  const params = useParams();
  const selectedItemId = params.selectedItemId;
  const [showNewSubstationModal, setShowNewSubstationModal] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [createSubstationInFolderId, setCreateSubstationInFolderId] = useState<
    string | undefined
  >();
  const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
    string | undefined
  >();
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);
  const { showConfirm } = useConfirm();

  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const [openDescriptionId, setOpenDescriptionId] = useState<
    string | undefined
  >(undefined);

  const { remove, duplicate, update } = useOrgSubstationCrud();

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState],
  );

  const { create } = useOrgSubstationCrud();

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  const {
    name,
    onNameChange,
    sortedResources: sortedSubstations,
  } = useLibrarySearchAndSort(substations, sortState);

  const { folders, orphans } = useLibraryFolderStructure({
    organisationId,
    resources: sortedSubstations,
    sortState,
    libraryManageRole: LibraryManageRole.SUBSTATION,
    searchTerm: name,
  });

  const { moveItems, createFolder } = useOrgFolderStructureCrud({
    libraryManageRole: "org_substation_manage",
  });

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folders);
    },
    [folders],
  );

  const collapsed = !!content;

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);
  const onClickOnResource = useCallback(
    async (id: string) => {
      if (
        !unsavedSettings ||
        (await showConfirm({
          title: "Unsaved settings",
          message: "Are you sure you want to quit without saving?",
        }))
      ) {
        setUnsavedSettings(false);
        navigate(`/organisation/${organisationId}/library/substation/${id}`);
      } else {
        navigate(`/organisation/${organisationId}/library/substation/${id}`);
      }
    },
    [
      navigate,
      unsavedSettings,
      setUnsavedSettings,
      organisationId,
      showConfirm,
    ],
  );

  const onCreateSubstationInFolder = useCallback(
    (folderId: string) => {
      setCreateSubstationInFolderId(folderId);
      setShowNewSubstationModal(true);
    },
    [setCreateSubstationInFolderId, setShowNewSubstationModal],
  );

  const onCreateFolderInFolder = useCallback(
    (folderId: string) => {
      setCreateFolderInFolderId(folderId);
      setShowCreateNewFolder(true);
    },
    [setCreateFolderInFolderId, setShowCreateNewFolder],
  );

  const {
    onDropOnFolder,
    onFolderDropOnFolder,
    dropRef,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(
    [SUBSTATION_TABLE_LIST_ITEM, SUBSTATION_TABLE_FOLDER_ITEM],
    folders,
    "org_substation_manage",
  );

  const crudOperations: ResourceCrudOperations<LibraryManageRole.SUBSTATION> =
    useMemo(
      () => ({
        remove: async (id: string) => {
          await remove(id);
          return;
        },
        duplicate: async ({ resource }) => {
          await duplicate({
            substation: resource,
            onSuccess: (newSubstation) => {
              navigate(
                `/organisation/${organisationId}/library/substation/${newSubstation.id}`,
              );
            },
          });
        },
      }),
      [remove, navigate, duplicate, organisationId],
    );

  const getDescription = (resource: SubstationType) => resource.note;
  const updateDescription = async (
    resource: SubstationType,
    newDescription: string,
  ) => {
    return await update({
      ...resource,
      note: newDescription,
    });
  };
  const getSubstationNameExtraInfo = (substation: SubstationType) => {
    return substation.type;
  };

  return (
    <>
      {showCreateNewFolder && (
        <NewItemModal
          title="New folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onSubmit={async (name) => {
            await createFolder(
              name,
              LibraryManageRole.SUBSTATION,
              [],
              createFolderInFolderId,
            );
          }}
          onClose={() => {
            setShowCreateNewFolder(false);
            setCreateFolderInFolderId(undefined);
          }}
        />
      )}
      {showNewSubstationModal && (
        <NewItemModal
          title="Create new library substation"
          placeholder="Enter substation name"
          defaultValue="New substation"
          onClose={() => {
            setShowNewSubstationModal(false);
          }}
          onSubmit={async (name: string) => {
            setSaveInProgress(true);
            navigate(`/organisation/${organisationId}/library/substation`);
            const res = await create({
              name: name,
              substation: {
                ...DEFAULT_SUBSTATION,
                type: onshore ? "onshore" : "offshore",
              },
            });
            if (res) {
              navigate(
                `/organisation/${organisationId}/library/substation/${res.id}`,
              );
              setSaveInProgress(false);
              if (createSubstationInFolderId) {
                await moveItems(
                  [
                    {
                      type: "RESOURCE",
                      id: res.id,
                      parentId: createSubstationInFolderId,
                    },
                  ],
                  createSubstationInFolderId,
                );
                setCreateSubstationInFolderId(undefined);
              }
            }
          }}
        />
      )}
      <TabContainer>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            Substations
          </h2>

          <Row>
            <Tooltip text={"Create new folder"}>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setShowCreateNewFolder(true);
                }}
                icon={<FolderAddIcon />}
              />
            </Tooltip>
            <Button
              text={collapsed ? "" : "New substation"}
              icon={collapsed && <AddIcon />}
              disabled={isLoading}
              onClick={() => setShowNewSubstationModal(true)}
            />
            <VerticalDivider height={3.2} />
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === selectedItemId) return undefined;
                  if (!selectedItemId) {
                    return {
                      type: "no-item-selected",
                    };
                  }
                  return {
                    type: "resource",
                    id: selectedItemId,
                  };
                });
              }}
            />
          </Row>
        </Row>
        <Row alignCenter>
          <SubHeader>Access: </SubHeader> <SubstationManagersPreview />
        </Row>
        <Row
          style={{
            padding: "1.2rem 0",
          }}
        >
          <SearchInput
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            placeholder={`Search substations`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </Row>
        <MainRow ref={dropRef}>
          <HeaderRow>
            <TableHeader
              onClick={() => toggleSort("name")}
              style={{
                cursor: "pointer",
                flex: 4,
              }}
            >
              Name
              <IconREMSize
                width={1.2}
                height={1.2}
                style={{ marginLeft: "0.4rem" }}
              >
                {sortState.field === "name" && sortState.order === "ASC" ? (
                  <DirectionDown />
                ) : sortState.field === "name" && sortState.order === "DESC" ? (
                  <DirectionUp />
                ) : (
                  <DirectionDown />
                )}
              </IconREMSize>
            </TableHeader>
            {!collapsed && (
              <>
                <TableHeader style={{ flex: 1 }}>Last edited</TableHeader>
                <TableHeader style={{ flex: 1 }}>Used in</TableHeader>
              </>
            )}
            <TableHeader style={{ flex: 1 }}></TableHeader>
          </HeaderRow>

          {folders.map((folder) => (
            <Fragment key={folder.id}>
              <RecursiveFolder<LibraryManageRole.SUBSTATION>
                folder={folder}
                depth={0}
                onDropOnFolder={onDropOnFolder}
                onFolderDropOnFolder={onFolderDropOnFolder}
                isLegalFolderMove={isLegalFolderMove}
                onCreateFolderInFolder={onCreateFolderInFolder}
                collapsed={collapsed}
                selectedItemId={selectedItemId}
                onClickResource={onClickOnResource}
                organisationId={organisationId}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setSelectedItemId={(id) => {
                  navigate(
                    `/organisation/${organisationId}/library/substation/${id}`,
                  );
                }}
                setContent={setContent}
                setSaveInProgress={setSaveInProgress}
                setDeleteInProgress={setDeleteInProgress}
                sortedResources={sortedSubstations}
                libraryManageRole={LibraryManageRole.SUBSTATION}
                onCreateResourceInFolder={onCreateSubstationInFolder}
                onFolderDropOnResource={onFolderDropOnResource}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                searchTerm={name}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                nameExtraInfo={getSubstationNameExtraInfo}
              />
            </Fragment>
          ))}

          {orphans.map((item) => {
            const substation =
              "name" in item
                ? item
                : sortedSubstations.find((s) => s.id === item.id);
            if (!substation || !("name" in substation)) return null;
            const parsedSubstation = _SubstationType.parse(substation);

            return (
              <ResourceItem<LibraryManageRole.SUBSTATION>
                key={substation.id}
                resource={parsedSubstation}
                depth={0}
                collapsed={collapsed}
                isSelected={selectedItemId === substation.id}
                onClickResource={onClickOnResource}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                libraryManageRole={LibraryManageRole.SUBSTATION}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                folders={folders}
                searchTerm={name}
                nameExtraInfo={getSubstationNameExtraInfo(parsedSubstation)}
              />
            );
          })}
        </MainRow>
      </TabContainer>
    </>
  );
}
