import styled from "styled-components";
import { Container } from "../../../../styles/misc/Misc";

export const ParentWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin: 0.2rem 0 0 0;
`;
