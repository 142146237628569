import { getTurbineResourcesOnNode } from "components/Organisation/Library/service";
import { atom } from "jotai";
import {
  getAdvancedOrgTurbine,
  getProjectTurbines,
  getAdvancedProjectTurbine,
} from "services/turbineAPIService";
import { designToolTypeAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import {
  adminAccessProjectSelector,
  orgTurbineManageAccessSelector,
} from "state/user";
import { DesignToolMode } from "types/map";
import {
  DEFAULT_OFFSHORE_TUBINE,
  DEFAULT_ONSHORE_TUBINE,
  SimpleTurbineType,
  SimpleTurbineTypeWithLevel,
  TurbineType,
  _TurbineLevel,
  defaultTurbinesForMode,
} from "types/turbines";
import { atomFamily, atomFromFn } from "utils/jotai";
import { scream } from "utils/sentry";

export const defaultTurbineTypesFamily = atomFamily((mode: DesignToolMode) =>
  atom<Map<string, SimpleTurbineType>>(
    new Map(
      defaultTurbinesForMode(mode).map((t) => [
        t.id,
        {
          ...t,
          ratedPower: t.ratedPower ?? Math.max(...t.power),
        },
      ]),
    ),
  ),
);

export const projectTurbinesRefreshAtom = atom(0); // TODO: Get rid of this
export const projectTurbineTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, SimpleTurbineType>>>(async (get) => {
    get(projectTurbinesRefreshAtom);
    const turbines = await getProjectTurbines(nodeId);
    return new Map(
      turbines
        .filter((t) => !t.archived)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => [t.id, t]),
    );
  }),
);

export const libraryTurbinesRefreshAtom = atom(0); // TODO: Get rid of this
export const libraryTurbineTypesFamily = atomFamily((nodeId: string) =>
  atomFromFn<Promise<Map<string, SimpleTurbineType>>>(async (get) => {
    get(libraryTurbinesRefreshAtom);
    const resources = await getTurbineResourcesOnNode(nodeId);
    return new Map(
      resources
        .filter((t) => !t.turbine.archived)
        .sort((a, b) => a.turbine.name.localeCompare(b.turbine.name))
        .map(({ turbine }) => [turbine.id, turbine]),
    );
  }),
);

export const simpleTurbineTypesAtom = atom<
  Promise<Map<string, SimpleTurbineType>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  const mode = get(designToolTypeAtom);
  if (!projectId) return new Map();
  const def = get(defaultTurbineTypesFamily(mode));
  const proj = get(projectTurbineTypesFamily(projectId));
  const lib = get(libraryTurbineTypesFamily(projectId));
  return new Map([...def, ...(await proj), ...(await lib)]);
});

export const simpleTurbineTypeFamily = atomFamily((turbineTypeId: string) =>
  atom(async (get) => {
    const types = await get(simpleTurbineTypesAtom);
    return types.get(turbineTypeId);
  }),
);

export const simpleTurbineTypesByRatingFamily = atom(async (get) => {
  const types = await get(simpleTurbineTypesAtom);
  return Array.from(types.values()).sort((a, b) => a.ratedPower - b.ratedPower);
});

export const simpleTurbineTypesWithLevelAtom = atom<
  Promise<Map<string, SimpleTurbineTypeWithLevel>>
>(async (get) => {
  const projectId = get(projectIdAtom);
  const ret = new Map<string, SimpleTurbineTypeWithLevel>();
  const mode = get(designToolTypeAtom);
  if (!projectId) return ret;

  for (const [id, turbine] of get(defaultTurbineTypesFamily(mode)))
    ret.set(id, { level: _TurbineLevel.Values.standard, turbine });
  const proj = get(projectTurbineTypesFamily(projectId));
  const lib = get(libraryTurbineTypesFamily(projectId));
  for (const [id, turbine] of await proj)
    ret.set(id, { level: _TurbineLevel.Values.project, turbine });
  for (const [id, turbine] of await lib)
    ret.set(id, { level: _TurbineLevel.Values.library, turbine });
  return ret;
});

const _currentTurbineIdAtom = atom<string>();

export const currentTurbineIdAtom = atomFamily(
  (_: { projectId: string | undefined }) =>
    atom(
      (get) => {
        const current = get(_currentTurbineIdAtom);
        if (current) return current;
        const mode = get(designToolTypeAtom);
        return mode === DesignToolMode.Onshore
          ? DEFAULT_ONSHORE_TUBINE
          : DEFAULT_OFFSHORE_TUBINE;
      },
      (get, set, newValue: string) => {
        set(_currentTurbineIdAtom, newValue);
      },
    ),
);

export const advancedTurbineTypesFamily = atomFamily(
  (params: { nodeId: string; turbineId: string }) =>
    atomFromFn<Promise<TurbineType | undefined>>(async (get) => {
      const orgTurbineManageAccess = get(orgTurbineManageAccessSelector);
      if (orgTurbineManageAccess) {
        const turbine = await getAdvancedOrgTurbine(
          params.nodeId,
          params.turbineId,
        );
        return turbine;
      }
      return undefined;
    }),
);

export const advancedProjectTurbineTypesFamily = atomFamily(
  (params: { nodeId: string; turbineId: string }) =>
    atomFromFn<Promise<TurbineType | undefined> | TurbineType>(async (get) => {
      if (get(adminAccessProjectSelector)) {
        try {
          const turbine = await getAdvancedProjectTurbine(
            params.nodeId,
            params.turbineId,
          );
          return turbine;
        } catch (e) {
          scream("Error fetching advanced project turbine", { e });
          return undefined;
        }
      }
      return undefined;
    }),
);
