import { useSetAtom } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";

import { defaultFoundations } from "../../../../state/foundations";
import {
  DEFAULT_MONOPILES,
  FoundationType,
} from "../../../../types/foundations";
import FoundationSettings from "../../../ConfigurationModal/FoundationSettings/FoundationSettings";
import { selectedMenuItemState } from "../../Shared/state";
import {
  orgFoundationManageAccessSelector,
  userNodeAccessSelectorFamily,
} from "state/user";
import {
  foundationResourceWithAccessOnNodeState,
  fromProjectToLibraryTabState,
} from "components/Organisation/Library/state";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import useNodeFoundationResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFoundationResourcesCrud";
import Button from "components/General/Button";
import FoundationModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/FoundationModal";
import { Anchor } from "components/General/Anchor";
import { useToast } from "hooks/useToast";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import { IconREMSize } from "styles/typography";
import { useNavigate, useLocation } from "react-router-dom";
import { useAtom, useAtomValue } from "jotai";
import {
  libraryFoundationTypesFamily,
  projectFoundationTypesFamily,
} from "state/jotai/foundation";
import { loadable } from "jotai/utils";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import SearchIcon from "@icons/24/Search.svg?react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Mixpanel } from "mixpanel";
import { FoundationWizardModal } from "../FoundationSubMenu";
import { useProjectFoundationCrud } from "components/ConfigurationModal/FoundationSettings/useProjectFoundationCrud";
import { scream } from "utils/sentry";
import { aset } from "utils/jotai";
import { useJotaiCallback } from "utils/jotai";

export const FOUNDATION_MENU_ID = "foundations";

export default function useFoundationSettings() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const navigate = useNavigate();
  const location = useLocation();

  const { duplicate: duplicateToLibrary } = useOrgFoundationCrud();
  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: projectId,
    }),
  );
  const editorAccessProject = nodeAccess >= 1;
  const orgFoundationManageAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const { error } = useToast();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);

  const projectFoundations = useAtomValue(
    loadable(projectFoundationTypesFamily(projectId)),
  );

  const libraryFoundations = useAtomValue(
    loadable(libraryFoundationTypesFamily(projectId)),
  );

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: FOUNDATION_MENU_ID,
      projectId,
    }),
  );

  const availableProjectFoundations = useMemo(() => {
    if (projectFoundations.state !== "hasData" || !projectFoundations.data)
      return [];
    return projectFoundations.data
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((t) => !t.archived);
  }, [projectFoundations]);

  const availableLibraryFoundations = useMemo(() => {
    if (libraryFoundations.state !== "hasData" || !libraryFoundations.data)
      return [];
    return libraryFoundations.data
      .filter((t) => !t.foundation.archived)
      .sort((a, b) => a.foundation.name.localeCompare(b.foundation.name));
  }, [libraryFoundations]);

  const availableDefaultFoundations = useMemo(() => {
    return defaultFoundations.filter((t) => !t.archived);
  }, []);

  useEffect(() => {
    if (!menuSelection) {
      const firstItem =
        availableProjectFoundations[0] ?? availableDefaultFoundations[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [
    availableProjectFoundations,
    availableLibraryFoundations,
    availableDefaultFoundations,
    menuSelection,
    setMenuSelection,
  ]);

  const { remove, onSave, duplicateToProject, isLoadingProject } =
    useProjectFoundationCrud();
  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProjectFoundationsCollapsed, setIsProjectFoundationsCollapsed] =
    useState<boolean>(false);
  const [isDefaultFoundationsCollapsed, setIsDefaultFoundationsCollapsed] =
    useState<boolean>(false);
  const [isLibraryFoundationsCollapsed, setIsLibraryFoundationsCollapsed] =
    useState<boolean>(false);

  const _onDuplicateToLibrary = useJotaiCallback(
    async (get, set, nodeId: string, foundation: FoundationType) => {
      setSaveInProgressLibrary(true);
      setIsLoading(true);
      try {
        await duplicateToLibrary({
          foundation,
          projectAccess: [projectId],
          onSuccess: (newFoundation) => {
            aset(
              get,
              set,
              foundationResourceWithAccessOnNodeState({
                nodeId: nodeId,
              }),
              (curr) => [
                ...curr,
                {
                  nodeId,
                  foundation: {
                    ...foundation,
                    id: newFoundation.id,
                  },
                },
              ],
            );
            set(
              selectedMenuItemState({
                menuId: FOUNDATION_MENU_ID,
                projectId,
              }),
              newFoundation.id,
            );
          },
        });
      } catch (e) {
        scream("Error when duplicating project foundation to library", {
          e,
        });
        error(
          "Something went wrong while trying to duplicate project foundation to library, the Vind team has been notified",
        );
      } finally {
        setSaveInProgressLibrary(false);
        setIsLoading(false);
      }
    },
    [setIsLoading, error, duplicateToLibrary, projectId],
  );

  const foundationSubMenus = useMemo(() => {
    const libraryFoundationList = availableLibraryFoundations.map(
      (foundationAccess) => {
        const isDefaultFoundation = defaultFoundations.some(
          (defaultFoundation) =>
            defaultFoundation.id === foundationAccess.foundation.id,
        );

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgFoundationManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library component",
            onSelect: () => {
              let searchParams = new URLSearchParams(location.search);
              Mixpanel.track_old("Click edit in library", {
                resource: "foundation",
              });
              let newSearchString = searchParams.toString();
              setFromProjectToLibraryTab(true);
              setContent({
                type: "resource",
                id: foundationAccess.foundation.id,
              });
              setFromProjectToLibraryTab(true);

              navigate(
                `/organisation/${organisationId}/library/foundation/${foundationAccess.foundation.id}?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
          if (editorAccessProject) {
            dotMenuOptions.push({
              title: "Create a project specific duplicate",
              onSelect: () => {
                setSaveInProgress(true);
                duplicateToProject(foundationAccess.foundation).then(() => {
                  setSaveInProgress(false);
                });
              },
              icon: <DuplicateIcon />,
            });
          }
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: foundationAccess.foundation.id,
          label: foundationAccess.foundation.name,
          loading: isLoading,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <FoundationSettings
                key={foundationAccess.foundation.id}
                foundation={foundationAccess.foundation}
                isDefault={isDefaultFoundation}
                onSave={() => {}}
                duplicate={() => {}}
                nodeId={organisationId}
                hasEditAccess={orgFoundationManageAccess}
                isLibraryFoundation={true}
              />
            </div>
          ),
          dotMenuOptions,
        };
      },
    );

    const projectFoundationList = availableProjectFoundations.map(
      (foundation) => {
        const isSiteSpecificMonopile = DEFAULT_MONOPILES.some(
          (defaultMonopile) => defaultMonopile.id === foundation.id,
        );

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Delete",
            onSelect: (foundationId: string) => remove(foundationId),
            icon: <DeleteIcon />,
          });
        }

        if (editorAccessProject && !isSiteSpecificMonopile) {
          dotMenuOptions.push({
            title: "Duplicate",
            onSelect: () => duplicateToProject(foundation),
            icon: <DuplicateIcon />,
          });
          if (orgFoundationManageAccess) {
            dotMenuOptions.push({
              title: "Duplicate to Library",
              onSelect: () => _onDuplicateToLibrary(projectId, foundation),
              icon: <DuplicateIcon />,
            });
          }
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: foundation.id,
          label: `${foundation.name}`,
          loading: isLoadingProject,
          onDuplicate: undefined,
          onChangeName: editorAccessProject
            ? (newName: string) => {
                return onSave(
                  {
                    ...foundation,
                    name: newName,
                  },
                  projectId,
                );
              }
            : undefined,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <FoundationSettings
                key={foundation.id}
                foundation={foundation}
                isDefault={false}
                onSave={(
                  foundation: FoundationType,
                  onlyUpdatingDescription?: boolean,
                ) => onSave(foundation, projectId, onlyUpdatingDescription)}
                duplicate={(foundation) => duplicateToProject(foundation)}
                nodeId={projectId}
                hasEditAccess={editorAccessProject}
              />
            </div>
          ),
          dotMenuOptions,
        };
      },
    );

    const defaultFoundationList = availableDefaultFoundations.map(
      (foundation) => {
        const isSiteSpecificMonopile = DEFAULT_MONOPILES.some(
          (defaultMonopile) => defaultMonopile.id === foundation.id,
        );

        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (editorAccessProject && !isSiteSpecificMonopile) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => duplicateToProject(foundation),
            icon: <DuplicateIcon />,
          });
          if (orgFoundationManageAccess) {
            dotMenuOptions.push({
              title: "Duplicate to Library",
              onSelect: () => _onDuplicateToLibrary(projectId, foundation),
              icon: <DuplicateIcon />,
            });
          }
        }

        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: foundation.id,
          label: `${foundation.name}`,
          loading: isLoadingProject,
          onDuplicate: undefined,
          onChangeName: undefined,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <FoundationSettings
                key={foundation.id}
                foundation={foundation}
                isDefault={true}
                onSave={(
                  foundation: FoundationType,
                  onlyUpdatingDescription?: boolean,
                ) => onSave(foundation, projectId, onlyUpdatingDescription)}
                duplicate={(foundation) => duplicateToProject(foundation)}
                nodeId={projectId}
                hasEditAccess={editorAccessProject}
              />
            </div>
          ),
          dotMenuOptions,
        };
      },
    );

    const libraryFoundationMenu: SettingsSubMenuProp = {
      title: "Library components",
      items: libraryFoundationList,
      isCollapsed: isLibraryFoundationsCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryFoundationsCollapsed}
            onClick={() =>
              setIsLibraryFoundationsCollapsed(!isLibraryFoundationsCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectFoundationMenu: SettingsSubMenuProp = {
      title: "Project specific components",
      items: projectFoundationList,
      isCollapsed: isProjectFoundationsCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectFoundationsCollapsed}
            onClick={() =>
              setIsProjectFoundationsCollapsed(!isProjectFoundationsCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const defaultFoundationMenu: SettingsSubMenuProp = {
      title: "Vind AI components",
      items: defaultFoundationList,
      isCollapsed: isDefaultFoundationsCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isDefaultFoundationsCollapsed}
            onClick={() =>
              setIsDefaultFoundationsCollapsed(!isDefaultFoundationsCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    return [
      libraryFoundationMenu,
      projectFoundationMenu,
      defaultFoundationMenu,
    ];
  }, [
    setFromProjectToLibraryTab,
    setContent,
    isLibraryFoundationsCollapsed,
    isProjectFoundationsCollapsed,
    isDefaultFoundationsCollapsed,
    availableLibraryFoundations,
    availableProjectFoundations,
    availableDefaultFoundations,
    orgFoundationManageAccess,
    projectId,
    editorAccessProject,
    isLoadingProject,
    isLoading,
    remove,
    onSave,
    organisationId,
    navigate,
    location.search,
    duplicateToProject,
    _onDuplicateToLibrary,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgressLibrary,
      element: <AddFoundationResourceToNode nodeId={projectId} />,
    };
  }, [saveInProgressLibrary, projectId]);

  const createNewFoundation = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <FoundationWizardModal
          isLoading={isLoading}
          disabled={!editorAccessProject}
        />
      ),
    };
  }, [saveInProgress, editorAccessProject, isLoading]);

  const foundationSettings = useMemo(() => {
    return {
      id: FOUNDATION_MENU_ID,
      label: "Foundations",
      icon: <FoundationIcon />,
      submenus: foundationSubMenus,
      createNew: createNewFoundation,
      addFromLibrary: addFromLibrary,
    };
  }, [foundationSubMenus, createNewFoundation, addFromLibrary]);

  return foundationSettings;
}

function AddFoundationResourceToNode({ nodeId }: { nodeId: string }) {
  const foundationResources = useAtomValue(
    foundationResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateFoundation } =
    useNodeFoundationResourcesCrud();
  const [showAddFoundation, setShowAddFoundation] = useState(false);
  const orgFoundationManageAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddFoundation(true)}
        icon={<SearchIcon />}
        disabled={!orgFoundationManageAccess}
      />
      {showAddFoundation && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <FoundationModal
            onSave={async (foundations) => {
              setSaveInProgress(true);
              await Promise.all(
                foundations.map((f) => addOrUpdateFoundation(nodeId, f)),
              );
              setSaveInProgress(false);
              setShowAddFoundation(false);
            }}
            existingFoundations={foundationResources.map(
              (t) => t.foundation.id,
            )}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
