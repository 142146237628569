import usePrevious from "./usePrevious";
import { useMemo } from "react";

export const didArrayChange = <T>(
  prev: T[] | undefined,
  curr: T[],
): boolean => {
  if (!prev) return true;
  const currSet = new Set(curr.map((item) => JSON.stringify(item)));
  const prevSet = new Set(prev.map((item) => JSON.stringify(item)));

  if (prevSet.size !== currSet.size) return true;

  for (const key of currSet) if (!prevSet.has(key)) return true;
  return false;
};

export const useArrayDidChange = <T>(array: T[]): boolean => {
  const prev = usePrevious(array);
  const didChange = useMemo(() => didArrayChange(prev, array), [array, prev]);
  return didChange;
};
