import { useAtomValue, useSetAtom } from "jotai";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { MultiPolygon, Polygon } from "geojson";
import { parkIdAtom } from "state/pathParams";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { ToastMessage } from "../state/toast";
import {
  ProjectFeature,
  stripFeatureTypeSpecificFieldsAndConvertToOther,
} from "../types/feature";
import { currentSelectionArrayAtom } from "../state/selection";
import {
  isDefined,
  isMultiPolygonFeature,
  isPark,
  isPolygonFeature,
} from "../utils/predicates";
import { useProjectElementsCrud } from "../components/ProjectElements/useProjectElementsCrud";
import {
  getFieldNameWithCorrectCasing,
  getGzippedBase64EncodedSize,
} from "../utils/utils";
import {
  DECIMAL_PRECISION,
  SIMPLIFY_TOLERANCE,
  expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons,
  reduceCoordinatePrecisionFeature,
  simplifyToSize,
} from "../utils/geojson/utils";
import { fastMax } from "../utils/utils";
import { _FeatureParser } from "../types/feature";
import useNavigateToPark from "hooks/useNavigateToPark";
import { currentExternalLayerSelection } from "state/externalLayerSelection";
import { hasNecessaryConnections } from "../state/projectLayers";
import { featuresListAtom } from "state/jotai/features";
import { ABLY_SIZE_LIMIT } from "@constants/ably";
import { unkinkFeature } from "utils/gdal";
import { useJotaiCallback } from "utils/jotai";
import { useToast } from "./useToast";

const WARN_TOLERANCE_THREHSOLD = 0.01;

const WARN_TOLERANCE_THREHSOLD_TEXT =
  "Some features have been simplified in order to fit within our size restriction. Please inspect the features to make sure they look acceptable";

const createFeatureClones = (
  features: (ProjectFeature | MapboxGeoJSONFeature)[],
  parkId: undefined | string,
): ProjectFeature[] => {
  const oldIdToNewIdMap = new Map(
    features.map((feature) => [feature.id, uuidv4()]),
  );

  return features
    .map((feature, index) => {
      const newId = oldIdToNewIdMap.get(feature.id)!; // Safety: all feature ids are in the map.

      const maybeMapboxGeojsonFeature = feature as MapboxGeoJSONFeature;
      const nameField = getFieldNameWithCorrectCasing(
        maybeMapboxGeojsonFeature.properties,
        "name",
      );
      let name;
      if (nameField) {
        name = maybeMapboxGeojsonFeature.properties?.[nameField];
      }

      if (!name) {
        name = maybeMapboxGeojsonFeature.sourceLayer
          ? `${maybeMapboxGeojsonFeature.sourceLayer} - Copy`
          : `#${index + 1}`;
      }

      return _FeatureParser.parse({
        id: newId,
        type: feature.type,
        geometry: {
          ...feature.geometry,
        },
        properties: {
          ...feature.properties,
          id: newId,
          name,
        },
      });
    }) // Add reference to new parentIds
    .map((clone) => {
      if (!clone.properties) return clone;

      // ParentIds: if we are pasting a park with features in it, the
      // parentIds of the features will be in `oldTdToNewIdMap`.  In that case
      // we should update the ids.   If not, we should update the id to the
      // passed id, since that's the current park.  This will make pasted
      // features belong to the current park.
      if (clone.properties.parentIds) {
        clone.properties.parentIds = clone.properties.parentIds
          .map((oldParentId) => {
            return oldIdToNewIdMap.get(oldParentId) ?? parkId;
          })
          .filter(isDefined);
      }

      if ((clone.properties as any)["fromId"])
        (clone.properties as any)["fromId"] =
          oldIdToNewIdMap.get((clone.properties as any)["fromId"]) ??
          clone.properties["fromId"];
      if ((clone.properties as any)["toId"])
        (clone.properties as any)["toId"] =
          oldIdToNewIdMap.get((clone.properties as any)["toId"]) ??
          clone.properties["toId"];
      if (clone.properties["anchor"])
        clone.properties["anchor"] =
          oldIdToNewIdMap.get(String(clone.properties["anchor"])) ??
          clone.properties["anchor"];
      if (clone.properties["target"])
        clone.properties["target"] =
          oldIdToNewIdMap.get(String(clone.properties["target"])) ??
          clone.properties["target"];
      if (clone.properties["fromSubstationId"])
        clone.properties["fromSubstationId"] =
          oldIdToNewIdMap.get(String(clone.properties["fromSubstationId"])) ??
          clone.properties["fromSubstationId"];
      if (clone.properties["toSubstationId"])
        clone.properties["toSubstationId"] =
          oldIdToNewIdMap.get(String(clone.properties["toSubstationId"])) ??
          clone.properties["toSubstationId"];
      return clone;
    });
};

export type AddFeaturesToElementsReturnType = (input: {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
  successMessage?: string;
  selectAfterCloning?: boolean;
}) => Promise<ProjectFeature[]>;

const reducePrecisionIfNeeded = (
  feature: ProjectFeature,
  decimals: number,
  maxSize: number,
  sizeFunction: (feature: ProjectFeature) => number,
) =>
  sizeFunction(feature) < maxSize
    ? feature
    : reduceCoordinatePrecisionFeature(feature, decimals);

const processFeature = (
  feature: ProjectFeature,
  parkId: string | undefined,
  featureIdSet: Set<string>,
): { feature: ProjectFeature; convertedToOther?: boolean } => {
  const parentIdIsOk = feature.properties.parentIds
    ? feature.properties.parentIds?.some((id) => featureIdSet.has(id))
    : true;

  if (parentIdIsOk) {
    return { feature };
  } else if (parkId && _FeatureParser.safeParse(feature).success) {
    return {
      feature: {
        ...feature,
        properties: {
          ...feature.properties,
          parentIds: [parkId],
        },
      },
    };
  } else {
    return {
      feature: stripFeatureTypeSpecificFieldsAndConvertToOther(feature),
      convertedToOther: true,
    };
  }
};

const handleProjectFeaturesParentInvalidity = (
  features: ProjectFeature[],
  parkId: string | undefined,
  showInfoToast: (
    text: React.ReactNode,
    args_1?:
      | Pick<ToastMessage, "link" | "timeout" | "showCountdown">
      | undefined,
  ) => () => void,
) => {
  const featureIdSet = new Set(features.map((feature) => feature.id));
  let convertedToOtherCounter = 0;
  const processedFeatures = features.map((feature) => {
    const { feature: processedFeature, convertedToOther } = processFeature(
      feature,
      parkId,
      featureIdSet,
    );
    if (convertedToOther) convertedToOtherCounter++;

    return processedFeature;
  });

  if (convertedToOtherCounter > 0) {
    showInfoToast(
      `${convertedToOtherCounter} feature(s) were converted to 'other' types because a parent park could not be found.`,
      { timeout: 5000 },
    );
  }

  return processedFeatures;
};

const simplifyFeatures = async (
  features: ProjectFeature[],
  sizeFunction: (feature: ProjectFeature) => number,
): Promise<[ProjectFeature[], number[]]> => {
  const clonesExpandedMulti = features.flatMap(
    expandMultiFeaturesExceptMultiPolygonWithSeveralPolygons,
  );

  const clonesExpandedMultiSimplifiedIfNeeded = clonesExpandedMulti
    .map((f) =>
      reducePrecisionIfNeeded(
        f,
        DECIMAL_PRECISION,
        ABLY_SIZE_LIMIT,
        sizeFunction,
      ),
    )
    .map((f) =>
      simplifyToSize<ProjectFeature>(
        f,
        ABLY_SIZE_LIMIT,
        SIMPLIFY_TOLERANCE,
        sizeFunction,
      ),
    );

  const simplifiedFeatures = clonesExpandedMultiSimplifiedIfNeeded.map(
    ([feature]) => feature,
  );
  const tolerances = clonesExpandedMultiSimplifiedIfNeeded.map(
    ([, tolerance]) => tolerance,
  );

  const unkinkedFeatures = (
    await Promise.all(
      simplifiedFeatures.map(async (f) => {
        if (isPolygonFeature(f) || isMultiPolygonFeature(f)) {
          return unkinkFeature<ProjectFeature<Polygon | MultiPolygon>>(f);
        }
        return f;
      }),
    )
  ).flat() as ProjectFeature[];

  return [unkinkedFeatures, tolerances];
};

const simplifyFeaturesWithinAblyLimits = async (
  features: ProjectFeature[],
  showInfoToast: (
    text: React.ReactNode,
    args_1?:
      | Pick<ToastMessage, "link" | "timeout" | "showCountdown">
      | undefined,
  ) => () => void,
  showWarningToast: (
    text: React.ReactNode,
    args_1?:
      | Pick<ToastMessage, "link" | "timeout" | "showCountdown">
      | undefined,
  ) => () => void,
) => {
  // Simplify features if necessary
  const [simplifiedFeatures, tolerances] = await simplifyFeatures(
    features,
    getGzippedBase64EncodedSize,
  );

  if (simplifiedFeatures.length !== features.length) {
    showInfoToast(
      `${features.length - simplifiedFeatures.length} feature(s) were simplified to fit size restrictions.`,
      {
        timeout: 5000,
      },
    );
  }

  if (fastMax(tolerances) >= WARN_TOLERANCE_THREHSOLD) {
    showWarningToast(WARN_TOLERANCE_THREHSOLD_TEXT, { timeout: 10000 });
  }

  return simplifiedFeatures;
};

const useAddFeaturesIntoElementsWithinLimits =
  (): AddFeaturesToElementsReturnType => {
    const { update: updateFeatures } = useProjectElementsCrud();
    const { navigateToPark } = useNavigateToPark();
    const {
      error: showErrorToast,
      info: showInfoToast,
      warning: showWarningToast,
    } = useToast();
    const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
    const parkId = useAtomValue(parkIdAtom);
    const setCurrentExternalLayerSelection = useSetAtom(
      currentExternalLayerSelection,
    );

    const ensureProjectElementWithTypesHaveRequiredFields = useJotaiCallback(
      async (get, _, features: ProjectFeature[]) => {
        // Ensure necessary connections are present before adding to map
        const currentMapFeatures = await get(featuresListAtom);
        const finalFeatures = features.map((feature) =>
          hasNecessaryConnections(feature, currentMapFeatures.concat(features))
            ? feature
            : stripFeatureTypeSpecificFieldsAndConvertToOther(feature),
        );

        const convertedFeaturesCount = finalFeatures.filter(
          (f, i) => f !== features[i],
        ).length;

        if (convertedFeaturesCount > 0) {
          showInfoToast(
            `${convertedFeaturesCount} feature(s) were converted to 'other' type because they were missing necessary connected features.`,
            {
              timeout: 5000,
            },
          );
        }

        return finalFeatures;
      },
      [showInfoToast],
    );

    const addFeaturesToElementsWithinLimits = useCallback(
      async ({
        add,
        remove,
        update,
        successMessage,
        selectAfterCloning = true,
      }: {
        add?: ProjectFeature[];
        remove?: string[];
        update?: ProjectFeature[];
        successMessage?: string;
        selectAfterCloning?: boolean;
      }): Promise<ProjectFeature[]> => {
        try {
          const prepareFeatures = async (
            clone: boolean,
            features?: ProjectFeature[],
          ) => {
            if (!features) return undefined;

            // Process features to ensure parentIds are valid
            const processedFeatures = handleProjectFeaturesParentInvalidity(
              features,
              parkId,
              showInfoToast,
            );

            // Create clones of processed features
            const clones: ProjectFeature[] = clone
              ? createFeatureClones(processedFeatures, parkId)
              : processedFeatures;

            // Ensure necessary connections are present before adding to map
            const finalFeatures =
              await ensureProjectElementWithTypesHaveRequiredFields(clones);

            // Simplify features if necessary
            const simplifiedFeatures = await simplifyFeaturesWithinAblyLimits(
              finalFeatures,
              showInfoToast,
              showWarningToast,
            );

            return simplifiedFeatures;
          };

          const [simplifiedAddedFeatures, simplifiedUpdatedFeatures] =
            await Promise.all([
              prepareFeatures(true, add),
              prepareFeatures(false, update),
            ]);

          updateFeatures({
            add: simplifiedAddedFeatures,
            remove,
            update: simplifiedUpdatedFeatures,
          });

          if (successMessage) {
            showInfoToast(successMessage, { timeout: 2000 });
          }

          if (selectAfterCloning) {
            setCurrentSelectionArray(
              [
                ...(simplifiedAddedFeatures ?? []),
                ...(simplifiedUpdatedFeatures ?? []),
              ].map((f) => f.id),
            );
            const parks = simplifiedAddedFeatures?.filter(isPark);
            if (parks && parks.length > 0) {
              navigateToPark(parks[0].id);
            }
            setCurrentExternalLayerSelection([]);
          }

          return [
            ...(simplifiedAddedFeatures ?? []),
            ...(simplifiedUpdatedFeatures ?? []),
          ];
        } catch (error) {
          console.error("Error in cloneFeatures:", error);
          showErrorToast(
            "An error occurred while cloning features. Please try again.",
            {
              timeout: 5000,
            },
          );
          return [];
        }
      },
      [
        parkId,
        showInfoToast,
        showWarningToast,
        updateFeatures,
        showErrorToast,
        setCurrentSelectionArray,
        setCurrentExternalLayerSelection,
        ensureProjectElementWithTypesHaveRequiredFields,
        navigateToPark,
      ],
    );

    return addFeaturesToElementsWithinLimits;
  };

export default useAddFeaturesIntoElementsWithinLimits;
