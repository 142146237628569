import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import Auth0ProviderWithCallback from "./Auth0ProviderWithCallback";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FullScreenLoader from "./components/FullScreenLoader/FullScreenLoader";
import { ErrorBoundary } from "react-error-boundary";
import { logErrorGlobal } from "./components/ErrorBoundaries/utils";
import ErrorBoundaryGlobalFallback from "./components/ErrorBoundaries/ErrorBoundaryGlobal";
import { GlobalStyle } from "styles/globalStyle";
import { WindowContextProvider } from "components/WindowedHOC/WindowContext";
import { ConfirmDialogProvider } from "components/ConfirmDialog/ConfirmDialog";

// The default typescript types for these functions are very error prone, since they
// return `any`.  This leads to accidentally assuming happy path every time these are used.
// Instead, we patch them to return `unknown` so that you have to parse the result, making
// sure that you know what you're getting.
declare global {
  interface Body {
    json(): Promise<unknown>;
  }

  interface JSON {
    parse(u: unknown): unknown;
  }

  interface ArrayConstructor {
    isArray(a: unknown): a is unknown[];
  }
}

Sentry.init({
  dsn: "https://9e365d10a859468da6400112c36ca11e@o1417537.ingest.sentry.io/6759813",
  integrations: [
    new BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/app\.vind\.ai/,
        /^\/api\//,
      ],
    }),
  ],
  enableTracing: true,
  tracesSampleRate: 1.0,
  environment: ["app.vind.ai", "app.vindtech.com"].includes(
    window.location.host,
  )
    ? "production"
    : "dev",
  enabled: ["app.vind.ai", "app.vindtech.com"].includes(window.location.host),
  release: import.meta.env.VITE_SENTRY_RELEASE,
  attachStacktrace: true,
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: cancelled",
    "Login required",
    "You must provide a featureId to enter direct_select mode",
    "Not authorized",
    /^((Error|LogError|ErrorGlobal): )?Connection closed/,
    /^((Error|LogError|ErrorGlobal): )?Failed to fetch/,
    /^((Error|LogError|ErrorGlobal): )?Timeout/,
    "AbortError: The user aborted a request",
    "AnalysisError",
  ],
});

function resetApp() {
  document.location.reload();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithCallback>
        <WindowContextProvider context={window}>
          <ConfirmDialogProvider>
            <DndProvider backend={HTML5Backend} context={window}>
              <ErrorBoundary
                FallbackComponent={ErrorBoundaryGlobalFallback}
                onError={logErrorGlobal}
                onReset={resetApp}
              >
                <React.Suspense fallback={<FullScreenLoader />}>
                  <GlobalStyle />
                  <App />
                </React.Suspense>
              </ErrorBoundary>
            </DndProvider>
          </ConfirmDialogProvider>
        </WindowContextProvider>
      </Auth0ProviderWithCallback>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
