import { LibraryManageRoleType } from "components/Organisation/Library/types";
import WindTurbineIcon from "@icons/24/WindTurbine.svg?react";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import CableIcon from "@icons/24/Cabling-2.svg?react";
import ExportCableIcon from "@icons/24/ExportCable.svg?react";
import AnalysisIcon from "@icons/24/Statistics.svg?react";
import FinancialIcon from "@icons/24/Cost.svg?react";
import SubstationIcon from "@icons/24/Substation.svg?react";
import DataPackageIcon from "@icons/24/DataPackage.svg?react";

export const getIcon = (type: LibraryManageRoleType) => {
  switch (type) {
    case "org_turbine_manage":
      return <WindTurbineIcon />;
    case "org_foundation_manage":
      return <FoundationIcon />;
    case "org_cable_manage":
      return <CableIcon />;
    case "org_export_cable_manage":
      return <ExportCableIcon />;
    case "org_analysis_manage":
      return <AnalysisIcon />;
    case "org_financial_manage":
      return <FinancialIcon />;
    case "org_substation_manage":
      return <SubstationIcon />;
    case "org_data_package_manage":
      return <DataPackageIcon />;
  }
};
