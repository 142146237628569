const decodeTerrarium = ({ r, g, b }: { r: number; g: number; b: number }) =>
  r * 256.0 + g + b / 256.0 - 32768.0;

export const decodeTerrariumDepth = (rgb: {
  r: number;
  g: number;
  b: number;
}) => decodeTerrarium(rgb) * -1;

export const decodeMapboxTerrain = ({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}) => (r * 256 * 256 + g * 256 + b) * 0.1 - 10000;
