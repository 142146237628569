import { parkWindRoseLayerId, getBeforeLayer } from "components/Mapbox/utils";
import { Feature } from "geojson";
import { useMemo } from "react";
import Point from "components/MapFeatures/Point";
import { displayLabelPropertyName } from "@constants/canvas";
import { SymbolLayer, CirclePaint } from "mapbox-gl";
import { mapAtom } from "state/map";
import { isMultipleSourceWindConfiguration } from "services/windSourceConfigurationService";
import { ProdId, getWindSourceConfiguration } from "analysis/inputs";
import { useAtomValue } from "jotai";
import { getAnalysisWindStats } from "analysis/output";
import { unwrap } from "jotai/utils";

const parkWindRoseSourceId = "park-wind-rose-symbol-source";

const windRoseSymbolLayer: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 9,
  layout: {
    "icon-image": "embassy",
    "icon-size": ["interpolate", ["exponential", 2], ["zoom"], 5, 1, 10, 1],
    "icon-allow-overlap": true,
    // Add text layout properties
    "text-field": ["get", "label"],
    "text-anchor": "top",
    "text-offset": [0, 2],
    "text-size": 8,
  },
  paint: {
    "icon-opacity": 0.8,
    "icon-color": "#ff0000",
    "icon-halo-color": "#fff",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};
const symbolPaint: CirclePaint = {
  "circle-radius": 6,
  "circle-opacity": 0,
};

export const WindRosePositions = ({
  productionId,
}: {
  productionId: ProdId;
}) => {
  const map = useAtomValue(mapAtom);
  const windStats = useAtomValue(unwrap(getAnalysisWindStats(productionId)));
  const windConfig = useAtomValue(
    unwrap(getWindSourceConfiguration(productionId)),
  );
  const dataPointsToShow: Feature[] = useMemo(() => {
    if (isMultipleSourceWindConfiguration(windConfig)) {
      return windConfig.source.data.map((data, index) => {
        return {
          type: "Feature",
          id: `data-point-${index}`,
          properties: {
            label: windStats?.source,
            [displayLabelPropertyName]: true,
          },
          geometry: {
            coordinates: [data.longitude ?? 0, data.latitude ?? 0],
            type: "Point",
          },
        };
      });
    } else {
      return [
        {
          type: "Feature",
          id: "current-wind-rose",
          properties: {
            label: windStats?.source.toUpperCase(),
            [displayLabelPropertyName]: true,
          },
          geometry: {
            coordinates: [windStats?.longitude ?? 0, windStats?.latitude ?? 0],
            type: "Point",
          },
        },
      ];
    }
  }, [windConfig, windStats]);

  if (!map) return null;
  return (
    <Point
      features={dataPointsToShow}
      symbols={windRoseSymbolLayer}
      sourceId={parkWindRoseSourceId}
      layerId={parkWindRoseLayerId}
      map={map}
      paint={symbolPaint}
      beforeLayer={getBeforeLayer(map, parkWindRoseLayerId)}
    />
  );
};
