import { atom, useSetAtom } from "jotai";
import { TourInfo, TourItemId } from "./TourStep";

export const lastEventState = atom<string | null>(null);
export const activeTourState = atom<TourInfo | null | undefined>(null);
export const activeTourStepState = atom<TourItemId | null | undefined>(null);
export const stepOrderIndexMapState = atom<Map<string, number>>(new Map());

export function useTrackEvent() {
  const setLastEvent = useSetAtom(lastEventState);
  return (event: string) => setLastEvent(event);
}
