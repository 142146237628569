import { MooringLineFeature } from "../../types/feature";
import { AnchorFeature } from "../../types/feature";
import { defaultMooringParameters, MooringParameters } from "./types";
import { previewTurbinesState } from "../../state/turbines";
import { atomFamily as AF, atomFromFn } from "utils/jotai";
import { mooringLineTypesByIdFamily } from "state/jotai/mooringLineType";
import { atom } from "jotai";

export const getMooringParametersAtomFamily = AF(
  (_: { foundationId: string }) =>
    atomFromFn<MooringParameters | Promise<MooringParameters>>(async (get) => {
      const lineTypes = await get(mooringLineTypesByIdFamily);
      let lineTypeIds = lineTypes.map((l) => l.id);
      while (lineTypeIds.length < 3) {
        // 3 is the number of mooring line segments
        lineTypeIds.push(lineTypeIds[0]);
      }
      const defaultParams = defaultMooringParameters(lineTypeIds);
      return defaultParams;
    }),
);

type PreviewMooringAndFoundationState = {
  /** The temporary state. */
  preview: {
    foundations: { turbineId: string; foundationId: string }[];
    mooringLines: MooringLineFeature[];
    anchors: AnchorFeature[];
    /**
     * A turbine is "partial" if some of its anchors were not added due to
     * exclusion/park constraints.
     */
    partialTurbines?: string[];
  };
  /**
   * Also list out the existing mooring lines and anchors so that we can
   * visualize them in case we only generate new ones for a part of the park.
   */
  existing: {
    mooringLines: MooringLineFeature[];
    anchors: AnchorFeature[];
  };
};

export const previewMooringAndFoundationState = atom<
  PreviewMooringAndFoundationState | undefined
>(undefined);

/**
 * Mooring preview state that also takes the turbine preview state into
 * account. This is needed for joint generation.
 */
export const jointPreviewMooringState = atom<
  PreviewMooringAndFoundationState | undefined
>((get) => {
  const tp = get(previewTurbinesState);
  const p = get(previewMooringAndFoundationState);
  if (!tp || !p) return p;

  const visibleTurbineIds = new Set(
    tp.existing.map((t) => t.id).concat(tp.preview.map((t) => t.id)),
  );

  const mooringLines = p.existing.mooringLines.filter((ml) =>
    visibleTurbineIds.has(ml.properties.target),
  );
  const connectedAnchorIds = new Set(
    mooringLines.map((ml) => ml.properties.anchor),
  );
  const anchors = p.existing.anchors.filter((a) =>
    connectedAnchorIds.has(a.id),
  );

  return {
    preview: p.preview,
    existing: {
      mooringLines,
      anchors,
    },
  };
});
