import {
  exportCableErrorKilometerPerVoltage,
  exportCableWarningKilometerPerVoltage,
} from "@constants/production";
import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { ValidationWarning } from "state/validationWarnings";
import { atomFamily } from "utils/jotai";
import {
  CableType,
  ExportCableVoltageType,
} from "../../services/cableTypeService";
import { ExportCableFeature } from "../../types/feature";

export function checkExportCablesTooLong(
  exportCables: ExportCableFeature[],
  cableTypes: Map<string, CableType>,
): { veryLong: ExportCableFeature[]; tooLong: ExportCableFeature[] } {
  const tooLong = [];
  const veryLong = [];
  for (const c of exportCables) {
    if (!c.properties.cableTypeId) continue;
    const type = cableTypes.get(c.properties.cableTypeId);
    if (!type) continue;
    const voltage: ExportCableVoltageType = type.voltage;

    const len = turf.length(c, { units: "kilometers" });
    if (exportCableErrorKilometerPerVoltage[voltage] <= len) {
      tooLong.push(c);
      continue;
    }

    if (exportCableWarningKilometerPerVoltage[voltage] <= len) veryLong.push(c);
  }

  return { tooLong, veryLong };
}

export const exportCableTooLongWarningErrorFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExportCableTooLongError>
        | ValidationWarning<ValidationWarningTypes.ExportCableTooLongWarning>
        | undefined
      >
    >(async (get) => {
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const cableTypes = get(
        unwrap(exportCableTypesFamily({ projectId: undefined })),
      );
      if (!cableTypes) return;
      const { tooLong, veryLong } = checkExportCablesTooLong(
        exportCables,
        cableTypes,
      );
      if (tooLong.length)
        return {
          type: ValidationWarningTypes.ExportCableTooLongError,
          featureIds: tooLong.map((f) => f.id),
          parkId: parkId,
        };
      if (veryLong.length)
        return {
          type: ValidationWarningTypes.ExportCableTooLongWarning,
          featureIds: veryLong.map((f) => f.id),
          parkId: parkId,
        };
    }),
);
