import { useAtomValue, useSetAtom } from "jotai";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { organisationIdAtom } from "state/pathParams";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useSyncSelectedItemWithResource = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const params = useParams();
  const selectedItemId = params.selectedItemId;

  useEffect(() => {
    if (selectedItemId) {
      setContent({
        type: "resource",
        id: selectedItemId,
      });
    } else if (!selectedItemId) {
      setContent(undefined);
    }
  }, [setContent, selectedItemId]);
};
