import ExpandIcon from "@icons/14/Link.svg?react";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { Table, fromObjects } from "components/General/Table";
import Tabs from "components/General/Tabs";
import { useAtom } from "jotai";
import { modalTypeOpenAtom } from "state/modal";
import styled from "styled-components";

export const KeyboardShortcutsModalType = "KeyboardShortcutsModal";
const KEYBOARD_SHORTCUTS_URL =
  "https://help.vindtech.com/en/articles/8001417-keyboard-shortcuts";

const Key = styled.div`
  display: inline-block;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background: #f7f7f7;
  box-shadow:
    inset 0 0 0 0.5px white,
    0px 1px #d1d1d1;
`;

const keyboardShortcuts = [
  {
    Command: "Copy",
    Description: "Copies the selected item(s) to the clipboard",
    Shortcut: {
      Windows: (
        <>
          <Key>ctrl</Key> + <Key>C</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⌘</Key> + <Key>C</Key>
        </>
      ),
    },
  },
  {
    Command: "Paste",
    Description: "Pastes the contents of the clipboard",
    Shortcut: {
      Windows: (
        <>
          <Key>ctrl</Key> + <Key>V</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⌘</Key> + <Key>V</Key>
        </>
      ),
    },
  },
  {
    Command: "Delete",
    Description: "Deletes the selected item(s)",
    Shortcut: { Windows: <Key>backspace</Key>, MacOS: <Key>delete</Key> },
  },
  {
    Command: "Multiselect",
    Description: "Selects multiple items",
    Shortcut: {
      Windows: (
        <>
          <Key>ctrl</Key> + <Key>click</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⌘</Key> + <Key>click</Key>
        </>
      ),
    },
  },
  {
    Command: "Select range",
    Description: "Selects multiple items in a range",
    Shortcut: {
      Windows: (
        <>
          <Key>⇧</Key> + <Key>click</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⇧</Key> + <Key>click</Key>
        </>
      ),
    },
  },
  {
    Command: "Multiselect in map",
    Description: " Select multiple features at once",
    Shortcut: {
      Windows: (
        <>
          <Key>⇧</Key> + <Key>click</Key> and drag
        </>
      ),
      MacOS: (
        <>
          <Key>⇧</Key> + <Key>click</Key> and drag
        </>
      ),
    },
  },
  {
    Command: "Avoid snapping",
    Description: "Avoids snapping to other features while drawing",
    Shortcut: {
      Windows: (
        <>
          hold <Key>⇧</Key>
        </>
      ),
      MacOS: (
        <>
          hold <Key>⇧</Key>
        </>
      ),
    },
  },
  {
    Command: "Undo",
    Description: "Undo the last action",
    Shortcut: {
      Windows: (
        <>
          <Key>ctrl</Key> + <Key>Z</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⌘</Key> + <Key>Z</Key>
        </>
      ),
    },
  },
  {
    Command: "Redo",
    Description: "Redo the last undone action",
    Shortcut: {
      Windows: (
        <>
          <Key>⇧</Key> + <Key>ctrl</Key> + <Key>Z</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⇧</Key> + <Key>⌘</Key> + <Key>Z</Key>
        </>
      ),
    },
  },
  {
    Command: "Show tooltips",
    Description: "Shows  tooltips on menus",
    Shortcut: { Windows: <Key>Q</Key>, MacOS: <Key>Q</Key> },
  },
  {
    Command: "Search",
    Description: "Search for features",
    Shortcut: {
      Windows: (
        <>
          <Key>ctrl</Key> + <Key>K</Key>
        </>
      ),
      MacOS: (
        <>
          <Key>⌘</Key> + <Key>K</Key>
        </>
      ),
    },
  },
];

const KeyboardShortcutsModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);

  if (!modalTypeOpen || modalTypeOpen.modalType !== KeyboardShortcutsModalType)
    return null;

  const userAgent = navigator.userAgent.toLowerCase();
  let initialTab = 0;
  if (
    userAgent.includes("darwin") ||
    userAgent.includes("macos") ||
    userAgent.includes("macintosh")
  ) {
    initialTab = 1;
  }

  const shortcutsTable = (os: "Windows" | "MacOS") => {
    const OSShortcuts = fromObjects(
      keyboardShortcuts.map((item) => ({
        ...item,
        Shortcut: item.Shortcut[os],
      })),
    );

    return (
      <Table
        items={OSShortcuts}
        columns={["Shortcut", "Command", "Description"]}
        hideRowNumbers
        style={{
          nohover: true,
          th: { textAlign: "left" },
          td: { textAlign: "left" },
        }}
      ></Table>
    );
  };

  const tabs = [
    { name: "Windows", data: shortcutsTable("Windows") },
    { name: "MacOS", data: shortcutsTable("MacOS") },
  ];

  return (
    <FullScreenModal>
      <ModalFrame
        title="Keyboard shortcuts"
        onExit={() => setModalTypeOpen(undefined)}
        style={{ width: "70rem" }}
      >
        <Column>
          <p>
            You can use keyboard shortcuts to perform certain actions in Vind
            AI, see the full list below.
          </p>
          <Tabs
            initialTab={initialTab}
            menuStyle={{ justifyContent: "flex-start" }}
            tabs={tabs}
          />
          <Row style={{ justifyContent: "space-between" }}>
            <Button
              onClick={() => window.open(KEYBOARD_SHORTCUTS_URL)}
              icon={<ExpandIcon />}
              text={"Open in a new window"}
              buttonType="text"
            />
            <Button
              onClick={() => setModalTypeOpen(undefined)}
              text={"Done"}
              buttonType="primary"
            ></Button>
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default KeyboardShortcutsModal;
