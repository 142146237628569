import { Suspense, useMemo } from "react";
import Spinner from "@icons/spinner/Spinner";
import PolygonTypeSelector from "./PolygonTypeSelector";
import PointTypeSelector from "./PointTypeSelector";
import LineStringTypeSelector from "./LineStringTypeSelector/LineStringTypeSelector";
import {
  ProjectFeature,
  _LineStringFeatureType,
  _PointFeatureType,
  _PolygonFeatureType,
} from "../../../types/feature";
import { isLineStringFeature } from "../../../utils/predicates";
import PositionHint from "../../ActiveTips/PositionHints/PositionHint";
import { changeFeatureHelpHintType } from "../../ActiveTips/PositionHints/ChangeFeatureTypeHelp";
import { Divider } from "components/General/Icons";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "../CanvasSelectOption.style";
import { parseOr } from "../../../utils/zod";
import MultiPolygonTypeSelector from "./MultiPolygonTypeSelector";

export const TypeSelector = ({
  selections,
  setCurrentSelectionArray,
  disabled,
}: {
  selections: ProjectFeature[];
  disabled: boolean;
  setCurrentSelectionArray(ids: string[]): void;
}) => {
  const allFeaturesOfSameType = useMemo(
    () =>
      selections.reduce(
        (previous, f) => {
          const isSameType = f.properties?.type === previous.type;
          const isSameGeometry =
            f.geometry?.type.replace("Multi", "") === previous.baseGeometry;

          return {
            type: f.properties?.type,
            same: isSameType,
            sameGeometry: isSameGeometry,
            baseGeometry: f.geometry?.type.replace("Multi", ""),
            hasMultiGeometry:
              previous.hasMultiGeometry || f.geometry?.type.includes("Multi"),
          };
        },
        {
          type: selections[0].properties.type,
          same: true,
          sameGeometry: true,
          baseGeometry: selections[0].geometry.type.replace("Multi", ""),
          hasMultiGeometry: selections[0].geometry.type.includes("Multi"),
        },
      ),
    [selections],
  );

  const selectedGeometryType =
    allFeaturesOfSameType.same && allFeaturesOfSameType.sameGeometry
      ? allFeaturesOfSameType.hasMultiGeometry
        ? "Multi" + allFeaturesOfSameType.baseGeometry
        : allFeaturesOfSameType.baseGeometry
      : undefined;

  const selectedType =
    allFeaturesOfSameType.same && allFeaturesOfSameType.sameGeometry
      ? allFeaturesOfSameType.type
      : undefined;

  const geometryTypeSelector = useMemo(() => {
    switch (selectedGeometryType) {
      case "MultiPolygon":
        return (
          <MultiPolygonTypeSelector
            selections={selections}
            disabled={disabled}
            selectedType={parseOr(_PolygonFeatureType, selectedType, "other")}
          />
        );
      case "Polygon":
        return (
          <PolygonTypeSelector
            selections={selections}
            setCurrentSelectionArray={setCurrentSelectionArray}
            disabled={disabled}
            selectedType={parseOr(_PolygonFeatureType, selectedType, "other")}
          />
        );
      case "Point":
        return (
          <PointTypeSelector
            selections={selections}
            disabled={disabled}
            selectedType={parseOr(_PointFeatureType, selectedType, "other")}
          />
        );
      case "LineString":
        return (
          <LineStringTypeSelector
            selections={selections.filter(isLineStringFeature)}
            disabled={disabled}
            selectedType={parseOr(
              _LineStringFeatureType,
              selectedType,
              "other",
            )}
          />
        );
      default:
        return null;
    }
  }, [
    disabled,
    selectedType,
    selections,
    setCurrentSelectionArray,
    selectedGeometryType,
  ]);

  if (!geometryTypeSelector) return null;

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <PositionHint
          allowedHints={[changeFeatureHelpHintType]}
          position={"top"}
        >
          <NamedTooltipContainer>
            <NamedTooltipWrapper>Type</NamedTooltipWrapper>
            {geometryTypeSelector}
          </NamedTooltipContainer>
        </PositionHint>
      </Suspense>
      <Divider />
    </>
  );
};
