import { useEffect } from "react";
import { turbineSourceId } from "components/Mapbox/constants";
import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { foundationTypesAtom } from "state/jotai/foundation";
import { turbinesFamily } from "state/jotai/turbine";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";

/** If `foundationId` is set, it should be the `id` of an existing foundation type. */
export const foundationTypeInvalidWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.FoundationTypeInvalid>
        | undefined
      >
    >(async (get) => {
      const foundationTypes = await get(foundationTypesAtom);
      const turbines = await get(turbinesFamily({ branchId }));
      const invalids = turbines.filter(
        (t) =>
          t.properties.foundationId &&
          !foundationTypes.has(t.properties.foundationId),
      );
      if (invalids.length === 0) return;
      return {
        type: ValidationWarningTypes.FoundationTypeInvalid,
        featureIds: invalids.map((t) => t.id),
      };
    }),
);

export const FoundationTypeInvalidJotai = ({
  map,
  source = turbineSourceId,
}: {
  map: mapboxgl.Map;
  source?: string;
}) => {
  const warning = useAtomValue(
    foundationTypeInvalidWarningFamily({ branchId: undefined }),
  );

  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { overlap: true });

    return () => {
      if (map.getSource(source))
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [warning, map, source]);

  return null;
};
