import { Subtitle } from "components/General/GeneralSideModals.style";
import {
  HorizontalLine,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import ReferenceHubHeight from "./ReferenceHubHeight";
import ReferenceRatedPower from "./ReferenceRatedPower";
import ReferenceRNAWeight from "./ReferenceRNAWeight";
import ReferenceRotorDiameter from "./ReferenceRotorDiameter";
import { ReferenceNumbersProps } from "./ReferenceNumberProps";

export const ReferenceNumbersTitle = () => {
  return (
    <TitleContainer>
      <Subtitle>Reference numbers</Subtitle>
      <HorizontalLine />
    </TitleContainer>
  );
};

const CommonReferenceNumbers = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceNumbersProps) => {
  return (
    <>
      <ReferenceRatedPower
        disabled={disabled}
        tempFoundation={tempFoundation}
        originalFoundation={originalFoundation}
        setTempFoundation={setTempFoundation}
        nodeId={nodeId}
        commentCategory={commentCategory}
      />
      <ReferenceRotorDiameter
        disabled={disabled}
        tempFoundation={tempFoundation}
        originalFoundation={originalFoundation}
        setTempFoundation={setTempFoundation}
        nodeId={nodeId}
        commentCategory={commentCategory}
      />
      <ReferenceHubHeight
        disabled={disabled}
        tempFoundation={tempFoundation}
        originalFoundation={originalFoundation}
        setTempFoundation={setTempFoundation}
        nodeId={nodeId}
        commentCategory={commentCategory}
      />
      <ReferenceRNAWeight
        disabled={disabled}
        tempFoundation={tempFoundation}
        originalFoundation={originalFoundation}
        setTempFoundation={setTempFoundation}
        nodeId={nodeId}
        commentCategory={commentCategory}
      />
    </>
  );
};

export default CommonReferenceNumbers;
