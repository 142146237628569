import { colorBrewerColors } from "business/style/colors";
import { MenuItemDiv } from "components/General/Menu";
import { typography } from "styles/typography";
import { partition } from "utils/utils";
import { GradientComp } from "./ColorRange";
import { Gradient } from "lib/colors";

export const ColorRangeMenu = ({
  onClick,
}: {
  onClick: (c: Gradient) => void;
}) => {
  const [diverging, sequential] = partition(
    colorBrewerColors,
    (c) => c.type === "div",
  );

  return (
    <>
      <MenuItemDiv nohover style={typography.sub3}>
        Sequential
      </MenuItemDiv>
      {sequential.map((colors, i) => (
        <MenuItemDiv
          key={i}
          onClick={() => onClick(Gradient.fromBalanced(colors[9], 0, 1))}
        >
          <GradientComp gradient={Gradient.fromBalanced(colors[9], 0, 1)} />
        </MenuItemDiv>
      ))}
      <MenuItemDiv nohover style={typography.sub3}>
        Diverging
      </MenuItemDiv>
      {diverging.map((colors, i) => (
        <MenuItemDiv
          key={i}
          onClick={() => onClick(Gradient.fromBalanced(colors[9], 0, 1))}
        >
          <GradientComp gradient={Gradient.fromBalanced(colors[9], 0, 1)} />
        </MenuItemDiv>
      ))}
    </>
  );
};
