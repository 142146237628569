import { defaultIACables } from "../constants/cabling";
import {
  CABLE_POWER_RATING_WATT_MAX,
  CABLE_POWER_RATING_WATT_MIN,
  CableType,
  IAVoltageType,
} from "services/cableTypeService";
import { z } from "zod";
import { CostUnit } from "./financial";

export class CablesNotLinearError extends Error {
  constructor() {
    super("Cables does not form a single array from the substation.");
    this.name = "Cables are not in arrays";
  }
}

export const wattToMegawatt = (watt: number) => watt / 1e6;

export const megawattToWatt = (megawatt: number) => megawatt * 1e6;

export const POWER_RATING_MW_MIN = wattToMegawatt(CABLE_POWER_RATING_WATT_MIN);
export const POWER_RATING_MW_MAX = wattToMegawatt(CABLE_POWER_RATING_WATT_MAX);
export const VOLTAGE_DEFAULT = IAVoltageType.kV66;
const RESISTANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].resistance;
const REACTANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].reactance;
const CAPACITANCE_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].capacitance;
const AMPACITY_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].ampacity;
const LAMBDA1_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].lambda1;
const LAMBDA2_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].lambda2;
const DIELECTRIC_LOSS_FACTOR_DEFAULT =
  defaultIACables[IAVoltageType.kV66]["Cu 800mm2 66kV"].dielectricLossFactor;

export const MAX_POWER_RATING_DEVIATION = 0.2;

export const DEFAULT_OFFSHORE_CABLE: Partial<CableType> = {
  name: "Cu 800mm2 66kV",
  voltage: VOLTAGE_DEFAULT,
  resistance: RESISTANCE_DEFAULT,
  reactance: REACTANCE_DEFAULT,
  capacitance: CAPACITANCE_DEFAULT,
  ampacity: AMPACITY_DEFAULT,
  lambda1: LAMBDA1_DEFAULT,
  lambda2: LAMBDA2_DEFAULT,
  dielectricLossFactor: DIELECTRIC_LOSS_FACTOR_DEFAULT,
  cost: 0.3,
  costUnit: CostUnit.millionEuroPerKM,
};

export const DEFAULT_ONSHORE_CABLE: Partial<CableType> = {
  name: "Cu 3x185mm2 33kV",
  voltage: IAVoltageType.kV33,
  ...defaultIACables[IAVoltageType.kV33]["Cu 3x185mm2 33kV"],
  cost: 0.1,
  costUnit: CostUnit.millionEuroPerKM,
};

const cableLevel = ["project", "library"] as const;

export const _CableLevel = z.enum(cableLevel);

type CableLevel = z.infer<typeof _CableLevel>;
export type CableTypeWithLevel = {
  level: CableLevel;
  cable: CableType;
};
