import { trackGlobalError } from "./service";
import { scream } from "../../utils/sentry";
import { ErrorInfo } from "react";

class LogError extends Error {
  name = "LogError";
  static clone(error: Error) {
    const newError = new LogError(error.message);
    newError.stack = error.stack;
    newError.cause = error.cause;
    return newError;
  }
}

class ErrorGlobal extends Error {
  name = "ErrorGlobal";
  static clone(error: Error) {
    const newError = new ErrorGlobal(error.message);
    newError.stack = error.stack;
    newError.cause = error.cause;
    return newError;
  }
}

export const logError = (errorType: string, error: Error, info: ErrorInfo) => {
  const logError = LogError.clone(error);
  let errorResponseInfo;
  if (error instanceof Response) {
    errorResponseInfo = {
      message: error.message,
      status: error.status,
      url: error.url,
    };
  }
  scream(logError, { error, errorResponseInfo });
  trackGlobalError({ errorType, error, errorInfo: info });
};

export const logErrorGlobal = (error: Error, info: ErrorInfo) => {
  const globalError = ErrorGlobal.clone(error);
  let errorResponseInfo;
  if (error instanceof Response) {
    errorResponseInfo = {
      message: error.message,
      status: error.status,
      url: error.url,
    };
  }

  scream(globalError, {
    error,
    errorResponseInfo,
  });
  trackGlobalError({ errorType: "global", error, errorInfo: info });
};
