import { useCallback } from "react";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import {
  portfolioItemsSimpleAtom,
  mappedPortfolioItemsStoreAtom,
  portfolioRefreshAtom,
} from "state/portfolio";
import { useToast } from "hooks/useToast";
import { sendWarning } from "utils/sentry";
import { fetchEnhancerWithToken } from "services/utils";
import {
  capitalize,
  commaSeparateArrayAndEndWithAnd as commaSeparateArrayEndWithAnd,
} from "utils/utils";
import { useJotaiCallback } from "utils/jotai";
import { parkFamily } from "state/jotai/park";
import { useAtomValue, useSetAtom } from "jotai";
import { unwrap } from "jotai/utils";
import { z } from "zod";

const _Parks = z.object({
  parkId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
  parkName: z.string(),
});

const addParkToPortfolio = (
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId: string,
) => {
  return fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}/${projectId}/${branchId}/${parkId}`,
    {
      method: "PUT",
    },
  );
};

const removeParkFromPortfolio = (
  organisationId: string,
  projectId: string,
  branchId: string,
  parkId: string,
) => {
  return fetchEnhancerWithToken(
    `/api/portfolio/${organisationId}/${projectId}/${branchId}/${parkId}`,
    {
      method: "DELETE",
    },
  );
};

const usePortfolioService = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const _projectId = useAtomValue(projectIdAtom);
  const _branchId = useAtomValue(branchIdAtom);
  const { success: showSuccess, error: showError } = useToast();
  const setPortfolioRefresh = useSetAtom(portfolioRefreshAtom);

  const refreshPortfolio = useCallback(() => {
    setPortfolioRefresh((curr) => curr + 1);
  }, [setPortfolioRefresh]);

  const getParkName = useJotaiCallback(
    async (get, _, parkId: string, branchId: string) => {
      const park = await get(parkFamily({ parkId, branchId }));
      return park?.properties.name;
    },
    [],
  );

  const create = useJotaiCallback(
    async (
      get,
      set,
      parks: {
        parkId: string;
        parkName: string;
        projectId?: string;
        branchId?: string;
      }[],
    ) => {
      const enrichedParksParsed = _Parks.array().safeParse(
        parks.map((park) => {
          return {
            ...park,
            projectId: park.projectId ?? _projectId,
            branchId: park.branchId ?? _branchId,
          };
        }),
      );
      if (!organisationId || !enrichedParksParsed.success) {
        sendWarning("usePortfolioService.remove: something was undefined", {
          organisationId,
          parks,
        });
        return;
      }

      const parksToAdd = enrichedParksParsed.data;

      const fallbackPortfolioItemsSimple = await get(portfolioItemsSimpleAtom);
      try {
        set(
          portfolioItemsSimpleAtom,
          fallbackPortfolioItemsSimple.concat(
            parksToAdd.map((p) => ({
              projectId: p.projectId,
              branchId: p.branchId,
              parkId: p.parkId,
            })),
          ),
        );
        await Promise.all(
          parksToAdd.map((p) =>
            addParkToPortfolio(
              organisationId,
              p.projectId,
              p.branchId,
              p.parkId,
            ),
          ),
        );
        refreshPortfolio();

        showSuccess(
          `${commaSeparateArrayEndWithAnd(parksToAdd.map((p) => capitalize(p.parkName)))} was added to the organisation portfolio.`,
          {
            timeout: 5000,
          },
        );
      } catch (error) {
        showError(
          `Something went wrong when adding ${commaSeparateArrayEndWithAnd(parksToAdd.map((p) => capitalize(p.parkName)))} to the organisation portfolio. Please try again.`,
          {
            timeout: 5000,
          },
        );
        set(portfolioItemsSimpleAtom, fallbackPortfolioItemsSimple);
      }
    },
    [
      organisationId,
      _projectId,
      _branchId,
      refreshPortfolio,
      showSuccess,
      showError,
    ],
  );

  const remove = useJotaiCallback(
    async (
      get,
      set,
      parkId: string,
      projectId: string | undefined = _projectId,
      branchId: string | undefined = _branchId,
    ) => {
      if (!organisationId || !parkId || !projectId || !branchId) {
        sendWarning("usePortfolioService.remove: something was undefined", {
          organisationId,
          parkId,
          projectId,
          branchId,
        });
        return;
      }
      const parkName = (await getParkName(parkId, branchId)) ?? "the park";

      const fallbackPortfolioItemsSimple = await get(portfolioItemsSimpleAtom);
      const fallbackPortfolioItemsStore = await get(
        mappedPortfolioItemsStoreAtom,
      );

      try {
        set(
          portfolioItemsSimpleAtom,
          fallbackPortfolioItemsSimple.filter((item) => {
            return (
              item.parkId !== parkId ||
              item.branchId !== branchId ||
              item.projectId !== projectId
            );
          }),
        );
        set(
          mappedPortfolioItemsStoreAtom,
          fallbackPortfolioItemsStore.filter((item) => {
            return (
              item.park.id !== parkId ||
              item.branchId !== branchId ||
              item.project.id !== projectId
            );
          }),
        );
        await removeParkFromPortfolio(
          organisationId,
          projectId,
          branchId,
          parkId,
        );
        showSuccess(
          `${capitalize(
            parkName,
          )} was removed from the organisation portfolio.`,
          {
            timeout: 5000,
          },
        );
      } catch (error) {
        showError(
          `Something went wrong when removing ${parkName} from the organisation portfolio. Please try again.`,
          {
            timeout: 5000,
          },
        );
        set(portfolioItemsSimpleAtom, fallbackPortfolioItemsSimple);
        set(mappedPortfolioItemsStoreAtom, fallbackPortfolioItemsStore);
      }
    },
    [
      showError,
      organisationId,
      _projectId,
      _branchId,
      getParkName,
      showSuccess,
    ],
  );

  const isPartOfPortfolio = useJotaiCallback(
    (get, _, parkId: string) => {
      const portfolioItems = get(unwrap(portfolioItemsSimpleAtom));
      if (!portfolioItems) return false;
      return portfolioItems.some(
        (item) =>
          item.projectId === _projectId &&
          item.branchId === _branchId &&
          item.parkId === parkId,
      );
    },
    [_branchId, _projectId],
  );

  return {
    create,
    remove,
    isPartOfPortfolio,
    refreshPortfolio,
    organisationId,
  };
};

export default usePortfolioService;
