import React, { useMemo } from "react";

import {
  Dashboard,
  WidgetCategory,
  WidgetId,
  widgetCategories,
  widgetIdsByCategoryAtom,
} from "components/Dashboard/types";
import { useDashboard } from "components/Dashboard/hooks";
import SelectAttributesGeneral from "components/General/SelectAttributesGeneral";
import { atom, useAtom, useAtomValue } from "jotai";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import ElectricalIcon from "@icons/24/SideBarIcons/Electrical.svg";
import FinancialIcon from "@icons/24/Financial.svg";
import FoundationIcon from "@icons/24/Foundation.svg";
import QuestionIcon from "@icons/24/Question.svg";
import MooringIcon from "@icons/24/Anchor.svg?react";
import TurbineIcon from "@icons/24/Turbine.svg?react";
import { ReactNode } from "react";

const attributeKeyToIcon: Record<string, ReactNode> = {
  Park: <ParkIcon />,
  Yield: <TurbineIcon />,
  Electrical: <ElectricalIcon />,
  Cost: <FinancialIcon />,
  Foundation: <FoundationIcon />,
  Mooring: <MooringIcon />,
  Other: <QuestionIcon />,
};

export const isSelectWidgetsOpen = atom<boolean>(false);

type Transformed = Record<
  string,
  { name: string; attributes: { key: string; name: string }[] }
>;
const transformCategories = (
  categories: Map<WidgetCategory, WidgetId[]>,
): Transformed => {
  return widgetCategories.reduce<Transformed>((acc, categoryId) => {
    const widgetIds = categories.get(categoryId);
    if (!widgetIds || widgetIds.length === 0) return acc;
    acc[categoryId] = {
      name: categoryId,
      attributes: widgetIds.map((widgetId) => ({
        key: widgetId,
        name: widgetId,
      })),
    };
    return acc;
  }, {});
};

const SelectWidgets = ({
  dashboard,
  canEdit,
}: {
  dashboard: Dashboard;
  canEdit: boolean;
}) => {
  const [isWidgetsOpen, setIsWidgetsOpen] = useAtom(isSelectWidgetsOpen);
  const activeWidgets = useMemo(
    () =>
      dashboard.rows.flatMap((r) => r.elements).map((e) => e.id as WidgetId),
    [dashboard],
  );
  const { removeWidget, addWidget } = useDashboard(dashboard.id);

  const widgets = useAtomValue(widgetIdsByCategoryAtom);

  const transformedCategories = useMemo(
    () => transformCategories(widgets),
    [widgets],
  );

  const activeWidgetsInCategories = useMemo(() => {
    return [...widgets.entries()].reduce(
      (acc, [category, widgets]) => {
        acc[category] = widgets.filter((widgetId) =>
          activeWidgets.includes(widgetId),
        );
        return acc;
      },
      {} as Record<string, WidgetId[]>,
    );
  }, [activeWidgets, widgets]);

  return (
    <SelectAttributesGeneral
      isOpen={isWidgetsOpen}
      setIsOpen={setIsWidgetsOpen}
      title="Select widgets"
      categories={transformedCategories}
      selectedKeys={activeWidgetsInCategories}
      onSelectionChange={async (categoryKey, newKeys) => {
        const currentKeys = [...(activeWidgetsInCategories[categoryKey] || [])];
        const newWidgetIds = newKeys.map((key) => key as WidgetId);

        const keysToAdd = newWidgetIds.filter(
          (key) => !currentKeys.includes(key),
        );

        const keysToRemove = currentKeys.filter(
          (key) => !newWidgetIds.includes(key),
        );

        if (keysToAdd.length > 0) {
          await addWidget(keysToAdd);
        }

        if (keysToRemove.length > 0) {
          await removeWidget(keysToRemove);
        }
      }}
      right={true}
      canEdit={canEdit}
      getCategoryIcon={(key) => attributeKeyToIcon[key]}
    />
  );
};

export default SelectWidgets;
