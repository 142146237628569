import { displayLabelPropertyName } from "@constants/canvas";
import { stylingLabelSelector } from "business/style/state";
import { SymbolLayout } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { AnchorFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_MOORING_VISIBLE_ZOOM,
  anchorLayerId,
  anchorSourceId,
  anchorSymbolLayerId,
} from "./constants";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { useAtomValue } from "jotai";
import { anchorStyleAtom } from "business/style/feature/anchor";
import {
  useAugmentFeaturesWithColorValues,
  useUpdateLayerLayout,
  useUpdateLayerStyle,
} from "business/style/hooks";
import { useAtomUnwrap } from "utils/jotai";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const anchorLabel: SymbolLayout = {
  "text-offset": [0, -1.4],
  "text-size": 10,
  "text-anchor": "center",
};

export const RenderAnchors = ({
  anchors,
  map,
  showSelectionHighlighter,
}: {
  anchors: AnchorFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => anchors.map((f) => f.id), [anchors]);

  useEffect(() => {
    map.addSource(anchorSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: anchorLayerId,
      type: "circle",
      source: anchorSourceId,
      paint: {},
      minzoom: MIN_MOORING_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: anchorSymbolLayerId,
      source: anchorSourceId,
      type: "symbol",
      minzoom: 11,
      layout: anchorLabel,
      filter: ["boolean", ["get", displayLabelPropertyName], true],
    });

    return () => {
      safeRemoveLayer(map, anchorSymbolLayerId);
      safeRemoveLayer(map, anchorLayerId);
      map.removeSource(anchorSourceId);
    };
  }, [map]);

  const paint = useAtomValue(anchorStyleAtom)?.circle;
  useUpdateLayerStyle(map, anchorLayerId, paint);
  const layout = useAtomUnwrap(stylingLabelSelector("anchors"));
  useUpdateLayerLayout(map, anchorSymbolLayerId, layout?.layout);

  const augmented = useAugmentFeaturesWithColorValues("anchors", anchors);

  useEffect(() => {
    const source = map.getSource(anchorSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(augmented),
    });
  }, [map, augmented]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={anchorSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
