import { _SimpleTurbineType } from "./../../../types/turbines";
import { _FoundationType } from "types/foundations";
import { z } from "zod";
import * as librarySpec from "../../../api/library";
import { _AnalysisConfiguration } from "services/configurationService";
import { _CableType } from "services/cableTypeService";
import { _CostConfiguration } from "services/costService";
import { _DataLibraryPackage } from "./dataLibrary/types";
import { _SubstationType } from "services/substationService";

export const {
  NodeLibraryResource: _NodeLibraryResource,
  LibraryResource: _LibraryResource,
  LibraryResourceType: _LibraryResourceType,
  UploadedVectorDataLayer: _UploadedVectorDataLayer,
} = librarySpec.components.schemas;

export type NodeLibraryResource = z.infer<typeof _NodeLibraryResource>;

export type LibraryResourceType = z.infer<typeof _LibraryResourceType>;

export const _NodeFoundationAccess = z.object({
  nodeId: z.string(),
  foundation: _FoundationType,
});

export type NodeFoundationAccess = z.infer<typeof _NodeFoundationAccess>;

export const _NodeTurbineAccess = z.object({
  nodeId: z.string(),
  turbine: _SimpleTurbineType,
});

export type NodeTurbineAccess = z.infer<typeof _NodeTurbineAccess>;

export const _NodeAnalysisAccess = z.object({
  nodeId: z.string(),
  config: _AnalysisConfiguration,
});

export type NodeAnalysisAccess = z.infer<typeof _NodeAnalysisAccess>;
export const _NodeCableAccess = z.object({
  nodeId: z.string(),
  cable: _CableType,
});

export type NodeCableAccess = z.infer<typeof _NodeCableAccess>;

export const _NodeFinancialAccess = z.object({
  nodeId: z.string(),
  config: _CostConfiguration,
});

export type NodeFinancialAccess = z.infer<typeof _NodeFinancialAccess>; //TODO: skal denne fra en yaml?

export const _NodeDataPackageAccess = z.object({
  nodeId: z.string(),
  config: _DataLibraryPackage,
});

export type NodeDataPackageAccess = z.infer<typeof _NodeDataPackageAccess>;

export type UploadedVectorDataLayer = z.infer<typeof _UploadedVectorDataLayer>;

export const _NodeSubstationAccess = z.object({
  nodeId: z.string(),
  substation: _SubstationType,
});

export type NodeSubstationAccess = z.infer<typeof _NodeSubstationAccess>;

export type Order = "NONE" | "ASC" | "DESC";
export type Field = null | "name";
export type SortState = {
  field: Field;
  order: Order;
};

export enum LibraryManageRole {
  TURBINE = "org_turbine_manage",
  SUBSTATION = "org_substation_manage",
  FOUNDATION = "org_foundation_manage",
  CABLE = "org_cable_manage",
  EXPORT_CABLE = "org_export_cable_manage",
  ANALYSIS = "org_analysis_manage",
  FINANCIAL = "org_financial_manage",
  DATA_PACKAGE = "org_data_package_manage",
}

export const LibraryManageRoleType = z.nativeEnum(LibraryManageRole);
export type LibraryManageRoleType = z.infer<typeof LibraryManageRoleType>;
