import { atom } from "jotai";
import { FillPaint, LinePaint } from "mapbox-gl";
import { colorValuesSelectorFamily } from "../state";
import { isOnshoreAtom } from "state/onshore";
import { Style } from "../types";
import { Color } from "lib/colors";
import { colors } from "styles/colors";
import { atomFamily } from "utils/jotai";
import { parksFamily } from "state/jotai/park";
import { isNever } from "utils/utils";
import { scream } from "utils/sentry";
import { z } from "zod";
import { Coloring } from "../types";
import { STYLE_GROUP_KEY } from "../constants";

export const _ParkColorKey = z.enum(["single", "group"]);
export const _ParkLabel = z.enum(["name", "group"]);
type ParkColorKey = z.infer<typeof _ParkColorKey>;
export const parkColorKeys = _ParkColorKey.options;
type ParkLabel = z.infer<typeof _ParkLabel>;
export const parkLabels = _ParkLabel.options;
export type StylePark = {
  feature: "parks";
} & Coloring<ParkColorKey> & { label?: ParkLabel };

export const defaultParkStyle = atom<Style>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    id: "default-style-park",
    defaultMarker: true,
    feature: "parks",
    source: "single",
    type: "single",
    name: "Single color",
    color: Color.fromHex(onshore ? colors.onElParkSurface : colors.park),
    createdAt: 0,
    label: "name",
  };
});

export const parkValuesSelectorFamily = atomFamily(
  (colorKey: ParkColorKey | ParkLabel) =>
    atom<Promise<Map<string, string | number> | undefined>>(async (get) => {
      const branchId = undefined;
      const parks = await get(parksFamily({ branchId }));
      if (colorKey === "single") {
        return new Map(parks.map((a) => [a.id, 0]));
      } else if (colorKey === "name") {
        return new Map(parks.map((a) => [a.id, a.properties.name ?? "Park"]));
      } else if (colorKey === "group") {
        return new Map(
          parks.map((p) => [p.id, String(p.properties[STYLE_GROUP_KEY] ?? "")]),
        );
      }
      isNever(colorKey);
      throw scream(new Error(`Illegal \`valueName\`: "${colorKey}"`));
    }),
);

export const parkStylesAtom = atom<
  Promise<{
    fill: FillPaint;
    line: LinePaint;
  }>
>(async (get) => {
  const { color } = await get(colorValuesSelectorFamily("parks"));

  return {
    fill: {
      "fill-color": color,
    },
    line: {
      "line-color": color,
    },
  };
});
