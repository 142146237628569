import { useToast } from "./useToast";
import { Stage, updateStages } from "services/stagesService";
import { useJotaiCallback } from "utils/jotai";
import { stagesFamily } from "state/jotai/stages";
import { aset } from "utils/jotai";
import { scream } from "utils/sentry";

export function useStageCrud(organisationId: string) {
  const { error: showError } = useToast();

  const updateLocal = useJotaiCallback(
    (get, set, stages: Stage[]) => {
      aset(get, set, stagesFamily({ organisationId }), () => {
        return stages;
      });
    },
    [organisationId],
  );

  const update = useJotaiCallback(
    async (get, set, stages: Stage[]) => {
      const fallback = await get(stagesFamily({ organisationId }));
      try {
        await updateStages(organisationId, stages);
        updateLocal(stages);
      } catch (e) {
        aset(get, set, stagesFamily({ organisationId }), () => fallback);
        showError("Failed to update stages");
        scream(e as Error);
      }
    },
    [organisationId, updateLocal, showError],
  );

  return {
    update,
  };
}
