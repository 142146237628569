import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { typography } from "styles/typography";

const Text = styled.p`
  ${typography.caption}
  // Target only the first letter of the paragraph
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const EntryHeader = styled(Text)``;

export const DateText = styled(Text)`
  color: ${colors.textSecondary};
`;
