import { z } from "zod";
import {
  WindData,
  WindDataVortex,
  MeanSpeedGridCustom,
  WindDataMultipleHeights,
  WRG,
} from "../state/jotai/windStatistics";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
  fetchWithToken,
} from "./utils";

import { scream } from "../utils/sentry";
import { DesignToolMode } from "types/map";
import * as spec from "../api/configurations";

export const _WakeModel = z.union([
  z.literal("jensen"),
  z.literal("turbopark"),
]);
export type WakeModel = z.infer<typeof _WakeModel>;

export const _OptimizeMethod = z.union([
  z.literal("regular"),
  z.literal("irregular"),
  z.literal("micrositing"),
  z.literal("exploration"),
]);
export type OptimizeMethod = z.infer<typeof _OptimizeMethod>;

export const _OptimizeObjective = z.union([
  z.literal("aep"),
  z.literal("lcoe"),
]);
export type OptimizeObjective = z.infer<typeof _OptimizeObjective>;

export const _OptimizeCostInput = z.object({
  turbineCostPerMW: z.number(),
  foundationCostPerTon: z.number(),
  cableCostPerMW: z.number(),
  substationCostPerMW: z.number(),
  exportCableCostPerMW: z.number(),
  devexPerMW: z.number(),
  decomPerMW: z.number(),
  opexPerYearPerMW: z.number(),
  lifetime: z.number(),
  discountRate: z.number(),
});
export type OptimizeCostInput = z.infer<typeof _OptimizeCostInput>;

export const _OptimizeRuntime = z.union([
  z.literal("fast"),
  z.literal("default"),
]);
export type OptimizeRuntime = z.infer<typeof _OptimizeRuntime>;

const _AnalysisPrecision = z.union([
  z.literal("high"),
  z.literal("default"),
  z.literal("fast"),
]);
export type AnalysisPrecision = z.infer<typeof _AnalysisPrecision>;

export const WAKE_MODEL_NAME: Record<WakeModel, string> = {
  jensen: "Jensen (Park 2)",
  turbopark: "TurbOPark",
};

export const _WakeAnalysisConfiguration = z.object({
  wakeModel: _WakeModel,
  wakeExpansionFactor: z.number(),
  turbulenceIntensity: z.number(),
  turboparkWakeExpansionCalibration: z.number(),
  blockage: z.boolean(),
  neighbourWakeMaxDistance: z.number().default(20),
  neighbourWake: z.boolean().default(true),
  density: z.enum(["mean_at_location"]).or(z.number()),
  precision: _AnalysisPrecision.default("default"),
});
export type WakeAnalysisConfiguration = z.infer<
  typeof _WakeAnalysisConfiguration
>;

const _EnergyLoss = spec.components.schemas.EnergyLoss;
const _ElectricalConfig = z.object({
  interArrayCableLoss: z.boolean().default(false),
  turbineTrafoLoss: z.boolean().default(false),
  cableLengthContingencyEnabled: z.boolean().default(false),
  cableLengthContingency: z.number().default(0),
  exportSystemLoss: z.boolean().optional().default(false), //remove the optional when this is released
});
export type EnergyLoss = z.infer<typeof _EnergyLoss>;
export type ElectricalConfig = z.infer<typeof _ElectricalConfig>;

export const _AnalysisConfiguration = spec.components.schemas.Configuration;

export type AnalysisConfiguration = z.infer<typeof _AnalysisConfiguration>;

const _WindDataReference = z.object({
  file: z.object({
    id: z.string(),
    name: z.string(),
  }),
  data: z.object({
    lon: z.number().optional(),
    lat: z.number().optional(),
    type: z.string().optional(),
  }),
});

type WindDataReference = z.infer<typeof _WindDataReference>;
export const _AnalysisConfigurationUsageType = z.object({
  analysisConfigurationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
});
export type AnalysisConfigurationUsageType = z.infer<
  typeof _AnalysisConfigurationUsageType
>;

export async function listAnalysisConfigurations(nodeId: string) {
  return fetchEnhancerWithToken(`/api/configuration/node/${nodeId}`, {
    method: "get",
  });
}

// --------- Organisation analysis configuration ------------------------

export async function listOrgAnalysisConfigurations(organisationId: string) {
  return fetchSchemaWithToken(
    _AnalysisConfiguration.array(),
    `/api/configuration/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
}

export async function updateOrgAnalysisConfiguration(
  organisationId: string,
  configuration: AnalysisConfiguration,
) {
  return fetchEnhancerWithToken(
    `/api/configuration/organisation/${organisationId}/config/${configuration.id}`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed saving configuration");
  });
}

export async function createOrgAnalysisConfigurationWithValues(
  organisationId: string,
  configuration: AnalysisConfiguration,
  projectAccess?: string[],
) {
  return await fetchSchemaWithToken(
    _AnalysisConfiguration,
    `/api/configuration/organisation/${organisationId}/config/new`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ configuration, projectAccess }),
    },
  );
}

export async function deleteOrgAnalysisConfiguration(
  organisationId: string,
  configId: string,
) {
  return fetchWithToken(
    `/api/configuration/organisation/${organisationId}/config/${configId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
// --------- Project analysis configuration ------------------------

export async function updateAnalysisConfiguration(
  nodeId: string,
  projectType: DesignToolMode,
  configuration: AnalysisConfiguration,
) {
  return fetchEnhancerWithToken(
    `/api/configuration/node/${nodeId}/config/${configuration.id}?project_type=${projectType}`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed saving configuration");
  });
}

const _WindDataGetUrlResponse = z.object({
  id: z.string(),
  uploadedAt: z.number(),
  url: z.string().url(),
  fields: z.record(z.string(), z.string()),
});

type WindDataGetUrlResponse = z.infer<typeof _WindDataGetUrlResponse>;

export async function deleteAnalysisConfiguration(
  nodeId: string,
  configId: string,
) {
  return fetchWithToken(
    `/api/configuration/node/${nodeId}/config/${configId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}
export async function createAnalysisConfigurationWithValues(
  nodeId: string,
  projectType: DesignToolMode,
  configuration: AnalysisConfiguration,
) {
  return await fetchSchemaWithToken(
    _AnalysisConfiguration,
    `/api/configuration/node/${nodeId}/config/new?project_type=${projectType}`,
    {
      method: "post",
      body: JSON.stringify(configuration),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function createAnalysisConfiguration(
  nodeId: string,
  projectType: DesignToolMode,
) {
  const config = await fetchSchemaWithToken(
    _AnalysisConfiguration,
    `/api/configuration/node/${nodeId}/config?project_type=${projectType}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return config;
}

export async function saveWindData(
  nodeId: string,
  data:
    | WindData
    | WindDataVortex
    | MeanSpeedGridCustom
    | WindDataMultipleHeights
    | WRG,
  fileName: string,
  fileType: string,
): Promise<WindDataReference> {
  const signedUrlResponse = await getWindDataPresignedUrl(nodeId);

  const formData = new FormData();
  Object.entries(signedUrlResponse.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  const blob = new Blob([JSON.stringify(data)], {
    type: "application/json",
  });

  formData.append("file", blob);

  const uploadFileResponse = await fetch(signedUrlResponse.url, {
    method: "post",
    body: formData,
  });

  if (uploadFileResponse.status !== 204) {
    throw scream(
      "Something went wrong when trying to upload the wind data...",
      { res: uploadFileResponse },
    );
  }

  const windDataRef: WindDataReference = {
    file: {
      id: signedUrlResponse.id,
      name: fileName,
    },
    data: {
      lon: data.lon,
      lat: data.lat,
      type: fileType,
    },
  };

  return fetchEnhancerWithToken(`/api/configuration/wind/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(windDataRef),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error("Failed saving wind data");
      return res.json();
    })
    .then(_renameWindResponse.parse)
    .then((val) => {
      return {
        ...windDataRef,
        file: {
          ...windDataRef.file,
          name: val.name,
        },
      };
    });
}

const getWindDataPresignedUrl = async (
  nodeId: string,
): Promise<WindDataGetUrlResponse> =>
  fetchEnhancerWithToken(
    `/api/configuration/wind/generate-url/node/${nodeId}`,
    {
      method: "get",
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error("Failed to get signed url");
      }
      return res.json();
    })
    .then(_WindDataGetUrlResponse.parse);

const _renameWindResponse = z.object({
  name: z.string(),
});

export async function renameWindData(
  nodeId: string,
  fileId: string,
  name: string,
): Promise<{ name: string }> {
  const response = await fetchWithToken(
    `/api/configuration/wind/node/${nodeId}/${fileId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    },
  );

  return _renameWindResponse.parse(await response.json());
}

export async function deleteWindData(
  nodeId: string,
  fileId: string,
): Promise<void> {
  await fetchWithToken(`/api/configuration/wind/node/${nodeId}/${fileId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
