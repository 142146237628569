import { useChannel } from "ably/react";
import { useEffect } from "react";
import useAblyClient from "../useAblyClient";
import { ConnectionStateChange } from "ably";

interface Props {
  channelName: string;
  ablyId: string;
  children: React.ReactNode;
}

export function ChannelErrorHandler({ channelName, ablyId, children }: Props) {
  const { channel } = useChannel({
    channelName,
    ablyId,
  });
  const client = useAblyClient(ablyId);

  useEffect(() => {
    if (!client || !channel) return;

    const onConnectionStateChange = (stateChange: ConnectionStateChange) => {
      if (stateChange.current === "connected") {
        if (channel.state === "failed") {
          console.debug(`Channel ${channelName} failed, attempting recovery`);
          channel.attach();
        }
      }
    };

    client.connection.on(onConnectionStateChange);
    return () => {
      client.connection.off(onConnectionStateChange);
    };
  }, [channel, channelName, client]);

  if (!["initialized", "attached", "attaching"].includes(channel?.state)) {
    return null;
  }

  return children;
}
