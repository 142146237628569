import { Mixpanel } from "mixpanel";

export function trackProjectControlMenu(
  menuName: string,
  options: Record<string, any>,
) {
  Mixpanel.track_old("Open modal in upper right horizontal menu", {
    ...options,
    menuName,
  });
}
