import type { SymbolLayer } from "mapbox-gl";
import { Feature, FeatureCollection } from "geojson";
import { Layer, LayerType } from "../../types/layers";
import { LayerStrokeStyle } from "../../components/LayerList/LayerSettings/types";
import { POLYGON_LAYERS_SUFFIX } from "./dynamicPolygonLayers";
import { LINE_LAYERS_SUFFIX } from "./dynamicLineLayers";
import { CIRCLE_LAYERS_SUFFIX } from "./dynamicCircleLayers";

export const filterFeatureCollectionAccordingToType = (
  featureCollection:
    | {
        [key: string]: any;
        features: Feature[];
      }
    | FeatureCollection,
  type: "Polygon" | "LineString" | "Point",
) => ({
  ...featureCollection,
  features: featureCollection.features.filter(
    (f) => f.geometry.type === type || f.geometry.type === "Multi" + type,
  ),
});

export const getLayerSymbols = (
  pinnedProperties?: string[],
  zoomLevels?: [number, number],
) => {
  if (!pinnedProperties) return;
  return {
    type: "symbol",
    minzoom: 5,
    layout: {
      "symbol-placement": "point",
      "text-field": pinnedProperties?.map((p) => `{${p}}`).join("\n"),
      "text-size": 12,
      "symbol-spacing": 300,
      "text-keep-upright": true,
    },
    paint: {
      "text-opacity": 1,
    },
    ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
  } as Omit<SymbolLayer, "id" | "source">;
};

export const layerToSourceId = (layer: Layer, suffix: string): string => {
  return `${layer.id}${suffix}`;
};

export const StrokeStyleToDashArray = {
  [LayerStrokeStyle.Solid]: [1, 0],
  [LayerStrokeStyle.Dashed]: [5, 5],
  [LayerStrokeStyle.Dotted]: [1, 2],
};

export const getZoomLevels = (zoomLevels: undefined | [number, number]) => ({
  maxzoom: zoomLevels != null ? zoomLevels[1] : 22,
  minzoom: zoomLevels != null ? zoomLevels[0] : 0,
});

export const getSourceAndLayerId = (layer: Layer) => {
  switch (layer.type) {
    case LayerType.FeatureCollection:
    case LayerType.Polygon:
      return layerToSourceId(layer, POLYGON_LAYERS_SUFFIX);
    case LayerType.Circle:
      return layerToSourceId(layer, CIRCLE_LAYERS_SUFFIX);
    case LayerType.Line:
      return layerToSourceId(layer, LINE_LAYERS_SUFFIX);

    default:
      return layer.id ?? "";
  }
};
