import React, { ErrorInfo, useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import styled from "styled-components";
import Button from "../General/Button";
import WarningTriangle from "@icons/24/WarningTriangle.svg?react";
import { scream } from "../../utils/sentry";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { IconREMSize } from "../../styles/typography";
import Tooltip from "../General/Tooltip";
import { HandleSilentErrors } from "./ErrorHandling";
import { BranchNotFoundError } from "./types";
import { useSetAtom } from "jotai";
import { validationFatalGeneral } from "components/ValidationWarnings/manualWarnings";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

interface Props extends FallbackProps, React.PropsWithChildren {
  style?: React.CSSProperties;
  errorMessage?: React.ReactNode;
  buttonText?: string;
  retryCallback?(): void;
}

export function ErrorBoundaryLocalFallback({
  error,
  errorMessage,
  resetErrorBoundary,
  buttonText,
  style,
}: Props) {
  return (
    <Wrapper style={style}>
      {errorMessage ? (
        <h4>{errorMessage}</h4>
      ) : (
        <h4>{`An error occured: ${error.message}`}</h4>
      )}
      {resetErrorBoundary ? (
        <Button
          buttonType="secondary"
          text={buttonText ?? "Try again"}
          onClick={() => {
            resetErrorBoundary?.();
          }}
        />
      ) : (
        <Button
          buttonType="secondary"
          text="Refresh page"
          onClick={() => document.location.reload()}
        />
      )}
    </Wrapper>
  );
}

export function ErrorBoundaryPrintOnly({ error, errorMessage, style }: Props) {
  return (
    <Wrapper style={style}>
      {errorMessage ? (
        <h4>{errorMessage}</h4>
      ) : (
        <h4>{`An error occured: ${error.message}`}</h4>
      )}
    </Wrapper>
  );
}

export function ErrorBoundaryWarningTriangle() {
  return (
    <IconREMSize height={2.4} width={2.4}>
      <Tooltip
        text={
          "This system is experiencing issues. The Vind team has been notified."
        }
      >
        <WarningTriangle />
      </Tooltip>
    </IconREMSize>
  );
}

export function ErrorBoundarySilent({ error }: Props) {
  return <HandleSilentErrors error={error}>{null}</HandleSilentErrors>;
}

export const ScreamOnError = (error: Error) => {
  if (error instanceof BranchNotFoundError) return;
  if (error instanceof Response) {
    const clone = error.clone();

    scream(new Error("Response error"), {
      url: error.url,
      status: error.status,
      body: clone.text(),
      error,
    });
  } else {
    scream(error, { error });
  }
};

export function FatalErrorBoundaryWrapper() {
  const setFatalGeneral = useSetAtom(validationFatalGeneral);

  useEffect(() => {
    setFatalGeneral({
      type: ValidationWarningTypes.FatalGeneral,
    });
  }, [setFatalGeneral]);

  return null;
}

export const ErrorBoundaryWrapper = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  FallbackComponent: React.ComponentType<FallbackProps>,
  OnError: (error: Error, info: ErrorInfo) => void,
) => {
  return class extends React.Component<P> {
    render() {
      return (
        <ErrorBoundary FallbackComponent={FallbackComponent} onError={OnError}>
          <WrappedComponent {...(this.props as P)} />
        </ErrorBoundary>
      );
    }
  };
};
