import {
  getAnalysisProgress,
  getAnalysisVersion,
  getAnalysisWindStats,
} from "analysis/output";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { orTextLoader } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { undefMap } from "utils/utils";
import { WAKE_MODEL_NAME } from "../../../services/configurationService";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";

const InputDetails = () => {
  const { configuration, triggerId } = useDashboardContext();
  const windStats = useAtomValue(loadable(getAnalysisWindStats(triggerId)));
  const analysisVersion = useAtomValue(loadable(getAnalysisVersion(triggerId)));
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const stopped = stoppedReason ?? analysisStoppedReason;
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  const includeBlockage = configuration?.wakeAnalysis?.blockage;
  const includeNeighbourWakeLoss = configuration?.wakeAnalysis?.neighbourWake;
  const neighbourWakeMaxDistance =
    configuration?.wakeAnalysis?.neighbourWakeMaxDistance;
  const analysisPrecision = configuration?.wakeAnalysis?.precision;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const wakeModelName = configuration?.wakeAnalysis?.wakeModel
    ? WAKE_MODEL_NAME[configuration?.wakeAnalysis.wakeModel]
    : "-";
  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Model</p>
        <p>{wakeModelName}</p>
      </Row>
      {configuration.wakeAnalysis.wakeModel === "turbopark" && (
        <>
          <Row>
            <p>Turbulence intensity</p>
            <p>{configuration.wakeAnalysis.turbulenceIntensity}</p>
          </Row>
          <Row>
            <p>Wake expansion calibration</p>
            <p>
              {configuration.wakeAnalysis.turboparkWakeExpansionCalibration}
            </p>
          </Row>
        </>
      )}
      {configuration.wakeAnalysis.wakeModel === "jensen" && (
        <Row>
          <p>Wake decay constant</p>
          <p>{configuration.wakeAnalysis.wakeExpansionFactor}</p>
        </Row>
      )}
      <Row>
        <p>Air density</p>
        {orTextLoader(
          windStats,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (a) => (
            <p>{a.meanDensity.toFixed(3)} kg/m3</p>
          ),
        )}
      </Row>
      <Row>
        <p>Blockage included</p>
        <p>{includeBlockage ? "Yes" : "No"}</p>
      </Row>
      <Row>
        <p>Neighbour wake loss included</p>
        <p>{includeNeighbourWakeLoss ? "Yes" : "No"}</p>
      </Row>
      {includeNeighbourWakeLoss && (
        <Row>
          <p>Neighbour wake loss max distance</p>
          <p>{neighbourWakeMaxDistance} km</p>
        </Row>
      )}
      <Row>
        <p>Analysis precision</p>
        <p>{analysisPrecision}</p>
      </Row>
      <Row>
        <p>Analysis version</p>
        {orTextLoader(
          analysisVersion,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (a) => (
            <p>{a}</p>
          ),
        )}
      </Row>
    </FlexGrid2>
  );
};

export const InputWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Input"
      id="Input"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <InputDetails />
    </SafeCard>
  );
};
