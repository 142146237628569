import styled from "styled-components";
import { borderRadiusMedium } from "styles/space";
import { colors } from "styles/colors";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";

const IconFlipSwitch = styled(Row)`
  gap: 0;
  align-items: center;

  ${IconBtn} {
    border-radius: 0;
  }

  ${IconBtn}:first-of-type {
    border-top-left-radius: ${borderRadiusMedium};
    border-bottom-left-radius: ${borderRadiusMedium};
  }
  ${IconBtn}:last-of-type {
    border-top-right-radius: ${borderRadiusMedium};
    border-bottom-right-radius: ${borderRadiusMedium};
  }

  [data-active="false"] {
    background-color: ${colors.surfaceSecondary};

    svg path[stroke] {
      stroke: ${colors.iconDisabled};
    }
    svg path:not([stroke]) {
      fill: ${colors.iconDisabled};
    }

    &:hover {
      background: ${colors.surfaceHover};
    }
  }
`;

export default IconFlipSwitch;
