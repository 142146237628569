// DnDElement.tsx
import React, { useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { DnDElement as DnDElementType } from "./types";
import { DropIndicator, Element, ElementWrapper } from "./DnDStyles";
import { useAtomValue } from "jotai";
import { resizeInProgressState } from "./state";

type DnDElementProps = {
  element: DnDElementType;
  moveElement: (
    draggedId: string,
    targetElementId: string | null,
    after: boolean,
  ) => void;
  showIndicator: boolean;
  updateIndicatorPosition: (elementId: string | null, after: boolean) => void;
  disabled?: boolean;
};

const DnDElement: React.FC<DnDElementProps> = ({
  element,
  moveElement,
  showIndicator,
  updateIndicatorPosition,
  disabled,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const resizeInProgress = useAtomValue(resizeInProgressState);

  const [, dragRef] = useDrag(() => ({
    type: "element",
    item: { id: element.id },
    canDrag: !resizeInProgress && !disabled,
  }));

  const [, dropRef] = useDrop(() => ({
    accept: "element",
    hover: (item: { id: string }, monitor: DropTargetMonitor) => {
      if (!elementRef.current) {
        return;
      }
      if (disabled) return;

      const draggedId = item.id;
      const targetId = element.id;
      const isDirectlyOver = monitor.isOver();

      if (draggedId === targetId || !isDirectlyOver) {
        return;
      }

      const targetRect = elementRef.current.getBoundingClientRect();
      const targetCenterX = (targetRect.right - targetRect.left) / 2;
      const mousePosition = monitor.getClientOffset();

      if (!mousePosition) {
        return;
      }

      const offsetX = mousePosition.x - targetRect.left;
      const after = offsetX > targetCenterX;

      updateIndicatorPosition(element.id, after);
    },
    drop: (item: { id: string }, monitor: DropTargetMonitor) => {
      if (disabled) return;
      updateIndicatorPosition(null, false);

      if (!elementRef.current) {
        return;
      }

      const draggedId = item.id;
      const targetId = element.id;

      if (draggedId === targetId) {
        return;
      }

      const targetRect = elementRef.current.getBoundingClientRect();
      const targetCenterX = (targetRect.right - targetRect.left) / 2;
      const mousePosition = monitor.getClientOffset();

      if (!mousePosition) {
        return;
      }

      const offsetX = mousePosition.x - targetRect.left;
      const after = offsetX > targetCenterX;

      moveElement(draggedId, targetId, after);
    },
  }));

  dragRef(dropRef(elementRef));

  return (
    <ElementWrapper
      resizeInProgress={resizeInProgress}
      disabled={disabled}
      ref={elementRef}
      widthShare={element.widthShare ?? 1}
    >
      <DropIndicator show={showIndicator && !disabled} />
      <Element>{element.component}</Element>
    </ElementWrapper>
  );
};

export default DnDElement;
