import { Mixpanel, TrackingFeature, TrackingCapability } from "mixpanel";
import { useCallback, useEffect, useState } from "react";
import { SearchGroup, SearchItem, SearchResultItem } from "../state";

type SearchAnalyticsProps = {
  page: "organisation" | "project";
  search: string;
  totalItems: number;
  searchGroups: SearchGroup[];
  selectedIndex: number;
};

export const useSearchAnalytics = ({
  page,
  search,
  totalItems,
  searchGroups,
  selectedIndex,
}: SearchAnalyticsProps) => {
  const [hasSearched, setHasSearched] = useState(false);

  // Track session start and end
  useEffect(() => {
    Mixpanel.track(
      TrackingCapability.Navigation,
      TrackingFeature.Search,
      "session started",
      {
        page,
      },
    );

    return () => {
      Mixpanel.track(
        TrackingCapability.Navigation,
        TrackingFeature.Search,
        "session ended",
        {
          page,
        },
      );
    };
  }, [page]);

  // Close search
  const trackSearchClose = useCallback(() => {
    Mixpanel.track(
      TrackingCapability.Navigation,
      TrackingFeature.Search,
      "closed",
      {
        page,
        searchTerm: search,
        totalResults: totalItems,
        hasResults: totalItems > 0,
        groupResults: searchGroups.map((group) => ({
          groupName: group.title,
          resultCount: group.items.length,
        })),
      },
    );
  }, [page, search, totalItems, searchGroups]);

  // Track first search
  useEffect(() => {
    if (search.length > 0 && !hasSearched) {
      Mixpanel.track(
        TrackingCapability.Navigation,
        TrackingFeature.Search,
        "performed",
        {
          searchTerm: search,
          totalResults: totalItems,
          hasResults: totalItems > 0,
          groupResults: searchGroups.map((group) => ({
            groupName: group.title,
            resultCount: group.items.length,
          })),
          page,
        },
      );
      setHasSearched(true);
    }
  }, [search, hasSearched, page, searchGroups, totalItems]);

  // Track search clear
  const trackSearchClear = useCallback(() => {
    if (search.length > 0) {
      Mixpanel.track(
        TrackingCapability.Navigation,
        TrackingFeature.Search,
        "cleared",
        {
          searchTerm: search,
          page,
        },
      );
    }
  }, [search, page]);

  // Track search result selection
  const trackSearchSelection = useCallback(
    (
      item: SearchResultItem<any> | SearchItem<any>,
      isSecondaryAction: boolean = false,
    ) => {
      const groupTitle = searchGroups.find((group) =>
        group.items.some((groupItem) => groupItem.id === item.id),
      )?.title;

      Mixpanel.track(
        TrackingCapability.Navigation,
        TrackingFeature.Search,
        "result selected",
        {
          searchTerm: search,
          resultType: groupTitle,
          resultId: item.id,
          resultName:
            typeof item.name === "string" ? item.name : "custom-element",
          isSecondaryAction,
          totalResults: totalItems,
          position: selectedIndex,
          page,
        },
      );
    },
    [search, searchGroups, totalItems, selectedIndex, page],
  );

  // Track recent search selection
  const trackRecentSearchSelection = useCallback(
    (item: SearchItem<any>, type: string) => {
      Mixpanel.track(
        TrackingCapability.Navigation,
        TrackingFeature.Search,
        "recent search selected",
        {
          resultType: type,
          resultId: item.id,
          resultName:
            typeof item.name === "string" ? item.name : "custom-element",
          page,
        },
      );
    },
    [page],
  );

  return {
    trackSearchClear,
    trackSearchSelection,
    trackRecentSearchSelection,
    trackSearchClose,
  };
};
