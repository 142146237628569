import { spaceLarge } from "../../../styles/space";
import { roundToDecimal, undefMap } from "../../../utils/utils";
import { FlexGrid2 } from "../../General/Form";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";
import { isDefined } from "../../../utils/predicates";
import { ReactNode } from "react";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { StatHeader } from "./Base.style";
import Table from "components/Dashboard/Table";
import { useAtomValue } from "jotai";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { mooringLineStatsInParkSelector } from "state/jotai/mooringLineType";

const MooringLinesDetails = () => {
  const { park, branch } = useDashboardContext();
  const branchId = branch.id;
  const parkId = park.id;

  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId, branchId }),
  );

  const { mooringLineTypes, lengthPerMooringLineType } = useAtomValue(
    mooringLineStatsInParkSelector({ parkId, branchId }),
  );

  const mooringLinesFormatted = mooringLineTypes
    .map((lineType) => {
      const lengthPerType = lengthPerMooringLineType[lineType.id] ?? 0;
      return {
        name: lineType.name,
        diameter: undefMap(lineType.diameter, (d) => `${d} mm`),
        material: lineType.material,
        length: undefMap(roundToDecimal(lengthPerType, 4), (l) => `${l} km`),
      };
    })
    .filter((lineType) => lineType.length);

  const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
    isDefined(t) ? (
      t
    ) : (
      <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
    );

  return (
    <>
      <FlexGrid2 style={{ padding: spaceLarge }}>
        <StatHeader
          title={"Number of mooring lines"}
          value={mooringLines.length}
        />
      </FlexGrid2>
      {mooringLines.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Mooring line name</th>
              <th>Material</th>
              <th>Diameter</th>
              <th>Total length</th>
            </tr>
          </thead>
          <tbody>
            {mooringLinesFormatted.map(
              ({ name, material, diameter, length }) => (
                <tr key={name}>
                  <td>{undefToSkeleton(name)}</td>
                  <td>{undefToSkeleton(material)}</td>
                  <td>{undefToSkeleton(diameter)}</td>
                  <td>{undefToSkeleton(length)}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};

export const MooringLinesWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Mooring lines"
      id="Mooring lines"
      resetKeys={errorBoundaryResetKeys}
    >
      <MooringLinesDetails />
    </SafeCard>
  );
};
