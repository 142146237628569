import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  ABLY_THREADS_ADD_FILTER,
  ABLY_THREADS_REMOVE_FILTER,
} from "../../../state/ably";
import {
  postThread,
  deleteThread,
  postConditionalThreadFollow,
} from "../service";
import { followThreadsAtomFamily, threadsAtomFamily } from "../state";
import { Thread } from "../types";
import { useReadComments } from "../UnreadComments/useReadComments";
import { useAblyPublish } from "../../../hooks/useAblyPublish";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { aset } from "utils/jotai";

export const useThreadCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const ablyPublish = useAblyPublish(projectNodeId);
  const { removeRead } = useReadComments();

  const post = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        data: {
          message: string;
          featureId?: string;
          mapPosition?: [number, number];
        },
      ) => {
        if (!projectNodeId) return;
        const res = await postThread(projectNodeId, branchId, data);
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return [...cur, res];
          },
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_THREADS_ADD_FILTER, res);
        try {
          const followResponse = await postConditionalThreadFollow(
            projectNodeId,
            branchId,
            res.threadId,
          );
          await aset(
            get,
            set,
            followThreadsAtomFamily({
              nodeId: projectNodeId,
              branchId,
            }),
            (cur) => {
              return [...cur, followResponse];
            },
          );
        } catch (e) {
          console.log(e);
        }
        return res;
      },
      [ablyPublish, branchId, projectNodeId],
    ),
  );

  const remove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.filter((c) => c.threadId !== threadId);
          },
        );
        const res = await deleteThread(projectNodeId, branchId, threadId);
        ablyPublish(`${projectNodeId}:all`, ABLY_THREADS_REMOVE_FILTER, {
          threadId,
        });

        removeRead(threadId);
        return res;
      },
      [ablyPublish, projectNodeId, branchId, removeRead],
    ),
  );

  const localPost = useAtomCallback(
    useCallback(
      async (get, set, thread: Thread) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return [...cur, thread];
          },
        );
      },
      [branchId, projectNodeId],
    ),
  );

  const localRemove = useAtomCallback(
    useCallback(
      async (get, set, threadId: string) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId,
          }),
          (cur) => {
            return cur.filter((c) => c.threadId !== threadId);
          },
        );
        removeRead(threadId);
      },
      [branchId, projectNodeId, removeRead],
    ),
  );

  return {
    post,
    remove,
    localPost,
    localRemove,
  };
};
