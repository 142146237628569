import React from "react";
import styled from "styled-components";
import InfoIcon from "@icons/24/Information.svg";
import { colors } from "styles/colors";
import { StandardBox } from "styles/boxes/Boxes";
import { IconREMSize, typography } from "styles/typography";
import { spacing2, spacing4, spacing8 } from "styles/space";
import Checkbox from "components/General/Checkbox";
import { Row } from "components/General/Layout";

export const ModalContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.6rem;
  width: 60rem;
  height: fit-content;
  max-width: 80vw;
  gap: 2.4rem;
  background: white;
`;

export const Label = styled.h4`
  ${typography.semiBold}
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0;
`;

export const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const SecondaryText = styled.p`
  ${typography.contentAndButtons}
  color: ${colors.secondaryText};
  margin: 0;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 3;
`;

export const SectionHeader = ({
  icon,
  text,
  style,
}: {
  icon: React.ReactNode;
  text: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Row
      style={{
        backgroundColor: colors.indigo50,
        marginTop: spacing8,
        ...style,
      }}
    >
      <DescriptionContainer style={{ padding: spacing2, gap: spacing4 }}>
        <IconREMSize height={1.2} width={1.2}>
          {icon}
        </IconREMSize>
        <p style={{ ...typography.caption, fontWeight: 600 }}>{text}</p>
      </DescriptionContainer>
    </Row>
  );
};

export const EntryContainer = styled.div<{ star?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;

  ${({ star }) =>
    star &&
    `
    &:after {
      content: "*";
      position: absolute;
      right: 25%;
      margin-left: 0.2rem;
    }`}
`;
export const StyledLink = styled.a`
  ${typography.contentAndButtons}
  margin: 0;
`;

const Wrapper = styled.div<{ size?: number; color?: string }>`
  display: flex;

  svg {
    width: ${(p) => p.size ?? 1.4}rem;
    height: ${(p) => p.size ?? 1.4}rem;
    path {
      stroke: ${({ color }) => color ?? colors.iconInfo};
    }
  }
`;

export function QuestionIconComp({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  return (
    <Wrapper onClick={onClick} style={{ cursor: "pointer" }}>
      <InfoIcon />
    </Wrapper>
  );
}

export function Yes({ star }: { star?: boolean }) {
  return (
    <EntryContainer star={star}>
      <Checkbox checked={true} disabled />
    </EntryContainer>
  );
}

export function No() {
  return (
    <EntryContainer>
      <Checkbox checked={false} disabled />
    </EntryContainer>
  );
}
