import Button from "components/General/Button";
import { SettingButton } from "components/SettingsV2/Shared/styles";
import { useSetAtom } from "jotai";
import { toastMessagesAtom } from "state/toast";

type SettingsFormActionsProps = {
  hasChanges: boolean;
  isLoading: boolean;
  onSave: () => void;
  onCancel: () => void;
  isLibraryContext?: boolean;
};

export const SettingsFormActions = ({
  hasChanges,
  isLoading,
  onSave,
  onCancel,
  isLibraryContext,
}: SettingsFormActionsProps) => {
  const setToastMessages = useSetAtom(toastMessagesAtom);

  return (
    <SettingButton
      style={{
        position: "absolute",
        right: "2.4rem",
        top: isLibraryContext ? "19rem" : "14.4rem",
        visibility: hasChanges ? "visible" : "hidden",
      }}
    >
      <Button
        disabled={isLoading || !hasChanges}
        text="Cancel"
        buttonType="text"
        onClick={onCancel}
      />
      <Button
        disabled={isLoading || !hasChanges}
        text="Save changes"
        onClick={() => {
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Saving...",
              timeout: 1000,
            },
          ]);
          onSave();
        }}
      />
    </SettingButton>
  );
};
