import {
  calculateCableSpliceFractions,
  getClosestPortToPark,
  getDistanceFromPortToPark,
  getDistancesFromPortToLandfall,
} from "../utils";
import * as turf from "@turf/turf";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getBranchId, getParkId } from "analysis/inputs";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { POSITION_ON_SITE_HOURS } from "../constants";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import {
  layBuryCableActivity,
  loadCableCarouselActivity,
  transitActivity,
} from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { isCableLayVessel } from "services/vesselService";
import { ExportCableActivitiesConfig } from "services/operationsConfigurationService";

export const exportCableInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const exportCables = await get(
      exportCablesInParkFamily({
        parkId,
        branchId,
      }),
    );
    const exportCableSplits = await get(
      exportCableSplitsOkFamily({ parkId, branchId }),
    );

    const substationsWithType = await get(
      substationsInParkWithTypeFamily({
        parkId,
        branchId,
      }),
    );

    const port = await get(getClosestPortToPark(id));
    const portParkDistance = await get(getDistanceFromPortToPark(id));
    const portLandfallDistances = await get(getDistancesFromPortToLandfall(id));

    const configuration = await get(getOperationsConfiguration(id));
    const {
      installationVessel,
      installationSeason,
      weatherLimits,
      activities,
    } = configuration.ti.exportCable;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!port || !portParkDistance || !portLandfallDistances) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isCableLayVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    //Only consider installation of offshore cable
    const cableLengths = exportCables.reduce<Record<string, number>>(
      (acc, c) => {
        const split = exportCableSplits.find(
          (seg) => seg.exportCable.id === c.id,
        );
        const offshoreLength = split
          ? turf.length(split.offshore, { units: "kilometers" })
          : turf.length(c, { units: "kilometers" });
        acc[c.id] = offshoreLength;
        return acc;
      },
      {},
    );

    const spliceFractions = exportCables.reduce<Record<string, number[]>>(
      (acc, c) => {
        const length = cableLengths[c.id];
        const fractions = calculateCableSpliceFractions(
          vessel.exportCableCapacity,
          length,
        );
        acc[c.id] = fractions;
        return acc;
      },
      {},
    );

    const distPortSplicePos = exportCables.reduce<Record<string, number[]>>(
      (acc, c) => {
        acc[c.id] = [];
        const subTypeEnd1 = substationsWithType.find(
          ([sub]) => c.properties.fromSubstationId === sub.id,
        );
        const end1IsOffshore =
          subTypeEnd1 && subTypeEnd1[1].type === "offshore" ? true : false;

        const spliceFractionsCable = spliceFractions[c.id];
        const cableLength = cableLengths[c.id];

        for (let j = 0; j < spliceFractionsCable.length; j++) {
          const spliceDistAlongCable = end1IsOffshore
            ? cableLength * (1.0 - spliceFractionsCable[j])
            : cableLength * spliceFractionsCable[j];
          const splicePosition = turf.along(c, spliceDistAlongCable).geometry
            .coordinates;
          const distPortSplicePos = turf.distance(port, splicePosition, {
            units: "kilometers",
          });
          const transitToSpliceTime = distPortSplicePos / vessel.transitSpeed;
          acc[c.id].push(transitToSpliceTime);
        }
        return acc;
      },
      {},
    );

    const numCables = exportCables.length;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numCables; i++) {
      const cable = exportCables[i];
      const numRounds = spliceFractions[cable.id].length;
      const transitLandfallTime = Math.ceil(
        portLandfallDistances[cable.id] / vessel.transitSpeed,
      );

      let finishedCableLength = 0;
      for (let j = 0; j < numRounds; j++) {
        const lengthOnCarousel =
          spliceFractions[cable.id][j] * cableLengths[cable.id] -
          finishedCableLength;

        const transitToSpliceTime = Math.ceil(
          distPortSplicePos[cable.id][j] / vessel.transitSpeed,
        );

        totalInstallTime.push(
          loadCableCarouselActivity(lengthOnCarousel, vessel.cableLoadingSpeed),
        );

        if (j === 0) {
          totalInstallTime.push(transitActivity(transitLandfallTime));
        } else {
          totalInstallTime.push(transitActivity(transitToSpliceTime));
        }

        if (j === 0) {
          totalInstallTime.push(posAtLandfallActivity);
          totalInstallTime.push(pullExportCableLandFallActivity(activities));
        } else {
          totalInstallTime.push(posAtExportCableJointActivity);
          totalInstallTime.push(joinExportCableActivity(activities));
        }

        totalInstallTime.push(
          layBuryCableActivity(
            lengthOnCarousel,
            vessel.cableLayBurySpeed,
            true,
          ),
        );
        finishedCableLength += lengthOnCarousel;

        if (j === numRounds - 1) {
          totalInstallTime.push(pullExportCableSubstationActivity(activities));
          totalInstallTime.push(transitActivity(transitTime));
        } else {
          totalInstallTime.push(transitActivity(transitToSpliceTime));
        }
      }
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const posAtLandfallActivity = {
  id: "Position at landfall",
  duration: POSITION_ON_SITE_HOURS,
  useWindLim: false,
  useWaveLim: true,
};

const pullExportCableLandFallActivity = (
  activities: ExportCableActivitiesConfig,
) => ({
  id: "Pull in cable at landfall",
  duration: activities.pullInExportCableAtLandfall,
  useWindLim: true,
  useWaveLim: true,
});

const posAtExportCableJointActivity = {
  id: "Position at cable joint",
  duration: POSITION_ON_SITE_HOURS,
  useWindLim: false,
  useWaveLim: true,
};

const joinExportCableActivity = (activities: ExportCableActivitiesConfig) => ({
  id: "Join export cable",
  duration: activities.joinExportCables,
  useWindLim: true,
  useWaveLim: true,
});

const pullExportCableSubstationActivity = (
  activities: ExportCableActivitiesConfig,
) => ({
  id: "Pull in cable at substation",
  duration: activities.pullInExportCableToSubstation,
  useWindLim: true,
  useWaveLim: true,
});
