import mapboxgl, { MercatorCoordinate } from "mapbox-gl";
import React, { Suspense, useEffect, useMemo } from "react";
import { mapAtom } from "../state/map";
import { showWakeAtom, wakeDirectionAtom } from "../state/windStatistics";
import { branchIdAtom, parkIdAtom, projectIdAtom } from "../state/pathParams";
import { isDefined } from "../utils/predicates";
import { LAYER_DEBUG_PRINT } from "../state/debug";
import * as turf from "@turf/turf";
import { colors } from "../styles/colors";
import {
  getBeforeLayer,
  wakePaddedParkLayerId,
} from "../components/Mapbox/utils";
import { scream } from "../utils/sentry";
import { useAtomValue } from "jotai";
import {
  surroundingTurbinesWithZonesFamily,
  turbinesInParkFamily,
} from "state/jotai/turbine";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { parkPaddedPolygonFamily } from "state/jotai/park";
import Polygon from "components/MapFeatures/Polygon";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { existingTurbinesFamily } from "state/jotai/existingTurbine";
import { createGlProgram } from "utils/gl";
import { tile2bbox, wrapMapboxTile } from "types/tile";
import { bboxOverlaps } from "utils/bboxOverlaps";
import { ErrorBoundaryWrapper } from "components/ErrorBoundaries/ErrorBoundaryLocal";

const wakePaddedParkSourceId = "wake-padded-park-source";

class WakeLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d" = "3d";

  gl?: {
    program: WebGLProgram;
    vertexBuffer: WebGLBuffer;
    aPos: GLint;
    loc: {
      scale: WebGLUniformLocation;
      turbines: WebGLUniformLocation;
      rotorDiameters: WebGLUniformLocation;
      numberOfTurbines: WebGLUniformLocation;
      dir: WebGLUniformLocation;
      bbox: WebGLUniformLocation;
    };
  };

  constructor(
    public turbines: number[][],
    public bbox: number[],
    public scale: number,
    public wakeDirection: number,
    public rotorDiameters: number[],
  ) {
    this.id = "wake";
    this.type = "custom";
    this.scale = scale;
    this.wakeDirection = wakeDirection;
    this.rotorDiameters = rotorDiameters;
  }

  onAdd(_: mapboxgl.Map, gl: WebGLRenderingContext) {
    LAYER_DEBUG_PRINT && console.log("WakeLayer.onAdd");
    const vertexSource = `
              precision highp float;

              uniform vec4  u_bbox;
              attribute vec2 a_pos;
              varying vec2 cc;

              vec2 transform(vec2 v) {
                return vec2(v.x, 1.0 - v.y);
              }

              void main() {
                  vec2 uv = transform(0.5 * a_pos + 0.5);
                  vec2 ll = u_bbox.xy;
                  vec2 ur = u_bbox.zw;
                  cc = ll + (ur - ll) * uv;

                  gl_Position = vec4(a_pos, 0.0, 1.0);
              }`;

    const fragmentSource = `
              precision highp float;
              const int max_turbines = 500;
              const float max_distance = 7000.;
              const float wake_decay_coefficient = 0.05; // https://www.emd-international.com/files/windpro/March-19_Revised%20offshore%20PARK%20WDC%20recommendations_v1.pdf
              const float thrust_coefficient = 0.8; // Dependent on turbine type and wind speed

              varying vec2 cc;
              uniform int u_number_of_turbines;
              uniform vec2 u_turbines[max_turbines];
              uniform float u_rotorDiameters[max_turbines];
              uniform float u_scale;
              uniform vec2 u_dir;

              float Jensen(vec2 turbine, vec2 point, float rotorDiameter) {
                vec2 vector = (point - turbine);
                vec2 unit = u_dir / length(u_dir);
                bool behind_turbine =  dot(vector, unit) > 0.;
                if (!behind_turbine) {
                  // Feather the region in front of the turbine to make it look nicer.
                  // We simply move the point to be along the edge where we're in front/behind the turbine,
                  // and at the same distance as we currently are.  This makes a nice smooth gradient around each turbine.
                  float distToTurbine = length(vector);
                  float featherThreshold = rotorDiameter * u_scale;
                  if (distToTurbine < featherThreshold) {
                    point = turbine + normalize(vec2(-unit.y, unit.x)) * distToTurbine;
                    vector = (point - turbine);
                  } else {
                    return 0.;
                  }
                }

                vector /= u_scale;
                vec2 projected_vector = unit * dot(vector, unit);
                float x = length(projected_vector);
                if (x > max_distance) {
                  return 0.;
                }

                float r = rotorDiameter * 0.5;
                float max_centerline_distance = r + x * wake_decay_coefficient;
                vec2 perpendicular_vector = projected_vector - vector;
                float centerline_distance = length(perpendicular_vector);

                float counter = 1. - sqrt(1. - thrust_coefficient);
                float denominator = pow(1. + wake_decay_coefficient * x / r, 2.);
                float sigma = 0.4 * max_centerline_distance;
                float gaussian = 1. / sigma / 2.507 * exp(-0.5 * pow(centerline_distance / sigma, 2.));
                return 100. * pow(counter / denominator, 2.) * gaussian;
              }

              // Jensen wake model

              void main() {
                float wake = 0.;
                for(int i=0;i<max_turbines;i++) {
                  wake += Jensen(u_turbines[i], cc, u_rotorDiameters[i]);

                  if (i > u_number_of_turbines) break;
                }
                float a = sqrt(wake) / 0.7;
                vec3 color = vec3(1.0, 1.0, 1.0);
                gl_FragColor = vec4(color * a, a);
              }`;

    const program = createGlProgram(gl, vertexSource, fragmentSource, {
      label: "noise",
    }).program;

    const vertexBuffer = gl.createBuffer();
    if (!vertexBuffer) throw scream("noise: failed to create buffer");
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([-1, -1, 1, -1, 1, 1, -1, 1]),
      gl.STATIC_DRAW,
    );

    const aPos = gl.getAttribLocation(program, "a_pos");

    const scale = gl.getUniformLocation(program, "u_scale");
    if (!scale) throw new Error(`No uniform "u_scale"`);
    const turbines = gl.getUniformLocation(program, "u_turbines");
    if (!turbines) throw new Error(`No uniform "u_turbines"`);
    const bbox = gl.getUniformLocation(program, "u_bbox");
    if (!bbox) throw new Error(`No uniform "u_bbox"`);

    const rotorDiameters = gl.getUniformLocation(program, "u_rotorDiameters");
    if (!rotorDiameters) throw new Error(`No uniform "u_rotorDiameters"`);

    const numberOfTurbines = gl.getUniformLocation(
      program,
      "u_number_of_turbines",
    );
    if (!numberOfTurbines) throw new Error(`No uniform "u_number_of_turbines"`);

    const dir = gl.getUniformLocation(program, "u_dir");
    if (!dir) throw new Error(`No uniform "u_dir"`);

    this.gl = {
      program,
      vertexBuffer,
      aPos,
      loc: {
        scale,
        turbines,
        rotorDiameters,
        numberOfTurbines,
        dir,
        bbox,
      },
    };
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.gl || !tileId.z) return;

    const tilebbox = tile2bbox({
      x: tileId.x,
      y: tileId.y,
      z: tileId.z,
    });
    if (!bboxOverlaps(tilebbox, this.bbox)) return;

    const ll = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: tilebbox[0],
      lat: tilebbox[1],
    });
    const ur = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: tilebbox[2],
      lat: tilebbox[3],
    });

    const scale =
      (ll.meterInMercatorCoordinateUnits() +
        ur.meterInMercatorCoordinateUnits()) /
      2.0;
    const mercBbox = [ll.x, ll.y, ur.x, ur.y];
    gl.useProgram(this.gl.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.uniform4fv(this.gl.loc.bbox, mercBbox);

    gl.uniform1f(this.gl.loc.scale, scale);
    gl.uniform1fv(this.gl.loc.rotorDiameters, this.rotorDiameters);
    gl.uniform1i(this.gl.loc.numberOfTurbines, this.turbines.length);
    const direction = this.wakeDirection - 90;
    const uDir = [
      -Math.cos((direction * Math.PI) / 180),
      -Math.sin((direction * Math.PI) / 180),
    ];
    gl.uniform2fv(this.gl.loc.dir, uDir);
    gl.uniform2fv(this.gl.loc.turbines, this.turbines.flat());

    gl.bindBuffer(gl.ARRAY_BUFFER, this.gl.vertexBuffer);
    gl.enableVertexAttribArray(this.gl.aPos);
    gl.vertexAttribPointer(this.gl.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  };

  render(_gl: WebGLRenderingContext, _matrix: number[]) {}
}

const Wake = ErrorBoundaryWrapper(
  () => {
    const showWake = useAtomValue(showWakeAtom);
    if (!showWake) return null;
    return (
      <Suspense fallback={null}>
        <WakeActive />
      </Suspense>
    );
  },
  () => null,
  (e) => {
    scream(e);
  },
);

const WakeActive = () => {
  const parkId = useAtomValue(parkIdAtom)!;
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom);
  const turbines = useAtomValue(
    turbinesInParkFamily({ parkId, branchId: undefined }),
  );
  const configuration = useAtomValue(
    analysisConfigurationSelectedFamily({ projectId, branchId }),
  );
  const paddedPark = useAtomValue(
    parkPaddedPolygonFamily({
      parkId,
      branchId,
      maxDistanceKM: configuration?.wakeAnalysis.neighbourWakeMaxDistance,
    }),
  );

  const surroundingFeatures = useAtomValue(
    surroundingTurbinesWithZonesFamily({
      parkId,
      branchId,
      maxDistanceKM: configuration?.wakeAnalysis.neighbourWakeMaxDistance,
    }),
  );

  const wakeDirection = useAtomValue(wakeDirectionAtom);
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const validExistingTurbines = useAtomValue(
    existingTurbinesFamily({ branchId: undefined }),
  ).filter((t) => t.properties.power);

  const closeValidExistingTurbines = useMemo(() => {
    if (!paddedPark) return [];
    return validExistingTurbines.filter((t) => turf.inside(t, paddedPark));
  }, [paddedPark, validExistingTurbines]);

  const map = useAtomValue(mapAtom);

  const features = useMemo(() => {
    const layoutFeatures = [...turbines];
    if (!configuration?.wakeAnalysis.neighbourWake) return layoutFeatures;
    return layoutFeatures.concat(surroundingFeatures);
  }, [
    turbines,
    surroundingFeatures,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const turbineLonlats = useMemo(() => {
    const regularTurbines = features.map((f) => f.geometry.coordinates);
    if (!configuration?.wakeAnalysis.neighbourWake) return regularTurbines;
    const existingTurbines = closeValidExistingTurbines.map(
      (f) => f.geometry.coordinates,
    );
    return regularTurbines.concat(existingTurbines);
  }, [
    features,
    closeValidExistingTurbines,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const rotorDiameters = useMemo(() => {
    if (!turbineTypes) return;
    const regular = features.map(
      (f) => turbineTypes.get(f.properties.turbineTypeId)?.diameter,
    );
    if (!configuration?.wakeAnalysis.neighbourWake) return regular;
    const existing = closeValidExistingTurbines.map((t) =>
      Math.exp((Math.log(t.properties.power! * 1e6) - Math.log(821)) / 1.79),
    );
    return regular.concat(existing);
  }, [
    features,
    turbineTypes,
    closeValidExistingTurbines,
    configuration?.wakeAnalysis.neighbourWake,
  ]);

  const wakeLayer = useMemo(() => {
    const bbox = turf.bbox(turf.featureCollection(features));
    const buffer = 0.3; // TODO: get a reasonable buffer from somewhere
    const bufferedBBOX = [
      bbox[0] - buffer,
      bbox[1] - buffer,
      bbox[2] + buffer,
      bbox[3] + buffer,
    ];

    const centerCoord = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: (bbox[0] + bbox[2]) / 2,
      lat: (bbox[1] + bbox[3]) / 2,
    });

    const turbinemcs = turbineLonlats.map<[number, number]>((f) => {
      const m = mapboxgl.MercatorCoordinate.fromLngLat({
        lng: f[0],
        lat: f[1],
      });
      return [m.x, m.y];
    });

    const scale = centerCoord.meterInMercatorCoordinateUnits();

    return new WakeLayer(
      turbinemcs,
      bufferedBBOX,
      scale,
      wakeDirection,
      rotorDiameters?.filter(isDefined) ?? [],
    );
  }, [features, turbineLonlats, wakeDirection, rotorDiameters]);

  useEffect(() => {
    if (!map || !wakeLayer) return;
    map.addLayer(wakeLayer as any);

    return () => {
      map.removeLayer(wakeLayer.id);
    };
  }, [map, wakeLayer]);

  return map && paddedPark && configuration?.wakeAnalysis.neighbourWake ? (
    <Polygon
      features={[paddedPark]}
      paint={{
        "fill-color": colors.primary,
        "fill-opacity": 0.1,
      }}
      sourceId={wakePaddedParkSourceId}
      layerId={wakePaddedParkLayerId}
      map={map}
      beforeLayer={getBeforeLayer(map, wakePaddedParkLayerId)}
    />
  ) : null;
};

export default Wake;
