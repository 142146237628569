import { Suspense, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ListResultsForProblem,
  OptProblem,
  OptResult,
} from "../../functions/optimize";
import { SubAreaFeature } from "../../types/feature";
import { colors } from "../../styles/colors";
import AddIcon from "@icons/24/Add.svg";
import { SkeletonBlock, SkeletonText } from "../Loading/Skeleton";
import {
  borderRadius,
  spaceDecent,
  spaceTiny,
  spacing1,
} from "../../styles/space";
import {
  IconREMSize,
  TextIcon,
  textFontSize,
  typography,
} from "../../styles/typography";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { ParkFeature } from "../../types/feature";
import { pointsAreEqual } from "../../utils/geometry";
import { dateToDateTime, partition, zip } from "../../utils/utils";
import { replaceOrUndefined } from "../ControlPanels/utils";
import { NumberOfTurbinesTag } from "./Tags";
import { isCompleted, isFailed, isInvalid } from "./predicates";
import {
  optProblemsAtom,
  problemResultsAtom,
  recentlyDeletedProblemAtom,
} from "./state";
import { useOptimizationCrud } from "./useOptimizationCrud";
import Trashcan from "@icons/24/Bin.svg?react";
import { inReadOnlyModeSelector } from "state/project";
import Tooltip from "components/General/Tooltip";
import { editorAccessProjectSelector } from "state/user";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { OverlineText } from "components/General/GeneralSideModals.style";
import Button from "components/General/Button";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import { useTrackEvent } from "components/OnboardingTours/state";
import { atom as atomJ, useAtom, useAtomValue } from "jotai";
import { Tag } from "components/General/Tag";
import { Exploration } from "./Exploration";
import { MultiPolygon, Polygon } from "geojson";
import { Feature } from "geojson";
import { OptimizationInput } from "./OptimizationInput";
import OvalTab from "components/General/OvalTab";
import { OptimizeParameters } from "types/turbines";
import { isOnshoreAtom } from "state/onshore";
import { Row } from "components/General/Layout";
import { setTurbineNames, useTurbineGeneration } from "./useGenerateAndSave";
import {
  currentTurbineIdAtom,
  simpleTurbineTypesAtom,
} from "state/jotai/turbineType";
import { OptimizeTurbinesContext } from "./GenerationSettings";
import { useJotaiCallback } from "utils/jotai";
import { previewTurbinesState } from "state/turbines";
import { totalProgressFamily } from "./state";
import usePrevious from "hooks/usePrevious";

type Ids = {
  nodeId: string;
  branchId: string;
  zoneId: string;
};

type Pids = Ids & {
  id: string;
};

const splitResultsByStatus = (items: ListResultsForProblem["items"]) => {
  const [completed, rest] = partition(items, isCompleted);
  const [failed, rest2] = partition(rest, isFailed);
  const [invalid, rest3] = partition(rest2, isInvalid);

  const [running, stuck] = partition(rest3, (e) => {
    const spendSeconds = Date.now() / 1000 - e.createdAt;
    return spendSeconds < 3600;
  });

  return {
    completed,
    failed,
    invalid,
    running,
    stuck,
  };
};

const ProblemEntry = styled.div<{
  open?: boolean;
}>`
  display: flex;
  flex-direction: column;

  margin: 0 !important;
  &:hover {
    cursor: pointer;
  }
  background-color: ${(p) => (p.open ? colors.surfaceSecondary : "auto")};
  border-radius: ${borderRadius.small};
  padding: 0 1.2rem;
`;

const ProblemEntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spaceTiny};
`;

const ResultEntry = styled.div`
  margin: 0rem;
  margin-left: 2rem !important;
`;

const ResultEntryContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${textFontSize};
  border-radius: 0.4rem;
  cursor: auto;
  min-height: 2.6rem;
  padding: ${spaceTiny} ${spaceDecent};
  .primary-dark {
    display: none;
  }
  &:hover {
    .primary-dark {
      display: flex;
    }
    background-color: ${colors.surfaceHover} !important;
  }
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceTiny};
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

const RoundIconWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TurbineIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.surfaceBrand};
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${colors.iconNegative};
    }
  }
`;

const PreviousProblemResultsPlaceholder = () => {
  const minHeight = "1.7rem"; // NOTE: fit this to the height of ResultEntry
  const marginTop = "0.6rem";
  const marginBottom = marginTop;
  return (
    <>
      <div>
        <SkeletonBlock
          style={{
            flex: 1,
            minHeight,
            marginTop,
            marginBottom,
          }}
        />
      </div>
    </>
  );
};

const ResultItem = ({
  index,
  onClick,
  aep,
  lcoe,
  version,
  style,
}: {
  index: number;
  onClick: Parameters<typeof ResultEntry>[0]["onClick"];
  aep?: number;
  lcoe?: number;
  version: string;
  style?: React.CSSProperties;
}) => {
  return (
    <ResultEntry style={style}>
      <ResultEntryContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.7rem",
          }}
        >
          <p>Option {index}</p>
          {lcoe && (
            <p
              style={{
                color: colors.textSecondary,
                fontSize: "1rem",
              }}
            >
              ～{Math.round(10 * lcoe) / 10} €/MWh,
            </p>
          )}
          {aep && version >= "2.0.4" && (
            <p
              style={{
                color: colors.textSecondary,
                fontSize: "1rem",
              }}
            >
              ～{Math.round(aep / 1e3)} GWh
            </p>
          )}
        </div>
        <Button
          buttonType={"primary-dark"}
          icon={<AddIcon />}
          onClick={onClick}
          size={"small"}
          style={{
            height: "2.4rem",
          }}
        />
      </ResultEntryContent>
    </ResultEntry>
  );
};

const PreviousProblemResults = ({
  ids,
  problem,
  selectedRegion,
  onOptionClick,
}: {
  ids: Ids;
  problem: OptProblem;
  selectedRegion:
    | ParkFeature
    | SubAreaFeature
    | Feature<Polygon | MultiPolygon>;
  onOptionClick?(
    result: OptResult,
    problem: OptProblem,
    outdated: boolean,
    deselect: boolean,
  ): void;
}) => {
  const pids: Pids = useMemo(
    () => ({
      ...ids,
      id: problem.id,
    }),
    [ids, problem.id],
  );
  const results = useAtomValue(problemResultsAtom(pids));

  const { completed, invalid } = splitResultsByStatus(results?.items ?? []);

  const { getResults } = useOptimizationCrud();

  const trackEvent = useTrackEvent();
  const isOutdated = useMemo(() => {
    const polygons =
      selectedRegion.geometry.type === "Polygon"
        ? [selectedRegion.geometry.coordinates]
        : selectedRegion.geometry.coordinates;
    const selectedCoords = polygons.flatMap(
      (p) => p.flat() as [number, number][],
    );
    const optCoords = (problem.polygons ?? [[problem.parkPolygon]]).flatMap(
      (p) => p.flat(),
    );
    if (selectedCoords.length !== optCoords.length) return true;
    const parksAreDifferent =
      zip(selectedCoords, optCoords).find(
        ([p, q]) => !pointsAreEqual(p, q, 0.00001),
      ) !== undefined;
    return parksAreDifferent;
  }, [problem, selectedRegion]);

  const [clickedOption, setClickedOption] = useState<undefined | number>(
    undefined,
  );

  const progress = useAtomValue(totalProgressFamily(problem.id));
  const previousProgress = usePrevious(progress);
  useEffect(() => {
    if (progress !== previousProgress && progress === 1) {
      getResults(pids);
    }
  }, [getResults, pids, previousProgress, progress]);

  if (!results || results.items.length === 0) {
    return <PreviousProblemResultsPlaceholder />;
  }

  if (progress !== undefined && progress < 1.0) {
    return <progress value={progress} />;
  }

  const isExploration =
    completed.length >= 1 && "netAep" in completed[0] && completed[0].netAep;
  if (isExploration) return <Exploration problem={problem} items={completed} />;

  if (completed.length === 0 && invalid.length === 0) {
    return (
      <ResultEntry>
        <SimpleAlert
          text={
            "No solutions were found." +
            (problem.method === "regular"
              ? "Try to reduce the minimum spacing."
              : problem.method === "exploration"
                ? " Try to reduce the number of turbines or the minimum spacing."
                : "")
          }
          type={"info"}
        />
      </ResultEntry>
    );
  }
  if (completed.length === 0 && invalid.length > 0) {
    return (
      <ResultEntry>
        <SimpleAlert
          text={
            "Optimization could not run. Please check your input, and verify that the wind configuration is valid for the selected region."
          }
          type={"info"}
        />
      </ResultEntry>
    );
  }
  completed.sort((a, b) => {
    if (b.lcoe && a.lcoe) {
      return a.lcoe - b.lcoe;
    }
    return b.aep - a.aep;
  });

  return (
    <>
      {completed.slice(0, 3).map((r, i) => {
        return (
          <ResultItem
            index={i + 1}
            aep={r.aep}
            lcoe={r.lcoe ?? undefined}
            version={r.version}
            key={`${problem.id}-${i}`}
            style={{
              backgroundColor:
                i % 2 === 0 ? `${colors.surfaceSecondary}` : "auto",
            }}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onOptionClick?.(r, problem, isOutdated, i === clickedOption);
              setClickedOption(replaceOrUndefined(i));
              trackEvent("optionSelected");
            }}
          />
        );
      })}
    </>
  );
};

export const openOptListItem = atomJ<string | undefined>(undefined);
const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  margin-right: 1rem;
  cursor: pointer;
  margin-left: ${spacing1};
  transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
  transition: 0.1s;

  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const ResultListFooter = ({
  ids,
  problem,
}: {
  ids: Ids;
  problem: OptProblem;
}) => {
  const isPreview = useAtomValue(previewTurbinesState);
  const isExploration = problem.method === "exploration";
  const progress = useAtomValue(totalProgressFamily(problem.id));

  const { region, park } = useContext(OptimizeTurbinesContext) ?? {};
  const currentTurbineId = useAtomValue(
    currentTurbineIdAtom({
      projectId: ids.nodeId,
    }),
  );
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbineType = turbineTypes.get(currentTurbineId);
  const { saveTurbines } = useTurbineGeneration(park!, region!, turbineType!);
  const actuallySaveTurbines = useJotaiCallback(
    (get) => {
      const preview = get(previewTurbinesState);
      const newTurbines = preview?.preview;
      if (!newTurbines) return;
      setTurbineNames(newTurbines);
      saveTurbines(newTurbines);
    },
    [saveTurbines],
  );

  if (!isExploration || (progress && progress < 1)) return <div />;
  return (
    <Row
      style={{
        margin: "0.8rem 0 0.8rem auto",
      }}
    >
      <Button
        disabled={!isPreview}
        text="Save layout"
        size="small"
        onClick={actuallySaveTurbines}
        tooltip="Click to apply layout"
      />
    </Row>
  );
};

const OptimizationsList = ({
  optProblems,
  recentlyDeletedProblem,
  ids,
  selectedRegion,
  onOptionClick,
}: {
  optProblems: OptProblem[];
  recentlyDeletedProblem: string[];
  ids: Ids;
  selectedRegion: ResProps["selectedRegion"];
  onOptionClick: ResProps["onOptionClick"];
}) => {
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const [open, setOpen] = useAtom(openOptListItem);
  const isOnshore = useAtomValue(isOnshoreAtom);
  const { deleteProblem } = useOptimizationCrud();

  return (
    <>
      {optProblems.length === 0 && (
        <NoResultsWrapper>
          <TurbineIconWrapper>
            <SingleTurbine />
          </TurbineIconWrapper>
          <p>No previous optimizations.</p>
          <p>Optimisation results are stored for 3 months</p>
        </NoResultsWrapper>
      )}
      {optProblems
        .filter((p) => !(Date.now() / 1000 - p.createdAt > 88 * 24 * 60 * 60)) //Filter out problems older than 88 days
        .map((p) => {
          const dateStr = dateToDateTime(new Date(p.createdAt * 1000));
          const isMoreThan10WeeksOld =
            Date.now() / 1000 - p.createdAt > 10 * 7 * 24 * 60 * 60;

          return (
            <ProblemEntry key={p.id} open={open === p.id}>
              <ProblemEntryHeader
                onClick={() => {
                  setOpen(replaceOrUndefined(p.id));
                }}
              >
                <Tooltip
                  secondary
                  text={
                    "The result will soon be deleted. Layouts used in the project will not be affected."
                  }
                  position="right"
                  disabled={!isMoreThan10WeeksOld}
                >
                  <OverlineText
                    style={{
                      color: isMoreThan10WeeksOld
                        ? colors.textWarning
                        : colors.textSecondary,
                      justifySelf: "end",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.8rem",
                    }}
                  >
                    <ExpandArrowWrapper open={p.id === open}>
                      <IconREMSize height={0.8} width={0.8}>
                        <ChevronDownIcon />
                      </IconREMSize>
                    </ExpandArrowWrapper>
                    {dateStr}
                    {(p.method == "regular" || p.method == "irregular") &&
                      !isOnshore && (
                        <Tag text={p.objective === "lcoe" ? "LCoE" : "AEP"} />
                      )}
                    {p.method != "exploration" && (
                      <NumberOfTurbinesTag n={p.numberOfTurbines} />
                    )}
                    {p.method == "exploration" && (
                      <>
                        <NumberOfTurbinesTag
                          n={`${p.minNumberOfTurbines}-${p.numberOfTurbines}`}
                        />
                        <Tag text={"Irregular"} />
                      </>
                    )}
                  </OverlineText>
                </Tooltip>

                <Tooltip
                  text={
                    "Delete this optimization problem and its results. This cannot be undone"
                  }
                >
                  <TextIcon
                    onClick={async (event) => {
                      event.stopPropagation();
                      deleteProblem(
                        {
                          ...ids,
                          id: p.id,
                        },
                        recentlyDeletedProblem,
                      );
                    }}
                    scale={1.2}
                    style={{
                      padding: spaceTiny,
                      alignItems: "center",
                    }}
                  >
                    <Trashcan />
                  </TextIcon>
                </Tooltip>
              </ProblemEntryHeader>
              {p.id === open && (
                <Suspense fallback={<PreviousProblemResultsPlaceholder />}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PreviousProblemResults
                      ids={ids}
                      problem={p}
                      selectedRegion={selectedRegion}
                      onOptionClick={onOptionClick}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <OptimizationInput
                        key={p.id}
                        method={p.method}
                        minNumberOfTurbines={p.minNumberOfTurbines}
                        numberOfTurbines={p.numberOfTurbines}
                        costInput={p.costInput}
                        objective={p.objective}
                        turbineId={p.turbineId}
                        minSpacing={p.minSpacing}
                        windConfigurationId={p.windConfigurationId}
                        runtime={p.runtime}
                      />

                      {canEdit && <ResultListFooter ids={ids} problem={p} />}
                    </div>
                  </div>
                </Suspense>
              )}
            </ProblemEntry>
          );
        })}
    </>
  );
};

type ResProps = Parameters<typeof PreviousProblemResults>[0];

const _PreviousOptimizationsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const PreviousOptimizationsList = ({
  ids,
  selectedRegion,
  onOptionClick,
  method,
  showLoadingItem,
}: {
  ids: Ids;
  selectedRegion: ResProps["selectedRegion"];
  onOptionClick: ResProps["onOptionClick"];
  method: OptimizeParameters["method"];
  showLoadingItem?: boolean;
}) => {
  const recentlyDeletedProblem = useAtomValue(recentlyDeletedProblemAtom);
  const problems = useAtomValue(optProblemsAtom(ids));
  const problemsNotDeleted = problems.filter(
    (p) => !recentlyDeletedProblem.includes(p.id),
  );
  const isOnshore = useAtomValue(isOnshoreAtom);
  const [activeTab, setActiveTab] =
    useState<OptimizeParameters["method"]>(method);

  useEffect(() => {
    setActiveTab(method);
  }, [method]);

  const sortedProblems = useMemo(() => {
    const l = [...problemsNotDeleted];
    l.sort((a, b) => b.createdAt - a.createdAt);
    return l;
  }, [problemsNotDeleted]);

  return (
    <_PreviousOptimizationsList className="PreviousOptimizationsList">
      <span style={{ ...typography.body, paddingBottom: "1rem" }}>
        Optimizations are ran in the background.
      </span>
      <div style={{ display: "flex", gap: "0.8rem" }}>
        {!isOnshore && (
          <OvalTab
            text="Regular"
            active={activeTab === "regular"}
            onClick={() => setActiveTab("regular")}
          />
        )}
        <OvalTab
          text="Irregular"
          active={activeTab === "irregular"}
          onClick={() => setActiveTab("irregular")}
        />
        <OvalTab
          text="Micrositing"
          active={activeTab === "micrositing"}
          onClick={() => setActiveTab("micrositing")}
        />
        <OvalTab
          text="Exploration"
          active={activeTab === "exploration"}
          onClick={() => setActiveTab("exploration")}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          marginTop: "1.6rem",
        }}
      >
        {showLoadingItem && (
          <SkeletonText
            style={{
              width: "initial",
              height: "2rem",
            }}
          />
        )}
        <OptimizationsList
          optProblems={sortedProblems.filter((p) => p.method === activeTab)}
          recentlyDeletedProblem={recentlyDeletedProblem}
          ids={ids}
          selectedRegion={selectedRegion}
          onOptionClick={onOptionClick}
        />
      </div>
    </_PreviousOptimizationsList>
  );
};
