import { LineString } from "@turf/turf";
import { Point } from "geojson";
import { ProjectFeature } from "types/feature";
import { scream } from "./sentry";

/**
 * Replace the endpoints of the geometry of the given line string with the
 * position of the two Point features. This is useful e.g. for ensuring that
 * the coordinates of a mooring line or a cable is consistent with it's
 * endpoints.
 */
export const replaceEndpoints = <F extends ProjectFeature<LineString>>(
  feature: F,
  from: ProjectFeature<Point> | undefined,
  to: ProjectFeature<Point> | undefined,
): F => {
  let f = from?.geometry?.coordinates ?? feature.geometry.coordinates.at(0);
  let t = to?.geometry?.coordinates ?? feature.geometry.coordinates.at(-1);
  if (!f || !t) throw scream("Input line feature was empty", feature);

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [f, ...feature.geometry.coordinates.slice(1, -1), t],
    },
  };
};
