import React, { useEffect, useState, useCallback, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import { ProjectFeature } from "types/feature";
import {
  getPopupPlacement,
  getPopupPlacementMultiFeature,
} from "components/CanvasSelectOption/Canvas.style";
import { getTopCenter } from "utils/turf";
import { getCenterOfFeatures } from "utils/geometry";
import usePrevious from "hooks/usePrevious";
import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import * as turf from "@turf/turf";
import { sendInfo } from "utils/sentry";

const POPUP_PADDING_y = 100; // Padding from viewport edges
const POPUP_PADDING_x = 250; // Padding from viewport edges
const FEATURE_BOX_PADDING = 50; // Padding around the features bounding box

const getDefaultPosition = (selectedProjectFeatures: ProjectFeature[]) => {
  return selectedProjectFeatures.length === 1
    ? getPopupPlacement(getTopCenter(selectedProjectFeatures[0]))
    : getPopupPlacementMultiFeature(
        getCenterOfFeatures(selectedProjectFeatures),
      );
};

const getFeaturesBoundingBox = (features: ProjectFeature[]): turf.BBox => {
  const featureCollection = turf.featureCollection(features);
  const bbox = turf.bbox(featureCollection);
  return bbox;
};

const useMoveablePopup = (
  selectedProjectFeatures: ProjectFeature[],
  recalculateOnCameraChange: boolean = false,
  popupRef: React.RefObject<HTMLDivElement>,
) => {
  const map = useAtomValue(mapAtom);
  const [mouseDownPosition, setMouseDownPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);
  const defaultPosition = useMemo(
    () => getDefaultPosition(selectedProjectFeatures),
    [selectedProjectFeatures],
  );
  const [startPosition, setStartPosition] = useState(defaultPosition);
  const [hasMoved, setHasMoved] = useState(false);
  const [popupPlacement, setPopupPlacement] = useState(defaultPosition);

  const featuresBoundingBox = useMemo(() => {
    return getFeaturesBoundingBox(selectedProjectFeatures);
  }, [selectedProjectFeatures]);

  const previousSelectedProjectFeatures = usePrevious(selectedProjectFeatures);

  const calculateSmartPosition = useCallback(
    (defaultPos: mapboxgl.LngLat): mapboxgl.LngLat => {
      if (!map || !popupRef.current) return defaultPos;

      const { x, y } = map.project(defaultPos);
      if (isNaN(x) || isNaN(y)) {
        sendInfo("x/y was NaN", {
          x,
          y,
        });
        return defaultPos;
      }
      const { width, height } = map.getCanvas();
      const popupWidth = popupRef.current.offsetWidth;
      const popupHeight = popupRef.current.offsetHeight;

      const projectedBottomLeft = map.project(
        new mapboxgl.LngLat(featuresBoundingBox[0], featuresBoundingBox[1]),
      );
      const projectedTopRight = map.project(
        new mapboxgl.LngLat(featuresBoundingBox[2], featuresBoundingBox[3]),
      );

      // Add padding to the bounding box
      const boxMinX = projectedBottomLeft.x - FEATURE_BOX_PADDING;
      const boxMinY = projectedTopRight.y - FEATURE_BOX_PADDING;
      const boxMaxX = projectedTopRight.x + FEATURE_BOX_PADDING;
      const boxMaxY = projectedBottomLeft.y + FEATURE_BOX_PADDING;

      // 1: Ensure the popup stays within the viewport
      let newX = Math.max(
        POPUP_PADDING_x,
        Math.min(x, width / 2 - popupWidth - POPUP_PADDING_x),
      );
      let newY = Math.max(
        POPUP_PADDING_y,
        Math.min(y, height / 2 - popupHeight - POPUP_PADDING_y),
      );

      // 2: Ensure the popup stays within the bounding box
      newX = Math.max(boxMinX, Math.min(newX, boxMaxX));
      newY = Math.max(boxMinY, Math.min(newY, boxMaxY));
      if (
        isNaN(newX) ||
        isNaN(newY) ||
        1e5 < Math.abs(newX) ||
        1e5 < Math.abs(newY)
      ) {
        return defaultPos;
      }

      return map.unproject([newX, newY]);
    },
    [map, popupRef, featuresBoundingBox],
  );

  const recalculatePosition = useCallback(() => {
    if (!map) return;
    const pos = calculateSmartPosition(
      new mapboxgl.LngLat(defaultPosition.lng, defaultPosition.lat),
    );
    setPopupPlacement(pos);
    setStartPosition(pos);
  }, [map, calculateSmartPosition, defaultPosition]);

  // Reset position if new features are selected
  useEffect(() => {
    const featureIds = selectedProjectFeatures.map((feature) => feature.id);
    const previousSelectedProjectFeatureIds =
      previousSelectedProjectFeatures?.map((feature) => feature.id) ?? [];

    if (
      hasMoved &&
      previousSelectedProjectFeatureIds.some((id) =>
        featureIds.find((featureId) => featureId === id),
      )
    ) {
      return;
    }

    recalculatePosition();
    setHasMoved(false);
  }, [
    hasMoved,
    previousSelectedProjectFeatures,
    selectedProjectFeatures,
    recalculatePosition,
  ]);

  useEffect(() => {
    if (!mouseDownPosition || !map || !popupRef.current) return;

    const onMouseMove = (e: MouseEvent) => {
      const project = map.project(startPosition);
      project.x = project.x + (e.clientX - mouseDownPosition.x);
      project.y = project.y + (e.clientY - mouseDownPosition.y);
      setPopupPlacement(map.unproject(project));
    };

    const onMouseUp = (_e: MouseEvent) => {
      setMouseDownPosition(undefined);
      setStartPosition(popupPlacement);
      setHasMoved(true);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [
    map,
    mouseDownPosition,
    popupPlacement,
    startPosition,
    popupRef,
    calculateSmartPosition,
  ]);

  // Add effect for recalculating position on camera changes
  useEffect(() => {
    if (!map || !recalculateOnCameraChange) return;

    const onMoveEnd = () => {
      if (!hasMoved) {
        recalculatePosition();
      }
    };

    const onZoomEnd = () => {
      if (!hasMoved) {
        recalculatePosition();
      }
    };

    map.on("moveend", onMoveEnd);
    map.on("zoomend", onZoomEnd);

    return () => {
      map.off("moveend", onMoveEnd);
      map.off("zoomend", onZoomEnd);
    };
  }, [map, recalculateOnCameraChange, hasMoved, recalculatePosition]);

  return {
    popupPlacement,
    isMoving: !!mouseDownPosition,
    setMouseDownPosition,
    recalculatePosition,
  };
};

export default useMoveablePopup;
