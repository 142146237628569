import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { accessToken, setAccessTokenGlobal } from "../state/global";
import { getTokenAtom, tokenAtom } from "../state/user";
import { parseJWT } from "utils/jwt";
import { useSetAtom } from "jotai";

export const useForceFetchNewTokenOnMount = () => {
  const { isAuthenticated } = useAuth0();
  const fetchNewToken = useCheckAccessToken();

  useEffect(() => {
    if (isAuthenticated) {
      fetchNewToken(true);
    }
  }, [fetchNewToken, isAuthenticated]);

  return null;
};

export const useCheckAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const setToken = useSetAtom(getTokenAtom);
  const setJotaiToken = useSetAtom(tokenAtom);

  const fetchNewToken = useCallback(
    async (force?: boolean) => {
      const newToken = await getAccessTokenSilently({
        ignoreCache: Boolean(force),
      });
      setAccessTokenGlobal(newToken);
      setToken(newToken);
      setJotaiToken(newToken);
    },
    [getAccessTokenSilently, setJotaiToken, setToken],
  );

  const func = useCallback(
    async (force?: boolean) => {
      if (force) {
        await fetchNewToken(force);
        return;
      }

      if (!accessToken) return;
      const decodedMiddleBit = parseJWT(accessToken);
      const expirationDate = new Date(decodedMiddleBit["exp"] * 1000).getTime();
      const inThirtySeconds = new Date().getTime() + 30 * 1000;
      if (inThirtySeconds > expirationDate) {
        fetchNewToken();
      }
    },
    [fetchNewToken],
  );

  return func;
};

export function useRefreshTokenBeforeExpiration() {
  const checkAccessToken = useCheckAccessToken();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkAccessToken(true);
      }
    };

    // recheck token every 10s ...
    const ref = setInterval(checkAccessToken, 10_000);
    // ... and when the user returns to the browser tab
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(ref);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [checkAccessToken]);
}
