import { useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import { projectIdAtom } from "state/pathParams";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getVisibleLayers } from "./LayerSettings/utils";
import { getLayerIdHash } from "./utils";
import { LAYER_URL_KEY, COLLECTION_URL_KEY } from "./const";

export default function useSyncVisibleLayerWithUrl() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [searchParams, setSearchParams] = useSearchParams();

  const visibleLayers = useAtomValue(
    unwrap(
      getVisibleLayers({
        projectId,
      }),
    ),
  );

  useEffect(() => {
    const oldLayerValue = searchParams.get(LAYER_URL_KEY);
    const oldCollectionValue = searchParams.get(COLLECTION_URL_KEY);

    if (visibleLayers) {
      const visibleLayerIdsHash = visibleLayers
        .map((l) => getLayerIdHash(l.id))
        .join("");

      if (visibleLayerIdsHash.length > 0) {
        searchParams.set(LAYER_URL_KEY, visibleLayerIdsHash);
      } else {
        searchParams.delete(LAYER_URL_KEY);
      }
    }

    const newLayerValue = searchParams.get(LAYER_URL_KEY);
    const newCollectionValue = searchParams.get(COLLECTION_URL_KEY);

    if (
      newLayerValue !== oldLayerValue ||
      newCollectionValue !== oldCollectionValue
    ) {
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [setSearchParams, visibleLayers, searchParams]);
}
