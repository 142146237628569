import React, { useEffect, useRef } from "react";
import {
  Modal,
  Wrapper,
  HeaderRow,
  Title,
  FoundationColoredGridPreviewItem,
  TableHeader,
  TableSubHeader,
  GridKey,
  GridValue,
  GridValueLeft,
} from "./style";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import { useSetAtom, useAtomValue } from "jotai";
import { useCallback } from "react";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { listProcurementCostForLibraryFoundationsOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { localCostConfigurationAtom } from "components/ConfigurationModal/Cost/state";
import { spaceSmall, spaceMedium } from "styles/space";
import styled from "styled-components";
import { typography } from "styles/typography";
import {
  capitalizeFirstLetter,
  formatMaterialName,
} from "components/Organisation/Library/financial/ProcurementCosts/Foundation/FoundationCostTable";
import { useClickOutside } from "hooks/useClickOutside";
import { foundationCategoriesToDisplayAtom } from "components/ConfigurationModal/Cost/Capex/FoundationsCosts";
import { foundationProcurementCostsAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { CostConfiguration } from "services/costService";
import { FoundationProcurementCost } from "services/procurementCostService";

const FoundationTypeTd = styled(GridKey)`
  ${typography.sub2};
`;

const FoundationComponentsPreview = ({
  foundationCosts,
  categoriesToDisplay,
  localConfig,
}: {
  foundationCosts: FoundationProcurementCost[];
  categoriesToDisplay: string[];
  localConfig: CostConfiguration;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose, () => false, {
    ignoreDragClicks: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const { foundations } = localConfig.capex.fixed;

  if (!foundations || !("floatingCostReference" in foundations)) {
    return null;
  }

  const foundationTypes = [
    { type: "jacket", reference: foundations.jacketCostReference },
    { type: "monopile", reference: foundations.monopileCostReference },
    { type: "floating", reference: foundations.floatingCostReference },
  ] as const;

  const foundationCostsToDisplay = foundationTypes.filter(({ type }) =>
    categoriesToDisplay.includes(type),
  );

  return (
    <Wrapper>
      <Modal ref={containerRef} style={{ width: "100rem", maxWidth: "80%" }}>
        <HeaderRow
          style={{ paddingLeft: spaceSmall, paddingBottom: spaceMedium }}
        >
          <Title>Foundation materials</Title>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        {foundationCostsToDisplay.map(({ type, reference }) => {
          const selectedTable = foundationCosts.find(
            (cost) => cost.id === reference,
          );
          if (!selectedTable) return null;

          const foundationData = selectedTable.costs[type];
          const materials = Object.entries(foundationData.material);

          return (
            <FoundationColoredGridPreviewItem key={type}>
              <React.Fragment key="header">
                <TableHeader>{selectedTable.tableName}</TableHeader>
                <TableHeader>Supply</TableHeader>
                <TableHeader>Shipping</TableHeader>
                <TableHeader />
                <TableHeader />
                <TableHeader />
              </React.Fragment>
              <React.Fragment key="sub-header">
                <TableSubHeader>Material</TableSubHeader>
                <TableSubHeader>Cost</TableSubHeader>
                <TableSubHeader>EUROPE</TableSubHeader>
                <TableSubHeader>ASIA</TableSubHeader>
                <TableSubHeader>USA</TableSubHeader>
                <TableSubHeader>Other</TableSubHeader>
              </React.Fragment>
              {/* Foundation Type Row */}
              <FoundationTypeTd>{capitalizeFirstLetter(type)}</FoundationTypeTd>
              <GridValue></GridValue> {/* Empty cell for supply cost */}
              {foundationData.shippingCost.map((transportCost) => (
                <GridValueLeft key={transportCost.region}>
                  <strong>{transportCost.cost}</strong>
                  {transportCost.unit}
                </GridValueLeft>
              ))}
              {/* Material Rows */}
              {materials.map(([material, supplyCost]) => (
                <React.Fragment key={`${type}-${material}`}>
                  <GridKey>{formatMaterialName(material)}</GridKey>
                  <GridValueLeft>
                    <strong>{supplyCost.cost}</strong>
                    {supplyCost.unit}
                  </GridValueLeft>
                  {/* Empty cells for shipping costs in material rows */}
                  <GridValue></GridValue>
                  <GridValue></GridValue>
                  <GridValue></GridValue>
                  <GridValue></GridValue>
                </React.Fragment>
              ))}
            </FoundationColoredGridPreviewItem>
          );
        })}
      </Modal>
    </Wrapper>
  );
};

const FoundationComponentsPreviewLibrary = ({
  organisationId,
}: {
  organisationId: string;
}) => {
  const foundationCosts = useAtomValue(
    foundationProcurementCostsAtom(organisationId),
  );

  const localConfig = useAtomValue(localCostConfigurationAtom);

  const categoriesToDisplay = useAtomValue(
    foundationCategoriesToDisplayAtom({
      _nodeId: organisationId,
      _configurationId: localConfig.id,
    }),
  ).map((c) => c.toLowerCase());

  return (
    <FoundationComponentsPreview
      foundationCosts={foundationCosts}
      categoriesToDisplay={categoriesToDisplay}
      localConfig={localConfig}
    />
  );
};

const FoundationComponentsPreviewProject = ({
  projectId,
  organisationId,
}: {
  projectId: string;
  organisationId: string;
}) => {
  const foundationCosts = useAtomValue(
    listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
  );

  const localConfig = useAtomValue(localCostConfigurationAtom);

  const categoriesToDisplay = useAtomValue(
    foundationCategoriesToDisplayAtom({
      _nodeId: organisationId,
      _configurationId: localConfig.id,
    }),
  ).map((c) => c.toLowerCase());

  return (
    <FoundationComponentsPreview
      foundationCosts={foundationCosts}
      categoriesToDisplay={categoriesToDisplay}
      localConfig={localConfig}
    />
  );
};

export const FoundationComponentsPreviewV2 = () => {
  const projectId = useAtomValue(projectIdAtom);
  const organisationId = useAtomValue(organisationIdAtom)!;

  if (projectId) {
    return (
      <FoundationComponentsPreviewProject
        projectId={projectId}
        organisationId={organisationId}
      />
    );
  }
  return <FoundationComponentsPreviewLibrary organisationId={organisationId} />;
};
