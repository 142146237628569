import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { fetchEnhancer } from "../services/utils";
import {
  TileJSONMetadata,
  _TileJSONMetadata,
  Layer,
  SourceTypesLayer,
  ExternalDataSourceLinkLayerWithSourceTileJSON,
} from "../types/layers";

export const isTileJSONLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceTileJSON => {
  return layer.sourceType === SourceTypesLayer.tilejson;
};

export const fetchTileJSONServerSelectorFamily = atomFamily((url: string) =>
  atom<Promise<Blob | undefined>>(async () => {
    try {
      const response = await fetchEnhancer(url, {
        method: "get",
      });
      return response.blob();
    } catch (err) {
      console.warn(`Could not read from WFS server: ${url}, ${err}`);
    }
  }),
);

export const tileJSONServerFullMetadataSelector = atomFamily((url: string) =>
  atom<Promise<TileJSONMetadata | undefined>>(async () => {
    try {
      const response = await fetchEnhancer(`${url}?f=json`, {
        method: "get",
      });
      return _TileJSONMetadata.parse(await response.json());
    } catch (err) {
      console.warn(`Could not read from WFS server: ${url}, ${err}`);
      return undefined;
    }
  }),
);
