import React, { useMemo } from "react";
import { useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import Fuse from "fuse.js";
import { colors } from "styles/colors";
import { projectIdAtom } from "state/pathParams";
import CloseIcon from "@icons/24/Close.svg";
import useTextInput from "hooks/useTextInput";
import { BranchMeta } from "types/api";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { spacing4, spacing7 } from "styles/space";
import { SearchInput } from "components/General/Input";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { selectedParksAtom } from "../../state";
import { branchMetasFamily } from "state/jotai/branch";
import NoItemsGeneric from "components/General/NoItemsGeneric";
import { SkeletonBlock } from "components/Loading/Skeleton";
import BranchesTable from "./BranchesTable";

const ParkCounterPill = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 1rem;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${colors.surfaceSelectedLight};
`;

const NumberSelectedParksPill = ({ nodeId }: { nodeId: string }) => {
  const [selectedParks, setSelectedParks] = useAtom(
    selectedParksAtom({ projectId: nodeId }),
  );

  return (
    <div style={{ display: "flex" }}>
      <ParkCounterPill
        onClick={() => {
          setSelectedParks([]);
        }}
        title="Clear all"
      >
        <div>
          {selectedParks.length > 0 ? selectedParks.length : "No"} selected park
          {selectedParks.length !== 1 ? "s" : ""}
        </div>
        <CloseIcon
          style={{
            width: "8px",
            height: "8px",
            visibility: selectedParks.length === 0 ? "hidden" : undefined,
          }}
        />
      </ParkCounterPill>
    </div>
  );
};

const BranchProjectPicker = React.forwardRef<
  HTMLDivElement,
  { onExit(): void }
>(({ onExit }, elementRef) => {
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const branchMetaObjects_ = useAtomValue(
    branchMetasFamily({ projectId: nodeId }),
  );
  const branchMetaObjects = useMemo(
    () => Array.from(branchMetaObjects_.values()),
    [branchMetaObjects_],
  );

  useHorizontalResize(
    elementRef as React.RefObject<HTMLElement>,
    "--branch-projects-frame-width",
  );
  const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
  const fuse = useMemo(
    () =>
      new Fuse(branchMetaObjects, {
        keys: ["title"],
        includeScore: true,
        threshold: 0.3,
      }),
    [branchMetaObjects],
  );

  const branchesSearchResult = useMemo<BranchMeta[]>(() => {
    return searchValue.length > 0
      ? fuse.search(searchValue).map((result) => result.item)
      : branchMetaObjects;
  }, [fuse, searchValue, branchMetaObjects]);

  return (
    <MenuFrame
      ref={elementRef}
      title="Select parks"
      onExit={onExit}
      style={{
        position: "absolute",
        left: spacing4,
        overflow: "hidden",
        maxHeight: "70vh",
        width: "var(--branch-projects-frame-width, 60rem)",
        zIndex: 5,
        resize: "horizontal",
        minWidth: "40rem",
        maxWidth: "50vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: `0 ${spacing7}`,
          gap: spacing7,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <React.Suspense
          fallback={
            <SkeletonBlock
              style={{
                height: "3rem",
              }}
            />
          }
        >
          <SearchInput
            style={{ width: "100%" }}
            wrapperDivStyle={{ marginBottom: "" }}
            placeholder="Search for branch"
            value={searchValue}
            onChange={onSearchValueChange}
            onClear={() => {
              setSearchValue("");
            }}
          />
          {nodeId && <NumberSelectedParksPill nodeId={nodeId} />}
          {searchValue !== "" && branchesSearchResult.length === 0 ? (
            <NoItemsGeneric
              headerText="No results"
              subText="We couldn't find any branches matching your search."
            />
          ) : (
            <BranchesTable
              isSearching={searchValue !== ""}
              branchesSearchResult={branchesSearchResult}
            />
          )}
        </React.Suspense>
      </div>
    </MenuFrame>
  );
});

export default BranchProjectPicker;
