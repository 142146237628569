import * as Sentry from "@sentry/react";
import { atom } from "jotai";
import * as turf from "@turf/turf";
import { makeCableForest } from "components/Cabling/CableWalk";
import { lazyWaveLengthCorrection } from "functions/lazyWave";
import { CableType } from "services/cableTypeService";
import { CableFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import {
  isDefined,
  isFloater,
  isNumber,
  isPointFeature,
  isTurbine,
} from "utils/predicates";
import { sendWarning } from "utils/sentry";
import { sum } from "utils/utils";
import {
  analysisConfigurationSelectedFamily,
  analysisConfigurationsFamily,
} from "./analysisConfiguration";
import { cableTypesFamily } from "./cableType";
import {
  featureMapFamily,
  featuresFamily,
  featuresInParkFamily,
} from "./features";
import { foundationTypesAtom } from "./foundation";
import { substationsInParkFamily } from "./substation";
import { turbinesInParkFamily } from "./turbine";
import { simpleTurbineTypesAtom } from "./turbineType";
import { projectIdAtom } from "state/pathParams";
import { fetchDepthsForCoordinates } from "services/bathymertyService";
import { SimpleTurbineType } from "types/turbines";

export const cablesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<CableFeature[]>>(async (get) => {
      const { cable } = await get(featuresFamily({ branchId }));

      return cable;
    }),
);

export const cablesInParkFamily = atomFamily(
  ({ branchId, parkId }: { branchId: string | undefined; parkId: string }) =>
    atom<Promise<CableFeature[]>>(async (get) => {
      const { cable } = await get(featuresInParkFamily({ branchId, parkId }));

      return cable;
    }),
);

export const cablesInParkWithType = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<[CableFeature, CableType][]>>(async (get) => {
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const types = await get(cableTypesFamily({ projectId: undefined }));
      return cables
        .map<[CableFeature, CableType] | undefined>((t) => {
          const typ = types.get(t.properties.cableTypeId ?? "");
          if (!typ) {
            Sentry.addBreadcrumb({
              category: "missingCableType",
              message: "Cable type for cable not found",
              data: {
                parkId,
                branchId,
                cableTypeId: t.properties.cableTypeId,
                cableId: t.id,
              },
            });
            return undefined;
          }
          return [t, typ];
        })
        .filter(isDefined);
    }),
);

/**
 * A forest for the inter-array system. Each {@link Tree} is the connections of
 * one substation. The `.data` field on the root nodes are the substations. On
 * the remaining nodes it is the turbine and the cable leading into the
 * turbine.
 */
export const cableForestFamily = atomFamily(
  ({ branchId, parkId }: { branchId: string | undefined; parkId: string }) =>
    atom(async (get) => {
      const cables = (
        await get(cablesInParkFamily({ parkId, branchId }))
      ).filter((c) => !c.properties.redundancy);
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      return makeCableForest(cables, substations, turbines);
    }),
);

/**
 * Create a {@link Map} from cable ids to the power that flows through the
 * cable. In addition, the substation ids in the map contain the total power
 * into the substation.
 *
 * Output is in Watts.
 */
export const cableLoadsFamily = atomFamily(
  ({
    branchId,
    parkId,
    turbineTypeOverride,
  }: {
    branchId: string | undefined;
    parkId: string;
    turbineTypeOverride: SimpleTurbineType | undefined;
  }) =>
    atom<Promise<Map<string, number>>>(async (get) => {
      const turbineTypes = await get(simpleTurbineTypesAtom);

      const forest = await get(cableForestFamily({ parkId, branchId }));

      const loads = forest.map((tree) =>
        tree.transformUp<[string, number]>((n, children) => {
          const s = sum(children, (n) => n.data[1]);
          if ("id" in n) return [n.id, s]; // substation; the root node

          const rated =
            turbineTypeOverride?.ratedPower ??
            turbineTypes.get(n.turbine.properties.turbineTypeId)?.ratedPower;
          if (rated === undefined) {
            return [n.cable.id, s];
          }

          return [n.cable.id, s + rated * 1e3]; // output is Watts.
        }),
      );
      return new Map(loads.flatMap((t) => t.flatten()));
    }),
);

export const cable3DLengthsFamily = atomFamily(
  ({
    parkId,
    branchId,
    analysisConfigurationId,
  }: {
    parkId: string;
    branchId: string | undefined;
    analysisConfigurationId: string | undefined;
  }) =>
    atom(async (get) => {
      const ret = new Map<
        string,
        {
          straight: number;
          threeD: number;
          contingent: number;
        }
      >();

      const projectId = get(projectIdAtom);
      if (!projectId) throw new Error("Missing projectId");
      const featureMap = await get(featureMapFamily({ branchId }));

      const foundationTypes = await get(foundationTypesAtom);
      const isFloatingTurbine = (id: string): boolean => {
        const f = featureMap.get(id);
        if (!isTurbine(f)) return false;
        const typ = foundationTypes.get(f.properties.foundationId ?? "");
        return isFloater(typ);
      };

      const cables = await get(cablesInParkFamily({ parkId, branchId }));

      if (cables.length === 0) {
        return ret;
      }

      const analysis =
        (await get(analysisConfigurationsFamily({ projectId }))).get(
          analysisConfigurationId ?? "",
        ) ??
        (await get(
          analysisConfigurationSelectedFamily({
            branchId,
            projectId,
          }),
        ));
      if (!analysis) throw new Error("Missing analysis configuration");

      const cableWithCoords = cables.map((cable) => {
        const from = featureMap.get(cable.properties.fromId);
        const to = featureMap.get(cable.properties.toId);
        let fromCoords = cable.geometry.coordinates[0];
        let toCoords =
          cable.geometry.coordinates[cable.geometry.coordinates.length - 1];

        if (isPointFeature(from)) fromCoords = from.geometry.coordinates;
        if (isPointFeature(to)) toCoords = to.geometry.coordinates;

        return {
          cable,
          coordinates: [fromCoords, toCoords],
          from,
          to,
        };
      });

      const depths = await fetchDepthsForCoordinates(
        cableWithCoords.flatMap((c) => c.coordinates),
      );

      cableWithCoords.forEach((cableCoord, currentIndex) => {
        const { from, to, cable } = cableCoord;

        // We send the coordinates flattened to the backend, and every cable has 2 positions (fromCoord, toCoord),
        // so we need to multiply the index by 2 to find this cable's depths
        const index = currentIndex * 2;
        if (
          typeof depths.samples[index] === "undefined" ||
          typeof depths.samples[index + 1] === "undefined"
        ) {
          sendWarning(new Error("Depth not found for cable coordinates"), {
            cableId: cable.id,
            coordinates: cable.geometry.coordinates,
          });
          return;
        }
        // Number is negative because the depths are negative, so we need to invert them
        let fromDepth = depths.samples[index][2] * -1;
        let toDepth = depths.samples[index + 1][2] * -1;

        // If we are offshore, we do two corrections to 3d cable lengths:
        //  1. Include up/down distances at the endpoints, and
        //  2. Lazy-wave correction.
        //
        // If we are onshore, we stick with pythagoras for now.
        const straight = turf.length(cable, { units: "kilometers" });
        // If both endpoints are on land, we just use pythagoras. Otherwise, we're offshore.
        const onshore = fromDepth <= 0 && toDepth <= 0;
        let threeD = straight;
        if (onshore) {
          const heightDiff = Math.abs(toDepth - fromDepth) / 1000;
          threeD = Math.sqrt(heightDiff * heightDiff + straight * straight);
        } else {
          // If either endpoint is on land, don't count the heights.
          // This isn't super accurate, for instance if the substation is on a tall hill,
          // but (a) this is probably not realistic, and (b) we don't have landfall points
          // for regular cables.
          if (from && 0 <= fromDepth)
            threeD +=
              fromDepth / 1000 +
              (isFloatingTurbine(from.id)
                ? lazyWaveLengthCorrection({ waterDepth: fromDepth }) / 1000
                : 0);
          if (to && 0 <= toDepth)
            threeD +=
              toDepth / 1000 +
              (isFloatingTurbine(to.id)
                ? lazyWaveLengthCorrection({ waterDepth: toDepth }) / 1000
                : 0);
        }

        const contingentFactor = analysis.electrical
          .cableLengthContingencyEnabled
          ? analysis.electrical.cableLengthContingency
          : 0.0;

        const contingent = threeD * (1 + contingentFactor);
        ret.set(cable.id, {
          straight,
          threeD,
          contingent: isNumber(contingent) ? contingent : 0,
        });
      });

      return ret;
    }),
);
