import { branchIdAtom } from "state/pathParams";
import { RenderAnchors } from "components/Mapbox/Anchors";
import { RenderCableCorridor } from "components/Mapbox/CableCorridors";
import { RenderCables } from "./Cables";
import { RenderDivisions } from "components/Mapbox/Divisions";
import { RenderExportCables } from "components/Mapbox/ExportCables";
import { RenderMooringLines } from "components/Mapbox/MooringLines";
import { RenderOtherLineStrings } from "components/Mapbox/OtherLineStrings";
import { RenderOtherPoints } from "components/Mapbox/OtherPoints";
import { RenderOtherPolygons } from "components/Mapbox/OtherPolygons";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderSubstations } from "components/Mapbox/Substations";
import {
  RenderTurbineDiameterCircle,
  RenderTurbineEllipsis,
  RenderTurbines,
} from "components/Mapbox/Turbines";
import { Map } from "mapbox-gl";
import { useMemo } from "react";
import { hiddenFeatureIdsFamily } from "state/jotai/hidden";
import { DivisionFeature, ProjectFeature } from "types/feature";
import { RenderExistingTurbines } from "./ExistingTurbines";
import { parkIdAtom } from "state/pathParams";
import {
  EllipsesFeature,
  ellipsesPerTurbineAtom,
} from "components/TurbineEllipsesSettings/state";
import { CableFreeSectorWhenEditing } from "components/CableFreeSector/CableFreeSectors";
import { atom, useAtomValue } from "jotai";
import { featuresFamily } from "state/jotai/features";
import { turbinesFamily } from "state/jotai/turbine";
import { anchorsFamily } from "state/jotai/anchor";
import { mooringLinesFamily } from "state/jotai/mooringLine";
import {
  otherLineFeaturesFamily,
  otherPointFeaturesFamily,
  otherPolygonFeaturesFamily,
} from "state/jotai/other";
import { previewTurbinesState } from "state/turbines";
import { partition } from "utils/utils";
import { inPark } from "state/park";
import { editmodePropertyName, ghostPropertyName } from "./constants";
import { jointPreviewMooringState } from "components/GenerateFoundationsAndAnchors/state";
import { subAreasConstrainedFamily } from "state/jotai/subArea";
import { RenderViewpoints } from "./Viewpoint";
import { RenderPorts } from "./Port";
import { RenderSensorPoints } from "./SensorPoint";

const turbinesToShow = atom(async (get) => {
  const turbines = await get(
    turbinesFamily({
      branchId: undefined,
    }),
  );
  const previewState = get(previewTurbinesState);
  if (!previewState) return turbines;
  const parkId = get(parkIdAtom);
  if (!parkId) return turbines;

  const [ghostTurbines, otherTurbines] = partition(turbines, inPark(parkId));
  return otherTurbines
    .concat(
      ghostTurbines.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          [ghostPropertyName]: true,
        },
      })),
    )
    .concat(previewState.existing)
    .concat(
      previewState.preview.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          [editmodePropertyName]: true,
        },
      })),
    );
});

const anchorsToShow = atom(async (get) => {
  const anchors = await get(
    anchorsFamily({
      branchId: undefined,
    }),
  );
  const preview = get(jointPreviewMooringState);
  if (!preview) return anchors;
  const parkId = get(parkIdAtom);
  if (!parkId) return anchors;

  const [ghostAnchors, otherAnchors] = partition(anchors, inPark(parkId));
  return otherAnchors
    .concat(
      ghostAnchors.map((f) => ({
        ...f,
        properties: {
          ...f.properties,
          [ghostPropertyName]: true,
        },
      })),
    )
    .concat(preview.existing.anchors)
    .concat(
      preview.preview.anchors.map((f) => ({
        ...f,
        properties: {
          ...f.properties,
          [editmodePropertyName]: true,
        },
      })),
    );
});
const mooringLinesToShow = atom(async (get) => {
  const mooringLines = await get(
    mooringLinesFamily({
      branchId: undefined,
    }),
  );
  const preview = get(jointPreviewMooringState);
  if (!preview) return mooringLines;
  const parkId = get(parkIdAtom);
  if (!parkId) return mooringLines;

  const [ghostAnchors, otherAnchors] = partition(mooringLines, inPark(parkId));
  return otherAnchors
    .concat(
      ghostAnchors.map((f) => ({
        ...f,
        properties: {
          ...f.properties,
          [ghostPropertyName]: true,
        },
      })),
    )
    .concat(preview.existing.mooringLines)
    .concat(
      preview.preview.mooringLines.map((f) => ({
        ...f,
        properties: {
          ...f.properties,
          [editmodePropertyName]: true,
        },
      })),
    );
});

export const FeatureLayers = ({ map }: { map: Map }) => {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const hidden = useAtomValue(
    hiddenFeatureIdsFamily({
      branchId,
    }),
  );

  const isVisible = useMemo(
    () => (f: ProjectFeature) => !hidden.includes(f.id),
    [hidden],
  );

  const {
    park: parks,
    existingTurbine: existingTurbines,
    substation: substations,
    exportCable: exportCables,
    cableCorridor: cableCorridors,
    cable: cables,
    exclusionZone: exclusionZones,
    bathymetry: bathymetries,
    geotiff: geotiffs,
    viewpoint: viewpoints,
    sensor: sensorPoints,
    port: ports,
  } = useAtomValue(
    featuresFamily({
      branchId: undefined,
    }),
  );

  const subAreas = useAtomValue(
    subAreasConstrainedFamily({
      branchId: undefined,
    }),
  );

  const turbines = useAtomValue(turbinesToShow);
  const anchors = useAtomValue(anchorsToShow);
  const mooringLines = useAtomValue(mooringLinesToShow);

  const turbineEllipses = useAtomValue(ellipsesPerTurbineAtom);

  const divisions = useMemo(() => {
    const ret: DivisionFeature[] = subAreas;
    return ret.concat(exclusionZones);
  }, [exclusionZones, subAreas]);

  const visibleEllipsis: Record<string, EllipsesFeature> = useMemo(() => {
    if (!turbineEllipses) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(turbineEllipses)
        .filter(([_, ellipsis]) => ellipsis.show)
        .map(([key, ellipsis]) => [key, ellipsis as EllipsesFeature]),
    );
  }, [turbineEllipses]);

  const otherPointFeatures = useAtomValue(
    otherPointFeaturesFamily({
      branchId: undefined,
    }),
  );
  const otherLineStringFeatures = useAtomValue(
    otherLineFeaturesFamily({
      branchId: undefined,
    }),
  );
  const otherPolygonFeatures = useAtomValue(
    otherPolygonFeaturesFamily({
      branchId: undefined,
    }),
  );

  const visibleParks = useMemo(
    () => parks.filter(isVisible),
    [parks, isVisible],
  );
  const visibleTurbines = useMemo(
    () => turbines.filter(isVisible),
    [turbines, isVisible],
  );

  const visibleExistingTurbines = useMemo(
    () => existingTurbines.filter(isVisible),
    [existingTurbines, isVisible],
  );
  const visibleDivisions = useMemo(
    () => divisions.filter(isVisible),
    [divisions, isVisible],
  );
  const visibleMooringLines = useMemo(
    () => mooringLines.filter(isVisible),
    [mooringLines, isVisible],
  );
  const visibleAnchors = useMemo(
    () => anchors.filter(isVisible),
    [anchors, isVisible],
  );
  const visibleSubstations = useMemo(
    () => substations.filter(isVisible),
    [substations, isVisible],
  );
  const visibleExportCables = useMemo(
    () => exportCables.filter(isVisible),
    [exportCables, isVisible],
  );
  const visibleCableCorridors = useMemo(
    () => cableCorridors.filter(isVisible),
    [cableCorridors, isVisible],
  );
  const visibleCables = useMemo(
    () => cables.filter(isVisible),
    [cables, isVisible],
  );
  const visiblePorts = useMemo(
    () => ports.filter(isVisible),
    [ports, isVisible],
  );
  const visibleViewpoints = useMemo(
    () => viewpoints.filter(isVisible),
    [viewpoints, isVisible],
  );
  const visibleSensorPoints = useMemo(
    () => sensorPoints.filter(isVisible),
    [sensorPoints, isVisible],
  );
  const visibleOtherPointFeatures = useMemo(
    () => otherPointFeatures.filter(isVisible),
    [otherPointFeatures, isVisible],
  );
  const visibleOtherLineStringFeatures = useMemo(
    () => otherLineStringFeatures.filter(isVisible),
    [otherLineStringFeatures, isVisible],
  );
  const visibleOtherPolygonFeatures = useMemo(
    () =>
      otherPolygonFeatures
        .concat(bathymetries)
        .concat(geotiffs)
        .filter(isVisible),
    [otherPolygonFeatures, bathymetries, geotiffs, isVisible],
  );

  return (
    <>
      <RenderParks map={map} parks={visibleParks} showSelectionHighlighter />
      <RenderTurbines
        map={map}
        turbines={visibleTurbines}
        showSelectionHighlighter
      />
      <RenderTurbineDiameterCircle map={map} turbines={visibleTurbines} />
      <RenderTurbineEllipsis
        map={map}
        turbines={visibleTurbines}
        visibleEllipses={visibleEllipsis}
      />
      <RenderDivisions
        map={map}
        divisions={visibleDivisions}
        showSelectionHighlighter
      />
      <RenderMooringLines
        map={map}
        mooringLines={visibleMooringLines}
        showSelectionHighlighter
      />
      <RenderAnchors
        map={map}
        anchors={visibleAnchors}
        showSelectionHighlighter
      />
      <RenderExistingTurbines
        map={map}
        turbines={visibleExistingTurbines}
        showSelectionHighlighter
      />
      <RenderSubstations
        map={map}
        substations={visibleSubstations}
        symbols
        showSelectionHighlighter
      />
      <RenderExportCables
        map={map}
        exportCables={visibleExportCables}
        showSelectionHighlighter
      />
      <RenderCableCorridor
        map={map}
        cableCorridors={visibleCableCorridors}
        showSelectionHighlighter
      />
      <RenderCables map={map} cables={visibleCables} showSelectionHighlighter />
      <RenderPorts map={map} ports={visiblePorts} showSelectionHighlighter />
      <RenderOtherPoints
        map={map}
        features={visibleOtherPointFeatures}
        showSelectionHighlighter
      />
      <RenderOtherLineStrings
        map={map}
        features={visibleOtherLineStringFeatures}
        showSelectionHighlighter
      />
      <RenderOtherPolygons
        map={map}
        features={visibleOtherPolygonFeatures}
        showSelectionHighlighter
      />
      <RenderViewpoints
        map={map}
        features={visibleViewpoints}
        showSelectionHighlighter
      />
      <RenderSensorPoints
        map={map}
        features={visibleSensorPoints}
        showSelectionHighlighter
      />

      <CableFreeSectorWhenEditing />
    </>
  );
};
