import { atom, useAtom, useSetAtom } from "jotai";
import { getNodesInOrganisation } from "../../services/customerAPI";
import { isLoaded, makeLoaded } from "../../types/Load";
import { Node } from "../../services/customerAPI";
import { useEffect, useMemo } from "react";
import { nodesInOrganisationAtom } from "./useOrganisationFolderCrud";

const refreshNodesInOrganisationAtom = atom(
  null,
  async (_, set, organisationId: string) => {
    if (!organisationId) throw new Error("No organisation id");

    set(nodesInOrganisationAtom({ organisationId }), (curr) => ({
      ...curr,
      loading: true,
    }));

    const nodes = await getNodesInOrganisation(organisationId);
    const loaded = nodes.map(makeLoaded);

    set(nodesInOrganisationAtom({ organisationId }), {
      data: loaded,
      loading: false,
      initialised: true,
    });
  },
);

const initialiseNodesInOrganisationAtom = atom(
  null,
  async (get, set, organisationId: string) => {
    if (!organisationId) return;
    const state = get(nodesInOrganisationAtom({ organisationId }));
    if (state.initialised || state.loading) return;
    set(refreshNodesInOrganisationAtom, organisationId);
  },
);

export function useNodesInOrganisationState(organisationId: string) {
  const [storedNodes, setStoredNodes] = useAtom(
    nodesInOrganisationAtom({ organisationId }),
  );
  const initialiseStoredNodes = useSetAtom(initialiseNodesInOrganisationAtom);
  const refresh = useSetAtom(refreshNodesInOrganisationAtom);

  const nodes: Node[] = useMemo(
    () => storedNodes.data.filter(isLoaded).map((n) => n.value),
    [storedNodes],
  );

  useEffect(() => {
    if (!organisationId) return;
    initialiseStoredNodes(organisationId);
  }, [organisationId, initialiseStoredNodes]);

  return {
    state: storedNodes,
    loadedState: nodes,
    setState: setStoredNodes,
    loading: storedNodes.loading,
    initialised: storedNodes.initialised,
    refresh,
  };
}
