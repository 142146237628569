import DeleteIcon from "@icons/24/Bin.svg?react";
import VesselIcon from "@icons/24/Vessel.svg?react";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { VesselType, VesselTypePostBody } from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import useVesselTypeCrud from "../../../../hooks/useVesselTypeCrud";
import { inReadOnlyModeSelector } from "../../../../state/project";
import { selectedMenuItemState } from "../../Shared/state";
import {
  SettingsItem,
  SettingsSubMenuProp,
} from "components/SettingsV2/Shared/types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { unwrap } from "jotai/utils";
import { userNodeAccessSelectorFamily } from "state/user";
import { VesselSettings } from "components/ConfigurationModal/VesselSettings/VesselSettings";
import { VesselWizardModal } from "components/ConfigurationModal/VesselSettings/VesselWizard";
import { projectIdAtom } from "state/pathParams";
import { vesselsUsedInOperationsConfig } from "state/jotai/operationsConfiguration";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { IconREMSize } from "styles/typography";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";

export const VESSEL_MENU_ID = "vessel";

export default function useVesselSettings() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: projectId,
    }),
  );
  const editorAccessProject = nodeAccess >= 1;
  const { update, create, deleteVessel } = useVesselTypeCrud();
  const vesselTypes = useAtomValue(unwrap(vesselTypesFamily(projectId)));
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const { success } = useToast();
  const [isProjectVesselCollapsed, setIsProjectVesselCollapsed] =
    useState<boolean>(false);

  const [isDefaultVesselCollapsed, setIsDefaultVesselCollapsed] =
    useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({ menuId: VESSEL_MENU_ID, projectId }),
  );

  useEffect(() => {
    if (!menuSelection && vesselTypes !== undefined && vesselTypes.size > 0) {
      setMenuSelection(vesselTypes.keys().next().value);
    }
  }, [menuSelection, setMenuSelection, vesselTypes]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const vesselToConfigUsage = useAtomValue(
    unwrap(vesselsUsedInOperationsConfig),
  );

  const _onDuplicate = useCallback(
    async (vesselId: string, name: string) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "vessel",
          defaultName: `${name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const vesselType = vesselTypes?.get(vesselId);
            if (!vesselType)
              throw new Error("Tried to duplicate non existing vessel");

            setIsLoading(true);
            setSaveInProgress(true);
            const clone: VesselTypePostBody = {
              ...vesselType,
              name: name,
            };
            delete clone["id"];
            return create({ vessel: clone }).finally(() => {
              setSaveInProgress(false);
              setIsLoading(false);
            });
          },
        },
      });
    },
    [create, vesselTypes, setSaveInProgress, setMidScreenModalTypeOpen],
  );

  const { error: showErrorToast } = useToast();

  const _onDelete = useCallback(
    async (vesselId: string) => {
      if (vesselToConfigUsage?.has(vesselId)) {
        showErrorToast(
          `This vessel is used in ${vesselToConfigUsage?.get(vesselId)?.length} operations configurations and can't be deleted.`,
          {
            timeout: 6000,
          },
        );
        return;
      }
      setIsLoading(true);
      return deleteVessel({ vesselId }).finally(() => setIsLoading(false));
    },
    [deleteVessel, vesselToConfigUsage, showErrorToast],
  );

  const _onUpdate = useCallback(
    async (newVessel: VesselType) => {
      setIsLoading(true);
      return update({ vessel: newVessel })
        .then(() => success("Saved"))
        .finally(() => setIsLoading(false));
    },
    [success, update],
  );
  const availableProjectVessels = useMemo(() => {
    if (!vesselTypes) return [];
    return Array.from(vesselTypes.values())
      .filter((t) => !t.archived && !t.id.includes("default_"))
      .sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);
  }, [vesselTypes]);

  const availableDefaultVessels = useMemo(() => {
    if (!vesselTypes) return [];
    return Array.from(vesselTypes.values())
      .filter((t) => !t.archived && t.id.includes("default_"))
      .sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);
  }, [vesselTypes]);

  const vesselSubMenus = useMemo(() => {
    const projectVesselList = Array.from(availableProjectVessels.values()).map(
      (vessel) => {
        return {
          id: vessel.id,
          label: vessel.name ?? "Unnamed vessel",
          loading: isLoading,
          content: (
            <div style={{ height: "100%", position: "relative" }}>
              <VesselSettings
                key={vessel.id}
                vessel={vessel}
                onSave={_onUpdate}
                isLoading={isLoading}
                disabled={isReadOnly}
                isDefault={false}
                nodeId={projectId}
                hasEditAccess={editorAccessProject}
                onDuplicate={_onDuplicate}
              />
            </div>
          ),
          onDuplicate: undefined,
          dotMenuOptions: [
            {
              title: "Delete",
              onSelect: _onDelete,
              icon: <DeleteIcon />,
            },
            {
              title: "Duplicate",
              onSelect: () => _onDuplicate(vessel.id, vessel.name ?? ""),
              icon: <DuplicateIcon />,
            },
          ],
        };
      },
    );

    const defaultVesselsList = Array.from(
      availableDefaultVessels?.values() ?? [],
    ).map((vessel) => {
      return {
        id: vessel.id,
        label: vessel.name ?? "Unnamed vessel",
        loading: isLoading,
        content: (
          <div style={{ height: "100%", position: "relative" }}>
            <VesselSettings
              key={vessel.id}
              vessel={vessel}
              onSave={_onUpdate}
              isLoading={isLoading}
              disabled={true}
              isDefault={true}
              nodeId={projectId}
              hasEditAccess={editorAccessProject}
              onDuplicate={_onDuplicate}
            />
          </div>
        ),
        onDuplicate: undefined,
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
          {
            title: "Create a project specific duplicate",
            onSelect: () => _onDuplicate(vessel.id, vessel.name ?? ""),
            icon: <DuplicateIcon />,
          },
        ],
      };
    });

    const projectVesselsMenu: SettingsSubMenuProp = {
      title: "Project specific components",
      items: projectVesselList,
      isCollapsed: isProjectVesselCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectVesselCollapsed}
            onClick={() =>
              setIsProjectVesselCollapsed(!isProjectVesselCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const defaultVesselsMenu: SettingsSubMenuProp = {
      title: "Vind AI components",
      items: defaultVesselsList,
      isCollapsed: isDefaultVesselCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isDefaultVesselCollapsed}
            onClick={() =>
              setIsDefaultVesselCollapsed(!isDefaultVesselCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    return [projectVesselsMenu, defaultVesselsMenu];
  }, [
    isLoading,
    isReadOnly,
    _onUpdate,
    projectId,
    _onDuplicate,
    _onDelete,
    editorAccessProject,
    availableProjectVessels,
    availableDefaultVessels,
    isProjectVesselCollapsed,
    isDefaultVesselCollapsed,
  ]);

  const createNewVessel = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <VesselWizardModal
          create={create}
          setLoading={setIsLoading}
          isLoading={isLoading}
          disabled={!editorAccessProject}
          vessels={Array.from(vesselTypes?.values() ?? [])}
          tooltipText={
            !editorAccessProject
              ? "Need project editor access to create a new vessel"
              : undefined
          }
        />
      ),
    };
  }, [saveInProgress, isLoading, create, editorAccessProject, vesselTypes]);

  const topLevelMenu: SettingsItem = useMemo(() => {
    return {
      id: VESSEL_MENU_ID,
      label: "Vessels",
      icon: <VesselIcon />,
      submenus: vesselSubMenus,
      createNew: createNewVessel,
    };
  }, [vesselSubMenus, createNewVessel]);

  return topLevelMenu;
}
