import Dropdown, { DropdownStyle } from "components/Dropdown/Dropdown";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { useEffect, useMemo, useState } from "react";
import { modalTypeOpenAtom } from "state/modal";
import styled from "styled-components";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { Column, ModalFrame } from "components/General/Layout";
import { spaceLarge } from "styles/space";
import { InputNumber } from "components/General/Input";
import Button from "components/General/Button";
import {
  getEPSGJsonSelectorFamily,
  selectedCRSAtom,
  availableCRSAtom,
  CRSDefinition,
} from "./state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";

export const AddCustomCRSModalType = "AddCustomCRSModal";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const EpsgInputColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const ErrorText = styled.p`
  ${typography.sub4}
  color: ${colors.textError};
`;

const AddCustomCRSModalInner = () => {
  const [newEPSGCode, setNewEPSGCode] = useState<number | undefined>();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setAvailableCRS = useSetAtom(availableCRSAtom);
  const setSelectedCRS = useSetAtom(selectedCRSAtom);

  const epsgResult = useAtomValue(
    loadable(getEPSGJsonSelectorFamily(newEPSGCode)),
  );

  return (
    <FullScreenModal>
      <ModalFrame title={"Add new custom CRS"}>
        <Column style={{ gap: spaceLarge }}>
          <p>
            Specify the wanted custom CRS using its{" "}
            <a target={"_blank"} href="https://epsg.io" rel="noreferrer">
              EPSG code
            </a>
            .
          </p>

          <EpsgInputColumn>
            <InputNumber
              placeholder={"Insert EPSG code"}
              type="number"
              value={newEPSGCode}
              onChange={(code) => {
                setNewEPSGCode(code);
              }}
              style={{ width: "14rem" }}
              validate={(n) => Number.isInteger(n) && 2000 < n && n <= 32766}
              validationMessage={`Needs to be integer between 2000 and 32766`}
            />
            {epsgResult.state === "hasData" && !!epsgResult.data && (
              <p>{epsgResult.data.name}</p>
            )}
            {epsgResult.state === "loading" && <p>Loading...</p>}
            {epsgResult.state === "hasError" && (
              <ErrorText>Unable to fetch name for EPSG</ErrorText>
            )}
          </EpsgInputColumn>
          <ButtonRow>
            <Button
              text="Cancel"
              buttonType="secondary"
              onClick={() => {
                setModalTypeOpen(undefined);
              }}
            />
            <Button
              disabled={
                epsgResult.state !== "hasData" ||
                !epsgResult.data ||
                !newEPSGCode
              }
              text="Store"
              buttonType="primary"
              onClick={() => {
                if (epsgResult.state !== "hasData" || !newEPSGCode) return;

                const newEpsg = epsgResult.data;
                if (!newEpsg) return;

                const newCrs = {
                  code: newEPSGCode,
                  name: newEpsg.name,
                  proj4string: newEpsg.proj4string,
                } as CRSDefinition;

                setAvailableCRS((oldCRS) => [
                  ...oldCRS.filter((crs) => crs.code !== newCrs.code),
                  newCrs,
                ]);
                setSelectedCRS(newCrs);
                setModalTypeOpen(undefined);
              }}
            />
          </ButtonRow>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export const AddCustomCRSModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  if (!modalTypeOpen || modalTypeOpen?.modalType !== AddCustomCRSModalType)
    return null;

  return <AddCustomCRSModalInner />;
};

const CustomCRSDropdown = ({
  onSelectCRS,
  kind,
  small,
  _size,
  disabled,
}: {
  onSelectCRS?: (newCRS: CRSDefinition) => void;
  kind?: DropdownStyle;
  small?: boolean;
  _size?: "small" | "large";
  disabled?: boolean;
}) => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);
  const availableCRS = useAtomValue(availableCRSAtom);
  const [selectedCRS, setSelectedCRS] = useAtom(selectedCRSAtom);
  const crsItems: DropDownItem<number>[] = useMemo(
    () => availableCRS.map((crs) => ({ value: crs.code, name: crs.name })),
    [availableCRS],
  );
  useEffect(() => {
    if (!onSelectCRS) return;
    onSelectCRS(selectedCRS);
  }, [selectedCRS, onSelectCRS]);

  return (
    <Dropdown
      kind={kind}
      small={small}
      _size={_size}
      disabled={disabled}
      value={selectedCRS.code}
      onChange={(e) => {
        const crs = parseInt(e.target.value);
        if (crs === 0) {
          setModalTypeOpen({
            modalType: AddCustomCRSModalType,
            backTo: modalTypeOpen,
          });
          return;
        }
        const selectedCRS = availableCRS.find(
          (availableCrs) => availableCrs.code === crs,
        );
        if (!selectedCRS) return;
        setSelectedCRS(selectedCRS);
      }}
    >
      {crsItems.map((crsItem) => (
        <option key={crsItem.value} value={crsItem.value}>
          {crsItem.name}
        </option>
      ))}
      <option value={0}>+ New CRS</option>
    </Dropdown>
  );
};

export default CustomCRSDropdown;
