import { useCallback, useMemo } from "react";
import { ABLY_NOISE_ANALYSIS_RESULT } from "../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "./useAblyGeneric";
import { useJotaiCallback } from "utils/jotai";
import { setNoiseAnalysisStatus } from "components/Noise/Onshore/state";
import {
  NoiseAnalysisStatus,
  _NoiseAnalysisStatus,
} from "components/Noise/Onshore/types";

export function useAblyNoiseAnalysisStatus(projectId: string) {
  const setJotaiResult = useJotaiCallback(
    (_, set, res: NoiseAnalysisStatus) => {
      set(setNoiseAnalysisStatus, res);
    },
    [],
  );

  const onAnalysisUpdate = useCallback(
    (message: InboundMessage) => {
      const data = message.data;
      const ablyResult = _NoiseAnalysisStatus.parse(data);
      setJotaiResult(ablyResult);
    },
    [setJotaiResult],
  );

  const domain = import.meta.env.VITE_DEV_OCTOPUS === "true" ? "dev" : "all";
  const channelName = useMemo(
    () => `${projectId}:${domain}`,
    [projectId, domain],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_NOISE_ANALYSIS_RESULT,
        onMessageReceived: onAnalysisUpdate,
      },
    ],
    [onAnalysisUpdate],
  );

  useAblyGeneric(channelName, events, projectId);
}
