import { atom } from "jotai";
import { Style } from "../types";
import { Color } from "lib/colors";
import { colors } from "styles/colors";
import { atomFamily } from "utils/jotai";
import { isNever } from "utils/utils";
import { parkIdAtom } from "state/pathParams";
import { scream } from "utils/sentry";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { currentParkBathymetryIdAtom } from "state/jotai/bathymetry";
import { bathymetryFamily } from "state/bathymetry";
import { z } from "zod";
import { Coloring } from "../types";
import { CirclePaint } from "mapbox-gl";
import { colorValuesSelectorFamily } from "../state";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  editmodePropertyName,
  ghostPropertyName,
} from "components/Mapbox/constants";
import { READ, caseexpr, pointOpacityInPark } from "components/Mapbox/defaults";
import { lockedPropertyName } from "@constants/canvas";
import { COLORVALUE } from "../constants";

export const _AnchorColorKey = z.enum(["single", "depth"]);
export const _AnchorLabel = z.enum(["depth"]);
type AnchorColorKey = z.infer<typeof _AnchorColorKey>;
export const anchorColorKeys = _AnchorColorKey.options;
type AnchorLabel = z.infer<typeof _AnchorLabel>;
export const anchorLabels = _AnchorLabel.options;
export type StyleAnchor = {
  feature: "anchors";
} & Coloring<AnchorColorKey> & { label?: AnchorLabel };

export const defaultAnchorStyle = atom<Style>(() => {
  return {
    id: "default-style-anchors",
    defaultMarker: true,
    feature: "anchors",
    source: "single",
    type: "single",
    name: "Single color",
    color: Color.fromHex(colors.anchor),
    createdAt: 0,
  };
});

export const anchorValuesSelectorFamily = atomFamily(
  (colorKey: AnchorColorKey | AnchorLabel) =>
    atom<Promise<Map<string, number> | undefined>>(async (get) => {
      const parkId = get(parkIdAtom);
      const branchId = undefined;
      if (colorKey === "single") {
        if (!parkId) return undefined;
        const anchors = await get(anchorsInParkFamily({ parkId, branchId }));
        return new Map(anchors.map((a) => [a.id, 0]));
      } else if (colorKey === "depth") {
        const bathId = get(currentParkBathymetryIdAtom);
        if (!bathId) return undefined;
        const bath = await get(bathymetryFamily(bathId));
        if (bath.status !== "finished") return undefined;
        if (!parkId) return undefined;
        const anchors = await get(anchorsInParkFamily({ parkId, branchId }));
        return new Map<string, number>(
          anchors.map((a) => [
            a.id,
            bath.raster.latLngToValue(
              a.geometry.coordinates[0],
              a.geometry.coordinates[1],
            ) ?? 0,
          ]),
        );
      }
      isNever(colorKey);
      throw scream(new Error(`Illegal \`valueName\`: "${colorKey}"`));
    }),
);

export const anchorStyleAtom = atom<
  Promise<{
    circle: CirclePaint;
  }>
>(async (get) => {
  const { color } = await get(colorValuesSelectorFamily("anchors"));

  return {
    circle: {
      "circle-radius": defaultPointCircleRadius,
      "circle-color": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        colors.anchorPreview,
        ["boolean", ["feature-state", "error"], false],
        colors.redAlert,
        ["boolean", ["feature-state", "warning"], false],
        colors.yellow,
        ["case", ["!=", ["get", COLORVALUE], null], color, colors.anchor],
      ],
      "circle-opacity": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        1.0,
        ["==", ["get", ghostPropertyName], true],
        0.1,
        pointOpacityInPark,
      ],
      "circle-stroke-color": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        colors.lightText,
        ["==", ["get", lockedPropertyName], true],
        colors.lockedFeatureOutline,
        caseexpr({
          fallback: colors.white,
          hover: colors.anchor,
          selected: colors.white,
          state: { borderColor: READ },
        }),
      ],
      "circle-stroke-width": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        3,
        ["==", ["get", lockedPropertyName], true],
        2,
        ["!=", ["feature-state", "borderColor"], null],
        2.0,
        caseexpr({
          hover: 2.0,
          selected: 2.0,
          fallback: 0.0,
        }),
      ],
    },
  };
});
