import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./colors";
import { borderRadiusSmall, spaceSmall } from "./space";
import { addSvgStrokeOrFill } from "utils/styleUtils";

export const typography = {
  h1: { fontWeight: "700", fontSize: "2.4rem", lineHeight: "3.3rem" },
  h2: { fontWeight: "700", fontSize: "2.0rem", lineHeight: "2.7rem" },
  h3: { fontWeight: "600", fontSize: "1.8rem", lineHeight: "2.5rem" },
  h4: { fontWeight: "600", fontSize: "1.6rem", lineHeight: "2.2rem" },

  sub1: { fontWeight: "600", fontSize: "1.4rem", lineHeight: "2.0rem" },
  sub2: { fontWeight: "600", fontSize: "1.2rem", lineHeight: "1.6rem" },
  sub3: {
    fontWeight: "600",
    fontSize: "1.0rem",
    lineHeight: "1.4rem",
    textTransform: "uppercase",
  },
  sub4: { fontWeight: "600", fontSize: "1.0rem", lineHeight: "1.4rem" },

  body: { fontWeight: "400", fontSize: "1.4rem", lineHeight: "2rem" },
  caption: { fontWeight: "400", fontSize: "1.2rem", lineHeight: "1.6rem" },
  graphics: { fontWeight: "400", fontSize: "1.0rem", lineHeight: "1.4rem" },

  // -----------------------------------------------------------------
  // TODO: get rid of these:
  /**
   * @deprecated
   */
  regular: { fontWeight: "400", fontSize: "1.4rem", lineHeight: "2rem" },
  /**
   * @deprecated
   */
  contentAndButtons: {
    fontWeight: "400",
    fontSize: "1.3rem",
    lineHeight: "2rem",
  },
  /**
   * @deprecated
   */
  semiBold: { fontWeight: "500", fontSize: "1.3rem", lineHeight: "2rem" },
  /**
   * @deprecated
   */
  label: { fontWeight: "400", fontSize: "1.2rem", lineHeight: "2rem" },
} as const;

export const RegularRaw = css`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0rem;

  font-feature-settings:
    "cv01" on,
    "cv02" on,
    "cv03" on,
    "cv04" on,
    "cv05" on,
    "cv06" on,
    "cv10" on;

  color: ${colors.primaryText};
`;

export const textFontSize = "1.2rem";
export const TextRaw = css`
  ${RegularRaw};
  ${typography.label}
`;
export const Text = styled.p`
  ${TextRaw}
`;

export const TextSemi = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0rem;
  font-feature-settings:
    "cv01" on,
    "cv02" on,
    "cv03" on,
    "cv04" on,
    "cv05" on,
    "cv06" on,
    "cv10" on;
  color: ${colors.primaryText};
  ${typography.semiBold};
`;

export const IconREMSize = styled.div<{
  height: number;
  width: number;
  iconColor?: string;
  hoverIconColor?: string;
  /**
   * @deprecated use iconColor instead
   */
  fill?: string;
  /**
   * @deprecated use iconColor instead
   */
  stroke?: string;
  /**
   * @deprecated use hoverIconColor instead
   */
  hoverFill?: string;
  /**
   * @deprecated use hoverIconColor instead
   */
  hoverStroke?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  &&&&& {
    ${(p) => p.iconColor && addSvgStrokeOrFill(p.iconColor)};
    svg {
      height: ${(p) => p.height}rem !important;
      min-height: ${(p) => p.height}rem !important;
      width: ${(p) => p.width}rem !important;
      min-width: ${(p) => p.width}rem !important;

      path {
        ${(p) => (p.stroke ? `stroke: ${p.stroke};` : "")};
        ${(p) => (p.fill ? `fill: ${p.fill};` : "")};
      }
    }
  }

  &:hover {
    ${({ hoverIconColor }) =>
      hoverIconColor && addSvgStrokeOrFill(hoverIconColor)};
    svg path {
      ${(p) => (p.hoverFill ? `fill: ${p.hoverFill};` : "")};
      ${(p) => (p.hoverStroke ? `stroke: ${p.hoverStroke};` : "")};
    }
  }
`;

/** A wrapper around an SVG or <img /> which makes it fit well with the surrounding text. */
export const TextIcon = styled.div<{
  stroke?: string;
  onClick?: React.ComponentProps<"div">["onClick"];
  scale?: number;
}>`
  display: flex;
  padding: 0.3rem;
  border-radius: ${borderRadiusSmall};

  :hover {
    ${(p) => p.onClick && `background: ${colors.hover};`}
  }

  ${(p) => p.onClick && `cursor: pointer;`}

  > svg {
    width: 1.4rem;
    height: 1.4rem;

    path {
      ${({ stroke }) => stroke !== undefined && `stroke: ${stroke};`}
    }
  }
`;

const DetailRaw = css`
  ${RegularRaw};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
`;

export const Detail = styled.p`
  ${DetailRaw};
  margin-top: ${spaceSmall};
  margin-bottom: ${spaceSmall};
`;

export const UiErrorText = styled.p`
  color: ${colors.redAlert};
`;
