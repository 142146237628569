import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { organisationTurbineResourceState } from "../state";
import {
  HeaderRow,
  MainRow,
  SubHeader,
  TabContainer,
  TableHeader,
} from "../style";
import AddIcon from "@icons/24/Add.svg";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { _TurbineType, TurbineType } from "types/turbines";
import { Row } from "components/General/Layout";
import useOrgTurbineCrud from "./useOrgTurbineCrud";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { TurbineManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import Tooltip from "components/General/Tooltip";
import { SearchInput } from "components/General/Input";
import { VerticalDivider } from "components/General/VerticalDivider";
import { Field, LibraryManageRole, Order, SortState } from "../types";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import useDefaultTurbines from "hooks/useDefaultTurbines";
import { DesignToolMode } from "types/map";
import { designToolTypeAtom } from "state/map";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import { getIsLegalMove } from "business/folderStructure/utils";
import { FolderItem, FolderTreeItem } from "types/folderStructures";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { IconREMSize } from "styles/typography";
import { RecursiveFolder } from "../shared/RecursiveFolder";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import {
  TURBINE_TABLE_LIST_ITEM,
  TURBINE_TABLE_FOLDER_ITEM,
} from "./constants";
import ResourceItem from "../shared/ResourceItem/ResourceItem";
import { useLibraryFolderStructure } from "../shared/hooks/useLibraryFolderStructure";
import { useLibrarySearchAndSort } from "../shared/hooks/useLibrarySearchAndSort";
import { useLibraryDragAndDrop } from "../shared/hooks/useLibraryDragAndDrop";
import { ResourceCrudOperations } from "../shared/types";
import TurbineRatedPower from "./TurbineRatedPower";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { orgTurbineManageAccessSelector } from "state/user";
import { selectedLibraryTabAtom } from "state/library";
import { useSyncSelectedItemWithResource } from "../hook";

const defaultTurbineIdForMode: Record<DesignToolMode, string> = {
  [DesignToolMode.Offshore]: "iea_15MW",
  [DesignToolMode.Onshore]: "nrel_5MW",
};

type TurbineOrFolderItem = TurbineType | FolderItem;

export default function TurbineTab() {
  const hasOrgTurbineAccess = useAtomValue(orgTurbineManageAccessSelector);
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  useEffect(() => {
    setSelectedLibraryTab("turbine");
    return () => {
      setSelectedLibraryTab(undefined);
    };
  }, [setSelectedLibraryTab]);

  if (!hasOrgTurbineAccess) return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <TurbineTabInner />;
    </Suspense>
  );
}

function TurbineTabInner() {
  useSyncSelectedItemWithResource();
  const defaultTurbines = useDefaultTurbines();
  const mode = useAtomValue(designToolTypeAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const turbines = useAtomValue(
    organisationTurbineResourceState({
      organisationId,
    }),
  );
  const [showNewTurbineModal, setShowNewTurbineModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const selectedItemId = params.selectedItemId;

  const [createTurbineInFolderId, setCreateTurbineInFolderId] = useState<
    string | undefined
  >();
  const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
    string | undefined
  >();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );

  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);
  const { showConfirm } = useConfirm();

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  const {
    name,
    onNameChange,
    sortedResources: sortedTurbines,
  } = useLibrarySearchAndSort(turbines, sortState);

  const { folders, orphans } = useLibraryFolderStructure({
    organisationId,
    resources: sortedTurbines,
    sortState,
    libraryManageRole: "org_turbine_manage",
    searchTerm: name,
  });

  const { moveItems, createFolder } = useOrgFolderStructureCrud({
    libraryManageRole: "org_turbine_manage",
  });

  const { create, remove, duplicate, update } = useOrgTurbineCrud();

  const crudOperations: ResourceCrudOperations<LibraryManageRole.TURBINE> =
    useMemo(
      () => ({
        remove: async (id: string) => {
          await remove(id);
          return;
        },
        duplicate: ({ resource }) =>
          duplicate({
            turbine: resource,
            onSuccess: (newTurbine) => {
              navigate(
                `/organisation/${organisationId}/library/turbine/${newTurbine.id}`,
              );
            },
          }),
      }),
      [remove, duplicate, navigate, organisationId],
    );

  const collapsed = !!content;

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  const onClickOnResource = useCallback(
    async (id: string) => {
      if (
        !unsavedSettings ||
        (await showConfirm({
          title: "Unsaved settings",
          message: "Are you sure you want to quit without saving?",
        }))
      ) {
        setUnsavedSettings(false);
        navigate(`/organisation/${organisationId}/library/turbine/${id}`);
      } else {
        navigate(`/organisation/${organisationId}/library/turbine/${id}`);
      }
    },
    [
      navigate,
      showConfirm,
      unsavedSettings,
      setUnsavedSettings,
      organisationId,
    ],
  );

  const onCreateTurbineInFolder = useCallback(
    (folderId: string) => {
      setCreateTurbineInFolderId(folderId);
      setShowNewTurbineModal(true);
    },
    [setCreateTurbineInFolderId, setShowNewTurbineModal],
  );

  const onCreateFolderInFolder = useCallback(
    (folderId: string) => {
      setCreateFolderInFolderId(folderId);
      setShowCreateNewFolder(true);
    },
    [setCreateFolderInFolderId, setShowCreateNewFolder],
  );

  const {
    onDropOnFolder,
    onFolderDropOnFolder,
    dropRef,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(
    [TURBINE_TABLE_LIST_ITEM, TURBINE_TABLE_FOLDER_ITEM],
    folders,
    "org_turbine_manage",
  );

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folders);
    },
    [folders],
  );

  const [openDescriptionId, setOpenDescriptionId] = useState<
    string | undefined
  >(undefined);

  const getDescription = (resource: TurbineType) => resource.description;

  const updateDescription = async (
    resource: TurbineType,
    newDescription: string,
  ): Promise<TurbineType> => {
    await update({
      ...resource,
      description: newDescription,
    });
    return resource;
  };

  return (
    <>
      {showCreateNewFolder && (
        <NewItemModal
          title="New folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onSubmit={async (name) => {
            await createFolder(
              name,
              LibraryManageRole.TURBINE,
              [],
              createFolderInFolderId,
            );
          }}
          onClose={() => {
            setShowCreateNewFolder(false);
            setCreateFolderInFolderId(undefined);
          }}
        />
      )}
      {showNewTurbineModal && (
        <NewItemModal
          title="Create new library turbine"
          placeholder="Enter turbine name"
          defaultValue="New turbine"
          onClose={() => {
            setShowNewTurbineModal(false);
          }}
          onSubmit={async (turbineName: string) => {
            setSaveInProgress(true);
            navigate(`/organisation/${organisationId}/library/turbine`);
            const defaultTurbine =
              defaultTurbines.find(
                (t) => t.id === defaultTurbineIdForMode[mode],
              ) ?? defaultTurbines[0];
            const res = await create({
              name: turbineName,
              turbine: {
                ...defaultTurbine,
              },
            });
            if (res) {
              navigate(
                `/organisation/${organisationId}/library/turbine/${res.id}`,
              );
              setSaveInProgress(false);
              if (createTurbineInFolderId) {
                await moveItems(
                  [
                    {
                      type: "RESOURCE",
                      id: res.id,
                      parentId: createTurbineInFolderId,
                    },
                  ],
                  createTurbineInFolderId,
                );
                setCreateTurbineInFolderId(undefined);
              }
            }
          }}
        />
      )}
      <TabContainer>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            Turbines
          </h2>

          <Row>
            <Tooltip text={"Create new folder"}>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setShowCreateNewFolder(true);
                }}
                icon={<FolderAddIcon />}
              />
            </Tooltip>
            <Button
              text={collapsed ? "" : "New library turbine"}
              icon={collapsed && <AddIcon />}
              disabled={isLoading}
              onClick={() => setShowNewTurbineModal(true)}
            />
            <VerticalDivider height={3.2} />
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === selectedItemId) return undefined;
                  if (!selectedItemId) {
                    return {
                      type: "no-item-selected",
                    };
                  }
                  return {
                    type: "resource",
                    id: selectedItemId,
                  };
                });
              }}
            />
          </Row>
        </Row>
        <Row alignCenter>
          <SubHeader>Access: </SubHeader> <TurbineManagersPreview />
        </Row>
        <Row
          style={{
            padding: "1.2rem 0",
          }}
        >
          <SearchInput
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            placeholder={`Search turbines`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </Row>
        <MainRow ref={dropRef}>
          <HeaderRow>
            <TableHeader
              onClick={() => toggleSort("name")}
              style={{
                cursor: "pointer",
                flex: 4,
              }}
            >
              Name
              <IconREMSize
                width={1.2}
                height={1.2}
                style={{ marginLeft: "0.4rem" }}
              >
                {sortState.field === "name" && sortState.order === "ASC" ? (
                  <DirectionDown />
                ) : sortState.field === "name" && sortState.order === "DESC" ? (
                  <DirectionUp />
                ) : (
                  <DirectionDown />
                )}
              </IconREMSize>
            </TableHeader>
            {!collapsed && (
              <>
                <TableHeader style={{ flex: 1 }}>Power rating</TableHeader>
                <TableHeader style={{ flex: 1 }}>Last edited</TableHeader>
                <TableHeader style={{ flex: 1 }}>Used in</TableHeader>
              </>
            )}
            <TableHeader style={{ flex: 1 }}></TableHeader>
          </HeaderRow>

          {folders.map((folder) => (
            <Fragment key={folder.id}>
              <RecursiveFolder<LibraryManageRole.TURBINE>
                folder={folder}
                depth={0}
                onDropOnFolder={onDropOnFolder}
                onFolderDropOnFolder={onFolderDropOnFolder}
                isLegalFolderMove={isLegalFolderMove}
                onCreateFolderInFolder={onCreateFolderInFolder}
                collapsed={collapsed}
                selectedItemId={selectedItemId}
                onClickResource={onClickOnResource}
                organisationId={organisationId}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setSelectedItemId={(id) => {
                  navigate(
                    `/organisation/${organisationId}/library/turbine/${id}`,
                  );
                }}
                setContent={setContent}
                setSaveInProgress={setSaveInProgress}
                setDeleteInProgress={setDeleteInProgress}
                sortedResources={sortedTurbines}
                libraryManageRole={LibraryManageRole.TURBINE}
                onCreateResourceInFolder={onCreateTurbineInFolder}
                onFolderDropOnResource={onFolderDropOnResource}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                searchTerm={name}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                extraColumn={(resource) => (
                  <TurbineRatedPower
                    isSelected={selectedItemId === resource.id}
                    ratedPower={resource.ratedPower}
                  />
                )}
              />
            </Fragment>
          ))}

          {orphans.map((item: TurbineOrFolderItem) => {
            const turbine =
              "name" in item
                ? item
                : sortedTurbines.find((t) => t.id === item.id);
            if (!turbine || !("name" in turbine)) return null;
            const parsedTurbine = _TurbineType.parse(turbine);

            return (
              <ResourceItem<LibraryManageRole.TURBINE>
                key={item.id}
                resource={parsedTurbine}
                depth={0}
                collapsed={collapsed}
                isSelected={selectedItemId === item.id}
                onClickResource={onClickOnResource}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                libraryManageRole={LibraryManageRole.TURBINE}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                folders={folders}
                searchTerm={name}
                getDescription={getDescription}
                updateDescription={updateDescription}
                extraColumn={(resource) => (
                  <TurbineRatedPower
                    isSelected={selectedItemId === resource.id}
                    ratedPower={resource.ratedPower}
                  />
                )}
              />
            );
          })}
        </MainRow>
      </TabContainer>
    </>
  );
}
