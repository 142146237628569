import { useCallback, useMemo } from "react";
import { ABLY_SUBSTATION_LIBRARY_TYPE_UPDATE } from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import { _SubstationType } from "services/substationService";
import { useLibraryNodeSubstations } from "../useLibraryNodeSubstations";

export function useAblyLibrarySubstationsForNode(projectId: string) {
  const { updateLocal } = useLibraryNodeSubstations(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const substation = _SubstationType.parse(message.data.meta);
      updateLocal(substation);
    },
    [updateLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SUBSTATION_LIBRARY_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
