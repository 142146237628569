import { atom } from "jotai";
import { GeotiffFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";
import { hiddenFeatureIdsFamily } from "./hidden";
import { branchIdAtom } from "state/pathParams";

export const geotiffFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<GeotiffFeature[]>>(async (get) => {
      const currentBranchId = get(branchIdAtom);
      if (!currentBranchId) return [];
      const canvasLayerFeatureHidden = get(
        hiddenFeatureIdsFamily({ branchId: branchId ?? currentBranchId }),
      );
      return (await get(featuresFamily({ branchId }))).geotiff.filter(
        (f) => !canvasLayerFeatureHidden.includes(f.id),
      );
    }),
);
