import { useAtomValue } from "jotai";
import { portfolioFilteredTotalCapacitySelector } from "state/portfolio";

const TotalPortfolioCapacity = () => {
  const totalCapacity = useAtomValue(portfolioFilteredTotalCapacitySelector);

  return <>{(totalCapacity / 1000 / 1000).toFixed(2)} GW</>;
};

export default TotalPortfolioCapacity;
