import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useCallback, useMemo, useRef, useState } from "react";
import { SVGWrapper } from "@icons/svgUtils";
import {
  groupsProjectsState,
  organisationGroupsState,
  userGroupsMembershipState,
  userNodeAccessState,
} from "components/Organisation/Groups/state";
import {
  GroupNodeAccess,
  UserNodeAccess,
} from "components/Organisation/Groups/types";
import {
  ContentTableColumn,
  ContentTableRow,
  ContentTableRowNonHover,
  HoverableColumn,
  SecondaryText,
  TabSubtitle,
  TableHeader,
  TextEllipsis,
  TextEllipsisWithTooltip,
} from "components/Organisation/OrganisationRightSide/style";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import {
  adminInOrganisationSelectorFamily,
  userHaveAdminNodeAccess,
} from "state/user";
import RemoveIcon from "@icons/24/Remove.svg";
import Folder from "@icons/14/Folder.svg";
import Earth from "@icons/14/Earth.svg";
import AddIcon from "@icons/24/Add.svg";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { UserNodeAccessDropdown } from "../../shared/UserNodeAccessDropdown";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import { _UserAccessRole } from "types/user";
import {
  AdminNodeAccessModalWrapper,
  NodeAccess,
} from "../../shared/NodeAccessModal";
import { OrganisationUser } from "types/customer";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import GroupTag from "../GroupTag";
import Admin_ChildNodesGlobe from "components/Organisation/OrganisationRightSide/content/Admin_ChildNodesGlobe";
import Tooltip from "components/General/Tooltip";
import { QuestionIconComp } from "components/AccessOverview/shared";
import { Anchor } from "components/General/Anchor";
import ProjectAccessOverviewModal from "components/AccessOverview/ProjectAccessOverviewModal";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useUserAccessState } from "components/Projects/useUserAccessState";

type GroupedAccess = {
  groupAccesses?: GroupNodeAccess[];
  personalAccess?: UserNodeAccess;
  nodeId: string;
};

export function UserProjects({ user }: { user: OrganisationUser }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const userId = user.user_id;
  const nodeAccess = useAtomValue(
    userNodeAccessState({
      organisationId,
      userId,
    }),
  );
  const projectAccessRef = useRef<HTMLDivElement>(null);

  const allNodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );
  const memberships = useAtomValue(
    userGroupsMembershipState({
      organisationId,
      userId,
    }),
  );
  const { addOrUpdate } = useUserNodeAccessCrud();

  const [showAddProject, setShowAddProject] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [showProjectAccessModal, setProjectAccessModal] = useState(false);

  const onAddProjects = useCallback(
    async (nodes: NodeAccess[]) => {
      setSaveInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            const role = _UserAccessRole.parse(node.resource_name);
            return addOrUpdate(userId, node.node_id, role).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdate, userId],
  );

  const groupNodeAccess = useAtomValue(
    groupsProjectsState({
      organisationId,
      groupIds: memberships.map((m) => m.group_id),
    }),
  );

  const groupedAccessShips = useMemo(() => {
    const nodeMap = new Map(allNodes.map((n) => [n.id, n]));
    const mappedGroupNodeAccess = groupNodeAccess.reduce(
      (acc: Record<string, GroupNodeAccess[]>, n) => {
        if (!acc[n.node_id]) acc[n.node_id] = [];
        acc[n.node_id].push(n);
        return acc;
      },
      {} as Record<string, GroupNodeAccess[]>,
    );
    const mappedUserNodeAccess = nodeAccess.reduce(
      (acc, n) => {
        return {
          ...acc,
          [n.node_id]: n,
        };
      },
      {} as Record<string, UserNodeAccess>,
    );

    const allNodeIds = [
      ...new Set([
        ...Object.keys(mappedGroupNodeAccess),
        ...Object.keys(mappedUserNodeAccess),
      ]),
    ];

    return allNodeIds
      .map((nodeId) => ({
        ...(mappedGroupNodeAccess[nodeId]
          ? {
              groupAccesses: mappedGroupNodeAccess[nodeId],
            }
          : {}),
        ...(mappedUserNodeAccess[nodeId]
          ? {
              personalAccess: mappedUserNodeAccess[nodeId],
            }
          : {}),
        nodeId,
      })) // Sort by folder first
      .sort((a, b) => {
        const nodeA = nodeMap.get(a.nodeId);
        const nodeB = nodeMap.get(b.nodeId);
        if (!nodeA || !nodeB) return 0;
        const nodeAIsFolder = nodeA.type === "folder";
        const nodeBIsFolder = nodeB.type === "folder";

        return nodeAIsFolder === nodeBIsFolder
          ? nodeA.name.localeCompare(nodeB.name)
          : nodeAIsFolder
            ? -1
            : 1;
      }) as GroupedAccess[];
  }, [groupNodeAccess, nodeAccess, allNodes]);

  return (
    <Column
      style={{
        height: "100%",
        gap: "1.6rem",
      }}
    >
      <Row
        style={{
          alignItems: "center",
        }}
      >
        <TabSubtitle>
          {user.nickname} has access to the projects below.
        </TabSubtitle>
        <div
          style={{
            marginLeft: "auto",
            position: "relative",
          }}
        >
          <Button
            text="Add projects"
            buttonType="secondary"
            icon={<AddIcon />}
            onClick={() => setShowAddProject((cur) => !cur)}
            disabled={saveInProgress > 0}
          />
          {showAddProject && (
            <div
              style={{
                position: "absolute",
                right: "0.8rem",
                zIndex: "1",
              }}
            >
              <AdminNodeAccessModalWrapper
                onSave={onAddProjects}
                existingAccess={nodeAccess.map((n) => n.node_id)}
              />
            </div>
          )}
        </div>
      </Row>
      <Column
        style={{
          gap: "1.2rem",
        }}
      >
        <Row
          style={{
            gap: 0,
          }}
        >
          <TableHeader
            style={{
              flex: "0 0 40%",
            }}
          >
            Project
          </TableHeader>
          <TableHeader
            ref={projectAccessRef}
            style={{
              flex: "0 0 50%",
              justifyContent: "center",
              paddingLeft: "1.2rem",
            }}
          >
            Access{" "}
            <QuestionIconComp
              onClick={(e) => {
                e.stopPropagation();
                setProjectAccessModal((cur) => !cur);
              }}
            />
          </TableHeader>
          {showProjectAccessModal && (
            <Anchor
              baseRef={projectAccessRef}
              floatPlace="topLeft"
              basePlace="bottomLeft"
            >
              <ProjectAccessOverviewModal
                onClose={() => setProjectAccessModal(false)}
              />
            </Anchor>
          )}
        </Row>
        <ContentTableColumn>
          {groupedAccessShips.map((ga) => (
            <GroupedRowHandler
              groupedNodeAccess={ga}
              userId={userId}
              key={ga.nodeId}
            />
          ))}
          {Array.from(
            {
              length: saveInProgress,
            },
            (_, index) => (
              <SkeletonBlock
                key={index}
                style={{
                  minHeight: "3.2rem",
                  marginTop: "0.8rem",
                }}
              />
            ),
          )}
        </ContentTableColumn>
      </Column>
    </Column>
  );
}

const GroupedRowHandler = ({
  groupedNodeAccess,
  userId,
}: {
  groupedNodeAccess: GroupedAccess;
  userId: string;
}) => {
  if (groupedNodeAccess.personalAccess && !groupedNodeAccess.groupAccesses) {
    return (
      <ProjectRow
        userId={userId}
        nodeAccess={groupedNodeAccess.personalAccess}
      />
    );
  } else if (
    !groupedNodeAccess.personalAccess &&
    groupedNodeAccess.groupAccesses
  ) {
    return (
      <>
        {groupedNodeAccess.groupAccesses.map((groupAccess) => (
          <GroupAccessRow
            key={groupAccess.group_id}
            groupNodeAccess={groupAccess}
          />
        ))}
      </>
    );
  }

  return (
    <GroupedRow
      groupAccesses={groupedNodeAccess.groupAccesses!}
      nodeAccess={groupedNodeAccess.personalAccess!}
      nodeId={groupedNodeAccess.nodeId}
      userId={userId}
    />
  );
};

const GroupedRow = ({
  groupAccesses,
  nodeAccess,
  nodeId,
  userId,
}: {
  groupAccesses: GroupNodeAccess[];
  nodeAccess: UserNodeAccess;
  nodeId: string;
  userId: string;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );
  const node = useMemo(
    () => nodes.find((n) => n.id === nodeId),
    [nodeId, nodes],
  );
  const { remove } = useUserNodeAccessCrud();
  const { showConfirm } = useConfirm();
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const { data: userAccess } = useUserAccessState();
  const isNodeAdmin = useMemo(
    () => userHaveAdminNodeAccess(userAccess, nodeId),
    [userAccess, nodeId],
  );

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  if (!node) {
    return null;
  }

  const RowIcon = node.type === "project" ? Earth : Folder;
  return (
    <HoverableColumn>
      <ContentTableRowNonHover
        style={{
          gap: 0,
        }}
        disabled={deleteInProgress}
      >
        <Row
          style={{
            alignItems: "center",
            flex: "0 0 60%",
            overflow: "hidden",
          }}
        >
          <SVGWrapper size={1.4}>
            <RowIcon />
          </SVGWrapper>
          <TextEllipsisWithTooltip
            style={{
              margin: 0,
            }}
            text={node.name}
          />
          <Admin_ChildNodesGlobe node={node} organisationId={organisationId} />
        </Row>
        {isAdminInOrg || isNodeAdmin ? (
          <>
            <Row
              style={{
                flex: "0 0 30%",
              }}
            >
              <UserNodeAccessDropdown
                role={_UserAccessRole.parse(nodeAccess.resource_name)}
                nodeId={node.id}
                userId={userId}
              />
            </Row>
            <Row
              style={{
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            >
              <Tooltip text="Remove access">
                <IconBtn
                  disabled={deleteInProgress}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (
                      !(await showConfirm({
                        title: "Remove access",
                        message:
                          "Are you sure you want to remove the access for this user?",
                        confirmButtonText: "Remove",
                      }))
                    ) {
                      return;
                    }
                    setDeleteInProgress(true);
                    remove(userId, nodeAccess.node_id).finally(() =>
                      setDeleteInProgress(false),
                    );
                  }}
                  size={"1.4rem"}
                >
                  <RemoveIcon />
                </IconBtn>
              </Tooltip>
            </Row>
          </>
        ) : (
          <>
            <Row
              style={{
                flex: "1 0 50%",
                justifyContent: "center",
              }}
            >
              <SecondaryText
                style={{
                  margin: 0,
                }}
              >
                {nodeAccess.resource_name}
              </SecondaryText>
            </Row>
          </>
        )}
      </ContentTableRowNonHover>
      {groupAccesses.map((groupNodeAccess) => (
        <GroupAccessSubRow
          groupNodeAccess={groupNodeAccess}
          key={groupNodeAccess.group_id}
        />
      ))}
    </HoverableColumn>
  );
};

const GroupAccessSubRow = ({
  groupNodeAccess,
}: {
  groupNodeAccess: GroupNodeAccess;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const group = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  ).find((g) => g.id === groupNodeAccess.group_id);

  if (!group) return <></>;

  return (
    <ContentTableRowNonHover
      key={groupNodeAccess.group_id}
      style={{
        gap: 0,
      }}
    >
      <Row
        style={{
          flex: "0 0 60%",
        }}
      />
      <Row
        style={{
          flex: "0 0 40%",
          overflow: "hidden",
          paddingLeft: "0.8rem",
        }}
      >
        <TextEllipsis
          style={{
            margin: 0,
            textTransform: "capitalize",
          }}
        >
          {groupNodeAccess.resource_name}
        </TextEllipsis>
        <GroupTag
          group={group}
          size="compact"
          organisationId={organisationId}
        />
      </Row>
    </ContentTableRowNonHover>
  );
};

const ProjectRow = ({
  nodeAccess,
  userId,
}: {
  nodeAccess: UserNodeAccess;
  userId: string;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );
  const node = useMemo(
    () => nodes.find((n) => n.id === nodeAccess.node_id),
    [nodeAccess.node_id, nodes],
  );
  const { remove } = useUserNodeAccessCrud();
  const { showConfirm } = useConfirm();
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const { data: userAccess } = useUserAccessState();
  const isNodeAdmin = useMemo(
    () => userHaveAdminNodeAccess(userAccess, nodeAccess.node_id),
    [userAccess, nodeAccess.node_id],
  );

  const [deleteInProgress, setDeleteInProgress] = useState(false);

  if (!node) {
    return null;
  }

  const RowIcon = node.type === "project" ? Earth : Folder;
  return (
    <ContentTableRow
      key={node.id}
      style={{
        gap: 0,
        cursor: "unset",
      }}
      disabled={deleteInProgress}
    >
      <Row
        style={{
          alignItems: "center",
          flex: "0 0 60%",
          overflow: "hidden",
        }}
      >
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>
        <TextEllipsisWithTooltip
          style={{
            margin: 0,
          }}
          text={node.name}
        />
        <Admin_ChildNodesGlobe node={node} organisationId={organisationId} />
      </Row>
      {isAdminInOrg || isNodeAdmin ? (
        <>
          <Row
            style={{
              flex: "1 0 30%",
              justifyContent: "unset",
            }}
          >
            <UserNodeAccessDropdown
              role={_UserAccessRole.parse(nodeAccess.resource_name)}
              nodeId={node.id}
              userId={userId}
            />
          </Row>

          <Row>
            <Tooltip text="Remove access">
              <IconBtn
                onClick={async (e) => {
                  e.stopPropagation();
                  if (
                    !(await showConfirm({
                      title: "Remove access",
                      message:
                        "Are you sure you want to remove the access for this user?",
                      confirmButtonText: "Remove",
                    }))
                  ) {
                    return;
                  }
                  setDeleteInProgress(true);
                  remove(userId, nodeAccess.node_id).finally(() =>
                    setDeleteInProgress(false),
                  );
                }}
                size={"1.4rem"}
                disabled={deleteInProgress}
                style={{
                  marginLeft: "auto",
                }}
              >
                <RemoveIcon />
              </IconBtn>
            </Tooltip>
          </Row>
        </>
      ) : (
        <SecondaryText
          style={{
            margin: 0,
          }}
        >
          {nodeAccess.resource_name}
        </SecondaryText>
      )}
    </ContentTableRow>
  );
};

function GroupAccessRow({
  groupNodeAccess,
}: {
  groupNodeAccess: GroupNodeAccess;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const group = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  ).find((g) => g.id === groupNodeAccess.group_id);

  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );
  const node = useMemo(
    () => nodes.find((n) => n.id === groupNodeAccess.node_id),
    [groupNodeAccess.node_id, nodes],
  );

  if (!node || !group) {
    return null;
  }

  const RowIcon = node.type === "project" ? Earth : Folder;

  return (
    <ContentTableRow
      style={{
        gap: 0,
        cursor: "unset",
      }}
    >
      <Row
        style={{
          alignItems: "center",
          flex: "0 0 60%",
          overflow: "hidden",
        }}
      >
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>

        <TextEllipsisWithTooltip
          style={{
            margin: 0,
          }}
          text={node.name}
        />
        <Admin_ChildNodesGlobe node={node} organisationId={organisationId} />
      </Row>
      <Row
        style={{
          flex: "1 0 40%",
        }}
      >
        <TextEllipsis
          style={{
            margin: 0,
            textTransform: "capitalize",
            paddingLeft: "0.8rem",
          }}
        >
          {groupNodeAccess.resource_name}
        </TextEllipsis>
        <GroupTag
          group={group}
          size="compact"
          organisationId={organisationId}
        />
      </Row>
    </ContentTableRow>
  );
}
