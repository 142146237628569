import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { parkFamily } from "state/jotai/park";
import { ValidationWarning } from "state/validationWarnings";
import { atom } from "jotai";

export const holeInParkWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<ValidationWarning<ValidationWarningTypes.HoleInPark> | undefined>
    >(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;

      const parkHasHoles =
        park.geometry.type === "Polygon" &&
        park.geometry.coordinates.length > 1;

      if (!parkHasHoles) return;
      return {
        type: ValidationWarningTypes.HoleInPark,
        featureIds: [park.id],
      };
    }),
);
