import { useSetAtom } from "jotai";
import React, { useCallback } from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ProjectFeature } from "../../../types/feature";
import { ExternalSelectionItem } from "../../../state/externalLayerSelection";
import InformationIcon from "@icons/24/Information.svg?react";
import CopyIcon from "@icons/24/Copy.svg?react";
import useSystemSpecificUnicode from "../../../hooks/useSystemSpecificUnicode";
import { toastMessagesAtom } from "../../../state/toast";
import { copy } from "components/MapControls/CopyPaste/copy";
import { Menu, MenuItem } from "../../General/Menu";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";
import SelectElementMenuItem from "./shared/SelectElementMenuItem";
import { featuresListAtom } from "state/jotai/features";
import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";

const OneOrMoreCanvasAndExternalSelectionMenu = ({
  sampleWmsCallback,
  selectedProjectFeatures,
  dynamicLayerSelection,
  featuresOnPoint,
  enableShowLayerInfo,
  closeMenu,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  sampleWmsCallback: () => void;
  selectedProjectFeatures: ProjectFeature[];
  dynamicLayerSelection: ExternalSelectionItem[];
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const stringToUnicode = useSystemSpecificUnicode();
  const projectFeatures = useAtomValue(featuresListAtom);
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);
  const projectId = useAtomValue(projectIdAtom);
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId: projectId ?? "",
    }),
  );
  const copyCallback = useCallback(() => {
    copy(
      selectedProjectFeatures,
      dynamicLayerSelection,
      projectFeatures,
      setToastMessagesAtom,
      allLayerSettings,
    );
  }, [
    selectedProjectFeatures,
    dynamicLayerSelection,
    projectFeatures,
    setToastMessagesAtom,
    allLayerSettings,
  ]);
  return (
    <Menu
      style={{
        boxShadow: "initial",
      }}
    >
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        disabled={!enableShowLayerInfo}
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <MenuItem
        name={"Copy"}
        icon={<CopyIcon />}
        shortcut={`${stringToUnicode("command")}+C`}
        onClick={() => {
          copyCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
    </Menu>
  );
};

export default OneOrMoreCanvasAndExternalSelectionMenu;
