import { useJotaiCallback } from "utils/jotai";
import { v4 as uuid4 } from "uuid";
import { ToastMessage, toastMessagesAtom } from "../state/toast";
import { atom } from "jotai";

export const toastAction = atom(
  null,
  (
    get,
    set,
    type: ToastMessage["type"],
    text: ToastMessage["text"],
    {
      timeout = 5000,
      showCountdown = true,
      link,
      groupId,
    }: Partial<
      Pick<ToastMessage, "timeout" | "showCountdown" | "link" | "groupId">
    > = {
      timeout: 5000,
      showCountdown: true,
    },
  ) => {
    const id = uuid4();
    const t: ToastMessage = {
      id,
      text,
      type,
      timeout,
      showCountdown: showCountdown ?? true,
      link,
      groupId,
    };
    set(toastMessagesAtom, (curr) => [
      ...curr.filter((s) => groupId === undefined || s.groupId !== groupId),
      t,
    ]);

    return () => {
      set(toastMessagesAtom, (curr) => curr.filter((m) => m.id !== id));
    };
  },
);

export const useToast = () => {
  const removeToast = useJotaiCallback((_get, set, id: string) => {
    set(toastMessagesAtom, (curr) => curr.filter((m) => m.id !== id));
  }, []);

  const success = useJotaiCallback(
    (
      _get,
      set,
      text: ToastMessage["text"],
      {
        timeout = 5000,
        showCountdown = true,
        link,
        groupId,
      }: Partial<
        Pick<ToastMessage, "timeout" | "showCountdown" | "link" | "groupId">
      > = {
        timeout: 5000,
        showCountdown: true,
      },
    ) => {
      const id = uuid4();
      const t: ToastMessage = {
        id,
        text,
        type: "success",
        timeout,
        showCountdown: showCountdown ?? true,
        link,
        groupId,
      };
      set(toastMessagesAtom, (curr) => [
        ...curr.filter((s) => groupId === undefined || s.groupId !== groupId),
        t,
      ]);

      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  const info = useJotaiCallback(
    (
      _get,
      set,
      text: ToastMessage["text"],
      {
        timeout = 5000,
        showCountdown = false,
        link,
        groupId,
      }: Partial<
        Pick<ToastMessage, "timeout" | "showCountdown" | "link" | "groupId">
      > = {
        timeout: 5000,
        showCountdown: false,
      },
    ) => {
      const id = uuid4();
      const t: ToastMessage = {
        id,
        text,
        type: "info",
        timeout,
        showCountdown: showCountdown ?? false,
        link,
        groupId,
      };
      set(toastMessagesAtom, (curr) => [
        ...curr.filter((s) => groupId === undefined || s.groupId !== groupId),
        t,
      ]);

      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  const warning = useJotaiCallback(
    (
      _get,
      set,
      text: ToastMessage["text"],
      {
        timeout = 5000,
        showCountdown = true,
        link,
        groupId,
      }: Partial<
        Pick<ToastMessage, "timeout" | "showCountdown" | "link" | "groupId">
      > = {
        timeout: 5000,
        showCountdown: true,
      },
    ) => {
      const id = uuid4();
      const t: ToastMessage = {
        id,
        text,
        type: "warning",
        timeout,
        showCountdown: showCountdown ?? true,
        link,
        groupId,
      };
      set(toastMessagesAtom, (curr) => [
        ...curr.filter((s) => groupId === undefined || s.groupId !== groupId),
        t,
      ]);

      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  const error = useJotaiCallback(
    (
      _get,
      set,
      text: ToastMessage["text"],
      {
        timeout = 5000,
        showCountdown = true,
        link,
        groupId,
      }: Partial<
        Pick<ToastMessage, "timeout" | "showCountdown" | "link" | "groupId">
      > = {
        timeout: 5000,
        showCountdown: true,
      },
    ) => {
      const id = uuid4();
      const t: ToastMessage = {
        id,
        text,
        type: "error",
        timeout,
        showCountdown: showCountdown ?? true,
        link,
        groupId,
      };
      set(toastMessagesAtom, (curr) => [
        ...curr.filter((s) => groupId === undefined || s.groupId !== groupId),
        t,
      ]);

      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  return {
    success,
    info,
    warning,
    error,
  };
};
