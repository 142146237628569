import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { modalTypeOpenAtom } from "state/modal";
import ExpandIcon from "@icons/14/Link.svg?react";
import { useAtom } from "jotai";

export const VideoModalType = "VideoModal";
const VIDEO_TUTORIALS_URL =
  "https://help.vindtech.com/en/collections/8361699-video-tutorials";

const VideoModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);

  if (!modalTypeOpen || modalTypeOpen.modalType !== VideoModalType) return null;

  const { title, videoId } = modalTypeOpen.metadata;

  return (
    <FullScreenModal>
      <ModalFrame
        title={title}
        onExit={() => setModalTypeOpen(undefined)}
        style={{ width: "115rem" }}
      >
        <Column>
          <Row style={{ justifyContent: "center" }}>
            <iframe
              src={videoId}
              width="1200"
              height="675"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{ border: "none" }}
              title="Short introduction"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js" />
          </Row>
          <Row style={{ justifyContent: "space-between" }}>
            <Button
              onClick={() => window.open(VIDEO_TUTORIALS_URL)}
              icon={<ExpandIcon />}
              text={"Watch more tutorials"}
              buttonType="text"
            />
            <Button
              onClick={() => setModalTypeOpen(undefined)}
              text={"Done"}
              buttonType="primary"
            ></Button>
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default VideoModal;
