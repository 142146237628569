import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import Add from "@icons/24/Add.svg?react";
import TeamMeeting from "@icons/24/TeamMeeting.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useState } from "react";
import { Group, GroupMembership } from "components/Organisation/Groups/types";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../style";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import SelectedLabel from "components/General/SelectedLabel";
import { SVGWrapper } from "@icons/svgUtils";
import NoItemsGeneric from "components/General/NoItemsGeneric";

export const UserGroupPicker = ({
  organisationId,
  selectedGroups,
  existingMemberships,
  toggleGroupSelection,
  onClose,
}: {
  organisationId: string;
  selectedGroups: Group[];
  existingMemberships: GroupMembership[];
  toggleGroupSelection(group: Group): void;
  onClose(): void;
}) => {
  const groupsInOrg = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const [name, onNameChange] = useTextInput("");
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);

  useEffect(() => {
    const fuse = new Fuse(groupsInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredGroups(name.length > 0 ? results : groupsInOrg);
  }, [name, groupsInOrg]);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={onClose}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        {filteredGroups.length > 0 ? (
          <ContentTableColumn
            style={{
              overflowY: "auto",
              padding: "1.2rem 0",
            }}
          >
            {filteredGroups.map((g) => {
              const selected = selectedGroups.some((sg) => sg.id === g.id);
              const alreadyGroupMember = existingMemberships.some(
                (eg) => eg.group_id === g.id,
              );
              return (
                <ContentTableRow
                  key={g.id}
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => !alreadyGroupMember && toggleGroupSelection(g)}
                  disabled={selected || alreadyGroupMember}
                >
                  <SVGWrapper size={1.4}>
                    <TeamMeeting />
                  </SVGWrapper>
                  <TextEllipsis
                    style={{
                      margin: 0,
                      marginRight: "auto",
                    }}
                  >
                    {g.name}
                  </TextEllipsis>
                  {alreadyGroupMember ? (
                    <SecondaryText
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      Already a member
                    </SecondaryText>
                  ) : selected ? (
                    <SecondaryText
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      Selected
                    </SecondaryText>
                  ) : (
                    <></>
                  )}
                  {!alreadyGroupMember && !selected && (
                    <Button
                      buttonType="primary"
                      text="Add"
                      icon={<Add />}
                      onClick={() => {}}
                      size="small"
                      style={{
                        padding: "0.2rem 1.2rem",
                      }}
                    />
                  )}
                </ContentTableRow>
              );
            })}
          </ContentTableColumn>
        ) : (
          <NoItemsGeneric
            headerText="No groups found"
            subText="Try searching for a group by name"
          />
        )}
      </ResultContainer>
    </>
  );
};

export default function UserGroupModal({
  existingMemberships,
  onSave,
}: {
  existingMemberships: GroupMembership[];
  onSave: (groups: Group[]) => void;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const toggleGroupSelection = (group: Group) => {
    setSelectedGroups((prevSelectedGroups) => {
      if (prevSelectedGroups.find((g) => g.id === group.id)) {
        return prevSelectedGroups.filter((g) => g.id !== group.id);
      } else {
        return [...prevSelectedGroups, group];
      }
    });
  };

  return (
    <SearchAndSelectContainer>
      <UserGroupPicker
        organisationId={organisationId}
        selectedGroups={selectedGroups}
        existingMemberships={existingMemberships}
        toggleGroupSelection={toggleGroupSelection}
        onClose={() => {
          onSave([]);
        }}
      />
      {selectedGroups.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "1.6rem 1.2rem",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedGroups.map((g) => {
                return (
                  <SelectedLabel
                    key={g.id}
                    title={g.name}
                    onDeselect={() => toggleGroupSelection(g)}
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => onSave(selectedGroups)}
              buttonType="primary"
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}
