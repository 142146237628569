import { projectIdAtom } from "state/pathParams";
import {
  CostConfiguration,
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
  isTurbineFeatureCost,
  isTurbineOverrideCost,
} from "../../../../services/costService";
import { useAtomValue, useSetAtom } from "jotai";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { Input, InputDimensioned, TextArea } from "../../../General/Input";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import { FirstRowWrapper, Row } from "../Capex";
import Turbine from "@icons/24/Turbine.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { DefaultCosts } from "../constants";
import { TURBINE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { isDefined } from "../../../../utils/predicates";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import TableIcon from "@icons/24/Table.svg?react";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { financialChangelogInfo } from "components/InputChangelog/const";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";

const TurbineSpecificCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleTurbineCostTypeChange =
    (type: "feature_cost" | "override") => () => {
      if (type === "feature_cost") {
        updateFixedCapex({
          turbines: type,
        });
      } else {
        updateFixedCapex({
          turbines: {
            unit: CostUnit.millionEuroPerMW,
            cost: DefaultCosts[CostType.Turbine].turbine["m€/MW"],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Turbines"}
          disabled={true}
          locked={true}
          style={{
            width: "100%",
          }}
        />

        {isTurbineFeatureCost(localConfig?.capex.fixed.turbines) ? (
          <Row
            style={{
              width: "100%",
            }}
          >
            <Tag>
              <TableIcon />
              From table
            </Tag>
            <IconBtn
              size={"1.4rem"}
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: ComponentsPreviewType,
                  metadata: {
                    componentType: TURBINE_MENU_ID,
                    isLibraryResource,
                    inLibraryContext,
                  },
                });
              }}
            >
              <TableIcon />
            </IconBtn>
            {inLibraryContext && (
              <HelpTooltip
                text={"Using cost specified in the procurement table"}
              />
            )}
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isTurbineOverrideCost(localConfig?.capex.fixed.turbines) &&
                  localConfig?.capex.fixed.turbines.unit) ||
                CostUnit.millionEuroPerMW
              }
              type="number"
              value={
                isTurbineOverrideCost(localConfig?.capex.fixed.turbines)
                  ? localConfig.capex.fixed.turbines.cost ??
                    DefaultCosts[CostType.Turbine].turbine[
                      CostUnit.millionEuroPerMW
                    ]
                  : undefined
              }
              initialValue={
                isTurbineOverrideCost(configuration?.capex.fixed.turbines)
                  ? configuration.capex.fixed.turbines.cost ??
                    DefaultCosts[CostType.Turbine].turbine[
                      CostUnit.millionEuroPerMW
                    ]
                  : undefined
              }
              onChange={(cost) =>
                updateFixedCapex(
                  {
                    cost,
                  },
                  "turbines",
                )
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{
                width: "100%",
              }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.fixed.turbines.cost",
                isLibraryResource ? "org_financial_manage" : "project",
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.fixed.turbines &&
                  isTurbineOverrideCost(localConfig?.capex.fixed.turbines)
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(localConfig?.capex.fixed.turbines === "feature_cost") ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (localConfig?.capex.fixed.turbines === "feature_cost")
                  return;
                updateFixedCapex({
                  turbines: {
                    ...localConfig?.capex.fixed.turbines,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.fixed.turbines.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from components"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.fixed.turbinesFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateFixedCapex({
                  turbinesFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}

        <Button
          size="small"
          icon={
            isTurbineFeatureCost(localConfig?.capex.fixed.turbines) ? (
              <PencilIcon />
            ) : (
              <TableIcon />
            )
          }
          buttonType="secondary"
          text={
            isTurbineFeatureCost(localConfig?.capex.fixed.turbines)
              ? "Override"
              : "Use table"
          }
          onClick={
            isTurbineFeatureCost(localConfig?.capex.fixed.turbines)
              ? handleTurbineCostTypeChange("override")
              : handleTurbineCostTypeChange("feature_cost")
          }
          disabled={isReadOnly}
        />
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

const TurbineInstallationCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();

  const isOnshoreConfig = configuration.type === "onshore";

  const handleTurbineCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ turbines: type });
      } else {
        updateInstallationCapex({
          turbines: {
            unit: CostUnit.thousandEuroPerMW,
            cost: DefaultCosts[CostType.Turbine].installation["k€/MW"],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.turbines) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.turbines,
                ) &&
                  localConfig?.capex.installation.turbines.unit) ||
                CostUnit.millionEuroPerMW
              }
              units={[CostUnit.thousandEuroPerMW, CostUnit.millionEuroPerMW]}
              type="number"
              value={
                isOperationsOverrideCost(
                  localConfig?.capex.installation.turbines,
                )
                  ? localConfig.capex.installation.turbines.cost ??
                    DefaultCosts[CostType.Turbine].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.turbines,
                )
                  ? configuration.capex.installation.turbines.cost ??
                    DefaultCosts[CostType.Turbine].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              onChange={(cost) => updateInstallationCapex({ cost }, "turbines")}
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.turbines.cost",
                isLibraryResource ? "org_financial_manage" : "project",
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.turbines &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.turbines,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(localConfig?.capex.installation.turbines === "operations_cost") ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.turbines === "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  turbines: {
                    ...localConfig?.capex.installation.turbines,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.turbines.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.installation.turbinesFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  turbinesFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.turbines) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.turbines)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.turbines)
                ? handleTurbineCostTypeChange("override")
                : handleTurbineCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const TurbineCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Turbines"
      category={CostType.Turbine}
      configuration={configuration}
      units={[
        CostUnit.thousandEuroPerMW,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerUnit,
        CostUnit.millionEuro,
      ]}
      icon={<Turbine />}
      defaultOpen={true}
      isReadOnly={isReadOnly}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
    >
      <TurbineSpecificCosts
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        isReadOnly={isReadOnly}
        configuration={configuration}
      />

      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <TurbineInstallationCosts
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        isReadOnly={isReadOnly}
        configuration={configuration}
      />

      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
