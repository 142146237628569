import { Map as MapboxMap, MercatorCoordinate } from "mapbox-gl";
import { fetchEnhancer, fetchWithToken } from "services/utils";
import { Tile, parentTileWithZ } from "types/tile";
import { MaybePromise } from "types/utils";
import { scream } from "utils/sentry";

function createNumberTexture(
  x: number,
  y: number,
  z: number,
  transparent: boolean = true,
  tileSize: number = 512,
): HTMLCanvasElement {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  canvas.width = tileSize;
  canvas.height = tileSize;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Failed to get 2D context");
  }

  if (transparent) {
    ctx.fillStyle = "rgba(255, 255, 255, 0.0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    return canvas;
  }

  // Set canvas background
  ctx.fillStyle = "rgba(255, 0, 0, 1.0)";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  if (transparent) return canvas;

  // Draw 1-pixel border
  ctx.strokeStyle = "black";
  ctx.lineWidth = 1;
  ctx.strokeRect(0.5, 0.5, canvas.width - 1, canvas.height - 1);

  // Draw numbers on the canvas
  ctx.fillStyle = "black";
  ctx.font = "48px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(`X: ${x}`, canvas.width / 2, canvas.height / 3);
  ctx.fillText(`Y: ${y}`, canvas.width / 2, canvas.height / 2);
  ctx.fillText(`Z: ${z}`, canvas.width / 2, (2 * canvas.height) / 3);

  return canvas;
}

// Function to fetch and create a canvas from a tile
async function fetchTileAsCanvas(
  url: string,
  useToken: boolean = false,
): Promise<HTMLCanvasElement> {
  const res = await (useToken
    ? fetchWithToken(url, { method: "get" })
    : fetchEnhancer(url, {
        method: "get",
        headers: {},
      }));
  if (!res.ok) throw new Error("Failed to fetch texture");

  const blob = await res.blob();
  const img = await createImageBitmap(blob);

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Failed to get 2D context");

  ctx.drawImage(img, 0, 0);

  return canvas;
}

// Helper method to create a WebGLTexture from a canvas
const createTextureFromCanvas = (
  gl: WebGL2RenderingContext,
  canvas: HTMLCanvasElement,
): WebGLTexture => {
  const texture = gl.createTexture();
  if (!texture) throw scream("Failed to create texture");
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  return texture;
};

/**
 * A texture and its uv offsets.
 * This is used for predenting that a larger texture is actually a smaller texture,
 * for instance if we have a 512x512 depth texture, but only care about a 256x256 part of it.
 * Instead of having a smaller texture, we keep the larger one and use uv offsets to sample the correct part.
 * The uv offsets in this case could be [0, 0, 0.5, 0.5] meaning that the lower left 1/4 of the texture is used.
 */
export type TextureUvOffset = {
  texture: WebGLTexture | null;
  /** [min u, min v, max u, max v] */
  uvOffsets: [number, number, number, number];
};

const createPlaceholderTexture = (
  gl: WebGL2RenderingContext,
  tileId: MercatorCoordinate,
  tileSize: number,
): WebGLTexture => {
  const canvasTexture = createNumberTexture(
    tileId.x,
    tileId.y,
    tileId.z || 0,
    true,
    tileSize,
  );
  const texture = gl.createTexture();
  if (!texture) throw scream("Failed to create texture");
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    canvasTexture,
  );
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  return texture;
};

/**
 * Take a child {@link Tile} of some higher parent tile together with the parent
 * UVs, and compute the correct child UVs.
 */
function getChildUVs(
  tile: Tile,
  parent: Tile,
  parentUvs: [number, number, number, number],
): [number, number, number, number] {
  const zoomDiff = tile.z - parent.z;
  if (zoomDiff < 0)
    throw scream("adjustUvOffsetsForHigherZoom: parent is child", {
      tile,
      parent,
    });
  const tileSize = 1 / Math.pow(2, zoomDiff);

  const relativeX = tile.x % Math.pow(2, zoomDiff);
  const relativeY = tile.y % Math.pow(2, zoomDiff);

  const [minU, minV, maxU, maxV] = parentUvs;
  const uRange = maxU - minU;
  const vRange = maxV - minV;

  const newMinU = minU + relativeX * tileSize * uRange;
  const newMinV = minV + relativeY * tileSize * vRange;
  const newMaxU = newMinU + tileSize * uRange;
  const newMaxV = newMinV + tileSize * vRange;

  return [newMinU, newMinV, newMaxU, newMaxV];
}

async function fetchAndCacheTextureWithUvOffsets(
  gl: WebGL2RenderingContext,
  tile: Tile,
  maxZoom: number,
  textureCache: Map<string, MaybePromise<TextureUvOffset>>,
  map: MapboxMap,
  urlFunc: (z: number, x: number, y: number) => string,
  useToken: boolean = false,
  isSubmitted: () => boolean,
): Promise<void> {
  const tileKey = urlFunc(tile.z, tile.x, tile.y);
  const entry = textureCache.get(tileKey);
  if (entry) return Promise.resolve(undefined); // lol

  if (maxZoom < tile.z) {
    // We're too far in. Request the best tile, and when it's ready, comptue the
    // UVs for us.
    const parent = parentTileWithZ(tile, maxZoom);
    const promise = fetchAndCacheTextureWithUvOffsets(
      gl,
      parent,
      maxZoom,
      textureCache,
      map,
      urlFunc,
      useToken,
      isSubmitted,
    ).then(async () => {
      let texP = textureCache.get(tileKey);
      if (!texP)
        throw scream("Awaited parent fetchAndCache, but not in cache.", {
          parent,
        });
      const tex = await texP;
      const uvOffsets = getChildUVs(tile, parent, tex.uvOffsets);
      return {
        texture: tex.texture,
        uvOffsets,
      };
    });
    textureCache.set(tileKey, promise);
  } else {
    // This should be a tile. Fetch it, and set the cache to the Promise (while
    // loading) and the result (when done).
    const promise = fetchTileAsCanvas(
      urlFunc(tile.z, tile.x, tile.y),
      useToken,
    ).then((canvas): TextureUvOffset => {
      if (isSubmitted()) return { texture: null, uvOffsets: [0, 0, 1, 1] };
      const texture = createTextureFromCanvas(gl, canvas);
      return { texture, uvOffsets: [0, 0, 1, 1] };
    });
    textureCache.set(tileKey, promise);
    promise.then((tex) => {
      if (tex.texture == null) return;
      // Confirm that we're only overwriting our own pending promise.
      if (textureCache.get(tileKey) === promise) {
        textureCache.set(tileKey, tex);
        map.triggerRepaint();
      }
    });
  }
}

/**
 * Get the best {@link TextureUvOffset} we have for rendering. Initiates requests
 * for potentially better tiles in the background.
 */
export function getBestAvailableTexture(
  gl: WebGL2RenderingContext,
  tileId: MercatorCoordinate,
  textureCache: Map<string, MaybePromise<TextureUvOffset>>,
  map: MapboxMap,
  urlFunc: (z: number, x: number, y: number) => string,
  maxZoom: number,
  tileSize: number,
  useToken: boolean = false,
  isSubmitted: () => boolean,
): TextureUvOffset {
  const tile = { x: tileId.x, y: tileId.y, z: tileId.z ?? 0 };
  const requestedKey = urlFunc(tile.z, tile.x, tile.y);

  const cachedTexture = textureCache.get(requestedKey);
  if (!cachedTexture) {
    fetchAndCacheTextureWithUvOffsets(
      gl,
      tile,
      maxZoom,
      textureCache,
      map,
      urlFunc,
      useToken,
      isSubmitted,
    );
  } else if (!(cachedTexture instanceof Promise)) {
    return cachedTexture;
  }

  // Look for the most zoomed in tile that we have in the cache
  for (let z = tile.z - 1; 0 < z; z--) {
    const parent = parentTileWithZ(tile, z);
    const parentKey = urlFunc(parent.z, parent.x, parent.y);
    const parentTex = textureCache.get(parentKey);
    if (parentTex && !(parentTex instanceof Promise)) {
      const uvOffsets = getChildUVs(tile, parent, parentTex.uvOffsets);
      return { texture: parentTex.texture, uvOffsets: uvOffsets };
    }
  }

  // Create a backup texture in case to just some /something/ right now.
  const placeholderTexture = createPlaceholderTexture(gl, tileId, tileSize);
  return { texture: placeholderTexture, uvOffsets: [0, 0, 1, 1] };
}
