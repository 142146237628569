import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useRefreshProjectTurbines } from "./useRefreshCustomTurbines";
import {
  DEFAULT_ONSHORE_OFFSHORE_TURBINES,
  SimpleTurbineType,
  TurbineType,
} from "types/turbines";
import {
  createNewProjectTurbine,
  updateProjectTurbine,
  getAdvancedProjectTurbine,
  deleteProjectTurbine,
} from "services/turbineAPIService";
import { Mixpanel } from "mixpanel";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { useJotaiCallback } from "utils/jotai";
import {
  advancedProjectTurbineTypesFamily,
  projectTurbineTypesFamily,
} from "state/jotai/turbineType";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog"; // Import the correct atom
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DuplicateComponentModalType } from "../Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";
export const useProjectTurbineCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { showConfirm } = useConfirm();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const refreshProjectTurbines = useRefreshProjectTurbines();
  const create = useJotaiCallback(
    (
      _,
      set,
      {
        turbine,
        name,
      }: {
        turbine: TurbineType;
        name?: string;
      },
    ) => {
      const newTurbine = {
        ...turbine,
        name: name ? name : `${turbine.name} copy`,
      };
      return createNewProjectTurbine(projectId, newTurbine).then((res) => {
        if (res) {
          refreshProjectTurbines();
          set(
            selectedMenuItemState({
              menuId: TURBINE_MENU_ID,
              projectId,
            }),
            res.id,
          );
          Mixpanel.track_old("Created new turbine", {
            turbineId: turbine.id,
          });
        }
      });
    },
    [projectId, refreshProjectTurbines],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      turbine: TurbineType,
      onlyUpdatingDescription: boolean = false,
    ) => {
      if (!projectId) return;
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "TURBINE",
          resourceId: turbine.id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "TURBINE",
          turbine.id,
        );
      }

      if (
        usage.length === 0 ||
        onlyUpdatingDescription ||
        (await showConfirm({
          title: "Update turbine",
          message: `Turbine type is currently being used in ${usage.length} turbines.`,
          confirmButtonText: "Update",
        }))
      ) {
        await updateProjectTurbine(projectId, turbine);
        set(
          advancedProjectTurbineTypesFamily({
            nodeId: projectId,
            turbineId: turbine.id,
          }),
          turbine,
        );

        if (!onlyUpdatingDescription) {
          refreshProjectTurbines();
        }
      }
    },
    [projectId, refreshProjectTurbines, showConfirm],
  );

  const duplicate = useJotaiCallback(
    async (_, set, turbineId: string, nodeId: string) => {
      const turbine =
        DEFAULT_ONSHORE_OFFSHORE_TURBINES.find(
          (turbine) => turbine.id === turbineId,
        ) ?? (await getAdvancedProjectTurbine(nodeId, turbineId));
      if (!turbine) {
        return;
      }
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "turbine",
          defaultName: `${turbine.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const newTurbine = {
              ...turbine,
              name: name,
            };
            const res = await createNewProjectTurbine(nodeId, newTurbine);
            if (res) {
              set(
                selectedMenuItemState({
                  menuId: TURBINE_MENU_ID,
                  projectId: nodeId,
                }),
                res.id,
              );
            }
            refreshProjectTurbines();
            Mixpanel.track_old("Duplicated turbine", {
              turbineId: turbine.id,
            });
          },
        },
      });
    },
    [refreshProjectTurbines, setMidScreenModalTypeOpen],
  );

  const onChangeName = useJotaiCallback(
    async (_get, _set, turbineId: string, newName: string) => {
      if (!projectId) {
        return;
      }

      const turbine = await getAdvancedProjectTurbine(projectId, turbineId);
      Mixpanel.track_old("Change name of turbine", {
        turbineId: turbine.id,
      });

      if (!turbine) {
        return;
      }
      return create({
        turbine: {
          ...turbine,
          name: newName,
        },
      });
    },
    [projectId, create],
  );

  const setSelectedMenuItemState = useJotaiCallback(
    (
      _,
      set,
      obj: {
        menuId: string;
        projectId: string;
      },
      state: string | undefined,
    ) => {
      set(selectedMenuItemState(obj), state);
    },
    [],
  );

  const remove = useJotaiCallback(
    async (get, set, turbineId: string) => {
      if (!projectId) return projectId;

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "TURBINE",
          resourceId: turbineId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "TURBINE",
          turbineId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete turbine",
          message: `Turbine type is currently being used in ${usage.length} turbines.`,
          confirmButtonText: "Delete",
        }))
      ) {
        deleteProjectTurbine(projectId, turbineId).then(() => {
          refreshProjectTurbines();
          setSelectedMenuItemState(
            {
              menuId: TURBINE_MENU_ID,
              projectId,
            },
            undefined,
          );
        });
      }
    },
    [projectId, refreshProjectTurbines, setSelectedMenuItemState, showConfirm],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, turbine: SimpleTurbineType) => {
      set(
        projectTurbineTypesFamily(projectId),
        async (currentTurbinesPromise) => {
          const currentTurbines = await currentTurbinesPromise;
          const newTurbines = new Map(currentTurbines);
          newTurbines.set(turbine.id, turbine);
          return newTurbines;
        },
      );
      set(
        advancedProjectTurbineTypesFamily({
          nodeId: projectId,
          turbineId: turbine.id,
        }),
        async (currentTurbinePromise) => {
          const currentTurbine = await currentTurbinePromise;
          if (!currentTurbine) return currentTurbinePromise;

          return {
            ...currentTurbine,
            ...turbine,
          };
        },
      );
    },
    [projectId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, turbineId: string) => {
      set(
        projectTurbineTypesFamily(projectId),
        async (currentTurbinesPromise) => {
          const currentTurbines = await currentTurbinesPromise;
          const newTurbines = new Map(currentTurbines);
          newTurbines.delete(turbineId);
          return newTurbines;
        },
      );
    },
    [projectId],
  );

  return {
    create,
    update,
    duplicate,
    onChangeName,
    remove,
    updateLocal,
    removeLocal,
  };
};
