import { ValidationWarningTypes } from "./utils";
import { pointInPolygon } from "utils/geometry";
import * as turf from "@turf/turf";
import { multiFeatureToFeatures } from "utils/geojson/utils";
import { isPolygonFeature } from "utils/predicates";
import { atomFamily } from "utils/jotai";
import { substationsInParkFamily } from "state/jotai/substation";
import {
  exclusionZonesByDomainFamily,
  exclusionZonesForParkByDomainFamily,
} from "state/jotai/exclusionZone";
import { atom } from "jotai";
import { cablesInParkFamily } from "state/jotai/cable";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { ValidationWarning } from "state/validationWarnings";

export const substationInExclusionZoneFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone>
        | undefined
      >
    >(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const exclusionZones = (
        await get(exclusionZonesByDomainFamily({ branchId }))
      ).substation;
      const overlaps = substations.filter((sub) =>
        exclusionZones.some((zone) =>
          pointInPolygon(sub.geometry, zone.geometry),
        ),
      );
      if (overlaps.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone,
        featureIds: overlaps.map((s) => s.id),
        parkId: parkId,
      };
    }),
);

export const cablesInsideNoCablesExclusionZoneWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.CableInsideNoCableExclusionZone>
        | undefined
      >
    >(async (get) => {
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const exclusionZones = (
        await get(exclusionZonesForParkByDomainFamily({ parkId, branchId }))
      ).cable;
      // Shrink all zones by 1m so that cables on the edge of the zone aren't included.
      // This is faster than checking that the cable is inside and not on the boundary of the polygon.
      const shrunkZones = exclusionZones
        .flatMap((zone) => {
          const buffer = turf.buffer(zone.geometry, -1, {
            units: "meters",
          });
          if (buffer == null) return [];
          return multiFeatureToFeatures(buffer);
        })
        .filter(isPolygonFeature);

      const overlaps = cables.filter((cable) =>
        shrunkZones.some((zone) => {
          return (
            turf.lineIntersect(cable.geometry, zone.geometry).features.length >
            0
          );
        }),
      );

      if (overlaps.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.CableInsideNoCableExclusionZone,
        featureIds: overlaps.map((s) => s.id),
        parkId: parkId,
      };
    }),
);

export const turbinesInExclusionZoneFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone>
        | undefined
      >
    >(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const exclusionZones = (
        await get(exclusionZonesForParkByDomainFamily({ parkId, branchId }))
      ).turbine;
      const overlaps = turbines.filter((sub) =>
        exclusionZones.some((zone) =>
          pointInPolygon(sub.geometry, zone.geometry),
        ),
      );
      if (overlaps.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone,
        featureIds: overlaps.map((s) => s.id),
        parkId: parkId,
      };
    }),
);
