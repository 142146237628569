import { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import {
  currentMapStyleAtom,
  editFeaturesAtom,
  mapAtom,
  mapTypeAtom,
} from "../../state/map";
import { isInChecklyMode } from "../../utils/utils";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import { getAccessTokenGlobal } from "../../state/global";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import { CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX } from "../../state/gisSourceCorsProxy";
import { MAP_EXAGGERATION, mapboxAccessToken } from "./constants";
import { useJotaiCallback } from "utils/jotai";
import { atom, useAtomValue, useSetAtom } from "jotai";
import ThreeDOSMBuildings from "components/MapNative/ThreeDOSMBuildings";
import { MapType } from "@constants/availableMapStyles";
import { featureFlag, useUrlFlag } from "components/General/FeatureFlag";
import { debounce } from "throttle-debounce";

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const NATIVE_MAP_ID = "main-map";

const startBounds = [
  [-6.15234375, 52.05249047600099],
  [17.75390625, 66.08936427047088],
] as [[number, number], [number, number]];

const maxBounds = [
  [-1000, -60],
  [1000, 81],
] as [[number, number], [number, number]];

mapboxgl.accessToken = mapboxAccessToken;
const defaultProjection: mapboxgl.Projection = {
  name: "mercator",
};

/**
 * We need to know if we're onshore or offshore before creating the map, since
 * this influences which map styles we're allowed to use. Instead of relying on
 * a specific component mount order, or effect order, we set up this atom that
 * we resolve only when the shore mode is set.
 *
 * This looks funny, but the only reliable alternative I could think of was making
 * `designToolTypeAtom` async, which would require all other atoms that use it
 * to be ascnc as well, which feels like a lot of boilerplate.
 */
export const waitWithCreatingTheMap = atom<Promise<any>>(new Promise(() => {}));

const MapNative = () => {
  useAtomValue(waitWithCreatingTheMap); // NOTE: wait for this promise to resolve.
  const setMapRef = useSetAtom(mapAtom);
  const mapContainer = useRef<HTMLDivElement>(null);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map>();
  const [mapResized, setMapResized] = useState(false);
  const inChecklyMode = useMemo(() => isInChecklyMode(), []);
  const setEditFeatures = useSetAtom(editFeaturesAtom);
  const mapType = useAtomValue(mapTypeAtom);
  const rotatingCamera = useUrlFlag(featureFlag.rotatingCamera);

  const useGlobe = mapType === MapType.GLOBE;
  const projection: undefined | mapboxgl.Projection = useMemo(
    () =>
      useGlobe
        ? {
            name: "globe",
          }
        : defaultProjection,
    [useGlobe],
  );

  const getInitialMapStyle = useJotaiCallback(
    (get) => get(currentMapStyleAtom).id,
    [],
  );

  useEffect(() => {
    if (!mapLoaded || !mapResized) return;
    setMapRef(mapLoaded);
  }, [setMapRef, mapLoaded, mapResized]);

  useEffect(() => {
    if (!mapLoaded || !rotatingCamera) return;
    const map = mapLoaded;
    const rotateCamera = (timestamp: number) => {
      map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      return requestAnimationFrame(rotateCamera);
    };
    const requestAnimationHandler = rotateCamera(0);
    return () => {
      cancelAnimationFrame(requestAnimationHandler);
    };
  }, [mapLoaded, rotatingCamera]);

  useEffect(() => {
    if (
      inChecklyMode ||
      !mapContainer.current ||
      mapContainer.current.childNodes.length !== 0
    )
      return;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: getInitialMapStyle(),
      bounds: startBounds,
      maxBounds,
      logoPosition: "bottom-right",
      preserveDrawingBuffer: true,
      hash: true,
      antialias: false, // Setting this to true makes some small lines appear on the map
      projection: defaultProjection,
      testMode: import.meta.env.VITE_CI ? true : undefined,
      // @ts-ignore: Looks like the Mapbox type is wrong here. This pattern is used in the API docs
      // https://docs.mapbox.com/mapbox-gl-js/api/properties/#requestparameters-example
      transformRequest: (url) => {
        const token = getAccessTokenGlobal();
        if (
          url.startsWith("/api/bathymetry") ||
          url.includes(CORS_AND_CACHE_PROXY_WITH_TOKEN_URL_PREFIX)
        ) {
          return {
            url: url,
            headers: {
              Authorization: "Bearer " + token,
            },
          };
        }
      },
    });
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: "metric",
    });
    map.addControl(scale);
    map.on("click", () => {
      window.getSelection()?.setPosition(null);
    });
    map
      .on("load", (e) => {
        setMapLoaded(e.target);
      })
      .on("remove", () => {
        setMapRef(undefined);
      });

    map.on("style.load", () => {
      if (!map.getSource("mapbox-dem")) {
        map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14,
        });
      }
      map.setTerrain({
        source: "mapbox-dem",
        exaggeration: MAP_EXAGGERATION,
      });
    });
  }, [getInitialMapStyle, inChecklyMode, setMapRef]);

  useEffect(() => {
    if (!mapLoaded || !projection) return;
    mapLoaded.setProjection(projection);
  }, [mapLoaded, projection]);

  useEffect(() => {
    if (mapLoaded) {
      const curentMapContainer = mapContainer.current;
      if (!curentMapContainer) return;

      // during some state changes a couple of resize events are fired, and a white flash is visible
      // so we debounce to avoid multiple resize events, this stops the white flash
      const debouncedResize = debounce(20, () => {
        mapLoaded.resize();
        setMapResized(true);
      });

      const resizeObserver = new ResizeObserver(debouncedResize);
      resizeObserver.observe(curentMapContainer);
      return () => {
        resizeObserver.unobserve(curentMapContainer);

        // Leave some time for cleanup effects in components that are dependant on the map
        setTimeout(() => {
          mapLoaded.remove();
          setEditFeatures(resetListIfNotAlreadyEmpty);
        }, 20);
      };
    }
  }, [mapLoaded, setEditFeatures]);

  return (
    <>
      {!mapLoaded && !isInChecklyMode && <FullScreenLoader />}
      <MapWrapper id={NATIVE_MAP_ID} data-map={1} ref={mapContainer} />
      <ThreeDOSMBuildings />
    </>
  );
};

export default MapNative;
