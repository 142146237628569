import { fetchEnhancerWithToken } from "./utils";
import {
  PROJECT_SERVICE_API_PATH,
  PROJECT_SERVICE_API_VERSION,
} from "components/ProjectElements/service";
import * as spec from "api/project-service";
import { z } from "zod";
import { LibraryManageRoleType } from "components/Organisation/Library/types";

const _ResourceTypeUsage = spec.components.schemas.ResourceTypeUsage;
export type ResourceTypeUsage = z.infer<typeof _ResourceTypeUsage>;

const _ResourceType = spec.components.schemas.ResourceType;
export type ResourceType = z.infer<typeof _ResourceType>;

export async function fetchLibraryResourceUsage(
  organisationId: string,
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">,
  resourceId: string,
) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/organisation/${organisationId}/libraryManageRole/${libraryManageRole}/resource/${resourceId}`,
    headers,
  );
  const j = await res.json();
  return _ResourceTypeUsage.array().parse(j);
}

export async function fetchProjectResourceUsage(
  nodeId: string,
  resourceType: ResourceType,
  resourceId: string,
) {
  const headers = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
    },
  };
  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/stats/node/${nodeId}/resource/${resourceType}/${resourceId}`,
    headers,
  );
  const j = await res.json();
  return _ResourceTypeUsage.array().parse(j);
}
