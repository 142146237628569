import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";
import { atom } from "jotai";
import { OtherFeature } from "types/feature";
import {
  isLineStringFeature,
  isMultiLineStringFeature,
  isMultiPointFeature,
  isMultiPolygonFeature,
  isPointFeature,
  isPolygonFeature,
} from "utils/predicates";
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "geojson";

const otherFeaturesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<OtherFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).other,
    ),
);

export const otherPointFeaturesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<OtherFeature<Point | MultiPoint>[]>>(async (get) =>
      (await get(otherFeaturesFamily({ branchId }))).filter(
        (f): f is OtherFeature<Point | MultiPoint> =>
          isPointFeature(f) || isMultiPointFeature(f),
      ),
    ),
);

export const otherLineFeaturesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<OtherFeature<LineString | MultiLineString>[]>>(async (get) =>
      (await get(otherFeaturesFamily({ branchId }))).filter(
        (f): f is OtherFeature<LineString | MultiLineString> =>
          isLineStringFeature(f) || isMultiLineStringFeature(f),
      ),
    ),
);

export const otherPolygonFeaturesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<OtherFeature<Polygon | MultiPolygon>[]>>(async (get) =>
      (await get(otherFeaturesFamily({ branchId }))).filter(
        (f): f is OtherFeature<Polygon | MultiPolygon> =>
          isPolygonFeature(f) || isMultiPolygonFeature(f),
      ),
    ),
);
