import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  isProjectBranchPublic,
  getProjectPublicSettings,
} from "./../services/projectDataAPIService";
import { PublicProjectBranchMeta, PublicProjectMeta } from "../types/api";

export const publicMetadataAtomFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string; branchId: string }) =>
    atomFromFn<Promise<PublicProjectBranchMeta>>(async () => {
      return isProjectBranchPublic(nodeId, branchId);
    }),
);

const publicProjectSettingsSelectorFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<PublicProjectMeta | undefined>>(async () => {
      if (!projectId) return;
      return getProjectPublicSettings(projectId);
    }),
);

export const publicProjectSettingsAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomFromFn<Promise<PublicProjectMeta | undefined>>(async (get) =>
      get(publicProjectSettingsSelectorFamily({ projectId })),
    ),
);
