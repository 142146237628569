import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import UploadIcon from "@icons/24/Upload.svg?react";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { Row } from "components/General/Layout";
import Radio, { RadioGroup } from "components/General/Radio";
import UploadCheckbox from "components/General/UploadCheckbox";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { SkeletonText } from "components/Loading/Skeleton";
import {
  UploadFileType,
  UploadModalType,
} from "components/UploadModal/UploadModal";
import { useSetAtom } from "jotai";
import { useMemo } from "react";
import {
  CustomWindSource,
  MultiCustomWindSource,
  MultipleSourceWindConfiguration,
  _MultipleSourceWindConfiguration,
  isMultipleSourceWindConfiguration,
} from "services/windSourceConfigurationService";
import { isWindDataMultipleHeights } from "state/jotai/windStatistics";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { UploadedWindData } from "state/windStatistics";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceSmall } from "styles/space";
import { typography } from "styles/typography";
import { CustomWindFileType, WindDataSource } from "types/metocean";
import { PreviewWindSource } from "./PreviewWindSource";

const MAX_TIMESERIES = 4;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceSmall};
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const CheckboxWrapper = styled.div`
  max-height: 22rem;
  overflow-y: auto;
`;

const RoundIconWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TurbineIconWrapper = styled(RoundIconWrapper)`
  background-color: ${colors.surfaceBrand};
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${colors.iconNegative};
    }
  }
`;

const UploadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  width: 100%;
`;

const MethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-top: 1.6rem;
`;

const RadioTitle = styled.div`
  ${typography.body}
`;

const LeftContentWrapper = styled.div`
  width: 35rem;
  padding: 0 1.6rem 0 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: ${colors.borderSubtle};
  }
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
`;

const Subtitle = styled.div`
  ${typography.sub1}
`;

const MultipleTimeSeries = ({
  windDataSets,
  disableActions,
  setLocalWindConfig,
  localWindConfig,
}: {
  windDataSets: UploadedWindData | undefined;
  disableActions: boolean;
  setLocalWindConfig: (w: MultipleSourceWindConfiguration) => void;
  localWindConfig: MultipleSourceWindConfiguration;
}) => {
  const uploadedTimeseries = useMemo<UploadedWindData>(() => {
    return [
      ...(windDataSets
        ? windDataSets.filter(
            (d) => !d.type || d.type === CustomWindFileType.TIMESERIES,
          )
        : []),
    ];
  }, [windDataSets]);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const isMaxTimeSeriesSelected = useMemo(() => {
    return isMultipleSourceWindConfiguration(localWindConfig)
      ? localWindConfig.source.data.length >= MAX_TIMESERIES
      : false;
  }, [localWindConfig]);

  return (
    <ContentWrapper>
      <LeftContentWrapper>
        {windDataSets === undefined ? (
          <SkeletonText text={"Loading timeseries..."} />
        ) : uploadedTimeseries.length === 0 ? (
          <NoResultsWrapper>
            <TurbineIconWrapper>
              <SingleTurbine />
            </TurbineIconWrapper>
            <p>
              <strong>No timeseries to show</strong>
            </p>
            <p>
              Upload timeseries to create a multiple timeseries configuration
            </p>
            <Button
              style={{ padding: "0 2rem" }}
              disabled={disableActions}
              size={"small"}
              text={"Upload"}
              icon={<UploadIcon />}
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: UploadModalType,
                  metadata: {
                    preSelectedFileType: UploadFileType.WIND_DATA,
                  },
                });
              }}
            />
          </NoResultsWrapper>
        ) : (
          <>
            <ResultsWrapper>
              <UploadRow>
                <Subtitle>Uploads</Subtitle>
                <Button
                  style={{ padding: "0 2rem" }}
                  disabled={disableActions}
                  buttonType="secondary"
                  icon={<UploadIcon />}
                  onClick={() => {
                    setMidScreenModalTypeOpen({
                      modalType: UploadModalType,
                      metadata: {
                        preSelectedFileType: UploadFileType.WIND_DATA,
                      },
                    });
                  }}
                />
              </UploadRow>
              <p>{`Select 2-${MAX_TIMESERIES} timeseries below`}</p>
              <CheckboxWrapper>
                <Label>
                  {uploadedTimeseries.map((i) => {
                    let showHeight = i.data
                      ? isWindDataMultipleHeights(i.data)
                        ? i.data.heights[0]
                        : i.data?.height
                      : 0;
                    return (
                      <UploadCheckbox
                        key={i.id}
                        onChange={(e) => {
                          if (
                            (isMaxTimeSeriesSelected && e.target.checked) ||
                            !i.data
                          ) {
                            return;
                          }
                          const newSource: CustomWindSource = {
                            id: i.id,
                            longitude: i.data.lon,
                            latitude: i.data.lat,
                            type: "custom",
                            height: showHeight,
                          };

                          let updatedConfig = localWindConfig;

                          // Check if the source is already in the list
                          const sourceExists = localWindConfig.source.data.some(
                            (source: CustomWindSource) =>
                              source.id === newSource.id,
                          );

                          if (sourceExists) {
                            // If the source is in the list, remove it
                            updatedConfig = {
                              ...localWindConfig,
                              source: {
                                type: "custom_multiple",
                                data: localWindConfig.source.data.filter(
                                  (source) => source.id !== newSource.id,
                                ),
                                interpolation:
                                  localWindConfig.source.interpolation,
                              },
                            };
                          } else {
                            const multiSource: MultiCustomWindSource = {
                              type: "custom_multiple",
                              data: [...localWindConfig.source.data, newSource],
                              interpolation:
                                localWindConfig.source.interpolation,
                            };

                            // If the source is not in the list, add it
                            updatedConfig = {
                              ...localWindConfig,
                              source: multiSource,
                            };
                          }

                          setLocalWindConfig(
                            _MultipleSourceWindConfiguration.parse(
                              updatedConfig,
                            ),
                          );
                        }}
                        label={i.name}
                        subLabel={showHeight}
                        disabled={
                          (isMaxTimeSeriesSelected &&
                            //Disable unckecked items if there are already 5 selected
                            isMultipleSourceWindConfiguration(
                              localWindConfig,
                            ) &&
                            !localWindConfig.source.data.some(
                              (source) => source.id === i.id,
                            )) ||
                          //Disable items with different heigth than the first selected
                          (isMultipleSourceWindConfiguration(localWindConfig) &&
                            localWindConfig.source.data.length > 0 &&
                            showHeight !==
                              localWindConfig.source.data[0]?.height)
                        }
                        checked={
                          isMultipleSourceWindConfiguration(localWindConfig) &&
                          localWindConfig.source.data.some(
                            (source) => source.id === i.id,
                          )
                        }
                      ></UploadCheckbox>
                    );
                  })}
                </Label>
              </CheckboxWrapper>
            </ResultsWrapper>
            <MethodWrapper>
              <Subtitle>Select method</Subtitle>
              <Row>
                <RadioTitle>Horizontal interpolation</RadioTitle>
                <HelpTooltip text="How to find the wind speed for each turbine position. Nearest: choose the nearest datapoint for each turbine. Weighted: inverse distance weighting between turbine position and wind data locations." />
              </Row>
              <RadioGroup
                style={{ paddingBottom: "1rem", flexDirection: "column" }}
              >
                <Radio
                  label="Nearest"
                  checked={localWindConfig.source.interpolation === "nearest"}
                  onChange={() => {
                    setLocalWindConfig({
                      ...localWindConfig,
                      source: {
                        ...localWindConfig.source,
                        interpolation: "nearest",
                      },
                    });
                  }}
                />
                <Radio
                  label="Distance weighted"
                  checked={localWindConfig.source.interpolation === "weighted"}
                  onChange={() => {
                    setLocalWindConfig({
                      ...localWindConfig,
                      source: {
                        ...localWindConfig.source,
                        interpolation: "weighted",
                      },
                    });
                  }}
                />
              </RadioGroup>
            </MethodWrapper>
          </>
        )}
      </LeftContentWrapper>
      <RightContentWrapper>
        {isMultipleSourceWindConfiguration(localWindConfig) &&
        localWindConfig.source.data.length > 0 ? (
          <PreviewWindSource
            selectedDataSourceType={WindDataSource.CUSTOM}
            points={localWindConfig.source.data.map((d) => ({
              lon: d.longitude,
              lat: d.latitude,
            }))}
          />
        ) : (
          <NoResultsWrapper>
            <TurbineIconWrapper>
              <SingleTurbine />
            </TurbineIconWrapper>
            <p>
              <strong>No timeseries selected</strong>
            </p>
            <p>
              Select from the list on the left or upload time series to show
              previews
            </p>
          </NoResultsWrapper>
        )}
      </RightContentWrapper>
    </ContentWrapper>
  );
};

export default MultipleTimeSeries;
