import { z } from "zod";
import { ProjectFeature, _FeatureParser } from "../../types/feature";
import { isDefined } from "../../utils/predicates";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";

export type UndoRedoAction = {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
};
export type UndoRedo = {
  action: UndoRedoAction;
  originAction: UndoRedoAction;
};

type HistorySource = {
  projectId: string;
  branchId: string;
};

export const undoState = atomFamily((_src: HistorySource | undefined) =>
  atom<UndoRedo[]>([]),
);

export const redoState = atomFamily((_src: HistorySource | undefined) =>
  atom<UndoRedo[]>([]),
);

export const _SafeParseObjectWithFeatureArray = z.object({
  features: z
    .unknown()
    .array()
    .transform((a) =>
      a
        .map((e) => {
          const res = _FeatureParser.safeParse(e);
          if (!res.success) return undefined;
          return res.data;
        })
        .filter(isDefined),
    ),
});
