import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  FormEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SendIcon from "@icons/24/Send.svg?react";
import Bin from "@icons/24/Bin.svg?react";
import Unread from "@icons/24/Unread.svg?react";
import Alarm from "@icons/24/Alarm.svg?react";
import CloseIcon from "@icons/24/Close.svg";
import { colors } from "../../../styles/colors";
import { dateToDateTime, range } from "../../../utils/utils";
import Button from "../../General/Button";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import CommentReactions from "../Reactions/CommentReactions";
import ReplyReactions from "../Reactions/ReplyReactions";
import {
  filteredThreadsSelectorFamily,
  followThreadsAtomFamily,
  newThreadAtomFamily,
  replySaveInProgressState,
  selectedThreadAtomFamily,
  threadRepliesAtomFamily,
} from "../state";
import { Reply, Thread } from "../types";
import { useReplyCrud } from "../hooks/useReplyCrud";
import { NewCommentModalInner } from "./NewCommentModal";
import { UserInfoProject } from "../../UserInfo/UserInfo";
import { UserInfoLoading } from "components/UserInfo/UserInfoLoading";
import TextareaWithMentions from "./TextareaWithMentions";
import TextWithMentions from "./TextWithMentions";
import { loggedInUserIdAtom } from "../../../state/user";
import { SkeletonRound, SkeletonText } from "../../Loading/Skeleton";
import { useReadComments } from "../UnreadComments/useReadComments";
import PreviewModal from "./PreviewModal";
import { getCurrentTokenUserId } from "../../../state/global";
import Like from "../../General/Like";
import { useThreadCrud } from "../hooks/useThreadCrud";
import {
  Divider,
  ButtonContainer,
  HeaderContainer,
  Container,
  MentionTextWrapper,
  ReplyWrapper,
  DeleteWrapper,
  Form,
} from "./style";
import { useFollowThreadCrud } from "../hooks/useFollowThreadCrud";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import { Column, Frame, Row } from "../../General/Layout";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { IconBtn } from "components/General/Icons";
import ResolvedLabel from "../LeftModal/ResolvedLabel";
import { useResolveThreadCrud } from "../hooks/useResolveThreadCrud";

export default function MapModal({ nodeId }: { nodeId: string }) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const { threads, resolvedThreads } = useAtomValue(
    filteredThreadsSelectorFamily({
      nodeId,
      branchId,
    }),
  );

  const combined = useMemo(
    () => threads.concat(resolvedThreads),
    [resolvedThreads, threads],
  );

  const newThreadOpen = useAtomValue(
    newThreadAtomFamily({
      projectId,
    }),
  );

  if (!nodeId) return null;

  return (
    <>
      <div>
        {newThreadOpen && (
          <NewCommentModalInner nodeId={nodeId} newThreadMeta={newThreadOpen} />
        )}
      </div>
      {combined.map((t) => (
        <CommentAndRepliesModalInner
          key={t.threadId}
          thread={t}
          nodeId={nodeId}
        />
      ))}
    </>
  );
}

function CommentAndRepliesModalInner({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const projectId = useAtomValue(projectIdAtom);

  const [text, setText] = useState("");
  const ref = useRef<HTMLTextAreaElement>(null);

  const [selectedThreadId, setSelectedThreadId] = useAtom(
    selectedThreadAtomFamily({
      nodeId: thread.nodeId,
    }),
  );

  useEffect(() => {
    if (selectedThreadId === thread.threadId) {
      setTimeout(() => ref.current?.focus(), 10);
    }
  }, [selectedThreadId, thread.threadId]);

  const { post } = useReplyCrud();

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      post(thread.threadId, {
        message: text,
      });
      setText("");
    },
    [post, text, thread.threadId],
  );

  const handleCancel = useCallback(() => {
    setText("");
    setSelectedThreadId(undefined);
  }, [setSelectedThreadId]);

  if (!projectId) return null;

  return (
    <PreviewModal currentProjectId={projectId} thread={thread} nodeId={nodeId}>
      <Frame
        style={{
          overflow: "visible",
          paddingTop: "1.2rem",
          paddingBottom: "0.8rem",
        }}
      >
        <CommentHeader thread={thread} />
        <Divider />
        <Suspense
          fallback={range(0, thread.replies).map((i) => (
            <CommentRowLoading key={i} />
          ))}
        >
          <CommentAndRepliesList thread={thread} nodeId={nodeId} />
        </Suspense>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <TextareaWithMentions
            text={text}
            onChange={setText}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            textAreaRef={ref}
            nodeId={nodeId}
          />
          <ButtonContainer>
            <Button
              type="submit"
              icon={<SendIcon />}
              buttonType="text"
              disabled={text.length === 0}
            />
          </ButtonContainer>
        </Form>
      </Frame>
    </PreviewModal>
  );
}

function CommentRowLoading() {
  return (
    <Column
      style={{
        padding: "0.6rem 1.2rem",
        gap: "0.8rem",
      }}
    >
      <Row>
        <SkeletonRound size={24} />
        <SkeletonText />
      </Row>
      <SkeletonText
        style={{
          marginLeft: "3.2rem",
          width: "calc(100% - 3.2rem)",
        }}
      />
    </Column>
  );
}

function CommentHeader({ thread }: { thread: Thread }) {
  const { remove } = useThreadCrud();
  const { put: putResolve } = useResolveThreadCrud();
  const { put: putFollow } = useFollowThreadCrud();
  const { showConfirm } = useConfirm();
  const { isRead, setRead, setUnread } = useReadComments();
  const isThreadRead = isRead(thread);

  const setSelectedThread = useSetAtom(
    selectedThreadAtomFamily({
      nodeId: thread.nodeId,
    }),
  );

  const follows = useAtomValue(
    loadable(
      followThreadsAtomFamily({
        nodeId: thread.nodeId,
        branchId: thread.branchId,
      }),
    ),
  );
  const isFollowed = useMemo(() => {
    if (follows.state !== "hasData") return false;
    return follows.data.some((f) => f.threadId === thread.threadId && f.follow);
  }, [follows, thread.threadId]);

  const onClose = useCallback(() => {
    setSelectedThread((cur) => (cur === thread.threadId ? undefined : cur));
  }, [setSelectedThread, thread.threadId]);

  const onResolve = useCallback(async () => {
    const newValue = !thread.resolved;
    putResolve(thread.threadId, newValue);
  }, [putResolve, thread.resolved, thread.threadId]);

  return (
    <HeaderContainer>
      <Row>
        <ResolvedLabel
          thread={thread}
          threadIsSelected={false}
          onResolve={onResolve}
        />
      </Row>
      <Row alignCenter>
        <DotMenu>
          <MenuItem
            name={isFollowed ? "Unfollow thread" : "Follow thread"}
            onClick={() => {
              putFollow(thread.threadId, !isFollowed);
            }}
            icon={<Alarm />}
          />
          <MenuItem
            name={isThreadRead ? "Mark as unread" : "Mark as rread"}
            onClick={() => {
              if (isThreadRead) {
                setUnread(thread);
              } else {
                setRead(thread);
              }
            }}
            icon={<Unread />}
          />
          <MenuItem
            name={"Delete thread"}
            onClick={async () => {
              if (
                await showConfirm({
                  title: "Delete comment",
                  message:
                    "This will permanently delete the comment with all replies and reactions.",
                  confirmButtonText: "Delete",
                })
              ) {
                remove(thread.threadId);
              }
            }}
            icon={<Bin />}
          />
        </DotMenu>
        <IconBtn onClick={onClose} size={"1.2rem"}>
          <CloseIcon />
        </IconBtn>
      </Row>
    </HeaderContainer>
  );
}

function CommentAndRepliesList({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const { setRead } = useReadComments();
  const replySaveInProgress = useAtomValue(replySaveInProgressState);
  const selectedThreadId = useAtomValue(
    selectedThreadAtomFamily({
      nodeId,
    }),
  );

  const currentProjectId = useAtomValue(projectIdAtom) ?? "";
  const [isHovered, setIsHovered] = useState(false);

  const replies = useAtomValue(
    threadRepliesAtomFamily({
      nodeId,
      branchId,
      threadId: thread.threadId,
    }),
  );
  useEffect(() => {
    if (selectedThreadId === thread.threadId) {
      setRead(
        thread,
        replies.map((r) => r.replyId),
      );
    }
  }, [replies, selectedThreadId, setRead, thread]);

  const timeString = useMemo(() => {
    return dateToDateTime(new Date(thread.threadTimestamp));
  }, [thread.threadTimestamp]);

  return (
    <Container>
      <Column>
        <Row alignCenter>
          <Suspense fallback={<UserInfoLoading />}>
            <UserInfoProject
              userId={thread.userId}
              projectId={currentProjectId}
            />
          </Suspense>
          <p
            style={{
              color: colors.secondaryText,
            }}
          >
            {timeString}
          </p>
        </Row>
        <MentionTextWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            justifyContent: "space-between",
          }}
        >
          <TextWithMentions message={thread.message} />
          <CommentReactions
            threadIsSelected={false}
            parentIsHovered={isHovered}
            thread={thread}
            nodeId={nodeId}
          />
        </MentionTextWrapper>
      </Column>
      {replies.map((r) => (
        <ReplyElement key={r.replyId} reply={r} />
      ))}
      {replySaveInProgress?.threadId === thread.threadId && (
        <LoadingReplyElement
          message={replySaveInProgress.message}
          userId={getCurrentTokenUserId()}
        />
      )}
    </Container>
  );
}

function LoadingReplyElement({
  message,
  userId,
}: {
  message: string;
  userId: string | undefined;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  return (
    <ReplyWrapper
      style={{
        opacity: 0.6,
        pointerEvents: "none",
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
        }}
      >
        {userId ? (
          <UserInfoProject userId={userId} projectId={projectId} />
        ) : (
          <UserInfoLoading />
        )}
      </Row>
      <MentionTextWrapper
        style={{
          justifyContent: "space-between",
        }}
      >
        <TextWithMentions message={message} />
        <Like
          like={false}
          onClick={() => {}}
          reactionList={null}
          totalLikes={0}
        />
      </MentionTextWrapper>
    </ReplyWrapper>
  );
}
function ReplyElement({ reply }: { reply: Reply }) {
  const timeString = useMemo(() => {
    return dateToDateTime(new Date(reply.replyTimestamp));
  }, [reply.replyTimestamp]);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ReplyWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Row
        style={{
          justifyContent: "space-between",
        }}
        alignCenter
      >
        <Row alignCenter>
          <UserInfoProject userId={reply.userId} projectId={projectId} />
          <Row>
            <p
              style={{
                margin: 0,
                color: colors.primaryDisabled,
              }}
            >
              {timeString}
            </p>
          </Row>
        </Row>

        <DeleteReply reply={reply} />
      </Row>
      <MentionTextWrapper
        style={{
          justifyContent: "space-between",
        }}
      >
        <TextWithMentions message={reply.message} />
        {projectId && (
          <ReplyReactions
            parentIsHovered={isHovered}
            reply={reply}
            nodeId={projectId}
            threadIsSelected={false}
          />
        )}
      </MentionTextWrapper>
    </ReplyWrapper>
  );
}

function DeleteReply({ reply }: { reply: Reply }) {
  const currentUserId = useAtomValue(loggedInUserIdAtom);
  const { remove } = useReplyCrud();

  const showDelete = useMemo(
    () => currentUserId && reply.userId === currentUserId,
    [currentUserId, reply.userId],
  );

  if (!showDelete) return null;

  return (
    <DeleteWrapper>
      <Bin onClick={() => remove(reply.threadId, reply.replyId)} />
    </DeleteWrapper>
  );
}
