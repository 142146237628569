import { SetStateAction } from "jotai";
import React, {
  Dispatch,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

const TabContext = createContext<{
  selected: undefined | string;
  setSelected: Dispatch<SetStateAction<undefined | string>>;
}>({
  selected: undefined,
  setSelected: () => {},
});

const _Tab = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;

  ${typography.sub2};
  color: ${colors.textBrand};

  svg {
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 8px;
    overflow: visible;

    path[stroke] {
      stroke: ${colors.textBrand};
    }
    path:not([stroke]) {
      fill: ${colors.textBrand};
    }
  }

  &:hover {
    background: ${colors.surfaceHover};
    cursor: pointer;
  }

  &[data-selected="true"] {
    color: ${colors.textSelected};
    background: ${colors.surfaceSelectedLight};
  }
  &:focus {
    background: red;
  }
`;
/**
 * Title component for the tab.  When defining the tab, the children of this is
 * the tab content.
 */
export function Tab({
  name,
  icon,
  _key,
}: PropsWithChildren<{
  name: string;
  icon?: ReactNode;
  _key?: string;
}>) {
  const { selected, setSelected } = useContext(TabContext);
  return (
    <_Tab
      onClick={() => setSelected(_key)}
      role="tab"
      data-selected={String(selected === _key)}
    >
      {icon}
      <span>{name}</span>
    </_Tab>
  );
}

const Divider = styled.div`
  background: ${colors.borderSubtle};
  width: 1px;
  justify-self: center;
`;

const _VTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 4.9rem 1fr;

  nav {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.8rem;
  }
`;
export const VTab = forwardRef<
  {
    setSelectedKey(k: string): void;
  },
  PropsWithChildren
>(({ children }: PropsWithChildren<{}>, ref) => {
  const [selected, setSelected] = useState<undefined | string>(undefined);

  const [tabList, chosen] = useMemo(() => {
    const list: React.ReactElement[] = [];
    let chosen;
    React.Children.map(children, (c, i) => {
      if (!React.isValidElement(c)) return;
      const key = c.key ?? `__VTab_internal_${i}`;

      if (key === selected) chosen = c.props.children;
      if ((c.type as any)["name"] !== "Tab") {
        console.error(
          `Children of '<VTab>' should only be '<Tab>'; found "${(c.type as any)["name"]}"`,
        );
      }
      list.push(
        React.cloneElement<any>(c, {
          key,
          _key: key,
        }),
      );
    });
    return [list, chosen];
  }, [children, selected]);

  if (!selected && 0 < tabList.length) setSelected(tabList[0].key ?? "");

  useImperativeHandle(
    ref,
    () => {
      return {
        setSelectedKey: (k: string) => setSelected(k),
      };
    },
    [],
  );

  return (
    <TabContext.Provider value={{ selected, setSelected }}>
      <_VTab>
        <nav className="vtab-nav">{tabList}</nav>
        <Divider />
        <div>{chosen}</div>
      </_VTab>
    </TabContext.Provider>
  );
});
