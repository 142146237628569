import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { useCallback } from "react";
import debounce from "debounce-promise";
import { sortProjectElements, ProjectElementSortOrder } from "./service";
import { getProjectElementsSortOrderAtomFamily } from "./state";
import { useAtom } from "jotai";

const debouncedSortProjectElements = debounce(sortProjectElements, 1000);
export const useProjectElementsSortOrder = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const [sortOrder, setSortOrder] = useAtom(
    getProjectElementsSortOrderAtomFamily({
      nodeId: projectId,
      branchId,
    }),
  );

  const localSortProjectElements = useCallback(
    (newSortOrder: ProjectElementSortOrder[]) => {
      setSortOrder(Promise.resolve(newSortOrder));
    },
    [setSortOrder],
  );

  const _sortProjectElements = useJotaiCallback(
    async (
      _get,
      _set,
      newSortOrder: ProjectElementSortOrder[],
      _branchId: string = branchId,
    ) => {
      localSortProjectElements(newSortOrder);

      await debouncedSortProjectElements(projectId, _branchId, newSortOrder);
    },
    [branchId, localSortProjectElements, projectId],
  );

  return {
    sortOrder,
    sortProjectElements: _sortProjectElements,
    localSortProjectElements,
  };
};
