import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { z } from "zod";
import { ABLY_CABLE_TYPE_UPDATE, ABLY_CABLE_TYPE_DELETE } from "state/ably";
import useOrgCableCrud from "components/Organisation/Library/cable/useOrgCableCrud";
import { _CableType } from "services/cableTypeService";

export function useAblyLibraryCable(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgCableCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_cable_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const cable = _CableType.parse(message.data.meta);
      updateLocal(cable);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const cableId = z.string().parse(message.data.meta);
      removeLocal(cableId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_CABLE_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_CABLE_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
