import styled from "styled-components";
import { colors } from "styles/colors";
import { useDrop } from "react-dnd";
import { ReactNode, useState, useCallback } from "react";

const DropIndicator = styled.div<{
  isOver: boolean;
  position: "top" | "bottom";
}>`
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ isOver }) =>
    isOver ? colors.blue900 : "transparent"};
  ${({ position }) => (position === "top" ? "top: 0;" : "bottom: 0;")}
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropTarget = styled.div<{ isOver: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOver }) =>
    isOver ? colors.surfaceSelectedLight : "transparent"};
  pointer-events: none;
`;

interface DraggableItemWrapperProps {
  children: ReactNode;
  acceptTypes: string[];
  onDrop: (item: any) => void;
}

export const DraggableItemWrapper = ({
  children,
  acceptTypes,
  onDrop,
}: DraggableItemWrapperProps) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [{ isOverMain }, dropMainRef] = useDrop(
    () => ({
      accept: acceptTypes,
      drop: (item: any, monitor) => {
        if (monitor.didDrop()) return;
        // Only handle the drop if we're not dropping on the top or bottom zones
        const clientOffset = monitor.getClientOffset();
        const dropTargetRect = wrapperElement?.getBoundingClientRect();

        if (clientOffset && dropTargetRect) {
          const y = clientOffset.y - dropTargetRect.top;
          if (y > 8 && y < dropTargetRect.height - 8) {
            onDrop(item);
          }
        }
      },
      collect: (monitor) => ({
        isOverMain:
          monitor.isOver({ shallow: true }) &&
          !monitor.isOver({ shallow: false }),
      }),
    }),
    [onDrop, wrapperElement, acceptTypes],
  );

  const [{ isOverTop }, dropTopRef] = useDrop(
    () => ({
      accept: acceptTypes,
      drop: (item: any, monitor) => {
        if (monitor.didDrop()) return;
        onDrop(item);
      },
      collect: (monitor) => ({
        isOverTop: monitor.isOver({ shallow: true }),
      }),
    }),
    [onDrop, acceptTypes],
  );

  const [{ isOverBottom }, dropBottomRef] = useDrop(
    () => ({
      accept: acceptTypes,
      drop: (item: any, monitor) => {
        if (monitor.didDrop()) return;
        onDrop(item);
      },
      collect: (monitor) => ({
        isOverBottom: monitor.isOver({ shallow: true }),
      }),
    }),
    [onDrop, acceptTypes],
  );

  // Combine the refs using useCallback
  const setWrapperRef = useCallback(
    (el: HTMLDivElement | null) => {
      setWrapperElement(el);
      dropMainRef(el);
    },
    [dropMainRef],
  );

  return (
    <Wrapper ref={setWrapperRef}>
      <div
        ref={dropTopRef}
        style={{
          position: "absolute",
          top: -4,
          left: 0,
          right: 0,
          height: 8,
          zIndex: 1,
        }}
      />
      <div
        ref={dropBottomRef}
        style={{
          position: "absolute",
          bottom: -4,
          left: 0,
          right: 0,
          height: 8,
          zIndex: 1,
        }}
      />
      <DropIndicator isOver={isOverTop} position="top" />
      <DropIndicator isOver={isOverBottom} position="bottom" />
      <DropTarget isOver={isOverMain} />
      {children}
    </Wrapper>
  );
};
