import { ABLY_SHADOW } from "../state/ably";
import { useMemo } from "react";
import { useAblyGeneric } from "./useAblyGeneric";
import { InboundMessage } from "ably";
import {
  shadowJobOnSuccess,
  currentJobsAtom,
  fetchJobsAtom,
  shadowJobOnProgress,
} from "components/ShadowFlicker/state";
import { useSetAtom } from "jotai";
import { _Response } from "components/ShadowFlicker/types";

export function useAblyShadowFlickerStatusUpdate(projectId: string) {
  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const setAllJobs = useSetAtom(currentJobsAtom);
  const fetchJobs = useSetAtom(fetchJobsAtom);
  const record = useSetAtom(shadowJobOnProgress);
  const onSuccess = useSetAtom(shadowJobOnSuccess);

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SHADOW,
        onMessageReceived: async (msg: InboundMessage) => {
          const res = _Response.parse(msg.data);
          if (res.status === "running") record(res);
          else if (res.status === "succeeded") {
            const jobs = await fetchJobs();
            setAllJobs(jobs);
            onSuccess(res);
          }
        },
      },
    ],
    [record, fetchJobs, setAllJobs, onSuccess],
  );

  useAblyGeneric(channelName, events, projectId);
}
