import { Suspense, useCallback, useEffect, useState } from "react";
import { TurbineType } from "types/turbines";
import useOrgTurbineCrud from "components/Organisation/Library/turbine/useOrgTurbineCrud";
import { idToTurbineChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import TurbineSettingCustomAdmin from "components/ConfigurationModal/TurbineSettings/TurbineSettingCustomAdmin";
import { TurbineProjects } from "./availability/TurbineProjects";
import { TurbineCostElement } from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const TurbineResourceContent = ({
  resource,
  organisationId,
}: {
  resource: TurbineType;
  organisationId: string;
}) => {
  const { update: updateTurbine } = useOrgTurbineCrud();

  const [tempTurbine, setTempTurbine] = useState(resource);

  useEffect(() => {
    setTempTurbine(resource);
  }, [resource, setTempTurbine]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<TurbineType>) => {
      setTempTurbine((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempTurbine],
  );

  const onSaveNameOrDescription = useCallback(
    (turbine: TurbineType) => {
      updateTurbine(turbine, true);
    },
    [updateTurbine],
  );

  if (!tempTurbine) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"turbine"}
        description={tempTurbine.description ?? ""}
        name={tempTurbine.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempTurbine.name,
            description: description ?? tempTurbine.description,
          })
        }
        onSave={({ name, description }) => {
          onSaveNameOrDescription({
            ...tempTurbine,
            name: name ?? tempTurbine.name,
            description: description ?? tempTurbine.description,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            description: resource.description,
          });
        }}
        changelogData={{
          id: idToTurbineChangelogId(resource.id),
          category: "org_turbine_manage",
        }}
      >
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("turbine")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <ResourceContentTabs
        tabs={turbineTabs(resource, organisationId!, updateTurbine)}
      />
    </>
  );
};

const turbineTabs = (
  resource: TurbineType,
  organisationId: string,
  update: (turbine: TurbineType) => Promise<any>,
) => [
  {
    name: "Turbine details",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <TurbineSettingCustomAdmin
          key={resource.id}
          turbine={resource}
          onSave={update}
          isLoading={false}
          nodeId={organisationId}
          readOnly={false}
          commentCategory="org_turbine_manage"
          turbineCostElement={<TurbineCostElement turbineType={resource} />}
        />
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <TurbineProjects resource={resource} />
      </Suspense>
    ),
  },
];
