import { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import { SortState } from "../../types";
import useTextInput from "hooks/useTextInput";

interface Resource {
  id: string;
  name?: string | null;
}

export const useLibrarySearchAndSort = <T extends Resource>(
  resources: T[],
  sortState: SortState,
) => {
  const [name, onNameChange] = useTextInput("");
  const [filteredResources, setFilteredResources] = useState<T[]>([]);

  useEffect(() => {
    if (!name) {
      setFilteredResources(resources);
      return;
    }

    const fuse = new Fuse(resources, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(name).map((result) => result.item);
    setFilteredResources(results);
  }, [name, resources]);

  const sortedResources = useMemo(() => {
    if (sortState.order === "NONE" || !sortState.field) {
      return filteredResources;
    }

    const uniqueResources = Array.from(
      new Map(filteredResources.map((r) => [r.id, r])).values(),
    );

    return uniqueResources.sort((a, b) => {
      if (!sortState.field) return 0;
      const fieldA = (a[sortState.field] ?? "")?.toString().toLowerCase();
      const fieldB = (b[sortState.field] ?? "")?.toString().toLowerCase();
      if (!fieldA) return -1;
      if (!fieldB) return 1;
      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [filteredResources, sortState.field, sortState.order]);

  return {
    name,
    onNameChange,
    sortedResources,
  };
};
