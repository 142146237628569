import { useState, useEffect, useCallback } from "react";
import * as turf from "@turf/turf";
import DownloadIcon from "@icons/24/Download.svg";
import { setHover, removeHover } from "components/Mapbox/utils";
import { exportCableSourceId } from "components/Mapbox/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";
import { cableMatrixMap } from "./CableMatrixMapWidget";
import { CenterContainer, SafeCard } from "./Base";
import Table from "components/Dashboard/Table";
import { MenuItem } from "components/General/Menu";
import { downloadText, undefMap } from "utils/utils";
import { kmStyle } from "components/Dashboard/utils";
import { useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { exportCablesInParkFamily } from "state/jotai/exportCable";

type ExportCableInfo = {
  featureId: string;
  name: string | undefined;
  offshoreLength: number;
  onshoreLength: number;
  totalLength: number;
  voltage?: number;
};

const useData = () => {
  const { park, branch } = useDashboardContext();
  return useJotaiCallback(
    async (get) => {
      const exportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );
      const exportCables = await get(
        exportCablesInParkFamily({ parkId: park.id, branchId: branch.id }),
      );
      const exportCableSegments = await get(
        exportCableSplitsOkFamily({ parkId: park.id, branchId: branch.id }),
      );
      return exportCables
        .map((f) => {
          const cableType = undefMap(f.properties.cableTypeId, (tid) =>
            exportCableTypes.get(tid),
          );
          const segments = exportCableSegments.find(
            (seg) => seg.exportCable.id === f.id,
          );

          return {
            featureId: f.id,
            name: f.properties.name,
            offshoreFeatureId: segments?.offshore.id,
            offshoreLength: segments
              ? turf.length(segments.offshore, { units: "kilometers" })
              : turf.length(f, { units: "kilometers" }),
            onshoreFeatureId: segments?.onshore.id,
            onshoreLength: segments
              ? turf.length(segments.onshore, { units: "kilometers" })
              : 0,
            voltage: cableType?.voltage,
          };
        })
        .map((f) => ({
          ...f,
          totalLength: f.offshoreLength + f.onshoreLength,
        }));
    },
    [park.id, branch.id],
  );
};

const ExportCables = () => {
  const map = useAtomValue(cableMatrixMap);
  const getData = useData();

  const [dataPerExportCable, setData] = useState<
    undefined | ExportCableInfo[] | Error
  >(undefined);

  useEffect(() => {
    let isMounted = true;
    getData()
      .then((d) => {
        if (isMounted) {
          setData(d);
        }
      })
      .catch((e) => {
        if (isMounted) {
          setData(e);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [getData]);

  if (dataPerExportCable instanceof Error) {
    throw dataPerExportCable;
  }

  if (dataPerExportCable?.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} />
      </CenterContainer>
    );

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Voltage (kV)</th>
          <th>Offshore cable length (km)</th>
          <th>Onshore cable length (km)</th>
          <th>Total length (km)</th>
        </tr>
      </thead>
      <tbody>
        {dataPerExportCable?.map((cable) => (
          <tr
            key={cable.featureId}
            onMouseEnter={() => {
              if (cable && map) {
                setHover(map, exportCableSourceId, cable.featureId);
              }
            }}
            onMouseLeave={() => {
              if (cable && map) {
                removeHover(map, exportCableSourceId, cable.featureId);
              }
            }}
          >
            <td>{cable.name}</td>
            <td>{cable.voltage ? cable.voltage : "-"}</td>
            <td>{kmStyle.format(cable.offshoreLength)}</td>
            <td>{kmStyle.format(cable.onshoreLength)}</td>
            <td
              style={{
                fontWeight: 600,
              }}
            >
              {kmStyle.format(cable.totalLength)} km
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const ExportCablesMenu = () => {
  const getData = useData();
  const { park, branch } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    const csvRows = [
      [
        "Name",
        "Voltage (kV)",
        "Offshore cable length (km)",
        "Onshore cable length (km)",
        "Total length (km)",
      ],
      ...data.map((cable) => [
        cable.name,
        cable.voltage ? cable.voltage : "",
        kmStyle.format(cable.offshoreLength),
        kmStyle.format(cable.onshoreLength),
        kmStyle.format(cable.totalLength),
      ]),
    ];
    downloadText(
      csvRows.join("\n"),
      `${park.properties.name}_${branch.title}_export_cables.csv`,
    );
  }, [branch.title, getData, park.properties.name]);

  return (
    <MenuItem
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      onClick={downloadCSV}
    />
  );
};

export const ExportCablesWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Export cables"
      id="Export cables"
      menuItems={<ExportCablesMenu />}
      resetKeys={errorBoundaryResetKeys}
    >
      <ExportCables />
    </SafeCard>
  );
};
