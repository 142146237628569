/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Group = z
      .object({
        author: z.string().optional(),
        start: z.number(),
        end: z.number(),
        meta: z
          .object({
            count: z.number(),
          })
          .passthrough(),
      })
      .passthrough();
    export const PartialMooringLineType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        material: z.string().optional(),
        diameter: z.number().optional(),
        EA: z.number().optional(),
        wetWeight: z.number().optional(),
        cost: z.number().optional(),
        costUnit: z.literal("m€/km").optional(),
        note: z.string().optional(),
      })
      .passthrough();
    export const MooringLineType = z
      .object({
        id: z.string(),
        name: z.string(),
        material: z.string(),
        diameter: z.number(),
        EA: z.number(),
        wetWeight: z.number(),
        cost: z.number(),
        costUnit: z.literal("m€/km"),
        note: z.string().optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const MooringLineVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        mooringLine: MooringLineType,
      })
      .passthrough();
    export const PartialSubstationType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        cost: z.number().optional(),
        costUnit: z
          .union([z.literal("m€/unit"), z.literal("k€/MW")])
          .optional(),
        type: z.union([z.literal("onshore"), z.literal("offshore")]).optional(),
        impedance: z.number().optional(),
        resistance: z.number().optional(),
        ironLosses: z.number().optional(),
        noLoadCurrent: z.number().optional(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        dcResistance: z.number().optional().default(0.003),
        dcIronLosses: z.number().optional().default(0.00025),
        lossFactorA: z.number().optional().default(0.00035),
        lossFactorB: z.number().optional().default(0.004),
        lossFactorC: z.number().optional().default(0.0027),
        note: z.string().optional(),
      })
      .passthrough();
    export const SubstationIdentifier = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
      })
      .passthrough();
    export const SubstationTypePost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        cost: z.number().optional(),
        costUnit: z
          .union([z.literal("m€/unit"), z.literal("k€/MW")])
          .optional(),
        type: z.union([z.literal("onshore"), z.literal("offshore")]).optional(),
        impedance: z.number().optional(),
        resistance: z.number().optional(),
        ironLosses: z.number().optional(),
        noLoadCurrent: z.number().optional(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        dcResistance: z.number().optional().default(0.003),
        dcIronLosses: z.number().optional().default(0.00025),
        lossFactorA: z.number().optional().default(0.00035),
        lossFactorB: z.number().optional().default(0.004),
        lossFactorC: z.number().optional().default(0.0027),
        note: z.string().optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const SubstationType = z
      .object({
        id: z.string(),
        name: z.string(),
        cost: z.number().optional(),
        costUnit: z
          .union([z.literal("m€/unit"), z.literal("k€/MW")])
          .optional(),
        type: z.union([z.literal("onshore"), z.literal("offshore")]),
        impedance: z.number(),
        resistance: z.number(),
        ironLosses: z.number(),
        noLoadCurrent: z.number(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        dcResistance: z.number().optional().default(0.003),
        dcIronLosses: z.number().optional().default(0.00025),
        lossFactorA: z.number().optional().default(0.00035),
        lossFactorB: z.number().optional().default(0.004),
        lossFactorC: z.number().optional().default(0.0027),
        note: z.string().optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const SubstationVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        substation: SubstationType,
      })
      .passthrough();
    export const PartialCableType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        /** @Deprecated */
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().optional().default(66),
        reactance: z.number().nullable().optional(),
        capacitance: z.number().nullable().optional(),
        resistance: z.number().optional(),
        ampacity: z.number().nullable().optional(),
        useAdvancedSettings: z.boolean().optional().default(false),
        lambda1: z.number().optional(),
        lambda2: z.number().optional(),
        dielectricLossFactor: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
        /** If the cable is an export cable and advanced settings are used, this field should be set to either alternative or both. If advanced settings are not used (use default values) or it is an inter-array cable, it should not be set. */
        exportCableUsage: z
          .object({
            offshore: z.boolean().optional(),
            onshore: z.boolean().optional(),
          })
          .passthrough()
          .optional(),
      })
      .passthrough();
    export const CableType = z
      .object({
        id: z.string(),
        name: z.string(),
        /** @Deprecated */
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().default(66),
        reactance: z.number().nullable(),
        capacitance: z.number().nullable(),
        resistance: z.number(),
        ampacity: z.number().nullable(),
        useAdvancedSettings: z.boolean().optional().default(false),
        lambda1: z.number().optional(),
        lambda2: z.number().optional(),
        dielectricLossFactor: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
        /** If the cable is an export cable and advanced settings are used, this field should be set to either alternative or both. If advanced settings are not used (use default values) or it is an inter-array cable, it should not be set. */
        exportCableUsage: z
          .object({
            offshore: z.boolean().optional(),
            onshore: z.boolean().optional(),
          })
          .passthrough()
          .optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const CableTypePost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        /** @Deprecated */
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().optional().default(66),
        reactance: z.number().nullable().optional(),
        capacitance: z.number().nullable().optional(),
        resistance: z.number().optional(),
        ampacity: z.number().nullable().optional(),
        useAdvancedSettings: z.boolean().optional().default(false),
        lambda1: z.number().optional(),
        lambda2: z.number().optional(),
        dielectricLossFactor: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
        /** If the cable is an export cable and advanced settings are used, this field should be set to either alternative or both. If advanced settings are not used (use default values) or it is an inter-array cable, it should not be set. */
        exportCableUsage: z
          .object({
            offshore: z.boolean().optional(),
            onshore: z.boolean().optional(),
          })
          .passthrough()
          .optional(),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const CableTypeIdentifier = z
      .object({
        id: z.string(),
        name: z.string(),
        voltage: z.number(),
        exportCableType: z.string().optional(),
      })
      .passthrough();
    export const CableTypeOrgPost = z
      .object({
        cable: CableTypePost,
        projectAccess: z.array(z.string()).optional(),
      })
      .passthrough();
    export const CableTypeUpdate = PartialCableType;
    export const CableTypeVersion = z
      .object({
        author: z.string().optional(),
        createdAt: z.number(),
        version: z.number(),
        cable: CableType,
      })
      .passthrough();
    export const WakeAnalysisConfiguration = z
      .object({
        wakeModel: z.union([z.literal("jensen"), z.literal("turbopark")]),
        wakeExpansionFactor: z.number(),
        turbulenceIntensity: z.number(),
        turboparkWakeExpansionCalibration: z.number(),
        blockage: z.boolean(),
        neighbourWakeMaxDistance: z.number().optional().default(20),
        neighbourWake: z.boolean().optional().default(true),
        density: z.union([z.number(), z.string()]),
        precision: z
          .union([z.literal("high"), z.literal("default"), z.literal("fast")])
          .optional()
          .default("default"),
      })
      .passthrough();
    export const ElectricalConfig = z
      .object({
        interArrayCableLoss: z.boolean().optional().default(false),
        turbineTrafoLoss: z.boolean().optional().default(false),
        exportSystemLoss: z.boolean().optional().default(false),
        cableLengthContingencyEnabled: z.boolean().optional().default(false),
        cableLengthContingency: z.number().optional().default(0),
      })
      .passthrough();
    export const EnergyLoss = z
      .object({
        id: z.string(),
        name: z.string(),
        value: z.number(),
        freeText: z.string().optional(),
      })
      .passthrough();
    export const Configuration = z
      .object({
        id: z.string(),
        name: z.string().optional(),
        description: z.string().optional(),
        wakeAnalysis: WakeAnalysisConfiguration,
        electrical: z
          .object({
            interArrayCableLoss: z.boolean().optional().default(false),
            turbineTrafoLoss: z.boolean().optional().default(false),
            exportSystemLoss: z.boolean().optional().default(false),
            cableLengthContingencyEnabled: z
              .boolean()
              .optional()
              .default(false),
            cableLengthContingency: z.number().optional().default(0),
          })
          .passthrough()
          .default({
            interArrayCableLoss: false,
            turbineTrafoLoss: false,
            exportSystemLoss: false,
          }),
        showLossesFreeText: z.boolean().optional(),
        energyLosses: z.array(EnergyLoss),
      })
      .passthrough();
    export const ConfigurationNew = z
      .object({
        name: z.string().optional(),
        description: z.string().optional(),
        wakeAnalysis: WakeAnalysisConfiguration.optional(),
        electrical: ElectricalConfig.optional(),
        energyLosses: z.array(EnergyLoss).optional(),
      })
      .passthrough();
    export const ConfigurationNewForOrg = z
      .object({
        configuration: ConfigurationNew.optional(),
        projectAccess: z.array(z.string()).optional(),
      })
      .passthrough();
    export const SubstationTypeOrgPost = z
      .object({
        substation: SubstationTypePost,
        projectAccess: z.array(z.string()).optional(),
      })
      .passthrough();
    export const InstallationVesselTypePost = z
      .object({
        id: z.string().optional(),
        type: z.literal("installation"),
        name: z.string().optional(),
        /** Time in days */
        mobilizationTime: z.number().optional().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().optional().default(12),
        /** If floating installation or not */
        floating: z.boolean().optional().default(false),
        /** hours */
        jackingTime: z.number().optional().default(3),
        /** Units */
        turbineCapacity: z.number().optional().default(4),
        /** Units */
        monopileCapacity: z.number().optional().default(6),
      })
      .passthrough();
    export const InstallationVesselType = z
      .object({
        id: z.string(),
        type: z.literal("installation"),
        name: z.string().optional(),
        /** Time in days */
        mobilizationTime: z.number().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().default(12),
        /** If floating installation or not */
        floating: z.boolean().default(false),
        /** hours */
        jackingTime: z.number().optional().default(3),
        /** Units */
        turbineCapacity: z.number().default(4),
        /** Units */
        monopileCapacity: z.number().default(6),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const FeederBargeVesselTypePost = z
      .object({
        id: z.string().optional(),
        type: z.literal("feederBarge"),
        name: z.string().optional().default("Feeder Barge"),
        /** Time in days */
        mobilizationTime: z.number().optional().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().optional().default(8),
        /** Number of jackets */
        maxJacketCapacity: z.number().optional().default(3),
      })
      .passthrough();
    export const FeederBargeVesselType = z
      .object({
        id: z.string(),
        type: z.literal("feederBarge"),
        name: z.string().optional().default("Feeder Barge"),
        /** Time in days */
        mobilizationTime: z.number().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().default(8),
        /** Number of jackets */
        maxJacketCapacity: z.number().default(3),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const AnchorHandlingVesselTypePost = z
      .object({
        id: z.string().optional(),
        type: z.literal("anchorHandling"),
        name: z.string().optional(),
        /** Time in days */
        mobilizationTime: z.number().optional().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().optional().default(15),
        /** Kilometers per hour */
        towingSpeed: z.number().optional().default(6),
        /** Number of units */
        anchorCapacity: z.number().optional().default(10),
      })
      .passthrough();
    export const AnchorHandlingVesselType = z
      .object({
        id: z.string(),
        type: z.literal("anchorHandling"),
        name: z.string().optional(),
        /** Time in days */
        mobilizationTime: z.number().default(7),
        /** Kilometers per hour */
        transitSpeed: z.number().default(15),
        /** Kilometers per hour */
        towingSpeed: z.number().default(6),
        /** Number of units */
        anchorCapacity: z.number().default(10),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const CableLayVesselTypePost = z
      .object({
        id: z.string().optional(),
        type: z.literal("cableLay"),
        name: z.string().optional().default("Cable Lay"),
        /** Time in days */
        mobilizationTime: z.number().optional().default(7),
        /** Kilometers */
        arrayCableCapacity: z.number().optional().default(40),
        /** Kilometers */
        exportCableCapacity: z.number().optional().default(20),
        /** Kilometers per hour */
        cableLayBurySpeed: z.number().optional().default(0.2),
        /** Kilometers per hour */
        cableLoadingSpeed: z.number().optional().default(0.6),
        /** Kilometers per hour */
        transitSpeed: z.number().optional().default(10),
      })
      .passthrough();
    export const CableLayVesselType = z
      .object({
        id: z.string(),
        type: z.literal("cableLay"),
        name: z.string().optional().default("Cable Lay"),
        /** Time in days */
        mobilizationTime: z.number().default(7),
        /** Kilometers */
        arrayCableCapacity: z.number().default(40),
        /** Kilometers */
        exportCableCapacity: z.number().default(20),
        /** Kilometers per hour */
        cableLayBurySpeed: z.number().default(0.2),
        /** Kilometers per hour */
        cableLoadingSpeed: z.number().default(0.6),
        /** Kilometers per hour */
        transitSpeed: z.number().default(10),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const ScourProtectionVesselTypePost = z
      .object({
        id: z.string().optional(),
        type: z.literal("scourProtection"),
        name: z.string().optional().default("Scour Protection"),
        /** Time in days */
        mobilizationTime: z.number().optional().default(7),
        /** Number of turbines */
        scourProtectionCapacity: z.number().optional().default(10),
        /** Kilometers per hour */
        transitSpeed: z.number().optional().default(15),
        /** Hours */
        rockLoadingTime: z.number().optional().default(12),
        /** Hours */
        rockDumpingTime: z.number().optional().default(2),
      })
      .passthrough();
    export const ScourProtectionVesselType = z
      .object({
        id: z.string(),
        type: z.literal("scourProtection"),
        name: z.string().optional().default("Scour Protection"),
        /** Time in days */
        mobilizationTime: z.number().default(7),
        /** Number of turbines */
        scourProtectionCapacity: z.number().default(10),
        /** Kilometers per hour */
        transitSpeed: z.number().default(15),
        /** Hours */
        rockLoadingTime: z.number().default(12),
        /** Hours */
        rockDumpingTime: z.number().default(2),
      })
      .passthrough()
      .and(z.object({}).passthrough());
    export const VesselType = z.union([
      InstallationVesselType,
      FeederBargeVesselType,
      AnchorHandlingVesselType,
      CableLayVesselType,
      ScourProtectionVesselType,
    ]);
    export const VesselTypePost = z.union([
      InstallationVesselTypePost,
      FeederBargeVesselTypePost,
      AnchorHandlingVesselTypePost,
      CableLayVesselTypePost,
      ScourProtectionVesselTypePost,
    ]);
    export const DayRate = z
      .object({
        cost: z.number(),
        unit: z.string(),
        confidenceLevel: z
          .union([
            z.literal("notSpecified"),
            z.literal("uncertain"),
            z.literal("verified"),
          ])
          .optional(),
      })
      .strict();
    export const VesselConfig = z
      .object({
        vesselId: z.string(),
        dayRate: DayRate,
      })
      .strict();
    export const InstallationSeasonConfig = z
      .object({
        startMonth: z.number(),
        endMonth: z.number(),
      })
      .strict();
    export const WeatherLimitsConfig = z
      .object({
        maxHsDuringInstallation: z.number(),
        maxHsDuringTransit: z.number(),
        maxWindSpeedDuringInstallation: z.number(),
        maxWindSpeedDuringTransit: z.number(),
      })
      .strict();
    export const TurbineActivitiesConfig = z
      .object({
        loadTurbineOnVessel: z.number(),
        liftAndAttachTower: z.number(),
        liftAndAttachNacelle: z.number(),
        liftAndAttachBlade: z.number(),
      })
      .strict();
    export const CableActivitiesConfig = z
      .object({
        pullInCableToTurbine: z.number(),
        pullInCableToSubstation: z.number(),
      })
      .strict();
    export const FloaterActivitiesConfig = z
      .object({
        assembleTurbineOnFloater: z.number(),
        ballastToTowingDraft: z.number(),
        ballastToOperationalDraft: z.number(),
        recoverMooringLine: z.number(),
        connectAndTensionMooringLine: z.number(),
      })
      .strict();
    export const MonopileActivitiesConfig = z
      .object({
        loadMonopileOnVessel: z.number(),
        upendAndPositionMonopile: z.number(),
        driveMonopile: z.number(),
        loadTPOnVessel: z.number(),
        liftAndBoltTP: z.number(),
      })
      .strict();
    export const JacketActivitiesConfig = z
      .object({
        loadJacketOnVessel: z.number(),
        layPinTemplate: z.number(),
        positionPile: z.number(),
        drivePile: z.number(),
        liftAndLowerJacket: z.number(),
        groutJacket: z.number(),
      })
      .strict();
    export const MooringActivitiesConfig = z
      .object({
        loadAnchorAndLineOnVessel: z.number(),
        ROVSurvey: z.number(),
        installAnchor: z.number(),
        installMooringLine: z.number(),
      })
      .strict();
    export const ExportCableActivitiesConfig = z
      .object({
        pullInExportCableAtLandfall: z.number(),
        pullInExportCableToSubstation: z.number(),
        joinExportCables: z.number(),
      })
      .strict();
    export const SubstationActivitiesConfig = z
      .object({
        loadJacketOnVessel: z.number(),
        layPinTemplate: z.number(),
        positionPile: z.number(),
        drivePile: z.number(),
        liftAndLowerJacket: z.number(),
        groutJacket: z.number(),
        loadTopsideOnVessel: z.number(),
        liftAndAttachTopside: z.number(),
      })
      .strict();
    export const FoundationVesselsConfig = z
      .object({
        floaters: z
          .object({
            installationVessel: VesselConfig,
            towingVessel: VesselConfig,
            portCrane: VesselConfig,
            activities: FloaterActivitiesConfig,
          })
          .strict(),
        monopiles: z
          .object({
            installationVessel: VesselConfig,
            activities: MonopileActivitiesConfig,
          })
          .strict(),
        jackets: z
          .object({
            installationVessel: VesselConfig,
            feederVessel: VesselConfig,
            activities: JacketActivitiesConfig,
          })
          .strict(),
        scourProtection: z
          .object({
            installationVessel: VesselConfig,
          })
          .strict(),
        installationSeason: InstallationSeasonConfig,
        weatherLimits: WeatherLimitsConfig,
      })
      .strict();
    export const TransportAndInstallationConfig = z
      .object({
        turbines: z
          .object({
            installationVessel: VesselConfig,
            installationSeason: InstallationSeasonConfig,
            weatherLimits: WeatherLimitsConfig,
            activities: TurbineActivitiesConfig,
          })
          .strict(),
        cables: z
          .object({
            installationVessel: VesselConfig,
            installationSeason: InstallationSeasonConfig,
            weatherLimits: WeatherLimitsConfig,
            activities: CableActivitiesConfig,
          })
          .strict(),
        mooring: z
          .object({
            installationVessel: VesselConfig,
            installationSeason: InstallationSeasonConfig,
            weatherLimits: WeatherLimitsConfig,
            activities: MooringActivitiesConfig,
          })
          .strict(),
        foundations: FoundationVesselsConfig,
        substation: z
          .object({
            installationVessel: VesselConfig,
            feederVessel: VesselConfig,
            installationSeason: InstallationSeasonConfig,
            weatherLimits: WeatherLimitsConfig,
            activities: SubstationActivitiesConfig,
          })
          .strict(),
        exportCable: z
          .object({
            installationVessel: VesselConfig,
            installationSeason: InstallationSeasonConfig,
            weatherLimits: WeatherLimitsConfig,
            activities: ExportCableActivitiesConfig,
          })
          .strict(),
      })
      .passthrough();
    export const OperationsConfigurationPost = z
      .object({
        id: z.string().optional(),
        name: z.string(),
        description: z.string().nullable().optional(),
        ti: TransportAndInstallationConfig,
      })
      .strict();
    export const OperationsConfiguration = z
      .object({
        id: z.string(),
        name: z.string(),
        description: z.string().nullable().optional(),
        ti: TransportAndInstallationConfig,
      })
      .strict();
    export const WindDataItem = z
      .object({
        hashKey: z.string(),
        rangeKey: z.string(),
        height: z.number().optional(),
        isArchived: z.boolean().optional(),
        lat: z.number().optional(),
        lon: z.number().optional(),
        name: z.string().optional(),
        s3Key: z.string().optional(),
        type: z.string().optional(),
      })
      .strict();
    export const ErrorResponse = z
      .object({
        error: z.string(),
      })
      .strict();
  }
}
export const components = {
  schemas: {
    Group: Components.Schemas.Group,
    PartialMooringLineType: Components.Schemas.PartialMooringLineType,
    MooringLineType: Components.Schemas.MooringLineType,
    MooringLineVersion: Components.Schemas.MooringLineVersion,
    PartialSubstationType: Components.Schemas.PartialSubstationType,
    SubstationIdentifier: Components.Schemas.SubstationIdentifier,
    SubstationTypePost: Components.Schemas.SubstationTypePost,
    SubstationType: Components.Schemas.SubstationType,
    SubstationVersion: Components.Schemas.SubstationVersion,
    PartialCableType: Components.Schemas.PartialCableType,
    CableType: Components.Schemas.CableType,
    CableTypePost: Components.Schemas.CableTypePost,
    CableTypeIdentifier: Components.Schemas.CableTypeIdentifier,
    CableTypeOrgPost: Components.Schemas.CableTypeOrgPost,
    CableTypeUpdate: Components.Schemas.CableTypeUpdate,
    CableTypeVersion: Components.Schemas.CableTypeVersion,
    WakeAnalysisConfiguration: Components.Schemas.WakeAnalysisConfiguration,
    ElectricalConfig: Components.Schemas.ElectricalConfig,
    EnergyLoss: Components.Schemas.EnergyLoss,
    Configuration: Components.Schemas.Configuration,
    ConfigurationNew: Components.Schemas.ConfigurationNew,
    ConfigurationNewForOrg: Components.Schemas.ConfigurationNewForOrg,
    SubstationTypeOrgPost: Components.Schemas.SubstationTypeOrgPost,
    InstallationVesselTypePost: Components.Schemas.InstallationVesselTypePost,
    InstallationVesselType: Components.Schemas.InstallationVesselType,
    FeederBargeVesselTypePost: Components.Schemas.FeederBargeVesselTypePost,
    FeederBargeVesselType: Components.Schemas.FeederBargeVesselType,
    AnchorHandlingVesselTypePost:
      Components.Schemas.AnchorHandlingVesselTypePost,
    AnchorHandlingVesselType: Components.Schemas.AnchorHandlingVesselType,
    CableLayVesselTypePost: Components.Schemas.CableLayVesselTypePost,
    CableLayVesselType: Components.Schemas.CableLayVesselType,
    ScourProtectionVesselTypePost:
      Components.Schemas.ScourProtectionVesselTypePost,
    ScourProtectionVesselType: Components.Schemas.ScourProtectionVesselType,
    VesselType: Components.Schemas.VesselType,
    VesselTypePost: Components.Schemas.VesselTypePost,
    DayRate: Components.Schemas.DayRate,
    VesselConfig: Components.Schemas.VesselConfig,
    InstallationSeasonConfig: Components.Schemas.InstallationSeasonConfig,
    WeatherLimitsConfig: Components.Schemas.WeatherLimitsConfig,
    TurbineActivitiesConfig: Components.Schemas.TurbineActivitiesConfig,
    CableActivitiesConfig: Components.Schemas.CableActivitiesConfig,
    FloaterActivitiesConfig: Components.Schemas.FloaterActivitiesConfig,
    MonopileActivitiesConfig: Components.Schemas.MonopileActivitiesConfig,
    JacketActivitiesConfig: Components.Schemas.JacketActivitiesConfig,
    MooringActivitiesConfig: Components.Schemas.MooringActivitiesConfig,
    ExportCableActivitiesConfig: Components.Schemas.ExportCableActivitiesConfig,
    SubstationActivitiesConfig: Components.Schemas.SubstationActivitiesConfig,
    FoundationVesselsConfig: Components.Schemas.FoundationVesselsConfig,
    TransportAndInstallationConfig:
      Components.Schemas.TransportAndInstallationConfig,
    OperationsConfigurationPost: Components.Schemas.OperationsConfigurationPost,
    OperationsConfiguration: Components.Schemas.OperationsConfiguration,
    WindDataItem: Components.Schemas.WindDataItem,
    ErrorResponse: Components.Schemas.ErrorResponse,
  },
};

export const paths = {
  "/api/mooring/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of mooring lines */
          "application/json": z.array(Components.Schemas.MooringLineType),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Mooring line to create */
      requestBody: {
        "application/json": Components.Schemas.PartialMooringLineType,
      },
      responses: {
        200: {
          /** Newly created mooring line */
          "application/json": Components.Schemas.MooringLineType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/mooring/node/{nodeId}/type/{mooringLineId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        mooringLineId: z.string(),
      }),
      /** Mooring line to update */
      requestBody: {
        "application/json": Components.Schemas.PartialMooringLineType,
      },
      responses: {
        200: {
          /** List of mooring lines */
          "application/json": Components.Schemas.MooringLineType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        mooringLineId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Mooring line not found */
          "application/json": z.string(),
        },
        409: {
          /** Mooring line already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of substations */
          "application/json": z.array(Components.Schemas.SubstationType),
        },
        400: {
          /** Bad request */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Substation to create */
      requestBody: {
        "application/json": Components.Schemas.PartialSubstationType,
      },
      responses: {
        200: {
          /** Newly created substation */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/node/{nodeId}/type/{substationId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        substationId: z.string(),
      }),
      /** Substation to update */
      requestBody: {
        "application/json": Components.Schemas.PartialSubstationType,
      },
      responses: {
        200: {
          /** List of substations */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Substation not found */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        substationId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Substation not found */
          "application/json": z.string(),
        },
        409: {
          /** Substation already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of organisation substations */
          "application/json": z.array(Components.Schemas.SubstationType),
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal organisation Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Substation to create */
      requestBody: {
        "application/json": Components.Schemas.SubstationTypeOrgPost,
      },
      responses: {
        200: {
          /** Created substation */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal organisation Id */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/organisation/{organisationId}/type/{substationId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        substationId: z.string(),
      }),
      /** Substation to update */
      requestBody: {
        "application/json": Components.Schemas.PartialSubstationType,
      },
      responses: {
        200: {
          /** Updated organisation substation */
          "application/json": Components.Schemas.SubstationType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Substation not found. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        substationId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Substation not found */
          "application/json": z.string(),
        },
        409: {
          /** Substation already archived */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/substation/organisation/{organisationId}/identifiers": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Successful response */
          "application/json": z.array(Components.Schemas.SubstationIdentifier),
        },
        400: {
          /** Bad request */
          "application/json": z.string(),
        },
        403: z.never(),
        500: z.never(),
      },
    },
  },
  "/api/vessel/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of vessels */
          "application/json": z.array(Components.Schemas.VesselType),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Vessel to create */
      requestBody: {
        "application/json": Components.Schemas.VesselTypePost,
      },
      responses: {
        200: {
          /** Newly created vessel */
          "application/json": Components.Schemas.VesselType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/vessel/node/{nodeId}/type/{vesselId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        vesselId: z.string(),
      }),
      /** Vessel to update */
      requestBody: {
        "application/json": Components.Schemas.VesselTypePost,
      },
      responses: {
        200: {
          /** List of vessels */
          "application/json": Components.Schemas.VesselType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        vesselId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Vessel not found */
          "application/json": z.string(),
        },
        409: {
          /** Vessel already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-createDefaultOpsConfigInvoke": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            author: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** New operation configuration */
          "application/json": Components.Schemas.OperationsConfiguration,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/api/operations/node/{nodeId}/config/default": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Newly created operation configuraton */
          "application/json": Components.Schemas.OperationsConfiguration,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/operations/node/{nodeId}/config": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of operation configurations */
          "application/json": z.array(
            Components.Schemas.OperationsConfiguration,
          ),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Operation configuration to create */
      requestBody: {
        "application/json": Components.Schemas.OperationsConfigurationPost,
      },
      responses: {
        200: {
          /** Newly created operation configuraton */
          "application/json": Components.Schemas.OperationsConfiguration,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
        404: {
          /** Illegal node Id */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/operations/node/{nodeId}/config/{configId}": {
    put: {
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      /** Operation configuration to update */
      requestBody: {
        "application/json": Components.Schemas.OperationsConfiguration,
      },
      responses: {
        200: {
          /** Updated operation configuration */
          "application/json": Components.Schemas.OperationsConfiguration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Operation configuration not found */
          "application/json": z.string(),
        },
        409: {
          /** Operation configuration already archived */
          "application/json": z.string(),
        },
        500: z.never(),
      },
    },
  },
  "/api/cable/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of cables */
          "application/json": z.array(Components.Schemas.CableType),
        },
        400: {
          /** Missing userId in headers */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Cable to create */
      requestBody: {
        "application/json": Components.Schemas.CableTypePost,
      },
      responses: {
        200: {
          /** Created cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of org inter array cables */
          "application/json": z.array(Components.Schemas.CableType),
        },
        400: {
          /** Missing userId in headers */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Cable to create */
      requestBody: {
        "application/json": Components.Schemas.CableTypeOrgPost,
      },
      responses: {
        200: {
          /** Created cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/exportCable/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of org export cables */
          "application/json": z.array(Components.Schemas.CableType),
        },
        400: {
          /** Missing userId in headers */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Export caable to create */
      requestBody: {
        "application/json": Components.Schemas.CableTypeOrgPost,
      },
      responses: {
        200: {
          /** Created export cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Missing user ID */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/node/{nodeId}/type/{cableId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        cableId: z.string(),
      }),
      /** Cable to update */
      requestBody: {
        "application/json": Components.Schemas.CableTypeUpdate,
      },
      responses: {
        200: {
          /** List of cables */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Cable not found */
          "application/json": z.string(),
        },
        409: {
          /** Cable already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/organisation/{organisationId}/type/{cableId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      /** Organisation cable to update */
      requestBody: {
        "application/json": Components.Schemas.CableTypeUpdate,
      },
      responses: {
        200: {
          /** Updated org cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Cable not found. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Cable not found */
          "application/json": z.string(),
        },
        409: {
          /** Cable already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/exportCable/organisation/{organisationId}/type/{cableId}": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      /** Organisation export cable to update */
      requestBody: {
        "application/json": Components.Schemas.CableTypeUpdate,
      },
      responses: {
        200: {
          /** Updated org export cable */
          "application/json": Components.Schemas.CableType,
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Export cable not found. */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        cableId: z.string(),
      }),
      responses: {
        200: {
          /** Success string */
          "application/json": z.string(),
        },
        400: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
        404: {
          /** Export cable not found */
          "application/json": z.string(),
        },
        409: {
          /** Export cable already archived */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cable/organisation/{organisationId}/identifiers": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Successfully listed all library cable identifiers */
          "application/json": z.array(Components.Schemas.CableTypeIdentifier),
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** List of configs */
          "application/json": z.array(Components.Schemas.Configuration),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** List of configs */
          "application/json": z.array(Components.Schemas.Configuration),
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config/new": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Project config to create */
      requestBody: {
        "application/json": Components.Schemas.ConfigurationNew,
      },
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config/new": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** Library config to create */
      requestBody: {
        "application/json": Components.Schemas.ConfigurationNewForOrg,
      },
      responses: {
        200: {
          /** New config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/node/{nodeId}/config/{configId}": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      /** Library config update */
      requestBody: {
        "application/json": Components.Schemas.Configuration,
      },
      responses: {
        200: {
          /** Updated config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        configId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/configuration/organisation/{organisationId}/config/{configId}": {
    post: {
      searchParams: z.object({
        project_type: z
          .union([z.literal("onshore"), z.literal("offshore")])
          .optional(),
      }),
      pathParams: z.object({
        organisationId: z.string(),
        configId: z.string(),
      }),
      /** Library config update */
      requestBody: {
        "application/json": Components.Schemas.Configuration,
      },
      responses: {
        200: {
          /** Updated config */
          "application/json": Components.Schemas.Configuration,
        },
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        configId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Something went wrong. */
          "application/json": z
            .object({
              message: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Something went wrong. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getWindDataInvoke": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            sourceId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Wind data item */
          "application/json": Components.Schemas.WindDataItem,
        },
        404: {
          /** Item not found */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getCableInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            cableId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Cable item */
          "application/json": Components.Schemas.CableType,
        },
        404: {
          /** Item not found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-listCablesInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Cable items */
          "application/json": z.array(Components.Schemas.CableType),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getSubstationInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            substationId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Substation item */
          "application/json": Components.Schemas.SubstationType,
        },
        404: {
          /** Item not found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-listSubstationsInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Substation items */
          "application/json": z.array(Components.Schemas.SubstationType),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getConfigInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            configId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Config item */
          "application/json": Components.Schemas.Configuration,
        },
        404: {
          /** Item not found */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-listOrgConfigsInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            organisationId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Config items */
          "application/json": z.array(Components.Schemas.Configuration),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-initializeConfigInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            author: z.string(),
            project_type: z
              .union([z.literal("onshore"), z.literal("offshore")])
              .optional(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Created config item */
          "application/json": Components.Schemas.Configuration,
        },
        412: {
          /** Config already exist */
          "application/json": z.string(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getCableCountInternal": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            orgId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Number of non-archived cables */
          "application/json": z.number(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getExportCableCountInternal": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            orgId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Number of non-archived export cables */
          "application/json": z.number(),
        },
      },
    },
  },
  "/invoke/configuration-api-prod-getConfigCountInternal": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            orgId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Number of non-archived configs */
          "application/json": z.number(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
