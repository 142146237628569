import { Column } from "components/General/Layout";
import styled from "styled-components";
import { spaceLarge } from "styles/space";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const ElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-bottom: 8rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  padding: 2rem 3.2rem;
  align-items: center;
  background-color: ${colors.surfaceSubtle};
  border-radius: 0.8rem;
  border: 1px solid ${colors.borderSubtle};
  height: 8rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.2rem 2.4rem;
`;

export const HeaderName = styled.h3`
  margin: 0;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 60rem;
`;

export const SubHeader = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  flex: 1;
`;

export const DetailSecondary = styled.div`
  ${typography.caption}
  color: ${colors.textDisabled};
`;

export const Detail = styled.div`
  ${typography.sub2}
  color: ${colors.textPrimary};
`;

export const SettingsColumn = styled(Column)`
  ${Row} {
    & > label {
      flex: 1;
    }
  }
  gap: ${spaceLarge};
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;
