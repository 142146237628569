import { Suspense, useCallback, useMemo, useState } from "react";
import { modalTypeOpenAtom } from "../../../state/modal";
import FullScreenModal from "../../FullScreenModal/FullScreenModal";
import Button from "../../General/Button";
import { Column, ModalFrame, Row } from "../../General/Layout";
import { useToast } from "../../../hooks/useToast";
import { currentOrganisationSelector } from "../state";
import { Label } from "../../General/Form";
import OrgImageUpload from "../Image/OrgImageUpload";
import { Input } from "../../General/Input";
import { useEditOrganisation } from "../hooks/useEditOrganisation";
import { adminInOrganisationSelectorFamily } from "state/user";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useOrgMemberCrud } from "../Members/useOrgMemberCrud";
import { TagsSettings } from "./TagsSettings";
import { StagesSettings } from "./StagesSettings";
import styled from "styled-components";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import { useStageCrud } from "hooks/useStageCrud";
import { useTagCrud } from "hooks/useTagCrud";
import { Tag } from "services/tagsService";
import { Stage } from "services/stagesService";
import { tagsFamily } from "state/jotai/tags";
import { stagesFamily } from "state/jotai/stages";
import SettingsIcon from "@icons/24/Settings.svg?react";
import TagsIcon from "@icons/24/Tags.svg?react";
import StagesIcon from "@icons/24/Stages.svg?react";
import { Mixpanel, TrackingFeature, TrackingCapability } from "mixpanel";
import { SkeletonText } from "components/Loading/Skeleton";
import { Organisation } from "../service";
import { activeOrgSettingsTabAtom } from "./ActiveTabState";

export const OrgSettingsModalType = "OrgSettingsModal";

const SideMenu = styled(Column)`
  width: 20rem;
  border-right: 1px solid ${colors.borderSubtle};
  padding: 2.4rem 0;
  gap: 0.4rem;
`;

const MenuItem = styled.div<{ isActive: boolean }>`
  padding: 0 1.4rem;
  border-radius: 0.4rem;
  margin-right: 1.6rem;
  min-height: 3.2rem;
  align-content: center;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? colors.surfaceSelectedLight : "transparent"};

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? colors.surfaceSelectedLight : colors.surfaceHover};
  }
  & p {
    ${({ isActive }) => (isActive ? typography.sub1 : typography.body)};

    color: ${({ isActive }) =>
      isActive ? colors.textSelected : colors.textBrand};
  }
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 2.4rem;
`;

const ModalContent = styled.div`
  display: flex;
  height: 100%;
`;

const Border = styled.div`
  border: 1px solid ${colors.borderSubtle};
  width: 100%;
`;

const OrgSettingsModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const currentOrganisation = useAtomValue(currentOrganisationSelector);

  if (modalTypeOpen?.modalType !== OrgSettingsModalType) return null;
  if (!currentOrganisation) return null;

  return (
    <Suspense
      fallback={
        <Column style={{ gap: "2.4rem" }}>
          <SkeletonText />
          <SkeletonText />
        </Column>
      }
    >
      <OrgSettingsModalInner currentOrganisation={currentOrganisation} />
    </Suspense>
  );
};

const OrgSettingsModalInner = ({
  currentOrganisation,
}: {
  currentOrganisation: Organisation;
}) => {
  const { leaveOrganisation } = useOrgMemberCrud(currentOrganisation.id);
  const editName = useEditOrganisation();
  const closeModal = useSetAtom(modalTypeOpenAtom);
  const [activeTab, setActiveTab] = useAtom(activeOrgSettingsTabAtom);

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId: currentOrganisation.id,
    }),
  );

  const [name, setName] = useState(currentOrganisation.name);

  const { success } = useToast();

  const tagCrud = useTagCrud(currentOrganisation.id);
  const stageCrud = useStageCrud(currentOrganisation.id);

  const tags = useAtomValue(
    tagsFamily({ organisationId: currentOrganisation.id }),
  );
  const stages = useAtomValue(
    stagesFamily({ organisationId: currentOrganisation.id }),
  );

  const [localTags, setLocalTags] = useState<Tag[]>(tags);
  const [localStages, setLocalStages] = useState<Stage[]>(stages);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(localTags) !== JSON.stringify(tags) ||
      JSON.stringify(localStages) !== JSON.stringify(stages) ||
      name !== currentOrganisation.name
    );
  }, [localTags, localStages, tags, stages, name, currentOrganisation.name]);

  const handleSave = useCallback(async () => {
    if (localTags !== tags) {
      await tagCrud.update(localTags);
    }
    if (localStages !== stages) {
      await stageCrud.update(localStages);
    }
    if (name !== currentOrganisation.name && name !== undefined) {
      await editName(currentOrganisation.id, name);
      success("Update complete");
    }
    closeModal(undefined);
  }, [
    localTags,
    localStages,
    tags,
    stages,
    name,
    currentOrganisation,
    editName,
    success,
    stageCrud,
    tagCrud,
    closeModal,
  ]);

  const handleCancel = () => {
    setLocalTags(tags);
    setLocalStages(stages);
    setName(currentOrganisation.name);
    closeModal(undefined);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "general":
        return (
          <Column style={{ gap: "2.4rem" }}>
            <OrgImageUpload size={9.2} />
            <Label style={{ width: "100%" }}>
              <p>Organisation name:</p>
              <Input
                value={name}
                disabled={!isAdminInOrg}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </Label>
            <Row style={{ marginLeft: "auto", width: "100%" }}>
              <Button
                style={{ width: "100%", maxWidth: "100%" }}
                buttonType="secondary"
                text="Leave organisation"
                onClick={leaveOrganisation}
              />
            </Row>
          </Column>
        );
      case "tags":
        return (
          <TagsSettings
            isAdmin={isAdminInOrg}
            tags={localTags}
            setTags={setLocalTags}
            organisationId={currentOrganisation.id}
          />
        );
      case "stages":
        return (
          <StagesSettings
            isAdmin={isAdminInOrg}
            stages={localStages}
            setStages={setLocalStages}
            organisationId={currentOrganisation.id}
          />
        );
    }
  };

  return (
    <FullScreenModal>
      <ModalFrame
        title="Organisation settings"
        style={{
          width: "60rem",
          maxWidth: "95%",
          maxHeight: "90vh",
          height: "40rem",
        }}
      >
        <ModalContent>
          <SideMenu>
            <MenuItem
              isActive={activeTab === "general"}
              onClick={() => setActiveTab("general")}
            >
              <Row style={{ gap: "0.8rem" }}>
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  iconColor={
                    activeTab === "general" ? colors.textSelected : undefined
                  }
                >
                  <SettingsIcon />
                </IconREMSize>
                <p>General</p>
              </Row>
            </MenuItem>
            <MenuItem
              isActive={activeTab === "tags"}
              onClick={() => {
                setActiveTab("tags");
                Mixpanel.track(
                  TrackingCapability.Organisation,
                  TrackingFeature.OrgSettings,
                  "discovered tags tab",
                  {},
                );
              }}
            >
              <Row style={{ gap: "0.8rem" }}>
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  iconColor={
                    activeTab === "tags" ? colors.textSelected : undefined
                  }
                >
                  <TagsIcon />
                </IconREMSize>
                <p>Tags</p>
              </Row>
            </MenuItem>
            <MenuItem
              isActive={activeTab === "stages"}
              onClick={() => {
                setActiveTab("stages");
                Mixpanel.track(
                  TrackingCapability.Organisation,
                  TrackingFeature.OrgSettings,
                  "discovered stages tab",
                  {},
                );
              }}
            >
              <Row style={{ gap: "0.8rem" }}>
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  iconColor={
                    activeTab === "stages" ? colors.textSelected : undefined
                  }
                >
                  <StagesIcon />
                </IconREMSize>
                <p>Stages</p>
              </Row>
            </MenuItem>
          </SideMenu>
          <ContentArea>{renderContent()}</ContentArea>
        </ModalContent>

        <Border />

        <Row style={{ marginLeft: "auto", marginTop: "1.4rem" }}>
          <Button buttonType="secondary" text="Cancel" onClick={handleCancel} />
          <Button
            text="Save"
            disabled={!hasChanges || !isAdminInOrg}
            onClick={handleSave}
          />
        </Row>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default OrgSettingsModal;
