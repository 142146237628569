import { projectIdAtom } from "state/pathParams";
import React, { Suspense, useEffect } from "react";
import { ContentContainer } from "../Shared/styles";
import SettingsSubMenu from "../Shared/SettingsSubMenu";
import { ProjectConfigModalTypeV2 } from "../../../state/configuration";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../state/modal";
import useProjectConfiguration from "./Data/useProjectConfiguration";
import { selectedMenuItemState, unsavedSettingsState } from "../Shared/state";
import { editorAccessProjectSelector } from "state/user";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const ProjectConfigurationModalInner = () => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  const projectConfiguration = useProjectConfiguration();
  const { title, items } = projectConfiguration;
  const unsavedSettings = useAtomValue(unsavedSettingsState);
  const { showConfirm } = useConfirm();

  return (
    <>
      <TopBarModalHeader
        title={title}
        onClose={async () => {
          if (
            !unsavedSettings ||
            (await showConfirm({
              title: "Unsaved settings",
              message: "Are you sure you want to quit without saving?",
            }))
          ) {
            setModalTypeOpen(undefined);
          }
        }}
      />
      <ContentContainer
        style={{
          padding: 0,
        }}
      >
        <SettingsSubMenu
          parentId={ProjectConfigModalTypeV2}
          submenus={items}
          depth={0}
        />
      </ContentContainer>
    </>
  );
};
const ProjectConfigurationModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom);

  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const noAccess = !editorAccessProject;

  const [selectedItemId, setSelectedItemId] = useAtom(
    selectedMenuItemState({
      menuId: ProjectConfigModalTypeV2,
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === ProjectConfigModalTypeV2) {
      const defaultSelection = modalTypeOpen.metadata?.selectedMenuId;
      if (defaultSelection) {
        setModalTypeOpen((curr) => {
          if (curr?.modalType === ProjectConfigModalTypeV2) {
            return {
              ...curr,
              metadata: {
                ...curr.metadata,
                selectedMenuId: undefined,
              },
            };
          }
          return curr;
        });
        setSelectedItemId(defaultSelection);
      } else if (!selectedItemId) {
        setSelectedItemId("analysis");
      }
    }
  }, [modalTypeOpen, selectedItemId, setModalTypeOpen, setSelectedItemId]);

  if (
    noAccess ||
    projectId == null ||
    modalTypeOpen?.modalType !== ProjectConfigModalTypeV2
  )
    return null;

  return (
    <TopBarModal
      disableClose={midScreenModalTypeOpen?.modalType === ComponentsPreviewType}
    >
      <Suspense
        fallback={
          <TopBarModalHeader
            title="Loading content"
            onClose={() => setModalTypeOpen(undefined)}
          />
        }
      >
        <ProjectConfigurationModalInner />
      </Suspense>
    </TopBarModal>
  );
};

export default ProjectConfigurationModal;
