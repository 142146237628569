import { useCallback, useEffect, useMemo } from "react";
import * as THREE from "three";
import {
  MathUtils,
  Shader,
  Group,
  MeshStandardMaterial,
  Mesh,
  BufferGeometry,
} from "three";
import {
  turbineScalingAtom,
  viewParkRotationAtom,
  viewTurbineCoordsAtom,
  viewTurbineDiameterAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
  viewViewModeAtom,
  VIEW_MODE,
} from "../../../../state/viewToPark";
import { disposeObject } from "../utils";
import { DIVISION_FACTOR } from "../constants";
import {
  createBlinkingLight,
  createLowIntensityLight,
  injectCurvatureIntoVertexShader,
  sampleTerrainMeshHeight,
} from "./utils";
import { range } from "utils/utils";
import { terrainPatchesAddedToSceneRefresherAtom } from "./useDynamicTerrain";
import { atom, useAtomValue } from "jotai";
import { ThreeCoreBasic } from "components/ViewAnalyses/common";
import { fetchTurbineModel } from "3d/turbine_glb";
import {
  defaultBladeMaterial,
  defaultNacelleMaterial,
  defaultTowerMaterial,
} from "3d/materials";
import {
  DIST_BLADE_OFFSET_FROM_ROTOR,
  DIST_ROTOR_X,
  DIST_ROTOR_Z,
  nacelleTransform,
  towerTransform,
} from "3d/turbine_glb";
import { deg2rad } from "utils/geometry";

const meshGroupAtom = atom(async () => {
  const gltf = await fetchTurbineModel();
  const group = new THREE.Group();
  group.name = "turbine.glb group";

  gltf.scene.traverse((object) => {
    if (object instanceof THREE.Mesh) {
      switch (object.name) {
        case "blade":
          {
            const bladeGroup = new Group();
            bladeGroup.name = "bladegroup";
            for (let k = 0; k < 3; k++) {
              const o = object.clone();
              o.material = defaultBladeMaterial();
              o.rotation.set(deg2rad(120 * k), deg2rad(-35), 0);
              o.scale.set(1, 1, 1);
              o.translateY(DIST_BLADE_OFFSET_FROM_ROTOR);
              o.name = `blade-${k}`;
              o.material.onBeforeCompile = (shader: Shader) => {
                shader.vertexShader = injectCurvatureIntoVertexShader(
                  shader.vertexShader,
                );
                shader.fragmentShader = `void main() {
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
              }`;
              };

              bladeGroup.add(o);
            }
            bladeGroup.position.set(-DIST_ROTOR_X, 0, DIST_ROTOR_Z + 100);
            group.add(bladeGroup);
          }
          break;
        case "tower":
          {
            const obj = object.clone();
            obj.material = defaultTowerMaterial();
            obj.matrixAutoUpdate = false;
            obj.matrix = towerTransform({});
            obj.name = "tower";
            obj.material.onBeforeCompile = (shader: Shader) => {
              shader.vertexShader = injectCurvatureIntoVertexShader(
                shader.vertexShader,
              );
              shader.fragmentShader = `void main() {
                gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
              }`;
            };
            group.add(obj);
          }
          break;
        case "nacelle":
          {
            const obj = object.clone();
            obj.material = defaultNacelleMaterial();
            obj.matrixAutoUpdate = false;
            obj.matrix = nacelleTransform({});
            obj.name = "nacelle";
            group.add(obj);
          }
          break;
      }
    }
  });
  return group;
});

export default function useTurbines(
  threeCore: ThreeCoreBasic | undefined,
  terrainPatches: Mesh<BufferGeometry, MeshStandardMaterial>[],
) {
  const turbineCoords = useAtomValue(viewTurbineCoordsAtom);
  const turbineHeights = useAtomValue(viewTurbineHeightsAtom);
  const turbineDiameters = useAtomValue(viewTurbineDiameterAtom);
  const viewMode = useAtomValue(viewViewModeAtom);
  const turbineScaling = useAtomValue(turbineScalingAtom);
  const parkRotation = useAtomValue(viewParkRotationAtom);
  const turbineLights = useAtomValue(viewTurbineLightsAtom);
  const terrainPatchesAddedToSceneRefresher = useAtomValue(
    terrainPatchesAddedToSceneRefresherAtom,
  );

  const meshGroup = useAtomValue(meshGroupAtom);

  const getHeight = useCallback(
    (coords: number[]) => {
      const _ = terrainPatchesAddedToSceneRefresher;
      let height = 0;
      for (const patch of terrainPatches) {
        const inside = patch.geometry.boundingBox?.containsPoint(
          new THREE.Vector3(
            coords[0],
            coords[1],
            (patch.geometry.boundingBox.max.z +
              patch.geometry.boundingBox.min.z) /
              2,
          ),
        );
        if (!inside) continue;
        const h = sampleTerrainMeshHeight([coords[0], coords[1]], patch) ?? 0;
        height = Math.max(height, h);
      }
      return height;
    },
    [terrainPatches, terrainPatchesAddedToSceneRefresher],
  );

  const turbineData = useMemo(() => {
    const n = Math.min(
      turbineCoords?.length ?? 0,
      turbineHeights.length,
      turbineDiameters.length,
    );
    return range(0, n).map((i) => {
      const [x, y] = turbineCoords?.[i] ?? [0, 0];
      const height = turbineHeights[i] * turbineScaling;
      const diameter = turbineDiameters[i] * turbineScaling;
      return { x, y, height, diameter };
    });
  }, [turbineCoords, turbineDiameters, turbineHeights, turbineScaling]);

  const turbineMeshes = useMemo(() => {
    meshGroup.traverse((obj) => {
      if (obj instanceof Mesh)
        obj.material.onBeforeCompile = (shader: Shader) => {
          shader.vertexShader = injectCurvatureIntoVertexShader(
            shader.vertexShader,
          );
        };
    });

    return turbineData.map(({ x, y, height, diameter }) => {
      const mg = meshGroup.clone();

      mg.position.set(-x, getHeight([x, y]) / DIVISION_FACTOR, y);
      mg.rotateX(deg2rad(-90));
      const ts = height / 100;
      const s = ts / DIVISION_FACTOR;
      mg.scale.set(s, s, s);

      const bs = diameter / 2 / 80 / ts;
      mg.getObjectByName("bladegroup")?.scale.set(bs, bs, bs);

      for (const m of [
        mg.getObjectByName("tower"),
        mg.getObjectByName("nacelle"),
        mg.getObjectByName("blade-0"),
        mg.getObjectByName("blade-1"),
        mg.getObjectByName("blade-2"),
      ]) {
        if (m instanceof Mesh) {
          m.material = new MeshStandardMaterial({
            color: 0xffffff,
            roughness: 0.2,
            metalness: 0.4,
            transparent: true,
          });
          m.material.onBeforeCompile = (shader: Shader) => {
            shader.vertexShader = injectCurvatureIntoVertexShader(
              shader.vertexShader,
            );
          };
        }
      }

      return mg;
    });
  }, [getHeight, meshGroup, turbineData]);

  useEffect(() => {
    for (const m of turbineMeshes) {
      threeCore?.scene.add(m);
    }
    return () => {
      for (const m of turbineMeshes) {
        threeCore?.scene.remove(m);
        disposeObject(m);
      }
    };
  }, [threeCore?.scene, turbineMeshes]);

  useEffect(() => {
    for (const mg of turbineMeshes) {
      for (const m of [
        mg.getObjectByName("tower"),
        mg.getObjectByName("nacelle"),
        mg.getObjectByName("blade-0"),
        mg.getObjectByName("blade-1"),
        mg.getObjectByName("blade-2"),
      ]) {
        if (m instanceof Mesh)
          m.material.wireframe = viewMode === VIEW_MODE.WIRE_FRAME_MODE;
      }
    }
  }, [turbineMeshes, viewMode]);

  const blinkingLights = useMemo(() => {
    const group = new Group();
    if (!turbineCoords || !turbineLights) return group;

    turbineCoords.forEach((c, i) => {
      const turbineHeight = turbineHeights[i] * turbineScaling;
      const sphere = createBlinkingLight(c, turbineHeight);
      group.add(sphere);
    });
    return group;
  }, [turbineCoords, turbineHeights, turbineLights, turbineScaling]);

  const lowIntensityLights = useMemo(() => {
    const group = new Group();
    if (!turbineCoords || !turbineLights) return group;

    turbineCoords.forEach((c, i) => {
      const turbineHeight = turbineHeights[i] * turbineScaling;
      if (turbineHeight < 150) return;
      const sphere = createLowIntensityLight(c, turbineHeight);
      group.add(sphere);
    });
    return group;
  }, [turbineCoords, turbineHeights, turbineLights, turbineScaling]);

  useEffect(() => {
    const angle = MathUtils.degToRad(parkRotation);
    for (const g of turbineMeshes) g.rotation.z = angle;
  }, [parkRotation, turbineMeshes]);

  useEffect(() => {
    if (!threeCore || !blinkingLights) return;
    const { scene } = threeCore;

    scene.add(blinkingLights);
    return () => {
      scene.remove(blinkingLights);
      disposeObject(blinkingLights);
    };
  }, [threeCore, blinkingLights]);

  useEffect(() => {
    if (!threeCore || !lowIntensityLights) return;
    const { scene } = threeCore;

    scene.add(lowIntensityLights);
    return () => {
      scene.remove(lowIntensityLights);
      disposeObject(lowIntensityLights);
    };
  }, [threeCore, lowIntensityLights]);

  return {
    windTurbineBlinkingLightGroup: blinkingLights,
    turbineMeshes,
  };
}
