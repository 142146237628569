import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { LayerCollection } from "../LayerSettings/types";
import { BackendLayerCollection, listLayerCollections } from "./service";
import {
  getLayersMap,
  layerSettingSelectorFamily,
} from "../LayerSettings/state";
import { expandLayerCollection2 } from "./utils";
import { isDefined } from "../../../utils/predicates";
import { Layer } from "../../../types/layers";
import { atomWithRefresh } from "jotai/utils";

export const allBackendLayerCollectionsAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomFromFn<Promise<BackendLayerCollection[]>>(async () => {
      if (!projectId) return [];
      return listLayerCollections(projectId);
    }),
);

export const getAllLayerCollections = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomWithRefresh<Promise<LayerCollection[]>>(async (get) => {
      const allBackendCollections = await get(
        allBackendLayerCollectionsAtomFamily({
          projectId,
        }),
      );
      const layerMap = await get(
        getLayersMap({
          projectId,
        }),
      );
      return allBackendCollections
        .map((b) => expandLayerCollection2(b, layerMap))
        .filter(isDefined);
    }),
);

export const getLayerCollection = atomFamily(
  ({ collectionId, projectId }: { projectId: string; collectionId: string }) =>
    atom<Promise<LayerCollection | undefined>>(async (get) => {
      const allCollections = await get(
        getAllLayerCollections({
          projectId,
        }),
      );
      return allCollections.find(
        (collection) => collection.id === collectionId,
      );
    }),
);

export const visibleCollectionLayersSelectorFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<Layer[]>>(async (get) => {
      const collections = await get(
        getAllLayerCollections({
          projectId,
        }),
      );
      const layers = collections.flatMap((l) => l.layers);
      const visibleLayers = (
        await Promise.all(
          layers.map(async (layer) => {
            const settings = await get(
              layerSettingSelectorFamily({
                projectId,
                layerId: layer.id,
              }),
            );
            if (settings.visible ?? false) return layer;
          }),
        )
      ).filter(isDefined);

      return visibleLayers;
    }),
);

export const isCreatingNewCollectionAtom = atom<boolean>(false);
