import styled from "styled-components";
import { Row } from "components/General/Layout";
import { spacing4, spacing6 } from "styles/space";
import { colors } from "styles/colors";
import { SkeletonBlock } from "components/Loading/Skeleton";
import React from "react";
import { IconREMSize } from "styles/typography";
import HourglassIcon from "@icons/14/Hourglass.svg";

export const TableCell = styled(Row)<{ selected?: boolean }>`
  padding: ${spacing6};
  white-space: nowrap;

  ${({ selected }) =>
    selected &&
    `&&&& {
    background-color: ${colors.selected};
    
    .blue-background {
      background-color: ${colors.blue200};
    }
    }`}
`;

export const LoadingUsersSkeletons = ({
  nrColumns,
  nrRows,
}: {
  nrColumns: number;
  nrRows: number;
}) => {
  return Array(nrColumns * nrRows)
    .fill("")
    .map((_, i) => (
      <SkeletonBlock
        key={i}
        style={{ height: "3rem", width: "90%", marginBottom: "1rem" }}
      />
    ));
};

export const RoundHourglassIcon = () => {
  return (
    <div
      className="gray-background"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `${spacing4} ${spacing4}`,
        borderRadius: "50%",
      }}
    >
      <IconREMSize height={1.6} width={1.6}>
        <HourglassIcon />
      </IconREMSize>
    </div>
  );
};
