import {
  CompletedOptItem,
  FailedOptResult,
  InvalidOptResult,
  OptResult,
  RunningOptResult,
} from "../../functions/optimize";

export const isRunning = (l: OptResult): l is RunningOptResult =>
  l.task_status === "running";
export const isFailed = (l: OptResult): l is FailedOptResult =>
  l.task_status === "failed";
export const isCompleted = (l: OptResult): l is CompletedOptItem =>
  l.task_status === "completed";
export const isInvalid = (l: OptResult): l is InvalidOptResult =>
  l.task_status === "invalid";
