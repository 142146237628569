import { FinanceId } from "finance/types";
import { DownloadCSV } from "components/CsvDownload/CsvDownloadButton";
import { CostUnit } from "types/financial";
import { currentYear } from "utils/inflation";
import { useLcoe } from "finance/hooks/useLcoe";
import { lunwrap } from "utils/jotai";
import { useAtomValue } from "jotai";
import { getPark } from "analysis/inputs";

export const DownloadLcoeDataButton = ({
  id,
  buttonType,
}: {
  id: FinanceId;
  buttonType: "text" | "menu";
}) => {
  const {
    results: { cashFlow, lcoe },
  } = useLcoe(id);
  const park = useAtomValue(getPark(id));

  const columns = [
    { header: "Type", field: "type" },
    { header: "Year", field: "year" },
    { header: "Value", field: "value" },
    { header: "Unit", field: "unit" },
  ];

  const getData = async () => {
    const cf = lunwrap(cashFlow);
    const lcoe_ = lunwrap(lcoe);
    if (!cf || !lcoe_) return undefined;

    return [
      ...cf,
      {
        year: currentYear(),
        value: lcoe_.toFixed(1),
        type: "LCoE",
        unit: CostUnit.euroPerMWh,
      },
    ];
  };

  const filename = `lcoe-${park.properties.name}-${new Date().toISOString()}.csv`;

  return (
    <DownloadCSV
      data={getData}
      columns={columns}
      filename={filename}
      buttonType={buttonType}
      loading={cashFlow.state === "loading" || lcoe.state === "loading"}
    />
  );
};
