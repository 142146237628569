import { CableType } from "../../../services/cableTypeService";
import {
  generateColorFromPrepickedColorLibrary,
  preGenerateColorFromPrepickedColorLibrary,
} from "./utils";

export const getSomeCableColor = () =>
  generateColorFromPrepickedColorLibrary(0);

export const getCableColorsMap = (
  cableTypes: Map<string, CableType>,
): Map<string, string> => {
  const preGeneratedColors = preGenerateColorFromPrepickedColorLibrary(
    cableTypes.size,
  );

  const ret = new Map();
  [...cableTypes.values()].forEach((type, i) => {
    ret.set(type.id, preGeneratedColors[i]);
  });
  return ret;
};

export const cableChainColor = "yellow";
