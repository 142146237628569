import { useConnectionStateListener } from "ably/react";
import { useCheckAccessToken } from "hooks/useRefreshTokenBeforeExpiration";
import { useToast } from "hooks/useToast";

const errorStates = ["suspended", "disconnected", "failed"];

export function AblyMonitorSystem({ clientId }: { clientId: string }) {
  const { error, info } = useToast();
  const refreshAccessToken = useCheckAccessToken();

  useConnectionStateListener((stateChange) => {
    let printLogs = false;
    // if going from error to ok, remove error toast
    if (
      errorStates.includes(stateChange.previous) &&
      !errorStates.includes(stateChange.current)
    ) {
      info("Connection restored", { groupId: "ably-monitor-system" });
      printLogs = true;
      // if going from ok to error, set error toast
    } else if (
      !errorStates.includes(stateChange.previous) &&
      errorStates.includes(stateChange.current) &&
      stateChange.reason
    ) {
      error(`Connection to Vind server is lost, trying to reconnect`, {
        groupId: "ably-monitor-system",
        timeout: 40000,
      });
      console.error("ably monitor system error: ", stateChange.reason.message);
      printLogs = true;
    }

    // if connection is suspended, disconnected or failed, refresh access token if possible
    if (
      stateChange.current === "suspended" ||
      stateChange.current === "disconnected" ||
      stateChange.current === "failed"
    ) {
      try {
        refreshAccessToken();
      } catch (e) {
        console.error("ably monitor system error: ", e);
      }
    }

    if (printLogs) {
      console.group("AblyMonitorSystem");
      console.log("clientId", clientId);
      console.log("current", stateChange.current); // the new connection state
      console.log("previous", stateChange.previous); // the previous connection state
      console.log("reason", stateChange.reason); // if applicable, an error indicating the reason for the connection state change
      console.groupEnd();
    }
  }, clientId);
  return <></>;
}
