import { ReactNode, CSSProperties, useEffect } from "react";
import styled from "styled-components";
import Chevron from "@icons/24/ArrowRight.svg?react";
import { typography } from "../../styles/typography";
import useBooleanState from "../../hooks/useBooleanState";
import { colors } from "../../styles/colors";
import { SkeletonText } from "../Loading/Skeleton";
import { spaceMedium } from "styles/space";

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: ${spaceMedium} 0;
`;

const ToggleableTitle = styled(Title)`
  cursor: pointer;
  color: ${colors.primaryText};
`;

const TitleWithImage = styled.div<{
  titleStyle?: {
    fontWeight: string;
    fontSize: string;
    lineHeight: string;
  };
}>`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  color: ${colors.textSecondary};
  ${(props) => props.titleStyle || typography.sub3}
`;

const ChevronWrapper = styled.div<{
  open?: boolean;
  chevronSize: CSSProperties["fontSize"];
}>`
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s;
  svg {
    height: ${(p) => p.chevronSize};
    width: ${(p) => p.chevronSize};
    path {
      stroke: ${colors.primaryText};
    }
  }
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;
`;

const LoaderDiv = styled.div`
  width: 2rem;
`;

const NewToggleableList = ({
  title,
  icon,
  tag,
  children,
  contentStyle,
  defaultOpen = false,
  chevronSize = "2.2rem",
  onOpenChange,
  value,
  loading,
}: {
  title: string;
  icon?: JSX.Element;
  tag?: JSX.Element;
  value?: JSX.Element | string;
  errorString?: string;
  chevronSize?: CSSProperties["fontSize"];
  contentStyle?: CSSProperties;
  children?: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?(isOpen: boolean): void;
  loading?: boolean;
}) => {
  const [open, toggleOpen] = useBooleanState(defaultOpen);

  useEffect(() => {
    onOpenChange?.(open);
  }, [open, onOpenChange]);

  return (
    <Content>
      <ToggleableTitle onClick={toggleOpen}>
        <TitleWithImage>
          {!!icon && icon}
          {title}
          {!!tag && tag}
        </TitleWithImage>{" "}
        <RightSideWrapper>
          {!!value && !loading && value}
          {loading && (
            <LoaderDiv>
              <SkeletonText />
            </LoaderDiv>
          )}
          <ChevronWrapper open={open} chevronSize={chevronSize}>
            <Chevron width={"1rem"} />
          </ChevronWrapper>
        </RightSideWrapper>
      </ToggleableTitle>
      {children && <div style={contentStyle}>{open && children}</div>}
    </Content>
  );
};

export default NewToggleableList;
