import { z } from "zod";
import { components } from "api/autosave";

export const _AutosaveRequestEntry = components.schemas.AutosaveRequestEntry;
export type AutosaveRequestEntry = z.infer<typeof _AutosaveRequestEntry>;

export const _SaveRequestEntry = components.schemas.SaveRequestEntry;
export type SaveRequestEntry = z.infer<typeof _SaveRequestEntry>;

export const _DeleteSaveRequestAblyMessage = z.object({
  nodeId: z.string(),
  branchId: z.string(),
  timestamp: z.number(),
});
