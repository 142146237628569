import { getBranchId, getParkId } from "analysis/inputs";
import { atom } from "jotai";
import { turbinesInParkWithFoundationFamily } from "state/jotai/turbine";
import { atomFamily } from "utils/jotai";
import { isDefined } from "utils/predicates";
import { getDistanceFromPortToPark } from "../utils";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import {
  jackUpDownActivity,
  loadJacketOnDeckActivity,
  positionOnSiteActivity,
  transitActivity,
} from "../common";
import {
  InstallationVesselType,
  isFeederBargeVessel,
  isInstallationVessel,
} from "services/vesselService";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { vesselTypesFamily } from "state/jotai/vesselType";
import {
  JacketActivitiesConfig,
  SubstationActivitiesConfig,
} from "services/operationsConfigurationService";

export const jacketInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const turbinesWithFnd = await get(
      turbinesInParkWithFoundationFamily({
        parkId,
        branchId,
      }),
    );

    const turbinesWithJacket = turbinesWithFnd
      .filter(([, f]) => f.type === "jacket" || f.type === "detailed_jacket")
      .map(([t]) => t);

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const { installationSeason, weatherLimits, jackets } =
      configuration.ti.foundations;
    const { installationVessel, feederVessel, activities } = jackets;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const installationVesselType = vesselTypes.get(installationVessel.vesselId);
    const feederVesselType = vesselTypes.get(feederVessel.vesselId);

    if (
      !installationVesselType ||
      !isInstallationVessel(installationVesselType)
    )
      return undefined;

    if (!feederVesselType || !isFeederBargeVessel(feederVesselType))
      return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const numJackets = turbinesWithJacket.length;
    const numRounds = Math.ceil(
      numJackets / feederVesselType.maxJacketCapacity,
    );
    const numJacketsLastRound =
      numJackets - (numRounds - 1) * feederVesselType.maxJacketCapacity;

    const transitTimeFeeder = Math.ceil(
      portParkDistance / feederVesselType.transitSpeed,
    );

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numRounds; i++) {
      const jacketsInRound =
        i === numRounds - 1
          ? numJacketsLastRound
          : feederVesselType.maxJacketCapacity;

      for (let j = 0; j < jacketsInRound; j++) {
        totalInstallTime.push(loadJacketOnDeckActivity(activities));
      }

      totalInstallTime.push(transitActivity(transitTimeFeeder));

      for (let j = 0; j < jacketsInRound; j++) {
        parkOperationsJacket(installationVesselType, activities, true).forEach(
          (operation) => {
            totalInstallTime.push(operation);
          },
        );
      }

      totalInstallTime.push(transitActivity(transitTimeFeeder));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const positionPileActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => ({
  id: "Position pile",
  duration: activities.positionPile,
  useWindLim: true,
  useWaveLim: true,
});

const drivePileActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => ({
  id: "Drive pile",
  duration: activities.drivePile,
  useWindLim: true,
  useWaveLim: true,
});

const layPinTemplaceActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => ({
  id: "Lay pin template",
  duration: activities.layPinTemplate,
  useWindLim: true,
  useWaveLim: true,
});

const liftLowerJacketActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => ({
  id: "Lift and lower jacket",
  duration: activities.liftAndLowerJacket,
  useWindLim: true,
  useWaveLim: true,
});

const groutJacketActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
  mileStone?: boolean,
) => ({
  id: "Grout jacket",
  mileStone,
  duration: activities.groutJacket,
  useWindLim: true,
  useWaveLim: true,
});

const pileOperations = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => [positionPileActivity(activities), drivePileActivity(activities)];

export const parkOperationsJacket = (
  vessel: InstallationVesselType,
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
  mileStone?: boolean,
) =>
  [
    positionOnSiteActivity,
    jackUpDownActivity(vessel),
    layPinTemplaceActivity(activities),
    ...pileOperations(activities),
    ...pileOperations(activities),
    ...pileOperations(activities),
    ...pileOperations(activities), //assume four legs
    liftLowerJacketActivity(activities),
    groutJacketActivity(activities, mileStone),
    jackUpDownActivity(vessel),
  ].filter(isDefined);
