import { ValidationWarningTypes } from "./utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { turbineTypeInvalidWarningFamily } from "./TurbineTypeInvalid";
import { cableTypeInvalidWarningFamily } from "./CableTypeInvalid";
import { foundationTypeInvalidWarningFamily } from "./FoundationTypeInvalid";
import { substationTypeInvalidWarningFamily } from "./SubstationTypeInvalid";
import { featureMapFamily } from "state/jotai/features";
import { undefMap } from "utils/utils";

type InvalidTypes = {
  turbines:
    | ValidationWarning<ValidationWarningTypes.TurbineTypeInvalid>
    | undefined;
  cables:
    | ValidationWarning<ValidationWarningTypes.CableTypeInvalid>
    | undefined;
  foundations:
    | ValidationWarning<ValidationWarningTypes.FoundationTypeInvalid>
    | undefined;
  substations:
    | ValidationWarning<ValidationWarningTypes.SubstationTypeInvalid>
    | undefined;
};

const invalidTypesFamily = atomFamily(
  (args: { branchId: string | undefined }) =>
    atom<Promise<InvalidTypes>>(async (get) => {
      return {
        turbines: await get(turbineTypeInvalidWarningFamily(args)),
        cables: await get(cableTypeInvalidWarningFamily(args)),
        foundations: await get(foundationTypeInvalidWarningFamily(args)),
        substations: await get(substationTypeInvalidWarningFamily(args)),
      };
    }),
);

export const invalidTypesInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<InvalidTypes>>(async (get) => {
      const invalids = await get(invalidTypesFamily({ branchId }));

      const fmap = await get(featureMapFamily({ branchId }));
      const inPark = (id: string) =>
        fmap.get(id)?.properties?.parentIds?.[0] === parkId;

      // This is ugly, but I didn't find a better way of writing it.

      const turbines = undefMap(invalids.turbines, (t) => ({
        ...t,
        featureIds: t.featureIds.filter(inPark),
      }));
      const cables = undefMap(invalids.cables, (t) => ({
        ...t,
        featureIds: t.featureIds.filter(inPark),
      }));
      const foundations = undefMap(invalids.foundations, (t) => ({
        ...t,
        featureIds: t.featureIds.filter(inPark),
      }));
      const substations = undefMap(invalids.substations, (t) => ({
        ...t,
        featureIds: t.featureIds.filter(inPark),
      }));

      return {
        turbines: turbines?.featureIds?.length ?? 0 ? turbines : undefined,
        cables: cables?.featureIds?.length ?? 0 ? cables : undefined,
        foundations:
          foundations?.featureIds?.length ?? 0 ? foundations : undefined,
        substations:
          substations?.featureIds?.length ?? 0 ? substations : undefined,
      };
    }),
);
