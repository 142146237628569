import CableIcon from "@icons/24/Cabling-2.svg?react";
import { InnerContainer, Container, Title } from "../style";
import Tooltip from "components/General/Tooltip";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import ManageMyAccessButton from "../shared/ManageMyAccessButton";
import { tabToName } from "../shared/utils";
import { CableManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { Row } from "components/General/Layout";

export default function CableImage({
  onClick,
  disabled,
  disabledText,
}: {
  onClick: () => void;
  disabled?: boolean;
  disabledText: string;
}) {
  return (
    <Container
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {disabled && <ManageMyAccessButton />}
      <Tooltip disabled={!disabled} text={disabledText}>
        <InnerContainer className="InnerContainer">
          <IconREMSize width={18} height={18} stroke={colors.blue600}>
            <CableIcon />
          </IconREMSize>
        </InnerContainer>
      </Tooltip>
      <Row style={{ justifyContent: "space-between" }}>
        <Title>{tabToName("cable")} </Title>
        <CableManagersPreview optionToAddManagers={false} />
      </Row>
    </Container>
  );
}
