import { projectIdAtom } from "state/pathParams";
import {
  CostConfiguration,
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
} from "../../../../services/costService";
import { useAtomValue, useSetAtom } from "jotai";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { Input, InputDimensioned, TextArea } from "../../../General/Input";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import { FirstRowWrapper } from "../Capex";
import Mooring from "@icons/24/Anchor.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { DefaultCosts } from "../constants";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { isDefined } from "../../../../utils/predicates";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { MOORING_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useMooringSettings";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import PreviewIcon from "@icons/24/PreviewIcon.svg?react";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { ChangelogCategory } from "components/InputChangelog/types";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";

const MooringSpecificCosts = ({
  isReadOnly,
  configuration,
  nodeId,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;
  const category: ChangelogCategory = isLibraryResource
    ? "org_financial_manage"
    : "project";

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleLineCostTypeChange = (type: "default" | "override") => () => {
    if (type === "default") {
      updateFixedCapex(
        {
          lines: undefined,
        },
        "mooring",
      );
    } else {
      updateFixedCapex(
        {
          lines: {
            cost: DefaultCosts[CostType.Mooring].line[
              CostUnit.millionEuroPerKM
            ],
            unit: CostUnit.millionEuroPerKM,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        },
        "mooring",
      );
    }
  };

  if (!localConfig.capex.fixed.mooring) return null;
  const { lines } = localConfig.capex.fixed.mooring ?? {};

  return (
    <>
      <FirstRowWrapper>
        <GridWithFiveColumns>
          <Input
            value={"Mooring system"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />

          {lines === undefined ? (
            <Row
              style={{
                width: "100%",
              }}
            >
              <Tag>
                <ComponentsIcon />
                From components
              </Tag>
              {inLibraryContext ? (
                <HelpTooltip text="Using the cost specified on the mooring line type" />
              ) : (
                <IconBtn
                  size={"1.5rem"}
                  onClick={() => {
                    setMidScreenModalTypeOpen({
                      modalType: ComponentsPreviewType,
                      metadata: {
                        componentType: MOORING_MENU_ID,
                        isLibraryResource,
                        inLibraryContext,
                      },
                    });
                  }}
                >
                  <PreviewIcon />
                </IconBtn>
              )}
            </Row>
          ) : (
            <Row
              style={{
                width: "100%",
              }}
            >
              <InputDimensioned
                step="0.1"
                unit={
                  localConfig?.capex.fixed.mooring.lines?.unit ??
                  CostUnit.millionEuroPerKM
                }
                units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
                type="number"
                value={
                  localConfig?.capex.fixed.mooring?.lines
                    ? localConfig?.capex.fixed.mooring.lines?.cost ??
                      DefaultCosts[CostType.Mooring].line[
                        CostUnit.millionEuroPerKM
                      ]
                    : undefined
                }
                initialValue={
                  configuration?.capex.fixed.mooring?.lines
                    ? configuration?.capex.fixed.mooring.lines?.cost ??
                      DefaultCosts[CostType.Mooring].line[
                        CostUnit.millionEuroPerKM
                      ]
                    : undefined
                }
                changelogInfo={financialChangelogInfo(
                  configuration.id,
                  nodeId,
                  "capex.fixed.mooring.lines.cost",
                  category,
                )}
                onChange={(cost, _, unit) =>
                  updateFixedCapex(
                    {
                      lines: {
                        unit,
                        cost,
                      },
                    },
                    "mooring",
                  )
                }
                onUnitChange={(unit) => {
                  updateFixedCapex(
                    {
                      lines: {
                        unit,
                        cost: (DefaultCosts as any)[CostType.Mooring].line[
                          unit
                        ],
                      },
                    },
                    "mooring",
                  );
                }}
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                style={{
                  width: "100%",
                }}
                disabled={
                  isReadOnly || !localConfig?.capex.fixed.mooring?.lines
                }
              />
            </Row>
          )}
          {useConfidenceLevel &&
            (!(lines === undefined) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex(
                    {
                      lines: {
                        ...localConfig.capex.fixed.mooring?.lines,
                        confidenceLevel,
                      },
                    },
                    "mooring",
                  );
                }}
                confidenceLevel={
                  localConfig.capex.fixed.mooring?.lines?.confidenceLevel
                }
              />
            ) : (
              <Tag>
                <Tooltip
                  text={
                    "Not able to specify confidence on configuration level when the cost is from components"
                  }
                >
                  <ButtonTextWrapper>
                    <ConfidenceLevelDot confidenceLevel={undefined} />
                    {ConfidenceLevel.notSpecified}
                  </ButtonTextWrapper>
                </Tooltip>
              </Tag>
            ))}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                  width: "100%",
                }}
                value={localConfig?.capex.fixed.mooringFreeText ?? ""}
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex({
                    mooringFreeText: e.target.value,
                  });
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}

          <Button
            size="small"
            icon={lines === undefined ? <PencilIcon /> : <ComponentsIcon />}
            buttonType="secondary"
            text={lines === undefined ? "Override" : "Use components"}
            onClick={
              lines === undefined
                ? handleLineCostTypeChange("override")
                : handleLineCostTypeChange("default")
            }
            disabled={isReadOnly}
          />
        </GridWithFiveColumns>
      </FirstRowWrapper>

      <Row>
        <GridWithFiveColumns>
          <Input
            value={"Anchors"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.millionEuroPerUnit}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.anchors?.cost ?? 0}
            initialValue={configuration.capex.fixed.mooring?.anchors?.cost ?? 0}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "capex.fixed.mooring.anchors.cost",
              category,
            )}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  anchors: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{
              width: "100%",
            }}
          />
          {useConfidenceLevel && (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    anchors: {
                      ...localConfig.capex.fixed.mooring?.anchors,
                      confidenceLevel: confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.anchors?.confidenceLevel
              }
            />
          )}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                  width: "100%",
                }}
                value={localConfig.capex.fixed.mooring?.anchors?.freeText ?? ""}
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex(
                    {
                      anchors: {
                        ...localConfig.capex.fixed.mooring?.anchors,
                        freeText: e.target.value,
                      },
                    },
                    "mooring",
                  );
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}
        </GridWithFiveColumns>
      </Row>
      <Row>
        <GridWithFiveColumns>
          <Input
            value={"Clump weights"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.euroPerT}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.clumpWeights?.cost ?? 0}
            initialValue={
              configuration.capex.fixed.mooring?.clumpWeights?.cost ?? 0
            }
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "capex.fixed.mooring.clumpWeights.cost",
              category,
            )}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  clumpWeights: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{
              width: "100%",
            }}
          />
          {useConfidenceLevel && (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    clumpWeights: {
                      ...localConfig.capex.fixed.mooring?.clumpWeights,
                      confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.clumpWeights?.confidenceLevel
              }
            />
          )}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                  width: "100%",
                }}
                value={
                  localConfig.capex.fixed.mooring?.clumpWeights?.freeText ?? ""
                }
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex(
                    {
                      clumpWeights: {
                        ...localConfig.capex.fixed.mooring?.clumpWeights,
                        freeText: e.target.value,
                      },
                    },
                    "mooring",
                  );
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}
        </GridWithFiveColumns>
      </Row>
      <Row>
        <GridWithFiveColumns>
          <Input
            value={"Buoys"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />
          <InputDimensioned
            step="0.1"
            unit={CostUnit.euroPerT}
            type="number"
            disabled={isReadOnly}
            value={localConfig.capex.fixed.mooring?.buoys?.cost ?? 0}
            initialValue={configuration.capex.fixed.mooring?.buoys?.cost ?? 0}
            changelogInfo={financialChangelogInfo(
              configuration.id,
              nodeId,
              "capex.fixed.mooring.buoys.cost",
              category,
            )}
            onChange={(cost, _, unit) =>
              updateFixedCapex(
                {
                  buoys: {
                    unit,
                    cost,
                  },
                },
                "mooring",
              )
            }
            style={{
              width: "100%",
            }}
          />
          {useConfidenceLevel && (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                updateFixedCapex(
                  {
                    buoys: {
                      ...localConfig.capex.fixed.mooring?.buoys,
                      confidenceLevel,
                    },
                  },
                  "mooring",
                );
              }}
              confidenceLevel={
                localConfig.capex.fixed.mooring?.buoys?.confidenceLevel
              }
            />
          )}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                  width: "100%",
                }}
                value={localConfig.capex.fixed.mooring?.buoys?.freeText ?? ""}
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex(
                    {
                      buoys: {
                        ...localConfig.capex.fixed.mooring?.buoys,
                        freeText: e.target.value,
                      },
                    },
                    "mooring",
                  );
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}
        </GridWithFiveColumns>
      </Row>
    </>
  );
};

const MooringInstallationCosts = ({
  isReadOnly,
  nodeId,
  category,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const isOnshoreConfig = configuration.type === "onshore";

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();

  const handleMooringCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ mooring: type });
      } else {
        updateInstallationCapex({
          mooring: {
            unit: CostUnit.millionEuroPerLine,
            cost: DefaultCosts[CostType.Mooring].installation[
              CostUnit.millionEuroPerLine
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.mooring) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.mooring,
                ) &&
                  localConfig?.capex.installation.mooring.unit) ||
                CostUnit.millionEuroPerLine
              }
              units={[
                CostUnit.thousandEuroPerMW,
                CostUnit.millionEuroPerLine,
                CostUnit.millionEuroPerUnit,
              ]}
              type="number"
              value={
                isOperationsOverrideCost(
                  localConfig?.capex.installation.mooring,
                )
                  ? localConfig.capex.installation.mooring.cost ??
                    DefaultCosts[CostType.Mooring].installation[
                      CostUnit.millionEuroPerLine
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.mooring,
                )
                  ? configuration.capex.installation.mooring.cost ??
                    DefaultCosts[CostType.Mooring].installation[
                      CostUnit.millionEuroPerLine
                    ]
                  : undefined
              }
              onChange={(cost) => updateInstallationCapex({ cost }, "mooring")}
              onUnitChange={(unit) => {
                updateInstallationCapex({
                  mooring: {
                    unit,
                    cost: (DefaultCosts as any)[CostType.Mooring].installation[
                      unit
                    ],
                  },
                });
              }}
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.mooring.cost",
                category,
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.mooring &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.mooring,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(localConfig?.capex.installation.mooring === "operations_cost") ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.mooring === "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  mooring: {
                    ...localConfig?.capex.installation.mooring,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.mooring.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.installation.mooringFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  mooringFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.mooring) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.mooring)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.mooring)
                ? handleMooringCostTypeChange("override")
                : handleMooringCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const MooringCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Mooring system"
      category={CostType.Mooring}
      isReadOnly={isReadOnly}
      configuration={configuration}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
      units={[
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerKM,
        CostUnit.millionEuroPerLine,
        CostUnit.millionEuro,
      ]}
      icon={<Mooring />}
    >
      <MooringSpecificCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <MooringInstallationCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={isLibraryResource ? "org_financial_manage" : "project"}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
