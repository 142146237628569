import { z } from "zod";

const RECENT_SEARCHES_KEY = "recent_searches_v1";

// Define the schema for a stored search item
const StoredSearchItemSchema = z.object({
  id: z.string(),
  type: z.string(),
  timestamp: z.number(),
});

// Create an array schema
const StoredSearchesSchema = z.array(StoredSearchItemSchema);

export type StoredSearchItem = z.infer<typeof StoredSearchItemSchema>;

export const addRecentSearch = (itemId: string, type: string) => {
  const stored = getStoredSearches();

  // Remove if exists and add to front
  const newStored = [
    {
      id: itemId,
      type,
      timestamp: Date.now(),
    },
    ...stored.filter((s) => !(s.id === itemId && s.type === type)),
  ].slice(0, 5); // Keep only 5 most recent

  localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(newStored));
};

export const getStoredSearches = (): StoredSearchItem[] => {
  try {
    const stored = localStorage.getItem(RECENT_SEARCHES_KEY);
    if (!stored) return [];

    // Parse and validate the stored data
    const parsed = StoredSearchesSchema.safeParse(JSON.parse(stored));

    if (!parsed.success) {
      // If validation fails, clear the invalid data and return empty array
      localStorage.removeItem(RECENT_SEARCHES_KEY);
      return [];
    }

    return parsed.data;
  } catch {
    return [];
  }
};

// Helper to determine the type of a search result
export const getSearchResultType = (groupTitle: string): string => {
  switch (groupTitle.toLowerCase()) {
    case "members":
      return "member";
    case "groups":
      return "group";
    case "projects and folders":
      return "project";
    case "actions":
      return "action";
    case "turbines":
      return "library-turbine";
    case "foundations":
      return "library-foundation";
    case "cables":
      return "library-cable";
    case "export cables":
      return "library-export-cable";
    case "substations":
      return "library-substation";
    case "analysis":
      return "library-analysis";
    case "financials":
      return "library-financial";
    case "data packages":
      return "library-data-package";
    case "features":
      return "feature";
    case "mapbox":
      return "mapbox";
    default:
      return "unknown";
  }
};
