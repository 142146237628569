import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  externalLayerFilterPropertyAtom,
  selectedDataLayerPropertiesTableAtom,
} from "../../../state/layer";
import { TextIcon, TextRaw } from "../../../styles/typography";
import FilterIcon from "@icons/24/Filter.svg?react";
import PinnedIcon from "@icons/24/Pinned.svg?react";
import { modalTypeOpenAtom } from "../../../state/modal";
import { FilterExternalDataLayersModalType } from "../../FilterExternalDataLayers/FilterExternalDataLayers";
import { useLayerSettingsCrud } from "../../LayerList/LayerSettings/useLayerSettingsCrud";
import {
  getSelectedLayers,
  layerSettingSelectorFamily,
} from "../../LayerList/LayerSettings/state";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { spaceMedium, spacing4 } from "../../../styles/space";
import {
  currentExternalLayerSelection,
  ExternalSelectionItem,
} from "../../../state/externalLayerSelection";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import { colors } from "../../../styles/colors";
import { resetListIfNotAlreadyEmpty } from "../../../utils/resetList";
import { Layer } from "../../../types/layers";
import { isCustomLayer } from "../../LayerList/utils";
import { useSetAtom } from "jotai";
import { maybeGetLayerId } from "utils/layer";
import { unwrap } from "jotai/utils";
import { customLayersMetadataSelectorAsync } from "state/customLayers";

const Scrollable = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
`;

const HiddenIconWrapper = styled(TextIcon)<{
  visible?: boolean;
}>`
  opacity: ${(p) => (p.visible ? "1" : "0")};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spaceMedium};

  &:hover ${HiddenIconWrapper} {
    opacity: 1;
  }
`;

const ValueWithMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }
`;

const BrokenWord = styled.p`
  word-break: break-all;
`;

const EllipsisWord = styled.p`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const KeyWrapper = styled.div`
  ${TextRaw};
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-width: 20%;
  margin: 0;
  word-break: break-all;

  svg {
    path {
      stroke: ${colors.secondaryText};
    }
  }
`;

const PropertyValueIsPinned = ({
  layerId,
  propertyKey,
  projectId,
}: {
  layerId: string;
  propertyKey: string;
  projectId: string;
}) => {
  const settings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layerId,
    }),
  );
  const visible = (settings.layerStyle?.pinnedProperties ?? []).includes(
    propertyKey,
  );

  return visible ? (
    <PinnedIcon
      style={{
        width: "1.3rem",
        height: "1.3rem",
        marginLeft: "1rem",
      }}
    />
  ) : null;
};

const getSourceName = (layer: Layer) => {
  if (isCustomLayer(layer)) {
    return layer.source;
  }
  return layer.source.name;
};

const ExternalInfoModal = ({
  feature,
  layerName,
}: {
  feature: ExternalSelectionItem;
  layerName: string;
}) => {
  const selectedDataLayerPropertiesTable = useAtomValue(
    selectedDataLayerPropertiesTableAtom,
  );
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const setSelectedExternal = useSetAtom(currentExternalLayerSelection);
  const externalLayerFilterProperty = useAtomValue(
    externalLayerFilterPropertyAtom,
  );
  const selectedLayers = useAtomValue(
    getSelectedLayers({
      projectId,
    }),
  );
  const customLayersL = useAtomValue(
    useMemo(
      () =>
        unwrap(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      [projectId],
    ),
  );

  const allLayers = useMemo(
    () => [...selectedLayers, ...(customLayersL ?? [])],
    [selectedLayers, customLayersL],
  );
  const { put: putLayerSettings } = useLayerSettingsCrud();
  const layerFilterProperty = externalLayerFilterProperty[layerName] || {};
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  const layerId = useMemo(() => maybeGetLayerId(feature), [feature]);
  const layer = useMemo(
    () => allLayers.find((l) => layerId && l.id === layerId),
    [allLayers, layerId],
  );

  const currentSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layerId ?? "",
    }),
  );

  if (!feature?.properties || selectedDataLayerPropertiesTable) {
    return null;
  }

  return (
    <MenuFrame
      title="Information"
      style={{
        minWidth: "20rem",
        maxHeight: "80vh",
        position: "fixed",
        top: `calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height) + ${spacing4})`,
        right: spacing4,
      }}
      onExit={() => setSelectedExternal(resetListIfNotAlreadyEmpty)}
      resizeLeftHorizontal={true}
    >
      <Scrollable>
        {layer && (
          <>
            <Row>
              <KeyWrapper>Source:</KeyWrapper>
              <EllipsisWord title={getSourceName(layer)}>
                {getSourceName(layer)}
              </EllipsisWord>
            </Row>
            <Row>
              <KeyWrapper>Layer:</KeyWrapper>
              <EllipsisWord title={layer.name}>{layer.name}</EllipsisWord>
            </Row>
          </>
        )}
        {Object.entries(feature.properties).map(([key, value]) => {
          const isFilteredOnProperty = Boolean(
            (layerFilterProperty as any)[key],
          );
          const isPinned = (
            currentSettings.layerStyle?.pinnedProperties ?? []
          ).includes(key);

          console.log({ currentSettings });
          return (
            <Row key={key}>
              <KeyWrapper title={key}>
                {key}:
                {isFilteredOnProperty && (
                  <FilterIcon
                    style={{
                      width: "1.3rem",
                      height: "1.3rem",
                      marginLeft: "1rem",
                    }}
                    title="Is filtered on property"
                  />
                )}
                {layerId && (
                  <PropertyValueIsPinned
                    propertyKey={key}
                    layerId={layerId}
                    projectId={projectId}
                  />
                )}
              </KeyWrapper>
              <ValueWithMenu>
                <BrokenWord>{value?.toString() ?? "--"}</BrokenWord>
                <DotMenu
                  buttonStyle={{
                    margin: "0",
                    maxWidth: "1rem",
                    height: "2.4rem",
                  }}
                >
                  <MenuItem
                    name={
                      isFilteredOnProperty ? "Edit filter" : "Create filter"
                    }
                    icon={<FilterIcon />}
                    onClick={() => {
                      console.log({ layer, layerName });
                      setModalTypeOpen({
                        modalType: FilterExternalDataLayersModalType,
                        metadata: {
                          sourceId: layerName,
                          sourceName: layer?.name ?? layerName,
                          property: key,
                        },
                      });
                    }}
                  />
                  <MenuItem
                    name={
                      isPinned
                        ? "Unpin property from map"
                        : "Pin property to map"
                    }
                    icon={<PinnedIcon />}
                    onClick={() => {
                      const newStyle = {
                        ...(currentSettings.layerStyle || {}),
                      };
                      const newPinnedProperties = (
                        newStyle?.pinnedProperties ?? []
                      ).includes(key)
                        ? newStyle.pinnedProperties!.filter((p) => p !== key)
                        : [...(newStyle.pinnedProperties ?? []), key];

                      putLayerSettings([
                        {
                          ...currentSettings,
                          id: currentSettings.id,
                          layerStyle: {
                            ...newStyle,
                            pinnedProperties: newPinnedProperties,
                          },
                        },
                      ]);
                    }}
                  />
                </DotMenu>
              </ValueWithMenu>
            </Row>
          );
        })}
      </Scrollable>
    </MenuFrame>
  );
};

export default ExternalInfoModal;
