import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

export const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;
