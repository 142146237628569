import { atomFamily } from "utils/jotai";
import { featuresInParkFamily } from "./features";
import { atom } from "jotai";
import { CableCorridorFeature } from "types/feature";

export const cableCorridorsInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<CableCorridorFeature[]>>(
      async (get) =>
        (await get(featuresInParkFamily({ parkId, branchId }))).cableCorridor,
    ),
);
