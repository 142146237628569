import { getCapacityFactorPerTurbine } from "analysis/output";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef, useState } from "react";
import { colors, hexToRgb } from "../../../styles/colors";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";

const CapacityFactorsGraph = () => {
  const { triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const stopped = stoppedReason || analysisStoppedReason;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return <Inner />;
};

const Inner = () => {
  const { triggerId } = useDashboardContext();
  const capacityFactors = useAtomValue(getCapacityFactorPerTurbine(triggerId));

  return <InnerDetails capacityFactors={capacityFactors} />;
};

const InnerDetails = ({ capacityFactors }: { capacityFactors: number[] }) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const calculateHistogram = (data: number[]) => {
    const binWidth = 0.1;
    const min = Math.floor(Math.min(...data) * 100);
    const max = Math.ceil(Math.max(...data) * 100);
    const bins = Math.round((max - min) / binWidth);
    const histogramData = new Array(bins).fill(0);
    const binEdges = Array.from(
      { length: bins + 1 },
      (_, i) => min + i * binWidth,
    );

    data.forEach((value) => {
      const percentValue = value * 100;
      const binIndex = Math.min(
        Math.floor((percentValue - min) / binWidth),
        bins - 1,
      );
      histogramData[binIndex]++;
    });

    return { histogramData, binEdges };
  };

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });

    if (current) observer.observe(current);

    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const { histogramData, binEdges } = calculateHistogram(capacityFactors);

    const trace = {
      x: binEdges.slice(0, -1).map((edge) => edge.toFixed(2)),
      y: histogramData,
      type: "bar" as const,
      name: "Capacity factor",
      textposition: "none" as const,
      hovertemplate: "Capacity Factor: %{x}<br />Number of turbines: %{y}",
      marker: {
        color: hexToRgb(colors.primary),
        line: {
          color: hexToRgb(colors.brand),
          width: 0.1,
        },
      },
    };

    const layout = {
      font: { size: 8 },
      paper_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      xaxis: { title: "Capacity factor [%]" },
      yaxis: { title: "Number of turbines" },
      showlegend: false,
      height: 200,
      bargap: 0.2,
      margin: {
        l: 60,
        r: 60,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, [trace], layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, capacityFactors]);

  return <div ref={graphRef} />;
};

export const TurbineCapacityFactorsHistogramWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Turbine capacity factors"
      id="Turbine capacity factors"
      resetKeys={errorBoundaryResetKeys}
    >
      <CapacityFactorsGraph />
    </SafeCard>
  );
};
