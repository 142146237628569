import { useEffect, useMemo } from "react";
import { Mesh, Vector3 } from "three";
import {
  cameraPositionAtom,
  heightAboveGroundAtom,
  viewFovAtom,
  viewHeightAtom,
  viewOrigoSelector,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTowardsSelector,
} from "../../../../state/viewToPark";
import { wgs84ToProjected } from "../../../../utils/proj4";
import { DIVISION_FACTOR } from "../constants";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import { sampleTerrainMeshHeight } from "./utils";
import { fastMax } from "utils/utils";
import { terrainPatchesAddedToSceneRefresherAtom } from "./useDynamicTerrain";
import { useAtomValue, useSetAtom } from "jotai";

export default function useUpdateCamera(
  threeCore: ThreeCoreViewPark | undefined,
  terrainPatches: Mesh[],
) {
  const viewPosition = useAtomValue(viewPositionAtom);
  const setCameraPosition = useSetAtom(cameraPositionAtom);
  const proj4String = useAtomValue(viewProj4StringAtom);
  const origo = useAtomValue(viewOrigoSelector);
  const viewTowards = useAtomValue(viewTowardsSelector);
  const setHeightAboveGroundAtom = useSetAtom(heightAboveGroundAtom);
  const viewHeight = useAtomValue(viewHeightAtom);
  const fov = useAtomValue(viewFovAtom);
  const terrainPatchesAddedToSceneRefresher = useAtomValue(
    terrainPatchesAddedToSceneRefresherAtom,
  );

  const cameraPosition: [number, number] | undefined = useMemo(() => {
    if (!viewPosition || !proj4String || !origo) return;
    const viewPositionPseudoMercator = wgs84ToProjected(
      [viewPosition.lng, viewPosition.lat],
      proj4String,
    );
    return [
      (viewPositionPseudoMercator[0] - origo[0]) / DIVISION_FACTOR,
      (viewPositionPseudoMercator[1] - origo[1]) / DIVISION_FACTOR,
    ];
  }, [viewPosition, origo, proj4String]);

  useEffect(() => {
    if (!cameraPosition) return;
    setCameraPosition(cameraPosition);
  }, [setCameraPosition, cameraPosition]);

  useEffect(() => {
    if (
      !threeCore ||
      !origo ||
      !viewTowards ||
      terrainPatchesAddedToSceneRefresher == null
    )
      return;
    const { camera } = threeCore;
    if (!cameraPosition || !camera) return;
    const lookAt = [
      (viewTowards[0] - origo[0]) / DIVISION_FACTOR,
      (viewTowards[1] - origo[1]) / DIVISION_FACTOR,
    ];

    const raycastHeight = fastMax(
      terrainPatches
        .filter((patch) =>
          patch.geometry.boundingBox?.containsPoint(
            new Vector3(
              cameraPosition[0],
              cameraPosition[1],
              (patch.geometry.boundingBox.max.z +
                patch.geometry.boundingBox.min.z) /
                2,
            ),
          ),
        )
        .map(
          (patchToSampleCameraFrom) =>
            sampleTerrainMeshHeight(cameraPosition, patchToSampleCameraFrom) ??
            0,
        ),
      0,
    );

    const addedViewPointHeight = fastMax(
      terrainPatches
        .filter((patch) =>
          patch.geometry.boundingBox?.containsPoint(
            new Vector3(
              lookAt[0],
              lookAt[1],
              (patch.geometry.boundingBox.max.z +
                patch.geometry.boundingBox.min.z) /
                2,
            ),
          ),
        )
        .map(
          (patchToSampleViewPointFrom) =>
            sampleTerrainMeshHeight(lookAt, patchToSampleViewPointFrom) ?? 0,
        ),
      0,
    );

    setHeightAboveGroundAtom(raycastHeight);

    camera.position.set(
      -cameraPosition[0],
      (viewHeight + raycastHeight) / DIVISION_FACTOR,
      cameraPosition[1],
    );

    camera.lookAt(
      new Vector3(
        -lookAt[0],
        addedViewPointHeight / DIVISION_FACTOR,
        lookAt[1],
      ),
    );
  }, [
    viewTowards,
    origo,
    cameraPosition,
    viewHeight,
    terrainPatches,
    setHeightAboveGroundAtom,
    threeCore,
    terrainPatchesAddedToSceneRefresher,
  ]);

  useEffect(() => {
    if (!threeCore) return;
    const { camera } = threeCore;
    camera.fov = fov;
    camera.updateProjectionMatrix();
  }, [threeCore, fov]);
}
