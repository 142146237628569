import { useAtomValue } from "jotai";
import { orgFolderStructureFamily } from "state/orgFolderStructure";
import {
  FolderItem,
  FolderTreeItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import { SortState } from "../../types";
import {
  buildFolderTree,
  getOrphans,
  isFolderItem,
} from "business/folderStructure/utils";
import { isDefined } from "utils/predicates";
import { useMemo } from "react";

interface Resource {
  id: string;
  name?: string | null;
}

export const useLibraryFolderStructure = <T extends Resource>({
  organisationId,
  resources,
  sortState,
  libraryManageRole,
  searchTerm,
}: {
  organisationId: string;
  resources: T[];
  sortState: SortState;
  libraryManageRole: string;
  searchTerm: string;
}) => {
  const folderStructure = useAtomValue(
    orgFolderStructureFamily({
      orgId: organisationId,
      libraryManageRole,
    }),
  );

  const hasMatchingResources = useMemo(() => {
    return (folder: FolderTreeItemOrResourceItem): boolean => {
      if (folder.type !== "FOLDER") return false;

      // Check if this folder contains any of the filtered resources
      const hasDirectMatches = resources.some(
        (resource) =>
          // Check if the resource exists in the folder's folderItems
          folder.children?.some(
            (item) => item.type === "RESOURCE" && item.id === resource.id,
          ) ?? false,
      );

      // Check children folders recursively
      const hasChildMatches =
        "children" in folder &&
        folder.children.some((child) => hasMatchingResources(child));

      return hasDirectMatches || hasChildMatches;
    };
  }, [resources]);

  const folderTree = useMemo(() => {
    const rootItems = folderStructure.filter((f) => !isDefined(f.parentId));
    const tree = buildFolderTree(rootItems, folderStructure);

    if (!searchTerm) {
      return tree;
    }

    // Filter out folders that don't contain any matching resources
    return tree.filter((item) => {
      if (item.type !== "FOLDER") return true;
      return hasMatchingResources(item);
    });
  }, [folderStructure, searchTerm, hasMatchingResources]);

  const orphanItems = useMemo(() => {
    return getOrphans(folderStructure, resources, (r: T) => r.id);
  }, [resources, folderStructure]);

  const folders = useMemo(() => {
    const unsortedFolders = folderTree.filter(
      (f): f is FolderTreeItem => isFolderItem(f) && "children" in f,
    );
    if (sortState.order === "NONE" || !sortState.field) {
      return unsortedFolders;
    }

    return [...unsortedFolders].sort((a, b) => {
      const fieldA = a.name?.toLowerCase();
      const fieldB = b.name?.toLowerCase();
      if (!fieldA) return -1;
      if (!fieldB) return 1;
      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [folderTree, sortState]);

  const orphansThatExistsInFolderStructure = useMemo(
    () => folderTree.filter((f) => f.type === "RESOURCE") as (T | FolderItem)[],
    [folderTree],
  );

  const orphans = useMemo(() => {
    const unsortedOrphans = [
      ...orphansThatExistsInFolderStructure,
      ...orphanItems,
    ] as (T | FolderItem)[];

    if (sortState.order === "NONE" || !sortState.field) {
      return unsortedOrphans;
    }

    return [...unsortedOrphans].sort((a, b) => {
      let fieldA, fieldB;

      if ("name" in a && "name" in b) {
        fieldA = (a.name ?? "")?.toString().toLowerCase();
        fieldB = (b.name ?? "")?.toString().toLowerCase();
      } else {
        return 0;
      }

      if (!fieldA) return -1;
      if (!fieldB) return 1;

      const comparison = fieldA.localeCompare(fieldB);
      return sortState.order === "ASC" ? comparison : -comparison;
    });
  }, [orphanItems, orphansThatExistsInFolderStructure, sortState]);

  return {
    folders,
    orphans,
  };
};
