import { colorBrewerColors } from "business/style/colors";
import { MenuItemDiv } from "components/General/Menu";
import styled from "styled-components";
import { spacing3 } from "styles/space";
import { typography } from "styles/typography";
import { partition } from "utils/utils";
import { Buckets } from "lib/colors";
import { ColorSquare } from "components/General/ColorSquare";

export const ColorBucketMenuItems = ({
  onClick,
  numBuckets,
}: {
  onClick: (c: Buckets) => void;
  numBuckets: 3 | 4 | 5 | 6 | 7 | 8 | 9;
}) => {
  const [diverging, sequential] = partition(
    colorBrewerColors,
    (c) => c.type === "div",
  );
  return (
    <>
      <MenuItemDiv nohover style={typography.sub3}>
        Sequential
      </MenuItemDiv>
      {sequential.map((colors, i) => (
        <MenuItemDiv
          key={i}
          onClick={() =>
            onClick(Buckets.fromBalanced(colors[numBuckets], 0, 1))
          }
        >
          <ColorRow buckets={Buckets.fromBalanced(colors[numBuckets], 0, 1)} />
        </MenuItemDiv>
      ))}
      <MenuItemDiv nohover style={typography.sub3}>
        Diverging
      </MenuItemDiv>
      {diverging.map((colors, i) => (
        <MenuItemDiv
          key={i}
          onClick={() =>
            onClick(Buckets.fromBalanced(colors[numBuckets], 0, 1))
          }
        >
          <ColorRow buckets={Buckets.fromBalanced(colors[numBuckets], 0, 1)} />
        </MenuItemDiv>
      ))}
    </>
  );
};

const ColorRow_ = styled.div`
  display: flex;
  gap: ${spacing3};
`;
const ColorRow = ({ buckets }: { buckets: Buckets }) => {
  return (
    <ColorRow_>
      {buckets.colors.map((c, i) => (
        <ColorSquare key={i} $color={c} />
      ))}
    </ColorRow_>
  );
};
