import { atom, useAtomValue, useSetAtom } from "jotai";
import { aset, atomFamily, atomFromFn, useJotaiCallback } from "utils/jotai";
import {
  GroupOrgResource,
  GroupedOrgResource,
  GroupedOrgResourceEntry,
  OrganisationResources,
  PersonalOrgResource,
  OrgResource,
} from "../../types";
import {
  addLibraryResourceManager,
  getUserOrganisationResources,
  getUsersOrganisationResources,
  removeUserLibraryResourceManager,
} from "../../service";
import { scream } from "utils/sentry";
import { toastMessagesAtom } from "state/toast";
import { isPersonalOrgResource } from "utils/predicates";
import {
  analysisManagersSelector,
  cableManagersSelector,
  dataPackageManagersSelector,
  exportcableManagersSelector,
  financialManagersSelector,
  foundationManagersSelector,
  turbineManagersSelector,
} from "components/Organisation/Library/state";
import { loggedInUserIdAtom } from "state/user";
import { usersInOrganisationState } from "components/Organisation/state";
import { RESET } from "jotai/utils";
import { refreshUserLibraryAccessAtom } from "components/Projects/useUserLibraryAccessState";

export const PERSONAL_GROUP = "personal";

export const userOrganisationResourcesAtomFamily = atomFamily(
  ({ organisationId, userId }: { organisationId: string; userId: string }) =>
    atomFromFn<Promise<OrgResource[]>>(
      async () => await getUserOrganisationResources(organisationId, userId),
    ),
);

/**
 * Get all resource access for a list of users in an organisation
 */
export const admin_usersOrganisationResourcesAtomFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<OrgResource[]>>(async (get) => {
      const userIds = (await get(usersInOrganisationState(organisationId))).map(
        (u) => u.user_id,
      );
      return await getUsersOrganisationResources(organisationId, userIds);
    }),
);

export const groupedUserOrgResourcesAccessSelectorFamily = atomFamily(
  ({ organisationId, userId }: { organisationId: string; userId: string }) =>
    atom<Promise<GroupedOrgResource>>(async (get) => {
      const rawResults = await get(
        userOrganisationResourcesAtomFamily({
          organisationId,
          userId,
        }),
      );
      return rawResults.reduce((acc, orgResource) => {
        let index;
        let name;
        if (orgResource.type === PERSONAL_GROUP) {
          const personalResource = orgResource as PersonalOrgResource;
          index = personalResource.type;
          name = PERSONAL_GROUP;
        } else {
          const groupResourceMaybe = orgResource as GroupOrgResource;
          index = groupResourceMaybe.group_id;
          name = groupResourceMaybe.group_name;
        }

        if (!acc[index])
          acc[index] = {
            name,
            resources: [],
          } as GroupedOrgResourceEntry;

        acc[index].resources.push(orgResource.resource_name);
        return acc;
      }, {} as GroupedOrgResource);
    }),
);

const useUserOrganisationResourcesCrud = () => {
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const currentUserId = useAtomValue(loggedInUserIdAtom);

  const add = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      userId: string,
      resourcesName: OrganisationResources,
    ) => {
      const prevState = await get(
        userOrganisationResourcesAtomFamily({
          organisationId,
          userId,
        }),
      );
      aset(
        get,
        set,
        userOrganisationResourcesAtomFamily({
          organisationId,
          userId,
        }),
        (curr) =>
          curr.some(
            (c) =>
              isPersonalOrgResource(c) &&
              c.resource_name === resourcesName &&
              c.user_id,
          )
            ? curr
            : [
                ...curr,
                {
                  user_id: userId,
                  resource_name: resourcesName,
                  type: "personal",
                } as PersonalOrgResource,
              ],
      );
      return addLibraryResourceManager(
        organisationId,
        userId,
        resourcesName,
        "user",
      )
        .then(() => {
          set(
            foundationManagersSelector({
              organisationId,
            }),
          );
          set(
            turbineManagersSelector({
              organisationId,
            }),
          );
          set(
            analysisManagersSelector({
              organisationId,
            }),
          );
          set(
            cableManagersSelector({
              organisationId,
            }),
          );
          set(
            exportcableManagersSelector({
              organisationId,
            }),
          );
          set(
            financialManagersSelector({
              organisationId,
            }),
          );
          set(
            dataPackageManagersSelector({
              organisationId,
            }),
          );
          set(
            admin_usersOrganisationResourcesAtomFamily({
              organisationId,
            }),
            RESET,
          );

          if (currentUserId === userId) {
            set(refreshUserLibraryAccessAtom, organisationId);
          }
        })
        .catch((e) => {
          scream(e);
          aset(
            get,
            set,
            userOrganisationResourcesAtomFamily({
              organisationId,
              userId,
            }),
            () => prevState,
          );
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Error while trying to update",
              timeout: 5000,
              type: "error",
            },
          ]);
        });
    },
    [currentUserId, setToastMessages],
  );
  const remove = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      userId: string,
      resourcesName: OrganisationResources,
    ) => {
      const prevState = await get(
        userOrganisationResourcesAtomFamily({
          organisationId,
          userId,
        }),
      );
      aset(
        get,
        set,
        userOrganisationResourcesAtomFamily({
          organisationId,
          userId,
        }),
        (curr) =>
          curr.filter((c) => {
            if (c.type === "group") return true;
            return !(
              isPersonalOrgResource(c) &&
              c.resource_name === resourcesName &&
              c.user_id === userId
            );
          }),
      );
      return removeUserLibraryResourceManager(
        organisationId,
        userId,
        resourcesName,
      )
        .then(() => {
          set(
            foundationManagersSelector({
              organisationId,
            }),
          );
          set(
            turbineManagersSelector({
              organisationId,
            }),
          );
          set(
            analysisManagersSelector({
              organisationId,
            }),
          );
          set(
            cableManagersSelector({
              organisationId,
            }),
          );
          set(
            exportcableManagersSelector({
              organisationId,
            }),
          );
          set(
            financialManagersSelector({
              organisationId,
            }),
          );
          set(
            dataPackageManagersSelector({
              organisationId,
            }),
          );
          set(
            admin_usersOrganisationResourcesAtomFamily({
              organisationId,
            }),
            RESET,
          );
          if (currentUserId === userId) {
            set(refreshUserLibraryAccessAtom, organisationId);
          }
        })
        .catch((e) => {
          scream(e);
          aset(
            get,
            set,
            userOrganisationResourcesAtomFamily({
              organisationId,
              userId,
            }),
            () => prevState,
          );
          setToastMessages((tm) => [
            ...tm,
            {
              text: "Error while trying to update",
              timeout: 5000,
              type: "error",
            },
          ]);
        });
    },
    [currentUserId, setToastMessages],
  );

  return {
    add,
    remove,
  };
};

export default useUserOrganisationResourcesCrud;
