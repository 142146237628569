import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceMedium, spaceTiny } from "../../styles/space";
import { Container } from "../../styles/misc/Misc";
import { typography } from "styles/typography";

export const RowWrapperV2 = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: flex-end;
`;

export const HelpToolsWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-self: flex-end;
`;
export const HelpScaleBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-self: flex-end;
`;

export const LowerRightColumnWrapperV2 = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  gap: ${spaceMedium};
`;

export const BlueHelpPill = styled.div`
  ${typography.sub2}
  box-sizing: border-box;
  background-color: ${colors.surfaceBrand};
  border-radius: 2rem;
  padding: 0.6rem 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaceTiny};
  justify-content: center;
  width: fit-content;

  cursor: pointer;

  p {
    color: white;
    margin: 0;
    padding: 0 0.4rem 0 0;
  }
`;

export const AnimatedBlueHelpPill = styled(BlueHelpPill)`
  width: 3.6rem;
  transition: width 0.1s ease-in-out;

  p {
    display: none;
  }

  &:hover {
    width: 7rem;
    p {
      display: block;
    }
  }
`;

export const HelpText = styled.div`
  ${typography.sub2}
  color: ${colors.white};
`;
