import DownloadIcon from "@icons/24/Download.svg?react";
import * as turf from "@turf/turf";
import { getAnalysisProgress, getStats } from "analysis/output";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import { stringify } from "csv-stringify/sync";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { cablesInParkFamily } from "state/jotai/cable";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { spaceMedium } from "styles/space";
import { isDefined } from "utils/predicates";
import { useToast } from "../../../hooks/useToast";
import { downloadText, fastMax, fastMin } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { colors } from "styles/colors";
import { SkeletonText } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { exportCableTypesFamily } from "state/jotai/exportCableType";

const ExportSystemVoltage = ({
  usedExportCableId,
  setUsedExportCableId,
}: {
  usedExportCableId: string | undefined;
  setUsedExportCableId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const { park, branch, configuration, triggerId } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const exportCables = useAtomValue(
    exportCablesInParkFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );
  const exportCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );
  const cables = useAtomValue(cablesInParkFamily({ parkId, branchId }));
  const exportCableSegments = useAtomValue(
    exportCableSplitsOkFamily({ parkId, branchId }),
  );

  const exportSystemLoss = useAtomValue(getStats(triggerId));
  const exportSystemVoltages =
    exportSystemLoss?.exportSystemStats?.exportSystemVoltages;
  const exportCableSegLengths =
    exportSystemLoss?.exportSystemStats?.exportCableSegLengths;
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const stopped = stoppedReason ?? analysisStoppedReason;
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  if (cables.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No inter-array cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!configuration.electrical.exportSystemLoss) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={
            "Export system losses are not enabled for the selected analysis configuration."
          }
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!usedExportCableId) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No export cables in park."} type={"error"} />
      </CenterContainer>
    );
  }

  if (!exportSystemVoltages || !exportCableSegLengths) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  const selectedExportSystemVoltages = exportSystemVoltages[usedExportCableId];
  const selectedExportCableSegLengths =
    exportCableSegLengths[usedExportCableId];

  const completeCable = exportCables.find((c) => c.id === usedExportCableId);
  const offshoreCable = exportCableSegments.find(
    (c) => c.exportCable.id === usedExportCableId,
  )?.offshore;
  const cableHasLandfall = offshoreCable ? true : false;
  const exportSystemType = exportCableTypes.get(
    completeCable?.properties.cableTypeId ?? "",
  )?.exportCableType;

  if (
    !selectedExportSystemVoltages ||
    !selectedExportCableSegLengths ||
    !completeCable ||
    !exportSystemType
  ) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={"Cannot find results for the selected export cable."}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  const offshoreLength = turf.length(offshoreCable ?? completeCable, {
    units: "kilometers",
  });

  return (
    <>
      <Label style={{ width: "30%", padding: spaceMedium }}>
        <p>Export cable</p>
        <Dropdown
          style={{ flex: 1 }}
          onChange={(e) => setUsedExportCableId(e.target.value)}
          value={usedExportCableId}
        >
          {exportCables.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {`${c.properties.name}`}
              </option>
            );
          })}
        </Dropdown>
      </Label>
      <ExportSystemVoltageInner
        selectedExportSystemVoltages={selectedExportSystemVoltages}
        selectedExportCableSegLengths={selectedExportCableSegLengths}
        offshoreLength={offshoreLength}
        cableHasLandfall={cableHasLandfall}
        exportSystemType={exportSystemType}
      />
    </>
  );
};

const ExportSystemVoltageInner = ({
  selectedExportSystemVoltages,
  selectedExportCableSegLengths,
  offshoreLength,
  cableHasLandfall,
  exportSystemType,
}: {
  selectedExportSystemVoltages: number[];
  selectedExportCableSegLengths: number[];
  offshoreLength: number;
  cableHasLandfall: boolean;
  exportSystemType: "HVAC" | "HVDC";
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const xValues = [...selectedExportCableSegLengths];

    if (exportSystemType === "HVAC") {
      xValues.splice(0, 0, xValues[0]);
      xValues.splice(xValues.length, 0, xValues[xValues.length - 1]);
    }

    const landfallX = [offshoreLength, offshoreLength];
    const landfallY = [0, 5];

    const data = [
      {
        name: "Voltage at full load",
        y: selectedExportSystemVoltages,
        x: xValues,
        type: "scatter" as const,
        hovertemplate: "Position: %{x:,.0f} km<br />Voltage: %{y:,.2f} pu",
        mode: "lines+markers",
      } as const,
      cableHasLandfall
        ? ({
            name: "Landfall",
            y: landfallY,
            x: landfallX,
            type: "scatter" as const,
            line: { color: colors.primaryDisabled, width: 1 } as const,
            mode: "lines",
          } as const)
        : undefined,
    ].filter(isDefined);

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0.5, y: 1.1, xanchor: "center", orientation: "h" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Distance from offshore substation [km]",
        range: [0, 1.01 * xValues[xValues.length - 1]],
        autotick: true,
      },
      yaxis: {
        title: "Export system voltage [pu]",
        range: [
          fastMin(selectedExportSystemVoltages) - 0.02,
          fastMax(selectedExportSystemVoltages) + 0.02,
        ],
        autotick: true,
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [
    graphRef,
    selectedExportSystemVoltages,
    selectedExportCableSegLengths,
    offshoreLength,
    cableHasLandfall,
    exportSystemType,
  ]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const ExportSystemVoltageMenu = ({
  usedExportCableId,
}: {
  usedExportCableId: string | undefined;
}) => {
  const { park, branch, triggerId } = useDashboardContext();
  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );
  const exportCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );
  const analysis = useAtomValue(getStats(triggerId));
  const exportSystemResult = analysis?.exportSystemStats;
  const selectedCable = exportCables.find((c) => c.id === usedExportCableId);
  const exportSystemType = exportCableTypes.get(
    selectedCable?.properties.cableTypeId ?? "",
  )?.exportCableType;
  const exportSystemVoltages = exportSystemResult?.exportSystemVoltages;
  const exportCableSegLengths = exportSystemResult?.exportCableSegLengths;

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!exportSystemVoltages || !exportCableSegLengths) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    if (!usedExportCableId) {
      warning("No export cables in park.");
      return;
    }

    const selectedExportSystemVoltages =
      exportSystemVoltages[usedExportCableId];
    const selectedExportCableSegLengths =
      exportCableSegLengths[usedExportCableId];

    if (
      !selectedExportSystemVoltages ||
      !selectedExportCableSegLengths ||
      !exportSystemType
    ) {
      warning("Cannot find results for the selected export cable.");
      return;
    }

    const header = [
      "Distance from offshore substation [km]",
      "Export system voltage at full load [pu]",
    ];

    const distances = [...selectedExportCableSegLengths];
    if (exportSystemType === "HVAC") {
      distances.splice(0, 0, distances[0]);
      distances.splice(distances.length, 0, distances[distances.length - 1]);
    }

    const rows = selectedExportSystemVoltages
      .map((_, i) => {
        const length = distances[i].toFixed(3);
        const voltage = selectedExportSystemVoltages[i].toFixed(3);

        return [length, voltage];
      })
      .filter(isDefined);

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "export_system_voltage.csv");
  }, [
    exportCableSegLengths,
    exportSystemVoltages,
    usedExportCableId,
    warning,
    exportSystemType,
  ]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const ExportSystemVoltageWidget = () => {
  const { park, branch, errorBoundaryResetKeys } = useDashboardContext();
  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );

  const [usedExportCableId, setUsedExportCableId] = useState<
    string | undefined
  >(exportCables[0]?.id);

  useEffect(() => {
    if (exportCables.length > 0) {
      setUsedExportCableId(exportCables[0]?.id);
    }
  }, [exportCables]);

  return (
    <SafeCard
      title="Export system voltage"
      id="Export system voltage"
      menuItems={
        <ExportSystemVoltageMenu usedExportCableId={usedExportCableId} />
      }
      resetKeys={errorBoundaryResetKeys}
    >
      <ExportSystemVoltage
        usedExportCableId={usedExportCableId}
        setUsedExportCableId={setUsedExportCableId}
      />
    </SafeCard>
  );
};
