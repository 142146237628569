import { useAtomValue, useSetAtom } from "jotai";
import {
  LatestChanges,
  latestLibraryChangesAtom,
  readVersionOfLibraryUpdatedNotificationAtom,
} from "./state";
import React, { useEffect, useMemo, useState } from "react";
import {
  NotificationHeader,
  IconWrapper,
  ContentWrapper,
  Title,
  Message,
  CloseButton,
} from "./style";
import CloseIcon from "@icons/24/Close.svg?react";
import { colors } from "styles/colors";
import styled from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { projectIdAtom } from "state/pathParams";
import LibraryIcon from "@icons/24/Library.svg?react";
import { ChangelogMetadata } from "components/InputChangelog/types";
import { unwrap } from "jotai/utils";
import { libraryCableTypesFamily } from "state/jotai/cableType";
import { libraryFoundationTypesFamily } from "state/jotai/foundation";
import { libraryTurbineTypesFamily } from "state/jotai/turbineType";
import { borderRadiusMedium, spacing4, spacing6, spacing7 } from "styles/space";
import { modalTypeOpenAtom } from "state/modal";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import {
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
} from "state/configuration";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { Row } from "components/General/Layout";
import ChevronIcon from "@icons/24/ArrowRight.svg?react";
import { libraryAnalysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { libraryCostConfigurationsFamily } from "state/jotai/costConfiguration";
import { ANALYSIS_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useAnalysisConfiguration";
import { COST_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { editorAccessProjectSelector } from "state/user";
import { inReadOnlyModeSelector } from "state/project";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { librarySubstationsFamily } from "state/jotai/substationType";
import { fetchProjectResourceUsage } from "services/usageService";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRoleType } from "components/Organisation/Library/types";

const PopupContainer = styled.div`
  position: absolute;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spacing4}
  );
  right: ${spacing4};
  background: ${colors.surfacePrimary};
  border-radius: ${borderRadiusMedium};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 1.2rem;
  width: 30rem;
  z-index: 6;
`;

const StyledLink = styled.a`
  ${typography.caption}
  color: ${colors.indigo700};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${colors.indigo800};
  }
  &:visited {
    color: ${colors.indigo900};
  }
  &:active {
    color: ${colors.indigo900};
  }
`;

const StyledText = styled.span`
  ${typography.caption}
  color: ${colors.textSecondary};
  align-self: center;
`;

const MoreDetailsRow = styled(Row)`
  padding-top: ${spacing7};
  padding-bottom: ${spacing6};
  cursor: pointer;
`;

const ChevronWrapper = styled.div<{ open: boolean }>`
  align-items: center;

  svg {
    transform: rotate(${(p) => (p.open ? "90deg" : "-90deg")});
    transition: 0.1s ease-in-out;

    width: 1rem;
    height: 1rem;
  }
`;

const getTitle = (type: ChangelogMetadata["libraryType"]) => {
  switch (type) {
    case "org_turbine_manage":
      return "Turbine";
    case "org_foundation_manage":
      return "Foundation";
    case "org_cable_manage":
      return "Cable";
    case "org_export_cable_manage":
      return "Export Cable";
    case "org_analysis_manage":
      return "Analysis";
    case "org_financial_manage":
      return "Financial";
    case "org_substation_manage":
      return "Substation";
  }
};

const getComponentTab = (type: ChangelogMetadata["libraryType"]) => {
  switch (type) {
    case "org_turbine_manage":
      return TURBINE_MENU_ID;
    case "org_foundation_manage":
      return FOUNDATION_MENU_ID;
    case "org_cable_manage":
      return CABLE_MENU_ID;
    case "org_export_cable_manage":
      return EXPORT_CABLE_MENU_ID;
    case "org_analysis_manage":
      return ANALYSIS_MENU_ID;
    case "org_financial_manage":
      return COST_MENU_ID;
    case "org_substation_manage":
      return SUBSTATION_MENU_ID;
  }
};

function ProjectNotificationsInner({
  projectId,
  filteredChanges,
  setIsOpen,
}: {
  projectId: string;
  filteredChanges: LatestChanges;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const libraryTurbines = useAtomValue(
    unwrap(libraryTurbineTypesFamily(projectId)),
  );

  const libraryCables = useAtomValue(
    unwrap(libraryCableTypesFamily(projectId)),
  );
  const libraryExportCables = useAtomValue(
    unwrap(libraryCableTypesFamily(projectId)),
  );
  const libraryFoundations = useAtomValue(
    unwrap(libraryFoundationTypesFamily(projectId)),
  );
  const libraryAnalysis = useAtomValue(
    unwrap(libraryAnalysisConfigurationsFamily({ projectId })),
  );
  const libraryFinancialConfigs = useAtomValue(
    unwrap(libraryCostConfigurationsFamily({ projectId })),
  );
  const librarySubstations = useAtomValue(
    unwrap(librarySubstationsFamily(projectId)),
  );

  const setNotificationVersion = useSetAtom(
    readVersionOfLibraryUpdatedNotificationAtom,
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  const setCableMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: CABLE_MENU_ID,
      projectId,
    }),
  );

  const setExportCableMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: EXPORT_CABLE_MENU_ID,
      projectId,
    }),
  );

  const setFoundationMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: FOUNDATION_MENU_ID,
      projectId,
    }),
  );

  const setTurbineMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: TURBINE_MENU_ID,
      projectId,
    }),
  );

  const setAnalysisMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: ANALYSIS_MENU_ID,
      projectId,
    }),
  );

  const setFinancialMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: COST_MENU_ID,
      projectId,
    }),
  );

  const setSubstationMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: SUBSTATION_MENU_ID,
      projectId,
    }),
  );

  const handleClose = () => {
    setIsOpen(false);
    setNotificationVersion(filteredChanges.maxVersion);
  };

  const handleGoToComponent = (change: ChangelogMetadata) => {
    setModalTypeOpen({
      modalType:
        change.libraryType === "org_analysis_manage" ||
        change.libraryType === "org_financial_manage"
          ? ProjectConfigModalTypeV2
          : FeatureSettingsModalTypeV2,
      metadata: { selectedMenuId: getComponentTab(change.libraryType) },
    });

    if (change.libraryType === "org_cable_manage") {
      setCableMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_export_cable_manage") {
      setExportCableMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_foundation_manage") {
      setFoundationMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_turbine_manage") {
      setTurbineMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_analysis_manage") {
      setAnalysisMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_financial_manage") {
      setFinancialMenuSelection(change.resourceId);
    } else if (change.libraryType === "org_substation_manage") {
      setSubstationMenuSelection(change.resourceId);
    }
  };

  const getResourceName = (
    id: string,
    type: ChangelogMetadata["libraryType"],
  ) => {
    switch (type) {
      case "org_turbine_manage":
        return libraryTurbines?.get(id)?.name;
      case "org_foundation_manage":
        return libraryFoundations?.find((f) => f.foundation.id === id)
          ?.foundation.name;
      case "org_cable_manage":
        return libraryCables?.get(id)?.name;
      case "org_export_cable_manage":
        return libraryExportCables?.get(id)?.name;
      case "org_analysis_manage":
        return libraryAnalysis?.get(id)?.name;
      case "org_financial_manage":
        return libraryFinancialConfigs?.get(id)?.name;
      case "org_substation_manage":
        return librarySubstations?.get(id)?.name;
    }
  };

  const isSingleChange = filteredChanges.changes.length === 1;
  const latestChange = filteredChanges.changes[0];

  return (
    <PopupContainer>
      <NotificationHeader>
        <IconWrapper>
          <IconREMSize height={2} width={1.5} iconColor={colors.iconInfo}>
            {isSingleChange ? (
              getIcon(latestChange.libraryType as LibraryManageRoleType)
            ) : (
              <LibraryIcon />
            )}
          </IconREMSize>
        </IconWrapper>
        <ContentWrapper>
          <Title>
            {isSingleChange
              ? `Library ${getTitle(latestChange.libraryType)} edited`
              : "Library resources updated"}
          </Title>
          <Message>
            {isSingleChange ? (
              <>
                The library {getTitle(latestChange.libraryType)}{" "}
                <strong>
                  {getResourceName(
                    latestChange.resourceId,
                    latestChange.libraryType,
                  )}
                </strong>{" "}
                has been edited.{" "}
                <StyledLink onClick={() => handleGoToComponent(latestChange)}>
                  See {getTitle(latestChange.libraryType)} details
                </StyledLink>
                .
              </>
            ) : (
              <>
                Since last time, {filteredChanges.changes.length} library
                resources have been updated.{" "}
                {!isSingleChange && (
                  <>
                    <MoreDetailsRow onClick={() => setIsExpanded(!isExpanded)}>
                      <StyledText>View more details</StyledText>
                      <ChevronWrapper open={isExpanded}>
                        <ChevronIcon />
                      </ChevronWrapper>
                    </MoreDetailsRow>
                    {isExpanded &&
                      filteredChanges.changes.map((change) => (
                        <Row key={change.resourceId}>
                          <IconREMSize height={1.2} width={1.2}>
                            {getIcon(
                              change.libraryType as LibraryManageRoleType,
                            )}
                          </IconREMSize>
                          <StyledLink
                            onClick={() => handleGoToComponent(change)}
                          >
                            {getResourceName(
                              change.resourceId,
                              change.libraryType,
                            )}
                          </StyledLink>
                        </Row>
                      ))}
                  </>
                )}
              </>
            )}
          </Message>
        </ContentWrapper>
        <CloseButton onClick={handleClose}>
          <IconREMSize height={1.4} width={1.4}>
            <CloseIcon />
          </IconREMSize>
        </CloseButton>
      </NotificationHeader>
    </PopupContainer>
  );
}

const getResourceType = (type: ChangelogMetadata["libraryType"]) => {
  switch (type) {
    case "org_turbine_manage":
      return "TURBINE";
    case "org_foundation_manage":
      return "FOUNDATION";
    case "org_cable_manage":
    case "org_export_cable_manage":
      return "CABLE";
    case "org_analysis_manage":
      return "ANALYSIS_CONFIGURATION";
    case "org_financial_manage":
      return "COST_CONFIGURATION";
    case "org_substation_manage":
      return "SUBSTATION";
  }
};

export default function ProjectNotifications() {
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = useMemo(
    () => isCustomerEditor && !isReadOnly,
    [isCustomerEditor, isReadOnly],
  );

  const latestChanges = useAtomValue(latestLibraryChangesAtom);

  const projectId = useAtomValue(projectIdAtom) ?? "";

  const setNotificationVersion = useSetAtom(
    readVersionOfLibraryUpdatedNotificationAtom,
  );
  const [isOpen, setIsOpen] = useState(true);

  const [filteredChanges, setFilteredChanges] = useState<LatestChanges>({
    changes: [],
    maxVersion: 0,
  });

  useEffect(() => {
    if (canEdit) {
      changesForResourcesUsedInProject(latestChanges, projectId).then(
        (changes) => {
          setFilteredChanges({ changes, maxVersion: latestChanges.maxVersion });
        },
      );
    }
  }, [latestChanges, projectId, canEdit]);

  // Set last viewed notification date to now if user has not seen any notification before
  useEffect(() => {
    setNotificationVersion((curr) => (curr === null ? Date.now() : curr));
  }, [filteredChanges.maxVersion, setNotificationVersion]);

  const showNotification =
    canEdit && filteredChanges.changes.length > 0 && isOpen;

  if (!showNotification) return null;

  return (
    <ProjectNotificationsInner
      filteredChanges={filteredChanges}
      projectId={projectId}
      setIsOpen={setIsOpen}
    />
  );
}

const changesForResourcesUsedInProject = async (
  latestChanges: LatestChanges,
  projectId: string,
): Promise<ChangelogMetadata[]> => {
  const filteredChanges = await Promise.all(
    latestChanges.changes.map(async (change) => {
      const resourceType = getResourceType(change.libraryType);
      if (!resourceType) return null;
      const usage = await fetchProjectResourceUsage(
        projectId,
        resourceType,
        change.resourceId,
      );
      return usage.length > 0 ? change : null;
    }),
  );

  return filteredChanges.filter(
    (change: ChangelogMetadata | null): change is ChangelogMetadata =>
      change !== null,
  );
};
