import { useSetAtom } from "jotai";
import React, { useCallback, useMemo } from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ProjectFeature } from "../../../types/feature";
import BinIcon from "@icons/24/Bin.svg?react";
import CopyIcon from "@icons/24/Copy.svg?react";
import InformationIcon from "@icons/24/Information.svg?react";
import useSystemSpecificUnicode from "../../../hooks/useSystemSpecificUnicode";
import { useDeleteFeaturesCallback } from "../../../hooks/deleteFeature";
import { toastMessagesAtom } from "../../../state/toast";
import { featureSupportsCopy } from "components/MapControls/CopyPaste/utils";
import { copy } from "components/MapControls/CopyPaste/copy";
import { Menu, MenuItem } from "../../General/Menu";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";
import SelectElementMenuItem from "./shared/SelectElementMenuItem";
import { colors } from "../../../styles/colors";
import { featureIsLocked } from "../../../utils/predicates";
import { useAtomValue } from "jotai";
import { featuresListAtom } from "state/jotai/features";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import { lockedPropertyName } from "@constants/canvas";
import { trackCanvasOption } from "components/CanvasSelectOption/MenuTracking";
import { useSetPropertyOnProjectFeatures } from "hooks/useSetPropertyOnProjectFeatures";
import LockIcon from "@icons/24/Lock.svg?react";
import UnlockIcon from "@icons/24/Unlock.svg?react";

const getMenuTitle = (nrSelectedFeatures: number) => {
  const menuTitle = `${nrSelectedFeatures} selected feature`;
  if (nrSelectedFeatures > 1) {
    return menuTitle.concat("s");
  }
  return menuTitle;
};

const OneOrMoreCanvasSelectionMenu = ({
  sampleWmsCallback,
  selectedProjectFeatures,
  closeMenu,
  featuresOnPoint,
  enableShowLayerInfo,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  sampleWmsCallback: () => void;
  selectedProjectFeatures: ProjectFeature[];
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const stringToUnicode = useSystemSpecificUnicode();
  const deleteFeatureWithChildren = useDeleteFeaturesCallback();
  const nonLockedFeatures = useMemo(
    () =>
      selectedProjectFeatures.filter((feature) => !featureIsLocked(feature)),
    [selectedProjectFeatures],
  );

  const deleteFeatures = useCallback(() => {
    deleteFeatureWithChildren(nonLockedFeatures.map((f) => f.id));
  }, [nonLockedFeatures, deleteFeatureWithChildren]);
  const projectFeatures = useAtomValue(featuresListAtom);
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId: projectId ?? "",
    }),
  );
  const copyCallback = useCallback(() => {
    copy(
      selectedProjectFeatures,
      [],
      projectFeatures,
      setToastMessagesAtom,
      allLayerSettings,
    );
  }, [
    selectedProjectFeatures,
    projectFeatures,
    setToastMessagesAtom,
    allLayerSettings,
  ]);

  const allLocked = useMemo(
    () => selectedProjectFeatures.every(featureIsLocked),
    [selectedProjectFeatures],
  );

  const onClickLockFeatures = useCallback(() => {
    trackCanvasOption("lock-features", {
      projectId,
      branchId,
    });
    updateFeatureProperties(
      selectedProjectFeatures.map((s) => s.id),
      {
        [lockedPropertyName]: !allLocked,
      },
    );
  }, [
    allLocked,
    branchId,
    projectId,
    selectedProjectFeatures,
    updateFeatureProperties,
  ]);

  const isCopyEnabled = selectedProjectFeatures.some(featureSupportsCopy);

  const menuTitle = useMemo(
    () => getMenuTitle(selectedProjectFeatures.length),
    [selectedProjectFeatures],
  );

  return (
    <Menu
      style={{
        boxShadow: "initial",
      }}
    >
      <MenuItem
        name={menuTitle}
        disabled={true}
        style={{
          borderBottom: `1px solid ${colors.inputOutline}`,
        }}
      />
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        disabled={!enableShowLayerInfo}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <MenuItem
        name={allLocked ? "Unlock elements" : "Lock elements"}
        icon={allLocked ? <UnlockIcon /> : <LockIcon />}
        onClick={onClickLockFeatures}
      />
      <MenuItem
        name={"Copy"}
        icon={<CopyIcon />}
        disabled={!isCopyEnabled}
        shortcut={`${stringToUnicode("command")}+C`}
        onClick={() => {
          copyCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
      <MenuItem
        name={"Delete"}
        disabled={nonLockedFeatures.length === 0}
        style={{
          borderTop: `1px solid ${colors.inputOutline}`,
        }}
        icon={<BinIcon />}
        shortcut={`${stringToUnicode("backspace")}`}
        onClick={() => {
          closeMenu();
          deleteFeatures();
        }}
      />
    </Menu>
  );
};

export default OneOrMoreCanvasSelectionMenu;
