import { countUnitsInEachRound, getDistanceFromPortToPark } from "../utils";
import { sum } from "utils/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getBranchId, getConfiguration, getParkId } from "analysis/inputs";
import { cable3DLengthsFamily, cablesInParkFamily } from "state/jotai/cable";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { substationsInParkFamily } from "state/jotai/substation";
import {
  layBuryCableActivity,
  loadCableCarouselActivity,
  positionOnSiteActivity,
  transitActivity,
} from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { isCableLayVessel } from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { CableActivitiesConfig } from "services/operationsConfigurationService";

export const cableInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const analysisConfiguration = await get(getConfiguration(id));

    const cableLengthsMap = await get(
      cable3DLengthsFamily({
        parkId,
        branchId,
        analysisConfigurationId: analysisConfiguration.id,
      }),
    );
    const cables = await get(
      cablesInParkFamily({
        parkId,
        branchId,
      }),
    );
    const substations = await get(
      substationsInParkFamily({
        parkId,
        branchId,
      }),
    );

    const cableLengths = Array.from(cableLengthsMap.values()).map(
      (c) => c.contingent,
    );
    const avgCableLength =
      cableLengths.length > 0 ? sum(cableLengths) / cableLengths.length : 0;

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const {
      installationVessel,
      installationSeason,
      weatherLimits,
      activities,
    } = configuration.ti.cables;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isCableLayVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const cablesInRounds = countUnitsInEachRound(
      cableLengths,
      vessel.arrayCableCapacity,
    );
    const numRounds = cablesInRounds.length;

    const numSubstationEndPoints = cables.filter((c) =>
      substations.some(
        (s) => s.id === c.properties.fromId || s.id === c.properties.toId,
      ),
    ).length;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const totalInstallTime: InstallationSequence = [];

    let cableCounter = 0;
    for (let i = 0; i < numRounds; i++) {
      const cablesInRound = cablesInRounds[i];
      const cableLengthInRound = avgCableLength * cablesInRound;

      totalInstallTime.push(
        loadCableCarouselActivity(cableLengthInRound, vessel.cableLoadingSpeed),
      );

      totalInstallTime.push(transitActivity(transitTime));

      totalInstallTime.push(positionOnSiteActivity);

      for (let j = 0; j < cablesInRound; j++) {
        totalInstallTime.push(pullInCableTurbineActivity(activities));
        totalInstallTime.push(
          layBuryCableActivity(avgCableLength, vessel.cableLayBurySpeed, true),
        );

        if (cableCounter < numSubstationEndPoints) {
          totalInstallTime.push(pullInCableSubstationActivity(activities));
        } else {
          totalInstallTime.push(pullInCableTurbineActivity(activities));
        }
        cableCounter += 1;
      }

      totalInstallTime.push(transitActivity(transitTime));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const pullInCableTurbineActivity = (activities: CableActivitiesConfig) => ({
  id: "Pull in cable at turbine",
  duration: activities.pullInCableToTurbine,
  useWindLim: true,
  useWaveLim: true,
});

const pullInCableSubstationActivity = (activities: CableActivitiesConfig) => ({
  id: "Pull in cable at substation",
  duration: activities.pullInCableToSubstation,
  useWindLim: true,
  useWaveLim: true,
});
