import Polygon from "components/MapFeatures/Polygon";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { WindDataSource } from "types/metocean";

const NORA3Border = {
  type: "Feature",
  properties: {
    id: "8ec4408b-67c1-4ce9-a9f2-21463b99c502",
    name: "NORA3",
    color: "#77bb77",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [3.99511887630922, 80.85459588131647],
        [78.64283323963429, 80.98017995181263],
        [71.87143621159893, 76.88889668217325],
        [67.414807646035, 71.25914376567107],
        [63.56572634031741, 60.25029454866569],
        [45.12307433356955, 59.92704328935412],
        [35.246122573605845, 58.5916364042123],
        [27.039015308528622, 56.581854089477396],
        [20.58618249687521, 54.70209811101179],
        [11.889181125413096, 50.51392462409177],
        [1.935726200938603, 44.03464989657522],
        [-5.068741275893132, 47.4627839191023],
        [-13.299862423332456, 50.74701904459786],
        [-21.643174194682654, 52.65839114969289],
        [-30.412134895861243, 54.28694809155948],
        [-26.28225527690512, 63.515298614772576],
        [-23.431012576930616, 67.69205932386913],
        [-20.198256734022024, 70.5212394072181],
        [-14.020799793362272, 74.62420408252413],
        [-7.874362374178304, 77.26595729698687],
        [-1.849189211527749, 79.0876181120176],
        [3.99511887630922, 80.85459588131647],
      ],
    ],
  },
};
const CERRABorder = {
  type: "Feature",
  properties: {
    id: "b9b081bd-e4d0-4e65-a8c9-9a3f12be1ff5",
    name: "CERRA",
    color: "#E1C16E",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-22.041343073884065, 73.57808016076228],
        [-42.8560965902937, 69.72743864409009],
        [-58.467161727601166, 63.86344095009895],
        [-48.22240023124289, 58.973814320564856],
        [-37.896331103961614, 51.993545311370866],
        [-28.789876440532595, 41.998167784524725],
        [-21.198691561342258, 28.990196967886945],
        [-17.503729601798767, 20.039091415637643],
        [-7.196730451491732, 23.410745687314645],
        [7.453469598629653, 24.88944564788133],
        [25.92827939634998, 22.754775902373396],
        [33.05890773932924, 20.40406066449445],
        [41.93978122103971, 38.56109770478551],
        [53.34878656980743, 51.93158294123717],
        [60.99800606500372, 57.56941969303176],
        [71.42652787609086, 62.81292411943534],
        [73.60969746647183, 63.86242541474087],
        [63.62949362473239, 68.3162835196118],
        [51.41413996426988, 71.50559049693047],
        [30.751403334625962, 74.47309299262585],
        [9.799936463874559, 75.41196519459353],
        [-7.782863184521318, 74.90695031866431],
        [-22.041343073884065, 73.57808016076228],
      ],
    ],
  },
};

const ERA5Border = {
  type: "Feature",
  properties: {
    id: "b9b081bd-e4d0-4e65-a8c9-9a3f12123ff5",
    name: "ERA5",
    color: "#6495ED",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-180, 90],
        [-180, -90],
        [180, -90],
        [180, 90],
        [-180, 90],
      ],
    ],
  },
};

const sourceBorder = {
  [WindDataSource.NORA3]: [NORA3Border],
  [WindDataSource.CERRA]: [CERRABorder],
  [WindDataSource.BEST]: [ERA5Border, NORA3Border, CERRABorder],
  [WindDataSource.CUSTOM]: [],
  [WindDataSource.ERA5]: [ERA5Border],
};

const windDataSourceId = "preview-wind-data-source";
const windDataLayerId = "preview-wind-data-layer-id";

export const WindSourceBorder = ({
  selectedDataSource,
  map,
}: {
  selectedDataSource: WindDataSource;
  map: any;
}): any => {
  if (
    ![
      WindDataSource.NORA3,
      WindDataSource.CERRA,
      WindDataSource.ERA5,
      WindDataSource.BEST,
    ].includes(selectedDataSource)
  )
    return <></>;

  const borders = sourceBorder[selectedDataSource] as Feature<
    Geometry,
    GeoJsonProperties
  >[];

  return borders.map((border, index) => (
    <Polygon
      key={index}
      features={[border]}
      sourceId={`${windDataSourceId}-${index}`}
      layerId={`${windDataLayerId}-${index}`}
      color={border && border.properties && border.properties.color}
      map={map}
      opacity={0.4}
      showText={selectedDataSource === WindDataSource.BEST}
    />
  ));
};
