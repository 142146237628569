import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useMemo, useState } from "react";
import { IconBtn } from "components/General/Icons";
import CloseIcon from "@icons/24/Close.svg";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { organisationLibraryLayersAtomFamily } from "../state";
import { HeaderRow, Modal, Title } from "./shared";
import { SearchInput } from "components/General/Input";
import {
  Column as ReactTableColumn,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { DataLibraryLayer } from "components/Organisation/Library/dataLibrary/types";
import { Row } from "components/General/Layout";
import { spacing4, spacing6, spacing8 } from "styles/space";
import Checkbox from "components/General/Checkbox";
import {
  HeaderColumn,
  HiddenIfNotChecked,
  StickyThead,
  Table,
  UserInfo,
} from "components/Organisation/Library/dataLibrary/shared";
import { EditableTextInternalState } from "components/General/EditableText";
import { typography } from "styles/typography";
import { dateToYearDateTime } from "utils/utils";
import { colors } from "styles/colors";
import { ScrollBody } from "hooks/useShowScrollShadow";
import DirectionDownIcon from "@icons/24/DirectionDown.svg?react";
import DirectionUpIcon from "@icons/24/DirectionUp.svg?react";
import { SkeletonText } from "components/Loading/Skeleton";
import Button from "components/General/Button";
import { useDataLibraryLayersCrud } from "components/Organisation/Library/dataLibrary/useDataLibraryLayersCrud";
import { modalTypeOpenAtom } from "state/modal";
import Spinner from "@icons/spinner/Spinner";
import useTextInput from "hooks/useTextInput";
import Fuse from "fuse.js";
import { Mixpanel } from "mixpanel";
import { useAtomValue, useSetAtom } from "jotai";

export const AddLayersFromAllDataListModalType =
  "addLayersFromAllDataListModal" as const;

// UseSortByColumnProps
const columns: Array<ReactTableColumn<DataLibraryLayer>> = [
  {
    id: "name",
    // @ts-ignore
    canSort: true,
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Header: ({ toggleAllSelected, allIsSelected, someIsSelected }) => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
          }}
        >
          <Checkbox
            checked={
              allIsSelected ? true : someIsSelected ? "indeterminate" : false
            }
            onChange={toggleAllSelected}
          />
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row, isSelected, onToggleLayerSelected, onChangeLayerName }) => {
      return (
        <Row
          alignCenter
          style={{
            display: "flex",
            gap: spacing6,
            paddingLeft: spacing8,
          }}
        >
          <HiddenIfNotChecked data-checked={isSelected}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onToggleLayerSelected(row.original.id);
              }}
            />
          </HiddenIfNotChecked>
          <EditableTextInternalState
            value={row.original.name}
            onEnter={(newValue) => {
              onChangeLayerName(row.original.id, newValue);
            }}
            textContainerStyle={{
              flex: 1,
              margin: 0,
              minWidth: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              overflowX: "clip",
              whiteSpace: "nowrap",
              paddingLeft: 0,
            }}
            inputStyle={{
              margin: 0,
              minWidth: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              overflowX: "clip",
              whiteSpace: "nowrap",
              paddingLeft: 0,
            }}
          />
        </Row>
      );
    },
  },
  // {
  //   id: "source",
  //   // @ts-ignore
  //   canSort: true,
  //   width: 100,
  //   Header: <HeaderColumn style={typography.sub2}>Source</HeaderColumn>,
  //   Cell: () => <p>Upload</p>,
  // },
  {
    id: "created_at",
    // @ts-ignore
    canSort: true,
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,
    sortType: (a, b) => {
      return a.original.created_at - b.original.created_at;
    },
    Cell: ({ row }) => (
      <p>
        {row.original.updated_at
          ? dateToYearDateTime(new Date(row.original.updated_at))
          : dateToYearDateTime(new Date(row.original.created_at))}
      </p>
    ),
  },
  {
    id: "author",
    // @ts-ignore
    canSort: true,
    Header: <HeaderColumn>Created by</HeaderColumn>,
    // sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Cell: ({ row, organisationId }) => {
      return (
        <Row alignCenter>
          <UserInfo
            organisationId={organisationId}
            userId={row.original.author}
          />
        </Row>
      );
    },
  },
];

const DataTable = ({
  layers,
  selectedLayerIds,
  setSelectedLayerIds,
}: {
  layers: DataLibraryLayer[];
  selectedLayerIds: string[];
  setSelectedLayerIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const allIsSelected =
    layers.length > 0 && selectedLayerIds.length === layers.length;
  const someIsSelected = selectedLayerIds.length > 0;

  const toggleAllSelected = useCallback(() => {
    setSelectedLayerIds(() => {
      if (allIsSelected) {
        return [];
      }

      return layers.map((p) => p.id);
    });
  }, [setSelectedLayerIds, allIsSelected, layers]);

  const getLayerIsSelected = useCallback(
    (layerId: string) => {
      return selectedLayerIds.includes(layerId);
    },
    [selectedLayerIds],
  );

  const onToggleLayerSelected = useCallback(
    (layerId: string) => {
      setSelectedLayerIds((curr) => {
        if (curr.includes(layerId)) {
          return curr.filter((p) => p !== layerId);
        } else {
          return [...curr, layerId];
        }
      });
    },
    [setSelectedLayerIds],
  );

  const { getTableProps, headerGroups, getTableBodyProps, page, prepareRow } =
    useTable<DataLibraryLayer>(
      {
        data: layers,
        columns: columns,
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetSortBy: false,
        initialState: {
          pageSize: 1000,
          pageIndex: 0,
          sortBy: [
            {
              id: "updated_at",
              desc: true,
            },
            {
              id: "created_at",
              desc: true,
            },
          ],
        },
      },
      useSortBy,
      usePagination,
    );

  return (
    <ScrollBody
      style={{
        flexGrow: 1,
      }}
    >
      <Table
        {...getTableProps({
          style: {
            width: "100%",
            borderSpacing: 0,
          },
        })}
      >
        <StickyThead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      onClick: () => {
                        if (column.canSort) {
                          Mixpanel.track_old(
                            "Sort Add layers from all data list modal",
                            {
                              id: column.id,
                              desc: !column.isSortedDesc,
                            },
                          );
                          column.toggleSortBy(!column.isSortedDesc);
                        }
                      },
                      style: {
                        textAlign: "unset",
                        cursor: column.canSort ? "pointer" : "unset",
                        width: column.width,
                        padding: "0.5rem 0",
                        borderBottom: `1px solid ${colors.blue400}`,
                      },
                    }),
                  )}
                >
                  <Row alignCenter>
                    {column.render("Header", {
                      allIsSelected,
                      toggleAllSelected,
                      someIsSelected,
                    })}
                    {column.canSort && (
                      <span
                        style={{
                          marginLeft: "0.3rem",
                        }}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DirectionDownIcon height={12} width={12} />
                          ) : (
                            <DirectionUpIcon height={12} width={12} />
                          )
                        ) : (
                          <DirectionDownIcon height={12} width={12} />
                        )}
                      </span>
                    )}
                  </Row>
                </th>
              ))}
            </tr>
          ))}
        </StickyThead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isSelected = getLayerIsSelected(row.original.id);

            return (
              <React.Fragment key={row.original.id}>
                <tr
                  {...row.getRowProps({
                    style: {
                      cursor: "pointer",
                      borderSpacing: 0,
                      backgroundColor: isSelected
                        ? colors.surfaceSelectedLight
                        : undefined,
                    },
                  })}
                  onClick={() => {
                    onToggleLayerSelected(row.original.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            padding: `${spacing4} 0`,
                          },
                        })}
                      >
                        <React.Suspense fallback={<SkeletonText />}>
                          {cell.render("Cell", {
                            organisationId,
                            isSelected,
                            selectedLayerIds,
                            onToggleLayerSelected,
                          })}
                        </React.Suspense>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </ScrollBody>
  );
};

const AddLayersFromAllDataListModalInner = ({
  packageId,
}: {
  packageId: string;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [searchValue, onSearchValueChange, setSearchValue] = useTextInput("");
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const layers = useAtomValue(
    organisationLibraryLayersAtomFamily({
      organisationId,
    }),
  );
  const { addLayersToPackage, addingLayersToPackage } =
    useDataLibraryLayersCrud();
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  const fuseLayers = useMemo(
    () =>
      new Fuse(layers, {
        keys: ["name"],
        includeScore: true,
        threshold: 0.3,
      }),
    [layers],
  );

  const layersSearchResult = useMemo<DataLibraryLayer[]>(() => {
    return searchValue.length > 0
      ? fuseLayers.search(searchValue).map((result) => result.item)
      : layers;
  }, [fuseLayers, layers, searchValue]);

  const onAddClick = useCallback(async () => {
    await addLayersToPackage(organisationId, packageId, selectedLayers);
    onClose();
  }, [addLayersToPackage, onClose, organisationId, packageId, selectedLayers]);

  return (
    <FullScreenModal onClick={onClose}>
      <Modal
        style={{
          maxWidth: "1000px",
          width: "80%",
          overflowY: "hidden",
        }}
      >
        <HeaderRow>
          <h2>
            <Title>Add layers from All data</Title>
          </h2>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <SearchInput
          style={{
            width: "100%",
          }}
          placeholder="Search"
          onChange={onSearchValueChange}
          value={searchValue}
          onClear={() => setSearchValue("")}
        />
        <DataTable
          layers={layersSearchResult}
          selectedLayerIds={selectedLayers}
          setSelectedLayerIds={setSelectedLayers}
        />
        <Row
          alignCenter
          style={{
            justifyContent: "flex-end",
          }}
        >
          <p
            style={{
              ...typography.caption,
              color: colors.grey500,
            }}
          >
            {selectedLayers.length} selected layer
            {selectedLayers.length !== 1 ? "s" : ""}
          </p>
          <Button text="Cancel" buttonType="secondary" onClick={onClose} />
          <Button
            text="Add"
            onClick={onAddClick}
            disabled={addingLayersToPackage}
            icon={addingLayersToPackage ? <Spinner size="1rem" /> : undefined}
          />
        </Row>
      </Modal>
    </FullScreenModal>
  );
};

const AddLayersFromAllDataListModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== AddLayersFromAllDataListModalType) {
    return null;
  }

  return (
    <AddLayersFromAllDataListModalInner
      packageId={modalTypeOpen.metadata.packageId}
    />
  );
};

export default AddLayersFromAllDataListModal;
