import { useCallback } from "react";
import { constants } from "@vind-ai/mapbox-gl-draw";
import { editFeaturesAtom, mapControlsAtom } from "../state/map";
import { resetListIfNotAlreadyEmpty } from "../utils/resetList";
import { sendWarning } from "../utils/sentry";
import { currentExternalLayerSelection } from "state/externalLayerSelection";
import { currentSelectionArrayAtom } from "state/selection";
import { useAtomValue, useSetAtom } from "jotai";

const useResetMapControls = () => {
  const mapControls = useAtomValue(mapControlsAtom);
  const setEditFeatures = useSetAtom(editFeaturesAtom);

  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const setExternalLayerSelection = useSetAtom(currentExternalLayerSelection);

  const resetCurrentSelectionArray = useCallback(() => {
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [setCurrentSelectionArray]);

  const resetExternalLayerSelection = useCallback(() => {
    setExternalLayerSelection(resetListIfNotAlreadyEmpty);
  }, [setExternalLayerSelection]);

  return useCallback(() => {
    try {
      mapControls?.changeMode(constants.modes.SIMPLE_SELECT).deleteAll();
      setEditFeatures(resetListIfNotAlreadyEmpty);
      resetCurrentSelectionArray();
      resetExternalLayerSelection();
    } catch (e) {
      sendWarning("failed to reset map controls", {
        e,
      });
    }
  }, [
    mapControls,
    resetCurrentSelectionArray,
    resetExternalLayerSelection,
    setEditFeatures,
  ]);
};

export default useResetMapControls;
