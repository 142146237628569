import { useAtom } from "jotai";
import { Suspense, useEffect } from "react";
import {
  DrawAnchorMenuType,
  DrawCircleMenuType,
  DrawRectangleMenuType,
  DrawLineStringMenuType,
  DrawMooringLineMenuType,
  DrawParkMenuType,
  DrawPointMenuType,
  DrawPolygonMenuType,
  DrawPortMenuType,
  DrawViewPointMenuType,
  DrawExistingTurbineMenuType,
  DrawSensorPointMenuType,
} from "@constants/draw";
import {
  AreaMenuType,
  ConstraintsMenuType,
  AddFeaturesMenuType,
  SiteLocatorMenuType,
} from "@constants/projectMapView";
import {
  AddSubStationMenuType,
  CableFreeZoneMenuType as CableFreeSectorMenuType,
  DrawCableCorridorMenuType,
  DrawCableMenuType,
  DrawExportCableMenuType,
} from "@constants/cabling";
import { TopRightMenuOptions } from "@constants/canvas";
import { DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE } from "@constants/division";
import UploadBottom from "@icons/24/SideBarIcons/UploadBottom.svg";
import Measure from "@icons/24/SideBarIcons/Measure.svg";
import SubAreaIcon from "@icons/24/SideBarIcons/SubArea.svg";
import Constraints from "@icons/24/SideBarIcons/Constraints.svg";
import ParkVector from "@icons/24/SideBarIcons/ParkWithVector.svg";
import ConstraintsVector from "@icons/24/SideBarIcons/ConstraintsWithVector.svg";
import Polygon from "@icons/24/Square.svg";
import Line from "@icons/24/Line.svg";
import Point from "@icons/24/Point.svg";
import Rectangle from "@icons/24/Rectangle.svg";
import Circle from "@icons/24/Circle.svg";
import FileIcon from "@icons/24/File.svg";
import Layers from "@icons/24/Layers.svg";
import Filter from "@icons/24/Filter.svg";
import AddFeature from "@icons/24/SideBarIcons/AddFeature.svg";
import AddFeatureVector from "@icons/24/SideBarIcons/AddWithVector.svg";
import SiteLocator from "@icons/24/SiteLocator2.svg";
import BuildZoneForbidden from "@icons/24/SubtractCircle.svg";
import Bathymetry from "@icons/24/SideBarIcons/Bathymetry.svg";
import FromCoordinates from "@icons/24/FromCoordinates.svg";
import { designToolTypeAtom, mapInteractionSelector } from "../../state/map";
import {
  modalTypeOpenAtom,
  midScreenModalTypeOpenAtom,
} from "../../state/modal";
import { inReadOnlyModeSelector } from "../../state/project";
import { editorAccessProjectSelector } from "../../state/user";
import { MeasureDistanceMode } from "../Distance/Distance";
import { UploadFileType, UploadModalType } from "../UploadModal/UploadModal";
import { replaceOrUndefined } from "./utils";
import { Mixpanel } from "mixpanel";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import {
  Divider,
  IconMenu,
  TypeAreaDot,
  TypeDot,
  TypeLineDot,
} from "components/General/Icons";
import SearchWrapper from "components/Search/SearchWrapper";
import { Menu, MenuItem } from "components/General/Menu";
import { spaceMedium, spaceTiny, spacing4, spacing6 } from "styles/space";
import { LeftSideButtonsContainer } from "./ControlPanel.layout";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import {
  PlaceGoZoneType,
  PlaceNoGoZoneType,
} from "components/DivisionDesign/DivisionConst";
import { parkIdAtom } from "state/pathParams";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { FilterMenuType } from "components/LowerRight/FilterInput";
import { AddDataLayersModalType } from "components/DataLayersModal/DataLayersModal";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import styled from "styled-components";
import { PlaceWindTurbinesMenuType } from "components/GenerateWindTurbines/PlaceWindTurbines";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import Tooltip from "components/General/Tooltip";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { useAtomValue, useSetAtom } from "jotai";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { DesignToolMode } from "types/map";
import { FilterOnshoreMenuType } from "components/LowerRight/FilterOnshoreInput";

const Subtitle = styled.div<{
  disabled?: boolean;
}>`
  ${typography.sub2}
  color: ${colors.seagreen700};
  opacity: ${(p) => (p.disabled ? "0.4" : "1.0")};
  padding: ${spacing6} ${spacing6} ${spacing4} ${spacing6};
`;

/**
 * We need to override colors hard-coded in the .svg of some icons. Since we
 * cannot add child selectors to inline-styling in react, and since we need
 * specificity of the inserted css, we wrap the icons in this component and
 * conditionally apply the style we want.
 */
const OverridePathColorIfOnshore = styled.div<{
  color: string;
}>`
  svg > * {
    fill: ${colors.onElSubArea};
  }
`;

const DrawToolsOnshore = () => {
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const selectedParkId = useAtomValue(parkIdAtom);
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId: selectedParkId ?? "",
      branchId: undefined,
    }),
  );
  const [, setLowerRightActiveMode] = useAtom(lowerRightMenuActiveModeAtom);
  const { showBottomShadow, scrollBodyRef } = useShowScrollShadow();

  useEffect(
    () => () => setLeftMenuActiveMode(undefined),
    [setLeftMenuActiveMode],
  );

  return (
    <>
      {editorAccessProject && !readOnlyMode && (
        <>
          <SearchWrapper
            title="Area"
            tags={[
              "area",
              "draw",
              "feature",
              "park",
              "polygon",
              "sitelocator",
              "coordinates",
            ]}
            id="search-area"
            icon={<ParkVector />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(AreaMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-area"}
              active={
                leftMenuActiveMode === AreaMenuType ||
                leftMenuActiveMode === DrawPointMenuType
              }
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(AreaMenuType))
              }
              title={"Area"}
              icon={<ParkVector />}
            >
              {leftMenuActiveMode === AreaMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    gap: spaceTiny,
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                  }}
                >
                  <Menu
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MenuItem
                      name="Draw park"
                      icon={<Polygon />}
                      id={`button-${DrawPolygonMenuType}`}
                      onClick={() =>
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawParkMenuType),
                        )
                      }
                    />
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Draw sub area"
                        icon={
                          <OverridePathColorIfOnshore
                            color={colors.onElSubArea}
                          >
                            <SubAreaIcon />
                          </OverridePathColorIfOnshore>
                        }
                        id={`button-draw-sub-area`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(PlaceGoZoneType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "sub area",
                          });
                        }}
                      />
                    </Tooltip>
                    <MenuItem
                      name="From file"
                      icon={<FileIcon />}
                      id={`button-area-from-file`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType: UploadFileType.PROJECT_FEATURE,
                            preSelectedFeatureType: PARK_PROPERTY_TYPE,
                          },
                        });
                      }}
                    />
                    <MenuItem
                      name="From coordinates"
                      icon={<FromCoordinates />}
                      id={`button-feature-from-coordinates"`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType:
                              UploadFileType.FEATURE_FROM_COORDINATES,
                          },
                        });
                      }}
                    />
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>

          <SearchWrapper
            title="Constraint"
            tags={["exclusion zone"]}
            id="search-constraints"
            icon={<Constraints />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(ConstraintsMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-constraints"}
              active={
                leftMenuActiveMode === ConstraintsMenuType ||
                leftMenuActiveMode === PlaceNoGoZoneType
              }
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(ConstraintsMenuType))
              }
              title={"Constraint"}
              icon={<ConstraintsVector />}
            >
              {leftMenuActiveMode === ConstraintsMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    gap: spaceTiny,
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                  }}
                >
                  <Menu
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MenuItem
                      name="Draw exclusion zone"
                      icon={<BuildZoneForbidden />}
                      id={`button-draw-exclusion-zone`}
                      onClick={() =>
                        setLeftMenuActiveMode(
                          replaceOrUndefined(PlaceNoGoZoneType),
                        )
                      }
                    />
                    <MenuItem
                      name="Exclusion zone from file"
                      icon={<FileIcon />}
                      id={`button-exclustion-zone-from-file`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType: UploadFileType.PROJECT_FEATURE,
                            preSelectedFeatureType:
                              DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
                          },
                        });
                      }}
                    />

                    <MenuItem
                      name="Browse GIS layers"
                      icon={<Layers />}
                      id={`button-constraint-data-layers`}
                      onClick={() => {
                        setModalTypeOpen({
                          modalType: AddDataLayersModalType,
                        });
                      }}
                    />
                    <MenuItem
                      name="Filter"
                      icon={<Filter />}
                      id={`button-constraint-filter`}
                      onClick={() => {
                        setLowerRightActiveMode(
                          replaceOrUndefined(FilterOnshoreMenuType),
                        );
                        setLeftMenuActiveMode(undefined);
                      }}
                    />
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={turbines.length === 0}
                      text="Select turbines or substations to create cable-free sectors"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable sectors"
                        icon={<Constraints />}
                        id={`button-constraint-cable-free-sectors`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(CableFreeSectorMenuType),
                          );
                        }}
                      />
                    </Tooltip>
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>

          <SearchWrapper
            title="Add elements"
            tags={[
              "gis",
              "feature",
              "map",
              "park",
              "exclusion",
              "inclusion",
              "zone",
              "area",
              "elements",
              "turbine",
              "substation",
              "cable",
              "mooring",
              "anchor",
            ]}
            id="search-draw-polygon"
            icon={<AddFeature />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(AddFeaturesMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-add"}
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              active={[
                AddFeaturesMenuType,
                DrawPolygonMenuType,
                DrawLineStringMenuType,
                DrawPointMenuType,
                DrawCircleMenuType,
                DrawRectangleMenuType,
                PlaceWindTurbinesMenuType,
                AddSubStationMenuType,
                DrawCableMenuType,
                DrawCableCorridorMenuType,
                DrawExistingTurbineMenuType,
                DrawViewPointMenuType,
                DrawPortMenuType,
              ].includes(leftMenuActiveMode ?? "")}
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(AddFeaturesMenuType))
              }
              icon={<AddFeatureVector />}
              title={"Add"}
            >
              {leftMenuActiveMode === AddFeaturesMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                    top: `${spaceTiny}`,
                    boxShadow: showBottomShadow
                      ? "inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08)"
                      : undefined,
                  }}
                >
                  <Menu
                    submenuRef={scrollBodyRef}
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                      background: colors.backgroundDefault,
                      maxHeight: "calc(100vh - 20rem)",
                    }}
                  >
                    <Subtitle>{"Other"}</Subtitle>
                    <MenuItem
                      name="Polygon"
                      icon={<Polygon />}
                      id={`button-draw-polygon`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPolygonMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "polygon",
                        });
                      }}
                    />
                    <MenuItem
                      name="Line"
                      icon={<Line />}
                      id={`button-draw-linestring`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawLineStringMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "linestring",
                        });
                      }}
                    />
                    <MenuItem
                      name="Point"
                      icon={<Point />}
                      id={`button-draw-point`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPointMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "point",
                        });
                      }}
                    />
                    <MenuItem
                      name="Rectangle"
                      icon={<Rectangle />}
                      id={`button-draw-rectangle`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawRectangleMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "rectangle",
                        });
                      }}
                    />

                    <MenuItem
                      name="Circle"
                      icon={<Circle />}
                      id={`button-draw-circle`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawCircleMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "circle",
                        });
                      }}
                    />
                    <Subtitle>{"Turbine"}</Subtitle>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        style={{
                          width: "100%",
                        }}
                        disabled={!selectedParkId}
                        name="Single turbine"
                        icon={<TypeDot dotColor={colors.onElTurbine} />}
                        id={`button-draw-single-turbine`}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(PlaceWindTurbinesMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "turbine",
                          });
                        }}
                      />
                    </Tooltip>
                    <MenuItem
                      name="Existing turbine"
                      icon={<TypeDot dotColor={colors.onElExistingTurbine} />}
                      id={`button-draw-existing-turbine`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawExistingTurbineMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "existing turbine",
                        });
                      }}
                    />
                    <Subtitle disabled={!selectedParkId}>
                      {"Electrical"}
                    </Subtitle>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        style={{
                          width: "100%",
                        }}
                        disabled={!selectedParkId}
                        name="Substation"
                        icon={<TypeDot dotColor={colors.onElSubstation} />}
                        id={`button-draw-substation`}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(AddSubStationMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "substation",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable"
                        icon={<TypeLineDot dotColor={colors.onElCable} />}
                        id={`button-draw-cable`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawCableMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "cable",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Export cable"
                        icon={
                          <TypeLineDot dotColor={colors.exportCableOffshore} />
                        }
                        id={`button-draw-export-cable`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawExportCableMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "export cable",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable corridor"
                        icon={
                          <TypeAreaDot dotColor={colors.onElCableCorridor} />
                        }
                        id={`button-draw-cable-corridor`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawCableCorridorMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "cable corridor",
                          });
                        }}
                      />
                    </Tooltip>

                    <Subtitle>{"More"}</Subtitle>
                    <MenuItem
                      name="View point"
                      icon={<TypeDot dotColor={colors.onElViewpoint} />}
                      id={`button-draw-view-point`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawViewPointMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "view point",
                        });
                      }}
                    />
                    <MenuItem
                      name="Sensor"
                      icon={<TypeDot dotColor={colors.sensor} />}
                      id={`button-draw-sensor-point`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawSensorPointMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "sensor point",
                        });
                      }}
                    />
                    <MenuItem
                      name="Port"
                      icon={<TypeDot dotColor={colors.port} />}
                      id={`button-draw-port`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPortMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "port",
                        });
                      }}
                    />
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>
          <SearchWrapper
            title="Upload"
            tags={["points", "upload", "new", "add", "import", "lat", "lng"]}
            id="search-elements-from-coordinates"
            icon={<UploadBottom />}
            onSelect={() =>
              setMidScreenModalTypeOpen({
                modalType: UploadModalType,
              })
            }
          >
            <SideBarIconBtn
              id={"button-upload"}
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: UploadModalType,
                });
                Mixpanel.track_old("Clicked upload button in left menu", {});
              }}
              title={"Upload"}
              icon={<UploadBottom />}
            ></SideBarIconBtn>
          </SearchWrapper>
        </>
      )}
      <Divider />
      <SearchWrapper
        title="Measure distance"
        id="search-measure-distance"
        tags={["ruler"]}
        icon={<Measure />}
        onSelect={() =>
          setLeftMenuActiveMode(replaceOrUndefined(MeasureDistanceMode))
        }
      >
        <SideBarIconBtn
          id={"button-measure"}
          active={leftMenuActiveMode === MeasureDistanceMode}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(MeasureDistanceMode));
          }}
          disabled={!mapInteraction.leftSideMenuActive}
          title={"Distance"}
          icon={<Measure />}
        ></SideBarIconBtn>
      </SearchWrapper>
    </>
  );
};

const DrawToolsOffshore = () => {
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const selectedParkId = useAtomValue(parkIdAtom);
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId: selectedParkId ?? "",
      branchId: undefined,
    }),
  );
  const [, setLowerRightActiveMode] = useAtom(lowerRightMenuActiveModeAtom);
  const { showBottomShadow, scrollBodyRef } = useShowScrollShadow();

  useEffect(
    () => () => setLeftMenuActiveMode(undefined),
    [setLeftMenuActiveMode],
  );

  return (
    <>
      {editorAccessProject && !readOnlyMode && (
        <>
          <SearchWrapper
            title="Area"
            tags={[
              "area",
              "draw",
              "feature",
              "park",
              "polygon",
              "sitelocator",
              "coordinates",
            ]}
            id="search-area"
            icon={<ParkVector />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(AreaMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-area"}
              active={
                leftMenuActiveMode === AreaMenuType ||
                leftMenuActiveMode === DrawPointMenuType
              }
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(AreaMenuType))
              }
              title={"Area"}
              icon={<ParkVector />}
            >
              {leftMenuActiveMode === AreaMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    gap: spaceTiny,
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                  }}
                >
                  <Menu
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MenuItem
                      name="Draw park"
                      icon={<Polygon />}
                      id={`button-${DrawPolygonMenuType}`}
                      onClick={() =>
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawParkMenuType),
                        )
                      }
                    />
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Draw sub area"
                        icon={<SubAreaIcon />}
                        id={`button-draw-sub-area`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(PlaceGoZoneType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "sub area",
                          });
                        }}
                      />
                    </Tooltip>
                    <MenuItem
                      name="From file"
                      icon={<FileIcon />}
                      id={`button-area-from-file`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType: UploadFileType.PROJECT_FEATURE,
                            preSelectedFeatureType: PARK_PROPERTY_TYPE,
                          },
                        });
                      }}
                    />
                    <MenuItem
                      name="From coordinates"
                      icon={<FromCoordinates />}
                      id={`button-feature-from-coordinates"`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType:
                              UploadFileType.FEATURE_FROM_COORDINATES,
                          },
                        });
                      }}
                    />
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to use site locator"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Site locator"
                        icon={<SiteLocator />}
                        id={`button-site-locator"`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(SiteLocatorMenuType),
                          );
                        }}
                      />
                    </Tooltip>
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>

          <SearchWrapper
            title="Constraint"
            tags={["exclusion zone"]}
            id="search-constraints"
            icon={<Constraints />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(ConstraintsMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-constraints"}
              active={
                leftMenuActiveMode === ConstraintsMenuType ||
                leftMenuActiveMode === PlaceNoGoZoneType
              }
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(ConstraintsMenuType))
              }
              title={"Constraint"}
              icon={<ConstraintsVector />}
            >
              {leftMenuActiveMode === ConstraintsMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    gap: spaceTiny,
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                  }}
                >
                  <Menu
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MenuItem
                      name="Draw exclusion zone"
                      icon={<BuildZoneForbidden />}
                      id={`button-draw-exclusion-zone`}
                      onClick={() =>
                        setLeftMenuActiveMode(
                          replaceOrUndefined(PlaceNoGoZoneType),
                        )
                      }
                    />
                    <MenuItem
                      name="Exclusion zone from file"
                      icon={<FileIcon />}
                      id={`button-exclustion-zone-from-file`}
                      onClick={() => {
                        setMidScreenModalTypeOpen({
                          modalType: UploadModalType,
                          metadata: {
                            preSelectedFileType: UploadFileType.PROJECT_FEATURE,
                            preSelectedFeatureType:
                              DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
                          },
                        });
                      }}
                    />

                    <MenuItem
                      name="Browse GIS layers"
                      icon={<Layers />}
                      id={`button-constraint-data-layers`}
                      onClick={() => {
                        setModalTypeOpen({
                          modalType: AddDataLayersModalType,
                        });
                      }}
                    />
                    <MenuItem
                      name="Filter"
                      icon={<Filter />}
                      id={`button-constraint-filter`}
                      onClick={() => {
                        setLowerRightActiveMode(
                          replaceOrUndefined(FilterMenuType),
                        );
                        setLeftMenuActiveMode(undefined);
                      }}
                    />
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to use bathymetry"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Bathymetry"
                        icon={<Bathymetry />}
                        id={`button-constraint-bathymetry`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setTopRightModeActive(TopRightMenuOptions.bathymetry);
                          setLeftMenuActiveMode(undefined);
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={turbines.length === 0}
                      text="Select turbines or substations to create cable-free sectors"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable sectors"
                        icon={<Constraints />}
                        id={`button-constraint-cable-free-sectors`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(CableFreeSectorMenuType),
                          );
                        }}
                      />
                    </Tooltip>
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>

          <SearchWrapper
            title="Add elements"
            tags={[
              "gis",
              "feature",
              "map",
              "park",
              "exclusion",
              "inclusion",
              "zone",
              "area",
              "elements",
              "turbine",
              "substation",
              "cable",
              "mooring",
              "anchor",
            ]}
            id="search-draw-polygon"
            icon={<AddFeature />}
            onSelect={() =>
              setLeftMenuActiveMode(replaceOrUndefined(AddFeaturesMenuType))
            }
          >
            <SideBarIconBtn
              id={"button-add"}
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              active={[
                AddFeaturesMenuType,
                DrawPolygonMenuType,
                DrawLineStringMenuType,
                DrawPointMenuType,
                DrawCircleMenuType,
                DrawRectangleMenuType,
                PlaceWindTurbinesMenuType,
                AddSubStationMenuType,
                DrawCableMenuType,
                DrawCableCorridorMenuType,
                DrawMooringLineMenuType,
                DrawAnchorMenuType,
                DrawExistingTurbineMenuType,
                DrawViewPointMenuType,
                DrawPortMenuType,
              ].includes(leftMenuActiveMode ?? "")}
              onClick={() =>
                setLeftMenuActiveMode(replaceOrUndefined(AddFeaturesMenuType))
              }
              icon={<AddFeatureVector />}
              title={"Add"}
            >
              {leftMenuActiveMode === AddFeaturesMenuType && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    flexGrow: 1,
                    flexShrink: 0,
                    alignItems: "center",
                    textAlign: "left",
                    left: `calc(var(--side-bars-width) + ${spaceMedium})`,
                    top: `${spaceTiny}`,
                    boxShadow: showBottomShadow
                      ? "inset 0 -6px 12px -4px rgba(51, 51, 51, 0.08)"
                      : undefined,
                  }}
                >
                  <Menu
                    submenuRef={scrollBodyRef}
                    style={{
                      marginTop: `-${spaceTiny}`,
                      whiteSpace: "nowrap",
                      background: colors.backgroundDefault,
                      maxHeight: "calc(100vh - 20rem)",
                    }}
                  >
                    <Subtitle>{"Other"}</Subtitle>
                    <MenuItem
                      name="Polygon"
                      icon={<Polygon />}
                      id={`button-draw-polygon`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPolygonMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "polygon",
                        });
                      }}
                    />
                    <MenuItem
                      name="Line"
                      icon={<Line />}
                      id={`button-draw-linestring`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawLineStringMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "linestring",
                        });
                      }}
                    />
                    <MenuItem
                      name="Point"
                      icon={<Point />}
                      id={`button-draw-point`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPointMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "point",
                        });
                      }}
                    />
                    <MenuItem
                      name="Rectangle"
                      icon={<Rectangle />}
                      id={`button-draw-rectangle`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawRectangleMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "rectangle",
                        });
                      }}
                    />

                    <MenuItem
                      name="Circle"
                      icon={<Circle />}
                      id={`button-draw-circle`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawCircleMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "circle",
                        });
                      }}
                    />
                    <Subtitle>{"Turbine"}</Subtitle>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        style={{
                          width: "100%",
                        }}
                        disabled={!selectedParkId}
                        name="Single turbine"
                        icon={<TypeDot dotColor={colors.turbine} />}
                        id={`button-draw-single-turbine`}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(PlaceWindTurbinesMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "turbine",
                          });
                        }}
                      />
                    </Tooltip>
                    <MenuItem
                      name="Existing turbine"
                      icon={<TypeDot dotColor={colors.existingTurbine} />}
                      id={`button-draw-existing-turbine`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawExistingTurbineMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "existing turbine",
                        });
                      }}
                    />
                    <Subtitle disabled={!selectedParkId}>
                      {"Electrical"}
                    </Subtitle>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        style={{
                          width: "100%",
                        }}
                        disabled={!selectedParkId}
                        name="Substation"
                        icon={<TypeDot dotColor={colors.substation} />}
                        id={`button-draw-substation`}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(AddSubStationMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "substation",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable"
                        icon={<TypeLineDot dotColor={colors.cable} />}
                        id={`button-draw-cable`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawCableMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "cable",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Export cable"
                        icon={
                          <TypeLineDot dotColor={colors.exportCableOffshore} />
                        }
                        id={`button-draw-export-cable`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawExportCableMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "export cable",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Cable corridor"
                        icon={<TypeAreaDot dotColor={colors.cableCorridor} />}
                        id={`button-draw-cable-corridor`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawCableCorridorMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "cable corridor",
                          });
                        }}
                      />
                    </Tooltip>

                    <Subtitle disabled={!selectedParkId}>{"Mooring"}</Subtitle>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Anchor"
                        icon={<TypeDot dotColor={colors.anchor} />}
                        id={`button-draw-anchor`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawAnchorMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "anchor",
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      innerDivStyle={{
                        width: "100%",
                      }}
                      disabled={!!selectedParkId}
                      text="Select a park to add this element"
                    >
                      <MenuItem
                        disabled={!selectedParkId}
                        name="Mooring line"
                        icon={<TypeLineDot dotColor={colors.mooringLine} />}
                        id={`button-draw-mooring-line`}
                        style={{
                          width: "100%",
                        }}
                        onClick={() => {
                          setLeftMenuActiveMode(
                            replaceOrUndefined(DrawMooringLineMenuType),
                          );
                          Mixpanel.track_old("Add element from add menu", {
                            type: "mooring line",
                          });
                        }}
                      />
                    </Tooltip>

                    <Subtitle>{"More"}</Subtitle>
                    <MenuItem
                      name="View point"
                      icon={<TypeDot dotColor={colors.viewpoint} />}
                      id={`button-draw-view-point`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawViewPointMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "view point",
                        });
                      }}
                    />
                    <MenuItem
                      name="Port"
                      icon={<TypeDot dotColor={colors.port} />}
                      id={`button-draw-port`}
                      onClick={() => {
                        setLeftMenuActiveMode(
                          replaceOrUndefined(DrawPortMenuType),
                        );
                        Mixpanel.track_old("Add element from add menu", {
                          type: "port",
                        });
                      }}
                    />
                  </Menu>
                </div>
              )}
            </SideBarIconBtn>
          </SearchWrapper>
          <SearchWrapper
            title="Upload"
            tags={["points", "upload", "new", "add", "import", "lat", "lng"]}
            id="search-elements-from-coordinates"
            icon={<UploadBottom />}
            onSelect={() =>
              setMidScreenModalTypeOpen({
                modalType: UploadModalType,
              })
            }
          >
            <SideBarIconBtn
              id={"button-upload"}
              disabled={readOnlyMode || !mapInteraction.leftSideMenuActive}
              onClick={() => {
                setMidScreenModalTypeOpen({
                  modalType: UploadModalType,
                });
                Mixpanel.track_old("Clicked upload button in left menu", {});
              }}
              title={"Upload"}
              icon={<UploadBottom />}
            ></SideBarIconBtn>
          </SearchWrapper>
        </>
      )}
      <Divider />
      <SearchWrapper
        title="Measure distance"
        id="search-measure-distance"
        tags={["ruler"]}
        icon={<Measure />}
        onSelect={() =>
          setLeftMenuActiveMode(replaceOrUndefined(MeasureDistanceMode))
        }
      >
        <SideBarIconBtn
          id={"button-measure"}
          active={leftMenuActiveMode === MeasureDistanceMode}
          onClick={() => {
            setLeftMenuActiveMode(replaceOrUndefined(MeasureDistanceMode));
          }}
          disabled={!mapInteraction.leftSideMenuActive}
          title={"Distance"}
          icon={<Measure />}
        ></SideBarIconBtn>
      </SearchWrapper>
    </>
  );
};

export const DrawTools = () => {
  const projectType = useAtomValue(designToolTypeAtom);

  return projectType === DesignToolMode.Onshore ? (
    <DrawToolsOnshore />
  ) : (
    <DrawToolsOffshore />
  );
};

const DrawToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    return (
      <LeftSideButtonsContainer>
        <Suspense fallback={null}>
          <div id="tools-container">
            <IconMenu vertical sideBar iconSize="2.4rem">
              <p style={typography.sub2}>Tools</p>
              <DrawTools />
            </IconMenu>
          </div>
        </Suspense>
      </LeftSideButtonsContainer>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default DrawToolsMenuV2;
