import { ExtractAtomValue, useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { Loadable as LoadableJ } from "jotai/vanilla/utils/loadable";
import { useEffect } from "react";
import { Raster } from "types/raster";
import { useJotaiCallback } from "utils/jotai";
import {
  bathymetryFamily,
  bathymetryFetchFamily,
  bathymetrySlopeFamily,
  slopeFetchFamily,
} from "../state/bathymetry";
import {
  SlopeResponseError,
  SlopeResponseWithRaster,
  TileResponseError,
  TileResponseWithRaster,
} from "../types/bathymetryTypes";

/**
 * Fetch a bathymetry tile from the backend.
 * Returns a tuple `[loadable, Raster]` so the user can
 * 1. Detect if we're loading (through the `loadable`)
 * 2. Get the {@link Raster} (if ready).
 */
export const useBathymetry = ({
  featureId,
  bufferKm,
  projectId,
  branchId,
}: {
  featureId: string;
  bufferKm: number | undefined;
  projectId: string | undefined;
  branchId: string | undefined;
}): [
  LoadableJ<ExtractAtomValue<ReturnType<typeof bathymetryFetchFamily>>>,
  Raster | undefined,
  string | undefined,
  string | undefined,
] => {
  const bathymetry = useAtomValue(
    loadable(
      bathymetryFetchFamily({
        featureId,
        bufferKm,
        projectId,
        branchId,
      }),
    ),
  );
  const setter = useJotaiCallback(
    (_, set, update: TileResponseWithRaster | TileResponseError) => {
      set(bathymetryFamily(update.id), Promise.resolve(update));
    },
    [],
  );

  const data = bathymetry.state === "hasData" ? bathymetry.data : undefined;
  useEffect(() => {
    if (data) setter(data);
  }, [data, setter]);

  const raster = data?.status === "finished" ? data.raster : undefined;
  const url = data?.status === "finished" ? data.url : undefined;

  return [bathymetry, raster, data?.id, url];
};

export const useSlopeBathymetry = ({
  featureId,
  bufferKm,
  projectId,
  branchId,
}: {
  featureId: string;
  bufferKm: number | undefined;
  projectId: string | undefined;
  branchId: string | undefined;
}): [
  LoadableJ<ExtractAtomValue<ReturnType<typeof slopeFetchFamily>>>,
  Raster | undefined,
] => {
  const bathymetry = useAtomValue(
    loadable(
      slopeFetchFamily({
        featureId,
        bufferKm,
        projectId,
        branchId,
      }),
    ),
  );
  const setter = useJotaiCallback(
    (_, set, update: SlopeResponseWithRaster | SlopeResponseError) => {
      set(bathymetrySlopeFamily(update.id), Promise.resolve(update));
    },
    [],
  );

  const data = bathymetry.state === "hasData" ? bathymetry.data : undefined;
  useEffect(() => {
    if (data) setter(data);
  }, [data, setter]);

  const raster = data?.status === "finished" ? data.raster : undefined;

  return [bathymetry, raster];
};
