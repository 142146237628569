import { useAtomValue } from "jotai";
import { nodeIdAtom, organisationIdAtom } from "state/pathParams";

const useFolderId = () => {
  const nodeId = useAtomValue(nodeIdAtom);
  const organisationId = useAtomValue(organisationIdAtom);

  return nodeId ?? organisationId;
};

export default useFolderId;
