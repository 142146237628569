import React from "react";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import { AnalysisConfiguration } from "services/configurationService";
import { AnalysisSettingsInner } from "../AnalysisSettings";

const AnalysisSettingCustomAdmin = ErrorBoundaryWrapper(
  ({
    configuration,
    onSave,
    nodeId,
  }: {
    configuration: AnalysisConfiguration;
    onSave: (config: AnalysisConfiguration) => Promise<any>;
    isLoading: boolean;
    nodeId: string;
    readOnly: boolean;
  }) => {
    return (
      <AnalysisSettingsInner
        key={configuration.id}
        configuration={configuration}
        save={onSave}
        isLibraryResource={true}
        nodeId={nodeId}
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default AnalysisSettingCustomAdmin;
