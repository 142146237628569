import { useCallback } from "react";
import { useSetAtom } from "jotai";
import {
  libraryTurbinesRefreshAtom as libraryTurbinesRefreshAtomJ,
  projectTurbinesRefreshAtom as projectTurbinesRefreshAtomJ,
} from "state/jotai/turbineType";

export const useRefreshProjectTurbines = () => {
  const refresh = useSetAtom(projectTurbinesRefreshAtomJ);
  return useCallback(() => {
    refresh((c) => c + 1);
  }, [refresh]);
};

export const useRefreshLibraryTurbines = () => {
  const refresh = useSetAtom(libraryTurbinesRefreshAtomJ);
  return useCallback(() => {
    refresh((c) => c + 1);
  }, [refresh]);
};
