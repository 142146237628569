import { Mixpanel } from "../../mixpanel";

export function trackCanvasOption(
  menuName: string,
  options: Record<string, any>,
) {
  Mixpanel.track_old("Canvas menu click", {
    ...options,
    menuName,
  });
}
