import { contourStepSizeAtom, mapAtom } from "state/map";
import { useEffect, useMemo } from "react";
import { currentMapStyleAtom } from "../state/map";
import mapboxgl, { MercatorCoordinate } from "mapbox-gl";
import { Map as MapboxMap } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { useAtomValue } from "jotai";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { MaybePromise } from "types/utils";
import { wrapMapboxTile } from "types/tile";
import { createGlProgram } from "utils/gl";

const MAP_SOURCE_DEPTH = "bathymetry-source";

const NORMALISATION_FACTOR = 1000;

export const BATHYMETRY_COLORS = {
  default: [
    [0.64 * 255, 0.78 * 255, 0.92 * 255, 255],
    [0.45 * 255, 0.55 * 255, 0.7 * 255, 255],
    [0.38 * 255, 0.47 * 255, 0.6 * 255, 255],
    [0.31 * 255, 0.36 * 255, 0.4 * 255, 255],
    [0.12 * 255, 0.17 * 255, 0.21 * 255, 255],
  ],
};

const makeGlProgram = (gl: WebGLRenderingContext) => {
  const vertexSource = `#version 300 es
precision highp float;
uniform mat4 u_matrix;

in vec2 a_pos;
out vec2 v_pos;

void main() {
  v_pos = a_pos;
  gl_Position = vec4(a_pos, 1.0, 1.0);
}
          `;

  const fragmentSource = `#version 300 es
precision highp float;
uniform sampler2D u_depth_raster;
uniform vec4 u_uv_offsets;
uniform float u_contourDist;

in vec2 v_pos;

layout(location=0) out vec4 outColor;

vec3 depthToColor(float depth) {
  vec3 lightBlue = vec3(0.64, 0.78, 0.92);
  vec3 medlightBlue = vec3(0.45, 0.55, 0.70);
  vec3 mediumBlue = vec3(0.38, 0.47, 0.60);
  vec3 darkBlue = vec3(0.31, 0.36, 0.40);
  vec3 darkestBlue = vec3(0.12, 0.17, 0.21);
  float l1 = 50.0;
  float l2 = 300.0;
  float l3 = 500.0;
  float l4 = 700.0;
  if (depth < l1) {
    float f = depth  / l1;
    return mix(lightBlue, medlightBlue, f);
  } else if (depth < l2) {
    float f = clamp((depth - l1) / (l2 - l1), 0., 1.);
    return mix(medlightBlue, mediumBlue, f);
  } else if (depth < l3) {
    float f = clamp((depth - l2) / (l3 - l2), 0., 1.);
    return mix(mediumBlue, darkBlue, f);
  } else {
    float f = clamp((depth - l3) / (l4 - l3), 0., 1.);
    return mix(darkBlue, darkestBlue, f);
  }
}

// Return 0.0 if we are off the line, 1.0 if we are on the line, and somewhere in between
// if we are somewhere in between.
float depthToContour(float depth, float Step) {
  // Simply checking that 'depth' is a multiple of 'step' will cause
  // thick lines in flat regions and thin lines in steep regions.
  // Instead, get the derivatives to figure out how far off the the pixel
  // is off of the contour line, and color based on that.
  float frac = fract(depth / Step); // 0.0 means on the line. We only have to be close
  float grace = fwidth(depth / Step); // How quickly we move.

  float ret = float(frac <= grace);
  return ret;
}

void main() {
  vec2 uv = 0.5 * v_pos + 0.5;

  uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
  uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

  float STEP = u_contourDist;
  bool u_bilinear = true;
  float depth = texture(u_depth_raster, uv).r * ${NORMALISATION_FACTOR}.;

  float land = step(STEP, depth); // 1.0 if we are in water, 0.0 if we are on land.
  float contour = depthToContour(depth, STEP);

  float depthstep = floor(depth / STEP);
  vec3 areaColor = depthToColor(depthstep * STEP);

  float sign = float(depth < 200.0) * 2.0 - 1.0; // +/- 1.0, positive is shallow.
  float showIso = 1.0;//float(u_bilinear);
  vec3 withIsoLines = areaColor + showIso * sign * contour * areaColor * 0.15;

  outColor = vec4(withIsoLines, 1.0) * land;
}
`;

  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  if (!vertexShader)
    throw scream("Fragment shader failed to compile:", {
      error: gl.getError(),
    });
  gl.shaderSource(vertexShader, vertexSource);
  gl.compileShader(vertexShader);

  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  if (!fragmentShader)
    throw scream("Fragment shader failed to compile:", {
      error: gl.getError(),
    });
  gl.shaderSource(fragmentShader, fragmentSource);
  gl.compileShader(fragmentShader);

  const program = gl.createProgram();
  if (!program) throw scream("Failed to create program");
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS))
    throw scream("Failed to create program", {
      program,
      vertexShader,
      fragmentShader,
      programInfo: gl.getProgramInfoLog(program),
      vertexInfo: gl.getShaderInfoLog(vertexShader),
      fragmentInfo: gl.getShaderInfoLog(fragmentShader),
    });

  const a_pos = gl.getAttribLocation(program, "a_pos");
  if (a_pos === -1) throw scream("Failed to get position location");

  const vertexBuffer = gl.createBuffer();
  if (!vertexBuffer) throw scream("Failed to create vertex buffer");

  const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);

  const u_depth_raster = gl.getUniformLocation(program, "u_depth_raster");
  if (u_depth_raster === null)
    throw scream("Failed to get u_depth_raster location");

  const u_uv_offsets = gl.getUniformLocation(program, "u_uv_offsets");
  if (u_uv_offsets === null)
    throw scream("Failed to get u_uv_offsets location");

  const u_contourDist = gl.getUniformLocation(program, "u_contourDist");
  if (u_contourDist === null)
    throw scream("Failed to get u_contourDist location");

  return {
    program,
    vertexShader,
    fragmentShader,
    loc: {
      a_pos,
      u_depth_raster,
      u_uv_offsets,
      u_contourDist,
    },
    buffer: {
      vertex: vertexBuffer,
    },
  };
};

function makeGlStage1(gl: WebGL2RenderingContext) {
  const vertexSource = `
attribute vec2 a_pos;
varying vec2 v_pos;
uniform vec2 u_minMax;
void main() {
v_pos = vec2(a_pos / 1.);
gl_Position = vec4(a_pos, 1.0, 1.0);
}`;

  const fragmentSource = `
precision highp float;
uniform sampler2D u_depth_raster;
uniform vec2 u_minMax;
varying vec2 v_pos;

float terrariumDepth(sampler2D depth_raster, vec2 texCoord) {
vec4 color = texture2D(depth_raster, texCoord);
float R = color.r * 255.0;
float G = color.g * 255.0;
float B = color.b * 255.0;
return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
}

void main() {
vec2 uv = 0.5 * v_pos + 0.5;
vec4 sample = texture2D(u_depth_raster, uv);
float depth = terrariumDepth(u_depth_raster, uv);
gl_FragColor = vec4(depth / ${NORMALISATION_FACTOR}., 0.0, 0.0, 1.0);
}`;

  const { program, vertexShader, fragmentShader } = createGlProgram(
    gl,
    vertexSource,
    fragmentSource,
  );

  const aPos = gl.getAttribLocation(program, "a_pos");
  const uColor = gl.getUniformLocation(program, "u_color");
  const uDepthRaster = gl.getUniformLocation(program, "u_depth_raster");

  const vertexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]),
    gl.STATIC_DRAW,
  );

  return {
    program,
    vertexShader,
    fragmentShader,
    vertexBuffer,
    loc: {
      aPos,
      uColor,
      uDepthRaster,
    },
  };
}

/**
 * We need to render in two steps to make texture interpolating work.  The
 * depths are RGB encoded, so interpolating in RGB space gives us weird results,
 * for instance when going from [40,0,0] to [39,0,0]. Instead, we create an
 * intermediate texture where the pixels are depths, in which interpolation
 * works just as expected.
 */
function makeGlTwostep(gl: WebGL2RenderingContext, tileSize: number) {
  const positionBuffer = gl.createBuffer();
  if (!positionBuffer) throw scream("Failed to create positionBuffer buffer");
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions = new Float32Array([
    1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1,
  ]);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  const framebuffer = gl.createFramebuffer();
  if (!framebuffer) throw scream("Failed to create framebuffer");
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

  const texture = gl.createTexture();
  if (!texture) throw scream("Failed to create texture");
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    tileSize,
    tileSize,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    null,
  );
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);

  const renderbuffer = gl.createRenderbuffer();
  gl.bindRenderbuffer(gl.RENDERBUFFER, renderbuffer);
  gl.renderbufferStorage(
    gl.RENDERBUFFER,
    gl.DEPTH_COMPONENT16,
    tileSize,
    tileSize,
  );

  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    texture,
    0,
  );
  gl.framebufferRenderbuffer(
    gl.FRAMEBUFFER,
    gl.DEPTH_ATTACHMENT,
    gl.RENDERBUFFER,
    renderbuffer,
  );

  gl.bindTexture(gl.TEXTURE_2D, null);
  gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);

  return {
    positionBuffer,
    framebuffer,
    texture,
    renderbuffer,
  };
}

class DepthContourLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  map: MapboxMap | undefined;
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;
  contourDist: number = 25.0;
  isSubmitted: boolean;

  contour: ReturnType<typeof makeGlProgram> | undefined = undefined;
  stage1: ReturnType<typeof makeGlStage1> | undefined = undefined;
  twostep: ReturnType<typeof makeGlTwostep> | undefined = undefined;

  urlFunc = (z: number, x: number, y: number) =>
    `/tiles/gebco-terrarium-2023/${z}/${x}/${y}.png`;

  constructor(id: string, maxZoom: number, tileSize: number) {
    this.id = id;
    this.type = "custom";
    this.renderingMode = "3d";
    this.textureCache = new Map();
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.isSubmitted = false;
  }

  updateContourDist(newContourDist: number) {
    this.contourDist = newContourDist;
  }

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;
    this.contour = makeGlProgram(gl);
    this.stage1 = makeGlStage1(gl);
    this.twostep = makeGlTwostep(gl, this.tileSize);
  };

  shouldRerenderTiles = () => {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  };

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.map || !this.stage1 || !this.contour || !this.twostep) return;
    const texture = getBestAvailableTexture(
      gl,
      tileId,
      this.textureCache,
      this.map,
      this.urlFunc,
      this.maxZoom,
      this.tileSize,
      false,
      () => this.isSubmitted,
    );

    const prevViewport = gl.getParameter(gl.VIEWPORT) as
      | [number, number, number, number]
      | null;
    const prevFramebuffer = gl.getParameter(
      gl.FRAMEBUFFER_BINDING,
    ) as WebGLFramebuffer | null;
    if (!prevViewport) throw scream("Failed to get viewport");

    gl.viewport(0, 0, this.tileSize, this.tileSize);

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.twostep.framebuffer);

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Step one:  depth-decode the bathymetry tile.
    gl.useProgram(this.stage1.program);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture.texture);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.stage1.vertexBuffer);
    gl.enableVertexAttribArray(this.stage1.loc.aPos);
    gl.vertexAttribPointer(this.stage1.loc.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.uniform1i(this.stage1.loc.uDepthRaster, 0);

    gl.drawArrays(gl.TRIANGLES, 0, 6);

    gl.bindFramebuffer(gl.FRAMEBUFFER, prevFramebuffer);
    gl.viewport(...prevViewport);

    // Step two:  draw contours from the interpolated depths.
    gl.useProgram(this.contour.program);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.contour.buffer.vertex);
    gl.enableVertexAttribArray(this.contour.loc.a_pos);
    gl.vertexAttribPointer(this.contour.loc.a_pos, 2, gl.FLOAT, false, 0, 0);

    gl.uniform1f(this.contour.loc.u_contourDist, this.contourDist);
    gl.uniform4fv(this.contour.loc.u_uv_offsets, texture.uvOffsets);

    // Bind depth raster texture to unit 0
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this.twostep.texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    // Finally render
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  };

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const DepthContour = () => {
  const mapStyle = useAtomValue(currentMapStyleAtom);
  return <> {mapStyle?.useBathymetry && <DepthContourActive />} </>;
};

const DepthContourActive = () => {
  const contourStepSize = useAtomValue(contourStepSizeAtom);
  const map = useAtomValue(mapAtom);

  const depthLayer = useMemo(
    () => new DepthContourLayer(MAP_SOURCE_DEPTH, 7, 512),
    [],
  );

  useEffect(() => {
    if (!map) return;
    depthLayer.updateContourDist(contourStepSize);
    map.triggerRepaint();
  }, [contourStepSize, depthLayer, map]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(depthLayer, "building");
    map.triggerRepaint();

    return () => {
      if (!map) return;
      map.removeLayer(depthLayer.id);
    };
  }, [map, depthLayer]);

  return null;
};

export default DepthContour;
