import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { MIN_CABLE_LENGTH_KM } from "../../state/cable";
import * as turf from "@turf/turf";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { cablesInParkFamily } from "state/jotai/cable";

export const tooShortCablesWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.TooShortCables> | undefined
      >
    >(async (get) => {
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const tooShort = cables.filter(
        (c) => turf.length(c, { units: "kilometers" }) < MIN_CABLE_LENGTH_KM,
      );
      if (tooShort.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.TooShortCables,
        featureIds: tooShort.map((c) => c.id),
        parkId: parkId,
      };
    }),
);
