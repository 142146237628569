import { useMemo } from "react";
import {
  Node,
  isFolderNodeInformation,
  isPersonalFolderNodeInformation,
  isProjectNodeInformation,
} from "services/customerAPI";
import { nodesInOrganisationSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useSearch } from "./useSearch";
import { SearchItem, SearchResultItem } from "../state";
import FolderIcon from "@icons/24/Folder.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { getPathPrefix } from "utils/utils";

const keys = ["name"];

export function useSearchProjects(organisationId: string, searchTerm: string) {
  const navigate = useNavigate();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const { filteredResults, rawData } = useSearch<Node>(
    true,
    searchTerm,
    keys,
    nodesInOrganisationSelectorFamily({ organisationId }),
  );

  const filteredSearchItems: SearchResultItem<Node>[] = useMemo(() => {
    return filteredResults.map((result) => {
      const isFolder =
        isFolderNodeInformation(result.item) ||
        isPersonalFolderNodeInformation(result.item);
      return {
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: isFolder ? <FolderIcon /> : <ProjectIcon />,
        onSelect: () => {
          if (isFolder) {
            setContent({
              type: "project",
              id: result.item.id,
            });
            navigate(
              `/organisation/${organisationId}/projects/${result.item.id}`,
            );
          } else {
            const url = `/${getPathPrefix(result.item)}/project/${organisationId}/${result.item.id}`;

            // if already in project page, do complete navigation, if not use react-router navigate
            // If not there is a lot of mess with mapbox clean up
            if (window.location.pathname.includes("/project")) {
              window.location.href = url;
            } else {
              navigate(url);
            }
          }
        },
        secondarySelect: isProjectNodeInformation(result.item)
          ? {
              name: "View in folder",
              onSelect: () => {
                setContent({
                  type: "project",
                  id: result.item.id,
                });
                const path =
                  result.item.parent_id === organisationId
                    ? `/organisation/${organisationId}/projects`
                    : `/organisation/${organisationId}/projects/${result.item.parent_id}`;
                navigate(path);
              },
            }
          : undefined,
      };
    });
  }, [filteredResults, navigate, organisationId, setContent]);

  const searchableItems: SearchItem<Node>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => {
      const isFolder =
        isFolderNodeInformation(item) || isPersonalFolderNodeInformation(item);
      return {
        id: item.id,
        name: item.name,
        item: item,
        icon: isFolder ? <FolderIcon /> : <ProjectIcon />,
        onSelect: () => {
          if (isFolder) {
            setContent({
              type: "project",
              id: item.id,
            });
            navigate(`/organisation/${organisationId}/projects/${item.id}`);
          } else {
            const url = `/${getPathPrefix(item)}/project/${organisationId}/${item.id}`;

            // if already in project page, do complete navigation, if not use react-router navigate
            // If not there is a lot of mess with mapbox clean up
            if (window.location.pathname.includes("/project")) {
              window.location.href = url;
            } else {
              navigate(url);
            }
          }
        },
        secondarySelect: isProjectNodeInformation(item)
          ? {
              name: "View in folder",
              onSelect: () => {
                setContent({
                  type: "project",
                  id: item.id,
                });
                const path =
                  item.parent_id === organisationId
                    ? `/organisation/${organisationId}/projects`
                    : `/organisation/${organisationId}/projects/${item.parent_id}`;
                navigate(path);
              },
            }
          : undefined,
      };
    });
  }, [rawData, navigate, organisationId, setContent]);

  return {
    filteredNodes: filteredSearchItems,
    searchableItems,
  };
}
