import { useAtomValue } from "jotai";
import React from "react";
import { modalTypeOpenAtom } from "state/modal";
import CompareAltIcon from "@icons/14/CompareAlt.svg?react";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import { CompareParksModalType } from "components/CompareParksModal/CompareParksModalType";
import SearchWrapper from "components/Search/SearchWrapper";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { colors } from "styles/colors";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { Mixpanel } from "mixpanel";
import { useAtom, useSetAtom } from "jotai";

const CompareParksModalButtonV2 = () => {
  const [modalTypeOpen, setModalType] = useAtom(modalTypeOpenAtom);
  const setOpenInNewWindow = useSetAtom(openInNewWindowAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);
  const trackEvent = useTrackEvent();

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    middleware: [
      offset({
        mainAxis: 40,
      }),
    ],
  });
  return (
    <SearchWrapper
      title="Compare parks"
      id="search-compare"
      tags={["alternative", "scenario", "base case", "presentation"]}
      icon={<CompareAltIcon />}
      onSelect={() => {
        if (readOnlyMode) {
          return;
        }

        setModalType({
          modalType: CompareParksModalType,
        }),
          Mixpanel.track_old("Open full screen modal", {
            type: CompareParksModalType,
          });
      }}
    >
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="general-intro-tour"
          stepId="compare"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="onshore-intro-tour"
          stepId="compare"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <HighlightStep
        tourId="general-intro-tour"
        stepId="compare"
        color={colors.blue100}
      >
        <HighlightStep
          tourId="onshore-intro-tour"
          stepId="compare"
          color={colors.blue100}
        >
          <Tooltip
            theme="light"
            id="compare-parks-button"
            text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Compare parks"}
            disabled={!readOnlyMode}
            position="bottom"
          >
            <MiddleItemWrapper
              className="middle-item-wrapper"
              active={modalTypeOpen?.modalType === CompareParksModalType}
              disabled={readOnlyMode}
              ref={refs.setReference}
              onClick={() => {
                if (readOnlyMode) {
                  return;
                }
                setModalType((curr) =>
                  curr?.modalType !== CompareParksModalType
                    ? {
                        modalType: CompareParksModalType,
                      }
                    : undefined,
                );
                setOpenInNewWindow((curr) => ({
                  ...curr,
                  [CompareParksModalType]: false,
                }));
                trackEvent("clickedCompare");
                Mixpanel.track_old("Open full screen modal", {
                  type: CompareParksModalType,
                });
              }}
            >
              <RoundIcon fillOrStroke="fill">
                <CompareAltIcon />
              </RoundIcon>
              Compare
            </MiddleItemWrapper>
          </Tooltip>
        </HighlightStep>
      </HighlightStep>
    </SearchWrapper>
  );
};

export default CompareParksModalButtonV2;
