import { useAtomValue, useSetAtom } from "jotai";
import { mapAtom } from "state/map";
import { Map, MapLayerMouseEvent } from "mapbox-gl";
import { useCallback } from "react";
import { SetterOrUpdater } from "types/utils";
import { currentSelectionWMSAtom, WMSSelection } from "../state/selection";
import { ExternalDataSourceLinkLayerWithSourceWMS } from "../types/layers";
import {
  getDistanceFromLatLonInM,
  wgs84ToPsuedoMercator,
} from "../utils/proj4";
import { appendQueryParamsSign } from "../utils/utils";
import { addCorsAndCacheProxyURL } from "../state/gisSourceCorsProxy";

const getQueryURL = (
  layer: ExternalDataSourceLinkLayerWithSourceWMS,
  bboxQueryPsuedoMercator: number[][],
) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;
  return encodeURI(
    `${addCorsAndCacheProxyURL(layer.sourceLink.url, isPrivate)}${appendQueryParamsSign(layer.sourceLink.url)}request=GetFeatureInfo&service=WMS&version=1.1.1&styles=&layers=${layer.sourceLayerId}&srs=EPSG:3857&bbox=${bboxQueryPsuedoMercator.join(",")}&width=256&height=256&format=image/png&query_layers=${layer.sourceLayerId}&info_format=text/html&x=128&y=128`,
  );
};

const checkWMSInfo = (
  map: Map,
  e: MapLayerMouseEvent,
  setCurrentSelectionWMS: SetterOrUpdater<WMSSelection[]>,
  layers: ExternalDataSourceLinkLayerWithSourceWMS[],
) => {
  const { lngLat } = e;
  const coord = [lngLat.lng, lngLat.lat];

  const mapBounds = map.getBounds();
  if (!mapBounds) return;
  const diagonalBounds = getDistanceFromLatLonInM(
    [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
    [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat],
  );
  const bboxBuffer = Math.round(diagonalBounds * 0.05);
  const pseudoMercator = wgs84ToPsuedoMercator(coord);
  const bboxQueryPsuedoMercator = [
    [pseudoMercator[0] - bboxBuffer, pseudoMercator[1] - bboxBuffer],
    [pseudoMercator[0] + bboxBuffer, pseudoMercator[1] + bboxBuffer],
  ];

  const newSelections = layers.map((layer) => {
    const isPrivate =
      "private" in layer.source ? layer.source["private"] : false;
    const queryUrl = getQueryURL(layer, bboxQueryPsuedoMercator);

    const legendGraphicUrl = `${addCorsAndCacheProxyURL(layer.sourceLink.url, isPrivate)}?service=WMS&version=1.1.1&request=GetLegendGraphic&format=image/png&layer=${layer.sourceLayerId}`;

    return {
      type: layer.name,
      url: queryUrl,
      legendGraphicUrl,
    };
  });

  setCurrentSelectionWMS((selections) => [
    ...selections.filter(
      (s) => !newSelections.map((ns) => ns.type).includes(s.type),
    ),
    ...newSelections,
  ]);
};

export const useCheckWMSInfo = (
  selectedDynamicWMSLayers: ExternalDataSourceLinkLayerWithSourceWMS[],
) => {
  const map = useAtomValue(mapAtom);
  const setCurrentSelectionWMS = useSetAtom(currentSelectionWMSAtom);

  return useCallback(
    (e: MapLayerMouseEvent) => {
      if (!map) return;
      checkWMSInfo(map, e, setCurrentSelectionWMS, selectedDynamicWMSLayers);
    },
    [map, setCurrentSelectionWMS, selectedDynamicWMSLayers],
  );
};
