import { InputDimensioned } from "components/General/Input";
import { Label } from "components/General/Form";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { between } from "utils/validations";
import { ReferenceNumbersProps } from "./ReferenceNumberProps";

const ReferenceRotorDiameter = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceNumbersProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Reference rotor diameter</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          validate={between(50, 350)}
          validationMessage={`Needs to be within 50 - 350 m`}
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "rotorDiameter",
            commentCategory,
          )}
          step="1"
          unit="m"
          type="number"
          value={tempFoundation.rotorDiameter}
          initialValue={originalFoundation?.rotorDiameter}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              rotorDiameter: d,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default ReferenceRotorDiameter;
