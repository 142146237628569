import DescriptionModal from "components/ConfigurationModal/DescriptionModal";
import { MenuItem } from "components/General/Menu";
import { DotMenu } from "components/General/MenuButton";
import { CommentAndDotMenuWrapper } from "../../style";
import { ResourceCrudOperations, ResourceMap } from "../types";
import ChatIcon from "@icons/24/Chat.svg";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";

interface ResourceActionsProps<T extends keyof ResourceMap> {
  openDescriptionId?: string;
  setOpenDescriptionId: (id: string | undefined) => void;
  resource: ResourceMap[T];
  libraryManageRole: T;
  remove: ResourceCrudOperations<T>["remove"];
  duplicate: ResourceCrudOperations<T>["duplicate"];
  setDeleteInProgress?: (id?: string) => void;
  isLoading?: boolean;
  getDescription: (resource: ResourceMap[T]) => string | undefined | null;
  updateDescription: (
    resource: ResourceMap[T],
    newDescription: string,
  ) => Promise<ResourceMap[T]>;
}

const ResourceActions = <T extends keyof ResourceMap>({
  openDescriptionId,
  setOpenDescriptionId,
  resource,
  libraryManageRole,
  remove,
  duplicate,
  setDeleteInProgress,
  isLoading,
  getDescription,
  updateDescription,
}: ResourceActionsProps<T>) => {
  const { deleteResource } = useOrgFolderStructureCrud({
    libraryManageRole,
  });

  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const handleDelete = async () => {
    setDeleteInProgress?.(resource.id);
    await remove(resource.id);
    await deleteResource(resource.id);
    setDeleteInProgress?.(undefined);
  };

  return (
    <CommentAndDotMenuWrapper>
      <DescriptionModal
        size="small"
        defaultValue={getDescription(resource) ?? ""}
        updateDescription={(newDescription) => {
          updateDescription(resource, newDescription);
        }}
        close={() => setOpenDescriptionId(undefined)}
        disabled={isLoading}
        subtitle={
          <div>
            <p>
              The description will be visible for Admins and Editors in projects
              with access to this configuration.
            </p>
          </div>
        }
        editByDefault={openDescriptionId === resource.id}
      />

      <DotMenu>
        <MenuItem
          onClick={() => {
            setOpenDescriptionId(resource.id);
          }}
          name={
            getDescription(resource) ? "Edit description" : "Add description"
          }
          icon={<ChatIcon />}
        />
        <MenuItem
          onClick={() => {
            duplicate({
              resource,
              onSuccess: (newResource) => {
                setContent({
                  type: "resource",
                  id: newResource.id,
                });
              },
            });
          }}
          name={"Duplicate"}
          icon={<DuplicateIcon />}
        />
        <MenuItem onClick={handleDelete} name={"Delete"} icon={<Trashcan />} />
      </DotMenu>
    </CommentAndDotMenuWrapper>
  );
};

export default ResourceActions;
