import LibraryResourceActions from "../../Components/LibraryResourceActions";
import { CableType } from "services/cableTypeService";
import { SettingsFormActions } from "components/ConfigurationModal/Components/SettingsFormActions";

const ProjectContextActions = ({
  editedCable,
  originalCable,
  nodeId,
  organisationId,
  isLibraryCableInProjectView,
  orgCableManageAccess,
  allChangesSaved,
  onSave,
  setCable,
  duplicateToProject,
  isLoading,
}: {
  editedCable: CableType;
  originalCable: CableType;
  nodeId: string;
  organisationId: string;
  isLibraryCableInProjectView: boolean | undefined;
  orgCableManageAccess: boolean;
  allChangesSaved: boolean;
  onSave?: (cable: CableType, onlyUpdatingDescription?: boolean) => void;
  setCable: (cable: CableType) => void;
  duplicateToProject: () => Promise<void>;
  isLoading: boolean;
}) => {
  return (
    <>
      {isLibraryCableInProjectView && orgCableManageAccess && (
        <LibraryResourceActions
          resourceType="cable"
          resourceId={originalCable.id}
          nodeId={nodeId}
          organisationId={organisationId ?? ""}
          onDuplicate={duplicateToProject}
        />
      )}

      {!allChangesSaved && !isLibraryCableInProjectView && (
        <SettingsFormActions
          hasChanges={!allChangesSaved}
          isLoading={isLoading}
          onSave={() => onSave?.(editedCable)}
          onCancel={() => setCable(originalCable)}
        />
      )}
    </>
  );
};

export default ProjectContextActions;
