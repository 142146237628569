import AblySyncDataLibrary from "components/Ably/ChannelProviders/Organisation/OrganisationAll/AblySyncDataLibrary";
import ChannelProviderWrapper from "../../ChannelProviderWrapper";
import { Suspense } from "react";
import { AblyMonitorSystem } from "components/Ably/AblyMonitorSystem";
import useAblyPortfolio from "hooks/useAblyPortfolio";
import { useAblyNodes } from "./useAblyNodes";
import AblyOrgFolderStructure from "./AblyOrgFolderStructure";

export default function OrganisationAllProvider({
  organisationId,
  ablyId,
}: {
  organisationId: string;
  ablyId: string;
}) {
  return (
    <>
      <ChannelProviderWrapper
        channelName={`${organisationId}:all`}
        ablyId={ablyId}
      >
        <OrganisationAllConsumer organisationId={organisationId} />
      </ChannelProviderWrapper>
    </>
  );
}

function OrganisationAllConsumer({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyPortfolio(organisationId);
  useAblyNodes(organisationId);

  return (
    <>
      <Suspense fallback={null}>
        <AblySyncDataLibrary organisationId={organisationId} />
        <AblyMonitorSystem clientId={organisationId} />
        <AblyOrgFolderStructure organisationId={organisationId} />
      </Suspense>
    </>
  );
}
