import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import * as spec from "api/configurations";
import { _SubstationIdentifier, SubstationIdentifier } from "types/substation";
import { scream } from "utils/sentry";

export const _SubstationType = spec.components.schemas.SubstationType;
export type SubstationType = z.infer<typeof _SubstationType>;

const TYPE_DEFAULT = "offshore";
const IMPEDANCE_DEFAULT = 0.13;
const RESISTANCE_DEFAULT = 0.003;
const IRON_LOSSES_DEFAULT = 0.00025;
const NO_LOAD_CURRENT_DEFAULT = 0.0015;
const REACTOR_DEFAULT = true;
export const COMPENSATION_SHARE_DEFAULT = 0.5;
const DC_RESISTANCE_DEFAULT = 0.003;
const DC_IRON_LOSSES_DEFAULT = 0.00025;
const LOSS_FACTOR_A_DEFAULT = 0.00035;
const LOSS_FACTOR_B_DEFAULT = 0.004;
const LOSS_FACTOR_C_DEFAULT = 0.0027;

export const DEFAULT_SUBSTATION: Partial<SubstationType> = {
  type: TYPE_DEFAULT,
  impedance: IMPEDANCE_DEFAULT,
  resistance: RESISTANCE_DEFAULT,
  ironLosses: IRON_LOSSES_DEFAULT,
  noLoadCurrent: NO_LOAD_CURRENT_DEFAULT,
  reactor: REACTOR_DEFAULT,
  compensationShare: COMPENSATION_SHARE_DEFAULT,
  dcResistance: DC_RESISTANCE_DEFAULT,
  dcIronLosses: DC_IRON_LOSSES_DEFAULT,
  lossFactorA: LOSS_FACTOR_A_DEFAULT,
  lossFactorB: LOSS_FACTOR_B_DEFAULT,
  lossFactorC: LOSS_FACTOR_C_DEFAULT,
};

export async function getSubstationTypes(nodeId: string, version?: number) {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}${version ? `?version=${version}` : ""}`,
    {
      method: "get",
    },
  );
}

export async function createSubstationType(
  nodeId: string,
  substationType: Partial<SubstationType>,
): Promise<SubstationType> {
  return fetchEnhancerWithToken(`/api/substation/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(substationType),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    return _SubstationType.parse(j);
  });
}
export async function updateSubstationType(
  nodeId: string,
  substationType: z.infer<
    (typeof spec)["paths"]["/api/substation/node/{nodeId}/type/{substationId}"]["post"]["requestBody"]["application/json"]
  >,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}/type/${substationType.id}`,
    {
      method: "post",
      body: JSON.stringify(substationType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to update");
  });
}

export async function deleteSubstationType(
  nodeId: string,
  substationTypeId: string,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/substation/node/${nodeId}/type/${substationTypeId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to delete");
  });
}

// -------- Organisation substation ------------------------

export async function createNewOrgSubstation(
  nodeId: string,
  substationType: Partial<SubstationType>,
  projectAccess?: string[],
): Promise<SubstationType> {
  const body = {
    substation: substationType,
    ...(projectAccess ? { projectAccess } : {}),
  };

  return fetchEnhancerWithToken(`/api/substation/organisation/${nodeId}`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    return _SubstationType.parse(j);
  });
}

export async function updateOrgSubstation(
  nodeId: string,
  substationType: SubstationType,
) {
  return fetchEnhancerWithToken(
    `/api/substation/organisation/${nodeId}/type/${substationType.id}`,
    {
      method: "put",
      body: JSON.stringify(substationType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then(async (res) => {
    if (!res.ok) throw new Error("Failed to update");
    return _SubstationType.parse(await res.json());
  });
}

export async function deleteOrgSubstation(
  nodeId: string,
  substationId: string,
) {
  return fetchEnhancerWithToken(
    `/api/substation/organisation/${nodeId}/type/${substationId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).catch((e) => {
    scream("deleteOrgSubstation failed", {
      e,
      nodeId,
      substationId,
    });
    throw e;
  });
}

export async function listOrgSubstations(nodeId: string) {
  const res = await fetchEnhancerWithToken(
    `/api/substation/organisation/${nodeId}`,
    {
      method: "get",
    },
  );
  const substations = await res.json();
  try {
    return z.array(_SubstationType).parse(substations);
  } catch (e) {
    scream("failed to parse substations", {
      e,
      substations,
      res,
    });
  }
}

export async function getSubstationIdentifiers(
  nodeId: string,
): Promise<SubstationIdentifier[]> {
  const res = await fetchEnhancerWithToken(
    `/api/substation/organisation/${nodeId}/identifiers`,
    {
      method: "get",
    },
  );
  const identifiers = await res.json();
  try {
    return z.array(_SubstationIdentifier).parse(identifiers);
  } catch (e) {
    scream("failed to parse substation identifiers", {
      e,
      identifiers,
      res,
    });
    throw e;
  }
}
