import { atom } from "jotai";
import { TopRightMenuOptions } from "@constants/canvas";

export type TopRightModeType =
  | undefined
  | (typeof TopRightMenuOptions)[keyof typeof TopRightMenuOptions];

export const TopRightModeActiveAtom = atom<TopRightModeType>(undefined);

export const configurationMenuActiveAtom = atom<boolean>(false);
