import { Atom, atom } from "jotai";
import {
  getForumThreads,
  getReplyReactions,
  getThreadReactions,
  getThreadReplies,
  getUserReactions,
  getUserThreadFollows,
} from "./service";
import { Thread, Reaction } from "./types";
import { z } from "zod";
import { AtomFamily } from "jotai/vanilla/utils/atomFamily";
import { atomFamily, atomFromFn, atomLocalStorage } from "utils/jotai";
import { featureMapFamily } from "state/jotai/features";

export const newThreadAtomFamily = atomFamily((_: { projectId: string }) =>
  atom<{ featureId: string; mapPosition?: [number, number] } | undefined>(
    undefined,
  ),
);

export const selectedThreadAtomFamily = atomFamily(
  (_: { nodeId: string | undefined }) => atom<string | undefined>(undefined),
);

export const threadsAtomFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string | undefined; branchId: string }) =>
    atomFromFn(async () => {
      if (!nodeId) return [];
      return await getForumThreads(nodeId, branchId).then((threads) =>
        threads.sort((a, b) => a.threadTimestamp - b.threadTimestamp),
      );
    }),
);

export const filteredThreadsSelectorFamily: AtomFamily<
  { nodeId: string; branchId: string },
  Atom<
    Promise<{
      threads: Thread[];
      resolvedThreads: Thread[];
      resolvedAndNotResolvedThreads: Thread[];
      deletedFeatureThreads: Thread[];
    }>
  >
> = atomFamily(({ nodeId, branchId }) =>
  atom(async (get) => {
    const featureMap = await get(featureMapFamily({ branchId }));
    const threads = await get(threadsAtomFamily({ nodeId, branchId }));

    const { resolved, resolvedAndNotResolvedThreads, notResolved, deleted } =
      threads.reduce<{
        resolved: Thread[];
        resolvedAndNotResolvedThreads: Thread[];
        notResolved: Thread[];
        deleted: Thread[];
      }>(
        (pre, thread) => {
          const isDeleted =
            thread.featureId && !featureMap.has(thread.featureId);
          if (!isDeleted) {
            pre.resolvedAndNotResolvedThreads.push(thread);
          }

          if (isDeleted) {
            pre.deleted.push(thread);
          } else if (thread.resolved) {
            pre.resolved.push(thread);
          } else {
            pre.notResolved.push(thread);
          }
          return pre;
        },
        {
          resolved: [],
          notResolved: [],
          deleted: [],
          resolvedAndNotResolvedThreads: [],
        },
      );
    return {
      threads: notResolved,
      resolvedThreads: resolved,
      resolvedAndNotResolvedThreads,
      deletedFeatureThreads: deleted,
    };
  }),
);

export const showResolvedCommentsAtom = atom<boolean>(false);

export const showOnlyUsersOwnCommentsAtom = atom<boolean>(false);

export const showUnreadCommentsAtom = atom<boolean>(false);

export const replySaveInProgressState = atom<
  { threadId: string; message: string } | undefined
>(undefined);

export const threadRepliesAtomFamily = atomFamily(
  ({
    nodeId,
    branchId,
    threadId,
  }: {
    nodeId: string;
    branchId: string;
    threadId: string;
  }) => atomFromFn(() => getThreadReplies(nodeId, branchId, threadId)),
);

export const threadReactionsCounterAtomFamily = atomFamily(
  (_: { nodeId: string; branchId: string; threadId: string }) =>
    atom<number>(0),
);

export const threadReactionsAtomFamily: AtomFamily<
  { nodeId: string; branchId: string; threadId: string },
  Atom<Promise<Reaction[]>>
> = atomFamily(({ nodeId, branchId, threadId }) =>
  atom((get) => {
    get(
      threadReactionsCounterAtomFamily({
        nodeId,
        branchId,
        threadId,
      }),
    );
    return getThreadReactions(nodeId, branchId, threadId);
  }),
);

export const replyReactionsCounterAtomFamily = atomFamily(
  (_: {
    nodeId: string;
    branchId: string;
    threadId: string;
    replyId: string;
  }) => atom(0),
);

export const replyReactionsAtomFamily: AtomFamily<
  {
    nodeId: string;
    branchId: string;
    threadId: string;
    replyId: string;
  },
  Atom<Promise<Reaction[]>>
> = atomFamily(({ nodeId, branchId, threadId, replyId }) =>
  atom((get) => {
    get(
      replyReactionsCounterAtomFamily({
        nodeId,
        branchId,
        threadId,
        replyId,
      }),
    );
    return getReplyReactions(nodeId, branchId, threadId, replyId);
  }),
);

// replyId or threadId
export const userReactionSaveInProgressState = atom<string | undefined>(
  undefined,
);

export const userReactionsAtomFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string; branchId: string }) =>
    atomFromFn(() => getUserReactions(nodeId, branchId)),
);

export const followThreadsAtomFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string; branchId: string }) =>
    atomFromFn(() => getUserThreadFollows(nodeId, branchId)),
);

/** Checks if the logged in user follows the given thread. */
export const followsThreadAtom = atomFamily(
  ({
    nodeId,
    branchId,
    threadId,
  }: {
    nodeId: string;
    branchId: string;
    threadId: string;
  }) =>
    atom(async (get) => {
      const follows = await get(followThreadsAtomFamily({ nodeId, branchId }));
      return follows.find((f) => f.threadId === threadId) !== undefined;
    }),
);

export const showCommentIconsInMapState = atomLocalStorage<boolean>(
  "vind:comments:show-in-map",
  true,
  z.boolean(),
);
