/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const ErrorResponse = z
      .object({
        message: z.string(),
      })
      .strict();
    export const Stage = z
      .object({
        id: z.string(),
        name: z.string(),
        color: z.string(),
      })
      .passthrough();
    export const Tag = z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    ErrorResponse: Components.Schemas.ErrorResponse,
    Stage: Components.Schemas.Stage,
    Tag: Components.Schemas.Tag,
  },
};

export const paths = {
  "/api/organisation-settings/organisation/{organisationId}/stages": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Stages retrieved */
          "application/json": z
            .object({
              stages: z.array(Components.Schemas.Stage).optional(),
            })
            .passthrough(),
        },
        400: {
          /** Invalid request */
          "application/json": Components.Schemas.ErrorResponse,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
    put: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            stages: z.array(Components.Schemas.Stage),
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Stages updated */
          "application/json": z
            .object({
              stages: z.array(Components.Schemas.Stage).optional(),
            })
            .passthrough(),
        },
        400: {
          /** Invalid request */
          "application/json": Components.Schemas.ErrorResponse,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/api/organisation-settings/organisation/{organisationId}/tags": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Tags retrieved */
          "application/json": z
            .object({
              tags: z.array(Components.Schemas.Tag).optional(),
            })
            .passthrough(),
        },
        400: {
          /** Invalid request */
          "application/json": Components.Schemas.ErrorResponse,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
    put: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            tags: z.array(Components.Schemas.Tag),
          })
          .passthrough(),
      },
      responses: {
        200: {
          /** Tags updated */
          "application/json": z
            .object({
              tags: z.array(Components.Schemas.Tag).optional(),
            })
            .passthrough(),
        },
        400: {
          /** Invalid request */
          "application/json": Components.Schemas.ErrorResponse,
        },
        500: {
          /** Internal server error */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
