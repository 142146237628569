import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { userNodesAccessSyncAtom } from "state/user";
import { fetchUserAllNodesAccess } from "services/userService";

const refreshUserAccessAtom = atom(null, async (_, set) => {
  set(userNodesAccessSyncAtom, (curr) => ({
    ...curr,
    loading: true,
  }));

  const access = await fetchUserAllNodesAccess();

  set(userNodesAccessSyncAtom, {
    data: access,
    loading: false,
    initialised: true,
  });
});

const initialiseUserAccessAtom = atom(null, async (get, set) => {
  const state = get(userNodesAccessSyncAtom);
  if (state.initialised || state.loading) return;
  set(refreshUserAccessAtom);
});

export function useUserAccessState() {
  const [userAccess, setUserAccess] = useAtom(userNodesAccessSyncAtom);
  const initialiseStoredNodes = useSetAtom(initialiseUserAccessAtom);
  const refresh = useSetAtom(refreshUserAccessAtom);

  useEffect(() => {
    initialiseStoredNodes();
  }, [initialiseStoredNodes]);

  return {
    data: userAccess.data,
    setState: setUserAccess,
    loading: userAccess.loading,
    initialised: userAccess.initialised,
    refresh,
  };
}
