import { useSetAtom } from "jotai";
import { mapAtom } from "state/map";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { editFeaturesAtom, mapInteractionSelector } from "../../state/map";
import { currentSelectionArrayAtom } from "../../state/selection";
import { modalTypeOpenAtom } from "state/modal";
import AddCircle from "@icons/24/AddCircle.svg?react";
import Pen from "@icons/24/Pencil.svg?react";
import Bin from "@icons/24/Bin.svg?react";
import EditNamesIcon from "@icons/24/EditNames.svg";
import Splitter from "@icons/splitter/splitter.svg?react";
import Combine from "@icons/24/Union.svg?react";
import Cabling from "@icons/24/Cabling.svg?react";
import Intersect from "@icons/24/Intersect.svg?react";
import Difference from "@icons/24/Difference.svg?react";
import DnDIconSmall from "@icons/12/DnDsmall.svg?react";
import { editorAccessProjectSelector } from "../../state/user";
import { inReadOnlyModeSelector } from "../../state/project";
import * as turf from "@turf/turf";
import { useDeleteFeaturesCallback } from "../../hooks/deleteFeature";
import { v4 as uuidv4 } from "uuid";
import { keyEventTargetIsInput } from "../../utils/keyboard";
import Tooltip, { WithTooltip } from "../General/Tooltip";
import { vecAdd } from "../../utils/geometry";
import MenuVertical from "@icons/24/MenuVertical.svg";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../../services/types";
import { ProjectFeature } from "../../types/feature";
import { DEFAULT_CANVAS_LAYER_COLOR } from "../../constants/canvas";
import TurbineTypeSelector from "./TurbineTypeSelector";
import { TypeSelector } from "./TypeSelector/TypeSelector";
import ConnectAnchorOption from "./ConnectAnchorOption/ConnectAnchorOption";
import { BufferSelector } from "./CanvasSingleSelectOption";
import {
  featureIsDerived,
  featureIsLocked,
  isAnchor,
  isCable,
  isCableChain,
  isCableCorridor,
  isCablePartition,
  isDefined,
  isExclusionDivision,
  isExportCable,
  isLineStringFeature,
  isMooringLine,
  isMultiPolygonFeature,
  isNotGeometryCollection,
  isPark,
  isPolygonFeature,
  isSubArea,
  isSubstation,
  isTurbine,
} from "../../utils/predicates";
import { makeAnchorFeature } from "../../types/turbines";
import { AnchorFeature } from "../../types/feature";
import CableTypeSelector from "./CableTypeSelector";
import { Feature, MultiPolygon } from "@turf/turf";
import { trackCanvasOption } from "./MenuTracking";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../state/pathParams";
import FoundationTypeSelector from "./FoundationTypeSelector";
import { PolygonFeature } from "../../types/feature";
import { MultiPolygonFeature } from "../../types/feature";
import { GeoJsonProperties, Polygon, Position } from "geojson";
import { ParkFeature } from "../../types/feature";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import StylingSelector from "../StylingSelector/StylingSelector";
import MooringLineTypeSelector from "./MooringLineTypeSelector";
import PositionHint from "../ActiveTips/PositionHints/PositionHint";
import { splitMultiFeatureHelpHintType } from "../ActiveTips/PositionHints/SplitMultiFeatureHelp";
import SplitMultiPart from "./SplitMultiPart";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import { multiFeatureToFeatures } from "../../utils/geojson/utils";
import { SubstationTypeSelector } from "./SubstationTypeSelector/SubstationTypeSelector";
import { Popup } from "components/Mapbox/Popup";
import { Divider, IconBtn, IconMenu } from "components/General/Icons";
import { dedup, roundToDecimal } from "utils/utils";
import {
  NamedTooltipWrapper,
  ToolsWrapper,
} from "components/CanvasSelectOption/CanvasSelectOption.style";
import { IconREMSize } from "styles/typography";
import useMoveablePopup from "./useMoveablePopup";
import { useToast } from "hooks/useToast";
import ExistingTurbineTypeSelector from "./ExistingTurbineTypeSelector";
import { differenceAll, intersectAll, unionAll } from "utils/turf";
import { sendInfo, sendWarning } from "utils/sentry";
import { makeExclusionZone, makeSubArea } from "state/division";
import ExclusionTypeSelector from "./TypeSelector/ExclusionTypeSelector";
import TurbineEllipsesSettings from "components/TurbineEllipsesSettings/TurbineEllipsesSettings";
import { GenerateNamesModalType } from "components/GenerateFeatureNamesModal/GenerateFeatureNamesModal";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useAtomValue } from "jotai";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { currentParkAtom, parkChildrenFamily } from "state/jotai/park";
import ExportCableTypeSelector from "./ExportCableTypeSelector";
import { GeometryNoCollection } from "utils/geojson/geojson";
import { CABLE_CORRIDOR_PROPERTY_TYPE } from "@constants/cabling";
import { isOnshoreAtom } from "state/onshore";
import { useJotaiCallback } from "utils/jotai";
import useAddFeaturesIntoElementsWithinLimits from "hooks/useAddFeaturesIntoElementsWithinLimits";
import MapPolygon from "components/MapFeatures/Polygon";
import { colors } from "styles/colors";
import OneOrMoreCanvasSelectionMenu from "components/RightClickMenu/SelectionMenu/OneOrMoreCanvasSelectionMenu";
import { Menu } from "components/General/Menu";

const LINE_BUFFER_BEFORE_SPLIT = 0.001;

/**
 * Given feature inputs to some polygon operation (e.g. union or intersection)
 * Make sure that the output feature has the same type, if all input types are
 * the same.
 */
function transferPolygonTypes(
  oldFeatures: ProjectFeature[],
  newFeature: Feature<GeometryNoCollection>,
  errorToast: (msg: string) => void,
): ProjectFeature | undefined {
  if (oldFeatures.every(isCableCorridor)) {
    if (!isPolygonFeature(newFeature)) {
      errorToast(
        `Result geometry was not a Polygon\nType: "${newFeature.geometry.type}"`,
      );
      return;
    }
    const id = uuidv4();
    return {
      type: "Feature",
      id,
      geometry: newFeature.geometry,
      properties: {
        id,
        type: CABLE_CORRIDOR_PROPERTY_TYPE,
      },
    };
  }
  if (oldFeatures.every(isExclusionDivision)) {
    if (!isPolygonFeature(newFeature) && !isMultiPolygonFeature(newFeature)) {
      errorToast(
        `Result geometry was not a Polygon\nType: "${newFeature.geometry.type}"`,
      );
      return;
    }
    return makeExclusionZone(newFeature.geometry);
  }

  if (oldFeatures.every(isSubArea)) {
    if (!isPolygonFeature(newFeature) && !isMultiPolygonFeature(newFeature)) {
      errorToast(
        `Result geometry was not a Polygon\nType: "${newFeature.geometry.type}"`,
      );
      return;
    }
    const parkIds = dedup(
      oldFeatures.map((f) => f?.properties?.parentIds?.[0]).filter(isDefined),
    );
    if (parkIds.length !== 1) {
      errorToast("Cannot join sub areas in different parks.");
      return;
    }
    return makeSubArea(newFeature.geometry, parkIds[0]);
  }

  if (!isNotGeometryCollection(newFeature)) return;
  const newId = uuidv4();
  return {
    ...newFeature,
    id: newId,
    properties: {
      color: DEFAULT_CANVAS_LAYER_COLOR,
      id: newId,
      name: "Union result",
    },
  } satisfies ProjectFeature;
}

const ShowGISOPerationResult = ({
  gisResult,
}: {
  gisResult: Feature<Polygon | MultiPolygon, GeoJsonProperties> | null;
}) => {
  const map = useAtomValue(mapAtom);
  if (!map || !gisResult) return null;
  return (
    <MapPolygon
      features={[gisResult]}
      sourceId={"showGISResultSource"}
      layerId={"showGISResultLayer"}
      paint={{
        "fill-opacity": 0,
      }}
      linePaint={{
        "line-color": "#efb14a",
        "line-width": 4,
        "line-opacity": 1,
        "line-dasharray": [4, 2],
      }}
      map={map}
    />
  );
};

const SplitFeatures = ({ selections }: { selections: ProjectFeature[] }) => {
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();

  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);

  const splitFeatures = useCallback(() => {
    const lines = selections
      .filter((f) => f.geometry.type === "LineString")
      .map((f) => f.geometry);
    const polygons = selections
      .filter((f) => f.geometry.type === "Polygon")
      .map((f) => f.geometry);

    const bufferedLines = lines.map((f) =>
      turf.buffer(f, LINE_BUFFER_BEFORE_SPLIT, {
        units: "kilometers",
      }),
    );

    const newFeatures = polygons
      .map((p) =>
        bufferedLines.map(
          (l) =>
            turf.difference(
              p as MultiPolygon,
              l as Feature<MultiPolygon>,
            ) as ProjectFeature,
        ),
      )
      .flat()
      .flatMap((features) => multiFeatureToFeatures(features))
      .map((f) => {
        const newId = uuidv4();
        return {
          ...f,
          id: newId,
          properties: {
            color: DEFAULT_CANVAS_LAYER_COLOR,
            name: "Split result",
            id: newId,
          },
        };
      });

    updateFeatures({
      add: newFeatures,
      remove: selections.map((s) => s.id),
    });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [selections, updateFeatures, setCurrentSelectionArray]);

  if (
    selections.filter((s) => s.geometry.type === "Polygon").length === 0 ||
    selections.filter((s) => s.geometry.type === "LineString").length !== 1
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Split">
        <IconBtn onClick={splitFeatures}>
          <Splitter />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const UnionFeatures = ({ selections }: { selections: ProjectFeature[] }) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const { showConfirm } = useConfirm();
  const { error } = useToast();
  const [tempFeature, setTempFeature] = useState<Feature<
    Polygon | MultiPolygon,
    GeoJsonProperties
  > | null>(null);

  const showUnionResult = useCallback(() => {
    const polygonSelections = selections.filter(
      (s): s is PolygonFeature | MultiPolygonFeature =>
        isPolygonFeature(s) || isMultiPolygonFeature(s),
    );
    const union = unionAll(polygonSelections);
    if (!union || !isNotGeometryCollection(union)) return;
    setTempFeature(union);
    return () => {
      setTempFeature(null);
    };
  }, [selections]);

  const combineFeatures = useJotaiCallback(
    async (get) => {
      const polygonSelections = selections.filter(
        (s): s is PolygonFeature | MultiPolygonFeature =>
          isPolygonFeature(s) || isMultiPolygonFeature(s),
      );
      const union = unionAll(polygonSelections);
      if (!union) {
        error("Failed to compute the union.");
        sendWarning("Failed to compute union", {
          selections,
          types: selections.map((s) => s.geometry.type),
        });
        return;
      }
      if (!isNotGeometryCollection(union)) {
        error("Union failed.");
        sendWarning("Failed to compute union: got a GeometryCollection", {
          selections,
          types: selections.map((s) => s.geometry.type),
        });
        return;
      }

      const newFeature = transferPolygonTypes(polygonSelections, union, error);
      if (!newFeature) return;

      // If we have parks in the selection we want the result to be a park.
      // Update the geometry of the first park we have, and move all features
      // from any other park to the first park.
      const park = selections.find(isPark);
      if (park && isMultiPolygonFeature(union)) {
        if (
          !(await showConfirm({
            title: "Union result cannot be a park",
            message:
              "The result cannot be turned into a park. Proceed, and turn all features into Other?",
          }))
        )
          return;
      }

      if (park && !isMultiPolygonFeature(union)) {
        const otherParks = selections.filter(
          (f) => isPark(f) && f.id !== park.id,
        );
        const otherParkFeatures = (
          await Promise.all(
            otherParks.map((f) =>
              get(parkChildrenFamily({ parkId: f.id, branchId: undefined })),
            ),
          )
        ).flat();

        const movedFeatures: ProjectFeature[] = otherParkFeatures.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            parentIds: [park.id],
          },
        }));

        updateFeatures({
          update: movedFeatures.concat([{ ...park, geometry: union.geometry }]),
          remove: selections.map((f) => f.id).filter((id) => id !== park.id),
        });
      } else {
        updateFeatures({
          add: [newFeature],
          remove: selections.map((s) => s.id),
        });
      }

      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
      trackCanvasOption("union", {
        projectId,
        branchId,
        numberOfFeatures: selections.length,
      });
    },
    [
      showConfirm,
      error,
      selections,
      updateFeatures,
      setCurrentSelectionArray,
      projectId,
      branchId,
    ],
  );

  if (
    selections.filter((s) => s.geometry.type.includes("Polygon")).length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Union">
        <IconBtn
          onClick={combineFeatures}
          onMouseEnter={() => {
            showUnionResult();
          }}
          onMouseLeave={() => {
            setTempFeature(null);
          }}
        >
          <Combine />
        </IconBtn>
      </Tooltip>
      <ShowGISOPerationResult gisResult={tempFeature} />
    </>
  );
};

const coordinatesOverlap = (coord1: Position, coord2: Position) =>
  roundToDecimal(coord1[0], 11) === roundToDecimal(coord2[0], 11) &&
  roundToDecimal(coord1[1], 11) === roundToDecimal(coord2[1], 11);

const overlapsWithStartPosition = (
  line1: ProjectFeature,
  line2: ProjectFeature,
) => {
  const endPointLine1 = line1.geometry.coordinates[
    line1.geometry.coordinates.length - 1
  ] as [number, number];

  const startPointLine2 = line2.geometry.coordinates[0] as [number, number];

  return coordinatesOverlap(endPointLine1, startPointLine2);
};

const findLineStringNeighbour = (
  linestring: ProjectFeature,
  selections: ProjectFeature[],
) => {
  const potentialNeighbourLineString = selections.filter(
    (s) => s.id !== linestring.id,
  );
  const startPoint = linestring.geometry.coordinates[0] as [number, number];
  const endPoint = linestring.geometry.coordinates[
    linestring.geometry.coordinates.length - 1
  ] as [number, number];

  return [
    potentialNeighbourLineString.filter(
      (s) =>
        coordinatesOverlap(startPoint, s.geometry.coordinates[0] as Position) ||
        coordinatesOverlap(
          startPoint,
          s.geometry.coordinates[s.geometry.coordinates.length - 1] as Position,
        ),
    ),
    potentialNeighbourLineString.filter(
      (s) =>
        coordinatesOverlap(endPoint, s.geometry.coordinates[0] as Position) ||
        coordinatesOverlap(
          endPoint,
          s.geometry.coordinates[s.geometry.coordinates.length - 1] as Position,
        ),
    ),
  ];
};

const UnionLineStrings = ({ selections }: { selections: ProjectFeature[] }) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const { error } = useToast();

  const combineLineStringFeatures = useCallback(() => {
    let selectionsToMerge = [...selections];
    let startLines = selectionsToMerge.filter((s) => {
      const [startNeighbour, _] = findLineStringNeighbour(s, selections);
      return startNeighbour.length === 0;
    });

    // Did not find a starting line so need to reverse it
    if (startLines.length === 0) {
      selectionsToMerge = [...selections].map((s) => ({
        ...s,
        geometry: {
          ...s.geometry,
          coordinates: [...s.geometry.coordinates].reverse(),
        },
      })) as ProjectFeature[];
      startLines = selectionsToMerge.filter((s) => {
        const [startNeighbour, _] = findLineStringNeighbour(s, selections);
        return startNeighbour.length === 0;
      });
    }

    if (startLines.length === 0) {
      error(
        "Could not find a clear starting linestring, do you have a circular dependency?",
      );
      throw new Error("Did not find a starting line");
    }

    let mergedLineStringFeature = startLines[0];
    selectionsToMerge = selectionsToMerge.filter(
      (s) => s.id !== mergedLineStringFeature.id,
    );

    while (selectionsToMerge.length !== 0) {
      const [_, endMatch] = findLineStringNeighbour(
        mergedLineStringFeature,
        selectionsToMerge,
      );

      if (endMatch.length > 1) {
        error(
          "Found multiple overlapping linestrings at one point, do you have a circular dependency?",
        );
        throw new Error("Did not find exact one neighbour");
      }

      const coordsToAdd = endMatch[0].geometry.coordinates as Position[];
      const coordsRightOrder = overlapsWithStartPosition(
        mergedLineStringFeature,
        endMatch[0],
      )
        ? coordsToAdd
        : [...coordsToAdd].reverse();
      mergedLineStringFeature = {
        ...mergedLineStringFeature,
        id: endMatch[0].id,
        geometry: {
          ...mergedLineStringFeature.geometry,
          type: "LineString",
          coordinates: [
            ...(mergedLineStringFeature.geometry.coordinates as Position[]),
            ...(coordsRightOrder.slice(1) as Position[]),
          ],
        },
      };
      selectionsToMerge = selectionsToMerge.filter(
        (s) => s.id !== mergedLineStringFeature.id,
      );
    }

    const newId = uuidv4();
    const newFeature = {
      ...mergedLineStringFeature,
      id: newId,
      properties: {
        color: DEFAULT_CANVAS_LAYER_COLOR,
        id: newId,
        name: "Union result",
      },
    } as ProjectFeature;
    updateFeatures({
      add: [newFeature],
      remove: selections.map((s) => s.id),
    });
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    trackCanvasOption("linestringunion", {
      projectId,
      branchId,
    });
  }, [
    selections,
    updateFeatures,
    setCurrentSelectionArray,
    projectId,
    branchId,
    error,
  ]);

  const tooLargeGap = useMemo(
    () =>
      selections.some((s) => {
        const [startNeighbour, endNeighbour] = findLineStringNeighbour(
          s,
          selections,
        );
        return startNeighbour.length === 0 && endNeighbour.length === 0;
      }),
    [selections],
  );

  if (selections.filter(isLineStringFeature).length !== selections.length)
    return null;

  return (
    <>
      <Tooltip
        position="top"
        text={tooLargeGap ? "Too large gap between lines" : "Union"}
      >
        <IconBtn onClick={combineLineStringFeatures} disabled={tooLargeGap}>
          <Combine />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const DifferenceFeatures = ({
  selections,
}: {
  selections: ProjectFeature[];
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const { error } = useToast();
  const { showConfirm } = useConfirm();
  const [tempFeature, setTempFeature] = useState<Feature<
    Polygon | MultiPolygon,
    GeoJsonProperties
  > | null>(null);

  const showDifferenceResult = useCallback(() => {
    if (
      !selections.every(
        (s): s is PolygonFeature | MultiPolygonFeature =>
          isPolygonFeature(s) || isMultiPolygonFeature(s),
      )
    )
      return;
    const difference = differenceAll(selections);
    if (!difference || !isNotGeometryCollection(difference)) return;
    setTempFeature(difference);
    return () => {
      setTempFeature(null);
    };
  }, [selections]);

  const differenceFeatures = useJotaiCallback(
    async (get) => {
      if (
        !selections.every(
          (s): s is PolygonFeature | MultiPolygonFeature =>
            isPolygonFeature(s) || isMultiPolygonFeature(s),
        )
      )
        return; // we're not mounted unless this is true
      const difference = differenceAll(selections);
      if (!difference) {
        sendInfo("Failed to take feature difference", { selections });
        return error("Failed to take the difference");
      }

      const firstDifferenceFeature = selections[0];
      const firstSelectionIsPark = isPark(firstDifferenceFeature);
      const resultIsMultiPolygon = isMultiPolygonFeature(difference);
      const newId = uuidv4();

      const projectFeature = {
        ...difference,
        id: firstDifferenceFeature.id,
        properties:
          firstSelectionIsPark && resultIsMultiPolygon
            ? {
                color: DEFAULT_CANVAS_LAYER_COLOR,
                id: newId,
                name: "Difference result",
              }
            : firstDifferenceFeature.properties,
      };

      // If the *first* feature is a park, we want to update the park instead
      // of replacing the selection with the difference feature.  Other parks
      // are removed, like other polygons.
      //
      // Note that this also removes features in the other parks, which makes
      // sense, since the output doesn't contain any of this park (since it's a
      // difference).
      if (firstSelectionIsPark && resultIsMultiPolygon) {
        if (
          !(await showConfirm({
            title: "Difference result cannot be a park",
            message:
              "The result cannot be turned into a park. Proceed, and turn all features into Other?",
          }))
        ) {
          return;
        }
      }

      if (firstSelectionIsPark) {
        const park = projectFeature;
        const otherParkFeatures = (
          await Promise.all(
            selections
              .slice(firstSelectionIsPark && resultIsMultiPolygon ? 0 : 1)
              .filter(isPark)
              .map((f) =>
                get(parkChildrenFamily({ parkId: f.id, branchId: undefined })),
              ),
          )
        ).flat();

        const toDelete = selections
          .slice(1)
          .map((f) => f.id)
          .concat(otherParkFeatures.map((f) => f.id));

        await updateFeatures({
          update: [
            {
              ...park,
              geometry: difference.geometry,
            },
          ],
          remove: toDelete,
        });
      } else {
        await updateFeatures({
          add: [projectFeature],
          remove: selections.map((s) => s.id),
        });
      }

      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
      trackCanvasOption("difference", {
        projectId,
        branchId,
      });
    },
    [
      showConfirm,
      selections,
      updateFeatures,
      setCurrentSelectionArray,
      projectId,
      branchId,
      error,
    ],
  );

  if (
    selections.filter((s) => s.geometry.type.includes("Polygon")).length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Difference">
        <IconBtn
          onClick={differenceFeatures}
          onMouseEnter={showDifferenceResult}
          onMouseLeave={() => setTempFeature(null)}
        >
          <Difference />
        </IconBtn>
      </Tooltip>
      <ShowGISOPerationResult gisResult={tempFeature} />
    </>
  );
};

const IntersectFeatures = ({
  selections,
}: {
  selections: ProjectFeature[];
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const { error } = useToast();
  const { showConfirm } = useConfirm();
  const [tempFeature, setTempFeature] = useState<Feature<
    Polygon | MultiPolygon,
    GeoJsonProperties
  > | null>(null);

  const showIntersectResult = useCallback(() => {
    const polygonSelections = selections.filter(
      (f): f is PolygonFeature | MultiPolygonFeature =>
        isPolygonFeature(f) || isMultiPolygonFeature(f),
    );
    const intersect = intersectAll(polygonSelections);
    if (
      !intersect ||
      !(isPolygonFeature(intersect) || isMultiPolygonFeature(intersect))
    )
      return;
    setTempFeature(intersect);
    return () => {
      setTempFeature(null);
    };
  }, [selections]);

  const intersectFeatures = useJotaiCallback(
    async (get) => {
      // NOTE: this is mostly type gymnastics, because we'll only call this function if all selections are polygons.
      const polygonSelections = selections.filter(
        (f): f is PolygonFeature | MultiPolygonFeature =>
          isPolygonFeature(f) || isMultiPolygonFeature(f),
      );

      const intersect = intersectAll(polygonSelections);
      if (!intersect) return error("Intersection is empty");

      const mfs = multiFeatureToFeatures(intersect);

      const newFeatures = mfs
        .map((f) =>
          isNotGeometryCollection(f)
            ? transferPolygonTypes(polygonSelections, f, error)
            : undefined,
        )
        .filter(isDefined);

      // We want to support intersections for park(s).
      // Problem: parks cannot be MultiFeatures, and if we create one park per
      // result polygon, transfering the features is gnarly.
      //
      // Solution: Only handle the easy case.  If the intersection is a Polygon,
      // we output a park with the intersected polygon as its geometry, and move
      // all features to the new park.  If not, show a `confirm` and convert
      // everything to Other.
      const park = selections.find(isPark);
      if (park && newFeatures.length === 1) {
        const geometry = newFeatures[0].geometry as Polygon;
        const otherParkFeatures = (
          await Promise.all(
            selections
              .filter((s) => isPark(s) && s.id !== park.id)
              .map((f) =>
                get(parkChildrenFamily({ parkId: f.id, branchId: undefined })),
              ),
          )
        ).flat();

        const movedFeatures: ProjectFeature[] = otherParkFeatures.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            parentIds: [park.id],
          },
        }));

        updateFeatures({
          update: movedFeatures.concat([{ ...park, geometry }]),
          remove: selections.map((f) => f.id).filter((id) => id !== park.id),
        });
      } else {
        if (park) {
          if (
            !(await showConfirm({
              title: "Intersection result cannot be a park",
              message:
                "The result cannot be turned into a park. Proceed, and turn all features into Other?",
            }))
          ) {
            return;
          }

          const otherParkFeatures = (
            await Promise.all(
              selections
                .filter((s) => isPark(s))
                .map((f) =>
                  get(
                    parkChildrenFamily({ parkId: f.id, branchId: undefined }),
                  ),
                ),
            )
          ).flat();

          const update = otherParkFeatures.map((f) => ({
            ...f,
            properties: {
              ...f.properties,
              type: undefined,
            },
          }));

          updateFeatures({
            add: newFeatures,
            update,
            remove: selections.map((s) => s.id),
          });
        } else {
          updateFeatures({
            add: newFeatures,
            remove: selections.map((s) => s.id),
          });
        }
      }

      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
      trackCanvasOption("intersect", {
        projectId,
        branchId,
      });
    },
    [
      showConfirm,
      selections,
      error,
      updateFeatures,
      setCurrentSelectionArray,
      projectId,
      branchId,
    ],
  );

  if (
    selections.filter((s) => s.geometry.type.includes("Polygon")).length !==
    selections.length
  )
    return null;

  return (
    <>
      <Tooltip position="top" text="Intersect">
        <IconBtn
          onClick={intersectFeatures}
          onMouseEnter={showIntersectResult}
          onMouseLeave={() => setTempFeature(null)}
        >
          <Intersect />
        </IconBtn>
      </Tooltip>
      <ShowGISOPerationResult gisResult={tempFeature} />
    </>
  );
};

const useMergeAnchors = (park: ParkFeature) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({
      parkId: park.id,
      branchId,
    }),
  );
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();

  const mergeAnchors = useCallback(
    (anchors: AnchorFeature[]) => {
      const ids = anchors.map((f) => f.id);
      const coords = anchors.map((f) => f.geometry.coordinates);
      const newPosition = coords
        .reduce(vecAdd, [0, 0])
        .map((n) => n / coords.length);

      const newAnchor = makeAnchorFeature(uuidv4(), newPosition, park.id);

      const updateLines = mooringLines
        .filter((f) => ids.includes(f.properties.anchor))
        .map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            anchor: newAnchor.id,
          },
        }));

      updateFeatures({
        update: updateLines,
        remove: ids,
        add: [newAnchor],
      });
      trackCanvasOption("merge-anchors", {
        projectId,
        branchId,
      });
    },
    [branchId, park.id, mooringLines, projectId, updateFeatures],
  );

  return mergeAnchors;
};

const MergeAnchors = ({
  selection,
  park,
}: {
  selection: ProjectFeature[];
  park: ParkFeature;
}) => {
  const mergeAnchors = useMergeAnchors(park);
  return (
    <>
      <Tooltip position="top" text="Merge anchors">
        <IconBtn
          iconColor={colors.iconBrand}
          onClick={() => {
            mergeAnchors(selection.filter(isAnchor));
          }}
        >
          <AddCircle />
        </IconBtn>
      </Tooltip>
    </>
  );
};

const CanvasMultiSelectOption = ({
  selectedProjectFeatures,
}: {
  selectedProjectFeatures: ProjectFeature[];
}) => {
  const isOnshore = useAtomValue(isOnshoreAtom);
  const parkId = useAtomValue(parkIdAtom);
  const projectId = useAtomValue(projectIdAtom)!;
  const branchId = useAtomValue(branchIdAtom)!;
  const park = useAtomValue(currentParkAtom);
  const map = useAtomValue(mapAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const canEdit = isCustomerEditor && !isReadOnly;
  const nonLockedFeatures = useMemo(
    () =>
      selectedProjectFeatures.filter((feature) => !featureIsLocked(feature)),
    [selectedProjectFeatures],
  );
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const setEditFeature = useSetAtom(editFeaturesAtom);
  const [showMoreFeatureActions, setShowMoreFeatureActions] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);
  const moreMenuRef = useRef<HTMLButtonElement>(null);

  const {
    popupPlacement,
    isMoving,
    setMouseDownPosition,
    recalculatePosition,
  } = useMoveablePopup(selectedProjectFeatures, true, popupRef);

  // Recalculate position when the popup content changes
  useEffect(() => {
    recalculatePosition();
  }, [selectedProjectFeatures, recalculatePosition]);

  const allFeaturesOfSameType = useMemo(
    () =>
      selectedProjectFeatures.reduce(
        (previous, f) => {
          const isSame =
            f.properties?.type === previous.type &&
            f.geometry?.type === previous.geometry;

          if (isSame) return previous;
          return {
            type: f.properties?.type,
            same: false,
            geometry: f.geometry?.type,
          };
        },
        {
          type: selectedProjectFeatures[0].properties.type,
          same: true,
          geometry: selectedProjectFeatures[0].geometry.type,
        },
      ),
    [selectedProjectFeatures],
  );

  const deleteFeaturesWithChildren = useDeleteFeaturesCallback();

  const deleteFeatures = useCallback(() => {
    if (!canEdit) return;
    deleteFeaturesWithChildren(nonLockedFeatures.map((s) => s.id));
  }, [canEdit, nonLockedFeatures, deleteFeaturesWithChildren]);

  const onClickEditFeatures = useCallback(() => {
    if (nonLockedFeatures.length < 1) return;
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
    setEditFeature(nonLockedFeatures.map((s) => s.id));
    trackCanvasOption("edit-feature", {
      projectId,
      branchId,
    });
  }, [
    branchId,
    projectId,
    nonLockedFeatures,
    setCurrentSelectionArray,
    setEditFeature,
  ]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!["Backspace", "Delete"].includes(e.key)) {
        return;
      }

      if (
        !mapInteraction.deleteFeatureKeyboardShortcut ||
        keyEventTargetIsInput(e)
      ) {
        return;
      }

      deleteFeatures();
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [deleteFeatures, mapInteraction.deleteFeatureKeyboardShortcut]);

  const selectionsAreOfTypeOther = selectedProjectFeatures.every(
    (s) =>
      s.properties.type == null &&
      ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
        s.properties.type ?? "",
      ),
  );

  const selectionsAreOfTypeGeoTiff = selectedProjectFeatures.every(
    (s) => s.properties.type === GeoTiffUserUploadedImageType,
  );
  const selectionsAreOfTypeBathymetry = selectedProjectFeatures.every(
    (s) => s.properties.type === BathymetryUserUploadedType,
  );

  const allowEdit = useMemo(() => {
    return selectedProjectFeatures.every(
      (s) =>
        ![BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
          s.properties.type ?? "",
        ),
    );
  }, [selectedProjectFeatures]);

  const allLocked = useMemo(
    () => selectedProjectFeatures.every(featureIsLocked),
    [selectedProjectFeatures],
  );

  const setCableEditMode = useSetAtom(cableEditModeAtom);

  if (!canEdit) return null;

  const allTurbines =
    allFeaturesOfSameType.same && isTurbine(selectedProjectFeatures[0]);

  const allAnchors =
    allFeaturesOfSameType.same && isAnchor(selectedProjectFeatures[0]);

  const allCables =
    allFeaturesOfSameType.same && isCable(selectedProjectFeatures[0]);

  const allMooringLines =
    allFeaturesOfSameType.same && isMooringLine(selectedProjectFeatures[0]);

  const allExclusionZones =
    allFeaturesOfSameType.same &&
    isExclusionDivision(selectedProjectFeatures[0]);

  const cablePartitionControls =
    selectedProjectFeatures.filter(isCablePartition).length === 1;
  const cableChainControls =
    selectedProjectFeatures.every(
      (f) => isTurbine(f) || isSubstation(f) || isCableChain(f),
    ) && selectedProjectFeatures.some(isCableChain);

  if (!map) return null;
  return (
    <Popup
      className={"multi-select-popup"}
      map={map}
      pos={popupPlacement}
      offsetPx={[0, -20]}
      place="bottom"
      style={{
        pointerEvents: isMoving ? "none" : undefined,
      }}
    >
      <div
        ref={popupRef}
        style={{
          position: "relative",
        }}
      >
        <IconMenu iconSize="2.2rem">
          <div
            style={{
              cursor: "move",
            }}
            onMouseDown={(e) => {
              setMouseDownPosition({
                x: e.clientX,
                y: e.clientY,
              });
            }}
          >
            <NamedTooltipWrapper>
              <IconREMSize height={1.2} width={1.2}>
                <DnDIconSmall />
              </IconREMSize>
            </NamedTooltipWrapper>
          </div>
          <>
            {!selectionsAreOfTypeGeoTiff && !selectionsAreOfTypeBathymetry && (
              <TypeSelector
                selections={selectedProjectFeatures}
                setCurrentSelectionArray={setCurrentSelectionArray}
                disabled={!canEdit || allLocked}
              />
            )}
            <TurbineTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />

            <ExistingTurbineTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />

            <SubstationTypeSelector
              selectedProjectFeatures={nonLockedFeatures}
              editable={canEdit && !allLocked}
            />
            {nonLockedFeatures.every(isExportCable) && (
              <ExportCableTypeSelector
                cables={nonLockedFeatures}
                editable={canEdit && !allLocked}
              />
            )}
            {!isOnshore && (
              <FoundationTypeSelector
                selectedProjectFeatures={nonLockedFeatures}
                editable={canEdit && !allLocked}
              />
            )}

            {allCables && (
              <>
                <CableTypeSelector
                  selectedProjectFeatures={nonLockedFeatures}
                  editable={canEdit && !allLocked}
                />
                <Divider />
              </>
            )}
            {allMooringLines && (
              <>
                <MooringLineTypeSelector
                  selectedProjectFeatures={nonLockedFeatures}
                  editable={canEdit && !allLocked}
                />
                <Divider />
              </>
            )}
            {allExclusionZones && (
              <>
                <ExclusionTypeSelector
                  divisions={nonLockedFeatures.filter(isExclusionDivision)}
                  setCurrentSelectionArray={setCurrentSelectionArray}
                />
                <Divider />
              </>
            )}

            <ToolsWrapper>
              {allowEdit && !(cablePartitionControls || cableChainControls) && (
                <Tooltip position="top" text={`Edit elements`}>
                  <IconBtn
                    iconColor={colors.iconBrand}
                    onClick={onClickEditFeatures}
                    disabled={allLocked}
                  >
                    <Pen />
                  </IconBtn>
                </Tooltip>
              )}

              {allowEdit && allCables && !allLocked && (
                <Tooltip position="top" text={`Change cabling`}>
                  <IconBtn
                    iconColor={colors.iconBrand}
                    onClick={() => {
                      setCableEditMode(true);
                      setCurrentSelectionArray([]);
                    }}
                  >
                    <Cabling />
                  </IconBtn>
                </Tooltip>
              )}

              {canEdit &&
                !allLocked &&
                selectedProjectFeatures.find((f) =>
                  f.geometry.type.includes("Multi"),
                ) && (
                  <PositionHint
                    allowedHints={[splitMultiFeatureHelpHintType]}
                    position={"top"}
                  >
                    <Tooltip position="top" text="Split into parts">
                      <SplitMultiPart
                        projectDataFeatures={selectedProjectFeatures}
                      />
                    </Tooltip>
                  </PositionHint>
                )}

              <UnionFeatures selections={selectedProjectFeatures} />
              <IntersectFeatures selections={selectedProjectFeatures} />
              <DifferenceFeatures selections={selectedProjectFeatures} />
              <SplitFeatures selections={selectedProjectFeatures} />
              {!allCables && (
                <UnionLineStrings selections={selectedProjectFeatures} />
              )}
              {canEdit &&
                !selectedProjectFeatures.some((f) =>
                  [
                    BathymetryUserUploadedType,
                    GeoTiffUserUploadedImageType,
                  ].includes(f.properties.type ?? ""),
                ) &&
                !(cablePartitionControls || cableChainControls) && (
                  <>
                    <Tooltip position="top" text="Buffer">
                      <BufferSelector />
                    </Tooltip>
                  </>
                )}
              {canEdit && (
                <>
                  <Tooltip position="top" text="Change style">
                    <StylingSelector
                      selectedProjectFeatures={selectedProjectFeatures}
                      enableColorStyling={selectionsAreOfTypeOther}
                      enableOpacityStyling={selectionsAreOfTypeGeoTiff}
                    />
                  </Tooltip>
                </>
              )}
              {canEdit && allTurbines && (
                <>
                  <Tooltip position="top" text="Display turbine ellipses">
                    <TurbineEllipsesSettings
                      selectedProjectFeatures={selectedProjectFeatures}
                    />
                  </Tooltip>
                </>
              )}

              {park &&
                canEdit &&
                allAnchors &&
                !allLocked &&
                nonLockedFeatures.length > 1 && (
                  <MergeAnchors selection={nonLockedFeatures} park={park} />
                )}

              {parkId && park && (
                <>
                  <ConnectAnchorOption
                    selection={selectedProjectFeatures}
                    park={park}
                  />
                </>
              )}
              {canEdit && allFeaturesOfSameType.same && (
                <WithTooltip text="Create new names">
                  <IconBtn
                    iconColor={colors.iconBrand}
                    onClick={() => {
                      if (isReadOnly) return;
                      setModalTypeOpen({
                        modalType: GenerateNamesModalType,
                        metadata: {
                          featureIds: selectedProjectFeatures.map((f) => f.id),
                        },
                      });
                    }}
                  >
                    <EditNamesIcon />
                  </IconBtn>
                </WithTooltip>
              )}
            </ToolsWrapper>
            {canEdit && !allLocked && (
              <>
                <Divider />
                <Tooltip position="top" text={`Delete features`}>
                  <IconBtn
                    iconColor={colors.iconBrand}
                    onClick={deleteFeatures}
                  >
                    <Bin />
                  </IconBtn>
                </Tooltip>
                <Divider />
              </>
            )}
            {!selectedProjectFeatures.some(featureIsDerived) && (
              <>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                  }}
                >
                  <IconBtn
                    iconColor={colors.iconBrand}
                    active={showMoreFeatureActions}
                    disabled={!canEdit}
                    ref={moreMenuRef}
                    onClick={() => {
                      setShowMoreFeatureActions(!showMoreFeatureActions);
                    }}
                  >
                    <MenuVertical />
                  </IconBtn>
                  {showMoreFeatureActions && (
                    <Menu
                      style={{
                        position: "absolute",
                        top: "4.4rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <OneOrMoreCanvasSelectionMenu
                        featuresOnPoint={[]}
                        enableShowLayerInfo={false}
                        closeMenu={() => {}}
                        sampleWmsCallback={() => {}}
                        onSelectFeature={() => {}}
                        onMouseEnterFeature={() => {}}
                        onMouseLeaveFeature={() => {}}
                        selectedProjectFeatures={selectedProjectFeatures}
                      />
                    </Menu>
                  )}
                </div>

                <Divider />
              </>
            )}
          </>
        </IconMenu>
      </div>
    </Popup>
  );
};

export default CanvasMultiSelectOption;
