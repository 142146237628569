import { AnalysisConfiguration } from "services/configurationService";
import { projectIdAtom } from "state/pathParams";
import {
  ConfigContainer,
  InputWrapper,
} from "components/CompareParksModal/ParkComparisonView/ComparisonParkV2/ComparisonParkV2.style";
import SelectAnalysisDropDown from "components/ProductionV2/SelectAnalysisDropDown";
import React, { useMemo, useState } from "react";
import { CostConfiguration } from "services/costService";
import SelectFinancialConfigDropDown from "components/FinancialAnalysis/SelectFinancialDropDown";
import useBooleanState from "hooks/useBooleanState";
import { InputDimensioned } from "components/General/Input";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import CheckIcon from "@icons/24/Check.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import UndoIcon from "@icons/24/Undo.svg";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import SelectWindDropDownCustom from "components/ProductionV2/SelectWindDropDown";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { colors } from "styles/colors";
import { Row } from "components/General/Layout";
import { MenuButton } from "components/General/MenuButton";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { MenuItem } from "components/General/Menu";
import { SimpleTurbineType } from "types/turbines";
import { CableType } from "services/cableTypeService";
import { useAtomValue } from "jotai";
import { exportCableTypesWithLevelAtom } from "state/jotai/exportCableType";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { windConfigurationsFamily } from "state/jotai/windConfiguration";
import { analysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { costConfigurationsFamily } from "state/jotai/costConfiguration";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { operationsConfigurationsFamily } from "state/jotai/operationsConfiguration";
import SelectOperationsDropDownCustom from "components/ProductionV2/SelectOperationsDropDown";
import { getExportSystemTypeInParkAndBranchFamily } from "state/jotai/electrical";

export function AnalysisConfigSelector({
  selectedConfig,
  setSelectedConfig,
}: {
  selectedConfig: AnalysisConfiguration | undefined;
  setSelectedConfig: (config: AnalysisConfiguration) => void;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const configurations = useAtomValue(
    analysisConfigurationsFamily({ projectId }),
  );

  return (
    <ConfigContainer>
      <SelectAnalysisDropDown
        data-selected-id={selectedConfig?.id}
        className="dropdown-config"
        selectedConfig={selectedConfig}
        projectId={projectId}
        style={{ flex: 1, border: "none", maxWidth: "100%", paddingLeft: 0 }}
        onSelectItem={(id) => {
          const config = configurations.get(id);
          if (!config) return;
          setSelectedConfig(config);
        }}
      />
    </ConfigContainer>
  );
}

export function WindConfigSelector({
  selectedConfig,
  setSelectedConfig,
}: {
  selectedConfig: WindSourceConfiguration | undefined;
  setSelectedConfig: (config: WindSourceConfiguration) => void;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const configurations = useAtomValue(windConfigurationsFamily({ projectId }));

  return (
    <ConfigContainer>
      <SelectWindDropDownCustom
        projectId={projectId}
        className="dropdown-config"
        selectedConfig={selectedConfig}
        data-selected-id={selectedConfig?.id}
        style={{ flex: 1, border: "none", maxWidth: "100%", paddingLeft: 0 }}
        onSelectItem={(value) => {
          const config = configurations.get(value);
          if (!config) return;
          setSelectedConfig(config);
        }}
      />
    </ConfigContainer>
  );
}

export function CostConfigSelector({
  selectedConfig,
  setSelectedConfig,
}: {
  selectedConfig: CostConfiguration;
  setSelectedConfig: (config: CostConfiguration) => void;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const configurations = useAtomValue(costConfigurationsFamily({ projectId }));

  return (
    <ConfigContainer>
      <SelectFinancialConfigDropDown
        selectedConfig={selectedConfig}
        className="dropdown-config"
        data-selected-id={selectedConfig.id}
        projectId={projectId}
        style={{ flex: 1, border: "none", maxWidth: "100%", paddingLeft: 0 }}
        onSelectItem={(id) => {
          const config = configurations.get(id);
          if (!config) return;
          setSelectedConfig(config);
        }}
      />
    </ConfigContainer>
  );
}

export function OperationsConfigSelector({
  selectedConfig,
  setSelectedConfig,
}: {
  selectedConfig: OperationsConfiguration | undefined;
  setSelectedConfig: (config: OperationsConfiguration) => void;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const configurations = useAtomValue(
    operationsConfigurationsFamily({ projectId }),
  );

  return (
    <ConfigContainer>
      <SelectOperationsDropDownCustom
        projectId={projectId}
        className="dropdown-config"
        selectedConfig={selectedConfig}
        data-selected-id={selectedConfig?.id}
        style={{ flex: 1, border: "none", maxWidth: "100%", paddingLeft: 0 }}
        onSelectItem={(value) => {
          const config = configurations.get(value);
          if (!config) return;
          setSelectedConfig(config);
        }}
      />
    </ConfigContainer>
  );
}

export function ExportCableTypeSelector({
  parkId,
  branchId,
  selectedExportCableType,
  setSelectedExportCableType,
  exportCableTypesString,
  hasChangedExportCableType,
}: {
  parkId: string;
  branchId: string;
  selectedExportCableType: CableType | undefined;
  exportCableTypesString: string | undefined;
  hasChangedExportCableType: boolean;
  setSelectedExportCableType: (
    selectedExportCableType: CableType | undefined,
  ) => void;
}) {
  const [tempType, setTempType] = useState(selectedExportCableType);
  const [isEditing, toggleIsEditing, setIsEditing] = useBooleanState(false);
  const isEdited = selectedExportCableType !== undefined;
  const allExportCableTypes = useAtomValue(exportCableTypesWithLevelAtom);
  const exportSystemType = useAtomValue(
    getExportSystemTypeInParkAndBranchFamily({ parkId, branchId }),
  );
  const validExportCableTypes = useMemo(() => {
    return Array.from(allExportCableTypes.values()).filter(
      (e) => e.cable.exportCableType === exportSystemType,
    );
  }, [allExportCableTypes, exportSystemType]);

  const items = useMemo(
    () =>
      Array.from(validExportCableTypes.values()).map((e) => ({
        id: e.cable.id,
        name: e.cable.name,
        icon: ["project"].includes(e.level) ? (
          <Tooltip text={"Project export cable"}>
            <ProjectIcon />
          </Tooltip>
        ) : (
          <Tooltip text={"Library export cable"}>
            <LibraryIcon />
          </Tooltip>
        ),
      })),
    [validExportCableTypes],
  );

  return (
    <InputWrapper>
      <Row style={{ alignItems: "center" }}>
        {isEditing ? (
          <div>
            <MenuButton
              icon={<ArrowRightIcon />}
              iconOpen={<ArrowDownIcon />}
              divStyle={{ display: "flex", position: "sticky" }}
              buttonStyle={{
                flexDirection: "row-reverse",
                height: "2.6rem",
                justifyContent: "space-between",
                padding: "0.8rem",
                gap: "1.6rem",
                fontWeight: 600,
                fontSize: "1.2rem",
                backgroundColor: "transparent",
              }}
              buttonType="dropdown"
              buttonText={
                tempType
                  ? validExportCableTypes.find(
                      (e) => e.cable.id === tempType.id,
                    )?.cable.name ?? "..."
                  : exportCableTypesString
              }
              maxWidth={10}
            >
              {items.map((item) => (
                <MenuItem
                  key={item.id}
                  name={item.name}
                  onClick={() => {
                    setTempType(
                      validExportCableTypes.find((e) => e.cable.id === item.id)
                        ?.cable,
                    );
                  }}
                  icon={item.icon}
                  style={{
                    width: "100%",
                  }}
                />
              ))}
            </MenuButton>
          </div>
        ) : (
          <>
            {selectedExportCableType !== undefined
              ? selectedExportCableType.name
              : exportCableTypesString}
          </>
        )}
        <Tooltip
          text={
            isEditing
              ? "Confirm"
              : "Changing this will not affect the selected export cable type"
          }
        >
          <Button
            buttonType={isEditing ? "primary" : "secondary"}
            icon={isEditing ? <CheckIcon /> : <PencilIcon />}
            size="small"
            onClick={() => {
              if (isEditing) {
                setSelectedExportCableType(tempType);
              }
              toggleIsEditing();
            }}
          />
        </Tooltip>

        {isEditing && isEdited && (
          <Tooltip text="Reset to default">
            <Button
              buttonType="secondary"
              icon={<UndoIcon />}
              size="small"
              onClick={() => {
                setSelectedExportCableType(undefined);
                setTempType(undefined);
                setIsEditing(false);
              }}
            />
          </Tooltip>
        )}
      </Row>
      {hasChangedExportCableType && (
        <HelpTooltip
          style={{ paddingLeft: "0.8rem" }}
          size={10}
          text="Selected export cable type differs from the export cable types in the park. This can have an effect on Net energy, Export cable voltages, Total export system loss, Export cable cost, CAPEX, IRR and LCOE."
          color={colors.iconBrand}
        />
      )}
    </InputWrapper>
  );
}

export function TurbineTypeSelector({
  selectedTurbineType,
  setSelectedTurbineType,
  turbineTypesString,
  hasChangedTurbineType,
  averageTurbineHeight,
}: {
  selectedTurbineType: SimpleTurbineType | undefined;
  turbineTypesString: string | undefined;
  hasChangedTurbineType: boolean;
  setSelectedTurbineType: (
    selectedTurbineType: SimpleTurbineType | undefined,
  ) => void;
  averageTurbineHeight: number;
}) {
  const [tempType, setTempType] = useState(selectedTurbineType);
  const [isEditing, toggleIsEditing, setIsEditing] = useBooleanState(false);
  const isEdited = selectedTurbineType !== undefined;
  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);

  const items = useMemo(
    () =>
      Array.from(allTurbineTypes.values()).map((t) => ({
        id: t.turbine.id,
        name: t.turbine.name,
        icon: ["project"].includes(t.level) ? (
          <Tooltip text={"Project turbine"}>
            <ProjectIcon />
          </Tooltip>
        ) : (
          <Tooltip text={"Library turbine"}>
            <LibraryIcon />
          </Tooltip>
        ),
      })),
    [allTurbineTypes],
  );

  /**  We want to stop the user from comparing parks with a big difference in turbine height,
   because of wind data and other inputs that are dependent on the turbine height */

  function isTurbineTypeHeightValid(
    turbineTypeHeight: number | undefined,
  ): boolean {
    if (turbineTypeHeight === undefined) {
      return false;
    }
    return Math.abs(turbineTypeHeight - averageTurbineHeight) > 30;
  }

  return (
    <InputWrapper>
      <Row style={{ alignItems: "center" }}>
        {isEditing ? (
          <div>
            <MenuButton
              icon={<ArrowRightIcon />}
              iconOpen={<ArrowDownIcon />}
              divStyle={{ display: "flex", position: "sticky" }}
              buttonStyle={{
                flexDirection: "row-reverse",
                height: "2.6rem",
                justifyContent: "space-between",
                padding: "0.8rem",
                gap: "1.6rem",
                fontWeight: 600,
                fontSize: "1.2rem",
                backgroundColor: "transparent",
              }}
              buttonType="dropdown"
              buttonText={
                tempType
                  ? allTurbineTypes.get(tempType.id)?.turbine.name ?? "..."
                  : turbineTypesString
              }
              maxWidth={10}
            >
              {items.map((item) => (
                <Tooltip
                  key={item.id}
                  text="The selected turbine type needs to be within 30 meters of the average park hub height"
                  disabled={
                    !isTurbineTypeHeightValid(
                      allTurbineTypes.get(item.id)?.turbine.hubHeight,
                    )
                  }
                  innerDivStyle={{
                    width: "100%",
                  }}
                >
                  <MenuItem
                    key={item.id}
                    name={item.name}
                    disabled={isTurbineTypeHeightValid(
                      allTurbineTypes.get(item.id)?.turbine.hubHeight,
                    )}
                    onClick={() => {
                      setTempType(allTurbineTypes.get(item.id)?.turbine);
                    }}
                    icon={item.icon}
                    style={{
                      width: "100%",
                    }}
                  />
                </Tooltip>
              ))}
            </MenuButton>
          </div>
        ) : (
          <>
            {selectedTurbineType !== undefined
              ? selectedTurbineType.name
              : turbineTypesString}
          </>
        )}
        <Tooltip
          text={
            isEditing
              ? "Confirm"
              : "Changing this will not affect the selected turbine type"
          }
        >
          <Button
            buttonType={isEditing ? "primary" : "secondary"}
            icon={isEditing ? <CheckIcon /> : <PencilIcon />}
            size="small"
            onClick={() => {
              if (isEditing) {
                setSelectedTurbineType(tempType);
              }
              toggleIsEditing();
            }}
          />
        </Tooltip>

        {isEditing && isEdited && (
          <Tooltip text="Reset to default">
            <Button
              buttonType="secondary"
              icon={<UndoIcon />}
              size="small"
              onClick={() => {
                setSelectedTurbineType(undefined);
                setTempType(undefined);
                setIsEditing(false);
              }}
            />
          </Tooltip>
        )}
      </Row>
      {hasChangedTurbineType && (
        <HelpTooltip
          style={{ paddingLeft: "0.8rem" }}
          size={10}
          text="Selected turbine type differs from the turbine types in the park"
          color={colors.iconBrand}
        />
      )}
    </InputWrapper>
  );
}

export function HubHeightSelector({
  formatFunction,
  selectedHubHeight,
  setSelectedHubHeight,
  originalAverageTurbineHeight,
  hasChangedHubHeight,
}: {
  formatFunction(result: number | undefined): React.ReactNode;
  selectedHubHeight: number | undefined;
  originalAverageTurbineHeight: number;
  hasChangedHubHeight: boolean;
  setSelectedHubHeight: (selectedHubHeight: number | undefined) => void;
}) {
  const [tempHeight, setTempHeight] = useState(originalAverageTurbineHeight);
  const [isEditing, toggleIsEditing, setIsEditing] = useBooleanState(false);
  const isEdited = selectedHubHeight !== undefined;

  return (
    <InputWrapper>
      <Row style={{ alignItems: "center" }}>
        {isEditing ? (
          <InputDimensioned
            autoFocus
            compact={true}
            unit={"m"}
            validationMessage={`Needs to be within +/- 20m`}
            validationMessageStyle={{
              transform: "translate(-50%, 110%)",
            }}
            validate={(n) =>
              originalAverageTurbineHeight - 20 <= n &&
              n <= originalAverageTurbineHeight + 20
            }
            value={tempHeight}
            onChange={(inputHeight) => {
              setTempHeight(inputHeight);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                setSelectedHubHeight(tempHeight);
                setIsEditing(false);
              }
            }}
            style={{
              width: "7rem",
              border: "none",
              backgroundColor: "unset",
              alignItems: "center",
            }}
          />
        ) : (
          <>
            {selectedHubHeight !== undefined
              ? formatFunction(selectedHubHeight)
              : formatFunction(originalAverageTurbineHeight)}
          </>
        )}
        <Tooltip
          text={
            isEditing
              ? "Confirm"
              : "Changing this will not affect the selected hub height"
          }
        >
          <Button
            buttonType={isEditing ? "primary" : "secondary"}
            icon={isEditing ? <CheckIcon /> : <PencilIcon />}
            size="small"
            onClick={() => {
              if (isEditing) {
                setSelectedHubHeight(tempHeight);
              }
              toggleIsEditing();
            }}
          />
        </Tooltip>

        {isEditing && isEdited && (
          <Tooltip text="Reset to default">
            <Button
              buttonType="secondary"
              icon={<UndoIcon />}
              size="small"
              onClick={() => {
                setSelectedHubHeight(undefined);
                setTempHeight(originalAverageTurbineHeight);
                setIsEditing(false);
              }}
            />
          </Tooltip>
        )}
      </Row>
      {hasChangedHubHeight && (
        <HelpTooltip
          size={10}
          text="Selected hub height differs from the average turbine height"
          color={colors.iconBrand}
        />
      )}
    </InputWrapper>
  );
}

export function MaxPowerSelector({
  formatFunction,
  selectedMaxPower,
  setSelectedMaxPower,
  parkCapacity,
  hasChangedMaxPower,
}: {
  formatFunction(result: number | undefined): React.ReactNode;
  selectedMaxPower: number | undefined;
  parkCapacity: number;
  hasChangedMaxPower: boolean;
  setSelectedMaxPower: (selectedMaxPower: number | undefined) => void;
}) {
  const [tempMaxPower, setTempMaxPower] = useState<number | undefined>(
    selectedMaxPower,
  );
  const [isEditing, toggleIsEditing, setIsEditing] = useBooleanState(false);
  const isEdited = selectedMaxPower !== undefined;

  return (
    <InputWrapper>
      <Row style={{ alignItems: "center" }}>
        {isEditing ? (
          <InputDimensioned
            autoFocus
            compact={true}
            unit={"MW"}
            validationMessage={`Should be between 80% and 100% of capacity (${Math.round(parkCapacity * 0.8)} - ${parkCapacity} MW)`}
            validationMessageStyle={{
              transform: "translate(-50%, 110%)",
            }}
            validate={(n) => parkCapacity * 0.8 <= n && n <= parkCapacity}
            value={tempMaxPower}
            onChange={(inputMaxPower) => {
              setTempMaxPower(inputMaxPower);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                setSelectedMaxPower(tempMaxPower);
                setIsEditing(false);
              }
            }}
            style={{
              width: "10rem",
              border: "none",
              backgroundColor: "unset",
              alignItems: "center",
            }}
          />
        ) : (
          <>{formatFunction(selectedMaxPower)}</>
        )}
        <Tooltip
          text={
            isEditing
              ? "Confirm"
              : "Limit the net power output to e.g. simulate a restriction on the grid capacity. Changing this will only affect numbers in Compare."
          }
        >
          <Button
            buttonType={isEditing ? "primary" : "secondary"}
            icon={isEditing ? <CheckIcon /> : <PencilIcon />}
            size="small"
            onClick={() => {
              if (isEditing) {
                setSelectedMaxPower(tempMaxPower);
              }
              toggleIsEditing();
            }}
          />
        </Tooltip>

        {isEditing && isEdited && (
          <Tooltip text="Reset">
            <Button
              buttonType="secondary"
              icon={<UndoIcon />}
              size="small"
              onClick={() => {
                setSelectedMaxPower(undefined);
                setTempMaxPower(undefined);
                setIsEditing(false);
              }}
            />
          </Tooltip>
        )}
      </Row>
      {hasChangedMaxPower && (
        <HelpTooltip
          size={10}
          text="The max power limit has been adjusted. This limits the net power output."
          color={colors.iconBrand}
        />
      )}
    </InputWrapper>
  );
}
