import { listArchivedProjectVersions } from "./service";
import { ArchivedProjectVersion } from "./types";
import { atomFamily, atomFromFn } from "utils/jotai";

export function sortArchivedProjectVersions(
  a: ArchivedProjectVersion,
  b: ArchivedProjectVersion,
) {
  return b.createdAt - a.createdAt;
}

export const archivedProjectVersionsAtomFamily = atomFamily(
  ({
    organisationId,
    projectId,
    branchId,
  }: {
    organisationId: string;
    projectId: string;
    branchId: string;
  }) =>
    atomFromFn<Promise<ArchivedProjectVersion[]>>(async () => {
      const res = await listArchivedProjectVersions({
        organisationId,
        projectId,
        branchId,
      });
      return res.archivedVersions.sort(sortArchivedProjectVersions);
    }),
);
