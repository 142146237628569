import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import * as spec from "api/configurations";

export const _VesselType = spec.components.schemas.VesselType;
const _InstallationVesselType = spec.components.schemas.InstallationVesselType;
const _FeederBargeVesselType = spec.components.schemas.FeederBargeVesselType;
const _AnchorHandlingVesselType =
  spec.components.schemas.AnchorHandlingVesselType;
const _CableLayVesselType = spec.components.schemas.CableLayVesselType;
const _ScourProtectionVesselType =
  spec.components.schemas.ScourProtectionVesselType;
export type VesselType = z.infer<typeof _VesselType> & { id: string };
export type InstallationVesselType = z.infer<typeof _InstallationVesselType> & {
  id: string;
};
type FeederBargeVesselType = z.infer<typeof _FeederBargeVesselType> & {
  id: string;
};
type AnchorHandlingVesselType = z.infer<typeof _AnchorHandlingVesselType> & {
  id: string;
};
type CableLayVesselType = z.infer<typeof _CableLayVesselType> & {
  id: string;
};
export type ScourProtectionVesselType = z.infer<
  typeof _ScourProtectionVesselType
> & { id: string };
export type VesselTypePostBody = z.input<
  (typeof spec)["paths"]["/api/vessel/node/{nodeId}"]["post"]["requestBody"]["application/json"]
>;

export async function createVesselType(
  nodeId: string,
  vesselType: VesselTypePostBody,
): Promise<VesselType> {
  return fetchEnhancerWithToken(`/api/vessel/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(vesselType),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    return _VesselType.parse(j);
  });
}
export async function updateVesselType(
  nodeId: string,
  vesselType: z.infer<
    (typeof spec)["paths"]["/api/vessel/node/{nodeId}/type/{vesselId}"]["post"]["requestBody"]["application/json"]
  >,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/vessel/node/${nodeId}/type/${vesselType.id}`,
    {
      method: "post",
      body: JSON.stringify(vesselType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to update");
  });
}

export async function deleteVesselType(
  nodeId: string,
  vesselTypeId: string,
): Promise<void> {
  return fetchEnhancerWithToken(
    `/api/vessel/node/${nodeId}/type/${vesselTypeId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to delete");
  });
}

export const isInstallationVessel = (
  t: VesselType | undefined,
): t is InstallationVesselType => t?.type === "installation";
export const isFeederBargeVessel = (
  t: VesselType | undefined,
): t is FeederBargeVesselType => t?.type === "feederBarge";
export const isAnchorHandlingVessel = (
  t: VesselType | undefined,
): t is AnchorHandlingVesselType => t?.type === "anchorHandling";
export const isCableLayVessel = (
  t: VesselType | undefined,
): t is CableLayVesselType => t?.type === "cableLay";
export const isScourProtectionVessel = (
  t: VesselType | undefined,
): t is ScourProtectionVesselType => t?.type === "scourProtection";

export enum VesselTypeIds {
  Installation = "installation",
  FeederBarge = "feederBarge",
  AnchorHandling = "anchorHandling",
  CableLay = "cableLay",
  ScourProtection = "scourProtection",
}

export const VesselTypeNames = {
  [VesselTypeIds.Installation]: "Installation vessel",
  [VesselTypeIds.FeederBarge]: "Feeder barge",
  [VesselTypeIds.AnchorHandling]: "Anchor handling vessel",
  [VesselTypeIds.CableLay]: "Cable lay vessel",
  [VesselTypeIds.ScourProtection]: "Scour protection vessel",
};
