import { useAtomValue } from "jotai";
import React from "react";
import styled from "styled-components";
import { spacing7 } from "styles/space";
import { Divider } from "../../shared";
import { editorAccessProjectSelector } from "state/user";
import CompareParksModalButtonV2 from "./CompareParksModalButtonV2";
import DashboardModalButtonV2 from "./DashboardModalButtonV2";
import ComponentsModalButton from "./ComponentsModalButton";
import ConfigurationsModalButton from "./ConfigurationsModalButton";

const MiddleWrapper = styled.div`
  display: flex;
  gap: ${spacing7};
  flex-basis: calc(100% / 3);
  justify-content: center;
`;

const Middle = () => {
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);

  return (
    <MiddleWrapper id="middle-wrapper">
      <CompareParksModalButtonV2 />
      <DashboardModalButtonV2 />
      {isCustomerEditor && (
        <>
          <Divider
            style={{
              marginTop: "0.7rem",
            }}
          />
          <ComponentsModalButton />
          <ConfigurationsModalButton />
        </>
      )}
    </MiddleWrapper>
  );
};

export default Middle;
