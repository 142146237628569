export function binarySearchClosest(list: number[], value: number): number {
  let left = 0;
  let right = list.length - 1;
  let closest = 0;
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (list[mid] === value) {
      return mid;
    } else if (list[mid] < value) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
    if (Math.abs(list[mid] - value) < Math.abs(list[closest] - value)) {
      closest = mid;
    }
  }
  return closest;
}
