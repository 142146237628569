import { unwrap } from "jotai/utils";
import { CenterContainer, LoadingState, SafeCard } from "../Base";
import { useDashboardContext } from "../../Dashboard";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { getClosestWaveDataSelector } from "state/waveStatistics";
import { WaveHeightHistogram } from "components/WindWaveMeasurementInfoModal/WaveHeightHistogram";
import { useAtomValue } from "jotai";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { getAnalysisWindStats } from "analysis/output";

const WaveGraph = () => {
  const { triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const stopped = stoppedReason ?? analysisStoppedReason;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return <Inner />;
};

const Inner = () => {
  const { triggerId } = useDashboardContext();
  const windStats = useAtomValue(getAnalysisWindStats(triggerId));
  const waveData = useAtomValue(
    unwrap(
      getClosestWaveDataSelector({
        lon: windStats?.longitude,
        lat: windStats?.latitude,
        source: undefined,
      }),
    ),
  );

  if (!waveData) return <LoadingState />;

  return <WaveHeightHistogram data={waveData} />;
};

export const WaveDistributionWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Wave height distribution"
      id="Wave height distribution"
      resetKeys={errorBoundaryResetKeys}
    >
      <WaveGraph />
    </SafeCard>
  );
};
