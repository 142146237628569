import React, { useMemo, useCallback, useState, useEffect } from "react";
import { addRecentSearch, getStoredSearches } from "./recentSearches";
import { SearchItem } from "./state";
import { Divider, DividerHeader, ElementRow } from "./style";
import { MainText } from "./style";
import { Column, Row } from "components/General/Layout";
import { SVGWrapper } from "@icons/svgUtils";
import { isDefined } from "utils/predicates";

type RecentSearchesProps = {
  searchableItems: SearchItem<any>[];
  closeSearch: () => void;
  trackRecentSearchSelection: (item: SearchItem<any>, type: string) => void;
};

export default function RecentSearchView({
  closeSearch,
  searchableItems,
  trackRecentSearchSelection,
}: RecentSearchesProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const recentItems = useMemo(() => {
    const stored = getStoredSearches();
    if (stored.length === 0) return [];

    // Find matching items from all groups
    return stored
      .map((storedItem) => {
        const searchItemMatch = searchableItems.find(
          (item) => item.id === storedItem.id,
        );
        if (!searchItemMatch) return undefined;

        return {
          item: searchItemMatch,
          groupTitle: storedItem.type,
        };
      })
      .filter(isDefined);
  }, [searchableItems]);

  const onSelect = useCallback(
    (item: SearchItem<any>, type: string) => {
      addRecentSearch(item.id, type);
      item.onSelect();
      trackRecentSearchSelection(item, type);
      closeSearch();
    },
    [closeSearch, trackRecentSearchSelection],
  );

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      if (!recentItems.length) return;

      switch (event.key) {
        case "ArrowDown":
        case "ArrowUp": {
          event.preventDefault();
          const direction = event.key === "ArrowDown" ? 1 : -1;
          setSelectedIndex((current) => {
            const nextIndex =
              (current + direction + recentItems.length) % recentItems.length;
            return nextIndex;
          });
          break;
        }
        case "Enter": {
          event.preventDefault();
          if (selectedIndex >= 0 && selectedIndex < recentItems.length) {
            const { item, groupTitle } = recentItems[selectedIndex];
            onSelect(item, groupTitle);
          }
          break;
        }
      }
    },
    [recentItems, selectedIndex, onSelect],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);
    return () => window.removeEventListener("keydown", handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  if (recentItems.length === 0) return null;

  return (
    <Column style={{ padding: "0.8rem 0" }}>
      <Row style={{ alignItems: "center", gap: "0.8rem" }}>
        <Row style={{ alignItems: "center", gap: "0.8rem", width: "100%" }}>
          <DividerHeader style={{ margin: 0 }}>Recent searches</DividerHeader>
          <Divider />
        </Row>
      </Row>
      {recentItems.map(({ item, groupTitle }, index) => (
        <ElementRow
          key={`recent-${item.id}`}
          role="listitem"
          selected={selectedIndex === index}
          onClick={() => onSelect(item, groupTitle)}
        >
          <SVGWrapper size={1.6}>{item.icon}</SVGWrapper>
          {typeof item.name === "string" ? (
            <MainText>{item.name}</MainText>
          ) : (
            item.name
          )}
        </ElementRow>
      ))}
    </Column>
  );
}
