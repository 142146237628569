import { useAtomValue } from "jotai";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MenuItem } from "components/General/Menu";
import SinglePersonIcon from "@icons/24/SinglePerson.svg";
import { UserProfileModalType } from "components/UserProfileModal/UserProfileModal";
import LogoutIcon from "@icons/14/Logout.svg";
import { LOCAL_STORAGE_TOKEN_KEY, loggedInUserAtom } from "state/user";
import { invalidateToken } from "services/invalidateTokenServiceAPI";
import { useSetAtom } from "jotai/index";
import { midScreenModalTypeOpenAtom } from "state/modal";
import ProfileDropdownMenuButton from "components/ProfileDropdownMenuButton/ProfileDropdownMenuButton";

const ProfileDropdown = () => {
  const user = useAtomValue(loggedInUserAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { logout } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <ProfileDropdownMenuButton>
      <MenuItem
        name="Profile settings"
        icon={<SinglePersonIcon />}
        onClick={() => {
          setMidScreenModalTypeOpen({
            modalType: UserProfileModalType,
            metadata: {
              user,
            },
          });
        }}
      />
      <MenuItem
        name="Logout"
        icon={<LogoutIcon />}
        onClick={async () => {
          localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
          await invalidateToken();
          logout({
            returnTo: "https://vind.ai",
          });
        }}
      />
    </ProfileDropdownMenuButton>
  );
};

export default ProfileDropdown;
