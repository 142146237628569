import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { parkFamily } from "state/jotai/park";
import { cableCorridorsInParkFamily } from "state/jotai/cableCorridor";

export const cableCorridorNotConnectedToParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return undefined;
      const corridors = await get(
        cableCorridorsInParkFamily({ parkId, branchId }),
      );
      const disconnected = corridors.filter((cc) => {
        const union = turf.union(cc, park);
        return union == null || union.geometry.type.includes("Multi");
      });
      if (disconnected.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.CableCorridorNotConnectedToPark,
        featureIds: disconnected.map((c) => c.id),
      } as const;
    }),
);
