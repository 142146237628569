import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback } from "react";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import {
  createProjectCableType,
  CableType,
  updateProjectCableType,
  deleteProjectCableType,
} from "../services/cableTypeService";
import { scream } from "utils/sentry";
import { aset, useJotaiCallback } from "utils/jotai";
import { _projectArrayAndExportCableTypesFamily } from "state/jotai/cableType";

const useCableTypeCrud = () => {
  const projectId = useAtomValue(projectIdAtom);
  const setIsAutoSaving = useSetAtom(savingConfigurationInProgressAtom);

  const updateLocal = useJotaiCallback(
    (get, set, projectId: string, cableType: CableType) => {
      return aset(
        get,
        set,
        _projectArrayAndExportCableTypesFamily(projectId),
        (cur) => {
          const exists = cur.some((c) => c.id === cableType.id);
          if (exists) {
            return cur.map((c) => (c.id === cableType.id ? cableType : c));
          } else {
            return [...cur, cableType];
          }
        },
      );
    },
    [],
  );

  const deleteLocal = useJotaiCallback(
    (get, set, projectId: string, cableId: string) => {
      return aset(
        get,
        set,
        _projectArrayAndExportCableTypesFamily(projectId),
        (cur) => cur.filter((c) => c.id !== cableId),
      );
    },
    [],
  );

  const create = useCallback(
    (cableType: Partial<CableType>) => {
      if (!projectId) throw scream("Missing projectId");
      setIsAutoSaving(true);
      return createProjectCableType(projectId, cableType)
        .then((cable) => {
          updateLocal(projectId, cable);
          return cable;
        })
        .finally(() => setIsAutoSaving(false));
    },
    [projectId, setIsAutoSaving, updateLocal],
  );

  const update = useCallback(
    (cableType: CableType) => {
      if (!projectId) throw scream("Missing projectId");

      setIsAutoSaving(true);
      updateLocal(projectId, cableType);
      return updateProjectCableType(projectId, cableType).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [projectId, setIsAutoSaving, updateLocal],
  );
  const deleteCable = useCallback(
    (cableId: string) => {
      if (!projectId) throw scream("Missing projectId");

      setIsAutoSaving(true);
      deleteLocal(projectId, cableId);
      return deleteProjectCableType(projectId, cableId).finally(() =>
        setIsAutoSaving(false),
      );
    },
    [deleteLocal, projectId, setIsAutoSaving],
  );

  return {
    create,
    update,
    deleteCable,
    updateLocal,
    deleteLocal,
  };
};

export default useCableTypeCrud;
