import { fetchEnhancerWithToken, fetchSchemaWithToken } from "services/utils";
import { OrganisationResources, _OrgResource } from "./types";

const ORG_RESOURCE_TO_LIBRARY_NAME: Record<OrganisationResources, string> = {
  org_foundation_manage: "foundation",
  org_turbine_manage: "turbine",
  org_cable_manage: "cable",
  org_export_cable_manage: "exportcable",
  org_analysis_manage: "analysis",
  org_financial_manage: "financial",
  org_data_package_manage: "datapackage",
  org_substation_manage: "substation",
};

export const getCurrentUserOrganisationResources = (organisationId: string) =>
  fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/customer/organisation/${organisationId}/user/resources`,
    {
      method: "get",
    },
  );
export const getUserOrganisationResources = (
  organisationId: string,
  userId: string,
) =>
  fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/customer/organisation/${organisationId}/user/${encodeURIComponent(
      userId,
    )}/resources`,
    {
      method: "get",
    },
  );

/**
 * Get all resource accesses for users in an organisation
 */
export const getUsersOrganisationResources = (
  organisationId: string,
  userIds: string[],
) =>
  fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/customer/organisation/${organisationId}/users/resources`,
    {
      method: "post",
      body: JSON.stringify(userIds),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const getGroupOrganisationResources = (
  organisationId: string,
  groupId: string,
) =>
  fetchSchemaWithToken(
    _OrgResource.array(),
    `/api/customer/organisation/${organisationId}/group/${groupId}/resources`,
    {
      method: "get",
    },
  );

export const addLibraryResourceManager = (
  organisationId: string,
  userOrGroupId: string,
  resourceName: OrganisationResources,
  type: "group" | "user",
) =>
  fetchSchemaWithToken(
    _OrgResource,
    `/api/library/libraryaccess/organisation/${organisationId}/manager`,
    {
      method: "put",
      body: JSON.stringify({
        resource_name: resourceName,
        type,
        id: userOrGroupId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const removeUserLibraryResourceManager = (
  organisationId: string,
  userId: string,
  resourceName: OrganisationResources,
) => {
  const type = ORG_RESOURCE_TO_LIBRARY_NAME[resourceName];
  return fetchEnhancerWithToken(
    `/api/library/libraryaccess/organisation/${organisationId}/${type}/manager/user/${encodeURIComponent(
      userId,
    )}`,
    {
      method: "delete",
    },
  );
};
export const removeGroupLibraryResourceManager = (
  organisationId: string,
  groupId: string,
  resourceName: OrganisationResources,
) => {
  const type = ORG_RESOURCE_TO_LIBRARY_NAME[resourceName];
  return fetchEnhancerWithToken(
    `/api/library/libraryaccess/organisation/${organisationId}/${type}/manager/group/${groupId}`,
    {
      method: "delete",
    },
  );
};
