import { Position } from "@turf/turf";
import { Raster } from "types/raster";
import { isLineStringFeature } from "utils/predicates";
import * as turf from "@turf/turf";
import { Feature, LineString, Point } from "geojson";
import { ExportCableFeature } from "types/feature";
import { Tile } from "types/tile";
import { tile2bbox, lonLatToTile, tilePrecisionM } from "types/tile";
import { mapboxAccessToken } from "components/MapNative/constants";
import { scream } from "utils/sentry";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { featureMapFamily } from "./features";
import { exportCablesInParkFamily } from "./exportCable";

const url = ({ x, y, z }: Tile) =>
  `https://api.mapbox.com/v4/mapbox.mapbox-terrain-dem-v1/${z}/${x}/${y}.pngraw?access_token=${mapboxAccessToken}`;

const fetchTerrain = async (t: Tile): Promise<Raster> => {
  const TILE_SIZE = 256;
  const res = await fetch(url(t));
  const blob = await res.blob();

  const canvas = document.createElement("canvas");
  canvas.height = canvas.width = TILE_SIZE;

  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Unable to get canvas context");

  const img = new Image();
  img.src = URL.createObjectURL(blob);
  await new Promise((res) => {
    img.onload = () => res(img);
  });
  if (!ctx) throw new Error("Unable to get canvas context");
  ctx.drawImage(img, 0, 0);

  const imageData = ctx.getImageData(0, 0, TILE_SIZE, TILE_SIZE);

  const bbox = tile2bbox(t);

  function decodeElevation(color: number[]): number {
    // elevation = -10000 + (({R} * 256 * 256 + {G} * 256 + {B}) * 0.1)
    const R = color[0];
    const G = color[1];
    const B = color[2];
    const elevation = -10000.0 + (R * 256.0 * 256.0 + G * 256.0 + B) * 0.1;
    return elevation;
  }

  const elevations = [];
  for (let i = 0; i < imageData.data.length; i += 4) {
    // NOTE: skip the alpha channel
    const color = [
      imageData.data[i + 0],
      imageData.data[i + 1],
      imageData.data[i + 2],
    ];
    const elevation = decodeElevation(color);
    elevations.push(elevation);
  }

  const raster = new Raster(
    elevations,
    TILE_SIZE,
    TILE_SIZE,
    bbox[0],
    bbox[3],
    (bbox[2] - bbox[0]) / TILE_SIZE,
    (bbox[3] - bbox[1]) / TILE_SIZE,
  );

  return raster;
};

export const fetchTerrainFamily = atomFamily((tile: Tile) =>
  atom(() => fetchTerrain(tile)),
);

const elevationAtPoint = atomFamily(
  ({ position, zoom }: { position: Position; zoom: number }) =>
    atom(async (get) => {
      const [x, y] = position;
      const tile = lonLatToTile(x, y, zoom);
      const raster = await get(fetchTerrainFamily(tile));
      return raster.latLngToValue(x, y, 0);
    }),
);

/**
 * Split a LineString at a given distance from the start. The first returned
 * segment is from the start and up to the given distance. The second returned
 * segment is from the given distance and to the end.
 */
const splitLineAt = (
  ls: Feature<LineString>,
  km: number,
): [Feature<LineString>, Feature<LineString>] => {
  const len = turf.length(ls);
  if (len < km) {
    scream("splitLineAt: len < km: ", { len, km, geometry: ls.geometry });
  }
  const first = turf.lineSliceAlong(ls, 0, km);
  const last = turf.lineSliceAlong(ls, km, len);
  return [first, last];
};

export enum SplitAtLandfallPointErr {
  IllegalFeatureId,
  FeatureNotALineString,
  RequestedPrecisionTooHigh,
  MissingElevationDataAtEndpoints,
  BothEndsOffshore,
  BothEndsOnshore,
  MissingElevationDataAtPoint,
  FailedToConverge,
}

/**
 * Returns the offshore segment, the landfall point, and the onshore segment for a cable in current branch.
 */
const splitAtLandfallPointFamily = atomFamily(
  ({
    id,
    branchId,
    precisionM,
  }: {
    id: string;
    branchId: string | undefined;
    precisionM: number;
  }) =>
    atom<
      Promise<
        | [Feature<LineString>, Feature<Point>, Feature<LineString>]
        | SplitAtLandfallPointErr
      >
    >(async (get) => {
      const feature = (await get(featureMapFamily({ branchId }))).get(id);
      if (!feature) return SplitAtLandfallPointErr.IllegalFeatureId;
      if (!isLineStringFeature(feature))
        return SplitAtLandfallPointErr.FeatureNotALineString;
      const g = feature.geometry;

      // Check that the precision is okay
      {
        const tile = lonLatToTile(g.coordinates[0][0], g.coordinates[0][1], 14);
        if (tile) {
          const maxPrecision = tilePrecisionM(tile);
          if (precisionM < maxPrecision)
            return SplitAtLandfallPointErr.RequestedPrecisionTooHigh;
        }
      }

      // Make sure that the line string is oriented from offshore to onshore
      const firstElev = await get(
        elevationAtPoint({ position: g.coordinates[0], zoom: 10 }),
      );
      const lastElev = await get(
        elevationAtPoint({ position: g.coordinates.at(-1)!, zoom: 10 }),
      );
      if (firstElev === undefined || lastElev === undefined)
        return SplitAtLandfallPointErr.MissingElevationDataAtEndpoints;
      let f = feature;
      const didReverse = lastElev === 0.0 && firstElev !== 0.0;
      if (didReverse) {
        f = {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: [...feature.geometry.coordinates].reverse(),
          },
        };
      }

      if (firstElev === 0.0 && lastElev === 0.0)
        return SplitAtLandfallPointErr.BothEndsOffshore;
      if (firstElev !== 0.0 && lastElev !== 0.0)
        return SplitAtLandfallPointErr.BothEndsOnshore;

      // Find the landfall point, binary search style.
      // Track the two distances in which we believe the landfall is in.
      // This starts at [0, length] and splits in the middle each iteration.
      let lenFromStart = 0.0;
      let lenToEnd = turf.length(f);

      // Ensure we're not in an infinite loop. Max 20 subdivides means we can
      // search from a span of 2^20 = 1,048,576 meters to 2^0 = 1 meter, which
      // should be enough.
      for (let _guard = 0; _guard < 22.0; _guard++) {
        const jumpKm = (lenToEnd - lenFromStart) / 2;
        const lenToMiddle = lenFromStart + jumpKm;
        const middlePt = turf.along(f, lenToMiddle);

        const elev = await get(
          elevationAtPoint({
            position: middlePt.geometry.coordinates,
            zoom: 12,
          }),
        );
        if (elev === undefined)
          return SplitAtLandfallPointErr.MissingElevationDataAtPoint;
        if (elev === 0.0) {
          lenFromStart = lenToMiddle;
        } else {
          const ourPrecision = (jumpKm * 1000.0) / 2;
          if (ourPrecision < precisionM) {
            const [first, last] = splitLineAt(f, lenToMiddle);
            const bearingMiddle = turf.along(f, lenToMiddle);
            return [first, bearingMiddle, last];
          }
          lenToEnd = lenToMiddle;
        }
      }

      return SplitAtLandfallPointErr.FailedToConverge;
    }),
);

export type ExportCableSplit = {
  exportCable: ExportCableFeature;
  offshore: Feature<LineString>;
  landfallPoint: Feature<Point>;
  onshore: Feature<LineString>;
};

export type ExportCableSplitErr = {
  exportCable: ExportCableFeature;
  error: SplitAtLandfallPointErr;
};

/**
 * Get all splits for all export cables in the given park and branch.
 * See {@link splitAtLandfallPointFamily} for details.
 */
export const exportCableSplitsFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<(ExportCableSplit | ExportCableSplitErr)[]>>(async (get) => {
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const ret = [];
      for (const exportCable of exportCables) {
        const split = await get(
          splitAtLandfallPointFamily({
            id: exportCable.id,
            branchId,
            precisionM: 10,
          }),
        );
        if (typeof split === "number") {
          ret.push({
            exportCable,
            error: split,
          });
        } else {
          const [offshore, landfallPoint, onshore] = split;
          ret.push({
            exportCable,
            offshore,
            landfallPoint,
            onshore,
          });
        }
      }
      return ret;
    }),
);

export const exportCableSplitsOkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<ExportCableSplit[]>>(async (get) => {
      const splits = await get(exportCableSplitsFamily({ parkId, branchId }));
      return splits.filter((s): s is ExportCableSplit => !("error" in s));
    }),
);
