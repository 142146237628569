import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { projectIdAtom } from "./pathParams";
import { projectWindConfigurationsFamily } from "./jotai/windConfiguration";

export const windConfigurationUsageRefresh = atomFamily(
  (__input: { nodeId: string; windConfigurationId: string }) =>
    atom<number>(
      (() => {
        return 0;
      })(),
    ),
);

// This is used to store the config name while still editing the config
// to avoid rendering the component and loose the values that is stored in LocalConfig
export const windConfigurationTempName = atomFamily(
  (__input: { nodeId: string }) =>
    atom<{ [key: string]: string | undefined }>({}),
);

export const uploadedWindDataSourceUsageAtomFamily = atomFamily(
  ({ uploadedWindDataId }: { uploadedWindDataId: string }) =>
    atom<Promise<number>>(async (get) => {
      const projectId = get(projectIdAtom);
      if (!projectId) return 0;
      const configurations = await get(
        projectWindConfigurationsFamily(projectId),
      );
      let count = 0;
      for (const config of configurations.values()) {
        if (
          (config.source.type === "custom" &&
            config.source.id === uploadedWindDataId) ||
          (config.spatial_calibration?.type === "custom" &&
            config.spatial_calibration?.id === uploadedWindDataId)
        )
          count += 1;
      }
      return count;
    }),
);

export const savingWindConfigurationInProgressAtom = atom<boolean>(false);
