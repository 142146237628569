import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { orLoader } from "components/Loading/Skeleton";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { TurbineFeature } from "../../../../types/feature";
import { ColoredGrid } from "../../../General/Form";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { useAtomValue } from "jotai";
import { ProdId } from "analysis/inputs";
import {
  getAEPPerTurbine,
  getAverageSpeedPerTurbine,
  getTotalWakeLossPerTurbine,
} from "analysis/output";
import { loadable, unwrap } from "jotai/utils";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { lmap } from "utils/jotai";
import { selectedTurbinesAtom } from "state/jotai/selection";

const Inner = ({ id, feature }: { id: ProdId; feature: TurbineFeature }) => {
  const aepL = useAtomValue(loadable(getAEPPerTurbine(id)));
  const aep = lmap(
    aepL,
    (list) => list?.find((t) => t.turbine.id === feature.id)?.value,
  );

  const wakeLossL = useAtomValue(loadable(getTotalWakeLossPerTurbine(id)));
  const wakeLoss = lmap(
    wakeLossL,
    (list) => list?.find((t) => t.turbine.id === feature.id)?.value,
  );

  const averageSpeedL = useAtomValue(loadable(getAverageSpeedPerTurbine(id)));
  const averageSpeed = lmap(
    averageSpeedL,
    (list) => list?.find((t) => t.turbine.id === feature.id)?.value,
  );

  const stoppedReason = useAtomValue(unwrap(getStoppedReason(id)));
  const analysisStoppedReason = useAtomValue(unwrap(getAnalysisError(id)));

  const stopped = stoppedReason || analysisStoppedReason;

  const selectedTurbines = useAtomValue(selectedTurbinesAtom);

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbineType = turbineTypes.get(feature.properties.turbineTypeId);

  if (stopped) {
    return (
      <div>
        <SimpleAlert
          text={analysisStoppedText[stopped]}
          type={"error"}
          title="Analysis stopped"
        ></SimpleAlert>
      </div>
    );
  }

  return (
    <>
      {1 < selectedTurbines.length && (
        <div style={{ paddingBottom: "1rem" }}>
          <SimpleAlert
            type="info"
            text={`${selectedTurbines.length} turbines are selected, but we only show information for one:`}
          />
        </div>
      )}
      <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
        <p>Turbine name:</p>
        <p>{feature.properties.name}</p>
        <p>Turbine type:</p>
        <p>{turbineType?.name}</p>

        <p>AEP:</p>
        {orLoader(aep, (aep) => (
          <p>{aep?.toFixed(0)} GWh</p>
        ))}

        <p>Wake loss:</p>
        {orLoader(wakeLoss, (wakeLoss) => (
          <p>{((wakeLoss ?? 0) * 100).toFixed(2)} %</p>
        ))}

        <p>Mean speed:</p>
        {orLoader(averageSpeed, (speed) => (
          <p>{speed?.toFixed(2)} m/s</p>
        ))}
      </ColoredGrid>
    </>
  );
};

const TurbineModal = ({
  canvasFeature,
  onClose,
}: {
  canvasFeature: TurbineFeature;
  onClose(): void;
}) => {
  return (
    <MenuFrame title={"Turbine statistics"} onExit={onClose}>
      <Inner id={currentSelectionProduction} feature={canvasFeature} />
    </MenuFrame>
  );
};

export default TurbineModal;
