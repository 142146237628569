import { SensorFeature } from "types/feature";
import {
  sensorPointLayerId,
  sensorPointSourceId,
  sensorPointSymbolLayerId,
} from "./constants";
import { atom, useAtomValue } from "jotai";
import { CirclePaint, SymbolLayerSpecification } from "mapbox-gl";
import { colors } from "styles/colors";
import {
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import { useEffect, useMemo } from "react";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { safeRemoveLayer } from "utils/map";
import { pointOpacityOutsidePark } from "./defaults";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const sensorPointCirclePaintAtom = atom<CirclePaint>(() => {
  return {
    "circle-color": colors.sensor,
    "circle-opacity": pointOpacityOutsidePark,
    "circle-radius": defaultPointCircleRadius,
    "circle-stroke-color": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      colors.lockedFeatureOutline,
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      ["boolean", ["feature-state", "selected"], false],
      colors.white,
      ["boolean", ["feature-state", "hover"], false],
      colors.white,
      colors.sensor,
    ],
    "circle-stroke-width": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      2.0,
      ["!=", ["feature-state", "borderColor"], null],
      2.0,
      ["boolean", ["feature-state", "selected"], false],
      2.0,
      ["boolean", ["feature-state", "hover"], false],
      2.0,
      0.0,
    ],
  };
});

const useSensorPointSymbolLayer: () => SymbolLayerSpecification = () => {
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  return useMemo(
    () => ({
      id: sensorPointSymbolLayerId,
      source: sensorPointSourceId,
      type: "symbol",
      minzoom: 8,
      layout: {
        "symbol-placement": "point",
        "text-field": "{name}",
        "text-offset": [0, -2],
        "text-size": 10,
        "text-anchor": "center",
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
      paint: defaultMapTextPaint,
    }),
    [defaultMapTextPaint],
  );
};

export const RenderSensorPoints = ({
  features,
  map,
  symbols,
  showSelectionHighlighter,
}: {
  features: SensorFeature[];
  map: mapboxgl.Map;
  symbols?: boolean;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const circlePaint = useAtomValue(sensorPointCirclePaintAtom);
  const sensorPointSymbolLayer = useSensorPointSymbolLayer();
  useEffect(() => {
    map.addSource(sensorPointSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: sensorPointLayerId,
      type: "circle",
      source: sensorPointSourceId,
      paint: circlePaint,
    });
    if (symbols) {
      addLayer(map, sensorPointSymbolLayer);
    }

    return () => {
      if (!map) return;
      safeRemoveLayer(map, sensorPointLayerId);
      if (symbols) {
        safeRemoveLayer(map, sensorPointSymbolLayer.id);
      }
      map.removeSource(sensorPointSourceId);
    };
  }, [map, symbols, circlePaint, sensorPointSymbolLayer]);

  useEffect(() => {
    const source = map.getSource(sensorPointSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={sensorPointSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
