import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { mapInteractionSelector } from "../../../../state/map";
import PasteIcon from "@icons/24/Paste.svg?react";
import useSystemSpecificUnicode from "../../../../hooks/useSystemSpecificUnicode";
import useAddFeaturesIntoElementsWithinLimits from "../../../../hooks/useAddFeaturesIntoElementsWithinLimits";
import {
  getClipboardCoordinatesIfContainingVindCoordinates,
  getClipboardFeaturesIfContainingVindFeatures,
} from "components/MapControls/CopyPaste/getClipboardFeaturesIfContainingVindFeatures";
import { MenuItem } from "../../../General/Menu";
import usePasteFeatures from "components/MapControls/CopyPaste/usePasteFeatures";
import { UploadFileType } from "components/UploadModal/components/NewUploadTab/types";
import { UploadModalType } from "components/UploadModal/UploadModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { uploadFeaturesFromCoordinatesStateAtom } from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/UploadFeaturesFromCoordinatesGeneral";

const PasteRowMenuItem = ({ closeMenu }: { closeMenu: () => void }) => {
  const stringToUnicode = useSystemSpecificUnicode();
  const addFeaturesToElementsWithinLimits =
    useAddFeaturesIntoElementsWithinLimits();
  const { pasteFeatures } = usePasteFeatures();
  const [enabled, setEnabled] = useState(false);
  const mapInteractions = useAtomValue(mapInteractionSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setUploadFeaturesFromCoordinatesState = useSetAtom(
    uploadFeaturesFromCoordinatesStateAtom,
  );

  const pasteCallback = useCallback(async () => {
    const vindCoordinates =
      await getClipboardCoordinatesIfContainingVindCoordinates();
    if (vindCoordinates) {
      setUploadFeaturesFromCoordinatesState(vindCoordinates);
      setMidScreenModalTypeOpen({
        modalType: UploadModalType,
        metadata: {
          preSelectedFileType: UploadFileType.FEATURE_FROM_COORDINATES,
        },
      });
      return;
    }

    const vindFeatureData =
      await getClipboardFeaturesIfContainingVindFeatures();
    if (!vindFeatureData) {
      return;
    }
    pasteFeatures(vindFeatureData, addFeaturesToElementsWithinLimits);
  }, [
    addFeaturesToElementsWithinLimits,
    pasteFeatures,
    setMidScreenModalTypeOpen,
    setUploadFeaturesFromCoordinatesState,
  ]);

  useEffect(() => {
    const asyncFunc = async () => {
      const vindCoordinates =
        await getClipboardCoordinatesIfContainingVindCoordinates();
      if (vindCoordinates) {
        setEnabled(true);
        return;
      }

      const json = await getClipboardFeaturesIfContainingVindFeatures();
      setEnabled(!!json);
    };
    asyncFunc();
  }, []);

  return (
    <MenuItem
      disabled={!enabled || !mapInteractions.paste}
      name={"Paste"}
      icon={<PasteIcon />}
      shortcut={`${stringToUnicode("command")}+V`}
      onClick={() => {
        pasteCallback();
        closeMenu();
      }}
    />
  );
};

export default PasteRowMenuItem;
