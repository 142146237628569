import { FolderOrResourceItem } from "types/folderStructures";
import { atomWithDefault } from "jotai/vanilla/utils";
import { atomFamily } from "utils/jotai";
import { getOrgFoldersForType } from "services/orgFolderService";
import { sortFolderTree } from "business/folderStructure/utils";

export const orgFolderStructureFamily = atomFamily(
  ({
    orgId,
    libraryManageRole,
  }: {
    orgId: string;
    libraryManageRole: string;
  }) =>
    atomWithDefault<Promise<FolderOrResourceItem[]>>(async (_get) => {
      return getOrgFoldersForType(orgId, libraryManageRole).then(
        (folderOrResources) => {
          return folderOrResources.sort(sortFolderTree);
        },
      );
    }),
);
