import { Suspense, useCallback, useEffect, useState } from "react";
import { idToDataPackageChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import PackageSection from "components/Organisation/Library/dataLibrary/PackageSection/PackageSection";
import { useDataLibraryLayersCrud } from "components/Organisation/Library/dataLibrary/useDataLibraryLayersCrud";
import AllDataSection from "components/Organisation/Library/dataLibrary/AllDataSection/AllDataSection";
import { DataPackageProjects } from "./availability/DataPackageProjects";
import { OrganisationResourceChangelogButton } from "components/Changelog/Changelog";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const DatapackageResourceContent = ({
  resource,
  organisationId,
}: {
  resource: DataLibraryPackage;
  organisationId: string;
}) => {
  const { updatePackage } = useDataLibraryLayersCrud();

  const [tempPackage, setTempPackage] = useState(resource);

  useEffect(() => {
    setTempPackage(resource);
  }, [resource, setTempPackage]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<DataLibraryPackage>) => {
      setTempPackage((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempPackage],
  );

  if (!tempPackage) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"data package"}
        description={tempPackage.description ?? ""}
        name={tempPackage.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempPackage.name,
            description: description ?? tempPackage.description,
          })
        }
        onSave={({ name, description }) => {
          updatePackage(organisationId, tempPackage.id, {
            name: name ?? tempPackage.name,
            description: description ?? tempPackage.description,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            description: resource.description,
          });
        }}
        changelogData={{
          id: idToDataPackageChangelogId(resource.id),
          category: "org_data_package_manage",
        }}
      >
        <OrganisationResourceChangelogButton
          organisationId={organisationId}
          resourceId={idToDataPackageChangelogId(resource.id)}
          category={"org_data_package_manage"}
        />
      </LibraryResourceHeader>

      <ResourceContentTabs tabs={datapackageTabs(resource)} />
    </>
  );
};

const datapackageTabs = (resource: DataLibraryPackage) => [
  {
    name: "GIS layers",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        {resource.id === undefined ? (
          <AllDataSection />
        ) : (
          <PackageSection packageId={resource.id} />
        )}
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <DataPackageProjects resource={resource} />
      </Suspense>
    ),
  },
];
