import { useAtomValue, useSetAtom } from "jotai";
import { viewerAccessProjectSelector } from "state/user";
import ChannelProviderWrapper from "../../ChannelProviderWrapper";
import { useAblyBathymetryListener } from "components/Bathymetry/useAblyGetBathymetryIncludingCustom";
import { useAblySlopeBathymetryListener } from "components/Bathymetry/useAblyGetSlopeIncludingCustom";
import useAblyAutosaveHistorySync from "components/Design/FeatureHistory/useAblyAutosaveHistorySync";
import { useAblyProjectAnalysisConfiguration } from "hooks/AblyProjectComponents/useAblyConfiguration";
import useAblyCostConfiguration from "hooks/AblyProjectComponents/useAblyCostConfiguration";
import { useAblyProjectMooringLine } from "hooks/AblyProjectComponents/useAblyMooringLines";
import { useAblyProjectCable } from "hooks/AblyProjectComponents/useAblyProjectCable";
import { useAblyProjectFoundation } from "hooks/AblyProjectComponents/useAblyProjectFoundation";
import { useAblyProjectSubstation } from "hooks/AblyProjectComponents/useAblyProjectSubstation";
import { useAblyProjectTurbine } from "hooks/AblyProjectComponents/useAblyProjectTurbine";
import { useAblyProjectVessels } from "hooks/AblyProjectComponents/useAblyProjectVessels";
import { useAblyLibraryAnalysisForNode } from "hooks/LibraryResourceToNode/useAblyLibraryAnalysisForNode";
import { useAblyLibraryCablesForNode } from "hooks/LibraryResourceToNode/useAblyLibraryCablesForNode";
import { useAblyLibraryCostConfigForNode } from "hooks/LibraryResourceToNode/useAblyLibraryCostConfigForNode";
import { useAblyLibraryFoundationForNode } from "hooks/LibraryResourceToNode/useAblyLibraryFoundationForNode";
import { useAblyLibrarySubstationsForNode } from "hooks/LibraryResourceToNode/useAblyLibrarySubstationsForNode";
import { useAblyLibraryTurbinesForNode } from "hooks/LibraryResourceToNode/useAblyLibraryTurbinesForNode";
import { useAblyAnalysisStatus } from "hooks/useAblyAnalysisStatus";
import { useAblyArchivedVersion } from "hooks/useAblyArchivedVersion";
import { useAblyBranch } from "hooks/useAblyBranch";
import { useAblyCursorPosition } from "hooks/useAblyCursorPosition";
import { useAblyLayer } from "hooks/useAblyLayer";
import { useAblyNoiseAnalysisStatus } from "hooks/useAblyNoiseAnalysisStatus";
import { useAblySelection } from "hooks/useAblySelection";
import { useAblyShadowFlickerStatusUpdate } from "hooks/useAblyShadowFlickerStatusUpdate";
import { useAblyStyling } from "hooks/useAblyStyling";
import { useAblyProjectNode } from "hooks/useAblyProject";
import { usePresenceEnter } from "hooks/useAbly";
import { useAblyChangelogProjectUpdate } from "components/Changelog/useChangelogAbly";
import AblyProjectElements from "hooks/useAblyProjectElements";
import AblyBranchFolderStructure from "hooks/useAblyBranchFolderStructure";
import AblyProjectElementsFolders from "hooks/useAblyProjectElementsFolders";
import { branchIdAtom } from "state/pathParams";
import { Suspense, useEffect } from "react";
import { useAblyComments } from "hooks/useAblyComments";
import { usePresenceListener } from "ably/react";
import { projectPresenceState } from "./state";

export default function ProjectAll({
  projectId,
  ablyId,
}: {
  projectId: string;
  ablyId: string;
}) {
  const hasViewAccess = useAtomValue(viewerAccessProjectSelector);

  return (
    <>
      {hasViewAccess && (
        <ChannelProviderWrapper
          channelName={`${projectId}:all`}
          ablyId={ablyId}
        >
          <ProjectAllChannelConsumers projectId={projectId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

const ProjectAllChannelConsumers = ({ projectId }: { projectId: string }) => {
  const branchId = useAtomValue(branchIdAtom);

  useAblyCursorPosition(projectId);
  useAblyAnalysisStatus(projectId);
  useAblyArchivedVersion(projectId);
  useAblyLayer(projectId);
  useAblyAutosaveHistorySync(projectId);
  useAblySelection(projectId);
  useAblyBathymetryListener(projectId);
  useAblySlopeBathymetryListener(projectId);
  useAblyCostConfiguration(projectId);
  useAblyProjectTurbine(projectId);
  useAblyProjectFoundation(projectId);
  useAblyProjectSubstation(projectId);
  useAblyProjectMooringLine(projectId);
  useAblyProjectCable(projectId);
  useAblyProjectVessels(projectId);
  useAblyProjectAnalysisConfiguration(projectId);
  useAblyLibraryTurbinesForNode(projectId);
  useAblyLibrarySubstationsForNode(projectId);
  useAblyLibraryFoundationForNode(projectId);
  useAblyLibraryCostConfigForNode(projectId);
  useAblyLibraryCablesForNode(projectId);
  useAblyLibraryAnalysisForNode(projectId);
  useAblyShadowFlickerStatusUpdate(projectId);
  useAblyStyling(projectId);
  useAblyBranch(projectId);
  useAblyNoiseAnalysisStatus(projectId);
  usePresenceEnter(projectId);
  useAblyProjectNode(projectId);
  useAblyChangelogProjectUpdate(projectId);
  useAblyComments(projectId);

  return (
    <Suspense fallback={null}>
      <AblyProjectElements projectId={projectId} />
      {branchId && <AblyProjectElementsFolders projectId={projectId} />}
      <AblyBranchFolderStructure projectId={projectId} />
      <ProjectPresenceStateSync projectId={projectId} />
    </Suspense>
  );
};

function ProjectPresenceStateSync({ projectId }: { projectId: string }) {
  const { presenceData } = usePresenceListener({
    channelName: `${projectId}:all`,
    ablyId: projectId,
  });

  const setPresenceState = useSetAtom(projectPresenceState(projectId));

  useEffect(() => {
    setPresenceState(presenceData);
  }, [presenceData, setPresenceState]);

  return <></>;
}
