import { getBranchId, getParkId } from "analysis/inputs";
import { atom } from "jotai";
import { turbinesInParkWithFoundationFamily } from "state/jotai/turbine";
import { atomFamily } from "utils/jotai";
import { getDistanceFromPortToPark } from "../utils";
import { isDefined } from "../../../utils/predicates";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import {
  jackUpDownActivity,
  positionOnSiteActivity,
  transitActivity,
} from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  InstallationVesselType,
  isInstallationVessel,
} from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { MonopileActivitiesConfig } from "services/operationsConfigurationService";

export const monopileInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const turbinesWithFnd = await get(
      turbinesInParkWithFoundationFamily({
        parkId,
        branchId,
      }),
    );

    const turbinesWithMonopile = turbinesWithFnd
      .filter(
        ([, f]) => f.type === "detailed_monopile" || f.type === "monopile",
      )
      .map(([t]) => t);

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const { installationSeason, weatherLimits, monopiles } =
      configuration.ti.foundations;
    const { installationVessel, activities } = monopiles;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isInstallationVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const numMonopiles = turbinesWithMonopile.length;
    const numRounds = Math.ceil(numMonopiles / vessel.monopileCapacity);
    const numMonopilesLastRound =
      numMonopiles - (numRounds - 1) * vessel.monopileCapacity;

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numRounds; i++) {
      const monopilesInRound =
        i === numRounds - 1 ? numMonopilesLastRound : vessel.monopileCapacity;

      for (let j = 0; j < monopilesInRound; j++) {
        totalInstallTime.push(loadMonopileOnDeckActivity(activities));
        totalInstallTime.push(loadTPOnDeckActivity(activities.loadTPOnVessel));
      }

      totalInstallTime.push(transitActivity(transitTime));

      for (let j = 0; j < monopilesInRound; j++) {
        parkOperationsMonopile(vessel, activities, true).forEach(
          (operation) => {
            totalInstallTime.push(operation);
          },
        );
      }

      totalInstallTime.push(transitActivity(transitTime));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const loadMonopileOnDeckActivity = (activities: MonopileActivitiesConfig) => ({
  id: "Load monopile on vessel",
  duration: activities.loadMonopileOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

const loadTPOnDeckActivity = (loadTPOnVessel: number) => ({
  id: "Load TP on vessel",
  duration: loadTPOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

const liftBoltTPActivity = (liftAndBoltTP?: number, mileStone?: boolean) => {
  if (!liftAndBoltTP) return;

  return {
    id: "Lift and bolt TP",
    mileStone,
    duration: liftAndBoltTP,
    useWindLim: true,
    useWaveLim: true,
  };
};

const upendPosMonopileActivity = (activities: MonopileActivitiesConfig) => ({
  id: "Upend and position monopile",
  duration: activities.upendAndPositionMonopile,
  useWindLim: true,
  useWaveLim: true,
});

const driveMonopileActivity = (activities: MonopileActivitiesConfig) => ({
  id: "Drive monopile",
  duration: activities.driveMonopile,
  useWindLim: true,
  useWaveLim: true,
});

const parkOperationsMonopile = (
  vessel: InstallationVesselType,
  activities: MonopileActivitiesConfig,
  mileStone?: boolean,
) =>
  [
    positionOnSiteActivity,
    jackUpDownActivity(vessel),
    upendPosMonopileActivity(activities),
    driveMonopileActivity(activities),
    liftBoltTPActivity(activities.liftAndBoltTP, mileStone),
    jackUpDownActivity(vessel),
  ].filter(isDefined);
