import { atom } from "jotai";
import { ViewpointFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";

export const viewPointsFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<ViewpointFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).viewpoint,
    ),
);
