import { WindDataSource, WindSpeedCalibrationType } from "types/metocean";
import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import { DesignToolMode } from "types/map";

const _BuiltInSourceId = z.union([
  z.literal(WindDataSource.ERA5),
  z.literal(WindDataSource.NORA3),
  z.literal(WindDataSource.CERRA),
]);

const _BuiltInSource = z.object({
  id: _BuiltInSourceId,
  type: z.literal("built_in").default("built_in"),
});
type BuiltInSource = z.infer<typeof _BuiltInSource>;

const _BestSource = z.object({
  id: z.literal(WindDataSource.BEST),
  type: z.literal("built_in").default("built_in"),
});
type BestSource = z.infer<typeof _BestSource>;

const _CustomSource = z.object({
  id: z.string(),
  type: z.literal("custom").default("custom"),
  longitude: z.number({ coerce: true }),
  latitude: z.number({ coerce: true }),
  height: z.number({ coerce: true }).nullish(),
});

const _MultiCustomSource = z.object({
  type: z.literal("custom_multiple").default("custom_multiple"),
  data: _CustomSource.array(),
  id: z.string().optional(),
  interpolation: z.enum(["weighted", "nearest"]).default("nearest"),
});

export type CustomWindSource = z.infer<typeof _CustomSource>;
export type MultiCustomWindSource = z.infer<typeof _MultiCustomSource>;

const _Source = z.union([
  _BuiltInSource,
  _CustomSource,
  _BestSource,
  _MultiCustomSource,
]);

export const isCustomWindSource = (source: {
  id: unknown;
}): source is CustomWindSource => _CustomSource.safeParse(source).success;

const isBuiltInWindSource = (source: {
  id: unknown;
}): source is BuiltInSource => _BuiltInSource.safeParse(source).success;

const isBestWindSource = (source: { id: unknown }): source is BestSource =>
  _BestSource.safeParse(source).success;

const _SpatialCalibrationGwa = z.object({
  type: z.enum(["global_wind_atlas"]),
  value: z.enum(["relative", "absolute"]),
});

const _SpatialCalibrationNewa = z.object({
  type: z.enum(["new_european_wind_atlas"]),
  value: z.enum(["relative", "absolute"]),
});

const _SpatialCalibrationCustom = z.object({
  type: z.enum(["custom"]),
  id: z.string(),
  value: z.enum(["absolute", "relative"]),
});

export const _SpatialCalibration = z.union([
  _SpatialCalibrationGwa,
  _SpatialCalibrationNewa,
  _SpatialCalibrationCustom,
  z.null(),
]);

const _PercentCalibration = z.object({
  type: z.literal(WindSpeedCalibrationType.PERCENT),
  value: z.number({ coerce: true }).default(0),
});

const _BaseWindSourceConfiguration = z.object({
  id: z.string(),
  created_ts: z.number(),
  updated_ts: z.number(),
  name: z.string().optional(),
  note: z.string().nullish().default(null),
  free_text: z.string().nullish().default(null),
  time_range: z.object({
    from_year: z.number(),
    to_year: z.number(),
  }),
});

const _BestWindSourceConfiguration = _BaseWindSourceConfiguration.extend({
  source: _BestSource,
  calibration: _PercentCalibration.nullish(),
  spatial_calibration: _SpatialCalibration.nullish().default(null),
});
type BestWindSourceConfiguration = z.infer<typeof _BestWindSourceConfiguration>;

const _BuiltInWindSourceConfiguration = _BaseWindSourceConfiguration.extend({
  source: _BuiltInSource,
  calibration: _PercentCalibration.nullish(),
  spatial_calibration: _SpatialCalibration.nullish().default(null),
});
type BuiltInWindSourceConfiguration = z.infer<
  typeof _BuiltInWindSourceConfiguration
>;

const _CustomWindSourceConfiguration = _BaseWindSourceConfiguration.extend({
  source: z.union([_CustomSource, _MultiCustomSource]),
  calibration: _PercentCalibration.nullish(),
  spatial_calibration: _SpatialCalibration.nullish().default(null),
});

export const _SingleSourceWindConfiguration =
  _BaseWindSourceConfiguration.extend({
    source: z.union([_CustomSource, _BestSource, _BuiltInSource]),
    calibration: _PercentCalibration.nullish(),
    spatial_calibration: z.null(),
  });

export type SingleSourceWindConfiguration = z.infer<
  typeof _SingleSourceWindConfiguration
>;

export const isSingleSourceWindConfiguration = (
  config: any,
): config is SingleSourceWindConfiguration =>
  _SingleSourceWindConfiguration.safeParse(config).success;

export const _SingleSourceSpatialCalibrationWindConfiguration =
  _BaseWindSourceConfiguration
    .extend({
      source: z.union([_CustomSource, _BestSource, _BuiltInSource]),
      calibration: _PercentCalibration.nullish(),
      spatial_calibration: _SpatialCalibration.default({
        type: "global_wind_atlas",
        value: "absolute",
      }),
    })
    .refine((config) => {
      if (config.spatial_calibration !== null) {
        return true;
      } else {
        return {
          message: "Spatial calibration should not be null",
          path: ["spatial_calibration"],
          details: { config: config },
        };
      }
    });

export type SingleSourceSpatialCalibrationWindConfiguration = z.infer<
  typeof _SingleSourceSpatialCalibrationWindConfiguration
>;

export const isSingleSourceSpatialCalibrationWindConfiguration = (
  config: any,
): config is SingleSourceSpatialCalibrationWindConfiguration =>
  _SingleSourceSpatialCalibrationWindConfiguration.safeParse(config).success &&
  config.spatial_calibration !== null;

export const _MultipleSourceWindConfiguration =
  _BaseWindSourceConfiguration.extend({
    source: _MultiCustomSource,
    spatial_calibration: z.null(),
    calibration: z.null(),
  });

export type MultipleSourceWindConfiguration = z.infer<
  typeof _MultipleSourceWindConfiguration
>;

export const isMultipleSourceWindConfiguration = (
  config: any,
): config is MultipleSourceWindConfiguration =>
  _MultipleSourceWindConfiguration.safeParse(config).success;

export const isBuiltInWindSourceConfiguration = (
  configuration: WindSourceConfiguration,
): configuration is BuiltInWindSourceConfiguration => {
  if (configuration.source.type === "custom_multiple") {
    return false;
  }
  return isBuiltInWindSource(configuration.source);
};

export const _WindSourceConfiguration = z.union([
  _MultipleSourceWindConfiguration,
  _SingleSourceSpatialCalibrationWindConfiguration,
  _SingleSourceWindConfiguration,
]);

export type WindSourceConfiguration = z.infer<typeof _WindSourceConfiguration>;

export const isBestWindSourceConfiguration = (
  configuration: WindSourceConfiguration,
): configuration is BestWindSourceConfiguration => {
  if (isMultipleSourceWindConfiguration(configuration)) {
    return false;
  }
  return isBestWindSource(configuration.source);
};

const _WindConfigurationUsageType = z.object({
  windConfigurationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
});
export type WindConfigurationUsageType = z.infer<
  typeof _WindConfigurationUsageType
>;

export async function createWindSourceConfiguration({
  nodeId,
  projectType,
}: {
  nodeId: string;
  projectType: DesignToolMode;
}): Promise<WindSourceConfiguration> {
  const response = await fetchEnhancerWithToken(
    `/api/octopus/metocean/${nodeId}/wind/configurations?project_type=${projectType}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const json = await response.json();

  return _WindSourceConfiguration.parse(json);
}

export async function updateWindSourceConfiguration({
  nodeId,
  configuration,
}: {
  nodeId: string;
  configuration: WindSourceConfiguration;
}): Promise<WindSourceConfiguration> {
  const isBest = isBestWindSourceConfiguration(configuration);
  const calibration = isBest ? undefined : configuration.calibration;
  const res = await fetchEnhancerWithToken(
    `/api/octopus/metocean/${nodeId}/wind/configurations/${configuration.id}`,
    {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: configuration.name,
        note: configuration.note,
        free_text: configuration.free_text,
        source: configuration.source,
        time_range: configuration.time_range,
        calibration,
        spatial_calibration: configuration.spatial_calibration,
      }),
    },
  );

  const json = await res.json();

  return _WindSourceConfiguration.parse(json);
}

export async function deleteWindSourceConfiguration({
  nodeId,
  configurationId,
}: {
  nodeId: string;
  configurationId: string;
}) {
  return fetchEnhancerWithToken(
    `/api/octopus/metocean/${nodeId}/wind/configurations/${configurationId}`,
    {
      method: "delete",
    },
  );
}

export async function listWindSourceConfigurations(nodeId: string) {
  return fetchEnhancerWithToken(
    `/api/octopus/metocean/${nodeId}/wind/configurations`,
    {
      method: "get",
    },
  );
}
