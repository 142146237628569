import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import Tooltip from "../General/Tooltip";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import { isCable } from "../../utils/predicates";
import { CableFeature } from "../../types/feature";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { ProjectFeature } from "../../types/feature";
import { MenuButton } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { IAcableTypesWithLevelAtom } from "state/jotai/cableType";
import { useAtomValue } from "jotai";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { useOpenComponentsConfig } from "./useOpenConfig";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import ComponentsIcon from "@icons/24/Components.svg?react";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;

const TypeSelector = ({
  selectedCables,
  editable,
}: {
  selectedCables: CableFeature[];
  editable: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const { update: updateFeatures } = useProjectElementsCrud();
  const allCableTypes = useAtomValue(IAcableTypesWithLevelAtom);
  const openNewCableConfig = useOpenComponentsConfig(projectId, CABLE_MENU_ID);

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-cable-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedCables.map((c) => ({
        ...c,
        properties: {
          ...c.properties,
          cableTypeId: val,
        },
      }));

      updateFeatures({
        update: updatedFeatures,
      });
    },
    [branchId, projectId, selectedCables, updateFeatures],
  );

  const currentCableType = useMemo(() => {
    const selectedCableTypes = new Set(
      selectedCables.map((c) => c.properties.cableTypeId),
    );

    if (selectedCableTypes.size === 1) {
      const selectedCableTypeId = selectedCableTypes.values().next().value;
      return allCableTypes.get(selectedCableTypeId);
    } else return null;
  }, [allCableTypes, selectedCables]);

  const items = useMemo(() => {
    return Array.from(allCableTypes.values()).map((t) => ({
      icon: ["project"].includes(t.level) ? <ProjectIcon /> : <LibraryIcon />,
      level: t.level,
      value: t.cable.id,
      name: `${t.cable.name} (${t.cable.voltage}kV)`,
    }));
  }, [allCableTypes]);

  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentCableType ? currentCableType.cable.name : "..."}
          </div>
        }
      >
        <>
          {items.filter((ft) => ft.level === "library").length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Library component</SubTitle>
              {items
                .filter((ft) => ft.level === "library")
                .map((item) => (
                  <MenuItem
                    selected={currentCableType?.cable.id === item.value}
                    key={item.value}
                    name={item.name}
                    onClick={() => onSelectItem(item.value)}
                    icon={item.icon}
                  />
                ))}
            </div>
          )}
          {items.filter((ft) => ft.level === "project").length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Project specific components</SubTitle>
              {items
                .filter((ft) => ft.level === "project")
                .map((item) => (
                  <MenuItem
                    textStyle={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    selected={currentCableType?.cable.id === item.value}
                    key={item.value}
                    name={item.name}
                    onClick={() => onSelectItem(item.value)}
                    icon={item.icon}
                  />
                ))}
            </div>
          )}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          ></div>

          <DropdownDivider />

          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewCableConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function CableTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedCableFeatures = useMemo(() => {
    const selectedCables = selectedProjectFeatures.filter(isCable);

    return selectedCables.length >= 1 ? selectedCables : null;
  }, [selectedProjectFeatures]);

  if (!selectedCableFeatures) return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <NamedTooltipContainer
          style={{
            minWidth: "8rem",
          }}
        >
          <NamedTooltipWrapper>Cable type</NamedTooltipWrapper>
          <TypeSelector
            selectedCables={selectedCableFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
    </Suspense>
  );
}
