import { aset, useJotaiCallback } from "utils/jotai";
import { useMemo } from "react";
import { InboundMessage } from "ably";
import {
  ABLY_AUTOSAVE_FEATURE_UPDATE,
  ABLY_DELETE_SAVE_FEATURE_UPDATE,
  ABLY_EDIT_SAVE_FEATURE_UPDATE,
  ABLY_SAVE_FEATURE_UPDATE,
  ABLY_UPDATE_SAVE_FEATURE_UPDATE,
} from "state/ably";
import {
  _AutosaveRequestEntry,
  _DeleteSaveRequestAblyMessage,
  _SaveRequestEntry,
} from "./type";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import {
  autosaveFeatureHistoryAtomFamily,
  saveFeatureHistoryAtomFamily,
} from "./state";

const useAblyAutosaveHistorySync = (projectId: string) => {
  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const onAutosaveMessageReceived = useJotaiCallback(
    async (get, set, message: InboundMessage) => {
      const autosaveRequestEntry = _AutosaveRequestEntry.parse(message.data);
      aset(
        get,
        set,
        autosaveFeatureHistoryAtomFamily({
          projectId: autosaveRequestEntry.nodeId,
          branchId: autosaveRequestEntry.branchId,
        }),
        (curr) => [...curr, autosaveRequestEntry],
      );
    },
    [],
  );

  const onSaveMessageReceived = useJotaiCallback(
    async (get, set, message: InboundMessage) => {
      const saveRequestEntry = _SaveRequestEntry.parse(message.data);
      aset(
        get,
        set,
        saveFeatureHistoryAtomFamily({
          projectId: saveRequestEntry.nodeId,
          branchId: saveRequestEntry.branchId,
        }),
        (curr) => [...curr, saveRequestEntry],
      );
    },
    [],
  );

  const onSaveDeleteMessageReceived = useJotaiCallback(
    async (get, set, message: InboundMessage) => {
      const saveRequestEntry = _DeleteSaveRequestAblyMessage.parse(
        message.data,
      );

      aset(
        get,
        set,
        saveFeatureHistoryAtomFamily({
          projectId: saveRequestEntry.nodeId,
          branchId: saveRequestEntry.branchId,
        }),
        (curr) =>
          curr.filter(
            (c) =>
              c.nodeId !== saveRequestEntry.nodeId ||
              c.branchId !== saveRequestEntry.branchId ||
              c.timestamp !== saveRequestEntry.timestamp,
          ),
      );
    },
    [],
  );

  const onSaveEditMessageReceived = useJotaiCallback(
    async (get, set, message: InboundMessage) => {
      const saveRequestEntry = _SaveRequestEntry.parse(message.data);
      aset(
        get,
        set,
        saveFeatureHistoryAtomFamily({
          projectId: saveRequestEntry.nodeId,
          branchId: saveRequestEntry.branchId,
        }),
        (curr) => [
          ...curr.filter(
            (c) =>
              c.nodeId !== saveRequestEntry.nodeId ||
              c.branchId !== saveRequestEntry.branchId ||
              c.timestamp !== saveRequestEntry.timestamp,
          ),
          saveRequestEntry,
        ],
      );
    },
    [],
  );
  const events = useMemo(
    () => [
      {
        eventName: ABLY_AUTOSAVE_FEATURE_UPDATE,
        onMessageReceived: onAutosaveMessageReceived,
      },
      {
        eventName: ABLY_SAVE_FEATURE_UPDATE,
        onMessageReceived: onSaveMessageReceived,
      },
      {
        eventName: ABLY_DELETE_SAVE_FEATURE_UPDATE,
        onMessageReceived: onSaveDeleteMessageReceived,
      },
      {
        eventName: ABLY_EDIT_SAVE_FEATURE_UPDATE,
        onMessageReceived: onSaveEditMessageReceived,
      },
      {
        eventName: ABLY_UPDATE_SAVE_FEATURE_UPDATE,
        onMessageReceived: onSaveEditMessageReceived,
      },
    ],
    [
      onAutosaveMessageReceived,
      onSaveMessageReceived,
      onSaveDeleteMessageReceived,
      onSaveEditMessageReceived,
    ],
  );

  useAblyGeneric(channelName, events, projectId);

  return null;
};

export default useAblyAutosaveHistorySync;
