import { useEffect, useMemo } from "react";
import SunCalc from "suncalc";
import {
  viewPositionAtom,
  viewDateAtom,
  viewParkRotationAtom,
} from "../../../../state/viewToPark";
import { ThreeCoreParkShadow } from "./useCreateThreeCore";
import { useAtomValue, useSetAtom } from "jotai";
import { MathUtils } from "three";

export default function useUpdateSunPos(
  threeCore: ThreeCoreParkShadow | undefined,
) {
  const viewPosition = useAtomValue(viewPositionAtom);
  const date = useAtomValue(viewDateAtom);
  const setParkRotation = useSetAtom(viewParkRotationAtom);
  const sunPosition = useMemo(() => {
    if (!viewPosition) return;
    return SunCalc.getPosition(
      date.toJSDate(),
      viewPosition.lat,
      viewPosition.lng,
    );
  }, [viewPosition, date]);

  useEffect(() => {
    if (!sunPosition || !threeCore || !setParkRotation) return;
    threeCore.setSunPosition(sunPosition.altitude, sunPosition.azimuth);
    setParkRotation(
      ((Math.round(-1 * MathUtils.radToDeg(sunPosition.azimuth - Math.PI / 2)) %
        360) +
        360) %
        360,
    );
  }, [sunPosition, threeCore, setParkRotation]);
}
