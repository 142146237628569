import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import styled from "styled-components";
import Folder from "@icons/14/Folder.svg?react";
import OpenFolder from "@icons/14/OpenFolder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import Add from "@icons/24/Add.svg?react";
import BranchIcon from "@icons/24/Branch.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import Close from "@icons/24/Close.svg?react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../style";
import { BranchAndParksInProject, Node } from "services/customerAPI";
import { SVGWrapper } from "components/Organisation/style";
import { Chevron } from "components/General/Chevron";
import { Row } from "components/General/Layout";
import Button from "components/General/Button";
import { _UserAccessRole } from "types/user";
import SelectedLabel from "components/General/SelectedLabel";
import {
  admin_BranchesAndParksInProjectSelectorFamily,
  admin_nodesInOrgSelectorFamily,
} from "components/Projects/useOrganisationFolderCrud";
import { searchNodesWithChildren, setupFuseSearch } from "utils/nodeSearch";
import NoItemsGeneric from "components/General/NoItemsGeneric";
import { SkeletonText } from "components/Loading/Skeleton";
import { portfolioItemsSimpleAtom } from "state/portfolio";
import { unwrap } from "jotai/utils";
import { adminInOrganisationSelectorFamily } from "state/user";
import { Input } from "components/General/Input";
import { IconBtn } from "components/General/Icons";
import useTextInput from "hooks/useTextInput";

type Park = {
  parkId: string;
  parkName: string;
};

type ParkWithBranchAndProject = {
  parkId: string;
  parkName: string;
  branchId: string;
  projectId: string;
};

const StyledText = styled(TextEllipsis)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export function AdminParkPickerModalWrapper(props: {
  onSave: (nodes: ParkWithBranchAndProject[]) => void;
  onClose: () => void;
  disableAccessRole?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  if (!isAdminInOrg) return <></>;

  return (
    <SearchAndSelectContainer>
      <React.Suspense
        fallback={
          <SkeletonText
            text="Loading parks..."
            style={{ width: "100%", padding: "1rem" }}
          />
        }
      >
        <OrganisationParkPickerModal {...props} />
      </React.Suspense>
    </SearchAndSelectContainer>
  );
}

function OrganisationParkPickerModal({
  onSave,
  onClose,
}: {
  onSave: (nodes: ParkWithBranchAndProject[]) => void;
  onClose: () => void;
}) {
  const portfolioItems = useAtomValue(unwrap(portfolioItemsSimpleAtom));
  const existingAccess = portfolioItems?.map((item) => item.parkId) ?? [];
  const [selectedParks, setSelectedParks] = useState<
    ParkWithBranchAndProject[]
  >([]);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  return (
    <>
      <ParkPicker
        nodes={nodes}
        selectedParkIds={selectedParks.map((park) => park.parkId)}
        onAddPark={(park) => setSelectedParks((cur) => [...cur, park])}
        existingAccess={existingAccess}
        onClose={onClose}
      />
      {selectedParks.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "1.6rem 1.2rem",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedParks.map((park) => {
                return (
                  <SinglePark
                    park={park}
                    key={park.parkId}
                    remove={() =>
                      setSelectedParks((cur) =>
                        cur.filter((n) => n.parkId !== park.parkId),
                      )
                    }
                  />
                );
              })}
            </Row>
            <Button
              text="Add"
              onClick={() => {
                onSave(selectedParks);
              }}
              buttonType="primary"
              icon={<Add />}
            />
          </Row>
        </>
      )}
    </>
  );
}

function SinglePark({
  park,
  remove,
}: {
  park: { parkId: string; parkName: string };
  remove: () => void;
}) {
  return <SelectedLabel title={park.parkName} onDeselect={remove} />;
}

const StateContext = createContext<
  | undefined
  | {
      selectedNodeIds: string[];
      onAddPark: (parkWithBranch: ParkWithBranchAndProject) => void;
      filteredNodes: Node[];
      existingAccess: string[];
      organisationId: string;
    }
>(undefined);

const ProjectElement = ({
  projectNode,
  level,
  searchTerm,
}: {
  projectNode: Node;
  level: number;
  searchTerm: string;
}) => {
  const context = useContext(StateContext);
  const [open, setOpen] = useState(false);
  const organisationId = context?.organisationId;

  const topNodes = useMemo(() => {
    const preFilteredNodes = context?.filteredNodes ?? [];
    const topNodesToShow = preFilteredNodes.filter(
      (n) => n.parent_id === projectNode.id,
    );
    return topNodesToShow;
  }, [context?.filteredNodes, projectNode.id]);

  const display =
    topNodes.length > 0 ||
    context?.filteredNodes?.some((node) => node.id === projectNode.id);

  const highlightSearchTerm = (text: string, searchTerm?: string) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm?.toLowerCase() ? (
        <strong key={index}>{part}</strong>
      ) : (
        part
      ),
    );
  };

  if (!display) return <></>;

  return (
    <>
      <ContentTableRow
        style={{
          padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <SVGWrapper>
          <Earth />
        </SVGWrapper>
        <StyledText>
          {highlightSearchTerm(projectNode.name, searchTerm)}
        </StyledText>
        <Chevron open={open} size={"1rem"} />
      </ContentTableRow>
      {open && organisationId && (
        <React.Suspense
          fallback={
            <SkeletonText
              text={"Loading branches..."}
              style={{ width: "100%", padding: ".8rem" }}
            />
          }
        >
          <BranchesInProject
            organisationId={organisationId}
            projectId={projectNode.id}
            level={level + 1}
          />
        </React.Suspense>
      )}
    </>
  );
};

const BranchElement = ({
  branch,
  projectId,
  level,
}: {
  branch: BranchAndParksInProject;
  projectId: string;
  level: number;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ContentTableRow
        style={{
          padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <SVGWrapper style={{ width: "1.4rem", height: "1.4rem" }}>
          <BranchIcon />
        </SVGWrapper>
        <StyledText>{branch.branchName}</StyledText>
        <Chevron open={open} size={"1rem"} />
      </ContentTableRow>
      {open && (
        <>
          {branch.parks.length === 0 && (
            <div style={{ padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem` }}>
              <SecondaryText>No parks found in this branch</SecondaryText>
            </div>
          )}
          {branch.parks.map((park) => (
            <ParkElement
              projectId={projectId}
              key={park.parkId}
              branchId={branch.branchId}
              parkNode={park}
              level={level + 1}
            />
          ))}
        </>
      )}
    </>
  );
};

const ParkElement = ({
  branchId,
  projectId,
  parkNode,
  level,
}: {
  branchId: string;
  projectId: string;
  parkNode: Park;
  level: number;
}) => {
  const context = useContext(StateContext);
  const parkAlreadyInPortfolio =
    context?.existingAccess.some((nodeId) => nodeId === parkNode.parkId) ??
    false;
  const selected = context?.selectedNodeIds.some(
    (nodeId) => nodeId === parkNode.parkId,
  );

  const disabled = selected || parkAlreadyInPortfolio;

  const onSelect = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!context) return;
      if (!disabled) {
        context.onAddPark?.({ ...parkNode, branchId, projectId });
      }
    },
    [context, disabled, parkNode, branchId, projectId],
  );

  return (
    <ContentTableRow
      style={{
        padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
        cursor: "pointer",
      }}
      disabled={disabled}
      onClick={onSelect}
    >
      <SVGWrapper style={{ width: "1.4rem", height: "1.4rem" }}>
        <ParkIcon />
      </SVGWrapper>
      <StyledText
        style={{
          marginRight: "auto",
        }}
      >
        {parkNode.parkName}
      </StyledText>
      {parkAlreadyInPortfolio ? (
        <SecondaryText>Already in portfolio</SecondaryText>
      ) : selected ? (
        <SecondaryText>Selected</SecondaryText>
      ) : (
        <></>
      )}
      {!disabled && (
        <Button
          buttonType="primary"
          size="small"
          text="Add"
          icon={<Add />}
          onClick={onSelect}
          style={{
            padding: "0.2rem 1.2rem",
          }}
        />
      )}
    </ContentTableRow>
  );
};

const FolderWithChildren = ({
  folderNode,
  level,
  searchTerm,
}: {
  folderNode: Node;
  level: number;
  searchTerm: string;
}) => {
  const context = useContext(StateContext);
  const [open, setOpen] = useState(false);

  const topNodes = useMemo(() => {
    const preFilteredNodes = context?.filteredNodes ?? [];
    const topNodesToShow = preFilteredNodes.filter(
      (n) => n.parent_id === folderNode.id,
    );
    return topNodesToShow;
  }, [context?.filteredNodes, folderNode.id]);

  const display =
    topNodes.length > 0 ||
    context?.filteredNodes?.some((node) => node.id === folderNode.id);

  const FolderIcon = open === true ? OpenFolder : Folder;

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      const hasMatchingChildren = topNodes.some(
        (node) =>
          node.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          folderNode.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setOpen(hasMatchingChildren);
    } else {
      setOpen(false);
    }
  }, [searchTerm, topNodes, folderNode.name]);

  if (!display) return <></>;

  return (
    <>
      <ContentTableRow
        style={{
          padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <SVGWrapper>
          <FolderIcon />
        </SVGWrapper>
        <StyledText>{folderNode.name}</StyledText>
        <Chevron open={open} size={"1rem"} />
      </ContentTableRow>
      {open && (
        <ChildrenNodesForParent
          searchTerm={searchTerm}
          level={level + 1}
          topNodes={topNodes}
        />
      )}
    </>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  level,
  searchTerm,
}: {
  topNodes: Node[];
  level: number;
  searchTerm: string;
}) => {
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  return (
    <>
      {sortedFolders.map((n) => (
        <FolderWithChildren
          level={level}
          key={n.id}
          folderNode={n}
          searchTerm={searchTerm}
        />
      ))}
      {sortedProjects.map((n) => (
        <ProjectElement
          level={level}
          projectNode={n}
          key={n.id}
          searchTerm={searchTerm}
        />
      ))}
    </>
  );
};

const BranchesInProject = ({
  organisationId,
  projectId,
  level,
}: {
  organisationId: string;
  projectId: string;
  level: number;
}) => {
  const branches = useAtomValue(
    admin_BranchesAndParksInProjectSelectorFamily({
      organisationId,
      projectId,
    }),
  );

  return (
    <>
      {branches.map((branch) => (
        <BranchElement
          projectId={projectId}
          branch={branch}
          key={branch.branchId}
          level={level}
        />
      ))}
    </>
  );
};

function findOrgTopNodes(allNodes: Node[], orgId: string) {
  const topNodes = [] as Node[];
  const findTopNode = (nodeId: string, nodes: Node[]): void => {
    if (topNodes.some((n) => n.id === nodeId)) return;

    const node = nodes.find((n) => n.id === nodeId);
    if (!node || !node.parent_id) return;

    const parentNode = nodes.find((n) => n.id === node.parent_id);
    if (!parentNode) {
      if (node.id === orgId) return;
      topNodes.push(node);
      return;
    } else if (parentNode.id === orgId) {
      topNodes.push(node);
      return;
    }

    return findTopNode(parentNode.id, nodes);
  };

  allNodes.forEach((n) => findTopNode(n.id, allNodes));
  return topNodes;
}

const ParkPicker = ({
  nodes,
  selectedParkIds: selectedNodeIds,
  onAddPark,
  onClose,
  existingAccess,
}: {
  nodes: Node[];
  selectedParkIds: string[];
  onAddPark: (parkWithBranch: ParkWithBranchAndProject) => void;
  existingAccess: string[];
  onClose: () => void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const topNodes = useMemo(
    () => findOrgTopNodes(nodes, organisationId),
    [nodes, organisationId],
  );

  const [filteredNodes, setFilteredNodes] = useState<Node[]>([]);
  const [name, onNameChange] = useTextInput("");

  const fuse = useMemo(() => setupFuseSearch(nodes), [nodes]);

  useEffect(() => {
    if (name.length > 0) {
      const searchResults = fuse.search(name).map((result) => result.item);
      const results = searchNodesWithChildren(nodes, searchResults);
      setFilteredNodes(results);
    } else {
      setFilteredNodes(nodes);
    }
  }, [name, nodes, fuse]);

  return (
    <StateContext.Provider
      value={{
        organisationId,
        selectedNodeIds,
        onAddPark,
        filteredNodes,
        existingAccess,
      }}
    >
      <Row
        style={{
          alignItems: "center",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={onClose}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        {filteredNodes.length > 0 ? (
          <ContentTableColumn>
            <ChildrenNodesForParent
              level={1}
              topNodes={topNodes}
              searchTerm={name}
            />
          </ContentTableColumn>
        ) : (
          <NoItemsGeneric
            headerText="No projects found"
            subText="Try searching for a project by name"
          />
        )}
      </ResultContainer>
    </StateContext.Provider>
  );
};
