import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import React from "react";

class ConfigNotFoundError extends Error {
  constructor(public msg?: string) {
    super(msg);
  }
}

export class AnalysisConfigNotFoundError extends ConfigNotFoundError {}

export class WindConfigNotFoundError extends ConfigNotFoundError {}

export const AnalysisConfigNotFoundErrorBoundaryFallbackRender = ({
  error,
}: {
  error: Error;
}) => {
  if (error instanceof ConfigNotFoundError) {
    return (
      <SimpleAlert
        text={"Please select an analysis/wind configuration."}
        type={"error"}
      />
    );
  }

  throw error;
};
