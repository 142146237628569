import { Input, InputDimensioned } from "../../../../General/Input";
import { ConfidenceLevel, CostUnit } from "../../../../../types/financial";
import { isDefined } from "../../../../../utils/predicates";
import { GridWithFiveColumns } from "../../styles";
import { Row } from "components/General/Layout";
import { localCostConfigurationAtom } from "../../state";
import { CostConfiguration } from "services/costService";
import { ConfidenceLevelSelector } from "../../ConfidenceLevelSelector";
import { InputChangelogInfo } from "components/InputChangelog/types";
import { useAtomValue } from "jotai";

export const FixedCost = ({
  title,
  value = 0,
  initialValue,
  unit,
  units = undefined,
  confidenceLevel,
  onValueChange,
  onUnitChange,
  onConfidenceLevelChange,
  isReadOnly,
  changelogInfo,
}: {
  title: string;
  value?: number;
  initialValue?: number;
  unit: CostUnit;
  units?: CostUnit[];
  confidenceLevel: ConfidenceLevel | undefined;
  onValueChange: (value: number, unit?: CostUnit) => void;
  onUnitChange?: (unit: CostUnit) => void;
  onConfidenceLevelChange: (confidenceLevel: ConfidenceLevel) => void;
  isReadOnly?: boolean;
  changelogInfo?: InputChangelogInfo;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  return (
    <Row>
      <GridWithFiveColumns>
        <Input
          value={title}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />
        <Row>
          <InputDimensioned
            style={{ width: "100%" }}
            unit={unit}
            units={units}
            value={value}
            initialValue={initialValue}
            onChange={(value, _, unit) => onValueChange(value, unit)}
            onUnitChange={onUnitChange}
            disabled={isReadOnly}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            changelogInfo={changelogInfo}
          />
        </Row>
        {useConfidenceLevel && (
          <ConfidenceLevelSelector
            disabled={isReadOnly}
            onConfidenceLevelChange={onConfidenceLevelChange}
            confidenceLevel={confidenceLevel}
          />
        )}
      </GridWithFiveColumns>
    </Row>
  );
};
