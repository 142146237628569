import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { Suspense, useMemo } from "react";
import styled from "styled-components";
import Like from "../../General/Like";
import { UserInfoProject } from "../../UserInfo/UserInfo";
import { UserInfoLoading } from "components/UserInfo/UserInfoLoading";
import { useThreadReactionCrud } from "../hooks/useThreadReactionCrud";
import {
  threadReactionsAtomFamily,
  userReactionsAtomFamily,
  userReactionSaveInProgressState,
} from "../state";
import { Thread } from "../types";
import { useAtomValue } from "jotai";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default function CommentReactions({
  thread,
  nodeId,
  parentIsHovered,
  threadIsSelected,
}: {
  thread: Thread;
  nodeId: string;
  parentIsHovered: boolean;
  threadIsSelected: boolean;
}) {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const currentUserReactions = useAtomValue(
    userReactionsAtomFamily({
      nodeId,
      branchId,
    }),
  );
  const { post, remove } = useThreadReactionCrud();

  const reactionSaveInProgress = useAtomValue(userReactionSaveInProgressState);

  const currentUserThreadReaction = useMemo(
    () =>
      currentUserReactions.find(
        (r) => r.threadId === thread.threadId && r.replyId === undefined,
      ),
    [currentUserReactions, thread.threadId],
  );

  return (
    <Container>
      <Like
        parentIsHovered={parentIsHovered}
        threadIsSelected={threadIsSelected}
        like={
          !!currentUserThreadReaction ||
          reactionSaveInProgress === thread.threadId
        }
        disabled={reactionSaveInProgress === thread.threadId}
        totalLikes={thread.reactions}
        onClick={() =>
          currentUserThreadReaction
            ? remove(thread.threadId, currentUserThreadReaction.reactionId)
            : post(thread.threadId)
        }
        reactionList={
          <Suspense
            fallback={
              <Column>
                {Array.from(Array(thread.reactions).keys()).map((_, i) => (
                  <UserInfoLoading key={i} />
                ))}
              </Column>
            }
          >
            <ReactionsList thread={thread} />
          </Suspense>
        }
      />
    </Container>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
function ReactionsList({ thread }: { thread: Thread }) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const reactions = useAtomValue(
    threadReactionsAtomFamily({
      nodeId: thread.nodeId,
      branchId: thread.branchId,
      threadId: thread.threadId,
    }),
  );

  return (
    <Column>
      {reactions.map((r) => (
        <UserInfoProject
          key={r.userId}
          userId={r.userId}
          projectId={projectId}
        />
      ))}
    </Column>
  );
}
