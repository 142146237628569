import {
  OptProblem,
  ListResultsForProblem,
  getOptProblem,
  listOptProblems,
  listResultsForProblem,
} from "../../functions/optimize";
import { scream } from "../../utils/sentry";
import { isDefined, isSubArea } from "../../utils/predicates";
import { defaultRegularParamsWithUnits } from "../../state/turbines";
import inferTurbineParameters, { inferResultToObj } from "./infer";
import { pointInPolygon } from "../../utils/geometry";
import { sendWarning } from "../../utils/sentry";
import { MooringParameters } from "components/GenerateFoundationsAndAnchors/types";
import { Raster } from "types/raster";
import { atom } from "jotai";
import { atomFromFn, atomFamily } from "utils/jotai";
import { parkFamily } from "state/jotai/park";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { subAreaFamily, subAreasInParkFamily } from "state/jotai/subArea";
import { GenerationMethodAndParametersWithUnit } from "types/turbines";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { isRunning } from "./predicates";
import {
  branchIdAtomDef2,
  parkIdAtomDef2,
  projectIdAtomDef2,
} from "state/pathParams";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import { OptimizationStatusMessage } from "hooks/useAblyAnalysisStatus";
import { isNever, sum } from "utils/utils";

export const turbinesAndFoundationsGenerationIsLiveAtom = atom<boolean>(false);

export const methodAndParametersForRegionAtomFamily = atomFamily(
  (regionId: string | undefined) =>
    atomFromFn<
      | Promise<GenerationMethodAndParametersWithUnit | undefined>
      | GenerationMethodAndParametersWithUnit
      | undefined
    >(async (get) => {
      if (!regionId) {
        return;
      }
      let park = await get(
        parkFamily({ parkId: regionId, branchId: undefined }),
      );
      if (park) {
        const turbines = await get(
          turbinesInParkFamily({ parkId: park.id, branchId: undefined }),
        );
        const params = inferTurbineParameters(turbines, park);

        if (params.ok) return inferResultToObj(params.value);
        return {
          method: "manual",
          params: defaultRegularParamsWithUnits(),
        };
      } else {
        const subArea = await get(
          subAreaFamily({ subAreaId: regionId, branchId: undefined }),
        );
        if (!subArea)
          throw sendWarning("Illegal state: no park or sub area for regionId", {
            regionId,
          });
        const parkId = subArea.properties.parentIds?.[0];
        if (!isDefined(parkId))
          throw scream("Illegal state: sub area lacks parkId", {
            subArea,
            parkId,
          });
        park = await get(parkFamily({ parkId, branchId: undefined }));
        if (!park)
          throw scream(new Error("Illegal state: no park for parkId"), {
            parkId,
          });
        const turbines = (
          await get(
            turbinesInParkFamily({ parkId: park.id, branchId: undefined }),
          )
        ).filter((t) => pointInPolygon(t.geometry, subArea.geometry));
        const params = inferTurbineParameters(turbines, subArea);
        if (params.ok) return inferResultToObj(params.value);
        return {
          method: "manual",
          params: defaultRegularParamsWithUnits(),
        };
      }
    }),
);

/** IDs used to query for optimization problems. */
export type Ids = {
  nodeId: string;
  branchId: string;
  zoneId: string;
};

/** IDs that identify a single optimization problem. */
export type Pids = Ids & { id: string };

export const listOptProblemsAtom = atomFamily((ids: Ids) =>
  atomFromFn(() => listOptProblems(ids)),
);

export const recentlyDeletedProblemAtom = atom<string[]>([]);

/**
 * A single optimization problem, identified by {@link Pids}.
 */
const optProblemAtom = atomFamily((pids: Pids) =>
  atomFromFn<Promise<OptProblem | undefined>>(() =>
    getOptProblem(pids).catch((e) => {
      sendWarning("getOptProblem endpoint failed", { pids, e });
      return undefined;
    }),
  ),
);

/**
 * All optimization problems for a park, identified by {@link Ids}.
 */
export const optProblemsAtom = atomFamily((ids: Ids) =>
  atom<Promise<OptProblem[]>>(async (get) => {
    const problems = await get(listOptProblemsAtom(ids));
    const problemIds = problems.map((e) => e.id);
    const allProblems = await Promise.all(
      problemIds.map((id) => get(optProblemAtom({ ...ids, id }))),
    );
    return allProblems.filter(isDefined);
  }),
);

/**
 * Optimization results for a problem ideitified by {@link Pids}.
 */
export const problemResultsAtom = atomFamily((pids: Pids) =>
  atomFromFn<Promise<ListResultsForProblem | undefined>>(async () =>
    listResultsForProblem(pids).catch((e) => {
      sendWarning("listResultsForProblem endpoint failed", { pids, e });
      return undefined;
    }),
  ),
);

/**
 * `true` if we have any optimization problems running.
 */
export const hasRunningProblemsAtom = atomFamily((ids: Ids) =>
  atom<Promise<boolean>>(async (get) => {
    const problems = await get(optProblemsAtom(ids));
    const results = await Promise.all(
      problems.map((p) => get(problemResultsAtom({ ...ids, id: p.id }))),
    );
    const running = results
      .filter(isDefined)
      .filter((res) => {
        if (!res.items.length) return true;
        return res.items.some((p) => isRunning(p));
      })
      .filter((res) => {
        const p = get(totalProgressFamily(res.id));
        return p === undefined || p < 1;
      });
    return running.length > 0;
  }),
);

/**
 * Get the {@link Ids} that we'd use if we had opened the optimization panel.
 */
export const currentOptimizationIds = atom<Promise<Ids | undefined>>(
  async (get) => {
    const [nodeId, branchId, parkId] = await Promise.all([
      get(projectIdAtomDef2),
      get(branchIdAtomDef2),
      get(parkIdAtomDef2),
    ]);

    const subAreas = await get(
      subAreasInParkFamily({
        parkId,
        branchId: undefined,
      }),
    );

    let zoneId: string;
    // If there are sub areas, we require that the user clicks on exactly one.
    if (0 < subAreas.length) {
      const selectedFeatures = await get(selectedProjectFeaturesAtom);
      const subArea = selectedFeatures.filter(isSubArea)[0];
      if (subArea) {
        zoneId = subArea.id;
      } else {
        return undefined;
      }
    } else {
      zoneId = parkId;
    }
    return {
      nodeId,
      branchId,
      zoneId,
    };
  },
);

export const hasCurrentLoadingProblemsAtom = atom(async (get) => {
  const ids = await get(currentOptimizationIds);
  if (!ids) return false;
  const running = await get(hasRunningProblemsAtom(ids));
  return running;
});

// Palms are sweaty
// Knees weak, arms are heavy
export const combinedGenMooringParameters = atom<
  (MooringParameters & { raster: Raster }) | undefined
>(undefined);

export const depthRangeValidAreasFamily = atomFamily<
  { _foundationTypeId: string | undefined; _regionId: string },
  Feature<Polygon | MultiPolygon> | undefined
>(({ _foundationTypeId, _regionId }) =>
  atom<Feature<Polygon | MultiPolygon> | undefined>(undefined),
);

/**
 * Progress for optimization problems. This tracks the progress that each worker
 * has made, if any.
 */
const optimizationProgressFamily = atomFamily((_problemId: string) =>
  atom<
    | {
        stage: 0;
        fraction: number;
      }
    | {
        stage: 1;
        numWorkers: number;
        workerProgress: Record<number, number>;
      }
    | undefined
  >(undefined),
);

/**
 * Get the total progress of an optimization problem as a percentage.
 */
export const totalProgressFamily = atomFamily((problemId: string) =>
  atom<number | undefined>((get) => {
    const p = get(optimizationProgressFamily(problemId));
    if (!p) return undefined;
    // We figure the init step is 10% of the total time and the workers 90%
    if (p.stage === 0) return p.fraction * 0.1;
    else if (p.stage === 1) {
      return 0.1 + (0.9 * sum(Object.values(p.workerProgress))) / p.numWorkers;
    } else throw isNever(p);
  }),
);

/**
 * Handles a optimization status update message.
 * Returns the new progress of the optimization problem the message references.
 * This is usetul to run code when the problem finishes, as that is the first time
 * we return 1.0.
 */
export const registerProgress = atom(
  null,
  (get, set, msg: OptimizationStatusMessage) => {
    const p = msg.progress;
    const curr = get(optimizationProgressFamily(msg.id));

    // If we already have a stage, the message has a stage, and we've got a newer stage, skip this message.
    if (
      curr?.stage !== undefined &&
      p.stage !== undefined &&
      p.stage < curr.stage
    )
      return get(totalProgressFamily(msg.id));

    if (p.stage === 0) {
      set(optimizationProgressFamily(msg.id), {
        stage: p.stage,
        fraction: p.fraction,
      });
    } else if (p.stage === 1) {
      if (!p.worker) throw scream(`Missing 'worker' field of message`, msg);
      if (curr?.stage === p.stage) {
        set(optimizationProgressFamily(msg.id), {
          ...curr,
          workerProgress: {
            ...curr.workerProgress,
            [p.worker.i]: p.fraction,
          },
        });
      } else {
        set(optimizationProgressFamily(msg.id), {
          stage: p.stage,
          numWorkers: p.worker.n,
          workerProgress: {
            [p.worker.i]: p.fraction,
          },
        });
      }
    } else if (p.stage !== undefined) {
      throw scream(`Missing handling of stage '${p.stage}'`, { msg });
    }
    return get(totalProgressFamily(msg.id));
  },
);
