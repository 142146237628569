import { useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  CableType,
  createNewOrgExportCable,
  deleteOrgExportCable,
  updateOrgExportCable,
} from "services/cableTypeService";
import { Mixpanel } from "mixpanel";
import { libraryCableRefreshAtom } from "components/Cabling/Generate/state";
import { libraryCableTypesRefresh } from "state/jotai/cableType";
import { cableIdentifierAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { organisationExportCableResourceState } from "../state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

const useOrgExportCableCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshLibraryCable = useSetAtom(libraryCableTypesRefresh);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { showConfirm } = useConfirm();

  const updateLocal = useJotaiCallback(
    async (get, set, cable: CableType) => {
      aset(
        get,
        set,
        organisationExportCableResourceState({ organisationId }),
        (currentCables) => {
          const existing = currentCables.find((c) => c.id === cable.id);
          return existing
            ? currentCables.map((c) =>
                c.id === cable.id ? { ...c, ...cable } : c,
              )
            : [...currentCables, cable];
        },
      );
    },
    [organisationId],
  );

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        cable,
        name,
        projectAccess,
      }: {
        cable: Partial<CableType>;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const newCable = {
        ...cable,
        name: name ? name : `${cable.name} copy`,
      };

      const fallback = await get(
        organisationExportCableResourceState({
          organisationId,
        }),
      );

      try {
        const res = await createNewOrgExportCable(
          organisationId,
          newCable,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationExportCableResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );

        set(cableIdentifierAtom(organisationId), RESET);
        updateLocal(res);

        Mixpanel.track_old("Created org export cable", {
          cableId: cable.id,
          organisationId,
        });
        return res;
      } catch (e) {
        set(
          organisationExportCableResourceState({
            organisationId,
          }),
          async () => fallback,
        );
        throw e;
      }
    },
    [organisationId, updateLocal],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      cable: CableType,
      onlyUpdatingDescription: boolean = false,
    ) => {
      const fallback = await get(
        organisationExportCableResourceState({
          organisationId,
        }),
      );

      return updateOrgExportCable(organisationId, cable)
        .then((res) => {
          if (res) {
            aset(
              get,
              set,
              organisationExportCableResourceState({
                organisationId,
              }),
              (cur) => cur.map((c) => (c.id === cable.id ? res : c)),
            );

            if (!onlyUpdatingDescription) {
              set(libraryCableRefreshAtom, (cur) => cur + 1);
              refreshLibraryCable((c) => c + 1);
            }
            set(cableIdentifierAtom(organisationId), RESET);

            Mixpanel.track_old("Update org export cable", {
              cableId: cable.id,
              organisationId,
            });
          }
        })
        .catch((e) => {
          set(
            organisationExportCableResourceState({
              organisationId,
            }),
            async () => fallback,
          );
          throw e;
        });
    },
    [organisationId, refreshLibraryCable],
  );

  const remove = useJotaiCallback(
    async (get, set, cableId: string) => {
      const fallback = await get(
        organisationExportCableResourceState({
          organisationId,
        }),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.EXPORT_CABLE,
          resourceId: cableId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.EXPORT_CABLE,
          cableId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete cable type",
          message: `Export cable type is currently being used in ${usage.length} cables, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return deleteOrgExportCable(organisationId, cableId)
          .then((res) => {
            if (res) {
              aset(
                get,
                set,
                organisationExportCableResourceState({
                  organisationId,
                }),
                (curr) => curr.filter((c) => c.id !== cableId),
              );
              set(libraryCableRefreshAtom, (cur) => cur + 1);
              refreshLibraryCable((c) => c + 1);
              Mixpanel.track_old("Delete org export cable", {
                cableId: cableId,
                organisationId,
              });
            }
          })
          .catch((e) => {
            set(
              organisationExportCableResourceState({
                organisationId,
              }),
              async () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, refreshLibraryCable, showConfirm],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        cable,
        projectAccess,
        onSuccess,
      }: {
        cable: CableType;
        projectAccess?: string[];
        onSuccess?: (newCable: CableType) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "exportcable",
          defaultName: `${cable.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const newCable = await create({
              cable,
              name,
              projectAccess,
            });
            onSuccess?.(newCable);
            return newCable;
          },
        },
      });
    },
    [create, setMidScreenModalTypeOpen],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, cableId: string) => {
      aset(
        get,
        set,
        organisationExportCableResourceState({ organisationId }),
        (currentCables) => currentCables.filter((c) => c.id !== cableId),
      );
    },
    [organisationId],
  );

  return {
    create,
    update,
    remove,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgExportCableCrud;
