import { useMemo } from "react";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import WindIcon from "@icons/24/Wind.svg?react";
import { WindSourceConfiguration } from "../../services/windSourceConfigurationService";
import { useAtomValue } from "jotai";
import { windConfigurationsFamily } from "state/jotai/windConfiguration";

export const WindSourceConfigurationSelector = ({
  windConfiguration,
  setWindConfiguration,
}: {
  windConfiguration?: WindSourceConfiguration;
  setWindConfiguration: (cfg: WindSourceConfiguration) => void;
}) => {
  const configurations = useAtomValue(
    windConfigurationsFamily({ projectId: undefined }),
  );

  const items = useMemo(() => {
    const items: DropDownItem[] = [];
    for (const cfg of configurations.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
      });
    return items;
  }, [configurations]);

  const selected = items.find((i) => i.value === windConfiguration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = configurations.get(e);
        if (!cfg) throw new Error("Configuration not found");
        setWindConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <WindIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
