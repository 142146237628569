import { fetchEnhancer, fetchWithToken } from "services/utils";
import * as spec from "api/customer";
import * as z from "zod";
import { mapboxAccessToken } from "components/MapNative/constants";
import { _FeatureCollection } from "utils/geojson/geojson";

const _IntercomSearchResult = spec.components.schemas.IntercomSearchResult;

type IntercomSearchResult = z.infer<typeof _IntercomSearchResult>;

const _IntercomArticle = spec.components.schemas.IntercomArticle;

export type IntercomArticle = z.infer<typeof _IntercomArticle>;

const _MapboxSuggestion = z.object({
  name: z.string(),
  mapbox_id: z.string(),
  feature_type: z.string(),
  address: z.string().optional(),
  full_address: z.string().optional(),
  place_formatted: z.string(),
});

const _MapboxSuggestionResults = z.object({
  suggestions: z.array(_MapboxSuggestion),
});

export type MapboxSuggestion = z.infer<typeof _MapboxSuggestion>;

export const searchIntercomArticles = async (
  phrase: string,
): Promise<IntercomSearchResult> => {
  const json = await fetchWithToken(
    `/api/customer/intercom/search?phrase=${phrase}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((response) => response.json());

  return _IntercomSearchResult.parse(json);
};

export const getMapboxAddressSuggestions = async (
  phrase: string,
  proximity: [number, number],
  session_token: string,
): Promise<MapboxSuggestion[]> => {
  const json = await fetchEnhancer(
    `https://api.mapbox.com/search/searchbox/v1/suggest?q=${phrase}&language=en&proximity=${proximity.join(",")}&session_token=${session_token}&access_token=${mapboxAccessToken}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((response) => response.json());

  return _MapboxSuggestionResults.parse(json).suggestions;
};

export const retrieveMapboxAddress = async (
  mapbox_id: string,
  session_token: string,
) => {
  const json = await fetchEnhancer(
    `https://api.mapbox.com/search/searchbox/v1/retrieve/${mapbox_id}?session_token=${session_token}&access_token=${mapboxAccessToken}
`,
  ).then((response) => response.json());

  return _FeatureCollection.parse(json);
};
