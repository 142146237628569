import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { ABLY_FOLDER_STRUCTURE_UPDATED } from "state/ably";
import { useAblyGeneric } from "./useAblyGeneric";
import useOrgFolderStructureCrud from "./useOrgFolderStructureCrud";
import { LibraryManageRole } from "components/Organisation/Library/types";

const useAblyOrgFolderStructure = (
  organisationId: string,
  libraryManageRole: LibraryManageRole,
) => {
  const { updateLocal } = useOrgFolderStructureCrud({ libraryManageRole });

  const channelName = useMemo(
    () => organisationId && `${organisationId}:all`,
    [organisationId],
  );

  const onUpdateMessageReceived = useCallback(
    (message: InboundMessage) => {
      if (message.data.resourceType !== getResourceType(libraryManageRole)) {
        return;
      }

      updateLocal(message.data.items);
    },
    [updateLocal, libraryManageRole],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_FOLDER_STRUCTURE_UPDATED,
        onMessageReceived: onUpdateMessageReceived,
      },
    ],
    [onUpdateMessageReceived],
  );

  useAblyGeneric(channelName, events, organisationId);
};

export default useAblyOrgFolderStructure;

const getResourceType = (libraryManageRole: LibraryManageRole): string => {
  switch (libraryManageRole) {
    case LibraryManageRole.TURBINE:
      return "TURBINE";
    case LibraryManageRole.FOUNDATION:
      return "FOUNDATION";
    case LibraryManageRole.EXPORT_CABLE:
      return "EXPORT_CABLE";
    case LibraryManageRole.CABLE:
      return "CABLE";
    case LibraryManageRole.ANALYSIS:
      return "ANALYSIS";
    case LibraryManageRole.FINANCIAL:
      return "FINANCIAL";
    case LibraryManageRole.SUBSTATION:
      return "SUBSTATION";
    case LibraryManageRole.DATA_PACKAGE:
      return "DATA_PACKAGE";
  }
};
