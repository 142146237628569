import React, { ErrorInfo, ReactNode } from "react";
import { TerrainSlopeAnalysis } from "./SlopeAnalysis";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { scream } from "../../../../utils/sentry";
import { ProjectFeature } from "../../../../types/feature";
import { Polygon } from "geojson";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import WaterAreaAnalysis from "./WaterAreaAnalysis";
import { FloatingFocusManager, offset, useFloating } from "@floating-ui/react";
import { TourStep } from "components/OnboardingTours/TourStep";

type Props = {
  canvasFeature: ProjectFeature<Polygon>;
  onClose?(): void;
};

type ErrorProps = {
  children: ReactNode;
};

class SlopeErrorBoundary extends React.Component<
  ErrorProps,
  { hasError: boolean; error?: Error }
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    scream(error, { message: "SlopeErrorBoundary caught", errorInfo });
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <SimpleAlert
            text={"Something went wrong when running slope analysis..."}
            type={"error"}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

const TerrainAnalysis = ({ canvasFeature, onClose }: Props) => {
  const { scrollBodyRef } = useShowScrollShadow(true);

  const { refs, floatingStyles, context } = useFloating({
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 40,
        crossAxis: 200,
      }),
    ],
  });
  const {
    refs: refsAddExclusionZones,
    floatingStyles: floatingStylesAddExclusionZones,
    context: contextAddExclusionZones,
  } = useFloating({
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 60,
        crossAxis: 200,
      }),
    ],
  });

  return (
    <>
      <FloatingFocusManager context={context} modal={false}>
        <TourStep
          tourId="onshore-intro-tour"
          stepId="addWaterbodies"
          innerRef={refs.setFloating}
          style={floatingStyles}
        />
      </FloatingFocusManager>
      <FloatingFocusManager context={contextAddExclusionZones} modal={false}>
        <TourStep
          tourId="onshore-intro-tour"
          stepId="addWaterbodiesAsExclusionZones"
          innerRef={refsAddExclusionZones.setFloating}
          style={floatingStylesAddExclusionZones}
        />
      </FloatingFocusManager>

      <MenuFrame title="Topography" onExit={onClose} ref={refs.setReference}>
        <div ref={refsAddExclusionZones.setReference} />
        <div
          ref={scrollBodyRef}
          style={{
            maxHeight: "calc(100vh - 26rem)",
            overflowY: "auto",
          }}
        >
          <SlopeErrorBoundary>
            <TerrainSlopeAnalysis canvasFeature={canvasFeature} />
          </SlopeErrorBoundary>
          <WaterAreaAnalysis canvasFeature={canvasFeature} />
        </div>
      </MenuFrame>
    </>
  );
};

export default TerrainAnalysis;
