import { atomFamily } from "utils/jotai";
import { featuresFamily, featuresInParkFamily } from "./features";
import { atom } from "jotai";
import { SubstationFeature } from "types/feature";
import { SubstationType } from "services/substationService";
import { allSubstationsForProjectAtom } from "./substationType";
import { isDefined } from "utils/predicates";

export const substationsFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<SubstationFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).substation,
    ),
);

export const substationsInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<SubstationFeature[]>>(
      async (get) =>
        (await get(featuresInParkFamily({ parkId, branchId }))).substation,
    ),
);

export const substationsInParkWithTypeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<[SubstationFeature, SubstationType][]>>(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const types = await get(allSubstationsForProjectAtom);

      return substations
        .map((t) => {
          const typ = types.get(t.properties.substationTypeId ?? "");
          if (!typ) return;
          return [t, typ] satisfies [SubstationFeature, SubstationType];
        })
        .filter(isDefined);
    }),
);
