import {
  BranchInfo,
  ExtraProjectUsageInfo,
} from "components/ConfigurationModal/SettingsUsage/common";
import {
  SuccessText,
  NumberWrapper,
  InfoText,
  UsageContainer,
  UsageContainerPlacement,
  UsageContainerLeftRightPlacement,
  ExtraContainer,
  Number,
} from "components/ConfigurationModal/SettingsUsage/style";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { LibraryManageRoleType } from "components/Organisation/Library/types";
import { useUserAccessState } from "components/Projects/useUserAccessState";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { Loadable } from "jotai/vanilla/utils/loadable";
import {
  ReactNode,
  useState,
  useRef,
  useMemo,
  useEffect,
  Suspense,
} from "react";
import { ResourceType, ResourceTypeUsage } from "services/usageService";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { IconREMSize } from "styles/typography";
import EarthIcon from "@icons/24/Earth.svg";
import BranchIcon from "@icons/24/Branch.svg";
import SuccessIcon from "@icons/24/Success.svg";
import { Node } from "services/customerAPI";
import { LibraryManageRole } from "components/Organisation/Library/types";

interface NumberOfResourcesInNodeProps {
  node: Node;
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">;
  resourceId: string;
  hasNodeAccess: boolean;
  icon: React.ReactNode;
  nodeId: string;
}

const getResourceUsageTypeFromRole = (
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">,
): ResourceType => {
  switch (libraryManageRole) {
    case "org_turbine_manage":
      return "TURBINE";
    case "org_foundation_manage":
      return "FOUNDATION";
    case "org_cable_manage":
    case "org_export_cable_manage":
      return "CABLE";
    case "org_analysis_manage":
      return "ANALYSIS_CONFIGURATION";
    case "org_financial_manage":
      return "COST_CONFIGURATION";
    case "org_substation_manage":
      return "SUBSTATION";
    default:
      throw new Error("Invalid library manage role");
  }
};

function NumberOfResourcesInNode({
  node,
  libraryManageRole,
  resourceId,
  hasNodeAccess,
  icon,
  nodeId,
}: NumberOfResourcesInNodeProps) {
  const usageLoadable = useAtomValue(
    loadable(
      projectResourceUsageAtomFamily({
        nodeId,
        resourceId,
        resourceType: getResourceUsageTypeFromRole(libraryManageRole),
      }),
    ),
  );

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  return (
    <>
      {usageLoadable.data.length > 0 ? (
        <>
          <IconREMSize width={1.4} height={1.4}>
            <SuccessIcon />
          </IconREMSize>
          <SuccessText>Yes:</SuccessText>
          {libraryManageRole !== LibraryManageRole.FINANCIAL &&
            libraryManageRole !== LibraryManageRole.ANALYSIS && (
              <NumberWrapper>
                {icon}
                {usageLoadable.data?.length}
              </NumberWrapper>
            )}
          {node.type === "folder" ? (
            <UsageInProjectContainerInner loadable={usageLoadable}>
              <ExtraProjectUsageInfo usage={usageLoadable.data} />
            </UsageInProjectContainerInner>
          ) : (
            <UsageInBranchContainerInner loadable={usageLoadable}>
              {hasNodeAccess ? (
                <ExtraBranchUsageInfo usage={usageLoadable.data} />
              ) : (
                "Access required"
              )}
            </UsageInBranchContainerInner>
          )}
        </>
      ) : (
        <InfoText>Not in use in this {node.type}</InfoText>
      )}
    </>
  );
}

function UsageInBranchContainerInner({
  loadable,
  children,
}: {
  loadable: Loadable<ResourceTypeUsage[]>;
  children: ReactNode;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild, setIsMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  const uniqueBranches = useMemo(() => {
    if (loadable.state !== "hasData") {
      return [];
    }
    return loadable.data.reduce(
      (pre: ResourceTypeUsage[], cur: ResourceTypeUsage) => {
        return pre.some((p) => p.branchId === cur.branchId)
          ? pre
          : [...pre, cur];
      },
      [],
    );
  }, [loadable]);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasData") {
    return (
      <UsageContainer>
        <SkeletonBlock
          style={{
            width: "100%",
            height: "2rem",
          }}
        />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer fillOrStroke="fill">
      <NumberWrapper
        onMouseEnter={() => setIsMouseOverParent(true)}
        onMouseLeave={() => setIsMouseOverParent(false)}
      >
        <BranchIcon />
        <Number>
          {uniqueBranches.length}
          {uniqueBranches.length > 0 && showChild && (
            <ExtraContainer
              placement={UsageContainerPlacement.MIDDLE}
              placementLeftRight={UsageContainerLeftRightPlacement.RIGHT}
              onMouseEnter={() => setIsMouseOverChild(true)}
              onMouseLeave={() => setIsMouseOverChild(false)}
            >
              <Suspense
                fallback={
                  <SkeletonText
                    style={{
                      width: "4rem",
                      height: "2rem",
                    }}
                  />
                }
              >
                {children}
              </Suspense>
            </ExtraContainer>
          )}
        </Number>
      </NumberWrapper>
    </UsageContainer>
  );
}

function ExtraBranchUsageInfo({ usage }: { usage: ResourceTypeUsage[] }) {
  const uniqueBranches = useMemo(
    () =>
      usage.reduce<ResourceTypeUsage[]>(
        (pre, cur) =>
          pre.some((p) => p.branchId === cur.branchId) ? pre : pre.concat(cur),
        [],
      ),
    [usage],
  );

  return (
    <>
      {usage.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            {uniqueBranches.map((u) => (
              <BranchInfo key={u.branchId} usage={u} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function UsageInProjectContainerInner({
  loadable,
  children,
}: {
  loadable: Loadable<ResourceTypeUsage[]>;
  children: ReactNode;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild, setIsMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  const uniqueProjects = useMemo(() => {
    if (loadable.state !== "hasData") {
      return [];
    }
    return loadable.data.reduce(
      (pre: ResourceTypeUsage[], cur: ResourceTypeUsage) => {
        return pre.some((p) => p.projectId === cur.projectId)
          ? pre
          : [...pre, cur];
      },
      [],
    );
  }, [loadable]);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasData") {
    return (
      <UsageContainer>
        <SkeletonBlock
          style={{
            width: "100%",
            height: "2rem",
          }}
        />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer fillOrStroke="stroke">
      <NumberWrapper
        onMouseEnter={() => setIsMouseOverParent(true)}
        onMouseLeave={() => {}}
      >
        <EarthIcon />
        <Number>
          {uniqueProjects.length}
          {uniqueProjects.length > 0 && showChild && (
            <ExtraContainer
              placement={UsageContainerPlacement.MIDDLE}
              placementLeftRight={UsageContainerLeftRightPlacement.RIGHT}
              onMouseEnter={() => setIsMouseOverChild(true)}
              onMouseLeave={() => {}}
            >
              <Suspense
                fallback={
                  <SkeletonText
                    style={{
                      width: "4rem",
                      height: "2rem",
                    }}
                  />
                }
              >
                {children}
              </Suspense>
            </ExtraContainer>
          )}
        </Number>
      </NumberWrapper>
    </UsageContainer>
  );
}

export const ComponentUsageInNodeGeneral = ({
  node,
  resourceId,
  nodeId,
  icon,
  libraryManageRole,
}: {
  node: Node;
  resourceId: string;
  nodeId: string;
  icon: React.ReactNode;
  libraryManageRole: Exclude<LibraryManageRoleType, "org_data_package_manage">;
}) => {
  const { data: userAllNodesAccess } = useUserAccessState();

  const nodeAccess = useMemo(() => {
    if (!userAllNodesAccess || !userAllNodesAccess.node_access) return [];
    return Object.keys(userAllNodesAccess.node_access);
  }, [userAllNodesAccess]);

  const hasNodeAccess = nodeAccess.includes(nodeId);

  return (
    <NumberOfResourcesInNode
      node={node}
      libraryManageRole={libraryManageRole}
      resourceId={resourceId}
      hasNodeAccess={hasNodeAccess}
      icon={icon}
      nodeId={nodeId}
    />
  );
};
