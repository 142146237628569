import mapboxgl from "mapbox-gl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { currentMapStyleIdAtom } from "state/map";
import * as turf from "@turf/turf";
import styled from "styled-components";
import { ProjectFeature, _GeoJSONFeatureToOtherFeature } from "types/feature";
import { addIdOnFeaturesIfNotUUID4 } from "utils/geojson/utils";
import { z } from "zod";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "geojson";
import { SimpleMapboxSyncEffects } from "components/Mapbox/MapboxSyncEffects";
import PolygonFeatures from "components/MapFeatures/Polygon";
import LineStringFeatures from "components/MapFeatures/LineString";
import PointFeatures from "components/MapFeatures/Point";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { spacing4 } from "styles/space";
import { typography } from "styles/typography";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import Button from "components/General/Button";
import { modalTypeOpenAtom } from "state/modal";
import { FeaturePropertiesEditor } from "components/FeatureProperties/FeaturePropertiesMenuFrame";
import Spinner from "@icons/spinner/Spinner";
import UndoRedoEditMap from "./UndoRedoEditMap";
import DrawToolsEditMap from "./DrawToolsEditMap";
import DragSelect from "./DragSelect";
import { DropFile } from "./DropFile";
import { parseFileAndCleanGeoJson } from "components/UploadModal/utils";
import { useToast } from "hooks/useToast";
import {
  prepareGISFilesInGDALFriendlyFormat,
  ONE_MEGABYTE_IN_BYTES,
  acceptedLayerFileEndingsWithShapeFiles,
} from "components/UploadModal/components/NewUploadTab/UploadDataLayer/UploadDataLayerGeneral";
import { neededShpFileSuffixes } from "components/UploadFile/fileUtils";
import { colors } from "styles/colors";
import { EditableText } from "components/General/EditableText";
import { DataLibraryLayer } from "components/Organisation/Library/dataLibrary/types";
import useTextInput from "hooks/useTextInput";
import { useAtomValue, useSetAtom } from "jotai";

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const HeaderTitle = styled.div`
  ${typography.h3};
  color: white;
`;

const MenuWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const startBounds = [
  [-6.15234375, 52.05249047600099],
  [17.75390625, 66.08936427047088],
] as [[number, number], [number, number]];

const parseFeatures = (
  featureCollection: FeatureCollection,
  type: "Polygon" | "LineString" | "Point",
) => {
  const featuresFiltered = z
    .object({ features: _GeoJSONFeatureToOtherFeature.array() })
    .parse(featureCollection).features;
  return addIdOnFeaturesIfNotUUID4(
    featuresFiltered.filter((f) => {
      if (f.geometry == null) {
        return false;
      }
      return f.geometry.type.includes(type);
    }),
  );
};

const InformationBox = ({
  selectedId,
  setSelectedIds,
  features,
  setLocalFeatureCollection,
}: {
  selectedId: string;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  features: Feature[];
  setLocalFeatureCollection: React.Dispatch<
    React.SetStateAction<FeatureCollection>
  >;
}) => {
  const feature = useMemo(
    () => features.find((f) => f.id === selectedId),
    [features, selectedId],
  );

  if (!feature) return null;

  return (
    <MenuFrame
      title="Information"
      style={{
        maxHeight: "80vh",
        position: "absolute",
        top: spacing4,
        right: spacing4,
      }}
      onExit={() => setSelectedIds([])}
    >
      <FeaturePropertiesEditor
        canvasFeature={feature as ProjectFeature}
        isEditor={true}
        nameEditable={true}
        inReadOnlyMode={false}
        updateFeatures={(updatedFeatures) => {
          if (!updatedFeatures) return;
          setLocalFeatureCollection((featureCollection) => {
            return {
              ...featureCollection,
              features: featureCollection.features.map((f) => {
                return updatedFeatures.find((uf) => uf.id === f.id) ?? f;
              }),
            };
          });
        }}
      />
    </MenuFrame>
  );
};

const KeyboardShortcuts = ({
  mapContainer,
  deleteSelectedFeatures,
}: {
  mapContainer: React.RefObject<HTMLDivElement>;
  deleteSelectedFeatures: () => void;
}) => {
  useEffect(() => {
    const map = mapContainer.current;
    if (!map) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (!["Backspace", "Delete"].includes(e.key)) {
        return;
      }

      deleteSelectedFeatures();
    };
    map.addEventListener("keydown", onKeyDown);
    return () => {
      map.removeEventListener("keydown", onKeyDown);
    };
  }, [deleteSelectedFeatures, mapContainer]);

  return null;
};

const EditMap = ({
  featureCollection,
  color,
  layer,
  onSave,
  onSaveNewName,
}: {
  featureCollection: FeatureCollection;
  color: string;
  layer: DataLibraryLayer;
  onSave: (newFeatureCollection: FeatureCollection) => Promise<void>;
  onSaveNewName: (newName: string) => void;
}) => {
  const { error, info } = useToast();
  const [layerName, onLayerNameChange] = useTextInput(layer.name);
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const activeMapStyleId = useAtomValue(currentMapStyleIdAtom);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [undoHistory, setUndoHistory] = useState<FeatureCollection[]>([]);
  const [redoHistory, setRedoHistory] = useState<FeatureCollection[]>([]);
  const [localFeatureCollection, _setLocalFeatureCollection] =
    useState(featureCollection);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [loading, setLoading] = useState(false);

  const setLocalFeatureCollection = useCallback(
    (
      set: React.SetStateAction<FeatureCollection<Geometry, GeoJsonProperties>>,
    ) => {
      setUndoHistory((h) => [...h, localFeatureCollection]);
      _setLocalFeatureCollection(set);
    },
    [_setLocalFeatureCollection, localFeatureCollection],
  );

  const handleNewFiles = useCallback(
    async (files: File[]) => {
      const { allAcceptableFiles, invalidShpNames, filesTooLarge } =
        await prepareGISFilesInGDALFriendlyFormat(files);

      if (filesTooLarge.length !== 0) {
        error(
          `Some files are too large and have been removed, maximum 100 megabytes zipped / 300 megabytes unzipped allowed\n\n${filesTooLarge.map((f) => `${f.name}: ${Math.round(f.size / ONE_MEGABYTE_IN_BYTES)}mb\n`)}`,
          {
            timeout: 8000,
          },
        );
      }

      if (invalidShpNames.length !== 0) {
        info(
          `file(s) "${invalidShpNames.join(
            ",",
          )}" needs corresponding shp,${neededShpFileSuffixes.join(
            ",",
          )} files to be able to be imported`,
          { timeout: 10000 },
        );
      }

      try {
        const geojsonMaybe = await Promise.all(
          allAcceptableFiles.map((f) =>
            parseFileAndCleanGeoJson({
              file: f,
            }),
          ),
        );
        const features = geojsonMaybe.flatMap((g) => g.cleaned);
        const bboxFeatureCollection = turf.bbox({
          type: "FeatureCollection",
          features,
        });
        setLocalFeatureCollection((f) => ({
          ...f,
          features: [...f.features, ...features],
        }));

        if (!map) return;
        map.fitBounds(
          new mapboxgl.LngLatBounds(
            [bboxFeatureCollection[0], bboxFeatureCollection[1]],
            [bboxFeatureCollection[2], bboxFeatureCollection[3]],
          ),
        );
      } catch (e) {
        error("Unable to parse GIS data from file");
      }
    },
    [error, info, map, setLocalFeatureCollection],
  );

  const undo = useCallback(() => {
    if (undoHistory.length === 0) return;
    const lastFeatureCollection = undoHistory[undoHistory.length - 1];
    setRedoHistory((r) => [...r, localFeatureCollection]);
    _setLocalFeatureCollection(lastFeatureCollection);
    setUndoHistory((h) => h.slice(0, h.length - 1));
  }, [
    undoHistory,
    setRedoHistory,
    _setLocalFeatureCollection,
    setUndoHistory,
    localFeatureCollection,
  ]);

  const redo = useCallback(() => {
    if (redoHistory.length === 0) return;
    const lastFeatureCollection = redoHistory[redoHistory.length - 1];
    setUndoHistory((h) => [...h, localFeatureCollection]);
    _setLocalFeatureCollection(lastFeatureCollection);
    setRedoHistory((r) => r.slice(0, r.length - 1));
  }, [
    redoHistory,
    setRedoHistory,
    _setLocalFeatureCollection,
    setUndoHistory,
    localFeatureCollection,
  ]);

  useEffect(() => {
    if (!mapContainer.current) return;

    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      logoPosition: "bottom-right",
      bounds: startBounds,
    });
    newMap.dragRotate.disable();
    newMap.touchZoomRotate.disableRotation();

    newMap.on("load", () => {
      newMap.resize();
      const bboxFeatureCollection = turf.bbox(featureCollection);
      newMap.fitBounds(
        new mapboxgl.LngLatBounds(
          [bboxFeatureCollection[0], bboxFeatureCollection[1]],
          [bboxFeatureCollection[2], bboxFeatureCollection[3]],
        ),
      );
      setMap(newMap);
    });
  }, [mapContainer, activeMapStyleId, setMap, featureCollection]);

  const polygons = useMemo(
    () => parseFeatures(localFeatureCollection, "Polygon"),
    [localFeatureCollection],
  );
  const lineStrings = useMemo(
    () => parseFeatures(localFeatureCollection, "LineString"),
    [localFeatureCollection],
  );
  const points = useMemo(
    () => parseFeatures(localFeatureCollection, "Point"),
    [localFeatureCollection],
  );

  return (
    <TopBarModal disableClose={true}>
      <TopBarModalHeader
        title={
          <EditableText
            type="text"
            smallInput={true}
            style={{
              ...typography.sub1,
              color: "white",
              width: "50rem",
            }}
            value={layerName}
            onChange={onLayerNameChange}
            onEnter={() => {
              if (layerName !== layer.name) {
                onSaveNewName(layerName);
              }
            }}
            renderText={(text) => <HeaderTitle>{text}</HeaderTitle>}
            editIconStrokeColor={colors.white}
            editIconStrokeHoverColor={colors.blue300}
            tooltipTheme="light"
            tooltipPosition="bottom"
          />
        }
        rightSide={
          <MenuWrapper>
            <Button
              buttonType="secondary"
              disabled={undoHistory.length === 0}
              onClick={() => {
                if (
                  confirm(
                    "Are you sure you want to reset the" +
                      "  changes you have done to this layer?",
                  )
                ) {
                  _setLocalFeatureCollection(featureCollection);
                  setUndoHistory([]);
                  setRedoHistory([]);
                }
              }}
              text={"Reset"}
            />
            <Button
              icon={loading ? <Spinner size={"1rem"} /> : undefined}
              disabled={undoHistory.length === 0}
              onClick={async () => {
                setUndoHistory([]);
                setLoading(true);
                await onSave(localFeatureCollection);
                setLoading(false);
              }}
              text="Save"
            />
          </MenuWrapper>
        }
        onClose={() => {
          if (
            undoHistory.length === 0 ||
            confirm("Are you sure you want to exit without saving?")
          ) {
            setModalTypeOpen(undefined);
          }
        }}
      />
      <DropFile
        acceptedFileTypes={acceptedLayerFileEndingsWithShapeFiles}
        handleNewFiles={handleNewFiles}
      >
        <MapWrapper ref={mapContainer} />
        {map && (
          <>
            <DrawToolsEditMap
              map={map}
              setLocalFeatureCollection={setLocalFeatureCollection}
              handleNewFiles={handleNewFiles}
              acceptedFileTypes={acceptedLayerFileEndingsWithShapeFiles}
            />
            {selectedIds.length === 1 && (
              <InformationBox
                setLocalFeatureCollection={setLocalFeatureCollection}
                setSelectedIds={setSelectedIds}
                selectedId={selectedIds[0]}
                features={localFeatureCollection.features}
              />
            )}
            <UndoRedoEditMap
              undo={undo}
              redo={redo}
              canRedo={redoHistory.length !== 0}
              canUndo={undoHistory.length !== 0}
              mapContainer={mapContainer}
            />
          </>
        )}
      </DropFile>
      <DragSelect map={map} setSelectedIds={setSelectedIds} />
      <KeyboardShortcuts
        mapContainer={mapContainer}
        deleteSelectedFeatures={() => {
          setLocalFeatureCollection((featureCollection) => {
            return {
              ...featureCollection,
              features: featureCollection.features.filter(
                (f) => !selectedIds.includes((f.id ?? "").toString()),
              ),
            };
          });
        }}
      />
      {map && (
        <>
          <SimpleMapboxSyncEffects
            setCurrentSelectionArray={setSelectedIds}
            map={map}
          />
          <PolygonFeatures
            layerId={"edit-map-polygons-layer-id"}
            sourceId={"edit-map-polygons-source-id"}
            map={map}
            features={polygons}
            onClickCallback={(features) => {
              setSelectedIds(
                features
                  .filter((f) => f.id)
                  .map((f) => f.id)
                  .slice(0, 1) as string[],
              );
            }}
            onDbClickCallback={() => {
              console.log("test");
            }}
            selectedIds={selectedIds}
            paint={{
              "fill-color": ["string", ["get", "color"], color],
            }}
          />
          <LineStringFeatures
            layerId={"edit-map-linestrings-layer-id"}
            sourceId={"edit-map-linestrings-source-id"}
            map={map}
            features={lineStrings}
            onClickCallback={(features) => {
              setSelectedIds(
                features
                  .filter((f) => f.id)
                  .map((f) => f.id)
                  .slice(0, 1) as string[],
              );
            }}
            selectedIds={selectedIds}
            paint={{
              "line-color": ["string", ["get", "color"], color],
              "line-width": 5,
            }}
          />
          <PointFeatures
            layerId={"edit-map-points-layer-id"}
            sourceId={"edit-map-points-source-id"}
            map={map}
            features={points}
            onDbClickCallback={() => {
              console.log("test");
            }}
            onClickCallback={(features) => {
              setSelectedIds(
                features
                  .filter((f) => f.id)
                  .map((f) => f.id)
                  .slice(0, 1) as string[],
              );
            }}
            selectedIds={selectedIds}
            paint={{
              "circle-color": ["string", ["get", "color"], color],
            }}
          />
        </>
      )}
    </TopBarModal>
  );
};

export default EditMap;
