import { TypedWorker, promiseWorker, typedWorker } from "../../../utils/utils";
import { Context, version as cwVersion } from "../../../cables/cables_wasm";
import { scream } from "../../../utils/sentry";

/**
 * This namespace contains all the handlers used to interface with the web
 * worker running cable code.
 */
export namespace cw {
  export type Fn =
    | ["new", [json: string]]
    | ["partition_turbines", Parameters<Context["partition_turbines"]>]
    | ["split_into_arrays", Parameters<Context["split_into_arrays"]>]
    | ["connect_arrays", Parameters<Context["connect_arrays"]>]
    | ["generate", Parameters<Context["generate"]>]
    | ["generate_substation", Parameters<Context["generate_substation"]>]
    | ["version", Parameters<typeof version>];

  let worker: TypedWorker<Fn, any> | undefined = undefined;

  const getOrInit = () => {
    if (!worker)
      worker = typedWorker<Fn, any>(
        new Worker(new URL("./cableGeneratorWebWorker.ts", import.meta.url), {
          type: "module",
        }),
      );
    return worker;
  };

  /** Tiny helper used to wrap a function type to an async function with the same input/outputs.  */
  type AFn<F extends (...args: any) => any> = (
    ...args: Parameters<F>
  ) => Promise<ReturnType<F> | { error: string }>;

  export const version: AFn<typeof cwVersion> = () =>
    promiseWorker(getOrInit(), ["version", []], "CableWorker/version");

  export const newContext: AFn<(s: string) => void> = (...args) =>
    promiseWorker(getOrInit(), ["new", args], "CableWorker/new");

  export const partition_turbines: AFn<Context["partition_turbines"]> = (
    ...args
  ) =>
    promiseWorker(
      getOrInit(),
      ["partition_turbines", args],
      "CableWorker/partition_turbines",
    );

  export const split_into_arrays: AFn<Context["split_into_arrays"]> = (
    ...args
  ) =>
    promiseWorker(
      getOrInit(),
      ["split_into_arrays", args],
      "CableWorker/split_into_arrays",
    );

  export const connect_arrays: AFn<Context["connect_arrays"]> = (...args) =>
    promiseWorker(
      getOrInit(),
      ["connect_arrays", args],
      "CableWorker/connect_arrays",
    );

  export const generate: AFn<Context["generate"]> = (
    subs,
    turbines,
    options,
    post,
  ) => {
    // NOTE: this has to be awkward because we want `post` here, but we can't send a closure to the worker.
    const worker = getOrInit();
    return new Promise((res, rej) => {
      worker.postMessage(["generate", [subs, turbines, options]]);
      worker.onmessage((e) => {
        if (typeof e.data === "string") {
          post?.(e.data);
        } else {
          return res(e.data);
        }
      });
      worker.onerror((e) => {
        if (e.error instanceof Error) {
          scream(e.error, {
            which: "generate",
            e,
            message: "CableWorker.onerror",
          });
        } else {
          scream(new Error("CableWorker.onerror"), { which: "generate", e });
        }
        rej(e);
      });
    });
  };

  export const generate_substation: AFn<
    Context["generate_substation"]
  > = async (turbines, locked_substations, options, post) => {
    // NOTE: this has to be awkward because we want `post` here, but we can't send a closure to the worker.
    const worker = getOrInit();
    return new Promise((res, rej) => {
      worker.postMessage([
        "generate_substation",
        [turbines, locked_substations, options],
      ]);
      worker.onmessage((e) => {
        if (typeof e.data === "string") {
          post?.(e.data);
        } else {
          return res(e.data);
        }
      });
      worker.onerror((e) => {
        if (e.error instanceof Error) {
          scream(e.error, {
            which: "generate_substation",
            e,
            message: "CableWorker.onerror",
          });
        } else {
          scream(new Error("CableWorker.onerror"), {
            which: "generate_substation",
            e,
          });
        }
        rej(e);
      });
    });
  };

  export const reset = () => {
    getOrInit().terminate();
    worker = undefined;
  };
}
