import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { z } from "zod";
import { fetchEnhancerWithToken } from "../../../../services/utils";

const _ClientCredentialScope = z.enum([
  "api.vind.ai/read_library_turbines",
  "api.vind.ai/write_library_turbines",
  "api.vind.ai/read_library_cables",
  "api.vind.ai/write_library_cables",
  "api.vind.ai/read_branch_features",
]);

export type ClientCredentialScope = z.infer<typeof _ClientCredentialScope>;

const _ClientCredentials = z.object({
  client_id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  created_at: z.number(),
  scopes: z.array(_ClientCredentialScope),
});

const _ClientCredentialsFull = _ClientCredentials.extend({
  client_secret: z.string(),
});

export type ClientCredentials = z.infer<typeof _ClientCredentials>;
type ClientCredentialsFull = z.infer<typeof _ClientCredentialsFull>;

async function getClientCredentials({
  organisationId,
}: {
  organisationId: string;
}) {
  const url = `/api/v2/integrations/${organisationId}/clients`;
  const options = {
    method: "GET",
    headers: {
      "vind-beta-access": "true",
    },
  };
  const res = await fetchEnhancerWithToken(url, options ?? {});
  if (!res.ok || res.status !== 200) {
    // handle error
    const body = (await res.json()) as {
      error: {
        message: string;
      };
    };
    throw new Error(`failed to get: ${body?.error?.message}`);
  }
  const json = await res.json();
  const clients = z.array(_ClientCredentials).parse(json);
  return clients;
}

export async function postClientCredentials({
  organisationId,
  body,
}: {
  organisationId: string;
  body: {
    name: string;
    scopes: ClientCredentialScope[];
    description?: string;
  };
}) {
  const url = `/api/v2/integrations/${organisationId}/clients`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "vind-beta-access": "true",
    },
    body: JSON.stringify(body),
  };
  const res = await fetchEnhancerWithToken(url, options ?? {});
  if (!res.ok || res.status !== 200) {
    // handle error
    const body = (await res.json()) as {
      error: {
        message: string;
      };
    };
    throw new Error(`failed to create: ${body?.error?.message}`);
  }
  const json = await res.json();
  const value = _ClientCredentialsFull.parse(json);
  return value;
}

export async function revokeClientCredentials({
  organisationId,
  clientId,
}: {
  organisationId: string;
  clientId: string;
}) {
  const url = `/api/v2/integrations/${organisationId}/clients/${clientId}`;
  const options = {
    method: "DELETE",
    headers: {
      "vind-beta-access": "true",
    },
  };
  const res = await fetchEnhancerWithToken(url, options ?? {});
  console.log(res);
  if (!res.ok || res.status !== 204) {
    // handle error
    const body = (await res.json()) as {
      error: {
        message: string;
      };
    };
    throw new Error(`failed to delete: ${body?.error?.message}`);
  }
  return;
}

export const orgClientCredentials = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<ClientCredentials[]>>(async () => {
      return getClientCredentials({
        organisationId,
      });
    }),
);

export const copyClientCredentialsModalState =
  atom<null | ClientCredentialsFull>(null);
