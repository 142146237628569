import { mapAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import { maxDepth } from "../components/DepthSlider/DepthSlider";

import {
  depthFilterAtomFamily,
  renderDepthFilterAtom,
  renderShoreDistanceAtom,
  renderWindSpeedAtom,
  shoreDistanceFilterAtomFamily,
  windSpeedFilterAtomFamily,
  maxShorelineDistance,
  maxShoreline,
} from "../state/filter";
import {
  lowerRightMenuActiveModeAtom,
  windLayerHeightAtom,
} from "../state/layer";
import { Map as MapboxMap, MercatorCoordinate } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { addLayer, filterLayerId } from "../components/Mapbox/utils";
import {
  maxWindSpeed,
  maxWindSpeedScalingFactor,
  minWindSpeed,
} from "../types/filter";
import { useAtomValue } from "jotai";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { MaybePromise } from "types/utils";
import { FilterMenuType } from "components/LowerRight/FilterInput";
import { wrapMapboxTile } from "types/tile";

const tileSize = 512;
const maxzoom = 7;

class TerrainLayer2 implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  program: any;
  vertexBuffer: any;
  map: MapboxMap | undefined;
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;
  windLayerHeight: number;

  depth: number[];
  shorelineDistance: number[];
  windSpeed: number[];
  isSubmitted: boolean;
  urlFuncDepth = (z: number, x: number, y: number) =>
    `/tiles/gebco-terrarium-2023/${z}/${x}/${y}.png`;

  urlFuncShoreDistance = (z: number, x: number, y: number) =>
    `/tiles/shore/${z}/${x}/${y}.png`;

  urlFuncGWA = (z: number, x: number, y: number) =>
    `/tiles/gwa/speed/${this.windLayerHeight}/${z}/${x}/${y}.png`;

  constructor(maxZoom: number, tileSize: number, windLayerHeight: number) {
    this.id = filterLayerId;
    this.type = "custom";
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.textureCache = new Map();
    this.windLayerHeight = windLayerHeight;

    this.depth = [0, maxDepth];
    this.shorelineDistance = [0, maxShorelineDistance];
    this.windSpeed = [0, maxWindSpeed];
    this.isSubmitted = false;
  }

  setWindLayerHeight = (windLayerHeight: number) => {
    this.windLayerHeight = windLayerHeight;
    this.textureCache = new Map();
  };

  setDepth(newDepth: number[]) {
    this.depth = newDepth;
  }

  setShorelineDistance(newShorelineDistance: number[]) {
    this.shorelineDistance = newShorelineDistance;
  }

  setWindSpeed(newWindSpeed: number[]) {
    this.windSpeed = newWindSpeed;
  }

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;

    const vertexSource = `
      attribute vec2 a_pos;
      varying vec2 v_pos;
      uniform vec2 u_minMax;
      void main() {
        v_pos = vec2(a_pos / 1.);
        gl_Position = vec4(a_pos, 1.0, 1.0);
      }`;

    var fragmentSource = `
  precision highp float;
  uniform sampler2D u_depth_raster;
  uniform sampler2D u_shoreline_distance_raster;
  uniform sampler2D u_windspeed_raster;
  uniform sampler2D u_palette;
  uniform vec2 u_depth;
  uniform vec2 u_shorelineDistance;
  uniform vec2 u_windspeed;
  uniform vec4 u_uv_offsets;
  varying vec2 v_pos;

  float getDepth(vec2 coord) {
    vec4 color = texture2D(u_depth_raster, coord);
    float R = color.r * 255.0;
    float G = color.g * 255.0;
    float B = color.b * 255.0;
    return ((R * 256.0 + G + B / 256.0) - 32768.0) * -1.;
  }

  vec2 getWindSpeedWithAlpha(vec2 coord) {
    vec4 value = texture2D(u_windspeed_raster, coord);
    float speed = value.r * 10. + 3.;
    float alpha = value.a;
    return vec2(speed, alpha);
  }

  void main() {
    vec2 uv = 0.5 * v_pos + 0.5;
    uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
    uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

    float depth = getDepth(uv);
    vec2 windSpeedWithAlpha = getWindSpeedWithAlpha(uv);
    float windSpeed = windSpeedWithAlpha.x;
    float windSpeedAlpha = windSpeedWithAlpha.y;
    vec4 texSample = texture2D(u_depth_raster, uv);
    vec4 shoreLineDistance = texture2D(u_shoreline_distance_raster, uv);
    float distance = shoreLineDistance.r * 256.;

    float alphaShorelineDistance = 0.0;
    if(distance > 0. && distance < u_shorelineDistance.x && distance < u_shorelineDistance.y) {
      alphaShorelineDistance = 1.;
    } else if(distance > 0. && distance > u_shorelineDistance.y) {
      alphaShorelineDistance = 1.;
    }

    float alphaWindSpeed = 0.;
    if(u_windspeed.x > ${minWindSpeed}.0 && windSpeed < (u_windspeed.x / ${maxWindSpeedScalingFactor}.0) || windSpeed > (u_windspeed.y / ${maxWindSpeedScalingFactor}.0)) {
      alphaWindSpeed = 1. * step(1., depth); // Only show wind offshore
    }

    float depthAlpha = 1.0;
    if(texSample.r == .0 && texSample.g == .0 && texSample.b == .0) {
      depthAlpha = .0;
    } else if(depth >= u_depth.x && depth <= u_depth.y) {
      depthAlpha = .0;
    }
    if(depth < 0.) {
      depthAlpha = 0.0;
    }

    gl_FragColor = vec4(0.5, 0.5, 0.5, max(max(alphaShorelineDistance, depthAlpha), alphaWindSpeed));
  }`;

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader)
      throw scream("bathymetry: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("bathymetry: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS))
      throw scream("Failed to create program", {
        program: this.program,
        vertexShader,
        fragmentShader,
        programInfo: gl.getProgramInfoLog(this.program),
        vertexInfo: gl.getShaderInfoLog(vertexShader),
        fragmentInfo: gl.getShaderInfoLog(fragmentShader),
      });

    this.program.aPos = gl.getAttribLocation(this.program, "a_pos");

    const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);
  };

  shouldRerenderTiles = () => {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  };

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.map) return;
    const map = this.map;
    const urlFuncs = [
      this.urlFuncDepth,
      this.urlFuncShoreDistance,
      this.urlFuncGWA,
    ];
    const [depthTexture, shoreDistanceTexture, windSpeedTexture] = urlFuncs.map(
      (urlFunc) =>
        getBestAvailableTexture(
          gl,
          tileId,
          this.textureCache,
          map,
          urlFunc,
          this.maxZoom,
          this.tileSize,
          false,
          () => this.isSubmitted,
        ),
    );

    gl.useProgram(this.program);

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_depth"), this.depth);

    gl.uniform2fv(
      gl.getUniformLocation(this.program, "u_shorelineDistance"),
      this.shorelineDistance,
    );

    gl.uniform2fv(
      gl.getUniformLocation(this.program, "u_windspeed"),
      this.windSpeed,
    );

    gl.uniform4fv(
      gl.getUniformLocation(this.program, "u_uv_offsets"),
      depthTexture.uvOffsets,
    );

    // Set up the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, depthTexture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);

    // Set up the texture
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, shoreDistanceTexture.texture);
    gl.uniform1i(
      gl.getUniformLocation(this.program, "u_shoreline_distance_raster"),
      1,
    );

    // Set up the texture
    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, windSpeedTexture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_windspeed_raster"), 2);

    // Draw
    this.draw(gl, this.map);
  };

  draw(gl: WebGL2RenderingContext, map: MapboxMap | undefined) {
    if (!map) return;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.program.aPos);
    gl.vertexAttribPointer(this.program.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const Filter = () => {
  const lowerRightActiveMode = useAtomValue(lowerRightMenuActiveModeAtom);
  const enabled = lowerRightActiveMode === FilterMenuType;

  const projectId = useAtomValue(projectIdAtom) ?? "";

  const map = useAtomValue(mapAtom);

  const renderDepth = useAtomValue(renderDepthFilterAtom);
  const depth = useAtomValue(
    depthFilterAtomFamily({
      projectId,
    }),
  );
  const renderShore = useAtomValue(renderShoreDistanceAtom);
  const shoreDistance = useAtomValue(
    shoreDistanceFilterAtomFamily({
      projectId,
    }),
  );
  const renderWindSpeed = useAtomValue(renderWindSpeedAtom);
  const windSpeed = useAtomValue(
    windSpeedFilterAtomFamily({
      projectId,
    }),
  );
  const windLayerHeight = useAtomValue(windLayerHeightAtom);

  const depthLayer = useMemo(
    () => enabled && new TerrainLayer2(maxzoom, tileSize, windLayerHeight),
    [enabled, windLayerHeight],
  );

  useEffect(() => {
    if (!map || !enabled) return;
    if (depthLayer) {
      if (renderDepth) depthLayer.setDepth(depth);
      else depthLayer.setDepth([0, maxDepth]);

      if (renderShore) depthLayer.setShorelineDistance(shoreDistance);
      else depthLayer.setShorelineDistance([0, maxShoreline]);

      if (renderWindSpeed) depthLayer.setWindSpeed(windSpeed);
      else depthLayer.setWindSpeed([minWindSpeed, maxWindSpeed]);
      map.triggerRepaint();
    }
  }, [
    map,
    depth,
    shoreDistance,
    depthLayer,
    windSpeed,
    renderWindSpeed,
    renderDepth,
    renderShore,
    enabled,
  ]);

  useEffect(() => {
    if (!map) return;
    if (!depthLayer) return;

    addLayer(map, depthLayer);

    return () => {
      if (!map) return;
      map.removeLayer(depthLayer.id);
    };
  }, [map, depthLayer]);

  return null;
};

export default Filter;
