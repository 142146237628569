import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { useToast } from "hooks/useToast";
import { UserHintSettings, postUserHintSettings } from "./services";
import { userHintSettingsAtom } from "./state";

export const usePutUserHintSettings = () => {
  const { error: showError } = useToast();
  return useAtomCallback(
    useCallback(
      async (get, set, update: Partial<UserHintSettings>) => {
        const fallback = get(userHintSettingsAtom);
        set(userHintSettingsAtom, (curr) => ({ ...curr, ...update }));

        postUserHintSettings(update).catch(() => {
          showError("Failed to save hint settings, please try again.");
          set(userHintSettingsAtom, async () => fallback);
        });
      },
      [showError],
    ),
  );
};
