import { RegularRaw, TextRaw, typography } from "./typography";
import { spaceMedium, spaceTiny } from "./space";
import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
h1, h2, h3, h4, h5, p {
  ${RegularRaw}
  margin-top: ${spaceMedium};
  margin-bottom: ${spaceTiny};
}

h1 {
  ${typography.h1};
}

h2 {
  ${typography.h2};
}

h3 {
  ${typography.h3};
}

h4 {
  ${typography.h4};
}

h5 {
  font-size: 1.3rem;
  font-weight: 600;
}

p {
  ${TextRaw}
  margin: 0;
}

.graphics {
  font-size: 1rem;
  font-weight: 400;
}

progress {
  background: ${colors.indigo50};
  height: 12px;
  border-radius: 4px;
  border: 1px solid ${colors.borderSubtle};
  box-sizing: border-box;

  ::-moz-progress-bar {
    background-color: ${colors.indigo600};
    border-radius: 3px;
  }

  -webkit-appearance: none;
  ::-webkit-progress-bar {
    background-color: ${colors.indigo50};
  }
  ::-webkit-progress-value {
    background-color: ${colors.indigo600};
    border-radius: 3px;
  }
}

`;
