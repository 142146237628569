import { SubHeader } from "components/ConfigurationModal/shared";
import Dropdown from "components/Dropdown/Dropdown";
import { Grid2 } from "components/General/Form";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import { getValue } from "components/InputChangelog/utils";
import { Row } from "components/General/Layout";

const MONTHS: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const InstallationSeason = ({
  isReadOnly,
  startMonth,
  endMonth,
  category,
  nodeId,
}: {
  isReadOnly?: boolean;
  startMonth: number;
  endMonth: number;
  category: string;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;
  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Season start</SubHeader>
        <SubHeader>Season end</SubHeader>
        <ChangelogAndCommentWrapper
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.installationSeason.startMonth`,
            "project",
            (obj) => {
              const monthNumber = getValue(
                obj,
                `ti.${category}.installationSeason.startMonth`,
              );
              const month = MONTHS[monthNumber - 1];
              return { month: month };
            },
          )}
          disabled={isReadOnly}
        >
          <Dropdown
            style={{ width: "20rem" }}
            disabled={isReadOnly}
            value={startMonth}
            onChange={(e) => {
              const month = parseInt(e.target.value);
              updateTIVessels(
                {
                  installationSeason: { startMonth: month, endMonth },
                },
                category,
              );
            }}
          >
            {MONTHS.map((month, i) => {
              const monthNumber = i + 1;
              return (
                <option key={`start${month}`} value={monthNumber}>
                  {month}
                </option>
              );
            })}
          </Dropdown>
        </ChangelogAndCommentWrapper>
        <ChangelogAndCommentWrapper
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.installationSeason.endMonth`,
            "project",
            (obj) => {
              const monthNumber = getValue(
                obj,
                `ti.${category}.installationSeason.endMonth`,
              );
              const month = MONTHS[monthNumber - 1];
              return { month: month };
            },
          )}
          disabled={isReadOnly}
        >
          <Dropdown
            style={{ width: "20rem" }}
            disabled={isReadOnly}
            value={endMonth}
            onChange={(e) => {
              const month = parseInt(e.target.value);
              updateTIVessels(
                {
                  installationSeason: { startMonth, endMonth: month },
                },
                category,
              );
            }}
          >
            {MONTHS.map((month, i) => {
              const monthNumber = i + 1;
              return (
                <option key={`end${month}`} value={monthNumber}>
                  {month}
                </option>
              );
            })}
          </Dropdown>
        </ChangelogAndCommentWrapper>
      </Grid2>
    </Row>
  );
};

export default InstallationSeason;
