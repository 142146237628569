import { changeStyleColorKey } from "business/style/actions";
import {
  colorKeysFromFeature,
  labelFromColorKey,
} from "business/style/constants";
import { styleSelectorFamily } from "business/style/state";
import Dropdown from "components/Dropdown/Dropdown";
import { useAtomValue, useSetAtom } from "jotai";

export const ColorByDropdown = ({
  styleId,
  disabled,
}: {
  styleId: string;
  disabled?: boolean;
}) => {
  const style = useAtomValue(styleSelectorFamily(styleId));
  const changeKey = useSetAtom(changeStyleColorKey);

  if (!style) return null;
  return (
    <Dropdown
      small
      value={style.source}
      onChange={(e) => changeKey(style, e.target.value)}
      disabled={disabled}
    >
      {colorKeysFromFeature[style.feature].map((k) => (
        <option key={k} value={k}>
          {labelFromColorKey[k]}
        </option>
      ))}
    </Dropdown>
  );
};
