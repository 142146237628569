import { SearchGroup, SearchItem, SearchResultItem } from "../state";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row } from "components/General/Layout";
import { SearchInput } from "components/General/Input";
import { colors } from "styles/colors";
import CloseIcon from "@icons/24/Close.svg?react";
import { SVGWrapper } from "@icons/svgUtils";
import { SkeletonText } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import { Container, MainText } from "../style";
import { ListContainer } from "../style";
import { ElementRow } from "../style";
import { ElementTitle } from "../style";
import { inputStyle } from "../style";
import { DividerHeader } from "../style";
import { Divider } from "../style";
import Button from "components/General/Button";
import { addRecentSearch, getSearchResultType } from "../recentSearches";
import RecentSearchView from "../RecentSearchView";
import { useSearchAnalytics } from "../hooks/useSearchAnalytics";

const SearchBarInner = ({
  _ref,
  close,
  searchGroups,
  searchableItems,
  search,
  setSearch,
  page,
  someItemsAreLoading,
}: {
  _ref: React.RefObject<HTMLDivElement>;
  close: () => void;
  searchGroups: SearchGroup[];
  searchableItems: SearchItem<any>[];
  search: string;
  setSearch: (search: string) => void;
  page: "organisation" | "project";
  someItemsAreLoading?: boolean;
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isSecondarySelected, setIsSecondarySelected] = useState(false);

  const totalItems = useMemo(() => {
    return searchGroups.reduce((sum, group) => sum + group.items.length, 0);
  }, [searchGroups]);

  const {
    trackSearchClear,
    trackSearchSelection,
    trackRecentSearchSelection,
    trackSearchClose,
  } = useSearchAnalytics({
    page,
    search,
    totalItems,
    searchGroups,
    selectedIndex,
  });

  const getItemFromIndex = useCallback(
    (index: number) => {
      let currentIndex = index;
      for (const group of searchGroups) {
        if (currentIndex < group.items.length) return group.items[currentIndex];
        currentIndex -= group.items.length;
      }
      return null;
    },
    [searchGroups],
  );

  const handleSearchCloseWithoutSelecting = useCallback(() => {
    trackSearchClose();
    close();
  }, [trackSearchClose, close]);

  const handleSearchSelection = useCallback(
    (
      item: SearchResultItem<any> | SearchItem<any>,
      isSecondaryAction: boolean = false,
    ) => {
      const groupTitle = searchGroups.find((group) =>
        group.items.some((groupItem) => groupItem.id === item.id),
      )?.title;

      if (groupTitle) {
        addRecentSearch(item.id, getSearchResultType(groupTitle));
      }

      if (isSecondaryAction && item.secondarySelect) {
        item.secondarySelect.onSelect();
      } else {
        item.onSelect();
      }

      trackSearchSelection(item, isSecondaryAction);
      close();
    },
    [searchGroups, trackSearchSelection, close],
  );

  const handleSearchClear = useCallback(() => {
    trackSearchClear();
    setSearch("");
  }, [trackSearchClear, setSearch]);

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      const keys = [
        "ArrowDown",
        "ArrowUp",
        "Enter",
        "Escape",
        "ArrowRight",
        "ArrowLeft",
      ];
      if (keys.includes(event.key)) {
        const ignoreLeftRight =
          selectedIndex === -1 ||
          !getItemFromIndex(selectedIndex)?.secondarySelect;

        if (
          ignoreLeftRight &&
          (event.key === "ArrowLeft" || event.key === "ArrowRight")
        ) {
          return;
        }

        event.preventDefault();
        switch (event.key) {
          case "ArrowDown":
          case "ArrowUp":
            const direction = event.key === "ArrowDown" ? 1 : -1;
            let nextIndex =
              (selectedIndex + direction + totalItems) % totalItems;
            setSelectedIndex(nextIndex);
            break;
          case "ArrowRight": {
            const selectedItem = getItemFromIndex(selectedIndex);
            if (selectedItem?.secondarySelect) {
              setIsSecondarySelected(true);
            }
            break;
          }
          case "ArrowLeft":
            setIsSecondarySelected(false);
            break;
          case "Enter": {
            const selectedItem = getItemFromIndex(selectedIndex);
            if (selectedItem) {
              handleSearchSelection(selectedItem, isSecondarySelected);
            }
            break;
          }
          case "Escape":
            handleSearchCloseWithoutSelecting();
            break;
        }
      }
    },
    [
      selectedIndex,
      getItemFromIndex,
      totalItems,
      handleSearchCloseWithoutSelecting,
      handleSearchSelection,
      isSecondarySelected,
    ],
  );

  useEffect(() => {
    if (selectedIndex != null) {
      const selectedItem = getItemFromIndex(selectedIndex);
      if (selectedItem) {
        const setFocus = selectedItem.setFocus;
        if (setFocus) {
          setFocus(true);
          return () => setFocus(false);
        }
      }
    }
  }, [selectedIndex, totalItems, getItemFromIndex]);

  useEffect(() => {
    if (selectedIndex >= 0 && listContainerRef.current) {
      const selectedItem = listContainerRef.current.children[selectedIndex];
      if (selectedItem) {
        const timeout = setTimeout(() => {
          selectedItem.scrollIntoView({
            behavior: "auto",
            block: "center",
          });
        }, 20);
        return () => clearTimeout(timeout);
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);
    return () => window.removeEventListener("keydown", handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  const showListContainer = search.length > 0;
  const noResults =
    search.length > 0 &&
    !someItemsAreLoading &&
    searchGroups.every((group) => group.items.length === 0 && !group.loading);

  return (
    <Container ref={_ref}>
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <IconBtn size="1.2rem" onClick={handleSearchCloseWithoutSelecting}>
          <CloseIcon />
        </IconBtn>
      </div>
      <SearchInput
        autoFocus
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setSelectedIndex(0);
        }}
        onCancel={handleSearchCloseWithoutSelecting}
        onKeyDown={() => {}}
        onClear={handleSearchClear}
        placeholder={`Search`}
        style={inputStyle}
        onFocus={(e) => e.target.select()}
      />
      {search.length === 0 ? (
        <RecentSearchView
          searchableItems={searchableItems}
          closeSearch={close}
          trackRecentSearchSelection={trackRecentSearchSelection}
        />
      ) : (
        showListContainer && (
          <ListContainer role="list" ref={listContainerRef}>
            {searchGroups.map((group, groupIndex, allGroups) => {
              const startIndex = allGroups
                .slice(0, groupIndex)
                .reduce((sum, g) => sum + g.items.length, 0);

              return (
                <Fragment key={group.title}>
                  <Row style={{ alignItems: "center", gap: "0.8rem" }}>
                    <DividerHeader style={{ margin: 0 }}>
                      {group.title}
                    </DividerHeader>
                    <Divider />
                  </Row>
                  {group.loading
                    ? Array.from({ length: 5 }, (_, index) => (
                        <ElementRow
                          key={index}
                          role="listitem"
                          selected={false}
                        >
                          <SkeletonText text={`Searching ...`} />
                        </ElementRow>
                      ))
                    : group.items.map((item, itemIndex) => (
                        <ElementRow
                          key={item.id}
                          role="listitem"
                          selected={selectedIndex === startIndex + itemIndex}
                          onClick={() => handleSearchSelection(item, false)}
                        >
                          <SVGWrapper size={1.6}>{item.icon}</SVGWrapper>
                          {typeof item.name === "string" ? (
                            <MainText>{item.name}</MainText>
                          ) : (
                            item.name
                          )}
                          {item.secondarySelect && (
                            <Button
                              buttonType="secondary"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSearchSelection(item, true);
                              }}
                              text={item.secondarySelect.name}
                              style={{
                                marginLeft: "auto",
                                outline:
                                  selectedIndex === startIndex + itemIndex &&
                                  isSecondarySelected
                                    ? `2px solid ${colors.primary}`
                                    : undefined,
                              }}
                            />
                          )}
                        </ElementRow>
                      ))}
                </Fragment>
              );
            })}
            {someItemsAreLoading && (
              <ElementRow role="listitem" selected={false}>
                <SkeletonText text={`Searching ...`} />
              </ElementRow>
            )}

            {noResults && (
              <ElementRow role="listitem" selected={false}>
                <ElementTitle style={{ color: colors.secondaryText }}>
                  Try another search term
                </ElementTitle>
              </ElementRow>
            )}
          </ListContainer>
        )
      )}
    </Container>
  );
};
export default SearchBarInner;
