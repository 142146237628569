import { useAtomValue, useSetAtom } from "jotai";
import { mapAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import {
  externalWFSAnchorId,
  getBeforeLayer,
  getHiddenLargeCircleClickLayer,
  getHiddenLargeLineClickLayer,
} from "../../components/Mapbox/utils";
import { defaultMouseHandlerCallBackClickableFeature } from "../../state/selection";
import { mapInteractionSelector } from "../../state/map";
import useSelectionInMap from "../../hooks/useSelectionInMap";
import {
  Layer,
  ExternalDataSourceLinkLayerWithSourceTileJSON,
  LayerType,
} from "../../types/layers";
import { useLayoutFilter } from "../../hooks/map";
import { visibleTileJSONLayersAtom } from "../../state/layer";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { isTileJSONLayer } from "../../state/tileJSON";
import { LineLayerSpecification, MapMouseEvent } from "mapbox-gl";

const PROMOTE_ID_OVERRIDES = "osm_id";

const getHiddenClickLayer = (
  layerStyle:
    | mapboxgl.CircleLayerSpecification
    | mapboxgl.FillLayerSpecification
    | mapboxgl.LineLayerSpecification,
):
  | mapboxgl.CircleLayerSpecification
  | mapboxgl.LineLayerSpecification
  | undefined => {
  switch (layerStyle.type) {
    case "circle":
      return {
        ...getHiddenLargeCircleClickLayer(
          layerStyle.id,
          layerStyle.source! as string,
        ),
        "source-layer": layerStyle["source-layer"],
      };
    case "fill":
      break;
    case "line":
      return {
        ...getHiddenLargeLineClickLayer(
          layerStyle.id,
          layerStyle.source! as string,
        ),
        "source-layer": layerStyle["source-layer"],
      };
  }
};

const DynamicTileJSONLayers = ErrorBoundaryWrapper(
  () => {
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const visibleLayers = useAtomValue(
      getVisibleLayers({
        projectId,
      }),
    );

    const tileJsonLayers = visibleLayers.filter(isTileJSONLayer);
    const selectedDynamicTileJSONPolygonLayers = tileJsonLayers.filter(
      (layer) => layer.type === LayerType.Polygon,
    );

    const selectedDynamicTileJSONCircleLayers = tileJsonLayers.filter(
      (layer) => layer.type === LayerType.Point,
    );

    const selectedDynamicTileJSONLineLayers = tileJsonLayers.filter(
      (layer) => layer.type === LayerType.Line,
    );

    return (
      <>
        {selectedDynamicTileJSONLineLayers.map((layer) => (
          <DynamicTileJSONLineLayer key={layer.id} layer={layer} />
        ))}
        {selectedDynamicTileJSONCircleLayers.map((layer) => (
          <DynamicTileJSONCircleLayer key={layer.id} layer={layer} />
        ))}
        {selectedDynamicTileJSONPolygonLayers.map((layer) => (
          <DynamicTileJSONPolygonLayer key={layer.id} layer={layer} />
        ))}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const dynamicTileCircleToSourceId = (layer: Layer) => {
  return `dynamic-tile-json-circle-source-${layer.sourceLayerId}`;
};

export const dynamicTileCircleToLayerId = (internalId: string | number) =>
  `dynamic-tile-json-circle-layer-id-${internalId}`;

const DynamicTileJSONCircleLayer = ({
  layer,
}: {
  layer: ExternalDataSourceLinkLayerWithSourceTileJSON;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const layerSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );
  const dynamicTileJSONLayersId = useMemo(
    () => dynamicTileCircleToLayerId(layer.sourceLayerId),
    [layer],
  );

  const dynamicTileJSONLayersSource = useMemo(
    () => dynamicTileCircleToSourceId(layer),
    [layer],
  );

  const setVisibleTileJSONLayers = useSetAtom(visibleTileJSONLayersAtom);
  useEffect(() => {
    setVisibleTileJSONLayers((c) => ({
      ...c,
      circles: [...c.circles, layer.sourceLayerId],
    }));
    return () => {
      setVisibleTileJSONLayers((c) => ({
        ...c,
        circles: c.circles.filter((id) => id !== layer.sourceLayerId),
      }));
    };
  }, [layer.sourceLayerId, setVisibleTileJSONLayers]);

  const dynamicLayer = useMemo(
    () =>
      ({
        maxzoom: 20,
        minzoom: 2,
        type: "circle",
        "source-layer": layer.sourceLayerId,
        paint: {
          "circle-color": layerSettings.layerStyle?.color ?? "#27AE60",
          "circle-radius": 4,
          "circle-opacity": [
            "case",
            [
              "boolean",
              ["feature-state", "hover"],
              ["feature-state", "selected"],
              false,
            ],
            layerSettings.layerStyle?.opacity
              ? layerSettings.layerStyle?.opacity + 0.2
              : 1,
            layerSettings.layerStyle?.opacity ?? 0.6,
          ],
        },
        id: dynamicTileJSONLayersId,
        source: dynamicTileJSONLayersSource,
      }) as mapboxgl.CircleLayer,
    [
      dynamicTileJSONLayersId,
      layer.sourceLayerId,
      dynamicTileJSONLayersSource,
      layerSettings,
    ],
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const dynamicTilePolygonToSourceId = (layer: Layer) => {
  return `dynamic-tile-json-polygon-layer-source-${layer.sourceLayerId}`;
};

export const dynamicTilePolygonToLayerId = (name: string) => {
  return `dynamic-tile-json-polygon-layer-id-${name}`;
};

const DynamicTileJSONPolygonLayer = ({
  layer,
}: {
  layer: ExternalDataSourceLinkLayerWithSourceTileJSON;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const layerSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const setVisibleTileJSONLayers = useSetAtom(visibleTileJSONLayersAtom);
  useEffect(() => {
    setVisibleTileJSONLayers((c) => ({
      ...c,
      polygons: [...c.polygons, layer.sourceLayerId],
    }));
    return () => {
      setVisibleTileJSONLayers((c) => ({
        ...c,
        polygons: c.polygons.filter((id) => id !== layer.sourceLayerId),
      }));
    };
  }, [layer.sourceLayerId, setVisibleTileJSONLayers]);

  const dynamicTileJSONPolygonLayersId = useMemo(
    () => dynamicTilePolygonToLayerId(layer.sourceLayerId),
    [layer],
  );
  const dynamicTileJSONLayersSourceId = useMemo(
    () => dynamicTilePolygonToSourceId(layer),
    [layer],
  );

  const dynamicLayer = useMemo(
    () =>
      ({
        maxzoom: 20,
        minzoom: 2,
        id: dynamicTileJSONPolygonLayersId,
        type: "fill",
        source: dynamicTileJSONLayersSourceId,
        layout: {},
        "source-layer": layer.sourceLayerId,
        paint: {
          "fill-color": layerSettings.layerStyle?.color ?? "#77bb77",
          "fill-opacity": [
            "case",
            [
              "boolean",
              ["feature-state", "hover"],
              ["feature-state", "selected"],
              false,
            ],
            layerSettings.layerStyle?.opacity
              ? layerSettings.layerStyle?.opacity + 0.2
              : 1,
            layerSettings.layerStyle?.opacity ?? 0.4,
          ],
        },
      }) as mapboxgl.FillLayer,
    [
      layer,
      dynamicTileJSONPolygonLayersId,
      dynamicTileJSONLayersSourceId,
      layerSettings,
    ],
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const lineTileToSourceId = (layer: Layer) => {
  return `dynamic-tile-json-line-layer-source-${layer.sourceLayerId}`;
};

export const dynamicTileLineToLayerId = (internalId: string) => {
  return `dynamic-tile-json-line-layer-id-${internalId}`;
};

const DynamicTileJSONLineLayer = ({
  layer,
}: {
  layer: ExternalDataSourceLinkLayerWithSourceTileJSON;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const layerSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const setVisibleTileJSONLayers = useSetAtom(visibleTileJSONLayersAtom);
  useEffect(() => {
    setVisibleTileJSONLayers((c) => ({
      ...c,
      lines: [...c.lines, layer.sourceLayerId],
    }));
    return () => {
      setVisibleTileJSONLayers((c) => ({
        ...c,
        lines: c.lines.filter((id) => id !== layer.sourceLayerId),
      }));
    };
  }, [layer.sourceLayerId, setVisibleTileJSONLayers]);

  const dynamicTileJSONLineLayersId = useMemo(
    () => dynamicTileLineToLayerId(layer.sourceLayerId),
    [layer],
  );
  const dynamicTileJSONLayersSource = useMemo(
    () => lineTileToSourceId(layer),
    [layer],
  );

  const dynamicLayer: LineLayerSpecification = useMemo(
    () => ({
      maxzoom: 20,
      minzoom: 2,
      id: dynamicTileJSONLineLayersId,
      type: "line",
      source: dynamicTileJSONLayersSource,
      "source-layer": layer.sourceLayerId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": layerSettings.layerStyle?.color ?? "#000000",
        "line-width": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          5,
          5,
        ],
        "line-opacity": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          layerSettings.layerStyle?.opacity
            ? layerSettings.layerStyle?.opacity + 0.2
            : 1,
          layerSettings.layerStyle?.opacity ?? 0.4,
        ],
      },
    }),
    [
      dynamicTileJSONLineLayersId,
      layer,
      dynamicTileJSONLayersSource,
      layerSettings,
    ],
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const DynamicTileJSONLayer = ({
  layer,
  layerStyle,
}: {
  layer: ExternalDataSourceLinkLayerWithSourceTileJSON;
  layerStyle:
    | mapboxgl.CircleLayerSpecification
    | mapboxgl.FillLayerSpecification
    | mapboxgl.LineLayerSpecification;
}) => {
  const map = useAtomValue(mapAtom);
  const mapInteraction = useAtomValue(mapInteractionSelector);
  const setLayerMouseHandling = useSetAtom(
    defaultMouseHandlerCallBackClickableFeature,
  );
  const { onExternalLayerClick, externalLayerSelection } = useSelectionInMap();

  const layerStyleSource = layerStyle?.source as string;

  const filter = useLayoutFilter(layerStyleSource);

  useEffect(() => {
    if (!map || !map.getLayer(layerStyle.id)) return;
    map.setFilter(layerStyle.id, filter);
  }, [filter, map, layerStyle.id]);

  useEffect(() => {
    if (!map) return;
    map.addSource(layerStyleSource, {
      type: "vector",
      url: layer.sourceLink.url,
      promoteId: PROMOTE_ID_OVERRIDES,
    });
    map.addLayer(layerStyle, getBeforeLayer(map, externalWFSAnchorId));
    const hiddenClickLayer = getHiddenClickLayer(layerStyle);
    if (hiddenClickLayer) {
      map.addLayer(hiddenClickLayer, getBeforeLayer(map, externalWFSAnchorId));
    }
    return () => {
      map.removeLayer(layerStyle.id);
      if (hiddenClickLayer) {
        map.removeLayer(hiddenClickLayer.id);
      }
      map.removeSource(layerStyleSource);
    };
  }, [layer, map, layerStyle, layerStyleSource]);

  useEffect(() => {
    if (!map) return;

    const selection = externalLayerSelection.filter(
      (cs) => cs.layer?.source === layerStyle.id,
    );

    selection.forEach((s) => {
      if (!s.source || !s.id) return;
      map.setFeatureState(
        {
          source: s.source,
          id: s.id,
          sourceLayer: s.layer?.source,
        },
        {
          selected: true,
        },
      );
    });
    return () => {
      if (!map.getLayer(layerStyle.id)) return;
      selection.forEach((s) => {
        if (!s.id || !s.source) return;
        map.removeFeatureState(
          {
            source: s.source,
            id: s.id,
            sourceLayer: s.layer?.source,
          },
          "selected",
        );
      });
    };
  }, [externalLayerSelection, map, layerStyle, layer.name, layer.type]);

  useEffect(() => {
    if (!map || !map.getSource(layerStyleSource)) return;

    const onClick = (e: MapMouseEvent) => {
      e.preventDefault();
      if (!mapInteraction.hover) return;
      const { ctrlKey, metaKey, shiftKey, altKey } = e.originalEvent;
      const append = ctrlKey || metaKey || shiftKey || altKey;
      onExternalLayerClick(e.features ?? [], append);
    };

    const onMouseMove = (e: MapMouseEvent) => {
      e.preventDefault();
    };

    setLayerMouseHandling((l) => ({
      ...l,
      [layerStyle.id]: {
        onClick: onClick,
        onMouseMove: onMouseMove,
      },
    }));

    return () => {
      setLayerMouseHandling((l) => {
        const cleanedL = {
          ...l,
        };
        delete cleanedL[layerStyle.id];
        return cleanedL;
      });
    };
  }, [
    map,
    mapInteraction,
    layerStyle,
    layer,
    setLayerMouseHandling,
    onExternalLayerClick,
    layerStyleSource,
  ]);

  return null;
};

export default DynamicTileJSONLayers;
