import { projectIdAtom } from "state/pathParams";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { SetterOrUpdater } from "types/utils";
import mapboxgl from "mapbox-gl";
import useNavigateToPark from "hooks/useNavigateToPark";
import { useGoToFeatures } from "hooks/map";
import { mapAtom } from "state/map";
import useDownloadFeatures from "hooks/useDownloadFeaturesAsZipped";
import useToggleFeaturesHidden from "hooks/useToggleFeaturesHidden";
import useSelectionInMap from "hooks/useSelectionInMap";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { ProjectFeature } from "types/feature";
import { editorAccessProjectSelector } from "state/user";
import { dedup } from "utils/utils";
import { ParkFeature } from "types/feature";
import { useProjectElementsFoldersCrud } from "./useProjectElementsFoldersCrud";
import { resetListIfNotAlreadyEmpty } from "utils/resetList";
import { inReadOnlyModeSelector } from "state/project";
import { useProjectElementsSortOrder } from "./useProjectElementsSortOrder";
import { ElementTreeRoot } from "./types";
import { branchIdAtom } from "state/pathParams";
import { elementTreeSelectorFamily } from "./state";
import { elementTreeFindAll } from "./utils";
import { useToast } from "hooks/useToast";
import { useAtomValue } from "jotai";
import { featuresListAtom } from "state/jotai/features";
import { parksFamily } from "state/jotai/park";
import { selectedOnlyFeatureAtom } from "state/jotai/selection";

export type ProjectElementsContextType = {
  map?: mapboxgl.Map;
  branchId?: string;
  editorAccessProject: boolean;
  isReadOnly: boolean;
  projectFeatures: ProjectFeature[];
  selectedParks: ParkFeature[];
  mapSelectedId?: string;
  navigateToPark: ReturnType<typeof useNavigateToPark>["navigateToPark"];
  updateFeatures: ReturnType<typeof useProjectElementsCrud>["update"];
  goToFeatures: (features: ProjectFeature[]) => void;
  isDownloading: Record<string, boolean>;
  downloadMultipleFeaturesShapeUsingId: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesShapeUsingId"];
  downloadMultipleFeaturesGeojsonUsingId: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesGeojsonUsingId"];
  downloadMultipleFeaturesKMLUsingId: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesKMLUsingId"];
  downloadMultipleFeaturesDXFUsingId: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesDXFUsingId"];
  downloadMultipleFeaturesCSVUsingId: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesCSVUsingId"];
  downloadMultipleFeaturesShape: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesShape"];
  downloadMultipleFeaturesGeojson: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesGeojson"];
  downloadMultipleFeaturesKML: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesKML"];
  downloadMultipleFeaturesDXF: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesDXF"];
  downloadMultipleFeaturesCSV: ReturnType<
    typeof useDownloadFeatures
  >["downloadMultipleFeaturesCSV"];
  getAreAllFeaturesVisible: ReturnType<
    typeof useToggleFeaturesHidden
  >["getAreAllFeaturesVisible"];
  toggleFeaturesHidden: ReturnType<
    typeof useToggleFeaturesHidden
  >["toggleFeaturesHidden"];
  createProjectElementsFolder: ReturnType<
    typeof useProjectElementsFoldersCrud
  >["create"];
  updateProjectElementsFolder: ReturnType<
    typeof useProjectElementsFoldersCrud
  >["update"];
  removeProjectElementsFolder: ReturnType<
    typeof useProjectElementsFoldersCrud
  >["remove"];
  currentSelectionArray: string[];
  currentSelectionMap: Map<string, boolean>;
  setCurrentSelectionArray: SetterOrUpdater<string[]>;
  reorderTopLevel: (
    ids: {
      type: "feature" | "folder";
      id: string;
    }[],
    index: number,
  ) => void;
  toggleFeaturesSelected(featureIds: string[]): void;
  shiftSelectFeatures(featureId: string): void;
  deselectAllFeatures(): void;
  onOpenedChange(id: string, isOpen: boolean): void;
  getIsOpened(id: string): boolean;
  /** Root node of the Elements tree. */
  tree: ElementTreeRoot;
};

const ProjectElementsContext = createContext<
  undefined | ProjectElementsContextType
>(undefined);

export const useProjectElementsContext = () => {
  const context = useContext(ProjectElementsContext);
  if (!context) {
    throw new Error("Context not found");
  }

  return context;
};

export const ProjectElementsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const map = useAtomValue(mapAtom);
  const branchId = useAtomValue(branchIdAtom);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const projectFeatures = useAtomValue(featuresListAtom);
  const parkFeatures = useAtomValue(
    parksFamily({
      branchId,
    }),
  );
  const mapSelectedId = useAtomValue(selectedOnlyFeatureAtom)?.id;
  const { update: updateFeatures } = useProjectElementsCrud();
  const { navigateToPark } = useNavigateToPark();
  const goToFeatures = useGoToFeatures(map);
  const projectElementsGoToFeature = useCallback(
    (features: ProjectFeature[]) => goToFeatures(features),
    [goToFeatures],
  );
  const [openedIds, setOpenedIds] = useState<string[]>([]);
  const {
    isLoading: isDownloading,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    downloadMultipleFeaturesDXF,
    downloadMultipleFeaturesCSV,
  } = useDownloadFeatures();
  const {
    create: createProjectElementsFolder,
    update: updateProjectElementsFolder,
    remove: removeProjectElementsFolder,
  } = useProjectElementsFoldersCrud();
  const { getAreAllFeaturesVisible, toggleFeaturesHidden } =
    useToggleFeaturesHidden();
  const { currentSelectionArray, setCurrentSelectionArray } =
    useSelectionInMap();
  const { sortProjectElements } = useProjectElementsSortOrder();

  const currentSelectionMap = useMemo(
    () => new Map(currentSelectionArray.map((id) => [id, true])),
    [currentSelectionArray],
  );

  const projectId = useAtomValue(projectIdAtom);
  const tree = useAtomValue(
    elementTreeSelectorFamily({
      nodeId: projectId ?? "",
      branchId: branchId ?? "",
    }),
  );

  /**
   * Splice in the nodes at the top level at the given index.
   */
  const reorderTopLevel = useCallback(
    (
      newItems: {
        type: "feature" | "folder";
        id: string;
      }[],
      index: number,
    ) => {
      const ids = new Set(newItems.map((n) => n.id));
      const items = tree.children.map((item) =>
        "folder" in item
          ? {
              id: item.folder.folderId,
              type: "folder" as const,
            }
          : {
              id: item.id,
              type: "feature" as const,
            },
      );

      const itemsWithoutNew = items.filter((e) => !ids.has(e.id));

      // If some elements move forwards and some backwards in the ordering, we
      // need to adjust the `index` so that we appear in the right place.
      // Subtract the number of elements before the index to adjust.
      const itemsBefore = items
        .slice(0, index)
        .filter(({ id }) => ids.has(id)).length;
      const shiftedIndex = index - itemsBefore;

      const newOrder = itemsWithoutNew
        .slice(0, shiftedIndex)
        .concat(newItems)
        .concat(itemsWithoutNew.slice(shiftedIndex));

      sortProjectElements(
        newOrder.map((item, index) => ({
          ...item,
          sortOrder: index,
        })),
      );
    },
    [sortProjectElements, tree.children],
  );

  const selectedParks = useMemo(() => {
    return parkFeatures.filter((feature) =>
      currentSelectionMap.has(feature.id),
    );
  }, [parkFeatures, currentSelectionMap]);

  const toggleFeaturesSelected = useCallback(
    (featureIds: string[]) => {
      setCurrentSelectionArray((selectedIds) => {
        const allIsSelected = featureIds.every((featureId) =>
          selectedIds.includes(featureId),
        );
        if (allIsSelected) {
          return selectedIds.filter((id) => !featureIds.includes(id));
        }
        return dedup([...selectedIds, ...featureIds]);
      });
    },
    [setCurrentSelectionArray],
  );

  const deselectAllFeatures = useCallback(() => {
    setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
  }, [setCurrentSelectionArray]);

  const { warning } = useToast();

  const shiftSelectFeatures = useCallback(
    (clickedFeatureId: string) => {
      const ids = new Set([...currentSelectionArray, clickedFeatureId]);
      const nodes = elementTreeFindAll(tree, (n) => ids.has(n.id));
      const first = nodes.at(0);
      if (!first) return;
      const last = nodes.at(-1)!; // Safety: if we have first, we have last.

      if (first.parent !== last.parent) {
        warning("Cannot multi-select from different folders.");
        return;
      }

      const p = first.parent;
      const selectFrom = p.children.findIndex((n) => n.id === first.id);
      const selectTo = p.children.findIndex((n) => n.id === last.id);
      const selectNodes = p.children.slice(selectFrom, selectTo + 1);
      setCurrentSelectionArray(selectNodes.map((n) => n.id));
    },
    [currentSelectionArray, setCurrentSelectionArray, tree, warning],
  );

  const onOpenedChange = useCallback((id: string, isOpened: boolean) => {
    if (isOpened) {
      setOpenedIds((prev) => [...prev, id]);
    } else {
      setOpenedIds((prev) => prev.filter((_id) => _id !== id));
    }
  }, []);

  const getIsOpened = useCallback(
    (id: string) => {
      return openedIds.includes(id);
    },
    [openedIds],
  );

  const value: ProjectElementsContextType = {
    map,
    branchId,
    selectedParks,
    editorAccessProject,
    isReadOnly,
    projectFeatures,
    mapSelectedId,
    navigateToPark,
    updateFeatures,
    goToFeatures: projectElementsGoToFeature,
    isDownloading,
    downloadMultipleFeaturesShapeUsingId,
    downloadMultipleFeaturesGeojsonUsingId,
    downloadMultipleFeaturesKMLUsingId,
    downloadMultipleFeaturesDXFUsingId,
    downloadMultipleFeaturesCSVUsingId,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    downloadMultipleFeaturesDXF,
    downloadMultipleFeaturesCSV,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    updateProjectElementsFolder,
    createProjectElementsFolder,
    removeProjectElementsFolder,
    reorderTopLevel,
    currentSelectionArray,
    setCurrentSelectionArray,
    currentSelectionMap,
    toggleFeaturesSelected,
    deselectAllFeatures,
    shiftSelectFeatures,
    onOpenedChange,
    getIsOpened,
    tree,
  };

  return (
    <ProjectElementsContext.Provider value={value}>
      {children}
    </ProjectElementsContext.Provider>
  );
};
