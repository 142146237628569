import { useCallback } from "react";
import { MenuItem } from "../../../General/Menu";
import { useAtomValue, useSetAtom } from "jotai";
import TagsIcon from "@icons/24/Tags.svg?react";
import { tagsFamily } from "state/jotai/tags";
import CheckIcon from "@icons/24/Check.svg?react";
import SettingsIcon from "@icons/24/Settings.svg?react";
import { OrgSettingsModalType } from "components/Organisation/OrgSettings/OrgSettingsModal";
import { modalTypeOpenAtom } from "state/modal";
import { activeOrgSettingsTabAtom } from "components/Organisation/OrgSettings/ActiveTabState";

interface SelectTagsProps {
  organisationId: string;
  selectedTags: string[];
  onTagsChange: (tags: string[]) => void;
  isAdminInOrg: boolean;
}

const SelectTags = ({
  organisationId,
  selectedTags,
  onTagsChange,
  isAdminInOrg,
}: SelectTagsProps) => {
  const availableTags = useAtomValue(tagsFamily({ organisationId }));
  const setModalOpen = useSetAtom(modalTypeOpenAtom);
  const setActiveTab = useSetAtom(activeOrgSettingsTabAtom);

  const handleTagClick = useCallback(
    (tagId: string) => {
      if (selectedTags.includes(tagId)) {
        onTagsChange(selectedTags.filter((id) => id !== tagId));
      } else {
        onTagsChange([...selectedTags, tagId]);
      }
    },
    [selectedTags, onTagsChange],
  );

  const disabledAndNoTags = !isAdminInOrg && availableTags.length === 0;

  return (
    <MenuItem
      name="Tags"
      icon={<TagsIcon />}
      tooltipStyle={disabledAndNoTags ? { width: "100%" } : {}}
      style={disabledAndNoTags ? { width: "100%" } : {}}
      disabled={disabledAndNoTags}
      tooltip={
        disabledAndNoTags
          ? "No tags yet, contact organisation admin to add tags"
          : undefined
      }
    >
      {availableTags.map((tag) => (
        <MenuItem
          key={tag.id}
          name={tag.name}
          icon={selectedTags.includes(tag.id) ? <CheckIcon /> : undefined}
          stopPropagationOnClick={true}
          onClick={() => handleTagClick(tag.id)}
        />
      ))}

      <MenuItem
        tooltip={
          !isAdminInOrg ? "Contact organisation admin to edit tags" : undefined
        }
        name={
          availableTags.length === 0 ? "No tags yet, create new" : "Edit tags"
        }
        disabled={!isAdminInOrg}
        icon={<SettingsIcon />}
        onClick={() => {
          setModalOpen({ modalType: OrgSettingsModalType });
          setActiveTab("tags");
        }}
        style={!isAdminInOrg ? { width: "100%" } : {}}
        tooltipStyle={!isAdminInOrg ? { width: "100%" } : {}}
      />
    </MenuItem>
  );
};

export default SelectTags;
