import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { dateToYearDateTime } from "utils/utils";
import styled from "styled-components";
import { colors } from "styles/colors";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";
import { Suspense, useMemo } from "react";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { SkeletonText } from "components/Loading/Skeleton";
import { Column, Row } from "components/General/Layout";
import UserImageRound from "components/UserImage/UserImageRound";
import { usersInOrganisationState } from "components/Organisation/state";
import { typography } from "styles/typography";
import { ChangelogCategory } from "components/InputChangelog/types";
import { useAtomValue } from "jotai";
import { getProjectLibraryChangesAtom } from "components/NotificationSystem/state";

const LastEditerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  color: ${colors.textPrimary};
  ${typography.caption}
`;

const Name = styled.p`
  ${typography.body}
  align-self: flex-start;
  text-transform: capitalize;
`;

const NameBold = styled.p`
  ${typography.sub2}
  align-self: flex-start;
  text-transform: capitalize;
  font-weight: 600;
`;

const SecondaryText = styled.p`
  color: ${colors.textSecondary};
  ${typography.graphics}
`;

const DateText = styled.p`
  ${typography.caption}
`;

const ComponentLastChanged = ({
  changelogId,
  nodeId,
  category,
  resourceId,
}: {
  changelogId: string;
  nodeId: string;
  category: ChangelogCategory;
  resourceId?: string;
}) => {
  return (
    <Suspense
      fallback={
        <SkeletonText
          style={{
            height: "1.6rem",
            width: "15rem",
          }}
        />
      }
    >
      <ComponentLastChangedInner
        changelogId={changelogId}
        nodeId={nodeId}
        category={category}
        resourceId={resourceId ?? ""}
      />
    </Suspense>
  );
};

const ComponentLastChangedInner = ErrorBoundaryWrapper(
  ({
    nodeId,
    changelogId,
    category,
    resourceId,
  }: {
    nodeId: string;
    changelogId: string;
    category: ChangelogCategory;
    resourceId: string;
  }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const changes = useAtomValue(
      inputChangelogsAtomFamily({
        nodeId,
        changelogId,
        category,
        organisationId,
      }),
    );
    const change = changes[0];

    const projectId = useAtomValue(projectIdAtom);
    let latestResourceChange;
    if (projectId) {
      const projectLibraryChanges = useAtomValue(getProjectLibraryChangesAtom);
      latestResourceChange = useMemo(() => {
        const relevantChanges = projectLibraryChanges
          .filter((change) => change.resourceId === resourceId)
          .sort((a, b) => b.lastModified - a.lastModified);

        return relevantChanges[0];
      }, [projectLibraryChanges, resourceId]);
    }

    if (change)
      return (
        <LastEditerWrapper>
          Last edited: {dateToYearDateTime(new Date(change.version))}
          <UserInfoOrganisation userId={change.author} size={2} />
        </LastEditerWrapper>
      );

    if (latestResourceChange)
      // Fetch changes for a library resource in projects
      return (
        <LastEditerWrapper>
          Last edited:{" "}
          {dateToYearDateTime(new Date(latestResourceChange.lastModified))}
          <UserInfoOrganisation
            userId={latestResourceChange.lastModifiedBy}
            size={2}
          />
        </LastEditerWrapper>
      );
    if (!change && !latestResourceChange)
      return (
        <LastEditerWrapper>
          Last edited: No last changes found
        </LastEditerWrapper>
      );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export const ComponentLastChangedSimple = ({
  changelogId,
  category,
  showInTitleBar,
}: {
  changelogId: string;
  category: ChangelogCategory;
  showInTitleBar?: boolean;
}) => {
  return (
    <Suspense
      fallback={<SkeletonText style={{ height: "1.6rem", width: "15rem" }} />}
    >
      <ComponentLastChangedSimpleInner
        changelogId={changelogId}
        category={category}
        showInTitleBar={showInTitleBar}
      />
    </Suspense>
  );
};

const ComponentLastChangedSimpleInner = ErrorBoundaryWrapper(
  ({
    changelogId,
    category,
    showInTitleBar,
  }: {
    changelogId: string;
    category: ChangelogCategory;
    showInTitleBar?: boolean;
  }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const users = useAtomValue(usersInOrganisationState(organisationId));

    const changes = useAtomValue(
      inputChangelogsAtomFamily({
        nodeId: organisationId,
        changelogId,
        category,
        organisationId,
      }),
    );

    const change = changes[0];
    const author = change?.author;

    if (author && change?.version) {
      const user = users?.find((cm) => cm.user_id === author);

      return user ? (
        <LastEditerWrapper>
          <UserImageRound size={3.2} user={user} />
          <Column
            style={{
              gap: 0,
            }}
          >
            <Row alignCenter>
              {showInTitleBar ? (
                <>
                  <SecondaryText>Last edited:</SecondaryText>
                  <NameBold>{user.nickname}</NameBold>
                </>
              ) : (
                <Name>{user.nickname}</Name>
              )}
            </Row>
            <DateText>{dateToYearDateTime(new Date(change.version))}</DateText>
          </Column>
        </LastEditerWrapper>
      ) : (
        !showInTitleBar && "--"
      );
    } else if (!showInTitleBar) {
      return "--";
    }

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default ComponentLastChanged;
