import { useAtomValue } from "jotai";
import DownloadIcon from "@icons/24/Download.svg?react";
import Plotly, { Layout } from "plotly.js-dist-min";
import { useCallback, useEffect, useRef, useState } from "react";
import { colors } from "styles/colors";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import {
  getInstallationTypesWithFeaturesInPark,
  getInstallationSCurveData,
} from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { capitalize, downloadText } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  getInstallationTimeStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { FinanceId } from "finance/types";
import { Label } from "components/General/Form";
import { spaceMedium } from "styles/space";
import Checkbox from "components/General/Checkbox";
import { isDefined } from "utils/predicates";
import { Row } from "components/General/Layout";
import { DateTime } from "luxon";
import { useToast } from "hooks/useToast";
import { MenuItem } from "components/General/Menu";
import { stringify } from "csv-stringify/sync";

const InstallationCategoryCheckbox = ({
  triggerId,
  selectedTypes,
  setSelectedTypes,
}: {
  triggerId: FinanceId;
  selectedTypes: InstallationType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<InstallationType[]>>;
}) => {
  const installationTypes = useAtomValue(
    getInstallationTypesWithFeaturesInPark({ id: triggerId }),
  );

  const handleCheckboxChange = (type: InstallationType) => {
    setSelectedTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type],
    );
  };

  return (
    <Label style={{ padding: spaceMedium }}>
      <Row style={{ gap: spaceMedium }}>
        {installationTypes.map((type) => (
          <div key={type} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={selectedTypes.includes(type)}
              label={capitalize(type)}
              onChange={() => handleCheckboxChange(type)}
              labelPlacement="after"
              disabled={
                selectedTypes.length === 1 && selectedTypes.includes(type)
              }
            />
          </div>
        ))}
      </Row>
    </Label>
  );
};

const InstallationSCurveChart = ({
  selectedTypes,
  setSelectedTypes,
}: {
  selectedTypes: InstallationType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<InstallationType[]>>;
}) => {
  const { triggerId } = useDashboardContext();

  const installationStoppedReason = useAtomValue(
    getInstallationTimeStoppedReason(triggerId),
  );

  const installationTypes = useAtomValue(
    getInstallationTypesWithFeaturesInPark({ id: triggerId }),
  );

  useEffect(() => {
    if (installationTypes.length > 0) {
      setSelectedTypes([installationTypes[0]]);
    }
  }, [installationTypes, setSelectedTypes]);

  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <>
      <InstallationCategoryCheckbox
        triggerId={triggerId}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
      />
      <InstallationSCurveChartInner selectedTypes={selectedTypes} />
    </>
  );
};

const InstallationSCurveChartInner = ({
  selectedTypes,
}: {
  selectedTypes: InstallationType[];
}) => {
  const { triggerId } = useDashboardContext();
  const allData = useAtomValue(getInstallationSCurveData({ id: triggerId }));

  const installationData = selectedTypes
    .map((type) => {
      const data = allData[type];
      if (!data) return undefined;
      return { type, ...data };
    })
    .filter(isDefined);

  if (installationData.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={`Installation analysis has failed, please check your input data.`}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return <InstallationSCurvePlot installationData={installationData} />;
};

const InstallationSCurvePlot = ({
  installationData,
}: {
  installationData: {
    type: InstallationType;
    completionSequence: number[];
    totalTimeSequence: DateTime[];
  }[];
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const colorMap: Record<InstallationType, string> = {
      [InstallationType.Turbine]: colors.blue500,
      [InstallationType.Cable]: colors.green500,
      [InstallationType.Mooring]: colors.orange500,
      [InstallationType.Substation]: colors.purple500,
      [InstallationType.ExportCable]: colors.yellow500,
      [InstallationType.Floater]: colors.seagreen500,
      [InstallationType.Monopile]: colors.red500,
      [InstallationType.Jacket]: colors.grey500,
      [InstallationType.ScourProtection]: colors.blue500,
    };

    const data = installationData.map(
      ({ type, completionSequence, totalTimeSequence }) => {
        const x = totalTimeSequence.map((date) => date.toISODate());
        const y = completionSequence;

        return {
          name: capitalize(type),
          y,
          x,
          line: {
            shape: "vh" as const,
            color: colorMap[type] || colors.black,
          },
          hovertemplate:
            "On %{x},<br>%{y:,.1f}% of the installation activities are completed",
          mode: "lines" as const,
          type: "scatter" as const,
        };
      },
    );

    const layout: Partial<Layout> = {
      hovermode: "closest",
      legend: {
        x: 0.02,
        y: 1.1,
        orientation: "h",
      },
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 250,
      bargap: 0.05,
      xaxis: {
        title: "Date",
        type: "date" as const,
      },
      yaxis: {
        title: "Completion [%]",
        range: [0, 101],
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, installationData]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const InstallationSCurveMenu = ({
  selectedTypes,
}: {
  selectedTypes: InstallationType[];
}) => {
  const { triggerId } = useDashboardContext();
  const { warning } = useToast();
  const allData = useAtomValue(getInstallationSCurveData({ id: triggerId }));

  const download = useCallback(() => {
    if (!allData) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const header = ["Type", "Date", "Completion [%]"];
    const rows: string[][] = [];

    selectedTypes.forEach((type) => {
      const data = allData[type];
      if (!data) return;

      const { completionSequence, totalTimeSequence } = data;
      completionSequence.forEach((completion, index) => {
        rows.push([
          capitalize(type),
          totalTimeSequence[index].toISODate() || "",
          completion.toFixed(1),
        ]);
      });
    });

    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "installation_s_curve.csv");
  }, [allData, warning, selectedTypes]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const InstallationSCurveWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();
  const [selectedTypes, setSelectedTypes] = useState<InstallationType[]>([
    InstallationType.Turbine,
  ]);

  return (
    <SafeCard
      title="Installation S curve"
      id="Installation S curve"
      menuItems={<InstallationSCurveMenu selectedTypes={selectedTypes} />}
      resetKeys={errorBoundaryResetKeys}
    >
      <InstallationSCurveChart
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
      />
    </SafeCard>
  );
};
