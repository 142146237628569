import { useAtomValue } from "jotai";
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { TextRaw } from "styles/typography";
import { spacing3, spacing4 } from "styles/space";
import { inReadOnlyModeSelector } from "state/project";
import { Anchor, Place, oppositePlace } from "./Anchor";

const animationTime = 0.2;

const TooltipContainer = styled.div<{
  show: boolean;
  maxWidth?: string;
  theme: "dark" | "light";
}>`
  z-index: 10;
  max-width: ${(p) => p.maxWidth ?? "25rem"};
  width: fit-content;
  padding: ${spacing3} ${spacing4};
  border-radius: 0.4rem;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => {
    if (theme === "dark") {
      return `
        background-color: ${colors.blue900};
      `;
    }

    if (theme === "light") {
      return `
        background-color: ${colors.blue100};
      `;
    }
  }}

  white-space: pre-line;
  word-wrap: anywhere; // Long identifiers without spaces needs to wrap.

  opacity: ${(p) => (p.show ? 1 : 0)};
  -webkit-transition: opacity ${animationTime}s ease-in-out;
  -moz-transition: opacity ${animationTime}s ease-in-out;
  transition: opacity ${animationTime}s ease-in-out;
`;

export const TooltipText = styled.p<{
  secondary: boolean;
  theme: "dark" | "light";
}>`
  ${TextRaw};
  margin: 0;
  ${(p) => p.secondary && "font-size: 1.2rem;"}

  ${({ theme }) => {
    if (theme === "dark") {
      return `
        color: ${colors.lightText};
      `;
    }

    if (theme === "light") {
      return `
        color: ${colors.grey900};
      `;
    }
  }}
`;

// NOTE: This is a stupid hack to make the transition opacity work without
// needing to mount the tooltips when they're not visible.
const TooltipContainer2 = ({
  show,
  maxWidth,
  children,
  theme,
  onMouseEnter,
  onMouseLeave,
}: React.PropsWithChildren<{
  show: boolean;
  maxWidth?: string;
  theme: "dark" | "light";
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}>) => {
  const [delayedShow, setDelayedShow] = useState(false);
  useEffect(() => {
    setDelayedShow(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return (
    <TooltipContainer
      show={delayedShow}
      maxWidth={maxWidth}
      theme={theme}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </TooltipContainer>
  );
};

const ShortcutText = styled.p`
  ${TextRaw};
  margin: 0;
  color: white;
  width: max-content;
  padding: 0.2rem 0.4rem;
  background-color: ${colors.secondaryText};
  border-radius: 0.4rem;
`;

const TooltipInner = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

/**
 * A "HOC"-ish component that clones the wrapped child and adds onMouseEnter, onMouseLeave etc to the child
 * to show a tooltip when hovering the child, without rendering any wrapping elements.
 * The child must be wrapped with forwardRef if its a functional component,
 * and it must forward some events to the underlying element:
 * onMouseEnter,
 * onMouseLeave,
 * onPointerLeave
 * Example:
 * ```tsx
 * const MyComponent = forwardRef(({prop1, onMouseEnter, onMouseLeave, onPointerLeave}, ref) => {
 *  return <SomeChildComponent
 *    ref={ref}
 *    prop1={prop1}
 *    onMouseEnter={onMouseEnter}
 *    onMouseLeave={onMouseLeave}
 *    onPointerLeave={onPointerLeave}>
 *    Hover me!
 *  </SomeChildComponent>
 *  });
 *
 *  <WithTooltip text="Hello">
 *    <MyComponent />
 *  </WithTooltip>
 *  ```
 */
export const WithTooltip = ({
  id,
  children,
  text,
  content,
  shortcut,
  theme = "dark",
  position = "top",
  secondary = false,
  delay = 500,
  closeDelay = 0,
  disabled = false,
  offset,
  floatPlace,
  maxWidth,
  revealWithQKey,
  readonlyAware,
  interactive,
  onlyOnOverflow,
}: {
  id?: string;
  children: ReactNode;
  text: string | string[];
  content?: React.ReactNode;
  shortcut?: string;
  theme?: "dark" | "light";
  position?: Place;
  offset?: [string | number, string | number];
  secondary?: boolean;
  floatPlace?: Place;
  maxWidth?: string;
  revealWithQKey?: boolean;
  readonlyAware?: boolean;
  delay?: number;
  closeDelay?: number;
  disabled?: boolean;

  /**
   * Only show the tooltip if the content of the child is overflowing.
   * This is useful when you want to show a tooltip only when the content is too long to fit in the container.
   * Only works if the immediate child is overflowing.
   */
  onlyOnOverflow?: boolean;

  /**
   * If true, the tooltip will not hide when the user mouseOvers it
   */
  interactive?: boolean;
}) => {
  const timeout = useRef<NodeJS.Timeout>();
  const closeTimeout = useRef<NodeJS.Timeout>();
  const [show, setShow] = useState(false);
  const [showingWithKey, setShowingWithKey] = useState(false);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const ref = useRef<HTMLDivElement>(null);

  const _onEnter = useCallback(() => {
    clearTimeout(closeTimeout.current);
    timeout.current = setTimeout(() => {
      setShow(true);
    }, delay);
  }, [delay]);

  const setDelayedCloseTimeout = useCallback(() => {
    clearTimeout(closeTimeout.current);
    closeTimeout.current = setTimeout(() => {
      setShow(false);
      clearTimeout(timeout.current);
    }, closeDelay);
  }, [closeDelay]);

  const _onLeave = useCallback(() => {
    if (closeDelay && show) {
      setDelayedCloseTimeout();
      return;
    }
    clearTimeout(timeout.current);
    setShow(false);
  }, [closeDelay, setDelayedCloseTimeout, show]);

  // Remove the tooltip on mouseDown
  // This will prevent the tooltip to still be there when for instance mousedowning to drag an element
  useEffect(() => {
    if (!show || interactive) {
      return;
    }

    document.addEventListener("mousedown", _onLeave);
    return () => {
      document.removeEventListener("mousedown", _onLeave);
    };
  }, [_onLeave, interactive, show]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      clearTimeout(closeTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (!revealWithQKey) return;
    const show = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(true);
    };
    const hide = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(false);
    };

    window.addEventListener("keydown", show);
    window.addEventListener("keyup", hide);

    return () => {
      window.removeEventListener("keydown", show);
      window.removeEventListener("keyup", hide);
    };
  }, [revealWithQKey, setShowingWithKey]);

  const isOverflowing =
    ref.current && ref.current.scrollWidth > ref.current.clientWidth;

  const shouldShow =
    (showingWithKey || show) && (!onlyOnOverflow || isOverflowing);

  return React.Children.map(children, (child) => {
    if (!React.isValidElement<any>(child)) {
      return child;
    }

    return React.cloneElement(
      child,
      {
        id: id ?? child.props.id,
        ref,
        onMouseEnter: _onEnter,
        onMouseLeave: _onLeave,
        onPointerLeave: _onLeave,
      },
      <>
        {child.props.children}
        {shouldShow && !disabled && (
          <Anchor
            basePlace={position}
            floatPlace={floatPlace ?? oppositePlace(position)}
            update={shouldShow}
            baseRef={ref}
            offset={offset}
          >
            <TooltipContainer2
              show={shouldShow}
              maxWidth={maxWidth}
              theme={theme}
              onMouseEnter={
                interactive
                  ? () => {
                      clearTimeout(closeTimeout.current);
                    }
                  : _onLeave
              }
              onMouseLeave={interactive ? setDelayedCloseTimeout : undefined}
            >
              {content ? (
                <div>{content}</div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {readonlyAware && isReadOnly ? (
                    <TooltipText secondary={secondary} theme={theme}>
                      Read only mode
                    </TooltipText>
                  ) : Array.isArray(text) ? (
                    text.map((t) => (
                      <TooltipText secondary={secondary} key={t} theme={theme}>
                        {t}
                      </TooltipText>
                    ))
                  ) : (
                    <TooltipText secondary={secondary} theme={theme}>
                      {text}
                    </TooltipText>
                  )}
                </div>
              )}
              {shortcut && <ShortcutText>{shortcut}</ShortcutText>}
            </TooltipContainer2>
          </Anchor>
        )}
      </>,
    );
  });
};

type TextOrContent =
  | {
      text: string | string[];
      content?: React.ReactNode;
    }
  | {
      text?: string;
      content: React.ReactNode;
    };

export default function Tooltip({
  id,
  children,
  text,
  content,
  shortcut,
  theme = "dark",
  position = "top",
  secondary = false,
  delay = 500,
  closeDelay = 0,
  disabled = false,
  offset,
  floatPlace,
  maxWidth,
  divRef,
  revealWithQKey,
  outerDivStyle,
  innerDivStyle,
  readonlyAware,
  interactive,
}: {
  id?: string;
  children: ReactNode;
  shortcut?: string;
  theme?: "dark" | "light";
  position?: Place;
  offset?: [string | number, string | number];
  secondary?: boolean;
  floatPlace?: Place;
  maxWidth?: string;
  divRef?: React.RefObject<HTMLDivElement>;
  revealWithQKey?: boolean;
  outerDivStyle?: CSSProperties;
  innerDivStyle?: CSSProperties;
  readonlyAware?: boolean;
  delay?: number;
  closeDelay?: number;
  disabled?: boolean;
  /**
   * If true, the tooltip will not hide when the user mouseOvers it
   */
  interactive?: boolean;
} & TextOrContent) {
  const timeout = useRef<NodeJS.Timeout>();
  const closeTimeout = useRef<NodeJS.Timeout>();
  const [show, setShow] = useState(false);
  const [showingWithKey, setShowingWithKey] = useState(false);
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);

  const _onEnter = useCallback(() => {
    clearTimeout(closeTimeout.current);
    timeout.current = setTimeout(() => {
      setShow(true);
    }, delay);
  }, [delay]);

  const setDelayedCloseTimeout = useCallback(() => {
    clearTimeout(closeTimeout.current);
    closeTimeout.current = setTimeout(() => {
      setShow(false);
      clearTimeout(timeout.current);
    }, closeDelay);
  }, [closeDelay]);

  const _onLeave = useCallback(() => {
    if (closeDelay && show) {
      setDelayedCloseTimeout();
      return;
    }
    clearTimeout(timeout.current);
    setShow(false);
  }, [closeDelay, setDelayedCloseTimeout, show]);

  // Remove the tooltip on mouseDown
  // This will prevent the tooltip to still be there when for instance mousedowning to drag an element
  useEffect(() => {
    if (!show || interactive) {
      return;
    }

    document.addEventListener("mousedown", _onLeave);
    return () => {
      document.removeEventListener("mousedown", _onLeave);
    };
  }, [_onLeave, interactive, show]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      clearTimeout(closeTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (!revealWithQKey) return;
    const show = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(true);
    };
    const hide = (event: KeyboardEvent) => {
      if (
        event.repeat ||
        event.isComposing ||
        !event.key ||
        event.key.toLowerCase() !== "q"
      ) {
        return;
      }
      setShowingWithKey(false);
    };

    window.addEventListener("keydown", show);
    window.addEventListener("keyup", hide);

    return () => {
      window.removeEventListener("keydown", show);
      window.removeEventListener("keyup", hide);
    };
  }, [revealWithQKey, setShowingWithKey]);

  const ref = useRef<HTMLDivElement>(null);

  const shouldShow = showingWithKey || show;

  return (
    <div
      id={id}
      className="TooltipOuter"
      ref={ref}
      style={{
        display: "flex",
        ...(outerDivStyle ?? {}),
      }}
    >
      <TooltipInner
        ref={divRef}
        onMouseEnter={_onEnter}
        onMouseLeave={_onLeave}
        onPointerLeave={_onLeave}
        style={innerDivStyle}
      >
        {children}
      </TooltipInner>
      {shouldShow && !disabled && (
        <Anchor
          basePlace={position}
          floatPlace={floatPlace ?? oppositePlace(position)}
          update={shouldShow}
          baseRef={ref}
          offset={offset}
        >
          <TooltipContainer2
            show={shouldShow}
            maxWidth={maxWidth}
            theme={theme}
            onMouseEnter={
              interactive
                ? () => {
                    clearTimeout(closeTimeout.current);
                  }
                : _onLeave
            }
            onMouseLeave={interactive ? setDelayedCloseTimeout : undefined}
          >
            {content ? (
              <div>{content}</div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {readonlyAware && isReadOnly ? (
                  <TooltipText secondary={secondary} theme={theme}>
                    Read only mode
                  </TooltipText>
                ) : Array.isArray(text) ? (
                  text.map((t) => (
                    <TooltipText secondary={secondary} key={t} theme={theme}>
                      {t}
                    </TooltipText>
                  ))
                ) : (
                  <TooltipText secondary={secondary} theme={theme}>
                    {text}
                  </TooltipText>
                )}
              </div>
            )}
            {shortcut && <ShortcutText>{shortcut}</ShortcutText>}
          </TooltipContainer2>
        </Anchor>
      )}
    </div>
  );
}
