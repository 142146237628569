import { atom, useAtomValue } from "jotai";
import { StandardBox } from "../../styles/boxes/Boxes";
import styled from "styled-components";
import { validationWarningsAllAtom } from "../../state/validationWarnings";
import { MaxSizeExceededJotai } from "./MaxSizeExceeded";
import { AnchorOutsideParkJotai } from "./AnchorOutsidePark";
import React, { useEffect, useMemo, useState } from "react";
import {
  ErrorBoundarySilent,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { ErrorBoundary } from "react-error-boundary";
import ValidationWarningBox from "./ValidationWarningBox";
import { colors } from "../../styles/colors";
import { TurbineNotConnectedJotai } from "./TurbineNotConnected";
import { TurbineOutsidePark } from "./TurbineOutsidePark";
import { mapAtom } from "state/map";
import { SubstationOverlapJotai } from "./SubstationOverlap";
import { TurbineTypeInvalidJotai } from "./TurbineTypeInvalid";
import { FoundationTypeInvalidJotai } from "./FoundationTypeInvalid";
import { CableTypeInvalidJotai } from "./CableTypeInvalid";
import { OffshoreSubstationOutsideParkCableCorridorJotai } from "./SubstationOutsideParkCableCorridor";
import { ExistingTurbinesWithoutPowerJotai } from "./ExistingTurbinesWithoutPower";
import {
  ValidationWarningTypes,
  ValidationSeverity,
  validationWarningSeverity,
  validationWarningSeverityColor,
  validationWarningBadgeSeverityColor,
  validationWarningBadgeSeverityFontColor,
} from "./utils";
import { parksFamily } from "state/jotai/park";
import { fastMax } from "utils/utils";
import { TurbineOverlapJotai } from "./TurbineOverlap";
import { modalTypeOpenAtom } from "state/modal";

const ExclamationWrapper = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
`;

const Badge = styled.div<{ background: string; fontColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${({ fontColor }) => fontColor};
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: ${(p) => p.background};
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
`;

const CriticalErrorText = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: white;
  margin-left: 0.4rem;
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 2rem;
  padding-left: 2rem;
  cursor: pointer;
`;

const Wrapper = styled(StandardBox)<{
  background: string;
  show: boolean;
  animate: boolean;
}>`
  position: relative;
  opacity: ${(p) => (p.show ? 1 : 0.3)};
  height: 0.8em;
  min-width: 0.8em;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  cursor: pointer;
  background: ${(p) => p.background};

  &:hover {
    opacity: ${(p) => (p.show ? 1 : 0.7)};
  }
  transition: opacity 0.2s linear;

  ${(p) => p.animate && "animation: pulse 2s 5;"};

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.15);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1);
    }
  }
`;

export const ShowWarningBoxAtom = atom<boolean>(false);

function FatalAblyMessage({
  warning,
}: {
  warning: {
    type: ValidationWarningTypes;
    retryTimestamp?: number;
  };
}) {
  const [countdown, setCountdown] = useState<string>();

  useEffect(() => {
    if (!warning.retryTimestamp) {
      setCountdown(undefined);
      return;
    }

    let run = true;
    const intervalId = setInterval(() => {
      if (!run) return;
      const now = Date.now();
      const timeLeft = warning.retryTimestamp! - now;

      if (timeLeft <= 0) {
        clearInterval(intervalId);
        setCountdown("0s");
      } else {
        const seconds = Math.floor(timeLeft / 1000);
        setCountdown(`${seconds}s`);
      }
    }, 1000);

    return () => {
      run = false;
      clearInterval(intervalId);
    };
  }, [warning.retryTimestamp]);

  return (
    <CriticalErrorText>
      {`Connection lost ${countdown ? "- retry in: " + countdown : ""}`}
    </CriticalErrorText>
  );
}

const ValidationWarningsInner = () => {
  const warnings = useAtomValue(validationWarningsAllAtom);
  const parks = useAtomValue(parksFamily({ branchId: undefined }));
  const map = useAtomValue(mapAtom);

  const [showBox, setShowBox] = useState(false);
  const [clicked, setClicked] = useState(false);

  const highestSeverity = useMemo(
    () => fastMax(warnings.map((w) => validationWarningSeverity[w.type])),
    [warnings],
  ) as ValidationSeverity;
  const color =
    warnings.length > 0
      ? validationWarningSeverityColor[highestSeverity]
      : colors.secondaryText;

  const badgeColor =
    warnings.length > 0
      ? validationWarningBadgeSeverityColor[highestSeverity]
      : colors.secondaryText;

  const badgeFontColor =
    warnings.length > 0
      ? validationWarningBadgeSeverityFontColor[highestSeverity]
      : colors.secondaryText;

  const showFatalError = useMemo(
    () => warnings.some((w) => w.type === ValidationWarningTypes.FatalGeneral),
    [warnings],
  );

  const showFataAblyLostError = useMemo(
    () => warnings.find((w) => w.type === ValidationWarningTypes.FatalAblyLost),
    [warnings],
  );

  const modalTypeOpen = useAtomValue(modalTypeOpenAtom); // hide warnings if dahsboard or something is open
  if (!map || modalTypeOpen) return null;

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundarySilent}
        onError={ScreamOnError}
      >
        {
          //Show Error from other parks even if none is selected
          parks.map(({ id: parkId }) => (
            <React.Fragment key={parkId}>
              <AnchorOutsideParkJotai parkId={parkId} />
              <TurbineNotConnectedJotai parkId={parkId} />
              <TurbineOutsidePark parkId={parkId} map={map} />
              <OffshoreSubstationOutsideParkCableCorridorJotai
                parkId={parkId}
                map={map}
              />
              <SubstationOverlapJotai parkId={parkId} map={map} />
              <TurbineOverlapJotai parkId={parkId} map={map} />
            </React.Fragment>
          ))
        }
        <MaxSizeExceededJotai />
        <CableTypeInvalidJotai map={map} />
        <TurbineTypeInvalidJotai map={map} />
        <FoundationTypeInvalidJotai map={map} />
        <ExistingTurbinesWithoutPowerJotai map={map} />
      </ErrorBoundary>
      {warnings.length > 0 && (
        <RelativeWrapper
          onClick={() => {
            setShowBox(!showBox);
            setClicked(true);
          }}
        >
          <Wrapper
            background={color}
            show={showBox || warnings.length > 0}
            animate={!clicked && warnings.length > 0}
          >
            {!showFatalError && !showFataAblyLostError && (
              <>
                <ExclamationWrapper>!</ExclamationWrapper>
                <Badge background={badgeColor} fontColor={badgeFontColor}>
                  {" "}
                  {warnings.length}
                </Badge>
              </>
            )}
            {showFatalError && (
              <ExclamationWrapper>
                !
                <CriticalErrorText>
                  System not responding correctly
                </CriticalErrorText>
              </ExclamationWrapper>
            )}

            {showFataAblyLostError && (
              <ExclamationWrapper>
                !
                <FatalAblyMessage warning={showFataAblyLostError} />
              </ExclamationWrapper>
            )}
          </Wrapper>
          {showBox && (
            <ValidationWarningBox
              warnings={warnings}
              close={() => setShowBox(false)}
            />
          )}
        </RelativeWrapper>
      )}
    </>
  );
};

const ValidationWarnings = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    // We need features to actually be added to the map before mounting this,
    // so that the warnings that color features (e.g. turbines outside park)
    // have features to color.
    const t = setTimeout(() => {
      setShow(true);
    }, 1_000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  if (show) return <ValidationWarningsInner />;
  return null;
};

export default ValidationWarnings;
