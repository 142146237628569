import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { projectOperationsConfigurationsFamily } from "state/jotai/operationsConfiguration";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectOperationsDropDownCustom = ({
  selectedConfig,
  onSelectItem,
  projectId,
  style,
  className,
}: {
  selectedConfig?: OperationsConfiguration;
  projectId: string;
  onSelectItem: (val: string) => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const operationsConfigs = useAtomValue(
    projectOperationsConfigurationsFamily(projectId),
  );

  const items = useMemo<DropDownItem[]>(() => {
    return [...operationsConfigs.values()].map((c) => ({
      value: c.id,
      name: c.name ?? "Untitled",
      icon: (
        <IconREMSize width={1.4} height={1.4}>
          <ProjectIcon />
        </IconREMSize>
      ),
    }));
  }, [operationsConfigs]);

  return (
    <DropdownButton
      className={className}
      items={items}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      size={"small"}
      listContainerStyle={style}
      style={style}
      onSelectItem={onSelectItem}
      buttonText={
        operationsConfigs.get(selectedConfig?.id ?? "")?.name ??
        "Select configuration"
      }
      selectedItemValue={selectedConfig?.id}
    />
  );
};

export default SelectOperationsDropDownCustom;
