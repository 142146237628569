import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { DateText, ExpandText, Label, Text } from "./style";
import ArrowRight from "@icons/24/KeyboardArrowRight.svg?react";
import {
  ChangelogRenderEntry,
  EventActions,
  InputChangelogInfo,
} from "./types";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Column, Row } from "components/General/Layout";
import UserInfo, { UserInfoProject } from "components/UserInfo/UserInfo";
import { timeAgoOrFormattedDate } from "utils/utils";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { colors } from "styles/colors";
import { ChangelogCommentSmallView } from "./ExistingChangelogComment";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowUpIcon from "@icons/24/ArrowUp.svg?react";
import { IconREMSize } from "styles/typography";
import { Icon } from "components/General/Icons";

const userImageSize = 2.4;
const maximumAllowwidth = 200;

const printValue = (
  suffix: string,
  value?: number | string | Array<any> | null,
) => {
  if (value == null) {
    return <Text>Not defined</Text>;
  }
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((v: any, index) => (
          <div key={index}>{typeof v === "object" ? JSON.stringify(v) : v}</div>
        ))}
      </div>
    );
  }

  return `${value} ${suffix}`;
};

const PrettifyObjectComparison = (
  oldObj: Record<string, any> | null | undefined,
  newObj: Record<string, any> | null | undefined,
): React.ReactNode => {
  const oldRef = useRef<HTMLDivElement>(null);
  const newRef = useRef<HTMLDivElement>(null);
  const [maxOldWidth, setMaxOldWidth] = useState<number | null>(null);
  const [maxNewWidth, setMaxNewWidth] = useState<number | null>(null);

  useEffect(() => {
    if (oldRef.current && newRef.current) {
      const oldWidths = Array.from(oldRef.current.children).map(
        (child) => (child as HTMLElement).getBoundingClientRect().width,
      );
      const newWidths = Array.from(newRef.current.children).map(
        (child) => (child as HTMLElement).getBoundingClientRect().width,
      );
      setMaxOldWidth(Math.min(maximumAllowwidth, Math.max(...oldWidths)));
      setMaxNewWidth(Math.min(maximumAllowwidth, Math.max(...newWidths)));
    }
  }, [oldRef, newRef]);

  const allKeys = new Set([
    ...(oldObj ? Object.keys(oldObj) : []),
    ...(newObj ? Object.keys(newObj) : []),
  ]);

  const changedKeys = new Set<string>();
  for (const key of allKeys) {
    if (oldObj?.[key] != newObj?.[key]) {
      changedKeys.add(key);
    }
  }

  return (
    <Row
      style={{
        gap: "1.6rem",
        alignItems: "center",
      }}
    >
      <Column
        style={{
          gap: "0.4rem",
        }}
      >
        {Array.from(changedKeys).map((key) => (
          <Label
            key={key}
            style={{
              width: "9rem",
              color: colors.textDisabled,
            }}
          >
            {key}:
          </Label>
        ))}
      </Column>
      <Column
        ref={oldRef}
        style={{
          visibility: maxOldWidth ? "visible" : "hidden",
          width: maxOldWidth ? `${maxOldWidth}px` : "auto",
          gap: "0.4rem",
        }}
      >
        {Array.from(changedKeys).map((key) => {
          const oldValue = oldObj?.[key] ?? "---";
          return (
            <Text
              key={key}
              style={{
                color: colors.textDisabled,
              }}
            >
              {oldValue}
            </Text>
          );
        })}
      </Column>
      <ArrowRight />
      <Column
        ref={newRef}
        style={{
          visibility: maxNewWidth ? "visible" : "hidden",
          width: maxNewWidth ? `${maxNewWidth}px` : "auto",
          gap: "0.4rem",
        }}
      >
        {Array.from(changedKeys).map((key) => {
          const newValue = newObj?.[key] ?? "---";
          return <Text key={key}>{newValue}</Text>;
        })}
      </Column>
    </Row>
  );
};

const ExistingChangelogContentRow = ({
  entry,
  suffix,
  oldEntry,
}: {
  entry: ChangelogRenderEntry;
  suffix?: string;
  oldEntry?: boolean;
}) => {
  switch (entry.action) {
    case EventActions.CREATE:
      if (typeof entry.value === "object") {
        return PrettifyObjectComparison(null, entry.value);
      }
      return (
        <Row
          style={{
            gap: "1.6rem",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: colors.textDisabled,
            }}
          >
            ---
          </Text>
          <Icon fill={oldEntry ? colors.textDisabled : colors.textPrimary}>
            <ArrowRight />
          </Icon>
          <Text
            style={{
              color: oldEntry ? colors.textDisabled : colors.textPrimary,
            }}
          >
            {printValue(suffix ?? "", entry.value)}
          </Text>
        </Row>
      );
    case EventActions.UPDATE:
      if (
        typeof entry.oldValue === "object" &&
        typeof entry.value === "object"
      ) {
        return PrettifyObjectComparison(entry.oldValue, entry.value);
      }
      return (
        <Row
          style={{
            gap: "1.6rem",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: colors.textDisabled,
            }}
          >
            {printValue(suffix ?? "", entry.oldValue)}
          </Text>
          <Icon fill={oldEntry ? colors.textDisabled : colors.textPrimary}>
            <ArrowRight />
          </Icon>
          <Text
            style={{
              color: oldEntry ? colors.textDisabled : colors.textPrimary,
            }}
          >
            {printValue(suffix ?? "", entry.value)}
          </Text>
        </Row>
      );
    default:
      return <></>;
  }
};

export function ExistingChangelogContent({
  changes,
  suffix,
  changelogInfo,
  disabled,
}: {
  changes: ChangelogRenderEntry[];
  changelogInfo: InputChangelogInfo;
  suffix?: string;
  disabled?: boolean;
}) {
  const projectId = useAtomValue(projectIdAtom);
  const [openChangelog, setOpenChangelog] = useState(false);

  if (changes.length === 0) {
    return <Text>No changelog available</Text>;
  }

  const [first, ...rest] = changes;

  return (
    <Column
      style={{
        width: "35rem",
        gap: "2.4rem",
        padding: "0 0 0.8rem",
      }}
    >
      <Column
        style={{
          gap: "1.6rem",
        }}
      >
        <h4
          style={{
            margin: 0,
          }}
        >
          Changelog
        </h4>
        <Column>
          <React.Fragment key={first.id + first.version}>
            <Row
              style={{
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Suspense
                fallback={
                  <SkeletonBlock
                    style={{
                      width: "5rem",
                      height: "1.4rem",
                    }}
                  />
                }
              >
                {projectId && (
                  <UserInfoProject
                    userId={first.author}
                    projectId={projectId}
                    size={userImageSize}
                    nameStyle={{
                      fontWeight: 400,
                    }}
                  />
                )}
                {!projectId && (
                  <UserInfo userId={first.author} size={userImageSize} />
                )}
              </Suspense>
              <DateText
                style={{
                  marginRight: "auto",
                }}
              >
                {timeAgoOrFormattedDate(first.version / 1000)}
              </DateText>
              <ChangelogCommentSmallView
                openByDefault={true}
                entry={first}
                changelogInfo={changelogInfo}
                disabled={disabled}
                commentTitle={
                  !projectId
                    ? "This comment is only visible for members with access to library."
                    : undefined
                }
              />
            </Row>
            <Row>
              <ExistingChangelogContentRow entry={first} suffix={suffix} />
            </Row>
          </React.Fragment>
        </Column>
        {rest.length > 0 && (
          <Row
            alignCenter
            style={{
              cursor: "pointer",
              gap: "0.8rem",
            }}
            onClick={() => setOpenChangelog(!openChangelog)}
          >
            <ExpandText>View all</ExpandText>
            <IconREMSize height={1} width={1}>
              {openChangelog ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconREMSize>
          </Row>
        )}
        {openChangelog && (
          <Column>
            {rest.map((entry) => (
              <React.Fragment key={entry.id + entry.version}>
                <Row
                  style={{
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Suspense
                    fallback={
                      <SkeletonBlock
                        style={{
                          width: "5rem",
                          height: "1.4rem",
                        }}
                      />
                    }
                  >
                    {projectId && (
                      <UserInfoProject
                        userId={entry.author}
                        projectId={projectId}
                        size={userImageSize}
                      />
                    )}
                    {!projectId && (
                      <UserInfo userId={entry.author} size={userImageSize} />
                    )}
                  </Suspense>
                  <DateText
                    style={{
                      marginRight: "auto",
                    }}
                  >
                    {timeAgoOrFormattedDate(entry.version / 1000)}
                  </DateText>
                  <ChangelogCommentSmallView
                    entry={entry}
                    changelogInfo={changelogInfo}
                    disabled={disabled}
                    commentTitle={
                      !projectId
                        ? "This comment is only visible for members with access to library."
                        : undefined
                    }
                  />
                </Row>
                <Row>
                  <ExistingChangelogContentRow
                    entry={entry}
                    suffix={suffix}
                    oldEntry={true}
                  />
                </Row>
              </React.Fragment>
            ))}
          </Column>
        )}
      </Column>
    </Column>
  );
}
