import { loadable } from "jotai/utils";
import React, { useMemo } from "react";
import { CenterContainer, SafeCard } from "./Base";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../Dashboard";
import { waveData2WaveRose } from "components/WindWaveMeasurementInfoModal/utils";
import { getClosestWaveDataSelector } from "state/waveStatistics";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { useAtomValue } from "jotai";
import { getAnalysisWindStats } from "analysis/output";

const WaveRose = React.lazy(
  () => import("components/WindWaveMeasurementInfoModal/WaveRose"),
);

const WaveRoseInner = () => {
  const { triggerId } = useDashboardContext();
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const stopped = stoppedReason ?? analysisStoppedReason;

  const windStats = useAtomValue(getAnalysisWindStats(triggerId));
  const waveData = useAtomValue(
    loadable(
      getClosestWaveDataSelector({
        lon: windStats?.longitude,
        lat: windStats?.latitude,
        source: undefined,
      }),
    ),
  );

  const waveRose = useMemo(() => {
    if (waveData.state !== "hasData" || !waveData.data) return;
    return waveData2WaveRose(waveData.data, 32);
  }, [waveData]);

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  if (!waveRose) return null;

  return <WaveRose waveRose={waveRose} />;
};

export const WaveRoseWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Wave rose"
      id="Wave rose"
      resetKeys={errorBoundaryResetKeys}
    >
      <WaveRoseInner />
    </SafeCard>
  );
};
