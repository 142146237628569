import { useSearch } from "./useSearch";
import { organisationAnalysisResourceState } from "components/Organisation/Library/state";
import { orgAnalysisManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AnalysisConfiguration } from "services/configurationService";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";

const keys = ["name", "note"];

export function useSearchLibraryAnalysis(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgAnalysisManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredAnalysis, rawData } =
    useSearch<AnalysisConfiguration>(
      enabled,
      searchTerm,
      keys,
      organisationAnalysisResourceState({
        organisationId,
      }),
    );

  const filteredSearchItems: SearchResultItem<AnalysisConfiguration>[] =
    useMemo(() => {
      return filteredAnalysis.map((result) => ({
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: getIcon(LibraryManageRole.ANALYSIS),
        onSelect: () => {
          navigate(
            `/organisation/${organisationId}/library/analysis/${result.item.id}`,
          );
        },
      }));
    }, [filteredAnalysis, navigate, organisationId]);

  const searchableItems: SearchItem<AnalysisConfiguration>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(`/organisation/${organisationId}/library/analysis/${item.id}`);
      },
      icon: getIcon(LibraryManageRole.ANALYSIS),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredAnalysis: filteredSearchItems,
    searchableItems,
  };
}
