import { useSearch } from "./useSearch";
import {
  SearchItem,
  SearchObject,
  SearchResultItem,
  searchObjectInFocusAtom,
  searchObjectsAtomFamily,
} from "../state";
import { useSetAtom } from "jotai";
import { useMemo } from "react";

const keys = ["title", "tags"];

export function useSearchAppObjects(enabled: boolean, searchTerm: string) {
  const setSearchObjectInFocus = useSetAtom(searchObjectInFocusAtom);
  const { filteredResults, rawData } = useSearch<SearchObject>(
    enabled,
    searchTerm,
    keys,
    searchObjectsAtomFamily,
  );

  const filteredSearchItems: SearchResultItem<SearchObject>[] = useMemo(() => {
    return filteredResults.map((result) => ({
      id: result.item.id,
      name: result.item.title,
      score: result.score,
      item: result.item,
      onSelect: () => {
        result.item.onSelect();
      },
      setFocus: (focus: boolean) => {
        setSearchObjectInFocus(focus ? result.item.id : undefined);
      },
      icon: result.item.icon,
    }));
  }, [filteredResults, setSearchObjectInFocus]);

  const searchableItems: SearchItem<SearchObject>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.title,
      item: item,
      onSelect: () => {
        item.onSelect();
      },
      setFocus: (focus: boolean) => {
        setSearchObjectInFocus(focus ? item.id : undefined);
      },
      icon: item.icon,
    }));
  }, [rawData, setSearchObjectInFocus]);

  return {
    filteredAppObjects: filteredSearchItems,
    searchableItems,
  };
}
