import { atom } from "jotai";
import { PortPointFeature } from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";

export const portPointsFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<PortPointFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).port,
    ),
);
