import { getBranchFeatures } from "components/ProjectElements/service";
import { _SafeParseObjectWithFeatureArray } from "components/ProjectElements/state";
import { atom } from "jotai";
import { inPark } from "state/park";
import { branchIdAtom, parkIdAtom, projectIdAtom } from "state/pathParams";
import {
  AnchorFeature,
  BathymetryFeature,
  CableCorridorFeature,
  CableFeature,
  ExclusionZoneFeature,
  ExistingTurbineFeature,
  ExportCableFeature,
  GeotiffFeature,
  MooringLineFeature,
  OtherFeature,
  ParkFeature,
  PortPointFeature,
  ProjectFeature,
  SensorFeature,
  SlackRegionFeature,
  SubAreaFeature,
  SubstationFeature,
  TurbineFeature,
  ViewpointFeature,
} from "types/feature";
import { MaybePromise } from "types/utils";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  isAnchor,
  isBathymetry,
  isCable,
  isCableCorridor,
  isExclusionDivision,
  isExistingTurbine,
  isExportCable,
  isGeotiff,
  isMooringLine,
  isPark,
  isPort,
  isSensorPoint,
  isSlackRegion,
  isSubArea,
  isSubstation,
  isTurbine,
  isViewPoint,
} from "utils/predicates";
import { cableChainsInParkFamily } from "./cableChain";
import { cablePartitionsInParkFamily } from "./cablePartition";
import { projectVersionFamily } from "./project";

/**
 * All features for the given source.
 */
export const allFeaturesFamily = atomFamily(
  ({
    projectId,
    branchId,
    version,
  }: {
    projectId: string;
    branchId: string;
    version: undefined | number;
  }) =>
    atomFromFn<MaybePromise<ProjectFeature[]>>(async () => {
      const res = await getBranchFeatures(projectId, branchId, version);
      return _SafeParseObjectWithFeatureArray.parse(res).features;
    }),
);

export const featuresListAtom = atom<Promise<ProjectFeature[]>>(async (get) => {
  const projectId = get(projectIdAtom);
  const branchId = get(branchIdAtom);
  if (!projectId || !branchId) return [];
  const version = get(projectVersionFamily({ projectId, branchId }));
  const features = await get(
    allFeaturesFamily({ branchId, projectId, version }),
  );
  return features ?? [];
});

type FeaturesByType = {
  park: ParkFeature[];
  turbine: TurbineFeature[];
  existingTurbine: ExistingTurbineFeature[];
  cable: CableFeature[];
  substation: SubstationFeature[];
  cableCorridor: CableCorridorFeature[];
  exportCable: ExportCableFeature[];
  anchor: AnchorFeature[];
  mooringLine: MooringLineFeature[];
  exclusionZone: ExclusionZoneFeature[];
  subArea: SubAreaFeature[];
  slackRegion: SlackRegionFeature[];
  viewpoint: ViewpointFeature[];
  sensor: SensorFeature[];
  geotiff: GeotiffFeature[];
  bathymetry: BathymetryFeature[];
  port: PortPointFeature[];
  other: OtherFeature[];
};

const splitFeaturesByType = (fs: ProjectFeature[]): FeaturesByType => {
  const ret: FeaturesByType = {
    park: [],
    turbine: [],
    existingTurbine: [],
    cable: [],
    substation: [],
    cableCorridor: [],
    exportCable: [],
    anchor: [],
    mooringLine: [],
    exclusionZone: [],
    subArea: [],
    slackRegion: [],
    viewpoint: [],
    sensor: [],
    geotiff: [],
    bathymetry: [],
    port: [],
    other: [],
  };

  for (const f of fs) {
    if (isTurbine(f)) ret.turbine.push(f);
    else if (isPark(f)) ret.park.push(f);
    else if (isExistingTurbine(f)) ret.existingTurbine.push(f);
    else if (isCable(f)) ret.cable.push(f);
    else if (isSubstation(f)) ret.substation.push(f);
    else if (isCableCorridor(f)) ret.cableCorridor.push(f);
    else if (isExportCable(f)) ret.exportCable.push(f);
    else if (isAnchor(f)) ret.anchor.push(f);
    else if (isMooringLine(f)) ret.mooringLine.push(f);
    else if (isExclusionDivision(f)) ret.exclusionZone.push(f);
    else if (isSubArea(f)) ret.subArea.push(f);
    else if (isSlackRegion(f)) ret.slackRegion.push(f);
    else if (isViewPoint(f)) ret.viewpoint.push(f);
    else if (isSensorPoint(f)) ret.sensor.push(f);
    else if (isGeotiff(f)) ret.geotiff.push(f);
    else if (isBathymetry(f)) ret.bathymetry.push(f);
    else if (isPort(f)) ret.port.push(f);
    else ret.other.push(f);
  }

  return ret;
};

const NO_FEATURES = splitFeaturesByType([]);

/**
 * Get features split by their type.
 */
export const featuresFamily = atomFamily(
  (input: { branchId: undefined | string }) =>
    atom<Promise<FeaturesByType>>(async (get) => {
      const projectId = get(projectIdAtom);
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!projectId || !branchId) return NO_FEATURES;
      const version = get(projectVersionFamily({ projectId, branchId }));
      const features = await get(
        allFeaturesFamily({ branchId, projectId, version }),
      );
      return splitFeaturesByType(features);
    }),
);

export const featureMapFamily = atomFamily(
  (input: { branchId: string | undefined }) =>
    atom<Promise<Map<string, ProjectFeature>>>(async (get) => {
      const projectId = get(projectIdAtom);
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!projectId || !branchId) return new Map();
      const version = get(projectVersionFamily({ projectId, branchId }));
      const features = await get(
        allFeaturesFamily({ branchId, projectId, version }),
      );
      return new Map(features.map((f) => [f.id, f]));
    }),
);

/**
 * Features in the given park, split by feature type.
 */
export const featuresInParkFamily = atomFamily(
  ({ branchId, parkId }: { branchId: string | undefined; parkId: string }) =>
    atom<
      Promise<{
        turbine: TurbineFeature[];
        cable: CableFeature[];
        substation: SubstationFeature[];
        cableCorridor: CableCorridorFeature[];
        exportCable: ExportCableFeature[];
        anchor: AnchorFeature[];
        mooringLine: MooringLineFeature[];
        subArea: SubAreaFeature[];
      }>
    >(async (get) => {
      const split = await get(featuresFamily({ branchId }));
      const isInPark = inPark(parkId);
      return {
        turbine: split.turbine.filter(isInPark),
        cable: split.cable.filter(isInPark),
        substation: split.substation.filter(isInPark),
        cableCorridor: split.cableCorridor.filter(isInPark),
        exportCable: split.exportCable.filter(isInPark),
        anchor: split.anchor.filter(isInPark),
        mooringLine: split.mooringLine.filter(isInPark),
        subArea: split.subArea.filter(isInPark),
      };
    }),
);

/**
 * Derived features are features we don't store explicitly, but that are
 * computed.  These features should *sometimes* be treated as regular features,
 * for instance with input handling.
 */
const featuresDerivedAtom = atom(async (get) => {
  const parkId = get(parkIdAtom);
  const branchId = get(branchIdAtom) ?? "";
  const features = get(featuresListAtom);
  if (parkId) {
    const chains = get(cableChainsInParkFamily({ parkId, branchId }));
    const partitions = get(cablePartitionsInParkFamily({ parkId, branchId }));
    return (await features).concat(await chains).concat(await partitions);
  } else {
    const parks = (await features).filter(isPark);
    const chains = (
      await Promise.all(
        parks.map(async (p) =>
          get(cableChainsInParkFamily({ parkId: p.id, branchId })),
        ),
      )
    ).flat();
    const partitions = (
      await Promise.all(
        parks.map(async (p) =>
          get(cablePartitionsInParkFamily({ parkId: p.id, branchId })),
        ),
      )
    ).flat();

    return (await features).concat(chains).concat(partitions);
  }
});

export const featuresSelectableMapAtom = atom(async (get) => {
  const features = await get(featuresDerivedAtom);
  return new Map(features.map((f) => [f.id, f]));
});
