import { InputDimensioned } from "../../General/Input";
import { Row } from "../../General/Layout";
import { Label } from "../../General/Form";
import { VesselType } from "services/vesselService";
import { vesselChangelogInfo } from "components/InputChangelog/const";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { SecondaryText, Text } from "components/SettingsV2/Shared/styles";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

export function InstallationVesselSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType & { type: "installation" };
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  return (
    <>
      <ChangelogAndCommentWrapper
        changelogInfo={vesselChangelogInfo(
          vessel.id,
          nodeId,
          "floating",
          "project",
        )}
        disabled={disabled}
      >
        <Row>
          <Toggle
            checked={vessel.floating}
            disabled={disabled}
            size={ToggleSize.SMALL}
            onChange={(e) => {
              setVessel({
                ...vessel,
                floating: e.target.checked,
              });
            }}
          />
          <Text>Floating</Text>
          <HelpTooltip text="If toggled off, a jack-up vessel is used." />
        </Row>
      </ChangelogAndCommentWrapper>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Turbine capacity</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.turbineCapacity}
            unit={"units"}
            step={1}
            validate={(e) => e >= 1 && e <= 15}
            validationMessage={`Must be between 1 and 15`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "turbineCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                turbineCapacity: Math.round(n),
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Monopile capacity</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.monopileCapacity}
            unit={"units"}
            step={1}
            validate={(e) => e >= 1 && e <= 15}
            validationMessage={`Must be between 1 and 15`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "monopileCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                monopileCapacity: Math.round(n),
              });
            }}
          />
        </Label>
        {!vessel.floating && (
          <Label>
            <SecondaryText>Jacking time</SecondaryText>
            <InputDimensioned
              decimals={0}
              style={{ width: "20rem" }}
              disabled={disabled}
              value={vessel.jackingTime}
              unit={"h"}
              step={1}
              validate={(e) => e >= 1 && e <= 20}
              validationMessage={`Must be between 1 and 20`}
              changelogInfo={vesselChangelogInfo(
                vessel.id,
                nodeId,
                "jackingTime",
                "project",
              )}
              onChange={(n) => {
                setVessel({
                  ...vessel,
                  jackingTime: Math.round(n),
                });
              }}
            />
          </Label>
        )}
      </div>
    </>
  );
}
