import { currentSelectionArrayAtom } from "./../../state/selection";
import { SetterOrUpdater } from "types/utils";
import {
  canvasLineLayerSource,
  canvasPolygonLayerSource,
  canvasPointLayerSource,
} from "../../constants/canvas";
import {
  SUB_AREA_PROPERTY_TYPE,
  SUB_AREA_COLOR,
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
} from "../../constants/division";
import {
  DrawLineStringMenuType,
  DrawParkMenuType,
  DrawPolygonMenuType,
  DrawPointMenuType,
  turbinesEditableSourceId,
  DrawPortMenuType,
  DrawAnchorMenuType,
  DrawExistingTurbineMenuType,
  DrawMooringLineMenuType,
  DrawViewPointMenuType,
  DrawCircleMenuType,
  DrawRectangleMenuType,
  DrawSensorPointMenuType,
} from "../../constants/draw";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_PROPERTY_TYPE,
  SENSOR_POINT_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import { PARK_PROPERTY_TYPE } from "../../constants/park";
import {
  AddSubStationMenuType,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  DrawCableCorridorMenuType,
  DrawCableMenuType,
  DrawExportCableMenuType,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../../constants/cabling";
import { zoneLayerSource } from "../../constants/zones";
import { LeftMenuModeType, leftMenuModeActiveAtom } from "../../state/filter";
import {
  addFeatureAtom,
  editFeaturesAtom,
  mapControlsAtom,
  mapAtom,
} from "../../state/map";
import {
  PlaceGoZoneType,
  PlaceNoGoZoneType,
} from "../DivisionDesign/DivisionConst";
import { PlaceWindTurbinesMenuType } from "../GenerateWindTurbines/PlaceWindTurbines";
import { AddFeatureOptions } from "../../types/map";
import { colors } from "../../styles/colors";
import { sendInfo } from "utils/sentry";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import {
  ParkFeature,
  SubstationFeature,
  TurbineFeature,
  exclusionDomainPack,
} from "../../types/feature";
import { parkIdAtom, projectIdAtom } from "state/pathParams";
import {
  anchorSourceId,
  cableCorridorSourceId,
  cableSourceId,
  existingTurbineSourceId,
  exportCableSourceId,
  mooringLineSourceId,
  portSourceId,
  sensorPointSourceId,
  viewPointSourceId,
} from "components/Mapbox/constants";
import { useEffect } from "react";
import { pointInPolygon } from "utils/geometry";
import { Feature, Point } from "geojson";
import * as turf from "@turf/turf";
import { VIEWPOINT_PROPERTY_TYPE } from "@constants/projectMapView";
import { isAnchor, isSubstation, isTurbine } from "utils/predicates";
import {
  AnchorLineError,
  CableConnectionError,
} from "components/CanvasSelectOption/TypeSelector/LineStringTypeSelector/utils";
import { useJotaiCallback } from "utils/jotai";
import { parkChildrenFamily, parksFamily } from "state/jotai/park";
import {
  currentExportCableIdAtom,
  drawNumberOfDuplicateExportCablesState,
  exportCablesInParkFamily,
} from "state/jotai/exportCable";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { currentSubstationIdAtomJ } from "state/jotai/substationType";
import { currentTurbineIdAtom } from "state/jotai/turbineType";
import { turbinesInParkByLabelFamily } from "state/jotai/turbine";
import { useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";

const newParkName = (numParks: number) => `Park ${numParks + 1}`;
const newExportCableName = (numExportCables: number) =>
  `Export cable ${numExportCables + 1}`;

export const useDrawMode = (): [
  LeftMenuModeType,
  SetterOrUpdater<LeftMenuModeType>,
] => {
  const leftMenuModeActive = useAtomValue(leftMenuModeActiveAtom);

  const setRecoilState = useJotaiCallback(
    (
      _get,
      set,
      mode: undefined | MapboxDraw.DrawMode | "draw_circle" | "draw_rectangle",
      options?: AddFeatureOptions,
      continuePlacing?: boolean,
      getPropertiesFunc?: (
        features: Feature,
      ) => Record<string, unknown> | Error,
    ) => {
      set(editFeaturesAtom, (s) => resetListIfNotAlreadyEmpty(s));
      if (mode !== undefined)
        set(currentSelectionArrayAtom, (s) => resetListIfNotAlreadyEmpty(s));

      set(
        addFeatureAtom,
        mode === undefined
          ? undefined
          : options
            ? {
                mode,
                options,
                continuePlacing,
                getPropertiesFunc,
              }
            : {
                mode,
              },
      );
    },
    [],
  );

  const setMapDrawMode = useJotaiCallback(
    async (
      get,
      set,
      mode: undefined | MapboxDraw.DrawMode | "draw_circle" | "draw_rectangle",
      options?: AddFeatureOptions,
      continuePlacing?: boolean,
      getPropertiesFunc?: (
        features: Feature,
      ) => Record<string, unknown> | Error,
      drawModeSettings?: Record<string, unknown>,
    ) => {
      const mapControls = get(mapControlsAtom);
      const map = get(mapAtom);
      if (!mapControls) return;
      if (map) {
        if (map.hasControl(mapControls)) {
          mapControls.deleteAll();
          mapControls.changeMode(
            // @ts-ignore: The overloads for MapControls.changeMode cause this to
            // not typecheck, even though it's fine.  Even
            // `mapControls.changeMode(mode)` doesnt work, since the overload in the
            // type definition is for `Exclude<DrawMode, ...>`.
            mode === undefined ? mapControls.modes.SIMPLE_SELECT : mode,
            drawModeSettings,
          );
        } else {
          sendInfo("MapControls not found in map when trying to change mode", {
            refIssue: "https://vind-technologies.sentry.io/issues/5183561431",
          });
        }
      }
      setRecoilState(mode, options, continuePlacing, getPropertiesFunc);
    },
    [setRecoilState],
  );

  const handleValueChange = useJotaiCallback(
    async (
      get,
      _,
      leftMenuActiveMode: LeftMenuModeType,
      previousLeftMenuActiveMode: LeftMenuModeType,
    ) => {
      if (leftMenuActiveMode === DrawLineStringMenuType) {
        setMapDrawMode("draw_line_string", {
          source: canvasLineLayerSource,
        });
      } else if (leftMenuActiveMode === DrawParkMenuType) {
        const parks = await get(
          parksFamily({
            branchId: undefined,
          }),
        );
        setMapDrawMode("draw_polygon", {
          type: PARK_PROPERTY_TYPE,
          color: colors.park2,
          name: newParkName(parks.length),
          source: "wat",
        });
      } else if (leftMenuActiveMode === PlaceGoZoneType) {
        const parkId = get(parkIdAtom);
        const parks = await get(
          parksFamily({
            branchId: undefined,
          }),
        );
        if (parkId)
          setMapDrawMode(
            "draw_polygon",
            {
              type: SUB_AREA_PROPERTY_TYPE,
              color: SUB_AREA_COLOR,
              source: zoneLayerSource,
              parentIds: [parkId],
              name: "Sub area",
            },
            false,
            (feature) => {
              const intersectingParks = parks.filter(
                (p) =>
                  turf.intersect(p, feature as Feature<turf.Polygon>) != null,
              );

              const parentIds = [
                intersectingParks.length === 1
                  ? intersectingParks[0]!.id
                  : parkId,
              ];

              return {
                parentIds,
              };
            },
          );
      } else if (leftMenuActiveMode === PlaceNoGoZoneType) {
        setMapDrawMode(
          "draw_polygon",
          {
            type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
            color: EXCLUSION_ZONE_COLOR,
            source: zoneLayerSource,
            domain: exclusionDomainPack({
              turbine: true,
              cable: true,
              substation: true,
              anchor: true,
            }),
            name: "Exclusion zone",
          },
          undefined,
          undefined,
          {
            warnIfTooBig: false,
          },
        );
      } else if (leftMenuActiveMode === DrawCircleMenuType) {
        setMapDrawMode("draw_circle", {
          source: canvasPolygonLayerSource,
        });
      } else if (leftMenuActiveMode === DrawRectangleMenuType) {
        setMapDrawMode("draw_rectangle", {
          source: canvasPolygonLayerSource,
        });
      } else if (leftMenuActiveMode === DrawPolygonMenuType) {
        setMapDrawMode(
          "draw_polygon",
          {
            source: canvasPolygonLayerSource,
          },
          undefined,
          undefined,
          {
            warnIfTooBig: false,
          },
        );
      } else if (leftMenuActiveMode === DrawPointMenuType) {
        setMapDrawMode("draw_point", {
          source: canvasPointLayerSource,
        });
      } else if (leftMenuActiveMode === DrawExportCableMenuType) {
        const parkId = await get(parkIdAtom);
        const onshore = get(isOnshoreAtom);
        if (parkId) {
          const exportCables = await get(
            exportCablesInParkFamily({
              parkId,
              branchId: undefined,
            }),
          );
          const currentExportCable = await get(currentExportCableIdAtom);
          const numberOfDuplicateExportCables = get(
            drawNumberOfDuplicateExportCablesState,
          );
          const subsWithType = await get(
            substationsInParkWithTypeFamily({
              parkId,
              branchId: undefined,
            }),
          );

          const onshores = subsWithType.filter(
            ([_, t]) => t.type === "onshore",
          );
          const offshores = subsWithType.filter(
            ([_, t]) => t.type === "offshore",
          );

          if (
            !currentExportCable ||
            onshores.length === 0 ||
            (!onshore && offshores.length === 0)
          )
            return;
          setMapDrawMode("draw_line_string", {
            source: exportCableSourceId,
            parentIds: [parkId],
            name: newExportCableName(exportCables.length),
            cableTypeId: currentExportCable.offshore,
            onshoreCableTypeId: currentExportCable.onshore,
            type: EXPORT_CABLE_PROPERTY_TYPE,
            duplicates: Math.max(1, numberOfDuplicateExportCables),
          });
        }
      } else if (leftMenuActiveMode === DrawCableCorridorMenuType) {
        const parkId = get(parkIdAtom);
        const parks = await get(
          parksFamily({
            branchId: undefined,
          }),
        );
        const findParkOverlap = (
          parkFeature: ParkFeature,
          feature: Feature<turf.Polygon>,
        ) => {
          const union = turf.union(parkFeature, feature);
          return union != null && !union.geometry.type.includes("Multi");
        };
        if (parkId)
          setMapDrawMode(
            "draw_polygon",
            {
              source: cableCorridorSourceId,
              parentIds: [parkId],
              name: "Cable corridor",
              type: CABLE_CORRIDOR_PROPERTY_TYPE,
            },
            false,
            (feature: Feature) => {
              const overlappingParks = parks.filter((p) =>
                findParkOverlap(p, feature as Feature<turf.Polygon>),
              );

              const parentIds = [
                overlappingParks.length === 1
                  ? overlappingParks[0]!.id
                  : parkId,
              ];
              return {
                parentIds,
              };
            },
            {
              warnIfTooBig: false,
            },
          );
      } else if (leftMenuActiveMode === DrawMooringLineMenuType) {
        const parkId = get(parkIdAtom);
        const parkFeatures = await get(
          parkChildrenFamily({
            parkId: parkId ?? "",
            branchId: undefined,
          }),
        );

        if (parkId)
          setMapDrawMode(
            "draw_line_string",
            {
              source: mooringLineSourceId,
              parentIds: [parkId],
              name: "Mooring line",
              type: MOORING_LINE_PROPERTY_TYPE,
              color: colors.mooringLine,
              slack: 0.3,
            },
            undefined,
            (feature) => {
              if (!("coordinates" in feature.geometry)) {
                return {};
              }

              const start = feature.geometry.coordinates.at(0) as number[];
              const end = feature.geometry.coordinates.at(-1) as number[];
              if (!start || !end) {
                return {};
              }

              const anchors = parkFeatures.filter(isAnchor);
              const turbines = parkFeatures.filter(isTurbine);

              const closestAnchors = anchors
                .map((anchor) => {
                  const distanceToStart = turf.distance(
                    anchor,
                    turf.point(start),
                    {
                      units: "meters",
                    },
                  );
                  const distanceToEnd = turf.distance(anchor, turf.point(end), {
                    units: "meters",
                  });
                  return {
                    distance: Math.min(distanceToStart, distanceToEnd),
                    anchor,
                  };
                })
                .filter(({ distance }) => distance < 1)
                .sort((a, b) => a.distance - b.distance);

              const closestTurbines = turbines
                .map((turbine) => {
                  const distanceToStart = turf.distance(
                    turbine,
                    turf.point(start),
                    {
                      units: "meters",
                    },
                  );
                  const distanceToEnd = turf.distance(
                    turbine,
                    turf.point(end),
                    {
                      units: "meters",
                    },
                  );
                  return {
                    distance: Math.min(distanceToStart, distanceToEnd),
                    turbine,
                  };
                })
                .filter(({ distance }) => distance < 1)
                .sort((a, b) => a.distance - b.distance);

              if (closestTurbines.length === 0 || closestAnchors.length === 0) {
                return new AnchorLineError(
                  "Mooring lines must connect to an anchor and a turbine",
                );
              }

              return {
                anchor: closestAnchors[0]?.anchor.id,
                target: closestTurbines[0]?.turbine.id,
              };
            },
          );
      } else if (leftMenuActiveMode === DrawCableMenuType) {
        const parkId = get(parkIdAtom);
        const projectFeatures = await get(
          parkChildrenFamily({
            parkId: parkId ?? "",
            branchId: undefined,
          }),
        );
        if (parkId)
          setMapDrawMode(
            "draw_line_string",
            {
              source: cableSourceId,
              parentIds: [parkId],
              name: "Cable",
              type: CABLE_PROPERTY_TYPE,
              color: colors.cable,
            },
            undefined,
            (feature) => {
              if (!("coordinates" in feature.geometry)) {
                return {};
              }

              const start = feature.geometry.coordinates.at(0) as number[];
              const end = feature.geometry.coordinates.at(-1) as number[];
              if (!start || !end) {
                return {};
              }

              const turbines = projectFeatures.filter(isTurbine);
              const substations = projectFeatures.filter(isSubstation);
              const turbinesAndSubstations = [...turbines, ...substations];

              const featuresCloseToStart = turbinesAndSubstations
                .map((turbine) => {
                  return {
                    distance: turf.distance(turbine, turf.point(start), {
                      units: "meters",
                    }),
                    turbine,
                  };
                })
                .filter(({ distance }) => distance < 1)
                .sort((a, b) => a.distance - b.distance);

              const featuresCloseToEnd = turbinesAndSubstations
                .map((turbine) => {
                  return {
                    distance: turf.distance(turbine, turf.point(end), {
                      units: "meters",
                    }),
                    turbine,
                  };
                })
                .filter(({ distance }) => distance < 1)
                .sort((a, b) => a.distance - b.distance);

              if (
                featuresCloseToEnd.length === 0 ||
                featuresCloseToStart.length === 0
              ) {
                return new CableConnectionError(
                  "Cables must connect turbines or a substation",
                );
              }

              return {
                fromId: featuresCloseToStart[0]?.turbine.id,
                toId: featuresCloseToEnd[0]?.turbine?.id,
              };
            },
          );
      } else if (leftMenuActiveMode === PlaceWindTurbinesMenuType) {
        const parkId = get(parkIdAtom);
        const parks = await get(
          parksFamily({
            branchId: undefined,
          }),
        );
        const projectId = get(projectIdAtom);
        const currentTurbine = get(
          currentTurbineIdAtom({
            projectId,
          }),
        );
        if (!currentTurbine) return;
        if (!parkId) return;
        const turbinesInParkByLabel = await get(
          turbinesInParkByLabelFamily({
            parkId,
            branchId: undefined,
          }),
        );

        const nextId = (() => {
          let id = turbinesInParkByLabel.length + 1;
          return () => id++;
        })();

        setMapDrawMode(
          "draw_point",
          {
            source: turbinesEditableSourceId,
            type: TURBINE_PROPERTY_TYPE,
            parentIds: [parkId],
            turbineTypeId: currentTurbine,
          } satisfies Omit<TurbineFeature["properties"], "id">,
          true,
          (feature: Feature) => {
            const parksOnPoint = parks.filter((p) =>
              pointInPolygon(feature.geometry as Point, p.geometry),
            );

            const parentIds = [
              parksOnPoint.length === 1 ? parksOnPoint[0]!.id : parkId,
            ];
            return {
              parentIds,
              name: `#${nextId()}`,
            };
          },
        );
      } else if (leftMenuActiveMode === AddSubStationMenuType) {
        const parkId = get(parkIdAtom);
        const parks = await get(
          parksFamily({
            branchId: undefined,
          }),
        );
        const currentSubstation = await get(currentSubstationIdAtomJ);
        if (!currentSubstation) return;
        if (parkId)
          setMapDrawMode(
            "draw_point",
            {
              source: turbinesEditableSourceId,
              type: SUBSTATION_PROPERTY_TYPE,
              parentIds: [parkId],
              name: "Substation",
              substationTypeId: currentSubstation,
              color: colors.substation,
            } satisfies Omit<SubstationFeature["properties"], "id">,
            false,
            (feature: Feature) => {
              const parksOnPoint = parks.filter((p) =>
                pointInPolygon(feature.geometry as Point, p.geometry),
              );
              const parentIds = [
                parksOnPoint.length === 1 ? parksOnPoint[0]!.id : parkId,
              ];
              return {
                parentIds,
              };
            },
          );
      } else if (leftMenuActiveMode === DrawViewPointMenuType) {
        setMapDrawMode("draw_point", {
          source: viewPointSourceId,
          type: VIEWPOINT_PROPERTY_TYPE,
          name: "View point",
          color: colors.viewpoint,
        });
      } else if (leftMenuActiveMode === DrawSensorPointMenuType) {
        setMapDrawMode("draw_point", {
          source: sensorPointSourceId,
          type: SENSOR_POINT_PROPERTY_TYPE,
          name: "Sensor",
          color: colors.sensor,
        });
      } else if (leftMenuActiveMode === DrawPortMenuType) {
        setMapDrawMode("draw_point", {
          source: portSourceId,
          type: PORT_POINT_PROPERTY_TYPE,
          name: "Port",
          color: colors.port,
        });
      } else if (leftMenuActiveMode === DrawAnchorMenuType) {
        const parkId = get(parkIdAtom);
        if (parkId)
          setMapDrawMode("draw_point", {
            source: anchorSourceId,
            type: ANCHOR_PROPERTY_TYPE,
            parentIds: [parkId],
            name: "Anchor",
            color: colors.anchor,
          });
      } else if (leftMenuActiveMode === DrawExistingTurbineMenuType) {
        setMapDrawMode("draw_point", {
          source: existingTurbineSourceId,
          type: EXISTING_TURBINE_PROPERTY_TYPE,
          name: "Existing turbine",
          color: colors.existingTurbine,
        });
      } else if (previousLeftMenuActiveMode !== leftMenuActiveMode) {
        setMapDrawMode(undefined);
      }
    },
    [setMapDrawMode],
  );

  // When the current turbine/substation/export cable changes, we need up update the draw mode to
  // use the new turbine.  Call handleValueChange to do this.
  const projectId = useAtomValue(projectIdAtom);
  const currentTurbineId = useAtomValue(
    currentTurbineIdAtom({
      projectId,
    }),
  );
  const currentSubstation = useAtomValue(currentSubstationIdAtomJ);
  const currentExportCable = useAtomValue(currentExportCableIdAtom);
  const numberOfDuplicateExportCables = useAtomValue(
    drawNumberOfDuplicateExportCablesState,
  );
  useEffect(() => {
    if (leftMenuModeActive === PlaceWindTurbinesMenuType)
      handleValueChange(PlaceWindTurbinesMenuType, PlaceWindTurbinesMenuType);
    else if (leftMenuModeActive === AddSubStationMenuType)
      handleValueChange(AddSubStationMenuType, AddSubStationMenuType);
    else if (leftMenuModeActive === DrawExportCableMenuType)
      handleValueChange(DrawExportCableMenuType, DrawExportCableMenuType);
    else return;
  }, [
    currentTurbineId,
    currentSubstation,
    currentExportCable,
    handleValueChange,
    leftMenuModeActive,
    numberOfDuplicateExportCables,
  ]);

  const setter: SetterOrUpdater<LeftMenuModeType> = useJotaiCallback(
    async (get, set, valOrFn) => {
      const currVal = await get(leftMenuModeActiveAtom);
      const nextVal =
        typeof valOrFn === "function" ? valOrFn(currVal) : valOrFn;
      handleValueChange(nextVal, currVal);
      set(leftMenuModeActiveAtom, nextVal);
      return valOrFn;
    },
    [handleValueChange],
  );

  return [leftMenuModeActive, setter];
};
