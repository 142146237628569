import { searchObjectInFocusAtom, searchObjectsAtomFamily } from "./state";
import { ReactNode, useEffect } from "react";
import { colors } from "styles/colors";
import { useAtomValue, useSetAtom } from "jotai";

const SearchWrapper = ({
  title,
  id,
  onSelect,
  tags,
  icon,
  children,
  disabled,
}: React.PropsWithChildren<{
  title: string;
  id: string;
  onSelect: () => void;
  icon?: ReactNode;
  tags?: string[];
  disabled?: boolean;
}>) => {
  const setSearchObject = useSetAtom(searchObjectsAtomFamily);
  const searchObjectInFocus = useAtomValue(searchObjectInFocusAtom);

  useEffect(() => {
    setSearchObject((cur) => {
      return [
        ...cur.filter((c) => c.id !== id),
        { title, id, tags, onSelect, icon, disabled },
      ];
    });

    return () => {
      setSearchObject((cur) => [...cur.filter((c) => c.id !== id)]);
    };
  }, [title, id, setSearchObject, tags, onSelect, icon, disabled]);

  return (
    <div
      style={
        !searchObjectInFocus
          ? {}
          : searchObjectInFocus !== id
            ? { opacity: 0.3 }
            : {
                backgroundColor: colors.surfaceButtonPrimary,
                borderRadius: "0.4rem",
              }
      }
    >
      {children}
    </div>
  );
};

export default SearchWrapper;
