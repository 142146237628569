import { Setter, atom, useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { userOrganisationResourcesSyncAtom } from "state/user";
import { getCurrentUserOrganisationResources } from "components/Organisation/OrganisationRightSide/service";
import debounce from "debounce-promise";

const refreshUserLibraryAccessImpl = async (
  set: Setter,
  organisationId: string,
  refreshAblyToken?: () => Promise<any>,
) => {
  set(userOrganisationResourcesSyncAtom({ organisationId }), (curr) => ({
    ...curr,
    loading: true,
  }));

  const access = await getCurrentUserOrganisationResources(organisationId);
  if (refreshAblyToken) {
    await refreshAblyToken();
  }

  set(userOrganisationResourcesSyncAtom({ organisationId }), {
    data: access,
    loading: false,
    initialised: true,
  });
};

const debouncedRefreshUserLibraryAccess = debounce(
  refreshUserLibraryAccessImpl,
  1000,
);

export const refreshUserLibraryAccessAtom = atom(
  null,
  async (
    _,
    set,
    organisationId: string,
    refreshAblyToken?: () => Promise<any>,
  ) => {
    await debouncedRefreshUserLibraryAccess(
      set,
      organisationId,
      refreshAblyToken,
    );
  },
);

const initialiseUserLibraryAccessAtom = atom(
  null,
  async (get, set, organisationId: string) => {
    const state = get(userOrganisationResourcesSyncAtom({ organisationId }));
    if (state.initialised || state.loading) return;
    set(refreshUserLibraryAccessAtom, organisationId);
  },
);

export function useUserLibraryAccessState({
  organisationId,
}: {
  organisationId: string;
}) {
  const [libraryAccess, setLibraryAccess] = useAtom(
    userOrganisationResourcesSyncAtom({ organisationId }),
  );
  const initialiseStoredNodes = useSetAtom(initialiseUserLibraryAccessAtom);
  const refresh = useSetAtom(refreshUserLibraryAccessAtom);

  useEffect(() => {
    initialiseStoredNodes(organisationId);
  }, [initialiseStoredNodes, organisationId]);

  return {
    data: libraryAccess.data,
    setState: setLibraryAccess,
    loading: libraryAccess.loading,
    initialised: libraryAccess.initialised,
    refresh,
  };
}
