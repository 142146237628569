import Plotly from "plotly.js-dist-min";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../../styles/colors";
import { CenterContainer, SafeCard } from "./Base";
import { Percentile } from "functions/production";
import { fastMax, fastMin } from "utils/utils";
import { useDashboardContext } from "../Dashboard";
import { useAtomValue } from "jotai";
import {
  getInstallationTimeDistribution,
  getInstallationTimePercentiles,
  getInstallationTypesWithFeaturesInPark,
  getNumberOfInstalledFeaturesPerType,
} from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { InstallationCategoryDropdown } from "./InstallationTimeBreakdownWidget";
import {
  getInstallationTimeStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { HOURS_PER_DAY } from "@constants/production";

const InstallationTimeDistributionGraph = ({
  installationType,
  setInstallationType,
}: {
  installationType: InstallationType;
  setInstallationType: React.Dispatch<React.SetStateAction<InstallationType>>;
}) => {
  const { triggerId } = useDashboardContext();

  const numFeatures = useAtomValue(
    getNumberOfInstalledFeaturesPerType({
      id: triggerId,
      type: installationType,
    }),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationTimeStoppedReason(triggerId),
  );

  const installationTypes = useAtomValue(
    getInstallationTypesWithFeaturesInPark({ id: triggerId }),
  );

  useEffect(() => {
    if (installationTypes.length > 0) {
      setInstallationType(installationTypes[0]);
    }
  }, [installationTypes, setInstallationType]);

  if (numFeatures === 0) {
    return (
      <>
        <InstallationCategoryDropdown
          triggerId={triggerId}
          installationType={installationType}
          setInstallationType={setInstallationType}
        />
        <CenterContainer>
          <SimpleAlert
            text={`No ${installationType} ${installationType === InstallationType.Turbine ? "installed at site" : ""} in park. ${installationType === InstallationType.Turbine ? `Floating turbines are assembled at quayside and included in the floating foundation installation.` : ""}`}
            type={"info"}
          />
        </CenterContainer>
      </>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <>
      <InstallationCategoryDropdown
        triggerId={triggerId}
        installationType={installationType}
        setInstallationType={setInstallationType}
      />
      <InstallationTimeDistributionGraphInner
        installationType={installationType}
      />
    </>
  );
};

const InstallationTimeDistributionGraphInner = ({
  installationType,
}: {
  installationType: InstallationType;
}) => {
  const { triggerId } = useDashboardContext();

  const percentiles = useAtomValue(
    getInstallationTimePercentiles({ id: triggerId, type: installationType }),
  );
  const distribution = useAtomValue(
    getInstallationTimeDistribution({ id: triggerId, type: installationType }),
  );

  if (percentiles === undefined || distribution === undefined)
    return (
      <CenterContainer>
        <SimpleAlert
          text={`Installation analysis has failed, please check your input data.`}
          type={"error"}
        />
      </CenterContainer>
    );

  return (
    <InstallationTimeDistributionGraphPlot
      percentiles={percentiles}
      distribution={distribution}
    />
  );
};

const InstallationTimeDistributionGraphPlot = ({
  percentiles,
  distribution,
}: {
  percentiles: Percentile[];
  distribution: { mean: number; std: number };
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const x = percentiles.map((p) => p.value / HOURS_PER_DAY);
    const xmin = fastMin(x);
    const xmax = fastMax(x);
    const step = (xmax - xmin) / (x.length / 2);

    const p10 =
      percentiles.find((p) => p.percentile === 10)!.value / HOURS_PER_DAY;
    const p90 =
      percentiles.find((p) => p.percentile === 90)!.value / HOURS_PER_DAY;

    var data = [
      {
        x,
        name: "Total installation time",
        type: "histogram" as const,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        autobinx: false,
        opacity: 0.8,
        marker: {
          color: colors.hoverSelected,
          line: {
            color: colors.brand,
            width: 1.5,
          },
        },
        xbins: {
          start: xmin - 2 * step,
          size: step,
          end: xmax + 2 * step,
        },
      },
      {
        x: [p90, p90],
        y: [0, 10],
        mode: "lines",
        yaxis: "y2",
        showlegend: false,
        hoverinfo: "none" as const,
        text: "hi",
        textposition: [p90, 4],
        opacity: 0.8,
        marker: {
          color: "black",
        },
      },
      {
        x: [p10, p10],
        y: [0, 10],
        mode: "lines",
        yaxis: "y2",
        showlegend: false,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        opacity: 0.8,
        marker: {
          color: "black",
        },
      },
    ];
    var layout = {
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      legend: {
        x: 1.1,
        xanchor: "right" as const,
        y: 10,
      },
      height: 200,
      bargap: 0.05,
      yaxis: {
        title: "Number of years",
        rangemode: "tozero" as const,
      },
      yaxis2: {
        rangemode: "tozero" as const,
        showgrid: false,
        showticklabels: false,
      } as const,
      xaxis: { title: "Time [days]" },
      margin: {
        l: 60,
        r: 20,
        b: 30,
        t: 30,
      },
      annotations: [
        {
          x: p10,
          y: 10,
          showarrow: false,
          yref: "y2" as const,
          text: `P10: ${(Math.round(10 * p10) / 10).toFixed(1)}`,
          xanchor: "right" as const,
          yanchor: "top" as const,
          textangle: "-90",
        },
        {
          x: p90,
          y: 10,
          text: `P90: ${(Math.round(10 * p90) / 10).toFixed(1)}`,
          showarrow: false,
          yref: "y2" as const,
          xanchor: "left" as const,
          yanchor: "top" as const,
          textangle: "90",
        },
      ],
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, percentiles, distribution]);

  return <div ref={graphRef} />;
};

export const InstallationTimeDistributionWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  const [installationType, setInstallationType] = useState<InstallationType>(
    InstallationType.Turbine,
  );

  return (
    <SafeCard
      title="Installation time variability"
      id="Installation time variability"
      resetKeys={errorBoundaryResetKeys}
    >
      <InstallationTimeDistributionGraph
        installationType={installationType}
        setInstallationType={setInstallationType}
      />
    </SafeCard>
  );
};
