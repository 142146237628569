import { atom, useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, atomFamily, atomFromFn, useJotaiCallback } from "utils/jotai";
import * as spec from "api/dataLayerLibrary";
import { z } from "zod";
import { fetchSchemaWithToken, fetchWithToken } from "services/utils";

export const _SourceType = spec.components.schemas.SourceType;
const _Source = spec.components.schemas.Source;
export type Source = z.infer<typeof _Source>;

const _ListSources =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources"].get;
const _ListP = _ListSources.pathParams;
const _ListRet = _ListSources.responses[200]["application/json"];
export const _PostSource =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources"].post;
const _PostBody = _PostSource.requestBody["application/json"];
const _PostP = _PostSource.pathParams;
const _PostRet = _PostSource.responses[201]["application/json"];

const _DeleteSource =
  spec.paths["/api/datalayerlibrary/{organisationId}/sources/{sourceId}"]
    .delete;
const _DeleteP = _DeleteSource.pathParams;

export const connectExternalModalOpen = atom<boolean>(false);

export const externalSourcesAtom = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<Source[]>>(() =>
      fetchSchemaWithToken(
        _ListRet,
        `/api/datalayerlibrary/${organisationId}/sources?layers=true`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      ),
    ),
);

export const useSourceCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const list = useJotaiCallback(
    async (get, set, p: z.infer<typeof _ListP>) => {
      const sources = await fetchSchemaWithToken(
        _ListRet,
        `/api/datalayerlibrary/${p.organisationId}/sources?layers=true`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      aset(
        get,
        set,
        externalSourcesAtom({
          organisationId,
        }),
        () => sources,
      );
    },
    [organisationId],
  );

  const post = useJotaiCallback(
    async (
      get,
      set,
      body: z.infer<typeof _PostBody>,
      p: z.infer<typeof _PostP>,
    ) => {
      const ret = await fetchSchemaWithToken(
        _PostRet,
        `/api/datalayerlibrary/${p.organisationId}/sources`,
        {
          method: "post",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      aset(
        get,
        set,
        externalSourcesAtom({
          organisationId,
        }),
        (c) => c.concat([ret]),
      );
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, p: z.infer<typeof _DeleteP>) => {
      await fetchWithToken(
        `/api/datalayerlibrary/${p.organisationId}/sources/${p.sourceId}`,
        {
          method: "delete",
        },
      );
      aset(
        get,
        set,
        externalSourcesAtom({
          organisationId,
        }),
        (c) => c.filter((src) => src.id !== p.sourceId),
      );
    },
    [organisationId],
  );

  return {
    list,
    post,
    remove,
  };
};
