import { useAtomValue } from "jotai";
import { designToolTypeAtom } from "state/map";
import { defaultTurbinesForMode } from "types/turbines";

const useDefaultTurbines = () => {
  const mode = useAtomValue(designToolTypeAtom);
  return defaultTurbinesForMode(mode);
};

export default useDefaultTurbines;
