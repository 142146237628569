import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { useMemo } from "react";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { ParkFeature } from "../../types/feature";
import { scream } from "../../utils/sentry";
import PolygonIcon from "@icons/24/Polygon.svg?react";
import { useAtomValue } from "jotai";
import { parksFamily } from "state/jotai/park";

export const ParkSelector = ({
  branchId,
  park,
  setPark,
}: {
  branchId?: string;
  park?: ParkFeature;
  setPark?: (park: ParkFeature) => void;
}) => {
  const parks = useAtomValue(parksFamily({ branchId }));

  const items: DropDownItem[] = useMemo(() => {
    return parks.map((park) => {
      return {
        value: park.id,
        name: park.properties.name ?? "unnamed park",
      };
    });
  }, [parks]);

  const selected = items.find((i) => i.value === park?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const park = parks.find((p) => p.id === e);
        if (!park)
          throw scream(new Error("Selected impossible park"), { parks, e });
        setPark?.(park);
      }}
      disabled={branchId === undefined}
      buttonText={selected?.name ?? "Select park"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <PolygonIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
