import * as turf from "@turf/turf";
import { FillPaint, Map } from "mapbox-gl";
import { useCallback, useMemo } from "react";
import {
  anchorsBufferZoneLayerId,
  anchorsBufferZoneSourceId,
  mooringBufferZoneLayerId,
  mooringBufferZoneSourceId,
  touchdownBufferZoneLayerId,
  touchdownBufferZoneSourceId,
} from "../../constants/projectMapView";
import { branchIdAtom, projectIdAtom } from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { ParkFeature } from "../../types/feature";
import { pencilBufferLineString } from "../../utils/bufferSingleFeature";
import { isDefined, notUndefinedOrNull } from "../../utils/predicates";
import {
  generateCablesSettingState,
  showAnchorBufferZoneAtom,
  showMooringBufferZoneAtom,
  showTouchdownBufferZoneAtom,
} from "../Cabling/Generate/state";
import Polygon from "../MapFeatures/Polygon";
import { useAtomValue } from "jotai";
import { useBathymetry } from "hooks/bathymetry";
import { touchdownPointsInParkFamily } from "state/jotai/touchdown";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";

const anchorBufferPaint: FillPaint = {
  "fill-color": `${colors.redAlert}`,
  "fill-opacity": 0.25,
};

const MooringBufferZoneInner = ({
  map,
  park,
  mooringLineBuffer,
}: {
  map: Map;
  park: ParkFeature;
  mooringLineBuffer?: number;
}) => {
  const branchId = useAtomValue(branchIdAtom);
  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId: park.id, branchId }),
  );

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const zones = useMemo(() => {
    return mooringLines
      .map((ml) =>
        pencilBufferLineString(ml, (mooringLineBuffer ?? 250) / 1000.0, true),
      )
      .filter(isDefined);
  }, [mooringLines, mooringLineBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(mooringBufferZoneSourceId)}
      layerId={addParkId(mooringBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

const AnchorBufferZoneInner = ({
  map,
  park,
  anchorBuffer,
}: {
  map: Map;
  park: ParkFeature;
  anchorBuffer: number;
}) => {
  const branchId = useAtomValue(branchIdAtom);
  const anchors = useAtomValue(
    anchorsInParkFamily({ parkId: park.id, branchId }),
  );

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const zones = useMemo(() => {
    return anchors
      .map((a) =>
        turf.buffer(a, anchorBuffer, {
          units: "meters",
          steps: 3,
        }),
      )
      .filter(notUndefinedOrNull);
  }, [anchors, anchorBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(anchorsBufferZoneSourceId)}
      layerId={addParkId(anchorsBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

const TouchdownBufferZoneInner = ({
  map,
  park,
  touchdownBuffer,
}: {
  map: Map;
  park: ParkFeature;
  touchdownBuffer: number;
}) => {
  const projectId = useAtomValue(projectIdAtom);

  const addParkId = useCallback(
    (str: string) => {
      return `${str}-${park.id}`;
    },
    [park.id],
  );

  const [bath] = useBathymetry({
    projectId,
    featureId: park.id,
    branchId: undefined,
    bufferKm: undefined,
  });
  const bathymetryId = bath.state === "hasData" ? bath.data.id : "";

  const touchdownPoints = useAtomValue(
    touchdownPointsInParkFamily({
      parkId: park.id,
      branchId: undefined,
      bathymetryId,
    }),
  );

  const zones = useMemo(() => {
    return touchdownPoints
      .map((p) =>
        turf.buffer(p, touchdownBuffer, {
          units: "meters",
          steps: 3,
        }),
      )
      .filter(notUndefinedOrNull);
  }, [touchdownPoints, touchdownBuffer]);

  return (
    <Polygon
      features={zones}
      map={map}
      sourceId={addParkId(touchdownBufferZoneSourceId)}
      layerId={addParkId(touchdownBufferZoneLayerId)}
      paint={anchorBufferPaint}
    />
  );
};

export const AnchorBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useAtomValue(showAnchorBufferZoneAtom);
  const settings = useAtomValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <AnchorBufferZoneInner
      park={park}
      map={map}
      anchorBuffer={settings.anchorBuffer ?? 250}
    />
  );
};

export const MooringBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useAtomValue(showMooringBufferZoneAtom);
  const settings = useAtomValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <MooringBufferZoneInner
      park={park}
      map={map}
      mooringLineBuffer={settings.mooringLineBuffer ?? 250}
    />
  );
};

export const TouchdownBufferZone = ({
  map,
  park,
}: {
  map: Map;
  park: ParkFeature;
}) => {
  const show = useAtomValue(showTouchdownBufferZoneAtom);
  const settings = useAtomValue(generateCablesSettingState);
  if (!show || !settings.routeAroundMooring) return null;
  return (
    <TouchdownBufferZoneInner
      park={park}
      map={map}
      touchdownBuffer={settings.touchdownBuffer ?? 100}
    />
  );
};
