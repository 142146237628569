import { useState } from "react";
import { useAtomValue } from "jotai/index";
import { projectIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { useToast } from "hooks/useToast";
import {
  FolderOrResourceItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import {
  createFolder as createFolderRequest,
  deleteFolder as deleteFolderRequest,
  deleteResource as deleteResourceRequest,
  editFolderName as editFolderNameRequest,
  moveItems as moveItemsRequest,
} from "services/branchFoldersService";
import { branchFolderStructureFamily } from "state/branchFolderStructure";
import { RESET } from "jotai/utils";
import { sortFolderTree } from "business/folderStructure/utils";
import { Mixpanel } from "mixpanel";

const useBranchFolderStructureCrud = () => {
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const { error: showError } = useToast();

  const updateLocal = useJotaiCallback(
    (get, set, updatedStructure: FolderOrResourceItem[]) => {
      return aset(get, set, branchFolderStructureFamily({ nodeId }), () => {
        return updatedStructure.sort(sortFolderTree);
      });
    },
    [nodeId],
  );

  const createFolder = useJotaiCallback(
    async (
      get,
      set,
      name: string,
      folderItems: FolderTreeItemOrResourceItem[],
      parentId?: string,
    ) => {
      setIsCreatingFolder(true);
      Mixpanel.track_old(`Branch folders - Create folder`, {
        hasParent: !!parentId,
        nrFolderItems: folderItems.length,
      });

      try {
        const newFolderStructure = await createFolderRequest(
          nodeId,
          name,
          folderItems,
          parentId,
        );

        updateLocal(newFolderStructure);
      } catch (err) {
        showError("Could not create folder, please try again.");
      } finally {
        setIsCreatingFolder(false);
      }
    },
    [nodeId, showError, updateLocal],
  );

  const deleteFolder = useJotaiCallback(
    async (get, set, folderId: string) => {
      Mixpanel.track_old(`Branch folders - Delete folder`, {});
      const fallback = await get(branchFolderStructureFamily({ nodeId }));
      aset(get, set, branchFolderStructureFamily({ nodeId }), (curr) => {
        const clone = curr.filter((f) => f.id !== folderId);
        return clone.sort(sortFolderTree);
      });

      try {
        const _newFolderStructure = await deleteFolderRequest(nodeId, folderId);
        // updateLocal(newFolderStructure);
      } catch (err) {
        aset(get, set, branchFolderStructureFamily({ nodeId }), () => fallback);
        showError("Could not delete folder, please try again.");
      }
    },
    [nodeId, showError],
  );

  const deleteResource = useJotaiCallback(
    async (get, set, resourceId: string) => {
      Mixpanel.track_old(`Branch folders - Delete resource`, {});
      const fallback = await get(branchFolderStructureFamily({ nodeId }));
      aset(get, set, branchFolderStructureFamily({ nodeId }), (curr) => {
        const clone = curr.filter((f) => f.id !== resourceId);
        return clone.sort(sortFolderTree);
      });

      try {
        const _newFolderStructure = await deleteResourceRequest(
          nodeId,
          resourceId,
        );
        // updateLocal(newFolderStructure);
      } catch (err) {
        aset(get, set, branchFolderStructureFamily({ nodeId }), () => fallback);
        showError("Could not delete resource, please try again.");
      }
    },
    [nodeId, showError],
  );

  const editFolderName = useJotaiCallback(
    async (get, set, folderId: string, name: string) => {
      Mixpanel.track_old(`Branch folders - Edit folder name`, {});
      const fallback = await get(branchFolderStructureFamily({ nodeId }));
      aset(get, set, branchFolderStructureFamily({ nodeId }), (curr) => {
        const clone = [...curr];
        const folderItem = clone.find((f) => f.id === folderId);
        if (folderItem) {
          folderItem.name = name;
        }
        return clone.sort(sortFolderTree);
      });

      try {
        const _newFolderStructure = await editFolderNameRequest(
          nodeId,
          folderId,
          name,
        );
      } catch (err) {
        showError("Could not edit folder name, please try again.");
        aset(get, set, branchFolderStructureFamily({ nodeId }), () => fallback);
      }
    },
    [nodeId, showError],
  );

  const moveItems = useJotaiCallback(
    async (
      get,
      set,
      folderItems: Partial<FolderTreeItemOrResourceItem>[],
      parentId: string | undefined,
    ) => {
      Mixpanel.track_old(`Branch folders - Move items`, {
        nrFolderItems: folderItems.length,
        hasParent: !!parentId,
      });
      const fallback = await get(branchFolderStructureFamily({ nodeId }));

      aset(get, set, branchFolderStructureFamily({ nodeId }), (curr) => {
        const clone = [...curr];
        for (const item of folderItems) {
          const folderItem = clone.find((f) => f.id === item.id);
          if (folderItem) {
            folderItem.parentId = parentId;
            if (typeof item.sortOrder === "number") {
              folderItem.sortOrder = item.sortOrder;
            }
          } else {
            clone.push(item as FolderTreeItemOrResourceItem);
          }
        }
        return clone.sort(sortFolderTree);
      });

      try {
        const _newFolderStructure = await moveItemsRequest(
          nodeId,
          folderItems,
          parentId,
        );
      } catch (e) {
        showError("Could not move item(s)");
        aset(get, set, branchFolderStructureFamily({ nodeId }), () => fallback);
      }
    },
    [nodeId, showError],
  );

  const refreshFolders = useJotaiCallback(
    async (get, set) => {
      set(branchFolderStructureFamily({ nodeId }), RESET);
    },
    [nodeId],
  );

  return {
    createFolder,
    editFolderName,
    moveItems,
    deleteFolder,
    deleteResource,
    isCreatingFolder,
    refreshFolders,
    updateLocal,
  };
};

export default useBranchFolderStructureCrud;
