import styled from "styled-components";
import GridCard from "../GridView/GridCard";
import ListView from "../ListView/ListView";
import {
  DataLayerTab,
  ViewMode,
} from "components/DataLayersModal/DataLayersModal";
import { useAtom, useAtomValue } from "jotai";
import { getFavoriteLayersSelector } from "state/layer";
import { Layer } from "types/layers";
import { useCallback } from "react";
import { selectedLayersToAddAtom } from "components/LayerList/LayerSettings/state";
import { Mixpanel } from "mixpanel";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import StarIcon from "@icons/24/Star.svg";
import { colors } from "styles/colors";
import Button from "components/General/Button";

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem 2.2rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StarIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg,
  path {
    stroke: ${colors.iconNegative};
  }
`;

interface FavoriteLayersProps {
  viewMode: ViewMode;
  selectedLayer: Layer | null;
  setSelectedLayer: (layer: Layer | null) => void;
  handleToggleFavorite?: (layerId: string) => void;
  layerIds: string[];
  handleSetSelectedTab: (tab: DataLayerTab) => void;
}

const FavoriteLayers = ({
  viewMode,
  selectedLayer,
  setSelectedLayer,
  handleToggleFavorite,
  layerIds,
  handleSetSelectedTab,
}: FavoriteLayersProps) => {
  const favoriteLayers = useAtomValue(getFavoriteLayersSelector);
  const filteredLayers = favoriteLayers.filter((layer) =>
    layerIds.includes(layer.id),
  );
  const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
    selectedLayersToAddAtom,
  );

  const handleLayerSelection = useCallback(
    (layerId: string) => {
      setSelectedLayersToAdd((prev: string[]) => {
        if (prev.includes(layerId)) {
          return prev.filter((id) => id !== layerId);
        }
        return [...prev, layerId];
      });
      Mixpanel.track_old("layer_selected", {
        layer_type: "vind-suggested",
        layer_id: layerId,
      });
    },
    [setSelectedLayersToAdd],
  );

  if (filteredLayers.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 2rem",
          height: "100%",
        }}
      >
        <EmptyState
          title="No favorites to display yet!"
          description="Click the star icon on the data layer. Favorites will appear here."
          icon={
            <StarIconWrapper>
              <StarIcon />
            </StarIconWrapper>
          }
          style={{
            boxSizing: "border-box",
          }}
          actionButton={
            <Button
              text="Browse layers"
              onClick={() => {
                handleSetSelectedTab(DataLayerTab.EXTERNAL);
              }}
            ></Button>
          }
        />
      </div>
    );
  }

  return (
    <div style={{ padding: "1rem 2rem", height: "100%" }}>
      {viewMode === "grid" ? (
        <GridContainer>
          {filteredLayers.map((layer: Layer) => (
            <GridCard
              key={layer.id}
              layer={layer}
              setSelectedLayer={setSelectedLayer}
              handleLayerSelection={handleLayerSelection}
              handleToggleFavorite={handleToggleFavorite}
              selectedLayersToAdd={selectedLayersToAdd}
            />
          ))}
        </GridContainer>
      ) : (
        <ListView
          layers={favoriteLayers}
          selectedLayer={selectedLayer}
          setSelectedLayer={setSelectedLayer}
          setSelectedLayersToAdd={setSelectedLayersToAdd}
          selectedLayersToAdd={selectedLayersToAdd}
          handleToggleFavorite={handleToggleFavorite}
        />
      )}
    </div>
  );
};

export default FavoriteLayers;
