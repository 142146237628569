import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing6, spacing9 } from "styles/space";
import { customerProjectAtomFamily } from "state/timeline";
import LeftSide from "./components/LeftSide/LeftSide";
import Middle from "./components/Middle/Middle";
import RightSide from "./components/RightSide/RightSide";

const TopbarNavigationWrapper = styled.div`
  height: var(--top-bar-menu-height);
  background-color: ${colors.blue900};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  padding: ${spacing6} ${spacing9};
  box-sizing: border-box;
  z-index: 5;
`;

const TopbarNavigation = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const projectMeta = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  if (!projectMeta) {
    return null;
  }

  return (
    <TopbarNavigationWrapper>
      <LeftSide />
      <Middle />
      <RightSide />
    </TopbarNavigationWrapper>
  );
};

export default TopbarNavigation;
