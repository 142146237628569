import { useSetAtom } from "jotai";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";

export const MemberLink = ({
  organisationId,
  userId,
  style,
  children,
}: React.PropsWithChildren<{
  organisationId: string;
  userId: string;
  style?: React.CSSProperties;
}>) => {
  const navigate = useNavigate();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const [urlSearchParams] = useSearchParams();

  const newSearch = useMemo(() => {
    const searchParams = new URLSearchParams(urlSearchParams);
    return searchParams.toString();
  }, [urlSearchParams]);

  return (
    <a
      href={`/organisation/${organisationId}/members?${newSearch}`}
      onClick={(e) => {
        e.preventDefault();
        navigate({
          pathname: `/organisation/${organisationId}/members`,
          search: newSearch,
        });
        // Something is resetting content when we navigate to organisation, so we need to wait a bit
        setTimeout(() => {
          setContent({
            type: "user",
            id: userId,
          });
        }, 10);
      }}
      style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      {children}
    </a>
  );
};
