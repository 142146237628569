import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { atom } from "jotai";
import {
  CostType,
  isFoundationMaterialCost,
  isFoundationOverrideCost,
  isLibraryFoundationReferenceCost,
  isOperationsCost,
} from "services/costService";
import { listProcurementCostForLibraryFoundationsOnNodeAtomFamily } from "state/jotai/procurementCosts";
import {
  AmountUnit,
  ConfidenceLevel,
  Cost,
  CostUnit,
  _AmountUnit,
  _CostUnit,
  unitToAmountUnit,
} from "types/financial";
import { atomFamily } from "utils/jotai";
import { costAddIds, costId } from "../amounts/costIds";
import { getParkOrSubareaCenter, getProjectId } from "analysis/inputs";
import { pickLocation } from "../amounts/pickLocation";
import { amountId } from "../amounts/amountIds";
import { vesselTypesFamily } from "state/jotai/vesselType";

const floatingFoundationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    const {
      capex: {
        fixed: { foundations },
      },
    } = configuration;

    if (!foundations) return [];
    if (isFoundationOverrideCost(foundations)) return [];

    if (isFoundationMaterialCost(foundations)) {
      const { floating } = foundations;

      if (!floating) return [];

      const steelDefaultCost = costAddIds({
        unit: floating.primarySteel.unit,
        name: "Primary steel floating",
        cost: floating.primarySteel.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.primarySteel.confidenceLevel,
      });

      const concreteDefaultCost = costAddIds({
        unit: floating.concrete.unit,
        name: "Concrete",
        cost: floating.concrete.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.concrete.confidenceLevel,
      });

      const reinforcementDefaultCost = costAddIds({
        name: "Reinforcement",
        unit: floating.reinforcement.unit,
        cost: floating.reinforcement.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.reinforcement.confidenceLevel,
      });

      const postTensionCablesDefaultCost = costAddIds({
        name: "Post tension cables",
        unit: floating.postTensionCables.unit,
        cost: floating.postTensionCables.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.postTensionCables.confidenceLevel,
      });

      const ballastDefaultCost = costAddIds({
        name: "Solid ballast",
        unit: floating.solidBallast.unit,
        cost: floating.solidBallast.cost,
        category: CostType.Foundation,
        confidenceLevel: floating.solidBallast.confidenceLevel,
      });

      return [
        steelDefaultCost,
        concreteDefaultCost,
        reinforcementDefaultCost,
        postTensionCablesDefaultCost,
        ballastDefaultCost,
      ];
    }
    if (isLibraryFoundationReferenceCost(foundations)) {
      const { floatingCostReference } = foundations;
      const projectId = await get(getProjectId(id));

      if (!floatingCostReference)
        throw new Error(
          "No procurement table reference for floating foundations",
        );

      const foundationProcurementCostTables = await get(
        listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
      );

      const floatingProcurementCostTable = foundationProcurementCostTables.find(
        (t) => t.id === floatingCostReference,
      );
      if (!floatingProcurementCostTable)
        throw new Error("Foundation procurement table not found");
      const {
        costs: { floating: floating },
      } = floatingProcurementCostTable;

      const steelDefaultCost = costAddIds({
        unit: _CostUnit.parse(floating.material.primarySteel.unit),
        name: "Primary steel floating",
        cost: floating.material.primarySteel.cost,
        category: CostType.Foundation,
        confidenceLevel: ConfidenceLevel.notSpecified,
      });

      const concreteDefaultCost = costAddIds({
        unit: _CostUnit.parse(floating.material.concrete.unit),
        name: "Concrete",
        cost: floating.material.concrete.cost,
        category: CostType.Foundation,
        confidenceLevel: ConfidenceLevel.notSpecified,
      });

      const reinforcementDefaultCost = costAddIds({
        name: "Reinforcement",
        unit: _CostUnit.parse(floating.material.reinforcement.unit),
        cost: floating.material.reinforcement.cost,
        category: CostType.Foundation,
        confidenceLevel: ConfidenceLevel.notSpecified,
      });

      const postTensionCablesDefaultCost = costAddIds({
        name: "Post tension cables",
        unit: _CostUnit.parse(floating.material.postTensionCables.unit),
        cost: floating.material.postTensionCables.cost,
        category: CostType.Foundation,
        confidenceLevel: ConfidenceLevel.notSpecified,
      });

      const ballastDefaultCost = costAddIds({
        name: "Solid ballast",
        unit: _CostUnit.parse(floating.material.solidBallast.unit),
        cost: floating.material.solidBallast.cost,
        category: CostType.Foundation,
        confidenceLevel: ConfidenceLevel.notSpecified,
      });

      const parkCenter = await get(getParkOrSubareaCenter(id));
      const shipping = pickLocation(floating.shippingCost, parkCenter);

      const shippingCost = {
        name: "Shipping floating foundation",
        id: "foundation_floating_shipping",
        amountId: amountId({
          unit: AmountUnit.unit,
          category: CostType.Foundation,
          featureTypeId: "floating",
        }),
        unit: shipping.unit,
        cost: shipping.cost,
        category: CostType.Foundation,
        confidenceLevel: shipping.confidenceLevel,
      };

      return [
        steelDefaultCost,
        concreteDefaultCost,
        reinforcementDefaultCost,
        postTensionCablesDefaultCost,
        ballastDefaultCost,
        shippingCost,
      ];
    }
    throw new Error("Unknown foundation cost type");
  }),
);

const jacketFoundationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    const {
      capex: {
        fixed: { foundations },
      },
    } = configuration;
    if (!foundations) return [];
    if (isFoundationOverrideCost(foundations)) return [];

    if (isFoundationMaterialCost(foundations)) {
      const { jacket } = foundations;

      if (!jacket) return [];

      const steelDefaultCost = costAddIds({
        name: "Primary steel jacket",
        unit: CostUnit.euroPerT,
        category: CostType.Foundation,
        cost: jacket.primarySteel.cost,
      });

      return [steelDefaultCost];
    }

    if (isLibraryFoundationReferenceCost(foundations)) {
      const { jacketCostReference } = foundations;
      const projectId = await get(getProjectId(id));

      if (!jacketCostReference)
        throw new Error(
          "No procurement table reference for jacket foundations",
        );

      const foundationProcurementCostTables = await get(
        listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
      );

      const jacketProcurementCostTable = foundationProcurementCostTables.find(
        (t) => t.id === jacketCostReference,
      );
      if (!jacketProcurementCostTable)
        throw new Error("Foundation procurement table not found");

      const {
        costs: { jacket: jacket },
      } = jacketProcurementCostTable;

      const steelDefaultCost = costAddIds({
        name: "Primary steel jacket",
        unit: _CostUnit.parse(jacket.material.primarySteel.unit),
        category: CostType.Foundation,
        cost: jacket.material.primarySteel.cost,
      });

      const parkCenter = await get(getParkOrSubareaCenter(id));
      const shipping = pickLocation(jacket.shippingCost, parkCenter);

      const shippingCost = {
        name: "Shipping jacket foundation",
        id: "foundation_jacket_shipping",
        amountId: amountId({
          unit: AmountUnit.unit,
          category: CostType.Foundation,
          featureTypeId: "jacket",
        }),
        unit: shipping.unit,
        cost: shipping.cost,
        category: CostType.Foundation,
        confidenceLevel: shipping.confidenceLevel,
      };

      return [steelDefaultCost, shippingCost];
    }
    throw new Error("Unknown foundation cost type");
  }),
);

const monopileFoundationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    const {
      capex: {
        fixed: { foundations },
      },
    } = configuration;

    if (!foundations) return [];
    if (isFoundationOverrideCost(foundations)) return [];

    if (isFoundationMaterialCost(foundations)) {
      const { monopile } = foundations;

      if (!monopile) return [];

      const steelDefaultCost = costAddIds({
        name: "Primary steel monopile",
        unit: CostUnit.euroPerT,
        category: CostType.Foundation,
        cost: monopile.primarySteel.cost,
      });

      return [steelDefaultCost];
    }
    if (isLibraryFoundationReferenceCost(foundations)) {
      const { monopileCostReference } = foundations;
      const projectId = await get(getProjectId(id));

      if (!monopileCostReference)
        throw new Error(
          "No procurement table reference for monopile foundations",
        );

      const foundationProcurementCostTables = await get(
        listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
      );

      const monopileProcurementCostTable = foundationProcurementCostTables.find(
        (t) => t.id === monopileCostReference,
      );
      if (!monopileProcurementCostTable)
        throw new Error("Foundation procurement table not found");

      const {
        costs: { monopile: monopile },
      } = monopileProcurementCostTable;

      const steelDefaultCost = costAddIds({
        name: "Primary steel monopile",
        unit: _CostUnit.parse(monopile.material.primarySteel.unit),
        category: CostType.Foundation,
        cost: monopile.material.primarySteel.cost,
      });

      const parkCenter = await get(getParkOrSubareaCenter(id));
      const shipping = pickLocation(monopile.shippingCost, parkCenter);
      const shippingCost = {
        name: "Shipping monopile",
        id: "foundation_monopile_shipping",
        amountId: amountId({
          unit: AmountUnit.unit,
          category: CostType.Foundation,
          featureTypeId: "monopile",
        }),
        unit: shipping.unit,
        cost: shipping.cost,
        category: CostType.Foundation,
        confidenceLevel: shipping.confidenceLevel,
      };

      return [steelDefaultCost, shippingCost];
    }
    throw new Error("Unknown foundation cost type");
  }),
);

const floatingInstallationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    if (!isOperationsCost(configuration.capex.installation.foundations))
      return [];

    const operationsConfiguration = await get(getOperationsConfiguration(id));

    const { installationVessel, towingVessel, portCrane } =
      operationsConfiguration.ti.foundations.floaters;

    const vesselTypes = await get(vesselTypesFamily(undefined));

    const installVesselName =
      vesselTypes.get(installationVessel.vesselId)?.name ?? "";
    const towingVesselName = vesselTypes.get(towingVessel.vesselId)?.name ?? "";

    const installVesselCostEntry = {
      id: `floater_installation_install_${installationVessel.vesselId}`,
      amountId: "floater_installation_install_vessel",
      name: installVesselName,
      unit: _CostUnit.parse(installationVessel.dayRate.unit),
      category: CostType.Foundation,
      cost: installationVessel.dayRate.cost,
    };

    //Have three towing vessels, so we multiply the day rate by three
    const towingVesselCostEntry = {
      id: `floater_installation_towing_${towingVessel.vesselId}`,
      amountId: "floater_installation_towing_vessel",
      name: `3 x ${towingVesselName}`,
      unit: _CostUnit.parse(towingVessel.dayRate.unit),
      category: CostType.Foundation,
      cost: 3 * towingVessel.dayRate.cost,
    };

    const portCraneCostEntry = {
      id: `floater_installation_quayside_${portCrane.vesselId}`,
      amountId: "floater_installation_port_crane",
      name: "Port crane",
      unit: _CostUnit.parse(portCrane.dayRate.unit),
      category: CostType.Foundation,
      cost: portCrane.dayRate.cost,
    };

    return [installVesselCostEntry, towingVesselCostEntry, portCraneCostEntry];
  }),
);

const monopileInstallationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    if (!isOperationsCost(configuration.capex.installation.foundations))
      return [];

    const operationsConfiguration = await get(getOperationsConfiguration(id));

    const { installationVessel } =
      operationsConfiguration.ti.foundations.monopiles;

    const vesselTypes = await get(vesselTypesFamily(undefined));

    const { vesselId, dayRate } = installationVessel;
    const { cost, unit: costUnit } = dayRate;
    const unit = _CostUnit.parse(costUnit);
    const name = vesselTypes.get(vesselId)?.name ?? "";

    const installationCosts = {
      id: `monopile_installation_${vesselId}`,
      amountId: amountId({
        unit: unitToAmountUnit[unit],
        category: CostType.Foundation,
        featureTypeId: `monopile_${vesselId}`,
      }),
      category: CostType.Foundation,
      name,
      cost,
      unit,
    };

    return [installationCosts];
  }),
);

const jacketInstallationCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    if (!isOperationsCost(configuration.capex.installation.foundations))
      return [];

    const operationsConfiguration = await get(getOperationsConfiguration(id));

    const { installationVessel, feederVessel } =
      operationsConfiguration.ti.foundations.jackets;

    const vesselTypes = await get(vesselTypesFamily(undefined));

    const installationCosts = [installationVessel, feederVessel].map(
      ({ dayRate, vesselId }) => {
        const { cost, unit: costUnit } = dayRate;
        const unit = _CostUnit.parse(costUnit);
        const name = vesselTypes.get(vesselId)?.name ?? "";

        return {
          id: `jacket_installation_${vesselId}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Foundation,
            featureTypeId: `jacket_${vesselId}`,
          }),
          category: CostType.Foundation,
          name,
          cost,
          unit,
        };
      },
    );

    return installationCosts;
  }),
);

const scourProtectionCostDefaultFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    if (!isOperationsCost(configuration.capex.installation.foundations))
      return [];

    const operationsConfiguration = await get(getOperationsConfiguration(id));

    const { installationVessel } =
      operationsConfiguration.ti.foundations.scourProtection;

    const vesselTypes = await get(vesselTypesFamily(undefined));

    const { vesselId, dayRate } = installationVessel;
    const { cost, unit: costUnit } = dayRate;
    const unit = _CostUnit.parse(costUnit);
    const name = vesselTypes.get(vesselId)?.name ?? "";

    const installationCosts = {
      id: `scour_protection_${vesselId}`,
      amountId: amountId({
        unit: unitToAmountUnit[unit],
        category: CostType.Foundation,
        featureTypeId: vesselId,
      }),
      category: CostType.Foundation,
      name,
      cost,
      unit,
    };

    return [installationCosts];
  }),
);
export const foundationCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    const floatingDefaultCost = await get(
      floatingFoundationCostDefaultFamily(id),
    );
    const monopileDefaultCost = await get(
      monopileFoundationCostDefaultFamily(id),
    );
    const jacketDefaultCost = await get(jacketFoundationCostDefaultFamily(id));

    const floatingInstallationDefaultCost = await get(
      floatingInstallationCostDefaultFamily(id),
    );
    const monopileInstallationDefaultCost = await get(
      monopileInstallationCostDefaultFamily(id),
    );
    const jacketInstallationDefaultCost = await get(
      jacketInstallationCostDefaultFamily(id),
    );
    const scourProtectionDefaultCost = await get(
      scourProtectionCostDefaultFamily(id),
    );

    const {
      capex: {
        fixed: { foundations },
        custom,
        installation,
      },
    } = configuration;

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Foundation)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.Foundation,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Foundation)
      .filter((c) => c.unit !== CostUnit.millionEuro)
      .flatMap((custom) => ({
        ...custom,
        id: costId({
          category: CostType.Foundation,
          costId: custom.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[custom.unit],
          category: custom.category,
        }),
      }));

    let foundationCost: Cost[] = [];
    if (isFoundationOverrideCost(foundations)) {
      const { cost, unit, confidenceLevel } = foundations;
      foundationCost.push({
        id: "foundation",
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Foundation,
        }),
        category: CostType.Foundation,
        name: "Foundations",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    let installationCost: Cost[] = [];

    if (!isOperationsCost(installation.foundations)) {
      const { cost, unit, confidenceLevel } = installation.foundations;
      installationCost.push({
        id: "foundation_installation",
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Foundation,
        }),
        category: CostType.Foundation,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    return [
      ...floatingDefaultCost,
      ...monopileDefaultCost,
      ...jacketDefaultCost,
      ...floatingInstallationDefaultCost,
      ...monopileInstallationDefaultCost,
      ...jacketInstallationDefaultCost,
      ...scourProtectionDefaultCost,
      ...foundationCost,
      ...customCosts,
      ...flatCosts,
      ...installationCost,
    ];
  }),
);
