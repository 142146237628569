import { useCallback, useMemo } from "react";
import {
  ABLY_SUBSTATION_TYPE_UPDATE,
  ABLY_SUBSTATION_TYPE_DELETE,
} from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import useProjectSubstationTypeCrud from "hooks/useProjectSubstationTypeCrud";
import { _SubstationType } from "services/substationService";
import { z } from "zod";

export function useAblyProjectSubstation(projectId: string) {
  const { updateLocal, deleteLocal } = useProjectSubstationTypeCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceivedUpdate = useCallback(
    (message: InboundMessage) => {
      const substation = _SubstationType.parse(message.data.meta);
      updateLocal(projectId, substation);
    },
    [updateLocal, projectId],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const substationId = z.string().parse(message.data.meta);
      deleteLocal(projectId, substationId);
    },
    [deleteLocal, projectId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SUBSTATION_TYPE_UPDATE,
        onMessageReceived: onMessageReceivedUpdate,
      },
      {
        eventName: ABLY_SUBSTATION_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceivedUpdate, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, projectId);
}
