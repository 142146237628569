import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SVGWrapper } from "@icons/svgUtils";
import CloseIcon from "@icons/24/Close.svg";
import FolderIcon from "@icons/24/Folder.svg";
import GroupIcon from "@icons/24/TeamMeeting.svg";
import ProjectGlobeIcon from "@icons/24/ProjectGlobe.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import { Column, Row } from "components/General/Layout";
import {
  getUsersAndAllAccessesForNode,
  nodeGroupUserAccessSelector,
  organisationGroupsState,
  UserWithAllAccesses,
} from "components/Organisation/Groups/state";
import {
  isUserNodeAccessWithMeta,
  isGroupNodeAccessWithMeta,
  Group,
} from "components/Organisation/Groups/types";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  SecondaryText,
  TextEllipsis,
  TabSubtitle,
  Text,
} from "components/Organisation/OrganisationRightSide/style";
import { singleNodeAtomFamily } from "state/timeline";
import {
  ACCESS_ROLE_TO_NUMBER,
  adminInOrganisationSelectorFamily,
  loggedInUserIdAtom,
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { UserNodeAccessDropdown } from "../../shared/UserNodeAccessDropdown";
import { GroupNodeAccessDropdown } from "../../shared/GroupNodeAccessDropdown";
import Button from "components/General/Button";
import UserGroupAccessModal, {
  GroupAccess,
  UserAccess,
} from "../../UserGroupAccessModal";
import { Node } from "services/customerAPI";
import { UserAccessRole } from "types/user";
import Tooltip, { TooltipText } from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { SkeletonBlock } from "components/Loading/Skeleton";
import {
  findTopLevelNode,
  useOrganisationNodeCrud,
} from "components/Projects/useOrganisationFolderCrud";
import { useLeaveNode } from "hooks/useUser";
import { useToast } from "hooks/useToast";
import { sendWarning } from "utils/sentry";
import { IconREMSize, typography } from "styles/typography";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spacing4 } from "styles/space";
import UsersAndGroupsInNodeTree from "./UsersAndGroupsInNodeTree";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import GroupLink from "./GroupLink";
import RoundGroupIcon from "./RoundGroupIcon";
import GroupTag from "components/Organisation/OrganisationRightSide/content/UserContent/GroupTag";
import { NumberOfGroupMembers } from "../../ResourceContent/modals/ResourceAccessModal";
import {
  inviteToOrganisationDefaultEmailValue,
  inviteToOrganisationDefaultSelectedProjectIds,
  inviteToOrganisationOpenAtom,
} from "components/Organisation/state";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const LeaveButton = styled(Button)`
  svg {
    padding: 5px !important;
  }
`;

const ColumnHeader = styled.div`
  ${typography.sub1}
`;

const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 40rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Bold = styled.span<{
  capitalize?: boolean;
}>`
  font-weight: bold;
  ${({ capitalize }) => (capitalize ? "text-transform: capitalize;" : "")}
`;

const UserStillHaveAccessModal = ({
  user,
  groups,
  onClose,
}: {
  user: UserWithAllAccesses;
  groups: Group[];
  onClose(): void;
}) => {
  const removedAccess = user.accesses[0];
  const continuedAccess = user.accesses[1];
  const [elem, setElem] = useState<HTMLDivElement | null>(null);
  const continuedGroup = groups.find((g) => g.id === continuedAccess.group_id);

  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  if (!elem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal onEscape={onClose}>
      <Modal>
        <Row
          alignCenter
          style={{
            justifyContent: "space-between",
          }}
        >
          <h3
            style={{
              margin: 0,
            }}
          >
            User still have access
          </h3>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </Row>
        <span style={typography.caption}>
          You have removed {user.nickname}'s{" "}
          <Bold capitalize>{removedAccess.resource_name}</Bold> access to this
          folder. Note that this user is also given{" "}
          <Bold capitalize>{continuedAccess.resource_name}</Bold> access through
          the group <Bold>{continuedGroup?.name}</Bold> and can therefore still
          collaborate with the items in this folder.
        </span>
        <Row
          style={{
            margin: `${spacing4} 0`,
            padding: spacing4,
            backgroundColor: colors.surfaceSecondary,
            justifyContent: "space-between",
          }}
        >
          <UserInfoOrganisationWithMemberLink
            userId={user.user_id}
            size={3.2}
          />
          <Column>
            <SecondaryText
              style={{
                textDecoration: "line-through",
                textTransform: "capitalize",
              }}
            >
              {removedAccess.resource_name}
            </SecondaryText>
            <Row>
              <Text
                style={{
                  margin: 0,
                  textTransform: "capitalize",
                }}
              >
                {continuedAccess.resource_name}
              </Text>
              <IconREMSize height={1.2} width={1.2}>
                <GroupIcon />
              </IconREMSize>
            </Row>
          </Column>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button buttonType="primary" text="Got it" onClick={onClose} />
        </div>
      </Modal>
    </FullScreenModal>,
    elem,
  );
};

const UsersChangedAccessLevelModal = ({
  users,
  group,
  fromAccessRole,
  toAccessRole,
  onClose,
}: {
  users: UserWithAllAccesses[];
  group: Group;
  fromAccessRole: UserAccessRole;
  toAccessRole?: UserAccessRole;
  onClose(): void;
}) => {
  const [elem, setElem] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  if (!elem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal onEscape={onClose}>
      <Modal>
        <Row
          alignCenter
          style={{
            justifyContent: "space-between",
          }}
        >
          {toAccessRole ? (
            <h3
              style={{
                margin: 0,
              }}
            >
              Changed access
            </h3>
          ) : (
            <h3
              style={{
                margin: 0,
              }}
            >
              Users still have access
            </h3>
          )}
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </Row>
        {toAccessRole ? (
          <span style={typography.caption}>
            You have changed the group <Bold>{group.name}</Bold>'s access from{" "}
            <Bold capitalize>{fromAccessRole}</Bold> to{" "}
            <Bold capitalize>{toAccessRole}</Bold>. Note that some users in this
            group will not be {toAccessRole}s as they are given higher personal
            access.
          </span>
        ) : (
          <span style={typography.caption}>
            You have removed the group <Bold>{group.name}</Bold>'s access to
            this folder. Note that some users in this group are also given
            personal access or other group access to this folder and can
            therefore still collaborate in this folder.
          </span>
        )}
        <ContentTableColumn>
          {users.map((user) => {
            const newAccess = user.accesses[1];
            const removedAccess = user.accesses[0];
            return (
              <Row
                key={user.user_id}
                style={{
                  margin: `${spacing4} 0`,
                  padding: spacing4,
                  backgroundColor: colors.surfaceSecondary,
                  justifyContent: "space-between",
                }}
              >
                <UserInfoOrganisationWithMemberLink
                  userId={user.user_id}
                  size={3.2}
                />
                <Column>
                  <Row>
                    <SecondaryText
                      style={{
                        textDecoration: "line-through",
                        textTransform: "capitalize",
                      }}
                    >
                      {removedAccess.resource_name}
                    </SecondaryText>
                    {removedAccess.group_id && (
                      <IconREMSize height={1.2} width={1.2}>
                        <GroupIcon />
                      </IconREMSize>
                    )}
                  </Row>
                  <Row>
                    <Text
                      style={{
                        margin: 0,
                        textTransform: "capitalize",
                      }}
                    >
                      {newAccess.resource_name}
                    </Text>
                    {newAccess.group_id && (
                      <IconREMSize height={1.2} width={1.2}>
                        <GroupIcon />
                      </IconREMSize>
                    )}
                  </Row>
                </Column>
              </Row>
            );
          })}
        </ContentTableColumn>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button buttonType="primary" text="Got it" onClick={onClose} />
        </div>
      </Modal>
    </FullScreenModal>,
    elem,
  );
};

const FolderTooltipContent = ({ node }: { node: Node }) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const navigate = useNavigate();
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  if (!organisationId) {
    return null;
  }

  return (
    <TooltipText secondary={false} theme="dark">
      Access given to entire folder. Go to{" "}
      <a
        href={`/organisation/${organisationId}`}
        onClick={(e) => {
          e.preventDefault();
          navigate({
            pathname: `/organisation/${organisationId}/projects/${node.id}`,
          });
          // Something is resetting content when we navigate to organisation, so we need to wait a bit
          setTimeout(() => {
            setContent({
              type: "project",
              id: node.id,
            });
          }, 10);
        }}
        style={{
          color: colors.blue300,
        }}
      >
        {node.name}
      </a>{" "}
      to edit.
    </TooltipText>
  );
};

const AllProjectsTooltipContent = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const navigate = useNavigate();

  if (!organisationId) {
    return null;
  }

  return (
    <TooltipText secondary={false} theme="dark">
      This library resource is made available in all projects. Go to{" "}
      <a
        href={`/organisation/${organisationId}/library`}
        onClick={(e) => {
          e.preventDefault();
          navigate({
            pathname: `/organisation/${organisationId}/library`,
          });
        }}
        style={{
          color: colors.blue300,
        }}
      >
        Library
      </a>{" "}
      to edit access.
    </TooltipText>
  );
};

export function Collaborators({
  node,
  openAddCollaborators,
  onAfterRender,
}: {
  node: Node;
  openAddCollaborators: boolean;
  onAfterRender(): void;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodeId = node.id;
  const [showUserStillHaveAccess, setShowUserStillHaveAccess] = useState<
    UserWithAllAccesses | undefined
  >();
  const { showConfirm } = useConfirm();
  const [
    showChangedToPersonalAccessForUsers,
    setShowChangedToPersonalAccessForUsers,
  ] = useState<
    | {
        fromAccessRole: UserAccessRole;
        toAccessRole?: UserAccessRole;
        users: UserWithAllAccesses[];
        group: Group;
      }
    | undefined
  >();
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const groupsAndUsersWithAccess = useAtomValue(
    nodeGroupUserAccessSelector({
      organisationId,
      nodeId,
    }),
  );
  const usersWithExplicitAccess = useMemo(
    () => groupsAndUsersWithAccess.filter(isUserNodeAccessWithMeta),
    [groupsAndUsersWithAccess],
  );
  const groupsWithExplicitAccess = useMemo(
    () => groupsAndUsersWithAccess.filter(isGroupNodeAccessWithMeta),
    [groupsAndUsersWithAccess],
  );

  const allUserAccessesForNode = useAtomValue(
    getUsersAndAllAccessesForNode({
      organisationId,
      nodeId,
    }),
  );

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const currentUserId = useAtomValue(loggedInUserIdAtom);

  const { loadedState: orgNodes } = useNodesInOrganisationState(organisationId);

  const setInviteToOrganisationOpen = useSetAtom(inviteToOrganisationOpenAtom);

  const setDefaultInviteToOrgEmailValue = useSetAtom(
    inviteToOrganisationDefaultEmailValue,
  );
  const setDefaultInviteToOrgProjectIds = useSetAtom(
    inviteToOrganisationDefaultSelectedProjectIds,
  );

  const chosenFolderHasPersonalTopFolder = node
    ? findTopLevelNode(orgNodes, node.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  const { remove: removeGroupNodeAccess, addOrUpdate: addOrUpdateGroup } =
    useGroupNodeAccessCrud();
  const { remove: removeUserNodeAccess, addOrUpdate: addOrUpdateUser } =
    useUserNodeAccessCrud();

  const { removeLocal } = useOrganisationNodeCrud();
  const { error } = useToast();

  const leaveNode = useLeaveNode(nodeId);
  const _leaveNode = useCallback(async () => {
    const adminsWithoutMe = groupsAndUsersWithAccess.filter(
      (a) => a.resource_name === "admin" && a.user_id !== currentUserId,
    );
    if (adminsWithoutMe.length === 0) {
      error("You are the only admin in this project. You cannot leave.");
      return;
    }

    if (
      !(await showConfirm({
        title: "Leave project",
        message: "Are you sure you want to leave this project?",
        confirmButtonText: "Leave",
      }))
    ) {
      return;
    }

    return leaveNode()
      .then(() => {
        removeLocal(nodeId);
        document.location.reload();
      })
      .catch((e) => {
        error("Failed to leave project");
        sendWarning("failed to leave project", e);
      });
  }, [
    currentUserId,
    error,
    groupsAndUsersWithAccess,
    leaveNode,
    nodeId,
    removeLocal,
    showConfirm,
  ]);

  const [showAddProject, setShowAddProject] = useState(openAddCollaborators);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (openAddCollaborators) {
      setShowAddProject(true);
    }
  }, [openAddCollaborators]);

  useEffect(() => {
    onAfterRender();
  }, [onAfterRender]);

  const onAddAccess = useCallback(
    async (
      access: {
        users: UserAccess[];
        groups: GroupAccess[];
      },
      accessRole: UserAccessRole,
    ) => {
      setSaveInProgress(access.groups.length + access.users.length);
      setShowAddProject(false);
      try {
        await Promise.all([
          ...access.groups.map((groupId) =>
            addOrUpdateGroup(groupId, nodeId, accessRole).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
          ...access.users.map((userId) =>
            addOrUpdateUser(userId, nodeId, accessRole).finally(() =>
              setSaveInProgress((cur) => cur - 1),
            ),
          ),
        ]);
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdateGroup, addOrUpdateUser, nodeId],
  );

  const enableAddButton = isNodeAdmin || isAdminInOrg;

  return (
    <Column
      style={{
        gap: "1.6rem",
        height: "100%",
      }}
    >
      {showUserStillHaveAccess && (
        <UserStillHaveAccessModal
          user={showUserStillHaveAccess}
          groups={groups}
          onClose={() => {
            setShowUserStillHaveAccess(undefined);
          }}
        />
      )}

      {showChangedToPersonalAccessForUsers && (
        <UsersChangedAccessLevelModal
          users={showChangedToPersonalAccessForUsers.users}
          group={showChangedToPersonalAccessForUsers.group}
          fromAccessRole={showChangedToPersonalAccessForUsers.fromAccessRole}
          toAccessRole={showChangedToPersonalAccessForUsers.toAccessRole}
          onClose={() => {
            setShowChangedToPersonalAccessForUsers(undefined);
          }}
        />
      )}
      <Row
        style={{
          alignItems: "center",
        }}
      >
        <TabSubtitle>Overview of collaborators in {node.name}.</TabSubtitle>
        <div
          style={{
            marginLeft: "auto",
            position: "relative",
          }}
        >
          <Tooltip
            text={
              chosenFolderHasPersonalTopFolder
                ? "Personal folders and projects cannot be shared"
                : "Organisation admin or project admin can add collaborators."
            }
          >
            <Button
              text={node.type === "project" ? "Share project" : "Share folder"}
              buttonType="secondary"
              onClick={() => setShowAddProject((cur) => !cur)}
              disabled={
                saveInProgress > 0 ||
                !enableAddButton ||
                chosenFolderHasPersonalTopFolder
              }
            />
          </Tooltip>
          {showAddProject && (
            <div
              style={{
                position: "absolute",
                right: "0.8rem",
                zIndex: 1,
              }}
            >
              <UserGroupAccessModal
                resourceName={node.name}
                autoFocus={!openAddCollaborators}
                existingGroups={groupsWithExplicitAccess}
                existingUsers={usersWithExplicitAccess}
                onSave={onAddAccess}
                onCancel={() => setShowAddProject(false)}
                onShowInviteToOrganisationClick={
                  isMemberInOrg
                    ? (defaultInputValue) => {
                        setShowAddProject(false);
                        setInviteToOrganisationOpen(true);
                        setDefaultInviteToOrgEmailValue(defaultInputValue);
                        setDefaultInviteToOrgProjectIds([nodeId]);
                      }
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </Row>

      <Row>
        <ColumnHeader
          style={{
            margin: 0,
            flex: "1 1 57%",
          }}
        >
          Collaborators
        </ColumnHeader>
        <ColumnHeader
          style={{
            margin: 0,
            flex: "1 1 50%",
          }}
        >
          Access
        </ColumnHeader>
      </Row>
      <ContentTableColumn>
        {allUserAccessesForNode.map((ua) => {
          const access = ua.accesses[0];
          const accessFromThisNode = access.node_id === nodeId;
          const isCurrentUser = ua.user_id === currentUserId;
          const group = access.group_id
            ? groups.find((g) => g.id === access.group_id)
            : undefined;
          return (
            <ContentTableRow
              key={ua.user_id + access.resource_name + access.node_id}
              style={{
                justifyContent: "space-between",
                cursor: "unset",
              }}
              disabled={deleteInProgress === ua.user_id}
            >
              <UserInfoOrganisationWithMemberLink
                style={{
                  flex: "0 0 50%",
                }}
                userId={ua.user_id}
                size={3.2}
              />
              {access.node_id ? (
                <>
                  <Row
                    style={{
                      flex: "1 0 20%",
                    }}
                  >
                    {accessFromThisNode ? (
                      <UserNodeAccessDropdown
                        role={access.resource_name}
                        nodeId={access.node_id!}
                        userId={ua.user_id}
                      />
                    ) : (
                      <>
                        <SecondaryText
                          style={{
                            margin: 0,
                            textTransform: "capitalize",
                            paddingLeft: "1rem",
                          }}
                        >
                          {access.resource_name}
                        </SecondaryText>
                        <NodeLink
                          nodeId={access.node_id}
                          showNodeName={false}
                        />
                      </>
                    )}
                  </Row>
                  <Row
                    style={{
                      width: "8rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    {accessFromThisNode && (
                      <>
                        {isCurrentUser ? (
                          <LeaveButton
                            text="Leave"
                            buttonType="text"
                            size="small"
                            iconPosition="right"
                            onClick={_leaveNode}
                            icon={
                              <IconREMSize height={1.4} width={1.4}>
                                <RemoveIcon />
                              </IconREMSize>
                            }
                          />
                        ) : (
                          (isNodeAdmin || isAdminInOrg) && (
                            <Tooltip text="Remove access">
                              <IconBtn
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  if (
                                    !(await showConfirm({
                                      title: "Remove access",
                                      message:
                                        "Are you sure you want to remove the access for this user?",
                                      confirmButtonText: "Remove",
                                    }))
                                  ) {
                                    return;
                                  }
                                  setDeleteInProgress(ua.user_id);
                                  removeUserNodeAccess(
                                    ua.user_id,
                                    access.node_id!,
                                  )
                                    .then(() => {
                                      if (ua.accesses.length > 1) {
                                        setShowUserStillHaveAccess(ua);
                                      }
                                    })
                                    .finally(() =>
                                      setDeleteInProgress(undefined),
                                    );
                                }}
                                size={"1.4rem"}
                                disabled={deleteInProgress === ua.user_id}
                              >
                                <RemoveIcon />
                              </IconBtn>
                            </Tooltip>
                          )
                        )}
                      </>
                    )}
                  </Row>
                </>
              ) : (
                access.group_id && (
                  <Row
                    style={{
                      flex: "1 0 50%",
                      alignItems: "center",
                    }}
                  >
                    <SecondaryText
                      style={{
                        ...typography.body,
                        margin: 0,
                        textTransform: "capitalize",
                        paddingLeft: "0.8rem",
                      }}
                    >
                      {access.resource_name}
                    </SecondaryText>
                    <GroupTag
                      organisationId={organisationId}
                      size="compact"
                      group={group}
                    />
                  </Row>
                )
              )}
            </ContentTableRow>
          );
        })}

        {groupsWithExplicitAccess.map((groupWithAccess) => {
          const group = groups.find((g) => g.id === groupWithAccess.group_id);
          const accessFromThisNode = groupWithAccess.node_id === nodeId;
          if (!group) {
            return null;
          }

          return (
            <React.Fragment key={group.id}>
              <ContentTableRow
                style={{
                  justifyContent: "space-between",
                  minHeight: "5.2rem",
                  cursor: "unset",
                }}
                disabled={deleteInProgress === groupWithAccess.group_id}
              >
                <Row
                  style={{
                    gap: "0.8rem",
                    alignItems: "center",
                    flex: "1 0 50%",
                    overflow: "hidden",
                  }}
                >
                  <GroupLink organisationId={organisationId} groupId={group.id}>
                    <RoundGroupIcon />
                  </GroupLink>

                  <GroupLink
                    organisationId={organisationId}
                    groupId={group.id}
                    style={{
                      overflow: "hidden",
                      textDecoration: "none",
                    }}
                  >
                    <TextEllipsis
                      style={{
                        ...typography.body,
                        margin: 0,
                      }}
                    >
                      {group.name}
                      <NumberOfGroupMembers
                        groupId={group.id}
                        organisationId={organisationId}
                      ></NumberOfGroupMembers>
                    </TextEllipsis>
                  </GroupLink>
                </Row>
                <Row
                  style={{
                    flex: "0 0 30%",
                  }}
                >
                  {accessFromThisNode ? (
                    <GroupNodeAccessDropdown
                      groupId={groupWithAccess.group_id}
                      nodeId={groupWithAccess.node_id}
                      role={groupWithAccess.resource_name}
                      onAfterChangeAccessRole={(
                        groupId,
                        _nodeId,
                        newAccessRole,
                      ) => {
                        const group = groups.find((g) => g.id === groupId);
                        if (!group) {
                          return;
                        }

                        // Find users that has groupId as first access role, and has more access roles under
                        const usersThatHadThisGroupAsHighestAccess =
                          allUserAccessesForNode.filter(
                            (u) =>
                              u.accesses[0].group_id === groupId &&
                              u.accesses.length > 1,
                          );

                        const usersThatWillHaveNewHighestAccess =
                          usersThatHadThisGroupAsHighestAccess.filter(
                            (user) => {
                              return (
                                user.accesses[1].node_id &&
                                ACCESS_ROLE_TO_NUMBER[
                                  user.accesses[1].resource_name
                                ] > ACCESS_ROLE_TO_NUMBER[newAccessRole]
                              );
                            },
                          );
                        if (usersThatWillHaveNewHighestAccess.length > 0) {
                          setShowChangedToPersonalAccessForUsers({
                            group,
                            fromAccessRole: groupWithAccess.resource_name,
                            toAccessRole: newAccessRole,
                            users: usersThatWillHaveNewHighestAccess,
                          });
                        }
                      }}
                    />
                  ) : (
                    <>
                      <SecondaryText
                        style={{
                          margin: 0,
                          textTransform: "capitalize",
                          paddingLeft: "1rem",
                        }}
                      >
                        {groupWithAccess.resource_name}
                      </SecondaryText>
                      <NodeLink
                        nodeId={groupWithAccess.node_id}
                        showNodeName={false}
                      />
                    </>
                  )}
                </Row>
                <Row
                  style={{
                    width: "8rem",
                    justifyContent: "flex-end",
                  }}
                >
                  {accessFromThisNode && (
                    <>
                      {(isNodeAdmin || isAdminInOrg) && (
                        <Tooltip text={"Remove access"}>
                          <IconBtn
                            disabled={
                              deleteInProgress === groupWithAccess.group_id
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                !confirm(
                                  "Are you sure you want to remove the access for this group?",
                                )
                              ) {
                                return;
                              }
                              setDeleteInProgress(groupWithAccess.group_id);

                              const group = groups.find(
                                (g) => g.id === groupWithAccess.group_id,
                              );
                              if (!group) {
                                return;
                              }

                              const usersThatHadThisGroupAsHighestAccessThatWillNowStillHaveAccess =
                                allUserAccessesForNode.filter(
                                  (u) =>
                                    u.accesses[0].group_id ===
                                      groupWithAccess.group_id &&
                                    u.accesses.length > 1 &&
                                    u.accesses[1].resource_name !==
                                      groupWithAccess.resource_name,
                                );

                              removeGroupNodeAccess(
                                groupWithAccess.group_id,
                                groupWithAccess.node_id,
                              )
                                .then(() => {
                                  if (
                                    usersThatHadThisGroupAsHighestAccessThatWillNowStillHaveAccess.length >
                                    0
                                  ) {
                                    setShowChangedToPersonalAccessForUsers({
                                      group,
                                      fromAccessRole:
                                        groupWithAccess.resource_name,
                                      users:
                                        usersThatHadThisGroupAsHighestAccessThatWillNowStillHaveAccess,
                                    });
                                  }
                                })
                                .finally(() => setDeleteInProgress(undefined));
                            }}
                            size={"1.4rem"}
                          >
                            <RemoveIcon />
                          </IconBtn>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Row>
              </ContentTableRow>
            </React.Fragment>
          );
        })}
        {Array.from(
          {
            length: saveInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "1rem",
              }}
            />
          ),
        )}
      </ContentTableColumn>
      <React.Suspense fallback={<SkeletonBlock />}>
        <UsersAndGroupsInNodeTree
          organisationId={organisationId}
          nodeId={nodeId}
          usersWithAccessToNode={usersWithExplicitAccess}
          groupsWithAccessToNode={groupsWithExplicitAccess}
        />
      </React.Suspense>
    </Column>
  );
}

export function NodeLink({
  nodeId,
  showNodeName = true,
}: {
  nodeId: string;
  showNodeName?: boolean;
}) {
  const node = useAtomValue(
    singleNodeAtomFamily({
      nodeId,
    }),
  );

  if (!node) {
    return null;
  }

  const isOrg = node.type === "organisation";

  return (
    <Tooltip
      content={
        !isOrg ? (
          <FolderTooltipContent node={node} />
        ) : (
          <AllProjectsTooltipContent />
        )
      }
      text=""
      closeDelay={100}
      interactive={true}
      delay={100}
    >
      <Row
        style={{
          alignItems: "center",
          gap: "0.4rem",
        }}
      >
        <SVGWrapper size={1.6}>
          {isOrg ? <ProjectGlobeIcon /> : <FolderIcon />}
        </SVGWrapper>
        {showNodeName && <Text>{isOrg ? "All projects" : node.name}</Text>}
      </Row>
    </Tooltip>
  );
}
