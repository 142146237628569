import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  UserNotificationSettings,
  getUserNotificationSettings,
} from "../services/userService";
import { fetchSchemaWithToken } from "../services/utils";
import {
  LoggedInUser,
  OrganisationAccessRole,
  UserAccessRole,
  UserNodesAccess,
  _LoggedInUser,
  _ProjectType,
  _UserMetaInfo,
  _UserNodesAccess,
} from "../types/user";
import { parseJWT } from "../utils/jwt";
import { sendWarning } from "../utils/sentry";
import { isInChecklyMode } from "../utils/utils";
import { organisationIdAtom, projectIdAtom } from "./pathParams";
import { atom as atomJ } from "jotai";
import { OrgResource } from "components/Organisation/OrganisationRightSide/types";

export const LOCAL_STORAGE_TOKEN_KEY = "access_token";

export const ORGANISATION_ACCESS_ROLE_TO_NUMBER: Record<
  OrganisationAccessRole | "null" | "undefined",
  number
> = {
  undefined: -10,
  null: -10,
  guest: 0,
  member: 1,
  admin: 2,
  owner: 3,
};

export const ACCESS_ROLE_TO_NUMBER: Record<
  UserAccessRole | "public" | "null" | "undefined",
  number
> = {
  undefined: -10,
  null: -10,
  public: -1,
  viewer: 0,
  editor: 1,
  admin: 2,
};

export class LoggedInUserNotFoundError extends Error {
  name = "LoggedInUserNotFoundError";
  extra: any;
  constructor(msg: string, _extra?: Record<any, any>) {
    super(msg);
    this.extra = _extra;
  }
}

export const getTokenAtom = atom<string | undefined>(undefined);

export const tokenAtom = atomJ<string | undefined>(undefined);
export const loggedInUserAtom = atomJ((get) => {
  const token = get(tokenAtom);
  if (!token) return;
  const parsedToken = parseJWT(token);

  const user: LoggedInUser = {
    nickname:
      parsedToken["https://app.vind.ai/nickname"] ??
      parsedToken["https://app.vind.ai/email"],
    picture: parsedToken["https://app.vind.ai/picture"] ?? undefined,
    hmac: parsedToken["https://app.vind.ai/hmac"],
    user_id: parsedToken["https://app.vind.ai/user_id"],
    email: parsedToken["https://app.vind.ai/email"],
    consents: parsedToken["https://app.vind.ai/consents"],
    interests: parsedToken["https://app.vind.ai/interests"],
    allow_news: parsedToken["https://app.vind.ai/allow_news"],
    accept_terms_of_use: parsedToken["https://app.vind.ai/accept_terms_of_use"],
    allow_interest_specific_news:
      parsedToken["https://app.vind.ai/allow_interest_specific_news"],
    internalRole: parsedToken["https://app.vind.ai/internal"] === true,
    isTemporaryToken:
      parsedToken["https://app.vind.ai/temporary_token"] === true,
    logins_count: parsedToken["https://app.vind.ai/metadata/logins_count"] ?? 0,
  };

  const res = _LoggedInUser.safeParse(user);
  if (!res.success && !isInChecklyMode()) {
    sendWarning("user object from token doesnt match our type", {
      error: res.error,
    });
  }

  return user;
});

export const loggedInUserIdAtom = atom((get) => get(loggedInUserAtom)?.user_id);

export const getCompanyNameFromEmail = (email?: string) => {
  return email?.split("@")?.[1]?.split(".")[0];
};

// jotai:
export const loggedInUserIsInternalSelector = atom<boolean>((get) => {
  const user = get(loggedInUserAtom);
  const emailDomain = user?.email?.split("@")?.[1];
  return user?.internalRole || emailDomain === "vind.ai";
});

export const loggedInUserIsCheckly = atom<boolean>((get) => {
  return get(loggedInUserIdAtom) === "auth0|639ad688a9d225d29157bdb6";
});

export const userNodesAccessSyncAtom = atom<{
  initialised: boolean;
  loading: boolean;
  data: UserNodesAccess;
}>({
  initialised: false,
  loading: false,
  data: { organisation_access: {}, node_access: {} },
});

// Org access
export const userOrganisationAccessSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom<number>((get) => {
      const userAccess = get(userNodesAccessSyncAtom).data;

      if (!userAccess) return ORGANISATION_ACCESS_ROLE_TO_NUMBER["null"];

      const orgAccess = userAccess["organisation_access"][organisationId];

      if (!orgAccess) return ORGANISATION_ACCESS_ROLE_TO_NUMBER["null"];

      return ORGANISATION_ACCESS_ROLE_TO_NUMBER[orgAccess];
    }),
);

function hasCorrectOrgAccessLevel(
  accessInOrg: number,
  role: OrganisationAccessRole | "null" | "undefined",
) {
  return accessInOrg >= ORGANISATION_ACCESS_ROLE_TO_NUMBER[role];
}

export const ownerInOrganisationSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom<boolean>((get) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({
          organisationId,
        }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "owner");
    }),
);
export const adminInOrganisationSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom<boolean>((get) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({
          organisationId,
        }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "admin");
    }),
);
export const memberInOrganisationSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom<boolean>((get) => {
      if (!organisationId) return false;
      const accessInOrg = get(
        userOrganisationAccessSelectorFamily({
          organisationId,
        }),
      );
      return hasCorrectOrgAccessLevel(accessInOrg, "member");
    }),
);

const userHaveNodeAccess = (userAccess: UserNodesAccess, nodeId?: string) => {
  if (!nodeId) return ACCESS_ROLE_TO_NUMBER["undefined"];
  if (!userAccess) return ACCESS_ROLE_TO_NUMBER["public"];
  const nodeAccess = userAccess["node_access"][nodeId];
  if (!nodeAccess) return ACCESS_ROLE_TO_NUMBER["public"];

  return ACCESS_ROLE_TO_NUMBER[nodeAccess];
};

export const userHaveEditorNodeAccess = (
  userAccess: UserNodesAccess,
  nodeId?: string,
) => userHaveNodeAccess(userAccess, nodeId) >= ACCESS_ROLE_TO_NUMBER["editor"];

export const userHaveAdminNodeAccess = (
  userAccess: UserNodesAccess,
  nodeId?: string,
) => userHaveNodeAccess(userAccess, nodeId) >= ACCESS_ROLE_TO_NUMBER["admin"];

export const userNodeAccessSelectorFamily = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atom<number>((get) => {
      const access = get(userNodesAccessSyncAtom).data;
      return userHaveNodeAccess(access, nodeId);
    }),
);

export const userNodeAccessNameSelectorFamily = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atom<string | undefined>((get) => {
      if (!nodeId) {
        return;
      }
      const userAccess = get(userNodesAccessSyncAtom).data;

      if (!userAccess) {
        return;
      }

      const nodeAccess = userAccess["node_access"][nodeId];
      if (!nodeAccess) {
        return;
      }
      return nodeAccess;
    }),
);

// Project access
export const adminAccessProjectSelector = atom<boolean>((get) => {
  const projectId = get(projectIdAtom);

  if (!projectId) return false;

  return (
    get(
      userNodeAccessSelectorFamily({
        nodeId: projectId,
      }),
    ) >= ACCESS_ROLE_TO_NUMBER["admin"]
  );
});
export const editorAccessProjectSelector = atom<boolean>((get) => {
  const projectId = get(projectIdAtom);

  if (!projectId) return false;
  return (
    get(
      userNodeAccessSelectorFamily({
        nodeId: projectId,
      }),
    ) >= ACCESS_ROLE_TO_NUMBER["editor"]
  );
});

export const viewerAccessProjectSelector = atom<boolean>((get) => {
  const projectId = get(projectIdAtom);

  if (!projectId) return false;
  return (
    get(
      userNodeAccessSelectorFamily({
        nodeId: projectId,
      }),
    ) >= ACCESS_ROLE_TO_NUMBER["viewer"]
  );
});

export const loggedInUserMetaInfoAtom = atomFromFn(() => {
  return fetchSchemaWithToken(_UserMetaInfo, `/api/user/metadata`, {
    method: "get",
    headers: {},
  });
});

// Org resource access for current user

export const userOrganisationResourcesSyncAtom = atomFamily<
  { organisationId: string },
  {
    data: OrgResource[];
    loading: boolean;
    initialised: boolean;
  }
>(() =>
  atom<{
    data: OrgResource[];
    loading: boolean;
    initialised: boolean;
  }>({
    data: [],
    loading: false,
    initialised: false,
  }),
);

export const orgTurbineManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);
  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_turbine_manage");
});

export const orgSubstationManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_substation_manage");
});

export const orgFoundationManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_foundation_manage");
});

export const orgAnalysisManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_analysis_manage");
});

export const orgCableManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_cable_manage");
});

export const orgExportcableManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some(
    (r) => r["resource_name"] === "org_export_cable_manage",
  );
});

export const orgFinanicalManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some((r) => r["resource_name"] === "org_financial_manage");
});

export const orgDataPackagesManageAccessSelector = atom<boolean>((get) => {
  const organisationId = get(organisationIdAtom);

  if (!organisationId) return false;

  const state = get(
    userOrganisationResourcesSyncAtom({
      organisationId,
    }),
  );

  const { data: resources, loading, initialised } = state;

  if (loading || !initialised) return false;

  return resources.some(
    (r) => r["resource_name"] === "org_data_package_manage",
  );
});

// True used as default value if a setting is not set yet
export const NotificationSettingsAtom = atomFromFn<
  Promise<UserNotificationSettings | undefined>
>(async () => {
  const settings = await getUserNotificationSettings();
  return {
    ...settings,
    email_on_mention: settings.email_on_mention ?? true,
    email_on_comment_in_followed_thread:
      settings.email_on_comment_in_followed_thread ?? true,
    email_on_snapshot_created: settings.email_on_snapshot_created ?? true,
    email_on_branch_created: settings.email_on_branch_created ?? true,
    email_on_project_created: settings.email_on_project_created ?? true,
    email_on_added_to_node: settings.email_on_added_to_node ?? true,
  };
});
