import { ModalFrame } from "components/General/Layout";
import { SecondaryText } from "components/SettingsV2/Shared/styles";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { colors } from "styles/colors";
import styled from "styled-components";
import { typography } from "styles/typography";
import { spacing4, spacing8 } from "styles/space";
import { CableType } from "services/cableTypeService";

const Header = styled.div`
  p {
    font-weight: bold;
    text-align: right;
    padding: 0.1rem 0.5rem;
  }

  p:first-child {
    text-align: left;
  }
`;

const CableListItem = styled.div<{ $isEven?: boolean; disabled?: boolean }>`
  background-color: ${(props) =>
    props.$isEven ? colors.surfaceSecondary : "transparent"};
  padding: ${spacing4} ${spacing8};

  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      background: ${colors.surfaceHover};
      cursor: pointer;
    }
  `}

  p {
    text-align: right;
    padding: 0.1rem 0.3rem;
  }

  p:first-child {
    text-align: left;
  }
`;

const HeaderCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  &:first-child {
    align-items: flex-start;
  }
`;

const Unit = styled.span`
  ${typography.sub2}
  color: ${colors.textDisabled};
`;

const Title = styled.span`
  ${typography.sub2}
`;

interface CableTemplatesModalModalProps {
  onClose: () => void;
  defaultCables: Record<string, any>;
  onSelectCable: (props: CableType) => void;
  disabled?: boolean;
  isExportCable?: boolean;
  isHVDC?: boolean;
}

const CableTemplatesModal = ({
  onClose,
  defaultCables,
  onSelectCable,
  disabled = false,
  isExportCable = false,
  isHVDC = false,
}: CableTemplatesModalModalProps) => {
  return (
    <ModalFrame
      onExit={onClose}
      title="Vind AI cable templates"
      style={{
        height: "auto",
        maxHeight: "80vh",
        width: "80rem",
        overflowY: "auto",
      }}
    >
      {Object.keys(defaultCables).length === 0 ? (
        <SimpleAlert
          text="No standard cable types available for the specified voltage."
          type="info"
        />
      ) : (
        <>
          <SecondaryText style={{ padding: "1.6rem 1.6rem 2rem 1.6rem" }}>
            Use values from the {isExportCable ? "export" : "inter array"} cable
            templates below. Click on a row to use the corresponding values
          </SecondaryText>

          <Header>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "3fr 1.5fr 1.5fr 1.5fr 1.5fr",
                borderBottom: `1px solid ${colors.blue500}`,
                paddingBottom: "0.8rem",
              }}
            >
              <HeaderCell>
                <Title>Name</Title>
              </HeaderCell>
              <HeaderCell>
                <Unit>(ohm per km)</Unit>
                <Title>Resistance</Title>
              </HeaderCell>
              {!isHVDC && (
                <>
                  <HeaderCell>
                    <Unit>(ohm per km)</Unit>
                    <Title>Reactance</Title>
                  </HeaderCell>
                  <HeaderCell>
                    <Unit>(nF per km)</Unit>
                    <Title>Capacitance</Title>
                  </HeaderCell>
                  <HeaderCell>
                    <Unit>(kilo Ampere)</Unit>
                    <Title>Ampacity</Title>
                  </HeaderCell>
                </>
              )}
            </div>
          </Header>

          {Object.entries(defaultCables).map(([name, props], index) => (
            <CableListItem
              key={name}
              $isEven={index % 2 === 0}
              disabled={disabled}
              onClick={() => !disabled && onSelectCable(props)}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "3fr 1.5fr 1.5fr 1.5fr 1.5fr",
                }}
              >
                <p>{name}</p>
                <p>{props.resistance}</p>
                <p>{props.reactance}</p>
                <p>{props.capacitance}</p>
                <p>{props.ampacity}</p>
              </div>
            </CableListItem>
          ))}
        </>
      )}
    </ModalFrame>
  );
};

export default CableTemplatesModal;
