import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  useState,
  useRef,
  useEffect,
  Suspense,
  ReactNode,
  useMemo,
} from "react";
import { customerProjectAtomFamily } from "state/timeline";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { SkeletonBlock, SkeletonText } from "../../Loading/Skeleton";
import {
  ExtraContainer,
  Number,
  Name,
  UsageContainer,
  UsageContainerPlacement,
  NumberWrapper,
  SubHeader,
  UsageContainerLeftRightPlacement,
  Tag,
} from "./style";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { ResourceTypeUsage } from "services/usageService";
import { DetailedProjectAndBranchUsage } from "./ProjectAndBranchUsage";

export function UsageContainerInner2({
  loadable,
  children,
  text,
  icon,
  typeName,
  placement = UsageContainerPlacement.TOP,
  wrapperStyle,
}: {
  text?: string;
  icon?: React.ReactNode;
  typeName?: string;
  loadable: Loadable<any>;
  children: ReactNode;
  placement?: UsageContainerPlacement;
  wrapperStyle?: React.CSSProperties;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild, setIsMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasData") {
    return (
      <UsageContainer>
        <SkeletonBlock
          style={{
            width: "100%",
            height: "2rem",
          }}
        />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer
      style={wrapperStyle}
      onMouseEnter={() => setIsMouseOverParent(true)}
      onMouseLeave={() => setIsMouseOverParent(false)}
    >
      <Tag>
        {icon ? (
          icon
        ) : (
          <p
            style={{
              margin: 0,
            }}
          >
            {text ?? "Used:"}{" "}
          </p>
        )}
        {loadable.data.length} {typeName ?? "times"}
        {loadable.data.length > 0 && showChild && (
          <ExtraContainer
            onMouseEnter={() => setIsMouseOverChild(true)}
            onMouseLeave={() => setIsMouseOverChild(false)}
            placement={placement}
          >
            <Suspense
              fallback={
                <SkeletonText
                  style={{
                    width: "4rem",
                    height: "2rem",
                  }}
                />
              }
            >
              {children}
            </Suspense>
          </ExtraContainer>
        )}
      </Tag>
    </UsageContainer>
  );
}

export function UsageContainerInner({
  loadable,
  children,
  text,
  typeName,
  placement,
  placementLeftRight,
  noHover = false,
}: {
  text?: string;
  typeName?: string;
  loadable: Loadable<any>;
  children: ReactNode;
  placement?: UsageContainerPlacement;
  placementLeftRight?: UsageContainerLeftRightPlacement;
  noHover?: boolean;
}) {
  const [isMouseOverParent, setIsMouseOverParent] = useState(false);
  const [isMouseOverChild] = useState(false);

  const hideTimeout = useRef<NodeJS.Timeout>();

  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    if (!isMouseOverParent && !isMouseOverChild) {
      hideTimeout.current = setTimeout(() => {
        setShowChild(false);
      }, 100);
    } else {
      clearTimeout(hideTimeout.current);
      setShowChild(true);
    }
  }, [isMouseOverChild, isMouseOverParent, showChild]);

  if (loadable.state !== "hasData") {
    return (
      <UsageContainer>
        <SkeletonBlock
          style={{
            width: "100%",
            height: "2rem",
          }}
        />
      </UsageContainer>
    );
  }

  return (
    <UsageContainer
      onMouseEnter={() => setIsMouseOverParent(true)}
      onMouseLeave={() => setIsMouseOverParent(false)}
    >
      <NumberWrapper>
        <p
          style={{
            margin: 0,
          }}
        >
          {text ?? "Used:"}{" "}
        </p>

        <Number>
          {loadable.data.length} {typeName ?? "times"}
          {loadable.data.length > 0 && showChild && !noHover && (
            <ExtraContainer
              placement={placement}
              placementLeftRight={placementLeftRight}
            >
              <Suspense
                fallback={
                  <SkeletonText
                    style={{
                      width: "4rem",
                      height: "2rem",
                    }}
                  />
                }
              >
                {children}
              </Suspense>
            </ExtraContainer>
          )}
        </Number>
      </NumberWrapper>
    </UsageContainer>
  );
}

export function BranchInfo({
  usage,
}: {
  usage: {
    projectId: string;
    branchId: string;
  };
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const branchMetaObjects = useAtomValue(
    branchMetasBySortOrderFamily({
      nodeId: usage.projectId,
    }),
  );

  return (
    <>
      <Name
        key={usage.branchId}
        to={`/design/project/${organisationId}/${usage.projectId}/${usage.branchId}`}
        target={"_blank"}
      >
        {branchMetaObjects.find((b) => b.id === usage.branchId)?.title}
      </Name>
    </>
  );
}

export function ProjectTitle({ projectId }: { projectId: string }) {
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  return <SubHeader>{project?.name}</SubHeader>;
}

export function ExtraProjectUsageInfo({
  usage,
}: {
  usage: ResourceTypeUsage[];
}) {
  const projectsWithBranches = useMemo(() => {
    return usage.reduce<{
      [projectId: string]: string[];
    }>((pre, cur) => {
      if (!pre[cur.projectId]) {
        pre[cur.projectId] = [];
      }
      const doesBranchExist = pre[cur.projectId].some(
        (branch) => branch === cur.branchId,
      );
      if (!doesBranchExist) {
        pre[cur.projectId].push(cur.branchId);
      }
      return pre;
    }, {});
  }, [usage]);

  return (
    <DetailedProjectAndBranchUsage
      projectsWithBranches={Object.entries(projectsWithBranches)}
    />
  );
}
