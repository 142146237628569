import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback, useState } from "react";
import {
  SubstationType,
  createSubstationType,
  deleteSubstationType,
  updateSubstationType,
} from "services/substationService";
import { scream } from "utils/sentry";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectSubstationTypesFamily } from "state/jotai/substationType";
import { useToast } from "hooks/useToast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import {
  createLoadingMessage,
  createSuccessMessage,
} from "components/Toast/ToastMessages";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useProjectSubstationTypeCrud = () => {
  const projectId = useAtomValue(projectIdAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showConfirm } = useConfirm();
  const { success: showSuccess, info: showInfo, error: showError } = useToast();

  const updateLocal = useJotaiCallback(
    (get, set, projectId: string, substationType: SubstationType) => {
      aset(get, set, projectSubstationTypesFamily(projectId), (cur) => {
        const ret = new Map(cur);
        ret.set(substationType.id, substationType);
        return ret;
      });
    },
    [],
  );

  const deleteLocal = useJotaiCallback(
    (get, set, projectId: string, substationId: string) => {
      aset(get, set, projectSubstationTypesFamily(projectId), (cur) => {
        const ret = new Map(cur);
        ret.delete(substationId);
        return ret;
      });
    },
    [],
  );

  const create = useCallback(
    async (
      substationType: Partial<SubstationType>,
    ): Promise<SubstationType> => {
      if (!projectId) throw scream("Missing project id");
      setIsLoading(true);
      showInfo(createLoadingMessage("Creating substation..."), {
        timeout: 1000,
      });

      try {
        const sub = await createSubstationType(projectId, substationType);
        updateLocal(projectId, sub);
        showSuccess(createSuccessMessage("Substation created successfully"));
        return sub;
      } catch (e) {
        showError("Failed to create substation");
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [projectId, updateLocal, showInfo, showSuccess, showError],
  );

  const update = useJotaiCallback(
    async (get, set, substationType: SubstationType) => {
      if (!projectId) throw scream("Missing project id");
      setIsLoading(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "SUBSTATION",
          resourceId: substationType.id,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "SUBSTATION",
          substationType.id,
        );
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Update substation",
          message: `Substation type is currently being used in ${usage.length} substations, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        try {
          updateLocal(projectId, substationType);
          await updateSubstationType(projectId, substationType);
          showSuccess(createSuccessMessage("Saved"));
        } catch (e) {
          showError("Failed to update substation");
          throw e;
        }
      }
      setIsLoading(false);
    },
    [projectId, updateLocal, showConfirm, showSuccess, showError],
  );

  const deleteSubstation = useJotaiCallback(
    async (get, set, substationId: string) => {
      if (!projectId) throw scream("Missing project id");
      setIsLoading(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "SUBSTATION",
          resourceId: substationId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "SUBSTATION",
          substationId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete substation",
          message: `Substation type is currently being used in ${usage.length} substations, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        try {
          showInfo(createLoadingMessage("Deleting substation..."), {
            timeout: 1000,
          });
          deleteLocal(projectId, substationId);
          await deleteSubstationType(projectId, substationId);
          showSuccess(createSuccessMessage("Substation deleted successfully"));
        } catch (e) {
          showError("Failed to delete substation");
          throw e;
        } finally {
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    },
    [projectId, deleteLocal, showConfirm, showInfo, showSuccess, showError],
  );

  const duplicate = useCallback(
    async (
      substationType: SubstationType,
      name: string,
    ): Promise<SubstationType> => {
      if (!projectId) throw scream("Missing project id");
      setIsLoading(true);
      showInfo(createLoadingMessage("Duplicating substation..."), {
        timeout: 1000,
      });

      try {
        const clone: Partial<SubstationType> = {
          ...substationType,
          name,
        };
        delete clone.id;
        const sub = await createSubstationType(projectId, clone);
        updateLocal(projectId, sub);
        showSuccess(createSuccessMessage("Substation duplicated successfully"));
        return sub;
      } catch (e) {
        showError("Failed to duplicate substation");
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [projectId, updateLocal, showInfo, showSuccess, showError],
  );

  return {
    create,
    update,
    deleteSubstation,
    duplicate,
    updateLocal,
    deleteLocal,
    isLoading,
  };
};

export default useProjectSubstationTypeCrud;
