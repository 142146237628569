import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Row } from "components/General/Layout";
import { Map } from "mapbox-gl";
import {
  Column,
  Row as ReactTableRow,
  useTable,
  useGlobalFilter,
  UseGlobalFiltersOptions,
  useFlexLayout,
} from "react-table";
import {
  externalLayerFilterPropertyAtom,
  selectedDataLayerPropertiesTableAtom,
  useDynamicStreamer,
} from "state/layer";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { borderRadius, spacing4 } from "styles/space";
import { Layer } from "types/layers";
import styled from "styled-components";
import { colors } from "styles/colors";
import { useGoToFeatures } from "hooks/map";
import { GeometryNoCollection } from "utils/geojson/geojson";
import { Feature } from "geojson";
import {
  getSelectedLayers,
  layerSettingSelectorFamily,
  layerVisibleAtomFamily,
} from "components/LayerList/LayerSettings/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useLayerSettingsCrud } from "components/LayerList/LayerSettings/useLayerSettingsCrud";
import FilterIcon from "@icons/24/Filter.svg?react";
import PinnedIcon from "@icons/24/Pinned.svg?react";
import CloseIcon from "@icons/24/Close.svg?react";
import { mapAtom } from "state/map";
import { SVGWrapper } from "@icons/svgUtils";
import Spinner from "@icons/spinner/Spinner";
import { typography } from "styles/typography";
import {
  isCustomLibraryLayer,
  isCustomUploadedLayer,
} from "components/LayerList/utils";
import { modalTypeOpenAtom } from "state/modal";
import { FilterExternalDataLayersModalType } from "components/FilterExternalDataLayers/FilterExternalDataLayers";
import { getSourceAndLayerId } from "layers/ExternalLayers/utils";
import { SearchInput } from "components/General/Input";
import useBooleanState from "hooks/useBooleanState";
import {
  currentExternalLayerSelection,
  ExternalSelectionItem,
} from "state/externalLayerSelection";
import { ProjectFeature } from "types/feature";
import { notUndefinedOrNull } from "utils/predicates";
import { platformCtrlOrCommand } from "utils/utils";
import { VectorError } from "components/VectorError/VectorError";
import { unwrap } from "jotai/utils";
import { customLayersMetadataSelectorAsync } from "state/customLayers";
import { maybeGetLayerId } from "utils/layer";
import { POLYGON_LAYERS_SUFFIX } from "layers/ExternalLayers/dynamicPolygonLayers";
import { LINE_LAYERS_SUFFIX } from "layers/ExternalLayers/dynamicLineLayers";
import { CIRCLE_LAYERS_SUFFIX } from "layers/ExternalLayers/dynamicCircleLayers";

const StickyThead = styled.thead`
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
  width: fit-content;
  min-width: 100%;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${colors.blue500};
  }
`;

const StandardRow = styled.tr<{ selected?: boolean }>`
  cursor: pointer;
  border-spacing: 0;
  &:hover {
    background-color: ${(p) =>
      p.selected ? colors.blue100 : colors.grey100} !important;
  }
  background-color: ${(p) =>
    p.selected ? colors.blue100 : "inherit"} !important;
`;

const PinPropertyToMapWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  opacity: ${(p) => (p.selected ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
`;

const FilterWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  opacity: ${(p) => (p.selected ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
  overflow-wrap: anywhere;
`;

const CellHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing4};
`;

const StandardCell = styled.td`
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: ${spacing4} ${spacing4};
`;

const StandardHeaderCell = styled.th`
  border-radius: ${borderRadius.small};
  padding: ${spacing4} ${spacing4};
  &:hover {
    background-color: ${colors.grey100};
    ${PinPropertyToMapWrapper} {
      opacity: 1;
    }
    ${FilterWrapper} {
      opacity: 1;
    }
  }
`;

const LayerDataPropertiesTableWrapper = styled.div<{ visible: boolean }>`
  padding: 0 2rem 0 2rem;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 30rem;
  bottom: ${(p) => (p.visible ? 0 : "calc(-100%)")};
  left: 0;
  right: 0;
  background-color: white;
  z-index: 6;
  border-top: 1px solid ${colors.blue500};
  transition: bottom 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  resize: vertical;
  transform: rotateX(180deg);
  max-height: 80%;
  min-height: 10%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading3 = styled.h3`
  ${typography.h3}
`;

const SubHeading = styled.div`
  ${typography.sub2}
  color: ${colors.grey500};
`;

const Tbody = styled.tbody`
  width: fit-content;
  min-width: 100%;
  ${StandardRow}:nth-child(even) {
    background-color: ${colors.grey50};
  }
`;

const SearchInputStyler = styled.div`
  cursor: pointer;

  input {
    width: 16px;
    transition: width 0.2s linear;

    &:focus,
    &:not([value=""]) {
      width: 30rem;
    }
  }
`;

const LayerDataPropertiesTableInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  transform: rotateX(180deg);
`;

type LayerProperties = Record<string, string> &
  Record<"_vind_all_values", Array<string>>;

const featureToExternalSelectionItem = (
  feature: Feature<GeometryNoCollection>,
  layer: Layer,
): ExternalSelectionItem => {
  const sourceAndLayerId = getSourceAndLayerId(layer);
  const externalSelectionItem = {
    ...feature,
    source: sourceAndLayerId,
    layer: {
      id: sourceAndLayerId,
      type: layer.type as "line" | "circle",
      source: sourceAndLayerId,
    },
    clone: () => externalSelectionItem,
    toJSON: () => feature,
    ignoreScrollToFeature: true,
  };
  return externalSelectionItem;
};

const getLayerIdFromFeatureType = (feature: Feature, layer: Layer): string => {
  switch (feature.geometry.type) {
    case "MultiPolygon":
    case "Polygon":
      return layer.id + POLYGON_LAYERS_SUFFIX;
    case "MultiLineString":
    case "LineString":
      return layer.id + LINE_LAYERS_SUFFIX;
    case "MultiPoint":
    case "Point":
      return layer.id + CIRCLE_LAYERS_SUFFIX;
    default:
      return "";
  }
};

const useDisableTextSelectionOnShiftClick = (
  tableWrapperRef: React.RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    if (!tableWrapperRef.current) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.shiftKey || !tableWrapperRef.current?.style) return;
      tableWrapperRef.current.style.userSelect = "none";
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.shiftKey || !tableWrapperRef.current?.style) return;
      tableWrapperRef.current.style.userSelect = "auto";
    };
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [tableWrapperRef]);
};

const useSyncTableWithLastSelection = (
  layer: Layer,
  selectedExternalFeatures: ExternalSelectionItem[],
  allLayers: Layer[],
  setSelectedDataLayerPropertiesTable: (layer: Layer) => void,
) => {
  useEffect(() => {
    const lastSelectedElement = selectedExternalFeatures.slice(-1)[0];
    if (!lastSelectedElement) return;

    const lastSelectedLayerId = maybeGetLayerId(lastSelectedElement);
    if (lastSelectedLayerId === layer.id) return;

    const lastSelectedLayer = allLayers.find(
      (l) => l.id === lastSelectedLayerId,
    );
    if (!lastSelectedLayer) return;
    setSelectedDataLayerPropertiesTable(lastSelectedLayer);
  }, [
    selectedExternalFeatures,
    layer,
    allLayers,
    setSelectedDataLayerPropertiesTable,
  ]);
};

const useScrollToSelectedFeature = (
  selectedExternalFeatures: ExternalSelectionItem[],
  features: Feature[],
  scrollRef: React.RefObject<ViewportListRef>,
) => {
  useEffect(() => {
    if (!scrollRef.current) return;
    const lastSelectedFeature = selectedExternalFeatures.slice(-1)[0];
    if (!lastSelectedFeature || lastSelectedFeature.ignoreScrollToFeature)
      return;
    const index = features.findIndex((f) => f.id === lastSelectedFeature.id);
    if (index === -1) return;
    scrollRef.current.scrollToIndex({ index: index - 1 });
  }, [selectedExternalFeatures, scrollRef, features]);
};

const LayerDataPropertiesTableInner = ({
  layer,
  map,
  projectId,
}: {
  layer: Layer;
  map: Map;
  projectId: string;
}) => {
  const [singleClickIndex, setSingleClickIndex] = useState<number | undefined>(
    undefined,
  );
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const selectedLayers = useAtomValue(
    getSelectedLayers({
      projectId,
    }),
  );
  const { dynamicVectorLayerFeatures } = useDynamicStreamer(layer);
  const filterLayerSourceId = useMemo(
    () =>
      dynamicVectorLayerFeatures.features.length !== 0
        ? getLayerIdFromFeatureType(
            dynamicVectorLayerFeatures.features[0],
            layer,
          )
        : "",
    [dynamicVectorLayerFeatures.features, layer],
  );
  const customLayersL = useAtomValue(
    useMemo(
      () =>
        unwrap(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      [projectId],
    ),
  );

  const allLayers = useMemo(
    () => [...selectedLayers, ...(customLayersL ?? [])],
    [selectedLayers, customLayersL],
  );
  const [selectedExternalFeatures, setSelectedExternalFeatures] = useAtom(
    currentExternalLayerSelection,
  );
  const setSelectedDataLayerPropertiesTable = useSetAtom(
    selectedDataLayerPropertiesTableAtom,
  );
  const externalLayerFilterProperty = useAtomValue(
    externalLayerFilterPropertyAtom,
  );
  const tableRef = useRef<HTMLTableElement>(null);
  const scrollRef = useRef<ViewportListRef>(null);
  const [layerVisible, setLayerVisible] = useAtom(
    layerVisibleAtomFamily({
      projectId,
      layerId: layer.id,
    }),
  );
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const goToFeatures = useGoToFeatures(map);
  const [isInFocus, toggleIsInFocus] = useBooleanState(false);
  const [searchValue, setSearchValue] = useState("");
  const currentSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const { put: putLayerSettings } = useLayerSettingsCrud();
  const keys = useMemo(() => {
    return dynamicVectorLayerFeatures.features.reduce((acc, f) => {
      Object.keys(f?.properties ?? {}).forEach((key) => {
        acc.add(key);
      });
      return acc;
    }, new Set<string>());
  }, [dynamicVectorLayerFeatures.features]);

  const tableData = useMemo(() => {
    return dynamicVectorLayerFeatures.features
      .map((f) => f.properties as LayerProperties)
      .map((p) => ({
        ...p,
        _vind_all_values: Object.values(p)
          .filter(notUndefinedOrNull)
          .map((v) => v.toString().toLowerCase()),
      })) as LayerProperties[];
  }, [dynamicVectorLayerFeatures.features]);

  const columns: Array<Column<LayerProperties>> = useMemo(
    () =>
      Array.from(keys).map((key) => ({
        id: key,
        Cell: ({ row }: { row: ReactTableRow<LayerProperties> }) => {
          return <CellWrapper>{row.original[key]}</CellWrapper>;
        },
        Header: () => {
          const layerFilterProperty =
            externalLayerFilterProperty[filterLayerSourceId] || {};
          const isFilteredOnProperty = Boolean(
            (layerFilterProperty as any)[key],
          );
          return (
            <CellHeaderWrapper>
              {key}
              <PinPropertyToMapWrapper
                selected={currentSettings.layerStyle?.pinnedProperties?.includes(
                  key,
                )}
                onClick={() => {
                  const newStyle = {
                    ...(currentSettings.layerStyle || {}),
                  };
                  const newPinnedProperties = (
                    newStyle?.pinnedProperties ?? []
                  ).includes(key)
                    ? newStyle.pinnedProperties!.filter((p) => p !== key)
                    : [...(newStyle.pinnedProperties ?? []), key];

                  putLayerSettings([
                    {
                      ...currentSettings,
                      id: currentSettings.id,
                      layerStyle: {
                        ...newStyle,
                        pinnedProperties: newPinnedProperties,
                      },
                    },
                  ]);
                }}
              >
                <PinnedIcon />
              </PinPropertyToMapWrapper>
              <FilterWrapper
                selected={isFilteredOnProperty}
                onClick={() => {
                  if (!filterLayerSourceId) return;
                  setModalTypeOpen({
                    modalType: FilterExternalDataLayersModalType,
                    metadata: {
                      sourceId: filterLayerSourceId,
                      sourceName: layer.name,
                      property: key,
                    },
                  });
                }}
              >
                <FilterIcon />
              </FilterWrapper>
            </CellHeaderWrapper>
          );
        },
      })),
    [
      keys,
      putLayerSettings,
      currentSettings,
      externalLayerFilterProperty,
      layer,
      setModalTypeOpen,
      filterLayerSourceId,
    ],
  );

  const globalFilterFunc = useMemo<
    UseGlobalFiltersOptions<any>["globalFilter"]
  >(
    () => (rows, _indexes, searchVal) => {
      return rows.filter((row) => {
        for (const value of row.original._vind_all_values) {
          if (value && value.includes(searchVal.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    },
    [],
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    globalFilteredRows: untypedGlobalFilteredRows,
  } = useTable<LayerProperties>(
    {
      columns,
      data: tableData,
      globalFilter: globalFilterFunc,
    },
    useGlobalFilter,
    useFlexLayout,
  );

  const globalFilteredRows = untypedGlobalFilteredRows as {
    index: number;
  }[];

  // Update global filter when searchString changes
  useEffect(() => {
    setGlobalFilter(searchValue);
  }, [searchValue, setGlobalFilter]);

  useSyncTableWithLastSelection(
    layer,
    selectedExternalFeatures,
    allLayers,
    setSelectedDataLayerPropertiesTable,
  );

  useDisableTextSelectionOnShiftClick(tableWrapperRef);

  useScrollToSelectedFeature(
    selectedExternalFeatures,
    dynamicVectorLayerFeatures.features,
    scrollRef,
  );

  return (
    <LayerDataPropertiesTableInnerWrapper>
      <HeaderWrapper>
        <HeadingWrapper>
          <Row alignCenter>
            <Heading3>{layer.name}</Heading3>
            {dynamicVectorLayerFeatures.isLoading && (
              <Spinner size={"1.4rem"} />
            )}
            <VectorError layer={layer} />
          </Row>
          <SubHeading>
            {isCustomUploadedLayer(layer)
              ? "Vind - Uploaded"
              : isCustomLibraryLayer(layer)
                ? "Vind - Library"
                : layer.source.name}
          </SubHeading>
        </HeadingWrapper>

        <Row alignCenter>
          <SearchInputStyler>
            <SearchInput
              placeholder={isInFocus ? "Search value" : undefined}
              value={searchValue}
              onFocus={toggleIsInFocus}
              onBlur={toggleIsInFocus}
              onChange={(e) => setSearchValue(e.target.value)}
              onClear={() => setSearchValue("")}
              style={{
                ...(!isInFocus &&
                  searchValue === "" && {
                    paddingLeft: spacing4,
                  }),
              }}
            />
          </SearchInputStyler>
          <SVGWrapper
            size={1.4}
            onClick={() => setSelectedDataLayerPropertiesTable(undefined)}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </SVGWrapper>
        </Row>
      </HeaderWrapper>

      <TableWrapper ref={tableWrapperRef}>
        <table
          ref={tableRef}
          {...getTableProps({
            style: {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderSpacing: 0,
              overflow: "scroll",
              flex: 1,
            },
          })}
        >
          <StickyThead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <StandardHeaderCell {...column.getHeaderProps()}>
                    <Row alignCenter>
                      {column.render("Header")}
                      {column.canSort && (
                        <span
                          style={{
                            marginLeft: "0.3rem",
                          }}
                        ></span>
                      )}
                    </Row>
                  </StandardHeaderCell>
                ))}
              </tr>
            ))}
          </StickyThead>
          <Tbody {...getTableBodyProps()}>
            <ViewportList viewportRef={tableRef} ref={scrollRef} items={rows}>
              {(row) => {
                prepareRow(row);
                const originalFeature = dynamicVectorLayerFeatures.features[
                  row.index
                ] as
                  | undefined
                  | (Feature<GeometryNoCollection> & { id: string });

                return (
                  <StandardRow
                    {...row.getRowProps()}
                    selected={selectedExternalFeatures.some(
                      (f) => f.id === originalFeature?.id,
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!originalFeature) return;
                      if (!layerVisible) setLayerVisible(Promise.resolve(true));
                      const clickedIndex = row.index;
                      if (e.shiftKey && singleClickIndex !== undefined) {
                        const lastSelectedIndex = singleClickIndex;
                        if (lastSelectedIndex === -1) return;
                        const minIndex = Math.min(
                          clickedIndex,
                          lastSelectedIndex,
                        );
                        const maxIndex =
                          Math.max(clickedIndex, lastSelectedIndex) + 1;
                        const newSelectedExternalFeatures = globalFilteredRows
                          .filter(
                            (r) => r.index >= minIndex && r.index < maxIndex,
                          )
                          .map((r) =>
                            featureToExternalSelectionItem(
                              dynamicVectorLayerFeatures.features[
                                r.index
                              ] as Feature<GeometryNoCollection>,
                              layer,
                            ),
                          );
                        setSelectedExternalFeatures(
                          newSelectedExternalFeatures,
                        );
                        goToFeatures(
                          newSelectedExternalFeatures as ProjectFeature[],
                        );
                        return;
                      } else if (platformCtrlOrCommand(e)) {
                        setSingleClickIndex(clickedIndex);
                        const externalSelectionItem =
                          featureToExternalSelectionItem(
                            originalFeature,
                            layer,
                          );
                        const newSelectedExternalFeatures =
                          selectedExternalFeatures.some(
                            (f) => f.id === originalFeature.id,
                          )
                            ? selectedExternalFeatures.filter(
                                (f) => f.id !== originalFeature.id,
                              )
                            : [
                                ...selectedExternalFeatures,
                                externalSelectionItem,
                              ];
                        setSelectedExternalFeatures(
                          newSelectedExternalFeatures,
                        );
                        goToFeatures(
                          newSelectedExternalFeatures as ProjectFeature[],
                        );
                        return;
                      } else {
                        const externalSelectionItem =
                          featureToExternalSelectionItem(
                            originalFeature,
                            layer,
                          );
                        setSingleClickIndex(clickedIndex);
                        setSelectedExternalFeatures([externalSelectionItem]);
                        goToFeatures([externalSelectionItem as ProjectFeature]);
                      }
                    }}
                  >
                    {row.cells.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <StandardCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StandardCell>
                    ))}
                  </StandardRow>
                );
              }}
            </ViewportList>
          </Tbody>
        </table>
      </TableWrapper>
    </LayerDataPropertiesTableInnerWrapper>
  );
};

const LayerDataPropertiesTable = () => {
  const map = useAtomValue(mapAtom);
  const layer = useAtomValue(selectedDataLayerPropertiesTableAtom);
  const [visible, setVisible] = useState(false);
  const projectId = useAtomValue(projectIdAtom);

  useEffect(() => {
    if (map && layer && projectId) {
      setVisible(true);
    }
    return () => {
      setVisible(false);
    };
  }, [layer, map, projectId]);

  return (
    <LayerDataPropertiesTableWrapper visible={visible}>
      {layer && map && projectId && (
        <Suspense fallback={<Spinner />}>
          <LayerDataPropertiesTableInner
            layer={layer}
            map={map}
            projectId={projectId}
          />
        </Suspense>
      )}
    </LayerDataPropertiesTableWrapper>
  );
};

export default LayerDataPropertiesTable;
