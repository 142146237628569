import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  ErrorBoundaryWrapper,
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import styled from "styled-components";

import { Column, Row } from "components/General/Layout";
import Trashcan from "@icons/24/Bin.svg?react";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { groupMembersState, organisationGroupsState } from "./state";
import { Group } from "./types";
import { typography } from "styles/typography";
import useGroupsCrud from "./useGroupsCrud";
import {
  ContentTableColumn,
  TableHeader,
} from "../OrganisationRightSide/style";
import { adminInOrganisationSelectorFamily } from "state/user";
import StackedUserImages from "components/UserImage/StackedUserImages";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usersInOrganisationState } from "../state";
import { isDefined } from "utils/predicates";
import { SkeletonText } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import { colors } from "styles/colors";
import React from "react";
import RoundGroupIcon from "components/Organisation/OrganisationRightSide/content/NodeContent/tabs/RoundGroupIcon";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const StyledRow = styled(Row)`
  gap: 0;
  padding: 0 0.8rem;

  > * {
    flex: 1;
  }
`;

const Groups = ErrorBoundaryWrapper(
  () => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const groups = useAtomValue(
      organisationGroupsState({
        organisationId,
      }),
    );
    const sortedGroups = useMemo(
      () =>
        Array.from(groups).sort((a, b) => {
          return a.name.localeCompare(b.name);
        }),
      [groups],
    );

    const setContent = useSetAtom(organisationRightSideModal(organisationId));
    useEffect(() => {
      setContent((curr) =>
        typeof curr !== "undefined"
          ? {
              type: "no-item-selected",
            }
          : undefined,
      );
    }, [setContent]);

    return (
      <Column
        style={{
          gap: "2.4rem",
          padding: "2.4rem 0 8rem 0",
        }}
      >
        <StyledRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Members</TableHeader>
          <div />
        </StyledRow>
        <ContentTableColumn>
          {sortedGroups.map((g) => {
            return (
              <React.Suspense key={g.id} fallback={null}>
                <GroupRow group={g} />
              </React.Suspense>
            );
          })}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);

const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;

const GroupRowContainer = styled(Row)`
  padding: 1.3rem 1.6rem;
  align-items: center;
  cursor: pointer;
  min-height: 6.2rem;
  box-sizing: border-box;
`;

const GroupMembers = ({
  organisationId,
  groupId,
}: {
  organisationId: string;
  groupId: string;
}) => {
  const members = useAtomValue(
    groupMembersState({
      organisationId,
      groupId,
    }),
  );
  const users = useAtomValue(usersInOrganisationState(organisationId));

  const usersToShow = useMemo(
    () =>
      members
        .map((m) => m.user_id)
        .map((userId) => users.find((u) => u.user_id === userId))
        .filter(isDefined),
    [members, users],
  );

  return <StackedUserImages users={usersToShow} size={2.2} />;
};

function GroupRow({ group }: { group: Group }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { remove } = useGroupsCrud();
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const { showConfirm } = useConfirm();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const isSelected = content?.id === group.id;

  const [saveInProgress, setSaveInProgress] = useState(false);
  const onRemove = useCallback(async () => {
    try {
      setSaveInProgress(true);
      await remove(group.id);
    } catch {
    } finally {
      setSaveInProgress(false);
    }
  }, [group.id, remove]);

  return (
    <GroupRowContainer
      key={group.id}
      style={{
        ...(saveInProgress
          ? {
              opacity: 0.6,
            }
          : {}),
        ...(isSelected
          ? {
              backgroundColor: colors.selected,
            }
          : {}),
      }}
      onClick={() =>
        setContent((curr) =>
          curr && curr.id === group.id
            ? undefined
            : {
                type: "group",
                id: group.id,
              },
        )
      }
    >
      <Row
        style={{
          alignItems: "center",
          gap: "0.8rem",
          flex: 1,
        }}
      >
        <RoundGroupIcon />
        <Text>{group.name}</Text>
      </Row>
      <Row
        style={{
          flex: 1,
        }}
      >
        <React.Suspense fallback={<SkeletonText text="Loading members" />}>
          <GroupMembers groupId={group.id} organisationId={organisationId} />
        </React.Suspense>
      </Row>
      {isAdminInOrg && (
        <div
          style={{
            flex: 1,
          }}
        >
          <IconBtn
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await showConfirm({
                  title: "Delete group",
                  message: "Are you sure you want to delete this group?",
                  confirmButtonText: "Delete",
                })
              ) {
                onRemove();
                setContent(undefined);
              }
            }}
            disabled={saveInProgress}
            size={"1.4rem"}
            style={{
              marginLeft: "auto",
            }}
          >
            <Trashcan />
          </IconBtn>
        </div>
      )}
    </GroupRowContainer>
  );
}

export default Groups;
