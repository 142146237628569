import { useAtom } from "jotai";
import * as turf from "@turf/turf";
import { defaultMapTextPaintAtom, mapAtom } from "state/map";
import {
  CircleLayer,
  CirclePaint,
  SymbolLayer,
  Map,
  LayerSpecification,
  VectorSourceSpecification,
} from "mapbox-gl";
import { designToolTypeAtom } from "../state/map";
import {
  existingTurbinesExternalLayerId,
  getBeforeLayer,
} from "../components/Mapbox/utils";
import GenericFeature from "../components/MapFeatures/GenericFeature";
import { existingTurbinesInputAtom } from "state/layer";
import { currentSelectionArrayAtom } from "state/selection";
import Add from "@icons/24/Add.svg?react";
import { ParkFeature, ProjectFeature, TurbineFeature } from "types/feature";
import { Popup } from "components/Mapbox/Popup";
import { useCallback, useMemo } from "react";
import { getCenterOfFeatures } from "utils/geometry";
import useAddFeaturesIntoElementsWithinLimits from "hooks/useAddFeaturesIntoElementsWithinLimits";
import { useProjectElementsFoldersCrud } from "components/ProjectElementsV2/useProjectElementsFoldersCrud";
import { dedup } from "utils/utils";
import { smallMapCircleRadius } from "components/MapFeatures/expressionUtils";
import { editmodePropertyName } from "components/Mapbox/constants";
import { DEFAULT_SELECTED_OPACITY } from "@constants/canvas";
import { useAtomValue } from "jotai";
import { DesignToolMode } from "types/map";
import { colors } from "styles/colors";
import { v4 } from "uuid";
import { MAX_AREA } from "@constants/park";
import { isOnshoreAtom } from "state/onshore";
import {
  leftModalMenuOpenStateAtom,
  LeftModalMenuTypes,
} from "components/LowerLeftV2/state";
import { Menu, MenuItem } from "components/General/Menu";

const ExistingTurbines = () => {
  const leftModalMenuOpen = useAtomValue(leftModalMenuOpenStateAtom);

  if (leftModalMenuOpen === LeftModalMenuTypes.ExistingTurbines)
    return <ExistingTurbinesActive />;
  return null;
};

const turbinePaint: CirclePaint = {
  "circle-radius": smallMapCircleRadius,
  "circle-color": colors.indigo600,
  "circle-opacity": DEFAULT_SELECTED_OPACITY,
  // prettier-ignore
  "circle-stroke-width": [
        "case",
        [
            "==",
            [
                "get",
                editmodePropertyName
            ],
            true
        ],
        3,
        [
            "!=",
            [
                "feature-state",
                "borderColor"
            ],
            null
        ],
        2.0,
        [
            "boolean",
            [
                "feature-state",
                "selected"
            ],
            false
        ],
        2.0,
        [
            "boolean",
            [
                "feature-state",
                "hover"
            ],
            false
        ],
        1.0,
        0.0
    ],
};

export const AddExistingTurbines = ({ map }: { map: Map }) => {
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );

  const isOnshore = useAtomValue(isOnshoreAtom);
  const selection = useAtomValue(currentSelectionArrayAtom);
  const addFeaturesToElementsWithinLimits =
    useAddFeaturesIntoElementsWithinLimits();
  const {
    create: createFolder,
    update: updateFolder,
    items: folderItems,
  } = useProjectElementsFoldersCrud();

  const turbines = useMemo(() => {
    const allLayers = map.getLayer(existingTurbinesExternalLayerId);
    if (!allLayers || leftModalMenuOpen !== LeftModalMenuTypes.ExistingTurbines)
      return [];
    const allTurbines = dedup(
      map.queryRenderedFeatures({
        layers: [allLayers.id],
      }),
      (t) => t.id,
    );
    const rawTurbines = allTurbines.filter((t) =>
      selection.includes(`${t.id}`),
    );
    return rawTurbines.map(
      (raw) =>
        ({
          type: "Feature",
          geometry: raw.geometry,
          id: raw?.id,
          properties: {
            type: "existing-turbine",
            ...raw?.properties,
            name:
              "Existing turbine" +
              (raw?.properties?.name ? ` - ${raw.properties.name}` : ""),
          },
        }) as ProjectFeature,
    );
  }, [leftModalMenuOpen, map, selection]);

  const potentialPark: ParkFeature | undefined = useMemo(() => {
    if (!turbines || turbines.length === 0) return;
    const points = turbines.map((t) =>
      turf.point([
        t.geometry.coordinates[0] as number,
        t.geometry.coordinates[1] as number,
      ]),
    );
    const features = turf.featureCollection(points);
    const p = turf.convex(features);
    if (!p) return;
    const buffered = turf.buffer(p, 500, { units: "meters" });
    if (!buffered || buffered.geometry.coordinates.length > 1) return;
    return {
      type: "Feature",
      geometry: buffered.geometry,
      id: v4(),
      properties: {
        type: "park-polygon",
        name: "Existing park",
      },
    } as ParkFeature;
  }, [turbines]);

  const parkArea = useMemo(() => {
    if (!potentialPark) return;
    return turf.area(potentialPark) / 1e6;
  }, [potentialPark]);

  const onClickDuplicateTurbines = useCallback(async () => {
    const createdFeatures = await addFeaturesToElementsWithinLimits({
      add: turbines,
      successMessage: "Added to Elements",
      selectAfterCloning: true,
    });
    setLeftModalMenuOpen(undefined);
    const createdFeatureIds = createdFeatures.map((feature) => feature.id);
    const addToFolderName = "Existing turbines";
    const existingFolder = folderItems.find(
      (folder) => folder.folderName === addToFolderName,
    );
    if (existingFolder) {
      updateFolder({
        ...existingFolder,
        featureIds: existingFolder.featureIds.concat(
          createdFeatureIds.map((id) => ({
            type: "feature",
            id,
          })),
        ),
      });
    } else {
      createFolder({
        featureIds: createdFeatures.map((feature) => ({
          type: "feature",
          id: feature.id,
        })),
        folderName: addToFolderName,
      });
    }
  }, [
    turbines,
    updateFolder,
    createFolder,
    folderItems,
    addFeaturesToElementsWithinLimits,
    setLeftModalMenuOpen,
  ]);

  const onClickDuplicatePark = useCallback(async () => {
    if (!potentialPark) return;
    const turbineFeatures: TurbineFeature[] = turbines.map((t, i) => {
      const id = v4();
      return {
        id,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            t.geometry.coordinates[0] as number,
            t.geometry.coordinates[1] as number,
          ],
        },
        properties: {
          id,
          parentIds: [potentialPark.id],
          type: "park-layout-turbine",
          turbineTypeId: isOnshore ? "nrel_5MW" : "iea_10MW",
          name: `#${i + 1}`,
        },
      };
    });
    await addFeaturesToElementsWithinLimits({
      add: [potentialPark, ...turbineFeatures],
      successMessage:
        "Added park with turbines to Elements. Default turbine type used, please correct if necessary.",
      selectAfterCloning: false,
    });
    setLeftModalMenuOpen(undefined);
  }, [
    potentialPark,
    addFeaturesToElementsWithinLimits,
    setLeftModalMenuOpen,
    turbines,
    isOnshore,
  ]);

  const pos = useMemo(() => {
    if (turbines.length === 0)
      return {
        lat: 0,
        lon: 0,
      };
    const p = getCenterOfFeatures(turbines);
    return {
      lng: p[0],
      lat: p[1],
    };
  }, [turbines]);

  if (
    leftModalMenuOpen !== LeftModalMenuTypes.ExistingTurbines ||
    turbines.length === 0
  )
    return null;

  const turbineString = `${turbines.length} existing turbine${turbines.length > 1 ? "s" : ""}`;
  const disabled = turbines.length > 200;
  return (
    <Popup map={map} pos={pos} offsetPx={[0, -30]}>
      <Menu>
        <MenuItem
          icon={<Add />}
          disabled={disabled}
          tooltip={disabled ? `Cannot add more than 200 turbines` : undefined}
          onClick={onClickDuplicateTurbines}
          name={`Add ${turbineString}`}
        />
        {parkArea && parkArea < MAX_AREA / 2 && turbines.length <= 100 && (
          <MenuItem
            icon={<Add />}
            onClick={onClickDuplicatePark}
            name={`Create park from ${turbineString}`}
          />
        )}
      </Menu>
    </Popup>
  );
};

const emptyList: ProjectFeature[] = [];
const ExistingTurbinesActive = () => {
  const map = useAtomValue(mapAtom);
  const [selection, setSelection] = useAtom(currentSelectionArrayAtom);
  const input = useAtomValue(existingTurbinesInputAtom);
  const mode = useAtomValue(designToolTypeAtom);
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  const source: VectorSourceSpecification = useMemo(() => {
    return {
      type: "vector",
      url:
        mode === DesignToolMode.Onshore
          ? "mapbox://vindai.onshore-turbines"
          : "mapbox://vindai.offshore-turbines",
    };
  }, [mode]);

  const sourceLayerName = useMemo(() => {
    return mode === DesignToolMode.Onshore
      ? "onshore-turbines"
      : "offshore-turbines";
  }, [mode]);

  const layer: LayerSpecification = useMemo(() => {
    return {
      id: existingTurbinesExternalLayerId,
      type: "circle",
      minzoom: 0,
      maxzoom: 17,
      source: sourceLayerName,
      "source-layer": sourceLayerName,
      paint: turbinePaint,
    };
  }, [sourceLayerName]);

  const symbolLayer = useMemo(() => {
    return {
      id: existingTurbinesExternalLayerId + "symbol",
      type: "symbol",
      minzoom: 9,
      maxzoom: 17,
      source: sourceLayerName,
      "source-layer": sourceLayerName,
      layout: {
        "text-field": [
          "case",
          ["==", ["typeof", ["get", "power"]], "number"],
          ["concat", ["get", "power"], "MW"],
          "Unknown MW",
        ],
        "text-offset": [0, 1],
        "text-size": 9,
        "text-anchor": "top",
      },
      paint: defaultMapTextPaint,
    } as SymbolLayer;
  }, [sourceLayerName, defaultMapTextPaint]);

  if (!map || !layer || !source) return null;
  return (
    <GenericFeature
      features={emptyList}
      sourceId={layer["source-layer"] as string}
      source={source}
      layerId={existingTurbinesExternalLayerId}
      map={map}
      layers={[layer as CircleLayer, symbolLayer as SymbolLayer]}
      onClickCallback={(features, shiftClicked) => {
        const newSelection = features.map((f) => `${f.id}` as string);
        if (shiftClicked) {
          setSelection([...selection, ...newSelection]);
        } else {
          setSelection(newSelection);
        }
      }}
      beforeLayer={getBeforeLayer(map, existingTurbinesExternalLayerId)}
      sourceLayer={layer["source-layer"]}
      selectedIds={selection}
      featureFilter={(selectedFeatures) =>
        selectedFeatures.filter(
          (f) =>
            f.layer?.id === existingTurbinesExternalLayerId &&
            f?.properties?.power !== null,
        )
      }
      filter={[
        "all",
        [">=", ["coalesce", ["get", "power"], 0], input.minPower],
        ["<=", ["coalesce", ["get", "power"], 0], input.maxPower],
      ]}
    />
  );
};

export default ExistingTurbines;
