import { fetchSchemaWithToken } from "services/utils";
import { parkIdAtomDef2, projectIdAtomDef2 } from "state/pathParams";
import { z } from "zod";
import {
  Job,
  RunningResponse,
  Settings,
  SucceededResponse,
  _Job,
  _Response,
  _RunningResponse,
  _SucceededResponse,
} from "./types";
import { atom } from "jotai";
import { turbinesInParkWithTypesFamily } from "state/jotai/turbine";
import { dedup, range, sum } from "utils/utils";
import { atomFamily, atomFromFn } from "utils/jotai";
import { MaybePromise } from "types/utils";
import { Buckets, Color } from "lib/colors";
import md5 from "md5";
import { FeatureCollection } from "geojson";
import { unwrap } from "jotai/utils";

const getShadowFlickerFeaturesHash = (featureCollection: FeatureCollection) => {
  const featureCollectionJson = JSON.stringify(featureCollection);
  return md5(featureCollectionJson);
};

export const flickerOpacity = atom<number>(1.0);

const relevantTurbinesAtom = atom(async (get) => {
  const parkId = await get(parkIdAtomDef2);
  return get(turbinesInParkWithTypesFamily({ parkId, branchId: undefined }));
});

const readTurbineHeight = atom(async (get) => {
  const turbines = await get(relevantTurbinesAtom);
  const heights = dedup(turbines.map(([_, typ]) => typ.hubHeight));
  if (heights.length === 1) return heights[0];
  return undefined;
});

const readTurbineRadius = atom(async (get) => {
  const turbines = await get(relevantTurbinesAtom);
  const heights = dedup(turbines.map(([_, typ]) => typ.diameter / 2));
  if (heights.length === 1) return heights[0];
  return undefined;
});

export const configAtom = atomFromFn<Promise<Settings> | Settings>(
  async (get) => {
    return {
      maxShadowLength: 1500,
      terrainDistance: 4000,
      startDatetime: new Date(2024, 0, 1, 0, 0, 0),
      endDatetime: new Date(2024, 11, 31, 23, 59, 59),

      overrideHeight: (await get(readTurbineHeight)) ?? 92,
      overrideHeightEnable: false,

      overrideRadius: (await get(readTurbineRadius)) ?? 56,
      overrideRadiusEnable: false,
    } satisfies Settings;
  },
);

/**
 * GeoJSON we POST to the backend.
 */
const geojsonAtom = atom(async (get) => {
  const parkId = await get(parkIdAtomDef2);
  const c = await get(configAtom);
  const turbines = await get(relevantTurbinesAtom);

  const features = turbines.map(([t, typ]) => {
    const height = typ.hubHeight;
    const radius = typ.diameter / 2.0;
    return {
      type: "Feature",
      geometry: t.geometry,
      properties: {
        id: t.id,
        height,
        radius,
      },
    };
  });

  return {
    featureCollection: {
      type: "FeatureCollection",
      features,
    } as FeatureCollection,
    terrain_buffer: c.terrainDistance,
    distance_cutoff: c.maxShadowLength,
    turbine_height: c.overrideHeightEnable ? c.overrideHeight : undefined,
    turbine_radius: c.overrideRadiusEnable ? c.overrideRadius : undefined,
    start_datetime: c.startDatetime.toISOString(),
    end_datetime: c.endDatetime.toISOString(),
    park_id: parkId,
    // zoom_level: 9, // Use this for debugging, it'll make the computation reasonably fast.
  };
});

export const colorBucketsAtom = atom<Buckets>(
  new Buckets(Color.Transparent(), [
    [new Color(162, 218, 238, 255), 30],
    [new Color(124, 119, 229, 255), 50],
    [new Color(118, 19, 224, 255), 100],
    [new Color(46, 0, 110, 255), 300],
  ]),
);

const _JobsRet = z.object({ jobs: _Job.array() });
export const fetchJobsAtom = atom<null, [], Promise<Job[]>>(
  null,
  async (get) => {
    const [projectId, parkId] = await Promise.all([
      get(projectIdAtomDef2),
      get(parkIdAtomDef2),
    ]);
    const json = await get(geojsonAtom);
    const featureCollectionHash = getShadowFlickerFeaturesHash(
      json.featureCollection,
    );
    const { jobs } = await fetchSchemaWithToken(
      _JobsRet,
      `/api/shadow/${projectId}/flicker/${featureCollectionHash}?park_id=${parkId}`,
    );
    return jobs;
  },
);

export const allJobsAtom = atomFamily(
  ({ projectId, parkId }: { projectId: string; parkId: string }) =>
    atomFromFn<MaybePromise<Job[]>>(async (get) => {
      const json = await get(geojsonAtom);
      const featureCollectionHash = getShadowFlickerFeaturesHash(
        json.featureCollection,
      );
      const { jobs } = await fetchSchemaWithToken(
        _JobsRet,
        `/api/shadow/${projectId}/flicker/${featureCollectionHash}?park_id=${parkId}`,
      );
      return jobs;
    }),
);

export const currentJobsAtom = atom(
  async (get) => {
    const [projectId, parkId] = await Promise.all([
      get(projectIdAtomDef2),
      get(parkIdAtomDef2),
    ]);
    return get(allJobsAtom({ projectId, parkId }));
  },
  async (get, set, jobs: MaybePromise<Job[]>) => {
    const [projectId, parkId] = await Promise.all([
      get(projectIdAtomDef2),
      get(parkIdAtomDef2),
    ]);
    return set(allJobsAtom({ projectId, parkId }), jobs);
  },
);

export const triggerPostShadowAnalysis = atom(null, async (get, set) => {
  const [projectId, parkId] = await Promise.all([
    get(projectIdAtomDef2),
    get(parkIdAtomDef2),
  ]);
  const json = await get(geojsonAtom);
  const res = await fetchSchemaWithToken(
    _Response,
    `/api/shadow/${projectId}/flicker`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    },
  );

  set(allJobsAtom({ projectId, parkId }), await set(fetchJobsAtom));

  return res;
});

/**
 * Turbine index to progress. In addition, the `-1` key maps to the progress of
 * the main StepFunction state. See {@link totalShadowProgress} to see how to make
 * sense of these numbers.
 */
const shadowProgress = atomFamily((_taskId: string) =>
  atom<{ workers?: number } & Record<number, number>>({}),
);

export const totalShadowProgress = atomFamily((taskId: string) =>
  atom((get) => {
    const o = get(shadowProgress(taskId));
    if (o.workers === 0) return 0;

    const mainProgress = o[-1];
    if (mainProgress === 0.1 && o.workers !== undefined) {
      // Workers are running; compute the worker progress
      const s = sum(range(0, o.workers).map((n) => o[n] ?? 0));
      const progress = s / o.workers;
      // Shift the progress to [0.1, 0.9]. The progress before workers start is 10% and
      // 90% when they're done.  This is defined in the backend.
      return progress * 0.8 + 0.1;
    }
    return mainProgress ?? 0;
  }),
);

export const shadowJobOnProgress = atom(
  null,
  (_, set, res: RunningResponse) => {
    const { worker_index, worker_progress, worker_count } = res;
    if (worker_index == null || worker_progress == null) return;
    set(shadowProgress(res.job), (curr) => ({
      ...curr,
      workers: worker_count ?? curr.workers,
      [worker_index]: worker_progress,
    }));
  },
);

export const shadowJobOnSuccess = atom(
  null,
  (_, set, res: SucceededResponse) => {
    set(shadowProgress(res.job), {});
  },
);

/**
 * `true` if any of the current shadow jobs are loading.
 * "current" job means it's returned by {@link currentJobsAtom}.
 */
export const shadowAnyLoading = atom((get) => {
  const jobs = get(unwrap(currentJobsAtom));
  if (!jobs) return false;
  const progress = jobs.map((j) => get(totalShadowProgress(j.job)));
  return progress.some((p) => 0.0 < p && p < 1.0);
});
