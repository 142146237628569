import { useCallback, useEffect, useMemo } from "react";
import Fuse from "fuse.js";
import CostTable from "../CostTable";
import { TableData, Costs } from "../ProcurementCostsContent";
import {
  _TurbineProcurementCost,
  ProcurementCost,
  Regions,
  TurbineProcurementCost,
} from "services/procurementCostService";
import { turbineIdentifierAtom, turbineProcurementCostsAtom } from "../state";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { z } from "zod";
import { CostUnit } from "types/financial";
import { roundToDecimal } from "utils/utils";
import { calculateTurbineDefaultCosts } from "utils/turbineCost";
import { SearchInput } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import { Row } from "components/General/Layout";
import WindTurbineIcon from "@icons/24/WindTurbineWhite.svg?react";

const DEFAULT_UNIT: CostUnit = CostUnit.millionEuroPerUnit;
const REGIONS: Regions[] = ["Europe", "Asia", "USA", "Other"];
const COMPONENT_TYPE = "turbine";
const TABLE_HEADERS = ["Turbine", "Supply", "Shipping"];
const TABLE_SUB_HEADERS = {
  Turbine: ["Name", "Power Rating"],
  Supply: ["Cost"],
  Shipping: ["Europe", "Asia", "USA", "Other"],
};

interface TurbineProcurementCostsProps {
  localTableData: TableData[];
  setLocalTableData: (costs: TableData[]) => void;
  setTableData: (costs: TableData[]) => void;
  setDataToBeSaved: (costs: ProcurementCost[]) => void;
  setChangedRows: (rows: Set<string>) => void;
  changedRows: Set<string>;
}

export default function TurbineProcurementCosts({
  localTableData,
  setLocalTableData,
  setTableData,
  setDataToBeSaved,
  setChangedRows,
  changedRows,
}: TurbineProcurementCostsProps) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const [searchInput, onSearchInputChange, setSearchInput] = useTextInput("");
  const allLibraryTurbines = useAtomValue(
    turbineIdentifierAtom(organisationId),
  );

  const turbineProcurementCosts = useAtomValue(
    turbineProcurementCostsAtom(organisationId),
  );

  const formatCostsForActiveComponent = useCallback(() => {
    const formattedCosts: TableData[] = allLibraryTurbines.map((turbine) => {
      const procurementCost = turbineProcurementCosts.get(turbine.id);
      const supplyCost = procurementCost
        ? {
            value:
              procurementCost.supplyCost?.cost !== undefined
                ? procurementCost.supplyCost?.cost
                : calculateTurbineDefaultCosts({
                    diameter: turbine.rotorDiameter,
                    ratedPower: turbine.ratedPower,
                    hubHeight: turbine.hubHeight,
                  }),
            unit:
              (procurementCost.supplyCost?.unit as CostUnit) ||
              CostUnit.millionEuroPerUnit,
          }
        : turbine.legacyCost // Will be gone after migration
          ? {
              value: turbine.legacyCost,
              unit: DEFAULT_UNIT,
            }
          : {
              value: calculateTurbineDefaultCosts({
                diameter: turbine.rotorDiameter,
                ratedPower: turbine.ratedPower,
                hubHeight: turbine.hubHeight,
              }),
              unit: DEFAULT_UNIT,
            };

      const shippingCosts: Costs = procurementCost
        ? procurementCost.shippingCost.reduce((acc, curr) => {
            acc[curr.region] = {
              value: curr.cost || 0,
              unit: curr.unit as CostUnit,
            };
            return acc;
          }, {} as Costs)
        : {
            Europe: { value: 0, unit: DEFAULT_UNIT },
            Asia: { value: 0, unit: DEFAULT_UNIT },
            USA: { value: 0, unit: DEFAULT_UNIT },
            Other: { value: 0, unit: DEFAULT_UNIT },
          };

      return {
        id: turbine.id,
        name: { value: turbine.name, unit: "" },
        extraValue: turbine.ratedPower
          ? `${roundToDecimal(turbine.ratedPower / 1e3, 1)}MW`
          : "",
        costs: {
          supplyCost,
          ...shippingCosts,
        },
      };
    });

    return formattedCosts;
  }, [turbineProcurementCosts, allLibraryTurbines]);

  useEffect(() => {
    if (allLibraryTurbines.length > 0) {
      const formattedCosts = formatCostsForActiveComponent();
      setTableData(formattedCosts);
      setLocalTableData(formattedCosts);
    }
  }, [
    allLibraryTurbines,
    setTableData,
    setLocalTableData,
    formatCostsForActiveComponent,
  ]);

  const fuse = useMemo(
    () =>
      new Fuse(localTableData, {
        keys: ["name.value"],
        includeScore: true,
      }),
    [localTableData],
  );

  const searchResults = useMemo(() => {
    if (searchInput === "") {
      return localTableData;
    }
    return fuse.search(searchInput).map((result) => result.item);
  }, [localTableData, fuse, searchInput]);

  const resetToDefault = useCallback(
    (item: TableData, field: string, id: string) => {
      const updatedCosts = { ...item.costs };
      if (field === "supplyCost") {
        const turbine = allLibraryTurbines.find(
          (turbine) => turbine.id === id,
        )!;
        updatedCosts.supplyCost = {
          unit: DEFAULT_UNIT,
          value: calculateTurbineDefaultCosts({
            diameter: turbine.rotorDiameter,
            ratedPower: turbine.ratedPower,
            hubHeight: turbine.hubHeight,
          }),
        };
      } else {
        updatedCosts[field as keyof typeof updatedCosts] = {
          ...updatedCosts[field as keyof typeof updatedCosts],
          value: 0,
          unit:
            updatedCosts[field as keyof typeof updatedCosts]?.unit ??
            DEFAULT_UNIT,
        };
      }
      return { ...item, costs: updatedCosts };
    },
    [allLibraryTurbines],
  );

  const handleDataChange = useCallback(
    (field: string, newValue: number | undefined, id: string) => {
      const updatedTurbineData = localTableData.map((item) => {
        if (item.id === id) {
          if (typeof newValue === "undefined") {
            return resetToDefault(item, field, id);
          } else {
            return {
              ...item,
              costs: {
                ...item.costs,
                [field]: {
                  ...item.costs[field as keyof typeof item.costs],
                  value: newValue,
                },
              },
            };
          }
        }
        return item;
      });
      const newChangedRows = new Set(changedRows);
      newChangedRows.add(id);

      setChangedRows(newChangedRows);
      setLocalTableData(updatedTurbineData);

      const updatedCosts = updatedTurbineData.filter((cost) =>
        newChangedRows.has(cost.id),
      );

      const transformedData = transformRowToProcurementCost(updatedCosts);
      setDataToBeSaved(transformedData);
    },
    [
      localTableData,
      changedRows,
      setLocalTableData,
      setDataToBeSaved,
      setChangedRows,
      resetToDefault,
    ],
  );

  return (
    <>
      <Row>
        <SearchInput
          placeholder="Search turbines"
          value={searchInput}
          onChange={onSearchInputChange}
          onClear={() => setSearchInput("")}
        />
      </Row>
      <CostTable
        headers={TABLE_HEADERS}
        subHeaders={TABLE_SUB_HEADERS}
        data={searchResults}
        onDataChange={handleDataChange}
        emptyStateIcon={<WindTurbineIcon />}
        emptyStateDescription="No Library turbines yet. Once you have turbines in Library, they will appear here and you can associate a cost."
      />
    </>
  );
}

const transformRowToProcurementCost = (
  costData: TableData[],
): TurbineProcurementCost[] => {
  const costs = costData.map((cost) => {
    const shippingCost = REGIONS.map((region) => ({
      region,
      cost: cost.costs[region]?.value ?? 0,
      unit: (cost.costs[region]?.unit ?? DEFAULT_UNIT) as CostUnit,
    }));
    const supplyCost =
      cost.costs.supplyCost && cost.costs.supplyCost.value !== undefined
        ? {
            cost: cost.costs.supplyCost.value,
            unit: cost.costs.supplyCost.unit as CostUnit,
          }
        : undefined;
    return {
      componentType: COMPONENT_TYPE,
      componentId: cost.id,
      supplyCost: supplyCost,
      shippingCost,
    };
  });

  return z.array(_TurbineProcurementCost).parse(costs);
};
