import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { libraryCableRefreshAtom } from "components/Cabling/Generate/state";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
} from "components/Organisation/Library/service";
import { nodesWithAccessToExportCable } from "components/Organisation/Library/state";
import { useSetAtom } from "jotai";
import { getExportCableResourcesOnNode } from "services/libraryService";
import { exportCableResourceWithAccessOnNodeState } from "state/cableType";
import { libraryCableTypesRefresh } from "state/jotai/cableType";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeExportCableResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshLibraryCable = useSetAtom(libraryCableTypesRefresh);
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, cableId: string) => {
      const fallback = await get(
        exportCableResourceWithAccessOnNodeState({
          nodeId,
        }),
      );

      return addOrUpdateResourceOnNode(
        nodeId,
        cableId,
        "org_export_cable_manage",
      )
        .then(async () => {
          const updatedCables = await getExportCableResourcesOnNode(nodeId);
          aset(
            get,
            set,
            exportCableResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => updatedCables,
          );
          set(
            nodesWithAccessToExportCable({
              organisationId,
              resourceId: cableId,
            }),
          );
          set(libraryCableRefreshAtom, (cur) => cur + 1);
          refreshLibraryCable((c) => c + 1);
        })
        .catch((e) => {
          aset(
            get,
            set,
            exportCableResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId, refreshLibraryCable],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        exportCableResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "CABLE",
          resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(nodeId, "CABLE", resourceId);
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Cable type is currently being used in ${usage.length} cables, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(
          nodeId,
          resourceId,
          "org_export_cable_manage",
        )
          .then(() => {
            aset(
              get,
              set,
              exportCableResourceWithAccessOnNodeState({
                nodeId,
              }),
              (cur) => cur.filter((c) => c.cable.id !== resourceId),
            );
            set(
              nodesWithAccessToExportCable({
                organisationId,
                resourceId,
              }),
            );
            set(libraryCableRefreshAtom, (cur) => cur + 1);
            refreshLibraryCable((c) => c + 1);
          })
          .catch((e) => {
            aset(
              get,
              set,
              exportCableResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, refreshLibraryCable, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeExportCableResourcesCrud;
