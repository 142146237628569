import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useCallback } from "react";
import { currentVersionSelector } from "../../state/project";
import { projectFoldersRefreshAtom, projectFoldersAtomFamily } from "./state";
import {
  createProjectElementsFolder,
  ProjectElementFolderType,
  deleteProjectElementsFolder,
  updateProjectElementsFolder,
} from "./service";
import { dedup } from "utils/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { aset, useJotaiCallback } from "utils/jotai";

export const useRefreshProjectElementsFolders = () => {
  const setProjectFoldersRefresh = useSetAtom(projectFoldersRefreshAtom);

  return useCallback(() => {
    setProjectFoldersRefresh((currVal) => currVal + 1);
  }, [setProjectFoldersRefresh]);
};

export const useProjectElementsFoldersCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const version = useAtomValue(currentVersionSelector);

  const projectFolders = useAtomValue(
    projectFoldersAtomFamily({
      projectId,
      branchId,
      version,
    }),
  );

  const localCreate = useJotaiCallback(
    (get, set, folder: ProjectElementFolderType) => {
      return aset(
        get,
        set,
        projectFoldersAtomFamily({
          projectId,
          branchId,
          version: undefined,
        }),
        (currVal) => [...currVal, folder],
      );
    },
    [branchId, projectId],
  );

  const localUpdate = useJotaiCallback(
    (get, set, folder: Partial<ProjectElementFolderType>) => {
      return aset(
        get,
        set,
        projectFoldersAtomFamily({
          projectId,
          branchId,
          version: undefined,
        }),
        (currVal) => {
          return currVal.map((currFolder) => {
            return currFolder.folderId === folder.folderId
              ? {
                  ...currFolder,
                  ...folder,
                }
              : currFolder;
          });
        },
      );
    },
    [branchId, projectId],
  );

  const localDelete = useJotaiCallback(
    (get, set, folderId: string) => {
      return aset(
        get,
        set,
        projectFoldersAtomFamily({
          projectId,
          branchId,
          version: undefined,
        }),
        (currVal) => currVal.filter((folder) => folder.folderId !== folderId),
      );
    },
    [branchId, projectId],
  );

  const create = useCallback(
    async (
      folder: Omit<ProjectElementFolderType, "folderId">,
      onTemporaryCreated?: (tempFolderId: string) => void,
    ) => {
      if (!projectId) return;
      const tempFolderId = (Math.random() * 1000).toFixed(0).toString();
      await localCreate({
        ...folder,
        folderId: tempFolderId,
        sortOrder: Number.MAX_VALUE,
        isTemporary: true,
      });
      onTemporaryCreated?.(tempFolderId);
      const response = await createProjectElementsFolder(
        projectId,
        branchId,
        folder,
      );

      await localDelete(tempFolderId);
      await localCreate(response);
      return response;
    },
    [localCreate, projectId, branchId, localDelete],
  );

  const remove = useCallback(
    async (folderId: string) => {
      if (!projectId) return;
      await localDelete(folderId);

      await deleteProjectElementsFolder(projectId, branchId, folderId);
    },
    [branchId, projectId, localDelete],
  );

  const update = useCallback(
    async (folder: ProjectElementFolderType, newBranchId?: string) => {
      if (!projectId) return;
      folder.featureIds = dedup(folder.featureIds, (t) => t.id);
      await localUpdate(folder);
      const response = await updateProjectElementsFolder(
        projectId,
        newBranchId ?? branchId,
        folder,
      );
      return response;
    },
    [branchId, projectId, localUpdate],
  );

  return {
    items: projectFolders,
    create,
    remove,
    update,
    localCreate,
    localDelete,
    localUpdate,
  };
};
