import * as turf from "@turf/turf";
import { DateTime } from "luxon";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import PlayIcon from "@icons/24/Play.svg?react";
import PauseIcon from "@icons/24/Pause.svg?react";
import { SetterOrUpdater } from "types/utils";
import styled from "styled-components";
import tzlookup from "tz-lookup";
import * as utm from "utm";
import { v4 as uuidv4 } from "uuid";
import { libraryLayersOpenAtom } from "state/layer";
import {
  turbineScalingAtom,
  viewDateAtom,
  viewFromShoreParkIdAtom,
  viewOrigoSelector,
  viewOrigoWGS84Atom,
  viewParkRotationAtom,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTurbineCoordsAtom,
  viewTurbineHeightsAtom,
  visibleParksAtom,
  viewTurbineDiameterAtom,
} from "state/viewToPark";
import { isDefined } from "utils/predicates";
import { wgs84ToProjected } from "utils/proj4";
import { dedup } from "utils/utils";
import Button from "../../General/Button";
import Checkbox from "../../General/Checkbox";
import { Label } from "../../General/Form";
import { Input } from "../../General/Input";
import { Column, Row } from "../../General/Layout";
import { Slider } from "../../General/Slider";
import { mapboxAccessToken } from "../../MapNative/constants";
import { ChevronIcon } from "../../ToggleableList/ToggleableList";
import { ParkFeature } from "types/feature";
import { DIVISION_FACTOR } from "../ViewToPark/constants";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { spaceTiny } from "styles/space";
import { parkIdAtom } from "state/pathParams";
import { verticalFovToHorizontalFov } from "../ViewToPark/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  getDate,
  takePrintScreen,
} from "ScreenCaptureMode/ScreenCaptureWrapper";
import SplitButton from "components/General/SplitButton";
import { MenuItem } from "components/General/Menu";
import {
  Divider,
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { Tag } from "components/General/Tag";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { turbinesFamily, turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { parksFamily } from "state/jotai/park";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import {
  threeCoreParkShadowAtom,
  viewParkShadowLenghtAtom,
} from "state/viewParkShadow";
import { ToEastLatLng } from "utils/geojson/utils";
import useParkShadowTerrain from "./ThreeContext/useParkShadowMeshes";
import { WarningCircle } from "styles/errorCircle";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { TourStep } from "components/OnboardingTours/TourStep";
import { deg2rad, degreeNormalize, rad2deg } from "utils/geometry";
import * as THREE from "three";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { configurationMenuActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { SkeletonText } from "components/Loading/Skeleton";

mapboxgl.accessToken = mapboxAccessToken;

const TurbineTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-top: 1rem;
`;

const TurbineHeader = styled.p`
  font-weight: 600;
`;

const CollapsableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const MapAndControllerWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
`;

const TurbineInformation = () => {
  const parkId = useAtomValue(parkIdAtom);
  const turbineScaling = useAtomValue(turbineScalingAtom);
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId: parkId ?? "",
      branchId: undefined,
    }),
  );

  const usedTurbineTypes = useMemo(
    () =>
      dedup(turbines.map((t) => t.properties.turbineTypeId).filter(isDefined)),
    [turbines],
  );

  const usedTurbines = useMemo(
    () => usedTurbineTypes.map((t) => turbineTypes.get(t)).filter(isDefined),
    [usedTurbineTypes, turbineTypes],
  );

  const isScaled = turbineScaling !== 1;

  return (
    <>
      {usedTurbines.length === 1 && (
        <Row
          style={{
            flexWrap: "wrap",
          }}
        >
          <Tag text={usedTurbines[0].name} />
          <Tag
            text={`Height (m): ${Math.round(usedTurbines[0].hubHeight * turbineScaling)} ${isScaled ? "*" : ""}`}
          />
          <Tag
            text={`Diameter (m): ${Math.round(usedTurbines[0].diameter * turbineScaling)} ${isScaled ? "*" : ""}`}
          />
        </Row>
      )}
      {usedTurbines.length > 1 && (
        <TurbineTable>
          <TurbineHeader>Turbine</TurbineHeader>
          <TurbineHeader>Height (m)</TurbineHeader>
          <TurbineHeader>Diameter (m)</TurbineHeader>
          {usedTurbines.map((t) => (
            <React.Fragment key={t.id}>
              <p>{t.name}</p>
              <p>
                {Math.round(t.hubHeight * turbineScaling)}
                {isScaled ? "*" : ""}
              </p>
              <p>
                {Math.round(t.diameter * turbineScaling)}
                {isScaled ? "*" : ""}
              </p>
            </React.Fragment>
          ))}
        </TurbineTable>
      )}
      {isScaled && <div>* scaled size</div>}
    </>
  );
};

export const ViewParkShadow = ({
  park,
  branchId,
  turbineHeights,
  turbineDiameters,
  onClose,
}: {
  park: ParkFeature;
  branchId: string;
  turbineHeights: Map<string, number>;
  turbineDiameters: Map<string, number>;
  onClose(): void;
}) => {
  const [viewPosition, setViewPosition] = useAtom(viewPositionAtom);
  const setDate = useSetAtom(viewDateAtom);
  const [parkRotation, setParkRotation] = useAtom(viewParkRotationAtom);
  const setOrigoWGS84 = useSetAtom(viewOrigoWGS84Atom);
  const setParkId = useSetAtom(viewFromShoreParkIdAtom);
  const allParks = useAtomValue(
    parksFamily({
      branchId: undefined,
    }),
  );
  const visibleParks = useAtomValue(visibleParksAtom(park.id));
  const resetVisibleParksState = useResetAtom(visibleParksAtom(park.id));

  const allTurbines = useAtomValue(
    turbinesFamily({
      branchId: undefined,
    }),
  );
  const allVisibleTurbines = useMemo(
    () =>
      allTurbines.filter((t) => {
        const parkId = t.properties.parentIds?.[0];
        return parkId && visibleParks[parkId];
      }),
    [allTurbines, visibleParks],
  );
  const [proj4String, setProj4String] = useAtom(viewProj4StringAtom);
  const location = useLocation();
  const setLibraryLayersOpen = useSetAtom(libraryLayersOpenAtom);

  useEffect(() => {
    setParkId(park.id);
  }, [park, setParkId]);

  useEffect(() => {
    setLibraryLayersOpen(false);
  }, [setLibraryLayersOpen]);

  useEffect(() => {
    return () => {
      resetVisibleParksState();
    };
  }, [resetVisibleParksState]);

  useEffect(() => {
    if (!park) {
      setViewPosition(undefined);
      return;
    }

    const origo = turf.center(park);
    const origoUTM = utm.fromLatLon(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );

    const timezone = tzlookup(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );
    setDate(
      DateTime.fromObject(
        {
          month: 8,
          hour: 18,
        },
        {
          zone: timezone,
        },
      ),
    );

    setProj4String(
      `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    const id = uuidv4();
    setOrigoWGS84({
      ...origo,
      id,
      properties: {
        ...origo.properties,
        id,
      },
    });
    setViewPosition(
      ToEastLatLng(
        {
          lng: origo.geometry.coordinates[0],
          lat: origo.geometry.coordinates[1],
        },
        0,
      ),
    );
  }, [setProj4String, setOrigoWGS84, setViewPosition, park, setDate]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("viewposlng") && query.get("viewposlat")) {
      setViewPosition({
        lng: parseFloat(query.get("viewposlng")!),
        lat: parseFloat(query.get("viewposlat")!),
      });
    }
  }, [location, setViewPosition]);

  const origo = useAtomValue(viewOrigoSelector);

  const setViewTurbineCoordsAtom = useSetAtom(viewTurbineCoordsAtom);
  const setViewTurbineHeightsAtom = useSetAtom(viewTurbineHeightsAtom);
  const setViewTurbineDiameterAtom = useSetAtom(viewTurbineDiameterAtom);

  useEffect(() => {
    if (!origo || !proj4String) {
      setViewTurbineCoordsAtom(undefined);
      return;
    }

    const coordinates = allVisibleTurbines.map((f) => {
      return wgs84ToProjected(f.geometry.coordinates, proj4String);
    });

    if (coordinates.length === 0) {
      setViewTurbineCoordsAtom([]);
      return;
    }

    setViewTurbineCoordsAtom(
      coordinates.map((coords) => [
        (coords[0] - origo[0]) / DIVISION_FACTOR,
        (coords[1] - origo[1]) / DIVISION_FACTOR,
      ]),
    );
    setViewTurbineHeightsAtom(
      allVisibleTurbines
        .map((f) => turbineHeights.get(f.properties.id))
        .filter(isDefined),
    );
    setViewTurbineDiameterAtom(
      allVisibleTurbines
        .map((f) => turbineDiameters.get(f.properties.id))
        .filter(isDefined),
    );
  }, [
    origo,
    proj4String,
    allVisibleTurbines,
    setViewTurbineCoordsAtom,
    setViewTurbineHeightsAtom,
    turbineHeights,
    setViewTurbineDiameterAtom,
    turbineDiameters,
  ]);

  if (!origo || !viewPosition) return null;

  return (
    <MapController
      onClose={onClose}
      park={park}
      branchId={branchId}
      allParks={allParks}
      parkRotation={parkRotation}
      setParkRotation={setParkRotation}
    />
  );
};

const MapController = ({
  park,
  branchId,
  parkRotation,
  setParkRotation,
  allParks,
  onClose,
}: {
  parkRotation: number;
  setParkRotation: SetterOrUpdater<number>;
  park: ParkFeature;
  branchId: string;
  allParks: ParkFeature[];
  onClose(): void;
}) => {
  const terrainLoadable = useParkShadowTerrain();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  return (
    <>
      <TourStep
        tourId={"onshore-intro-tour"}
        stepId="viewingParkShadow"
        style={{
          top: "0rem",
          left: "-30rem",
        }}
        onEnterStepAction={() => {
          setSettingsOpen(true);
        }}
      />
      <MapAndControllerWrapper>
        <MenuFrame
          title={"View Park Shadow"}
          onExit={onClose}
          style={{ width: "30rem" }}
        >
          {terrainLoadable.state === "loading" ? (
            <Column
              style={{
                gap: 0,
              }}
            >
              <div
                style={{
                  maxHeight: `calc(72vh - 40rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
                  overflowY: "auto",
                }}
              >
                <TurbineInformation />
                <Row
                  style={{
                    alignItems: "baseline",
                  }}
                >
                  <Column style={{ width: "100%" }}>
                    <SkeletonText text={"Loading terrain..."} />
                  </Column>
                </Row>
              </div>
            </Column>
          ) : (
            <MapControllerInner
              branchId={branchId}
              park={park}
              parkRotation={parkRotation}
              setParkRotation={setParkRotation}
              allParks={allParks}
              terrainLoadable={terrainLoadable}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          )}
        </MenuFrame>
      </MapAndControllerWrapper>
    </>
  );
};

const CameraSettings = () => {
  const threeCore = useAtomValue(threeCoreParkShadowAtom);
  const [az, setAz] = useState(() => {
    const bearing = rad2deg(
      Math.PI - (threeCore?.controls?.getAzimuthalAngle?.() ?? 0),
    );
    return Math.round(degreeNormalize(bearing));
  });

  useEffect(() => {
    if (!threeCore) return;
    const onChange = () => {
      const bearing = rad2deg(Math.PI - threeCore.controls.getAzimuthalAngle());
      setAz(Math.round(degreeNormalize(bearing)));
    };
    threeCore.controls.addEventListener("change", onChange);
    return () => {
      threeCore.controls.removeEventListener("change", onChange);
    };
  }, [threeCore]);

  return (
    <Column>
      <Label>
        <p style={{ display: "inline-flex", gap: "1rem" }}>
          Bearing{" "}
          <HelpTooltip text="Zero bearing is north, and 90 bearing is east." />
        </p>
        <Slider
          min={0}
          value={az}
          max={360}
          onChange={(az) => {
            if (!threeCore) return;
            // Figure out how far off we are, and rotate around +Y the
            // remaining angle, which is upwards.
            //
            // Something weird is going on here: after rotating, the azimuth
            // isn't what it's supposed to be, but it's close. Only seems to
            // happen if you move and/or rotate the camera first, so probably
            // there's something in some of the other matrices that make it not
            // quite work.
            //
            // Dirty hack to fix: iterate (at most 10 times), and gradially get
            // closer to the target angle. When close enough, break.  This
            // works reliably.
            for (let i = 0; i < 10; i++) {
              let angle =
                deg2rad(180 - az) - threeCore.controls.getAzimuthalAngle();
              if (Math.abs(angle) < 0.001) break;

              threeCore.camera.position.applyAxisAngle(
                new THREE.Vector3(0, 1, 0),
                angle,
              );

              threeCore.controls.update();
              angle =
                deg2rad(180 - az) - threeCore.controls.getAzimuthalAngle();
            }
          }}
          label
          renderLabel={(d) => `${d}°`}
        />
      </Label>
    </Column>
  );
};

const MapControllerInner = ({
  park,
  branchId,
  parkRotation,
  setParkRotation,
  allParks,
  terrainLoadable,
  settingsOpen,
  setSettingsOpen,
}: {
  parkRotation: number;
  setParkRotation: SetterOrUpdater<number>;
  park: ParkFeature;
  branchId: string;
  allParks: ParkFeature[];
  terrainLoadable: Loadable<any>; // FIX
  settingsOpen: boolean;
  setSettingsOpen: SetterOrUpdater<boolean>;
}) => {
  const [camOpen, setCamOpen] = useState(false);
  const [turbineScaling, setTurbineScaling] = useAtom(turbineScalingAtom);
  const [date, setDate] = useAtom(viewDateAtom);
  const [visibleParks, setVisibleParks] = useAtom(visibleParksAtom(park.id));

  const threeCore = useAtomValue(threeCoreParkShadowAtom);

  const sortedParksWithSelectedFirst = useMemo(
    () => [...allParks].sort((a) => (a.id === park.id ? -1 : 0)),
    [park.id, allParks],
  );

  const relevantInvalidTypes = useAtomValue(
    invalidTypesInParkFamily({
      parkId: park.id,
      branchId,
    }),
  ).turbines;

  const download4K = () => {
    if (threeCore && park) {
      const formattedDate = getDate();
      takePrintScreen(
        [3840, 2160],
        threeCore.camera.aspect,
        threeCore,
        `${park.properties.name ?? "Unnamed_park"}_${formattedDate}.png`,
      );
    }
  };

  const downloadPanorama = async () => {
    if (!threeCore?.camera || !threeCore?.renderer || !park) return;

    const horizontalFOV = verticalFovToHorizontalFov(
      threeCore.camera.aspect,
      35,
    );

    if (!horizontalFOV) return;

    const formattedDate = getDate();
    const resolution = [3840 * 3, 2160] as [number, number];
    takePrintScreen(
      resolution,
      resolution[0] / resolution[1],
      threeCore,
      `${park.properties.name ?? "Unnamed_park"}_${formattedDate}_${horizontalFOV * 3}_deg`,
    );
  };

  const [timeLapse, setTimeLapse] = useState<boolean>(false);

  const [visibleParksOpen, setVisibleParksOpen] = useState(false);

  const [shadowLength, setShadowLength] = useAtom(viewParkShadowLenghtAtom);

  useEffect(() => {
    if (!timeLapse) return;
    const thisDate = DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
    });
    let count = 0;
    let interval = setInterval(() => {
      setDate(
        thisDate.plus({
          minutes: 10 * count,
        }),
      );
      count += 1;
    }, 100);
    return () => {
      clearInterval(interval);
      setTimeLapse(false);
    };
  }, [timeLapse, setDate, date.year, date.month, date.day]);

  return (
    <>
      {relevantInvalidTypes ? (
        <SimpleAlert
          text={"Some turbines in the park have invalid turbine types."}
        />
      ) : (
        <Column
          style={{
            gap: 0,
          }}
        >
          <div
            style={{
              maxHeight: "calc(72vh)",
              overflow: "hidden auto",
              paddingBottom: "2rem",
            }}
          >
            <TurbineInformation />
            {terrainLoadable.state === "hasError" && (
              <Row
                style={{
                  alignItems: "baseline",
                }}
              >
                <WarningCircle title="Error loading terrain" />
              </Row>
            )}
            <div
              style={{
                cursor: "pointer",
                paddingTop: `${spaceTiny}`,
              }}
              onClick={() => {
                setVisibleParksOpen(!visibleParksOpen);
              }}
            >
              <SubtitleWithLine
                text={"Visible parks"}
                expandButton={
                  <ChevronIcon
                    open={visibleParksOpen}
                    chevronSize={"1.4rem"}
                    style={{
                      alignSelf: "center",
                    }}
                  />
                }
              />
            </div>
            {visibleParksOpen && (
              <CollapsableContentWrapper>
                {sortedParksWithSelectedFirst.map((park) => {
                  const checked = visibleParks[park.id] ?? false;
                  return (
                    <Checkbox
                      key={park.id + checked}
                      checked={checked}
                      onChange={(e) => {
                        e.preventDefault();
                        setVisibleParks((state) => ({
                          ...state,
                          [park.id]: !checked,
                        }));
                      }}
                      label={park.properties.name}
                      labelPlacement="after"
                    />
                  );
                })}
              </CollapsableContentWrapper>
            )}
            <div
              style={{
                cursor: "pointer",
                paddingTop: `${spaceTiny}`,
              }}
              onClick={() => {
                setSettingsOpen(!settingsOpen);
              }}
            >
              <SubtitleWithLine
                text={"Settings"}
                expandButton={
                  <ChevronIcon
                    open={settingsOpen}
                    chevronSize={"1.4rem"}
                    style={{
                      alignSelf: "center",
                    }}
                  />
                }
              />
            </div>
            {settingsOpen && (
              <CollapsableContentWrapper>
                <Label>
                  <InputTitle>Date</InputTitle>
                  <HighlightStep
                    tourId="onshore-intro-tour"
                    stepId="viewingParkShadow"
                  >
                    <Input
                      compact
                      type="date"
                      value={date.toISO()?.split("T")[0]}
                      onChange={(e) => {
                        if (!date.zoneName || e.target.value === "") return;

                        const newDate = DateTime.fromISO(e.target.value)
                          .setZone(date.zoneName)
                          .set({
                            hour: date.hour,
                            minute: date.minute,
                          });

                        setDate(newDate);
                      }}
                    />
                  </HighlightStep>
                </Label>
                <Label>
                  <InputTitle>Time</InputTitle>
                  <Row
                    style={{
                      alignItems: "baseline",
                    }}
                  >
                    <Input
                      compact
                      style={{
                        width: "70%",
                      }}
                      type="time"
                      value={`${date.hour.toString().padStart(2, "0")}:${date.minute.toString().padStart(2, "0")}`}
                      onChange={(e) => {
                        const [hour, minute] = e.target.value
                          .split(":")
                          .map((t) => parseInt(t));
                        const newDate = date.set({
                          hour: hour,
                          minute: minute,
                        });
                        setDate(newDate);
                      }}
                    />
                    <Slider
                      min={0}
                      max={24 * 60 - 1}
                      step={1}
                      value={Math.round(date.hour * 60 + date.minute)}
                      onChange={(minutes) => {
                        const newDate = date.set({
                          hour: Math.floor(minutes / 60),
                          minute: minutes % 60,
                        });
                        setDate(newDate);
                      }}
                    />
                    <HighlightStep
                      tourId="onshore-intro-tour"
                      stepId="viewingParkShadow"
                    >
                      <Button
                        text=""
                        icon={timeLapse ? <PauseIcon /> : <PlayIcon />}
                        buttonType="secondary"
                        onClick={() => {
                          if (!timeLapse) {
                            setTimeLapse(true);
                          } else {
                            setTimeLapse(false);
                          }
                        }}
                      />
                    </HighlightStep>
                  </Row>
                </Label>

                <Label>
                  <InputTitle>Park rotation</InputTitle>
                  <RangeWithDimInput
                    min={0}
                    max={360}
                    inputStep={1}
                    value={parkRotation}
                    onChange={(r) => {
                      setParkRotation(r);
                    }}
                    unit={"deg"}
                  />
                </Label>

                <Label>
                  <InputTitle>Turbine size scaling</InputTitle>
                  <RangeWithDimInput
                    inputStep={0.1}
                    min={0.5}
                    max={1.5}
                    value={turbineScaling}
                    onChange={(n) => {
                      setTurbineScaling(n);
                    }}
                    unit={"x"}
                  />
                </Label>

                <Label>
                  <InputTitle>Turbine circle radius</InputTitle>
                  <RangeWithDimInput
                    min={0}
                    max={3000}
                    inputStep={100}
                    value={shadowLength}
                    onChange={(r) => {
                      setShadowLength(r);
                    }}
                    unit={"m"}
                  />
                </Label>
              </CollapsableContentWrapper>
            )}

            <div
              style={{
                cursor: "pointer",
                paddingTop: `${spaceTiny}`,
              }}
              onClick={() => {
                setCamOpen(!camOpen);
              }}
            >
              <SubtitleWithLine
                text={"Camera settings"}
                expandButton={
                  <ChevronIcon
                    open={camOpen}
                    chevronSize={"1.4rem"}
                    style={{
                      alignSelf: "center",
                    }}
                  />
                }
              />
            </div>
            {camOpen && <CameraSettings />}
          </div>
          <Divider
            style={{
              marginBottom: "1.6rem",
              marginLeft: "-2rem",
              marginRight: "-2rem",
            }}
          />

          <Row
            style={{
              justifyContent: "end",
            }}
          >
            <SplitButton
              buttonType="secondary"
              text="Screenshot"
              onClick={download4K}
            >
              <MenuItem
                name="4K Screenshot"
                onClick={download4K}
                defaultButton
              ></MenuItem>
              <MenuItem name="Panorama" onClick={downloadPanorama}></MenuItem>
            </SplitButton>
          </Row>
        </Column>
      )}
    </>
  );
};
