import { Amount, AmountUnit } from "types/financial";

import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { cable3DLengthsFamily, cablesInParkFamily } from "state/jotai/cable";
import {
  getBranchId,
  getParkId,
  getProjectId,
  getTurbineCapacity,
} from "analysis/inputs";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import {
  IAcableTypesByAmpacityFamily,
  IAcableTypesFamily,
} from "state/jotai/cableType";
import { sumKeys } from "utils/utils";
import { CableFeature } from "types/feature";
import { CostType, isOperationsCost } from "services/costService";
import { amountId } from "./amountIds";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { vesselTypesFamily } from "state/jotai/vesselType";

const lengthPerCableTypeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Record<string, number>>>(async (get) => {
    const projectId = await get(getProjectId(id));
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const cables = await get(cablesInParkFamily({ parkId, branchId }));
    const cableTypesMap = await get(IAcableTypesFamily({ projectId }));
    const analysisConfiguration = await get(getConfiguration(id));

    const cableLengths = await get(
      cable3DLengthsFamily({
        parkId,
        branchId,
        analysisConfigurationId: analysisConfiguration.id,
      }),
    );

    if (!cableLengths) return {};

    const lengthPerCableType = sumKeys(
      cables.map((c: CableFeature) => {
        const len = cableLengths.get(c.id)?.contingent ?? 0;
        if (
          !c.properties.cableTypeId ||
          !cableTypesMap.has(c.properties.cableTypeId)
        )
          return ["other", len];
        return [c.properties.cableTypeId, len];
      }),
    );

    return lengthPerCableType;
  }),
);

export const cableAmountsSelectorFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Amount[]>>(async (get) => {
    const projectId = get(getProjectId(id));
    const branchId = get(getBranchId(id));
    const parkId = get(getParkId(id));

    const cables = await get(
      cablesInParkFamily({ branchId: await branchId, parkId: await parkId }),
    );

    const capacity = await get(getTurbineCapacity(id));

    const cableTypes = await get(
      IAcableTypesByAmpacityFamily({ projectId: await projectId }),
    );
    const lengthPerCableType = await get(lengthPerCableTypeFamily(id));

    const kmPerType = cableTypes.map((cable) => {
      const amount = lengthPerCableType[cable.id] ?? 0;
      const unit = AmountUnit.km;
      const category = CostType.Cable;

      return {
        id: amountId({ unit, category, featureTypeId: cable.id }),
        unit,
        amount,
        category,
      };
    });

    const kmTotal = {
      id: amountId({
        unit: AmountUnit.km,
        category: CostType.Cable,
      }),
      amount: kmPerType.reduce((acc, { amount }) => acc + amount, 0),
      unit: AmountUnit.km,
      category: CostType.Cable,
    };

    const cableCount = {
      id: amountId({
        unit: AmountUnit.cable,
        category: CostType.Cable,
      }),
      unit: AmountUnit.cable,
      amount: cables.length,
      category: CostType.Cable,
    };

    const mwAmount = {
      id: amountId({
        unit: AmountUnit.MW,
        category: CostType.Cable,
      }),
      unit: AmountUnit.MW,
      amount: cables.length > 0 ? capacity : 0,
      category: CostType.Cable,
    };

    const fixedAmount = {
      id: amountId({
        unit: AmountUnit.fixed,
        category: CostType.Cable,
      }),
      unit: AmountUnit.fixed,
      amount: 1,
      category: CostType.Cable,
    };

    const configuration = await get(getConfiguration(id));

    let perDayAmount: Amount[] = [];
    if (isOperationsCost(configuration.capex.installation.cables)) {
      const installationTime =
        cables.length > 0
          ? await get(
              getTotalInstallationTime({ id, type: InstallationType.Cable }),
            )
          : 0;
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.cables;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      const mobilizationTime =
        vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

      perDayAmount.push({
        id: amountId({
          unit: AmountUnit.day,
          category: CostType.Cable,
          featureTypeId: installationVessel.vesselId,
        }),
        unit: AmountUnit.day,
        amount:
          cables.length > 0 ? (installationTime ?? 0) + mobilizationTime : 0,
        category: CostType.Cable,
      });
    }

    return [
      ...kmPerType,
      kmTotal,
      mwAmount,
      cableCount,
      fixedAmount,
      ...perDayAmount,
    ];
  }),
);
