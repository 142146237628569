import * as turf from "@turf/turf";
import { MultiPolygon, Polygon } from "geojson";
import { v4 as uuidv4 } from "uuid";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
  SUB_AREA_COLOR,
  SUB_AREA_PROPERTY_TYPE,
} from "../constants/division";
import {
  ExclusionZoneFeature,
  SubAreaFeature,
  ParkFeature,
  exclusionDomainPack,
} from "../types/feature";
import { multiFeatureToFeatures } from "utils/geojson/utils";

export const makeExclusionDivisonToExclusionDivisionPolygon = (
  f: ExclusionZoneFeature[],
) => f.map(multiFeatureToFeatures).flat() as ExclusionZoneFeature[];

export const makeExclusionZone = (
  polygon: Polygon | MultiPolygon,
): ExclusionZoneFeature => {
  const id = uuidv4();
  return {
    type: "Feature",
    id,
    geometry: polygon,
    properties: {
      id,
      type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
      color: EXCLUSION_ZONE_COLOR,
      domain: exclusionDomainPack({
        turbine: true,
        cable: true,
        substation: true,
        anchor: true,
      }),
    },
  };
};

export const makeSubArea = (
  polygon: Polygon | MultiPolygon,
  parkId: string,
): SubAreaFeature => {
  const id = uuidv4();
  console.log("polygon", polygon);
  return {
    type: "Feature",
    id,
    geometry: polygon,
    properties: {
      id,
      type: SUB_AREA_PROPERTY_TYPE,
      color: SUB_AREA_COLOR,
      parentIds: [parkId],
    },
  };
};

export const constrainFeatureToParkLayout = (
  f: SubAreaFeature,
  park: ParkFeature,
): SubAreaFeature[] => {
  if (!park) return [f];
  const parkPolygon = park.geometry;
  const intersectionFeature = turf.intersect(parkPolygon, f.geometry);
  if (!intersectionFeature) return [f];
  return [
    {
      ...f,
      geometry: intersectionFeature.geometry,
    },
  ];
};
