import { parkIdAtom } from "state/pathParams";
import { Suspense, useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { ExportCableFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import {
  exportCableSourceId,
  exportCableLayerId,
  exportCableLandfallLayerId,
  exportCableLandfallSourceId,
  exportCableLandfallSegmentLayerId,
  exportCableLandfallSegmentSourceId,
} from "./constants";
import LineString from "components/MapFeatures/LineString";
import Point from "components/MapFeatures/Point";
import { currentSelectionArrayAtom } from "state/selection";
import { atom, useAtomValue } from "jotai";
import {
  ExportCableSplit,
  exportCableSplitsFamily,
} from "state/jotai/landfall";
import { isOnshoreAtom } from "state/onshore";
import { LinePaint } from "mapbox-gl";
import { caseexpr } from "./defaults";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const exportCablePaintAtom = atom<LinePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "line-color": [
      "case",
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      [
        "any",
        ["==", ["get", "onshoreCableTypeId"], null],
        ["==", ["get", "cableTypeId"], null],
      ],
      colors.exportCableMissingType,
      onshore ? colors.onElExportCable : colors.exportCableOffshore,
    ],
    "line-opacity": caseexpr({
      fallback: 0.3,
      hover: 0.8,
      selected: 1,
      active: 1,
      state: { borderColor: 0.8 },
    }),
    "line-width": caseexpr({
      fallback: 2,
      selected: 4,
      hover: 4,
      state: { borderColor: 4 },
    }),
  };
});

const landfallPointPaint: mapboxgl.CirclePaint = {
  "circle-color": "black",
  "circle-radius": 4,
};

const landfallSegmentPaint: mapboxgl.LinePaint = {
  "line-color": [
    "case",
    ["==", ["get", "type"], "onshore"],
    colors.exportCableOnshore,
    ["==", ["get", "type"], "offshore"],
    colors.exportCableOffshore,
    colors.exportCableMissingType,
  ],
  "line-width": caseexpr({
    fallback: 2,
    selected: 4,
    hover: 4,
  }),
};

const landfallPointSymbol: Omit<mapboxgl.SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 11.0,
  layout: {
    "text-field": "landfall point",
    "text-offset": [1, 0],
    "text-size": 10,
    "text-anchor": "left",
  },
};

const RenderLandfall = ({
  map,
  features,
  parkId,
}: {
  features: ExportCableFeature[];
  map: mapboxgl.Map;
  parkId: string;
}) => {
  const selection = useAtomValue(currentSelectionArrayAtom);
  const splits = useAtomValue(
    exportCableSplitsFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const { lines, points } = useMemo(() => {
    const oks = splits.filter((s): s is ExportCableSplit => !("error" in s));
    const ids = new Set(features.map((f) => f.id));
    const sp = oks.filter((s) => ids.has(s.exportCable.id));
    const offshores = sp.map((split) => split.offshore);
    const onshores = sp.map((split) => split.onshore);
    const landfallPoints = sp.map((split) => split.landfallPoint);
    const selected = sp.map((split) =>
      selection.includes(split.exportCable.id),
    );

    return {
      lines: onshores
        .map((f, i) => {
          const type =
            oks[i].exportCable.properties.onshoreCableTypeId === undefined
              ? undefined
              : "onshore";
          return {
            ...f,
            properties: {
              ...f.properties,
              type,
              selected: selected[i],
            },
          };
        })
        .concat(
          offshores.map((f, i) => {
            const type =
              oks[i].exportCable.properties.cableTypeId === undefined
                ? undefined
                : "offshore";
            return {
              ...f,
              properties: {
                ...f.properties,
                type,
                selected: selected[i],
              },
            };
          }),
        ),
      points: landfallPoints,
    };
  }, [features, selection, splits]);

  return (
    <>
      <LineString
        features={lines}
        map={map}
        paint={landfallSegmentPaint}
        sourceId={exportCableLandfallSegmentSourceId}
        layerId={exportCableLandfallSegmentLayerId}
      />
      <Point
        features={points}
        map={map}
        paint={landfallPointPaint}
        sourceId={exportCableLandfallSourceId}
        layerId={exportCableLandfallLayerId}
        symbols={landfallPointSymbol}
      />
    </>
  );
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  exportCableLayerId,
  exportCableSourceId,
);

export const RenderExportCables = ({
  exportCables: features,
  map,
  showSelectionHighlighter,
}: {
  exportCables: ExportCableFeature[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const parkId = useAtomValue(parkIdAtom);

  const exportCablePaint = useAtomValue(exportCablePaintAtom);

  useEffect(() => {
    map.addSource(exportCableSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, exportCableLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(exportCableSourceId);
    };
  }, [map, exportCablePaint]);

  useEffect(() => {
    addLayer(map, {
      id: exportCableLayerId,
      type: "line",
      source: exportCableSourceId,
      paint: exportCablePaint,
    });
    addLayer(map, hiddenClickLayer);
  }, [map, exportCablePaint]);

  useEffect(() => {
    const source = map.getSource(exportCableSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  if (!parkId) return null;
  return (
    <Suspense fallback={null}>
      <RenderLandfall features={features} map={map} parkId={parkId} />
      <AblySelectionHighlighter
        map={map}
        sourceId={exportCableSourceId}
        featureIds={featureIds}
        enabled={showSelectionHighlighter}
      />
    </Suspense>
  );
};
