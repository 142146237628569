import type { WaveData, WaveRoseType } from "state/waveStatistics";

export function waveData2WaveRose(
  waveData: WaveData,
  nDirections: number,
): WaveRoseType {
  const { hs, direction } = waveData;
  const bucketSize = 360 / nDirections;

  const directionCounts = direction.reduce<
    {
      count: number;
      hsSum: number;
    }[]
  >(
    (acc, d, i) => {
      if (waveData.hs[i] < 0) return acc;
      const bucketNumber = Math.floor(
        ((d + bucketSize / 2) % 360) / bucketSize,
      );
      acc[bucketNumber] = {
        ...acc[bucketNumber],
        count: acc[bucketNumber].count + 1,
        hsSum: acc[bucketNumber].hsSum + hs[i],
      };
      return acc;
    },
    new Array(nDirections).fill({
      count: 0,
      hsSum: 0,
    }),
  );
  const totalCount = directionCounts.reduce((acc, o) => (acc += o.count), 0);
  const directionProbabilities = directionCounts.map(
    (c) => c.count / totalCount,
  );

  const meanHs = directionCounts.map((c) => c.hsSum / c.count);

  return {
    directions: directionProbabilities.map((p, i) => ({
      probability: p,
      direction: i * bucketSize,
      hs: meanHs[i],
    })),
    longitude: waveData.longitude,
    latitude: waveData.latitude,
  };
}
