import { isDefined } from "../../utils/predicates";
import { omitFields } from "../../utils/utils";
import { DnDElement } from "../General/DnDGrid/types";
import { CableMatrixMapWidget } from "./Widgets/CableMatrixMapWidget";
import { CableMatrixWidget } from "./Widgets/CableMatrixWidget";
import { CableUsageWidget } from "./Widgets/CableUsageWidget";
import { CapexPieChartWidget } from "./Widgets/CapexPieChartWidget";
import { CapexTableWidget } from "./Widgets/CapexTableWidget";
import { CumulativeGraphWidget } from "./Widgets/CumulativeProductionWidget";
import { EnergyWidget } from "./Widgets/EnergyWidget";
import { InputWidget } from "./Widgets/InputWidget";
import { LCoEWidget } from "./Widgets/LCOEWidget";
import { LossWidget } from "./Widgets/LossWidget";
import { MapWidget } from "./Widgets/MapWidget";
import { MonthlyProductionWidget } from "./Widgets/MonthlyProductionWidget";
import { MooringLineAttachmentsWidget } from "./Widgets/MooringLineAttachmentsWidget";
import { MooringLinesWidget } from "./Widgets/MooringLinesWidget";
import { ParkWidget } from "./Widgets/ParkWidget";
import { TextWidget } from "./Widgets/TextWidget";
import { TurbineListWidget } from "./Widgets/TurbineListWidget";
import { WindRoseWidget } from "./Widgets/WindRoseWidget";
import { Dashboard, DashboardSerialize, WidgetId } from "./types";
import { MooringLineAnchorsWidget } from "./Widgets/MooringLineAnchorsWidget";
import { FoundationKeyStatisticsWidget } from "./Widgets/FoundationKeyStatisticsWidget";
import { FoundationTotalsWidget } from "./Widgets/FoundationTotalsWidget";
import { ThreeDFoundationWidget } from "./Widgets/ThreeDFoundationWidget";
import { FoundationListWidget } from "./Widgets/FoundationListWidget";
import { WindDistributionWidget } from "./Widgets/WindDistributionWidget";
import { ExportCableLoadingWidget } from "./Widgets/ExportCableLoadingWidget";
import { ExportSystemVoltageWidget } from "./Widgets/ExportCableVoltageWidget";
import { AEPDistributionWidget } from "./Widgets/AEPDistributionWidget";
import { ParkLifeCashFlowWidget } from "./Widgets/ParkLifeCashFlowWidget";
import { InterArrayLossGraphWidget } from "./Widgets/InterArrayLossGraphWidget";
import { IRRWidget } from "./Widgets/IRRWidget";
import { CostInputWidget } from "./Widgets/CostInputWidget";
import { OtherExpendituresInputWidget } from "./Widgets/OtherExpendituresInputWidget";
import { CapexInputWidget } from "./Widgets/CapexInputWidget";
import { ExportCablesWidget } from "components/Dashboard/Widgets/ExportCablesWidget";
import { WaveRoseWidget } from "./Widgets/WaveRoseWidget";
import { WaveDistributionWidget } from "./Widgets/Metocean/WaveDistributionWidget";
import { MetoceanMapWidget } from "./Widgets/Metocean/MetoceanMapWidget";
import { WindDetailsWidget } from "./Widgets/WindDetails";
import { WaveDetailsWidget } from "./Widgets/Metocean/WaveDetails";
import { MooringLinesListWidget } from "./Widgets/MooringLinesListWidget";
import { TurbineCapacityFactorsHistogramWidget } from "./Widgets/TurbineCapacityFactorsHistogram";
import { InstallationTimeBreakdownWidget } from "./Widgets/InstallationTimeBreakdownWidget";
import { InstallationTimeDistributionWidget } from "./Widgets/InstallationTimeDistributionWidget";
import { InstallationTableWidget } from "./Widgets/InstallationTableWidget";
import { InstallationSCurveWidget } from "./Widgets/InstallationSCurveWidget";
import { ElectricalLossWidget } from "./Widgets/ElectricalLossWidget";

const WidgetToComponent: Record<WidgetId, undefined | React.ComponentType> = {
  "Lcoe table": LCoEWidget,
  "IRR table": IRRWidget,
  Energy: EnergyWidget,
  Foundations: FoundationListWidget,
  "Foundation key statistics": FoundationKeyStatisticsWidget,
  "Foundation totals": FoundationTotalsWidget,
  Park: ParkWidget,
  "Wind rose": WindRoseWidget,
  "Wind details": WindDetailsWidget,
  "Wave details": WaveDetailsWidget,
  "Wave rose": WaveRoseWidget,
  "Capex input": CapexInputWidget,
  "Capex table": CapexTableWidget,
  "Capex pie chart": CapexPieChartWidget,
  "Cost input": CostInputWidget,
  "Park life cash flow": ParkLifeCashFlowWidget,
  "Monthly graph": MonthlyProductionWidget,
  "Wind speed distribution": WindDistributionWidget,
  "Wave height distribution": WaveDistributionWidget,
  "Annual variability": AEPDistributionWidget,
  Map: MapWidget,
  "Metocean map": MetoceanMapWidget,
  "Cumulative graph": CumulativeGraphWidget,
  Turbines: TurbineListWidget,
  Input: InputWidget,
  Losses: LossWidget,
  Cabling: CableUsageWidget,
  "Cable matrix": CableMatrixWidget,
  "Export cables": ExportCablesWidget,
  "Cable matrix map": CableMatrixMapWidget,
  "Electrical losses": ElectricalLossWidget,
  Text: TextWidget,
  "Mooring lines": MooringLinesWidget,
  "Mooring lines list": MooringLinesListWidget,
  "Mooring line attachments": MooringLineAttachmentsWidget,
  "Mooring line anchors": MooringLineAnchorsWidget,
  "Other expenditures input": OtherExpendituresInputWidget,
  "3D-foundation": ThreeDFoundationWidget,
  "Export cable loading": ExportCableLoadingWidget,
  "Export system voltage": ExportSystemVoltageWidget,
  "Inter array loss graph": InterArrayLossGraphWidget,
  "Turbine capacity factors": TurbineCapacityFactorsHistogramWidget,
  "Installation time breakdown chart": InstallationTimeBreakdownWidget,
  "Installation time variability": InstallationTimeDistributionWidget,
  "Installation table": InstallationTableWidget,
  "Installation S curve": InstallationSCurveWidget,
};

/** Formats a number `12345.6789` to e.g. `12,345.68`, depending on locale. */
export const kmStyle = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const dndEntry = (id: WidgetId): undefined | DnDElement => {
  const Component = WidgetToComponent[id];
  if (Component === undefined) return undefined;
  return {
    id,
    component: <Component />,
  };
};

export const deserializeDashboard = (ds: DashboardSerialize): Dashboard => {
  return {
    ...ds,
    widgetState: ds.widgetState ?? {},
    rows: deserializeDashboardRows(ds.rows),
  };
};

export const serializeDashboardRows = (
  rows: Dashboard["rows"],
): DashboardSerialize["rows"] => {
  return (
    rows?.map((row) => ({
      ...row,
      elements: row.elements.map((el) => omitFields(el, "component")),
    })) ?? []
  );
};

export const deserializeDashboardRows = (
  rows: DashboardSerialize["rows"],
): Dashboard["rows"] => {
  return (
    rows?.map((row) => ({
      ...row,
      elements: row.elements
        .map((el) => dndEntry(el.id as WidgetId))
        .filter(isDefined),
    })) ?? []
  );
};

export const serializeDashboard = (
  dashboard: Dashboard,
): DashboardSerialize => {
  return {
    ...dashboard,
    rows: serializeDashboardRows(dashboard.rows),
  };
};
