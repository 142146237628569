import { SetStateAction, useAtom } from "jotai";
import { mapAtom } from "state/map";
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { imageToGeorefAtom } from "../../state/georef";
import { StandardBox } from "../../styles/boxes/Boxes";
import { THIRTY_MEGABYTES } from "../../services/projectDataAPIService";
import { uploadGeoTiff } from "services/uploadGeoTiff";
import { toastMessagesAtom } from "../../state/toast";
import { ProjectFeature } from "../../types/feature";
import { GeotiffType } from "../../utils/geojson/utils";
import eventEmitter from "../../utils/eventEmitter";
import Point from "../MapFeatures/Point";
import { Feature } from "geojson";
import Button from "../General/Button";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { MapMouseEvent } from "mapbox-gl";
import {
  mouseMoveHandlerAtom,
  clickHandlerAtom,
  doubleClickHandlerAtom,
} from "components/Mapbox/state";
import { projectIdAtom } from "state/pathParams";
import { useAtomValue, useSetAtom } from "jotai";
import { georefImage } from "utils/gdal";
import { GeoRefPoint } from "types/gis";
import { Row } from "components/General/Layout";
import { spacing4 } from "styles/space";
import { WithTooltip } from "components/General/Tooltip";

const sourceLayerId = "georefPoints";

const Wrapper = styled(StandardBox)`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: calc(var(--side-bars-width) + ${spacing4});
  font-size: 1.3rem;
  line-height: 2;
  outline: none;
  justify-content: center;
  padding: 20px 10px;
`;

const Header = styled.h3``;

const Points = styled.div``;

const ButtonGeoref = styled(Button)`
  margin: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CanvasWrapper = styled.div<{ disabled: boolean }>`
  width: 40vw;
  height: 40vh;
  overflow: hidden;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "crosshair")};
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
`;

const GeorefPoint = styled.div<{
  completed: boolean;
}>`
  background-color: ${({ completed }) => (completed ? "red" : "gray")};
  border-radius: 0.8rem;
  height: 0.8rem;
  width: 0.8rem;
`;

export const GEOREF_DONE_EVENT = "geoRefDoneEvent";
const useHandleGeorefedImage = (
  coords: GeoRefPoint[],
  imageToGeoref?: File,
) => {
  const projectId = useAtomValue(projectIdAtom);
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const { update: updateFeatures } = useProjectElementsCrud();

  return useCallback(async () => {
    if (!imageToGeoref || !projectId) return;
    setToastMessages((tm) => [
      ...tm,
      {
        text: "Processing image...",
        timeout: 2000,
      },
    ]);
    const blob = await georefImage(imageToGeoref, coords);
    const geotiffLayerEntry: ProjectFeature | undefined = await uploadGeoTiff(
      blob,
      projectId!,
      setToastMessages,
      GeotiffType.georefimage,
      THIRTY_MEGABYTES,
      imageToGeoref?.name,
    );

    if (geotiffLayerEntry)
      updateFeatures({
        add: [geotiffLayerEntry],
      });
  }, [setToastMessages, imageToGeoref, coords, projectId, updateFeatures]);
};

const ZoomableImageToGeoref = ({
  setCoords,
  objectURL,
  coords,
  disabled,
}: {
  setCoords: Dispatch<SetStateAction<GeoRefPoint[]>>;
  objectURL: string | undefined;
  coords: GeoRefPoint[];
  disabled: boolean;
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [scaleOffset, setScaleOffset] = useState({ x: 0, y: 0, scale: 1 });

  const mouseClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect || disabled) return;

      const adjustedX =
        (e.clientX - rect.left - scaleOffset.x) / scaleOffset.scale;
      const adjustedY =
        (e.clientY - rect.top - scaleOffset.y) / scaleOffset.scale;

      const percentageX = (adjustedX / imageRef.current!.naturalWidth) * 100;
      const percentageY = (adjustedY / imageRef.current!.naturalHeight) * 100;

      setCoords((coords) => [
        ...coords,
        {
          imgCoords: {
            x: Math.max(adjustedX, 0),
            y: Math.max(adjustedY, 0),
            percentage: [percentageX * 100, percentageY * 100],
          },
        },
      ]);
    },
    [setCoords, scaleOffset, disabled],
  );

  useEffect(() => {
    if (!objectURL || !canvasRef.current) return;

    const img = new Image();
    img.src = objectURL;
    img.onload = () => {
      imageRef.current = img;
      const canvas = canvasRef.current;
      if (!canvas) return;

      //Get size of canvas parent
      const parent = canvas.parentElement;
      if (!parent) return;
      const parentWidth = parent.clientWidth;
      const parentHeight = parent.clientHeight;

      canvas.width = parentWidth;
      canvas.height = parentHeight;

      // Calculate scale to fit image
      const scaleX = parentWidth / img.naturalWidth;
      const scaleY = parentHeight / img.naturalHeight;
      const scale = Math.min(scaleX, scaleY);

      // Calculate centering offsets
      const offsetX = (parentWidth - img.naturalWidth * scale) / 2;
      const offsetY = (parentHeight - img.naturalHeight * scale) / 2;

      setScaleOffset({
        x: offsetX,
        y: offsetY,
        scale: scale,
      });

      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      ctx.drawImage(img, 0, 0);
    };
  }, [objectURL, setScaleOffset]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const handleWheel = (event: WheelEvent) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const imageX = (mouseX - scaleOffset.x) / scaleOffset.scale;
      const imageY = (mouseY - scaleOffset.y) / scaleOffset.scale;

      const zoomFactor = event.deltaY < 0 ? 1.1 : 0.9;
      const newScale = scaleOffset.scale * zoomFactor;
      const boundedScale = Math.max(0.1, Math.min(10, newScale));

      const newScaleOffset = {
        x: mouseX - imageX * boundedScale,
        y: mouseY - imageY * boundedScale,
        scale: boundedScale,
      };

      setScaleOffset(newScaleOffset);
    };

    const handleMouseDown = (event: MouseEvent) => {
      let mouseMoved = false;
      const startX = event.clientX - scaleOffset.x;
      const startY = event.clientY - scaleOffset.y;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        mouseMoved = true;
        setScaleOffset({
          x: moveEvent.clientX - startX,
          y: moveEvent.clientY - startY,
          scale: scaleOffset.scale,
        });
      };

      const handleMouseUp = (event: MouseEvent) => {
        if (!mouseMoved) {
          mouseClick(event);
        }
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    };

    canvas.addEventListener("wheel", handleWheel, {
      passive: true,
    });
    canvas.addEventListener("mousedown", handleMouseDown);

    return () => {
      canvas.removeEventListener("wheel", handleWheel);
      canvas.removeEventListener("mousedown", handleMouseDown);
    };
  }, [scaleOffset, mouseClick]);

  useEffect(() => {
    if (!canvasRef.current || !imageRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.save();

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.scale(scaleOffset.scale, scaleOffset.scale);
    ctx.translate(
      scaleOffset.x / scaleOffset.scale,
      scaleOffset.y / scaleOffset.scale,
    );

    ctx.drawImage(imageRef.current, 0, 0);

    coords.forEach((c) => {
      const { x, y } = c.imgCoords;
      ctx.fillStyle = "red";
      ctx.beginPath();
      ctx.arc(x, y, 3 / scaleOffset.scale, 0, 2 * Math.PI); // Adjust dot size based on scale
      ctx.fill();
    });

    ctx.restore();
  }, [scaleOffset, coords]);

  return (
    <CanvasWrapper disabled={disabled}>
      <canvas ref={canvasRef} />
    </CanvasWrapper>
  );
};

const GeorefImageModalInner = () => {
  const [imageToGeoref, setImageToGeoref] = useAtom(imageToGeorefAtom);
  const [coords, setCoords] = useState<GeoRefPoint[]>([]);
  const objectURL = useMemo(() => {
    if (!imageToGeoref) return;
    return URL.createObjectURL(imageToGeoref);
  }, [imageToGeoref]);
  const map = useAtomValue(mapAtom);
  const georefImage = useHandleGeorefedImage(coords, imageToGeoref);

  const setMouseMoveHandler = useSetAtom(mouseMoveHandlerAtom);
  const setClickHandler = useSetAtom(clickHandlerAtom);
  const setDoubleClickHandler = useSetAtom(doubleClickHandlerAtom);

  const waitingForLatLng = useMemo(() => {
    const lastElement = coords.slice(-1)[0];
    return lastElement && !lastElement.mapCoords;
  }, [coords]);

  const mouseClick = useCallback(
    (e: MapMouseEvent) => {
      e.preventDefault();
      setCoords((coords) => {
        const last = coords.slice(-1)[0];
        return [
          ...coords.slice(0, -1),
          {
            ...last,
            mapCoords: {
              lng: parseFloat(e.lngLat.lng.toFixed(4)),
              lat: parseFloat(e.lngLat.lat.toFixed(4)),
            },
          },
        ];
      });
    },
    [setCoords],
  );

  useEffect(() => {
    if (!map) return;
    if (waitingForLatLng) {
      map.getCanvas().style.cursor = "crosshair";
      setClickHandler(() => mouseClick);
      setMouseMoveHandler(() => () => {});
      setDoubleClickHandler(() => () => {});
      return () => {
        setMouseMoveHandler(undefined);
        setDoubleClickHandler(undefined);
        setClickHandler(undefined);
      };
    }
    map.getCanvas().style.cursor = "unset";
  }, [
    map,
    waitingForLatLng,
    mouseClick,
    setClickHandler,
    setMouseMoveHandler,
    setDoubleClickHandler,
  ]);

  const mapPoints: Feature[] = useMemo(() => {
    if (!map) return [];
    return coords
      .filter((c) => c.mapCoords != null)
      .map((c, i) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [c.mapCoords!.lng, c.mapCoords!.lat],
          },
          properties: {
            id: i,
          },
        };
      });
  }, [map, coords]);

  useEffect(() => {
    const undo = (event: KeyboardEvent) => {
      if (
        event.isComposing ||
        event.code !== "KeyZ" ||
        (!event.metaKey && !event.ctrlKey) ||
        event.shiftKey
      ) {
        return;
      }
      setCoords(coords.slice(0, -1));
    };

    window.addEventListener("keydown", undo);
    return () => {
      window.removeEventListener("keydown", undo);
    };
  }, [setCoords, coords]);

  if (!map) return null;

  return (
    <Wrapper>
      <Header>Georeference Image</Header>
      <Row>
        <Points>{`Number of points: ${coords.length} (min 3 required)`}</Points>
        <PointsWrapper onClick={() => setCoords((c) => c.slice(0, -1))}>
          {coords.map((c) => (
            <WithTooltip
              key={JSON.stringify(c.imgCoords)}
              text="Click to remove last added point"
            >
              <GeorefPoint completed={c.mapCoords != null} />
            </WithTooltip>
          ))}
        </PointsWrapper>
      </Row>
      <ZoomableImageToGeoref
        setCoords={setCoords}
        objectURL={objectURL}
        coords={coords}
        disabled={waitingForLatLng}
      />
      <ButtonWrapper>
        <ButtonGeoref
          text="Save image"
          disabled={coords.length < 3 || waitingForLatLng}
          onClick={() => {
            georefImage();
            setImageToGeoref(undefined);
            setCoords([]);
            eventEmitter.emit(GEOREF_DONE_EVENT, true);
          }}
        />
        <ButtonGeoref
          text="Cancel"
          buttonType="secondary"
          onClick={() => {
            setImageToGeoref(undefined);
            setCoords([]);
            eventEmitter.emit(GEOREF_DONE_EVENT, false);
          }}
        />
      </ButtonWrapper>
      <Point
        map={map}
        features={mapPoints}
        layerId={sourceLayerId}
        sourceId={sourceLayerId}
        paint={{
          "circle-radius": 3,
          "circle-color": "#f00",
        }}
      />
    </Wrapper>
  );
};

const GeorefImageModal = () => {
  const imageToGeoref = useAtomValue(imageToGeorefAtom);

  if (!imageToGeoref) return null;

  return <GeorefImageModalInner />;
};

export default GeorefImageModal;
