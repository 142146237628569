import * as turf from "@turf/turf";
import {
  threedCableLayerId,
  threedMooringLayerId,
  threedTurbineLayerId,
} from "components/Mapbox/constants";
import { cableVisualization } from "functions/lazyWave";
import { useBathymetry } from "hooks/bathymetry";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback, useEffect, useMemo, useRef } from "react";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { cablesInParkFamily } from "state/jotai/cable";
import { foundationTypesAtom } from "state/jotai/foundation";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { currentParkAtom } from "state/jotai/park";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { foundations3dLayerId } from "../../constants/projectMapView";
import {
  MooringCoords,
  mooringSegmentVisualization,
} from "../../functions/mooring";
import { foundationScale } from "../../state/foundations";
import { parkIdAtom, projectIdAtom } from "../../state/pathParams";
import { previewTurbinesState } from "../../state/turbines";
import { TurbineFeature } from "../../types/feature";
import groupBy from "../../utils/groupBy";
import { safeRemoveLayer } from "../../utils/map";
import {
  isDefined,
  isMooringLineMultiple,
  isNumber,
} from "../../utils/predicates";
import { dedup, sum } from "../../utils/utils";
import { previewMooringAndFoundationState } from "../GenerateFoundationsAndAnchors/state";
import { addLayer, getBeforeLayer } from "../Mapbox/utils";
import { isFloater } from "utils/predicates";
import { ThreeDCables } from "./3Dcables";
import { ThreeDFoundations } from "./3Dfoundations";
import { ThreeDMooring } from "./3Dmooring";
import { mooringLineTypesAtom } from "state/jotai/mooringLineType";
import { getMapboxTerrainSampleFamily } from "state/jotai/terrain";
import { useAtomUnwrap } from "utils/jotai";
import { useDeep } from "hooks/useDeep";
import { ThreeDTurbineData, ThreeDTurbines2 } from "./3Dturbines2";
import { turbineModelAtom } from "3d/turbine_glb";
import { isOnshoreAtom } from "state/onshore";

export const Feature3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const onshore = useAtomValue(isOnshoreAtom);
  return (
    <Suspense fallback={null}>
      <Turbines3DRender map={map} features={features} />
      {!onshore && (
        <>
          <Foundation3DRender map={map} />
          <Mooring3DRender features={features} map={map} />
          <Cables3DRender features={features} map={map} />
        </>
      )}
    </Suspense>
  );
};

const turbinefeatures = atom<undefined | TurbineFeature[]>();

const getdata = atom<Promise<ThreeDTurbineData | undefined>>(async (get) => {
  const turbines = get(turbinefeatures);
  if (!turbines) return;
  const turbineTypes = await get(simpleTurbineTypesAtom);

  const lonlats = turbines.map((f) => {
    const c = f.geometry.coordinates;
    return [c[0], c[1]];
  });

  const elevations = await get(
    getMapboxTerrainSampleFamily({ coords: lonlats }),
  );

  return turbines
    .map((t, i) => {
      const [lon, lat] = lonlats[i];
      const elevation = elevations[i];
      const typ = turbineTypes.get(t.properties.turbineTypeId);
      if (!typ) return;
      const { diameter, hubHeight } = typ;

      return {
        lon,
        lat,
        elevation,
        diameter,
        hubHeight,
      };
    })
    .filter(isDefined);
});

const Turbines3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const setTurbines = useSetAtom(turbinefeatures);
  const deepFeatures = useDeep(features);
  useEffect(() => {
    setTurbines(deepFeatures);
  }, [deepFeatures, setTurbines]);

  const turbineGLTF = useAtomValue(turbineModelAtom);

  const threeTurbinesLayer = useMemo(() => {
    const refPt = deepFeatures.at(0)?.geometry ?? turf.point([0, 0]).geometry;
    return new ThreeDTurbines2(threedTurbineLayerId, turbineGLTF, refPt);
  }, [deepFeatures, turbineGLTF]);

  const data = useAtomUnwrap(getdata);
  useEffect(() => {
    if (data) threeTurbinesLayer.setData(data);
  }, [data, threeTurbinesLayer]);

  useEffect(() => {
    if (!map || !threeTurbinesLayer) return;

    safeRemoveLayer(map, threedTurbineLayerId);
    map.addLayer(
      threeTurbinesLayer,
      getBeforeLayer(map, threeTurbinesLayer.id),
    );

    return () => {
      safeRemoveLayer(map, threedTurbineLayerId);
    };
  }, [map, threeTurbinesLayer]);

  return null;
};

const Foundation3DRender = ({ map }: { map: mapboxgl.Map }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const park = useAtomValue(currentParkAtom);
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const parkTurbines = useAtomValue(
    turbinesInParkFamily({ parkId, branchId: undefined }),
  );
  const previewParkTurbines = useAtomValue(previewTurbinesState);

  const allFoundationTypes = useAtomValue(foundationTypesAtom);

  const [, raster] = useBathymetry({
    projectId,
    featureId: parkId,
    branchId: undefined,
    bufferKm: undefined,
  });

  const foundationIds = useMemo(() => {
    return dedup(
      parkTurbines.map((f) => f.properties.foundationId).filter(isDefined),
    );
  }, [parkTurbines]);

  const previewMooringAndFoundations = useAtomValue(
    previewMooringAndFoundationState,
  );

  const previewFoundations = useMemo(
    () =>
      previewMooringAndFoundations
        ? previewMooringAndFoundations.preview.foundations
        : [],
    [previewMooringAndFoundations],
  );

  const turbinesByFoundationId = groupBy(
    [...parkTurbines, ...(previewParkTurbines?.preview ?? [])].filter(
      (f) =>
        isDefined(f.properties.foundationId) ||
        previewFoundations.find((p) => p.turbineId === f.id),
    ),
    (f) =>
      previewFoundations.find((p) => p.turbineId === f.id)?.foundationId ??
      f.properties.foundationId!,
  );

  const threeFoundations = useMemo(
    () =>
      Object.entries(turbinesByFoundationId).map(([foundationId, features]) => {
        if (!raster) return undefined;
        const foundation = allFoundationTypes.get(foundationId);

        const lonlats = features.map(
          (f) => f.geometry.coordinates as [number, number],
        );

        const turbines = features
          .map((t) => turbineTypes.get(t.properties.turbineTypeId))
          .filter(isDefined);

        const numFeatures = features.length;

        const waterDepthSum = -sum(
          features.filter((f) =>
            raster.contains(
              f.geometry.coordinates[0],
              f.geometry.coordinates[1],
            ),
          ),
          (f) =>
            raster.latLngToValue(
              f.geometry.coordinates[0],
              f.geometry.coordinates[1],
              0,
            ),
        );

        const waterDepth = numFeatures !== 0 ? waterDepthSum / numFeatures : -1;

        return new ThreeDFoundations(
          lonlats,
          turbines,
          waterDepth,
          foundation,
          parkId ?? "",
        );
      }),
    [allFoundationTypes, raster, parkId, turbineTypes, turbinesByFoundationId],
  ).filter(isDefined);

  const foundations3dLayerParkId = foundations3dLayerId + (park?.id ?? "");

  useEffect(() => {
    if (!threeFoundations) return;

    foundationIds.forEach((id) => {
      const layerId = foundations3dLayerParkId + id;
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });

    threeFoundations.forEach((layer) => {
      map.addLayer(layer, getBeforeLayer(map, foundations3dLayerParkId));
    });

    return () => {
      threeFoundations.forEach((layer) => {
        if (map.getLayer(layer.id)) {
          map.removeLayer(layer.id);
        }
      });
    };
  }, [map, threeFoundations, foundationIds, park, foundations3dLayerParkId]);

  return null;
};

const Mooring3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const mooringLineCache = useRef(new Map());
  const threeMooringRef = useRef<ThreeDMooring | null>(null);

  const allTurbines = useAtomValue(simpleTurbineTypesAtom);
  const allFoundationTypes = useAtomValue(foundationTypesAtom);
  const previewMooringAndFoundations = useAtomValue(
    previewMooringAndFoundationState,
  );
  const previewFoundations = useMemo(
    () =>
      previewMooringAndFoundations
        ? previewMooringAndFoundations.preview.foundations
        : [],
    [previewMooringAndFoundations],
  );

  const parkId = useAtomValue(parkIdAtom) ?? "";

  const parkAnchors = useAtomValue(
    anchorsInParkFamily({ parkId, branchId: undefined }),
  );
  const parkMooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId, branchId: undefined }),
  );

  const refPt = useMemo(
    () => features.at(0)?.geometry ?? turf.point([0, 0]).geometry,
    [features],
  );

  const anchors = useMemo(
    () =>
      previewMooringAndFoundations
        ? [
            ...previewMooringAndFoundations.preview.anchors,
            ...previewMooringAndFoundations.existing.anchors,
          ]
        : parkAnchors,
    [previewMooringAndFoundations, parkAnchors],
  );

  const turbineMap = useMemo(
    () => new Map(features.map((a) => [a.id, a])),
    [features],
  );
  const anchorMap = useMemo(
    () => new Map(anchors.map((a) => [a.id, a])),
    [anchors],
  );

  const mooringLines = useMemo(
    () =>
      previewMooringAndFoundations
        ? [
            ...previewMooringAndFoundations.preview.mooringLines,
            ...previewMooringAndFoundations.existing.mooringLines,
          ]
        : parkMooringLines,
    [previewMooringAndFoundations, parkMooringLines],
  );

  const lineTypes = useAtomValue(mooringLineTypesAtom);

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [, raster] = useBathymetry({
    projectId,
    featureId: parkId,
    branchId: undefined,
    bufferKm: undefined,
  });

  useEffect(() => {
    if (!threeMooringRef.current) {
      threeMooringRef.current = new ThreeDMooring(
        [],
        threedMooringLayerId,
        refPt,
      );
      addLayer(map, threeMooringRef.current);
    }
    return () => {
      if (threeMooringRef.current) safeRemoveLayer(map, threedMooringLayerId);
    };
  }, [map, refPt]);

  useEffect(() => {
    if (!anchors || !mooringLines || !raster) {
      return;
    }

    const availableLines = mooringLines.filter((line) => {
      const t = turbineMap.get(line.properties.target);
      if (!t) return false;
      const a = anchorMap.get(line.properties.anchor);
      if (!a) return false;

      if (isMooringLineMultiple(line))
        return line.properties.lineTypes.find((id) => lineTypes.get(id));
      return lineTypes.get(line.properties.lineType ?? "");
    });
    if (availableLines.length === 0) return;

    const lonLatAndmooringCoords: [
      [number, number],
      MooringCoords,
      MooringCoords,
      MooringCoords,
    ][] = availableLines.map((line) => {
      const lineId = line.id;
      if (mooringLineCache.current.has(lineId)) {
        return mooringLineCache.current.get(lineId);
      }

      const turbine = turbineMap.get(line.properties.target)!;
      const currentTurbineType = allTurbines.get(
        turbine.properties.turbineTypeId,
      );
      const anchor = anchorMap.get(line.properties.anchor)!;

      const lonlat = turbine.geometry.coordinates as [number, number];

      const previewFoundationId = previewFoundations.find(
        (t) => t.turbineId === turbine.id,
      )?.foundationId;

      const foundation = allFoundationTypes.get(
        previewFoundationId ?? turbine.properties.foundationId ?? "",
      );

      const scale = isFloater(foundation)
        ? foundationScale({
            foundation: foundation,
            turbine: currentTurbineType,
          })
        : 1;
      const fairRadius =
        (scale ?? 1) *
        (isFloater(foundation) && foundation.fairRadius
          ? foundation.fairRadius
          : 0);
      const fairZ =
        (scale ?? 1) *
        (isFloater(foundation) && foundation.fairZ ? foundation.fairZ : 0);

      const bearing = turf.bearing(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
      );

      const anchorRadius = turf.distance(
        turbine.geometry.coordinates,
        anchor.geometry.coordinates,
        { units: "meters" },
      );

      const latLngToValueVal = raster.latLngToValue(
        turbine.geometry.coordinates[0],
        turbine.geometry.coordinates[1],
      );
      const waterDepth = isNumber(latLngToValueVal) ? -latLngToValueVal : 0;

      let lineLengths: number[] = [];
      let EAs: number[] = [];
      let wetWeights: number[] = [];
      let attachments: number[] = [];
      if (isMooringLineMultiple(line)) {
        for (let i = 0; i < line.properties["lineLengths"].length; i++) {
          lineLengths.push(1000 * line.properties["lineLengths"][i]);
          const lineType = Array.from(lineTypes.values()).find(
            (lt) => lt.id === line.properties["lineTypes"][i],
          );
          EAs.push(lineType?.EA ?? 1e2);
          wetWeights.push(lineType?.wetWeight ?? 1);
          attachments.push(line.properties["attachments"][i]);
        }
      } else if (line.properties.lineLength) {
        lineLengths.push(1000 * line.properties["lineLength"]);
        const lineType = Array.from(lineTypes.values()).find(
          (lt) => lt.id === line.properties["lineType"],
        );
        EAs.push(lineType?.EA ?? 1e2);
        wetWeights.push(lineType?.wetWeight ?? 1);
        attachments.push(0);
      }

      const { mooringCoords, clumpWeightCoords, buoyCoords } =
        mooringSegmentVisualization({
          lineLengths: lineLengths,
          anchorRadius: anchorRadius,
          bearing: bearing + 90,
          waterDepth: waterDepth,
          fairRadius: fairRadius,
          fairZ: fairZ,
          EAs: EAs,
          wetWeights: wetWeights,
          attachments: attachments,
        });

      const coords = [lonlat, mooringCoords, clumpWeightCoords, buoyCoords];
      mooringLineCache.current.set(lineId, coords);

      return coords;
    });

    if (threeMooringRef.current) {
      threeMooringRef.current.updateData(lonLatAndmooringCoords);
    }
  }, [
    allFoundationTypes,
    allTurbines,
    anchorMap,
    anchors,
    raster,
    lineTypes,
    mooringLines,
    previewFoundations,
    turbineMap,
  ]);

  return null;
};

const Cables3DRender = ({
  features,
  map,
}: {
  features: TurbineFeature[];
  map: mapboxgl.Map;
}) => {
  const cableCache = useRef(new Map());
  const threeCableRef = useRef<ThreeDCables | null>(null);

  const parkId = useAtomValue(parkIdAtom) ?? "";
  const cables = useAtomValue(
    cablesInParkFamily({ parkId, branchId: undefined }),
  );
  const turbines = useAtomValue(
    turbinesInParkFamily({ parkId, branchId: undefined }),
  );
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const foundations = useAtomValue(foundationTypesAtom);
  const [, raster] = useBathymetry({
    projectId,
    featureId: parkId,
    branchId: undefined,
    bufferKm: undefined,
  });

  const isFloatingFoundation = useCallback(
    (foundationId: string) => isFloater(foundations.get(foundationId)),
    [foundations],
  );

  const refPt = useMemo(
    () => turbines.at(0)?.geometry ?? turf.point([0, 0]).geometry,
    [turbines],
  );

  useEffect(() => {
    if (!threeCableRef.current) {
      threeCableRef.current = new ThreeDCables([], threedCableLayerId, refPt);

      addLayer(map, threeCableRef.current);
    }
    return () => {
      if (threeCableRef.current) {
        if (map.getLayer(threeCableRef.current.id)) {
          map.removeLayer(threeCableRef.current.id);
        }
      }
    };
  }, [map, refPt]);

  useEffect(() => {
    if (!cables || !raster) {
      return undefined;
    }

    const lonLatAndCableCoords: [[number, number], MooringCoords][] = cables
      .map((cable) => {
        const cableId = cable.id;
        const pos = cable.geometry.coordinates;
        const lonlat = pos[0] as [number, number];

        const [startLon, startLat] = pos[0];
        const [endLon, endLat] = pos[pos.length - 1];
        if (
          !(
            raster.contains(startLon, startLat) &&
            raster.contains(endLon, endLat)
          )
        )
          return undefined;

        const distance: number[] = [];
        const bearing: number[] = [];
        const waterDepth: number[] = [];
        for (let i = 0; i < pos.length - 1; i++) {
          distance.push(
            turf.distance(pos[i], pos[i + 1], {
              units: "meters",
            }),
          );
          bearing.push(turf.rhumbBearing(pos[i], pos[i + 1]));
          const latLngToValueVal = raster.latLngToValue(pos[i][0], pos[i][1]);
          const thisWaterDepth = isNumber(latLngToValueVal)
            ? -latLngToValueVal
            : 0;
          waterDepth.push(thisWaterDepth);
        }

        const lastLatLngValueVal = raster.latLngToValue(
          pos[pos.length - 1][0],
          pos[pos.length - 1][1],
        );
        const lastWaterDepth = isNumber(lastLatLngValueVal)
          ? -lastLatLngValueVal
          : 0;
        waterDepth.push(lastWaterDepth);

        const floaterFrom = turbines.some(
          (t) =>
            t.id === cable.properties.fromId &&
            t.properties.foundationId &&
            isFloatingFoundation(t.properties.foundationId),
        );
        const floaterTo = turbines.some(
          (t) =>
            t.id === cable.properties.toId &&
            t.properties.foundationId &&
            isFloatingFoundation(t.properties.foundationId),
        );

        const cableCoords = cableVisualization({
          bearing,
          waterDepth,
          distance,
          floaterFrom,
          floaterTo,
        });

        const coords = [lonlat, cableCoords];
        cableCache.current.set(cableId, coords);

        return coords as [[number, number], MooringCoords];
      })
      .filter(isDefined);

    if (threeCableRef.current) {
      threeCableRef.current.updateData(lonLatAndCableCoords);
    }
  }, [raster, cables, features, isFloatingFoundation, turbines]);

  return null;
};
