import { z } from "zod";
import { fetchEnhancerWithToken } from "./utils";
import * as spec from "api/configurations";

type OperationsConfigurationPostBody = z.input<
  (typeof spec)["paths"]["/api/operations/node/{nodeId}/config"]["post"]["requestBody"]["application/json"]
>;
type OperationsConfigurationListBody = z.output<
  (typeof spec)["paths"]["/api/operations/node/{nodeId}/config"]["get"]["responses"]["200"]["application/json"]
>;

export type WeatherLimitsConfig = z.output<
  (typeof spec)["components"]["schemas"]["WeatherLimitsConfig"]
>;

export type TurbineActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["TurbineActivitiesConfig"]
>;
export type CableActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["CableActivitiesConfig"]
>;
export type FloaterActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["FloaterActivitiesConfig"]
>;
export type MonopileActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["MonopileActivitiesConfig"]
>;
export type JacketActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["JacketActivitiesConfig"]
>;
export type MooringActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["MooringActivitiesConfig"]
>;
export type ExportCableActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["ExportCableActivitiesConfig"]
>;
export type SubstationActivitiesConfig = z.output<
  (typeof spec)["components"]["schemas"]["SubstationActivitiesConfig"]
>;

export const OperationsConfigurationZod =
  spec.components.schemas.OperationsConfiguration;

export type OperationsConfiguration = z.infer<
  typeof OperationsConfigurationZod
>;

const _OperationsConfigurationUsageType = z.object({
  operationsConfigurationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
});
export type OperationsConfigurationUsageType = z.infer<
  typeof _OperationsConfigurationUsageType
>;

export async function createDefaultOperationsConfiguration({
  nodeId,
}: {
  nodeId: string;
}): Promise<OperationsConfiguration> {
  const response = await fetchEnhancerWithToken(
    `/api/operations/node/${nodeId}/config/default`,
    {
      method: "post",
    },
  );
  const json = await response.json();
  return OperationsConfigurationZod.parse(json);
}

export async function createOperationsConfiguration({
  nodeId,
  configuration,
}: {
  nodeId: string;
  configuration: OperationsConfigurationPostBody | {};
}): Promise<OperationsConfiguration> {
  const response = await fetchEnhancerWithToken(
    `/api/operations/node/${nodeId}/config`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(configuration),
    },
  );
  const json = await response.json();

  return OperationsConfigurationZod.parse(json);
}

export async function updateOperationsConfiguration({
  nodeId,
  configuration,
}: {
  nodeId: string;
  configuration: OperationsConfiguration;
}): Promise<OperationsConfiguration> {
  const res = await fetchEnhancerWithToken(
    `/api/operations/node/${nodeId}/config/${configuration.id}`,
    {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(configuration),
    },
  );

  const json = await res.json();

  return OperationsConfigurationZod.parse(json);
}

export async function deleteOperationsConfiguration({
  nodeId,
  configurationId,
}: {
  nodeId: string;
  configurationId: string;
}) {
  return fetchEnhancerWithToken(
    `/api/operations/node/${nodeId}/config/${configurationId}`,
    {
      method: "delete",
    },
  );
}

export async function listOperationsConfigurations(
  nodeId: string,
): Promise<OperationsConfigurationListBody> {
  const res = await fetchEnhancerWithToken(
    `/api/operations/node/${nodeId}/config`,
    {
      method: "get",
    },
  );
  const json = await res.json();
  return OperationsConfigurationZod.array().parse(json);
}
