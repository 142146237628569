import {
  ValidationWarningOrganisationStateType,
  ValidationWarningStateType,
  validationWarningsAllForParkFamily,
} from "state/validationWarnings";
import styled from "styled-components";
import { colors } from "styles/colors";
import { ShowWarningBoxAtom } from "./ValidationWarnings";
import Tooltip from "components/General/Tooltip";
import { modalTypeOpenAtom } from "state/modal";
import useNavigateToPark from "hooks/useNavigateToPark";
import { currentSelectionArrayAtom } from "state/selection";
import { mapAtom } from "state/map";
import { useGoToFeatures } from "hooks/map";
import {
  ValidationWarningTypes,
  ValidationSeverity,
  validationWarningSeverity,
} from "./utils";
import { useAtomValue, useSetAtom } from "jotai";
import { max } from "utils/utils";
import { parkFamily } from "state/jotai/park";

const WarningWrapper = styled.div<{
  type?: ValidationSeverity;
}>`
  border-radius: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  background-color: ${(p) =>
    p.type === ValidationSeverity.Warning
      ? colors.yellow
      : p.type === ValidationSeverity.Error
        ? colors.redAlert
        : colors.secondaryText};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const foundationStatSpecificErrors = [
  ValidationWarningTypes.FoundationTypeInvalid,
  ValidationWarningTypes.TurbineAndMonopileTypeError,
  ValidationWarningTypes.OverlappingParks,
  ValidationWarningTypes.ProjectSize,
  ValidationWarningTypes.TurbineOutsidePark,
  ValidationWarningTypes.FatalGeneral,
  ValidationWarningTypes.FatalAblyLost,
];

const productionSpecificErrors = [
  ValidationWarningTypes.TurbineOverlap,
  ValidationWarningTypes.ExistingTurbineOverlap,
  ValidationWarningTypes.TurbineTypeInvalid,
  ValidationWarningTypes.OverlappingParks,
  ValidationWarningTypes.HoleInPark,
  ValidationWarningTypes.FatalGeneral,
  ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
  ValidationWarningTypes.TurbineNotConnected,
  ValidationWarningTypes.SubstationNotConnected,
  ValidationWarningTypes.ProjectSize,
  ValidationWarningTypes.ParkMultiPolygons,
  ValidationWarningTypes.TurbineOutsidePark,
  ValidationWarningTypes.FatalAblyLost,
];

const electricalStatSpecificErrors = [
  ValidationWarningTypes.CableTypeInvalid,
  ValidationWarningTypes.CablesDifferentIAVoltageTypes,
  ValidationWarningTypes.CableDifferentExportVoltageTypes,
  ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes,
  ValidationWarningTypes.TooShortCables,
  ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
  ValidationWarningTypes.ExportCableLandfallFailed,
  ValidationWarningTypes.SubstationMissingType,
  ValidationWarningTypes.ExportCableNotConnected,
  ValidationWarningTypes.CableCorridorNotConnectedToPark,
  ValidationWarningTypes.SubstationOverlap,
  ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone,
  ValidationWarningTypes.SubstationInsideNoCableExclusionZone,
  ValidationWarningTypes.TurbinesNotReachable,
  ValidationWarningTypes.ExportSystemInsufficientCapacity,
  ValidationWarningTypes.InterArrayInsufficientCapacity,
  ValidationWarningTypes.ExportCableDifferentTypes,
  ValidationWarningTypes.ExportCableDifferentTypesInCable,
  ValidationWarningTypes.ExportSystemWillNotConverge,
];

const notCostSpecificErrors = [
  ValidationWarningTypes.OverlappingSubAreas,
  ValidationWarningTypes.SubAreasOutsidePark,
  ValidationWarningTypes.AnchorsOutsidePark,
];

const WarningBox = ({
  parkId,
  branchId,
  highestSeverity,
}: {
  parkId: string;
  branchId: string | undefined;
  highestSeverity?: ValidationSeverity;
}) => {
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);
  const setShowBox = useSetAtom(ShowWarningBoxAtom);
  const park = useAtomValue(
    parkFamily({
      parkId,
      branchId,
    }),
  );
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);

  const { navigateToPark } = useNavigateToPark();

  return (
    <Tooltip
      text={
        "Validation errors can give incorrect results. Click here to view the park and get more details."
      }
    >
      <WarningWrapper
        type={highestSeverity}
        onClick={(e) => {
          e.preventDefault();
          setModalTypeOpen(undefined);
          navigateToPark(parkId, branchId);
          setCurrentSelectionArray([parkId]);
          setShowBox(true);
          if (!park) return;
          goToFeatures([park]);
        }}
      >
        <div>!</div>
      </WarningWrapper>
    </Tooltip>
  );
};

const WarningBoxGeneric = ({
  parkId,
  branchId,
  filter,
}: {
  parkId: string;
  branchId: string | undefined;
  filter: (
    v: ValidationWarningStateType | ValidationWarningOrganisationStateType,
  ) => boolean;
}) => {
  const warnings = useAtomValue(
    validationWarningsAllForParkFamily({
      parkId,
      branchId,
    }),
  );
  const filtered = warnings.filter(filter);

  if (filtered.length === 0) return null;

  const highestSeverity = max(
    filtered.map((w) => validationWarningSeverity[w.type]),
  ) as ValidationSeverity;

  return (
    <WarningBox
      parkId={parkId}
      branchId={branchId}
      highestSeverity={highestSeverity}
    />
  );
};

export const ProductionError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string | undefined;
}) => {
  return (
    <WarningBoxGeneric
      parkId={parkId}
      branchId={branchId}
      filter={(w) => productionSpecificErrors.includes(w.type)}
    />
  );
};

export const FoundationStatError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  return (
    <WarningBoxGeneric
      parkId={parkId}
      branchId={branchId}
      filter={(w) => foundationStatSpecificErrors.includes(w.type)}
    />
  );
};

export const ElectricalStatError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  return (
    <WarningBoxGeneric
      parkId={parkId}
      branchId={branchId}
      filter={(w) =>
        electricalStatSpecificErrors.includes(w.type) ||
        productionSpecificErrors.includes(w.type)
      }
    />
  );
};

export const ParkHasAnyValidationError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  return (
    <WarningBoxGeneric
      parkId={parkId}
      branchId={branchId}
      filter={(w) => notCostSpecificErrors.includes(w.type)}
    />
  );
};

export const SubstationInsideNoCableExclusionZonesValidationError = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId: string;
}) => {
  return (
    <WarningBoxGeneric
      parkId={parkId}
      branchId={branchId}
      filter={(w) =>
        w.type === ValidationWarningTypes.SubstationInsideNoCableExclusionZone
      }
    />
  );
};
