import { useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import React, { useState } from "react";
import styled from "styled-components";
import useBooleanState from "hooks/useBooleanState";
import { spacing6 } from "styles/space";
import { ProjectPresenceWrapper } from "components/Ably/AblyComponents";
import { FeatureFlagSelectorInner } from "components/General/FeatureFlag";
import { ScreenCaptureModeAnalysis } from "ScreenCaptureMode/ScreenCaptureWrapper";
import MiscDotMenu from "./MiscDotMenu";
import ShareProjectButtonV2 from "./ShareProjectButtonV2";
import NumberOfCollaboratorsV2 from "./NumberOfCollaboratorsV2";
import SearchButtonV2 from "./SearchButtonV2";
import Notifications from "components/NotificationSystem/Notification";
import ProfileDropdown from "components/Design/TopbarNavigation/components/RightSide/ProfileDropdown";

const RightSideWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacing6};
  flex-basis: calc(100% / 3);
  align-items: center;
`;

const RightSide = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [featureFlagsAreOpen, toggleFeatureFlagsAreOpen, setFeatureFlagsOpen] =
    useBooleanState(false);
  const [screenshotOpen, setScreenshotOpen] = useState(false);

  return (
    <RightSideWrapper>
      <Notifications />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.4rem",
        }}
      >
        <ProjectPresenceWrapper
          tooltipTheme="light"
          tooltipPosition="bottom"
          organisationId={organisationId}
          nodeId={projectId}
          size={2.2}
          itemGap="0.4rem"
          showSelf={false}
          nrToShow={6}
        />
        <ProfileDropdown />
      </div>
      <ShareProjectButtonV2 />
      <NumberOfCollaboratorsV2
        nodeId={projectId}
        organisationId={organisationId}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SearchButtonV2 />
        <MiscDotMenu
          toggleFeatureFlagsAreOpen={toggleFeatureFlagsAreOpen}
          setScreenshotOpen={setScreenshotOpen}
        />
      </div>

      {featureFlagsAreOpen && (
        <FeatureFlagSelectorInner setIsOpen={setFeatureFlagsOpen} />
      )}
      {screenshotOpen && (
        <ScreenCaptureModeAnalysis onClose={() => setScreenshotOpen(false)} />
      )}
    </RightSideWrapper>
  );
};

export default RightSide;
