import { Modal, modalTypeOpenAtom } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../General/Button";
import { Column, ModalFrame, Row } from "../General/Layout";
import { useToast } from "hooks/useToast";
import { useAtomValue, useSetAtom } from "jotai";
import { scream } from "utils/sentry";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { lonLatToTile } from "types/tile";
import { OSM_ZOOM_LEVEL } from "state/layer";
import * as turf from "@turf/turf";
import { PBFToGeojsonFile } from "components/UploadModal/utils";
import { mapboxAccessToken } from "components/MapNative/constants";
import { useProjectElementsFoldersCrud } from "components/ProjectElementsV2/useProjectElementsFoldersCrud";
import { v4 as uuidv4 } from "uuid";
import { ProjectFeature } from "types/feature";
import ChevronUp from "@icons/24/ArrowUp.svg?react";
import ChevronDown from "@icons/24/ArrowDown.svg?react";
import {
  isMultiPolygonFeature,
  isNotGeometryCollection,
  isPolygonFeature,
  notUndefinedOrNull,
} from "utils/predicates";
import { WarningCircle } from "styles/errorCircle";
import { intersectFeatures } from "utils/gdal";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { currentSelectionArrayAtom } from "state/selection";
import {
  DECIMAL_PRECISION,
  reduceCoordinatePrecisionFeature,
} from "utils/geojson/utils";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import PointIcon from "@icons/24/Point.svg";
import LineIcon from "@icons/24/Line.svg";
import PolygonIcon from "@icons/24/Square.svg";
import MapItemsIcon from "@icons/24/MapItems.svg";
import { colors } from "styles/colors";
import { IconREMSize, typography } from "styles/typography";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import { Tag } from "components/General/Tag";

export const ExtractMapDataModalType = "ExtractMapDataModal";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const GraphicsTextSecondary = styled.div`
  ${typography.graphics}
  color: ${colors.textSecondary};
`;

const GraphicsTextPrimary = styled.div`
  ${typography.graphics}
  color: ${colors.textPrimary};
`;

const CaptionTextSecondary = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

const CaptionTextPrimary = styled.div`
  ${typography.caption}
  color: ${colors.textPrimary};
`;

export const RoundIconWrapper = styled.div`
  background-color: ${colors.surfaceInfo};
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box;
  transition: background-color 0.2s;
`;

const ExpandButton = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ChevronWrapper = styled.div<{
  open?: boolean;
}>`
  padding-top: 0.4rem;
  svg {
    height: 1.4rem;
    width: 1.4rem;
    path {
      stroke: ${colors.primaryText};
    }
  }
`;

enum ExtractMapDataModalLoadingState {
  DOWNLOADING = "Downloading...",
  PROCESSING = "Processing...",
  UPLOADING = "Uploading...",
}

const MAX_TILES_TO_QUERY = 800;

const POLYGON_TO_POINT_TYPES = ["building"];

const polygonToPoint = (polygon: Feature<Polygon | MultiPolygon>) => {
  const center = turf.centerOfMass(polygon);
  return { ...center, properties: polygon.properties };
};

const ExtractMapDataModalInner = ({
  modal,
}: {
  modal: Modal<typeof ExtractMapDataModalType>;
}) => {
  const [value, setValue] = useState<string | undefined>();
  const formRef = useRef<HTMLFormElement>(null);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [isLoading, setIsLoading] = useState<
    undefined | ExtractMapDataModalLoadingState
  >();

  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);

  const { update: updateFeatures } = useProjectElementsCrud();
  const { create: createProjectElementsFolder } =
    useProjectElementsFoldersCrud();
  const { info } = useToast();

  const { error } = useToast();

  const polygon = modal.metadata.selection;

  const tilesToQuery = useMemo(() => {
    const bbox = turf.bbox(polygon);
    const tileMin = lonLatToTile(bbox[0], bbox[1], OSM_ZOOM_LEVEL);
    const tileMax = lonLatToTile(bbox[2], bbox[3], OSM_ZOOM_LEVEL);

    const tiles = [];
    for (let x = tileMin.x; x <= tileMax.x; x++) {
      for (let y = tileMax.y; y <= tileMin.y; y++) {
        tiles.push({ x, y });
      }
    }
    return tiles;
  }, [polygon]);

  const tooManyTiles = tilesToQuery.length > MAX_TILES_TO_QUERY;

  const dropdownItems: DropDownItem[] = useMemo(() => {
    return [
      {
        name: "Buildings (points)",
        value: "building",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <PointIcon />
          </IconREMSize>
        ),
      },
      {
        name: "Roads (lines)",
        value: "road",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <LineIcon />
          </IconREMSize>
        ),
      },
      {
        name: "Water (polygons)",
        value: "water",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <PolygonIcon />
          </IconREMSize>
        ),
      },
      {
        name: "Water way (lines)",
        value: "waterway",
        icon: (
          <IconREMSize width={1.4} height={1.4}>
            <LineIcon />
          </IconREMSize>
        ),
      },
    ];
  }, []);

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!value) return;

    setIsLoading(ExtractMapDataModalLoadingState.DOWNLOADING);
    try {
      const features: Feature[] = [];

      const tilesRes = await Promise.all(
        tilesToQuery.map(async (tile) => {
          const res = await fetch(
            `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/${OSM_ZOOM_LEVEL}/${tile.x}/${tile.y}.vector.pbf?access_token=${mapboxAccessToken}`,
            { method: "get" },
          );
          return { tile, res };
        }),
      );

      for (let { tile, res } of tilesRes) {
        if (!res.ok) continue;
        const blob = await res.blob();
        const pbfFile = new File(
          [blob],
          `${OSM_ZOOM_LEVEL}-${tile.x}-${tile.y}.pbf`,
        );
        const geojsonFile = await PBFToGeojsonFile(pbfFile, value);
        if (!geojsonFile) continue;
        const text = await geojsonFile.text();
        const featureCollection = JSON.parse(text) as FeatureCollection;
        features.push(...featureCollection.features);
      }

      setIsLoading(ExtractMapDataModalLoadingState.PROCESSING);

      const featuresProcessed = features
        .filter(isNotGeometryCollection)
        .map((f) =>
          POLYGON_TO_POINT_TYPES.includes(value)
            ? polygonToPoint(f as Feature<Polygon | MultiPolygon>)
            : f,
        );

      const projectFeaturesClipped = await intersectFeatures(
        polygon,
        featuresProcessed,
      );

      const projectFeatures: ProjectFeature[] = projectFeaturesClipped
        .filter(isNotGeometryCollection)
        .map((f) =>
          isPolygonFeature(f) || isMultiPolygonFeature(f)
            ? turf.buffer(f, 0.2, { units: "meters", steps: 1 })
            : f,
        )
        .filter(notUndefinedOrNull)
        .map((f) => reduceCoordinatePrecisionFeature(f, DECIMAL_PRECISION))
        .map((f) => {
          const id = uuidv4();
          const properties = f.properties;
          if (properties?.type) {
            properties.mapbox_type = properties.type;
            delete properties?.type;
          }

          return {
            ...f,
            id,
            properties: {
              ...properties,
              name: `Extracted ${value}`,
              id,
            },
          };
        });

      if (projectFeatures.length === 0) {
        info(`No '${value}' elements found within the bounding box`);
        setIsLoading(undefined);
        return;
      }

      setIsLoading(ExtractMapDataModalLoadingState.DOWNLOADING);

      await Promise.all([
        updateFeatures({
          add: projectFeatures,
        }),
        createProjectElementsFolder({
          folderName: `Extracted ${value} for ${polygon.properties?.name ?? "Unnamed polygon"}`,
          featureIds: projectFeatures.map((f) => ({
            id: f.id,
            type: "feature" as const,
          })),
        }),
      ]);

      setCurrentSelectionArray(projectFeatures.map((f) => f.id));
      setModalTypeOpen(undefined);
    } catch (e) {
      scream(e as Error);
      error(
        "Something went wrong when extracting map features, the Vind team has been notified",
      );
      setIsLoading(undefined);
    }
  }, [
    setModalTypeOpen,
    updateFeatures,
    polygon,
    value,
    createProjectElementsFolder,
    info,
    error,
    tilesToQuery,
    setCurrentSelectionArray,
  ]);

  return (
    <FullScreenModal>
      <ModalFrame title={"Extract map data"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          ref={formRef}
        >
          <Column style={{ gap: "2.4rem" }}>
            <Row alignCenter>
              <RoundIconWrapper>
                <IconREMSize
                  width={1.4}
                  height={1.4}
                  iconColor={colors.iconInfo}
                >
                  <MapItemsIcon />
                </IconREMSize>
              </RoundIconWrapper>
              <CaptionTextPrimary>
                Add items from the map into elements so they can be used in your
                project.
              </CaptionTextPrimary>
            </Row>
            <Column style={{ gap: "0.8rem" }}>
              <CaptionTextSecondary>
                Select what you want to add
              </CaptionTextSecondary>
              <DropdownButton
                items={dropdownItems}
                onSelectItem={(e) => {
                  setValue(e);
                }}
                buttonText={
                  dropdownItems.find((i) => i.value === value)?.name ??
                  "Select type"
                }
                selectedItemValue={value}
              />
            </Column>

            <Column style={{ gap: "0.8rem" }}>
              <ExpandButton
                onClick={() => setShowMoreInfo(!showMoreInfo)}
                className="expand-button"
              >
                <CaptionTextSecondary>
                  {showMoreInfo ? "Hide more info" : "Show more info"}
                </CaptionTextSecondary>
                <ChevronWrapper
                  open={showMoreInfo}
                  style={{
                    alignSelf: "center",
                  }}
                >
                  {showMoreInfo ? <ChevronUp /> : <ChevronDown />}
                </ChevronWrapper>
              </ExpandButton>
              {showMoreInfo && (
                <Row alignCenter>
                  <RoundIconWrapper>
                    <IconREMSize
                      width={1.4}
                      height={1.4}
                      iconColor={colors.iconInfo}
                    >
                      <PolygonIcon />
                    </IconREMSize>
                  </RoundIconWrapper>
                  <GraphicsTextPrimary>
                    The selected polygons bounding box will be used as the
                    clipping area.
                  </GraphicsTextPrimary>
                </Row>
              )}{" "}
            </Column>
            <Column style={{ gap: "0.8rem" }}>
              <Row alignCenter>
                <GraphicsTextSecondary>Source:</GraphicsTextSecondary>
                <a
                  href="https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tag text="Mapbox Streets v8" />
                </a>
              </Row>
              <Row alignCenter>
                <GraphicsTextSecondary>Tiles to query:</GraphicsTextSecondary>
                <Tag text={tilesToQuery.length} />
                {tooManyTiles && (
                  <WarningCircle
                    title={`Maximum allowed number of tiles to query is ${MAX_TILES_TO_QUERY}`}
                  />
                )}
              </Row>
            </Column>

            <ButtonRow>
              <Button
                disabled={!!isLoading}
                text="Cancel"
                buttonType="secondary"
                onClick={() => setModalTypeOpen(undefined)}
              />
              <Button
                text={
                  isLoading
                    ? isLoading
                    : tooManyTiles
                      ? "Too large area"
                      : "Add to project"
                }
                buttonType="primary"
                type="submit"
                disabled={!!isLoading || !value || tooManyTiles}
              />
            </ButtonRow>
          </Column>
        </form>
      </ModalFrame>
    </FullScreenModal>
  );
};

const ExtractMapDataModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  if (!modalTypeOpen || modalTypeOpen?.modalType !== ExtractMapDataModalType)
    return null;

  return <ExtractMapDataModalInner modal={modalTypeOpen} />;
};

export default ExtractMapDataModal;
