import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { ValidationWarning } from "state/validationWarnings";
import { atom } from "jotai";
import { parksFamily } from "state/jotai/park";
import { isMultiPolygonFeature } from "utils/predicates";

export const multiPolygonParksWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.ParkMultiPolygons> | undefined
      >
    >(async (get) => {
      const parks = await get(parksFamily({ branchId }));
      const multiPolygonParks = parks.filter(isMultiPolygonFeature);

      if (multiPolygonParks.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.ParkMultiPolygons,
        featureIds: multiPolygonParks.map((p) => p.id),
      };
    }),
);
