import { InputDimensioned } from "../../General/Input";
import { Row } from "../../General/Layout";
import { Label } from "../../General/Form";
import { VesselType } from "services/vesselService";
import { vesselChangelogInfo } from "components/InputChangelog/const";

export function FeederBargeVesselSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType & { type: "feederBarge" };
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  return (
    <>
      <Row>
        <Label>
          <p>Jacket capacity</p>
          <InputDimensioned
            decimals={0}
            style={{ width: "16rem" }}
            disabled={disabled}
            value={vessel.maxJacketCapacity}
            unit={"units"}
            step={1}
            validate={(e) => e >= 1 && e <= 10}
            validationMessage={`Must be between 1 and 10`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "maxJacketCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                maxJacketCapacity: Math.round(n),
              });
            }}
          />
        </Label>
      </Row>
    </>
  );
}
