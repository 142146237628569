import { useAtomValue } from "jotai";
import { SVGWrapper } from "@icons/svgUtils";
import { IconBtn } from "components/General/Icons";
import { NodeFoundationAccess } from "components/Organisation/Library/types";
import {
  ContentTableRow,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useState } from "react";
import { orgFoundationManageAccessSelector } from "state/user";
import useNodeFoundationResourcesCrud from "../../../useNodeFoundationResourcesCrud";
import { NodeLink } from "../../Collaborators";
import Earth from "@icons/14/Earth.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";

export function SingleFoundationResource({
  nodeId,
  access,
}: {
  nodeId: string;
  access: NodeFoundationAccess;
}) {
  const { remove } = useNodeFoundationResourcesCrud();

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const orgFoundationManageAccess = useAtomValue(
    orgFoundationManageAccessSelector,
  );
  const accessFromThisNode = access.nodeId === nodeId;

  return (
    <ContentTableRow disabled={deleteInProgress}>
      <SVGWrapper size={1.4}>
        <Earth />
      </SVGWrapper>
      <TextEllipsis
        style={{
          margin: 0,
          marginRight: "auto",
        }}
      >
        {access.foundation.name}
      </TextEllipsis>

      {!accessFromThisNode && <NodeLink nodeId={access.nodeId} />}
      {orgFoundationManageAccess && accessFromThisNode && (
        <IconBtn
          disabled={deleteInProgress}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteInProgress(true);
            remove(nodeId, access.foundation.id).finally(() =>
              setDeleteInProgress(false),
            );
          }}
          size={"1.4rem"}
          style={{
            marginLeft: "auto",
          }}
        >
          <Trashcan />
        </IconBtn>
      )}
    </ContentTableRow>
  );
}
