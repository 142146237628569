import type { HTMLAttributes } from "react";
import styled from "styled-components";
import SearchIcon from "@icons/24/Search.svg?react";
import { Input } from "../../../General/Input";
import { LineIconWrapper } from "../../../LayerList/LineIconWrapper";
import { colors } from "../../../../styles/colors";
import React, { useRef, useCallback } from "react";

const SearchBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 100%;
  padding-right: 4rem;
  background: white;
  border-color: ${colors.dropdownBorder};
`;

const IconWrapper = styled(LineIconWrapper)`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
  padding-right: 0.8rem;
`;

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  value: string;
  onChange(search: string): void;
  autoFocus?: boolean;
}

const SearchBox = ({ onChange, value, autoFocus }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    },
    [onChange],
  );

  return (
    <SearchBoxWrapper>
      <StyledInput
        ref={inputRef}
        placeholder="Search GIS layers"
        autoFocus={autoFocus}
        onChange={handleChange}
        value={value}
        type="search"
      />
      <IconWrapper>
        <SearchIcon stroke="red" style={{ stroke: "red" }} />
      </IconWrapper>
    </SearchBoxWrapper>
  );
};

export default React.memo(SearchBox);
