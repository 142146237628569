import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import JSZip from "jszip";
import { _OtherFeature, ProjectFeature } from "../../types/feature";
import { FeatureWithAnyProperties } from "../../types/feature";
import { cleanGeojsonFeatures, shpSupportFiles } from "../UploadFile/fileUtils";
import { validateShpInput } from "../UploadFile/shape";
import workerUrl from "gdal3.js/dist/package/gdal3.js?url";
import dataUrl from "gdal3.js/dist/package/gdal3WebAssembly.data?url";
import wasmUrl from "gdal3.js/dist/package/gdal3WebAssembly.wasm?url";
import initGdalJs from "gdal3.js";
import * as Sentry from "@sentry/react";
import { v4 as uuid4 } from "uuid";
import {
  DECIMAL_PRECISION,
  SIMPLIFY_TOLERANCE,
  reduceCoordinatePrecisionFeature,
  removeLineStringFeatureWithOnlyOneCoordinate,
  simplifyTakingCareOfPolygonsWithHoles,
} from "utils/geojson/utils";
import { SafeParseReturnType } from "zod";

const SHAPE_TYPE_TO_MIME: Record<string, string> = {
  shp: "x-gis/x-shapefile",
  shx: "x-gis/x-shapefile",
  dbf: "application/octet-stream",
  prj: "text/plain",
};

const paths = {
  wasm: wasmUrl,
  data: dataUrl,
  js: workerUrl,
};

export const supportedUploadCRS = 7193914;

export const acceptedShapeFileEndings = [".shp", ...shpSupportFiles];

export const getFileTypeFromFileName = (fileName: string) => {
  return (fileName.split(".").at(-1) ?? "").toLowerCase();
};

export const getEPSGForGISFile = async (file: File) => {
  const ogrInfo = await new Promise<any>((res, hasError) =>
    initGdalJs({ paths }).then(async (Gdal) => {
      try {
        const result = await Gdal.open(
          file,
          undefined,
          file.name.endsWith(".zip") ? ["vsizip"] : undefined,
        );
        const data = result.datasets[0];

        const output = await Gdal.ogrinfo(data, ["-json"]);
        res(output);
        return;
      } catch (error) {
        hasError("Error when running 'ogrinfo' on file");
        return;
      }
    }),
  );

  const crsCodesPerLayer = ogrInfo.layers
    .flatMap((l: any) =>
      l.geometryFields.map((gf: any) => {
        if (typeof gf.coordinateSystem.projjson.id?.code !== "undefined") {
          return gf.coordinateSystem.projjson.id.code;
        }

        if (
          typeof gf.coordinateSystem.projjson.target_crs?.id?.code !==
          "undefined"
        ) {
          return gf.coordinateSystem.projjson.target_crs.id.code;
        }

        if (
          typeof gf.coordinateSystem.projjson.source_crs?.id?.code !==
          "undefined"
        ) {
          return gf.coordinateSystem.projjson.source_crs.id.code;
        }

        const epsg = (gf.coordinateSystem.projjson.components.map(
          (c: any) => c.id?.code,
        ) ?? [])[0];

        return epsg;
      }),
    )
    .filter((epsg: number | undefined) => epsg) as number[];

  return crsCodesPerLayer;
};

export const convertGeotiffFileToEPSG = async (
  geotiff: File,
  epsg: number,
  compression = false,
): Promise<Blob> =>
  await new Promise<Blob>((res, hasError) =>
    initGdalJs({ paths }).then(async (Gdal) => {
      try {
        const result = await Gdal.open(geotiff);
        const data = result.datasets[0];

        const output = await Gdal.gdalwarp(data, [
          "-of",
          "GTiff",
          "-t_srs",
          `EPSG:${epsg}`,
          ...(compression ? ["-co", "COMPRESS=LZW"] : []),
        ]);
        const bytes = await Gdal.getFileBytes(output);
        const blob = new Blob([bytes], {
          type: "image/tiff; application=geotiff",
        });

        res(blob);
        return;
      } catch (error) {
        hasError("Error when running 'ogrinfo' on file");
        return;
      }
    }),
  );

export const getAllFilesOfTypeFromZip = async (
  zipFile: File,
  fileTypes: string[],
) => {
  const zipToUpload = new JSZip();
  const zip = await zipToUpload.loadAsync(zipFile);
  const filesFromZipWithFileTypes = await Promise.all(
    Object.keys(zip.files)
      .filter((file) =>
        fileTypes.some(
          (fileType) => file.endsWith(fileType) && !file.includes("__MACOSX"),
        ),
      )
      .map((file) => zip.files[file])
      .map((zipFileEntry) =>
        zipFileEntry.async("blob").then((blob) => {
          const zipFileName = zipFile.name.split(".").at(0);
          const zipFileEntryName = zipFileEntry.name
            .split("/")
            .at(-1)
            ?.split(".")
            .at(0);
          const filename =
            zipFileName === zipFileEntryName
              ? zipFileEntry.name.split("/").at(-1)
              : zipFile.name + "_" + zipFileEntry.name.split("/").at(-1);
          return new File([blob], filename ?? zipFileEntry.name);
        }),
      ),
  );
  return filesFromZipWithFileTypes;
};

export const getZippedShapeFiles = async (
  files: File[],
  maxFileSize: number,
): Promise<[File[], string[]]> => {
  const shapeFiles = files.filter((file) => {
    const fileSuffix = getFileTypeFromFileName(file.name);
    return acceptedShapeFileEndings.includes(`.${fileSuffix}`);
  });

  const [shapeResult, invalidShpNames] = validateShpInput(shapeFiles);

  const result: File[] = [];
  for (const shapeFileName of shapeResult) {
    const zipToUpload = new JSZip();
    for (const shapeFile of shapeFiles) {
      if (
        shapeFile.name.toLowerCase().startsWith(shapeFileName) &&
        shapeFile.size < maxFileSize
      ) {
        zipToUpload.file(shapeFile.name, shapeFile);
      }
    }

    const zipBlob = await zipToUpload.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
    });

    const fileToUpload = new File([zipBlob], `${shapeFileName}.shp.zip`);
    result.push(fileToUpload);
  }
  return [result, invalidShpNames];
};

export const parseFileAndCleanGeoJson = async ({
  file,
}: {
  file: File;
}): Promise<
  ReturnType<typeof cleanGeojsonFeatures> & {
    collections: FeatureCollection<Geometry, GeoJsonProperties>[];
  }
> => {
  const geojsonFromShape = await new Promise<Uint8Array>((res, hasError) =>
    initGdalJs({ paths }).then(async (Gdal) => {
      try {
        const result = await Gdal.open(
          file,
          undefined,
          file.name.endsWith(".zip") ? ["vsizip"] : undefined,
        );
        const shapeData = result.datasets[0];

        const output = await Gdal.ogr2ogr(shapeData, [
          "-f",
          "GeoJSON",
          "-t_srs",
          "EPSG:4326",
          "-nln",
          file.name,
          "-skipfailures",
        ]);
        const bytes = await Gdal.getFileBytes(output);
        res(bytes);
      } catch (error) {
        hasError(error);
      }
    }),
  );

  const featureCollection = JSON.parse(
    new TextDecoder().decode(geojsonFromShape),
  ) as FeatureCollection<Geometry, GeoJsonProperties>;

  // // Make sure we can parse all features
  // if (
  //   !featureCollection.features.every((f) => _OtherFeature.safeParse(f).success)
  // ) {
  //   throw new Error("Unable to parse any GIS data from file.");
  // }

  const collections = [
    {
      ...featureCollection,
      features: featureCollection.features
        .map((f) => {
          const id = uuid4();
          return { ...f, id, properties: { ...f.properties, id } };
        })
        .filter((f) => !!f.geometry)
        .map((f) => reduceCoordinatePrecisionFeature(f, DECIMAL_PRECISION))
        .filter(removeLineStringFeatureWithOnlyOneCoordinate)
        .map((f) =>
          simplifyTakingCareOfPolygonsWithHoles(f, SIMPLIFY_TOLERANCE),
        ),
    },
  ];

  if (!collections) {
    throw new Error("Unable to parse any GIS data from file.");
  }

  let cleaned: ProjectFeature[] = [];
  let cleanedWithAnyProperties: FeatureWithAnyProperties[] = [];
  let failedToParse: Array<{
    feature: any;
    zodError: SafeParseReturnType<any, any>;
  }> = [];
  let shapefileMissingEncoding = false;
  for (const collection of collections) {
    const result = cleanGeojsonFeatures(collection.features);
    cleaned = cleaned.concat(result.cleaned);
    cleanedWithAnyProperties = cleanedWithAnyProperties.concat(
      result.cleanedWithAnyProperties,
    );
    failedToParse = failedToParse.concat(result.failedToParse);
    shapefileMissingEncoding =
      shapefileMissingEncoding || result.shapefileMissingEncoding;
  }

  return {
    collections,
    cleaned,
    cleanedWithAnyProperties,
    shapefileMissingEncoding,
    failedToParse,
  };
};

export const geojsonFileToZippedShapeFiles = async (
  features: ProjectFeature[],
  epsg = 4326,
  filename: string,
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "ESRI Shapefile",
    "-t_srs",
    `EPSG:${epsg}`,
  ]);

  const allOuput = output.all as FilePath[] | undefined;

  if (!allOuput) throw new Error("No output from ogr2ogr");

  const files = await Promise.all(
    allOuput.map(async (o) => {
      const bytes = await Gdal.getFileBytes(o);
      const blob = new Blob([bytes], {
        type: SHAPE_TYPE_TO_MIME[o.real.split("/").at(-1) as string],
      });
      const fileEnding = o.real.split(".").at(-1) ?? "unknown";
      return new File([blob], filename + "." + fileEnding);
    }),
  );

  const zipToUpload = new JSZip();
  for (const shapeFile of files) {
    zipToUpload.file(shapeFile.name, shapeFile);
  }

  const zipBlob = await zipToUpload.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  });

  return new File([zipBlob], `${filename}.shp.zip`);
};

export const convertGeojsonFeaturesUsingGDAL = async (
  features: ProjectFeature[],
  epsg = 4326,
): Promise<FeatureCollection> => {
  const featureCollection = {
    type: "FeatureCollection",
    features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "GEOJSON",
    "-t_srs",
    `EPSG:${epsg}`,
  ]);

  const allOuput = output.all as FilePath[] | undefined;

  if (!allOuput) throw new Error("No output from ogr2ogr");

  const convertedFeatures = await Promise.all(
    allOuput.map(async (o) => {
      const bytes = await Gdal.getFileBytes(o);
      return new TextDecoder().decode(bytes);
    }),
  );

  return JSON.parse(convertedFeatures) as FeatureCollection;
};

export const geojsonToKMLFile = async (features: ProjectFeature[]) => {
  const featureCollection = {
    type: "FeatureCollection",
    features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "KML",
    "-t_srs",
    "EPSG:4326",
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/vnd. google-earth. kml+xml",
  });

  return new File([blob], `${uuid4()}.kml`);
};

export const geojsonToDXFFile = async (
  features: ProjectFeature[],
  epsg = 4326,
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "DXF",
    "-t_srs",
    `EPSG:${epsg}`,
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/dxf",
  });

  return new File([blob], `${uuid4()}.dxf`);
};

export const geojsonToCSVFile = async (
  features: ProjectFeature[],
  epsg = 4326,
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features: features.map((f) => ({
      ...f,
      properties: {
        ...(f.properties.name ? { name: f.properties.name } : {}),
        ...(f.properties.type ? { name: f.properties.type } : {}),
        epsg: epsg,
      },
    })),
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`,
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "CSV",
    "-t_srs",
    `EPSG:${epsg}`,
    "-lco",
    "GEOMETRY=AS_XY",
    "-skipfailures",
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "text/csv",
  });

  return new File([blob], `${uuid4()}.csv`);
};

export const KMLToGeojsonFile = async (file: File) => {
  const Gdal = await initGdalJs({ paths });
  const result = await Gdal.open(file);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "GeoJSON",
    "-t_srs",
    "EPSG:4326",
    "-nln",
    file.name,
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/json",
  });

  return new File([blob], `${file.name}.geojson`);
};

export const PBFToGeojsonFile = async (
  file: File,
  layerName: string,
): Promise<File | undefined> => {
  const Gdal = await initGdalJs({ paths });
  const result = await Gdal.open(file);

  // Send errors to Sentry
  if (result.errors.length > 0) {
    result.errors.forEach((error) => {
      Sentry.captureException(new Error(error), {
        tags: {
          fileName: file.name,
          fileType: file.type,
        },
        extra: { fileData: file, layerName },
      });
    });
  }
  // return empty if no dataSets, assuming errors has been sent to Sentry above
  if (result.datasets.length === 0) {
    return;
  }

  const shapeData = result.datasets[0];

  if (!(shapeData.info as any).layers.find((l: any) => l.name === layerName)) {
    return;
  }

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "GeoJSON",
    "-s_srs",
    "EPSG:3857",
    "-t_srs",
    "EPSG:4326",
    "-skipfailures",
    layerName,
  ]);

  const bytes = await Gdal.getFileBytes(output);
  const blob = new Blob([bytes], {
    type: "application/json",
  });

  return new File([blob], `${file.name}.geojson`);
};
