import { FinancialLibraryProvider } from "./Financial/FinancialLibraryProvider";
import { TurbineLibraryProvider } from "./Turbine/TurbineLibraryProvider";
import { FoundationLibraryProvider } from "./Foundation/FoundationLibraryProvider";
import { CableLibraryProvider } from "./Cable/CableLibraryProvider";
import { ExportCableLibraryProvider } from "./ExportCable/ExportCableLibraryProvider";
import { AnalysisLibraryProvider } from "./Analysis/AnalysisLibraryProvider";
import { DataPackageLibraryProvider } from "./DataPackage/DataPackageLibraryProvider";
import { SubstationLibraryProvider } from "./Substation/SubstationLibraryProvider";

export default function LibraryProviders({
  organisationId,
  ablyId,
}: {
  organisationId: string;
  ablyId: string;
}) {
  return (
    <>
      <FinancialLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
      <TurbineLibraryProvider organisationId={organisationId} ablyId={ablyId} />
      <FoundationLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
      <CableLibraryProvider organisationId={organisationId} ablyId={ablyId} />
      <ExportCableLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
      <AnalysisLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
      <DataPackageLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
      <SubstationLibraryProvider
        organisationId={organisationId}
        ablyId={ablyId}
      />
    </>
  );
}
