import { Suspense, useCallback, useEffect, useState } from "react";
import { idToCableChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { CableType } from "services/cableTypeService";
import useOrgCableCrud from "components/Organisation/Library/cable/useOrgCableCrud";
import { SingleCable } from "components/ConfigurationModal/CableSettings/CableSettings";
import { CableProjects } from "./availability/CableProjects";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const CableResourceContent = ({
  resource,
  organisationId,
}: {
  resource: CableType;
  organisationId: string;
}) => {
  const { update: updateCable } = useOrgCableCrud();

  const [tempCable, setTempCable] = useState(resource);

  useEffect(() => {
    setTempCable(resource);
  }, [resource, setTempCable]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<CableType>) => {
      setTempCable((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempCable],
  );

  const onSaveNameOrDescription = useCallback(
    (cable: CableType) => {
      updateCable(cable, true);
    },
    [updateCable],
  );

  if (!tempCable) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"cable"}
        description={tempCable.note ?? ""}
        name={tempCable.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempCable.name,
            note: description ?? tempCable.note,
          })
        }
        onSave={({ name, description }) => {
          onSaveNameOrDescription({
            ...tempCable,
            name: name ?? tempCable.name,
            note: description ?? tempCable.note,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            note: resource.note,
          });
        }}
        changelogData={{
          id: idToCableChangelogId(resource.id),
          category: "org_cable_manage",
        }}
      >
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("cable")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <ResourceContentTabs tabs={cableTabs(resource, updateCable)} />
    </>
  );
};

const cableTabs = (
  resource: CableType,
  update: (cable: CableType, onlyUpdatingDescription?: boolean) => Promise<any>,
) => [
  {
    name: "Cable details",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <SingleCable
          disabled={false}
          key={resource.id}
          cable={resource}
          onSave={update}
          isLoading={false}
          isLibraryCable={true}
        />
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <CableProjects resource={resource} />
      </Suspense>
    ),
  },
];
