import { atom } from "jotai";
import { featureMapFamily, featuresSelectableMapAtom } from "./features";
import { isDefined, isPark, isSubArea, isTurbine } from "utils/predicates";
import { inPark } from "state/park";
import { branchIdAtom, parkIdAtom } from "state/pathParams";
import { parkFamily } from "./park";
import {
  AnchorFeature,
  CableChainFeature,
  CableFeature,
  MooringLineFeature,
  ParkFeature,
  ProjectFeature,
  SubAreaFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { cablesInParkFamily } from "./cable";
import { turbinesInParkFamily } from "./turbine";
import { pointInPolygon } from "utils/geometry";
import { dedup, maxBy } from "utils/utils";
import { substationsInParkFamily } from "./substation";
import { cableChainsInParkFamily } from "./cableChain";
import { SimpleTurbineType } from "types/turbines";
import { simpleTurbineTypesAtom } from "./turbineType";
import { mooringLinesInParkFamily } from "./mooringLine";
import { anchorsInParkFamily } from "./anchor";
import { DefaultMap } from "lib/DefaultMap";
import { currentSelectionArrayAtom } from "state/selection";

export const selectedProjectFeaturesAtom = atom<Promise<ProjectFeature[]>>(
  async (get) => {
    const featureMap = await get(featuresSelectableMapAtom);
    const ids = get(currentSelectionArrayAtom);
    return ids.map((id) => featureMap.get(id)).filter(isDefined);
  },
);

export const selectedOnlyFeatureAtom = atom<
  Promise<ProjectFeature | undefined>
>(async (get) => {
  const all = await get(selectedProjectFeaturesAtom);
  if (all.length === 1) return all[0];
  return undefined;
});

export const selectedSubAreasAtom = atom(async (get) => {
  const featureMap = await get(featureMapFamily({ branchId: undefined }));
  const ids = get(currentSelectionArrayAtom);
  return ids.map((id) => featureMap.get(id)).filter(isSubArea);
});

export const selectedAreaAtom = atom<
  Promise<SubAreaFeature[] | ParkFeature | undefined>
>(async (get) => {
  const parkId = get(parkIdAtom);
  if (!parkId) return undefined;

  const selectedPark = (await get(selectedProjectFeaturesAtom)).find(
    (f) => f.id === parkId,
  );
  if (selectedPark && isPark(selectedPark)) return selectedPark;

  const subAreas = (await get(selectedSubAreasAtom)).filter(inPark(parkId));
  if (subAreas.length) return subAreas;
  const park = await get(parkFamily({ parkId, branchId: undefined }));
  return park;
});

const selectedAreaStrictAtom = atom<
  Promise<SubAreaFeature[] | ParkFeature | undefined>
>(async (get) => {
  const parkId = get(parkIdAtom);
  if (!parkId) return undefined;
  const selection = await get(selectedProjectFeaturesAtom);
  const park = selection.find((p) => p.id === parkId);
  if (isPark(park)) return park;

  const areas = selection.filter(isSubArea).filter(inPark(parkId));
  if (areas.length) return areas;
  return undefined;
});

export const selectedTurbinesAtom = atom<Promise<TurbineFeature[]>>(
  async (get) => {
    const parkId = get(parkIdAtom);
    if (!parkId) return [];

    const turbinesInPark = get(
      turbinesInParkFamily({ parkId, branchId: undefined }),
    );
    const area = await get(selectedAreaStrictAtom);
    if (!area) {
      const selected = await get(selectedProjectFeaturesAtom);
      if (selected.length === 0) return turbinesInPark;
      return selected.filter(isTurbine).filter(inPark(parkId));
    }

    if (Array.isArray(area)) {
      const subareaTurbines = (await turbinesInPark).filter((t) =>
        area.some((a) => pointInPolygon(t.geometry, a.geometry)),
      );
      const selected = (await get(selectedProjectFeaturesAtom))
        .filter(isTurbine)
        .filter(inPark(parkId));
      return dedup(subareaTurbines.concat(selected), (f) => f.id);
    }

    return turbinesInPark;
  },
);

export const selectedCablesAtom = atom<Promise<CableFeature[]>>(async (get) => {
  const parkId = get(parkIdAtom);
  if (!parkId) return [];
  const cablesInPark = await get(
    cablesInParkFamily({ parkId, branchId: undefined }),
  );
  const turbines = await get(selectedTurbinesAtom);
  const ids = new Set(turbines.map((t) => t.id));
  const selectionIds = new Set(get(currentSelectionArrayAtom));
  return cablesInPark.filter(
    (c) =>
      ids.has(c.properties.fromId) ||
      ids.has(c.properties.toId) ||
      selectionIds.has(c.id),
  );
});

export const selectedSubstationsAtom = atom<Promise<SubstationFeature[]>>(
  async (get) => {
    const parkId = get(parkIdAtom);
    if (!parkId) return [];
    const cables = await get(selectedCablesAtom);
    const selectionIds = get(currentSelectionArrayAtom);
    const ids = new Set(
      cables
        .flatMap((c) => [c.properties.fromId, c.properties.toId])
        .concat(selectionIds),
    );
    const subsInPark = await get(
      substationsInParkFamily({ parkId, branchId: undefined }),
    );
    return subsInPark.filter((s) => ids.has(s.id));
  },
);

export const selectedCableChainsAtom = atom<Promise<CableChainFeature[]>>(
  async (get) => {
    const branchId = get(branchIdAtom);
    const parkId = get(parkIdAtom);
    if (!parkId || !branchId) return [];
    const subs = await get(selectedSubstationsAtom);
    const ids = new Set(subs.map((s) => s.id));
    const chains = await get(cableChainsInParkFamily({ parkId, branchId }));
    return chains.filter((c) => ids.has(c.properties.substation));
  },
);

export const selectedTurbineTypesAtom = atom<Promise<SimpleTurbineType[]>>(
  async (get) => {
    const turbines = await get(selectedTurbinesAtom);
    const turbineTypes = await get(simpleTurbineTypesAtom);
    return dedup(
      turbines
        .map((t) => turbineTypes.get(t.properties.turbineTypeId))
        .filter(isDefined),
      (t) => t.id,
    );
  },
);

export const selectedTurbineTypesMostFrequentAtom = atom<
  Promise<SimpleTurbineType | undefined>
>(async (get) => {
  const turbines = await get(selectedTurbinesAtom);
  const counts = new DefaultMap<string, number>(() => 0);
  for (const t of turbines)
    counts.update(t.properties.turbineTypeId, (n) => n + 1);
  const [top] = maxBy(Array.from(counts.inner.entries()), (n) => n[1]) ?? [];
  if (!top) return;
  return (await get(simpleTurbineTypesAtom)).get(top);
});

export const selectedMooringLinesAtom = atom<Promise<MooringLineFeature[]>>(
  async (get) => {
    const turbines = await get(selectedTurbinesAtom);
    const parkId = get(parkIdAtom);
    if (!parkId) return [];
    const lines = await get(
      mooringLinesInParkFamily({ parkId, branchId: undefined }),
    );
    const selectedIds = new Set(get(currentSelectionArrayAtom));
    const turbineIds = new Set(turbines.map((t) => t.id));
    return lines.filter(
      (line) =>
        turbineIds.has(line.properties.target) || selectedIds.has(line.id),
    );
  },
);

export const selectedAnchorsAtom = atom<Promise<AnchorFeature[]>>(
  async (get) => {
    const parkId = get(parkIdAtom);
    if (!parkId) return [];
    const anchors = await get(
      anchorsInParkFamily({ parkId, branchId: undefined }),
    );
    const lines = await get(selectedMooringLinesAtom);
    const selectedIds = get(currentSelectionArrayAtom);
    const anchorIds = new Set(
      lines.map((line) => line.properties.anchor).concat(selectedIds),
    );
    return anchors.filter((a) => anchorIds.has(a.id));
  },
);
