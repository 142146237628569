import { isDefined } from "./../../../../utils/predicates";
import { LineStringFeature } from "../../../../types/feature";
import { ProjectFeature } from "../../../../types/feature";
import { CableCycleError } from "../../../Cabling/CableWalk";
import {
  ANCHOR_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "../../../../constants/projectMapView";
import { MooringLineFeature } from "../../../../types/feature";

export class CableConnectionError extends Error {
  constructor(public msg?: string) {
    super(msg);
  }
}

export class AnchorLineError extends Error {
  constructor(public msg?: string) {
    super(msg);
  }
}

export function createNewAnchorLineFeatures(
  parkId: string,
  allFeatures: ProjectFeature[],
  selections: LineStringFeature[],
  connectionsToMooringCableCompatableFeatures: {
    id: string;
    anchorId?: string;
    targetId?: string;
  }[],
) {
  const updatedFeatures = selections
    .map((f) => {
      const snap = connectionsToMooringCableCompatableFeatures.find(
        (snap) => snap.id === f.id,
      );
      const anchorId = snap?.anchorId;
      const targetId = snap?.targetId;
      if (!anchorId || !targetId) return undefined;

      // Only allow snapping to turbines and substations
      const anchorFeature = allFeatures.find((f) => f.id === anchorId);
      const targetFeature = allFeatures.find((f) => f.id === targetId);

      if (
        !anchorFeature ||
        !targetFeature ||
        anchorFeature.properties.type !== ANCHOR_PROPERTY_TYPE ||
        targetFeature.properties.type !== TURBINE_PROPERTY_TYPE
      ) {
        throw new AnchorLineError(
          "Mooring line must connect to an anchor and a turbine",
        );
      }

      return {
        ...f,
        properties: {
          ...f.properties,
          anchor: anchorId,
          target: targetId,
          type: MOORING_LINE_PROPERTY_TYPE,
          parentIds: [parkId],
          slack: 0.3,
        },
      } as MooringLineFeature;
    })
    .filter(isDefined);

  return updatedFeatures;
}

export function getReadableErrorMessage(e: Error) {
  if (e instanceof CableCycleError) {
    return "Cable cycles are not supported.";
  } else if (e instanceof CableConnectionError) {
    return "Cables must connect turbines or a substation.";
  } else if (e instanceof AnchorLineError) {
    return "Mooring line must connect to an anchor and a turbine";
  } else {
    return "Failed to change type, please try again.";
  }
}
