import React, { forwardRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { typography } from "../../styles/typography";
import { borderRadius, spacing3, spacing6 } from "../../styles/space";
const StyledTab = styled.button<{ active?: boolean }>`
  transition:
    all 200ms ease,
    visibility 0s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  border-radius: ${borderRadius.pill};
  padding: ${spacing3} ${spacing6};
  gap: 0.6rem;
  text-align: center;
  height: 3.2rem;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  ${typography.body}
  background-color: ${(p) => {
    if (p.disabled) return colors.backgroundDefault;
    if (p.active) {
      return colors.blue800;
    }
    return colors.blue10;
  }};
  &:hover {
    background: ${(p) => {
      if (p.active) return colors.blue700;
      return colors.indigo100;
    }};
  }
  span {
    white-space: nowrap;
    color: ${(p) => {
      if (p.active) return colors.blue10;
      return colors.blue800;
    }};
    background: unset;
    padding: ${spacing3} 0;
  }
`;
interface OvalTabProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: React.ReactNode;
  active?: boolean;
}
const OvalTab = forwardRef<HTMLButtonElement, OvalTabProps>(
  ({ text, active, ...props }, ref) => {
    return (
      <StyledTab
        ref={ref}
        active={active}
        {...props}
        style={{ position: "relative", ...props.style }}
      >
        {text && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </span>
        )}
      </StyledTab>
    );
  },
);
export default OvalTab;
