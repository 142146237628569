import React, { useMemo } from "react";
import styled from "styled-components";
import * as turf from "@turf/turf";
import { editorAccessProjectSelector } from "../../state/user";
import { LineStringFeature } from "../../types/feature";
import {
  isExportCable,
  isLineStringFeature,
  isMultiPolygonFeature,
  isPointFeature,
  isPolygonFeature,
  isSubstation,
  isTurbine,
} from "../../utils/predicates";
import { FlexGrid2, Grid2 } from "../General/Form";
import {
  BATHYMETRY_SOURCE_DESCRIPTION,
  getDepthAtPoints,
} from "business/bathymetry/utils";
import { SkeletonText } from "../Loading/Skeleton";
import { TurbineFeature } from "../../types/feature";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../state/pathParams";
import FeatureProperties from "./FeatureProperties";
import {
  getHumanReadableArea,
  getHumanReadableDistance,
  pointInPolygon,
  sampleLineString,
} from "../../utils/geometry";
import { movingWindow, zip } from "../../utils/utils";
import { LineString, Point } from "geojson";
import { ProjectFeature } from "../../types/feature";
import { useBathymetry } from "hooks/bathymetry";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { EditableTextInternalState } from "components/General/EditableText";
import { formatGWConditional } from "components/ProductionV2/format";
import { useAtomValue } from "jotai";
import { parkFamily } from "state/jotai/park";
import { exportCableLoadMapFamily } from "state/jotai/exportCable";
import { exportCableSplitsFamily } from "state/jotai/landfall";
import { reduceCoordinatePrecisionFeature } from "utils/geojson/utils";
import { isOnshoreAtom } from "state/onshore";

// Lazy load to prevent plotly being in main.js
const DepthProfile = React.lazy(
  () => import("../RightSide/InfoModal/ProjectFeatureInfoModal/DepthProfile"),
);

const OverflowWrapper = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
`;

const PropertyList = styled(FlexGrid2)`
  &:empty {
    display: none;
  }

  > div {
    > p {
      flex: 0 0 50%;
    }

    > :nth-child(2n) {
      text-align: end;
      justify-self: end;
    }

    &:hover {
      * svg {
        display: initial;
      }
    }
  }
`;

const DepthInner = ({
  projectId,
  branchId,
  parkId,
  feature,
}: {
  projectId: string;
  branchId: string;
  parkId: string;
  feature: ProjectFeature<Point>;
}) => {
  const [, raster] = useBathymetry({
    projectId,
    branchId,
    featureId: parkId,
    bufferKm: undefined,
  });

  const depth = useMemo(() => {
    if (!raster) return;
    return getDepthAtPoints(raster, [feature.geometry.coordinates])[0];
  }, [raster, feature]);

  if (!depth) {
    return <p>N/A</p>;
  }

  return <p>{Math.round(Math.abs(depth))} m</p>;
};

const DepthInPark = ({
  projectId,
  branchId,
  parkId,
  feature,
}: {
  projectId: string;
  branchId: string;
  parkId: string;
  feature: ProjectFeature<Point>;
}) => {
  const park = useAtomValue(
    parkFamily({
      parkId,
      branchId,
    }),
  );

  if (!park) {
    return <p>N/A</p>;
  }
  const pointInsidePark = pointInPolygon(feature.geometry, park.geometry);

  if (!pointInsidePark) {
    return <p>N/A</p>;
  }

  return (
    <DepthInner
      projectId={projectId}
      branchId={branchId}
      parkId={parkId}
      feature={feature}
    />
  );
};

const Depth = ({ feature }: { feature: ProjectFeature<Point> }) => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const parkId = useAtomValue(parkIdAtom);

  if (!projectId || !branchId || !parkId) {
    return <p>N/A</p>;
  }

  return (
    <DepthInPark
      projectId={projectId}
      branchId={branchId}
      parkId={parkId}
      feature={feature}
    />
  );
};

const DepthAwareLengthStats = ({
  line,
  horizontalLength,
}: {
  line: LineStringFeature;
  horizontalLength: number;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const [, raster] = useBathymetry({
    projectId,
    branchId: branchId,
    featureId: line.id,
    bufferKm: undefined,
  });

  const length = useMemo(() => {
    if (!raster) return undefined;

    const spacing = Math.min(10, horizontalLength / 2); // Ensure we get at least two points
    const lineSamples = sampleLineString(line.geometry, spacing).points;
    const depths = getDepthAtPoints(raster, lineSamples, true);

    let length = 0;
    movingWindow(zip(lineSamples, depths)).forEach(([[p1, d1], [p2, d2]]) => {
      const hdist = turf.distance(p1, p2, {
        units: "kilometers",
      });
      const d = (d1 - d2) / 1000.0; // km to m
      length += Math.sqrt(hdist * hdist + d * d); // thanks, Pythagoras
    });

    return length;
  }, [raster, horizontalLength, line.geometry]);

  if (!length) return null;

  return (
    <>
      <p
        style={{
          display: "flex",
          gap: "0.8rem",
        }}
      >
        Depth aware length
        <HelpTooltip text={BATHYMETRY_SOURCE_DESCRIPTION} />
      </p>
      <p>{getHumanReadableDistance(length * 1000)}</p>
    </>
  );
};

const LineStats = ({
  feature,
  updateFeatures,
  nameEditable,
  extraProperties,
}: {
  feature: ProjectFeature<LineString>;
  nameEditable?: boolean;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
  extraProperties?: Record<string, string>;
}) => {
  const distance = useMemo(() => {
    if (!feature) return;
    return turf.length(feature, {
      units: "meters",
    });
  }, [feature]);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);

  const name =
    typeof feature.properties.name !== "undefined"
      ? String(feature.properties.name)
      : undefined;

  return (
    <>
      <Grid2>
        <p>Name</p>
        <EditableTextInternalState
          disabled={!nameEditable}
          value={name ?? ""}
          renderText={(text) => (
            <OverflowWrapper title={text}>{text}</OverflowWrapper>
          )}
          textContainerStyle={{
            padding: 0,
          }}
          onEnter={(newName) => {
            updateFeatures([
              {
                ...feature,
                properties: {
                  ...feature.properties,
                  name: newName,
                },
              },
            ]);
          }}
        />
        {distance && (
          <>
            <p>Horizontal length</p>
            <p>{getHumanReadableDistance(distance)}</p>
            <DepthAwareLengthStats
              line={feature}
              horizontalLength={Number(distance / 1000)}
            />
          </>
        )}
      </Grid2>
      <PropertyList>
        <FeatureProperties feature={feature} updateFeatures={updateFeatures} />
        {extraProperties &&
          Object.keys(extraProperties ?? {}).map((key) => (
            <div key={key}>
              {key}
              <p
                style={{
                  wordBreak: "break-all",
                }}
              >{`${extraProperties[key]}`}</p>
            </div>
          ))}
      </PropertyList>
      {editorAccessProject && <DepthProfile feature={feature} />}
    </>
  );
};

const ExportCableStats = ({
  feature,
  updateFeatures,
  nameEditable,
}: {
  feature: ProjectFeature<LineString>;
  nameEditable?: boolean;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const splits = useAtomValue(
    exportCableSplitsFamily({
      parkId,
      branchId,
    }),
  );
  const landfallPoint = useMemo(() => {
    const split = splits.find((s) => s.exportCable.id === feature.id);
    if (!split || "error" in split) return;
    return {
      Landfall: reduceCoordinatePrecisionFeature(
        split.landfallPoint,
        6,
      ).geometry.coordinates.join(", "),
    };
  }, [splits, feature]);

  return (
    <LineStats
      feature={feature}
      updateFeatures={updateFeatures}
      nameEditable={nameEditable}
      extraProperties={landfallPoint}
    />
  );
};

const PolygonStats = ({
  feature,
  updateFeatures,
  nameEditable,
}: {
  feature: ProjectFeature;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
  nameEditable?: boolean;
}) => {
  const areaString = useMemo(() => {
    if (!feature) return;
    return getHumanReadableArea(turf.area(feature));
  }, [feature]);

  const name =
    typeof feature.properties.name !== "undefined"
      ? String(feature.properties.name)
      : undefined;

  return (
    <>
      <Grid2>
        <p>Name:</p>
        <EditableTextInternalState
          disabled={!nameEditable}
          value={name ?? ""}
          renderText={(text) => (
            <OverflowWrapper title={text}>{text}</OverflowWrapper>
          )}
          textContainerStyle={{
            padding: 0,
          }}
          onEnter={(newName) => {
            updateFeatures([
              {
                ...feature,
                properties: {
                  ...feature.properties,
                  name: newName,
                },
              },
            ]);
          }}
        />
        <p>Area:</p>
        <p>{areaString}</p>
      </Grid2>
      <PropertyList>
        <FeatureProperties feature={feature} updateFeatures={updateFeatures} />
      </PropertyList>
    </>
  );
};

const PointCoordinateInfo = ({
  feature,
  updateFeatures,
  nameEditable,
}: {
  feature: ProjectFeature<Point>;
  nameEditable?: boolean;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const [x, y] = feature.geometry.coordinates;

  const name =
    typeof feature.properties.name !== "undefined"
      ? String(feature.properties.name)
      : undefined;

  return (
    <>
      <Grid2>
        <p>Name</p>
        <EditableTextInternalState
          disabled={!nameEditable}
          value={name ?? ""}
          renderText={(text) => (
            <OverflowWrapper title={text}>{text}</OverflowWrapper>
          )}
          textContainerStyle={{
            padding: 0,
          }}
          onEnter={(newName) => {
            updateFeatures([
              {
                ...feature,
                properties: {
                  ...feature.properties,
                  name: newName,
                },
              },
            ]);
          }}
        />
        <p>Latitude</p> <p>{y.toFixed(4)}</p>
        <p>Longitude</p> <p>{x.toFixed(4)}</p>
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
          }}
        >
          Depth
          <HelpTooltip text={BATHYMETRY_SOURCE_DESCRIPTION} />
        </div>
        <React.Suspense fallback={<SkeletonText />}>
          <Depth feature={feature} />
        </React.Suspense>
      </Grid2>
      <PropertyList>
        <FeatureProperties feature={feature} updateFeatures={updateFeatures} />
      </PropertyList>
    </>
  );
};

const SubstationCoordinateInfo = ({
  feature,
  updateFeatures,
  nameEditable,
}: {
  feature: ProjectFeature<Point>;
  nameEditable?: boolean;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom);

  const exportLoadMap = useAtomValue(
    exportCableLoadMapFamily({
      parkId,
      branchId,
      turbineTypeOverride: undefined,
    }),
  );
  const capacity = exportLoadMap.get(feature.id);
  const [x, y] = feature.geometry.coordinates;

  const name =
    typeof feature.properties.name !== "undefined"
      ? String(feature.properties.name)
      : undefined;

  return (
    <>
      <Grid2>
        <p>Name</p>
        <EditableTextInternalState
          disabled={!nameEditable}
          value={name ?? ""}
          renderText={(text) => (
            <OverflowWrapper title={text}>{text}</OverflowWrapper>
          )}
          textContainerStyle={{
            padding: 0,
          }}
          onEnter={(newName) => {
            updateFeatures([
              {
                ...feature,
                properties: {
                  ...feature.properties,
                  name: newName,
                },
              },
            ]);
          }}
        />
        <p>Latitude</p> <p>{y.toFixed(4)}</p>
        <p>Longitude</p> <p>{x.toFixed(4)}</p>
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
          }}
        >
          Depth
          <HelpTooltip text={BATHYMETRY_SOURCE_DESCRIPTION} />
        </div>
        <React.Suspense fallback={<SkeletonText />}>
          <Depth feature={feature} />
        </React.Suspense>
        <p>Load</p>{" "}
        <p>
          {capacity ? formatGWConditional(capacity / 1e9) : "Not available"}
        </p>
      </Grid2>
      <PropertyList>
        <FeatureProperties feature={feature} updateFeatures={updateFeatures} />
      </PropertyList>
    </>
  );
};

const TurbineInfo = ({
  feature,
  updateFeatures,
}: {
  feature: TurbineFeature;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
}) => {
  const onshore = useAtomValue(isOnshoreAtom);
  const [x, y] = feature.geometry.coordinates;
  return (
    <>
      <Grid2>
        <p>Name</p>
        <OverflowWrapper title={feature.properties.name}>
          {feature.properties.name}
        </OverflowWrapper>
        <p>Latitude</p> <p>{y.toFixed(4)}</p>
        <p>Longitude</p> <p>{x.toFixed(4)}</p>
        {!onshore && (
          <>
            <div
              style={{
                display: "flex",
                gap: "0.8rem",
              }}
            >
              Depth
              <HelpTooltip text={BATHYMETRY_SOURCE_DESCRIPTION} />
            </div>
            <React.Suspense fallback={<SkeletonText />}>
              <Depth feature={feature} />
            </React.Suspense>
          </>
        )}
      </Grid2>
      <PropertyList>
        <FeatureProperties updateFeatures={updateFeatures} feature={feature} />
      </PropertyList>
    </>
  );
};

export default function KeyInformationGeneral({
  canvasFeature,
  updateFeatures,
  nameEditable,
}: {
  canvasFeature: ProjectFeature;
  updateFeatures: (features: ProjectFeature[] | undefined) => void;
  nameEditable?: boolean;
}) {
  if (isTurbine(canvasFeature)) {
    return (
      <TurbineInfo feature={canvasFeature} updateFeatures={updateFeatures} />
    );
  }

  if (isSubstation(canvasFeature)) {
    return (
      <SubstationCoordinateInfo
        feature={canvasFeature}
        updateFeatures={updateFeatures}
        nameEditable={nameEditable}
      />
    );
  }

  if (isPointFeature(canvasFeature)) {
    return (
      <PointCoordinateInfo
        feature={canvasFeature}
        updateFeatures={updateFeatures}
        nameEditable={nameEditable}
      />
    );
  }

  if (isPolygonFeature(canvasFeature) || isMultiPolygonFeature(canvasFeature)) {
    return (
      <PolygonStats
        feature={canvasFeature}
        updateFeatures={updateFeatures}
        nameEditable={nameEditable}
      />
    );
  }

  if (isExportCable(canvasFeature)) {
    return (
      <ExportCableStats
        feature={canvasFeature}
        updateFeatures={updateFeatures}
        nameEditable={nameEditable}
      />
    );
  }

  if (isLineStringFeature(canvasFeature)) {
    return (
      <LineStats
        feature={canvasFeature}
        updateFeatures={updateFeatures}
        nameEditable={nameEditable}
      />
    );
  }

  return null;
}
