import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  loggedInUserIdAtom,
  ORGANISATION_ACCESS_ROLE_TO_NUMBER,
  userOrganisationAccessSelectorFamily,
} from "state/user";
import { OrganisationUser } from "types/customer";
import { _OrganisationAccessRole } from "types/user";
import { TableText } from "./style";
import { useOrgMemberCrud } from "./useOrgMemberCrud";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { useMemo } from "react";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";

export default function OrgRoleDropdown({ user }: { user: OrganisationUser }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const organisationAccess = useAtomValue(
    userOrganisationAccessSelectorFamily({
      organisationId,
    }),
  );

  const currentUser = useAtomValue(loggedInUserIdAtom);

  const userIsCurrentUser = currentUser === user.user_id;

  const accessLevel =
    ORGANISATION_ACCESS_ROLE_TO_NUMBER[user.org_role ?? "null"];

  const { update, isUpdatingRole } = useOrgMemberCrud(organisationId);

  const dropdownItems = useMemo(() => {
    const items: DropDownItem[] = [];
    if (organisationAccess >= 1) {
      items.push({
        value: "guest",
        name: "Guest",
      });
      items.push({
        value: "member",
        name: "Member",
      });
    }
    if (organisationAccess >= 2) {
      items.push({
        value: "admin",
        name: "Admin",
      });
    }
    return items;
  }, [organisationAccess]);

  const selectedText = useMemo(
    () => dropdownItems.find((i) => i.value === user.org_role)?.name,
    [dropdownItems, user.org_role],
  );

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {organisationAccess >= ORGANISATION_ACCESS_ROLE_TO_NUMBER["admin"] &&
      !userIsCurrentUser &&
      accessLevel !== ORGANISATION_ACCESS_ROLE_TO_NUMBER["owner"] ? (
        <DropdownButton
          items={dropdownItems}
          selectedItemValue={user.org_role ?? undefined}
          buttonText={selectedText ?? ""}
          disabled={isUpdatingRole}
          onClick={(e) => e.stopPropagation()}
          onSelectItem={(value) => {
            const newRole = _OrganisationAccessRole.parse(value);
            return update(user.user_id, newRole);
          }}
          style={{
            paddingLeft: 0,
            border: "none",
            width: "auto",
          }}
        />
      ) : (
        <TableText
          style={{
            textTransform: "capitalize",
          }}
        >
          {user.org_role}
        </TableText>
      )}
    </div>
  );
}
