import { FoundationMaxDepths } from "types/foundations";

import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import {
  FoundationMinDepths,
  DetailedMonopileType,
  DetailedJacketType,
} from "types/foundations";
import { between } from "utils/validations";
import { CommonReferenceNumbersProps } from "./ReferenceNumberProps";

interface ReferenceWaterDepthProps extends CommonReferenceNumbersProps {
  tempFoundation: DetailedMonopileType | DetailedJacketType;
  originalFoundation: DetailedMonopileType | DetailedJacketType | undefined;
}

const ReferenceWaterDepth = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceWaterDepthProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Reference water depth</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          validate={between(
            FoundationMinDepths.detailed_monopile,
            FoundationMaxDepths.detailed_monopile,
          )}
          validationMessage={`Needs to be within ${FoundationMinDepths.detailed_monopile} - ${FoundationMaxDepths.detailed_monopile} m`}
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "waterDepth",
            commentCategory,
          )}
          step="1"
          unit="m"
          type="number"
          value={tempFoundation.waterDepth}
          initialValue={originalFoundation?.waterDepth}
          decimals={1}
          onChange={(d) => {
            setTempFoundation({
              ...tempFoundation,
              waterDepth: d,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default ReferenceWaterDepth;
