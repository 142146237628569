import { ReactNode, Suspense, useRef, useState } from "react";
import {
  ChangelogEntryRow,
  ChangelogWrapper,
  DateWrapper,
  ExtraContainer,
  TimelineContainer,
  TimelineContainerPlacement,
} from "./style";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { UserInfo } from "components/Organisation/Library/dataLibrary/shared";
import { dateToYearDateTime } from "utils/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { useClickOutside } from "hooks/useClickOutside";
import ChangelogIcon from "@icons/24/Changelog.svg?react";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";
import { ChangelogCategory } from "components/InputChangelog/types";
import { useAtomValue } from "jotai";
import { Tag } from "components/ConfigurationModal/SettingsUsage/style";

const OrganisationResourceChangelogPopupInner = ({
  organisationId,
  resourceId,
  category,
  placement = TimelineContainerPlacement.BOTTOM,
}: {
  organisationId: string;
  resourceId: string;
  category: ChangelogCategory;
  placement?: TimelineContainerPlacement;
}) => {
  const changelogEntry = useAtomValue(
    inputChangelogsAtomFamily({
      nodeId: organisationId,
      changelogId: resourceId,
      category,
      organisationId,
    }),
  );

  return (
    <>
      {changelogEntry && (
        <ExtraContainer placement={placement}>
          {changelogEntry.length === 0 && <div>No changelog entries</div>}
          {changelogEntry.slice(0, 5).map((entry) => (
            <ChangelogEntryRow key={entry.sqsMessageId}>
              <DateWrapper>
                {dateToYearDateTime(new Date(entry.version))}
              </DateWrapper>
              <div>{entry.action}</div>
              <UserInfo organisationId={organisationId} userId={entry.author} />
            </ChangelogEntryRow>
          ))}
          {changelogEntry.length > 5 && <div>....</div>}
        </ExtraContainer>
      )}
    </>
  );
};

const OrganisationResourceChangelogPopup = ErrorBoundaryWrapper(
  ({
    organisationId,
    resourceId,
    category,
    children,
    placement = TimelineContainerPlacement.BOTTOM,
  }: {
    organisationId: string;
    resourceId: string;
    category: ChangelogCategory;
    children: ReactNode;
    placement?: TimelineContainerPlacement;
  }) => {
    const [changelogOpen, setChangelogOpen] = useState(false);
    const changeLogRef = useRef<HTMLDivElement>(null);
    useClickOutside(changeLogRef, () => setChangelogOpen(false));
    return (
      <TimelineContainer
        onClick={(e) => {
          e.stopPropagation();
          setChangelogOpen(!changelogOpen);
        }}
        ref={changeLogRef}
      >
        {changelogOpen && (
          <Suspense
            fallback={
              <ExtraContainer placement={placement}>
                <TimelineContainer>
                  <SkeletonBlock style={{ width: "10rem", height: "2rem" }} />
                </TimelineContainer>
              </ExtraContainer>
            }
          >
            <OrganisationResourceChangelogPopupInner
              placement={placement}
              organisationId={organisationId}
              resourceId={resourceId}
              category={category}
            />
          </Suspense>
        )}
        {children}
      </TimelineContainer>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export const OrganisationResourceChangelogButton = ({
  organisationId,
  resourceId,
  category,
}: {
  organisationId: string;
  resourceId: string;
  category: ChangelogCategory;
}) => {
  return (
    <OrganisationResourceChangelogPopup
      organisationId={organisationId}
      resourceId={resourceId}
      placement={TimelineContainerPlacement.BOTTOM}
      category={category}
    >
      <Tag>
        <ChangelogWrapper>
          <ChangelogIcon />

          <div>See changelog</div>
        </ChangelogWrapper>
      </Tag>
    </OrganisationResourceChangelogPopup>
  );
};
