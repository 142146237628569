import { z } from "zod";
import { Color } from "lib/colors";
import { atom } from "jotai";
import {
  MaxValueType,
  SensorStatsType,
} from "components/RightSide/InfoModal/ProjectFeatureInfoModal/SensorStats";

export const COLORS = {
  yellow: new Color(255, 255, 0),
  red: new Color(255, 0, 0),
} as const;

export const GROUND_TYPES = {
  soft: 1,
  medium: 0.4,
  hard: 0,
};
const _StandardGroundModel = z.object({
  type: z.literal("standard"),
  groundType: z.enum(["soft", "medium", "hard"]),
});
const _AlternativeGroundModel = z.object({
  type: z.literal("alternativeGround"),
});
const _GroundModel = z.discriminatedUnion("type", [
  _StandardGroundModel,
  _AlternativeGroundModel,
]);

export const _NoiseAnalysisInput = z.object({
  source: z.number().describe("Source sound level at source in dB"),
  sensorHeight: z.number().describe("Sensor height in meters"),
  humidity: z.number().min(0).max(100).describe("Relative humidity"),
  temperature: z
    .number()
    .min(0)
    .max(100)
    .describe("Temperature in degrees Celsius"),
  groundModel: _GroundModel,
});
export type NoiseAnalysisSettings = z.infer<typeof _NoiseAnalysisInput>;
const _NoiseDisplaySettings = z.object({
  redBoundary: z.number(),
  yellowBoundary: z.number(),
  opacity: z.number(),
});

type NoiseDisplaySettings = z.infer<typeof _NoiseDisplaySettings>;

export const noiseDisplaySettingsAtom = atom<NoiseDisplaySettings>({
  redBoundary: 50,
  yellowBoundary: 40,
  opacity: 0.4,
});
const _NoiseAnalysisBase = z.object({
  id: z.string().optional().default("noise-analysis-id"),
  version: z.string(),
  progress: z.number().min(0).max(1).optional(),
});
const _NoiseAnalysisTriggered = _NoiseAnalysisBase.extend({
  status: z.literal("triggered"),
});
type NoiseAnalysisTriggered = z.infer<typeof _NoiseAnalysisTriggered>;
const _NoiseAnalysisStarted = _NoiseAnalysisBase.extend({
  status: z.literal("started"),
});
type NoiseAnalysisStarted = z.infer<typeof _NoiseAnalysisStarted>;
const _NoiseAnalysisStopped = _NoiseAnalysisBase.extend({
  status: z.literal("stopped"),
  reason: z.string(),
});
export type NoiseAnalysisStopped = z.infer<typeof _NoiseAnalysisStopped>;
const _NoiseAnalysisFailed = _NoiseAnalysisBase.extend({
  status: z.literal("failed"),
  reason: z.string(),
});
export type NoiseAnalysisFailed = z.infer<typeof _NoiseAnalysisFailed>;
const _NoiseAnalysisRunning = _NoiseAnalysisBase.extend({
  status: z.literal("running"),
});
const _NoiseAnalysisComplete = _NoiseAnalysisBase.extend({
  status: z.literal("complete"),
  presignedUrl: z.string().url(),
  points: z.array(z.number()),
});

export type NoiseAnalysisComplete = z.infer<typeof _NoiseAnalysisComplete>;

export const _NoiseAnalysisStatus = z.discriminatedUnion("status", [
  _NoiseAnalysisTriggered,
  _NoiseAnalysisStarted,
  _NoiseAnalysisStopped,
  _NoiseAnalysisFailed,
  _NoiseAnalysisRunning,
  _NoiseAnalysisComplete,
]);

export type NoiseAnalysisStatus = z.infer<typeof _NoiseAnalysisStatus>;

export const isAnalysisRunning = (
  status: NoiseAnalysisStatus | undefined,
): status is NoiseAnalysisTriggered | NoiseAnalysisStarted =>
  status?.status === "triggered" ||
  status?.status === "started" ||
  status?.status === "running";

export const isAnalysisStatusStopped = (
  status: NoiseAnalysisStatus | undefined,
): status is NoiseAnalysisStopped => status?.status === "stopped";

export const isAnalysisStatusComplete = (
  status: NoiseAnalysisStatus | undefined,
): status is NoiseAnalysisComplete => status?.status === "complete";

export const isAnalysisStatusFailed = (
  status: NoiseAnalysisStatus | undefined,
): status is NoiseAnalysisFailed => status?.status === "failed";
export type NoiseAnalysisResults = {
  sensorStats: SensorStatsType[];
  maxValue: MaxValueType;
};
