import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import SliderIcon from "@icons/24/Slider.svg?react";
import { MooringLineSingleFeature, ProjectFeature } from "../../types/feature";
import Tooltip from "../General/Tooltip";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { MooringLineFeature } from "../../types/feature";
import { isMooringLine, isMooringLineMultiple } from "../../utils/predicates";
import { ANCHOR_RADIUS_MAX_KM } from "../../services/mooringLineTypeService";
import { useClickOutside } from "../../hooks/useClickOutside";
import { previewMooringAndFoundationState } from "../GenerateFoundationsAndAnchors/state";
import { ColorSelectorWrapper } from "./FeatureCoordinateEditor.style";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import * as turf from "@turf/turf";
import { IconBtn } from "components/General/Icons";
import { useAtomValue, useSetAtom } from "jotai";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { useJotaiCallback } from "utils/jotai";
import { colors } from "styles/colors";
import { InputDimensioned } from "components/General/Input";
import { Row } from "components/General/Layout";
import { spaceTiny } from "styles/space";

const LineLengthEditor = ({
  parkId,
  feature,
  setOpen,
}: {
  parkId: string;
  feature: MooringLineSingleFeature;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(popupRef, () => setOpen(false));

  const turbines = useAtomValue(
    turbinesInParkFamily({ parkId: parkId ?? "", branchId: undefined }),
  );
  const anchors = useAtomValue(
    anchorsInParkFamily({ parkId: parkId ?? "", branchId: undefined }),
  );
  const mooringLines = useAtomValue(
    mooringLinesInParkFamily({ parkId: parkId ?? "", branchId: undefined }),
  );

  const setPreviewMooringAndFoundationState = useSetAtom(
    previewMooringAndFoundationState,
  );

  const { update: updateFeatures } = useProjectElementsCrud();
  const saveOnExitCallback = useJotaiCallback(
    async (get, set) => {
      const p = get(previewMooringAndFoundationState);
      if (!p) return;

      await updateFeatures({ update: [...p.preview.mooringLines] });
      set(previewMooringAndFoundationState, undefined);
    },
    [updateFeatures],
  );

  useEffect(() => {
    return () => {
      saveOnExitCallback();
    };
  }, [saveOnExitCallback]);

  const updateMooringLine = useCallback(
    (line: MooringLineFeature, length: number | number[]) => {
      const updatedline = {
        ...line,
        properties: {
          ...line.properties,
          ...(Array.isArray(length)
            ? { lineLengths: length }
            : { lineLength: length }),
        },
      };

      const existingMooringLines = mooringLines.filter(
        (ml) => ml.id !== line.id,
      );

      setPreviewMooringAndFoundationState({
        preview: {
          foundations: [],
          mooringLines: [updatedline],
          anchors: [],
        },
        existing: {
          mooringLines: existingMooringLines,
          anchors: anchors,
        },
      });
    },
    [anchors, mooringLines, setPreviewMooringAndFoundationState],
  );

  const [previewLength, setPreviewLength] = useState<number | number[]>(
    isMooringLineMultiple(feature)
      ? feature.properties.lineLengths
      : feature.properties.lineLength ?? 0,
  );

  const anchorRadius = useMemo(() => {
    const anchor = anchors.find((a) => a.id === feature.properties.anchor);
    const turbine = turbines.find((t) => t.id === feature.properties.target);

    if (!anchor || !turbine) return ANCHOR_RADIUS_MAX_KM;

    return turf.distance(
      turbine.geometry.coordinates,
      anchor.geometry.coordinates,
      { units: "kilometers" },
    );
  }, [anchors, turbines, feature]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(
    containerRef,
    () => setOpen(false),
    (target) => {
      return (
        target instanceof HTMLElement &&
        target.dataset?.buttonType === "edit-mooring-line-length"
      );
    },
    {
      ignoreDragClicks: true,
    },
  );

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        top: "0.6rem",
        transform: "translateX(-50%)",
      }}
    >
      <MenuFrame
        title="Line length"
        onExit={() => setOpen(false)}
        style={{ width: "26rem" }}
      >
        {Array.isArray(previewLength) ? (
          <>
            {[0, 1, 2].map((index) => (
              <Row key={index} style={{ gap: spaceTiny, alignItems: "center" }}>
                <p>Segment {index + 1}</p>
                <InputDimensioned
                  style={{ width: "12rem", margin: "0 1.5rem" }}
                  decimals={2}
                  unit="km"
                  validate={(n) => 0.01 <= n && n <= anchorRadius * 1.5}
                  validationMessage={`Needs to be within 0.01 - ${Math.round(anchorRadius * 1.5 * 100) / 100} km`}
                  step={0.01}
                  value={previewLength[index]}
                  onChange={(d) => {
                    const updatedLengths = [...previewLength];
                    updatedLengths[index] = d;
                    setPreviewLength(updatedLengths);
                    updateMooringLine(feature, updatedLengths);
                  }}
                />
              </Row>
            ))}
          </>
        ) : (
          <InputDimensioned
            style={{ width: "12rem", margin: "0 1.5rem" }}
            decimals={2}
            unit={"km"}
            validate={(n) => 0.01 <= n && n <= anchorRadius * 1.5}
            validationMessage={`Needs to be within 0.01 - ${Math.round(anchorRadius * 1.5 * 100) / 100} km`}
            step={0.01}
            value={previewLength}
            onChange={(d) => {
              setPreviewLength(d);
              updateMooringLine(feature, d);
            }}
          />
        )}
      </MenuFrame>
    </div>
  );
};

const MooringLineLengthEditor = ({
  feature,
  parkId,
}: {
  feature: ProjectFeature;
  parkId: string;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip position="top" text={"Edit line length"}>
        <ColorSelectorWrapper>
          <IconBtn
            iconColor={colors.iconBrand}
            data-button-type="edit-mooring-line-length"
            active={open}
            onClick={() => {
              setOpen(true);
            }}
          >
            <SliderIcon />
          </IconBtn>
          <div style={{ position: "relative", alignSelf: "end" }}>
            {open && isMooringLine(feature) && (
              <LineLengthEditor
                parkId={parkId}
                feature={feature}
                setOpen={setOpen}
              />
            )}
          </div>
        </ColorSelectorWrapper>
      </Tooltip>
    </>
  );
};

export default MooringLineLengthEditor;
