import { useCallback, useMemo } from "react";
import {
  ABLY_TURBINE_TYPE_UPDATE,
  ABLY_TURBINE_TYPE_DELETE,
} from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import { useProjectTurbineCrud } from "components/ConfigurationModal/TurbineSettings/useNodeTurbineCrud";
import { _SimpleTurbineType } from "types/turbines";
import { z } from "zod";

export function useAblyProjectTurbine(projectId: string) {
  const { updateLocal, removeLocal } = useProjectTurbineCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const turbine = _SimpleTurbineType.parse(message.data.meta);
      updateLocal(turbine);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const turbineId = z.string().parse(message.data.meta);
      removeLocal(turbineId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_TURBINE_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_TURBINE_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, projectId);
}
