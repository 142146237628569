import { DIAMETER_OF_TURBINE_BLADE_MODEL } from "./../../ViewToPark/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as THREE from "three";
import { MathUtils, Group, MeshStandardMaterial, Mesh } from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  turbineScalingAtom,
  viewParkRotationAtom,
  viewTurbineCoordsAtom,
  viewTurbineDiameterAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
} from "../../../../state/viewToPark";
import { disposeObject } from "../../ViewToPark/utils";
import {
  DIVISION_FACTOR,
  HEIGHT_OF_TURBINE_HUB_MODEL,
  MESH_ROTOR_NAME,
  MESH_TURBINE_NAME,
  ROTOR_OFFSET,
} from "../../ViewToPark/constants";
import { sampleTerrainMeshHeight } from "./utils";
import { useAtomValue } from "jotai";
import { ThreeCoreBasic } from "components/ViewAnalyses/common";
export default function useTurbines(
  threeCore: ThreeCoreBasic | undefined,
  terrain: Mesh<THREE.BufferGeometry, MeshStandardMaterial> | undefined,
  terrainLoaded: boolean,
) {
  const turbineCoords = useAtomValue(viewTurbineCoordsAtom);
  const turbineHeights = useAtomValue(viewTurbineHeightsAtom);
  const turbineDiameters = useAtomValue(viewTurbineDiameterAtom);
  const turbineScaling = useAtomValue(turbineScalingAtom);
  const parkRotation = useAtomValue(viewParkRotationAtom);
  const turbineLights = useAtomValue(viewTurbineLightsAtom);

  const [windTurbineGroup, setWindTurbineGroup] = useState<Group>();
  const [turbineBladeGroup, setTurbineBladeGroup] = useState<Group>();

  const rotationOffset = useMemo(
    () =>
      turbineCoords ? turbineCoords.map(() => Math.random() * 360) : undefined,
    [turbineCoords],
  );

  const rotateAndScaleGltf = useCallback(
    (gltf: GLTF, coords: [number, number], scaleFactor: number) => {
      const newTurbine = gltf.scene.clone(true);

      const terrainHeight =
        sampleTerrainMeshHeight([coords[0], coords[1]], terrain) ?? 0;
      newTurbine.translateX(-coords[0]);
      newTurbine.translateZ(coords[1]);
      newTurbine.translateY(terrainHeight / DIVISION_FACTOR);
      newTurbine.rotateX(MathUtils.degToRad(-90));

      newTurbine.scale.set(
        scaleFactor / DIVISION_FACTOR,
        scaleFactor / DIVISION_FACTOR,
        scaleFactor / DIVISION_FACTOR,
      );
      return newTurbine;
    },
    [terrain],
  );

  useEffect(() => {
    if (!turbineCoords || !rotationOffset || !turbineHeights || !terrainLoaded)
      return;

    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/tower.gltf",
      function (gltf) {
        gltf.scene.traverse((node: any) => {
          if (!node.isMesh) return;
          node.castShadow = true;
          node.receiveShadow = true;
        });
        const group = new Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i] * turbineScaling;
          const newTurbine = rotateAndScaleGltf(
            gltf,
            coords,
            (1 / HEIGHT_OF_TURBINE_HUB_MODEL) * turbineHeight,
          );
          newTurbine.translateZ((-0.1 * turbineHeight) / DIVISION_FACTOR);
          newTurbine.name = MESH_TURBINE_NAME;
          group.add(newTurbine);
        });
        setWindTurbineGroup(group);
      },
    );

    const loaderTurbine = new GLTFLoader();
    loaderTurbine.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/rotor_rotated_center.glb",
      function (gltf) {
        gltf.scene.traverse((node: any) => {
          if (!node.isMesh) return;
          node.castShadow = true;
          node.receiveShadow = true;
          node.material.side = THREE.BackSide; // Seems like the triangles are flipped.
        });

        const group = new Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i] * turbineScaling;
          const turbineDiameter = turbineDiameters[i] * turbineScaling;
          const newRotor = rotateAndScaleGltf(
            gltf,
            coords,
            (1 / DIAMETER_OF_TURBINE_BLADE_MODEL) * turbineDiameter,
          );

          newRotor.translateZ((turbineHeight * ROTOR_OFFSET) / DIVISION_FACTOR);
          newRotor.rotateX(MathUtils.degToRad(90));
          newRotor.name = MESH_ROTOR_NAME;

          newRotor.children.forEach((c) =>
            c.children.forEach(
              (c2) => (c2.rotation.x = MathUtils.degToRad(rotationOffset[i])),
            ),
          );

          group.add(newRotor);
        });
        setTurbineBladeGroup(group);
      },
    );
  }, [
    rotateAndScaleGltf,
    rotationOffset,
    turbineCoords,
    turbineDiameters,
    turbineHeights,
    turbineLights,
    turbineScaling,
    terrainLoaded,
  ]);
  useEffect(() => {
    if (!turbineBladeGroup) return;
    turbineBladeGroup.children.forEach((c) => {
      c.rotation.y = MathUtils.degToRad(parkRotation);
    });
  }, [parkRotation, turbineBladeGroup]);

  useEffect(() => {
    if (!threeCore || !turbineBladeGroup) return;
    const { scene } = threeCore;
    scene.add(turbineBladeGroup);
    return () => {
      scene.remove(turbineBladeGroup);
      disposeObject(turbineBladeGroup);
    };
  }, [threeCore, turbineBladeGroup]);

  useEffect(() => {
    if (!threeCore || !windTurbineGroup) return;
    const { scene } = threeCore;
    scene.add(windTurbineGroup);
    return () => {
      scene.remove(windTurbineGroup);
      disposeObject(windTurbineGroup);
    };
  }, [threeCore, windTurbineGroup]);

  return { turbineBladeGroup };
}
