import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { CSSProperties } from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import SearchIcon from "@icons/24/Search.svg?react";
import {
  IconREMSize,
  RegularRaw,
  TextRaw,
  typography,
} from "../../styles/typography";
import { colors } from "../../styles/colors";
import {
  borderRadiusMedium,
  spaceSmall,
  spacing1,
  spacing3,
  spacing5,
  spacing6,
} from "../../styles/space";
import { Comp } from "../../types/utils";
import { ValidationContainer } from "./Validate";
import { Distance } from "../Units/units";
import { InputChangelogInfo } from "components/InputChangelog/types";
import InputChangelog from "components/InputChangelog/InputChangelog";
import { roundToDecimal } from "utils/utils";
import { ChangelogIconWrapper } from "components/InputChangelog/style";

export const TextInput = styled.input<{
  invalid?: boolean;
  compact?: boolean;
  locked?: boolean;
  smallInput?: boolean;
}>`
  ${RegularRaw}
  ${typography.body}

  ${(p) =>
    p.compact
      ? `padding: ${spacing1} ${spacing5}`
      : `padding: ${spacing3} ${spacing6}`};

  box-sizing: border-box;
  height: ${(p) => (p.compact ? "2.4rem" : "3.2rem")};

  border: 1px solid ${colors.borderDefault};
  border-radius: ${borderRadiusMedium};

  background-color: white;

  &::placeholder {
    color: ${colors.textDisabled};
  }

  &:focus {
    outline: none;
    border-color: ${colors.borderSelected};
  }

  &:hover {
    border-color: ${colors.grey400};
  }

  &:invalid:not(:focus) {
    border-color: ${(p) =>
      p.formNoValidate ? "none" : colors.borderError} !important;
  }

  ${({ invalid }) =>
    invalid && `border-color: ${colors.borderError} !important;`};

  &:disabled {
    border-color: ${colors.grey300};
    background-color: ${colors.surfaceDisabled};
    color: ${colors.grey500};
    cursor: not-allowed;
  }

  ${(p) =>
    p.locked &&
    `
    && {
      background-color: white;
      color: ${colors.primaryText};
      ${lockedCss(colors.primaryDisabled)}
    }
  `}

  ${(p) =>
    p.smallInput &&
    `
    && {
      padding: 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      margin: 0;
      height: 2rem;
      border-bottom: 1px solid ${colors.borderDefault};
    }
  `}
`;

type InputProps = {
  locked?: boolean;
  invalid?: boolean;
  compact?: boolean;
  mini?: boolean;
  smallInput?: boolean;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onCancel?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef<HTMLInputElement, Comp<"input", InputProps>>(
  ({ onEnter, onCancel, ...props }, ref) => {
    const _onKeyDown = useCallback(
      (e: any) => {
        e.stopPropagation();
        props.onKeyDown?.(e);
        if (e.key === "Enter") onEnter?.(e);
        if (e.key === "Escape") onCancel?.(e);
      },
      [onCancel, onEnter, props],
    );

    return <TextInput onKeyDown={_onKeyDown} {...props} ref={ref} />;
  },
);

export type SearchInputProps = Omit<Comp<"input", InputProps>, "type"> & {
  onClear?(): void;
  wrapperDivStyle?: React.CSSProperties;
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ wrapperDivStyle, onClear, ...props }, passedRef) => {
    const ownRef = useRef<HTMLInputElement>(null);
    return (
      <div
        style={{
          position: "relative",
          ...wrapperDivStyle,
        }}
        ref={passedRef}
      >
        <IconREMSize
          height={1.6}
          width={1.6}
          style={{
            position: "absolute",
            left: "1rem",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          onClick={() => ownRef.current?.focus()}
        >
          <SearchIcon />
        </IconREMSize>
        <TextInput
          ref={ownRef}
          type="text"
          {...props}
          style={{
            paddingLeft: "3.5rem",
            paddingRight: "2.5rem",
            ...props.style,
          }}
        />
        {props.value !== "" && onClear && (
          <IconREMSize
            height={1}
            width={1}
            style={{
              position: "absolute",
              right: "1rem",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            onClick={() => {
              onClear();
              ownRef.current?.focus();
            }}
            hoverStroke={colors.blue800}
          >
            <CloseIcon />
          </IconREMSize>
        )}
      </div>
    );
  },
);

export const StyledTextarea = styled.textarea`
  ${TextRaw};
  min-height: 3.2rem;

  border-radius: 4px;

  box-sizing: border-box;

  border: 1px solid ${colors.inputOutline};
  background-color: white;
  &::placeholder {
    color: ${colors.primaryDisabled};
  }

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    &::placeholder {
      color: transparent;
    }
  }

  &:invalid {
    border-color: ${colors.redAlert};
  }

  &:disabled {
    background-color: ${colors.focusBackground};
    color: ${colors.primaryDisabled};
  }
`;

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onEnter?: () => void;
  onCancel?: () => void;
}

/**
 * @param commentId - The id of the comment to be shown. The comment is stored using this as part of the key, so it should be unique for this input field/value. E.g. "[configId]-turbineCost".
 * @returns
 */
export const TextArea = ({
  changelogInfo,
  onEnter,
  onCancel,
  ...props
}: TextAreaProps & {
  changelogInfo?: InputChangelogInfo;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [, setParentSize] = useState({ width: 0, height: 0 });
  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      const handleResize = (entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
          setParentSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(parentRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const _onKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      props.onKeyDown?.(e);
      if (e.key === "Enter") onEnter?.();
      if (e.key === "Escape") onCancel?.();
    },
    [onCancel, onEnter, props],
  );

  return (
    <div
      ref={parentRef}
      style={{
        position: "relative",
        width: "fit-content",
        height: "fit-content",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledTextarea onKeyDown={_onKeyDown} {...props} />

      {changelogInfo && (
        <ChangelogIconWrapper inFocus={isHovered}>
          <InputChangelog showBtn={isHovered} changelogInfo={changelogInfo} />
        </ChangelogIconWrapper>
      )}
    </div>
  );
};

const chevronSize = "10px";
const chevronCss = (color: string) => `
  background: url('data:image/svg+xml,<svg height="${chevronSize}" width="${chevronSize}" viewBox="0 0 24 24"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.25 7.31104L12.53 18.03C12.4604 18.0997 12.3778 18.1549 12.2869 18.1926C12.1959 18.2304 12.0984 18.2498 12 18.2498C11.9016 18.2498 11.8041 18.2304 11.7131 18.1926C11.6222 18.1549 11.5396 18.0997 11.47 18.03L0.75 7.31104" stroke="%23${color.slice(
  1,
)}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg>')
    no-repeat;
  background-position: calc(100% - 0.8rem) !important;
`;

const lockedCss = (color: string) => `
  background: url('data:image/svg+xml,<svg height="16" width="16" viewBox="0 0 16 16"  fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 6.5H3.5C2.94772 6.5 2.5 6.94772 2.5 7.5V14.5C2.5 15.0523 2.94772 15.5 3.5 15.5H12.5C13.0523 15.5 13.5 15.0523 13.5 14.5V7.5C13.5 6.94772 13.0523 6.5 12.5 6.5Z" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
<path d="M4.5 6.5V4C4.5 3.07174 4.86875 2.1815 5.52513 1.52513C6.1815 0.868749 7.07174 0.5 8 0.5C8.92826 0.5 9.8185 0.868749 10.4749 1.52513C11.1313 2.1815 11.5 3.07174 11.5 4V6.5" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
<path d="M8 10V12" stroke="%23${color.slice(
  1,
)}" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
</svg>')
    no-repeat;
    background-position: calc(100% - 0.6rem) !important;
`;

export const StyledDimensionedInput = styled.div<{
  compact?: boolean;
  mini?: boolean;
  single?: boolean;
  invalid?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  background: #fff;
  gap: ${spaceSmall};
  position: relative;
  box-sizing: border-box;

  border: 1px solid ${colors.borderDefault};
  border-radius: ${borderRadiusMedium};

  min-height: ${(p) => (p.compact ? "2.4rem" : p.mini ? "2rem" : "3.2rem")};
  max-height: ${(p) => (p.compact ? "2.4rem" : p.mini ? "2rem" : "3.2rem")};
  width: 13rem;

  input {
    width: 0;
    flex: 1 1 2rem;
    min-width: 1lh;
    border-radius: inherit;
    background: inherit;
    border: none;
    height: ${(p) => (p.compact ? "2rem" : p.mini ? "1.4rem" : "2.8rem")};

    ${(p) =>
      p.compact
        ? `padding: ${spacing1} 0 ${spacing1} ${spacing5}`
        : `padding: ${spacing3} 0 ${spacing3} ${spacing6}`};
  }

  &::placeholder {
    color: ${colors.textDisabled};
  }

  &:focus-within {
    outline: none;
    border-color: ${colors.borderSelected};
  }

  &:hover:not(:focus-within) {
    border-color: ${colors.grey400};
  }

  &:disabled {
    border-color: ${colors.grey300};
    background-color: ${colors.surfaceDisabled};
    color: ${colors.grey500};
    cursor: not-allowed;
  }

  ${(p) => p.invalid && `border-color: ${colors.textError} !important`};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  select {
    ${TextRaw};
    text-align: end;
    border-radius: inherit;
    border: none;
    ${(p) =>
      p.compact
        ? ` padding: 0      calc(1.4rem + ${chevronSize}) 0      0`
        : p.mini
          ? ` padding: 0 calc(1rem + ${chevronSize}) 0 0`
          : ` padding: 0.5rem calc(1.4rem + ${chevronSize}) 0.5rem 0`};
    background: inherit;

    &:focus-visible {
      outline: none;
      text-decoration: underline;
    }

    ${(p) =>
      !p.single &&
      chevronCss(p.disabled ? colors.textDisabled : colors.textPrimary)}
    ${(p) => p.single && `padding-right: ${spaceSmall};`}

    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
  }

  select:disabled {
    color: ${colors.textDisabled};
  }

  ${(p) =>
    p.disabled &&
    `color: ${colors.inputOutline};
     border-color: ${colors.inputOutline};
     background-color: ${colors.focusBackground};
     `}
`;

const ValidationWrapper = styled.div`
  position: absolute;
  transform: translateY(110%);
  bottom: 0;
  z-index: 1;
`;

/**
 * @param commentId - The id of the comment to be shown. The comment is stored using this as part of the key, so it should be unique for this input field/value. E.g. "[configId]-turbineCost".
 * @returns
 */
export const InputDimensioned = <U extends string>({
  value,
  unit,
  units,
  onChange,
  onUnitChange,
  onDefault,
  validate,
  validationMessage,
  validationMessageStyle,
  compact,
  mini,
  style,
  format = (v) => v.toString(),
  min,
  max,
  changelogInfo,
  scaleFactor = 1,
  decimals,
  ...props
}: {
  scaleFactor?: number;
  decimals?: number;
  changelogInfo?: InputChangelogInfo;
  nodeId?: string;
  initialValue?: number;
  value?: number;
  min?: number;
  max?: number;
  unit?: U;
  units?: readonly U[];
  onChange?: (
    value: number,
    e: ChangeEvent<HTMLInputElement>,
    unit?: U,
  ) => void;
  onUnitChange?: (unit: U, e: ChangeEvent<HTMLSelectElement>) => void;
  onDefault?: (unit: U | undefined) => number;
  validate?: (value: number, unit?: U) => boolean;
  validationMessage?: string;
  validationMessageStyle?: React.CSSProperties;
  style?: CSSProperties;
  compact?: boolean;
  mini?: boolean;
  /** Formatter for driven values.  Note that user inputted numbers will not be ran through this. */
  format?: (value: number, unit?: U) => string;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "min" | "max"
>) => {
  const scaledValue = useMemo(() => {
    if (value == null) return undefined;
    return decimals != null
      ? roundToDecimal(value * scaleFactor, decimals)
      : value * scaleFactor;
  }, [decimals, scaleFactor, value]);

  const singleUnit = (units?.length ?? 0) <= 1;

  const [text, setText] = useState(
    scaledValue !== undefined ? format(scaledValue, unit) : "",
  );
  const [valid, setValid] = useState(true);
  const [hasFocus, setFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [, setParentSize] = useState({ width: 0, height: 0 });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const handleResize = (entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
          setParentSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const valueDiffers =
    scaledValue != null
      ? 1e-5 < Math.abs(parseFloat(text) - scaledValue)
      : false;
  useEffect(() => {
    if (
      (valueDiffers || isNaN(parseFloat(text))) &&
      !hasFocus &&
      scaledValue !== undefined
    ) {
      const formatted = format(scaledValue, unit);
      setText(formatted);
      const valid = validate?.(scaledValue) ?? true;
      setValid(valid);
    }
  }, [format, hasFocus, text, unit, validate, scaledValue, valueDiffers]);

  // If value is changed from something to undefined, clear the text field.
  useEffect(() => {
    if (value === undefined) setText("");
  }, [value]);

  return (
    <>
      <StyledDimensionedInput
        style={style}
        compact={compact}
        mini={mini}
        single={singleUnit}
        invalid={!valid}
        disabled={props.disabled}
        ref={containerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Input
          type="number"
          compact={compact}
          mini={mini}
          value={text}
          min={min}
          max={max}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter" && text.trim() === "" && onDefault) {
              const defaultValue = onDefault(unit);
              setText(defaultValue.toString());
              setValid(true);
            }
          }}
          {...props}
          onFocus={(e) => {
            setFocus(true);
            props.onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            props.onBlur?.(e);
          }}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();

            const [n, d] = e.target.value.split(".");
            const decimalsToUse =
              decimals != null && d
                ? decimals === 0
                  ? undefined
                  : d.slice(0, decimals)
                : d;
            const text = d && decimalsToUse ? n + "." + decimalsToUse : n;

            setText(text);

            const nonScaledNum = parseFloat(text.replaceAll(",", "."));

            if (validate && !validate(nonScaledNum, unit)) {
              setValid(false);
              return;
            }
            const inverseScaling = 1 / scaleFactor;
            const num = nonScaledNum * inverseScaling;
            setValid(true);
            onChange?.(num, e, unit);
          }}
        />

        <select
          value={unit}
          disabled={singleUnit || props.disabled}
          onChange={(e) => {
            const u = e.target.value as U;
            onUnitChange?.(u, e);
          }}
          tabIndex={-1}
          style={{
            color: colors.textSecondary,
            opacity: props.disabled ? 0.5 : undefined,
          }}
        >
          {(units ? units : unit ? [unit] : []).map((u) => (
            <option key={u} value={u}>
              {u}
            </option>
          ))}
        </select>
        {!valid && validationMessage && !props.disabled && (
          <ValidationPositioner style={validationMessageStyle}>
            <ValidationContainer>
              <p>{validationMessage}</p>
            </ValidationContainer>
          </ValidationPositioner>
        )}
        {changelogInfo && (
          <ChangelogIconWrapper inFocus={isHovered}>
            <InputChangelog
              showBtn={isHovered}
              changelogInfo={changelogInfo}
              suffix={unit}
              scaleFactor={scaleFactor}
              decimals={decimals}
              disabled={props.disabled}
            />
          </ChangelogIconWrapper>
        )}
      </StyledDimensionedInput>
    </>
  );
};

/** Uses the cannonical unit of {@link Distance} (meter). */
export const InputDimensionedDistance = (
  props: Parameters<typeof InputDimensioned>[0] & {
    shouldConvert?: boolean;
  },
) => {
  const [unit, setUnit] = useState(Distance.cannonical);
  return (
    <InputDimensioned
      {...props}
      unit={props.unit ?? unit}
      units={Distance.units}
      onChange={(value, event) => {
        const cann = props.shouldConvert
          ? Distance.convert(value, { from: unit })
          : value;
        props.onChange?.(cann, event, unit);
      }}
      onUnitChange={(unit, event) => {
        setUnit(unit as "m" | "km" | "NM");
        props.onUnitChange?.(unit, event);
      }}
    />
  );
};

// Move the validation "popup" if it is outside the screen
const ValidationPositioner = ({
  style,
  children,
}: { style?: React.CSSProperties } & React.PropsWithChildren) => {
  const thisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!thisRef.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry1]) => {
      if (!thisRef.current) {
        return;
      }

      const isRightSideOfScreen =
        entry1.boundingClientRect.right > entry1.intersectionRect.right;
      const toMove =
        entry1.boundingClientRect.width - entry1.intersectionRect.width;

      if (isRightSideOfScreen) {
        thisRef.current.style.left = `-${toMove}px`;
      } else {
        thisRef.current.style.left = `${toMove}px`;
      }
    }, {});

    observer.observe(thisRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ValidationWrapper ref={thisRef} style={style}>
      {children}
    </ValidationWrapper>
  );
};

export const InputNumber = <U extends string>({
  value,
  onChange,
  validate,
  validationMessage,
  compact,
  style,
  changelogInfo,
  format = (v) => v.toString(),
  blurOnEnter = true,
  ...props
}: {
  value?: number;
  initialValue?: number;
  onChange?: (value: number, e: ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: number) => boolean;
  validationMessage?: string;
  style?: CSSProperties;
  compact?: boolean;
  changelogInfo?: InputChangelogInfo;
  blurOnEnter?: boolean;
  /** Formatter for driven values.  Note that user inputted numbers will not be ran through this. */
  format?: (value: number, unit?: U) => string;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "min" | "max"
>) => {
  const [text, setText] = useState(value != null ? format(value) : "");
  const [valid, setValid] = useState(true);
  const [hasFocus, setFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [, setParentSize] = useState({ width: 0, height: 0 });

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const handleResize = (entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
          setParentSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const valueDiffers =
    value != null ? 1e-5 < Math.abs(parseFloat(text) - value) : false;
  useEffect(() => {
    if (valueDiffers && !hasFocus && value !== undefined) {
      const formatted = format(value);
      setText(formatted);
      const valid = validate?.(value) ?? true;
      setValid(valid);
    }
  }, [format, hasFocus, text, validate, value, valueDiffers]);

  // If value is changed from something to undefined, clear the text field.
  useEffect(() => {
    if (value === undefined) setText("");
  }, [value]);

  return (
    <>
      <StyledDimensionedInput
        style={style}
        compact={compact}
        single={true}
        invalid={!valid}
        className="StyledDimensionedInput-2"
        disabled={props.disabled}
        ref={containerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Input
          type="number"
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Escape") e.stopPropagation();
            if (e.key === "Enter" && blurOnEnter) {
              (e.target as HTMLElement)["blur"]?.();
            }
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setText(e.target.value);

            const num = parseFloat(e.target.value.replaceAll(",", "."));
            if (validate && !validate(num)) {
              setValid(false);
              return;
            }
            setValid(true);
            onChange?.(num, e);
          }}
          compact={compact}
          {...props}
        />

        {changelogInfo && (
          <ChangelogIconWrapper inFocus={isHovered}>
            <InputChangelog
              showBtn={isHovered}
              changelogInfo={changelogInfo}
              disabled={props.disabled}
            />
          </ChangelogIconWrapper>
        )}
        {!valid && validationMessage && !props.disabled && (
          <ValidationPositioner>
            <ValidationContainer>
              <p>{validationMessage}</p>
            </ValidationContainer>
          </ValidationPositioner>
        )}
      </StyledDimensionedInput>
    </>
  );
};
