import { _OrgFeatureAccess } from "../components/OrganisationFeatureAccess/types";
import { fetchFeatureAccess } from "../services/featureAccessService";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";

const featureAccessAtomFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom(() => fetchFeatureAccess(organisationId)),
);

export const libraryAllSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom(async (get) => {
      if (!organisationId) return false;
      const featureAccessInOrg = await get(
        featureAccessAtomFamily({ organisationId }),
      );
      if (!featureAccessInOrg) return false;
      return featureAccessInOrg.some((fa) => fa === "library_all");
    }),
);

export const onshoreAccessSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom(async (get) => {
      if (!organisationId) return false;
      const featureAccessInOrg = await get(
        featureAccessAtomFamily({ organisationId }),
      );
      if (!featureAccessInOrg) return false;
      return featureAccessInOrg.some((fa) => fa === "onshore_access");
    }),
);

export const offshoreAccessSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom(async (get) => {
      if (!organisationId) return false;
      const featureAccessInOrg = await get(
        featureAccessAtomFamily({ organisationId }),
      );
      if (!featureAccessInOrg) return false;
      return featureAccessInOrg.some((fa) => fa === "offshore_access");
    }),
);

export const integrationAccessSelectorFamily = atomFamily(
  ({ organisationId }: { organisationId: string | undefined }) =>
    atom(async (get) => {
      if (!organisationId) return false;
      const featureAccessInOrg = await get(
        featureAccessAtomFamily({ organisationId }),
      );
      if (!featureAccessInOrg) return false;
      return featureAccessInOrg.some((fa) => fa === "integration");
    }),
);
