import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { substationsInParkFamily } from "state/jotai/substation";
import { ValidationWarning } from "state/validationWarnings";

export const substationMissingTypeWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubstationMissingType>
        | undefined
      >
    >(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const withoutType = substations.filter(
        (sub) => sub.properties.substationTypeId === undefined,
      );
      if (withoutType.length === 0) return;
      return {
        type: ValidationWarningTypes.SubstationMissingType,
        featureIds: withoutType.map((s) => s.id),
        parkId: parkId,
      };
    }),
);
