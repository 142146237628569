import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  CustomLayer,
  ExternalDataSourceLink,
  ExternalDataSourceLinkLayerWithSourceArcgis,
  Layer,
  SourceTypesLayer,
} from "../../../types/layers";
import { spaceLarge, spacing4 } from "../../../styles/space";
import {
  CountryPill,
  removeHtmlAndMakeClickableLinks,
  SourcePill,
  TagPill,
} from "../../DataLayersModal/components/ListView/ListView";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { SourceNameComponent } from "../LayerListPane/ProjectLayerItem";
import {
  selectedLayerForLayerInformationSelector,
  selectedLayerIdsWithCollectionIdAtom,
} from "../state";
import { useGetUserFromIdOrgPage } from "../../../hooks/useUser";
import UserImageRoundFromId from "../../UserImage/UserImageRoundFromId";
import { dateToDateTime } from "../../../utils/utils";
import { isCustomLayer, isPrivateSource } from "../utils";
import { SkeletonBlock } from "../../Loading/Skeleton";
import LayerPreviewImageV2 from "../../DataLayersModal/components/LayerPreviewImageV2";
import { selectedSourceNameAtom, SourceName } from "../LayerSettings/state";
import { getLegendGraphicsUrl } from "../LayerListV2";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { dynamicVectorLayerFeaturesAtomFamily } from "state/layer";
import {
  arcgisLayerDrawingInfoSelector,
  isArcgisLayer,
} from "state/arcgisRestAPI";
import Tooltip from "components/General/Tooltip";

const ArcgisRestAPILegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ArcgisRestAPILegendRowWrapper = styled.div`
  display: flex;
  flex-direction: Row;
  gap: 0.5rem;
  align-items: center;
`;

const ColorBox = styled.div<{
  color: string;
}>`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.color};
`;

const DataSourceAttribute = ({
  name,
  value,
}: {
  name: string;
  value?: React.ReactNode;
}) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <div
        style={{
          fontWeight: "bold",
          marginRight: "0.5rem",
        }}
      >
        {name}:
      </div>
      <span
        style={{
          overflowWrap: "anywhere",
        }}
      >
        {value}
      </span>
    </>
  );
};

const LegalInformation = ({
  sourceLink,
}: {
  sourceLink: ExternalDataSourceLink;
}) => {
  if (
    !sourceLink.licenseType &&
    !sourceLink.attribution &&
    !sourceLink.urlWhereFound
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
      }}
    >
      <h4
        style={{
          margin: 0,
        }}
      >
        Legal information
      </h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          rowGap: "0.5rem",
        }}
      >
        <DataSourceAttribute
          name="License Type"
          value={sourceLink.licenseType}
        />
        {sourceLink.licenseUrl && (
          <DataSourceAttribute
            name="License URL"
            value={
              <a href={sourceLink.licenseUrl} target="_blank" rel="noreferrer">
                {sourceLink.licenseUrl}
              </a>
            }
          />
        )}
        <DataSourceAttribute
          name="Source URL"
          value={
            sourceLink.urlWhereFound ? (
              <a
                href={sourceLink.urlWhereFound}
                target="_blank"
                rel="noreferrer"
              >
                {sourceLink.urlWhereFound}
              </a>
            ) : undefined
          }
        />
        <DataSourceAttribute
          name="Attribution"
          value={sourceLink.attribution}
        />
      </div>
    </div>
  );
};

const UploadedByWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const Author = ({
  author,
  createdAt,
}: {
  author: string;
  createdAt: number;
}) => {
  const user = useGetUserFromIdOrgPage()(author);
  return (
    <UploadedByWrapper>
      <div>Uploaded by:</div>
      <UserImageRoundFromId size={1.6} userId={author} />
      <div>{user?.nickname}</div>
      <div>{dateToDateTime(new Date(createdAt))}</div>
    </UploadedByWrapper>
  );
};

const AuthorWrapper = ({ layer }: { layer: CustomLayer }) => {
  return (
    <>
      {layer.author && layer.dateAdded && (
        <Author author={layer.author} createdAt={layer.dateAdded} />
      )}
    </>
  );
};

const LayerInformationWrapper = styled.div`
  position: fixed;
  left: var(--left-menu-margin);
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spacing4}
  );
`;

const LayerInfoSpacing = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceLarge};
`;

const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex-grow: 0;
`;

const menuFrameStyling: React.CSSProperties = {
  maxHeight: "calc(100vh - 20rem)",
  overflowY: "auto",
  resize: "horizontal",
};

export const TranslatedLayerOrSourceLinkAbstract = ({
  layerOrSourceLink,
}: {
  layerOrSourceLink: {
    abstract?: string | null;
    abstractEnglish?: string;
  };
}) => {
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);
  if (!layerOrSourceLink.abstract) {
    return null;
  }

  if (!layerOrSourceLink.abstractEnglish) {
    return <>{removeHtmlAndMakeClickableLinks(layerOrSourceLink.abstract)}</>;
  }

  if (selectedSourceName === SourceName.English) {
    return (
      <>
        {removeHtmlAndMakeClickableLinks(layerOrSourceLink.abstractEnglish)}
        <span
          style={{
            cursor: "help",
          }}
          title="This description has been automatically translated"
        >
          {" "}
          *
        </span>
      </>
    );
  }

  return <>{removeHtmlAndMakeClickableLinks(layerOrSourceLink.abstract)}</>;
};

export const TranslatedLayerName = ({ layer }: { layer: Layer }) => {
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);
  if (isCustomLayer(layer)) {
    return <>{layer.name}</>;
  }

  if (!("nameEnglish" in layer) || !layer.nameEnglish) {
    return <>{layer.name}</>;
  }

  if (selectedSourceName === SourceName.English) {
    return (
      <>
        {layer.nameEnglish}
        <span
          style={{
            cursor: "help",
          }}
          title="This name has been automatically translated"
        >
          *
        </span>
      </>
    );
  }
  return <Tooltip text={layer.name}>{layer.name}</Tooltip>;
};

const ArcgisRestAPILegends = ({
  layer,
}: {
  layer: ExternalDataSourceLinkLayerWithSourceArcgis;
}) => {
  const drawingInfo = useAtomValue(
    arcgisLayerDrawingInfoSelector({
      layer,
    }),
  );

  if (drawingInfo?.type === "uniqueValue" && drawingInfo?.uniqueValueInfos) {
    return (
      <div>
        <h4>Legend graphic</h4>
        <ArcgisRestAPILegendWrapper>
          {drawingInfo.uniqueValueInfos.map((uniqueValueInfo) => (
            <ArcgisRestAPILegendRowWrapper
              key={uniqueValueInfo.symbol.color.join(",")}
            >
              <ColorBox
                color={`rgb(${uniqueValueInfo.symbol.color.join(",")})`}
              />
              <div>{uniqueValueInfo.value}</div>
            </ArcgisRestAPILegendRowWrapper>
          ))}
        </ArcgisRestAPILegendWrapper>
      </div>
    );
  }

  return null;
};

const LayerInformationInner = ({ layer }: { layer: Layer }) => {
  const legendGraphicUrl = useMemo(() => {
    if (layer.sourceType === SourceTypesLayer.wms) {
      return getLegendGraphicsUrl(layer.sourceLayerId, layer.sourceLink.url);
    }
  }, [layer]);

  const dynamicVectorLayerFeatures = useAtomValue(
    dynamicVectorLayerFeaturesAtomFamily(layer.id),
  );

  return (
    <LayerInfoSpacing>
      <div>
        <h4
          style={{
            margin: "0 0 0.8rem 0",
            wordBreak: "break-word",
          }}
        >
          {layer.name}
        </h4>
        {isCustomLayer(layer) && <AuthorWrapper layer={layer} />}
        <LayerPreviewImageV2
          layer={layer}
          style={{
            width: "27rem",
            boxSizing: "border-box",
          }}
          clickToEnlarge={false}
        />
      </div>
      <PillWrapper>
        {layer.source?.countries?.map((country) => (
          <CountryPill key={country}>{country}</CountryPill>
        ))}
        {layer.tags?.map((tag) => <TagPill key={tag}>{tag}</TagPill>)}
        <SourcePill>
          <SourceNameComponent layer={layer} />
        </SourcePill>
      </PillWrapper>
      {legendGraphicUrl && (
        <div>
          <h4>Legend graphic</h4>
          <img src={legendGraphicUrl} alt={"Legend graphic"} />
        </div>
      )}
      {isArcgisLayer(layer) && <ArcgisRestAPILegends layer={layer} />}
      {!isCustomLayer(layer) && (
        <LegalInformation sourceLink={layer.sourceLink} />
      )}
      {!isCustomLayer(layer) && layer.deprecated != null && (
        <SimpleAlert
          title={"Deprecated"}
          text={layer.deprecated}
          type={"error"}
        ></SimpleAlert>
      )}
      {dynamicVectorLayerFeatures.hasError && (
        <SimpleAlert
          title={"Problem downloading"}
          text={`Layer experienced error when downloading, ${dynamicVectorLayerFeatures.features.length === 0 ? `no features downloaded` : `${dynamicVectorLayerFeatures.features.length} features successfully downloaded`}`}
        ></SimpleAlert>
      )}

      {isCustomLayer(layer) && (
        <>
          {layer.description && (
            <div>
              <h4>Description</h4>
              <span>{layer.description}</span>
            </div>
          )}
        </>
      )}
      {!isCustomLayer(layer) && (
        <>
          {layer.abstract && (
            <div>
              <h4>Description</h4>
              <TranslatedLayerOrSourceLinkAbstract layerOrSourceLink={layer} />
            </div>
          )}
          {layer.sourceLink?.abstract && (
            <div>
              <h4>Source description</h4>
              <TranslatedLayerOrSourceLinkAbstract
                layerOrSourceLink={layer.sourceLink}
              />
            </div>
          )}
        </>
      )}
      {layer.source && isPrivateSource(layer.source) && (
        <div>
          <h4>Source url</h4>
          <span
            style={{
              wordWrap: "break-word",
            }}
          >
            {layer.sourceLink.url}
          </span>
        </div>
      )}
    </LayerInfoSpacing>
  );
};

const LayerInformation = () => {
  const menuFrameRef = useRef<HTMLDivElement>(null);
  const selectedLayer = useAtomValue(selectedLayerForLayerInformationSelector);

  const setSelectedLayers = useSetAtom(selectedLayerIdsWithCollectionIdAtom);

  const onExit = useCallback(() => {
    setSelectedLayers([]);
  }, [setSelectedLayers]);

  if (!selectedLayer) {
    return null;
  }

  return (
    <LayerInformationWrapper>
      <MenuFrame
        ref={menuFrameRef}
        title="Layer information"
        onExit={onExit}
        style={menuFrameStyling}
      >
        <React.Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SkeletonBlock
                style={{
                  width: "28rem",
                  height: "28rem",
                }}
              />
            </div>
          }
        >
          <LayerInformationInner layer={selectedLayer} />
        </React.Suspense>
      </MenuFrame>
    </LayerInformationWrapper>
  );
};

export default LayerInformation;
