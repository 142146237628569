import Dropdown from "components/Dropdown/Dropdown";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { Input, InputDimensioned } from "components/General/Input";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import {
  GenericFromTableOrCostInputElement,
  RedirectToProjectFinancialConfig,
} from "components/Organisation/Library/financial/ProcurementCosts/CostInComponentView";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import {
  TitleContainer,
  HorizontalLine,
} from "components/SettingsV2/Shared/styles";
import {
  ExportSystemType,
  ExportCableVoltageType,
  CableType,
  IAVoltageType,
} from "services/cableTypeService";
import {
  ChangelogCategory,
  GetChangeLogValue,
  InputChangelogInfo,
} from "components/InputChangelog/types";
import { Row } from "components/General/Layout";
import { Label } from "components/General/Form";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";

type GeneralInfoProps = {
  cable: CableType;
  setCable: (cable: CableType) => void;
  nodeId: string;
  changelogCategory: ChangelogCategory;
  disabled: boolean;
  isLibraryCable: boolean | undefined;
  onshore: boolean;
  getChangelogInfo: (
    id: string,
    nodeId: string,
    field: string,
    category: ChangelogCategory,
    getValue?: GetChangeLogValue,
  ) => InputChangelogInfo;
};

const GeneralInfo = ({
  cable,
  setCable,
  nodeId,
  changelogCategory,
  disabled,
  isLibraryCable,
  onshore,
  getChangelogInfo,
}: GeneralInfoProps) => {
  return (
    <>
      <TitleContainer>
        <Subtitle>General info</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Name</SecondaryText>
          <ChangelogAndCommentWrapper
            changelogInfo={getChangelogInfo(
              cable.id,
              nodeId,
              "name",
              changelogCategory,
            )}
            style={{
              width: "42.4rem",
            }}
            disabled={disabled}
          >
            <Input
              style={{
                width: "100%",
              }}
              disabled={disabled}
              value={cable.name}
              type="string"
              onChange={(e) =>
                setCable({
                  ...cable,
                  name: e.target.value,
                })
              }
            />
          </ChangelogAndCommentWrapper>
        </Label>
        <Label>
          <SecondaryText>Voltage</SecondaryText>
          <ChangelogAndCommentWrapper
            changelogInfo={getChangelogInfo(
              cable.id,
              nodeId,
              "voltage",
              changelogCategory,
            )}
            style={{
              width: "100%",
            }}
            disabled={disabled}
          >
            <Dropdown
              style={{
                width: "20rem",
              }}
              disabled={disabled}
              value={cable.voltage}
              onChange={(e) => {
                setCable({
                  ...cable,
                  voltage: Number.parseInt(e.target.value),
                });
              }}
            >
              <>
                {cable.exportCableType
                  ? getVoltageOptionsForExportCable({
                      cableType: cable.exportCableType,
                      onshore,
                    })
                  : getVoltageOptionsForInterArrayCable({ onshore })}
              </>
            </Dropdown>
          </ChangelogAndCommentWrapper>
        </Label>
        <Label>
          <SecondaryText>Cost</SecondaryText>
          <Row alignCenter>
            {isLibraryCable ? (
              <GenericFromTableOrCostInputElement
                activeProcurementType={
                  cable.exportCableType === ExportSystemType.HVAC
                    ? "exportCable"
                    : "interArrayCable"
                }
                defaultInput={
                  <InputDimensioned
                    disabled={disabled}
                    unit={cable.costUnit}
                    value={cable.cost}
                    initialValue={cable.cost}
                    step={0.1}
                    validate={(e) => 0 <= e}
                    validationMessage={`Needs to be greater than 0`}
                    changelogInfo={getChangelogInfo(
                      cable.id,
                      nodeId,
                      "cost",
                      changelogCategory,
                    )}
                    onChange={(cost) => {
                      setCable({
                        ...cable,
                        cost,
                      });
                    }}
                  />
                }
              />
            ) : (
              <RedirectToProjectFinancialConfig
                menuId={
                  cable.exportCableType ? EXPORT_CABLE_MENU_ID : CABLE_MENU_ID
                }
              />
            )}
          </Row>
        </Label>
      </div>
    </>
  );
};

export default GeneralInfo;

const getVoltageOptionsForExportCable = ({
  cableType,
  onshore,
}: {
  cableType: string;
  onshore: boolean;
}) => {
  return (
    <>
      {cableType === ExportSystemType.HVAC ? (
        <>
          <option
            value={ExportCableVoltageType.kV110}
          >{`${ExportCableVoltageType.kV110}kV`}</option>
          {!onshore && (
            <>
              <option
                value={ExportCableVoltageType.kV150}
              >{`${ExportCableVoltageType.kV150}kV`}</option>
              <option
                value={ExportCableVoltageType.kV220}
              >{`${ExportCableVoltageType.kV220}kV`}</option>
              <option value={ExportCableVoltageType.kV275}>
                {`${ExportCableVoltageType.kV275}kV`}
              </option>
              <option
                value={ExportCableVoltageType.kV345}
              >{`${ExportCableVoltageType.kV345}kV`}</option>
              <option
                value={ExportCableVoltageType.kV380}
              >{`${ExportCableVoltageType.kV380}kV`}</option>
              <option
                value={ExportCableVoltageType.kV420}
              >{`${ExportCableVoltageType.kV420}kV`}</option>
            </>
          )}
        </>
      ) : (
        <>
          <option value={ExportCableVoltageType.kV320}>
            {`${ExportCableVoltageType.kV320}kV`}
          </option>
          <option value={ExportCableVoltageType.kV525}>
            {`${ExportCableVoltageType.kV525}kV`}
          </option>
        </>
      )}
    </>
  );
};

const getVoltageOptionsForInterArrayCable = ({
  onshore,
}: {
  onshore: boolean;
}) => {
  return (
    <>
      {onshore ? (
        <>
          <option
            value={IAVoltageType.kV33}
          >{`${IAVoltageType.kV33}kV`}</option>
        </>
      ) : (
        <>
          <option
            value={IAVoltageType.kV66}
          >{`${IAVoltageType.kV66}kV`}</option>
          <option value={IAVoltageType.kV132}>
            {`${IAVoltageType.kV132}kV`}
          </option>
        </>
      )}
    </>
  );
};
