import React, { useMemo, createContext, useContext } from "react";
import { useAtomValue } from "jotai";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import FolderMenuOpenIcon from "@icons/20/FolderMenuOpen.svg";
import PersonalFolderClosedIcon from "@icons/20/PersonalFolderClosed.svg";
import PersonalFolderOpenIcon from "@icons/20/PersonalFolderOpen.svg";
import { IconREMSize, typography } from "styles/typography";
import type { Node } from "services/customerAPI";
import { colors } from "styles/colors";
import useBooleanState from "hooks/useBooleanState";
import { getNodeName } from "components/Organisation/utils";
import {
  ExpandArrowWrapper,
  FolderListWrapper,
  ListItemTopRow,
  ListItemWrapper,
} from "components/FolderList/style";
import { WithTooltip } from "components/General/Tooltip";
import { findTopLevelNode } from "components/Projects/useOrganisationFolderCrud";
import { organisationIdAtom } from "state/pathParams";
import { Margin, ProjectTitle } from "./style";

const StateContext = createContext<
  | undefined
  | {
      selectedProjectId?: string | undefined;
      setSelectedProjectId?: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    }
>(undefined);

const FolderIsEmptyMessage = ({ depth }: { depth: number }) => {
  return (
    <ListItemWrapper>
      <ListItemTopRow
        alignCenter
        depth={depth}
        hasIcon={false}
        hasExpandIcon={false}
      >
        <p
          style={{
            ...typography.contentAndButtons,
            color: colors.textDisabled,
          }}
        >
          Folder is empty
        </p>
      </ListItemTopRow>
    </ListItemWrapper>
  );
};

const ProjectElement = ({
  projectNode,
  level,
}: {
  projectNode: Node;
  level: number;
}) => {
  const context = useContext(StateContext);
  return (
    <ListItemWrapper>
      <WithTooltip text={projectNode.name}>
        <ListItemTopRow
          alignCenter
          depth={level}
          hasIcon={false}
          hasExpandIcon={false}
          clickable={true}
          active={context?.selectedProjectId === projectNode.id}
          onClick={() =>
            context &&
            context.setSelectedProjectId &&
            context.setSelectedProjectId(projectNode.id)
          }
        >
          <ProjectTitle selected={false} noHover={true}>
            {projectNode.name}
          </ProjectTitle>
        </ListItemTopRow>
      </WithTooltip>
    </ListItemWrapper>
  );
};

const FolderWithChildren = ({
  folderNode,
  allNodes,
  level,
}: {
  folderNode: Node;
  level: number;
  allNodes: Node[];
}) => {
  const [open, toggleOpen] = useBooleanState(false);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const topNodes = useMemo(
    () => allNodes.filter((n) => n.parent_id === folderNode.id),
    [folderNode, allNodes],
  );

  const isPersonalFolder = folderNode?.type === "personal_folder";
  const showPersonalFolder =
    isPersonalFolder || folderNode
      ? findTopLevelNode(allNodes, folderNode.id, organisationId ?? "")
          ?.type === "personal_folder"
      : false;

  return (
    <ListItemWrapper>
      <WithTooltip text={getNodeName(folderNode)}>
        <ListItemTopRow
          alignCenter
          depth={level}
          hasIcon={true}
          hasExpandIcon={true}
          clickable={true}
          onClick={toggleOpen}
        >
          <ExpandArrowWrapper
            open={open}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleOpen();
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          <IconREMSize height={2} width={2}>
            {open ? (
              showPersonalFolder ? (
                <PersonalFolderOpenIcon />
              ) : (
                <FolderMenuOpenIcon />
              )
            ) : showPersonalFolder ? (
              <PersonalFolderClosedIcon />
            ) : (
              <FolderMenuClosedIcon />
            )}
          </IconREMSize>
          <ProjectTitle noHover>{getNodeName(folderNode)}</ProjectTitle>
        </ListItemTopRow>
      </WithTooltip>
      {open && (
        <ChildrenNodesForParent
          allNodes={allNodes}
          level={level + 1}
          topNodes={topNodes}
        />
      )}
    </ListItemWrapper>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  allNodes,
  level,
}: {
  topNodes: Node[];
  allNodes: Node[];
  level: number;
}) => {
  const sortedPersonalFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "personal_folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  if (topNodes.length === 0) {
    return <FolderIsEmptyMessage depth={level} />;
  }

  return (
    <Margin>
      {sortedPersonalFolders.map((n) => (
        <FolderWithChildren
          allNodes={allNodes}
          level={level}
          key={n.id}
          folderNode={n}
        />
      ))}
      {sortedFolders.map((n) => (
        <FolderWithChildren
          allNodes={allNodes}
          level={level}
          key={n.id}
          folderNode={n}
        />
      ))}
      {sortedProjects.map((n) => (
        <ProjectElement level={level} projectNode={n} key={n.id} />
      ))}
    </Margin>
  );
};

const ProjectPicker = ({
  topNodes,
  allNodes,
  selectedProjectId,
  setSelectedProjectId,
}: {
  topNodes: Node[];
  allNodes: Node[];
  selectedProjectId?: string | undefined;
  setSelectedProjectId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  return (
    <StateContext.Provider value={{ selectedProjectId, setSelectedProjectId }}>
      <FolderListWrapper>
        <ChildrenNodesForParent
          allNodes={allNodes}
          level={0}
          topNodes={topNodes}
        />
      </FolderListWrapper>
    </StateContext.Provider>
  );
};

export default ProjectPicker;
