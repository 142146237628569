import { ABLY_SLOPE_INCLUDING_CUSTOM } from "../../state/ably";
import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../hooks/useAblyGeneric";
import { scream, sendWarning } from "../../utils/sentry";
import {
  SlopeResponseError,
  SlopeResponseFinished,
  _SlopeResponseError,
  _SlopeResponseFinished,
} from "../../types/bathymetryTypes";
import { z } from "zod";
import { bathymetrySlopeAblyResponse } from "../../state/bathymetry";
import { useJotaiCallback } from "utils/jotai";

const _Response = z.union([_SlopeResponseFinished, _SlopeResponseError]);

export function useAblySlopeBathymetryListener(nodeId: string) {
  const channelName = useMemo(() => `${nodeId}:all`, [nodeId]);

  const setResponse = useJotaiCallback(
    (_, set, response: SlopeResponseFinished | SlopeResponseError) => {
      set(bathymetrySlopeAblyResponse(response.id), Promise.resolve(response));
    },
    [],
  );

  const onMessage = useCallback(
    (message: InboundMessage) => {
      const data = message.data;
      const parse = _Response.safeParse(data);
      if (parse.success) {
        const { data } = parse;
        setResponse(data);
        if (data.status === "failed" && data.message !== "nodata") {
          sendWarning("Bathymetry slope response failed", {
            message: data.message,
          });
        }
      } else {
        scream(parse.error, {
          data: data,
          error: parse.error,
          message: "Failed to parse slope response",
        });
      }
    },
    [setResponse],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_SLOPE_INCLUDING_CUSTOM,
        onMessageReceived: onMessage,
      },
    ],
    [onMessage],
  );

  useAblyGeneric(channelName, events, nodeId);
}
