import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import styled from "styled-components";
import Folder from "@icons/14/Folder.svg?react";
import OpenFolder from "@icons/14/OpenFolder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import Add from "@icons/24/Add.svg?react";
import Close from "@icons/24/Close.svg?react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  ResultContainer,
  SearchAndSelectContainer,
  SecondaryText,
  TextEllipsis,
} from "../../style";
import { Node } from "services/customerAPI";
import { SVGWrapper } from "components/Organisation/style";
import { Chevron } from "components/General/Chevron";
import { Row } from "components/General/Layout";
import Button from "components/General/Button";
import Dropdown from "components/Dropdown/Dropdown";
import { UserAccessRole, _UserAccessRole } from "types/user";
import useTextInput from "hooks/useTextInput";
import { Input } from "components/General/Input";
import { IconBtn } from "components/General/Icons";
import SelectedLabel from "components/General/SelectedLabel";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { searchNodesWithChildren, setupFuseSearch } from "utils/nodeSearch";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import NoItemsGeneric from "components/General/NoItemsGeneric";

const StyledText = styled(TextEllipsis)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export type NodeAccess = {
  node_id: string;
  resource_name: UserAccessRole;
};

export function AdminNodeAccessModalWrapper(props: {
  onSave: (nodes: NodeAccess[]) => void;
  existingAccess: string[];
  disableAccessRole?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const nodes = useAtomValue(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  return <NodeAccessModal {...props} nodes={nodes} />;
}

export function NodeAccessModalWrapper(props: {
  onSave: (nodes: NodeAccess[]) => void;
  existingAccess: string[];
  disableAccessRole?: boolean;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);

  return <NodeAccessModal {...props} nodes={nodes} />;
}

function NodeAccessModal({
  nodes,
  onSave,
  existingAccess,
  disableAccessRole = false,
  style,
}: {
  nodes: Node[];
  onSave: (nodes: NodeAccess[]) => void;
  existingAccess: string[];
  disableAccessRole?: boolean;
  style?: React.CSSProperties;
}) {
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<UserAccessRole>("viewer");

  return (
    <SearchAndSelectContainer style={style}>
      <ProjectPicker
        nodes={nodes}
        selectedNodeIds={selectedNodeIds}
        onAddNodeId={(nodeId) => setSelectedNodeIds((cur) => [...cur, nodeId])}
        existingAccess={existingAccess}
        onClose={() => onSave([])}
      />
      {selectedNodeIds.length > 0 && (
        <>
          <Divider />
          <Row
            style={{
              padding: "1.6rem 1.2rem",
            }}
          >
            <Row
              style={{
                flexWrap: "wrap",
                flex: 1,
                gap: "0.6rem",
              }}
            >
              {selectedNodeIds.map((nodeId) => {
                const node = nodes.find((n) => n.id === nodeId);
                if (!node) return <></>;
                return (
                  <SingleNode
                    node={node}
                    key={nodeId}
                    remove={() =>
                      setSelectedNodeIds((cur) =>
                        cur.filter((n) => n !== nodeId),
                      )
                    }
                  />
                );
              })}
            </Row>
            {!disableAccessRole && (
              <Dropdown
                style={{
                  marginLeft: "auto",
                }}
                value={selectedRole}
                onChange={async (e) => {
                  const newRole = _UserAccessRole.parse(e.target.value);
                  setSelectedRole(newRole);
                }}
              >
                <option value={"admin"}>Admin</option>
                <option value={"editor"}>Editor</option>
                <option value={"viewer"}>Viewer</option>
              </Dropdown>
            )}
            <Button
              text="Add"
              onClick={() =>
                onSave(
                  selectedNodeIds.map((nodeId) => ({
                    node_id: nodeId,
                    resource_name: selectedRole,
                  })),
                )
              }
              buttonType="primary"
              icon={<Add />}
            />
          </Row>
        </>
      )}
    </SearchAndSelectContainer>
  );
}

function SingleNode({ node, remove }: { node: Node; remove: () => void }) {
  return <SelectedLabel title={node.name} onDeselect={remove} />;
}

const StateContext = createContext<
  | undefined
  | {
      selectedNodeIds: string[];
      onAddNodeId: (nodeId: string) => void;
      filteredNodes: Node[];
      existingAccess: string[];
    }
>(undefined);

const ProjectElement = ({
  projectNode,
  level,
  parentSelected,
  searchTerm,
}: {
  projectNode: Node;
  level: number;
  parentSelected: boolean;
  searchTerm: string;
}) => {
  const context = useContext(StateContext);
  const alreadyHasAccess =
    context?.existingAccess.some((nodeId) => nodeId === projectNode.id) ??
    false;
  const selected = context?.selectedNodeIds.some(
    (nodeId) => nodeId === projectNode.id,
  );

  const disabled = selected || parentSelected || alreadyHasAccess;

  const onSelect = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!context) return;
      if (!disabled) {
        context.onAddNodeId?.(projectNode.id);
      }
    },
    [context, disabled, projectNode.id],
  );

  const display = context?.filteredNodes?.some(
    (node) => node.id === projectNode.id,
  );

  const highlightSearchTerm = (text: string, searchTerm?: string) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm?.toLowerCase() ? (
        <strong key={index}>{part}</strong>
      ) : (
        part
      ),
    );
  };

  if (!display) return <></>;

  return (
    <ContentTableRow
      style={{
        padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
        cursor: "pointer",
      }}
      disabled={disabled}
      onClick={onSelect}
    >
      <SVGWrapper>
        <Earth />
      </SVGWrapper>
      <StyledText
        style={{
          marginRight: "auto",
        }}
      >
        {highlightSearchTerm(projectNode.name, searchTerm)}
      </StyledText>
      {parentSelected ? (
        <></>
      ) : alreadyHasAccess ? (
        <SecondaryText>Already a member</SecondaryText>
      ) : selected || parentSelected ? (
        <SecondaryText>Selected</SecondaryText>
      ) : (
        <></>
      )}
      {!disabled && (
        <Button
          buttonType="primary"
          size="small"
          text="Add"
          icon={<Add />}
          onClick={onSelect}
          style={{
            padding: "0.2rem 1.2rem",
          }}
        />
      )}
    </ContentTableRow>
  );
};

const FolderWithChildren = ({
  folderNode,
  level,
  parentSelected,
  searchTerm,
}: {
  folderNode: Node;
  level: number;
  parentSelected: boolean;
  searchTerm: string;
}) => {
  const context = useContext(StateContext);
  const [open, setOpen] = useState(false);

  const topNodes = useMemo(() => {
    const preFilteredNodes = context?.filteredNodes ?? [];
    const topNodesToShow = preFilteredNodes.filter(
      (n) => n.parent_id === folderNode.id,
    );
    return topNodesToShow;
  }, [context?.filteredNodes, folderNode.id]);

  const display =
    topNodes.length > 0 ||
    context?.filteredNodes?.some((node) => node.id === folderNode.id);

  const FolderIcon = open === true ? OpenFolder : Folder;

  const selected =
    context?.selectedNodeIds.some((nodeId) => nodeId === folderNode.id) ??
    false;

  const alreadyHasAccess = context?.existingAccess.some(
    (nodeId) => nodeId === folderNode.id,
  );

  const disabled = alreadyHasAccess || selected || parentSelected;

  const onSelect = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!context) return;
      if (!disabled) {
        context.onAddNodeId?.(folderNode.id);
      }
    },
    [context, disabled, folderNode.id],
  );

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      const hasMatchingChildren = topNodes.some(
        (node) =>
          node.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          folderNode.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setOpen(hasMatchingChildren);
    } else {
      setOpen(false);
    }
  }, [searchTerm, topNodes, folderNode.name]);

  if (!display) return <></>;

  return (
    <>
      <ContentTableRow
        style={{
          padding: `0.8rem 1.6rem 0.8rem ${level * 2.2}rem`,
          cursor: "pointer",
        }}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <SVGWrapper>
          <FolderIcon />
        </SVGWrapper>
        <StyledText>{folderNode.name}</StyledText>
        <Chevron open={open} size={"1rem"} />
        {parentSelected ? (
          <></>
        ) : alreadyHasAccess ? (
          <SecondaryText
            style={{
              marginLeft: "auto",
            }}
          >
            Has access
          </SecondaryText>
        ) : selected ? (
          <SecondaryText
            style={{
              marginLeft: "auto",
            }}
          >
            Selected
          </SecondaryText>
        ) : (
          <></>
        )}
        {!disabled && (
          <Button
            buttonType="primary"
            size="small"
            text="Add"
            icon={<Add />}
            onClick={onSelect}
            style={{
              padding: "0.2rem 1.2rem",
              marginLeft: "auto",
            }}
          />
        )}
      </ContentTableRow>
      {open && (
        <ChildrenNodesForParent
          searchTerm={searchTerm}
          parentSelected={alreadyHasAccess || selected || parentSelected}
          level={level + 1}
          topNodes={topNodes}
        />
      )}
    </>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  level,
  parentSelected,
  searchTerm,
}: {
  topNodes: Node[];
  level: number;
  parentSelected: boolean;
  searchTerm: string;
}) => {
  const sortedPersonalFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "personal_folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  return (
    <>
      {sortedPersonalFolders.map((n) => (
        <FolderWithChildren
          parentSelected={parentSelected}
          level={level}
          key={n.id}
          folderNode={n}
          searchTerm={searchTerm}
        />
      ))}
      {sortedFolders.map((n) => (
        <FolderWithChildren
          parentSelected={parentSelected}
          level={level}
          key={n.id}
          folderNode={n}
          searchTerm={searchTerm}
        />
      ))}
      {sortedProjects.map((n) => (
        <ProjectElement
          searchTerm={searchTerm}
          parentSelected={parentSelected}
          level={level}
          projectNode={n}
          key={n.id}
        />
      ))}
    </>
  );
};

function findOrgTopNodes(allNodes: Node[], orgId: string) {
  const topNodes = [] as Node[];
  const findTopNode = (nodeId: string, nodes: Node[]): void => {
    if (topNodes.some((n) => n.id === nodeId)) return;

    const node = nodes.find((n) => n.id === nodeId);
    if (!node || !node.parent_id) return;

    const parentNode = nodes.find((n) => n.id === node.parent_id);
    if (!parentNode) {
      if (node.id === orgId) return;
      topNodes.push(node);
      return;
    } else if (parentNode.id === orgId) {
      topNodes.push(node);
      return;
    }

    return findTopNode(parentNode.id, nodes);
  };

  allNodes.forEach((n) => findTopNode(n.id, allNodes));
  return topNodes;
}

export const ProjectPicker = ({
  nodes,
  selectedNodeIds,
  onAddNodeId,
  existingAccess,
  onClose,
}: {
  nodes: Node[];
  selectedNodeIds: string[];
  onAddNodeId: (nodeId: string) => void;
  existingAccess: string[];
  onClose: () => void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const topNodes = useMemo(
    () => findOrgTopNodes(nodes, organisationId),
    [nodes, organisationId],
  );

  const [filteredNodes, setFilteredNodes] = useState<Node[]>([]);
  const [name, onNameChange] = useTextInput("");

  const fuse = useMemo(() => setupFuseSearch(nodes), [nodes]);

  useEffect(() => {
    if (name.length > 0) {
      const searchResults = fuse.search(name).map((result) => result.item);
      const results = searchNodesWithChildren(nodes, searchResults);
      setFilteredNodes(results);
    } else {
      setFilteredNodes(nodes);
    }
  }, [name, nodes, fuse]);

  return (
    <StateContext.Provider
      value={{
        selectedNodeIds,
        onAddNodeId,
        filteredNodes,
        existingAccess,
      }}
    >
      <Row
        style={{
          alignItems: "center",
          padding: "1.6rem 1.2rem 0",
        }}
      >
        <Input
          autoFocus
          value={name}
          onChange={onNameChange}
          type="search"
          placeholder={`Search`}
          style={{
            width: "100%",
          }}
        />
        <IconBtn
          size="1.4rem"
          onClick={onClose}
          style={{
            marginLeft: "auto",
          }}
        >
          <Close />
        </IconBtn>
      </Row>
      <ResultContainer>
        {filteredNodes.length > 0 ? (
          <ContentTableColumn>
            <ChildrenNodesForParent
              level={1}
              topNodes={topNodes}
              parentSelected={false}
              searchTerm={name}
            />
          </ContentTableColumn>
        ) : (
          <NoItemsGeneric
            headerText="No projects found"
            subText="Try searching for a project by name"
          />
        )}
      </ResultContainer>
    </StateContext.Provider>
  );
};
