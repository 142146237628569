import { useSearch } from "./useSearch";
import { organisationFinancialResourceState } from "components/Organisation/Library/state";
import { orgFinanicalManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";
import { CostConfiguration } from "services/costService";

const keys = ["name", "note"];

export function useSearchLibraryFinancials(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgFinanicalManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredFinancials, rawData } =
    useSearch<CostConfiguration>(
      enabled,
      searchTerm,
      keys,
      organisationFinancialResourceState({
        organisationId,
      }),
    );

  const filteredSearchItems: SearchResultItem<CostConfiguration>[] =
    useMemo(() => {
      return filteredFinancials.map((result) => ({
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: getIcon(LibraryManageRole.FINANCIAL),
        onSelect: () => {
          navigate(
            `/organisation/${organisationId}/library/financial/${result.item.id}`,
          );
        },
      }));
    }, [filteredFinancials, navigate, organisationId]);

  const searchableItems: SearchItem<CostConfiguration>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/financial/${item.id}`,
        );
      },
      icon: getIcon(LibraryManageRole.FINANCIAL),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredFinancials: filteredSearchItems,
    searchableItems,
  };
}
