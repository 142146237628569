import { useEffect, useState, useRef } from "react";

export function useVisibleObserver(firstVisibilityOnly: boolean) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = elementRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (firstVisibilityOnly && entries[0].isIntersecting) {
        setIsVisible(true);
        observer.disconnect();
      } else {
        setIsVisible(entries[0].isIntersecting);
      }
    });

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [firstVisibilityOnly]);

  return { isVisible, elementRef };
}
