import styled from "styled-components";
import { typography } from "../../../styles/typography";
import { colors } from "../../../styles/colors";
import { Column, Row } from "components/General/Layout";
import { borderRadiusMedium } from "styles/space";

export const TableHeader = styled.div`
  ${typography.sub1}
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
`;

export const ContentTableRow = styled(Row)<{ flexGrow?: boolean }>`
  padding: 0.7rem 1.5rem;
  align-items: center;
  min-height: 3.9rem;
  box-sizing: border-box;
  cursor: pointer;

  ${({ flexGrow }) =>
    flexGrow !== false &&
    `
     > * {
      flex: 1;
    }
    `}
`;

export const SubHeader = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
`;

export const SubTitle = styled.div`
  ${typography.sub1}
  color: ${colors.textPrimary};
`;

export const Container = styled(Column)<{ disabled?: boolean }>`
  position: relative;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  background: ${colors.surfaceSecondary};
  border-radius: ${borderRadiusMedium};
  padding: 0.8rem 0.8rem 0 0.8rem;
  button {
    opacity: 0;
  }

  p,
  .InnerContainer {
    opacity: ${(p) => (p.disabled ? "0.5" : "inherit")};
  }

  &:hover {
    background: ${colors.surfaceHover};
    button {
      opacity: 1;
    }
  }
  transition: all 0.1s ease-in-out;
`;
export const InnerContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: end;
  overflow: hidden;

  border-radius: 0.4rem;
  background: ${colors.blue700};
  width: 26.2rem;
  height: 15.9rem;
`;

export const Title = styled.p`
  ${typography.sub2}
  margin: 0;
  padding: 0.4rem 0.8rem 1.2rem 0.8rem;
`;

export const StyledLink = styled.a`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const TabContainer = styled(Column)`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const MainRow = styled(Row)<{ isOver?: boolean }>`
  height: 100%;
  overflow-y: auto;
  background-color: ${(p) => (p.isOver ? colors.surfaceHover : undefined)};
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  ${typography.body}
  overflow-x: hidden;
`;

export const HeaderRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.blue500};
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${colors.surfacePrimary};
  width: 100%;
  padding: 0;
`;

export const ResourceColumn = styled(Column)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 1.6rem;
`;

export const CaptionText = styled.p`
  ${typography.caption}
`;

export const ResourceItemTitle = styled.div<{
  isDraggingOver?: boolean;
  height?: string;
}>`
  display: flex;
  cursor: pointer;
  gap: 0.8rem;
  text-align: left;
  overflow: hidden;
  border-radius: ${borderRadiusMedium};
  flex: 4;
  margin: 0.2rem 1.5rem;
  height: ${(p) => p.height || "5rem"};
`;

export const RowContainer = styled.div<{
  isDragging: boolean;
  isSelected: boolean;
  isOver?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: ${borderRadiusMedium};
  background-color: ${(p) =>
    p.isOver
      ? colors.surfaceHover
      : p.isSelected
        ? colors.surfaceSelectedLight
        : undefined};
  &:hover {
    background-color: ${colors.surfaceHover};
  }
  opacity: ${(p) => (p.isDragging ? 0.5 : 1)};
  width: 100%;
  overflow: hidden;
  transition: background-color 0.2s ease;
`;

export const CommentAndDotMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DragIconWrapper = styled.div`
  opacity: 0;
  margin-right: 0.2rem;
  display: flex;
  align-items: center;
`;

export const RowWithIcon = styled.div`
  display: flex;
  &:hover {
    ${DragIconWrapper} {
      opacity: 1;
    }
  }
`;

export const NameWithPadding = styled.div<{ depth: number }>`
  display: flex;
  align-items: center;
  padding-left: ${(p) => `${p.depth * 2.6}rem`};
`;

export const ResourceNameText = styled.p<{
  selected?: boolean;
  collapsed?: boolean;
}>`
  ${typography.body}
  font-weight: ${(p) => (p.selected ? "600" : "400")};
  color: ${(p) => (p.selected ? colors.textSelected : colors.textPrimary)};
  display: block;
  max-width: ${(p) => (p.collapsed ? "23rem" : "100%")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
