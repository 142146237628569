import { ExportCableVoltageType } from "../services/cableTypeService";

export const HOURS_PER_DAY = 24;
export const HOURS_PER_YEAR = 8766;

export const exportCableErrorKilometerPerVoltage: Record<
  ExportCableVoltageType,
  number
> = {
  [ExportCableVoltageType.kV110]: 160,
  [ExportCableVoltageType.kV150]: 200,
  [ExportCableVoltageType.kV220]: 160,
  [ExportCableVoltageType.kV275]: 130,
  [ExportCableVoltageType.kV320]: Infinity,
  [ExportCableVoltageType.kV345]: 110,
  [ExportCableVoltageType.kV380]: 100,
  [ExportCableVoltageType.kV420]: 90,
  [ExportCableVoltageType.kV525]: Infinity,
};

export const exportCableWarningKilometerPerVoltage: Record<
  ExportCableVoltageType,
  number
> = {
  [ExportCableVoltageType.kV110]: 140,
  [ExportCableVoltageType.kV150]: 180,
  [ExportCableVoltageType.kV220]: 140,
  [ExportCableVoltageType.kV275]: 110,
  [ExportCableVoltageType.kV320]: Infinity,
  [ExportCableVoltageType.kV345]: 90,
  [ExportCableVoltageType.kV380]: 80,
  [ExportCableVoltageType.kV420]: 70,
  [ExportCableVoltageType.kV525]: Infinity,
};
