import { organisationIdAtom } from "state/pathParams";
import { useMemo } from "react";
import {
  ContentTableColumn,
  GaplessRow,
  GaplessRowPadding,
  TabSubtitle,
  TableHeader,
} from "../../../style";
import { Column, Row } from "components/General/Layout";
import { GroupedOrgResource, OrganisationResources } from "../../../types";
import useUserOrganisationResourcesCrud, {
  PERSONAL_GROUP,
  groupedUserOrgResourcesAccessSelectorFamily,
} from "../../hooks/useUserOrganisationResourcesCrud";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { Text } from "styles/typography";
import Tooltip from "components/General/Tooltip";
import { OrganisationUser } from "types/customer";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { useAtomValue } from "jotai";
import GroupTag from "components/Organisation/OrganisationRightSide/content/UserContent/GroupTag";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

export const resourcesToNameMap: Record<OrganisationResources, string> = {
  org_turbine_manage: "Library turbines",
  org_foundation_manage: "Library foundations",
  org_cable_manage: "Library cables",
  org_export_cable_manage: "Library export cables",
  org_analysis_manage: "Library analysis",
  org_financial_manage: "Library financial",
  org_data_package_manage: "Library GIS packages",
  org_substation_manage: "Library substations",
};

type SimpleGroupResource = {
  id: string;
  name: string;
  resources: OrganisationResources[];
};

function OrgResourceRow({
  groupedResources,
  userId,
  resourceName,
  organisationId,
}: {
  groupedResources: GroupedOrgResource;
  userId: string;
  resourceName: OrganisationResources;
  organisationId: string;
}) {
  const { add, remove } = useUserOrganisationResourcesCrud();

  const personalAccess = groupedResources[PERSONAL_GROUP]?.resources ?? [];

  const groupsWithResource = useMemo(
    () =>
      Object.keys(groupedResources)
        .filter((k) => groupedResources[k].resources.includes(resourceName))
        .map(
          (k) =>
            ({
              id: k,
              name: groupedResources[k].name,
              resources: groupedResources[k].resources,
            }) as SimpleGroupResource,
        ),
    [groupedResources, resourceName],
  );

  const disabled =
    groupsWithResource.filter((g) => g.id !== PERSONAL_GROUP).length !== 0;

  return (
    <GaplessRowPadding>
      <Text
        style={{
          flex: "3",
        }}
      >
        {resourcesToNameMap[resourceName]} access
      </Text>
      <Tooltip
        disabled={
          groupsWithResource.filter((g) => g.id !== PERSONAL_GROUP).length === 0
        }
        text={"Edit group to change access"}
        position={"top"}
      >
        <Toggle
          checked={groupsWithResource.length !== 0}
          disabled={disabled}
          onChange={() => {
            personalAccess.includes(resourceName)
              ? remove(organisationId, userId, resourceName)
              : add(organisationId, userId, resourceName);
          }}
          size={ToggleSize.SMALL}
        />
      </Tooltip>
      <Column
        style={{
          overflow: "hidden",
          flex: "2",
        }}
      >
        {groupsWithResource
          .filter((g) => g.id !== PERSONAL_GROUP)
          .map((group) => (
            <Row
              key={group.id}
              style={{
                flex: "2",
              }}
            >
              <GroupTag
                group={group}
                size="full"
                organisationId={organisationId}
              />
            </Row>
          ))}
      </Column>
    </GaplessRowPadding>
  );
}

const OrganisationResourceUserTable = ErrorBoundaryWrapper(
  ({ user }: { user: OrganisationUser }) => {
    const organisationId = useAtomValue(organisationIdAtom) ?? "";
    const userId = user.user_id;
    const groupedResources = useAtomValue(
      groupedUserOrgResourcesAccessSelectorFamily({
        organisationId,
        userId,
      }),
    );

    const allLibraryAccess = useAtomValue(
      libraryAllSelectorFamily({
        organisationId,
      }),
    );

    const organisationalResources = allLibraryAccess
      ? ([
          "org_turbine_manage",
          "org_foundation_manage",
          "org_cable_manage",
          "org_export_cable_manage",
          "org_analysis_manage",
          "org_financial_manage",
          "org_data_package_manage",
          "org_substation_manage",
        ] as OrganisationResources[])
      : ([
          "org_turbine_manage",
          "org_foundation_manage",
        ] as OrganisationResources[]);

    return (
      <Column
        style={{
          gap: "1.6rem",
        }}
      >
        <TabSubtitle>
          Below is an overview of the library resources that are available for{" "}
          {user.nickname}.
        </TabSubtitle>
        {user.org_role === "guest" && (
          <SimpleAlert type="warning">
            {user.nickname} is a guest in this organisation. Even with Library
            access they won't be able to access the Library.
          </SimpleAlert>
        )}
        <GaplessRow>
          <TableHeader
            style={{
              flex: "3",
            }}
          >
            Library resource
          </TableHeader>
          <TableHeader
            style={{
              flex: "2",
            }}
          >
            Access
          </TableHeader>
          <div
            style={{
              flex: "1",
            }}
          />
        </GaplessRow>
        <ContentTableColumn>
          {organisationalResources.map((resourceName) => {
            return (
              <OrgResourceRow
                key={resourceName}
                userId={userId}
                resourceName={resourceName}
                groupedResources={groupedResources}
                organisationId={organisationId}
              />
            );
          })}
        </ContentTableColumn>
      </Column>
    );
  },
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);

export default OrganisationResourceUserTable;
