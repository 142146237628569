import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { parkFamily } from "state/jotai/park";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { ValidationWarning } from "state/validationWarnings";

export const subAreaOutsideParkWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.SubAreasOutsidePark>
        | undefined
      >
    >(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;
      const subAreas = await get(subAreasInParkFamily({ parkId, branchId }));

      const outsideParkIds = subAreas
        .filter((area) => turf.intersect(park, area) == null)
        .map((area) => area.id);

      if (outsideParkIds.length === 0) return;
      return {
        type: ValidationWarningTypes.SubAreasOutsidePark,
        featureIds: outsideParkIds,
        parkId: parkId,
      };
    }),
);
