import { Position } from "@turf/turf";
import { fetchEnhancerWithToken } from "./utils";
import { mapboxAccessToken } from "components/MapNative/constants";
import { PBFToGeojsonFile } from "components/UploadModal/utils";
import { Feature, FeatureCollection, MultiPolygon } from "geojson";
import { z } from "zod";
import { scream } from "utils/sentry";

export async function getViewShedAnalysis(
  coords: Position[],
  epsg: number,
  zoom: number,
  observerHeight: number,
  bbox: [number, number, number, number],
) {
  const body = { coords, bbox };

  const headers = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/terrain-giver/viewshedv2/${zoom}/${epsg}?include_land=false&observerHeight=${observerHeight}`,
    headers,
  );
}

export async function getTerrainSlopeAnalysis(
  zoom: number,
  bbox: [number, number, number, number],
) {
  const body = { bbox };

  const headers = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await fetchEnhancerWithToken(
    `/api/terrain-giver/slope/${zoom}`,
    headers,
  );
  if (!res.ok) {
    throw scream("Unable to read terrain slope tiff", {
      res,
    });
  }

  const parser = z.object({
    url: z.string(),
    terrainUrl: z.string(),
  });
  const j = await res.json();
  const jsonRes = parser.parse(j);

  const viewShedRes = await fetch(jsonRes.url);
  const viewshedBlob = await viewShedRes.blob();

  const terrainRes = await fetch(jsonRes.terrainUrl);
  const terrainBlob = await terrainRes.blob();
  return [viewshedBlob, terrainBlob];
}

export const getOSMBuildingsFromTile = async (
  x: number,
  y: number,
  z: number,
): Promise<Feature<MultiPolygon>[]> => {
  const res = await fetch(
    `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/${z}/${x}/${y}.vector.pbf?access_token=${mapboxAccessToken}`,
    {
      method: "get",
    },
  );
  if (!res.ok)
    throw new Error("Error downloading building footprints from mapbox");
  const blob = await res.blob();
  const geojsonFile = await PBFToGeojsonFile(
    new File([blob], `${z}-${x}-${y}.pbf`),
    "building",
  );

  if (!geojsonFile) return [];

  var reader = new FileReader();
  reader.readAsText(geojsonFile);
  const text = await new Promise<string>(
    (res) =>
      (reader.onload = function () {
        res(reader.result as string);
      }),
  );
  return (JSON.parse(text) as FeatureCollection<MultiPolygon>).features.filter(
    (f) => f.geometry.type.includes("Polygon"),
  );
};
