import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import { Label } from "components/General/Form";
import { Row } from "components/General/Layout";
import { getBranchSelectorFamily, useUpdateBranch } from "state/timeline";
import { windConfigurationUsageRefresh } from "state/windSourceConfiguration";
import SelectWindDropDownCustom from "./SelectWindDropDown";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import { editorAccessProjectSelector } from "state/user";
import { DarkSubtitle } from "components/RightSide/InfoModal/style";
import { Suspense } from "react";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import WindIcon from "@icons/24/Wind.svg?react";

const Inner = ({
  projectId,
  branchId,
}: {
  projectId: string;
  branchId: string;
}) => {
  const liveBranchMeta = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );
  const selectedWindConfig = useAtomValue(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const projectEditorAccess = useAtomValue(editorAccessProjectSelector);
  const updateBranch = useUpdateBranch();
  const resetWindConfUsage = useJotaiCallback(
    async (_get, set, windConfigurationId: string) => {
      if (!projectId) return;
      set(
        windConfigurationUsageRefresh({
          nodeId: projectId,
          windConfigurationId,
        }),
        (curr) => curr + 1,
      );
    },
    [projectId],
  );

  return (
    <SelectWindDropDownCustom
      projectId={projectId}
      style={{
        flex: 1,
      }}
      backgroundColor={colors.surfacePrimary}
      disabled={!projectEditorAccess}
      onSelectItem={(item) => {
        if (!liveBranchMeta) return;
        liveBranchMeta.windConfigurationId &&
          resetWindConfUsage(liveBranchMeta.windConfigurationId);
        resetWindConfUsage(item);
        updateBranch({
          ...liveBranchMeta,
          windConfigurationId: item,
        });
      }}
      selectedConfig={selectedWindConfig}
    />
  );
};

export const SelectWindConfiguration = () => {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  if (!projectId || !branchId) return null;
  return (
    <Row
      style={{
        margin: 0,
        alignItems: "end",
      }}
    >
      <Label style={{ flex: 1, width: "100%", gap: "0.4rem" }}>
        <Row>
          <IconREMSize height={1.4} width={1.4} iconColor={colors.iconNegative}>
            <WindIcon />
          </IconREMSize>
          <DarkSubtitle>Wind</DarkSubtitle>
        </Row>
        <Suspense fallback={<SkeletonBlock style={{ minHeight: "2.6rem" }} />}>
          <Inner projectId={projectId} branchId={branchId} />
        </Suspense>
      </Label>
    </Row>
  );
};
