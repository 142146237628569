import { useEffect } from "react";
import {
  BufferGeometry,
  Fog,
  Group,
  Mesh,
  MeshStandardMaterial,
  Shader,
} from "three";
import SunCalc from "suncalc";
import {
  viewPositionAtom,
  viewDateAtom,
  viewParkFogStyleAtom,
  VISIBILITY_MODE,
} from "../../../../state/viewToPark";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import {
  getSkyGradientColor,
  injectCurvatureIntoVertexShader,
  injectFogOpacityAndCurvatureIntoShader,
} from "./utils";
import { FOG_FAR, FOG_NEAR } from "../constants";
import { useAtomValue } from "jotai";
import { Color } from "lib/colors";

export default function useUpdateFog(
  threeCore: ThreeCoreViewPark | undefined,
  terrainPatches: Mesh<BufferGeometry, MeshStandardMaterial>[],
  turbineMeshes: Group[],
) {
  const viewPosition = useAtomValue(viewPositionAtom);
  const date = useAtomValue(viewDateAtom);
  const viewParkFogStyle = useAtomValue(viewParkFogStyleAtom);

  useEffect(() => {
    if (!threeCore) return;

    if (viewParkFogStyle === VISIBILITY_MODE.FULL_VISIBILITY_MODE) {
      threeCore.scene.fog = null;
      threeCore.water.material.fog = false;
      threeCore.water.material.needsUpdate = true;
      return;
    }

    if (!viewPosition) return;
    const sunPosition = SunCalc.getPosition(
      date.toJSDate(),
      viewPosition.lat,
      viewPosition.lng,
    );
    const horizonColor = getSkyGradientColor(sunPosition.altitude);

    const skyColor = new Color(
      horizonColor.r,
      horizonColor.g,
      horizonColor.b,
      1,
    );

    threeCore.scene.fog = new Fog(skyColor.toHex(), FOG_NEAR, FOG_FAR);
    threeCore.water.material.fog = true;
    threeCore.water.material.needsUpdate = true;
  }, [viewPosition, date, threeCore, viewParkFogStyle]);

  useEffect(() => {
    if (viewParkFogStyle === VISIBILITY_MODE.REDUCED_VISIBILITY_MODE) {
      terrainPatches.forEach((patch) => {
        if (patch.material instanceof MeshStandardMaterial) {
          patch.material.onBeforeCompile = (shader) => {
            injectFogOpacityAndCurvatureIntoShader(shader);

            const fragmentShader =
              `
        varying vec3 v_pos;
        ` +
              shader.fragmentShader.split("}")[0] +
              `
      if(v_pos.z < 0.0) {
        gl_FragColor = vec4(1.0, 0.0, 0.0, 0.0);
      } else {
        gl_FragColor = gl_FragColor;
      }
    }
    `;

            shader.fragmentShader = fragmentShader;
          };
          patch.material.needsUpdate = true;
        }
      });

      turbineMeshes.forEach((mg) => {
        for (const m of [
          mg.getObjectByName("tower"),
          mg.getObjectByName("nacelle"),
          mg.getObjectByName("blade-0"),
          mg.getObjectByName("blade-1"),
          mg.getObjectByName("blade-2"),
        ]) {
          if (m instanceof Mesh) {
            m.material.onBeforeCompile = (shader: Shader) => {
              injectFogOpacityAndCurvatureIntoShader(shader);
            };
            m.material.needsUpdate = true;
          }
        }
      });
    }
    if (viewParkFogStyle === VISIBILITY_MODE.FULL_VISIBILITY_MODE) {
      terrainPatches.forEach((patch) => {
        if (patch.material instanceof MeshStandardMaterial) {
          patch.material.onBeforeCompile = (shader) => {
            shader.vertexShader = injectCurvatureIntoVertexShader(
              shader.vertexShader,
            );

            const fragmentShader =
              `
        varying vec3 v_pos;
        ` +
              shader.fragmentShader.split("}")[0] +
              `
      if(v_pos.z < 0.0) {
        gl_FragColor = vec4(1.0, 0.0, 0.0   , 0.0);
      } else {
        gl_FragColor = gl_FragColor;
      }
    }
    `;

            shader.fragmentShader = fragmentShader;
          };
          patch.material.needsUpdate = true;
        }
      });

      turbineMeshes.forEach((mg) => {
        for (const m of [
          mg.getObjectByName("tower"),
          mg.getObjectByName("nacelle"),
          mg.getObjectByName("blade-0"),
          mg.getObjectByName("blade-1"),
          mg.getObjectByName("blade-2"),
        ]) {
          if (m instanceof Mesh) {
            m.material.onBeforeCompile = (shader: Shader) => {
              shader.vertexShader = injectCurvatureIntoVertexShader(
                shader.vertexShader,
              );
            };
            m.material.needsUpdate = true;
          }
        }
      });
    }
  }, [terrainPatches, viewParkFogStyle, turbineMeshes]);
}
