import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";
import { ExistingTurbineFeature } from "types/feature";

export const existingTurbinesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<ExistingTurbineFeature[]>>(async (get) => {
      const features = await get(featuresFamily({ branchId }));
      return features.existingTurbine;
    }),
);
