import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { ABLY_RESOLVED_TOGGLE_FILTER } from "../../../state/ably";
import { resolveThread } from "../service";
import { threadsAtomFamily } from "../state";
import { useAblyPublish } from "../../../hooks/useAblyPublish";
import { aset } from "utils/jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

export const useResolveThreadCrud = () => {
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const ablyPublish = useAblyPublish(projectNodeId);

  const localPut = useAtomCallback(
    useCallback(
      async (
        get,
        set,
        branchId: string,
        threadId: string,
        resolved: boolean,
      ) => {
        if (!projectNodeId) return;
        await aset(
          get,
          set,
          threadsAtomFamily({
            nodeId: projectNodeId,
            branchId: branchId,
          }),
          (cur) => {
            return cur.map((c) =>
              c.threadId === threadId
                ? {
                    ...c,
                    resolved: resolved,
                  }
                : c,
            );
          },
        );
      },
      [projectNodeId],
    ),
  );

  const put = useAtomCallback(
    useCallback(
      async (_get, _set, threadId: string, resolved: boolean) => {
        if (!projectNodeId) return;
        await localPut(branchId, threadId, resolved);

        const res = await resolveThread(
          projectNodeId,
          branchId,
          threadId,
          resolved,
        );
        ablyPublish(`${projectNodeId}:all`, ABLY_RESOLVED_TOGGLE_FILTER, {
          nodeId: projectNodeId,
          branchId,
          threadId,
          resolved,
        });
        return res;
      },
      [ablyPublish, projectNodeId, branchId, localPut],
    ),
  );

  return {
    put,
    localPut,
  };
};
