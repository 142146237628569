import { useAtomValue } from "jotai";
import { SVGWrapper } from "@icons/svgUtils";
import { IconBtn } from "components/General/Icons";
import { Row } from "components/General/Layout";
import { NodeTurbineAccess } from "components/Organisation/Library/types";
import {
  ContentTableRow,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useState } from "react";
import { orgTurbineManageAccessSelector } from "state/user";
import useNodeTurbineResourcesCrud from "../../../useNodeTurbineResourcesCrud";
import { NodeLink } from "../../Collaborators";
import Earth from "@icons/14/Earth.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";

export function SingleTurbineResource({
  access,
  nodeId,
}: {
  access: NodeTurbineAccess;
  nodeId: string;
}) {
  const { remove } = useNodeTurbineResourcesCrud();

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const orgTurbineManageAccess = useAtomValue(orgTurbineManageAccessSelector);

  const accessFromThisNode = access.nodeId === nodeId;

  return (
    <ContentTableRow disabled={deleteInProgress}>
      <SVGWrapper size={1.4}>
        <Earth />
      </SVGWrapper>
      <TextEllipsis
        style={{
          margin: 0,
          marginRight: "auto",
        }}
      >
        {access.turbine.name}
      </TextEllipsis>

      {!accessFromThisNode && <NodeLink nodeId={access.nodeId} />}
      {orgTurbineManageAccess && accessFromThisNode && (
        <Row>
          <IconBtn
            disabled={deleteInProgress}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteInProgress(true);
              remove(nodeId, access.turbine.id).finally(() =>
                setDeleteInProgress(false),
              );
            }}
            size={"1.4rem"}
            style={{
              marginLeft: "auto",
            }}
          >
            <Trashcan />
          </IconBtn>
        </Row>
      )}
    </ContentTableRow>
  );
}
