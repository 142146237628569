// DnDRow.tsx
import React, { Fragment, useMemo, useRef } from "react";
import DnDElement from "./DnDElement";
import {
  DnDRow as DnDRowType,
  DnDElement as DnDElementType,
  IndicatorPosition,
} from "./types";
import { DropIndicator, Row, RowContent } from "./DnDStyles";
import { RowResizer } from "./RowResizer";
import { resizeInProgressState } from "./state";
import { useAtomValue } from "jotai";
import { isDefined } from "../../../utils/predicates";

type DnDRowProps = {
  row: DnDRowType;
  moveElement: (
    draggedId: string,
    targetElementId: string | null,
    after: boolean,
  ) => void;
  indicatorPosition: IndicatorPosition;
  updateIndicatorPosition: (elementId: string | null, after: boolean) => void;
  adjustRowHeight: (rowId: string, newHeight: number) => void;
  onCreateNewRow: (draggedId: string) => void;
  disabled?: boolean;
};

const DnDRow: React.FC<DnDRowProps> = ({
  row,
  moveElement,
  indicatorPosition,
  updateIndicatorPosition,
  adjustRowHeight,
  onCreateNewRow,
  disabled,
}) => {
  const rowRef = useRef(null);
  const resizeInProgress = useAtomValue(resizeInProgressState);

  const variableWidthShare = useMemo(() => {
    const allocated = row.elements
      .map((e) => e.widthShare ?? 0)
      .reduce((a, e) => a + e, 0);
    if (1.0 < allocated) return 1.0 / row.elements.length;

    const variableElements = row.elements.filter(
      (e) => !isDefined(e.widthShare),
    );

    if (variableElements.length === 0) {
      // If allocated is less than 1.0 and there are no variable elements, then
      // we end up with empty space on the side.  Not clear what's the best way
      // of handling this.  Return value doesn't really matter here, since it's
      // not used.
      return 0.0;
    }
    return (1.0 - allocated) / variableElements.length;
  }, [row.elements]);

  const updatedElements: DnDElementType[] = row.elements.map((element) => ({
    widthShare: variableWidthShare,
    ...element,
  }));

  const showDropIndicatorOnPosition = useMemo(() => {
    if (resizeInProgress || !indicatorPosition) return undefined;

    const indexOfElement = updatedElements.findIndex(
      (e) => e.id === indicatorPosition.elementId,
    );
    if (indexOfElement < 0) return undefined;

    return indicatorPosition.after ? indexOfElement + 1 : indexOfElement;
  }, [indicatorPosition, resizeInProgress, updatedElements]);

  return (
    <Row ref={rowRef} height={row.height ?? 200} data-row={1}>
      <RowContent>
        {updatedElements.map((element, i) => {
          return (
            <Fragment key={element.id}>
              <DnDElement
                showIndicator={
                  showDropIndicatorOnPosition != null &&
                  showDropIndicatorOnPosition === i
                }
                key={`${row.id}-${element.id}`}
                element={element}
                moveElement={(draggedId, targetId, after) =>
                  moveElement(draggedId, targetId, after)
                }
                updateIndicatorPosition={updateIndicatorPosition}
                disabled={disabled}
              />
            </Fragment>
          );
        })}
        <DropIndicator
          show={
            showDropIndicatorOnPosition === updatedElements.length && !disabled
          }
        />
      </RowContent>
      <RowResizer
        row={row}
        adjustRowHeight={adjustRowHeight}
        rowRef={rowRef}
        onDrop={onCreateNewRow}
        disabled={disabled}
      />
    </Row>
  );
};

export default DnDRow;
