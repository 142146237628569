import { InstallationVesselType } from "services/vesselService";
import { POSITION_ON_SITE_HOURS } from "./constants";
import {
  JacketActivitiesConfig,
  SubstationActivitiesConfig,
} from "services/operationsConfigurationService";

export const transitActivity = (transitTime: number) => {
  return {
    id: "Transit",
    duration: Math.ceil(transitTime),
    useWindLim: true,
    useWaveLim: true,
  };
};

export const positionOnSiteActivity = {
  id: "Position on site",
  duration: POSITION_ON_SITE_HOURS,
  useWindLim: false,
  useWaveLim: true,
};

export const jackUpDownActivity = (vessel: InstallationVesselType) => {
  if (vessel.floating) return;
  return {
    id: "Jack up/down",
    duration: Math.ceil(vessel.jackingTime),
    useWindLim: false,
    useWaveLim: true,
  };
};

export const loadJacketOnDeckActivity = (
  activities: JacketActivitiesConfig | SubstationActivitiesConfig,
) => ({
  id: "Load jacket on vessel",
  duration: activities.loadJacketOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

export const loadCableCarouselActivity = (
  cableLengthInRound: number,
  cableLoadingSpeed: number,
) => {
  return {
    id: "Load cable carousel",
    duration: Math.ceil(cableLengthInRound / cableLoadingSpeed),
    useWindLim: false,
    useWaveLim: false,
  };
};

export const layBuryCableActivity = (
  cableLength: number,
  cableLayBurySpeed: number,
  mileStone?: boolean,
) => {
  return {
    id: "Lay and bury cable",
    mileStone,
    duration: Math.ceil(cableLength / cableLayBurySpeed),
    useWindLim: true,
    useWaveLim: true,
  };
};
