import { atom } from "jotai";
import {
  ExclusionDomainUnpacked,
  ExclusionZoneFeature,
  exclusionDomainUnpack,
} from "types/feature";
import { atomFamily } from "utils/jotai";
import { featuresFamily } from "./features";
import { parkFamily } from "./park";
import { cableCorridorsInParkFamily } from "./cableCorridor";
import * as turf from "@turf/turf";

export const exclusionZonesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<ExclusionZoneFeature[]>>(async (get) => {
      const features = await get(featuresFamily({ branchId }));
      return features.exclusionZone;
    }),
);

const byDomain = (zones: ExclusionZoneFeature[]) => {
  const ret: Record<keyof ExclusionDomainUnpacked, ExclusionZoneFeature[]> = {
    turbine: [],
    cable: [],
    substation: [],
    anchor: [],
  };

  for (const z of zones) {
    const d = exclusionDomainUnpack(z.properties.domain);
    if (d.turbine) ret.turbine.push(z);
    if (d.cable) ret.cable.push(z);
    if (d.substation) ret.substation.push(z);
    if (d.anchor) ret.anchor.push(z);
  }

  return ret;
};

export const exclusionZonesByDomainFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom(async (get) => {
      const exclusionZones = await get(exclusionZonesFamily({ branchId }));
      return byDomain(exclusionZones);
    }),
);

/**
 * Exclusion zones that intersect the park boundary or a cable corridor in the
 * park. Since all park features should be within these two feature types,
 * these are the exclusion zones that are "relevant" for the park.
 *
 */
export const exclusionZonesForParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<ExclusionZoneFeature[]>>(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return [];
      const corridors = await get(
        cableCorridorsInParkFamily({ parkId, branchId }),
      );

      const zones = await get(exclusionZonesFamily({ branchId }));
      return zones.filter(
        (zone) =>
          turf.intersect(park, zone) ||
          corridors.some((c) => turf.intersect(zone, c)),
      );
    }),
);

/** See {@link exclusionZonesForParkFamily} and {@link exclusionZonesByDomainFamily}. */
export const exclusionZonesForParkByDomainFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const exclusionZonesForPark = await get(
        exclusionZonesForParkFamily({ parkId, branchId }),
      );
      return byDomain(exclusionZonesForPark);
    }),
);
