import { atom } from "jotai";
import { atom as atomJ } from "jotai";
import { jotaiSuspendThisAtom, suspendAtom } from "utils/jotai";

/**
 * This atom contains an object that will change every time we change any path params.
 * This will probably lead to re-renders even though the field you looked at didn't change.
 * Prefer any of the selectors `projectIdSelector` and so on.
 */
export const pathParamsSelector_DontUseThisDirectly = atom<{
  organisationId: string | undefined;
  nodeId: string | undefined;
  projectId: string | undefined;
  branchId: string | undefined;
  parkId: string | undefined;
}>((get) => {
  return {
    organisationId: get(organisationIdAtom),
    nodeId: get(nodeIdAtom),
    projectId: get(projectIdAtom),
    branchId: get(branchIdAtom),
    parkId: get(parkIdAtom),
  };
});

export const hasParkSelector = atom<boolean>((get) => Boolean(get(parkIdAtom)));
export const parkIdAtom = atomJ<string | undefined>(undefined);
/** @deprecated: Typing is wrong in `jotaiSuspendThisAtom`, so this doesn't do what's intended. */
export const parkIdAtomDef = atomJ<string>(
  (get) => get(parkIdAtom) ?? get(jotaiSuspendThisAtom),
);
export const parkIdAtomDef2 = atom<Promise<string>>(
  async (get) => get(parkIdAtom) ?? (await get(suspendAtom)),
);
export const branchIdAtom = atomJ<string | undefined>(undefined);
/** @deprecated: Typing is wrong in `jotaiSuspendThisAtom`, so this doesn't do what's intended. */
export const branchIdAtomDef = atomJ<string>(
  (get) => get(branchIdAtom) ?? get(jotaiSuspendThisAtom),
);
export const branchIdAtomDef2 = atom<Promise<string>>(
  async (get) => get(branchIdAtom) ?? (await get(suspendAtom)),
);

export const projectIdAtom = atomJ<string | undefined>(undefined);
/** @deprecated: Typing is wrong in `jotaiSuspendThisAtom`, so this doesn't do what's intended. */
export const projectIdAtomDef = atomJ<string>(
  (get) => get(projectIdAtom) ?? get(jotaiSuspendThisAtom),
);
export const projectIdAtomDef2 = atom<Promise<string>>(
  async (get) => get(projectIdAtom) ?? (await get(suspendAtom)),
);
export const organisationIdAtom = atomJ<string | undefined>(undefined);
export const nodeIdAtom = atomJ<string | undefined>(undefined);
