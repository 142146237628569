import { atom } from "jotai";
import { Feature } from "geojson";
import { LayerSpecification } from "mapbox-gl";

export type ExternalSelectionItem = Feature & {
  layer?: LayerSpecification;
  source?: string;
  clone: () => ExternalSelectionItem;
  toJSON: () => Feature;
  ignoreScrollToFeature?: boolean;
};
export const currentExternalLayerSelection = atom<ExternalSelectionItem[]>([]);
