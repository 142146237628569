import { useAtomValue } from "jotai";
import { orgSubstationManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibrarySubstation } from "components/Ably/ChannelProviders/Organisation/Library/Substation/useAblyLibrarySubstation";

interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function SubstationLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgSubstationManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_substation_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblySubstationChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblySubstationChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibrarySubstation(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_substation_manage"}
    />
  );
}
