import {
  CABLE_BUOY_SUBMERGED_WEIGHT,
  CABLE_SUBMERGED_WEIGHT,
  HANG_OFF_ANGLE_LAZY_WAVE,
  Z_HOG_DEPTH_FRACTION,
  Z_SAG_DEPTH_FRACTION,
} from "../state/cable";
import { MooringCoords } from "./mooring";

const lazyWave = ({
  waterDepth,
  hangOffAngle,
  zHog,
  zSag,
  w,
  wB,
}: {
  waterDepth: number;
  hangOffAngle: number;
  zHog: number;
  zSag: number;
  w: number;
  wB: number;
}): {
  x: number[];
  z: number[];
} => {
  const x: number[] = [];

  const z: number[] = [];

  const z5 = waterDepth - zSag;
  const H =
    ((w * z5) / Math.tan(hangOffAngle) ** 2) * (1 + 1 / Math.cos(hangOffAngle));
  const x5 = (H / w) * Math.acosh((w * z5) / H + 1);
  const s5 = (H / w) * Math.sinh((w * x5) / H);

  const z4 = (wB / (w + wB)) * (zHog - zSag);
  const x4 = (H / w) * Math.acosh((w * z4) / H + 1);
  const s4 = (H / w) * Math.sinh((w * x4) / H);

  const z3 = (w / (w + wB)) * (zHog - zSag);
  const x3 = (H / wB) * Math.acosh((wB * z3) / H + 1);
  const s3 = (H / wB) * Math.sinh((wB * x3) / H);

  const z2 = (w / (w + wB)) * zHog;
  const x2 = (H / wB) * Math.acosh((wB * z2) / H + 1);
  const s2 = (H / wB) * Math.sinh((wB * x2) / H);

  const z1 = (wB / (w + wB)) * zHog;
  const x1 = (H / w) * Math.acosh((w * z1) / H + 1);
  const s1 = (H / w) * Math.sinh((w * x1) / H);

  const x50 = x5;
  const z50 = -z5;
  for (let _s = s5; _s >= 0; _s += -5) {
    const xUnrot = (-H / w) * Math.asinh((w * _s) / H) + x50;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x50)) / H) - 1) + z50);
  }

  const x40 = x50;
  const z40 = z50;
  for (let _s = 0; _s <= s4; _s += 5) {
    const xUnrot = (H / w) * Math.asinh((w * _s) / H) + x40;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x40)) / H) - 1) + z40);
  }

  const x30 = (H / w) * Math.asinh((w * s4) / H) + x40 + x3;
  const z30 = (H / w) * (Math.cosh((w * x4) / H) - 1) + z40 + z3;
  for (let _s = s3; _s >= 0; _s += -5) {
    const xUnrot = -(H / wB) * Math.asinh((wB * _s) / H) + x30;
    x.push(xUnrot);
    z.push(-(H / wB) * (Math.cosh((wB * (xUnrot - x30)) / H) - 1) + z30);
  }

  const x20 = x30;
  const z20 = z30;
  for (let _s = 0; _s <= s2; _s += 5) {
    const xUnrot = (H / wB) * Math.asinh((wB * _s) / H) + x20;
    x.push(xUnrot);
    z.push((-H / wB) * (Math.cosh((wB * (xUnrot - x20)) / H) - 1) + z20);
  }

  const x10 =
    (H / wB) * Math.asinh((wB * s2) / H) +
    x20 +
    (H / w) * Math.asinh((w * s1) / H);
  const z10 =
    (-H / wB) * (Math.cosh((wB * x2) / H) - 1) +
    z20 -
    (H / w) * (Math.cosh((w * x1) / H) - 1);
  for (let _s = s1; _s >= 0; _s += -5) {
    const xUnrot = (-H / w) * Math.asinh((w * _s) / H) + x10;
    x.push(xUnrot);
    z.push((H / w) * (Math.cosh((w * (xUnrot - x10)) / H) - 1) + z10);
  }

  return { x, z };
};
const bottomFixed = ({
  waterDepth,
}: {
  waterDepth: number;
}): {
  x: number[];
  z: number[];
} => {
  const x = [0, 0];
  const z = [0, -waterDepth];

  return { x, z };
};

export const cableVisualization = ({
  bearing,
  waterDepth,
  distance,
  floaterFrom,
  floaterTo,
}: {
  bearing: number[];
  waterDepth: number[];
  distance: number[];
  floaterFrom: boolean;
  floaterTo: boolean;
}): MooringCoords => {
  const zSagFrom = waterDepth[0] * Z_SAG_DEPTH_FRACTION;
  const zHogFrom = waterDepth[0] * Z_HOG_DEPTH_FRACTION;
  const zSagTo = waterDepth[waterDepth.length - 1] * Z_SAG_DEPTH_FRACTION;
  const zHogTo = waterDepth[waterDepth.length - 1] * Z_HOG_DEPTH_FRACTION;

  const w = 40 * 9.81;
  const wB = w;

  const accDistance: number[] = [];
  let count = 0;
  for (let i = 0; i < distance.length; i++) {
    count += distance[i];
    accDistance.push(count);
  }

  let CoordsFrom: { x: number[]; z: number[] },
    CoordsTo: { x: number[]; z: number[] };

  if (floaterFrom) {
    CoordsFrom = lazyWave({
      waterDepth: waterDepth[0],
      hangOffAngle: HANG_OFF_ANGLE_LAZY_WAVE,
      zHog: zHogFrom,
      zSag: zSagFrom,
      w,
      wB,
    });
  } else {
    CoordsFrom = bottomFixed({
      waterDepth: waterDepth[0],
    });
  }
  if (floaterTo) {
    CoordsTo = lazyWave({
      waterDepth: waterDepth[waterDepth.length - 1],
      hangOffAngle: HANG_OFF_ANGLE_LAZY_WAVE,
      zHog: zHogTo,
      zSag: zSagTo,
      w,
      wB,
    });
  } else {
    CoordsTo = bottomFixed({
      waterDepth: waterDepth[waterDepth.length - 1],
    });
  }

  const xCable: number[] = [];
  const yCable: number[] = [];
  const zCable: number[] = [];

  const totalDistance = accDistance[accDistance.length - 1];

  let seg = 0;
  let x0 = 0;
  let y0 = 0;
  for (let i = 0; i < CoordsFrom.x.length; i++) {
    if (CoordsFrom.x[i] > accDistance[seg]) {
      x0 += distance[seg] * Math.sin((bearing[seg] * Math.PI) / 180);
      y0 += distance[seg] * Math.cos((bearing[seg] * Math.PI) / 180);
      seg += 1;
      continue;
    }
    xCable.push(
      x0 + CoordsFrom.x[i] * Math.sin((bearing[seg] * Math.PI) / 180),
    );
    yCable.push(
      y0 + CoordsFrom.x[i] * Math.cos((bearing[seg] * Math.PI) / 180),
    );
    zCable.push(CoordsFrom.z[i]);
  }

  for (let i = seg; i < accDistance.length; i++) {
    if (
      accDistance[i] > CoordsFrom.x[CoordsFrom.x.length - 1] &&
      accDistance[i] < totalDistance - CoordsTo.x[CoordsFrom.x.length - 1]
    ) {
      x0 += distance[i] * Math.sin((bearing[i] * Math.PI) / 180);
      y0 += distance[i] * Math.cos((bearing[i] * Math.PI) / 180);
      xCable.push(x0);
      yCable.push(y0);
      zCable.push(-waterDepth[i + 1]);
    }
  }

  seg = 0;
  x0 = 0;
  y0 = 0;
  for (let i = CoordsTo.x.length - 1; i >= 0; i--) {
    if (totalDistance - CoordsTo.x[i] > accDistance[seg]) {
      x0 += distance[seg] * Math.sin((bearing[seg] * Math.PI) / 180);
      y0 += distance[seg] * Math.cos((bearing[seg] * Math.PI) / 180);
      seg += 1;
      continue;
    }
    xCable.push(
      x0 +
        (distance[seg] - CoordsTo.x[i]) *
          Math.sin((bearing[seg] * Math.PI) / 180),
    );
    yCable.push(
      y0 +
        (distance[seg] - CoordsTo.x[i]) *
          Math.cos((bearing[seg] * Math.PI) / 180),
    );
    zCable.push(CoordsTo.z[i]);
  }

  return { x: xCable, y: yCable, z: zCable };
};

export const lazyWaveLengthCorrection = ({
  waterDepth,
}: {
  waterDepth: number;
}): number => {
  const hangOffAngle = HANG_OFF_ANGLE_LAZY_WAVE;
  const zSag = waterDepth * Z_SAG_DEPTH_FRACTION;
  const zHog = waterDepth * Z_HOG_DEPTH_FRACTION;
  const w = CABLE_SUBMERGED_WEIGHT;
  const wB = CABLE_BUOY_SUBMERGED_WEIGHT;

  const z5 = waterDepth - zSag;
  const H =
    ((w * z5) / Math.tan(hangOffAngle) ** 2) * (1 + 1 / Math.cos(hangOffAngle));
  const x5 = (H / w) * Math.acosh((w * z5) / H + 1);
  const s5 = (H / w) * Math.sinh((w * x5) / H);

  const z4 = (wB / (w + wB)) * (zHog - zSag);
  const x4 = (H / w) * Math.acosh((w * z4) / H + 1);
  const s4 = (H / w) * Math.sinh((w * x4) / H);

  const z3 = (w / (w + wB)) * (zHog - zSag);
  const x3 = (H / wB) * Math.acosh((wB * z3) / H + 1);
  const s3 = (H / wB) * Math.sinh((wB * x3) / H);

  const z2 = (w / (w + wB)) * zHog;
  const x2 = (H / wB) * Math.acosh((wB * z2) / H + 1);
  const s2 = (H / wB) * Math.sinh((wB * x2) / H);

  const z1 = (wB / (w + wB)) * zHog;
  const x1 = (H / w) * Math.acosh((w * z1) / H + 1);
  const s1 = (H / w) * Math.sinh((w * x1) / H);

  const lazyWaveLength = s1 + s2 + s3 + s4 + s5;
  const horLength = x1 + x2 + x3 + x4 + x5;
  const lengthCorr = lazyWaveLength - (horLength + waterDepth);

  return lengthCorr;
};
