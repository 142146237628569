import { useAtomValue, useSetAtom } from "jotai";
import {
  branchIdAtom,
  organisationIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "state/pathParams";
import { Suspense, useRef } from "react";
import styled from "styled-components";
import { getBranchSelectorFamily } from "../../../../state/timeline";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { spaceMedium } from "styles/space";
import { Inner } from "./Inner";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { Column } from "components/General/Layout";
import {
  RightModalNames,
  rightModalOpenStateAtom,
} from "components/Design/state";

const VersionHistoryContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 6;
  right: ${spaceMedium};
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  height: calc(
    100vh - calc(
        var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
          var(--side-bars-width)
      ) - 2.4rem
  );
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    height: calc(
      100vh - calc(
          var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
            var(--side-bars-width)
        ) - 6.8rem
    );
  }
`;

const ArchiveProjectModalOuterInner = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const branch = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const branchName = branch?.title ?? "Banch";

  const setrightModalOpenStateAtom = useSetAtom(rightModalOpenStateAtom);

  return (
    <VersionHistoryContainer>
      <MenuFrame
        ref={frameRef}
        title="Saved project data"
        subtitle={branchName}
        onExit={() => setrightModalOpenStateAtom(undefined)}
        style={{
          flex: 1,
          overflow: "hidden",
          width: "30rem",
        }}
      >
        <Suspense
          fallback={
            <Column
              style={{
                gap: "1.2rem",
              }}
            >
              <SkeletonBlock
                style={{
                  height: "8rem",
                }}
              />
              <SkeletonBlock
                style={{
                  height: "8rem",
                }}
              />
            </Column>
          }
        >
          <Inner
            organisationId={organisationId}
            projectId={projectId}
            branchId={branchId}
            parkId={parkId}
          />
        </Suspense>
      </MenuFrame>
    </VersionHistoryContainer>
  );
};

export default function ArchiveProjectModal() {
  const rightModalOpenState = useAtomValue(rightModalOpenStateAtom);
  if (rightModalOpenState !== RightModalNames.ArchiveProject) {
    return null;
  }

  return <ArchiveProjectModalOuterInner />;
}
