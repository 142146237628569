import styled from "styled-components";
import { StandardBox } from "../../../styles/boxes/Boxes";

export const StyledModalBase = styled(StandardBox)`
  padding: 1.2rem;
  min-width: 27rem;
  font-size: 1.3rem;
  line-height: 1;
  color: #6b6b76;
  outline: none;
  border-radius: 6px;
  box-sizing: content-box;
  display: flex;
  position: relative;
  height: fit-content;
`;

export const ModalHeader = styled.h2`
  margin: 0;
  color: white;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin: 0 0 1rem 0;
`;
