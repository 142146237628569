import { useMemo } from "react";
import useAnalysisConfiguration from "./useAnalysisConfiguration";
import useCostConfiguration from "./useCostConfiguration";
import useOperationsConfiguration from "./useOperationsConfiguration";
import useWindConfiguration from "./useWindConfiguration";
import { isOnshoreAtom } from "state/onshore";
import { useAtomValue } from "jotai";

export default function useProjectConfiguration() {
  const analysisConfiguration = useAnalysisConfiguration();
  const windConfigurations = useWindConfiguration();
  const isOnshore = useAtomValue(isOnshoreAtom);

  const costConfiguration = useCostConfiguration();

  const operationsConfiguration = useOperationsConfiguration();

  const projectConfiguration = useMemo(() => {
    const configurationTypes = [
      analysisConfiguration,
      costConfiguration,
      windConfigurations,
      ...(isOnshore ? [] : [operationsConfiguration]),
    ];

    return {
      title: "Configuration",
      items: [
        {
          items: configurationTypes,
        },
      ],
    };
  }, [
    analysisConfiguration,
    windConfigurations,
    costConfiguration,
    operationsConfiguration,
    isOnshore,
  ]);

  return projectConfiguration;
}
