import { useSearch } from "./useSearch";
import { organisationLibraryDataPackageResourceState } from "components/Organisation/Library/state";
import { orgDataPackagesManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";

const keys = ["name", "note"];

export function useSearchLibraryDataPackage(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgDataPackagesManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredDataPackages, rawData } =
    useSearch<DataLibraryPackage>(
      enabled,
      searchTerm,
      keys,
      organisationLibraryDataPackageResourceState({
        organisationId,
      }),
    );

  const filteredSearchItems: SearchResultItem<DataLibraryPackage>[] =
    useMemo(() => {
      return filteredDataPackages.map((result) => ({
        id: result.item.id,
        name: result.item.name,
        score: result.score,
        item: result.item,
        icon: getIcon(LibraryManageRole.DATA_PACKAGE),
        onSelect: () => {
          navigate(
            `/organisation/${organisationId}/library/datalayers/${result.item.id}`,
          );
        },
      }));
    }, [filteredDataPackages, navigate, organisationId]);

  const searchableItems: SearchItem<DataLibraryPackage>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/datalayers/${item.id}`,
        );
      },
      icon: getIcon(LibraryManageRole.DATA_PACKAGE),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredDataPackages: filteredSearchItems,
    searchableItems,
  };
}
