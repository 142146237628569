import ProductionIcon from "@icons/24/SideBarIcons/Production.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import WindIcon from "@icons/24/SideBarIcons/Wind.svg";
import ElectricalIcon from "@icons/24/SideBarIcons/Electrical.svg";
import FinancialIcon from "@icons/24/Financial.svg";
import FoundationIcon from "@icons/24/Foundation.svg";
import QuestionIcon from "@icons/24/Question.svg";

import { ComparisonAttributeKey } from "./types";
import { ReactNode } from "react";

export const attributeKeyToIcon: Record<ComparisonAttributeKey, ReactNode> = {
  [ComparisonAttributeKey.PARK]: <ParkIcon />,
  [ComparisonAttributeKey.PRODUCTION]: <ProductionIcon />,
  [ComparisonAttributeKey.WIND]: <WindIcon />,
  [ComparisonAttributeKey.CABLING]: <ElectricalIcon />,
  [ComparisonAttributeKey.FINANCIAL]: <FinancialIcon />,
  [ComparisonAttributeKey.FOUNDATIONS]: <FoundationIcon />,
  [ComparisonAttributeKey.FINANCIAL_INPUTS]: <QuestionIcon />,
};
