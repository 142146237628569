import { atomFamily, atomFromFn } from "utils/jotai";
import { Tag, getTags } from "services/tagsService";

export const tagsFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<Tag[]>>(async () => {
      const response = await getTags(organisationId);
      return response.tags;
    }),
);
