import { useAtomValue } from "jotai";
import { SVGWrapper } from "@icons/svgUtils";
import { IconBtn } from "components/General/Icons";
import { NodeAnalysisAccess } from "components/Organisation/Library/types";
import {
  ContentTableRow,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { useState } from "react";
import { orgAnalysisManageAccessSelector } from "state/user";
import useNodeAnalysisResourcesCrud from "../../../useNodeAnalysisResourcesCrud";
import { NodeLink } from "../../Collaborators";
import Earth from "@icons/14/Earth.svg?react";
import Trashcan from "@icons/24/Bin.svg?react";

export function SingleAnalysisResource({
  nodeId,
  access,
}: {
  nodeId: string;
  access: NodeAnalysisAccess;
}) {
  const { remove } = useNodeAnalysisResourcesCrud();

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const orgAnalysisManageAccess = useAtomValue(orgAnalysisManageAccessSelector);
  const accessFromThisNode = access.nodeId === nodeId;

  return (
    <ContentTableRow disabled={deleteInProgress}>
      <SVGWrapper size={1.4}>
        <Earth />
      </SVGWrapper>
      <TextEllipsis
        style={{
          margin: 0,
          marginRight: "auto",
        }}
      >
        {access.config.name}
      </TextEllipsis>

      {!accessFromThisNode && <NodeLink nodeId={access.nodeId} />}
      {orgAnalysisManageAccess && accessFromThisNode && (
        <IconBtn
          disabled={deleteInProgress}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteInProgress(true);
            remove(nodeId, access.config.id).finally(() =>
              setDeleteInProgress(false),
            );
          }}
          size={"1.4rem"}
          style={{
            marginLeft: "auto",
          }}
        >
          <Trashcan />
        </IconBtn>
      )}
    </ContentTableRow>
  );
}
