import { Label as _Label } from "components/General/Form";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
  gap: 1.6rem;
  width: 100rem;
`;

export const DividerWrapper = styled.div`
  padding: 0 0 2rem 0;
`;

export const InstallationDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault}};
`;
