import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { unwrap } from "jotai/utils";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import {
  adminAccessProjectSelector,
  memberInOrganisationSelectorFamily,
} from "state/user";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  getNodeSelectorFamily,
  topLevelNodeFromAllNodesInStateAtomFamily as rootLevelNodeFromBackendAtomFamily,
} from "components/Projects/useOrganisationFolderCrud";
import SearchWrapper from "components/Search/SearchWrapper";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import Spinner from "@icons/spinner/Spinner";
import Share from "@icons/24/Share.svg";
import { spaceLarge, spaceMedium, spaceTiny } from "styles/space";
import { Anchor } from "components/General/Anchor";
import { nodeGroupUserAccessSelector } from "components/Organisation/Groups/state";
import {
  isGroupNodeAccessWithMeta,
  isUserNodeAccessWithMeta,
} from "components/Organisation/Groups/types";
import { useToast } from "hooks/useToast";
import { useClickOutside } from "hooks/useClickOutside";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { Frame } from "components/General/Layout";
import UserGroupAccessModal from "components/Organisation/OrganisationRightSide/content/UserGroupAccessModal";
import { colors } from "styles/colors";
import InviteToOrganisationFrame from "components/Organisation/Invites/InviteToOrganisation/InviteToOrganisationFrame";
import { trackProjectControlMenu } from "./MenuTracking";
import {
  inviteToOrganisationDefaultEmailValue,
  inviteToOrganisationDefaultSelectedProjectIds,
  inviteToOrganisationOpenAtom,
} from "components/Organisation/state";

const InviteFrame = ({
  buttonRef,
  setOpen,
  onShowInviteToOrganisationClick,
}: {
  buttonRef: React.RefObject<HTMLDivElement>;
  setOpen: (b: boolean) => void;
  onShowInviteToOrganisationClick?(defaultValue: string): void;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const groupsAndUsersWithAccess = useAtomValue(
    unwrap(
      nodeGroupUserAccessSelector({
        organisationId,
        nodeId: projectId,
      }),
    ),
  );
  const node = useAtomValue(
    getNodeSelectorFamily({
      nodeId: projectId,
      organisationId,
    }),
  );
  const usersWithAccess = useMemo(
    () => groupsAndUsersWithAccess?.filter(isUserNodeAccessWithMeta) ?? [],
    [groupsAndUsersWithAccess],
  );
  const groupsWithAccess = useMemo(
    () => groupsAndUsersWithAccess?.filter(isGroupNodeAccessWithMeta) ?? [],
    [groupsAndUsersWithAccess],
  );

  const frameRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const { error: showError } = useToast();

  useClickOutside(
    frameRef,
    () => {
      setOpen(false);
    },
    (target) => {
      return target === buttonRef.current;
    },
  );

  const { addOrUpdateMultiple: addOrUpdateGroups } = useGroupNodeAccessCrud();
  const { addOrUpdateMultiple: addOrUpdateUsers } = useUserNodeAccessCrud();

  const invite = useCallback(
    async (
      access: {
        users: string[];
        groups: string[];
      },
      accessRole: "admin" | "viewer" | "editor",
    ) => {
      setLoading(true);
      try {
        await Promise.all([
          addOrUpdateGroups(access.groups, projectId, accessRole),
          addOrUpdateUsers(access.users, projectId, accessRole),
        ]);
        setOpen(false);
      } catch {
        showError(
          "Something went wrong when inviting users, please try again.",
          {
            timeout: 5000,
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [addOrUpdateGroups, projectId, addOrUpdateUsers, setOpen, showError],
  );

  return (
    <Frame
      style={{
        paddingTop: spaceMedium,
        marginTop: spaceLarge,
        width: "auto",
      }}
      ref={frameRef}
    >
      <UserGroupAccessModal
        resourceName={node?.name}
        style={{
          boxShadow: "none",
          width: "50rem",
          maxWidth: "unset",
        }}
        onShowInviteToOrganisationClick={onShowInviteToOrganisationClick}
        existingUsers={usersWithAccess}
        existingGroups={groupsWithAccess}
        onCancel={() => setOpen(false)}
        onSave={invite}
        isSaving={loading}
      />
    </Frame>
  );
};

const ShareProjectButtonV2 = () => {
  const adminAccessToProject = useAtomValue(adminAccessProjectSelector);
  const organisationId = useAtomValue(organisationIdAtom);

  const setDefaultInviteToOrgEmailValue = useSetAtom(
    inviteToOrganisationDefaultEmailValue,
  );
  const setDefaultInviteToOrgProjectIds = useSetAtom(
    inviteToOrganisationDefaultSelectedProjectIds,
  );
  const [showInviteToOrganisation, setShowInviteToOrganisation] = useAtom(
    inviteToOrganisationOpenAtom,
  );
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    if (!open) trackProjectControlMenu("invite-button", {});
    setOpen(!open);
  }, [open]);

  const isOrgMember = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const projectId = useAtomValue(projectIdAtom);
  const toplevelNode = useAtomValue(
    rootLevelNodeFromBackendAtomFamily({
      organisationId,
      nodeId: projectId,
    }),
  );
  const chosenProjectHasPersonalTopFolder =
    toplevelNode?.type === "personal_folder";

  const disable = !adminAccessToProject || chosenProjectHasPersonalTopFolder;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <SearchWrapper
        title="Share project"
        id="search-share"
        icon={<Share />}
        tags={["share", "invite", "users", "members", "access"]}
        disabled={disable}
        onSelect={() => setOpen(true)}
      >
        <Tooltip
          theme="light"
          position="bottom"
          text={
            chosenProjectHasPersonalTopFolder
              ? "Projects in personal folders cannot be shared"
              : "Only project admins can invite"
          }
          disabled={!disable}
        >
          <React.Suspense
            fallback={
              <Button
                buttonType="primary-dark"
                text="Share"
                icon={<Spinner size="0.8rem" color={colors.textDisabled} />}
                disabled={true}
                style={{
                  marginLeft: spaceTiny,
                }}
              />
            }
          >
            <Button
              buttonType="primary-dark"
              text="Share"
              disabled={disable}
              onClick={onClick}
              style={{
                marginLeft: spaceTiny,
              }}
            />
            {open && (
              <Anchor
                baseRef={ref}
                basePlace="bottomRight"
                floatPlace="topRight"
              >
                <InviteFrame
                  buttonRef={ref}
                  setOpen={setOpen}
                  onShowInviteToOrganisationClick={
                    isOrgMember
                      ? (defaultValue) => {
                          setDefaultInviteToOrgEmailValue(defaultValue);
                          if (projectId) {
                            setDefaultInviteToOrgProjectIds([projectId]);
                          }
                          setShowInviteToOrganisation(true);
                          setOpen(false);
                        }
                      : undefined
                  }
                />
              </Anchor>
            )}
            {showInviteToOrganisation && (
              <Anchor
                baseRef={ref}
                basePlace="bottomRight"
                floatPlace="topRight"
                offset={[0, "1rem"]}
              >
                <InviteToOrganisationFrame
                  fullscreen={false}
                  onClose={() => {
                    setShowInviteToOrganisation(false);
                  }}
                />
              </Anchor>
            )}
          </React.Suspense>
        </Tooltip>
      </SearchWrapper>
    </div>
  );
};

export default ShareProjectButtonV2;
