import { atom } from "jotai";
import { mooringLinesInParkFamily } from "./mooringLine";
import { atomFamily } from "utils/jotai";
import { isMooringLineMultiple } from "utils/predicates";

type BuoysAndClumpWeights = {
  buoys: number[];
  clumpWeights: number[];
};

export const buoysAndClumpWeightsInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<BuoysAndClumpWeights>>(async (get) => {
      const mooringLines = await get(
        mooringLinesInParkFamily({ parkId, branchId }),
      );

      const buoys: number[] = [];
      const clumpWeights: number[] = [];

      mooringLines.filter(isMooringLineMultiple).forEach((line) => {
        line.properties["attachments"].forEach((attachment) => {
          if (attachment > 0) {
            clumpWeights.push(attachment);
          } else if (attachment < 0) {
            buoys.push(-attachment);
          }
        });
      });

      return {
        buoys,
        clumpWeights,
      };
    }),
);
