import React, { useEffect, useRef, useState } from "react";
import { scream } from "utils/sentry";

/**
 * Make a component movable by dragging on mover with the mouse
 *
 * Tiny example:
 * <MakeMovable mover={moveControl.current} container={mapNativeElement}>
 *   <div ref={moveControl}>Move me!<div>
 * </MakeMovable>
 *
 * @param children
 * @param mover The element that will be used to move the child
 * @param container An optional element that we want to restrict the movement inside
 * @param onMove Callback when the child is moved
 * @param onMoveStart Callback when the child starts moving
 * @param onMoveEnd Callback when the child stops moving
 */
const MakeMovable = ({
  children,
  mover,
  container,
  onMove,
  onMoveStart,
  onMoveEnd,
}: {
  children: React.ReactNode;
  mover?: HTMLElement | null;
  container?: Element | null;
  onMove?(left: number, top: number): void;
  onMoveStart?(clientX: number, clientY: number): void;
  onMoveEnd?(clientX: number, clientY: number): void;
}) => {
  const childRef = useRef<HTMLElement | null>(null);
  const [pos, setPos] = useState<{ left: number; top: number } | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!childRef.current || !mover) {
      return;
    }

    const handleMouseDown = (e: MouseEvent) => {
      if (!childRef.current) {
        return;
      }
      const oldUserSelect = mover.style.userSelect;
      mover.style.userSelect = "none";

      const childRect = childRef.current.getBoundingClientRect();
      const offsetX = e.clientX - childRect.left;
      const offsetY = e.clientY - childRect.top;
      onMoveStart?.(e.clientX, e.clientY);

      const containerRect = container?.getBoundingClientRect() || {
        left: 0,
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
      };

      const handleMouseMove = (e: MouseEvent) => {
        const newLeft = Math.max(
          containerRect.left,
          Math.min(e.clientX - offsetX, containerRect.right - childRect.width),
        );

        const newTop = Math.max(
          containerRect.top,
          Math.min(
            e.clientY - offsetY,
            containerRect.bottom - childRect.height,
          ),
        );

        onMove?.(newLeft, newTop);

        setPos({
          left: newLeft,
          top: newTop,
        });
      };

      const handleMouseUp = (e: MouseEvent) => {
        onMoveEnd?.(e.clientX, e.clientY);
        mover.style.userSelect = oldUserSelect;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    mover.addEventListener("mousedown", handleMouseDown);
    return () => {
      mover.removeEventListener("mousedown", handleMouseDown);
    };
  }, [mover, onMoveEnd, onMove, onMoveStart, container]);

  const didMove = pos !== undefined;
  // Make sure the child doesnt go out of bounds when the container resizes
  useEffect(() => {
    if (!didMove) {
      return;
    }

    const adjustPositionToFitInContainer: ResizeObserverCallback = ([
      entry,
    ]) => {
      console.log(entry);
      if (!childRef.current) return;

      const childRect = childRef.current.getBoundingClientRect();
      const containerRect = entry.target.getBoundingClientRect();

      let newLeft = childRect.left;
      // Check right edge overflow
      if (childRect.right > containerRect.right) {
        newLeft = containerRect.right - childRect.width;
      }

      let newTop = childRect.top;
      // Check bottom edge overflow
      if (childRect.bottom > containerRect.bottom) {
        newTop = containerRect.bottom - childRect.height;
      }

      setPos({
        left: newLeft,
        top: newTop,
      });
    };

    const resizeObserver = new ResizeObserver(adjustPositionToFitInContainer);

    const containingElement = container ?? document.documentElement;
    resizeObserver.observe(containingElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [container, didMove]);

  if (React.Children.count(children) !== 1) {
    scream(
      new Error("MakeMovable expects exactly one child", {
        cause: "TooManyChildren",
      }),
      {
        childCount: React.Children.count(children),
      },
    );
    return null;
  }

  return React.Children.map(children, (child) => {
    if (!React.isValidElement<any>(child)) {
      return child;
    }
    return React.cloneElement(child, {
      ref: childRef,
      style: {
        ...child.props.style,
        ...(pos && {
          position: "fixed",
          left: pos.left,
          top: pos.top,
        }),
      },
    });
  });
};

export default MakeMovable;
