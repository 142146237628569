import React, { Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { Divider } from "../../styles/misc/Misc";
import { modalTypeOpenAtom } from "../../state/modal";
import WindowsIcon from "@icons/24/Windows.svg?react";
import { SkeletonBlock } from "../Loading/Skeleton";
import ParkComparisonView from "./ParkComparisonView/ParkComparisonView";
import { v4 as uuid } from "uuid";
import {
  getSelectedParksForComparisonSelector,
  selectedParksAtom,
} from "./state";
import {
  ContentWrapper,
  Header,
  HeaderLeftSide,
  HeaderRightSide,
  Wrapper,
} from "./CompareParksModal.style";
import { CompareParksModalType } from "./CompareParksModalType";
import { ARTICLE_COMPARE, HelpLink } from "../HelpTooltip/HelpTooltip";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../state/pathParams";
import { IconBtn } from "components/General/Icons";
import Windowed from "components/WindowedHOC/WindowedHOC";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import { colors } from "styles/colors";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import Tooltip from "components/General/Tooltip";
import Spinner from "@icons/spinner/Spinner";
import CompareDownloadButton from "./CompareDownloadButton";
import ConfigurationBar from "./ConfigurationBar/ConfigurationBar";
import CopyLinkButton from "./CopyLinkButton";
import { useAtomValue, useSetAtom } from "jotai";

export const PARK_IDS_SEARCH_PARAM = "selectedParks";

export const _ParkUrlMetadata = z.object({
  p: z.string(),
  b: z.string(),
  ac: z.string().optional(),
  wc: z.string().optional(),
  cc: z.string().optional(),
  oc: z.string().optional(),
});

const CompareParksModalContent = ({ nodeId }: { nodeId: string }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBranch] = useState<string>(branchId);
  const setSelectedParkIds = useSetAtom(selectedParksAtom({ projectId }));
  const selectedParks = useAtomValue(
    getSelectedParksForComparisonSelector({ nodeId }),
  );

  useEffect(() => {
    // Use parkId/branchId from url
    const parkIdsInSearchParams = searchParams.get(PARK_IDS_SEARCH_PARAM);
    if (parkIdsInSearchParams) {
      try {
        const parkIds = _ParkUrlMetadata
          .array()
          .parse(JSON.parse(parkIdsInSearchParams));
        setSelectedParkIds(
          parkIds.map(({ p, b, ac, wc, cc, oc }) => ({
            parkId: p,
            branchId: b,
            comparisonId: uuid(),
            selectedAnalysisConfigurationId: ac,
            selectedWindConfigurationId: wc,
            selectedCostConfigurationId: cc,
            selectedOperationsConfigurationId: oc,
          })),
        );
        searchParams.delete(PARK_IDS_SEARCH_PARAM);
        setSearchParams(searchParams);
        return;
      } catch (err) {
        //
      }
    }
    if (selectedParks.length !== 0 || !parkId) {
      return;
    }

    setSelectedParkIds([
      { parkId, branchId: selectedBranch, comparisonId: uuid() },
    ]);
    // We do not want to add all dependencies since it would run the effect when not wanted
    // We only want to run this effect on first render, to set a park if no park has been selected for comparison before
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedParkIds]);

  return (
    <ContentWrapper>
      <ConfigurationBar />
      <ParkComparisonView selectedParks={selectedParks} />
    </ContentWrapper>
  );
};

const WindowedCompareParksModal = Windowed(
  // WrappedComponent
  ({ projectId }: { projectId: string }) => {
    return (
      <Suspense
        fallback={
          <SkeletonBlock
            style={{
              margin: "3rem",
              boxSizing: "border-box",
              width: "calc(100% - 6rem)",
            }}
          />
        }
      >
        <CompareParksModalContent nodeId={projectId} />
      </Suspense>
    );
  },
  // WrapperWhenNotWindowed
  ({ closeModal, setOpenInNewWindow, children, projectId }) => {
    return (
      <TopBarModal>
        <TopBarModalHeader
          title={
            <HeaderLeftSide>
              <h3>Compare</h3>
              <HelpLink
                article={ARTICLE_COMPARE}
                iconStroke={colors.white}
                hoverBackgroundColor="transparent"
              />
            </HeaderLeftSide>
          }
          onClose={closeModal}
          rightSide={
            <HeaderRightSide>
              <React.Suspense
                fallback={<Spinner color={colors.white} size="1rem" />}
              >
                <CompareDownloadButton projectId={projectId} />
              </React.Suspense>
              <Tooltip text="Open in new window" position="bottom">
                <IconBtn
                  size="1.5rem"
                  onClick={setOpenInNewWindow}
                  iconColor={colors.white}
                  hoverBackgroundColor="transparent"
                >
                  <WindowsIcon />
                </IconBtn>
              </Tooltip>
              <div
                style={{
                  width: "1px",
                  height: "2rem",
                  backgroundColor: colors.borderDefault,
                }}
              />
              <Tooltip text="Copy link to comparison">
                <CopyLinkButton projectId={projectId} />
              </Tooltip>
            </HeaderRightSide>
          }
        />
        <Wrapper>{children}</Wrapper>
      </TopBarModal>
    );
  },
  // WrapperWhenNewWindow
  ({ children }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: colors.background,
        }}
      >
        <Header>
          <HeaderLeftSide>
            <h3>Compare</h3>
            <HelpLink article={ARTICLE_COMPARE} />
          </HeaderLeftSide>
        </Header>
        <Divider style={{ margin: 0 }} />
        {children}
      </div>
    );
  },
  CompareParksModalType,
  {
    width: 1400,
    height: 810,
    title: "Compare",
  },
);

const CompareParksModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const openInNewWindow = useAtomValue(openInNewWindowAtom);
  const projectId = useAtomValue(projectIdAtom);

  if (
    !projectId ||
    (modalTypeOpen?.modalType !== CompareParksModalType &&
      !openInNewWindow[CompareParksModalType])
  ) {
    return null;
  }

  return <WindowedCompareParksModal projectId={projectId} />;
};

export default CompareParksModal;
