import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { organisationExportCableResourceState } from "../state";
import {
  HeaderRow,
  MainRow,
  SubHeader,
  TabContainer,
  TableHeader,
} from "../style";
import AddIcon from "@icons/24/Add.svg";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Row } from "components/General/Layout";
import OpenMoreInfo from "components/General/MoreInfoButton";
import { ExportcableManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { SearchInput } from "components/General/Input";
import { VerticalDivider } from "components/General/VerticalDivider";
import { Field, LibraryManageRole, Order, SortState } from "../types";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { useAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { _CableType, CableType } from "services/cableTypeService";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import { getIsLegalMove } from "business/folderStructure/utils";
import { FolderItem, FolderTreeItem } from "types/folderStructures";
import DirectionDown from "@icons/24/DirectionDown.svg";
import DirectionUp from "@icons/24/DirectionUp.svg";
import { IconREMSize } from "styles/typography";
import { RecursiveFolder } from "../shared/RecursiveFolder";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import {
  EXPORT_CABLE_TABLE_LIST_ITEM,
  EXPORT_CABLE_TABLE_FOLDER_ITEM,
} from "./constants";
import { ExportCableWizard } from "components/SettingsV2/FeatureSettings/ExportCableWizard";
import ResourceItem from "../shared/ResourceItem/ResourceItem";
import { ResourceCrudOperations } from "../shared/types";
import { useLibrarySearchAndSort } from "../shared/hooks/useLibrarySearchAndSort";
import { Anchor } from "components/General/Anchor";
import Tooltip from "components/General/Tooltip";
import { useLibraryDragAndDrop } from "../shared/hooks/useLibraryDragAndDrop";
import { useLibraryFolderStructure } from "../shared/hooks/useLibraryFolderStructure";
import useOrgExportCableCrud from "./useOrgExportCableCrud";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { orgExportcableManageAccessSelector } from "state/user";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { TablePlaceholder } from "components/Organisation/OrganisationRightSide/content/shared/TablePlaceholder";
import { selectedLibraryTabAtom } from "state/library";
import { useSyncSelectedItemWithResource } from "../hook";

type ExportCableOrFolderItem = CableType | FolderItem;

export default function ExportCableTab() {
  const hasOrgExportCableAccess = useAtomValue(
    orgExportcableManageAccessSelector,
  );
  const organisationId = useAtomValue(organisationIdAtom);
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );
  const setSelectedLibraryTab = useSetAtom(selectedLibraryTabAtom);
  useEffect(() => {
    setSelectedLibraryTab("exportcable");
    return () => {
      setSelectedLibraryTab(undefined);
    };
  }, [setSelectedLibraryTab]);
  if (!hasOrgExportCableAccess || !allLibraryAccess)
    return <div>No access</div>;

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <ExportCableTabInner />;
    </Suspense>
  );
}

function ExportCableTabInner() {
  useSyncSelectedItemWithResource();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const exportCables = useAtomValue(
    organisationExportCableResourceState({
      organisationId,
    }),
  );
  const [showNewExportCableModal, setShowNewExportCableModal] = useState(false);
  const params = useParams();
  const selectedItemId = params.selectedItemId;
  const navigate = useNavigate();
  const [createExportCableInFolderId, setCreateExportCableInFolderId] =
    useState<string | undefined>();
  const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
    string | undefined
  >();

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );
  const { showConfirm } = useConfirm();
  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    order: "ASC",
  });

  const toggleSort = useCallback(
    (field: Field) => {
      let newOrder: Order = "ASC";
      if (sortState.field === field && sortState.order === "ASC") {
        newOrder = "DESC";
      } else if (sortState.field === field && sortState.order === "DESC") {
        newOrder = "ASC";
      }
      setSortState({
        field,
        order: newOrder,
      });
    },
    [sortState.field, sortState.order],
  );

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<
    string | undefined
  >();
  const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);

  const isLoading = useMemo(
    () => saveInProgress || !!deleteInProgress,
    [saveInProgress, deleteInProgress],
  );

  const {
    name,
    onNameChange,
    sortedResources: sortedExportCables,
  } = useLibrarySearchAndSort(exportCables, sortState);

  const { folders, orphans } = useLibraryFolderStructure({
    organisationId,
    resources: sortedExportCables,
    sortState,
    libraryManageRole: "org_export_cable_manage",
    searchTerm: name,
  });

  const { moveItems, createFolder } = useOrgFolderStructureCrud({
    libraryManageRole: "org_export_cable_manage",
  });

  const { create, remove, duplicate, update } = useOrgExportCableCrud();

  const crudOperations: ResourceCrudOperations<LibraryManageRole.EXPORT_CABLE> =
    useMemo(
      () => ({
        remove: async (id: string) => {
          await remove(id);
          return;
        },
        duplicate: ({ resource }) =>
          duplicate({
            cable: resource,
            onSuccess: (newCable) => {
              navigate(
                `/organisation/${organisationId}/library/exportcable/${newCable.id}`,
              );
            },
          }),
      }),
      [remove, navigate, duplicate, organisationId],
    );

  const getDescription = (resource: CableType) => resource.note;
  const updateDescription = async (
    resource: CableType,
    newDescription: string,
  ): Promise<CableType> => {
    await update({
      ...resource,
      note: newDescription,
    });
    return resource;
  };

  const collapsed = !!content;

  const ref = useRef<HTMLDivElement>(null);

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  const onClickOnResource = useCallback(
    async (id: string) => {
      if (
        !unsavedSettings ||
        (await showConfirm({
          title: "Unsaved changes",
          message:
            "You have unsaved changes. Continuing will discard any changes.",
        }))
      ) {
        setUnsavedSettings(false);
        navigate(`/organisation/${organisationId}/library/exportcable/${id}`);
      } else {
        navigate(`/organisation/${organisationId}/library/exportcable/${id}`);
      }
    },
    [
      navigate,
      showConfirm,
      unsavedSettings,
      setUnsavedSettings,
      organisationId,
    ],
  );

  const onCreateExportCableInFolder = useCallback(
    (folderId: string) => {
      setCreateExportCableInFolderId(folderId);
      setShowNewExportCableModal(true);
    },
    [setCreateExportCableInFolderId, setShowNewExportCableModal],
  );

  const onCreateFolderInFolder = useCallback(
    (folderId: string) => {
      setCreateFolderInFolderId(folderId);
      setShowCreateNewFolder(true);
    },
    [setCreateFolderInFolderId, setShowCreateNewFolder],
  );

  const {
    onDropOnFolder,
    onFolderDropOnFolder,
    dropRef,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(
    [EXPORT_CABLE_TABLE_LIST_ITEM, EXPORT_CABLE_TABLE_FOLDER_ITEM],
    folders,
    "org_export_cable_manage",
  );

  const isLegalFolderMove = useCallback(
    (folder: FolderTreeItem, targetFolder: FolderTreeItem) => {
      return getIsLegalMove(folder, targetFolder, folders);
    },
    [folders],
  );

  const [openDescriptionId, setOpenDescriptionId] = useState<
    string | undefined
  >(undefined);

  const getExportCableVoltage = (resource: CableType) => {
    return `${resource.voltage} kV`;
  };

  return (
    <>
      {showCreateNewFolder && (
        <NewItemModal
          title="New folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onSubmit={async (name) => {
            await createFolder(
              name,
              LibraryManageRole.EXPORT_CABLE,
              [],
              createFolderInFolderId,
            );
          }}
          onClose={() => {
            setShowCreateNewFolder(false);
            setCreateFolderInFolderId(undefined);
          }}
        />
      )}
      <TabContainer>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <h2
            style={{
              margin: 0,
            }}
          >
            Export cables
          </h2>

          <Row>
            <Tooltip text={"Create new folder"}>
              <Button
                buttonType="secondary"
                onClick={() => {
                  setShowCreateNewFolder(true);
                }}
                icon={<FolderAddIcon />}
              />
            </Tooltip>
            <div ref={ref}>
              <Button
                text={collapsed ? "" : "New library export cable"}
                icon={collapsed && <AddIcon />}
                disabled={isLoading}
                onClick={() => setShowNewExportCableModal(true)}
              />
              {showNewExportCableModal && (
                <Anchor
                  baseRef={ref}
                  basePlace={"bottomRight"}
                  floatPlace={"topRight"}
                >
                  <ExportCableWizard
                    close={() => setShowNewExportCableModal(false)}
                    create={async (exportCable, name) =>
                      create({
                        cable: exportCable,
                        name,
                      }).then(async (res) => {
                        setShowNewExportCableModal(false);
                        navigate(
                          `/organisation/${organisationId}/library/exportcable/${res.id}`,
                        );
                        if (createExportCableInFolderId) {
                          await moveItems(
                            [
                              {
                                type: "RESOURCE",
                                id: res.id,
                                parentId: createExportCableInFolderId,
                              },
                            ],
                            createExportCableInFolderId,
                          );
                          setCreateExportCableInFolderId(undefined);
                        }
                      })
                    }
                    setLoading={setSaveInProgress}
                  />
                </Anchor>
              )}
            </div>
            <VerticalDivider height={3.2} />
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === selectedItemId) return undefined;
                  if (!selectedItemId) {
                    return {
                      type: "no-item-selected",
                    };
                  }
                  return {
                    type: "resource",
                    id: selectedItemId,
                  };
                });
              }}
            />
          </Row>
        </Row>
        <Row alignCenter>
          <SubHeader>Access: </SubHeader> <ExportcableManagersPreview />
        </Row>
        <Row
          style={{
            padding: "1.2rem 0",
          }}
        >
          <SearchInput
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            placeholder={`Search export cables`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </Row>
        <MainRow ref={dropRef}>
          <HeaderRow>
            <TableHeader
              onClick={() => toggleSort("name")}
              style={{
                cursor: "pointer",
                flex: 4,
              }}
            >
              Name
              <IconREMSize
                width={1.2}
                height={1.2}
                style={{ marginLeft: "0.4rem" }}
              >
                {sortState.field === "name" && sortState.order === "ASC" ? (
                  <DirectionDown />
                ) : sortState.field === "name" && sortState.order === "DESC" ? (
                  <DirectionUp />
                ) : (
                  <DirectionDown />
                )}
              </IconREMSize>
            </TableHeader>
            {!collapsed && (
              <>
                <TableHeader style={{ flex: 1 }}>Last edited</TableHeader>
                <TableHeader style={{ flex: 1 }}>Used in</TableHeader>
              </>
            )}
            <TableHeader style={{ flex: 1 }}></TableHeader>
          </HeaderRow>

          {folders.map((folder) => (
            <RecursiveFolder
              key={folder.id}
              folder={folder}
              depth={0}
              onDropOnFolder={onDropOnFolder}
              onFolderDropOnFolder={onFolderDropOnFolder}
              isLegalFolderMove={isLegalFolderMove}
              onCreateFolderInFolder={onCreateFolderInFolder}
              collapsed={collapsed}
              selectedItemId={selectedItemId}
              onClickResource={onClickOnResource}
              organisationId={organisationId}
              isLoading={isLoading}
              deleteInProgress={deleteInProgress}
              setSelectedItemId={(id) => {
                navigate(
                  `/organisation/${organisationId}/library/exportcable/${id}`,
                );
              }}
              setContent={setContent}
              setSaveInProgress={setSaveInProgress}
              setDeleteInProgress={setDeleteInProgress}
              sortedResources={sortedExportCables}
              libraryManageRole={LibraryManageRole.EXPORT_CABLE}
              onCreateResourceInFolder={onCreateExportCableInFolder}
              onFolderDropOnResource={onFolderDropOnResource}
              openDescriptionId={openDescriptionId}
              setOpenDescriptionId={setOpenDescriptionId}
              searchTerm={name}
              remove={crudOperations.remove}
              duplicate={crudOperations.duplicate}
              getDescription={getDescription}
              updateDescription={updateDescription}
              nameExtraInfo={getExportCableVoltage}
            />
          ))}

          {orphans.map((item: ExportCableOrFolderItem) => {
            const exportCable =
              "name" in item
                ? item
                : sortedExportCables.find((c) => c.id === item.id);
            if (!exportCable || !("name" in exportCable)) return null;

            const cable = _CableType.parse(exportCable);

            return (
              <ResourceItem<LibraryManageRole.EXPORT_CABLE>
                key={item.id}
                resource={cable}
                depth={0}
                collapsed={collapsed}
                isSelected={selectedItemId === item.id}
                onClickResource={onClickOnResource}
                isLoading={isLoading}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                openDescriptionId={openDescriptionId}
                setOpenDescriptionId={setOpenDescriptionId}
                libraryManageRole={LibraryManageRole.EXPORT_CABLE}
                remove={crudOperations.remove}
                duplicate={crudOperations.duplicate}
                getDescription={getDescription}
                updateDescription={updateDescription}
                folders={folders}
                searchTerm={name}
                nameExtraInfo={getExportCableVoltage(cable)}
              />
            );
          })}
        </MainRow>
      </TabContainer>
    </>
  );
}
