import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { Mixpanel } from "mixpanel";
import Button from "components/General/Button";
import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import { getShareUrl } from "hooks/useOpenModal";
import { CompareParksModalType } from "./CompareParksModalType";
import { _ParkUrlMetadata, PARK_IDS_SEARCH_PARAM } from "./CompareParksModal";
import { selectedParksAtom } from "./state";
import { useAtomValue } from "jotai";
import { useToast } from "hooks/useToast";

const HyperlinkIconStyled = styled(HyperlinkIcon)`
  path {
    stroke: ${colors.white} !important;
  }
`;

const CopyLinkButton = ({ projectId }: { projectId: string }) => {
  const selectedParkIds = useAtomValue(selectedParksAtom({ projectId }));
  const { info } = useToast();

  return (
    <Button
      icon={<HyperlinkIconStyled />}
      buttonType="text"
      onClick={() => {
        Mixpanel.track_old("Copy Compare parks link", {});
        const selectedParksParam = _ParkUrlMetadata.array().parse(
          selectedParkIds.map((row) => ({
            p: row.parkId,
            b: row.branchId,
            ac: row.selectedAnalysisConfigurationId,
            wc: row.selectedWindConfigurationId,
            cc: row.selectedCostConfigurationId,
            oc: row.selectedOperationsConfigurationId,
          })),
        );
        const url = getShareUrl(
          { modalType: CompareParksModalType },
          {
            [PARK_IDS_SEARCH_PARAM]: JSON.stringify(selectedParksParam),
          },
        );
        navigator.clipboard.writeText(url);
        info("Copied", { timeout: 2000 });
      }}
    />
  );
};

export default CopyLinkButton;
