import React from "react";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";
import { downloadAppFile, downloadText } from "utils/utils";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { TextNoMargin, DropdownText } from "../../shared";
import geoJsonExampleFile from "../example-files/example-feature.json";
//@ts-ignore
import tifExampleFile from "../example-files/example-bathymetry.tif";
//@ts-ignore
import zipExampleFile from "../example-files/example-feature.zip";
import { isOnshoreAtom } from "state/onshore";
import { useAtomValue } from "jotai";

const NoFileSelectedWrapper = () => {
  const isOnshore = useAtomValue(isOnshoreAtom);
  return (
    <>
      <TextNoMargin>
        Supported formats: .shp, .kml, .kmz, .geojson, .json, .tif, .tiff, .zip,
        .jpg, .jpeg, .png
      </TextNoMargin>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextNoMargin>Max file size:</TextNoMargin>
        <TextNoMargin style={{ marginLeft: spaceMedium }}>
          Elements: 10 MB
        </TextNoMargin>
        {!isOnshore && (
          <TextNoMargin style={{ marginLeft: spaceMedium }}>
            Bathymetry: 60 MB
            <span
              style={{ marginLeft: spaceMedium, color: colors.secondaryText }}
            >
              | Files exceeding the limit will be compressed to 60 MB
            </span>
          </TextNoMargin>
        )}
        <TextNoMargin style={{ marginLeft: spaceMedium }}>
          Image: 30 MB
        </TextNoMargin>
      </div>
      <TextNoMargin>
        Coordinates closer than .00001 degrees in WGS84 are simplified and max 8
        decimals stored
      </TextNoMargin>
      <div style={{ display: "flex" }}>
        <DropdownButton
          items={[
            {
              name: "Feature (.geojson)",
              value: "geojson",
            },
            {
              name: "Feature (.zip shape files)",
              value: "zip",
            },
            {
              name: "Bathymetry (.tif)",
              value: "tiff",
            },
          ]}
          onSelectItem={(val) => {
            switch (val) {
              case "geojson":
                downloadText(
                  JSON.stringify(geoJsonExampleFile, null, 2),
                  "example-file.geojson",
                );
                break;
              case "zip":
                downloadAppFile(zipExampleFile, "example-file.zip");
                break;
              case "tiff":
                downloadAppFile(tifExampleFile, "example-file.tif");
                break;
            }
          }}
          buttonText="Download an example file"
          position="top"
          renderText={(text) => {
            return <DropdownText>{text}</DropdownText>;
          }}
          chevronStyle={{
            width: "0.8rem",
            height: "0.8rem",
          }}
        />
      </div>
    </>
  );
};

export default NoFileSelectedWrapper;
