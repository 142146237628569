import { Suspense, useMemo } from "react";
import groupBy from "../../../utils/groupBy";
import minMax from "../../../utils/minMax";
import { valueRounding } from "../../RightSide/InfoModal/FoundationModal/utils";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { dedup, undefMap } from "../../../utils/utils";
import { ReactNode } from "react";
import { isDefined } from "../../../utils/predicates";
import { SkeletonBlock } from "../../Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Table from "components/Dashboard/Table";
import { atom, useAtomValue } from "jotai";
import { turbinesInParkFamily } from "state/jotai/turbine";
import {
  foundationFixedTypesAtom,
  foundationFloaterTypesAtom,
  getAllScaledStatisticsForFloatingFoundationsInParkFamily,
  getDetailedJacketMassesFamily,
  getDetailedMonopileMassesFamily,
  getSimpleFixedMassesFamily,
  turbinesInParkGroupFoundationTypeFamily,
} from "state/jotai/foundation";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { atomFamily } from "utils/jotai";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { TurbineFeature } from "types/feature";

const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const floatingStats = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const data = await get(
        getAllScaledStatisticsForFloatingFoundationsInParkFamily(parkId),
      );
      const floaterTypes = await get(foundationFloaterTypesAtom);
      const turbineTypes = await get(simpleTurbineTypesAtom);

      return data.map((d) => {
        const currentFoundation = floaterTypes.find(
          (f) => f.id === d.foundationId,
        );
        const currentTurbineType = turbineTypes.get(d.turbineTypeId);
        const numberOfTurbines = turbines.filter(
          (t) =>
            t.properties.turbineTypeId === d.turbineTypeId &&
            t.properties.foundationId === d.foundationId,
        ).length;

        return {
          ...d,
          turbineTypeName: currentTurbineType?.name,
          foundationName: currentFoundation?.name,
          material: currentFoundation?.material,
          numberOfTurbines: numberOfTurbines,
          primaryWeight:
            d.scaledConcreteWeight && d.scaledConcreteWeight > 0
              ? undefMap(
                  d.scaledConcreteWeight,
                  (w) => `${w.toLocaleString()} t`,
                )
              : undefMap(
                  d.scaledPrimarySteelWeight,
                  (w) => `${w.toLocaleString()} t`,
                ),
          displacement: undefMap(
            d.scaledDisplacementVolume,
            (d) => `${d.toLocaleString()} m³`,
          ),
          draft: undefMap(d.scaledDraft, (d) => `${d} m`),
        };
      });
    }),
);

const FloatingFoundationStatistics = () => {
  const { park, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const formattedData = useAtomValue(floatingStats({ parkId, branchId }));

  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>Foundation</th>
          <th>Amount</th>
          <th>Material</th>
          <th>Primary weight</th>
          <th>Displacement</th>
          <th>Draft</th>
        </tr>
      </thead>
      <tbody>
        {formattedData.map((d) => (
          <tr key={d.turbineTypeId + d.foundationId}>
            <td>{undefToSkeleton(d.turbineTypeName)}</td>
            <td>{undefToSkeleton(d.foundationName)}</td>
            <td>{undefToSkeleton(d.numberOfTurbines)}</td>
            <td>{undefToSkeleton(d.material)}</td>
            <td>{undefToSkeleton(d.primaryWeight)}</td>
            <td>{undefToSkeleton(d.displacement)}</td>
            <td>{undefToSkeleton(d.draft)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const useSimpleFixedStatistics = (
  turbinesWithSimpleFixed: TurbineFeature[],
  rasterId: string,
): {
  turbineTypeId: string;
  foundationId: string;
  numberOfTurbines: number;
  weight: string;
}[] => {
  const { foundationMasses } = useAtomValue(
    getSimpleFixedMassesFamily({
      turbinesWithSimpleFixed,
      rasterId,
    }),
  );

  const simpleFixedDesignsByIds = groupBy(
    foundationMasses,
    ({ turbineTypeId, foundationId }) => `${turbineTypeId},${foundationId}`,
  );

  const turbineSimpleFixedCombinations = dedup(
    turbinesWithSimpleFixed?.map(
      (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
    ) ?? [],
  );

  return turbineSimpleFixedCombinations.map((combination) => {
    const [t_id, f_id] = combination.split("-");
    const masses = simpleFixedDesignsByIds[`${t_id},${f_id}`];
    const numberOfTurbines = masses.length;

    const massStats = minMax(masses, (d) => d.mass);
    const massMin = massStats && valueRounding(massStats.min / 1000, 10);
    const massMax = massStats && valueRounding(massStats.max / 1000, 10);

    const weight =
      numberOfTurbines !== 1 && massStats
        ? `${massMin?.toLocaleString()} - ${massMax?.toLocaleString()}`
        : `${massMax}`;
    return {
      turbineTypeId: t_id,
      foundationId: f_id,
      numberOfTurbines: numberOfTurbines,
      weight,
    };
  });
};

const FixedFoundationStatistics = () => {
  const { park, branch, parkBathymetryId } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const turbinesByFoundation = useAtomValue(
    turbinesInParkGroupFoundationTypeFamily({
      parkId,
      branchId,
    }),
  );

  const { monopileDetails } = useAtomValue(
    getDetailedMonopileMassesFamily({
      turbinesWithDetailedMonopile: turbinesByFoundation.detailed_monopile.map(
        ({ turbine }) => turbine,
      ),
      rasterId: parkBathymetryId,
    }),
  );

  const { jacketDetails } = useAtomValue(
    getDetailedJacketMassesFamily({
      turbinesWithDetailedJacket: turbinesByFoundation.detailed_jacket.map(
        ({ turbine }) => turbine,
      ),
      rasterId: parkBathymetryId,
    }),
  );

  const detailedMonopileStatistics = useMemo(() => {
    const monopileDesignsByTurbineTypeId = groupBy(
      monopileDetails,
      ({ turbineTypeId }) => turbineTypeId,
    );

    const turbineMonopileCombinations = dedup(
      turbinesByFoundation.detailed_monopile.map(
        ({ turbine: t }) =>
          `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
      ),
    );

    return turbineMonopileCombinations.map((combination) => {
      const [t_id, f_id] = combination.split("-");

      const monopileDesign = monopileDesignsByTurbineTypeId[t_id];

      const numberOfTurbines = monopileDesign.length;
      const pileMassStats = minMax(monopileDesign, (d) => d.pileMass);
      const primarySteelMin =
        pileMassStats && valueRounding(pileMassStats.min / 1000, 10);
      const primarySteelMax =
        pileMassStats && valueRounding(pileMassStats.max / 1000, 10);

      const weight =
        numberOfTurbines !== 1 && pileMassStats
          ? `${primarySteelMin?.toLocaleString()} - ${primarySteelMax?.toLocaleString()}`
          : `${primarySteelMax}`;

      return {
        turbineTypeId: t_id,
        foundationId: f_id,
        numberOfTurbines: numberOfTurbines,
        weight,
      };
    });
  }, [monopileDetails, turbinesByFoundation.detailed_monopile]);

  const detailedJacketStatistics = useMemo(() => {
    const jacketDesignsByTurbineTypeId = groupBy(
      jacketDetails,
      ({ turbineTypeId }) => turbineTypeId,
    );

    const turbineJacketCombinations = dedup(
      turbinesByFoundation.detailed_jacket.map(
        ({ turbine: t }) =>
          `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
      ),
    );

    return turbineJacketCombinations.map((combination) => {
      const [t_id, f_id] = combination.split("-");

      const jacketDesign = jacketDesignsByTurbineTypeId[t_id];

      const numberOfTurbines = jacketDesign.length;
      const totalMassStats = minMax(jacketDesign, (d) => d.totalMass);
      const primarySteelMin =
        totalMassStats && valueRounding(totalMassStats.min / 1000, 10);
      const primarySteelMax =
        totalMassStats && valueRounding(totalMassStats.max / 1000, 10);

      const weight =
        numberOfTurbines !== 1 && totalMassStats
          ? `${primarySteelMin?.toLocaleString()} - ${primarySteelMax?.toLocaleString()}`
          : `${primarySteelMax}`;

      return {
        turbineTypeId: t_id,
        foundationId: f_id,
        numberOfTurbines: numberOfTurbines,
        weight,
      };
    });
  }, [jacketDetails, turbinesByFoundation.detailed_jacket]);

  const allFixed = useAtomValue(foundationFixedTypesAtom);
  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const turbinesWithSimpleMonopile = turbinesByFoundation.monopile.map(
    ({ turbine }) => turbine,
  );
  const turbinesWithSimpleJacket = turbinesByFoundation.jacket.map(
    ({ turbine }) => turbine,
  );
  const turbinesWithSimpleFixed = [
    ...turbinesWithSimpleMonopile,
    ...turbinesWithSimpleJacket,
  ];
  const simpleFixedStatistics = useSimpleFixedStatistics(
    turbinesWithSimpleFixed,
    parkBathymetryId,
  );

  const allFixedFoundationStatistics = detailedMonopileStatistics.concat(
    detailedJacketStatistics,
    simpleFixedStatistics,
  );

  const allFixedFoundationStatisticsFormatted =
    allFixedFoundationStatistics.map((ffs) => {
      const currentFoundation = allFixed.find((f) => f.id === ffs.foundationId);
      const currentTurbineType = allTurbineTypes.get(ffs.turbineTypeId);
      return {
        ...ffs,
        turbineTypeName: currentTurbineType?.name,
        foundationName: currentFoundation?.name,
      };
    });

  return (
    <Table>
      <thead>
        <tr>
          <th>Turbine</th>
          <th>Foundation</th>
          <th>Amount</th>
          <th>Weight(s)</th>
        </tr>
      </thead>
      <tbody>
        {parkBathymetryId &&
          allFixedFoundationStatisticsFormatted.map((d) => (
            <tr key={d.turbineTypeId + d.foundationId}>
              <td>{d.turbineTypeName}</td>
              <td>{d.foundationName}</td>
              <td>{d.numberOfTurbines}</td>
              <td>{d.weight} t</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const FoundationKeyStatisticsDetails = () => {
  const { park, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;

  const turbinesByFoundation = useAtomValue(
    turbinesInParkGroupFoundationTypeFamily({
      parkId,
      branchId,
    }),
  );

  const invalids = useAtomValue(invalidTypesInParkFamily({ parkId, branchId }));
  if (invalids.turbines?.featureIds.length)
    <CenterContainer style={{ margin: "3rem" }}>
      <SimpleAlert
        text={"Some turbines in the park have invalid turbine types."}
      />
    </CenterContainer>;
  if (invalids.foundations?.featureIds.length)
    <CenterContainer style={{ margin: "3rem" }}>
      <SimpleAlert
        text={"Some turbines in the park have invalid foundation types."}
      />
    </CenterContainer>;

  const includesFloaters =
    0 <
    turbinesByFoundation.semi_central.length +
      turbinesByFoundation.semi_peripheral.length +
      turbinesByFoundation.spar.length;
  const includesFixed =
    0 <
    turbinesByFoundation.jacket.length +
      turbinesByFoundation.monopile.length +
      turbinesByFoundation.detailed_monopile.length +
      turbinesByFoundation.detailed_jacket.length;
  if (!includesFixed && !includesFloaters) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No foundations in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  return (
    <>
      {includesFloaters && <FloatingFoundationStatistics />}
      {includesFixed && (
        <Suspense fallback={<LoadingState />}>
          <FixedFoundationStatistics />
        </Suspense>
      )}
    </>
  );
};

export const FoundationKeyStatisticsWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Foundation key statistics"
      id="Foundation key statistics"
      resetKeys={errorBoundaryResetKeys}
    >
      <FoundationKeyStatisticsDetails />
    </SafeCard>
  );
};
