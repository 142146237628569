import { useAtomValue } from "jotai";
import React from "react";
import styled from "styled-components";
import { createImageFromInitials } from "../../../utils/image";
import { isInChecklyMode } from "../../../utils/utils";
import { Organisation } from "../service";
import { orgImageCounterState } from "./state";

const StyledProfileImage = styled.img<{
  onClick?: () => void;
  size: number;
}>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;
  object-fit: cover;
  cursor: ${(p) => (p.onClick ? "pointer" : "inherit")};
`;

const BASE_URL =
  "https://vind-ai-organisation-image.s3.eu-west-1.amazonaws.com";
const MAIN_IMAGE_NAME = "main-image";

type OrganisationThumbnailSize = 32 | 64 | 128 | 256;

const OrganisationImageRound = ({
  organisation,
  onClick,
  size = 3.2,
  style,
  title,
  thumbnailSize,
}: {
  organisation: Organisation;
  onClick?: () => void;
  size?: number;
  style?: React.CSSProperties;
  title?: HTMLImageElement["title"];
  thumbnailSize?: OrganisationThumbnailSize;
}) => {
  const count = useAtomValue(orgImageCounterState);

  const imageName = thumbnailSize ? `${thumbnailSize}.jpeg` : MAIN_IMAGE_NAME;

  const url = `${BASE_URL}/${organisation.id}/${imageName}?count=${count}`;

  if (isInChecklyMode()) return null;

  return (
    <>
      <StyledProfileImage
        title={title ?? organisation.name}
        onClick={onClick}
        style={style}
        size={size}
        src={url}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = createImageFromInitials(
            organisation.name ?? "Org",
          );
        }}
        alt="Organisation image"
      />
    </>
  );
};

export default OrganisationImageRound;
