import { InboundMessage } from "ably";
import { styleMessageHandler } from "business/style/state";
import { _Styles } from "business/style/types";
import { useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { fetchWithToken } from "services/utils";
import { useAblyGeneric } from "./useAblyGeneric";
import { z } from "zod";
import { Mixpanel } from "mixpanel";

/**
 * Temporary sync for existing styles that are stored in LS. Push these up to
 * Dynamo, and clear the LS. When we stop seeing events in Mixpanel, or at
 * Christmas 2024, we can remove this.
 */
const usePushExistingStyles = (projectId: string) => {
  useEffect(() => {
    if (typeof window === "undefined" || !("localStorage" in window)) return;
    const key = `vind:feature-styles:${projectId}`;
    const it = window.localStorage.getItem(key);
    if (!it) return;

    const styles = _Styles.parse(JSON.parse(it));
    for (const s of styles) {
      fetchWithToken(`/api/json/${projectId}/styling/${s.id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(s),
      });
    }
    if (styles.length)
      Mixpanel.track_old("Sync local styles", {
        count: styles.length,
      });
    window.localStorage.removeItem(key);
  }, [projectId]);
};

const JsonMsg = z.object({
  key: z.string(),
  data: z.unknown(),
});

const JsonDelete = z.object({
  key: z.string(),
});

export const useAblyStyling = (projectId: string) => {
  const handleStyle = useSetAtom(styleMessageHandler);
  const channelName = `${projectId}:all`;

  usePushExistingStyles(projectId);

  const events = useMemo(
    () => [
      {
        eventName: "json-create",
        onMessageReceived: (msg: InboundMessage) => {
          const { key, data } = JsonMsg.parse(msg.data);
          if (key.startsWith("styling/")) handleStyle("create", key, data);
        },
      },
      {
        eventName: "json-update",
        onMessageReceived: (msg: InboundMessage) => {
          const { key, data } = JsonMsg.parse(msg.data);
          if (key.startsWith("styling/")) handleStyle("update", key, data);
        },
      },
      {
        eventName: "json-delete",
        onMessageReceived: (msg: InboundMessage) => {
          const { key } = JsonDelete.parse(msg.data);
          if (key.startsWith("styling/")) handleStyle("delete", key);
        },
      },
    ],
    [handleStyle],
  );

  useAblyGeneric(channelName, events, projectId);
};
