import { unwrap } from "jotai/utils";
import { projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../state/modal";
import { NotificationSettingsAtom, loggedInUserAtom } from "../../state/user";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import { Suspense, useCallback, useState } from "react";
import { SkeletonBlock, SkeletonImage } from "../Loading/Skeleton";
import { colors } from "../../styles/colors";
import UserImageRound, {
  useInvalidateUntilOkay,
} from "../UserImage/UserImageRound";
import useTextInput from "../../hooks/useTextInput";
import {
  makeUserPictureUrl,
  UserNotificationSettings,
} from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { Input } from "../General/Input";
import { Row, Column, ModalFrame } from "../General/Layout";
import {
  useUpdateUserMeta,
  useUpdateUserNotifications,
} from "../../hooks/useUser";
import { Label } from "../General/Form";
import { LoggedInUser, UserMetaInfoUpdate } from "../../types/user";
import { CustomerPersona } from "../../types/customer";
import {
  ErrorBoundaryPrintOnly,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import Tooltip from "../General/Tooltip";
import { spaceLarge, spaceMedium } from "../../styles/space";
import { Comp } from "../../types/utils";
import Tabs from "../General/Tabs";
import Checkbox from "../General/Checkbox";
import { allCollaboratorsInNodeSelectorFamily } from "../../state/customer";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

export const UserProfileModalType = "UserProfileModal";

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.8rem;
`;

const UserImageWithUploadWrapper = styled.div<{
  size: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;

  margin: ${spaceMedium};

  .overlay {
    margin: -${spaceMedium};
    border-radius: 50%;
  }
  img {
    margin: ${spaceMedium};
  }
`;

const UploadOverlay = styled.div.attrs(() => ({
  className: "overlay",
}))`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transition: background 0.1s ease-in;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${colors.brand}30;
  }

  > input[type="file"] {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  > input[type="file"]::file-selector-button {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TabWrapper = styled(Column)`
  flex: 1;
  margin: ${spaceLarge} ${spaceLarge} 0 ${spaceLarge};
`;

const UserImageWithUpload = ({
  user,
  size,
  onFile,
  ...props
}: {
  user: LoggedInUser;
  size: number;
  onFile: (s: string | Blob) => undefined | Promise<any>;
} & Comp<"div">) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <UserImageWithUploadWrapper size={size} {...props}>
      {loading ? (
        <SkeletonImage size={size} />
      ) : (
        <Tooltip text="Upload picture" position="bottom">
          <UploadOverlay>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (!file) return;
                setLoading(true);
                const promise = onFile(file);
                if (promise) {
                  promise.then(() => {
                    setLoading(false);
                  });
                } else setLoading(false);
              }}
            />
          </UploadOverlay>
          <UserImageRound user={user} size={size} />
        </Tooltip>
      )}
    </UserImageWithUploadWrapper>
  );
};

const UserForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const UserProfileSettingsTabFallback = () => (
  <TabWrapper>
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
  </TabWrapper>
);

const UserProfileSettingsNotifications = ({ user }: { user: LoggedInUser }) => {
  const updateSettings = useUpdateUserNotifications();
  const [loadingSettings, setLoadingSettings] = useState<Set<string>>(
    new Set(),
  );

  const settings = useAtomValue(NotificationSettingsAtom) ?? {};

  const _onChange = useCallback(
    async (setting: Partial<UserNotificationSettings>) => {
      setLoadingSettings((curr) => {
        const s = new Set(curr);
        for (const k of Object.keys(setting)) s.add(k);
        return s;
      });
      await updateSettings(setting);
      setLoadingSettings((curr) => {
        const s = new Set(curr);
        for (const k of Object.keys(setting)) s.delete(k);
        return s;
      });
    },
    [updateSettings],
  );

  const updateUserMeta = useUpdateUserMeta();
  const updateUserInfo = useCallback(
    async (update: UserMetaInfoUpdate) => {
      setLoadingSettings((c) => new Set(c).add("user-settings"));
      await updateUserMeta(update);
      setLoadingSettings((c) => {
        const s = new Set(c);
        s.delete("user-settings");
        return s;
      });
    },
    [updateUserMeta],
  );

  return (
    <TabWrapper>
      <>
        <p>I want to receive an email when:</p>
        <h4>Project activity</h4>
        <Checkbox
          checked={settings.email_on_mention}
          disabled={loadingSettings.has("email_on_mention")}
          onChange={() =>
            _onChange({
              email_on_mention: settings.email_on_mention
                ? !settings.email_on_mention
                : true,
            })
          }
          label={"Someone @mentions me"}
          labelPlacement="after"
        />
        <Checkbox
          checked={settings.email_on_comment_in_followed_thread}
          disabled={loadingSettings.has("email_on_comment_in_followed_thread")}
          onChange={() =>
            _onChange({
              email_on_comment_in_followed_thread:
                !settings.email_on_comment_in_followed_thread,
            })
          }
          label={"Someone comments in threads I'm following"}
          labelPlacement="after"
        />

        <Checkbox
          checked={settings.email_on_branch_created}
          disabled={loadingSettings.has("email_on_branch_created")}
          onChange={() =>
            _onChange({
              email_on_branch_created: !settings.email_on_branch_created,
            })
          }
          label={"A new branch is created in a project I've starred"}
          labelPlacement="after"
        />

        <h4>Team activity</h4>
        <Checkbox
          checked={settings.email_on_project_created}
          disabled={loadingSettings.has("email_on_project_created")}
          onChange={() =>
            _onChange({
              email_on_project_created: !settings.email_on_project_created,
            })
          }
          label={"A new project is created in one of my teams"}
          labelPlacement="after"
        />
        <Checkbox
          checked={settings.email_on_added_to_node}
          disabled={loadingSettings.has("email_on_added_to_node")}
          onChange={() =>
            _onChange({
              email_on_added_to_node: !settings.email_on_added_to_node,
            })
          }
          label={"I'm added as a collaborator to a folder or project"}
          labelPlacement="after"
        />

        <h4>Other e-mail updates</h4>
        <Checkbox
          checked={user.allow_news}
          disabled={loadingSettings.has("user-settings")}
          onChange={(e) =>
            updateUserInfo({
              ...user,
              allow_news: e.target.checked,
            })
          }
          label={"Vind AI has product updates and news"}
          labelPlacement="after"
        />
        <Checkbox
          checked={user.allow_interest_specific_news}
          disabled={loadingSettings.has("user-settings")}
          onChange={(e) =>
            updateUserInfo({
              ...user,
              allow_interest_specific_news: e.target.checked,
            })
          }
          label={"Interest specific news and updates"}
          labelPlacement="after"
        />
      </>
      {loadingSettings.size !== 0 && (
        <SkeletonBlock
          style={{
            height: "1rem",
            width: "100%",
          }}
        />
      )}
    </TabWrapper>
  );
};

const UserProfileSettingsProfile = ({ user }: { user: LoggedInUser }) => {
  const invalidateUntilOkay = useInvalidateUntilOkay();
  const updateUserMeta = useUpdateUserMeta();

  const [loading, setLoading] = useState<boolean>(false);
  const [writtenName, onWrittenNameChange] = useTextInput(user.nickname);

  const updateUserInfo = useCallback(
    async (update: UserMetaInfoUpdate) => {
      setLoading(true);
      return updateUserMeta(update).finally(() => {
        setLoading(false);
      });
    },
    [updateUserMeta],
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        updateUserInfo({
          ...user,
          nickname: writtenName,
        });
      }}
    >
      <TabWrapper>
        <UserImageWithUpload
          style={{
            alignSelf: "center",
          }}
          user={user}
          size={10}
          onFile={async (file) => {
            const { url } = await makeUserPictureUrl(file);

            updateUserInfo({
              nickname: writtenName,
              picture: url,
            });
            await invalidateUntilOkay(
              {
                ...user,
                nickname: writtenName,
                picture: url,
              },
              64,
            );
          }}
        />

        <Label>
          <p>Name</p>
          <Input
            value={writtenName ?? user.nickname}
            onChange={onWrittenNameChange}
            disabled={loading}
          />
        </Label>

        <Label>
          <p>Email</p>
          <Input
            value={user.email}
            disabled
            type="email"
            title="Changing email is not supported. Contact us!"
          />
        </Label>

        <Row
          style={{
            marginTop: "auto",
            alignItems: "center",
          }}
        >
          {loading && (
            <SkeletonBlock
              style={{
                height: "1rem",
                flex: 1,
              }}
            />
          )}
          <Button
            disabled={loading}
            type="submit"
            buttonType={"primary"}
            text="Save"
            style={{
              marginLeft: "auto",
              alignSelf: "end",
            }}
          />
        </Row>
      </TabWrapper>
    </Form>
  );
};

const CurrentUserModalInner = ({
  selectedUser,
}: {
  selectedUser: LoggedInUser;
}) => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  const tab = (modalTypeOpen as any)?.["metadata"]?.["tab"] ?? "settings";
  const tabI = ["settings", "notifications"].indexOf(tab);

  return (
    <Row>
      <Tabs
        initialTab={tabI}
        wrapperStyle={{
          flex: 1,
        }}
        contentWrapperStyle={{
          flex: 1,
        }}
        menuStyle={{
          justifyContent: "flex-start",
        }}
        tabs={[
          {
            name: "Settings",
            data: (
              <Suspense fallback={<UserProfileSettingsTabFallback />}>
                <UserProfileSettingsProfile user={selectedUser} />
              </Suspense>
            ),
          },
          {
            name: "Notifications",
            data: (
              <Suspense fallback={<UserProfileSettingsTabFallback />}>
                <UserProfileSettingsNotifications user={selectedUser} />
              </Suspense>
            ),
          },
        ]}
      />
    </Row>
  );
};

const GenericUserProfile = ({
  userId,
  nodeId,
}: {
  userId: string;
  nodeId: string;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);

  const membersInCustomers = useAtomValue(
    unwrap(allCollaboratorsInNodeSelectorFamily(nodeId)),
  );
  const member = membersInCustomers?.find(
    (u: CustomerPersona) => u.user_id === userId,
  );

  if (!member) return null;

  return (
    <>
      <UserForm>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UserImageRound user={member} size={10} />
        </div>

        <Label>
          <p>Name</p>
          <Input value={member.nickname} disabled />
        </Label>

        <Label>
          <p>Email</p>
          <Input value={member.email} disabled type="email" />
        </Label>

        <ButtonGroup>
          <Button
            size="small"
            buttonType="secondary"
            text="Close"
            onClick={() => setModalTypeOpen(undefined)}
          />
        </ButtonGroup>
      </UserForm>
    </>
  );
};

const UserProfileModalOuter = ErrorBoundaryWrapper(
  ({
    userId,
    loggedInUser,
  }: {
    userId: string;
    loggedInUser: LoggedInUser;
  }) => {
    const projectId = useAtomValue(projectIdAtom);

    const isSelf = loggedInUser.user_id === userId;

    if (isSelf) return <CurrentUserModalInner selectedUser={loggedInUser} />;

    if (!projectId) return null;
    return <GenericUserProfile userId={userId} nodeId={projectId} />;
  },
  ErrorBoundaryPrintOnly,
  ScreamOnError,
);

const UserProfileSettingsModal = () => {
  const [midScreenModalTypeOpen, setMidScreenModalTypeOpen] = useAtom(
    midScreenModalTypeOpenAtom,
  );
  const navigate = useNavigate();

  const onExit = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("profile") != null) query.delete("profile");
    const path =
      window.location.pathname +
      (query.keys.length !== 0 ? "" : `?${query.toString()}`) +
      window.location.hash;
    navigate(path);
    setMidScreenModalTypeOpen(undefined);
  }, [navigate, setMidScreenModalTypeOpen]);

  const userData = useAtomValue(unwrap(loggedInUserAtom));

  if (
    !midScreenModalTypeOpen ||
    midScreenModalTypeOpen.modalType !== UserProfileModalType ||
    !userData
  )
    return null;

  const userId = midScreenModalTypeOpen.metadata.user.user_id;
  if (!userId) return null;

  return (
    <FullScreenModal placeOnTopOfOtherModals={true}>
      <ModalFrame
        title="Profile settings"
        onExit={onExit}
        style={{
          width: "40rem",
          minHeight: "42rem",
        }}
      >
        <Suspense
          fallback={
            <SkeletonBlock
              style={{
                height: "1rem",
                width: "100%",
              }}
            />
          }
        >
          <UserProfileModalOuter userId={userId} loggedInUser={userData} />
        </Suspense>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default UserProfileSettingsModal;
