import { z } from "zod";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import * as spec from "api/configurations";
import { scream } from "utils/sentry";

export const CABLE_POWER_RATING_WATT_MIN = 5e6;
export const CABLE_POWER_RATING_WATT_MAX = 3000e6;

export enum IAVoltageType {
  kV33 = 33,
  kV66 = 66,
  kV132 = 132,
}
export const _IAVoltageType = z.nativeEnum(IAVoltageType);

export enum ExportCableVoltageType {
  kV110 = 110,
  kV150 = 150,
  kV220 = 220,
  kV275 = 275,
  kV345 = 345,
  kV380 = 380,
  kV420 = 420,
  kV320 = 320,
  kV525 = 525,
}
export const _ExportCableVoltageType = z.nativeEnum(ExportCableVoltageType);

export enum ExportSystemType {
  HVAC = "HVAC",
  HVDC = "HVDC",
}

export const _CableType = spec["components"]["schemas"]["CableType"];
export type CableType = z.infer<typeof _CableType>;
const CableTypeIdentifier =
  spec["components"]["schemas"]["CableTypeIdentifier"];
type CableTypeIdentifier = z.infer<typeof CableTypeIdentifier>;

export async function getCableTypes(nodeId: string, version?: number) {
  return fetchEnhancerWithToken(
    `/api/cable/node/${nodeId}${version ? `?version=${version}` : ""}`,
    {
      method: "get",
    },
  );
}

export async function createProjectCableType(
  nodeId: string,
  cableType: Partial<CableType>,
): Promise<CableType> {
  return fetchEnhancerWithToken(`/api/cable/node/${nodeId}`, {
    method: "post",
    body: JSON.stringify(cableType),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    const ct = _CableType.parse(j);
    return ct;
  });
}
export async function updateProjectCableType(
  nodeId: string,
  cableType: CableType,
) {
  return fetchEnhancerWithToken(
    `/api/cable/node/${nodeId}/type/${cableType.id}`,
    {
      method: "post",
      body: JSON.stringify(cableType),
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then((res) => {
    if (!res.ok) throw new Error("Failed to update");
  });
}

export async function deleteProjectCableType(nodeId: string, cableId: string) {
  return fetchEnhancerWithToken(`/api/cable/node/${nodeId}/type/${cableId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Failed to delete");
  });
}

// -------- Organisation cable ------------------------

export async function createNewInterArrayOrgCable(
  nodeId: string,
  cableType: Partial<CableType>,
  projectAccess?: string[],
): Promise<CableType> {
  const body = {
    cable: cableType,
    ...(projectAccess ? { projectAccess } : {}),
  };

  return fetchEnhancerWithToken(`/api/cable/organisation/${nodeId}`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    const ct = _CableType.parse(j);
    return ct;
  });
}

export async function createNewOrgExportCable(
  nodeId: string,
  cableType: Partial<CableType>,
  projectAccess?: string[],
): Promise<CableType> {
  const body = {
    cable: cableType,
    ...(projectAccess ? { projectAccess } : {}),
  };

  return fetchEnhancerWithToken(`/api/exportCable/organisation/${nodeId}`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (!res.ok) throw new Error("Failed to create");
    const j = await res.json();
    const ct = _CableType.parse(j);
    return ct;
  });
}

export async function updateOrgInterArrayCable(
  organisationId: string,
  cable: CableType,
) {
  const headers = {
    method: "put",
    body: JSON.stringify(cable),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/cable/organisation/${organisationId}/type/${cable.id}`,
    headers,
  )
    .then(async (response) => {
      const json = await response.json();
      return _CableType.parse(json);
    })
    .catch((err) => {
      scream("updateCable failed", { e: err });
      throw err;
    });
}

export async function updateOrgExportCable(
  organisationId: string,
  cable: CableType,
) {
  const headers = {
    method: "put",
    body: JSON.stringify(cable),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/exportCable/organisation/${organisationId}/type/${cable.id}`,
    headers,
  )
    .then(async (response) => {
      const json = await response.json();
      return _CableType.parse(json);
    })
    .catch((err) => {
      scream("updateExportCable failed", { e: err });
      throw err;
    });
}

export async function deleteOrgInterArrayCable(
  organisationId: string,
  cableId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/cable/organisation/${organisationId}/type/${cableId}`,
    headers,
  ).catch((err) => {
    scream("deleteCable failed", { e: err });
    throw err;
  });
}

export async function deleteOrgExportCable(
  organisationId: string,
  cableId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/exportCable/organisation/${organisationId}/type/${cableId}`,
    headers,
  ).catch((err) => {
    scream("deleteExportCable failed", { e: err });
    throw err;
  });
}

export const getLibraryCableIdentifiers = async (organisationId: string) => {
  return fetchSchemaWithToken(
    CableTypeIdentifier.array(),
    `/api/cable/organisation/${organisationId}/identifiers`,
    {
      method: "get",
    },
  );
};
