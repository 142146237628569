import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import {
  getBranchId,
  getPark,
  getParkId,
  getTurbines,
  getTurbinesWithFixedFoundations,
  getTurbinesWithFloatingFoundations,
} from "analysis/inputs";
import { fetchInstallationDuration } from "services/metoceanService";
import { turbineInstallationTimeFamily } from "./turbines/turbineInstallationTime";
import { getParkCenter } from "utils/parkUtils";
import { calculateInstallationDates } from "./utils";
import {
  InstallationAnalysisInput,
  InstallationAnalysisResult,
  InstallationType,
} from "state/jotai/windStatistics";
import { sum } from "utils/utils";
import { Percentile } from "functions/production";
import { cableInstallationTimeFamily } from "./cables/cableInstallationTime";
import { exportCableInstallationTimeFamily } from "./exportCables/exportCableInstallationTime";
import { mooringInstallationTimeFamily } from "./mooring/mooringInstallationTime";
import { substationInstallationTimeFamily } from "./substation/substationInstallationTime";
import { floaterInstallationTimeFamily } from "./foundations/floaterInstallationTime";
import { jacketInstallationTimeFamily } from "./foundations/jacketInstallationTime";
import { monopileInstallationTimeFamily } from "./foundations/monopileInstallationTime";
import { scourProtectionTimeFamily } from "./foundations/scourProtectionTime";
import { turbinesInParkWithFoundationFamily } from "state/jotai/turbine";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { cablesInParkFamily } from "state/jotai/cable";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { FinanceId } from "finance/types";
import { HOURS_PER_DAY } from "@constants/production";
import { DateTime } from "luxon";

const getInstallationInput = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
      let installationInput: InstallationAnalysisInput | undefined;
      switch (type) {
        case InstallationType.Turbine:
          installationInput = await get(turbineInstallationTimeFamily(id));
          break;
        case InstallationType.Floater:
          installationInput = await get(floaterInstallationTimeFamily(id));
          break;
        case InstallationType.Monopile:
          installationInput = await get(monopileInstallationTimeFamily(id));
          break;
        case InstallationType.Jacket:
          installationInput = await get(jacketInstallationTimeFamily(id));
          break;
        case InstallationType.ScourProtection:
          installationInput = await get(scourProtectionTimeFamily(id));
          break;
        case InstallationType.Mooring:
          installationInput = await get(mooringInstallationTimeFamily(id));
          break;
        case InstallationType.Cable:
          installationInput = await get(cableInstallationTimeFamily(id));
          break;
        case InstallationType.ExportCable:
          installationInput = await get(exportCableInstallationTimeFamily(id));
          break;
        case InstallationType.Substation:
          installationInput = await get(substationInstallationTimeFamily(id));
          break;
      }

      if (!installationInput) return;

      return installationInput;
    }),
);

const getTriggerInstallationAnalysis = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<InstallationAnalysisResult | undefined>>(async (get) => {
      const park = await get(getPark(id));
      const parkCenter = getParkCenter(park, []);
      const installationInput = await get(getInstallationInput({ id, type }));

      if (!installationInput) return;

      const response = await fetchInstallationDuration({
        parkCenter,
        installationInput,
      });
      return response;
    }),
);

export const getInstallationTimeDistribution = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<{ mean: number; std: number } | undefined>>(async (get) => {
      const installationResult = await get(
        getTriggerInstallationAnalysis({ id, type }),
      );
      if (!installationResult) return;
      return installationResult.totalInstallationTimeDistribution;
    }),
);

export const getInstallationTimePercentiles = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<Percentile[] | undefined>>(async (get) => {
      const installationResult = await get(
        getTriggerInstallationAnalysis({ id, type }),
      );
      if (!installationResult) return;
      return installationResult.totalInstallationTimePercentiles;
    }),
);

export const getTotalInstallationTime = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<number | undefined>>(async (get) => {
      const numFeatures = await get(
        getNumberOfInstalledFeaturesPerType({ id, type }),
      );
      if (numFeatures === 0) return 0;

      const installationResult = await get(
        getTriggerInstallationAnalysis({ id, type }),
      );
      if (!installationResult) return undefined;

      const totalInstallTimes = installationResult.installationTimePerStep.map(
        ({ duration }) => sum(duration),
      );

      const meantotalInstallTimeDays =
        Math.round(
          (10 * sum(totalInstallTimes)) /
            totalInstallTimes.length /
            HOURS_PER_DAY,
        ) / 10;

      return meantotalInstallTimeDays;
    }),
);

export const getTotalWorkingTime = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<number | undefined>>(async (get) => {
      const numFeatures = await get(
        getNumberOfInstalledFeaturesPerType({ id, type }),
      );
      if (numFeatures === 0) return 0;

      const installationInput = await get(getInstallationInput({ id, type }));

      if (!installationInput) return undefined;

      const installationSequence = installationInput.installationSequence;

      const totalWorkingTimeHours = installationSequence.reduce(
        (acc, { duration }) => (acc = acc + Math.ceil(duration)),
        0,
      );

      const totalWorkingTimeDays = totalWorkingTimeHours / HOURS_PER_DAY;

      return totalWorkingTimeDays;
    }),
);

export const getTotalWaitingTimeAndPercent = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<
      Promise<
        | { totalWaitingTimeDays: number; totalWaitingTimePercent: number }
        | undefined
      >
    >(async (get) => {
      const numFeatures = await get(
        getNumberOfInstalledFeaturesPerType({ id, type }),
      );
      if (numFeatures === 0)
        return { totalWaitingTimeDays: 0, totalWaitingTimePercent: 0 };

      const totalInstallationTimeDays = await get(
        getTotalInstallationTime({ id, type }),
      );
      const totalWorkingTimeDays = await get(getTotalWorkingTime({ id, type }));

      if (!totalInstallationTimeDays || !totalWorkingTimeDays) return;

      const totalWaitingTimeDays =
        totalInstallationTimeDays - totalWorkingTimeDays;

      const totalWaitingTimePercent =
        (totalWaitingTimeDays / totalInstallationTimeDays) * 100;

      return { totalWaitingTimeDays, totalWaitingTimePercent };
    }),
);

export const getInstallationStartAndEndDate = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<
      Promise<
        | {
            installationYears: number;
            installationStartDate: string;
            installationEndDate: string;
          }
        | undefined
      >
    >(async (get) => {
      const numFeatures = await get(
        getNumberOfInstalledFeaturesPerType({ id, type }),
      );

      const totalInstallationTime =
        numFeatures > 0 ? await get(getTotalInstallationTime({ id, type })) : 0;

      const installationInput = await get(getInstallationInput({ id, type }));

      if (!totalInstallationTime || !installationInput) return;

      const { startMonth, endMonth } = installationInput;

      const installationDates = calculateInstallationDates({
        startMonth,
        endMonth,
        totalInstallationTime,
      });

      return installationDates;
    }),
);

export const getWorkingTimeBreakdown = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<{ id: string; totalDuration: number }[] | undefined>>(
      async (get) => {
        const installationInput = await get(getInstallationInput({ id, type }));

        if (!installationInput) return undefined;

        const installationSequence = installationInput.installationSequence;

        const installBreakdown = installationSequence.reduce(
          (acc, curr) => {
            if (acc[curr.id]) {
              acc[curr.id].totalDuration +=
                Math.ceil(curr.duration) / HOURS_PER_DAY;
            } else {
              acc[curr.id] = {
                id: curr.id,
                totalDuration: Math.ceil(curr.duration) / HOURS_PER_DAY,
              };
            }
            return acc;
          },
          {} as { [key: string]: { id: string; totalDuration: number } },
        );

        return Object.values(installBreakdown);
      },
    ),
);

const getMeanDurationForInstallationSteps = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<number[] | undefined>>(async (get) => {
      const installationResult = await get(
        getTriggerInstallationAnalysis({ id, type }),
      );
      if (!installationResult) return undefined;

      const installationTimePerStep =
        installationResult.installationTimePerStep;
      const durationLength = installationTimePerStep[0].duration.length;
      const meanInstallationStepDuration = Array(durationLength).fill(0);

      installationTimePerStep.forEach((result) => {
        result.duration.forEach((duration, index) => {
          meanInstallationStepDuration[index] += duration / HOURS_PER_DAY;
        });
      });

      return meanInstallationStepDuration.map(
        (sum) => sum / installationTimePerStep.length,
      );
    }),
);

export const getWeatherWaitingTimeBreakdown = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<{ id: string; totalWaitingTime: number }[] | undefined>>(
      async (get) => {
        const installationInput = await get(getInstallationInput({ id, type }));
        const meanInstallationStepDuration = await get(
          getMeanDurationForInstallationSteps({ id, type }),
        );
        if (!installationInput || !meanInstallationStepDuration)
          return undefined;

        const installationSequence = installationInput.installationSequence;

        const installBreakdown = installationSequence.reduce(
          (acc, curr, i) => {
            const waitingTime =
              meanInstallationStepDuration[i] - curr.duration / HOURS_PER_DAY;
            if (acc[curr.id]) {
              acc[curr.id].totalWaitingTime += waitingTime;
            } else {
              acc[curr.id] = { id: curr.id, totalWaitingTime: waitingTime };
            }
            return acc;
          },
          {} as { [key: string]: { id: string; totalWaitingTime: number } },
        );

        return Object.values(installBreakdown);
      },
    ),
);

export const getNumberOfInstalledFeaturesPerType = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<Promise<number>>(async (get) => {
      const branchId = await get(getBranchId(id));
      const parkId = await get(getParkId(id));

      const turbinesWithFnd = await get(
        turbinesInParkWithFoundationFamily({
          parkId,
          branchId,
        }),
      );

      let installedFeatures: number = 0;
      switch (type) {
        case InstallationType.Turbine:
          const turbines = await get(getTurbines(id));
          const turbinesInstalledAtPort = await get(
            getTurbinesWithFloatingFoundations(id),
          );
          const turbinesInstalledAtSite = turbines.filter(
            (turbine) =>
              !turbinesInstalledAtPort.some(
                (portTurbine) => portTurbine.id === turbine.id,
              ),
          );
          installedFeatures = turbinesInstalledAtSite.length;
          break;
        case InstallationType.Floater:
          installedFeatures = (
            await get(getTurbinesWithFloatingFoundations(id))
          ).length;
          break;
        case InstallationType.Monopile:
          const turbinesWithMonopile = turbinesWithFnd
            .filter(
              ([, f]) =>
                f.type === "monopile" || f.type === "detailed_monopile",
            )
            .map(([t]) => t);
          installedFeatures = turbinesWithMonopile.length;
          break;
        case InstallationType.Jacket:
          const turbinesWithJacket = turbinesWithFnd
            .filter(
              ([, f]) => f.type === "jacket" || f.type === "detailed_jacket",
            )
            .map(([t]) => t);
          installedFeatures = turbinesWithJacket.length;
          break;
        case InstallationType.ScourProtection:
          installedFeatures = (await get(getTurbinesWithFixedFoundations(id)))
            .length;
          break;
        case InstallationType.Mooring:
          installedFeatures = (
            await get(anchorsInParkFamily({ parkId, branchId }))
          ).length;
          break;
        case InstallationType.Cable:
          installedFeatures = (
            await get(cablesInParkFamily({ parkId, branchId }))
          ).length;
          break;
        case InstallationType.ExportCable:
          installedFeatures = (
            await get(exportCablesInParkFamily({ parkId, branchId }))
          ).length;
          break;
        case InstallationType.Substation:
          const substations = await get(
            substationsInParkWithTypeFamily({ parkId, branchId }),
          );
          installedFeatures = substations.filter(
            ([, typ]) => typ.type === "offshore",
          ).length;
          break;
      }

      return installedFeatures;
    }),
);

export const getInstallationTypesWithFeaturesInPark = atomFamily(
  ({ id }: { id: FinanceId }) =>
    atom<Promise<InstallationType[]>>(async (get) => {
      const installationTypes = Object.values(InstallationType);

      const typeCounts = await Promise.all(
        installationTypes.map(async (type) => ({
          type,
          count: await get(getNumberOfInstalledFeaturesPerType({ id, type })),
        })),
      );

      return typeCounts
        .filter(({ count }) => count > 0)
        .map(({ type }) => type);
    }),
);

const getInstallationSequenceWithDates = atomFamily(
  ({ id, type }: { id: FinanceId; type: InstallationType }) =>
    atom<
      Promise<
        | {
            completionSequence: number[];
            totalTimeSequence: DateTime[];
          }
        | undefined
      >
    >(async (get) => {
      const installationInput = await get(getInstallationInput({ id, type }));
      const meanInstallationStepDuration = await get(
        getMeanDurationForInstallationSteps({ id, type }),
      );

      if (!installationInput || !meanInstallationStepDuration) return undefined;

      const { startMonth, endMonth } = installationInput;
      const installationStartDate = DateTime.local()
        .set({ month: startMonth })
        .startOf("month");
      let seasonStartDate = DateTime.local()
        .set({ month: startMonth })
        .startOf("month");
      let seasonEndDate = DateTime.local()
        .set({ month: endMonth })
        .endOf("month");

      if (endMonth < startMonth) {
        seasonEndDate = seasonEndDate.plus({ years: 1 });
      }

      const completionSequence = [];
      const totalTimeSequence = [];
      let cumulativeCompletion = 0;
      let cumulativeTotal = 0;

      const totalMileStones = installationInput.installationSequence.filter(
        (step) => step.mileStone,
      ).length;

      if (totalMileStones === 0) return undefined;

      for (let i = 0; i < installationInput.installationSequence.length; i++) {
        const completedMileStone =
          installationInput.installationSequence[i].mileStone;
        const totalTime = meanInstallationStepDuration[i];

        cumulativeCompletion += completedMileStone ? 1 : 0;
        cumulativeTotal += totalTime;

        let totalDate = installationStartDate.plus({
          days: cumulativeTotal,
        });

        if (totalDate > seasonEndDate) {
          const daysToNextSeason = seasonStartDate
            .plus({ years: 1 })
            .diff(seasonEndDate, "days").days;
          cumulativeTotal += daysToNextSeason;

          seasonStartDate = seasonStartDate.plus({ years: 1 });
          totalDate = seasonStartDate.plus({
            days: totalDate.diff(seasonEndDate, "days").days,
          });
          seasonEndDate = seasonEndDate.plus({ years: 1 });
        }

        completionSequence.push((cumulativeCompletion / totalMileStones) * 100);

        totalTimeSequence.push(totalDate);
      }

      return { completionSequence, totalTimeSequence };
    }),
);

export const getInstallationSCurveData = atomFamily(
  ({ id }: { id: FinanceId }) =>
    atom<
      Promise<{
        [key in InstallationType]?: {
          completionSequence: number[];
          totalTimeSequence: DateTime[];
        };
      }>
    >(async (get) => {
      const types = await get(getInstallationTypesWithFeaturesInPark({ id }));
      const result: {
        [key in InstallationType]?: {
          completionSequence: number[];
          totalTimeSequence: DateTime[];
        };
      } = {};

      for (const type of types) {
        const sequenceWithDates = await get(
          getInstallationSequenceWithDates({ id, type }),
        );
        if (sequenceWithDates) {
          result[type] = sequenceWithDates;
        }
      }

      return result;
    }),
);
