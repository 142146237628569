import styled from "styled-components";
import { Frame } from "../General/Layout";
import { spacing5 } from "../../styles/space";

export const Wrapper = styled(Frame)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > * {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem;
  box-sizing: border-box;
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  gap: 0.8rem;

  h3 {
    margin: 0;
  }
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing5};
  margin-right: ${spacing5};
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
