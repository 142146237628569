import { fetchEnhancer, fetchEnhancerWithToken } from "services/utils";
import {
  MeanSpeedGrid,
  _MeanSpeedGrid,
  _WRG,
  WRG,
} from "state/jotai/windStatistics";
import { z } from "zod";
import { _PresignedUrlResponse } from "./production";

export const _GumbelParams = z.object({
  loc: z.number().nullish(),
  scale: z.number().nullish(),
});
export type GumbelParams = z.infer<typeof _GumbelParams>;

export function gumbelPercentileValue(
  loc: number,
  scale: number,
  percentile: number,
): number {
  if (percentile === Math.exp(1)) return loc;

  return loc - scale * Math.log(-Math.log(percentile));
}

export function isMeanSpeedGrid(data: any): data is MeanSpeedGrid {
  return data?.grid !== undefined;
}

export function isWRG(data: any): data is WRG {
  return data && data.points && data.easting && data.northing;
}

export async function fetchCustomCalibrationFile({
  nodeId,
  id,
}: {
  nodeId: string;
  id: string;
}): Promise<MeanSpeedGrid | WRG> {
  return fetchEnhancerWithToken(
    `/api/octopus/metocean/speedgrid/file/node/${nodeId}/${id}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then(_PresignedUrlResponse.parse)
    .then((r) =>
      fetchEnhancer(r.url, {
        method: "get",
      }),
    )
    .then(async (r) => {
      if (!r.ok) {
        throw new Error("Failed to fetch custom mean speed grid");
      }

      return r.json();
    })
    .then((r) => _MeanSpeedGrid.or(_WRG).parse(r));
}
