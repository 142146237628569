import { InboundMessage, Message } from "ably";
import { getCurrentTokenUserId } from "../state/global";
import { base64DecodeAndUnzip } from "utils/utils";
import useAblyClient from "components/Ably/useAblyClient";
import * as Sentry from "@sentry/react";
import { useChannel } from "ably/react";
import { useCallback } from "react";

export type AblyEventHandler = {
  eventName: string;
  onMessageReceived: (msg: InboundMessage) => void;
};

export function useAblyGeneric(
  channelName: string,
  events: AblyEventHandler[],
  clientId: string,
) {
  const client = useAblyClient(clientId);

  const onMessageReceived = useCallback(
    (message: Message) => {
      const match = events.find((e) => e.eventName === message.name);
      if (match) {
        try {
          const decodedData =
            message.data?.encoding === "gzip"
              ? base64DecodeAndUnzip(message.data.encodedData)
              : message.data;

          // Message is missing connectionId, might be a message from the backend where we do not add connectionId
          if (!decodedData._connectionId) {
            if (decodedData.clientId === getCurrentTokenUserId()) {
              return;
            }
          } else if (decodedData._connectionId === client?.connection.id) {
            // do not apply your own changes
            return;
          }

          match.onMessageReceived({
            ...message,
            data: decodedData,
          } as InboundMessage);
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              channelName: channelName,
              eventName: match.eventName,
            },
            extra: {
              message,
            },
          });
        }
      }
    },
    [channelName, client?.connection.id, events],
  );

  useChannel(
    {
      channelName: channelName,
      ablyId: clientId,
      onChannelError: () => {},
      onConnectionError: () => {},
    },
    onMessageReceived,
  );
}
