import React from "react";
import styled from "styled-components";
import WarningTriangleIcon from "@icons/24/WarningTriangle.svg?react";
import Spinner from "@icons/spinner/Spinner";
import { colors } from "../../../../styles/colors";
import { spaceLarge, spaceMedium, spaceSmall } from "../../../../styles/space";
import { IconREMSize, typography } from "../../../../styles/typography";
import { isDefined, isNumber } from "../../../../utils/predicates";
import { EditableTextInternalState } from "../../../General/EditableText";
import { LoadProgress, SelectedFileType } from "./types";
import { HelpLink } from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import { FeatureWithAnyProperties } from "types/feature";

const SelectedFileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spaceSmall};
  padding: ${spaceMedium} ${spaceLarge};
  background-color: ${colors.selected};
  border-radius: 4px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spaceSmall};
  align-items: center;
`;

const FileBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spaceSmall};
`;

const LoadingBar = styled.div<{ progress: number }>`
  display: flex;
  justify-content: space-between;
  gap: ${spaceSmall};
  width: ${({ progress }) => `${progress}%`};
  height: 5px;
  background-color: ${colors.primaryHover};
  border-radius: 4px;
  transition: width ease-in-out 0.1s;
`;

const ONE_MEGABYTE_IN_BYTES = 1_000_000;
const SelectedFile = ({
  fileName,
  fileSize,
  loadProgress,
  children,
  onRename,
  uploadShpGeojson,
}: {
  fileName: string;
  fileSize?: number;
  loadProgress?: Omit<LoadProgress, "id">;
  onRename?(newName: string): void;
  uploadShpGeojson?: (
    file: SelectedFileType,
  ) => Promise<FeatureWithAnyProperties[] | undefined>;
} & React.PropsWithChildren) => {
  const fileSizeInMb = isNumber(fileSize)
    ? (fileSize / ONE_MEGABYTE_IN_BYTES).toFixed(1)
    : undefined;

  const numChildren = React.Children.toArray(children).filter(isDefined).length;
  return (
    <SelectedFileWrapper>
      <TopRow>
        <EditableTextInternalState
          style={{ padding: 0, width: "100%" }}
          value={fileName}
          disabled={!onRename}
          onEnter={onRename}
          renderText={(val) => (
            <span style={{ ...typography.contentAndButtons }}>{val}</span>
          )}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: spaceMedium,
            flexShrink: 0,
          }}
        >
          {loadProgress?.waitingMessage ? (
            <span style={{ ...typography.label }}>
              {loadProgress.waitingMessage}
            </span>
          ) : loadProgress &&
            !loadProgress.error &&
            loadProgress.progress !== 100 ? (
            <>
              <span style={{ ...typography.label }}>
                {loadProgress.message}
              </span>
              <Spinner style={{ height: "0.7rem", width: "0.7rem" }} />
            </>
          ) : (
            loadProgress?.progress === 100 &&
            numChildren === 0 && (
              <span style={{ ...typography.contentAndButtons }}>Done</span>
            )
          )}
          {numChildren > 0 && children}
        </div>
      </TopRow>

      <FileBottomRow>
        {loadProgress?.error ? (
          <div
            style={{ display: "flex", gap: spaceSmall, alignItems: "center" }}
          >
            <IconREMSize height={1.3} width={1.3}>
              <WarningTriangleIcon />
            </IconREMSize>
            <span style={{ color: colors.errorText }}>
              {loadProgress.error}
            </span>
            {loadProgress.intercomHelpId && (
              <HelpLink article={loadProgress.intercomHelpId} />
            )}
          </div>
        ) : (
          <>
            <span style={{ ...typography.contentAndButtons }}>
              {typeof fileSizeInMb !== "undefined" && <>{fileSizeInMb}MB</>}
            </span>
            <span style={{ ...typography.contentAndButtons }}>
              {typeof loadProgress?.progress !== "undefined" && (
                <>{loadProgress.progress}%</>
              )}
            </span>
          </>
        )}
      </FileBottomRow>
      {loadProgress?.fixUpload && uploadShpGeojson && (
        <div>
          <Button
            text={loadProgress.fixUpload.message}
            onClick={async () => {
              const fixedFile = await loadProgress.fixUpload!.callback();
              await uploadShpGeojson(fixedFile);
            }}
          />
        </div>
      )}
      {loadProgress && !loadProgress.error && loadProgress.progress !== 100 && (
        <LoadingBar progress={loadProgress.progress ?? 0} />
      )}
    </SelectedFileWrapper>
  );
};

export default SelectedFile;
