import GeoTIFF, { TypedArray } from "geotiff";
import { Map } from "mapbox-gl";
import { useEffect, useState } from "react";
import { fetchEnhancer } from "../../services/utils";
import { transformBBOXToWGS84Square } from "../../utils/proj4";
import { addLayer } from "components/Mapbox/utils";
import { MapboxBbox4 } from "types/mapbox";

export const MapboxRaster = ({
  layerId,
  map,
  imageUrl,
  bbox,
  opacity = 1,
  interpolation = "nearest",
}: {
  layerId: string;
  map: Map;
  imageUrl: string;
  bbox: MapboxBbox4;
  opacity: number;
  interpolation?: "nearest" | "linear";
}) => {
  useEffect(() => {
    if (!imageUrl || !bbox) return;
    map.addSource(layerId, {
      type: "image",
      url: imageUrl,
      coordinates: bbox,
    });
    addLayer(map, {
      id: layerId,
      type: "raster",
      source: layerId,
      paint: {
        "raster-resampling": interpolation,
        "raster-opacity": opacity,
      },
    });
    return () => {
      map.removeLayer(layerId);
      map.removeSource(layerId);
    };
    // Dont add opacity as dependency,
    // we dont want to remove and re-add the layer when the opacity changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, map, bbox, layerId, interpolation]);

  useEffect(() => {
    if (!map || !map.getLayer(layerId)) return;
    map.setPaintProperty(layerId, "raster-opacity", opacity);
  }, [layerId, map, opacity]);

  return null;
};

export const GeotiffImage = ({
  layerId,
  map,
  geotiff,
  opacity = 1,
}: {
  layerId: string;
  map: Map;
  geotiff: GeoTIFF;
  opacity: number;
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [bbox, setBBOX] = useState<MapboxBbox4>();

  useEffect(() => {
    if (!geotiff) return;
    let isCancelled = false;
    const initBBOX = async () => {
      const image = await geotiff.getImage();
      const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
      const epsg =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;

      let square = [
        { lng: bbox[0], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[3] },
        { lng: bbox[0], lat: bbox[3] },
      ];

      if (epsg !== 4326) {
        const response = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
          method: "get",
        });
        const proj4String = await response.text();
        square = transformBBOXToWGS84Square(bbox, proj4String);
      }

      const data = (await image.readRGB({
        interleave: true,
        enableAlpha: true,
      })) as TypedArray & { height: number; width: number }; // NOTE: see https://geotiffjs.github.io/geotiff.js/module-geotiff.html#~ReadRasterResult

      const canvas = document.createElement("canvas");
      canvas.width = data.width;
      canvas.height = data.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      var imgData = ctx.createImageData(data.width, data.height);

      if (image.getSamplesPerPixel() === 4) {
        imgData.data.set(data);
      } else {
        let alphaSkip = 0;
        for (var i = 0; i < data.length; i += 3) {
          imgData.data[i + alphaSkip] = data[i]; //red
          imgData.data[i + 1 + alphaSkip] = data[i + 1]; //green
          imgData.data[i + 2 + alphaSkip] = data[i + 2]; //blue
          imgData.data[i + 3 + alphaSkip] = 255; //alpha
          alphaSkip++;
        }
      }

      ctx.putImageData(imgData, 0, 0);

      const rotatedImage = canvas.toDataURL("image/png");

      if (isCancelled) return;
      setImageUrl(rotatedImage);
      setBBOX([
        ...square.map((point) => [point.lng, point.lat]).reverse(),
      ] as MapboxBbox4);
    };
    initBBOX();
    return () => {
      isCancelled = true;
    };
  }, [geotiff, setImageUrl, setBBOX]);

  if (!imageUrl || !bbox) return null;

  return (
    <MapboxRaster
      layerId={layerId}
      map={map}
      imageUrl={imageUrl}
      bbox={bbox}
      opacity={opacity}
    />
  );
};

export type ColorBucket = {
  threshold: number;
  color: [number, number, number];
};

export const GeotiffArray = ({
  layerId,
  map,
  geotiff,
  colorBuckets,
  opacity = 255,
}: {
  layerId: string;
  map: Map;
  geotiff: GeoTIFF;
  colorBuckets: ColorBucket[];
  opacity?: number;
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [bbox, setBBOX] = useState<MapboxBbox4>();

  useEffect(() => {
    if (!geotiff) return;
    let isCancelled = false;
    const initBBOX = async () => {
      const image = await geotiff.getImage();
      const bbox = image.getBoundingBox() as [number, number, number, number]; // safety: okay according to docs.
      const epsg =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;

      let square = [
        { lng: bbox[0], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[3] },
        { lng: bbox[0], lat: bbox[3] },
      ];

      if (epsg !== 4326) {
        const response = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
          method: "get",
        });
        const proj4String = await response.text();
        square = transformBBOXToWGS84Square(bbox, proj4String);
      }

      if (isCancelled) return;

      const data = await image.readRasters();

      const canvas = document.createElement("canvas");
      canvas.width = data.width;
      canvas.height = data.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      var imgData = ctx.createImageData(data.width, data.height);

      const array = data[0] as TypedArray;

      let offset = 0;
      for (var i = 0; i < array.length; i++) {
        const value = array[i];
        if (value === 0) {
          imgData.data[i + offset] = 0;
          imgData.data[i + 1 + offset] = 0;
          imgData.data[i + 2 + offset] = 0;
          imgData.data[i + 3 + offset] = 0;
        } else {
          const colorRGB =
            colorBuckets.find((cb) => cb.threshold >= value)?.color ??
            colorBuckets[0].color; // default to first color if threshold is lower than all buckets
          imgData.data[i + offset] = colorRGB[0];
          imgData.data[i + 1 + offset] = colorRGB[1];
          imgData.data[i + 2 + offset] = colorRGB[2];
          imgData.data[i + 3 + offset] = 255;
        }
        offset += 3;
      }

      ctx.putImageData(imgData, 0, 0);

      const rotatedImage = canvas.toDataURL("image/png");

      if (isCancelled) return;
      setImageUrl(rotatedImage);
      setBBOX([
        ...square.map((point) => [point.lng, point.lat]).reverse(),
      ] as MapboxBbox4);
    };
    initBBOX();
    return () => {
      isCancelled = true;
    };
  }, [geotiff, setImageUrl, setBBOX, colorBuckets]);

  if (!imageUrl || !bbox) return null;

  return (
    <MapboxRaster
      layerId={layerId}
      map={map}
      imageUrl={imageUrl}
      bbox={bbox}
      opacity={opacity}
    />
  );
};
