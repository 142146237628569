import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useEffect, useMemo, useState } from "react";
import { replaceOrUndefined } from "../ControlPanels/utils";
import Elements from "@icons/24/SideBarIcons/Elements.svg?react";
import SingleTurbine from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import Layers from "@icons/24/SideBarIcons/Layers.svg?react";
import Comments from "@icons/24/SideBarIcons/Comments.svg?react";
import StylingIcon from "@icons/14/Styling.svg";
import { Divider, IconMenu } from "components/General/Icons";
import SearchWrapper from "components/Search/SearchWrapper";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import SideBarIconBtn from "components/General/SideBarIconButton";
import CommentsLeftModal from "components/Comments/LeftModal/LeftModal";
import ProjectElementsV2 from "components/ProjectElementsV2/ProjectElementsV2";
import { leftModalMenuOpenStateAtom } from "./state";
import styled from "styled-components";
import { LeftModalMenuTypes } from "./state";
import { Mixpanel } from "mixpanel";
import { filteredThreadsSelectorFamily } from "components/Comments/state";
import { useReadComments } from "components/Comments/UnreadComments/useReadComments";
import { UnreadCommentDot } from "components/Comments/UnreadComments/style";
import LayerListV2 from "components/LayerList/LayerListV2";
import UndoRedoButtonsV2 from "components/ControlPanels/UndoRedoButtonsV2";
import { typography } from "styles/typography";
import { spacing1 } from "styles/space";
import { StylingPanel } from "components/StylingPanel/Panel";
import {
  ANIMATION_DURATION,
  LeftModalContainer,
} from "components/Design/styles";
import { hasCustomActiveFeatureSelector } from "business/style/state";
import { colors } from "styles/colors";
import { loadable } from "jotai/utils";
import { useAtom, useAtomValue } from "jotai";
import ExistingTurbinesMenu from "components/LowerRight/ExistingTurbines";

const MarkerWrapper = styled.div<{
  active: boolean;
}>`
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 0.5rem;
  background: ${colors.purple400};
  top: 0.2rem;
  right: 0.6rem;
  box-shadow: 0 0 10px ${colors.purple400};
`;

const LowerLeftColumnWrapperV2 = styled.div`
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const StylingPanelPositioning = () => {
  const leftModalMenuOpen = useAtomValue(leftModalMenuOpenStateAtom);
  const [shouldRender, setShouldRender] = useState(
    leftModalMenuOpen === LeftModalMenuTypes.Styling,
  );

  useEffect(() => {
    if (leftModalMenuOpen === LeftModalMenuTypes.Styling) {
      setShouldRender(true);
    } else {
      const delay = setTimeout(() => {
        setShouldRender(false);
      }, ANIMATION_DURATION * 1000);
      return () => clearTimeout(delay);
    }
  }, [leftModalMenuOpen]);

  return (
    <LeftModalContainer
      open={leftModalMenuOpen === LeftModalMenuTypes.Styling}
      style={{ gap: "1rem", overflowY: "hidden" }}
    >
      {shouldRender && <StylingPanel />}
    </LeftModalContainer>
  );
};

const LowerLeftTools = () => {
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const allCommentsLoadable = useAtomValue(
    loadable(
      filteredThreadsSelectorFamily({
        nodeId: projectId,
        branchId,
      }),
    ),
  );
  const commentsData =
    allCommentsLoadable.state === "hasData"
      ? allCommentsLoadable.data
      : undefined;
  const { isRead } = useReadComments();

  const hasUnreadComments = useMemo(() => {
    if (!commentsData) return false;
    const { threads } = commentsData;
    return threads.some((c) => !isRead(c));
  }, [commentsData, isRead]);

  const hasStyling = useAtomValue(hasCustomActiveFeatureSelector);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--left-menu-margin",
      leftModalMenuOpen &&
        [
          LeftModalMenuTypes.Elements,
          LeftModalMenuTypes.Comment,
          LeftModalMenuTypes.Layers,
          LeftModalMenuTypes.Styling,
        ].includes(leftModalMenuOpen)
        ? `calc(var(--left-menu-width) + 1.6rem)`
        : "0.8rem",
    );
  }, [leftModalMenuOpen]);

  return (
    <IconMenu
      sideBar
      iconSize="2.4rem"
      style={{
        paddingBottom: 0,
        paddingTop: spacing1,
      }}
    >
      <SearchWrapper
        title="View elements list"
        tags={[
          "features",
          "elements",
          "project",
          "status",
          "upload",
          "lock",
          "unlock",
          "styling",
          "style",
          "hide",
          "show",
          "download",
          "export",
        ]}
        id="search-elements-list"
        icon={<Elements />}
        onSelect={() => {
          setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Elements));
          Mixpanel.track_old(
            `Elements ${leftModalMenuOpen === LeftModalMenuTypes.Elements ? "closed" : "opened"}`,
            {
              projectId,
              branchId,
            },
          );
        }}
      >
        <SideBarIconBtn
          id="button-elements-list"
          icon={<Elements />}
          active={leftModalMenuOpen === LeftModalMenuTypes.Elements}
          onClick={() => {
            setLeftModalMenuOpen(
              replaceOrUndefined(LeftModalMenuTypes.Elements),
            );
            Mixpanel.track_old(
              `Elements ${leftModalMenuOpen === LeftModalMenuTypes.Elements ? "closed" : "opened"}`,
              {
                projectId,
                branchId,
              },
            );
          }}
        >
          <p
            style={{
              ...typography.graphics,
            }}
          >
            Elements
          </p>
        </SideBarIconBtn>
      </SearchWrapper>
      <Divider />
      <SearchWrapper
        title="View GIS layers"
        tags={["layers", "data", "hide", "show", "download", "gis"]}
        id="search-layers"
        icon={<Layers />}
        onSelect={() => {
          setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Layers));
          Mixpanel.track_old(
            `Layers ${leftModalMenuOpen === LeftModalMenuTypes.Layers ? "closed" : "opened"}`,
            {
              projectId,
              branchId,
            },
          );
        }}
      >
        <SideBarIconBtn
          id="button-layers"
          icon={<Layers />}
          active={leftModalMenuOpen === LeftModalMenuTypes.Layers}
          onClick={() => {
            setLeftModalMenuOpen(replaceOrUndefined(LeftModalMenuTypes.Layers));
            Mixpanel.track_old(
              `Layers ${leftModalMenuOpen === LeftModalMenuTypes.Layers ? "closed" : "opened"}`,
              {
                projectId,
                branchId,
              },
            );
          }}
        >
          <p
            style={{
              ...typography.graphics,
            }}
          >
            GIS Layers
          </p>
        </SideBarIconBtn>
      </SearchWrapper>
      <SearchWrapper
        title="Existing turbines"
        tags={["existing", "turbines"]}
        id="existing-turbines"
        icon={<SingleTurbine />}
        onSelect={() => {
          setLeftModalMenuOpen(
            replaceOrUndefined(LeftModalMenuTypes.ExistingTurbines),
          );
          Mixpanel.track_old(
            `Existing turbines ${
              leftModalMenuOpen === LeftModalMenuTypes.ExistingTurbines
                ? "closed"
                : "opened"
            }`,
            {
              projectId,
              branchId,
            },
          );
        }}
      >
        <SideBarIconBtn
          id="button-existing-turbines"
          active={leftModalMenuOpen === LeftModalMenuTypes.ExistingTurbines}
          onClick={() => {
            setLeftModalMenuOpen(
              replaceOrUndefined(LeftModalMenuTypes.ExistingTurbines),
            );
            Mixpanel.track_old(
              `Existing turbines ${
                leftModalMenuOpen === LeftModalMenuTypes.ExistingTurbines
                  ? "closed"
                  : "opened"
              }`,
              {
                projectId,
                branchId,
              },
            );
          }}
          icon={<SingleTurbine />}
        >
          <p style={typography.graphics}>Turbines</p>
        </SideBarIconBtn>
      </SearchWrapper>
      {projectId && branchId && (
        <>
          <Divider />
          <SearchWrapper
            title="Style features"
            tags={["style", "color", "pretty"]}
            id="style-features-list"
            icon={<StylingIcon />}
            onSelect={() => {
              setLeftModalMenuOpen(
                replaceOrUndefined(LeftModalMenuTypes.Styling),
              );
              Mixpanel.track_old(
                `Styling ${leftModalMenuOpen === LeftModalMenuTypes.Styling ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          >
            <SideBarIconBtn
              style={{
                position: "relative",
              }}
              id="button-style-features-list"
              icon={<StylingIcon />}
              onClick={() => {
                setLeftModalMenuOpen(
                  replaceOrUndefined(LeftModalMenuTypes.Styling),
                );
                Mixpanel.track_old(
                  `Styling ${leftModalMenuOpen === LeftModalMenuTypes.Styling ? "closed" : "opened"}`,
                  {
                    projectId,
                    branchId,
                  },
                );
              }}
              active={leftModalMenuOpen === LeftModalMenuTypes.Styling}
            >
              <MarkerWrapper active={hasStyling} />
              <p style={typography.graphics}>Styling</p>
            </SideBarIconBtn>
          </SearchWrapper>
        </>
      )}
      {projectId && branchId && (
        <>
          <SearchWrapper
            title="View all comments"
            tags={["people", "users", "communication", "message"]}
            id="search-comments-list"
            icon={<Comments />}
            onSelect={() => {
              setLeftModalMenuOpen(
                replaceOrUndefined(LeftModalMenuTypes.Comment),
              );
              Mixpanel.track_old(
                `Comments ${leftModalMenuOpen === LeftModalMenuTypes.Comment ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          >
            <SideBarIconBtn
              style={{
                position: "relative",
              }}
              id="button-comments-list"
              icon={<Comments />}
              onClick={() => {
                setLeftModalMenuOpen(
                  replaceOrUndefined(LeftModalMenuTypes.Comment),
                );
                Mixpanel.track_old(
                  `Comments ${leftModalMenuOpen === LeftModalMenuTypes.Comment ? "closed" : "opened"}`,
                  {
                    projectId,
                    branchId,
                  },
                );
              }}
              active={leftModalMenuOpen === LeftModalMenuTypes.Comment}
            >
              <p
                style={{
                  ...typography.graphics,
                }}
              >
                Comments
              </p>
              {allCommentsLoadable.state === "hasData" && hasUnreadComments && (
                <UnreadCommentDot
                  style={{
                    position: "absolute",
                    top: "0.1rem",
                    right: "1rem",
                  }}
                />
              )}
            </SideBarIconBtn>
          </SearchWrapper>
        </>
      )}
    </IconMenu>
  );
};

const LowerLeftToolsMenuV2 = ErrorBoundaryWrapper(
  () => {
    return (
      <LowerLeftColumnWrapperV2 id="lower-left">
        <LowerLeftTools />
        <CommentsLeftModal />
        <ProjectElementsV2 />
        <LayerListV2 />
        <ExistingTurbinesMenu />
        <UndoRedoButtonsV2 />
        <StylingPanelPositioning />
      </LowerLeftColumnWrapperV2>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export default LowerLeftToolsMenuV2;
