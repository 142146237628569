import styled from "styled-components";
import Loader from "@icons/full-screen-loader.svg";
import { colors } from "styles/colors";

const Wrapper = styled.div`
  position: fixed;
  inset: 0 0 0 0;
  z-index: 10000;
  overflow: hidden;
  padding: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.grey50};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const FullScreenLoader = () => {
  return (
    <Wrapper id={"fullscreenloader"}>
      <Loader />
    </Wrapper>
  );
};

export default FullScreenLoader;
