import { useAtomValue } from "jotai";
import React, { useCallback } from "react";
import styled from "styled-components";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import SearchIcon from "@icons/24/Search.svg";
import Upload from "@icons/24/Upload.svg";
import Spinner from "@icons/spinner/Spinner";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../state/modal";
import { editorAccessProjectSelector } from "../../../state/user";
import { colors } from "../../../styles/colors";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { openInNewWindowAtom } from "components/WindowedHOC/state";
import { AddDataLayersModalType } from "../../DataLayersModal/DataLayersModal";
import Button from "../../General/Button";
import { UploadFileType, UploadModalType } from "../../UploadModal/UploadModal";
import { BottomButtonsContainer, SIDEBAR_PADDING } from "../LayerList.style";
import { useCollectionCrud } from "../Collections/useCollectionCrud";
import ProjectLayers from "./ProjectLayers";
import { useSetAtom } from "jotai";

const LayerListV2Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.primaryText};
  overflow: hidden;
  padding-left: ${SIDEBAR_PADDING};
  padding-right: 0;
`;

const LayerListPane = () => {
  const canEditProject = useAtomValue(editorAccessProjectSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setOpenInNewWindow = useSetAtom(openInNewWindowAtom);
  const { post, isCreatingNewCollection } = useCollectionCrud();
  const isLoadingLayers = false;

  const onUploadClick = useCallback(
    () =>
      setMidScreenModalTypeOpen({
        modalType: UploadModalType,
        metadata: {
          preSelectedFileType: UploadFileType.DATA_LAYER,
        },
      }),
    [setMidScreenModalTypeOpen],
  );

  const onBrowseLayersClick = useCallback(() => {
    setOpenInNewWindow((curr) => ({
      ...curr,
      [AddDataLayersModalType]: false,
    }));
    setModalTypeOpen({
      modalType: AddDataLayersModalType,
    });
  }, [setModalTypeOpen, setOpenInNewWindow]);

  const onFolderAddClick = useCallback(() => {
    return post({
      name: "New folder",
      layers: [],
      isDefault: false,
    });
  }, [post]);

  return (
    <>
      <LayerListV2Wrapper>
        <React.Suspense
          fallback={
            <SkeletonBlock
              style={{
                gridRow: "1 / -1",
                margin: `${SIDEBAR_PADDING}`,
                width: `calc(100% - calc(${SIDEBAR_PADDING} * 3)`,
              }}
            />
          }
        >
          <ProjectLayers canEditProject={canEditProject} />
          <BottomButtonsContainer>
            <Button
              icon={
                isCreatingNewCollection ? (
                  <Spinner size="1rem" />
                ) : (
                  <FolderAddIcon />
                )
              }
              buttonType="secondary"
              onClick={onFolderAddClick}
              disabled={isCreatingNewCollection}
            />
            <Button
              icon={<Upload />}
              buttonType="secondary"
              text="Upload"
              onClick={onUploadClick}
            />
            <Button
              icon={isLoadingLayers ? <Spinner size="1rem" /> : <SearchIcon />}
              disabled={isLoadingLayers}
              title={
                isLoadingLayers ? "Loading GIS layers, please wait" : undefined
              }
              buttonType="primary"
              text="Browse layers"
              onClick={onBrowseLayersClick}
            />
          </BottomButtonsContainer>
        </React.Suspense>
      </LayerListV2Wrapper>
    </>
  );
};

export default LayerListPane;
