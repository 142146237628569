import { useSetAtom } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { useToast } from "../../../../hooks/useToast";
import CostIcon from "@icons/24/Cost.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import { savingConfigurationInProgressAtom } from "../../../../state/configuration";
import { scream } from "../../../../utils/sentry";
import {
  CostSettingsForLibraryConfig,
  CostSettingsInner,
} from "../../../ConfigurationModal/CostSettings";
import { selectedMenuItemState } from "../../Shared/state";
import {
  CostConfiguration,
  createConfiguration,
  deleteConfiguration,
  _CostConfigurationInput,
  createDefaultNodeFinancialConfiguration,
} from "../../../../services/costService";
import useCostConfigurationCrud from "../../../../hooks/useCostConfigurationCrud";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../../state/modal";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { IconREMSize } from "styles/typography";
import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import {
  financialResourceWithAccessOnNodeState,
  fromProjectToLibraryTabState,
} from "components/Organisation/Library/state";
import {
  editorAccessProjectSelector,
  orgFinanicalManageAccessSelector,
} from "state/user";
import { useLocation, useNavigate } from "react-router-dom";
import useNodeFinancialResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFinancialResourcesCrud";
import Button from "components/General/Button";
import { Anchor } from "components/General/Anchor";
import FinancialModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/FinancialModal";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useAtom, useAtomValue } from "jotai";
import {
  libraryCostConfigurationsFamily,
  projectCostConfigurationsFamily,
} from "state/jotai/costConfiguration";
import { firstValueInMap } from "utils/utils";
import { unwrap } from "jotai/utils";
import { designToolTypeAtom } from "state/map";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import SearchIcon from "@icons/24/Search.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { useJotaiCallback } from "utils/jotai";
import { fetchProjectResourceUsage } from "services/usageService";
import { useDuplicateCostToProject } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToProject";
import { useDuplicateCostToLibrary } from "components/SettingsV2/FeatureSettings/Data/useDuplicateToLibrary";

export const COST_MENU_ID = "cost";

export default function useCostConfiguration() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const projectType = useAtomValue(designToolTypeAtom);

  const node = useAtomValue(
    getNodeSelectorFamily({ organisationId, nodeId: projectId }),
  );
  const project_type = node?.project_type;
  const { error: showErrorToast } = useToast();
  const projectConfigurations = useAtomValue(
    unwrap(projectCostConfigurationsFamily({ projectId })),
  );
  const libraryConfigurations = useAtomValue(
    unwrap(
      libraryCostConfigurationsFamily({
        projectId,
      }),
    ),
  );
  const [isAutoSaving, setIsAutoSaving] = useAtom(
    savingConfigurationInProgressAtom,
  );
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: COST_MENU_ID,
      projectId,
    }),
  );

  const [isProjectCostCollapsed, setIsProjectCostCollapsed] =
    useState<boolean>(false);
  const [isLibraryCostCollapsed, setIsLibraryCostCollapsed] =
    useState<boolean>(false);

  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const {
    save: saveProjectConfig,
    updateLocal,
    deleteLocal,
  } = useCostConfigurationCrud();

  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const navigate = useNavigate();
  const location = useLocation();
  const { duplicateToProject } = useDuplicateCostToProject();
  const { duplicateToLibrary } = useDuplicateCostToLibrary();

  useEffect(() => {
    if (projectConfigurations && !menuSelection) {
      const firstItem = firstValueInMap(projectConfigurations);
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [projectConfigurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "cost" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsAutoSaving(true);
    setSaveInProgress(true);
    const newConfig = await createDefaultNodeFinancialConfiguration(
      projectId,
      projectType,
    );

    updateLocal(newConfig);
    setMenuSelection(newConfig.id);
    setIsAutoSaving(false);
    setSaveInProgress(false);
  }, [projectId, setIsAutoSaving, setMenuSelection, updateLocal, projectType]);

  const _delete = useJotaiCallback(
    async (get, set, id: string) => {
      if (!projectId) return;
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "COST_CONFIGURATION",
          resourceId: id,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "COST_CONFIGURATION",
          id,
        );
      }
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        setIsAutoSaving(true);
        setSaveInProgress(true);
        try {
          const res = await deleteConfiguration(projectId, id);
          if ([204, 409].includes(res.status)) {
            deleteLocal(id);
          } else {
            const body = await res.text();
            throw new Error(body);
          }
        } catch (error) {
          if (error instanceof Error)
            showErrorToast(error.message, {
              timeout: 4000,
            });
        } finally {
          setSaveInProgress(false);
          setIsAutoSaving(false);
        }
      }
    },
    [projectId, deleteLocal, setIsAutoSaving, showErrorToast],
  );

  const _duplicate = useCallback(
    async (config: CostConfiguration) => {
      if (!projectId) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "cost",
          defaultName: `${config.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const clonedConfig = _CostConfigurationInput.parse({
              ...config,
              name: name,
              capex: {
                ...config.capex,
                custom: config.capex.custom.map((entry) => ({
                  ...entry,
                  id: uuid(),
                })),
              },
              opex: {
                ...config.opex,
                custom: config.opex.custom.map((entry) => ({
                  ...entry,
                  id: uuid(),
                })),
              },
            });

            setIsAutoSaving(true);
            setSaveInProgress(true);
            try {
              const newConfig = await createConfiguration(
                projectId,
                clonedConfig,
              );

              updateLocal(newConfig);
              setMenuSelection(newConfig.id);
            } catch (error) {
              if (error instanceof Error)
                scream(error, {
                  config: clonedConfig,
                });

              showErrorToast("Something went wrong when duplicating config", {
                timeout: 4000,
              });
            } finally {
              setIsAutoSaving(false);
              setSaveInProgress(false);
            }
          },
        },
      });
    },
    [
      projectId,
      setIsAutoSaving,
      setMenuSelection,
      showErrorToast,
      updateLocal,
      setMidScreenModalTypeOpen,
    ],
  );

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const loadedProjectConfigurations = projectConfigurations
      ? Array.from(projectConfigurations.values())
      : [];
    const projectConfigurationList = loadedProjectConfigurations
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((c) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (param: any) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Delete",
            onSelect: _delete,
            icon: <DeleteIcon />,
          });
          dotMenuOptions.push({
            title: "Duplicate",
            onSelect: () => _duplicate(c),
            icon: <DuplicateIcon />,
          });
        }
        if (orgFinancialManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => duplicateToLibrary(projectId, c),
            icon: <DuplicateIcon />,
          });
        }
        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;
        return {
          id: c.id,
          label: c.name ?? "Default",
          loading: isAutoSaving,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <CostSettingsInner
                key={c.id}
                save={saveProjectConfig}
                configuration={c}
                readOnly={!editorAccessProject}
                nodeId={projectId}
                isLibraryResource={false}
              />
            </div>
          ),
          onDuplicate: undefined,
          onChangeName: async (newName: string) => {
            setIsAutoSaving(true);
            try {
              await saveProjectConfig({
                ...c,
                name: newName,
              });
            } catch (err) {
              showErrorToast("Could not save configuration, please try again", {
                timeout: 4000,
              });
            } finally {
              setIsAutoSaving(false);
            }
          },
          dotMenuOptions,
        };
      });

    const loadedLibraryConfigurations = libraryConfigurations
      ? Array.from(libraryConfigurations.values())
      : [];
    const libraryConfigurationList = loadedLibraryConfigurations
      .filter(
        (c) =>
          !project_type ||
          (project_type === "offshore" && c.type === "offshore") ||
          (project_type === "onshore" && c.type === "onshore"),
      )
      .map((c) => {
        let dotMenuOptions:
          | {
              title: string;
              onSelect: (id: string) => void;
              icon: ReactElement;
            }[]
          | undefined = [];

        if (orgFinancialManageAccess) {
          dotMenuOptions.push({
            title: "Edit Library configuration",
            onSelect: () => {
              setContent({
                type: "resource",
                id: c.id,
              });
              setFromProjectToLibraryTab(true);

              let searchParams = new URLSearchParams(location.search);
              let newSearchString = searchParams.toString();

              navigate(
                `/organisation/${organisationId}/library/financial?${newSearchString}`,
              );
            },
            icon: <ArrowTopRightIcon />,
          });
        }
        if (editorAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => duplicateToProject(c),
            icon: <DuplicateIcon />,
          });
        }
        if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

        return {
          id: c.id,
          label: c.name ?? "Default configuration",
          loading: isAutoSaving,
          content: (
            <div
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <CostSettingsForLibraryConfig configuration={c} />
            </div>
          ),
          onChangeName: () => {},
          dotMenuOptions,
        };
      });

    const libraryFinancialMenu: SettingsSubMenuProp = {
      title: "Library configs",
      items: libraryConfigurationList,
      isCollapsed: isLibraryCostCollapsed,
      loading: libraryConfigurations == null,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryCostCollapsed}
            onClick={() => setIsLibraryCostCollapsed(!isLibraryCostCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectFinancialMenu: SettingsSubMenuProp = {
      title: "Project specific configs",
      items: projectConfigurationList,
      isCollapsed: isProjectCostCollapsed,
      loading: projectConfigurations == null,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectCostCollapsed}
            onClick={() => setIsProjectCostCollapsed(!isProjectCostCollapsed)}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    return [libraryFinancialMenu, projectFinancialMenu];
  }, [
    projectConfigurations,
    isProjectCostCollapsed,
    libraryConfigurations,
    setFromProjectToLibraryTab,
    isLibraryCostCollapsed,
    projectId,
    isAutoSaving,
    editorAccessProject,
    orgFinancialManageAccess,
    saveProjectConfig,
    _delete,
    _duplicate,
    setIsAutoSaving,
    showErrorToast,
    setContent,
    location.search,
    navigate,
    organisationId,
    project_type,
    duplicateToProject,
    duplicateToLibrary,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: <AddFinancialResourceToNode nodeId={projectId} />,
    };
  }, [projectId, saveInProgress]);

  const createNewCostConfiguration = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <Button
          disabled={!editorAccessProject || saveInProgress}
          onClick={(e) => {
            e.stopPropagation();
            _create();
          }}
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [saveInProgress, editorAccessProject, _create]);

  const costConfiguration = useMemo(() => {
    return {
      id: COST_MENU_ID,
      label: "Financial",
      icon: <CostIcon />,
      submenus: configSubMenus,
      createNew: createNewCostConfiguration,
      addFromLibrary: addFromLibrary,
    };
  }, [configSubMenus, createNewCostConfiguration, addFromLibrary]);

  return costConfiguration;
}

function AddFinancialResourceToNode({ nodeId }: { nodeId: string }) {
  const financialResources = useAtomValue(
    financialResourceWithAccessOnNodeState({
      nodeId,
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateConfig } = useNodeFinancialResourcesCrud();
  const [showAddFinancial, setShowAddFinancial] = useState(false);
  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );

  if (!orgFinancialManageAccess) return <></>;

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddFinancial(true)}
        icon={<SearchIcon />}
      />
      {showAddFinancial && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <FinancialModal
            onSave={async (config) => {
              await Promise.all(
                config.map((c) => addOrUpdateConfig(nodeId, c)),
              );

              setShowAddFinancial(false);
            }}
            existingFinancialConfig={financialResources.map((c) => c.config.id)}
          />
        </Anchor>
      )}
    </div>
  );
}
