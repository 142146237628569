import { atom } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { dedup } from "utils/utils";
import { branchMetaFamily } from "./branch";
import {
  CostConfiguration,
  listCostConfigurations,
} from "services/costService";
import { RESET } from "jotai/utils";
import { financialResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import { isOnshoreAtom } from "state/onshore";

/**
 * Re-fetch cost configurations for both project and library.
 */
export const costConfigurationsRefetch = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom(null, (_, set) => {
      set(projectCostConfigurationsFamily({ projectId }), RESET);
      set(libraryCostConfigurationsFamily({ projectId }), RESET);
    }),
);

export const projectCostConfigurationsFamily = atomFamily(
  (input: { projectId: string }) =>
    atomFromFn<Promise<Map<string, CostConfiguration>>>(async (_) => {
      const projectId = input.projectId;
      if (!projectId)
        throw new Error("costConfigurations require projectIdAtom");
      const { items } = await listCostConfigurations(projectId);
      return new Map(items.map((c) => [c.id, c]));
    }),
);

export const libraryCostConfigurationsFamily = atomFamily(
  (input: { projectId: string }) =>
    atomFromFn<Promise<Map<string, CostConfiguration>>>(async (get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      const isOnshore = get(isOnshoreAtom);
      if (!projectId)
        throw new Error("costConfigurations require projectIdAtom");
      const cost = await get(
        financialResourceWithAccessOnNodeState({ nodeId: projectId }),
      );
      const filteredCost = cost.filter(
        (c) => c.config.type === (isOnshore ? "onshore" : "offshore"),
      );
      const unique = dedup(filteredCost, (a) => a.config.id);
      return new Map(unique.map(({ config }) => [config.id, config]));
    }),
);

export const costConfigurationsFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom(async (get) => {
      const [proj, lib] = await Promise.all([
        get(projectCostConfigurationsFamily({ projectId })),
        get(libraryCostConfigurationsFamily({ projectId })),
      ]);
      return new Map([...proj, ...lib]);
    }),
);

export const costConfigurationSelectedFamily = atomFamily(
  (input: { branchId: string | undefined; projectId: string }) =>
    atom(async (get) => {
      const { projectId } = input;
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId)
        throw new Error(
          "costConfigurationSelectedFamily requires branchIdAtom",
        );
      const [branch, configs] = await Promise.all([
        get(branchMetaFamily({ branchId, projectId })),
        get(costConfigurationsFamily({ projectId })),
      ]);
      return configs.get(branch?.costConfigurationId ?? "");
    }),
);
