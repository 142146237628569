import { z } from "zod";

export enum WindDataSource {
  ERA5 = "era5",
  NORA3 = "nora3",
  CUSTOM = "custom",
  CERRA = "cerra",
  BEST = "best_available",
}

export enum MesoWindDataSource {
  GWA = "gwa",
  NEWA = "newa",
}

export enum WindSpeedCalibrationType {
  PERCENT = "percent",
}

export enum SpatialCalibrationType {
  GWA = "global_wind_atlas",
  NEWA = "new_european_wind_atlas",
  CUSTOM = "custom",
}

export const spatialCalibrationName = {
  [SpatialCalibrationType.GWA]: "Global Wind Atlas",
  [SpatialCalibrationType.NEWA]: "New European Wind Atlas",
  [SpatialCalibrationType.CUSTOM]: "Custom",
};

export enum CustomWindFileType {
  TIMESERIES = "timeseries",
  MEAN_SPEED_GRID = "meanSpeedGrid",
  WRG = "wrg",
}

export const _WindSource = z.object({
  id: z.union([
    z.literal(WindDataSource.ERA5),
    z.literal(WindDataSource.NORA3),
    z.literal(WindDataSource.CUSTOM),
    z.literal(WindDataSource.CERRA),
  ]),
  name: z.string(),
});

export const _CustomWindSource = z.object({
  id: z.string(),
  name: z.string(),
});

export const _WindDataset = z.object({
  source: _WindSource,
  calibration: z.any().optional(), //TODO: does not work when using string
});

export type WindDataset = z.infer<typeof _WindDataset>;

const _PercentCalibration = z.string().regex(new RegExp(/^-?\d*\.?\d+%$/));
