import React from "react";
import TableIcon from "@icons/24/Table.svg";
import ArrowTopRight from "@icons/24/ArrowTopRight.svg";
import { activeProcurementCostIdAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { orgFinanicalManageAccessSelector } from "state/user";
import { Tag } from "components/General/Tag";
import Tooltip from "components/General/Tooltip";
import { useAtomValue, useSetAtom } from "jotai";
import styled from "styled-components";
import { colors } from "styles/colors";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { libraryAllSelectorFamily } from "state/featureAccess";
import { InputDimensioned } from "components/General/Input";
import { CostUnit } from "types/financial";
import { calculateTurbineDefaultCosts } from "utils/turbineCost";
import { organisationIdAtom } from "state/pathParams";
import { TurbineType } from "types/turbines";
import {
  FeatureSettingsModalTypeV2,
  ProjectConfigModalTypeV2,
} from "state/configuration";
import { COST_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { midScreenModalTypeOpenAtom, modalTypeOpenAtom } from "state/modal";
import { CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useCableSettings";
import { ProcurementCostComponentType } from "services/procurementCostService";
import { ProcurementModalType } from "components/Organisation/Library/financial/ProcurementCosts/ProcurementCostsModal";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useNavigate } from "react-router-dom";
import { SUBSTATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/Substation/useSubstationSettings";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const IconStyler = styled.div<{
  disabled?: boolean;
  active?: boolean;
  $stroke?: boolean;
}>`
  display: flex;

  ${(p) => {
    if (p.disabled) return `cursor: not-allowed;`;
    else return `cursor: pointer;`;
  }};

  svg {
    width: 1rem;
    height: 1rem;

    path,
    line {
      ${(p) => {
        if (p.disabled) return `fill: ${colors.iconDisabled};`;
        return `fill: ${colors.iconSubtle};`;
      }}
    }
  }
`;

export const GoToProcurementTableArrowIcon = ({
  activeProcurementType,
}: {
  activeProcurementType?: ProcurementCostComponentType;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setActiveComponentId = useSetAtom(activeProcurementCostIdAtom);
  const unsavedSettings = useAtomValue(unsavedSettingsState);
  const { showConfirm } = useConfirm();

  return (
    <IconStyler
      disabled={!orgFinancialManageAccess}
      onClick={async () => {
        if (!orgFinancialManageAccess) {
          return;
        }
        if (
          !unsavedSettings ||
          (await showConfirm({
            title: "Unsaved settings",
            message: "Are you sure you want to quit without saving?",
          }))
        ) {
          if (activeProcurementType) {
            setActiveComponentId(activeProcurementType);
          }

          setModalTypeOpen((curr) => ({
            modalType: ProcurementModalType,
            backTo: curr,
          }));
          setMidScreenModalTypeOpen(undefined);
        }
      }}
    >
      <Tooltip
        text={
          orgFinancialManageAccess
            ? "Go to Procurement table"
            : "Library financial access required"
        }
      >
        <ArrowTopRight />
      </Tooltip>
    </IconStyler>
  );
};

const FromProcurementTableCostElement = ({
  activeProcurementType,
}: {
  activeProcurementType?: ProcurementCostComponentType;
}) => {
  return (
    <>
      <Tag text={"From procurement table"} icon={<TableIcon />} />
      <GoToProcurementTableArrowIcon
        activeProcurementType={activeProcurementType}
      />
      <HelpTooltip
        style={{
          display: "inline-flex",
        }}
        text="Unit cost is moved to Procurement table in Financial configuration in Library"
        size={10}
      />
    </>
  );
};

const DefaultTurbineCostElement = ({
  turbineType,
}: {
  turbineType: TurbineType;
}) => {
  if (!turbineType.ratedPower) {
    console.log("Missing rated power on turbine");
    return null;
  }
  return (
    <>
      <InputDimensioned
        disabled={true}
        value={calculateTurbineDefaultCosts({
          diameter: turbineType.diameter,
          ratedPower: turbineType.ratedPower,
          hubHeight: turbineType.hubHeight,
        })}
        unit={CostUnit.millionEuroPerUnit}
      />
      <HelpTooltip
        style={{
          display: "inline-flex",
        }}
        text="Turbine cost is moved to fincancial configuration in Library. To edit cost without this library access, choose the override option in financial configuration within each project."
        size={10}
      />
    </>
  );
};

export const TurbineCostElement = ({
  turbineType,
}: {
  turbineType: TurbineType;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);

  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );

  if (!organisationId) return null;
  if (allLibraryAccess) return <FromProcurementTableCostElement />;
  return <DefaultTurbineCostElement turbineType={turbineType} />;
};

export const GenericFromTableOrCostInputElement = ({
  defaultInput,
  activeProcurementType,
}: {
  defaultInput: React.ReactNode;
  activeProcurementType?: ProcurementCostComponentType;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const allLibraryAccess = useAtomValue(
    libraryAllSelectorFamily({
      organisationId,
    }),
  );

  if (!organisationId) return null;

  if (allLibraryAccess)
    return (
      <FromProcurementTableCostElement
        activeProcurementType={activeProcurementType}
      />
    );

  return defaultInput;
};

export const RedirectToProjectFinancialConfig = ({
  menuId,
}: {
  menuId:
    | typeof TURBINE_MENU_ID
    | typeof CABLE_MENU_ID
    | typeof EXPORT_CABLE_MENU_ID
    | typeof SUBSTATION_MENU_ID;
}) => {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  return (
    <>
      {menuId !== SUBSTATION_MENU_ID && (
        <Tag text={"From procurement table"} icon={<TableIcon />} />
      )}
      <IconStyler
        onClick={() => {
          setModalType({
            modalType: ProjectConfigModalTypeV2,
            metadata: {
              selectedMenuId: COST_MENU_ID,
            },
            backTo: {
              modalType: FeatureSettingsModalTypeV2,
              metadata: {
                selectedMenuId: menuId,
              },
            },
          });
        }}
      >
        <Tooltip text={"Go to financial configuration"}>
          <ArrowTopRight />
        </Tooltip>
      </IconStyler>
      <HelpTooltip
        style={{
          display: "inline-flex",
        }}
        text="Component cost is now embedded within Financial configuration."
        size={10}
      />
    </>
  );
};

export const RedirectToLibraryFinancialConfig = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const navigate = useNavigate();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  return (
    <>
      <IconStyler
        onClick={() => {
          setContent(undefined);
          navigate(`/organisation/${organisationId}/library/financial`);
        }}
      >
        <Tooltip text={"Go to financial configuration"}>
          <ArrowTopRight />
        </Tooltip>
      </IconStyler>
      <HelpTooltip
        style={{
          display: "inline-flex",
        }}
        text="Component cost is now embedded within financial configuration."
        size={10}
      />
    </>
  );
};
