import { Anchor } from "components/General/Anchor";
import Button from "components/General/Button";
import useNodeSubstationResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeSubstationResourcesCrud";
import SubstationModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/SubstationModal";
import { useAtomValue } from "jotai";
import { useRef, useState } from "react";
import { substationResourceWithAccessOnNodeState } from "state/substationType";
import { orgSubstationManageAccessSelector } from "state/user";
import SearchIcon from "@icons/24/Search.svg?react";

export function AddSubstationResourceToNode({ nodeId }: { nodeId: string }) {
  const substationResources = useAtomValue(
    substationResourceWithAccessOnNodeState({
      nodeId,
    }),
  );

  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateSubstation } =
    useNodeSubstationResourcesCrud();
  const [showAddSubstation, setShowAddSubstation] = useState(false);
  const orgSubstationManageAccess = useAtomValue(
    orgSubstationManageAccessSelector,
  );
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddSubstation(true)}
        icon={<SearchIcon />}
        disabled={!orgSubstationManageAccess}
      />
      {showAddSubstation && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <SubstationModal
            onSave={async (substations) => {
              setSaveInProgress(true);
              await Promise.all(
                substations.map((s) => addOrUpdateSubstation(nodeId, s.id)),
              );
              setSaveInProgress(false);
              setShowAddSubstation(false);
            }}
            existingSubstations={substationResources.map(
              (s) => s.substation.id,
            )}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
