import { ContentTableRow } from "components/Organisation/OrganisationRightSide/style";
import useOrgFolderStructureCrud from "hooks/useOrgFolderStructureCrud";
import { FolderTreeItem } from "types/folderStructures";
import { DotMenu } from "components/General/MenuButton";
import { useDrag, useDrop } from "react-dnd";
import { borderRadiusMedium } from "styles/space";
import { MenuItem } from "components/General/Menu";
import Trashcan from "@icons/24/Bin.svg?react";
import Tooltip from "components/General/Tooltip";
import styled from "styled-components";
import { colors } from "styles/colors";
import AddIcon from "@icons/24/Add.svg";
import FolderIcon from "@icons/24/Folder.svg";
import { LibraryManageRole } from "../types";
import SelectDrag from "@icons/24/SelectDrag.svg?react";
import { DragIconWrapper, RowWithIcon } from "../style";
import { Folder } from "./Folder";
import { getAcceptableTypes } from "./utils";
import { useRef, useState } from "react";
import EmptyFolderRow from "./EmptyFolderRow";

const FolderRowStyle = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  width: 100%;
  border-radius: ${borderRadiusMedium};
  background-color: ${(p) =>
    p.isDraggingOver ? colors.surfaceSelectedHover : undefined};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${colors.surfaceHover};
    ${DragIconWrapper} {
      opacity: 1;
    }
  }
  overflow: hidden;
`;

const FolderContentWrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusMedium};
  overflow: hidden;
`;

const FolderHeaderRow = styled.div`
  display: flex;
  width: 100%;
`;

interface FolderRowProps {
  folder: FolderTreeItem;
  onDropOnFolder: (folder: FolderTreeItem, turbineId: string) => void;
  onFolderDropOnFolder: (
    folder: FolderTreeItem,
    droppedFolder: FolderTreeItem,
  ) => void;
  isLegalFolderMove: (
    folder: FolderTreeItem,
    targetFolder: FolderTreeItem,
  ) => boolean;
  onCreateFolderInFolder: (folderId: string) => void;
  collapsed: boolean;
  isOpen?: boolean;
  onToggleOpen?: () => void;
  depth: number;
  libraryManageRole: LibraryManageRole;
  onCreateResourceInFolder: (folderId: string) => void;
}

const libraryManageRoleToText = (libraryManageRole: LibraryManageRole) => {
  switch (libraryManageRole) {
    case LibraryManageRole.TURBINE:
      return "Create new turbine";
    case LibraryManageRole.SUBSTATION:
      return "Create new substation";
    case LibraryManageRole.FOUNDATION:
      return "Create new foundation";
    case LibraryManageRole.CABLE:
      return "Create new cable";
    case LibraryManageRole.EXPORT_CABLE:
      return "Create new export cable";
    case LibraryManageRole.ANALYSIS:
      return "Create new analysis";
    case LibraryManageRole.FINANCIAL:
      return "Create new financial";
    case LibraryManageRole.DATA_PACKAGE:
      return "Create new GIS package";
    default:
      return "Create new";
  }
};

export const FolderRow = ({
  folder,
  onDropOnFolder,
  onFolderDropOnFolder,
  isLegalFolderMove,
  onCreateFolderInFolder,
  isOpen = true,
  onToggleOpen,
  collapsed,
  depth,
  libraryManageRole,
  onCreateResourceInFolder,
}: FolderRowProps) => {
  const { deleteFolder } = useOrgFolderStructureCrud({
    libraryManageRole,
  });

  const acceptableTypes = getAcceptableTypes(libraryManageRole);
  const [isDraggingOverEmptyFolder, setIsDraggingOverEmptyFolder] =
    useState(false);
  const [activeDotMenu, setActiveDotMenu] = useState(false);

  const [{ isDraggingOver }, dropRef] = useDrop(() => ({
    accept: acceptableTypes,
    collect: (monitor) => ({
      isDraggingOver: monitor.isOver() && monitor.canDrop(),
    }),
    drop: (item: any, monitor) => {
      if (monitor.didDrop()) {
        return; // Don't handle the drop if a child already handled it
      }

      if ("type" in item && item.type === "FOLDER") {
        onFolderDropOnFolder(folder, item);
      } else {
        onDropOnFolder(folder, item.id);
      }
    },
  }));

  const folderHasChildren = folder.children && folder.children.length > 0;

  const dragElementRef = useRef<HTMLDivElement>(null);

  const [, dragRef] = useDrag(
    () => ({
      type: getAcceptableTypes(libraryManageRole)[1],
      item: { ...folder, type: "FOLDER" },
      canDrag: true,
    }),
    [folder, libraryManageRole],
  );

  dragRef(dragElementRef);

  return (
    <>
      <RowWithIcon>
        <DragIconWrapper>
          <SelectDrag />
        </DragIconWrapper>
        <FolderContentWrapper ref={dropRef} isOpen={isOpen}>
          <FolderHeaderRow ref={dragElementRef}>
            <FolderRowStyle
              isDraggingOver={isDraggingOver || isDraggingOverEmptyFolder}
            >
              <Folder
                key={folder.id}
                folder={folder}
                onDropOnFolder={onDropOnFolder}
                onFolderDropOnFolder={onFolderDropOnFolder}
                depth={depth}
                isLegalFolderMove={isLegalFolderMove}
                showControls={true}
                shouldForceOpen={isOpen}
                onReorder={() => {}}
                onToggleOpen={onToggleOpen}
                libraryManageRole={libraryManageRole}
              />

              {!collapsed && (
                <>
                  <ContentTableRow style={{ flex: 1 }}>--</ContentTableRow>
                  <ContentTableRow style={{ flex: 1 }}>--</ContentTableRow>
                  {(libraryManageRole === LibraryManageRole.TURBINE ||
                    libraryManageRole === LibraryManageRole.FOUNDATION) && (
                    <ContentTableRow style={{ flex: 1 }}>--</ContentTableRow>
                  )}
                </>
              )}
              <ContentTableRow style={{ flex: 1, justifyContent: "flex-end" }}>
                <DotMenu
                  divStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  hoverBackgroundColor={colors.grey200}
                  isActive={activeDotMenu}
                  onChange={() => {
                    setActiveDotMenu(!activeDotMenu);
                  }}
                >
                  <MenuItem
                    name="Create new sub folder"
                    icon={<FolderIcon />}
                    onClick={() => onCreateFolderInFolder(folder.id)}
                  />
                  <MenuItem
                    name={libraryManageRoleToText(libraryManageRole)}
                    icon={<AddIcon />}
                    onClick={() => onCreateResourceInFolder(folder.id)}
                  />
                  <Tooltip
                    text={"Folder must be empty to delete"}
                    position="left"
                    disabled={!folderHasChildren}
                    innerDivStyle={{ width: "100%" }}
                  >
                    <MenuItem
                      name={"Delete"}
                      disabled={folderHasChildren}
                      onClick={() => deleteFolder(folder.id)}
                      icon={<Trashcan />}
                      style={{ width: "100%" }}
                    />
                  </Tooltip>
                </DotMenu>
              </ContentTableRow>
            </FolderRowStyle>
          </FolderHeaderRow>
        </FolderContentWrapper>
      </RowWithIcon>
      <>
        {folder.children.length === 0 && isOpen && (
          <EmptyFolderRow
            collapsed={collapsed}
            libraryManageRole={libraryManageRole}
            depth={depth + 1}
            onDropItem={onDropOnFolder}
            onDropFolder={onFolderDropOnFolder}
            folder={folder}
            setIsDraggingOverEmptyFolder={setIsDraggingOverEmptyFolder}
          />
        )}
      </>
    </>
  );
};
