import { Polygon } from "@turf/turf";
import { v4 as uuid } from "uuid";
import { pointInPolygon } from "../utils/geometry";
import * as turf from "@turf/turf";
import { clamp, splitDecimal } from "../utils/utils";
import { scream } from "../utils/sentry";
import { MercatorCoordinate } from "mapbox-gl";

type RasterType = {
  values: number[];
  width: number;
  height: number;
  minLon: number;
  stepLon: number;
  maxLat: number;
  stepLat: number;
};

/**
 * Compute the fraction that `lat` is from `minLat` to `maxLat` under the Mercator projection.
 * - If `lat === minLat` return 0.0.
 * - If `lat === maxLat` return 1.0.
 * - If `lat ~= (minLat + maxLat) / 2` return something *close* to 0.5, but
 *   probably not exactly 0.5, since that's not how Mercator works.
 */
function fractionInMercator(
  lat: number,
  minLat: number,
  maxLat: number,
): number {
  const y0 = MercatorCoordinate.fromLngLat([0, minLat]).y;
  const y1 = MercatorCoordinate.fromLngLat([0, maxLat]).y;
  const y = MercatorCoordinate.fromLngLat([0, lat]).y;
  const f = (y - y0) / (y1 - y0);
  return f;
}

export class Raster implements RasterType {
  id = uuid();

  constructor(
    public values: number[],
    public width: number,
    public height: number,
    public minLon: number,
    public maxLat: number,
    public stepLon: number,
    public stepLat: number,
  ) {}

  /**
   * Checks if the Raster contains the given point. Points on the edges are
   * considered to be contained.
   */
  contains(lon: number, lat: number): boolean {
    return (
      lon >= this.minLon &&
      lon <= this.minLon + this.stepLon * this.width &&
      lat <= this.maxLat &&
      lat >= this.maxLat - this.stepLat * this.height
    );
  }

  indexToLatLng(i: number): [number, number] {
    const x = i % this.width;
    const y = Math.floor(i / this.width);
    return this.coordsToLatLng(x, y);
  }

  bbox(): [number, number, number, number] {
    const { minLon, maxLat } = this;
    const maxLon = this.minLon + this.stepLon * this.width;
    const minLat = this.maxLat - this.stepLat * this.height;
    return [minLon, minLat, maxLon, maxLat];
  }

  coordsToLatLng(x: number, y: number): [number, number] {
    return [x * this.stepLon + this.minLon, y * -this.stepLat + this.maxLat];
  }

  latLngToValue(lon: number, lat: number, replaceNaN?: number): number {
    // NOTE: the naive math below doesn't work out if the sampled points are on the
    // edge of the raster.  If this is the case, just snap to the previous cell.
    let [x, lonF] = splitDecimal((lon - this.minLon) / this.stepLon);
    if (x === this.width && lonF < 0.01) {
      x = this.width - 1;
    }

    let latMercFrac = fractionInMercator(
      lat,
      this.maxLat - this.stepLat * this.height,
      this.maxLat,
    );
    // Pixel come in reading order (north-south, west-east), but mercator is
    // positive up, so 0.0 would be bottom. Flip.
    latMercFrac = 1.0 - latMercFrac;
    let [y, latF] = splitDecimal(latMercFrac * this.height);
    if (y === this.height && latF < 0.01) {
      y = this.height - 1;
    }

    let index = y * this.width + x;

    if (index < 0 || index >= this.values.length) {
      throw scream("latLngToValue: index is out-of-bounds", {
        index,
        length: this.values.length,
        x,
        y,
        width: this.width,
        height: this.height,
        lon,
        lat,
        minLon: this.minLon,
        maxLon: this.minLon + this.stepLon * this.width,
        maxLat: this.maxLat,
        minLat: this.maxLat - this.stepLat * this.height,
      });
    }
    const val = this.values[index];
    if (isNaN(val) && replaceNaN !== undefined) return replaceNaN;
    return val;
  }

  latLngToValue_bilinear(lon: number, lat: number): number {
    // https://en.wikipedia.org/wiki/Bilinear_interpolation
    /**
     * d01 ----------------- d11   lat1
     *  |      :              |
     *  | a01  :      a11     |    ^
     *  |      :              |    | lat
     *  |------o--------------|    |
     *  | a00  :      a10     |
     *  |      :              |
     * d00 ----------------- d10   lat0
     *
     * lon0    -- lon -->    lon1
     */

    const i0 = Math.min(
      this.width - 2,
      Math.floor((lon - this.minLon) / this.stepLon),
    );
    const lon0 = i0 * this.stepLon + this.minLon;
    const i1 = i0 + 1;
    const lon1 = i1 * this.stepLon + this.minLon;
    lon = clamp(lon0, lon, lon1);
    if (lon < lon0 || lon > lon1 || lon1 < lon0)
      throw scream("lon wrong", { lon, lon0, lon1 });

    // NOTE: we go from the top down on the indices here, so this looks a little weird.
    const j1 = Math.min(
      this.height - 2,
      Math.floor((this.maxLat - lat) / this.stepLat),
    );
    const lat1 = this.maxLat - j1 * this.stepLat;
    const j0 = j1 + 1;
    const lat0 = this.maxLat - j0 * this.stepLat;
    lat = clamp(lat0, lat, lat1);
    if (lat < lat0 || lat > lat1 || lat1 < lat0)
      throw scream("lat wrong", { lat, lat0, lat1 });

    const d00 = this.values[j0 * this.width + i0];
    const d10 = this.values[j0 * this.width + i1];
    const d01 = this.values[j1 * this.width + i0];
    const d11 = this.values[j1 * this.width + i1];

    // Each endpoint contribute proportionally to the area of the cell opposite to it.
    const totalArea = (lon1 - lon0) * (lat1 - lat0);
    const a00 = (lon - lon0) * (lat - lat0);
    const a10 = (lon1 - lon) * (lat - lat0);
    const a01 = (lon - lon0) * (lat1 - lat);
    const a11 = (lon1 - lon) * (lat1 - lat);

    const w00 = a11 / totalArea;
    const w01 = a10 / totalArea;
    const w10 = a01 / totalArea;
    const w11 = a00 / totalArea;
    const w_sum = w00 + w01 + w10 + w11;
    if (Math.abs(1 - w_sum) > 0.0001)
      scream("weights are wrong", { w00, w01, w10, w11, w_sum });

    return w00 * d00 + w01 * d01 + w10 * d10 + w11 * d11;
  }

  valuesInPolygon(polygon: Polygon): number[] {
    return this.values.reduce((acc: number[], e: number, i: number) => {
      const lngLat = this.indexToLatLng(i);
      const point = turf.point(lngLat);
      const contains = pointInPolygon(point.geometry, polygon);
      if (contains) {
        acc.push(e);
      }
      return acc;
    }, []);
  }
}
