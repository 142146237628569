import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { LayerMouseHandlingCallbacks } from "../hooks/mouseHandling";
import { SelectionType } from "../services/types";

export const currentSelectionArrayAtom = atom<string[]>([]);

export type WMSSelection = {
  type: string;
  url: string;
  legendGraphicUrl: string;
  html?: string;
};

export const currentSelectionWMSAtom = atom<WMSSelection[]>([]);

export const defaultMouseHandlerCallBackClickableFeature =
  atom<LayerMouseHandlingCallbacks>({});

type OtherUserSelection = {
  userId: string;
  selection: SelectionType[];
};

export const otherUsersSelectionArrayAtomFamily = atomFamily(
  (
    _:
      | {
          projectId: string;
          branchId: string;
          version?: number;
        }
      | undefined,
  ) => atom<OtherUserSelection[]>([]),
);
