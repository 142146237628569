import * as turf from "@turf/turf";
import { Point, Polygon, Position } from "geojson";
import { ProjectFeature } from "types/feature";
import { pointsAreEqual, projectPointToLine, sampleLine } from "utils/geometry";
import { scream, sendWarning } from "utils/sentry";
import { fastMax, fastMin } from "utils/utils";

export const bufferPointsForChainOrPartition = (
  fs: ProjectFeature<Point>[],
  buffer: number,
): Polygon => {
  let hull =
    fs.length === 1
      ? fs[0]
      : fs.length === 2
        ? turf.lineString([
            fs[0].geometry.coordinates,
            fs[1].geometry.coordinates,
          ])
        : turf.convex(turf.featureCollection<Point>(fs));
  if (!hull) {
    if (fs.length <= 2)
      throw sendWarning("Cannot buffer less than 2 points", { fs });
    // https://github.com/Turfjs/turf/issues/2449
    // Check that all points are colinear (common for regular grid turbines).
    // Then, find the endpoints and use the line string between them as the hull.
    const segment: [Position, Position] = [
      fs[0].geometry.coordinates,
      fs[1].geometry.coordinates,
    ];

    const factors = fs
      .map((p) => p.geometry.coordinates)
      .map((pt) => {
        const { point, factor } = projectPointToLine(pt, segment);
        if (!pointsAreEqual(point, pt))
          throw sendWarning("hull failed, but points aren't colinear", {
            fs,
            pt,
          });
        return factor;
      });
    const min = fastMin(factors);
    const max = fastMax(factors);

    const p = sampleLine(segment, min);
    const q = sampleLine(segment, max);
    hull = turf.lineString([p, q]);
  }

  if (!hull) throw sendWarning("hull is null", { fs, buffer });
  const buffered = turf.buffer(hull, buffer, {
    units: "meters",
  });
  if (!buffered) throw scream("failed to buffer hull", { hull, buffer });
  if (buffered.geometry.type === "MultiPolygon")
    throw sendWarning("Buffered convex hull cannot be a multipolygon", {
      buffered,
      hull,
    });
  return buffered.geometry;
};
