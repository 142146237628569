import * as Sentry from "@sentry/react";
import { SetterOrUpdater } from "types/utils";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { ToastMessage } from "../../../state/toast";
import {
  _OtherFeature,
  ProjectFeature,
  stripFeatureTypeSpecificFields,
} from "../../../types/feature";
import { ExternalSelectionItem } from "../../../state/externalLayerSelection";
import { findFeatureChildren } from "../../../state/projectLayers";
import { dedup } from "../../../utils/utils";
import { isPark, isFeature, isDefined } from "utils/predicates";
import { featureSupportsCopy } from "./utils";
import { LayerSettings } from "components/LayerList/LayerSettings/types";
import { maybeGetLayerId } from "utils/layer";

export const getPinnedPropertiesAsName = (
  allLayerSettings: LayerSettings[],
  selection: ExternalSelectionItem,
) => {
  const layerId = maybeGetLayerId(selection);
  const layerSetting = allLayerSettings.find((ls) => ls.id === layerId);
  const pinnedProperties = layerSetting?.layerStyle?.pinnedProperties;
  const nameProperties = pinnedProperties
    ? pinnedProperties
        .map((p) => (selection?.properties ?? {})[p])
        .filter(isDefined)
        .join(" ")
    : undefined;
  return nameProperties;
};

export const copy = async (
  currentSelectedFeatures: (ProjectFeature | MapboxGeoJSONFeature)[],
  externalLayerSelection: ExternalSelectionItem[],
  allProjectFeatures: ProjectFeature[],
  setToastMessagesAtom: SetterOrUpdater<ToastMessage[]>,
  allLayerSettings: LayerSettings[],
) => {
  const toastMessages: ToastMessage[] = [];
  const features = currentSelectedFeatures
    .concat(
      externalLayerSelection
        .map((f) => {
          const name = getPinnedPropertiesAsName(allLayerSettings, f);
          const paddName = name ? { name } : {};
          const parsed = _OtherFeature.safeParse({
            ...f,
            properties: {
              ...f.properties,
              type: undefined,
              parentIds: undefined,
              ...paddName,
            },
            id: String(f.id),
          });
          return parsed.success ? parsed.data : undefined;
        })
        .filter(isDefined)
        .map((parsed) => {
          return {
            ...parsed,
            properties: stripFeatureTypeSpecificFields(parsed),
          };
        }),
    )
    .filter(isFeature);

  const children = features.flatMap((selected) =>
    isPark(selected)
      ? findFeatureChildren(allProjectFeatures, String(selected.id))
      : [],
  );
  const allFeaturesWithTheirChildren = dedup(
    [...features, ...children],
    (f) => f.id,
  );

  const featuresToCopy = allFeaturesWithTheirChildren
    .filter(featureSupportsCopy)
    .map((feature) => feature);

  if (featuresToCopy.length < allFeaturesWithTheirChildren.length) {
    toastMessages.push({
      text: "Image copying is not supported",
      timeout: 2000,
    });
  }

  if (featuresToCopy.length > 0) {
    await window.navigator.clipboard
      .writeText(
        JSON.stringify({
          features: featuresToCopy,
          copiedFromVind: true,
        }),
      )
      .then(() => {
        Sentry.addBreadcrumb({
          category: "project",
          message: "Copied features to clipboard",
          data: {
            nrFeatures: featuresToCopy.length,
            nrExternalFeatures: externalLayerSelection.length,
          },
        });
        toastMessages.push({
          text: "Copied",
          timeout: 2000,
          type: "success",
          groupId: "copy/paste-copy",
        });
      })
      .catch((e) => {
        if (window.navigator.userAgent.includes("Firefox")) {
          toastMessages.push({
            text: `Failed to copy.\nFirefox does not support programatically writing to the clipboard.`,
            timeout: 5000,
            type: "warning",
            groupId: "copy/paste-copy",
          });
        } else if (e instanceof Error) {
          toastMessages.push({
            text: `Failed to copy: ${e.message}`,
            timeout: 5000,
            type: "error",
            groupId: "copy/paste-copy",
          });
        } else {
          console.log(e);
          throw e;
        }
      });
  }
  const groupIds = new Set(
    toastMessages.map((t) => t.groupId).filter(isDefined),
  );
  setToastMessagesAtom((tm) =>
    tm
      .filter((m) => !m.groupId || !groupIds.has(m.groupId))
      .concat(toastMessages),
  );
};
