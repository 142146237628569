import type { SortByFn } from "react-table";
import type { DataLibraryLayer } from "components/Organisation/Library/dataLibrary/types";
import { idToCustomLayerChangelogId } from "components/InputChangelog/const";
import { ChangelogEntry } from "components/InputChangelog/types";

export const getSortTypes = (
  getUsername: (userId: string) => string | undefined,
  getChangeLogForItem: (changelogId: string) => ChangelogEntry | undefined,
): Record<string, SortByFn<DataLibraryLayer>> => {
  return {
    lastChanged: (rowA, rowB) => {
      const changeLogItemA = getChangeLogForItem(
        idToCustomLayerChangelogId(rowA.original.id),
      );
      const changeLogItemB = getChangeLogForItem(
        idToCustomLayerChangelogId(rowB.original.id),
      );

      const versionA = changeLogItemA?.version;
      const versionB = changeLogItemB?.version;

      if (!versionA && !versionB) {
        return 0;
      }
      if (!versionA) {
        return -1;
      }
      if (!versionB) {
        return 1;
      }

      return versionA - versionB;
    },
    author: (rowA, rowB) => {
      const user1Name = getUsername(rowA.original.author);
      const user2Name = getUsername(rowB.original.author);

      if (!user1Name && !user2Name) {
        return 0;
      }
      if (!user1Name) {
        return 1;
      }
      if (!user2Name) {
        return -1;
      }
      return user1Name.localeCompare(user2Name);
    },
  };
};
