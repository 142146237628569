import { Label as _Label } from "components/General/Form";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { spaceTiny } from "../../../styles/space";
import { typography } from "../../../styles/typography";
import { ConfidenceLevel } from "types/financial";
import { getColorFromConfidenceLevel } from "finance/hooks/useCapex";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  gap: 1.6rem;
  width: 100rem;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 1.8rem;
  width: fill-available;
  max-width: 18.4rem;
  white-space: nowrap;
  border-radius: 0.4rem;
  color: ${colors.textBrand};
  ${typography.caption}

  gap: ${spaceTiny};
  padding: ${spaceTiny} 0.8rem;
  background: ${colors.surfaceDisabled};

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const DividerWrapper = styled.div`
  padding: 0 0 2rem 0;
`;

export const CostDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault}};
`;

export const ConfidenceLevelDot = styled.div<{
  confidenceLevel: ConfidenceLevel | undefined;
}>`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: ${(props) =>
    getColorFromConfidenceLevel(props.confidenceLevel)};
`;

export const GridWithFiveColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 3.2rem;
  align-items: center;
  padding: 0 0 2rem 0;
  & > :nth-child(5) {
    justify-self: end;
  }
  width: 100%;
`;

export const GridWithFiveSmallerColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 18rem) 15rem;
  gap: 3.2rem;
  align-items: center;
  padding: 0 0 2rem 0;
  & > :nth-child(5) {
    justify-self: end;
  }
`;

export const IconWrapper = styled.div`
  svg {
    height: 1.4rem;
    min-height: 1.4rem;
    width: 1.4rem;
    min-width: 1.4rem;

    path {
      stroke: ${colors.primaryText};
    }
  }
`;

export const Text = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const RowItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  label {
    display: flex;
    width: 100%;
  }

  input {
    flex-grow: 1;
    width: 100%;
  }
`;

export const Label = styled(_Label)`
  ${typography.contentAndButtons}
`;
