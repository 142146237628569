import { useAtomValue } from "jotai";
import { TableWrapper } from "../styles";
import { CableVessels } from "./CableVessels";
import { ExportCableVessels } from "./ExportCableVessels";
import { FoundationVessels } from "./FoundationsVessels";
import { MooringVessels } from "./MooringVessels";
import { SubstationVessels } from "./SubstationVessels";
import { TurbineVessels } from "./TurbineVessels";
import { localOperationsConfigurationAtom } from "../state";

export const TransportInstallationVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  return (
    <TableWrapper>
      <TurbineVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <CableVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <FoundationVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <MooringVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <SubstationVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <ExportCableVessels isReadOnly={isReadOnly} nodeId={nodeId} />
    </TableWrapper>
  );
};
