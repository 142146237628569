import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import CloseIcon from "@icons/24/Close.svg?react";
import { SVGWrapper } from "@icons/svgUtils";

const Container = styled.div`
  display: flex;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-radius: 0.4rem;
  background: ${colors.secondary};
  width: fit-content;
  height: fit-content;
`;

const Label = styled.p`
  ${typography.label}
  margin: 0;
`;

export default function SelectedLabel({
  title,
  onDeselect,
}: {
  title: string;
  onDeselect: () => void;
}) {
  return (
    <Container>
      <Label>{title}</Label>
      <SVGWrapper size={0.8} onClick={onDeselect} style={{ cursor: "pointer" }}>
        <CloseIcon />
      </SVGWrapper>
    </Container>
  );
}
