import { atom } from "jotai";
import { atomFamily, atomFromFn, atomLocalStorage } from "utils/jotai";
import { z } from "zod";
import { LAYER_URL_KEY } from "../const";
import { CustomLayer, Layer } from "../../../types/layers";
import { getAllLayersSelector } from "../../../state/layer";
import { customLayersMetadataSelectorAsync } from "../../../state/customLayers";
import { defaultLayerSettings } from "./const";
import { listLayerSettings } from "./service";
import { LayerSettings } from "./types";
import { visibleInUrl } from "../utils";
import { scream } from "../../../utils/sentry";
import { loggedInUserIdAtom } from "../../../state/user";
import { unwrap } from "jotai/utils";
import { isDefined } from "utils/predicates";

export enum SourceName {
  Original = "Original",
  English = "English",
}

const _SourceName = z.object({
  language: z.nativeEnum(SourceName),
});

const SELECTED_SOURCE_NAME_LS_KEY =
  "vind:selected-source-name-language" as const;

export const selectedLayersToAddAtom = atom<string[]>([]);
export const filteredLibraryDataLayersIdsAtom = atom<string[]>([]);
export const selectedSourceNameAtom = atomLocalStorage<{
  language: SourceName;
}>(SELECTED_SOURCE_NAME_LS_KEY, { language: SourceName.Original }, _SourceName);

export const allLayerSettingsAtomFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomFromFn<Promise<LayerSettings[]>>(async () => {
      if (!projectId) return [];
      return listLayerSettings(projectId).catch((e) => {
        scream(e);
        return [];
      });
    }),
);

const getLayerSettingsLocalStorageKey = (nodeId: string, userId: string) =>
  `vind:layer-visibility-${nodeId}-${userId}`;

/**
 * {@link atomLocalStorage} requires the key when initializing, so {@link
 * layerVisibleAtomFamily} can't use `userId` as part of it (since it's
 * dynamic). Instead, make a second atom family (this one), and have the other
 * family just be a wrapper.
 */
const __layerVisibleAtomFamily = atomFamily(
  ({ projectId, userId }: { projectId: string; userId: string }) =>
    atomLocalStorage(
      getLayerSettingsLocalStorageKey(projectId, userId),
      [],
      z.string().array(),
    ),
);

export const layerVisibleAtomFamily = atomFamily(
  ({ projectId, layerId }: { projectId: string; layerId: string }) =>
    atom<Promise<boolean>, [Promise<boolean>], void>(
      async (get) => {
        const userId = get(loggedInUserIdAtom);
        if (userId) {
          const ids = get(__layerVisibleAtomFamily({ projectId, userId }));
          return ids.includes(layerId);
        }

        const visibleFromSharedUrl = visibleInUrl(layerId, LAYER_URL_KEY);
        if (visibleFromSharedUrl) return true;

        const allLayersSettings = await get(
          allLayerSettingsAtomFamily({
            projectId,
          }),
        );
        const settingsForLayer = allLayersSettings.find(
          (l) => l.id === layerId,
        );
        const ret = settingsForLayer?.standard ?? false;
        return ret;
      },
      async (get, set, update) => {
        const newValue = await update;
        const userId = get(loggedInUserIdAtom);
        if (!userId) return;

        set(__layerVisibleAtomFamily({ projectId, userId }), (curr) =>
          newValue ? [...curr, layerId] : curr.filter((id) => id !== layerId),
        );
      },
    ),
);

export const layerSettingSelectorFamily = atomFamily(
  ({ projectId, layerId }: { projectId: string; layerId: string }) =>
    atom<Promise<LayerSettings>>(async (get) => {
      const visible = await get(
        layerVisibleAtomFamily({
          projectId,
          layerId,
        }),
      );
      const allLayerSettings = await get(
        allLayerSettingsAtomFamily({
          projectId,
        }),
      );
      const settingsForLayer = allLayerSettings.find((s) => s.id === layerId);
      return {
        ...(settingsForLayer ?? defaultLayerSettings(layerId)),
        visible,
      };
    }),
);

export const layersSettingSelectorFamily = atomFamily(
  ({ projectId, layerIds }: { projectId: string; layerIds: string[] }) =>
    atom<Promise<LayerSettings[]>>((get) =>
      Promise.all(
        layerIds.map((layerId) =>
          get(
            layerSettingSelectorFamily({
              projectId,
              layerId,
            }),
          ),
        ),
      ),
    ),
);

/** Get all layers as a list */
const getLayers = atomFamily(({ projectId }: { projectId: string }) =>
  atom<Promise<Layer[]>>(async (get) => {
    const gisDataLayers = await get(
      getAllLayersSelector({
        projectId,
      }),
    );
    const customLayers =
      get(
        unwrap(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      ) ?? [];
    const successLayers = gisDataLayers;
    return Object.values(successLayers).concat(customLayers).flat();
  }),
);

/** Get all layers as a `Map` from layer ID to the layer. */
export const getLayersMap = atomFamily(({ projectId }: { projectId: string }) =>
  atom<Promise<Map<string, Layer>>>(async (get) => {
    const layers = await get(
      getLayers({
        projectId,
      }),
    );
    return new Map(
      layers.map((layer) => {
        return [layer.id, layer];
      }),
    );
  }),
);

export const getSelectedLayers = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<Layer[]>>(async (get) => {
      const successLayers = await get(
        getAllLayersSelector({
          projectId,
        }),
      );

      const allLayerSettings = await get(
        allLayerSettingsAtomFamily({
          projectId,
        }),
      );
      return Object.values(successLayers)
        .flat()
        .filter((layer) => {
          const settingsForLayer = allLayerSettings.find(
            (s) => s.id === layer.id,
          );
          return settingsForLayer;
        });
    }),
);

export const visibleSelectedLayersSelectorFamily = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<Layer[]>>(async (get) => {
      const selectedLayers = await get(
        getSelectedLayers({
          projectId,
        }),
      );

      const visibleLayers = (
        await Promise.all(
          selectedLayers.map(async (layer) => {
            const layerId = layer.id;
            const settings = await get(
              layerSettingSelectorFamily({
                projectId,
                layerId,
              }),
            );
            // Don't enable layers that has been deleted by the source
            if (!Boolean(layer.dateDeleted) && Boolean(settings.visible))
              return layer;
          }),
        )
      ).filter(isDefined);

      return visibleLayers;
    }),
);

export const visibleCustomLayersSelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<CustomLayer[]>>(async (get) => {
      const customLayers = await get(
        customLayersMetadataSelectorAsync({
          nodeId: projectId,
        }),
      );

      const visibleCustomLayers = (
        await Promise.all(
          customLayers.map(async (layer) => {
            const layerId = layer.id;
            const settings = await get(
              layerSettingSelectorFamily({
                projectId,
                layerId,
              }),
            );
            if (settings.visible ?? false) return layer;
          }),
        )
      ).filter(isDefined);
      return visibleCustomLayers;
    }),
);
