import { LibraryManageRoleType } from "components/Organisation/Library/types";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import {
  fetchLibraryResourceUsage,
  fetchProjectResourceUsage,
  ResourceType,
  ResourceTypeUsage,
} from "services/usageService";
import { atomFromFn } from "utils/jotai";

const compareProjectInputs = (
  a: { nodeId: string; resourceType: string; resourceId: string },
  b: { nodeId: string; resourceType: string; resourceId: string },
) => {
  return (
    a.nodeId === b.nodeId &&
    a.resourceType === b.resourceType &&
    a.resourceId === b.resourceId
  );
};

export const projectResourceUsageRefresh = atomFamily(
  (_input: { nodeId: string; resourceType: string; resourceId: string }) =>
    atom<number>(0),
  compareProjectInputs,
);

export const projectResourceUsageAtomFamily = atomFamily(
  ({
    nodeId,
    resourceType,
    resourceId,
  }: {
    nodeId: string;
    resourceType: ResourceType;
    resourceId: string;
  }) =>
    atomFromFn<Promise<ResourceTypeUsage[]>>(async (get) => {
      if (!nodeId) return [];

      get(
        projectResourceUsageRefresh({
          nodeId,
          resourceType,
          resourceId,
        }),
      );

      return await fetchProjectResourceUsage(nodeId, resourceType, resourceId);
    }),
  compareProjectInputs,
);

const compareInputs = (
  a: { organisationId: string; libraryManageRole: string; resourceId: string },
  b: { organisationId: string; libraryManageRole: string; resourceId: string },
) => {
  return (
    a.organisationId === b.organisationId &&
    a.libraryManageRole === b.libraryManageRole &&
    a.resourceId === b.resourceId
  );
};

export const libraryResourceUsageRefresh = atomFamily(
  (_input: {
    organisationId: string;
    libraryManageRole: string;
    resourceId: string;
  }) => atom<number>(0),
  compareInputs,
);

export const libraryResourceUsageAtomFamily = atomFamily(
  ({
    organisationId,
    libraryManageRole,
    resourceId,
  }: {
    organisationId: string;
    libraryManageRole: Exclude<
      LibraryManageRoleType,
      "org_data_package_manage"
    >;
    resourceId: string;
  }) =>
    atomFromFn<Promise<ResourceTypeUsage[]>>(async (get) => {
      if (!organisationId) return [];

      get(
        libraryResourceUsageRefresh({
          organisationId,
          libraryManageRole,
          resourceId,
        }),
      );

      return await fetchLibraryResourceUsage(
        organisationId,
        libraryManageRole,
        resourceId,
      );
    }),
  compareInputs,
);
