import { ABLY_BATHYMETRY_INCLUDING_CUSTOM } from "../../state/ably";
import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../hooks/useAblyGeneric";
import {
  TileResponseError,
  TileResponseFinished,
  _TileResponseError,
  _TileResponseFinished,
} from "../../types/bathymetryTypes";
import { bathymetryAblyResponse } from "../../state/bathymetry";
import { scream, sendWarning } from "../../utils/sentry";
import { z } from "zod";
import { useJotaiCallback } from "utils/jotai";

const _Response = z.union([_TileResponseFinished, _TileResponseError]);

const noDataErrorCode = "nodata";

export const useAblyBathymetryListener = (projectNodeId: string) => {
  const channelName = useMemo(
    () => projectNodeId && `${projectNodeId}:all`,
    [projectNodeId],
  );

  const setResponse = useJotaiCallback(
    (_, set, response: TileResponseFinished | TileResponseError) => {
      set(bathymetryAblyResponse(response.id), Promise.resolve(response));
    },
    [],
  );

  const onUpdateBathymaretryIncludingCustom = useCallback(
    (message: InboundMessage) => {
      const data = message.data;
      const parse = _Response.safeParse(data);
      if (parse.success) {
        const { data } = parse;
        setResponse(data);
        if (data.status === "failed" && data.message !== noDataErrorCode) {
          sendWarning("Bathymetry response failed", { message: data.message });
        }
      } else {
        scream(parse.error, {
          data: data,
          error: parse.error,
          message: "Failed to parse tile response",
        });
      }
    },
    [setResponse],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_BATHYMETRY_INCLUDING_CUSTOM,
        onMessageReceived: onUpdateBathymaretryIncludingCustom,
      },
    ],
    [onUpdateBathymaretryIncludingCustom],
  );

  useAblyGeneric(channelName, events, projectNodeId);
};
