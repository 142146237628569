import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import {
  getProjectBranchCount,
  getUserProjectFollows,
} from "../services/projectDataAPIService";
import { ProjectFollow } from "../types/api";
import { branchIdAtom, projectIdAtom } from "./pathParams";

export const DISABLED_IN_READ_ONLY_MODE = "Disabled in read only mode";

export const savingNewProjectAtom = atom(false);

export const followProjectsAtom = atomFromFn<Promise<ProjectFollow[]>>(
  getUserProjectFollows,
);

export const projectVersionAtom = atomFamily(
  (_: { projectId: string | undefined; branchId: string | undefined }) =>
    atom<undefined | number>(undefined),
);

export const currentVersionSelector = atom<undefined | number>((get) => {
  const projectId = get(projectIdAtom);
  const branchId = get(branchIdAtom);
  return get(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
});

export const inReadOnlyModeSelector = atom((get) => {
  return !!get(currentVersionSelector);
});

export const projectBranchCountAtom = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomFromFn<Promise<number>>(() => getProjectBranchCount(nodeId)),
);
