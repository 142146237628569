import React, { Suspense, useCallback, useEffect } from "react";
import styled from "styled-components";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { createHorizontalStrength, useDndScrolling } from "react-dnd-scrolling";
import TurbineIcon from "@icons/24/WindTurbine.svg";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { Mixpanel } from "mixpanel";
import { SkeletonBlock } from "../../Loading/Skeleton";
import { useColumnTemplates } from "../columnTemplates";
import { projectIdAtom } from "state/pathParams";
import {
  CompareColumnItemAttribute,
  CompareColumnListItemAttribute,
  ComparisonAttributeKey,
} from "../types";
import {
  AttributeHeader,
  AttributeValue,
  ColumnTemplate,
  DropZone,
  ITEM_WIDTH,
  ParkComparisonViewWrapper,
  ParksWrapper,
} from "./ParkComparisonView.style";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { spacing2, spacing6, spacing9 } from "styles/space";
import { Row } from "components/General/Layout";
import { RoundIconWrapper } from "../shared";
import {
  _SelectedParkCompare,
  baselineComparisonValuesAtom,
  compareIsLoading,
  EnrichedSelectedParkCompare,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  visibleComparisonListRowsAtom,
} from "../state";
import ComparisonParkV2, {
  PARK_DRAG_TYPE,
} from "./ComparisonParkV2/ComparisonParkV2";
import { StickyHeader } from "./ComparisonParkV2/ComparisonParkV2.style";
import { UpdateCompareTestData } from "./UpdateCompareTestData";
import { ReferenceType } from "@floating-ui/react";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { useJotaiCallback } from "utils/jotai";
import { loggedInUserIsInternalAtom } from "state/jotai/user";
import { isOnshoreAtom } from "state/onshore";

const DropdownMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0.5rem 0.2rem;
  font-size: 1.2rem;
`;

const TooltipMessageIcon = ({ text }: { text: string }) => {
  return (
    <DropdownMessage className="hide-in-export">
      <HelpTooltip text={text} size={10} />
    </DropdownMessage>
  );
};

const AttributeHeaderFlattener = ({
  value,
  visibleIds,
}: {
  value: CompareColumnItemAttribute | CompareColumnListItemAttribute;
  visibleIds: string[] | undefined;
}) => {
  if (value.type === "list") {
    return (
      <>
        {value.values.map((v) => {
          if (Array.isArray(visibleIds) && !visibleIds.includes(v.key)) {
            return null;
          }

          return (
            <AttributeHeaderFlattener
              key={v.key}
              value={v}
              visibleIds={visibleIds}
            />
          );
        })}
      </>
    );
  }

  return (
    <AttributeValue
      style={{ justifyContent: "space-between" }}
      key={value.key}
      data-attribute-value={1}
    >
      <p>{value.name}</p>
      {value.infoMessage && <TooltipMessageIcon text={value.infoMessage} />}
      {value.tag && value.tag}
    </AttributeValue>
  );
};

const AttributesIndexesColumn = ({
  showRightShadow,
  selectedAttributes,
}: {
  showRightShadow: boolean;
  selectedAttributes: Record<ComparisonAttributeKey, string[]>;
}) => {
  const columnTemplates = useColumnTemplates();
  const isOnshore = useAtomValue(isOnshoreAtom);
  const visibleComparisonListRows = useAtomValue(visibleComparisonListRowsAtom);

  return (
    <ColumnTemplate
      data-park={1}
      showRightShadow={showRightShadow}
      style={{
        zIndex: 3,
        position: "sticky",
        left: 0,
        backgroundColor: "white",
        borderTop: "1px solid transparent",
      }}
      className="hide-scroll-in-export"
    >
      <StickyHeader>
        <div />
      </StickyHeader>
      <div />
      <AttributeHeader>
        <p style={typography.sub3}>Configurations</p>
      </AttributeHeader>
      <AttributeValue
        style={{ justifyContent: "space-between" }}
        data-attribute-value={1}
      >
        <p>Financial</p>
        <TooltipMessageIcon text="Changing this will not affect the selected configs for the park" />
      </AttributeValue>
      <AttributeValue
        style={{ justifyContent: "space-between" }}
        data-attribute-value={1}
      >
        <p>Analysis</p>
        <TooltipMessageIcon text="Changing this will not affect the selected configs for the park" />
      </AttributeValue>
      <AttributeValue
        style={{ justifyContent: "space-between" }}
        data-attribute-value={1}
      >
        <p>Wind</p>
        <TooltipMessageIcon text="Changing this will not affect the selected configs for the park" />
      </AttributeValue>
      {!isOnshore && (
        <AttributeValue
          style={{ justifyContent: "space-between" }}
          data-attribute-value={1}
        >
          <p>Operations</p>
          <TooltipMessageIcon text="Changing this will not affect the selected configs for the park" />
        </AttributeValue>
      )}
      <AttributeValue data-attribute-value={1}>
        <p>Analysis version</p>
      </AttributeValue>

      {Object.entries(columnTemplates).map(([attributeKey, columnTemplate]) => {
        const visibleAttributes = columnTemplate.attributes.filter((f) =>
          (selectedAttributes as any)[attributeKey]?.includes(f.key),
        );

        if (visibleAttributes.length === 0) {
          return null;
        }

        return (
          <div key={attributeKey}>
            <AttributeHeader>
              <p style={typography.sub3}>{columnTemplate.name}</p>
            </AttributeHeader>

            {visibleAttributes.map((value) => (
              <AttributeHeaderFlattener
                key={value.key}
                value={value}
                visibleIds={visibleComparisonListRows[value.key]}
              />
            ))}
          </div>
        );
      })}
    </ColumnTemplate>
  );
};

const ParkComparisonView = ({
  selectedParks,
  innerRef: _innerRef,
}: {
  selectedParks: Array<EnrichedSelectedParkCompare>;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const setSelectedParks = useSetAtom(selectedParksAtom({ projectId }));
  const selectedAttributes = useAtomValue(selectedComparisonAttributesAtom);
  const { scrollBodyRef, showRightShadow } = useShowScrollShadow();
  useDndScrolling(scrollBodyRef, {
    horizontalStrength: createHorizontalStrength(320),
  });
  // Subscribe to compareIsLoading to update asap when loading state changes
  useAtomValue(compareIsLoading);
  const resetVisibleComparisonListRows = useResetAtom(
    visibleComparisonListRowsAtom,
  );
  // Subscribe to baseline state to update asap when chosen baseline changes
  // We need to use useAtom here and ignore the value because we want to trigger
  // the resize effect on every change
  const [_baselineComparisonValues, setBaselineComparisonValues] = useAtom(
    baselineComparisonValuesAtom,
  );

  const onRemoveParkFromComparison = useJotaiCallback(
    async (
      get,
      set,
      parkId: string,
      branchId: string,
      comparisonId: string,
    ) => {
      Mixpanel.track_old(
        "Remove park from Compare park from Comparison view",
        {},
      );
      const atom = selectedParksAtom({ projectId });
      const currVal = await get(atom);
      set(
        atom,
        currVal.filter(
          (selectedPark) =>
            selectedPark.parkId !== parkId ||
            selectedPark.branchId !== branchId ||
            selectedPark.comparisonId !== comparisonId,
        ),
      );
      // Recalculate visible rows
      resetVisibleComparisonListRows();
    },
    [projectId, resetVisibleComparisonListRows],
  );

  const movePark = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedParks = [...selectedParks];
      const [movedPark] = updatedParks.splice(fromIndex, 1);
      updatedParks.splice(toIndex, 0, movedPark);
      setSelectedParks(_SelectedParkCompare.array().parse(updatedParks));
    },
    [selectedParks, setSelectedParks],
  );

  const [, dropRef] = useDrop(
    () => ({
      accept: PARK_DRAG_TYPE,
      drop: (_draggedItem: { index: number }, monitor: DropTargetMonitor) => {
        if (monitor.didDrop()) {
          return;
        }

        return {
          droppedOnWrapper: true,
        };
      },
    }),
    [],
  );

  // Make all attribute rows the same height whenever scrollBodyRef changes size
  useEffect(() => {
    if (!scrollBodyRef.current) {
      return;
    }

    const currentScrollBodyRef = scrollBodyRef.current;
    const updateRowHeights = () => {
      const columns = Array.from(
        currentScrollBodyRef.querySelectorAll('[data-park="1"]'),
      );

      if (columns.length === 0) {
        return;
      }

      const attributeValues = Array.from(
        columns[0].querySelectorAll("[data-attribute-value]"),
      );

      for (const attributeValueIndex in attributeValues) {
        const tallestAttributeRow = columns.reduce<number[]>(
          (acc, parkColumn) => {
            const parkAttribute = parkColumn
              .querySelectorAll<HTMLDivElement>(`[data-attribute-value]`)
              .item(Number(attributeValueIndex));

            if (!parkAttribute) {
              return acc;
            }
            parkAttribute.style.height = "auto";
            const parkAttributeHeight =
              parkAttribute.getBoundingClientRect().height;
            acc.push(parkAttributeHeight);
            return acc;
          },
          [],
        );

        const uniqueHeights = [...new Set(tallestAttributeRow)];

        if (uniqueHeights.length > 1) {
          for (const column of columns) {
            const parkAttribute = column
              .querySelectorAll<HTMLDivElement>(`[data-attribute-value]`)
              .item(Number(attributeValueIndex));
            if (!parkAttribute) {
              continue;
            }
            parkAttribute.style.height = `${Math.max(
              ...tallestAttributeRow,
            )}px`;
          }
        }
      }
    };

    updateRowHeights();
    const resizeObserver = new ResizeObserver(updateRowHeights);
    resizeObserver.observe(scrollBodyRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  });

  useEffect(() => {
    return () => {
      setBaselineComparisonValues(undefined);
      resetVisibleComparisonListRows();
    };
  }, [setBaselineComparisonValues, resetVisibleComparisonListRows]);

  const isInternal = useAtomValue(loggedInUserIsInternalAtom);

  return (
    <ParkComparisonViewWrapper>
      {selectedParks.length === 0 ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: colors.surfaceInfo,
              padding: spacing9,
              borderRadius: "4px",
              width: "30rem",
              textAlign: "center",
            }}
          >
            <RoundIconWrapper style={{ marginBottom: spacing6 }}>
              <IconREMSize height={1.6} width={1.6} stroke={colors.white}>
                <TurbineIcon />
              </IconREMSize>
            </RoundIconWrapper>
            <p style={{ ...typography.sub2, marginBottom: spacing2 }}>
              No parks to compare yet!
            </p>
            <p style={typography.caption}>
              Select parks and rows from the top bar to start comparing your
              results!
            </p>
          </div>
        </div>
      ) : (
        <>
          {isInternal &&
            projectId ===
              "ab19cd19-3f14-4675-9dc1-74ce6d38db43" /** "World-wide parks"; Vind playwright org */ && (
              <Row>
                <UpdateCompareTestData />
              </Row>
            )}

          <ParksWrapper ref={scrollBodyRef} id="compare-print-area">
            <AttributesIndexesColumn
              showRightShadow={showRightShadow}
              selectedAttributes={selectedAttributes}
            />
            {selectedParks.map((park, index) => (
              <Suspense
                key={park.comparisonId}
                fallback={
                  <SkeletonBlock
                    style={{
                      width: ITEM_WIDTH,
                      flexShrink: 0,
                      border: "1px solid #d9d9d9",
                    }}
                  />
                }
              >
                <ComparisonParkV2
                  selectedPark={park}
                  onRemove={onRemoveParkFromComparison}
                  index={index}
                  movePark={movePark}
                  maxIndex={selectedParks.length - 1}
                />
              </Suspense>
            ))}
            <DropZone ref={dropRef} className="hide-in-export" />
          </ParksWrapper>
        </>
      )}
    </ParkComparisonViewWrapper>
  );
};

export default ParkComparisonView;
