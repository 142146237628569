import { atom, useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { atomFamily, atomFromFn } from "utils/jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  getNode,
  Node,
  _Node,
  _ProjectNodeInformation,
} from "./../services/customerAPI";
import React, { useCallback } from "react";
import { Mixpanel } from "../mixpanel";
import * as api from "../types/api";
import { With } from "../utils/utils";
import { useProjectElementsFoldersCrud } from "../components/ProjectElementsV2/useProjectElementsFoldersCrud";
import {
  deleteBranch,
  updateBranchMeta,
  duplicateBranch,
  createBranch,
  sortBranches,
  archiveBranch,
} from "../services/projectDataAPIService";
import { projectFoldersRefreshAtom } from "../components/ProjectElementsV2/state";
import {
  createProjectElementsFolder,
  ProjectElementFolderType,
} from "../components/ProjectElementsV2/service";
import { scream } from "../utils/sentry";
import { useToast } from "../hooks/useToast";
import { useProjectElementsSortOrder } from "components/ProjectElementsV2/useProjectElementsSortOrder";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { useSetAtom } from "jotai";
import { aset, useJotaiCallback } from "utils/jotai";
import { featuresListAtom } from "./jotai/features";
import { archivedBranchMetasFamily, branchMetasFamily } from "./jotai/branch";
import { branchMetasBySortOrderFamily } from "./jotai/branch";
import { Row } from "components/General/Layout";
import AnimatedLoading from "@icons/AnimatedLoading/AnimatedLoading";
import { colors } from "styles/colors";
import { ABLY_BRANCH_DUPLICATED } from "state/ably";

// ======== Atoms ========

export const singleNodeAtomFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomFromFn<Promise<Node | undefined>>(async () => {
      try {
        const node = await getNode(nodeId);
        const parsedNode = _Node.parse(node);
        return parsedNode;
      } catch {
        return undefined;
      }
    }),
);
export const customerProjectAtomFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomFromFn<api.ProjectMeta | undefined>((get) => {
      const organisationId = get(organisationIdAtom);
      if (!organisationId) return;
      try {
        const node = get(
          getNodeSelectorFamily({
            organisationId,
            nodeId,
          }),
        );
        const projectNode = _ProjectNodeInformation.parse(node);
        return projectNode;
      } catch {
        return undefined;
      }
    }),
);
export const customerProjectLegacySelectorFamily = atomFamily(
  ({ projectId }: { projectId: string | undefined }) =>
    atom<api.ProjectMeta | undefined>((get) => {
      if (!projectId) return undefined;
      return get(
        customerProjectAtomFamily({
          nodeId: projectId,
        }),
      );
    }),
);

// ======== Branch ========

export const useCreateBranch = () => {
  const getFeatures = useJotaiCallback((get) => get(featuresListAtom), []);

  const updateLocalJotai = useJotaiCallback(
    (get, set, meta: api.BranchMeta, projectId: string) => {
      if (!projectId) {
        throw new Error("failed to find nodeId");
      }
      return aset(
        get,
        set,
        branchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          const existing = ret.get(meta.id);
          if (existing) {
            ret.set(meta.id, {
              ...existing,
              ...meta,
            });
          } else {
            ret.set(meta.id, meta);
          }
          return ret;
        },
      );
    },
    [],
  );

  const create = useJotaiCallback(
    async (
      get,
      set,
      projectId: string,
      branch: With<api.BranchMeta, "title">,
      withFeatures?: boolean,
    ) => {
      const features = withFeatures ? await getFeatures() : [];

      if (!projectId) throw new Error("failed to find nodeId");
      return await createBranch(projectId, branch.title, features).then(
        ({ meta }) => {
          set(
            branchMetasBySortOrderFamily({
              nodeId: projectId,
            }),
            (curr) => [...curr, meta],
          );
          updateLocalJotai(meta, projectId);
          Mixpanel.track_old(`Project branch created`, {
            projectId,
            branchId: meta.id,
          });
          return {
            meta,
          };
        },
      );
    },
    [getFeatures, updateLocalJotai],
  );

  const updateLocal = useJotaiCallback(
    async (_get, set, meta: api.BranchMeta, projectId: string) => {
      if (!projectId) throw new Error("failed to find nodeId");
      updateLocalJotai(meta, projectId);
      set(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
        (curr) => {
          const matchingBranch = curr.find((b) => b.id === meta.id) ?? {};
          const filtered = curr.filter((b) => b.id !== meta.id);
          return [
            ...filtered,
            {
              ...matchingBranch,
              ...meta,
            },
          ];
        },
      );
    },
    [updateLocalJotai],
  );
  return {
    create,
    updateLocal,
  };
};

export const useDuplicateBranch = () => {
  const { items: projectElementsFolders, update: updateProjectElementFolders } =
    useProjectElementsFoldersCrud();
  const { sortProjectElements, sortOrder } = useProjectElementsSortOrder();

  const { error: showError, info: showInfo } = useToast();
  const setProjectFoldersRefresh = useSetAtom(projectFoldersRefreshAtom);

  const updateLocalJotai = useJotaiCallback(
    (get, set, meta: api.BranchMeta, projectId: string) => {
      if (!projectId) {
        throw new Error("failed to find nodeId");
      }
      return aset(
        get,
        set,
        branchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          const existing = ret.get(meta.id);
          if (existing) {
            ret.set(meta.id, {
              ...existing,
              ...meta,
            });
          } else {
            ret.set(meta.id, meta);
          }
          return ret;
        },
      );
    },
    [],
  );

  const callback = useJotaiCallback(
    async (
      _get,
      set,
      projectId: string,
      branchId: string,
      title?: string,
      snapshotId?: string,
    ) => {
      if (!projectId) return;
      showInfo(
        <Row alignCenter>
          <AnimatedLoading
            $size="1.6rem"
            $baseColor={colors.white}
            $highlightColor={colors.grey700}
          />
          <p
            style={{
              color: colors.white,
            }}
          >
            Duplicating branch features, this might take a few seconds
          </p>
        </Row>,
        {
          groupId: ABLY_BRANCH_DUPLICATED,
          timeout: 60000,
        },
      );
      const dupedBranch = await duplicateBranch(
        projectId,
        branchId,
        title,
        snapshotId,
      ).then(({ meta }) => {
        set(
          branchMetasBySortOrderFamily({
            nodeId: projectId,
          }),
          (curr) => [...curr, meta],
        );
        updateLocalJotai(meta, projectId);

        Mixpanel.track_old(`Project branch duplicated`, {
          projectId,
          branchId: meta.id,
        });
        return {
          meta,
        };
      });

      /**
       * Clone project element folders
       * 1. Create new folders in new branch
       * 2. Save mapping of new folderIds
       * 3. Update featureIds of folders in new branch with new folderIds
       */
      const newFolderIdsMapping = new Map();
      for (const folder of projectElementsFolders) {
        try {
          const newFolder = await createProjectElementsFolder(
            projectId,
            dupedBranch.meta.id,
            {
              featureIds: folder.featureIds,
              folderName: folder.folderName,
            },
          );

          newFolderIdsMapping.set(folder.folderId, newFolder.folderId);
        } catch (error) {
          scream(
            "Could not clone project element folder when duplicating branch",
            {
              error,
              projectId,
              newBranchId: dupedBranch.meta.id,
              nrFeatures: folder.featureIds.length,
              folderName: folder.folderName,
            },
          );

          showError(
            `The feature folder "${folder.folderName}" could not be cloned to the new branch.
            The Vind team has been notified about the issue. Please try to clone the branch again, or contact support for more information.`,
            {
              timeout: 10_000,
            },
          );
        }
      }

      const foldersWithNewFolderIds: ProjectElementFolderType[] =
        projectElementsFolders.map((folder) => ({
          ...folder,
          folderId: newFolderIdsMapping.has(folder.folderId)
            ? newFolderIdsMapping.get(folder.folderId)
            : folder.folderId,
          featureIds: folder.featureIds.map((feature) => ({
            ...feature,
            id: newFolderIdsMapping.has(feature.id)
              ? newFolderIdsMapping.get(feature.id)
              : feature.id,
          })),
        }));

      const sortOrderWithNewFolderIds = sortOrder.map((item) => ({
        ...item,
        id: newFolderIdsMapping.has(item.id)
          ? newFolderIdsMapping.get(item.id)
          : item.id,
      }));

      for (const folderWithNewIds of foldersWithNewFolderIds) {
        await updateProjectElementFolders(
          folderWithNewIds,
          dupedBranch.meta.id,
        );
      }
      await sortProjectElements(sortOrderWithNewFolderIds, dupedBranch.meta.id);

      setProjectFoldersRefresh((r) => r + 1);
      return dupedBranch;
    },
    [
      projectElementsFolders,
      setProjectFoldersRefresh,
      showError,
      showInfo,
      sortOrder,
      sortProjectElements,
      updateProjectElementFolders,
      updateLocalJotai,
    ],
  );
  return useCallback(callback, [callback]);
};

export const getBranchSelectorFamily = atomFamily(
  (o: { projectId: string; branchId: string }) =>
    atom<Promise<undefined | api.BranchMeta>>(async (get) => {
      const branches = await get(
        branchMetasBySortOrderFamily({
          nodeId: o.projectId,
        }),
      );

      return branches.find((b) => b.id === o.branchId);
    }),
);

export const useUpdateBranch = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const updateJotaiBranch = useJotaiCallback(
    async (
      get,
      set,
      update: Pick<
        api.BranchMeta,
        | "id"
        | "title"
        | "description"
        | "analysisConfigurationId"
        | "windConfigurationId"
        | "costConfigurationId"
        | "operationsConfigurationId"
      >,
    ) => {
      const prunedUpdate = api._BranchMeta.partial().strip().parse(update);
      const prunedUpdateId = prunedUpdate.id;

      if (!prunedUpdateId) {
        scream("Branch update without id", {
          update,
        });
        return;
      }

      const atom = branchMetasFamily({
        projectId,
      });
      const ret = new Map(await get(atom));
      ret.set(prunedUpdateId, {
        ...ret.get(prunedUpdateId)!,
        ...prunedUpdate,
      });

      set(atom, Promise.resolve(ret));
    },
    [projectId],
  );

  const callback = useJotaiCallback(
    async (
      get,
      set,
      update: Pick<
        api.BranchMeta,
        | "id"
        | "title"
        | "description"
        | "analysisConfigurationId"
        | "windConfigurationId"
        | "costConfigurationId"
        | "operationsConfigurationId"
      >,
    ) => {
      if (!projectId) return;

      const {
        title,
        analysisConfigurationId,
        windConfigurationId,
        description,
        costConfigurationId,
        operationsConfigurationId,
      } = update;

      const stateBeforeUpdate = await get(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
      );

      set(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
        (curr) =>
          [...curr].map((b) =>
            b.id === update.id
              ? {
                  ...b,
                  ...update,
                }
              : b,
          ),
      );
      await updateJotaiBranch(update);

      await updateBranchMeta(projectId, update.id, {
        title,
        analysisConfigurationId,
        description: description ?? "",
        windConfigurationId,
        costConfigurationId,
        operationsConfigurationId,
      })
        .then(({ meta }) => {
          Mixpanel.track_old(`Update branch meta`, {
            projectId,
            branchId: meta.id,
          });
        })
        .catch(() => {
          set(
            branchMetasBySortOrderFamily({
              nodeId: projectId,
            }),
            () => stateBeforeUpdate,
          );
        });
    },
    [projectId, updateJotaiBranch],
  );
  return useCallback(callback, [callback]);
};

export const useSortBranches = () => {
  const projectId = useAtomValue(projectIdAtom);

  const updateLocal = useJotaiCallback(
    async (_get, set, sortOrder: string[]) => {
      if (!projectId) return;

      set(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
        (curr) => {
          return [...curr].sort((a, b) => {
            const itemA = sortOrder.findIndex((id) => id === a.id);
            const itemB = sortOrder.findIndex((id) => id === b.id);

            return itemA - itemB;
          });
        },
      );
    },
    [projectId],
  );

  const _sortBranches = useJotaiCallback(
    async (get, set, sortOrder: string[]) => {
      if (!projectId) return;

      const stateBeforeUpdate = await get(
        branchMetasBySortOrderFamily({
          nodeId: projectId,
        }),
      );

      updateLocal(sortOrder);

      await sortBranches(projectId, sortOrder)
        .then(() => {
          Mixpanel.track_old(`Update branches sort order`, {
            projectId,
            nrBranches: sortOrder.length,
          });
        })
        .catch(() => {
          set(
            branchMetasBySortOrderFamily({
              nodeId: projectId,
            }),
            () => stateBeforeUpdate,
          );
        });
    },
    [projectId, updateLocal],
  );

  return {
    updateLocal,
    sortBranches: _sortBranches,
  };
};

export const useDeleteBranch = () => {
  const deleteLocalJotai = useJotaiCallback(
    (get, set, branchId: string, projectId: string) => {
      if (!projectId) {
        throw new Error("failed to find nodeId");
      }

      return aset(
        get,
        set,
        archivedBranchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          ret.delete(branchId);
          return ret;
        },
      ).then(() =>
        aset(
          get,
          set,
          branchMetasFamily({
            projectId,
          }),
          (curr) => {
            const ret = new Map(curr);
            ret.delete(branchId);
            return ret;
          },
        ),
      );
    },
    [],
  );

  const callback = useJotaiCallback(
    async (_get, set, branch: api.BranchMeta, projectId: string) => {
      if (!projectId) throw new Error("No nodeId found");
      await deleteBranch(projectId, branch.id).then(() => {
        deleteLocalJotai(branch.id, projectId);
        set(
          branchMetasBySortOrderFamily({
            nodeId: projectId,
          }),
          (curr) => curr.filter((b) => b.id !== branch.id),
        );
        Mixpanel.track_old(`Delete branch`, {
          projectId,
          branchId: branch.id,
        });
      });
    },
    [deleteLocalJotai],
  );
  return useCallback(callback, [callback]);
};

export const useArchiveBranch = () => {
  const archiveLocalJotai = useJotaiCallback(
    async (get, set, branchId: string, projectId: string) => {
      if (!projectId) {
        throw new Error("failed to find nodeId");
      }

      const existingBranch = (
        await get(
          branchMetasFamily({
            projectId,
          }),
        )
      ).get(branchId);

      if (!existingBranch) {
        const err = new Error("Branch not found when archiving");
        scream(err, {
          branchId,
          projectId,
        });
        throw err;
      }

      // Delete from branchMetasFamily
      await aset(
        get,
        set,
        branchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          ret.delete(branchId);
          return ret;
        },
      );

      // Add to archivedBranchMetasFamily
      return aset(
        get,
        set,
        archivedBranchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          ret.set(branchId, { ...existingBranch, isArchived: true });
          return ret;
        },
      );
    },
    [],
  );

  const callback = useJotaiCallback(
    async (get, set, branch: api.BranchMeta, projectId: string) => {
      if (!projectId) throw new Error("No nodeId found");
      await archiveBranch(projectId, branch.id);
      await archiveLocalJotai(branch.id, projectId);
      Mixpanel.track_old(`Archive branch`, {
        projectId,
        branchId: branch.id,
      });
    },
    [archiveLocalJotai],
  );

  return callback;
};

export const useRestoreBranch = () => {
  const restoreBranchLocalJotai = useJotaiCallback(
    async (get, set, branchId: string, projectId: string) => {
      if (!projectId) {
        throw new Error("failed to find nodeId");
      }

      const existingBranch = (
        await get(
          archivedBranchMetasFamily({
            projectId,
          }),
        )
      ).get(branchId);

      if (!existingBranch) {
        const err = new Error("Branch not found when restoring");
        scream(err, {
          branchId,
          projectId,
        });
        throw err;
      }

      // Delete from archivedBranchMetasFamily
      await aset(
        get,
        set,
        archivedBranchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          ret.delete(branchId);
          return ret;
        },
      );

      // Add to branchMetasFamily
      return aset(
        get,
        set,
        branchMetasFamily({
          projectId,
        }),
        (curr) => {
          const ret = new Map(curr);
          ret.set(branchId, { ...existingBranch, isArchived: false });
          return ret;
        },
      );
    },
    [],
  );

  const callback = useJotaiCallback(
    async (get, set, branch: api.BranchMeta, projectId: string) => {
      if (!projectId) throw new Error("No nodeId found");
      await updateBranchMeta(projectId, branch.id, {
        title: branch.title,
        isArchived: false,
      });
      await restoreBranchLocalJotai(branch.id, projectId);
      Mixpanel.track_old(`Restore branch`, {
        projectId,
        branchId: branch.id,
      });
    },
    [restoreBranchLocalJotai],
  );

  return callback;
};
