/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const ResourceType = z.union([
      z.literal("BRANCH"),
      z.literal("TURBINE"),
      z.literal("INTER_ARRAY_CABLE"),
      z.literal("EXPORT_CABLE"),
      z.literal("FOUNDATION"),
    ]);
    export const ItemType = z.union([
      z.literal("FOLDER"),
      z.literal("RESOURCE"),
    ]);
    export const FolderItem = z
      .object({
        /** The title of the item. This cannot be empty. */
        id: z.string(),
        type: ItemType,
        /** The parent of the item. This can be empty. */
        parentId: z.string().optional(),
        /** The name of the item. This can be empty. */
        name: z.string().optional(),
        /** The order of the item in the folder. */
        sortOrder: z.number().optional(),
      })
      .passthrough();
    export const NewFolder = z
      .object({
        /** The title of the item. This cannot be empty. */
        name: z.string().optional(),
        /** The parent of the item. This can be empty. */
        parentId: z.string().optional(),
        /** The items that this folder contains. */
        folderItems: z.array(FolderItem).optional(),
      })
      .passthrough();
    export const EditItems = z
      .object({
        /** The parent of the item. This can be empty. */
        parentId: z.string().optional(),
        /** The items that this folder contains. */
        folderItems: z.array(FolderItem),
      })
      .passthrough();
    export const EditFolder = z
      .object({
        /** The title of the item. This cannot be empty. */
        name: z.string(),
        /** The parent of the item. This can be empty. */
        parentId: z.string().optional(),
        /** The items that this folder contains. */
        folderItems: z.array(FolderItem).optional(),
      })
      .passthrough();
    export const LibraryManageRole = z.union([
      z.literal("org_foundation_manage"),
      z.literal("org_turbine_manage"),
      z.literal("org_cable_manage"),
      z.literal("org_export_cable_manage"),
      z.literal("org_analysis_manage"),
      z.literal("org_financial_manage"),
      z.literal("org_data_package_manage"),
      z.literal("org_substation_manage"),
    ]);
  }
}
export const components = {
  schemas: {
    /** The type of resource in the folder structure */
    ResourceType: Components.Schemas.ResourceType,
    /** The type of item in the folder structure (folder or resource) */
    ItemType: Components.Schemas.ItemType,
    FolderItem: Components.Schemas.FolderItem,
    NewFolder: Components.Schemas.NewFolder,
    EditItems: Components.Schemas.EditItems,
    EditFolder: Components.Schemas.EditFolder,
    LibraryManageRole: Components.Schemas.LibraryManageRole,
  },
};

export const paths = {
  "/api/folders/{nodeId}/{resourceType}": {
    get: {
      pathParams: z.object({
        /** The ID of the node to create the item under. */
        nodeId: z.string(),
        /** The resource type. */
        resourceType: Components.Schemas.ResourceType,
      }),
      responses: {
        200: {
          /** Lists the resource type folders for project. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error. */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        /** The ID of the node to create the item under. */
        nodeId: z.string(),
        /** The resource type (BRANCH etc) */
        resourceType: Components.Schemas.ResourceType,
      }),
      /** Initial data for the item. */
      requestBody: {
        "application/json": Components.Schemas.NewFolder,
      },
      responses: {
        201: {
          /** Return the created item. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        404: {
          /** Parent folder not found. */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/{nodeId}/{resourceType}/move": {
    put: {
      pathParams: z.object({
        /** The ID of the node to create the item under. */
        nodeId: z.string(),
        /** The resource type (BRANCH etc). */
        resourceType: Components.Schemas.ResourceType,
      }),
      /** Items with sort order. */
      requestBody: {
        "application/json": Components.Schemas.EditItems,
      },
      responses: {
        200: {
          /** Lists the resource type folders for project. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        404: {
          /** Parent folder not found. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/{nodeId}/{resourceType}/{folderId}": {
    put: {
      pathParams: z.object({
        /** The ID of the node that has the folder. */
        nodeId: z.string(),
        /** The resource type (BRANCH etc). */
        resourceType: Components.Schemas.ResourceType,
        /** The ID of the folder that is being edited. */
        folderId: z.string(),
      }),
      /** Edited data for the item. */
      requestBody: {
        "application/json": Components.Schemas.EditFolder,
      },
      responses: {
        200: {
          /** New folder structure */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        404: {
          /** Folder not found. */
          "application/json": z.string(),
        },
      },
    },
    delete: {
      pathParams: z.object({
        /** The ID of the node that has the folder. */
        nodeId: z.string(),
        /** The resource type (BRANCH etc). */
        resourceType: Components.Schemas.ResourceType,
        /** The ID of the folder that is being edited. */
        folderId: z.string(),
      }),
      responses: {
        200: {
          /** New folder structure */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        403: {
          /** Folder is not empty. */
          "application/json": z.string(),
        },
        404: {
          /** Folder not found. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/{nodeId}/{resourceType}/resource/{resourceId}": {
    delete: {
      pathParams: z.object({
        /** The ID of the node that has the folder. */
        nodeId: z.string(),
        /** The resource type (BRANCH etc). */
        resourceType: Components.Schemas.ResourceType,
        /** The ID of the resource that is being deleted. */
        resourceId: z.string(),
      }),
      responses: {
        200: {
          /** The resource has been deleted. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        403: {
          /** Resource has dependencies. */
          "application/json": z.string(),
        },
        404: {
          /** Resource not found. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/organisation/{organisationId}/role/{libraryManageRole}": {
    get: {
      pathParams: z.object({
        /** The ID of the organisation to create the item under. */
        organisationId: z.string(),
        /** The resource type. */
        libraryManageRole: Components.Schemas.LibraryManageRole,
      }),
      responses: {
        200: {
          /** Lists the resource type folders for project. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        /** The ID of the organisation to create the item under. */
        organisationId: z.string(),
        /** The resource type. */
        libraryManageRole: Components.Schemas.LibraryManageRole,
      }),
      /** Initial data for the item. */
      requestBody: {
        "application/json": Components.Schemas.NewFolder,
      },
      responses: {
        201: {
          /** Return the created item. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        404: {
          /** Parent folder not found. */
          "application/json": z.string(),
        },
        500: {
          /** Internal server error. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/organisation/{organisationId}/role/{libraryManageRole}/move": {
    put: {
      pathParams: z.object({
        /** The ID of the organisation to create the item under. */
        organisationId: z.string(),
        /** The resource type. */
        libraryManageRole: Components.Schemas.LibraryManageRole,
      }),
      /** Items with sort order. */
      requestBody: {
        "application/json": Components.Schemas.EditItems,
      },
      responses: {
        200: {
          /** Lists the resource type folders for project. */
          "application/json": z.array(Components.Schemas.NewFolder),
        },
        400: {
          /** Invalid resource type. */
          "application/json": z.string(),
        },
        404: {
          /** Parent folder not found. */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/folders/organisation/{organisationId}/role/{libraryManageRole}/{folderId}":
    {
      put: {
        pathParams: z.object({
          /** The ID of the organisation that has the folder. */
          organisationId: z.string(),
          /** The resource type. */
          libraryManageRole: Components.Schemas.LibraryManageRole,
          /** The ID of the folder that is being edited. */
          folderId: z.string(),
        }),
        /** Edited data for the item. */
        requestBody: {
          "application/json": Components.Schemas.EditFolder,
        },
        responses: {
          200: {
            /** New folder structure */
            "application/json": z.array(Components.Schemas.NewFolder),
          },
          400: {
            /** Invalid resource type. */
            "application/json": z.string(),
          },
          404: {
            /** Folder not found. */
            "application/json": z.string(),
          },
        },
      },
      delete: {
        pathParams: z.object({
          /** The ID of the organisation that has the folder. */
          organisationId: z.string(),
          /** The resource type. */
          libraryManageRole: Components.Schemas.LibraryManageRole,
          /** The ID of the folder that is being edited. */
          folderId: z.string(),
        }),
        responses: {
          200: {
            /** New folder structure */
            "application/json": z.array(Components.Schemas.NewFolder),
          },
          400: {
            /** Invalid resource type. */
            "application/json": z.string(),
          },
          403: {
            /** Folder is not empty. */
            "application/json": z.string(),
          },
          404: {
            /** Folder not found. */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/folders/organisation/{organisationId}/role/{libraryManageRole}/resource/{resourceId}":
    {
      delete: {
        pathParams: z.object({
          /** The ID of the organisation that has the folder. */
          organisationId: z.string(),
          /** The resource type. */
          libraryManageRole: Components.Schemas.LibraryManageRole,
          /** The ID of the resource that is being deleted. */
          resourceId: z.string(),
        }),
        responses: {
          200: {
            /** The resource has been deleted. */
            "application/json": z.array(Components.Schemas.NewFolder),
          },
          400: {
            /** Invalid resource type. */
            "application/json": z.string(),
          },
          403: {
            /** Resource has dependencies. */
            "application/json": z.string(),
          },
          404: {
            /** Resource not found. */
            "application/json": z.string(),
          },
        },
      },
    },
};

// Generated using openapi-to-zod v0.1.47
