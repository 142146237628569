import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../components/Logo/Logo";
import {
  customerProjectAtomFamily,
  getBranchSelectorFamily,
} from "../state/timeline";
import { StandardBox } from "../styles/boxes/Boxes";
import { BranchMeta, ProjectMeta } from "../types/api";
import Legend from "./Legends/Legend";
import debounce from "debounce";

const Wrapper = styled.div<{
  show: boolean;
}>`
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: white;
  ${(p) => !p.show && "transform: scale(0.5);"}
  ${(p) => !p.show && "border: 3px solid #fff;"}
  ${(p) => !p.show && "box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);"}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const NameContainer = styled.div`
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
const LogoContainer = styled.div`
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  > * > div {
    box-shadow: none;
  }
`;

const StyledBox = styled(StandardBox)`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  height: 3rem;
  box-shadow: none;
`;

function ScreenCaptureModeInner({
  refProp,
  show,
  showProjectName,
  showBranchName,
  showFeatureLegend,
  showViewshedLegend,
  map,
  project,
  branch,
}: {
  refProp: React.Ref<HTMLDivElement>;
  show: boolean;
  showProjectName: boolean;
  showBranchName: boolean;
  showFeatureLegend: boolean;
  showViewshedLegend: boolean;
  map: mapboxgl.Map;
  project: ProjectMeta;
  branch: BranchMeta;
}) {
  useEffect(() => {
    if (show) {
      const rootElement = document.documentElement;
      const currentSize = rootElement.style.fontSize;
      rootElement.style.fontSize = `8px`;
      return () => {
        rootElement.style.fontSize = currentSize;
      };
    }
  }, [show]);

  const [mapImageUrl, setImageUrl] = useState<string | undefined>(
    map.getCanvas().toDataURL(),
  );

  const createSnapshot = useCallback(() => {
    setImageUrl(map.getCanvas().toDataURL());
  }, [map]);

  useEffect(() => {
    const debouncedCreateSnapshot = debounce(createSnapshot, 100);
    map.on("render", debouncedCreateSnapshot);
    return () => {
      map.off("render", debouncedCreateSnapshot);
      debouncedCreateSnapshot.clear();
    };
  }, [createSnapshot, map]);

  return (
    <Wrapper
      ref={refProp}
      show={show}
      style={{
        fontSize: "8px",
      }}
    >
      <NameContainer>
        {(showProjectName || showBranchName) && (
          <StyledBox>
            <h3>
              {showProjectName && project.name}
              {showProjectName && showBranchName && " — "}
              {showBranchName && branch.title}
            </h3>
          </StyledBox>
        )}
      </NameContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Legend
        showFeatureLegend={showFeatureLegend}
        showViewshedLegend={showViewshedLegend}
      />
      <Image src={mapImageUrl} alt="map" />
    </Wrapper>
  );
}

export default function ScreenCaptureMode({
  refProp,
  show,
  showProjectName,
  showBranchName,
  showFeatureLegend,
  showViewshedLegend,
}: {
  refProp: React.Ref<HTMLDivElement>;
  show: boolean;
  showProjectName: boolean;
  showBranchName: boolean;
  showFeatureLegend: boolean;
  showViewshedLegend: boolean;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const map = useAtomValue(mapAtom);
  const projectMeta = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  const currentBranchMeta = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId,
    }),
  );

  if (!map || !projectMeta || !currentBranchMeta) return null;

  return (
    <ScreenCaptureModeInner
      refProp={refProp}
      show={show}
      showProjectName={showProjectName}
      showBranchName={showBranchName}
      showFeatureLegend={showFeatureLegend}
      showViewshedLegend={showViewshedLegend}
      map={map}
      project={projectMeta}
      branch={currentBranchMeta}
    />
  );
}
