import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback, useMemo, useState } from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import BinIcon from "@icons/24/Bin.svg?react";
import CopyIcon from "@icons/24/Copy.svg?react";
import InformationIcon from "@icons/24/Information.svg?react";
import { toastMessagesAtom } from "state/toast";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import { useDeleteFeaturesCallback } from "hooks/deleteFeature";
import { ProjectFeature } from "types/feature";
import { colors } from "styles/colors";
import { featureIsLocked, isPark } from "utils/predicates";
import { Menu, MenuItem } from "components/General/Menu";
import { copy } from "components/MapControls/CopyPaste/copy";
import { ElementToIcon } from "components/ElementsToIcon/ElementsToIcon";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import SelectElementMenuItem, {
  getFeatureName,
} from "./shared/SelectElementMenuItem";
import PasteRowMenuItem from "./shared/PasteRowMenuItem";
import SelectAllParkElementOfTypeMenuItem from "./shared/SelectAllParkElementOfTypeMenuItem";
import { ElementIconWrapper } from "./SelectionMenu.style";
import AddToPortfolioMenuItem from "components/Organisation/Portfolio/AddToPortfolioMenuItem";
import { modalTypeOpenAtom } from "state/modal";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import UnlockIcon from "@icons/24/Unlock.svg";
import LockIcon from "@icons/24/Lock.svg";
import { useSetPropertyOnProjectFeatures } from "hooks/useSetPropertyOnProjectFeatures";
import { trackCanvasOption } from "components/CanvasSelectOption/MenuTracking";
import { lockedPropertyName } from "@constants/canvas";
import FeatureCoordinateEditor from "components/CanvasSelectOption/FeatureCoordinateEditor";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import SelectTags from "./shared/SelectTags";
import SelectStages from "./shared/SelectStages";
import { useFeatureMetaCrud } from "./featureMetaCrud";
import { featureMetaFamily } from "state/jotai/featureMeta";
import { adminInOrganisationSelectorFamily } from "state/user";
import { SkeletonText } from "components/Loading/Skeleton";

const SingleFeatureMenu = ({
  feature,
  featuresOnPoint,
  enableShowLayerInfo,
  closeMenu,
  sampleWmsCallback,
  onSelectFeature,
  onMouseEnterFeature,
  onMouseLeaveFeature,
}: {
  feature: MapboxGeoJSONFeature | ProjectFeature;
  featuresOnPoint: MapboxGeoJSONFeature[];
  enableShowLayerInfo: boolean;
  closeMenu(): void;
  sampleWmsCallback: () => void;
  onSelectFeature(feature: MapboxGeoJSONFeature): void;
  onMouseEnterFeature(feature: MapboxGeoJSONFeature): void;
  onMouseLeaveFeature(feature: MapboxGeoJSONFeature): void;
}) => {
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);
  const isLocked = useMemo(() => featureIsLocked(feature), [feature]);
  const stringToUnicode = useSystemSpecificUnicode();
  const deleteFeatureWithChildren = useDeleteFeaturesCallback();
  const deleteFeatures = useCallback(() => {
    deleteFeatureWithChildren([feature.properties!.id]);
  }, [feature, deleteFeatureWithChildren]);
  const setModalOpen = useSetAtom(modalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom);
  const allLayerSettings = useAtomValue(
    allLayerSettingsAtomFamily({
      projectId: projectId ?? "",
    }),
  );
  const branchId = useAtomValue(branchIdAtom);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const [coordinateEditorOpen, setCoordinateEditorOpen] = useState(false);
  const disabledEditCoordinates = useMemo(
    () => feature.geometry.type.includes("Multi"),
    [feature],
  );
  const { updateMeta } = useFeatureMetaCrud({
    nodeId: projectId ?? "",
    branchId: branchId ?? "",
    featureId: feature.properties!.id,
  });
  const meta = useAtomValue(
    featureMetaFamily({
      nodeId: projectId ?? "",
      branchId: branchId ?? "",
      featureId: feature.properties!.id,
    }),
  );
  const handleTagsChange = useCallback(
    (newTags: string[]) => {
      updateMeta({
        ...meta,
        tags: newTags,
      });
    },
    [meta, updateMeta],
  );
  const handleStagesChange = useCallback(
    (newStage: string | null) => {
      updateMeta({
        ...meta,
        stages: newStage ? [newStage] : [],
      });
    },
    [meta, updateMeta],
  );

  const copyCallback = useCallback(() => {
    copy([feature], [], [], setToastMessagesAtom, allLayerSettings);
  }, [feature, setToastMessagesAtom, allLayerSettings]);

  const updateFeatureProperties = useSetPropertyOnProjectFeatures(() =>
    setModalOpen(undefined),
  );

  const onClickLockFeature = useCallback(() => {
    trackCanvasOption("lock-feature", {
      projectId,
      branchId,
    });

    updateFeatureProperties([feature.properties!.id], {
      [lockedPropertyName]: !isLocked,
    });
  }, [branchId, projectId, feature, updateFeatureProperties, isLocked]);

  const selectedParkId =
    feature.properties &&
    feature.properties.type === PARK_PROPERTY_TYPE &&
    feature.id;

  return (
    <Menu
      style={{
        boxShadow: "initial",
      }}
    >
      <MenuItem
        name={getFeatureName(feature)}
        disabled={true}
        style={{
          borderBottom: `1px solid ${colors.inputOutline}`,
        }}
        icon={
          <ElementIconWrapper>
            <ElementToIcon
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
              element={feature as any}
              fillPolygons={true}
              fillParks={true}
            />
          </ElementIconWrapper>
        }
      />
      {isPark(feature as ProjectFeature) && (
        <>
          <AddToPortfolioMenuItem feature={feature} onAfterClick={closeMenu} />
          <Suspense
            fallback={
              <>
                <SkeletonText
                  style={{ padding: "0.2rem", borderRadius: "0" }}
                />
                <SkeletonText
                  style={{ padding: "0.2rem", borderRadius: "0" }}
                />
              </>
            }
          >
            <SelectTags
              organisationId={organisationId}
              selectedTags={meta.tags ?? []}
              onTagsChange={handleTagsChange}
              isAdminInOrg={isAdminInOrg}
            />
            <SelectStages
              organisationId={organisationId}
              selectedStage={meta.stages?.[0] ?? null}
              onStagesChange={handleStagesChange}
              isAdminInOrg={isAdminInOrg}
            />
          </Suspense>
        </>
      )}
      <MenuItem
        name={"Show layer info"}
        icon={<InformationIcon />}
        title={
          !enableShowLayerInfo
            ? "No WMS layers are visible in the map"
            : undefined
        }
        disabled={!enableShowLayerInfo}
        onClick={() => {
          sampleWmsCallback();
          closeMenu();
        }}
      />
      <MenuItem
        name={isLocked ? "Unlock element" : "Lock element"}
        icon={isLocked ? <UnlockIcon /> : <LockIcon />}
        onClick={onClickLockFeature}
      />
      <MenuItem
        name={"Copy"}
        icon={<CopyIcon />}
        shortcut={`${stringToUnicode("command")}+C`}
        onClick={() => {
          copyCallback();
          closeMenu();
        }}
      />
      <PasteRowMenuItem closeMenu={closeMenu} />
      <MenuItem
        name={"Edit coordinates"}
        icon={<CoordinatesAltIcon />}
        onClick={() => {
          !disabledEditCoordinates && setCoordinateEditorOpen((c) => !c);
          trackCanvasOption("open-feature-coordinate-editor", {
            projectId,
            branchId,
          });
        }}
      />
      {coordinateEditorOpen && (
        <FeatureCoordinateEditor
          setOpen={setCoordinateEditorOpen}
          feature={feature as ProjectFeature}
        />
      )}
      <SelectElementMenuItem
        onSelectFeature={onSelectFeature}
        onMouseEnterFeature={onMouseEnterFeature}
        onMouseLeaveFeature={onMouseLeaveFeature}
        closeMenu={closeMenu}
        features={featuresOnPoint}
      />
      {selectedParkId && (
        <SelectAllParkElementOfTypeMenuItem
          closeMenu={closeMenu}
          selectedParkId={selectedParkId.toString()}
        />
      )}
      <MenuItem
        name={"Delete"}
        style={{
          borderTop: `1px solid ${colors.inputOutline}`,
        }}
        shortcut={`${stringToUnicode("backspace")}`}
        icon={<BinIcon />}
        disabled={isLocked}
        onClick={() => {
          closeMenu();
          deleteFeatures();
        }}
      />
    </Menu>
  );
};

export default SingleFeatureMenu;
