import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import Checkmark from "@icons/checkmark/checkmark.svg?react";
import { colors } from "../../styles/colors";
import { spacing6 } from "../../styles/space";
import { Comp } from "../../types/utils";
import { typography } from "styles/typography";

export const RadioGroup = styled.fieldset<{
  disabled?: boolean;
}>`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  gap: ${spacing6};
  border: none;

  ${(p) =>
    p.disabled &&
    css`
      mouse-events: none;
      p {
        color: ${colors.textDisabled};
      }
    `}
`;

export const RadioLabel = styled.label<{
  disabled?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  gap: ${spacing6};

  cursor: pointer;
  p {
    margin: 0;
    line-height: 1.6rem;
    ${typography.caption};
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      p {
        color: ${colors.textDisabled};
      }
    `}
`;

const HiddenRadio = styled.input`
  visibility: hidden;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0;
  margin: 0;
`;

const StyledRadio = styled.div`
  box-sizing: border-box;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  transition: all 200ms ease;

  svg path {
    stroke: ${colors.white};
  }

  border: 1px solid ${colors.blue500};

  background: ${colors.white};
  input:hover + & {
    border-color: ${colors.blue600};
  }
  input:active + & {
    border-color: ${colors.blue700};
  }

  input:checked + & {
    background: ${colors.blue500};
  }
  input:checked:hover + & {
    background: ${colors.blue600};
  }
  input:checked:active + & {
    background: ${colors.blue700};
  }

  input:disabled + & {
    background: ${colors.grey300};
    border-color: ${colors.iconDisabled};

    svg path {
      stroke: ${colors.surfaceDisabled};
    }
  }
  input:checked:disabled + & {
    background: ${colors.iconDisabled};
  }
`;

export default function Radio({
  label,
  checked,
  disabled,
  labelStyle,
  style,
  title,
  ...p
}: Comp<
  "input",
  {
    label?: string;
    labelStyle?: CSSProperties;
    checked?: boolean;
  }
>) {
  return (
    <RadioLabel title={title} style={style} disabled={disabled}>
      <HiddenRadio type="radio" checked={checked} disabled={disabled} {...p} />
      <StyledRadio>{checked && <Checkmark />}</StyledRadio>
      {label && <p style={labelStyle}>{label}</p>}
    </RadioLabel>
  );
}
