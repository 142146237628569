import { useEffect, useMemo } from "react";
import { MathUtils } from "three";
import SunCalc from "suncalc";
import {
  viewPositionAtom,
  viewDateAtom,
  cameraPositionAtom,
} from "../../../../state/viewToPark";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import { updateSun } from "./utils";
import { DISTANCE_TO_WIRE_FRAME_SUN } from "../constants";
import { useAtomValue } from "jotai";

export default function useUpdateSunPos(
  threeCore: ThreeCoreViewPark | undefined,
) {
  const viewPosition = useAtomValue(viewPositionAtom);
  const cameraPosition = useAtomValue(cameraPositionAtom);
  const date = useAtomValue(viewDateAtom);
  const sunPosition = useMemo(() => {
    if (!viewPosition) return;
    return SunCalc.getPosition(
      date.toJSDate(),
      viewPosition.lat,
      viewPosition.lng,
    );
  }, [viewPosition, date]);

  useEffect(() => {
    if (!sunPosition || !threeCore) return;
    updateSun(
      {
        elevation: MathUtils.radToDeg(sunPosition.altitude),
        azimuth: MathUtils.radToDeg(sunPosition.azimuth),
      },
      threeCore.sky,
      threeCore.water,
      threeCore.renderer,
      threeCore.scene,
    );
  }, [sunPosition, threeCore]);

  useEffect(() => {
    if (!sunPosition || !threeCore || !cameraPosition) return;

    const { sun } = threeCore;

    const { altitude, azimuth } = sunPosition;
    const phi = MathUtils.degToRad(MathUtils.radToDeg(altitude));
    const theta = MathUtils.degToRad(MathUtils.radToDeg(azimuth));

    const X = DISTANCE_TO_WIRE_FRAME_SUN * (Math.cos(phi) * Math.sin(theta));
    const Z = DISTANCE_TO_WIRE_FRAME_SUN * (Math.cos(phi) * Math.cos(theta));
    const Y = DISTANCE_TO_WIRE_FRAME_SUN * Math.sin(phi);

    //We want the suns angle to be set from the position of the camera
    //the weird + and - signs are correct, probably from different coordinate systems
    sun.position.x = X - cameraPosition[0];
    sun.position.y = Y;
    sun.position.z = -Z + cameraPosition[1];
  }, [sunPosition, threeCore, cameraPosition]);
}
