import { atom, useAtom, useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ScrollBody } from "../../../../hooks/useShowScrollShadow";
import useTextInput from "../../../../hooks/useTextInput";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import DatabaseIcon from "@icons/24/Database.svg?react";
import { IconREMSize, typography } from "styles/typography";
import { partition } from "utils/utils";
import {
  findSourceWithName,
  getAllDataSourcesSelector,
} from "../../../../state/layer";
import { colors } from "../../../../styles/colors";
import { spaceLarge, spaceMedium, spaceSmall } from "../../../../styles/space";
import Button from "../../../General/Button";
import Checkbox from "../../../General/Checkbox";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../../General/FeatureFlag";
import { Input } from "../../../General/Input";
import {
  selectedSourceNameAtom,
  SourceName,
} from "../../../LayerList/LayerSettings/state";
import {
  activeFiltersAtom,
  availableLayerListFiltersAtom,
} from "../../layer-filter-state";

const buttonStyle = {
  ...typography.sub1,
  border: "none",
  backgroundColor: "transparent",
  color: colors.textPrimary,
  justifyContent: "space-between",
  padding: "6px 0",
  "&:hover": {
    border: "none",
    backgroundColor: colors.surfaceHover,
  },
};

const iconStyle = {
  marginLeft: "1rem",
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-left: 2.4rem;
  button {
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: none;

    span {
      width: 15rem;
    }

    &.secondary {
      color: ${colors.primaryText};

      svg {
        path {
          stroke: ${colors.primaryText};
        }
      }
    }
  }
`;

const SourceListWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: visible;
`;

const SourcesFrame = styled.div`
  padding: ${spaceLarge} 0;
  max-height: 400px;
  overflow-y: auto;
  gap: ${spaceMedium};
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 6px;
`;

const NonScrollableSourcesFrame = styled(SourcesFrame)`
  overflow-y: hidden;
`;
const openFilterAtom = atom<string[]>([]);

const BrowseLayersFilters = () => {
  const [openFilters, setOpenFilters] = useAtom(openFilterAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const countriesOpen = openFilters.includes("countries");
  const sourcesOpen = openFilters.includes("source");
  const tagsOpen = openFilters.includes("tags");
  const typesOpen = openFilters.includes("types");
  const [sourcesSearch, onSourcesSearchChange] = useTextInput("");
  const [activeFilters, setActiveFilters] = useAtom(activeFiltersAtom);
  const availableFilters = useAtomValue(
    availableLayerListFiltersAtom({
      projectId,
    }),
  );
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);
  const sources = useAtomValue(
    getAllDataSourcesSelector({
      projectId,
    }),
  );
  const [countriesSearch, onCountriesSearchChange] = useTextInput("");
  const [tagsSearch, onTagsSearchChange] = useTextInput("");

  const [privateSources, defaultSources] = useMemo(() => {
    const allSources = availableFilters.source
      .map((sourceName) => {
        const source = findSourceWithName(sourceName, sources);
        const title =
          selectedSourceName === SourceName.Original
            ? source?.originalName ?? source?.name ?? sourceName
            : source?.name ?? sourceName;

        return {
          sourceName: sourceName,
          originalName: source?.originalName,
          title,
          isPrivate: source?.private ?? false,
          sourceId: source?.id,
        };
      })
      .filter(({ sourceName, originalName }) => {
        return (
          !sourcesSearch ||
          sourceName.toLowerCase().includes(sourcesSearch.toLowerCase()) ||
          originalName?.toLowerCase().includes(sourcesSearch.toLowerCase())
        );
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    const [privateSources, defaultSources] = partition(
      allSources,
      (source) => source.isPrivate,
    );
    return [privateSources, defaultSources];
  }, [availableFilters.source, selectedSourceName, sources, sourcesSearch]);

  return (
    <ButtonWrapper>
      <div>
        <Button
          data-popup-type="countries"
          text="Country"
          onClick={() => {
            if (!countriesOpen) {
              setOpenFilters(["countries"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "countries"));
            }
          }}
          onTouchEnd={() => {
            if (!countriesOpen) {
              setOpenFilters(["countries"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "countries"));
            }
          }}
          buttonType="dropdown"
          size="small"
          icon={
            countriesOpen ? (
              <ArrowDownIcon style={iconStyle} />
            ) : (
              <ArrowRightIcon style={iconStyle} />
            )
          }
          iconPosition="right"
          style={buttonStyle}
        />
        {countriesOpen && (
          <SourceListWrapper>
            <NonScrollableSourcesFrame>
              <div>
                <StyledInput
                  type="search"
                  value={countriesSearch}
                  onChange={onCountriesSearchChange}
                  placeholder="Search country"
                  autoFocus={true}
                />
              </div>
              <ScrollBody gap="inherit">
                <FeatureFlag name={SHOW_DATA_CLEANING}>
                  <div>
                    <Checkbox
                      label={"Show layers without countries"}
                      checked={activeFilters.debug_missing_countries}
                      labelPlacement="after"
                      onChange={() => {
                        setActiveFilters((curr) => ({
                          ...curr,
                          debug_missing_countries:
                            !curr.debug_missing_countries,
                        }));
                      }}
                    />
                  </div>
                </FeatureFlag>
                {availableFilters.country
                  .filter((country) =>
                    country
                      .toLowerCase()
                      .includes(countriesSearch.toLowerCase()),
                  )
                  .map((country) => {
                    const isActive = activeFilters.country.some(
                      (activeCountry) => activeCountry === country,
                    );

                    return (
                      <div key={country}>
                        <Checkbox
                          style={{
                            textTransform: "capitalize",
                          }}
                          label={country}
                          value={country}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              country: isActive
                                ? curr.country.filter(
                                    (existingCountry) =>
                                      existingCountry !== country,
                                  )
                                : [...curr.country, country],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
              </ScrollBody>
            </NonScrollableSourcesFrame>
          </SourceListWrapper>
        )}
      </div>
      <div>
        <Button
          data-popup-type="source"
          text="Source"
          onClick={() => {
            if (!sourcesOpen) {
              setOpenFilters(["source"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "source"));
            }
          }}
          onTouchEnd={() => {
            if (!sourcesOpen) {
              setOpenFilters(["source"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "source"));
            }
          }}
          buttonType="dropdown"
          size="small"
          icon={
            sourcesOpen ? (
              <ArrowDownIcon style={iconStyle} />
            ) : (
              <ArrowRightIcon style={iconStyle} />
            )
          }
          iconPosition="right"
          style={buttonStyle}
        />
        {sourcesOpen && (
          <SourceListWrapper>
            <NonScrollableSourcesFrame>
              <div>
                <StyledInput
                  type="search"
                  value={sourcesSearch}
                  onChange={onSourcesSearchChange}
                  placeholder="Search source name"
                  autoFocus={true}
                />
              </div>
              <ScrollBody gap="inherit" style={{ maxHeight: "27vh" }}>
                {privateSources.length > 0 && (
                  <>
                    <h4
                      style={{
                        ...typography.h4,
                      }}
                    >
                      Added sources
                    </h4>
                    {privateSources.map(({ sourceName, title, sourceId }) => {
                      const isActive = activeFilters.source.some(
                        (activeSource) => activeSource === sourceName,
                      );

                      return (
                        <div
                          key={sourceId}
                          style={{
                            display: "flex",
                            gap: spaceSmall,
                          }}
                        >
                          <Checkbox
                            label={title}
                            value={sourceName}
                            checked={isActive}
                            labelPlacement="after"
                            onChange={() => {
                              setActiveFilters((curr) => ({
                                ...curr,
                                source: isActive
                                  ? curr.source.filter(
                                      (source) => source !== sourceName,
                                    )
                                  : [...curr.source, sourceName],
                              }));
                            }}
                          />
                          <IconREMSize height={1.2} width={1.2}>
                            <DatabaseIcon />
                          </IconREMSize>
                        </div>
                      );
                    })}
                  </>
                )}
                {defaultSources
                  .filter(({ sourceName, originalName }) => {
                    return (
                      !sourcesSearch ||
                      sourceName
                        .toLowerCase()
                        .includes(sourcesSearch.toLowerCase()) ||
                      originalName
                        ?.toLowerCase()
                        .includes(sourcesSearch.toLowerCase())
                    );
                  })
                  .map(({ sourceName, title, sourceId }) => {
                    const isActive = activeFilters.source.some(
                      (activeSource) => activeSource === sourceName,
                    );

                    return (
                      <div key={sourceId}>
                        <Checkbox
                          label={title}
                          value={sourceName}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              source: isActive
                                ? curr.source.filter(
                                    (source) => source !== sourceName,
                                  )
                                : [...curr.source, sourceName],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
              </ScrollBody>
            </NonScrollableSourcesFrame>
          </SourceListWrapper>
        )}
      </div>
      <div>
        <Button
          data-popup-type="tags"
          text="Tag"
          onClick={() => {
            if (!tagsOpen) {
              setOpenFilters(["tags"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "tags"));
            }
          }}
          onTouchEnd={() => {
            if (!tagsOpen) {
              setOpenFilters(["tags"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "tags"));
            }
          }}
          buttonType="dropdown"
          size="small"
          icon={
            tagsOpen ? (
              <ArrowDownIcon style={iconStyle} />
            ) : (
              <ArrowRightIcon style={iconStyle} />
            )
          }
          iconPosition="right"
          style={buttonStyle}
        />
        {tagsOpen && (
          <SourceListWrapper>
            <NonScrollableSourcesFrame>
              <div>
                <StyledInput
                  type="search"
                  value={tagsSearch}
                  onChange={onTagsSearchChange}
                  placeholder="Search tag"
                  autoFocus={true}
                />
              </div>
              <ScrollBody gap="inherit" style={{ maxHeight: "27vh" }}>
                <FeatureFlag name={SHOW_DATA_CLEANING}>
                  <div>
                    <Checkbox
                      label={"Show layers without tags"}
                      checked={activeFilters.debug_missing_tags}
                      labelPlacement="after"
                      onChange={() => {
                        setActiveFilters((curr) => ({
                          ...curr,
                          debug_missing_tags: !curr.debug_missing_tags,
                        }));
                      }}
                    />
                  </div>
                </FeatureFlag>
                {availableFilters.tags
                  .filter((tag) =>
                    tag.toLowerCase().includes(tagsSearch.toLowerCase()),
                  )
                  .map((f) => {
                    const isActive = activeFilters.tags.some(
                      (tag) => tag === f,
                    );

                    return (
                      <div key={f}>
                        <Checkbox
                          label={f}
                          value={f}
                          checked={isActive}
                          labelPlacement="after"
                          onChange={() => {
                            setActiveFilters((curr) => ({
                              ...curr,
                              tags: isActive
                                ? curr.tags.filter((source) => source !== f)
                                : [...curr.tags, f],
                            }));
                          }}
                        />
                      </div>
                    );
                  })}
              </ScrollBody>
            </NonScrollableSourcesFrame>
          </SourceListWrapper>
        )}
      </div>
      <div>
        <Button
          data-popup-type="types"
          text="Type"
          onClick={() => {
            if (!typesOpen) {
              setOpenFilters(["types"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "types"));
            }
          }}
          onTouchEnd={() => {
            if (!typesOpen) {
              setOpenFilters(["types"]);
            } else {
              setOpenFilters((curr) => curr.filter((f) => f !== "types"));
            }
          }}
          buttonType="dropdown"
          size="small"
          icon={
            typesOpen ? (
              <ArrowDownIcon style={iconStyle} />
            ) : (
              <ArrowRightIcon style={iconStyle} />
            )
          }
          iconPosition="right"
          style={buttonStyle}
        />
        {typesOpen && (
          <SourceListWrapper>
            <SourcesFrame>
              {availableFilters.sourceTypes.map((f) => {
                const isActive = activeFilters.sourceTypes.some(
                  (tag) => tag === f,
                );

                return (
                  <div key={f}>
                    <Checkbox
                      label={f}
                      value={f}
                      checked={isActive}
                      labelPlacement="after"
                      onChange={() => {
                        setActiveFilters((curr) => ({
                          ...curr,
                          sourceTypes: isActive
                            ? curr.sourceTypes.filter((source) => source !== f)
                            : [...curr.sourceTypes, f],
                        }));
                      }}
                    />
                  </div>
                );
              })}
            </SourcesFrame>
          </SourceListWrapper>
        )}
      </div>
    </ButtonWrapper>
  );
};

export default BrowseLayersFilters;
