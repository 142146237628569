import { atom } from "jotai";
import { existingTurbinesFamily } from "state/jotai/existingTurbine";
import { ValidationWarning } from "state/validationWarnings";
import { atomFamily } from "utils/jotai";
import { overlappingPointFeatures } from "utils/overlappingPointFeatures";
import { ValidationWarningTypes } from "./utils";

export const EXISTING_TURBINE_OVERLAP_DISTANCE = 100;

export const existingTurbineOverlapWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExistingTurbineOverlap>
        | undefined
      >
    >(async (get) => {
      const existing = await get(existingTurbinesFamily({ branchId }));
      const overlaps = overlappingPointFeatures(
        existing,
        EXISTING_TURBINE_OVERLAP_DISTANCE,
      );

      if (overlaps.length === 0) return;
      return {
        type: ValidationWarningTypes.ExistingTurbineOverlap,
        featureIds: overlaps.map((t) => t.id),
      };
    }),
);
