import styled from "styled-components";
import { colors } from "../../styles/colors";
import { typography } from "styles/typography";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 75%;
`;

const Title = styled.span`
  ${typography.sub1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.grey200};
`;

export default function HeaderWithBorder({ title }: { title: string }) {
  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
      </Wrapper>
      <Border />
    </>
  );
}
