import { useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import {
  RefObject,
  Suspense,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import styled from "styled-components";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";
import { projectPersonasSelectorFamily } from "../../../state/customer";
import { StandardBox } from "../../../styles/boxes/Boxes";
import { hexToRgbA, colors } from "../../../styles/colors";
import { UserInfoProject } from "../../UserInfo/UserInfo";
import { UserInfoLoading } from "components/UserInfo/UserInfoLoading";
import { platformCtrlOrCommand } from "../../../utils/utils";
import { TextRaw } from "../../../styles/typography";
import { CustomerPersona } from "types/customer";
import UserImageRound from "components/UserImage/UserImageRound";
import { loggedInUserAtom } from "state/user";

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 0.8rem;
`;

const Textarea = styled.textarea`
  ${TextRaw}
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  resize: none;
  margin: 0;
  width: 100%;
  min-width: 22rem;
  max-height: 30rem;
  min-height: 2.4rem;

  color: ${colors.textPrimary};
  line-height: 2rem;
  border-bottom: 1px solid ${hexToRgbA(colors.primaryDisabled, 0.5)};

  &::placeholder {
    color: ${colors.primaryDisabled};
  }

  &:focus {
    outline: none;
  }
`;

const MentionContainer = styled(StandardBox)`
  position: absolute;
  top: 110%;
  padding: 0.8rem 0;
`;

const MentionRow = styled.div`
  padding: 0.4rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: ${hexToRgbA(colors.hoverSelected, 0.4)};
  }
`;

export function getAuthIdFromMention(mention: string) {
  const from = mention.indexOf("(") + 1;
  const to = mention.indexOf(")");
  return mention.substring(from, to);
}

export function isMention(word: string) {
  return word.startsWith("@(auth0|") && word.endsWith(")");
}

export default function TextareaWithMentions({
  text,
  onChange,
  onSubmit,
  onCancel,
  textAreaRef,
  nodeId,
}: {
  text: string;
  onChange: (text: string) => void;
  onSubmit: (e: SyntheticEvent<any>) => void;
  onCancel: (e: SyntheticEvent<any>) => void;
  textAreaRef?: RefObject<HTMLTextAreaElement>;
  nodeId: string;
}) {
  const members = useAtomValue(
    useMemo(
      () =>
        unwrap(
          projectPersonasSelectorFamily({
            projectNodeId: nodeId,
          }),
        ),
      [nodeId],
    ),
  );
  const userData = useAtomValue(loggedInUserAtom);

  const [textWithName, nameToMentionMap] = useMemo(() => {
    const map = new Map<string, string>();
    const words = text.split(" ");
    const transformed = words.map((w) => {
      if (isMention(w)) {
        const replaceWith = `@${members?.find((m) => m.user_id === getAuthIdFromMention(w))?.nickname}`;
        map.set(replaceWith, w);
        return replaceWith;
      }
      return w;
    });
    return [transformed.join(" "), map];
  }, [members, text]);

  const _ref = useRef<HTMLTextAreaElement>(null);
  const ref = textAreaRef ?? _ref;
  useAutosizeTextArea(ref.current, text, 20);

  const showMentions = useMemo(() => {
    const words = textWithName.split(" ");
    if (words.length === 0) return false;
    const lastWord = words[words.length - 1];
    return lastWord[0] === "@";
  }, [textWithName]);

  const mentionSearchString = useMemo(() => {
    const words = textWithName.split(" ");
    if (words.length === 0) return undefined;
    const lastWord = words[words.length - 1];
    if (lastWord[0] !== "@") return undefined;
    return lastWord.substring(1);
  }, [textWithName]);

  const mentions = useMemo(
    () =>
      (members ?? []).filter((m) => {
        return mentionSearchString
          ? m.nickname.toLowerCase().includes(mentionSearchString.toLowerCase())
          : true;
      }),
    [members, mentionSearchString],
  );

  const insertMention = useCallback(
    (mention: CustomerPersona) => {
      const mentionString = `@(${mention.user_id})`;
      const lastIndex = text.lastIndexOf(" ");
      const textWithoutPartialMention = text.substring(0, lastIndex);
      onChange(textWithoutPartialMention + " " + mentionString + " ");
      ref?.current?.focus();
    },
    [onChange, ref, text],
  );

  useEffect(() => {
    setTimeout(() => ref?.current?.focus(), 10);
  }, [ref]);

  return (
    <Container>
      {userData && <UserImageRound size={2.4} user={userData} />}
      <Textarea
        aria-multiline
        autoFocus
        value={textWithName}
        maxLength={5000}
        onChange={(e) => {
          const words = e.target.value.split(" ");
          const transformedBack = words.map((w) => {
            if (nameToMentionMap.has(w)) {
              return nameToMentionMap.get(w);
            } else {
              return w;
            }
          });
          onChange(transformedBack.join(" "));
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (
            e.key === "Enter" &&
            platformCtrlOrCommand(e) &&
            textWithName.length > 0
          ) {
            onSubmit(e);
          } else if (e.key === "Escape") {
            onCancel(e);
          }
        }}
        placeholder={"Write a reply, mention with @..."}
        rows={1}
        ref={ref}
      />
      {showMentions && (
        <MentionContainer>
          {mentions.map((m) => (
            <MentionRow key={m.user_id} onClick={() => insertMention(m)}>
              <Suspense fallback={<UserInfoLoading />}>
                <UserInfoProject projectId={nodeId} userId={m.user_id} />
              </Suspense>
            </MentionRow>
          ))}
        </MentionContainer>
      )}
    </Container>
  );
}
