import { analysisStoppedText } from "analysis/warnings";
import { currentParkTriggerId } from "components/Finance/Triggers";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef } from "react";
import { CostType } from "services/costService";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { useAtomValue } from "jotai";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useRealValueCapexMillion } from "finance/hooks/useCapex";
import { lunwrap } from "utils/jotai";
import { getFinancialStoppedReason } from "finance/warnings";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";

export const CapexPieChartWidget = () => {
  const { park, branch } = useDashboardContext();

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );
  if (Object.values(invalidTypes).some((e) => e))
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some elements are using a type that is invalid"}
          type={"error"}
        />
      </CenterContainer>
    );

  return (
    <SafeCard title="Capex graph" id="Capex pie chart">
      <CapexPieChartInnerV2 />
    </SafeCard>
  );
};

const CapexPieChartInnerV2 = () => {
  const { triggerId } = useDashboardContext();
  const graphRef = useRef<HTMLDivElement>(null);
  const { useCosts } = useRealValueCapexMillion(triggerId);
  const turbinesCapex = lunwrap(useCosts(CostType.Turbine).sum);
  const interArrayCablingCapex = lunwrap(useCosts(CostType.Cable).sum);
  const foundationCapex = lunwrap(useCosts(CostType.Foundation).sum);
  const mooringCapex = lunwrap(useCosts(CostType.Mooring).sum);
  const substationCapex = lunwrap(useCosts(CostType.Substation).sum);
  const exportCableCapex = lunwrap(useCosts(CostType.ExportCable).sum);
  const otherCapex = lunwrap(useCosts(CostType.Other).sum);

  useEffect(() => {
    if (!graphRef.current) return;
    if (turbinesCapex === undefined) return;
    if (interArrayCablingCapex === undefined) return;
    if (foundationCapex === undefined) return;
    if (mooringCapex === undefined) return;
    if (substationCapex === undefined) return;
    if (exportCableCapex === undefined) return;
    if (otherCapex === undefined) return;

    const valueLabels = [
      [turbinesCapex, "Turbines", "#002334"],
      [interArrayCablingCapex, "Inter array cabling", "#03B6F7"],
      [foundationCapex, "Foundations", "#00445F"],
      [mooringCapex, "Mooring", "#028EC2"],
      [substationCapex, "Substation", "#01688F"],
      [exportCableCapex, "Export cable", "#A3D8FF"],
      [otherCapex, "Other", "#E5F2FF"],
    ].filter(([val]) => val !== 0);

    const values = valueLabels.map(([val, ,]) => val);
    const labels = valueLabels.map(([, label]) => label);
    const colors = valueLabels.map(([, , color]) => color);
    var data = [
      {
        values,
        labels,
        marker: {
          colors,
        },
        type: "pie" as const,
        automargin: true,
      },
    ];

    var layout = {
      autosize: true,
      height: 200,
      width: 350,
      margin: { t: 0, b: 0, l: 0, r: 0 },
      showlegend: true,
    };

    Plotly.newPlot(graphRef.current, data, layout);
  }, [
    exportCableCapex,
    foundationCapex,
    graphRef,
    interArrayCablingCapex,
    mooringCapex,
    otherCapex,
    substationCapex,
    turbinesCapex,
  ]);

  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(currentParkTriggerId),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );
  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  if (
    [
      turbinesCapex,
      interArrayCablingCapex,
      foundationCapex,
      mooringCapex,
      substationCapex,
      exportCableCapex,
      otherCapex,
    ].some((x) => x === undefined)
  )
    return <LoadingState />;

  return <div ref={graphRef} />;
};
