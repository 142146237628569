import { ReactNode, useRef, useState } from "react";
import { colors } from "styles/colors";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import { Menu } from "./Menu";
import { Anchor } from "./Anchor";
import { StyledButton } from "./Button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  text?: React.ReactNode;
  icon?: ReactNode;
  buttonType?: "primary" | "secondary" | "text" | "dropdown";
  size?: "small" | "medium";
  iconPosition?: "left" | "right";
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
  skeleton?: boolean;
  maxWidth?: number;
}

export default function SplitButton({
  children,
  text,
  buttonType = "primary",
  size = "medium",
  icon,
  ...props
}: ButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const btn = (
    <div>
      <div style={{ display: "flex" }}>
        <StyledButton
          className={[buttonType, size].join(" ")}
          {...props}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          iconPosition="left"
        >
          {icon}
          <span>{text}</span>
        </StyledButton>
        <StyledButton
          ref={ref}
          className={[buttonType, size].join(" ")}
          {...props}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "1px solid",
            borderLeftColor: props.disabled
              ? colors.borderDefault
              : buttonType === "secondary"
                ? colors.indigo200
                : buttonType === "primary"
                  ? colors.blue400
                  : "#00000000",
          }}
          onClick={() => setMenuOpen(!menuOpen)}
          iconPosition="right"
        >
          {<ArrowDownIcon />}
        </StyledButton>
      </div>
      {menuOpen && (
        <Anchor baseRef={ref} basePlace="bottomRight" floatPlace="topRight">
          <Menu
            style={{ maxWidth: "30rem" }}
            submenuRef={menuRef}
            setOpen={setMenuOpen}
          >
            {children}
          </Menu>
        </Anchor>
      )}
    </div>
  );
  return btn;
}
