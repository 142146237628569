import { useCallback, useMemo } from "react";
import { InboundMessage } from "ably";
import { _CostConfiguration } from "services/costService";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import { useLibraryNodeCostConfig } from "hooks/useLibraryNodeCostConfig";
import { ABLY_ORG_COST_CONFIGURATION_LIBRARY_TYPE_UPDATE } from "state/ably";

export function useAblyLibraryCostConfigForNode(projectId: string) {
  const { updateLocal } = useLibraryNodeCostConfig(projectId);

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      console.log("message", message);
      const costConfig = _CostConfiguration.parse(message.data.meta);
      updateLocal(costConfig);
    },
    [updateLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ORG_COST_CONFIGURATION_LIBRARY_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
    ],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);
}
