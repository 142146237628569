import { ChangelogCategory, ChangelogEntry } from "./types";
import { getNodeChangelogs, getOrganisationChangelogs } from "./service";
import { atomFamily, atomFromFn } from "utils/jotai";
import { userOrganisationAccessSelectorFamily } from "state/user";

export const inputChangelogsAtomFamily = atomFamily(
  ({
    organisationId,
    nodeId,
    changelogId,
    category,
  }: {
    nodeId: string;
    changelogId: string;
    category: ChangelogCategory;
    organisationId: string | undefined;
  }) =>
    atomFromFn<Promise<ChangelogEntry[]>>(async (get) => {
      const userAccess = get(
        userOrganisationAccessSelectorFamily({
          organisationId: organisationId ?? "",
        }),
      );
      if (organisationId && organisationId === nodeId && userAccess < 1) {
        return [];
      }
      return organisationId === nodeId
        ? getOrganisationChangelogs(nodeId, changelogId, category)
        : getNodeChangelogs(nodeId, changelogId, category);
    }),
);
