import { useCallback, useMemo } from "react";
import { Message } from "ably";
import * as Sentry from "@sentry/react";
import { _CostConfiguration } from "../../services/costService";
import { ABLY_COST_CONFIG_UPDATE } from "../../state/ably";
import useCostConfigurationCrud from "../useCostConfigurationCrud";
import { useAblyGeneric } from "../useAblyGeneric";

const useAblyCostConfiguration = (projectId: string) => {
  const { updateLocal, deleteLocal } = useCostConfigurationCrud();

  const channelName = useMemo(
    () => projectId && `${projectId}:all`,
    [projectId],
  );

  const onMessageReceived = useCallback(
    (message: Message) => {
      const { isArchived } = message.data;

      Sentry.addBreadcrumb({
        category: "ably",
        message: "Received cost configuration update message",
        data: {
          isArchived,
          costConfigId: message.data.id,
        },
      });

      if (isArchived) {
        deleteLocal(message.data.id);
        return;
      }

      const configuration = _CostConfiguration.parse(
        message.data.configuration,
      );
      updateLocal(configuration);
    },
    [deleteLocal, updateLocal],
  );

  const events = useMemo(
    () => [{ eventName: ABLY_COST_CONFIG_UPDATE, onMessageReceived }],
    [onMessageReceived],
  );

  useAblyGeneric(channelName, events, projectId);

  return null;
};

export default useAblyCostConfiguration;
