import { useCallback, useEffect } from "react";
import { Group, MathUtils } from "three";
import { ThreeCoreParkShadow } from "./useCreateThreeCore";

const FPS = 60;

export default function useAnimation(
  threeCore: ThreeCoreParkShadow | undefined,
  turbineRotorsGlobal: Group | undefined,
) {
  const render = useCallback(() => {
    if (!threeCore) return;
    const { renderer, scene, camera } = threeCore;

    if (turbineRotorsGlobal) {
      const timestampDeci = Math.round(Date.now() / 60);
      turbineRotorsGlobal.children.forEach((c) =>
        c.children.forEach(
          (c2) => (c2.rotation.x = MathUtils.degToRad(timestampDeci % 360)),
        ),
      );
    }

    renderer.render(scene, camera);
  }, [threeCore, turbineRotorsGlobal]);

  useEffect(() => {
    let stopAnimation = false;
    function animate() {
      if (stopAnimation) return;
      setTimeout(() => {
        requestAnimationFrame(animate);
        render();
      }, 1000 / FPS);
    }
    animate();

    return () => {
      stopAnimation = true;
    };
  }, [render]);
}
