import { featureMetaFamily } from "state/jotai/featureMeta";
import { FeatureMeta } from "services/projectDataAPIService";
import { updateFeatureMeta } from "services/projectDataAPIService";
import { aset, useJotaiCallback } from "utils/jotai";
import { useToast } from "hooks/useToast";

export const useFeatureMetaCrud = ({
  nodeId,
  branchId,
  featureId,
}: {
  nodeId: string;
  branchId: string;
  featureId: string;
}) => {
  const { error: showError } = useToast();

  const updateLocal = useJotaiCallback(
    async (get, set, meta: FeatureMeta) => {
      aset(
        get,
        set,
        featureMetaFamily({ nodeId, branchId, featureId }),
        () => meta,
      );
    },
    [nodeId, branchId, featureId],
  );

  const updateMeta = useJotaiCallback(
    async (get, set, meta: FeatureMeta) => {
      const fallback = await get(
        featureMetaFamily({ nodeId, branchId, featureId }),
      );
      try {
        updateFeatureMeta(nodeId, branchId, featureId, meta);
        updateLocal(meta);
      } catch (err) {
        aset(
          get,
          set,
          featureMetaFamily({ nodeId, branchId, featureId }),
          () => fallback,
        );
        showError("Could not update feature meta, please try again.");
      }
    },
    [nodeId, branchId, featureId, updateLocal, showError],
  );

  return { updateMeta };
};
