import { costId } from "../amounts/costIds";
import { amountId } from "../amounts/amountIds";
import { atom } from "jotai";
import {
  CostType,
  isFeatureCost,
  isOperationsCost,
  isOperationsOverrideCost,
  isOverrideCost,
} from "services/costService";
import {
  exportCableTypesFamily,
  libraryExportCableTypesFamily,
  projectExportCableTypesFamily,
} from "state/jotai/exportCableType";
import {
  unitToAmountUnit,
  _CostUnit,
  CostUnit,
  ConfidenceLevel,
  CostWithUnit,
  ComponentProcurementCostForPark,
} from "types/financial";
import { Cost } from "types/financial";
import { atomFamily } from "utils/jotai";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import {
  getBranchId,
  getParkId,
  getParkOrSubareaCenter,
  getProjectId,
} from "analysis/inputs";
import { dedup, partition } from "utils/utils";
import { listProcurementCostForLibraryExportCablesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { pickLocation } from "../amounts/pickLocation";
import { exportCablesInParkWithTypeFamily } from "state/jotai/exportCable";
import { isDefined } from "utils/predicates";
import { vesselTypesFamily } from "state/jotai/vesselType";

const DEFAULT_SUPPLY_COST: CostWithUnit = {
  cost: DefaultCosts.exportCable.exportCable[CostUnit.millionEuroPerKM],
  unit: CostUnit.millionEuroPerKM,
  confidenceLevel: ConfidenceLevel.notSpecified,
};

const DEAFAULT_SHIPPING_COST: CostWithUnit = {
  cost: 0,
  unit: CostUnit.millionEuro,
  confidenceLevel: ConfidenceLevel.notSpecified,
};

const projectExportCableCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, CostWithUnit & { id: string }>>>(async (get) => {
    const projectIdPromise = get(getProjectId(id));
    const costConfig = await get(getConfiguration(id));
    const projectCableTypes = await get(
      projectExportCableTypesFamily(await projectIdPromise),
    );

    const projectComponentCosts =
      costConfig.capex.projectComponentCosts.exportCable;

    const [customCostCables, defaultCostCables] = partition(
      [...projectCableTypes],
      ([id]) => id in projectComponentCosts,
    );

    const customCosts = customCostCables.map(([cableTypeId]) => ({
      ...projectComponentCosts[cableTypeId],
      confidenceLevel: ConfidenceLevel.notSpecified,
    }));

    const defaultCosts = defaultCostCables.map(([cableTypeId]) => ({
      id: cableTypeId,
      ...DEFAULT_SUPPLY_COST,
    }));

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.id, entry]));
  }),
);

const libraryExportCableCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, ComponentProcurementCostForPark>>>(async (get) => {
    const projectId = await get(getProjectId(id));

    const libraryExportCableTypes = await get(
      libraryExportCableTypesFamily(projectId),
    );

    const libraryExportCableCosts = await get(
      listProcurementCostForLibraryExportCablesOnNodeAtomFamily(projectId),
    );

    const [customCostExportCables, defaultCostExportCables] = partition(
      [...libraryExportCableTypes],
      ([id]) => libraryExportCableCosts.has(id),
    );

    const parkCenter = await get(getParkOrSubareaCenter(id));

    const customCosts = customCostExportCables.map(([exportCableTypeId]) => {
      const cost = libraryExportCableCosts.get(exportCableTypeId);

      if (!cost) throw new Error("Library cable cost not found");

      const shippingCost = pickLocation(cost.shippingCost, parkCenter);

      return {
        componentTypeId: exportCableTypeId,
        supplyCost: cost.supplyCost
          ? {
              cost: cost.supplyCost.cost,
              unit: _CostUnit.parse(cost.supplyCost.unit),
              confidenceLevel: ConfidenceLevel.notSpecified,
            }
          : DEFAULT_SUPPLY_COST,
        shippingCost,
      };
    });

    const defaultCosts = defaultCostExportCables.map(([exportCableTypeId]) => ({
      componentTypeId: exportCableTypeId,
      supplyCost: DEFAULT_SUPPLY_COST,
      shippingCost: DEAFAULT_SHIPPING_COST,
    }));

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.componentTypeId, entry]));
  }),
);

const exportCableCostsInParkFamily = atomFamily((id: FinanceId) =>
  atom<Promise<ComponentProcurementCostForPark[]>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const exportCables = await get(
      exportCablesInParkWithTypeFamily({ parkId, branchId }),
    );

    const projectExportCableCosts = await get(
      projectExportCableCostsFamily(id),
    );

    const libraryExportCableCosts = await get(
      libraryExportCableCostsFamily(id),
    );

    const exportCableTypeIdsInPark = dedup(
      exportCables.flatMap(([_, offshoreType, onshoreType]) => [
        offshoreType.id,
        onshoreType.id,
      ]),
    );

    const libraryCosts: ComponentProcurementCostForPark[] =
      exportCableTypeIdsInPark
        .map((id) => libraryExportCableCosts.get(id))
        .filter(isDefined);

    const projectCosts: ComponentProcurementCostForPark[] =
      exportCableTypeIdsInPark
        .map((cableTypeId) => projectExportCableCosts.get(cableTypeId))
        .filter(isDefined)
        .map(({ id: componentTypeId, cost, unit, confidenceLevel }) => ({
          componentTypeId,
          supplyCost: {
            cost,
            unit,
            confidenceLevel,
          },
          shippingCost: undefined,
        }));

    return [...libraryCosts, ...projectCosts];
  }),
);

export const exportCableCostsSelectorFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const {
      capex: { custom, fixed, installation },
    } = await get(getConfiguration(id));

    const allExportCableTypes = await get(
      exportCableTypesFamily({ projectId: undefined }),
    );

    const exportCableCosts = await get(exportCableCostsInParkFamily(id));

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.ExportCable)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.ExportCable,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] =
      custom
        .filter((c) => c.category === CostType.ExportCable)
        .filter((c) => c.unit !== CostUnit.millionEuro)
        .flatMap((custom) => ({
          ...custom,
          name: `${custom.name}`,
          id: costId({
            category: CostType.ExportCable,
            costId: custom.id,
            featureTypeId: CostType.ExportCable,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
          }),
        })) ?? [];

    let fixedCosts: Cost[] = [];

    if (isFeatureCost(fixed.exportCable)) {
      exportCableCosts.forEach(
        ({
          supplyCost,
          shippingCost,
          componentTypeId: cableTypeId,
        }: ComponentProcurementCostForPark) => {
          const { name } = allExportCableTypes.get(cableTypeId) ?? {
            name: "Unknown",
          };

          fixedCosts.push({
            id: `export_cable_${cableTypeId}`,
            amountId: amountId({
              unit: unitToAmountUnit[supplyCost.unit],
              category: CostType.ExportCable,
              featureTypeId: cableTypeId,
            }),
            category: CostType.ExportCable,
            name,
            cost: supplyCost.cost,
            unit: supplyCost.unit,
            confidenceLevel: undefined,
          });

          shippingCost?.cost &&
            fixedCosts.push({
              id: `export_cable_shipping_${cableTypeId}`,
              amountId: amountId({
                unit: unitToAmountUnit[CostUnit.millionEuro],
                category: CostType.ExportCable,
              }),
              category: CostType.ExportCable,
              name: `Shipping ${name}`,
              cost: shippingCost.cost,
              // Note: this is a quickfix - shippingCost.unit is currently wrongly set to millionEuro/unit
              unit: CostUnit.millionEuro,
              confidenceLevel: undefined,
            });
        },
      );
    } else if (isOverrideCost(fixed.exportCable)) {
      const { cost, unit, confidenceLevel } = fixed.exportCable;
      fixedCosts.push({
        id: `export_cable`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.ExportCable,
        }),
        category: CostType.ExportCable,
        name: "Export cable",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    let installationCosts: Cost[] = [];

    if (isOperationsCost(installation.exportCable)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.exportCable;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      const { vesselId, dayRate } = installationVessel;
      const { cost, unit: costUnit } = dayRate;
      const unit = _CostUnit.parse(costUnit);
      const name = vesselTypes.get(vesselId)?.name ?? "";

      installationCosts.push({
        id: `export_cable_installation_${vesselId}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.ExportCable,
          featureTypeId: vesselId,
        }),
        category: CostType.ExportCable,
        name,
        cost,
        unit,
        confidenceLevel: undefined,
      });
    } else if (isOperationsOverrideCost(installation.exportCable)) {
      const { cost, unit, confidenceLevel } = installation.exportCable;
      installationCosts.push({
        id: `export_cable_installation`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.ExportCable,
        }),
        category: CostType.ExportCable,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    return [...fixedCosts, ...customCosts, ...flatCosts, ...installationCosts];
  }),
);
