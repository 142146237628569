import { mapAtom } from "state/map";
import { useEffect, useMemo } from "react";
import { windLayerHeightAtom, windLayerSourceAtom } from "../state/layer";
import {
  defaultWindSpeedRasterMinMax,
  getActiveOtherMapSelector,
  windSpeedRasterMinMaxAtom,
} from "../state/map";
import { CustomLayerInterface, MercatorCoordinate } from "mapbox-gl";
import { scream } from "../utils/sentry";
import { Map as MapboxMap } from "mapbox-gl";
import { useAtomValue } from "jotai";
import { MesoWindDataSource } from "types/metocean";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { MaybePromise } from "types/utils";
import { wrapMapboxTile } from "types/tile";

const MAX_ZOOM = 7;

const tileSize = 512;

export const COLORS = {
  default: [
    [12, 44, 132, 255],
    [34, 94, 168, 255],
    [29, 145, 192, 255],
    [65, 182, 196, 255],
    [127, 205, 187, 255],
    [199, 233, 180, 255],
    [237, 248, 177, 255],
    [255, 255, 217, 255],
  ],
};

class WindspeedOnTerrainLayer implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "3d";
  program: any;
  vertexBuffer: any;
  map: MapboxMap | undefined;
  minMax: [number, number] = defaultWindSpeedRasterMinMax;
  colors: number[][];
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;
  windLayerHeight: number;
  windSpeedSource: MesoWindDataSource;
  paletteTexture: WebGLTexture | undefined;
  isSubmitted: boolean;
  constructor(
    id: string,
    minMax: [number, number],
    maxZoom: number,
    tileSize: number,
    windLayerHeight: number,
    windSpeedSource: MesoWindDataSource,
  ) {
    this.id = id;
    this.type = "custom";
    this.renderingMode = "3d";
    this.minMax = minMax;
    this.colors = COLORS.default;
    this.textureCache = new Map();
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.windLayerHeight = windLayerHeight;
    this.windSpeedSource = windSpeedSource;
    this.isSubmitted = false;
  }

  urlFunc = (z: number, x: number, y: number) =>
    `/tiles/${this.windSpeedSource}/speed/${this.windLayerHeight}/${z}/${x}/${y}.png`;

  setMinMax = (minMax: [number, number]) => {
    this.minMax = minMax;
  };

  setWindLayerHeight = (windLayerHeight: number) => {
    this.windLayerHeight = windLayerHeight;
    this.textureCache = new Map();
  };

  setWindSpeedSource = (windSpeedSource: MesoWindDataSource) => {
    this.windSpeedSource = windSpeedSource;
    this.textureCache = new Map();
  };

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;

    const vertexSource = `
      attribute vec2 a_pos;
      varying vec2 v_pos;
      uniform vec2 u_minMax;
      void main() {
        v_pos = vec2(a_pos / 1.);
        gl_Position = vec4(a_pos, 1.0, 1.0);
      }`;

    const fragmentSource = `
      precision highp float;
      varying vec2 v_pos;
      uniform sampler2D u_raster;
      uniform vec2 u_minMax;
      uniform sampler2D u_palette;
      uniform vec3 u_color;
      uniform vec4 u_uv_offsets;

      void main() {
          vec2 uv = 0.5 * v_pos + 0.5;
          uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
          uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

          vec4 value = texture2D(u_raster, uv);
          float speed = value.r * 10. + 3.;
          float c = (speed - u_minMax.x) / (u_minMax.y - u_minMax.x);
          vec4 color = texture2D(u_palette, vec2(c, .5));
          if (value.a == 0.0) {
            gl_FragColor = vec4(0, 0, 0, 0);
          } else {
            gl_FragColor = color;
          }
      }`;

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader) throw scream("windSpeed: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("windSpeed: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS))
      throw scream("Failed to create program", {
        program: this.program,
        vertexShader,
        fragmentShader,
        programInfo: gl.getProgramInfoLog(this.program),
        vertexInfo: gl.getShaderInfoLog(vertexShader),
        fragmentInfo: gl.getShaderInfoLog(fragmentShader),
      });

    this.program.aPos = gl.getAttribLocation(this.program, "a_pos");
    this.program.uColor = gl.getUniformLocation(this.program, "u_color");

    const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);

    const palette = new Uint8Array(this.colors.flatMap((v) => v));
    this.paletteTexture = gl.createTexture() ?? undefined;
    if (!this.paletteTexture) throw scream("Failed to create texture");
    gl.bindTexture(gl.TEXTURE_2D, this.paletteTexture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      this.colors.length,
      1,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      palette,
    );
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  };

  shouldRerenderTiles() {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  }

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.map) return;
    const texture = getBestAvailableTexture(
      gl,
      tileId,
      this.textureCache,
      this.map,
      this.urlFunc,
      this.maxZoom,
      this.tileSize,
      false,
      () => this.isSubmitted,
    );

    gl.useProgram(this.program);

    if (this.paletteTexture) {
      gl.uniform1i(gl.getUniformLocation(this.program, "u_palette"), 2);
      gl.activeTexture(gl.TEXTURE2);
      gl.bindTexture(gl.TEXTURE_2D, this.paletteTexture);
    } else {
      console.error("Missing palette texture");
    }

    gl.uniform2fv(gl.getUniformLocation(this.program, "u_minMax"), this.minMax);
    gl.uniform4fv(
      gl.getUniformLocation(this.program, "u_uv_offsets"),
      texture.uvOffsets,
    );
    // Set up the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_raster"), 0);

    // Draw
    this.draw(gl, this.map);
  };

  draw = (gl: WebGL2RenderingContext, map: MapboxMap | undefined) => {
    if (!map) return;
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.program.aPos);
    gl.vertexAttribPointer(this.program.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  };

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const SpeedLayer = () => {
  const activeOtherMap = useAtomValue(getActiveOtherMapSelector);
  return <> {activeOtherMap?.id === "wind" && <ActiveSpeedLayer />} </>;
};

const ActiveSpeedLayer = ({
  inputWindLayerHeight,
  source,
}: {
  inputWindLayerHeight?: number;
  source?: MesoWindDataSource;
}) => {
  const stateMap = useAtomValue(mapAtom);
  const stateWindLayerHeight = useAtomValue(windLayerHeightAtom);
  const windSource = useAtomValue(windLayerSourceAtom);
  const windSpeedRasterMinMax = useAtomValue(windSpeedRasterMinMaxAtom);

  const map = stateMap;
  const windLayerHeight =
    inputWindLayerHeight !== undefined
      ? inputWindLayerHeight
      : stateWindLayerHeight;

  const speedLayer = useMemo(
    () =>
      new WindspeedOnTerrainLayer(
        "speed-source-speed-layer",
        defaultWindSpeedRasterMinMax,
        MAX_ZOOM,
        tileSize,
        150,
        MesoWindDataSource.GWA,
      ),
    [],
  );

  useEffect(() => {
    speedLayer.setWindSpeedSource(source || windSource);
    if (!map) return;
    map.triggerRepaint();
  }, [map, windSource, source, speedLayer]);

  useEffect(() => {
    speedLayer.setMinMax(windSpeedRasterMinMax);
    if (!map) return;
    map.triggerRepaint();
  }, [map, windSpeedRasterMinMax, speedLayer]);

  useEffect(() => {
    speedLayer.setWindLayerHeight(windLayerHeight);
    if (!map) return;
    map.triggerRepaint();
  }, [map, windLayerHeight, speedLayer]);

  useEffect(() => {
    if (!map) return;
    map.addLayer(speedLayer, "bridge-rail");

    return () => {
      if (map) {
        map.removeLayer(speedLayer.id);
      }
    };
  }, [map, speedLayer]);
  return null;
};

export default SpeedLayer;
