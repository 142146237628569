import mixpanel from "mixpanel-browser";
import { DesignToolMode } from "types/map";
mixpanel.init("63a3a6a3beca5b8ccf6c4cd666e6cb05", {
  api_host: "/api/lenapxim",
});

let inDevelopment = process.env.NODE_ENV === "development";

/**
 * Capabilities that can be tracked in analytics
 * We use the enum to ensure you look through existing capabilities before adding a new one.
 * Please do not change names of existing capabilities, as it affects existing events.
 */
export enum TrackingCapability {
  Design = "design",
  Analysis = "analysis",
  Report = "report",
  Configuration = "configuration",
  Portfolio = "portfolio",
  Navigation = "navigation",
  Organisation = "organisation",
}

/**
 * Features that can be tracked in analytics
 * We use the enum to ensure you look through existing features before adding a new one
 * Please do not change names of existing capabilities, as it affects existing events.
 */
export enum TrackingFeature {
  Optimize = "turbine optimisation",
  Yield = "yield",
  Portfolio = "portfolio",
  Search = "search",
  OrgSettings = "organisation settings",
  ThreeDMode = "3D mode",
  SlideShow = "slideshow",
}

let actions = {
  identify: (id: string) => {
    if (!inDevelopment) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (!inDevelopment) mixpanel.alias(id);
  },
  /**
   * @deprecated: Use track_v2 for all new tracking events.
   */
  track_old: (name: string, props: any) => {
    if (!inDevelopment) mixpanel.track(name, props);
  },
  /**
   * Track an event following our naming convention.
   * @param capability - The capability that the event belongs to
   * @param feature - The feature that the event belongs to
   * @param action - The action being performed. Example: "clicked", "viewed", "applied" or similar
   * @param props - Any additional properties to track
   */
  track: (
    capability: TrackingCapability,
    feature: TrackingFeature,
    action: string,
    props?: Record<string, any>,
  ) => {
    if (!inDevelopment)
      mixpanel.track(`${capability} - ${feature} - ${action}`, props);
  },
  people: {
    set: (props: any) => {
      if (!inDevelopment) mixpanel.people.set(props);
    },
    set_once: (props: any) => {
      if (!inDevelopment) mixpanel.people.set_once(props);
    },
  },
  project_mode: {
    set: (mode: DesignToolMode) => {
      if (!inDevelopment) mixpanel.register_once({ project_mode: mode });
    },
    remove: () => {
      if (!inDevelopment) mixpanel.register_once({ project_mode: undefined });
    },
  },
  register: (props: any) => {
    mixpanel.register(props);
  },
  stop_tracking: () => {
    mixpanel.opt_out_tracking();
  },
  start_tracking: () => {
    mixpanel.opt_in_tracking();
  },
};

export let Mixpanel = actions;
