import React, { forwardRef } from "react";
import Close from "@icons/24/Close.svg?react";
import { Comp } from "../../types/utils";
import { Frame, Row } from "../General/Layout";
import styled from "styled-components";
import { IconBtn } from "../General/Icons";
import { spaceLarge, spaceMedium } from "../../styles/space";
import { colors } from "styles/colors";
import { typography } from "styles/typography";

export const LeftMenuPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: calc(var(--side-bars-width) + ${spaceMedium});
  height: 100%;
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

export const RightMenuPositionWrapper = styled.div`
  display: flex;
  height: fit-content;
  position: absolute;
  right: calc(var(--side-bars-width) + ${spaceMedium});
  top: 0;
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

export const BottomMenuPositionWrapper = styled.div<{ parkSelected?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: calc(var(--side-bars-width) + ${spaceMedium});
  right: ${(p) =>
    p.parkSelected ? `calc(var(--side-bars-width) + ${spaceMedium})` : "0"};
  transition: right 0.3s ease-in-out;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${spaceLarge} ${spaceLarge} ${spaceLarge};
`;

const HeaderTitle = styled.h3`
  margin: 0;
`;

const HeaderSubtitle = styled.div`
  ${typography.sub2}
  color: ${colors.textDisabled};
  margin: 0;
`;

type MenuFrameProps = {
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  validationError?: JSX.Element;
  iconLeftSide?: JSX.Element;
  onExit?: React.MouseEventHandler<HTMLElement>;
  headerId?: string;
  isLoading?: boolean;
  blockWhenLoading?: boolean;
  headerStyle?: React.CSSProperties;
  settingsMenu?: React.ReactNode;
  resizeLeftHorizontal?: boolean;
};

const LoadingBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  background-color: ${colors.indigo100};
  height: 5px;
  border-radius: 4px;
`;

const Loader = styled.div<{ progress?: number }>`
  width: ${(props) =>
    props.progress !== undefined ? `${props.progress}%` : "50%"};
  position: relative;
  height: 5px;
  top: 0;
  z-index: 1;
  background-color: ${colors.indigo600};
  border-radius: 5px;
  ${(props) =>
    props.progress === undefined &&
    `
    animation: loading 2s ease-in-out infinite;
    @keyframes loading {
      0% {
        left: -50%;
      }
      75% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
  `}
  ${(props) =>
    props.progress !== undefined &&
    `
    left: 0;
    transition: width 0.3s ease-in-out;
  `}
`;

export const LoadingBarWrapper = ({
  block,
  style,
  progress,
}: {
  block: boolean;
  style?: React.CSSProperties;
  progress?: number;
}) => {
  if (!block) {
    return (
      <LoadingBar style={style}>
        <Loader progress={progress} />
      </LoadingBar>
    );
  }

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        top: 0,
        left: 0,
        padding: 0,
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: `${colors.white}aa`,
        ...style,
      }}
    >
      <LoadingBar>
        <Loader progress={progress} />
      </LoadingBar>
    </div>
  );
};

export const MenuFrame = forwardRef<
  HTMLDivElement,
  Comp<"div", MenuFrameProps>
>(
  (
    {
      title,
      subtitle,
      icon,
      onExit,
      isLoading,
      iconLeftSide,
      validationError,
      style,
      headerStyle,
      children,
      headerId,
      blockWhenLoading = true,
      id,
      settingsMenu,
      resizeLeftHorizontal,
    },
    ref,
  ) => (
    <Frame
      style={{
        ...style,
        ...(resizeLeftHorizontal && {
          resize: "horizontal",
          direction: "rtl",
        }),
      }}
      ref={ref}
      id={id}
    >
      {isLoading && <LoadingBarWrapper block={blockWhenLoading} />}
      <HeaderWrapper style={{ ...headerStyle, direction: "ltr" }}>
        <HeaderRow>
          <HeaderTitle id={headerId}>
            {iconLeftSide}
            {title}
            {validationError}
            {icon}
          </HeaderTitle>
          <Row alignCenter>
            {settingsMenu && settingsMenu}
            {onExit && (
              <>
                <IconBtn id={"menu-popup-close"} size="1.4rem" onClick={onExit}>
                  <Close />
                </IconBtn>
              </>
            )}
          </Row>
        </HeaderRow>
        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      </HeaderWrapper>
      {resizeLeftHorizontal ? (
        <div style={{ direction: "ltr" }}>{children}</div>
      ) : (
        children
      )}
    </Frame>
  ),
);
