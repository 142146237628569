import { SkeletonBlock } from "components/Loading/Skeleton";

const Fallback = () => {
  return (
    <>
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
      <SkeletonBlock
        style={{
          height: "5rem",
        }}
      />
    </>
  );
};

export default Fallback;
