import { ViewpointFeature } from "types/feature";
import {
  viewPointLayerId,
  viewPointSourceId,
  viewPointSymbolLayerId,
} from "./constants";
import { atom, useAtomValue } from "jotai";
import { CirclePaint, SymbolLayer } from "mapbox-gl";
import { isOnshoreAtom } from "state/onshore";
import { colors } from "styles/colors";
import {
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import { useEffect, useMemo } from "react";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import { safeRemoveLayer } from "utils/map";
import { pointOpacityOutsidePark } from "./defaults";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

export const viewPointCirclePaintAtom = atom<CirclePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "circle-color": onshore ? colors.onElViewpoint : colors.viewpoint,
    "circle-opacity": pointOpacityOutsidePark,
    "circle-radius": defaultPointCircleRadius,
    "circle-stroke-color": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      colors.lockedFeatureOutline,
      ["!=", ["feature-state", "borderColor"], null],
      ["feature-state", "borderColor"],
      ["boolean", ["feature-state", "selected"], false],
      onshore ? colors.onElViewpointBorderSel : colors.white,
      onshore ? colors.onElViewpoint : colors.viewpoint,
    ],
    "circle-stroke-width": [
      "case",
      ["==", ["get", lockedPropertyName], true],
      2.0,
      ["!=", ["feature-state", "borderColor"], null],
      2.0,
      ["boolean", ["feature-state", "selected"], false],
      2.0,
      ["boolean", ["feature-state", "hover"], false],
      2.0,
      0.0,
    ],
  };
});

const viewpointSymbolLayer: SymbolLayer = {
  id: viewPointSymbolLayerId,
  source: viewPointSourceId,
  type: "symbol",
  minzoom: 8,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-offset": [0, -2],
    "text-size": 10,
    "text-anchor": "center",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderViewpoints = ({
  features,
  map,
  symbols,
  showSelectionHighlighter,
}: {
  features: ViewpointFeature[];
  map: mapboxgl.Map;
  symbols?: boolean;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const circlePaint = useAtomValue(viewPointCirclePaintAtom);
  useEffect(() => {
    map.addSource(viewPointSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: viewPointLayerId,
      type: "circle",
      source: viewPointSourceId,
      paint: circlePaint,
    });
    if (symbols) {
      addLayer(map, viewpointSymbolLayer);
    }

    return () => {
      if (!map) return;
      safeRemoveLayer(map, viewPointLayerId);
      if (symbols) {
        safeRemoveLayer(map, viewpointSymbolLayer.id);
      }
      map.removeSource(viewPointSourceId);
    };
  }, [map, symbols, circlePaint]);

  useEffect(() => {
    const source = map.getSource(viewPointSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={viewPointSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
