import { ReactNode } from "react";
import { atom } from "jotai";

export type SearchObject = {
  title: string;
  onSelect: () => void;
  setFocus?: (focus: boolean) => void;
  id: string;
  tags?: string[];
  icon?: ReactNode;
  disabled?: boolean;
};

export const searchBarOpen = atom<boolean>(false);
export const searchObjectsAtomFamily = atom<SearchObject[]>([]);
export const searchObjectInFocusAtom = atom<string | undefined>(undefined);

export type SearchResultItem<T> = {
  id: string;
  name: string | ReactNode;
  score: number;
  item: T;
  onSelect: () => void;
  setFocus?: (focus: boolean) => void;
  secondarySelect?: {
    name: string;
    onSelect: () => void;
  };
  icon?: ReactNode;
};

export type SearchItem<T> = Omit<SearchResultItem<T>, "score">;

export type SearchGroup = {
  title: string;
  items: SearchResultItem<any>[];
  bestScore: number;
  loading?: boolean;
};
