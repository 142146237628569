import { LibraryTab } from "../state";

import {
  ANALYSIS_TABLE_LIST_ITEM,
  ANALYSIS_TABLE_FOLDER_ITEM,
} from "../analysis/constants";
import {
  CABLE_TABLE_LIST_ITEM,
  CABLE_TABLE_FOLDER_ITEM,
} from "../cable/constants";
import {
  EXPORT_CABLE_TABLE_LIST_ITEM,
  EXPORT_CABLE_TABLE_FOLDER_ITEM,
} from "../exportcable/constants";
import {
  FINANCIAL_TABLE_LIST_ITEM,
  FINANCIAL_TABLE_FOLDER_ITEM,
} from "../financial/constants";
import {
  FOUNDATION_TABLE_LIST_ITEM,
  FOUNDATION_TABLE_FOLDER_ITEM,
} from "../foundation/constants";
import {
  TURBINE_TABLE_LIST_ITEM,
  TURBINE_TABLE_FOLDER_ITEM,
} from "../turbine/constants";
import { LibraryManageRole, LibraryManageRoleType } from "../types";
import {
  SUBSTATION_TABLE_LIST_ITEM,
  SUBSTATION_TABLE_FOLDER_ITEM,
} from "../substation/constants";
import {
  idToAnalysisChangelogId,
  idToCableChangelogId,
  idToExportCableChangelogId,
  idToFinancialChangelogId,
  idToFoundationChangelogId,
  idToSubstationChangelogId,
  idToTurbineChangelogId,
} from "components/InputChangelog/const";

export function tabToName(libraryTab: LibraryTab) {
  switch (libraryTab) {
    case "financial":
      return "Financial";
    case "turbine":
      return "Turbines";
    case "foundation":
      return "Foundations";
    case "cable":
      return "Inter array cables";
    case "exportcable":
      return "Export cables";
    case "analysis":
      return "Analysis ";
    case "datalayers":
      return "GIS packages";
    case "substation":
      return "Substations";
    default:
      return "Library";
  }
}

export const libraryTabToManageRole = (
  libraryTab: Exclude<LibraryTab, undefined>,
): Exclude<LibraryManageRoleType, "org_data_package_manage"> => {
  switch (libraryTab) {
    case "financial":
      return LibraryManageRole.FINANCIAL;
    case "turbine":
      return LibraryManageRole.TURBINE;
    case "foundation":
      return LibraryManageRole.FOUNDATION;
    case "cable":
      return LibraryManageRole.CABLE;
    case "exportcable":
      return LibraryManageRole.EXPORT_CABLE;
    case "analysis":
      return LibraryManageRole.ANALYSIS;
    case "substation":
      return LibraryManageRole.SUBSTATION;
    default:
      throw new Error("Invalid library tab");
  }
};

export const getAcceptableTypes = (libraryManageRole: LibraryManageRole) => {
  switch (libraryManageRole) {
    case LibraryManageRole.TURBINE:
      return [TURBINE_TABLE_LIST_ITEM, TURBINE_TABLE_FOLDER_ITEM];
    case LibraryManageRole.FOUNDATION:
      return [FOUNDATION_TABLE_LIST_ITEM, FOUNDATION_TABLE_FOLDER_ITEM];
    case LibraryManageRole.EXPORT_CABLE:
      return [EXPORT_CABLE_TABLE_LIST_ITEM, EXPORT_CABLE_TABLE_FOLDER_ITEM];
    case LibraryManageRole.CABLE:
      return [CABLE_TABLE_LIST_ITEM, CABLE_TABLE_FOLDER_ITEM];
    case LibraryManageRole.ANALYSIS:
      return [ANALYSIS_TABLE_LIST_ITEM, ANALYSIS_TABLE_FOLDER_ITEM];
    case LibraryManageRole.FINANCIAL:
      return [FINANCIAL_TABLE_LIST_ITEM, FINANCIAL_TABLE_FOLDER_ITEM];
    case LibraryManageRole.SUBSTATION:
      return [SUBSTATION_TABLE_LIST_ITEM, SUBSTATION_TABLE_FOLDER_ITEM];
    default:
      return [];
  }
};

export const getChangelogId = (
  libraryManageRole: LibraryManageRole,
  resourceId: string,
): string => {
  switch (libraryManageRole) {
    case LibraryManageRole.TURBINE:
      return idToTurbineChangelogId(resourceId);
    case LibraryManageRole.FOUNDATION:
      return idToFoundationChangelogId(resourceId);
    case LibraryManageRole.EXPORT_CABLE:
      return idToExportCableChangelogId(resourceId);
    case LibraryManageRole.CABLE:
      return idToCableChangelogId(resourceId);
    case LibraryManageRole.ANALYSIS:
      return idToAnalysisChangelogId(resourceId);
    case LibraryManageRole.FINANCIAL:
      return idToFinancialChangelogId(resourceId);
    case LibraryManageRole.SUBSTATION:
      return idToSubstationChangelogId(resourceId);
    default:
      return "";
  }
};

export const highlightSearchTerm = (text: string, searchTerm?: string) => {
  if (!searchTerm) return text;

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm?.toLowerCase() ? (
      <strong key={index}>{part}</strong>
    ) : (
      part
    ),
  );
};
