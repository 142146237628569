import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import FileIcon from "@icons/24/File.svg?react";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import { UploadFileType } from "./components/NewUploadTab/types";
import { ModalTitleMapper } from "./components/shared";
import { hideUploadModalAtom, selectedUploadFileTypeAtom } from "./state";
import { IconBtn } from "components/General/Icons";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

const NewUploadTab = React.lazy(
  () => import("./components/NewUploadTab/NewUploadTab"),
);

export { UploadFileType };
export const UploadModalType = "UploadModal" as const;

const Modal = styled(StandardBox)`
  color: ${colors.primaryText};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 50vw;
  max-width: 65rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceMedium};

  h2 {
    margin: 0;
  }
`;

const UploadModalInner = ({
  hidden,
  preSelectedFileType,
}: {
  hidden: boolean;
  preSelectedFileType?: UploadFileType;
}) => {
  const [selectedUploadFileType, setSelectedUploadFileType] = useAtom(
    selectedUploadFileTypeAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  useEffect(() => {
    if (preSelectedFileType) {
      setSelectedUploadFileType(preSelectedFileType);
    }
    return () => {
      setSelectedUploadFileType(undefined);
    };
  }, [preSelectedFileType, setSelectedUploadFileType]);

  return (
    <FullScreenModal
      closeOnWrapperClick={false}
      placeOnTopOfOtherModals={true}
      style={
        hidden
          ? {
              visibility: "hidden",
            }
          : undefined
      }
    >
      <Modal>
        <HeaderRow>
          <Title>
            <ModalTitleMapper fileType={selectedUploadFileType} />
          </Title>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
          <IconREMSize height={1.4} width={1.4}>
            <FileIcon />
          </IconREMSize>
          <span style={{ ...typography.regular }}>
            Uploaded data will only be visible for this project’s members.
          </span>
        </div>

        <NewUploadTab onClose={onClose} />
      </Modal>
    </FullScreenModal>
  );
};

const UploadModal = () => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const hideUploadModal = useAtomValue(hideUploadModalAtom);

  if (
    !midScreenModalTypeOpen ||
    midScreenModalTypeOpen?.modalType !== UploadModalType
  ) {
    return null;
  }

  return (
    <UploadModalInner
      hidden={hideUploadModal}
      preSelectedFileType={midScreenModalTypeOpen.metadata?.preSelectedFileType}
    />
  );
};

export default UploadModal;
