import { useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import { projectIdAtom } from "state/pathParams";
import {
  filteredLayerListWithOverlapSelector,
  layerSortConfigAtom,
} from "../../layer-filter-state";
import GridCard from "./GridCard";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layer, LayerWithSearchRelevance } from "types/layers";
import { unwrap } from "jotai/vanilla/utils";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import LayersIcon from "@icons/24/Layers.svg";
import { colors } from "styles/colors";

// Using rem values directly
const CARD_WIDTH = "30rem";
const CARD_HEIGHT = "25rem";
const GRID_GAP = "2.4rem";
const BUFFER_SIZE = 4; // Number of extra rows to render

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 2rem;
  position: relative;
  height: 100%;
  width: 100%;
`;

const VirtualizedGridContent = styled.div`
  position: absolute;
  left: 2rem;
  right: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${CARD_WIDTH});
  gap: ${GRID_GAP};
  width: calc(100% - 4rem);
`;

const DataLayersGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const LayersIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg,
  path {
    stroke: ${colors.iconNegative};
  }
`;

interface DataLayersGridProps {
  setSelectedLayer: (layer: Layer | null) => void;
  setSelectedLayersToAdd: React.Dispatch<React.SetStateAction<string[]>>;
  selectedLayersToAdd: string[];
  handleToggleFavorite?: (layerId: string) => void;
}

const DataLayersGrid = ({
  setSelectedLayer,
  setSelectedLayersToAdd,
  selectedLayersToAdd,
  handleToggleFavorite,
}: DataLayersGridProps) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const filteredLayersResult = useAtomValue(
    unwrap(
      filteredLayerListWithOverlapSelector({
        projectId,
      }),
    ),
  );

  const [sortConfig] = useAtom(layerSortConfigAtom);

  const sortedLayers = useMemo(() => {
    const filteredLayerList = filteredLayersResult?.layers ?? [];

    const layersToSort = [...filteredLayerList] as LayerWithSearchRelevance[];

    return layersToSort.sort((a, b) => {
      const aRelevance = a.searchRelevance ?? -Infinity;
      const bRelevance = b.searchRelevance ?? -Infinity;

      if (aRelevance !== bRelevance) {
        return sortConfig.key === "searchRelevance" &&
          sortConfig.direction === "desc"
          ? bRelevance - aRelevance
          : aRelevance - bRelevance;
      }

      if (a.name < b.name) {
        return sortConfig.key === "name" && sortConfig.direction === "asc"
          ? -1
          : 1;
      }
      if (a.name > b.name) {
        return sortConfig.key === "name" && sortConfig.direction === "asc"
          ? 1
          : -1;
      }
      return 0;
    });
  }, [filteredLayersResult, sortConfig]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 800,
    height: 800,
  });

  // Function to convert rem to pixels based on current font size
  const remToPixels = useCallback((rem: string) => {
    const fontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    return parseFloat(rem) * fontSize;
  }, []);

  // Calculate grid layout parameters
  const gridLayout = useMemo(() => {
    if (containerDimensions.width === 0) return { columns: 0, rows: 0 };

    const cardWidthPx = remToPixels(CARD_WIDTH);
    const gapPx = remToPixels(GRID_GAP);
    const availableWidth = containerDimensions.width - 2 * remToPixels("2rem"); // Account for container padding

    const columns = Math.floor(
      (availableWidth + gapPx) / (cardWidthPx + gapPx),
    );
    const rows = Math.ceil(sortedLayers.length / columns);

    return { columns, rows };
  }, [containerDimensions.width, sortedLayers.length, remToPixels]);

  // Calculate visible range
  const visibleRange = useMemo(() => {
    if (gridLayout.columns === 0) return { startIndex: 0, endIndex: 0 };

    const cardHeightPx = remToPixels(CARD_HEIGHT);
    const gapPx = remToPixels(GRID_GAP);
    const rowHeight = cardHeightPx + gapPx;

    const startRow = Math.max(
      0,
      Math.floor(scrollTop / rowHeight) - BUFFER_SIZE,
    );
    const visibleRows =
      Math.ceil(containerDimensions.height / rowHeight) + 2 * BUFFER_SIZE;

    const startIndex = startRow * gridLayout.columns;
    const endIndex = Math.min(
      (startRow + visibleRows) * gridLayout.columns,
      sortedLayers.length,
    );

    const totalRows = Math.ceil(sortedLayers.length / gridLayout.columns);

    return {
      startIndex,
      endIndex,
      totalHeight: totalRows * rowHeight - gapPx,
      startRow,
    };
  }, [
    scrollTop,
    containerDimensions.height,
    gridLayout.columns,
    sortedLayers.length,
    remToPixels,
  ]);

  // Handle scroll
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  }, []);

  // Update container dimensions on mount and resize
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setContainerDimensions({
          width: containerRef.current.clientWidth,
          height: Math.max(containerRef.current.clientHeight, 800),
        });
      }
    };

    const timeoutIds = [100, 250, 500, 1000].map((delay) =>
      setTimeout(updateDimensions, delay),
    );

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateDimensions();

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
      resizeObserver.disconnect();
    };
  }, []);

  const handleLayerSelection = useCallback(
    (layerId: string) => {
      setSelectedLayersToAdd((prev: string[]) => {
        if (prev.includes(layerId)) {
          return prev.filter((id) => id !== layerId);
        }
        return [...prev, layerId];
      });
    },
    [setSelectedLayersToAdd],
  );

  if (sortedLayers.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 2rem",
          height: "100%",
          width: "100%",
        }}
      >
        <EmptyState
          title="No layers match your search!"
          description="Check your spelling and filter options, or try another keyword."
          icon={
            <LayersIconWrapper>
              <LayersIcon />
            </LayersIconWrapper>
          }
          style={{
            boxSizing: "border-box",
          }}
        />
      </div>
    );
  }

  return (
    <DataLayersGridWrapper>
      <GridContainer ref={containerRef} onScroll={handleScroll}>
        <div style={{ height: visibleRange.totalHeight, position: "relative" }}>
          <VirtualizedGridContent
            style={{
              transform: `translateY(${
                visibleRange.startRow
                  ? visibleRange.startRow *
                    (remToPixels(CARD_HEIGHT) + remToPixels(GRID_GAP))
                  : 0
              }px)`,
            }}
          >
            {sortedLayers
              .slice(visibleRange.startIndex, visibleRange.endIndex)
              .map((layer) => (
                <GridCard
                  key={layer.id}
                  layer={layer}
                  setSelectedLayer={setSelectedLayer}
                  handleLayerSelection={handleLayerSelection}
                  selectedLayersToAdd={selectedLayersToAdd}
                  handleToggleFavorite={handleToggleFavorite}
                />
              ))}
          </VirtualizedGridContent>
        </div>
      </GridContainer>
    </DataLayersGridWrapper>
  );
};

export default DataLayersGrid;
