import { typography } from "../../../../../styles/typography";
import { GridWithFiveColumns } from "../../styles";
import styled from "styled-components";
import { colors } from "styles/colors";
import { localCostConfigurationAtom } from "../../state";
import { CostConfiguration } from "services/costService";
import { useAtomValue } from "jotai";

const Title = styled.div`
  ${typography.sub3}
  color: ${colors.textSecondary};
`;

const BlueDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.blue500}};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

export const CostTopRow = () => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  return (
    <Wrapper>
      <GridWithFiveColumns style={{ padding: 0 }}>
        <Title>Type</Title>
        <Title>Cost</Title>
        {useConfidenceLevel && <Title>Confidence level</Title>}
        {showFreeTextCapex && <Title>Free text</Title>}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
      </GridWithFiveColumns>
      <BlueDivider />
    </Wrapper>
  );
};
