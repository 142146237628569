import React, { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { addSvgStrokeOrFill } from "utils/styleUtils";
import { colors } from "../../styles/colors";
import { typography } from "../../styles/typography";
import Tooltip from "./Tooltip";
import { SkeletonBlock } from "../Loading/Skeleton";
import {
  borderRadius,
  borderRadiusLarge,
  spaceDecent,
  spaceSmall,
  spacing1,
  spacing3,
  spacing4,
  spacing6,
  spacing7,
} from "../../styles/space";
import { isDefined } from "utils/predicates";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: React.ReactNode;
  icon?: ReactNode;
  iconText?: ReactNode;
  buttonType?: "primary" | "primary-dark" | "secondary" | "text" | "dropdown";
  size?: "tiny" | "small" | "medium";
  iconPosition?: "left" | "right";
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
  skeleton?: boolean;
  maxWidth?: number;
}

export const StyledButton = styled.button<{
  iconPosition: ButtonProps["iconPosition"];
}>`
  transition:
    all 200ms ease,
    visibility 0s ease;
  display: flex;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === "right" ? "row-reverse" : "row"};

  min-width: fit-content;
  max-width: fit-content;

  align-items: center;
  justify-content: space-around;

  border: none;
  border-radius: ${borderRadius.small};

  height: 3.2rem;
  padding: ${spacing3} ${spacing7};
  gap: 0.6rem;

  text-align: center;

  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }

  span {
    white-space: nowrap;
  }

  ${typography.body}

  svg {
    ${addSvgStrokeOrFill(colors.iconDefault)}
    min-width: 1.2rem;
    width: 1.2rem;
    min-height: 1.2rem;
    height: 1.2rem;
    overflow: visible;
    ${(p) =>
      p.iconPosition === "right"
        ? "margin-right: -0.7rem;"
        : "margin-left: -0.7rem;"}
    // If we only have an icon, make it have zero width;
    :only-child {
      margin: 0 -0.7rem;
    }
  }

  &.tiny svg {
    min-width: 0.8rem;
    width: 0.8rem;
    min-height: 0.8rem;
    height: 0.8rem;
  }

  &.small {
    height: 2.4rem;
    padding: ${spacing1} ${spacing6};
  }

  &.tiny {
    height: 1.2rem;
    padding: ${spacing1} ${spacing6};
    font-size: 1rem;
  }

  &.primary,
  &.primary-dark {
    color: ${colors.textNegative};
    background: ${colors.surfaceButtonPrimary};
    ${addSvgStrokeOrFill(colors.iconNegative)}

    &:hover {
      background: ${colors.blue600};
    }
    &:active {
      background: ${colors.blue700};
    }
  }

  &.secondary {
    color: ${colors.iconBrand};
    background: ${colors.surfaceButtonSecondary};
    ${addSvgStrokeOrFill(colors.iconBrand)}

    &:hover {
      background: ${colors.indigo100};
    }
    &:active {
      background: ${colors.indigo200};
    }
  }

  &.text {
    color: ${colors.brand};
    background: unset;
    padding: ${spacing3} 0;
    ${addSvgStrokeOrFill(colors.brand)}

    svg {
      margin: 0;
      // Text button with only an icon should have more widith so that it's not super tiny.
      &:only-child {
        padding: 0 ${spacing4};
      }
    }
    &:hover {
      color: ${colors.blue700};
      ${addSvgStrokeOrFill(colors.blue700)}
    }
    &:active {
      color: ${colors.blue800};
      ${addSvgStrokeOrFill(colors.blue800)}
    }
  }

  &:not(.primary-dark):disabled {
    color: ${colors.textDisabled} !important;
    :not(.text) {
      background: ${colors.surfaceDisabled} !important;
    }
    &&& {
      ${addSvgStrokeOrFill(colors.textDisabled)}
    }
  }

  &.primary-dark:disabled {
    color: ${colors.textDisabled} !important;
    :not(.text) {
      background: ${colors.blue800} !important;
    }
    &&& {
      ${addSvgStrokeOrFill(colors.textDisabled)}
    }
  }

  &.dropdown {
    border: 1px solid ${colors.dropdownBorder};
    box-sizing: border-box;
    background-color: ${colors.dropdownBackground};
    color: ${colors.primaryText};
    ${addSvgStrokeOrFill(colors.primaryText)}

    :hover {
      border: 1px solid ${colors.dropdownBorderHover};
      ${addSvgStrokeOrFill(colors.primaryText)}
    }
  }
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      icon,
      iconText,
      buttonType = "primary",
      tooltip,
      iconPosition,
      size = "medium",
      skeleton,
      maxWidth,
      className,
      ...props
    },
    ref,
  ) => {
    const btn = (
      <StyledButton
        ref={ref}
        className={[buttonType, size, className].filter(isDefined).join(" ")}
        {...props}
        style={{ position: "relative", ...props.style }}
        iconPosition={iconPosition}
      >
        {icon ? icon : undefined}
        {text && (
          <span
            style={
              maxWidth
                ? {
                    maxWidth: `${maxWidth}rem`,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            {iconText}
            {text}
          </span>
        )}
        {skeleton && (
          <SkeletonBlock
            style={{
              width: "initial",
              height: "initial",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              position: "absolute",
              borderRadius: borderRadiusLarge,
              margin: `${spaceSmall} ${spaceDecent}`,
            }}
          />
        )}
      </StyledButton>
    );

    if (tooltip) return <Tooltip text={tooltip}>{btn}</Tooltip>;
    return btn;
  },
);

export default Button;
