import {
  AnalysisStatus,
  AnalysisWindStats,
  Percentile,
  fetchAnalysisWindStats,
  fetchLatestAnalysisVersion,
  fetchStats,
  triggerAnalysis,
  Stats,
} from "functions/production";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import {
  ProdId,
  getOtherLosses,
  getProjectId,
  getTriggerAnalysisArgs,
  getTriggerAnalysisRefresh,
  getTurbineCapacity,
  getTurbineTypes,
  getTurbines,
} from "./inputs";
import {
  AnalysisError,
  AnalysisStoppedTypes,
  getParkTurbineTypeIsMissing,
  getStoppedReason,
} from "./warnings";
import { getExportSystemAnalysis, getInterArrayAnalysis } from "./electrical";
import {
  computeCapacityFactor,
  computeGrossEnergyProductionPerTurbine,
  computeInternalWakeLoss,
  computeLoss,
  computePostWakeEnergyProductionPerTurbine,
} from "components/ProductionV2/functions";
import { TurbineStat } from "components/ProductionV2/types";
import { sum, zip } from "utils/utils";
import { scream } from "utils/sentry";

/**
 * Analysis status received async over Ably.
 *
 * The input `id` is **not** the {@link ProdId}, but rather the `id` you get
 * back from the POST to Octopus.
 */
export const analysisAblyStatus = atomFamily(
  (_: { analysisStatusId: string }) =>
    atom<AnalysisStatus | undefined>(undefined),
);

const getTriggerAnalysis = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStatus>>(async (get, { signal }) => {
    get(getTriggerAnalysisRefresh(id)); // TODO: should be able to remove this by using Jotai's RESET mechanism instead.
    const args = await get(getTriggerAnalysisArgs(id));
    const stoppedReason = await get(getStoppedReason(id));
    if (stoppedReason) {
      throw new AnalysisError(stoppedReason);
    }
    if (signal.aborted) throw new Error("cancelled");
    const res = await triggerAnalysis(args);
    return res;
  }),
);

/**
 * You need to set {@link analysisOverrideInputAtomFamily} with the {@link ProdId} before this will return.
 */
export const getAnalysisResponse = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStatus>>(async (get) => {
    // Either we use the response from the fetch call, or we look at the atom
    // that is set by the ably subscription.
    const triggerStatus = await get(getTriggerAnalysis(id));
    const ablyStatus = get(
      analysisAblyStatus({ analysisStatusId: triggerStatus.id }),
    );
    const status = ablyStatus ?? triggerStatus;
    return status;
  }),
);

export const getAnalysisVersion = atomFamily((id: ProdId) =>
  atom<Promise<string>>(async (get) => {
    const ana = await get(getAnalysisResponse(id));
    return ana.version;
  }),
);

export const getIsOutdated = atomFamily((id: ProdId) =>
  atom<Promise<boolean>>(async (get) => {
    function removePatchVersion(version: string): string {
      return version.split(".").slice(0, 2).join(".");
    }
    try {
      const analysis = await get(getAnalysisResponse(id));
      if (analysis?.status !== "complete") return false;
      const currentVersion = await fetchLatestAnalysisVersion();
      const thisMinor = removePatchVersion(analysis.version);
      const latestMinor = removePatchVersion(currentVersion);
      return thisMinor !== latestMinor;
    } catch (_) {
      return false;
    }
  }),
);

export const getAnalysisProgress = atomFamily((id: ProdId) =>
  atom<Promise<number | undefined>>(async (get) => {
    const { progress } = await get(getAnalysisResponse(id));
    return progress;
  }),
);

type AnalysisComplete = AnalysisStatus & {
  status: "complete";
};
const analysisIsComplete = (a: AnalysisStatus): a is AnalysisComplete =>
  a.status === "complete";

type AnalysisStopped = AnalysisStatus & {
  status: "stopped";
  reason: AnalysisStoppedTypes;
};
const analysisIsStopped = (a: AnalysisStatus): a is AnalysisStopped => {
  return a.status === "stopped" && a.reason !== undefined && a.reason !== null;
};

export const getAnalysisComplete = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisComplete>>(async (get) => {
    const ana = await get(getAnalysisResponse(id));
    if (analysisIsComplete(ana)) {
      return ana;
    }
    if (analysisIsStopped(ana)) {
      throw new AnalysisError(ana.reason);
    }
    if (["running", "started"].includes(ana.status)) {
      await new Promise(() => {});
    }

    scream(new Error("Analysis failed"), { analysis: ana });
    throw new AnalysisError(AnalysisStoppedTypes.Unknown);
  }),
);

export const maxNetPowerLimit = atomFamily((_id: ProdId) =>
  atom<number | undefined>(undefined),
);

export const getStatsError = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisStoppedTypes | null>>(async (get) => {
    try {
      await get(getStats(id));
      return null;
    } catch (error) {
      if (error instanceof AnalysisError) return error.type;
      return AnalysisStoppedTypes.Unknown;
    }
  }),
);

export const getStats = atomFamily((id: ProdId) =>
  atom<Promise<Stats>>(async (get) => {
    const [ana, nodeId, otherLoss, interArrayAnalysis, exportSystemAnalysis] =
      await Promise.all([
        get(getAnalysisComplete(id)),
        get(getProjectId(id)),
        get(getOtherLosses(id)),
        get(getInterArrayAnalysis(id)),
        get(getExportSystemAnalysis(id)),
      ]);
    const maxPower = await get(maxNetPowerLimit(id));
    return fetchStats({
      nodeId,
      id: ana.id,
      version: ana.version,
      otherLosses: otherLoss,
      interArrayAnalysis,
      exportSystemAnalysis,
      maxNetPowerLimit: maxPower,
    });
  }),
);

export const getAnalysisWindStats = atomFamily((id: ProdId) =>
  atom<Promise<AnalysisWindStats>>(async (get) => {
    const ana = await get(getAnalysisComplete(id)); // requrie that the analysis actually finished successfully.
    const projectId = await get(getProjectId(id));
    return fetchAnalysisWindStats({
      nodeId: projectId,
      id: ana.id,
      version: ana.version,
    });
  }),
);

// TODO: Replace `TurbineStat` with `Map<turbineId, Stat>`.
export const getAEPPerTurbine = atomFamily((id: ProdId) =>
  atom<Promise<TurbineStat[]>>(async (get) => {
    const stats = await get(getStats(id));
    const turbines = await get(getTurbines(id));
    return computeGrossEnergyProductionPerTurbine(
      stats.turbineStats.grossPerTurbine,
      stats.turbineStats.turbineSpecificLossPerTurbine,
      stats.turbineStats.totalWakeLossPerTurbine,
      turbines,
    );
  }),
);

export const getTotalWakeLoss = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    const gross = await get(getGrossEnergy(id));
    return computeLoss(
      stats.turbineStats.grossPerTurbine,
      stats.turbineStats.totalWakeLossPerTurbine,
      gross,
    );
  }),
);

export const getTurbineSpecificLoss = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    const gross = await get(getGrossEnergy(id));
    return computeLoss(
      stats.turbineStats.grossPerTurbine,
      stats.turbineStats.turbineSpecificLossPerTurbine,
      gross,
    );
  }),
);

export const getGrossPerTurbine = atomFamily((id: ProdId) =>
  atom<Promise<TurbineStat[]>>(async (get) => {
    const stats = await get(getStats(id));
    const turbines = await get(getTurbines(id));
    const zeros = turbines.map(() => 0);
    return computeGrossEnergyProductionPerTurbine(
      stats.turbineStats.grossPerTurbine,
      zeros,
      zeros,
      turbines,
    );
  }),
);

export const getAEP = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    return stats.net.aep;
  }),
);

export const getMaxPower = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    return stats.net.maxPower;
  }),
);

/**
 * Returns the average wind speed per turbine.  Only makes sense with spatial
 * calibration, otherwise the avg speed is the same, and taken from wind rose.
 */
export const getAverageSpeedPerTurbine = atomFamily((id: ProdId) =>
  atom<Promise<TurbineStat[]>>(async (get) => {
    const stats = await get(getStats(id));
    const turbines = await get(getTurbines(id));
    return zip(
      turbines,
      stats.turbineStats.averageAmbientWindSpeedPerTurbine,
    ).map(([turbine, speed]) => ({
      turbine,
      value: speed,
    }));
  }),
);

export const getAverageTurbineSpeed = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    return (
      sum(stats.turbineStats.averageAmbientWindSpeedPerTurbine) /
      stats.turbineStats.averageAmbientWindSpeedPerTurbine.length
    );
  }),
);

export const getGrossEnergy = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    return sum(stats.turbineStats.grossPerTurbine);
  }),
);

export const getTotalLoss = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const aep = await get(getAEP(id));
    const gross = await get(getGrossEnergy(id));
    return (gross - aep) / gross;
  }),
);

export const getTotalWakeLossPerTurbine = atomFamily((id: ProdId) =>
  atom<Promise<TurbineStat[]>>(async (get) => {
    const { turbines } = await get(getTriggerAnalysisArgs(id));
    const stats = await get(getStats(id));
    return zip(turbines, stats.turbineStats.totalWakeLossPerTurbine).map(
      ([turbine, wakeLoss]) => ({
        turbine,
        value: wakeLoss,
      }),
    );
  }),
);

export const getPostWakeEnergy = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    const turbines = await get(getTurbines(id));
    const perTurbine = computePostWakeEnergyProductionPerTurbine(
      stats.turbineStats.grossPerTurbine,
      stats.turbineStats.totalWakeLossPerTurbine,
      turbines,
    );
    return sum(perTurbine, (p) => p.value);
  }),
);

export const getNetEmpiricalPercentiles = atomFamily((id: ProdId) =>
  atom<Promise<Percentile[]>>(async (get) => {
    const stats = await get(getStats(id));
    return stats.net.percentiles.netEnergyPerYearPercentiles;
  }),
);

export const getAverageMonthlyEnergy = atomFamily((id: ProdId) =>
  atom<Promise<number[]>>(async (get) => {
    const stats = await get(getStats(id));
    return stats.net.percentiles.netEnergyPerMonth;
  }),
);

export const getCapacityFactor = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const stats = await get(getStats(id));
    const capacity = await get(getTurbineCapacity(id));
    return computeCapacityFactor(stats.net.aep, capacity);
  }),
);

export const getCapacityFactorPerTurbine = atomFamily((id: ProdId) =>
  atom<Promise<number[]>>(async (get) => {
    const aepPerTurbine = await get(getAEPPerTurbine(id));
    const turbineTypes = await get(getTurbineTypes(id));
    const illegalTypes = await get(getParkTurbineTypeIsMissing(id));
    if (illegalTypes)
      throw new AnalysisError(AnalysisStoppedTypes.TurbinesNotFound);

    const capacityFactors = aepPerTurbine.map(({ turbine, value }) => {
      const capacity =
        turbineTypes.get(turbine.properties.turbineTypeId)!.ratedPower / 1e3;
      return computeCapacityFactor(value, capacity);
    });

    return capacityFactors;
  }),
);

export const getNeighbourWakeLoss = atomFamily((id: ProdId) =>
  atom<Promise<number>>(async (get) => {
    const totalWakeLoss = await get(getTotalWakeLoss(id));
    const gross = await get(getGrossEnergy(id));
    const stats = await get(getStats(id));
    const internalWakeLoss = computeInternalWakeLoss(
      stats.turbineStats.grossPerTurbine,
      stats.turbineStats.internalWakeLossPerTurbine,
      gross,
    );
    return totalWakeLoss - internalWakeLoss;
  }),
);

export const getProductionHistograms = atomFamily((id: ProdId) =>
  atom<
    Promise<{
      net: {
        bins: number[];
        cumulativeProduction: number[];
      };
    }>
  >(async (get) => {
    const stats = await get(getStats(id));
    const { bins, values } = stats.net.percentiles.netEnergyCDF;

    const net = {
      bins: bins,
      cumulativeProduction: values,
    };
    return {
      net,
    };
  }),
);
