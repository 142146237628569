import { z } from "zod";
import { keysMatch } from "./utils";
import * as spec from "api/customer";

export const { ProjectType: _ProjectType } = spec.components.schemas;

export const _OrganisationAccessRole = z.union([
  z.literal("owner"),
  z.literal("admin"),
  z.literal("member"),
  z.literal("guest"),
]);
export type OrganisationAccessRole = z.infer<typeof _OrganisationAccessRole>;

const _OrganisationAccess = z
  .object({
    role: _OrganisationAccessRole.nullish(),
  })
  .nullish();

export const _UserAccessRole = z.union([
  z.literal("viewer"),
  z.literal("admin"),
  z.literal("editor"),
]);
export type UserAccessRole = z.infer<typeof _UserAccessRole>;
export const UserAccessRoles = ["viewer", "editor", "admin"] as const;
keysMatch<(typeof UserAccessRoles)[number], UserAccessRole>();

const _ProjectAccess = z
  .record(
    z.string(),
    z.object({
      role: _UserAccessRole.nullish(),
    }),
  )
  .nullish();

export const _CollaboratorAccess = z.object({
  role: z.null().optional(),
  projects: _ProjectAccess, // NOTE: when we edit access, we might get back the user without access to anything.
});

export const _MemberAccss = z.object({
  role: _UserAccessRole,
  projects: z.null().optional(),
});

export const _UserAccess = z.union([_CollaboratorAccess, _MemberAccss]);

export const _Consent = z.object({
  type: z.enum(["remove_consent", "consent"]),
  version: z.string(),
  timestamp: z.number(),
});

const _UserInterests = z
  .object({ other: z.string().nullish() })
  .catchall(z.boolean());

export type UserInterests = z.infer<typeof _UserInterests>;

export const _LoggedInUser = z.object({
  nickname: z.string(),
  picture: z.string().optional(),
  user_id: z.string(),
  email: z.string(),
  allow_news: z.boolean(),
  allow_interest_specific_news: z.boolean().optional(),
  accept_terms_of_use: z.boolean().optional(),
  hmac: z.string().optional(),
  internalRole: z.boolean(),
  consents: z.array(_Consent),
  isTemporaryToken: z.boolean(),
  logins_count: z.number(),
  role: z.string().optional(),
  interests: _UserInterests.nullish(),
});
export type LoggedInUser = z.infer<typeof _LoggedInUser>;

export const _UserMetaInfo = z.object({
  user_id: z.string(),
  picture: z.string().optional(),
  nickname: z.string().catch(""),
  consentVersion: z.string().optional(),
  allow_news: z.boolean(),
  allow_interest_specific_news: z.boolean().optional(),
  accept_terms_of_use: z.boolean().optional(),
  interests: _UserInterests.nullish(),
});

export type UserMetaInfo = z.infer<typeof _UserMetaInfo>;
export type UserMetaInfoUpdate = Partial<Omit<UserMetaInfo, "user_id">>;

const _OtherUserMetaInfo = z.object({
  picture: z.string().optional(),
  nickname: z.string().catch(""),
  user_id: z.string().optional(),
});
export type OtherUserMetaInfo = z.infer<typeof _OtherUserMetaInfo>;

export const _UserNodesAccess = z.object({
  organisation_access: z.record(z.string(), _OrganisationAccessRole),
  node_access: z.record(z.string(), _UserAccessRole),
});
export type UserNodesAccess = z.infer<typeof _UserNodesAccess>;

export type ProjectType = z.infer<typeof _ProjectType>;
