import { Dashboard } from "./types";
import { dndEntry } from "./utils";
import { isDefined } from "../../utils/predicates";
import { fetchListDashboards } from "./service";
import { atom } from "jotai";
import { aset, atomFamily, atomFromFn } from "utils/jotai";
import { DesignToolMode } from "types/map";

export const VIND_DASHBOARDS = [
  {
    id: "vind-preset-dashboard-overview",
    name: "Overview",
    preset: true,
    applicableMode: [DesignToolMode.Offshore, DesignToolMode.Onshore],
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Park"), dndEntry("Map")].filter(isDefined),
      },
      {
        id: "row-2",
        height: 200,
        elements: [dndEntry("Energy"), dndEntry("Wind rose")].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-yield",
    name: "Yield",
    applicableMode: [DesignToolMode.Offshore, DesignToolMode.Onshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Turbines"), dndEntry("Wind rose")].filter(
          isDefined,
        ),
      },
      {
        id: "row-2",
        height: 200,
        elements: [
          dndEntry("Energy"),
          dndEntry("Losses"),
          dndEntry("Input"),
          dndEntry("Wind details"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 200,
        elements: [
          dndEntry("Wind speed distribution"),
          dndEntry("Cumulative graph"),
        ].filter(isDefined),
      },
      {
        id: "row-4",
        height: 200,
        elements: [
          dndEntry("Monthly graph"),
          dndEntry("Annual variability"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-electrical",
    name: "Electrical",
    applicableMode: [DesignToolMode.Offshore, DesignToolMode.Onshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 200,
        elements: [dndEntry("Cabling"), dndEntry("Cable matrix map")].filter(
          isDefined,
        ),
      },
      {
        id: "row-2",
        height: 345,
        elements: [
          dndEntry("Cable matrix"),
          dndEntry("Inter array loss graph"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 345,
        elements: [
          dndEntry("Export cables"),
          dndEntry("Electrical losses"),
        ].filter(isDefined),
      },
      {
        id: "row-4",
        height: 401,
        elements: [
          dndEntry("Export cable loading"),
          dndEntry("Export system voltage"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-cost",
    name: "Cost",
    applicableMode: [DesignToolMode.Offshore, DesignToolMode.Onshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 401,
        elements: [
          dndEntry("Lcoe table"),
          dndEntry("Capex table"),
          dndEntry("Park life cash flow"),
        ].filter(isDefined),
      },
      {
        id: "row-2",
        height: 345,
        elements: [
          dndEntry("IRR table"),
          dndEntry("Capex pie chart"),
          dndEntry("Map"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 345,
        elements: [
          dndEntry("Cost input"),
          dndEntry("Capex input"),
          dndEntry("Other expenditures input"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-mooring",
    name: "Mooring",
    applicableMode: [DesignToolMode.Offshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 50,
        elements: [dndEntry("Mooring lines")].filter(isDefined),
      },
      {
        id: "row-2",
        height: 200,
        elements: [
          dndEntry("Mooring line attachments"),
          dndEntry("Mooring line anchors"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 200,
        elements: [dndEntry("Mooring lines list")].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-foundation",
    name: "Foundation",
    applicableMode: [DesignToolMode.Offshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 50,
        elements: [
          dndEntry("Foundation totals"),
          dndEntry("3D-foundation"),
        ].filter(isDefined),
      },
      {
        id: "row-2",
        height: 100,
        elements: [dndEntry("Foundation key statistics")].filter(isDefined),
      },
      {
        id: "row-3",
        height: 100,
        elements: [dndEntry("Foundations")].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-metocean",
    name: "Metocean",
    applicableMode: [DesignToolMode.Offshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 50,
        elements: [dndEntry("Metocean map")].filter(isDefined),
      },
      {
        id: "row-2",
        height: 50,
        elements: [
          dndEntry("Wind rose"),
          dndEntry("Wind details"),
          dndEntry("Wind speed distribution"),
        ].filter(isDefined),
      },
      {
        id: "row-3",
        height: 50,
        elements: [
          dndEntry("Wave rose"),
          dndEntry("Wave details"),
          dndEntry("Wave height distribution"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
  {
    id: "vind-preset-dashboard-installation",
    name: "Installation",
    applicableMode: [DesignToolMode.Offshore],
    preset: true,
    rows: [
      {
        id: "row-1",
        height: 400,
        elements: [
          dndEntry("Installation time breakdown chart"),
          dndEntry("Installation time variability"),
        ].filter(isDefined),
      },
      {
        id: "row-2",
        height: 400,
        elements: [
          dndEntry("Installation S curve"),
          dndEntry("Installation table"),
        ].filter(isDefined),
      },
    ],
    widgetState: {},
  },
];

export const dashboardsAtom = atomFamily(({ nodeId }: { nodeId: string }) =>
  atomFromFn<Promise<Dashboard[]>>(() => fetchListDashboards(nodeId)),
);

export const dashboardAtom = atomFamily(
  ({ nodeId, dashboardId }: { nodeId: string; dashboardId: string }) =>
    atom(
      async (get) => {
        const all = await get(dashboardsAtom({ nodeId }));
        return all.find((d) => d.id === dashboardId);
      },
      (get, set, update: Dashboard | undefined) => {
        if (!update) {
          aset(get, set, dashboardsAtom({ nodeId }), (c) =>
            c.filter((d) => d.id !== dashboardId),
          );
        } else {
          aset(get, set, dashboardsAtom({ nodeId }), (c) =>
            c.filter((d) => d.id !== update.id).concat([update]),
          );
        }
      },
    ),
);

export const isPrintingAtom = atom(false);
