import { useSetAtom } from "jotai";
import mapboxgl from "mapbox-gl";
import { useEffect, useMemo } from "react";
import {
  MeanSpeedGrid,
  meanSpeedGridLimitsAtom,
} from "../state/windStatistics";
import { COLORS } from "./windSpeed";

const layerId = "speedgrid";

const SpeedGrid = ({
  meanSpeedGrid,
  map,
}: {
  meanSpeedGrid: MeanSpeedGrid;
  map: mapboxgl.Map | undefined;
}) => {
  const setMeanSpeedLimits = useSetAtom(meanSpeedGridLimitsAtom);

  const [minSpeed, maxSpeed, imageUrl, bbox] = useMemo(() => {
    const speeds = meanSpeedGrid.grid.flat();
    const min =
      speeds.reduce((a, v) => (v > 0 ? Math.min(a, v) : a), 15) - 0.01;
    const max = speeds.reduce((a, v) => Math.max(a, v), 0) + 0.01;

    const canvas = document.createElement("canvas");
    canvas.width = meanSpeedGrid.ncols;
    canvas.height = meanSpeedGrid.nrows;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      const imageData = ctx.createImageData(
        meanSpeedGrid.ncols,
        meanSpeedGrid.nrows,
      );

      for (let row = 0; row < meanSpeedGrid.nrows; row++) {
        for (let col = 0; col < meanSpeedGrid.ncols; col++) {
          const i = row * meanSpeedGrid.ncols + col;
          const speed = speeds[i];
          const normalizedSpeed = (speed - min) / (max - min);

          const colorIndex = Math.max(
            Math.min(
              Math.round(normalizedSpeed * (COLORS.default.length - 1)),
              COLORS.default.length - 1,
            ),
            0,
          );
          const color = COLORS.default[colorIndex];

          // Flip the image vertically by reversing the row index
          const flippedRow = meanSpeedGrid.nrows - 1 - row;
          const pixelIndex = (flippedRow * meanSpeedGrid.ncols + col) * 4;

          imageData.data[pixelIndex] = color[0];
          imageData.data[pixelIndex + 1] = color[1];
          imageData.data[pixelIndex + 2] = color[2];
          imageData.data[pixelIndex + 3] = speed > 0 ? 255 : 0;
        }
      }

      ctx.putImageData(imageData, 0, 0);
    }

    const imageUrl = canvas.toDataURL("image/png");

    const x0 = meanSpeedGrid.xllcorner - meanSpeedGrid.dx * 0.5;
    const x1 =
      x0 + meanSpeedGrid.dx * meanSpeedGrid.ncols + meanSpeedGrid.dx * 0.5;
    const y0 = meanSpeedGrid.yllcorner - meanSpeedGrid.dy * 0.5;
    const y1 =
      y0 + meanSpeedGrid.dy * meanSpeedGrid.nrows + meanSpeedGrid.dy * 0.5;
    const bbox = [
      [x0, y1],
      [x1, y1],
      [x1, y0],
      [x0, y0],
    ] as [
      [number, number],
      [number, number],
      [number, number],
      [number, number],
    ];

    return [min, max, imageUrl, bbox];
  }, [meanSpeedGrid]);

  useEffect(() => {
    setMeanSpeedLimits([minSpeed, maxSpeed]);
    return () => setMeanSpeedLimits(undefined);
  }, [minSpeed, maxSpeed, setMeanSpeedLimits]);

  useEffect(() => {
    if (!map) return;

    map.addSource(layerId, {
      type: "image",
      url: imageUrl,
      coordinates: bbox,
    });

    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: layerId,
        paint: {
          "raster-opacity": 1,
          "raster-resampling": "nearest",
        },
      },
      "bridge-rail",
    );

    return () => {
      if (!map) return;
      if (map.getLayer(layerId)) map.removeLayer(layerId);
      if (map.getSource(layerId)) map.removeSource(layerId);
    };
  }, [map, imageUrl, bbox]);

  return null;
};

export default SpeedGrid;
