import { InboundMessage } from "ably";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { useMemo, useCallback } from "react";
import {
  _MoveNode,
  _InvalidateNode,
  _RemoveNode,
  _Node,
} from "services/customerAPI";
import { ABLY_UPDATE_PROJECT_NODE } from "state/ably";
import { useAblyGeneric } from "./useAblyGeneric";

export function useAblyProjectNode(projectId: string) {
  const { updateLocal } = useOrganisationNodeCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceivedUpdate = useCallback(
    (message: InboundMessage) => {
      const node = _Node.parse(message.data.meta);
      updateLocal(node);
    },
    [updateLocal],
  );

  const events = useMemo(
    () =>
      projectId
        ? [
            {
              eventName: ABLY_UPDATE_PROJECT_NODE,
              onMessageReceived: onMessageReceivedUpdate,
            },
          ]
        : [],
    [onMessageReceivedUpdate, projectId],
  );

  useAblyGeneric(channelName, events, projectId);
}
