import React from "react";
import { spaceLarge } from "../../../styles/space";
import { useDashboardContext } from "../Dashboard";
import { LoadingState, SafeCard } from "./Base";
import { FlexGrid2 } from "../../General/Form";
import { MAX_BUFFER_DISTANCE_RASTER_ANCHOR } from "services/mooringLineTypeService";
import { StatHeader } from "./Base.style";
import { useBathymetry } from "hooks/bathymetry";
import { anchorsInParkFamily } from "state/jotai/anchor";
import { useAtomValue } from "jotai";

// Lazy load to prevent plotly being in main.js
const AnchorDistribution = React.lazy(
  () => import("../../RightSide/InfoModal/AnchorDistribution"),
);

const MooringLineAnchorsDetails = () => {
  const { projectId, park, branch } = useDashboardContext();

  const anchors = useAtomValue(
    anchorsInParkFamily({
      parkId: park.id,
      branchId: branch.id,
    }),
  );

  const [loadable, raster] = useBathymetry({
    projectId,
    featureId: park.id,
    branchId: undefined,
    bufferKm: MAX_BUFFER_DISTANCE_RASTER_ANCHOR,
  });

  if (loadable.state === "loading") {
    return <LoadingState />;
  }

  if (!raster) {
    throw new Error("Bathymetry could not be loaded");
  }

  return (
    <>
      <FlexGrid2 style={{ padding: spaceLarge }}>
        <StatHeader title={"Number of anchors"} value={anchors.length} />
      </FlexGrid2>
      <React.Suspense fallback={null}>
        <AnchorDistribution anchors={anchors} depthRaster={raster} />
      </React.Suspense>
    </>
  );
};

export const MooringLineAnchorsWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Mooring line anchors"
      id="Mooring line anchors"
      resetKeys={errorBoundaryResetKeys}
    >
      <MooringLineAnchorsDetails />
    </SafeCard>
  );
};
