import UserAllProvider from "./UserAll/UserAll";
import LibraryProviders from "./Library/LibraryProviders";
import OrganisationAllProvider from "./OrganisationAll/OrganisationAll";

export default function OrganisationChannelProviders({
  organisationId,
}: {
  organisationId: string | undefined;
}) {
  if (!organisationId) {
    return <></>;
  }

  return (
    <>
      <OrganisationAllProvider
        organisationId={organisationId}
        ablyId={organisationId}
      />
      <UserAllProvider
        ablyId={organisationId}
        organisationId={organisationId}
      />
      <LibraryProviders
        organisationId={organisationId}
        ablyId={organisationId}
      />
    </>
  );
}
