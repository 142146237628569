import { getTurbineCapacity } from "analysis/inputs";
import { getAEP } from "analysis/output";
import { atom } from "jotai";
import {
  CashFlowType,
  CostCategory,
  ParkFinance,
  OpexCost,
  ComputationResult,
  CashFlowAdjustment,
} from "utils/finances";
import { atomFamily } from "utils/jotai";
import { getAmounts, getConfiguration } from "./inputs";
import { CashFlow, FinanceId } from "./types";
import { MILLION, THOUSAND } from "@constants/financialAnalysis";
import { Cost, CostUnit, GeneralCost, unitToScalingMap } from "types/financial";
import { currentYear, presentValue } from "utils/inflation";
import { CostType } from "services/costService";

import { turbineCapexEntriesSelectorFamily } from "./costs/turbineCosts";
import { cableCapexEntriesSelectorFamily } from "./costs/cableCosts";
import { foundationCostsFamily } from "./costs/foundationCosts";
import { exportCableCostsSelectorFamily } from "./costs/exportCableCosts";
import { substationCostsFamily } from "./costs/substationCosts";
import { otherCostsFamily } from "./costs/otherCosts";
import { mooringCostsAtomFamily } from "./costs/mooringCosts";
import { sendWarning } from "utils/sentry";
import { isDefined } from "utils/predicates";
import { roundToDecimal, sum } from "utils/utils";

export const getProjectStart = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.lifeCycle.projectStart;
  }),
);

export const getOperationStart = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.lifeCycle.operationStart;
  }),
);

export const getInflationRate = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.inflation.inflationRate;
  }),
);

export const getCapexReferenceYear = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.inflation.referenceYearCapex;
  }),
);

export const getDiscountRate = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.lcoe.discountRate;
  }),
);

export const getCapexContingency = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.contingency.capex.value;
  }),
);

export const getOpexContingency = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return configuration.contingency.opex.value;
  }),
);

export const getParkLifeTime = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const configuration = await get(getConfiguration(id));
    return (
      configuration.lifeCycle.decomissioning -
      configuration.lifeCycle.operationStart
    );
  }),
);

const getParkFinance = atomFamily((id: FinanceId) =>
  atom<Promise<ParkFinance>>(async (get) => {
    const config = get(getConfiguration(id));
    const capacityMW = get(getTurbineCapacity(id));
    const aepGWh = get(getAEP(id));

    const devexPV = get(DEVEX.getRealValueTotal(id));
    const capexPV = get(CAPEX.getRealValueTotal(id));
    const decomPV = get(DECOM.getRealValueTotal(id));

    const opexPV = get(OPEX.getRealValueCosts(id));

    const guaranteedPricePV = get(
      IRR.getPresentValueGuaranteedPriceRevenue(id),
    );
    const marketPricePV = get(IRR.getPresentValueMarketPriceRevenue(id));

    const parkFinance = new ParkFinance({
      aepMWh: (await aepGWh) * THOUSAND,
      capacityMW: await capacityMW,
      capexPV: await capexPV,
      devexPV: await devexPV,
      opexPV: await opexPV,
      decomPV: await decomPV,
      guaranteedPricePV: await guaranteedPricePV,
      marketPricePV: await marketPricePV,
      costConfig: await config,
    });

    return parkFinance;
  }),
);

export namespace CAPEX {
  export const getNominalValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.CAPEX).sum("inflated");
    }),
  );

  export const getNetPresentValue = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.CAPEX).npv();
    }),
  );

  export const getRealValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const turbine = get(
        getRealValueTotalOfType({ id, type: CostType.Turbine }),
      );
      const cable = get(getRealValueTotalOfType({ id, type: CostType.Cable }));
      const mooring = get(
        getRealValueTotalOfType({ id, type: CostType.Mooring }),
      );
      const substation = get(
        getRealValueTotalOfType({ id, type: CostType.Substation }),
      );
      const exportCable = get(
        getRealValueTotalOfType({ id, type: CostType.ExportCable }),
      );
      const foundation = get(
        getRealValueTotalOfType({ id, type: CostType.Foundation }),
      );
      const other = get(getRealValueTotalOfType({ id, type: CostType.Other }));

      const all = await Promise.all([
        turbine,
        cable,
        mooring,
        substation,
        exportCable,
        foundation,
        other,
      ]);

      return sum(all);
    }),
  );

  const getRealValueTotalOfType = atomFamily(
    ({ id, type }: { id: FinanceId; type: CostType }) =>
      atom<Promise<number>>(async (get) => {
        const costs = await get(getRealValueCostsOfType({ id, type }));

        return sum(costs.map(({ cost }) => cost));
      }),
  );

  export const getRealValueCosts = atomFamily((id: FinanceId) =>
    atom<Promise<GeneralCost[]>>(async (get) => {
      const turbine = get(
        getRealValueCostsOfType({ id, type: CostType.Turbine }),
      );
      const cable = get(getRealValueCostsOfType({ id, type: CostType.Cable }));
      const mooring = get(
        getRealValueCostsOfType({ id, type: CostType.Mooring }),
      );
      const substation = get(
        getRealValueCostsOfType({ id, type: CostType.Substation }),
      );
      const exportCable = get(
        getRealValueCostsOfType({ id, type: CostType.ExportCable }),
      );
      const foundation = get(
        getRealValueCostsOfType({ id, type: CostType.Foundation }),
      );
      const other = get(getRealValueCostsOfType({ id, type: CostType.Other }));

      const costs = await Promise.all([
        turbine,
        cable,
        mooring,
        substation,
        exportCable,
        foundation,
        other,
      ]);

      return costs.flat();
    }),
  );

  export const getRealValueCostsOfType = atomFamily(
    ({ id, type }: { id: FinanceId; type: CostType }) =>
      atom<Promise<GeneralCost[]>>(async (get) => {
        const inflationRate = await get(getInflationRate(id));
        const referenceYear = await get(getCapexReferenceYear(id));
        const presentYear = currentYear();

        const allAmounts = await get(getAmounts(id));
        const amounts = allAmounts[type];

        let entries: Cost[];
        switch (type) {
          case CostType.Turbine:
            entries = await get(turbineCapexEntriesSelectorFamily(id));
            break;
          case CostType.Cable:
            entries = await get(cableCapexEntriesSelectorFamily(id));
            break;
          case CostType.Mooring:
            entries = await get(mooringCostsAtomFamily(id));
            break;
          case CostType.Substation:
            entries = await get(substationCostsFamily(id));
            break;
          case CostType.ExportCable:
            entries = await get(exportCableCostsSelectorFamily(id));
            break;
          case CostType.Foundation:
            entries = await get(foundationCostsFamily(id));
            break;
          case CostType.Other:
            entries = await get(otherCostsFamily(id));
            break;
        }

        return entries
          .map((entry) => {
            const generalAmount = amounts.find(
              ({ id }) => entry.amountId === id,
            );

            if (generalAmount === undefined) {
              if (entry.category !== CostType.Foundation) {
                // TODO: figure out how to re-enable for foundations without too much noise
                sendWarning(`Missing financial amount: ${entry.amountId}`, {
                  amountId: entry.amountId,
                  unit: entry.unit,
                  category: entry.category,
                });
              }
              return undefined;
            }

            const costPerAmount = presentValue({
              amount: entry.cost,
              inflationRate,
              referenceYear,
              presentYear,
            });

            const cost =
              costPerAmount *
              generalAmount.amount *
              unitToScalingMap[entry.unit];

            return {
              ...entry,
              id: `${generalAmount.id}_${entry.id}`,
              name: `${entry.name}${
                generalAmount.amountName ? ` ${generalAmount.amountName}` : ""
              }`,
              amount: generalAmount.amount,
              amountUnit: generalAmount.unit,
              costPerAmount,
              cost,
              confidenceLevel: entry.confidenceLevel,
            };
          })
          .filter(isDefined);
      }),
  );

  export const getCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));

      return parkFinance.capex.cashFlow(true);
    }),
  );
}

export namespace DEVEX {
  export const getNominalValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.DEVEX).sum("inflated");
    }),
  );

  export const getNetPresentValue = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.DEVEX).npv();
    }),
  );

  export const getRealValueTotal = atomFamily((id: FinanceId) =>
    atom(async (get) => {
      const capacity = await get(getTurbineCapacity(id));
      const configuration = await get(getConfiguration(id));

      let { cost: devexCost, unit } = configuration.devex;
      if (unit === CostUnit.percent) {
        const capexPV = await get(CAPEX.getRealValueTotal(id));
        devexCost = capexPV * (devexCost / 100);
      }
      const { inflationRate, referenceYearOtherExpenditures: referenceYear } =
        configuration.inflation;

      const presentYear = new Date().getFullYear();

      const costPV = presentValue({
        amount: devexCost,
        inflationRate,
        referenceYear,
        presentYear,
      });

      if (unit === CostUnit.percent) {
        return costPV;
      } else {
        return costPV * unitToScalingMap[unit] * capacity;
      }
    }),
  );

  export const getCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.devex.cashFlow(true);
    }),
  );
}

export namespace OPEX {
  export const getNominalValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.OPEX).sum("inflated");
    }),
  );

  export const getNetPresentValue = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.OPEX).npv();
    }),
  );

  export const getCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.opex.cashFlow(true);
    }),
  );

  export const getRealValueCosts = atomFamily((id: FinanceId) =>
    atom<Promise<OpexCost[]>>(async (get) => {
      const capacity = await get(getTurbineCapacity(id));
      const aepGWh = await get(getAEP(id));
      const {
        opex: { custom },
        inflation: {
          inflationRate,
          referenceYearOtherExpenditures: referenceYear,
        },
      } = await get(getConfiguration(id));

      const amountPerUnit = {
        [CostUnit.thousandEuroPerMWPerYear]: capacity,
        [CostUnit.euroPerMWh]: aepGWh * THOUSAND,
        [CostUnit.millionEuro]: 1,
      };

      return custom.map(
        ({ id, name, cost: costPerAmount, unit, occurance, occuranceYear }) => {
          const cost =
            costPerAmount * amountPerUnit[unit] * unitToScalingMap[unit];

          const costPV = presentValue({
            amount: cost,
            inflationRate: inflationRate,
            referenceYear: referenceYear,
            presentYear: currentYear(),
          });

          return { id, name, costPV, occurance, occuranceYear };
        },
      );
    }),
  );
}

export namespace DECOM {
  export const getNominalValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.DECOM).sum("inflated");
    }),
  );

  export const getNetPresentValue = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.cost(CostCategory.DECOM).npv();
    }),
  );

  export const getRealValueTotal = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const capacity = await get(getTurbineCapacity(id));
      const {
        decom: { cost, unit },
        inflation: {
          inflationRate,
          referenceYearOtherExpenditures: referenceYear,
        },
      } = await get(getConfiguration(id));

      const presentYear = currentYear();

      if (unit === CostUnit.percent) {
        const capexTotal = await get(CAPEX.getRealValueTotal(id));

        // TODO: this is the current behaviour, but we should verify if that's what the customers expect.
        // IMO I think we should just return capexTotal * (cost / 100)
        const realValueCost = presentValue({
          amount: capexTotal * (cost / 100),
          inflationRate,
          referenceYear,
          presentYear,
        });

        return realValueCost;
      } else {
        const realValueCost = presentValue({
          amount: cost,
          inflationRate,
          referenceYear,
          presentYear,
        });

        return realValueCost * unitToScalingMap[unit] * capacity;
      }
    }),
  );

  export const getCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.decom.cashFlow(true);
    }),
  );
}

export namespace LCoE {
  export const getLCoE = atomFamily((id: FinanceId) =>
    atom<Promise<number | undefined>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.lcoe();
    }),
  );
  export const getNetPresentValueDevexMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const npv = parkFinance.devex.npv();

      return roundToDecimal(npv / MILLION, 0);
    }),
  );

  export const getNetPresentValueCapexMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const npv = parkFinance.capex.npv();

      return roundToDecimal(npv / MILLION, 0);
    }),
  );

  export const getNetPresentValueOpexMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const npv = parkFinance.opex.npv();

      return roundToDecimal(npv / MILLION, 0);
    }),
  );

  export const getNetPresentValueDecomMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const npv = parkFinance.decom.npv();

      return roundToDecimal(npv / MILLION, 0);
    }),
  );

  export const getPresentValueCashflowDEVEX = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlow>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const filterPositive = true;
      return parkFinance
        .cost(CostCategory.DEVEX)
        .cashFlow(filterPositive)
        .discounted();
    }),
  );

  export const getPresentValueCashflowCAPEX = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlow>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const filterPositive = true;
      return parkFinance
        .cost(CostCategory.CAPEX)
        .cashFlow(filterPositive)
        .discounted();
    }),
  );

  export const getPresentValueCashflowOPEX = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlow>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const filterPositive = true;
      return parkFinance
        .cost(CostCategory.OPEX)
        .cashFlow(filterPositive)
        .discounted();
    }),
  );

  export const getPresentValueCashflowDECOM = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlow>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const filterPositive = true;
      return parkFinance
        .cost(CostCategory.DECOM)
        .cashFlow(filterPositive)
        .discounted();
    }),
  );

  export const getOpexDistribution = atomFamily((id: FinanceId) =>
    atom<Promise<{ id: string; name: string; percent: number }[]>>(
      async (get) => {
        const parkFinance = await get(getParkFinance(id));
        const filterPositive = true;

        return parkFinance
          .cost(CostCategory.OPEX)
          .cashFlow(filterPositive)
          .distributions();
      },
    ),
  );
}

export namespace IRR {
  export const getIRR = atomFamily((id: FinanceId) =>
    atom<Promise<ComputationResult>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const irr = parkFinance.irr();

      return irr;
    }),
  );

  export const getGuaranteedCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.guaranteedPrice.cashFlow(true);
    }),
  );

  export const getMarketCashFlow = atomFamily((id: FinanceId) =>
    atom<Promise<CashFlowType>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      return parkFinance.marketPrice.cashFlow(true);
    }),
  );

  export const getTotalRevenueMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const revenue = parkFinance.sum("in");

      return roundToDecimal(revenue / MILLION, 0);
    }),
  );

  export const getTotalCostMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const cost = parkFinance.sum("out");

      // NOTE: we return as a positive number
      return -roundToDecimal(cost / MILLION, 0);
    }),
  );

  export const getDevexCostMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const cost = await get(DEVEX.getNominalValueTotal(id));

      return roundToDecimal(cost / MILLION, 0);
    }),
  );

  export const getCapexCostMilion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const cost = await get(CAPEX.getNominalValueTotal(id));

      return roundToDecimal(cost / MILLION, 0);
    }),
  );

  export const getOpexCostMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const cost = await get(OPEX.getNominalValueTotal(id));

      return roundToDecimal(cost / MILLION, 0);
    }),
  );

  export const getDecomCostMillion = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const cost = await get(DECOM.getNominalValueTotal(id));

      return roundToDecimal(cost / MILLION, 0);
    }),
  );

  export const getPresentValueMarketPriceRevenue = atomFamily((id: FinanceId) =>
    atom<Promise<number>>(async (get) => {
      const {
        cashFlows: { marketPrice },
        inflation: { inflationRate, referenceYearRevenue: referenceYear },
      } = await get(getConfiguration(id));
      const aepGWh = await get(getAEP(id));
      const presentYear = currentYear();

      const aepMWh = aepGWh * THOUSAND;

      const pricePV = presentValue({
        amount: marketPrice.cost * aepMWh,
        inflationRate,
        referenceYear,
        presentYear,
      });

      return pricePV;
    }),
  );

  export const getPresentValueGuaranteedPriceRevenue = atomFamily(
    (id: FinanceId) =>
      atom<Promise<number>>(async (get) => {
        const {
          cashFlows: { guaranteedPrice },
          inflation: { inflationRate, referenceYearRevenue: referenceYear },
        } = await get(getConfiguration(id));
        const aepGWh = await get(getAEP(id));
        const presentYear = currentYear();

        const aepMWh = aepGWh * THOUSAND;

        const pricePV = presentValue({
          amount: guaranteedPrice.cost * aepMWh,
          inflationRate,
          referenceYear,
          presentYear,
        });

        return pricePV;
      }),
  );
}

export namespace GuaranteedRevenues {
  export const getTotalCostMillion = atomFamily(
    ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      atom<Promise<number>>(async (get) => {
        const parkFinance = await get(getParkFinance(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.GUARANTEED_PRICE).sum(adjustment) /
            MILLION,
          0,
        );
      }),
  );

  export const getYears = atomFamily((id: FinanceId) =>
    atom<
      Promise<{ startYear: number | undefined; endYear: number | undefined }>
    >(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const years = parkFinance.guaranteedPrice
        .cashFlow(true)
        .raw()
        .map(({ year }) => year);

      return { startYear: years.at(0), endYear: years.at(-1) };
    }),
  );
}

export namespace MarketRevenues {
  export const getTotalCostMillion = atomFamily(
    ({ id, adjustment }: { id: FinanceId; adjustment: CashFlowAdjustment }) =>
      atom(async (get) => {
        const parkFinance = await get(getParkFinance(id));
        return roundToDecimal(
          parkFinance.cost(CostCategory.MARKET_PRICE).sum(adjustment) / MILLION,
          0,
        );
      }),
  );

  export const getYears = atomFamily((id: FinanceId) =>
    atom<
      Promise<{ startYear: number | undefined; endYear: number | undefined }>
    >(async (get) => {
      const parkFinance = await get(getParkFinance(id));
      const years = parkFinance.marketPrice
        .cashFlow(true)
        .raw()
        .map(({ year }) => year);

      return { startYear: years.at(0), endYear: years.at(-1) };
    }),
  );
}
