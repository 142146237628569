export const ABLY_REMOVE_NODE = "customer_remove_node";
export const ABLY_MOVE_NODE = "customer_mode_node";
export const ABLY_UPDATE_PROJECT_NODE = "update_project_node";
export const ABLY_INVALIDATE_NODE = "invalidate_node";
export const ABLY_ADD_REPLIES_FILTER = "replies_add";
export const ABLY_REMOVE_REPLIES_FILTER = "replies_remove";
export const ABLY_THREADS_ADD_FILTER = "threads_add";
export const ABLY_THREADS_REMOVE_FILTER = "threads_remove";
export const ABLY_THREAD_REACTIONS_ADD_FILTER = "thread_reactions_add";
export const ABLY_THREAD_REACTIONS_REMOVE_FILTER = "thread_reactions_remove";
export const ABLY_REPLY_REACTIONS_ADD_FILTER = "reply_reactions_add";
export const ABLY_REPLY_REACTIONS_REMOVE_FILTER = "reply_reactions_remove";
export const ABLY_RESOLVED_TOGGLE_FILTER = "thread_resolved_toggle";
export const ABLY_PROJECT_ELEMENTS = "project_elements_updates";
export const ABLY_PROJECT_ELEMENT_CREATE_FOLDER =
  "project_elements_create_folder";
export const ABLY_PROJECT_ELEMENT_DELETE_FOLDER =
  "project_elements_delete_folder";
export const ABLY_PROJECT_ELEMENT_UPDATE_FOLDER =
  "project_elements_update_folder";
export const ABLY_PROJECT_ELEMENT_SORT_ORDER = "project_elements_sort_order";
export const ABLY_CURSOR_POSITION = "cursor_position";
export const ABLY_FEATURE_SELECTION = "feature_selection";
export const ABLY_BRANCH_UPDATE = "branch_update";
export const ABLY_BRANCH_DUPLICATED = "branch_duplicated";
export const ABLY_BRANCH_DELETE = "branch_delete";
export const ABLY_BRANCH_SORT = "branch_sort";
export const ABLY_COLLECTION_UPDATE = "collection_update";
export const ABLY_COLLECTION_DELETE = "collection_delete";
export const ABLY_LAYER_UPDATE = "layer_update";
export const ABLY_LAYER_DELETE = "layer_delete";
export const ABLY_BATHYMETRY_INCLUDING_CUSTOM =
  "bathymetry_including_custom_service";
export const ABLY_SLOPE_INCLUDING_CUSTOM = "slope_including_custom_service";
export const ABLY_WAKE_ANALYSIS_RESULT = "wake_analysis_result";
export const ABLY_NOISE_ANALYSIS_RESULT = "noise_analysis_result";
export const ABLY_COST_CONFIG_UPDATE = "cost_config_update";
export const ABLY_COST_CONFIG_DELETE = "cost_config_delete";
export const ABLY_AUTOSAVE_FEATURE_UPDATE = "new_autosave";
export const ABLY_SAVE_FEATURE_UPDATE = "new_save";
export const ABLY_DELETE_SAVE_FEATURE_UPDATE = "delete_save";
export const ABLY_UPDATE_SAVE_FEATURE_UPDATE = "update_save";
export const ABLY_EDIT_SAVE_FEATURE_UPDATE = "edit_save";
export const ABLY_PORTFOLIO_PARK_ADDED = "portfolio-park-added";
export const ABLY_PORTFOLIO_PARK_REMOVED = "portfolio-park-removed";
export const ABLY_CHANGELOG_UPDATE = "new_changelog_event";
export const ABLY_ORGANISATION_DATA_PACKAGE_CREATE =
  "organisation_data_package_create";
export const ABLY_ORGANISATION_DATA_PACKAGE_UPDATE =
  "organisation_data_package_update";
export const ABLY_ORGANISATION_DATA_PACKAGE_DELETE =
  "organisation_data_package_delete";
export const ABLY_UPLOAD_CUSTOM_ORGANISATION_LAYER_CHANNEL_NAME =
  "upload_organisation_custom_layer_service";

export const ABLY_CREATE_ARCHIVED_VERSION = "create_archived_version";
export const ABLY_DELETE_ARCHIVED_VERSION = "delete_archived_version";
export const ABLY_FOLDER_STRUCTURE_UPDATED = "ABLY_FOLDER_STRUCTURE_UPDATED";

export const ABLY_TURBINE_TYPE_UPDATE = "turbine_type_update";
export const ABLY_TURBINE_TYPE_DELETE = "turbine_type_delete";
export const ABLY_FOUNDATION_TYPE_UPDATE = "foundation_type_update";
export const ABLY_FOUNDATION_TYPE_DELETE = "foundation_type_delete";
export const ABLY_CABLE_TYPE_UPDATE = "cable_type_update";
export const ABLY_CABLE_TYPE_DELETE = "cable_type_delete";
export const ABLY_EXPORT_CABLE_TYPE_UPDATE = "export_cable_type_update";
export const ABLY_EXPORT_CABLE_TYPE_DELETE = "export_cable_type_delete";
export const ABLY_ANALYSIS_UPDATE = "analysis_update";
export const ABLY_ANALYSIS_DELETE = "analysis_delete";
export const ABLY_ORG_TURBINE_LIBRARY_TYPE_UPDATE = "org_turbine_type_update";
export const ABLY_ORG_FOUNDATION_LIBRARY_TYPE_UPDATE =
  "org_foundation_type_update";
export const ABLY_ORG_CABLE_TYPE_UPDATE = "org_cable_type_update";
export const ABLY_ORG_ANALYSIS_CONFIGURATION_LIBRARY_TYPE_UPDATE =
  "org_analysis_configuration_type_update";
export const ABLY_ORG_COST_CONFIGURATION_LIBRARY_TYPE_UPDATE =
  "org_cost_configuration_type_update";
export const ABLY_PROCUREMENT_COST_UPDATE = "procurement_cost_update";
export const ABLY_PROCUREMENT_COST_DELETE = "procurement_cost_delete";

export const ABLY_SHADOW = "shadow";

export const ABLY_SUBSTATION_TYPE_UPDATE = "substation_type_update";
export const ABLY_SUBSTATION_TYPE_DELETE = "substation_type_delete";
export const ABLY_SUBSTATION_LIBRARY_TYPE_UPDATE = "org_substation_type_update";
export const ABLY_MOORING_TYPE_UPDATE = "mooring_type_update";
export const ABLY_MOORING_TYPE_DELETE = "mooring_type_delete";
export const ABLY_VESSEL_TYPE_UPDATE = "vessel_type_update";
export const ABLY_VESSEL_TYPE_DELETE = "vessel_type_delete";

export const ABLY_NODE_ACCESS_USER_UPDATE = "node_access_user_update";

export const ABLY_UPDATE_GROUP_ACCESS = "update_group_access";
export const ABLY_USER_LIBRARY_ACCESS = "user_library_access";
