import { turbineSourceId } from "components/Mapbox/constants";
import { atom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { parkFamily } from "state/jotai/park";
import { substationsInParkFamily } from "state/jotai/substation";
import { ValidationWarning } from "state/validationWarnings";
import { atomFamily } from "utils/jotai";
import { overlappingPointFeatures } from "utils/overlappingPointFeatures";
import { ValidationWarningTypes } from "./utils";

export const substationOverlapWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        ValidationWarning<ValidationWarningTypes.SubstationOverlap> | undefined
      >
    >(async (get) => {
      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;

      const overlaps = overlappingPointFeatures(substations, 50);
      if (overlaps.length === 0) return;
      return {
        type: ValidationWarningTypes.SubstationOverlap,
        featureIds: overlaps.map((s) => s.id),
        parkId: parkId,
      };
    }),
);

export const SubstationOverlapJotai = ({
  parkId,
  map,
  source = turbineSourceId,
}: {
  parkId: string;
  map: mapboxgl.Map;
  source?: string;
}) => {
  const overlappingSubstationsWarning = useAtomValue(
    substationOverlapWarningFamily({
      parkId: parkId,
      branchId: undefined,
    }),
  );

  useEffect(() => {
    if (!overlappingSubstationsWarning) return;
    if (map.getSource(source))
      for (const id of overlappingSubstationsWarning.featureIds)
        map.setFeatureState({ source, id: id }, { overlap: true });

    return () => {
      if (map.getSource(source))
        for (const id of overlappingSubstationsWarning.featureIds)
          map.removeFeatureState({ source, id }, "overlap");
    };
  }, [map, overlappingSubstationsWarning, source]);

  return null;
};
