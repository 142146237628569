import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import WindowExpandIcon from "@icons/24/WindowExpand.svg?react";
import WindowMinimizeIcon from "@icons/24/WindowMinimize.svg?react";
import AddIcon from "@icons/24/Add.svg";
import Tooltip from "components/General/Tooltip";
import {
  ARTICLE_PORTFOLIO,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { useToast } from "hooks/useToast";
import { useAtom } from "jotai";
import { Mixpanel, TrackingFeature, TrackingCapability } from "mixpanel";
import React, { useState } from "react";
import { portfolioFullScreenAtom } from "state/portfolio";
import { colors } from "styles/colors";
import { spaceDecent, spaceHuge, spaceLarge, spaceMedium } from "styles/space";
import { typography } from "styles/typography";
import SelectParksFilterButton from "./SelectParksFilterButton";
import TotalPortfolioCapacity from "./TotalPortfolioCapacity";
import { IconBtn } from "components/General/Icons";
import Button from "components/General/Button";
import { AdminParkPickerModalWrapper } from "components/Organisation/OrganisationRightSide/content/shared/OrganisationParkPickerModal";
import usePortfolioService from "hooks/usePortfolioService";
import { useClickOutside } from "hooks/useClickOutside";

const PortfolioTopRow = () => {
  const [isFullScreen, setFullScreen] = useAtom(portfolioFullScreenAtom);
  const { success: showSuccess } = useToast();
  const [showAddPark, setShowAddPark] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);
  const { create: addToPortfolio } = usePortfolioService();
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setShowAddPark(false));

  return (
    <div
      style={{
        display: "flex",
        marginBottom: spaceLarge,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: spaceHuge,
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <React.Suspense fallback={null}>
          <SelectParksFilterButton />
        </React.Suspense>
        <div
          style={{ display: "flex", gap: spaceDecent, alignItems: "center" }}
        >
          <p
            style={{
              ...typography.graphics,
              color: colors.textSecondary,
              margin: 0,
            }}
          >
            Total capacity:
          </p>
          <React.Suspense fallback={null}>
            <p
              style={{
                ...typography.caption,
                color: colors.textBrand,
                margin: 0,
                backgroundColor: colors.surfaceInfo,
                padding: "0.4rem 0.8rem",
                borderRadius: "0.4rem",
              }}
            >
              <TotalPortfolioCapacity />
            </p>
          </React.Suspense>
        </div>
      </div>
      <div style={{ display: "flex", gap: spaceMedium }}>
        <div
          style={{
            marginLeft: "auto",
            position: "relative",
          }}
        >
          <Button
            text="Add parks"
            buttonType="secondary"
            icon={<AddIcon />}
            onClick={() => setShowAddPark((cur) => !cur)}
            disabled={saveInProgress > 0}
          />
          {showAddPark && (
            <div
              ref={ref}
              style={{
                position: "absolute",
                right: "0.8rem",
                zIndex: "1",
              }}
            >
              <AdminParkPickerModalWrapper
                onClose={() => setShowAddPark(false)}
                onSave={async (parks) => {
                  Mixpanel.track(
                    TrackingCapability.Portfolio,
                    TrackingFeature.Portfolio,
                    "add park from portfolio page",
                    {
                      numberOfParks: parks.length,
                    },
                  );
                  setSaveInProgress(parks.length);
                  await addToPortfolio(parks);
                  setSaveInProgress(0);
                  setShowAddPark(false);
                }}
              />
            </div>
          )}
        </div>
        <HelpLink article={ARTICLE_PORTFOLIO} />
        <Tooltip text="Toggle full screen">
          <IconBtn
            size="1.4rem"
            buttonType="secondary"
            onClick={() => {
              Mixpanel.track(
                TrackingCapability.Portfolio,
                TrackingFeature.Portfolio,
                "toggle portfolio full screen",
                {
                  isFullScreen: !isFullScreen,
                },
              );
              setFullScreen((curr) => !curr);
            }}
          >
            {isFullScreen ? <WindowMinimizeIcon /> : <WindowExpandIcon />}
          </IconBtn>
        </Tooltip>
        <Tooltip text="Copy link to portfolio">
          <IconBtn
            size="1.4rem"
            buttonType="secondary"
            onClick={async () => {
              Mixpanel.track(
                TrackingCapability.Portfolio,
                TrackingFeature.Portfolio,
                "copy portfolio link",
                {},
              );
              await navigator.clipboard.writeText(document.location.toString());
              showSuccess("Link to portfolio copied to clipboard", {
                timeout: 2000,
              });
            }}
          >
            <HyperlinkIcon />
          </IconBtn>
        </Tooltip>
      </div>
    </div>
  );
};

export default PortfolioTopRow;
