import { useSearch } from "./useSearch";
import { organisationExportCableResourceState } from "components/Organisation/Library/state";
import { orgExportcableManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CableType } from "services/cableTypeService";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";

const keys = ["name", "description"];

export function useSearchLibraryExportCables(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgExportcableManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults, rawData } = useSearch<CableType>(
    enabled,
    searchTerm,
    keys,
    organisationExportCableResourceState({
      organisationId,
    }),
  );

  const filteredSearchItems: SearchResultItem<CableType>[] = useMemo(() => {
    return filteredResults.map((result) => ({
      id: result.item.id,
      name: result.item.name,
      score: result.score,
      item: result.item,
      icon: getIcon(LibraryManageRole.EXPORT_CABLE),
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/exportcable/${result.item.id}`,
        );
      },
    }));
  }, [filteredResults, navigate, organisationId]);

  const searchableItems: SearchItem<CableType>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/exportcable/${item.id}`,
        );
      },
      icon: getIcon(LibraryManageRole.EXPORT_CABLE),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredExportCables: filteredSearchItems,
    searchableItems,
  };
}
