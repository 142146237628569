import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { colors } from "styles/colors";
import { TextInput } from "components/General/Input";
import Button from "components/General/Button";
import useTextInput from "hooks/useTextInput";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { spacing8 } from "styles/space";
import Spinner from "@icons/spinner/Spinner";
import { typography } from "styles/typography";
import { DesignToolMode } from "types/map";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const Subtext = styled.p`
  ${typography.caption};
  color: ${colors.textSecondary};
  margin-bottom: 1.2rem;
`;

const NewItemModal = ({
  title,
  placeholder,
  defaultValue,
  submitButtonText = "Create",
  onSubmit,
  onClose,
  subtitle,
  description,
}: {
  title: string;
  placeholder: string;
  defaultValue: string;
  submitButtonText?: string;
  onSubmit: (
    newValue: string,
    projectType?: DesignToolMode,
  ) => Promise<void> | void;
  onClose(): void;
  subtitle?: string;
  description?: string;
}) => {
  const [modalElem, setModalElem] = useState<HTMLDivElement | null>(null);
  const inputElem = useRef<HTMLInputElement>(null);
  const [value, onValueChange] = useTextInput(defaultValue);
  const formRef = useRef<HTMLFormElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setModalElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  useEffect(() => {
    // Hack needed to wait for next frame to select the input
    setTimeout(() => {
      inputElem.current?.select();
    }, 0);
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        onClose();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClose]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.currentTarget.reportValidity()) {
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit(value);
    } finally {
      setIsLoading(false);
    }
    onClose();
  };

  if (!modalElem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal
      placeOnTopOfOtherModals={true}
      onEscape={onClose}
      closeOnWrapperClick={true}
      onClick={onClose}
    >
      <ModalFrame
        title={title}
        style={{
          width: "40rem",
        }}
      >
        {subtitle && <Subtext>{subtitle}</Subtext>}
        <form ref={formRef} onSubmit={handleSubmit}>
          <Column
            style={{
              gap: spacing8,
            }}
          >
            <TextInput
              ref={inputElem}
              autoFocus
              required
              placeholder={placeholder}
              minLength={1}
              value={value}
              onChange={onValueChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  submitButtonRef.current?.click();
                }
              }}
              style={{
                width: "100%",
              }}
              disabled={isLoading}
            />
            {description && <SimpleAlert type="info" text={description} />}
            <Row
              style={{
                justifyContent: "flex-end",
                gap: "1.2rem",
              }}
            >
              <Button text="Cancel" buttonType="secondary" onClick={onClose} />
              <Button
                ref={submitButtonRef}
                text={submitButtonText}
                type="submit"
                disabled={isLoading}
                icon={isLoading ? <Spinner size="1rem" /> : undefined}
              />
            </Row>
          </Column>
        </form>
      </ModalFrame>
    </FullScreenModal>,
    modalElem,
  );
};

export default NewItemModal;
