import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import { layerSettingSelectorFamily } from "../../components/LayerList/LayerSettings/state";
import { externalXYZAnchorId } from "../../components/Mapbox/utils";
import {
  ExternalDataSourceLinkLayerWithSourceXYZ,
  Layer,
  SourceTypesLayer,
} from "../../types/layers";
import mapboxgl from "mapbox-gl";
import { getVisibleLayers } from "../../components/LayerList/LayerSettings/utils";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../components/ErrorBoundaries/ErrorBoundaryLocal";
import { getZoomLevels, layerToSourceId } from "./utils";

const isXTZLayer = (
  layer: Layer,
): layer is ExternalDataSourceLinkLayerWithSourceXYZ => {
  return layer.sourceType === SourceTypesLayer.xyz;
};

const DynamicXYZLayers = ErrorBoundaryWrapper(
  () => {
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const visibleLayers = useAtomValue(
      getVisibleLayers({
        projectId,
      }),
    );

    const selectedDynamicXYZLayers = visibleLayers.filter(isXTZLayer);

    return (
      <>
        {selectedDynamicXYZLayers.map((layer) => {
          return <DynamicXYZLayer key={layer.id} layer={layer} />;
        })}
      </>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

const DynamicXYZLayer = ErrorBoundaryWrapper(
  ({ layer }: { layer: ExternalDataSourceLinkLayerWithSourceXYZ }) => {
    const map = useAtomValue(mapAtom);
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const settings = useAtomValue(
      layerSettingSelectorFamily({
        projectId,
        layerId: layer.id,
      }),
    );

    const zoomLevels = settings.layerStyle?.zoomLevels;

    const dynamicXYZLayersId = useMemo(
      () => `dynamic-xyz-layer-${layer.source.name}-${layer.name}`,
      [layer],
    );
    const dynamicXYZLayersSource = useMemo(
      () => layerToSourceId(layer, "-xyz"),
      [layer],
    );

    const dynamicXYZLayer: mapboxgl.RasterLayer = useMemo(
      () => ({
        id: dynamicXYZLayersId,
        type: "raster",
        source: dynamicXYZLayersSource,
        paint: {},
        ...getZoomLevels(zoomLevels),
      }),
      [dynamicXYZLayersId, dynamicXYZLayersSource, zoomLevels],
    );

    useEffect(() => {
      if (!map) return;
      map.addSource(dynamicXYZLayersSource, {
        type: "raster",
        tiles: [`${layer.sourceLink.url}/{z}/{x}/{y}.png`],
        tileSize: 256,
      });
      map.addLayer(
        dynamicXYZLayer,
        map.getLayer(externalXYZAnchorId) ? externalXYZAnchorId : undefined,
      );
      return () => {
        map.removeLayer(dynamicXYZLayer.id);
        map.removeSource(dynamicXYZLayersSource);
      };
    }, [
      map,
      dynamicXYZLayersId,
      layer,
      dynamicXYZLayer,
      dynamicXYZLayersSource,
    ]);

    useEffect(() => {
      if (!map) return;

      if (settings.layerStyle?.opacity !== undefined) {
        map.setPaintProperty(
          dynamicXYZLayer.id,
          "raster-opacity",
          settings.layerStyle.opacity,
        );
      }
    }, [map, dynamicXYZLayersId, dynamicXYZLayer, layer, settings.layerStyle]);

    return null;
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default DynamicXYZLayers;
