import WindMeasure from "@icons/24/WindMeasure.svg?react";
import {
  ProdId,
  getAverageHubHeight,
  getParkOrSubareaCenter,
} from "analysis/inputs";
import {
  defaultTurbineStyle,
  defaultTurbineStyleWakeLoss,
  defaultTurbineStyleWindSpeed,
} from "business/style/feature/turbine";
import { enabledStyleAtomFamily } from "business/style/state";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import Button from "components/General/Button";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { InputDimensioned } from "components/General/Input";
import { Row } from "components/General/Layout";
import { Slider } from "components/General/Slider";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { nDirectionsWindRoseVisualisation } from "components/ProductionV2/constants";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtom, useAtomValue } from "jotai";
import { Suspense, useEffect } from "react";
import { mostProbableWindDirectionFamily } from "state/jotai/windStatistics";
import { projectIdAtomDef2 } from "state/pathParams";
import { showWakeAtom, wakeDirectionAtom } from "state/windStatistics";
import { useAtomUnwrap } from "utils/jotai";
import { isDefined } from "utils/predicates";
import { WindRosePositions } from "./MeanSpeedGridLabels";

const DirectionalWake = ({ id }: { id: ProdId }) => {
  const [wakeDirection, setWakeDirection] = useAtom(wakeDirectionAtom);

  const averageHubHeight = useAtomValue(getAverageHubHeight(id));

  const center = useAtomValue(getParkOrSubareaCenter(id));

  const mwd = useAtomUnwrap(
    mostProbableWindDirectionFamily({
      lon: center[0],
      lat: center[1],
      height: averageHubHeight,
      fromYear: undefined,
      toYear: undefined,
    }),
  );

  return (
    <>
      <div style={{ display: "flex", gap: "1rem" }}>
        <InputTitle>{`Wind direction`}</InputTitle>
        {isDefined(mwd) && (
          <Button
            icon={<WindMeasure />}
            onClick={() => setWakeDirection(mwd)}
            tooltip="Set to most probable direction (ERA5)"
            buttonType="text"
            text={`${mwd} deg`}
            style={{
              fontSize: "1rem",
              height: "1rem",
              textDecoration: "underline",
            }}
          />
        )}
      </div>
      <Row style={{ alignItems: "center" }}>
        <InputDimensioned
          style={{ flex: 1, minWidth: "12rem" }}
          value={wakeDirection}
          unit={"deg"}
          units={["deg"]}
          onChange={(n) => setWakeDirection(n)}
          validate={(n) => 0 <= n && n <= 360}
          compact
        />
        <Slider
          min={0}
          max={360}
          step={360 / nDirectionsWindRoseVisualisation}
          value={wakeDirection}
          onChange={(n) => setWakeDirection(n)}
        />
      </Row>
    </>
  );
};

const Visualizations_ = ({ id }: { id: ProdId }) => {
  const [showWake, setShowWake] = useAtom(showWakeAtom);
  const nodeId = useAtomValue(projectIdAtomDef2);

  const [enabledId, setEnabled] = useAtom(
    enabledStyleAtomFamily({ feature: "turbines", nodeId }),
  );

  const defaultTurbineStyleId = useAtomValue(defaultTurbineStyle).id;
  const defaultTurbineStyleWakeLossId = useAtomValue(
    defaultTurbineStyleWakeLoss,
  ).id;
  const defaultTurbineStyleWindSpeedId = useAtomValue(
    defaultTurbineStyleWindSpeed,
  ).id;

  useEffect(() => {
    return () => {
      setShowWake(false);
      setEnabled(defaultTurbineStyleId);
    };
  }, [setShowWake, setEnabled, defaultTurbineStyleId]);

  return (
    <>
      <SubtitleWithLine text={"Visualisations"} />

      <Row>
        <Toggle
          checked={defaultTurbineStyleWakeLossId === enabledId}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked && defaultTurbineStyleWakeLossId !== enabledId)
              setEnabled(defaultTurbineStyleWakeLossId);
            else if (!checked) setEnabled(defaultTurbineStyleId);
          }}
          size={ToggleSize.SMALL}
        />
        <InputTitle style={{ flex: 1 }}>Wake loss labels</InputTitle>
      </Row>

      <Row>
        <Toggle
          checked={defaultTurbineStyleWindSpeedId === enabledId}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked && defaultTurbineStyleWindSpeedId !== enabledId)
              setEnabled(defaultTurbineStyleWindSpeedId);
            else if (!checked) setEnabled(defaultTurbineStyleId);
          }}
          size={ToggleSize.SMALL}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputTitle>Mean speed</InputTitle>
        </div>
      </Row>

      <>
        <Row>
          <Toggle
            checked={showWake}
            onChange={() => setShowWake(!showWake)}
            size={ToggleSize.SMALL}
          />
          <InputTitle>Directional wake</InputTitle>
        </Row>

        {showWake && (
          <Suspense fallback={"loading"}>
            <DirectionalWake id={id} />
          </Suspense>
        )}
      </>
      {defaultTurbineStyleWindSpeedId === enabledId && (
        <WindRosePositions productionId={id} />
      )}
    </>
  );
};

export const Visualizations = ErrorBoundaryWrapper(
  Visualizations_,
  () => <SimpleAlert text="Visualizations failed" />,
  ScreamOnError,
);
