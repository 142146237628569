import { useAtomValue } from "jotai";
import { inReadOnlyModeSelector } from "state/project";
import { SearchObject } from "components/Search/state";
import SearchWrapper from "components/Search/SearchWrapper";
import SideBarIconBtn from "components/General/SideBarIconButton";
import { ReferenceType } from "@floating-ui/react";
import { RefObject } from "react";

export const MenuOptionV2 = ({
  Icon,
  chosenMenu,
  setChosenMenu,
  option,
  title,
  id = undefined,
  searchObject,
  innerRef,
  disabled,
  dark,
}: {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;

  chosenMenu: any;
  setChosenMenu: any;
  option: any;
  title?: string;
  id?: string;
  searchObject?: SearchObject;
  disabled?: boolean;
  dark?: boolean;
  innerRef?:
    | (((node: ReferenceType | null) => void) &
        ((node: ReferenceType | null) => void))
    | RefObject<HTMLDivElement>;
}) => {
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);
  const selected = chosenMenu === option;
  const children = (
    <SideBarIconBtn
      id={id}
      data-intercom-target={id}
      active={selected}
      disabled={disabled || readOnlyMode}
      onClick={() => setChosenMenu(selected ? undefined : option)}
      icon={<Icon />}
      title={title}
      innerRef={innerRef}
      dark={dark}
    />
  );

  return searchObject ? (
    <SearchWrapper {...searchObject}>{children}</SearchWrapper>
  ) : (
    children
  );
};
