import { TurbineFeature } from "types/feature";
import * as turf from "@turf/turf";
import { SimpleTurbineType } from "types/turbines";

export function getMinDistanceBetweenTurbines(
  turbines: TurbineFeature[],
  turbineTypes: Map<string, SimpleTurbineType>,
): number {
  let minDistanceBetweenTurbines = Infinity;
  for (let i = 0; i < turbines.length; i++) {
    for (let j = i + 1; j < turbines.length; j++) {
      const turbine1 = turbines[i];
      const diam1 = turbineTypes.get(
        turbine1.properties.turbineTypeId,
      )?.diameter;
      const turbine2 = turbines[j];
      const diam2 = turbineTypes.get(
        turbine2.properties.turbineTypeId,
      )?.diameter;
      if (!diam1 || !diam2) continue;

      const from = turf.point([
        turbine1.geometry.coordinates[0],
        turbine1.geometry.coordinates[1],
      ]);
      const to = turf.point([
        turbine2.geometry.coordinates[0],
        turbine2.geometry.coordinates[1],
      ]);

      const distance =
        turf.distance(from, to, { units: "meters" }) / Math.max(diam1, diam2);

      if (distance < minDistanceBetweenTurbines) {
        minDistanceBetweenTurbines = distance;
      }
    }
  }
  return Math.round(minDistanceBetweenTurbines * 100) / 100;
}
