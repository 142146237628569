import { useSetAtom } from "jotai";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import React, { useRef } from "react";
import styled from "styled-components";
import { getBranchSelectorFamily } from "../../../state/timeline";
import { MenuFrame } from "../../MenuPopup/CloseableMenuPopup";
import { spaceMedium } from "styles/space";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { DateText, EntryHeader } from "./styles";
import { timeAgoOrFormattedDate } from "utils/utils";
import Earth from "@icons/14/Earth.svg?react";
import BranchIcon from "@icons/24/Branch.svg";
import { UserInfoProject } from "components/UserInfo/UserInfo";
import {
  idToAnalysisChangelogId,
  idToCableChangelogId,
  idToCustomLayerChangelogId,
  idToDataPackageChangelogId,
  idToFinancialChangelogId,
  idToFoundationChangelogId,
  idToMooringChangelogId,
  idToSubstationChangelogId,
  idToTurbineChangelogId,
  idToWindChangelogId,
} from "components/InputChangelog/const";
import { Icon } from "components/General/Icons";
import { RightModalNames, rightModalOpenStateAtom } from "../state";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { useAtomValue } from "jotai";

const VersionHistoryContainer = styled.div`
  display: flex;
  position: fixed;
  right: ${spaceMedium};
  z-index: 6;
  top: calc(
    calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) +
      ${spaceMedium}
  );
  height: calc(
    100vh - calc(
        var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
          var(--side-bars-width)
      ) - 2.4rem
  );
  @media screen and (max-width: 1000px) {
    /* This is to not cover the mapbox logo */
    height: calc(
      100vh - calc(
          var(--top-bar-menu-height) + var(--branch-tab-bar-height) +
            var(--side-bars-width)
        ) - 6.8rem
    );
  }
`;

const ListWrapper = styled.div`
  overflow: auto;
  position: relative;
  padding: 1.6rem 2rem;
  display: flex;
  flex-direction: column;

  & > *:nth-child(odd) {
    background-color: ${colors.surfaceSecondary};
  }
`;

const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 0.8rem;
`;

const EntryRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
`;

const NoChangelogsWrapper = styled(StandardBox)`
  background-color: ${colors.surfaceSelectedLight};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
`;

const NoChangelogsTitle = styled.div`
  ${typography.sub2};
  color: ${colors.textPrimary};
`;

const NoChangelogsText = styled.div`
  ${typography.caption};
`;

const changeIdToChange = {
  [idToTurbineChangelogId("")]: "Turbine config",
  [idToFoundationChangelogId("")]: "Foundation config",
  [idToMooringChangelogId("")]: "Mooring config",
  [idToSubstationChangelogId("")]: "Substation config",
  [idToCableChangelogId("")]: "Cable config",
  [idToAnalysisChangelogId("")]: "Analysis config",
  [idToFinancialChangelogId("")]: "Financial config",
  [idToWindChangelogId("")]: "Wind config",
  [idToDataPackageChangelogId("")]: "Data package",
  [idToCustomLayerChangelogId("")]: "Custom layer",
  PRIVATE_SOURCE: "Private data source",
  PUBLIC_MODE: "Public mode",
  AUTOSAVE: "Feature change autosave",
  SAVE: "Feature save",
};

const ChangelogEntries = ({
  nodeId,
  branchId,
}: {
  nodeId: string;
  branchId: string;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom);
  const entries = useAtomValue(
    inputChangelogsAtomFamily({
      nodeId,
      changelogId: "",
      category: "project",
      organisationId,
    }),
  )
    .filter(
      (e) => !e.id.includes("BRANCH#") || e.id.includes(`BRANCH#${branchId}`),
    )
    .sort((a, b) => b.version - a.version)
    .filter((e) => Object.keys(changeIdToChange).some((k) => e.id.includes(k)));

  if (entries.length === 0) {
    return (
      <NoChangelogsWrapper>
        <NoChangelogsTitle>No changelogs</NoChangelogsTitle>
        <NoChangelogsText>
          No changelogs have yet been stored for this project or branch.
        </NoChangelogsText>
      </NoChangelogsWrapper>
    );
  }

  return (
    <ListWrapper>
      {entries.map((entry) => (
        <EntryWrapper key={`${entry.id}-${entry.version}`}>
          <EntryRow
            style={{
              gap: "0.8rem",
            }}
          >
            {!!entry.branchId ? (
              <Icon size={"1.2rem"}>
                <BranchIcon />
              </Icon>
            ) : (
              <Icon size={"1.2rem"}>
                <Earth />
              </Icon>
            )}
            <EntryHeader>
              {entry.action}{" "}
              {
                changeIdToChange[
                  Object.keys(changeIdToChange).find((k) =>
                    entry.id.includes(k),
                  ) ?? ""
                ]
              }
            </EntryHeader>
          </EntryRow>
          <EntryRow>
            <DateText
              style={{
                marginRight: "auto",
              }}
            >
              {timeAgoOrFormattedDate(entry.version / 1000)}
            </DateText>
            {projectId && (
              <UserInfoProject
                size={1.6}
                userId={entry.author}
                projectId={projectId}
                nameStyle={{
                  fontWeight: 400,
                }}
              />
            )}
          </EntryRow>
        </EntryWrapper>
      ))}
    </ListWrapper>
  );
};

const ChangelogInner = () => {
  const frameRef = useRef<HTMLDivElement>(null);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const branch = useAtomValue(
    getBranchSelectorFamily({
      projectId,
      branchId: branchId,
    }),
  );
  const branchName = branch?.title ?? "Banch";

  const setRightModalOpenState = useSetAtom(rightModalOpenStateAtom);

  return (
    <VersionHistoryContainer>
      <MenuFrame
        ref={frameRef}
        title="Changelog"
        subtitle={branchName}
        onExit={() => setRightModalOpenState(undefined)}
        style={{
          flex: 1,
          overflowY: "auto",
          width: "30rem",
        }}
      >
        <React.Suspense fallback={<SkeletonBlock />}>
          <ChangelogEntries
            nodeId={projectId}
            branchId={branchId}
          ></ChangelogEntries>
        </React.Suspense>
      </MenuFrame>
    </VersionHistoryContainer>
  );
};

export default function Changelog() {
  const rightModalOpen = useAtomValue(rightModalOpenStateAtom);
  if (rightModalOpen !== RightModalNames.Changelog) {
    return null;
  }

  return <ChangelogInner />;
}
