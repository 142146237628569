import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TopRightMenuOptions } from "../../../../constants/canvas";
import Production from "../../../../icons/24/SideBarIcons/Production.svg?react";
import NoiseIcon from "../../../../icons/24/SideBarIcons/Noise.svg?react";
import Financial from "../../../../icons/24/SideBarIcons/Financial.svg?react";
import ViewFromShore from "../../../../icons/24/SideBarIcons/ViewFromShore.svg?react";
import ParkShadows from "../../../../icons/24/SideBarIcons/ParkShadows.svg?react";
import Viewshed from "../../../../icons/24/SideBarIcons/Viewshed.svg?react";
import Electrical from "../../../../icons/24/SideBarIcons/Electrical.svg?react";
import Bathymetry from "../../../../icons/24/SideBarIcons/Bathymetry.svg?react";
import Terrain from "../../../../icons/24/SideBarIcons/Terrain.svg?react";
import ShadowFlickerIcon from "../../../../icons/24/ShadowFlicker.svg";
import Foundation from "../../../../icons/24/SideBarIcons/Foundation.svg?react";
import { viewerAccessProjectSelector } from "../../../../state/user";
import { viewFromShoreVisibleAtom } from "../../../../state/viewToPark";
import { ProjectFeature } from "../../../../types/feature";
import FilterIcon from "@icons/12/Filter.svg";
import {
  isSubArea,
  isPark,
  isPolygonFeature,
  isTurbine,
  isBathymetry,
  isGeotiff,
} from "../../../../utils/predicates";
import { Divider, IconMenu } from "../../../General/Icons";
import { FinancialAnalysisV2 } from "../../../FinancialAnalysis/FinancialAnalysis";
import { RightMenuPositionWrapper } from "../../../MenuPopup/CloseableMenuPopup";
import { ProductionV2 } from "../../../ProductionV2/Productionv2";
import { ViewToPark } from "../../../ViewAnalyses/ViewToPark/ViewToPark";
import { ElectricalStatistics } from "../ElectricalStatistics/ElectricalStatistics";
import FoundationModal from "../FoundationModal/FoundationModal";
import TurbineModal from "../TurbineModal/TurbineModal";
import BathymetryAnalysis from "./BathymetryAnalysis";
import { MenuOptionV2 } from "./InfoModalV2.style";
import MapContextProvider from "./MapContextProvider";
import ViewshedAnalysis from "./ViewshedAnalysis";
import {
  configurationMenuActiveAtom,
  TopRightModeActiveAtom,
  TopRightModeType,
} from "./state";
import { branchIdAtom, parkIdAtom } from "state/pathParams";
import { typography } from "styles/typography";
import { TourStep } from "components/OnboardingTours/TourStep";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useTrackEvent } from "components/OnboardingTours/state";
import { FloatingFocusManager, useFloating, offset } from "@floating-ui/react";
import { rightModalOpenStateAtom } from "components/Design/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { turbinesFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { currentParkAtom } from "state/jotai/park";
import { featureMapFamily } from "state/jotai/features";
import { designToolTypeAtom } from "state/map";
import { DesignToolMode } from "types/map";
import { viewParkShadowVisibleAtom } from "state/viewParkShadow";
import { ViewParkShadow } from "components/ViewAnalyses/ViewParkShadow/ViewParkShadow";
import Tooltip from "components/General/Tooltip";
import * as turf from "@turf/turf";
import TerrainAnalysis from "./TerrainAnalysis";
import { ShadowFlicker } from "components/ShadowFlicker/ShadowFlicker";
import { NoiseAnalysis } from "../../../Noise/OffshoreNoiseAnalysis";
import { OnshoreNoiseAnalysis } from "../../../Noise/Onshore/OnshoreNoiseAnalysis";
import { LoadingIndicator } from "@icons/AnimatedLoading/LoadingIndicator";
import { shadowAnyLoading } from "components/ShadowFlicker/state";
import { Anchor } from "components/General/Anchor";
import { ConfigurationMenu } from "./ConfigurationMenu";
import styled from "styled-components";

const RightMenuStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-end;
`;

const MAX_KM_2_SIZE_FOR_PARK_SHADOW = 200;

const ViewParkShadowAnalysis = ({ onClose }: { onClose(): void }) => {
  const branchId = useAtomValue(branchIdAtom);
  const park = useAtomValue(currentParkAtom);

  const setViewParkShadowVisible = useSetAtom(viewParkShadowVisibleAtom);

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbines = useAtomValue(
    turbinesFamily({
      branchId: undefined,
    }),
  );

  const turbineIdToHeight = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypes.get(f.properties.turbineTypeId)?.hubHeight ?? 0,
        ]),
      ),
    [turbines, turbineTypes],
  );
  const turbineIdToDiameter = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypes.get(f.properties.turbineTypeId)?.diameter ?? 0,
        ]),
      ),
    [turbines, turbineTypes],
  );

  useEffect(() => {
    setViewParkShadowVisible(true);
    return () => {
      setViewParkShadowVisible(false);
    };
  }, [setViewParkShadowVisible]);

  if (!park || !branchId) return null;

  return (
    <ViewParkShadow
      park={park}
      branchId={branchId}
      turbineHeights={turbineIdToHeight}
      turbineDiameters={turbineIdToDiameter}
      onClose={onClose}
    />
  );
};

const ViewFromShoreAnalysis = ({ onClose }: { onClose(): void }) => {
  const branchId = useAtomValue(branchIdAtom);
  const park = useAtomValue(currentParkAtom);

  const setViewFromShoreVisible = useSetAtom(viewFromShoreVisibleAtom);

  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbines = useAtomValue(
    turbinesFamily({
      branchId: undefined,
    }),
  );

  const turbineIdToHeight = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypes.get(f.properties.turbineTypeId)?.hubHeight ?? 0,
        ]),
      ),
    [turbines, turbineTypes],
  );
  const turbineIdToDiameter = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          turbineTypes.get(f.properties.turbineTypeId)?.diameter ?? 0,
        ]),
      ),
    [turbines, turbineTypes],
  );

  useEffect(() => {
    setViewFromShoreVisible(true);
    return () => {
      setViewFromShoreVisible(false);
    };
  }, [setViewFromShoreVisible]);

  if (!park || !branchId) return null;

  return (
    <ViewToPark
      park={park}
      branchId={branchId}
      turbineHeights={turbineIdToHeight}
      turbineDiameters={turbineIdToDiameter}
      onClose={onClose}
    />
  );
};

const ChosenContent = ({
  chosenMenu,
  canvasFeature,
  onClose,
}: {
  chosenMenu: TopRightModeType;
  canvasFeature: ProjectFeature;
  onClose(): void;
}) => {
  const parkId = useAtomValue(parkIdAtom);
  const InnerChosenContent = useMemo(() => {
    switch (chosenMenu) {
      case TopRightMenuOptions.productionStatistics:
        return <ProductionV2 onClose={onClose} />;
      case TopRightMenuOptions.cableStatistics:
        if (!parkId) return null;
        return <ElectricalStatistics onClose={onClose} parkId={parkId} />;
      case TopRightMenuOptions.turbineStatistics:
        if (!isTurbine(canvasFeature)) return null;
        return <TurbineModal canvasFeature={canvasFeature} onClose={onClose} />;
      case TopRightMenuOptions.foundationStatistics:
        return <FoundationModal onClose={onClose} />;
      case TopRightMenuOptions.noise:
        return <NoiseAnalysis onClose={onClose} />;
      case TopRightMenuOptions.onshoreNoise:
        return <OnshoreNoiseAnalysis onClose={onClose} />;
      case TopRightMenuOptions.terrainanalysis:
        if (!isPolygonFeature(canvasFeature)) return null;
        return (
          <TerrainAnalysis canvasFeature={canvasFeature} onClose={onClose} />
        );
      case TopRightMenuOptions.shadowFlicker:
        if (!isPark(canvasFeature) && !isSubArea(canvasFeature)) return null;
        return (
          <ShadowFlicker canvasFeature={canvasFeature} onClose={onClose} />
        );
      case TopRightMenuOptions.bathymetry:
        if (!isPolygonFeature(canvasFeature)) return null;
        return (
          <BathymetryAnalysis canvasFeature={canvasFeature} onClose={onClose} />
        );
      case TopRightMenuOptions.view:
        return <ViewFromShoreAnalysis onClose={onClose} />;
      case TopRightMenuOptions.parkShadow:
        return <ViewParkShadowAnalysis onClose={onClose} />;
      case TopRightMenuOptions.viewshed:
        return <ViewshedAnalysis onClose={onClose} />;
      case TopRightMenuOptions.cost:
        if (!isPark(canvasFeature) || !parkId) return null;
        return <FinancialAnalysisV2 park={canvasFeature} onClose={onClose} />;

      default:
        return null;
    }
  }, [canvasFeature, chosenMenu, onClose, parkId]);
  if (!chosenMenu) return null;
  if (!InnerChosenContent) return null;
  return InnerChosenContent;
};

const ProjectFeatureInfoModalV2Onshore = ({
  featureId,
  initialMenuOption,
}: {
  featureId: string;
  initialMenuOption?: TopRightModeType;
}) => {
  const viewerAccessProject = useAtomValue(viewerAccessProjectSelector);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );

  const [topRightModeActive, setTopRightModeActive] = useAtom(
    TopRightModeActiveAtom,
  );
  const [configurationMenuActive, setConfigurationMenuActive] = useAtom(
    configurationMenuActiveAtom,
  );
  const rightModalOpenState = useAtomValue(rightModalOpenStateAtom);
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (initialMenuOption) {
      setTopRightModeActive(initialMenuOption);
    }
  }, [initialMenuOption, setTopRightModeActive]);

  const onSelectMenu = useCallback(
    (menu: TopRightModeType) => {
      setTopRightModeActive(menu);
    },
    [setTopRightModeActive],
  );

  const onClose = useCallback(() => {
    setTopRightModeActive(undefined);
  }, [setTopRightModeActive]);

  const projectFeature = useMemo(
    () => featureMap.get(featureId),
    [featureMap, featureId],
  );

  const featureSize = useMemo(() => {
    if (!projectFeature) return 0;
    return turf.area(projectFeature) / (1000 * 1000);
  }, [projectFeature]);

  const menuList: string[] = useMemo(() => {
    if (isPark(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.onshoreNoise,
        TopRightMenuOptions.view,
        TopRightMenuOptions.viewshed,
        TopRightMenuOptions.parkShadow,
        TopRightMenuOptions.cost,
        TopRightMenuOptions.terrainanalysis,
        TopRightMenuOptions.shadowFlicker,
      ];
    }

    if (isTurbine(projectFeature)) {
      return [TopRightMenuOptions.turbineStatistics];
    }

    if (isSubArea(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.viewshed,
        TopRightMenuOptions.terrainanalysis,
        TopRightMenuOptions.shadowFlicker,
      ];
    }

    if (isBathymetry(projectFeature)) {
      return [];
    }

    if (isGeotiff(projectFeature)) {
      return [];
    }

    if (isPolygonFeature(projectFeature)) {
      return [TopRightMenuOptions.terrainanalysis];
    }

    return [];
  }, [projectFeature]);

  useEffect(() => {
    if (!topRightModeActive) return;
    if (topRightModeActive === TopRightMenuOptions.cost) return;
    if (!menuList.includes(topRightModeActive))
      setTopRightModeActive(undefined);
  }, [projectFeature, topRightModeActive, setTopRightModeActive, menuList]);

  const [isReferenceReady, setReferenceReady] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "left",
    middleware: [
      offset({
        mainAxis: 10,
        crossAxis: 20,
      }),
    ],
  });

  useLayoutEffect(() => {
    if (refs.reference.current) {
      setReferenceReady(true);
    }
  }, [refs.reference]);

  const {
    refs: refsTopography,
    floatingStyles: floatingStylesTopography,
    context: contextTopography,
  } = useFloating({
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 10,
        crossAxis: 20,
      }),
    ],
  });

  const {
    refs: refsViewParkShadow,
    floatingStyles: floatingStylesViewParkShadow,
    context: contextViewParkShadow,
  } = useFloating({
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 10,
        crossAxis: 20,
      }),
    ],
  });

  const shadowFlickerRef = useRef(null);

  if (!projectFeature || rightModalOpenState) return null;

  return (
    <>
      <RightMenuPositionWrapper>
        <RightMenuStack>
          {configurationMenuActive && <ConfigurationMenu />}
          <MapContextProvider>
            <Suspense fallback={null}>
              <ChosenContent
                onClose={onClose}
                chosenMenu={topRightModeActive}
                canvasFeature={projectFeature}
              />
            </Suspense>
          </MapContextProvider>
        </RightMenuStack>
      </RightMenuPositionWrapper>

      <IconMenu vertical sideBar iconSize="2.4rem">
        <p style={typography.sub2}>Analysis</p>
        <MenuOptionV2
          dark
          Icon={FilterIcon}
          chosenMenu={configurationMenuActive}
          setChosenMenu={() =>
            setConfigurationMenuActive(!configurationMenuActive)
          }
          option={true}
          searchObject={{
            title: "Open branch configurations",
            id: "search-branch-configurations",
            tags: ["configuration", "edit"],
            icon: <FilterIcon />,
            onSelect: () => {
              setConfigurationMenuActive(!configurationMenuActive);
            },
          }}
        />
        {menuList.length > 0 && (
          <>
            <Divider />
            {menuList.includes(TopRightMenuOptions.turbineStatistics) &&
              viewerAccessProject && (
                <MenuOptionV2
                  Icon={Production}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.turbineStatistics}
                  title={"Turbine"}
                  searchObject={{
                    title: "Open turbine analysis",
                    id: "search-turbine-stats",
                    tags: ["numbers", "stats", "turbine information"],
                    icon: <Production />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.turbineStatistics);
                    },
                  }}
                />
              )}

            {menuList.includes(TopRightMenuOptions.productionStatistics) &&
              viewerAccessProject && (
                <>
                  <HighlightStep
                    tourId="general-intro-tour"
                    stepId="seeStatistics"
                  >
                    <HighlightStep
                      tourId="onshore-intro-tour"
                      stepId="seeStatistics"
                    >
                      <MenuOptionV2
                        id={`button-${TopRightMenuOptions.productionStatistics}`}
                        innerRef={refs.setReference}
                        Icon={Production}
                        chosenMenu={topRightModeActive}
                        setChosenMenu={(m: TopRightModeType) => {
                          trackEvent("clickedProductionStatistics");
                          return onSelectMenu(m);
                        }}
                        option={TopRightMenuOptions.productionStatistics}
                        title={"Production"}
                        searchObject={{
                          title: "Open production analysis",
                          id: "search-production-stats",
                          tags: ["numbers", "stats"],
                          icon: <Production />,
                          onSelect: () => {
                            onSelectMenu(
                              TopRightMenuOptions.productionStatistics,
                            );
                          },
                        }}
                      />
                    </HighlightStep>
                  </HighlightStep>
                  {isReferenceReady && (
                    <FloatingFocusManager context={context} modal={false}>
                      <TourStep
                        tourId="general-intro-tour"
                        stepId="seeStatistics"
                        innerRef={refs.setFloating}
                        style={floatingStyles}
                      />
                    </FloatingFocusManager>
                  )}
                  {isReferenceReady && (
                    <FloatingFocusManager context={context} modal={false}>
                      <TourStep
                        tourId="onshore-intro-tour"
                        stepId="seeStatistics"
                        innerRef={refs.setFloating}
                        style={floatingStyles}
                      />
                    </FloatingFocusManager>
                  )}
                  <FloatingFocusManager
                    context={contextTopography}
                    modal={false}
                  >
                    <TourStep
                      tourId="onshore-intro-tour"
                      stepId="selectTopography"
                      innerRef={refsTopography.setFloating}
                      style={floatingStylesTopography}
                      onEnterStepAction={() => {
                        if (
                          topRightModeActive !==
                          TopRightMenuOptions.terrainanalysis
                        ) {
                          setTopRightModeActive(undefined);
                        }
                      }}
                    />
                  </FloatingFocusManager>
                  <FloatingFocusManager
                    context={contextViewParkShadow}
                    modal={false}
                  >
                    <TourStep
                      tourId="onshore-intro-tour"
                      stepId="viewParkShadow"
                      innerRef={refsViewParkShadow.setFloating}
                      style={floatingStylesViewParkShadow}
                      onEnterStepAction={() => {
                        if (
                          topRightModeActive !== TopRightMenuOptions.parkShadow
                        ) {
                          setTopRightModeActive(undefined);
                        }
                      }}
                    />
                  </FloatingFocusManager>
                </>
              )}
            {menuList.includes(TopRightMenuOptions.cost) && (
              <MenuOptionV2
                id={`button-${TopRightMenuOptions.cost}`}
                Icon={Financial}
                chosenMenu={topRightModeActive}
                setChosenMenu={onSelectMenu}
                option={TopRightMenuOptions.cost}
                title={"Financial"}
                searchObject={{
                  title: "Open financial analysis",
                  id: "search-financial-stats",
                  tags: ["numbers", "stats", "overview", "money"],
                  icon: <Financial />,
                  onSelect: () => {
                    onSelectMenu(TopRightMenuOptions.cost);
                  },
                }}
              />
            )}
            {menuList.includes(TopRightMenuOptions.cableStatistics) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.cableStatistics}`}
                    Icon={Electrical}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.cableStatistics}
                    title={"Electrical"}
                    searchObject={{
                      title: "Open electrical analysis",
                      id: "search-electrical-stats",
                      tags: ["numbers", "stats", "overview", "power", "cable"],
                      icon: <Electrical />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.cableStatistics);
                      },
                    }}
                  />
                </>
              )}

            {menuList.length > 1 && <Divider />}
            {menuList.includes(TopRightMenuOptions.terrainanalysis) &&
              viewerAccessProject && (
                <HighlightStep
                  tourId="onshore-intro-tour"
                  stepId="selectTopography"
                >
                  <MenuOptionV2
                    Icon={Terrain}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={(m: TopRightModeType) => {
                      trackEvent("clickedTopography");
                      return onSelectMenu(m);
                    }}
                    innerRef={refsTopography.setReference}
                    option={TopRightMenuOptions.terrainanalysis}
                    title={"Topography"}
                    searchObject={{
                      title: "Open topography analysis",
                      id: "search-topography-analysis",
                      tags: ["ground", "slope analysis", "water analysis"],
                      icon: <Terrain />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.terrainanalysis);
                      },
                    }}
                  />
                </HighlightStep>
              )}
            {menuList.includes(TopRightMenuOptions.onshoreNoise) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.onshoreNoise}`}
                    Icon={NoiseIcon}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.onshoreNoise}
                    title={"Noise"}
                    searchObject={{
                      title: "Open noise analysis",
                      id: "search-noise-analysis",
                      tags: ["surroundings"],
                      icon: <NoiseIcon />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.onshoreNoise);
                      },
                    }}
                  />
                </>
              )}
            <Divider />
            {menuList.includes(TopRightMenuOptions.shadowFlicker) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    Icon={ShadowFlickerIcon}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.shadowFlicker}
                    title={"Shadow flicker"}
                    searchObject={{
                      title: "Open shadow flicker analysis",
                      id: "search-shadow-analysis",
                      tags: ["shadow", "flicker", "cast"],
                      icon: <ShadowFlickerIcon />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.shadowFlicker);
                      },
                    }}
                    innerRef={shadowFlickerRef}
                  />
                  <Anchor
                    baseRef={shadowFlickerRef}
                    basePlace="topRight"
                    floatPlace="topRight"
                  >
                    <LoadingIndicator atom={shadowAnyLoading} />
                  </Anchor>
                </>
              )}

            {menuList.includes(TopRightMenuOptions.parkShadow) &&
              viewerAccessProject && (
                <>
                  <HighlightStep
                    tourId="onshore-intro-tour"
                    stepId="viewParkShadow"
                  >
                    <Tooltip
                      text={`Park too big, only parks less than ${MAX_KM_2_SIZE_FOR_PARK_SHADOW}km^2 supported`}
                      disabled={featureSize < MAX_KM_2_SIZE_FOR_PARK_SHADOW}
                    >
                      <MenuOptionV2
                        disabled={featureSize >= MAX_KM_2_SIZE_FOR_PARK_SHADOW}
                        Icon={ParkShadows}
                        innerRef={refsViewParkShadow.setReference}
                        chosenMenu={topRightModeActive}
                        setChosenMenu={(m: TopRightModeType) => {
                          trackEvent("clickedViewParkShadow");
                          return onSelectMenu(m);
                        }}
                        option={TopRightMenuOptions.parkShadow}
                        title={"View park shadows"}
                        id="view_from_park_shadow"
                        searchObject={{
                          title: "Open view park shadows",
                          id: "search-view-park-shadows",
                          tags: ["view analysis", "3D"],
                          icon: <ViewFromShore />,
                          onSelect: () => {
                            onSelectMenu(TopRightMenuOptions.parkShadow);
                          },
                        }}
                      />
                    </Tooltip>
                  </HighlightStep>
                </>
              )}
            {menuList.length > 1 && <Divider />}
            {menuList.includes(TopRightMenuOptions.viewshed) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    Icon={Viewshed}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.viewshed}
                    title={"Viewshed"}
                    searchObject={{
                      title: "Open viewshed analysis",
                      id: "search-viewshed-analysis",
                      tags: ["gdal", "visible turbines"],
                      icon: <Viewshed />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.viewshed);
                      },
                    }}
                  />
                </>
              )}
            {menuList.includes(TopRightMenuOptions.view) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    Icon={ViewFromShore}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.view}
                    title={"View park"}
                    id="view_from_shore"
                    searchObject={{
                      title: "Open view park",
                      id: "search-view-from-shore",
                      tags: ["view analysis", "3D"],
                      icon: <ViewFromShore />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.view);
                      },
                    }}
                  />
                </>
              )}
          </>
        )}
      </IconMenu>
    </>
  );
};

const ProjectFeatureInfoModalV2Offshore = ({
  featureId,
  initialMenuOption,
}: {
  featureId: string;
  initialMenuOption?: TopRightModeType;
}) => {
  const viewerAccessProject = useAtomValue(viewerAccessProjectSelector);
  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );

  const [topRightModeActive, setTopRightModeActive] = useAtom(
    TopRightModeActiveAtom,
  );
  const [configurationMenuActive, setConfigurationMenuActive] = useAtom(
    configurationMenuActiveAtom,
  );
  const rightModalOpenState = useAtomValue(rightModalOpenStateAtom);
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (initialMenuOption) {
      setTopRightModeActive(initialMenuOption);
    }
  }, [initialMenuOption, setTopRightModeActive]);

  const onSelectMenu = useCallback(
    (menu: TopRightModeType) => {
      setTopRightModeActive(menu);
    },
    [setTopRightModeActive],
  );

  const onClose = useCallback(() => {
    setTopRightModeActive(undefined);
  }, [setTopRightModeActive]);

  const projectFeature = useMemo(
    () => featureMap.get(featureId),
    [featureMap, featureId],
  );

  const menuList: string[] = useMemo(() => {
    if (isPark(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.foundationStatistics,
        TopRightMenuOptions.noise,
        TopRightMenuOptions.bathymetry,
        TopRightMenuOptions.view,
        TopRightMenuOptions.viewshed,
        TopRightMenuOptions.cost,
      ];
    }

    if (isTurbine(projectFeature)) {
      return [TopRightMenuOptions.turbineStatistics];
    }

    if (isSubArea(projectFeature)) {
      return [
        TopRightMenuOptions.productionStatistics,
        TopRightMenuOptions.cableStatistics,
        TopRightMenuOptions.foundationStatistics,
        TopRightMenuOptions.bathymetry,
        TopRightMenuOptions.viewshed,
      ];
    }

    if (isBathymetry(projectFeature)) {
      return [];
    }

    if (isGeotiff(projectFeature)) {
      return [];
    }

    if (isPolygonFeature(projectFeature)) {
      return [TopRightMenuOptions.bathymetry];
    }

    return [];
  }, [projectFeature]);

  useEffect(() => {
    if (!topRightModeActive) return;
    if (topRightModeActive === TopRightMenuOptions.cost) return;
    if (!menuList.includes(topRightModeActive))
      setTopRightModeActive(undefined);
  }, [projectFeature, topRightModeActive, setTopRightModeActive, menuList]);

  const { refs, floatingStyles, context } = useFloating({
    placement: "left",
    middleware: [
      offset({
        mainAxis: 10,
        crossAxis: 20,
      }),
    ],
  });

  if (!projectFeature || rightModalOpenState) return null;

  return (
    <>
      <RightMenuPositionWrapper>
        <RightMenuStack>
          {configurationMenuActive && <ConfigurationMenu />}
          <MapContextProvider>
            <Suspense fallback={null}>
              <ChosenContent
                onClose={onClose}
                chosenMenu={topRightModeActive}
                canvasFeature={projectFeature}
              />
            </Suspense>
          </MapContextProvider>
        </RightMenuStack>
      </RightMenuPositionWrapper>

      <IconMenu vertical sideBar iconSize="2.4rem" id="analysis-menu">
        <p style={typography.sub2}>Analysis</p>
        <MenuOptionV2
          dark
          Icon={FilterIcon}
          chosenMenu={configurationMenuActive}
          setChosenMenu={() =>
            setConfigurationMenuActive(!configurationMenuActive)
          }
          option={true} // To be selected when configuration menu is active (configurationMenuActive = true)
          searchObject={{
            title: "Open branch configurations",
            id: "search-branch-configurations",
            tags: ["configuration", "edit"],
            icon: <FilterIcon />,
            onSelect: () => {
              setConfigurationMenuActive(!configurationMenuActive);
            },
          }}
        />
        {menuList.length > 0 && (
          <>
            <Divider />
            {menuList.includes(TopRightMenuOptions.turbineStatistics) &&
              viewerAccessProject && (
                <MenuOptionV2
                  Icon={Production}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.turbineStatistics}
                  title={"Turbine"}
                  searchObject={{
                    title: "Open turbine analysis",
                    id: "search-turbine-stats",
                    tags: ["numbers", "stats", "turbine information"],
                    icon: <Production />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.turbineStatistics);
                    },
                  }}
                />
              )}

            {menuList.includes(TopRightMenuOptions.productionStatistics) &&
              viewerAccessProject && (
                <>
                  <HighlightStep
                    tourId="general-intro-tour"
                    stepId="seeStatistics"
                  >
                    <MenuOptionV2
                      id={`button-${TopRightMenuOptions.productionStatistics}`}
                      innerRef={refs.setReference}
                      Icon={Production}
                      chosenMenu={topRightModeActive}
                      setChosenMenu={(m: TopRightModeType) => {
                        trackEvent("clickedProductionStatistics");
                        return onSelectMenu(m);
                      }}
                      option={TopRightMenuOptions.productionStatistics}
                      title={"Production"}
                      searchObject={{
                        title: "Open production analysis",
                        id: "search-production-stats",
                        tags: ["numbers", "stats"],
                        icon: <Production />,
                        onSelect: () => {
                          onSelectMenu(
                            TopRightMenuOptions.productionStatistics,
                          );
                        },
                      }}
                    />
                  </HighlightStep>
                  <FloatingFocusManager context={context} modal={false}>
                    <TourStep
                      tourId="general-intro-tour"
                      stepId="seeStatistics"
                      innerRef={refs.setFloating}
                      style={floatingStyles}
                    />
                  </FloatingFocusManager>
                </>
              )}
            {menuList.includes(TopRightMenuOptions.cost) && (
              <>
                <MenuOptionV2
                  id={`button-${TopRightMenuOptions.cost}`}
                  Icon={Financial}
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.cost}
                  title={"Financial"}
                  searchObject={{
                    title: "Open financial analysis",
                    id: "search-financial-stats",
                    tags: ["numbers", "stats", "overview", "money"],
                    icon: <Financial />,
                    onSelect: () => {
                      onSelectMenu(TopRightMenuOptions.cost);
                    },
                  }}
                />
              </>
            )}
            {menuList.includes(TopRightMenuOptions.foundationStatistics) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.foundationStatistics}`}
                    Icon={Foundation}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.foundationStatistics}
                    title={"Foundation"}
                    searchObject={{
                      title: "Open foundation analysis",
                      id: "search-foundation-stats",
                      tags: ["numbers", "stats", "overview"],
                      icon: <Foundation />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.foundationStatistics);
                      },
                    }}
                  />
                </>
              )}
            {menuList.includes(TopRightMenuOptions.cableStatistics) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.cableStatistics}`}
                    Icon={Electrical}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.cableStatistics}
                    title={"Electrical"}
                    searchObject={{
                      title: "Open electrical analysis",
                      id: "search-electrical-stats",
                      tags: ["numbers", "stats", "overview", "power", "cable"],
                      icon: <Electrical />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.cableStatistics);
                      },
                    }}
                  />
                </>
              )}

            {menuList.length > 1 && <Divider />}
            {menuList.includes(TopRightMenuOptions.bathymetry) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.bathymetry}`}
                    Icon={Bathymetry}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.bathymetry}
                    title={"Bathymetry"}
                    searchObject={{
                      title: "Open bathymetry analysis",
                      id: "search-bathymetry-analysis",
                      tags: ["ground", "slope analysis", "depth analysis"],
                      icon: <Bathymetry />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.bathymetry);
                      },
                    }}
                  />
                </>
              )}
            {menuList.includes(TopRightMenuOptions.noise) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.noise}`}
                    Icon={NoiseIcon}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.noise}
                    title={"Noise"}
                    searchObject={{
                      title: "Open noise analysis",
                      id: "search-noise-analysis",
                      tags: ["surroundings"],
                      icon: <NoiseIcon />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.noise);
                      },
                    }}
                  />
                </>
              )}
            {menuList.length > 1 && <Divider />}

            {menuList.includes(TopRightMenuOptions.viewshed) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    id={`button-${TopRightMenuOptions.viewshed}`}
                    Icon={Viewshed}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.viewshed}
                    title={"Viewshed"}
                    searchObject={{
                      title: "Open viewshed analysis",
                      id: "search-viewshed-analysis",
                      tags: ["gdal", "visible turbines"],
                      icon: <Viewshed />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.viewshed);
                      },
                    }}
                  />
                </>
              )}
            {menuList.includes(TopRightMenuOptions.view) &&
              viewerAccessProject && (
                <>
                  <MenuOptionV2
                    Icon={ViewFromShore}
                    chosenMenu={topRightModeActive}
                    setChosenMenu={onSelectMenu}
                    option={TopRightMenuOptions.view}
                    title={"View from shore"}
                    id="view_from_shore"
                    searchObject={{
                      title: "Open view from shore",
                      id: "search-view-from-shore",
                      tags: ["view analysis", "3D"],
                      icon: <ViewFromShore />,
                      onSelect: () => {
                        onSelectMenu(TopRightMenuOptions.view);
                      },
                    }}
                  />
                </>
              )}
          </>
        )}
      </IconMenu>
    </>
  );
};

const ProjectFeatureInfoModalV2 = ({
  featureId,
  initialMenuOption,
}: {
  featureId?: string;
  initialMenuOption?: TopRightModeType;
}) => {
  const projectType = useAtomValue(designToolTypeAtom);
  const [configurationMenuActive, setConfigurationMenuActive] = useAtom(
    configurationMenuActiveAtom,
  );
  if (!featureId) {
    return (
      <>
        <RightMenuPositionWrapper>
          <RightMenuStack>
            {configurationMenuActive && <ConfigurationMenu />}
          </RightMenuStack>
        </RightMenuPositionWrapper>
        <IconMenu vertical sideBar iconSize="2.4rem">
          <p style={typography.sub2}>Analysis</p>
          <MenuOptionV2
            dark
            Icon={FilterIcon}
            chosenMenu={configurationMenuActive}
            setChosenMenu={() =>
              setConfigurationMenuActive(!configurationMenuActive)
            }
            option={true}
            searchObject={{
              title: "Open branch configurations",
              id: "search-branch-configurations",
              tags: ["configuration", "edit"],
              icon: <FilterIcon />,
              onSelect: () => {
                setConfigurationMenuActive(!configurationMenuActive);
              },
            }}
          />
          <Divider />
          <Tooltip
            position="left"
            text="Click park or element to view analysis"
          >
            <MenuOptionV2
              Icon={Production}
              chosenMenu={undefined}
              setChosenMenu={() => {}}
              disabled={true}
              option={true}
            />
          </Tooltip>
        </IconMenu>
      </>
    );
  }

  return projectType === DesignToolMode.Onshore ? (
    <ProjectFeatureInfoModalV2Onshore
      featureId={featureId}
      initialMenuOption={initialMenuOption}
    />
  ) : (
    <ProjectFeatureInfoModalV2Offshore
      featureId={featureId}
      initialMenuOption={initialMenuOption}
    />
  );
};

export default ProjectFeatureInfoModalV2;
