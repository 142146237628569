import PositionHint from "components/ActiveTips/PositionHints/PositionHint";
import SideBarIconBtn from "components/General/SideBarIconButton";
import SearchWrapper from "components/Search/SearchWrapper";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { mapAtom } from "state/map";
import { typography } from "styles/typography";
import Reset from "@icons/24/SideBarIcons/Reset.svg?react";
import { resetOrientationHelpHelpHintType } from "components/ActiveTips/PositionHints/ResetOrientationHelp";

const ResetMapOrientation = () => {
  const map = useAtomValue(mapAtom);
  const resetToNorth = useCallback(() => {
    if (!map) return;
    map.easeTo({
      pitch: 0,
      bearing: 0,
      duration: 500,
    });
  }, [map]);

  const [rotation, setRotation] = useState(0);
  useEffect(() => {
    if (!map) return;
    const setRotationOnMove = () => {
      setRotation(map.getBearing());
    };
    map.on("move", setRotationOnMove);
    return () => {
      map.off("move", setRotationOnMove);
    };
  }, [map]);

  return (
    <SearchWrapper
      title="Reset orientation"
      tags={["camera", "map", "view", "north"]}
      id="search-reset-orientation"
      icon={<Reset />}
      onSelect={() => {
        resetToNorth();
      }}
    >
      <PositionHint
        allowedHints={[resetOrientationHelpHelpHintType]}
        position={"top"}
      >
        <SideBarIconBtn
          onClick={resetToNorth}
          icon={<Reset style={{ transform: `rotate(${-rotation - 45}deg)` }} />}
        >
          <p style={typography.graphics}>Reset</p>
        </SideBarIconBtn>
      </PositionHint>
    </SearchWrapper>
  );
};

export default ResetMapOrientation;
