import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { _CostConfiguration } from "../services/costService";
import { savingCostConfigurationInProgressAtom } from "../state/costConfigurations";
import { windConfigurationTempName } from "state/windSourceConfiguration";
import { updateWindSourceConfiguration } from "services/windSourceConfigurationService";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectWindConfigurationsFamily } from "state/jotai/windConfiguration";

const useWindConfigurationCrud = () => {
  const projectId = useAtomValue(projectIdAtom);
  const setIsSaving = useSetAtom(savingCostConfigurationInProgressAtom);

  const saveNameLocalJotai = useJotaiCallback(
    async (get, set, configId: string, name: string) => {
      if (!projectId) {
        return;
      }

      return aset(
        get,
        set,
        projectWindConfigurationsFamily(projectId),
        (cur) => {
          const newMap = new Map(cur);
          const config = newMap.get(configId);
          if (!config) return cur;
          newMap.set(configId, {
            ...config,
            name,
          });
          return newMap;
        },
      );
    },
    [projectId],
  );

  // This method saves the new name to the data base and the atom 'windConfigurationTempName'.
  // The new name is not updated in localConfig or windSourceConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useJotaiCallback(
    async (get, set, configId: string, name: string) => {
      if (!projectId) return;
      setIsSaving(true);

      saveNameLocalJotai(configId, name);
      set(
        windConfigurationTempName({
          nodeId: projectId,
        }),
        (cur) => ({
          ...cur,
          [configId]: name,
        }),
      );

      const currentConfigurations = await get(
        projectWindConfigurationsFamily(projectId),
      );

      const currentConfig = currentConfigurations.get(configId);

      if (!currentConfig)
        throw Error("Could not find wind config, config id: " + configId);

      await updateWindSourceConfiguration({
        nodeId: projectId,
        configuration: {
          ...currentConfig,
          name: name,
        },
      });
      setIsSaving(false);
    },
    [projectId, setIsSaving, saveNameLocalJotai],
  );

  // This is used to transfer the new names stored in windConfigurationTempName to windSourceConfigurationsAtomFamily,
  // This is called when the component is unmounted
  const saveTempNameToLocal = useJotaiCallback(
    async (get, set, configId: string) => {
      if (!projectId) return;

      const tempConfigNames = get(
        windConfigurationTempName({
          nodeId: projectId,
        }),
      );

      const tempName = tempConfigNames[configId];
      if (!tempName) return;

      set(projectWindConfigurationsFamily(projectId), async (cur) => {
        const m = new Map(await cur);
        const prev = m.get(configId);
        if (prev) m.set(prev.id, { ...prev, name: tempName });
        return m;
      });

      set(
        windConfigurationTempName({
          nodeId: projectId,
        }),
        (cur) => ({
          ...cur,
          [configId]: undefined,
        }),
      );
    },
    [projectId],
  );

  return {
    saveName,
    saveTempNameToLocal,
  };
};

export default useWindConfigurationCrud;
