import { analysisStoppedText, getAnalysisError } from "analysis/warnings";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { formatPercent } from "components/ProductionV2/format";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { getFinancialStoppedReason } from "finance/warnings";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getFinancialStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );

  const stopped = stoppedReason ?? analysisStoppedReason;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stopped]} type={"error"} />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={installationStoppedText[installationStoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <h4>Project lifetime</h4>
      <Row>
        <p>Project start</p>
        <p>{costConfiguration?.lifeCycle.projectStart}</p>
      </Row>
      <Row>
        <p>Operation start</p>
        <p>{costConfiguration?.lifeCycle.operationStart}</p>
      </Row>
      <Row>
        <p>Decomissioning</p>
        <p>{costConfiguration?.lifeCycle.decomissioning}</p>
      </Row>
      <h4>Contingency</h4>
      <Row>
        <p>CAPEX</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.contingency.capex.value)
            : "-"}
        </p>
      </Row>
      <Row>
        <p>OPEX</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.contingency.opex.value)
            : "-"}
        </p>
      </Row>

      <h4>LCoE rates</h4>
      <Row>
        <p>Discount rate</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.lcoe.discountRate)
            : "-"}
        </p>
      </Row>
      <Row>
        <p>Inflation rate</p>
        <p>
          {costConfiguration
            ? formatPercent(costConfiguration.inflation.inflationRate)
            : "-"}
        </p>
      </Row>

      <h4>Cash flow</h4>
      <Row>
        <p>Revenue reference year</p>
        <p>{costConfiguration?.inflation.referenceYearRevenue}</p>
      </Row>

      <Row>
        <p>Guaranteed price</p>
        <p>
          {costConfiguration?.cashFlows.guaranteedPrice.cost}{" "}
          {costConfiguration?.cashFlows.guaranteedPrice.unit}
        </p>
      </Row>

      {costConfiguration?.lifeCycle.operationStart &&
        costConfiguration?.cashFlows.guaranteedYears && (
          <Row>
            <p>Guaranteed price years</p>
            <p>
              {costConfiguration?.lifeCycle.operationStart} -{" "}
              {costConfiguration?.lifeCycle.operationStart +
                costConfiguration?.cashFlows.guaranteedYears}
            </p>
          </Row>
        )}

      <Row>
        <p>Market price</p>
        <p>
          {costConfiguration?.cashFlows.marketPrice.cost}{" "}
          {costConfiguration?.cashFlows.marketPrice.unit}
        </p>
      </Row>
    </FlexGrid2>
  );
};

export const CostInputWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Cost input"
      id="Cost input"
      resetKeys={errorBoundaryResetKeys}
    >
      <InputDetails />
    </SafeCard>
  );
};
