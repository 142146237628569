import styled from "styled-components";
import { spaceMedium } from "styles/space";
import { colors } from "styles/colors";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  width: 100%;
  max-height: 25rem;
  overflow-y: auto;

  > :nth-child(odd) {
    background-color: ${colors.focusBackground};
  }
`;
