import { FinanceId } from "finance/types";
import { DownloadCSV } from "components/CsvDownload/CsvDownloadButton";
import { useIRR } from "finance/hooks/useIrr";
import { lunwrap } from "utils/jotai";
import { getPark } from "analysis/inputs";
import { useAtomValue } from "jotai";

export const DownloadIRRData = ({
  id,
  buttonType,
}: {
  id: FinanceId;
  buttonType: "text" | "menu";
}) => {
  const {
    results: { exportData },
  } = useIRR(id);
  const park = useAtomValue(getPark(id));

  const columns = [
    { header: "Type", field: "type" },
    { header: "Value", field: "value" },
    { header: "Unit", field: "unit" },
  ];

  const getData = async () => lunwrap(exportData);

  // TODO: this should be on-demand too
  const filename = `irr-${park.properties.name}-${new Date().toISOString()}.csv`;

  return (
    <DownloadCSV
      data={getData}
      columns={columns}
      filename={filename}
      buttonType={buttonType}
      loading={exportData.state === "loading"}
    />
  );
};
