import { useJotaiCallback } from "utils/jotai";
import { AnalysisConfiguration } from "services/configurationService";
import { libraryAnalysisConfigurationsFamily } from "state/jotai/analysisConfiguration";

export function useLibraryNodeAnalysis(projectId: string) {
  const updateAnalysisLocal = useJotaiCallback(
    async (_, set, analysis: AnalysisConfiguration) => {
      set(
        libraryAnalysisConfigurationsFamily({ projectId }),
        async (currentAnalysisPromise) => {
          const currentAnalysis = await currentAnalysisPromise;
          const newAnalysis = new Map(currentAnalysis);
          newAnalysis.set(analysis.id, analysis);
          return newAnalysis;
        },
      );
    },
    [projectId],
  );

  return {
    updateAnalysisLocal,
  };
}
