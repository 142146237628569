import { atom } from "jotai";

export const ProjectConfigModalTypeV2 = "ProjectConfigModal";
export const FeatureSettingsModalTypeV2 = "FeatureSettingsModal";
export const PublicModeModalTypeV2 = "PublicModeModal";
export const ConfigurationModalType = "ConfigurationModal";
export const TeamConfigurationModalType = "TeamConfigurationModal";
export const ProjectOverviewModalType = "ProjectOverviewModal";

export enum MenuItems {}

export const savingConfigurationInProgressAtom = atom<boolean>(false);
