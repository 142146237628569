import SubtractCircleIcon from "@icons/24/SubtractCircle.svg";
import {
  sourceValueMinMaxSelector,
  styleSelectorFamily,
} from "business/style/state";
import { BucketStyle, isBucket } from "business/style/types";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import { Input, TextInput } from "components/General/Input";
import { Column } from "components/General/Layout";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing3, spacing7 } from "styles/space";
import { typography } from "styles/typography";
import { parseValue, renderValue } from "business/style/constants";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { ColorSquare } from "components/General/ColorSquare";
import { balanceStyle } from "business/style/actions";
import ReverseIcon from "@icons/14/Reverse.svg";
import StylingIcon from "@icons/14/Styling.svg";
import { MenuButton, MenuButtonRef } from "components/General/MenuButton";
import { useRef } from "react";
import { ColorBucketMenuItems } from "./ColorBucketDropdown";
import { Buckets, Color } from "lib/colors";
import Dropdown from "components/Dropdown/Dropdown";
import { colorBrewer } from "business/style/colors";

/**
 * Resize a bucket.  Try to find the color scheme used for the current choice of colors.
 * If it exists, use the same color scheme but with the new bucket size.
 */
function getColorsForNewBucketSize(colors: Color[], len: number): Color[] {
  let key: keyof typeof colorBrewer = "YlOrRd";
  let rev = false;
  for (const [name, obj] of Object.entries(colorBrewer)) {
    if (!(colors.length in obj)) continue;
    const cls = (obj as any)[colors.length]; // safety: above
    if (cls.at(-1) === colors.at(-1)) {
      key = name as keyof typeof colorBrewer;
      break;
    } else if (cls.at(-1) === colors.at(0)) {
      key = name as keyof typeof colorBrewer;
      rev = true;
      break;
    }
  }

  if (!(len in colorBrewer[key]))
    throw new Error("no matching scheme with supplied nextLen");
  const out = (colorBrewer[key] as any)[len];
  if (rev) out.reverse();
  return out;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: ${spacing3} ${spacing7};

  > ${ColorSquare} {
    height: 2rem;
    width: 2rem;
    margin: 0.2rem;
  }

  > ${TextInput} {
    ${typography.caption};
    min-width: 0;
    flex: 1;
  }

  ${IconBtn} {
    svg {
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    ${IconBtn} {
      svg {
        opacity: 1;
      }
    }
  }
`;

const StyleCol = styled(Column)`
  .list {
    display: flex;
    flex-direction: column;
    > div:nth-child(2n + 2):not(:last-child) {
      background: ${colors.surfaceSecondary};
    }
  }

  & > button {
    align-self: end;
  }
`;

export function EditColorBucket({ style }: { style: BucketStyle }) {
  const disabled = style.defaultMarker;
  const setStyle = useSetAtom(styleSelectorFamily(style.id));

  const updateNumber = (i: number, n: number) => {
    if (!isBucket(style)) return;
    const buckets = style.buckets.clone().moveBoundary(i, n);
    setStyle({ ...style, buckets });
  };

  const minmax = useAtomValue(loadable(sourceValueMinMaxSelector(style.id)));
  const balance = useSetAtom(balanceStyle);
  const mbRef = useRef<MenuButtonRef>(null);

  if (!isBucket(style)) return null;
  return (
    <StyleCol>
      <Row>
        <MenuButton
          ref={mbRef}
          icon={<StylingIcon />}
          size="small"
          buttonType="secondary"
          disabled={disabled}
        >
          <ColorBucketMenuItems
            numBuckets={style.buckets.numBuckets() as any}
            onClick={(buckets: Buckets) => {
              mbRef.current?.setIsOpen(false);
              balance({ ...style, buckets });
            }}
          />
        </MenuButton>
        <Dropdown
          small
          value={style.buckets.numBuckets()}
          onChange={(e) => {
            const n = parseInt(e.target.value) as 3 | 4 | 5 | 6 | 7;
            if (n < 3 || 7 < n) return; // NOTE: should never happen, but just in case
            const colors = getColorsForNewBucketSize(
              style.buckets.buckets().map((b) => b.color),
              n,
            );
            const { min, max } = (minmax.state === "hasData"
              ? minmax.data
              : undefined) ?? { min: 0, max: 1 };

            const buckets = Buckets.fromBalanced(colors, min, max);
            setStyle({
              ...style,
              buckets,
            });
          }}
        >
          <option value="3">3 buckets</option>
          <option value="4">4 buckets</option>
          <option value="5">5 buckets</option>
          <option value="6">6 buckets</option>
          <option value="7">7 buckets</option>
        </Dropdown>
      </Row>

      <div className="list">
        {style.buckets.buckets().map((b, i, a) => {
          const from = i === 0 ? "min" : renderValue(style.source, b.from);
          const to =
            i === a.length - 1 ? "max" : renderValue(style.source, b.to);
          return (
            <Row key={i}>
              <ColorSquare $color={b.color} />
              <Input
                disabled={i === 0}
                key={"f" + from}
                defaultValue={from}
                compact
                onBlur={(e) =>
                  updateNumber(i - 1, parseValue(style.source, e.target.value))
                }
              />
              <Input
                disabled={i === a.length - 1}
                key={"t" + to}
                defaultValue={to}
                compact
                onBlur={(e) =>
                  updateNumber(i, parseValue(style.source, e.target.value))
                }
              />
              <IconBtn
                size="1.4rem"
                disabled={style.buckets.numBuckets() <= 3}
                onClick={() => {
                  if (style.buckets.numBuckets() <= 3) return;
                  const buckets = style.buckets.clone().pop(i);
                  setStyle({ ...style, buckets });
                }}
              >
                <SubtractCircleIcon />
              </IconBtn>
            </Row>
          );
        })}
      </div>
      <Row>
        <Button
          icon={<ReverseIcon />}
          size="small"
          buttonType="secondary"
          disabled={style.buckets.numBuckets() <= 3 || disabled}
          onClick={() => {
            if (style.buckets.numBuckets() <= 3) return;
            setStyle({
              ...style,
              buckets: style.buckets.clone().reverseColors(),
            });
          }}
        />
        <Button
          size="small"
          buttonType="secondary"
          text="Balance interval"
          disabled={minmax.state !== "hasData" || disabled}
          onClick={() => balance(style)}
        />
      </Row>
    </StyleCol>
  );
}
