const roundUpToNearestHoundredThousand = (value: number) => {
  return Math.ceil(value / 1e5) * 1e5;
};

export const calculateTurbineDefaultCosts = ({
  diameter,
  ratedPower,
  hubHeight,
}: {
  diameter: number;
  ratedPower: number;
  hubHeight: number;
}): number => {
  // Source: page 9 in https://ens.dk/sites/ens.dk/files/Statistik/technology_catalogue_offshore_wind_march_2022_-_annex_to_prediction_of_performance_and_cost.pdf
  const rotorArea = Math.PI * (diameter / 2) ** 2;
  const specificPower = (1000 * ratedPower) / rotorArea;

  const A = -0.5;
  const B = 750;
  const C = 0.53;
  const D = 5500;
  const E = 1.1;

  const cost =
    (ratedPower * (A * specificPower + B) + C * hubHeight * rotorArea + D) * E;

  return roundUpToNearestHoundredThousand(cost) / 1e6;
};
