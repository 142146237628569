import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import {
  deleteCommentFromChangelogNode,
  putCommentToChangelogNode,
} from "./service";
import { inputChangelogsAtomFamily } from "./state";
import { ChangelogCategory } from "./types";
import { aset, useJotaiCallback } from "utils/jotai";

function createPutUrl(
  nodeId: string,
  changelogId: string,
  version: number,
  category: ChangelogCategory,
) {
  switch (category) {
    case "project":
      return `/api/timeline/changelog/node/${nodeId}/entry/${encodeURIComponent(changelogId)}/version/${version}/comment`;
    case "org_turbine_manage":
    case "org_cable_manage":
    case "org_foundation_manage":
    case "org_analysis_manage":
    case "org_financial_manage":
    case "org_data_package_manage":
      return `/api/timeline/changelog/organisation/${nodeId}/${category}/entry/${encodeURIComponent(changelogId)}/version/${version}/comment`;

    default:
      throw new Error(`Unknown category: ${category}`);
  }
}
function createDeleteUrl(
  nodeId: string,
  changelogId: string,
  version: number,
  keyPath: string,
  category: ChangelogCategory,
) {
  switch (category) {
    case "project":
      return `/api/timeline/changelog/node/${nodeId}/entry/${encodeURIComponent(changelogId)}/version/${version}/comment/${keyPath}`;
    case "org_turbine_manage":
    case "org_cable_manage":
    case "org_foundation_manage":
    case "org_analysis_manage":
    case "org_financial_manage":
    case "org_data_package_manage":
      return `/api/timeline/changelog/organisation/${nodeId}/${category}/entry/${encodeURIComponent(changelogId)}/version/${version}/comment/${keyPath}`;
    default:
      throw new Error(`Unknown category: ${category}`);
  }
}

export const useChangelogCommentCrud = (nodeId: string) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const put = useJotaiCallback(
    async (
      _,
      set,
      changelogId: string,
      version: number,
      category: ChangelogCategory,
      comment: {
        comment: string;
        keyPath: string;
      },
    ) => {
      if (!nodeId) return;
      const res = await putCommentToChangelogNode(
        createPutUrl(nodeId, changelogId, version, category),
        comment,
      );
      await aset(
        _,
        set,
        inputChangelogsAtomFamily({
          nodeId,
          changelogId,
          category,
          organisationId,
        }),
        (cur) =>
          cur.map((entry) =>
            entry.version === version
              ? {
                  ...entry,
                  comments: (entry.comments ?? [])
                    .filter((c) => c.keyPath !== comment.keyPath)
                    .concat(res),
                }
              : entry,
          ),
      );

      return res;
    },
    [nodeId, organisationId],
  );

  const _delete = useJotaiCallback(
    async (
      _,
      set,
      changelogId: string,
      version: number,
      keyPath: string,
      category: ChangelogCategory,
    ) => {
      if (!nodeId) return;
      await deleteCommentFromChangelogNode(
        createDeleteUrl(nodeId, changelogId, version, keyPath, category),
      );
      await aset(
        _,
        set,
        inputChangelogsAtomFamily({
          nodeId,
          changelogId,
          category,
          organisationId,
        }),
        (curr) =>
          curr.map((entry) =>
            entry.version === version
              ? {
                  ...entry,
                  comments: (entry.comments ?? []).filter(
                    (c) => c.keyPath !== keyPath,
                  ),
                }
              : entry,
          ),
      );
    },
    [nodeId, organisationId],
  );

  return {
    put,
    _delete,
  };
};
