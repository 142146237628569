import { CSSProperties, useEffect, useState } from "react";
import {
  getProjectImage,
  getProjectImageAdmin,
} from "../../services/projectDataAPIService";
import { SkeletonBlock } from "../Loading/Skeleton";
import styled from "styled-components";
import { ProjectMeta } from "../../types/api";
import StaticImage from "./static_image.png";
import { colors } from "styles/colors";
import { DesignToolMode } from "types/map";

export const projectImageWidth = "28.4rem";
export const projectImageHeight = "15.2rem";
const ProjectImage = styled.img`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
  border-radius: 0.6rem;
  object-fit: cover;
  object-position: center;
  margin: 0.8rem;
`;

const NOT_AVAILABLE = "not_available";

const ProjectImageSkeleton = styled(SkeletonBlock)`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
  margin: 0.8rem;
`;

export const TutorialProjectImage = styled.div`
  width: ${projectImageWidth};
  height: ${projectImageHeight};
  background: ${colors.blue100};
  border-radius: 0.6rem;
  object-fit: cover;
  object-position: center;
  margin: 0.8rem;
`;

const StaticMapboxProjectImage = ({
  project,
  branchId,
  parkId,
  style,
  admin,
}: {
  project: ProjectMeta;
  branchId?: string;
  parkId?: string;
  style?: CSSProperties;
  admin?: boolean;
}) => {
  let [imgSrc, setImgSrc] = useState<string | undefined | typeof NOT_AVAILABLE>(
    undefined,
  );

  const projectMode =
    project.project_type === DesignToolMode.Onshore
      ? DesignToolMode.Onshore
      : DesignToolMode.Offshore;

  // Reset the image when the project changes, to show loader while the new image loads
  useEffect(() => {
    setImgSrc(undefined);
  }, [project]);

  useEffect(() => {
    let isSubmitted = false;
    const asyncFunc = async () => {
      if (!project.main_branch_id) return;
      const url = admin
        ? await getProjectImageAdmin(
            project.id,
            branchId ?? project.main_branch_id,
            parkId,
            projectMode,
          )
        : await getProjectImage(
            project.id,
            branchId ?? project.main_branch_id,
            parkId,
            projectMode,
          );
      if (isSubmitted) return;
      if (!url) {
        setImgSrc(NOT_AVAILABLE);
        return;
      }
      setImgSrc(url);
    };

    asyncFunc();
    return () => {
      isSubmitted = true;
    };
  }, [parkId, branchId, project, projectMode, admin]);

  if (imgSrc === undefined) return <ProjectImageSkeleton style={style} />;

  if (imgSrc === NOT_AVAILABLE) imgSrc = StaticImage;

  return (
    <ProjectImage
      src={imgSrc}
      alt={"layout"}
      style={style}
      referrerPolicy="origin"
    />
  );
};

export default StaticMapboxProjectImage;
