import { organisationIdAtom } from "state/pathParams";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import Spinner from "@icons/spinner/Spinner";
import { getCenter } from "utils/turf";
import { typography } from "styles/typography";
import { spaceLarge } from "styles/space";
import { colors } from "styles/colors";
import Button from "components/General/Button";
import { mapboxAccessToken } from "components/MapNative/constants";
import {
  portfolioDotClusterLayerId,
  portfolioDotLayerId,
  portfolioDotSourceId,
  portfolioDotSymbolLayerId,
} from "components/Mapbox/constants";
import { ProjectFeature } from "types/feature";
import { currentMapStyleIdAtom } from "state/map";
import { adminInOrganisationSelectorFamily } from "state/user";
import {
  mappedPortfolioItemsWithFilteredAtom,
  portfolioFullScreenAtom,
  portfolioMapPopupBoundaryRefAtom,
} from "state/portfolio";
import { dedup } from "utils/utils";
import { ModalFrame } from "components/General/Layout";
import { useGoToFeatures } from "hooks/map";
import eventEmitter from "utils/eventEmitter";
import RenderPortfolioParkDots from "components/Organisation/Portfolio/RenderPortfolioParkDots";
import ClickedParkPopup from "./ClickedParkPopup";
import {
  MapCover,
  MapPopupBoundary,
  MapWrapper,
  PortfolioWrapper,
} from "./style";
import PortfolioTopRow from "./PortfolioTopRow/PortfolioTopRow";
import { MappedPortfolioItem } from "services/portfolioAPIService";
import {
  ARTICLE_PORTFOLIO,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import usePortfolioService from "hooks/usePortfolioService";
import { organisationRightSideModal } from "../OrganisationRightSide/state";
import { TabHeader } from "../style";
import { useJotaiCallback } from "utils/jotai";
import { useAtomValue, useSetAtom } from "jotai";
import PortfolioAccessNeededModal from "./PortfolioAccessNeededModal/PortfolioAccessNeededModal";

const NoParksToShowModalFrame = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const isOrgAdmin = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  return (
    <ModalFrame
      title="No parks to show"
      icon={<HelpLink article={ARTICLE_PORTFOLIO} />}
      style={{
        width: "50rem",
        gap: spaceLarge,
        backgroundColor: colors.blue50,
      }}
    >
      {isOrgAdmin ? (
        <>
          <p
            style={{
              ...typography.contentAndButtons,
            }}
          >
            To add parks to the portfolio view, head over to a project, and
            select a park.
          </p>
          <p
            style={{
              ...typography.contentAndButtons,
            }}
          >
            You can either add it by right-clicking on the park, or use the
            Features list to add the parks to this view.
          </p>
          <Link
            to={`/organisation/${organisationId}/projects`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button buttonType="primary" text="Go to projects" />
          </Link>
        </>
      ) : (
        <>
          <p
            style={{
              ...typography.contentAndButtons,
            }}
          >
            When parks are added to the portfolio by an organisation
            administrator, they will appear here.
          </p>
        </>
      )}
    </ModalFrame>
  );
};

const startBounds = [
  [-6.15234375, 52.05249047600099],
  [17.75390625, 66.08936427047088],
] as [[number, number], [number, number]];

const maxBounds = [
  [-1000, -60],
  [1000, 81],
] as [[number, number], [number, number]];

mapboxgl.accessToken = mapboxAccessToken;
const defaultProjection: mapboxgl.Projection = {
  name: "mercator",
};

export type PortfolioFeature = ProjectFeature & {
  properties: {
    parkId: string;
    branchId: string;
  };
};

const PortfolioInner = ({
  organisationId,
  map,
}: {
  organisationId: string;
  map: mapboxgl.Map;
}) => {
  const goToFeatures = useGoToFeatures(map);
  const allPortfolioItems = useAtomValue(mappedPortfolioItemsWithFilteredAtom);
  const { refreshPortfolio } = usePortfolioService();
  const [showAccessNeededModal, setShowAccessNeededModal] = useState(false);
  const portfolioItemsFiltered = useMemo(
    () => allPortfolioItems.filter((f) => !f.isFiltered),
    [allPortfolioItems],
  );
  const [clickedFeature, setClickedFeature] = useState<
    PortfolioFeature | undefined
  >(undefined);

  const clickedPortfolioItem = useMemo<MappedPortfolioItem | undefined>(
    () =>
      clickedFeature
        ? portfolioItemsFiltered.find(
            (project) =>
              project.park.id === clickedFeature.properties.parkId &&
              project.branchId === clickedFeature.properties.branchId,
          )
        : undefined,
    [clickedFeature, portfolioItemsFiltered],
  );

  const visibleParkDots = useMemo<PortfolioFeature[]>(() => {
    return portfolioItemsFiltered
      .map((project) => {
        const center = getCenter(project.park);
        return {
          ...project.park,
          // Since multiple parks can have the same id (and different branchId), we need to make sure the id is unique
          id: `${project.park.id}-${project.branchId}`,
          properties: {
            ...project.park.properties,
            id: `${project.park.id}-${project.branchId}`,
            parkId: project.park.id,
            branchId: project.branchId,
          },
          geometry: {
            type: "Point" as "Point",
            coordinates: [center.lng, center.lat],
          },
        };
      })
      .flat();
  }, [portfolioItemsFiltered]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const canvas = map.getCanvas();
    const onMouseMove = (e: mapboxgl.MapMouseEvent) => {
      for (const project of allPortfolioItems) {
        e.target.setFeatureState(
          {
            id: `${project.park.id}-${project.branchId}`,
            source: portfolioDotSourceId,
          },
          {
            hover: false,
          },
        );
      }
      const clustersOnPoint = dedup(
        map.queryRenderedFeatures(e.point, {
          layers: [portfolioDotClusterLayerId],
        }),
        (cluster) => cluster.properties?.cluster_id,
      );

      const features = dedup(
        e.target.queryRenderedFeatures(e.point, {
          layers: [portfolioDotLayerId, portfolioDotSymbolLayerId],
        }),
        (feature) => feature.id,
      );

      if (features.length > 0) {
        e.target.setFeatureState(features[0], {
          hover: true,
        });
      }

      const showMousePointer =
        clustersOnPoint.length > 0 || features.length > 0;
      if (showMousePointer) {
        canvas.style.cursor = "pointer";
      } else {
        canvas.style.cursor = "unset";
      }
    };
    map.on("mousemove", onMouseMove);

    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [allPortfolioItems, map]);

  useEffect(() => {
    goToFeatures(visibleParkDots, undefined, {
      padding: 100,
      maxZoom: 8,
    });
  }, [goToFeatures, visibleParkDots]);

  // Force refresh of portfolio when unmounting
  useEffect(() => {
    return () => {
      refreshPortfolio();
    };
  }, [refreshPortfolio]);

  return (
    <>
      {allPortfolioItems.length === 0 && (
        <MapCover>
          <NoParksToShowModalFrame />
        </MapCover>
      )}
      {clickedPortfolioItem && (
        <ClickedParkPopup
          portfolioItem={clickedPortfolioItem}
          organisationId={organisationId}
          map={map}
          setShowAccessNeededModal={setShowAccessNeededModal}
        />
      )}
      {showAccessNeededModal && clickedPortfolioItem && (
        <PortfolioAccessNeededModal
          onClose={() => {
            setShowAccessNeededModal(false);
          }}
          projectId={clickedPortfolioItem.project.id}
          branchId={clickedPortfolioItem.branchId}
          parkId={clickedPortfolioItem.park.id}
          projectType={clickedPortfolioItem.project.project_type}
        />
      )}
      <RenderPortfolioParkDots
        map={map}
        parks={visibleParkDots}
        onFeatureClick={setClickedFeature}
      />
    </>
  );
};

const Portfolio = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminInOrg) {
      navigate(`/organisation/${organisationId}/projects`);
    }
  }, [isAdminInOrg, navigate, organisationId]);

  return <PortfolioAsAdmin organisationId={organisationId} />;
};

const PortfolioAsAdmin = ({ organisationId }: { organisationId: string }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const isFullScreen = useAtomValue(portfolioFullScreenAtom);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map>();
  const portalRef = useRef<HTMLDivElement>(null);
  const setPortfolioMapWrapperRef = useSetAtom(
    portfolioMapPopupBoundaryRefAtom,
  );

  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  useEffect(() => {
    setContent(undefined);
  }, [setContent]);

  const getActiveMapStyleId = useJotaiCallback(
    (get) => get(currentMapStyleIdAtom),
    [],
  );

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: getActiveMapStyleId(),
      bounds: startBounds,
      maxBounds,
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      projection: defaultProjection,
      dragRotate: false,
      testMode: import.meta.env.VITE_CI ? true : undefined,
    });
    const scale = new mapboxgl.ScaleControl({
      unit: "metric",
    });
    map.addControl(scale, "top-right");
    map.on("load", (e) => {
      setMapLoaded(e.target);
    });

    return () => {
      map.remove();
      setMapLoaded(undefined);
    };
  }, [getActiveMapStyleId]);

  useEffect(() => {
    if (!mapLoaded) {
      return;
    }

    let isMounted = true;

    // Timeout needed to make sure the view is fully loaded
    const timeoutId = setTimeout(() => {
      if (isMounted && mapLoaded) {
        mapLoaded.resize();
      }
    }, 1000);

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [isFullScreen, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) {
      return;
    }

    let isMounted = true;

    const onResize = () => {
      if (isMounted && mapLoaded) {
        mapLoaded.resize();
      }
    };

    eventEmitter.on("organisation-left-menu-resize", onResize);
    return () => {
      isMounted = false;
      eventEmitter.off("organisation-left-menu-resize", onResize);
    };
  }, [mapLoaded]);

  useEffect(() => {
    if (portalRef.current) {
      setPortfolioMapWrapperRef(portalRef);
    }

    return () => {
      setPortfolioMapWrapperRef(undefined);
    };
  }, [setPortfolioMapWrapperRef]);

  return (
    <>
      <TabHeader>Portfolio</TabHeader>
      <PortfolioWrapper fullScreen={isFullScreen}>
        <PortfolioTopRow />

        <MapPopupBoundary ref={portalRef}>
          <MapWrapper ref={mapContainer} />
          {mapLoaded && (
            <Suspense
              fallback={
                <MapCover>
                  <Spinner size="5rem" />
                </MapCover>
              }
            >
              <PortfolioInner organisationId={organisationId} map={mapLoaded} />
            </Suspense>
          )}
        </MapPopupBoundary>
      </PortfolioWrapper>
    </>
  );
};

export default Portfolio;
