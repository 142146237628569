import { useCallback } from "react";
import { MenuItem } from "../../../General/Menu";
import { useAtomValue, useSetAtom } from "jotai";
import StagesIcon from "@icons/24/Stages.svg?react";
import CheckIcon from "@icons/24/Check.svg?react";
import { stagesFamily } from "state/jotai/stages";
import { stageBackgroundColorToTextColor } from "components/Organisation/OrgSettings/StagesSettings";
import { modalTypeOpenAtom } from "state/modal";
import { OrgSettingsModalType } from "components/Organisation/OrgSettings/OrgSettingsModal";
import SettingsIcon from "@icons/24/Settings.svg?react";
import { activeOrgSettingsTabAtom } from "components/Organisation/OrgSettings/ActiveTabState";

interface SelectStagesProps {
  organisationId: string;
  selectedStage: string | null;
  onStagesChange: (stage: string | null) => void;
  isAdminInOrg: boolean;
}

const SelectStages = ({
  organisationId,
  selectedStage,
  onStagesChange,
  isAdminInOrg,
}: SelectStagesProps) => {
  const availableStages = useAtomValue(stagesFamily({ organisationId }));
  const setModalOpen = useSetAtom(modalTypeOpenAtom);
  const setActiveTab = useSetAtom(activeOrgSettingsTabAtom);

  const handleStageClick = useCallback(
    (stageId: string) => {
      if (selectedStage === stageId) {
        onStagesChange(null);
      } else {
        onStagesChange(stageId);
      }
    },
    [selectedStage, onStagesChange],
  );

  const disabledAndNoStages = !isAdminInOrg && availableStages.length === 0;

  return (
    <MenuItem
      name="Stages"
      icon={<StagesIcon />}
      disabled={disabledAndNoStages}
      tooltip={
        disabledAndNoStages
          ? "No stages yet, contact organisation admin to add stages"
          : undefined
      }
      tooltipStyle={disabledAndNoStages ? { width: "100%" } : {}}
      style={disabledAndNoStages ? { width: "100%" } : {}}
    >
      {availableStages.map((stage) => (
        <MenuItem
          key={stage.id}
          name={stage.name}
          icon={selectedStage === stage.id ? <CheckIcon /> : undefined}
          stopPropagationOnClick={true}
          onClick={() => handleStageClick(stage.id)}
          textStyle={{
            color: stageBackgroundColorToTextColor(stage.color),
            backgroundColor: stage.color,
            padding: "0.2rem 0.8rem",
            borderRadius: "0.4rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />
      ))}
      <MenuItem
        name={
          availableStages.length === 0
            ? "No stages yet, create new"
            : "Edit stages"
        }
        tooltip={
          !isAdminInOrg
            ? "Contact organisation admin to edit stages"
            : undefined
        }
        disabled={!isAdminInOrg}
        icon={<SettingsIcon />}
        onClick={() => {
          setModalOpen({ modalType: OrgSettingsModalType });
          setActiveTab("stages");
        }}
        style={!isAdminInOrg ? { width: "100%" } : {}}
        tooltipStyle={!isAdminInOrg ? { width: "100%" } : {}}
      />
    </MenuItem>
  );
};

export default SelectStages;
