import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { LayerSettings } from "./types";
import {
  delLayerSetting,
  delLayerSettingDebounced,
  putLayerSetting,
  putLayerSettingDebounced,
} from "./service";
import { allLayerSettingsAtomFamily } from "./state";
import { Mixpanel } from "../../../mixpanel";

export const useLayerSettingsCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";

  const put = useJotaiCallback(
    async (get, set, settings: LayerSettings[], debounce = false) => {
      if (!projectId) return;
      aset(
        get,
        set,
        allLayerSettingsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter(
            (l) => !settings.map((s) => s.id).includes(l.id),
          );
          return [...rest, ...settings];
        },
      );
      let res;
      if (debounce) {
        res = await putLayerSettingDebounced(projectId, settings);
      } else {
        res = await putLayerSetting(projectId, settings);
      }
      Mixpanel.track_old("Update layer settings", {
        projectId,
        ...settings,
      });
      return res;
    },
    [projectId],
  );
  const putLocal = useJotaiCallback(
    async (get, set, projectId: string, settings: LayerSettings[]) => {
      aset(
        get,
        set,
        allLayerSettingsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter(
            (l) => !settings.map((s) => s.id).includes(l.id),
          );
          return [...rest, ...settings];
        },
      );
    },
    [],
  );

  const del = useJotaiCallback(
    async (get, set, layerIds: string[], debounce = false) => {
      if (!projectId) return;
      aset(
        get,
        set,
        allLayerSettingsAtomFamily({
          projectId,
        }),
        (cur) => {
          const rest = cur.filter((l) => !layerIds.includes(l.id));
          return rest;
        },
      );
      let res;
      if (debounce) {
        res = await delLayerSettingDebounced(projectId, layerIds);
      } else {
        res = await delLayerSetting(projectId, layerIds);
      }
      Mixpanel.track_old("Update layer settings", {
        projectId,
        layerIds,
      });
      return res;
    },
    [projectId],
  );
  const delLocal = useJotaiCallback(
    async (get, set, projectId: string, layerIds: string[]) => {
      aset(
        get,
        set,
        allLayerSettingsAtomFamily({
          projectId,
        }),
        (cur) => cur.filter((l) => !layerIds.includes(l.id)),
      );
    },
    [],
  );

  return {
    put,
    putLocal,
    del,
    delLocal,
  };
};
