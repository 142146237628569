import { useAtom } from "jotai";
import {
  BathymetryStyle,
  bathymetryStyles,
  otherMap,
  OtherMap,
  mapStylesAtom,
  MapStyle,
  MapType,
} from "@constants/availableMapStyles";
import { replaceOrUndefined } from "components/ControlPanels/utils";
import Tooltip from "components/General/Tooltip";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import {
  activeBathymetryStyleIdAtom,
  activeBathymetryStyleSelector,
  currentMapStyleIdAtom,
  activeOtherMapIdAtom,
  designToolTypeAtom,
  currentMapStyleAtom,
  getActiveOtherMapSelector,
  mapTypeAtom,
} from "state/map";
import styled from "styled-components";
import { colors } from "styles/colors";
import { borderRadiusMedium, spaceTiny } from "styles/space";
import { IconREMSize, Text, typography } from "styles/typography";
import { Comp } from "types/utils";
import OpenWindowRight from "icons/24/OpenWindowRight.svg?react";
import { useAtomValue, useSetAtom } from "jotai";
import { DesignToolMode } from "types/map";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

const DisabledWrapper = styled.button`
  ${typography.caption};
  display: flex;
  flex-direction: column;
  gap: ${spaceTiny};
  background: initial;
  align-items: center;

  opacity: 0.5;

  p {
    margin: 0;
    max-width: 4.8rem;
  }

  img {
    border-radius: ${borderRadiusMedium};
    width: 4.8rem;
    height: 4.8rem;
    margin: 2px;
  }
`;

const ImgButtonWrapper = styled.button<{
  active: boolean;
}>`
  ${typography.caption};
  color: ${colors.textPrimary};
  display: flex;
  flex-direction: column;
  gap: ${spaceTiny};
  background: initial;
  cursor: pointer;
  align-items: center;
  width: 11rem;

  ${(p) => p.disabled && `opacity: 0.5;`}

  &:hover {
    p {
      color: ${colors.textHover};
    }
    img {
      border: 2px solid ${colors.grey400};
      margin: 0;
    }
  }

  ${(p) => p.active && typography.sub2}
  ${(p) => p.active && `color: ${colors.textSelected} !important;`}


  img {
    border-radius: ${borderRadiusMedium};
    width: 4.8rem;
    height: 4.8rem;
    ${(p) =>
      p.active && `border: 2px solid ${colors.borderSelected} !important;`}
    margin: ${(p) => (p.active ? "0px" : "2px")};
  }
`;

const ImgButton = ({
  style,
  active,
  disabled,
  ...props
}: Comp<
  "button",
  {
    style: BathymetryStyle | MapStyle | OtherMap;
    active: boolean;
    disabled: boolean;
  }
>) => {
  const text =
    style.id === "lcoe-map" ? (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {"LCoE"}
          <IconREMSize height={1} width={1}>
            <OpenWindowRight
              style={{
                marginLeft: "0.5rem",
                paddingBottom: "0.4rem",
              }}
            />
          </IconREMSize>
        </div>
      </>
    ) : "text" in style ? (
      style.text
    ) : (
      style.name
    );

  if (disabled)
    return (
      <DisabledWrapper {...props}>
        <img src={style.image} alt={`image ${text}`} />
        {text}
      </DisabledWrapper>
    );

  return (
    <ImgButtonWrapper active={active} {...props}>
      <img src={style.image} alt={`image ${text}`} />
      <Tooltip
        text={
          "tooltip" in style && style.tooltip !== undefined ? style.tooltip : ""
        }
        disabled={!("tooltip" in style)}
      >
        {text}
      </Tooltip>
    </ImgButtonWrapper>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
`;

const GrayLine = styled.div`
  height: 1px;
  background-color: ${colors.grey200};
  flex-grow: 2;
`;

const SubHeader = styled.p`
  ${typography.sub1}
  margin: 0.8rem 0 0.4rem;
  flex-grow: 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.4rem;
`;

const MapStyleFrameOnshore = () => {
  const setMapStyle = useSetAtom(currentMapStyleIdAtom);
  const activeMapStyle = useAtomValue(currentMapStyleAtom);
  const mode = useAtomValue(designToolTypeAtom);

  const setOtherMap = useSetAtom(activeOtherMapIdAtom);
  const activeOtherMap = useAtomValue(getActiveOtherMapSelector);

  const [, setLowerRightActiveMode] = useAtom(lowerRightMenuActiveModeAtom);

  const mapStyles = useAtomValue(mapStylesAtom);
  const [mapType, setMapType] = useAtom(mapTypeAtom);

  return (
    <MenuFrame
      title="Map"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{
        width: "fit-content",
      }}
    >
      <Row>
        <SubHeader>Map style</SubHeader>
        <GrayLine />
      </Row>
      <Grid>
        {mapStyles.map((style) => (
          <ImgButton
            disabled={false}
            style={style}
            key={style.id}
            active={activeMapStyle?.id === style.id}
            onClick={() => {
              setMapStyle(style.id);
              if (style.id !== activeMapStyle?.id) document.location.reload();
            }}
          />
        ))}
      </Grid>
      <Row>
        <SubHeader>Overlay maps</SubHeader>
        <GrayLine />
      </Row>
      <Grid>
        {otherMap
          .filter((m) => m.modes.includes(mode))
          .map((style) => (
            <ImgButton
              key={style.id}
              disabled={false}
              style={style}
              active={activeOtherMap?.id === style.id}
              onClick={() => {
                if (activeOtherMap?.id === style.id) {
                  setOtherMap(undefined);
                } else {
                  setOtherMap(style.id);
                  if (style.id === "lcoe-map") {
                    setLowerRightActiveMode(replaceOrUndefined("cost"));
                  }
                }
              }}
            />
          ))}
      </Grid>
      <GrayLine style={{ margin: "1rem 0" }} />
      <Row>
        <Toggle
          size={ToggleSize.SMALL}
          title="Globe view"
          checked={mapType === MapType.GLOBE}
          onChange={() =>
            setMapType(mapType === MapType.GLOBE ? MapType.FLAT : MapType.GLOBE)
          }
        />
        <Text>Globe view</Text>
        <HelpTooltip text="Visualise the world as a globe" />
      </Row>
    </MenuFrame>
  );
};

const MapStyleFrameOffshore = () => {
  const setMapStyle = useSetAtom(currentMapStyleIdAtom);
  const activeMapStyle = useAtomValue(currentMapStyleAtom);
  const mode = useAtomValue(designToolTypeAtom);

  const activeBathymetryStyle = useAtomValue(activeBathymetryStyleSelector);
  const setBathymetryStyleId = useSetAtom(activeBathymetryStyleIdAtom);

  const setOtherMap = useSetAtom(activeOtherMapIdAtom);
  const activeOtherMap = useAtomValue(getActiveOtherMapSelector);

  const [, setLowerRightActiveMode] = useAtom(lowerRightMenuActiveModeAtom);

  const mapStyles = useAtomValue(mapStylesAtom);
  const [mapType, setMapType] = useAtom(mapTypeAtom);

  return (
    <MenuFrame
      title="Map"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{
        width: "fit-content",
      }}
    >
      <Row>
        <SubHeader>Map style</SubHeader>
        <GrayLine />
      </Row>
      <Grid>
        {mapStyles.map((style) => (
          <ImgButton
            disabled={false}
            style={style}
            key={style.id}
            active={activeMapStyle?.id === style.id}
            onClick={() => {
              setMapStyle(style.id);
              if (style.id !== activeMapStyle?.id) document.location.reload();
            }}
          />
        ))}
      </Grid>
      <Row>
        <SubHeader>Bathymetry style</SubHeader>
        <GrayLine />
      </Row>
      <Grid>
        {bathymetryStyles.map((style) => (
          <Tooltip
            key={style.id}
            text={`Not available with Satellite`}
            disabled={activeMapStyle?.useBathymetry}
            outerDivStyle={{
              justifyContent: "center",
            }}
          >
            <ImgButton
              disabled={!activeMapStyle?.useBathymetry}
              style={style}
              active={activeBathymetryStyle?.id === style.id}
              onClick={() => {
                if (activeBathymetryStyle?.id === style.id) {
                  setBathymetryStyleId("none");
                } else {
                  setBathymetryStyleId(style.id);
                }
              }}
            />
          </Tooltip>
        ))}
      </Grid>
      <Row>
        <SubHeader>Overlay maps</SubHeader>
        <GrayLine />
      </Row>
      <Grid>
        {otherMap
          .filter((m) => m.modes.includes(mode))
          .map((style) => (
            <Tooltip
              key={style.id}
              text={`Not available with Satellite`}
              disabled={activeMapStyle?.useBathymetry}
              outerDivStyle={{
                justifyContent: "center",
              }}
            >
              <ImgButton
                disabled={!activeMapStyle?.useBathymetry}
                style={style}
                active={activeOtherMap?.id === style.id}
                onClick={() => {
                  if (activeOtherMap?.id === style.id) {
                    setOtherMap(undefined);
                  } else {
                    setOtherMap(style.id);
                    if (style.id === "lcoe-map") {
                      setLowerRightActiveMode(replaceOrUndefined("cost"));
                    }
                  }
                }}
              />
            </Tooltip>
          ))}
      </Grid>
      <GrayLine style={{ margin: "1rem 0" }} />
      <Row>
        <Toggle
          size={ToggleSize.SMALL}
          title="Globe view"
          checked={mapType === MapType.GLOBE}
          onChange={() =>
            setMapType(mapType === MapType.GLOBE ? MapType.FLAT : MapType.GLOBE)
          }
        />
        <Text>Globe view</Text>
        <HelpTooltip text="Visualise the world as a globe" />
      </Row>
    </MenuFrame>
  );
};

export const MapStyleFrame = () => {
  const projectType = useAtomValue(designToolTypeAtom);

  return projectType === DesignToolMode.Onshore ? (
    <MapStyleFrameOnshore />
  ) : (
    <MapStyleFrameOffshore />
  );
};
