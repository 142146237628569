import { useCallback, useEffect, useState } from "react";
import { spaceLarge, spaceLarge_2, spaceTiny } from "styles/space";
import { colors } from "styles/colors";
import AddIcon from "@icons/24/Add.svg?react";
import {
  CreatedOrganisationInvitation,
  useAddMultipleToOrganisation,
  UserAlreadyExistedInvitation,
  useUpdateInvitation,
} from "hooks/useUser";
import { Organisation } from "components/Organisation/service";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import useMemberInGroupCrud from "components/Organisation/Groups/useMemberInGroupCrud";
import { useToast } from "hooks/useToast";
import useBooleanState from "hooks/useBooleanState";
import { InvitationNodeAccess } from "../shared";
import { Group } from "components/Organisation/Groups/types";
import { scream } from "utils/sentry";
import { Row } from "components/General/Layout";
import { typography } from "styles/typography";
import GroupPickerFrame from "../GroupPickerFrame";
import ProjectPickerFrame from "../ProjectPickerFrame";
import Button from "components/General/Button";
import Tooltip from "components/General/Tooltip";
import Spinner from "@icons/spinner/Spinner";
import { useRefreshInvitations } from "state/customer";
import { ProjectList } from "./ProjectList";
import { GroupList } from "./GroupList";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const AfterInviteResult = ({
  isOrgAdmin,
  inviteResult,
  organisation,
  defaultSelectProjectIds,
  onClose,
}: {
  isOrgAdmin: boolean;
  inviteResult: Awaited<
    ReturnType<ReturnType<typeof useAddMultipleToOrganisation>>
  >;
  defaultSelectProjectIds?: string[];
  organisation: Organisation;
  onClose(): void;
}) => {
  const updateInvitation = useUpdateInvitation(organisation.id);
  const refreshInvitations = useRefreshInvitations();
  const { showConfirm } = useConfirm();
  const { addOrUpdate } = useUserNodeAccessCrud();
  const { add: addToGroup } = useMemberInGroupCrud();
  const { success: showSuccess } = useToast();
  const [projectPickerOpen, toggleProjectPickerOpen] = useBooleanState(false);
  const [groupPickerOpen, toggleGroupPickerOpen] = useBooleanState(false);
  const [updatingInvites, setUpdatingInvites] = useState(false);
  const invitedEmails = inviteResult.map((invite) => invite.user_email);
  const [nodesAccess, setNodesAccess] = useState<InvitationNodeAccess[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const confirmButtonDisabled =
    nodesAccess.some((n) => !n.role) ||
    (selectedGroups.length === 0 && nodesAccess.length === 0);

  useEffect(() => {
    if (defaultSelectProjectIds && defaultSelectProjectIds.length > 0) {
      const defaultNodesAccess = defaultSelectProjectIds.map((nodeId) => ({
        nodeId,
      }));
      setNodesAccess(defaultNodesAccess);
    }
  }, [defaultSelectProjectIds]);

  const continueWithoutAccess = useCallback(async () => {
    if (
      await showConfirm({
        title: "Continue without access",
        message: "The user(s) will not have access to any projects.",
      })
    ) {
      refreshInvitations();
      onClose();
    }
  }, [onClose, refreshInvitations, showConfirm]);

  const resetInvitationsAndClose = useCallback(() => {
    refreshInvitations();
    onClose();
  }, [onClose, refreshInvitations]);

  const onConfirmClick = useCallback(async () => {
    const createdInvites = inviteResult.filter(
      (result) => result.status === "INVITATION_CREATED",
    ) as CreatedOrganisationInvitation[];

    const usersExisted = inviteResult.filter(
      (result) => result.status === "USER_ALREADY_EXISTED",
    ) as UserAlreadyExistedInvitation[];

    const groupIds = selectedGroups.map((g) => g.id);

    setUpdatingInvites(true);
    try {
      const invitationPromises = createdInvites.map((invitation) => {
        return updateInvitation(
          invitation.invitationId,
          nodesAccess as any,
          groupIds,
        );
      });

      const existingUserNodeAccessPromises = usersExisted.flatMap((user) => {
        return nodesAccess.map(async (node) => {
          return addOrUpdate(user.user_id, node.nodeId, node.role!);
        });
      });

      const existingUserGroupAccessPromises = usersExisted.flatMap((user) => {
        return groupIds.map(async (groupId) => {
          return addToGroup(groupId, user.user_id);
        });
      });

      await Promise.all([
        ...invitationPromises,
        ...existingUserNodeAccessPromises,
        ...existingUserGroupAccessPromises,
      ]);

      const nrInvitedUsers = createdInvites.length + usersExisted.length;

      if (nrInvitedUsers > 0) {
        showSuccess(
          `${createdInvites.length} invitation${createdInvites.length !== 1 ? "s" : ""} updated`,
          {
            timeout: 5000,
          },
        );
      }
      resetInvitationsAndClose();
    } catch (error) {
      const extra = {
        inviteResult,
        nodesAccess,
        selectedGroups,
      };
      if (error instanceof Error) {
        scream(error, extra);
      } else {
        scream("Something went wrong when inviting users", extra);
      }
    } finally {
      setUpdatingInvites(false);
    }
  }, [
    addOrUpdate,
    addToGroup,
    inviteResult,
    nodesAccess,
    resetInvitationsAndClose,
    selectedGroups,
    showSuccess,
    updateInvitation,
  ]);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: spaceTiny,
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              ...typography.body,
            }}
          >
            {`Grant ${invitedEmails
              .map((e, index, all) =>
                all.length > 1 && index === all.length - 1 ? `and ${e}` : e,
              )
              .join(
                ", ",
              )} access to projects and groups to start collaborating with the team.`}
          </span>
        </div>
      </Row>
      {isOrgAdmin && (
        <Row>
          {groupPickerOpen && (
            <div
              style={{
                position: "relative",
                overflow: "visible",
              }}
            >
              <GroupPickerFrame
                selectedGroups={selectedGroups}
                onClose={toggleGroupPickerOpen}
                organisationId={organisation.id}
                setSelectedGroups={setSelectedGroups}
              />
            </div>
          )}
        </Row>
      )}
      <Row>
        {projectPickerOpen && (
          <div
            style={{
              position: "relative",
              overflow: "visible",
            }}
          >
            <ProjectPickerFrame
              organisationId={organisation.id}
              isOrgAdmin={isOrgAdmin}
              setNodesAccess={setNodesAccess}
              onClose={toggleProjectPickerOpen}
              selectedNodeIds={nodesAccess.map((n) => n.nodeId)}
            />
          </div>
        )}
      </Row>
      <Row>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: colors.inputOutline,
            margin: `${spaceLarge_2} 0`,
          }}
        />
      </Row>
      {nodesAccess.length === 0 && (
        <Row
          style={{
            marginBottom: spaceLarge,
          }}
        >
          <h5
            style={{
              margin: 0,
            }}
          >
            Add to projects
          </h5>
        </Row>
      )}

      {nodesAccess.length > 0 && (
        <Row
          style={{
            flexDirection: "column",
            marginBottom: spaceLarge,
          }}
        >
          <h5
            style={{
              margin: 0,
            }}
          >
            Selected projects
          </h5>
          <ProjectList
            organisationId={organisation.id}
            nodesAccess={nodesAccess}
            onChangeRole={(nodeId, role) => {
              setNodesAccess((curr) =>
                curr.map((n) => (n.nodeId === nodeId ? { ...n, role } : n)),
              );
            }}
            onRemove={(nodeId) => {
              setNodesAccess((curr) => curr.filter((n) => n.nodeId !== nodeId));
            }}
          />
        </Row>
      )}

      <Row
        style={{
          marginBottom: spaceLarge,
        }}
      >
        <Button
          buttonType={
            nodesAccess.length === 0 && selectedGroups.length === 0
              ? "primary"
              : "secondary"
          }
          text="Add to projects"
          disabled={updatingInvites}
          icon={<AddIcon />}
          style={{
            flexGrow: 1,
          }}
          onClick={toggleProjectPickerOpen}
        />
      </Row>
      {isOrgAdmin && (
        <>
          {selectedGroups.length === 0 && (
            <Row
              style={{
                marginBottom: spaceLarge,
                marginTop: spaceLarge,
              }}
            >
              <h5
                style={{
                  margin: 0,
                }}
              >
                Add to groups
              </h5>
            </Row>
          )}
          {selectedGroups.length > 0 && (
            <Row
              style={{
                flexDirection: "column",
                marginBottom: spaceLarge,
              }}
            >
              <h5
                style={{
                  margin: 0,
                }}
              >
                Selected groups
              </h5>
              <GroupList
                groups={selectedGroups}
                onRemove={(groupId) => {
                  setSelectedGroups((curr) =>
                    curr.filter((g) => g.id !== groupId),
                  );
                }}
              />
            </Row>
          )}
          <Row
            style={{
              marginBottom: spaceLarge,
            }}
          >
            <Button
              buttonType={
                selectedGroups.length === 0 && nodesAccess.length === 0
                  ? "primary"
                  : "secondary"
              }
              disabled={updatingInvites}
              text="Add to group"
              icon={<AddIcon />}
              style={{
                flexGrow: 1,
              }}
              onClick={toggleGroupPickerOpen}
            />
          </Row>
        </>
      )}
      <Row
        style={{
          justifyContent: "flex-end",
        }}
      >
        {nodesAccess.length === 0 && selectedGroups.length === 0 && (
          <Button
            buttonType="text"
            text="Continue without access"
            onClick={continueWithoutAccess}
            disabled={updatingInvites}
          />
        )}
        <Tooltip
          text="Select access level above to confirm"
          disabled={!confirmButtonDisabled}
        >
          <Button
            buttonType="primary"
            text="Confirm"
            disabled={confirmButtonDisabled || updatingInvites}
            icon={updatingInvites ? <Spinner size="0.5rem" /> : undefined}
            style={{
              flexGrow: 1,
            }}
            onClick={onConfirmClick}
          />
        </Tooltip>
      </Row>
    </>
  );
};

export default AfterInviteResult;
