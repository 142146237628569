import { z } from "zod";
import { MILLION, THOUSAND } from "../constants/financialAnalysis";
import { CapexEntry, CostType } from "services/costService";

export enum ConfidenceLevel {
  notSpecified = "Not specified",
  uncertain = "Uncertain",
  verified = "Verified",
}

export type CostWithUnit = {
  cost: number;
  unit: CostUnit;
  confidenceLevel: ConfidenceLevel;
};

export enum CostUnit {
  millionEuroPerUnit = "m€/unit",
  millionEuroPerOSS = "m€/OSS",
  millionEuroPerONS = "m€/ONS",
  millionEuroPerMW = "m€/MW",
  millionEuroPerKM = "m€/km",
  millionEuroPerOffshoreKM = "m€/off.km",
  millionEuroPerOnshoreKM = "m€/ons.km",
  thousandEuroPerMW = "k€/MW",
  thousandEuroPerMWPerYear = "k€/MW/y",
  thousandEuroPerDay = "k€/day",
  euroPerKM = "€/km",
  millionEuroPerCable = "m€/cable",
  millionEuroPerExportCable = "m€/exp.cable",
  euroPerM3 = "€/m³",
  euroPerT = "€/t",
  euroPerMWh = "€/MWh",
  millionEuroPerLine = "m€/line",
  millionEuro = "m€",
  gigaWattHours = "GWh",
  percent = "%",
  years = "years",
}
export const _CostUnit = z.nativeEnum(CostUnit);

export type ComponentProcurementCostForPark = {
  // Types used when procurement cost is applied on a specific park given its location
  componentTypeId: string;
  supplyCost: CostWithUnit;
  shippingCost?: CostWithUnit;
};

export enum OccuranceType {
  Yearly = "yearly",
  Recurring = "recurring",
  SingleEvent = "singleEvent",
}
const _Occurance = z.nativeEnum(OccuranceType);

export type Amount = {
  id: string;
  amount: number;
  amountName?: string;
  category: CostType;
  unit: AmountUnit;
};

export enum AmountUnit {
  MW = "MW",
  unit = "unit",
  OSS = "OSS",
  ONS = "ONS",
  km = "km",
  offshorekm = "off.km",
  onshorekm = "ons.km",
  t = "t",
  line = "line",
  string = "string",
  cable = "cable",
  exportcable = "exp.cable",
  m3 = "m3",
  fixed = "fixed",
  day = "days",
}
export const _AmountUnit = z.nativeEnum(AmountUnit);

export const unitToAmountUnit = {
  [CostUnit.millionEuroPerUnit]: AmountUnit.unit,
  [CostUnit.millionEuroPerOSS]: AmountUnit.OSS,
  [CostUnit.millionEuroPerONS]: AmountUnit.ONS,
  [CostUnit.millionEuroPerMW]: AmountUnit.MW,
  [CostUnit.thousandEuroPerMW]: AmountUnit.MW,
  [CostUnit.thousandEuroPerDay]: AmountUnit.day,
  [CostUnit.millionEuroPerKM]: AmountUnit.km,
  [CostUnit.millionEuroPerOffshoreKM]: AmountUnit.offshorekm,
  [CostUnit.millionEuroPerOnshoreKM]: AmountUnit.onshorekm,
  [CostUnit.millionEuro]: AmountUnit.fixed,
  [CostUnit.euroPerKM]: AmountUnit.km,
  [CostUnit.millionEuroPerCable]: AmountUnit.cable,
  [CostUnit.euroPerM3]: AmountUnit.m3,
  [CostUnit.euroPerT]: AmountUnit.t,
  [CostUnit.millionEuroPerLine]: AmountUnit.line,
  [CostUnit.millionEuroPerExportCable]: AmountUnit.exportcable,
} as Record<CostUnit, AmountUnit>;

export const unitToScalingMap = {
  [CostUnit.millionEuroPerUnit]: MILLION,
  [CostUnit.millionEuroPerOSS]: MILLION,
  [CostUnit.millionEuroPerONS]: MILLION,
  [CostUnit.millionEuroPerMW]: MILLION,
  [CostUnit.thousandEuroPerMW]: THOUSAND,
  [CostUnit.thousandEuroPerDay]: THOUSAND,
  [CostUnit.thousandEuroPerMWPerYear]: THOUSAND,
  [CostUnit.euroPerMWh]: 1,
  [CostUnit.millionEuroPerKM]: MILLION,
  [CostUnit.millionEuroPerOffshoreKM]: MILLION,
  [CostUnit.millionEuroPerOnshoreKM]: MILLION,
  [CostUnit.millionEuro]: MILLION,
  [CostUnit.euroPerKM]: 1,
  [CostUnit.millionEuroPerCable]: MILLION,
  [CostUnit.millionEuroPerExportCable]: MILLION,
  [CostUnit.euroPerM3]: 1,
  [CostUnit.euroPerT]: 1,
  [CostUnit.millionEuroPerLine]: MILLION,
  [CostUnit.percent]: 1 / 100,
} as Record<CostUnit, number>;

export const amountUnitPrefixSuffix = {
  [AmountUnit.ONS]: { prefix: "#" },
  [AmountUnit.OSS]: { prefix: "#" },
  [AmountUnit.unit]: { prefix: "#" },
  [AmountUnit.cable]: { prefix: "#" },
  [AmountUnit.exportcable]: { prefix: "#" },
  [AmountUnit.string]: { prefix: "#" },
  [AmountUnit.line]: { prefix: "#" },
  [AmountUnit.fixed]: { prefix: "#" },
  [AmountUnit.km]: { suffix: "km" },
  [AmountUnit.offshorekm]: { suffix: "off.km" },
  [AmountUnit.onshorekm]: { suffix: "ons.km" },
  [AmountUnit.MW]: { suffix: "MW" },
  [AmountUnit.m3]: { suffix: "m³" },
  [AmountUnit.t]: { suffix: "t" },
  [AmountUnit.day]: { suffix: "days" },
} as Record<AmountUnit, { prefix?: string; suffix?: string }>;
export type Cost = CapexEntry & { amountId: string };

export type GeneralCost = {
  id: string;
  name: string;
  category: CostType;
  amount: number;
  amountUnit: AmountUnit;
  costPerAmount: number;
  cost: number;
  unit: CostUnit;
  confidenceLevel: ConfidenceLevel | undefined;
};
