import { E_STEEL } from "../constants/foundations";
import { rotorTowerSizing } from "./rotorTowerSizing";
import { SimpleTurbineType } from "types/turbines";

export const monopileNaturalFrequency = ({
  pileDiameter,
  avgPileThickness,
  embedLength,
  totalPileLength,
  waterDepth,
  soilCoeffSubReact,
  turbineType,
}: {
  pileDiameter: number;
  avgPileThickness: number;
  embedLength: number;
  totalPileLength: number;
  waterDepth: number;
  soilCoeffSubReact: number;
  turbineType: SimpleTurbineType;
}): number => {
  //Calculates the natural frequency for a monopile based on the research article "Design of monopiles for offshore wind turbines in 10 steps"
  const { hubHeight, diameter: rotorDiameter, rnaMass } = turbineType;

  const {
    towerBaseDiameter,
    towerTopDiameter,
    towerBaseThickness,
    towerTopThickness,
    towerMass,
  } = rotorTowerSizing(rotorDiameter);

  // estimated tower length, assuming 5 m vertical distance from tower top to hub height for 15 MW turbine (diameter 240 m), which is linearly scaled with the actual rotor diameter
  const towerLength =
    hubHeight +
    waterDepth +
    embedLength -
    totalPileLength -
    (rotorDiameter / 240) * 5.0;

  // estimated inertia for the tower, and natural frequency for a case where the tower base is fixed
  const I_tower =
    (((1 / 8) * (towerBaseThickness + towerBaseThickness)) / 2) *
    Math.PI *
    (Math.pow(towerBaseDiameter, 3) + Math.pow(towerTopDiameter, 3));
  const I_tower_top =
    (1 / 8) * towerTopThickness * Math.PI * Math.pow(towerTopDiameter, 3);
  const natFreqFixedBase =
    (1 / (2 * Math.PI)) *
    Math.sqrt(
      (3 * E_STEEL * I_tower) /
        (Math.pow(towerLength, 3) * (rnaMass + (33 / 140) * towerMass)),
    );

  const I_pile =
    (Math.PI / 64) *
    (Math.pow(pileDiameter, 4) -
      Math.pow(pileDiameter - 2 * avgPileThickness, 4));

  const K_l = (1 / 2) * Math.pow(embedLength, 2) * soilCoeffSubReact;
  const K_lr = (-1 / 3) * Math.pow(embedLength, 3) * soilCoeffSubReact;
  const K_r = (1 / 4) * Math.pow(embedLength, 4) * soilCoeffSubReact;

  const xi = (E_STEEL * I_tower) / (E_STEEL * I_pile);
  const psi = (totalPileLength - embedLength) / towerLength;

  const C_s = Math.sqrt(1 / (1 + Math.pow(1 + psi, 3) * xi - xi));

  const q = towerBaseDiameter / towerTopDiameter;
  const f_q =
    ((1 / 3) * (2 * Math.pow(q, 2) * Math.pow(q - 1, 3))) /
    (2 * Math.pow(q, 2) * Math.log(q) - 3 * Math.pow(q, 2) + 4 * q - 1);
  const I_eta = I_tower_top * f_q;

  const eta_l = (K_l * Math.pow(towerLength, 3)) / (E_STEEL * I_eta);
  const eta_lr = (K_lr * Math.pow(towerLength, 2)) / (E_STEEL * I_eta);
  const eta_r = (K_r * towerLength) / (E_STEEL * I_eta);

  const C_l = 1 - 1 / (1 + 0.5 * (eta_l - Math.pow(eta_lr, 2) / eta_r));
  const C_r = 1 - 1 / (1 + 0.6 * (eta_r - Math.pow(eta_lr, 2) / eta_l));

  const natFreq = C_l * C_r * C_s * natFreqFixedBase;

  return natFreq;
};
