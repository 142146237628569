import { Subtitle } from "components/General/GeneralSideModals.style";
import { InputDimensioned, TextArea } from "components/General/Input";
import { Row } from "components/General/Layout";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/InputChangelog/style";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import {
  ColumnSection,
  TitleContainer,
  HorizontalLine,
} from "components/SettingsV2/Shared/styles";
import {
  FoundationMinDepths,
  FoundationMaxDepths,
  FoundationType,
  JacketType,
  MonopileType,
} from "types/foundations";
import { isSimpleJacket, isSimpleMonopile } from "utils/predicates";
import { kWtoMW } from "../FoundationSettings";
import Mexp from "math-expression-evaluator";
import { scalingEquationTokens } from "@constants/foundations";

function validEquation(equation: string) {
  var mexp = new Mexp();
  const tokenValues = {
    p: 15,
    w: 30,
    d: 220,
  }; //check equation for 15MW and 30 m water depth, assume it's then ok for all other p, w and d
  try {
    mexp.eval(equation, scalingEquationTokens, tokenValues);
    return true;
  } catch (error) {
    return false;
  }
}

interface DesignParametersProps {
  foundation: FoundationType;
  isDisabled: boolean;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  setIsValid: (isValid: boolean) => void;
  tempFoundation: JacketType | MonopileType;
  nodeId: string;
  commentCategory: "org_foundation_manage" | "project";
}

const DesignParameters = ({
  foundation,
  isDisabled,
  setTempFoundation,
  setIsValid,
  tempFoundation,
  nodeId,
  commentCategory,
}: DesignParametersProps) => {
  return (
    <ColumnSection>
      <TitleContainer>
        <Subtitle>Design parameters</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto",
          gap: "3rem",
        }}
      >
        <Label>
          <SecondaryText>Design rated power range</SecondaryText>
          <Row style={{ gap: "0.4rem" }}>
            <InputDimensioned
              style={{ width: "9.2rem" }}
              unit="MW"
              disabled={isDisabled}
              scaleFactor={kWtoMW}
              value={tempFoundation.minRatedPower}
              initialValue={
                isSimpleJacket(foundation) || isSimpleMonopile(foundation)
                  ? foundation.minRatedPower
                  : undefined
              }
              decimals={1}
              validate={(e) =>
                1 <= e && e <= 30 && e < tempFoundation.maxRatedPower * kWtoMW
              }
              validationMessage="Must be between 1 and 30 MW, and less than the maximum design rated power."
              onChange={(ratedPowerMW) => {
                setTempFoundation({
                  ...tempFoundation,
                  minRatedPower: ratedPowerMW,
                });
              }}
            />
            <p>-</p>
            <InputDimensioned
              style={{ width: "9.2rem" }}
              unit="MW"
              disabled={isDisabled}
              scaleFactor={kWtoMW}
              value={tempFoundation.maxRatedPower}
              initialValue={
                isSimpleJacket(foundation) || isSimpleMonopile(foundation)
                  ? foundation.maxRatedPower
                  : undefined
              }
              decimals={1}
              validate={(e) =>
                1 <= e && e <= 30 && e > tempFoundation.minRatedPower * kWtoMW
              }
              validationMessage="Must be between 1 and 30 MW, and greater than the minimum design rated power."
              onChange={(ratedPowerMW) => {
                setTempFoundation({
                  ...tempFoundation,
                  maxRatedPower: ratedPowerMW,
                });
              }}
            />
          </Row>
        </Label>
        <Label>
          <SecondaryText>Design water depth range</SecondaryText>
          <Row style={{ gap: "0.4rem" }}>
            <InputDimensioned
              style={{ width: "9.2rem" }}
              unit="m"
              disabled={isDisabled}
              value={tempFoundation.minWaterDepth}
              initialValue={
                isSimpleJacket(foundation) || isSimpleMonopile(foundation)
                  ? foundation.minWaterDepth
                  : undefined
              }
              decimals={1}
              validate={(e) =>
                FoundationMinDepths[tempFoundation.type] <= e &&
                e <= FoundationMaxDepths[tempFoundation.type] &&
                e < tempFoundation.maxWaterDepth
              }
              validationMessage={`Must be between ${FoundationMinDepths[tempFoundation.type]} and ${FoundationMaxDepths[tempFoundation.type]} m, and less than the maximum design water depth.`}
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  minWaterDepth: d,
                });
              }}
            />
            <p>-</p>
            <InputDimensioned
              style={{ width: "9.2rem" }}
              unit="m"
              disabled={isDisabled}
              value={tempFoundation.maxWaterDepth}
              initialValue={
                isSimpleJacket(foundation) || isSimpleMonopile(foundation)
                  ? foundation.maxWaterDepth
                  : undefined
              }
              decimals={1}
              validate={(e) =>
                FoundationMinDepths[tempFoundation.type] <= e &&
                e <= FoundationMaxDepths[tempFoundation.type] &&
                e > tempFoundation.minWaterDepth
              }
              validationMessage={`Must be between ${FoundationMinDepths[tempFoundation.type]} and ${FoundationMaxDepths[tempFoundation.type]} m, and greater than the minimum design water depth.`}
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  maxWaterDepth: d,
                });
              }}
            />
          </Row>
        </Label>
      </div>
      <Label style={{ paddingTop: "0.8rem" }}>
        <Row>
          <SecondaryText>Weight (tonnes)</SecondaryText>
          <HelpTooltip text="A constant number, or an equation describing how the weight of the foundation varies with rated power (p) in MW and/or water depth (w) in m. Other possible variables are: rotor diameter (d) in m." />
        </Row>
        <TextArea
          style={{
            resize: "vertical",
            width: "42.4rem",
          }}
          rows={3}
          disabled={isDisabled}
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "scalingEquation",
            commentCategory,
          )}
          value={tempFoundation.scalingEquation}
          onChange={(e) => {
            setTempFoundation((curr) => ({
              ...curr,
              scalingEquation: e.target.value,
            }));
            const isValidEquation = validEquation(e.target.value);
            setIsValid(isValidEquation);
          }}
        />
      </Label>
    </ColumnSection>
  );
};

export default DesignParameters;
