import { atom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { organisationIdAtom } from "state/pathParams";
import { allLayerSettingsAtomFamily } from "components/LayerList/LayerSettings/state";
import { DataLibraryLayer, DataLibraryPackage, ProjectUsages } from "./types";
import {
  dataLayersUsage,
  dataPackageUsage,
  getOrganisationCustomLayerResources,
} from "./service";
import {
  dataLayerAccessOnNodeState,
  dataPackageResourceWithAccessOnNodeStateRefreshAtom,
  organisationLibraryDataPackageResourceState,
} from "../state";
import { UploadedVectorDataLayer } from "../types";
import { ValOrUpdate } from "types/utils";

export const organisationLibraryPackageSelector = atomFamily(
  ({ packageId }: { packageId: string }) =>
    atom<Promise<DataLibraryPackage | undefined>>(async (get) => {
      const organisationId = get(organisationIdAtom);
      const packages = await get(
        organisationLibraryDataPackageResourceState({
          organisationId: organisationId ?? "",
        }),
      );
      return packages.find((p) => p.id === packageId);
    }),
);

export const organisationLibraryLayersAtomFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<DataLibraryLayer[]>>(async () => {
      return await getOrganisationCustomLayerResources(organisationId);
    }),
);

const selectedOrganisationLayersAtom = atom<string[]>([]);

// Selector for selected layers that still exist in the organisation
export const selectedOrganisationLayerIdsSelector = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atom<Promise<string[]>, [ValOrUpdate<string[]>], void>(
      async (get) => {
        const selectedLayers = get(selectedOrganisationLayersAtom);
        const existingLayers = await get(
          organisationLibraryLayersAtomFamily({
            organisationId,
          }),
        );
        return selectedLayers.filter((l) =>
          existingLayers.find((e) => e.id === l),
        );
      },
      (get, set, newValue: ValOrUpdate<string[]>) => {
        set(selectedOrganisationLayersAtom, newValue);
      },
    ),
);

export const organisationLibraryLayerSelector = atomFamily(
  ({ layerId }: { layerId: string }) =>
    atom<Promise<DataLibraryLayer>>(async (get) => {
      const organisationId = get(organisationIdAtom);
      const layers = await get(
        organisationLibraryLayersAtomFamily({
          organisationId: organisationId ?? "",
        }),
      );
      const layer = layers.find((l) => l.id === layerId);
      if (!layer) {
        throw new Error("Layer not found");
      }
      return layer;
    }),
);

export const organisationLibraryLayersSelector = atomFamily(
  ({ layerIds }: { layerIds: string[] }) =>
    atom<Promise<DataLibraryLayer[]>>(async (get) => {
      const organisationId = get(organisationIdAtom);
      const layers = await get(
        organisationLibraryLayersAtomFamily({
          organisationId: organisationId ?? "",
        }),
      );
      return layers.filter((l) => layerIds.includes(l.id));
    }),
);

export const libraryLayersAddedToProject = atomFamily(
  ({ projectId }: { projectId: string | undefined }) =>
    atom<Promise<UploadedVectorDataLayer[]>>(async (get) => {
      if (!projectId) return [];
      const libraryLayers = await get(
        dataLayerAccessOnNodeState({
          nodeId: projectId,
        }),
      );
      const allLayerSettingIds = (
        await get(
          allLayerSettingsAtomFamily({
            projectId,
          }),
        )
      ).map((ll) => ll.id);
      return libraryLayers.filter((ll) => allLayerSettingIds.includes(ll.id));
    }),
);

export const activePackageIdAtom = atom<string | undefined>(undefined);

export const scrollToOrganisationLayerIdAtom = atom<string | undefined>(
  undefined,
);

export const getDataLayersUsage = atomFamily(
  ({ layerIds, nodeId }: { layerIds: string[]; nodeId: string }) =>
    atom<Promise<ProjectUsages>>(async (get) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      return dataLayersUsage(nodeId, layerIds);
    }),
);

export const getDataPackageUsage = atomFamily(
  ({
    packageId,
    organisationId,
  }: {
    packageId: string;
    organisationId: string;
  }) =>
    atom<Promise<ProjectUsages>>(async (get) => {
      get(dataPackageResourceWithAccessOnNodeStateRefreshAtom);
      return dataPackageUsage(organisationId, packageId);
    }),
);

export const allDataLayerSearchInputAtom = atom<string>("");
