import Turbine from "@icons/24/Turbine.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import { Row } from "components/General/Layout";
const TurbineSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { activities } = localConfig.ti.turbines;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Load turbine on vessel</SubHeader>
        <SubHeader>Lift and attach tower</SubHeader>
        <SubHeader>Lift and attach nacelle</SubHeader>
        <SubHeader>Lift and attach blade</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.turbines.activities.loadTurbineOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadTurbineOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={100}
          onChange={(duration) =>
            updateTIVessels({
              turbines: {
                ...localConfig.ti.turbines,
                activities: {
                  ...activities,
                  loadTurbineOnVessel: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.turbines.activities.liftAndAttachTower`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndAttachTower}
          step={1}
          validate={(e) => 0 < e}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              turbines: {
                ...localConfig.ti.turbines,
                activities: {
                  ...activities,
                  liftAndAttachTower: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.turbines.activities.liftAndAttachNacelle`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndAttachNacelle}
          step={1}
          validate={(e) => 0 < e}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              turbines: {
                ...localConfig.ti.turbines,
                activities: {
                  ...activities,
                  liftAndAttachNacelle: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.turbines.activities.liftAndAttachBlade`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndAttachBlade}
          step={1}
          validate={(e) => 0 < e}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              turbines: {
                ...localConfig.ti.turbines,
                activities: {
                  ...activities,
                  liftAndAttachBlade: Math.round(duration),
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const TurbineActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  return (
    <InstallationCategoryEntry
      title="Turbines"
      icon={<Turbine />}
      defaultOpen={true}
    >
      <TurbineSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
