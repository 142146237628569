import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { useEffect, useState } from "react";
import { Mixpanel, TrackingCapability, TrackingFeature } from "mixpanel";

const useIsCameraIn3D = (): boolean => {
  const map = useAtomValue(mapAtom);
  const [isCameraIn3DView, setisCameraIn3DView] = useState(
    map ? map.getPitch() > 0 : false,
  );

  useEffect(() => {
    if (!map) return;
    const onCameraPitch = () => {
      const pitch = map.getPitch();
      setisCameraIn3DView(pitch > 0);
    };
    map.on("pitch", onCameraPitch);

    return () => {
      map.off("pitch", onCameraPitch);
    };
  }, [map]);

  useEffect(() => {
    Mixpanel.track(
      TrackingCapability.Navigation,
      TrackingFeature.ThreeDMode,
      "toggled",
      {
        enabled: isCameraIn3DView,
      },
    );
  }, [isCameraIn3DView]);

  return isCameraIn3DView;
};

export default useIsCameraIn3D;
