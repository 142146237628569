import React from "react";
import { organisationIdAtom } from "state/pathParams";
import { isDefined } from "utils/predicates";
import { ProjectNodeInformation } from "services/customerAPI";
import {
  _MappedPortfolioItem,
  _PortfolioItemBase,
  getPortfolio,
  getPortfolioSimpleItems,
  MappedPortfolioItem,
  PortfolioItemBase,
  PortfolioItemResponse,
} from "services/portfolioAPIService";
import { atomFromFn } from "utils/jotai";
import { MaybePromise } from "types/utils";
import { atom } from "jotai";
import { atomLocalStorage } from "utils/jotai";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";

export const portfolioItemsSimpleAtom = atomFromFn<
  MaybePromise<PortfolioItemBase[]>
>((get) => {
  get(portfolioRefreshAtom);
  const organisationId = get(organisationIdAtom);
  if (!organisationId) {
    return [];
  }
  return getPortfolioSimpleItems(organisationId);
});

const portfolioItemsStoreAtom = atomFromFn<
  MaybePromise<PortfolioItemResponse[]>
>((get) => {
  get(portfolioRefreshAtom);
  const organisationId = get(organisationIdAtom);
  if (!organisationId) {
    return [];
  }

  return getPortfolio(organisationId);
});

export const mappedPortfolioItemsStoreAtom = atomFromFn<
  MaybePromise<MappedPortfolioItem[]>
>(async (get) => {
  const organisationId = get(organisationIdAtom);
  if (!organisationId) {
    return [];
  }

  const response = await get(portfolioItemsStoreAtom);
  const projectNodes = await get(
    admin_nodesInOrgSelectorFamily({
      organisationId,
    }),
  );

  const result = response
    .map<MappedPortfolioItem | undefined>((item) => {
      const project = projectNodes.find((n) => n.id === item.projectId) as
        | ProjectNodeInformation
        | undefined;

      if (!project) {
        return undefined;
      }

      return {
        project,
        branch: item.branch,
        nrTurbines: item.items.turbines.count,
        totalPower: item.items.turbines.totalPower,
        branchId: item.branchId,
        park: item.items.park,
      };
    })
    .filter(isDefined);
  return result;
});

export const mappedPortfolioItemsWithFilteredAtom = atomFromFn<
  MaybePromise<MappedPortfolioItem[]>
>(async (get) => {
  const items = await get(mappedPortfolioItemsStoreAtom);
  const filteredParkItems = get(filteredPortfolioParkItemsAtom);

  const result = items.map((item) => {
    const isFiltered = filteredParkItems.some(
      (p) =>
        p.parkId === item.park.id &&
        p.branchId === item.branchId &&
        p.projectId === item.project.id,
    );

    return {
      isFiltered,
      ...item,
    };
  });

  return _MappedPortfolioItem.array().parse(result);
});

export const filteredPortfolioParkItemsAtom = atomLocalStorage<
  PortfolioItemBase[]
>("vind:filtered-portfolio-items", [], _PortfolioItemBase.array());

export const portfolioFilteredTotalCapacitySelector = atom<Promise<number>>(
  async (get) => {
    const portfolioItems = await get(mappedPortfolioItemsWithFilteredAtom);
    return portfolioItems
      .filter((item) => !item.isFiltered)
      .reduce((acc, item) => acc + item.totalPower, 0);
  },
);

export const portfolioRefreshAtom = atom(0);

export const portfolioFullScreenAtom = atom<boolean>(false);

export const portfolioMapPopupBoundaryRefAtom = atom<
  React.RefObject<HTMLDivElement> | undefined
>(undefined);
