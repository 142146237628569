import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Suspense, useMemo, useState } from "react";
import { Row } from "components/General/Layout";
import Tabs, { TabData } from "components/General/Tabs";
import TeamMeeting from "@icons/24/TeamMeeting.svg";
import { organisationGroupsState } from "components/Organisation/Groups/state";
import {
  ContentHeader,
  ContentHeaderColumn,
  ContentWrapper,
  Text,
} from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { adminInOrganisationSelectorFamily } from "state/user";
import { EditableText } from "components/General/EditableText";
import useGroupsCrud from "components/Organisation/Groups/useGroupsCrud";
import { GroupMembers } from "./tabs/GroupMembers";
import { GroupProjects } from "./tabs/GroupProjects";
import { OrganisationResourceGroupTable } from "./tabs/OrganisationGroupResourceTable";
import { ContentImageWrapper } from "components/Organisation/Image/ImageWrapper";
import UserInfoOrganisation from "components/UserInfo/UserInfo";
import { colors } from "styles/colors";

export default function GroupContent({ groupId }: { groupId: string }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { update } = useGroupsCrud();
  const group = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  ).find((g) => g.id === groupId);

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const createdByUser = group?.author.startsWith("auth0") ?? false;
  const [name, setName] = useState(group?.name ?? "");

  const tabs = useMemo<TabData[]>(() => {
    if (!group) return [];
    const ts = [
      {
        name: "Members",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <GroupMembers group={group} />
          </Suspense>
        ),
      },
    ];
    if (isAdminInOrg) {
      ts.push({
        name: "Project access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <GroupProjects groupId={group.id} />
          </Suspense>
        ),
      });
      ts.push({
        name: "Library access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <OrganisationResourceGroupTable groupId={group.id} />
          </Suspense>
        ),
      });
    }
    return ts;
  }, [group, isAdminInOrg]);

  if (!group)
    return (
      <ContentWrapper>
        <h4>Group not found</h4>
      </ContentWrapper>
    );

  return (
    <ContentWrapper>
      <Row
        style={{
          gap: "2.4rem",
          alignItems: "center",
        }}
      >
        <ContentImageWrapper
          color={colors.grey50}
          svgColor={colors.iconSelected}
        >
          <TeamMeeting />
        </ContentImageWrapper>
        <ContentHeaderColumn>
          <EditableText
            type="text"
            value={name}
            disabled={!isAdminInOrg}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onEnter={() => {
              update(groupId, name);
            }}
            onCancel={() => {
              setName(group.name ?? "");
            }}
            textContainerStyle={{
              maxWidth: "20vw",
              padding: 0,
            }}
            editIconTitle="Rename"
            renderText={(title) => <ContentHeader>{title}</ContentHeader>}
          />
          {createdByUser && (
            <Row
              style={{
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Text>Created by:</Text>
              <UserInfoOrganisation userId={group.author} />
            </Row>
          )}
        </ContentHeaderColumn>
      </Row>
      <Tabs
        menuStyle={{
          justifyContent: "space-between",
          whiteSpace: "nowrap",
        }}
        tabs={tabs}
        wrapperStyle={{
          overflowY: "hidden",
        }}
        contentWrapperStyle={{
          height: "100%",
        }}
      />
    </ContentWrapper>
  );
}
