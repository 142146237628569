import ListIcon from "@icons/24/List.svg";
import InformationIcon from "@icons/24/Information.svg";
import NoItemsGeneric from "components/General/NoItemsGeneric";
import { ContentWrapper } from "../style";

export const SelectAnItem = () => {
  return (
    <ContentWrapper>
      <NoItemsGeneric
        subText="Select an item to see the details"
        Icon={<ListIcon />}
      />
    </ContentWrapper>
  );
};

export const NoInfoToShow = () => {
  return (
    <ContentWrapper>
      <NoItemsGeneric subText="No info to show" Icon={<InformationIcon />} />
    </ContentWrapper>
  );
};
