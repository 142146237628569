import { useCallback } from "react";
import { useProjectElementsCrud } from "../components/ProjectElements/useProjectElementsCrud";
import { PARK_PROPERTY_TYPE } from "../constants/park";
import {
  ProjectFeature,
  stripFeatureTypeSpecificFields,
} from "../types/feature";
import { findFeatureChildrenIds } from "../state/projectLayers";
import { AddFeatureOptions } from "../types/map";
import { _FeatureParser } from "../types/feature";
import { useAtomValue } from "jotai";
import { featureMapFamily, featuresListAtom } from "state/jotai/features";
import { isDefined } from "utils/predicates";

export const useSetPropertyOnProjectFeatures = (callback?: () => void) => {
  const projectFeatures = useAtomValue(featuresListAtom);
  const featuresMap = useAtomValue(featureMapFamily({ branchId: undefined }));
  const { update: updateFeatures } = useProjectElementsCrud();

  return useCallback(
    (
      featureIds: string[],
      newProperties: AddFeatureOptions,
      newFeatureSpecificProperties?: Record<string, AddFeatureOptions>,
    ) => {
      const featuresToUpdate = featureIds
        .map((id) => featuresMap.get(id))
        .filter(isDefined);
      if (featuresToUpdate.length > 0) {
        const updateObject = featuresToUpdate.reduce<{
          remove: string[];
          update: ProjectFeature[];
        }>(
          (acc, feature) => {
            const specificProperties =
              newFeatureSpecificProperties &&
              feature.id in newFeatureSpecificProperties
                ? newFeatureSpecificProperties[feature.id]
                : {};
            const featureId = feature.id;
            let propertiesToKeepFromFeature = feature.properties;

            if (
              "type" in newProperties &&
              feature?.properties?.type !== newProperties.type
            ) {
              propertiesToKeepFromFeature =
                stripFeatureTypeSpecificFields(feature);
            }

            const changeFromTypeParkToSomethingElse =
              feature?.properties?.type === PARK_PROPERTY_TYPE &&
              "type" in newProperties &&
              newProperties.type !== PARK_PROPERTY_TYPE;

            const featureIdsToDelete: string[] =
              changeFromTypeParkToSomethingElse
                ? findFeatureChildrenIds(projectFeatures, featureId)
                : [];

            const updatedFeature = _FeatureParser.parse({
              ...feature,
              properties: {
                ...propertiesToKeepFromFeature,
                ...newProperties,
                ...specificProperties,
              },
            });

            // Remove undefined values from properties
            for (const [key, val] of Object.entries(newProperties)) {
              if (
                typeof val === "undefined" &&
                typeof (updatedFeature as any).properties[key] === "undefined"
              ) {
                delete (updatedFeature as any).properties[key];
              }
            }

            return {
              remove: [...acc.remove, ...featureIdsToDelete],
              update: [...acc.update, updatedFeature],
            };
          },
          { remove: [], update: [] },
        );

        updateFeatures(updateObject);
      }

      callback?.();
    },
    [featuresMap, projectFeatures, callback, updateFeatures],
  );
};
