import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useJotaiCallback } from "utils/jotai";
import {
  findTopLevelNode,
  getNodesWithMissingParents,
  useOrganisationNodeCrud,
} from "components/Projects/useOrganisationFolderCrud";
import { Node } from "services/customerAPI";
import useBooleanState from "hooks/useBooleanState";
import { useEffect, useMemo, useRef } from "react";
import FolderMenuOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import PersonalFolderOpenIcon from "@icons/20/PersonalFolderOpen.svg";
import PersonalFolderClosedIcon from "@icons/20/PersonalFolderClosed.svg";
import { useDrop, useDrag } from "react-dnd";
import { Link, useLocation } from "react-router-dom";
import useFolderId from "hooks/useFolderId";
import { Margin, ProjectTitle } from "./style";
import {
  folderTreeSelectorFamily,
  TreeItem,
  FolderTreeItem,
} from "../Projects/state";
import {
  adminInOrganisationSelectorFamily,
  memberInOrganisationSelectorFamily,
  userHaveEditorNodeAccess,
} from "../../state/user";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import styled from "styled-components";
import { colors } from "styles/colors";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Book.svg?react";
import BulbIcon from "@icons/24/Bulb.svg?react";
import IntegrationIcon from "@icons/24/Integration.svg?react";
import PortfolioIcon from "@icons/24/Portfolio.svg?react";
import Members from "@icons/24/Persons.svg?react";
import { IconREMSize, typography } from "styles/typography";
import { getNodeName } from "components/Organisation/utils";
import { WithTooltip } from "components/General/Tooltip";
import { getPathPrefix } from "utils/utils";
import SearchWrapper from "components/Search/SearchWrapper";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import { useUserAccessState } from "components/Projects/useUserAccessState";
import {
  ExpandArrowWrapper,
  ListItemTopRow,
  ListItemWrapper,
} from "components/FolderList/style";
import { Mixpanel } from "mixpanel";

const Divider = styled.div`
  margin: 1.6rem auto;
  border-bottom: 1px solid ${colors.hover};
  width: 90%;
`;

const hasChildThatIsSelected = (
  selectedId: string,
  parentId: string,
  nodes: Node[],
): boolean => {
  const nodesForParent = nodes.filter((n) => n.parent_id === parentId);

  if (nodesForParent.find((n) => n.id === selectedId)) return true;

  const sortedPersonalFolders = nodesForParent.filter((n) =>
    ["personal_folder", "folder"].includes(n.type),
  );

  return sortedPersonalFolders.some(
    (n) => !!hasChildThatIsSelected(selectedId, n.id, nodes),
  );
};

const ProjectElement = ({
  projectNode,
  level,
}: {
  projectNode: Node;
  level: number;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const location = useLocation();
  const elementRef = useRef<HTMLDivElement>(null);
  const { data: userAllNodesAccess } = useUserAccessState();
  const isCustomerEditor = useMemo(
    () => userHaveEditorNodeAccess(userAllNodesAccess, projectNode.id),
    [userAllNodesAccess, projectNode.id],
  );

  const [, dragRef] = useDrag(() => ({
    type: "PROJECT-list",
    canDrag: () => isCustomerEditor,
    item: () => ({
      id: projectNode.id,
      parentId: projectNode.parent_id,
    }),
  }));

  let searchParams = new URLSearchParams(location.search);
  searchParams.delete("page");
  let newSearchString = searchParams.toString();

  dragRef(elementRef);
  return (
    <ListItemWrapper enableDrag={isCustomerEditor} ref={elementRef}>
      <WithTooltip text={projectNode.name}>
        <Link
          className="unstyled-link"
          to={`/${getPathPrefix(projectNode)}/project/${organisationId}/${projectNode.id}?${newSearchString}`}
        >
          <ListItemTopRow
            alignCenter
            depth={level}
            hasIcon={false}
            hasExpandIcon={false}
            clickable={true}
          >
            <ProjectTitle className="clickable" selected={false} noHover={true}>
              {projectNode.name}
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </WithTooltip>
    </ListItemWrapper>
  );
};

const AllProjectsElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const linkRef = useRef<HTMLAnchorElement>(null);

  const location = useLocation();
  const isPageSelected =
    location.pathname.split("/").slice(-1)[0] === "projects";

  const updatedPath = `/organisation/${organisationId}/projects`;
  return (
    <SearchWrapper
      title="All projects"
      tags={["projects", "list", "project list"]}
      id="search-all-projects"
      icon={<ProjectIcon />}
      onSelect={() => {
        linkRef.current?.click();
      }}
    >
      <ListItemWrapper>
        <Link
          className="unstyled-link"
          to={updatedPath}
          ref={linkRef}
          onClick={() => {
            setContent((curr) =>
              typeof curr !== "undefined"
                ? {
                    type: "no-item-selected",
                  }
                : undefined,
            );
          }}
        >
          <ListItemTopRow
            alignCenter
            depth={0}
            hasIcon={true}
            hasExpandIcon={false}
            active={isPageSelected}
          >
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={
                isPageSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <ProjectIcon />
            </IconREMSize>
            <ProjectTitle noHover selected={isPageSelected}>
              All projects
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </ListItemWrapper>
    </SearchWrapper>
  );
};

const LibraryElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const linkRef = useRef<HTMLAnchorElement>(null);

  const location = useLocation();
  const isPageSelected =
    location.pathname.split("/").slice(-1)[0] === "library";

  const updatedPath = `/organisation/${organisationId}/library`;

  return (
    <SearchWrapper
      title="Library"
      tags={[
        "library",
        "list",
        "Shared components",
        "components",
        "turbines",
        "cables",
        "foundations",
        "inter array cables",
        "mooring lines",
        "substation",
        "export cables",
      ]}
      id="search-library"
      icon={<LibraryIcon />}
      onSelect={() => {
        linkRef.current?.click();
      }}
    >
      <ListItemWrapper>
        <Link
          className="unstyled-link"
          to={updatedPath}
          ref={linkRef}
          onClick={() => {
            setContent(undefined);
          }}
        >
          <ListItemTopRow
            alignCenter
            depth={0}
            hasIcon={true}
            hasExpandIcon={false}
            active={isPageSelected}
          >
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={
                isPageSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <LibraryIcon />
            </IconREMSize>
            <ProjectTitle noHover selected={isPageSelected}>
              Library
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </ListItemWrapper>
    </SearchWrapper>
  );
};

const MembersElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);

  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const linkRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const isPageSelected =
    location.pathname.split("/").slice(-1)[0] === "members";

  const updatedPath = `/organisation/${organisationId}/members`;

  return (
    <SearchWrapper
      title="Members"
      tags={[
        "members",
        "list",
        "member list",
        "users",
        "user list",
        "admins",
        "consultants",
        "guests",
        "groups",
        "pending invitations",
        "invite",
      ]}
      id="search-members"
      icon={<Members />}
      onSelect={() => {
        linkRef.current?.click();
      }}
    >
      <ListItemWrapper>
        <Link
          className="unstyled-link"
          to={updatedPath}
          ref={linkRef}
          onClick={() => {
            setContent((curr) =>
              typeof curr !== "undefined"
                ? {
                    type: "no-item-selected",
                  }
                : undefined,
            );
          }}
        >
          <ListItemTopRow
            alignCenter
            depth={0}
            hasIcon={true}
            hasExpandIcon={false}
            active={isPageSelected}
          >
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={
                isPageSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <Members />
            </IconREMSize>
            <ProjectTitle noHover selected={isPageSelected}>
              Members
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </ListItemWrapper>
    </SearchWrapper>
  );
};

const LearnElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const location = useLocation();
  const isPageSelected = location.pathname.split("/").slice(-1)[0] === "learn";
  const updatedPath = `/organisation/${organisationId}/learn`;

  return (
    <ListItemWrapper>
      <Link
        className="unstyled-link"
        to={updatedPath}
        onClick={() => {
          setContent(undefined);
        }}
      >
        <ListItemTopRow
          alignCenter
          depth={0}
          hasIcon={true}
          hasExpandIcon={false}
          active={isPageSelected}
        >
          <IconREMSize
            height={1.4}
            width={1.4}
            iconColor={
              isPageSelected ? colors.iconSelected : colors.iconDefault
            }
          >
            <BulbIcon />
          </IconREMSize>
          <ProjectTitle noHover selected={isPageSelected}>
            Learn
          </ProjectTitle>
        </ListItemTopRow>
      </Link>
    </ListItemWrapper>
  );
};
const PortfolioElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);

  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const linkRef = useRef<HTMLAnchorElement>(null);

  const location = useLocation();
  const isPageSelected =
    location.pathname.split("/").slice(-1)[0] === "portfolio";

  const updatedPath = `/organisation/${organisationId}/portfolio`;

  return (
    <SearchWrapper
      title="Portfolio"
      tags={[
        "portfolio",
        "list",
        "portfolio list",
        "overview",
        "projects",
        "compare projects",
      ]}
      id="search-portfolio"
      icon={<PortfolioIcon />}
      onSelect={() => {
        linkRef.current?.click();
      }}
    >
      <ListItemWrapper>
        <Link
          className="unstyled-link"
          to={updatedPath}
          ref={linkRef}
          onClick={() => {
            setContent(undefined);
            Mixpanel.track_old("portfolio_clicked", {});
          }}
        >
          <ListItemTopRow
            alignCenter
            depth={0}
            hasIcon={true}
            hasExpandIcon={false}
            active={isPageSelected}
          >
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={
                isPageSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <PortfolioIcon />
            </IconREMSize>
            <ProjectTitle noHover selected={isPageSelected}>
              Portfolio
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </ListItemWrapper>
    </SearchWrapper>
  );
};

const IntegrationElement = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const linkRef = useRef<HTMLAnchorElement>(null);

  const location = useLocation();
  const isPageSelected =
    location.pathname.split("/").slice(-1)[0] === "integration";

  const updatedPath = `/organisation/${organisationId}/integration`;

  return (
    <SearchWrapper
      title="Integration"
      tags={[
        "integration",
        "integration list",
        "API",
        "client secret",
        "upload data",
        "send data",
        "download",
        "token",
      ]}
      id="search-integration"
      icon={<IntegrationIcon />}
      onSelect={() => {
        linkRef.current?.click();
      }}
    >
      <ListItemWrapper>
        <Link
          className="unstyled-link"
          to={updatedPath}
          ref={linkRef}
          onClick={() => {
            setContent(undefined);
          }}
        >
          <ListItemTopRow
            alignCenter
            depth={0}
            hasIcon={true}
            hasExpandIcon={false}
            active={isPageSelected}
          >
            <IconREMSize
              height={1.4}
              width={1.4}
              iconColor={
                isPageSelected ? colors.iconSelected : colors.iconDefault
              }
            >
              <IntegrationIcon />
            </IconREMSize>
            <ProjectTitle noHover selected={isPageSelected}>
              Integration
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </ListItemWrapper>
    </SearchWrapper>
  );
};

const findChildInTrees = (
  nodes: (TreeItem | FolderTreeItem)[],
  id: string,
): boolean => {
  return nodes.some((n) => findChildInTree(n, id));
};

const findChildInTree = (
  node: TreeItem | FolderTreeItem,
  id: string,
): boolean => {
  if (node.id === id) {
    return true;
  }
  if (!("items" in node)) {
    return false;
  }

  return (node.items as TreeItem[]).some((n) => findChildInTree(n, id));
};

const FolderIsEmptyMessage = ({ depth }: { depth: number }) => {
  return (
    <ListItemWrapper>
      <ListItemTopRow
        alignCenter
        depth={depth}
        hasIcon={false}
        hasExpandIcon={false}
      >
        <p
          style={{
            ...typography.contentAndButtons,
            color: colors.textDisabled,
          }}
        >
          Folder is empty
        </p>
      </ListItemTopRow>
    </ListItemWrapper>
  );
};

const FolderWithChildren = ({
  folderNode,
  level,
}: {
  folderNode: Node;
  level: number;
}) => {
  const location = useLocation();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const elementRef = useRef<HTMLDivElement>(null);
  const { move: moveNode } = useOrganisationNodeCrud();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const folderId = useFolderId() ?? "";
  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);
  const defaultOpen =
    folderId === folderNode.id ||
    hasChildThatIsSelected(folderId, folderNode.id, nodes);
  const [open, toggleOpen, setOpen] = useBooleanState(defaultOpen);

  const getFolderTree = useJotaiCallback(
    (
      get,
      _set,
      {
        id,
      }: {
        id: string;
      },
    ) => {
      const folderTree = get(
        folderTreeSelectorFamily({
          organisationId,
          folderId: id,
        }),
      );
      return folderTree;
    },
    [organisationId],
  );

  const [dropCollection, dropRef] = useDrop<
    {
      id: string;
      parentId?: string | null;
    },
    {
      handled: boolean;
    },
    {
      isHovering: boolean;
    }
  >(
    () => ({
      accept: [
        "PROJECT-list",
        "PROJECT",
        "FOLDER-list",
        "FOLDER",
        "PROJECT|FOLDER",
      ],
      collect: (monitor) => {
        return {
          isHovering: monitor.isOver({
            shallow: true,
          }),
        };
      },
      canDrop: () => true,
      drop: (droppedItem, monitor) => {
        // Its been dropped on something lower in the tree
        if (monitor.getDropResult()?.handled) {
          return {
            handled: true,
          };
        }

        const type = monitor.getItemType();
        switch (type) {
          case "PROJECT":
          case "PROJECT-list":
            // Its been dropped on the folder it already belongs to
            if (droppedItem.parentId === folderNode.id) {
              return {
                handled: true,
              };
            }

            moveNode(droppedItem.id, folderNode.id);
            return {
              handled: true,
            };

          case "FOLDER":
          case "FOLDER-list":
            if (droppedItem.id === folderNode.id) {
              return {
                handled: true,
              };
            }

            const folderTree = getFolderTree({
              id: droppedItem.id,
            });
            if (findChildInTrees(folderTree, folderNode.id)) {
              // Cannot move a folder into its own children
              return;
            }
            moveNode(droppedItem.id, folderNode.id);
            return {
              handled: true,
            };
          case "PROJECT|FOLDER":
            if (droppedItem.id === folderNode.id) {
              return {
                handled: true,
              };
            }

            const ft = getFolderTree({
              id: droppedItem.id,
            });
            if (findChildInTrees(ft, folderNode.id)) {
              // Cannot move a folder into its own children
              return;
            }
            moveNode(droppedItem.id, folderNode.id);
            return {
              handled: true,
            };
        }
      },
    }),
    [folderNode.id, getFolderTree, moveNode],
  );

  const [, dragRef] = useDrag(() => ({
    type: "FOLDER-list",
    canDrag: () => level > 0,
    item: () => ({
      id: folderNode.id,
    }),
  }));

  const selected = useMemo(
    () =>
      open
        ? folderId === folderNode.id
        : folderId === folderNode.id ||
          hasChildThatIsSelected(folderId, folderNode.id, nodes),
    [open, folderId, folderNode, nodes],
  );

  const topNodes = useMemo(
    () => nodes.filter((n) => n.parent_id === folderNode.id),
    [folderNode, nodes],
  );

  useEffect(() => {
    if (
      folderNode.id !== folderId ||
      !hasChildThatIsSelected(folderId, folderNode.id, nodes)
    )
      return;
    setOpen(true);
  }, [folderNode.id, folderId, setOpen, nodes]);

  const isPersonalFolder = folderNode?.type === "personal_folder";

  const showPersonalFolder =
    isPersonalFolder || folderNode
      ? findTopLevelNode(nodes, folderNode.id, organisationId ?? "")?.type ===
        "personal_folder"
      : false;

  let searchParams = new URLSearchParams(location.search);
  let newSearchString = searchParams.toString();

  dropRef(dragRef(elementRef));

  return (
    <ListItemWrapper
      enableDrag={true}
      isHoveredMiddle={dropCollection.isHovering}
    >
      <WithTooltip text={getNodeName(folderNode)}>
        <Link
          to={`/organisation/${organisationId}/projects/${folderNode.id}?${newSearchString}`}
          className="unstyled-link"
          onClick={() => {
            setContent((curr) =>
              typeof curr !== "undefined"
                ? {
                    type: "project",
                    id: folderNode.id,
                  }
                : curr,
            );
          }}
        >
          <ListItemTopRow
            alignCenter
            ref={elementRef}
            depth={level}
            active={selected}
            hasIcon={true}
            hasExpandIcon={true}
            clickable={true}
            onDoubleClick={toggleOpen}
          >
            <ExpandArrowWrapper
              open={open}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleOpen();
              }}
            >
              <ChevronDownIcon />
            </ExpandArrowWrapper>
            <IconREMSize height={2} width={2}>
              {open ? (
                showPersonalFolder ? (
                  <PersonalFolderOpenIcon />
                ) : (
                  <FolderMenuOpenIcon />
                )
              ) : showPersonalFolder ? (
                <PersonalFolderClosedIcon />
              ) : (
                <FolderMenuClosedIcon />
              )}
            </IconREMSize>
            <ProjectTitle selected={selected} noHover={true}>
              {getNodeName(folderNode)}
            </ProjectTitle>
          </ListItemTopRow>
        </Link>
      </WithTooltip>
      {open &&
        (topNodes.length > 0 ? (
          <ChildrenNodesForParent topNodes={topNodes} level={level + 1} />
        ) : (
          <FolderIsEmptyMessage depth={level + 1} />
        ))}
    </ListItemWrapper>
  );
};

const ChildrenNodesForParent = ({
  topNodes,
  level,
}: {
  topNodes: Node[];
  level: number;
}) => {
  const sortedPersonalFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "personal_folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedFolders = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "folder")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );
  const sortedProjects = useMemo(
    () =>
      topNodes
        .filter((n) => n.type === "project")
        .sort((a, b) => a.name.localeCompare(b.name)),
    [topNodes],
  );

  return (
    <Margin>
      {sortedPersonalFolders.map((n) => (
        <FolderWithChildren level={level} key={n.id} folderNode={n} />
      ))}
      {sortedFolders.map((n) => (
        <FolderWithChildren level={level} key={n.id} folderNode={n} />
      ))}
      {sortedProjects.map((n) => (
        <ProjectElement level={level} projectNode={n} key={n.id} />
      ))}
    </Margin>
  );
};

const FolderProjectList = ({ organisationId }: { organisationId: string }) => {
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const topNodes = useAtomValue(
    getNodesWithMissingParents({
      organisationId,
    }),
  );

  const personalFolders = useMemo(
    () => topNodes.filter((n) => n.type === "personal_folder"),
    [topNodes],
  );

  const nonPersonalFolders = useMemo(
    () => topNodes.filter((n) => n.type === "folder"),
    [topNodes],
  );

  const orphanProjects = useMemo(
    () => topNodes.filter((n) => n.type === "project"),
    [topNodes],
  );

  return (
    <Margin addPadding id={"project-list"}>
      <AllProjectsElement />
      {isMemberInOrg && (
        <>
          <LibraryElement />
          <MembersElement />
        </>
      )}
      <LearnElement />
      {isMemberInOrg && <IntegrationElement />}
      {isAdminInOrg && <PortfolioElement />}

      <Divider />
      <ChildrenNodesForParent level={0} topNodes={personalFolders} />
      <ChildrenNodesForParent level={0} topNodes={nonPersonalFolders} />
      <ChildrenNodesForParent level={0} topNodes={orphanProjects} />
    </Margin>
  );
};

export default FolderProjectList;
