import { projectIdAtom } from "state/pathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Mixpanel } from "mixpanel";
import VesselIcon from "@icons/24/Vessel.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import { v4 as uuid } from "uuid";
import { toastMessagesAtom } from "../../../../state/toast";
import { selectedMenuItemState } from "../../Shared/state";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../../state/modal";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { useToast } from "hooks/useToast";
import { firstValueInMap } from "utils/utils";
import { scream } from "../../../../utils/sentry";
import { savingConfigurationInProgressAtom } from "state/configuration";
import { OperationsSettings } from "components/ConfigurationModal/OperationsSettings";
import {
  projectOperationsConfigurationsByNameFamily,
  projectOperationsConfigurationsFamily,
} from "state/jotai/operationsConfiguration";
import {
  createDefaultOperationsConfiguration,
  createOperationsConfiguration,
  deleteOperationsConfiguration,
  OperationsConfiguration,
  OperationsConfigurationZod,
  updateOperationsConfiguration,
} from "services/operationsConfigurationService";
import useOperationsConfigurationCrud from "hooks/useOperationsConfigurationCrud";
import { unwrap } from "jotai/utils";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { IconREMSize } from "styles/typography";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { useJotaiCallback } from "utils/jotai";
import { fetchProjectResourceUsage } from "services/usageService";

export const OPERATIONS_MENU_ID = "operations";

export default function useOperationsConfiguration() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { error: showErrorToast } = useToast();
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const [isSaving, setIsSaving] = useAtom(savingConfigurationInProgressAtom);
  const configurations = useAtomValue(
    unwrap(projectOperationsConfigurationsFamily(projectId)),
  );
  const setConfigurations = useSetAtom(
    projectOperationsConfigurationsFamily(projectId),
  );
  const configurationsList = useAtomValue(
    unwrap(projectOperationsConfigurationsByNameFamily(projectId)),
  );
  const { showConfirm } = useConfirm();

  const [isProjectOperationsCollapsed, setIsProjectOperationsCollapsed] =
    useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: OPERATIONS_MENU_ID,
      projectId,
    }),
  );

  useEffect(() => {
    if (configurations && !menuSelection) {
      const firstItem = firstValueInMap(configurations);
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [configurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] ===
        "operations" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _delete = useCallback(
    async (configurationId: string) => {
      if (!projectId) return;
      setIsSaving(true);
      const usage = []; //await fetchOperationsConfigurationUsage(
      // projectId,
      // configurationId,
      // );
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        setConfigurations(async (curr) => {
          const m = new Map(await curr);
          m.delete(configurationId);
          return m;
        });
        await deleteOperationsConfiguration({
          nodeId: projectId,
          configurationId,
        });
      }
      setIsSaving(false);
    },
    [projectId, setConfigurations, setIsSaving, showErrorToast],
  );

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsSaving(true);

    const newConfig = await createDefaultOperationsConfiguration({
      nodeId: projectId,
    });
    setConfigurations(async (cur) => {
      const m = new Map(await cur);
      m.set(newConfig.id, newConfig);
      return m;
    });
    setMenuSelection(newConfig.id);
    setIsSaving(false);
  }, [projectId, setConfigurations, setIsSaving, setMenuSelection]);

  const _duplicate = useCallback(
    async (id: string, name: string) => {
      if (!projectId || !configurations) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "operations",
          defaultName: `${name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const configToDuplicate = configurations.get(id);
            if (!configToDuplicate) {
              return;
            }
            setIsSaving(true);
            const clonedConfig = OperationsConfigurationZod.parse({
              ...configToDuplicate,
              name: name,
              id: uuid(),
            });

            try {
              const newConfig = await createOperationsConfiguration({
                nodeId: projectId,
                configuration: clonedConfig,
              });

              setConfigurations(async (curr) => {
                const m = new Map(await curr);
                m.set(newConfig.id, newConfig);
                return m;
              });
              setMenuSelection(newConfig.id);
            } catch (error) {
              if (error instanceof Error)
                scream(error, {
                  config: clonedConfig,
                });

              showErrorToast("Something went wrong when duplicating config", {
                timeout: 4000,
              });
            } finally {
              setIsSaving(false);
            }
          },
        },
      });
    },
    [
      projectId,
      configurations,
      setIsSaving,
      setMenuSelection,
      setConfigurations,
      showErrorToast,
      setMidScreenModalTypeOpen,
    ],
  );

  const _update = useJotaiCallback(
    async (get, set, operationsConfiguration: OperationsConfiguration) => {
      if (!projectId || !configurations) return;
      setIsSaving(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "OPERATIONS_CONFIGURATION",
          resourceId: operationsConfiguration.id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "OPERATIONS_CONFIGURATION",
          operationsConfiguration.id,
        );
      }

      const currentConfig = configurations.get(operationsConfiguration.id);

      const { name: _, ...configurationWithoutName } = operationsConfiguration;
      const { name: __, ...currentConfigWithoutName } = currentConfig || {};

      const changedSomethingElseThanName =
        JSON.stringify(configurationWithoutName) !==
        JSON.stringify(currentConfigWithoutName);

      const { description: ___, ...configurationWithoutDescription } =
        operationsConfiguration;
      const { description: ____, ...currentConfigWithoutDescription } =
        currentConfig || {};

      const changedSomethingElseThanDescription =
        JSON.stringify(configurationWithoutDescription) !==
        JSON.stringify(currentConfigWithoutDescription);

      if (
        (!changedSomethingElseThanName &&
          !changedSomethingElseThanDescription) ||
        usage.length === 0 ||
        (await showConfirm({
          title: "Update configuration",
          message: `This configuration is used in ${usage.length} branch${usage.length > 1 ? "es" : ""}, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        const newConfig = await updateOperationsConfiguration({
          nodeId: projectId,
          configuration: operationsConfiguration,
        });

        setConfigurations(async (curr) => {
          const m = new Map(await curr);
          m.set(newConfig.id, newConfig);
          return m;
        });
        Mixpanel.track_old("Config saved", {
          type: "operations",
        });
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Saved",
            timeout: 3000,
            type: "success",
          },
        ]);
      }
      setIsSaving(false);
    },
    [
      showConfirm,
      projectId,
      setIsSaving,
      configurations,
      setConfigurations,
      setToastMessages,
    ],
  );

  const { saveName, saveTempNameToLocal } = useOperationsConfigurationCrud();

  const onUnmount = useCallback(() => {
    if (!configurations) return;
    configurations.forEach((c) => {
      if (saveTempNameToLocal) saveTempNameToLocal(c.id);
    });
  }, [configurations, saveTempNameToLocal]);

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const loadedConfigurationsList = configurationsList ?? [];
    const configList = loadedConfigurationsList.map((config) => ({
      id: config.id,
      label: config.name ?? "",
      loading: isSaving,
      content: (
        <div
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <OperationsSettings
            key={config.id}
            configuration={config}
            nodeId={projectId}
            configurationName={config.name ?? ""}
            onSave={_update}
            onSaveName={saveName}
            onUnmount={onUnmount}
          />
        </div>
      ),
      onDuplicate: undefined,
      onChangeName: async (newName: string) => {
        return _update({
          ...config,
          name: newName,
        });
      },
      dotMenuOptions: [
        ...(loadedConfigurationsList.length > 1
          ? [
              {
                title: "Delete",
                onSelect: _delete,
                icon: <DeleteIcon />,
              },
            ]
          : []),
        {
          title: "Duplicate",
          onSelect: () => _duplicate(config.id, config.name),
          icon: <DuplicateIcon />,
        },
      ],
    }));

    return [
      {
        title: "Project specific configs",
        items: configList,
        isCollapsed: isProjectOperationsCollapsed,
        loading: configurationsList == null,
        icon: (
          <IconREMSize height={0.6} width={0.6}>
            <ExpandArrowWrapper
              open={!isProjectOperationsCollapsed}
              onClick={() =>
                setIsProjectOperationsCollapsed(!isProjectOperationsCollapsed)
              }
            >
              <ChevronDownIcon />
            </ExpandArrowWrapper>
          </IconREMSize>
        ),
      },
    ];
  }, [
    _delete,
    _duplicate,
    _update,
    isProjectOperationsCollapsed,
    configurationsList,
    isSaving,
    onUnmount,
    saveName,
    projectId,
  ]);

  const createNewOperationsConfiguration = useMemo(() => {
    return {
      type: "element",
      saveInProgress: isSaving,
      element: (
        <Button
          disabled={isSaving}
          onClick={(e) => {
            e.stopPropagation();
            _create();
          }}
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [isSaving, _create]);

  const operationsConfigurations = useMemo(() => {
    return {
      id: OPERATIONS_MENU_ID,
      label: "Operations",
      icon: <VesselIcon />,
      submenus: configSubMenus,
      createNew: createNewOperationsConfiguration,
    };
  }, [configSubMenus, createNewOperationsConfiguration]);

  return operationsConfigurations;
}
