import { MenuItem } from "../../General/Menu";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";

type TypeMenuItemProps = {
  item: {
    value: string;
    name: string;
    type: string;
    level: string;
  };
  isSelected: boolean;
  onSelect: (value: string) => void;
  showType?: boolean;
};

export const LevelIcon = ({ level }: { level: string }) => {
  switch (level) {
    case "library":
      return <LibraryIcon />;
    case "project":
      return <ProjectIcon />;
    default:
      return <VindLogo />;
  }
};

export function TypeMenuItem({
  item,
  isSelected,
  onSelect,
  showType,
}: TypeMenuItemProps) {
  const icon = LevelIcon({ level: item.level });

  const itemName = showType ? `${item.name} (${item.type})` : item.name;

  return (
    <MenuItem
      textStyle={{
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      selected={isSelected}
      key={item.value}
      name={itemName}
      onClick={() => onSelect(item.value)}
      icon={icon}
    />
  );
}
