import DownloadIcon from "@icons/24/Download.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import {
  getAEPPerTurbine,
  getAnalysisProgress,
  getAnalysisWindStats,
  getAverageSpeedPerTurbine,
  getGrossPerTurbine,
  getTotalWakeLossPerTurbine,
} from "analysis/output";
import { getStoppedReason, getThrowStoppedReason } from "analysis/warnings";
import { BATHYMETRY_SOURCE_DESCRIPTION } from "business/bathymetry/utils";
import Table from "components/Dashboard/Table";
import Tooltip from "components/General/Tooltip";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { loadable } from "jotai/utils";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { turbinesInParkByLabelFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import styled from "styled-components";
import { SimpleTurbineType } from "types/turbines";
import { lmap, useJotaiCallback } from "utils/jotai";
import { useToast } from "../../../hooks/useToast";
import { colors } from "../../../styles/colors";
import { TurbineFeature } from "../../../types/feature";
import { Raster } from "../../../types/raster";
import { isDefined } from "../../../utils/predicates";
import { downloadText, roundToDecimal, undefMap } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import {
  SkeletonBlock,
  SkeletonText,
  orTextLoader,
} from "../../Loading/Skeleton";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import { bathymetryWithRasterFamily } from "state/bathymetry";
import { isOnshoreAtom } from "state/onshore";

const InfoIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  svg {
    width: 12px;
    height: 12px;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

const getDepth = (turbine: TurbineFeature, raster: Raster): number => {
  const [lon, lat] = turbine.geometry.coordinates;

  return raster.latLngToValue(lon, lat);
};

type Data = {
  name: string | number;
  turbine: TurbineFeature;
  number: number;
  turbineType: SimpleTurbineType | undefined;
  wakeLoss: Loadable<number | undefined>;
  depth: number | undefined;
  speed: Loadable<number | undefined>;
  aep: Loadable<number | undefined>;
  gross: Loadable<number | undefined>;
  longitude: number;
  latitude: number;
};

type FormattedData = {
  name: string | number;
  turbine: TurbineFeature;
  number: number;
  turbineType: SimpleTurbineType | undefined;
  wakeLoss: Loadable<string | undefined>;
  depth: string | undefined;
  speed: Loadable<string | undefined>;
  aep: Loadable<string | undefined>;
  gross: Loadable<string | undefined>;
  longitude: string;
  latitude: string;
};

/**
 * Source for the data we're showing. The same hook is used to show the data in
 * the table, as well as for downloading the .csv.
 */
const useTurbineData = () => {
  const { park, branch, triggerId, parkBathymetryId } = useDashboardContext();

  const { raster, hasCustomBathymetry } = useAtomValue(
    bathymetryWithRasterFamily(parkBathymetryId),
  );

  const wakeLossPerTurbine = useAtomValue(
    loadable(getTotalWakeLossPerTurbine(triggerId)),
  );
  const aepPerTurbine = useAtomValue(loadable(getAEPPerTurbine(triggerId)));
  const grossPerTurbine = useAtomValue(loadable(getGrossPerTurbine(triggerId)));
  const averageSpeedPerTurbine = useAtomValue(
    loadable(getAverageSpeedPerTurbine(triggerId)),
  );
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));
  const windStats = useAtomValue(loadable(getAnalysisWindStats(triggerId)));

  const getWakeLossForTurbine = useCallback(
    (turbineId: string) =>
      lmap(
        wakeLossPerTurbine,
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [wakeLossPerTurbine],
  );

  const getSpeedForTurbine = useCallback(
    (turbineId: string) =>
      lmap(
        averageSpeedPerTurbine,
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [averageSpeedPerTurbine],
  );

  const getAepForTurbine = useCallback(
    (turbineId: string) =>
      lmap(
        aepPerTurbine,
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [aepPerTurbine],
  );

  const getGrossForTurbine = useCallback(
    (turbineId: string) =>
      lmap(
        grossPerTurbine,
        (l) => l?.find((t) => t.turbine.id === turbineId)?.value,
      ),
    [grossPerTurbine],
  );

  const getData = useJotaiCallback(
    async (get): Promise<Data[] | undefined> => {
      if (!raster) return;
      const turbines = await get(
        turbinesInParkByLabelFamily({ parkId: park.id, branchId: branch.id }),
      );
      const turbineTypes = await get(simpleTurbineTypesAtom);
      const depthPerTurbine = turbines.map((turbine) =>
        getDepth(turbine, raster),
      );
      const data = turbines.map((turbine, i) => {
        return {
          name: turbine.properties.name ?? i + 1,
          turbine,
          speed: getSpeedForTurbine(turbine.id),
          wakeLoss: getWakeLossForTurbine(turbine.id),
          number: i + 1,
          depth: depthPerTurbine?.[i],
          aep: getAepForTurbine(turbine.id),
          gross: getGrossForTurbine(turbine.id),
          longitude: turbine.geometry.coordinates[0],
          latitude: turbine.geometry.coordinates[1],
          turbineType: turbineTypes.get(turbine.properties.turbineTypeId),
        };
      });
      return data;
    },
    [
      getAepForTurbine,
      raster,
      park.id,
      branch.id,
      getWakeLossForTurbine,
      getSpeedForTurbine,
      getGrossForTurbine,
    ],
  );

  return {
    getData,
    hasCustomBathymetry,
    stoppedReason,
    analysisProgress,
    windStats,
  };
};

/** Replaces the argument with a `Skeleton` if it is `undefined`.
 *
 * When we're requeting for production numbers, we immediately get back some
 * numbers, but not all. The ones that aren't ready yet are given as
 * `undefined`.  This is so that we can show as much data as possible eary on,
 * without having to wait for the analysis.
 *
 * This function just renders these values "correctly", so that the loading ones
 * are shown as a loader, and the ready ones are shown as-is.
 */
const undefToSkeleton = <T,>(t: T | undefined): T | ReactNode =>
  isDefined(t) ? (
    t
  ) : (
    <SkeletonBlock style={{ height: "1rem", width: "100%" }} />
  );

const TurbineDetails = () => {
  const { triggerId } = useDashboardContext();
  useAtomValue(getThrowStoppedReason(triggerId));
  return <Inner />;
};

const Inner = () => {
  const { analysisProgress, getData, windStats } = useTurbineData();
  const isOnshore = useAtomValue(isOnshoreAtom);

  const [items, setItems] = useState<undefined | FormattedData[]>(undefined);

  useEffect(() => {
    getData().then((data) => {
      setItems(
        data?.map((d) => {
          const wakeLoss = lmap(d.wakeLoss, (wl) =>
            undefMap(wl, (wl) => `${(wl * 100.0).toFixed(1)}%`),
          );
          const depth = undefMap(d.depth, (d) => `${(-d).toFixed(0)} m`);
          const speed = lmap(d.speed, (speed) =>
            undefMap(speed, (s) => `${s.toFixed(2)} m/s`),
          );
          const aep = lmap(d.aep, (aep) =>
            undefMap(aep, (aep) => `${aep?.toFixed(1)} GWh`),
          );
          const gross = lmap(d.gross, (gross) =>
            undefMap(gross, (gross) => `${gross?.toFixed(1)} GWh`),
          );
          return {
            ...d,
            wakeLoss,
            depth,
            speed,
            aep,
            gross,
            longitude: d.longitude.toFixed(4),
            latitude: d.latitude.toFixed(4),
          };
        }),
      );
    });
  }, [getData]);

  if (!items) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  if (items.length === 0)
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );

  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Type</th>
          <th>Gross</th>
          <th>Wake loss</th>
          <th>
            <InfoIconWrapper>
              <>AEP</>
              <Tooltip
                text={`Gross minus total wake loss and custom turbine-specific losses.`}
              >
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          <th>
            <InfoIconWrapper>
              <>Avg. speed</>
              <Tooltip
                text={`Unwaked mean speed, including any spatial calibration, valid at hub height: ${
                  windStats.state === "hasData" ? windStats.data.height : ""
                }m`}
              >
                <InfoIcon />
              </Tooltip>
            </InfoIconWrapper>
          </th>
          {!isOnshore && (
            <th>
              <InfoIconWrapper>
                <>Depth</>
                <Tooltip text={BATHYMETRY_SOURCE_DESCRIPTION}>
                  <InfoIcon />
                </Tooltip>
              </InfoIconWrapper>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map(
          ({
            name,
            turbineType,
            aep,
            speed,
            depth,
            wakeLoss,
            turbine,
            gross,
          }) => (
            <tr key={turbine.id}>
              <td>{undefToSkeleton(name)}</td>
              <td>{undefToSkeleton(turbineType?.name)}</td>
              <td>
                {orTextLoader(
                  gross,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>
                {orTextLoader(
                  wakeLoss,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>
                {orTextLoader(
                  aep,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              <td>
                {orTextLoader(
                  speed,
                  undefMap(
                    analysisProgress,
                    (p) => `${Math.round(p * 100)}%`,
                  ) ?? "",
                  (n) => (
                    <>{n}</>
                  ),
                )}
              </td>
              {!isOnshore && <td>{undefToSkeleton(depth)}</td>}
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

const delimiter = ",";
const removeDelimiter = (s: string) => s.replace(delimiter, "");
const TurbineMenu = () => {
  const { error, warning } = useToast();
  const { hasCustomBathymetry, getData } = useTurbineData();
  const { park } = useDashboardContext();

  const downloadCSV = useCallback(async () => {
    const data = await getData();
    if (!data) {
      error("Cannot download turbine data: missing bathymetry data.");
      return;
    }

    const depthDecimals = hasCustomBathymetry ? 1 : 0;

    const isLoading = data.find(
      (d) =>
        d.wakeLoss.state !== "hasData" ||
        d.speed.state !== "hasData" ||
        d.aep.state !== "hasData",
    );

    if (isLoading) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const fields = [
      "name",
      "turbineType",
      "gross",
      "wakeLoss",
      "aep",
      "speed",
      "depth",
      "longitude",
      "latitude",
    ];
    type Field = (typeof fields)[number];

    const formatted: Record<
      Field,
      string | undefined | number | TurbineFeature
    >[] = data.map((d) => ({
      name: `${d.name}`,
      turbine: d.turbine,
      wakeLoss:
        d.wakeLoss.state === "hasData"
          ? undefMap(d.wakeLoss.data, (s) => roundToDecimal(s, 4))
          : undefined,

      number: d.number,
      depth: undefMap(d.depth, (d) => roundToDecimal(-d, depthDecimals)),
      aep:
        d.aep.state === "hasData"
          ? undefMap(d.aep.data, (d) => d.toFixed(1))
          : undefined,
      gross:
        d.gross.state === "hasData"
          ? undefMap(d.gross.data, (d) => d.toFixed(1))
          : undefined,
      speed:
        d.speed.state === "hasData"
          ? undefMap(d.speed.data, (d) => d.toFixed(2))
          : undefined,
      longitude: d.longitude.toFixed(6),
      latitude: d.latitude.toFixed(6),
      turbineType: d.turbineType?.name ?? "-",
    }));

    const headerNames: Record<Field, string> = {
      name: "Name",
      turbineType: "Type",
      gross: "Gross (GWh)",
      wakeLoss: "Wake loss",
      aep: "AEP (GWh)",
      depth: "Depth (m)",
      speed: "Mean speed (m/s)",
      longitude: "Longitude",
      latitude: "Latitude",
    };

    let csv = "#";
    for (const f of fields.slice(1)) {
      csv += delimiter + headerNames[f];
    }

    for (const d of formatted) {
      csv += "\n" + removeDelimiter(String(d.name));
      for (const f of fields.slice(1)) {
        csv += delimiter + removeDelimiter(String(d[f]));
      }
    }

    downloadText(
      csv,
      `turbines-${park.properties.name}-${new Date().toISOString()}.csv`,
    );
  }, [error, getData, hasCustomBathymetry, park.properties.name, warning]);

  return (
    <MenuItem
      name={"Download as .csv"}
      icon={<DownloadIcon />}
      onClick={() => downloadCSV()}
    />
  );
};

export const TurbineListWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Turbines"
      id="Turbines"
      menuItems={<TurbineMenu />}
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <TurbineDetails />
    </SafeCard>
  );
};
