import {
  WebGLRenderer,
  Scene,
  Color,
  PerspectiveCamera,
  Vector3,
  Mesh,
  SphereGeometry,
  Object3D,
  Raycaster,
  DirectionalLight,
  Vector2,
  CameraHelper,
  MeshLambertMaterial,
  AmbientLight,
  PCFShadowMap,
  MOUSE,
} from "three";
import {
  DISTANCE_TO_WIRE_FRAME_SUN,
  DIVISION_FACTOR,
  WIRE_FRAME_SUN_RADIUS,
} from "../../ViewToPark/constants";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

const buffer = 1.2;

export function createRenderer() {
  const renderer = new WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFShadowMap;
  return renderer;
}

export function createScene() {
  const scene = new Scene();
  scene.background = new Color(0xeeeeee);
  return scene;
}

export function createPerspectiveCamera() {
  const camera = new PerspectiveCamera(
    30,
    window.innerWidth / window.innerHeight,
    1 / DIVISION_FACTOR,
    80_000 / DIVISION_FACTOR,
  );
  camera.position.set(
    -3000 / DIVISION_FACTOR,
    3000 / DIVISION_FACTOR,
    3000 / DIVISION_FACTOR,
  );
  camera.lookAt(new Vector3(0, 0, 0));

  return camera;
}

export function createOrbitControls(
  camera: PerspectiveCamera,
  renderer: WebGLRenderer,
) {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.rotateSpeed = 1 / 2;
  controls.zoomSpeed = 1 / 4;
  controls.mouseButtons = {
    LEFT: MOUSE.PAN,
    MIDDLE: MOUSE.DOLLY,
    RIGHT: MOUSE.ROTATE,
  };
  controls.screenSpacePanning = false;
  return controls;
}

export function createSun(
  width: number,
  height: number,
  center: [number, number],
  maxShadowMapSize: number,
) {
  const diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) * buffer;
  const color = 0xffffff;
  const light = new DirectionalLight(color);
  const shadowMapSize = Math.min(8192, maxShadowMapSize);
  light.shadow.mapSize = new Vector2(shadowMapSize, shadowMapSize);
  light.castShadow = true;
  light.shadow.camera.near = DISTANCE_TO_WIRE_FRAME_SUN;
  light.shadow.camera.far =
    DISTANCE_TO_WIRE_FRAME_SUN + Math.max(height, width) * 2;
  light.shadow.camera.left = -diagonal / 2;
  light.shadow.camera.right = diagonal / 2;
  light.shadow.camera.top = diagonal / 2;
  light.shadow.camera.bottom = -diagonal / 2;
  light.target.position.set(center[0], 0, center[1]);
  light.target.updateMatrixWorld();

  const cameraHelper = new CameraHelper(light.shadow.camera);

  const bulb = new Mesh(
    new SphereGeometry(WIRE_FRAME_SUN_RADIUS, 32, 16),
    new MeshLambertMaterial({ color: 0xfff000 }),
  );

  const setSunPosition = (elevation: number, azimuth: number) => {
    const r = DISTANCE_TO_WIRE_FRAME_SUN + Math.max(height, width);
    const x = r * Math.cos(elevation) * Math.sin(azimuth);
    const z = -r * Math.cos(elevation) * Math.cos(azimuth);
    const y = r * Math.sin(elevation);
    light.position.set(x, y, z);
    light.target.position.set(center[0], 0, center[1]);
    light.target.updateMatrixWorld();
    bulb.position.set(x, y, z);
    cameraHelper.update();
  };

  const ambientlight = new AmbientLight(color, 0.6); // soft white light

  return { light, cameraHelper, bulb, setSunPosition, ambientlight };
}

export const INITIAL_SUN_POS = {
  elevation: 2,
  azimuth: 180,
};

export const sampleTerrainMeshHeight = (
  coords: number[],
  terrainMesh?: Object3D,
): number | undefined => {
  if (!terrainMesh) return;
  const ray = new Raycaster();
  const rayPos = new Vector3();

  rayPos.set(-coords[0], 100, coords[1]);
  const rayDir = new Vector3(0, -1, 0); // Ray points down
  ray.set(rayPos, rayDir);
  const intersect = ray.intersectObject(terrainMesh);

  if (intersect.length !== 1) {
    console.log("No intersection");
    return;
  }

  return Math.max(intersect[0].point.y * DIVISION_FACTOR, 0);
};
