import React from "react";
import styled from "styled-components";
import { spaceLarge, spaceMedium } from "../../../../../styles/space";
import { typography } from "../../../../../styles/typography";
import Dropdown from "../../../../Dropdown/Dropdown";

const Wrapper = styled.div`
  display: flex;
  gap: ${spaceMedium};
  align-items: center;
  justify-content: space-between;
  padding-right: calc(${spaceLarge} + 1px);
`;

const AssignParkDropdown = ({
  selectedParkId,
  setSelectedParkId,
  parks,
}: {
  selectedParkId: string;
  setSelectedParkId: (id: string) => void;
  parks: { name: string; id: string }[];
}) => {
  return (
    <Wrapper>
      <span style={{ ...typography.contentAndButtons }}>
        To assign feature types to your uploads, you need to select a park.
      </span>
      <Dropdown
        onChange={({ target }) => setSelectedParkId(target.value)}
        value={selectedParkId}
        style={{ width: "18rem" }}
      >
        <option value="">No park selected</option>
        {parks.map((park) => (
          <option key={park.id} value={park.id}>
            {park.name}
          </option>
        ))}
      </Dropdown>
    </Wrapper>
  );
};

export default AssignParkDropdown;
