import type {
  DropTargetHookSpec,
  DragSourceHookSpec,
} from "react-dnd/src/hooks/types";
import { DropCollectedProps } from "./types";

export const getDropCollect = <T, U>(): DropTargetHookSpec<
  T,
  U,
  DropCollectedProps
>["collect"] => {
  return (monitor) => {
    const isHovered = monitor.isOver() && monitor.canDrop();
    return {
      isHovered,
    };
  };
};

export const dragCollect: DragSourceHookSpec<any, any, any>["collect"] = (
  monitor,
) => ({
  isDragging: monitor.isDragging(),
});
