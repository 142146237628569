import * as turf from "@turf/turf";
import { useMemo } from "react";
import { projectIdAtom } from "../../../state/pathParams";
import { ParkFeature } from "../../../types/feature";
import { ColoredGrid } from "../../General/Form";
import { isMooringLineMultiple } from "../../../utils/predicates";
import { previewMooringAndFoundationState } from "../../GenerateFoundationsAndAnchors/state";
import { Row } from "./InfoModal.style";
import { DashboardModalType, modalTypeOpenAtom } from "../../../state/modal";
import Dashboard from "@icons/24/Dashboard.svg?react";
import { ButtonWrapper } from "./style";
import Button from "../../General/Button";
import { sum } from "../../../utils/utils";
import { MAX_BUFFER_DISTANCE_RASTER_ANCHOR } from "services/mooringLineTypeService";
import { Column } from "components/General/Layout";
import { SkeletonText, ifLoader } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  SubtitleWithLine,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { SubAreaTag } from "components/SiteLocator/Tags";
import { useAtomValue, useSetAtom } from "jotai";
import { useBathymetry } from "hooks/bathymetry";
import {
  selectedAnchorsAtom,
  selectedAreaAtom,
  selectedMooringLinesAtom,
} from "state/jotai/selection";
import { Tag } from "components/General/Tag";
import React from "react";

const AnchorDistribution = React.lazy(
  () => import("../../RightSide/InfoModal/AnchorDistribution"),
);

const Inner = ({
  projectId,
  park,
}: {
  projectId: string;
  park: ParkFeature;
}) => {
  const setModalType = useSetAtom(modalTypeOpenAtom);

  const previewMooringAndFoundations = useAtomValue(
    previewMooringAndFoundationState,
  );

  const areaFeatures = useAtomValue(selectedAreaAtom) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const selectedLines = useAtomValue(selectedMooringLinesAtom);
  const selectedAnchors = useAtomValue(selectedAnchorsAtom);

  const anchors = previewMooringAndFoundations
    ? previewMooringAndFoundations.preview.anchors
    : selectedAnchors;

  const mooringLines = previewMooringAndFoundations
    ? previewMooringAndFoundations.preview.mooringLines
    : selectedLines;

  const totalLength = sum(mooringLines, (line) =>
    isMooringLineMultiple(line)
      ? sum(line.properties.lineLengths)
      : line.properties.lineLength ?? 0,
  );

  const [bathymetry] = useBathymetry({
    projectId,
    featureId: park.id,
    branchId: undefined,
    bufferKm: MAX_BUFFER_DISTANCE_RASTER_ANCHOR,
  });

  if (bathymetry.state === "hasError")
    return (
      <div>
        <SimpleAlert text="Error downloading bathymetry." />
      </div>
    );

  if (anchors.length === 0)
    return (
      <div>
        <SimpleAlert text="No anchors in the park." />
      </div>
    );

  return (
    <>
      <Row style={{ justifyContent: "left", flexWrap: "wrap" }}>
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag text="Park" />
        )}
        <Tag text={`${selectedArea} km²`} />
      </Row>

      <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
        <p>Number of anchors</p>
        <ResultValue>
          <strong>{anchors.length}</strong>
        </ResultValue>

        <p>Mooring line length</p>
        <ResultValue>
          <strong>{totalLength.toFixed(3)}</strong> km
        </ResultValue>
      </ColoredGrid>
      <SubtitleWithLine text={"Anchor depth histogram"} />
      {ifLoader(
        bathymetry,
        () => (
          <Column>
            <SkeletonText
              style={{ height: "2rem" }}
              text="Loading bathymetry"
            />
          </Column>
        ),
        (bath) =>
          bath.status === "finished" ? (
            <AnchorDistribution anchors={anchors} depthRaster={bath.raster} />
          ) : null,
      )}
      <ButtonWrapper>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-mooring" },
            });
          }}
        />
      </ButtonWrapper>
    </>
  );
};

const MooringTab = ({ park }: { park: ParkFeature }) => {
  const projectId = useAtomValue(projectIdAtom);
  if (!projectId)
    return (
      <div>
        <SimpleAlert text="Missing park ID." />
      </div>
    );
  return <Inner park={park} projectId={projectId} />;
};

export default MooringTab;
