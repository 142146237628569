import { getDistanceFromPortToPark } from "../utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getBranchId, getParkId } from "analysis/inputs";
import { mooringLinesInParkFamily } from "state/jotai/mooringLine";
import { anchorsInParkFamily } from "state/jotai/anchor";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import { positionOnSiteActivity, transitActivity } from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { isAnchorHandlingVessel } from "services/vesselService";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { MooringActivitiesConfig } from "services/operationsConfigurationService";

export const mooringInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const parkId = await get(getParkId(id));

    const mooringLines = await get(
      mooringLinesInParkFamily({ parkId, branchId }),
    );

    const anchors = await get(anchorsInParkFamily({ parkId, branchId }));

    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const configuration = await get(getOperationsConfiguration(id));
    const {
      installationVessel,
      installationSeason,
      weatherLimits,
      activities,
    } = configuration.ti.mooring;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isAnchorHandlingVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const numLines = mooringLines.length;
    const numAnchors = anchors.length;
    const numRounds = Math.ceil(numAnchors / vessel.anchorCapacity);
    const numExtraLines = numLines - numAnchors; //number of mooring lines might be greater than number of anchors (shared anchors)
    const numAnchorsInLastRound =
      numAnchors - (numRounds - 1) * vessel.anchorCapacity;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numRounds; i++) {
      const anchorsInRound =
        i === numRounds - 1 ? numAnchorsInLastRound : vessel.anchorCapacity;

      for (let j = 0; j < anchorsInRound; j++) {
        totalInstallTime.push(loadoutAnchorActivity(activities));
      }

      totalInstallTime.push(transitActivity(transitTime));

      for (let j = 0; j < anchorsInRound; j++) {
        anchorLineOperations(activities).forEach((operation) => {
          totalInstallTime.push(operation);
        });
      }

      if (i === numRounds - 1) {
        for (let j = 0; j < numExtraLines; j++) {
          totalInstallTime.push(installMooringLineActivity(activities));
        }
      }

      totalInstallTime.push(transitActivity(transitTime));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const loadoutAnchorActivity = (activities: MooringActivitiesConfig) => ({
  id: "Loadout anchor and mooring line",
  duration: activities.loadAnchorAndLineOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

const siteSurveyActivity = (activities: MooringActivitiesConfig) => ({
  id: "Site survey",
  duration: activities.ROVSurvey,
  useWindLim: false,
  useWaveLim: true,
});

const installAnchorActivity = (activities: MooringActivitiesConfig) => ({
  id: "Install anchor",
  duration: activities.installAnchor,
  useWindLim: true,
  useWaveLim: true,
});

const installMooringLineActivity = (activities: MooringActivitiesConfig) => ({
  id: "Install mooring line",
  mileStone: true,
  duration: activities.installMooringLine,
  useWindLim: true,
  useWaveLim: true,
});

const anchorLineOperations = (activities: MooringActivitiesConfig) => [
  positionOnSiteActivity,
  siteSurveyActivity(activities),
  installAnchorActivity(activities),
  installMooringLineActivity(activities),
];
