import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { useCallback, useState } from "react";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { SingleNodeView, UsageGrid } from "./shared";
import { CableType } from "services/cableTypeService";
import useNodeCableResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeCableResourcesCrud";
import { nodesWithAccessToCable } from "components/Organisation/Library/state";
import {
  adminInOrganisationSelectorFamily,
  orgCableManageAccessSelector,
} from "state/user";
import { Column, Row } from "components/General/Layout";
import Checkbox from "components/General/Checkbox";
import {
  ContentTableColumnFirstGrey,
  TabDescription,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg";
import {
  AdminNodeAccessModalWrapper,
  NodeAccessModalWrapper,
} from "../../shared/NodeAccessModal";
import { colors } from "styles/colors";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import Earth from "@icons/14/Earth.svg";
import { AllProjectsResourceAccess } from "./AllProjectsResourceAccess";
import { LibraryManageRole } from "components/Organisation/Library/types";

export function CableProjects({ resource }: { resource: CableType }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const { addOrUpdate: addOrUpdateCable, remove } = useNodeCableResourcesCrud();

  const nodesWithAccess = useAtomValue(
    nodesWithAccessToCable({
      organisationId,
      resourceId: resource.id,
    }),
  );

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const entireOrgHasAccessToResource = nodesWithAccess.some(
    (na) => na.node_information_id === organisationId,
  );
  const hasOrgCableAccess = useAtomValue(orgCableManageAccessSelector);

  const [showAddProject, setShowAddProject] = useState(false);
  const [addInProgress, setAddInProgress] = useState<number>(0);
  const [removeInProgress, setRemoveInProgress] = useState(false);

  const onAddProjects = useCallback(
    async (
      nodes: {
        node_id: string;
      }[],
    ) => {
      setAddInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            return addOrUpdateCable(node.node_id, resource.id).then(() =>
              setAddInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setShowAddProject(false);
        setAddInProgress(0);
      }
    },
    [addOrUpdateCable, resource],
  );

  const onRemoveAccess = useCallback(
    async (
      nodes: {
        node_id: string;
      }[],
    ) => {
      setRemoveInProgress(true);
      try {
        await Promise.all(
          nodes.map((node) => {
            return remove(node.node_id, resource.id);
          }),
        );
        setShowAddProject(false);
      } catch {
      } finally {
        setShowAddProject(false);
        setRemoveInProgress(false);
      }
    },
    [remove, resource.id],
  );

  return (
    <Column
      style={{
        gap: "2.4rem",
        paddingTop: "2.4rem",
        height: "100%",
      }}
    >
      <Row>
        <Checkbox
          label={
            <TextEllipsis>
              Make <strong>{resource.name}</strong> available in all projects
            </TextEllipsis>
          }
          labelPlacement="after"
          disabled={removeInProgress || addInProgress > 0}
          checked={entireOrgHasAccessToResource}
          onChange={(e) => {
            e.target.checked
              ? onAddProjects([
                  {
                    node_id: organisationId,
                  },
                ])
              : onRemoveAccess([
                  {
                    node_id: organisationId,
                  },
                ]);
          }}
        />
        {hasOrgCableAccess && (
          <div
            style={{
              marginLeft: "auto",
              position: "relative",
            }}
          >
            <Tooltip
              text={
                entireOrgHasAccessToResource
                  ? "Entire organsiation can use this resource"
                  : ""
              }
              disabled={!entireOrgHasAccessToResource}
            >
              <Button
                text="Add projects"
                onClick={() => setShowAddProject((cur) => !cur)}
                disabled={entireOrgHasAccessToResource || addInProgress > 0}
                icon={<AddIcon />}
              />
            </Tooltip>
            {showAddProject && (
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                }}
              >
                {isAdminInOrg ? (
                  <AdminNodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                ) : (
                  <NodeAccessModalWrapper
                    onSave={onAddProjects}
                    existingAccess={nodesWithAccess.map(
                      (n) => n.node_information_id,
                    )}
                    disableAccessRole={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Row>

      <ContentTableColumnFirstGrey>
        {nodesWithAccess.length > 0 ? (
          <>
            <UsageGrid
              style={{
                backgroundColor: colors.surfacePrimary,
              }}
            >
              <TabDescription>
                This library cable is{" "}
                {entireOrgHasAccessToResource ? "used" : "available"} in:
              </TabDescription>
              <TabDescription>Usage:</TabDescription>
            </UsageGrid>
            {entireOrgHasAccessToResource ? (
              <AllProjectsResourceAccess
                organisationId={organisationId}
                resourceId={resource.id}
                resourceType={"CABLE"}
                libraryManageRole={LibraryManageRole.CABLE}
              />
            ) : (
              nodesWithAccess.map((na) => {
                return (
                  <SingleNodeView
                    key={na.node_information_id}
                    nodeId={na.node_information_id}
                    resourceId={resource.id}
                    organisationId={organisationId}
                    canRemove={true}
                    type={"CABLE"}
                  />
                );
              })
            )}
          </>
        ) : (
          <EmptyState
            title="Resource not available in any projects"
            description="Make this resource available in all projects or select specific projects that can use it by clicking the add button above."
            icon={<Earth />}
            withFill={true}
            style={{
              boxSizing: "border-box",
            }}
          />
        )}
        {Array.from(
          {
            length: addInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "0.8rem",
              }}
            />
          ),
        )}
      </ContentTableColumnFirstGrey>
    </Column>
  );
}
