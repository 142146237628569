import TurbineIcon from "@icons/24/RenewableEnergyWindTurbine.svg?react";
import { InnerContainer, Container, Title } from "../style";
import Tooltip from "components/General/Tooltip";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";

import ManageMyAccessButton from "../shared/ManageMyAccessButton";
import { tabToName } from "../shared/utils";
import { TurbineManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { Row } from "components/General/Layout";

export default function TurbineImage({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Container
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {disabled && <ManageMyAccessButton />}
      <Tooltip disabled={!disabled} text={"Turbine library access needed"}>
        <InnerContainer className="InnerContainer">
          <IconREMSize width={18} height={18} stroke={colors.blue600}>
            <TurbineIcon />
          </IconREMSize>
        </InnerContainer>
      </Tooltip>
      <Row style={{ justifyContent: "space-between" }}>
        <Title>{tabToName("turbine")} </Title>
        <TurbineManagersPreview optionToAddManagers={false} />
      </Row>
    </Container>
  );
}
