import { LngLatBoundsLike } from "mapbox-gl";
import { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { DivisionFeature, ParkFeature } from "types/feature";
import { currentMapStyleIdAtom } from "state/map";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import { MapboxMoveLogoToUpperLeft } from "./Base.style";
import { RenderCables } from "components/Mapbox/Cables";
import { RenderDivisions } from "components/Mapbox/Divisions";
import { RenderExportCables } from "components/Mapbox/ExportCables";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderSubstations } from "components/Mapbox/Substations";
import { RenderTurbines } from "components/Mapbox/Turbines";
import { RenderCableChains } from "components/Mapbox/CableChains";
import { useDashboardContext } from "../Dashboard";
import { isPrintingAtom } from "../state";
import { SafeCard } from "./Base";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { cablesInParkFamily } from "state/jotai/cable";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { substationsInParkFamily } from "state/jotai/substation";
import { cableChainsInParkFamily } from "state/jotai/cableChain";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesFamily } from "state/jotai/exclusionZone";
import { branchIdAtom } from "state/pathParams";

export const cableMatrixMap = atom<mapboxgl.Map | null>(null);

const Map = ({ map, park }: { map: mapboxgl.Map; park: ParkFeature }) => {
  const parkId = park.id;

  const branchId = useAtomValue(branchIdAtom) ?? "";

  const turbines = useAtomValue(turbinesInParkFamily({ parkId, branchId }));
  const cables = useAtomValue(cablesInParkFamily({ parkId, branchId }));
  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId }),
  );
  const substations = useAtomValue(
    substationsInParkFamily({ parkId, branchId }),
  );

  const chains = useAtomValue(cableChainsInParkFamily({ parkId, branchId }));

  const subAreas = useAtomValue(subAreasInParkFamily({ parkId, branchId }));
  const exclusionZones = useAtomValue(exclusionZonesFamily({ branchId }));

  const parks = useMemo(() => [park], [park]);
  const zones = useMemo(() => {
    const ret: DivisionFeature[] = subAreas;
    return ret.concat(exclusionZones);
  }, [subAreas, exclusionZones]);

  return (
    <>
      <RenderParks map={map} parks={parks} />
      <RenderDivisions map={map} divisions={zones} />
      <RenderTurbines map={map} turbines={turbines} />
      <RenderSubstations map={map} substations={substations} symbols />
      <RenderExportCables map={map} exportCables={exportCables} />
      <RenderCables map={map} cables={cables} />
      <RenderCableChains map={map} chains={chains} />
    </>
  );
};

const CableMatrixMap = () => {
  const { park } = useDashboardContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const setCableMatrixMap = useSetAtom(cableMatrixMap);
  const isPrinting = useAtomValue(isPrintingAtom);
  const activeMapStyleId = useAtomValue(currentMapStyleIdAtom);

  const [_boundsCount, refreshBounds] = useState(0);
  const bounds: LngLatBoundsLike = useMemo(() => {
    const bbox = getBBOXArrayFromFeatures([park]);
    return [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ];
  }, [park]);

  useEffect(() => {
    return () => {
      setMapIsLoaded(false);
      setCableMatrixMap(null);
    };
  }, [setCableMatrixMap, setMapIsLoaded]);

  useEffect(() => {
    if (map || !mapContainer.current) return;
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      bounds,
      interactive: false,
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
    });
    setMap(newMap);
    newMap.on("load", () => {
      newMap.resize();
      setCableMatrixMap(newMap);
      setMapIsLoaded(true);
    });
  }, [
    map,
    mapContainer,
    bounds,
    setCableMatrixMap,
    activeMapStyleId,
    setMapIsLoaded,
  ]);

  useEffect(() => {
    if (!map) return;
    map.fitBounds(bounds);
  }, [bounds, map, _boundsCount]);

  useEffect(() => {
    if (!map) return;
    const observer = new ResizeObserver(() => {
      refreshBounds((c) => c + 1);
      setTimeout(() => map.resize(), 0);
    });

    // Start observing the element with the ref
    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    // Cleanup function to stop observing on unmount
    return () => observer.disconnect();
  }, [map]);

  const [mapImageUrl, setImageUrl] = useState<string | undefined>(
    map ? map.getCanvas().toDataURL() : undefined,
  );

  useEffect(() => {
    if (!isPrinting || !map) return;
    setImageUrl(map.getCanvas().toDataURL());
    return () => {
      setImageUrl(undefined);
    };
  }, [isPrinting, map, setImageUrl]);

  return (
    <div
      ref={boxRef}
      style={{ position: "relative", flex: 1, display: "flex" }}
    >
      <MapboxMoveLogoToUpperLeft ref={mapContainer} />
      {!isPrinting && mapIsLoaded && <Map map={map!} park={park} />}
      {isPrinting && mapImageUrl && (
        <img
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          alt={"Park"}
          src={mapImageUrl}
        />
      )}
    </div>
  );
};

export const CableMatrixMapWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title=""
      id="Cable matrix map"
      fullscreen
      resetKeys={errorBoundaryResetKeys}
    >
      <CableMatrixMap />
    </SafeCard>
  );
};
