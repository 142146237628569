import React, { useEffect, useRef, useState } from "react";
import ColorPicker from "simple-color-picker";
import {
  ColorCircle,
  ColorSelectorWrapper,
  FeatureColorPickerContainer,
} from "./Color.style";

export const DEFAULT_COLOR = "#454545";

const ColorSelectorInner = ({
  color,
  setColor,
  setOpen,
  position,
}: {
  color?: string;
  setColor: (c: string | number) => void;
  setOpen: (o: boolean) => void;
  position?: "left" | "right";
}) => {
  const colorPickerRef = useRef<HTMLDivElement | null>(null);
  const [colorPickerObj, setColorPickerObj] = useState<ColorPicker>();

  useEffect(() => {
    if (!colorPickerRef.current) return;
    const colorPicker = new ColorPicker({
      color,
      background: DEFAULT_COLOR,
      el: colorPickerRef.current,
      width: 200,
      height: 200,
    });
    setColorPickerObj(colorPicker);

    const closeWhenClickingOutside = (e: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !e.composedPath().includes(colorPickerRef.current)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mouseup", closeWhenClickingOutside);

    return () => {
      document.removeEventListener("mouseup", closeWhenClickingOutside);
      colorPicker.remove();
    };
  }, [colorPickerRef, color, setOpen]);

  useEffect(() => {
    const ref = colorPickerRef.current;
    if (!ref || !colorPickerObj) return;
    const onMouseUp = () => {
      setColor(colorPickerObj.getColor());
    };
    ref.addEventListener("mouseup", onMouseUp);
    return () => {
      ref.removeEventListener("mouseup", onMouseUp);
    };
  }, [colorPickerObj, setColor]);

  return (
    <FeatureColorPickerContainer
      ref={colorPickerRef}
      open={true}
      position={position}
    />
  );
};

const ColorSelector = ({
  color,
  setColor,
  position = "right",
  disabled,
}: {
  color?: string;
  setColor: (c: string | number) => void;
  position?: "left" | "right";
  disabled?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <ColorSelectorWrapper title={"Color"}>
      <ColorCircle
        disabled={disabled}
        color={color}
        onClick={() => {
          if (disabled) return;
          setOpen(true);
        }}
      />
      {open && (
        <ColorSelectorInner
          color={color}
          setOpen={setOpen}
          setColor={setColor}
          position={position}
        />
      )}
    </ColorSelectorWrapper>
  );
};

export default ColorSelector;
