import { ValidationWarning } from "state/validationWarnings";
import { ValidationWarningTypes } from "./utils";
import { checkForCycles } from "components/Cabling/CableWalk";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { cablesInParkFamily } from "state/jotai/cable";

export const cableLoopsFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<undefined | ValidationWarning<ValidationWarningTypes.CableLoops>>
    >(async (get) => {
      const cables = (
        await get(cablesInParkFamily({ parkId, branchId }))
      ).filter((c) => !c.properties.redundancy);
      const looping = checkForCycles(cables);
      if (!looping) return undefined;
      return {
        type: ValidationWarningTypes.CableLoops,
        featureIds: [looping.id],
      };
    }),
);
