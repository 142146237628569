import { useAtomValue } from "jotai";
import styled from "styled-components";
import { typography } from "styles/typography";
import { usersInOrganisationState } from "components/Organisation/state";
import { OrganisationUser } from "types/customer";
import { Row } from "components/General/Layout";
import UserImageRound from "components/UserImage/UserImageRound";
import React from "react";
import { colors } from "styles/colors";
import { customerProjectAtomFamily } from "state/timeline";

export const HiddenIfNotChecked = styled.div`
  display: flex;
  align-items: center;
`;

export const Table = styled.table`
  ${HiddenIfNotChecked}:not([data-checked=true]) {
    visibility: hidden;
  }

  tbody {
    tr:hover {
      ${HiddenIfNotChecked} {
        visibility: visible;
      }

      background-color: ${colors.surfaceHover};
    }

    // First cell in each row
    tr td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    // Last cell in each row
    tr td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const HeaderColumn = styled.p`
  ${typography.sub2};
`;

export const StickyThead = styled.thead`
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${colors.blue500};
  }
`;

const Name = styled.p`
  ${typography.body}
  text-transform: capitalize;
`;

export const UserInfo = ({
  organisationId,
  userId,
}: {
  organisationId: string;
  userId: string;
}) => {
  const allMembersRaw = useAtomValue(usersInOrganisationState(organisationId));

  const user = allMembersRaw.find(
    (m: OrganisationUser) => m.user_id === userId,
  );

  return (
    <Row alignCenter>
      {user ? (
        <>
          <UserImageRound user={user} size={1.6} />
          <p>{user.nickname}</p>
        </>
      ) : (
        <p>User not found</p>
      )}
    </Row>
  );
};

export const UserInfoBigger = ({
  organisationId,
  userId,
}: {
  organisationId: string;
  userId: string;
}) => {
  const allMembersRaw = useAtomValue(usersInOrganisationState(organisationId));

  const user = allMembersRaw.find(
    (m: OrganisationUser) => m.user_id === userId,
  );

  return (
    <Row alignCenter>
      {user ? (
        <>
          <UserImageRound user={user} size={3.2} />
          <Name>{user.nickname}</Name>
        </>
      ) : (
        <p>User not found</p>
      )}
    </Row>
  );
};

export const SelectedLayersWrapper = styled.div`
  border-top: 1px solid ${colors.borderSubtle};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.6rem 7rem 1.6rem 1.6rem;
  gap: 1.2rem;
  color: ${colors.white};

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const LimitAccessSecondaryText = styled.span`
  ${typography.caption}
  margin: 0;
  white-space: nowrap;
  color: ${colors.textDisabled};
`;

export const ProjectInfo = ({ projectId }: { projectId: string }) => {
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  return (
    <div>
      {project?.name ?? (
        <LimitAccessSecondaryText>
          No access to project
        </LimitAccessSecondaryText>
      )}
    </div>
  );
};
