import * as spec from "api/lcoe";
import { z } from "zod";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { scream } from "utils/sentry";

export const _ProcurementCost = spec.components.schemas.procurementCost;
export type ProcurementCost = z.infer<typeof _ProcurementCost>;
export const _FoundationProcurementCost =
  spec.components.schemas.foundationProcurementCost;
export const _TurbineProcurementCost =
  spec.components.schemas.turbineProcurementCost;
export type TurbineProcurementCost = z.infer<typeof _TurbineProcurementCost>;

export const _InterArrayCableProcurementCost =
  spec.components.schemas.interArrayCableProcurementCost;

export const _ExportCableProcurementCost =
  spec.components.schemas.exportCableProcurementCost;

export type ProcurementCostComponentType = z.infer<
  typeof spec.components.schemas.componentType
>;
export type FoundationProcurementCost = z.infer<
  typeof _FoundationProcurementCost
>;
export type _TurbineProcurementCost = z.infer<typeof _TurbineProcurementCost>;
export type InterArrayCableProcurementCost = z.infer<
  typeof _InterArrayCableProcurementCost
>;
export type ExportCableProcurementCost = z.infer<
  typeof _ExportCableProcurementCost
>;
export type _FoundationProcurementCost = z.infer<
  typeof _FoundationProcurementCost
>;
const _FoundationProcurementCostResponse =
  spec.components.schemas.foundationProcurementCostResponse;
export type Regions = z.infer<typeof spec.components.schemas.regions>;

const _ShippingCost = spec.components.schemas.shippingCost;
export type ShippingCost = z.infer<typeof _ShippingCost>[number];

export async function listProcurementCosts(
  organisationId: string,
): Promise<ProcurementCost[]> {
  const res = await fetchSchemaWithToken(
    z.array(_ProcurementCost),
    `/api/cost/organisation/${organisationId}/procurementCosts`,
    {
      method: "get",
    },
  );
  return res;
}

export async function updateProcurementCost(
  organisationId: string,
  componentType: ProcurementCostComponentType,
  procurementCosts: ProcurementCost[],
): Promise<ProcurementCost[]> {
  return await fetchSchemaWithToken(
    z.array(_ProcurementCost),
    `/api/cost/organisation/${organisationId}/procurementCosts/update/${componentType}`,
    {
      method: "put",
      body: JSON.stringify(procurementCosts),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function deleteProcurementCost(
  organisationId: string,
  procurementCostId: string,
  componentType: ProcurementCostComponentType,
) {
  return await fetchEnhancerWithToken(
    `/api/cost/organisation/${organisationId}/procurementCosts/${procurementCostId}/${componentType}`,
    { method: "delete" },
  );
}

export async function listProcurementCostForLibraryTurbinesOnNode(
  nodeId: string,
): Promise<TurbineProcurementCost[]> {
  const res = await fetchSchemaWithToken(
    z.array(_TurbineProcurementCost),
    `/api/cost/procurementCosts/libraryaccess/node/${nodeId}/turbine`,
    {
      method: "get",
    },
  ).catch((e) => {
    scream(
      "Failed to list procurement costs for library turbines for project",
      e,
    );
    throw Error(
      "Failed to list procurement costs for library turbines for project.",
    );
  });
  return res;
}

export async function listProcurementCostForLibraryInterArrayCablesOnNode(
  nodeId: string,
): Promise<InterArrayCableProcurementCost[]> {
  const res = await fetchSchemaWithToken(
    z.array(_InterArrayCableProcurementCost),
    `/api/cost/procurementCosts/libraryaccess/node/${nodeId}/cable`,
    {
      method: "get",
    },
  ).catch((e) => {
    scream(
      "Failed to list procurement costs for library inter array cables for project",
      e,
    );
    throw Error(
      "Failed to list procurement costs for library inter array cables for project.",
    );
  });
  return res;
}

export async function listProcurementCostForLibraryExportCablesOnNode(
  nodeId: string,
): Promise<ExportCableProcurementCost[]> {
  const res = await fetchSchemaWithToken(
    z.array(_ExportCableProcurementCost),
    `/api/cost/procurementCosts/libraryaccess/node/${nodeId}/exportCable`,
    {
      method: "get",
    },
  ).catch((e) => {
    scream(
      "Failed to list procurement costs for library export cables for project",
      e,
    );
    throw Error(
      "Failed to list procurement costs for library export cables for project.",
    );
  });
  return res;
}

export async function listProcurementCostForLibraryFoundationOnNode(
  nodeId: string,
): Promise<FoundationProcurementCost[]> {
  const res = await fetchSchemaWithToken(
    z.array(_FoundationProcurementCost),
    `/api/cost/procurementCosts/libraryaccess/node/${nodeId}/foundation`,
    {
      method: "get",
    },
  ).catch((e) => {
    scream(
      "Failed to list procurement costs for library foundations for project",
      e,
    );
    throw Error(
      "Failed to list procurement costs for library foundations for project.",
    );
  });
  return res;
}
