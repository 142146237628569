import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import Button from "components/General/Button";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useNavigate } from "react-router-dom";
import {
  adminInOrganisationSelectorFamily,
  loggedInUserIdAtom,
} from "state/user";

export default function ManageMyAccessButton() {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const navigate = useNavigate();
  const userId = useAtomValue(loggedInUserIdAtom);

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  if (!userId) return <></>;
  if (!isAdminInOrg) return <></>;

  return (
    <Button
      text="Manage my access"
      onClick={() => {
        navigate(`/organisation/${organisationId}/members`, {
          replace: false,
        });
        setContent({
          type: "user",
          id: userId,
          meta: {
            showLibraryAccess: true,
          },
        });
      }}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        zIndex: 100,
      }}
    />
  );
}
