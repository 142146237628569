import Cabling from "@icons/24/Cabling.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import { useAtomValue } from "jotai";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import InstallationSeason from "./InstallationSeason";
import { DividerWrapper, InstallationDivider } from "../styles";
import { SubHeader } from "components/ConfigurationModal/shared";
import Dropdown from "components/Dropdown/Dropdown";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { projectIdAtom } from "state/pathParams";
import { isCableLayVessel } from "services/vesselService";
import WeatherLimits from "./WeatherLimits";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { getValue } from "components/InputChangelog/utils";
import { Row } from "components/General/Layout";

const CableSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantVesselTypes = Array.from(vesselTypes.values()).filter(
    isCableLayVessel,
  );
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { installationVessel } = localConfig.ti.cables;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Installation vessel</SubHeader>
        <SubHeader>Day rate</SubHeader>
        <ChangelogAndCommentWrapper
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            "ti.cables.installationVessel.vesselId",
            "project",
            (obj) => {
              const vesselId = getValue(
                obj,
                "ti.cables.installationVessel.vesselId",
              );
              const vesselName = vesselTypes.get(vesselId)?.name;
              return { "vessel type": vesselName };
            },
          )}
          disabled={isReadOnly}
        >
          <Dropdown
            style={{ width: "20rem" }}
            disabled={isReadOnly}
            value={installationVessel.vesselId}
            onChange={(e) =>
              updateTIVessels({
                cables: {
                  ...localConfig.ti.cables,
                  installationVessel: {
                    ...installationVessel,
                    vesselId: e.target.value,
                  },
                },
              })
            }
          >
            {relevantVesselTypes.map((vessel) => {
              return (
                <option key={vessel.id} value={vessel.id}>
                  {vessel.name}
                </option>
              );
            })}
          </Dropdown>
        </ChangelogAndCommentWrapper>
        <InputDimensioned
          style={{ width: "20rem" }}
          disabled={isReadOnly}
          unit={installationVessel.dayRate.unit}
          value={installationVessel.dayRate.cost}
          step={0.1}
          validate={(e) => 0 <= e}
          validationMessage={`Needs to be greater than 0`}
          initialValue={100}
          onChange={(cost) =>
            updateTIVessels({
              cables: {
                ...localConfig.ti.cables,
                installationVessel: {
                  ...installationVessel,
                  dayRate: {
                    ...installationVessel.dayRate,
                    cost,
                  },
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const CableVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;
  const { startMonth, endMonth } = localConfig.ti.cables.installationSeason;

  return (
    <InstallationCategoryEntry
      title="Inter array cables"
      icon={<Cabling />}
      defaultOpen={false}
    >
      <CableSpecificVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <WeatherLimits
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={"cables"}
        weatherLimits={localConfig.ti.cables.weatherLimits}
      />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <InstallationSeason
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        startMonth={startMonth}
        endMonth={endMonth}
        category={"cables"}
      />
    </InstallationCategoryEntry>
  );
};
