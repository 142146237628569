import {
  getProjectElementsFolders,
  getProjectElementsSortOrder,
  ProjectElementFolderType,
} from "./service";
import { isDefined } from "utils/predicates";
import { ProjectFeature } from "types/feature";
import { ElementTreeNode, ElementTreeRoot } from "./types";
import { atomFamily, atomFromFn } from "utils/jotai";
import { atom } from "jotai";
import { featuresFamily } from "state/jotai/features";

export const projectFoldersAtomFamily = atomFamily(
  ({
    projectId,
    branchId,
    version,
  }: {
    projectId: string;
    branchId: string;
    version: undefined | number;
  }) =>
    atomFromFn<Promise<ProjectElementFolderType[]>>(async (get) => {
      get(projectFoldersRefreshAtom);
      const folders = await getProjectElementsFolders(
        projectId,
        branchId,
        version,
      );
      return folders.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
    }),
);

export const projectFoldersRefreshAtom = atom(1);

/**
 * Sort order of top level elements in a branch.
 */
export const getProjectElementsSortOrderAtomFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string; branchId: string }) =>
    atomFromFn(() => getProjectElementsSortOrder(nodeId, branchId)),
);

const elementFeaturesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<ProjectFeature[]>>(async (get) => {
      const f = await get(featuresFamily({ branchId }));
      const ret: ProjectFeature[] = [];
      return (
        ret
          .concat(f.park)
          .concat(f.other)
          .concat(f.bathymetry)
          .concat(f.geotiff)
          .concat(f.viewpoint)
          .concat(f.sensor)
          .concat(f.port)
          .concat(f.existingTurbine)
          // .concat(f.gridConnection)
          .concat(f.exclusionZone)
      );
    }),
);

/**
 * Gets a tree representation of the Elements list.
 */
export const elementTreeSelectorFamily = atomFamily(
  ({ nodeId, branchId }: { nodeId: string; branchId: string }) =>
    atom(async (get) => {
      const folders = await get(
        projectFoldersAtomFamily({
          projectId: nodeId,
          branchId,
          version: undefined,
        }),
      );
      const features = await get(elementFeaturesFamily({ branchId }));

      const root: ElementTreeRoot = { type: "root", children: [] };

      // Construct a node for every folder and feature (that should be a node)
      const id2node = new Map<string, ElementTreeNode>(
        folders
          .map<[string, ElementTreeNode]>((f) => [
            f.folderId,
            {
              type: "folder" as const,
              id: f.folderId,
              folder: f,
              children: [],
              parent: root,
            },
          ])
          .concat(
            features.map((feature) => [
              feature.id,
              {
                type: "feature" as const,
                id: feature.id,
                feature,
                parent: root,
              },
            ]),
          ),
      );

      // Candidates for nodes on the top level.
      const topLevelIds = new Set(
        folders.map((f) => f.folderId).concat(features.map((f) => f.id)),
      );
      const placed = new Set<string>(); // Safeguard, in case an ID appears in multiple folders

      for (const folder of folders) {
        const f = id2node.get(folder.folderId);
        if (!f || f.type !== "folder") continue; // NOTE: f is always a folder, but TS doesn't know it
        // NOTE: This is where we decide the ordering of the folder contents.
        // It's just the `featureIds` array.
        f.children = folder.featureIds
          .map((o) => {
            if (o.id === f.id) return;
            if (placed.has(o.id))
              // Check that we only insert a node once in the tree
              return;
            placed.add(o.id);
            topLevelIds.delete(o.id); // register this id as in a folder
            const node = id2node.get(o.id);
            if (node) node.parent = f;
            return node;
          })
          .filter(isDefined);
      }

      // Sort the top level, since this is handled by a separate endpoint
      const sortOrder = await get(
        getProjectElementsSortOrderAtomFamily({ nodeId, branchId }),
      );
      const id2order = new Map(sortOrder.map((so) => [so.id, so.sortOrder]));

      const topNodes = [...topLevelIds.values()]
        .map((id) => id2node.get(id))
        .filter(isDefined);

      topNodes.sort((a, b) => {
        const ka = id2order.get(a.id) ?? -1;
        const kb = id2order.get(b.id) ?? -1;
        return ka - kb;
      });

      root.children = topNodes;

      return root;
    }),
);
