import { z } from "zod";
import { WakeAnalysisConfiguration } from "../services/configurationService";
import {
  fetchEnhancerWithToken,
  fetchSchemaWithToken,
} from "../services/utils";
import {
  DEFAULT_ONSHORE_OFFSHORE_TURBINES,
  SimpleTurbineType,
} from "../types/turbines";
import {
  ExistingTurbineFeature,
  ParkFeature,
  TurbineFeature,
} from "../types/feature";
import { reduceCoordinatePrecision } from "../utils/geojson/utils";
import {
  _SpatialCalibration,
  WindSourceConfiguration,
} from "services/windSourceConfigurationService";
import { Position } from "@turf/turf";
import { _MeanSpeedGrid } from "state/jotai/windStatistics";
import Semaphore from "utils/semaphore";
import { isDefined } from "utils/predicates";
import {
  ExportSystemAnalysisSuccessResult,
  InterArrayAnalysisSuccessResult,
} from "analysis/electrical";

const _Percentile = z.object({
  percentile: z.number(),
  value: z.number(),
});

export type Percentile = z.infer<typeof _Percentile>;

const _Loss = z.object({
  fraction: z
    .number()
    .describe("Loss in fraction, on average. Multiply with 100 to get %"),
  mw: z.number().describe("Loss in MW, on average"),
  gwh: z.number().describe("Loss in GWh, yearly"),
});

const _Histogram = z.object({
  bins: z.number().array().describe("Bins"),
  values: z.number().array().describe("Values"),
});

const _InterArrayStats = z.object({
  interArrayLoss: _Loss.describe("Total inter-array loss"),
  interArrayTrafoLoss: _Loss.describe("Total inter-array trafo loss"),
  interArrayCableLoss: _Loss.describe("Total inter-array cable loss"),
  interArrayCableLossPerBin: z
    .number()
    .array()
    .describe("Inter-array cable loss per bin in MW"),
  interArrayTrafoLossPerBin: z
    .number()
    .array()
    .describe("Inter-array trafo loss per bin in MW"),
  powerBins: z.number().array().describe("Power bins in MW"),
});

const _ExportSystemStats = z.object({
  exportSystemLoss: _Loss.describe("Total export system loss"),
  exportSystemCableLoss: _Loss.describe("Total export system cable loss"),
  exportSystemOnshoreTrafoLoss: _Loss.describe(
    "Total export system onshore trafo loss",
  ),
  exportSystemOffshoreTrafoLoss: _Loss.describe(
    "Total export system offshore trafo loss",
  ),
  exportCableCurrents: z.record(z.number().array()),
  exportCableSegLengths: z.record(z.number().array()),
  exportSystemVoltages: z.record(z.number().array()),
});

const _PercentileStats = z.object({
  netEnergyPerYear: z.number().array().describe("Net energy per year in GWh"),
  netEnergyPerMonth: z.number().array().describe("Net energy per month in GWh"),
  netEnergyPerYearPercentiles: _Percentile
    .array()
    .describe("Net energy per year percentiles"),
  netEnergyCDF: _Histogram.describe("Net energy histogram in MW"),
});

const _NetEnergyStats = z.object({
  aep: z.number().describe("Net Annual Energy Production (GWh)"),
  maxPower: z.number().describe("Net Maximum Power (MW)"),
  percentiles: _PercentileStats,
});

const _TurbineStats = z.object({
  grossPerTurbine: z
    .number()
    .array()
    .describe("Gross annual energy production in GWh"),
  turbineSpecificLossPerTurbine: z
    .number()
    .array()
    .describe("Turbine specific loss in fraction"),
  totalWakeLossPerTurbine: z
    .number()
    .array()
    .describe("Total wake loss in fraction"),
  internalWakeLossPerTurbine: z
    .number()
    .array()
    .describe("Total internal wake loss in fraction"),
  averageAmbientWindSpeedPerTurbine: z
    .number()
    .array()
    .describe("Average ambient wind speed per turbine in m/s"),
});

const _Stats = z.object({
  net: _NetEnergyStats,
  turbineStats: _TurbineStats,
  otherLoss: _Loss.describe("Other losses in fraction"),
  interArrayStats: _InterArrayStats.nullish(),
  exportSystemStats: _ExportSystemStats.nullish(),
});

export const _AnalysisStatus = z.object({
  id: z.string(),
  status: z.enum(["running", "failed", "stopped", "complete", "started"]),
  version: z.string(),
  reason: z.string().nullish().optional(),
  progress: z.number().optional(), // 0 - 1
});

export const _PresignedUrlResponse = z.object({
  url: z.string(),
});

const GwaSpeedCalibration = z.object({
  type: z.literal("global_wind_atlas"),
  value: z.enum(["absolute", "relative"]),
});

const NewaSpeedCalibration = z.object({
  type: z.literal("new_european_wind_atlas"),
  value: z.enum(["absolute", "relative"]),
});

const CustomSpeedCalibration = z.object({
  type: z.literal("custom"),
  value: z.enum(["absolute", "relative"]),
  id: z.string(),
});

// TODO: Verify format from backend
const SpatialCalibration = z.union([
  GwaSpeedCalibration,
  NewaSpeedCalibration,
  CustomSpeedCalibration,
]);

const _AnalysisWindStats = z.object({
  meanSpeed: z.number(),
  speedBins: z.number().array(),
  speedBinProbabilities: z.number().array(),
  directionBins: z.number().array(),
  directionProbabilities: z.number().array(),
  meanSpeedPerDirection: z.number().array(),
  source: z.string(),
  spatialCalibration: SpatialCalibration.optional().nullish(),
  longitude: z.number(),
  latitude: z.number(),
  meanAlpha: z.number(),
  meanDensity: z.number(),
  height: z.number(),
  fromYear: z.number(),
  toYear: z.number(),
});

export type AnalysisStatus = z.infer<typeof _AnalysisStatus>;
export type Stats = z.infer<typeof _Stats>;
export type PresignedUrlResponse = z.infer<typeof _PresignedUrlResponse>;
export type AnalysisWindStats = z.infer<typeof _AnalysisWindStats>;

const _AnalysisVersion = z.object({ version: z.string() });

export async function fetchLatestAnalysisVersion(): Promise<string> {
  return fetchEnhancerWithToken(`/api/octopus/layout/analysis/version`, {
    method: "get",
  })
    .then((res) => res.json())
    .then(_AnalysisVersion.parse)
    .then((v) => v.version);
}

// Only run 5 analyses at a time
const triggerAnalysisQueue = new Semaphore(5);
export async function triggerAnalysis({
  nodeId,
  turbines,
  neighbourTurbines,
  existingTurbines,
  wakeSettings,
  windConfiguration,
  hubHeightOverride,
  turbineTypeOverride,
  park,
  parkCenter,
  restart,
}: {
  nodeId: string;
  turbines: TurbineFeature[];
  neighbourTurbines: TurbineFeature[];
  existingTurbines: ExistingTurbineFeature[];
  wakeSettings: WakeAnalysisConfiguration;
  windConfiguration: WindSourceConfiguration;
  hubHeightOverride?: number;
  turbineTypeOverride?: SimpleTurbineType;
  park: ParkFeature;
  parkCenter: Position;
  restart?: boolean;
}): Promise<AnalysisStatus> {
  let turbineTypes;

  if (turbineTypeOverride) {
    const turbineIds = [turbineTypeOverride.id].concat(
      neighbourTurbines.map((t) => t.properties.turbineTypeId),
    );
    turbineTypes = Object.fromEntries(
      DEFAULT_ONSHORE_OFFSHORE_TURBINES.filter((t) =>
        turbineIds.includes(t.id),
      ).map((t) => [t.id, t]),
    );
  } else {
    const turbineIds = turbines
      .map((t) => t.properties.turbineTypeId)
      .concat(neighbourTurbines.map((t) => t.properties.turbineTypeId));
    turbineTypes = Object.fromEntries(
      DEFAULT_ONSHORE_OFFSHORE_TURBINES.filter((t) =>
        turbineIds.includes(t.id),
      ).map((t) => [t.id, t]),
    );
  }

  const settings = {
    wakeModel: wakeSettings.wakeModel,
    wakeExpansionFactor: wakeSettings.wakeExpansionFactor,
    turbulenceIntensity: wakeSettings.turbulenceIntensity,
    blockage: wakeSettings.blockage,
    density: wakeSettings.density,
    precision: wakeSettings.precision,
    turboparkWakeExpansionCalibration:
      wakeSettings.turboparkWakeExpansionCalibration,
    hubHeightOverride: hubHeightOverride ?? null,
  };

  const allNeighbours = neighbourTurbines
    .map((t) => {
      const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 8);
      return {
        lon,
        lat,
        type: t.properties.turbineTypeId,
      };
    })
    .concat(
      existingTurbines.map((t) => {
        const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 5);
        return {
          lon,
          lat,
          type: "generic",
          power: t.properties.power,
        };
      }),
    );

  const body = {
    turbines: turbines.map((t) => {
      const [lon, lat] = reduceCoordinatePrecision(t.geometry.coordinates, 8);
      return {
        lon,
        lat,
        type: turbineTypeOverride?.id ?? t.properties.turbineTypeId,
      };
    }),
    neighbourTurbines: allNeighbours,
    turbineTypes,
    settings,
    windConfiguration,
    parkPolygon: park.geometry.coordinates[0],
    parkCenter,
  };
  const params = new URLSearchParams();
  restart && params.set("restart", "true");

  await triggerAnalysisQueue.acquire();
  const response = await fetchEnhancerWithToken(
    `/api/octopus/layout/analysis/node/${nodeId}?${params}`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisStatus.parse(r))
    .finally(() => triggerAnalysisQueue.release());
  return response;
}

export async function fetchStats({
  nodeId,
  id,
  version,
  interArrayAnalysis,
  exportSystemAnalysis,
  otherLosses,
  maxNetPowerLimit,
}: {
  nodeId: string;
  id: string;
  version?: string;
  interArrayAnalysis: InterArrayAnalysisSuccessResult | null;
  exportSystemAnalysis: ExportSystemAnalysisSuccessResult | null;
  otherLosses: number;
  maxNetPowerLimit?: number;
}): Promise<Stats> {
  const params = new URLSearchParams({
    other_losses: otherLosses.toString(),
  });
  if (isDefined(maxNetPowerLimit)) {
    params.set("max_net_power_limit", maxNetPowerLimit.toString());
  }
  if (isDefined(version)) {
    params.set("version", version!);
  }
  if (interArrayAnalysis !== null) {
    params.set("inter_array_id", interArrayAnalysis.id);
    params.set("inter_array_version", interArrayAnalysis.version);
  }
  if (exportSystemAnalysis !== null) {
    params.set("export_system_id", exportSystemAnalysis.id);
    params.set("export_system_version", exportSystemAnalysis.version);
  }

  return fetchSchemaWithToken(
    _Stats,
    `/api/octopus/analysis/stats/${nodeId}/${id}/net_energy_v2?${params}`,
    {
      method: "get",
      headers: {},
    },
  );
}

export async function fetchAnalysisWindStats({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<AnalysisWindStats> {
  return fetchEnhancerWithToken(
    `/api/octopus/analysis/stats/${nodeId}/${id}/wind_stats?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => _AnalysisWindStats.parse(r));
}

export async function fetchAnalysisWindData({
  nodeId,
  id,
  version,
}: {
  nodeId: string;
  id: string;
  version: string;
}): Promise<PresignedUrlResponse> {
  return fetchEnhancerWithToken(
    `/api/octopus/analysis/stats/${nodeId}/${id}/wind_data_file?version=${version}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => {
      try {
        return _PresignedUrlResponse.parse(r);
      } catch (e) {
        console.log("Error parsing wind data file response", r, e);
        throw e;
      }
    });
}

export async function fetchAnalysisTimeseriesFile({
  nodeId,
  id,
  version,
  detailed,
}: {
  nodeId: string;
  id: string;
  version: string;
  detailed: boolean;
}): Promise<PresignedUrlResponse> {
  return fetchEnhancerWithToken(
    `/api/octopus/analysis/stats/${nodeId}/${id}/timeseries_file?version=${version}&detailed=${detailed}`,
    {
      method: "get",
      headers: {},
    },
  )
    .then((response) => response.json())
    .then((r) => {
      try {
        return _PresignedUrlResponse.parse(r);
      } catch (e) {
        console.log("Error parsing wind data file response", r, e);
        throw e;
      }
    });
}
