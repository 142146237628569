import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing6 } from "styles/space";
import { baselineComparisonValuesAtom } from "../state";
import SelectParksButton from "./SelectParks/SelectParksButton";
import SelectAttributes from "./SelectAttributes";
import ComparisonModeToggle from "./ComparisonModeToggle";
import { useAtomValue } from "jotai";

const ConfigurationBarWrapper = styled.div`
  display: flex;
  gap: 2rem;
  padding: ${spacing6};
  background-color: ${colors.surfaceSecondary};
  align-items: center;
`;

const ConfigurationBar = () => {
  const baselineComparisonValues = useAtomValue(baselineComparisonValuesAtom);

  return (
    <ConfigurationBarWrapper>
      <SelectParksButton />
      <SelectAttributes />
      {baselineComparisonValues && <ComparisonModeToggle />}
    </ConfigurationBarWrapper>
  );
};

export default ConfigurationBar;
