import { useAtomValue } from "jotai";
import { orgFinanicalManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibraryCostConfig } from "components/Ably/ChannelProviders/Organisation/Library/Financial/useAblyLibraryCostConfig";
import useAblyProcurementCost from "hooks/useAblyProcurementCost";
interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function FinancialLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgFinanicalManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_financial_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyFinancialChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyFinancialChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyProcurementCost(organisationId);
  useAblyLibraryCostConfig(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_financial_manage"}
    />
  );
}
