import { useState } from "react";
import { useAtomValue } from "jotai/index";
import { aset, useJotaiCallback } from "utils/jotai";
import { useToast } from "hooks/useToast";
import {
  FolderOrResourceItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import { RESET } from "jotai/utils";
import { sortFolderTree } from "business/folderStructure/utils";
import { Mixpanel } from "mixpanel";
import {
  createOrgFolder,
  deleteOrgFolder,
  deleteOrgResource,
  editOrgFolderName,
  moveOrgItems,
} from "services/orgFolderService";
import { orgFolderStructureFamily } from "state/orgFolderStructure";
import { organisationIdAtom } from "state/pathParams";
import { LibraryManageRole } from "components/Organisation/Library/types";

const useOrgFolderStructureCrud = ({
  libraryManageRole,
}: {
  libraryManageRole: string;
}) => {
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const orgId = useAtomValue(organisationIdAtom) ?? "";
  const { error: showError, success: showSuccess } = useToast();

  const updateLocal = useJotaiCallback(
    (get, set, updatedStructure: FolderOrResourceItem[]) => {
      return aset(
        get,
        set,
        orgFolderStructureFamily({ orgId, libraryManageRole }),
        () => {
          return updatedStructure.sort(sortFolderTree);
        },
      );
    },
    [orgId, libraryManageRole],
  );

  const createFolder = useJotaiCallback(
    async (
      get,
      set,
      name: string,
      libraryManageRole: LibraryManageRole,
      folderItems: FolderTreeItemOrResourceItem[],
      parentId?: string,
    ) => {
      setIsCreatingFolder(true);

      try {
        const newFolderStructure = await createOrgFolder(
          orgId,
          libraryManageRole,
          name,
          folderItems,
          parentId,
        );

        updateLocal(newFolderStructure);
        showSuccess("Folder created successfully");
      } catch (err) {
        showError("Could not create folder, please try again.");
      } finally {
        setIsCreatingFolder(false);
      }
    },
    [orgId, showError, showSuccess, updateLocal],
  );

  const deleteFolder = useJotaiCallback(
    async (get, set, folderId: string) => {
      const fallback = await get(
        orgFolderStructureFamily({ orgId, libraryManageRole }),
      );
      aset(
        get,
        set,
        orgFolderStructureFamily({ orgId, libraryManageRole }),
        (curr) => {
          const clone = curr.filter((f) => f.id !== folderId);
          return clone.sort(sortFolderTree);
        },
      );

      try {
        await deleteOrgFolder(orgId, libraryManageRole, folderId);
        showSuccess("Folder deleted successfully");
      } catch (err) {
        aset(
          get,
          set,
          orgFolderStructureFamily({ orgId, libraryManageRole }),
          () => fallback,
        );
        showError("Could not delete folder, please try again.");
      }
    },
    [orgId, showError, showSuccess, libraryManageRole],
  );

  const deleteResource = useJotaiCallback(
    async (get, set, resourceId: string) => {
      const fallback = await get(
        orgFolderStructureFamily({ orgId, libraryManageRole }),
      );
      aset(
        get,
        set,
        orgFolderStructureFamily({ orgId, libraryManageRole }),
        (curr) => {
          const clone = curr.filter((f) => f.id !== resourceId);
          return clone.sort(sortFolderTree);
        },
      );

      try {
        await deleteOrgResource(orgId, libraryManageRole, resourceId);
      } catch (err) {
        aset(
          get,
          set,
          orgFolderStructureFamily({ orgId, libraryManageRole }),
          () => fallback,
        );
      }
    },
    [orgId, libraryManageRole],
  );

  const editFolderName = useJotaiCallback(
    async (get, set, folderId: string, name: string) => {
      const fallback = await get(
        orgFolderStructureFamily({ orgId, libraryManageRole }),
      );
      aset(
        get,
        set,
        orgFolderStructureFamily({ orgId, libraryManageRole }),
        (curr) => {
          const clone = [...curr];
          const folderItem = clone.find((f) => f.id === folderId);
          if (folderItem) {
            folderItem.name = name;
          }
          return clone.sort(sortFolderTree);
        },
      );

      try {
        await editOrgFolderName(orgId, libraryManageRole, folderId, name);
        showSuccess("Folder name updated successfully");
      } catch (err) {
        showError("Could not edit folder name, please try again.");
        aset(
          get,
          set,
          orgFolderStructureFamily({ orgId, libraryManageRole }),
          () => fallback,
        );
      }
    },
    [orgId, showError, showSuccess, libraryManageRole],
  );

  const moveItems = useJotaiCallback(
    async (
      get,
      set,
      folderItems: Partial<FolderTreeItemOrResourceItem>[],
      parentId: string | undefined,
    ) => {
      Mixpanel.track_old(`Branch folders - Move items`, {
        nrFolderItems: folderItems.length,
        hasParent: !!parentId,
      });
      const fallback = await get(
        orgFolderStructureFamily({ orgId, libraryManageRole }),
      );

      aset(
        get,
        set,
        orgFolderStructureFamily({ orgId, libraryManageRole }),
        (curr) => {
          const clone = [...curr];
          for (const item of folderItems) {
            const folderItem = clone.find((f) => f.id === item.id);
            if (folderItem) {
              folderItem.parentId = parentId;
              if (typeof item.sortOrder === "number") {
                folderItem.sortOrder = item.sortOrder;
              }
            } else {
              clone.push(item as FolderTreeItemOrResourceItem);
            }
          }
          return clone.sort(sortFolderTree);
        },
      );

      try {
        await moveOrgItems(orgId, libraryManageRole, folderItems, parentId);
        showSuccess(`${folderItems.length} item(s) moved successfully`);
      } catch (e) {
        showError("Could not move item(s)");
        aset(
          get,
          set,
          orgFolderStructureFamily({ orgId, libraryManageRole }),
          () => fallback,
        );
      }
    },
    [orgId, showError, showSuccess, libraryManageRole],
  );

  const refreshFolders = useJotaiCallback(
    async (get, set) => {
      set(orgFolderStructureFamily({ orgId, libraryManageRole }), RESET);
    },
    [orgId, libraryManageRole],
  );

  return {
    createFolder,
    editFolderName,
    moveItems,
    deleteFolder,
    deleteResource,
    isCreatingFolder,
    refreshFolders,
    updateLocal,
  };
};

export default useOrgFolderStructureCrud;
