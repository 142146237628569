import { useState } from "react";
import { Input } from "../General/Input";
import styled from "styled-components";
import Button from "../General/Button";
import CloseIcon from "@icons/24/Close.svg?react";

const ActiveFilterButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  flex-wrap: wrap;
  button {
    margin: 0;
    text-transform: capitalize;
  }
`;

const inputStyle: React.CSSProperties = {
  background: "white",
  paddingRight: "4rem",
  width: "100%",
};

const TaggingField = ({
  tags,
  setTags,
  placeholder,
}: {
  tags: Set<string>;
  setTags: (tags: Set<string>) => void;
  placeholder?: string;
}) => {
  const [newTag, setNewTag] = useState<string>("");
  return (
    <>
      <Input
        placeholder={placeholder}
        style={inputStyle}
        onChange={(e) => setNewTag(e.target.value)}
        onEnter={() => {
          setTags(new Set(tags).add(newTag));
          setNewTag("");
        }}
        value={newTag}
      />
      <ActiveFilterButtonsWrapper>
        {Array.from(tags).map((t) => (
          <Button
            buttonType="secondary"
            iconPosition="right"
            key={t}
            text={t}
            icon={<CloseIcon style={{ width: "8px" }} />}
            style={{ flexShrink: 0, flexGrow: 1 }}
            onClick={() => {
              const newTags = new Set(tags);
              newTags.delete(t);
              setTags(newTags);
            }}
            size="small"
            title="Remove filter"
          />
        ))}
      </ActiveFilterButtonsWrapper>
    </>
  );
};

export default TaggingField;
