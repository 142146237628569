import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { colors } from "../../styles/colors";
import { MenuItem } from "../General/Menu";
import { DotMenu } from "../General/MenuButton";
import BinIcon from "@icons/24/Bin.svg";
import ShareIcon from "@icons/24/Share.svg";
import MoveToFolderIcon from "@icons/14/MoveToFolder.svg";
import StarIcon from "@icons/24/Star.svg";
import InfoIcon from "@icons/24/Information.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import { ProjectMeta } from "../../types/api";
import { useCallback, useMemo } from "react";
import { followProjectsAtom } from "../../state/project";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import {
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { Node } from "../../services/customerAPI";
import {
  OrganisationNodeCrud,
  topLevelNodeFromAllNodesInStateAtomFamily,
} from "./useOrganisationFolderCrud";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { moveProjectIdAtom } from "components/Organisation/MoveNodeModal/state";
import {
  ConfirmContentText,
  useConfirm,
} from "components/ConfirmDialog/ConfirmDialog";

export const ProjectDotMenu = ({
  project,
  crudNode,
  setIsEditingTitle,
  onChange,
}: {
  project: ProjectMeta;
  currentFolder: Node | undefined;
  crudNode: OrganisationNodeCrud;
  setIsEditingTitle: (value: boolean) => void;
  nestedDirection?: Parameters<typeof MenuItem>[0]["direction"];
  onChange?: (open: boolean) => void;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setMoveProjectId = useSetAtom(moveProjectIdAtom);
  const { showConfirm } = useConfirm();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const toplevelNode = useAtomValue(
    topLevelNodeFromAllNodesInStateAtomFamily({
      organisationId,
      nodeId: project.id,
    }),
  );

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: project.id,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;
  const isNodeEditor = nodeAccess >= 1;

  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const { put: putFollow } = useFollowProjectCrud();

  const follows = useAtomValue(followProjectsAtom);
  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === project.id && f.follow),
    [follows, project],
  );

  const deleteProjectCallback = useCallback(async () => {
    if (
      !(await showConfirm({
        title: "Delete project",
        message: (
          <ConfirmContentText>
            You are about to <b>permanently delete</b> the project "
            {project.name}". This action can not be undone. Are you sure you
            want to continue?
          </ConfirmContentText>
        ),
        confirmButtonText: "Delete",
      }))
    ) {
      return;
    }
    await crudNode.remove(project.id);
    setContent((curr) =>
      curr?.type === "project" && curr.id === project.id ? undefined : curr,
    );
  }, [crudNode, project.id, project.name, setContent, showConfirm]);

  const chosenProjectHasPersonalTopFolder =
    toplevelNode?.type === "personal_folder";

  return (
    <DotMenu onChange={onChange} size="medium">
      {isMemberInOrg && (
        <MenuItem
          name="Project information"
          icon={<InfoIcon />}
          onClick={() => {
            setContent({
              type: "project",
              id: project.id,
            });
          }}
        />
      )}

      {isNodeAdmin && !chosenProjectHasPersonalTopFolder && (
        <>
          <MenuItem
            name="Share project"
            icon={<ShareIcon />}
            onClick={() => {
              setContent({
                type: "project",
                id: project.id,
                meta: {
                  openAddCollaborators: true,
                },
              });
            }}
          />
        </>
      )}
      <MenuItem
        name="Move project"
        icon={<MoveToFolderIcon />}
        disabled={!isNodeAdmin}
        tooltip={
          !isNodeAdmin ? "You need to be an admin to move a project" : undefined
        }
        onClick={() => setMoveProjectId(project.id)}
      />
      <MenuItem
        name="Rename project"
        icon={<PencilIcon />}
        disabled={!isNodeEditor}
        tooltip={
          !isNodeEditor
            ? "You need to be an editor to rename a project"
            : undefined
        }
        onClick={() => {
          setIsEditingTitle(true);
        }}
      />
      <MenuItem
        name={isFollowed ? "Unstar project" : "Star project"}
        icon={
          <StarIcon
            stroke={isFollowed ? colors.surfaceBrand : colors.surfaceBrand}
            fill={isFollowed ? colors.surfaceBrand : "transparent"}
          />
        }
        onClick={() => {
          putFollow(project.id, !isFollowed);
        }}
      />

      <MenuItem
        name="Delete project"
        icon={<BinIcon />}
        disabled={!isNodeAdmin}
        tooltip={
          !isNodeAdmin
            ? "You need to be an admin to delete a project"
            : undefined
        }
        onClick={() => {
          deleteProjectCallback();
        }}
      />
    </DotMenu>
  );
};
