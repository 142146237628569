import React, { useCallback, useMemo, useRef, useState } from "react";
import Spinner from "@icons/spinner/Spinner";
import UserImageRound from "./UserImageRound";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { OtherUserMetaInfo } from "types/user";
import { dedup } from "utils/utils";
import { Anchor } from "components/General/Anchor";
import { Column, Frame } from "components/General/Layout";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import { Row } from "components/Design/styles";
import { spacing4 } from "styles/space";

const StyledRow = styled.div<{
  size: number;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(p) => p.size / 3}rem;

  > * {
    margin-left: -${(p) => p.size / 3}rem;
    z-index: 1;
  }

  cursor: ${(p) => (p.onClick ? "pointer" : "inherit")};
`;

const StyledProfileImage = styled.div<{ size: number }>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
  border-radius: ${(p) => p.size / 2}rem;
  background-color: ${colors.hover};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoUsersText = styled.p`
  ${typography.contentAndButtons}
  margin: 0;
  color: ${colors.secondaryText};
`;

const ContentTableColumn = styled(Column)`
  gap: 0;
  padding-left: 0;
  padding-right: 0;

  & > :nth-child(2n + 1) {
    background-color: ${colors.surfaceSecondary};
  }
`;

const DEFAULT_MAX = 4;

function StackedUserImages<T extends OtherUserMetaInfo>({
  users,
  maxNumberOfUsers = DEFAULT_MAX,
  size = 2.4,
  onClick,
  noUsersText = "No members yet",
  renderPopupRowRightSide,
}: {
  users: T[];
  maxNumberOfUsers?: number;
  size?: number;
  onClick?: () => void;
  noUsersText?: string;
  renderPopupRowRightSide?: (user: T) => React.ReactNode;
}) {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const showPopupTimeoutId = useRef<NodeJS.Timeout>();
  const hidePopupTimeoutId = useRef<NodeJS.Timeout>();
  const rowRef = useRef<HTMLDivElement>(null);
  const uniqueUsersToShow = useMemo(() => {
    return dedup(users, (user) => user.user_id).slice(0, maxNumberOfUsers);
  }, [maxNumberOfUsers, users]);

  const additionalUsersCount = users.length - maxNumberOfUsers;

  const onMouseOver = useCallback(() => {
    clearTimeout(hidePopupTimeoutId.current);
    showPopupTimeoutId.current = setTimeout(() => {
      setPopupIsVisible(true);
    }, 200);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (!popupIsVisible) {
      clearTimeout(showPopupTimeoutId.current);
    } else {
      hidePopupTimeoutId.current = setTimeout(() => {
        setPopupIsVisible(false);
        clearTimeout(showPopupTimeoutId.current);
      }, 200);
    }
  }, [popupIsVisible]);

  if (users.length === 0) {
    return <NoUsersText>{noUsersText}</NoUsersText>;
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <StyledRow
        ref={rowRef}
        size={size}
        onMouseEnter={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={
          onClick
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
              }
            : undefined
        }
      >
        {uniqueUsersToShow.map((user) => {
          return <UserImageRound key={user.user_id} user={user} size={size} />;
        })}
        {additionalUsersCount > 0 && (
          <StyledProfileImage size={size}>
            +{additionalUsersCount}
          </StyledProfileImage>
        )}
      </StyledRow>
      {popupIsVisible && (
        <Anchor baseRef={rowRef} basePlace="bottom" floatPlace="topLeft">
          <Frame
            style={{
              overflowY: "auto",
              maxHeight: "50vh",
              maxWidth: "30rem",
              width: "auto",
              padding: 0,
            }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              clearTimeout(hidePopupTimeoutId.current);
            }}
            onMouseLeave={onMouseLeave}
          >
            <ContentTableColumn>
              {users.map((user) => {
                if (!user.user_id) {
                  return null;
                }

                return (
                  <Row
                    key={user.user_id}
                    style={{
                      justifyContent: "space-between",
                      gap: spacing4,
                      padding: `${spacing4} ${spacing4}`,
                    }}
                  >
                    <UserInfoOrganisationWithMemberLink
                      userId={user.user_id}
                      size={size}
                    />
                    {renderPopupRowRightSide?.(user)}
                  </Row>
                );
              })}
            </ContentTableColumn>
          </Frame>
        </Anchor>
      )}
    </React.Suspense>
  );
}

export default StackedUserImages;
