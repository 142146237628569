import SearchIcon from "@icons/24/Search.svg?react";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { searchBarOpen } from "components/Search/state";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import { colors } from "styles/colors";
import { spacing4 } from "styles/space";
import { modalTypeOpenAtom } from "state/modal";
import { useCallback } from "react";
import { useSetAtom } from "jotai";

const SearchButtonV2 = () => {
  const setOpen = useSetAtom(searchBarOpen);
  const stringToUnicode = useSystemSpecificUnicode();

  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const closeAnyOpenModal = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  return (
    <Tooltip
      id="search"
      theme="light"
      text={"Search"}
      position="bottom"
      shortcut={`${stringToUnicode("command")}+K`}
    >
      <IconBtn
        hoverBackgroundColor={colors.grey900}
        hoverStroke={colors.blue300}
        iconColor="white"
        size="1.6rem"
        style={{
          padding: spacing4,
        }}
        onClick={() => {
          closeAnyOpenModal();
          setOpen(true);
        }}
      >
        <SearchIcon />
      </IconBtn>
    </Tooltip>
  );
};

export default SearchButtonV2;
