// Utilities for WebGl.
//
// For format, internal format, and type compatability, see
// https://registry.khronos.org/webgl/specs/latest/2.0/#TEXTURE_TYPES_FORMATS_FROM_DOM_ELEMENTS_TABLE

import { scream } from "./sentry";

type ArrayType = Uint8Array | Uint16Array | Uint32Array | Float32Array;

const getGLDataType = (array: ArrayType): number => {
  if (array instanceof Uint8Array) {
    return WebGL2RenderingContext.UNSIGNED_BYTE;
  } else if (array instanceof Uint16Array) {
    return WebGL2RenderingContext.UNSIGNED_SHORT;
  } else if (array instanceof Uint32Array) {
    return WebGL2RenderingContext.UNSIGNED_INT;
  } else if (array instanceof Float32Array) {
    return WebGL2RenderingContext.FLOAT;
  } else {
    throw new Error("Unsupported array type");
  }
};

/**
 * Single channel texture upload.
 * Access in shader as the `r` channel.
 */
export const glTexImageRed = (
  gl: WebGL2RenderingContext,
  width: number,
  height: number,
  array: ArrayType,
) => {
  const expectedLength = width * height;
  if (array.length !== expectedLength)
    throw new Error(
      `Invalid array length; expected ${expectedLength} found ${array.length}`,
    );
  const type = getGLDataType(array);
  let internalFormat: number;
  let format: number;
  if (type === WebGL2RenderingContext.UNSIGNED_BYTE) {
    internalFormat = gl.R8;
    format = gl.RED;
  } else if (type === WebGL2RenderingContext.FLOAT) {
    internalFormat = gl.R32F;
    format = gl.RED;
  } else throw new Error("Unsupported array type");
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    internalFormat,
    width,
    height,
    0,
    format,
    type,
    array,
  );
};

export const glTexImageRGB = (
  gl: WebGL2RenderingContext,
  width: number,
  height: number,
  array: ArrayType,
) => {
  const expectedLength = width * height * 3;
  if (array.length !== expectedLength)
    throw new Error(
      `Invalid array length; expected ${expectedLength} found ${array.length}`,
    );
  const type = getGLDataType(array);
  let internalFormat: number;
  let format: number;
  if (type === WebGL2RenderingContext.UNSIGNED_BYTE) {
    internalFormat = gl.RGB8;
    format = gl.RGB;
  } else if (type === WebGL2RenderingContext.FLOAT) {
    internalFormat = gl.RGB32F;
    format = gl.RGB;
  } else throw new Error("Unsupported array type");
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    internalFormat,
    width,
    height,
    0,
    format,
    type,
    array,
  );
};

export const glTexImageRGBA = (
  gl: WebGL2RenderingContext,
  width: number,
  height: number,
  array: ArrayType,
) => {
  const expectedLength = width * height * 4;
  if (array.length !== expectedLength)
    throw new Error(
      `Invalid array length; expected ${expectedLength} found ${array.length}`,
    );
  const type = getGLDataType(array);
  let internalFormat: number;
  let format: number;
  if (type === WebGL2RenderingContext.UNSIGNED_BYTE) {
    internalFormat = gl.RGBA8;
    format = gl.RGBA;
  } else if (type === WebGL2RenderingContext.FLOAT) {
    internalFormat = gl.RGBA32F;
    format = gl.RGBA;
  } else throw new Error("Unsupported array type");
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    internalFormat,
    width,
    height,
    0,
    format,
    type,
    array,
  );
};

export function createGlProgram(
  gl: WebGLRenderingContext,
  vertexSource: string,
  fragmentSource: string,
  opts?: {
    label?: string;
  },
) {
  const prefix = opts?.label ? `[GL ${opts.label}]: ` : `[GL]: `;

  // create a vertex shader
  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  if (!vertexShader) throw scream(`${prefix}failed to create vertexShader`);
  gl.shaderSource(vertexShader, vertexSource);
  gl.compileShader(vertexShader);

  // create a fragment shader
  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  if (!fragmentShader) throw scream(`${prefix}failed to create fragmentShader`);
  gl.shaderSource(fragmentShader, fragmentSource);
  gl.compileShader(fragmentShader);

  // link the two shaders into a WebGL program
  const program = gl.createProgram();
  if (!program) throw scream(`${prefix}failed to create program`);
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS))
    throw scream("Failed to create program", {
      program: program,
      vertexShader,
      fragmentShader,
      programInfo: gl.getProgramInfoLog(program),
      vertexInfo: gl.getShaderInfoLog(vertexShader),
      fragmentInfo: gl.getShaderInfoLog(fragmentShader),
    });

  return {
    program,
    vertexShader,
    fragmentShader,
  };
}
