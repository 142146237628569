import { fetchSchemaWithToken } from "services/utils";
import {
  _FolderItem,
  _FolderOrResourceItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";

export const createFolder = async (
  nodeId: string,
  name: string,
  folderItems: FolderTreeItemOrResourceItem[],
  parentId?: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/BRANCH`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        parentId: parentId,
        folderItems: folderItems,
      }),
    },
  );
};

export const deleteFolder = async (nodeId: string, folderId: string) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/BRANCH/${folderId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const deleteResource = async (nodeId: string, resourceId: string) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/BRANCH/resource/${resourceId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const editFolderName = async (
  nodeId: string,
  folderId: string,
  name: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/BRANCH/${folderId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    },
  );
};

export const moveItems = async (
  nodeId: string,
  folderItems: Partial<FolderTreeItemOrResourceItem>[],
  parentId: string | undefined,
) => {
  return fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/BRANCH/move`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        folderItems,
        parentId,
      }),
    },
  );
};
