import { SensorFeature } from "types/feature";
import styled from "styled-components";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { ColorSquare } from "components/General/ColorSquare";
import { Color } from "lib/colors";
import { Column, Row } from "components/General/Layout";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { currentSelectionArrayAtom } from "state/selection";
import { mapAtom } from "state/map";
import { useGoToFeatures } from "hooks/map";
import { ReactNode, useEffect } from "react";
import { clearHover, setHoverMultiple } from "components/Mapbox/utils";
import { sensorPointSourceId } from "components/Mapbox/constants";
import { Tag } from "components/General/Tag";
import { roundToDecimal } from "utils/utils";

export type SensorStatsType = {
  sensors: SensorFeature[];
  name: string;
  color: Color;
};

export type MaxValueType = {
  value: number;
  unit: string;
  sensor: SensorFeature;
};

const BucketList_ = styled.div`
  display: grid;
  grid-template-columns: min-content max-content 2rem max-content auto;
  > :nth-child(10n-9),
  > :nth-child(10n-8),
  > :nth-child(10n-7),
  > :nth-child(10n-6),
  > :nth-child(10n-5) {
    background: ${colors.surfaceSecondary};
  }

  > * {
    padding: 0.6rem 0.8rem;
  }
  ${typography.caption}

  > span {
    justify-content: end;
    display: flex;
    align-items: center;
    padding: 0.4rem 0.4rem;
    gap: 0.4rem;
  }
  span.line {
    color: ${colors.textDisabled};
  }

  ${ColorSquare} {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const hoveredSensorIdsAtom = atom<{ source: string; id: string }[] | undefined>(
  undefined,
);

const SensorStat = ({
  children,
  sensors,
}: {
  children: ReactNode;
  sensors: SensorFeature[];
}) => {
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);
  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);
  const setHoveredSensorsIds = useSetAtom(hoveredSensorIdsAtom);

  return (
    <span
      style={{ cursor: "pointer" }}
      onClick={() => {
        goToFeatures(sensors, undefined, undefined, 0.001);
        setCurrentSelectionArray(sensors.map((s) => s.id));
      }}
      onMouseEnter={() => {
        setHoveredSensorsIds(
          sensors.map((s) => ({
            source: sensorPointSourceId,
            id: s.id,
          })),
        );
      }}
      onMouseLeave={() => {
        setHoveredSensorsIds(undefined);
      }}
    >
      {children}
    </span>
  );
};

const SensorStatRow = ({ sensorStat }: { sensorStat: SensorStatsType }) => {
  const map = useAtomValue(mapAtom);
  const hoveredSensorsIds = useAtomValue(hoveredSensorIdsAtom);

  useEffect(() => {
    if (map) {
      if (hoveredSensorsIds) {
        setHoverMultiple(map, hoveredSensorsIds);
        return () => {
          clearHover(map);
        };
      }
    }
  }, [hoveredSensorsIds, map]);

  const count = sensorStat.sensors.length;
  return (
    <>
      <div>
        <ColorSquare $color={sensorStat.color} />
      </div>
      <span>{sensorStat.name}</span>
      <span className="line">&mdash;</span>
      <SensorStat sensors={sensorStat.sensors}>
        {count} {count === 1 ? "Sensor" : "Sensors"}
      </SensorStat>
      <span />
    </>
  );
};

const SensorStats = ({
  sensorStats,
  title = true,
  maxValue = undefined,
}: {
  sensorStats: SensorStatsType[];
  maxValue?: MaxValueType;
  title?: boolean;
}) => {
  if (sensorStats.length === 0) return null;

  return (
    <Column>
      {title && <SubtitleWithLine text="Sensor stats" />}
      {maxValue && (
        <Row alignCenter>
          <InputTitle>Max sensor value</InputTitle>
          <SensorStat sensors={[maxValue.sensor]}>
            <Tag
              id="max-sensor-value"
              text={`${roundToDecimal(maxValue.value, 1)} ${maxValue.unit}`}
            />
          </SensorStat>
        </Row>
      )}

      <BucketList_>
        {sensorStats.map((stat) => (
          <SensorStatRow key={stat.color.toRGB()} sensorStat={stat} />
        ))}
      </BucketList_>
    </Column>
  );
};

export default SensorStats;
