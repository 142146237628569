import { useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import { projectIdAtom } from "state/pathParams";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { customLayersMetadataSelectorAsync } from "../../../state/customLayers";
import { useShowScrollShadow } from "../../../hooks/useShowScrollShadow";
import { getAllLayerCollections } from "../Collections/state";
import { SIDEBAR_PADDING } from "../LayerList.style";
import { getSelectedLayers } from "../LayerSettings/state";
import { sortLayers } from "../utils";
import ProjectLayersTab from "./ProjectLayersTab";
import useScrollToLayerInList from "./useScrollToLayerInList";

const ProjectLayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: ${SIDEBAR_PADDING};
  overflow-y: auto;
  overflow-x: hidden;
`;

interface Props {
  canEditProject: boolean;
}

const ProjectLayers = ({ canEditProject }: Props) => {
  const [openedCollections, setOpenedCollections] = useState<string[]>([]);
  const { scrollBodyRef, forceCheck } = useShowScrollShadow();
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const collections = useAtomValue(
    getAllLayerCollections({
      projectId,
    }),
  );
  const selectedLayers = useAtomValue(
    getSelectedLayers({
      projectId,
    }),
  );
  const customLayersL = useAtomValue(
    useMemo(
      () =>
        unwrap(
          customLayersMetadataSelectorAsync({
            nodeId: projectId,
          }),
        ),
      [projectId],
    ),
  );

  const sortedAllLayers = useMemo(
    () => sortLayers(selectedLayers.concat(customLayersL ?? [])),
    [selectedLayers, customLayersL],
  );

  useScrollToLayerInList(scrollBodyRef, sortedAllLayers);

  useEffect(() => {
    if (!scrollBodyRef.current) {
      return;
    }
    forceCheck();

    // We want to run effect when scrollBodyRef changes, disable eslint check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, sortedAllLayers, scrollBodyRef.current, openedCollections]);

  return (
    <ProjectLayersWrapper>
      <ProjectLayersTab
        layers={sortedAllLayers}
        collections={collections}
        canEditProject={canEditProject}
        setOpenedCollections={setOpenedCollections}
      />
    </ProjectLayersWrapper>
  );
};

export default ProjectLayers;
