import { useAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import {
  Column as ReactTableColumn,
  SortByFn,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useJotaiCallback } from "utils/jotai";
import { Mixpanel } from "mixpanel";
import BinIcon from "@icons/24/Bin.svg";
import DataPackageIcon from "@icons/24/DataPackage.svg";
import DownloadIcon from "@icons/24/Download.svg";
import DuplicateIcon from "@icons/24/Duplicate.svg";
import EarthIcon from "@icons/14/Earth.svg";
import PencilIcon from "@icons/24/Pencil.svg";
import ReplaceIcon from "@icons/14/Replace.svg";
import DirectionUpIcon from "@icons/24/DirectionUp.svg?react";
import DirectionDownIcon from "@icons/24/DirectionDown.svg?react";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { Anchor } from "components/General/Anchor";
import { Row } from "components/General/Layout";
import { spacing4, spacing6, spacing8 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";
import { EditableTextInternalState } from "components/General/EditableText";
import { SkeletonText } from "components/Loading/Skeleton";
import { fetchEnhancer } from "services/utils";
import { midScreenModalTypeOpenAtom, modalTypeOpenAtom } from "state/modal";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import Checkbox from "components/General/Checkbox";
import Tooltip from "components/General/Tooltip";
import { downloadText } from "utils/utils";
import { DataLibraryLayer, DataLibraryPackage } from "../types";
import ChatIcon from "@icons/24/Chat.svg";
import {
  HeaderColumn,
  HiddenIfNotChecked,
  StickyThead,
  Table,
  ProjectInfo,
  UserInfoBigger,
} from "../shared";
import { EditVectordataType } from "../Edit/EditVectordata";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import {
  getDataLayersUsage,
  scrollToOrganisationLayerIdAtom,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import { organisationResourceLastUpdateSelectorFamily } from "components/Changelog/state";
import { UsageContainerInner2 } from "components/ConfigurationModal/SettingsUsage/common";
import { UsageContainerPlacement } from "components/ConfigurationModal/SettingsUsage/style";
import { organisationLibraryDataPackageResourceState } from "../../state";
import { usersInOrganisationState } from "components/Organisation/state";
import { getSortTypes } from "components/Organisation/Library/dataLibrary/sortTypes";
import { idToCustomLayerChangelogId } from "components/InputChangelog/const";
import { loadable, unwrap } from "jotai/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { ComponentLastChangedSimple } from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import AddToPackageFrame from "./AddToPackageFrame";
import { inputChangelogsAtomFamily } from "components/InputChangelog/state";
import NewItemModal from "components/NewItemModal/NewItemModal";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";

// UseSortByColumnProps
const columns: Array<ReactTableColumn<DataLibraryLayer>> = [
  {
    id: "name",
    width: "40%",
    // @ts-ignore
    canSort: true,
    sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Header: ({ toggleAllSelected, allIsSelected, someIsSelected }) => {
      return (
        <Row
          style={{
            paddingLeft: spacing8,
          }}
        >
          <Checkbox
            checked={
              allIsSelected ? true : someIsSelected ? "indeterminate" : false
            }
            onChange={toggleAllSelected}
          />
          <HeaderColumn>Name</HeaderColumn>
        </Row>
      );
    },
    Cell: ({ row, isSelected, onToggleLayerSelected, onChangeLayerName }) => {
      return (
        <Row
          alignCenter
          style={{
            display: "flex",
            gap: spacing6,
            paddingLeft: spacing8,
          }}
        >
          <HiddenIfNotChecked data-checked={isSelected}>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onToggleLayerSelected(row.original.id);
              }}
            />
          </HiddenIfNotChecked>
          <EditableTextInternalState
            value={row.original.name}
            onEnter={(newValue) => {
              onChangeLayerName(row.original.id, newValue);
            }}
            inputStyle={{
              flex: 1,
            }}
            textContainerStyle={{
              padding: 0,
              margin: 0,
            }}
          />
        </Row>
      );
    },
  },
  {
    id: "created_at",
    width: "22%",
    // @ts-ignore
    canSort: true,
    sortType: "lastChanged",
    Header: <HeaderColumn style={typography.sub2}>Last updated</HeaderColumn>,

    Cell: ({ row, lastChanges }) =>
      typeof lastChanges === "undefined" ? (
        <SkeletonText />
      ) : (
        <ComponentLastChangedSimple
          changelogId={idToCustomLayerChangelogId(row.original.id)}
          category="org_data_package_manage"
        />
      ),
  },
  {
    id: "author",
    width: "15%",
    // @ts-ignore
    canSort: true,
    sortType: "author",
    Header: <HeaderColumn>Created by</HeaderColumn>,
    // sortType: (a, b) => a.original.name.localeCompare(b.original.name),
    Cell: ({ row, organisationId }) => {
      return (
        <Row alignCenter>
          <UserInfoBigger
            organisationId={organisationId}
            userId={row.original.author}
          />
        </Row>
      );
    },
  },
  {
    id: "nrPackageUsages",
    // @ts-ignore
    canSort: false,
    width: "5%",
    Header: <HeaderColumn>Used in</HeaderColumn>,
    Cell: ({ row, layerUsagesInProjects, layerUsagesInPackages }) => {
      const projectUsage = (layerUsagesInProjects[row.original.id] ??
        []) as string[];
      const packetUsage = (layerUsagesInPackages[row.original.id] ??
        []) as DataLibraryPackage[];
      return (
        <Row
          alignCenter
          style={{
            paddingRight: spacing8,
          }}
        >
          <Row
            alignCenter
            style={{
              gap: "0",
            }}
          >
            <UsageContainerInner2
              icon={<DataPackageIcon />}
              wrapperStyle={{
                backgroundColor: "unset",
                alignItems: "center",
                gap: spacing4,
                padding: 0,
              }}
              text={""}
              loadable={{ state: "hasData", data: packetUsage }}
              typeName=""
              placement={UsageContainerPlacement.BOTTOM}
            >
              {packetUsage &&
                packetUsage.map((packet) => (
                  <div key={packet.id}>{packet.name}</div>
                ))}
            </UsageContainerInner2>
          </Row>
          <Row
            alignCenter
            style={{
              gap: "0",
            }}
          >
            <UsageContainerInner2
              icon={<EarthIcon />}
              wrapperStyle={{
                backgroundColor: "unset",
                alignItems: "center",
                gap: spacing4,
                padding: 0,
              }}
              text={""}
              loadable={{ state: "hasData", data: projectUsage }}
              typeName=""
              placement={UsageContainerPlacement.BOTTOM}
            >
              {projectUsage &&
                projectUsage.map((projectId) => (
                  <ProjectInfo key={projectId} projectId={projectId} />
                ))}
            </UsageContainerInner2>
          </Row>
        </Row>
      );
    },
  },
  {
    id: "actions",
    // @ts-ignore
    canSort: false,
    Header: <></>,
    width: 150,
    Cell: ({
      row,
      onEditClick,
      onReplaceClick,
      onDeleteClick,
      onDuplicateClick,
    }) => {
      const { editLayerMetadata } = useDataLibraryLayersCrud();
      const [isOpen, setIsOpen] = useState(false);
      const [isAddToPackageOpen, setIsAddToPackageOpen] = useState(false);
      const dotMenuRef = useRef<HTMLDivElement>(null);
      const menuItemRef = useRef<HTMLDivElement>(null);

      return (
        <Row
          alignCenter
          style={{
            gap: spacing6,
            justifyContent: "flex-end",
            paddingRight: "0.8rem",
          }}
        >
          <DescriptionModal
            size="small"
            updateDescription={(description: string) =>
              editLayerMetadata(row.original.id, {
                description,
              })
            }
            defaultValue={row.original.description ?? ""}
            close={() => setIsOpen(false)}
            subtitle={
              <div>
                <p>Add a short description to the data layer.</p>
                <p>
                  The description will be visible for members and projects with
                  access to this data layer.
                </p>
              </div>
            }
            editByDefault={isOpen}
          />

          {isAddToPackageOpen && (
            <Anchor baseRef={dotMenuRef} floatPlace="topRight">
              <AddToPackageFrame
                layerIds={[row.original.id]}
                setIsOpen={setIsAddToPackageOpen}
              />
            </Anchor>
          )}

          <Tooltip text="Edit layer">
            <IconBtn
              style={{
                padding: "0.9rem",
              }}
              hoverBackgroundColor={colors.grey200}
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(row.original.id);
              }}
            >
              <IconREMSize height={1.4} width={1.4}>
                <PencilIcon />
              </IconREMSize>
            </IconBtn>
          </Tooltip>
          <div ref={dotMenuRef}>
            <DotMenu hoverBackgroundColor={colors.grey200}>
              <div ref={menuItemRef}>
                <MenuItem
                  name="Add to package"
                  icon={<DataPackageIcon />}
                  onClick={(e) => {
                    Mixpanel.track_old(
                      "Add to package library layer click",
                      {},
                    );
                    e.preventDefault();
                    e.stopPropagation();
                    setIsAddToPackageOpen(true);
                  }}
                />

                <MenuItem
                  name={
                    row.original.description
                      ? "Edit description"
                      : "Add description"
                  }
                  icon={<ChatIcon />}
                  onClick={() => setIsOpen(!isOpen)}
                />
                <MenuItem
                  name={"Replace layer"}
                  icon={<ReplaceIcon />}
                  onClick={() => {
                    Mixpanel.track_old("Replace library layer click", {});
                    onReplaceClick(row.original.id);
                  }}
                />
                <MenuItem
                  name={"Duplicate layer"}
                  icon={<DuplicateIcon />}
                  onClick={() => {
                    Mixpanel.track_old("Duplicate library layer click", {});
                    onDuplicateClick(row.original.id);
                  }}
                />
                <MenuItem
                  name={"Download"}
                  icon={<DownloadIcon />}
                  onClick={() => {
                    Mixpanel.track_old("Download library layer click", {});
                    let fileName = row.original.name.replaceAll(".gz", "");
                    if (!fileName.endsWith(".geojson")) {
                      fileName = fileName.concat(".geojson");
                    }
                    fetchEnhancer(row.original.path, {
                      method: "GET",
                    })
                      .then((result) => result.text())
                      .then((text) => {
                        downloadText(text, fileName);
                      });
                  }}
                />
                <MenuItem
                  name={"Delete"}
                  icon={<BinIcon />}
                  onClick={() => {
                    Mixpanel.track_old("Delete library layer click", {});
                    onDeleteClick(row.original.id);
                  }}
                />
              </div>
            </DotMenu>
          </div>
        </Row>
      );
    },
  },
];

const TR = styled.tr<{
  isSelected: boolean;
  shouldFlash: boolean;
}>`
  @keyframes blink {
    50% {
      background-color: ${colors.blue400};
    }
  }

  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? `&&& { background-color: ${colors.surfaceSelectedLight}; }`
      : ""}

  ${({ shouldFlash }) => (shouldFlash ? `animation: blink 1s;` : "")};
`;

export type DraggedLayersProps = {
  layerIds: string[];
};

const DraggableLayerRow = React.memo(
  ({
    Row,
    layerId,
    selectedLayers,
  }: {
    Row: React.ReactElement;
    layerId: string;
    selectedLayers: string[];
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isDragging: _isDragging }, dragRef] = useDrag<
      DraggedLayersProps,
      void,
      {
        isDragging: boolean;
      }
    >(
      () => ({
        type: "ORGANISATION_LAYER",
        canDrag: false,
        item: {
          layerIds:
            selectedLayers.length > 0 && selectedLayers.includes(layerId)
              ? selectedLayers
              : [layerId],
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [layerId, selectedLayers],
    );

    dragRef(ref);
    return React.cloneElement(Row, {
      ref,
      style: {
        ...Row.props.style,
        opacity: _isDragging ? 0.5 : 1,
      },
    });
  },
);

const DataTable = ({
  layers,
  allLayers,
}: {
  layers: DataLibraryLayer[];
  allLayers: DataLibraryLayer[];
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { deleteLayer, editLayerMetadata, cloneOrgLayer } =
    useDataLibraryLayersCrud();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const scrollBodyRef = useRef<HTMLDivElement>(null);
  const [selectedLayers, setSelectedLayers] = useAtom(
    selectedOrganisationLayerIdsSelector({
      organisationId,
    }),
  );
  const [showDuplicateLayerModal, setShowDuplicateLayerModal] = useState<
    undefined | DataLibraryLayer
  >(undefined);
  const [scrollToLayerId, setScrollToLayerId] = useAtom(
    scrollToOrganisationLayerIdAtom,
  );

  const allMembersRaw = useAtomValue(usersInOrganisationState(organisationId));

  const getUsername = useCallback(
    (userId: string) => {
      return allMembersRaw.find((m) => m.user_id === userId)?.nickname;
    },
    [allMembersRaw],
  );

  const allIsSelected =
    layers.length > 0 && selectedLayers.length === layers.length;
  const someIsSelected = selectedLayers.length > 0;

  const layerUsagesInProjects = useAtomValue(
    getDataLayersUsage({
      layerIds: allLayers.map((l) => l.id),
      nodeId: organisationId,
    }),
  );

  const dataPackages = useAtomValue(
    organisationLibraryDataPackageResourceState({
      organisationId: organisationId,
    }),
  );

  const getChangeLogForItem = useJotaiCallback(
    (get, _set, changelogId: string) => {
      const changes = get(
        unwrap(
          inputChangelogsAtomFamily({
            nodeId: organisationId,
            changelogId,
            category: "org_data_package_manage",
            organisationId,
          }),
        ),
      );

      if (changes) {
        return changes[0];
      }
      return undefined;
    },
    [organisationId],
  );

  const layerUsagesInPackages = useMemo(
    () =>
      layers.reduce((acc, layer) => {
        const dataPacakgesForLayer = dataPackages.filter((dp) =>
          dp.layerIds.includes(layer.id),
        );
        if (dataPacakgesForLayer.length === 0) return acc;
        return {
          ...acc,
          [layer.id]: dataPacakgesForLayer,
        };
      }, {}),
    [dataPackages, layers],
  );

  const lastChangesLoadable = loadable(
    organisationResourceLastUpdateSelectorFamily({
      organisationId,
      resourceIds: allLayers.map((l) => idToCustomLayerChangelogId(l.id)),
    }),
  );

  const toggleAllSelected = useCallback(() => {
    Mixpanel.track_old("Toggle all library layers selected", {});
    setSelectedLayers(() => {
      if (someIsSelected) {
        return [];
      }

      return layers.map((p) => p.id);
    });
  }, [setSelectedLayers, someIsSelected, layers]);

  const onEditClick = useCallback(
    (layerId: string) => {
      Mixpanel.track_old("Edit library layer click", {});
      setModalTypeOpen({
        modalType: EditVectordataType,
        metadata: {
          layerId: layerId,
        },
      });
    },
    [setModalTypeOpen],
  );

  const onReplaceClick = useCallback(
    (layerId: string) => {
      setMidScreenModalTypeOpen({
        modalType: UploadOrganisationDataLayerModalType,
        metadata: {
          fileIdToReplace: layerId,
        },
      });
    },
    [setMidScreenModalTypeOpen],
  );

  const onDuplicateClick = useCallback(
    (layerId: string) => {
      const layer = allLayers.find((l) => l.id === layerId);
      setShowDuplicateLayerModal(layer);
    },
    [allLayers],
  );

  const onDeleteClick = useCallback(
    (layerId: string) => {
      return deleteLayer(layerId);
    },
    [deleteLayer],
  );

  const getLayerIsSelected = useCallback(
    (layerId: string) => {
      return selectedLayers.includes(layerId);
    },
    [selectedLayers],
  );

  const onToggleLayerSelected = useCallback(
    (layerId: string) => {
      Mixpanel.track_old("Toggle library layer selected", {});
      setSelectedLayers((curr) => {
        if (curr.includes(layerId)) {
          return curr.filter((p) => p !== layerId);
        } else {
          return [...curr, layerId];
        }
      });
    },
    [setSelectedLayers],
  );

  const onChangeLayerName = useCallback(
    (layerId: string, newName: string) => {
      Mixpanel.track_old("Change library layer name", {
        nameLength: newName.length,
      });
      return editLayerMetadata(layerId, {
        name: newName,
      });
    },
    [editLayerMetadata],
  );

  const sortTypes = useMemo<Record<string, SortByFn<DataLibraryLayer>>>(
    () => getSortTypes(getUsername, getChangeLogForItem),
    [getUsername, getChangeLogForItem],
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    getRowId,
    rows,
    rowsById,
  } = useTable<DataLibraryLayer>(
    {
      data: layers,
      columns: columns,
      sortTypes,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSortBy: false,
      initialState: {
        pageSize: 1000,
        pageIndex: 0,
        sortBy: [
          {
            id: "updated_at",
            desc: true,
          },
          {
            id: "created_at",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  // Deselect layers when unmounting
  useEffect(() => {
    return () => {
      setSelectedLayers([]);
      setScrollToLayerId(undefined);
    };
  }, [setSelectedLayers, setScrollToLayerId]);

  // Scroll to layer if scrollToLayerId is set
  useEffect(() => {
    if (scrollToLayerId) {
      const row = rows.find((row) => row.original.id === scrollToLayerId);
      if (!row) {
        return;
      }

      const element = document.querySelector(
        `#data-table tbody [data-layer-id="${scrollToLayerId}"]`,
      );
      if (!element) {
        return;
      }

      scrollBodyRef.current?.scrollTo({
        top:
          element.getBoundingClientRect().top -
          scrollBodyRef.current.getBoundingClientRect().top -
          50,
      });
    }
  }, [scrollToLayerId, rows, rowsById, getRowId, setSelectedLayers]);

  return (
    <>
      {showDuplicateLayerModal && (
        <NewItemModal
          title="Duplicate layer"
          placeholder="Enter duplicated layer name"
          submitButtonText="Duplicate"
          defaultValue={`${showDuplicateLayerModal.name} duplicate`}
          onSubmit={async (name) => {
            await cloneOrgLayer(showDuplicateLayerModal.id, name);
          }}
          onClose={() => {
            setShowDuplicateLayerModal(undefined);
          }}
        />
      )}
      <ScrollBody ref={scrollBodyRef}>
        <Table
          id="data-table"
          {...getTableProps({
            style: {
              width: "100%",
              borderSpacing: 0,
            },
          })}
        >
          <StickyThead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        onClick: (e) => {
                          if (
                            !column.canSort ||
                            e.nativeEvent
                              .composedPath()
                              .find(
                                (e) =>
                                  e instanceof HTMLElement &&
                                  e.dataset.type === "checkbox",
                              )
                          ) {
                            return;
                          }

                          Mixpanel.track_old("Sort library layers", {
                            id: column.id,
                            desc: !column.isSortedDesc,
                          });
                          column.toggleSortBy(!column.isSortedDesc);
                        },
                        style: {
                          textAlign: "unset",
                          cursor: column.canSort ? "pointer" : "unset",
                          width: column.width,
                          padding: "2rem 0",
                        },
                      }),
                    )}
                  >
                    <Row alignCenter>
                      {column.render("Header", {
                        allIsSelected,
                        toggleAllSelected,
                        someIsSelected,
                      })}
                      {column.canSort && (
                        <span
                          style={{
                            marginLeft: "0.3rem",
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <DirectionDownIcon height={12} width={12} />
                            ) : (
                              <DirectionUpIcon height={12} width={12} />
                            )
                          ) : (
                            <DirectionDownIcon height={12} width={12} />
                          )}
                        </span>
                      )}
                    </Row>
                  </th>
                ))}
              </tr>
            ))}
          </StickyThead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const isSelected = getLayerIsSelected(row.original.id);

              return (
                <React.Fragment key={row.original.id}>
                  <DraggableLayerRow
                    layerId={row.original.id}
                    selectedLayers={selectedLayers}
                    Row={
                      <TR
                        isSelected={isSelected}
                        shouldFlash={scrollToLayerId === row.original.id}
                        data-layer-id={row.original.id}
                        {...row.getRowProps({})}
                        onClick={() => {
                          onToggleLayerSelected(row.original.id);
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td
                              {...cell.getCellProps({
                                style: {
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  padding: `${spacing4} 0`,
                                },
                              })}
                            >
                              <React.Suspense fallback={<SkeletonText />}>
                                {cell.render("Cell", {
                                  lastChanges: unwrap(lastChangesLoadable),
                                  organisationId,
                                  onEditClick,
                                  onReplaceClick,
                                  onDuplicateClick,
                                  onDeleteClick,
                                  isSelected,
                                  selectedLayers,
                                  onToggleLayerSelected,
                                  onChangeLayerName,
                                  layerUsagesInProjects,
                                  layerUsagesInPackages,
                                })}
                              </React.Suspense>
                            </td>
                          );
                        })}
                      </TR>
                    }
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </ScrollBody>
    </>
  );
};

export default React.memo(DataTable);
