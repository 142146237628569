import useOrgSubstationCrud from "components/Organisation/Library/substation/useOrgSubstationCrud";
import { useToast } from "hooks/useToast";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { SubstationType } from "services/substationService";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { SUBSTATION_MENU_ID } from "./Substation/useSubstationSettings";
import { projectIdAtom } from "state/pathParams";

export const useDuplicateSubstationToLibrary = () => {
  const { create } = useOrgSubstationCrud();
  const { success, error } = useToast();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom);
  const setMenuSelection = useSetAtom(
    selectedMenuItemState({
      menuId: SUBSTATION_MENU_ID,
      projectId: projectId ?? "",
    }),
  );

  const duplicateToLibrary = useCallback(
    async (projectId: string, substation: SubstationType) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "substation",
          defaultName: `${substation.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            try {
              const clone: Partial<SubstationType> = {
                ...substation,
                name: name,
              };
              delete clone.id;

              const result = await create({
                substation: clone,
                name: `${substation.name} (duplicate)`,
                projectAccess: [projectId],
              });
              setMenuSelection?.(result.id);

              success("Substation duplicated to library");
              return result;
            } catch (e) {
              error("Failed to duplicate substation to library");
              throw e;
            }
          },
        },
      });
    },
    [create, success, error, setMidScreenModalTypeOpen, setMenuSelection],
  );

  return { duplicateToLibrary };
};
