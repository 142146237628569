import { IconREMSize } from "styles/typography";
import Tooltip from "./Tooltip";
import InfoIcon from "@icons/24/Information.svg?react";
import Button from "./Button";
import { colors } from "styles/colors";

export default function OpenMoreInfo({
  onClick,
  isOpen,
}: {
  onClick: () => void;
  isOpen?: boolean;
}) {
  return (
    <Tooltip text="Open more info to the right">
      <Button
        style={isOpen ? { backgroundColor: colors.indigo200 } : {}}
        buttonType="secondary"
        icon={
          <IconREMSize height={1.4} width={1.4}>
            <InfoIcon />
          </IconREMSize>
        }
        onClick={onClick}
      />
    </Tooltip>
  );
}
