import {
  opacityPropertyName,
  displayLabelPropertyName,
  strokeWidthPropertyName,
} from "@constants/canvas";
import { MultiLineString, LineString } from "geojson";
import { LinePaint, SymbolLayer } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { OtherFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  getHiddenLargeLineClickLayer,
  addLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import {
  otherLineStringSymbolLayerId,
  otherLineStringSourceId,
  otherLineStringLayerId,
  DEFAULT_ZOOM_THRESHOLD,
} from "./constants";
import { colors } from "styles/colors";
import { READ, caseexpr } from "./defaults";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const linePaint: LinePaint = {
  "line-color": caseexpr({
    get: { color: READ },
    fallback: colors.otherLineString,
  }),
  "line-width": caseexpr({
    get: { [strokeWidthPropertyName]: READ },
    fallback: 4,
  }),
  "line-opacity": caseexpr({
    selected: 1,
    hover: 0.8,
    get: { [opacityPropertyName]: READ },
    fallback: 0.3,
  }),
  "line-dasharray": [
    "case",
    ["==", ["get", "strokeStyle"], "dashed"],
    ["literal", [4, 4]],
    ["==", ["get", "strokeStyle"], "dotted"],
    ["literal", [1, 2]],
    ["literal", [1, 0]],
  ],
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  otherLineStringLayerId,
  otherLineStringSourceId,
);

const symbolLayer: SymbolLayer = {
  id: otherLineStringSymbolLayerId,
  source: otherLineStringSourceId,
  type: "symbol",
  minzoom: DEFAULT_ZOOM_THRESHOLD,
  layout: {
    "symbol-placement": "line",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
    "text-offset": [0, 1],
  },
  paint: { "text-opacity": 0.6 },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

export const RenderOtherLineStrings = ({
  features,
  map,
  showSelectionHighlighter,
}: {
  features: OtherFeature<LineString | MultiLineString>[];
  map: mapboxgl.Map;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  useEffect(() => {
    map.addSource(otherLineStringSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, symbolLayer.id);
      safeRemoveLayer(map, otherLineStringLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(otherLineStringSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: otherLineStringLayerId,
      type: "line",
      source: otherLineStringSourceId,
      paint: linePaint,
    });
    addLayer(map, hiddenClickLayer);
    addLayer(map, symbolLayer);
  }, [map]);

  useEffect(() => {
    const source = map.getSource(otherLineStringSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={otherLineStringSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
