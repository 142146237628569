import { DYNAMIC_LAYER_TYPE } from "@constants/dynamic";
import {
  CIRCLE_LAYERS_SUFFIX,
  HIDDEN_CIRCLE_LAYERS_SUFFIX,
} from "layers/ExternalLayers/dynamicCircleLayers";
import { LINE_LAYERS_SUFFIX } from "layers/ExternalLayers/dynamicLineLayers";
import { POLYGON_LAYERS_SUFFIX } from "layers/ExternalLayers/dynamicPolygonLayers";
import {
  dynamicTileCircleToLayerId,
  dynamicTileLineToLayerId,
  dynamicTilePolygonToLayerId,
} from "layers/ExternalLayers/dynamicTileJSONLayers";
import { ExternalSelectionItem } from "state/externalLayerSelection";
import { LayerType } from "types/layers";
import { isNever } from "utils/utils";
import { sendWarning } from "utils/sentry";

export const maybeGetLayerId = (feature: ExternalSelectionItem) => {
  const endsWith = [
    POLYGON_LAYERS_SUFFIX,
    LINE_LAYERS_SUFFIX,
    CIRCLE_LAYERS_SUFFIX,
    HIDDEN_CIRCLE_LAYERS_SUFFIX,
    DYNAMIC_LAYER_TYPE,
  ].find((suffix) => feature.layer?.id?.endsWith(suffix));

  if (endsWith) {
    // safety: we .find on this being defined.
    return feature.layer!.id?.split(endsWith)[0];
  }

  const startsWith = [
    dynamicTileCircleToLayerId(""),
    dynamicTilePolygonToLayerId(""),
    dynamicTileLineToLayerId(""),
  ].find((prefix) => feature.layer?.id?.startsWith(prefix));

  if (startsWith) {
    // safety: we .find on this being defined.
    return feature.layer!.id?.split(startsWith)[1];
  }
};

export const getLayerTypeTooltipText = (layerType: LayerType) => {
  switch (layerType) {
    case LayerType.Point:
    case LayerType.Circle:
    case LayerType.Line:
    case LayerType.Polygon:
    case LayerType.FeatureCollection:
      return "Vector data layer";

    case LayerType.wms:
    case LayerType.wmts:
    case LayerType.XYZ:
    case LayerType.Raster:
      return "Raster data layer";

    default:
      isNever(layerType);
      sendWarning(new Error(`Unknown layer type ${layerType}`), {
        layerType,
      });
      return "Unknown layer type";
  }
};
