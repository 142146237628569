import { useJotaiCallback } from "utils/jotai";
import { CostConfiguration } from "services/costService";
import { libraryCostConfigurationsFamily } from "state/jotai/costConfiguration";

export function useLibraryNodeCostConfig(projectId: string) {
  const updateLocal = useJotaiCallback(
    async (get, set, costConfig: CostConfiguration) => {
      set(
        libraryCostConfigurationsFamily({ projectId }),
        async (currentCostConfigsPromise) => {
          const currentCostConfigs = await currentCostConfigsPromise;
          const newCostConfigs = new Map(currentCostConfigs);
          newCostConfigs.set(costConfig.id, costConfig);
          return newCostConfigs;
        },
      );
    },
    [projectId],
  );

  return {
    updateLocal,
  };
}
