import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import {
  ArqisLayer as ArcgisLayer,
  PrivateGISLayer,
  SourceTypes,
  WfsLayer,
  WmsLayer,
  _PrivateGISLayer,
  SourceTypesLayer,
  WmtsLayer,
} from "../types/layers";
import { getPrivateDataSources } from "../services/gisDataAPIService";
import { atomWithRefresh } from "jotai/utils";

export const privateGISSourceDataAPISelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atomWithRefresh<Promise<PrivateGISLayer[]>>(async () => {
      if (!projectId) return [];
      const response = await getPrivateDataSources(projectId);

      let j = await response.json();
      return _PrivateGISLayer.array().parse(j);
    }),
);

export const privateGISSourceDataWMSAPISelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<WmsLayer[]>>(async (get) => {
      const privateGISLayers = await get(
        privateGISSourceDataAPISelector({
          projectId,
        }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wms)
        .map(
          (l) =>
            ({
              source: l.source,
              wms_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wms,
            }) as WmsLayer,
        );
    }),
);

export const privateGISSourceDataWMTSAPISelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<WmtsLayer[]>>(async (get) => {
      const privateGISLayers = await get(
        privateGISSourceDataAPISelector({
          projectId,
        }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wmts)
        .map(
          (l) =>
            ({
              source: l.source,
              wmts_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wmts,
            }) as WmtsLayer,
        );
    }),
);

export const privateGISSourceDataArcgisRestAPISelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<ArcgisLayer[]>>(async (get) => {
      const privateGISLayers = await get(
        privateGISSourceDataAPISelector({
          projectId,
        }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.arcgis_rest_api)
        .map(
          (l) =>
            ({
              source: l.source,
              arcgis_rest_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.arcgis,
            }) as ArcgisLayer,
        );
    }),
);

export const privateGISSourceDataWFSAPISelector = atomFamily(
  ({ projectId }: { projectId: string }) =>
    atom<Promise<WfsLayer[]>>(async (get) => {
      const privateGISLayers = await get(
        privateGISSourceDataAPISelector({
          projectId,
        }),
      );
      return privateGISLayers
        .filter((l) => l.type === SourceTypes.wfs)
        .map(
          (l) =>
            ({
              source: l.source,
              wfs_url: l.url,
              private: true,
              sourceType: SourceTypesLayer.wfs,
            }) as WfsLayer,
        );
    }),
);
