import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtomDef } from "state/pathParams";
import debounce from "debounce";
import {
  CostConfiguration,
  updateConfiguration,
  _CostConfiguration,
  _CostConfigurationInput,
} from "../services/costService";
import { savingCostConfigurationInProgressAtom } from "../state/costConfigurations";
import { useToast } from "./useToast";
import { sendWarning } from "utils/sentry";
import { Mixpanel } from "mixpanel";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectCostConfigurationsFamily } from "state/jotai/costConfiguration";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const saveToDB = async (
  nodeId: string,
  configuration: CostConfiguration,
  onSuccess: () => void,
  onError: (error: Error) => void,
) => {
  return updateConfiguration(
    nodeId,
    configuration.id,
    _CostConfigurationInput.parse(configuration),
  )
    .then(() => {
      onSuccess();
    })
    .catch(onError);
};

const saveToDbDebounced = debounce(saveToDB, 600);

const useCostConfigurationCrud = () => {
  const projectId = useAtomValue(projectIdAtomDef);
  const setIsSaving = useSetAtom(savingCostConfigurationInProgressAtom);
  const { info, success: showSuccess, error: showError } = useToast();
  const { showConfirm } = useConfirm();
  const projectConfigurations = useAtomValue(
    projectCostConfigurationsFamily({
      projectId,
    }),
  );

  const updateLocal = useJotaiCallback(
    async (get, set, configuration: CostConfiguration) => {
      if (!projectId) return;
      return aset(
        get,
        set,
        projectCostConfigurationsFamily({
          projectId,
        }),
        (cur) => {
          const newMap = new Map(cur);
          newMap.set(configuration.id, configuration);
          return newMap;
        },
      );
    },
    [projectId],
  );

  const save = useJotaiCallback(
    async (get, set, configuration: CostConfiguration) => {
      if (!projectId) return;
      setIsSaving(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "COST_CONFIGURATION",
          resourceId: configuration.id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "COST_CONFIGURATION",
          configuration.id,
        );
      }

      const currentConfig = projectConfigurations.get(configuration.id);

      const {
        name: _,
        description: __,
        ...configurationWithoutNameAndDescription
      } = configuration;
      const {
        name: ___,
        description: ____,
        ...currentConfigWithoutNameAndDescription
      } = currentConfig || {};

      const changedSomethingElseThanNameOrDescription =
        JSON.stringify(configurationWithoutNameAndDescription) !==
        JSON.stringify(currentConfigWithoutNameAndDescription);

      const updatedConfiguration: CostConfiguration = currentConfig
        ? {
            ...currentConfig,
            ...configuration,
          }
        : configuration;

      const parsed = _CostConfiguration.safeParse(updatedConfiguration);
      if (!parsed.success) {
        setIsSaving(false);

        sendWarning("Tried to save invalid cost config", {
          errors: parsed.error.errors.map(({ code, path, message }) => ({
            code,
            path,
            message,
          })),
        });

        return parsed.error.errors.slice(0, 1).forEach(({ path, message }) => {
          showError(`Invalid configuration: [${path.join(".")}]: ${message}`, {
            timeout: 10_000,
          });
        });
      }

      if (
        !changedSomethingElseThanNameOrDescription ||
        usage.length === 0 ||
        (await showConfirm({
          title: "Update configuration",
          message: `This configuration is used in ${usage.length} branch${usage.length !== 1 ? "es" : ""}, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        info("Saving...", { timeout: 3000, groupId: "cost-update" });
        updateLocal(updatedConfiguration);
        return saveToDbDebounced(
          projectId,
          updatedConfiguration,
          () => {
            setIsSaving(false);
            Mixpanel.track_old("Config saved", {
              type: "cost",
            });
            showSuccess("Saved", { timeout: 3000, groupId: "cost-update" });
          },
          () => {
            setIsSaving(false);
            currentConfig && updateLocal(currentConfig);
            showError(
              "Something went wrong when saving cost config, try again",
              { timeout: 3000, groupId: "cost-update" },
            );
          },
        );
      } else {
        setIsSaving(false);
      }
    },
    [
      info,
      projectId,
      setIsSaving,
      showConfirm,
      showError,
      showSuccess,
      updateLocal,
      projectConfigurations,
    ],
  );

  const deleteLocal = useJotaiCallback(
    async (_, set, configurationId: string) => {
      if (!projectId) return;
      set(
        projectCostConfigurationsFamily({
          projectId,
        }),
        async (cur) => {
          const ret = new Map(await cur);
          ret.delete(configurationId);
          return ret;
        },
      );
    },
    [projectId],
  );

  return {
    save,
    updateLocal,
    deleteLocal,
  };
};

export default useCostConfigurationCrud;
