import {
  getTotalExportSystemLoss,
  getTotalInterArrayLoss,
} from "analysis/electrical";
import { getConfiguration, getOtherLosses } from "analysis/inputs";
import {
  getAnalysisProgress,
  getNeighbourWakeLoss,
  getTotalWakeLoss,
  getTurbineSpecificLoss,
} from "analysis/output";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { orTextLoader } from "components/Loading/Skeleton";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { colors } from "styles/colors";
import { undefMap } from "utils/utils";
import { spaceMedium } from "../../../styles/space";
import { TurbineFeature } from "../../../types/feature";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import {
  formatElectricalLossToPercentDecimal,
  formatLossToPercent,
  formatPercent,
} from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";

const Disabled = () => (
  <p style={{ color: colors.primaryDisabled }}>Disabled</p>
);

const Loss = () => {
  const { park, triggerId, branch } = useDashboardContext();
  const parkId = park.id;
  const branchId = branch.id;
  const turbines: TurbineFeature[] = useAtomValue(
    turbinesInParkFamily({ parkId, branchId }),
  );

  const otherLoss = useAtomValue(getOtherLosses(triggerId));

  const totalInterArrayLoss = useAtomValue(
    loadable(getTotalInterArrayLoss(triggerId)),
  );

  const totalExportSystemLoss = useAtomValue(
    loadable(getTotalExportSystemLoss(triggerId)),
  );

  const totalWakeLoss = useAtomValue(loadable(getTotalWakeLoss(triggerId)));
  const turbineSpecificLoss = useAtomValue(
    loadable(getTurbineSpecificLoss(triggerId)),
  );
  const neighbourWakeLoss = useAtomValue(
    loadable(getNeighbourWakeLoss(triggerId)),
  );

  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  const configuration = useAtomValue(getConfiguration(triggerId));
  const showNeighbourWakeLoss =
    configuration?.wakeAnalysis.neighbourWake ?? false;

  const showInterArrayLoss =
    (configuration?.electrical.interArrayCableLoss ?? false) ||
    (configuration?.electrical.turbineTrafoLoss ?? false);

  const exportSystemIsEnabled =
    configuration?.electrical.exportSystemLoss ?? false;

  if (turbines.length === 0) {
    return (
      <CenterContainer>
        <SimpleAlert text={"No turbines in the park."} type={"error"} />
      </CenterContainer>
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Total wake loss</p>
        {orTextLoader(
          totalWakeLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (wl) => (
            <p>{formatLossToPercent(wl)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Neighbour wake loss</p>
        {showNeighbourWakeLoss ? (
          orTextLoader(
            neighbourWakeLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (nwl) => <p>{formatLossToPercent(nwl)}%</p>,
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <Row>
          <p style={{ display: "flex", gap: "0.8rem", fontWeight: 700 }}>
            Turbine-specific loss{" "}
          </p>
          <HelpTooltip
            text={
              "Proportion of the gross output that is lost to turbine-specific losses, which is specific on the turbine type."
            }
          />
        </Row>

        {orTextLoader(
          turbineSpecificLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (wl) => (
            <p>{formatLossToPercent(wl)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Total inter array loss</p>
        {showInterArrayLoss ? (
          orTextLoader(
            totalInterArrayLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (ia) => (
              <p>
                {formatElectricalLossToPercentDecimal(
                  ia?.totalInterArrayLoss ?? 0,
                )}
              </p>
            ),
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <p>Total export system loss</p>
        {exportSystemIsEnabled ? (
          orTextLoader(
            totalExportSystemLoss,
            undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
            (ia) => (
              <p>
                {formatElectricalLossToPercentDecimal(
                  ia?.totalExportSystemLoss ?? 0,
                )}
              </p>
            ),
          )
        ) : (
          <Disabled />
        )}
      </Row>

      <Row>
        <p>Other losses</p>
        <p>{formatPercent(otherLoss)}</p>
      </Row>
    </FlexGrid2>
  );
};

export const LossWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Losses"
      id="Losses"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <Loss />
    </SafeCard>
  );
};
