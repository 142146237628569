import { z } from "zod";

export const _ArchivedProjectVersion = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  author: z.string(),
  createdAt: z.number(),
  status: z.enum(["started", "completed", "failed"]),
  organisationId: z.string(),
  nodeId: z.string(),
  branchId: z.string(),
  parkId: z.string(),
  isLegacySnapshot: z.boolean().optional(),
});

export type ArchivedProjectVersion = z.infer<typeof _ArchivedProjectVersion>;
