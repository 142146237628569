import { useEffect } from "react";
import {
  activeTourState,
  activeTourStepState,
  stepOrderIndexMapState,
} from "./state";
import styled from "styled-components";
import { spacing4, spacing6 } from "styles/space";
import { typography } from "styles/typography";
import Button from "components/General/Button";
import CloseIcon from "@icons/24/Close.svg";
import { IconBtn } from "components/General/Icons";
import ReactMarkdown from "react-markdown";
import { Mixpanel } from "mixpanel";
import { useAtomValue, useSetAtom } from "jotai";

const TourHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TourTitle = styled.h1<{ modal?: boolean }>`
  font-size: 16px;
  margin: 0;
  ${({ modal }) =>
    !modal &&
    ` color: white;
`}
`;

const MarkdownContainer = styled.div<{ modal?: boolean }>`
  p {
    font-size: 1.3rem;
    ${({ modal }) =>
      !modal &&
      ` color: white;
`}
  }
`;

const TourWrapper = styled.div<{ active?: boolean; modal?: boolean }>`
  ${({ active }) =>
    active &&
    ` z-index: 10;
  `}

  ${({ modal }) =>
    modal
      ? ` 
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `
      : `
    position: relative;
  `}
`;

const Popover = styled.div<{ modal?: boolean }>`
  display: grid;
  box-shadow:
    0 4px 12px rgba(0, 0, 0, 0.1),
    0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: ${spacing4};
  gap: 16px;
  padding: 16px;

  ${({ modal }) =>
    modal
      ? ` 
    z-index: 2;
    width: 50rem;
    height: 20rem;
    position: relative;
    background: #fff;
  `
      : `
    position: absolute;
    z-index: 4;
    width: 200px;
    color: white;
    background: #023859;
  `}
`;

const ButtonWrapper = styled.div`
  width: auto;
  right: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
  margin-left: auto;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency as needed */
  z-index: 1;
`;

const Footer = styled.div``;

export type TourItemId = string;
type TourItemContent = {
  id: TourItemId;
  nextStep?: string;
  progressEvent?: string;
  step?: number;
  title: string;
  body: string;
  nextButton?: string;
  exitButton?: string;
  modal?: boolean;
};

export type TourInfo = {
  tour_id: string;
  default?: boolean;
  steps: TourItemContent[];
};

const TourStepInner = ({
  step,
  innerRef,
  activeTour,
  onExitStepAction,
}: {
  step: TourItemContent;
  innerRef?: ((node: HTMLElement | null) => void) &
    ((node: HTMLElement | null) => void);
  activeTour: TourInfo;
  onExitStepAction?: any;
}) => {
  const setActiveTour = useSetAtom(activeTourState);
  const setActiveStep = useSetAtom(activeTourStepState);

  const stepOrderIndexMap = useAtomValue(stepOrderIndexMapState);
  const stepIdx: number = (step && stepOrderIndexMap.get(step.id)) ?? 0;

  useEffect(() => {
    return () => {
      if (onExitStepAction) {
        onExitStepAction();
      }
    };
  }, [onExitStepAction]);

  return (
    <>
      {step.modal && <Backdrop />}
      <Popover ref={innerRef} modal={step.modal}>
        <TourHeader>
          <TourTitle modal={step.modal} style={{ ...typography.h3 }}>
            {step.title}
          </TourTitle>

          <IconBtn
            iconColor="white"
            hoverStroke="black"
            onClick={() => setActiveTour(undefined)}
          >
            <CloseIcon
              style={{
                height: spacing6,
                width: spacing6,
              }}
            />
          </IconBtn>
        </TourHeader>
        <MarkdownContainer modal={step.modal}>
          <ReactMarkdown>{step.body}</ReactMarkdown>
        </MarkdownContainer>
        <ButtonWrapper>
          {!step.modal && (
            <Footer>
              {stepIdx + 1} / {activeTour.steps.length}
            </Footer>
          )}
          {step.exitButton && (
            <Button
              buttonType="text"
              text={step.exitButton}
              onClick={() => setActiveTour(undefined)}
            />
          )}
          {step.nextButton && (
            <Button
              text={step.nextButton}
              onClick={() => {
                setActiveStep(step.nextStep);
                Mixpanel.track_old("Onboarding tour step", {
                  step: step.id,
                  tour: activeTour.tour_id,
                  completed: false,
                });
              }}
            />
          )}
        </ButtonWrapper>
      </Popover>
    </>
  );
};

export function TourStep({
  stepId,
  tourId,
  innerRef,
  style,
  onEnterStepAction,
  onExitStepAction,
}: {
  stepId: string;
  tourId: string;
  innerRef?: ((node: HTMLElement | null) => void) &
    ((node: HTMLElement | null) => void);
  style?: any;
  onEnterStepAction?: any;
  onExitStepAction?: any;
}) {
  const activeTour = useAtomValue(activeTourState);
  const activeStep = useAtomValue(activeTourStepState);

  const step = activeTour?.steps.find((s) => s.id === activeStep);
  const active = tourId === activeTour?.tour_id && stepId === activeStep;

  useEffect(() => {
    if (onEnterStepAction && active) {
      onEnterStepAction();
    }
  }, [active, onEnterStepAction]);

  if (!step) return <></>;

  return (
    <TourWrapper active={active} style={style} modal={step.modal}>
      {active && (
        <TourStepInner
          step={step}
          activeTour={activeTour}
          innerRef={innerRef}
          onExitStepAction={onExitStepAction}
        />
      )}
    </TourWrapper>
  );
}
