import Dashboard from "@icons/24/Dashboard.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import Button from "components/General/Button";
import { ColoredGrid } from "components/General/Form";
import {
  Divider,
  OverlineText,
  OverlineTextWrapper,
  ResultValue,
} from "components/General/GeneralSideModals.style";
import { orLoader } from "components/Loading/Skeleton";
import { formatGWhConditional } from "components/ProductionV2/format";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { ButtonWrapper } from "components/UploadModal/components/shared";
import { useRealValueCapexMillion } from "finance/hooks/useCapex";
import { useLcoe } from "finance/hooks/useLcoe";
import { FinanceId } from "finance/types";
import { useAtomValue, useSetAtom } from "jotai";
import { costConfigurationSelectedFamily } from "state/jotai/costConfiguration";
import { DashboardModalType, modalTypeOpenAtom } from "state/modal";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { IconREMSize, typography } from "styles/typography";
import { lunwrap } from "utils/jotai";
import { roundToDecimal } from "utils/utils";
import { MILLION } from "../../../constants/financialAnalysis";
import {
  ScrollBody,
  useShowScrollShadow,
} from "../../../hooks/useShowScrollShadow";
import { CostUnit, unitToScalingMap } from "../../../types/financial";
import Tooltip from "../../General/Tooltip";
import GeneralCostConfidenceBar from "../ConfidenceBar";
import { PVInfoTag } from "../costUtils";
import {
  BottomTableWrapper,
  ResultWrapper,
  RowKey,
  RowValue,
  SumTotalValue,
  TableWrapper,
} from "../styles";
import { DownloadLcoeDataButton } from "./DownloadLcoeData";
import LcoeFormula from "./LcoeFormula";

export const Lcoe = ({ id }: { id: FinanceId }) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom);

  const setModalType = useSetAtom(modalTypeOpenAtom);
  const selectedCostConfig = useAtomValue(
    costConfigurationSelectedFamily({ projectId, branchId }),
  );
  const useConfidenceLevel = selectedCostConfig?.useConfidenceLevel;
  const { costs: capexCosts } = useRealValueCapexMillion(id);

  const {
    inputs,
    results: { devex, capex, opex, decom, lcoe },
  } = useLcoe(id);

  return (
    <>
      <ScrollBody ref={scrollBodyRef}>
        <TableWrapper>
          <NewToggleableList
            title="DEVEX"
            tag={<PVInfoTag />}
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                {orLoader(devex.npv, (npv) => (
                  <span>
                    <strong>{npv}</strong> {CostUnit.millionEuro}
                  </span>
                ))}
              </ResultValue>
            }
            loading={devex.cashFlow.state === "loading"}
          >
            {orLoader(devex.cashFlow, (cashFlow) => (
              <>
                {cashFlow.map(({ year, value }) => (
                  <ColoredGrid key={`capex-${year}`}>
                    <ResultValue>{year}</ResultValue>
                    <ResultValue>
                      <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
              </>
            ))}
          </NewToggleableList>

          <NewToggleableList
            title="CAPEX"
            tag={<PVInfoTag text="Note this might differ from Total CAPEX." />}
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                <span>
                  {orLoader(capex.npv, (npv) => (
                    <span>
                      <strong>{npv}</strong> {CostUnit.millionEuro}
                    </span>
                  ))}
                </span>
              </ResultValue>
            }
            loading={capex.cashFlow.state === "loading"}
          >
            {orLoader(capex.cashFlow, (cashFlow) => (
              <>
                {useConfidenceLevel && (
                  <GeneralCostConfidenceBar
                    key={"capex"}
                    generalCosts={lunwrap(capexCosts)}
                  />
                )}
                {cashFlow.map(({ year, value }, index) => (
                  <ColoredGrid key={`capex-${year}`} noColor={index % 2 !== 0}>
                    <RowKey>{year}</RowKey>
                    <ResultValue>
                      <strong> {roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
                {
                  <ColoredGrid key={`capex-contingency`} noColor>
                    <p>{"Includes contingency"}</p>
                  </ColoredGrid>
                }
              </>
            ))}
          </NewToggleableList>
          <NewToggleableList
            title="OPEX"
            tag={<PVInfoTag />}
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                {orLoader(opex.npv, (npv) => (
                  <span>
                    <strong>{npv}</strong> {CostUnit.millionEuro}
                  </span>
                ))}
                <span></span>
              </ResultValue>
            }
            loading={opex.npv.state === "loading"}
          >
            <div>
              {orLoader(opex.distribution, (distribution) => (
                <>
                  {distribution.map(({ id, name, percent }, index) => (
                    <ColoredGrid key={id} noColor={index % 2 !== 0}>
                      <p>{name}</p>
                      <ResultValue>
                        <strong>{percent}</strong> {CostUnit.percent}
                      </ResultValue>
                    </ColoredGrid>
                  ))}
                </>
              ))}
            </div>
            <div>
              {orLoader(opex.cashFlow, (cashFlow) => (
                <>
                  {cashFlow.slice(0, 1).map(({ year, value }, index) => (
                    <ColoredGrid
                      style={{ gridTemplateColumns: "2fr 1fr" }}
                      key={`capex-${year}`}
                      noColor={index % 2 !== 1}
                    >
                      <p>First production year ({year}) </p>
                      <ResultValue>
                        <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                        {CostUnit.millionEuro}
                      </ResultValue>
                    </ColoredGrid>
                  ))}
                </>
              ))}
            </div>
          </NewToggleableList>

          <NewToggleableList
            title="DECOM"
            tag={<PVInfoTag />}
            chevronSize="1rem"
            defaultOpen={false}
            value={
              <ResultValue>
                {orLoader(decom.npv, (npv) => (
                  <span>
                    <strong>{npv}</strong> {CostUnit.millionEuro}
                  </span>
                ))}
              </ResultValue>
            }
            loading={decom.npv.state === "loading"}
          >
            {orLoader(decom.cashFlow, (cashFlow) => (
              <>
                {cashFlow.map(({ year, value }, index) => (
                  <ColoredGrid key={`capex-${year}`} noColor={index % 2 !== 0}>
                    <p>{year}</p>
                    <ResultValue>
                      <strong>{roundToDecimal(value / MILLION, 1)}</strong>{" "}
                      {CostUnit.millionEuro}
                    </ResultValue>
                  </ColoredGrid>
                ))}
              </>
            ))}
          </NewToggleableList>
          <OverlineTextWrapper>
            <OverlineText>Net AEP</OverlineText>

            <RowValue>
              {orLoader(inputs.netAEP, (value) => (
                <ResultValue>
                  <strong>{formatGWhConditional(value, true)}</strong>{" "}
                  {CostUnit.gigaWattHours}
                </ResultValue>
              ))}
            </RowValue>
          </OverlineTextWrapper>

          <NewToggleableList
            title="Inputs"
            chevronSize="1rem"
            defaultOpen={true}
          >
            <ColoredGrid>
              <p>Discount rate</p>
              <RowValue>
                {orLoader(inputs.discountRate, (value) => (
                  <ResultValue>
                    <strong>{`${roundToDecimal(
                      value / unitToScalingMap[CostUnit.percent],
                      1,
                    )}`}</strong>{" "}
                    {CostUnit.percent}
                  </ResultValue>
                ))}
              </RowValue>
            </ColoredGrid>
            <ColoredGrid noColor>
              <p>Inflation rate</p>
              <RowValue>
                {orLoader(inputs.inflationRate, (value) => (
                  <ResultValue>
                    <strong>{`${roundToDecimal(
                      value / unitToScalingMap[CostUnit.percent],
                      1,
                    )} `}</strong>{" "}
                    {CostUnit.percent}
                  </ResultValue>
                ))}
              </RowValue>
            </ColoredGrid>
            <ColoredGrid>
              <p>CAPEX Contingency</p>
              <RowValue>
                {orLoader(inputs.capexContingency, (value) => (
                  <ResultValue>
                    <strong>{`${roundToDecimal(
                      value / unitToScalingMap[CostUnit.percent],
                      1,
                    )}`}</strong>{" "}
                    {CostUnit.percent}
                  </ResultValue>
                ))}
              </RowValue>
            </ColoredGrid>
            <ColoredGrid noColor>
              <p>OPEX Contingency</p>
              <RowValue>
                {orLoader(inputs.opexContingency, (value) => (
                  <ResultValue>
                    <strong>{`${roundToDecimal(
                      value / unitToScalingMap[CostUnit.percent],
                      1,
                    )}`}</strong>{" "}
                    {CostUnit.percent}
                  </ResultValue>
                ))}
              </RowValue>
            </ColoredGrid>
            <ColoredGrid>
              <p>Life time</p>
              <RowValue>
                {orLoader(inputs.lifeTime, (value) => (
                  <ResultValue>
                    <strong>{value}</strong> years
                  </ResultValue>
                ))}
              </RowValue>
            </ColoredGrid>
          </NewToggleableList>
        </TableWrapper>
      </ScrollBody>
      <Divider />
      <BottomTableWrapper>
        <ResultWrapper>
          <Tooltip
            text="LCoE"
            content={<LcoeFormula />}
            position="bottomLeft"
            maxWidth="40rem"
          >
            <span style={{ ...typography.sub1, paddingRight: "0.8rem" }}>
              LCoE
            </span>
            <IconREMSize height={1.4} width={1.4}>
              <InfoIcon />
            </IconREMSize>
          </Tooltip>

          {orLoader(lcoe, (l) => (
            <SumTotalValue>
              <strong id={"lcoe-value"}>{l?.toFixed(1) ?? "-"}</strong> €/MWh
            </SumTotalValue>
          ))}
        </ResultWrapper>
        <ButtonWrapper style={{ padding: 0 }}>
          <DownloadLcoeDataButton id={id} buttonType="text" />
          <Button
            text="View in dashboard"
            buttonType="secondary"
            icon={<Dashboard />}
            onClick={() => {
              setModalType({
                modalType: DashboardModalType,
                metadata: { id: "vind-preset-dashboard-cost" },
              });
            }}
          />
        </ButtonWrapper>
      </BottomTableWrapper>
      {/* </Suspense> */}
    </>
  );
};
