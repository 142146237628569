import styled from "styled-components";
import { Row } from "components/General/Layout";
import { colors } from "styles/colors";
import { IconBtn } from "components/General/Icons";

const SettingsRow = styled(Row)<{
  isFocused?: boolean;
  withEveryOtherGray?: boolean;
}>`
  align-items: center;
  padding: 0 0.4rem;
  border-radius: 0.4rem;
  min-height: 3.2rem;
  background-color: ${({ isFocused }) =>
    isFocused ? colors.surfaceSelectedLight : "transparent"};
  transition: background-color 0.2s ease;

  &:nth-child(even) {
    background-color: ${({ withEveryOtherGray }) =>
      withEveryOtherGray ? colors.surfaceSecondary : "transparent"};
  }

  &:hover {
    background-color: ${({ isFocused }) =>
      isFocused ? colors.surfaceSelectedLight : colors.surfaceHover};
  }
`;

export const DeleteButton = styled(IconBtn)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin-left: auto;
  &:hover {
    background-color: ${colors.iconSecondaryHover};
  }
`;

export const SettingsRowWithHover = styled(SettingsRow)`
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;
