import { RoundIconWrapper } from "components/CompareParksModal/shared";
import Tooltip from "components/General/Tooltip";
import { ProcurementCostComponentType } from "services/procurementCostService";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import { ResourceNameText, SubTitle } from "../../style";
import { PROCUREMENT_COST_ITEMS } from "./ProcurementCostsModal";
import { borderRadiusMedium } from "styles/space";
import styled from "styled-components";

export default function ProcurementCostItems({
  activeComponentId,
  onClick,
}: {
  activeComponentId?: ProcurementCostComponentType;
  onClick: (id: ProcurementCostComponentType) => void;
}) {
  return (
    <>
      <SubTitle>Name</SubTitle>
      {PROCUREMENT_COST_ITEMS.map((item) => (
        <ComponentTableItemEntry
          selected={activeComponentId === item.id}
          key={item.id}
          title={item.title}
          onClick={onClick}
          icon={item.icon}
          id={item.id}
        />
      ))}
    </>
  );
}

const ComponentItemTitle = styled.div`
  display: flex;
  cursor: pointer;
  gap: 0.8rem;
  text-align: left;
  overflow: hidden;
  border-radius: ${borderRadiusMedium};
`;

const ComponentTableItemEntry = ({
  title,
  onClick,
  selected,
  icon,
  id,
}: {
  title: string;
  onClick: (id: ProcurementCostComponentType) => void;
  selected: boolean;
  icon: React.ReactNode;
  id: ProcurementCostComponentType;
}) => {
  return (
    <ComponentItemTitle
      onClick={() => onClick(id)}
      style={{
        padding: "0.8rem 1.1rem",
        alignItems: "center",
        cursor: "pointer",
        ...(selected ? { backgroundColor: colors.surfaceSelectedLight } : {}),
      }}
    >
      <IconREMSize height={1.6} width={1.6} stroke={colors.iconDefault}>
        <RoundIconWrapper style={{ backgroundColor: colors.green100 }}>
          {icon}
        </RoundIconWrapper>
      </IconREMSize>
      <Tooltip text={title}>
        <ResourceNameText>{title}</ResourceNameText>
      </Tooltip>
    </ComponentItemTitle>
  );
};
