import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
  getDataPackageResourcesOnNode,
} from "components/Organisation/Library/service";
import {
  dataPackageResourceWithAccessOnNodeState,
  nodesWithAccessToDataPackage,
  dataPackageResourceWithAccessOnNodeStateRefreshAtom,
} from "components/Organisation/Library/state";
import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import { getDataLayersUsage } from "components/Organisation/Library/dataLibrary/state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const useNodeDataPackageResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, dataPackage: DataLibraryPackage) => {
      const fallback = await get(
        dataPackageResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      return addOrUpdateResourceOnNode(
        nodeId,
        dataPackage.id,
        "org_data_package_manage",
      )
        .then(async () => {
          const updatedConfigs = await getDataPackageResourcesOnNode(nodeId);
          aset(
            get,
            set,
            dataPackageResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => updatedConfigs,
          );
          set(
            nodesWithAccessToDataPackage({
              organisationId,
              resourceId: dataPackage.id,
            }),
          );
          set(
            dataPackageResourceWithAccessOnNodeStateRefreshAtom,
            (cur) => cur + 1,
          );
        })
        .catch((e) => {
          aset(
            get,
            set,
            dataPackageResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        dataPackageResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const currentConfig = fallback.find((c) => c.config.id === resourceId);
      if (currentConfig) {
        currentConfig?.config.layerIds;
        const usage = await get(
          getDataLayersUsage({
            nodeId,
            layerIds: currentConfig.config.layerIds,
          }),
        );

        const totalUsage = Object.values(usage).flat();
        if (
          totalUsage.length !== 0 &&
          !(await showConfirm({
            title: "Remove access",
            message: `Data package configuration is currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
            confirmButtonText: "Remove",
          }))
        ) {
          return;
        }
      }

      return deleteResourceOnNode(nodeId, resourceId, "org_data_package_manage")
        .then(() => {
          aset(
            get,
            set,
            dataPackageResourceWithAccessOnNodeState({
              nodeId,
            }),
            (cur) => cur.filter((c) => c.config.id !== resourceId),
          );
          set(
            nodesWithAccessToDataPackage({
              organisationId,
              resourceId,
            }),
          );
          set(
            dataPackageResourceWithAccessOnNodeStateRefreshAtom,
            (cur) => cur + 1,
          );
        })
        .catch((e) => {
          aset(
            get,
            set,
            dataPackageResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeDataPackageResourcesCrud;
