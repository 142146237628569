import { atom } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { loggedInUserIdAtom } from "state/user";
import { atomFamily } from "utils/jotai";
import { sendInfo } from "utils/sentry";
import { z } from "zod";

const HIDDEN_FEATURES_LS_KEY = "vind:hidden-feature-ids";
const getHiddenFeaturesLocalStorageKey = (
  nodeId: string,
  branchId: string,
  userId: string,
) => HIDDEN_FEATURES_LS_KEY.concat("-", nodeId, "-", branchId, "-", userId);

export const hiddenFeatureIdsFamily = atomFamily(
  ({ branchId }: { branchId: string }) => {
    const backing = atom<string[] | undefined>(undefined);
    return atom(
      (get) => {
        const projectId = get(projectIdAtom);
        const userId = get(loggedInUserIdAtom);

        const curr = get(backing);
        if (curr) return curr;

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !branchId ||
          !userId
        ) {
          return [];
        }

        const key = getHiddenFeaturesLocalStorageKey(
          projectId,
          branchId,
          userId,
        );

        try {
          const storageItem = localStorage.getItem(key);
          if (storageItem) {
            const result = JSON.parse(storageItem);
            return z.array(z.string()).parse(result);
          }
        } catch (err) {
          sendInfo("Could not read local storage", {
            key: key,
            err,
          });
        }

        return [];
      },
      (get, set, newValue: string[]) => {
        set(backing, newValue);
        const projectId = get(projectIdAtom);
        const branchId = get(branchIdAtom);
        const userId = get(loggedInUserIdAtom);

        if (
          typeof window === "undefined" ||
          !("localStorage" in window) ||
          !projectId ||
          !branchId ||
          !userId
        ) {
          return;
        }

        const key = getHiddenFeaturesLocalStorageKey(
          projectId,
          branchId,
          userId,
        );
        try {
          if (newValue.length === 0) {
            localStorage.removeItem(key);
            return;
          }

          localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {
          sendInfo("Could not set local storage", {
            key: key,
            newValue,
          });
        }
      },
    );
  },
);
