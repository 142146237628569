import { SubstationType } from "services/substationService";
import { Label } from "components/General/Form";
import {
  SecondaryText,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import { InputDimensioned } from "components/General/Input";
import { substationChangelogInfo } from "components/InputChangelog/const";
import { Row } from "components/General/Layout";
import { Title } from "./shared";
import ShadeToggleableList from "components/ToggleableList/ShadeToggleableList";
import { useLocalStorage } from "hooks/useBrowserStorage";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import React from "react";
import { colors } from "styles/colors";

const SubstationConverterGraph = React.lazy(
  () => import("../../SubstationConverterGraph"),
);

type DCSettingsProps = {
  substation: SubstationType;
  onChange: (substation: SubstationType) => void;
  disabled?: boolean;
  nodeId: string;
  isLibraryResource?: boolean;
  libraryResourceInOnshoreProjectContext?: boolean;
};

export const DCSettings = ({
  substation,
  onChange,
  disabled,
  nodeId,
  isLibraryResource,
  libraryResourceInOnshoreProjectContext,
}: DCSettingsProps) => {
  const [isOpen, setIsOpen] = useLocalStorage<boolean | undefined>(
    `vind:substation-DCsettings-open`,
  );

  const fractionToPercentage = 100;

  return (
    <ShadeToggleableList
      disableOpenChange={libraryResourceInOnshoreProjectContext}
      contentStyle={{ minWidth: "40rem" }}
      title={
        isLibraryResource ? (
          <Row alignCenter>
            <div>DC Settings</div>
            <p
              style={{
                color: colors.textDisabled,
              }}
            >
              {`Only ${libraryResourceInOnshoreProjectContext ? "shown" : "used"} in offshore projects`}
            </p>
          </Row>
        ) : (
          "DC settings"
        )
      }
      defaultOpen={
        libraryResourceInOnshoreProjectContext ? false : isOpen ?? false
      }
      onOpenChange={setIsOpen}
    >
      <TitleContainer>
        <Title>Transformer</Title>
      </TitleContainer>
      <div
        style={{
          paddingTop: "0.8rem",
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label style={{ flex: 0 }}>
          <SecondaryText>Resistance</SecondaryText>
          <InputDimensioned
            style={{ width: "20rem" }}
            disabled={disabled}
            decimals={3}
            value={substation.dcResistance}
            unit="%"
            scaleFactor={fractionToPercentage}
            step={0.01}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage="Must be between 0 and 10"
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "dcResistance",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                dcResistance: e,
              });
            }}
            placeholder="0.14"
          />
        </Label>

        <Label style={{ flex: 0 }}>
          <SecondaryText>Iron losses</SecondaryText>
          <InputDimensioned
            style={{ width: "20rem" }}
            disabled={disabled}
            decimals={3}
            scaleFactor={fractionToPercentage}
            value={substation.dcIronLosses}
            unit="%"
            step={0.1}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage="Must be between 0 and 10"
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "dcIronLosses",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                dcIronLosses: e,
              });
            }}
            placeholder="0.14"
          />
        </Label>
      </div>

      <Row style={{ marginTop: "1.0rem" }}>
        <TitleContainer>
          <Title>Converter</Title>
          <HelpTooltip
            size={12}
            text="Converter loss in percentage of rated park power is taken as A + B * Pu + C * Pu^2, where Pu is the power production relative to full load."
          />
        </TitleContainer>
      </Row>

      {/* Converter settings */}
      <div
        style={{
          paddingTop: "0.8rem",
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        {/* Loss factors A, B, C */}
        <Label style={{ flex: 0 }}>
          <SecondaryText>Loss factor A</SecondaryText>
          <InputDimensioned
            style={{ width: "20rem" }}
            disabled={disabled}
            decimals={3}
            scaleFactor={fractionToPercentage}
            value={substation.lossFactorA}
            unit="%"
            step={0.1}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage="Must be between 0 and 10"
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "lossFactorA",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                lossFactorA: e,
              });
            }}
          />
        </Label>
        <Label style={{ flex: 0 }}>
          <SecondaryText>Loss factor B</SecondaryText>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            decimals={3}
            scaleFactor={fractionToPercentage}
            value={substation.lossFactorB}
            initialValue={substation.lossFactorB}
            unit={"%"}
            step={0.1}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage={`Must be between 0 and 10`}
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "lossFactorB",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                lossFactorB: e as any,
              });
            }}
          />
        </Label>
        <Label
          style={{
            flex: 0,
          }}
        >
          <SecondaryText>Loss factor C</SecondaryText>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            decimals={3}
            scaleFactor={fractionToPercentage}
            value={substation.lossFactorC}
            initialValue={substation.lossFactorC}
            unit={"%"}
            step={0.1}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage={`Must be between 0 and 10`}
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "lossFactorC",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                lossFactorC: e as any,
              });
            }}
          />
        </Label>
      </div>

      <Label>
        <Row style={{ marginTop: "8rem" }}>
          <React.Suspense fallback={null}>
            <SubstationConverterGraph substation={substation} />
          </React.Suspense>
        </Row>
      </Label>
    </ShadeToggleableList>
  );
};
