import { useAtomValue } from "jotai";
import type React from "react";
import stc from "string-to-color";
import {
  CustomLayer,
  CustomLibraryLayer,
  ExternalDataSource,
  Layer,
  LayerType,
  PrivateDataSource,
} from "../../types/layers";
import { spacing4 } from "styles/space";
import { arcgisLayerDrawingInfoSelector } from "state/arcgisRestAPI";
import { rgbToHex } from "utils/image";
import Linestring from "@icons/24/Line.svg";
import Point from "@icons/24/Point.svg";
import Polygon from "@icons/24/Square.svg";
import Upload from "@icons/24/Upload.svg";
import WMSLayerIcon from "@icons/24/WmsLayer.svg";
import {
  MapColorIndicator,
  TransparentMapColorIndicator,
} from "../General/MapColorIndicator";
import { LayerSettings } from "./LayerSettings/types";
import { CUSTOM_LAYER_TYPE } from "@constants/customLayers";
import { SourceName } from "./LayerSettings/state";

export const LayerIconMapper = ({ layer }: { layer: Layer }) => {
  if (isCustomLayer(layer)) {
    return <Upload />;
  }

  switch (layer.type) {
    case LayerType.Raster:
      return <WMSLayerIcon />;
    case LayerType.Point:
    case LayerType.Circle:
      // TODO: new icon for circlr
      return <Point />;
    case LayerType.Line:
      return <Linestring />;
    case LayerType.Polygon:
    case LayerType.FeatureCollection:
      // TODO: consider something else for featurecollection
      return <Polygon />;
    case LayerType.wms:
    case LayerType.wmts:
    case LayerType.XYZ:
      return <WMSLayerIcon />;
    default:
      return null;
  }
};

export const LayerColorMarkerMapper = ({
  layer,
  settings,
}: {
  layer: Layer;
  settings: LayerSettings;
}) => {
  const drawingInfo = useAtomValue(
    arcgisLayerDrawingInfoSelector({
      layer,
    }),
  );

  if (settings.overrideLayerStyle === false) {
    if (
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol?.color?.length === 4
    ) {
      const [r, g, b] = drawingInfo.symbol.color;
      return (
        <MapColorIndicator
          color={rgbToHex(r, g, b)}
          opacity={settings.layerStyle?.opacity}
          style={{
            width: "1rem",
            height: `4.8rem`,
            borderRadius: "4px",
            flexShrink: 0,
            marginRight: spacing4,
          }}
        />
      );
    }

    return (
      <MapColorIndicator
        color={stc(layer.id)}
        opacity={settings.layerStyle?.opacity}
        style={{
          width: "1rem",
          height: `4.8rem`,
          borderRadius: "4px",
          flexShrink: 0,
          marginRight: spacing4,
        }}
      />
    );
  }

  if (isCustomLayer(layer)) {
    return (
      <MapColorIndicator
        color={settings.layerStyle?.color}
        opacity={settings.layerStyle?.opacity}
        style={{
          width: "1rem",
          height: `4.8rem`,
          borderRadius: "4px",
          flexShrink: 0,
          marginRight: spacing4,
        }}
      />
    );
  }

  switch (layer.type) {
    case "point":
    case "circle":
    case "line":
    case "polygon":
    case "featureCollection":
      return (
        <MapColorIndicator
          color={settings.layerStyle?.color}
          opacity={settings.layerStyle?.opacity}
          style={{
            width: "1rem",
            height: `4.8rem`,
            borderRadius: "4px",
            flexShrink: 0,
            marginRight: spacing4,
          }}
        />
      );
    default:
      return (
        <TransparentMapColorIndicator
          style={{
            width: "0",
            height: `4.8rem`,
            borderRadius: "4px",
            flexShrink: 0,
          }}
        />
      );
  }
};

export const isCustomLayer = (layer: Layer): layer is CustomLayer =>
  isCustomUploadedLayer(layer) || isCustomLibraryLayer(layer);

export const isCustomUploadedLayer = (layer: Layer): layer is CustomLayer =>
  CUSTOM_LAYER_TYPE.CUSTOM_LAYER_SOURCE_TYPE === layer.sourceType;

export const isCustomLibraryLayer = (
  layer: Layer,
): layer is CustomLibraryLayer => {
  return (
    layer.sourceType === CUSTOM_LAYER_TYPE.CUSTOM_LIBRARY_LAYER_SOURCE_TYPE
  );
};

export const getSourceTitle = (layer: Layer, selectedSourceName: SourceName) =>
  isCustomUploadedLayer(layer)
    ? "Vind - Uploaded"
    : isCustomLibraryLayer(layer)
      ? "Vind - Library"
      : selectedSourceName === SourceName.Original && layer.source.originalName
        ? layer.source.originalName
        : layer.source.name;

export const isPrivateSource = (
  source: ExternalDataSource | PrivateDataSource,
): source is PrivateDataSource => {
  return source.private === true;
};

export function sortLayers(layers: Layer[]): Layer[] {
  return [...layers].sort((a, b) => {
    const na: string = a.name;
    const nb: string = b.name;
    return na.localeCompare(nb);
  });
}

// create a simple hash and use first 5 chars
export function getLayerIdHash(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  const full = new Uint32Array([hash])[0].toString(36);
  return full.substring(0, 5);
}

export function visibleInUrl(layerId: string, urlKey: string) {
  const layerIdHash = getLayerIdHash(layerId);
  const sp = new URLSearchParams(window.location.search);
  const visibleLayerHash = sp.get(urlKey);

  if (!visibleLayerHash) return false;
  console.log({
    layerId,
    layerIdHash,
    visibleLayerHash,
  });
  return visibleLayerHash.includes(layerIdHash);
}
