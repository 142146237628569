import { Load } from "../types/Load";
import { With } from "../utils/utils";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { z } from "zod";
import * as spec from "../api/customer";
import * as projectServiceSpec from "../api/project-service";
import { Body } from "../types/utils";
import { uuidRegex } from "@constants/regex";
import { scream } from "utils/sentry";
import { _OrganisationStatus } from "components/OrgToolingInternal/types";

export const {
  Type: _Type,

  PersonalFolderNodeInformation: _PersonalFolderNodeInformation,

  FolderNodeInformation: _FolderNodeInformation,

  ProjectNodeInformation: _ProjectNodeInformation,

  OrganisationUserInformation: _OrganisationUserInformation,
  NodeInformation: _Node,

  NodeMutable: _NodeMutable,
} = spec.components.schemas;

export const {
  BranchesAndParksResponse: _BranchAndParksInProjectResult,
  BranchWithParks: _BranchAndParksInProject,
} = projectServiceSpec.components.schemas;

export type LoadingFolder = {
  loading: true;
  type: "folder" | "project";
  id: string;
  createdAt: number;
  parent?: string;
};

export const _InvalidateNode = z.object({
  nodeId: z.string(),
});
export type InvalidateNode = z.infer<typeof _InvalidateNode>;

export const _MoveNode = z.object({
  nodeId: z.string(),
  toNodeId: z.string(),
  topLevelNodeId: z.string(),
});
export type MoveNode = z.infer<typeof _MoveNode>;
export const _RemoveNode = z.object({
  nodeId: z.string(),
});

type FolderNodeInformation = z.infer<typeof _FolderNodeInformation>;
export type ProjectNodeInformation = z.infer<typeof _ProjectNodeInformation>;
type PersonalFolderNodeInformation = z.infer<
  typeof _PersonalFolderNodeInformation
>;
type OrganisationUserInformation = z.infer<typeof _OrganisationUserInformation>;

export const isProjectNodeInformation = (
  n: Node,
): n is ProjectNodeInformation => n.type === "project";

export const isFolderNodeInformation = (n: Node): n is FolderNodeInformation =>
  n.type === "folder";

export const isPersonalFolderNodeInformation = (
  n: Node,
): n is PersonalFolderNodeInformation => n.type === "personal_folder";

export type NodeType = z.infer<typeof _Type>;

export type Node = z.infer<typeof _Node>;

export type BranchAndParksInProject = z.infer<typeof _BranchAndParksInProject>;

export type BranchAndParksInProjectResult = z.infer<
  typeof _BranchAndParksInProjectResult
>;

export type NodeMutable = z.infer<typeof _NodeMutable>;

type LoadingNode = {
  loading: true;
  type?: "folder" | "project";
  id: string;
  createdAt: number;
  parent?: string;
};

export type LoadNode = Load<Node, LoadingNode>;

export const listOrganisations = async (): Promise<
  OrganisationUserInformation[]
> => {
  return fetchSchemaWithToken(
    _OrganisationUserInformation
      .extend({
        status: _OrganisationStatus,
      })
      .array(),
    `/api/customer/organisation/user`,
    {
      method: "get",
    },
  );
};

export const deleteNode = async (nodeId: string) =>
  await fetchEnhancerWithToken(`/api/customer/node/${nodeId}`, {
    method: "delete",
  });

export const getNode = async (nodeId: string) => {
  if (!uuidRegex.test(nodeId)) {
    const error = new Error("Node id is not a uuid");
    scream(error, { nodeId, link: window.location.href });
  }
  const res = await fetchEnhancerWithToken(`/api/customer/node/${nodeId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const j = await res.json();
  return _Node.parse(j);
};

export type PostNode = Body<typeof spec, "/api/customer/node/{nodeId}", "post">;
export const postNode = async (args: PostNode) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

type PostOrgNode = Body<
  typeof spec,
  "/api/customer/v2/organisation/{organisationId}",
  "post"
>;
export const postOrgNode = async (args: PostOrgNode) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/v2/organisation/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

type CreateProjectNode = Body<
  typeof spec,
  "/api/customer/v2/node/{nodeId}",
  "post"
>;
export const createProjectNode = async (args: CreateProjectNode) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/v2/node/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

export const createProjectNodeInOrganisation = async (
  args: CreateProjectNode,
) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/v2/organisation/${args.parent_id}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

type PutNode = Body<typeof spec, "/api/customer/node/{nodeId}", "put">;
export const putNode = async (args: With<PutNode, "id">) => {
  const res = await fetchEnhancerWithToken(`/api/customer/node/${args.id}`, {
    method: "put",
    body: JSON.stringify(args),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const j = await res.json();
  return _Node.parse(j);
};

export const moveNode = async (nodeId: string, toNodeId: string) => {
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/${nodeId}/move/${toNodeId}`,
    {
      method: "post",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const j = await res.json();
  return _Node.parse(j);
};

export const getNodesInOrganisation = async (
  organisationId: string,
): Promise<Node[]> => {
  if (!organisationId) return [];
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/organisation/${organisationId}`,
    {
      method: "get",
    },
  );
  const j = await res.json();
  return _Node.array().parse(j);
};
export const admin_getNodesInOrganisation = async (
  organisationId: string,
): Promise<Node[]> => {
  if (!organisationId) return [];
  const res = await fetchEnhancerWithToken(
    `/api/customer/node/organisation/${organisationId}/admin`,
    {
      method: "get",
    },
  );
  const j = await res.json();
  return _Node.array().parse(j);
};

export const admin_getBranchesAndParksInProject = async (
  organisationId: string,
  projectId: string,
): Promise<BranchAndParksInProject[]> => {
  const res = await fetchSchemaWithToken(
    _BranchAndParksInProjectResult,
    `/api/project-service/branches-parks-meta/organisation/${organisationId}/project/${projectId}`,
    {
      method: "get",
    },
  );
  return res.branches;
};

export const getTopLevelNodeForNodeId = async (
  organisationId: string,
  nodeId: string,
): Promise<Node | undefined> => {
  if (!organisationId) return undefined;
  const res = await fetchEnhancerWithToken(
    `/api/customer/organisation/${organisationId}/team/node/${nodeId}`,
    {
      method: "get",
    },
  );
  try {
    const j = await res.json();
    return _Node.parse(j);
  } catch (e) {
    return;
  }
};
