import React, { ErrorInfo, ReactNode, Suspense, useMemo, useRef } from "react";
import * as turf from "@turf/turf";
import DepthAnalysis from "./DepthAnalysis";
import SlopeAnalysis from "./SlopeAnalysis";
import AddIcon from "@icons/24/Add.svg?react";
import PolygonIcon from "@icons/16/Polygon.svg?react";
import { IconREMSize } from "../../../../styles/typography";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { scream } from "../../../../utils/sentry";
import {
  ExclusionDomainDefault,
  ProjectFeature,
} from "../../../../types/feature";
import { MultiPolygon, Polygon } from "geojson";
import Button from "components/General/Button";
import useBooleanState from "hooks/useBooleanState";
import { Anchor } from "components/General/Anchor";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { configurationMenuActiveAtom, TopRightModeActiveAtom } from "./state";
import useAddFeaturesIntoElementsWithinLimits from "hooks/useAddFeaturesIntoElementsWithinLimits";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
  SUB_AREA_COLOR,
} from "@constants/division";
import { multiFeatureToFeatures, splitMultiPolygon } from "utils/geojson/utils";
import { v4 as uuidv4 } from "uuid";
import { useClickOutside } from "hooks/useClickOutside";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { useAtomValue, useSetAtom } from "jotai";
import { getGzippedBase64EncodedSize } from "utils/utils";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { displayLabelPropertyName } from "@constants/canvas";
import { editorAccessProjectSelector } from "state/user";

const MAX_AREA_SQM_ALLOWED = 2200 * 1000 * 1000;

type Props = {
  canvasFeature: ProjectFeature<Polygon>;
  onClose?(): void;
};

type ErrorProps = {
  children: ReactNode;
};

const formatArea = (area: number) => Math.round(area / (1000 * 1000)) + " km²";
class SlopeErrorBoundary extends React.Component<
  ErrorProps,
  { hasError: boolean; error?: Error }
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    scream(error, { message: "SlopeErrorBoundary caught", errorInfo });
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <SimpleAlert
            text={"Something went wrong when running slope analysis..."}
            type={"error"}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

const AddAnalysisModal = styled(StandardBox)`
  display: flex;
  flex-direction: column;
`;

const AddAnalysisRow = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1.6rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.hover};
  }

  path {
    fill: ${(p) => p.color};
  }
`;

export const AddAnalysisButton = ({
  region,
  parkId,
}: {
  region: ProjectFeature<Polygon | MultiPolygon>;
  parkId?: string;
}) => {
  const projectEditorAccess = useAtomValue(editorAccessProjectSelector);
  const ref = useRef<HTMLDivElement>(null);
  const [showAddAnalysis, toggleShowAddAnalysis, setShowAddAnalysis] =
    useBooleanState(false);
  const setTopRightModeActive = useSetAtom(TopRightModeActiveAtom);
  const addFeaturesToElementsWithinLimits =
    useAddFeaturesIntoElementsWithinLimits();
  const clonesExpandedMulti = useMemo(() => {
    const id = uuidv4();
    return multiFeatureToFeatures({
      ...region,
      id,
      properties: { ...region.properties, id },
    });
  }, [region]);
  useClickOutside(
    ref,
    () => {
      setShowAddAnalysis(false);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      return target.id === "add-bathymetry-analysis-modal";
    },
  );
  const trackEvent = useTrackEvent();

  if (!projectEditorAccess) return null;

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <HighlightStep
        tourId="onshore-intro-tour"
        stepId="addWaterbodiesAsExclusionZones"
      >
        <Button
          text=""
          size="small"
          buttonType="secondary"
          onClick={toggleShowAddAnalysis}
          icon={<AddIcon />}
        />
      </HighlightStep>
      {showAddAnalysis && (
        <Anchor baseRef={ref} floatPlace="topRight" basePlace="bottom">
          <AddAnalysisModal id={"add-bathymetry-analysis-modal"}>
            <HighlightStep
              tourId="onshore-intro-tour"
              stepId="addWaterbodiesAsExclusionZones"
            >
              <AddAnalysisRow
                color={colors.exclusionZone}
                onClick={() => {
                  setTopRightModeActive(undefined);
                  trackEvent("addedExclusionZones");
                  const exclusionZone = {
                    ...region,
                    properties: {
                      ...region.properties,
                      type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
                      color: EXCLUSION_ZONE_COLOR,
                      domain: ExclusionDomainDefault,
                      [displayLabelPropertyName]: false,
                    },
                  };
                  const splitExclusionZones = splitMultiPolygon(
                    exclusionZone,
                    getGzippedBase64EncodedSize,
                  );
                  addFeaturesToElementsWithinLimits({
                    add: splitExclusionZones,
                    selectAfterCloning: true,
                  });
                }}
              >
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  style={{ marginRight: "0.4rem" }}
                >
                  <PolygonIcon />
                </IconREMSize>
                Add as Exclusion zone
              </AddAnalysisRow>
            </HighlightStep>
            {parkId && (
              <AddAnalysisRow
                color={colors.subArea}
                onClick={() => {
                  setTopRightModeActive(undefined);
                  addFeaturesToElementsWithinLimits({
                    add: clonesExpandedMulti.map((f) => ({
                      ...f,
                      properties: {
                        ...f.properties,
                        type: SUB_AREA_PROPERTY_TYPE,
                        parentIds: [parkId],
                        color: SUB_AREA_COLOR,
                        [displayLabelPropertyName]: false,
                      },
                    })),
                    selectAfterCloning: true,
                  });
                }}
              >
                <IconREMSize
                  height={1.4}
                  width={1.4}
                  style={{ marginRight: "0.4rem" }}
                >
                  <PolygonIcon />
                </IconREMSize>
                Add as Sub area
              </AddAnalysisRow>
            )}
            <AddAnalysisRow
              color={colors.other}
              onClick={() => {
                setTopRightModeActive(undefined);

                const splitFeatures = splitMultiPolygon(
                  {
                    ...region,
                    properties: {
                      ...region.properties,
                      color: colors.defaultCanvasLayer,
                    },
                  },
                  getGzippedBase64EncodedSize,
                );
                addFeaturesToElementsWithinLimits({
                  add: splitFeatures,
                  selectAfterCloning: true,
                });
              }}
            >
              <IconREMSize
                height={1.4}
                width={1.4}
                style={{ marginRight: "0.4rem" }}
              >
                <PolygonIcon />
              </IconREMSize>
              Add as Other
            </AddAnalysisRow>
          </AddAnalysisModal>
        </Anchor>
      )}
    </div>
  );
};

const BathymetryAnalysisInner = ({ canvasFeature }: Props) => {
  const areaOfFeature = useMemo(
    () => turf.area(canvasFeature),
    [canvasFeature],
  );

  if (areaOfFeature > MAX_AREA_SQM_ALLOWED) {
    return (
      <div>
        <SimpleAlert
          text={`The selected area of ${formatArea(areaOfFeature)} is too large
          for our bathymetry analysis, which has a limit of
           ${formatArea(MAX_AREA_SQM_ALLOWED)}`}
          type={"error"}
        />
      </div>
    );
  }

  return (
    <>
      <Suspense fallback={null}>
        <DepthAnalysis canvasFeature={canvasFeature} />
      </Suspense>
      <SlopeErrorBoundary>
        <Suspense fallback={null}>
          <SlopeAnalysis canvasFeature={canvasFeature} />
        </Suspense>
      </SlopeErrorBoundary>
    </>
  );
};

const BathymetryAnalysis = ({ canvasFeature, onClose }: Props) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  return (
    <MenuFrame title="Bathymetry" onExit={onClose}>
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: `calc(100vh - 26rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
          overflowY: "auto",
        }}
      >
        <BathymetryAnalysisInner canvasFeature={canvasFeature} />
      </div>
    </MenuFrame>
  );
};

export default BathymetryAnalysis;
