import { NotAuthorizedError } from "components/ErrorBoundaries/types";
import { z } from "zod";
import { FoundationType, _FoundationType } from "../types/foundations";
import {
  SimpleTurbineType,
  TurbineIdentifiers,
  TurbineType,
  _SimpleTurbineType,
  _TurbineIdentifiers,
  _TurbineType,
} from "../types/turbines";
import { scream } from "../utils/sentry";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";

// ------- Project/Team turbine ----------------------

export async function createNewProjectTurbine(
  nodeId: string,
  turbine: Omit<TurbineType, "id">,
): Promise<TurbineType | void> {
  const body = { turbine };
  const headers = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(`/api/turbines/node/${nodeId}`, headers)
    .then((response) => {
      return response.json() as Promise<TurbineType>;
    })
    .catch((err) => {
      scream("createNewTurbine failed", { e: err });
    });
}

export async function updateProjectTurbine(
  nodeId: string,
  turbine: TurbineType,
) {
  const body = { turbine };
  const headers = {
    method: "put",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/${turbine.id}`,
    headers,
  ).catch((err) => {
    scream("updateTurbine failed", { e: err });
  });
}

export async function deleteProjectTurbine(nodeId: string, turbineId: string) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/${turbineId}`,
    headers,
  ).catch((err) => {
    scream("deleteTurbine failed", { e: err });
  });
}

export async function getProjectTurbines(
  nodeId: string,
): Promise<SimpleTurbineType[]> {
  const headers = {
    method: "get",
  };
  const res = await fetchEnhancerWithToken(
    `/api/turbines/v2/node/${nodeId}`,
    headers,
  );
  const j = await res.json();
  try {
    return z.array(_SimpleTurbineType).parse(j);
  } catch (e) {
    scream("failed to parse project turbine", { e, j, res });
    throw e;
  }
}

export async function getAdvancedProjectTurbine(
  nodeId: string,
  turbineId: string,
): Promise<TurbineType> {
  const headers = {
    method: "get",
  };
  const res = await fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/${turbineId}`,
    headers,
  );
  if (res.status === 403) {
    throw new NotAuthorizedError("Not authorized");
  }
  const j = await res.json();
  try {
    return _TurbineType.parse(j);
  } catch (e) {
    scream("failed to parse custom turbine", { e, j, res });
    throw e;
  }
}

// --------- Organisation turbine ------------------------

export async function createNewOrgTurbine(
  organisationId: string,
  turbine: Omit<TurbineType, "id">,
  projectAccess?: string[],
): Promise<TurbineType> {
  const body = { turbine, ...(projectAccess ? { projectAccess } : {}) };

  return fetchSchemaWithToken(
    _TurbineType,
    `/api/turbines/organisation/${organisationId}`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function updateOrgTurbine(
  organisationId: string,
  turbine: TurbineType,
) {
  const body = { turbine };
  const headers = {
    method: "put",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/turbines/organisation/${organisationId}/${turbine.id}`,
    headers,
  )
    .then(async (response) => {
      const json = await response.json();
      return _TurbineType.parse(json);
    })
    .catch((err) => {
      scream("updateTurbine failed", { e: err });
    });
}

export async function deleteOrgTurbine(
  organisationId: string,
  turbineId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/turbines/organisation/${organisationId}/${turbineId}`,
    headers,
  ).catch((err) => {
    scream("deleteTurbine failed", { e: err });
  });
}

export async function getAdvancedOrgTurbine(
  organisationId: string,
  turbineId: string,
): Promise<TurbineType> {
  const headers = {
    method: "get",
  };
  const res = await fetchEnhancerWithToken(
    `/api/turbines/organisation/${organisationId}/${turbineId}`,
    headers,
  );
  if (res.status === 403) {
    throw new NotAuthorizedError("Not authorized");
  }
  const j = await res.json();
  try {
    return _TurbineType.parse(j);
  } catch (e) {
    scream("failed to parse custom turbine", { e, j, res });
    throw e;
  }
}

export async function getLibraryTurbineIdentifiers(
  organisationId: string,
): Promise<TurbineIdentifiers[]> {
  const headers = {
    method: "get",
  };
  const res = await fetchEnhancerWithToken(
    `/api/turbines/organisation/${organisationId}/identifiers`,
    headers,
  );
  const libraryTurbineIdentifiers = await res.json();
  try {
    return z.array(_TurbineIdentifiers).parse(libraryTurbineIdentifiers);
  } catch (e) {
    scream("failed to parse turbine identifiers", {
      e,
      libraryTurbineIdentifiers,
      res,
    });
    throw e;
  }
}

// --------- Project/Team foundation ---------------------------

export async function createNewProjectFoundation(
  nodeId: string,
  foundation: Omit<FoundationType, "id">,
): Promise<FoundationType | void> {
  const body = { foundation };
  const headers = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/foundations`,
    headers,
  );
  const j = await res.json();
  return _FoundationType.parse(j);
}

export async function getCustomProjectFoundation(
  nodeId: string,
  foundationId: string,
): Promise<FoundationType> {
  const headers = {
    method: "get",
  };
  const res = await fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/foundations/${foundationId}`,
    headers,
  );
  const j = await res.json();
  try {
    return _FoundationType.parse(j);
  } catch (e) {
    scream("failed to parse custom foundation", { e, j, res });
    throw e;
  }
}

export async function updateProjectFoundation(
  nodeId: string,
  foundation: FoundationType,
) {
  const body = { foundation };
  const headers = {
    method: "put",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/foundations/${foundation.id}`,
    headers,
  ).catch((err) => {
    scream("updateFoundation failed", { e: err });
  });
}

export async function deleteProjectFoundation(
  nodeId: string,
  foundationId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/turbines/node/${nodeId}/foundations/${foundationId}`,
    headers,
  ).catch((err) => {
    scream("deleteFoundation failed", { e: err });
  });
}

// -------- Organisation foundation ------------------------

export async function createNewOrgFoundation(
  organisationId: string,
  foundation: Omit<FoundationType, "id">,
  projectAccess?: string[],
): Promise<FoundationType> {
  const body = { foundation, ...(projectAccess ? { projectAccess } : {}) };

  return await fetchSchemaWithToken(
    _FoundationType,
    `/api/turbines/organisation/${organisationId}/foundations`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
}

export async function updateOrgFoundation(
  organisationId: string,
  foundation: FoundationType,
) {
  const body = { foundation };
  const headers = {
    method: "put",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const newFoundation = await fetchSchemaWithToken(
      _FoundationType,
      `/api/turbines/organisation/${organisationId}/foundations/${foundation.id}`,
      headers,
    );
    return newFoundation;
  } catch (err) {
    scream("updateFoundation failed", { e: err });
  }
}
export async function deleteOrgFoundation(
  organisationId: string,
  foundationId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/turbines/organisation/${organisationId}/foundations/${foundationId}`,
    headers,
  ).catch((err) => {
    scream("deleteFoundation failed", { e: err });
  });
}
