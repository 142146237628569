import { useAtom } from "jotai";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import React, { useCallback } from "react";
import { Mixpanel } from "mixpanel";
import { colors } from "styles/colors";
import { DotMenu } from "components/General/MenuButton";
import SearchWrapper from "components/Search/SearchWrapper";
import { MenuItem } from "components/General/Menu";
import { PublicModeModalTypeV2 } from "state/configuration";
import CameraIcon from "@icons/24/Camera.svg?react";
import FlagIcon from "@icons/24/WindMeasure.svg?react";
import VersionHistoryIcon from "@icons/14/VersionHistoryAlt.svg?react";
import ChangelogIcon from "@icons/24/Changelog.svg?react";
import ArchivedProjectVersions from "@icons/24/ArchivedProjectVersions.svg?react";
import PublicModeAltIcon from "@icons/14/PublicModeAlt.svg?react";
import { IconButtonStyler } from "components/Design/TopbarNavigation/components/RightSide/style";
import {
  editorAccessProjectSelector,
  loggedInUserIsInternalSelector,
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "state/user";
import { modalTypeOpenAtom } from "state/modal";
import { lowerRightMenuActiveModeAtom } from "state/layer";
import { TopRightModeActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import { TopRightMenuOptions } from "@constants/canvas";
import {
  RightModalNames,
  rightModalOpenStateAtom,
} from "components/Design/state";
import { useAtomValue, useSetAtom } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import Tooltip from "components/General/Tooltip";

const MiscDotMenu = ({
  toggleFeatureFlagsAreOpen,
  setScreenshotOpen,
}: {
  toggleFeatureFlagsAreOpen(): void;
  setScreenshotOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isCustomerEditor = useAtomValue(editorAccessProjectSelector);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const isInternal = useAtomValue(loggedInUserIsInternalSelector);
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const topRightModeActive = useAtomValue(TopRightModeActiveAtom);
  const setLowerRightActiveMode = useSetAtom(lowerRightMenuActiveModeAtom);
  const [rightModalOpenState, setRightModalOpenState] = useAtom(
    rightModalOpenStateAtom,
  );
  const isOnshore = useAtomValue(isOnshoreAtom);

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: projectId,
    }),
  );
  const isAdminForNode = nodeAccess >= 2;
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const showArchivedVersions = isAdminForNode && isMemberInOrg;

  const closeAnyOpenModal = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  return (
    <IconButtonStyler hoverBackgroundColor={colors.grey900}>
      <DotMenu
        color={colors.white}
        hoverColor={colors.blue300}
        hoverType="stroke"
      >
        <SearchWrapper
          title="Screenshot"
          id="search-screenshot"
          tags={["picture", "camera", "download", "export", "snapshot"]}
          icon={<CameraIcon />}
          onSelect={() => {
            closeAnyOpenModal();
            setScreenshotOpen(true);
          }}
        >
          <MenuItem
            name="Screenshot"
            disabled={topRightModeActive === TopRightMenuOptions.view}
            icon={<CameraIcon />}
            onClick={() => {
              closeAnyOpenModal();
              setScreenshotOpen(true);
            }}
          />
        </SearchWrapper>
        {isCustomerEditor && (
          <MenuItem
            name="Public mode"
            icon={<PublicModeAltIcon />}
            onClick={() => {
              setModalTypeOpen({
                modalType: PublicModeModalTypeV2,
                metadata: {
                  selectedMenuId: "public_mode",
                },
              });
            }}
          />
        )}
        <SearchWrapper
          title="View change log"
          tags={["change", "timeline", "history", "log"]}
          id="search-change-log"
          icon={<ChangelogIcon />}
          onSelect={() => {
            closeAnyOpenModal();
            setLowerRightActiveMode(undefined);
            setRightModalOpenState(RightModalNames.Changelog);
          }}
        >
          <MenuItem
            name="Changelog"
            icon={<ChangelogIcon />}
            onClick={() => {
              closeAnyOpenModal();
              setLowerRightActiveMode(undefined);
              setRightModalOpenState(RightModalNames.Changelog);

              Mixpanel.track_old(
                `Changelog ${rightModalOpenState === RightModalNames.Changelog ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          />
        </SearchWrapper>
        <SearchWrapper
          title="View elements history"
          tags={[
            "version",
            "undo",
            "error",
            "timeline",
            "history",
            "change",
            "log",
            "snapshot",
            "event",
            "autosave",
            "save",
          ]}
          id="search-feature-history"
          icon={<VersionHistoryIcon />}
          onSelect={() => {
            closeAnyOpenModal();
            setLowerRightActiveMode(undefined);
            setRightModalOpenState(RightModalNames.FeatureHistory);
          }}
        >
          <MenuItem
            name="Elements history"
            icon={<VersionHistoryIcon />}
            onClick={() => {
              closeAnyOpenModal();
              setLowerRightActiveMode(undefined);
              setRightModalOpenState(RightModalNames.FeatureHistory);

              Mixpanel.track_old(
                `Changelog ${rightModalOpenState === RightModalNames.FeatureHistory ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          />
        </SearchWrapper>
        {showArchivedVersions && (
          <SearchWrapper
            title="View Saved Project Versions"
            tags={[
              "version",
              "undo",
              "error",
              "timeline",
              "history",
              "change",
              "log",
              "snapshot",
              "event",
              "autosave",
              "save",
            ]}
            id="search-saved-project-versions"
            icon={<ArchivedProjectVersions />}
            onSelect={() => {
              closeAnyOpenModal();
              setLowerRightActiveMode(undefined);
              setRightModalOpenState(RightModalNames.ArchiveProject);

              Mixpanel.track_old(
                `Saved project version ${rightModalOpenState === RightModalNames.ArchiveProject ? "closed" : "opened"}`,
                {
                  projectId,
                  branchId,
                },
              );
            }}
          >
            <Tooltip
              disabled={!isOnshore}
              position="left"
              text={`Saved project data is currently not available for onshore projects`}
            >
              <MenuItem
                name="Saved project data"
                icon={<ArchivedProjectVersions />}
                disabled={isOnshore}
                onClick={() => {
                  closeAnyOpenModal();
                  setLowerRightActiveMode(undefined);
                  setRightModalOpenState(RightModalNames.ArchiveProject);

                  Mixpanel.track_old(
                    `Saved project version ${rightModalOpenState === RightModalNames.ArchiveProject ? "closed" : "opened"}`,
                    {
                      projectId,
                      branchId,
                    },
                  );
                }}
              />
            </Tooltip>
          </SearchWrapper>
        )}

        {isInternal && (
          <MenuItem
            name="Feature flags"
            icon={<FlagIcon />}
            onClick={() => {
              closeAnyOpenModal();
              toggleFeatureFlagsAreOpen();
            }}
          />
        )}
      </DotMenu>
    </IconButtonStyler>
  );
};

export default MiscDotMenu;
