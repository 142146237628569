import { DataLibraryPackage } from "components/Organisation/Library/dataLibrary/types";
import { AnalysisConfiguration } from "services/configurationService";
import { CostConfiguration } from "services/costService";
import TurbineModal from "../../../ResourceContent/modals/TurbineModal";
import FoundationModal from "../../../ResourceContent/modals/FoundationModal";
import CableModal from "../../../ResourceContent/modals/CableModal";
import ExportCableModal from "../../../ResourceContent/modals/ExportCableModal";
import SubstationModal from "../../../ResourceContent/modals/SubstationModal";
import AnalysisModal from "../../../ResourceContent/modals/AnalysisModal";
import FinancialModal from "../../../ResourceContent/modals/FinancialModal";
import DataPackageModal from "../../../ResourceContent/modals/DataPackageModal";

type ModalProps = {
  onSave: (items: any[]) => Promise<void>;
  existingItems: string[];
};

export const DataPackageModalWrapper = ({
  onSave,
  existingItems,
}: ModalProps) => (
  <DataPackageModal
    onSave={onSave as (items: DataLibraryPackage[]) => void}
    existingDataPackageConfig={existingItems}
  />
);

export const AnalysisModalWrapper = ({ onSave, existingItems }: ModalProps) => (
  <AnalysisModal
    onSave={onSave as (items: AnalysisConfiguration[]) => void}
    existingAnalysis={existingItems}
  />
);

export const FinancialModalWrapper = ({
  onSave,
  existingItems,
}: ModalProps) => (
  <FinancialModal
    onSave={onSave as (items: CostConfiguration[]) => void}
    existingFinancialConfig={existingItems}
  />
);

export const TurbineModalWrapper = ({ onSave, existingItems }: ModalProps) => (
  <TurbineModal onSave={onSave} existingTurbines={existingItems} />
);

export const FoundationModalWrapper = ({
  onSave,
  existingItems,
}: ModalProps) => (
  <FoundationModal onSave={onSave} existingFoundations={existingItems} />
);

export const CableModalWrapper = ({ onSave, existingItems }: ModalProps) => (
  <CableModal onSave={onSave} existingCables={existingItems} />
);

export const ExportCableModalWrapper = ({
  onSave,
  existingItems,
}: ModalProps) => (
  <ExportCableModal onSave={onSave} existingCables={existingItems} />
);

export const SubstationModalWrapper = ({
  onSave,
  existingItems,
}: ModalProps) => (
  <SubstationModal onSave={onSave} existingSubstations={existingItems} />
);
