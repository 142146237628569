import {
  FloaterType,
  FoundationType,
  ScalingLawType,
} from "../../../../../types/foundations";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Label } from "../../../../General/Form";
import {
  ColumnSection,
  HorizontalLine,
  SecondaryText,
  Subtitle,
  TitleContainer,
} from "../../../../SettingsV2/Shared/styles";
import Dropdown from "components/Dropdown/Dropdown";
import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { isSpar } from "utils/predicates";
import { between } from "utils/validations";
import { kgToTonne } from "../../FoundationSettings";
import { Grid } from "../../style";
import CommonReferenceNumbers, {
  ReferenceNumbersTitle,
} from "../ReferenceNumbers/CommonReferenceNumbers";

interface FloatingFoundationSettingsProps {
  originalFoundation?: FloaterType;
  tempFoundation: FloaterType;
  setTempFoundation: React.Dispatch<React.SetStateAction<FoundationType>>;
  disabled: boolean;
  nodeId: string;
}

const FloatingFoundationSettings = ({
  originalFoundation,
  tempFoundation,
  setTempFoundation,
  disabled,
  nodeId,
}: FloatingFoundationSettingsProps) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const commentCategory =
    nodeId === organisationId ? "org_foundation_manage" : "project";

  return (
    <>
      <Label>
        <SecondaryText>Scaling law</SecondaryText>
        <Dropdown
          style={{
            width: "20rem",
          }}
          id="scaling-law"
          disabled={true}
          value={tempFoundation.scalingLaw}
          onChange={(e) => {
            setTempFoundation({
              ...tempFoundation,
              scalingLaw: e.target.value as ScalingLawType,
            });
          }}
        >
          <option value={"Mass scaling"}>{"Mass scaling"}</option>
        </Dropdown>
      </Label>
      <ColumnSection>
        <ReferenceNumbersTitle />
        <Grid>
          <CommonReferenceNumbers
            disabled={disabled}
            tempFoundation={tempFoundation}
            originalFoundation={originalFoundation}
            setTempFoundation={setTempFoundation}
            nodeId={nodeId}
            commentCategory={commentCategory}
          />
        </Grid>
      </ColumnSection>
      <ColumnSection>
        <TitleContainer>
          <Subtitle>Weight and geometry</Subtitle>
          <HorizontalLine />
        </TitleContainer>
        <Grid>
          <Label>
            <SecondaryText>{tempFoundation.material} weight</SecondaryText>
            <InputDimensioned
              unit="t"
              disabled={disabled}
              scaleFactor={kgToTonne}
              value={tempFoundation.primaryMass}
              initialValue={
                originalFoundation ? originalFoundation.primaryMass : undefined
              }
              validate={between(10, 100_000)}
              validationMessage="Must be between 10 and 100 000 t"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "primaryMass",
                commentCategory,
              )}
              onChange={(primaryMassKilogram) => {
                setTempFoundation({
                  ...tempFoundation,
                  primaryMass: primaryMassKilogram,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Solid ballast weight</SecondaryText>
            <InputDimensioned
              unit="t"
              disabled={disabled}
              scaleFactor={kgToTonne}
              value={tempFoundation.solidBallastMass}
              initialValue={
                originalFoundation
                  ? originalFoundation.solidBallastMass
                  : undefined
              }
              validate={between(0, 100_000)}
              validationMessage="Must be between 0 and 100 000 t"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "solidBallastMass",
                commentCategory,
              )}
              onChange={(solidBallastMassKilograms) => {
                setTempFoundation({
                  ...tempFoundation,
                  solidBallastMass: solidBallastMassKilograms,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Liquid ballast weight</SecondaryText>
            <InputDimensioned
              unit="t"
              disabled={disabled}
              scaleFactor={kgToTonne}
              value={tempFoundation.liquidBallastMass}
              initialValue={
                originalFoundation
                  ? originalFoundation.liquidBallastMass
                  : undefined
              }
              validate={between(0, 100_000)}
              validationMessage="Must be between 0 and 100 000 t"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "liquidBallastMass",
                commentCategory,
              )}
              onChange={(liquidBallastMassKilograms) => {
                setTempFoundation({
                  ...tempFoundation,
                  liquidBallastMass: liquidBallastMassKilograms,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Displacement</SecondaryText>
            <InputDimensioned
              unit="m³"
              disabled={disabled}
              value={tempFoundation.displacement}
              initialValue={originalFoundation?.displacement}
              validate={between(1e3, 1e8)}
              validationMessage="Must be between 1e3 and 1e8 m³"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "displacement",
                commentCategory,
              )}
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  displacement: d,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Draft</SecondaryText>
            <InputDimensioned
              unit="m"
              disabled={disabled}
              value={tempFoundation.draft}
              initialValue={originalFoundation?.draft}
              validate={between(0, 1e2)}
              validationMessage="Must be between 0 and 100 m"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "draft",
                commentCategory,
              )}
              onChange={(d) => {
                setTempFoundation({
                  ...tempFoundation,
                  draft: d,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          {isSpar(tempFoundation) && (
            <Label>
              <SecondaryText>Main diameter</SecondaryText>
              <InputDimensioned
                unit="m"
                disabled={disabled}
                value={tempFoundation.baseDiameter}
                initialValue={
                  isSpar(originalFoundation)
                    ? originalFoundation.baseDiameter
                    : undefined
                }
                validate={between(0, 1e2)}
                validationMessage="Must be between 0 and 100 m"
                changelogInfo={foundationChangelogInfo(
                  tempFoundation.id,
                  nodeId,
                  "baseDiameter",
                  commentCategory,
                )}
                onChange={(d) => {
                  setTempFoundation({
                    ...tempFoundation,
                    baseDiameter: d,
                  });
                }}
                style={{
                  width: "20rem",
                }}
              />
            </Label>
          )}
          <Label>
            <SecondaryText>Fairlead radius</SecondaryText>
            <InputDimensioned
              unit="m"
              disabled={disabled}
              value={tempFoundation.fairRadius}
              initialValue={originalFoundation?.fairRadius}
              validate={between(0, 1e2)}
              validationMessage="Must be between 0 and 100 m"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "fairRadius",
                commentCategory,
              )}
              onChange={(r) => {
                setTempFoundation({
                  ...tempFoundation,
                  fairRadius: r,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
          <Label>
            <SecondaryText>Fairlead height</SecondaryText>
            <InputDimensioned
              unit="m"
              disabled={disabled}
              value={tempFoundation.fairZ}
              initialValue={originalFoundation?.fairZ}
              validate={between(-1e2, 1e2)}
              validationMessage="Must be between -100 and 100 m"
              changelogInfo={foundationChangelogInfo(
                tempFoundation.id,
                nodeId,
                "fairZ",
                commentCategory,
              )}
              onChange={(h) => {
                setTempFoundation({
                  ...tempFoundation,
                  fairZ: h,
                });
              }}
              style={{
                width: "20rem",
              }}
            />
          </Label>
        </Grid>
      </ColumnSection>
    </>
  );
};

export default FloatingFoundationSettings;
