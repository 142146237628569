import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { CostConfiguration } from "services/costService";
import { CostSettingsInner } from "components/ConfigurationModal/CostSettings";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";

const FinancialSettingInLibrary = ErrorBoundaryWrapper(
  ({
    configuration,
    onSave,
  }: {
    configuration: CostConfiguration;
    onSave: (config: CostConfiguration) => Promise<any>;
    isLoading: boolean;
  }) => {
    const organisationId = useAtomValue(organisationIdAtom);

    if (!organisationId) return null;

    return (
      <CostSettingsInner
        key={configuration.id}
        configuration={configuration}
        save={onSave}
        isLibraryResource={true}
        readOnly={false}
        nodeId={organisationId}
      />
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default FinancialSettingInLibrary;
