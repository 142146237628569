import { useLocalStorage } from "../../../../hooks/useBrowserStorage";
import { IconWrapper } from "../../Cost/styles";
import { Column } from "../../Cost/Capex";
import ShadeToggleableList from "../../../ToggleableList/ShadeToggleableList";
import { ReactNode } from "react";

export const InstallationCategoryEntry = ({
  title,
  defaultOpen = false,
  icon = undefined,
  children = undefined,
}: {
  title: string;
  defaultOpen?: boolean;
  icon?: JSX.Element;
  children?: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useLocalStorage<boolean | undefined>(
    `vind:config-operations-ti-${title.replace(" ", "-")}-open`,
  );

  return (
    <ShadeToggleableList
      contentStyle={{
        minWidth: "40rem",
      }}
      title={title}
      defaultOpen={isOpen ?? defaultOpen}
      icon={icon && <IconWrapper>{icon}</IconWrapper>}
      onOpenChange={setIsOpen}
    >
      <Column>{children ? children : null}</Column>
    </ShadeToggleableList>
  );
};
