import { STEEL_DENSITY } from "../constants/foundations";

export const calculateJacketMass = ({
  Ltop,
  Lbottom,
  jacketHeight,
  numBays,
  legDiameter,
  legThickness,
  braceDiameter,
  braceThickness,
  numLegs,
}: {
  jacketHeight: number;
  Ltop: number;
  Lbottom: number;
  numBays: number;
  legDiameter: number;
  legThickness: number;
  braceDiameter: number;
  braceThickness: number;
  numLegs: number;
}) => {
  const m = Math.pow(Lbottom / Ltop, 1 / numBays);
  const heightBays: number[] = [
    (jacketHeight * (m - 1)) / (Math.pow(m, numBays) - 1),
  ];
  for (let i = 1; i < numBays; i++) {
    heightBays.push(heightBays[i - 1] * m);
  }
  heightBays.reverse();

  const batterAnglePlanar = Math.atan((Lbottom - Ltop) / 2 / jacketHeight);

  const braceAngle = Math.atan(
    (m - 1) / ((m + 1) * Math.tan(batterAnglePlanar)),
  );

  const legsAngle = (2 * Math.PI) / numLegs;
  const batterAngleSpatial = Math.atan(
    Math.tan(batterAnglePlanar) / Math.sin(legsAngle / 2),
  );

  const legsLength = jacketHeight / Math.cos(batterAngleSpatial);

  let jacketMass =
    ((numLegs * legsLength * Math.PI) / 4) *
    (Math.pow(legDiameter, 2) - Math.pow(legDiameter - 2 * legThickness, 2)) *
    STEEL_DENSITY;
  for (let i = 0; i < numBays; i++) {
    const braceLength = heightBays[i] / Math.sin(braceAngle);
    jacketMass +=
      ((numLegs * 2 * braceLength * Math.PI) / 4) *
      (Math.pow(braceDiameter, 2) -
        Math.pow(braceDiameter - 2 * braceThickness, 2)) *
      STEEL_DENSITY;
  }

  return jacketMass;
};
