import { projectIdAtom } from "state/pathParams";
import { Thread } from "../types";
import CommentReactions from "../Reactions/CommentReactions";
import { Suspense, useCallback, useMemo, useState } from "react";
import { followThreadsAtomFamily, selectedThreadAtomFamily } from "../state";
import { UserInfoProject } from "../../UserInfo/UserInfo";
import { UserInfoLoading } from "components/UserInfo/UserInfoLoading";
import TextWithMentions from "../MapModal/TextWithMentions";
import { useReadComments } from "../UnreadComments/useReadComments";
import { UnreadCommentDot } from "../UnreadComments/style";
import { useGoToFeatureCenter } from "../../../hooks/map";
import { Column, Row } from "../../General/Layout";
import ResolvedLabel from "./ResolvedLabel";
import { useAtom, useAtomValue } from "jotai";
import { colors } from "styles/colors";
import { loadable } from "jotai/utils";
import Bin from "@icons/24/Bin.svg?react";
import Alarm from "@icons/24/Alarm.svg?react";
import Unread from "@icons/24/Unread.svg?react";
import { dateToDateTime } from "utils/utils";
import { borderRadiusSmall } from "styles/space";
import styled from "styled-components";
import { typography } from "styles/typography";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useFollowThreadCrud } from "../hooks/useFollowThreadCrud";
import { useThreadCrud } from "../hooks/useThreadCrud";
import { useResolveThreadCrud } from "../hooks/useResolveThreadCrud";

const RepliesText = styled.div<{
  unreadReplies: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(p) => (p.unreadReplies ? colors.textHover : colors.textPrimary)};
  ${typography.caption}
  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const ThreadContainer = styled(Column)<{
  selected: boolean;
}>`
  cursor: pointer;
  gap: 0;
  padding: 0.4rem 0.8rem 0.8rem 0.8rem;
  border-radius: ${borderRadiusSmall};
  background-color: ${(p) =>
    p.selected ? colors.surfaceSelectedLight : colors.surfaceSecondary};

  &:hover {
    background-color: ${(p) =>
      p.selected ? colors.surfaceSelectedLight : colors.surfaceHover};
  }
`;

export default function Comment({
  thread,
  nodeId,
}: {
  thread: Thread;
  nodeId: string;
}) {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { put: putFollow } = useFollowThreadCrud();
  const { remove } = useThreadCrud();
  const { put: putResolve } = useResolveThreadCrud();
  const { showConfirm } = useConfirm();

  const goToFeatureId = useGoToFeatureCenter();

  const [selectedThread, setSelectedThread] = useAtom(
    selectedThreadAtomFamily({
      nodeId,
    }),
  );
  const [isHovered, setIsHovered] = useState(false);
  const { isRead, setRead, setUnread, isRepliesRead } = useReadComments();
  const isThreadRead = isRead(thread);
  const showAsUnread = useMemo(() => !isRead(thread), [thread, isRead]);
  const timeString = useMemo(() => {
    return dateToDateTime(new Date(thread.threadTimestamp));
  }, [thread.threadTimestamp]);

  const follows = useAtomValue(
    loadable(
      followThreadsAtomFamily({
        nodeId: thread.nodeId,
        branchId: thread.branchId,
      }),
    ),
  );
  const hasUnreadReplies = useMemo(() => {
    return thread.replies > 0 && !isRepliesRead(thread);
  }, [isRepliesRead, thread]);

  const isFollowed = useMemo(() => {
    if (follows.state !== "hasData") return false;
    return follows.data.some((f) => f.threadId === thread.threadId && f.follow);
  }, [follows, thread.threadId]);

  const onResolve = useCallback(async () => {
    const newValue = !thread.resolved;
    putResolve(thread.threadId, newValue);
  }, [putResolve, thread.resolved, thread.threadId]);

  return (
    <ThreadContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      selected={thread.threadId === selectedThread}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedThread((cur) =>
          cur === thread.threadId ? undefined : thread.threadId,
        );
        thread.featureId && goToFeatureId(thread.featureId);
      }}
      style={{
        position: "relative",
      }}
    >
      {showAsUnread && (
        <UnreadCommentDot
          size={"0.6rem"}
          style={{
            position: "absolute",
            top: "0.4rem",
            right: "0.4rem",
            cursor: "pointer",
          }}
          onClick={() => setRead(thread)}
        />
      )}
      <Row
        alignCenter
        style={{
          justifyContent: "space-between",
        }}
      >
        <Row alignCenter>
          <Suspense fallback={<UserInfoLoading />}>
            <UserInfoProject userId={thread.userId} projectId={projectId} />
          </Suspense>
          <p
            style={{
              color: colors.textDisabled,
            }}
          >
            {timeString}
          </p>
        </Row>
        <Row
          style={{
            gap: "0.2rem",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <DotMenu>
            <MenuItem
              name={isFollowed ? "Unfollow thread" : "Follow thread"}
              onClick={() => {
                putFollow(thread.threadId, !isFollowed);
              }}
              icon={<Alarm />}
            />
            <MenuItem
              name={isThreadRead ? "Mark as unread" : "Mark as read"}
              onClick={() => {
                if (isThreadRead) {
                  setUnread(thread);
                } else {
                  setRead(thread);
                }
              }}
              icon={<Unread />}
            />
            <MenuItem
              name={"Delete thread"}
              onClick={async () => {
                if (
                  await showConfirm({
                    title: "Delete comment",
                    message:
                      "This will permanently delete the comment with all replies and reactions.",
                    confirmButtonText: "Delete",
                  })
                ) {
                  remove(thread.threadId);
                }
              }}
              icon={<Bin />}
            />
          </DotMenu>
          <ResolvedLabel
            onResolve={onResolve}
            thread={thread}
            threadIsSelected={thread.threadId === selectedThread}
          />
        </Row>
      </Row>
      <div
        style={{
          paddingLeft: "3.2rem",
        }}
      >
        <Row>
          <TextWithMentions message={thread.message} />
          <CommentReactions
            threadIsSelected={thread.threadId === selectedThread}
            parentIsHovered={isHovered}
            thread={thread}
            nodeId={nodeId}
          />
        </Row>

        {thread.replies > 0 && (
          <Row
            style={{
              justifyContent: "space-between",
              gap: "1.6rem",
              padding: "0.8rem 0",
            }}
          >
            <Row
              alignCenter
              style={{
                cursor: "pointer",
              }}
            >
              <RepliesText
                unreadReplies={hasUnreadReplies}
              >{`${thread.replies} ${thread.replies === 1 ? "reply" : "replies"} `}</RepliesText>
            </Row>
          </Row>
        )}
      </div>
    </ThreadContainer>
  );
}
