import debounce from "debounce";
import React, { useCallback, useEffect, useState } from "react";
import { noiseLevelSelectorFamily, showNoiseAtom } from "../../state/turbines";
import { editorAccessProjectSelector } from "../../state/user";
import { ParkFeature } from "../../types/feature";
import { NoiseTemperatures, TurbineNoiseSettings } from "../../types/turbines";
import { Grid2 } from "../General/Form";
import { InputDimensioned } from "../General/Input";
import { ARTICLE_NOISE_ANALYSIS, HelpLink } from "../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { parkIdAtom } from "state/pathParams";
import { Slider } from "components/General/Slider";
import Dropdown from "components/Dropdown/Dropdown";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import {
  InputTitle,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useAtomValue, useSetAtom } from "jotai";
import { parkFamily } from "state/jotai/park";

export const NoiseAnalysis = ({ onClose }: { onClose(): void }) => {
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const setShowNoise = useSetAtom(showNoiseAtom);
  const parkId = useAtomValue(parkIdAtom) ?? "";
  const turbineNoise = useAtomValue(
    noiseLevelSelectorFamily({
      parkId,
    }),
  );
  const park = useAtomValue(
    parkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const { update: updateFeatures } = useProjectElementsCrud();

  const updateNoise = useCallback(
    (newNoise: Partial<TurbineNoiseSettings>) => {
      if (!park) return;
      const noiseSettings = {
        ...turbineNoise,
        ...newNoise,
      };
      const updatedPark: ParkFeature = {
        ...park,
        properties: {
          ...park.properties,
          noiseSettings,
        },
      };
      updateFeatures({
        update: [updatedPark],
      });
    },
    [park, turbineNoise, updateFeatures],
  );

  const debouncedUpdateNoise = debounce(updateNoise, 100);
  const [assumptionsOpen, setAssumptionsOpen] = useState(true);

  useEffect(() => {
    setShowNoise(true);
    return () => setShowNoise(false);
  }, [setShowNoise]);

  return (
    <MenuFrame
      title={"Noise"}
      icon={<HelpLink article={ARTICLE_NOISE_ANALYSIS} />}
      onExit={onClose}
    >
      <p>
        The noise analysis uses the ISO-9613 standard. It assumes a flat, hard
        surface, and a humidity of 70%.
      </p>
      <SubtitleWithLine text="Settings" />
      <Grid2
        style={{
          padding: "1.6rem",
        }}
      >
        <InputTitle>Sound level at turbine</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.source}
          onChange={(n) =>
            debouncedUpdateNoise({
              source: n,
            })
          }
          readOnly={!editorAccessProject}
          validate={(n) => 90 <= n && n <= 120}
          unit={"dB"}
          style={{
            minWidth: "8rem",
          }}
        />

        <InputTitle>Red boundary</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.red}
          onChange={(n) =>
            debouncedUpdateNoise({
              red: n,
            })
          }
          readOnly={!editorAccessProject}
          validate={(n) => 40 <= n && n <= 80}
          unit={"dB(A)"}
          style={{
            minWidth: "8rem",
          }}
        />

        <InputTitle>Yellow boundary</InputTitle>
        <InputDimensioned
          compact
          value={turbineNoise.yellow}
          onChange={(n) =>
            debouncedUpdateNoise({
              yellow: n,
            })
          }
          readOnly={!editorAccessProject}
          validate={(n) => 20 <= n && n <= 60}
          unit={"dB(A)"}
          style={{
            minWidth: "8rem",
          }}
        />
      </Grid2>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setAssumptionsOpen(!assumptionsOpen);
        }}
      >
        <SubtitleWithLine
          text={"Noise analysis assumptions"}
          expandButton={
            <ChevronIcon
              open={assumptionsOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {assumptionsOpen && (
        <Grid2
          style={{
            marginTop: "1rem",
          }}
        >
          <InputTitle>Temperature</InputTitle>
          <Dropdown
            small
            style={{
              flex: 1,
            }}
            onChange={(n) => {
              const temp = parseFloat(n.target.value) as NoiseTemperatures;
              debouncedUpdateNoise({
                temperature: temp,
              });
            }}
            value={turbineNoise.temperature}
          >
            <option value={10}>10°</option>
            <option value={20}>20°</option>
            <option value={30}>30°</option>
          </Dropdown>
          <InputTitle>Coloring opacity</InputTitle>
          <Slider
            onChange={(n) =>
              debouncedUpdateNoise({
                opacity: n,
              })
            }
            min={0}
            step={0.1}
            value={turbineNoise.opacity}
            max={1}
            label
            renderLabel={(n) => `${(n * 100).toFixed(0)}`}
          />
        </Grid2>
      )}
    </MenuFrame>
  );
};
