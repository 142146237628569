import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Suspense } from "react";
import { ContentWrapper } from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { libraryResourceSelector } from "components/Organisation/Library/state";
import { TurbineResourceContent } from "./TurbineResourceContent";
import { AnalysisResourceContent } from "./AnalysisResourceContent";
import { FinancialResourceContent } from "./FinancialResourceContent";
import { FoundationResourceContent } from "./FoundationResourceContent";
import { CableResourceContent } from "./CableResourceContent";
import { ExportCableResourceContent } from "./ExportCableResourceContent";
import { SubstationResourceContent } from "./SubstationResourceContent";
import { DatapackageResourceContent } from "./DatapackageResourceContent";

export default function ResourceContent({
  resourceId,
}: {
  resourceId: string;
}) {
  const organisationId = useAtomValue(organisationIdAtom);

  if (!organisationId) {
    return null;
  }

  return (
    <ContentWrapper>
      <Suspense fallback={<TablePlaceholder />}>
        <ResourceContentInner
          resourceId={resourceId}
          organisationId={organisationId}
        />
      </Suspense>
    </ContentWrapper>
  );
}

function ResourceContentInner({
  resourceId,
  organisationId,
}: {
  resourceId: string;
  organisationId: string;
}) {
  const resource = useAtomValue(
    libraryResourceSelector({
      organisationId,
      resourceId,
    }),
  );

  if (!resource) {
    return null;
  }

  if (resource.type === "turbine") {
    return (
      <TurbineResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "foundation") {
    return (
      <FoundationResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "cable") {
    return (
      <CableResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "exportcable") {
    return (
      <ExportCableResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "substation") {
    return (
      <SubstationResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "analysis") {
    return (
      <AnalysisResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "financial") {
    return (
      <FinancialResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  if (resource.type === "datapackage") {
    return (
      <DatapackageResourceContent
        resource={resource.resource}
        organisationId={organisationId}
      />
    );
  }

  return <></>;
}
