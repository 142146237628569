import DownloadIcon from "@icons/24/Download.svg?react";
import { getAnalysisProgress, getProductionHistograms } from "analysis/output";
import { getStoppedReason, getAnalysisError } from "analysis/warnings";
import { SkeletonText } from "components/Loading/Skeleton";
import { stringify } from "csv-stringify/sync";
import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "../../../hooks/useToast";
import { colors } from "../../../styles/colors";
import { downloadText } from "../../../utils/utils";
import { MenuItem } from "../../General/Menu";
import { useDashboardContext } from "../Dashboard";
import { LoadingState, SafeCard } from "./Base";

const useNumbers = () => {
  const { triggerId } = useDashboardContext();
  const productionHistograms = useAtomValue(getProductionHistograms(triggerId));
  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));
  const { net } = productionHistograms ?? {};

  const [netDuration, netPower] = useMemo(() => {
    if (!net) return [undefined, undefined];
    const hours = [
      8760,
      ...net.cumulativeProduction.map((p) => (1 - p) * 8760),
    ];
    const midPoints = [
      0,
      ...net.bins.slice(1).map((_, i) => (net.bins[i] + net.bins[i + 1]) * 0.5),
    ];
    return [hours, midPoints];
  }, [net]);

  return {
    netPower,
    netDuration,
    stoppedReason,
    analysisStoppedReason,
    analysisProgress,
  };
};

const GraphWrapper = () => {
  const { netPower, netDuration, analysisProgress } = useNumbers();

  if (!netDuration || !netPower) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return <Inner netDuration={netDuration} netPower={netPower} />;
};

const Inner = ({
  netPower,
  netDuration,
}: {
  netDuration: number[];
  netPower: number[];
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);
    const data = [
      {
        name: "",
        y: netPower,
        x: netDuration,
        line: { shape: "vh", color: colors.primary } as const,
        mode: "lines",
        type: "scatter" as const,
        hovertemplate:
          "The park outputs %{y:,.0f} MW or more<br />for %{x:,.0f} hours each year (on average)",
      } as const,
    ];

    const layout = {
      hovermode: "closest" as const,
      legend: { x: 0, y: 0.1, xanchor: "left" } as const,
      hoverlabel: { align: "left" } as const,
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      height: 220,
      xaxis: {
        title: "Hours",
        range: [0, 8766],
      },
      yaxis: {
        title: "Net power [MW]",
      },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      showTips: false,
    }).then(() => setIsLoaded(true));
  }, [graphRef, netDuration, netPower]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

const CumulativeGraphMenu = () => {
  const { netDuration, netPower } = useNumbers();

  const { warning } = useToast();

  const download = useCallback(() => {
    if (!netDuration || !netPower) {
      warning(
        "Data is still loading, please wait a few seconds and try again.",
      );
      return;
    }

    const header = [
      "gross_power_mw",
      "gross_duration_hours",
      "net_power_mw",
      "net_duration_hours",
    ];

    const rows = netPower.map((p, i) => {
      return [Math.round(p), Math.round(netDuration[i])];
    });
    const csvString = stringify([header, ...rows]);
    downloadText(csvString, "duration_curves.csv");
  }, [netDuration, netPower, warning]);

  return (
    <>
      <MenuItem
        name={"Download as .csv"}
        icon={<DownloadIcon />}
        onClick={() => download()}
      />
    </>
  );
};

export const CumulativeGraphWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Production duration curve"
      id="Cumulative graph"
      menuItems={<CumulativeGraphMenu />}
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <GraphWrapper />
    </SafeCard>
  );
};
