import { projectIdAtom } from "state/pathParams";
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  ReactElement,
} from "react";
import { SettingsItem, SettingsSubMenuProp } from "./types";
import SettingsMenuItem from "./SettingsMenuItem";
import AddIcon from "@icons/24/Add.svg?react";
import Tooltip from "../../General/Tooltip";
import {
  MainContentContainer,
  NoItemsWrapper,
  OpenOrCollapseContainer,
  SubMenuColumn,
  SubMenuTitle,
} from "./styles";
import Button from "../../General/Button";
import OpenOrCollapse from "../../General/OpenOrCollapse";
import { selectedMenuItemState, unsavedSettingsState } from "./state";
import eventEmitter from "../../../utils/eventEmitter";
import ResizeBarVertical from "../../ResizeBar/ResizeBarVertical";
import { Column, Row } from "components/General/Layout";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useAtom, useAtomValue } from "jotai";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

interface SubMenuProps extends React.PropsWithChildren {
  parentId: string;
  submenus: SettingsSubMenuProp[];
  depth: number;
  onClose?: () => void;
  title?: string;
  wide?: boolean;
  createNew?: {
    element: ReactElement;
    saveInProgress?: boolean;
  };
  addFromLibrary?: {
    element: ReactElement;
    saveInProgress?: boolean;
  };
}

interface SubMenuCustomMenuListProps extends React.PropsWithChildren {
  parentId: string;
  items: SettingsItem[];
  depth: number;
  onClose?: () => void;
  title?: string;
  wide?: boolean;
}

function SettingsSubMenu({
  parentId,
  submenus,
  depth,
  onClose,
  wide,
  children,
  createNew,
  addFromLibrary,
}: SubMenuProps) {
  const [expandedFirstLevel, setExpandedFirstLevel] = useState(true);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const submenuColumnRef = React.useRef<HTMLDivElement>(null);
  const [selectedItemId, setSelectedItemId] = useAtom(
    selectedMenuItemState({
      menuId: parentId,
      projectId,
    }),
  );
  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);
  const { showConfirm } = useConfirm();

  const selectedItem = useMemo(
    () =>
      submenus
        .flatMap((e) => e.items)
        .find((item) => item.id === selectedItemId),
    [submenus, selectedItemId],
  );

  const isFirstLevel = depth === 0;
  const expanded = isFirstLevel ? expandedFirstLevel : true;

  // 100ms animation
  const [fullyExpanded, setFullyExpanded] = useState(true);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (expanded) {
      timeout = setTimeout(() => {
        setFullyExpanded(true);
      }, 100);
    } else {
      setFullyExpanded(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [expanded]);

  const onAfterResize = useCallback(() => {
    eventEmitter.emit("settings-submenu-resize");
  }, []);

  return (
    <>
      <SubMenuColumn
        ref={submenuColumnRef}
        opagueness={Math.max(0, 1 - 0.33 * depth)}
        expanded={expanded}
        isFirstLevel={isFirstLevel}
        wide={wide}
        widthVariable={`--settings-submenu-width-${parentId}`}
      >
        <Column
          style={{
            overflowY: "auto",
            gap: "2.4rem",
          }}
        >
          {!isFirstLevel && (
            <Row
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {createNew && createNew.element}
              {addFromLibrary && addFromLibrary.element}
            </Row>
          )}
          {submenus.map((submenu, index) => (
            <Column
              key={`submenu-${index}`}
              style={{
                gap: "1.2rem",
              }}
            >
              <Row
                style={{
                  alignItems: "center",
                }}
              >
                {submenu.icon}
                {submenu.title && (
                  <SubMenuTitle expanded={expanded} key={`title-${index}`}>
                    {submenu.title}
                  </SubMenuTitle>
                )}
                {fullyExpanded && submenu.create && (
                  <>
                    {submenu.create.type === "element" ? (
                      submenu.create.element
                    ) : (
                      <Button
                        text={!submenu.title ? submenu.create.title : ""}
                        disabled={submenu.create.disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          submenu.create?.type === "action" &&
                            submenu.create.onCreate();
                        }}
                        buttonType="secondary"
                        icon={<AddIcon />}
                        style={{
                          marginLeft: "auto",
                        }}
                      />
                    )}
                  </>
                )}
              </Row>
              <Column style={{ gap: "0.8rem" }}>
                {submenu.loading && (
                  <>
                    <NoItemsWrapper>{`Loading ${submenu.title}`}</NoItemsWrapper>
                    <SkeletonBlock
                      style={{
                        height: "4rem",
                        marginTop: "0.8rem",
                      }}
                    />
                  </>
                )}

                {!submenu.isCollapsed && (
                  <>
                    {!submenu.loading && submenu.items.length === 0 && (
                      <NoItemsWrapper>{`No ${submenu.title} added`}</NoItemsWrapper>
                    )}
                    {submenu.items.map((item, index) => (
                      <Tooltip
                        innerDivStyle={{
                          width: "100%",
                        }}
                        key={`tooltip-${item.id}`}
                        position="top"
                        text={item.label}
                        readonlyAware
                        disabled={expanded}
                      >
                        <SettingsMenuItem
                          key={item.id}
                          item={item}
                          isFirstLevel={isFirstLevel}
                          onClick={async () => {
                            if (unsavedSettings) {
                              if (
                                selectedItemId !== item.id &&
                                (await showConfirm({
                                  title: "Unsaved settings",
                                  message:
                                    "Are you sure you want to quit without saving?",
                                }))
                              ) {
                                setUnsavedSettings(false);
                                setSelectedItemId(item.id);
                              }
                            } else {
                              setSelectedItemId(item.id);
                            }
                          }}
                          expanded={!!expanded}
                          selected={selectedItemId === item.id}
                          index={index + 1}
                        />
                      </Tooltip>
                    ))}
                  </>
                )}
                {submenu.create && submenu.create.saveInProgress && (
                  <SkeletonBlock
                    style={{
                      height: "4rem",
                      marginTop: "0.8rem",
                    }}
                  />
                )}
              </Column>
            </Column>
          ))}
        </Column>
        {isFirstLevel && (
          <OpenOrCollapseContainer collapsed={!expanded}>
            <OpenOrCollapse
              setIsOpen={setExpandedFirstLevel}
              isOpen={!!expanded}
            />
          </OpenOrCollapseContainer>
        )}
        {!isFirstLevel && parentId && expanded && (
          <ResizeBarVertical
            resizeElemRef={submenuColumnRef}
            barSide="RIGHT"
            cssVariableToUpdate={`--settings-submenu-width-${parentId}`}
            style={{
              position: "absolute",
              right: "-8px",
            }}
            onAfterResize={onAfterResize}
          />
        )}
      </SubMenuColumn>
      {selectedItem?.submenus && (
        <SettingsSubMenu
          parentId={selectedItem.id}
          key={selectedItemId}
          submenus={selectedItem.submenus}
          createNew={selectedItem.createNew}
          addFromLibrary={selectedItem.addFromLibrary}
          depth={depth + 1}
          title={selectedItem.title}
          onClose={() => {
            setExpandedFirstLevel(false);
            onClose?.();
          }}
        />
      )}
      {selectedItem?.content && (
        <React.Suspense fallback={null}>
          <MainContentContainer>{selectedItem.content}</MainContentContainer>
          {children}
        </React.Suspense>
      )}
    </>
  );
}

export const SettingsSubMenuCustomMenuListContext = createContext<{
  selectedItemId: string | undefined;
  setSelectedItemId: (id: string) => void;
  expanded: boolean;
  fullyExpanded: boolean;
}>({
  selectedItemId: undefined,
  setSelectedItemId: () => {},
  expanded: true,
  fullyExpanded: true,
});

export const SettingsSubMenuCustomMenuList = ({
  parentId,
  items,
  depth,
  wide,
  children,
}: React.PropsWithChildren<SubMenuCustomMenuListProps>) => {
  const [expanded, setExpanded] = useState(true);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [selectedItemId, setSelectedItemId] = useAtom(
    selectedMenuItemState({
      menuId: parentId,
      projectId,
    }),
  );

  const selectedItem = useMemo(
    () => items.find((item) => item.id === selectedItemId),
    [items, selectedItemId],
  );

  // 100ms animation
  const [fullyExpanded, setFullyExpanded] = useState(true);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (expanded) {
      timeout = setTimeout(() => {
        setFullyExpanded(true);
      }, 100);
    } else {
      setFullyExpanded(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [expanded]);

  return (
    <>
      <SubMenuColumn
        opagueness={Math.max(0, 1 - 0.33 * depth)}
        expanded={expanded}
        isFirstLevel={depth === 0}
        wide={wide}
      >
        <SettingsSubMenuCustomMenuListContext.Provider
          value={{
            selectedItemId,
            setSelectedItemId,
            expanded,
            fullyExpanded,
          }}
        >
          {children}
        </SettingsSubMenuCustomMenuListContext.Provider>
        <OpenOrCollapseContainer>
          <OpenOrCollapse setIsOpen={setExpanded} isOpen={expanded} />
        </OpenOrCollapseContainer>
      </SubMenuColumn>
      {selectedItem?.content && (
        <MainContentContainer>{selectedItem.content}</MainContentContainer>
      )}
    </>
  );
};

export default SettingsSubMenu;
