import { useAtom, useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { spaceDecent, spaceSmall } from "styles/space";
import { ModalFrame } from "components/General/Layout";
import {
  currentOrganisationSelector,
  inviteToOrganisationDefaultEmailValue,
  inviteToOrganisationDefaultSelectedProjectIds,
} from "components/Organisation/state";
import { InviteUsersResponse } from "hooks/useUser";
import { adminInOrganisationSelectorFamily } from "state/user";
import AfterInviteResult from "./AfterInvite/AfterInviteResult";
import BeforeInvite from "./BeforeInvite";

const InviteToOrganisationFrame = ({
  onClose,
  fullscreen = true,
}: {
  onClose(): void;
  fullscreen?: boolean;
}) => {
  const organisation = useAtomValue(currentOrganisationSelector);
  const isOrgAdmin = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId: organisation?.id ?? "",
    }),
  );
  const [defaultEmailValue, setDefaultEmailValue] = useAtom(
    inviteToOrganisationDefaultEmailValue,
  );
  const [defaultProjectIds, setDefaultProjectIds] = useAtom(
    inviteToOrganisationDefaultSelectedProjectIds,
  );
  const [inviteResult, setInviteResult] = useState<InviteUsersResponse>([]);

  // Reset default values when the modal is closed
  useEffect(() => {
    return () => {
      setDefaultEmailValue("");
      setDefaultProjectIds([]);
    };
  }, [setDefaultEmailValue, setDefaultProjectIds]);

  if (!organisation) {
    return null;
  }

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <ModalFrame
        title="Invite to organisation"
        h2style={{
          marginBottom: spaceDecent,
        }}
        fullscreen={fullscreen}
        closeOnWrapperClick={false}
        style={{
          position: "absolute",
          right: 0,
          minHeight: "10rem",
          minWidth: "50rem",
          top: spaceSmall,
          overflow: "visible",
        }}
        onExit={inviteResult.length > 0 ? undefined : onClose}
      >
        {inviteResult.length === 0 ? (
          <BeforeInvite
            organisation={organisation}
            isOrgAdmin={isOrgAdmin}
            setInviteResult={setInviteResult}
            defaultInputValue={defaultEmailValue}
          />
        ) : (
          <AfterInviteResult
            isOrgAdmin={isOrgAdmin}
            inviteResult={inviteResult}
            organisation={organisation!}
            defaultSelectProjectIds={defaultProjectIds}
            onClose={onClose}
          />
        )}
      </ModalFrame>
    </div>
  );
};

export default InviteToOrganisationFrame;
