import React, { useEffect } from "react";
import { scrollToLayerIdInListAtom } from "../state";
import { useAtom } from "jotai";

const useScrollToLayerInList = (
  scrollBodyRef: React.RefObject<HTMLDivElement>,
  refreshDependency: any,
) => {
  const [scrollToLayerId, setScrollToLayerId] = useAtom(
    scrollToLayerIdInListAtom,
  );

  useEffect(() => {
    if (!scrollBodyRef.current || !scrollToLayerId) {
      return;
    }

    const item = scrollBodyRef.current.querySelector<HTMLDivElement>(
      `[data-layer-id="${scrollToLayerId}"]`,
    );

    if (!item) {
      return;
    }

    const top = item.offsetTop - scrollBodyRef.current.offsetTop;

    scrollBodyRef.current.scroll({
      top: top,
    });
    setScrollToLayerId(undefined);
  }, [scrollBodyRef, scrollToLayerId, setScrollToLayerId, refreshDependency]);
};

export default useScrollToLayerInList;
