import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectOperationsConfigurationsFamily } from "state/jotai/operationsConfiguration";
import { updateOperationsConfiguration } from "services/operationsConfigurationService";
import {
  operationsConfigurationTempName,
  savingOperationsConfigurationInProgressAtom,
} from "state/operationsConfiguration";

const useOperationsConfigurationCrud = () => {
  const projectId = useAtomValue(projectIdAtom);
  const setIsSaving = useSetAtom(savingOperationsConfigurationInProgressAtom);

  const saveNameLocalJotai = useJotaiCallback(
    async (get, set, configId: string, name: string) => {
      if (!projectId) {
        return;
      }

      return aset(
        get,
        set,
        projectOperationsConfigurationsFamily(projectId),
        (cur) => {
          const newMap = new Map(cur);
          const config = newMap.get(configId);
          if (!config) return cur;
          newMap.set(configId, {
            ...config,
            name,
          });
          return newMap;
        },
      );
    },
    [projectId],
  );

  // This method saves the new name to the data base and the atom 'operationsConfigurationTempName'.
  // The new name is not updated in localConfig or operationsConfigurationsAtomFamily,
  // because it would result in loosing non-saved values in the config
  const saveName = useJotaiCallback(
    async (get, set, configId: string, name: string) => {
      if (!projectId) return;
      setIsSaving(true);

      saveNameLocalJotai(configId, name);
      set(
        operationsConfigurationTempName({
          nodeId: projectId,
        }),
        (cur) => ({
          ...cur,
          [configId]: name,
        }),
      );

      const currentConfigurations = await get(
        projectOperationsConfigurationsFamily(projectId),
      );

      const currentConfig = currentConfigurations.get(configId);

      if (!currentConfig)
        throw Error("Could not find wind config, config id: " + configId);

      await updateOperationsConfiguration({
        nodeId: projectId,
        configuration: {
          ...currentConfig,
          name: name,
        },
      });
      setIsSaving(false);
    },
    [projectId, setIsSaving, saveNameLocalJotai],
  );

  // This is used to transfer the new names stored in operationsConfigurationTempName to operationsConfigurationsAtomFamily,
  // This is called when the component is unmounted
  const saveTempNameToLocal = useJotaiCallback(
    async (get, set, configId: string) => {
      if (!projectId) return;

      const tempConfigNames = get(
        operationsConfigurationTempName({
          nodeId: projectId,
        }),
      );

      const tempName = tempConfigNames[configId];
      if (!tempName) return;

      set(projectOperationsConfigurationsFamily(projectId), async (cur) => {
        const m = new Map(await cur);
        const prev = m.get(configId);
        if (prev) m.set(prev.id, { ...prev, name: tempName });
        return m;
      });

      set(
        operationsConfigurationTempName({
          nodeId: projectId,
        }),
        (cur) => ({
          ...cur,
          [configId]: undefined,
        }),
      );
    },
    [projectId],
  );

  return {
    saveName,
    saveTempNameToLocal,
  };
};

export default useOperationsConfigurationCrud;
