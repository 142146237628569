import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback } from "react";
import Button from "components/General/Button";
import Fuse from "fuse.js";
import { Input } from "components/General/Input";
import { Column, Frame, Row } from "components/General/Layout";
import Close from "@icons/24/Close.svg?react";
import SinglePersonIcon from "@icons/24/SinglePerson.svg";
import InformationIcon from "@icons/14/RoundWarning.svg?react";
import useTextInput from "hooks/useTextInput";
import { useEffect, useRef, useState } from "react";
import { Group } from "components/Organisation/Groups/types";
import { IconBtn } from "components/General/Icons";
import {
  ContentTableColumn,
  ContentTableRow,
  Divider,
  SearchResultContainer,
  SearchAndSelectContainer,
} from "../../../style";
import {
  groupMembersState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import { usersInOrganisationState } from "components/Organisation/state";
import { OrganisationUser } from "types/customer";
import UserImageRound from "components/UserImage/UserImageRound";
import RemoveIcon from "@icons/24/Remove.svg";
import { UserAccessRole } from "types/user";
import SelectedLabel from "components/General/SelectedLabel";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import useUserOrganisationResourcesCrud from "../../hooks/useUserOrganisationResourcesCrud";
import { adminInOrganisationSelectorFamily } from "state/user";
import useGroupOrganisationResourcesCrud from "../../hooks/useGroupOrganisationResourcesCrud";
import { OrganisationResources } from "../../../types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "components/General/Tooltip";
import RoundGroupIcon from "components/Organisation/OrganisationRightSide/content/NodeContent/tabs/RoundGroupIcon";
import { Anchor } from "components/General/Anchor";
import { UserInfoOrganisationWithMemberLink } from "components/UserInfo/UserInfo";
import { spacing4 } from "styles/space";
import { toastMessagesAtom } from "state/toast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const Text = styled.div`
  ${typography.body};
  color: ${colors.textPrimary};
  max-width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SecondaryText = styled.div`
  ${typography.body};
  color: ${colors.textDisabled};
  max-width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  gap: 1.2rem;
`;

type UserAccess = string;
type GroupAccess = string;

const fromResourceToString = (resource: OrganisationResources) => {
  switch (resource) {
    case "org_turbine_manage":
      return "turbines";
    case "org_foundation_manage":
      return "foundations";
    case "org_cable_manage":
      return "cables";
    case "org_export_cable_manage":
      return "export cables";
    case "org_financial_manage":
      return "financial";
    case "org_analysis_manage":
      return "analysis";
    case "org_data_package_manage":
      return "GIS package";
    case "org_substation_manage":
      return "substation";
  }
};

export const NumberOfGroupMembers = ({
  groupId,
  organisationId,
}: {
  groupId: string;
  organisationId: string;
}) => {
  const groupMembers = useAtomValue(
    groupMembersState({
      organisationId,
      groupId,
    }),
  );
  const rowRef = useRef<HTMLDivElement>(null);

  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const showPopupTimeoutId = useRef<NodeJS.Timeout>();
  const hidePopupTimeoutId = useRef<NodeJS.Timeout>();

  const onMouseOver = useCallback(() => {
    clearTimeout(hidePopupTimeoutId.current);
    showPopupTimeoutId.current = setTimeout(() => {
      setPopupIsVisible(true);
    }, 200);
  }, []);

  const onMouseLeave = useCallback(() => {
    hidePopupTimeoutId.current = setTimeout(() => {
      setPopupIsVisible(false);
      clearTimeout(showPopupTimeoutId.current);
    }, 200);
  }, []);

  return (
    <>
      <Row
        style={{
          gap: spacing4,
          alignItems: "center",
        }}
        ref={rowRef}
        onMouseEnter={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <IconREMSize height={1.2} width={1.2}>
          <SinglePersonIcon />
        </IconREMSize>
        <p
          style={{
            ...typography.caption,
            color: colors.textSecondary,
          }}
        >
          {groupMembers.length}
        </p>

        {popupIsVisible && (
          <Anchor baseRef={rowRef} basePlace="bottom" floatPlace="top">
            <Frame
              style={{
                overflowY: "auto",
                maxHeight: "50vh",
                maxWidth: "30rem",
                width: "auto",
                padding: 0,
              }}
              onMouseEnter={(e) => {
                e.stopPropagation();
                clearTimeout(hidePopupTimeoutId.current);
              }}
              onMouseLeave={onMouseLeave}
            >
              <ContentTableColumn
                style={{
                  padding: 0,
                }}
              >
                {groupMembers.map((user) => {
                  if (!user.user_id) {
                    return null;
                  }

                  return (
                    <Row
                      key={user.user_id}
                      style={{
                        justifyContent: "space-between",
                        gap: spacing4,
                        padding: `${spacing4} ${spacing4}`,
                      }}
                    >
                      <UserInfoOrganisationWithMemberLink
                        userId={user.user_id}
                        size={2.5}
                      />
                    </Row>
                  );
                })}
              </ContentTableColumn>
            </Frame>
          </Anchor>
        )}
      </Row>
    </>
  );
};

export default function ResourceAccessModal({
  resource,
  existingUsers,
  existingGroups,
  onSave,
  onCancel,
  isSaving,
  showAlreadyExistingItems = true,
}: {
  resource: OrganisationResources;
  existingUsers: {
    user_id: string;
  }[];
  existingGroups: {
    group_id: string;
  }[];
  isSaving?: boolean;
  showAlreadyExistingItems?: boolean;
  onSave: (
    newAccess: {
      users: UserAccess[];
      groups: GroupAccess[];
    },
    accessRole: UserAccessRole,
  ) => void;
  onCancel?: () => void;
}) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const usersInOrg = useAtomValue(usersInOrganisationState(organisationId));
  const groupsInOrg = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const { showConfirm } = useConfirm();

  const [name, onNameChange] = useTextInput("");
  const [selectedGroups, setSelectedGroups] = useState<GroupAccess[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserAccess[]>([]);
  const [selectedRole] = useState<UserAccessRole>("viewer");
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<OrganisationUser[]>([]);

  const { remove: removeUser } = useUserOrganisationResourcesCrud();
  const { remove: removeGroup } = useGroupOrganisationResourcesCrud();

  const [removeInProgress, setRemoveInProgress] = useState<
    string | undefined
  >();

  useEffect(() => {
    const fuseGroups = new Fuse(groupsInOrg, {
      keys: ["name"],
      includeScore: true,
      threshold: 0.3,
    });
    const fuseUsers = new Fuse(usersInOrg, {
      keys: ["nickname", "email"],
      includeScore: true,
      threshold: 0.2,
    });

    const groupsResult = fuseGroups.search(name).map((result) => result.item);
    const userResult = fuseUsers.search(name).map((result) => result.item);
    setFilteredGroups(name.length ? groupsResult : groupsInOrg);
    setFilteredUsers(name.length ? userResult : usersInOrg);
  }, [name, groupsInOrg, usersInOrg]);

  const toggleGroupSelection = (groupId: GroupAccess) => {
    setSelectedGroups((prevSelectedGroups) => {
      if (prevSelectedGroups.find((g) => g === groupId)) {
        return prevSelectedGroups.filter((g) => g !== groupId);
      } else {
        return [...prevSelectedGroups, groupId];
      }
    });
  };
  const toggleUserSelection = (userId: UserAccess) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.find((u) => u === userId)) {
        return prevSelectedUsers.filter((u) => u !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (name && inputRef.current) {
      inputRef.current.focus();
    }
  }, [name]);

  return (
    <SearchAndSelectContainer>
      <Column
        style={{
          padding: "1.6rem 2rem",
        }}
      >
        <Row>
          <h3>Library {fromResourceToString(resource)} access</h3>
          {onCancel && (
            <IconBtn
              size="1.4rem"
              onClick={onCancel}
              style={{
                height: "min-content",
                marginLeft: "auto",
              }}
            >
              <Close />
            </IconBtn>
          )}
        </Row>
        <p
          style={{
            ...typography.caption,
            color: colors.textPrimary,
            margin: 0,
          }}
        >
          People and groups below are given full access to create, edit, see all
          details and assign library {fromResourceToString(resource)} to
          projects.
        </p>

        <Row
          style={{
            alignItems: "center",
            gap: "0.8rem",
          }}
        >
          <Input
            ref={inputRef}
            autoFocus={false}
            value={name}
            onChange={onNameChange}
            type="search"
            placeholder={`Add people and groups`}
            style={{
              flexGrow: 1,
            }}
            onFocus={() => setSearchOpen(true)}
            onBlur={() => setSearchOpen(false)}
          />
        </Row>
        <SearchResultContainer>
          {searchOpen && (
            <>
              <Column
                onMouseDown={(event) => event.preventDefault()}
                style={{
                  position: "absolute",
                  overflowY: "auto",
                  maxHeight: "30rem",
                  maxWidth: "30rem",
                  backgroundColor: colors.surfacePrimary,
                  borderRadius: "0.8rem",
                  border: `1px solid ${colors.borderDefault}`,
                }}
              >
                <Row
                  style={{
                    padding: "0.4rem 0.8rem",
                    backgroundColor: colors.surfacePrimary,
                  }}
                >
                  <p
                    style={{
                      ...typography.caption,
                      color: colors.textSecondary,
                    }}
                  >
                    Select existing users or groups to share the resource with.
                    Invite new users to the organisation from the{" "}
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        setSearchOpen(false);
                      }}
                      to={`/organisation/${organisationId}/members`}
                    >
                      Members
                    </Link>{" "}
                    page.
                  </p>
                </Row>
                <ContentTableColumn>
                  {filteredUsers.length > 0 &&
                    filteredUsers
                      .filter((u) => {
                        return (
                          showAlreadyExistingItems ||
                          !existingUsers.some((eu) => eu.user_id === u.user_id)
                        );
                      })
                      .map((u) => {
                        const selected = selectedUsers.some(
                          (su) => su === u.user_id,
                        );
                        const alreadyHasAcces = existingUsers.some(
                          (eu) => eu.user_id === u.user_id,
                        );
                        const showGuestWarning = u.org_role === "guest";
                        return (
                          <ContentTableRow
                            key={u.user_id}
                            style={{
                              alignItems: "center",
                              overflow: "hidden",
                              position: "relative",
                            }}
                            disabled={
                              selected || alreadyHasAcces || showGuestWarning
                            }
                            onClick={() =>
                              !alreadyHasAcces &&
                              !selected &&
                              toggleUserSelection(u.user_id)
                            }
                          >
                            <UserImageRound user={u} size={2.2} />
                            <Column
                              style={{
                                gap: 0,
                              }}
                            >
                              <Text>{u.nickname}</Text>
                            </Column>
                            {alreadyHasAcces ? (
                              <SecondaryText>Already a member</SecondaryText>
                            ) : selected ? (
                              <SecondaryText>Selected</SecondaryText>
                            ) : (
                              <SecondaryText>
                                <Tooltip
                                  innerDivStyle={{
                                    textOverflow: "ellipsis",
                                  }}
                                  text={u.email}
                                >
                                  {u.email}
                                </Tooltip>
                              </SecondaryText>
                            )}
                            {showGuestWarning && (
                              <Tooltip
                                outerDivStyle={{ marginLeft: "auto" }}
                                text="This user is a guest in this organisation. Even with Library access they won't be able to access the Library."
                              >
                                <InformationIcon />
                              </Tooltip>
                            )}
                            {!alreadyHasAcces &&
                              !selected &&
                              !showGuestWarning && (
                                <Button
                                  buttonType="primary"
                                  text="Select"
                                  onClick={() => {}}
                                  size="small"
                                  style={{
                                    padding: "0.2rem 1.2rem",
                                    position: "absolute",
                                    right: "1.2rem",
                                  }}
                                />
                              )}
                          </ContentTableRow>
                        );
                      })}{" "}
                  {filteredGroups.length > 0 &&
                    filteredGroups
                      .filter((g) => {
                        return (
                          showAlreadyExistingItems ||
                          !existingGroups.some((eg) => eg.group_id === g.id)
                        );
                      })
                      .map((g) => {
                        const selected = selectedGroups.some(
                          (sg) => sg === g.id,
                        );
                        const alreadyGroupMember = existingGroups.some(
                          (eg) => eg.group_id === g.id,
                        );
                        return (
                          <ContentTableRow
                            key={g.id}
                            style={{
                              alignItems: "center",
                              overflow: "hidden",
                              position: "relative",
                            }}
                            disabled={selected || alreadyGroupMember}
                            onClick={() =>
                              !alreadyGroupMember &&
                              !selected &&
                              toggleGroupSelection(g.id)
                            }
                          >
                            <RoundGroupIcon />
                            <Column
                              style={{
                                gap: "0.1rem",
                              }}
                            >
                              <Text>{g.name}</Text>
                              <React.Suspense fallback={null}>
                                <NumberOfGroupMembers
                                  groupId={g.id}
                                  organisationId={organisationId}
                                />
                              </React.Suspense>
                            </Column>

                            {alreadyGroupMember ? (
                              <SecondaryText>Already a member</SecondaryText>
                            ) : selected ? (
                              <SecondaryText>Selected</SecondaryText>
                            ) : (
                              <></>
                            )}
                            {!alreadyGroupMember && !selected && (
                              <Button
                                buttonType="primary"
                                text="Select"
                                onClick={() => {}}
                                size="small"
                                style={{
                                  padding: "0.2rem 1.2rem",
                                  position: "absolute",
                                  right: "1.2rem",
                                }}
                              />
                            )}
                          </ContentTableRow>
                        );
                      })}
                  <div
                    style={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: colors.surfacePrimary,
                    }}
                  >
                    {(selectedUsers.length > 0 ||
                      selectedGroups.length > 0) && (
                      <>
                        <Divider />
                        <Row
                          style={{
                            padding: "1.6rem 1.2rem",
                          }}
                        >
                          <Row
                            style={{
                              flexWrap: "wrap",
                              flex: 1,
                              gap: "0.6rem",
                            }}
                          >
                            {selectedUsers.map((su) => {
                              const user = usersInOrg.find(
                                (u) => u.user_id === su,
                              );
                              if (!user) return <></>;
                              return (
                                <SelectedLabel
                                  key={user.user_id}
                                  title={user.nickname}
                                  onDeselect={() => toggleUserSelection(su)}
                                />
                              );
                            })}
                            {selectedGroups.map((sg) => {
                              const group = groupsInOrg.find(
                                (g) => g.id === sg,
                              );
                              if (!group) return <></>;
                              return (
                                <SelectedLabel
                                  key={group.id}
                                  title={group.name}
                                  onDeselect={() => toggleGroupSelection(sg)}
                                />
                              );
                            })}
                          </Row>
                          <Button
                            text="Share"
                            onClick={() => {
                              onSave(
                                {
                                  groups: selectedGroups,
                                  users: selectedUsers,
                                },
                                selectedRole,
                              );
                              setToastMessages((tm) => [
                                ...tm,
                                {
                                  text: `User(s) was successfully given access to ${fromResourceToString(resource)} library`,
                                  type: "success",
                                  timeout: 5000,
                                },
                              ]);
                            }}
                            buttonType="primary"
                            disabled={isSaving}
                            icon={
                              isSaving ? <Spinner size="1.2rem" /> : undefined
                            }
                          />
                        </Row>
                      </>
                    )}
                  </div>
                </ContentTableColumn>
              </Column>
            </>
          )}
        </SearchResultContainer>
      </Column>
      <ContentTableColumn
        style={{
          overflowY: "auto",
          maxHeight: "50rem",
          padding: "0 2rem 2rem 2rem",
        }}
      >
        {existingUsers.map((u) => {
          const user = usersInOrg.find((user) => user.user_id === u.user_id);
          const showGuestWarning = user?.org_role === "guest";
          if (!user) return <></>;
          return (
            <ContentRow
              key={user.user_id}
              style={{
                alignItems: "center",
              }}
            >
              {" "}
              <UserImageRound user={user} size={3.2} />
              <Column
                style={{
                  gap: 0,
                }}
              >
                <Text
                  style={{
                    margin: 0,
                  }}
                >
                  {user.nickname}
                </Text>

                <SecondaryText
                  style={{
                    margin: 0,
                    marginRight: "auto",
                  }}
                >
                  {user.email}
                </SecondaryText>
              </Column>
              {isAdminInOrg && (
                <Row
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  {showGuestWarning && (
                    <Tooltip text="This user is a guest in this organisation. Even with Library access they won't be able to access the Library.">
                      <InformationIcon />
                    </Tooltip>
                  )}
                  <IconBtn
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (
                        !(await showConfirm({
                          title: "Remove access",
                          message:
                            "Are you sure you want to remove the access for this user?",
                          confirmButtonText: "Remove",
                        }))
                      ) {
                        return;
                      }
                      setRemoveInProgress(user.user_id);
                      removeUser(organisationId, user.user_id, resource).then(
                        () => setRemoveInProgress(undefined),
                      );
                    }}
                    disabled={removeInProgress === user.user_id}
                    size={"1.4rem"}
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <RemoveIcon />
                  </IconBtn>
                </Row>
              )}
            </ContentRow>
          );
        })}{" "}
        {existingGroups.map((g) => {
          const group = groupsInOrg.find((group) => group.id === g.group_id);
          if (!group) return <></>;
          return (
            <ContentRow
              key={group.id}
              style={{
                alignItems: "center",
              }}
            >
              <RoundGroupIcon />
              <Column
                style={{
                  gap: 0,
                }}
              >
                <Text>{group.name}</Text>
                <React.Suspense fallback={null}>
                  <NumberOfGroupMembers
                    groupId={group.id}
                    organisationId={organisationId}
                  />
                </React.Suspense>
              </Column>

              {isAdminInOrg && (
                <Row
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <IconBtn
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        !confirm(
                          "Are you sure you want to remove the access for this group?",
                        )
                      ) {
                        return;
                      }
                      setRemoveInProgress(group.id);
                      removeGroup(group.id, resource).then(() =>
                        setRemoveInProgress(undefined),
                      );
                    }}
                    size={"1.4rem"}
                    disabled={removeInProgress === group.id}
                  >
                    <RemoveIcon />
                  </IconBtn>
                </Row>
              )}
            </ContentRow>
          );
        })}
      </ContentTableColumn>
    </SearchAndSelectContainer>
  );
}
