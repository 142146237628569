import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  displayLabelPropertyName,
  lockedPropertyName,
} from "@constants/canvas";
import { CirclePaint, SymbolLayerSpecification } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { colors } from "styles/colors";
import { SubstationFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer, removeCodepointsFromFeatures } from "./utils";
import {
  substationSymbolLayerId,
  substationSourceId,
  substationLayerId,
} from "./constants";
import { atom, useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";
import { READ, caseexpr, pointOpacityInPark } from "./defaults";
import { defaultMapTextPaintAtom } from "state/map";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const substationPaintAtom = atom<CirclePaint>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    "circle-color": caseexpr({
      fallback: onshore ? colors.onElSubstation : colors.substation,
      state: { outside: colors.textError },
    }),
    "circle-opacity": pointOpacityInPark,
    "circle-radius": defaultPointCircleRadius,
    "circle-stroke-color": caseexpr({
      fallback: colors.white,
      selected: colors.white,
      hover: onshore ? colors.onElSubstation : colors.substation,
      get: { [lockedPropertyName]: colors.lockedFeatureOutline },
      state: { borderColor: READ },
    }),
    "circle-stroke-width": caseexpr({
      hover: 2,
      selected: 2,
      fallback: 0,
      state: { borderColor: 2 },
    }),
  };
});

const useSubstationSymbolLayer: () => SymbolLayerSpecification = () => {
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);

  return useMemo(
    () => ({
      id: substationSymbolLayerId,
      source: substationSourceId,
      type: "symbol",
      minzoom: 8,
      layout: {
        "symbol-placement": "point",
        "text-field": "{name}",
        "text-offset": [0, -2],
        "text-size": 10,
        "text-anchor": "center",
      },
      filter: ["boolean", ["get", displayLabelPropertyName], true],
      paint: defaultMapTextPaint,
    }),
    [defaultMapTextPaint],
  );
};

export const RenderSubstations = ({
  substations: features,
  map,
  symbols,
  showSelectionHighlighter,
}: {
  substations: SubstationFeature[];
  map: mapboxgl.Map;
  symbols?: boolean;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  const substationSymbolLayer = useSubstationSymbolLayer();
  const substationPaint = useAtomValue(substationPaintAtom);
  useEffect(() => {
    map.addSource(substationSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: substationLayerId,
      type: "circle",
      source: substationSourceId,
      paint: substationPaint,
    });
    if (symbols) {
      addLayer(map, substationSymbolLayer);
    }

    return () => {
      safeRemoveLayer(map, substationLayerId);
      if (symbols) {
        safeRemoveLayer(map, substationSymbolLayer.id);
      }
      map.removeSource(substationSourceId);
    };
  }, [map, symbols, substationPaint, substationSymbolLayer]);

  useEffect(() => {
    const source = map.getSource(substationSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(features),
    });
  }, [map, features]);

  if (!showSelectionHighlighter) return null;

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={substationSourceId}
      featureIds={featureIds}
    />
  );
};
