import { colors } from "styles/colors";
import styled from "styled-components";
import { stageBackgroundColorToTextColor } from "../StagesSettings";

export const DEFAULT_COLOR = "#454545";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.8rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey100};
  border-radius: 0.4rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const ColorRow = styled.div`
  display: flex;
  gap: 0.4rem;
`;

const ColorOption = styled.div<{ color: string; isSelected: boolean }>`
  width: ${({ isSelected }) => (isSelected ? "calc(2.4rem - 4px)" : "2.4rem")};
  height: ${({ isSelected }) => (isSelected ? "calc(2.4rem - 4px)" : "2.4rem")};
  border-radius: 0.4rem;
  background-color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ isSelected }) =>
    isSelected ? `2px solid ${colors.primary}` : "none"};
`;

const COLORS = [
  colors.grey200,
  colors.orange200,
  colors.yellow100,
  colors.chartreuse100,
  colors.seagreen100,
  colors.purple100,
  colors.magenta100,
];

interface StagesColorSelectorProps {
  selectedColor: string;
  onSelect: (color: string) => void;
}

const ColorBoxText = styled.div<{ color: string }>`
  color: ${({ color }) => stageBackgroundColorToTextColor(color)};
`;

const StagesColorSelector = ({
  selectedColor,
  onSelect,
}: StagesColorSelectorProps) => {
  return (
    <Container>
      <ColorRow>
        {COLORS.map((color) => (
          <ColorOption
            key={color}
            color={color}
            isSelected={selectedColor === color}
            onClick={() => onSelect(color)}
          >
            <ColorBoxText color={stageBackgroundColorToTextColor(color)}>
              A
            </ColorBoxText>
          </ColorOption>
        ))}
      </ColorRow>
    </Container>
  );
};

export default StagesColorSelector;
