import { useAtomValue } from "jotai";
import { orgFoundationManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";
import { useAblyLibraryFoundation } from "components/Ably/ChannelProviders/Organisation/Library/Foundation/useAblyLibraryFoundation";
interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function FoundationLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgFoundationManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_foundation_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyFoundationChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyFoundationChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  useAblyLibraryFoundation(organisationId);
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_foundation_manage"}
    />
  );
}
