import { atom } from "jotai";
import { getProjectResourcesChangelogMetadata } from "components/InputChangelog/service";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { z } from "zod";
import { atomLocalStorage } from "utils/jotai";
import {
  ChangelogMetadata,
  EventActions,
} from "components/InputChangelog/types";
import { LibraryManageRole } from "components/Organisation/Library/types";

const NEW_FEATURE_NOTIFICATION_KEY = "vind:new-feature-notification";
const LIBRARY_UPDATED_NOTIFICATION_KEY = "vind:library-updated-notification";

export const readVersionOfNewFeatureNotificationAtom = atomLocalStorage(
  NEW_FEATURE_NOTIFICATION_KEY,
  0,
  z.number(),
);

export const readVersionOfLibraryUpdatedNotificationAtom = atomLocalStorage(
  LIBRARY_UPDATED_NOTIFICATION_KEY,
  null,
  z.number().nullable(),
);

export interface LatestChanges {
  changes: ChangelogMetadata[];
  maxVersion: number;
}

export const latestLibraryChangesAtom = atom<Promise<LatestChanges>>(
  async (get) => {
    const organisationId = get(organisationIdAtom) ?? "";
    const projectId = get(projectIdAtom) ?? "";
    const lastNotificationVersion = get(
      readVersionOfLibraryUpdatedNotificationAtom,
    );

    const metadata = await getProjectResourcesChangelogMetadata(
      organisationId,
      projectId,
    );

    // If lastNotificationVersion is null (first visit), don't show any changes
    const recentChanges =
      lastNotificationVersion === null
        ? []
        : metadata
            .filter((change) => change.lastModified > lastNotificationVersion)
            .filter((change) => change.action !== "DELETE")
            .sort((a, b) => b.lastModified - a.lastModified);

    const maxVersion = Math.max(...metadata.map((c) => c.lastModified), 0);

    return {
      changes: recentChanges,
      maxVersion,
    };
  },
);

export const getProjectLibraryChangesAtom = atom<Promise<ChangelogMetadata[]>>(
  async (get) => {
    const organisationId = get(organisationIdAtom) ?? "";
    const projectId = get(projectIdAtom) ?? "";

    const metadata = await getProjectResourcesChangelogMetadata(
      organisationId,
      projectId,
    );

    return metadata.filter((change) => change.action !== "DELETE");
  },
);

export const deletedTurbineActionChangelogMetadataAtom = atom<
  Promise<ChangelogMetadata[]>
>(async (get) => {
  const organisationId = get(organisationIdAtom) ?? "";
  const projectId = get(projectIdAtom) ?? "";
  const metadata = await getProjectResourcesChangelogMetadata(
    organisationId,
    projectId,
  );
  return metadata.filter(
    (meta) =>
      meta.action === EventActions.DELETE &&
      meta.libraryType === LibraryManageRole.TURBINE,
  );
});
