import { projectIdAtom } from "state/pathParams";
import {
  CostConfiguration,
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
} from "../../../../services/costService";
import { useAtomValue, useSetAtom } from "jotai";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import { Input, InputDimensioned, TextArea } from "../../../General/Input";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import { FirstRowWrapper, Row } from "../Capex";
import Cabling from "@icons/24/Cabling.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { DefaultCosts } from "../constants";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { CABLE_MENU_ID } from "../../../SettingsV2/FeatureSettings/Data/useCableSettings";
import { isDefined } from "../../../../utils/predicates";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import TableIcon from "@icons/24/Table.svg";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { financialChangelogInfo } from "components/InputChangelog/const";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import { midScreenModalTypeOpenAtom } from "state/modal";

const CableSpecificCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const handleCableCostTypeChange = (type: "default" | "override") => () => {
    if (type === "default") {
      updateFixedCapex({
        cables: undefined,
      });
    } else {
      updateFixedCapex({
        cables: {
          cost: DefaultCosts[CostType.Cable].cable[CostUnit.millionEuroPerKM],
          unit: CostUnit.millionEuroPerKM,
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
      });
    }
  };

  return (
    <>
      <FirstRowWrapper>
        <GridWithFiveColumns>
          <Input
            value={"Cables"}
            disabled={true}
            locked={true}
            style={{
              width: "100%",
            }}
          />

          {localConfig?.capex.fixed.cables === undefined ? (
            <Row
              style={{
                width: "100%",
              }}
            >
              <Tag>
                <TableIcon />
                From table
              </Tag>

              <IconBtn
                size={"1.4rem"}
                onClick={() => {
                  setMidScreenModalTypeOpen({
                    modalType: ComponentsPreviewType,
                    metadata: {
                      componentType: CABLE_MENU_ID,
                      isLibraryResource,
                      inLibraryContext,
                    },
                  });
                }}
              >
                <TableIcon />
              </IconBtn>
              {inLibraryContext && (
                <HelpTooltip
                  text={"Using cost specified in the procurement table"}
                />
              )}
            </Row>
          ) : (
            <Row
              style={{
                width: "100%",
              }}
            >
              <InputDimensioned
                step="0.1"
                unit={
                  localConfig?.capex.fixed.cables?.unit
                    ? localConfig.capex.fixed.cables.unit
                    : CostUnit.millionEuroPerKM
                }
                units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
                type="number"
                value={
                  localConfig?.capex.fixed.cables
                    ? localConfig?.capex.fixed.cables?.cost
                      ? localConfig.capex.fixed.cables.cost
                      : DefaultCosts[CostType.Cable].cable[
                          CostUnit.millionEuroPerKM
                        ]
                    : undefined
                }
                initialValue={
                  configuration?.capex.fixed.cables
                    ? configuration?.capex.fixed.cables?.cost
                      ? configuration.capex.fixed.cables.cost
                      : DefaultCosts[CostType.Cable].cable[
                          CostUnit.millionEuroPerKM
                        ]
                    : undefined
                }
                changelogInfo={financialChangelogInfo(
                  localConfig.id,
                  nodeId,
                  "capex.fixed.cables.cost",
                  isLibraryResource ? "org_financial_manage" : "project",
                )}
                onUnitChange={(unit: CostUnit) =>
                  updateFixedCapex({
                    cables: {
                      cost: (DefaultCosts[CostType.Cable].cable as any)[unit],
                      unit,
                    },
                  })
                }
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                disabled={isReadOnly || !localConfig?.capex.fixed.cables}
                onChange={(cost) =>
                  updateFixedCapex(
                    {
                      cost,
                    },
                    "cables",
                  )
                }
                style={{
                  width: "100%",
                }}
              />
            </Row>
          )}

          {useConfidenceLevel &&
            (!(localConfig?.capex.fixed.cables === undefined) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex({
                    cables: {
                      ...localConfig.capex.fixed.cables,
                      confidenceLevel: confidenceLevel,
                    },
                  });
                }}
                confidenceLevel={
                  localConfig.capex.fixed.cables?.confidenceLevel
                }
              />
            ) : (
              <Tag>
                <Tooltip
                  text={
                    "Not able to specify confidence on configuration level when the cost is from components"
                  }
                >
                  <ButtonTextWrapper>
                    <ConfidenceLevelDot confidenceLevel={undefined} />
                    {ConfidenceLevel.notSpecified}
                  </ButtonTextWrapper>
                </Tooltip>
              </Tag>
            ))}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                }}
                value={localConfig?.capex.fixed.cablesFreeText ?? ""}
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex({
                    cablesFreeText: e.target.value,
                  });
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}

          <Button
            size="small"
            icon={
              !(localConfig?.capex.fixed.cables !== undefined) ? (
                <PencilIcon />
              ) : (
                <TableIcon />
              )
            }
            buttonType="secondary"
            text={
              !(localConfig?.capex.fixed.cables !== undefined)
                ? "Override"
                : "Use table"
            }
            onClick={
              !(localConfig?.capex.fixed.cables !== undefined)
                ? handleCableCostTypeChange("override")
                : handleCableCostTypeChange("default")
            }
            disabled={isReadOnly}
          />
        </GridWithFiveColumns>
      </FirstRowWrapper>
    </>
  );
};

const CableInstallationCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();
  const isOnshoreConfig = configuration.type === "onshore";

  const handleCableCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ cables: type });
      } else {
        updateInstallationCapex({
          cables: {
            unit: CostUnit.millionEuroPerKM,
            cost: DefaultCosts[CostType.Cable].installation[
              CostUnit.millionEuroPerKM
            ],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.cables) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.cables,
                ) &&
                  localConfig?.capex.installation.cables.unit) ||
                CostUnit.millionEuroPerMW
              }
              units={[CostUnit.millionEuroPerKM, CostUnit.thousandEuroPerMW]}
              type="number"
              value={
                isOperationsOverrideCost(localConfig?.capex.installation.cables)
                  ? localConfig.capex.installation.cables.cost ??
                    DefaultCosts[CostType.Cable].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.cables,
                )
                  ? configuration.capex.installation.cables.cost ??
                    DefaultCosts[CostType.Cable].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              onChange={(cost) => updateInstallationCapex({ cost }, "cables")}
              onUnitChange={(unit: CostUnit) =>
                updateInstallationCapex({
                  cables: {
                    cost: (DefaultCosts[CostType.Cable].installation as any)[
                      unit
                    ],
                    unit,
                  },
                })
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.cables.cost",
                isLibraryResource ? "org_financial_manage" : "project",
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.cables &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.cables,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(localConfig?.capex.installation.cables === "operations_cost") ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.cables === "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  cables: {
                    ...localConfig?.capex.installation.cables,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.cables.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
              }}
              value={localConfig?.capex.installation.cablesFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  cablesFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}

        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.cables) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.cables)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.cables)
                ? handleCableCostTypeChange("override")
                : handleCableCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const CableCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Inter array cables"
      configuration={configuration}
      category={CostType.Cable}
      units={[
        CostUnit.millionEuroPerKM,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerCable,
        CostUnit.millionEuro,
      ]}
      icon={<Cabling />}
      isReadOnly={isReadOnly}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
    >
      <CableSpecificCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <CableInstallationCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        isLibraryResource={isLibraryResource}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
