import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { Amount, AmountUnit } from "types/financial";
import { getTurbineCapacity } from "analysis/inputs";
import { CostType } from "services/costService";
import { FinanceId } from "finance/types";
import { amountId } from "./amountIds";

export const otherAmountsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Amount[]>>(async (get) => {
    const capacity = await get(getTurbineCapacity(id));

    const category = CostType.Other;

    return [
      {
        id: amountId({
          unit: AmountUnit.MW,
          category,
        }),
        unit: AmountUnit.MW,
        amount: capacity ?? 0,
        category,
      },
      {
        id: amountId({
          unit: AmountUnit.fixed,
          category,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category,
      },
    ];
  }),
);
