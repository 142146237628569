import { useState, useCallback, useEffect, useMemo } from "react";
import { SubstationType } from "services/substationService";
import { ModalContainer } from "components/SettingsV2/Shared/styles";
import { SubstationHeader } from "../components/SubstationHeader";
import { SubstationContent } from "../components/SubstationContent";
import { SettingsFormActions } from "../../Components/SettingsFormActions";
import { useSetAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import useEnterToSave from "../../useEnterToSave";
import LibraryResourceActions from "components/ConfigurationModal/Components/LibraryResourceActions";
import { objectEquals } from "utils/utils";
import usePrevious from "hooks/usePrevious";
import { mergePreservingChanges } from "utils/utils";

type ProjectSubstationProps = {
  substation: SubstationType;
  onSave: (substation: SubstationType) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
  variant?: "libraryInProject";
  orgSubstationManageAccess?: boolean;
  organisationId?: string;
  duplicateToProject?: (substationId: string) => void;
};

const excludedKeys = ["createdAt", "updatedAt", "note"];

export function ProjectSubstation({
  substation,
  onSave,
  isLoading,
  disabled,
  nodeId,
  variant,
  orgSubstationManageAccess,
  organisationId,
  duplicateToProject,
}: ProjectSubstationProps) {
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);
  const [editedSubstation, setEditedSubstation] =
    useState<SubstationType>(substation);

  const prevSubstation = usePrevious(substation);
  useEffect(() => {
    if (substation) {
      setEditedSubstation((prevTempSubstation) => {
        if (!prevTempSubstation || !prevSubstation) return substation;
        return mergePreservingChanges(
          prevSubstation,
          substation,
          prevTempSubstation,
        );
      });
    }
  }, [substation, setEditedSubstation, prevSubstation]);

  const hasChanged = useMemo(() => {
    if (!substation || !editedSubstation) return false;
    return !objectEquals(substation, editedSubstation, excludedKeys);
  }, [substation, editedSubstation]);

  const onEnterSaveCallback = useCallback(
    () => onSave(editedSubstation),
    [onSave, editedSubstation],
  );
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);

  const handleUpdateDescription = useCallback(
    (description: string) => {
      if (!substation) return;
      setEditedSubstation((prev) => ({
        ...prev,
        note: description,
      }));
      onSave({
        ...substation,
        note: description,
      });
    },
    [substation, onSave],
  );

  return (
    <ModalContainer>
      <SubstationHeader
        substation={editedSubstation}
        nodeId={nodeId}
        description={editedSubstation.note ?? ""}
        onUpdateDescription={handleUpdateDescription}
        disabled={disabled}
      />

      {variant === "libraryInProject" && orgSubstationManageAccess && (
        <LibraryResourceActions
          resourceType="substation"
          resourceId={substation.id}
          nodeId={nodeId}
          organisationId={organisationId ?? ""}
          onDuplicate={() => duplicateToProject?.(substation.id)}
        />
      )}

      <SettingsFormActions
        hasChanges={hasChanged}
        isLoading={isLoading}
        onSave={() => onSave(editedSubstation)}
        onCancel={() => setEditedSubstation(substation)}
      />

      <SubstationContent
        substation={editedSubstation}
        onChange={setEditedSubstation}
        disabled={disabled}
        nodeId={nodeId}
        libraryResourceInProjectContext={variant === "libraryInProject"}
      />
    </ModalContainer>
  );
}
