import { CableType } from "../../../services/cableTypeService";
import { SimpleTurbineType } from "../../../types/turbines";
import { fastMax } from "../../../utils/utils";
import { estimatePowerRating } from "../CableWalk";

export const calculateMaxChainLength = ({
  maxTurbineRating,
  maxCableRating,
}: {
  maxTurbineRating: number;
  maxCableRating: number;
}) => {
  return Math.floor(maxCableRating / maxTurbineRating);
};

export const maxChainLengthFromTypes = (
  turbines: SimpleTurbineType[],
  cables: CableType[],
) => {
  const maxTurbineRating =
    fastMax(turbines.map((type) => type?.ratedPower ?? 0)) * 1e3;

  const maxCableRating = fastMax(
    cables.map((type) => estimatePowerRating(type)),
  );

  const maxChainLength = calculateMaxChainLength({
    maxCableRating,
    maxTurbineRating,
  });

  return maxChainLength;
};
