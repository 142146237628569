import { useEffect } from "react";
import { ValidationWarningTypes } from "./utils";
import { substationSourceId } from "components/Mapbox/constants";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { unwrap } from "jotai/utils";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";
import { substationsInParkFamily } from "state/jotai/substation";
import { parkFamily } from "state/jotai/park";
import { cableCorridorsInParkFamily } from "state/jotai/cableCorridor";
import { pointInPolygon } from "utils/geometry";

export const offshoreSubstationOutsideParkCableCorridorWarningFamily =
  atomFamily(
    ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
      atom<
        Promise<
          | ValidationWarning<ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor>
          | undefined
        >
      >(async (get) => {
        const subTypes = get(unwrap(allSubstationsForProjectAtom));
        if (!subTypes) return;

        const subs = (
          await get(substationsInParkFamily({ parkId, branchId }))
        ).filter(
          (s) =>
            subTypes.get(s.properties.substationTypeId ?? "")?.type ===
            "offshore",
        );

        const park = await get(parkFamily({ parkId, branchId }));
        if (!park) return;
        const corridors = await get(
          cableCorridorsInParkFamily({
            parkId,
            branchId,
          }),
        );
        const outside = subs.filter(
          (t) =>
            !pointInPolygon(t.geometry, park.geometry) &&
            !corridors.some((cc) => pointInPolygon(t.geometry, cc.geometry)),
        );

        if (outside.length === 0) return;
        return {
          type: ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor,
          featureIds: outside.map((t) => t.id),
          parkId: parkId,
        };
      }),
  );

export const OffshoreSubstationOutsideParkCableCorridorJotai = ({
  map,
  parkId,
  source = substationSourceId,
}: {
  map: mapboxgl.Map;
  parkId: string;
  source?: string;
}) => {
  const warning = useAtomValue(
    offshoreSubstationOutsideParkCableCorridorWarningFamily({
      parkId: parkId,
      branchId: undefined,
    }),
  );

  useEffect(() => {
    if (!warning) return;
    if (map.getSource(source))
      for (const id of warning.featureIds)
        map.setFeatureState({ source, id }, { outside: true });
    return () => {
      if (map.getSource(source) && warning?.featureIds)
        for (const id of warning.featureIds)
          map.removeFeatureState({ source, id }, "outside");
    };
  }, [map, warning, source]);

  return null;
};
