import { useJotaiCallback } from "utils/jotai";
import { CableType } from "services/cableTypeService";
import { libraryExportCableTypesFamily } from "state/jotai/exportCableType";
import { libraryCableTypesFamily } from "state/jotai/cableType";

export function useLibraryNodeCables(projectId: string) {
  const updateExportCablesLocal = useJotaiCallback(
    async (_, set, cable: CableType) => {
      set(
        libraryExportCableTypesFamily(projectId),
        async (currentCablesPromise) => {
          const currentCables = await currentCablesPromise;
          const newCables = new Map(currentCables);
          newCables.set(cable.id, cable);
          return newCables;
        },
      );
    },
    [projectId],
  );

  const updateInterArrayCablesLocal = useJotaiCallback(
    async (_, set, cable: CableType) => {
      set(libraryCableTypesFamily(projectId), async (currentCablesPromise) => {
        const currentCables = await currentCablesPromise;
        const newCables = new Map(currentCables);
        newCables.set(cable.id, cable);
        return newCables;
      });
    },
    [projectId],
  );

  return {
    updateExportCablesLocal,
    updateInterArrayCablesLocal,
  };
}
