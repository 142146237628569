import { atom } from "jotai";
import {
  isConcreteFoundation,
  isDetailedJacket,
  isSemiCentral,
  isSemiPeripheral,
  isSimpleJacket,
  isSimpleMonopile,
  isSpar,
  isSteelFoundation,
} from "utils/predicates";
import { valueRounding } from "../components/RightSide/InfoModal/FoundationModal/utils";
import {
  CONCRETE_DENSITY,
  scalingEquationTokens,
  STEEL_DENSITY,
} from "../constants/foundations";
import {
  DEFAULT_JACKETS,
  DEFAULT_MONOPILES,
  DEFAULT_SEMI_CENTRAL_FLOATERS,
  DEFAULT_SEMI_PERIPHERAL_FLOATERS,
  DEFAULT_SPAR_FLOATERS,
  FixedType,
  FloaterType,
  FoundationMaxDepths,
  FoundationMinDepths,
  FoundationType,
} from "../types/foundations";
import { SimpleTurbineType } from "../types/turbines";
import { isDefined, isDetailedMonopile } from "../utils/predicates";
import { TurbineFeature } from "../types/feature";
import Mexp from "math-expression-evaluator";
import { calculateJacketMass } from "functions/jacketSizing";

const defaultFloaters: FloaterType[] = [
  ...DEFAULT_SEMI_CENTRAL_FLOATERS,
  ...DEFAULT_SEMI_PERIPHERAL_FLOATERS,
  ...DEFAULT_SPAR_FLOATERS,
];

const defaultFixed: FixedType[] = [...DEFAULT_MONOPILES, ...DEFAULT_JACKETS];

export const defaultFoundations: FoundationType[] = [
  ...defaultFloaters,
  ...defaultFixed,
];

export type TurbineFeatureWithFoundation = TurbineFeature & {
  properties: {
    foundationId: string;
  };
};

export const isTurbineFeatureWithFoundation = (
  t: TurbineFeature,
): t is TurbineFeatureWithFoundation => isDefined(t.properties.foundationId);

// jotai:
type FloatingFoundationScaledStatistics = {
  turbineTypeId: string;
  foundationId: string;
  scaledConcreteVolume?: number;
  scaledConcreteWeight?: number;
  scaledPrimarySteelWeight?: number;
  scaledSolidBallastWeight: number;
  scaledLiquidBallastWeight: number;
  scaledReinforcementWeight?: number;
  scaledPostTensionCableWeight?: number;
  scaledDisplacementVolume: number;
  scaledDraft?: number;
  scaledFootprintLength?: number;
  scaledFootprintBreath?: number;
  scaledMainDiameter?: number;
};

// jotai:
export const getScaledStatisticsForFloatingFoundation = ({
  currentFoundation,
  turbineTypeId,
  scale,
}: {
  currentFoundation: FloaterType;
  turbineTypeId: string;
  scale: number;
}): FloatingFoundationScaledStatistics => {
  const scaledConcreteVolume = isConcreteFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / CONCRETE_DENSITY,
        10,
      )
    : undefined;

  const scaledConcreteWeight = isConcreteFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        10,
      )
    : undefined;

  const scaledPrimarySteelWeight = isSteelFoundation(currentFoundation)
    ? valueRounding(
        (Math.pow(scale, 3) * currentFoundation.primaryMass) / 1000,
        10,
      )
    : undefined;

  const scaledSolidBallastWeight = valueRounding(
    (Math.pow(scale, 3) * currentFoundation.solidBallastMass) / 1000,
    10,
  );

  const scaledLiquidBallastWeight = valueRounding(
    (Math.pow(scale, 3) * currentFoundation.liquidBallastMass) / 1000,
    10,
  );

  const scaledReinforcementWeight =
    isConcreteFoundation(currentFoundation) &&
    currentFoundation.reinforceDensity
      ? valueRounding(
          (Math.pow(scale, 3) *
            currentFoundation.reinforceDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          10,
        )
      : undefined;

  const scaledPostTensionCableWeight =
    isConcreteFoundation(currentFoundation) && currentFoundation.postTensDensity
      ? valueRounding(
          (Math.pow(scale, 3) *
            currentFoundation.postTensDensity *
            currentFoundation.primaryMass) /
            CONCRETE_DENSITY /
            1000,
          10,
        )
      : undefined;

  const scaledDisplacementVolume = valueRounding(
    Math.pow(scale, 3) * currentFoundation.displacement,
    10,
  );

  const scaledDraft = Math.round(scale * currentFoundation.draft);

  const scaledFootprintLength =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(1.5 * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledFootprintBreath =
    isSemiCentral(currentFoundation) || isSemiPeripheral(currentFoundation)
      ? Math.round(Math.sqrt(3) * scale * currentFoundation.ccDistance)
      : undefined;

  const scaledMainDiameter = isSpar(currentFoundation)
    ? valueRounding(scale * currentFoundation.baseDiameter, 10)
    : undefined;

  return {
    turbineTypeId,
    foundationId: currentFoundation.id,
    scaledConcreteVolume,
    scaledConcreteWeight,
    scaledPrimarySteelWeight,
    scaledSolidBallastWeight,
    scaledLiquidBallastWeight,
    scaledReinforcementWeight,
    scaledPostTensionCableWeight,
    scaledDisplacementVolume,
    scaledDraft,
    scaledFootprintLength,
    scaledFootprintBreath,
    scaledMainDiameter,
  };
};

// jotai:
export const foundationScale = ({
  turbine,
  foundation,
}: {
  turbine: SimpleTurbineType | undefined;
  foundation: FloaterType | undefined;
}): number | undefined => {
  if (!turbine || !foundation) return;

  const scaledTowerMass =
    (((foundation.towerMass * turbine.diameter) / foundation.rotorDiameter) *
      turbine.hubHeight) /
    foundation.hubHeight;
  const scaledMass = turbine.rnaMass + scaledTowerMass;
  const baseMass = foundation.rnaMass + foundation.towerMass;

  return Math.cbrt(scaledMass / baseMass);
};

export const getFoundationDepthRange = (foundationType: FoundationType) => {
  if (isSimpleMonopile(foundationType) || isSimpleJacket(foundationType))
    return {
      minDepth: foundationType.minWaterDepth,
      maxDepth: foundationType.maxWaterDepth,
    };
  return {
    minDepth: FoundationMinDepths[foundationType.type],
    maxDepth: FoundationMaxDepths[foundationType.type],
  };
};

export const turbineTypeAndFloatingFoundationCombinations = (
  tempLayout: TurbineFeature[],
  allFloaters: FloaterType[],
): {
  turbineTypeId: string;
  foundationId: string;
}[] => {
  const combinations: {
    turbineTypeId: string;
    foundationId: string;
  }[] = [];

  const turbinesWithFloatingFoundation = tempLayout.filter(
    (t) =>
      t.properties.foundationId &&
      allFloaters.find((f) => f.id === t.properties.foundationId),
  );

  const turbineTypesIds = Array.from(
    new Set(
      turbinesWithFloatingFoundation.map(
        (turbine) => turbine.properties.turbineTypeId,
      ),
    ),
  );

  turbineTypesIds.forEach((tti) => {
    turbinesWithFloatingFoundation
      .filter((t) => t.properties.turbineTypeId === tti)
      .forEach((t) => {
        const foundationId = t.properties.foundationId;
        if (
          foundationId &&
          !combinations.find((c) => {
            return c.foundationId === foundationId && c.turbineTypeId === tti;
          })
        ) {
          combinations.push({
            turbineTypeId: tti,
            foundationId,
          });
        }
      });
  });
  return combinations;
};

export const showNewFoundationWizardAtom = atom<boolean>(false);

export const newFoundationNodeId = atom<string>("");

export const libraryFoundationsRefreshAtom = atom(1);

export const calculateFoundationWeightPerDepth = ({
  foundationType,
  turbineType,
}: {
  foundationType: FoundationType | undefined;
  turbineType: SimpleTurbineType;
}): { depth: number[]; weight: number[] } | undefined => {
  if (!foundationType) return undefined;

  const { minDepth, maxDepth } = getFoundationDepthRange(foundationType);
  const minDepthInt = Math.floor(minDepth);
  const maxDepthInt = Math.ceil(maxDepth);

  const result = {
    depth: [] as number[],
    weight: [] as number[],
  };

  var mexp = new Mexp();
  for (let depth = minDepthInt; depth <= maxDepthInt; depth++) {
    let weight = 0;
    if (isSimpleMonopile(foundationType) || isSimpleJacket(foundationType)) {
      weight = mexp.eval(
        foundationType.scalingEquation,
        scalingEquationTokens,
        {
          p: turbineType.ratedPower / 1000,
          d: turbineType.diameter,
          w: depth,
        },
      );
    } else if (isDetailedMonopile(foundationType)) {
      const {
        pileDiameter,
        avgPileThickness,
        totalPileLength,
        waterDepth: designWaterDepth,
      } = foundationType;
      const referenceMass =
        ((Math.PI / 4) *
          (Math.pow(pileDiameter, 2) -
            Math.pow(pileDiameter - 2 * avgPileThickness, 2)) *
          totalPileLength *
          STEEL_DENSITY) /
        1000;

      weight =
        referenceMass * (1 - (designWaterDepth - depth) / totalPileLength);
    } else if (isDetailedJacket(foundationType)) {
      const {
        legDiameter,
        legThickness,
        braceDiameter,
        braceThickness,
        jacketHeight,
        numLegs,
        Ltop,
        Lbottom,
        numBays,
        waterDepth: designWaterDepth,
        tpMass,
        pileMass,
      } = foundationType;

      const depthDifference = designWaterDepth - depth;
      const scaledHeight = jacketHeight - depthDifference;

      const jacketMass = calculateJacketMass({
        Ltop,
        Lbottom,
        jacketHeight: scaledHeight,
        numBays,
        legDiameter,
        legThickness,
        braceDiameter,
        braceThickness,
        numLegs,
      });

      weight = (jacketMass + tpMass + numLegs * pileMass) / 1000;
    }
    result.depth.push(depth);
    result.weight.push(weight);
  }
  return result;
};
