import { useEffect } from "react";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { sendInfo } from "../../utils/sentry";
import { atom, useAtomValue } from "jotai";
import { featuresFamily } from "state/jotai/features";
import { ValidationWarning } from "state/validationWarnings";
import { atomFamily } from "utils/jotai";

const MAX_ALLOWED_SIZE = 56000000;

export const maxSizeExceededWarningFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<
      Promise<ValidationWarning<ValidationWarningTypes.ProjectSize> | undefined>
    >(async (get) => {
      const projectData = await get(featuresFamily({ branchId }));
      const sizeOfProjectData = new TextEncoder().encode(
        JSON.stringify(projectData),
      ).length;
      if (sizeOfProjectData < MAX_ALLOWED_SIZE) return;
      return {
        type: ValidationWarningTypes.ProjectSize,
      };
    }),
);

export const MaxSizeExceededJotai = () => {
  const warning = useAtomValue(
    maxSizeExceededWarningFamily({ branchId: undefined }),
  );
  useEffect(() => {
    if (!warning) return;
    sendInfo("Max size of branch shown for a user");
  }, [warning]);

  return null;
};
