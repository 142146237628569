import styled from "styled-components";
import { colors } from "styles/colors";
import { spacing2, spacing3, spacing4, spacing6 } from "styles/space";
import { Column, Row } from "components/General/Layout";

export const ExpandArrowWrapper = styled.div<{ open: boolean }>`
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    transform: rotate(${({ open }) => (!open ? "-90deg" : "0deg")});
    transition: 0.1s;
  }
  ${({ open }) =>
    !open &&
    `
    svg {
      path {
        fill: ${colors.grey500};
      }
    }`};
`;

const addDepthPadding = ({ depth }: { depth: number }) => {
  return `padding-left: calc(var(--list-wrapper-padding) + calc(var(--folder-width) * ${depth}))`;
};

export const VisibleOnHover = styled.div`
  visibility: hidden;
`;

const getListItemGridTemplate = ({
  hasIcon,
  hasExpandIcon,
}: {
  hasIcon: boolean;
  hasExpandIcon: boolean;
}) => {
  let str = ["grid-template-columns:"];

  // Expand arrow
  if (hasExpandIcon) {
    str.push("var(--expand-arrow-width)");
  }

  // Folder icon
  if (hasIcon) {
    str.push("var(--folder-width)");
  }

  // Name and controls
  str.push("1fr auto");
  return str.join(" ");
};

export const ListItemTopRow = styled(Row)<{
  depth: number;
  hasIcon: boolean;
  hasExpandIcon: boolean;
  forceHover?: boolean;
  clickable?: boolean;
  active?: boolean;
}>`
  --expand-arrow-width: 1.4rem;
  --folder-width: 2rem;
  --list-wrapper-padding: ${spacing6};
  display: grid;
  ${getListItemGridTemplate};
  ${addDepthPadding};
  grid-auto-rows: auto;
  gap: ${spacing3};
  ${({ clickable }) => clickable && `cursor: pointer;`};
  min-height: 3.2rem;
  padding-right: ${spacing4};
  border-radius: 4px;
  box-sizing: border-box;
  justify-content: space-between;

  &:hover {
    background-color: ${colors.surfaceHover};

    ${VisibleOnHover}:not(.hidden) {
      visibility: visible;
    }

    .clickable {
      color: ${colors.blue600};
    }
  }

  ${({ forceHover }) =>
    forceHover &&
    `
      background-color: ${colors.surfaceHover};
      .clickable {
        color: ${colors.blue600};
      }
  `}

  ${({ active }) =>
    active &&
    `
      background: ${colors.surfaceSelectedLight};
      color: ${colors.textSelected};
      
      &:hover {
        background: ${colors.surfaceSelectedHover};
      }
  `}
`;

export const addSharedDragStyles = ({
  enableDrag,
  isHoveredBottom,
  isHoveredTop,
  isHoveredMiddle,
}: {
  enableDrag?: boolean;
  isHoveredBottom?: boolean;
  isHoveredMiddle?: boolean;
  isHoveredTop?: boolean;
  depth?: number;
}) => {
  if (!enableDrag) {
    return "";
  }

  if (isHoveredMiddle) {
    return `
        background: ${colors.blue100};
        transition: background 0.15s ease-in-out;
    `;
  }

  if (isHoveredBottom) {
    return `
       &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: ${colors.blue900};
          bottom: -1px;
          right: 0;
          mouse-events: none;
        }
      `;
  }

  if (isHoveredTop) {
    return `
       &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: ${colors.blue900};
          top: -1px;
          right: 0;
          mouse-events: none;
        }
      `;
  }
};

export const ListItemWrapper = styled.div<{
  isHoveredTop?: boolean;
  isHoveredBottom?: boolean;
  isHoveredMiddle?: boolean;
  enableDrag?: boolean;
}>`
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: ${spacing2};

  ${addSharedDragStyles}
  button.is-loading {
    visibility: visible;
  }
`;

export const FolderListWrapper = styled(Column)<{ striped?: boolean }>`
  overflow-y: auto;
  gap: ${spacing2};
  padding-right: ${spacing2};
  width: 100%;
  ${({ striped }) =>
    striped &&
    `
    & > ${ListItemTopRow}:nth-of-type(2n + 1) {
      background-color: ${colors.grey50};
    }
    > ${ListItemTopRow} {
      cursor: pointer;
  
      &:hover {
        background-color: ${colors.grey100};
      }
    }
  `};
`;
