import React from "react";
import styled from "styled-components";
import SearchIcon from "@icons/24/Search.svg";
import { colors } from "styles/colors";
import { spacing6, spacing7 } from "styles/space";
import { IconREMSize, typography } from "styles/typography";
import { RoundIconWrapper } from "components/CompareParksModal/shared";

const NoItemsGenericWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.blue50};
  padding: ${spacing7};
  text-align: center;
`;

const NoItemsGeneric = ({
  Icon,
  headerText,
  subText,
  CallToAction,
  style,
}: {
  headerText?: React.ReactNode;
  subText?: React.ReactNode;
  Icon?: React.ReactNode;
  CallToAction?: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <NoItemsGenericWrapper style={style}>
      <RoundIconWrapper style={{ marginBottom: spacing6 }}>
        <IconREMSize height={1.6} width={1.6} iconColor={colors.white}>
          {Icon ?? <SearchIcon />}
        </IconREMSize>
      </RoundIconWrapper>
      {typeof headerText === "string" ? (
        <p style={{ ...typography.sub2 }}>{headerText}</p>
      ) : (
        headerText
      )}
      {typeof subText === "string" ? (
        <p style={{ ...typography.caption }}>{subText}</p>
      ) : (
        subText
      )}
      {CallToAction}
    </NoItemsGenericWrapper>
  );
};

export default NoItemsGeneric;
