import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { Mixpanel } from "mixpanel";
import {
  analysisResourceWithAccessOnNodeState,
  organisationAnalysisResourceState,
} from "../state";
import {
  AnalysisConfiguration,
  createOrgAnalysisConfigurationWithValues,
  deleteOrgAnalysisConfiguration,
  updateOrgAnalysisConfiguration,
} from "services/configurationService";
import { savingConfigurationInProgressAtom } from "state/configuration";
import { userNodesAccessSyncAtom } from "state/user";
import { isDefined } from "utils/predicates";
import { RESET } from "jotai/utils";
import { useToast } from "hooks/useToast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

const useOrgAnalysisCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setIsSaving = useSetAtom(savingConfigurationInProgressAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { info, success, error } = useToast();
  const { showConfirm } = useConfirm();

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        analysis,
        name,
        projectAccess,
      }: {
        analysis?: AnalysisConfiguration;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const fallback = await get(
        organisationAnalysisResourceState({
          organisationId,
        }),
      );

      try {
        const newName = name
          ? name
          : isDefined(analysis)
            ? `${analysis.name} copy`
            : "Untitled";
        const newAnalysis = {
          ...analysis,
          name: newName,
        } as AnalysisConfiguration;
        const res = await createOrgAnalysisConfigurationWithValues(
          organisationId,
          newAnalysis,
          projectAccess,
        );

        aset(
          get,
          set,
          organisationAnalysisResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );

        Mixpanel.track_old("Created org analysis", {
          analysisId: newAnalysis.id,
          organisationId,
          projectAccess,
        });
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationAnalysisResourceState({
            organisationId,
          }),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      analysis: AnalysisConfiguration,
      skipUsageCheck?: boolean,
    ) => {
      setIsSaving(true);
      const fallback = await get(
        organisationAnalysisResourceState({
          organisationId,
        }),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.ANALYSIS,
          resourceId: analysis.id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        info("Looking for usage...", {
          timeout: 3000,
          groupId: "analysis-update",
        });
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.ANALYSIS,
          analysis.id,
        );
      }

      if (
        usage.length === 0 ||
        skipUsageCheck ||
        (await showConfirm({
          title: "Update configuration",
          message: `Configuration is currently being used in ${usage.length} places, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        info("Saving...", { timeout: 3000, groupId: "analysis-update" });
        const userAccess = get(userNodesAccessSyncAtom).data;
        aset(
          get,
          set,
          organisationAnalysisResourceState({
            organisationId,
          }),
          (cur) => cur.map((c) => (c.id === analysis.id ? analysis : c)),
        );

        return updateOrgAnalysisConfiguration(organisationId, analysis)
          .then(() => {
            Mixpanel.track_old("Update org analysis", {
              analysisId: analysis.id,
              organisationId,
            });
            Object.keys(userAccess.node_access).map((nodeId) =>
              set(
                analysisResourceWithAccessOnNodeState({
                  nodeId,
                }),
                RESET,
              ),
            );
            success("Saved", { timeout: 3000, groupId: "analysis-update" });
          })
          .catch((e) => {
            aset(
              get,
              set,
              organisationAnalysisResourceState({
                organisationId,
              }),
              () => fallback,
            );
            error("Failed to save", {
              timeout: 3000,
              groupId: "analysis-update",
            });

            throw e;
          })
          .finally(() => {
            setIsSaving(false);
          });
      }
      setIsSaving(false);
    },
    [setIsSaving, info, organisationId, showConfirm, success, error],
  );

  const remove = useJotaiCallback(
    async (get, set, analysisId: string) => {
      const fallback = await get(
        organisationAnalysisResourceState({
          organisationId,
        }),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.ANALYSIS,
          resourceId: analysisId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.ANALYSIS,
          analysisId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete configuration",
          message: `Configuration is currently being used in ${usage.length} places, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        const userAccess = get(userNodesAccessSyncAtom).data;
        return deleteOrgAnalysisConfiguration(organisationId, analysisId)
          .then((res) => {
            if (res) {
              aset(
                get,
                set,
                organisationAnalysisResourceState({
                  organisationId,
                }),
                (cur) => [...cur].filter((c) => c.id !== analysisId),
              );
              Object.keys(userAccess.node_access).map((nodeId) =>
                set(
                  analysisResourceWithAccessOnNodeState({
                    nodeId,
                  }),
                  RESET,
                ),
              );
              Mixpanel.track_old("Delete org analysis", {
                analysisId: analysisId,
                organisationId,
              });
            }
            return {
              status: "success",
            };
          })
          .catch((e) => {
            aset(
              get,
              set,
              organisationAnalysisResourceState({
                organisationId,
              }),
              () => fallback,
            );
            throw e;
          });
      } else {
        return {
          status: "cancelled",
        };
      }
    },
    [organisationId, showConfirm],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        analysis,
        projectAccess,
        onSuccess,
      }: {
        analysis: AnalysisConfiguration;
        projectAccess?: string[];
        onSuccess?: (newAnalysis: AnalysisConfiguration) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "analysis",
          defaultName: `${analysis.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const result = await create({
              analysis,
              name,
              projectAccess,
            });
            onSuccess?.(result);
            return result;
          },
        },
      });
    },
    [create, setMidScreenModalTypeOpen],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, analysis: AnalysisConfiguration) => {
      aset(
        get,
        set,
        organisationAnalysisResourceState({ organisationId }),
        (currentAnalyses) => {
          const existing = currentAnalyses.find((a) => a.id === analysis.id);
          return existing
            ? currentAnalyses.map((a) =>
                a.id === analysis.id ? { ...a, ...analysis } : a,
              )
            : [...currentAnalyses, analysis];
        },
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, analysisId: string) => {
      aset(
        get,
        set,
        organisationAnalysisResourceState({ organisationId }),
        (currentAnalyses) => currentAnalyses.filter((a) => a.id !== analysisId),
      );
    },
    [organisationId],
  );

  return {
    create,
    remove,
    update,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgAnalysisCrud;
