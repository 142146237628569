import { projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { Suspense, useCallback, useMemo, useState } from "react";
import { ExportCableFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import Tooltip, { WithTooltip } from "../General/Tooltip";
import { isDefined } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { Divider } from "components/General/Icons";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { dedup } from "utils/utils";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useAtomValue } from "jotai";
import { exportCableTypesWithLevelAtom } from "state/jotai/exportCableType";
import { isOnshoreAtom } from "state/onshore";
import { useOpenComponentsConfig } from "./useOpenConfig";
import { EXPORT_CABLE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useExportCableSettings";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import ComponentsIcon from "@icons/24/Components.svg?react";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;

const Inner = ({
  exportCables,
  editable,
  onSelect,
  field,
  usage,
}: {
  exportCables: ExportCableFeature[];
  editable: boolean;
  onSelect: (val: string) => void;
  field: "cableTypeId" | "onshoreCableTypeId";
  usage: "offshore" | "onshore";
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const allExportCableTypes = useAtomValue(exportCableTypesWithLevelAtom);
  const openNewExportCableConfig = useOpenComponentsConfig(
    projectId,
    EXPORT_CABLE_MENU_ID,
  );

  const usageExportCableTypes = useMemo(
    () =>
      Array.from(allExportCableTypes.values()).filter((c) =>
        c.cable.useAdvancedSettings && c.cable.exportCableUsage
          ? usage === "offshore"
            ? c.cable.exportCableUsage.offshore
            : c.cable.exportCableUsage.onshore
          : true,
      ),
    [allExportCableTypes, usage],
  );

  const typeIds = dedup(
    exportCables.map((s) => s.properties[field]).filter(isDefined),
  );
  const items = useMemo(
    () =>
      usageExportCableTypes.map((t) => ({
        icon: ["project"].includes(t.level) ? (
          <Tooltip text={"Project export cable"}>
            <ProjectIcon />
          </Tooltip>
        ) : (
          <Tooltip text={"Library export cable"}>
            <LibraryIcon />
          </Tooltip>
        ),
        level: t.level,
        value: t.cable.id,
        name: `${t.cable.name} (${t.cable.voltage}kV)`,
        voltage: t.cable.voltage,
        type: t.cable.exportCableType,
      })),
    [usageExportCableTypes],
  );

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");

    return {
      project: projectItems,
      library: libraryItems,
    };
  }, [items]);

  const currentChoice =
    typeIds.length === 1
      ? usageExportCableTypes.find((t) => t.cable.id === typeIds[0])?.cable
          .name ?? "..."
      : "...";

  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          minWidth: "13rem",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          maxWidth: "20rem",
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentChoice}
          </div>
        }
      >
        <>
          {groupedItems.library.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Library component</SubTitle>
              {groupedItems.library.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentChoice.length === 1 && currentChoice === item.name
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelect(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          {groupedItems.project.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Project specific components</SubTitle>
              {groupedItems.project.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentChoice.length === 1 && currentChoice === item.name
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelect(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          ></div>
          <DropdownDivider />
          <MenuItem
            textStyle={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            name="Go to components to create new"
            onClick={() => openNewExportCableConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

function ExportCableTypeSelectorOnshore({
  editable,
  cables,
}: {
  editable: boolean;
  cables: ExportCableFeature[];
}) {
  const [_refresh, refresh] = useState(0);
  const projectId = useAtomValue(projectIdAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const onSelect = useCallback(
    (key: string) => (val: string) => {
      refresh((c) => c + 1);
      trackCanvasOption("change-export-cable-type", {
        projectId,
      });
      const update = cables.map((c) => ({
        ...c,
        properties: {
          ...c.properties,
          [key]: val,
        },
      }));
      updateFeatures({
        update,
      });
    },
    [cables, projectId, updateFeatures],
  );

  if (cables.length === 0) return null;

  return (
    <>
      <WithTooltip
        position="top"
        text="Change type"
        readonlyAware
        key={_refresh}
      >
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Cable type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock
                style={{
                  height: "1rem",
                  width: "7rem",
                }}
              />
            }
          >
            <Inner
              exportCables={cables}
              editable={editable}
              onSelect={onSelect("cableTypeId")}
              field="cableTypeId"
              usage="onshore"
            />
          </Suspense>
        </NamedTooltipContainer>
      </WithTooltip>
      <Divider />
    </>
  );
}

function ExportCableTypeSelectorOffshore({
  editable,
  cables,
}: {
  editable: boolean;
  cables: ExportCableFeature[];
}) {
  const [_refresh, refresh] = useState(0); // Selecting a dropdown needs to refresh the tooltip, otherwise it doesn't disappear.

  const projectId = useAtomValue(projectIdAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const onSelect = useCallback(
    (key: string) => (val: string) => {
      refresh((c) => c + 1);
      trackCanvasOption("change-export-cable-type", {
        projectId,
      });
      const update = cables.map((c) => ({
        ...c,
        properties: {
          ...c.properties,
          [key]: val,
        },
      }));
      updateFeatures({
        update,
      });
    },
    [cables, projectId, updateFeatures],
  );

  if (cables.length === 0) return null;

  return (
    <>
      <Tooltip position="top" text="Change type" readonlyAware key={_refresh}>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Offshore cable type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock
                style={{
                  height: "1rem",
                  width: "7rem",
                }}
              />
            }
          >
            <Inner
              exportCables={cables}
              editable={editable}
              onSelect={onSelect("cableTypeId")}
              field="cableTypeId"
              usage="offshore"
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
      <Tooltip
        position="top"
        text="Change type"
        readonlyAware
        key={_refresh + 1}
      >
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Onshore cable type</NamedTooltipWrapper>
          <Suspense
            fallback={
              <SkeletonBlock
                style={{
                  height: "1rem",
                  width: "7rem",
                }}
              />
            }
          >
            <Inner
              exportCables={cables}
              editable={editable}
              onSelect={onSelect("onshoreCableTypeId")}
              field="onshoreCableTypeId"
              usage="onshore"
            />
          </Suspense>
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </>
  );
}

export default function ExportCableTypeSelector(props: {
  editable: boolean;
  cables: ExportCableFeature[];
}) {
  const onshore = useAtomValue(isOnshoreAtom);
  if (onshore) return <ExportCableTypeSelectorOnshore {...props} />;
  return <ExportCableTypeSelectorOffshore {...props} />;
}
