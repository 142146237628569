import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import {
  Cost,
  CostUnit,
  ComponentProcurementCostForPark,
  _CostUnit,
  unitToAmountUnit,
  ConfidenceLevel,
  CostWithUnit,
} from "types/financial";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  IAcableTypesFamily,
  libraryCableTypesFamily,
  projectCableTypesFamily,
} from "state/jotai/cableType";
import {
  getBranchId,
  getParkId,
  getParkOrSubareaCenter,
  getProjectId,
} from "analysis/inputs";
import {
  CostType,
  isCableFeatureCost,
  isCableOverrideCost,
  isOperationsCost,
  isOperationsOverrideCost,
} from "services/costService";
import { costId } from "../amounts/costIds";
import { amountId } from "../amounts/amountIds";
import { cablesInParkWithType } from "state/jotai/cable";
import { dedup, partition } from "utils/utils";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { listProcurementCostForLibraryInterArrayCablesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { pickLocation } from "../amounts/pickLocation";
import { isDefined } from "utils/predicates";
import { vesselTypesFamily } from "state/jotai/vesselType";

const DEFAULT_SUPPLY_COST: CostWithUnit = {
  cost: DefaultCosts.cable.cable[CostUnit.millionEuroPerKM],
  unit: CostUnit.millionEuroPerKM,
  confidenceLevel: ConfidenceLevel.notSpecified,
};

const DEFAULT_SHIPPING_COST: CostWithUnit = {
  cost: 0,
  unit: CostUnit.millionEuro,
  confidenceLevel: ConfidenceLevel.notSpecified,
};

const projectCableCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, CostWithUnit & { id: string }>>>(async (get) => {
    const projectIdPromise = get(getProjectId(id));
    const costConfig = await get(getConfiguration(id));
    const projectCableTypes = await get(
      projectCableTypesFamily(await projectIdPromise),
    );

    const projectComponentCosts = costConfig.capex.projectComponentCosts.cables;

    const [customCostCables, defaultCostCables] = partition(
      [...projectCableTypes],
      ([id]) => id in projectComponentCosts,
    );

    const customCosts = customCostCables.map(([cableTypeId]) => ({
      ...projectComponentCosts[cableTypeId],
      confidenceLevel: ConfidenceLevel.notSpecified,
    }));

    const defaultCosts = defaultCostCables.map(([cableTypeId]) => ({
      id: cableTypeId,
      ...DEFAULT_SUPPLY_COST,
    }));

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.id, entry]));
  }),
);

const libraryCableCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, ComponentProcurementCostForPark>>>(async (get) => {
    const projectId = await get(getProjectId(id));

    const libraryCableTypes = await get(libraryCableTypesFamily(projectId));

    const libraryCableCosts = await get(
      listProcurementCostForLibraryInterArrayCablesOnNodeAtomFamily(projectId),
    );

    const [customCostCables, defaultCostCables] = partition(
      [...libraryCableTypes],
      ([id]) => libraryCableCosts.has(id),
    );

    const parkCenter = await get(getParkOrSubareaCenter(id));

    const customCosts = customCostCables.map(([cableTypeId]) => {
      const cost = libraryCableCosts.get(cableTypeId);

      if (!cost) throw new Error("Library cable cost not found");

      const shippingCost = pickLocation(cost.shippingCost, parkCenter);

      return {
        componentTypeId: cableTypeId,
        supplyCost: cost.supplyCost
          ? {
              cost: cost.supplyCost.cost,
              unit: _CostUnit.parse(cost.supplyCost.unit),
              confidenceLevel: ConfidenceLevel.notSpecified,
            }
          : DEFAULT_SUPPLY_COST,
        shippingCost,
      };
    });

    const defaultCosts = defaultCostCables.map(([cableTypeId]) => ({
      componentTypeId: cableTypeId,
      supplyCost: DEFAULT_SUPPLY_COST,
      shippingCost: DEFAULT_SHIPPING_COST,
    }));

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.componentTypeId, entry]));
  }),
);

const cableCostsInParkFamily = atomFamily((id: FinanceId) =>
  atom<Promise<ComponentProcurementCostForPark[]>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));
    const cables = await get(cablesInParkWithType({ parkId, branchId }));

    const projectCableCosts = await get(projectCableCostsFamily(id));

    const libraryCableCosts = await get(libraryCableCostsFamily(id));

    const cableTypeIdsInPark = dedup(cables.map(([_, type]) => type.id));

    const libraryCosts: ComponentProcurementCostForPark[] = cableTypeIdsInPark
      .map((id) => libraryCableCosts.get(id))
      .filter(isDefined);

    const projectCosts: ComponentProcurementCostForPark[] = cableTypeIdsInPark
      .map((id) => projectCableCosts.get(id))
      .filter(isDefined)
      .map(({ id, cost, unit, confidenceLevel }) => ({
        componentTypeId: id,
        supplyCost: {
          cost,
          unit,
          confidenceLevel,
        },
        shippingCost: undefined,
      }));

    return [...libraryCosts, ...projectCosts];
  }),
);

export const cableCapexEntriesSelectorFamily = atomFamily((id: FinanceId) =>
  atom(async (get) => {
    const projectId = await get(getProjectId(id));

    const cableTypes = await get(IAcableTypesFamily({ projectId }));

    const configuration = await get(getConfiguration(id));

    if (!configuration) return [];

    const cableCosts = await get(cableCostsInParkFamily(id));

    const {
      capex: { custom, fixed, installation },
    } = configuration;

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Cable)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.Cable,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Cable)
      .filter((c) => c.unit !== CostUnit.millionEuro)
      .flatMap((custom) => ({
        ...custom,
        name: `${custom.name}`,
        id: costId({
          category: CostType.Cable,
          costId: custom.id,
          featureTypeId: CostType.Cable,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[custom.unit],
          category: custom.category,
        }),
      }));

    let installationCosts: Cost[] = [];

    if (isOperationsCost(installation.cables)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.cables;

      const vesselTypes = await get(vesselTypesFamily(undefined));
      const { vesselId, dayRate } = installationVessel;
      const { cost, unit: costUnit } = dayRate;
      const unit = _CostUnit.parse(costUnit);
      const name = vesselTypes.get(vesselId)?.name ?? "";

      installationCosts.push({
        id: `cable_installation_${vesselId}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Cable,
          featureTypeId: vesselId,
        }),
        category: CostType.Cable,
        name,
        cost,
        unit,
        confidenceLevel: undefined,
      });
    } else if (isOperationsOverrideCost(installation.cables)) {
      const { cost, unit, confidenceLevel } = installation.cables;
      installationCosts.push({
        id: `cable_installation`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Cable,
        }),
        category: CostType.Cable,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      });
    }

    let fixedCosts: Cost[] = [];
    if (isCableOverrideCost(fixed.cables)) {
      const { cost, unit, confidenceLevel } = fixed.cables;

      fixedCosts = [
        {
          id: `cables_${unit}`,
          amountId: amountId({
            unit: unitToAmountUnit[unit],
            category: CostType.Cable,
          }),
          category: CostType.Cable,
          name: "Inter array cables",
          cost: cost,
          unit: unit,
          confidenceLevel: confidenceLevel,
        },
      ];
    }

    if (isCableFeatureCost(fixed.cables)) {
      cableCosts.forEach(
        ({
          supplyCost,
          shippingCost,
          componentTypeId: cableTypeId,
        }: ComponentProcurementCostForPark) => {
          const { name } = cableTypes.get(cableTypeId) ?? { name: "Unknown" };

          fixedCosts.push({
            id: `cables_${cableTypeId}`,
            amountId: amountId({
              unit: unitToAmountUnit[supplyCost.unit],
              category: CostType.Cable,
              featureTypeId: cableTypeId,
            }),
            category: CostType.Cable,
            name,
            cost: supplyCost.cost,
            unit: supplyCost.unit,
            confidenceLevel: undefined,
          });

          shippingCost?.cost &&
            fixedCosts.push({
              id: `cables_${cableTypeId}_shipping`,
              amountId: amountId({
                unit: unitToAmountUnit[CostUnit.millionEuro],
                category: CostType.Cable,
              }),
              category: CostType.Cable,
              name: `Shipping ${name}`,
              cost: shippingCost.cost,
              unit: CostUnit.millionEuro,
              confidenceLevel: undefined,
            });
        },
      );
    }

    return [...fixedCosts, ...customCosts, ...flatCosts, ...installationCosts];
  }),
);
