import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import OvalTab from "./OvalTab";

export type TabData = {
  id?: string;
  name: string;
  tabNameContent?: React.ReactNode;
  data: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
};

type Props = {
  tabs: TabData[];
  children?: (tab: React.ReactNode) => React.ReactNode;
  onAfterTabChange?(n: number): void;
  queryParam?: string;
  wrapperStyle?: CSSProperties;
  contentWrapperStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  initialTab?: number;
  buttonSection?: React.ReactNode;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: 100%;
  flex: 1;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 2.4rem 0;
  overflow-y: auto;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
`;

const getCurrentParamValue = (queryParam: string) => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(queryParam);
};

// You need to add an id field to each tab data object for this hook to work
const useSyncCurrentTabWithQueryParam = (
  tabs: TabData[],
  setSelectedIndex: React.Dispatch<SetStateAction<number>>,
  queryParam?: string,
) => {
  const navigate = useNavigate();
  const selectedTabInQueryParam = getCurrentParamValue(queryParam ?? "") ?? "";

  const updateQueryParam = useCallback(
    (selectedTabIndex: number, replace = false) => {
      if (!queryParam) {
        return;
      }

      const tabId = tabs.find((_t, index) => index === selectedTabIndex)?.id;
      if (!tabId) {
        return;
      }

      const newSearchParams = new URLSearchParams(document.location.search);
      newSearchParams.set(queryParam, tabId);

      const currentQueryParamValue = getCurrentParamValue(queryParam);
      if (currentQueryParamValue !== tabId) {
        navigate(
          {
            search: newSearchParams.toString(),
          },
          {
            replace,
          },
        );
      }
    },
    [navigate, queryParam, tabs],
  );

  const onMount = useCallback(
    (initialTab?: number) => {
      if (typeof initialTab === "number") {
        updateQueryParam(initialTab, true);
        return;
      }

      const tabInQueryParam = getCurrentParamValue(queryParam ?? "") ?? "";
      if (tabInQueryParam) {
        const tabIndex = tabs.findIndex((tab) => tab.id === tabInQueryParam);
        if (tabIndex !== -1) {
          setSelectedIndex(tabIndex);
          return;
        }
      }
    },
    [queryParam, setSelectedIndex, tabs, updateQueryParam],
  );

  // When query param change but index hasnt changed yet (Going back/forward in history)
  useEffect(() => {
    const tabIndex = tabs.findIndex(
      (tab) => tab.id === selectedTabInQueryParam,
    );
    if (tabIndex === -1) {
      return;
    }

    setSelectedIndex((curr) => {
      if (curr !== tabIndex) {
        return tabIndex;
      }
      return curr;
    });
  }, [selectedTabInQueryParam, setSelectedIndex, tabs]);

  return { updateQueryParam, onMount };
};

const Tabs = ({
  tabs,
  initialTab,
  onAfterTabChange,
  children,
  wrapperStyle,
  contentWrapperStyle,
  menuStyle,
  queryParam,
  buttonSection,
}: Props) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(initialTab ?? 0);
  const { onMount, updateQueryParam } = useSyncCurrentTabWithQueryParam(
    tabs,
    setSelectedTabIdx,
    queryParam,
  );

  useEffect(() => {
    onAfterTabChange?.(selectedTabIdx);
  }, [selectedTabIdx, onAfterTabChange]);

  // Update query param when loading the tabs component
  useEffect(() => {
    onMount(initialTab);
    // We dont want to run the effect if only initialTab changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMount]);

  const toRender =
    tabs.length < selectedTabIdx ? null : tabs[selectedTabIdx]?.data;
  return (
    <Wrapper style={wrapperStyle}>
      <MenuWrapper style={menuStyle}>
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
            flexDirection: "row",
            paddingBottom: "0.8rem",
          }}
        >
          {tabs.map((t, i) => (
            <OvalTab
              id={t.name}
              key={i}
              text={t.name}
              disabled={t.disabled}
              active={i === selectedTabIdx}
              onClick={() => {
                if (t.disabled) {
                  return;
                }

                setSelectedTabIdx(i);
                updateQueryParam(i);
              }}
            />
          ))}{" "}
        </div>
        {buttonSection}
      </MenuWrapper>
      {children ? (
        children(toRender)
      ) : (
        <ContentWrapper style={contentWrapperStyle}>{toRender}</ContentWrapper>
      )}
    </Wrapper>
  );
};

export default Tabs;
