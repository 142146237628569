import { atomFamily, atomFromFn } from "utils/jotai";
import { Stage, getStages } from "services/stagesService";

export const stagesFamily = atomFamily(
  ({ organisationId }: { organisationId: string }) =>
    atomFromFn<Promise<Stage[]>>(async () => {
      const response = await getStages(organisationId);
      return response.stages;
    }),
);
