import { useAtomValue } from "jotai";
import { parkIdAtom } from "state/pathParams";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ZoomToComment from "./ZoomToComment";
import { InnerZoomToExistingPark, InnerZoomToParks } from "./ZoomToPark";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";

const ZoomToObject = ErrorBoundaryWrapper(
  () => {
    const parkId = useAtomValue(parkIdAtom);
    const { hash } = useLocation();

    const [hasZoomed, setHasZoomed] = useState(Boolean(hash));
    const [hasZoomedIntoParks, sethasZoomedIntoParks] = useState(Boolean(hash));
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");

    const setHasZoomedCallback = useCallback(
      (hasZoomed: boolean) => {
        setHasZoomed(hasZoomed);
        sethasZoomedIntoParks(hasZoomed);
      },
      [setHasZoomed, sethasZoomedIntoParks],
    );

    // Reset hasZoomed value when switching park
    useEffect(() => {
      return () => {
        setHasZoomed(false);
      };
    }, [parkId]);

    if (hasZoomed) {
      return null;
    }

    if (commentId) {
      return <ZoomToComment setHasZoomed={setHasZoomedCallback} />;
    } else {
      if (hasZoomedIntoParks) return null;
      if (parkId) {
        return (
          <InnerZoomToExistingPark
            parkId={parkId}
            setHasZoomed={setHasZoomedCallback}
          />
        );
      }
      return <InnerZoomToParks setHasZoomed={setHasZoomedCallback} />;
    }
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default ZoomToObject;
