// This file implements Zod parsers for GeoJSON as written in
// https://www.rfc-editor.org/rfc/rfc7946
import { z } from "zod";
import {
  Point,
  MultiPoint,
  LineString,
  MultiLineString,
  Polygon,
  MultiPolygon,
  GeometryCollection,
  Feature,
  FeatureCollection,
  BBox,
  Geometry,
} from "geojson";
import { pointsAreEqual } from "../geometry";

const _FeatureLiteral = z.literal("Feature");
const _FeatureCollectionLiteral = z.literal("FeatureCollection");
const _PointLiteral = z.literal("Point");
const _MultiPointLiteral = z.literal("MultiPoint");
const _LineStringLiteral = z.literal("LineString");
const _MultiLineStringLiteral = z.literal("MultiLineString");
const _PolygonLiteral = z.literal("Polygon");
const _MultiPolygonLiteral = z.literal("MultiPolygon");
const _GeometryCollectionLiteral = z.literal("GeometryCollection");

const _Type = z.union([_FeatureLiteral, _FeatureCollectionLiteral]);

export const _GeometryType = z.union([
  _PointLiteral,
  _MultiPointLiteral,
  _LineStringLiteral,
  _MultiLineStringLiteral,
  _PolygonLiteral,
  _MultiPolygonLiteral,
  _GeometryCollectionLiteral,
]);

const _GeometryTypeNoCollection = z.union([
  _PointLiteral,
  _MultiPointLiteral,
  _LineStringLiteral,
  _MultiLineStringLiteral,
  _PolygonLiteral,
  _MultiPolygonLiteral,
]);

export const _Bbox: z.ZodType<BBox> = z
  .tuple([z.number(), z.number(), z.number(), z.number()])
  .or(
    z.tuple([
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
    ]),
  );

export const _Position = z.number().array().min(2).max(3);

export const _Point: z.ZodType<Point> = z.object({
  type: _PointLiteral,
  coordinates: _Position,
  bbox: _Bbox.optional(),
});

const _MultiPoint: z.ZodType<MultiPoint> = z.object({
  type: _MultiPointLiteral,
  coordinates: _Position.array(),
  bbox: _Bbox.optional(),
});

export const _LineString: z.ZodType<LineString> = z.object({
  type: _LineStringLiteral,
  coordinates: _Position.array().min(2),
  bbox: _Bbox.optional(),
});

const _MultiLineString: z.ZodType<MultiLineString> = z.object({
  type: _MultiLineStringLiteral,
  coordinates: _Position.array().min(2).array(),
  bbox: _Bbox.optional(),
});

const _ClosedLineString = _Position
  .array()
  .transform((coords) => {
    if (coords.length === 3 && !pointsAreEqual(coords.at(0)!, coords.at(-1)!))
      return [...coords, coords[0]];
    return coords;
  })
  .refine((coords) => 3 < coords.length, {
    message: "Closed line strings should be at least 4 points long.",
  })
  .refine((coords) => pointsAreEqual(coords.at(0)!, coords.at(-1)!), {
    message: "Closed line strings should start and end at the same point.",
  });

const _PolygonOutline = _ClosedLineString.array().min(0);
const _MultiPolygonOutline = _ClosedLineString.array().array().min(0);

export const _Polygon: z.ZodType<Polygon> = z.object({
  type: _PolygonLiteral,
  coordinates: _PolygonOutline,
});

export const _PolygonOrMultiPolygon: z.ZodType<Polygon | MultiPolygon> =
  z.union([
    z.object({
      type: _PolygonLiteral,
      coordinates: _PolygonOutline,
    }),
    z.object({
      type: _MultiPolygonLiteral,
      coordinates: _MultiPolygonOutline,
    }),
  ]);

export const _MultiPolygon: z.ZodType<MultiPolygon> = z.object({
  type: _MultiPolygonLiteral,
  coordinates: _PolygonOutline.array(),
  bbox: _Bbox.optional(),
});

const _GeometryCollection_RecursionHack = z.object({
  type: _GeometryCollectionLiteral,
  bbox: _Bbox.optional(),
});

const _GeometryCollection: z.ZodType<GeometryCollection> =
  _GeometryCollection_RecursionHack.extend({
    geometries: z.lazy(() =>
      z
        .union([
          _Point,
          _MultiPoint,
          _LineString,
          _MultiLineString,
          _Polygon,
          _MultiPolygon,
        ])
        .or(_GeometryCollection)
        .array(),
    ),
  });

const GeometryTypeToParser = {
  [_PointLiteral.value]: _Point,
  [_MultiPointLiteral.value]: _MultiPoint,
  [_LineStringLiteral.value]: _LineString,
  [_MultiLineStringLiteral.value]: _MultiLineString,
  [_PolygonLiteral.value]: _Polygon,
  [_MultiPolygonLiteral.value]: _MultiPolygon,
  [_GeometryCollectionLiteral.value]: _GeometryCollection,
};

export const _GeometryNoCollection: z.ZodType<
  Exclude<Geometry, GeometryCollection>,
  z.ZodTypeDef,
  unknown
> = z
  .object({
    type: _GeometryTypeNoCollection,
  })
  .passthrough()
  .transform((val, ctx) => {
    switch (val.type) {
      case _PointLiteral.value:
      case _MultiPointLiteral.value:
      case _LineStringLiteral.value:
      case _MultiLineStringLiteral.value:
      case _PolygonLiteral.value:
      case _MultiPolygonLiteral.value: {
        // Need safe parse here because we are in a transform and cannot throw.
        const parser = GeometryTypeToParser[val.type];
        const res = parser.safeParse(val);
        if (res.success) {
          return res.data;
        }
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Geometry ${val.type} failed to parse`,
        });
        return z.NEVER;
      }
      default:
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid geometry type",
        });
        return z.NEVER;
    }
  });
export type GeometryNoCollection = z.infer<typeof _GeometryNoCollection>;

const _Geometry: z.ZodType<Geometry, z.ZodTypeDef, unknown> = z
  .object({
    type: _GeometryType,
  })
  .passthrough()
  .transform((val, ctx) => {
    switch (val.type) {
      case _PointLiteral.value:
        return _Point.parse(val);
      case _MultiPointLiteral.value:
        return _MultiPoint.parse(val);
      case _LineStringLiteral.value:
        return _LineString.parse(val);
      case _MultiLineStringLiteral.value:
        return _MultiLineString.parse(val);
      case _PolygonLiteral.value:
        return _Polygon.parse(val);
      case _MultiPolygonLiteral.value:
        return _MultiPolygon.parse(val);
      case _GeometryCollectionLiteral.value:
        return _GeometryCollection.parse(val);
      default:
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid geometry type",
        });
        return z.NEVER;
    }
  });

export const _Feature: z.ZodType<Feature, z.ZodTypeDef, unknown> = z
  .object({
    type: _FeatureLiteral,
    id: z.string().or(z.number()).optional(),
    geometry: _Geometry,
    properties: z.object({}).passthrough().nullable(),
    bbox: _Bbox.optional(),
  })
  .passthrough();

export const _FeatureCollection: z.ZodType<
  FeatureCollection,
  z.ZodTypeDef,
  unknown
> = z
  .object({
    type: _FeatureCollectionLiteral,
    features: _Feature.array(),
    bbox: _Bbox.optional(),
  })
  .passthrough();

export function multiToSingleGeometryType(type: "MultiPoint"): "Point";
export function multiToSingleGeometryType(
  type: "MultiLineString",
): "LineString";
export function multiToSingleGeometryType(type: "MultiPolygon"): "Polygon";
export function multiToSingleGeometryType(
  type: "MultiPoint" | "MultiLineString" | "MultiPolygon",
): "Point" | "LineString" | "Polygon";
export function multiToSingleGeometryType(
  type: "MultiPoint" | "MultiLineString" | "MultiPolygon",
) {
  return type.replace("Multi", "");
}
