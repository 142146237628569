import { activeBathymetryStyleIdAtom } from "state/map";
import CostLayer from "./costLayer";
import Bathymetry from "./depth";
import SpeedLayer from "./windSpeed";
import { useAtomValue } from "jotai";
import DepthContour from "./depthContours";
import { isOnshoreAtom } from "state/onshore";
import TerrainContourLayer from "./terrainContour";
import HillshadingLayer from "./hillshading";

const SelectedBathymetryStyle = () => {
  const onshore = useAtomValue(isOnshoreAtom);
  const style = useAtomValue(activeBathymetryStyleIdAtom);

  if (onshore) return null;
  if (style === "contour") return <DepthContour />;
  if (style === "raster") return <Bathymetry />;
  return null;
};

export default function GeneralMapLayers() {
  return (
    <>
      <SelectedBathymetryStyle />
      <CostLayer />
      <SpeedLayer />
      <TerrainContourLayer />
      <HillshadingLayer />
    </>
  );
}
