import Substation from "@icons/24/Substation.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import { Row } from "components/General/Layout";

const SubstationSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;

  const { activities } = localConfig.ti.substation;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Load jacket on vessel</SubHeader>
        <SubHeader>Lay pin template</SubHeader>
        <SubHeader>Position pile</SubHeader>
        <SubHeader>Drive pile</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.loadJacketOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadJacketOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  loadJacketOnVessel: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.layPinTemplate`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.layPinTemplate}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  layPinTemplate: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.positionPile`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.positionPile}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  positionPile: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.drivePile`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.drivePile}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  drivePile: Math.round(duration),
                },
              },
            })
          }
        />
        <SubHeader>Lift and lower jacket</SubHeader>
        <SubHeader>Grout jacket</SubHeader>
        <SubHeader>Load topside on vessel</SubHeader>
        <SubHeader>Lift and attach topside</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.liftAndLowerJacket`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndLowerJacket}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  liftAndLowerJacket: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.groutJacket`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.groutJacket}
          step={1}
          validate={(e) => e >= 1 && e <= 50}
          validationMessage={`Must be between 1 and 50`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  groutJacket: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.loadTopsideOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadTopsideOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 50}
          validationMessage={`Must be between 1 and 50`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  loadTopsideOnVessel: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.substation.activities.liftAndAttachTopside`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.liftAndAttachTopside}
          step={1}
          validate={(e) => e >= 1 && e <= 50}
          validationMessage={`Must be between 1 and 50`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              substation: {
                ...localConfig.ti.substation,
                activities: {
                  ...activities,
                  liftAndAttachTopside: Math.round(duration),
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const SubstationActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  return (
    <InstallationCategoryEntry
      title="Substations"
      icon={<Substation />}
      defaultOpen={true}
    >
      <SubstationSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
