import React from "react";
import { Column, Row } from "components/General/Layout";
import CloseIcon from "@icons/24/Close.svg";
import EarthIcon from "@icons/24/Earth.svg";
import WindTurbineIcon from "@icons/24/WindTurbine.svg";
import { SVGWrapper } from "@icons/svgUtils";
import { useClickOutside } from "hooks/useClickOutside";
import {
  DescriptionContainer,
  EntryContainer,
  Label,
  ModalContainer,
  No,
  SecondaryText,
  SectionHeader,
  StyledLink,
  Text,
  Yes,
} from "./shared";

export default function ProjectAccessOverviewModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  return (
    <ModalContainer ref={ref}>
      <Column style={{ gap: "0.8rem" }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <h3 style={{ margin: 0 }}>Project role access</h3>
          <SVGWrapper
            size={1.4}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </SVGWrapper>
        </Row>
        <Text>
          Here's a breakdown of what each role means in terms of access.{" "}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={
              "https://help.vindtech.com/en/articles/7056300-access-management#h_6ce2c3099f"
            }
          >
            Read more
          </StyledLink>
        </Text>
      </Column>
      <Column style={{ gap: "1.2rem" }}>
        <Row>
          <DescriptionContainer>
            <div />
          </DescriptionContainer>
          <EntryContainer>
            <Label>Admin</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Editor</Label>
          </EntryContainer>
          <EntryContainer>
            <Label>Viewer</Label>
          </EntryContainer>
        </Row>
        <SectionHeader
          style={{ marginTop: 0 }}
          icon={<EarthIcon />}
          text="General"
        />
        <Row>
          <DescriptionContainer>
            <Text>Manage access to project</Text>
          </DescriptionContainer>
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>See project member activity</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Edit/Use all project features</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>See/Run analysis</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Upload project features</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Download project features</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <Yes />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Share project public</Text>
          </DescriptionContainer>
          <Yes />
          <No />
          <No />
        </Row>
        <SectionHeader icon={<WindTurbineIcon />} text="Turbines" />
        <Row>
          <DescriptionContainer>
            <Text>Edit/Add/See all details on project turbines</Text>
          </DescriptionContainer>
          <Yes />
          <No />
          <No />
        </Row>
        <Row>
          <DescriptionContainer>
            <Text>Use Project and Library turbines*</Text>
          </DescriptionContainer>
          <Yes />
          <Yes />
          <No />
        </Row>

        <Row />
        <Row>
          <SecondaryText>
            * By Use, we mean being able to place in a park, run analysis on it
            and see basic information such as name, diameter, height and rated
            power. Access to add, edit and see all details on library turbines
            are handled with a separate Library access.
          </SecondaryText>
        </Row>
      </Column>
    </ModalContainer>
  );
}
