import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Suspense, useMemo, useRef, useState } from "react";
import { Column, Row } from "components/General/Layout";
import Tabs from "components/General/Tabs";
import { usersInOrganisationState } from "components/Organisation/state";
import UserImageRound from "components/UserImage/UserImageRound";
import { adminInOrganisationSelectorFamily } from "state/user";
import { organisationRightSideModal } from "../../state";
import Tooltip from "components/General/Tooltip";
import { useToast } from "hooks/useToast";
import {
  ContentHeader,
  ContentHeaderColumn,
  ContentWrapper,
  Text,
} from "../../style";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import OrganisationResourceUserTable from "./tabs/OrganisationUserResourceTable";
import { UserGroups } from "./tabs/UserGroups";
import { UserProjects } from "./tabs/UserProjects";
import { colors } from "styles/colors";
import { QuestionIconComp } from "components/AccessOverview/shared";
import { Anchor } from "components/General/Anchor";
import OrganisationAccessOverviewModal from "components/AccessOverview/OrganisationAccessOverviewModal";

export default function UserContent({ userId }: { userId: string }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const orgRoleRef = useRef<HTMLDivElement>(null);
  const { info } = useToast();

  const user = useAtomValue(usersInOrganisationState(organisationId)).find(
    (u) => u.user_id === userId,
  );

  const isAdminInOrg = useAtomValue(
    adminInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const rightSideModalContent = useAtomValue(
    organisationRightSideModal(organisationId),
  );
  const [showOrgRoleAccessModal, setOrgRoleAccessModal] = useState(false);

  const tabs = useMemo(() => {
    if (!user) return [];
    const ts = [];
    if (isAdminInOrg) {
      ts.push({
        name: "Projects",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <UserProjects user={user} />
          </Suspense>
        ),
      });
    }
    if (isAdminInOrg) {
      ts.push({
        name: "Library access",
        data: (
          <Suspense fallback={<TablePlaceholder />}>
            <OrganisationResourceUserTable user={user} />
          </Suspense>
        ),
      });
    }

    ts.push({
      name: "Groups",
      data: (
        <Suspense fallback={<TablePlaceholder />}>
          <UserGroups user={user} />
        </Suspense>
      ),
    });

    return ts;
  }, [isAdminInOrg, user]);

  if (!user)
    return (
      <ContentWrapper>
        <h4>User not found</h4>
      </ContentWrapper>
    );

  return (
    <ContentWrapper>
      <Row
        style={{
          gap: "2.4rem",
          alignItems: "center",
        }}
      >
        <UserImageRound user={user} size={8} />
        <ContentHeaderColumn>
          <ContentHeader>{user.nickname}</ContentHeader>
          <Tooltip text="Click to copy email to clipboard">
            <Text
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(user.email);
                info("Copied to clipboard", {
                  timeout: 2000,
                  showCountdown: true,
                });
              }}
            >
              {user.email}
            </Text>
          </Tooltip>
          <Column
            ref={orgRoleRef}
            style={{
              gap: 0,
            }}
          >
            {user.org_role && (
              <Row alignCenter>
                <Text
                  style={{
                    color: colors.textSecondary,
                  }}
                >
                  Organisation role:
                </Text>
                <Text
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {user.org_role}
                </Text>

                <QuestionIconComp
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrgRoleAccessModal((cur) => !cur);
                  }}
                />
              </Row>
            )}
          </Column>
        </ContentHeaderColumn>
        {showOrgRoleAccessModal && (
          <Anchor
            baseRef={orgRoleRef}
            floatPlace="topLeft"
            basePlace="bottomLeft"
          >
            <OrganisationAccessOverviewModal
              onClose={() => setOrgRoleAccessModal(false)}
            />
          </Anchor>
        )}
      </Row>
      <Tabs
        initialTab={
          rightSideModalContent?.meta?.showLibraryAccess ? 1 : undefined
        }
        menuStyle={{
          justifyContent: "space-between",
          whiteSpace: "nowrap",
        }}
        tabs={tabs}
        wrapperStyle={{
          overflowY: "hidden",
        }}
        contentWrapperStyle={{
          height: "100%",
        }}
      />
    </ContentWrapper>
  );
}
