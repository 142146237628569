import { InboundMessage } from "ably";
import { useAblyGeneric } from "hooks/useAblyGeneric";
import useVesselTypeCrud from "hooks/useVesselTypeCrud";
import { useMemo, useCallback } from "react";
import { _VesselType } from "services/vesselService";
import { ABLY_VESSEL_TYPE_UPDATE, ABLY_VESSEL_TYPE_DELETE } from "state/ably";
import { z } from "zod";

export function useAblyProjectVessels(projectId: string) {
  const { updateLocal, deleteLocal } = useVesselTypeCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceivedUpdate = useCallback(
    (message: InboundMessage) => {
      const vessel = _VesselType.parse(message.data.meta);
      updateLocal(vessel);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const vesselId = z.string().parse(message.data.meta);
      deleteLocal(vesselId);
    },
    [deleteLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_VESSEL_TYPE_UPDATE,
        onMessageReceived: onMessageReceivedUpdate,
      },
      {
        eventName: ABLY_VESSEL_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceivedUpdate, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, projectId);
}
