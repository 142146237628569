import { z } from "zod";
import * as spec from "../../api/customer";

export const _NewOrgRes = spec.components.schemas.OrganisationInformation;
export type NewOrgRes = z.infer<typeof _NewOrgRes>;

export const _OrganisationStatus = z.enum(["deactivated"]).nullish();
export type OrganisationStatus = z.infer<typeof _OrganisationStatus>;

export const _InternalOrg = z.object({
  name: z.string(),
  id: z.string(),
  featureAccess: z.string().array(),
  userCount: z.number(),
  owner: z.string().nullish(),
  status: _OrganisationStatus,
});

export type InternalOrg = z.infer<typeof _InternalOrg>;

export const _LibraryItemsCount = z.object({
  turbineCount: z.number(),
  foundationCount: z.number(),
  cableCount: z.number(),
  configCount: z.number(),
  exportCableCount: z.number(),
});

export type LibraryItemsCount = z.infer<typeof _LibraryItemsCount>;
