import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { foundationProcurementCostsAtom } from "../state";
import { organisationIdAtom } from "state/pathParams";
import FoundationCostTable from "./FoundationCostTable";
import {
  FoundationProcurementCost,
  ProcurementCost,
  Regions,
} from "services/procurementCostService";
import { CostUnit } from "types/financial";
import { SearchInput } from "components/General/Input";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg";
import { Row } from "components/General/Layout";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { CostType } from "services/costService";
import NewItemModal from "components/NewItemModal/NewItemModal";
import Fuse from "fuse.js";
import useTextInput from "hooks/useTextInput";
import { useToast } from "hooks/useToast";
import useOrgProcurementCostCrud from "../useProcurementCostCrud";

interface FoundationProcurementCostsProps {
  localTableData: FoundationProcurementCost[];
  setLocalTableData: React.Dispatch<
    React.SetStateAction<FoundationProcurementCost[]>
  >;
  setTableData: React.Dispatch<
    React.SetStateAction<FoundationProcurementCost[]>
  >;
  setDataToBeSaved: React.Dispatch<React.SetStateAction<ProcurementCost[]>>;
  save: (dataToSave: FoundationProcurementCost[]) => Promise<void>;
  isSaving: boolean;
}

const DEFAULT_UNIT = CostUnit.millionEuroPerUnit;
const REGIONS: Regions[] = ["Europe", "Asia", "USA", "Other"];
export const DEFAULT_FOUNDATION_TABLE_NAME = "Fabrication Location";

export const getDefaultFoundationCost = (material: string): number => {
  const defaultCosts = DefaultCosts[CostType.Foundation];
  const materialCosts = defaultCosts[material as keyof typeof defaultCosts];
  if (typeof materialCosts === "object") {
    if ("€/m³" in materialCosts) {
      return materialCosts["€/m³"];
    } else if ("€/t" in materialCosts) {
      return materialCosts["€/t"];
    }
  }
  return 0;
};

const createDefaultFoundationTable = (
  tableName?: string,
): FoundationProcurementCost => ({
  componentType: "foundation",
  tableName: tableName || DEFAULT_FOUNDATION_TABLE_NAME,
  costs: {
    monopile: {
      material: {
        primarySteel: {
          cost: getDefaultFoundationCost("primarySteel"),
          unit: CostUnit.euroPerT,
        },
      },
      shippingCost: REGIONS.map((region) => ({
        region,
        cost: 0,
        unit: DEFAULT_UNIT,
      })),
    },
    jacket: {
      material: {
        primarySteel: {
          cost: getDefaultFoundationCost("primarySteel"),
          unit: CostUnit.euroPerT,
        },
      },
      shippingCost: REGIONS.map((region) => ({
        region,
        cost: 0,
        unit: DEFAULT_UNIT,
      })),
    },
    floating: {
      material: {
        primarySteel: {
          cost: getDefaultFoundationCost("primarySteel"),
          unit: CostUnit.euroPerT,
        },
        concrete: {
          cost: getDefaultFoundationCost("concrete"),
          unit: CostUnit.euroPerM3,
        },
        solidBallast: {
          cost: getDefaultFoundationCost("solidBallast"),
          unit: CostUnit.euroPerT,
        },
        reinforcement: {
          cost: getDefaultFoundationCost("reinforcement"),
          unit: CostUnit.euroPerT,
        },
        postTensionCables: {
          cost: getDefaultFoundationCost("postTensionCables"),
          unit: CostUnit.euroPerT,
        },
      },
      shippingCost: REGIONS.map((region) => ({
        region,
        cost: 0,
        unit: DEFAULT_UNIT,
      })),
    },
  },
});

const FoundationProcurementCosts = ({
  localTableData,
  setLocalTableData,
  setTableData,
  setDataToBeSaved,
  save,
  isSaving,
}: FoundationProcurementCostsProps) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const foundationProcurementCosts = useAtomValue(
    foundationProcurementCostsAtom(organisationId),
  );
  const { success: showSuccess, error: showError, info: showInfo } = useToast();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [searchInput, onSearchInputChange, setSearchInput] = useTextInput("");
  const [hasGenericTable, setHasGenericTable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { remove } = useOrgProcurementCostCrud();

  useEffect(() => {
    const initialData =
      foundationProcurementCosts.length > 0
        ? foundationProcurementCosts
        : [createDefaultFoundationTable()];

    setTableData(initialData);
    setLocalTableData(initialData);
    setHasGenericTable(foundationProcurementCosts.length === 0);
  }, [foundationProcurementCosts, setTableData, setLocalTableData]);

  const handleDataChange = useCallback(
    (
      foundationType: keyof FoundationProcurementCost["costs"],
      material: string,
      field: string,
      newValue: number,
      tableId: string | number,
    ) => {
      setLocalTableData((prevData) => {
        const newData = JSON.parse(
          JSON.stringify(prevData),
        ) as FoundationProcurementCost[];
        const tableIndex = newData.findIndex(
          (table) =>
            table.id === tableId || (typeof tableId === "number" && !table.id),
        );
        if (tableIndex === -1) return prevData;

        const currentCost = newData[tableIndex].costs[foundationType];
        if (field === "supplyCost" && currentCost.material) {
          type MaterialType = keyof typeof currentCost.material;
          const materialKey = material as MaterialType;
          newData[tableIndex].costs[foundationType].material[materialKey].cost =
            newValue;
        } else if (currentCost.shippingCost) {
          const shippingIndex = currentCost.shippingCost.findIndex(
            (t) => t.region === field,
          );
          if (shippingIndex !== -1) {
            currentCost.shippingCost[shippingIndex].cost = newValue;
          }
        }
        setDataToBeSaved(newData);
        return newData;
      });
    },
    [setLocalTableData, setDataToBeSaved],
  );

  const handleTableNameChange = useCallback(
    (newTableName: string, tableId: string | number) => {
      setLocalTableData((prevData) => {
        const newData = JSON.parse(
          JSON.stringify(prevData),
        ) as FoundationProcurementCost[];
        const tableIndex = newData.findIndex(
          (table) =>
            table.id === tableId || (typeof tableId === "number" && !table.id),
        );
        if (tableIndex === -1) return prevData;

        newData[tableIndex].tableName = newTableName;
        setDataToBeSaved(newData);
        return newData;
      });
    },
    [setLocalTableData, setDataToBeSaved],
  );

  const handleAddTable = useCallback(
    (tableName: string) => {
      const localData = JSON.parse(
        JSON.stringify(localTableData),
      ) as FoundationProcurementCost[];
      const newTable = createDefaultFoundationTable(tableName);
      const updatedData = [newTable, ...localData];
      setLocalTableData(updatedData);
      save(updatedData);
    },
    [localTableData, setLocalTableData, save],
  );

  const handleDeleteTable = useCallback(
    async (tableId: string | number) => {
      if (hasGenericTable || localTableData.length === 1) {
        showError("You need at least one table");
        return;
      }
      showInfo("Deleting...");
      setIsUpdating(true);

      const localData = JSON.parse(
        JSON.stringify(localTableData),
      ) as FoundationProcurementCost[];
      const tableToDelete = localData.find(
        (table) =>
          table.id === tableId || (typeof tableId === "number" && !table.id),
      );
      if (!tableToDelete?.id) {
        setLocalTableData((prevData) =>
          prevData.filter(
            (table) =>
              table.id !== tableId &&
              (typeof tableId !== "number" || table.id !== undefined),
          ),
        );

        showSuccess("Table deleted successfully");
        setIsUpdating(false);
        return;
      }

      const result = await remove(tableToDelete.id, "foundation");
      if (result.success) {
        setLocalTableData((prevData) =>
          prevData.filter((table) => table.id !== tableToDelete.id),
        );
        setTableData((prevData) =>
          prevData.filter((table) => table.id !== tableToDelete.id),
        );
        setDataToBeSaved((prevData) =>
          prevData.filter(
            (table) => "id" in table && table.id !== tableToDelete.id,
          ),
        );
        showSuccess("Table deleted successfully");
        setIsUpdating(false);
      } else {
        console.error("Failed to delete procurement cost table:", result.error);
        showError(result.error || "Failed to delete table. Please try again.");
        setIsUpdating(false);
      }
    },
    [
      localTableData,
      hasGenericTable,
      setLocalTableData,
      setTableData,
      setDataToBeSaved,
      remove,
      showSuccess,
      showError,
      showInfo,
    ],
  );

  const handleDuplicateTable = useCallback(
    (tableId: string | number) => {
      const localData = JSON.parse(
        JSON.stringify(localTableData),
      ) as FoundationProcurementCost[];
      const tableToDuplicate = localData.find(
        (table) =>
          table.id === tableId || (typeof tableId === "number" && !table.id),
      );
      if (!tableToDuplicate) return;
      const newTable: FoundationProcurementCost = {
        ...tableToDuplicate,
        id: undefined,
        tableName: `${tableToDuplicate.tableName} (Copy)`,
      };
      const updatedData = [newTable, ...localData];
      setLocalTableData(updatedData);
      save(updatedData);
    },
    [localTableData, setLocalTableData, save],
  );

  const fuse = useMemo(
    () =>
      new Fuse(localTableData, {
        keys: ["tableName"],
        includeScore: true,
      }),
    [localTableData],
  );

  const searchResults = useMemo(() => {
    if (searchInput === "") {
      return localTableData;
    }
    return fuse.search(searchInput).map((result) => result.item);
  }, [localTableData, fuse, searchInput]);

  return (
    <>
      <Row>
        <SearchInput
          placeholder="Search tables"
          value={searchInput}
          onChange={onSearchInputChange}
          onClear={() => setSearchInput("")}
        />
        <Button
          buttonType="secondary"
          onClick={() => setIsAddModalOpen(true)}
          text={"Add table"}
          icon={<AddIcon />}
        />
      </Row>
      {searchResults.map((table, index) => (
        <FoundationCostTable
          key={table.id || index}
          data={table}
          onDataChange={handleDataChange}
          handleTableNameChange={handleTableNameChange}
          tableId={table.id || index}
          onDeleteTable={handleDeleteTable}
          onDuplicateTable={handleDuplicateTable}
          isUpdating={isUpdating || isSaving}
          isDeleteDisabled={hasGenericTable || localTableData.length === 1}
        />
      ))}
      {isAddModalOpen && (
        <NewItemModal
          title="Add Fabrication Location"
          subtitle="Select which area to add a new table for"
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={handleAddTable}
          placeholder="Enter table name"
          defaultValue={""}
        />
      )}
    </>
  );
};

export default FoundationProcurementCosts;
