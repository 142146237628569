import React, { useEffect, useMemo, useState } from "react";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import {
  DropDownActionItem,
  DropDownItem,
} from "components/General/Dropdown/DropdownItems";
import ProjectIcon from "@icons/24/ProjectGlobe.svg";
import LibraryIcon from "@icons/24/Library.svg";
import styled, { CSSProperties } from "styled-components";
import { IconREMSize, typography } from "styles/typography";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import { colors } from "styles/colors";
import { useAtom, useAtomValue } from "jotai";
import {
  currentTurbineIdAtom,
  simpleTurbineTypesWithLevelAtom,
} from "state/jotai/turbineType";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { useOpenComponentsConfig } from "components/CanvasSelectOption/useOpenConfig";
import { SimpleTurbineTypeWithLevel } from "types/turbines";
import Fuse from "fuse.js";
import { SearchInput } from "components/General/Input";
import ComponentsIcon from "@icons/24/Components.svg?react";

const DropDownTextWrapper = styled.div`
  ${typography.body}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 10.2rem;
`;

const DropDownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectTurbineDropDown = ({
  projectId,
  listContainerStyle,
  buttonStyle,
  className,
  disabled,
}: {
  projectId: string;

  listContainerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  className?: string;
  disabled?: boolean;
}) => {
  const [currentTurbineId, setCurrentTurbineId] = useAtom(
    currentTurbineIdAtom({
      projectId,
    }),
  );

  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);
  const currentTurbine = useMemo(() => {
    return allTurbineTypes.get(currentTurbineId);
  }, [allTurbineTypes, currentTurbineId]);
  const openNewTurbineConfig = useOpenComponentsConfig(
    projectId,
    TURBINE_MENU_ID,
  );
  const [search, setSearch] = useState("");
  const [filteredResources, setFilteredResources] = useState<
    Map<string, SimpleTurbineTypeWithLevel>
  >(new Map());

  useEffect(() => {
    if (!search) {
      setFilteredResources(new Map(allTurbineTypes));
      return;
    }

    const fuse = new Fuse(Array.from(allTurbineTypes.values()), {
      keys: ["turbine.name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(search).map((result) => result.item);

    setFilteredResources(new Map(results.map((r) => [r.turbine.id, r])));
  }, [search, allTurbineTypes]);

  const items = useMemo(
    () =>
      Array.from(filteredResources.values()).map((t) => ({
        level: t.level,
        value: t.turbine.id,
        name: t.turbine.name,
        icon:
          t.level === "standard" ? (
            <VindLogo />
          ) : t.level === "project" ? (
            <ProjectIcon />
          ) : (
            <LibraryIcon />
          ),
      })),
    [filteredResources],
  );

  const actionItem: DropDownActionItem = useMemo(() => {
    return {
      value: "create-new",
      name: "Go to components to create new",
      onClick: () => openNewTurbineConfig(),
      icon: <ComponentsIcon />,
      disabled: false,
    };
  }, [openNewTurbineConfig]);

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");
    const standardItems = items.filter((ft) => ft.level === "standard");

    return {
      project: projectItems,
      library: libraryItems,
      standard: standardItems,
    };
  }, [items]);

  const configItems = useMemo<DropDownItem[]>(() => {
    const items: DropDownItem[] = [];

    // Project items
    if (groupedItems.project.length > 0) {
      items.push({
        value: "project-title", // unique value for the title
        name: "Project specific components",
        isTitle: true, // Add this to your DropDownItem type if needed
        disabled: true, // Make title non-clickable
      });
      items.push(
        ...groupedItems.project.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <ProjectIcon />
            </IconREMSize>
          ),
        })),
      );
    }

    // Library items
    if (groupedItems.library.length > 0) {
      items.push({
        value: "library-title",
        name: "Library components",
        isTitle: true,
        disabled: true,
      });
      items.push(
        ...groupedItems.library.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <LibraryIcon />
            </IconREMSize>
          ),
        })),
      );
    }

    // Standard items
    if (groupedItems.standard.length > 0) {
      items.push({
        value: "standard-title",
        name: "Vind AI components",
        isTitle: true,
        disabled: true,
      });
      items.push(
        ...groupedItems.standard.map((c) => ({
          value: c.value,
          name: c.name ?? "Untitled",
          icon: (
            <IconREMSize width={1.4} height={1.4}>
              <VindLogo />
            </IconREMSize>
          ),
        })),
      );
    }

    return items;
  }, [groupedItems]);

  return (
    <DropdownButton
      searchBar={
        <div style={{ padding: "1.2rem 1.2rem 0.8rem" }}>
          <SearchInput
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            autoFocus={false}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClear={() => {
              setSearch("");
            }}
            placeholder={`Search turbine components`}
            style={{
              flexGrow: 1,
              width: "30rem",
            }}
          />
        </div>
      }
      className={className}
      renderText={(text) => (
        <DropDownTextWrapper>
          <DropDownText>{text}</DropDownText>
        </DropDownTextWrapper>
      )}
      items={configItems}
      disabled={disabled}
      size={"small"}
      listContainerStyle={listContainerStyle}
      style={buttonStyle}
      onSelectItem={setCurrentTurbineId}
      buttonText={currentTurbine?.turbine.name ?? "Select a turbine"}
      selectedItemValue={currentTurbine?.turbine.id}
      actionItem={actionItem}
    />
  );
};

export default SelectTurbineDropDown;
