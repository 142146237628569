import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { substationsInParkFamily } from "state/jotai/substation";
import { allSubstationsForProjectAtom } from "state/jotai/substationType";
import { ValidationWarning } from "state/validationWarnings";
import { ExportCableFeature, SubstationFeature } from "types/feature";
import { SubstationType } from "services/substationService";
import { isOnshoreAtom } from "state/onshore";

export const getExportCableNotConnected = (
  exportCables: ExportCableFeature[],
  substations: SubstationFeature[],
  substationTypes: Map<string, SubstationType>,
) => {
  const illegals = [];
  for (const c of exportCables) {
    const { fromSubstationId, toSubstationId } = c.properties;

    const fromSubId =
      substations.find((s) => s.id === fromSubstationId)?.properties
        .substationTypeId ?? "";
    const fromType = substationTypes.get(fromSubId);

    const toSubId =
      substations.find((s) => s.id === toSubstationId)?.properties
        .substationTypeId ?? "";
    const toType = substationTypes.get(toSubId);

    if (!fromType || !toType || fromType.type == toType.type)
      illegals.push(c.id);
  }
  return illegals;
};

export const exportCableNotConnectedWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExportCableNotConnected>
        | undefined
      >
    >(async (get) => {
      if (get(isOnshoreAtom)) return;
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );

      const substations = await get(
        substationsInParkFamily({ parkId, branchId }),
      );
      const substationTypes = await get(allSubstationsForProjectAtom);

      const illegals = getExportCableNotConnected(
        exportCables,
        substations,
        substationTypes,
      );

      if (illegals.length === 0) return;
      return {
        type: ValidationWarningTypes.ExportCableNotConnected,
        featureIds: illegals,
        parkId: parkId,
      };
    }),
);
