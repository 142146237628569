import { ProjectFeature } from "../../types/feature";

const unAckedSaves: Map<string, string[]> = new Map();

export function addChangedFeaturesToAckMap(
  uniqueUpdateId: string,
  cf: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
) {
  const changedFeatureIds = getIdsOfChangedFeatures(cf);
  changedFeatureIds.forEach((id) => {
    if (!unAckedSaves.has(id)) {
      unAckedSaves.set(id, [uniqueUpdateId]);
    } else {
      unAckedSaves.get(id)!.push(uniqueUpdateId);
    }
  });
}

function getIdsOfChangedFeatures(cf: {
  add?: ProjectFeature[];
  remove?: string[];
  update?: ProjectFeature[];
}) {
  return [
    ...(cf.add ?? []).map((f) => f.id),
    ...(cf.update ?? []).map((f) => f.id),
    ...(cf.remove ?? []),
  ];
}
