import { AnalysisConfiguration } from "services/configurationService";
import { Suspense, useCallback, useEffect, useState } from "react";
import { LibraryResourceHeader } from "./ResourceHeader";
import { idToAnalysisChangelogId } from "components/InputChangelog/const";
import AnalysisSettingCustomAdmin from "components/ConfigurationModal/AnalysisSettings/AnalysisSettingCustomAdmin";
import useOrgAnalysisCrud from "components/Organisation/Library/analysis/useOrgAnalysisCrud";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";

export const AnalysisResourceContent = ({
  resource,
  organisationId,
}: {
  resource: AnalysisConfiguration;
  organisationId: string;
}) => {
  const { update: updateAnalysisConfig } = useOrgAnalysisCrud();

  const [editResource, setEditResource] = useState(resource);
  useEffect(() => {
    setEditResource(resource);
  }, [resource, setEditResource]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<AnalysisConfiguration>) => {
      setEditResource((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setEditResource],
  );

  if (!organisationId || !editResource) {
    return null;
  }

  return (
    <>
      <LibraryResourceHeader
        resourceType={"analysis"}
        description={editResource.description ?? ""}
        name={editResource.name ?? ""}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? editResource.name,
            description: description ?? editResource.description,
          })
        }
        onSave={({ name, description }) => {
          updateAnalysisConfig(
            {
              ...editResource,
              name: name ?? editResource.name,
              description: description ?? editResource.description,
            },
            true,
          );
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            description: resource.description,
          });
        }}
        changelogData={{
          id: idToAnalysisChangelogId(resource.id),
          category: "org_analysis_manage",
        }}
      >
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("analysis")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <Suspense>
        <AnalysisSettingCustomAdminWrapper
          resource={resource}
          organisationId={organisationId}
        />
      </Suspense>
    </>
  );
};

const AnalysisSettingCustomAdminWrapper = ({
  resource,
  organisationId,
}: {
  resource: AnalysisConfiguration;
  organisationId: string;
}) => {
  const { update: updateAnalysisConfig } = useOrgAnalysisCrud();

  return (
    <Suspense fallback={<TablePlaceholder />}>
      <AnalysisSettingCustomAdmin
        configuration={resource}
        onSave={updateAnalysisConfig}
        isLoading={false}
        nodeId={organisationId}
        readOnly={false}
      />
    </Suspense>
  );
};
