import styled from "styled-components";
import { colors } from "../../styles/colors";
import {
  spaceHuge,
  spaceMedium,
  spaceSmall,
  spacing6,
  spacing9,
} from "../../styles/space";
import { typography } from "../../styles/typography";

export const TitleBarDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  justify-content: space-between;
  background: ${colors.brand};
  height: var(--top-bar-menu-height);
  max-height: 8rem;
  padding: ${spacing6} ${spacing9};
  box-sizing: border-box;
`;

export const VindLogoWrapper = styled.div`
  svg {
    path {
      fill: white;
    }
  }
`;

export const TeamsListWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 2.4rem 0 2rem 0;
  min-width: 25rem;
  max-width: 50rem;

  border-right: 1px solid ${colors.inputOutline};
`;

export const TeamListItemPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  ${typography.contentAndButtons};
  padding: ${spaceMedium} ${spaceSmall} ${spaceMedium} ${spaceHuge};
`;

export const VerticalDivider = styled.div`
  display: flex;
  border-right: 1px solid ${colors.inputOutline};
  height: 1.6rem;
`;

export const WrapperFullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 5%;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: ${colors.grey50};
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 50rem;
  }
`;

export const EditableTextH3Wrapper = styled.h3`
  * {
    ${typography.h3};
  }
`;
