import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import "nouislider/dist/nouislider.css";
import { houseDistanceFilterAtomFamily } from "../../state/filter";
import { Slider } from "../General/Slider";
import { useAtom } from "jotai";
import { maxResidentialFilterDistance } from "@constants/onshoreFilter";

const ResidentialDistanceSlider = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [distance, setDistance] = useAtom(
    houseDistanceFilterAtomFamily({
      projectId,
    }),
  );
  return (
    <label>
      <Slider
        label
        min={100}
        step={5}
        max={maxResidentialFilterDistance}
        value={distance as number}
        onChange={(dist) => setDistance(dist)}
      />
    </label>
  );
};

export default ResidentialDistanceSlider;
