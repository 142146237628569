import { Input, InputDimensioned } from "../../General/Input";
import { useEffect, useMemo, useState, useCallback } from "react";
import Button from "../../General/Button";
import { Column, Row } from "../../General/Layout";
import { ColumnContent } from "../TurbineSettings";
import {
  ContentWrapper,
  HorizontalLine,
  ModalContainer,
  Subtitle,
  SecondaryText,
  SettingButton,
  TitleContainer,
} from "../../SettingsV2/Shared/styles";
import { Label } from "../../General/Form";
import { unsavedSettingsState } from "../../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../../state/toast";
import useEnterToSave from "../useEnterToSave";
import {
  VesselType,
  VesselTypeIds,
  VesselTypeNames,
} from "services/vesselService";
import { spaceLarge } from "styles/space";
import styled from "styled-components";
import {
  idToVesselChangelogId,
  vesselChangelogInfo,
} from "components/InputChangelog/const";
import ComponentLastChanged from "../SettingsUsage/ComponentLastChanged";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { useSetAtom } from "jotai";
import { HeaderContainer, HeaderWrapper } from "../shared";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { VesselTypeSpecificSettings } from "./VesselTypeSpecificSettings";
import { colors } from "styles/colors";
import { Tag } from "components/General/Tag";
import { VesselUsageCurrentProject } from "./VesselUsageCurrentProject";

const SettingsColumn = styled(Column)`
  ${Row} {
    & > label {
      flex: 1;
    }
  }
  gap: ${spaceLarge};
`;

export const kmPerHrToKnots = 0.539957;

const EXPORT_VESSEL_TYPE_NAMES: Record<VesselTypeIds, string> = {
  [VesselTypeIds.Installation]: VesselTypeNames.installation,
  [VesselTypeIds.FeederBarge]: VesselTypeNames.feederBarge,
  [VesselTypeIds.AnchorHandling]: VesselTypeNames.anchorHandling,
  [VesselTypeIds.CableLay]: VesselTypeNames.cableLay,
  [VesselTypeIds.ScourProtection]: VesselTypeNames.scourProtection,
};

export function VesselSettings({
  vessel,
  onSave,
  onDuplicate,
  isLoading,
  disabled,
  nodeId,
  hasEditAccess,
  isDefault,
}: {
  vessel: VesselType;
  onSave: (vessel: VesselType) => void;
  onDuplicate: (vesselId: string, name: string) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
  hasEditAccess: boolean;
  isDefault: boolean;
}) {
  const [_vessel, setVessel] = useState(vessel);
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);

  useEffect(() => {
    setVessel(vessel);
  }, [vessel]);

  const hasChanged = useMemo(() => {
    return vessel !== _vessel;
  }, [_vessel, vessel]);

  const onEnterSaveCallback = useCallback(
    () => onSave(_vessel),
    [onSave, _vessel],
  );
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);
  return (
    <ModalContainer>
      <HeaderWrapper>
        <HeaderContainer>
          <Column
            style={{
              width: "100%",
            }}
          >
            <h3
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "60rem",
              }}
            >
              {_vessel.name}
            </h3>
            <Row
              style={{
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <ComponentLastChanged
                changelogId={idToVesselChangelogId(_vessel.id)}
                nodeId={nodeId}
                category={"project"}
              />
              <Row>
                <Row>
                  {_vessel.type && (
                    <Tag
                      background={colors.grey100}
                      text={`Type: ${EXPORT_VESSEL_TYPE_NAMES[_vessel.type]}`}
                    />
                  )}
                </Row>
                <Row>
                  <VesselUsageCurrentProject vesselId={_vessel.id} />
                </Row>
              </Row>
            </Row>
          </Column>
        </HeaderContainer>
        <SettingButton
          style={{
            justifyContent: "flex-end",
            position: "absolute",
            right: "2.4rem",
            top: "14.4rem",
          }}
        >
          {hasChanged && (
            <>
              <Button
                disabled={isLoading || !hasChanged}
                text="Cancel"
                buttonType="text"
                onClick={() => {
                  setVessel(vessel);
                }}
                style={{ marginLeft: "auto" }}
              />
              <Button
                disabled={isLoading || !hasChanged}
                text="Save changes"
                onClick={() => {
                  setToastMessagesAtom((tm) => [
                    ...tm,
                    { text: "Saving...", timeout: 1000 },
                  ]);
                  onSave(_vessel);
                }}
              />
            </>
          )}
          {isDefault && hasEditAccess && (
            <Row style={{ marginLeft: "auto" }}>
              <Button
                text="Duplicate to edit"
                icon={<DuplicateIcon />}
                onClick={() => onDuplicate(vessel.id, vessel.name ?? "")}
              />
            </Row>
          )}
        </SettingButton>
      </HeaderWrapper>

      <ContentWrapper
        style={{
          maxHeight: "calc(100% - 7.3rem)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <SettingsColumn>
            <TitleContainer>
              <Subtitle>General info</Subtitle>
              <HorizontalLine />
            </TitleContainer>
            <div
              style={{
                display: "grid",
                justifyContent: "start",
                gridTemplateColumns: "auto auto auto",
                gap: "2.4rem",
              }}
            >
              <Label>
                <SecondaryText>Name</SecondaryText>
                <ChangelogAndCommentWrapper
                  changelogInfo={vesselChangelogInfo(
                    vessel.id,
                    nodeId,
                    "name",
                    "project",
                  )}
                  disabled={disabled}
                >
                  <Input
                    style={{ width: "42.4rem" }}
                    disabled={disabled}
                    value={_vessel.name}
                    onChange={(e) => {
                      setVessel({
                        ..._vessel,
                        name: e.target.value,
                      });
                    }}
                  />
                </ChangelogAndCommentWrapper>
              </Label>

              <Label>
                <SecondaryText>Mobilization time</SecondaryText>
                <InputDimensioned
                  style={{ width: "20rem" }}
                  decimals={1}
                  disabled={disabled}
                  value={_vessel.mobilizationTime}
                  unit={"days"}
                  step={0.1}
                  validate={(e) => e >= 0 && e <= 30}
                  validationMessage={`Must be between 0 and 30`}
                  changelogInfo={vesselChangelogInfo(
                    vessel.id,
                    nodeId,
                    "mobilizationTime",
                    "project",
                  )}
                  onChange={(n) => {
                    setVessel({
                      ..._vessel,
                      mobilizationTime: n,
                    });
                  }}
                />
              </Label>
              <Label>
                <SecondaryText>Transit speed</SecondaryText>
                <InputDimensioned
                  style={{ width: "20rem" }}
                  decimals={1}
                  disabled={disabled}
                  value={_vessel.transitSpeed}
                  unit={"knots"}
                  scaleFactor={kmPerHrToKnots}
                  step={0.1}
                  validate={(e) => e >= 1.0 && e <= 20}
                  validationMessage={`Must be between 1 and 20`}
                  changelogInfo={vesselChangelogInfo(
                    vessel.id,
                    nodeId,
                    "transitSpeed",
                    "project",
                  )}
                  onChange={(n) => {
                    setVessel({
                      ..._vessel,
                      transitSpeed: n,
                    });
                  }}
                />
              </Label>
            </div>
            <TitleContainer>
              <Subtitle>Vessel specific settings</Subtitle>
              <HorizontalLine />
            </TitleContainer>
            <VesselTypeSpecificSettings
              vessel={_vessel}
              setVessel={setVessel}
              disabled={disabled}
              nodeId={nodeId}
            />
          </SettingsColumn>
        </ColumnContent>
      </ContentWrapper>
    </ModalContainer>
  );
}
