import { useCallback, useEffect, useState } from "react";
import { Group, MathUtils } from "three";
import { ThreeCoreViewPark } from "./useCreateThreeCore";
import FPSControls from "./firstPersonControls";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import {
  viewFromShoreFirstPersonCameraAngleAtom,
  viewFromShoreFreeCameraAtom,
} from "state/viewToPark";
import { useAtomValue, useSetAtom } from "jotai";
import { deg2rad } from "utils/geometry";

const FPS = 60;

export default function useAnimation(
  threeCore: ThreeCoreViewPark | undefined,
  windTurbineLightsGlobal: Group | undefined,
  turbineMeshes: Group[],
) {
  const freeCamera = useAtomValue(viewFromShoreFreeCameraAtom);
  const setFirstPersonCameraAngle = useSetAtom(
    viewFromShoreFirstPersonCameraAngleAtom,
  );

  useEffect(() => {
    if (!threeCore || !freeCamera) return;
    const { renderer, camera } = threeCore;
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.zoomSpeed = 1 / 10;
    controls.rotateSpeed = 1 / 2;
    return () => {
      controls.dispose();
    };
  }, [freeCamera, threeCore]);

  const [firstPersonControls, setFirstPersonControls] = useState<
    FPSControls | undefined
  >();
  useEffect(() => {
    if (!threeCore || freeCamera) return;
    const { scene, camera } = threeCore;
    const controls = new FPSControls(camera, (angle: number) => {
      setFirstPersonCameraAngle(angle);
    });
    scene.add(controls.getObject());
    setFirstPersonControls(controls);

    return () => {
      setFirstPersonControls(undefined);
      controls.dispose();
      scene.remove(controls.getObject());
      setFirstPersonCameraAngle(undefined);
    };
  }, [freeCamera, threeCore, setFirstPersonCameraAngle]);

  const render = useCallback(() => {
    if (!threeCore) return;
    const { water, renderer, scene, camera } = threeCore;

    const time = performance.now();
    const delta = time / 1000;

    // Update controls
    if (firstPersonControls) {
      firstPersonControls.update(delta);
    }

    water.material.uniforms["time"].value = time / 1000 / 4;

    const seconds = Math.round(time / 1000);
    if (windTurbineLightsGlobal) {
      windTurbineLightsGlobal.visible = seconds % 2 === 0;
    }

    const timestampDeci = Math.round(time / 60);
    const angle = MathUtils.degToRad(timestampDeci % 360);
    for (const g of turbineMeshes) {
      const bg = g.getObjectByName("bladegroup");
      if (bg) bg.rotation.x = angle + deg2rad(0);
    }

    renderer.render(scene, camera);
  }, [threeCore, turbineMeshes, windTurbineLightsGlobal, firstPersonControls]);

  useEffect(() => {
    const it = setInterval(() => {
      requestAnimationFrame(render);
    }, 1000 / FPS);
    return () => {
      clearInterval(it);
    };
  }, [render]);
}
