import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { TurbineType } from "../../../types/turbines";
import { Input, InputDimensioned, TextArea } from "../../General/Input";
import { adminAccessProjectSelector } from "../../../state/user";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";

import { Column, Row } from "../../General/Layout";
import { Label as _Label } from "../../General/Form";

import { formatCurveToText } from "../../SettingsV2/FeatureSettings/Data/utils";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ColumnSection,
  ContentWrapper,
  HorizontalLine,
  ModalContainer,
  SecondaryText,
  SettingButton,
  Subtitle,
  TitleContainer,
} from "../../SettingsV2/Shared/styles";
import Button from "../../General/Button";
import React, { useState, useEffect } from "react";
import { spaceLarge } from "../../../styles/space";
import Checkbox from "../../General/Checkbox";
import { IAVoltageType } from "../../../services/cableTypeService";
import {
  Detail,
  DetailSecondary,
  HeaderContainer,
  HeaderWrapper,
} from "../shared";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { calculateTurbineDefaultCosts } from "utils/turbineCost";
import { CostUnit } from "types/financial";
import { isOnshoreAtom } from "state/onshore";
import DescriptionModal from "../DescriptionModal";
import ProjectResourceUsage from "../SettingsUsage/ProjectResourceUsage";

// Lazy load to prevent plotly being in main.js
const TurbineGraph = React.lazy(() => import("../TurbineGraph"));

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: 100%;
  width: 100%;
`;

const Label = styled(_Label)`
  flex: 1;
`;

const DefaultTurbineSettingsView = ({
  turbine,
  duplicate,
}: {
  turbine: TurbineType;
  duplicate?: (turbineId: string) => void;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  if (!projectId) return null;
  return (
    <DefaultTurbineSettingsViewInner
      turbine={turbine}
      duplicate={duplicate}
      projectId={projectId}
    />
  );
};

const DefaultTurbineSettingsViewInner = ErrorBoundaryWrapper(
  ({
    turbine,
    duplicate,
    projectId,
  }: {
    turbine: TurbineType;
    duplicate?: (turbineId: string) => void;
    projectId: string;
  }) => {
    const onshore = useAtomValue(isOnshoreAtom);
    const adminAccessProject = useAtomValue(adminAccessProjectSelector);
    const [values, setValues] = useState<string>("");
    const [showFreeText, setShowFreeText] = useState(!!turbine?.note);
    const [description, setDescription] = useState(turbine?.description ?? "");

    useEffect(() => {
      if (turbine) {
        setValues(
          formatCurveToText(
            turbine.windSpeed,
            turbine.power,
            turbine.thrustCoefficient,
          ),
        );
      }
    }, [setValues, turbine]);

    useEffect(() => {
      setDescription(turbine?.description ?? "");
      setShowFreeText(!!turbine?.note);
    }, [turbine]);

    if (!projectId) return null;
    return (
      <ModalContainer>
        <HeaderWrapper>
          <HeaderContainer>
            <Column
              style={{
                width: "100%",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "60rem",
                }}
              >
                {`${turbine.name}`}
              </h3>

              <Row
                style={{
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <ProjectResourceUsage
                  resourceType="TURBINE"
                  resourceId={turbine.id}
                />
              </Row>
            </Column>

            <DescriptionModal
              disabled={true}
              defaultValue={description}
              updateDescription={() => {}}
              subtitle={
                <div>
                  <p>
                    The description will be visible for Admins and Editors in
                    projects with access to this turbine.
                  </p>
                </div>
              }
            />
          </HeaderContainer>

          <SettingButton
            style={{
              justifyContent: "flex-end",
              position: "absolute",
              right: "2.4rem",
              top: "14.4rem",
            }}
          >
            {adminAccessProject && duplicate && (
              <Row
                style={{
                  marginLeft: "auto",
                }}
              >
                <Button
                  text="Duplicate to edit"
                  icon={<DuplicateIcon />}
                  onClick={() => duplicate(turbine.id)}
                />
              </Row>
            )}
          </SettingButton>
        </HeaderWrapper>

        <ContentWrapper
          style={{
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <ColumnContent>
            <ColumnSection
              style={{
                padding: 0,
              }}
            >
              <TitleContainer>
                <Subtitle>General info</Subtitle>
                <HorizontalLine />
              </TitleContainer>

              <div
                style={{
                  display: "grid",
                  justifyContent: "start",
                  gridTemplateColumns: "auto auto",
                  gap: "2.4rem",
                }}
              >
                <Label>
                  <SecondaryText>Name</SecondaryText>
                  <Input
                    style={{
                      width: "42.4rem",
                    }}
                    type="string"
                    disabled={true}
                    value={`${turbine.name}`}
                  />
                </Label>
                <Label>
                  <SecondaryText>Cost</SecondaryText>
                  <InputDimensioned
                    disabled={true}
                    value={calculateTurbineDefaultCosts({
                      diameter: turbine.diameter,
                      ratedPower: turbine.ratedPower!,
                      hubHeight: turbine.hubHeight,
                    })}
                    unit={CostUnit.millionEuroPerUnit}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>
              </div>
              <div
                style={{
                  paddingTop: "0.8rem",
                  display: "grid",
                  justifyContent: "start",
                  gridTemplateColumns: "auto auto auto auto ",
                  gap: "2.4rem",
                }}
              >
                <Label>
                  <p>Diameter</p>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={turbine.diameter}
                    unit={"m"}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>

                <Label>
                  <SecondaryText>Hub height</SecondaryText>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    style={{
                      width: "20rem",
                    }}
                    value={turbine?.hubHeight}
                    unit={"m"}
                  />
                </Label>

                <Label>
                  <SecondaryText>RNA Mass</SecondaryText>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={Math.round(turbine.rnaMass / 1000)}
                    unit={"t"}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>

                <Label>
                  <SecondaryText>Reference air density</SecondaryText>
                  <InputDimensioned
                    type="number"
                    disabled={true}
                    value={turbine.referenceAirDensity}
                    unit={"kg/m3"}
                    step={0.001}
                    style={{
                      width: "20rem",
                    }}
                  />
                </Label>
              </div>
            </ColumnSection>
            <ColumnSection style={{ paddingTop: "0.8rem" }}>
              <Label>
                <SecondaryText>Voltage</SecondaryText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: spaceLarge,
                  }}
                >
                  {onshore ? (
                    <Checkbox
                      value={IAVoltageType.kV33}
                      label={String(IAVoltageType.kV33).concat("kV")}
                      labelPlacement="after"
                      checked={turbine.voltage.includes(IAVoltageType.kV33)}
                      disabled={true}
                    />
                  ) : (
                    <>
                      <Checkbox
                        value={IAVoltageType.kV66}
                        label={String(IAVoltageType.kV66).concat("kV")}
                        labelPlacement="after"
                        checked={turbine.voltage.includes(IAVoltageType.kV66)}
                        disabled={true}
                      />
                      <Checkbox
                        value={IAVoltageType.kV132}
                        label={String(IAVoltageType.kV132).concat("kV")}
                        labelPlacement="after"
                        checked={turbine.voltage.includes(IAVoltageType.kV132)}
                        disabled={true}
                      />
                    </>
                  )}
                </div>
              </Label>
            </ColumnSection>
            <ColumnSection>
              <Row>
                <TitleContainer>
                  <Subtitle>
                    Losses{" "}
                    <HelpTooltip
                      style={{
                        display: "inline-flex",
                      }}
                      text="Turbine-specific losses"
                      size={10}
                    />
                  </Subtitle>
                  <HorizontalLine />
                </TitleContainer>
              </Row>
              <Row>
                <SimpleAlert
                  text={`No turbine-specific losses added`}
                  type={"info"}
                />
              </Row>
            </ColumnSection>
            <ColumnSection>
              <TitleContainer>
                <Subtitle>Power curves</Subtitle>
                <HorizontalLine />
              </TitleContainer>
              <Label>
                <Row
                  style={{
                    gap: "1.8rem",
                    paddingRight: "3rem",
                  }}
                >
                  <Column>
                    <Row
                      style={{
                        flex: 0,
                        justifyContent: "space-between",
                        marginRight: "1rem",
                      }}
                    >
                      <Column
                        style={{
                          gap: 0,
                        }}
                      >
                        <DetailSecondary>(m/s)</DetailSecondary>
                        <Detail>Speed</Detail>
                      </Column>
                      <Column
                        style={{
                          gap: 0,
                        }}
                      >
                        <DetailSecondary>(kW)</DetailSecondary>
                        <Detail>Power</Detail>
                      </Column>
                      <Column
                        style={{
                          gap: 0,
                        }}
                      >
                        <DetailSecondary>(-)</DetailSecondary>
                        <Detail>Thrust</Detail>
                      </Column>
                    </Row>
                    <TextArea
                      cols={25}
                      rows={15}
                      style={{
                        resize: "vertical",
                      }}
                      disabled={true}
                      placeholder={"Speed Power Thrust\n[m/s] [kW] [-]"}
                      value={values}
                    />
                  </Column>
                  {turbine && (
                    <React.Suspense fallback={null}>
                      <TurbineGraph turbine={turbine} />
                    </React.Suspense>
                  )}
                </Row>
              </Label>
              <Label>
                <Row>
                  <SecondaryText>Rated power</SecondaryText>
                  <HelpTooltip
                    style={{
                      display: "inline-flex",
                    }}
                    text="Maximum power output of the turbine, taken from the maximum value in the power curve"
                    size={10}
                  />
                </Row>
                <InputDimensioned
                  type="number"
                  disabled={true}
                  value={turbine.ratedPower}
                  unit={"MW"}
                  scaleFactor={1 / 1000}
                  decimals={1}
                  style={{
                    width: "20rem",
                  }}
                />
              </Label>
            </ColumnSection>
            <ColumnSection>
              <Row alignCenter>
                {showFreeText && (
                  <Column style={{ width: "100%" }}>
                    <TitleContainer>
                      <Subtitle>Free text</Subtitle>
                      <HorizontalLine />
                    </TitleContainer>
                    <TextArea
                      style={{
                        resize: "vertical",
                        width: "40rem",
                      }}
                      rows={3}
                      disabled={true}
                      value={turbine.note ?? ""}
                    />
                  </Column>
                )}
              </Row>
            </ColumnSection>
          </ColumnContent>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default DefaultTurbineSettingsView;
