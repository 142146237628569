import { z } from "zod";
import { fetchEnhancer } from "services/utils";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { atom } from "jotai";

const _CRSDefinition = z.object({
  code: z.number(),
  name: z.string(),
  proj4string: z.string(),
});
export type CRSDefinition = z.infer<typeof _CRSDefinition>;

export const getEPSGJsonSelectorFamily = atomFamily(
  (code: number | undefined) =>
    atom(async () => {
      if (!code) return;
      const resName = await fetchEnhancer(`https://epsg.io/${code}.json`);
      const resJsonName = (await resName.json()) as { name?: string };
      const name = resJsonName.name;
      if (!resName.ok || !name)
        throw new Error("Unable to fetch name for EPSG");

      const resProj4String = await fetchEnhancer(
        `https://epsg.io/${code}.proj4`,
      );
      if (!resProj4String.ok)
        throw new Error("Unable to fetch proj4string for EPSG");
      const proj4string = await resProj4String.text();

      return { name, proj4string };
    }),
);

export const availableCRSAtom = atomLocalStorage<CRSDefinition[]>(
  "vind:CRS:customv2",
  [
    {
      code: 4326,
      name: "WGS84",
      proj4string: "+proj=longlat +datum=WGS84 +no_defs +type=crs",
    },
    {
      code: 3006,
      name: "SWEREF99 TM",
      proj4string:
        "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 32633,
      name: "UTM zone 33N",
      proj4string:
        "+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32632,
      name: "UTM zone 32N",
      proj4string:
        "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32631,
      name: "UTM zone 31N",
      proj4string:
        "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32630,
      name: "UTM zone 30N",
      proj4string:
        "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 32629,
      name: "UTM zone 29N",
      proj4string:
        "+proj=utm +zone=29 +datum=WGS84 +units=m +no_defs +type=crs",
    },
    {
      code: 5111,
      name: "NTM zone 11",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 5110,
      name: "NTM zone 10",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
    {
      code: 5109,
      name: "NTM zone 10",
      proj4string:
        "+proj=tmerc +lat_0=58 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    },
  ],
  _CRSDefinition.array(),
);

const selectedCRSLocalStorage = atomLocalStorage<CRSDefinition | undefined>(
  `vind:CRS:selected`,
  undefined,
  _CRSDefinition,
);

export const selectedCRSAtom = atom(
  (get) => {
    const stored = get(selectedCRSLocalStorage);
    if (stored) return stored;
    return get(availableCRSAtom)[0];
  },
  (_, set, update: CRSDefinition) => set(selectedCRSLocalStorage, update),
);
