import { useEffect } from "react";
import { useGoToFeatures } from "../../../hooks/map";
import { mapAtom } from "../../../state/map";
import useNavigateToPark from "../../../hooks/useNavigateToPark";
import useSelectionInMap from "../../../hooks/useSelectionInMap";
import { useAtomValue } from "jotai";
import { parkFamily, parksFamily } from "state/jotai/park";

export const InnerZoomToExistingPark = ({
  parkId,
  setHasZoomed,
}: {
  parkId: string;
  setHasZoomed: (hasZoomed: boolean) => void;
}) => {
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);

  const park = useAtomValue(parkFamily({ parkId, branchId: undefined }));

  useEffect(() => {
    if (!map) return;
    if (!park) return;
    setHasZoomed(true);
    setTimeout(() => goToFeatures([park]), 1000); // avoid race condition on initialization
  }, [setHasZoomed, goToFeatures, map, park]);
  return null;
};

export const InnerZoomToParks = ({
  setHasZoomed,
}: {
  setHasZoomed: (hasZoomed: boolean) => void;
}) => {
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);
  const parks = useAtomValue(parksFamily({ branchId: undefined }));
  const { navigateToPark } = useNavigateToPark();
  const { setCurrentSelectionArray } = useSelectionInMap();

  useEffect(() => {
    if (!map || parks.length === 0) return;
    const ref = setTimeout(() => {
      setHasZoomed(true);
      if (parks.length === 1) {
        navigateToPark(parks[0].id);
        setCurrentSelectionArray([parks[0].id]);
      }
      goToFeatures(parks);
    }, 1000);
    return () => clearTimeout(ref);
  }, [
    goToFeatures,
    map,
    navigateToPark,
    parks,
    setCurrentSelectionArray,
    setHasZoomed,
  ]);
  return null;
};
