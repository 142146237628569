import React, {
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { getShareUrl } from "../../hooks/useOpenModal";
import { ScrollBody } from "../../hooks/useShowScrollShadow";
import { useToast } from "../../hooks/useToast";
import AddIcon from "@icons/24/Add.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import CostIcon from "@icons/24/Cost.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import FlashIcon from "@icons/24/Flash.svg?react";
import GraphIcon from "@icons/24/Graph.svg?react";
import HyperlinkIcon from "@icons/24/Hyperlink.svg?react";
import SettingsIcon from "@icons/24/Settings.svg?react";
import TurbineIcon from "@icons/24/Turbine.svg?react";
import FoundationIcon from "@icons/24/Foundation.svg?react";
import MetoceanIcon from "@icons/24/Wind.svg?react";
import MooringIcon from "@icons/24/Anchor.svg?react";
import VesselIcon from "@icons/24/Vessel.svg?react";
import { AnalysisConfiguration } from "../../services/configurationService";
import { WindSourceConfiguration } from "../../services/windSourceConfigurationService";
import { DashboardModalType, modalTypeOpenAtom } from "../../state/modal";
import {
  parkIdAtom,
  branchIdAtom,
  projectIdAtom,
  organisationIdAtom,
  projectIdAtomDef,
} from "../../state/pathParams";
import { editorAccessProjectSelector } from "../../state/user";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceHuge, spaceLarge, spaceTiny, spacing8 } from "../../styles/space";
import { BranchMeta } from "../../types/api";
import { ParkFeature } from "../../types/feature";
import Button from "../General/Button";
import DnDGrid from "../General/DnDGrid/DnDGrid";
import { EditableTextInternalState } from "../General/EditableText";
import { Column, Row } from "../General/Layout";
import Tooltip from "../General/Tooltip";
import { EditableTextH3Wrapper } from "../Projects/styles";
import SettingsMenuItem from "../SettingsV2/Shared/SettingsMenuItem";
import {
  SettingsSubMenuCustomMenuList,
  SettingsSubMenuCustomMenuListContext,
} from "../SettingsV2/Shared/SettingsSubMenu";
import { selectedMenuItemState } from "../SettingsV2/Shared/state";
import { ContentContainer } from "../SettingsV2/Shared/styles";
import { ConfigurationSelector } from "./ConfigurationSelector";
import {
  CenterGrayMessage,
  DropdownList,
  VDivider,
  TextIcon,
  CollapseWithButton,
  HeaderWrapper,
  WidgetNewPlaceholder,
  DashboardColumn,
} from "./Dashboard.style";
import { ParkSelector } from "./ParkSelector";
import { WindSourceConfigurationSelector } from "./WindSourceConfigurationSelector";
import { useDashboard } from "./hooks";
import { VIND_DASHBOARDS, dashboardsAtom } from "./state";
import { Dashboard, WidgetId, widgetIdRequireFeatureFlag } from "./types";
import { z } from "zod";
import { CostConfiguration } from "../../services/costService";
import { CostConfigurationSelector } from "./CostConfigurationSelector";
import { useAllFeatureFlags } from "components/General/FeatureFlag";
import {
  TriggerDashboardFinanceAndProduction,
  currentParkTriggerId,
} from "components/Finance/Triggers";
import {
  ElectricalStatError,
  FoundationStatError,
  ParkHasAnyValidationError,
  ProductionError,
} from "components/ValidationWarnings/FeatureSpecificErrors";
import { FinanceId } from "finance/types";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { Mixpanel } from "mixpanel";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { parkFamily } from "state/jotai/park";
import { branchMetaFamily } from "state/jotai/branch";
import {
  analysisConfigurationSelectedFamily,
  analysisConfigurationsFamily,
} from "state/jotai/analysisConfiguration";
import {
  windConfigurationSelectedFamily,
  windConfigurationsFamily,
} from "state/jotai/windConfiguration";
import {
  costConfigurationSelectedFamily,
  costConfigurationsFamily,
} from "state/jotai/costConfiguration";
import { useBathymetry } from "hooks/bathymetry";
import { isDefined } from "utils/predicates";
import { atomFamily } from "utils/jotai";
import { designToolTypeAtom } from "state/map";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import {
  operationsConfigurationSelectedFamily,
  operationsConfigurationsFamily,
} from "state/jotai/operationsConfiguration";
import { OperationsConfigurationSelector } from "./OperationsConfigurationSelector";
import { isOnshoreAtom } from "state/onshore";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import SelectWidgets, {
  isSelectWidgetsOpen,
} from "components/CompareParksModal/ConfigurationBar/SelectWidgets";

/** The preset dashboards have designated icons */
const _PresetDashboardIcon: Record<
  (typeof VIND_DASHBOARDS)[number]["id"],
  ReactNode
> = {
  "vind-preset-dashboard-overview": <GraphIcon />,
  "vind-preset-dashboard-yield": <TurbineIcon />,
  "vind-preset-dashboard-electrical": <FlashIcon />,
  "vind-preset-dashboard-cost": <CostIcon />,
  "vind-preset-dashboard-mooring": <MooringIcon />,
  "vind-preset-dashboard-foundation": <FoundationIcon />,
  "vind-preset-dashboard-metocean": <MetoceanIcon />,
  "vind-preset-dashboard-installation": <VesselIcon />,
};

const DEFAULT_SELECTION_SP = "dashboardIds";
const _DefaultSelection = z.object({
  /** Branch */
  b: z.string().optional(),
  /** Park */
  p: z.string().optional(),
  /** Configuration */
  c: z.string().optional(),
  /** Wind configuration */
  w: z.string().optional(),
  /** Cost configuration */
  f: z.string().optional(),
  /** Operations configuration */
  o: z.string().optional(),
});

type DefaultSelection = {
  branchId?: string;
  parkId?: string;
  configurationId?: string;
  windConfigurationId?: string;
  costConfigurationId?: string;
  operationsConfigurationId?: string;
};

type DashboardContextType = {
  projectId: string;
  organisationId: string;
  branch: BranchMeta;
  park: ParkFeature;
  configuration: AnalysisConfiguration;
  windConfiguration: WindSourceConfiguration;
  costConfiguration?: CostConfiguration;
  operationsConfiguration?: OperationsConfiguration;
  dashboard: Dashboard;
  canEdit?: boolean;

  addWidget: (ids: WidgetId[]) => void;
  removeWidget: (ids: WidgetId[]) => void;

  /** Hide dot menus and help texts. */
  noMenus?: boolean;

  /**
   * ID for finance and production triggers. This ensures that all widgets show
   * data from the same source.
   */
  triggerId: FinanceId;

  /**
   * Bathymetry ID for the current park. Widgets can use this to get bathymetry data.
   */
  parkBathymetryId: string;

  /*
   * An array of IDs that is used in the error boundary to know when to reset the error in the boundary.
   * */
  errorBoundaryResetKeys: string[];
};

const DashboardContext = React.createContext<DashboardContextType | undefined>(
  undefined,
);

const H4Collapsible = ({
  text,
  collapsed,
}: {
  text: string;
  collapsed?: boolean;
}) => {
  if (collapsed)
    return (
      <h4
        style={{
          marginLeft: spaceLarge,
        }}
      >
        —
      </h4>
    );
  return (
    <h4
      style={{
        marginLeft: spaceLarge,
        whiteSpace: "nowrap",
      }}
    >
      {text}
    </h4>
  );
};

const DashboardComponentFallback = () => (
  <Column
    style={{
      flex: 1,
      alignItems: "center",
      padding: spaceHuge,
    }}
  >
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
    <SkeletonBlock
      style={{
        height: "1rem",
        width: "100%",
      }}
    />
  </Column>
);

const DashboardStatError = ({
  parkId,
  branchId,
  dashboardId,
}: {
  parkId: string | undefined;
  branchId: string | undefined;
  dashboardId: string;
}) => {
  if (!parkId || !dashboardId || !branchId) return null;
  switch (dashboardId) {
    case "vind-preset-dashboard-electrical":
      return <ElectricalStatError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-overview" || "vind-preset-dashboard-yield":
      return <ProductionError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-cost":
      return <ParkHasAnyValidationError parkId={parkId} branchId={branchId} />;
    case "vind-preset-dashboard-foundation" || "vind-preset-dashboard-mooring":
      return <FoundationStatError parkId={parkId} branchId={branchId} />;
    default:
      return null;
  }
};

export const useDashboardContext = (): DashboardContextType => {
  const ctx = useContext(DashboardContext);
  if (ctx === undefined) throw new Promise(() => {});
  return ctx;
};

/** BathymetryId for the current park. */
const currentBathymetryId = atomFamily<
  {
    parkId?: string;
    branchId?: string;
  },
  string
>(() => atom(""));

const FetchBathymetry = ({
  parkId,
  branchId,
}: {
  parkId: string;
  branchId?: string;
}) => {
  const [res] = useBathymetry({
    branchId,
    featureId: parkId,
    projectId: undefined,
    bufferKm: 1,
  });
  const id = res.state === "hasData" ? res.data.id : undefined;
  const setId = useSetAtom(
    currentBathymetryId({
      parkId,
      branchId,
    }),
  );
  useEffect(() => {
    if (id) setId(id);
  }, [id, setId]);

  return null;
};

const DashboardComponent = ({
  dashboard,
  canEdit,
  defaultSelection,
}: {
  dashboard: Dashboard;
  canEdit?: boolean;
  defaultSelection?: DefaultSelection;
}) => {
  const projectId = useAtomValue(projectIdAtomDef);
  const organisationId = useAtomValue(organisationIdAtom);
  const isOnshore = useAtomValue(isOnshoreAtom);

  const mapBranchId = useAtomValue(branchIdAtom);
  const mapParkId = useAtomValue(parkIdAtom);
  const setIsWidgetsOpen = useSetAtom(isSelectWidgetsOpen);

  const parkId =
    defaultSelection?.parkId ?? dashboard.parkId ?? mapParkId ?? "";
  const branchId =
    defaultSelection?.branchId ?? dashboard.branchId ?? mapBranchId;

  const mapPark = useAtomValue(
    parkFamily({
      parkId,
      branchId,
    }),
  );
  const [park, setPark] = useState<undefined | ParkFeature>(mapPark);

  const branch = useAtomValue(
    branchMetaFamily({
      branchId: branchId ?? "",
      projectId,
    }),
  );

  const enabledFeatureFlags = useAllFeatureFlags();

  const dashboardRows = useMemo(
    () =>
      dashboard.rows
        .map((row) => ({
          ...row,
          elements: row.elements.filter((element) => {
            const widgetId = element.id as WidgetId;
            const requireFeatureFlag = widgetIdRequireFeatureFlag[widgetId];
            return (
              !requireFeatureFlag ||
              enabledFeatureFlags.includes(requireFeatureFlag)
            );
          }),
        }))
        .filter((row) => 0 < row.elements.length),
    [dashboard.rows, enabledFeatureFlags],
  );

  const {
    setRows,
    removeWidget,
    addWidget,
    update: updateDashboard,
  } = useDashboard(dashboard.id);
  const configurations = useAtomValue(
    analysisConfigurationsFamily({
      projectId,
    }),
  );

  const branchConfiguration = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const [configuration, setConfiguration] = useState<
    undefined | AnalysisConfiguration
  >(() => {
    const target =
      defaultSelection?.configurationId ?? dashboard.configurationId;
    const ret = configurations.get(target ?? "") ?? branchConfiguration;
    return ret;
  });

  const windConfigurations = useAtomValue(
    windConfigurationsFamily({
      projectId,
    }),
  );
  const branchWindConfiguration = useAtomValue(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const branchCostConfig = useAtomValue(
    costConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const operationsConfigurations = useAtomValue(
    operationsConfigurationsFamily({
      projectId,
    }),
  );
  const branchOperationsConfiguration = useAtomValue(
    operationsConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const [windConfiguration, setWindConfiguration] = useState<
    undefined | WindSourceConfiguration
  >(() => {
    const target =
      defaultSelection?.windConfigurationId ?? dashboard.windConfigurationId;
    return windConfigurations.get(target ?? "") ?? branchWindConfiguration;
  });

  const costConfigurations = useAtomValue(
    costConfigurationsFamily({
      projectId,
    }),
  );

  const [costConfiguration, setCostConfiguration] = useState<
    undefined | CostConfiguration
  >(() => {
    const target =
      defaultSelection?.costConfigurationId ?? dashboard.costConfigurationId;
    return costConfigurations.get(target ?? "") ?? branchCostConfig;
  });

  const [operationsConfiguration, setOperationsConfiguration] = useState<
    undefined | OperationsConfiguration
  >(() => {
    const target =
      defaultSelection?.operationsConfigurationId ??
      dashboard.operationsConfigurationId;
    const firstOperationConfig = operationsConfigurations.values().next().value;
    return (
      operationsConfigurations.get(target ?? "") ??
      branchOperationsConfiguration ??
      firstOperationConfig
    );
  });

  const { info } = useToast();
  const [dropdownHidden, setDropdownHidden] = useState(false);

  const selectedCustomDashboardPark = useAtomValue(
    parkFamily({
      parkId,
      branchId,
    }),
  );

  // Use the park from the dashboard if it is a custom one and have a park already selected
  const selectedDashboardPark = !dashboard.preset
    ? selectedCustomDashboardPark || park
    : park;
  const selectedDashboardConfiguration = !dashboard.preset
    ? configurations.get(dashboard.configurationId ?? "") || configuration
    : configuration;
  const selectedDashboardWindConfiguration = !dashboard.preset
    ? windConfigurations.get(dashboard.windConfigurationId ?? "") ||
      windConfiguration
    : windConfiguration;
  const selectedDashboardCostConfiguration = !dashboard.preset
    ? costConfigurations.get(dashboard.costConfigurationId ?? "") ||
      costConfiguration
    : costConfiguration;
  const selectedDashboardOperationsConfiguration = !dashboard.preset
    ? operationsConfigurations.get(dashboard.operationsConfigurationId ?? "") ||
      operationsConfiguration
    : operationsConfiguration;

  const parkBathymetryId = useAtomValue(
    currentBathymetryId({
      parkId: selectedDashboardPark?.id,
      branchId,
    }),
  );
  if (!projectId || !organisationId) return null;

  return (
    <Row
      style={{
        flex: 1,
        alignItems: "stretch",
        overflowY: "auto",
      }}
    >
      {park && <FetchBathymetry parkId={park.id} branchId={branchId} />}
      <DashboardColumn>
        <HeaderWrapper>
          {canEdit ? (
            <EditableTextH3Wrapper>
              <EditableTextInternalState
                value={dashboard.name}
                onEnter={(newName) => {
                  updateDashboard({
                    id: dashboard.id,
                    name: newName,
                  });
                }}
              />
            </EditableTextH3Wrapper>
          ) : (
            <h3>
              {dashboard.name}
              <DashboardStatError
                parkId={park?.id}
                branchId={branch?.id}
                dashboardId={dashboard.id}
              />
            </h3>
          )}
        </HeaderWrapper>
        <DropdownList>
          <CollapseWithButton hidden={dropdownHidden}>
            <Button
              icon={<SettingsIcon />}
              text={dropdownHidden ? "Show settings" : "Hide settings"}
              buttonType={dropdownHidden ? "secondary" : "text"}
              onClick={() => setDropdownHidden((c) => !c)}
              style={{
                margin: spaceTiny,
              }}
            />
            <ParkSelector
              branchId={branchId}
              park={selectedDashboardPark}
              setPark={(selectedPark) => {
                setPark(selectedPark);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    parkId: selectedPark.id,
                  });
                }
              }}
            />
            <ConfigurationSelector
              configuration={selectedDashboardConfiguration}
              setConfiguration={(selectedConfiguration) => {
                setConfiguration(selectedConfiguration);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    configurationId: selectedConfiguration.id,
                  });
                }
              }}
            />
            <WindSourceConfigurationSelector
              windConfiguration={selectedDashboardWindConfiguration}
              setWindConfiguration={(selectedWindConfig) => {
                setWindConfiguration(selectedWindConfig);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    windConfigurationId: selectedWindConfig.id,
                  });
                }
              }}
            />
            <CostConfigurationSelector
              configuration={selectedDashboardCostConfiguration}
              setConfiguration={(selectedCostConfig) => {
                setCostConfiguration(selectedCostConfig);
                if (!dashboard.preset) {
                  updateDashboard({
                    id: dashboard.id,
                    costConfigurationId: selectedCostConfig.id,
                  });
                }
              }}
            />
            {!isOnshore && (
              <OperationsConfigurationSelector
                configuration={selectedDashboardOperationsConfiguration}
                setConfiguration={(selectedOperationsConfig) => {
                  setOperationsConfiguration(selectedOperationsConfig);
                  if (!dashboard.preset) {
                    updateDashboard({
                      id: dashboard.id,
                      operationsConfigurationId: selectedOperationsConfig.id,
                    });
                  }
                }}
              />
            )}
          </CollapseWithButton>
          {!dashboard.preset && (
            <>
              <VDivider />
              <SelectWidgets dashboard={dashboard} canEdit={!!canEdit} />
            </>
          )}
          <VDivider />
          <Tooltip text="Copy link to dashboard">
            <Button
              icon={<HyperlinkIcon />}
              buttonType="secondary"
              onClick={() => {
                const ids = _DefaultSelection.parse({
                  b: branch?.id,
                  p: selectedDashboardPark?.id,
                  c: selectedDashboardConfiguration?.id,
                  w: selectedDashboardWindConfiguration?.id,
                  f: selectedDashboardCostConfiguration?.id,
                  o: selectedDashboardOperationsConfiguration?.id,
                });

                navigator.clipboard.writeText(
                  getShareUrl(
                    {
                      modalType: DashboardModalType,
                    },
                    {
                      id: dashboard.id,
                      [DEFAULT_SELECTION_SP]: JSON.stringify(ids),
                    },
                  ),
                );
                info("Copied");
              }}
            />
          </Tooltip>
        </DropdownList>
        <ScrollBody>
          {!branch ? (
            <CenterGrayMessage>
              <p>Choose a branch from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardPark ? (
            <CenterGrayMessage>
              <p>Choose a park from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardWindConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a wind configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardCostConfiguration ? (
            <CenterGrayMessage>
              <p>Choose a cost configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : !selectedDashboardOperationsConfiguration && !isOnshore ? (
            <CenterGrayMessage>
              <p>Choose an operations configuration from the dropdown.</p>
            </CenterGrayMessage>
          ) : null}

          {branch &&
            selectedDashboardPark &&
            selectedDashboardConfiguration &&
            selectedDashboardWindConfiguration &&
            selectedDashboardCostConfiguration &&
            (selectedDashboardOperationsConfiguration || isOnshore) && (
              <DashboardContext.Provider
                value={{
                  projectId,
                  organisationId,
                  branch,
                  park: selectedDashboardPark,
                  addWidget,
                  removeWidget,
                  configuration: selectedDashboardConfiguration,
                  windConfiguration: selectedDashboardWindConfiguration,
                  costConfiguration: selectedDashboardCostConfiguration,
                  operationsConfiguration:
                    selectedDashboardOperationsConfiguration,
                  dashboard,
                  canEdit,
                  triggerId: currentParkTriggerId,
                  parkBathymetryId,
                  errorBoundaryResetKeys: [
                    selectedDashboardPark.id,
                    selectedDashboardConfiguration.id,
                    selectedDashboardWindConfiguration.id,
                    selectedDashboardCostConfiguration.id,
                    selectedDashboardOperationsConfiguration?.id,
                  ].filter(isDefined),
                }}
              >
                <TriggerDashboardFinanceAndProduction
                  costConfigurationId={selectedDashboardCostConfiguration.id}
                  analysisConfiguration={selectedDashboardConfiguration}
                  parkId={selectedDashboardPark.id}
                  branchId={branch.id}
                  projectId={projectId}
                  windConfiguration={selectedDashboardWindConfiguration}
                  operationsConfigurationId={
                    selectedDashboardOperationsConfiguration?.id
                  }
                />
                {0 < dashboardRows.length ? (
                  <DnDGrid
                    rows={dashboardRows}
                    setRows={setRows}
                    disabled={!canEdit}
                  />
                ) : (
                  <WidgetNewPlaceholder
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsWidgetsOpen(true);
                    }}
                  >
                    <AddIcon />
                    <p>Add widget</p>
                  </WidgetNewPlaceholder>
                )}
              </DashboardContext.Provider>
            )}
        </ScrollBody>
      </DashboardColumn>
    </Row>
  );
};

const SettingMenuItemSkeleton = () => (
  <SkeletonBlock
    style={{
      height: "2rem",
      width: "initial",
      margin: "0.8rem 0.5rem",
    }}
  />
);

const DashboardList = ({
  dashboards,
  canEdit,
}: {
  dashboards: Dashboard[];
  canEdit?: boolean;
}) => {
  const designToolType = useAtomValue(designToolTypeAtom);
  const { selectedItemId, setSelectedItemId, expanded } = useContext(
    SettingsSubMenuCustomMenuListContext,
  );
  const { showConfirm } = useConfirm();
  const { create, remove, duplicate } = useDashboard("");

  useEffect(() => {
    const selected =
      dashboards.find((d) => d.id === selectedItemId) ??
      VIND_DASHBOARDS.find((d) => d.id === selectedItemId);
    if (selected === undefined) {
      setSelectedItemId(dashboards[0]?.id ?? VIND_DASHBOARDS[0]?.id);
    }
  }, [dashboards, selectedItemId, setSelectedItemId]);

  const [loadingNewDashboard, setLoadingNewDashboard] = useState(false);

  return (
    <>
      <H4Collapsible collapsed={!expanded} text="Vind dashboards" />
      {VIND_DASHBOARDS.filter(
        (d) => !d.applicableMode || d.applicableMode.includes(designToolType),
      ).map((d, index) => (
        <Tooltip
          text={d.name}
          key={d.id}
          innerDivStyle={{
            width: "100%",
          }}
          disabled={d.id === selectedItemId}
        >
          <SettingsMenuItem
            item={{
              id: d.id,
              label: d.name,
              icon: _PresetDashboardIcon[d.id],
              dotMenuOptions: canEdit
                ? [
                    {
                      title: "Duplicate",
                      onSelect: () => {
                        setLoadingNewDashboard(true);
                        duplicate(d.id)
                          .then((db) => {
                            setSelectedItemId(db.id);
                          })
                          .finally(() => {
                            setLoadingNewDashboard(false);
                          });
                      },
                      icon: <DuplicateIcon />,
                    },
                  ]
                : undefined,
            }}
            isFirstLevel
            onClick={() => {
              Mixpanel.track_old("Open default dashboard", {
                id: d.id,
              });
              setSelectedItemId(d.id);
            }}
            expanded={expanded}
            selected={selectedItemId === d.id}
            index={index + 1}
          />
        </Tooltip>
      ))}
      <div
        style={{
          height: "1rem",
        }}
      />
      <H4Collapsible text="Your boards" collapsed={!expanded} />
      {dashboards.map((d, index) => (
        <Tooltip
          text={d.name}
          key={d.id}
          innerDivStyle={{
            width: "100%",
          }}
          disabled={d.id === selectedItemId}
        >
          <SettingsMenuItem
            item={{
              id: d.id,
              label: d.name,
              icon: expanded ? undefined : (
                <TextIcon selected={selectedItemId === d.id}>
                  {d.name[0]}
                </TextIcon>
              ),
              dotMenuOptions: canEdit
                ? [
                    {
                      title: "Duplicate",
                      onSelect: () => duplicate(d.id),
                      icon: <DuplicateIcon />,
                    },
                    {
                      title: "Delete",
                      onSelect: () => {
                        showConfirm({
                          title: "Delete dashboard",
                          message: `This will permanently delete ${d.name}.`,
                          confirmButtonText: "Delete",
                        }).then((confirmed) => {
                          if (confirmed) {
                            remove(d.id);
                          }
                        });
                      },
                      icon: <DeleteIcon />,
                    },
                  ]
                : undefined,
            }}
            isFirstLevel
            onClick={() => {
              Mixpanel.track_old("Open custom dashboard", {
                widgetIds: d.rows.flatMap((r) => r.elements).map((e) => e.id),
              });
              setSelectedItemId(d.id);
            }}
            expanded={expanded}
            selected={selectedItemId === d.id}
            index={index + 1}
          />
        </Tooltip>
      ))}
      {loadingNewDashboard && <SettingMenuItemSkeleton />}
      {canEdit && (
        <Button
          text={expanded ? "New dashboard" : ""}
          onClick={async (e) => {
            e.stopPropagation();
            setLoadingNewDashboard(true);
            await create({
              name: "New dashboard",
            })
              .then((dashboard) => {
                setSelectedItemId(dashboard.id);
              })
              .finally(() => {
                setLoadingNewDashboard(false);
              });
          }}
          buttonType="text"
          size="medium"
          icon={<AddIcon />}
          style={{
            overflowX: "hidden",
            marginTop: "0.8rem",
            marginLeft: "0.4rem",
          }}
        />
      )}
    </>
  );
};

const DashboardInnerFallback = () => {
  return (
    <Column
      style={{
        flex: 1,
        padding: "5rem",
        gap: "3rem",
      }}
    >
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "100%",
        }}
      />
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "100%",
        }}
      />
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "100%",
        }}
      />
    </Column>
  );
};

const DashboardInner = ({
  defaultSelection,
}: {
  defaultSelection?: DefaultSelection;
}) => {
  const projectId = useAtomValue(projectIdAtom);
  const dashboards = useAtomValue(
    dashboardsAtom({
      nodeId: projectId ?? "",
    }),
  );

  const canEdit = useAtomValue(editorAccessProjectSelector);

  const items = useMemo(
    () =>
      dashboards.concat(VIND_DASHBOARDS).map((d) => ({
        id: d.id,
        label: d.name,
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: () => {
              alert(`delete ${d.name}`);
            },
            icon: <DeleteIcon />,
          },
        ],
        content: (
          <Suspense fallback={<DashboardComponentFallback />}>
            <DashboardComponent
              dashboard={d}
              canEdit={canEdit && !d.preset}
              defaultSelection={defaultSelection}
            />
          </Suspense>
        ),
      })),
    [canEdit, dashboards, defaultSelection],
  );

  return (
    <SettingsSubMenuCustomMenuList
      parentId={DashboardModalType}
      items={items}
      depth={0}
      wide
    >
      <DashboardList dashboards={dashboards} canEdit={canEdit} />
    </SettingsSubMenuCustomMenuList>
  );
};

const DashboardHeader = ({ projectId }: { projectId: string }) => {
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const branch = useAtomValue(
    branchMetaFamily({
      projectId,
      branchId,
    }),
  );
  return (
    <Row
      style={{
        alignItems: "center",
        gap: spacing8,
      }}
    >
      <h3>Dashboard</h3>
      <div
        style={{
          width: "1px",
          height: "2rem",
          backgroundColor: colors.blue200,
        }}
      />
      <p
        style={{
          ...typography.caption,
          color: colors.textNegative,
        }}
      >
        {branch?.title}
      </p>
    </Row>
  );
};

export const DashboardModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const setSelectedDashboard = useSetAtom(
    selectedMenuItemState({
      menuId: DashboardModalType,
      projectId,
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === DashboardModalType) {
      const id = modalTypeOpen.metadata?.id;
      if (id) setSelectedDashboard(id);
    }
  }, [modalTypeOpen, setSelectedDashboard]);

  const [defaultSelection, setDefaultSelection] = useState<
    Partial<DefaultSelection> | undefined
  >(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const parkIdsInSearchParams = searchParams.get(DEFAULT_SELECTION_SP);
    if (parkIdsInSearchParams) {
      try {
        const { b, p, c, w, f, o } = _DefaultSelection.parse(
          JSON.parse(parkIdsInSearchParams),
        );
        setDefaultSelection({
          branchId: b,
          parkId: p,
          configurationId: c,
          windConfigurationId: w,
          costConfigurationId: f,
          operationsConfigurationId: o,
        });
        searchParams.delete(DEFAULT_SELECTION_SP);
        setSearchParams(searchParams);
        return;
      } catch (err) {}
    }
    // We only want to run this effect on first render, and not when the search params change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (modalTypeOpen?.modalType !== DashboardModalType) {
    return null;
  }

  return (
    <TopBarModal>
      <TopBarModalHeader
        title={
          <React.Suspense fallback={null}>
            <DashboardHeader projectId={projectId} />
          </React.Suspense>
        }
        onClose={() => {
          setModalTypeOpen(undefined);
        }}
      />
      <ContentContainer
        style={{
          padding: 0,
        }}
      >
        <Suspense fallback={<DashboardInnerFallback />}>
          <DashboardInner defaultSelection={defaultSelection} />
        </Suspense>
      </ContentContainer>
    </TopBarModal>
  );
};
