import { useDrag } from "react-dnd";
import {
  ContentTableRow,
  DragIconWrapper,
  RowContainer,
  RowWithIcon,
} from "../../style";
import Spinner from "@icons/spinner/Spinner";
import SelectDrag from "@icons/24/SelectDrag.svg?react";
import styled from "styled-components";
import { DraggableItemWrapper } from "../DraggableItemWrapper";
import { colors } from "styles/colors";
import { useAtomValue } from "jotai";
import { ComponentLastChangedSimple } from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import { organisationIdAtom } from "state/pathParams";
import { useLibraryDragAndDrop } from "../hooks/useLibraryDragAndDrop";
import { getAcceptableTypes, getChangelogId } from "../utils";
import { FolderTreeItem } from "types/folderStructures";
import ResourceName from "./ResourceName";
import ResourceActions from "./ResourceActions";
import { ResourceCrudOperations, ResourceMap } from "../types";
import { UsedInProjects } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { RenderOnFirstVisibility } from "components/General/RenderOnFirstVisibility";

const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface ResourceItemProps<T extends keyof ResourceMap> {
  resource: ResourceMap[T];
  depth: number;
  collapsed?: boolean;
  isSelected?: boolean;
  onClickResource: (id: string) => void;
  isLoading?: boolean;
  deleteInProgress?: string;
  setDeleteInProgress?: (id?: string) => void;
  parentFolderId?: string;
  openDescriptionId?: string;
  setOpenDescriptionId: (id: string | undefined) => void;
  libraryManageRole: T;
  remove: ResourceCrudOperations<T>["remove"];
  duplicate: ResourceCrudOperations<T>["duplicate"];
  folders: FolderTreeItem[];
  onDropOnFolder?: (folderId: string, resourceId: string) => void;
  searchTerm: string;
  getDescription: (resource: ResourceMap[T]) => string | undefined | null;
  updateDescription: (
    resource: ResourceMap[T],
    newDescription: string,
  ) => Promise<ResourceMap[T]>;
  nameExtraInfo?: string;
  extraColumn?: (resource: ResourceMap[T]) => React.ReactNode;
}

const ResourceItem = <T extends keyof ResourceMap>({
  resource,
  depth,
  collapsed = false,
  isSelected = false,
  onClickResource,
  isLoading = false,
  deleteInProgress,
  setDeleteInProgress,
  parentFolderId,
  openDescriptionId,
  setOpenDescriptionId,
  libraryManageRole,
  remove,
  duplicate,
  folders,
  onDropOnFolder,
  searchTerm,
  getDescription,
  updateDescription,
  nameExtraInfo,
  extraColumn,
}: ResourceItemProps<T>) => {
  const acceptableTypes = getAcceptableTypes(libraryManageRole);

  const {
    onDropOutsideFolder,
    onFolderDropOnResourceOutsideFolder,
    onFolderDropOnResource,
  } = useLibraryDragAndDrop(acceptableTypes, folders, libraryManageRole);

  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: acceptableTypes[0],
      item: resource,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [resource, acceptableTypes],
  );

  const handleDrop = (item: any) => {
    if ("type" in item && item.type === "FOLDER") {
      if (parentFolderId) {
        onFolderDropOnResource?.(parentFolderId, item.id);
      } else {
        onFolderDropOnResourceOutsideFolder?.(item);
      }
      return;
    }

    if (parentFolderId && onDropOnFolder) {
      onDropOnFolder(parentFolderId, item.id);
    } else if (onDropOutsideFolder) {
      onDropOutsideFolder(item.id);
    }
  };

  const selectedStyle = isSelected
    ? {
        backgroundColor: colors.surfaceSelectedLight,
      }
    : {};

  return (
    <ItemWrapper>
      <DraggableItemWrapper acceptTypes={acceptableTypes} onDrop={handleDrop}>
        <RowWithIcon ref={dragRef}>
          <DragIconWrapper>
            <SelectDrag />
          </DragIconWrapper>
          <RowContainer
            onClick={() => onClickResource(resource.id)}
            isDragging={isDragging}
            isSelected={isSelected}
          >
            <ResourceName
              collapsed={collapsed}
              depth={depth}
              resource={resource}
              searchTerm={searchTerm}
              nameExtraInfo={nameExtraInfo}
            />

            {!collapsed && (
              <>
                {extraColumn && extraColumn(resource)}

                <ContentTableRow style={{ flex: 1 }}>
                  <RenderOnFirstVisibility>
                    <ComponentLastChangedSimple
                      changelogId={getChangelogId(
                        libraryManageRole,
                        resource.id,
                      )}
                      category={libraryManageRole}
                    />
                  </RenderOnFirstVisibility>
                </ContentTableRow>
                <ContentTableRow style={{ flex: 1 }}>
                  <RenderOnFirstVisibility>
                    <UsedInProjects
                      organisationId={organisationId}
                      libraryManageRole={libraryManageRole}
                      resourceId={resource.id}
                    />
                  </RenderOnFirstVisibility>
                </ContentTableRow>
              </>
            )}
            {!(deleteInProgress === resource.id) ? (
              <ContentTableRow style={{ flex: 1 }}>
                <ResourceActions
                  resource={resource}
                  libraryManageRole={libraryManageRole}
                  setOpenDescriptionId={setOpenDescriptionId}
                  remove={remove}
                  duplicate={duplicate}
                  isLoading={isLoading}
                  openDescriptionId={openDescriptionId}
                  setDeleteInProgress={setDeleteInProgress}
                  getDescription={getDescription}
                  updateDescription={updateDescription}
                />
              </ContentTableRow>
            ) : (
              <ContentTableRow
                flexGrow={false}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                  ...selectedStyle,
                }}
              >
                <Spinner size="1rem" />
              </ContentTableRow>
            )}
          </RowContainer>
        </RowWithIcon>
      </DraggableItemWrapper>
    </ItemWrapper>
  );
};

export default ResourceItem;
