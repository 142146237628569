import { useAtom } from "jotai";
import NewItemModal from "components/NewItemModal/NewItemModal";

import { useEffect } from "react";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { ResourceType, resourceTypeToName } from "./types";

export const DuplicateComponentModalType = "DuplicateComponentModal" as const;

const DuplicateComponentOrConfigModalInner = ({
  componentType,
  defaultName,
  onClose,
  onDuplicate,
  description,
}: {
  componentType: ResourceType;
  defaultName: string;
  onClose(): void;
  onDuplicate(name: string): void;
  description?: string;
}) => {
  return (
    <NewItemModal
      title={`Duplicate ${resourceTypeToName[componentType]}`}
      description={description}
      placeholder={`Enter ${resourceTypeToName[componentType]} name`}
      defaultValue={`${defaultName}`}
      onSubmit={async (name) => {
        await onDuplicate(name);
      }}
      onClose={onClose}
    />
  );
};

const DuplicateComponentOrConfigModal = () => {
  const [midScreenModalTypeOpen, setMidScreenModalTypeOpen] = useAtom(
    midScreenModalTypeOpenAtom,
  );

  // Unset value when unmounting = going to projects for instance
  useEffect(() => {
    return () => {
      setMidScreenModalTypeOpen(undefined);
    };
  }, [setMidScreenModalTypeOpen]);

  if (
    !midScreenModalTypeOpen ||
    midScreenModalTypeOpen.modalType !== DuplicateComponentModalType
  ) {
    return null;
  }

  return (
    <DuplicateComponentOrConfigModalInner
      componentType={midScreenModalTypeOpen.metadata.componentType}
      defaultName={midScreenModalTypeOpen.metadata.defaultName}
      description={midScreenModalTypeOpen.metadata.description}
      onClose={() => {
        setMidScreenModalTypeOpen(undefined);
      }}
      onDuplicate={midScreenModalTypeOpen.metadata.onDuplicate}
    />
  );
};

export default DuplicateComponentOrConfigModal;
