import { projectIdAtom } from "state/pathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Mixpanel } from "mixpanel";
import WindIcon from "@icons/14/Wind.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";
import {
  createWindSourceConfiguration,
  deleteWindSourceConfiguration,
  updateWindSourceConfiguration,
  WindSourceConfiguration,
} from "../../../../services/windSourceConfigurationService";
import { toastMessagesAtom } from "../../../../state/toast";
import {
  savingWindConfigurationInProgressAtom,
  windConfigurationTempName,
} from "../../../../state/windSourceConfiguration";
import WindSettings from "../../../ConfigurationModal/WindSettings";
import { selectedMenuItemState } from "../../Shared/state";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../../../state/modal";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { useToast } from "hooks/useToast";
import useWindConfigurationCrud from "hooks/useWindConfigurationCrud";
import {
  projectWindConfigurationsByNameFamily,
  projectWindConfigurationsFamily,
} from "state/jotai/windConfiguration";
import { firstValueInMap } from "utils/utils";
import { unwrap } from "jotai/utils";
import { designToolTypeAtom } from "state/map";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { IconREMSize } from "styles/typography";
import Button from "components/General/Button";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import AddIcon from "@icons/24/Add.svg?react";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { useJotaiCallback } from "utils/jotai";
import { fetchProjectResourceUsage } from "services/usageService";

const WIND_MENU_ID = "wind";

export default function useWindConfiguration() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const projectType = useAtomValue(designToolTypeAtom);
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  const { error: showErrorToast } = useToast();
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const [isSaving, setIsSaving] = useAtom(
    savingWindConfigurationInProgressAtom,
  );
  const configurations = useAtomValue(
    unwrap(projectWindConfigurationsFamily(projectId)),
  );
  const setConfigurations = useSetAtom(
    projectWindConfigurationsFamily(projectId),
  );
  const configurationsList = useAtomValue(
    unwrap(projectWindConfigurationsByNameFamily(projectId)),
  );
  const [isProjectWindCollapsed, setIsProjectWindCollapsed] =
    useState<boolean>(false);

  const setConfigurationsJotai = useSetAtom(
    projectWindConfigurationsFamily(projectId),
  );
  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: WIND_MENU_ID,
      projectId,
    }),
  );
  const { showConfirm } = useConfirm();

  const tempConfigNames = useAtomValue(
    windConfigurationTempName({
      nodeId: projectId,
    }),
  );

  useEffect(() => {
    if (configurations && !menuSelection) {
      const firstItem = firstValueInMap(configurations);
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [configurations, menuSelection, setMenuSelection]);

  useEffect(() => {
    if (
      (modalTypeOpen as any)?.["metadata"]?.["selectedMenuId"] === "wind" &&
      (modalTypeOpen as any)?.["metadata"]?.["selectedConfigId"]
    ) {
      setMenuSelection((modalTypeOpen as any)["metadata"]["selectedConfigId"]);
    }
  }, [modalTypeOpen, setMenuSelection]);

  const _delete = useJotaiCallback(
    async (get, set, configurationId: string) => {
      if (!projectId) return;
      setIsSaving(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "WIND_CONFIGURATION",
          resourceId: configurationId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "WIND_CONFIGURATION",
          configurationId,
        );
      }
      if (usage.length > 0) {
        showErrorToast(
          `Configuration is used in ${usage.length} branch(es) and can't be deleted`,
          {
            timeout: 6000,
          },
        );
      } else {
        setConfigurations(async (curr) => {
          const m = new Map(await curr);
          m.delete(configurationId);
          return m;
        });
        setConfigurationsJotai(async (curr) => {
          const newMap = new Map(await curr);
          newMap.delete(configurationId);
          return newMap;
        });
        await deleteWindSourceConfiguration({
          nodeId: projectId,
          configurationId,
        });
      }
      setIsSaving(false);
    },
    [
      projectId,
      setConfigurations,
      setConfigurationsJotai,
      setIsSaving,
      showErrorToast,
    ],
  );

  const _create = useCallback(async () => {
    if (!projectId) return;
    setIsSaving(true);
    const newConfig = await createWindSourceConfiguration({
      nodeId: projectId,
      projectType,
    });
    setConfigurations(async (cur) => {
      const m = new Map(await cur);
      m.set(newConfig.id, newConfig);
      return m;
    });
    setConfigurationsJotai(async (curr) => {
      const newMap = new Map(await curr);
      newMap.set(newConfig.id, newConfig);
      return newMap;
    });
    setMenuSelection(newConfig.id);
    setIsSaving(false);
  }, [
    projectId,
    setConfigurations,
    setConfigurationsJotai,
    setIsSaving,
    setMenuSelection,
    projectType,
  ]);

  const _duplicate = useCallback(
    async (id: string, name: string) => {
      if (!projectId || !configurations) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "wind",
          defaultName: `${name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const configToDuplicate = configurations.get(id);
            if (!configToDuplicate) {
              return;
            }
            setIsSaving(true);

            const configClone = JSON.parse(
              JSON.stringify(configToDuplicate),
            ) as WindSourceConfiguration;

            try {
              const newConfig = await createWindSourceConfiguration({
                nodeId: projectId,
                projectType,
              });

              const updatedConfig = {
                ...newConfig,
                ...configClone,
                id: newConfig.id,
                name: name,
              };
              await updateWindSourceConfiguration({
                nodeId: projectId,
                configuration: updatedConfig,
              });
              setConfigurations(async (cur) => {
                const m = new Map(await cur);
                m.set(updatedConfig.id, updatedConfig);
                return m;
              });
              setConfigurationsJotai(async (curr) => {
                const newMap = new Map(await curr);
                newMap.set(updatedConfig.id, updatedConfig);
                return newMap;
              });
              setMenuSelection(updatedConfig.id);
            } catch {
            } finally {
              setIsSaving(false);
            }
          },
        },
      });
    },
    [
      configurations,
      projectId,
      setConfigurations,
      setConfigurationsJotai,
      setIsSaving,
      setMenuSelection,
      projectType,
      setMidScreenModalTypeOpen,
    ],
  );

  const _update = useJotaiCallback(
    async (get, set, windConfiguration: WindSourceConfiguration) => {
      if (!projectId || !configurations) return;
      setIsSaving(true);
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "WIND_CONFIGURATION",
          resourceId: windConfiguration.id,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          projectId,
          "WIND_CONFIGURATION",
          windConfiguration.id,
        );
      }

      const currentConfig = configurations.get(windConfiguration.id);

      const { name: _, ...configurationWithoutName } = windConfiguration;
      const { name: __, ...currentConfigWithoutName } = currentConfig || {};

      const changedSomethingElseThanName =
        JSON.stringify(configurationWithoutName) !==
        JSON.stringify(currentConfigWithoutName);

      const { note: ___, ...configurationWithoutNote } = windConfiguration;
      const { note: ____, ...currentConfigWithoutNote } = currentConfig || {};

      const changedSomethingElseThanNote =
        JSON.stringify(configurationWithoutNote) !==
        JSON.stringify(currentConfigWithoutNote);

      if (
        (!changedSomethingElseThanName && !changedSomethingElseThanNote) ||
        usage.length === 0 ||
        (await showConfirm({
          title: "Update configuration",
          message: `This configuration is used in ${usage.length} branch${usage.length > 1 ? "es" : ""}, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        const newConfig = await updateWindSourceConfiguration({
          nodeId: projectId,
          configuration: windConfiguration,
        });

        setConfigurations(async (curr) => {
          const m = new Map(await curr);
          m.set(newConfig.id, newConfig);
          return m;
        });
        setConfigurationsJotai(async (curr) => {
          const newMap = new Map(await curr);
          newMap.set(newConfig.id, newConfig);
          return newMap;
        });
        Mixpanel.track_old("Config saved", {
          type: "wind",
        });
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Saved",
            timeout: 3000,
            type: "success",
          },
        ]);
      }
      setIsSaving(false);
    },
    [
      showConfirm,
      projectId,
      setIsSaving,
      configurations,
      setConfigurations,
      setConfigurationsJotai,
      setToastMessages,
    ],
  );

  const { saveName, saveTempNameToLocal } = useWindConfigurationCrud();

  const onUnmount = useCallback(() => {
    if (!configurations) return;
    configurations.forEach((c) => {
      if (saveTempNameToLocal) saveTempNameToLocal(c.id);
    });
  }, [configurations, saveTempNameToLocal]);

  const configSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    const loadedConfigurationsList = configurationsList ?? [];
    const configList = loadedConfigurationsList.map((config) => ({
      id: config.id,
      label: tempConfigNames[config.id] ?? config.name ?? "",
      loading: isSaving,
      content: (
        <div
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <WindSettings
            key={config.id}
            windConfiguration={config}
            windConfigurationName={
              tempConfigNames[config.id] ?? config.name ?? ""
            }
            onSave={_update}
            onSaveName={saveName}
            onUnmount={onUnmount}
          />
        </div>
      ),
      onDuplicate: undefined,
      onChangeName: async (newName: string) => {
        return _update({
          ...config,
          name: newName,
        });
      },
      dotMenuOptions: [
        ...(loadedConfigurationsList.length > 1
          ? [
              {
                title: "Delete",
                onSelect: _delete,
                icon: <DeleteIcon />,
              },
            ]
          : []),
        {
          title: "Duplicate",
          onSelect: () => _duplicate(config.id, config.name ?? ""),
          icon: <DuplicateIcon />,
        },
      ],
    }));
    return [
      {
        title: "Project specific configs",
        items: configList,
        isCollapsed: isProjectWindCollapsed,
        loading: configurationsList == null,
        icon: (
          <IconREMSize height={0.6} width={0.6}>
            <ExpandArrowWrapper
              open={!isProjectWindCollapsed}
              onClick={() => {
                setIsProjectWindCollapsed(!isProjectWindCollapsed);
              }}
            >
              <ChevronDownIcon />
            </ExpandArrowWrapper>
          </IconREMSize>
        ),
      },
    ];
  }, [
    _delete,
    _duplicate,
    _update,
    isProjectWindCollapsed,
    configurationsList,
    isSaving,
    onUnmount,
    tempConfigNames,
    saveName,
  ]);

  const createNewWindConfiguration = useMemo(() => {
    return {
      type: "element",
      saveInProgress: isSaving,
      element: (
        <Button
          disabled={isSaving}
          onClick={(e) => {
            e.stopPropagation();
            _create();
          }}
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [isSaving, _create]);

  const windConfigurations = useMemo(() => {
    return {
      id: WIND_MENU_ID,
      label: "Wind",
      icon: <WindIcon />,
      submenus: configSubMenus,
      createNew: createNewWindConfiguration,
    };
  }, [configSubMenus, createNewWindConfiguration]);

  return windConfigurations;
}
