// From https://github.com/axismaps/colorbrewer
// License: Apache

import { Color } from "lib/colors";

// prettier-ignore
export const colorBrewer = {
  BrBG: {
    3: [ new Color(216, 179, 101), new Color(245, 245, 245), new Color(90, 180, 172)
    ],
    4: [ new Color(166, 97, 26), new Color(223, 194, 125), new Color(128, 205, 193),
      new Color(1, 133, 113) ],
    5: [ new Color(166, 97, 26), new Color(223, 194, 125), new Color(245, 245, 245),
      new Color(128, 205, 193), new Color(1, 133, 113) ],
    6: [ new Color(140, 81, 10), new Color(216, 179, 101), new Color(246, 232, 195),
      new Color(199, 234, 229), new Color(90, 180, 172), new Color(1, 102, 94) ],
    7: [ new Color(140, 81, 10), new Color(216, 179, 101), new Color(246, 232, 195),
      new Color(245, 245, 245), new Color(199, 234, 229), new Color(90, 180, 172), new Color(1, 102, 94)
    ],
    8: [ new Color(140, 81, 10), new Color(191, 129, 45), new Color(223, 194, 125),
      new Color(246, 232, 195), new Color(199, 234, 229), new Color(128, 205, 193),
      new Color(53, 151, 143), new Color(1, 102, 94) ],
    9: [ new Color(140, 81, 10), new Color(191, 129, 45), new Color(223, 194, 125),
      new Color(246, 232, 195), new Color(245, 245, 245), new Color(199, 234, 229),
      new Color(128, 205, 193), new Color(53, 151, 143), new Color(1, 102, 94) ],
    10: [ new Color(84, 48, 5), new Color(140, 81, 10), new Color(191, 129, 45), new Color(223, 194, 125),
      new Color(246, 232, 195), new Color(199, 234, 229), new Color(128, 205, 193),
      new Color(53, 151, 143), new Color(1, 102, 94), new Color(0, 60, 48) ],
    11: [ new Color(84, 48, 5), new Color(140, 81, 10), new Color(191, 129, 45), new Color(223, 194, 125),
      new Color(246, 232, 195), new Color(245, 245, 245), new Color(199, 234, 229),
      new Color(128, 205, 193), new Color(53, 151, 143), new Color(1, 102, 94), new Color(0, 60, 48)
    ],
    type: "div"
  },
  PiYG: {
    3: [ new Color(233, 163, 201), new Color(247, 247, 247), new Color(161, 215, 106)
    ],
    4: [ new Color(208, 28, 139), new Color(241, 182, 218), new Color(184, 225, 134),
      new Color(77, 172, 38) ],
    5: [ new Color(208, 28, 139), new Color(241, 182, 218), new Color(247, 247, 247),
      new Color(184, 225, 134), new Color(77, 172, 38) ],
    6: [ new Color(197, 27, 125), new Color(233, 163, 201), new Color(253, 224, 239),
      new Color(230, 245, 208), new Color(161, 215, 106), new Color(77, 146, 33) ],
    7: [ new Color(197, 27, 125), new Color(233, 163, 201), new Color(253, 224, 239),
      new Color(247, 247, 247), new Color(230, 245, 208), new Color(161, 215, 106),
      new Color(77, 146, 33) ],
    8: [ new Color(197, 27, 125), new Color(222, 119, 174), new Color(241, 182, 218),
      new Color(253, 224, 239), new Color(230, 245, 208), new Color(184, 225, 134),
      new Color(127, 188, 65), new Color(77, 146, 33) ],
    9: [ new Color(197, 27, 125), new Color(222, 119, 174), new Color(241, 182, 218),
      new Color(253, 224, 239), new Color(247, 247, 247), new Color(230, 245, 208),
      new Color(184, 225, 134), new Color(127, 188, 65), new Color(77, 146, 33) ],
    10: [ new Color(142, 1, 82), new Color(197, 27, 125), new Color(222, 119, 174),
      new Color(241, 182, 218), new Color(253, 224, 239), new Color(230, 245, 208),
      new Color(184, 225, 134), new Color(127, 188, 65), new Color(77, 146, 33), new Color(39, 100, 25)
    ],
    11: [ new Color(142, 1, 82), new Color(197, 27, 125), new Color(222, 119, 174),
      new Color(241, 182, 218), new Color(253, 224, 239), new Color(247, 247, 247),
      new Color(230, 245, 208), new Color(184, 225, 134), new Color(127, 188, 65), new Color(77, 146, 33),
      new Color(39, 100, 25) ],
    type: "div"
  },
  RdYlBu: {
    3: [ new Color(252, 141, 89), new Color(255, 255, 191), new Color(145, 191, 219)
    ],
    4: [ new Color(215, 25, 28), new Color(253, 174, 97), new Color(171, 217, 233),
      new Color(44, 123, 182) ],
    5: [ new Color(215, 25, 28), new Color(253, 174, 97), new Color(255, 255, 191),
      new Color(171, 217, 233), new Color(44, 123, 182) ],
    6: [ new Color(215, 48, 39), new Color(252, 141, 89), new Color(254, 224, 144),
      new Color(224, 243, 248), new Color(145, 191, 219), new Color(69, 117, 180) ],
    7: [ new Color(215, 48, 39), new Color(252, 141, 89), new Color(254, 224, 144),
      new Color(255, 255, 191), new Color(224, 243, 248), new Color(145, 191, 219),
      new Color(69, 117, 180) ],
    8: [ new Color(215, 48, 39), new Color(244, 109, 67), new Color(253, 174, 97), new Color(254, 224, 144),
      new Color(224, 243, 248), new Color(171, 217, 233), new Color(116, 173, 209),
      new Color(69, 117, 180) ],
    9: [ new Color(215, 48, 39), new Color(244, 109, 67), new Color(253, 174, 97), new Color(254, 224, 144),
      new Color(255, 255, 191), new Color(224, 243, 248), new Color(171, 217, 233),
      new Color(116, 173, 209), new Color(69, 117, 180) ],
    10: [ new Color(165, 0, 38), new Color(215, 48, 39), new Color(244, 109, 67), new Color(253, 174, 97),
      new Color(254, 224, 144), new Color(224, 243, 248), new Color(171, 217, 233),
      new Color(116, 173, 209), new Color(69, 117, 180), new Color(49, 54, 149) ],
    11: [ new Color(165, 0, 38), new Color(215, 48, 39), new Color(244, 109, 67), new Color(253, 174, 97),
      new Color(254, 224, 144), new Color(255, 255, 191), new Color(224, 243, 248),
      new Color(171, 217, 233), new Color(116, 173, 209), new Color(69, 117, 180), new Color(49, 54, 149)
    ],
    type: "div"
  },
  RdPu: {
    3: [ new Color(253, 224, 221), new Color(250, 159, 181), new Color(197, 27, 138)
    ],
    4: [ new Color(254, 235, 226), new Color(251, 180, 185), new Color(247, 104, 161),
      new Color(174, 1, 126) ],
    5: [ new Color(254, 235, 226), new Color(251, 180, 185), new Color(247, 104, 161),
      new Color(197, 27, 138), new Color(122, 1, 119) ],
    6: [ new Color(254, 235, 226), new Color(252, 197, 192), new Color(250, 159, 181),
      new Color(247, 104, 161), new Color(197, 27, 138), new Color(122, 1, 119) ],
    7: [ new Color(254, 235, 226), new Color(252, 197, 192), new Color(250, 159, 181),
      new Color(247, 104, 161), new Color(221, 52, 151), new Color(174, 1, 126), new Color(122, 1, 119)
    ],
    8: [ new Color(255, 247, 243), new Color(253, 224, 221), new Color(252, 197, 192),
      new Color(250, 159, 181), new Color(247, 104, 161), new Color(221, 52, 151), new Color(174, 1, 126),
      new Color(122, 1, 119) ],
    9: [ new Color(255, 247, 243), new Color(253, 224, 221), new Color(252, 197, 192),
      new Color(250, 159, 181), new Color(247, 104, 161), new Color(221, 52, 151), new Color(174, 1, 126),
      new Color(122, 1, 119), new Color(73, 0, 106) ],
    type: "seq"
  },
  BuPu: {
    3: [ new Color(224, 236, 244), new Color(158, 188, 218), new Color(136, 86, 167)
    ],
    4: [ new Color(237, 248, 251), new Color(179, 205, 227), new Color(140, 150, 198),
      new Color(136, 65, 157) ],
    5: [ new Color(237, 248, 251), new Color(179, 205, 227), new Color(140, 150, 198),
      new Color(136, 86, 167), new Color(129, 15, 124) ],
    6: [ new Color(237, 248, 251), new Color(191, 211, 230), new Color(158, 188, 218),
      new Color(140, 150, 198), new Color(136, 86, 167), new Color(129, 15, 124) ],
    7: [ new Color(237, 248, 251), new Color(191, 211, 230), new Color(158, 188, 218),
      new Color(140, 150, 198), new Color(140, 107, 177), new Color(136, 65, 157), new Color(110, 1, 107)
    ],
    8: [ new Color(247, 252, 253), new Color(224, 236, 244), new Color(191, 211, 230),
      new Color(158, 188, 218), new Color(140, 150, 198), new Color(140, 107, 177),
      new Color(136, 65, 157), new Color(110, 1, 107) ],
    9: [ new Color(247, 252, 253), new Color(224, 236, 244), new Color(191, 211, 230),
      new Color(158, 188, 218), new Color(140, 150, 198), new Color(140, 107, 177),
      new Color(136, 65, 157), new Color(129, 15, 124), new Color(77, 0, 75) ],
    type: "seq"
  },
  BuGn: {
    3: [ new Color(229, 245, 249), new Color(153, 216, 201), new Color(44, 162, 95) ],
    4: [ new Color(237, 248, 251), new Color(178, 226, 226), new Color(102, 194, 164),
      new Color(35, 139, 69) ],
    5: [ new Color(237, 248, 251), new Color(178, 226, 226), new Color(102, 194, 164),
      new Color(44, 162, 95), new Color(0, 109, 44) ],
    6: [ new Color(237, 248, 251), new Color(204, 236, 230), new Color(153, 216, 201),
      new Color(102, 194, 164), new Color(44, 162, 95), new Color(0, 109, 44) ],
    7: [ new Color(237, 248, 251), new Color(204, 236, 230), new Color(153, 216, 201),
      new Color(102, 194, 164), new Color(65, 174, 118), new Color(35, 139, 69), new Color(0, 88, 36)
    ],
    8: [ new Color(247, 252, 253), new Color(229, 245, 249), new Color(204, 236, 230),
      new Color(153, 216, 201), new Color(102, 194, 164), new Color(65, 174, 118), new Color(35, 139, 69),
      new Color(0, 88, 36) ],
    9: [ new Color(247, 252, 253), new Color(229, 245, 249), new Color(204, 236, 230),
      new Color(153, 216, 201), new Color(102, 194, 164), new Color(65, 174, 118), new Color(35, 139, 69),
      new Color(0, 109, 44), new Color(0, 68, 27) ],
    type: "seq"
  },
  GnBu: {
    3: [ new Color(224, 243, 219), new Color(168, 221, 181), new Color(67, 162, 202)
    ],
    4: [ new Color(240, 249, 232), new Color(186, 228, 188), new Color(123, 204, 196),
      new Color(43, 140, 190) ],
    5: [ new Color(240, 249, 232), new Color(186, 228, 188), new Color(123, 204, 196),
      new Color(67, 162, 202), new Color(8, 104, 172) ],
    6: [ new Color(240, 249, 232), new Color(204, 235, 197), new Color(168, 221, 181),
      new Color(123, 204, 196), new Color(67, 162, 202), new Color(8, 104, 172) ],
    7: [ new Color(240, 249, 232), new Color(204, 235, 197), new Color(168, 221, 181),
      new Color(123, 204, 196), new Color(78, 179, 211), new Color(43, 140, 190), new Color(8, 88, 158)
    ],
    8: [ new Color(247, 252, 240), new Color(224, 243, 219), new Color(204, 235, 197),
      new Color(168, 221, 181), new Color(123, 204, 196), new Color(78, 179, 211), new Color(43, 140, 190),
      new Color(8, 88, 158) ],
    9: [ new Color(247, 252, 240), new Color(224, 243, 219), new Color(204, 235, 197),
      new Color(168, 221, 181), new Color(123, 204, 196), new Color(78, 179, 211), new Color(43, 140, 190),
      new Color(8, 104, 172), new Color(8, 64, 129) ],
    type: "seq"
  },
  YlOrRd: {
    3: [ new Color(255, 237, 160), new Color(254, 178, 76), new Color(240, 59, 32) ],
    4: [ new Color(255, 255, 178), new Color(254, 204, 92), new Color(253, 141, 60),
      new Color(227, 26, 28) ],
    5: [ new Color(255, 255, 178), new Color(254, 204, 92), new Color(253, 141, 60),
      new Color(240, 59, 32), new Color(189, 0, 38) ],
    6: [ new Color(255, 255, 178), new Color(254, 217, 118), new Color(254, 178, 76),
      new Color(253, 141, 60), new Color(240, 59, 32), new Color(189, 0, 38) ],
    7: [ new Color(255, 255, 178), new Color(254, 217, 118), new Color(254, 178, 76),
      new Color(253, 141, 60), new Color(252, 78, 42), new Color(227, 26, 28), new Color(177, 0, 38)
    ],
    8: [ new Color(255, 255, 204), new Color(255, 237, 160), new Color(254, 217, 118),
      new Color(254, 178, 76), new Color(253, 141, 60), new Color(252, 78, 42), new Color(227, 26, 28),
      new Color(177, 0, 38) ],
    9: [ new Color(255, 255, 204), new Color(255, 237, 160), new Color(254, 217, 118),
      new Color(254, 178, 76), new Color(253, 141, 60), new Color(252, 78, 42), new Color(227, 26, 28),
      new Color(189, 0, 38), new Color(128, 0, 38) ],
    type: "seq"
  }
}

export const colorBrewerColors = [
  colorBrewer.RdPu,
  colorBrewer.BuPu,
  colorBrewer.BuGn,
  colorBrewer.GnBu,
  colorBrewer.YlOrRd,
  colorBrewer.PiYG,
  colorBrewer.RdYlBu,
  colorBrewer.BrBG,
];
