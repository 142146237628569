import Plotly from "plotly.js-dist-min";
import { useEffect, useMemo, useRef, useState } from "react";
import { WaveData } from "state/waveStatistics";
import { colors, hexToRgb } from "styles/colors";

export const WaveHeightHistogram = ({ data }: { data: WaveData }) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });

    if (current) observer.observe(current);

    return () => observer.disconnect();
  }, [isLoaded]);

  const maxHs = useMemo(() => {
    return data.hs.reduce((acc, h) => Math.max(acc, h), 0);
  }, [data]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const trace = {
      x: data.hs,
      type: "histogram" as const,
      histnorm: "percent" as const,
      hovertemplate: "Hs: %{x}<br />Probability: %{y:,.2f}%",
      marker: {
        color: hexToRgb(colors.primary),
        line: {
          color: hexToRgb(colors.brand),
          width: 0.1,
        },
      },
    };

    const layout = {
      font: { size: 8 },
      paper_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      xaxis: {
        title: "Significant wave height, Hs [m]",
        range: [0, Math.min(6, maxHs)],
      },
      yaxis: { title: "Probability [%]" },
      showlegend: false,
      height: 200,
      bargap: 0.2,
      margin: {
        l: 60,
        r: 60,
        b: 30,
        t: 30,
      },
      legend: {
        xanchor: "right",
        yanchor: "bottom",
      } as const,
    };

    Plotly.newPlot(graphRef.current, [trace], layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, data, maxHs]);

  return <div ref={graphRef} />;
};
