import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import mapboxgl, { CirclePaint, LngLatBoundsLike } from "mapbox-gl";
import { MapWrapper } from "components/Organisation/Portfolio/style";
import {
  meanSpeedGridLimitsAtom,
  WRG,
  WRGPoint,
  WRGSector,
} from "state/windStatistics";
import { Feature } from "geojson";
import Point from "components/MapFeatures/Point";
import { fastMax, fastMin } from "utils/utils";
import { COLORS } from "layers/windSpeed";
import { rgbToHex } from "utils/image";
import { Popup } from "components/Mapbox/Popup";
import { useToast } from "hooks/useToast";
import { weibullMean } from "functions/windStatistics";
import { UploadWindDataFileSizeTooLargeError } from "state/uploadWindData";
import { useSetAtom } from "jotai";
import { MesoWindDataSource } from "types/metocean";
import { ActiveSpeedLayer2D } from "layers/windSpeed2D";
import React from "react";

const WindRose = React.lazy(
  () => import("components/WindWaveMeasurementInfoModal/WindRose"),
);

const layerId = "wrgPointsLayer";
const sourceId = "wrgPointsSource";
const defaultProjection: mapboxgl.Projection = {
  name: "mercator",
};

export const ShowGwaLayerOuter = ({
  source,
}: {
  source: MesoWindDataSource;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/vindai/cl7khk1rl000l16lnommr0cr3",
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      dragRotate: false,
      projection: defaultProjection,
      zoom: 0,
      center: [0, 30],
    });

    map.on("load", (e) => {
      setMap(e.target);
    });

    return () => {
      setTimeout(() => map.remove(), 200);
    };
  }, []);

  if (!mapContainer) return null;
  return (
    <div
      style={{
        height: "40vh",
      }}
    >
      <MapWrapper id="grid-map" ref={mapContainer} />
      {map && (
        <ActiveSpeedLayer2D
          inputMap={map}
          inputWindLayerHeight={100}
          source={source}
        />
      )}
    </div>
  );
};

export const ShowWRGFileOuter = ({ wrg }: { wrg: WRG }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [selected, setSelected] = useState<WRGPoint | undefined>();

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/vindai/cl7khk1rl000l16lnommr0cr3",
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
      dragRotate: false,
      projection: defaultProjection,
    });

    map.on("load", (e) => {
      setMap(e.target);
    });

    map.on("click", layerId, (e) => {
      if (!e.features) return;
      const point = e.features[0].properties as WRGPoint;
      setSelected(point);
    });

    map.on("mouseenter", layerId, () => {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", layerId, () => {
      map.getCanvas().style.cursor = "";
    });

    return () => {
      setTimeout(() => map.remove(), 200);
    };
  }, []);

  const bounds: LngLatBoundsLike | undefined = useMemo(() => {
    const lons = wrg.points.map((p) => p.lon);
    const lats = wrg.points.map((p) => p.lat);
    return [fastMin(lons), fastMin(lats), fastMax(lons), fastMax(lats)];
  }, [wrg]);

  useEffect(() => {
    if (!bounds || !map) return;
    map.fitBounds(bounds, {
      padding: 50,
      animate: false,
    });
  }, [bounds, map]);
  if (!mapContainer) return null;
  return (
    <div
      style={{
        height: "40vh",
      }}
    >
      <MapWrapper id="grid-map" ref={mapContainer} />
      <ShowWRGFileInner
        map={map}
        wrg={wrg}
        point={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

const ShowWRGFileInner = ({
  wrg,
  map,
  point,
  setSelected,
}: {
  wrg: WRG;
  setSelected: (feature: any) => void;
  map?: mapboxgl.Map;
  point?: WRGPoint;
}) => {
  const features: Feature[] = useMemo(() => {
    return wrg.points.map((p) => {
      return {
        type: "Feature" as const,
        id: uuidv4(),
        geometry: {
          coordinates: [p.lon, p.lat],
          type: "Point",
        },
        properties: {
          ...p,
          meanSpeed: Math.round(p.A * Math.log(2) ** (1 / p.k) * 100) / 100,
        },
      };
    });
  }, [wrg]);

  const paint: CirclePaint | undefined = useMemo(() => {
    if (features.length === 0) return;
    const colors = COLORS.default;
    const speeds = features.map((f) => f?.properties?.meanSpeed || 8);
    const minSpeed = speeds.reduce(
      (acc, s) => (s > 2 ? Math.min(acc, s) : acc),
      8,
    );
    const maxSpeed = speeds.reduce((acc, s) => Math.max(acc, s), 9);
    const ds = (maxSpeed - minSpeed) / 4;
    return {
      "circle-color": [
        "interpolate",
        ["linear"],
        ["number", ["get", "meanSpeed"]],
        minSpeed,
        rgbToHex(colors[0][0], colors[0][1], colors[0][2]),
        minSpeed + ds,
        rgbToHex(colors[1][0], colors[1][1], colors[1][2]),
        minSpeed + ds * 2,
        rgbToHex(colors[2][0], colors[2][1], colors[2][2]),
        minSpeed + ds * 3,
        rgbToHex(colors[3][0], colors[3][1], colors[3][2]),
        maxSpeed,
        rgbToHex(colors[4][0], colors[4][1], colors[4][2]),
      ],
      "circle-opacity": 1,
    };
  }, [features]);

  const windRose = useMemo(() => {
    if (!point) return;
    const sectors = JSON.parse(point.sectors) as WRGSector[];
    return {
      longitude: point.lon,
      latitude: point.lat,
      height: point.height,
      directions: sectors.map((s, i) => ({
        probability: s.probability,
        direction: (i * 360) / sectors.length,
        meanSpeed: weibullMean(s.A, s.k),
      })),
      alpha: 0,
      speeds: [],
      speedProbabilities: [],
    };
  }, [point]);

  if (!map) return null;

  return (
    <>
      {map && point && windRose && (
        <Popup
          map={map}
          pos={{
            lng: point.lon,
            lat: point.lat,
          }}
        >
          <WindRose
            windRose={windRose}
            width={200}
            height={200}
            radialStep={10}
            showMeanSpeed={true}
          />
        </Popup>
      )}

      {map && paint && (
        <Point
          map={map}
          features={features}
          sourceId={sourceId}
          layerId={layerId}
          paint={paint}
          onClickCallback={(features) => setSelected(features[0]?.properties)}
        />
      )}
    </>
  );
};

export const ShowWRGFile = ({ data }: { data: Promise<WRG | undefined> }) => {
  const [wrg, setWrg] = useState<WRG | undefined>();
  const { error: errorMessage } = useToast();
  useEffect(() => {
    data
      .then((res) => res && setWrg(res))
      .catch((error) => {
        if (error instanceof UploadWindDataFileSizeTooLargeError) {
          errorMessage(error.message);
          return;
        }
        throw error;
      });
  }, [data, errorMessage]);

  if (!wrg) return null;

  return <ShowWRGFileOuter wrg={wrg} />;
};

export const ShowWRGFileInMap = ({ wrg }: { wrg: WRG }) => {
  const map = useAtomValue(mapAtom);
  const setMeanSpeedLimits = useSetAtom(meanSpeedGridLimitsAtom);
  const [selected, setSelected] = useState<any>();
  const features = useMemo(() => {
    return wrg.points
      .map((p) => {
        return {
          type: "Feature" as const,
          id: uuidv4(),
          geometry: {
            coordinates: [p.lon, p.lat],
            type: "Point",
          },
          properties: {
            ...p,
            meanSpeed: weibullMean(p.A, p.k),
          },
        };
      })
      .filter((f) => f.properties.meanSpeed > 1) as Feature[];
  }, [wrg]);

  useEffect(() => {
    if (!features) return;
    const minSpeed = features.reduce(
      (acc, f) => Math.min(acc, f?.properties?.meanSpeed),
      10,
    );
    const maxSpeed = features.reduce(
      (acc, f) => Math.max(acc, f?.properties?.meanSpeed),
      5,
    );
    setMeanSpeedLimits([minSpeed, maxSpeed]);
  }, [features, setMeanSpeedLimits]);

  if (!map) return null;

  return (
    <ShowWRGFileInner
      map={map}
      wrg={wrg}
      point={selected}
      setSelected={setSelected}
    />
  );
};
