import { InputDimensioned, Input } from "../General/Input";

import {
  MooringLineType,
  MooringMaterialType,
  MOORING_LINE_DIAMETER_MAX,
  MOORING_LINE_DIAMETER_MIN,
} from "../../services/mooringLineTypeService";
import { useEffect, useMemo, useState, useCallback } from "react";
import Button from "../General/Button";
import { DefaultMooringProps } from "../../constants/mooring";
import { WATER_DENSITY } from "../../constants/metocean";
import Dropdown from "../Dropdown/Dropdown";
import { isNumber } from "../../utils/predicates";
import { Column, Row } from "../General/Layout";
import {
  ContentWrapper,
  HorizontalLine,
  SecondaryText,
  ModalContainer,
  SettingButton,
  Subtitle,
  TitleContainer,
} from "../SettingsV2/Shared/styles";
import { Label } from "../General/Form";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { toastMessagesAtom } from "../../state/toast";
import useEnterToSave from "./useEnterToSave";

import {
  idToMooringChangelogId,
  mooringChangelogInfo,
} from "components/InputChangelog/const";
import ComponentLastChanged from "./SettingsUsage/ComponentLastChanged";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { useSetAtom } from "jotai";
import { ColumnContent, HeaderContainer, HeaderWrapper } from "./shared";
import DescriptionModal from "./DescriptionModal";

const diameterToEA = (
  diameter: number,
  material: MooringMaterialType,
): number => {
  const mooringProps = DefaultMooringProps[material];
  const d_nom = diameter / 1000;

  return mooringProps.EA_d2 * Math.pow(d_nom, 2);
};

const diameterToWetWeight = (
  diameter: number,
  material: MooringMaterialType,
): number => {
  const mooringProps = DefaultMooringProps[material];
  const d_nom = diameter / 1000;
  const d_vol = mooringProps.dvol_dnom * d_nom;
  const massDens = mooringProps.mass_d2 * Math.pow(d_nom, 2);

  return (massDens - (Math.PI / 4) * d_vol ** 2 * WATER_DENSITY) * 9.81;
};

export const MATERIAL_DEFAULT: MooringMaterialType = "chain_r3";
export const DIAMETER_DEFAULT = 100;
export const EA_DEFAULT = diameterToEA(DIAMETER_DEFAULT, MATERIAL_DEFAULT);
export const WETWEIGHT_DEFAULT = diameterToWetWeight(
  DIAMETER_DEFAULT,
  MATERIAL_DEFAULT,
);

export function SingleLineType({
  line,
  onSave,
  isLoading,
  disabled,
  nodeId,
}: {
  line: MooringLineType;
  onSave: (line: MooringLineType) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
}) {
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const [description, setDescription] = useState(line.note ?? "");
  const [_line, setLine] = useState<MooringLineType>(line);

  useEffect(() => {
    setLine(line);
    setDescription(line.note ?? "");
  }, [line]);

  const hasChanged = useMemo(() => {
    return line !== _line;
  }, [_line, line]);

  const onEnterSaveCallback = useCallback(() => onSave(_line), [onSave, _line]);
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);

  const lineId = line?.id;

  const initializeLine = useCallback(() => {
    if (line && lineId && !_line) {
      setLine(line);
    }
  }, [line, lineId, _line, setLine]);

  useEffect(() => {
    initializeLine();
  }, [lineId, initializeLine]);

  const handleUpdateMooringDescription = useCallback(
    (description: string) => {
      if (!line) return;
      setDescription(description);
      if (onSave) {
        onSave({
          ...line,
          note: description,
        });
      }
    },
    [line, onSave],
  );

  return (
    <ModalContainer>
      <HeaderWrapper>
        <HeaderContainer>
          <Column
            style={{
              width: "100%",
            }}
          >
            <h3
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "60rem",
              }}
            >
              {_line.name}
            </h3>
            <Row
              style={{
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <ComponentLastChanged
                changelogId={idToMooringChangelogId(_line.id)}
                nodeId={nodeId}
                category={"project"}
              />
            </Row>
          </Column>
          <DescriptionModal
            disabled={disabled}
            defaultValue={description}
            updateDescription={handleUpdateMooringDescription}
            subtitle={
              <div>
                <p>
                  The description will be visible for Admins and Editors in
                  projects with access to this mooring line.
                </p>
              </div>
            }
          />
        </HeaderContainer>
        <SettingButton
          style={{
            justifyContent: "flex-end",
            position: "absolute",
            right: "2.4rem",
            top: "14.4rem",
          }}
        >
          {hasChanged && (
            <>
              <Button
                disabled={isLoading || !hasChanged}
                text="Cancel"
                buttonType="text"
                onClick={() => {
                  setLine(line);
                }}
                style={{
                  marginLeft: "auto",
                }}
              />
              <Button
                disabled={isLoading || !hasChanged}
                text="Save changes"
                onClick={() => {
                  setToastMessages((tm) => [
                    ...tm,
                    {
                      text: "Saving...",
                      timeout: 1000,
                    },
                  ]);
                  onSave(_line);
                }}
              />
            </>
          )}
        </SettingButton>
      </HeaderWrapper>

      <ContentWrapper
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <ColumnContent>
          <Column>
            <TitleContainer>
              <Subtitle>General info</Subtitle>
              <HorizontalLine />
            </TitleContainer>

            <div
              style={{
                display: "grid",
                justifyContent: "start",
                gridTemplateColumns: "auto auto",
                gap: "2.4rem",
              }}
            >
              <Label>
                <SecondaryText>Name</SecondaryText>
                <ChangelogAndCommentWrapper
                  changelogInfo={mooringChangelogInfo(
                    _line.id,
                    nodeId,
                    "name",
                    "project",
                  )}
                  style={{
                    width: "100%",
                  }}
                  disabled={disabled}
                >
                  <Input
                    style={{
                      width: "42.4rem",
                    }}
                    disabled={disabled}
                    value={_line.name}
                    onChange={(e) => {
                      setLine({
                        ..._line,
                        name: e.target.value,
                      });
                    }}
                  />
                </ChangelogAndCommentWrapper>
              </Label>
              <Label>
                <SecondaryText>Cost</SecondaryText>
                <InputDimensioned
                  style={{
                    width: "20rem",
                  }}
                  disabled={disabled}
                  unit={_line.costUnit}
                  value={_line.cost}
                  initialValue={line.cost}
                  step={0.1}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  changelogInfo={mooringChangelogInfo(
                    _line.id,
                    nodeId,
                    "cost",
                    "project",
                  )}
                  onChange={(e) => {
                    if (!isNumber(e)) return;
                    setLine({
                      ..._line,
                      cost: e,
                    });
                  }}
                />
              </Label>
            </div>
            <TitleContainer>
              <Subtitle>Line properties</Subtitle>
              <HorizontalLine />
            </TitleContainer>
            <div
              style={{
                display: "grid",
                justifyContent: "start",
                gridTemplateColumns: "auto auto auto auto",
                gap: "2.4rem",
              }}
            >
              <ChangelogAndCommentWrapper
                changelogInfo={mooringChangelogInfo(
                  _line.id,
                  nodeId,
                  "material",
                  "project",
                )}
                style={{
                  width: "100%",
                }}
                disabled={disabled}
              >
                <Label>
                  <SecondaryText>Material</SecondaryText>
                  <Dropdown
                    style={{
                      width: "20rem",
                    }}
                    disabled={disabled}
                    value={_line.material as string}
                    onChange={(e) => {
                      const material = e.target.value as MooringMaterialType;
                      setLine({
                        ..._line,
                        material: material,
                      });
                    }}
                  >
                    <option value={"chain_r3"}>{"Chain R3"}</option>
                    <option value={"chain_r4"}>{"Chain R4"}</option>
                    <option value={"polyester"}>{"Polyester"}</option>
                    <option value={"nylon"}>{"Nylon"}</option>
                  </Dropdown>
                </Label>
              </ChangelogAndCommentWrapper>
              <Label>
                <SecondaryText>Diameter</SecondaryText>
                <InputDimensioned
                  style={{
                    width: "20rem",
                  }}
                  disabled={disabled}
                  unit="mm"
                  value={_line.diameter}
                  initialValue={line.diameter}
                  validate={(e) =>
                    MOORING_LINE_DIAMETER_MIN <= e &&
                    e <= MOORING_LINE_DIAMETER_MAX
                  }
                  validationMessage={`Must be between ${MOORING_LINE_DIAMETER_MIN} and ${MOORING_LINE_DIAMETER_MAX} mm`}
                  changelogInfo={mooringChangelogInfo(
                    _line.id,
                    nodeId,
                    "diameter",
                    "project",
                  )}
                  onChange={(e) => {
                    if (!isNumber(e)) return;

                    setLine({
                      ..._line,
                      diameter: e,
                    });
                  }}
                />
              </Label>
              <Label>
                <SecondaryText>EA</SecondaryText>
                <InputDimensioned
                  style={{
                    width: "20rem",
                  }}
                  disabled={disabled}
                  unit="MN"
                  scaleFactor={1 / 1e6}
                  value={_line.EA}
                  initialValue={line.EA}
                  decimals={2}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  changelogInfo={mooringChangelogInfo(
                    _line.id,
                    nodeId,
                    "EA",
                    "project",
                  )}
                  onChange={(e) => {
                    if (!isNumber(e)) return;
                    setLine({
                      ..._line,
                      EA: e,
                    });
                  }}
                />
              </Label>
              <Label>
                <SecondaryText>Wet weight</SecondaryText>
                <InputDimensioned
                  style={{
                    width: "20rem",
                  }}
                  disabled={disabled}
                  unit="N/m"
                  decimals={1}
                  value={_line.wetWeight}
                  initialValue={line.wetWeight}
                  validate={(e) => e >= 0.0}
                  validationMessage={`Must be greater than 0`}
                  changelogInfo={mooringChangelogInfo(
                    _line.id,
                    nodeId,
                    "wetWeight",
                    "project",
                  )}
                  onChange={(e) => {
                    if (!isNumber(e)) return;
                    setLine({
                      ..._line,
                      wetWeight: e,
                    });
                  }}
                />
              </Label>
            </div>
          </Column>
        </ColumnContent>
      </ContentWrapper>
    </ModalContainer>
  );
}
