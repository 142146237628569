import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { spacing2 } from "styles/space";

export const Margin = styled.div<{ addPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing2};
  ${({ addPadding }) => addPadding && `padding: 0 1rem;`}
`;

export const ProjectTitle = styled.div<{
  selected?: boolean;
  noHover?: boolean;
}>`
  ${typography.body}
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: ${(p) => (p.selected ? colors.textSelected : colors.textPrimary)};
  font-weight: ${(p) => (p.selected ? "600" : "400")};
  white-space: nowrap;
  &:hover {
    color: ${(p) => (p.noHover ? "inherit" : colors.blue600)};
  }
`;

export const TabHeader = styled.div`
  ${typography.h2}
  color: ${colors.textPrimary};
`;

export const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OrganisationPageBodyWrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 0;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;
