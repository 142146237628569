import { COLORVALUE, LABELVALUE, renderValues } from "business/style/constants";
import { DefaultMap } from "lib/DefaultMap";
import { projectIdAtom, projectIdAtomDef2 } from "state/pathParams";
import { isDefined } from "utils/predicates";
import { fastMax, fastMin, isNever, undefMap } from "utils/utils";
import { z } from "zod";
import {
  ColorCategory,
  ColorKey,
  Style,
  _Buckets,
  _Color,
  _Gradient,
  _Styles,
  isSingle,
  styleFeaturesOffshore,
  styleFeaturesOnshore,
} from "./types";
import { _ParkColorKey, _ParkLabel } from "./feature/park";
import { _AnchorColorKey, _AnchorLabel } from "./feature/anchor";
import { _CableColorKey, _CableLabel } from "./feature/cable";
import { _TurbineColorKey, _TurbineLabel } from "./feature/turbine";
import { atomFamily, atomFromFn, atomLocalStorage, lunwrap } from "utils/jotai";
import { atom } from "jotai";
import { cableTypesFamily } from "state/jotai/cableType";
import { RESET, loadable, unwrap } from "jotai/utils";
import { Buckets, Color, Gradient } from "lib/colors";
import { defaultParkStyle, parkValuesSelectorFamily } from "./feature/park";
import { cableValuesSelectorFamily, defaultCableStyle } from "./feature/cable";
import {
  anchorValuesSelectorFamily,
  defaultAnchorStyle,
} from "./feature/anchor";
import {
  defaultTurbineStyle,
  defaultTurbineStyleWakeLoss,
  defaultTurbineStyleWindSpeed,
  turbineValuesSelectorFamily,
} from "./feature/turbine";
import { fetchSchemaWithToken, fetchWithToken } from "services/utils";
import { scream } from "utils/sentry";
import { MaybePromise } from "types/utils";
import { ExpressionSpecification, SymbolLayerSpecification } from "mapbox-gl";
import { isOnshoreAtom } from "state/onshore";

const defaultStyle = atomFamily((feature: Style["feature"]) =>
  atom<Style>((get) => {
    if (feature === "turbines") return get(defaultTurbineStyle);
    if (feature === "cables") return get(defaultCableStyle);
    if (feature === "anchors") return get(defaultAnchorStyle);
    if (feature === "parks") return get(defaultParkStyle);
    throw isNever(feature);
  }),
);

const defaultStylesList = atom<Style[]>((get) => [
  get(defaultTurbineStyle),
  get(defaultTurbineStyleWakeLoss),
  get(defaultTurbineStyleWindSpeed),
  get(defaultAnchorStyle),
  get(defaultCableStyle),
  get(defaultParkStyle),
]);

const customStylesAtom = atomFamily(({ nodeId }: { nodeId: string }) =>
  atomFromFn<MaybePromise<Style[]>>(async () => {
    const styles = await fetchSchemaWithToken(
      z.object({ key: z.string(), data: z.any() }).array(),
      `/api/json/${nodeId}/styling/?list`,
      {
        method: "GET",
      },
    );
    return _Styles.parse(styles.map((s) => s.data)) as any;
  }),
);

export const enabledStyleAtomFamily = atomFamily(
  ({ nodeId, feature }: { feature: Style["feature"]; nodeId: string }) =>
    atomLocalStorage(
      `vind:feature-style:${nodeId}:${feature}`,
      `default-style-${feature}`,
      z.string(),
    ),
);

const stylesSelector = atom<Style[]>((get) => {
  const projectId = get(projectIdAtom);
  const s: Style[] = get(defaultStylesList);
  const custom =
    undefMap(projectId, (nodeId) =>
      get(unwrap(customStylesAtom({ nodeId }))),
    ) ?? [];
  const st = s.concat(custom ?? []);
  st.sort((a, b) => a.createdAt - b.createdAt);
  return st;
});

export const styleSelectorFamily = atomFamily((id: string) =>
  atom<Style | undefined, [Style | undefined | typeof RESET], void>(
    (get) => get(stylesSelector).find((s) => s.id === id),
    async (get, set, val: Style | undefined | typeof RESET) => {
      const nodeId = await get(projectIdAtomDef2);
      const styles = await get(customStylesAtom({ nodeId }));
      if (!val || val === RESET) {
        set(
          customStylesAtom({ nodeId }),
          styles.filter((s) => s.id !== id),
        );
        await fetchWithToken(`/api/json/${nodeId}/styling/${id}`, {
          method: "DELETE",
        });
      } else {
        set(
          customStylesAtom({ nodeId }),
          styles.filter((s) => s.id !== id).concat(val),
        );
        await fetchWithToken(`/api/json/${nodeId}/styling/${id}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(val),
        });
      }
    },
  ),
);

/**
 * Set style state based on a json-ably-message.
 */
export const styleMessageHandler = atom(
  null,
  async (
    get,
    set,
    kind: "create" | "update" | "delete",
    key: string,
    data?: unknown,
  ) => {
    const nodeId = await get(projectIdAtomDef2);
    const styleId = key.split("/")[1];
    const styles = await get(customStylesAtom({ nodeId }));
    if (kind === "delete") {
      set(
        customStylesAtom({ nodeId }),
        styles.filter((s) => s.id !== styleId),
      );
    } else {
      const [style] = _Styles.parse([data]) as Style[];
      if (!style) return scream("Cannot parse style", { data });
      set(
        customStylesAtom({ nodeId }),
        styles.filter((s) => s.id !== styleId).concat(style),
      );
    }
  },
);

/**
 * All styles for a feature type
 */
export const stylesPerFeatureSelector = atom<Record<Style["feature"], Style[]>>(
  (get) => {
    const styles = get(stylesSelector);
    const map = new DefaultMap<Style["feature"], Style[]>(() => []);
    for (const s of styles) map.get(s.feature).push(s);

    return Object.fromEntries(
      styleFeaturesOffshore.map((f) => [f, map.get(f)]),
    ) as Record<Style["feature"], Style[]>; // safety: All keys are accounted for, and DefaultMap.get returns a list.
  },
);

/**
 * ID for the style that's currently being edited.
 */
export const currentEditStyleIdAtom = atom<undefined | string>(undefined);

/**
 * If this is `true`, the default styles will always be used. This is set in
 * Dashboard so that the dashboard map is not using the custom styles, because
 * the legend would be wrong if we did, and we don't really have room for the
 * detailed legend in there.   Not a great solution, should figure out
 * something better.
 */
export const overrideUseDefaultStylesAtom = atom<boolean>(false);

/**
 * The current active style for a feature type.
 */
const activeFeatureStyleFamily = atomFamily((feature: Style["feature"]) =>
  atom<Style>((get) => {
    if (get(overrideUseDefaultStylesAtom)) return get(defaultStyle(feature));
    const editStyleId = get(currentEditStyleIdAtom);
    const styles = get(stylesPerFeatureSelector)[feature];
    if (editStyleId) {
      const style = styles.find((s) => s.id === editStyleId);
      if (style) return style;
    }
    const nodeId = get(projectIdAtom) ?? "";
    const enabledId = get(enabledStyleAtomFamily({ nodeId, feature }));
    return styles.find((s) => s.id === enabledId) ?? get(defaultStyle(feature));
  }),
);

const activeOffshoreFeatureStyles = atom<Style[]>((get) => {
  return styleFeaturesOffshore
    .map((f) => get(activeFeatureStyleFamily(f)))
    .filter(isDefined);
});

const activeOnshoreFeatureStyles = atom<Style[]>((get) => {
  return styleFeaturesOnshore
    .map((f) => get(activeFeatureStyleFamily(f)))
    .filter(isDefined);
});

export const activeFeatureStyles = atom<Style[]>((get) => {
  const onshore = get(isOnshoreAtom);
  if (onshore) return get(activeOnshoreFeatureStyles);
  return get(activeOffshoreFeatureStyles);
});

/** Whether any of the active stylings are custom, i.e. not default. */
export const hasCustomActiveFeatureSelector = atom<boolean>((get) =>
  get(activeFeatureStyles).some((s) => !s.defaultMarker),
);

export const sourceValueSelectorFamily = atomFamily(
  ({ styleId, colorKey }: { styleId: string; colorKey: ColorKey }) =>
    atom<Promise<Map<string, number | string> | undefined>>(async (get) => {
      const style = get(styleSelectorFamily(styleId));
      if (!style) return;
      if (style.feature === "turbines")
        return get(
          turbineValuesSelectorFamily(
            _TurbineColorKey.or(_TurbineLabel).parse(colorKey),
          ),
        );
      else if (style.feature === "cables")
        return get(
          cableValuesSelectorFamily(
            _CableColorKey.or(_CableLabel).parse(colorKey),
          ),
        );
      else if (style.feature === "anchors")
        return get(
          anchorValuesSelectorFamily(
            _AnchorColorKey.or(_AnchorLabel).parse(colorKey),
          ),
        );
      else if (style.feature === "parks")
        return get(
          parkValuesSelectorFamily(
            _ParkColorKey.or(_ParkLabel).parse(colorKey),
          ),
        );
      isNever(style);
      throw new Error(`Illegal \`feature\`: ${style}`);
    }),
);

/**
 * Get the value range for the given style.
 */
export const sourceValueMinMaxSelector = atomFamily((styleId: string) =>
  atom<Promise<{ min: number; max: number } | undefined>>(async (get) => {
    const style = get(styleSelectorFamily(styleId));
    if (!style) return;
    const map = await get(
      sourceValueSelectorFamily({ styleId, colorKey: style.source }),
    );
    if (!map) return undefined;
    const values = [...map.values()];
    if (values.some((v) => typeof v === "string")) {
      return { min: 0, max: 1 };
    }
    const vals = values as number[]; // safety: check above
    const min = fastMin(vals);
    const max = fastMax(vals);
    return { min, max };
  }),
);

/** Make a Mapbox expression for bucketing the {@link ColorBucket}s */
function bucketexpr(bs: Buckets, fallback: Color): ExpressionSpecification {
  return [
    "case",
    ...bs.buckets().flatMap((b, i, a) => {
      let max = b.to;
      if (i === a.length - 1) max += Math.abs(max * 0.01);
      return [["<", ["get", COLORVALUE], max], b.color.toRGB()];
    }),
    fallback.toRGB(),
  ];
}

/**
 * Make a Mapbox expression for range coloring, interpolating the {@link ColorRange}s
 *
 * Mapbox syntax is
 * ```
 * ["interpolate", "linear", <value expr>, in1, out1, in2, out2, ...]
 * ```
 * The inputs must be in **strictly increasing** order. Values outside the
 * range are clamped to the endpoints.
 */
function rangeexpr(ls: Gradient): ExpressionSpecification {
  return [
    "interpolate",
    ["linear"],
    ["get", COLORVALUE],
    ...ls.ranges().flatMap((b, i, a) => {
      // NOTE: mapbox insists on having the numbers strictly increase, so we
      // need to skip empty ranges.
      if (b.from === b.to && i !== 0) return [];
      let from = b.from;
      // Be generous with the endpoints, to ensure that they're included.
      if (i === 0) from -= Math.abs(b.from * 0.01);
      if (i === a.length - 1) {
        return [
          from,
          b.fromColor.toRGB(),
          b.to + Math.abs(b.to * 0.01),
          b.toColor.toRGB(),
        ];
      }
      return [from, b.fromColor.toRGB()];
    }),
  ];
}

/** Make a Mapbox expression for category coloring. */
function catexpr(
  cats: ColorCategory[],
  fallback: Color,
): ExpressionSpecification | string {
  if (cats.length === 0) return fallback.toRGB();
  return [
    "match",
    ["get", COLORVALUE],
    ...cats.flatMap((b) => [b.id, b.color.toRGB()]),
    fallback.toRGB(),
  ];
}

export const colorValuesSelectorFamily = atomFamily(
  (feature: Style["feature"]) =>
    atom<
      Promise<{
        color?: string | ExpressionSpecification | undefined;
        values?: Map<string, number | string>;
      }>
    >(async (get) => {
      const style = get(activeFeatureStyleFamily(feature));
      if (!style) return {};
      const values = lunwrap(
        get(
          loadable(
            sourceValueSelectorFamily({
              styleId: style.id,
              colorKey: style.source,
            }),
          ),
        ),
      );

      const defStyle = get(defaultStyle(feature));
      const fallbackColor = isSingle(defStyle)
        ? defStyle.color
        : Color.fromHex("#ff00ff");

      const color =
        style.type === "bucket"
          ? bucketexpr(style.buckets, fallbackColor)
          : style.type === "gradient"
            ? rangeexpr(style.gradient)
            : style.type === "category"
              ? catexpr(style.categories, fallbackColor)
              : style.color.toRGB();
      return { color, values };
    }),
);

/**
 * Get the {@link SymbolLayout} for Mapbox rendering of the labels in the
 * current style for the feature type.
 */
export const stylingLabelSelector = atomFamily((feature: Style["feature"]) =>
  atom<
    Promise<{
      layout?: SymbolLayerSpecification["layout"];
      values?: Map<string, string>;
    }>
  >(async (get) => {
    const style = get(activeFeatureStyleFamily(feature));
    if (!style?.label)
      return {
        layout: { "text-field": "" },
      };

    const size = { small: 10, medium: 12, large: 16 }[
      style.labelSize ?? "small"
    ];

    // Some labels are rendered differently because the values they're rendering
    // does not come from `"vind:labelvalue"`.  Special case these here.
    if (style.label === "name") {
      return {
        layout: {
          "text-field": ["get", "name"],
          "text-size": size,
          "text-allow-overlap": true,
        },
      };
    }

    let values = get(
      unwrap(
        sourceValueSelectorFamily({
          styleId: style.id,
          colorKey: style.label,
        }),
      ),
    );

    if (style.label === "group" && values && style.type === "category") {
      const catMap = new Map(style.categories.map((c) => [c.id, c.label]));
      const out = new Map(
        [...values.entries()]
          .map<[string, string] | undefined>(([parkId, catId]) => {
            const l = catMap.get(String(catId));
            if (!l) return undefined;
            return [parkId, l];
          })
          .filter(isDefined),
      );

      return {
        layout: {
          "text-field": ["get", LABELVALUE],
          "text-size": size,
          "text-allow-overlap": true,
        },
        values: out,
      };
    }
    if (style.label === "cable-type" && values) {
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));
      const out = new Map(
        [...values.entries()]
          .map<[string, string] | undefined>(([cableId, typeId]) => {
            if (typeof typeId !== "string") return undefined;
            const ct = cableTypes.get(typeId);
            if (!ct) return undefined;
            return [cableId, ct.name];
          })
          .filter(isDefined),
      );
      return {
        layout: {
          "text-field": ["get", LABELVALUE],
          "text-size": size,
          "text-allow-overlap": true,
        },
        values: out,
      };
    }

    return {
      layout: {
        "text-field": ["get", LABELVALUE],
        "text-size": size,
        "text-allow-overlap": true,
      },
      values: undefMap(values, (m) => renderValues(style.label, m)),
    };
  }),
);

/**
 * Get the set of possible values for a category.  For instance, for
 * `cable-type`, this would be the set of all cable types in the project.
 */
export const categoryDomainSelectorFamily = atomFamily((colorKey: ColorKey) =>
  atom<Promise<{ id: string; label?: string }[] | undefined>>(async (get) => {
    if (colorKey === "cable-type") {
      const cableTypes = await get(cableTypesFamily({ projectId: undefined }));
      return [...cableTypes.values()].map((c) => ({
        id: c.id,
        label: c.name,
      }));
    }
    return undefined;
  }),
);
