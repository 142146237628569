import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { useRefreshLibraryFoundation } from "components/ConfigurationModal/useRefreshCustomFoundations";
import { Mixpanel } from "mixpanel";
import {
  createNewOrgFoundation,
  deleteOrgFoundation,
  updateOrgFoundation,
} from "services/turbineAPIService";
import { FoundationType } from "types/foundations";
import { organisationFoundationResourceState } from "../state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

const useOrgFoundationCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshLibraryFoundations = useRefreshLibraryFoundation();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { showConfirm } = useConfirm();

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        foundation,
        name,
        projectAccess,
      }: {
        foundation: FoundationType;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const newFoundation = {
        ...foundation,
        name: name ? name : `${foundation.name} copy`,
      };

      const fallback = await get(
        organisationFoundationResourceState({
          organisationId,
        }),
      );

      try {
        const res = await createNewOrgFoundation(
          organisationId,
          newFoundation,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationFoundationResourceState({
            organisationId,
          }),
          (cur) => [...cur, res],
        );
        refreshLibraryFoundations();
        Mixpanel.track_old("Created org foundation", {
          foundationId: foundation.id,
          organisationId,
        });
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationFoundationResourceState({
            organisationId,
          }),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId, refreshLibraryFoundations],
  );

  const update = useJotaiCallback(
    async (
      get,
      set,
      foundation: FoundationType,
      onlyUpdatingDescription: boolean = false,
    ) => {
      const fallback = await get(
        organisationFoundationResourceState({
          organisationId,
        }),
      );

      return updateOrgFoundation(organisationId, foundation)
        .then((res) => {
          if (res) {
            aset(
              get,
              set,
              organisationFoundationResourceState({
                organisationId,
              }),
              (cur) => cur.map((c) => (c.id === foundation.id ? res : c)),
            );
            if (!onlyUpdatingDescription) {
              refreshLibraryFoundations();
            }
            Mixpanel.track_old("Update org foundation", {
              foundationId: foundation.id,
              organisationId,
            });
          }
        })
        .catch(() => {
          aset(
            get,
            set,
            organisationFoundationResourceState({
              organisationId,
            }),
            () => fallback,
          );
        });
    },
    [organisationId, refreshLibraryFoundations],
  );

  const remove = useJotaiCallback(
    async (get, set, foundationId: string) => {
      const fallback = await get(
        organisationFoundationResourceState({
          organisationId,
        }),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.FOUNDATION,
          resourceId: foundationId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.FOUNDATION,
          foundationId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete foundation",
          message: `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return deleteOrgFoundation(organisationId, foundationId)
          .then((res) => {
            if (res) {
              aset(
                get,
                set,
                organisationFoundationResourceState({
                  organisationId,
                }),
                (cur) => [...cur].filter((c) => c.id !== foundationId),
              );
              refreshLibraryFoundations();
              Mixpanel.track_old("Delete org foundation", {
                foundationId: foundationId,
                organisationId,
              });
            }
          })
          .catch(() => {
            aset(
              get,
              set,
              organisationFoundationResourceState({
                organisationId,
              }),
              () => fallback,
            );
          });
      }
    },
    [organisationId, refreshLibraryFoundations, showConfirm],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        foundation,
        projectAccess,
        onSuccess,
      }: {
        foundation: FoundationType;
        projectAccess?: string[];
        onSuccess?: (newFoundation: FoundationType) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "foundation",
          defaultName: `${foundation.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const newFoundation = await create({
              foundation,
              name,
              projectAccess,
            });
            onSuccess?.(newFoundation);
            return newFoundation;
          },
        },
      });
    },
    [create, setMidScreenModalTypeOpen],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, foundation: FoundationType) => {
      aset(
        get,
        set,
        organisationFoundationResourceState({ organisationId }),
        (currentFoundations) => {
          const existing = currentFoundations.find(
            (f) => f.id === foundation.id,
          );
          return existing
            ? currentFoundations.map((f) =>
                f.id === foundation.id ? { ...f, ...foundation } : f,
              )
            : [...currentFoundations, foundation];
        },
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, foundationId: string) => {
      aset(
        get,
        set,
        organisationFoundationResourceState({ organisationId }),
        (currentFoundations) => {
          return currentFoundations.filter((f) => f.id !== foundationId);
        },
      );
    },
    [organisationId],
  );

  return {
    create,
    remove,
    update,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgFoundationCrud;
