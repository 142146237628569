import { objectEquals, roundToDecimal } from "utils/utils";
import { ChangelogEntry, ChangelogRenderEntry, EventActions } from "./types";

// Expects path of the form "a.b.c.d"
export const getValue = (obj: Record<string, any>, path: string): any =>
  path.split(".").reduce((acc, key) => acc && acc[key], obj);

const formatValue = (value: any, scaleFactor: number, decimals?: number) => {
  if (value == null) return value;
  if (typeof value === "number") {
    return decimals
      ? roundToDecimal(value * scaleFactor, decimals)
      : value * scaleFactor;
  }
  if (typeof value === "string") {
    try {
      const parsed = parseFloat(value);
      if (isNaN(parsed)) return value;
      return decimals
        ? roundToDecimal(parseFloat(value) * scaleFactor, decimals)
        : parseFloat(value) * scaleFactor;
    } catch (e) {
      return value;
    }
  }
  return value;
};

export function findChanges(
  changeLogObjects: ChangelogEntry[],
  path: string,
  _getValue: (entry: Record<string, any>, path: string) => any = getValue,
  scaleFactor: number = 1,
  decimals?: number,
) {
  // sort, but not in place
  const sorted = changeLogObjects.slice().sort((a, b) => a.version - b.version);
  const changes: ChangelogRenderEntry[] = [];

  for (let i = 0; i < sorted.length; i++) {
    const changeLogObject = sorted[i];

    if (changeLogObject.action === EventActions.DELETE) {
      changes.push({
        ...changeLogObject,
        action: EventActions.DELETE,
      });
      break;
    }

    if (!changeLogObject.meta) break;

    const value = formatValue(
      _getValue(changeLogObject.meta, path),
      scaleFactor,
      decimals,
    );

    const comment = changeLogObject.comments?.find((c) => c.keyPath === path);

    // No value, just assume delete
    if (value == null) {
      if (changes.length !== 0) {
        changes.push({
          ...changeLogObject,
          action: EventActions.DELETE,
          comment: comment ?? undefined,
        });
        break;
      }
      continue;
    }

    if (changeLogObject.action === EventActions.CREATE) {
      changes.push({
        ...changeLogObject,
        action: EventActions.CREATE,
        value,
        comment: comment ?? undefined,
      });
      continue;
    }

    if (changeLogObject.action === EventActions.UPDATE) {
      if (value != null && changes.length === 0) {
        changes.push({
          ...changeLogObject,
          action: EventActions.CREATE,
          value,
          comment: comment ?? undefined,
        });
        continue;
      }
      const previousChange = changes.slice(-1)[0];
      if (previousChange.action === EventActions.DELETE) {
        continue;
      }
      const previousValue = previousChange.value;
      if (objectEquals(previousValue, value)) {
        previousChange.comment = previousChange.comment ?? comment;
        continue;
      }
      changes.push({
        ...changeLogObject,
        action: EventActions.UPDATE,
        oldValue: previousValue,
        value,
        comment: comment ?? undefined,
      });
    }
  }

  return changes;
}

// expect initialCostPath to be the path from the outer object in to the array, e.g. "capex.custom"
// expect keyInfo to be in the format of "idName.idValue", the key and value used to find the correct object
// keysToInclude: empty means entire object, else use the keys in the list
export const getCustomArrayChangeLogValue = (
  object: Record<string, any>,
  initialCostPath: string,
  idInfo: string,
  keysToInclude?: string[],
) => {
  const customArray = getValue(object, initialCostPath);
  if (!customArray) {
    return undefined;
  }

  const [idName, idValue] = idInfo.split(".");

  const arrayEntry = customArray.find(
    (custom: any) => custom[idName] === idValue,
  );

  if (!arrayEntry) return undefined;

  if (keysToInclude && keysToInclude.length > 0) {
    // extract keysToInclude from arrayEntry object
    return keysToInclude.reduce<Record<string, any>>((acc, key) => {
      acc[key] = arrayEntry[key];
      return acc;
    }, {});
  }

  return arrayEntry;
};
