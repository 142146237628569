import { EnergyLoss } from "services/configurationService";
import { TurbineFeature } from "types/feature";
import { sum } from "utils/utils";
import { HOURS_PER_YEAR } from "@constants/production";
import { TurbineStat } from "./types";

export type TotalInterArrayLoss = {
  totalInterArrayLoss: number | null;
  totalInterArrayCableLoss: number | null;
  totalTurbineTrafoLoss: number | null;
};
export type TotalExportSystemLoss = {
  totalExportSystemLoss: number | null;
  totalExportCableLoss: number | null;
  totalOffshoreTrafoLoss: number | null;
  totalOnshoreTrafoLoss: number | null;
};

export const computeInternalWakeLoss = (
  grossPerTurbine: number[],
  internalWakeLossPerTurbine: number[],
  gross: number,
): number => {
  const netWithoutNeighbours = sum(
    grossPerTurbine,
    (g, i) => g * (1 - internalWakeLossPerTurbine[i]),
  );
  const internalWakeLoss = 1 - netWithoutNeighbours / gross;
  return internalWakeLoss;
};

/**
 * Computes the total loss for a series of `EnergyLoss`es.
 */
export const computeOtherLosses = (energyLosses: EnergyLoss[]): number => {
  const efficiency = energyLosses.reduce((t, l) => (t *= 1 - l.value), 1);
  return 1 - efficiency;
};

export const computeGrossEnergyProductionPerTurbine = (
  grossPerTurbine: number[],
  turbineLosses: number[],
  totalWakeLossPerTurbine: number[],
  turbines: TurbineFeature[],
): TurbineStat[] => {
  return grossPerTurbine.map((g, i) => {
    const value = g * (1 - turbineLosses[i]) * (1 - totalWakeLossPerTurbine[i]);
    return { turbine: turbines[i], value };
  });
};

export const computePostWakeEnergyProductionPerTurbine = (
  grossPerTurbine: number[],
  totalWakeLossPerTurbine: number[],
  turbines: TurbineFeature[],
): TurbineStat[] => {
  return grossPerTurbine.map((g, i) => {
    const value = g * (1 - totalWakeLossPerTurbine[i]);
    return { turbine: turbines[i], value };
  });
};

export const computeLoss = (
  grossPerTurbine: number[],
  totalLossPerTurbine: number[],
  gross: number,
) => {
  const net = sum(grossPerTurbine, (g, i) => g * (1 - totalLossPerTurbine[i]));
  return 1 - net / gross;
};

export const computeCapacityFactor = (aep: number, capacity: number): number =>
  (1e3 * aep) / (capacity * HOURS_PER_YEAR);
