import { ThreeCoreParkShadow } from "components/ViewAnalyses/ViewParkShadow/ThreeContext/useCreateThreeCore";
import { atom } from "jotai";
import { z } from "zod";
import { atomLocalStorage } from "utils/jotai";

export const viewParkShadowVisibleAtom = atom<boolean>(false);
export const threeCoreParkShadowAtom = atom<ThreeCoreParkShadow | undefined>(
  undefined,
);
export const viewParkShadowLenghtAtom = atomLocalStorage<number>(
  "vind:view-park-shadow:shadow-length",
  100,
  z.number().min(0).max(3000),
);
