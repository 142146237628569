import { loadable } from "jotai/utils";
import { orLoader, undefinedLoader } from "components/Loading/Skeleton";
import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { spaceMedium } from "../../../../styles/space";
import { FlexGrid2 } from "../../../General/Form";
import { Row } from "../../../General/Layout";
import { CenterContainer, SafeCard } from "../Base";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useDashboardContext } from "../../Dashboard";
import {
  getClosestWaveDataSelector,
  getWaveStatsSelectorFamily,
} from "state/waveStatistics";
import { getAnalysisWindStats } from "analysis/output";
import { useAtomValue } from "jotai";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { lunwrap } from "utils/jotai";

const Grid = styled(FlexGrid2)`
  flex: 1;
  padding: ${spaceMedium};
  align-self: start;
  p {
    white-space: nowrap;
  }
`;

const WaveDetails = () => {
  const { triggerId } = useDashboardContext();

  const windStats = useAtomValue(getAnalysisWindStats(triggerId));
  const waveData = useAtomValue(
    loadable(
      getClosestWaveDataSelector({
        lon: windStats?.longitude,
        lat: windStats?.latitude,
        source: undefined,
      }),
    ),
  );
  const parkCenter = windStats
    ? [windStats.longitude, windStats.latitude]
    : undefined;
  const waveStats = useAtomValue(
    loadable(
      getWaveStatsSelectorFamily({
        parkCenter,
      }),
    ),
  );

  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));

  const stopped = stoppedReason ?? analysisStoppedReason;

  const rowref = useRef<HTMLDivElement>(null);
  const gridref = useRef<HTMLDivElement>(null);

  const averageHs = useMemo(() => {
    const d = lunwrap(waveData);
    if (!d?.hs) return;
    const hs = d.hs;
    return hs.reduce((m: number, h: number) => (m += h), 0) / hs.length;
  }, [waveData]);

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert
          title="Analysis stopped"
          text={analysisStoppedText[stopped]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <Row
      style={{
        flex: 1,
      }}
      ref={rowref}
    >
      <Grid ref={gridref}>
        <Row>
          <p>Latitude</p>
          {orLoader(waveData, (s) => (
            <p>{s?.latitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Longitude</p>
          {orLoader(waveData, (s) => (
            <p>{s?.longitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Wave source</p>
          {orLoader(waveData, (s) => (
            <p>{s?.source.toUpperCase()}</p>
          ))}
        </Row>
        <Row>
          <p>Avg. Hs</p>
          {undefinedLoader(averageHs, (s) => (
            <p>{s.toFixed(1)} m</p>
          ))}
        </Row>
        <Row>
          <p>50-year Hs</p>
          {orLoader(waveStats, (s) => (
            <p>{s?.hs50Yr.toFixed(1)} m</p>
          ))}
        </Row>
      </Grid>
    </Row>
  );
};

export const WaveDetailsWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Wave details"
      id="Wave details"
      resetKeys={errorBoundaryResetKeys}
    >
      <WaveDetails />
    </SafeCard>
  );
};
