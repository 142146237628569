import {
  ChangelogCategory,
  GetChangeLogValue,
  InputChangelogInfo,
} from "./types";

export const idToTurbineChangelogId = (id: string) => `CONFIG#TURBINE#${id}`;
export const idToFoundationChangelogId = (id: string) =>
  `CONFIG#FOUNDATION#${id}`;
export const idToMooringChangelogId = (id: string) => `CONFIG#MOORING#${id}`;
export const idToSubstationChangelogId = (id: string) =>
  `CONFIG#SUBSTATION#${id}`;
export const idToCableChangelogId = (id: string) => `CONFIG#CABLE#${id}`;
export const idToExportCableChangelogId = (id: string) =>
  `CONFIG#EXPORTCABLE#${id}`;
export const idToAnalysisChangelogId = (id: string) => `CONFIG#ANALYSIS#${id}`;
export const idToFinancialChangelogId = (id: string) =>
  `CONFIG#FINANCIAL#${id}`;
const idToProcurementCostsChangelogId = (id: string) =>
  `CONFIG#PROCUREMENT_COST#${id}`;
const idToOperationsChangelogId = (id: string) => `CONFIG#OPERATIONS#${id}`;
export const idToWindChangelogId = (id: string) => `CONFIG#WIND#${id}`;
export const idToDataPackageChangelogId = (id: string) =>
  `CONFIG#DATA_PACKAGE#${id}`;
export const idToCustomLayerChangelogId = (id: string) => `CUSTOM_LAYER#${id}`;

export const idToVesselChangelogId = (id: string) => `CONFIG#VESSEL#${id}`;

// TURBINE

export const turbineChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToTurbineChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// FOUNDATION

export const foundationChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToFoundationChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// MOORING

export const mooringChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToMooringChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// SUBSTATION

export const substationChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToSubstationChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Cable

export const cableChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToCableChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Export Cable

export const exportCableChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToExportCableChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Analysis

export const analysisChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToAnalysisChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Financial

export const financialChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToFinancialChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Procurement Costs

export const procurementCostsChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: any,
): InputChangelogInfo => {
  return {
    changelogId: idToProcurementCostsChangelogId(id),
    changelogEntryKeyId: entryKeyId,
    nodeId,
    category,
    getValue,
  };
};

// Operations

export const operationsChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToOperationsChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Wind config

export const windChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToWindChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});

// Vessel
export const vesselChangelogInfo = (
  id: string,
  nodeId: string,
  entryKeyId: string,
  category: ChangelogCategory,
  getValue?: GetChangeLogValue,
): InputChangelogInfo => ({
  changelogId: idToVesselChangelogId(id),
  changelogEntryKeyId: entryKeyId,
  nodeId,
  category,
  getValue,
});
