import { useAtomValue, useSetAtom } from "jotai";
import debounce from "debounce";
import {
  AnalysisConfiguration,
  updateAnalysisConfiguration,
} from "../services/configurationService";
import { savingConfigurationInProgressAtom } from "../state/configuration";
import { Mixpanel } from "mixpanel";
import { aset, useJotaiCallback } from "utils/jotai";
import { projectAnalysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { projectIdAtomDef } from "state/pathParams";
import { DesignToolMode } from "types/map";
import { designToolTypeAtom } from "state/map";
import { useToast } from "./useToast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const saveToDB = async (
  nodeId: string,
  projectType: DesignToolMode,
  configuration: AnalysisConfiguration,
  onComplete: () => void,
) => {
  return updateAnalysisConfiguration(
    nodeId,
    projectType,
    configuration,
  ).finally(() => {
    onComplete();
  });
};

const saveToDbDebounced = debounce(saveToDB, 600);

const useConfigurationCrud = () => {
  const projectId = useAtomValue(projectIdAtomDef);
  const projectType = useAtomValue(designToolTypeAtom);
  const setIsSaving = useSetAtom(savingConfigurationInProgressAtom);
  const { info, success } = useToast();
  const projectConfigurations = useAtomValue(
    projectAnalysisConfigurationsFamily({
      projectId,
    }),
  );
  const { showConfirm } = useConfirm();

  const updateLocal = useJotaiCallback(
    (get, set, nodeId: string, configuration: AnalysisConfiguration) => {
      return aset(
        get,
        set,
        projectAnalysisConfigurationsFamily({
          projectId: nodeId,
        }),
        (cur) => {
          const newMap = new Map(cur);
          newMap.set(configuration.id, configuration);
          return newMap;
        },
      );
    },
    [],
  );

  const deleteLocal = useJotaiCallback(
    (get, set, nodeId: string, configurationId: string) => {
      return aset(
        get,
        set,
        projectAnalysisConfigurationsFamily({
          projectId: nodeId,
        }),
        (cur) => {
          const filtered = Array.from(cur.entries()).filter(
            ([id]) => id !== configurationId,
          );
          return new Map(filtered);
        },
      );
    },
    [],
  );

  const save = useJotaiCallback(
    async (
      get,
      set,
      configuration: AnalysisConfiguration,
      skipUsageCheck?: boolean,
    ) => {
      if (!projectId) return;
      setIsSaving(true);

      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId: projectId,
          resourceType: "ANALYSIS_CONFIGURATION",
          resourceId: configuration.id,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        info("Looking for usage...", {
          timeout: 3000,
          groupId: "analysis-update",
        });
        usage = await fetchProjectResourceUsage(
          projectId,
          "ANALYSIS_CONFIGURATION",
          configuration.id,
        );
      }

      const currentConfig = projectConfigurations.get(configuration.id);

      const {
        name: _,
        description: __,
        ...configurationWithoutNameAndDescription
      } = configuration;
      const {
        name: ___,
        description: ____,
        ...currentConfigWithoutNameAndDescription
      } = currentConfig || {};

      const changedSomethingElseThanNameOrDescription =
        JSON.stringify(configurationWithoutNameAndDescription) !==
        JSON.stringify(currentConfigWithoutNameAndDescription);

      const updatedConfiguration: AnalysisConfiguration = currentConfig
        ? {
            ...currentConfig,
            ...configuration,
          }
        : configuration;

      if (
        !changedSomethingElseThanNameOrDescription ||
        usage.length === 0 ||
        skipUsageCheck ||
        (await showConfirm({
          title: "Update configuration",
          message: `This configuration is used in ${usage.length} branch${usage.length !== 1 ? "es" : ""}, are you sure you want to update it?`,
          confirmButtonText: "Update",
        }))
      ) {
        info("Saving...", { timeout: 3000, groupId: "analysis-update" });
        updateLocal(projectId, updatedConfiguration);
        return saveToDbDebounced(
          projectId,
          projectType,
          updatedConfiguration,
          () => {
            setIsSaving(false);
            success("Saved", { timeout: 3000, groupId: "analysis-update" });
            Mixpanel.track_old("Config saved", {
              type: "analysis",
            });
          },
        );
      } else {
        setIsSaving(false);
      }
    },
    [
      showConfirm,
      projectId,
      setIsSaving,
      info,
      projectConfigurations,
      updateLocal,
      projectType,
      success,
    ],
  );

  return {
    save,
    updateLocal,
    deleteLocal,
  };
};

export default useConfigurationCrud;
