import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import ComponentsIcon from "@icons/24/Components.svg?react";
import TurbinesIcon from "@icons/24/SideBarIcons/SingleTurbine.svg?react";
import FoundationIcon from "@icons/24/SideBarIcons/Foundation.svg?react";
import { FeatureSettingsModalTypeV2 } from "state/configuration";
import Tooltip from "components/General/Tooltip";
import {
  DISABLED_IN_READ_ONLY_MODE,
  inReadOnlyModeSelector,
} from "state/project";
import SearchWrapper from "components/Search/SearchWrapper";
import { modalTypeOpenAtom } from "state/modal";
import RoundIcon from "components/Design/TopbarNavigation/components/Middle/RoundIcon";
import { MiddleItemWrapper } from "components/Design/TopbarNavigation/components/Middle/style";
import { Mixpanel } from "mixpanel";
import { useAtom } from "jotai";
import SearchObject from "components/Search/SearchObject";

function ComponentsSearchObject({ children }: { children: React.ReactNode }) {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);

  return (
    <>
      <SearchObject
        title="Turbine components"
        tags={[
          "turbines",
          "project resource",
          "turbine type",
          "hub height",
          "power",
          "thrust",
          "loss",
          "voltage",
          "power curves",
          "thrust curves",
        ]}
        id="search-turbine-components"
        icon={<TurbinesIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: FeatureSettingsModalTypeV2,
            metadata: {
              selectedMenuId: "turbines",
            },
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: FeatureSettingsModalTypeV2,
            });
        }}
      />
      <SearchObject
        title="Foundation components"
        tags={[
          "foundations",
          "project resource",
          "foundation type",
          "weight",
          "foundation material",
          "monopile",
          "spar",
          "simple jacket",
          "site-specific jacket wizard",
        ]}
        id="search-foundation-components"
        icon={<FoundationIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: FeatureSettingsModalTypeV2,
            metadata: {
              selectedMenuId: "foundations",
            },
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: FeatureSettingsModalTypeV2,
            });
        }}
      />
      <SearchWrapper
        title="Components"
        tags={[
          "projects",
          "list",
          "project list",
          "feature list",
          "turbine",
          "foundation",
          "inter array",
          "cable",
          "mooring line",
          "substation",
          "export cable",
          "cost",
          "diameter",
          "hub height",
          "loss",
          "voltage",
          "thrust",
          "power",
          "material",
          "steel",
          "balast",
          "resistance",
          "reactor",
        ]}
        id="search-components"
        icon={<ComponentsIcon />}
        onSelect={() => {
          if (readOnlyMode) {
            return;
          }
          setModalType({
            modalType: FeatureSettingsModalTypeV2,
          }),
            Mixpanel.track_old("Open full screen modal", {
              type: FeatureSettingsModalTypeV2,
            });
        }}
      >
        {children}
      </SearchWrapper>
    </>
  );
}

const ComponentsModalButton = () => {
  const [modalTypeOpen, setModalType] = useAtom(modalTypeOpenAtom);
  const readOnlyMode = useAtomValue(inReadOnlyModeSelector);

  return (
    <ComponentsSearchObject>
      <Tooltip
        theme="light"
        text={readOnlyMode ? DISABLED_IN_READ_ONLY_MODE : "Settings"}
        position="bottom"
        disabled={!readOnlyMode}
      >
        <MiddleItemWrapper
          className="middle-item-wrapper"
          active={modalTypeOpen?.modalType === FeatureSettingsModalTypeV2}
          disabled={readOnlyMode}
          onClick={() => {
            if (readOnlyMode) {
              return;
            }
            setModalType((curr) =>
              curr?.modalType !== FeatureSettingsModalTypeV2
                ? {
                    modalType: FeatureSettingsModalTypeV2,
                  }
                : undefined,
            );
            Mixpanel.track_old("Open full screen modal", {
              type: FeatureSettingsModalTypeV2,
            });
          }}
        >
          <RoundIcon fillOrStroke="fill">
            <ComponentsIcon />
          </RoundIcon>
          Components
        </MiddleItemWrapper>
      </Tooltip>
    </ComponentsSearchObject>
  );
};

export default ComponentsModalButton;
