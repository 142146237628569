import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { projectIdAtom } from "state/pathParams";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { ValidationWarning } from "state/validationWarnings";

export const differentExportVoltageTypesFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.CableDifferentExportVoltageTypes>
        | undefined
      >
    >(async (get) => {
      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const projectId = get(projectIdAtom);
      if (!projectId) return;
      const exportCableTypes = await get(exportCableTypesFamily({ projectId }));

      const badCables = exportCables.filter((cable) => {
        const { cableTypeId, onshoreCableTypeId } = cable.properties;
        if (!cableTypeId || !onshoreCableTypeId) return false;
        const offshoreType = exportCableTypes.get(cableTypeId);
        const onshoreType = exportCableTypes.get(onshoreCableTypeId);
        if (!offshoreType || !onshoreType) return false;
        return offshoreType.voltage !== onshoreType.voltage;
      });

      if (badCables.length === 0) return;
      return {
        type: ValidationWarningTypes.CableDifferentExportVoltageTypes,
        featureIds: badCables.map((f) => f.id),
        parkId: parkId,
      };
    }),
);
