import { TurbineFeature } from "../../../../../types/feature";
import groupBy from "../../../../../utils/groupBy";
import { dedup } from "utils/utils";
import { useAtomValue } from "jotai";
import {
  foundationFixedTypesAtom,
  getDetailedJacketMassesFamily,
} from "state/jotai/foundation";
import { JacketSingleStats } from "./JacketSingleStats";

export const DetailedJacketGenerationInner = ({
  turbines,
  turbineNames,
  rasterId,
}: {
  turbines: TurbineFeature[];
  turbineNames: { [key: string]: string };
  rasterId: string;
}) => {
  const { jacketDetails } = useAtomValue(
    getDetailedJacketMassesFamily({
      turbinesWithDetailedJacket: turbines,
      rasterId,
    }),
  );

  const allFixed = useAtomValue(foundationFixedTypesAtom);
  const foundationIds = dedup(
    turbines.map((turbine) => turbine.properties.foundationId),
  );

  const jacketDesignsByTurbineTypeFoundationId = groupBy(
    jacketDetails,
    ({ turbineTypeId, foundationId }) => `${turbineTypeId}-${foundationId}`,
  );

  const foundationNames = new Map(
    allFixed
      .filter((f) => foundationIds.includes(f.id))
      .map((f) => [f.id, f.name]),
  );

  const turbineJacketCombinations = Array.from(
    new Set(
      turbines.map(
        (t) => `${t.properties.turbineTypeId}-${t.properties.foundationId}`,
      ),
    ),
  );

  return (
    <>
      {turbineJacketCombinations.map((combination) => {
        const [t_id, f_id] = combination.split("-");
        const t_name = turbineNames[t_id];
        const f_name = String(foundationNames.get(f_id));
        return (
          <JacketSingleStats
            key={t_id + f_id}
            name={t_name + " - " + f_name}
            designs={jacketDesignsByTurbineTypeFoundationId[combination]}
          />
        );
      })}
    </>
  );
};
