import {
  getAnalysisProgress,
  getNetEmpiricalPercentiles,
} from "analysis/output";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../../styles/colors";
import { LoadingState, SafeCard } from "./Base";
import { Percentile } from "functions/production";
import { fastMax, fastMin } from "utils/utils";
import { SkeletonText } from "components/Loading/Skeleton";
import { useDashboardContext } from "../Dashboard";
import { useAtomValue } from "jotai";

const AEPGraph = () => {
  const { triggerId } = useDashboardContext();
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));
  const percentiles = useAtomValue(getNetEmpiricalPercentiles(triggerId));

  if (!percentiles) {
    if (analysisProgress)
      return (
        <SkeletonText
          style={{ margin: "2rem", height: "2rem" }}
          text={`${Math.round(analysisProgress * 100)}%`}
        />
      );
    return <LoadingState />;
  }

  return <AEPGraphInner percentiles={percentiles} />;
};

const AEPGraphInner = ({ percentiles }: { percentiles: Percentile[] }) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const current = graphRef.current;
    if (!isLoaded || !current) return;
    const observer = new ResizeObserver(() => {
      if (!current.clientWidth) return;
      Plotly.Plots.resize(current);
    });
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [isLoaded]);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const x = percentiles.map((p) => p.value / 1e3);
    const xmin = fastMin(x);
    const xmax = fastMax(x);
    const step = (xmax - xmin) / (x.length / 2);
    const xStart = xmin - 2 * step;
    const xStop = xmax + 2 * step;
    const n = Math.round((xStop - xStart) / step);
    if (!n || n < 0) return;

    var data = [
      {
        x,
        name: "Net energy",
        type: "histogram" as const,
        textposition: "auto" as const,
        hoverinfo: "none" as const,
        autobinx: false,
        opacity: 0.8,
        marker: {
          color: colors.hoverSelected,
          line: {
            color: colors.brand,
            width: 1.5,
          },
        },
        xbins: {
          start: xmin - 2 * step,
          size: step,
          end: xmax + 2 * step,
        },
      },
    ];
    var layout = {
      font: { size: 10, family: "Open Sans" },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      autosize: true,
      legend: {
        x: 1.1,
        xanchor: "right" as const,
        y: 10,
      },
      height: 200,
      bargap: 0.05,
      yaxis: {
        title: "Number of years",
        rangemode: "tozero" as const,
      },
      yaxis2: {
        rangemode: "tozero" as const,
        showgrid: false,
        showticklabels: false,
      } as const,
      xaxis: { title: "GWh" },
      margin: {
        l: 60,
        r: 20,
        b: 30,
        t: 30,
      },
    };

    Plotly.newPlot(graphRef.current, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    }).then(() => setIsLoaded(true));
  }, [graphRef, percentiles]);

  return <div ref={graphRef} />;
};

export const AEPDistributionWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();
  return (
    <SafeCard
      title="Annual variability"
      id="Annual variability"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <AEPGraph />
    </SafeCard>
  );
};
