import { ValidationWarningTypes } from "./utils";
import { isSubstation, isTurbine } from "utils/predicates";
import * as turf from "@turf/turf";
import { Sector } from "lib/sector";
import { deg2rad } from "utils/geometry";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { cablesInParkFamily } from "state/jotai/cable";
import { featureMapFamily } from "state/jotai/features";

export const cableIntersectsSectorFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const cables = await get(cablesInParkFamily({ parkId, branchId }));
      const featureMap = await get(featureMapFamily({ branchId }));
      const badCables = [];

      cableLoop: for (const c of cables) {
        const from = featureMap.get(c.properties.fromId);
        if (!isTurbine(from) && !isSubstation(from)) continue;
        const fromAngle = deg2rad(
          turf.bearing(c.geometry.coordinates[0], c.geometry.coordinates[1]),
        );
        for (const sec of from.properties.cableFreeSectors ?? []) {
          const s = new Sector(sec.middle, sec.span);
          // If the angular distance is negative, it is inside the span.
          if (s.distance(fromAngle) < -0.001) {
            badCables.push(c);
            continue cableLoop;
          }
        }

        const to = featureMap.get(c.properties.toId);
        if (!isTurbine(to) && !isSubstation(to)) continue;
        const toAngle = deg2rad(
          turf.bearing(
            c.geometry.coordinates.at(-1)!,
            c.geometry.coordinates.at(-2)!,
          ),
        );
        for (const sec of to.properties.cableFreeSectors ?? []) {
          const s = new Sector(sec.middle, sec.span);
          // If the angular distance is negative, it is inside the span.
          if (s.distance(toAngle) < -0.001) {
            badCables.push(c);
            continue cableLoop;
          }
        }
      }

      if (badCables.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.CableIntersectsSector,
        featureIds: badCables.map((c) => c.id),
      } as const;
    }),
);
