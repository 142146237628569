import { typography } from "styles/typography";
import { colors } from "styles/colors";
import styled from "styled-components";
import { Column, Row } from "components/General/Layout";
import { StandardBox } from "styles/boxes/Boxes";
import { borderRadiusMedium, spaceTiny } from "styles/space";
import Tooltip from "components/General/Tooltip";
import React from "react";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  overflow: hidden;
`;

export const ContentHeaderColumn = styled(Column)`
  gap: 0.4rem;
`;
export const ContentHeader = styled.p`
  ${typography.h4}
`;

export const ContentTableColumn = styled(Column)`
  & > :nth-child(2n + 1) {
    background-color: ${colors.surfaceSecondary};

    .gray-background {
      background-color: ${colors.grey100};
    }
  }
  .gray-background {
    background-color: ${colors.surfaceSecondary};
  }
  gap: 0;
`;

export const ContentTableColumnFirstGrey = styled(Column)`
  & > :nth-child(2n) {
    background-color: ${colors.surfaceSecondary};
  }
  gap: 0;
`;

export const ContentTableRow = styled(Row)<{
  disabled?: boolean;
  disableHover?: boolean;
}>`
  padding: 0.7rem 1.5rem;
  align-items: center;
  min-height: 3.9rem;
  box-sizing: border-box;
  ${(p) => p.disabled && `opacity: 0.6`};
  ${(p) => !p.disabled && `cursor: pointer`};
  > button {
    opacity: 0;
    transition: opacity 0.15s;
  }
  &:hover {
    > button {
      opacity: 1;
    }
  }
`;

export const ContentTableRowNonHover = styled(ContentTableRow)`
  cursor: inherit;
  &:hover {
    background-color: inherit;
    cursor: inherit;
  }
`;

export const HoverableColumn = styled(Column)`
  gap: 0rem;
  height: 100%;
`;

export const GaplessRow = styled(Row)`
  gap: 0;

  > * {
    flex: 1;
  }
`;

export const GaplessRowPadding = styled(GaplessRow)`
  padding: 1rem;
  align-items: center;
`;

export const TabHeader = styled.p`
  ${typography.h4}
  text-transform: capitalize;
  text-wrap: nowrap;
  margin: 0;
`;

export const TabSubtitle = styled.p`
  ${typography.caption}
`;

export const TabDescription = styled.p`
  ${typography.body}
  font-weight: 600;
  padding: 0.8rem;
`;
export const Text = styled.p`
  ${typography.caption}
  text-wrap: nowrap;
`;
export const TextEllipsis = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

const overflowStyle: React.CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  textWrap: "nowrap",
};

export const TextEllipsisWithTooltip = ({
  text,
  style,
}: {
  text: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Tooltip
      text={text}
      outerDivStyle={overflowStyle}
      innerDivStyle={overflowStyle}
    >
      <TextEllipsis style={style}>{text}</TextEllipsis>
    </Tooltip>
  );
};

export const SecondaryText = styled.p`
  ${typography.contentAndButtons}
  color: ${colors.secondaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const SearchAndSelectContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 35rem;
  height: fit-content;
  max-width: 35rem;
  gap: 1.2rem;
  background: white;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 30vh;
  gap: 1.2rem;
  overflow-y: auto;
`;

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 20vh;
  gap: 1.2rem;
  overflow-y: auto;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.inputOutline};
`;

export const SortWrapper = styled.div`
  display: flex;
  visibility: hidden;
  svg {
    path {
      fill: ${colors.iconDisabled};
    }
  }
`;

export const TableHeader = styled.div`
  ${typography.sub1}
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  white-space: nowrap;
  &:hover {
    ${SortWrapper} {
      visibility: visible;
    }
  }
`;

export const NoGroupMemberWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
`;

export const NoGroupMember = styled.div`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightBlue};
  border-radius: ${borderRadiusMedium};
  width: fit-content;
`;

export const ShoreTypePill = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfaceButtonSecondary};
  color: ${colors.textSecondary};
  border-radius: 2rem;
  padding: 0.6rem 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaceTiny};
  justify-content: center;
  width: fit-content;

  cursor: pointer;

  p {
    color: white;
    margin: 0;
    padding: 0 0.4rem 0 0;
  }
`;
