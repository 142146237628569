import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSetAtom, useAtomValue } from "jotai";
import { useDrag, useDrop } from "react-dnd";
import { customerProjectAtomFamily, useUpdateBranch } from "state/timeline";
import { getPathToBranch } from "components/Design/BranchTabBar/useNavigateToBranch";
import { openedExistingBranchesTabsAtomFamily } from "components/Design/BranchTabBar/state";
import { BranchMeta } from "types/api";
import {
  FolderTreeItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import {
  findItemInTree,
  getAllResourcesInFolderTree,
  isFolderItem,
} from "business/folderStructure/utils";
import {
  cursorIsInBottomHalfOfElement,
  findCursorPositionInElement,
} from "utils/dragNDropUtils";
import { Row } from "components/General/Layout";
import { EditableTextInternalState } from "components/General/EditableText";
import { dedup, getPathPrefix } from "utils/utils";
import { IconREMSize, typography } from "styles/typography";
import { spacing1, spacing2, spacing3 } from "styles/space";
import { colors } from "styles/colors";
import { DotMenu, MenuButtonRef } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import ChatIcon from "@icons/24/Chat.svg";
import DuplicateAltIcon from "@icons/24/DuplicateAlt.svg";
import ArchiveIcon from "@icons/24/Archive.svg";
import BinIcon from "@icons/24/Bin.svg";
import useBranchFolderStructureCrud from "hooks/useBranchFolderStructureCrud";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import FolderMenuOpenIcon from "@icons/20/FolderMenuOpen.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import AddIcon from "@icons/24/Add.svg";
import OpenInTabBarIcon from "@icons/24/OpenInTabBar.svg";
import FolderIcon from "@icons/24/Folder.svg";
import BranchIcon from "@icons/24/Branch.svg";
import { projectIdAtom } from "state/pathParams";
import {
  ExpandArrowWrapper,
  ListItemTopRow,
  ListItemWrapper,
} from "components/FolderList/style";
import {
  FolderChildren,
  FolderContainsActiveBranchDot,
} from "components/Design/BranchTabBar/components/style";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";

const TOP_BOTTOM_MARGIN = 12;

export const BRANCH_TABLE_LIST_ITEM = "BRANCH_TABLE_LIST_ITEM";
export const BRANCH_TABLE_FOLDER_ITEM = "BRANCH_TABLE_FOLDER_ITEM";

export const BranchTableItem = ({
  branch,
  organisationId,
  nodeId,
  index,
  active,
  enableSorting,
  showControls,
  canDeleteBranch,
  onDuplicateBranchClick,
  depth,
  parentFolder,
  onDropItem,
  onBranchClick,
  onArchiveBranch,
  onDeleteBranch,
  isLegalFolderMove,
}: {
  branch: BranchMeta;
  organisationId: string;
  nodeId: string;
  index: number;
  active: boolean;
  enableSorting: boolean;
  showControls: boolean;
  canDeleteBranch: boolean;
  depth: number;
  parentFolder?: FolderTreeItem;
  onDropItem(
    item: BranchMeta | FolderTreeItem,
    newIndex: number,
    parentFolder?: FolderTreeItem,
  ): void;
  onArchiveBranch(branch: BranchMeta): void;
  onDuplicateBranchClick(branch: BranchMeta): void;
  onDeleteBranch(branch: BranchMeta): void;
  onBranchClick(branch: BranchMeta): void;
  isLegalFolderMove(
    folder: FolderTreeItem,
    targetFolder: FolderTreeItem,
  ): boolean;
}) => {
  const updateBranch = useUpdateBranch();
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId,
    }),
  );
  const setOpenBranches = useSetAtom(
    openedExistingBranchesTabsAtomFamily({ nodeId }),
  );
  const handleUpdateBranchDescription = useCallback(
    (newText: string) => {
      updateBranch({ ...branch, description: newText }).finally(() => {});
    },
    [updateBranch, branch],
  );
  const dotMenuRef = useRef<MenuButtonRef>(null);
  const [isDotdotMenuOpen, setIsDotdotMenuOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState<undefined | "top" | "bottom">(
    undefined,
  );
  const [isRenaming, setIsRenaming] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const [, dragRef] = useDrag(
    () => ({
      type: BRANCH_TABLE_LIST_ITEM,
      item: branch,
      canDrag: enableSorting && !isRenaming,
    }),
    [branch, enableSorting, isRenaming],
  );

  const [dropCollection, dropRef] = useDrop<
    BranchMeta | FolderTreeItem,
    {
      handled: boolean;
    },
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: [BRANCH_TABLE_LIST_ITEM, BRANCH_TABLE_FOLDER_ITEM],
      canDrop: (item, _monitor) => {
        if (isFolderItem(item) && parentFolder) {
          return isLegalFolderMove(item, parentFolder);
        }
        return true;
      },
      hover: (hoveredItem, monitor) => {
        if (!monitor.isOver() || hoveredItem.id === branch.id) {
          return setHoverState(undefined);
        }
        if (isFolderItem(hoveredItem) && parentFolder) {
          return setHoverState(undefined);
        }
        const hoveringBottom = cursorIsInBottomHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        setHoverState(hoveringBottom ? "bottom" : "top");
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem: BranchMeta | FolderTreeItem, monitor) => {
        if (monitor.getDropResult()?.handled) {
          return;
        }

        const isBottom = cursorIsInBottomHalfOfElement(
          elementRef.current,
          monitor.getClientOffset(),
        );
        const newIndex = isBottom ? index + 1 : index;

        if (isFolderItem(draggedItem)) {
          if (parentFolder && !isLegalFolderMove(draggedItem, parentFolder)) {
            return {
              handled: true,
            };
          }
        }
        onDropItem(draggedItem, newIndex, parentFolder);

        return {
          handled: true,
        };
      },
    }),
    [branch.id, index, isLegalFolderMove, onDropItem, parentFolder],
  );

  dragRef(dropRef(elementRef));

  return (
    <ListItemWrapper
      key={branch.id}
      ref={elementRef}
      onClick={() => {
        onBranchClick(branch);
      }}
      enableDrag={!isRenaming && enableSorting}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
      onContextMenu={(e) => {
        if (showControls) {
          e.preventDefault();
          e.stopPropagation();
          dotMenuRef.current?.setIsOpen(true);
          setIsDotdotMenuOpen(true);
        }
      }}
    >
      <ListItemTopRow
        alignCenter
        hasIcon={false}
        depth={depth}
        active={active}
        forceHover={isDotdotMenuOpen}
        clickable={true}
        hasExpandIcon={false}
      >
        {/*<VisibleOnHoverIfSortingEnabled>*/}
        {/*  <DnDSmallIcon />*/}
        {/*</VisibleOnHoverIfSortingEnabled>*/}
        <EditableTextInternalState
          value={branch.title}
          renderText={(text) => (
            <a
              style={{
                textDecoration: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap",
              }}
              href={getPathToBranch(
                getPathPrefix(project),
                organisationId,
                nodeId,
                branch.id,
              )}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <p
                className="clickable"
                style={{
                  ...typography.contentAndButtons,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  overflowX: "clip",
                  whiteSpace: "nowrap",
                }}
              >
                {text}
              </p>
            </a>
          )}
          style={{
            overflow: "hidden",
          }}
          textContainerStyle={{
            padding: 0,
          }}
          isEditing={isRenaming}
          onEnter={(newName) => {
            updateBranch({ ...branch, title: newName });
          }}
          onEditChange={setIsRenaming}
          disabled={!showControls}
        />
        {showControls && (
          <Row
            style={{
              gap: spacing3,
            }}
            alignCenter
          >
            {active && (
              <div
                style={{
                  padding: `${spacing1} ${spacing2}`,
                  borderRadius: "4px",
                  backgroundColor: colors.blue200,
                }}
              >
                <p style={typography.graphics}>Active</p>
              </div>
            )}
            <DescriptionModal
              size="small"
              disabled={false}
              close={() => setShowDescription(false)}
              defaultValue={branch.description}
              updateDescription={handleUpdateBranchDescription}
              subtitle={
                <div>
                  <p>The description will be visible for Admins and Editors.</p>
                </div>
              }
              editByDefault={showDescription}
            />
            <DotMenu
              ref={dotMenuRef}
              hoverColor={colors.iconSelected}
              hoverType="stroke"
              hoverBackgroundColor={
                active
                  ? colors.surfaceSelectedHover
                  : colors.surfaceSelectedLight
              }
              onChange={setIsDotdotMenuOpen}
            >
              <MenuItem
                name={"Add to tab bar"}
                icon={<OpenInTabBarIcon />}
                onClick={() => {
                  setOpenBranches((curr) => {
                    return [...curr, branch.id];
                  });
                }}
              />
              <MenuItem
                name={
                  branch.description ? "Edit description" : "Add description"
                }
                icon={<ChatIcon />}
                onClick={() => setShowDescription(true)}
              />
              <MenuItem
                name="Duplicate branch"
                icon={<DuplicateAltIcon />}
                onClick={() => onDuplicateBranchClick(branch)}
              />
              {/*<MenuItem name="See more info" disabled={true} />
            <MenuItem name="Add description" disabled={true} />
            <MenuItem name="Tags" disabled={true} />
            <MenuItem name="Lock branch" disabled={true} />
            <MenuItem name="Archive branch" disabled={true} />*/}
              <MenuItem
                name="Archive branch"
                icon={<ArchiveIcon />}
                onClick={() => {
                  onArchiveBranch(branch);
                }}
                disabled={!canDeleteBranch}
                title={
                  !canDeleteBranch ? "Can not archive last branch" : undefined
                }
              />
              <MenuItem
                name="Delete branch"
                icon={<BinIcon />}
                onClick={() => {
                  onDeleteBranch(branch);
                }}
                disabled={!canDeleteBranch}
                title={
                  !canDeleteBranch ? "Can not delete last branch" : undefined
                }
              />
            </DotMenu>
          </Row>
        )}
      </ListItemTopRow>
    </ListItemWrapper>
  );
};

export const ArchivedBranchTableItem = ({
  branch,
  showControls,
  onDeleteBranch,
  onRestoreBranch,
}: {
  branch: BranchMeta;
  nodeId: string;
  showControls: boolean;
  onDeleteBranch(branch: BranchMeta): void;
  onRestoreBranch(branch: BranchMeta): void;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isDotdotMenuOpen, setIsDotdotMenuOpen] = useState(false);
  const dotMenuRef = useRef<MenuButtonRef>(null);

  return (
    <ListItemWrapper
      key={branch.id}
      ref={elementRef}
      isHoveredTop={false}
      isHoveredBottom={false}
      enableDrag={false}
      onContextMenu={(e) => {
        if (showControls) {
          e.preventDefault();
          e.stopPropagation();
          dotMenuRef.current?.setIsOpen(true);
          setIsDotdotMenuOpen(true);
        }
      }}
    >
      <ListItemTopRow
        alignCenter
        hasIcon={false}
        depth={0}
        hasExpandIcon={false}
        clickable={false}
        forceHover={isDotdotMenuOpen}
      >
        <EditableTextInternalState
          value={branch.title}
          renderText={(text) => (
            <p
              className="clickable"
              style={{
                ...typography.contentAndButtons,
                overflow: "hidden",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </p>
          )}
          style={{
            overflow: "hidden",
          }}
          textContainerStyle={{
            padding: 0,
          }}
          disabled={true}
        />
        {showControls && (
          <Row
            style={{
              gap: spacing3,
            }}
            alignCenter
          >
            <DescriptionModal
              size="small"
              disabled={true}
              defaultValue={branch.description}
              subtitle={<div></div>}
              updateDescription={() => {}}
            />
            <DotMenu
              ref={dotMenuRef}
              hoverColor={colors.iconSelected}
              hoverType="stroke"
              hoverBackgroundColor={colors.surfaceSelectedLight}
              onChange={setIsDotdotMenuOpen}
            >
              <MenuItem
                name="Restore branch"
                icon={<ArchiveIcon />}
                onClick={() => {
                  onRestoreBranch(branch);
                }}
              />
              <MenuItem
                name="Delete branch"
                icon={<BinIcon />}
                onClick={() => {
                  onDeleteBranch(branch);
                }}
              />
            </DotMenu>
          </Row>
        )}
      </ListItemTopRow>
    </ListItemWrapper>
  );
};

export const Folder = ({
  folder,
  parentFolder,
  enableSorting,
  showControls,
  depth,
  activeBranchId,
  folderTreeContainsActiveFolder,
  shouldForceOpen,
  onBranchDropOnFolder,
  onFolderDropOnFolder,
  onCreateBranchInFolder,
  onCreateFolderInFolder,
  onReorder,
  isLegalFolderMove,
  children,
}: {
  folder: FolderTreeItem;
  parentFolder?: FolderTreeItem;
  enableSorting: boolean;
  showControls: boolean;
  depth: number;
  shouldForceOpen: boolean;
  activeBranchId: string;
  folderTreeContainsActiveFolder: boolean;
  onCreateBranchInFolder(folderId: string): void;
  onCreateFolderInFolder(folderId: string): void;
  onBranchDropOnFolder(folder: FolderTreeItem, droppedBranch: BranchMeta): void;
  onFolderDropOnFolder(
    folder: FolderTreeItem,
    droppedFolder: FolderTreeItem,
    parentFolder?: FolderTreeItem,
  ): void;
  onReorder(
    folder: FolderTreeItem,
    index: number,
    parentFolder?: FolderTreeItem,
  ): void;
  isLegalFolderMove(
    folder: FolderTreeItem,
    targetFolder: FolderTreeItem,
  ): boolean;
  children: (
    resourceId: string,
    parentFolder: FolderTreeItem,
    depth: number,
    index: number,
  ) => React.ReactNode;
}) => {
  const { editFolderName, deleteFolder } = useBranchFolderStructureCrud();
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const { showConfirm } = useConfirm();
  const setOpenedBranches = useSetAtom(
    openedExistingBranchesTabsAtomFamily({ nodeId }),
  );

  const dropElementRef = useRef<HTMLDivElement>(null);
  const dragElementRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isForcedOpen, setIsForcedOpen] = useState(shouldForceOpen);
  const [isDotdotMenuOpen, setIsDotdotMenuOpen] = useState(false);
  const [hoverState, setHoverState] = useState<
    "bottom" | "top" | "middle" | undefined
  >();
  const dotMenuRef = useRef<MenuButtonRef>(null);

  useEffect(() => {
    setIsForcedOpen(shouldForceOpen);
  }, [shouldForceOpen]);

  const [, dragRef] = useDrag(
    () => ({
      type: BRANCH_TABLE_FOLDER_ITEM,
      item: folder,
      canDrag: enableSorting && !isRenaming,
    }),
    [enableSorting, folder, isRenaming],
  );

  const [dropCollection, dropRef] = useDrop<
    BranchMeta | FolderTreeItemOrResourceItem,
    {
      handled: boolean;
    },
    {
      isHovered: boolean;
    }
  >(
    () => ({
      accept: [BRANCH_TABLE_LIST_ITEM, BRANCH_TABLE_FOLDER_ITEM],
      canDrop: (draggedItem, _monitor) => {
        if (isFolderItem(draggedItem)) {
          return isLegalFolderMove(draggedItem, folder);
        }
        return true;
      },
      hover: (draggedItem, monitor) => {
        if (!monitor.isOver({ shallow: true })) {
          return setHoverState(undefined);
        }
        if (isFolderItem(draggedItem)) {
          const zone = findCursorPositionInElement(
            dropElementRef.current,
            monitor.getClientOffset(),
            TOP_BOTTOM_MARGIN,
          );
          setHoverState(zone);
        } else {
          setHoverState("middle");
        }
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (draggedItem, monitor) => {
        if (monitor.getDropResult()?.handled) {
          return;
        }

        if (draggedItem.id === folder.id) {
          return {
            handled: true,
          };
        }

        const draggedItemIsFolder = isFolderItem(draggedItem);

        if (!draggedItemIsFolder) {
          onBranchDropOnFolder(folder, draggedItem as BranchMeta);
          return {
            handled: true,
          };
        }

        const zone = findCursorPositionInElement(
          dropElementRef.current,
          monitor.getClientOffset(),
          TOP_BOTTOM_MARGIN,
        );

        if (zone === "middle") {
          if (draggedItem.parentId === folder.id) {
            return {
              handled: true,
            };
          }
          onFolderDropOnFolder(folder, draggedItem, parentFolder);
        } else {
          const newIndex =
            zone === "bottom"
              ? (folder.sortOrder ?? 0) + 1
              : folder.sortOrder ?? 0;
          onReorder(draggedItem, newIndex, parentFolder);
        }
        return {
          handled: true,
        };
      },
    }),
    [
      folder,
      isLegalFolderMove,
      onBranchDropOnFolder,
      onFolderDropOnFolder,
      onReorder,
      parentFolder,
    ],
  );

  dropRef(dropElementRef);
  dragRef(dragElementRef);

  const folderIsEmpty = !(folder.children && folder.children.length > 0);
  const isHoveredBottom = dropCollection.isHovered && hoverState === "bottom";
  const isHoveredTop = dropCollection.isHovered && hoverState === "top";
  const isHoveredMiddle = dropCollection.isHovered && hoverState === "middle";

  const shouldShowAsOpen = isOpen || isForcedOpen;
  return (
    <ListItemWrapper
      ref={dropElementRef}
      isHoveredBottom={isHoveredBottom}
      isHoveredTop={isHoveredTop}
      isHoveredMiddle={isHoveredMiddle}
      enableDrag={enableSorting}
      onContextMenu={(e) => {
        if (showControls) {
          e.preventDefault();
          e.stopPropagation();
          dotMenuRef.current?.setIsOpen(true);
          setIsDotdotMenuOpen(true);
        }
      }}
    >
      <ListItemTopRow
        alignCenter
        clickable
        hasIcon={true}
        hasExpandIcon={true}
        ref={dragElementRef}
        onClick={() => {
          if (shouldShowAsOpen) {
            setIsOpen(false);
            setIsForcedOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        depth={depth}
        forceHover={isDotdotMenuOpen}
      >
        <ExpandArrowWrapper open={shouldShowAsOpen}>
          <ChevronDownIcon />
        </ExpandArrowWrapper>
        <IconREMSize height={2} width={2}>
          {shouldShowAsOpen ? <FolderMenuOpenIcon /> : <FolderMenuClosedIcon />}
        </IconREMSize>
        <EditableTextInternalState
          value={folder.name}
          renderText={(text) => (
            <p
              style={{
                ...typography.contentAndButtons,
                overflow: "hidden",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </p>
          )}
          style={{
            overflow: "hidden",
          }}
          onEditChange={setIsRenaming}
          onEnter={(newValue) => {
            return editFolderName(folder.id, newValue);
          }}
          disabled={!showControls}
        />
        <Row alignCenter>
          {showControls && (
            <DotMenu
              ref={dotMenuRef}
              hoverColor={colors.iconSelected}
              hoverType="stroke"
              hoverBackgroundColor={colors.surfaceSelectedLight}
              onChange={setIsDotdotMenuOpen}
            >
              <MenuItem name="Create new" icon={<AddIcon />}>
                <MenuItem
                  name="Folder"
                  icon={<FolderIcon />}
                  onClick={() => onCreateFolderInFolder(folder.id)}
                />
                <MenuItem
                  name="Branch"
                  icon={<BranchIcon />}
                  onClick={() => onCreateBranchInFolder(folder.id)}
                />
              </MenuItem>
              {!folderIsEmpty && (
                <MenuItem
                  name="Add branches to tab bar"
                  icon={<OpenInTabBarIcon />}
                  onClick={() => {
                    const resources = getAllResourcesInFolderTree([folder]);

                    setOpenedBranches((curr) => {
                      return dedup([...curr, ...resources.map((r) => r.id)]);
                    });
                  }}
                />
              )}

              <MenuItem
                name="Delete folder"
                icon={<BinIcon />}
                disabled={!folderIsEmpty}
                title={
                  !folderIsEmpty ? "Folder must be empty to delete" : undefined
                }
                onClick={async () => {
                  if (!folderIsEmpty) {
                    return;
                  }
                  if (
                    await showConfirm({
                      title: "Delete folder",
                      message: "Are you sure you want to delete the folder?",
                      confirmButtonText: "Delete",
                    })
                  ) {
                    return deleteFolder(folder.id);
                  }
                }}
              />
            </DotMenu>
          )}
          {folderTreeContainsActiveFolder && !shouldShowAsOpen && (
            <FolderContainsActiveBranchDot />
          )}
        </Row>
      </ListItemTopRow>
      {shouldShowAsOpen && (
        <FolderChildren>
          {folder.children.length === 0 ? (
            <ListItemTopRow
              alignCenter
              depth={depth + 1}
              hasIcon={false}
              hasExpandIcon={false}
            >
              <p
                style={{
                  ...typography.contentAndButtons,
                  color: colors.textDisabled,
                }}
              >
                Folder is empty
              </p>
            </ListItemTopRow>
          ) : (
            folder.children.map((child, index) => {
              if (isFolderItem(child)) {
                const containsActiveBranch = Boolean(
                  findItemInTree(activeBranchId, child.children),
                );
                return (
                  <Folder
                    key={child.id}
                    folder={child}
                    enableSorting={enableSorting}
                    showControls={showControls}
                    depth={depth + 1}
                    parentFolder={folder}
                    shouldForceOpen={shouldForceOpen || containsActiveBranch}
                    onBranchDropOnFolder={onBranchDropOnFolder}
                    onFolderDropOnFolder={onFolderDropOnFolder}
                    onReorder={onReorder}
                    isLegalFolderMove={isLegalFolderMove}
                    onCreateBranchInFolder={onCreateBranchInFolder}
                    onCreateFolderInFolder={onCreateFolderInFolder}
                    activeBranchId={activeBranchId}
                    folderTreeContainsActiveFolder={containsActiveBranch}
                  >
                    {children}
                  </Folder>
                );
              }
              return children(child.id, folder, depth, index);
            })
          )}
        </FolderChildren>
      )}
    </ListItemWrapper>
  );
};
