import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import MapModal from "./MapModal/MapModal";
import { Suspense } from "react";
import {
  ErrorBoundarySilent,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";

const Comments = ErrorBoundaryWrapper(
  () => {
    const projectId = useAtomValue(projectIdAtom) ?? "";
    const branchId = useAtomValue(branchIdAtom) ?? "";

    if (!projectId) return null;
    if (!branchId) return null;
    return (
      <Suspense fallback={null}>
        <MapModal nodeId={projectId} />
      </Suspense>
    );
  },
  ErrorBoundarySilent,
  ScreamOnError,
);

export default Comments;
