import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import {
  borderRadiusMedium,
  spaceLarge,
  spaceMedium,
  spaceSmall,
  spaceTiny,
  spacing3,
  spacing6,
} from "../../styles/space";
import { typography } from "styles/typography";
import { addSvgStrokeOrFill } from "utils/styleUtils";

/**
 * Wrapper for a non-interactive icon.
 */
export const Icon = styled.div<{
  size?: string;
  stroke?: string;
  fill?: string;
}>`
  display: flex;
  svg {
    ${(p) => {
      if (p.size) return `width: ${p.size}; height: ${p.size};`;
    }}
    overflow: initial; // Icons that are slightly larger than the viewPort due to weird scaling should not be clipped.

    path,
    line {
      ${({ stroke }) => stroke !== undefined && `stroke: ${stroke};`}
      ${({ fill }) => fill !== undefined && `fill: ${fill};`}
    }
  }
`;

export const RoundIconBtn = styled.button<{
  /** Size of the icon. Used for `width` and `height` as-is. */
  size?: string;
  /** Listener for clicks. This will automatically add the hover effect and `cursor: pointer` */
  onClick?: React.ComponentProps<"button">["onClick"];
  /** Use `fill` instead of `stroke`. */
  $fill?: string | boolean;
  /** Use `fill` instead of `stroke`. */
  stroke?: string | boolean;

  /** Change width of stroke */
  pathStrokeWidth?: number;
  disabled?: boolean;
  /** If the button is "pressed", e.g. when you click on the "Place point" tool and it's "active". */
  active?: boolean;
}>`
  display: flex;
  padding: 0.8rem;
  width: 3.2rem;
  height: 3.2rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    overflow: initial; // Icons that are slightly larger than the viewPort due to weird scaling should not be clipped.

    path,
    line {
      ${(p) => `stroke-width: ${p.pathStrokeWidth}`};
      ${(p) => {
        if (p.$fill) {
          if (typeof p.$fill === "string") return `fill: ${p.$fill};`;
          return `fill: ${colors.iconBrand};`;
        } else {
          if (typeof p.stroke === "string") return `stroke: ${p.stroke};`;
          return `stroke: ${colors.iconBrand};`;
        }
      }}
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  background-color: ${(p) => {
    if (p.disabled) return colors.grey100;
    if (p.active) return colors.backgroundDefault;
    return colors.backgroundDefault;
  }};

  :hover {
    background-color: ${(p) => {
      if (p.disabled) return colors.grey200;
      if (p.onClick) return colors.grey300;
      return colors.backgroundDefault;
    }};
  }

  ${(p) => p.onClick && `cursor: pointer;`}
  border-radius: 50%;
`;

export const IconBtn = styled.button<{
  size?: string;
  onClick?: React.ComponentProps<"button">["onClick"];
  $fill?: string | boolean;
  stroke?: string | boolean;
  iconColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  activeBackgroundColor?: string;
  hoverStroke?: string;
  pathStrokeWidth?: number;
  disabled?: boolean;
  active?: boolean;
  buttonType?: "secondary";
}>`
  display: flex;
  padding: 0.5rem;
  background-color: ${(p) => {
    if (p.disabled) return colors.backgroundDefault;
    if (p.active) {
      if (p.activeBackgroundColor) return p.activeBackgroundColor;
      return colors.surfaceSelectedLight;
    }
    if (p.backgroundColor) {
      return p.backgroundColor;
    }
    return "initial";
  }};
  border-radius: ${borderRadiusMedium};
  ${(p) => p.onClick && `cursor: pointer;`}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    ${({ hoverStroke }) => hoverStroke && addSvgStrokeOrFill(hoverStroke)}

    background-color: ${(p) => {
      if (p.hoverBackgroundColor) {
        return p.hoverBackgroundColor;
      }
      if (p.disabled) return colors.backgroundDefault;
      if (p.active) return colors.surfaceSelectedHover;
      return colors.surfaceHover;
    }};
  }

  ${({ buttonType }) =>
    buttonType === "secondary" &&
    `
    background: ${colors.surfaceButtonSecondary};
    &:hover {
      background: ${colors.indigo100};
    }
    &:active {
      background: ${colors.indigo200};
    }
  `}

  p {
    ${(p) => {
      if (p.size) return `line-height: ${p.size};`;
    }}
    margin-left: 0.5rem;
  }

  svg {
    ${(p) => {
      if (p.size) return `width: ${p.size}; height: ${p.size};`;
    }}
    overflow: initial;
    transition:
      width 0.3s,
      height 0.3s;

    ${(p) => {
      if (p.pathStrokeWidth) {
        return `stroke-width: ${p.pathStrokeWidth};`;
      }
    }}
  }

  ${(p) => {
    if (p.active) {
      return addSvgStrokeOrFill(colors.iconSelected);
    }
    if (p.iconColor) {
      return addSvgStrokeOrFill(p.iconColor);
    }
    // if (p.iconColor) {
    //   return addSvgStrokeOrFill(p.iconColor);
    // }
  }}
`;

/**
 * Divider for {@link IconMenu}. Automatically handles horizontal and vertical
 * orientations, and auto hides if it's the first or last element in the
 * container.
 */
export const Divider = styled.div.attrs(() => ({ className: "divider" }))`
  :last-child,
  :first-child {
    display: none;
  }
`;

/**
 * Menu list of icons.
 *
 * Related:
 *  - {@link IconBtn}: Clicable icon button
 *  - {@link Divider}: Divider for the menu
 */
export const IconMenu = styled(StandardBox)<{
  vertical?: boolean;
  sideBar?: boolean;
  iconSize?: string;
}>`
  color: ${colors.textPrimary};
  typography: ${typography.sub2};
  display: flex;
  flex-direction: ${(p) => (p.vertical ? "column" : "row")};
  align-items: center;
  gap: ${(p) =>
    p.sideBar ? (p.vertical ? `${spacing6}` : `${spacing3}`) : "0"};
  background: ${colors.backgroundDefault};
  border-radius: ${borderRadiusMedium};
  height: ${(p) => (p.vertical ? "auto" : p.sideBar ? "6rem" : "auto")};
  padding: ${(p) =>
    p.sideBar
      ? p.vertical
        ? `${spaceLarge} ${spaceTiny}`
        : `${spaceTiny} ${spaceLarge}`
      : `0 ${spaceMedium} 0 ${spaceTiny}`};
  &:empty {
    display: none;
  }

  * > ${IconBtn}, * > ${Icon} {
    svg {
      ${(p) => {
        if (p.iconSize) return `width: ${p.iconSize}; height: ${p.iconSize};`;
      }}
    }
  }

  .divider {
    align-self: stretch;
    ${(p) =>
      p.sideBar
        ? p.vertical
          ? `margin: calc(${spaceSmall} - 1px) ${spaceTiny};`
          : `margin: ${spaceTiny} calc(${spaceSmall} - 1px);`
        : p.vertical
          ? `margin: ${spaceMedium} 0;`
          : `margin: 0 ${spaceMedium} ;`}
    ${(p) =>
      p.vertical
        ? `border-bottom 1px solid ${colors.inputOutline};`
        : `border-right 1px solid ${colors.inputOutline};`}
  }
`;

export const TypeDot = styled.div<{ dotColor: string }>`
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: ${({ dotColor }) => dotColor};
`;

export const TypeLineDot = styled.div<{ dotColor: string }>`
  width: 12px;
  height: 2px;
  background-color: ${({ dotColor }) => dotColor};
  border-radius: 2px;
  transform: rotate(310deg);
  transform-origin: center;
`;

export const TypeAreaDot = styled.div<{ dotColor: string }>`
  width: 12px;
  height: 5px;
  background-color: ${({ dotColor }) => dotColor};
  border-radius: 5px;
  transform: rotate(310deg);
  transform-origin: center;
`;
