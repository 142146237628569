import { useAtomValue } from "jotai";
import styled from "styled-components";
import {
  DEFAULT_ONSHORE_OFFSHORE_TURBINES,
  TurbineType,
} from "../../types/turbines";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import DefaultTurbineSettingsView from "./TurbineSettings/DefaultTurbineSettingsView";
import SimpleTurbineSettingsView from "./TurbineSettings/SimpleTurbineSettingsView";
import {
  TurbineSettingAdvancedLibraryManage,
  TurbineSettingAdvancedProjectAdmin,
} from "./TurbineSettings/TurbineSettingCustomAdmin";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 100%;
  width: 100%;
`;

const TurbineSettingsWrapper = ErrorBoundaryWrapper(
  ({
    turbineId,
    onSave,
    duplicate,
    nodeId,
    isLibraryTurbine,
    accessToAdvancedTurbine,
  }: {
    turbineId: string;
    onSave?: (
      turbine: TurbineType,
      onlyUpdatingDescription?: boolean,
    ) => Promise<any>;
    duplicate?: (turbineId: string) => void;
    nodeId: string;
    isLibraryTurbine: boolean;
    accessToAdvancedTurbine: boolean;
  }) => {
    const simpleTurbineTypes = useAtomValue(simpleTurbineTypesAtom);

    const defaultTurbine = DEFAULT_ONSHORE_OFFSHORE_TURBINES.find(
      (t) => t.id === turbineId,
    );

    if (defaultTurbine)
      return (
        <DefaultTurbineSettingsView
          turbine={defaultTurbine}
          duplicate={duplicate}
        />
      );
    else if (isLibraryTurbine && accessToAdvancedTurbine)
      return (
        <TurbineSettingAdvancedLibraryManage
          turbineId={turbineId}
          nodeId={nodeId}
        />
      );
    else if (accessToAdvancedTurbine)
      return (
        <TurbineSettingAdvancedProjectAdmin
          turbineId={turbineId}
          onSave={(turbine, onlyUpdatingDescription = false) =>
            onSave?.(turbine, onlyUpdatingDescription) ?? Promise.resolve()
          }
          nodeId={nodeId}
        />
      );
    else {
      const turbine = simpleTurbineTypes.get(turbineId);
      if (!turbine) return null;
      return (
        <SimpleTurbineSettingsView
          turbine={turbine}
          isLibraryTurbine={isLibraryTurbine}
        />
      );
    }
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);

export default TurbineSettingsWrapper;
