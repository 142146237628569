import { Dispatch, SetStateAction, useCallback } from "react";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { z } from "zod";
import { useAtom } from "jotai";

const INIT_SYMBOL = Symbol("init");
const hiddenFamily = atomFamily((key: string) =>
  atomLocalStorage(key, INIT_SYMBOL, z.any()),
);

/** @deprecated: This hook offers no type safety. */
export function useLocalStorage<T>(
  key: string,
  defaultValue: T | undefined = undefined,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [val, set] = useAtom(hiddenFamily(key));

  const setValWithDefaultSafe = useCallback(
    (newVal: SetStateAction<T | undefined>) => {
      return set((oldVal: T) => {
        const oldValWithDefault =
          oldVal === INIT_SYMBOL ? defaultValue : oldVal;

        const finalVal =
          typeof newVal === "function"
            ? (newVal as Function)(oldValWithDefault)
            : newVal;
        return finalVal;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [set],
  );

  const returnValue = val === INIT_SYMBOL ? defaultValue : val;
  return [returnValue, setValWithDefaultSafe] as [
    T | undefined,
    Dispatch<SetStateAction<T | undefined>>,
  ];
}
