/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const Type = z.union([
      z.literal("folder"),
      z.literal("personal_folder"),
      z.literal("project"),
      z.literal("organisation"),
    ]);
    export const OrganisationResources = z.union([
      z.literal("org_turbine_manage"),
      z.literal("org_foundation_manage"),
      z.literal("org_cable_manage"),
      z.literal("org_export_cable_manage"),
      z.literal("org_analysis_manage"),
      z.literal("org_financial_manage"),
      z.literal("org_data_package_manage"),
      z.literal("org_substation_manage"),
    ]);
    export const SharedNodeMutable = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: Type.optional(),
      })
      .passthrough();
    export const SharedNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      );
    export const SharedNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough());
    export const PersonalFolderNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("personal_folder"),
          })
          .passthrough(),
      );
    export const PersonalFolderNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("personal_folder").optional(),
          })
          .passthrough(),
      );
    export const FolderNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("folder"),
          })
          .passthrough(),
      );
    export const FolderNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("folder").optional(),
          })
          .passthrough(),
      );
    export const OrganisationNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            type: z.literal("organisation"),
          })
          .passthrough(),
      );
    export const OrganisationNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("organisation").optional(),
          })
          .passthrough(),
      );
    export const Project = z
      .object({
        location: z.string().nullable().optional(),
        status: z.string().nullable().optional(),
        tutorial_id: z.string().nullable().optional(),
        main_branch_id: z.string().nullable().optional(),
      })
      .passthrough();
    export const ProjectNodeInformation = z
      .object({
        name: z.string(),
        parent_id: z.string().nullable().optional(),
        type: z.union([
          z.literal("folder"),
          z.literal("personal_folder"),
          z.literal("project"),
          z.literal("organisation"),
        ]),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string(),
            created_at: z.number(),
            archived_at: z.number().nullable().optional(),
            author: z.string(),
          })
          .passthrough(),
      )
      .and(z.object({}).passthrough())
      .and(
        z
          .object({
            location: z.string().nullable().optional(),
            status: z.string().nullable().optional(),
            tutorial_id: z.string().nullable().optional(),
            main_branch_id: z.string().nullable().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("project"),
          })
          .passthrough(),
      );
    export const ProjectNodeInformation_ = z
      .object({
        name: z.string().optional(),
        parent_id: z.string().nullable().optional(),
        type: z
          .union([
            z.literal("folder"),
            z.literal("personal_folder"),
            z.literal("project"),
            z.literal("organisation"),
          ])
          .optional(),
      })
      .passthrough()
      .and(
        z
          .object({
            id: z.string().optional(),
            organisation_id: z.string().nullable().optional(),
            legacy_id: z.string().optional(),
            created_at: z.number().optional(),
            archived_at: z.number().nullable().optional(),
            author: z.string().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            location: z.string().nullable().optional(),
            status: z.string().nullable().optional(),
            tutorial_id: z.string().nullable().optional(),
            main_branch_id: z.string().nullable().optional(),
          })
          .passthrough(),
      )
      .and(
        z
          .object({
            type: z.literal("project").optional(),
          })
          .passthrough(),
      );
    export const NodeInformation = z.union([
      ProjectNodeInformation,
      PersonalFolderNodeInformation,
      FolderNodeInformation,
      OrganisationNodeInformation,
    ]);
    export const NodeInformation_ = z.union([
      ProjectNodeInformation_,
      PersonalFolderNodeInformation_,
      FolderNodeInformation_,
      OrganisationNodeInformation_,
    ]);
    export const Group = z
      .object({
        id: z.string(),
        name: z.string(),
        author: z.string(),
        organisation_id: z.string(),
        created_at: z.number(),
        is_user: z.boolean(),
      })
      .passthrough();
    export const PersonalOrgResource = z
      .object({
        resource_name: OrganisationResources,
        user_id: z.string(),
        type: z.literal("personal"),
      })
      .passthrough();
    export const GroupOrgResource = z
      .object({
        resource_name: OrganisationResources,
        group_name: z.string(),
        group_id: z.string(),
        type: z.literal("group"),
      })
      .passthrough();
    export const OrgResource = z.union([GroupOrgResource, PersonalOrgResource]);
    export const OrgFeatureAccess = z.union([
      z.literal("library_all"),
      z.literal("granular_access"),
    ]);
    export const SetNodeLibraryResourceBody = z
      .object({
        resource_id: z.string(),
      })
      .passthrough();
    export const LibraryManagerBody = z
      .object({
        resource_name: OrganisationResources,
        id: z.string(),
        type: z.union([z.literal("group"), z.literal("user")]),
      })
      .passthrough();
    export const LibraryResourceMigrationType = z.union([
      z.literal("FOUNDATION"),
      z.literal("TURBINE"),
    ]);
    export const LibraryResourceType = z.union([
      z.literal("FOUNDATION"),
      z.literal("TURBINE"),
      z.literal("CABLE"),
      z.literal("EXPORT_CABLE"),
      z.literal("ANALYSIS"),
      z.literal("FINANCIAL"),
      z.literal("DATAPACKAGE"),
      z.literal("SUBSTATION"),
    ]);
    export const NodeLibraryResource = z
      .object({
        resource_id: z.string(),
        node_information_id: z.string(),
        author: z.string(),
        type: LibraryResourceType,
      })
      .passthrough();
    export const SimplifiedFoundationResourceV2 = z
      .object({
        nodeId: z.string(),
        foundation: z.object({}).passthrough(),
      })
      .passthrough();
    export const SimplifiedFoundationResource = z.object({}).passthrough();
    export const SimplifiedTurbineResource = z
      .object({
        name: z.string(),
        id: z.string(),
        note: z.string().nullable().optional(),
        hubHeight: z.number(),
        rnaMass: z.number(),
        diameter: z.number(),
        ratedPower: z.number().optional(),
        peakThrustSpeed: z.number().optional(),
        peakThrustCt: z.number().nullable().optional(),
        cost: z.number().optional(),
        costUnit: z.string().optional(),
        archived: z.boolean().optional(),
        voltage: z.array(z.number()).optional(),
      })
      .passthrough();
    export const SimplifiedTurbineResourceV2 = z
      .object({
        nodeId: z.string(),
        turbine: SimplifiedTurbineResource,
      })
      .passthrough();
    export const SimplifiedResource = z
      .object({
        nodeId: z.string(),
        resource: z
          .object({
            id: z.string(),
          })
          .passthrough(),
      })
      .passthrough();
    export const SimplifiedCableResource = z
      .object({
        nodeId: z.string(),
        cable: z.object({}).passthrough(),
      })
      .passthrough();
    export const SimplifiedDataPackageResource = z
      .object({
        nodeId: z.string(),
        config: z.object({}).passthrough(),
      })
      .passthrough();
    export const UploadedVectorDataLayer = z
      .object({
        url: z.string(),
        name: z.string(),
        id: z.string(),
        path: z.string(),
        source: z.string(),
        bbox: z.string().optional(),
        author: z.string().optional(),
        created_at: z.number().optional(),
        description: z.string().nullable().optional(),
      })
      .strict();
    export const MigrateResourceType = z.union([
      z.literal("project"),
      z.literal("organisation"),
    ]);
    export const SimplifiedAnalysisResource = z
      .object({
        nodeId: z.string(),
        config: z.object({}).passthrough(),
      })
      .passthrough();
    export const SimplifiedFinancialResource = z
      .object({
        nodeId: z.string(),
        config: z.object({}).passthrough(),
      })
      .passthrough();
    export const LibraryResource = z
      .object({
        resource_id: z.string(),
        node_id: z.string(),
        type: LibraryResourceType,
      })
      .passthrough();
    export const MigrateResourceTriggerBody = z
      .object({
        resourceType: LibraryResourceMigrationType,
        teamNodeId: z.string(),
        migrateType: MigrateResourceType,
        resourceId: z.string(),
      })
      .passthrough();
    export const MigrateResourceAvailableBody = z
      .object({
        resourceType: LibraryResourceMigrationType,
        teamNodeId: z.string(),
        resourceId: z.string(),
        resourceAuthor: z.string().optional(),
        resourceName: z.string(),
        teamName: z.string(),
        isMigrating: z.boolean(),
        usages: z.array(z.string()),
      })
      .passthrough();
    export const MigrateResourceGet = z
      .object({
        resources: z.array(MigrateResourceAvailableBody),
        nodes: z.array(NodeInformation),
      })
      .passthrough();
    export const PartialCableType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        /** @Deprecated */
        powerRating: z.number().optional(),
        /** @Deprecated */
        costPerKilometer: z.number().optional(),
        voltage: z.number().optional().default(66),
        reactance: z.number().optional(),
        capacitance: z.number().optional(),
        resistance: z.number().optional(),
        ampacity: z.number().optional(),
        note: z.string().optional(),
        cost: z.number().optional().default(0),
        costUnit: z.string().optional().default("m€/km"),
        /** If the cable is an export cable, this field should be set to either alternative. If it is an inter-array cable, it should not be set. */
        exportCableType: z
          .union([z.literal("HVAC"), z.literal("HVDC")])
          .optional(),
      })
      .passthrough();
    export const PartialSubstationType = z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        cost: z.number().optional(),
        costUnit: z
          .union([z.literal("m€/unit"), z.literal("k€/MW")])
          .optional(),
        type: z.union([z.literal("onshore"), z.literal("offshore")]).optional(),
        impedance: z.number().optional(),
        resistance: z.number().optional(),
        ironLosses: z.number().optional(),
        noLoadCurrent: z.number().optional(),
        reactor: z.boolean().optional(),
        compensationShare: z.number().optional(),
        dcResistance: z.number().optional().default(0.003),
        dcIronLosses: z.number().optional().default(0.00025),
        lossFactorA: z.number().optional().default(0.00035),
        lossFactorB: z.number().optional().default(0.004),
        lossFactorC: z.number().optional().default(0.0027),
        note: z.string().optional(),
      })
      .passthrough();
    export const LibraryManageRole = z.union([
      z.literal("org_turbine_manage"),
      z.literal("org_foundation_manage"),
      z.literal("org_cable_manage"),
      z.literal("org_export_cable_manage"),
      z.literal("org_analysis_manage"),
      z.literal("org_financial_manage"),
      z.literal("org_data_package_manage"),
      z.literal("org_substation_manage"),
    ]);
    export const SimplifiedSubstationResource = z
      .object({
        nodeId: z.string(),
        substation: z.object({}).passthrough(),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    Type: Components.Schemas.Type,
    OrganisationResources: Components.Schemas.OrganisationResources,
    SharedNodeMutable: Components.Schemas.SharedNodeMutable,
    SharedNodeInformation_: Components.Schemas.SharedNodeInformation_,
    SharedNodeInformation: Components.Schemas.SharedNodeInformation,
    PersonalFolderNodeInformation:
      Components.Schemas.PersonalFolderNodeInformation,
    PersonalFolderNodeInformation_:
      Components.Schemas.PersonalFolderNodeInformation_,
    FolderNodeInformation: Components.Schemas.FolderNodeInformation,
    FolderNodeInformation_: Components.Schemas.FolderNodeInformation_,
    OrganisationNodeInformation: Components.Schemas.OrganisationNodeInformation,
    OrganisationNodeInformation_:
      Components.Schemas.OrganisationNodeInformation_,
    Project: Components.Schemas.Project,
    ProjectNodeInformation: Components.Schemas.ProjectNodeInformation,
    ProjectNodeInformation_: Components.Schemas.ProjectNodeInformation_,
    NodeInformation: Components.Schemas.NodeInformation,
    NodeInformation_: Components.Schemas.NodeInformation_,
    Group: Components.Schemas.Group,
    PersonalOrgResource: Components.Schemas.PersonalOrgResource,
    GroupOrgResource: Components.Schemas.GroupOrgResource,
    OrgResource: Components.Schemas.OrgResource,
    OrgFeatureAccess: Components.Schemas.OrgFeatureAccess,
    SetNodeLibraryResourceBody: Components.Schemas.SetNodeLibraryResourceBody,
    LibraryManagerBody: Components.Schemas.LibraryManagerBody,
    LibraryResourceMigrationType:
      Components.Schemas.LibraryResourceMigrationType,
    LibraryResourceType: Components.Schemas.LibraryResourceType,
    NodeLibraryResource: Components.Schemas.NodeLibraryResource,
    SimplifiedFoundationResourceV2:
      Components.Schemas.SimplifiedFoundationResourceV2,
    SimplifiedFoundationResource:
      Components.Schemas.SimplifiedFoundationResource,
    SimplifiedTurbineResource: Components.Schemas.SimplifiedTurbineResource,
    SimplifiedTurbineResourceV2: Components.Schemas.SimplifiedTurbineResourceV2,
    SimplifiedResource: Components.Schemas.SimplifiedResource,
    SimplifiedCableResource: Components.Schemas.SimplifiedCableResource,
    SimplifiedDataPackageResource:
      Components.Schemas.SimplifiedDataPackageResource,
    UploadedVectorDataLayer: Components.Schemas.UploadedVectorDataLayer,
    MigrateResourceType: Components.Schemas.MigrateResourceType,
    SimplifiedAnalysisResource: Components.Schemas.SimplifiedAnalysisResource,
    SimplifiedFinancialResource: Components.Schemas.SimplifiedFinancialResource,
    LibraryResource: Components.Schemas.LibraryResource,
    MigrateResourceTriggerBody: Components.Schemas.MigrateResourceTriggerBody,
    MigrateResourceAvailableBody:
      Components.Schemas.MigrateResourceAvailableBody,
    MigrateResourceGet: Components.Schemas.MigrateResourceGet,
    PartialCableType: Components.Schemas.PartialCableType,
    PartialSubstationType: Components.Schemas.PartialSubstationType,
    LibraryManageRole: Components.Schemas.LibraryManageRole,
    SimplifiedSubstationResource:
      Components.Schemas.SimplifiedSubstationResource,
  },
};

export const paths = {
  "/api/library/libraryaccess/organisation/{organisationId}/managers/role/admin{libraryManageRoleRequested}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          libraryManageRoleRequested: Components.Schemas.LibraryManageRole,
        }),
        responses: {
          200: {
            /** org resources */
            "application/json": z.array(Components.Schemas.OrgResource),
          },
          400: {
            /** Access denied */
            "application/json": z.string(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/organisation/{organisationId}/managers/role/{libraryManageRole}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          libraryManageRole: Components.Schemas.LibraryManageRole,
        }),
        responses: {
          200: {
            /** org resources */
            "application/json": z.array(Components.Schemas.OrgResource),
          },
          400: {
            /** Access denied */
            "application/json": z.string(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/organisation/{organisationId}/role/{libraryManageRole}/{resourceId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          resourceId: z.string(),
          libraryManageRole: Components.Schemas.LibraryManageRole,
        }),
        responses: {
          200: {
            /** node-resource access objects */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          400: {
            /** Access denied */
            "application/json": z.string(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/node/{nodeId}/role/{libraryManageRole}/{resourceId}":
    {
      delete: {
        pathParams: z.object({
          nodeId: z.string(),
          resourceId: z.string(),
          libraryManageRole: Components.Schemas.LibraryManageRole,
        }),
        responses: {
          200: {
            /** Updated node library access */
            "application/json": z.array(Components.Schemas.NodeLibraryResource),
          },
          400: {
            /** Access denied */
            "application/json": z.string(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/node/{nodeId}/viewer/role/{libraryManageRoleRequested}":
    {
      get: {
        pathParams: z.object({
          nodeId: z.string(),
          libraryManageRoleRequested: Components.Schemas.LibraryManageRole,
        }),
        responses: {
          200: {
            /** node library access */
            "application/json": z.array(Components.Schemas.SimplifiedResource),
          },
          400: {
            /** Access denied */
            "application/json": z.string(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/node/{nodeId}/role/{libraryManageRole}": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
        libraryManageRole: Components.Schemas.LibraryManageRole,
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(Components.Schemas.SimplifiedResource),
        },
        400: {
          /** Access denied */
          "application/json": z.string(),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
    put: {
      pathParams: z.object({
        nodeId: z.string(),
        libraryManageRole: Components.Schemas.LibraryManageRole,
      }),
      /** Resources to add */
      requestBody: {
        "application/json": Components.Schemas.SetNodeLibraryResourceBody,
      },
      responses: {
        200: {
          /** Set node library access */
          "application/json": z.array(Components.Schemas.NodeLibraryResource),
        },
        400: {
          /** Access denied */
          "application/json": z.string(),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/financial": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Get node library financial access */
          "application/json": z.array(
            Components.Schemas.SimplifiedFinancialResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/analysis": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Get node library analysis access */
          "application/json": z.array(
            Components.Schemas.SimplifiedAnalysisResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/turbine/v2": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.SimplifiedTurbineResourceV2,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/foundation/v2": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.SimplifiedFoundationResourceV2,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/organisation/{organisationId}/manager": {
    put: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** managers to add */
      requestBody: {
        "application/json": Components.Schemas.LibraryManagerBody,
      },
      responses: {
        200: {
          /** org resources */
          "application/json": Components.Schemas.OrgResource,
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/organisation/{organisationId}/{resourceType}/manager/user/{userId}":
    {
      delete: {
        pathParams: z.object({
          organisationId: z.string(),
          userId: z.string(),
          resourceType: z.string(),
        }),
        responses: {
          204: {
            /** org resources */
            "application/json": z.object({}).passthrough(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/organisation/{organisationId}/{resourceType}/manager/group/{groupId}":
    {
      delete: {
        pathParams: z.object({
          organisationId: z.string(),
          groupId: z.string(),
          resourceType: z.string(),
        }),
        responses: {
          204: {
            /** org resources */
            "application/json": z.object({}).passthrough(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/libraryaccess/node/{nodeId}/cable": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.SimplifiedCableResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/exportcable": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.SimplifiedCableResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/datalayer": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.UploadedVectorDataLayer,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/usage/datalayer/organisation/{organisationId}/package/{packageId}":
    {
      get: {
        pathParams: z.object({
          organisationId: z.string(),
          packageId: z.string(),
        }),
        responses: {
          200: {
            /** data package project usage */
            "application/json": z.object({}).passthrough(),
          },
          403: {
            /** Access denied */
            "application/json": z.string(),
          },
          404: {
            /** Not found */
            "application/json": z.string(),
          },
          500: {
            /** Something went wrong */
            "application/json": z
              .object({
                error: z.string().optional(),
              })
              .passthrough(),
          },
        },
      },
    },
  "/api/library/usage/datalayer/node/{nodeId}/layer": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      /** Resources to show usage for */
      requestBody: {
        "application/json": z.array(z.string()),
      },
      responses: {
        200: {
          /** data layers project usage. Map of layerId to list of projectIds using the layer */
          "application/json": z.object({}).and(z.record(z.array(z.string()))),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        404: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/usage/datalayer/node/{nodeId}/resource/{resourceId}": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
        resourceId: z.string(),
      }),
      /** Resources to show usage for */
      requestBody: {
        "application/json": z.array(z.string()),
      },
      responses: {
        200: {
          /** data layers project usage */
          "application/json": z.object({}).passthrough(),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/datapackage": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** node library access */
          "application/json": z.array(
            Components.Schemas.SimplifiedDataPackageResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/api/library/libraryaccess/node/{nodeId}/substation": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Successful response */
          "application/json": z.array(
            Components.Schemas.SimplifiedSubstationResource,
          ),
        },
        403: {
          /** Access denied */
          "application/json": z.string(),
        },
        500: {
          /** Something went wrong */
          "application/json": z
            .object({
              error: z.string().optional(),
            })
            .passthrough(),
        },
      },
    },
  },
  "/invoke/library-prod-invokeNodeResourceAccess": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            types: z.array(Components.Schemas.LibraryResourceType),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** List of resources that node has access to */
          "application/json": z.array(Components.Schemas.NodeLibraryResource),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
