import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { typography } from "styles/typography";

export const EntryHeader = styled.p`
  ${typography.caption}
  font-weight: 400;
`;

export const DateText = styled.p`
  ${typography.caption}
  color: ${colors.textSecondary};
`;
