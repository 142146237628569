import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { Input } from "components/General/Input";
import { ModalFrame, Column, Row } from "components/General/Layout";
import { useState, useCallback, useEffect } from "react";
import { modalTypeOpenAtom } from "state/modal";
import { useCreateArchivedVersions } from "../useArchivedVersions";
import { useToast } from "hooks/useToast";
import Dropdown from "components/Dropdown/Dropdown";
import Tooltip from "components/General/Tooltip";
import { useAtomValue, useSetAtom } from "jotai";
import { parksFamily } from "state/jotai/park";
import { ParkFeature } from "types/feature";
import useNavigateToPark from "hooks/useNavigateToPark";
import { TriggerCurrentFinance } from "components/Finance/Triggers";
import { getStoppedReason } from "analysis/warnings";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import { loadable, unwrap } from "jotai/utils";
import { getAnalysisResponse } from "analysis/output";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { analysisStoppedText } from "analysis/warnings";
import Spinner from "@icons/spinner/Spinner";
import { useCheckAccessToken } from "hooks/useRefreshTokenBeforeExpiration";
import { AnalysisConfigNotFoundErrorBoundaryFallbackRender } from "components/ProductionV2/ErrorUtils";
import { ErrorBoundary } from "react-error-boundary";

export const NewArchivedVersionModalTypeName = "NewArchivedVersionModal";
export type NewArchivedVersionModalType = {
  modalType: typeof NewArchivedVersionModalTypeName;
  metadata: {
    organisationId: string;
    projectId: string;
    branchId: string;
    parkId: string;
  };
};

const NewArchivedProjectVersionModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);
  if (modalTypeOpen?.modalType !== NewArchivedVersionModalTypeName) return null;

  return <NewArchivedProjectVersionModalInner {...modalTypeOpen.metadata} />;
};

const ParkSelector = ({
  parks,
  parkId,
  setParkId,
}: {
  parks: ParkFeature[];
  parkId: string;
  setParkId: (id: string) => void;
}) => {
  const { navigateToPark } = useNavigateToPark();
  return (
    <Label style={{ width: "100%" }}>
      <p>Park:</p>
      <Dropdown
        onChange={(event) => {
          navigateToPark(event.target.value);
          setParkId(event.target.value);
        }}
        value={parkId}
      >
        <option value={"undefined"}>---------</option>
        {parks.map((p) => (
          <option key={p.id} value={p.id}>
            {p.properties.name}
          </option>
        ))}
      </Dropdown>
    </Label>
  );
};

const ArchiveForm = ({
  name,
  setName,
  description,
  setDescription,
  onSave,
  onCancel,
  disableExport,
}: {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  onSave: () => void;
  onCancel: () => void;
  disableExport: boolean;
}) => {
  const analysis = useAtomValue(
    loadable(getAnalysisResponse(currentSelectionProduction)),
  );
  const stoppedReason = useAtomValue(
    unwrap(getStoppedReason(currentSelectionProduction)),
  );
  const hasErrorsOrLoading = analysis.state !== "hasData" || !!stoppedReason;

  return (
    <>
      {hasErrorsOrLoading && (
        <Column>
          {analysis.state === "loading" && (
            <Row style={{ alignItems: "center", gap: "1rem" }}>
              <Spinner size="1.2rem" />
              <SimpleAlert text="Checking for errors..." type="info" />
            </Row>
          )}

          {stoppedReason && (
            <SimpleAlert
              text={analysisStoppedText[stoppedReason]}
              type="error"
            />
          )}
          {analysis.state === "hasError" && (
            <SimpleAlert
              text={
                "An error occurred during analysis and the park could not be exported."
              }
              type="error"
            />
          )}
        </Column>
      )}

      {analysis.state === "hasData" && (
        <>
          <Label style={{ width: "100%" }}>
            <p>Name:</p>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: "100%" }}
            />
          </Label>
          <Label style={{ width: "100%" }}>
            <p>Description:</p>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ width: "100%" }}
            />
          </Label>
          <Row style={{ marginLeft: "auto" }}>
            <Button buttonType="secondary" text="Cancel" onClick={onCancel} />
            <Tooltip
              text={disableExport ? "Name is required" : ""}
              disabled={!disableExport}
            >
              <Button
                text="Save"
                onClick={onSave}
                disabled={
                  disableExport ||
                  analysis.state !== "hasData" ||
                  !!stoppedReason
                }
              />
            </Tooltip>
          </Row>
        </>
      )}
    </>
  );
};

const NewArchivedProjectVersionModalInner = ({
  organisationId,
  projectId,
  branchId,
  parkId: initialParkId,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
}) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const parks = useAtomValue(parksFamily({ branchId }));
  const [parkId, setParkId] = useState<string>(initialParkId || "undefined");
  const { create } = useCreateArchivedVersions();
  const { info } = useToast();

  // Refresh access token to ensure it's has 10 minutes left before expiry
  // This token is used to create the archived version in the backend
  // and needs to be refreshed to increase the chance of it still being valid
  // throughout the process
  const refreshAccessToken = useCheckAccessToken();
  useEffect(() => {
    refreshAccessToken(true);
  }, [refreshAccessToken]);

  const onSave = useCallback(async () => {
    create(organisationId, projectId, branchId, parkId, name, description);
    info("Creating new project data save...");
    setModalTypeOpen(undefined);
  }, [
    organisationId,
    projectId,
    branchId,
    parkId,
    name,
    description,
    create,
    info,
    setModalTypeOpen,
  ]);

  const onCancel = () => setModalTypeOpen(undefined);
  const disableExport = name === "";

  return (
    <FullScreenModal>
      <ModalFrame title="New project data export">
        <ErrorBoundary
          fallbackRender={AnalysisConfigNotFoundErrorBoundaryFallbackRender}
        >
          <Column style={{ gap: "2.4rem", alignItems: "center" }}>
            <ParkSelector parks={parks} parkId={parkId} setParkId={setParkId} />
            {parkId !== "undefined" && (
              <TriggerCurrentFinance
                fallback={
                  <Row style={{ alignItems: "center", gap: "1rem" }}>
                    <Spinner size="1.2rem" />
                    <SimpleAlert text="Analysis in progress..." type="info" />
                  </Row>
                }
                projectId={projectId}
                branchId={branchId}
                parkId={parkId}
              >
                <ArchiveForm
                  name={name}
                  setName={setName}
                  description={description}
                  setDescription={setDescription}
                  onSave={onSave}
                  onCancel={onCancel}
                  disableExport={disableExport}
                />
              </TriggerCurrentFinance>
            )}
          </Column>
        </ErrorBoundary>
      </ModalFrame>
    </FullScreenModal>
  );
};

export default NewArchivedProjectVersionModal;
