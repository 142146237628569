import { useJotaiCallback } from "utils/jotai";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  EXCLUSION_ZONE_COLOR,
} from "@constants/division";
import { zoneLayerSource } from "@constants/zones";
import { showArticle } from "components/HelpTooltip/HelpTooltip";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { useAtomValue } from "jotai";
import { FitBoundsOptions, Map } from "mapbox-gl";
import { MIN_CABLE_LENGTH_KM } from "state/cable";
import { parksFamily } from "state/jotai/park";
import { currentSelectionArrayAtom } from "state/selection";
import {
  ValidationWarningOrganisationStateType,
  ValidationWarningStateType,
} from "state/validationWarnings";
import { colors } from "styles/colors";
import { ProjectFeature, exclusionDomainPack } from "types/feature";
import { v4 as uuid4 } from "uuid";
import Button from "../General/Button";
import { MAX_RELATIVE_TURBINE_DATA_ERROR } from "./TurbineAndMonopileTypeError";
import { featuresFamily } from "state/jotai/features";
import { branchIdAtom } from "state/pathParams";
import { unkinkFeature } from "utils/gdal";
import { MultiPolygon, Polygon, Position } from "geojson";
import { unionAll } from "utils/turf";
import { isDefined } from "utils/predicates";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { goToCoordinates } from "hooks/map";
import { Row } from "components/General/Layout";
import { useEffect, useState } from "react";

export enum ValidationSeverity {
  // NOTE: these needs to be in increasing severity
  Warning = 0,
  Error,
  Fatal,
  AblyLost,
}

export enum ValidationWarningTypes {
  ProjectSize = "project_size",
  TurbineOverlap = "turbine_overlap",
  SelfIntersectingPolygon = "invalid_polygon",
  InvalidFeatures = "invalid_features",
  ExistingTurbineOverlap = "existing_turbine_overlap",
  CableTypeInvalid = "cable_type_invalid",
  TurbineTypeInvalid = "turbine_type_invalid",
  FoundationTypeInvalid = "foundation_type_invalid",
  SubstationTypeInvalid = "substation_type_invalid",
  TurbineOutsidePark = "turbine_outside_park",
  TurbinesNotReachable = "turbines_not_reachable",
  CableInsideNoCableExclusionZone = "cable_inside_no_cable_exclusion_zone",
  TurbineInsideNoTurbineExclusionZone = "turbine_inside_no_turbine_exclusion_zone",
  SubstationInsideNoSubstationExclusionZone = "substation_inside_no_substation_exclusion_zone",
  SubstationInsideNoCableExclusionZone = "substation_inside_no_cable_exclusion_zone",
  SubstationOverlap = "substation_overlap",
  OffshoreSubstationOutsideParkCableCorridor = "offshore_substation_outside_park_cable_corridor",
  OverlappingSubAreas = "overlapping_sub_areas",
  SubAreasOutsidePark = "sub_areas_outside_park",
  CableCorridorNotConnectedToPark = "cable_corridor_not_connected_to_park",
  OverlappingParks = "overlapping_parks",
  HoleInPark = "hole_in_park",
  AnchorsOutsidePark = "anchors_outside_park",
  CablesDifferentIAVoltageTypes = "different_IAvoltage_types",
  CableDifferentExportVoltageTypes = "different_export_voltage_types",
  TurbinesAndCablesErroneousIAVoltageTypes = "turbines_and_cables_erroneous_IAvoltage_types",
  TurbineNotConnected = "turbine_not_connected",
  SubstationNotConnected = "substation_not_connected",
  CableNotConnected = "cable_not_connected",
  SubstationMissingType = "substation_missing_type",
  ExportCableNotConnected = "export_cable_not_connected",
  ExportCableTooLongWarning = "export_cable_too_long_warning",
  ExportCableTooLongError = "export_cable_too_long_error",
  TooShortCables = "too_short_cables",
  TurbineAndMonopileTypeError = "turbine_and_monopile_type_error",
  CablesCross = "cables_cross",
  FatalGeneral = "fatal_general",
  FatalAblyLost = "critical_ably_lost",
  ExportCableLandfallFailed = "export_cable_landfall_failed",
  ExistingTurbinesWithoutPower = "existing_turbines_without_power",
  ParkMultiPolygons = "park_multi_polygons",
  ExportSystemInsufficientCapacity = "export_system_insufficient_capacity",
  InterArrayInsufficientCapacity = "inter_array_insufficient_capacity",
  CableIntersectsSector = "cable_intersects_sector",
  CableLoops = "cable_loops",
  ExportCableDifferentTypes = "export_cable_different_types",
  ExportCableDifferentTypesInCable = "export_cable_different_types_in_cable",
  ExportSystemWillNotConverge = "export_system_will_not_converge",
}

const RemoveParkHolesButton = ({
  featureIds,
  text = "Remove holes",
}: {
  featureIds: string[];
  text?: string;
}) => {
  const parks = useAtomValue(
    parksFamily({
      branchId: undefined,
    }),
  );

  const { update: updateFeatures } = useProjectElementsCrud();
  const removeHoles = useJotaiCallback(async () => {
    const parksWithHoles = parks.filter((p) => featureIds.includes(p.id));
    const parkWithoutHoles = parksWithHoles.map((p) => ({
      ...p,
      geometry: {
        ...p.geometry,
        coordinates: [p.geometry.coordinates[0]],
      },
    }));
    updateFeatures({
      update: parkWithoutHoles,
    });
  }, [featureIds, updateFeatures, parks]);
  return <Button text={text} size={"small"} onClick={removeHoles} />;
};

const ParkHolesIntoExclusionZonesButton = ({
  featureIds,
  text = "Holes into exclusion zones",
}: {
  featureIds: string[];
  text?: string;
}) => {
  const parks = useAtomValue(
    parksFamily({
      branchId: undefined,
    }),
  );

  const { update: updateFeatures } = useProjectElementsCrud();
  const holesIntoExclusionZones = useJotaiCallback(async () => {
    const parksWithHoles = parks.filter((p) => featureIds.includes(p.id));
    const parkWithoutHoles = parksWithHoles.map((p) => ({
      ...p,
      geometry: {
        ...p.geometry,
        coordinates: [p.geometry.coordinates[0]],
      },
    }));
    const newExclusionZones: ProjectFeature[] = parksWithHoles.flatMap((p) => {
      const id = uuid4();
      return p.geometry.coordinates.slice(-1).map((c) => ({
        id,
        type: "Feature",
        properties: {
          type: DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          color: EXCLUSION_ZONE_COLOR,
          source: zoneLayerSource,
          id,
          domain: exclusionDomainPack({
            turbine: true,
            cable: true,
            substation: true,
            anchor: true,
          }),
          name: "Exclusion zone",
        },
        geometry: {
          type: "Polygon",
          coordinates: [c],
        },
      }));
    });
    updateFeatures({
      add: newExclusionZones,
      update: parkWithoutHoles,
    });
  }, [featureIds, updateFeatures, parks]);
  return (
    <Button text={text} size={"small"} onClick={holesIntoExclusionZones} />
  );
};

const SelectFeaturesButton = ({
  featureIds,
  text = "Select features",
}: {
  featureIds: string[];
  text?: string;
}) => {
  const selectFeatures = useJotaiCallback(
    async (_get, set) => {
      set(currentSelectionArrayAtom, featureIds);
    },
    [featureIds],
  );
  return <Button text={text} size={"small"} onClick={selectFeatures} />;
};

const SelectFeaturesAndGoToCoordinatesButton = ({
  coordinates,
  featureIds,
  map,
}: {
  coordinates: Position[];
  featureIds: string[];
  map: Map;
  text?: string;
}) => {
  const [zoomedToIndex, setZoomedToIndex] = useState(0);
  useEffect(() => {
    setZoomedToIndex(0);
  }, [coordinates]);
  const selectFeatures = useJotaiCallback(
    async (_get, set) => {
      set(currentSelectionArrayAtom, featureIds);
      const firstIntersection = coordinates[zoomedToIndex];
      goToCoordinates([firstIntersection], map, { zoom: 18 });
      setZoomedToIndex((prev) => (prev + 1) % coordinates.length);
    },
    [featureIds, coordinates, map, zoomedToIndex],
  );

  return (
    <Button
      text={
        coordinates.length > 1
          ? `Zoom to (${zoomedToIndex + 1}/${coordinates.length})`
          : "Zoom to"
      }
      size={"small"}
      onClick={selectFeatures}
    />
  );
};

const FixSelfIntersectingPolygonsButton = ({
  featureIds,
  text,
}: {
  featureIds: string[];
  text?: string;
}) => {
  const { update } = useProjectElementsCrud();
  const { showConfirm } = useConfirm();
  const selectFeatures = useJotaiCallback(
    async (_get) => {
      if (
        !(await showConfirm({
          title: "Fix intersecting polygons",
          message:
            "This operation will modify your polygons, please make sure that the output of this operation looks correct",
        }))
      ) {
        return;
      }
      const branchId = _get(branchIdAtom) ?? "";
      const split = await _get(featuresFamily({ branchId }));
      const kinkedFeatures = Object.values(split)
        .flat()
        .filter((f) => featureIds.includes(f.id)) as ProjectFeature<
        Polygon | MultiPolygon
      >[];
      const unkinkedFeatures = (await Promise.all(
        kinkedFeatures.map((f) => unkinkFeature(f)),
      )) as ProjectFeature<Polygon | MultiPolygon>[][];

      const unkinedUnionedFeatures = unkinkedFeatures
        .map((featureList) => unionAll(featureList))
        .map((f) => {
          if (!f) return undefined;
          const id = uuid4();
          return {
            ...f,
            id: id,
            properties: {
              ...f.properties,
              id: id,
            },
          };
        })
        .filter(isDefined);

      await update({
        update: unkinedUnionedFeatures,
        remove: featureIds,
      });
    },
    [featureIds, showConfirm, update],
  );
  return (
    <Button
      buttonType="secondary"
      text={text}
      size={"small"}
      onClick={selectFeatures}
    />
  );
};

const DeleteFeaturesButton = ({
  featureIds,
  text = "Delete features",
}: {
  featureIds: string[];
  text?: string;
}) => {
  const { update } = useProjectElementsCrud();
  return (
    <Button
      text={text}
      size={"small"}
      onClick={() => update({ remove: featureIds })}
    />
  );
};

export const getOnclickHandler = (
  warn:
    | ValidationWarningOrganisationStateType
    | ValidationWarningStateType
    | undefined,
  zoomToFeature: (
    featureId: string,
    options?: FitBoundsOptions | undefined,
  ) => void,
  zoomToFeatureIds: (
    featureIds: string[],
    e?: any,
    options?: FitBoundsOptions | undefined,
    boundBuffer?: number | undefined,
  ) => void,
) => {
  if (!warn) return;

  switch (warn.type) {
    case ValidationWarningTypes.CableDifferentExportVoltageTypes:
    case ValidationWarningTypes.ExportCableNotConnected:
    case ValidationWarningTypes.AnchorsOutsidePark:
    case ValidationWarningTypes.ExistingTurbinesWithoutPower:
    case ValidationWarningTypes.ParkMultiPolygons:
    case ValidationWarningTypes.TooShortCables:
    case ValidationWarningTypes.TurbineAndMonopileTypeError:
    case ValidationWarningTypes.TurbineOverlap:
    case ValidationWarningTypes.SelfIntersectingPolygon:
    case ValidationWarningTypes.InvalidFeatures:
    case ValidationWarningTypes.ExistingTurbineOverlap:
    case ValidationWarningTypes.FoundationTypeInvalid:
    case ValidationWarningTypes.TurbinesNotReachable:
    case ValidationWarningTypes.SubstationOverlap:
    case ValidationWarningTypes.SubstationInsideNoCableExclusionZone:
    case ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone:
    case ValidationWarningTypes.CableInsideNoCableExclusionZone:
    case ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone:
    case ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor:
    case ValidationWarningTypes.CablesCross:
    case ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes:
    case ValidationWarningTypes.TurbineOutsidePark:
    case ValidationWarningTypes.TurbineNotConnected:
    case ValidationWarningTypes.TurbineTypeInvalid:
    case ValidationWarningTypes.SubstationNotConnected:
    case ValidationWarningTypes.CableNotConnected:
    case ValidationWarningTypes.SubstationMissingType:
    case ValidationWarningTypes.OverlappingSubAreas:
    case ValidationWarningTypes.SubAreasOutsidePark:
    case ValidationWarningTypes.ExportSystemInsufficientCapacity:
    case ValidationWarningTypes.InterArrayInsufficientCapacity:
    case ValidationWarningTypes.ExportCableTooLongWarning:
    case ValidationWarningTypes.ExportCableTooLongError:
    case ValidationWarningTypes.OverlappingParks:
    case ValidationWarningTypes.HoleInPark:
    case ValidationWarningTypes.CableCorridorNotConnectedToPark:
    case ValidationWarningTypes.ExportCableDifferentTypes:
    case ValidationWarningTypes.ExportCableDifferentTypesInCable:
    case ValidationWarningTypes.ExportSystemWillNotConverge:
    case ValidationWarningTypes.CableTypeInvalid:
      const zoomToFeaturesId = warn.featureIds as string[] | undefined;
      if (!zoomToFeaturesId) return;
      return () =>
        zoomToFeatureIds(zoomToFeaturesId, undefined, undefined, 0.01);
    case ValidationWarningTypes.ExportCableLandfallFailed:
      const zoomToFeatureId = warn.data?.[0].exportCableId;
      if (!zoomToFeatureId) return;
      return () =>
        zoomToFeature(zoomToFeatureId, {
          zoom: 12,
        });
    default:
      return;
  }
};

export const ValidationWarningButton = ({
  warn,
  map,
}: {
  warn: ValidationWarningOrganisationStateType | ValidationWarningStateType;
  map?: Map;
}): Record<ValidationWarningTypes, JSX.Element> => ({
  [ValidationWarningTypes.ProjectSize]: <></>,

  [ValidationWarningTypes.TurbineOverlap]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select overlapping turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.ExistingTurbineOverlap]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select overlapping existing turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.CableTypeInvalid]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbineTypeInvalid]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select invalid turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.FoundationTypeInvalid]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.SubstationTypeInvalid]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select invalid substations"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbineOutsidePark]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines outside park"}
      />
    </>
  ),
  [ValidationWarningTypes.SelfIntersectingPolygon]: (
    <Row alignCenter style={{ justifyContent: "flex-end" }}>
      <FixSelfIntersectingPolygonsButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Split & fix"}
      />
      {map && "coordinates" in warn ? (
        <SelectFeaturesAndGoToCoordinatesButton
          featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
          coordinates={(warn as any)["coordinates"] ?? ([] as Position[])}
          map={map}
        />
      ) : (
        <SelectFeaturesButton
          featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
          text={"Select turbines outside park"}
        />
      )}
    </Row>
  ),
  [ValidationWarningTypes.InvalidFeatures]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select invalid features"}
      />
    </>
  ),
  [ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select offshore substations"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbinesNotReachable]: (
    <>
      <Button
        onClick={() => {
          showArticle("cableGen");
        }}
        buttonType="text"
        text="Read more about cables here"
      />

      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),

  [ValidationWarningTypes.SubstationInsideNoCableExclusionZone]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select substations"}
      />
    </>
  ),
  [ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select substations"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.CableInsideNoCableExclusionZone]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.SubstationOverlap]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select substations"}
      />
    </>
  ),
  [ValidationWarningTypes.OverlappingSubAreas]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select sub areas"}
      />
    </>
  ),
  [ValidationWarningTypes.SubAreasOutsidePark]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select sub areas"}
      />
    </>
  ),
  [ValidationWarningTypes.OverlappingParks]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select parks"}
      />
    </>
  ),
  [ValidationWarningTypes.HoleInPark]: (
    <>
      <RemoveParkHolesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Remove holes"}
      />
      <ParkHolesIntoExclusionZonesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Make holes into exclusion zones"}
      />
    </>
  ),
  [ValidationWarningTypes.CableCorridorNotConnectedToPark]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cable corridors"}
      />
    </>
  ),
  [ValidationWarningTypes.CablesDifferentIAVoltageTypes]: <></>,
  [ValidationWarningTypes.CableDifferentExportVoltageTypes]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbineNotConnected]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines not connected"}
      />
    </>
  ),
  [ValidationWarningTypes.SubstationNotConnected]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select substations"}
      />
    </>
  ),
  [ValidationWarningTypes.CableNotConnected]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.SubstationMissingType]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select substations"}
      />
    </>
  ),
  [ValidationWarningTypes.ExportCableNotConnected]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select export cables"}
      />
    </>
  ),

  [ValidationWarningTypes.ExportCableTooLongWarning]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={`Select relevant export cables`}
      />
    </>
  ),
  [ValidationWarningTypes.ExportCableTooLongError]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={`Select relevant export cables`}
      />
    </>
  ),
  [ValidationWarningTypes.ExportSystemWillNotConverge]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={`Select relevant export cables`}
      />
    </>
  ),
  [ValidationWarningTypes.TooShortCables]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.TurbineAndMonopileTypeError]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.AnchorsOutsidePark]: <></>,
  [ValidationWarningTypes.ExistingTurbinesWithoutPower]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select turbines"}
      />
    </>
  ),
  [ValidationWarningTypes.CablesCross]: (
    <>
      <Button
        size={"small"}
        buttonType="text"
        onClick={() => window.Intercom("showMessages")}
        text="Open chat"
      />
    </>
  ),
  [ValidationWarningTypes.FatalGeneral]: (
    <>
      <div
        style={{
          margin: "1.2rem 1.6rem 0 auto",
          display: "flex",
          alignItems: "center",
          gap: "1.2rem",
        }}
      >
        <Button
          size={"small"}
          buttonType="secondary"
          onClick={() => window.Intercom("showMessages")}
          text="Open chat"
        />
        <Button
          size={"small"}
          buttonType="primary"
          text="Refresh page"
          onClick={() => document.location.reload()}
        />
      </div>
    </>
  ),
  [ValidationWarningTypes.FatalAblyLost]: (
    <>
      <div
        style={{
          margin: "1.2rem 1.6rem 0 auto",
          display: "flex",
          alignItems: "center",
          gap: "1.2rem",
        }}
      >
        <Button
          size={"small"}
          buttonType="secondary"
          onClick={() => window.Intercom("showMessages")}
          text="Open chat"
        />
        <Button
          size={"small"}
          buttonType="primary"
          text="Refresh page"
          onClick={() => document.location.reload()}
        />
      </div>
    </>
  ),
  [ValidationWarningTypes.ExportCableLandfallFailed]: (
    <>
      <SelectFeaturesButton
        featureIds={
          (warn as ValidationWarningStateType).type ===
            ValidationWarningTypes.ExportCableLandfallFailed &&
          (warn as any).data &&
          (warn as any).data.length !== 0
            ? [(warn as any).data?.[0].exportCableId]
            : []
        }
        text={"Select cables"}
      />
    </>
  ),
  [ValidationWarningTypes.ParkMultiPolygons]: (
    <>
      <DeleteFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Delete multipolygons"}
      />
    </>
  ),
  [ValidationWarningTypes.ExportSystemInsufficientCapacity]: (
    <SelectFeaturesButton
      featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
      text={"Select export cables"}
    />
  ),
  [ValidationWarningTypes.InterArrayInsufficientCapacity]: (
    <SelectFeaturesButton
      featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
      text={"Select cables"}
    />
  ),
  [ValidationWarningTypes.CableIntersectsSector]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select overlapping cables"}
      />
    </>
  ),
  [ValidationWarningTypes.CableLoops]: (
    <>
      <SelectFeaturesButton
        featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
        text={"Select cable in loop"}
      />
    </>
  ),
  [ValidationWarningTypes.ExportCableDifferentTypes]: (
    <SelectFeaturesButton
      featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
      text={"Select export cables"}
    />
  ),
  [ValidationWarningTypes.ExportCableDifferentTypesInCable]: (
    <SelectFeaturesButton
      featureIds={(warn as any)["featureIds"] ?? ([] as string[])}
      text={"Select export cables"}
    />
  ),
});

export const validationWarningSeverity: Record<
  ValidationWarningTypes,
  ValidationSeverity
> = {
  [ValidationWarningTypes.ProjectSize]: ValidationSeverity.Error,
  [ValidationWarningTypes.ParkMultiPolygons]: ValidationSeverity.Error,
  [ValidationWarningTypes.TurbineOutsidePark]: ValidationSeverity.Error,
  [ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor]:
    ValidationSeverity.Error,
  [ValidationWarningTypes.TurbineOverlap]: ValidationSeverity.Error,
  [ValidationWarningTypes.SelfIntersectingPolygon]: ValidationSeverity.Error,
  [ValidationWarningTypes.InvalidFeatures]: ValidationSeverity.Error,
  [ValidationWarningTypes.ExistingTurbineOverlap]: ValidationSeverity.Warning,
  [ValidationWarningTypes.CableTypeInvalid]: ValidationSeverity.Error,
  [ValidationWarningTypes.TurbineTypeInvalid]: ValidationSeverity.Error,
  [ValidationWarningTypes.SubstationTypeInvalid]: ValidationSeverity.Error,
  [ValidationWarningTypes.FoundationTypeInvalid]: ValidationSeverity.Error,
  [ValidationWarningTypes.OverlappingParks]: ValidationSeverity.Warning,
  [ValidationWarningTypes.HoleInPark]: ValidationSeverity.Warning,
  [ValidationWarningTypes.TurbinesNotReachable]: ValidationSeverity.Warning,
  [ValidationWarningTypes.SubstationOverlap]: ValidationSeverity.Error,
  [ValidationWarningTypes.OverlappingSubAreas]: ValidationSeverity.Warning,
  [ValidationWarningTypes.SubAreasOutsidePark]: ValidationSeverity.Warning,
  [ValidationWarningTypes.CableCorridorNotConnectedToPark]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.AnchorsOutsidePark]: ValidationSeverity.Warning,
  [ValidationWarningTypes.CablesDifferentIAVoltageTypes]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.CableDifferentExportVoltageTypes]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.TurbineNotConnected]: ValidationSeverity.Warning,
  [ValidationWarningTypes.SubstationNotConnected]: ValidationSeverity.Error,
  [ValidationWarningTypes.CableNotConnected]: ValidationSeverity.Warning,
  [ValidationWarningTypes.SubstationInsideNoCableExclusionZone]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.CableInsideNoCableExclusionZone]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.SubstationMissingType]: ValidationSeverity.Error,
  [ValidationWarningTypes.ExportCableNotConnected]: ValidationSeverity.Warning,
  [ValidationWarningTypes.ExportCableTooLongWarning]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.ExportCableTooLongError]: ValidationSeverity.Error,
  [ValidationWarningTypes.TooShortCables]: ValidationSeverity.Warning,
  [ValidationWarningTypes.TurbineAndMonopileTypeError]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.CablesCross]: ValidationSeverity.Warning,
  [ValidationWarningTypes.FatalGeneral]: ValidationSeverity.Fatal,
  [ValidationWarningTypes.FatalAblyLost]: ValidationSeverity.AblyLost,
  [ValidationWarningTypes.ExportCableLandfallFailed]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.ExistingTurbinesWithoutPower]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.ExportSystemInsufficientCapacity]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.InterArrayInsufficientCapacity]:
    ValidationSeverity.Warning,
  [ValidationWarningTypes.CableIntersectsSector]: ValidationSeverity.Warning,
  [ValidationWarningTypes.CableLoops]: ValidationSeverity.Error,
  [ValidationWarningTypes.ExportCableDifferentTypes]: ValidationSeverity.Error,
  [ValidationWarningTypes.ExportCableDifferentTypesInCable]:
    ValidationSeverity.Error,
  [ValidationWarningTypes.ExportSystemWillNotConverge]:
    ValidationSeverity.Error,
} as const;

export const validationWarningTitleText: Record<
  ValidationWarningTypes,
  string
> = {
  [ValidationWarningTypes.ProjectSize]: "Project exceeds max data size",
  [ValidationWarningTypes.TurbineOverlap]: "Turbines are overlapping",
  [ValidationWarningTypes.SelfIntersectingPolygon]:
    "Some polygons are self intersecting",
  [ValidationWarningTypes.InvalidFeatures]: "Some features are invalid",
  [ValidationWarningTypes.ExistingTurbineOverlap]:
    "Existing turbines are overlapping",
  [ValidationWarningTypes.CableTypeInvalid]:
    "Park contains invalid cable type(s)",
  [ValidationWarningTypes.TurbineTypeInvalid]:
    "Park contains invalid turbine type(s)",
  [ValidationWarningTypes.SubstationTypeInvalid]:
    "Park contains invalid substation type(s)",
  [ValidationWarningTypes.FoundationTypeInvalid]:
    "Park contains invalid foundation type(s)",
  [ValidationWarningTypes.TurbineOutsidePark]:
    "Turbine(s) outside park boundary",
  [ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor]:
    "Offshore substation(s) outside park boundary or cable corridor",
  [ValidationWarningTypes.TurbinesNotReachable]:
    "Turbines are not possible to connect",
  [ValidationWarningTypes.SubstationInsideNoCableExclusionZone]:
    "Substations placed inside exclusion zone",
  [ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone]:
    "Substations placed inside exclusion zone",
  [ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone]:
    "Turbines placed inside exclusion zone",
  [ValidationWarningTypes.CableInsideNoCableExclusionZone]:
    "Cables placed inside exclusion zone",
  [ValidationWarningTypes.SubstationOverlap]: "Substations are overlapping",
  [ValidationWarningTypes.OverlappingSubAreas]: "Overlapping sub areas",
  [ValidationWarningTypes.SubAreasOutsidePark]: "Sub areas outside park",
  [ValidationWarningTypes.OverlappingParks]: "Overlapping parks",
  [ValidationWarningTypes.HoleInPark]: "Park contain holes",
  [ValidationWarningTypes.CableCorridorNotConnectedToPark]:
    "Cable corridors not connected to park",
  [ValidationWarningTypes.CablesDifferentIAVoltageTypes]:
    "Different voltage levels",
  [ValidationWarningTypes.CableDifferentExportVoltageTypes]:
    "Different voltage levels",
  [ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes]:
    "Mismatching voltage levels",
  [ValidationWarningTypes.TurbineNotConnected]:
    "Turbines not connected to substation",
  [ValidationWarningTypes.SubstationNotConnected]:
    "Substations not connected to an export cable",
  [ValidationWarningTypes.CableNotConnected]:
    "Cables not connected to turbine or substation",
  [ValidationWarningTypes.SubstationMissingType]:
    "Substations missing substation type",
  [ValidationWarningTypes.ExportCableNotConnected]:
    "Export cables not connected to both an onshore and an offshore substation",
  [ValidationWarningTypes.ExportCableTooLongWarning]: `Export cables may be too long for loss calculations`,
  [ValidationWarningTypes.ExportCableTooLongError]: `Export cables are too long for loss calculations`,
  [ValidationWarningTypes.TooShortCables]: "Short inter array cables",
  [ValidationWarningTypes.TurbineAndMonopileTypeError]:
    "Mismatching turbine type for site-specific foundation",
  [ValidationWarningTypes.AnchorsOutsidePark]: "Anchors outside park",
  [ValidationWarningTypes.ExistingTurbinesWithoutPower]:
    "Existing turbines without power rating",
  [ValidationWarningTypes.ParkMultiPolygons]:
    "Multipolygon park(s) detected in branch",
  [ValidationWarningTypes.CablesCross]: "Cable crossings",
  [ValidationWarningTypes.FatalGeneral]:
    "A critical system is not responding correctly",
  [ValidationWarningTypes.FatalAblyLost]: "Connection lost",
  [ValidationWarningTypes.ExportCableLandfallFailed]:
    "No landfall for export cables",
  [ValidationWarningTypes.ExportSystemInsufficientCapacity]:
    "Export system capacity may be too low",
  [ValidationWarningTypes.InterArrayInsufficientCapacity]:
    "Inter array capacity may be too low",
  [ValidationWarningTypes.CableIntersectsSector]:
    "Cables intersects cable-free sectors",
  [ValidationWarningTypes.CableLoops]:
    "Cables loop without any redunancy cables",
  [ValidationWarningTypes.ExportCableDifferentTypes]:
    "Different export cable types",
  [ValidationWarningTypes.ExportCableDifferentTypesInCable]:
    "Cable contains different export cable types",
  [ValidationWarningTypes.ExportSystemWillNotConverge]: "Invalid export system",
};

export const validationWarningDetailsText: Record<
  ValidationWarningTypes,
  string
> = {
  [ValidationWarningTypes.ParkMultiPolygons]:
    "Some parks are multi polygons. This is not supported and can create issues. Removal recommended.",
  [ValidationWarningTypes.ProjectSize]:
    "Due to current technical limitations, there is an upper limit of how much data a single branch can contain and be stored in the cloud. This branch has unfortunately exceeded that limit and things you do from this point on might not be stored. Please split up this branch into multiple branches in order to continue to save things.",
  [ValidationWarningTypes.TurbineOverlap]:
    "Two turbines are placed very close to each other, this can create issues in the wake analysis. The turbines have been colored red in order to more easily show where the overlap is.",
  [ValidationWarningTypes.SelfIntersectingPolygon]:
    "Some polygons are self intersecting and can create issues.",
  [ValidationWarningTypes.InvalidFeatures]:
    "Some features have invalid geometry and can create issues. You should remove them.",
  [ValidationWarningTypes.ExistingTurbineOverlap]:
    "Two existing turbines are placed very close to each other, this can create issues in the wake analysis.",
  [ValidationWarningTypes.CableTypeInvalid]:
    "Please update all cables with a valid cable type to ensure that all numbers in the project are correct.",
  [ValidationWarningTypes.TurbineTypeInvalid]:
    "Please update all turbines with a valid turbine type to ensure that all numbers in the project are correct.",
  [ValidationWarningTypes.SubstationTypeInvalid]:
    "Please update all substations with a valid substation type to ensure that all numbers in the project are correct.",
  [ValidationWarningTypes.FoundationTypeInvalid]:
    "Please update all turbines with a valid foundation type to ensure that all numbers in the project are correct.",
  [ValidationWarningTypes.TurbineOutsidePark]:
    "One or more turbines are placed outside the park boundary. This can create issues in the analysis. The relevant turbines have been colored red.",
  [ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor]:
    "One or more offshore substations are placed outside the park boundary or any cable corridor. This can create issues in the analysis. The relevant substations have been colored bright red.",
  [ValidationWarningTypes.TurbinesNotReachable]:
    "There is no way to connect some turbines to the placed substations. Cables are not allowed to exit the park polygon unless it enters a cable corridor, or entering an exclusion zone, but they can be put outside of sub areas, as long as they stay within the park boundary.",
  [ValidationWarningTypes.SubstationInsideNoCableExclusionZone]:
    "Some substations are placed within exclusion zones that do not allow cables.",
  [ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone]:
    "Some substations are placed within exclusion zones that do not allow substations.",
  [ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone]:
    "Some turbines are placed within exclusion zones that do not allow turbines.",
  [ValidationWarningTypes.CableInsideNoCableExclusionZone]:
    "Some cables are placed within exclusion zones that do not allow cables.",
  [ValidationWarningTypes.SubstationOverlap]:
    "Two substations are placed very close to each other.",
  [ValidationWarningTypes.OverlappingSubAreas]:
    "Some sub areas are overlapping. This can lead to non-intuitive behaviour when generating turbines or cabling.",
  [ValidationWarningTypes.SubAreasOutsidePark]:
    "Some sub areas are placed outside the park. This is not how sub areas are supposed to be used and might lead to wrong expectations.",
  [ValidationWarningTypes.OverlappingParks]:
    "Some parks are overlapping. The app will still work properly and if this is intentional it can be fine. Be aware that turbines from the overlapping parks might end up extremely close to each other and affect the result.",
  [ValidationWarningTypes.HoleInPark]:
    "Some parks have holes in them. This is not properly supported and can lead to strange results. In case you want to have an area in the park which should be excluded, please use an exclusion zone.",
  [ValidationWarningTypes.CableCorridorNotConnectedToPark]:
    "Some cable corridors do not connect to the park they belong to and will not be able to be used. A cable corridor must join with its park in order for cables to be drawn across it.",
  [ValidationWarningTypes.CablesDifferentIAVoltageTypes]:
    "The cable types used in the park have more than one unique voltage level. If inter array losses are calculated, the most used voltage level (in terms of total cable length) will be applied in the analysis.",
  [ValidationWarningTypes.CableDifferentExportVoltageTypes]:
    "Some export cables have more than one unique voltage level. Please verify that the offshore and onshore export cable types have the same voltage.",
  [ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes]:
    "There are turbines that are connected with cables that do not match the turbines voltage settings.",
  [ValidationWarningTypes.TurbineNotConnected]:
    "Some turbines are not connected to a substation. This can lead to erroneous inter array calculations.",
  [ValidationWarningTypes.SubstationNotConnected]:
    "Some substations are not connected to an export cable. This can lead to erroneous export system calculations.",
  [ValidationWarningTypes.CableNotConnected]:
    "Some cables are not connected to turbines and/or substations. This can lead to erroneous inter array calculations.",
  [ValidationWarningTypes.SubstationMissingType]:
    "Some substations are missing substation types.",
  [ValidationWarningTypes.ExportCableNotConnected]:
    "Some export cables are not connected to both an offshore and an onshore substation. This can lead to erroneous export system calculations.",
  [ValidationWarningTypes.ExportCableTooLongWarning]: `Export cable loss is enabled, but some export cables are too long to reliably calculate losses.`,
  [ValidationWarningTypes.ExportCableTooLongError]: `Export cable loss is enabled, but some export cables are too long to calculate losses.`,
  [ValidationWarningTypes.TooShortCables]: `Some cables have very short horizontal length (less than ${1000 * MIN_CABLE_LENGTH_KM} m). This can create issues in the inter array loss calculations.`,
  [ValidationWarningTypes.TurbineAndMonopileTypeError]: `Some site-specific foundations are placed on a turbine with rotor diameter, hub height, or RNA weight that differs more than
    ${MAX_RELATIVE_TURBINE_DATA_ERROR * 100}% from what it was designed for. This may result in erroneous foundation weight estimates.`,
  [ValidationWarningTypes.AnchorsOutsidePark]:
    "Some anchors are outside the park boundary.",
  [ValidationWarningTypes.ExistingTurbinesWithoutPower]:
    "There are existing turbines in the project that have no power rating. These will be ignored in the analysis.",
  [ValidationWarningTypes.CablesCross]:
    "Due to a technical limitation, it is not guaranteed that the generated cable layout is free of cable crossings.",
  [ValidationWarningTypes.FatalGeneral]:
    "Due to a technical error, the app is not able to be used — the vind team have been notified.",
  [ValidationWarningTypes.FatalAblyLost]:
    "Due to inactivity, the connection to our systems has been lost, any activity done in the project might not be stored. Please refresh the page in order to re-connect.",
  [ValidationWarningTypes.ExportCableLandfallFailed]:
    "Export cables are expected to go from an offshore substation to an onshore substation. Cannot find the landfall point for export cables.",
  [ValidationWarningTypes.ExportSystemInsufficientCapacity]:
    "The total capacity of the export cables may be lower than the total capacity of the park.",
  [ValidationWarningTypes.InterArrayInsufficientCapacity]:
    "The estimated capacity of some inter array cables is lower than the cable load.",
  [ValidationWarningTypes.CableIntersectsSector]:
    "One or more cables intersects a cable-free sector around a turbine or substation.",
  [ValidationWarningTypes.CableLoops]:
    "There are cables in a loop in the park.  Cable loops are only supported if at least one cable in the loop is marked as a redundancy cable.",
  [ValidationWarningTypes.ExportCableDifferentTypes]:
    "Park contains export cables of both HVAC and HVDC type. Please verify that the all export cables have the same type.",
  [ValidationWarningTypes.ExportCableDifferentTypesInCable]:
    "Some export cables have more than one unique type (HVAC and HVDC). Please verify that the offshore and onshore export cable parts have the same type.",
  [ValidationWarningTypes.ExportSystemWillNotConverge]:
    "Some export cables are too long compared to the amount of power going into them, and the loss analysis will not converge. Please reduce the export cable length, try a different export cable voltage, or switch to HVDC.",
};

export const validationWarningSeverityColor: Record<
  ValidationSeverity,
  string
> = {
  [ValidationSeverity.Warning]: colors.orange400,
  [ValidationSeverity.Error]: colors.red600,
  [ValidationSeverity.Fatal]: colors.red600,
  [ValidationSeverity.AblyLost]: colors.blue400,
};

export const validationWarningBadgeSeverityColor: Record<
  ValidationSeverity,
  string
> = {
  [ValidationSeverity.Warning]: colors.orange200,
  [ValidationSeverity.Error]: colors.red200,
  [ValidationSeverity.Fatal]: colors.red200,
  [ValidationSeverity.AblyLost]: colors.blue400,
};

export const validationWarningBadgeSeverityFontColor: Record<
  ValidationSeverity,
  string
> = {
  [ValidationSeverity.Warning]: colors.orange500,
  [ValidationSeverity.Error]: colors.red800,
  [ValidationSeverity.Fatal]: colors.red800,
  [ValidationSeverity.AblyLost]: colors.blue400,
};
