import { mapAtom } from "state/map";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { contourStepSizeAtom } from "../state/map";
import { useEffect, useMemo } from "react";
import { getGeorefImageSelectorFamily } from "../state/georef";
import {
  geotifBathymetryLayerId,
  geotifImageLayerId,
  getBeforeLayer,
} from "../components/Mapbox/utils";
import { GeotiffImage } from "../components/MapFeatures/Geotiff";
import { BathymetryFeature, GeotiffFeature } from "../types/feature";
import { fastMax, fastMin } from "utils/utils";
import { scream } from "utils/sentry";
import { MercatorCoordinate } from "mapbox-gl";
import {
  opacityPropertyName,
  DEFAULT_CANVAS_GEOTIFF_OPACITY,
} from "@constants/canvas";
import { useAtomValue } from "jotai";
import { geotiffFamily } from "state/jotai/geotiff";
import { bathymetryFeatureFamily } from "state/jotai/bathymetry";
import {
  getBestAvailableTexture,
  TextureUvOffset,
} from "./tilesLayerOverTerrainUtils";
import { Map as MapboxMap } from "mapbox-gl";
import { lonLatToTile, wrapMapboxTile } from "types/tile";
import { MaybePromise } from "types/utils";

const CanvasLayer = () => {
  const canvasLayerImageFeatures = useAtomValue(
    geotiffFamily({
      branchId: undefined,
    }),
  );
  const canvasLayerBathymetryFeatures = useAtomValue(
    bathymetryFeatureFamily({
      branchId: undefined,
    }),
  );

  return (
    <>
      {canvasLayerImageFeatures.map((canvasLayerImageFeature) => (
        <CanvasImageLayer
          key={canvasLayerImageFeature.id}
          feature={canvasLayerImageFeature}
        />
      ))}
      {canvasLayerBathymetryFeatures.map((canvasLayerBathymetryFeature) => (
        <CanvasBathymetryLayer
          key={canvasLayerBathymetryFeature.id}
          feature={canvasLayerBathymetryFeature}
        />
      ))}
    </>
  );
};

class BathymetryContourLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode?: "3d";
  program: any;
  vertexBuffer: any;
  map: MapboxMap | undefined;
  textureCache: Map<string, MaybePromise<TextureUvOffset>>;
  maxZoom: number;
  tileSize: number;
  projectId: string;
  fileName: string;

  contourDist: number;
  bounds: [number, number, number, number];
  isSubmitted: boolean;
  urlFunc = (z: number, x: number, y: number) =>
    `/api/bathymetry/custom/${this.projectId}/${this.fileName}/${x}/${y}/${z}?include_land=true`;

  constructor(
    id: string,
    maxZoom: number,
    tileSize: number,
    projectId: string,
    fileName: string,
    bounds: [number, number, number, number],
  ) {
    this.id = id;
    this.type = "custom";
    this.renderingMode = "3d";
    this.contourDist = 25.0;
    this.textureCache = new Map();
    this.maxZoom = maxZoom;
    this.tileSize = tileSize;
    this.projectId = projectId;
    this.fileName = fileName;
    this.bounds = bounds;
    this.isSubmitted = false;
  }

  updateContourDist(newContourDist: number) {
    this.contourDist = newContourDist;
  }

  onAdd = (map: MapboxMap, gl: WebGL2RenderingContext) => {
    this.map = map;

    const vertexSource = `#version 300 es
      precision highp float;
      uniform mat4 u_matrix;

      in vec2 a_pos;
      out vec2 v_pos;

      void main() {
        v_pos = a_pos;
        gl_Position = vec4(a_pos, 1.0, 1.0);
      }
    `;

    const fragmentSource = `#version 300 es
        precision highp float;
        uniform sampler2D u_depth_raster;
        uniform float u_contourDist;
        uniform vec4 u_uv_offsets;

        in vec2 v_pos;

        layout(location=0) out vec4 outColor;

        vec3 depthToColor(float depth) {
          vec3 lightBlue = vec3(0.64, 0.78, 0.92);
          vec3 medlightBlue = vec3(0.45, 0.55, 0.70);
          vec3 mediumBlue = vec3(0.38, 0.47, 0.60);
          vec3 darkBlue = vec3(0.31, 0.36, 0.40);
          vec3 darkestBlue = vec3(0.12, 0.17, 0.21);
          float l1 = 50.0;
          float l2 = 300.0;
          float l3 = 500.0;
          float l4 = 700.0;
          if (depth < l1) {
            float f = depth  / l1;
            return mix(lightBlue, medlightBlue, f);
          } else if (depth < l2) {
            float f = clamp((depth - l1) / (l2 - l1), 0., 1.);
            return mix(medlightBlue, mediumBlue, f);
          } else if (depth < l3) {
            float f = clamp((depth - l2) / (l3 - l2), 0., 1.);
            return mix(mediumBlue, darkBlue, f);
          } else {
            float f = clamp((depth - l3) / (l4 - l3), 0., 1.);
            return mix(darkBlue, darkestBlue, f);
          }
        }

        float depthToContour(float depth, float Step) {
          float frac = fract(depth / Step);
          float grace = fwidth(depth / Step);
          return 1.0 - step(grace, frac);
        }

        void main() {
          vec2 uv = 0.5 * v_pos + 0.5;
          uv.x = u_uv_offsets.x + (u_uv_offsets.z - u_uv_offsets.x) * uv.x;
          uv.y = u_uv_offsets.y + (u_uv_offsets.w - u_uv_offsets.y) * uv.y;

          float STEP = u_contourDist;
          vec4 texel = texture(u_depth_raster, uv) * 255.;
          float depth = -(-32768. + (texel.r * 256. + texel.g + texel.b / 256.));

          float contour = depthToContour(depth, STEP);

          float depthstep = floor(depth / STEP);
          vec3 areaColor = depthToColor(depthstep * STEP);

          float sign = float(depth < 200.0) * 2.0 - 1.0; // +/- 1.0, positive is shallow.
          vec3 withIsoLines = areaColor + sign * contour * areaColor * 0.2;

          float alpha = texel.a / 255. * 0.9;
          outColor = vec4(withIsoLines, alpha);
    }`;

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (!vertexShader)
      throw scream("bathymetry: failed to create vertexShader");
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (!fragmentShader)
      throw scream("bathymetry: failed to create fragmentShader");
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS))
      throw scream("Failed to create program", {
        program: this.program,
        vertexShader,
        fragmentShader,
        programInfo: gl.getProgramInfoLog(this.program),
        vertexInfo: gl.getShaderInfoLog(vertexShader),
        fragmentInfo: gl.getShaderInfoLog(fragmentShader),
      });

    this.program.aPos = gl.getAttribLocation(this.program, "a_pos");

    const verts = new Float32Array([1, 1, 1, -1, -1, -1, -1, -1, -1, 1, 1, 1]);
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, verts, gl.STATIC_DRAW);
  };

  shouldRerenderTiles = () => {
    // return true only when frame content has changed otherwise, all the terrain
    // render cache would be invalidated and redrawn causing huge drop in performance.
    return true;
  };

  onRemove() {
    this.isSubmitted = true;
  }

  renderToTile = (gl: WebGL2RenderingContext, tileId: MercatorCoordinate) => {
    wrapMapboxTile(tileId);
    if (!this.map) return;
    const boundsTileSW = lonLatToTile(
      this.bounds[0],
      this.bounds[1],
      tileId.z ?? 0,
    );
    const boundsTileNE = lonLatToTile(
      this.bounds[2],
      this.bounds[3],
      tileId.z ?? 0,
    );

    if (
      !(
        tileId.x >= boundsTileSW.x &&
        tileId.x <= boundsTileNE.x &&
        tileId.y >= boundsTileNE.y &&
        tileId.y <= boundsTileSW.y
      )
    ) {
      return;
    }

    const texture = getBestAvailableTexture(
      gl,
      tileId,
      this.textureCache,
      this.map,
      this.urlFunc,
      this.maxZoom,
      this.tileSize,
      true,
      () => this.isSubmitted,
    );
    gl.useProgram(this.program);

    gl.uniform1f(
      gl.getUniformLocation(this.program, "u_contourDist"),
      this.contourDist,
    );
    gl.uniform4fv(
      gl.getUniformLocation(this.program, "u_uv_offsets"),
      texture.uvOffsets,
    );
    // Set up the texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture.texture);
    gl.uniform1i(gl.getUniformLocation(this.program, "u_depth_raster"), 0);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    // Draw
    this.draw(gl);
  };

  draw(gl: WebGL2RenderingContext) {
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.enableVertexAttribArray(this.program.aPos);
    gl.vertexAttribPointer(this.program.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  render(_gl: WebGL2RenderingContext, _matrix: number[]) {}
}

const CanvasBathymetryLayer = ({ feature }: { feature: BathymetryFeature }) => {
  const map = useAtomValue(mapAtom);
  const projectId = useAtomValue(projectIdAtom);
  const contourStepSize = useAtomValue(contourStepSizeAtom);
  const { filename } = feature.properties;

  const layerId = useMemo(
    () => `${geotifBathymetryLayerId}-${feature.id}`,
    [feature],
  );

  const bounds: [number, number, number, number] = useMemo(() => {
    const xs = feature.geometry.coordinates[0].map((c) => c[0]);
    const ys = feature.geometry.coordinates[0].map((c) => c[1]);
    return [fastMin(xs), fastMin(ys), fastMax(xs), fastMax(ys)];
  }, [feature]);

  const layer = useMemo(() => {
    if (!projectId) return;
    return new BathymetryContourLayer(
      layerId,
      13,
      256,
      projectId,
      filename,
      bounds,
    );
  }, [layerId, filename, projectId, bounds]);

  useEffect(() => {
    if (!map || !layer) return;
    layer.updateContourDist(contourStepSize);
    map.triggerRepaint();
  }, [map, layer, contourStepSize]);

  useEffect(() => {
    if (!map || !layer) return;
    map.addLayer(layer, getBeforeLayer(map, geotifBathymetryLayerId));

    return () => {
      if (!map) return;
      map.removeLayer(layer.id);
    };
  }, [map, layer]);

  return null;
};

const CanvasImageLayer = ({
  feature,
}: {
  feature: BathymetryFeature | GeotiffFeature;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const geotiff = useAtomValue(
    getGeorefImageSelectorFamily({
      projectId,
      branchId,
      filename: feature.properties.filename,
    }),
  );
  const map = useAtomValue(mapAtom);

  const layerId = useMemo(
    () => `${geotifImageLayerId}-${feature.id}`,
    [feature],
  );

  if (!map || !geotiff) return null;
  const opacity =
    feature.properties[opacityPropertyName] ?? DEFAULT_CANVAS_GEOTIFF_OPACITY;
  return (
    <GeotiffImage
      layerId={layerId}
      map={map}
      geotiff={geotiff}
      opacity={opacity}
    />
  );
};

export default CanvasLayer;
