import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { WeatherLimitsConfig } from "services/operationsConfigurationService";
import { InputDimensioned } from "components/General/Input";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { Row } from "components/General/Layout";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";

const WeatherLimits = ({
  isReadOnly,
  weatherLimits,
  category,
  nodeId,
}: {
  isReadOnly?: boolean;
  weatherLimits: WeatherLimitsConfig;
  category: string;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <Row>
          <SubHeader>Max wind speed during transit</SubHeader>
          <HelpTooltip text="1-hr wind speed at 10 m." />
        </Row>
        <Row>
          <SubHeader>Max wind speed during installation</SubHeader>
          <HelpTooltip text="1-hr wind speed at 10 m." />
        </Row>
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.weatherLimits.maxWindSpeedDuringTransit`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"m/s"}
          decimals={1}
          value={weatherLimits.maxWindSpeedDuringTransit}
          step={0.1}
          validate={(e) => 0.5 <= e && e <= 50}
          validationMessage={`Must be between 0.5 and 50`}
          initialValue={25}
          onChange={(value) =>
            updateTIVessels(
              {
                weatherLimits: {
                  ...weatherLimits,
                  maxWindSpeedDuringTransit: value,
                },
              },
              category,
            )
          }
        />
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.weatherLimits.maxWindSpeedDuringInstallation`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"m/s"}
          decimals={1}
          value={weatherLimits.maxWindSpeedDuringInstallation}
          step={0.1}
          validate={(e) => 0.5 <= e && e <= 50}
          validationMessage={`Must be between 0.5 and 50`}
          initialValue={15}
          onChange={(value) =>
            updateTIVessels(
              {
                weatherLimits: {
                  ...weatherLimits,
                  maxWindSpeedDuringInstallation: value,
                },
              },
              category,
            )
          }
        />
      </Grid2>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <Row>
          <SubHeader>Max Hs during transit</SubHeader>
          <HelpTooltip text="1-hr significant wave height." />
        </Row>
        <Row>
          <SubHeader>Max Hs during installation</SubHeader>
          <HelpTooltip text="1-hr significant wave height." />
        </Row>
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.weatherLimits.maxHsDuringTransit`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"m"}
          decimals={1}
          value={weatherLimits.maxHsDuringTransit}
          step={0.1}
          validate={(e) => 0.5 <= e && e <= 10}
          validationMessage={`Must be between 0.5 and 10`}
          initialValue={3.5}
          onChange={(value) =>
            updateTIVessels(
              {
                weatherLimits: {
                  ...weatherLimits,
                  maxHsDuringTransit: value,
                },
              },
              category,
            )
          }
        />
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.${category}.weatherLimits.maxHsDuringInstallation`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"m"}
          decimals={1}
          value={weatherLimits.maxHsDuringInstallation}
          step={0.1}
          validate={(e) => 0.5 <= e && e <= 10}
          validationMessage={`Must be between 0.5 and 10`}
          initialValue={2.5}
          onChange={(value) =>
            updateTIVessels(
              {
                weatherLimits: {
                  ...weatherLimits,
                  maxHsDuringInstallation: value,
                },
              },
              category,
            )
          }
        />
      </Grid2>
    </Row>
  );
};

export default WeatherLimits;
