import Dashboard from "@icons/24/Dashboard.svg?react";
import * as turf from "@turf/turf";
import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { Column } from "components/General/Layout";
import { ifLoader, orLoader } from "components/Loading/Skeleton";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import {
  formatElectricalLossToGWhDecimal,
  formatElectricalLossToMWDecimal,
  formatElectricalLossToPercentDecimal,
} from "components/ProductionV2/format";
import {
  TotalExportSystemLoss,
  TotalInterArrayLoss,
} from "components/ProductionV2/functions";
import {
  ElectricalLossUnit,
  getTotalExportSystemLoss,
  getTotalExportSystemLossMW,
  getTotalExportSystemLossGWh,
  getTotalInterArrayLoss,
  getTotalInterArrayLossMW,
  getTotalInterArrayLossGWh,
} from "analysis/electrical";
import { ChevronIcon } from "components/ToggleableList/ToggleableList";
import { ElectricalStatError } from "components/ValidationWarnings/FeatureSpecificErrors";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { atom, useAtom, useAtomValue } from "jotai";
import { Suspense, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { spaceSmall } from "styles/space";
import { DashboardModalType, modalTypeOpenAtom } from "../../../../state/modal";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
} from "../../../../state/pathParams";
import { ParkFeature } from "../../../../types/feature";
import {
  fastMax,
  fastMin,
  mostCommon,
  nullMap,
  sum,
  undefMap,
} from "../../../../utils/utils";
import Button from "../../../General/Button";
import { ColoredGrid } from "../../../General/Form";
import Tooltip, { WithTooltip } from "../../../General/Tooltip";
import {
  ExportSystemTypeTag,
  NumberOfSubstationsTag,
  NumberOfTurbinesTag,
} from "../../../GenerateWindTurbines/Tags";
import HelpTooltip, {
  ARTICLE_CABLE_STATS,
  ARTICLE_INTER_ARRAY_LOSS,
  HelpLink,
} from "../../../HelpTooltip/HelpTooltip";
import { SkeletonBlock, SkeletonText } from "../../../Loading/Skeleton";
import { MenuFrame } from "../../../MenuPopup/CloseableMenuPopup";
import { Row } from "../InfoModal.style";
import { ButtonWrapper } from "../style";
import { loadable, unwrap } from "jotai/utils";
import { getConfiguration } from "analysis/inputs";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { exportCableSplitsFamily } from "state/jotai/landfall";
import {
  Divider,
  OverlineText,
  ResultValue,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import NewToggleableList from "components/ToggleableList/NewToggleableList";
import { parseOr } from "utils/zod";
import {
  _ExportCableVoltageType,
  _IAVoltageType,
} from "services/cableTypeService";
import { SubAreaTag } from "components/SiteLocator/Tags";
import { useSetAtom } from "jotai";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import {
  selectedAreaAtom,
  selectedCableChainsAtom,
  selectedCablesAtom,
  selectedSubstationsAtom,
  selectedTurbinesAtom,
} from "state/jotai/selection";
import { cable3DLengthsFamily } from "state/jotai/cable";
import { lmap } from "utils/jotai";
import { cableTypesFamily } from "state/jotai/cableType";
import { parkFamily } from "state/jotai/park";
import { getExportSystemTypeInParkAndBranchFamily } from "state/jotai/electrical";
import { Tag } from "components/General/Tag";
import { IconBtn } from "components/General/Icons";
import ElectricalIcon from "@icons/24/ElectricalWithBorder.svg";
import PercentageIcon from "@icons/24/PercentageWithBorder.svg";
import BatteryIcon from "@icons/24/BatteryWithBorder.svg";
import { isOnshoreAtom } from "state/onshore";
import IconFlipSwitch from "components/General/IconFlipSwitch";
import { configurationMenuActiveAtom } from "../ProjectFeatureInfoModal/state";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { IconREMSize } from "styles/typography";
import StatisticsIcon from "@icons/24/Statistics.svg";

const DisabledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceSmall};
  p {
    color: ${colors.primaryDisabled};
    margin: 0;
  }
  * > svg {
    height: 1.4rem;
    width: 1.4rem;
    padding: 0.1rem;
    path {
      stroke: ${colors.primaryDisabled};
    }
  }
`;

const electricalLossesDisplayUnitAtom = atom<ElectricalLossUnit>(
  ElectricalLossUnit.Percent,
);

const Disabled = ({ text }: { text: string }) => (
  <DisabledRow>
    <ResultValue style={{ color: colors.textDisabled }}>Disabled</ResultValue>
    <HelpTooltip text={text} />
  </DisabledRow>
);

const Inner = ({ park }: { park: ParkFeature }) => {
  const branchId = useAtomValue(branchIdAtom);
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const { scrollBodyRef } = useShowScrollShadow(true);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);

  if (!branchId) return null;
  return (
    <>
      <div
        ref={scrollBodyRef}
        style={{
          maxHeight: `calc(100vh - 32rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
          overflowY: "auto",
        }}
      >
        <Stats park={park} />
      </div>
      <Divider />
      <ButtonWrapper>
        <Button
          text="View in dashboard"
          buttonType="secondary"
          icon={<Dashboard />}
          onClick={() => {
            setModalType({
              modalType: DashboardModalType,
              metadata: { id: "vind-preset-dashboard-electrical" },
            });
          }}
        />
      </ButtonWrapper>
    </>
  );
};

const ElectricalLosses = () => {
  const stoppedReason = useAtomValue(
    unwrap(getStoppedReason(currentSelectionProduction)),
  );
  const analysisStoppedReason = useAtomValue(
    unwrap(getAnalysisError(currentSelectionProduction)),
  );
  const stopped = stoppedReason ?? analysisStoppedReason;
  const interArrayLossMW = useAtomValue(
    loadable(getTotalInterArrayLossMW(currentSelectionProduction)),
  );
  const interArrayLossGWh = useAtomValue(
    loadable(getTotalInterArrayLossGWh(currentSelectionProduction)),
  );
  const interArrayLossPercent = useAtomValue(
    loadable(getTotalInterArrayLoss(currentSelectionProduction)),
  );
  const exportSystemLossMW = useAtomValue(
    loadable(getTotalExportSystemLossMW(currentSelectionProduction)),
  );
  const exportSystemLossGWh = useAtomValue(
    loadable(getTotalExportSystemLossGWh(currentSelectionProduction)),
  );
  const exportSystemLossPercent = useAtomValue(
    loadable(getTotalExportSystemLoss(currentSelectionProduction)),
  );
  const configuration = useAtomValue(
    unwrap(getConfiguration(currentSelectionProduction)),
  );

  const [lossDisplayUnit, setLossDisplayUnit] = useAtom(
    electricalLossesDisplayUnitAtom,
  );

  const exportSystemLossEnabled =
    configuration?.electrical.exportSystemLoss ?? false;

  const interArrayLossEnabled =
    configuration?.electrical.interArrayCableLoss ?? false;
  const interArrayLoss = (() => {
    switch (lossDisplayUnit) {
      case ElectricalLossUnit.Percent:
        return interArrayLossPercent;
      case ElectricalLossUnit.MW:
        return interArrayLossMW;
      case ElectricalLossUnit.GWh:
        return interArrayLossGWh;
      default:
        return interArrayLossPercent;
    }
  })();

  const exportSystemLoss = (() => {
    switch (lossDisplayUnit) {
      case ElectricalLossUnit.Percent:
        return exportSystemLossPercent;
      case ElectricalLossUnit.MW:
        return exportSystemLossMW;
      case ElectricalLossUnit.GWh:
        return exportSystemLossGWh;
      default:
        return exportSystemLossPercent;
    }
  })();
  return (
    <>
      <SubtitleWithLine
        text={"Electrical losses"}
        article={ARTICLE_INTER_ARRAY_LOSS}
        iconButton={
          <IconFlipSwitch>
            <WithTooltip text="Losses in MW (average)">
              <IconBtn
                data-active={lossDisplayUnit === ElectricalLossUnit.MW}
                size="1.2rem"
                active={lossDisplayUnit === ElectricalLossUnit.MW}
                onClick={() => setLossDisplayUnit(ElectricalLossUnit.MW)}
              >
                <ElectricalIcon />
              </IconBtn>
            </WithTooltip>
            <WithTooltip text="Losses in GWh (annual)">
              <IconBtn
                data-active={lossDisplayUnit === ElectricalLossUnit.GWh}
                size="1.2rem"
                active={lossDisplayUnit === ElectricalLossUnit.GWh}
                onClick={() => setLossDisplayUnit(ElectricalLossUnit.GWh)}
              >
                <BatteryIcon />
              </IconBtn>
            </WithTooltip>
            <WithTooltip text="Losses in %">
              <IconBtn
                data-active={lossDisplayUnit === ElectricalLossUnit.Percent}
                size="1.2rem"
                active={lossDisplayUnit === ElectricalLossUnit.Percent}
                onClick={() => setLossDisplayUnit(ElectricalLossUnit.Percent)}
              >
                <PercentageIcon />
              </IconBtn>
            </WithTooltip>
          </IconFlipSwitch>
        }
      />
      <Column style={{ paddingBottom: "0.8rem", gap: "0.4rem" }}>
        {stopped ? (
          <SimpleAlert
            title="Analysis stopped"
            text={analysisStoppedText[stopped]}
          />
        ) : (
          <>
            {interArrayLossEnabled ? (
              ifLoader(
                interArrayLoss,
                () => <ToggleableLoader text="Total inter array loss" />,
                (loss) =>
                  loss ? (
                    <TotalIALoss
                      loss={loss}
                      lossDisplayUnit={lossDisplayUnit}
                    />
                  ) : (
                    <ToggleableLoader text="Total inter array loss" />
                  ),
              )
            ) : (
              <Row>
                <OverlineText>Total inter array loss</OverlineText>
                <Disabled text="Inter array loss is disabled in the current analysis configuration." />
              </Row>
            )}

            {exportSystemLossEnabled ? (
              ifLoader(
                exportSystemLoss,
                () => <ToggleableLoader text="Total export system loss:" />,
                (loss) =>
                  loss ? (
                    <ExportSystemLosses
                      loss={loss}
                      lossDisplayUnit={lossDisplayUnit}
                    />
                  ) : (
                    <ToggleableLoader text="Total export system loss:" />
                  ),
              )
            ) : (
              <Row>
                <OverlineText>Total export system loss</OverlineText>
                <Disabled text="Export system loss is disabled in the current analysis configuration." />
              </Row>
            )}
          </>
        )}
      </Column>
    </>
  );
};

const ExportSystemOnshore = () => {
  const [open, setOpen] = useState(true);
  const parkId = useAtomValue(parkIdAtom) ?? "";

  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId: undefined }),
  );
  const exportLength = sum(exportCables, (e) =>
    turf.length(e, { units: "kilometers" }),
  );
  const cableExportId = mostCommon(
    exportCables.map((c) => c.properties.cableTypeId),
  );

  const allCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );

  const exportvoltage = undefMap(
    allCableTypes.get(cableExportId ?? "")?.voltage,
    (v) => parseOr(_ExportCableVoltageType, v, undefined),
  );

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <SubtitleWithLine
          text={"Export system"}
          expandButton={
            <ChevronIcon
              open={open}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {open && (
        <>
          {exportCables.length === 0 ? (
            <SimpleAlert text="No export cables to show" type="info" />
          ) : (
            <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
              <ResultValue>Export cable voltage</ResultValue>
              <ResultValue>
                <strong>{exportvoltage} </strong> kV
              </ResultValue>
              <ResultValue>Total horizontal length</ResultValue>
              <ResultValue>
                <strong>{exportLength.toFixed(1)} </strong> km
              </ResultValue>
            </ColoredGrid>
          )}
        </>
      )}
    </>
  );
};

const ExportSystemOffshore = () => {
  const [open, setOpen] = useState(true);
  const parkId = useAtomValue(parkIdAtom) ?? "";

  const exportCables = useAtomValue(
    exportCablesInParkFamily({ parkId, branchId: undefined }),
  );
  const exportCableSplits = useAtomValue(
    exportCableSplitsFamily({ parkId, branchId: undefined }),
  );
  const exportLength = sum(exportCables, (e) =>
    turf.length(e, { units: "kilometers" }),
  );
  const cableExportId = mostCommon(
    exportCables.map((c) => c.properties.cableTypeId),
  );

  const { onshoreLen, offshoreLen, anyLengthError } = useMemo(() => {
    let onshoreLen = 0;
    let offshoreLen = 0;
    let anyErrors = false;
    for (const sp of exportCableSplits) {
      if ("error" in sp) {
        anyErrors = true;
      } else {
        onshoreLen += turf.length(sp.onshore, { units: "kilometers" });
        offshoreLen += turf.length(sp.offshore, { units: "kilometers" });
      }
    }
    return { offshoreLen, onshoreLen, anyLengthError: anyErrors };
  }, [exportCableSplits]);

  const allCableTypes = useAtomValue(
    exportCableTypesFamily({ projectId: undefined }),
  );

  const exportvoltage = undefMap(
    allCableTypes.get(cableExportId ?? "")?.voltage,
    (v) => parseOr(_ExportCableVoltageType, v, undefined),
  );

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <SubtitleWithLine
          text={"Export system"}
          expandButton={
            <ChevronIcon
              open={open}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {open && (
        <>
          {anyLengthError ? (
            <SimpleAlert text="Failed to compute some lengths" />
          ) : exportCables.length === 0 ? (
            <SimpleAlert text="No export cables to show" type="info" />
          ) : (
            <ColoredGrid style={{ gridTemplateColumns: "auto auto" }}>
              <ResultValue>Export cable voltage</ResultValue>
              <ResultValue>
                <strong>{exportvoltage} </strong> kV
              </ResultValue>
              <ResultValue>Export cable length offshore</ResultValue>
              <ResultValue>
                <strong>{offshoreLen.toFixed(1)} </strong> km
              </ResultValue>
              <ResultValue>Export cable length onshore</ResultValue>
              <ResultValue>
                <strong>{onshoreLen.toFixed(1)} </strong> km
              </ResultValue>
              <ResultValue>Total horizontal length</ResultValue>
              <ResultValue>
                <strong>{exportLength.toFixed(1)} </strong> km
              </ResultValue>
            </ColoredGrid>
          )}
        </>
      )}
    </>
  );
};

const Visualisations = () => {
  return (
    <>
      <SubtitleWithLine text={"Visualisations"} />
      <SimpleAlert
        type="info"
        text={`Cable type visualization has been moved to "Styling" in the bottom left corner.`}
      />
    </>
  );
};

const TotalIALoss = ({
  loss,
  lossDisplayUnit,
}: {
  loss: TotalInterArrayLoss;
  lossDisplayUnit: ElectricalLossUnit;
}) => {
  const configuration = useAtomValue(
    unwrap(getConfiguration(currentSelectionProduction)),
  );
  const interArrayLossEnabled =
    configuration?.electrical.interArrayCableLoss ?? false;
  const turbineTrafoLossEnabled =
    configuration?.electrical.turbineTrafoLoss ?? false;

  return (
    <NewToggleableList
      title="Total inter array loss"
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <p>
          {nullMap(
            loss.totalInterArrayLoss,
            lossDisplayUnit === ElectricalLossUnit.Percent
              ? formatElectricalLossToPercentDecimal
              : lossDisplayUnit === ElectricalLossUnit.MW
                ? formatElectricalLossToMWDecimal
                : formatElectricalLossToGWhDecimal,
          ) ?? "-"}{" "}
        </p>
      }
    >
      <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
        <p>{"Inter array cable loss:"}</p>
        {interArrayLossEnabled ? (
          <p>
            {nullMap(
              loss.totalInterArrayCableLoss,
              lossDisplayUnit === ElectricalLossUnit.Percent
                ? formatElectricalLossToPercentDecimal
                : lossDisplayUnit === ElectricalLossUnit.MW
                  ? formatElectricalLossToMWDecimal
                  : formatElectricalLossToGWhDecimal,
            ) ?? "-"}
          </p>
        ) : (
          <Disabled
            text={
              "Inter-array cable loss is disabled in the current analysis configuration."
            }
          />
        )}

        <p>{"Turbine transformer loss:"}</p>
        {turbineTrafoLossEnabled ? (
          <p>
            {nullMap(
              loss.totalTurbineTrafoLoss,
              lossDisplayUnit === ElectricalLossUnit.Percent
                ? formatElectricalLossToPercentDecimal
                : lossDisplayUnit === ElectricalLossUnit.MW
                  ? formatElectricalLossToMWDecimal
                  : formatElectricalLossToGWhDecimal,
            ) ?? "-"}
          </p>
        ) : (
          <Disabled
            text={
              "Turbine transformer loss is disabled in the current analysis configuration."
            }
          />
        )}
      </ColoredGrid>
    </NewToggleableList>
  );
};

const ExportSystemLosses = ({
  loss,
  lossDisplayUnit,
}: {
  loss: TotalExportSystemLoss;
  lossDisplayUnit: ElectricalLossUnit;
}) => {
  return (
    <NewToggleableList
      title="Total export system loss"
      chevronSize="1rem"
      defaultOpen={false}
      value={
        <p>
          {nullMap(
            loss.totalExportSystemLoss,
            lossDisplayUnit === ElectricalLossUnit.Percent
              ? formatElectricalLossToPercentDecimal
              : lossDisplayUnit === ElectricalLossUnit.MW
                ? formatElectricalLossToMWDecimal
                : formatElectricalLossToGWhDecimal,
          ) ?? "-"}
        </p>
      }
    >
      <ColoredGrid style={{ gridTemplateColumns: "4fr 2fr" }}>
        <ResultValue>Export cable loss</ResultValue>
        <p>
          {nullMap(
            loss.totalExportCableLoss,
            lossDisplayUnit === ElectricalLossUnit.Percent
              ? formatElectricalLossToPercentDecimal
              : lossDisplayUnit === ElectricalLossUnit.MW
                ? formatElectricalLossToMWDecimal
                : formatElectricalLossToGWhDecimal,
          ) ?? "-"}
        </p>

        <ResultValue>Offshore transformer loss</ResultValue>
        <p>
          {nullMap(
            loss.totalOffshoreTrafoLoss,
            lossDisplayUnit === ElectricalLossUnit.Percent
              ? formatElectricalLossToPercentDecimal
              : lossDisplayUnit === ElectricalLossUnit.MW
                ? formatElectricalLossToMWDecimal
                : formatElectricalLossToGWhDecimal,
          ) ?? "-"}
        </p>

        <ResultValue>Onshore transformer loss</ResultValue>
        <p>
          {nullMap(
            loss.totalOnshoreTrafoLoss,
            lossDisplayUnit === ElectricalLossUnit.Percent
              ? formatElectricalLossToPercentDecimal
              : lossDisplayUnit === ElectricalLossUnit.MW
                ? formatElectricalLossToMWDecimal
                : formatElectricalLossToGWhDecimal,
          ) ?? "-"}
        </p>
      </ColoredGrid>
    </NewToggleableList>
  );
};

const ToggleableLoader = ({ text }: { text: string }) => (
  <ColoredGrid style={{ gridTemplateColumns: "5fr 3fr" }}>
    <OverlineText>{text}</OverlineText>
    <SkeletonText style={{ alignSelf: "center", height: "1.8rem" }} />
  </ColoredGrid>
);

const Stats = ({ park }: { park: ParkFeature }) => {
  const onshore = useAtomValue(isOnshoreAtom);
  const areaFeatures = useAtomValue(selectedAreaAtom) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const cables = useAtomValue(selectedCablesAtom);
  const turbines = useAtomValue(selectedTurbinesAtom);
  const substations = useAtomValue(selectedSubstationsAtom);
  const chains = useAtomValue(selectedCableChainsAtom);

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const redundancyCables = cables?.filter((c) => c.properties.redundancy);

  const cableLengths = useAtomValue(
    loadable(
      cable3DLengthsFamily({
        parkId: park.id,
        branchId: undefined,
        analysisConfigurationId: undefined,
      }),
    ),
  );

  const aggregates = lmap(cableLengths, (lengths) => {
    const values = Array.from(lengths.values()).map((o) => o.contingent);
    const totalHorizontal = sum(
      Array.from(lengths.values()),
      (l) => l.straight,
    );
    return {
      shortest: fastMin(values, 0),
      longest: fastMax(values, 0),
      total: sum(values),
      totalHorizontal,
    };
  });

  const cableIAId = mostCommon(cables.map((c) => c.properties.cableTypeId));

  const allCableTypes = useAtomValue(
    cableTypesFamily({ projectId: undefined }),
  );
  const voltage = undefMap(allCableTypes.get(cableIAId ?? "")?.voltage, (v) =>
    parseOr(_IAVoltageType, v, undefined),
  );

  const exportSystemType = useAtomValue(
    getExportSystemTypeInParkAndBranchFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );

  const selectedAnalysisConfig = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId: projectId,
      branchId: branchId,
    }),
  );

  const setConfigurationMenuActive = useSetAtom(configurationMenuActiveAtom);

  const [interArraySystemOpen, setInterArraySystemOpen] = useState(true);

  return (
    <>
      <Row style={{ justifyContent: "left", flexWrap: "wrap" }}>
        {selectedAnalysisConfig && (
          <Tag
            maxWidth="20rem"
            onClick={() => {
              setConfigurationMenuActive(true);
            }}
            icon={
              <IconREMSize
                height={1.4}
                width={1.4}
                iconColor={colors.iconNegative}
              >
                <StatisticsIcon />
              </IconREMSize>
            }
            dark
            text={`${selectedAnalysisConfig?.name}`}
            tooltip="Selected analysis configuration"
          />
        )}
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag text="Park" />
        )}
        <Tag text={`${selectedArea} km²`} />
        {turbines && <NumberOfTurbinesTag n={turbines.length} />}
        {substations && <NumberOfSubstationsTag n={substations.length} />}
        <ExportSystemTypeTag n={exportSystemType} />
      </Row>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setInterArraySystemOpen(!interArraySystemOpen);
        }}
      >
        <SubtitleWithLine
          text={"Inter array cabling"}
          expandButton={
            <ChevronIcon
              open={interArraySystemOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {interArraySystemOpen && (
        <ColoredGrid style={{ gridTemplateColumns: "5fr 3fr" }}>
          <ResultValue>No. of cable segments</ResultValue>
          <ResultValue>
            <strong>{cables?.length ?? 0}</strong>
          </ResultValue>

          <ResultValue>Cable segment lengths</ResultValue>
          {orLoader(aggregates, ({ shortest, longest }) => (
            <ResultValue>
              <strong>
                {shortest === 0 && longest === 0
                  ? "0"
                  : `${shortest.toFixed(2)} - ${longest.toFixed(2)}`}
              </strong>{" "}
              km
            </ResultValue>
          ))}

          <ResultValue>Number of chains</ResultValue>
          <ResultValue>
            <strong>{chains?.length ?? 0}</strong>
          </ResultValue>

          {redundancyCables !== undefined && (
            <>
              <ResultValue>Redundancy cables</ResultValue>
              <ResultValue>
                <strong>{redundancyCables.length}</strong>
              </ResultValue>
            </>
          )}

          <ResultValue>Inter array voltage</ResultValue>
          <ResultValue>
            <strong>{voltage ? voltage : 0}</strong> kV
          </ResultValue>
          <Tooltip text="The horizontal cable length, plus the distance up to the water surface at the cable ends and contingency.">
            <ResultValue style={{ margin: 0 }}>Total cable length</ResultValue>
          </Tooltip>
          {orLoader(aggregates, ({ total }) => (
            <ResultValue>
              <strong>{total === 0 ? "0" : `${total.toFixed(2)}`}</strong> km
            </ResultValue>
          ))}
          <Tooltip text="The horizontal cable length, excluding contingency.">
            <ResultValue style={{ margin: 0 }}>
              Total horizontal length
            </ResultValue>
          </Tooltip>
          {orLoader(aggregates, ({ totalHorizontal }) => (
            <ResultValue>
              <strong>
                {totalHorizontal === 0 ? "0" : `${totalHorizontal.toFixed(2)}`}
              </strong>{" "}
              km
            </ResultValue>
          ))}
        </ColoredGrid>
      )}

      {onshore ? <ExportSystemOnshore /> : <ExportSystemOffshore />}
      <ElectricalLosses />
      <Visualisations />
    </>
  );
};

export const ElectricalStatistics = ({
  onClose,
  parkId,
}: {
  onClose(): void;
  parkId: string;
}) => {
  const park = useAtomValue(parkFamily({ parkId, branchId: undefined }));
  const branchId = useAtomValue(branchIdAtom);
  if (!park) return null;

  return (
    <MenuFrame
      title={"Electrical"}
      icon={<HelpLink article={ARTICLE_CABLE_STATS} />}
      onExit={onClose}
      validationError={
        <ElectricalStatError parkId={park.id} branchId={branchId ?? ""} />
      }
    >
      <Suspense
        fallback={
          <SkeletonBlock
            style={{ height: "1rem", margin: "1rem", width: "unset" }}
          />
        }
      >
        <Inner park={park} />
      </Suspense>
    </MenuFrame>
  );
};
