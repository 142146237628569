import { MooringCoords } from "functions/mooring";
import * as THREE from "three";

export function defaultMooringLineMaterial() {
  return new THREE.LineBasicMaterial({
    color: 0xd3d3d3,
    depthWrite: false,
    depthTest: false,
    linewidth: 10,
  });
}

export function createMooringLineGeometry(
  mooringCoords: MooringCoords,
): THREE.BufferGeometry {
  const points: THREE.Vector3[] = [];
  for (let i = 0; i < mooringCoords.x.length; i++) {
    points.push(
      new THREE.Vector3(
        mooringCoords.x[i],
        mooringCoords.y[i],
        mooringCoords.z[i],
      ),
    );
  }
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  return geometry;
}

export function createClumpWeightGeometry(
  x: number,
  y: number,
  z: number,
): THREE.BufferGeometry {
  const geometry = new THREE.SphereGeometry(8, 32, 16);
  geometry.translate(x, y, z);
  return geometry;
}
