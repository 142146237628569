import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import Spinner from "@icons/spinner/Spinner";
import { toastMessagesAtom } from "../../state/toast";
import { Mixpanel } from "../../mixpanel";
import useBooleanState from "../../hooks/useBooleanState";
import Checkbox from "../General/Checkbox";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import { useCollectionCrud } from "../LayerList/Collections/useCollectionCrud";
import { getLayerCollection } from "../LayerList/Collections/state";
import { isCustomLayer, isPrivateSource } from "../LayerList/utils";
import { sendWarning } from "../../utils/sentry";
import ProjectPicker from "components/Organisation/ProjectPicker";
import { getNodesWithMissingParents } from "components/Projects/useOrganisationFolderCrud";
import { MenuFrame } from "components/MenuPopup/CloseableMenuPopup";
import { Column, Row } from "components/General/Layout";
import { useAtomValue, useSetAtom } from "jotai";
import { isDefined } from "utils/predicates";
import { useJotaiCallback } from "utils/jotai";
import {
  ACCESS_ROLE_TO_NUMBER,
  userNodeAccessSelectorFamily,
} from "state/user";
import { useToast } from "hooks/useToast";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";

export const CopyCollectionModalType = "CopyCollectionModal" as const;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.8rem;
`;

const ProjectPickerWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
`;

const CopyCollectionModalInner = ({
  collectionId,
}: {
  collectionId: string;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);
  const { copyCollection } = useCollectionCrud();
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [includeCustomLayers, toggleIncludeCustomLayers] =
    useBooleanState(true);
  const [clonePrivateSources, toggleClonePrivateSources] =
    useBooleanState(true);
  const { warning } = useToast();

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);

  const topNodes = useAtomValue(
    getNodesWithMissingParents({
      organisationId,
    }),
  );

  const collection = useAtomValue(
    getLayerCollection({
      collectionId,
      projectId,
    }),
  );
  const [selectedProjectId, setSelectedProjectId] = useState<
    undefined | string
  >("");

  const customLayerIds = useMemo<string[]>(() => {
    if (!collection) {
      return [];
    }

    return collection.layers.filter(isCustomLayer).map((layer) => layer.id);
  }, [collection]);

  const privateSourceUrls = useMemo<string[]>(() => {
    if (!collection) {
      return [];
    }

    return [
      ...new Set(
        collection.layers
          .filter(
            (layer) => isDefined(layer.source) && isPrivateSource(layer.source),
          )
          .flatMap((layer) =>
            "sourceLink" in layer ? layer.sourceLink?.url : undefined,
          )
          .filter(isDefined),
      ),
    ];
  }, [collection]);

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  const onSave = useJotaiCallback(
    async (get) => {
      Mixpanel.track_old("Copy collection", {});
      if (!selectedProjectId) {
        return;
      }

      const selectedProjectAccess = get(
        userNodeAccessSelectorFamily({
          nodeId: selectedProjectId,
        }),
      );

      if (selectedProjectAccess < ACCESS_ROLE_TO_NUMBER["admin"]) {
        warning(
          "You cannot move items into project without admin access to it",
        );
        return;
      }

      setIsLoading(true);
      try {
        await copyCollection(
          collectionId,
          selectedProjectId,
          includeCustomLayers ? customLayerIds : [],
          includeCustomLayers ? [] : customLayerIds,
          clonePrivateSources ? privateSourceUrls : [],
        );

        setToastMessagesAtom((curr) => [
          ...curr,
          {
            text: "The collection was copied to your other project",
            type: "success",
            timeout: 5000,
          },
        ]);
        onClose();
      } catch (error) {
        sendWarning("Something went wrong when trying to copy collection", {
          error,
          collection,
        });
        setToastMessagesAtom((curr) => [
          ...curr,
          {
            text: "Something went wrong when copying the collection, please try again.",
            type: "error",
            timeout: 10000,
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    },
    [
      selectedProjectId,
      copyCollection,
      collectionId,
      includeCustomLayers,
      customLayerIds,
      clonePrivateSources,
      privateSourceUrls,
      setToastMessagesAtom,
      onClose,
      collection,
      warning,
    ],
  );

  return (
    <FullScreenModal>
      <MenuFrame
        title="Copy collection to project"
        onExit={onClose}
        style={{
          width: "50rem",
          height: "50vh",
        }}
      >
        <Column
          style={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <ProjectPickerWrapper>
            <ProjectPicker
              selectedProjectId={selectedProjectId}
              setSelectedProjectId={setSelectedProjectId}
              topNodes={topNodes}
              allNodes={nodes}
            />
          </ProjectPickerWrapper>
          <ButtonRow>
            <Column>
              {customLayerIds.length > 0 && (
                <Checkbox
                  checked={includeCustomLayers}
                  onChange={toggleIncludeCustomLayers}
                  label="Include custom layers"
                  labelPlacement="after"
                />
              )}
              {privateSourceUrls.length > 0 && (
                <Checkbox
                  checked={clonePrivateSources}
                  onChange={toggleClonePrivateSources}
                  label={`Clone ${privateSourceUrls.length} private source${privateSourceUrls.length !== 1 ? "s" : ""} used in this collection`}
                  labelPlacement="after"
                />
              )}
            </Column>
            <Row alignCenter>
              <Button text="Cancel" buttonType="secondary" onClick={onClose} />
              <Button
                text="Copy"
                buttonType="primary"
                disabled={!selectedProjectId || isLoading}
                onClick={onSave}
                icon={isLoading ? <Spinner size="1rem" /> : undefined}
              />
            </Row>
          </ButtonRow>
        </Column>
      </MenuFrame>
    </FullScreenModal>
  );
};

const CopyCollectionModal = () => {
  const modalTypeOpen = useAtomValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== CopyCollectionModalType) {
    return null;
  }

  return (
    <CopyCollectionModalInner
      collectionId={modalTypeOpen.metadata.collectionId}
    />
  );
};

export default CopyCollectionModal;
