import * as turf from "@turf/turf";
import { Feature, Point as GeoJsonPoint } from "geojson";
import { DateTime } from "luxon";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import CameraPositionImage from "@icons/CameraPosition.png";
import CameraPositionIcon from "@icons/24/CameraPosition.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import DownloadIcon from "@icons/24/Download.svg";
import InformationIcon from "@icons/24/Information.svg";
import OpenInfoIcon from "@icons/24/OpenInfo.svg";
import PlayIcon from "@icons/24/Play.svg?react";
import PauseIcon from "@icons/24/Pause.svg?react";
import { SetterOrUpdater } from "types/utils";
import styled from "styled-components";
import tzlookup from "tz-lookup";
import * as utm from "utm";
import { v4 as uuidv4 } from "uuid";
import { libraryLayersOpenAtom } from "state/layer";
import {
  VIEW_MODE,
  heightAboveGroundAtom,
  turbineScalingAtom,
  viewCameraAspectAtom,
  viewDateAtom,
  viewFovAtom,
  viewFromShoreParkIdAtom,
  viewHeightAtom,
  viewOrigoSelector,
  viewOrigoWGS84Atom,
  viewParkRotationAtom,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTowardsSelector,
  viewTowardsWGS84Atom,
  viewTurbineCoordsAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
  viewViewModeAtom,
  visibleParksAtom,
  viewTurbineDiameterAtom,
  viewFromShoreTerrainColorAtom,
  viewFromShoreTerrainColorActiveAtom,
  threeCoreAtom,
  viewFromShoreOSMBuildingsActiveAtom,
  osmBuildingTileStatusSelector,
  viewFromShoreFreeCameraAtom,
  VISIBILITY_MODE,
  viewParkFogStyleAtom,
  viewFromShoreFirstPersonCameraAngleAtom,
} from "state/viewToPark";
import Toggle, { ToggleSize } from "../../General/Toggle";
import { ToEastLatLng } from "utils/geojson/utils";
import { getBBOXArrayFromFeatures } from "utils/geojson/validate";
import { isDefined, isNumber } from "utils/predicates";
import { getDistanceFromLatLonInM, wgs84ToProjected } from "utils/proj4";
import { dedup, isInChecklyMode, usingMac } from "utils/utils";
import Dropdown from "../../Dropdown/Dropdown";
import Button from "../../General/Button";
import Checkbox from "../../General/Checkbox";
import { ColoredGrid, Label } from "../../General/Form";
import { Input } from "../../General/Input";
import { Column, Row } from "../../General/Layout";
import { Slider } from "../../General/Slider";
import LineString from "../../MapFeatures/LineString";
import Point from "../../MapFeatures/Point";
import Polygon from "../../MapFeatures/Polygon";
import { mapboxAccessToken } from "../../MapNative/constants";
import { ChevronIcon } from "../../ToggleableList/ToggleableList";
import { SkeletonText } from "components/Loading/Skeleton";
import { IconREMSize, typography } from "styles/typography";
import { mapContext } from "../../RightSide/InfoModal/ProjectFeatureInfoModal/MapContextProvider";
import { ParkFeature } from "types/feature";
import { useLocalStorage } from "hooks/useBrowserStorage";
import {
  DIVISION_FACTOR,
  ViewPositionMapBoxId,
  ViewOrigoPositionMapBoxId,
  ViewPointPositionMapBoxId,
  FOVLineMapBoxId,
  TerrainBBOXMapBoxSourceId,
  TerrainBBOXMapBoxLayerId,
  OSMMapBoxLayerId,
  OSMMapBoxSourceId,
  CONTROL_MAP_ELEMENT_ID,
} from "./constants";
import {
  LoadingBarWrapper,
  MenuFrame,
} from "../../MenuPopup/CloseableMenuPopup";
import {
  borderRadiusMedium,
  spaceTiny,
  spacing2,
  spacing4,
  spacing5,
  spacing6,
} from "styles/space";
import { colors } from "styles/colors";
import { currentMapStyleIdAtom, designToolTypeAtom } from "state/map";
import useTextInput from "../../../hooks/useTextInput";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderTurbines } from "components/Mapbox/Turbines";
import { parkIdAtom } from "state/pathParams";
import { verticalFovToHorizontalFov } from "./utils";
import ColorSelector from "components/ColorSelector/ColorSelector";
import useSystemSpecificUnicode from "hooks/useSystemSpecificUnicode";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  getDate,
  takePrintScreen,
} from "ScreenCaptureMode/ScreenCaptureWrapper";
import SplitButton from "components/General/SplitButton";
import { MenuItem } from "components/General/Menu";
import {
  Divider,
  InputTitle,
  OverlineText,
  SubtitleWithLine,
} from "components/General/GeneralSideModals.style";
import { RangeWithDimInput } from "components/General/RangeWithDimInput";
import { loggedInUserIsInternalSelector } from "state/user";
import {
  getLoadingTerrainTilesFromMapbox,
  terrainPatchesRequestedAtom,
} from "./ThreeContext/useDynamicTerrain";
import { tile2bbox } from "types/tile";
import { getFOVLines, getFOVLinesFromAngle } from "./ThreeContext/utils";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { turbinesFamily, turbinesInParkFamily } from "state/jotai/turbine";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import { parksFamily } from "state/jotai/park";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { viewPointsFamily } from "state/jotai/viewPoints";
import { DesignToolMode } from "types/map";
import { viewPointCirclePaintAtom } from "components/Mapbox/Viewpoint";
import { Tag } from "components/General/Tag";
import { LngLat } from "types/gis";
import HelpTooltip, {
  articleMap,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";
import { TypeDot } from "components/General/Icons";
import { TooltipText } from "components/General/Tooltip";
import AnimatedLoading from "@icons/AnimatedLoading/AnimatedLoading";
import DropdownButton from "components/General/Dropdown/DropdownButton";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import MakeMovable from "components/WithMovable/MakeMovable";
import { NATIVE_MAP_ID } from "components/MapNative/MapNative";
import { configurationMenuActiveAtom } from "components/RightSide/InfoModal/ProjectFeatureInfoModal/state";

mapboxgl.accessToken = mapboxAccessToken;

const CAMERA_ICON_IMAGE_NAME = "camera-icon";

const viewTowardsPointPaint: mapboxgl.CirclePaint = {
  "circle-radius": 6,
  "circle-color": colors.viewFromShoreViewTowardsPoint,
};

const fovPaint: mapboxgl.LinePaint = {
  "line-color": "#888",
  "line-width": 1,
};

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusMedium};

  .mapboxgl-canvas {
    border-radius: ${borderRadiusMedium};
  }
`;

const MapWithControlsWrapper = styled.div`
  position: relative;
  height: 43rem;
  width: 53rem;
  border-radius: ${borderRadiusMedium};
`;

const HideIfNotHover = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
  cursor: move;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  overflow: hidden;
  place-items: stretch;
  gap: 2rem;
  z-index: 1;
  padding: ${spacing6} ${spacing4};
  background-color: ${colors.surfacePrimary}bb;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;

  &:hover {
    ${HideIfNotHover} {
      visibility: visible;
    }
  }
`;

const ControlBasic = styled.div`
  ${typography.contentAndButtons}
  display: flex;
  align-items: center;
  gap: ${spacing2};
  white-space: nowrap;
  overflow: hidden;

  &:nth-of-type(1) {
    justify-content: flex-start;
  }
  &:nth-of-type(2) {
    justify-content: center;
  }
  &:nth-of-type(3) {
    justify-content: flex-end;
  }
`;

const ControlValue = styled(ControlBasic)`
  background-color: ${colors.blue50};
  border-radius: ${borderRadiusMedium};
  padding: ${spacing2} ${spacing4};
`;

const TurbineTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-top: 1rem;
`;

const TurbineHeader = styled.p`
  font-weight: 600;
`;

const CollapsableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const MapAndControllerWrapper = styled.div<{
  configurationMenuActive?: boolean;
}>`
  height: calc(
    100vh - calc(var(--top-bar-menu-height) + var(--branch-tab-bar-height)) -
      ${({ configurationMenuActive }) =>
        configurationMenuActive
          ? `var(--branch-configuration-menu-height)`
          : "0rem"} - 2rem
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
`;

const HintPositioner = styled.div`
  position: absolute;
  right: ${spacing5};
  bottom: ${spacing5};
  z-index: 1;
`;

const HintPopupWrapper = styled.div`
  ${typography.body}
  padding: ${spacing6};
  background-color: ${colors.surfaceBrand};
  color: ${colors.surfacePrimary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    margin: ${spaceTiny};
  }
`;

const TurbineInformation = () => {
  const parkId = useAtomValue(parkIdAtom);
  const turbineScaling = useAtomValue(turbineScalingAtom);
  const turbineTypes = useAtomValue(simpleTurbineTypesAtom);
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId: parkId ?? "",
      branchId: undefined,
    }),
  );

  const usedTurbineTypes = useMemo(
    () =>
      dedup(turbines.map((t) => t.properties.turbineTypeId).filter(isDefined)),
    [turbines],
  );

  const usedTurbines = useMemo(
    () => usedTurbineTypes.map((t) => turbineTypes.get(t)).filter(isDefined),
    [usedTurbineTypes, turbineTypes],
  );

  const isScaled = turbineScaling !== 1;

  return (
    <>
      {usedTurbines.length === 1 && (
        <Row
          style={{
            flexWrap: "wrap",
          }}
        >
          <Tag text={usedTurbines[0].name} />
          <Tag
            text={`Height (m): ${Math.round(usedTurbines[0].hubHeight * turbineScaling)} ${isScaled ? "*" : ""}`}
          />
          <Tag
            text={`Diameter (m): ${Math.round(usedTurbines[0].diameter * turbineScaling)} ${isScaled ? "*" : ""}`}
          />
        </Row>
      )}
      {usedTurbines.length > 1 && (
        <TurbineTable>
          <TurbineHeader>Turbine</TurbineHeader>
          <TurbineHeader>Height (m)</TurbineHeader>
          <TurbineHeader>Diameter (m)</TurbineHeader>
          {usedTurbines.map((t) => (
            <React.Fragment key={t.id}>
              <p>{t.name}</p>
              <p>
                {Math.round(t.hubHeight * turbineScaling)}
                {isScaled ? "*" : ""}
              </p>
              <p>
                {Math.round(t.diameter * turbineScaling)}
                {isScaled ? "*" : ""}
              </p>
            </React.Fragment>
          ))}
        </TurbineTable>
      )}
      {isScaled && <div>* scaled size</div>}
    </>
  );
};

export const ViewToPark = ({
  park,
  branchId,
  turbineHeights,
  turbineDiameters,
  onClose,
}: {
  park: ParkFeature;
  branchId: string;
  turbineHeights: Map<string, number>;
  turbineDiameters: Map<string, number>;
  onClose(): void;
}) => {
  const [viewPosition, setViewPosition] = useAtom(viewPositionAtom);
  const setDate = useSetAtom(viewDateAtom);
  const [fov, setFov] = useAtom(viewFovAtom);
  const [parkRotation, setParkRotation] = useAtom(viewParkRotationAtom);
  const [viewHeight, setViewHeight] = useAtom(viewHeightAtom);
  const setOrigoWGS84 = useSetAtom(viewOrigoWGS84Atom);
  const [viewTowardsWGS84, setViewTowardsWGS84] = useAtom(viewTowardsWGS84Atom);
  const cameraAspect = useAtomValue(viewCameraAspectAtom);
  const [viewMode, setViewMode] = useAtom(viewViewModeAtom);
  const [turbineLights, setTurbineLights] = useAtom(viewTurbineLightsAtom);
  const setParkId = useSetAtom(viewFromShoreParkIdAtom);
  const allParks = useAtomValue(
    parksFamily({
      branchId: undefined,
    }),
  );
  const visibleParks = useAtomValue(visibleParksAtom(park.id));
  const resetVisibleParksState = useResetAtom(visibleParksAtom(park.id));
  const onshoreMode =
    useAtomValue(designToolTypeAtom) === DesignToolMode.Onshore;

  const allTurbines = useAtomValue(
    turbinesFamily({
      branchId: undefined,
    }),
  );
  const allVisibleTurbines = useMemo(
    () =>
      allTurbines.filter((t) => {
        const parkId = t.properties.parentIds?.[0];
        return parkId && visibleParks[parkId];
      }),
    [allTurbines, visibleParks],
  );
  const [proj4String, setProj4String] = useAtom(viewProj4StringAtom);
  const location = useLocation();
  const setLibraryLayersOpen = useSetAtom(libraryLayersOpenAtom);

  useEffect(() => {
    setParkId(park.id);
  }, [park, setParkId]);

  useEffect(() => {
    setLibraryLayersOpen(false);
  }, [setLibraryLayersOpen]);

  useEffect(() => {
    return () => {
      resetVisibleParksState();
    };
  }, [resetVisibleParksState]);

  useEffect(() => {
    if (!park) {
      setViewPosition(undefined);
      return;
    }

    const origo = turf.center(park);
    const origoUTM = utm.fromLatLon(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );

    const timezone = tzlookup(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0],
    );
    setDate(
      DateTime.fromObject(
        {
          month: 8,
          hour: 18,
        },
        {
          zone: timezone,
        },
      ),
    );

    setProj4String(
      `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    const startingDistanceFromPark = onshoreMode ? 4 : 20;
    const id = uuidv4();
    setOrigoWGS84({
      ...origo,
      id,
      properties: {
        ...origo.properties,
        id,
      },
    });
    setViewTowardsWGS84({
      ...origo,
      id,
      properties: {
        ...origo.properties,
        id,
      },
    });
    setViewPosition(
      ToEastLatLng(
        {
          lng: origo.geometry.coordinates[0],
          lat: origo.geometry.coordinates[1],
        },
        startingDistanceFromPark,
      ),
    );
  }, [
    setProj4String,
    setOrigoWGS84,
    setViewTowardsWGS84,
    setViewPosition,
    park,
    setDate,
    onshoreMode,
  ]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("viewposlng") && query.get("viewposlat")) {
      setViewPosition({
        lng: parseFloat(query.get("viewposlng")!),
        lat: parseFloat(query.get("viewposlat")!),
      });
    }
  }, [location, setViewPosition]);

  const origo = useAtomValue(viewOrigoSelector);

  const setViewTurbineCoordsAtom = useSetAtom(viewTurbineCoordsAtom);
  const setViewTurbineHeightsAtom = useSetAtom(viewTurbineHeightsAtom);
  const setViewTurbineDiameterAtom = useSetAtom(viewTurbineDiameterAtom);

  useEffect(() => {
    if (!origo || !proj4String) {
      setViewTurbineCoordsAtom(undefined);
      return;
    }

    const coordinates = allVisibleTurbines.map((f) => {
      return wgs84ToProjected(f.geometry.coordinates, proj4String);
    });

    if (coordinates.length === 0) {
      setViewTurbineCoordsAtom([]);
      return;
    }

    setViewTurbineCoordsAtom(
      coordinates.map((coords) => [
        (coords[0] - origo[0]) / DIVISION_FACTOR,
        (coords[1] - origo[1]) / DIVISION_FACTOR,
      ]),
    );
    setViewTurbineHeightsAtom(
      allVisibleTurbines
        .map((f) => turbineHeights.get(f.properties.id))
        .filter(isDefined),
    );
    setViewTurbineDiameterAtom(
      allVisibleTurbines
        .map((f) => turbineDiameters.get(f.properties.id))
        .filter(isDefined),
    );
  }, [
    origo,
    proj4String,
    allVisibleTurbines,
    setViewTurbineCoordsAtom,
    setViewTurbineHeightsAtom,
    turbineHeights,
    setViewTurbineDiameterAtom,
    turbineDiameters,
  ]);

  if (!origo || !viewPosition) return null;

  return (
    <MapController
      onClose={onClose}
      park={park}
      branchId={branchId}
      allParks={allParks}
      viewPosition={viewPosition}
      setViewPosition={setViewPosition}
      fov={fov}
      setFov={setFov}
      parkRotation={parkRotation}
      setParkRotation={setParkRotation}
      viewTowardsWGS84={viewTowardsWGS84}
      setViewTowardsWGS84={setViewTowardsWGS84}
      cameraAspect={cameraAspect}
      viewMode={viewMode}
      setViewMode={setViewMode}
      viewHeight={viewHeight}
      setViewHeight={setViewHeight}
      turbineLights={turbineLights}
      setTurbineLights={setTurbineLights}
      proj4String={proj4String}
    />
  );
};

const TerrainInformation = () => {
  const terrainLoading = useAtomValue(getLoadingTerrainTilesFromMapbox);
  const [terrainColor, setTerrainColor] = useAtom(
    viewFromShoreTerrainColorAtom,
  );
  const [terrainColorActive, setTerrainColorActive] = useAtom(
    viewFromShoreTerrainColorActiveAtom,
  );
  const [terrainInfoOpen, setTerrainInfoOpen] = useState(false);
  if (terrainLoading.length !== 0) {
    return (
      <Column>
        <p>{`${terrainLoading.length}`} terrain tiles loading...</p>
        <SkeletonText
          style={{
            width: "100%",
          }}
        />
      </Column>
    );
  }

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setTerrainInfoOpen(!terrainInfoOpen);
        }}
      >
        <SubtitleWithLine
          text={"Terrain info"}
          expandButton={
            <ChevronIcon
              open={terrainInfoOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {terrainInfoOpen && (
        <>
          <ColoredGrid>
            <p>Terrain source:</p>
            <p>
              <a
                href={
                  "https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Mapbox
              </a>
            </p>
          </ColoredGrid>
          <OverlineText>Options</OverlineText>
          <Column
            style={{
              gap: "0.8rem",
              paddingBottom: "1.6rem",
            }}
          >
            <Label right>
              <InputTitle>Terrain color</InputTitle>
              <Toggle
                style={{
                  flex: "initial",
                }}
                checked={terrainColorActive}
                onChange={() => setTerrainColorActive(!terrainColorActive)}
                size={ToggleSize.SMALL}
              />
            </Label>
            {terrainColorActive && (
              <Label right>
                <InputTitle>Set terrain color</InputTitle>
                <ColorSelector
                  color={terrainColor}
                  setColor={(e) => {
                    setTerrainColor(e as string);
                  }}
                  position={"left"}
                />
              </Label>
            )}
          </Column>
        </>
      )}
    </>
  );
};

const BuildingInformation = () => {
  const { loading, error } = useAtomValue(osmBuildingTileStatusSelector);
  const [buildingInfoOpen, setBuildingInfoOpen] = useState(false);
  const [viewFromShoreOSMBuildingsActive, setViewFromShoreOSMBuildingsActive] =
    useAtom(viewFromShoreOSMBuildingsActiveAtom);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setBuildingInfoOpen(!buildingInfoOpen);
        }}
      >
        <SubtitleWithLine
          text={"Buildings"}
          expandButton={
            <ChevronIcon
              open={buildingInfoOpen}
              chevronSize={"1.4rem"}
              style={{
                alignSelf: "center",
              }}
            />
          }
        />
      </div>
      {buildingInfoOpen && (
        <>
          <ColoredGrid>
            <p>Buildings source:</p>
            <p>
              <a
                href={
                  "https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8#building"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Mapbox
              </a>
            </p>
          </ColoredGrid>
          <OverlineText>Options</OverlineText>
          <Column
            style={{
              gap: "0.8rem",
            }}
          >
            <Label right>
              <InputTitle>Show buildings</InputTitle>
              <Toggle
                style={{
                  flex: "initial",
                }}
                checked={viewFromShoreOSMBuildingsActive}
                onChange={() =>
                  setViewFromShoreOSMBuildingsActive(
                    !viewFromShoreOSMBuildingsActive,
                  )
                }
                size={ToggleSize.SMALL}
              />
            </Label>
          </Column>
          {viewFromShoreOSMBuildingsActive && loading && (
            <div>
              <p>Loading buildings...</p>
              <SkeletonText
                style={{
                  width: "100%",
                }}
              />
            </div>
          )}
          {viewFromShoreOSMBuildingsActive && error && (
            <SimpleAlert text={"Buildings failed to load"} type={"error"} />
          )}
        </>
      )}
    </>
  );
};

const MapController = ({
  turbineLights,
  setTurbineLights,
  park,
  branchId,
  viewPosition,
  setViewPosition,
  fov,
  setFov,
  parkRotation,
  setParkRotation,
  viewTowardsWGS84,
  setViewTowardsWGS84,
  cameraAspect,
  viewMode,
  setViewMode,
  viewHeight,
  setViewHeight,
  proj4String,
  allParks,
  onClose,
}: {
  turbineLights: boolean;
  setTurbineLights: SetterOrUpdater<boolean>;
  viewPosition: LngLat;
  fov: number;
  setFov: SetterOrUpdater<number>;
  parkRotation: number;
  setParkRotation: SetterOrUpdater<number>;
  viewTowardsWGS84: Feature<GeoJsonPoint> | null;
  setViewTowardsWGS84: SetterOrUpdater<Feature<GeoJsonPoint> | null>;
  cameraAspect: any;
  viewMode: VIEW_MODE;
  setViewMode: SetterOrUpdater<VIEW_MODE>;
  viewHeight: number;
  setViewHeight: SetterOrUpdater<number>;
  proj4String: string | undefined;
  park: ParkFeature;
  branchId: string;
  setViewPosition: SetterOrUpdater<LngLat | undefined>;
  allParks: ParkFeature[];
  onClose(): void;
}) => {
  const [turbineScaling, setTurbineScaling] = useAtom(turbineScalingAtom);
  const [viewParkFogStyle, setViewParkFogStyle] = useAtom(viewParkFogStyleAtom);
  const isInternal = useAtomValue(loggedInUserIsInternalSelector);
  const [freeCamera, setFreeCamera] = useAtom(viewFromShoreFreeCameraAtom);
  const [firstPersonCameraAngle, setFirstPersonCameraAngle] = useAtom(
    viewFromShoreFirstPersonCameraAngleAtom,
  );
  const viewTowards = useAtomValue(viewTowardsSelector);
  const viewPoints = useAtomValue(
    viewPointsFamily({
      branchId: undefined,
    }),
  );
  const designToolType = useAtomValue(designToolTypeAtom);
  const [date, setDate] = useAtom(viewDateAtom);
  const checkly = useMemo(() => isInChecklyMode(), []);
  const [visibleParks, setVisibleParks] = useAtom(visibleParksAtom(park.id));
  const [hintPopupHidden, setHintPopupHidden] = useLocalStorage<boolean>(
    "vind:view-from-shore-information-hidden",
    false,
  );
  const [tempLat, onTempLatChange] = useTextInput(String(viewPosition.lat));
  const [tempLng, onTempLngChange] = useTextInput(String(viewPosition.lng));

  const [chosenViewpointId, setChosenViewpointId] = useState<
    string | undefined
  >();

  // We will contain the moving of the map component inside this element
  const mapNativeElement = useMemo(
    () => document.getElementById(NATIVE_MAP_ID),
    [],
  );

  const mapContainer = useRef<HTMLDivElement>(null);
  const mapContextValue = useContext(mapContext);
  const hintPopup = useRef<mapboxgl.Popup>();
  const map = mapContextValue?.map;
  const location = useLocation();
  const activeMapStyleId = useAtomValue(currentMapStyleIdAtom);
  const threeCore = useAtomValue(threeCoreAtom);

  const bounds: LngLatBoundsLike = useMemo(() => {
    const viewpoint = viewPoints.find((vp) => vp.id === chosenViewpointId);

    const bbox = getBBOXArrayFromFeatures(
      viewpoint ? [park, viewpoint] : [park],
    );
    const buffer = 0.05;
    return [
      [bbox[0] - buffer, bbox[1] - buffer],
      [bbox[2] + buffer, bbox[3] + buffer],
    ];
  }, [chosenViewpointId, park, viewPoints]);

  useEffect(() => {
    map?.fitBounds(bounds, {
      padding: 30,
      animate: false,
    });
  }, [bounds, map]);

  const sortedParksWithSelectedFirst = useMemo(
    () => [...allParks].sort((a) => (a.id === park.id ? -1 : 0)),
    [park.id, allParks],
  );

  const visibleSortedParksWithSelectedFirst = useMemo(
    () =>
      sortedParksWithSelectedFirst.filter((visiblePark) =>
        Boolean(visibleParks[visiblePark.id]),
      ),
    [sortedParksWithSelectedFirst, visibleParks],
  );

  const allTurbines = useAtomValue(
    turbinesFamily({
      branchId: undefined,
    }),
  );
  const turbinesInVisibleParks = useMemo(() => {
    const ids = new Set(visibleSortedParksWithSelectedFirst.map((f) => f.id));
    return allTurbines.filter((t) =>
      ids.has(t.properties.parentIds?.[0] ?? ""),
    );
  }, [allTurbines, visibleSortedParksWithSelectedFirst]);

  useEffect(() => {
    if (
      !mapContextValue ||
      mapContextValue.map ||
      checkly ||
      !mapContainer.current
    )
      return;

    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      bounds,
    });
    newMap.dragRotate.disable();
    newMap.touchZoomRotate.disableRotation();

    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
      visualizePitch: false,
    });
    newMap.addControl(nav, "bottom-right");

    newMap.on("load", () => {
      newMap.resize();
      newMap.loadImage(CameraPositionImage, (error, image) => {
        if (error) throw error;
        if (image) newMap.addImage(CAMERA_ICON_IMAGE_NAME, image);
      });
      newMap.getCanvas().style.cursor = "crosshair";
      mapContextValue.setMap(newMap);
    });
  }, [mapContainer, bounds, checkly, mapContextValue, activeMapStyleId]);

  useEffect(() => {
    if (map) {
      // syncs the div ref <=> mapbox container ref
      document
        .getElementById(CONTROL_MAP_ELEMENT_ID)
        ?.replaceWith(map.getContainer());
      map.getCanvas().style.cursor = "crosshair";
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const onClick = (e: mapboxgl.MapMouseEvent) => {
      const { metaKey, altKey } = e.originalEvent;
      setFirstPersonCameraAngle(undefined);
      if (metaKey || altKey) {
        setViewTowardsWGS84({
          ...viewTowardsWGS84!,
          geometry: {
            ...viewTowardsWGS84!.geometry,
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
        });
      } else {
        setChosenViewpointId(undefined);
        setViewPosition(e.lngLat);
      }
    };
    map.on("click", onClick);
    return () => {
      map.off("click", onClick);
    };
  }, [
    setViewPosition,
    viewTowardsWGS84,
    setViewTowardsWGS84,
    location,
    map,
    setFirstPersonCameraAngle,
  ]);

  useEffect(() => {
    if (!map || !viewPosition) return;
    map.addSource(ViewPositionMapBoxId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [viewPosition.lng, viewPosition.lat],
            },
            properties: {},
          },
        ],
      },
    });
    map.addLayer({
      id: ViewPositionMapBoxId,
      type: "symbol",
      source: ViewPositionMapBoxId,
      layout: {
        "icon-image": CAMERA_ICON_IMAGE_NAME,
        "icon-size": 0.5,
        "icon-offset": [0, 2],
      },
    });
    return () => {
      map.removeLayer(ViewPositionMapBoxId);
      map.removeSource(ViewPositionMapBoxId);
    };
  }, [map, viewPosition]);

  const viewTowardsPoint = useMemo<Feature[]>(() => {
    if (!viewTowardsWGS84) return [];
    return [viewTowardsWGS84];
  }, [viewTowardsWGS84]);

  const horizontalFOV = useMemo(
    () => verticalFovToHorizontalFov(cameraAspect, fov),
    [fov, cameraAspect],
  );

  const horizontalFOVFeatures = useMemo<Feature[]>(
    () =>
      firstPersonCameraAngle
        ? getFOVLinesFromAngle(
            viewTowards,
            firstPersonCameraAngle,
            horizontalFOV,
            proj4String,
            viewPosition,
          )
        : getFOVLines(viewTowards, horizontalFOV, proj4String, viewPosition),
    [
      viewTowards,
      viewPosition,
      horizontalFOV,
      proj4String,
      firstPersonCameraAngle,
    ],
  );

  const distanceToViewPoint = useMemo(
    () =>
      (
        getDistanceFromLatLonInM(viewTowardsWGS84!.geometry.coordinates, [
          viewPosition.lng,
          viewPosition.lat,
        ]) / 1000
      ).toFixed(1),
    [viewPosition, viewTowardsWGS84],
  );

  const onToggleLightsClick = useCallback(() => {
    setTurbineLights(!turbineLights);
  }, [setTurbineLights, turbineLights]);

  const heightAboveGround = useAtomValue(heightAboveGroundAtom);

  const relevantInvalidTypes = useAtomValue(
    invalidTypesInParkFamily({
      parkId: park.id,
      branchId,
    }),
  ).turbines;

  const download4K = () => {
    if (threeCore && park) {
      const formattedDate = getDate();
      takePrintScreen(
        [3840, 2160],
        threeCore.camera.aspect,
        threeCore,
        `${park.properties.name ?? "Unnamed_park"}_${formattedDate}.png`,
      );
    }
  };

  const downloadPanorama = async () => {
    if (!threeCore?.camera || !threeCore?.renderer || !fov || !park) return;

    const horizontalFOV = verticalFovToHorizontalFov(
      threeCore.camera.aspect,
      fov,
    );

    if (!horizontalFOV) return;

    const formattedDate = getDate();
    const resolution = [3840 * 3, 2160] as [number, number];
    takePrintScreen(
      resolution,
      resolution[0] / resolution[1],
      threeCore,
      `${park.properties.name ?? "Unnamed_park"}_${formattedDate}_${horizontalFOV * 3}_deg`,
    );
  };

  useEffect(() => {
    const val = parseFloat(tempLat);
    if (!isNumber(val)) {
      return;
    }

    setViewPosition((coords?: LngLat) =>
      coords
        ? {
            ...coords,
            lat: val,
          }
        : undefined,
    );
  }, [setViewPosition, tempLat]);

  useEffect(() => {
    const val = parseFloat(tempLng);
    if (!isNumber(val)) {
      return;
    }

    setViewPosition((coords?: LngLat) =>
      coords
        ? {
            ...coords,
            lng: val,
          }
        : undefined,
    );
  }, [setViewPosition, tempLng]);

  const [timeLapse, setTimeLapse] = useState<boolean>(false);

  const [visibleParksOpen, setVisibleParksOpen] = useState(false);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const [otherOpen, setOtherOpen] = useState(false);

  useEffect(() => {
    if (!timeLapse) return;
    const thisDate = DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
    });
    let count = 0;
    let interval = setInterval(() => {
      setDate(
        thisDate.plus({
          minutes: 10 * count,
        }),
      );
      count += 1;
    }, 100);
    return () => {
      clearInterval(interval);
      setTimeLapse(false);
    };
  }, [timeLapse, setDate, date.year, date.month, date.day]);

  const systemUnicode = useSystemSpecificUnicode();

  const patchesRequested = useAtomValue(terrainPatchesRequestedAtom);
  const terrainLoading = useAtomValue(getLoadingTerrainTilesFromMapbox);

  const patchesRequestedPolygons = useMemo(() => {
    return Object.values(patchesRequested).map((patch) => {
      const tileBBOX = tile2bbox({
        x: patch.x,
        y: patch.y,
        z: patch.z,
      });
      const polygon = turf.bboxPolygon(tileBBOX);
      const id = uuidv4();
      return {
        ...polygon,
        id,
        properties: {
          id,
          loading: terrainLoading.some(
            (t) => t.x === patch.x && t.y === patch.y && t.z === patch.z,
          ),
        },
      };
    });
  }, [patchesRequested, terrainLoading]);
  const { finished: osmBuildingFeatures, loading: osmBuildingsLoading } =
    useAtomValue(osmBuildingTileStatusSelector);

  const viewpointCirclePaint = useAtomValue(viewPointCirclePaintAtom);

  const viewpointDropdownItems = useMemo<DropDownItem[]>(() => {
    const defaultValue: DropDownItem = {
      value: "disabled",
      name: "Viewpoints",
      isTitle: true,
      disabled: true,
    };

    if (viewPoints.length === 0) {
      return [defaultValue].concat({
        value: "no-viewpoints-available",
        name: "No viewpoints available",
        disabled: true,
      });
    }
    return [defaultValue].concat(
      viewPoints.map<DropDownItem>((vp) => ({
        value: vp.id,
        name: vp.properties.name!,
      })),
    ) satisfies DropDownItem[];
  }, [viewPoints]);

  const mapControlsRef = useRef<HTMLDivElement>(null);
  const configurationMenuActive = useAtomValue(configurationMenuActiveAtom);
  return (
    <MapAndControllerWrapper configurationMenuActive={configurationMenuActive}>
      <MenuFrame
        title={
          designToolType === DesignToolMode.Offshore
            ? "View from shore"
            : "View park"
        }
        onExit={onClose}
      >
        {relevantInvalidTypes ? (
          <SimpleAlert
            text={"Some turbines in the park have invalid turbine types."}
          />
        ) : (
          <Column
            style={{
              gap: 0,
            }}
          >
            <div
              style={{
                maxHeight: `calc(72vh - 40rem - ${configurationMenuActive ? `var(--branch-configuration-menu-height)` : "0rem"} )`,
                overflowY: "auto",
              }}
            >
              <TurbineInformation />
              <div
                style={{
                  cursor: "pointer",
                  paddingTop: `${spaceTiny}`,
                }}
                onClick={() => {
                  setVisibleParksOpen(!visibleParksOpen);
                }}
              >
                <SubtitleWithLine
                  text={"Visible parks"}
                  expandButton={
                    <ChevronIcon
                      open={visibleParksOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {visibleParksOpen && (
                <CollapsableContentWrapper>
                  {sortedParksWithSelectedFirst.map((park) => {
                    const checked = visibleParks[park.id] ?? false;
                    return (
                      <Checkbox
                        key={park.id + checked}
                        checked={checked}
                        onChange={(e) => {
                          e.preventDefault();
                          setVisibleParks((state) => ({
                            ...state,
                            [park.id]: !checked,
                          }));
                        }}
                        label={park.properties.name}
                        labelPlacement="after"
                      />
                    );
                  })}
                </CollapsableContentWrapper>
              )}
              <div
                style={{
                  cursor: "pointer",
                  paddingTop: `${spaceTiny}`,
                }}
                onClick={() => {
                  setSettingsOpen(!settingsOpen);
                }}
              >
                <SubtitleWithLine
                  text={"Settings"}
                  expandButton={
                    <ChevronIcon
                      open={settingsOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {settingsOpen && (
                <CollapsableContentWrapper>
                  {viewPosition && (
                    <Label>
                      <InputTitle>Coordinates (lat/lng)</InputTitle>
                      <Row
                        style={{
                          alignItems: "baseline",
                        }}
                      >
                        <Input
                          compact
                          style={{
                            width: "100%",
                          }}
                          value={parseFloat(tempLat).toFixed(4)}
                          onChange={onTempLatChange}
                        />
                        <Input
                          compact
                          style={{
                            width: "100%",
                          }}
                          value={parseFloat(tempLng).toFixed(4)}
                          onChange={onTempLngChange}
                        />
                      </Row>
                    </Label>
                  )}
                  <Label>
                    <InputTitle>Date</InputTitle>
                    <Input
                      compact
                      type="date"
                      value={date.toISO()?.split("T")[0]}
                      onChange={(e) => {
                        if (!date.zoneName || e.target.value === "") return;

                        const newDate = DateTime.fromISO(e.target.value)
                          .setZone(date.zoneName)
                          .set({
                            hour: date.hour,
                            minute: date.minute,
                          });

                        setDate(newDate);
                      }}
                    />
                  </Label>
                  <Label>
                    <InputTitle>Time</InputTitle>
                    <Row
                      style={{
                        alignItems: "baseline",
                      }}
                    >
                      <Input
                        compact
                        style={{
                          width: "70%",
                        }}
                        type="time"
                        value={`${date.hour.toString().padStart(2, "0")}:${date.minute.toString().padStart(2, "0")}`}
                        onChange={(e) => {
                          const [hour, minute] = e.target.value
                            .split(":")
                            .map((t) => parseInt(t));
                          const newDate = date.set({
                            hour: hour,
                            minute: minute,
                          });
                          setDate(newDate);
                        }}
                      />
                      <Slider
                        min={0}
                        max={24 * 60 - 1}
                        step={1}
                        value={Math.round(date.hour * 60 + date.minute)}
                        onChange={(minutes) => {
                          const newDate = date.set({
                            hour: Math.floor(minutes / 60),
                            minute: minutes % 60,
                          });
                          setDate(newDate);
                        }}
                      />
                      <Button
                        text=""
                        icon={timeLapse ? <PauseIcon /> : <PlayIcon />}
                        buttonType="secondary"
                        onClick={() => {
                          if (!timeLapse) {
                            setTimeLapse(true);
                          } else {
                            setTimeLapse(false);
                          }
                        }}
                      />
                    </Row>
                  </Label>

                  <Label>
                    <InputTitle>Park rotation</InputTitle>
                    <RangeWithDimInput
                      min={0}
                      max={360}
                      inputStep={1}
                      value={parkRotation}
                      onChange={(r) => {
                        setParkRotation(r);
                      }}
                      unit={"deg"}
                    />
                  </Label>

                  <Label>
                    <InputTitle>View height</InputTitle>
                    <RangeWithDimInput
                      inputStep={1}
                      min={1}
                      max={100}
                      value={viewHeight}
                      onChange={(n) => {
                        setViewHeight(n);
                      }}
                      unit={"m"}
                    />
                  </Label>

                  <Label>
                    <InputTitle>Turbine size scaling</InputTitle>
                    <RangeWithDimInput
                      inputStep={0.1}
                      min={0.5}
                      max={1.5}
                      value={turbineScaling}
                      onChange={(n) => {
                        setTurbineScaling(n);
                      }}
                      unit={"x"}
                    />
                  </Label>
                  {cameraAspect && (
                    <Label>
                      <InputTitle>Field of view (vert/hor)</InputTitle>
                      <Slider
                        min={25}
                        max={35}
                        step={1}
                        value={fov}
                        onChange={(e) => {
                          setFov(Math.round(e));
                        }}
                        label
                        renderLabel={() => `${fov}° / ${horizontalFOV}°`}
                      />
                    </Label>
                  )}
                  <Row style={{ alignItems: "center", gap: "0rem" }}>
                    <Label>
                      <InputTitle>Visibility</InputTitle>
                    </Label>
                    <HelpLink article={articleMap.viewParkVisibility} />
                  </Row>

                  <Dropdown
                    value={viewParkFogStyle}
                    small={true}
                    onChange={(e) => {
                      setViewParkFogStyle(e.target.value as VISIBILITY_MODE);
                    }}
                  >
                    <option value={VISIBILITY_MODE.FULL_VISIBILITY_MODE}>
                      Full visibility
                    </option>
                    <option value={VISIBILITY_MODE.REDUCED_VISIBILITY_MODE}>
                      Reduced visibility
                    </option>
                  </Dropdown>
                </CollapsableContentWrapper>
              )}

              <TerrainInformation />
              <BuildingInformation />
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setOtherOpen(!otherOpen)}
              >
                <SubtitleWithLine
                  text={"Other"}
                  expandButton={
                    <ChevronIcon
                      open={otherOpen}
                      chevronSize={"1.4rem"}
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  }
                />
              </div>
              {otherOpen && (
                <Column
                  style={{
                    gap: "0.8rem",
                    paddingBottom: "1.2rem",
                  }}
                >
                  <Label right>
                    <InputTitle>Lights (BETA)</InputTitle>
                    <Toggle
                      style={{
                        flex: "initial",
                      }}
                      checked={turbineLights}
                      onChange={onToggleLightsClick}
                      size={ToggleSize.SMALL}
                    />
                  </Label>
                  <Label right>
                    <InputTitle>Wireframe view</InputTitle>
                    <Toggle
                      style={{
                        flex: "initial",
                      }}
                      checked={viewMode === VIEW_MODE.WIRE_FRAME_MODE}
                      onChange={() => {
                        if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
                          setViewMode(VIEW_MODE.NATURAL_MODE);
                        } else {
                          setViewMode(VIEW_MODE.WIRE_FRAME_MODE);
                        }
                      }}
                      size={ToggleSize.SMALL}
                    />
                  </Label>
                  {isInternal && (
                    <Label right>
                      <InputTitle>Free camera (INTERNAL)</InputTitle>
                      <Toggle
                        style={{
                          flex: "initial",
                        }}
                        checked={freeCamera}
                        onChange={() => setFreeCamera(!freeCamera)}
                        size={ToggleSize.SMALL}
                      />
                    </Label>
                  )}
                </Column>
              )}
            </div>
            <Divider
              style={{
                marginBottom: "1.6rem",
                marginLeft: "-2rem",
                marginRight: "-2rem",
              }}
            />

            <Row
              style={{
                justifyContent: "end",
              }}
            >
              <SplitButton
                buttonType="secondary"
                text="Screenshot"
                icon={<DownloadIcon />}
                onClick={download4K}
              >
                <MenuItem
                  name="4K Screenshot"
                  onClick={download4K}
                  defaultButton
                ></MenuItem>
                <MenuItem name="Panorama" onClick={downloadPanorama}></MenuItem>
              </SplitButton>
            </Row>
          </Column>
        )}
      </MenuFrame>
      <Column>
        <MakeMovable
          mover={mapControlsRef.current}
          container={mapNativeElement}
        >
          <MapWithControlsWrapper>
            {(terrainLoading.length !== 0 || osmBuildingsLoading) && (
              <LoadingBarWrapper
                block={false}
                style={{
                  zIndex: 2,
                }}
              />
            )}
            <ControlsWrapper>
              <ControlBasic>
                <DropdownButton
                  size="small"
                  style={{
                    // maxWidth: "100%",
                    backgroundColor: "white",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  onOptionHover={(item) => {
                    const viewPoint = viewPoints.find(
                      (vp) => vp.id === item.value,
                    );

                    if (viewPoint) {
                      map?.setFeatureState(
                        {
                          source: ViewPointPositionMapBoxId,
                          id: viewPoint.id,
                        },
                        {
                          selected: true,
                        },
                      );
                    }
                  }}
                  onOptionMouseLeave={(item) => {
                    const viewPoint = viewPoints.find(
                      (vp) => vp.id === item.value,
                    );
                    if (viewPoint) {
                      map?.removeFeatureState(
                        {
                          source: ViewPointPositionMapBoxId,
                          id: viewPoint.id,
                        },
                        "selected",
                      );
                    }
                  }}
                  selectedItemValue={chosenViewpointId}
                  items={viewpointDropdownItems}
                  buttonText={
                    viewPoints.find(
                      (viewPoint) => viewPoint.id === chosenViewpointId,
                    )?.properties.name ?? "Select Viewpoint"
                  }
                  renderText={(item) => {
                    return (
                      <Row
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <IconREMSize
                          height={1.6}
                          width={1.6}
                          iconColor={colors.grey600}
                        >
                          <CameraPositionIcon />
                        </IconREMSize>
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color:
                              item === "Select Viewpoint"
                                ? colors.grey600
                                : undefined,
                          }}
                        >
                          {item}
                        </p>
                      </Row>
                    );
                  }}
                  onSelectItem={(val) => {
                    const selectedViewPoint = viewPoints.find(
                      (vp) => vp.id === val,
                    );
                    if (!selectedViewPoint) {
                      return;
                    }

                    setChosenViewpointId(selectedViewPoint.id);
                    setViewPosition({
                      lng: selectedViewPoint.geometry.coordinates[0],
                      lat: selectedViewPoint.geometry.coordinates[1],
                    });
                  }}
                />
              </ControlBasic>
              <ControlBasic>
                Distance to{" "}
                <TypeDot
                  style={{
                    minWidth: "unset",
                    minHeight: "unset",
                    width: spacing4,
                    height: spacing4,
                  }}
                  dotColor={colors.viewFromShoreViewTowardsPoint}
                />
                <ControlValue>{distanceToViewPoint} km</ControlValue>
                <HelpTooltip
                  color={colors.iconInfo}
                  place="bottom"
                  content={
                    <>
                      <TooltipText secondary={false} theme="dark">
                        <Row>
                          Distance from camera position{" "}
                          <IconREMSize
                            height={1.6}
                            width={1.6}
                            iconColor="white"
                          >
                            {" "}
                            <CameraPositionIcon />
                          </IconREMSize>
                        </Row>{" "}
                        <Row alignCenter>
                          {" "}
                          to the point where the camera looks{" "}
                          <TypeDot
                            style={{
                              minWidth: "unset",
                              minHeight: "unset",
                              width: spacing4,
                              height: spacing4,
                            }}
                            dotColor={colors.viewFromShoreViewTowardsPoint}
                          />
                        </Row>
                      </TooltipText>
                    </>
                  }
                  style={{
                    display: "inline-flex",
                  }}
                  size={12}
                />
              </ControlBasic>
              <ControlBasic>
                Ground height{" "}
                <ControlValue>
                  {terrainLoading.length > 0 ? (
                    <AnimatedLoading
                      $size="1.4rem"
                      $highlightColor={colors.blue100}
                    />
                  ) : (
                    `${Math.round(heightAboveGround)} m`
                  )}
                </ControlValue>
              </ControlBasic>
              <HideIfNotHover>
                <Row alignCenter ref={mapControlsRef}>
                  <DnDIconSmall />
                </Row>
              </HideIfNotHover>
            </ControlsWrapper>
            <MapWrapper id={CONTROL_MAP_ELEMENT_ID} ref={mapContainer} />
            {map && (
              <>
                <RenderParks
                  map={map}
                  parks={visibleSortedParksWithSelectedFirst}
                />
                <RenderTurbines map={map} turbines={turbinesInVisibleParks} />
                <Point
                  features={viewTowardsPoint}
                  sourceId={ViewOrigoPositionMapBoxId}
                  layerId={ViewOrigoPositionMapBoxId}
                  map={map}
                  paint={viewTowardsPointPaint}
                />
                <Point
                  features={viewPoints}
                  sourceId={ViewPointPositionMapBoxId}
                  layerId={ViewPointPositionMapBoxId}
                  map={map}
                  paint={viewpointCirclePaint}
                />
                <LineString
                  features={horizontalFOVFeatures}
                  map={map}
                  layerId={FOVLineMapBoxId}
                  sourceId={FOVLineMapBoxId}
                  paint={fovPaint}
                />
                <Polygon
                  features={osmBuildingFeatures}
                  sourceId={OSMMapBoxSourceId}
                  layerId={OSMMapBoxLayerId}
                  map={map}
                  paint={{
                    "fill-color": "#fff",
                    "fill-opacity": 1.0,
                  }}
                  linePaint={{
                    "line-color": "#000",
                    "line-width": 1,
                    "line-opacity": 0.5,
                  }}
                />
                <Polygon
                  features={patchesRequestedPolygons}
                  sourceId={TerrainBBOXMapBoxSourceId}
                  layerId={TerrainBBOXMapBoxLayerId}
                  map={map}
                  paint={{
                    "fill-color": [
                      "case",
                      ["boolean", ["get", "loading"], false],
                      colors.green200,
                      "#77bb77",
                    ],
                    "fill-opacity": 0.3,
                  }}
                  linePaint={{
                    "line-opacity": 0,
                  }}
                />
              </>
            )}
            <HintPositioner>
              {hintPopupHidden ? (
                <IconREMSize
                  style={{
                    cursor: "pointer",
                  }}
                  height={2}
                  width={2}
                  iconColor={colors.surfaceBrand}
                  onClick={() => {
                    setHintPopupHidden(false);
                  }}
                >
                  <OpenInfoIcon />
                </IconREMSize>
              ) : (
                <HintPopupWrapper>
                  {hintPopupHidden ? (
                    <IconREMSize height={1.2} width={1.2} iconColor="white">
                      <InformationIcon />
                    </IconREMSize>
                  ) : (
                    <>
                      <Row>
                        <IconREMSize height={1.6} width={1.6} iconColor="white">
                          <CameraPositionIcon />
                        </IconREMSize>{" "}
                        Where camera is placed
                      </Row>
                      <Row alignCenter>
                        <TypeDot
                          style={{
                            minWidth: "unset",
                            minHeight: "unset",
                            width: spacing4,
                            height: spacing4,
                          }}
                          dotColor={colors.viewFromShoreViewTowardsPoint}
                        />{" "}
                        Where camera looks at
                      </Row>
                      <Row>Left click to move camera position</Row>
                      <Row>
                        Left click +{" "}
                        {usingMac()
                          ? systemUnicode("command")
                          : systemUnicode("option")}{" "}
                        to move where camera looks
                      </Row>

                      <Button
                        text="Got it - Hide info"
                        size="small"
                        onClick={() => {
                          hintPopup.current?.remove();
                          setHintPopupHidden(true);
                        }}
                      />
                    </>
                  )}
                </HintPopupWrapper>
              )}
            </HintPositioner>
          </MapWithControlsWrapper>
        </MakeMovable>
      </Column>
    </MapAndControllerWrapper>
  );
};
