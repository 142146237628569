import { roundToDecimal } from "utils/utils";
import { ContentTableRow, CaptionText } from "../style";
import { colors } from "styles/colors";

const TurbineRatedPower = ({
  ratedPower,
  isSelected,
}: {
  ratedPower: number | undefined;
  isSelected: boolean;
}) => {
  const selectedStyle = isSelected
    ? {
        backgroundColor: colors.surfaceSelectedLight,
      }
    : {};

  return (
    <ContentTableRow
      style={{
        ...selectedStyle,
        flex: 1,
      }}
    >
      <CaptionText>
        {ratedPower ? `${roundToDecimal(ratedPower / 1e3, 1)}MW` : ""}
      </CaptionText>
    </ContentTableRow>
  );
};

export default TurbineRatedPower;
