import { useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import AnchorIcon from "@icons/24/Anchor.svg?react";
import DeleteIcon from "@icons/24/Bin.svg?react";

import { inReadOnlyModeSelector } from "../../../../state/project";
import { selectedMenuItemState } from "../../Shared/state";
import {
  MooringLineType,
  _MooringLineType,
} from "../../../../services/mooringLineTypeService";
import useMooringLineTypeCrud from "../../../../hooks/useMooringLineTypeCrud";
import {
  DIAMETER_DEFAULT,
  EA_DEFAULT,
  MATERIAL_DEFAULT,
  SingleLineType,
  WETWEIGHT_DEFAULT,
} from "../../../ConfigurationModal/MooringSettings";
import { toastMessagesAtom } from "../../../../state/toast";
import { DefaultCosts } from "../../../ConfigurationModal/Cost/constants";
import { CostUnit } from "../../../../types/financial";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import { useAtom, useAtomValue } from "jotai";
import { mooringLineTypesAtom } from "state/jotai/mooringLineType";
import AddIcon from "@icons/24/Add.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { IconREMSize } from "styles/typography";
import Button from "components/General/Button";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";

export const MOORING_MENU_ID = "mooring";

export default function useMooringSettings() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const isReadOnly = useAtomValue(inReadOnlyModeSelector);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { update, create, deleteMooringLine } = useMooringLineTypeCrud();
  const lineTypes = useAtomValue(mooringLineTypesAtom);
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [isProjectLineCollapsed, setIsProjectLineCollapsed] =
    useState<boolean>(false);

  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: MOORING_MENU_ID,
      projectId,
    }),
  );

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = Array.from(lineTypes.values())[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [lineTypes, menuSelection, setMenuSelection]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addMooringLine = useCallback(
    async (mooringLine: Partial<MooringLineType>) => {
      setIsLoading(true);
      setSaveInProgress(true);
      await create(mooringLine)
        .then((result) => {
          if (result) {
            setMenuSelection(result.id);
          }
        })
        .finally(() => {
          setSaveInProgress(false);
          setIsLoading(false);
        });
    },
    [create, setMenuSelection, setSaveInProgress],
  );

  const _onDuplicate = useCallback(
    async (lineId: string, name: string) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "mooring",
          defaultName: `${name} (duplicate)`,
          onDuplicate: async (name: string) => {
            const line = lineTypes.get(lineId);
            if (!line) {
              return;
            }
            setIsLoading(true);
            setSaveInProgress(true);
            const clone = JSON.parse(
              JSON.stringify(line),
            ) as Partial<MooringLineType>;
            clone.name = name;
            delete clone.id;

            try {
              await create(clone);
            } catch {
            } finally {
              setSaveInProgress(false);
              setIsLoading(false);
            }
          },
        },
      });
    },
    [create, lineTypes, setSaveInProgress, setMidScreenModalTypeOpen],
  );

  const _onDelete = useCallback(
    async (lineId: string) => {
      setIsLoading(true);
      try {
        await deleteMooringLine(lineId);
      } catch {}
      setIsLoading(false);
    },
    [deleteMooringLine],
  );

  const _onUpdate = useCallback(
    async (newMooringLine: MooringLineType) => {
      setIsLoading(true);

      const valid = _MooringLineType.safeParse(newMooringLine).success;
      if (!valid) return;

      try {
        await update(newMooringLine);
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Saved",
            timeout: 3000,
            type: "success",
          },
        ]);
      } catch {}
      setIsLoading(false);
    },
    [setToastMessages, update],
  );

  const mooringSubMenus: SettingsSubMenuProp[] = useMemo(() => {
    if (!lineTypes) return [];

    const lineList = Array.from(lineTypes.values()).map((line) => {
      return {
        id: line.id,
        label: line.name,
        loading: isLoading,
        content: (
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <SingleLineType
              disabled={isReadOnly}
              key={line.id}
              line={line}
              onSave={_onUpdate}
              isLoading={isLoading}
              nodeId={projectId}
            />
          </div>
        ),
        onDuplicate: undefined,
        onChangeName: (newName: string) => {
          return _onUpdate({
            ...line,
            name: newName,
          });
        },
        dotMenuOptions: [
          {
            title: "Delete",
            onSelect: _onDelete,
            icon: <DeleteIcon />,
          },
          {
            title: "Duplicate",
            onSelect: () => _onDuplicate(line.id, line.name),
            icon: <DuplicateIcon />,
          },
        ],
      };
    });
    return [
      {
        title: "Project specific components",
        items: lineList,
        isCollapsed: isProjectLineCollapsed,
        icon: (
          <IconREMSize height={0.6} width={0.6}>
            <ExpandArrowWrapper
              open={!isProjectLineCollapsed}
              onClick={() => setIsProjectLineCollapsed(!isProjectLineCollapsed)}
            >
              <ChevronDownIcon />
            </ExpandArrowWrapper>
          </IconREMSize>
        ),
      },
    ];
  }, [
    lineTypes,
    isLoading,
    isReadOnly,
    _onUpdate,
    projectId,
    _onDuplicate,
    _onDelete,
    isProjectLineCollapsed,
  ]);

  const createNewLine = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <Button
          disabled={isLoading}
          onClick={() =>
            addMooringLine({
              name: `Line type ${lineTypes.size + 1}`,
              material: MATERIAL_DEFAULT,
              diameter: DIAMETER_DEFAULT,
              EA: EA_DEFAULT,
              wetWeight: WETWEIGHT_DEFAULT,
              cost: DefaultCosts.mooring.line[CostUnit.millionEuroPerKM],
              costUnit: CostUnit.millionEuroPerKM,
            })
          }
          buttonType="primary"
          icon={<AddIcon />}
        />
      ),
    };
  }, [saveInProgress, isLoading, lineTypes, addMooringLine]);

  const topLevelMenu = useMemo(() => {
    return {
      id: MOORING_MENU_ID,
      label: "Mooring lines",
      icon: <AnchorIcon />,
      submenus: mooringSubMenus,
      createNew: createNewLine,
    };
  }, [mooringSubMenus, createNewLine]);

  return topLevelMenu;
}
