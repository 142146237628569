import { CapexEntry } from "../../../../../services/costService";
import { Input, InputDimensioned, TextArea } from "../../../../General/Input";
import { localCostConfigurationAtom } from "../../state";
import { TextIcon } from "../../../../../styles/typography";
import Trashcan from "@icons/24/Bin.svg?react";
import { spaceTiny } from "../../../../../styles/space";
import { CostUnit } from "../../../../../types/financial";
import { isDefined } from "../../../../../utils/predicates";
import { CostDivider, DividerWrapper, GridWithFiveColumns } from "../../styles";
import { ConfidenceLevelSelector } from "../../ConfidenceLevelSelector";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { getCustomArrayChangeLogValue } from "components/InputChangelog/utils";
import { ChangelogCategory } from "components/InputChangelog/types";
import { useAtom } from "jotai";

const CostInput = ({
  entry,
  originalEntry,
  autoFocus,
  units = [],
  isReadOnly,
  nodeId,
  category,
}: {
  entry: CapexEntry;
  originalEntry?: CapexEntry;
  autoFocus: boolean;
  units?: CostUnit[];
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
}) => {
  const [localConfig, setLocalConfig] = useAtom(localCostConfigurationAtom);
  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  return (
    <>
      <GridWithFiveColumns>
        <div>
          <Input
            value={entry.name}
            autoFocus={autoFocus}
            disabled={isReadOnly}
            onChange={(e) => {
              setLocalConfig({
                ...localConfig,
                capex: {
                  ...localConfig.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, name: e.target.value } : c,
                    ) ?? [],
                },
              });
            }}
            style={{ width: "100%" }}
          />
        </div>
        <div>
          <InputDimensioned
            style={{ width: "100%" }}
            unit={entry.unit}
            units={units}
            value={entry.cost}
            initialValue={originalEntry?.cost}
            validate={(value) => isDefined(value) && value >= 0}
            validationMessage={"Must be greater than or equal to 0"}
            disabled={isReadOnly}
            changelogInfo={financialChangelogInfo(
              localConfig.id,
              nodeId,
              `${entry.id}`,
              category,
              (obj) =>
                getCustomArrayChangeLogValue(
                  obj,
                  "capex.custom",
                  `id.${entry.id}`,
                  ["cost", "name", "unit"],
                ),
            )}
            onChange={(cost) => {
              setLocalConfig({
                ...localConfig,
                capex: {
                  ...localConfig.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, cost } : c,
                    ) ?? [],
                },
              });
            }}
            onUnitChange={(unit) => {
              setLocalConfig({
                ...localConfig,
                capex: {
                  ...localConfig.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id ? { ...c, unit } : c,
                    ) ?? [],
                },
              });
            }}
          />
        </div>
        {useConfidenceLevel && (
          <ConfidenceLevelSelector
            disabled={isReadOnly}
            onConfidenceLevelChange={(confidenceLevel) => {
              setLocalConfig({
                ...localConfig,
                capex: {
                  ...localConfig.capex,
                  custom:
                    localConfig?.capex.custom.map((c) =>
                      c.id === entry.id
                        ? {
                            ...c,
                            confidenceLevel: confidenceLevel,
                          }
                        : c,
                    ) ?? [],
                },
              });
            }}
            confidenceLevel={entry.confidenceLevel}
          />
        )}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={entry.freeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                setLocalConfig({
                  ...localConfig,
                  capex: {
                    ...localConfig.capex,
                    custom:
                      localConfig?.capex.custom.map((c) =>
                        c.id === entry.id
                          ? { ...c, freeText: e.target.value }
                          : c,
                      ) ?? [],
                  },
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
        {!isReadOnly && (
          <TextIcon
            onClick={() =>
              setLocalConfig({
                ...localConfig,
                capex: {
                  ...localConfig.capex,
                  custom:
                    localConfig?.capex.custom.filter(
                      (c) => c.id !== entry.id,
                    ) ?? [],
                },
              })
            }
            scale={1.2}
            style={{
              padding: spaceTiny,
              alignSelf: "center",
            }}
          >
            <Trashcan />
          </TextIcon>
        )}
      </GridWithFiveColumns>
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </>
  );
};

export default CostInput;
