import { useAtomValue } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { useState } from "react";
import {
  LibraryManageRole,
  LibraryManageRoleType,
  LibraryResourceType,
} from "components/Organisation/Library/types";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import useNodeTurbineResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeTurbineResourcesCrud";
import useNodeFoundationResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFoundationResourcesCrud";
import useNodeAnalysisResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeAnalysisResourcesCrud";
import useNodeCableResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeCableResourcesCrud";
import useNodeFinancialResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeFinancialResourcesCrud";
import useNodeDataPackageResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeDataPackageResourcesCrud";
import Earth from "@icons/14/Earth.svg";
import Folder from "@icons/14/Folder.svg";
import {
  ContentTableRow,
  TextEllipsis,
} from "components/Organisation/OrganisationRightSide/style";
import { SVGWrapper } from "@icons/svgUtils";
import { TextIcon } from "styles/typography";
import { spaceTiny } from "styles/space";
import RemoveIcon from "@icons/24/Remove.svg";
import Tooltip from "components/General/Tooltip";
import styled from "styled-components";
import { colors } from "styles/colors";
import useNodeExportCableResourcesCrud from "../../NodeContent/useNodeExportCableResourcesCrud";
import useNodeSubstationResourcesCrud from "../../NodeContent/useNodeSubstationResourcesCrud";
import { ComponentUsageInNodeGeneral } from "components/ConfigurationModal/SettingsUsage/UsedInProjects";
import { getIcon } from "components/General/getIconFromRole";

export const UsageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 4rem;
  gap: ${spaceTiny};
`;

export function SingleNodeView({
  nodeId,
  resourceId,
  organisationId,
  type,
  canRemove,
}: {
  nodeId: string;
  resourceId: string;
  organisationId: string;
  canRemove: boolean;
  type: LibraryResourceType;
}) {
  const node = useAtomValue(
    getNodeSelectorFamily({
      organisationId,
      nodeId: nodeId,
    }),
  );

  const { remove: _removeTurbine } = useNodeTurbineResourcesCrud();
  const { remove: _removeFoundation } = useNodeFoundationResourcesCrud();
  const { remove: _removeAnalysis } = useNodeAnalysisResourcesCrud();
  const { remove: _removeCable } = useNodeCableResourcesCrud();
  const { remove: _removeExportCable } = useNodeExportCableResourcesCrud();
  const { remove: _removeFinancial } = useNodeFinancialResourcesCrud();
  const { remove: _removeDataPackage } = useNodeDataPackageResourcesCrud();
  const { remove: _removeSubstation } = useNodeSubstationResourcesCrud();

  const [saveInProgress, setSaveInProgress] = useState(false);

  const removeTurbine = useJotaiCallback(
    async (_get, _set, nodeId: string, turbineTypeId: string) => {
      try {
        setSaveInProgress(true);
        _removeTurbine(nodeId, turbineTypeId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeTurbine],
  );

  const removeFoundation = useJotaiCallback(
    async (_get, _set, nodeId: string, foundationId: string) => {
      try {
        setSaveInProgress(true);
        _removeFoundation(nodeId, foundationId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeFoundation],
  );

  const removeAnalysis = useJotaiCallback(
    async (_get, _set, nodeId: string, turbineTypeId: string) => {
      try {
        setSaveInProgress(true);
        _removeAnalysis(nodeId, turbineTypeId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeAnalysis],
  );

  const removeCable = useJotaiCallback(
    async (_get, _set, nodeId: string, cableId: string) => {
      try {
        setSaveInProgress(true);
        _removeCable(nodeId, cableId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeCable],
  );

  const removeExportCable = useJotaiCallback(
    async (_get, _set, nodeId: string, cableId: string) => {
      try {
        setSaveInProgress(true);
        _removeExportCable(nodeId, cableId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeExportCable],
  );

  const removeFinancial = useJotaiCallback(
    async (_get, _set, nodeId: string, financialConfigId: string) => {
      try {
        setSaveInProgress(true);
        _removeFinancial(nodeId, financialConfigId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeFinancial],
  );

  const removeDataPackage = useJotaiCallback(
    async (_get, _set, nodeId: string, financialConfigId: string) => {
      try {
        setSaveInProgress(true);
        _removeDataPackage(nodeId, financialConfigId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeDataPackage],
  );

  const removeSubstation = useJotaiCallback(
    async (_get, _set, nodeId: string, substationId: string) => {
      try {
        setSaveInProgress(true);
        _removeSubstation(nodeId, substationId);
      } catch (e) {
      } finally {
        setSaveInProgress(false);
      }
    },
    [setSaveInProgress, _removeSubstation],
  );

  if (!node) return <NoAccessNode />;
  const NodeIcon = node.type === "project" ? Earth : Folder;
  const libraryManageRole = getLibraryManageRole(type);

  return (
    <UsageGrid
      style={{
        cursor: "auto",
      }}
    >
      <ContentTableRow
        style={{
          cursor: "unset",
        }}
        disabled={saveInProgress}
      >
        <SVGWrapper size={1.4}>
          <NodeIcon />
        </SVGWrapper>
        <TextEllipsis
          style={{
            margin: 0,
          }}
        >
          {node.name}
        </TextEllipsis>
      </ContentTableRow>
      <ContentTableRow
        style={{
          cursor: "unset",
        }}
      >
        {libraryManageRole === "org_data_package_manage" ? null : (
          <ComponentUsageInNodeGeneral
            node={node}
            resourceId={resourceId}
            nodeId={nodeId}
            libraryManageRole={libraryManageRole}
            icon={getIcon(libraryManageRole)}
          />
        )}
      </ContentTableRow>
      {canRemove && (
        <ContentTableRow
          style={{
            cursor: "unset",
          }}
        >
          <TextIcon
            onClick={(e) => {
              if (saveInProgress) return;
              e.stopPropagation();
              if (type === "FOUNDATION") {
                removeFoundation(nodeId, resourceId);
              } else if (type === "TURBINE") {
                removeTurbine(nodeId, resourceId);
              } else if (type === "ANALYSIS") {
                removeAnalysis(nodeId, resourceId);
              } else if (type === "CABLE") {
                removeCable(nodeId, resourceId);
              } else if (type === "EXPORT_CABLE") {
                removeExportCable(nodeId, resourceId);
              } else if (type === "FINANCIAL") {
                removeFinancial(nodeId, resourceId);
              } else if (type === "DATAPACKAGE") {
                removeDataPackage(nodeId, resourceId);
              } else if (type === "SUBSTATION") {
                removeSubstation(nodeId, resourceId);
              }
            }}
            scale={1.2}
            style={{
              padding: spaceTiny,
              alignSelf: "center",
              marginLeft: "auto",
            }}
          >
            <RemoveIcon />
          </TextIcon>
        </ContentTableRow>
      )}
    </UsageGrid>
  );
}

const getLibraryManageRole = (
  type: LibraryResourceType,
): LibraryManageRoleType => {
  if (type === "FOUNDATION") return LibraryManageRole.FOUNDATION;
  if (type === "TURBINE") return LibraryManageRole.TURBINE;
  if (type === "ANALYSIS") return LibraryManageRole.ANALYSIS;
  if (type === "CABLE") return LibraryManageRole.CABLE;
  if (type === "EXPORT_CABLE") return LibraryManageRole.CABLE;
  if (type === "FINANCIAL") return LibraryManageRole.FINANCIAL;
  if (type === "SUBSTATION") return LibraryManageRole.SUBSTATION;
  if (type === "DATAPACKAGE") return LibraryManageRole.DATA_PACKAGE;
  throw new Error("Invalid resource type");
};

function NoAccessNode() {
  return (
    <Tooltip text="You don't have access to this project.">
      <ContentTableRow disabled={true}>
        <SVGWrapper size={1.4}>
          <Earth />
        </SVGWrapper>
        <TextEllipsis
          style={{
            margin: 0,
          }}
        >
          No access
        </TextEllipsis>
      </ContentTableRow>
    </Tooltip>
  );
}

export const NotUsedInAnyProjectsYet = () => {
  return (
    <ContentTableRow
      style={{
        backgroundColor: colors.surfaceSecondary,
      }}
    >
      <SVGWrapper size={1.4}>
        <Earth />
      </SVGWrapper>
      <TextEllipsis
        style={{
          margin: 0,
        }}
      >
        This resource is not used in any projects yet
      </TextEllipsis>
    </ContentTableRow>
  );
};
