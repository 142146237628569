import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { DropDownItem } from "components/General/Dropdown/DropdownItems";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "components/General/Dropdown/DropdownButton";
import Checkbox from "components/General/Checkbox";
import { foundationInstallationCategoriesToDisplayAtom } from "./FoundationsVessels";

export const FoundationInstallationCategorySelector = ({
  disabled,
  onSelectionChange,
}: {
  disabled?: boolean;
  onSelectionChange: (foundationCategory: string) => void;
}) => {
  const [foundationCategoriesToDisplay, setFoundationCategoriesToDisplay] =
    useAtom(foundationInstallationCategoriesToDisplayAtom);

  useEffect(() => {}, [setFoundationCategoriesToDisplay]);

  const items: DropDownItem[] = useMemo(() => {
    return [
      ...["Jacket", "Monopile", "Floating"].map((category) => {
        return {
          value: category,
          name: category,
          icon: (
            <Checkbox
              checked={foundationCategoriesToDisplay?.includes(category)}
              onChange={() => {
                setFoundationCategoriesToDisplay((prev) => {
                  if (prev.includes(category)) {
                    return prev.filter((c) => c !== category);
                  }
                  return [...prev, category];
                });
              }}
            />
          ),
        };
      }),
    ];
  }, [foundationCategoriesToDisplay, setFoundationCategoriesToDisplay]);

  return (
    <DropdownButton
      style={{ margin: "0 0 1rem 0" }}
      disabled={disabled}
      size={"fixed"}
      items={items}
      onSelectItem={(e) => {
        onSelectionChange(e as string);
      }}
      keepOpen={true}
      buttonText={"..."}
      renderText={() => (
        <ButtonTextWrapper>
          <ButtonText>
            {foundationCategoriesToDisplay.length === 3
              ? "All"
              : foundationCategoriesToDisplay.join(", ")}
          </ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
