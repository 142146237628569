import { useSetAtom } from "jotai";
import DeleteIcon from "@icons/24/Bin.svg?react";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import WindTurbineIcon from "@icons/24/WindTurbine.svg?react";

import ArrowTopRightIcon from "@icons/24/ArrowTopRight.svg?react";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { useProjectTurbineCrud } from "components/ConfigurationModal/TurbineSettings/useNodeTurbineCrud";
import { fromProjectToLibraryTabState } from "components/Organisation/Library/state";
import {
  organisationIdAtom,
  projectIdAtom,
} from "../../../../state/pathParams";
import {
  adminAccessProjectSelector,
  orgTurbineManageAccessSelector,
} from "../../../../state/user";
import { TurbineType } from "../../../../types/turbines";
import TurbineSettings from "../../../ConfigurationModal/TurbineSettings";
import { selectedMenuItemState } from "../../Shared/state";
import { SettingsSubMenuProp } from "components/SettingsV2/Shared/types";
import AddIcon from "@icons/24/Add.svg?react";
import Button from "components/General/Button";
import useNodeTurbineResourcesCrud from "components/Organisation/OrganisationRightSide/content/NodeContent/useNodeTurbineResourcesCrud";
import TurbineModal from "components/Organisation/OrganisationRightSide/content/ResourceContent/modals/TurbineModal";
import { Anchor } from "components/General/Anchor";
import { IconREMSize } from "styles/typography";
import { useDuplicateTurbineToLibrary } from "./useDuplicateToLibrary";
import { useDuplicateTurbineToProject } from "./useDuplicateToProject";
import { useLocation, useNavigate } from "react-router-dom";
import { TurbineWizard } from "../TurbineWizard";
import Tooltip from "components/General/Tooltip";
import { toastMessagesAtom } from "state/toast";
import { useAtom, useAtomValue } from "jotai";
import {
  defaultTurbineTypesFamily,
  libraryTurbineTypesFamily,
  projectTurbineTypesFamily,
} from "state/jotai/turbineType";
import { unwrap } from "jotai/utils";
import { designToolTypeAtom } from "state/map";
import SearchIcon from "@icons/24/Search.svg?react";
import { ExpandArrowWrapper } from "components/SettingsV2/Shared/styles";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import { Mixpanel } from "mixpanel";

export const TURBINE_MENU_ID = "turbines";

export default function useTurbineSettings() {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const navigate = useNavigate();
  const location = useLocation();

  const mode = useAtomValue(designToolTypeAtom);

  const adminAccessProject = useAtomValue(adminAccessProjectSelector);
  const orgTurbineManageAccess = useAtomValue(orgTurbineManageAccessSelector);

  const defaultTurbines = useAtomValue(defaultTurbineTypesFamily(mode));
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [saveInProgressLibrary, setSaveInProgressLibrary] =
    useState<boolean>(false);
  const [menuSelection, setMenuSelection] = useAtom(
    selectedMenuItemState({
      menuId: TURBINE_MENU_ID,
      projectId,
    }),
  );

  useEffect(() => {
    if (!menuSelection) {
      const firstItem = Array.from(defaultTurbines.values())[0];
      if (firstItem) {
        setMenuSelection(firstItem.id);
      }
    }
  }, [defaultTurbines, menuSelection, setMenuSelection]);

  const projectTurbines = useAtomValue(
    unwrap(projectTurbineTypesFamily(projectId)),
  );

  const libraryTurbines = useAtomValue(
    unwrap(libraryTurbineTypesFamily(projectId)),
  );

  const availableLibraryTurbines = useMemo(() => {
    if (!libraryTurbines) return [];
    return Array.from(libraryTurbines.values())
      .filter((t) => !t.archived)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [libraryTurbines]);

  const availableProjectTurbines = useMemo(() => {
    if (!projectTurbines) return [];
    return Array.from(projectTurbines.values())
      .filter((t) => !t.archived)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [projectTurbines]);

  const availableDefaultTurbines = useMemo(() => {
    if (!defaultTurbines) return [];
    return Array.from(defaultTurbines.values())
      .filter((t) => !t.archived)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [defaultTurbines]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProjectTurbineCollapsed, setIsProjectTurbineCollapsed] =
    useState<boolean>(false);
  const [isLibraryTurbineCollapsed, setIsLibraryTurbineCollapsed] =
    useState<boolean>(false);
  const [isDefaultTurbineCollapsed, setIsDefaultTurbineCollapsed] =
    useState<boolean>(false);
  const { create, update, duplicate, onChangeName, remove } =
    useProjectTurbineCrud();

  const { duplicateToLibrary } = useDuplicateTurbineToLibrary();
  const { duplicateToProject } = useDuplicateTurbineToProject();
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const setFromProjectToLibraryTab = useSetAtom(fromProjectToLibraryTabState);

  const turbineSubMenus = useMemo(() => {
    const libraryTurbineList = availableLibraryTurbines.map((turbine) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (orgTurbineManageAccess) {
        dotMenuOptions.push({
          title: "Edit Library component",
          onSelect: () => {
            Mixpanel.track_old("Click edit in library", {
              resource: "turbine",
            });

            let searchParams = new URLSearchParams(location.search);
            let newSearchString = searchParams.toString();
            setFromProjectToLibraryTab(true);

            navigate(
              `/organisation/${organisationId}/library/turbine/${turbine.id}?${newSearchString}`,
            );
          },
          icon: <ArrowTopRightIcon />,
        });
        if (adminAccessProject) {
          dotMenuOptions.push({
            title: "Create a project specific duplicate",
            onSelect: () => {
              setSaveInProgress(true);
              setIsLoading(true);
              duplicateToProject(turbine.id, turbine.name).then(() => {
                setSaveInProgress(false);
                setIsLoading(false);
              });
            },
            icon: <DuplicateIcon />,
          });
        }
      }
      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: turbine.id,
        label: `${turbine.name}`,
        loading: isLoading,
        content: (
          <div
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <TurbineSettings
              turbineId={turbine.id}
              nodeId={organisationId}
              accessToAdvancedTurbine={orgTurbineManageAccess}
              isLibraryTurbine={true}
            />
          </div>
        ),
        dotMenuOptions,
      };
    });

    const projectTurbineList = availableProjectTurbines.map((turbine) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (adminAccessProject) {
        dotMenuOptions = [
          {
            title: "Delete",
            onSelect: (turbineId: string) => {
              setIsLoading(true);
              setToastMessages((tm) => [
                ...tm,
                {
                  text: "Deleting...",
                  timeout: 2000,
                },
              ]);
              remove(turbineId).then(() => {
                setIsLoading(false);
              });

              //
            },
            icon: <DeleteIcon />,
          },
        ];

        dotMenuOptions.push({
          title: "Duplicate",
          onSelect: () => {
            setSaveInProgress(true);
            setIsLoading(true);
            duplicate(turbine.id, projectId).then(() => {
              setSaveInProgress(false);
              setIsLoading(false);
            });
          },
          icon: <DuplicateIcon />,
        });
        if (orgTurbineManageAccess) {
          dotMenuOptions.push({
            title: "Duplicate to Library",
            onSelect: () => {
              setSaveInProgressLibrary(true);
              setIsLoading(true);
              duplicateToLibrary(projectId, turbine).then(() => {
                setSaveInProgressLibrary(false);
                setIsLoading(false);
              });
            },
            icon: <DuplicateIcon />,
          });
        }
      }

      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: turbine.id,
        label: `${turbine.name}`,
        loading: isLoading,
        onDuplicate: undefined,
        onChangeName: (newName: string) => {
          return onChangeName(turbine.id, newName);
        },

        content: (
          <div
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <TurbineSettings
              turbineId={turbine.id}
              onSave={(
                turbine: TurbineType,
                onlyUpdatingDescription?: boolean,
              ) => {
                setIsLoading(true);
                return update(turbine, onlyUpdatingDescription).then(() =>
                  setIsLoading(false),
                );
              }}
              duplicate={(turbineId: string) => {
                setSaveInProgress(true);
                setIsLoading(true);
                duplicate(turbineId, projectId).then(() => {
                  setSaveInProgress(false);
                  setIsLoading(false);
                });
              }}
              nodeId={projectId}
              accessToAdvancedTurbine={adminAccessProject}
              isLibraryTurbine={false}
            />
          </div>
        ),
        dotMenuOptions,
      };
    });

    const defaultTurbinesList = availableDefaultTurbines.map((turbine) => {
      let dotMenuOptions:
        | {
            title: string;
            onSelect: (id: string) => void;
            icon: ReactElement;
          }[]
        | undefined = [];

      if (adminAccessProject) {
        dotMenuOptions = [
          {
            title: "Create a project specific duplicate",
            onSelect: () => {
              setSaveInProgress(true);
              setIsLoading(true);
              duplicate(turbine.id, projectId).then(() => {
                setSaveInProgress(false);
                setIsLoading(false);
              });
            },
            icon: <DuplicateIcon />,
          },
        ];
      }

      if (dotMenuOptions.length === 0) dotMenuOptions = undefined;

      return {
        id: turbine.id,
        label: `${turbine.name}`,
        loading: isLoading,
        onDuplicate: undefined,
        onChangeName: undefined,
        content: (
          <div
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <TurbineSettings
              turbineId={turbine.id}
              onSave={(
                turbine: TurbineType,
                onlyUpdatingDescription?: boolean,
              ) => {
                setIsLoading(true);
                return update(turbine, onlyUpdatingDescription).then(() =>
                  setIsLoading(false),
                );
              }}
              duplicate={(turbineId: string) => {
                setSaveInProgress(true);
                setIsLoading(true);
                duplicate(turbineId, projectId).then(() => {
                  setSaveInProgress(false);
                  setIsLoading(false);
                });
              }}
              nodeId={projectId}
              accessToAdvancedTurbine={adminAccessProject}
              isLibraryTurbine={false}
            />
          </div>
        ),
        dotMenuOptions,
      };
    });

    const libraryTurbinesMenu: SettingsSubMenuProp = {
      title: "Library components",
      items: libraryTurbineList,
      isCollapsed: isLibraryTurbineCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isLibraryTurbineCollapsed}
            onClick={() =>
              setIsLibraryTurbineCollapsed(!isLibraryTurbineCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const projectTurbinesMenu: SettingsSubMenuProp = {
      title: "Project specific components",
      items: projectTurbineList,
      isCollapsed: isProjectTurbineCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isProjectTurbineCollapsed}
            onClick={() =>
              setIsProjectTurbineCollapsed(!isProjectTurbineCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    const defaultTurbinesMenu: SettingsSubMenuProp = {
      title: "Vind AI components",
      items: defaultTurbinesList,
      isCollapsed: isDefaultTurbineCollapsed,
      icon: (
        <IconREMSize height={0.6} width={0.6}>
          <ExpandArrowWrapper
            open={!isDefaultTurbineCollapsed}
            onClick={() =>
              setIsDefaultTurbineCollapsed(!isDefaultTurbineCollapsed)
            }
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
        </IconREMSize>
      ),
    };

    return [libraryTurbinesMenu, projectTurbinesMenu, defaultTurbinesMenu];
  }, [
    availableLibraryTurbines,
    availableProjectTurbines,
    availableDefaultTurbines,
    setFromProjectToLibraryTab,
    isLibraryTurbineCollapsed,
    isProjectTurbineCollapsed,
    isDefaultTurbineCollapsed,
    orgTurbineManageAccess,
    projectId,
    adminAccessProject,
    setIsLoading,
    isLoading,
    organisationId,
    navigate,
    location.search,
    duplicateToProject,
    remove,
    duplicateToLibrary,
    duplicate,
    onChangeName,
    update,
    setToastMessages,
  ]);

  const addFromLibrary = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgressLibrary,
      element: <AddTurbineResourceToNode nodeId={projectId} />,
    };
  }, [saveInProgressLibrary, projectId]);
  const createNewTurbine = useMemo(() => {
    return {
      type: "element",
      saveInProgress: saveInProgress,
      element: (
        <TurbineWizardModal
          create={create}
          setLoading={setIsLoading}
          isLoading={isLoading}
          disabled={!adminAccessProject}
          tooltipText={
            !adminAccessProject
              ? "Only project admin users can add turbines"
              : undefined
          }
        />
      ),
    };
  }, [saveInProgress, adminAccessProject, isLoading, create]);

  const turbineSettings = useMemo(() => {
    return {
      id: TURBINE_MENU_ID,
      label: "Turbines",
      icon: <WindTurbineIcon />,
      submenus: turbineSubMenus,
      createNew: createNewTurbine,
      addFromLibrary: addFromLibrary,
    };
  }, [turbineSubMenus, createNewTurbine, addFromLibrary]);

  return turbineSettings;
}

const TurbineWizardModal = ({
  create,
  setLoading,
  isLoading,
  disabled,
  tooltipText,
}: {
  create: ({ turbine }: { turbine: TurbineType }) => Promise<any>;
  setLoading: (v: boolean) => void;
  isLoading: boolean;
  disabled?: boolean;
  tooltipText?: string | undefined;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Tooltip text={tooltipText ?? ""} disabled={!tooltipText}>
        <Button
          buttonType="primary"
          onClick={() => setShow(true)}
          icon={<AddIcon />}
          disabled={disabled || isLoading}
        />
        {show && (
          <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
            <TurbineWizard
              close={() => setShow(false)}
              create={(turbine) => create(turbine).then(() => setShow(false))}
              setLoading={setLoading}
            />
          </Anchor>
        )}
      </Tooltip>
    </div>
  );
};

function AddTurbineResourceToNode({ nodeId }: { nodeId: string }) {
  const turbineResources = useAtomValue(libraryTurbineTypesFamily(nodeId));
  const ref = useRef<HTMLDivElement>(null);
  const { addOrUpdate: addOrUpdateTurbine } = useNodeTurbineResourcesCrud();
  const [showAddTurbine, setShowAddTurbine] = useState(false);
  const orgTurbineManageAccess = useAtomValue(orgTurbineManageAccessSelector);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <Button
        buttonType="secondary"
        text="Add from library"
        onClick={() => setShowAddTurbine(true)}
        disabled={!orgTurbineManageAccess || saveInProgress}
        icon={<SearchIcon />}
      />
      {showAddTurbine && (
        <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
          <TurbineModal
            onSave={async (turbines) => {
              setSaveInProgress(true);
              await Promise.all(
                turbines.map((t) => addOrUpdateTurbine(nodeId, t.id)),
              );
              setSaveInProgress(false);
              setShowAddTurbine(false);
            }}
            existingTurbines={Array.from(turbineResources.keys())}
            isSaving={saveInProgress}
          />
        </Anchor>
      )}
    </div>
  );
}
