import { CostConfiguration, CostType } from "../../../../services/costService";

import { GeneralCost } from "./General/GeneralCost";
import { CostUnit } from "../../../../types/financial";

export const OtherCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  return (
    <GeneralCost
      title="Other"
      category={CostType.Other}
      configuration={configuration}
      isReadOnly={isReadOnly}
      units={[CostUnit.millionEuroPerMW, CostUnit.millionEuro]}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
    />
  );
};
