import { useAtom, useSetAtom } from "jotai";
import { useCallback, useState } from "react";
import {
  changedDataLayersAtom,
  changedDataSourcesAtom,
  hasUnsavedChangesAtom,
} from "../../../state/internalDataCleaning";
import Spinner from "@icons/spinner/Spinner";
import Button from "../../General/Button";
import FeatureFlag, { SHOW_DATA_CLEANING } from "../../General/FeatureFlag";
import {
  updateExternalDataLayer,
  updateExternalDataSource,
} from "../../../services/gisDataAPIService";
import { allDataSourcesRefreshAtom } from "../../../state/layer";

const SaveDataCleaningChangesInner = () => {
  const [changedDataLayers, setChangedDataLayers] = useAtom(
    changedDataLayersAtom,
  );
  const [changedDataSources, setChangedDataSources] = useAtom(
    changedDataSourcesAtom,
  );
  const setAllDataSourcesRefresh = useSetAtom(allDataSourcesRefreshAtom);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useAtom(
    hasUnsavedChangesAtom,
  );
  const [isLoading, setIsSaving] = useState(false);

  const saveChanges = useCallback(async () => {
    setIsSaving(true);

    await Promise.all([
      ...Object.values(changedDataLayers).map((layer) =>
        updateExternalDataLayer(layer),
      ),
      ...Object.values(changedDataSources).map((source) =>
        updateExternalDataSource(source),
      ),
    ]);
    setChangedDataLayers({});
    setChangedDataSources({});
    setIsSaving(false);
    setHasUnsavedChanges(false);
    setAllDataSourcesRefresh((curr) => curr + 1);
  }, [
    changedDataLayers,
    changedDataSources,
    setChangedDataLayers,
    setChangedDataSources,
    setHasUnsavedChanges,
    setAllDataSourcesRefresh,
  ]);

  if (!hasUnsavedChanges) {
    return null;
  }

  return (
    <Button
      text="Save changed layers"
      buttonType="primary"
      onClick={saveChanges}
      icon={isLoading ? <Spinner size="0.5rem" /> : undefined}
      disabled={isLoading}
    />
  );
};

const SaveDataCleaningChanges = () => (
  <FeatureFlag name={SHOW_DATA_CLEANING}>
    <SaveDataCleaningChangesInner />
  </FeatureFlag>
);

export default SaveDataCleaningChanges;
