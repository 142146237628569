import { atom } from "jotai";
import {
  CablePartitionFeature,
  SubstationFeature,
  TurbineFeature,
} from "types/feature";
import { atomFamily } from "utils/jotai";
import { cableForestFamily } from "./cable";
import { v4 } from "uuid";
import { bufferPointsForChainOrPartition } from "utils/bufferPointsForChainOrPartition";
import { cableChainColor } from "components/Cabling/CablingMapController/Render";
import { CABLE_PARTITION_POLYGON_PROPERTY_TYPE } from "@constants/cabling";

export const cablePartitionsInParkFamily = atomFamily(
  ({ branchId, parkId }: { branchId: string; parkId: string }) =>
    atom<Promise<CablePartitionFeature[]>>(async (get) => {
      const forest = await get(cableForestFamily({ parkId, branchId }));
      const partitions: CablePartitionFeature[] = [];
      for (const tree of forest) {
        if (!("id" in tree.data)) continue; // TS dumb, the first layer is always substations.
        let substation: SubstationFeature = tree.data;
        const turbines: TurbineFeature[] = [];
        tree.traverseDepthFirst((n) => {
          if ("id" in n) return;
          turbines.push(n.turbine);
        });

        const id = v4();
        const geometry = bufferPointsForChainOrPartition(
          [substation, ...turbines],
          400,
        );

        partitions.push({
          type: "Feature",
          id,
          geometry,
          properties: {
            type: CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
            parentIds: [parkId],
            substation: substation.id,
            turbines: turbines.map((t) => t.id),
            id,
            color: cableChainColor,
          },
        });
      }
      return partitions;
    }),
);
