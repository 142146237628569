import { atom } from "jotai";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { z } from "zod";
import { loggedInUserIdAtom, LoggedInUserNotFoundError } from "state/user";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { dedup } from "utils/utils";
import { BranchMeta } from "types/api";
import { isDefined } from "utils/predicates";
import { branchIdAtom } from "state/pathParams";
import { SetStateAction } from "jotai/index";

const OPENED_BRANCHES_LS_KEY = "vind:opened-branches";
const getOpenedBranchesLocalStorageKey = (nodeId: string, userId: string) =>
  OPENED_BRANCHES_LS_KEY.concat("-", nodeId, "-", userId);

const _openedBranchIdsTabsAtomFamilyStorage = atomFamily(
  ({ nodeId, userId }: { nodeId: string; userId: string }) =>
    atomLocalStorage(
      getOpenedBranchesLocalStorageKey(nodeId, userId),
      [] as string[],
      z.string().array(),
    ),
);

export const openedExistingBranchesTabsAtomFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atom<Promise<BranchMeta[]>, [SetStateAction<string[]>], void>(
      async (get) => {
        const userId = get(loggedInUserIdAtom);
        if (!userId) {
          throw new LoggedInUserNotFoundError(
            "User not found when trying to get opened branches",
          );
        }
        const openedBranchIdsFromStorage = get(
          _openedBranchIdsTabsAtomFamilyStorage({
            nodeId,
            userId,
          }),
        );
        const currentBranchId = get(branchIdAtom);
        const existingBranches = await get(
          branchMetasBySortOrderFamily({
            nodeId,
          }),
        );

        const openedExistingBranchesWithCurrentBranch =
          openedBranchIdsFromStorage
            .map((row) => {
              return existingBranches.find((branch) => branch.id === row);
            })
            .concat(
              existingBranches.find((branch) => branch.id === currentBranchId),
            )
            .filter(isDefined);

        const uniqueOpenedBranchesAndCurrentBranch = dedup(
          openedExistingBranchesWithCurrentBranch,
          (branch) => branch.id,
        );

        if (uniqueOpenedBranchesAndCurrentBranch.length > 0) {
          return uniqueOpenedBranchesAndCurrentBranch;
        }

        return [];
      },
      (get, set, newValue) => {
        const userId = get(loggedInUserIdAtom);
        if (!userId) {
          return;
        }

        set(
          _openedBranchIdsTabsAtomFamilyStorage({ nodeId, userId }),
          newValue,
        );
      },
    ),
);

export const allBranchesFrameOpenedAtom = atom<boolean>(false);
export const allBranchesFrameLoadingAtom = atom<boolean>(false);

export const allBranchesFrameSearchValueAtom = atom<string>("");
export const archivedBranchesFrameSearchValueAtom = atom<string>("");
