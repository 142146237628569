import { atom } from "jotai";
import { MoveNodeConsequences } from "components/Projects/useExplainNodeMove";

export const MoveNodeAccessWarningModalAtom = atom<
  | undefined
  | {
      state: "searching";
      nodeId: string;
      nodeType: string;
      parentId: string;
    }
  | {
      state: "action-needed";
      consequences: MoveNodeConsequences;
      confirm: () => void;
      cancel: () => void;
      nodeId: string;
      nodeType: string;
      parentId: string;
    }
>(undefined);
