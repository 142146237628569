import { Container, InnerContainer, Title } from "../style";
import SubstationIcon from "@icons/24/Substation.svg?react";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import { SubstationManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";
import { colors } from "styles/colors";
import { IconREMSize } from "styles/typography";
import ManageMyAccessButton from "../shared/ManageMyAccessButton";
import { tabToName } from "../shared/utils";

const SubstationImage = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Container
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {disabled && <ManageMyAccessButton />}
      <Tooltip disabled={!disabled} text={"Substation library access needed"}>
        <InnerContainer className="InnerContainer">
          <IconREMSize width={16} height={16} stroke={colors.blue600}>
            <SubstationIcon />
          </IconREMSize>
        </InnerContainer>
      </Tooltip>
      <Row style={{ justifyContent: "space-between" }}>
        <Title>{tabToName("substation")} </Title>
        <SubstationManagersPreview optionToAddManagers={false} />
      </Row>
    </Container>
  );
};

export default SubstationImage;
