import { useState, useCallback, useEffect, useMemo } from "react";
import { SubstationType } from "services/substationService";
import { ModalContainer } from "components/SettingsV2/Shared/styles";
import { SubstationContent } from "../components/SubstationContent";
import { useSetAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import useEnterToSave from "../../useEnterToSave";
import { SettingsFormActions } from "../../Components/SettingsFormActions";
import usePrevious from "hooks/usePrevious";
import { mergePreservingChanges, objectEquals } from "utils/utils";

type LibrarySubstationProps = {
  substation: SubstationType;
  onSave: (substation: SubstationType) => void;
  isLoading: boolean;
  disabled: boolean;
  nodeId: string;
  orgSubstationManageAccess?: boolean;
  organisationId?: string;
  duplicateToProject?: (substationId: string) => void;
};

const excludedKeys = ["createdAt", "updatedAt", "note"];

export function LibrarySubstation({
  substation,
  onSave,
  isLoading,
  disabled,
  nodeId,
}: LibrarySubstationProps) {
  const [editedSubstation, setEditedSubstation] =
    useState<SubstationType>(substation);
  const setUnsavedSettings = useSetAtom(unsavedSettingsState);

  const prevSubstation = usePrevious(substation);
  useEffect(() => {
    if (substation) {
      setEditedSubstation((prevTempSubstation) => {
        if (!prevTempSubstation || !prevSubstation) return substation;
        return mergePreservingChanges(
          prevSubstation,
          substation,
          prevTempSubstation,
        );
      });
    }
  }, [substation, setEditedSubstation, prevSubstation]);

  const hasChanged = useMemo(() => {
    if (!substation || !editedSubstation) return false;
    return !objectEquals(substation, editedSubstation, excludedKeys);
  }, [substation, editedSubstation]);

  const onEnterSaveCallback = useCallback(
    () => onSave(editedSubstation),
    [onSave, editedSubstation],
  );
  useEnterToSave(onEnterSaveCallback, hasChanged && !isLoading);

  useEffect(() => {
    setUnsavedSettings(hasChanged);
  }, [hasChanged, setUnsavedSettings]);

  return (
    <ModalContainer>
      <SettingsFormActions
        hasChanges={hasChanged}
        isLoading={isLoading}
        onSave={() => onSave(editedSubstation)}
        onCancel={() => setEditedSubstation(substation)}
        isLibraryContext
      />

      <SubstationContent
        substation={editedSubstation}
        onChange={setEditedSubstation}
        disabled={disabled}
        nodeId={nodeId}
        isLibrary
      />
    </ModalContainer>
  );
}
