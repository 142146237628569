import { fetchSchemaWithToken } from "services/utils";
import { _FolderOrResourceItem } from "types/folderStructures";

export const getFoldersForType = async (
  nodeId: string,
  contentType: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/${nodeId}/${contentType}`,
    {
      method: "GET",
    },
  );
};
