import mapboxgl, {
  CustomLayerInterface,
  Map,
  MercatorCoordinate,
} from "mapbox-gl";
import * as THREE from "three";
import { Camera, Scene, WebGLRenderer } from "three";
import { MooringCoords } from "../../functions/mooring";
import { dispose } from "../../utils/three";
import { createCableLineGeometry, defaultCableMaterial } from "3d/models";
import { Point } from "geojson";

export class ThreeDCables implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  data: [[number, number], MooringCoords][];
  camera: Camera | undefined;
  scene: Scene | undefined;
  renderer: WebGLRenderer | undefined;
  map: Map | undefined;
  refMerc: mapboxgl.MercatorCoordinate;

  constructor(
    data: [[number, number], MooringCoords][],
    layerId: string,
    refPt: Point,
  ) {
    this.id = layerId;
    this.type = "custom";
    this.renderingMode = "3d";
    this.data = data;
    this.refMerc = mapboxgl.MercatorCoordinate.fromLngLat([
      refPt.coordinates[0],
      refPt.coordinates[1],
    ]);
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    this.map = map;
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    map.setLayerZoomRange(this.id, 13, 24);

    this.addMooringObjects();

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;
  }

  onRemove(_map: mapboxgl.Map, _gl: WebGLRenderingContext): void {
    this.scene?.traverse((o) => dispose(o));
    this.renderer?.dispose();
  }

  addMooringObjects() {
    for (const [lonlat, cableCoords] of this.data) {
      const merc = mapboxgl.MercatorCoordinate.fromLngLat(lonlat);
      const scale = merc.meterInMercatorCoordinateUnits();

      merc.x -= this.refMerc.x;
      merc.y -= this.refMerc.y;
      if (merc.z) merc.z -= this.refMerc.z ?? 0;

      this.addCable(merc, cableCoords, scale);
    }
  }

  updateData(newData: [[number, number], MooringCoords][]) {
    this.data = newData;
    this.clearScene();
    this.addMooringObjects();
  }

  clearScene() {
    if (this.scene) {
      while (this.scene.children.length) {
        this.scene.remove(this.scene.children[0]);
      }
    }
  }

  addCable(
    merc: MercatorCoordinate,
    cableCoords: MooringCoords,
    scale: number,
  ) {
    const line = new THREE.Line(
      createCableLineGeometry(cableCoords),
      defaultCableMaterial(),
    );
    line.position.set(merc.x, merc.y, merc.z ?? 0);
    line.scale.set(scale, -scale, scale);
    this.scene?.add(line);
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    if (!this.camera || !this.renderer || !this.scene || !this.map) return;
    const m = new THREE.Matrix4().fromArray(matrix);
    this.camera.projectionMatrix = m
      .clone()
      .multiply(
        new THREE.Matrix4().setPosition(
          this.refMerc.x,
          this.refMerc.y,
          this.refMerc.z ?? 0,
        ),
      );
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }
}
