import {
  createArchivedProjectVersion,
  deleteArchivedProjectVersion,
} from "./service";
import {
  archivedProjectVersionsAtomFamily,
  sortArchivedProjectVersions,
} from "./state";
import { ArchivedProjectVersion } from "./types";
import { aset, useJotaiCallback } from "utils/jotai";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export const useCreateArchivedVersions = () => {
  const create = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      projectId: string,
      branchId: string,
      parkId: string,
      name: string,
      description?: string,
    ) => {
      const meta = await createArchivedProjectVersion({
        organisationId,
        projectId,
        branchId,
        parkId,
        name,
        description,
      });

      aset(
        get,
        set,
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId,
          branchId,
        }),
        (curr) => {
          const match = curr.find((b) => b.id === meta.id);
          if (match) return curr; // Ably beat us to it
          return [meta, ...curr].sort(sortArchivedProjectVersions);
        },
      );
    },
    [],
  );

  const updateLocal = useJotaiCallback(
    async (
      get,
      set,
      av: ArchivedProjectVersion,
      meta: { organisationId: string; projectId: string; branchId: string },
    ) => {
      const { organisationId, projectId, branchId } = meta;
      aset(
        get,
        set,
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId,
          branchId,
        }),
        (curr) => {
          const match = curr.find((b) => b.id === av.id) ?? {};
          const filtered = curr.filter((b) => b.id !== av.id);
          return [...filtered, { ...match, ...av }].sort(
            sortArchivedProjectVersions,
          );
        },
      );
    },
    [],
  );

  const deleteLocal = useJotaiCallback(
    async (
      get,
      set,
      meta: {
        organisationId: string;
        nodeId: string;
        branchId: string;
        versionId: string;
      },
    ) => {
      const { organisationId, nodeId, branchId, versionId } = meta;
      aset(
        get,
        set,
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId: nodeId,
          branchId,
        }),
        (curr) => {
          return curr
            .filter((b) => b.id !== versionId)
            .sort(sortArchivedProjectVersions);
        },
      );
    },
    [],
  );
  return { create, updateLocal, deleteLocal };
};

export const useDeleteArchivedVersions = () => {
  const { showConfirm } = useConfirm();

  const _delete = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      nodeId: string,
      branchId: string,
      versionId: string,
    ) => {
      if (
        !(await showConfirm({
          title: "Delete data",
          message:
            "Are you sure you want to delete this data? This cannot be undone.",
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      }
      const match = await get(
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId: nodeId,
          branchId,
        }),
      ).then((res) => res.find((b) => b.id === versionId));

      aset(
        get,
        set,
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId: nodeId,
          branchId,
        }),
        (curr) => {
          return curr
            .filter((b) => b.id !== versionId)
            .sort(sortArchivedProjectVersions);
        },
      );

      try {
        await deleteArchivedProjectVersion({
          organisationId,
          projectId: nodeId,
          branchId,
          versionId,
        });
      } catch (e) {
        if (match) {
          aset(
            get,
            set,
            archivedProjectVersionsAtomFamily({
              organisationId,
              projectId: nodeId,
              branchId,
            }),
            (curr) => [...curr, match].sort(sortArchivedProjectVersions),
          );
        }
      }
    },
    [showConfirm],
  );

  const deleteLocal = useJotaiCallback(
    async (
      get,
      set,
      meta: {
        organisationId: string;
        nodeId: string;
        branchId: string;
        versionId: string;
      },
    ) => {
      const { organisationId, nodeId, branchId, versionId } = meta;
      aset(
        get,
        set,
        archivedProjectVersionsAtomFamily({
          organisationId,
          projectId: nodeId,
          branchId,
        }),
        (curr) => {
          return curr
            .filter((b) => b.id !== versionId)
            .sort(sortArchivedProjectVersions);
        },
      );
    },
    [],
  );
  return { _delete, deleteLocal };
};
