import { useAtomValue } from "jotai";
import { orgDataPackagesManageAccessSelector } from "state/user";
import ChannelProviderWrapper from "../../../ChannelProviderWrapper";
import { useMemo } from "react";
import { AblyChangelogCommentLibraryInner } from "components/Changelog/useChangelogAbly";

interface ProviderProps {
  organisationId: string;
  ablyId: string;
}

export function DataPackageLibraryProvider({
  organisationId,
  ablyId,
}: ProviderProps) {
  const hasAccess = useAtomValue(orgDataPackagesManageAccessSelector);
  const channelName = useMemo(
    () => `${organisationId}:org_data_package_manage`,
    [organisationId],
  );

  return (
    <>
      {hasAccess && (
        <ChannelProviderWrapper channelName={channelName} ablyId={ablyId}>
          <AblyDataPackageChannelConsumers organisationId={organisationId} />
        </ChannelProviderWrapper>
      )}
    </>
  );
}

function AblyDataPackageChannelConsumers({
  organisationId,
}: {
  organisationId: string;
}) {
  return (
    <AblyChangelogCommentLibraryInner
      organisationId={organisationId}
      libraryType={"org_data_package_manage"}
    />
  );
}
