import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import { ExistingTurbineFeature } from "../../types/feature";
import Tooltip from "../General/Tooltip";
import { isExistingTurbine } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { Divider } from "components/General/Icons";
import { ProjectFeature } from "../../types/feature";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { InputDimensioned } from "components/General/Input";
import { between } from "utils/validations";
import {
  ARTICLE_GENERIC_TURBINE,
  HelpLink,
} from "components/HelpTooltip/HelpTooltip";

const PowerSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const PowerSelector = ({
  selectedTurbines,
  editable,
}: {
  selectedTurbines: ExistingTurbineFeature[];
  editable: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const { update: updateFeatures } = useProjectElementsCrud();

  const currentTurbinePowers = [
    ...new Set(selectedTurbines.map((t) => t.properties.power)).values(),
  ];

  const onSelectItem = useCallback(
    (val: number) => {
      trackCanvasOption("change-existing-turbine-power", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedTurbines.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          power: val,
        },
      }));

      updateFeatures({
        update: updatedFeatures,
      });
    },
    [branchId, projectId, selectedTurbines, updateFeatures],
  );

  const value =
    currentTurbinePowers.length === 1 ? currentTurbinePowers[0] : undefined;

  return (
    <PowerSelectorWrapper>
      <InputDimensioned
        disabled={!editable}
        value={value || undefined}
        unit="MW"
        validate={between(2, 30)}
        validationMessage="Must be between 2 and 30"
        onChange={(value) => onSelectItem(value)}
        style={{
          width: "8rem",
        }}
        mini={true}
      />
    </PowerSelectorWrapper>
  );
};

export default function ExistingTurbineTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedExistingTurbineFeatures = useMemo(
    () => selectedProjectFeatures.filter(isExistingTurbine),
    [selectedProjectFeatures],
  );

  const onlyTurbinesSelected =
    selectedExistingTurbineFeatures.length === selectedProjectFeatures.length;

  if (selectedExistingTurbineFeatures.length === 0 || !onlyTurbinesSelected)
    return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change power" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper
            style={{
              display: "flex",
            }}
          >
            <>Size</>
            <HelpLink
              article={ARTICLE_GENERIC_TURBINE}
              size={"1.2rem"}
              style={{
                padding: "0 0.2rem",
              }}
            />
          </NamedTooltipWrapper>
          <PowerSelector
            selectedTurbines={selectedExistingTurbineFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </Suspense>
  );
}
