import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { fetchSchemaWithToken } from "services/utils";
import { _VesselType, VesselType } from "services/vesselService";
import { projectIdAtom } from "state/pathParams";
import { atomFamily } from "utils/jotai";
import * as spec from "api/configurations";

export const projectVesselRefreshAtom = atom(Symbol());

export const _projectVesselTypeFamily = atomFamily((nodeId: string) =>
  atomWithDefault<Promise<VesselType[]>>((get) => {
    get(projectVesselRefreshAtom);
    return fetchSchemaWithToken(
      spec["paths"]["/api/vessel/node/{nodeId}"]["get"]["responses"]["200"][
        "application/json"
      ],
      `/api/vessel/node/${nodeId}`,
    );
  }),
);

export const vesselTypesFamily = atomFamily(
  (projectIdInput: string | undefined) =>
    atom<Promise<Map<string, VesselType>>>(async (get) => {
      const projectId = projectIdInput ?? get(projectIdAtom);
      if (!projectId)
        throw new Error("vesselTypesFamily requires projectIdAtom");

      const project_vessels = await get(_projectVesselTypeFamily(projectId));
      return new Map(project_vessels.map((v) => [v.id, v]));
    }),
);
