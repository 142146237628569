import { isDefined } from "utils/predicates";
import {
  FolderOrResourceItem,
  FolderTreeItem,
  FolderTreeItemOrResourceItem,
  ResourceItem,
} from "types/folderStructures";

/**
 * Type guard to check if an item is a folder item.
 */
export const isFolderItem = (item: any): item is FolderTreeItem =>
  "type" in item && item.type === "FOLDER";

/**
 * Builds a folder tree from the given root items and full folder structure.
 */
export function buildFolderTree(
  rootItems: FolderOrResourceItem[],
  fullFolderStructure: FolderOrResourceItem[],
): FolderTreeItemOrResourceItem[] {
  return rootItems
    .map((folderOrResource) => {
      if (folderOrResource.type === "RESOURCE") {
        return folderOrResource as ResourceItem;
      }

      const children = fullFolderStructure.filter(
        (r) => r.parentId === folderOrResource.id,
      );

      const folderWithChildren: FolderTreeItem = {
        ...folderOrResource,
        children: buildFolderTree(children, fullFolderStructure),
      };

      return folderWithChildren;
    })
    .sort(sortFolderTree);
}

/**
 * Gets orphaned resource items that does not have a corresponding folder or resource item.
 * For instance, creating a new branch will not add a resource in the folder structure.
 * First when moving a branch, the resource will be created.
 */
export function getOrphans<T>(
  fullFolderStructure: FolderOrResourceItem[],
  resourceItems: T[],
  getResourceId: (item: T) => string | undefined,
): T[] {
  const orphansWithoutResource = resourceItems.filter((b) => {
    const folderItem = fullFolderStructure.find(
      (f) => f.id === getResourceId(b),
    );
    return !folderItem;
  });

  return orphansWithoutResource.filter(isDefined);
}

/**
 * Finds an item in the folder tree by its ID.
 */
export const findItemInTree = (
  id: string,
  folderTree: FolderOrResourceItem[],
): FolderOrResourceItem | undefined => {
  for (const item of folderTree) {
    if (item.id === id) {
      return item;
    }
    if (isFolderItem(item) && item.children) {
      const found = findItemInTree(id, item.children);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const getAllResourcesInFolderTree = (
  folderTree: FolderTreeItemOrResourceItem[],
): ResourceItem[] => {
  return folderTree
    .map((f) => {
      if (isFolderItem(f)) {
        return getAllResourcesInFolderTree(f.children);
      }
      return f;
    })
    .flat();
};

/**
 * Checks if moving an item to a target folder is a legal move.
 * Returns false if user is trying to move a parent to somewhere in its own child tree
 * or to itself
 */
export const getIsLegalMove = (
  itemToMove: FolderTreeItemOrResourceItem,
  targetFolder: FolderTreeItemOrResourceItem,
  folderTree: FolderTreeItemOrResourceItem[],
) => {
  if (itemToMove.id === targetFolder.id) {
    return false;
  }
  const folderTreeItem = findItemInTree(itemToMove.id, folderTree);
  if (!folderTreeItem) {
    return true;
  }
  if (isFolderItem(folderTreeItem)) {
    return !findItemInTree(targetFolder.id, folderTreeItem.children);
  }
  return true;
};

/**
 * Filters a folder tree to include only the folders that has resources that match the given IDs.
 */
export const getFilteredFolderTree = (
  folderTree: FolderTreeItemOrResourceItem[],
  resourceIds: string[],
): FolderTreeItemOrResourceItem[] => {
  return folderTree
    .map((f) => {
      if (isFolderItem(f)) {
        const filteredChildren = getFilteredFolderTree(f.children, resourceIds);
        const containsResource = f.children.some((child) =>
          resourceIds.includes(child.id),
        );

        return containsResource || filteredChildren.length > 0
          ? { ...f, children: filteredChildren }
          : undefined;
      }
      return resourceIds.includes(f.id) ? f : undefined;
    })
    .filter(isDefined);
};

export const sortFolderTree = (
  a: FolderOrResourceItem,
  b: FolderOrResourceItem,
): number => {
  if (isFolderItem(a) && !isFolderItem(b)) {
    return -1;
  }
  if (!isFolderItem(a) && isFolderItem(b)) {
    return 1;
  }
  return (a.sortOrder ?? 0) - (b.sortOrder ?? 0);
};
