import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  opacityPropertyName,
  displayLabelPropertyName,
  strokeWidthPropertyName,
  DEFAULT_CANVAS_LINE_STROKE_WIDTH,
  DEFAULT_CANVAS_DOTTED_POLYGON_STROKE_WIDTH,
  DEFAULT_CANVAS_POLYGON_STROKE_WIDTH,
} from "../../constants/canvas";
import {
  ColorSelectorWrapper,
  GridContainer,
  RowItemWrapper,
  FeatureStylingPickerContainer,
  OpacitySliderWrapper,
} from "./StylingSelector.style";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Slider } from "../General/Slider";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import StylingIcon from "@icons/14/Styling.svg";
import { ProjectFeature } from "../../types/feature";
import { trackCanvasOption } from "../CanvasSelectOption/MenuTracking";
import ColorSelector from "../ColorSelector/ColorSelector";
import { Input } from "../General/Input";
import Dropdown from "../Dropdown/Dropdown";
import { LayerStrokeStyle } from "../LayerList/LayerSettings/types";
import { getFeatureOfSelectionUndefinedIfTheyAreDifferent } from "./StylingSelector.utils";
import { colors } from "../../styles/colors";
import Toggle, { ToggleSize } from "../General/Toggle";
import { IconBtn } from "components/General/Icons";
import { isAnchor, isCable, isPark, isTurbine } from "utils/predicates";
import { Style, isCategory } from "business/style/types";
import { stylesPerFeatureSelector } from "business/style/state";
import Radio, { RadioGroup } from "components/General/Radio";
import { ColorSquare } from "components/General/ColorSquare";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { STYLE_GROUP_KEY } from "business/style/constants";
import { typography } from "styles/typography";
import { Column } from "components/General/Layout";

const StylingSelectorBoxPolygon = ({
  color,
  strokeColor,
  setStrokeColor,
  setColor,
  strokeStyle,
  setStrokeStyle,
}: {
  color: string | undefined;
  strokeColor: string | undefined;
  setStrokeColor: (color: string | number) => void;
  setColor: (color: any) => void;
  strokeStyle: LayerStrokeStyle;
  setStrokeStyle: (layerStrokeStyle: LayerStrokeStyle) => void;
}) => {
  const [tempColor, setTempColor] = useState(color);
  const [tempStrokeColor, setTempStrokeColor] = useState(strokeColor);

  return (
    <GridContainer gridSize={3}>
      <RowItemWrapper>Fill:</RowItemWrapper>
      <RowItemWrapper>
        <ColorSelector
          setColor={(c) => {
            setColor(c);
            setTempColor(c as string);
          }}
          color={color}
        />
        <Input
          style={{
            width: "11rem",
          }}
          value={tempColor}
          onChange={(e) => {
            setTempColor(e.target.value);
            const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
            const match = re.exec(e.target.value);
            if (!match) return;
            setColor(e.target.value);
          }}
          type="string"
          placeholder="hex color code"
        />
      </RowItemWrapper>
      <div />

      <RowItemWrapper>Stroke:</RowItemWrapper>
      <RowItemWrapper>
        <ColorSelector
          setColor={(c) => {
            setStrokeColor(c);
            setTempStrokeColor(c as string);
          }}
          color={strokeColor}
        />
        <Input
          style={{
            width: "11rem",
          }}
          value={tempStrokeColor}
          onChange={(e) => {
            setTempStrokeColor(e.target.value);
            const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
            const match = re.exec(e.target.value);
            if (!match) return;
            setStrokeColor(e.target.value);
          }}
          type="string"
          placeholder="hex color code"
        />
      </RowItemWrapper>

      <RowItemWrapper>
        <Dropdown
          value={strokeStyle}
          onChange={(e) => setStrokeStyle(e.target.value as LayerStrokeStyle)}
        >
          {Object.values(LayerStrokeStyle).map((ss) => (
            <option key={ss} value={ss}>
              {ss}
            </option>
          ))}
        </Dropdown>
      </RowItemWrapper>
    </GridContainer>
  );
};

const StylingSelectorBoxLineString = ({
  color,
  setColor,
  strokeStyle,
  setStrokeStyle,
}: {
  color: string | undefined;
  setColor: (color: any) => void;
  strokeStyle: LayerStrokeStyle;
  setStrokeStyle: (layerStrokeStyle: LayerStrokeStyle) => void;
}) => {
  const [tempColor, setTempColor] = useState(color);

  return (
    <GridContainer gridSize={3}>
      <RowItemWrapper>Stroke:</RowItemWrapper>
      <RowItemWrapper>
        <ColorSelector
          setColor={(c) => {
            setColor(c);
            setTempColor(c as string);
          }}
          color={color}
        />
        <Input
          style={{
            width: "11rem",
          }}
          value={tempColor}
          onChange={(e) => {
            setTempColor(e.target.value);
            const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
            const match = re.exec(e.target.value);
            if (!match) return;
            setColor(e.target.value);
          }}
          type="string"
          placeholder="hex color code"
        />
      </RowItemWrapper>
      <RowItemWrapper>
        <Dropdown
          value={strokeStyle}
          onChange={(e) => setStrokeStyle(e.target.value as LayerStrokeStyle)}
        >
          {Object.values(LayerStrokeStyle).map((ss) => (
            <option key={ss} value={ss}>
              {ss}
            </option>
          ))}
        </Dropdown>
      </RowItemWrapper>
    </GridContainer>
  );
};

const StylingSelectorBoxPoint = ({
  color,
  setColor,
}: {
  color: string | undefined;
  setColor: (color: any) => void;
}) => {
  const [tempColor, setTempColor] = useState(color);
  return (
    <GridContainer gridSize={3}>
      <RowItemWrapper>Fill:</RowItemWrapper>
      <RowItemWrapper>
        <ColorSelector
          setColor={(c) => {
            setColor(c);
            setTempColor(c as string);
          }}
          color={color}
        />
        <Input
          style={{
            width: "11rem",
          }}
          value={tempColor}
          onChange={(e) => {
            setTempColor(e.target.value);
            const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
            const match = re.exec(e.target.value);
            if (!match) return;
            setColor(e.target.value);
          }}
          type="string"
          placeholder="hex color code"
        />
      </RowItemWrapper>
    </GridContainer>
  );
};

const StylingSelectorBoxGeneral = ({
  color,
  setColor,
}: {
  selectedProjectFeatures: ProjectFeature[];
  color: string | undefined;
  setColor: (color: any) => void;
}) => {
  const [tempColor, setTempColor] = useState(color);
  return (
    <GridContainer gridSize={3}>
      <RowItemWrapper>Fill:</RowItemWrapper>
      <RowItemWrapper>
        <ColorSelector
          setColor={(c) => {
            setColor(c);
            setTempColor(c as string);
          }}
          color={color}
        />
        <Input
          style={{
            width: "11rem",
          }}
          value={tempColor}
          onChange={(e) => {
            setTempColor(e.target.value);
            const re = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");
            const match = re.exec(e.target.value);
            if (!match) return;
            setColor(e.target.value);
          }}
          type="string"
          placeholder="hex color code"
        />
      </RowItemWrapper>
    </GridContainer>
  );
};

const StyleGroupSelector = ({ feature }: { feature: ProjectFeature }) => {
  const featureType: Style["feature"] | undefined = useMemo(() => {
    if (isAnchor(feature)) return "anchors";
    if (isCable(feature)) return "cables";
    if (isPark(feature)) return "parks";
    if (isTurbine(feature)) return "turbines";
    return undefined;
  }, [feature]);

  const allStyles = useAtomValue(stylesPerFeatureSelector);
  const { update } = useProjectElementsCrud();

  if (!featureType) return null;
  const styles = allStyles[featureType];

  const allCategories = styles.flatMap((s) =>
    isCategory(s) ? s.categories : [],
  );

  if (allCategories.length === 0) return null;

  return (
    <Column style={{ padding: "1rem" }}>
      <h4 style={typography.sub3}>Style group</h4>
      <RadioGroup style={{ flexDirection: "column", minWidth: "20rem" }}>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Radio
            checked={feature.properties[STYLE_GROUP_KEY] == undefined}
            label="None"
            onChange={() => {
              update({
                update: [
                  {
                    ...feature,
                    properties: {
                      ...feature.properties,
                      [STYLE_GROUP_KEY]: undefined,
                    },
                  },
                ],
              });
            }}
          />
        </div>
        {allCategories.map((c) => {
          return (
            <div
              key={c.id}
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Radio
                checked={feature.properties[STYLE_GROUP_KEY] == c.id}
                label={c.label}
                onChange={() => {
                  update({
                    update: [
                      {
                        ...feature,
                        properties: {
                          ...feature.properties,
                          [STYLE_GROUP_KEY]: c.id,
                        },
                      },
                    ],
                  });
                }}
              />
              <ColorSquare $color={c.color} style={{ maxHeight: "2rem" }} />
            </div>
          );
        })}
      </RadioGroup>
    </Column>
  );
};

export const StylingSelectorBox = ({
  selectedProjectFeatures,
  setOpen,
  direction = "bottom",
  enableColorStyling,
  enableOpacityStyling,
}: {
  selectedProjectFeatures: ProjectFeature[];
  setOpen?: Dispatch<SetStateAction<boolean>>;
  direction?: "bottom" | "right";
  enableColorStyling: boolean;
  enableOpacityStyling: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const opacityContainerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(
    opacityContainerRef,
    () => setOpen && setOpen(false),
    (target) => {
      return (
        target instanceof HTMLElement &&
        target.dataset?.buttonType === "styling-selector"
      );
    },
    {
      ignoreDragClicks: true,
    },
  );
  const selectionsAreOfSameGeometryType = selectedProjectFeatures.every(
    (s, i) =>
      i === 0 ||
      s.geometry.type === selectedProjectFeatures[i - 1].geometry.type,
  );
  const setProperty = useSetPropertyOnProjectFeatures();
  const selectedProjectFeaturesIds = useMemo(
    () =>
      selectedProjectFeatures.map(
        (selectedProjectFeature) => selectedProjectFeature.id,
      ),
    [selectedProjectFeatures],
  );
  const opacity = useMemo(
    () =>
      getFeatureOfSelectionUndefinedIfTheyAreDifferent<number>(
        selectedProjectFeatures,
        opacityPropertyName,
        0.5,
      ),
    [selectedProjectFeatures],
  );
  const setOpacity = useCallback(
    (opacity: number) => {
      setProperty(selectedProjectFeaturesIds, {
        [opacityPropertyName]: opacity,
      });
      trackCanvasOption("opacity", {
        projectId,
        branchId,
      });
    },
    [selectedProjectFeaturesIds, projectId, branchId, setProperty],
  );
  const color = useMemo(
    () =>
      getFeatureOfSelectionUndefinedIfTheyAreDifferent<string | undefined>(
        selectedProjectFeatures,
        "color",
        undefined,
      ),
    [selectedProjectFeatures],
  );
  const setColor = useCallback(
    (color: string) => {
      setProperty(selectedProjectFeaturesIds, {
        color: color.toString(),
      });
      trackCanvasOption("color", {
        projectId,
        branchId,
      });
    },
    [projectId, branchId, setProperty, selectedProjectFeaturesIds],
  );
  const strokeStyle = useMemo(
    () =>
      getFeatureOfSelectionUndefinedIfTheyAreDifferent<LayerStrokeStyle>(
        selectedProjectFeatures,
        "strokeStyle",
        LayerStrokeStyle.Solid,
      ),
    [selectedProjectFeatures],
  );
  const setStrokeStyle = useCallback(
    (layerStrokeStyle: LayerStrokeStyle) => {
      setProperty(selectedProjectFeaturesIds, {
        strokeStyle: layerStrokeStyle,
      });
      trackCanvasOption("strokeStyle", {
        projectId,
        branchId,
      });
    },
    [setProperty, selectedProjectFeaturesIds, projectId, branchId],
  );
  const strokeWidth = useMemo(() => {
    const defaultStrokeWidth = ["Polygon", "MultiPolygon"].includes(
      selectedProjectFeatures[0].geometry.type,
    )
      ? strokeStyle === LayerStrokeStyle.Solid
        ? DEFAULT_CANVAS_POLYGON_STROKE_WIDTH
        : DEFAULT_CANVAS_DOTTED_POLYGON_STROKE_WIDTH
      : DEFAULT_CANVAS_LINE_STROKE_WIDTH;
    return getFeatureOfSelectionUndefinedIfTheyAreDifferent<number>(
      selectedProjectFeatures,
      strokeWidthPropertyName,
      defaultStrokeWidth,
    );
  }, [selectedProjectFeatures, strokeStyle]);

  const setStrokeWidth = useCallback(
    (stroke: number) => {
      setProperty(selectedProjectFeaturesIds, {
        [strokeWidthPropertyName]: stroke,
      });
      trackCanvasOption("stroke", {
        projectId,
        branchId,
      });
    },
    [selectedProjectFeaturesIds, projectId, branchId, setProperty],
  );
  const strokeColor = useMemo(
    () =>
      getFeatureOfSelectionUndefinedIfTheyAreDifferent<string | undefined>(
        selectedProjectFeatures,
        "strokeColor",
        colors.other,
      ),
    [selectedProjectFeatures],
  );
  const setStrokeColor = useCallback(
    (color: string | number) => {
      setProperty(selectedProjectFeaturesIds, {
        strokeColor: color.toString(),
      });
      trackCanvasOption("strokeColor", {
        projectId,
        branchId,
      });
    },
    [selectedProjectFeaturesIds, projectId, branchId, setProperty],
  );

  const onLabelToggle = useCallback(
    (checked: boolean) => {
      setProperty(selectedProjectFeaturesIds, {
        [displayLabelPropertyName]: checked,
      });
      trackCanvasOption("displayLabel", {
        projectId,
        branchId,
        checked,
      });
    },
    [selectedProjectFeaturesIds, projectId, branchId, setProperty],
  );

  return (
    <FeatureStylingPickerContainer
      ref={opacityContainerRef}
      position={direction === "bottom" ? "left" : direction}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <GridContainer gridSize={2}>
        <RowItemWrapper>Label:</RowItemWrapper>
        <Toggle
          title="Display labels"
          checked={selectedProjectFeatures.every(
            (feature) => feature.properties[displayLabelPropertyName] ?? true,
          )}
          size={ToggleSize.SMALL}
          onChange={(e) => onLabelToggle(e.target.checked)}
        />
      </GridContainer>
      {enableColorStyling && !selectionsAreOfSameGeometryType && (
        <StylingSelectorBoxGeneral
          selectedProjectFeatures={selectedProjectFeatures}
          color={color}
          setColor={setColor}
        />
      )}
      {selectedProjectFeatures.length === 1 && (
        <StyleGroupSelector feature={selectedProjectFeatures[0]} />
      )}
      {enableColorStyling &&
        selectionsAreOfSameGeometryType &&
        ["Polygon", "MultiPolygon"].includes(
          selectedProjectFeatures[0].geometry.type,
        ) && (
          <StylingSelectorBoxPolygon
            strokeColor={strokeColor}
            setStrokeColor={setStrokeColor}
            color={color}
            setColor={setColor}
            strokeStyle={strokeStyle}
            setStrokeStyle={setStrokeStyle}
          />
        )}
      {enableColorStyling &&
        selectionsAreOfSameGeometryType &&
        selectedProjectFeatures[0].geometry.type === "LineString" && (
          <StylingSelectorBoxLineString
            color={color}
            setColor={setColor}
            strokeStyle={strokeStyle}
            setStrokeStyle={setStrokeStyle}
          />
        )}
      {enableColorStyling &&
        selectionsAreOfSameGeometryType &&
        selectedProjectFeatures[0].geometry.type === "Point" && (
          <StylingSelectorBoxPoint color={color} setColor={setColor} />
        )}
      {enableColorStyling &&
        ["Polygon", "MultiPolygon", "LineString"].includes(
          selectedProjectFeatures[0].geometry.type,
        ) && (
          <OpacitySliderWrapper>
            <RowItemWrapper
              style={{
                flex: 1,
              }}
            >
              Stroke width:
            </RowItemWrapper>
            <RowItemWrapper
              style={{
                flex: 3,
              }}
            >
              <Slider
                min={1}
                max={10}
                step={0.1}
                value={strokeWidth}
                onChange={setStrokeWidth}
              />
            </RowItemWrapper>
          </OpacitySliderWrapper>
        )}
      {(enableColorStyling || enableOpacityStyling) && (
        <OpacitySliderWrapper>
          <RowItemWrapper
            style={{
              flex: 1,
            }}
          >
            Opacity:
          </RowItemWrapper>
          <RowItemWrapper
            style={{
              flex: 3,
            }}
          >
            <Slider
              min={0}
              max={100}
              step={10}
              value={opacity * 100}
              onChange={(n) => setOpacity(n / 100)}
            />
          </RowItemWrapper>
        </OpacitySliderWrapper>
      )}
    </FeatureStylingPickerContainer>
  );
};

const StylingSelector = ({
  selectedProjectFeatures,
  enableColorStyling,
  enableOpacityStyling,
}: {
  selectedProjectFeatures: ProjectFeature[];
  enableColorStyling: boolean;
  enableOpacityStyling: boolean;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <ColorSelectorWrapper>
      <IconBtn
        data-button-type="styling-selector"
        active={open}
        onClick={(e) => {
          setOpen((c) => !c);
          e.stopPropagation();
        }}
      >
        <StylingIcon />
      </IconBtn>

      {open && (
        <StylingSelectorBox
          direction={"bottom"}
          setOpen={setOpen}
          selectedProjectFeatures={selectedProjectFeatures}
          enableColorStyling={enableColorStyling}
          enableOpacityStyling={enableOpacityStyling}
        />
      )}
    </ColorSelectorWrapper>
  );
};

export default StylingSelector;
