import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as turf from "@turf/turf";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { TopRightMenuOptions } from "../../constants/canvas";
import { MAX_AREA, MIN_AREA } from "../../constants/park";
import {
  GenerateWindTurbinesAndFoundationsMenuType,
  TURBINE_PROPERTY_TYPE,
} from "../../constants/projectMapView";
import { useClickOutside } from "../../hooks/useClickOutside";
import { currentSelectionArrayAtom } from "../../state/selection";
import { SkeletonText } from "../Loading/Skeleton";
import {
  _AnchorFeature,
  _MooringLineFeature,
  SubAreaFeature,
  ParkFeature,
  ProjectFeature,
} from "../../types/feature";
import { differenceAll } from "../../utils/turf";
import { count, dedup, undefMap } from "../../utils/utils";
import { Grid2, Label } from "../General/Form";
import { Column, Row } from "../General/Layout";
import { ARTICLE_LAYOUT_GEN, HelpLink } from "../HelpTooltip/HelpTooltip";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { GenerationSettings } from "./GenerationSettings";
import Toggle, { ToggleSize } from "components/General/Toggle";
import useBooleanState from "hooks/useBooleanState";
import { Mode } from "components/GenerateFoundationsAndAnchors/GenerateFoundationAnchorMenu";
import Button from "components/General/Button";
import { spaceLarge, spaceMedium, spaceSmall } from "styles/space";
import {
  turbinesAndFoundationsGenerationIsLiveAtom,
  methodAndParametersForRegionAtomFamily,
  combinedGenMooringParameters,
} from "components/GenerateWindTurbines/state";
import { colors } from "styles/colors";
import { defaultFoundations } from "state/foundations";
import {
  getMooringParametersAtomFamily,
  previewMooringAndFoundationState,
} from "components/GenerateFoundationsAndAnchors/state";
import { isFixed, isFloater } from "utils/predicates";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import { useTurbineGeneration } from "components/GenerateWindTurbines/useGenerateAndSave";
import { previewTurbinesState } from "state/turbines";
import {
  DEFAULT_ONSHORE_OFFSHORE_TURBINES,
  SimpleTurbineType,
} from "types/turbines";
import { FoundationType } from "types/foundations";
import { MooringInner } from "components/GenerateFoundationsAndAnchors/MooringInner";
import { FixedFoundationInner } from "components/GenerateFoundationsAndAnchors/FixedFoundationInner";
import {
  getFixedTurbines,
  getFloatingTurbines,
} from "components/GenerateFoundationsAndAnchors/utils";
import { FoundationWarnings } from "components/GenerateFoundationsAndAnchors/shared";
import { Raster } from "types/raster";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useToast } from "hooks/useToast";
import {
  SubtitleWithLine,
  Divider,
  InputTitle,
} from "components/General/GeneralSideModals.style";
import { TourStep } from "components/OnboardingTours/TourStep";
import { useTrackEvent } from "components/OnboardingTours/state";
import { HighlightStep } from "components/OnboardingTours/HighlightWrapper";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import {
  FloatingFocusManager,
  useFloating,
  offset,
  ReferenceType,
} from "@floating-ui/react";
import { IconBtn } from "components/General/Icons";
import OpenRight from "@icons/24/OpenWindowRight.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useBathymetry } from "hooks/bathymetry";
import {
  foundationMostCommonInParkFamily,
  foundationTypesAtom,
} from "state/jotai/foundation";
import { selectedProjectFeaturesAtom } from "state/jotai/selection";
import { subAreasInParkFamily } from "state/jotai/subArea";
import { exclusionZonesFamily } from "state/jotai/exclusionZone";
import { featuresInParkFamily } from "state/jotai/features";
import { currentParkAtom } from "state/jotai/park";
import { useJotaiCallback } from "utils/jotai";
import { isOnshoreAtom } from "state/onshore";
import { simpleTurbineTypesAtom } from "state/jotai/turbineType";
import Tooltip from "components/General/Tooltip";
import SelectFoundationDropDown from "./SelectFoundationDropDown";
import DropdownButton from "components/General/Dropdown/DropdownButton";

const CombinedFoundationInner = ({
  park,
  live,
  raster,
  foundation,
  setCurrentFoundationId,
}: {
  park: ParkFeature;
  live: boolean;
  raster: Raster;
  foundation: FoundationType;
  setCurrentFoundationId: (id: string) => void;
}) => {
  const turbinesPreviewState = useAtomValue(previewTurbinesState);
  const previewTurbines = useMemo(
    () => turbinesPreviewState?.preview ?? [],
    [turbinesPreviewState?.preview],
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const mode: Mode = useMemo(
    () => ({
      mode: "turbines",
      turbines: previewTurbines,
    }),
    [previewTurbines],
  );

  const selectedTypeCounts = count(
    previewTurbines.map((f) => f.properties.type),
  );
  const selectedTypes = [...selectedTypeCounts.keys()];
  selectedTypes.sort();

  if (selectedTypes.length === 0) {
    selectedTypes.push(TURBINE_PROPERTY_TYPE);
  }

  const allTurbineTypes = useAtomValue(simpleTurbineTypesAtom);

  const floatingTurbines = getFloatingTurbines({
    turbineFeatures: previewTurbines,
    raster,
    currentFoundation: foundation,
  });
  const fixedTurbines = getFixedTurbines({
    turbineFeatures: previewTurbines,
    raster,
    currentFoundation: foundation,
    turbineTypes: allTurbineTypes,
  });

  const isFloatingFoundation = isFloater(foundation);
  const isFixedFoundation = isFixed(foundation);

  return (
    <Column
      ref={scrollBodyRef}
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 26rem)",
      }}
    >
      <SelectFoundationDropDown
        currentFoundation={foundation}
        onSelectItem={(id) => {
          setCurrentFoundationId(id);
        }}
      />
      <FoundationWarnings
        isFloatingFoundation={isFloatingFoundation}
        isFixedFoundation={isFixedFoundation}
        floatingTurbines={floatingTurbines}
        fixedTurbines={fixedTurbines}
        selectedTurbines={previewTurbines}
        foundation={foundation}
      />
      {isFixedFoundation && fixedTurbines.length > 0 && (
        <FixedFoundationInner
          park={park}
          mode={mode}
          fixedTurbines={fixedTurbines}
          selectedTurbines={previewTurbines}
          currentFoundationId={foundation.id}
        />
      )}
      {isFloatingFoundation && (
        <MooringInner
          live={live}
          park={park}
          mode={mode}
          raster={raster}
          currentFoundationId={foundation.id}
          floatingTurbines={floatingTurbines}
        />
      )}
    </Column>
  );
};

const GenerateFoundationsOuter = ({
  park,
  live,
}: {
  park: ParkFeature;
  live: boolean;
}) => {
  const [loadable, raster] = useBathymetry({
    featureId: park.id,
    projectId: undefined,
    branchId: undefined,
    bufferKm: undefined,
  });

  if (loadable.state === "loading") {
    return (
      <Column>
        <SkeletonText
          style={{
            height: "2rem",
          }}
          text="Loading bathymetry"
        />
      </Column>
    );
  }

  if (!raster) {
    return (
      <div>
        <SimpleAlert
          text={"Failed to load bathymetry, can not generate foundations"}
          type={"error"}
        />
      </div>
    );
  }

  return <GenerateFoundations park={park} live={live} raster={raster} />;
};

const GenerateFoundations = ({
  park,
  live,
  raster,
}: {
  park: ParkFeature;
  live: boolean;
  raster: Raster;
}) => {
  const [isGenerateFoundationsActive, toggleIsGenerateFoundationsActive] =
    useBooleanState(false);
  const [isPanelOpen, toggleIsPanelOpen, setIsPanelOpen] =
    useBooleanState(false);
  const parkFoundation = useAtomValue(
    foundationMostCommonInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );
  const foundationId = parkFoundation?.id ?? "";
  const [currentFoundationId, setCurrentFoundationId] =
    useState<string>(foundationId);

  const allFoundationTypes = useAtomValue(foundationTypesAtom);
  const currentFoundation = allFoundationTypes.get(currentFoundationId);

  const mooringParams = useAtomValue(
    getMooringParametersAtomFamily({
      foundationId,
    }),
  );

  const syncWithRecoil = useSetAtom(combinedGenMooringParameters);

  useEffect(() => {
    if (isGenerateFoundationsActive && isFloater(currentFoundation)) {
      syncWithRecoil({
        ...mooringParams,
        raster,
      });
    }
    return () => {
      syncWithRecoil(undefined);
    };
  }, [
    isGenerateFoundationsActive,
    currentFoundation,
    mooringParams,
    raster,
    syncWithRecoil,
  ]);

  if (!currentFoundation) {
    setCurrentFoundationId(defaultFoundations[0].id);
  }

  return (
    <React.Suspense
      fallback={
        <Column>
          <SkeletonText />
        </Column>
      }
    >
      <Row
        style={{
          paddingTop: spaceSmall,
          paddingBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          text={`Turbines must be generated before foundations can be added.`}
          disabled={live}
        >
          <Label
            left
            style={{
              cursor: "pointer",
            }}
          >
            <Toggle
              checked={isGenerateFoundationsActive}
              disabled={!live}
              onChange={() => {
                toggleIsGenerateFoundationsActive();
                if (!isGenerateFoundationsActive && !isPanelOpen) {
                  setIsPanelOpen(true);
                } else if (isGenerateFoundationsActive && isPanelOpen) {
                  setIsPanelOpen(false);
                }
              }}
              title="Generate foundations"
              size={ToggleSize.SMALL}
            />
            <InputTitle>Generate foundations</InputTitle>
          </Label>
        </Tooltip>
        <IconBtn
          active={isPanelOpen}
          disabled={!live}
          backgroundColor={colors.surfaceButtonSecondary}
          size={"1.4rem"}
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
        >
          <OpenRight />
        </IconBtn>
      </Row>

      {isPanelOpen && (
        <>
          {currentFoundation && (
            <MenuFrame
              title={"Generate foundations"}
              onExit={toggleIsPanelOpen}
              style={{
                display: isPanelOpen ? "block" : "none",
                padding: "1.6rem 0rem 2rem 0rem",
                position: "absolute",
                left: "calc(100% + 1rem)",
                bottom: 0,
                backgroundColor: colors.background,
              }}
            >
              <CombinedFoundationInner
                park={park}
                live={live}
                foundation={currentFoundation}
                setCurrentFoundationId={setCurrentFoundationId}
                raster={raster}
              />
            </MenuFrame>
          )}
        </>
      )}
    </React.Suspense>
  );
};

const GenerateTurbines = ({
  park,
  live,
  selectedRegion,
  selectedZoneSize,
  innerRef,
}: {
  selectedRegion: ParkFeature | SubAreaFeature;
  park: ParkFeature;
  live: boolean;
  selectedZoneSize: number | undefined;
  innerRef?: ((node: ReferenceType | null) => void) &
    ((node: ReferenceType | null) => void);
}) => {
  return (
    <React.Suspense
      fallback={
        <Column>
          <SkeletonText />
        </Column>
      }
    >
      <GenerationSettings
        park={park}
        region={selectedRegion}
        selectedZoneSize={selectedZoneSize}
        live={live}
        selectedRegion={selectedRegion}
        innerRef={innerRef}
      />
    </React.Suspense>
  );
};

const Inner = ({ park }: { park: ParkFeature }) => {
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const setSelectedFeatures = useSetAtom(currentSelectionArrayAtom);
  const { update: updateFeatures } = useProjectElementsCrud();
  const featuresInPark = useAtomValue(
    featuresInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );
  const subAreas = useAtomValue(
    subAreasInParkFamily({
      parkId: park.id,
      branchId: undefined,
    }),
  );
  const exclusionZones = useAtomValue(
    exclusionZonesFamily({
      branchId: undefined,
    }),
  );
  const { scrollBodyRef } = useShowScrollShadow(true);

  const selectedSubAreas = useMemo(
    () =>
      [...subAreas, park].filter((f) =>
        selectedFeatures.some((sf) => sf.id === f.id),
      ),
    [selectedFeatures, subAreas, park],
  );

  const selectedRegion = useMemo(() => {
    if (subAreas.length === 0) return park;
    // If there are sub areas, we require that the user clicks on exactly one.
    if (selectedSubAreas.length === 0) return undefined;
    if (selectedSubAreas.length === 1) return selectedSubAreas[0];
    return undefined;
  }, [subAreas, park, selectedSubAreas]);

  const [live, setLive] = useAtom(turbinesAndFoundationsGenerationIsLiveAtom);

  const methodAndParameters = useAtomValue(
    methodAndParametersForRegionAtomFamily(selectedRegion?.id),
  );

  const selectedZoneSize = useMemo(
    () =>
      undefMap(
        exclusionZones.length !== 0 && selectedRegion
          ? differenceAll([selectedRegion, ...exclusionZones])
          : selectedRegion,
        (r) => (r == null ? undefined : turf.area(r) / 1e6),
      ),
    [selectedRegion, exclusionZones],
  );

  const tooLarge = (selectedZoneSize ?? -Infinity) > MAX_AREA;
  const tooSmall = (selectedZoneSize ?? Infinity) < MIN_AREA;

  const { getFeaturesToSave } = useTurbineGeneration(
    park,
    selectedRegion ?? park,
    DEFAULT_ONSHORE_OFFSHORE_TURBINES[0] as SimpleTurbineType,
  );

  const { warning } = useToast();

  const getLive = useAtomCallback(
    useCallback((get) => get(turbinesAndFoundationsGenerationIsLiveAtom), []),
  );

  const saveOnExitAndApplyCallback = useJotaiCallback(
    async (get, set) => {
      if (!getLive()) return;

      const turbinePreviews = get(previewTurbinesState);
      const mooringPreviews = get(previewMooringAndFoundationState);
      const turbinesWithNewFoundation =
        turbinePreviews?.preview.map((turbine) => {
          const foundationId = mooringPreviews?.preview.foundations.find(
            (f) => f.turbineId === turbine.id,
          )?.foundationId;
          return {
            ...turbine,
            properties: {
              ...turbine.properties,
              foundationId,
            },
          };
        }) ?? [];

      let featuresToSave: {
        add: ProjectFeature[];
        remove: string[];
      } = await getFeaturesToSave(turbinesWithNewFoundation);

      if (mooringPreviews) {
        // // Remove all mooring lines and anchors that are in this park. Everything we want to keep is in `p`.
        // // The same with turbines, because we might have changed the foundation type.
        const { mooringLine: mooringLines, anchor: anchors } = featuresInPark;

        const linesToRemove = mooringLines.filter(
          (ml) =>
            !mooringPreviews.existing.mooringLines.find((m) => ml.id === m.id),
        );
        const anchorsToRemove = anchors.filter(
          (an) =>
            !mooringPreviews?.existing.anchors.find((a) => an.id === a.id),
        );

        if (mooringPreviews.preview.partialTurbines?.length) {
          const n = mooringPreviews.preview.partialTurbines.length;
          warning(`${n} turbines did not get all anchors`);
        }

        featuresToSave = {
          ...featuresToSave,
          add: [
            ...featuresToSave.add,
            ..._AnchorFeature.array().parse(mooringPreviews.preview.anchors),
            ..._MooringLineFeature
              .array()
              .parse(mooringPreviews.preview.mooringLines),
          ],
          remove: dedup([
            ...featuresToSave.remove,
            ...linesToRemove.map((f) => f.id),
            ...anchorsToRemove.map((f) => f.id),
          ]),
        };
      }

      await updateFeatures(featuresToSave);
      set(previewMooringAndFoundationState, undefined);
      set(previewTurbinesState, undefined);
    },
    [featuresInPark, getFeaturesToSave, warning, updateFeatures, getLive],
  );

  const trackEvent = useTrackEvent();

  useEffect(() => {
    return () => {
      saveOnExitAndApplyCallback();
      setLive(false);
    };
  }, [saveOnExitAndApplyCallback, setLive]);

  const {
    refs: refGenerate,
    floatingStyles: floatingStyleGenerate,
    context: contextGenerate,
  } = useFloating({
    placement: "right",
    middleware: [
      offset({
        mainAxis: 10,
      }),
    ],
  });

  const {
    refs: refApply,
    floatingStyles: floatingStyleApply,
    context: contextApply,
  } = useFloating({
    placement: "right-start",
    middleware: [
      offset({
        mainAxis: 140,
      }),
    ],
  });

  const isOnshore = useAtomValue(isOnshoreAtom);

  return (
    <>
      {tooLarge && (
        <div>
          <SimpleAlert
            text={`Too large area. Current maximum is ${MAX_AREA} km²`}
            type={"error"}
          />
        </div>
      )}
      {tooSmall && (
        <div>
          <SimpleAlert
            text={`Too small area. Current minimum is ${MIN_AREA} km²`}
            type={"error"}
          />
        </div>
      )}
      {1 < selectedSubAreas.length && (
        <div>
          <SimpleAlert text={"Select exactly one area"} type={"error"} />
        </div>
      )}
      {subAreas.length > 0 && (
        <Grid2
          style={{
            gridTemplateColumns: "0.8fr 0.9fr",
            gap: spaceSmall,
            padding: "0.4rem 1.6rem",
            justifyContent: "space-between",
          }}
        >
          <InputTitle>Sub area</InputTitle>
          <DropdownButton
            size={"small"}
            itemWidth={"small"}
            style={{
              width: "13.5rem",
            }}
            items={[
              {
                name: park.properties.name ?? "Park",
                value: park.id,
              },
              ...subAreas.map((iz) => ({
                name: iz.properties.name ?? "Unnamed sub area",
                value: iz.id,
              })),
            ]}
            buttonText={selectedRegion?.properties.name ?? "Select sub area"}
            onSelectItem={(value) => {
              setSelectedFeatures([value]);
            }}
            selectedItemValue={selectedRegion?.id}
          />
        </Grid2>
      )}
      {selectedRegion && methodAndParameters && !tooLarge && !tooSmall && (
        <>
          <FloatingFocusManager context={contextApply} modal={false}>
            <TourStep
              tourId="general-intro-tour"
              stepId="editParameters"
              innerRef={refApply.setFloating}
              style={floatingStyleApply}
            />
          </FloatingFocusManager>
          <FloatingFocusManager context={contextApply} modal={false}>
            <TourStep
              tourId="onshore-intro-tour"
              stepId="editParameters"
              innerRef={refApply.setFloating}
              style={floatingStyleApply}
            />
          </FloatingFocusManager>
          <div
            ref={scrollBodyRef}
            style={{
              overflow: "hidden auto",
            }}
          >
            <GenerateTurbines
              park={park}
              live={live}
              selectedRegion={selectedRegion}
              selectedZoneSize={selectedZoneSize}
              innerRef={refApply.setReference}
            />
            {methodAndParameters.method !== "optimize" && !isOnshore && (
              <>
                <SubtitleWithLine
                  style={{
                    paddingTop: "2rem",
                  }}
                  text={"Foundations"}
                />
                <React.Suspense
                  fallback={
                    <Column>
                      <SkeletonText
                        style={{
                          height: "2rem",
                        }}
                        text="Loading bathymetry"
                      />
                    </Column>
                  }
                >
                  <GenerateFoundationsOuter park={park} live={live} />
                </React.Suspense>
              </>
            )}
          </div>
          {methodAndParameters.method === "manual" && (
            <>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: `${spaceLarge} 0 0 ${spaceLarge}`,
                  gap: spaceMedium,
                }}
              >
                {live && (
                  <>
                    <Button
                      id="reset-turbines-button"
                      text={"Reset"}
                      buttonType={"secondary"}
                      onClick={() => {
                        setLive((curr) => !curr);
                      }}
                    />
                    <HighlightStep
                      tourId="general-intro-tour"
                      stepId="editParameters"
                    >
                      <HighlightStep
                        tourId="onshore-intro-tour"
                        stepId="editParameters"
                      >
                        <Button
                          id="apply-turbines-button"
                          text={"Apply"}
                          buttonType={"primary"}
                          onClick={() => {
                            saveOnExitAndApplyCallback();
                            setLive((curr) => !curr);
                            trackEvent("clickedApply");
                          }}
                        />
                      </HighlightStep>
                    </HighlightStep>
                  </>
                )}
                {!live && (
                  <>
                    <FloatingFocusManager
                      context={contextGenerate}
                      modal={false}
                    >
                      <TourStep
                        tourId="general-intro-tour"
                        stepId="createLayout"
                        innerRef={refGenerate.setFloating}
                        style={floatingStyleGenerate}
                      />
                    </FloatingFocusManager>
                    <FloatingFocusManager
                      context={contextGenerate}
                      modal={false}
                    >
                      <TourStep
                        tourId="onshore-intro-tour"
                        stepId="createLayout"
                        innerRef={refGenerate.setFloating}
                        style={floatingStyleGenerate}
                      />
                    </FloatingFocusManager>
                    <HighlightStep
                      tourId="general-intro-tour"
                      stepId="createLayout"
                    >
                      <HighlightStep
                        tourId="onshore-intro-tour"
                        stepId="createLayout"
                      >
                        <Button
                          id="generate-turbines-button"
                          text={"Generate"}
                          ref={refGenerate.setReference}
                          buttonType={"primary"}
                          onClick={() => {
                            setLive((curr) => !curr);
                            trackEvent("clickedGenerate");
                          }}
                        />
                      </HighlightStep>
                    </HighlightStep>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const GenerateWindTurbinesAndFoundationsMenu = () => {
  const park = useAtomValue(currentParkAtom);
  const [layoutControlActive, setLayoutControlActive] = useDrawMode();
  const popupRef = useRef<HTMLDivElement>(null);

  useClickOutside(
    popupRef,
    () => {
      setLayoutControlActive(undefined);
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      if (target.dataset?.["isModal"]) {
        return true;
      }
      if (
        target.classList.contains("vind_anchor") || // <Anchor>ed elements, like dropdown items in sub-panels
        target.classList.contains("dragcover") || // shitty plotly thing that's mounted on drags
        target.classList.contains("PreviousOptimizationsList") // entire opt result panel
      )
        return true;
      const ignoreClickInTheseIds = [
        `button-${GenerateWindTurbinesAndFoundationsMenuType}`,
        "mooring-submenu-double-anchors",
        "mooring-submenu-mooring-angles",
        "opt-submenu-cost",
        "turbine-type-selector",
        // If we're in turbine gen menu and want to open exactly the production statistics pane,
        // let us do so :)
        `button-${TopRightMenuOptions.productionStatistics}`,
      ];

      return ignoreClickInTheseIds.includes(target.id);
    },
    {
      ignoreDragClicks: true,
    },
  );

  if (
    !park ||
    layoutControlActive !== GenerateWindTurbinesAndFoundationsMenuType
  )
    return null;

  return (
    <>
      <MenuFrame
        id={GenerateWindTurbinesAndFoundationsMenuType}
        title="Turbine layout"
        icon={<HelpLink article={ARTICLE_LAYOUT_GEN} />}
        ref={popupRef}
        onExit={() => {
          setLayoutControlActive(undefined);
        }}
        style={{
          maxHeight: "calc(100vh - 18rem)",
          boxSizing: "border-box",
          position: "relative",
          overflow: "visible",
        }}
      >
        <React.Suspense
          fallback={
            <Column>
              <SkeletonText
                style={{
                  height: "2rem",
                }}
                text="Loading bathymetry"
              />
            </Column>
          }
        >
          <Inner park={park} />
        </React.Suspense>
      </MenuFrame>
    </>
  );
};

export default GenerateWindTurbinesAndFoundationsMenu;
