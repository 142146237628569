import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useAtom, useAtomValue } from "jotai";
import { Mixpanel } from "mixpanel";
import { modalTypeOpenAtom } from "state/modal";
import { featuresListAtom } from "state/jotai/features";
import { ProjectFeature } from "types/feature";
import useTextInput from "hooks/useTextInput";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { Label } from "components/General/Form";
import { TextInput } from "components/General/Input";
import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { sortFeatures, SortOrder, templateVariables } from "./utils";

export const GenerateNamesModalType = "GenerateNamesModal" as const;

const GenerateNamesModalInner = ({
  featureIds,
  onClose,
}: {
  featureIds: string[];
  onClose(): void;
}) => {
  const element = useMemo(() => document.createElement("div"), []);
  const projectFeatures = useAtomValue(featuresListAtom);
  const features = useMemo(() => {
    return projectFeatures.filter((f) => featureIds.includes(f.id));
  }, [featureIds, projectFeatures]);

  const firstFeature = features[0];

  const [inputValue, onInputValueChange, setInputValue] = useTextInput(
    `#${templateVariables[1].template}`,
  );
  const [sortOrder, setSortOrder] = useState(
    SortOrder.AS_SEEN_IN_ELEMENTS_LIST,
  );
  const { update: updateFeatures } = useProjectElementsCrud();

  const replaceTemplates = useCallback(
    (feature: ProjectFeature, index: number) => {
      return templateVariables.reduce((acc, { template, replace }) => {
        return replace(acc, template, feature, index);
      }, inputValue);
    },
    [inputValue],
  );

  const onGenerateClick = useCallback(() => {
    const sortedFeatures = sortFeatures([...features], sortOrder);

    Mixpanel.track_old("Generate new names - onGenerate click", {
      nrFeatures: features.length,
      sortOrder,
    });
    const updatedFeatures = sortedFeatures.map((feature, index) => {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          name: replaceTemplates(feature, index + 1),
        },
      };
    });

    updateFeatures({
      update: updatedFeatures,
    });
    onClose();
  }, [features, sortOrder, updateFeatures, onClose, replaceTemplates]);

  useEffect(() => {
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal
      onClick={onClose}
      onEscape={() => {
        Mixpanel.track_old("Generate new names - onEscape", {
          nrFeatures: features.length,
        });
        onClose();
      }}
    >
      <ModalFrame
        title="Create names"
        style={{
          width: "40rem",
          gap: "1.2rem",
        }}
        h2style={{ margin: 0 }}
      >
        <p>
          Available templates:
          <code>
            {templateVariables.map((f) => (
              <span
                key={f.template}
                style={{
                  cursor: "pointer",
                  marginLeft: "0.8rem",
                }}
                onClick={() => {
                  setInputValue((curr) => curr.concat(f.template));
                  Mixpanel.track_old("Generate new names - onTemplateClick", {
                    nrFeatures: features.length,
                    template: f.template,
                  });
                }}
              >
                {f.template}
              </span>
            ))}
          </code>
        </p>
        <Column>
          <Label htmlFor={"generate-names-input-field"}>Name</Label>
          <TextInput
            autoFocus
            id="generate-names-input-field"
            style={{
              width: "100%",
            }}
            value={inputValue}
            onChange={onInputValueChange}
          />
        </Column>

        <Column>
          <Label htmlFor={"generate-names-sort-order"}>
            Sort order (Applicable if using {templateVariables[1].template})
          </Label>
          <Dropdown
            id="generate-names-sort-order"
            value={sortOrder}
            onChange={(e) => {
              Mixpanel.track_old("Generate new names - onSortOrderChange", {
                nrFeatures: features.length,
                sortOrder: e.target.value,
              });
              setSortOrder(e.target.value as SortOrder);
            }}
          >
            <option value={SortOrder.AS_SEEN_IN_ELEMENTS_LIST}>
              As seen in elements list
            </option>
            <option value={SortOrder.LATITUDE_DESC}>North to south</option>
            <option value={SortOrder.LATITUDE_ASC}>South to north</option>
            <option value={SortOrder.LONGITUDE_ASC}>West to east</option>
            <option value={SortOrder.LONGITUDE_DESC}>East to west</option>
          </Dropdown>
        </Column>

        {firstFeature && (
          <Column>
            <Label>Result example</Label>
            <p>{replaceTemplates(firstFeature, 20)}</p>
          </Column>
        )}
        <Row
          style={{
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Button
            buttonType="secondary"
            text="Cancel"
            onClick={() => {
              Mixpanel.track_old("Generate new names - onCancelClick", {
                nrFeatures: features.length,
              });
              onClose();
            }}
          />
          <Button
            buttonType="primary"
            text="Generate"
            onClick={onGenerateClick}
          />
        </Row>
      </ModalFrame>
    </FullScreenModal>,
    element,
  );
};

const GenerateNamesModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  if (modalTypeOpen?.modalType !== GenerateNamesModalType) {
    return null;
  }

  return (
    <GenerateNamesModalInner
      featureIds={modalTypeOpen.metadata.featureIds}
      onClose={onClose}
    />
  );
};

export default GenerateNamesModal;
