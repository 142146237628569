import { RESET } from "jotai/utils";
import { useJotaiCallback } from "utils/jotai";
import { nodesInOrganisationAtom } from "components/Projects/useOrganisationFolderCrud";
import { isLoaded } from "types/Load";
import { usersNodeAccessInNodeAndSubnodesAtomFamily } from "./state";
import { getParentNodeIds } from "./utils";

const useRefreshUsersInNodeAndSubnodes = () => {
  return useJotaiCallback(
    (get, set, organisationId: string, nodeId: string) => {
      const allNodes = get(
        nodesInOrganisationAtom({
          organisationId,
        }),
      )
        .data.filter(isLoaded)
        .map((n) => n.value);

      const thisNode = allNodes?.find((node) => node.id === nodeId);

      const parentIds = getParentNodeIds(allNodes ?? [], thisNode?.parent_id);

      parentIds.forEach((parentId) => {
        set(
          usersNodeAccessInNodeAndSubnodesAtomFamily({
            organisationId,
            nodeId: parentId,
          }),
          RESET,
        );
      });

      set(
        usersNodeAccessInNodeAndSubnodesAtomFamily({
          organisationId,
          nodeId,
        }),
        RESET,
      );

      set(
        usersNodeAccessInNodeAndSubnodesAtomFamily({
          organisationId,
          nodeId: organisationId,
        }),
        RESET,
      );
    },
    [],
  );
};

export default useRefreshUsersInNodeAndSubnodes;
