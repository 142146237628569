import { DIVISION_FACTOR } from "components/ViewAnalyses/ViewToPark/constants";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { viewParkShadowLenghtAtom } from "state/viewParkShadow";
import { viewTurbineCoordsAtom } from "state/viewToPark";
import { BufferGeometry, Mesh, MeshStandardMaterial, Vector3 } from "three";

export const useUpdateTerrainTurbineRadiusSize = (
  terrain: Mesh<BufferGeometry, MeshStandardMaterial> | undefined,
) => {
  const turbineCoords = useAtomValue(viewTurbineCoordsAtom);
  const shadowLength = useAtomValue(viewParkShadowLenghtAtom);

  useEffect(() => {
    if (!terrain || !turbineCoords || turbineCoords.length === 0) return;

    const radius = shadowLength / DIVISION_FACTOR;
    const points = turbineCoords.map(
      (coord) => new Vector3(-coord[0], 0, coord[1]),
    );
    // Store the original onBeforeCompile if we haven't modified the material yet

    terrain.material.userData.modified = true;

    terrain.material.onBeforeCompile = (shader) => {
      // Store shader reference for later updates
      terrain.material.userData.shader = shader;

      // Add uniforms
      shader.uniforms.points = { value: points };
      shader.uniforms.pointCount = { value: points.length };
      shader.uniforms.radius = { value: radius };

      // Add varying and uniforms to vertex shader
      shader.vertexShader = shader.vertexShader.replace(
        "#include <common>",
        `
      #include <common>
      varying vec3 vWorldPosition;
      `,
      );

      // Set varying in vertex shader
      shader.vertexShader = shader.vertexShader.replace(
        "#include <begin_vertex>",
        `
      #include <begin_vertex>
      vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      `,
      );

      // Add uniforms and functions to fragment shader
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <common>",
        `
      #include <common>
      uniform vec3 points[${points.length}];
      uniform int pointCount;
      uniform float radius;
      varying vec3 vWorldPosition;

      float getMinDistanceToPoints() {
        float minDist = 1000000.0;
        for(int i = 0; i < ${points.length}; i++) {
          vec2 pointXZ = vec2(points[i].x, points[i].z);
          vec2 posXZ = vec2(vWorldPosition.x, vWorldPosition.z);
          float dist = distance(posXZ, pointXZ);
          minDist = min(minDist, dist);
        }
        return minDist;
      }
      `,
      );

      // Modify the output to include our circle effect
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <output_fragment>",
        `
        float dist = getMinDistanceToPoints();

        float ring = step(dist, radius);
        vec3 ringColor = vec3(1.0, 0.0, 0.0);
        
        outgoingLight = mix(outgoingLight, ringColor, ring * 0.1);
          
        gl_FragColor = vec4(outgoingLight, diffuseColor.a);
      `,
      );
    };

    // Force material update
    terrain.material.needsUpdate = true;

    // Update uniforms if shader exists
    const shader = terrain.material.userData.shader;
    if (shader) {
      shader.uniforms.radius.value = radius;
    }
  }, [turbineCoords, terrain, shadowLength]);
};
