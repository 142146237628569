import * as turf from "@turf/turf";
import { Feature, LineString, MultiPolygon, Polygon, Position } from "geojson";
import { v4 as uuidv4 } from "uuid";
import { OtherFeature, ProjectFeature } from "../types/feature";
import { bearingAdd, totalRingCurvature } from "./geometry";
import { isLineStringFeature, isPolygonFeature } from "./predicates";
import { scream, sendWarning } from "./sentry";

/**
 * Buffer a `LineString` feature so that it narrows in towards the endpoints, like a pencil.
 * @param bufferDist In kilometers.
 * @param graceEndpoints Don't go all the way to the endpoints, but leave a 10m grace region.
 */
export const pencilBufferLineString = (
  feature: Feature<LineString>,
  bufferDist: number,
  graceEndpoints: boolean = false,
): undefined | Feature<Polygon> => {
  if (bufferDist < 0.01) return undefined;
  if (2 === feature.geometry.coordinates.length) {
    // Special case for line with only two points. Here we want to make the pencil part a small triangle.
    // Since we already know the endpoints, we can construct this polygon manually.
    // This multiplier is used to make the triangle more acute, which is useful when making mooring line buffers.
    const factor = 1.5;

    const len = turf.length(feature, { units: "kilometers" });
    // The buffer distance cannot be larger than half the length; in that case we get a diamond.
    bufferDist = Math.min(bufferDist, len / 2.01);
    const factorBufferDist = Math.min(bufferDist * factor, len / 2.01);

    let [leftTip, rightTip] = feature.geometry.coordinates;
    const bearing = turf.bearing(leftTip, rightTip);

    const leftCenter = turf.destination(leftTip, factorBufferDist, bearing);
    const rightCenter = turf.destination(
      rightTip,
      factorBufferDist,
      bearingAdd(bearing, 180),
    );

    if (graceEndpoints) {
      const grace = 10 / 1000;
      const leftGrace = turf.destination(leftTip, grace, bearing);
      const rightGrace = turf.destination(
        rightTip,
        grace,
        bearingAdd(bearing, 180),
      );
      leftTip = leftGrace.geometry.coordinates;
      rightTip = rightGrace.geometry.coordinates;
    }

    const leftUp = turf.destination(
      leftCenter.geometry.coordinates,
      bufferDist,
      bearingAdd(bearing, -90),
    ).geometry.coordinates;
    const leftDown = turf.destination(
      leftCenter.geometry.coordinates,
      bufferDist,
      bearingAdd(bearing, 90),
    ).geometry.coordinates;

    const rightUp = turf.destination(
      rightCenter.geometry.coordinates,
      bufferDist,
      bearingAdd(bearing, -90),
    ).geometry.coordinates;
    const rightDown = turf.destination(
      rightCenter.geometry.coordinates,
      bufferDist,
      bearingAdd(bearing, 90),
    ).geometry.coordinates;

    const coords = [
      leftTip,
      leftDown,
      rightDown,
      rightTip,
      rightUp,
      leftUp,
      leftTip,
    ];
    const totalCurvature = totalRingCurvature(coords);
    if (1e-4 < Math.abs(totalCurvature - 2 * Math.PI))
      scream("The resulting polygon is self-intersecting");
    return turf.polygon([coords]);
  } else if (2 < feature.geometry.coordinates.length) {
    const makePencilFeature = (
      end: Position,
      pen: Position,
    ): Feature<Polygon> => {
      const bearing = turf.bearing(pen, end);

      const up = turf.destination(pen, bufferDist, bearingAdd(bearing, 90), {
        units: "kilometers",
      }).geometry.coordinates;

      const down = turf.destination(pen, bufferDist, bearingAdd(bearing, -90), {
        units: "kilometers",
      }).geometry.coordinates;

      const feature: Feature<Polygon> = {
        id: "tmp-id",
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [[end, down, up, end]],
        },
        properties: {},
      };
      return feature;
    };

    const c = feature.geometry.coordinates;
    const first = makePencilFeature(c[0], c[1]);
    const last = makePencilFeature(c.at(-1)!, c.at(-2)!);

    const middleFeature =
      c.length <= 3
        ? turf.point(c[1])
        : turf.lineString(feature.geometry.coordinates.slice(1, -1));

    const middle = turf.buffer(middleFeature, bufferDist, {
      units: "kilometers",
    });

    if (!middle) {
      sendWarning("pencilBufferLineString: failed to buffer middle feature", {
        middleFeature,
        bufferDist,
      });
      return undefined;
    }

    const firstUnion = turf.union(first, middle);
    if (!firstUnion) return undefined;
    const secondUnion = turf.union(firstUnion, last);
    if (!secondUnion) return undefined;
    if (isPolygonFeature(secondUnion)) return secondUnion;
    throw scream("Should be impossible to get a MultiPolygon here");
  }
  return undefined;
};

export const bufferSingleFeature = (
  feature: ProjectFeature,
  bufferSize: number,
  narrowInTowardsEnd: boolean,
  steps = 4,
): undefined | OtherFeature<Polygon | MultiPolygon> => {
  const bufferDist = bufferSize / 1000;

  let buffered: Feature<Polygon | MultiPolygon> | undefined | null;
  if (isLineStringFeature(feature) && narrowInTowardsEnd) {
    buffered = pencilBufferLineString(feature, bufferDist);
  } else {
    buffered = turf.buffer(feature, bufferDist, {
      units: "kilometers",
      steps,
    });
  }

  // This can happen if the distance is larger than the feature.
  if (!buffered) return undefined;

  const name = feature.properties.name
    ? `${feature.properties.name} - Buffer`
    : "Buffer result";

  const newId = uuidv4();
  const newFeature: OtherFeature<Polygon | MultiPolygon> = {
    ...buffered,
    id: newId,
    properties: {
      id: newId,
      name,
    },
  };

  return newFeature;
};
