import { mapAtom } from "state/map";
import { projectIdAtomDef } from "state/pathParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  renderHouseDistanceFilterAtom,
  houseDistanceFilterAtomFamily,
  tilesToRequestAtom,
  TileStatus,
  renderHouseDistanceFilterTypeSetAtom,
  renderHouseDistanceFilterTilesLoadingAtom,
} from "../state/filter";
import { useAtom, useAtomValue } from "jotai";
import {
  fetchMapboxBuildingVectorTileAtom,
  lowerRightMenuActiveModeAtom,
  OSM_ZOOM_LEVEL,
} from "state/layer";
import { lonLatToTile } from "types/tile";
import { useAtomCallback, useResetAtom } from "jotai/utils";
import * as turf from "@turf/turf";
import { Feature, GeoJsonProperties, Point } from "geojson";
import { addLayer, filterOnshoreLayerId } from "components/Mapbox/utils";
import { maxResidentialFilterZoomLevel } from "@constants/onshoreFilter";
import { FilterOnshoreMenuType } from "components/LowerRight/FilterOnshoreInput";
import { reduceCoordinatePrecision } from "utils/geojson/utils";
import { roundToDecimal } from "utils/utils";
import { getRotationAndTiltIndependentViewportBounds } from "utils/map";

const sourceId = "buildings";
const maxTileToRequest = 500;

const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

const OSMBuildingTile = ({
  xyzString,
  setTileStatus,
}: {
  xyzString: string;
  setTileStatus: (
    xyzString: string,
    status: TileStatus,
    result?: Feature<Point>[],
  ) => void;
}) => {
  const [x, y, z] = xyzString.split(",").map(Number);
  const map = useAtomValue(mapAtom);
  const refreshfetchMapboxBuildingVectorTileAtom = useResetAtom(
    fetchMapboxBuildingVectorTileAtom({ x, y, z }),
  );

  const getOSMFeaturesFromMapbox = useAtomCallback(
    useCallback(
      async (get) => {
        if (!map) return;

        let isSubmitted = false;
        const asyncFunc = async () => {
          try {
            const timeoutKeeper = window.setTimeout(
              () => setTileStatus(xyzString, "error"),
              8_000,
            );
            const features = await get(
              fetchMapboxBuildingVectorTileAtom({
                x,
                y,
                z,
              }),
            );
            window.clearTimeout(timeoutKeeper);
            const filteredFeatures = Object.values(
              features
                .flat()
                .map((f) => ({
                  ...turf.centerOfMass(f),
                  properties: f.properties,
                }))
                .map((f) => {
                  const reducedCoords = reduceCoordinatePrecision(
                    f.geometry.coordinates,
                    5,
                  ) as [number, number];
                  return {
                    type: "Feature",
                    geometry: { type: "Point", coordinates: reducedCoords },
                    properties: f.properties,
                  } as Feature<Point, GeoJsonProperties>;
                }),
            );

            if (isSubmitted) return;
            setTileStatus(xyzString, "complete", filteredFeatures);
          } catch (e: any) {
            if (e.message.includes("FS error")) {
              refreshfetchMapboxBuildingVectorTileAtom();
              console.warn(
                `FS error when getting OSM buildings tile ${xyzString}, will retry this tile in 1 sec`,
              );
              window.setTimeout(() => {
                asyncFunc();
              }, 1000);
              return;
            }
            setTileStatus(xyzString, "error");
            throw e;
          } finally {
          }
        };

        asyncFunc();

        return () => {
          isSubmitted = true;
        };
      },
      [
        map,
        x,
        y,
        z,
        xyzString,
        refreshfetchMapboxBuildingVectorTileAtom,
        setTileStatus,
      ],
    ),
  );

  useEffect(() => {
    getOSMFeaturesFromMapbox();
  }, [getOSMFeaturesFromMapbox]);

  return null;
};

const ResidentialFilterLayer = ({
  osmBuildingsCenterPoints,
  distance,
  latitude,
}: {
  osmBuildingsCenterPoints: Feature<Point, GeoJsonProperties>[];
  distance: number;
  latitude: number;
}) => {
  const renderHouseDistanceFilterTypeSet = useAtomValue(
    renderHouseDistanceFilterTypeSetAtom,
  );
  const map = useAtomValue(mapAtom);

  useEffect(() => {
    if (!map) return;
    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: true,
      clusterMaxZoom: 18, // Max zoom to cluster points on
      clusterRadius: 10,
    });
    addLayer(map, {
      id: filterOnshoreLayerId,
      type: "circle",
      source: sourceId,
      paint: {
        "circle-radius": {
          stops: [
            [0, 0],
            [20, metersToPixelsAtMaxZoom(800, 10)],
          ],
          base: 2,
        },
        "circle-color": "#FF0000",
        "circle-opacity": 0.1,
        "circle-stroke-color": "#FF0000",
        "circle-pitch-alignment": "map",
      },
    });
    return () => {
      map.removeLayer(filterOnshoreLayerId);
      map.removeSource(sourceId);
    };
  }, [map]);

  useEffect(() => {
    if (!map || !map.getSource(sourceId)) return;

    const buildingTypeArray = Array.from(renderHouseDistanceFilterTypeSet);
    const filteredBuildings = osmBuildingsCenterPoints.filter(
      (f) =>
        buildingTypeArray.length === 0 ||
        buildingTypeArray.some((type) =>
          (f.properties?.type ?? "").includes(type),
        ),
    );

    const source = map.getSource(sourceId);
    if (source?.type === "geojson") {
      source.setData({
        type: "FeatureCollection",
        features: filteredBuildings,
      });
    }
  }, [osmBuildingsCenterPoints, map, renderHouseDistanceFilterTypeSet]);

  useEffect(() => {
    if (!map || !map.getLayer(filterOnshoreLayerId)) return;
    map.setPaintProperty(filterOnshoreLayerId, "circle-radius", {
      stops: [
        [0, 0],
        [20, metersToPixelsAtMaxZoom(distance, latitude)],
      ],
      base: 2,
    });
  }, [distance, latitude, map]);

  return null;
};

const ResidentialFilter = () => {
  const projectId = useAtomValue(projectIdAtomDef);

  const map = useAtomValue(mapAtom);

  const distance = useAtomValue(houseDistanceFilterAtomFamily({ projectId }));
  const [latitude, setLatitude] = useState(0);

  const [tilesToRequest, setTilesToRequestSet] = useAtom(tilesToRequestAtom);
  const renderHouseDistanceFilterTilesLoading = useAtomValue(
    renderHouseDistanceFilterTilesLoadingAtom,
  );

  const fetchTiles = useCallback(() => {
    if (!map) return;
    if (roundToDecimal(map.getZoom(), 1) < maxResidentialFilterZoomLevel) {
      return;
    }
    const mapBounds = getRotationAndTiltIndependentViewportBounds(map);
    if (!mapBounds) return;
    const bbox = [
      mapBounds.getWest(),
      mapBounds.getSouth(),
      mapBounds.getEast(),
      mapBounds.getNorth(),
    ];

    const minLonLat = lonLatToTile(bbox[0], bbox[1], OSM_ZOOM_LEVEL);
    const maxLonLat = lonLatToTile(bbox[2], bbox[3], OSM_ZOOM_LEVEL);
    const centerLat = (bbox[1] + bbox[3]) / 2;
    setLatitude(centerLat);

    //MaxLonLat.y is lower than minLonLat.y
    const tiles = [minLonLat.x, maxLonLat.y, maxLonLat.x, minLonLat.y] as [
      number,
      number,
      number,
      number,
    ];

    const tilesToRequestString: string[] = [];
    for (let y = tiles[1]; y <= tiles[3]; y++) {
      for (let x = tiles[0]; x <= tiles[2]; x++) {
        tilesToRequestString.push(`${x},${y},${OSM_ZOOM_LEVEL}`);
      }
    }

    const boundedTilesToRequestString = tilesToRequestString.slice(
      0,
      maxTileToRequest - renderHouseDistanceFilterTilesLoading,
    );
    setTilesToRequestSet((curr) => ({
      ...curr,
      ...boundedTilesToRequestString
        .filter((xyzString) => !curr[xyzString])
        .reduce((acc, key) => ({ ...acc, [key]: { status: "pending" } }), {}),
    }));
  }, [
    map,
    setTilesToRequestSet,
    renderHouseDistanceFilterTilesLoading,
    setLatitude,
  ]);

  useEffect(() => {
    if (!map) return;

    fetchTiles();
    map.on("moveend", fetchTiles);
    return () => {
      map.off("moveend", fetchTiles);
    };
  }, [map, fetchTiles]);

  const setTileStatus = useCallback(
    (xyzString: string, status: TileStatus, result?: Feature<Point>[]) => {
      setTilesToRequestSet((curr) => ({
        ...curr,
        [xyzString]: { status, result },
      }));
    },
    [setTilesToRequestSet],
  );

  const osmBuildingsCenterPoints = useMemo(() => {
    return Object.values(tilesToRequest)
      .filter((t) => t.status === "complete")
      .flatMap((t) => t.result ?? []);
  }, [tilesToRequest]);

  return (
    <>
      {Object.keys(tilesToRequest)
        .filter((t) =>
          ["pending", "processing"].includes(tilesToRequest[t].status),
        )
        .map((key) => (
          <OSMBuildingTile
            key={key}
            xyzString={key}
            setTileStatus={setTileStatus}
          />
        ))}
      <ResidentialFilterLayer
        osmBuildingsCenterPoints={osmBuildingsCenterPoints}
        distance={distance}
        latitude={latitude}
      />
    </>
  );
};

const FilterOnshore = () => {
  const lowerRightActiveMode = useAtomValue(lowerRightMenuActiveModeAtom);
  const enabled = lowerRightActiveMode === FilterOnshoreMenuType;
  const renderResidentialDistance = useAtomValue(renderHouseDistanceFilterAtom);

  if (!enabled) return null;

  return <>{renderResidentialDistance && <ResidentialFilter />}</>;
};

export default FilterOnshore;
