import * as turf from "@turf/turf";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { subAreasInParkFamily } from "state/jotai/subArea";

export const zoneOverlapWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.OverlappingSubAreas>
        | undefined
      >
    >(async (get) => {
      const subAreas = await get(subAreasInParkFamily({ parkId, branchId }));

      const overlapIds: string[] = [];
      for (let i = 0; i < subAreas.length; i++) {
        const a = subAreas[i];
        for (let j = i + 1; j < subAreas.length; j++) {
          const b = subAreas[j];
          const result = turf.intersect(a, b);
          if (result) overlapIds.push(a.id, b.id);
        }
      }

      if (overlapIds.length === 0) return undefined;
      return {
        type: ValidationWarningTypes.OverlappingSubAreas,
        featureIds: overlapIds,
        parkId: parkId,
      };
    }),
);
