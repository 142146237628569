import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import * as Sentry from "@sentry/react";
import { featuresFamily, featuresInParkFamily } from "./features";
import { ExistingTurbineFeature, TurbineFeature } from "types/feature";
import { parkFamily, parkPaddedPolygonFamily, parksFamily } from "./park";
import * as turf from "@turf/turf";
import { simpleTurbineTypesAtom } from "./turbineType";
import { SimpleTurbineType } from "types/turbines";
import { FoundationType } from "types/foundations";
import { foundationTypesAtom } from "./foundation";
import { selectedProjectFeaturesAtom } from "./selection";
import { isSubArea } from "utils/predicates";
import { pointInPolygon } from "utils/geometry";
import { existingTurbinesFamily } from "./existingTurbine";
import { scream } from "utils/sentry";

export const turbinesFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<TurbineFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).turbine,
    ),
);

export const turbinesInParkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<TurbineFeature[]>>(
      async (get) =>
        (await get(featuresInParkFamily({ parkId, branchId }))).turbine,
    ),
);

export const turbinesInParkByLabelFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<TurbineFeature[]>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const withLabel: [number, TurbineFeature][] = turbines.map((t) => {
        const n = parseInt(t.properties.name?.slice(1) ?? "0");
        return [n, t];
      });
      withLabel.sort((a, b) => a[0] - b[0]);
      return withLabel.map((t) => t[1]);
    }),
);

/**
 * `throw`s if some turbines are missing a valid type.
 */
export const turbinesInParkWithTypesFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<[TurbineFeature, SimpleTurbineType][]>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const types = await get(simpleTurbineTypesAtom);
      const ret: [TurbineFeature, SimpleTurbineType][] = [];
      for (const t of turbines) {
        const typ = types.get(t.properties.turbineTypeId);
        if (!typ) continue;
        ret.push([t, typ]);
      }
      return ret;
    }),
);

/**
 * All turbines in the park which `turbineType` does not correspond to a known turbine type.
 */
export const turbinesInParkWithIllegalTypeFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<TurbineFeature[]>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const types = await get(simpleTurbineTypesAtom);
      return turbines.filter((t) => !types.has(t.properties.turbineTypeId));
    }),
);

/**
 * Filters out turbines without a valid foundation.
 */
export const turbinesInParkWithFoundationFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string }) =>
    atom<Promise<[TurbineFeature, FoundationType][]>>(async (get) => {
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const foundations = await get(foundationTypesAtom);
      const ret: [TurbineFeature, FoundationType][] = [];
      for (const t of turbines) {
        if (!t.properties.foundationId) continue;
        const typ = foundations.get(t.properties.foundationId);
        if (!typ) continue;
        ret.push([t, typ]);
      }
      return ret;
    }),
);

export const turbinesInParkWithTypeAndFoundationFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<[TurbineFeature, SimpleTurbineType, FoundationType][]>>(
      async (get) => {
        const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
        const types = await get(simpleTurbineTypesAtom);
        const foundations = await get(foundationTypesAtom);
        const ret: [TurbineFeature, SimpleTurbineType, FoundationType][] = [];
        for (const t of turbines) {
          const typ = types.get(t.properties.turbineTypeId);
          if (!typ) continue;
          if (!t.properties.foundationId) continue;
          const foundation = foundations.get(t.properties.foundationId);
          if (!foundation) continue;
          ret.push([t, typ, foundation]);
        }
        return ret;
      },
    ),
);

/**
 * List turbines close to a park.  Only includes turbines which park overlaps
 * the padded input park.
 */
export const surroundingTurbinesFamily = atomFamily(
  ({
    parkId,
    branchId,
    maxDistanceKM,
  }: {
    parkId: string;
    branchId: string | undefined;
    maxDistanceKM: number | undefined;
  }) =>
    atom<Promise<TurbineFeature[]>>(async (get) => {
      const hull = await get(
        parkPaddedPolygonFamily({
          parkId,
          branchId,
          maxDistanceKM,
        }),
      );
      if (!hull) {
        Sentry.addBreadcrumb({
          category: "surroundingTurbines",
          message: "Failed to compute hull",
          data: {
            parkId,
            branchId,
            maxDistanceKM,
          },
        });
        return [];
      }

      const parks = await get(parksFamily({ branchId }));
      const closestParks = parks.filter(
        (p) => p.id !== parkId && turf.intersect(hull, p),
      );

      const turbinesInParks = (
        await Promise.all(
          closestParks.map(
            async (p) =>
              await get(turbinesInParkFamily({ parkId: p.id, branchId })),
          ),
        )
      ).flat();
      return turbinesInParks.filter((t) => turf.inside(t, hull));
    }),
);

export const surroundingTurbinesWithZonesFamily = atomFamily(
  ({
    parkId,
    branchId,
    maxDistanceKM,
  }: {
    parkId: undefined | string;
    branchId: undefined | string;
    maxDistanceKM: undefined | number;
  }) =>
    atom<Promise<TurbineFeature[]>>(async (get) => {
      if (!parkId || !branchId || !maxDistanceKM) return [];

      const park = await get(parkFamily({ parkId, branchId }));

      const closeTurbines = !park
        ? []
        : await get(
            surroundingTurbinesFamily({
              parkId,
              branchId,
              maxDistanceKM,
            }),
          );

      const selectedFeatures = await get(selectedProjectFeaturesAtom);
      const selectedSubAreas = selectedFeatures.filter(isSubArea);
      const selectedSubAreaIds = selectedSubAreas.map((f) => f.id);
      if (selectedSubAreaIds.length !== 0) {
        const layout = await get(turbinesInParkFamily({ parkId, branchId }));
        const turbineFeaturesOutsideSubAreas = layout.filter(
          (feature) =>
            !selectedSubAreas.some((e) =>
              pointInPolygon(feature.geometry, e.geometry),
            ),
        );
        return [...closeTurbines, ...turbineFeaturesOutsideSubAreas];
      }

      return closeTurbines;
    }),
);

export const existingTurbinesCloseToParkFamily = atomFamily(
  ({
    parkId,
    branchId,
    maxDistanceKM,
  }: {
    parkId: undefined | string;
    branchId: undefined | string;
    maxDistanceKM: undefined | number;
  }) =>
    atom<Promise<ExistingTurbineFeature[]>>(async (get) => {
      if (!parkId || !branchId || !maxDistanceKM) return [];

      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return [];

      const paddedPark = turf.buffer(park, maxDistanceKM);
      if (!paddedPark) {
        scream(new Error("Failed to buffer park"), { park, maxDistanceKM });
        return [];
      }

      const closeValidExistingTurbines = (
        await get(existingTurbinesFamily({ branchId }))
      )
        .filter((t) => t.properties.power)
        .filter((t) => turf.inside(t, paddedPark));

      return closeValidExistingTurbines;
    }),
);
