import { OverlineText } from "components/General/GeneralSideModals.style";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spaceMedium, spaceSmall, spaceTiny } from "styles/space";
import { typography } from "styles/typography";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background-color: #00070f66;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 40rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  ${typography.h3}
`;

export const Subtitle = styled.div`
  color: ${colors.textSecondary};
  ${typography.sub4}
`;

export const GridValue = styled.div<{ showBg?: boolean }>`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${spaceTiny};

  ${({ showBg }) => showBg && `background-color: ${colors.surfaceSecondary};`}
`;

export const GridValueLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceTiny};
`;

export const GridKey = styled.div<{ showBg?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ showBg }) => showBg && `background-color: ${colors.surfaceSecondary};`}
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.borderSubtle};
  margin: 0 -2rem;
`;

export const OpenPreviewButton = styled.div`
  display: flex;
  padding: 0 0.9rem;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 1rem;
    height: 1rem;
    stroke: ${colors.iconSubtle};
    &:hover {
      stroke: ${colors.iconHover};
    }
  }
`;

export const ColoredGridPreviewItem = styled.div<{ noColor?: boolean }>`
  ${typography.caption}
  display: grid;
  grid-template-columns: 1fr fit-content(20%) repeat(5, 1fr);

  padding-bottom: ${spaceSmall};
  padding-left: ${spaceMedium};

  & > * {
    padding: ${spaceSmall};
  }
  & > :nth-child(7n + 3):not(:last-child) {
    border-right: 1px solid ${colors.grey400};
  }

  & > :nth-child(7n + 2):not(:last-child) {
    border-right: 1px solid ${colors.grey400};
  }

  & > :nth-child(n + 8):nth-child(-n + 14) {
    border-bottom: 1px solid ${colors.blue500};
  }
  & > :nth-child(14n-13),
  & > :nth-child(14n-12),
  & > :nth-child(14n-11),
  & > :nth-child(14n-10),
  & > :nth-child(14n-9),
  & > :nth-child(14n-8),
  & > :nth-child(14n-7) {
    background-color: ${(p) => !p.noColor && colors.surfaceSecondary};
  }

  > p {
    margin: 0;
    overflow-x: hidden;
    word-wrap: break-word;
  }
`;
export const FoundationColoredGridPreviewItem = styled.div`
  ${typography.caption}
  display: grid;

  padding-bottom: ${spaceSmall};
  padding-left: ${spaceMedium};

  & > * {
    padding: ${spaceSmall} ${spaceMedium};
  }

  > p {
    margin: 0;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  ${typography.caption}
  grid-template-columns: 1fr 1.6fr repeat(4, 1fr);

  & > :nth-child(6n + 1):not(:last-child),
  & > :nth-child(6n + 2):not(:last-child) {
    border-right: 1px solid ${colors.grey400}; // Grey vertical line between material and supplier cost
  }

  & > :nth-child(n + 7):nth-child(-n + 12) {
    border-bottom: 1px solid ${colors.blue500}; // Blue horizontal line under sub header
  }
  & > :nth-child(12n-11),
  & > :nth-child(12n-10),
  & > :nth-child(12n-9),
  & > :nth-child(12n-8),
  & > :nth-child(12n-7),
  & > :nth-child(12n-6) {
    background-color: ${colors.surfaceSecondary};
  }
`;

export const TableHeader = styled.div`
  ${typography.sub2};
  color: ${colors.textPrimary};
  background-color: ${colors.surfaceSecondary};
  text-align: left;
`;

export const TableSubHeader = styled(OverlineText)`
  text-align: left;
  color: ${colors.textPrimary};
  background-color: ${colors.surfacePrimary};
  border-bottom: 1px solid ${colors.blue500};
`;

export const ColoredGridProjectItems = styled.div<{ nrColumns: number }>`
  ${typography.caption}
  width: 100%;
  display: grid;
  ${({ nrColumns }) =>
    nrColumns >= 1
      ? `
      grid-template-columns: repeat(${nrColumns - 1}, 1fr) auto;
    `
      : `
        grid-template-columns: 1fr;
      `}

  padding-bottom: ${spaceSmall};
  padding-left: ${spaceMedium};

  & > * {
    padding: ${spaceSmall};
    justify-content: left;
  }

  > p {
    margin: 0;
    overflow-x: hidden;
    word-wrap: break-word;
  }
`;
