import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { lowerRightMenuActiveModeAtom } from "../../state/layer";
import { useAtom, useAtomValue } from "jotai";

import { isOnshoreAtom } from "state/onshore";
import ResidentialFilter from "./ResidentialFilter";

export const FilterOnshoreMenuType = "FilterOnshoreMenu";

const FilterOnshoreInput = () => {
  const onshore = useAtomValue(isOnshoreAtom);
  const [lowerRightActiveMode, setLowerRightActiveMode] = useAtom(
    lowerRightMenuActiveModeAtom,
  );
  if (lowerRightActiveMode !== FilterOnshoreMenuType || !onshore) return null;

  return (
    <MenuFrame
      title="Filters"
      onExit={() => setLowerRightActiveMode(undefined)}
      style={{
        gap: "1.2rem",
      }}
    >
      <ResidentialFilter />
    </MenuFrame>
  );
};

export default FilterOnshoreInput;
