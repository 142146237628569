import DrawToolsMenuV2 from "components/ControlPanels/DrawToolsMenuV2";
import { MapboxSyncEffects } from "components/Mapbox/MapboxSyncEffects";
import { useProjectElementsCrud } from "components/ProjectElements/useProjectElementsCrud";
import RightSideMapV2 from "components/RightSide/RightSideMapV2";
import { useBathymetry } from "hooks/bathymetry";
import { useSnapExportCableToChangedSubstations } from "hooks/useSnapExportCableToChangedSubstations";
import { useAtomValue, useSetAtom } from "jotai";
import ExistingTurbines, { AddExistingTurbines } from "layers/existingTurbines";
import React, { Suspense, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { currentParkBathymetryIdAtom } from "state/jotai/bathymetry";
import { featureMapFamily } from "state/jotai/features";
import { parksFamily } from "state/jotai/park";
import {
  selectedOnlyFeatureAtom,
  selectedProjectFeaturesAtom,
} from "state/jotai/selection";
import { PARK_PROPERTY_TYPE } from "../../constants/park";
import useNavigateToPark from "../../hooks/useNavigateToPark";
import CanvasLayer from "../../layers/canvasLayer";
import Filter from "../../layers/filter";
import GeneralMapLayers from "../../layers/GeneralMapLayers";
import Noise from "../../layers/noise";
import Wake from "../../layers/wake";
import { currentExternalLayerSelection } from "../../state/externalLayerSelection";
import { mapAtom } from "../../state/map";
import {
  hasParkSelector,
  parkIdAtom,
  projectIdAtomDef,
} from "../../state/pathParams";
import { projectVersionAtom } from "../../state/project";
import { CableEdit } from "../Cabling/Generate/CableEdit";
import { cableEditModeAtom } from "../Cabling/Generate/state";
import CanvasMultiSelectOption from "../CanvasSelectOption/CanvasMultiSelectOption";
import CanvasSingleSelectOption from "../CanvasSelectOption/CanvasSingleSelectOption";
import GenerationToolsMenuV2 from "../ControlPanels/GenerationToolsMenuV2";
import CoordinateOnMouse from "../CoordinateOnMouse/CoordinateOnMouse";
import DynamicSelectOption from "../DynamicSelectOption/DynamicSelectOption";
import {
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import { FeatureLayers } from "../Mapbox/ParkLayers";
import ScoresOnMouse from "../SiteLocator/SiteLocatorTooltip";
import { SelectOptionsContext } from "./SelectOptions";
import { customerProjectAtomFamily } from "state/timeline";
import { getPathPrefix } from "utils/utils";
import FilterOnshore from "layers/filterOnshore";
import { isOnshoreAtom } from "state/onshore";
import ProjectNotifications from "components/NotificationSystem/ProjectNotification";
import ReplaceModal from "components/NotificationSystem/ReplaceTurbine/ReplaceModal";
import OrgSettingsModal from "components/Organisation/OrgSettings/OrgSettingsModal";

const ParkDesignInner = () => {
  const parkIsSelected = useAtomValue(hasParkSelector);
  const isOnshore = useAtomValue(isOnshoreAtom);

  return (
    <>
      <OrgSettingsModal />
      <Suspense fallback={null}>
        <ParkDesignMap />
      </Suspense>
      <Suspense fallback={null}>
        <ProjectNotifications />
        <ReplaceModal />
      </Suspense>
      <Suspense fallback={null}>
        <RightSideMapV2 />
      </Suspense>
      <ScoresOnMouse />

      {parkIsSelected ? (
        <>
          <GenerationToolsMenuV2 />
        </>
      ) : (
        <>
          <DrawToolsMenuV2 />
          <CoordinateOnMouse />
        </>
      )}

      {!isOnshore && <Filter />}
      {isOnshore && <FilterOnshore />}
      <RedirectToSelectedPark />
    </>
  );
};

const RedirectToSelectedPark = ErrorBoundaryWrapper(
  () => {
    const firstCurrentSelection = useAtomValue(selectedOnlyFeatureAtom);
    const { navigateToPark } = useNavigateToPark();
    const parkId = useAtomValue(parkIdAtom);
    const projectFeatures = useAtomValue(
      featureMapFamily({
        branchId: undefined,
      }),
    );
    const selectedItem = projectFeatures.get(firstCurrentSelection?.id ?? "");

    useEffect(() => {
      if (
        selectedItem?.properties?.type === PARK_PROPERTY_TYPE &&
        firstCurrentSelection &&
        parkId !== firstCurrentSelection?.id
      ) {
        navigateToPark(firstCurrentSelection?.id);
      }
    }, [
      firstCurrentSelection,
      navigateToPark,
      parkId,
      selectedItem?.properties?.type,
    ]);

    return null;
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);

export const ParkDesignV2 = () => {
  return (
    <>
      <React.Suspense fallback={null}>
        <ParkDesignInner />
      </React.Suspense>
    </>
  );
};

function ClearParkFromUrl() {
  const { organisationId, projectId, branchId, parkId } = useParams();
  const parks = useAtomValue(
    parksFamily({
      branchId,
    }),
  );
  const version = useAtomValue(
    projectVersionAtom({
      projectId,
      branchId,
    }),
  );
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId ?? "",
    }),
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const skipCheck = !!version;

  useEffect(() => {
    // selected park does not exist (Dont clear if we are viewing a specific version with Feature version history)
    if (!skipCheck && parkId && !parks.some((p) => p.id === parkId)) {
      navigate(
        {
          pathname: `/${getPathPrefix(project)}/project/${organisationId}/${projectId}/${branchId}`,
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [
    branchId,
    organisationId,
    navigate,
    parkId,
    parks,
    projectId,
    searchParams,
    skipCheck,
    project,
  ]);
  return null;
}

const SelectOptions = () => {
  const selectedFeatures = useAtomValue(selectedProjectFeaturesAtom);
  const { update: updateFeatures } = useProjectElementsCrud();

  return (
    <SelectOptionsContext.Provider
      value={{
        updateFeatures,
      }}
    >
      {selectedFeatures.length === 1 && (
        <React.Suspense fallback={null}>
          <CanvasSingleSelectOption
            selectedProjectFeature={selectedFeatures[0]}
          />
        </React.Suspense>
      )}
      {selectedFeatures.length > 1 && (
        <React.Suspense fallback={null}>
          <CanvasMultiSelectOption selectedProjectFeatures={selectedFeatures} />
        </React.Suspense>
      )}
    </SelectOptionsContext.Provider>
  );
};

/**
 * Fetches bathymetry for the current park (if any), and sets {@link
 * currentBathymetryId}. This ensures that bathymetry for the currently
 * selected park is always available for selectors.
 */
function SyncCurrentParkBathymetry({ parkId }: { parkId: string }) {
  const projectId = useAtomValue(projectIdAtomDef);

  const setCurrentParkBathymetryIdAtom = useSetAtom(
    currentParkBathymetryIdAtom,
  );
  const [jbath] = useBathymetry({
    projectId,
    featureId: parkId,
    bufferKm: 2,
    branchId: undefined,
  });
  const jbathData = jbath.state === "hasData" ? jbath.data : undefined;

  useEffect(() => {
    if (jbathData) setCurrentParkBathymetryIdAtom(jbathData.id);
  }, [jbathData, setCurrentParkBathymetryIdAtom]);

  return null;
}

const ParkDesignMap = ErrorBoundaryWrapper(
  () => {
    const parkId = useAtomValue(parkIdAtom);
    const map = useAtomValue(mapAtom);

    const dynamicLayerSelection = useAtomValue(currentExternalLayerSelection);

    const cableEditMode = useAtomValue(cableEditModeAtom);

    useSnapExportCableToChangedSubstations();

    if (!map) return null;

    return (
      <>
        <ClearParkFromUrl />
        <Wake />
        <Noise />
        <CanvasLayer />
        <ExistingTurbines />
        <AddExistingTurbines map={map} />
        <MapboxSyncEffects map={map} />
        {parkId && (
          <Suspense fallback={null}>
            <SyncCurrentParkBathymetry parkId={parkId} />
          </Suspense>
        )}

        <FeatureLayers map={map} />
        <GeneralMapLayers />
        {dynamicLayerSelection.length > 0 && (
          <DynamicSelectOption selections={dynamicLayerSelection} />
        )}
        <SelectOptions />
        {cableEditMode && parkId && <CableEdit parkId={parkId} />}
      </>
    );
  },
  FatalErrorBoundaryWrapper,
  ScreamOnError,
);
