import { branchIdAtom, projectIdAtom } from "state/pathParams";
import styled from "styled-components";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { TurbineFeature } from "../../types/feature";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import LibraryIcon from "@icons/24/Library.svg?react";
import Tooltip from "../General/Tooltip";
import { isTurbine } from "../../utils/predicates";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import { trackCanvasOption } from "./MenuTracking";
import { Divider } from "components/General/Icons";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { useAtomValue } from "jotai";
import { simpleTurbineTypesWithLevelAtom } from "state/jotai/turbineType";
import { TURBINE_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useTurbineSettings";
import { useOpenComponentsConfig } from "./useOpenConfig";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import ComponentsIcon from "@icons/24/Components.svg?react";
import VindLogo from "@icons/24/VindLogoDark.svg?react";
import { SearchInput } from "components/General/Input";
import { SimpleTurbineTypeWithLevel } from "types/turbines";
import Fuse from "fuse.js";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;

const TypeSelector = ({
  selectedTurbines,
  editable,
}: {
  selectedTurbines: TurbineFeature[];
  editable: boolean;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";
  const { update: updateFeatures } = useProjectElementsCrud();
  const allTurbineTypes = useAtomValue(simpleTurbineTypesWithLevelAtom);
  const openNewTurbineConfig = useOpenComponentsConfig(
    projectId,
    TURBINE_MENU_ID,
  );
  const [search, setSearch] = useState("");
  const [filteredResources, setFilteredResources] = useState<
    Map<string, SimpleTurbineTypeWithLevel>
  >(new Map());

  const currentTurbineTypes = [
    ...new Set(
      selectedTurbines.map((t) => t.properties.turbineTypeId),
    ).values(),
  ];

  useEffect(() => {
    if (!search) {
      setFilteredResources(new Map(allTurbineTypes));
      return;
    }

    const fuse = new Fuse(Array.from(allTurbineTypes.values()), {
      keys: ["turbine.name"],
      includeScore: true,
      threshold: 0.3,
    });

    const results = fuse.search(search).map((result) => result.item);

    setFilteredResources(new Map(results.map((r) => [r.turbine.id, r])));
  }, [search, allTurbineTypes]);

  const onSelectItem = useCallback(
    (val: string) => {
      trackCanvasOption("change-turbine-type", {
        projectId,
        branchId,
      });
      const updatedFeatures = selectedTurbines.map((t) => ({
        ...t,
        properties: {
          ...t.properties,
          turbineTypeId: val,
        },
      }));

      updateFeatures({
        update: updatedFeatures,
      });
    },
    [branchId, projectId, selectedTurbines, updateFeatures],
  );

  const items = useMemo(
    () =>
      Array.from(filteredResources.values()).map((t) => ({
        level: t.level,
        value: t.turbine.id,
        name: t.turbine.name,
        icon:
          t.level === "standard" ? (
            <VindLogo />
          ) : t.level === "project" ? (
            <ProjectIcon />
          ) : (
            <LibraryIcon />
          ),
      })),
    [filteredResources],
  );

  const groupedItems = useMemo(() => {
    const projectItems = items.filter((ft) =>
      ["project", "team"].includes(ft.level),
    );
    const libraryItems = items.filter((ft) => ft.level === "library");
    const standardItems = items.filter((ft) => ft.level === "standard");

    return {
      project: projectItems,
      library: libraryItems,
      standard: standardItems,
    };
  }, [items]);
  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        buttonContainerStyle={{ backgroundColor: colors.surfacePrimary }}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          maxWidth: "20rem",
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentTurbineTypes.length === 1
              ? allTurbineTypes.get(currentTurbineTypes[0])?.turbine.name ??
                "..."
              : "..."}
          </div>
        }
      >
        <>
          <div style={{ padding: "1.2rem 1.2rem 0.8rem" }}>
            <SearchInput
              autoFocus={false}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => {
                setSearch("");
              }}
              placeholder={`Search turbine components`}
              style={{
                flexGrow: 1,
                width: "30rem",
              }}
            />
          </div>

          {groupedItems.library.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Library components</SubTitle>
              {groupedItems.library.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentTurbineTypes.length === 1 &&
                    currentTurbineTypes.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelectItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          {groupedItems.project.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Project specific components</SubTitle>
              {groupedItems.project.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentTurbineTypes.length === 1 &&
                    currentTurbineTypes.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelectItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          {groupedItems.standard.length > 0 && (
            <div style={{ maxWidth: "100%" }}>
              <SubTitle>Vind AI components</SubTitle>
              {groupedItems.standard.map((item) => (
                <MenuItem
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  selected={
                    currentTurbineTypes.length === 1 &&
                    currentTurbineTypes.includes(item.value)
                  }
                  key={item.value}
                  name={item.name}
                  onClick={() => onSelectItem(item.value)}
                  icon={item.icon}
                />
              ))}
            </div>
          )}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          ></div>
          <DropdownDivider />
          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewTurbineConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function TurbineTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedTurbineFeatures = useMemo(
    () => selectedProjectFeatures.filter(isTurbine),
    [selectedProjectFeatures],
  );

  const onlyTurbinesSelected =
    selectedTurbineFeatures.length === selectedProjectFeatures.length;

  if (selectedTurbineFeatures.length === 0 || !onlyTurbinesSelected)
    return null;

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>Turbine type</NamedTooltipWrapper>
          <TypeSelector
            selectedTurbines={selectedTurbineFeatures}
            editable={editable}
          />
        </NamedTooltipContainer>
      </Tooltip>
      <Divider />
    </Suspense>
  );
}
