import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { branchIdAtom } from "state/pathParams";
import { projectVersionAtom } from "./../state/project";
import { ABLY_CURSOR_POSITION } from "./../state/ably";
import { useCallback, useEffect } from "react";
import { liveCursorState } from "../components/LiveCursor/state";
import { loggedInUserIdAtom } from "../state/user";
import { MapMouseEvent } from "mapbox-gl";
import { getCurrentTokenUserId } from "../state/global";
import { useAtomCallback } from "jotai/utils";
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { useChannel } from "ably/react";
import { Message } from "ably";

const _LngLatLike = z
  .tuple([z.number(), z.number()])
  .or(z.object({ lng: z.number(), lat: z.number() }))
  .or(z.object({ lon: z.number(), lat: z.number() }));

let isThrottled = false;
export function useAblyCursorPosition(projectId: string) {
  const branchId = useAtomValue(branchIdAtom);
  const version = useAtomValue(projectVersionAtom({ projectId, branchId }));
  const currentUserId = useAtomValue(loggedInUserIdAtom);
  const map = useAtomValue(mapAtom);

  const onCursorPositionReceived = useAtomCallback(
    useCallback(async (_, set, message: Message) => {
      const match = message.name === ABLY_CURSOR_POSITION;
      if (!match) return;
      const { userId, position, source } = z
        .object({
          userId: z.string(),
          position: _LngLatLike.nullish(),
          source: z.object({
            projectId: z.string(),
            branchId: z.string(),
            version: z.number().nullish(),
          }),
        })
        .parse(message.data);

      if (userId === getCurrentTokenUserId()) return;

      set(
        liveCursorState({ ...source, version: source.version ?? undefined }),
        (prevPositions) => {
          const newPositions = new Map(prevPositions);
          if (position) {
            newPositions.set(userId, position);
          } else {
            newPositions.delete(userId);
          }
          return newPositions;
        },
      );
    }, []),
  );

  const { channel, publish, channelError, connectionError } = useChannel(
    {
      channelName: `${projectId}:all`,
      ablyId: projectId,
      onChannelError: () => {},
      onConnectionError: () => {},
    },
    onCursorPositionReceived,
  );

  const onMouseMove = useCallback(
    (e: MapMouseEvent) => {
      if (channelError || connectionError) return;

      if (!isThrottled) {
        isThrottled = true;
        const lngLat = Object.values(e.lngLat);

        publish(ABLY_CURSOR_POSITION, {
          userId: currentUserId,
          position: lngLat,
          source: { projectId, branchId, version },
        }).catch((error) => {
          console.debug("Error publishing cursor position:", error);
          Sentry.captureException(error, {
            tags: {
              channelName: channel.name,
              channelState: channel.state,
              messageType: "cursor_position",
            },
          });
        });

        setTimeout(() => {
          isThrottled = false;
        }, 120);
      }
    },
    [
      channelError,
      connectionError,
      publish,
      currentUserId,
      projectId,
      branchId,
      version,
      channel.name,
      channel.state,
    ],
  );

  useEffect(() => {
    if (!map) return;
    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [map, onMouseMove]);

  useEffect(() => {
    if (channelError || connectionError) return;
    if (!projectId || !branchId) return;

    return () => {
      // Clear cursor position on unmount
      publish({
        name: ABLY_CURSOR_POSITION,
        data: {
          userId: currentUserId,
          position: null,
          source: { projectId, branchId, version },
        },
      }).catch(console.error);
    };
  }, [
    branchId,
    channelError,
    connectionError,
    currentUserId,
    projectId,
    publish,
    version,
  ]);
}
