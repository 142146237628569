import styled from "styled-components";
import { Layer, ExternalLayer } from "../../../types/layers";
import { colors } from "../../../styles/colors";
import CloseIcon from "@icons/24/Close.svg?react";
import LayerPreviewImageV2 from "./LayerPreviewImageV2";
import LayerDataCleaningView from "../../LayerList/LayerDataCleaning/LayerDataCleaning";
import {
  isCustomLayer,
  isPrivateSource,
  LayerIconMapper,
} from "../../LayerList/utils";
import {
  TranslatedLayerName,
  TranslatedLayerOrSourceLinkAbstract,
} from "../../LayerList/LayerInformation/LayerInformation";
import { Deprecated } from "../../Deprecated/Deprecated";
import Button from "components/General/Button";
import { IconREMSize, typography } from "styles/typography";
import AddIcon from "@icons/24/Add.svg?react";
import { spaceSmall } from "styles/space";
import { useDataLayerCellProps } from "hooks/useDataLayerCellProps";
import { Column, Row } from "components/General/Layout";
import { Pill } from "./Pill";

const SideModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.surfacePrimary};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const Title = styled.h4`
  ${typography.h4}
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SourceName = styled.p`
  ${typography.caption}
`;

const SourceType = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const SourceTypeText = styled.p`
  ${typography.caption}
  margin: 0;
`;

const Description = styled.span`
  word-break: break-word;
  max-width: 100%;
`;

const StyledLayerDataCleaningView = styled(LayerDataCleaningView)`
  max-width: 100%;
  overflow-x: hidden;
`;

const ImageAndTitleContainer = styled(Column)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface DataLayerSideModalProps {
  layer: Layer;
  onClose: () => void;
}

const DataLayerSideModal = ({ layer, onClose }: DataLayerSideModalProps) => {
  const cellProps = useDataLayerCellProps();

  const source = layer.source;
  const sourceLink = isCustomLayer(layer) ? undefined : layer.sourceLink;
  const sourceName = cellProps.getSourceName(source);

  return (
    <SideModalContainer>
      <ContentWrapper>
        <CloseButton onClick={onClose}>
          <CloseIcon height={14} width={14} />
        </CloseButton>
        <Row>
          <LayerPreviewImageV2
            layer={layer}
            clickToEnlarge={true}
            style={{
              width: "10rem",
              height: "auto",
            }}
            withDataCleaning={true}
          />
          <ImageAndTitleContainer>
            <Title title={layer.name}>
              <TranslatedLayerName layer={layer} />
            </Title>
            <SourceName>{layer.source?.name}</SourceName>
            <SourceType>
              <IconREMSize height={1.5} width={1.5}>
                <LayerIconMapper layer={layer} />
              </IconREMSize>
              <SourceTypeText>{layer.sourceType}</SourceTypeText>
            </SourceType>
          </ImageAndTitleContainer>
        </Row>
        <Row style={{ justifyContent: "flex-end", width: "100%" }}>
          {cellProps.getIsAdded(layer.id) ? (
            <Button
              buttonType="text"
              text="Remove from map"
              style={{
                backgroundColor: colors.surfaceButtonSecondary,
                padding: spaceSmall,
              }}
              onClick={(e) => {
                e.stopPropagation();
                cellProps.removeLayer(layer.id);
              }}
            />
          ) : (
            <Button
              buttonType="text"
              text="Add to map"
              icon={<AddIcon />}
              style={{
                backgroundColor: colors.surfaceButtonSecondary,
                padding: spaceSmall,
              }}
              onClick={(e) => {
                e.stopPropagation();
                cellProps.addLayer(layer.id);
              }}
            />
          )}
        </Row>
        <ContentSection>
          {(layer as any)["deprecated"] != null && (
            <div>
              <Deprecated>
                <h5 style={{ margin: 0, color: colors.redAlert }}>
                  Deprecated
                </h5>
              </Deprecated>
              <Description style={{ color: colors.redAlert }}>
                {(layer as any)["deprecated"]}
              </Description>
            </div>
          )}
          {(layer as any)["abstract"] && (
            <div>
              <h5 style={{ marginTop: 0 }}>Description</h5>
              <Description>
                <TranslatedLayerOrSourceLinkAbstract
                  layerOrSourceLink={layer as ExternalLayer}
                />
              </Description>
            </div>
          )}
          {sourceLink?.abstract && (
            <div>
              <h5 style={{ marginTop: 0 }}>Source description</h5>
              <Description>
                <TranslatedLayerOrSourceLinkAbstract
                  layerOrSourceLink={sourceLink}
                />
              </Description>
            </div>
          )}
        </ContentSection>
        <PillContainer>
          {source?.countries?.map((country) => (
            <Pill key={country} filterType="country">
              {country}
            </Pill>
          ))}
          <Pill filterType="source">{sourceName}</Pill>
          {layer.tags?.map((tag) => (
            <Pill key={tag} filterType="tags">
              {tag}
            </Pill>
          ))}
          {source && isPrivateSource(source) && sourceLink && (
            <Pill filterType="source">{sourceLink.url}</Pill>
          )}
        </PillContainer>
        {!isCustomLayer(layer) && (
          <StyledLayerDataCleaningView inputLayer={layer} />
        )}
      </ContentWrapper>
    </SideModalContainer>
  );
};

export default DataLayerSideModal;
