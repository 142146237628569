import { atomFamily } from "utils/jotai";
import { atom } from "jotai";

import { isLibraryFoundationReferenceCost } from "services/costService";
import { AnalysisStoppedTypes, getStoppedReason } from "analysis/warnings";
import {
  getConfiguration,
  getConfiguration as getFinanceConfiguration,
} from "finance/inputs";
import { FinanceId } from "./types";
import { listProcurementCostForLibraryFoundationsOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { getProjectId } from "analysis/inputs";

const getFinancialArgsStoppedReason = atomFamily((id: FinanceId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    const configuration = await get(getFinanceConfiguration(id));
    if (
      isLibraryFoundationReferenceCost(configuration.capex.fixed.foundations)
    ) {
      if (await get(getHasProcurementTableReferenceMissingValue(id)))
        return AnalysisStoppedTypes.ProcurementTableDontExist;
    }
  }),
);

export const getFinancialStoppedReason = atomFamily((id: FinanceId) =>
  atom<Promise<AnalysisStoppedTypes | undefined>>(async (get) => {
    const argsStopped = await get(getFinancialArgsStoppedReason(id));
    if (argsStopped !== undefined) return argsStopped;

    const analysisStopped = await get(getStoppedReason(id));
    if (analysisStopped !== undefined) return analysisStopped;
    return undefined;
  }),
);

const getHasProcurementTableReferenceMissingValue = atomFamily(
  (id: FinanceId) =>
    atom<Promise<boolean>>(async (get) => {
      const configuration = await get(getConfiguration(id));
      const projectId = await get(getProjectId(id));
      const foundationProcurementCostTables = await get(
        listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
      );
      const foundations = configuration.capex.fixed.foundations;
      if (isLibraryFoundationReferenceCost(foundations)) {
        const {
          jacketCostReference,
          monopileCostReference,
          floatingCostReference,
        } = foundations;
        const jacketCostTable = foundationProcurementCostTables.find(
          (table) => table.id === jacketCostReference,
        );
        const monopileCostTable = foundationProcurementCostTables.find(
          (table) => table.id === monopileCostReference,
        );
        const floatingCostTable = foundationProcurementCostTables.find(
          (table) => table.id === floatingCostReference,
        );
        return !jacketCostTable || !monopileCostTable || !floatingCostTable;
      }
      return false;
    }),
);
