import { atom } from "jotai";
import { Style } from "../types";
import { isOnshoreAtom } from "state/onshore";
import { Color, Gradient } from "lib/colors";
import { colors } from "styles/colors";
import { atomFamily } from "utils/jotai";
import {
  getAEPPerTurbine,
  getAnalysisResponse,
  getAverageSpeedPerTurbine,
  getTotalWakeLossPerTurbine,
} from "analysis/output";
import { currentSelectionProduction } from "components/ProductionV2/Triggers";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { parkIdAtom } from "state/pathParams";
import { isNever } from "utils/utils";
import { scream } from "utils/sentry";
import { z } from "zod";
import { Coloring } from "../types";
import { CirclePaint } from "mapbox-gl";
import { colorValuesSelectorFamily } from "../state";
import { defaultPointCircleRadius } from "components/MapFeatures/expressionUtils";
import {
  editmodePropertyName,
  generateFoundationWarningPropertyName,
  ghostPropertyName,
} from "components/Mapbox/constants";
import { READ, caseexpr } from "components/Mapbox/defaults";
import { lockedPropertyName } from "@constants/canvas";
import { COLORVALUE } from "../constants";

export const _TurbineColorKey = z.enum([
  "single",
  "wake-loss",
  "avg-wind-speed",
  "aep",
]);
export const _TurbineLabel = z.enum([
  "name",
  "wake-loss",
  "avg-wind-speed",
  "aep",
]);
type TurbineColorKey = z.infer<typeof _TurbineColorKey>;
export const turbineColorKeys = _TurbineColorKey.options;
type TurbineLabel = z.infer<typeof _TurbineLabel>;
export const turbineLabels = _TurbineLabel.options;
export type StyleTurbine = {
  feature: "turbines";
} & Coloring<TurbineColorKey> & { label?: TurbineLabel };

export const defaultTurbineStyle = atom<Style>((get) => {
  const onshore = get(isOnshoreAtom);
  return {
    id: "default-style-turbines",
    defaultMarker: true,
    feature: "turbines",
    source: "single",
    type: "single",
    color: Color.fromHex(onshore ? colors.onElTurbine : colors.turbine),
    label: "name",
    name: "Single color",
    createdAt: 0,
  };
});

export const defaultTurbineStyleWakeLoss = atom<Style>(() => {
  return {
    id: "default-style-turbines-wake-loss",
    defaultMarker: true,
    feature: "turbines",
    source: "wake-loss",
    type: "gradient",
    gradient: Gradient.Brewer("YlOrRd", 9).balance(0.05, 0.15),
    label: "wake-loss",
    createdAt: 0,
    name: "Wake loss",
    labelSize: "small",
  };
});

export const defaultTurbineStyleWindSpeed = atom<Style>((get) => {
  const onshore = get(isOnshoreAtom);
  const limits = onshore ? [5, 10] : [7, 13];
  return {
    id: "default-style-turbines-wind-speed",
    defaultMarker: true,
    feature: "turbines",
    source: "avg-wind-speed",
    type: "gradient",
    gradient: Gradient.WindSpeed().balance(limits[0], limits[1]),
    label: "avg-wind-speed",
    createdAt: 0,
    name: "Wind speed",
    labelSize: "small",
  };
});

export const turbineValuesSelectorFamily = atomFamily(
  (colorKey: TurbineColorKey | TurbineLabel) =>
    atom(async (get) => {
      if (colorKey === "avg-wind-speed") {
        const a = await get(getAnalysisResponse(currentSelectionProduction));
        if (a.status === "failed" || a.status === "stopped")
          throw new Error("Analysis stopped or failed");
        const speeds = await get(
          getAverageSpeedPerTurbine(currentSelectionProduction),
        );
        return new Map(speeds.map((t) => [t.turbine.id, t.value]));
      } else if (colorKey === "wake-loss") {
        const avg = await get(
          getTotalWakeLossPerTurbine(currentSelectionProduction),
        );
        return new Map(avg.map((t) => [t.turbine.id, t.value]));
      } else if (colorKey === "single" || colorKey === "name") {
        const parkId = get(parkIdAtom);
        if (!parkId) return undefined;
        const turbines = await get(
          turbinesInParkFamily({ parkId: parkId ?? "", branchId: undefined }),
        );
        return new Map(turbines.map((c) => [c.id, 0]));
      } else if (colorKey === "aep") {
        const aep = await get(getAEPPerTurbine(currentSelectionProduction));
        return new Map(aep.map((t) => [t.turbine.id, t.value]));
      }
      isNever(colorKey);
      throw scream(new Error(`Illegal \`valueName\`: "${colorKey}"`));
    }),
);

export const turbineStyleAtom = atom<
  Promise<{
    circle: CirclePaint;
  }>
>(async (get) => {
  const onshore = get(isOnshoreAtom);
  const { color } = await get(colorValuesSelectorFamily("turbines"));

  return {
    circle: {
      "circle-radius": defaultPointCircleRadius,
      "circle-color": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        colors.yellow,
        [
          "boolean",
          ["feature-state", generateFoundationWarningPropertyName],
          false,
        ],
        colors.orange,
        [
          "boolean",
          ["feature-state", "overlap"],
          ["feature-state", "outside"],
          false,
        ],
        colors.red500,
        caseexpr({
          fallback: [
            "case",
            ["!=", ["get", COLORVALUE], null],
            color,
            onshore ? colors.onElTurbine : colors.turbine,
          ],
        }),
      ],
      "circle-opacity": [
        "case",
        ["==", ["get", ghostPropertyName], true],
        0.2,
        ["==", ["get", editmodePropertyName], true],
        1.0,
        ["==", ["get", lockedPropertyName], true],
        0.5,
        caseexpr({
          fallback: 0.3,
          hover: 1,
          selected: 1,
          active: 1,
        }),
      ],
      "circle-stroke-color": [
        "case",
        ["==", ["get", editmodePropertyName], true],
        colors.lightText,
        ["==", ["get", lockedPropertyName], true],
        colors.lockedFeatureOutline,
        caseexpr({
          selected: onshore ? colors.onElTurbineBorderSel : colors.white,
          hover: onshore ? colors.onElTurbineBorderHov : colors.turbine,
          state: { borderColor: READ },
          fallback: colors.white,
        }),
      ],
      // prettier-ignore
      "circle-stroke-width": [
      "case",
      ["==", ["get", editmodePropertyName], true], 3,
      ["==", ["get", lockedPropertyName], true], 2,
      ["!=", ["feature-state", "borderColor"], null], 2.0,
      caseexpr({
        hover: 2,
        selected: 2,
        fallback: 0,
      })
    ],
    },
  };
});
