import debounce from "debounce-promise";
import { PROJECT_DATA_API_VERSION } from "../../../services/projectDataAPIService";
import {
  addTokenToOptions,
  fetchSchemaWithToken,
} from "../../../services/utils";
import { LayerSettings, _LayerSettings } from "./types";

export const putLayerSetting = async (
  nodeId: string,
  update: LayerSettings[],
) =>
  fetchSchemaWithToken(
    _LayerSettings.array(),
    `/api/layer/${nodeId}`,
    addTokenToOptions({
      method: "put",
      body: JSON.stringify(update),
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
export const putLayerSettingDebounced = debounce(putLayerSetting, 500);

export const delLayerSetting = async (nodeId: string, layerIds: string[]) =>
  fetchSchemaWithToken(
    _LayerSettings.array(),
    `/api/layer/${nodeId}`,
    addTokenToOptions({
      method: "delete",
      body: JSON.stringify(layerIds),
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
export const delLayerSettingDebounced = debounce(delLayerSetting, 500);

export const listLayerSettings = (nodeId: string, version?: number) =>
  fetchSchemaWithToken(
    _LayerSettings.array(),
    `/api/layer/${nodeId}${version ? `?version=${version}` : ""}`,
    addTokenToOptions({
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }),
  );
