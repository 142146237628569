import { Anchor } from "components/General/Anchor";
import { Subtitle } from "components/General/GeneralSideModals.style";
import { IconBtn } from "components/General/Icons";
import { InputDimensioned } from "components/General/Input";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import {
  TitleContainer,
  HorizontalLine,
  SecondaryText,
} from "components/SettingsV2/Shared/styles";
import { CableType, ExportSystemType } from "services/cableTypeService";
import { colors } from "styles/colors";
import { between } from "utils/validations";
import CableTemplatesModal from "./CableTemplatesModal";
import {
  ChangelogCategory,
  GetChangeLogValue,
  InputChangelogInfo,
} from "components/InputChangelog/types";
import { Row } from "components/General/Layout";
import { useRef, useState } from "react";
import CableIcon from "@icons/24/Cabling-2.svg?react";
import { Label } from "components/General/Form";

interface ElectricalPropertiesProps {
  editedCable: CableType;
  originalCable: CableType;
  setCable: (cable: CableType) => void;
  disabled: boolean;
  nodeId: string;
  changelogCategory: ChangelogCategory;
  currentDefaultCables: Record<string, Partial<CableType>>;
  onSelectCableFromTemplate: (props: CableType) => void;
  getChangelogInfo: (
    id: string,
    nodeId: string,
    field: string,
    category: ChangelogCategory,
    getValue?: GetChangeLogValue,
  ) => InputChangelogInfo;
}

const ElectricalProperties = ({
  editedCable,
  originalCable,
  setCable,
  disabled,
  nodeId,
  changelogCategory,
  currentDefaultCables,
  onSelectCableFromTemplate,
  getChangelogInfo,
}: ElectricalPropertiesProps) => {
  const [isCableLibraryModalOpen, setIsCableLibraryModalOpen] = useState(false);
  const cableButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <TitleContainer>
        <Subtitle>Electrical properties</Subtitle>
        <HorizontalLine />
      </TitleContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2.4rem",
        }}
      >
        <Row alignCenter>
          <SecondaryText>Use Vind AI cable templates</SecondaryText>
          <IconBtn
            ref={cableButtonRef}
            size={"1.4rem"}
            backgroundColor={colors.surfaceButtonSecondary}
            hoverBackgroundColor={colors.indigo100}
            onClick={() => setIsCableLibraryModalOpen(!isCableLibraryModalOpen)}
          >
            <CableIcon />
          </IconBtn>
        </Row>

        {isCableLibraryModalOpen && (
          <Anchor baseRef={cableButtonRef} floatPlace="topRight">
            <CableTemplatesModal
              onClose={() => setIsCableLibraryModalOpen(false)}
              defaultCables={currentDefaultCables}
              onSelectCable={onSelectCableFromTemplate}
              disabled={disabled}
              isExportCable={true}
              isHVDC={editedCable.exportCableType === ExportSystemType.HVDC}
            />
          </Anchor>
        )}
      </div>

      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <Row>
            <SecondaryText>Resistance</SecondaryText>
            <HelpTooltip
              size={12}
              text={`Conductor ${editedCable.exportCableType === ExportSystemType.HVDC ? "DC" : "AC"} resistance at 20 degrees.`}
            />
          </Row>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            value={editedCable.resistance}
            initialValue={editedCable.resistance}
            validate={between(0.001, 1)}
            step={0.001}
            validationMessage={`Must be between 0.001 and 1`}
            unit={"ohm per km"}
            changelogInfo={getChangelogInfo(
              editedCable.id,
              nodeId,
              "resistance",
              changelogCategory,
            )}
            onChange={(e) => {
              setCable({
                ...editedCable,
                resistance: e,
              });
            }}
          />
        </Label>
        {editedCable.exportCableType !== ExportSystemType.HVDC && (
          <>
            <Label>
              <SecondaryText>Reactance</SecondaryText>
              <InputDimensioned
                disabled={disabled}
                value={editedCable.reactance ?? undefined}
                initialValue={originalCable.reactance ?? undefined}
                validate={between(0.001, 1)}
                validationMessage={`Must be between 0.001 and 1`}
                unit={"ohm per km"}
                step={0.001}
                style={{
                  width: "20rem",
                }}
                changelogInfo={getChangelogInfo(
                  editedCable.id,
                  nodeId,
                  "reactance",
                  changelogCategory,
                )}
                onChange={(e) => {
                  setCable({
                    ...editedCable,
                    reactance: e,
                  });
                }}
              />
            </Label>
            <Label>
              <SecondaryText>Capacitance</SecondaryText>
              <InputDimensioned
                style={{
                  width: "20rem",
                }}
                disabled={disabled}
                value={editedCable.capacitance ?? undefined}
                initialValue={originalCable.capacitance ?? undefined}
                validate={between(1, 1000)}
                validationMessage={`Must be between 1 and 1000`}
                unit={"nF per km"}
                changelogInfo={getChangelogInfo(
                  editedCable.id,
                  nodeId,
                  "capacitance",
                  changelogCategory,
                )}
                onChange={(e) => {
                  setCable({
                    ...editedCable,
                    capacitance: e,
                  });
                }}
              />
            </Label>
            <Label>
              <SecondaryText>Ampacity</SecondaryText>
              <InputDimensioned
                style={{
                  width: "20rem",
                }}
                disabled={disabled}
                value={editedCable.ampacity ?? undefined}
                initialValue={originalCable.ampacity ?? undefined}
                validate={between(0.1, 10)}
                validationMessage={`Must be between 0.1 and 10`}
                unit={"kilo Ampere"}
                step={0.01}
                changelogInfo={getChangelogInfo(
                  editedCable.id,
                  nodeId,
                  "ampacity",
                  changelogCategory,
                )}
                onChange={(e) => {
                  setCable({
                    ...editedCable,
                    ampacity: e,
                  });
                }}
              />
            </Label>
          </>
        )}
      </div>
    </>
  );
};

export default ElectricalProperties;
