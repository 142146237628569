import { Column } from "components/General/Layout";
import { useMemo } from "react";
import { partition } from "utils/utils";
import { BranchInfo, ProjectTitle } from "./common";
import {
  LimitAccessHeader,
  LimitAccessSecondaryText,
  LimitAccessText,
  LimitedAccessContainer,
} from "./style";
import { IconREMSize } from "styles/typography";
import WarningRoundIcon from "@icons/24/WarningRound.svg?react";
import { useUserAccessState } from "components/Projects/useUserAccessState";

export function DetailedProjectAndBranchUsage({
  projectsWithBranches,
}: {
  projectsWithBranches: [projectId: string, branchIds: string[]][];
}) {
  const { data: userAllNodesAccess } = useUserAccessState();

  const projectAccess = useMemo(() => {
    if (!userAllNodesAccess || !userAllNodesAccess.node_access) return [];
    return Object.keys(userAllNodesAccess.node_access);
  }, [userAllNodesAccess]);

  const [projectIdsWithAccess, projectIdsWithoutAccess] = useMemo(() => {
    return partition(projectsWithBranches, ([projectId, _]) =>
      projectAccess.includes(projectId),
    );
  }, [projectAccess, projectsWithBranches]);

  return (
    <>
      {projectsWithBranches.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            {projectIdsWithAccess.map(([projectId, branches]) => (
              <Column
                key={projectId}
                style={{
                  gap: 0,
                }}
              >
                <ProjectTitle projectId={projectId} />
                {branches.map((branch) => (
                  <BranchInfo
                    key={branch}
                    usage={{ projectId, branchId: branch }}
                  />
                ))}
              </Column>
            ))}
            {projectIdsWithoutAccess.length > 0 && (
              <div>
                <Column
                  key={"no_access"}
                  style={{
                    gap: 0,
                  }}
                >
                  <LimitedAccessContainer>
                    <IconREMSize height={1.4} width={1.4}>
                      <WarningRoundIcon />
                    </IconREMSize>
                    <LimitAccessHeader>Limited access to</LimitAccessHeader>
                  </LimitedAccessContainer>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.4rem",
                    }}
                  >
                    <LimitAccessText>
                      {projectIdsWithoutAccess.length} projects
                    </LimitAccessText>
                    <LimitAccessSecondaryText>
                      {
                        projectIdsWithoutAccess.flatMap(
                          ([_, branches]) => branches,
                        ).length
                      }{" "}
                      branches
                    </LimitAccessSecondaryText>
                  </div>
                </Column>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
