import * as THREE from "three";

export const defaultBladeMaterial = () =>
  new THREE.MeshLambertMaterial({
    color: 0xffffff,
  });

export const defaultTowerMaterial = () =>
  new THREE.MeshLambertMaterial({
    color: 0xf4f4f4,
  });

export const defaultNacelleMaterial = () =>
  new THREE.MeshLambertMaterial({
    color: 0xeeeeee,
  });

export const defaultClumpWeightMaterial = () =>
  new THREE.MeshBasicMaterial({ color: 0x5a5a5a });

export const defaultBuoyMaterial = () =>
  new THREE.MeshBasicMaterial({ color: 0xffff00 });

export const defaultHemisphereLight = () =>
  new THREE.HemisphereLight(0xeeeeff, 0x404060, 0.5);

export const defaultAmbientLight = () => new THREE.AmbientLight(0xffffff, 0.5);
