import { aset, useJotaiCallback } from "utils/jotai";
import { OrganisationAccessRole } from "../../../types/user";
import { leaveOrg, removeUserFromOrg, updateOrgRole } from "../service";
import { usersInOrganisationState } from "../state";
import { useState } from "react";

export const useOrgMemberCrud = (organisationId: string | undefined) => {
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);
  const update = useJotaiCallback(
    async (get, set, userId: string, role: OrganisationAccessRole) => {
      if (!organisationId) return;
      setIsUpdatingRole(true);
      const preUpdate = await get(usersInOrganisationState(organisationId));
      aset(get, set, usersInOrganisationState(organisationId), (cur) =>
        cur.map((user) =>
          user.user_id === userId
            ? {
                ...user,
                org_role: role,
                org_access: role,
              }
            : user,
        ),
      );
      return updateOrgRole(organisationId, userId, role)
        .then()
        .catch((e) => {
          aset(
            get,
            set,
            usersInOrganisationState(organisationId),
            () => preUpdate,
          );
          throw e;
        })
        .finally(() => {
          console.log(`done update`);
          setIsUpdatingRole(false);
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, userId: string) => {
      if (!organisationId) return;
      const preUpdate = await get(usersInOrganisationState(organisationId));
      aset(get, set, usersInOrganisationState(organisationId), (cur) =>
        cur.filter((user) => user.user_id !== userId),
      );
      return removeUserFromOrg(organisationId, userId)
        .then()
        .catch((e) => {
          aset(
            get,
            set,
            usersInOrganisationState(organisationId),
            () => preUpdate,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const leaveOrganisation = useJotaiCallback(
    async (get, set) => {
      if (!organisationId) return;
      aset(get, set, usersInOrganisationState(organisationId), (cur) =>
        cur.filter((user) => user.user_id !== user.user_id),
      );
      await leaveOrg(organisationId);
      window.location.href = window.location.origin;
    },
    [organisationId],
  );
  return {
    update,
    remove,
    leaveOrganisation,
    isUpdatingRole,
  };
};
