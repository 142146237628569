import { useAtomValue, useSetAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import {
  ExternalDataSourceLinkLayerWithSourceArcgis,
  ExternalDataSourceLinkLayerWithSourceWFS,
  Layer,
  LayerType,
  SourceTypesLayer,
} from "../../../types/layers";
import {
  layerSettingSelectorFamily,
  layerVisibleAtomFamily,
  selectedSourceNameAtom,
} from "../LayerSettings/state";
import BinIcon from "@icons/24/Bin.svg?react";
import { selectedLayerIdsWithCollectionIdAtom } from "../state";
import { useForceReloadCustomLayers } from "../../../state/customLayers";
import { toastMessagesAtom } from "../../../state/toast";
import { useLayerSettingsCrud } from "../LayerSettings/useLayerSettingsCrud";
import {
  deleteCustomLayer,
  editCustomLayerName,
} from "../../../services/customLayersAPIService";
import {
  dateToDayMonthShortYearFormat,
  platformCtrlOrCommand,
} from "../../../utils/utils";
import { scream } from "../../../utils/sentry";
import { LineIconWrapper } from "../LineIconWrapper";
import {
  getSourceTitle,
  isCustomLayer,
  isCustomUploadedLayer,
  LayerColorMarkerMapper,
  LayerIconMapper,
} from "../utils";
import { EditableTextInternalState } from "../../General/EditableText";
import Spinner from "@icons/spinner/Spinner";
import {
  HideIfNotHoverOrVisible,
  LayerItemBottomRow,
  LayerItemTopRow,
} from "../LayerList.style";
import Toggle, { ToggleSize } from "../../General/Toggle";
import { Ui13RegularOverflowDiv } from "../../ProjectElementsV2/ProjectElementsV2.style";
import { dynamicVectorLayerFeaturesAtomFamily } from "../../../state/layer";
import Tooltip, { WithTooltip } from "../../General/Tooltip";
import { isWfsLayer } from "../../../state/wfs";
import { isArcgisLayer } from "../../../state/arcgisRestAPI";
import { Deprecated } from "../../Deprecated/Deprecated";
import { TranslatedLayerName } from "../LayerInformation/LayerInformation";
import { VectorError } from "components/VectorError/VectorError";
import { spacing4 } from "styles/space";
import { VerticalDivider } from "components/General/VerticalDivider";
import { IconREMSize } from "styles/typography";
import { useToast } from "hooks/useToast";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { ZoomTooLowWarning } from "components/ZoomTooLow/ZoomTooLow";
import { getLayerTypeTooltipText } from "utils/layer";

const LayerItemWithoutCollectionWrapper = styled.div<{
  isSelected: boolean;
  isDeprecated: boolean;
  editable: boolean;
  hasError: boolean;
  depth: number;
  visible: boolean;
  isDeletedFromSource: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  user-select: none;
  box-sizing: border-box;
  ${({ depth }) => {
    if (depth === 0) {
      return `padding: 0 ${spacing4} 0 ${spacing4};`;
    }

    return `padding: 0 ${spacing4} 0 calc(${depth * 3.3} * ${spacing4});`;
  }}

  border-radius: 4px;

  ${({ isDeletedFromSource }) =>
    isDeletedFromSource &&
    `
    width: 100%;
    background-color: ${colors.surfaceDisabled};
    color: ${colors.textDisabled};
  `}

  ${({ hasError }) =>
    hasError &&
    `
    background-color: ${colors.surfaceWarning};

    &&:hover {
      background-color: ${colors.orange100};
    }
  `}

  ${({ isDeprecated }) =>
    isDeprecated &&
    `
    background-color: ${colors.surfaceError};

    &&:hover {
      background-color: ${colors.red100};
    }
  `}

  ${({ editable }) =>
    editable &&
    `
    cursor: pointer;
  `}

  &:hover {
    background-color: ${colors.surfaceHover};
  }

  ${({ isSelected }) =>
    isSelected
      ? `
    background-color: ${colors.selected};
    &:hover {
      background-color: #CCEAF5;
    }
  `
      : ``}

  ${({ visible }) =>
    !visible &&
    `
    ${HideIfNotHoverOrVisible} {
      visibility: hidden;
    }

    &:hover {
      ${HideIfNotHoverOrVisible} {
        visibility: inherit;
      }
    }
  `}
`;

const VectorLoaderInner = ({
  layer,
}: {
  layer:
    | ExternalDataSourceLinkLayerWithSourceWFS
    | ExternalDataSourceLinkLayerWithSourceArcgis;
}) => {
  const dynamicVectorLayerFeatures = useAtomValue(
    dynamicVectorLayerFeaturesAtomFamily(layer.id),
  );
  if (!dynamicVectorLayerFeatures.isLoading) return null;
  return (
    <Spinner
      size={"1rem"}
      style={{
        margin: "0 1rem",
      }}
    />
  );
};

const VectorLoader = ({ layer }: { layer: Layer }) => {
  if (!isWfsLayer(layer) && !isArcgisLayer(layer)) return null;

  return <VectorLoaderInner layer={layer} />;
};

const SourceNameComponent = ({ layer }: { layer: Layer }) => {
  const { language: selectedSourceName } = useAtomValue(selectedSourceNameAtom);

  const title = getSourceTitle(layer, selectedSourceName);

  return <>{title}</>;
};

const MaybeWrapWithDateDeletedTooltip = ({
  dateDeleted,
  children,
}: {
  dateDeleted: string | null | undefined;
} & React.PropsWithChildren) => {
  if (dateDeleted) {
    return (
      <Tooltip
        text={`This layer has been deleted by the data provider ${dateToDayMonthShortYearFormat(new Date(dateDeleted))}`}
        innerDivStyle={{
          width: "100%",
        }}
      >
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};

const LayerItem = ({
  layer,
  datasetLayerId,
  editable,
  depth,
  nameEditable,
  onToggleMultiSelect,
  onShiftSelect,
  isSelected,
  dotMenu,
  onContextMenu,
  collectionId,
}: {
  layer: Layer;
  datasetLayerId?: string;
  editable: boolean;
  depth: number;
  nameEditable: boolean;
  isSelected: boolean;
  onToggleMultiSelect: (
    newLayerId: string,
    newCollectionId: string | undefined,
  ) => void;
  onShiftSelect: (
    newLayerId: string,
    newCollectionId: string | undefined,
  ) => void;
  onContextMenu: React.MouseEventHandler<HTMLDivElement>;
  collectionId?: string;
  dotMenu: React.ReactNode;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const layerSettings = useAtomValue(
    layerSettingSelectorFamily({
      projectId,
      layerId: layer.id,
    }),
  );
  const setSelectedLayerIdsWithCollection = useSetAtom(
    selectedLayerIdsWithCollectionIdAtom,
  );

  const dynamicVectorLayerFeatures = useAtomValue(
    dynamicVectorLayerFeaturesAtomFamily(layer.id),
  );
  const isArcgisRasterLayer =
    layer.type === LayerType.Raster &&
    layer.sourceType === SourceTypesLayer.arcgis;

  const isDeprecated = !isCustomLayer(layer) && layer.deprecated != null;

  const forceReloadCustomLayers = useForceReloadCustomLayers();
  const setToastMessages = useSetAtom(toastMessagesAtom);
  const { error: showError } = useToast();
  const [isLoadingLayer, setIsLoadingLayer] = useState<boolean>(false);
  const { del: delLayerSettings } = useLayerSettingsCrud();
  const setLayerVisible = useSetAtom(
    layerVisibleAtomFamily({
      projectId,
      layerId: layer.id,
    }),
  );

  const layerVisible = layerSettings.visible ?? layerSettings.standard;

  const onMultiSelect = useCallback(() => {
    onToggleMultiSelect(layer.id, collectionId);
  }, [collectionId, layer.id, onToggleMultiSelect]);

  const layerName =
    layerSettings["alias"] ?? (layer as any)["alias"] ?? layer.name;

  const onChangeName = useCallback(
    async (newName: string) => {
      const trimmedName = newName.trim();
      if (!projectId || trimmedName === "") {
        return;
      }

      setIsLoadingLayer(true);

      try {
        await editCustomLayerName(projectId, layer.id, trimmedName);
      } catch (error) {
        scream("Could not change name of layer", {
          error,
          trimmedName,
        });
        setToastMessages((tm) => [
          ...tm,
          {
            text: "Could not change name of layer, please try again.",
            timeout: 5000,
            type: "error",
          },
        ]);
      } finally {
        setIsLoadingLayer(false);
        forceReloadCustomLayers();
      }
    },
    [projectId, layer.id, setToastMessages, forceReloadCustomLayers],
  );

  const removeLayer = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!projectId) return;
      if (isCustomUploadedLayer(layer)) {
        setIsLoadingLayer(true);
        try {
          await deleteCustomLayer(projectId, layer.id);
        } catch (error) {
          scream("Could not delete custom layer", {
            error,
          });
          showError("Could not delete layer, try again.");
        } finally {
          setIsLoadingLayer(false);
          forceReloadCustomLayers();
        }
      } else {
        return delLayerSettings([layerSettings.id]);
      }
    },
    [
      projectId,
      layer,
      forceReloadCustomLayers,
      delLayerSettings,
      layerSettings.id,
      showError,
    ],
  );

  const onLayerItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (e.shiftKey) {
        if (editable) {
          onShiftSelect(layer.id, collectionId);
        }
      } else if (platformCtrlOrCommand(e)) {
        if (editable) {
          onMultiSelect();
        }
      } else {
        //Deselect if already selected, otherwise select
        setSelectedLayerIdsWithCollection((curr) => {
          if (
            curr.length === 1 &&
            curr[0].layerId === layer.id &&
            curr[0].collectionId === collectionId
          ) {
            return [];
          }
          return [
            {
              layerId: layer.id,
              collectionId: collectionId,
            },
          ];
        });
      }
    },
    [
      collectionId,
      editable,
      layer.id,
      onMultiSelect,
      onShiftSelect,
      setSelectedLayerIdsWithCollection,
    ],
  );

  return (
    <MaybeWrapWithDateDeletedTooltip dateDeleted={layer.dateDeleted}>
      <LayerItemWithoutCollectionWrapper
        data-layer-id={datasetLayerId}
        isSelected={isSelected}
        isDeletedFromSource={Boolean(layer.dateDeleted)}
        isDeprecated={isDeprecated}
        hasError={Boolean(dynamicVectorLayerFeatures.hasError)}
        editable={editable}
        depth={depth}
        visible={layerVisible}
        title={layer.name}
        onClick={onLayerItemClick}
        onContextMenu={onContextMenu}
      >
        <React.Suspense
          fallback={
            <SkeletonBlock
              style={{
                width: "1rem",
                height: `4.8rem`,
                borderRadius: "4px",
                flexShrink: 0,
                marginRight: spacing4,
              }}
            />
          }
        >
          <LayerColorMarkerMapper layer={layer} settings={layerSettings} />
        </React.Suspense>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <LayerItemTopRow>
            <EditableTextInternalState
              title={layerName}
              value={layerName}
              disabled={!editable || !nameEditable}
              onEnter={onChangeName}
              style={{
                flex: 1,
                margin: 0,
                minWidth: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap",
                paddingLeft: 0,
              }}
              textContainerStyle={{
                flex: 1,
              }}
            >
              <Deprecated isDeprecated={isDeprecated}>
                <VectorError layer={layer} />
                <Ui13RegularOverflowDiv
                  title={layerName}
                  style={{
                    color: isSelected ? colors.textSelected : undefined,
                    fontWeight: isSelected ? 600 : undefined,
                  }}
                >
                  <TranslatedLayerName layer={layer} />
                </Ui13RegularOverflowDiv>
              </Deprecated>
              {isArcgisRasterLayer && layerVisible && <ZoomTooLowWarning />}
            </EditableTextInternalState>
            {layerVisible && <VectorLoader layer={layer} />}

            {isLoadingLayer ? (
              <Spinner
                size="1.5rem"
                style={{
                  margin: "0 1rem",
                }}
              />
            ) : (
              <>
                <HideIfNotHoverOrVisible>{dotMenu}</HideIfNotHoverOrVisible>
                {!Boolean(layer.dateDeleted) && (
                  <Toggle
                    size={ToggleSize.SMALL}
                    checked={layerVisible}
                    onChange={() => {
                      if (!Boolean(layer.dateDeleted)) {
                        setLayerVisible(Promise.resolve(!layerVisible));
                      }
                    }}
                    disabled={Boolean(layer.dateDeleted)}
                    title="Toggle layer visibility"
                  />
                )}

                {Boolean(layer.dateDeleted) && (
                  <div
                    style={{
                      width: "32px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconREMSize height={1.6} width={1.6}>
                      <BinIcon
                        onClick={removeLayer}
                        title="Remove deleted layer"
                      />
                    </IconREMSize>
                  </div>
                )}
              </>
            )}
          </LayerItemTopRow>
          <LayerItemBottomRow>
            <WithTooltip text={getLayerTypeTooltipText(layer.type)}>
              <LineIconWrapper
                style={{
                  padding: 0,
                }}
              >
                <LayerIconMapper layer={layer} />
              </LineIconWrapper>
            </WithTooltip>
            <VerticalDivider height={1.5} />
            <SourceNameComponent layer={layer} />
          </LayerItemBottomRow>
        </div>
      </LayerItemWithoutCollectionWrapper>
    </MaybeWrapWithDateDeletedTooltip>
  );
};

export default LayerItem;
