import { Divider, IconBtn } from "components/General/Icons";
import Tooltip from "components/General/Tooltip";
import { useSelectOptionContext } from "components/ParkDesign/SelectOptions";
import { CableFeature } from "types/feature";
import Redundant from "@icons/24/Redundant.svg";
import { colors } from "styles/colors";

export const CableRedundancy = ({
  editable,
  cables,
}: {
  cables: CableFeature[];
  editable: boolean;
}) => {
  const { updateFeatures } = useSelectOptionContext();

  const active = cables.some((c) => c.properties.redundancy);
  return (
    <>
      <Tooltip text="Mark the cable as a redundancy cable.  These cables are not included in power analysis, but are included in length computations.">
        <IconBtn
          disabled={!editable}
          active={active}
          iconColor={active ? colors.white : colors.blue800}
          style={
            active
              ? {
                  backgroundColor: colors.surfaceSelectedDark,
                }
              : {}
          }
          onClick={() => {
            updateFeatures({
              update: cables.map((c) => ({
                ...c,
                properties: { ...c.properties, redundancy: !active },
              })),
            });
          }}
        >
          <Redundant />
        </IconBtn>
      </Tooltip>
      <Divider />
    </>
  );
};
