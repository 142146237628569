import Checkbox from "components/General/Checkbox";
import { Label } from "components/General/Form";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { exportCableChangelogInfo } from "components/InputChangelog/const";
import { ChangelogCategory } from "components/InputChangelog/types";
import { SecondaryText } from "components/SettingsV2/Shared/styles";
import { CableType } from "services/cableTypeService";
import { spaceLarge } from "styles/space";

type Props = {
  editedExportCable: CableType;
  setEditedExportCable: (cable: CableType) => void;
  disabled: boolean;
  nodeId: string;
  changelogCategory: ChangelogCategory;
};

const AdvancedSettingsValidFor = ({
  editedExportCable,
  setEditedExportCable,
  disabled,
  nodeId,
  changelogCategory,
}: Props) => {
  return (
    <ChangelogAndCommentWrapper
      changelogInfo={exportCableChangelogInfo(
        editedExportCable.id,
        nodeId,
        "exportCableUsage",
        changelogCategory,
      )}
      disabled={disabled}
    >
      <Label>
        <SecondaryText>Valid for</SecondaryText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: spaceLarge,
          }}
        >
          <Checkbox
            value={"offshore"}
            label={"Offshore"}
            labelPlacement="after"
            checked={editedExportCable.exportCableUsage?.offshore}
            disabled={
              disabled ||
              (editedExportCable.exportCableUsage?.offshore &&
                !editedExportCable.exportCableUsage?.onshore)
            }
            onChange={(e) =>
              setEditedExportCable({
                ...editedExportCable,
                exportCableUsage: {
                  ...editedExportCable.exportCableUsage,
                  offshore: e.target.checked,
                },
              })
            }
          />
          <Checkbox
            value={"onshore"}
            label={"Onshore"}
            labelPlacement="after"
            checked={editedExportCable.exportCableUsage?.onshore}
            disabled={
              disabled ||
              (editedExportCable.exportCableUsage?.onshore &&
                !editedExportCable.exportCableUsage?.offshore)
            }
            onChange={(e) =>
              setEditedExportCable({
                ...editedExportCable,
                exportCableUsage: {
                  ...editedExportCable.exportCableUsage,
                  onshore: e.target.checked,
                },
              })
            }
          />
        </div>
      </Label>
    </ChangelogAndCommentWrapper>
  );
};

export default AdvancedSettingsValidFor;
