import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { nodesWithAccessToTurbine } from "./../../../Library/state";
import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
} from "components/Organisation/Library/service";
import { turbineResourceWithAccessOnNodeState } from "components/Organisation/Library/state";
import { userNodesAccessSyncAtom } from "state/user";
import { RESET } from "jotai/utils";
import { libraryTurbinesRefreshAtom } from "state/jotai/turbineType";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeTurbineResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, turbineId: string) => {
      const fallback = await get(
        turbineResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const userAccess = get(userNodesAccessSyncAtom).data;

      return addOrUpdateResourceOnNode(nodeId, turbineId, "org_turbine_manage")
        .then(async () => {
          Object.keys(userAccess.node_access).map((nodeId) =>
            set(
              turbineResourceWithAccessOnNodeState({
                nodeId,
              }),
              RESET,
            ),
          );
          set(libraryTurbinesRefreshAtom, (curr) => curr + 1);
          set(
            nodesWithAccessToTurbine({
              organisationId,
              resourceId: turbineId,
            }),
          );
        })
        .catch((e) => {
          aset(
            get,
            set,
            turbineResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        turbineResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const userAccess = get(userNodesAccessSyncAtom).data;
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "TURBINE",
          resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(nodeId, "TURBINE", resourceId);
      }
      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Turbine type is currently being used in ${usage.length} turbines, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(nodeId, resourceId, "org_turbine_manage")
          .then(() => {
            Object.keys(userAccess.node_access).map((nodeId) =>
              set(
                turbineResourceWithAccessOnNodeState({
                  nodeId,
                }),
                RESET,
              ),
            );
            set(libraryTurbinesRefreshAtom, (curr) => curr + 1);
            set(
              nodesWithAccessToTurbine({
                organisationId,
                resourceId,
              }),
            );
          })
          .catch((e) => {
            aset(
              get,
              set,
              turbineResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeTurbineResourcesCrud;
