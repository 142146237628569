import React, { Suspense } from "react";
import { Column } from "components/General/Layout";
import { DownloadAnalysisWindDataButton } from "components/WindWaveMeasurementInfoModal/DownloadWindDataButton";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { ProdId, getWindRose } from "analysis/inputs";
import { projectIdAtom } from "state/pathParams";
import { SubtitleWithLine } from "components/General/GeneralSideModals.style";
import { spaceLarge } from "styles/space";
import { useAtomValue } from "jotai";
import { getAnalysisResponse } from "analysis/output";
import { loadable, unwrap } from "jotai/utils";
import {
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";

const WindRose = React.lazy(
  () => import("components/WindWaveMeasurementInfoModal/WindRose"),
);

const DownloadButton = ({ id }: { id: ProdId }) => {
  const analysisL = useAtomValue(loadable(getAnalysisResponse(id)));
  const projectId = useAtomValue(projectIdAtom);
  if (
    !projectId ||
    analysisL.state !== "hasData" ||
    analysisL.data.status !== "complete"
  ) {
    return null;
  }

  return (
    <DownloadAnalysisWindDataButton
      analysisId={analysisL.data.id}
      projectId={projectId}
      analysisVersion={analysisL.data.version}
    />
  );
};

const WindRoseWidget_ = ({ id }: { id: ProdId }) => {
  const windRose = useAtomValue(unwrap(getWindRose(id)));

  return (
    <>
      <SubtitleWithLine
        style={{ paddingTop: spaceLarge }}
        text={"Wind rose"}
        iconButton={
          <Suspense fallback={null}>
            <DownloadButton id={id} />
          </Suspense>
        }
      />

      {windRose ? (
        <Suspense fallback={null}>
          <WindRose windRose={windRose} />
        </Suspense>
      ) : (
        <Column>
          <SkeletonBlock style={{ height: "1rem" }} />
          <SkeletonBlock style={{ height: "1rem" }} />
          <SkeletonBlock style={{ height: "1rem" }} />
        </Column>
      )}
    </>
  );
};

export const WindRoseWidget = ErrorBoundaryWrapper(
  WindRoseWidget_,
  () => <SimpleAlert text="Wind rose failed" />,
  ScreamOnError,
);
