import { getAnalysisWindStats } from "analysis/output";
import { orLoader } from "components/Loading/Skeleton";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { useRef } from "react";
import styled from "styled-components";
import { spatialCalibrationName } from "types/metocean";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";

const Grid = styled(FlexGrid2)`
  flex: 1;
  padding: ${spaceMedium};
  align-self: start;
  p {
    white-space: nowrap;
  }
`;

const WindDetails = () => {
  const { triggerId, windConfiguration } = useDashboardContext();
  const stats = useAtomValue(loadable(getAnalysisWindStats(triggerId)));
  const rowref = useRef<HTMLDivElement>(null);
  const gridref = useRef<HTMLDivElement>(null);

  return (
    <Row style={{ flex: 1 }} ref={rowref}>
      <Grid ref={gridref}>
        <Row>
          <p>Latitude</p>
          {orLoader(stats, (s) => (
            <p>{s.latitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Longitude</p>
          {orLoader(stats, (s) => (
            <p>{s.longitude.toFixed(3)}</p>
          ))}
        </Row>
        <Row>
          <p>Height</p>
          {orLoader(stats, (s) => (
            <p>{s.height} m</p>
          ))}
        </Row>
        <Row>
          <p>Wind source</p>
          {orLoader(stats, (s) => (
            <p>{s.source.toUpperCase()}</p>
          ))}
        </Row>
        <Row>
          <p>Wind source timerange</p>
          {orLoader(stats, (s) => (
            <p>
              {s.fromYear}-{s.toYear}
            </p>
          ))}
        </Row>
        {windConfiguration.calibration &&
          windConfiguration.calibration.type == "percent" && (
            <Row>
              <p>Mean speed calibration</p>
              <p>{windConfiguration.calibration.value} %</p>
            </Row>
          )}
        {windConfiguration.spatial_calibration && (
          <Row>
            <p>Spatial calibration</p>
            <p>
              {
                spatialCalibrationName[
                  windConfiguration.spatial_calibration.type
                ]
              }
              ({windConfiguration.spatial_calibration.value})
            </p>
          </Row>
        )}
        <Row>
          <p>Avg. wind speed</p>
          {orLoader(stats, (s) => (
            <p>{s.meanSpeed.toFixed(2)} m/s</p>
          ))}
        </Row>
        <Row>
          <p>Avg. power-law coeff.</p>
          {orLoader(stats, (s) => (
            <p>{s.meanAlpha.toFixed(3)}</p>
          ))}
        </Row>
      </Grid>
    </Row>
  );
};

export const WindDetailsWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Wind details"
      id="Wind details"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <WindDetails />
    </SafeCard>
  );
};
