import { useAtom } from "jotai";
import {
  branchIdAtom,
  parkIdAtom,
  projectIdAtom,
  projectIdAtomDef,
} from "state/pathParams";
import * as Sentry from "@sentry/react";
import * as turf from "@turf/turf";
import { getUserColor } from "components/LiveCursor/utils";
import {
  ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES,
  layersToExcludeInSelectWithSmallZoom,
} from "components/MapControls/MultiSelect/const";
import {
  CABLE_CHAIN_POLYGON_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
  CABLE_PARTITION_POLYGON_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "@constants/cabling";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  SUB_AREA_PROPERTY_TYPE,
} from "@constants/division";
import { PARK_PROPERTY_TYPE } from "@constants/park";
import {
  ANCHOR_PROPERTY_TYPE,
  EXISTING_TURBINE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  PORT_POINT_PROPERTY_TYPE,
  SENSOR_POINT_PROPERTY_TYPE,
  TURBINE_PROPERTY_TYPE,
} from "@constants/projectMapView";
import { GeoJsonGeometryTypes } from "geojson";
import useNavigateToPark from "hooks/useNavigateToPark";
import mapboxgl, { MapLayerMouseEvent, MapboxGeoJSONFeature } from "mapbox-gl";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "services/types";
import { currentExternalLayerSelection } from "state/externalLayerSelection";
import { editFeaturesAtom } from "state/map";
import { organisationIdAtom } from "state/pathParams";
import { currentVersionSelector } from "state/project";
import {
  currentSelectionArrayAtom,
  currentSelectionWMSAtom,
  defaultMouseHandlerCallBackClickableFeature,
  otherUsersSelectionArrayAtomFamily,
} from "state/selection";
import { ProjectFeature } from "types/feature";
import { featureIsLocked, isDefined } from "utils/predicates";
import { EMPTY_LIST, resetListIfNotAlreadyEmpty } from "utils/resetList";
import { Defined, getPathPrefix, undefMap } from "utils/utils";
import {
  anchorLayerId,
  anchorSourceId,
  cableChainLayerId,
  cableChainSourceId,
  cableCorridorLayerId,
  cableCorridorSourceId,
  cableLayerId,
  cablePartitionLayerId,
  cablePartitionSourceId,
  cableSourceId,
  divisionLayerId,
  divisionOutlineLayerId,
  divisionSourceId,
  exportCableLandfallLayerId,
  exportCableLandfallSegmentLayerId,
  exportCableLayerId,
  exportCableSourceId,
  mooringLineLayerId,
  mooringLineSourceId,
  otherLineStringLayerId,
  otherLineStringSourceId,
  otherPointLayerId,
  otherPointSourceId,
  otherPolygonLayerId,
  otherPolygonSourceId,
  parkLayerFillId,
  parkLayerOutlineId,
  parkSourceId,
  substationLayerId,
  substationSourceId,
  turbineLayerId,
  turbineSourceId,
  existingTurbineLayerId,
  existingTurbineSourceId,
  HIDDEN_CLICK_LAYER_SUFFIX,
  viewPointLayerId,
  viewPointSourceId,
  portSourceId,
  portLayerId,
  sensorPointSourceId,
  sensorPointLayerId,
} from "./constants";
import {
  clickHandlerAtom,
  doubleClickHandlerAtom,
  mouseMoveHandlerAtom,
} from "./state";
import {
  clearHover,
  nora3LayerId,
  removeInFocus,
  setAllSelected,
  setHover,
  setInFocus,
  wavePointsLayerId,
  windPointsLayerId,
} from "./utils";
import { IGNORE_CLICK_ON_MAP } from "components/MapControls/Edit/Callbacks/TopLevelFeatureCallbacks";
import { useAtomValue, useSetAtom } from "jotai";
import { useJotaiCallback } from "utils/jotai";
import { featuresSelectableMapAtom } from "state/jotai/features";
import { customerProjectAtomFamily } from "state/timeline";
import { VIEWPOINT_PROPERTY_TYPE } from "@constants/projectMapView";
import { projectPresenceState } from "components/Ably/ChannelProviders/Project/ProjectAll/state";

const geometryTypeToDim: Record<GeoJsonGeometryTypes, number> = {
  Point: 0,
  MultiPoint: 0,
  LineString: 1,
  MultiLineString: 1,
  Polygon: 2,
  MultiPolygon: 2,
  GeometryCollection: 3,
};

/**
 * Ugly. This is the names of the mapbox sources in which these feature types are stored.
 */
const featureTypeToSourceName: Record<string, string> = {
  [EXPORT_CABLE_PROPERTY_TYPE]: exportCableSourceId,
  [MOORING_LINE_PROPERTY_TYPE]: mooringLineSourceId,
  [PARK_PROPERTY_TYPE]: parkSourceId,
  [CABLE_CORRIDOR_PROPERTY_TYPE]: cableCorridorSourceId,
  [SUBSTATION_PROPERTY_TYPE]: substationSourceId,
  [TURBINE_PROPERTY_TYPE]: turbineSourceId,
  [DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE]: divisionSourceId,
  [SUB_AREA_PROPERTY_TYPE]: divisionSourceId,
  [CABLE_PROPERTY_TYPE]: cableSourceId,
  [ANCHOR_PROPERTY_TYPE]: anchorSourceId,
  [EXISTING_TURBINE_PROPERTY_TYPE]: existingTurbineSourceId,
  [CABLE_PARTITION_POLYGON_PROPERTY_TYPE]: cablePartitionSourceId,
  [CABLE_CHAIN_POLYGON_PROPERTY_TYPE]: cableChainSourceId,
  [VIEWPOINT_PROPERTY_TYPE]: viewPointSourceId,
  [SENSOR_POINT_PROPERTY_TYPE]: sensorPointSourceId,
  [PORT_POINT_PROPERTY_TYPE]: portSourceId,
  // NOTE: GeoTIFFs are rendered as a custom layer with a custom shader, but
  // they are also rendered as an Other polygon, so that we get highlighting of
  // the border when selected.
  [GeoTiffUserUploadedImageType]: otherPolygonSourceId,
};

/**
 * This list of Layer IDs will be used to determine if a feature is interactive
 * or not. If it is not interactive, we will not show the hover effect and
 * clicking on it won't do anything.
 */
const INTERACTIVE_LAYERS = [
  parkLayerFillId,
  parkLayerOutlineId,
  turbineLayerId,
  divisionLayerId,
  divisionOutlineLayerId,
  mooringLineLayerId,
  anchorLayerId,
  substationLayerId,
  exportCableLayerId,
  cableCorridorLayerId,
  cableLayerId,
  otherPointLayerId,
  otherLineStringLayerId,
  otherPolygonLayerId,
  cablePartitionLayerId,
  cableChainLayerId,
  nora3LayerId,
  windPointsLayerId,
  wavePointsLayerId,
  existingTurbineLayerId,
  viewPointLayerId,
  sensorPointLayerId,
  portLayerId,
];

const NONINTERACTIVE_LAYERS = [
  exportCableLandfallLayerId,
  exportCableLandfallSegmentLayerId,
];

export const getSmallestFeature = (
  e: mapboxgl.MapMouseEvent,
  map: mapboxgl.Map,
  allFeatures?: Map<string, ProjectFeature>,
  options?: Defined<Parameters<mapboxgl.Map["queryRenderedFeatures"]>>[0],
): MapboxGeoJSONFeature[] =>
  map
    .queryRenderedFeatures(e.point, options)
    .filter((f) => f.source !== "composite")
    .filter((l) => {
      if (l.layer && NONINTERACTIVE_LAYERS.includes(l.layer.id)) return false;
      // We don't want to interact with e.g. Polygon names or wake labels, but
      // some of the interactive layers contain symbol features.
      return (
        l.layer?.type !== "symbol" || INTERACTIVE_LAYERS.includes(l.layer.id)
      );
    })
    .map((f) => ({
      ...f,
      geometry:
        (allFeatures && allFeatures.get(String(f.id))?.geometry) ?? f.geometry,
    }))
    .map<[number, MapboxGeoJSONFeature]>((f) => [
      geometryTypeToDim[f.geometry.type] ?? 3,
      f,
    ])
    .sort((a, b) => {
      try {
        if (a[0] === b[0]) return turf.area(a[1]) - turf.area(b[1]);
        return a[0] - b[0];
      } catch (e) {
        Sentry.addBreadcrumb({
          category: "mouse handling",
          message: "Unable to calculate area of features",
          level: "error",
          data: {
            a,
            b,
            allFeatures,
          },
        });
        throw e;
      }
    })
    .map((f) => f[1]);

/**
 *
 * @param feature  See {@link https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfeaturestate}
 */
export const useSyncFeatureFlag = (
  map: mapboxgl.Map | undefined,
  source: string,
  id: string | number | undefined,
  field: string,
) => {
  useEffect(() => {
    if (!map || !id) return;
    map.setFeatureState(
      {
        source,
        id,
      },
      {
        [field]: true,
      },
    );
    return () => {
      if (map.getSource(source)) {
        map.removeFeatureState(
          {
            source,
            id,
          },
          field,
        );
      }
    };
  }, [field, id, map, source]);
};

export const useSyncFeatureFlags = (
  map: mapboxgl.Map | undefined,
  source: string,
  ids: (string | number)[] | undefined,
  field: string,
) => {
  useEffect(() => {
    if (!map || !ids) return;
    for (const id of ids)
      map.setFeatureState(
        {
          source,
          id,
        },
        {
          [field]: true,
        },
      );
    return () => {
      if (map.getSource(source)) {
        for (const id of ids)
          map.removeFeatureState(
            {
              source,
              id,
            },
            field,
          );
      }
    };
  }, [field, ids, map, source]);
};

export const SimpleMapboxSyncEffects = ({
  map,
  setCurrentSelectionArray,
}: {
  map: mapboxgl.Map;
  setCurrentSelectionArray: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const clearSelection = useCallback(
    () => setCurrentSelectionArray([]),
    [setCurrentSelectionArray],
  );
  const getMouseHandlers = useJotaiCallback(
    (get) => get(defaultMouseHandlerCallBackClickableFeature),
    [],
  );

  useEffect(() => {
    const mousemove = async (e: MapLayerMouseEvent) => {
      let sortedFeatures = getSmallestFeature(e, map);
      if (map.getZoom() < ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES) {
        const filtered = sortedFeatures.filter((f) => {
          if (!f.layer) return false;
          const id = f.layer.id;
          return !layersToExcludeInSelectWithSmallZoom.includes(id);
        });
        if (0 < filtered.length) sortedFeatures = filtered;
      }
      const feature = sortedFeatures[0];

      const layer = feature?.layer?.id;
      const allCallbacks = await getMouseHandlers();
      const callbacks = undefMap(
        feature?.layer?.id,
        (id) => allCallbacks?.[id],
      );

      if (callbacks?.onMouseMove) {
        e.features = sortedFeatures;
        map.getCanvas().style.cursor = "pointer";
        callbacks.onMouseMove(e);
        // Call mouseLeave for all other layers that weren't hovered over
        for (const [layerId, callbacks] of Object.entries(allCallbacks ?? {}))
          if (layerId !== layer && callbacks?.onMouseLeave)
            callbacks.onMouseLeave(e);
        return;
      }

      if (!layer) {
        // if we didn't hover over a layer, we need to call onLeave for *all
        // layers* that have this callback, because this is what the previous
        // system did.
        map.getCanvas().style.cursor = "unset";
        for (const callbacks of Object.values(allCallbacks ?? {}))
          if (callbacks?.onMouseLeave) callbacks.onMouseLeave(e);
      }

      if (!feature) {
        map.getCanvas().style.cursor = "unset";
      } else {
        map.getCanvas().style.cursor = "pointer";
      }
    };
    map.on("mousemove", mousemove);
    return () => {
      map.off("mousemove", mousemove);
    };
  }, [getMouseHandlers, map]);

  useEffect(() => {
    const click = async (e: MapLayerMouseEvent) => {
      const clickOnCanvas = e.originalEvent.target === map.getCanvas();
      if (!clickOnCanvas) return;
      if (IGNORE_CLICK_ON_MAP) return;

      const shiftClicked = e.originalEvent.shiftKey;

      let sortedFeatures = getSmallestFeature(e, map);
      if (map.getZoom() < ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES) {
        const filtered = sortedFeatures.filter((f) => {
          if (!f.layer) return false;
          const id = f.layer.id;
          return !layersToExcludeInSelectWithSmallZoom.includes(id);
        });
        if (0 < filtered.length) sortedFeatures = filtered;
      }
      const feature = sortedFeatures[0];

      const allCallbacks = await getMouseHandlers();
      const layerId = feature?.layer?.id;
      const callbacks = undefMap(layerId, (id) => allCallbacks?.[id]);
      if (callbacks?.onClick) {
        e.features = sortedFeatures;
        callbacks.onClick(e);
        return;
      }

      setCurrentSelectionArray((currentlySelectedIds) => {
        if (!feature) return resetListIfNotAlreadyEmpty(currentlySelectedIds);

        const id = feature.id;
        if (!id) return currentlySelectedIds;

        const isAlreadySelected = currentlySelectedIds.find(
          (selectedId) => selectedId === id,
        );

        const newSelection = String(id);
        if (shiftClicked) {
          if (isAlreadySelected) {
            return currentlySelectedIds.filter(
              (selectedId) => selectedId !== id,
            );
          }
          return currentlySelectedIds.concat([newSelection]);
        } else {
          if (isAlreadySelected && currentlySelectedIds.length === 1) {
            return EMPTY_LIST;
          }
          return [newSelection];
        }
      });

      if (!feature) {
        clearSelection();
      }
    };
    map.on("click", click);
    return () => {
      map.off("click", click);
    };
  }, [clearSelection, getMouseHandlers, map, setCurrentSelectionArray]);

  useEffect(() => {
    const dblclick = async (e: MapLayerMouseEvent) => {
      const clickOnCanvas = e.originalEvent.target === map.getCanvas();
      if (!clickOnCanvas) return;

      e.preventDefault(); // don't zoom

      let sortedFeatures = getSmallestFeature(e, map);
      if (map.getZoom() < ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES) {
        const filtered = sortedFeatures.filter((f) => {
          if (!f.layer) return false;
          const id = f.layer.id;
          return !layersToExcludeInSelectWithSmallZoom.includes(id);
        });
        if (0 < filtered.length) sortedFeatures = filtered;
      }
      const feature = sortedFeatures[0];

      const allCallbacks = await getMouseHandlers();
      const layerId = feature?.layer?.id;
      const callbacks = undefMap(layerId, (id) => allCallbacks?.[id]);
      if (callbacks?.onDblClick) {
        e.features = sortedFeatures;
        callbacks.onDblClick(e);
        return;
      }

      //setEditFeature([String(id)]);
    };
    map.on("dblclick", dblclick);
    return () => {
      map.off("dblclick", dblclick);
    };
  }, [map, setCurrentSelectionArray, getMouseHandlers]);

  return null;
};

export const MapboxSyncEffects = ({ map }: { map: mapboxgl.Map }) => {
  const featureMap = useAtomValue(featuresSelectableMapAtom);
  const [currentSelectionArray, setCurrentSelectionArray] = useAtom(
    currentSelectionArrayAtom,
  );
  const setEditFeature = useSetAtom(editFeaturesAtom);
  const { navigateToPark, navigate } = useNavigateToPark();
  const [searchParams] = useSearchParams();
  const version = useAtomValue(currentVersionSelector);

  const clearSelectionJotai = useJotaiCallback((_, set) => {
    set(currentExternalLayerSelection, EMPTY_LIST);
  }, []);

  const clearSelection = useJotaiCallback(
    (get, set) => {
      set(currentSelectionArrayAtom, EMPTY_LIST);
      clearSelectionJotai();
      set(currentSelectionWMSAtom, EMPTY_LIST);
      const organisationId = get(organisationIdAtom);
      const projectId = get(projectIdAtomDef);
      const branchId = get(branchIdAtom);
      const project = get(
        customerProjectAtomFamily({
          nodeId: projectId,
        }),
      );
      navigate(
        {
          pathname: `/${getPathPrefix(project)}/project/${organisationId}/${projectId}/${branchId}`,
          search: searchParams.toString(),
          hash: document.location.hash,
        },
        {
          replace: true,
        },
      );
    },
    [navigate, searchParams, clearSelectionJotai],
  );

  // Sync the mapbox selection with the currentSelectionArray
  useEffect(() => {
    const items = currentSelectionArray
      .flatMap((id) => {
        const f = featureMap.get(id);
        if (!f) return undefined;
        let type = f.properties?.type;
        let source = featureTypeToSourceName[type ?? ""];
        if (!type || type === GeoTiffUserUploadedImageType) {
          // Not sure which type this is. Assume it's an other feature.
          source = (
            {
              Point: otherPointSourceId,
              LineString: otherLineStringSourceId,
              Polygon: otherPolygonSourceId,
            } as Record<string, string>
          )[f.geometry.type];
        }
        if (!source) return undefined;
        return [
          {
            source,
            featureId: id,
          },
        ];
      })
      .filter(isDefined);
    setAllSelected(map, items);
  }, [currentSelectionArray, featureMap, map]);

  // Sync focus for when we have selected a park.
  const parkId = useAtomValue(parkIdAtom);
  useEffect(() => {
    // This timeout looks really dumb, and it is.  If you navigate to a URL
    // with a park the park will be selected, since it is in the URL, but this
    // hook will run too early, i.e. before the features are added to the map
    // (I think), and they'll not get the focus effect, and look grayed out.
    // By adding a delay, we circumvent this issue.
    setTimeout(() => {
      if (parkId) {
        for (const f of featureMap.values()) {
          const id = f.id;
          if (!id) continue;
          const inFocus =
            f.id === parkId || f.properties?.parentIds?.includes(parkId);
          const source = featureTypeToSourceName[f.properties?.type ?? ""];
          if (!source) continue;
          if (inFocus) {
            setInFocus(map, source, String(id));
          } else {
            removeInFocus(map, source, String(id));
          }
        }
      } else {
        for (const f of featureMap.values()) {
          const source = featureTypeToSourceName[f.properties?.type ?? ""];
          if (!source) continue;
          removeInFocus(map, source, String(f.id));
        }
      }
    }, 50);
  }, [featureMap, map, parkId]);

  const setExternalLayerSelection = useSetAtom(currentExternalLayerSelection);
  const setWMSSelection = useSetAtom(currentSelectionWMSAtom);

  const getMouseHandlers = useJotaiCallback(
    (get) => get(defaultMouseHandlerCallBackClickableFeature),
    [],
  );
  // Mosue move event handler
  const mouseMoveHandler = useAtomValue(mouseMoveHandlerAtom);
  useEffect(() => {
    const mousemove = async (e: MapLayerMouseEvent) => {
      let sortedFeatures = getSmallestFeature(e, map, featureMap);
      if (map.getZoom() < ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES) {
        const filtered = sortedFeatures.filter((f) => {
          if (!f.layer) return false;
          const id = f.layer.id;
          return !layersToExcludeInSelectWithSmallZoom.includes(id);
        });
        if (0 < filtered.length) sortedFeatures = filtered;
      }
      const feature = sortedFeatures[0];

      const layer = feature?.layer?.id.replace(HIDDEN_CLICK_LAYER_SUFFIX, "");
      const allCallbacks = await getMouseHandlers();
      const callbacks = undefMap(layer, (id) => allCallbacks?.[id]);

      if (callbacks?.onMouseMove) {
        e.features = sortedFeatures;
        map.getCanvas().style.cursor = "pointer";
        callbacks.onMouseMove(e);
        // Call mouseLeave for all other layers that weren't hovered over
        for (const [layerId, callbacks] of Object.entries(allCallbacks ?? {}))
          if (layerId !== layer && callbacks?.onMouseLeave)
            callbacks.onMouseLeave(e);
        return;
      }

      if (!layer) {
        // if we didn't hover over a layer, we need to call onLeave for *all
        // layers* that have this callback, because this is what the previous
        // system did.
        map.getCanvas().style.cursor = "unset";
        for (const callbacks of Object.values(allCallbacks ?? {}))
          if (callbacks?.onMouseLeave) callbacks.onMouseLeave(e);
      }

      const isNotInteractive = !layer || !INTERACTIVE_LAYERS.includes(layer);
      if (!feature || isNotInteractive) {
        map.getCanvas().style.cursor = "unset";
        clearHover(map);
      } else {
        map.getCanvas().style.cursor = "pointer";
        if (feature.source)
          setHover(map, feature.source, feature.properties?.id);
      }
    };
    map.on("mousemove", mouseMoveHandler ?? mousemove);
    return () => {
      map.off("mousemove", mouseMoveHandler ?? mousemove);
    };
  }, [featureMap, getMouseHandlers, map, mouseMoveHandler]);

  // Mouse click event handler
  const clickHandler = useAtomValue(clickHandlerAtom);
  useEffect(() => {
    const click = async (e: MapLayerMouseEvent) => {
      const clickOnCanvas = e.originalEvent.target === map.getCanvas();
      if (!clickOnCanvas) return;
      if (IGNORE_CLICK_ON_MAP) return;

      const shiftClicked = e.originalEvent.shiftKey;

      let sortedFeatures = getSmallestFeature(e, map, featureMap);
      if (map.getZoom() < ZOOM_LEVEL_CUT_OFF_FOR_SMALL_FEATURES) {
        const filtered = sortedFeatures.filter((f) => {
          if (!f.layer) return false;
          const id = f.layer.id;
          return !layersToExcludeInSelectWithSmallZoom.includes(id);
        });
        if (0 < filtered.length) sortedFeatures = filtered;
      }
      const feature = sortedFeatures[0];

      const allCallbacks = await getMouseHandlers();
      const layerId = feature?.layer?.id.replace(HIDDEN_CLICK_LAYER_SUFFIX, "");
      const callbacks = undefMap(layerId, (id) => allCallbacks?.[id]);
      if (callbacks?.onClick) {
        e.features = sortedFeatures;
        callbacks.onClick(e);
        return;
      }

      setExternalLayerSelection(resetListIfNotAlreadyEmpty);
      setWMSSelection(resetListIfNotAlreadyEmpty);
      const isNotInteractive =
        !layerId || !INTERACTIVE_LAYERS.includes(layerId);

      setCurrentSelectionArray((currentlySelectedIds) => {
        if (!feature || isNotInteractive)
          return resetListIfNotAlreadyEmpty(currentlySelectedIds);

        const id = feature.id;
        if (!id) return currentlySelectedIds;

        const isAlreadySelected = currentlySelectedIds.find(
          (selectedId) => selectedId === id,
        );

        const newSelection = String(id);
        if (shiftClicked) {
          if (isAlreadySelected) {
            return currentlySelectedIds.filter(
              (selectedId) => selectedId !== id,
            );
          }
          return currentlySelectedIds.concat([newSelection]);
        } else {
          if (isAlreadySelected && currentlySelectedIds.length === 1) {
            return EMPTY_LIST;
          }
          return [newSelection];
        }
      });

      if (feature && !isNotInteractive) {
        const parentIds = feature?.properties?.parentIds
          ? (JSON.parse(feature.properties.parentIds) as string[])
          : undefined;
        if (parentIds && parentIds.length > 0) {
          navigateToPark(parentIds[0]);
        }
      } else {
        clearSelection();
        navigateToPark(undefined);
      }
    };
    map.on("click", clickHandler ?? click);
    return () => {
      map.off("click", clickHandler ?? click);
    };
  }, [
    clearSelection,
    clickHandler,
    featureMap,
    getMouseHandlers,
    map,
    navigateToPark,
    setCurrentSelectionArray,
    setExternalLayerSelection,
    setWMSSelection,
  ]);

  // Mouse double click event handler
  const doubleClickHandler = useAtomValue(doubleClickHandlerAtom);
  useEffect(() => {
    const dblclick = (e: MapLayerMouseEvent) => {
      const clickOnCanvas = e.originalEvent.target === map.getCanvas();
      if (!clickOnCanvas) return;

      e.preventDefault(); // don't zoom

      if (version !== undefined) {
        return;
      }

      const sortedFeatures = getSmallestFeature(e, map, featureMap, {
        layers: [
          ...INTERACTIVE_LAYERS,
          ...INTERACTIVE_LAYERS.map((l) => l + HIDDEN_CLICK_LAYER_SUFFIX),
        ].filter((l) => map.getLayer(l)),
      });
      const feature = sortedFeatures.at(0);

      const id = feature?.id;
      if (
        !feature ||
        [BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
          feature?.properties?.type,
        ) ||
        featureIsLocked(feature) ||
        !id
      )
        return;

      setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
      setEditFeature([String(id)]);
    };
    map.on("dblclick", doubleClickHandler ?? dblclick);
    return () => {
      map.off("dblclick", doubleClickHandler ?? dblclick);
    };
  }, [
    doubleClickHandler,
    featureMap,
    map,
    setCurrentSelectionArray,
    setEditFeature,
    version,
  ]);

  /// Highlight features that are in focus by other users.
  const projectNodeId = useAtomValue(projectIdAtom) ?? "";
  const presenceData = useAtomValue(projectPresenceState(projectNodeId));

  const branchId = useAtomValue(branchIdAtom) ?? "";
  const otherUsersSelection = useAtomValue(
    otherUsersSelectionArrayAtomFamily({
      projectId: projectNodeId,
      branchId,
      version,
    }),
  );
  useEffect(() => {
    for (const { selection, userId } of otherUsersSelection) {
      const borderColor = getUserColor(userId);
      for (const id of selection) {
        const feature = featureMap.get(id);
        if (!feature) continue;
        const source = featureTypeToSourceName[feature.properties?.type ?? ""];
        if (!source) continue;
        map.setFeatureState(
          {
            source,
            id,
          },
          {
            borderColor,
          },
        );
      }
    }
    return () => {
      for (const { selection } of otherUsersSelection) {
        for (const id of selection) {
          const feature = featureMap.get(id);
          if (!feature) continue;
          const source =
            featureTypeToSourceName[feature.properties?.type ?? ""];
          if (!source) continue;
          map.removeFeatureState(
            {
              source,
              id,
            },
            "borderColor",
          );
        }
      }
    };
  }, [featureMap, map, otherUsersSelection, presenceData]);

  return null;
};
