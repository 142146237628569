import React, { useCallback, useEffect, useRef, useState } from "react";
import { sendWarning } from "utils/sentry";
import { ModalFrame, Row } from "components/General/Layout";
import { StyledTextarea } from "components/General/Input";
import styled from "styled-components";
import Button from "components/General/Button";
import { useClickOutside } from "hooks/useClickOutside";
import { MenuItem } from "components/General/Menu";
import Send from "@icons/24/Send.svg?react";
import Edit from "@icons/24/Pencil.svg";
import Trashcan from "@icons/24/Bin.svg";
import { IconREMSize, typography } from "styles/typography";
import { colors } from "styles/colors";
import ChatIcon from "@icons/24/Chat.svg?react";
import { DotMenu } from "components/General/MenuButton";
import ChatFilledIcon from "@icons/24/ChatFilled.svg?react";
import { Anchor } from "components/General/Anchor";
import { IconBtn } from "components/General/Icons";
import { platformCtrlOrCommand } from "utils/utils";

const MessageBubble = styled.div`
  background-color: ${colors.indigo50};
  padding: 1rem;
  border-radius: 0.5rem;
  min-width: 20rem;
  max-width: 60rem;
  position: relative;
  &:hover {
    background-color: ${colors.indigo100};
  }
`;

const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.borderSubtle};
  margin: 0.4rem 0;
`;

const CircleButton = styled.button`
  background-color: ${colors.indigo100};
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  svg {
    color: ${colors.indigo500};
    width: 1.6rem;
    height: 1.6rem;
  }

  &:hover {
    background-color: ${colors.indigo200};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: inherit;
  flex: 1;
`;

const Text = styled.p`
  ${typography.caption}
  color: ${colors.textPrimary};
  margin: 0;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
`;

const DescriptionModal = ({
  defaultValue,
  updateDescription,
  close,
  subtitle,
  disabled,
  editByDefault,
  size = "large",
}: {
  defaultValue?: string;
  updateDescription(newDescription: string): void;
  close?: () => void;
  subtitle: React.ReactNode;
  disabled?: boolean;
  size?: "large" | "small";
  editByDefault?: boolean;
}) => {
  const [text, setText] = useState(defaultValue ?? "");
  const [isSaving, setIsSaving] = useState(false);
  const [edit, setEdit] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(true);
  const chatBtnRef = useRef<HTMLButtonElement>(null);
  const [showDotMenu, setShowDotMenu] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // When opening op the
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (editByDefault) {
      setEdit(true);
    }
  }, [editByDefault]);
  useClickOutside(
    elementRef,
    () => {
      setText(defaultValue ?? "");
      setEdit(false);
      setIsPreview(false);
      if (close) {
        close();
      }
    },
    (target) => {
      if (!(target instanceof HTMLElement)) {
        return false;
      }
      // Add icon button check to the ignore condition
      const isIconButton = target.closest('[data-description-icon="true"]');
      return target.id === "dot-menu" || isIconButton !== null;
    },
  );

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setText(defaultValue ?? "");
  }, [defaultValue]);

  const onDoneClick = useCallback(async () => {
    setIsSaving(true);
    setEdit(false);
    setIsPreview(false);
    try {
      updateDescription(text);
    } catch (err) {
      if (isMountedRef.current) {
        sendWarning("Could not update description.", { err });
      }
    } finally {
      if (isMountedRef.current) {
        setIsSaving(false);
      }
    }
  }, [text, updateDescription]);

  const handleDelete = useCallback(async () => {
    if (disabled) return;
    setIsSaving(true);
    try {
      updateDescription("");
      if (close) {
        close();
      }
    } catch (err) {
      sendWarning("Could not delete description.", { err });
    } finally {
      setIsSaving(false);
      setEdit(false);
      setIsPreview(false);
    }
  }, [disabled, close, updateDescription]);

  if (disabled && !defaultValue) {
    return null;
  }

  if (edit || isPreview) {
    return (
      <>
        <>
          {size === "large" && (
            <CircleButton
              ref={chatBtnRef}
              disabled={isSaving}
              onClick={() => setEdit(true)}
            >
              <ChatIcon />
            </CircleButton>
          )}
          {size === "small" && (
            <IconBtn
              style={{
                height: "2.4rem",
                alignSelf: "center",
              }}
              ref={chatBtnRef}
              size="1.4rem"
              disabled={isSaving}
              onClick={() => setEdit(true)}
              iconColor={
                defaultValue || edit ? colors.indigo500 : colors.iconDefault
              }
              backgroundColor={
                edit ? colors.surfaceSelectedLight : "transparent"
              }
            >
              {defaultValue || edit ? <ChatFilledIcon /> : <></>}
            </IconBtn>
          )}
          <Anchor baseRef={chatBtnRef} floatPlace="topRight">
            <ModalFrame
              frameRef={elementRef}
              onClick={(e) => {
                e.stopPropagation();
              }}
              title="Description"
              style={{
                height: "auto",
                maxHeight: "80vh",
                minHeight: edit ? "15rem" : "8rem",
                overflowY: edit ? "hidden" : "auto",
                resize: "vertical",
              }}
              menuItems={
                <>
                  <MenuItem
                    id="dot-menu"
                    name="Edit"
                    icon={<Edit />}
                    disabled={disabled || isSaving}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEdit(true);
                      setIsPreview(false);
                    }}
                  />
                  <MenuItem
                    id="dot-menu"
                    name="Delete"
                    icon={<Trashcan />}
                    disabled={disabled || isSaving}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDelete();
                    }}
                  />
                </>
              }
            >
              {!isPreview ? (
                <>
                  {subtitle}
                  <HorizontalDivider />

                  <Form style={{ width: "90%" }}>
                    <StyledTextarea
                      ref={textareaRef}
                      autoFocus
                      value={text}
                      disabled={isSaving}
                      maxLength={5000} // sort of random number
                      onKeyDown={(e) => {
                        // cmd+enter to save
                        if (e.key === "Enter" && platformCtrlOrCommand(e)) {
                          e.preventDefault();
                          e.stopPropagation();
                          onDoneClick();
                        } else if (e.key === "Escape") {
                          setText(defaultValue ?? "");
                          setEdit(false);
                          setIsPreview(false);
                        }
                      }}
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Write a description..."
                      style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                        resize: "none",
                        minHeight: "2.8rem",
                      }}
                    />
                    <Button
                      type="button"
                      icon={<Send />}
                      buttonType="text"
                      disabled={text.length === 0 || isSaving}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDoneClick();
                      }}
                    />
                  </Form>
                </>
              ) : (
                <Row style={{ alignItems: "center" }}>
                  <Text style={{ textWrap: "balance" }}>{text ?? ""}</Text>
                </Row>
              )}
            </ModalFrame>
          </Anchor>
        </>
      </>
    );
  }

  return (
    <>
      {size === "large" && (
        <>
          {defaultValue ? (
            <MessageBubble
              ref={elementRef}
              onMouseEnter={() => setShowDotMenu(true)}
              onMouseLeave={() => setShowDotMenu(false)}
            >
              <Row
                style={{ justifyContent: "space-between", padding: "0.4rem" }}
              >
                <Row style={{ gap: "0.4rem", alignItems: "center" }}>
                  <IconREMSize
                    height={1.6}
                    width={1.6}
                    style={{ padding: "0.8rem" }}
                  >
                    <ChatFilledIcon />
                  </IconREMSize>
                  <Text style={{ maxHeight: "8rem" }}>{text}</Text>
                </Row>

                <DotMenu
                  hoverBackgroundColor={colors.grey200}
                  showDots={showDotMenu}
                >
                  <MenuItem
                    name="Edit"
                    icon={<Edit />}
                    disabled={disabled || isSaving}
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                  <MenuItem
                    name="Delete"
                    icon={<Trashcan />}
                    disabled={disabled || isSaving}
                    onClick={handleDelete}
                  />
                </DotMenu>
              </Row>
            </MessageBubble>
          ) : (
            <CircleButton
              ref={chatBtnRef}
              disabled={isSaving}
              onClick={() => setEdit(true)}
            >
              <ChatIcon />
            </CircleButton>
          )}
        </>
      )}
      {size === "small" && (
        <>
          {defaultValue ? (
            <IconBtn
              style={{
                height: "2.4rem",
                alignSelf: "center",
              }}
              ref={chatBtnRef}
              size="1.4rem"
              disabled={isSaving}
              onClick={(e) => {
                e.stopPropagation();
                setIsPreview(true);
              }}
              iconColor={defaultValue ? colors.indigo500 : colors.iconDefault}
              backgroundColor={
                edit || isPreview ? colors.surfaceSelectedLight : "transparent"
              }
              hoverBackgroundColor={colors.grey200}
            >
              <ChatFilledIcon />
            </IconBtn>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default DescriptionModal;
