import { validateRnaMass } from "components/ConfigurationModal/TurbineSettings/TurbineSettingCustomAdmin";
import { InputDimensioned } from "components/General/Input";
import { foundationChangelogInfo } from "components/InputChangelog/const";
import { Label } from "components/General/Form";
import { SecondaryText } from "components/Projects/ProjectGrid.style";
import { kgToTonne } from "../../FoundationSettings";
import { ReferenceNumbersProps } from "./ReferenceNumberProps";

const ReferenceRNAWeight = ({
  disabled,
  tempFoundation,
  originalFoundation,
  setTempFoundation,
  nodeId,
  commentCategory,
}: ReferenceNumbersProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Reference RNA weight</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          validate={(rnaMassTons) =>
            validateRnaMass({
              rnaMass: rnaMassTons * 1000,
              diameter: tempFoundation.rotorDiameter,
            })
          }
          changelogInfo={foundationChangelogInfo(
            tempFoundation.id,
            nodeId,
            "rnaMass",
            commentCategory,
          )}
          validationMessage={`The value differs from our estimation by more than 50%`}
          step="1"
          unit="t"
          type="number"
          scaleFactor={kgToTonne}
          value={tempFoundation.rnaMass}
          initialValue={
            originalFoundation ? originalFoundation.rnaMass : undefined
          }
          onChange={(rnaMassKilograms) => {
            setTempFoundation({
              ...tempFoundation,
              rnaMass: rnaMassKilograms,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default ReferenceRNAWeight;
