import { useRef } from "react";
import {
  borderRadiusMedium,
  spaceDecent,
  spaceTiny,
  spacing4,
} from "styles/space";
import EarthIcon from "@icons/14/Earth.svg";
import { useClickOutside } from "hooks/useClickOutside";
import { IconREMSize, typography } from "styles/typography";
import { Mixpanel } from "mixpanel";
import { colors } from "styles/colors";
import useBooleanState from "hooks/useBooleanState";
import { projectIdAtom } from "state/pathParams";
import BranchProjectPicker from "components/CompareParksModal/ConfigurationBar/SelectParks/BranchProjectPicker";
import { selectedParksAtom } from "components/CompareParksModal/state";
import { useAtomValue } from "jotai";

const SelectParksButton = () => {
  const [isOpen, toggleIsOpen] = useBooleanState(false);
  const nodeId = useAtomValue(projectIdAtom);
  const selectedParks = useAtomValue(
    selectedParksAtom({ projectId: nodeId ?? "" }),
  );
  const frameRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useClickOutside(
    frameRef,
    toggleIsOpen,
    (target) => target === frameRef?.current || target === buttonRef?.current,
    {
      ignoreDragClicks: true,
    },
  );

  return (
    <div style={{ position: "relative", overflow: "visible" }}>
      <div
        ref={buttonRef}
        style={{
          display: "flex",
          gap: spaceDecent,
          borderRadius: borderRadiusMedium,
          padding: `${spaceTiny} ${spaceDecent}`,
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          height: "3.2rem",
          border: isOpen
            ? `1px solid ${colors.borderSelected}`
            : `1px solid ${colors.borderDefault}`,
          boxSizing: "border-box",
        }}
        onClick={() => {
          toggleIsOpen();
          Mixpanel.track_old("Toggle Select comparison parks open", {});
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <EarthIcon />
        </IconREMSize>
        <p style={{ ...typography.regular, margin: 0 }}>Select parks</p>
        <span
          style={{
            ...typography.regular,
            margin: 0,
            backgroundColor: colors.blue100,
            color: colors.blue600,
            padding: `2px ${spacing4}`,
            borderRadius: typography.regular.fontSize,
          }}
        >
          {selectedParks.length}
        </span>
      </div>
      {isOpen && <BranchProjectPicker ref={frameRef} onExit={toggleIsOpen} />}
    </div>
  );
};

export default SelectParksButton;
