import { MercatorCoordinate } from "mapbox-gl";
import { rad2deg } from "utils/geometry";
import { getDistanceFromLatLonInM } from "utils/proj4";
import { scream } from "utils/sentry";

export type Tile = {
  x: number;
  y: number;
  z: number;
};
type LonLat = {
  lon: number;
  lat: number;
};

type TileData = { x: number; y: number; imageData: ImageData };
export type PointWithLngLag = {
  x: number;
  y: number;
  lng: number;
  lat: number;
};
export type TileSamplingArgs = [
  TileData[],
  PointWithLngLag[],
  number,
  number,
  SphericalMercator,
];
/**
 * Higher X means more east.
 * Higher Y means more south.
 */
export const tile2NWcorner = (x: number, y: number, z: number): LonLat => {
  const n = 1 << z;
  const lon_deg = (x / n) * 360 - 180;
  const lat_rad = Math.atan(Math.sinh(Math.PI * (1 - (2 * y) / n)));
  const lat_deg = rad2deg(lat_rad);
  return { lon: lon_deg, lat: lat_deg };
};

export const tile2SEcorner = (x: number, y: number, z: number): LonLat =>
  tile2NWcorner(x + 1, y + 1, z);

export const tile2bbox = ({
  x,
  y,
  z,
}: Tile): [number, number, number, number] => {
  const nw = tile2NWcorner(x, y, z);
  const se = tile2SEcorner(x, y, z);
  return [nw.lon, se.lat, se.lon, nw.lat];
};

/**
 * Get the number of meters that one pixel in the tile represents.
 * When the meters is different in the x and y direction, return the largest.
 */
export const tilePrecisionM = (t: Tile, tileSize: number = 256): number => {
  const bbox = tile2bbox(t);
  const length = getDistanceFromLatLonInM(
    [bbox[0], bbox[1]],
    [bbox[2], bbox[1]],
  );
  const height = getDistanceFromLatLonInM(
    [bbox[0], bbox[1]],
    [bbox[0], bbox[3]],
  );
  return Math.min(length, height) / tileSize;
};

export const lonLatToTileFloat = (
  lon: number,
  lat: number,
  z: number,
): Tile => {
  const x = ((lon + 180) / 360) * Math.pow(2, z);
  const y =
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180),
      ) /
        Math.PI) /
      2) *
    Math.pow(2, z);
  return { x, y, z };
};

export const lonLatToTile = (lon: number, lat: number, z: number): Tile => {
  const { x, y } = lonLatToTileFloat(lon, lat, z);
  return { x: Math.floor(x), y: Math.floor(y), z };
};

export const parentTileWithZ = (t: Tile, newZ: number): Tile => {
  const { x, y, z } = t;
  if (z < newZ)
    throw scream("Requested z is larger than tile.z.", { tile: t, newZ });
  if (newZ === z) return { ...t };
  const diff = z - newZ;
  return {
    x: Math.floor(x / Math.pow(2, diff)),
    y: Math.floor(y / Math.pow(2, diff)),
    z: newZ,
  };
};

/**
 * Weird mapbox bug where zooming out without globe view will give us tiles with
 * negative indices. * Handle this by wrapping around negative indices.
 * See VIN-433.
 */
export function wrapMapboxTile(mc: MercatorCoordinate) {
  // zoom z means x,y goes from [0, 2^z).
  const Z = 2 << (mc.z - 1);
  if (mc.x < 0) mc.x += Z;
  if (Z <= mc.x) mc.x -= Z;
  if (mc.y < 0) mc.y += Z;
  if (Z <= mc.y) mc.y -= Z;
}
