import { atom, useSetAtom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { useCallback } from "react";
import { OrganisationInvite, _OrganisationInvite } from "types/customer";
import { fetchSchemaWithToken } from "services/utils";
import {
  CustomerPersona,
  collaboratorRole,
  personaIsMember,
  _CustomerPersona,
} from "types/customer";
import { admin_nodesInOrgSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { groupsInNodeAndSubnodesAtomFamily } from "components/Organisation/Groups/state";
import { dedup } from "utils/utils";
import { getFolderChildNodes } from "business/customer/utils";
import { isDefined } from "utils/predicates";
import {
  GroupNodeAccess,
  UserNodeAccess,
} from "components/Organisation/Groups/types";

async function fetchAllOrganisationInvites(organisationId: string) {
  const response = await fetchSchemaWithToken(
    _OrganisationInvite.array(),
    `/api/user/${organisationId}/invite`,
    {
      method: "get",
      headers: {},
    },
  );
  return response;
}

export const allOrganisationInvitationsAtomFamily = atomFamily(
  (organisationId: string) =>
    atomFromFn<Promise<OrganisationInvite[]>>(async (get) => {
      get(refreshInvitationsAtom);
      return fetchAllOrganisationInvites(organisationId);
    }),
);

export type OrganisationInvite_WithUserProjects = OrganisationInvite & {
  userProjects: (UserNodeAccess | GroupNodeAccess)[];
};

export const admin_allOrganisationInvitationsSelectorFamily = atomFamily(
  (organisationId: string) =>
    atom<Promise<OrganisationInvite_WithUserProjects[]>>(async (get) => {
      get(refreshInvitationsAtom);

      const [invites, allNodes, groupsInNodeAndSubnode] = await Promise.all([
        get(allOrganisationInvitationsAtomFamily(organisationId)),
        get(
          admin_nodesInOrgSelectorFamily({
            organisationId,
          }),
        ),
        get(
          groupsInNodeAndSubnodesAtomFamily({
            organisationId,
            nodeId: organisationId,
          }),
        ),
      ]);

      return invites.map((invite) => {
        const { groupIds } = invite;
        const groupNodes = groupIds.flatMap((groupId) => {
          return groupsInNodeAndSubnode.filter((g) => g.group_id === groupId);
        });

        const groupNodesAccesses = groupNodes
          .flatMap((groupMembership) => {
            const node = allNodes.find((n) => n.id === groupMembership.node_id);
            if (!node) {
              return;
            }

            if (node.type === "project") {
              return {
                ...groupMembership,
                node,
              };
            }

            if (node.type === "folder") {
              return getFolderChildNodes(allNodes, node).map((n) => ({
                ...groupMembership,
                node: n,
              }));
            }
          })
          .filter(isDefined);

        const personalProjectAccesses = Object.entries(invite.teamAccess)
          .flatMap(([nodeId, access]) => {
            const node = allNodes.find((n) => n.id === nodeId);
            if (!node) {
              return;
            }

            if (node.type === "project") {
              return {
                user_id: "",
                node_id: nodeId,
                resource_name: access.role,
                node,
              };
            }

            if (node.type === "folder") {
              return getFolderChildNodes(allNodes, node).map((n) => ({
                user_id: "",
                node_id: nodeId,
                resource_name: access.role,
                node: n,
              }));
            }
          })
          .filter(isDefined);

        const uniqueUserNodes = dedup(
          [...personalProjectAccesses, ...groupNodesAccesses],
          (node) => node.node.id,
        );

        return {
          ...invite,
          userProjects: uniqueUserNodes,
        };
      }) as any;
    }),
);

export const refreshInvitationsAtom = atom<number>(0);

export const useRefreshInvitations = () => {
  const set = useSetAtom(refreshInvitationsAtom);
  return useCallback(() => set((v) => v + 1), [set]);
};

export const allCollaboratorsInNodeSelectorFamily = atomFamily(
  (nodeId: string | undefined) =>
    atomFromFn<Promise<CustomerPersona[]>>(async () => {
      if (!nodeId || nodeId.length === 0) return [];
      const response = await fetchSchemaWithToken(
        _CustomerPersona.array(),
        `/api/user/node/${nodeId}`,
        {
          method: "get",
          headers: {},
        },
      );
      return response;
    }),
);

const allCollaboratorsInNodeSortedSelectorFamily = atomFamily(
  (nodeId: string | undefined) =>
    atom<Promise<CustomerPersona[]>>(async (get) => {
      if (!nodeId) return [];
      const m = [...(await get(allCollaboratorsInNodeSelectorFamily(nodeId)))];
      m.sort((a, b) => {
        // NOTE: µ is compared to be after a-z so that collaborators are placed at the end.
        const r = (a.role ?? "µ").localeCompare(b.role ?? "µ");
        return r === 0 ? a.user_id.localeCompare(b.user_id) : r;
      });
      return m;
    }),
);

export const projectPersonasSelectorFamily = atomFamily(
  ({ projectNodeId }: { projectNodeId: string }) =>
    atom<Promise<CustomerPersona[]>>(async (get) => {
      const members = await get(
        allCollaboratorsInNodeSortedSelectorFamily(projectNodeId),
      );
      return members.filter((u) => {
        if (personaIsMember(u)) return true;
        return !!collaboratorRole(u, projectNodeId);
      });
    }),
);
