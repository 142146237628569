import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import SlideShowPlaceholder from "assets/slideshow-placeholder.jpg";

const Step1 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 1</TopHeaderAccent>
          <SubHeader>
            Precise inter-array cable optimization, routed around constraints
            and park structures
          </SubHeader>
          <ContentText>
            Leverage real-time tools to automate cable sizing and route planning
            to reduce cable cost.
          </ContentText>
          <ContentText>
            Quickly evaluate different routing options and understand the impact
            on project costs and timelines. Including optimal substation
            placement, and connect seamlessly to existing grid connections.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

const Step2 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 2</TopHeaderAccent>
          <SubHeader>Explore multiple designs in minutes, not days</SubHeader>
          <ContentText>
            Real-time analysis and user-friendly interface lets your quickly
            compare your various electrical design options, connection points or
            export cable designs. Compare electrical losses, cost and affect
            LCoE in an instant.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="left"
    />
  );
};

const Step3 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 3</TopHeaderAccent>
          <SubHeader>
            Streamline collaboration across disciplines with a unified platform
          </SubHeader>
          <ContentText>
            Work seamlessly with GIS, environmental, and financial teams in one
            platform to ensure your electrical designs align with broader
            project goals.
          </ContentText>
          <ContentText>
            Real-time updates and centralized data make interdisciplinary
            collaboration easier, reducing delays and miscommunications.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

export const ElectricalSteps = [Step1, Step2, Step3];
