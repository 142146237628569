import { atomFamily, atomFromFn } from "utils/jotai";
import { _CableType } from "../services/cableTypeService";
import { _CableLevel } from "types/cables";
import {
  getCableResourcesOnNode,
  getExportCableResourcesOnNode,
} from "services/libraryService";
import { NodeCableAccess } from "components/Organisation/Library/types";

export const cableResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeCableAccess[]>>(async () => {
      if (!nodeId) return [];
      return await getCableResourcesOnNode(nodeId).catch(() => []);
    }),
);

export const exportCableResourceWithAccessOnNodeState = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atomFromFn<Promise<NodeCableAccess[]>>(async () => {
      if (!nodeId) return [];
      return await getExportCableResourcesOnNode(nodeId).catch(() => []);
    }),
);
