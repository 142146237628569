import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { colors } from "../../styles/colors";
import { MenuItem } from "../General/Menu";
import { DotMenu, DotMenuIconSize } from "../General/MenuButton";
import BinIcon from "@icons/24/Bin.svg?react";
import MoveToFolderIcon from "@icons/14/MoveToFolder.svg";
import ShareIcon from "@icons/24/Share.svg?react";
import StarIcon from "@icons/24/Star.svg?react";
import InfoIcon from "@icons/24/Information.svg?react";
import PencilIcon from "@icons/24/Pencil.svg?react";
import { useCallback, useMemo } from "react";
import { followProjectsAtom } from "../../state/project";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import {
  memberInOrganisationSelectorFamily,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { Node } from "../../services/customerAPI";
import {
  OrganisationNodeCrud,
  topLevelNodeFromAllNodesInStateAtomFamily,
} from "./useOrganisationFolderCrud";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { moveFolderIdAtom } from "components/Organisation/MoveNodeModal/state";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export const FolderDotMenu = ({
  folderId,
  currentFolder,
  isPersonalFolder,
  nodes,
  crudNode,
  setIsEditingTitle,
  onChange,
  useArrowIcon,
  size,
}: {
  folderId: string;
  currentFolder: Node | undefined;
  isPersonalFolder: boolean;
  nodes: Node[];
  crudNode: OrganisationNodeCrud;
  setIsEditingTitle: (value: boolean) => void;
  onChange?: (open: boolean) => void;
  useArrowIcon?: boolean;
  size: DotMenuIconSize;
}) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const navigate = useNavigate();
  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const setMoveFolderId = useSetAtom(moveFolderIdAtom);
  const { showConfirm } = useConfirm();
  const toplevelNode = useAtomValue(
    topLevelNodeFromAllNodesInStateAtomFamily({
      organisationId,
      nodeId: folderId,
    }),
  );

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: folderId,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;
  const isNodeEditor = nodeAccess >= 1;

  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const { put: putFollow } = useFollowProjectCrud();

  const follows = useAtomValue(followProjectsAtom);
  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === folderId && f.follow),
    [follows, folderId],
  );

  const folderChildren = useMemo(
    () => nodes.filter((n) => n.parent_id === folderId),
    [folderId, nodes],
  );

  const deleteFolderCallback = useCallback(async () => {
    await showConfirm({
      title: "Delete folder",
      message: "Are you sure you want to delete this folder?",
      confirmButtonText: "Delete",
    }).then((confirmed) => {
      if (!confirmed) return;
      crudNode.remove(folderId);
      const to = toplevelNode?.id === folderId ? "" : currentFolder?.parent_id;

      navigate(`/organisation/${organisationId}/projects/${to}`);
    });
  }, [
    showConfirm,
    crudNode,
    folderId,
    toplevelNode?.id,
    currentFolder?.parent_id,
    navigate,
    organisationId,
  ]);

  const chosenProjectHasPersonalTopFolder =
    toplevelNode?.type === "personal_folder";

  return (
    <DotMenu useArrowIcon={useArrowIcon} onChange={onChange} size={size}>
      {isMemberInOrg && (
        <MenuItem
          name="Folder information"
          icon={<InfoIcon />}
          onClick={() => {
            setContent({
              type: "project",
              id: folderId,
            });
          }}
        />
      )}

      {isNodeAdmin && !chosenProjectHasPersonalTopFolder && (
        <MenuItem
          name="Share folder"
          icon={<ShareIcon />}
          onClick={() => {
            setContent({
              type: "project",
              id: folderId,
              meta: {
                openAddCollaborators: true,
              },
            });
          }}
        />
      )}
      {isNodeAdmin && !isPersonalFolder && (
        <MenuItem
          name="Move folder"
          icon={<MoveToFolderIcon />}
          onClick={() => {
            setMoveFolderId(folderId);
          }}
          fillOrStroke="fill"
        />
      )}
      <MenuItem
        disabled={isPersonalFolder || !isNodeEditor}
        name="Rename folder"
        icon={<PencilIcon />}
        onClick={() => {
          setIsEditingTitle(true);
        }}
      />
      <MenuItem
        name={isFollowed ? "Unstar folder" : "Star folder"}
        icon={
          <StarIcon
            stroke={isFollowed ? colors.surfaceBrand : colors.surfaceBrand}
            fill={isFollowed ? colors.surfaceBrand : "transparent"}
          />
        }
        onClick={() => {
          putFollow(folderId, !isFollowed);
        }}
      />

      <MenuItem
        name="Delete folder"
        disabled={folderChildren.length > 0 || !isNodeEditor}
        title={
          folderChildren.length > 0
            ? "Folder must be empty to delete"
            : undefined
        }
        icon={<BinIcon />}
        onClick={() => {
          deleteFolderCallback();
        }}
      />
    </DotMenu>
  );
};
