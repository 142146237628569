import { unwrap } from "jotai/utils";
import { useAtomValue } from "jotai";
import { loggedInUserAtom } from "../../state/user";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import ProgressIndicator from "./ProgressIndicator/ProgressIndicator";
import { ModalContainer } from "./style";
import TermsOfUse from "./TermsOfUse";
import Interests from "./UserInterests";
import { wizardStepState } from "./state";

function WizardInner() {
  const step = useAtomValue(wizardStepState);
  const currentUser = useAtomValue(loggedInUserAtom);

  return (
    <>
      <ProgressIndicator currentStep={step} />
      {step === 0 && <TermsOfUse />}
      {step === 1 && (
        <Interests
          currentInterests={currentUser?.interests ?? {}}
          currentReceiveNews={!!currentUser?.allow_interest_specific_news}
        />
      )}
    </>
  );
}

export default function TermsOfUseWizard() {
  const userData = useAtomValue(unwrap(loggedInUserAtom));

  if (!userData) return null;
  if (userData.interests && userData.accept_terms_of_use) return null;

  return (
    <FullScreenModal>
      <ModalContainer>
        <WizardInner />
      </ModalContainer>
    </FullScreenModal>
  );
}
