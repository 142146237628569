import { displayLabelPropertyName } from "@constants/canvas";
import {
  useAugmentFeaturesWithColorValues,
  useUpdateLayerLayout,
  useUpdateLayerStyle,
} from "business/style/hooks";
import { stylingLabelSelector } from "business/style/state";
import mapboxgl, { SymbolLayer } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { CableFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import {
  MIN_CABLE_VISIBLE_ZOOM,
  cableLayerId,
  cableSourceId,
  cableSymbolLayerId,
} from "./constants";
import {
  addLayer,
  getHiddenLargeLineClickLayer,
  removeCodepointsFromFeatures,
} from "./utils";
import { useAtomValue } from "jotai";
import { useAtomUnwrap } from "utils/jotai";
import { cableStyleAtom } from "business/style/feature/cable";
import AblySelectionHighlighter from "./AblySelectionHighlighter";

const cableSymbolLayer: SymbolLayer = {
  id: cableSymbolLayerId,
  source: cableSourceId,
  type: "symbol",
  layout: {
    "text-field": [
      "case",
      ["boolean", ["get", "redundancy"], false],
      "Rendundancy cable",
      ["concat", ["get", "powerLoad"], "MW"],
    ],
    "text-offset": [0, 0.75],
    "text-size": 10,
    "symbol-placement": "line",
  },
  filter: ["boolean", ["get", displayLabelPropertyName], true],
};

const hiddenClickLayer = getHiddenLargeLineClickLayer(
  cableLayerId,
  cableSourceId,
  MIN_CABLE_VISIBLE_ZOOM,
);

export const RenderCables = ({
  cables: features,
  map,
  minVisibleZoom = MIN_CABLE_VISIBLE_ZOOM,
  showSelectionHighlighter,
}: {
  cables: CableFeature[];
  map: mapboxgl.Map;
  minVisibleZoom?: number;
  showSelectionHighlighter?: boolean;
}) => {
  const featureIds = useMemo(() => features.map((f) => f.id), [features]);
  useEffect(() => {
    map.addSource(cableSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    addLayer(map, {
      id: cableLayerId,
      type: "line",
      source: cableSourceId,
      minzoom: minVisibleZoom,
      paint: {},
    });
    addLayer(map, cableSymbolLayer);
    addLayer(map, hiddenClickLayer);

    return () => {
      safeRemoveLayer(map, cableSymbolLayerId);
      safeRemoveLayer(map, cableLayerId);
      safeRemoveLayer(map, hiddenClickLayer.id);
      map.removeSource(cableSourceId);
    };
  }, [map, minVisibleZoom]);

  const paint = useAtomValue(cableStyleAtom)?.line;
  useUpdateLayerStyle(map, cableLayerId, paint);
  const layout = useAtomUnwrap(stylingLabelSelector("cables"));
  useUpdateLayerLayout(map, cableSymbolLayerId, layout?.layout);

  const augmented = useAugmentFeaturesWithColorValues("cables", features);

  useEffect(() => {
    const source = map.getSource(cableSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: removeCodepointsFromFeatures(augmented),
    });
  }, [map, augmented]);

  return (
    <AblySelectionHighlighter
      map={map}
      sourceId={cableSourceId}
      featureIds={featureIds}
      enabled={showSelectionHighlighter}
    />
  );
};
