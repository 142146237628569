import {
  CapexEntry,
  CashFlows,
  Contingency,
  CostConfiguration,
  Inflation,
  LCOE,
  LifeCycle,
  OpexEntry,
  _CostConfiguration,
  ComponentCost,
} from "../../../services/costService";
import { CostWithUnit } from "../../../types/financial";
import { atom } from "jotai";
import { useJotaiCallback } from "utils/jotai";

export const localCostConfigurationAtom = atom<CostConfiguration>(
  <CostConfiguration>{},
);

export const useLocalCostConfigurationCrud = () => {
  const updateProjectComponentCosts = useJotaiCallback(
    async (
      _,
      set,
      update: Record<string, ComponentCost>,
      type: "turbines" | "cables" | "exportCable",
    ) => {
      set(localCostConfigurationAtom, (cur) => {
        return _CostConfiguration.parse({
          ...cur,
          capex: {
            ...cur.capex,
            projectComponentCosts: {
              ...cur.capex.projectComponentCosts,
              [type]: {
                ...cur.capex.projectComponentCosts[type],
                ...update,
              },
            },
          },
        });
      });
    },
    [],
  );

  const replaceProjectComponentCosts = useJotaiCallback(
    async (
      _,
      set,
      replacement: Record<string, ComponentCost>,
      type: "turbines" | "cables" | "exportCable",
    ) => {
      set(localCostConfigurationAtom, (cur) => {
        return _CostConfiguration.parse({
          ...cur,
          capex: {
            ...cur.capex,
            projectComponentCosts: {
              ...cur.capex.projectComponentCosts,
              [type]: replacement,
            },
          },
        });
      });
    },
    [],
  );

  const deleteComponentCost = useJotaiCallback(
    async (_, set, id: string, type: "turbines" | "cables" | "exportCable") => {
      set(localCostConfigurationAtom, (cur) => {
        const { [id]: _, ...newTypeCosts } =
          cur.capex.projectComponentCosts[type];

        return {
          ...cur,
          capex: {
            ...cur.capex,
            projectComponentCosts: {
              ...cur.capex.projectComponentCosts,
              [type]: newTypeCosts,
            },
          },
        };
      });
    },
    [],
  );

  const updateFixedCapex = useJotaiCallback(
    async (_, set, update: Record<string, unknown>, key?: string) => {
      set(localCostConfigurationAtom, (cur) => {
        if (key) {
          return {
            ...cur,
            capex: {
              ...cur.capex,
              fixed: {
                ...cur.capex.fixed,
                [key]: {
                  ...(cur.capex.fixed as any)[key],
                  ...update,
                },
              },
            },
          };
        }

        return {
          ...cur,
          capex: {
            ...cur.capex,
            fixed: {
              ...cur.capex.fixed,
              ...update,
            },
          },
        };
      });
    },
    [],
  );

  const updateInstallationCapex = useJotaiCallback(
    async (_, set, update: Record<string, unknown>, key?: string) => {
      set(localCostConfigurationAtom, (cur) => {
        if (key) {
          return {
            ...cur,
            capex: {
              ...cur.capex,
              installation: {
                ...cur.capex.installation,
                [key]: {
                  ...(cur.capex.installation as any)[key],
                  ...update,
                },
              },
            },
          };
        }

        return {
          ...cur,
          capex: {
            ...cur.capex,
            installation: {
              ...cur.capex.installation,
              ...update,
            },
          },
        };
      });
    },
    [],
  );

  const updateCustomCapex = useJotaiCallback(
    async (_, set, update: CapexEntry) => {
      set(localCostConfigurationAtom, (cur) => {
        const match = cur.capex.custom.some((c) => c.id === update.id);

        if (match) {
          return {
            ...cur,
            capex: {
              ...cur.capex,
              custom: cur.capex.custom.map((c) =>
                c.id === update.id ? update : c,
              ),
            },
          };
        }

        return {
          ...cur,
          capex: {
            ...cur.capex,
            custom: [...cur.capex.custom, update],
          },
        };
      });
    },
    [],
  );

  const updateLCOE = useJotaiCallback(async (_, set, update: Partial<LCOE>) => {
    set(localCostConfigurationAtom, (cur) => {
      return {
        ...cur,
        lcoe: {
          ...cur.lcoe,
          ...update,
        },
      };
    });
  }, []);

  const updateCashFlows = useJotaiCallback(
    async (_, set, update: Partial<CashFlows>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          cashFlows: {
            ...cur.cashFlows,
            ...update,
          },
        };
      });
    },
    [],
  );

  const updateOPEX = useJotaiCallback(async (_, set, update: OpexEntry) => {
    set(localCostConfigurationAtom, (cur) => {
      const match = cur.opex.custom.some((c) => c.id === update.id);

      if (match) {
        return {
          ...cur,
          opex: {
            ...cur.opex,
            custom: cur.opex.custom.map((e) =>
              e.id === update.id ? update : e,
            ),
          },
        };
      }

      return {
        ...cur,
        opex: {
          ...cur.opex,
          custom: [...cur.opex.custom, update],
        },
      };
    });
  }, []);

  const updateDEVEX = useJotaiCallback(
    async (_, set, update: Partial<CostWithUnit>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          devex: {
            ...cur.devex,
            ...update,
          },
        };
      });
    },
    [],
  );

  const updateDECOM = useJotaiCallback(
    async (_, set, update: Partial<CostWithUnit>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          decom: {
            ...cur.decom,
            ...update,
          },
        };
      });
    },
    [],
  );

  const updateLifeCycle = useJotaiCallback(
    async (_, set, update: Partial<LifeCycle>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          lifeCycle: {
            ...cur.lifeCycle,
            ...update,
          },
        };
      });
    },
    [],
  );

  const updateInflation = useJotaiCallback(
    async (_, set, update: Partial<Inflation>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          inflation: {
            ...cur.inflation,
            ...update,
          },
        };
      });
    },
    [],
  );

  const updateContingency = useJotaiCallback(
    async (_, set, update: Partial<Contingency>) => {
      set(localCostConfigurationAtom, (cur) => {
        return {
          ...cur,
          contingency: {
            ...cur.contingency,
            ...update,
          },
        };
      });
    },
    [],
  );

  const deleteOPEX = useJotaiCallback(async (_, set, id: string) => {
    set(localCostConfigurationAtom, (cur) => {
      const match = cur.opex.custom.some((c) => c.id === id);

      if (match) {
        return {
          ...cur,
          opex: {
            ...cur.opex,
            custom: cur.opex.custom.filter((e) => e.id !== id),
          },
        };
      }

      return cur;
    });
  }, []);

  return {
    updateProjectComponentCosts,
    replaceProjectComponentCosts,
    deleteComponentCost,
    updateFixedCapex,
    updateInstallationCapex,
    updateCustomCapex,
    updateLCOE,
    updateDEVEX,
    updateOPEX,
    updateDECOM,
    updateCashFlows,
    deleteOPEX,
    updateLifeCycle,
    updateInflation,
    updateContingency,
  };
};
