import { costId } from "../amounts/costIds";
import { amountId } from "../amounts/amountIds";
import { getConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { atom } from "jotai";
import { CostType } from "services/costService";

import { Cost, unitToAmountUnit } from "types/financial";
import { atomFamily } from "utils/jotai";

export const otherCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const {
      capex: { custom },
    } = await get(getConfiguration(id));

    const category = CostType.Other;

    const customCosts: Cost[] = custom
      .filter((c) => c.category === category)
      .flatMap((custom) => ({
        ...custom,
        name: `${custom.name}`,
        id: costId({
          category: category,
          costId: custom.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[custom.unit],
          category: category,
        }),
      }));

    return customCosts;
  }),
);
