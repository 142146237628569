import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { SVGWrapper } from "@icons/svgUtils";
import Button from "components/General/Button";
import { Column, Row } from "components/General/Layout";
import {
  groupProjectsState,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import useGroupNodeAccessCrud from "components/Organisation/Groups/useGroupNodeAccessCrud";
import {
  ContentTableColumn,
  ContentTableRow,
  TableHeader,
  TabSubtitle,
  TextEllipsisWithTooltip,
} from "components/Organisation/OrganisationRightSide/style";
import React, { useState, useCallback, useRef } from "react";
import { UserAccessRole, _UserAccessRole } from "types/user";
import {
  AdminNodeAccessModalWrapper,
  NodeAccess,
} from "../../shared/NodeAccessModal";
import RemoveIcon from "@icons/24/Remove.svg";
import Folder from "@icons/14/Folder.svg?react";
import Earth from "@icons/14/Earth.svg?react";
import { GroupNodeAccessDropdown } from "../../shared/GroupNodeAccessDropdown";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { IconBtn } from "components/General/Icons";
import Admin_ChildNodesGlobe from "components/Organisation/OrganisationRightSide/content/Admin_ChildNodesGlobe";
import Tooltip from "components/General/Tooltip";
import { getNodeSelectorFamily } from "components/Projects/useOrganisationFolderCrud";
import { QuestionIconComp } from "components/AccessOverview/shared";
import { Anchor } from "components/General/Anchor";
import ProjectAccessOverviewModal from "components/AccessOverview/ProjectAccessOverviewModal";

export function GroupProjects({ groupId }: { groupId: string }) {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const projectsAccess = useAtomValue(
    groupProjectsState({
      organisationId,
      groupId,
    }),
  );
  const projectAccessRef = useRef<HTMLDivElement>(null);
  const [showProjectAccessModal, setProjectAccessModal] = useState(false);
  const groups = useAtomValue(
    organisationGroupsState({
      organisationId,
    }),
  );
  const group = groups.find((g) => g.id === groupId);
  const { addOrUpdate } = useGroupNodeAccessCrud();
  const [showAddProject, setShowAddProject] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState<number>(0);

  const onAddProjects = useCallback(
    async (nodes: NodeAccess[]) => {
      setSaveInProgress(nodes.length);
      setShowAddProject(false);
      try {
        await Promise.all(
          nodes.map((node) => {
            const role = _UserAccessRole.parse(node.resource_name);
            return addOrUpdate(groupId, node.node_id, role).then(() =>
              setSaveInProgress((cur) => cur - 1),
            );
          }),
        );
      } catch {
      } finally {
        setSaveInProgress(0);
      }
    },
    [addOrUpdate, groupId],
  );

  return (
    <Column
      style={{
        gap: "1.6rem",
        height: "100%",
      }}
    >
      <Column
        style={{
          gap: "1.2rem",
        }}
      >
        <Row
          style={{
            alignItems: "center",
          }}
        >
          <TabSubtitle>
            {`${group?.name ?? "This group"}`} has access to the projects below.
          </TabSubtitle>
          <div
            style={{
              marginLeft: "auto",
              position: "relative",
            }}
          >
            <Button
              text="Add projects"
              buttonType="secondary"
              onClick={() => setShowAddProject((cur) => !cur)}
              disabled={saveInProgress > 0}
            />
            {showAddProject && (
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  zIndex: 1,
                }}
              >
                <AdminNodeAccessModalWrapper
                  onSave={onAddProjects}
                  existingAccess={projectsAccess.map((p) => p.node_id)}
                />
              </div>
            )}
          </div>
        </Row>
      </Column>
      <Row
        style={{
          gap: 0,
        }}
      >
        <TableHeader
          style={{
            flex: "0 0 35%",
          }}
        >
          Project
        </TableHeader>
        <TableHeader
          ref={projectAccessRef}
          style={{
            flex: "0 0 40%",
            justifyContent: "center",
            paddingLeft: "1.2rem",
          }}
        >
          Access{" "}
          <QuestionIconComp
            onClick={(e) => {
              e.stopPropagation();
              setProjectAccessModal((cur) => !cur);
            }}
          />
        </TableHeader>
        {showProjectAccessModal && (
          <Anchor
            baseRef={projectAccessRef}
            floatPlace="topLeft"
            basePlace="bottomLeft"
          >
            <ProjectAccessOverviewModal
              onClose={() => setProjectAccessModal(false)}
            />
          </Anchor>
        )}
      </Row>
      <ContentTableColumn>
        {projectsAccess.map((m) => {
          return (
            <SingleNode
              key={m.node_id}
              groupId={groupId}
              nodeId={m.node_id}
              resourceName={_UserAccessRole.parse(m.resource_name)}
              organisationId={organisationId}
            />
          );
        })}
        {Array.from(
          {
            length: saveInProgress,
          },
          (_, index) => (
            <SkeletonBlock
              key={index}
              style={{
                height: "4rem",
                marginTop: "0.8rem",
              }}
            />
          ),
        )}
      </ContentTableColumn>
    </Column>
  );
}

function SingleNode({
  groupId,
  nodeId,
  resourceName,
  organisationId,
}: {
  groupId: string;
  nodeId: string;
  resourceName: UserAccessRole;
  organisationId: string;
}) {
  const { remove } = useGroupNodeAccessCrud();
  const node = useAtomValue(
    getNodeSelectorFamily({
      organisationId,
      nodeId,
    }),
  );

  const [saveInProgress, setSaveInProgress] = useState(false);

  if (!node) {
    return null;
  }

  const RowIcon = node.type === "project" ? Earth : Folder;

  return (
    <ContentTableRow
      disabled={saveInProgress}
      style={{
        cursor: "unset",
      }}
    >
      <Row
        style={{
          flex: "0 0 45%",
          overflow: "hidden",
        }}
      >
        <SVGWrapper size={1.4}>
          <RowIcon />
        </SVGWrapper>
        <TextEllipsisWithTooltip
          style={{
            margin: 0,
          }}
          text={node.name}
        />
        <Admin_ChildNodesGlobe node={node} organisationId={organisationId} />
      </Row>
      <Row
        style={{
          flex: "0 0 45%",
        }}
      >
        <GroupNodeAccessDropdown
          groupId={groupId}
          nodeId={nodeId}
          role={resourceName}
        />
      </Row>
      <Row
        style={{
          flex: "0 0 5%",
        }}
      >
        <Tooltip text="Remove access">
          <IconBtn
            onClick={(e) => {
              e.stopPropagation();
              if (
                !confirm(
                  "Are you sure you want to remove the access for this group?",
                )
              ) {
                return;
              }
              setSaveInProgress(true);
              remove(groupId, nodeId).finally(() => setSaveInProgress(false));
            }}
            size={"1.4rem"}
            disabled={saveInProgress}
          >
            <RemoveIcon />
          </IconBtn>
        </Tooltip>
      </Row>
    </ContentTableRow>
  );
}
