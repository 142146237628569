import { LayoutSpecification, PaintSpecification } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import {
  AnchorFeature,
  CableFeature,
  ParkFeature,
  TurbineFeature,
} from "types/feature";
import { COLORVALUE, LABELVALUE } from "./constants";
import { colorValuesSelectorFamily, stylingLabelSelector } from "./state";
import { Style } from "./types";
import { _AnchorColorKey } from "./feature/anchor";
import { _CableColorKey } from "./feature/cable";
import { _TurbineColorKey } from "./feature/turbine";
import { useAtomUnwrap } from "utils/jotai";

/**
 * Update the layer with paint.
 */
export const useUpdateLayerStyle = (
  map: mapboxgl.Map,
  layerId: string,
  style?: PaintSpecification,
) => {
  useEffect(() => {
    if (!style || !map.getLayer(layerId)) return;
    for (const [k, v] of Object.entries(style)) {
      map.setPaintProperty(layerId, k as keyof PaintSpecification, v);
    }
  }, [layerId, map, style]);
};

/**
 * Update the layer with layout.
 */
export const useUpdateLayerLayout = (
  map: mapboxgl.Map,
  layerId: string,
  layout?: LayoutSpecification,
) => {
  useEffect(() => {
    if (!layout || !map.getLayer(layerId)) return;
    for (const [k, v] of Object.entries(layout)) {
      map.setLayoutProperty(layerId, k as keyof LayoutSpecification, v);
    }
  }, [layerId, map, layout]);
};

/** Map {@Style["feature"]} to the feature type */
type FType = {
  turbines: TurbineFeature;
  parks: ParkFeature;
  anchors: AnchorFeature;
  cables: CableFeature;
};

/**
 * Augment the features of a type with the numerical values used for coloring
 * and the label string used for labels.
 */
export const useAugmentFeaturesWithColorValues = <F extends Style["feature"]>(
  featureType: F,
  features: FType[F][],
) => {
  const values = useAtomUnwrap(colorValuesSelectorFamily(featureType));
  const labels = useAtomUnwrap(stylingLabelSelector(featureType));
  return useMemo(
    () =>
      features.map((t) => {
        const properties = { ...t.properties };
        if (values?.values)
          properties[COLORVALUE] = values.values.get(t.id) ?? null;
        else delete properties[COLORVALUE];
        if (labels?.values)
          properties[LABELVALUE] = labels.values.get(t.id) ?? null;
        else delete properties[LABELVALUE];
        return { ...t, properties };
      }),
    [features, labels, values],
  );
};
