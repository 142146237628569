import { useAtomValue } from "jotai";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef, useState } from "react";
import { colors } from "styles/colors";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, LoadingState, SafeCard } from "./Base";
import {
  getWorkingTimeBreakdown,
  getWeatherWaitingTimeBreakdown,
  getNumberOfInstalledFeaturesPerType,
  getInstallationTypesWithFeaturesInPark,
} from "components/Installation/installation";
import Dropdown from "components/Dropdown/Dropdown";
import { Label } from "components/General/Form";
import { spaceMedium } from "styles/space";
import { InstallationType } from "state/jotai/windStatistics";
import { capitalize } from "utils/utils";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import {
  getInstallationTimeStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { FinanceId } from "finance/types";

export const InstallationCategoryDropdown = ({
  triggerId,
  installationType,
  setInstallationType,
}: {
  triggerId: FinanceId;
  installationType: InstallationType;
  setInstallationType: React.Dispatch<React.SetStateAction<InstallationType>>;
}) => {
  const installationTypes = useAtomValue(
    getInstallationTypesWithFeaturesInPark({ id: triggerId }),
  );

  return (
    <Label style={{ width: "30%", padding: spaceMedium }}>
      <p>Category</p>
      <Dropdown
        style={{ flex: 1 }}
        onChange={(e) =>
          setInstallationType(e.target.value as InstallationType)
        }
        value={installationType}
      >
        {installationTypes.map((c) => {
          return (
            <option key={c} value={c}>
              {`${capitalize(c)}`}
            </option>
          );
        })}
      </Dropdown>
    </Label>
  );
};

const InstallationTimeBreakdownChart = ({
  installationType,
  setInstallationType,
}: {
  installationType: InstallationType;
  setInstallationType: React.Dispatch<React.SetStateAction<InstallationType>>;
}) => {
  const { triggerId } = useDashboardContext();

  const numFeatures = useAtomValue(
    getNumberOfInstalledFeaturesPerType({
      id: triggerId,
      type: installationType,
    }),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationTimeStoppedReason(triggerId),
  );

  const installationTypes = useAtomValue(
    getInstallationTypesWithFeaturesInPark({ id: triggerId }),
  );

  useEffect(() => {
    if (installationTypes.length > 0) {
      setInstallationType(installationTypes[0]);
    }
  }, [installationTypes, setInstallationType]);

  if (numFeatures === 0) {
    return (
      <>
        <InstallationCategoryDropdown
          triggerId={triggerId}
          installationType={installationType}
          setInstallationType={setInstallationType}
        />
        <CenterContainer>
          <SimpleAlert
            text={`No ${installationType} ${installationType === InstallationType.Turbine ? "installed at site" : ""} in park. ${installationType === InstallationType.Turbine ? `Floating turbines are assembled at quayside and included in the floating foundation installation.` : ""}`}
            type={"info"}
          />
        </CenterContainer>
      </>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <>
      <InstallationCategoryDropdown
        triggerId={triggerId}
        installationType={installationType}
        setInstallationType={setInstallationType}
      />
      <InstallationTimeBreakdownChartInner
        installationType={installationType}
      />
    </>
  );
};

const InstallationTimeBreakdownChartInner = ({
  installationType,
}: {
  installationType: InstallationType;
}) => {
  const { triggerId } = useDashboardContext();

  const workingTimeBreakdown = useAtomValue(
    getWorkingTimeBreakdown({ id: triggerId, type: installationType }),
  );
  const waitingTimeBreakdown = useAtomValue(
    getWeatherWaitingTimeBreakdown({ id: triggerId, type: installationType }),
  );

  if (workingTimeBreakdown === undefined || waitingTimeBreakdown === undefined)
    return (
      <CenterContainer>
        <SimpleAlert
          text={`Installation analysis has failed, please check your input data.`}
          type={"error"}
        />
      </CenterContainer>
    );

  return (
    <InstallationTimeBreakdownPlot
      workingTimeBreakdown={workingTimeBreakdown}
      waitingTimeBreakdown={waitingTimeBreakdown}
    />
  );
};

const InstallationTimeBreakdownPlot = ({
  workingTimeBreakdown,
  waitingTimeBreakdown,
}: {
  workingTimeBreakdown: {
    id: string;
    totalDuration: number;
  }[];
  waitingTimeBreakdown: {
    id: string;
    totalWaitingTime: number;
  }[];
}) => {
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!graphRef.current) return;
    setIsLoaded(false);

    const workTrace = {
      x: workingTimeBreakdown.map((step) => step.totalDuration).reverse(),
      y: workingTimeBreakdown.map((step) => step.id).reverse(),
      name: "Working time",
      type: "bar" as const,
      orientation: "h" as const,
      marker: { color: colors.blue500 },
      hovertemplate:
        "%{y} has a total working time of %{x:,.1f} days<extra></extra>",
    };

    const waitTrace = {
      x: waitingTimeBreakdown.map((step) => step.totalWaitingTime).reverse(),
      y: waitingTimeBreakdown.map((step) => step.id).reverse(),
      name: "Weather waiting time (P50)",
      type: "bar" as const,
      orientation: "h" as const,
      marker: { color: colors.orange500 },
      hovertemplate:
        "%{y} has a total weather waiting time of %{x:,.1f} days<extra></extra>",
    };

    var layout = {
      autosize: true,
      height: 250,
      bargap: 0.05,
      font: { size: 10, family: "Open Sans" },
      margin: {
        l: 60,
        r: 30,
        b: 30,
        t: 30,
      },
      xaxis: { title: "Total duration [days]", fixedrange: true },
      yaxis: { title: "", fixedrange: true, automargin: true },
      barmode: "relative" as const,
      showlegend: true,
    };

    Plotly.newPlot(graphRef.current, [workTrace, waitTrace], layout, {
      responsive: true,
      modeBarButtons: [["toImage"]],
      displaylogo: false,
      toImageButtonOptions: { format: "svg" },
    }).then(() => setIsLoaded(true));
  }, [graphRef, workingTimeBreakdown, waitingTimeBreakdown]);

  return (
    <div style={{ flex: 1 }} ref={graphRef}>
      {isLoaded ?? <LoadingState />}
    </div>
  );
};

export const InstallationTimeBreakdownWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  const [installationType, setInstallationType] = useState<InstallationType>(
    InstallationType.Turbine,
  );

  return (
    <SafeCard
      title="Installation time breakdown chart"
      id="Installation time breakdown chart"
      resetKeys={errorBoundaryResetKeys}
    >
      <InstallationTimeBreakdownChart
        installationType={installationType}
        setInstallationType={setInstallationType}
      />
    </SafeCard>
  );
};
