import { parkIdAtom } from "state/pathParams";
import React, { useMemo } from "react";
import { MenuItem } from "../../../General/Menu";
import { getParkChildrenTypes } from "../../../ProjectElementsV2/utils";
import AnchorIcon from "@icons/24/Anchor.svg?react";
import SubstationIcon from "@icons/24/Substation.svg?react";
import TurbineIcon from "@icons/24/Turbine.svg?react";
import BuildZone from "@icons/24/AddCircle.svg?react";
import BuildZoneForbidden from "@icons/24/SubtractCircle.svg?react";
import Cabling from "@icons/24/Cabling-2.svg?react";
import ExternalCable from "@icons/24/ExportCable.svg?react";
import CableCorridor from "@icons/24/CableCorridor.svg?react";
import useSelectionInMap from "../../../../hooks/useSelectionInMap";
import useNavigateToPark from "../../../../hooks/useNavigateToPark";
import { useAtomValue } from "jotai";
import { parkChildrenFamily } from "state/jotai/park";
import ElementsIcon from "@icons/24/Elements.svg?react";

const childrenToReadableNameAndIcon: Record<
  string,
  {
    name: string;
    icon: React.ReactElement;
  }
> = {
  subAreas: {
    name: "Sub areas",
    icon: <BuildZone />,
  },
  exclusionZones: {
    name: "Exclusion zones",
    icon: <BuildZoneForbidden />,
  },
  exportCables: {
    name: "Export cables",
    icon: <ExternalCable />,
  },
  internalCabling: {
    name: "Internal cables",
    icon: <Cabling />,
  },
  substations: {
    name: "Substations",
    icon: <SubstationIcon />,
  },
  cableCorridors: {
    name: "Cable corridors",
    icon: <CableCorridor />,
  },
  turbines: {
    name: "Turbines",
    icon: <TurbineIcon />,
  },
  anchoring: {
    name: "Anchors",
    icon: <AnchorIcon />,
  },
};

const SelectAllParkElementOfTypeMenuItem = ({
  closeMenu,
  selectedParkId,
}: {
  closeMenu(): void;
  selectedParkId: string;
}) => {
  const parkId = useAtomValue(parkIdAtom);
  const { navigateToPark } = useNavigateToPark();
  const { setCurrentSelectionArray } = useSelectionInMap();
  const parkChildren = useAtomValue(
    parkChildrenFamily({
      parkId: selectedParkId,
      branchId: undefined,
    }),
  );
  const children = useMemo(
    () => getParkChildrenTypes(parkChildren),
    [parkChildren],
  );

  const typesToShow = useMemo(
    () =>
      Object.keys(children).filter(
        (k) =>
          childrenToReadableNameAndIcon[k] && (children as any)[k].length !== 0,
      ),
    [children],
  );

  if (typesToShow.length === 0) return null;

  return (
    <MenuItem name="Select park elements of type" icon={<ElementsIcon />}>
      {typesToShow.map((k) => (
        <MenuItem
          key={k}
          name={childrenToReadableNameAndIcon[k].name}
          icon={childrenToReadableNameAndIcon[k].icon}
          stopPropagationOnClick={false}
          onClick={() => {
            if (parkId !== selectedParkId) {
              navigateToPark(selectedParkId);
            }

            setCurrentSelectionArray(
              (children as any)[k].map((f: any) => f.id),
            );
            closeMenu();
          }}
        />
      ))}
    </MenuItem>
  );
};

export default SelectAllParkElementOfTypeMenuItem;
