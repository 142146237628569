import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { useState } from "react";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { spaceLarge, spaceMedium } from "styles/space";
import Close from "@icons/24/Close.svg?react";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { HeaderTitle } from "components/RightSide/InfoModal/style";
import { SkeletonBlock } from "components/Loading/Skeleton";
import {
  CableType,
  ExportCableVoltageType,
  ExportSystemType,
  _ExportCableVoltageType,
} from "services/cableTypeService";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";
import { CostUnit } from "types/financial";
import {
  EXPORT_AMPACITY_DEFAULT,
  EXPORT_CAPACITANCE_DEFAULT,
  EXPORT_REACTANCE_DEFAULT,
  EXPORT_RESISTANCE_DEFAULT,
  EXPORT_VOLTAGE_DEFAULT,
  EXPORT_LAMBDA1_DEFAULT,
  EXPORT_LAMBDA2_DEFAULT,
  EXPORT_DIELECTRIC_LOSS_FACTOR_DEFAULT,
  EXPORT_USAGE_DEFAULT,
} from "@constants/cabling";
import { TextInput } from "components/General/Input";
import useTextInput from "hooks/useTextInput";
import { useAtomValue } from "jotai";
import { isOnshoreAtom } from "state/onshore";

const WizardContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  height: fit-content;
  gap: 1.2rem;
  background: white;
`;

export const ExportCableWizard = ({
  close,
  create,
  setLoading,
}: {
  close: () => void;
  setLoading: (v: boolean) => void;
  create: (exportCable: Partial<CableType>, name: string) => Promise<any>;
}) => {
  const [status, setStatus] = useState<"loading" | null>(null);
  const [selectedType, setSelectedType] = useState<ExportSystemType>(
    ExportSystemType.HVAC,
  );
  const [nameValue, onNameValueChange] = useTextInput("New export cable");

  const onshore = useAtomValue(isOnshoreAtom);

  return (
    <WizardContainer>
      <Row style={{ alignItems: "center" }}>
        <HeaderTitle>Create export cable</HeaderTitle>
        <IconBtn
          size="1.4rem"
          onClick={close}
          style={{ marginLeft: "auto" }}
          disabled={status === "loading"}
        >
          <Close />
        </IconBtn>
      </Row>
      <Label>
        <p>Name</p>
        <TextInput autoFocus value={nameValue} onChange={onNameValueChange} />
      </Label>
      <Label>
        <p>Cable type</p>
        <Dropdown
          id="export-cable-type-options"
          value={selectedType}
          disabled={onshore}
          onChange={(e) => {
            setSelectedType(e.target.value as ExportSystemType);
          }}
        >
          <option value={ExportSystemType.HVAC}>AC</option>
          <option value={ExportSystemType.HVDC}>DC</option>
        </Dropdown>
      </Label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "baseline",
          gap: spaceMedium,
          paddingTop: spaceLarge,
        }}
      >
        <Button
          disabled={status === "loading"}
          style={{ alignSelf: "end" }}
          text="Create"
          onClick={() => {
            setLoading(true);
            setStatus("loading");
            const exportCable = {
              name: nameValue,
              voltage: onshore
                ? ExportCableVoltageType.kV110
                : EXPORT_VOLTAGE_DEFAULT[selectedType],
              resistance: EXPORT_RESISTANCE_DEFAULT[selectedType],
              reactance: EXPORT_REACTANCE_DEFAULT[selectedType],
              capacitance: EXPORT_CAPACITANCE_DEFAULT[selectedType],
              ampacity: EXPORT_AMPACITY_DEFAULT[selectedType],
              cost: DefaultCosts.exportCable.exportCable[
                CostUnit.millionEuroPerKM
              ],
              costUnit: CostUnit.millionEuroPerKM,
              exportCableType: selectedType,
              lambda1: EXPORT_LAMBDA1_DEFAULT[selectedType],
              lambda2: EXPORT_LAMBDA2_DEFAULT[selectedType],
              dielectricLossFactor:
                EXPORT_DIELECTRIC_LOSS_FACTOR_DEFAULT[selectedType],
              exportCableUsage: EXPORT_USAGE_DEFAULT[selectedType],
            };
            create(exportCable, nameValue).then(() => {
              setStatus(null);
              setLoading(false);
            });
          }}
        />
      </div>
      {status === "loading" && <SkeletonBlock style={{ height: "2rem" }} />}
    </WizardContainer>
  );
};
