import { Map } from "mapbox-gl";
import { getBearingFromTwoPoints, insideBounds } from "../../utils/geometry";
import * as turf from "@turf/turf";
import { BBOX } from "utils/geojson/validate";

const MAP_POPUP_PLACEMENT_BUFFER = 0.45;

export const getPopupPlacementMultiFeature = (
  featureCenterPosition: number[],
  map?: Map,
) =>
  getPopupPlacement(
    { lng: featureCenterPosition[0], lat: featureCenterPosition[1] },
    map,
  );

export const getPopupPlacement = (
  featureCenterPosition: {
    lng: number;
    lat: number;
  },
  map?: Map,
) => {
  if (!map) return featureCenterPosition;
  const mapBounds = (map.getBounds()?.toArray().flat() ?? [0, 0, 1, 1]) as BBOX;
  const featureTopCenterArray = [
    featureCenterPosition.lng,
    featureCenterPosition.lat,
  ] as [number, number];
  const insideMapBounds = insideBounds(featureTopCenterArray, mapBounds);
  if (insideMapBounds || map.isMoving()) return featureCenterPosition;

  const mapCenter = map.getCenter().toArray() as [number, number];
  const bearing = getBearingFromTwoPoints(mapCenter, featureTopCenterArray);

  const minDistance =
    Math.min(
      turf.distance(
        turf.point([mapBounds[0], mapBounds[1]]),
        turf.point([mapBounds[2], mapBounds[1]]),
        { units: "kilometers" },
      ),
      turf.distance(
        turf.point([mapBounds[0], mapBounds[1]]),
        turf.point([mapBounds[0], mapBounds[3]]),
        { units: "kilometers" },
      ),
    ) * MAP_POPUP_PLACEMENT_BUFFER;
  const movedPoint = turf.transformTranslate(
    turf.point([mapCenter[0], mapCenter[1]]),
    minDistance,
    bearing,
  );
  return {
    lng: movedPoint.geometry.coordinates[0],
    lat: movedPoint.geometry.coordinates[1],
  };
};
