import { useAtomValue } from "jotai";
import React from "react";
import { isInChecklyMode } from "utils/utils";
import { loggedInUserIsCheckly } from "state/user";

const DontRenderWhenCheckly = ({ children }: React.PropsWithChildren) => {
  const isCheckly = isInChecklyMode();
  const isChecklyUser = useAtomValue(loggedInUserIsCheckly);

  if (isCheckly || isChecklyUser) {
    return null;
  }

  return children;
};

export default DontRenderWhenCheckly;
