import { useAtom, useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import { Mixpanel } from "mixpanel";
import CoordinatesAltIcon from "@icons/24/CoordinatesAlt.svg";
import UploadIcon from "@icons/24/Upload.svg";
import { Column, Row } from "components/General/Layout";
import Button from "components/General/Button";
import SplitButton from "components/General/SplitButton";
import DataPackageIcon from "@icons/24/DataPackage.svg";
import { SearchInput } from "components/General/Input";
import { MenuItem } from "components/General/Menu";
import useBooleanState from "hooks/useBooleanState";
import { typography } from "styles/typography";
import { colors } from "styles/colors";
import { spacing1, spacing4 } from "styles/space";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DataLibraryNewEntry } from "../DataLibraryTab.style";
import { UploadOrganisationDataLayerModalType } from "../modals/UploadOrganisationLayerModal";
import { UploadOrganisationLayerFromCoordinatesModalType } from "../modals/UploadOrganisationLayerFromCoordinatesModal";
import { useDataLibraryLayersCrud } from "../useDataLibraryLayersCrud";
import {
  allDataLayerSearchInputAtom,
  organisationLibraryLayersAtomFamily,
  selectedOrganisationLayerIdsSelector,
} from "../state";
import { SelectedLayersWrapper } from "../shared";
import DataTable from "./DataTable";
import AddToPackageFrame from "./AddToPackageFrame";
import { useSetAtom } from "jotai";
import LayersIcon from "@icons/24/Layers.svg";
import { Anchor } from "components/General/Anchor";

const AddDataSplitButton = () => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  return (
    <SplitButton
      buttonType="secondary"
      text="New"
      icon={<LayersIcon />}
      onClick={() => {
        Mixpanel.track_old("Add library layer click DEFAULT", {});
        setMidScreenModalTypeOpen({
          modalType: UploadOrganisationDataLayerModalType,
        });
      }}
    >
      <MenuItem
        name="From file"
        defaultButton={true}
        onClick={() => {
          Mixpanel.track_old("Add library layer click From file", {});
          setMidScreenModalTypeOpen({
            modalType: UploadOrganisationDataLayerModalType,
          });
        }}
        icon={<UploadIcon />}
      />
      {}
      {}
      <MenuItem
        name="From coordinates"
        onClick={() => {
          Mixpanel.track_old("Add library layer click From coordinates", {});
          setMidScreenModalTypeOpen({
            modalType: UploadOrganisationLayerFromCoordinatesModalType,
          });
        }}
        icon={<CoordinatesAltIcon />}
      />
      {}
      {}
    </SplitButton>
  );
};

const DataSectionInner = ({
  organisationId,
  searchValue,
}: {
  organisationId: string;
  searchValue: string;
}) => {
  const layers = useAtomValue(
    organisationLibraryLayersAtomFamily({
      organisationId,
    }),
  );

  const fuse = useMemo(
    () =>
      new Fuse(layers, {
        keys: ["name", "description"],
        includeScore: true,
        threshold: 0.3,
      }),
    [layers],
  );

  const searchResults = useMemo(() => {
    if (searchValue.length === 0) return layers;
    return fuse.search(searchValue).map((result) => result.item);
  }, [fuse, layers, searchValue]);

  if (layers.length > 0) {
    return <DataTable layers={searchResults} allLayers={layers} />;
  }

  return (
    <div
      style={{
        width: "25rem",
      }}
    >
      <DataLibraryNewEntry
        icon={<UploadIcon />}
        title="Upload files"
        text="Uploaded data will appear here"
        actionButton={<AddDataSplitButton />}
      />
    </div>
  );
};

const SearchInputStyler = styled.div`
  cursor: pointer;

  input {
    width: 16px;
    transition: width 0.1s linear;

    &:focus,
    &:not([value=""]) {
      width: 40rem;
    }
  }
`;

const AllDataSection = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [selectedLayerIds, setSelectedLayerIds] = useAtom(
    selectedOrganisationLayerIdsSelector({
      organisationId,
    }),
  );
  const { creatingNewPackage } = useDataLibraryLayersCrud();

  const [isInFocus, toggleIsInFocus] = useBooleanState(false);
  const [searchValue, setSearchValue] = useAtom(allDataLayerSearchInputAtom);

  return (
    <Column
      style={{
        overflowY: "hidden",
        flexGrow: 1,
        height: "100%",
        gap: 0,
      }}
    >
      <Row
        alignCenter
        style={{
          justifyContent: "space-between",
        }}
      >
        <h3>All GIS layers</h3>
        <div
          style={{
            height: "6rem",
            display: "flex",
            alignItems: "center",
            paddingLeft: spacing1,
            gap: "1.2rem",
          }}
        >
          <SearchInputStyler>
            <SearchInput
              placeholder="Search"
              value={searchValue}
              onFocus={toggleIsInFocus}
              onBlur={toggleIsInFocus}
              onChange={(e) => setSearchValue(e.target.value)}
              onClear={() => setSearchValue("")}
              style={{
                ...(!isInFocus &&
                  searchValue === "" && {
                    paddingLeft: spacing4,
                  }),
              }}
            />
          </SearchInputStyler>
          <AddDataSplitButton />
        </div>
      </Row>
      <React.Suspense
        fallback={
          <SkeletonBlock
            style={{
              width: "100%",
              height: "30rem",
            }}
          />
        }
      >
        <DataSectionInner
          organisationId={organisationId}
          searchValue={searchValue}
        />

        <SelectedLayersWrapper>
          <p
            style={{
              ...typography.caption,
              color: colors.textSecondary,
            }}
          >
            {selectedLayerIds.length} selected
          </p>
          <Button
            buttonType="text"
            onClick={() => setSelectedLayerIds([])}
            text="Cancel"
            disabled={selectedLayerIds.length === 0}
          />
          <Button
            ref={buttonRef}
            text="Add to package"
            buttonType="primary"
            onClick={() => setIsOpen(!isOpen)}
            icon={<DataPackageIcon />}
            disabled={creatingNewPackage || selectedLayerIds.length === 0}
          />
          {isOpen && (
            <Anchor
              baseRef={buttonRef}
              floatPlace="bottom" // Change to topLeft to appear above
              offset={[-32, -16]}
            >
              <AddToPackageFrame
                layerIds={selectedLayerIds}
                buttonRef={buttonRef}
                setIsOpen={setIsOpen}
              />
            </Anchor>
          )}
        </SelectedLayersWrapper>
      </React.Suspense>
    </Column>
  );
};

export default AllDataSection;
