import { useAtom } from "jotai";
import {
  branchIdAtom,
  organisationIdAtom,
  parkIdAtom,
  projectIdAtom,
  nodeIdAtom,
  pathParamsSelector_DontUseThisDirectly,
} from "state/pathParams";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { SetterOrUpdater } from "types/utils";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import WebFont from "webfontloader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getCompanyNameFromEmail,
  getTokenAtom,
  loggedInUserIsCheckly,
  loggedInUserIsInternalSelector,
  loggedInUserAtom,
  tokenAtom,
  userNodeAccessNameSelectorFamily,
} from "./state/user";
import Toast from "./components/Toast/Toast";
import UserProfileSettingsModal, {
  UserProfileModalType,
} from "./components/UserProfileModal/UserProfileModal";
import RenameModals from "./components/RenameModal/RenameModals";
import QgisPluginReadme from "./components/QgisPluginReadme/QgisPluginReadme";
import ArcgisPluginReadme from "./components/ArcgisPluginReadme/ArcgisPluginReadme";
import { RedirectToPublicModeApp } from "./PublicMap/PublicMap";
import { Mixpanel } from "./mixpanel";
import TrackNavigation from "./hooks/TrackNavigation";
import BufferModal from "./components/BufferModal/BufferModal";
import Design from "./components/Design/DesignWrapper";
import FilterExternalDataLayers from "./components/FilterExternalDataLayers/FilterExternalDataLayers";
import * as SentryR from "@sentry/react";
import { isInChecklyMode } from "./utils/utils";
import {
  useForceFetchNewTokenOnMount,
  useRefreshTokenBeforeExpiration,
} from "./hooks/useRefreshTokenBeforeExpiration";
import { setAccessTokenGlobal } from "./state/global";
import { ParkDesignV2 } from "./components/ParkDesign/ParkDesignV2";
import SelectCorrectBranch from "./components/SelectCorrectBranch/SelectCorrectBranch";
import CompareParksModal from "./components/CompareParksModal/CompareParksModal";
import { midScreenModalTypeOpenAtom } from "./state/modal";
import CopyCollectionModal from "./components/CopyCollectionModal/CopyCollectionModal";
import { LoggedInUser } from "./types/user";
import ReloadForNewVersionModal from "./components/ReloadForNewVersionModal/ReloadForNewVersionModal";
import FrontEndVersionPoller from "./components/FrontendVersionPoller/FrontEndVersionPoller";
import GPUCheck from "./components/GPUCheck/GPUCheck";
import ProjectConfigurationModal from "./components/SettingsV2/ProjectConfiguration/ProjectConfigurationModal";
import FeatureSettingsModal from "./components/SettingsV2/FeatureSettings/FeatureSettingsModal";
import PublicModeModal from "./components/SettingsV2/PublicModeSettings/PublicModeModal";
import TermsOfUseWizard from "./components/TermsOfUseWizard/TermsOfUseWizard";
import UploadModal from "./components/UploadModal/UploadModal";
import OrganisationPage, {
  CustomerBody,
  CustomerBodyPlaceholder,
  OrganisationBody,
  OrganisationBodyWrapperOldRedirect,
} from "./components/Organisation/OrganisationPage";
import { DashboardModal } from "./components/Dashboard/Dashboard";
import AddLayerSourceInternalModal from "./components/AddLayerSourceInternalModal/AddLayerSourceInternalModal";
import * as Sentry from "@sentry/browser";
import { TopRightModeActiveAtom } from "./components/RightSide/InfoModal/ProjectFeatureInfoModal/state";
import usePrevious from "./hooks/usePrevious";
import { lowerRightMenuActiveModeAtom } from "./state/layer";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import useRefreshOnTabFocusAfterDormancy from "hooks/useRefreshOnTabFocusAfterDormancy";
import NoOrganisationPage from "components/Organisation/NoOrganisationPage";
import KeyboardShortcutsModal from "components/LearnVind/KeyboardShortcutsModal";
import VideoModal from "components/LearnVind/VideoModal";
import DownloadCustomCRSModal from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { AddCustomCRSModal } from "components/CustomCRSDropdown/CustomCRSDropdown";
import ComponentsPreview from "components/ComponentsPreview/ComponentsPreviewModal";
import ArchiveProjectAccess from "components/Design/ArchiveProjectVersion/ArchiveProjectAccess";
import ArchiveProjectVersion from "components/Design/ArchiveProjectVersion/lambdaRender/ArchiveProjectVersion";
import { useAtomValue, useSetAtom } from "jotai";
import { DesignToolMode } from "types/map";
import {
  featureFlag,
  FeatureFlagSync,
  useUrlFlagValue,
} from "components/General/FeatureFlag";
import ProcurementCostsModal from "components/Organisation/Library/financial/ProcurementCosts/ProcurementCostsModal";
import { OrganisationAblyProvider } from "components/Ably/OrganisationAblyWrapper";
import { ProjectNodeAblyProvider } from "components/Ably/ProjectNodeAblyProvider";
import DeleteTurbineModal from "components/NotificationSystem/ReplaceTurbine/DeleteTurbineModal";
import ExtractMapDataModal from "components/ExtractMapDataModal/ExtractMapDataModal";
import FullScreenLoader from "components/FullScreenLoader/FullScreenLoader";
import { useUserAccessState } from "components/Projects/useUserAccessState";
import { useUserLibraryAccessState } from "components/Projects/useUserLibraryAccessState";
import { organisationsState } from "components/Organisation/state";
import DuplicateComponentModal from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { useLocalStorage } from "hooks/useBrowserStorage";
import Library from "components/Organisation/Library/Library";
import MembersPage from "components/Organisation/Members/MembersPage";
import { LearnVindGrid } from "components/LearnVind/LearnVindGrid";
import Portfolio from "components/Organisation/Portfolio/Portfolio";
import Integration from "components/Organisation/Integration/IntegrationSettings";
import TurbineTab from "components/Organisation/Library/turbine/TurbineTab";
import FoundationTab from "components/Organisation/Library/foundation/FoundationTab";
import CableTab from "components/Organisation/Library/cable/CableTab";
import ExportCableTab from "components/Organisation/Library/exportcable/ExportcableTab";
import AnalysisTab from "components/Organisation/Library/analysis/AnalysisTab";
import FinancialTab from "components/Organisation/Library/financial/FinancialTab";
import DataLibraryTab from "components/Organisation/Library/dataLibrary/DataLibraryTab";
import SubstationTab from "components/Organisation/Library/substation/SubstationTab";
import OnboardingSlideShow from "components/OnboardingSlideShow/OnboardingSlideShow";
import OrganisationIsDeactivated from "./components/Organisation/OrganisationIsDeactivated";

// Remove !!"" for debugging
if (import.meta.env?.MODE !== "production" && !!"") {
  import("debug/jotai");
}

export const useSetPathParams = () => {
  const params = useParams();
  const setParkId = useSetAtom(parkIdAtom);
  const setBranchId = useSetAtom(branchIdAtom);
  const setProjectId = useSetAtom(projectIdAtom);
  const setOrganisationId = useSetAtom(organisationIdAtom);
  const setNodeId = useSetAtom(nodeIdAtom);

  useEffect(() => {
    setParkId(params?.parkId);
  }, [params?.parkId, setParkId]);

  useEffect(() => {
    setBranchId(params?.branchId);
  }, [params?.branchId, setBranchId]);

  useEffect(() => {
    setProjectId(params?.projectId);
  }, [params?.projectId, setProjectId]);

  useEffect(() => {
    setOrganisationId(params?.organisationId);
  }, [params?.organisationId, setOrganisationId]);

  useEffect(() => {
    setNodeId(params?.nodeId);
  }, [params?.nodeId, setNodeId]);
};

const BootIntercom = () => {
  const userData = useAtomValue(loggedInUserAtom);
  const [intercomInitialized, setIntercomInitialized] = useState(false);

  useEffect(() => {
    if (
      userData &&
      userData.email !== "checkly@vind.ai" &&
      userData.hmac &&
      !intercomInitialized
    ) {
      window.Intercom("boot", {
        app_id: "vt2a9v5k",
        custom_launcher_selector: "#help_icon_link",
        user_id: userData.user_id,
        user_hash: userData.hmac,
        email: userData.email,
      });
      setIntercomInitialized(true);
    }
  }, [intercomInitialized, userData]);

  return null;
};

const SetMixpanelData = () => {
  const userData = useAtomValue(loggedInUserAtom);
  const isInternal = useAtomValue(loggedInUserIsInternalSelector);
  const isCheckly = useAtomValue(loggedInUserIsCheckly);
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false);

  useEffect(() => {
    if (!userData || mixpanelInitialized) {
      return;
    }

    if (isCheckly) {
      Mixpanel.stop_tracking();
      return;
    }

    const userCompany = getCompanyNameFromEmail(userData.email);

    Mixpanel.identify(userData.user_id);
    if (userCompany) {
      Mixpanel.people.set_once({
        company: userCompany,
      });
    }
    if (userData.email) {
      Mixpanel.people.set_once({
        $email: userData.email,
      });
    }
    const interests = userData.interests;
    Mixpanel.people.set({
      interests: interests ?? {},
      allow_interest_specific_news:
        userData.allow_interest_specific_news ?? false,
      allow_news: userData.allow_news ?? false,
    });
    Mixpanel.track_old("App loaded", {
      internal: isInternal,
    });
    setMixpanelInitialized(true);
  }, [isCheckly, isInternal, mixpanelInitialized, userData]);

  return null;
};

const SetSentryData = () => {
  const userData = useAtomValue(loggedInUserAtom);
  const isInternal = useAtomValue(loggedInUserIsInternalSelector);
  const { organisationId, projectId, branchId } = useParams();
  const userProjectAccess = useAtomValue(
    userNodeAccessNameSelectorFamily({
      nodeId: projectId,
    }),
  );
  const [sentryInitialized, setSentryInitialized] = useState(false);

  useEffect(() => {
    if (!userData || sentryInitialized) {
      return;
    }

    const userCompany = getCompanyNameFromEmail(userData.email);

    if (isInternal) {
      Sentry.setTag("internal", userData.email);
    }

    if (userCompany) {
      Sentry.setTag("vind.company", userCompany);
    }
    setSentryInitialized(true);
  }, [isInternal, sentryInitialized, userData]);

  useEffect(() => {
    Sentry.setTag("vind.organisationId", organisationId);
    Sentry.setTag("vind.projectId", projectId);
    Sentry.setTag("vind.branchId", branchId);
    Sentry.setTag("vind.projectAccess", userProjectAccess);
  }, [branchId, organisationId, projectId, userProjectAccess]);

  return null;
};

const TrackStateChangesInner = () => {
  const pathParams = useAtomValue(pathParamsSelector_DontUseThisDirectly);

  // track mode changes in the left menu
  const [leftMenuModeActive] = useDrawMode();
  const previousLeft = usePrevious(leftMenuModeActive);
  useEffect(() => {
    if (leftMenuModeActive && leftMenuModeActive !== previousLeft) {
      Mixpanel.track_old("Left menu mode activated", {
        mode: leftMenuModeActive,
        ...pathParams,
      });
    }
  }, [leftMenuModeActive, pathParams, previousLeft]);

  // track mode changes in the top right menu
  const topRightMenuModeActive = useAtomValue(TopRightModeActiveAtom);
  const previousTopRight = usePrevious(topRightMenuModeActive);
  useEffect(() => {
    if (topRightMenuModeActive && previousTopRight !== topRightMenuModeActive) {
      Mixpanel.track_old("Top right mode activated", {
        mode: topRightMenuModeActive,
        ...pathParams,
      });
    }
  }, [pathParams, previousTopRight, topRightMenuModeActive]);

  // track mode changes in the lower right menu
  const lowerRightMenuModeActive = useAtomValue(lowerRightMenuActiveModeAtom);
  const previousLowerRight = usePrevious(lowerRightMenuModeActive);
  useEffect(() => {
    if (
      lowerRightMenuModeActive &&
      previousLowerRight !== lowerRightMenuModeActive
    ) {
      Mixpanel.track_old("Lower right mode activated", {
        mode: lowerRightMenuModeActive,
        ...pathParams,
      });
    }
  }, [lowerRightMenuModeActive, pathParams, previousLowerRight]);

  return null;
};

const TrackStateChanges = () => {
  const projectId = useAtomValue(projectIdAtom);
  if (!projectId) return null;
  return <TrackStateChangesInner />;
};

const DesignSystem = React.lazy(() => import("./components/DesignSystem"));
const PermissionsPage = React.lazy(
  () => import("./components/PermissionsPage"),
);
const OrgInternalPage = React.lazy(
  () => import("./components/OrgToolingInternal"),
);
const PageAPI = React.lazy(() => import("./components/PageAPI"));

const InternalRoute = () => {
  const userIsInternal = useAtomValue(loggedInUserIsInternalSelector);
  const navigate = useNavigate();
  const ref = useRef<NodeJS.Timeout>();
  if (ref.current) clearTimeout(ref.current);
  if (!userIsInternal) {
    ref.current = setTimeout(() => navigate("/"), 100);
    return null;
  }
  return <Outlet />;
};

const ProtectedRoute = ({
  token,
  setToken,
}: {
  token: string | undefined;
  setToken: SetterOrUpdater<string | undefined>;
}) => {
  useRefreshTokenBeforeExpiration();
  useForceFetchNewTokenOnMount();
  useSetPathParams();
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    handleRedirectCallback,
  } = useAuth0();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sharedToken = query.get("token");
    if (sharedToken) {
      setAccessTokenGlobal(sharedToken);
      setToken(sharedToken);
      return;
    }

    if (isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          redirectUrl: window.location.pathname + window.location.search,
        },
      });
      return;
    }
  }, [
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    setToken,
    handleRedirectCallback,
  ]);

  if (!token) {
    return null;
  }

  return <Outlet />;
};

const OpenUSerProfileIfQueryParamSet = () => {
  const OpenUSerProfileIfQueryParamSetInner = ({
    userData,
  }: {
    userData: LoggedInUser;
  }) => {
    const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      if (query.get("profile") == null) return;
      setMidScreenModalTypeOpen({
        modalType: UserProfileModalType,
        metadata: {
          user: userData,
        },
      });
    }, [setMidScreenModalTypeOpen, userData]);
    return null;
  };
  const userData = useAtomValue(loggedInUserAtom);

  if (!userData) return null;

  return <OpenUSerProfileIfQueryParamSetInner userData={userData} />;
};

const LevelSelector = () => {
  const projectLevel = useUrlFlagValue(featureFlag.projectLevel);
  const organisationLevel = useUrlFlagValue(featureFlag.organisationLevel);
  const internalUser = useAtomValue(loggedInUserIsInternalSelector);
  const { data: allNodesAccess, setState: setAllNodesAccess } =
    useUserAccessState();

  useEffect(() => {
    if (!internalUser) return;

    if (
      organisationLevel &&
      ["guest", "member", "admin", "owner"].includes(organisationLevel) &&
      Object.values(allNodesAccess.organisation_access).some(
        (setLevel) => setLevel !== organisationLevel,
      )
    ) {
      console.log("setting organisation level", organisationLevel);
      setAllNodesAccess({
        data: {
          node_access: allNodesAccess.node_access,
          organisation_access: Object.keys(
            allNodesAccess.organisation_access,
          ).reduce((acc, key) => ({ ...acc, [key]: organisationLevel }), {}),
        },
        initialised: true,
        loading: false,
      });
    }

    if (
      projectLevel &&
      ["viewer", "editor", "admin"].includes(projectLevel) &&
      Object.values(allNodesAccess.node_access).some(
        (setLevel) => setLevel !== projectLevel,
      )
    ) {
      console.log("setting project level", projectLevel);
      setAllNodesAccess({
        data: {
          organisation_access: allNodesAccess.organisation_access,
          node_access: Object.keys(allNodesAccess.node_access).reduce(
            (acc, key) => ({ ...acc, [key]: projectLevel }),
            {},
          ),
        },
        initialised: true,
        loading: false,
      });
    }
  }, [
    internalUser,
    setAllNodesAccess,
    allNodesAccess,
    projectLevel,
    organisationLevel,
  ]);

  return null;
};

function InitialiseUserAccessState({
  children,
}: {
  children: React.ReactNode;
}) {
  const organisationId = useAtomValue(organisationIdAtom);
  if (!organisationId) return <FullScreenLoader />;

  return (
    <InitialiseUserAccessStateInner organisationId={organisationId}>
      {children}
    </InitialiseUserAccessStateInner>
  );
}

function InitialiseUserAccessStateInner({
  children,
  organisationId,
}: {
  children: React.ReactNode;
  organisationId: string;
}) {
  const { initialised: initialisedNodeAccess } = useUserAccessState();
  const { initialised: initialisedLibraryAccess } = useUserLibraryAccessState({
    organisationId,
  });
  if (!initialisedNodeAccess || !initialisedLibraryAccess)
    return <FullScreenLoader />;

  return children;
}

function RedirectToFirstOrganisation({
  children,
}: {
  children: React.ReactNode;
}) {
  const organisations = useAtomValue(organisationsState);
  const organisationId = useAtomValue(organisationIdAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const [lastVisitedOrg, setLastVisitedOrg] = useLocalStorage<string>(
    "vind:last-visited-organisation",
  );

  useEffect(() => {
    if (organisationId) {
      setLastVisitedOrg(organisationId);
    }
  }, [organisationId, setLastVisitedOrg]);

  useEffect(() => {
    if (!organisationId) {
      const firstOrg = lastVisitedOrg ? lastVisitedOrg : organisations[0]?.id;
      if (firstOrg) {
        const newPath = `/organisation/${firstOrg}${location.search}`;
        navigate(newPath);
      }
    } else {
      const invalidOrgId = !organisations.some(
        (org) => org.id === organisationId,
      );
      if (invalidOrgId) {
        const firstOrgId = organisations[0]?.id;
        if (firstOrgId) {
          const newPath = `/organisation/${firstOrgId}${location.search}`;
          navigate(newPath);
        } else {
          // user has no organisation
          navigate("/no-access");
        }
      }
    }
  }, [
    lastVisitedOrg,
    location.search,
    navigate,
    organisationId,
    organisations,
  ]);

  return children;
}

const CheckOrganisationStatus = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const organisations = useAtomValue(organisationsState);
  const organisationId = useAtomValue(organisationIdAtom);

  const currentOrg = organisations.find((org) => org.id === organisationId);

  if (currentOrg?.status === "deactivated") {
    return <OrganisationIsDeactivated />;
  }

  return <>{children}</>;
};

const CommonComponentsForLoggedInUsers = () => {
  return (
    <RedirectToFirstOrganisation>
      <InitialiseUserAccessState>
        <CheckOrganisationStatus>
          <OrganisationAblyProvider>
            <ProjectNodeAblyProvider>
              <Toast />
              <LevelSelector />
              <SetMixpanelData />
              <FeatureFlagSync />
              <TrackStateChanges />
              <BootIntercom />
              <SetSentryData />
              <GPUCheck />
              <OpenUSerProfileIfQueryParamSet />
              <UserProfileSettingsModal />
              <KeyboardShortcutsModal />
              <VideoModal />
              <ProjectConfigurationModal />
              <FeatureSettingsModal />
              <ProcurementCostsModal />
              <DashboardModal />
              <PublicModeModal />
              <TermsOfUseWizard />
              <React.Suspense fallback={null}>
                <FrontEndVersionPoller />
                <RenameModals />
                <BufferModal />
                <ExtractMapDataModal />
                <DownloadCustomCRSModal />
                <AddCustomCRSModal />
                <ReloadForNewVersionModal />
                <CopyCollectionModal />
                <FilterExternalDataLayers />
                <CompareParksModal />
                <AddLayerSourceInternalModal />
                <UploadModal />
                <DuplicateComponentModal />
                <DeleteTurbineModal />
                <ComponentsPreview />
              </React.Suspense>
              <Outlet />
            </ProjectNodeAblyProvider>
          </OrganisationAblyProvider>
        </CheckOrganisationStatus>
      </InitialiseUserAccessState>
    </RedirectToFirstOrganisation>
  );
};

function App() {
  const [token, setToken] = useAtom(getTokenAtom);
  const setJotaiToken = useSetAtom(tokenAtom);

  useRefreshOnTabFocusAfterDormancy();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans:100,200,300,400,500,600,700,800,900"],
      },
    });
  }, []);
  const inChecklyMode = useMemo(() => isInChecklyMode(), []);

  useEffect(() => {
    if (inChecklyMode) {
      Mixpanel.stop_tracking();
    } else {
      Mixpanel.start_tracking();
    }
  }, [inChecklyMode]);

  const SentryRoutes = useMemo(
    () => SentryR.withSentryReactRouterV6Routing(Routes),
    [],
  );

  return (
    <>
      <SentryRoutes>
        <Route path="/public/*" element={<RedirectToPublicModeApp />} />
        <Route
          element={
            <ProtectedRoute
              token={token}
              setToken={(t) => {
                setToken(t);
                setJotaiToken(t);
              }}
            />
          }
        >
          <Route path="welcome" element={<OnboardingSlideShow />} />

          <Route element={<CommonComponentsForLoggedInUsers />}>
            <Route
              path="no-access"
              element={
                <Suspense fallback={<CustomerBodyPlaceholder />}>
                  <NoOrganisationPage />
                </Suspense>
              }
            />

            <Route path="organisation" element={<OrganisationPage />}>
              <Route path={`:organisationId`} element={<OrganisationBody />}>
                <Route index element={<OrganisationBodyWrapperOldRedirect />} />
                <Route path={`projects/:nodeId?`} element={<CustomerBody />} />
                <Route
                  path={`library/turbine/:selectedItemId?`}
                  element={<TurbineTab />}
                />
                <Route
                  path={`library/foundation/:selectedItemId?`}
                  element={<FoundationTab />}
                />
                <Route
                  path={`library/cable/:selectedItemId?`}
                  element={<CableTab />}
                />
                <Route
                  path={`library/exportcable/:selectedItemId?`}
                  element={<ExportCableTab />}
                />
                <Route
                  path={`library/analysis/:selectedItemId?`}
                  element={<AnalysisTab />}
                />
                <Route
                  path={`library/financial/:selectedItemId?`}
                  element={<FinancialTab />}
                />
                <Route
                  path={`library/datalayers/:selectedItemId?`}
                  element={<DataLibraryTab />}
                />
                <Route
                  path={`library/substation/:selectedItemId?`}
                  element={<SubstationTab />}
                />
                <Route
                  path={`library`}
                  element={
                    <Suspense fallback={<CustomerBodyPlaceholder />}>
                      <Library />
                    </Suspense>
                  }
                />
                <Route path={`members`} element={<MembersPage />} />
                <Route path={`portfolio`} element={<Portfolio />} />
                <Route path={`integration`} element={<Integration />} />
                <Route path={`learn`} element={<LearnVindGrid />} />
                <Route
                  path=":nodeId"
                  element={<OrganisationBodyWrapperOldRedirect />}
                />
                <Route
                  path="*"
                  element={<OrganisationBodyWrapperOldRedirect />}
                />
              </Route>
            </Route>

            <Route
              path="/projects"
              element={<Navigate replace to="/organisation" />}
            />

            <Route
              path="design/project/:organisationId/:projectId"
              element={<SelectCorrectBranch />}
            />
            <Route
              path="onshore/project/:organisationId/:projectId"
              element={<SelectCorrectBranch />}
            />
            <Route
              path="design"
              element={<Design mode={DesignToolMode.Offshore} />}
            >
              <Route
                path="project/:organisationId/:projectId/:branchId/:parkId?"
                element={<ParkDesignV2 />}
              />
              <Route index element={<Navigate replace to="/organisation" />} />
            </Route>
            <Route
              path="onshore"
              element={<Design mode={DesignToolMode.Onshore} />}
            >
              <Route
                path="project/:organisationId/:projectId/:branchId/:parkId?"
                element={<ParkDesignV2 />}
              />
              <Route index element={<Navigate replace to="/organisation" />} />
            </Route>
            <Route path="/qgisplugin" element={<QgisPluginReadme />} />
            <Route path="/arcgis" element={<ArcgisPluginReadme />} />
            <Route path="*" element={<Navigate to="/organisation" />} />
          </Route>

          <Route element={<InternalRoute />}>
            <Route path="/permissions" element={<PermissionsPage />} />
            <Route path="/endpoints" element={<PageAPI />} />
            <Route path="/designsystem" element={<DesignSystem />} />
            <Route path="/tooling/org" element={<OrgInternalPage />} />
          </Route>
        </Route>

        <Route element={<ArchiveProjectAccess />}>
          <Route
            path="/archive/:organisationId/:projectId/:branchId/:parkId"
            element={<ArchiveProjectVersion />}
          />
        </Route>
      </SentryRoutes>
      <TrackNavigation />
    </>
  );
}

export default SentryR.withProfiler(App);
