import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import {
  exportCableLoadMapFamily,
  exportCablesInParkFamily,
} from "state/jotai/exportCable";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { estimatePowerRating } from "components/Cabling/CableWalk";

const renderMW = (n: number): string => `${(n / 1e6).toFixed(0)} MW`;

export const exportSystemCapacityWarningFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.ExportSystemInsufficientCapacity>
        | undefined
      >
    >(async (get) => {
      const exportLoads = await get(
        exportCableLoadMapFamily({
          parkId,
          branchId,
          turbineTypeOverride: undefined,
        }),
      ).catch(() => undefined);
      if (!exportLoads) return;

      const exportCables = await get(
        exportCablesInParkFamily({ parkId, branchId }),
      );
      const exportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );

      const overloaded = [];
      for (const c of exportCables) {
        const load = exportLoads.get(c.id);
        if (load === undefined) continue;
        const type = exportCableTypes.get(c.properties.cableTypeId ?? "");
        if (!type || !type.ampacity) continue;
        const capacity = estimatePowerRating(type);
        if (capacity < load) overloaded.push({ id: c.id, load, capacity });
      }

      if (overloaded.length === 0) return undefined;

      const msg =
        "Export cable capacity may be too low.\n\n" +
        overloaded
          .map(
            ({ capacity, load }) =>
              `The total estimated capacity of the export cable (${renderMW(capacity)}) is ${renderMW(load - capacity)} lower than the total load (${renderMW(load)}).`,
          )
          .join("\n\n");

      return {
        type: ValidationWarningTypes.ExportSystemInsufficientCapacity,
        detailsText: msg,
        featureIds: overloaded.flatMap(({ id }) => id),
        parkId,
      };
    }),
);
