import { Feature, FeatureCollection, Position } from "geojson";
import proj4, { TemplateCoordinates } from "proj4";
import { funcOnCoords } from "./utils";
import { GeometryNoCollection } from "./geojson/geojson";

const PSUEDO_MERCATOR_PROJ4_STRING =
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";

export const ESRI_54008 =
  "+proj=sinu +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs";

export const EPSG_3857 =
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs";

const projToArray = (tc: TemplateCoordinates): Position =>
  Array.isArray(tc) ? tc : [tc.x, tc.y];

export const wgs84ToProjected = (coord: number[], proj4String: string) =>
  projToArray(proj4(proj4String, coord.slice(0, 2)));

export const wgs84ToPsuedoMercator = (coord: proj4.TemplateCoordinates) =>
  projToArray(proj4(PSUEDO_MERCATOR_PROJ4_STRING, coord));

export const projectedToWGS84 = (coord: Position, proj4String: string) =>
  projToArray(proj4(proj4String, "WGS84", coord));

export const pseudoMercatorToWGS84 = (coord: Position) =>
  projToArray(proj4(PSUEDO_MERCATOR_PROJ4_STRING, "WGS84", coord));

export const pseudoMercatorToProjectedFunction = (proj4String: string) =>
  proj4(PSUEDO_MERCATOR_PROJ4_STRING, proj4String);

export const pseudoMercatorToProjected = (
  coord: Position,
  proj4String: string,
) => projToArray(proj4(PSUEDO_MERCATOR_PROJ4_STRING, proj4String, coord));

export const toWGS84 = (coord: Position, proj4String: string) =>
  projToArray(proj4(proj4String, "WGS84", coord));

export const featureWGS84ToProjected = (
  f: Feature<GeometryNoCollection>,
  proj4String: string,
) => ({
  ...f,
  geometry: {
    ...f.geometry,
    coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
      wgs84ToProjected(c, proj4String),
    ),
  },
});

export const featureProjectedToWGS84 = (
  f: Feature<GeometryNoCollection>,
  proj4String: string,
) => ({
  ...f,
  geometry: {
    ...f.geometry,
    coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
      toWGS84(c, proj4String),
    ),
  },
});

export const addToFeatureCoordinates = (
  f: Feature<GeometryNoCollection>,
  coordinates: string,
) => ({
  ...f,
  geometry: {
    ...f.geometry,
    coordinates: funcOnCoords(f.geometry.coordinates, (c) => [
      c[0] + coordinates[0],
      c[1] + coordinates[1],
    ]),
  },
});

export const featureWGS84ToPsuedoMercator = (
  f: Feature<GeometryNoCollection>,
) => ({
  ...f,
  geometry: {
    ...f.geometry,
    coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
      wgs84ToPsuedoMercator(c),
    ),
  },
});

export const featureCollectionWGS84ToPsuedoMercator = (
  featureCollection: FeatureCollection<GeometryNoCollection>,
) => {
  return {
    ...featureCollection,
    features: featureCollection.features.map(featureWGS84ToPsuedoMercator),
  };
};

export const featureCollectionProjectedToWGS84 = (
  featureCollection: FeatureCollection,
  proj4String: string,
) => {
  return {
    ...featureCollection,
    features: featureCollection.features.map((f) =>
      featureProjectedToWGS84(f as any, proj4String),
    ),
  };
};

export const transformBBOXToWGS84Square = (
  bbox: [number, number, number, number],
  proj4String: string,
) => {
  return [
    proj4(proj4String, "EPSG:4326", [bbox[0], bbox[1]]),
    proj4(proj4String, "EPSG:4326", [bbox[2], bbox[1]]),
    proj4(proj4String, "EPSG:4326", [bbox[2], bbox[3]]),
    proj4(proj4String, "EPSG:4326", [bbox[0], bbox[3]]),
  ].map((c) => ({ lng: c[0], lat: c[1] }));
};

export const transformNonWGS84BBOXToWGS84BBOX = (
  bbox: number[][],
  proj4String: string,
) => {
  const bboxPolygon = [
    [bbox[0][0], bbox[0][1]],
    [bbox[1][0], bbox[0][1]],
    [bbox[1][0], bbox[1][1]],
    [bbox[0][0], bbox[1][1]],
  ];
  const bboxPolygon4326 = bboxPolygon.map((b) =>
    proj4(proj4String, "EPSG:4326", [b[0], b[1]]),
  );
  const Xs = bboxPolygon4326.map((bbox) => bbox[0]);
  const Ys = bboxPolygon4326.map((bbox) => bbox[1]);
  return [
    [Math.min(...Xs), Math.min(...Ys)],
    [Math.max(...Xs), Math.max(...Ys)],
  ];
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInM = (
  [lng1, lat1]: Position,
  [lng2, lat2]: Position,
) => {
  const R = 6371000; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

// approximately
export const metersToLat = (meters: number): number => {
  return meters / 111000;
};

export const metersToLng = (meters: number, latitude: number): number => {
  return meters / (111000 * Math.cos((latitude * Math.PI) / 180));
};
