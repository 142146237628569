import Tabs, { TabData } from "components/General/Tabs";

export default function ResourceContentTabs({ tabs }: { tabs: TabData[] }) {
  return (
    <Tabs
      menuStyle={{
        justifyContent: "space-between",
        gap: "5rem",
      }}
      tabs={tabs}
      wrapperStyle={{
        overflowY: "hidden",
      }}
      contentWrapperStyle={{
        height: "100%",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    />
  );
}
