import { viewOrigoSelector, viewProj4StringAtom } from "state/viewToPark";
import { useEffect, useMemo } from "react";
import { mapboxAccessToken } from "components/MapNative/constants";
import { lonLatToTile } from "types/tile";
import {
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  MathUtils,
  Mesh,
  MeshStandardMaterial,
  ShadowMaterial,
  Texture,
  TextureLoader,
} from "three";
import { getTileBBox } from "../../ViewToPark/ThreeContext/utils";
import { MeshBVH, SerializedBVH } from "three-mesh-bvh";
import { promiseWorker, typedWorker } from "utils/utils";
import { Position } from "@turf/turf";
import * as turf from "@turf/turf";
import { atom, useAtomValue } from "jotai";
import { atomFamily } from "utils/jotai";
import { currentParkAtom } from "state/jotai/park";
import * as Sentry from "@sentry/react";
import { ThreeCoreParkShadow } from "./useCreateThreeCore";
import { loadable } from "jotai/utils";
import { canvasToImage, getRGBAArray, mergeImages } from "utils/image";
import { mapboxTerrainDemTileUrlFamily } from "state/map";

const TERRAIN_ZOOM_LEVEL = 11;
const extraResolution = 2;

const PARK_SURROUNDING_TILES = 1;
const pixelSize = 512;

const getTerrainTextureFromMapbox = atomFamily(
  (requestedTiles: undefined | [number, number, number, number]) =>
    atom<Promise<Texture | undefined>>(async (get) => {
      const proj4String = get(viewProj4StringAtom);
      const origo = await get(viewOrigoSelector);
      if (!requestedTiles || !proj4String || !origo) return undefined;

      const textureZoom = TERRAIN_ZOOM_LEVEL + extraResolution;
      const seCorner2 = {
        x:
          requestedTiles[2] * Math.pow(2, extraResolution) +
          Math.pow(2, extraResolution) -
          1,
        y:
          requestedTiles[3] * Math.pow(2, extraResolution) +
          Math.pow(2, extraResolution) -
          1,
      };
      const nwCorner2 = {
        x: requestedTiles[0] * Math.pow(2, extraResolution),
        y: requestedTiles[1] * Math.pow(2, extraResolution),
      };

      const textureTilesToRequest = [];
      for (let y = nwCorner2.y; y <= seCorner2.y; y++) {
        for (let x = nwCorner2.x; x <= seCorner2.x; x++) {
          textureTilesToRequest.push(
            `https://api.mapbox.com/v4/mapbox.satellite/${textureZoom}/${x}/${y}@2x.jpg?access_token=${mapboxAccessToken}`,
          );
        }
      }

      const mergedTextureCanvas = await mergeImages(
        textureTilesToRequest,
        seCorner2.x - nwCorner2.x + 1,
      );

      const textureBlob = await new Promise<Blob>((resolve, reject) => {
        mergedTextureCanvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas toBlob failed"));
          }
        }, "image/jpeg");
      });

      const textureSignedUrl = window.URL.createObjectURL(textureBlob);
      const loader = new TextureLoader();
      const texture = await new Promise<Texture>((res, fail) => {
        loader.load(
          textureSignedUrl,
          function (texture) {
            res(texture);
          },
          undefined,
          function (err) {
            fail(err);
          },
        );
      });
      return texture;
    }),
);

const getTerrainGeometryFromMapbox = atomFamily(
  (requestedTiles: undefined | [number, number, number, number]) =>
    atom<Promise<BufferGeometry | undefined>>(async (get) => {
      const proj4String = get(viewProj4StringAtom);
      const origo = await get(viewOrigoSelector);
      if (!requestedTiles || !proj4String || !origo) return undefined;

      const terrainTilesToRequest = [];
      for (let y = requestedTiles[1]; y <= requestedTiles[3]; y++) {
        for (let x = requestedTiles[0]; x <= requestedTiles[2]; x++) {
          terrainTilesToRequest.push(
            get(mapboxTerrainDemTileUrlFamily({ z: TERRAIN_ZOOM_LEVEL, x, y })),
          );
        }
      }

      const mergedTerrainCanvas = await mergeImages(
        terrainTilesToRequest,
        requestedTiles[2] - requestedTiles[0] + 1,
        1,
      );

      const mergedTerrainImg = await canvasToImage(mergedTerrainCanvas);
      const rgbaArray = getRGBAArray(mergedTerrainImg);

      const parseTerrainTileWorker = typedWorker<
        [
          [number[], number[], number[], number[]],
          [number, number, number, number],
          [number, number],
          string,
          Position,
        ],
        [ArrayLike<number>, ArrayLike<number>, SerializedBVH, ArrayLike<number>]
      >(
        new Worker(
          new URL(
            "../../ViewToPark/ThreeContext/parseTerrainTileWorker.ts",
            import.meta.url,
          ),
          {
            type: "module",
          },
        ),
      );

      const minBBOX = getTileBBox(
        requestedTiles[0],
        requestedTiles[1],
        TERRAIN_ZOOM_LEVEL,
      );
      const maxBBOX = getTileBBox(
        requestedTiles[2],
        requestedTiles[3],
        TERRAIN_ZOOM_LEVEL,
      );

      const minX = Math.min(minBBOX[0], minBBOX[2], maxBBOX[0], maxBBOX[2]);
      const minY = Math.min(minBBOX[1], minBBOX[3], maxBBOX[1], maxBBOX[3]);
      const maxX = Math.max(minBBOX[0], minBBOX[2], maxBBOX[0], maxBBOX[2]);
      const maxY = Math.max(minBBOX[1], minBBOX[3], maxBBOX[1], maxBBOX[3]);

      const bbox: [number, number, number, number] = [minX, minY, maxX, maxY];

      const [position, normal, bvhSerialised, uvs] = await promiseWorker(
        parseTerrainTileWorker,
        [
          rgbaArray,
          bbox,
          [
            pixelSize * (requestedTiles[2] - requestedTiles[0] + 1),
            pixelSize * (requestedTiles[3] - requestedTiles[1] + 1),
          ],
          proj4String,
          origo,
        ],
        "useParkShadowMeshes/parseTerrainTileWorker",
      );
      parseTerrainTileWorker.terminate();

      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(position, 3),
      );
      geometry.setAttribute("normal", new Float32BufferAttribute(normal, 3));
      geometry.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
      const deserializedBVH = MeshBVH.deserialize(bvhSerialised, geometry);
      geometry.boundsTree = deserializedBVH;

      geometry.computeBoundingBox();

      return geometry;
    }),
);

const getTerrainMeshFromMapbox = atomFamily(
  (requestedTiles: undefined | [number, number, number, number]) =>
    atom<
      Promise<{
        mesh: Mesh<BufferGeometry, MeshStandardMaterial> | undefined;
        shadowMesh: Mesh<BufferGeometry, ShadowMaterial> | undefined;
      }>
    >(async (get) => {
      const [texture, geometry] = await Promise.all([
        get(getTerrainTextureFromMapbox(requestedTiles)),
        get(getTerrainGeometryFromMapbox(requestedTiles)),
      ]);

      if (!texture || !geometry)
        return {
          mesh: undefined,
          shadowMesh: undefined,
        };

      const terrainMaterial = new MeshStandardMaterial({
        transparent: true,
        map: texture,
      });

      const shadowMaterial = new ShadowMaterial({
        opacity: 1.0,
        side: DoubleSide,
      });
      const mesh = new Mesh(geometry, terrainMaterial);
      const shadowMesh = new Mesh(geometry, shadowMaterial);

      mesh.castShadow = true;
      mesh.receiveShadow = false;
      shadowMesh.castShadow = false;
      shadowMesh.receiveShadow = true;
      mesh.rotateX(MathUtils.degToRad(-90));
      mesh.rotateZ(MathUtils.degToRad(-180));
      shadowMesh.rotateX(MathUtils.degToRad(-90));
      shadowMesh.rotateZ(MathUtils.degToRad(-180));

      mesh.matrixAutoUpdate = false;
      mesh.updateMatrix();
      shadowMesh.matrixAutoUpdate = false;
      shadowMesh.updateMatrix();

      return { mesh, shadowMesh };
    }),
);

export default function useParkShadowTerrain() {
  const park = useAtomValue(currentParkAtom);

  const requestTerrainTiles: undefined | [number, number, number, number] =
    useMemo(() => {
      if (!park) return undefined;
      const centerCoords = turf.center(park).geometry.coordinates;
      const highResolutionTile = lonLatToTile(
        centerCoords[0],
        centerCoords[1],
        TERRAIN_ZOOM_LEVEL,
      );
      return [
        highResolutionTile.x - PARK_SURROUNDING_TILES,
        highResolutionTile.y - PARK_SURROUNDING_TILES,
        highResolutionTile.x + PARK_SURROUNDING_TILES,
        highResolutionTile.y + PARK_SURROUNDING_TILES,
      ];
    }, [park]);

  const terrainLoadable = useAtomValue(
    loadable(getTerrainMeshFromMapbox(requestTerrainTiles)),
  );

  return terrainLoadable;
}

export const ParkShadowTerrain = ({
  threeCore,
  parkShadowTerrain,
  parkShadowShadowMesh,
  setTerrainLoaded,
}: {
  threeCore: ThreeCoreParkShadow | undefined;
  parkShadowTerrain: Mesh<BufferGeometry, MeshStandardMaterial> | undefined;
  parkShadowShadowMesh: Mesh<BufferGeometry, ShadowMaterial> | undefined;
  setTerrainLoaded: (loaded: boolean) => void;
}) => {
  useEffect(() => {
    if (!threeCore || !parkShadowTerrain || !parkShadowShadowMesh) return;
    const { scene } = threeCore;

    scene.add(parkShadowTerrain);
    scene.add(parkShadowShadowMesh);
    Sentry.addBreadcrumb({
      category: "viewfromparkshadow",
      level: "debug",
      data: { scene: threeCore.scene },
    });
    threeCore.renderer.render(threeCore.scene, threeCore.camera);
    setTerrainLoaded(true);
  }, [threeCore, parkShadowTerrain, parkShadowShadowMesh, setTerrainLoaded]);
  return null;
};
