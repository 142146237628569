import { z } from "zod";

import { MapboxGeoJSONFeature } from "mapbox-gl";
import { GeoTiffUserUploadedImageType } from "services/types";
import { _FeatureParser, ProjectFeature } from "types/feature";

export const _VindFeaturesParser = z
  .object({
    copiedFromVind: z.literal(true),
    features: z
      .unknown()
      .array()
      .transform((arr) =>
        arr.filter((f) => _FeatureParser.safeParse(f).success),
      ),
  })
  .passthrough();

export type VindFeaturesParser = z.infer<typeof _VindFeaturesParser>;

export const featureSupportsCopy = (
  feature: ProjectFeature | MapboxGeoJSONFeature,
) => feature.properties?.type !== GeoTiffUserUploadedImageType;
