import { useEffect, useMemo } from "react";
import { currentParkAtom } from "state/jotai/park";
import * as turf from "@turf/turf";
import { lonLatToTile } from "types/tile";
import {
  BufferGeometry,
  ExtrudeGeometry,
  MathUtils,
  Mesh,
  MeshStandardMaterial,
  Shape,
} from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { wgs84ToProjected } from "utils/proj4";
import { DIVISION_FACTOR } from "components/ViewAnalyses/ViewToPark/constants";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { ProjectFeature } from "types/feature";
import { multiFeatureToFeatures } from "utils/geojson/utils";
import { sampleTerrainMeshHeight } from "./utils";
import { atom, useAtomValue } from "jotai";
import { atomFamily } from "utils/jotai";
import { viewOrigoSelector, viewProj4StringAtom } from "state/viewToPark";
import { fastMax, fastMin, funcOnCoords } from "utils/utils";
import { ThreeCoreParkShadow } from "./useCreateThreeCore";
import { loadable } from "jotai/utils";
import {
  fetchMapboxBuildingVectorTilesAsFeaturesAtom,
  OSM_ZOOM_LEVEL,
} from "state/layer";

const getOSMMeshsesFromMapbox = atomFamily(
  ({
    requestedTiles,
    terrainMesh,
    terrainLoaded,
  }: {
    requestedTiles: undefined | [number, number, number, number];
    terrainMesh: Mesh<BufferGeometry, MeshStandardMaterial> | undefined;
    terrainLoaded: boolean;
  }) =>
    atom<Promise<Mesh<BufferGeometry, MeshStandardMaterial> | undefined>>(
      async (get) => {
        const proj4String = get(viewProj4StringAtom);
        const origo = await get(viewOrigoSelector);
        if (
          !requestedTiles ||
          !proj4String ||
          !origo ||
          !terrainMesh ||
          !terrainLoaded
        )
          return;

        const features = await get(
          fetchMapboxBuildingVectorTilesAsFeaturesAtom(requestedTiles),
        );

        const osmBuildingsProjectedScaled = features.map((feature) => ({
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: funcOnCoords(
              feature.geometry.coordinates,
              (coordinate) => {
                const projectedCoords = wgs84ToProjected(
                  coordinate,
                  proj4String,
                );
                return [
                  (projectedCoords[0] - origo[0]) / DIVISION_FACTOR,
                  (projectedCoords[1] - origo[1]) / DIVISION_FACTOR,
                ];
              },
            ),
          },
        })) as Feature<MultiPolygon>[];

        const extrudeFeatures = osmBuildingsProjectedScaled
          .filter((feature) => feature.properties)
          .filter((feature) => feature?.properties?.extrude === "true");
        const extrudeSingleFeatures = extrudeFeatures.flatMap((f) =>
          multiFeatureToFeatures(f as ProjectFeature),
        ) as Feature<Polygon>[];
        const geometries = extrudeSingleFeatures.flatMap((feature) =>
          feature.geometry.coordinates.map((shapeCoords) => {
            const terrainSamples = shapeCoords.map(
              (coord) => sampleTerrainMeshHeight(coord, terrainMesh) ?? 0,
            );
            const groundLevelMin = fastMin(terrainSamples);
            const groundLevelMax = fastMax(terrainSamples);
            const extraHeight = groundLevelMax - groundLevelMin;
            const shape = new Shape();
            shape.moveTo(shapeCoords[0][0], shapeCoords[0][1]);

            shapeCoords
              .slice(1)
              .forEach((coord) => shape.lineTo(coord[0], coord[1]));

            const minHeight = feature.properties?.min_height ?? 0;

            const geometry = new ExtrudeGeometry(shape, {
              depth:
                ((feature?.properties?.height ?? 3) + extraHeight - minHeight) /
                DIVISION_FACTOR,
              bevelEnabled: false,
            });

            geometry.translate(
              0,
              0,
              (groundLevelMin + minHeight) / DIVISION_FACTOR,
            );

            return geometry;
          }),
        );

        if (geometries.length === 0) return;

        const mergedGeometry =
          BufferGeometryUtils.mergeBufferGeometries(geometries);

        const materialCurvature = new MeshStandardMaterial({
          transparent: false,
          color: 0xffffff,
        });

        const mesh = new Mesh(mergedGeometry, materialCurvature);
        mesh.rotateX(MathUtils.degToRad(-90));
        mesh.rotateZ(MathUtils.degToRad(-180));
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        return mesh;
      },
    ),
);
export default function useParkShadowOSMBuildings(
  terrainMesh: Mesh<BufferGeometry, MeshStandardMaterial> | undefined,
  terrainLoaded: boolean,
) {
  const park = useAtomValue(currentParkAtom);

  const requestOSMBuildingTiles: undefined | [number, number, number, number] =
    useMemo(() => {
      if (!park) return undefined;
      const parkBBOX = turf.bbox(turf.buffer(park, 1, { units: "kilometers" }));
      const minLonLat = lonLatToTile(parkBBOX[0], parkBBOX[1], OSM_ZOOM_LEVEL);
      const maxLonLat = lonLatToTile(parkBBOX[2], parkBBOX[3], OSM_ZOOM_LEVEL);

      //MaxLonLat.y is lower than minLonLat.y
      return [minLonLat.x, maxLonLat.y, maxLonLat.x, minLonLat.y];
    }, [park]);

  const parkShadowOSMBuildingsLoadable = useAtomValue(
    loadable(
      getOSMMeshsesFromMapbox({
        requestedTiles: requestOSMBuildingTiles,
        terrainMesh,
        terrainLoaded,
      }),
    ),
  );

  return parkShadowOSMBuildingsLoadable.state !== "hasData"
    ? undefined
    : parkShadowOSMBuildingsLoadable.data;
}

export const ParkShadowOSMBuildings = ({
  threeCore,
  parkShadowOSMBuildings,
}: {
  threeCore: ThreeCoreParkShadow | undefined;
  parkShadowOSMBuildings:
    | Mesh<BufferGeometry, MeshStandardMaterial>
    | undefined;
}) => {
  useEffect(() => {
    if (!threeCore || !parkShadowOSMBuildings) return;
    const { scene } = threeCore;

    scene.add(parkShadowOSMBuildings);
    threeCore.renderer.render(threeCore.scene, threeCore.camera);
  }, [threeCore, parkShadowOSMBuildings]);

  return null;
};
