import React, { ComponentRef, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Mixpanel } from "../../mixpanel";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Frame } from "./Layout";
import Checkbox from "./Checkbox";
import {
  borderRadiusMedium,
  spaceDecent,
  spaceLarge,
  spaceMedium,
  spaceSmall,
  spaceTiny,
  spacing4,
} from "../../styles/space";
import { colors } from "../../styles/colors";
import { IconREMSize, typography } from "../../styles/typography";
import ColumnIcon from "@icons/24/Column.svg";
import { Tab, VTab } from "./VTabs";
import FuseSearchInput from "./FuseSearchInput";
import Fuse from "fuse.js";
import { AnchorClassName } from "./Anchor";

const SelectAttributesWrapper = styled.div`
  position: relative;
  z-index: 4;
`;

const SelectAttributesFrame = styled(Frame)<{ right?: boolean }>`
  padding: ${spaceLarge} 0;
  display: flex;
  position: absolute;
  gap: 1.6rem;
  margin-top: ${spaceSmall};
  width: 60rem;
  max-height: 60vh;

  overflow-y: auto;
  ${({ right }) =>
    right &&
    `
      right: 0;
    `}

  > * {
    padding-left: initial;
    padding-right: initial;
  }
  padding-left: ${spaceLarge};
  padding-right: ${spaceLarge};
`;

const AttributeCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin-top: ${spaceMedium};

  label {
    padding: 0.4rem;
    border-radius: 4px;
    transition: background 1.5s ease-out;
    &[data-highlight="true"] {
      transition: unset;
      background: ${colors.orange300};
    }
  }
`;

const SearchDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.4rem 0.8rem;

  span {
    ${typography.body}
  }
  span:first-child {
    color: ${colors.textSecondary};
  }

  span:last-child {
    color: ${colors.textPrimary};
  }
`;

interface SelectAttributesProps {
  title: string;
  categories: Record<string, any>;
  selectedKeys: Record<string, string[]>;
  onSelectionChange: (categoryKey: string, selectedKeys: string[]) => void;
  getCategoryIcon?: (categoryKey: string) => React.ReactNode;
  right?: boolean;
  canEdit?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SelectAttributesGeneral = ({
  title,
  categories,
  selectedKeys,
  onSelectionChange,
  getCategoryIcon,
  right = false,
  canEdit,
  isOpen,
  setIsOpen,
}: SelectAttributesProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<ComponentRef<typeof VTab>>(null);
  const [hl, setHl] = useState<string>("");

  useClickOutside(
    frameRef,
    () => setIsOpen(false),
    (target) =>
      target === wrapperRef?.current ||
      (target instanceof HTMLElement &&
        target.classList.contains(AnchorClassName)),
  );

  const nrSelectedAttributes = Object.values(selectedKeys).reduce(
    (acc, curr) => acc + curr.length,
    0,
  );

  const fuse = useMemo(() => {
    const flattenedFields = Object.entries(categories).flatMap(([key, obj]) =>
      obj.attributes.map((attr: any) => ({
        category: obj.name,
        categoryKey: key,
        field: attr.name,
        fieldKey: attr.key,
      })),
    );

    return new Fuse(flattenedFields, {
      keys: ["category", "field"],
    });
  }, [categories]);

  return (
    <SelectAttributesWrapper ref={wrapperRef}>
      <div
        style={{
          display: "flex",
          gap: spaceDecent,
          borderRadius: borderRadiusMedium,
          padding: `${spaceTiny} ${spaceDecent}`,
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          height: "3.2rem",
          border: isOpen
            ? `1px solid ${colors.borderSelected}`
            : `1px solid ${colors.borderDefault}`,
          boxSizing: "border-box",
          opacity: canEdit ? 1 : 0.5,
        }}
        onClick={() => {
          if (!canEdit) return;
          setIsOpen(!isOpen);
          Mixpanel.track_old("Toggle Select attributes open", {});
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <ColumnIcon />
        </IconREMSize>
        <p style={{ ...typography.regular, margin: 0, whiteSpace: "nowrap" }}>
          {title}
        </p>
        <span
          style={{
            ...typography.regular,
            margin: 0,
            backgroundColor: colors.blue100,
            color: colors.blue600,
            padding: `2px ${spacing4}`,
            borderRadius: typography.regular.fontSize,
          }}
        >
          {nrSelectedAttributes}
        </span>
      </div>
      {isOpen && (
        <SelectAttributesFrame ref={frameRef} right={right}>
          <h3>{title}</h3>

          <FuseSearchInput
            placeholder="Search ..."
            wrapperDivStyle={{ display: "flex" }}
            style={{ flex: 1 }}
            fuse={fuse}
            maxRows={10}
            onSelect={(e) => {
              if (!tabRef.current) return;
              tabRef.current.setSelectedKey(e.item.categoryKey);
              setHl(e.item.fieldKey);
              setTimeout(() => {
                setHl("");
              }, 250);
            }}
            renderItem={(e) => (
              <SearchDiv>
                <span>{e.item.category}</span>
                <span>{e.item.field}</span>
              </SearchDiv>
            )}
          />

          <VTab ref={tabRef}>
            {Object.entries(categories).map(([key, category]) => {
              if (category.attributes.length === 0) return null;

              const selectedKeysForCategory = selectedKeys[key] || [];

              const allIsSelected = category.attributes.every((attr: any) =>
                selectedKeysForCategory.includes(attr.key),
              );
              const anyoneIsSelected = category.attributes.some((attr: any) =>
                selectedKeysForCategory.includes(attr.key),
              );

              return (
                <Tab
                  key={key}
                  name={category.name}
                  icon={getCategoryIcon?.(key)}
                >
                  <AttributeCheckboxes>
                    <Checkbox
                      label="Select all"
                      labelPlacement="after"
                      checked={
                        allIsSelected
                          ? true
                          : anyoneIsSelected
                            ? "indeterminate"
                            : false
                      }
                      disabled={!canEdit}
                      labelStyle={{ fontWeight: "bold" }}
                      onChange={(event) => {
                        const newKeys = event.target.checked
                          ? category.attributes.map((attr: any) => attr.key)
                          : [];
                        onSelectionChange(key, newKeys);
                      }}
                    />
                    {category.attributes.map((attr: any) => (
                      <Checkbox
                        key={attr.key}
                        labelProps={
                          { "data-highlight": hl === attr.key } as any
                        }
                        label={attr.name}
                        labelPlacement="after"
                        value={attr.key}
                        checked={selectedKeysForCategory.includes(attr.key)}
                        disabled={!canEdit}
                        labelStyle={{
                          overflow: "hidden",
                          textWrap: "wrap",
                          wordWrap: "break-word",
                        }}
                        onChange={(event) => {
                          const newKeys = event.target.checked
                            ? [...selectedKeysForCategory, attr.key]
                            : selectedKeysForCategory.filter(
                                (k) => k !== attr.key,
                              );
                          onSelectionChange(key, newKeys);
                        }}
                      />
                    ))}
                  </AttributeCheckboxes>
                </Tab>
              );
            })}
          </VTab>
        </SelectAttributesFrame>
      )}
    </SelectAttributesWrapper>
  );
};

export default SelectAttributesGeneral;
