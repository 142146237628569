import { ChannelProvider } from "ably/react";
import { ChannelErrorHandler } from "./ChannelErrorHandler";

/**
 * A wrapper component that provides Ably channel connectivity and error handling.
 *
 * @component
 * @param {Object} props
 * @param {string} props.channelName - The name of the Ably channel to connect to
 * @param {string} props.ablyId - The Ably client identifier
 * @param {React.ReactNode} props.children - Child components that consume the Ably channel
 *
 * @note Only Ably-consuming components should be passed as children
 * @note Children will not render if the channel is in an error state
 */
export default function ChannelProviderWrapper({
  channelName,
  ablyId,
  children,
}: {
  channelName: string;
  ablyId: string;
  children: React.ReactNode;
}) {
  return (
    <ChannelProvider channelName={channelName} ablyId={ablyId}>
      <ChannelErrorHandler channelName={channelName} ablyId={ablyId}>
        {children}
      </ChannelErrorHandler>
    </ChannelProvider>
  );
}
