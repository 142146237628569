import { Suspense, useCallback, useEffect, useState } from "react";
import { idToFoundationChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import useOrgFoundationCrud from "components/Organisation/Library/foundation/useOrgFoundationCrud";
import { FoundationType } from "types/foundations";
import { FoundationProjects } from "./availability/FoundationProjects";
import FoundationSettings, {
  FOUNDATION_TYPE_NAMES,
} from "components/ConfigurationModal/FoundationSettings/FoundationSettings";
import { colors } from "styles/colors";
import { Tag } from "components/General/Tag";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const FoundationResourceContent = ({
  resource,
  organisationId,
}: {
  resource: FoundationType;
  organisationId: string;
}) => {
  const { update: updateFoundation } = useOrgFoundationCrud();

  const [tempFoundation, setTempFoundation] = useState(resource);

  useEffect(() => {
    setTempFoundation(resource);
  }, [resource, setTempFoundation]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<FoundationType>) => {
      setTempFoundation((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempFoundation],
  );

  const onSaveNameOrDescription = useCallback(
    (foundation: FoundationType) => {
      updateFoundation(foundation, true);
    },
    [updateFoundation],
  );

  if (!tempFoundation) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"foundation"}
        description={tempFoundation.description ?? ""}
        name={tempFoundation.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempFoundation.name,
            description: description ?? tempFoundation.description,
          })
        }
        onSave={({ name, description }) => {
          onSaveNameOrDescription({
            ...tempFoundation,
            name: name ?? tempFoundation.name,
            description: description ?? tempFoundation.description,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            description: resource.description,
          });
        }}
        changelogData={{
          id: idToFoundationChangelogId(resource.id),
          category: "org_foundation_manage",
        }}
      >
        <Tag
          background={colors.grey100}
          text={`Type: ${FOUNDATION_TYPE_NAMES[resource.type]}`}
        />
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("foundation")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <ResourceContentTabs
        tabs={foundationTabs(resource, organisationId!, updateFoundation)}
      />
    </>
  );
};

const foundationTabs = (
  resource: FoundationType,
  organisationId: string,
  update: (foundation: FoundationType) => Promise<any>,
) => [
  {
    name: "Foundation details",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FoundationSettings
          key={resource.id}
          foundation={resource}
          isDefault={false}
          onSave={update}
          duplicate={() => {}}
          nodeId={organisationId}
          hasEditAccess={true}
        />
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <FoundationProjects resource={resource} />
      </Suspense>
    ),
  },
];
