import {
  MooringLineType,
  _MooringLineType,
  getMooringLineTypes,
} from "services/mooringLineTypeService";
import { projectIdAtom } from "state/pathParams";
import { mooringLinesInParkFamily } from "./mooringLine";
import { isMooringLineMultiple } from "utils/predicates";
import { atomFamily, atomFromFn } from "utils/jotai";
import { atom } from "jotai";

export const projectMooringLineTypesFamily = atomFamily((projectId: string) =>
  atomFromFn<Promise<Map<string, MooringLineType>>>(async () => {
    const response = await getMooringLineTypes(projectId);
    if (!response.ok) throw new Error("Fetching mooring line types failed");
    const j = await response.json();

    const types = _MooringLineType.array().parse(j);
    return new Map(types.map((t) => [t.id, t]));
  }),
);

export const mooringLineTypesAtom = atom<Promise<Map<string, MooringLineType>>>(
  async (get) => {
    const projectId = get(projectIdAtom);
    if (!projectId)
      throw new Error("mooringLineTypesFamily requires projectIdAtom");
    return get(projectMooringLineTypesFamily(projectId));
  },
);

export const mooringLineTypesByIdFamily = atom<Promise<MooringLineType[]>>(
  async (get) => {
    const types = await get(mooringLineTypesAtom);

    return Array.from(types.values()).sort((a, b) => (a.id > b.id ? 1 : -1));
  },
);

export const mooringLineTypesInParkAtom = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<Map<string, MooringLineType>>>(async (get) => {
      const types = await get(mooringLineTypesAtom);
      const mooringLines = await get(
        mooringLinesInParkFamily({ parkId, branchId }),
      );
      const typeIdsInUse = new Set(
        mooringLines.flatMap((m) =>
          m.properties.lineType
            ? [m.properties.lineType]
            : m.properties.lineTypes ?? [],
        ),
      );
      const ret = new Map<string, MooringLineType>();
      for (const [id, type] of types)
        if (typeIdsInUse.has(id)) ret.set(id, type);
      return ret;
    }),
);

export const mooringLineStatsInParkSelector = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom(async (get) => {
      const mooringLines = await get(
        mooringLinesInParkFamily({ parkId, branchId }),
      );
      const mooringLineTypesGroup = await get(mooringLineTypesAtom);
      const mooringLineTypes = await get(mooringLineTypesByIdFamily);

      const lineSegmentLengths: number[] = [];
      const lineSegmentTypes: string[] = [];
      let clumpWeightTonnes = 0;
      let buoyTonnes = 0;
      for (const line of mooringLines) {
        if (isMooringLineMultiple(line)) {
          for (let i = 0; i < line.properties.lineLengths.length; i++) {
            lineSegmentLengths.push(line.properties.lineLengths[i]);
            lineSegmentTypes.push(line.properties.lineTypes[i]);
            if (line.properties.attachments[i] > 0) {
              clumpWeightTonnes += line.properties.attachments[i] / 1000;
            } else if (line.properties.attachments[i] < 0) {
              buoyTonnes += -line.properties.attachments[i] / 1000;
            }
          }
        } else if (line.properties.lineLength) {
          lineSegmentLengths.push(line.properties.lineLength);
          lineSegmentTypes.push(line.properties.lineType ?? "");
        }
      }

      let lengthPerMooringLineType: { [key: string]: number } = {};
      for (let i = 0; i < lineSegmentLengths.length; i++) {
        const len = lineSegmentLengths[i];
        const type = lineSegmentTypes[i];
        const typeName = mooringLineTypesGroup.has(type) ? type : "other";
        lengthPerMooringLineType[typeName] =
          (lengthPerMooringLineType[typeName] ?? 0) + len;
      }

      return {
        mooringLineTypes,
        lengthPerMooringLineType,
        clumpWeightTonnes,
        buoyTonnes,
      };
    }),
);
