import RBush from "rbush";
import { TurbineFeature } from "types/feature";
import { SimpleTurbineType } from "types/turbines";
import { getDistanceFromLatLonInM, metersToLat, metersToLng } from "./proj4";

export const getOverlappingTurbines = (
  turbines: TurbineFeature[],
  turbineTypes: Map<string, SimpleTurbineType>,
) => {
  const tree = new RBush<{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    turbine: TurbineFeature;
  }>();

  turbines.forEach((turbine) => {
    const [lng, lat] = turbine.geometry.coordinates;
    tree.insert({
      minX: lng,
      minY: lat,
      maxX: lng,
      maxY: lat,
      turbine,
    });
  });

  return turbines.filter((turbine) => {
    const diameter = turbineTypes.get(
      turbine.properties.turbineTypeId,
    )?.diameter;
    if (diameter === undefined) return false;
    const [lng, lat] = turbine.geometry.coordinates;

    const latDiff = metersToLat(diameter);
    const lngDiff = metersToLng(diameter, lat);

    const nearbyTurbines = tree.search({
      minX: lng - lngDiff,
      minY: lat - latDiff,
      maxX: lng + lngDiff,
      maxY: lat + latDiff,
    });

    return nearbyTurbines.some((item) => {
      if (item.turbine.id === turbine.id) return false;
      const otherDiameter = turbineTypes.get(
        item.turbine.properties.turbineTypeId,
      )?.diameter;
      if (otherDiameter === undefined) return false;

      const distance = getDistanceFromLatLonInM(
        turbine.geometry.coordinates,
        item.turbine.geometry.coordinates,
      );
      return distance < diameter / 2 + otherDiameter / 2;
    });
  });
};
