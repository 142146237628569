import React from "react";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { DrawCableMenuType } from "@constants/cabling";

const DrawCableHelp = () => {
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();

  if (leftMenuActiveMode !== DrawCableMenuType) return null;

  return (
    <MenuFrame
      title="Draw cable"
      onExit={() => setLeftMenuActiveMode(undefined)}
    >
      <p>A cable must be connected to turbines or substations</p>
    </MenuFrame>
  );
};

export default DrawCableHelp;
