import { useEffect } from "react";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { turbinesEditableSourceId } from "../../constants/draw";
import { atomFamily } from "utils/jotai";
import { atom, useAtomValue } from "jotai";
import { ValidationWarning } from "state/validationWarnings";
import { cableForestFamily, cablesInParkFamily } from "state/jotai/cable";
import { turbinesInParkFamily } from "state/jotai/turbine";
import { mapAtom } from "state/map";

/**
 * Turbine is not connected with a cable to a substation. Does not apply if
 * there are no substations or no cables in the park.
 */
export const turbinesNotConnectedFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<
      Promise<
        | ValidationWarning<ValidationWarningTypes.TurbineNotConnected>
        | undefined
      >
    >(async (get) => {
      const forest = await get(cableForestFamily({ parkId, branchId }));
      const numberOfCables = (
        await get(cablesInParkFamily({ parkId, branchId }))
      ).length;
      if (numberOfCables === 0) return;
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const loose = turbines.filter((t) => {
        for (const tree of forest)
          if (tree.contains((n) => "turbine" in n && n.turbine.id === t.id))
            return false;
        return true;
      });

      if (loose.length === 0) return;
      return {
        type: ValidationWarningTypes.TurbineNotConnected,
        featureIds: loose.map((t) => t.id),
        parkId: parkId,
      };
    }),
);

export const TurbineNotConnectedJotai = ({ parkId }: { parkId: string }) => {
  const map = useAtomValue(mapAtom);
  const warning = useAtomValue(
    turbinesNotConnectedFamily({
      parkId,
      branchId: undefined,
    }),
  );

  useEffect(() => {
    if (!map || !warning) return;
    const source = map.getSource(turbinesEditableSourceId);

    warning.featureIds.forEach((id) => {
      source &&
        map.setFeatureState(
          { source: turbinesEditableSourceId, id: id },
          { overlap: true },
        );
    });

    return () => {
      const sourceOnUnmount = map.getSource(turbinesEditableSourceId);
      warning.featureIds.forEach((id) => {
        sourceOnUnmount &&
          map.removeFeatureState(
            { source: turbinesEditableSourceId, id: id },
            "overlap",
          );
      });
    };
  }, [map, warning]);

  return null;
};
