import {
  ProdId,
  getAverageHubHeight,
  getBranchId,
  getConfiguration,
  getProjectId,
  getTurbineCapacity,
  getWindSourceConfiguration,
} from "analysis/inputs";
import { getAnalysisVersion, getIsOutdated } from "analysis/output";
import { AnalysisStoppedTypes, analysisStoppedText } from "analysis/warnings";
import { AnalysisConfigNotFoundCompareErrorBoundaryFallbackRender } from "components/CompareParksModal/ErrorUtils";
import ParkWithChildrenImage from "components/CompareParksModal/ParkWithChildrenImage";
import { useColumnTemplates } from "components/CompareParksModal/columnTemplates";
import { v4 as uuid } from "uuid";
import {
  ComparisonMode,
  EnrichedSelectedParkCompare,
  _SelectedParkCompare,
  baselineComparisonValuesAtom,
  compareIsLoading,
  comparisonModeAtom,
  selectedComparisonAttributesAtom,
  selectedParksAtom,
  shownCompareDataAtom,
  visibleComparisonListRowsAtom,
} from "components/CompareParksModal/state";
import {
  CompareColumnItemAttribute,
  CompareColumnListItemAttribute,
  ComparisonAttributeKey,
} from "components/CompareParksModal/types";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import {
  ErrorBoundaryLocalFallback,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { TriggerCompareFinanceAndProduction } from "components/Finance/Triggers";
import { FinanceId } from "finance/types";
import Button from "components/General/Button";
import { IconBtn } from "components/General/Icons";
import Tooltip from "components/General/Tooltip";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { SkeletonBlock, SkeletonText } from "components/Loading/Skeleton";
import { useAnalysisForceRestart } from "components/ProductionV2/Triggers";
import {
  ElectricalStatError,
  ParkHasAnyValidationError,
  ProductionError,
} from "components/ValidationWarnings/FeatureSpecificErrors";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { useGoToFeatures } from "hooks/map";
import useNavigateToPark from "hooks/useNavigateToPark";
import useSelectionInMap from "hooks/useSelectionInMap";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { loadable, unwrap } from "jotai/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { CableType } from "services/cableTypeService";
import { AnalysisConfiguration } from "services/configurationService";
import { CostConfiguration } from "services/costService";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { branchMetaFamily } from "state/jotai/branch";
import { costConfigurationsFamily } from "state/jotai/costConfiguration";
import { mapAtom } from "state/map";
import { modalTypeOpenAtom } from "state/modal";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { editorAccessProjectSelector } from "state/user";
import styled from "styled-components";
import { colors } from "styles/colors";
import { WarningCircle } from "styles/errorCircle";
import { spacing2, spacing4, spacing5, spacing8 } from "styles/space";
import { typography } from "styles/typography";
import { BranchMeta } from "types/api";
import { SimpleTurbineType } from "types/turbines";
import { cursorIsInRightHalfOfElement } from "utils/dragNDropUtils";
import { isDefined } from "utils/predicates";
import { dedup, getPathPrefix, objectEquals } from "utils/utils";
import {
  AttributeHeader,
  AttributeValue,
  ITEM_WIDTH,
} from "../ParkComparisonView.style";
import useParkComparisonValues from "../useParkComparisonValues";
import {
  ColumnHeader,
  ColumnTemplateWithBorder,
  DnDWrapper,
  ErrorWrapper,
  MenuWrapper,
  ParkName,
  StickyHeader,
  StickyHeaderBorderContent,
} from "./ComparisonParkV2.style";
import ComparisonValue from "./ComparisonValue";
import {
  AnalysisConfigSelector,
  CostConfigSelector,
  ExportCableTypeSelector,
  HubHeightSelector,
  MaxPowerSelector,
  OperationsConfigSelector,
  TurbineTypeSelector,
  WindConfigSelector,
} from "./config-selector-dropdowns";
import CloseIcon from "@icons/24/Close.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import DuplicateIcon from "@icons/24/Duplicate.svg";
import { useAtomUnwrap, useJotaiCallback } from "utils/jotai";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import { customerProjectAtomFamily } from "state/timeline";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import {
  operationsConfigurationSelectedFamily,
  operationsConfigurationsFamily,
} from "state/jotai/operationsConfiguration";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { DotMenu } from "components/General/MenuButton";
import { MenuItem } from "components/General/Menu";
import { isOnshoreAtom } from "state/onshore";

function AttributeValueFlattener({
  attribute,
  averageTurbineHeight,
  selectedMaxPower,
  parkCapacity,
  setSelectedMaxPower,
  visibleIds,
  selectedHubHeight,
  setSelectedHubHeight,
  selectedTurbineType,
  setSelectedTurbineType,
  selectedExportCableType,
  setSelectedExportCableType,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  installationWasStopped,
  showProgress,
  analysisProgress,
  baseline,
  attributeKey,
  parkId,
  branchId,
  value,
  comparisonMode,
}: {
  attribute: CompareColumnItemAttribute | CompareColumnListItemAttribute;
  averageTurbineHeight: number;
  parkCapacity: number;
  selectedMaxPower: number | undefined;
  setSelectedMaxPower: (selectedMaxPower: number | undefined) => void;
  visibleIds: string[] | undefined;
  selectedHubHeight: number | undefined;
  setSelectedHubHeight: (selectedHubHeight: number | undefined) => void;
  selectedTurbineType: SimpleTurbineType | undefined;
  setSelectedTurbineType: (turbineType: SimpleTurbineType | undefined) => void;
  selectedExportCableType: CableType | undefined;
  setSelectedExportCableType: (exportCableType: CableType | undefined) => void;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  installationWasStopped: boolean;
  showProgress: 0 | undefined | boolean;
  analysisProgress: number | undefined;
  baseline?: unknown;
  attributeKey: ComparisonAttributeKey;
  parkId: string;
  branchId: string;
  value: unknown;
  comparisonMode: ComparisonMode;
}) {
  if (attribute.type === "list") {
    return (
      <>
        {attribute.values.map((att) => {
          if (Array.isArray(visibleIds) && !visibleIds.includes(att.key)) {
            return null;
          }

          return (
            <AttributeValueFlattener
              key={att.key}
              attribute={att}
              visibleIds={visibleIds}
              value={(value as Record<string, unknown>)[att.key]}
              baseline={
                (baseline as Record<string, unknown> | undefined)?.[att.key]
              }
              averageTurbineHeight={averageTurbineHeight}
              parkCapacity={parkCapacity}
              selectedMaxPower={selectedMaxPower}
              setSelectedMaxPower={setSelectedMaxPower}
              selectedHubHeight={selectedHubHeight}
              setSelectedHubHeight={setSelectedHubHeight}
              selectedTurbineType={selectedTurbineType}
              setSelectedTurbineType={setSelectedTurbineType}
              selectedExportCableType={selectedExportCableType}
              setSelectedExportCableType={setSelectedExportCableType}
              analysisStoppedOrFailed={analysisStoppedOrFailed}
              hasInvalidTypes={hasInvalidTypes}
              installationWasStopped={installationWasStopped}
              showProgress={showProgress}
              analysisProgress={analysisProgress}
              attributeKey={attributeKey}
              parkId={parkId}
              branchId={branchId}
              comparisonMode={comparisonMode}
            />
          );
        })}
      </>
    );
  }

  const valueIsDefined = isDefined(value);

  if (attribute.key === "averageTurbineHeight") {
    const hasChangedHubHeight =
      Boolean(selectedHubHeight) && selectedHubHeight !== averageTurbineHeight;
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedHubHeight}
      >
        <HubHeightSelector
          formatFunction={attribute.format}
          originalAverageTurbineHeight={averageTurbineHeight}
          selectedHubHeight={selectedHubHeight}
          setSelectedHubHeight={setSelectedHubHeight}
          hasChangedHubHeight={hasChangedHubHeight}
        />
      </AttributeValue>
    );
  }

  if (attribute.key === "turbineTypesString") {
    const hasChangedTurbineType =
      Boolean(selectedTurbineType) &&
      selectedTurbineType !== attribute.format(value);
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedTurbineType}
      >
        <TurbineTypeSelector
          turbineTypesString={value as string}
          selectedTurbineType={selectedTurbineType}
          setSelectedTurbineType={setSelectedTurbineType}
          hasChangedTurbineType={hasChangedTurbineType}
          averageTurbineHeight={averageTurbineHeight}
        />
      </AttributeValue>
    );
  }

  if (attribute.key === "exportCableTypesString") {
    const hasChangedExportCableType =
      Boolean(selectedExportCableType) &&
      selectedExportCableType !== attribute.format(value);
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedExportCableType}
      >
        <ExportCableTypeSelector
          parkId={parkId}
          branchId={branchId}
          exportCableTypesString={value as string}
          selectedExportCableType={selectedExportCableType}
          setSelectedExportCableType={setSelectedExportCableType}
          hasChangedExportCableType={hasChangedExportCableType}
        />
      </AttributeValue>
    );
  }

  if (attribute.key === "maxPower") {
    const hasChangedMaxPower = Boolean(selectedMaxPower);
    return (
      <AttributeValue
        textRight
        data-attribute-value={1}
        overflowVisible={true}
        warning={hasChangedMaxPower}
      >
        {!valueIsDefined ? (
          <SkeletonText
            style={{
              width: "50%",
              minHeight: "2rem",
            }}
            text={`${showProgress ? `${Math.round(analysisProgress! * 100)}%` : ""}`}
          />
        ) : (
          <MaxPowerSelector
            formatFunction={attribute.format}
            parkCapacity={parkCapacity}
            selectedMaxPower={value as number}
            setSelectedMaxPower={setSelectedMaxPower}
            hasChangedMaxPower={hasChangedMaxPower}
          />
        )}
      </AttributeValue>
    );
  }

  return (
    <AttributeValue textRight data-attribute-value={1}>
      {(analysisStoppedOrFailed || hasInvalidTypes || installationWasStopped) &&
      attribute.key !== "area" &&
      attribute.key !== "subArea" ? ( // Always show area as the area is always calculated
        <p>-</p>
      ) : !valueIsDefined ? (
        <SkeletonText
          style={{
            width: "50%",
            minHeight: "2rem",
          }}
          text={`${showProgress ? `${Math.round(analysisProgress! * 100)}%` : ""}`}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: spacing8,
            flexDirection: "row-reverse",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: spacing2,
            }}
          >
            {attribute.format(value)}
          </div>
          {typeof baseline !== "undefined" && (
            <ComparisonValue
              baselineValue={baseline}
              attribute={attribute}
              value={value}
              comparisonMode={comparisonMode}
            />
          )}
        </div>
      )}
      {attributeKey === ComparisonAttributeKey.CABLING && (
        <ElectricalStatError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.WIND && (
        <ProductionError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.PRODUCTION && (
        <ProductionError parkId={parkId} branchId={branchId} />
      )}
      {attributeKey === ComparisonAttributeKey.FINANCIAL && (
        <ParkHasAnyValidationError parkId={parkId} branchId={branchId} />
      )}
    </AttributeValue>
  );
}

const SelectedConfigDiffersHelpTooltip = ({
  nameOfDefault,
}: {
  nameOfDefault?: string;
}) => {
  return (
    <div
      style={{
        flexShrink: 0,
      }}
    >
      <HelpTooltip
        text={"Selected config differs from branch default".concat(
          nameOfDefault ? ` (${nameOfDefault})` : "",
        )}
        color={colors.black}
      />
    </div>
  );
};

const ParkAttributes = React.memo(
  ({
    parkId,
    baseline,
    branchId,
    branch,
    analysisVersion,
    selectedAttributes,
    parkComparisonValues,
    selectedConfig,
    setSelectedConfig,
    defaultAnalysisConfig,
    selectedWindConfig,
    setSelectedWindConfig,
    defaultWindConfig,
    selectedCostConfig,
    setSelectedCostConfig,
    defaultCostConfig,
    selectedOperationsConfig,
    setSelectedOperationsConfig,
    defaultOperationsConfig,
    averageTurbineHeight,
    selectedHubHeight,
    setSelectedHubHeight,
    selectedMaxPower,
    parkCapacity,
    setSelectedMaxPower,
    selectedTurbineType,
    setSelectedTurbineType,
    selectedExportCableType,
    setSelectedExportCableType,
    analysisStoppedOrFailed,
    hasInvalidTypes,
    installationWasStopped,
    analysisProgress,
  }: {
    parkId: string;
    branchId: string;
    branch: BranchMeta;
    analysisVersion?: string;
    selectedAttributes: Record<ComparisonAttributeKey, string[]>;
    parkComparisonValues: Record<
      ComparisonAttributeKey,
      Record<string, unknown>
    >;
    baseline?: Record<ComparisonAttributeKey, Record<string, unknown>>;
    selectedConfig: AnalysisConfiguration | undefined;
    defaultAnalysisConfig?: AnalysisConfiguration;
    setSelectedConfig: (config: AnalysisConfiguration) => void;
    selectedWindConfig: WindSourceConfiguration;
    setSelectedWindConfig: (config: WindSourceConfiguration) => void;
    defaultWindConfig?: WindSourceConfiguration;
    selectedCostConfig: CostConfiguration;
    setSelectedCostConfig: (config: CostConfiguration) => void;
    defaultCostConfig?: CostConfiguration;
    selectedOperationsConfig: OperationsConfiguration | undefined;
    setSelectedOperationsConfig: (config: OperationsConfiguration) => void;
    defaultOperationsConfig?: OperationsConfiguration;
    averageTurbineHeight: number;
    selectedHubHeight: number | undefined;
    setSelectedHubHeight: (selectedHubHeight: number | undefined) => void;
    selectedMaxPower: number | undefined;
    parkCapacity: number;
    setSelectedMaxPower: (selectedMaxPower: number | undefined) => void;
    selectedTurbineType: SimpleTurbineType | undefined;
    setSelectedTurbineType: (
      turbineType: SimpleTurbineType | undefined,
    ) => void;
    selectedExportCableType: CableType | undefined;
    setSelectedExportCableType: (
      exportCableType: CableType | undefined,
    ) => void;
    analysisStoppedOrFailed: boolean;
    hasInvalidTypes: boolean;
    installationWasStopped: boolean;
    analysisProgress: number | undefined;
  }) => {
    const columnTemplates = useColumnTemplates();
    const visibleComparisonListRows = useAtomValue(
      visibleComparisonListRowsAtom,
    );
    const isOnshore = useAtomValue(isOnshoreAtom);
    const comparisonMode = useAtomValue(comparisonModeAtom);
    return (
      <>
        <AttributeHeader />
        <AttributeValue
          textRight
          style={{
            gap: spacing4,
          }}
          warning={selectedCostConfig.id !== branch.costConfigurationId}
          data-attribute-value={1}
        >
          {selectedCostConfig.id !== branch.costConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultCostConfig?.name}
            />
          )}
          <CostConfigSelector
            selectedConfig={selectedCostConfig}
            setSelectedConfig={setSelectedCostConfig}
          />
        </AttributeValue>
        <AttributeValue
          textRight
          warning={selectedConfig?.id !== branch.analysisConfigurationId}
          style={{
            gap: spacing4,
          }}
          data-attribute-value={1}
        >
          {selectedConfig?.id !== branch.analysisConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultAnalysisConfig?.name}
            />
          )}
          <AnalysisConfigSelector
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
          />
        </AttributeValue>
        <AttributeValue
          textRight
          warning={selectedWindConfig?.id !== branch.windConfigurationId}
          style={{
            gap: spacing4,
          }}
          data-attribute-value={1}
        >
          {selectedWindConfig?.id !== branch.windConfigurationId && (
            <SelectedConfigDiffersHelpTooltip
              nameOfDefault={defaultWindConfig?.name}
            />
          )}
          <WindConfigSelector
            selectedConfig={selectedWindConfig}
            setSelectedConfig={setSelectedWindConfig}
          />
        </AttributeValue>
        {!isOnshore && (
          <AttributeValue
            textRight
            warning={
              selectedOperationsConfig?.id !== branch.operationsConfigurationId
            }
            style={{
              gap: spacing4,
            }}
            data-attribute-value={1}
          >
            {selectedOperationsConfig?.id !==
              branch.operationsConfigurationId && (
              <SelectedConfigDiffersHelpTooltip
                nameOfDefault={defaultOperationsConfig?.name}
              />
            )}
            <OperationsConfigSelector
              selectedConfig={selectedOperationsConfig}
              setSelectedConfig={setSelectedOperationsConfig}
            />
          </AttributeValue>
        )}
        <AttributeValue textRight data-attribute-value={1}>
          {analysisVersion}
        </AttributeValue>

        {Object.entries(columnTemplates).map(
          ([attributeKey, columnTemplate]) => {
            const typedAttributeKey = attributeKey as ComparisonAttributeKey;
            const visibleAttributes = columnTemplate.attributes.filter((f) =>
              (selectedAttributes as any)[typedAttributeKey]?.includes(f.key),
            );

            if (visibleAttributes.length === 0) {
              return null;
            }
            const showProgress =
              analysisProgress && columnTemplate.name === "Production";
            return (
              <div key={typedAttributeKey}>
                <AttributeHeader />
                {visibleAttributes.map((attribute) => {
                  return (
                    <AttributeValueFlattener
                      key={attribute.key}
                      attribute={attribute}
                      visibleIds={visibleComparisonListRows[attribute.key]}
                      averageTurbineHeight={averageTurbineHeight}
                      selectedHubHeight={selectedHubHeight}
                      setSelectedHubHeight={setSelectedHubHeight}
                      parkCapacity={parkCapacity}
                      selectedMaxPower={selectedMaxPower}
                      setSelectedMaxPower={setSelectedMaxPower}
                      selectedTurbineType={selectedTurbineType}
                      setSelectedTurbineType={setSelectedTurbineType}
                      selectedExportCableType={selectedExportCableType}
                      setSelectedExportCableType={setSelectedExportCableType}
                      analysisStoppedOrFailed={analysisStoppedOrFailed}
                      hasInvalidTypes={hasInvalidTypes}
                      installationWasStopped={installationWasStopped}
                      showProgress={showProgress}
                      analysisProgress={analysisProgress}
                      baseline={baseline?.[typedAttributeKey][attribute.key]}
                      attributeKey={typedAttributeKey}
                      parkId={parkId}
                      branchId={branchId}
                      value={
                        parkComparisonValues[typedAttributeKey][attribute.key]
                      }
                      comparisonMode={comparisonMode}
                    />
                  );
                })}
              </div>
            );
          },
        )}
      </>
    );
  },
);

const ClickableParkName = ({
  projectId,
  selectedPark,
  children,
}: {
  projectId: string;
  selectedPark: EnrichedSelectedParkCompare;
} & React.PropsWithChildren) => {
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);
  const organisationId = useAtomValue(organisationIdAtom);
  const { navigateToPark } = useNavigateToPark();
  const { setCurrentSelectionArray } = useSelectionInMap();
  const project = useAtomValue(
    customerProjectAtomFamily({
      nodeId: projectId,
    }),
  );

  const setModalOpen = useSetAtom(modalTypeOpenAtom);

  return (
    <Tooltip
      text="Go to park"
      outerDivStyle={{
        overflowX: "hidden",
      }}
      innerDivStyle={{
        overflowX: "hidden",
      }}
    >
      <Link
        style={{
          ...typography.h4,
          textDecoration: "none",
        }}
        to={{
          pathname: `/${getPathPrefix(project)}/project/${organisationId}/${projectId}/${selectedPark.branchId}/${selectedPark.parkId}`,
        }}
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(undefined);
          navigateToPark(selectedPark.parkId, selectedPark.branchId);
          goToFeatures([selectedPark.park]);
          setCurrentSelectionArray([selectedPark.parkId]);
        }}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

const ParkImageWrapper = styled.div`
  position: relative;
  background-color: #a9c6e8;
  height: 18rem;
  display: flex;
  justify-content: center;
`;

const parkImageStyle: React.CSSProperties = {
  width: "100%",
  height: "18rem",
};

export const PARK_DRAG_TYPE = "PARK";

const InnerErrorWrapper = ({
  selectedPark,
  onRemove,
  index,
  maxIndex,
  movePark,
  triggerId,
}: {
  selectedPark: EnrichedSelectedParkCompare;
  onRemove(parkId: string, branchId: string, comparisonId: string): void;
  index: number;
  maxIndex: number;
  movePark: (fromIndex: number, toIndex: number) => void;
  triggerId: FinanceId;
}) => {
  const projectId = useAtomValue(getProjectId(triggerId));

  const currentSelectionProductionLoadable = useAtomValue(
    loadable(getConfiguration(triggerId)),
  );

  const currentWindSourceConfigurationLoadable = useAtomValue(
    loadable(getWindSourceConfiguration(triggerId)),
  );

  const onSelectAnalysisConfigOnError = useJotaiCallback(
    async (get, set, config: AnalysisConfiguration) => {
      const atom = selectedParksAtom({
        projectId,
      });
      const curr = await get(atom);
      set(
        atom,
        curr.map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedAnalysisConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [projectId, selectedPark.comparisonId],
  );

  const onSelectWindAnalysisConfigOnError = useJotaiCallback(
    async (get, set, config: WindSourceConfiguration) => {
      const atom = selectedParksAtom({
        projectId,
      });
      const curr = await get(atom);
      set(
        atom,
        curr.map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedWindConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [projectId, selectedPark.comparisonId],
  );

  const resetKeys = useMemo(
    () => [
      currentSelectionProductionLoadable.state,
      currentWindSourceConfigurationLoadable.state,
    ],
    [
      currentSelectionProductionLoadable,
      currentWindSourceConfigurationLoadable,
    ],
  );

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <AnalysisConfigNotFoundCompareErrorBoundaryFallbackRender
          error={error}
          onSelectAnalysisConfig={onSelectAnalysisConfigOnError}
          onSelectWindConfig={onSelectWindAnalysisConfigOnError}
        />
      )}
      resetKeys={resetKeys}
    >
      <Inner
        selectedPark={selectedPark}
        onRemove={onRemove}
        index={index}
        maxIndex={maxIndex}
        movePark={movePark}
        triggerId={triggerId}
      />
    </ErrorBoundary>
  );
};

const Inner = ({
  selectedPark,
  onRemove,
  index,
  maxIndex,
  movePark,
  triggerId,
}: {
  selectedPark: EnrichedSelectedParkCompare;
  onRemove(parkId: string, branchId: string, comparisonId: string): void;
  index: number;
  maxIndex: number;
  movePark: (fromIndex: number, toIndex: number) => void;
  triggerId: FinanceId;
}) => {
  const projectId = useAtomValue(getProjectId(triggerId));
  const [baselineComparison, setBaselineComparison] = useAtom(
    baselineComparisonValuesAtom,
  );
  const ref = useRef<HTMLInputElement>(null);

  const [hoverState, setHoverState] = useState<undefined | "start" | "end">(
    undefined,
  );
  const thisComparisonIsBaseline = Boolean(
    baselineComparison?.comparisonId &&
      baselineComparison.comparisonId === selectedPark.comparisonId,
  );

  const isProjectEditor = useAtomValue(editorAccessProjectSelector);

  const branchId = useAtomValue(getBranchId(triggerId));
  const branch = useAtomValue(
    branchMetaFamily({
      branchId,
      projectId,
    }),
  );

  const invalids = useAtomValue(
    invalidTypesInParkFamily({
      parkId: selectedPark.parkId,
      branchId: selectedPark.branchId,
    }),
  );
  const hasInvalidTypes = Object.values(invalids).some((o) => o !== undefined);

  const installationStopped = useAtomUnwrap(
    getInstallationCostStoppedReason(triggerId),
  );

  const outdated = useAtomValue(unwrap(getIsOutdated(triggerId)));
  const [restarted, setRestarted] = useState<boolean>(false);
  const restartProduction = useAnalysisForceRestart(triggerId);
  const [analysisStopped, setAnalysisStopped] = useState<string>();
  const [analysisFailed, setAnalysisFailed] = useState<boolean>(false);
  const analysisStoppedOrFailed: boolean = !!analysisStopped || analysisFailed;
  const setVisibleComparisonListRows = useSetAtom(
    visibleComparisonListRowsAtom,
  );

  const parkComparisonValues = useParkComparisonValues(
    triggerId,
    selectedPark.park,
    selectedPark.branchId,
    selectedPark.selectedHubHeight,
    selectedPark.selectedMaxPower,
    selectedPark.selectedTurbineType,
    selectedPark.selectedExportCableType,
  );

  const cableTypes = parkComparisonValues[ComparisonAttributeKey.CABLING]
    .cableTypes as Record<string, number> | Record<string, undefined>;
  useEffect(() => {
    if (cableTypes) {
      const ids = Object.entries(cableTypes)
        .filter(([_key, value]) => typeof value !== "undefined" && value > 0)
        .map(([key]) => key);
      setVisibleComparisonListRows((curr) => {
        if (!curr.cableTypes) {
          return {
            ...curr,
            cableTypes: ids,
          };
        } else {
          return {
            ...curr,
            cableTypes: dedup([...curr.cableTypes, ...ids]),
          };
        }
      });
    }
  }, [cableTypes, setVisibleComparisonListRows, maxIndex]);

  const handleRemove = useCallback(() => {
    onRemove(
      selectedPark.parkId,
      selectedPark.branchId,
      selectedPark.comparisonId,
    );

    if (baselineComparison?.comparisonId === selectedPark.comparisonId) {
      setBaselineComparison(undefined);
    }
  }, [
    baselineComparison?.comparisonId,
    onRemove,
    selectedPark.branchId,
    selectedPark.comparisonId,
    selectedPark.parkId,
    setBaselineComparison,
  ]);

  const [dropCollection, drop] = useDrop(
    () => ({
      accept: PARK_DRAG_TYPE,
      hover: (hoveredItem, monitor) => {
        if (!monitor.isOver() || hoveredItem.index === index) {
          return setHoverState(undefined);
        }
        const hoveringRight = cursorIsInRightHalfOfElement(
          ref.current,
          monitor.getClientOffset(),
        );
        setHoverState(hoveringRight ? "end" : "start");
      },
      collect: (monitor) => {
        const isHovered = monitor.isOver() && monitor.canDrop();
        return {
          isHovered,
        };
      },
      drop: (
        draggedItem: {
          index: number;
        },
        monitor: DropTargetMonitor,
      ) => {
        const rect = ref.current?.getBoundingClientRect();
        if (!rect) {
          return {
            droppedOnWrapper: false,
          };
        }
        if (draggedItem.index === index) {
          return {
            droppedOnWrapper: false,
          };
        }

        const isRight = cursorIsInRightHalfOfElement(
          ref.current,
          monitor.getClientOffset(),
        );

        const newIndex = draggedItem.index > index ? index + 1 : index;

        movePark(draggedItem.index, isRight ? newIndex : newIndex - 1);
        return {
          droppedOnWrapper: false,
        };
      },
    }),
    [index, movePark],
  );

  const [{ isDragging }, drag] = useDrag<
    any,
    {
      droppedOnWrapper: boolean;
    },
    {
      isDragging: boolean;
    }
  >(
    () => ({
      type: PARK_DRAG_TYPE,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (draggedItem, monitor) => {
        if (monitor.getDropResult()?.droppedOnWrapper) {
          movePark(draggedItem.index, maxIndex);
        }
      },
    }),
    [index, maxIndex, movePark],
  );

  useEffect(() => {
    if (thisComparisonIsBaseline) {
      setBaselineComparison((curr) => {
        if (!curr) {
          return curr;
        }
        return {
          comparisonId: curr?.comparisonId,
          values: parkComparisonValues,
        };
      });
    }
  }, [
    parkComparisonValues,
    selectedPark.comparisonId,
    setBaselineComparison,
    thisComparisonIsBaseline,
  ]);

  const onSetBaselineClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (Boolean(analysisFailed) || Boolean(analysisStopped)) {
      return;
    }

    setBaselineComparison((curr) => {
      if (curr?.comparisonId === selectedPark.comparisonId) {
        return undefined;
      }
      return {
        comparisonId: selectedPark.comparisonId,
        values: parkComparisonValues,
      };
    });
  };

  drag(drop(ref));

  const onDuplicate = useJotaiCallback(
    async (get, set) => {
      if (!branch) {
        return;
      }

      const curr = await get(selectedParksAtom({ projectId }));
      set(
        selectedParksAtom({ projectId }),
        curr.concat({
          ...selectedPark,
          comparisonId: uuid(),
        }),
      );
    },
    [branch, projectId, selectedPark],
  );

  return (
    <>
      <ColumnTemplateWithBorder
        isBaseline={thisComparisonIsBaseline}
        isError={
          analysisStoppedOrFailed ||
          hasInvalidTypes ||
          installationStopped !== undefined
        }
        isHoveredStart={dropCollection.isHovered && hoverState === "start"}
        isHoveredEnd={dropCollection.isHovered && hoverState === "end"}
        ref={ref}
        style={{
          opacity: isDragging ? 0.3 : 1,
        }}
        data-park={1}
      >
        <StickyHeader>
          <StickyHeaderBorderContent
            isBaseline={thisComparisonIsBaseline}
            isError={
              analysisStoppedOrFailed ||
              hasInvalidTypes ||
              installationStopped !== undefined
            }
          >
            <div
              style={{
                display: "flex",
                gap: spacing5,
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <DnDWrapper>
                <DnDIconSmall />
              </DnDWrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing4,
                  cursor: "pointer",
                  padding: `${spacing4} 0 ${spacing4} ${spacing4}`,
                }}
                onClick={onSetBaselineClick}
              >
                <Button
                  disabled={Boolean(analysisFailed) || Boolean(analysisStopped)}
                  buttonType="secondary"
                  style={{
                    padding: "0.4rem 1.6rem",
                    backgroundColor: thisComparisonIsBaseline
                      ? colors.indigo200
                      : undefined,
                  }}
                  size="small"
                  text="Baseline"
                  onClick={onSetBaselineClick}
                />
              </div>
              <MenuWrapper>
                <DotMenu>
                  <MenuItem
                    name={"Duplicate"}
                    onClick={onDuplicate}
                    icon={<DuplicateIcon />}
                  />
                </DotMenu>
              </MenuWrapper>
            </div>
            <ColumnHeader style={{ position: "relative" }}>
              <ParkName>
                <ClickableParkName
                  selectedPark={selectedPark}
                  projectId={projectId}
                >
                  {selectedPark.park.properties.name}
                </ClickableParkName>
              </ParkName>
              <p style={typography.body}>{branch?.title}</p>
              <IconBtn
                onClick={handleRemove}
                size="0.8rem"
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  top: "0.8rem",
                }}
              >
                <CloseIcon />
              </IconBtn>
            </ColumnHeader>
            {analysisStoppedOrFailed && (
              <ErrorWrapper
                style={{
                  position: "relative",
                }}
              >
                <SimpleAlert
                  title={analysisStopped ? "Analysis stopped" : undefined}
                  text={
                    analysisStopped
                      ? analysisStoppedText[
                          analysisStopped as AnalysisStoppedTypes
                        ]
                      : "Production analysis failed. The Vind team has been notified."
                  }
                  type={"error"}
                />
              </ErrorWrapper>
            )}
            {outdated && !analysisStoppedOrFailed && (
              <ErrorWrapper
                style={{
                  position: "relative",
                  width: "96%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <WarningCircle title={"Analysis is outdated"} />
                <p>Analysis is outdated</p>
                <Button
                  text={"Rerun"}
                  style={{
                    height: "2rem",
                    margin: 0,
                  }}
                  onClick={() => {
                    setRestarted(true);
                    restartProduction();
                  }}
                  disabled={restarted || !isProjectEditor}
                />
              </ErrorWrapper>
            )}
            {hasInvalidTypes && !analysisStoppedOrFailed && (
              <ErrorWrapper
                style={{
                  position: "relative",
                }}
              >
                <SimpleAlert text={"Some types in the park are invalid"} />
              </ErrorWrapper>
            )}
            {installationStopped !== undefined && (
              <ErrorWrapper
                style={{
                  position: "relative",
                }}
              >
                <SimpleAlert
                  text={installationStoppedText[installationStopped]}
                />
              </ErrorWrapper>
            )}
          </StickyHeaderBorderContent>
        </StickyHeader>
        <ParkImageWrapper>
          <DontRenderWhenCheckly>
            <ParkWithChildrenImage
              park={selectedPark.park}
              branchId={selectedPark.branchId}
              style={parkImageStyle}
              mapboxImageSize="200x200"
              noExportCable
            />
          </DontRenderWhenCheckly>
        </ParkImageWrapper>

        <ErrorSafeParkComparisonValues
          parkComparisonValues={parkComparisonValues}
          selectedPark={selectedPark}
          triggerId={triggerId}
          setAnalysisStopped={setAnalysisStopped}
          setAnalysisFailed={setAnalysisFailed}
          analysisStoppedOrFailed={analysisStoppedOrFailed}
          hasInvalidTypes={hasInvalidTypes}
          installationWasStopped={installationStopped !== undefined}
        />
      </ColumnTemplateWithBorder>
    </>
  );
};

const ErrorSafeParkComparisonValues = ({
  parkComparisonValues,
  selectedPark,
  triggerId,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  installationWasStopped,
  setAnalysisStopped,
  setAnalysisFailed,
}: {
  parkComparisonValues: Record<ComparisonAttributeKey, Record<string, unknown>>;
  selectedPark: EnrichedSelectedParkCompare;
  triggerId: ProdId & FinanceId;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  installationWasStopped: boolean;
  setAnalysisStopped: (analysisStopped: any) => void;
  setAnalysisFailed: (analysisFailed: boolean) => void;
}) => {
  const projectId = useAtomValue(getProjectId(triggerId));

  const currentCostConfigurationLoadable = useAtomValue(
    loadable(
      costConfigurationsFamily({
        projectId,
      }),
    ),
  );

  const emptyCostConfig = useMemo(
    () =>
      currentCostConfigurationLoadable.state === "hasData" &&
      currentCostConfigurationLoadable.data.size === 0,
    [currentCostConfigurationLoadable],
  );

  return (
    <>
      {currentCostConfigurationLoadable.state === "hasData" &&
        currentCostConfigurationLoadable.data.size === 0 && (
          <div>
            <SimpleAlert
              text={
                "No financial configurations exist for this project. Go to Project configurations to create one."
              }
              type={"error"}
            />
          </div>
        )}
      {!emptyCostConfig && (
        <ParkComparisonValues
          parkComparisonValues={parkComparisonValues}
          selectedPark={selectedPark}
          triggerId={triggerId}
          setAnalysisStopped={setAnalysisStopped}
          setAnalysisFailed={setAnalysisFailed}
          analysisStoppedOrFailed={analysisStoppedOrFailed}
          hasInvalidTypes={hasInvalidTypes}
          installationWasStopped={installationWasStopped}
        />
      )}
    </>
  );
};

const ParkComparisonValues = ({
  parkComparisonValues,
  selectedPark,
  triggerId,
  analysisStoppedOrFailed,
  hasInvalidTypes,
  installationWasStopped,
  setAnalysisStopped,
  setAnalysisFailed,
}: {
  parkComparisonValues: Record<ComparisonAttributeKey, Record<string, unknown>>;
  selectedPark: EnrichedSelectedParkCompare;
  triggerId: ProdId & FinanceId;
  analysisStoppedOrFailed: boolean;
  hasInvalidTypes: boolean;
  installationWasStopped: boolean;
  setAnalysisStopped: (analysisStopped: any) => void;
  setAnalysisFailed: (analysisFailed: boolean) => void;
}) => {
  const projectId = useAtomValue(getProjectId(triggerId));

  const selectedAttributes = useAtomValue(selectedComparisonAttributesAtom);
  const setShownData = useSetAtom(shownCompareDataAtom);

  const branchId = useAtomValue(getBranchId(triggerId));
  const branch = useAtomValue(
    branchMetaFamily({
      branchId,
      projectId,
    }),
  );
  if (!branch) throw new Error(`Illegal branch id "${branchId}"`);

  const selectedAnalysisConfig = useAtomValue(getConfiguration(triggerId));

  const selectedWindConfig = useAtomValue(
    getWindSourceConfiguration(triggerId),
  );

  const analysisVersion = useAtomUnwrap(getAnalysisVersion(triggerId));
  const capacity = useAtomValue(getTurbineCapacity(triggerId));
  const averageTurbineHeight = useAtomValue(getAverageHubHeight(triggerId));
  const baselineComparisonValues = useAtomValue(baselineComparisonValuesAtom);

  const costConfigurations = useAtomValue(
    costConfigurationsFamily({
      projectId,
    }),
  );

  const operationsConfigurations = useAtomValue(
    operationsConfigurationsFamily({
      projectId,
    }),
  );

  const defaultBranchAnalysisConfig = useAtomValue(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const defaultBranchCostConfig = useMemo(
    () => costConfigurations.get(branch?.costConfigurationId ?? ""),
    [costConfigurations, branch?.costConfigurationId],
  );

  if (!isDefined(defaultBranchCostConfig)) {
    throw new Error("Default cost configuration for branch not found");
  }

  const defaultBranchWindConfig = useAtomValue(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const defaultBranchOperationsConfig = useAtomValue(
    operationsConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );

  const [selectedCostConfig, setSelectedCostConfig] =
    useState<CostConfiguration>(
      costConfigurations.get(selectedPark.selectedCostConfigurationId ?? "") ??
        costConfigurations.get(branch?.costConfigurationId ?? "") ??
        Array.from(costConfigurations.values())[0],
    );

  if (!isDefined(selectedCostConfig)) {
    throw new Error("Cost configuration not found");
  }

  const [selectedOperationsConfig, setSelectedOperationsConfig] =
    useState<OperationsConfiguration>(
      operationsConfigurations.get(
        selectedPark.selectedOperationsConfigurationId ?? "",
      ) ??
        operationsConfigurations.get(branch?.operationsConfigurationId ?? "") ??
        Array.from(operationsConfigurations.values())[0],
    );

  const onSelectAnalysisConfig = useJotaiCallback(
    async (get, set, config: AnalysisConfiguration) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedAnalysisConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectWindConfig = useJotaiCallback(
    async (get, set, config: WindSourceConfiguration) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedWindConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectCostConfig = useJotaiCallback(
    async (get, set, config: CostConfiguration) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      setSelectedCostConfig(config);
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedCostConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectOperationsConfig = useJotaiCallback(
    async (get, set, config: OperationsConfiguration) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      setSelectedOperationsConfig(config);
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedOperationsConfigurationId: config.id,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectHubHeight = useJotaiCallback(
    async (get, set, selectedHubHeight: number | undefined) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedHubHeight: selectedHubHeight,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectMaxPower = useJotaiCallback(
    async (get, set, selectedMaxPower: number | undefined) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedMaxPower: selectedMaxPower,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectTurbineType = useJotaiCallback(
    async (get, set, selectedTurbineType: SimpleTurbineType | undefined) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedTurbineType: selectedTurbineType,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const onSelectExportCableType = useJotaiCallback(
    async (get, set, selectedExportCableType: CableType | undefined) => {
      const curr = await get(
        selectedParksAtom({
          projectId,
        }),
      );
      set(
        selectedParksAtom({
          projectId,
        }),
        [...curr].map((p) => {
          return p.comparisonId === selectedPark.comparisonId
            ? {
                ...p,
                selectedExportCableType: selectedExportCableType,
              }
            : p;
        }),
      );
    },
    [selectedPark.comparisonId, projectId],
  );

  const analysisProgress = parkComparisonValues.PRODUCTION.progress;

  useEffect(() => {
    setAnalysisStopped(parkComparisonValues.PRODUCTION.stoppedReason);
    setAnalysisFailed(parkComparisonValues.PRODUCTION.status === "failed");
  }, [parkComparisonValues, setAnalysisFailed, setAnalysisStopped]);

  useEffect(() => {
    setShownData((prev) => {
      const p = prev[selectedPark.comparisonId];
      if (!p) {
        return {
          ...prev,
          [selectedPark.comparisonId]: {
            branchId: selectedPark.branchId,
            parkId: selectedPark.parkId,
            analysisConfigurationId:
              selectedPark.selectedAnalysisConfigurationId,
            windConfigurationId: selectedPark.selectedWindConfigurationId,
            costConfigurationId: selectedPark.selectedCostConfigurationId,
            operationsConfigurationId:
              selectedPark.selectedOperationsConfigurationId,
            analysisVersion: analysisVersion,
            comparisonData: parkComparisonValues,
          },
        };
      }

      if (!objectEquals(p.comparisonData, parkComparisonValues)) {
        return {
          ...prev,
          [selectedPark.comparisonId]: {
            ...prev[selectedPark.comparisonId],
            comparisonData: parkComparisonValues,
          },
        };
      }
      return prev;
    });

    return () => {
      setShownData((prev) => {
        const clone = JSON.parse(JSON.stringify(prev)) as typeof prev;
        delete clone[selectedPark.comparisonId];
        return clone;
      });
    };
  }, [
    parkComparisonValues,
    analysisVersion,
    selectedPark.branchId,
    selectedPark.comparisonId,
    selectedPark.parkId,
    selectedPark.selectedAnalysisConfigurationId,
    selectedPark.selectedCostConfigurationId,
    selectedPark.selectedWindConfigurationId,
    selectedPark.selectedOperationsConfigurationId,
    setShownData,
  ]);

  return (
    <ParkAttributes
      key={selectedPark.park.id}
      baseline={
        baselineComparisonValues?.comparisonId !== selectedPark.comparisonId
          ? baselineComparisonValues?.values
          : undefined
      }
      branch={branch}
      parkId={selectedPark.parkId}
      parkCapacity={capacity}
      branchId={selectedPark.branchId}
      analysisVersion={analysisVersion}
      selectedAttributes={selectedAttributes}
      parkComparisonValues={parkComparisonValues}
      selectedConfig={selectedAnalysisConfig}
      defaultCostConfig={defaultBranchCostConfig}
      defaultAnalysisConfig={defaultBranchAnalysisConfig}
      defaultWindConfig={defaultBranchWindConfig}
      defaultOperationsConfig={defaultBranchOperationsConfig}
      setSelectedConfig={onSelectAnalysisConfig}
      selectedWindConfig={selectedWindConfig}
      setSelectedWindConfig={onSelectWindConfig}
      selectedCostConfig={selectedCostConfig}
      setSelectedCostConfig={onSelectCostConfig}
      selectedOperationsConfig={selectedOperationsConfig}
      setSelectedOperationsConfig={onSelectOperationsConfig}
      averageTurbineHeight={averageTurbineHeight}
      selectedHubHeight={selectedPark.selectedHubHeight}
      setSelectedHubHeight={onSelectHubHeight}
      selectedTurbineType={selectedPark.selectedTurbineType}
      setSelectedTurbineType={onSelectTurbineType}
      selectedExportCableType={selectedPark.selectedExportCableType}
      setSelectedExportCableType={onSelectExportCableType}
      selectedMaxPower={selectedPark.selectedMaxPower}
      setSelectedMaxPower={onSelectMaxPower}
      analysisStoppedOrFailed={analysisStoppedOrFailed}
      hasInvalidTypes={hasInvalidTypes}
      installationWasStopped={installationWasStopped}
      analysisProgress={analysisProgress as number}
    />
  );
};

const ComparisonPark = ErrorBoundaryWrapper(
  (props: {
    selectedPark: EnrichedSelectedParkCompare;
    onRemove(parkId: string, branchId: string, id: string): void;
    index: number;
    maxIndex: number;
    movePark: (fromIndex: number, toIndex: number) => void;
  }) => {
    const projectId = useAtomValue(projectIdAtom);

    const { selectedPark } = props;

    const triggerId = useMemo(
      () => `analysis-${selectedPark.comparisonId}` as ProdId & FinanceId,
      [selectedPark.comparisonId],
    );

    const setIsLoading = useSetAtom(compareIsLoading);
    useEffect(() => {
      setIsLoading((c) => ({
        ...c,
        [triggerId]: true,
      }));

      return () => {
        setIsLoading((c) => ({
          ...c,
          [triggerId]: undefined,
        }));
      };
    }, [triggerId, setIsLoading]);

    if (!projectId) return null;

    return (
      <TriggerCompareFinanceAndProduction
        triggerId={triggerId}
        projectId={projectId}
        selectedPark={selectedPark}
        fallback={
          <SkeletonBlock
            style={{
              width: ITEM_WIDTH,
              flexShrink: 0,
            }}
          />
        }
      >
        <InnerErrorWrapper {...props} triggerId={triggerId} />
      </TriggerCompareFinanceAndProduction>
    );
  },
  ErrorBoundaryLocalFallback,
  ScreamOnError,
);

export default React.memo(ComparisonPark);
