import { midScreenModalTypeOpenAtom, MidScreenModal } from "../../state/modal";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import styled from "styled-components";
import { useCallback, useMemo, useRef, useState } from "react";
import { currentSelectionArrayAtom } from "../../state/selection";
import { InputDimensioned } from "../General/Input";
import { ProjectFeature } from "../../types/feature";
import Button from "../General/Button";
import Checkbox from "../General/Checkbox";
import Tooltip from "../General/Tooltip";
import { isDefined } from "../../utils/predicates";
import { Column, ModalFrame } from "../General/Layout";
import { spaceLarge } from "../../styles/space";
import { resetListIfNotAlreadyEmpty } from "../../utils/resetList";
import { Distance } from "components/Units/units";
import { useToast } from "hooks/useToast";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { featureMapFamily } from "state/jotai/features";
import { scream } from "utils/sentry";
import { BufferWorkerArgs } from "./bufferWorker";
import { promiseWorker, typedWorker } from "utils/utils";
import useAddFeaturesIntoElementsWithinLimits from "hooks/useAddFeaturesIntoElementsWithinLimits";

export const BufferModalType = "BufferModal";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
  }
`;

const unitAtom = atom(Distance.cannonical);

const BufferModalInner = ({
  modal,
}: {
  modal: MidScreenModal<typeof BufferModalType>;
}) => {
  const [value, setValue] = useState<number | undefined>();
  const [unit, setUnit] = useAtom(unitAtom);
  const formRef = useRef<HTMLFormElement>(null);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const rawSelection = useAtomValue(currentSelectionArrayAtom);
  const [isLoading, setIsLoading] = useState(false);

  const featureMap = useAtomValue(
    featureMapFamily({
      branchId: undefined,
    }),
  );
  const updateFeatures = useAddFeaturesIntoElementsWithinLimits();

  const [pencilEnds, setPencilEnds] = useState<boolean>(false);

  const setCurrentSelectionArray = useSetAtom(currentSelectionArrayAtom);

  const { warning, error } = useToast();

  const selection: undefined | ProjectFeature | ProjectFeature[] =
    useMemo(() => {
      const featureSelection = modal.metadata?.selection;
      if (featureSelection === undefined) {
        const ids = rawSelection;
        const features = ids.map((id) => featureMap.get(id)).filter(isDefined);
        if (features.length === 1) return features[0];
        return features;
      }
      if (Array.isArray(featureSelection) && featureSelection.length < 2)
        return featureSelection[0];
      return featureSelection;
    }, [featureMap, modal.metadata, rawSelection]);

  const bufferFeature = useCallback(
    async (features: ProjectFeature[], val: number, _unit: Distance.Unit) => {
      setIsLoading(true);
      try {
        const worker = typedWorker<
          BufferWorkerArgs,
          [
            ProjectFeature[],
            string[],
            (
              | {
                  userErrorText: string;
                  errorText: string;
                  bufferedFeatures: string;
                }
              | undefined
            ),
          ]
        >(
          new Worker(new URL("./bufferWorker.ts", import.meta.url), {
            type: "module",
          }),
        );

        const bufferResults = await promiseWorker(
          worker,
          [features, val, _unit, pencilEnds],
          "buffer/BufferWorker",
        );
        const [splitUnionFeatures, warnings, errorResult] = bufferResults;
        worker.terminate();

        if (warnings.length > 0) {
          warning(warnings.join("\n"));
        }

        if (errorResult) {
          error(errorResult.userErrorText);
          scream(new Error(errorResult.errorText), {
            bufferedFeatures: errorResult.bufferedFeatures,
          });
          return;
        }

        updateFeatures({
          add: splitUnionFeatures,
        });
      } catch (e) {
        scream(e as Error);
        error(
          "Something went wrong when buffering features, the Vind team has been notified",
        );
        setIsLoading(false);
      } finally {
        setIsLoading(false);
        setCurrentSelectionArray(resetListIfNotAlreadyEmpty);
        setMidScreenModalTypeOpen(undefined);
      }
    },
    [
      pencilEnds,
      setCurrentSelectionArray,
      setMidScreenModalTypeOpen,
      updateFeatures,
      warning,
      error,
    ],
  );

  const onSubmit = useCallback(async () => {
    if (typeof value === "undefined") {
      return;
    }

    await bufferFeature(
      Array.isArray(selection) ? selection : [selection],
      value,
      unit,
    );
  }, [bufferFeature, selection, unit, value]);

  return (
    <FullScreenModal placeOnTopOfOtherModals={true}>
      <ModalFrame title={"Buffer feature"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          ref={formRef}
        >
          <Column
            style={{
              gap: spaceLarge,
            }}
          >
            <p>
              Create a new polygon around this feature that is the given
              distance away from it.
            </p>
            <p>
              For a smaller polygon (negative buffer), type a negative number.
            </p>

            <InputDimensioned
              required
              autoFocus
              unit={unit}
              value={value}
              units={Distance.units}
              onChange={setValue}
              onUnitChange={setUnit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (formRef.current?.reportValidity()) {
                    onSubmit();
                  } else {
                    e.preventDefault();
                  }
                }
              }}
              placeholder="Buffer size"
              style={{
                width: "100%",
              }}
            />

            {!Array.isArray(selection) &&
              selection.geometry.type === "LineString" &&
              2 <= selection.geometry.coordinates.length && (
                <Tooltip
                  text={
                    "Instead of buffering the whole line segment, buffer the middle segments as usual, and narrow in the buffer at the endpoints."
                  }
                >
                  <Checkbox
                    label="Narrow in towards endpoints"
                    labelPlacement="after"
                    checked={pencilEnds}
                    onChange={(e) => setPencilEnds(e.target.checked)}
                  />
                </Tooltip>
              )}

            <ButtonRow>
              <Button
                disabled={isLoading}
                text="Cancel"
                buttonType="secondary"
                onClick={() => setMidScreenModalTypeOpen(undefined)}
              />
              <Button
                text={isLoading ? "Buffering.." : "Buffer"}
                buttonType="primary"
                type="submit"
                disabled={isLoading}
              />
            </ButtonRow>
          </Column>
        </form>
      </ModalFrame>
    </FullScreenModal>
  );
};

const BufferModal = () => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  if (
    !midScreenModalTypeOpen ||
    midScreenModalTypeOpen?.modalType !== BufferModalType
  )
    return null;

  return <BufferModalInner modal={midScreenModalTypeOpen} />;
};

export default BufferModal;
