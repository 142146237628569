import React from "react";
import { downloadAppFile, downloadText } from "../../../../../utils/utils";
import DropdownButton from "../../../../General/Dropdown/DropdownButton";
import { TextNoMargin, DropdownText } from "../../shared";
import geoJsonExampleFile from "../example-files/example-feature.json";
//@ts-ignore
import zipExampleFile from "../example-files/example-feature.zip";

const NoFileSelectedWrapper = () => {
  return (
    <>
      <TextNoMargin>
        Supported formats: .geojson, .json, .zip, .shp, .kml, .kmz
      </TextNoMargin>
      <TextNoMargin>Max file size: 200MB zipped</TextNoMargin>
      <TextNoMargin>
        Coordinates closer than .00001 degrees in WGS84 are simplified and max 8
        decimals stored
      </TextNoMargin>
      <div style={{ display: "flex" }}>
        <DropdownButton
          items={[
            {
              name: "Feature (.geojson)",
              value: "geojson",
            },
            {
              name: "Feature (.zip shape files)",
              value: "zip",
            },
          ]}
          onSelectItem={(val) => {
            switch (val) {
              case "geojson":
                downloadText(
                  JSON.stringify(geoJsonExampleFile, null, 2),
                  "example-file.geojson",
                );
                break;
              case "zip":
                downloadAppFile(zipExampleFile, "example-file.zip");
                break;
            }
          }}
          buttonText="Download an example file"
          position="top"
          renderText={(text) => {
            return <DropdownText>{text}</DropdownText>;
          }}
          chevronStyle={{
            width: "0.8rem",
            height: "0.8rem",
          }}
        />
      </div>
    </>
  );
};

export default NoFileSelectedWrapper;
