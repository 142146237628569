import { useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useProjectTurbineCrud } from "components/ConfigurationModal/TurbineSettings/useNodeTurbineCrud";
import { useRefreshProjectTurbines } from "components/ConfigurationModal/TurbineSettings/useRefreshCustomTurbines";
import { selectedMenuItemState } from "components/SettingsV2/Shared/state";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { getAdvancedOrgTurbine } from "services/turbineAPIService";
import { scream } from "utils/sentry";
import {
  AnalysisConfiguration,
  createAnalysisConfigurationWithValues,
} from "services/configurationService";
import { CableType, createProjectCableType } from "services/cableTypeService";
import { savingConfigurationInProgressAtom } from "state/configuration";
import { ANALYSIS_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useAnalysisConfiguration";
import { useJotaiCallback } from "utils/jotai";
import useCableTypeCrud from "hooks/useCableTypeCrud";
import { projectAnalysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { designToolTypeAtom } from "state/map";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { CABLE_MENU_ID } from "./useCableSettings";
import { EXPORT_CABLE_MENU_ID } from "./useExportCableSettings";
import {
  CostConfiguration,
  CostType,
  createConfiguration,
  FoundationMaterialCost,
  isLibraryFoundationReferenceCost,
  LibraryFoundationReferenceCost,
} from "services/costService";
import { projectCostConfigurationsFamily } from "state/jotai/costConfiguration";
import { COST_MENU_ID } from "components/SettingsV2/ProjectConfiguration/Data/useCostConfiguration";
import { listProcurementCostForLibraryFoundationOnNode } from "services/procurementCostService";
import { CostUnit, CostWithUnit } from "types/financial";
import { DefaultCosts } from "components/ConfigurationModal/Cost/constants";

export function useDuplicateTurbineToProject() {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { error } = useToast();
  const { create } = useProjectTurbineCrud();
  const refreshProjectTurbines = useRefreshProjectTurbines();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const duplicateToProject = useJotaiCallback(
    async (_get, _set, turbineId: string, turbineName: string) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "turbine",
          defaultName: `${turbineName} (duplicate)`,
          onDuplicate: async (name: string) => {
            setIsLoading(true);
            try {
              const turbine = await getAdvancedOrgTurbine(
                organisationId,
                turbineId,
              );
              await create({
                turbine: turbine,
                name: name,
              });
              refreshProjectTurbines();
            } catch (e) {
              scream("Error when duplicating library turbine to project", {
                e,
              });
              error(
                "Something went wrong while trying to duplicate library turbine to project, the Vind team has been notified",
              );
            } finally {
              setIsLoading(false);
            }
          },
        },
      });
    },
    [
      organisationId,
      create,
      refreshProjectTurbines,
      error,
      setMidScreenModalTypeOpen,
    ],
  );

  return {
    duplicateToProject,
    isLoading,
  };
}

export function useDuplicateCableToProject(isExportCable?: boolean) {
  const projectId = useAtomValue(projectIdAtom);
  const { error } = useToast();

  const setIsAutoSaving = useSetAtom(savingConfigurationInProgressAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { updateLocal: localCableUpdate } = useCableTypeCrud();
  const setSelectedMenuItemState = useSetAtom(
    selectedMenuItemState({
      menuId: isExportCable ? EXPORT_CABLE_MENU_ID : CABLE_MENU_ID,
      projectId: projectId ?? "",
    }),
  );

  const duplicateToProject = useJotaiCallback(
    async (_, set, cable: CableType) => {
      if (!projectId) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "cable",
          defaultName: `${cable.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            setIsAutoSaving(true);
            try {
              const newCable = {
                ...cable,
                name: name,
              };
              const res = await createProjectCableType(projectId, newCable);
              if (res) {
                localCableUpdate(projectId, res);
                setSelectedMenuItemState(res.id);
              }
              return res;
            } catch (e) {
              scream("Error when duplicating library cable to project", {
                e,
              });
              error(
                "Something went wrong while trying to duplicate library cable to project, the Vind team has been notified",
              );
            } finally {
              setIsAutoSaving(false);
            }
          },
        },
      });
    },
    [
      projectId,
      setIsAutoSaving,
      localCableUpdate,
      error,
      setMidScreenModalTypeOpen,
      setSelectedMenuItemState,
    ],
  );

  return {
    duplicateToProject,
  };
}

const getProjectFoundationCostsFromLibraryConfig = async (
  foundationCost: LibraryFoundationReferenceCost,
  projectId: string,
): Promise<FoundationMaterialCost | CostWithUnit> => {
  const { jacketCostReference, monopileCostReference, floatingCostReference } =
    foundationCost;

  const costsFromTable =
    await listProcurementCostForLibraryFoundationOnNode(projectId);

  // Find relevant cost tables
  const [jacket, monopile, floating] = [
    jacketCostReference,
    monopileCostReference,
    floatingCostReference,
  ].map((id) => costsFromTable.find((t) => t.id === id));

  // Convert to material costs, using default costs if not found in table
  return {
    floating: {
      primarySteel: {
        cost:
          floating?.costs.floating.material.primarySteel.cost ??
          DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT],
        unit:
          floating?.costs.floating.material.primarySteel.unit ??
          CostUnit.euroPerT,
      },
      concrete: {
        cost:
          floating?.costs.floating.material.concrete.cost ??
          DefaultCosts[CostType.Foundation].concrete[CostUnit.euroPerM3],
        unit:
          floating?.costs.floating.material.concrete.unit ?? CostUnit.euroPerM3,
      },
      reinforcement: {
        cost:
          floating?.costs.floating.material.reinforcement.cost ??
          DefaultCosts[CostType.Foundation].reinforcement[CostUnit.euroPerT],
        unit:
          floating?.costs.floating.material.reinforcement.unit ??
          CostUnit.euroPerT,
      },
      postTensionCables: {
        cost:
          floating?.costs.floating.material.postTensionCables.cost ??
          DefaultCosts[CostType.Foundation].postTensionCables[
            CostUnit.euroPerT
          ],
        unit:
          floating?.costs.floating.material.postTensionCables.unit ??
          CostUnit.euroPerT,
      },
      solidBallast: {
        cost:
          floating?.costs.floating.material.solidBallast.cost ??
          DefaultCosts[CostType.Foundation].solidBallast[CostUnit.euroPerT],
        unit:
          floating?.costs.floating.material.solidBallast.unit ??
          CostUnit.euroPerT,
      },
    },
    monopile: {
      primarySteel: {
        cost:
          monopile?.costs.monopile.material.primarySteel.cost ??
          DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT],
        unit:
          monopile?.costs.monopile.material.primarySteel.unit ??
          CostUnit.euroPerT,
      },
    },
    jacket: {
      primarySteel: {
        cost:
          jacket?.costs.jacket.material.primarySteel.cost ??
          DefaultCosts[CostType.Foundation].primarySteel[CostUnit.euroPerT],
        unit:
          jacket?.costs.jacket.material.primarySteel.unit ?? CostUnit.euroPerT,
      },
    },
  } as FoundationMaterialCost;
};

export function useDuplicateCostToProject() {
  const projectId = useAtomValue(projectIdAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { error } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setSelectedMenuItemState = useJotaiCallback(
    (
      _,
      set,
      obj: {
        menuId: string;
        projectId: string;
      },
      state: string | undefined,
    ) => {
      set(selectedMenuItemState(obj), state);
    },
    [],
  );

  const duplicateToProject = useJotaiCallback(
    async (_get, set, cost: CostConfiguration) => {
      if (!projectId) return;

      const isLibraryReferenceCost = Boolean(
        cost.capex.fixed.foundations &&
          isLibraryFoundationReferenceCost(cost.capex.fixed.foundations),
      );

      const projectFoundationCostsFromLibrary = isLibraryReferenceCost
        ? await getProjectFoundationCostsFromLibraryConfig(
            cost.capex.fixed.foundations as LibraryFoundationReferenceCost,
            projectId,
          )
        : cost.capex.fixed.foundations;

      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "cost",
          defaultName: `${cost.name} (duplicate)`,
          description: isLibraryReferenceCost
            ? `Note that foundations material costs will be set to the selected values, but will be disconnected from the Procurement table.`
            : undefined,
          onDuplicate: async (name: string) => {
            setIsLoading(true);
            try {
              const newCost = {
                ...cost,
                name: name,
                capex: {
                  ...cost.capex,
                  fixed: {
                    ...cost.capex.fixed,
                    foundations: projectFoundationCostsFromLibrary,
                  },
                },
              };
              const res = await createConfiguration(projectId, newCost);
              set(
                projectCostConfigurationsFamily({ projectId }),
                async (curr) => {
                  const m = new Map(await curr);
                  m.set(res.id, res);
                  return m;
                },
              );
              if (res) {
                setSelectedMenuItemState(
                  {
                    menuId: COST_MENU_ID,
                    projectId,
                  },
                  res.id,
                );
              }
              return res;
            } catch (e) {
              scream("Error when duplicating library cost to project", {
                e,
              });
              error(
                "Something went wrong while trying to duplicate library cost to project, the Vind team has been notified",
              );
            } finally {
              setIsLoading(false);
            }
          },
        },
      });
    },
    [error, projectId, setSelectedMenuItemState, setMidScreenModalTypeOpen],
  );

  return {
    duplicateToProject,
    isLoading,
  };
}
export function useDuplicateAnalysisToProject() {
  const projectId = useAtomValue(projectIdAtom);
  const projectType = useAtomValue(designToolTypeAtom);
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { error } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setSelectedMenuItemState = useJotaiCallback(
    (
      _,
      set,
      obj: {
        menuId: string;
        projectId: string;
      },
      state: string | undefined,
    ) => {
      set(selectedMenuItemState(obj), state);
    },
    [],
  );

  const duplicateToProject = useJotaiCallback(
    async (_get, set, analysis: AnalysisConfiguration) => {
      if (!projectId) return;
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "analysis",
          defaultName: `${analysis.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            setIsLoading(true);
            try {
              const newAnalysis = {
                ...analysis,
                name: name,
              };
              const res = await createAnalysisConfigurationWithValues(
                projectId,
                projectType,
                newAnalysis,
              );
              set(
                projectAnalysisConfigurationsFamily({ projectId }),
                async (curr) => {
                  const m = new Map(await curr);
                  m.set(res.id, res);
                  return m;
                },
              );
              if (res) {
                setSelectedMenuItemState(
                  {
                    menuId: ANALYSIS_MENU_ID,
                    projectId,
                  },
                  res.id,
                );
              }
              return res;
            } catch (e) {
              scream("Error when duplicating library analysis to project", {
                e,
              });
              error(
                "Something went wrong while trying to duplicate library analysis to project, the Vind team has been notified",
              );
            } finally {
              setIsLoading(false);
            }
          },
        },
      });
    },
    [
      error,
      projectId,
      projectType,
      setSelectedMenuItemState,
      setMidScreenModalTypeOpen,
    ],
  );

  return {
    duplicateToProject,
    isLoading,
  };
}
