import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import SlideShowPlaceholder from "assets/slideshow-placeholder.jpg";

const Step1 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 1</TopHeaderAccent>
          <SubHeader>Reduce risks and improve confidence</SubHeader>
          <ContentText>
            Avoid costly mistakes and strengthen decision-making across
            strategic levels by evaluating all aspects of your project early on.
          </ContentText>
          <ContentText>
            Utilise benchmarked models and a user-friendly interface to
            instantly model yield, noise, and other critical analyses.
          </ContentText>
          <ContentText>
            Centralize project analyses to ensure consistency, control, and a
            solid foundation for success.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

const Step2 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 2</TopHeaderAccent>
          <SubHeader>Break silos with real-time collaboration</SubHeader>
          <ContentText>
            Collaborate seamlessly across disciplines, from yield and GIS to
            electrical, environmental, and financial teams—all within one
            platform.
          </ContentText>
          <ContentText>
            Real-time updates and centralized data make interdisciplinary
            collaboration easier, reducing delays and miscommunications.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="left"
    />
  );
};

const Step3 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 3</TopHeaderAccent>
          <SubHeader>Track Project Progress</SubHeader>
          <ContentText>
            With all disciplines working in one platform, you’ll always have
            access to the latest project data. Easily monitor key metrics and
            progress without needing to chase updates. Manage all your projects
            in one place for a clear, organized overview.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

const Step4 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 4</TopHeaderAccent>
          <SubHeader>Engage stakeholders</SubHeader>
          <ContentText>
            Easily generate visualizations that summarize your findings and
            recommendations for comminication with stakeholders, including
            management, regulatory bodies, local communities, and investors.
          </ContentText>
          <ContentText>
            Securely invite external stakeholders into the platform to view
            relevant aspects of the project directly. Our user-friendly
            interface and flexible access options makes sure your project data
            is safe while giving your stakeholders the information they need.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

export const ProjectManagerSteps = [Step1, Step2, Step3, Step4];
