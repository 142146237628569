import { SubstationType } from "services/substationService";
import { Label } from "components/General/Form";
import {
  SecondaryText,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import { InputDimensioned } from "components/General/Input";
import { substationChangelogInfo } from "components/InputChangelog/const";
import { Row } from "components/General/Layout";
import Toggle, { ToggleSize } from "components/General/Toggle";
import { Text } from "components/SettingsV2/Shared/styles";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ShadeToggleableList from "components/ToggleableList/ShadeToggleableList";
import { useLocalStorage } from "hooks/useBrowserStorage";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { Title } from "./shared";

type ACSettingsProps = {
  substation: SubstationType;
  onChange: (substation: SubstationType) => void;
  disabled?: boolean;
  nodeId: string;
};

export const ACSettings = ({
  substation,
  onChange,
  disabled,
  nodeId,
}: ACSettingsProps) => {
  const [isOpen, setIsOpen] = useLocalStorage<boolean | undefined>(
    `vind:substation-ACsettings-open`,
  );

  const fractionToPercentage = 100;

  return (
    <ShadeToggleableList
      contentStyle={{ minWidth: "40rem" }}
      title="AC settings"
      defaultOpen={isOpen ?? true}
      onOpenChange={setIsOpen}
    >
      <TitleContainer>
        <Title>Transformer</Title>
      </TitleContainer>
      <div
        style={{
          paddingTop: "0.8rem",
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Short-circuit impedance</SecondaryText>
          <InputDimensioned
            style={{ width: "20rem" }}
            disabled={disabled}
            scaleFactor={fractionToPercentage}
            value={substation.impedance}
            unit="%"
            step={0.01}
            validate={(e) => e >= 0.0 && e <= 30}
            validationMessage="Must be between 0 and 30"
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "impedance",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                impedance: e,
              });
            }}
            placeholder="0.14"
          />
        </Label>
        <Label>
          <SecondaryText>Resistance</SecondaryText>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            value={substation.resistance}
            initialValue={substation.resistance}
            unit={"%"}
            scaleFactor={fractionToPercentage}
            step={0.01}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage={`Must be between 0 and 10`}
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "resistance",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                resistance: e as any,
              });
            }}
            placeholder="0.14"
          />
        </Label>

        <Label>
          <SecondaryText>Iron losses</SecondaryText>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            scaleFactor={fractionToPercentage}
            value={substation.ironLosses}
            initialValue={substation.ironLosses}
            unit={"%"}
            step={0.1}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage={`Must be between 0 and 10`}
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "ironLosses",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                ironLosses: e as any,
              });
            }}
            placeholder="0.14"
          />
        </Label>

        <Label>
          <SecondaryText>No load current</SecondaryText>
          <InputDimensioned
            style={{
              width: "20rem",
            }}
            disabled={disabled}
            value={substation.noLoadCurrent}
            initialValue={substation.noLoadCurrent}
            unit={"%"}
            scaleFactor={fractionToPercentage}
            step={0.01}
            validate={(e) => e >= 0.0 && e <= 10}
            validationMessage={`Must be between 0 and 10`}
            changelogInfo={substationChangelogInfo(
              substation.id,
              nodeId,
              "noLoadCurrent",
              "project",
            )}
            onChange={(e) => {
              onChange({
                ...substation,
                noLoadCurrent: e as any,
              });
            }}
            placeholder="0.14"
          />
        </Label>
      </div>
      {substation.type === "offshore" && (
        <>
          <TitleContainer>
            <Title>Reactive compensation</Title>
          </TitleContainer>
          <Row
            style={{
              marginTop: "2.0rem",
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              gap: "2.4rem",
              alignItems: "start",
            }}
          >
            <ChangelogAndCommentWrapper
              changelogInfo={substationChangelogInfo(
                substation.id,
                nodeId,
                "reactor",
                "project",
              )}
              disabled={disabled}
            >
              <Label>
                <Row>
                  <Toggle
                    disabled={disabled}
                    size={ToggleSize.SMALL}
                    checked={substation.reactor ?? false}
                    onChange={() => {
                      onChange({
                        ...substation,
                        reactor: !substation.reactor,
                      });
                    }}
                  />
                  <Text>Reactor</Text>
                  <HelpTooltip text="Whether reactive power compensation should be performed at the substation." />
                </Row>
              </Label>
            </ChangelogAndCommentWrapper>

            {substation.reactor && (
              <Label>
                <Row>
                  <SecondaryText>Total compensation share</SecondaryText>
                  <HelpTooltip text="Share of total compensation that is performed at the offshore substation." />
                </Row>
                <InputDimensioned
                  style={{
                    width: "20rem",
                  }}
                  disabled={disabled}
                  scaleFactor={fractionToPercentage}
                  value={substation.compensationShare}
                  initialValue={substation.compensationShare}
                  unit={"%"}
                  step={0.01}
                  validate={(e) => e >= 0.0 && e <= 100}
                  validationMessage={`Must be between 0 and 100`}
                  changelogInfo={substationChangelogInfo(
                    substation.id,
                    nodeId,
                    "compensationShare",
                    "project",
                  )}
                  onChange={(e) => {
                    onChange({
                      ...substation,
                      compensationShare: e as any,
                    });
                  }}
                  placeholder="50"
                />
              </Label>
            )}
          </Row>
        </>
      )}
    </ShadeToggleableList>
  );
};
