import { Point } from "geojson";
import RBush from "rbush";
import { ProjectFeature } from "types/feature";
import { getDistanceFromLatLonInM, metersToLat, metersToLng } from "./proj4";

export const overlappingPointFeatures = <F extends ProjectFeature<Point>>(
  features: F[],
  maxDistance: number,
): F[] => {
  const tree = new RBush<{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    feature: ProjectFeature<Point>;
    index: number;
  }>();

  features.forEach((feature, index) => {
    const [lng, lat] = feature.geometry.coordinates;
    tree.insert({
      minX: lng,
      minY: lat,
      maxX: lng,
      maxY: lat,
      feature,
      index,
    });
  });

  return features.filter((f, i) => {
    const [lng, lat] = f.geometry.coordinates;
    const latDiff = metersToLat(maxDistance);
    const lngDiff = metersToLng(maxDistance, lat);

    const nearby = tree.search({
      minX: lng - lngDiff,
      minY: lat - latDiff,
      maxX: lng + lngDiff,
      maxY: lat + latDiff,
    });

    return nearby.some(({ feature, index }) => {
      if (i === index) return false;

      const distance = getDistanceFromLatLonInM(
        f.geometry.coordinates,
        feature.geometry.coordinates,
      );
      return distance < maxDistance;
    });
  });
};
