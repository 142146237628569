import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { colors, hexToRgbA } from "styles/colors";
import { typography } from "styles/typography";

export const PopupContainer = styled(StandardBox)`
  padding: 1.2rem 1.6rem;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  cursor: auto;
  max-height: 50rem;
  overflow: auto;
  z-index: 1;
  width: 35rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: inherit;
`;

export const Label = styled.p`
  ${typography.caption}
  text-transform: capitalize;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
`;
export const Text = styled.p`
  ${typography.caption}
  color: ${colors.textPrimary};
  font-weight: 500;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
`;

export const DateText = styled(Text)`
  color: ${colors.textSecondary};
`;

export const IconContainer = styled.div<{ size: string }>`
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: 0.4rem;
`;

export const ChangelogIconWrapper = styled.div<{
  inFocus: boolean;
}>`
  position: absolute;
  background: ${(p) => (p.inFocus ? "white" : "transparent")};
  top: -1rem;
  right: -1rem;
  z-index: 2;
`;

export const WrapperChangelogParent = styled.div`
  position: relative;
  width: fit-content;
`;

export const ExpandText = styled.p`
  ${typography.caption}
  text-wrap: nowrap;
`;

export const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid ${hexToRgbA(colors.grey500, 0.5)};
  padding: 0;
  opacity: 0.5;
  height: 1px;
  width: 100%;
  margin: 0;
`;

export const CommentDot = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;

  background-color: ${colors.indigo500};
  position: absolute;
  transition: all 0.3s;
`;
