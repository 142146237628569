import { atom, useSetAtom } from "jotai";
import { atomFamily, atomFromFn } from "utils/jotai";
import { useCallback } from "react";
import {
  CustomLayerMetadata,
  getCustomLayerMetadata,
} from "../services/customLayersAPIService";
import { _CustomLayer, CustomLayer } from "../types/layers";
import { CUSTOM_LAYER_TYPE } from "../constants/customLayers";
import { _Bbox } from "../utils/geojson/geojson";
import { libraryLayersAddedToProject } from "components/Organisation/Library/dataLibrary/state";

export const mapLayerMetadataToCustomLayer = (
  customLayer: CustomLayerMetadata,
  sourceType: CUSTOM_LAYER_TYPE,
): CustomLayer => {
  return _CustomLayer.parse({
    id: customLayer.id,
    author: customLayer.author,
    name: customLayer.name,
    url: customLayer.path,
    sourceType,
    source: undefined,
    sourceLayerId: customLayer.name,
    tags: [],
    tokenRequired: true,
    description: customLayer.description,
    dateAdded: customLayer.created_at,
    bbox: customLayer.bbox
      ? _Bbox.parse(JSON.parse(customLayer.bbox))
      : undefined,
    created_at: customLayer.created_at,
  });
};

const customLayerMetadataAtomFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomFromFn<Promise<CustomLayerMetadata[]>>((get) => {
      get(forceReloadCustomLayersAtom);
      return getCustomLayerMetadata(nodeId);
    }),
);

export const customLayersMetadataSelectorAsync = atomFamily(
  ({ nodeId }: { nodeId: string | undefined }) =>
    atom<Promise<CustomLayer[]>>(async (get) => {
      if (!nodeId) return [];
      const customLayers = await get(
        customLayerMetadataAtomFamily({
          nodeId,
        }),
      );
      const libraryLayers = await get(
        libraryLayersAddedToProject({
          projectId: nodeId,
        }),
      );
      return customLayers
        .map((l) =>
          mapLayerMetadataToCustomLayer(
            l,
            CUSTOM_LAYER_TYPE.CUSTOM_LAYER_SOURCE_TYPE,
          ),
        )
        .concat(
          libraryLayers.map((l) =>
            mapLayerMetadataToCustomLayer(
              l,
              CUSTOM_LAYER_TYPE.CUSTOM_LIBRARY_LAYER_SOURCE_TYPE,
            ),
          ),
        );
    }),
);

const forceReloadCustomLayersAtom = atom(0);

export const useForceReloadCustomLayers = () => {
  const setState = useSetAtom(forceReloadCustomLayersAtom);

  return useCallback(() => {
    setState((n) => n + 1);
  }, [setState]);
};
