import { Suspense, useCallback, useEffect, useState } from "react";
import { idToSubstationChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { SubstationType } from "services/substationService";
import useOrgSubstationCrud from "components/Organisation/Library/substation/useOrgSubstationCrud";
import { SubstationProjects } from "./availability/SubstationProjects";
import { SubstationSettings } from "components/ConfigurationModal/SubstationSettings/SubstationSettings";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const SubstationResourceContent = ({
  resource,
  organisationId,
}: {
  resource: SubstationType;
  organisationId: string;
}) => {
  const { update: updateSubstation } = useOrgSubstationCrud();

  const [tempSubstation, setTempSubstation] = useState(resource);

  useEffect(() => {
    setTempSubstation(resource);
  }, [resource, setTempSubstation]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<SubstationType>) => {
      setTempSubstation((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempSubstation],
  );

  const onSaveNameOrDescription = useCallback(
    (substation: SubstationType) => {
      updateSubstation(substation);
    },
    [updateSubstation],
  );

  if (!tempSubstation) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"substation"}
        description={tempSubstation.note ?? ""}
        name={tempSubstation.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempSubstation.name,
            note: description ?? tempSubstation.note,
          })
        }
        onSave={({ name, description }) => {
          onSaveNameOrDescription({
            ...tempSubstation,
            name: name ?? tempSubstation.name,
            note: description ?? tempSubstation.note,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            note: resource.note,
          });
        }}
        changelogData={{
          id: idToSubstationChangelogId(resource.id),
          category: "org_substation_manage",
        }}
      >
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("substation")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <ResourceContentTabs
        tabs={substationTabs(resource, organisationId!, updateSubstation)}
      />
    </>
  );
};
const substationTabs = (
  resource: SubstationType,
  organisationId: string,
  update: (substation: SubstationType) => Promise<SubstationType>,
) => [
  {
    name: "Substation details",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <SubstationSettings
          key={resource.id}
          substation={resource}
          onSave={update}
          isLoading={false}
          disabled={false}
          nodeId={organisationId}
          isLibraryContext={true}
        />
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <SubstationProjects resource={resource} />
      </Suspense>
    ),
  },
];
