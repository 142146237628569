import { z } from "zod";
import { fetchEnhancerWithToken } from "../../services/utils";
import { _FeatureParser } from "../../types/feature";
import { BranchNotFoundError, NotFoundError } from "../ErrorBoundaries/types";
import { _Node } from "services/customerAPI";
import { getSavedFeatureHistory } from "components/Design/FeatureHistory/service";

export const PROJECT_SERVICE_API_PATH = "/api/project-service";
export const PROJECT_SERVICE_API_VERSION = "1.0.0";

export const getBranchFeatures = async (
  nodeId: string,
  branchId: string,
  version: number | null | undefined, // timestamp in seconds
) => {
  const sp: Record<string, string> = { features: "true" };

  const currentSearchParams = new URLSearchParams(window.location.search);
  const featuredbExists = currentSearchParams.has("featuredb");

  try {
    const res = await fetchEnhancerWithToken(
      `${PROJECT_SERVICE_API_PATH}/node/${nodeId}/branch/${branchId}${
        featuredbExists ? "/new" : ""
      }?` + new URLSearchParams(sp),
      {
        method: "get",
        headers: {
          "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
        },
      },
    );
    const data = (await res.json()) as Record<string, any>;
    if (version) {
      const historyFeatures = await getSavedFeatureHistory({
        nodeId,
        branchId,
        timestamp: version,
      });
      return { ...data, features: historyFeatures };
    }
    return data;
  } catch (err) {
    if (err instanceof NotFoundError) {
      throw new BranchNotFoundError(err.message);
    }
    throw err;
  }
};

// -------- Tutorial --------

const _TutorialProjectResponse = z.object({
  meta: _Node,
  branch: z.object({}),
});

export const createOffshoreTutorialProjectService = async (nodeId: string) => {
  const tutorialId = "2g5pfzNjvnfGFWkSxEudrHcBqQP";

  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/tutorial/${nodeId}/${tutorialId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  const j = await res.json();
  const parsed = _TutorialProjectResponse.parse(j);
  return parsed.meta;
};

export const createOnshoreTutorialProjectService = async (nodeId: string) => {
  const tutorialId = "2me3haTlwxqtWzfu5LJ5pPwmNmD";

  const res = await fetchEnhancerWithToken(
    `${PROJECT_SERVICE_API_PATH}/tutorial/${nodeId}/${tutorialId}`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    },
  );
  const j = await res.json();
  const parsed = _TutorialProjectResponse.parse(j);
  return parsed.meta;
};
