import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { spaceLarge } from "styles/space";

export const TabItemText = styled.span`
  ${typography.contentAndButtons};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TabItemWrapper = styled.div<{
  selected?: boolean;
  disabled?: boolean;
  staticSize?: boolean;
  isHoveredStart?: boolean;
  isHoveredEnd?: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  flex-wrap: nowrap;
  ${({ staticSize }) =>
    staticSize &&
    `
      grid-column: minmax(min-content, max-content);
    `};

  overflow: hidden;

  padding: 0 ${spaceLarge} 0 ${spaceLarge};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${TabItemText} {
    font-weight: 500;
  }

  ${({ staticSize }) =>
    !staticSize &&
    `
      ${TabItemText} {
         position: relative;
         text-overflow: ellipsis;
         overflow: hidden;
       }
    `};

  .visible-on-hover {
    visibility: hidden;
  }

  &:hover {
    .visible-on-hover {
      visibility: visible;
    }
  }

  gap: 1rem;
  ${({ selected }) =>
    selected
      ? `
      background-color: var(--branch-tab-bar-item-selected);
      ${TabItemText} {
        color: ${colors.blue900};
      }
      svg * {
          fill: ${colors.blue900};
      }
      
      .visible-on-hover {
        visibility: visible;
      }
    `
      : `
      
      
      ${TabItemText} {
        color: ${colors.lightText};
      }
      
    `};

  ${({ disabled, selected }) =>
    disabled
      ? `
      cursor: not-allowed;
      ${TabItemText} {
        color: ${colors.grey300};
      }
      
    `
      : !selected &&
        `
       &:hover {
        background-color: var(--branch-tab-bar-item-hover);
      }
    `};

  ${({ isHoveredEnd, isHoveredStart }) => {
    if (isHoveredStart) {
      return `
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 3px;
          background-color: ${colors.blue100};
          top: 0;
          left: 0;
        }
      `;
    }

    if (isHoveredEnd) {
      return `
        &::after {
          content: "";
          position: absolute;
           height: 100%;
          width: 3px;
          background-color: ${colors.blue100};
          top: 0;
          right: 0;
        }
      `;
    }
  }};
`;

export const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, max-content));
  flex-wrap: nowrap;
  height: 3.2rem;
  width: 100%;
  flex-shrink: 0;
  text-space: nowrap;
  white-space: nowrap;
  box-sizing: border-box;
  overflow-x: hidden;
  user-select: none;
  background-color: var(--branch-tab-bar-background);

  ${TabItemWrapper}:not(:last-of-type) {
    border-right: 0.5px solid ${colors.grey500};
  }
`;
