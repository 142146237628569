import styled from "styled-components";
import { useRef } from "react";
import Checkbox from "../General/Checkbox";
import { acceptedTermsOfUseState, wizardStepState } from "./state";
import Button from "../General/Button";
import { Column, Row } from "../General/Layout";
import { LOCAL_STORAGE_TOKEN_KEY } from "../../state/user";
import { invalidateToken } from "../../services/invalidateTokenServiceAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useAtom, useSetAtom } from "jotai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  border: 1px solid #ccc;
`;

const TermsIframe = styled.iframe`
  width: 100%;
  height: calc(100% + 70px);
  border: none;
  margin-top: -70px;
`;

export default function TermsOfUse() {
  const [hasRead, setHasRead] = useAtom(acceptedTermsOfUseState);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const setStep = useSetAtom(wizardStepState);
  const { logout } = useAuth0();

  return (
    <>
      <Column>
        <h2 style={{ margin: "0" }}>{"Please review our Terms of Use"}</h2>
      </Column>
      <Container>
        <IframeContainer>
          <TermsIframe ref={iframeRef} src="https://vind.ai/terms-of-use/" />
        </IframeContainer>
        <Checkbox
          key={"tou"}
          label={"I have read the Terms of Use"}
          checked={hasRead}
          labelPlacement="after"
          onChange={(e) => {
            setHasRead(e.target.checked);
          }}
        />
        <Row style={{ gap: "1.8rem" }}>
          <Button
            style={{ marginLeft: "auto" }}
            text="Log out"
            buttonType="text"
            onClick={async () => {
              localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
              await invalidateToken();
              logout({ returnTo: "https://vind.ai" });
            }}
          />
          <Button
            text="Continue"
            disabled={!hasRead}
            onClick={() => setStep((cur) => Math.min(cur + 1, 1))}
          />
        </Row>
      </Container>
    </>
  );
}
