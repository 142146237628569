import React, { useCallback, useMemo, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai/index";
import Fuse from "fuse.js";
import { spacing7 } from "styles/space";
import AddIcon from "@icons/24/Add.svg";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";
import { projectIdAtom } from "state/pathParams";
import { branchMetasBySortOrderFamily } from "state/jotai/branch";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import { useCreateBranch } from "state/timeline";
import useNavigateToBranch from "components/Design/BranchTabBar/useNavigateToBranch";
import { useToast } from "hooks/useToast";
import { editorAccessProjectSelector } from "state/user";
import {
  allBranchesFrameLoadingAtom,
  allBranchesFrameOpenedAtom,
  allBranchesFrameSearchValueAtom,
} from "components/Design/BranchTabBar/state";
import { showDuplicateBranchForBranchIdAtom } from "components/DuplicateBranchModal/state";
import useBranchFolderStructureCrud from "hooks/useBranchFolderStructureCrud";
import { BranchMeta } from "types/api";
import { scream } from "utils/sentry";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { SearchInput } from "components/General/Input";
import BranchesTable from "components/Design/BranchTabBar/components/BranchesTable";
import { Mixpanel } from "mixpanel";
import { HorizontalDivider } from "components/InputChangelog/style";
import { Row } from "components/General/Layout";
import Tooltip from "components/General/Tooltip";
import Button from "components/General/Button";
import NoItemsGeneric from "components/General/NoItemsGeneric";

export const BranchesTabContent = ErrorBoundaryWrapper(
  () => {
    const nodeId = useAtomValue(projectIdAtom) ?? "";
    const branchMetaObjects = useAtomValue(
      branchMetasBySortOrderFamily({
        nodeId,
      }),
    );
    const elementRef = React.useRef<HTMLDivElement>(null);
    useHorizontalResize(elementRef, "--all-branches-frame-width");
    const { create: createBranch } = useCreateBranch();
    const navigateToBranch = useNavigateToBranch();
    const { error: showError } = useToast();
    const editorAccessProject = useAtomValue(editorAccessProjectSelector);
    const setIsLoading = useSetAtom(allBranchesFrameLoadingAtom);
    const [showCreateNewFolder, setShowCreateNewFolder] = useState(false);
    const [showCreateNewBranch, setShowCreateNewBranch] = useState(false);
    const [createBranchInFolderId, setCreateBranchInFolderId] = useState<
      string | undefined
    >();
    const [createFolderInFolderId, setCreateFolderInFolderId] = useState<
      string | undefined
    >();
    const setShowDuplicateBranchForBranchId = useSetAtom(
      showDuplicateBranchForBranchIdAtom,
    );
    const { moveItems, refreshFolders } = useBranchFolderStructureCrud();
    const [searchValue, setSearchValue] = useAtom(
      allBranchesFrameSearchValueAtom,
    );
    const setBranchFrameVisible = useSetAtom(allBranchesFrameOpenedAtom);
    const fuse = useMemo(
      () =>
        new Fuse(branchMetaObjects, {
          keys: ["title"],
          includeScore: true,
          threshold: 0.3,
        }),
      [branchMetaObjects],
    );
    const { createFolder } = useBranchFolderStructureCrud();

    const branchesSearchResult = useMemo<BranchMeta[]>(() => {
      return searchValue.length > 0
        ? fuse.search(searchValue).map((result) => result.item)
        : branchMetaObjects;
    }, [fuse, searchValue, branchMetaObjects]);

    const onCreateBranch = useCallback(
      async (name: string) => {
        setIsLoading(true);
        try {
          const { meta } = await createBranch(nodeId, {
            title: name,
          });
          navigateToBranch(meta.id, true);
          setBranchFrameVisible(false);
          return meta;
        } catch (err) {
          if (err instanceof Error) {
            scream(err, {
              nodeId,
            });
          } else {
            scream(new Error("Failed to create branch"), {
              nodeId,
              err,
            });
          }
          showError("Failed to create branch, please try again.");
        } finally {
          setIsLoading(false);
        }
      },
      [
        setBranchFrameVisible,
        createBranch,
        navigateToBranch,
        nodeId,
        showError,
        setIsLoading,
      ],
    );

    return (
      <>
        {showCreateNewFolder && (
          <NewItemModal
            title="New folder"
            placeholder="Enter folder name"
            defaultValue="New folder"
            onSubmit={async (name) => {
              await createFolder(name, [], createFolderInFolderId);
            }}
            onClose={() => {
              setShowCreateNewFolder(false);
              setCreateFolderInFolderId(undefined);
            }}
          />
        )}
        {showCreateNewBranch && (
          <NewItemModal
            title="New branch"
            placeholder="Enter branch name"
            defaultValue={`Branch ${branchMetaObjects.length + 1}`}
            onSubmit={async (name) => {
              const createdBranch = await onCreateBranch(name);
              if (createdBranch && createBranchInFolderId) {
                await moveItems(
                  [
                    {
                      id: createdBranch.id,
                      type: "RESOURCE",
                      parentId: createBranchInFolderId,
                    },
                  ],
                  createBranchInFolderId,
                );
                refreshFolders();
              }
            }}
            onClose={() => {
              setShowCreateNewBranch(false);
              setCreateBranchInFolderId(undefined);
            }}
          />
        )}

        <SearchInput
          style={{ width: "100%" }}
          wrapperDivStyle={{ marginBottom: "" }}
          placeholder="Search for branch"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onClear={() => {
            setSearchValue("");
          }}
        />
        {searchValue !== "" && branchesSearchResult.length === 0 ? (
          <NoItemsGeneric
            headerText="No results"
            subText="We couldn't find any branches matching your search."
          />
        ) : (
          <BranchesTable
            enableDrag={true}
            isSearching={searchValue !== ""}
            branchesSearchResult={branchesSearchResult}
            onCreateBranchInFolder={(folderId) => {
              Mixpanel.track_old(
                `Branch folders - Show create branch in folder`,
                {},
              );
              setCreateBranchInFolderId(folderId);
              setShowCreateNewBranch(true);
            }}
            onCreateFolderInFolder={(folderId) => {
              Mixpanel.track_old(
                `Branch folders - Show create folder in folder`,
                {},
              );
              setCreateFolderInFolderId(folderId);
              setShowCreateNewFolder(true);
            }}
            onDuplicateBranchClick={(branch) => {
              Mixpanel.track_old(`Branch folders - Show duplicate branch`, {});
              setShowDuplicateBranchForBranchId(branch.id);
            }}
          />
        )}

        {editorAccessProject && (
          <>
            <HorizontalDivider
              style={{
                marginLeft: "-2rem",
                width: "calc(100% + 4rem)",
              }}
            />
            <Row style={{ justifyContent: "flex-end", gap: spacing7 }}>
              <Tooltip text={"Create new folder"}>
                <Button
                  buttonType="secondary"
                  onClick={() => {
                    setShowCreateNewFolder(true);
                  }}
                  icon={<FolderAddIcon />}
                />
              </Tooltip>
              <Button
                text="New branch"
                buttonType="primary"
                onClick={() => setShowCreateNewBranch(true)}
                icon={<AddIcon />}
              />
            </Row>
          </>
        )}
      </>
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);
