import Mooring from "@icons/24/Anchor.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { SubHeader } from "components/ConfigurationModal/shared";
import { Grid2 } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { useAtomValue } from "jotai";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { Row } from "components/General/Layout";

const MooringSpecificActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const localConfig = useAtomValue(localOperationsConfigurationAtom);

  if (!localConfig) return null;

  const { activities } = localConfig.ti.mooring;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Load anchor and line on vessel</SubHeader>
        <SubHeader>ROV survey</SubHeader>
        <SubHeader>Install anchor</SubHeader>
        <SubHeader>Install mooring line</SubHeader>
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.mooring.activities.loadAnchorAndLineOnVessel`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.loadAnchorAndLineOnVessel}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              mooring: {
                ...localConfig.ti.mooring,
                activities: {
                  ...activities,
                  loadAnchorAndLineOnVessel: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.mooring.activities.ROVSurvey`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.ROVSurvey}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              mooring: {
                ...localConfig.ti.mooring,
                activities: {
                  ...activities,
                  ROVSurvey: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.mooring.activities.installAnchor`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.installAnchor}
          step={1}
          validate={(e) => e >= 1 && e <= 30}
          validationMessage={`Must be between 1 and 30`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              mooring: {
                ...localConfig.ti.mooring,
                activities: {
                  ...activities,
                  installAnchor: Math.round(duration),
                },
              },
            })
          }
        />
        <InputDimensioned
          decimals={0}
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            `ti.mooring.activities.installMooringLine`,
            "project",
          )}
          disabled={isReadOnly}
          unit={"hours"}
          value={activities.installMooringLine}
          step={1}
          validate={(e) => e >= 1 && e <= 20}
          validationMessage={`Must be between 1 and 20`}
          initialValue={10}
          onChange={(duration) =>
            updateTIVessels({
              mooring: {
                ...localConfig.ti.mooring,
                activities: {
                  ...activities,
                  installMooringLine: Math.round(duration),
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

export const MooringActivities = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  return (
    <InstallationCategoryEntry
      title="Mooring system"
      icon={<Mooring />}
      defaultOpen={true}
    >
      <MooringSpecificActivities isReadOnly={isReadOnly} nodeId={nodeId} />
    </InstallationCategoryEntry>
  );
};
