import { useSetAtom } from "jotai";
import styled from "styled-components";
import { HeaderRow } from "components/ComponentsPreview/style";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { IconBtn } from "components/General/Icons";
import { modalTypeOpenAtom } from "state/modal";
import { Modal, DataLayersTableWrapper } from "../DataLayersModal";
import CloseIcon from "@icons/24/Close.svg";

const ModalHeader = styled.h2`
  margin: 0;
`;

function DataLayerErrorBoundaryComponent() {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  return (
    <FullScreenModal>
      <Modal>
        <HeaderRow>
          <ModalHeader>Browse GIS layers</ModalHeader>
          <IconBtn size="1.5rem" onClick={() => setModalTypeOpen(undefined)}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>
        <DataLayersTableWrapper
          style={{
            padding: "0 2rem",
          }}
        >
          An error occured in the GIS layers modal
        </DataLayersTableWrapper>
      </Modal>
    </FullScreenModal>
  );
}

export default DataLayerErrorBoundaryComponent;
