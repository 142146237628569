import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";

export const ModalContainer = styled(StandardBox)`
  width: 40rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2.4rem;
  gap: 2.4rem;
`;
