import { useAtomValue } from "jotai";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { putThreadFollow } from "../service";
import { followThreadsAtomFamily } from "../state";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ThreadFollow } from "../types";
import { aset } from "utils/jotai";

export const useFollowThreadCrud = () => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const put = useAtomCallback(
    useCallback(
      async (get, set, threadId: string, follow: boolean) => {
        if (!projectId) return [];

        const threadFollow: ThreadFollow = {
          branchId,
          threadId,
          follow,
        };
        const atom = followThreadsAtomFamily({
          nodeId: projectId,
          branchId,
        });
        await aset(get, set, atom, (curr) =>
          curr.filter((c) => c.threadId !== threadId).concat([threadFollow]),
        );
        const res = await putThreadFollow(projectId, branchId, threadId, {
          follow,
        });

        return res;
      },
      [branchId, projectId],
    ),
  );

  return {
    put,
  };
};
