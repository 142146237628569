import { useCallback, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@icons/24/Close.svg?react";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { StandardBox } from "styles/boxes/Boxes";
import { colors } from "styles/colors";
import { spaceMedium } from "styles/space";
import { typography } from "styles/typography";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import ChevronUp from "@icons/24/ArrowUp.svg?react";
import ChevronDown from "@icons/24/ArrowDown.svg?react";

import { IconBtn } from "components/General/Icons";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { Column } from "components/General/Layout";
import DeleteTurbineImage from "./replace_turb.png";
import Button from "components/General/Button";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import { LibraryManageRole } from "components/Organisation/Library/types";

export const DeleteTurbineModalType = "DeleteTurbineModal" as const;
const hideDeleteModalAtom = atom<boolean>(false);

const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.8rem;
  width: 50vw;
  max-width: 65rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
`;

const ChevronWrapper = styled.div<{
  open?: boolean;
}>`
  padding-top: 0.4rem;
  svg {
    height: 1.4rem;
    width: 1.4rem;
    path {
      stroke: ${colors.primaryText};
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;

  justify-content: flex-end;
  padding-top: 1rem;
`;

const ExpandButton = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceMedium};

  h2 {
    margin: 0;
  }
`;

const PreviewContent = styled.div`
  display: flex;
  gap: 1.6rem;
  margin-top: 1rem;
`;

const PreviewImage = styled.div`
  width: 20rem;
  height: 18rem;
  background: ${colors.grey100};
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DeleteTurbineModalInner = ({
  turbineTypeId,
  noUsage,
  name,
  onConfirm,
  hidden,
}: {
  turbineTypeId: string;
  noUsage?: boolean;
  name: string;
  hidden: boolean;
  onConfirm: () => void;
}) => {
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const organisationId = useAtomValue(organisationIdAtom);

  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);

  const onClose = useCallback(
    () => setMidScreenModalTypeOpen(undefined),
    [setMidScreenModalTypeOpen],
  );

  return (
    <FullScreenModal
      closeOnWrapperClick={false}
      style={
        hidden
          ? {
              visibility: "hidden",
            }
          : undefined
      }
    >
      <Modal style={{ width: "35rem" }}>
        <HeaderRow>
          <Title>
            <h2>Delete turbine</h2>
          </Title>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </HeaderRow>

        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
        >
          {noUsage ? (
            <span style={{ ...typography.caption }}>
              You are about to delete <strong>{name}</strong>. This turbine is
              currently not in use in any projects.
            </span>
          ) : (
            <>
              <LibraryResourceUsage
                organisationId={organisationId ?? ""}
                libraryManageRole={LibraryManageRole.TURBINE}
                resourceId={turbineTypeId}
                noHoverOnUsage={true}
              />

              <span style={{ ...typography.caption, paddingTop: "1rem" }}>
                By deleting <strong>{name}</strong>, analysis will no longer run
                on parks with this turbine type. It can be replaced in the
                affected projects to re-run analysis.
              </span>
              <Column>
                <ExpandButton
                  onClick={() => setIsPreviewExpanded(!isPreviewExpanded)}
                  className="expand-button"
                >
                  {"View how it will look in projects"}
                  <ChevronWrapper
                    open={isPreviewExpanded}
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    {isPreviewExpanded ? <ChevronUp /> : <ChevronDown />}
                  </ChevronWrapper>
                </ExpandButton>
              </Column>
              {isPreviewExpanded && (
                <PreviewContent>
                  <PreviewImage>
                    <img src={DeleteTurbineImage} alt="Delete turbine" />
                  </PreviewImage>
                  <div style={{ flex: 1 }}>
                    <p style={{ ...typography.caption }}>
                      Admins and Editors in projects using the deleted turbine
                      will receive a notification with possibility to replace
                      it.
                    </p>
                  </div>
                </PreviewContent>
              )}
            </>
          )}

          <ButtonRow>
            <Button text="Cancel" onClick={onClose} buttonType="secondary" />
            <Button
              text="Delete"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            />
          </ButtonRow>
        </div>
      </Modal>
    </FullScreenModal>
  );
};

const DeleteTurbineModal = () => {
  const midScreenModalTypeOpen = useAtomValue(midScreenModalTypeOpenAtom);
  const hideDeleteModal = useAtomValue(hideDeleteModalAtom);

  if (midScreenModalTypeOpen?.modalType !== DeleteTurbineModalType) {
    return null;
  }

  return (
    <DeleteTurbineModalInner
      noUsage={midScreenModalTypeOpen.metadata.noUsage}
      name={midScreenModalTypeOpen.metadata.name}
      hidden={hideDeleteModal}
      turbineTypeId={midScreenModalTypeOpen.metadata.turbineTypeId}
      onConfirm={midScreenModalTypeOpen.metadata.onConfirm}
    />
  );
};

export default DeleteTurbineModal;
