import React, { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  midScreenModalTypeOpenAtom,
  modalTypeOpenAtom,
} from "../../state/modal";
import { unsavedSettingsState } from "../SettingsV2/Shared/state";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { spaceHuge, spaceLarge } from "styles/space";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

export const FullScreenModalSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spaceLarge,
        flex: 1,
        margin: spaceHuge,
      }}
    >
      <SkeletonBlock style={{ height: "1rem" }} />
      <SkeletonBlock style={{ height: "1rem" }} />
      <SkeletonBlock style={{ height: "1rem" }} />
    </div>
  );
};

const Wrapper = styled.div<{ placeOnTopOfOtherModals?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ placeOnTopOfOtherModals }) =>
    placeOnTopOfOtherModals ? 8 : 7};
  width: 100%;
  height: 100%;
  background: #00070f66;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenModal = ({
  closeOnWrapperClick = true,
  placeOnTopOfOtherModals = false,
  onClick,
  onEscape,
  children,
  disableClose,
  style,
  ...rest
}: {
  closeOnWrapperClick?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  placeOnTopOfOtherModals?: boolean;
  children: ReactNode;
  onEscape?: () => void;
  disableClose?: boolean;
  style?: React.CSSProperties;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">) => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const [midScreenModalTypeOpen, setMidScreenModalTypeOpen] = useAtom(
    midScreenModalTypeOpenAtom,
  );
  const unsavedSettings = useAtomValue(unsavedSettingsState);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disableClose) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onEscape) {
          onEscape();
          e.stopPropagation();
        } else if (midScreenModalTypeOpen) {
          setMidScreenModalTypeOpen(undefined);
          e.stopPropagation();
        } else {
          setModalTypeOpen(undefined);
        }
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [
    setModalTypeOpen,
    setMidScreenModalTypeOpen,
    midScreenModalTypeOpen,
    disableClose,
    onEscape,
  ]);

  return (
    <Wrapper
      {...rest}
      placeOnTopOfOtherModals={placeOnTopOfOtherModals}
      data-is-modal={1}
      style={style}
      ref={wrapperRef}
      onMouseDown={(e) => {
        if (disableClose) return;
        if (e.target !== wrapperRef.current) {
          return;
        }
        if (onClick) {
          onClick(e);
          return;
        }
        if (closeOnWrapperClick === false) {
          return;
        }
        if (unsavedSettings) {
          if (window.confirm("Are you sure you want to quit without saving?")) {
            e.stopPropagation();
            setModalTypeOpen(undefined);
          }
        } else {
          e.stopPropagation();
          setModalTypeOpen(undefined);
        }
      }}
    >
      {children}
    </Wrapper>
  );
};

export default FullScreenModal;
