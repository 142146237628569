import styled, { css } from "styled-components";
import LoadingIcon from "@icons/24/Loading.svg";

const generateClasses = (animationDurationS = 1) => {
  const styles = [];

  for (let i = 1; i <= 8; i++) {
    styles.push(`
      .loading-${i} {
        animation: animateLoading ${animationDurationS}s linear infinite;
        animation-delay: ${(animationDurationS / 8) * (i - 1)}s;
      }
    `);
  }

  return css`
    ${styles.join("\n")}
  `;
};

const AnimatedLoading = styled(LoadingIcon)<{
  $size?: string;
  $baseColor?: string;
  $highlightColor?: string;
  $thickness?: number;
}>`
  overflow: visible;
  @keyframes animateLoading {
    0%,
    100% {
      stroke: ${({ $baseColor }) => $baseColor}; // Base color
    }

    12.5%,
    25% {
      stroke: ${({ $highlightColor }) => $highlightColor}; // Highlight color
    }
  }

  ${({ $baseColor, $thickness }) =>
    $baseColor &&
    `
    path {
      stroke: ${$baseColor};
      ${$thickness && `stroke-width: ${$thickness}`}
    }
  `};

  ${({ $size }) => $size && `width: ${$size}; height: ${$size};`};

  ${generateClasses()};
`;

export default AnimatedLoading;
