import { ABLY_SIZE_LIMIT } from "@constants/ably";
import { DataSource } from "../state/dataSource";
import { ProjectFeature } from "../types/feature";
import { gzipAndBase64Encode } from "./utils";

//By stuffing things into an object we add some extra bytes, so we need to account for that
const ABLY_SIZE_LIMIT_WITH_BUFFER = ABLY_SIZE_LIMIT - 2000;

export function prepareProjectUpdateMessagesGzip(
  dataSource: DataSource,
  changedFeatures: {
    add?: ProjectFeature[];
    remove?: string[];
    update?: ProjectFeature[];
  },
) {
  const recordByteLength = new Blob([
    JSON.stringify({
      dataSource,
      changedFeatures: {
        ...(changedFeatures.add
          ? { add: changedFeatures.add.map((f) => gzipAndBase64Encode(f)) }
          : {}),
        remove: changedFeatures.remove,
        ...(changedFeatures.update
          ? {
              update: changedFeatures.update.map((f) => gzipAndBase64Encode(f)),
            }
          : {}),
      },
    }),
  ]).size;

  if (recordByteLength < ABLY_SIZE_LIMIT_WITH_BUFFER) {
    return [
      {
        dataSource,
        add: changedFeatures.add?.map((e) => gzipAndBase64Encode(e)),
        remove: changedFeatures.remove,
        update: changedFeatures.update?.map((e) => gzipAndBase64Encode(e)),
      },
    ];
  }

  const messages: {
    add: string[];
    remove: string[];
    update: string[];
  }[] = [];

  let accumulatedSize = 0;
  let tempMessage: {
    add: string[];
    remove: string[];
    update: string[];
  } = { add: [], remove: [], update: [] };

  const getElementSize = (element: string) =>
    new TextEncoder().encode(element).length;

  for (const element of changedFeatures.remove ?? []) {
    const size = getElementSize(element);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT_WITH_BUFFER) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.remove.push(element);
    accumulatedSize += size;
  }

  for (const element of changedFeatures.add ?? []) {
    const gzippedElement = gzipAndBase64Encode(element);
    const size = getElementSize(gzippedElement);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT_WITH_BUFFER) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.add.push(gzippedElement);
    accumulatedSize += size;
  }

  for (const element of changedFeatures.update ?? []) {
    const gzippedElement = gzipAndBase64Encode(element);
    const size = getElementSize(gzippedElement);
    if (accumulatedSize + size > ABLY_SIZE_LIMIT_WITH_BUFFER) {
      messages.push({ ...tempMessage });
      tempMessage = { add: [], remove: [], update: [] };
      accumulatedSize = 0;
    }
    tempMessage.update.push(gzippedElement);
    accumulatedSize += size;
  }

  if (
    tempMessage.add.length +
      tempMessage.remove.length +
      tempMessage.update.length >
    0
  ) {
    messages.push({ ...tempMessage });
  }

  return messages.map((m) => ({ dataSource, ...m }));
}
