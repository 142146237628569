import { ColorCategory } from "business/style/types";
import { Buckets, Color, Gradient } from "lib/colors";
import styled from "styled-components";

export const ColorSquare = styled.div.attrs<{ $color: Color }>((props) => ({
  style: {
    background: props.$color.css(),
  },
}))<{
  $color: Color;
}>`
  width: 2rem;
  height: 2em;
  border: 1px solid #00000020;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const GradientSquare = styled.div.attrs<{ gradient: Gradient }>(
  (props) => ({
    style: {
      backgroundImage: props.gradient.css(),
    },
  }),
)<{
  gradient: Gradient;
}>`
  width: 2rem;
  height: 2em;
  border-radius: 2px;
  background-position-x: -1px;
  background-size: calc(100% + 2px);
  box-sizing: border-box;
  border: 1px solid #00000020;
`;

const _BucketSquare = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-radius: 2px;
  overflow: hidden;
  > :nth-child(1),
  > :nth-child(2) {
    border-top: 1px solid #00000020;
  }
  > :nth-child(2),
  > :nth-child(4) {
    border-right: 1px solid #00000020;
  }
  > :nth-child(3),
  > :nth-child(4) {
    border-bottom: 1px solid #00000020;
  }
  > :nth-child(1),
  > :nth-child(3) {
    border-left: 1px solid #00000020;
  }
`;
export const BucketSquare = ({
  buckets,
  className,
}: {
  buckets: Buckets;
  className?: string;
}) => {
  const bs = buckets.buckets();
  const n = bs.length;
  const i0 = 0;
  const i1 = Math.round((n - 1) / 3);
  const i2 = Math.round((2 * (n - 1)) / 3);
  const i3 = n - 1;

  return (
    <_BucketSquare className={className}>
      <div style={{ background: bs[i0].color.css() }} />
      <div style={{ background: bs[i1].color.css() }} />
      <div style={{ background: bs[i2].color.css() }} />
      <div style={{ background: bs[i3].color.css() }} />
    </_BucketSquare>
  );
};

const _CategoriesSquare = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-radius: 2px;
  overflow: hidden;
  > div {
  }
`;
export const CategoriesSquare = ({
  categories,
  className,
}: {
  categories: ColorCategory[];
  className?: string;
}) => {
  const n = categories.length;
  const i0 = 0;
  const i1 = Math.round((n - 1) / 3);
  const i2 = Math.round((2 * (n - 1)) / 3);
  const i3 = n - 1;

  const c0 = categories.at(i0)?.color ?? Color.Transparent();
  const c1 = categories.at(i1)?.color ?? Color.Transparent();
  const c2 = categories.at(i2)?.color ?? Color.Transparent();
  const c3 = categories.at(i3)?.color ?? Color.Transparent();

  return (
    <_CategoriesSquare className={className}>
      <div style={{ background: c0.css() }} />
      <div style={{ background: c1.css() }} />
      <div style={{ background: c2.css() }} />
      <div style={{ background: c3.css() }} />
    </_CategoriesSquare>
  );
};
