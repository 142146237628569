import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { Group } from "components/Organisation/Groups/types";
import { List } from "./style";
import RemoveIcon from "@icons/24/Remove.svg?react";
import TeamMeetingIcon from "@icons/24/TeamMeeting.svg?react";
import { spaceSmall } from "styles/space";
import { IconREMSize, typography } from "styles/typography";

interface GroupListProps {
  groups: Group[];
  onRemove: (groupId: string) => void;
}

export const GroupList = ({ groups, onRemove }: GroupListProps) => {
  const { scrollBodyRef } = useShowScrollShadow(true);

  return (
    <List ref={scrollBodyRef}>
      {groups.map((group) => (
        <GroupRow
          key={group.id}
          group={group}
          onRemove={() => onRemove(group.id)}
        />
      ))}
    </List>
  );
};

const GroupRow = ({ group, onRemove }: { group: Group; onRemove(): void }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: spaceSmall,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: spaceSmall,
        }}
      >
        <IconREMSize height={1.6} width={1.6}>
          <TeamMeetingIcon />
        </IconREMSize>
        <p
          style={{
            ...typography.contentAndButtons,
          }}
        >
          {group.name}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          gap: spaceSmall,
        }}
      >
        <IconREMSize
          height={1.4}
          width={1.4}
          style={{
            cursor: "pointer",
          }}
          onClick={onRemove}
        >
          <RemoveIcon />
        </IconREMSize>
      </div>
    </div>
  );
};
