import { useEffect } from "react";
import { CablePartitionFeature } from "types/feature";
import { safeRemoveLayer } from "utils/map";
import { addLayer } from "./utils";
import { cablePartitionSourceId, cablePartitionLayerId } from "./constants";

const cablePartitionPaint: mapboxgl.FillPaint = {
  "fill-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    0.6,
    0.2,
  ],
  "fill-color": "orange",
};

export const RenderCablePartitions = ({
  map,
  partitions,
}: {
  map: mapboxgl.Map;
  partitions: CablePartitionFeature[];
}) => {
  useEffect(() => {
    map.addSource(cablePartitionSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cablePartitionLayerId);
      map.removeSource(cablePartitionSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: cablePartitionLayerId,
      type: "fill",
      source: cablePartitionSourceId,
      paint: cablePartitionPaint,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(cablePartitionSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: partitions,
    });
  }, [map, partitions]);

  return null;
};
