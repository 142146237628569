import { threedBuildingsLayerId } from "components/Mapbox/constants";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { mapAtom } from "state/map";
import { safeRemoveLayer } from "utils/map";

const ThreeDOSMBuildings = () => {
  const mapRefJ = useAtomValue(mapAtom);

  useEffect(() => {
    if (!mapRefJ) return;

    mapRefJ.addLayer({
      id: threedBuildingsLayerId,
      source: "composite",
      "source-layer": "building",
      filter: ["==", "extrude", "true"],
      type: "fill-extrusion",
      minzoom: 15,
      paint: {
        "fill-extrusion-color": "#aaa",
        "fill-extrusion-height": ["get", "height"],
        "fill-extrusion-base": ["get", "min_height"],
        "fill-extrusion-opacity": 0.8,
      },
    });

    return () => {
      if (mapRefJ) {
        safeRemoveLayer(mapRefJ, threedBuildingsLayerId);
      }
    };
  }, [mapRefJ]);

  return null;
};

export default ThreeDOSMBuildings;
