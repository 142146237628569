import { getProj4StringForEPSG } from "../services/epsgAPIService";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";

export const getProj4StringForEPSGSelectorFamily = atomFamily(
  (epsg: number | string | undefined) =>
    atom(async () => {
      if (!epsg) return;
      const epsgNumber = typeof epsg === "string" ? parseInt(epsg) : epsg;
      if (isNaN(epsgNumber)) return;
      try {
        return await getProj4StringForEPSG(epsgNumber);
      } catch (e) {
        return;
      }
    }),
);
