import { InputDimensioned } from "../../General/Input";
import { Label } from "../../General/Form";
import { VesselType } from "services/vesselService";
import { vesselChangelogInfo } from "components/InputChangelog/const";
import { kmPerHrToKnots } from "./VesselSettings";
import { SecondaryText } from "components/SettingsV2/Shared/styles";

export function AnchorHandlingVesselSettings({
  vessel,
  setVessel,
  disabled,
  nodeId,
}: {
  vessel: VesselType & { type: "anchorHandling" };
  setVessel: (vessel: VesselType) => void;
  disabled: boolean;
  nodeId: string;
}) {
  return (
    <>
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "auto auto",
          gap: "2.4rem",
        }}
      >
        <Label>
          <SecondaryText>Anchor capacity</SecondaryText>
          <InputDimensioned
            decimals={0}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.anchorCapacity}
            unit={"units"}
            step={1}
            validate={(e) => e >= 1 && e <= 50}
            validationMessage={`Must be between 1 and 50`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "anchorCapacity",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                anchorCapacity: Math.round(n),
              });
            }}
          />
        </Label>
        <Label>
          <SecondaryText>Towing speed </SecondaryText>
          <InputDimensioned
            decimals={1}
            style={{ width: "20rem" }}
            disabled={disabled}
            value={vessel.towingSpeed}
            scaleFactor={kmPerHrToKnots}
            unit={"knots"}
            step={1}
            validate={(e) => e >= 1 && e <= 20}
            validationMessage={`Must be between 1 and 20`}
            changelogInfo={vesselChangelogInfo(
              vessel.id,
              nodeId,
              "towingSpeed",
              "project",
            )}
            onChange={(n) => {
              setVessel({
                ...vessel,
                towingSpeed: n,
              });
            }}
          />
        </Label>
      </div>
    </>
  );
}
