import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import debounce from "debounce";
import { modalTypeOpenAtom } from "state/modal";
import { openInNewWindowAtom } from "./state";
import {
  cloneExistingLinks,
  cloneExistingScripts,
  cloneExistingStylesAndPollForStyleChanges,
  readSizeFromLocalStorage,
  saveSizeToLocalStorage,
} from "./utils";
import { Mixpanel } from "mixpanel";
import { WindowContextProvider } from "components/WindowedHOC/WindowContext";
import { useTrackEvent } from "components/OnboardingTours/state";
import { useAtom, useSetAtom } from "jotai";

const Windowed = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  WrapperWhenNotWindowed: React.ComponentType<
    React.PropsWithChildren<
      {
        setOpenInNewWindow(): void;
        closeModal(): void;
      } & P
    >
  >,
  WrapperWhenNewWindow: React.ComponentType<React.PropsWithChildren<P>>,
  componentIdentifier: string,
  options: {
    width?: number;
    height?: number;
    title?: string;
  },
) => {
  return function RenderInWindow(props: P) {
    const [container, setContainer] = useState<HTMLDivElement>();
    const [newWindow, setNewWindow] = useState<Window | undefined>();
    const setOpenedModal = useSetAtom(modalTypeOpenAtom);
    const [openInNewWindow, setOpenInNewWindow] = useAtom(openInNewWindowAtom);
    const stylePollingIntervalId = useRef<number>();
    const doOpenInNewWindow = openInNewWindow[componentIdentifier];

    useEffect(() => {
      setContainer(document.createElement("div"));
    }, []);

    const onWindowResize = useMemo(
      () =>
        debounce((event: UIEvent) => {
          const target = event.target as Window | null;
          if (!target) {
            return;
          }

          Mixpanel.track_old("Resize new window modal", {
            componentIdentifier,
            width: target.innerWidth,
            height: target.innerHeight,
          });

          saveSizeToLocalStorage(
            componentIdentifier,
            target.innerWidth,
            target.innerHeight,
          );
        }, 1000),
      [],
    );

    const openWindowAndAppendPortal = useCallback(
      (report: (intervalId: number) => void) => {
        const savedSize = readSizeFromLocalStorage(componentIdentifier);

        const width = savedSize?.width ?? options.width ?? 600;
        const height = savedSize?.height ?? options.height ?? 400;
        const openedWindow = window.open(
          "",
          componentIdentifier,
          `width=${width},height=${height},left=200,top=200,popup`,
        );

        if (!openedWindow) {
          return;
        }

        const parent = openedWindow.opener as Document | null;
        if (!parent) {
          openedWindow.close();
          return;
        }

        // Close the opened window when refreshing the opener window
        parent.addEventListener("unload", () => {
          openedWindow.close();
        });

        // Close the opened window if user is trying to refresh the opened window
        openedWindow.addEventListener("unload", () => {
          openedWindow.close();
        });

        // Set opened modal to undefined when openedwindow is closed
        openedWindow.addEventListener("beforeunload", () => {
          setOpenInNewWindow((curr) => ({
            ...curr,
            [componentIdentifier]: false,
          }));
        });

        openedWindow.addEventListener("resize", onWindowResize);

        const parentStylePollIntervalId =
          cloneExistingStylesAndPollForStyleChanges(openedWindow);
        cloneExistingScripts(openedWindow);
        cloneExistingLinks(openedWindow);

        if (options.title) {
          const titleElem = openedWindow.document.createElement("title");
          titleElem.innerText = options.title;
          openedWindow.document.head.appendChild(titleElem);
        }

        setNewWindow(openedWindow);
        openedWindow.document.body.appendChild(container!);

        report(parentStylePollIntervalId);
      },
      [container, onWindowResize, setOpenInNewWindow],
    );

    useEffect(() => {
      if (!container || !doOpenInNewWindow || newWindow) {
        return;
      }

      // Firefox bug: "Should not already be working"
      // Open window in next event cycle to avoid bug
      // https://github.com/facebook/react/issues/17355
      // Seems to have been fixed in react 18
      setTimeout(openWindowAndAppendPortal, 0, (intervalId) => {
        // Horrible workaround to be able to disconnect the mutationObserver on unmount since we can't use the cleanup function in this effect
        stylePollingIntervalId.current = intervalId;
      });
    }, [container, newWindow, doOpenInNewWindow, openWindowAndAppendPortal]);

    useEffect(() => {
      return () => {
        clearInterval(stylePollingIntervalId.current);
      };
    }, []);

    // If the modal has been windowed but not anymore, close any window and reset state vars
    useEffect(() => {
      if (!doOpenInNewWindow && newWindow) {
        newWindow.close();
        setNewWindow(undefined);
        setContainer(document.createElement("div"));
      }
    }, [doOpenInNewWindow, newWindow]);

    const trackEvent = useTrackEvent();
    if (!container) {
      return null;
    }

    if (!doOpenInNewWindow) {
      return (
        <WrapperWhenNotWindowed
          setOpenInNewWindow={() => {
            Mixpanel.track_old("Open modal in new window", {
              componentIdentifier,
            });
            setOpenedModal(undefined);
            setOpenInNewWindow((curr) => ({
              ...curr,
              [componentIdentifier]: true,
            }));
          }}
          closeModal={() => {
            trackEvent("closedCompare");
            setOpenedModal(undefined);
          }}
          {...props}
        >
          <WrappedComponent {...props} />
        </WrapperWhenNotWindowed>
      );
    }

    return createPortal(
      <WindowContextProvider context={newWindow}>
        <DndProvider backend={HTML5Backend} context={newWindow}>
          <WrapperWhenNewWindow {...props}>
            <WrappedComponent {...props} />
          </WrapperWhenNewWindow>
        </DndProvider>
      </WindowContextProvider>,
      container,
    );
  };
};

export default Windowed;
