import styled from "styled-components";
import WarningTriangleIcon from "@icons/24/WarningTriangle.svg";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import { minArcgisRasterZoomLevel } from "@constants/layer";
import { useMapZoomLevel } from "hooks/useMapZoomLevel";
import Tooltip from "components/General/Tooltip";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  overflow: auto;
`;

export const ZoomTooLowWarning = () => {
  const mapZoomLevel = useMapZoomLevel();
  if (mapZoomLevel >= minArcgisRasterZoomLevel) {
    return null;
  }
  return (
    <ErrorWrapper>
      <Tooltip
        text={`Only visible at zoom level ${minArcgisRasterZoomLevel} or above. Current zoom level: ${mapZoomLevel}`}
      >
        <IconREMSize height={1.4} width={1.4} stroke={colors.textWarning}>
          <WarningTriangleIcon
            title={`Only visible at zoom level ${minArcgisRasterZoomLevel} or above. Current zoom level: ${mapZoomLevel}`}
          />
        </IconREMSize>
      </Tooltip>
    </ErrorWrapper>
  );
};
