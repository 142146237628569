import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { parkFamily } from "state/jotai/park";

export const differentExportSystemTypesInCableSelectorFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string }) =>
    atom<
      Promise<
        | {
            type: ValidationWarningTypes.ExportCableDifferentTypesInCable;
            featureIds: string[];
            parkId: string;
          }
        | undefined
      >
    >(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return;

      const exportCables = await get(
        exportCablesInParkFamily({
          parkId,
          branchId,
        }),
      );
      const exportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );

      const cablesDifferentType = exportCables.filter((c) => {
        const offshoreCableType = exportCableTypes.get(
          c.properties.cableTypeId ?? "",
        )?.exportCableType;
        const onshoreCableType = exportCableTypes.get(
          c.properties.onshoreCableTypeId ?? "",
        )?.exportCableType;

        return (
          offshoreCableType &&
          onshoreCableType &&
          offshoreCableType !== onshoreCableType
        );
      });

      return cablesDifferentType.length === 0
        ? undefined
        : {
            type: ValidationWarningTypes.ExportCableDifferentTypesInCable,
            featureIds: cablesDifferentType.map((f) => f.id),
            parkId: parkId,
          };
    }),
);
