import { analysisStoppedText } from "analysis/warnings";
import { DownloadCapexData } from "components/FinancialAnalysis/Capex/DownloadCapexData";
import { RealValueInfoTag } from "components/FinancialAnalysis/costUtils";
import { Row } from "components/General/Layout";
import Toggle, { ToggleSize } from "components/General/Toggle";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { ValidationAlert } from "components/ValidationWarnings/ValidationAlert";
import { useRealValueCapexMillion } from "finance/hooks/useCapex";
import { FinanceId } from "finance/types";
import useBooleanState from "hooks/useBooleanState";
import { useAtomValue } from "jotai";
import { CostType } from "../../../services/costService";
import { CostUnit, amountUnitPrefixSuffix } from "../../../types/financial";
import { orLoader } from "../../Loading/Skeleton";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { Stat, StatHeaderLoadable, Stats } from "./Base.style";
import { getFinancialStoppedReason } from "finance/warnings";
import { isOnshoreAtom } from "state/onshore";

const CapexTableTitle = () => (
  <span style={{ display: "flex", gap: "1rem" }}>
    CAPEX
    <RealValueInfoTag text="The total CAPEX inflation adjusted to the current year. Excluding contingency." />
  </span>
);
export const CapexTableWidget = () => {
  const { park, branch, triggerId, errorBoundaryResetKeys } =
    useDashboardContext();
  const isOnshore = useAtomValue(isOnshoreAtom);

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId: branch.id }),
  );
  if (Object.values(invalidTypes).some((e) => e))
    return (
      <CenterContainer style={{ margin: "3rem" }}>
        <SimpleAlert
          text={"Some elements are using a type that is invalid"}
          type={"error"}
        />
      </CenterContainer>
    );
  return (
    <SafeCard
      title={<CapexTableTitle />}
      id="Capex table"
      menuItems={<DownloadCapexData id={triggerId} buttonType={"menu"} />}
      resetKeys={errorBoundaryResetKeys}
    >
      {isOnshore ? <CAPEXTableInnerOnshore /> : <CAPEXTableInnerV2 />}
    </SafeCard>
  );
};

const CAPEXCategoryPrinterV2 = ({
  title,
  type,
  id,
  viewSumPerMW,
}: {
  title: string;
  type: CostType;
  id: FinanceId;
  viewSumPerMW: boolean;
}) => {
  const { useCosts } = useRealValueCapexMillion(id);
  const { costs, sum, sumPerMW, costsPerMW } = useCosts(type);

  const costUnit = viewSumPerMW
    ? CostUnit.millionEuroPerMW
    : CostUnit.millionEuro;

  const costsToUse = viewSumPerMW ? costsPerMW : costs;
  const sumToUse = viewSumPerMW ? sumPerMW : sum;

  return (
    <>
      <StatHeaderLoadable title={title} value={sumToUse} unit={costUnit} />
      {orLoader(costsToUse, (costs) => (
        <>
          {costs
            .filter((c) => c.amount !== 0)
            .map((c) => (
              <Stat
                bold={false}
                key={c.id}
                title={`${
                  amountUnitPrefixSuffix[c.amountUnit]?.prefix ?? ""
                }${Math.round(c.amount)} ${
                  amountUnitPrefixSuffix[c.amountUnit]?.suffix ?? ""
                } ${c.name}`}
                value={`${c.cost} ${costUnit}`}
              />
            ))}
        </>
      ))}
    </>
  );
};

const CAPEXTableInnerV2 = () => {
  const { triggerId } = useDashboardContext();
  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(triggerId),
  );
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );

  const [viewSumPerMW, toggleViewSumPerMW] = useBooleanState(false);

  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Installation analysis stopped"
          description={installationStoppedText[installationStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <Row alignCenter style={{ marginLeft: "auto" }}>
        <label>Show m€/MW</label>
        <Toggle
          size={ToggleSize.TINY}
          checked={viewSumPerMW}
          onChange={toggleViewSumPerMW}
        />
      </Row>
      <CAPEXCategoryPrinterV2
        title={"Turbines"}
        type={CostType.Turbine}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Inter array cabling"}
        type={CostType.Cable}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Foundations"}
        type={CostType.Foundation}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Mooring"}
        type={CostType.Mooring}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Substation"}
        type={CostType.Substation}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Export cable"}
        type={CostType.ExportCable}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Other"}
        type={CostType.Other}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
    </Stats>
  );
};

const CAPEXTableInnerOnshore = () => {
  const { triggerId } = useDashboardContext();
  const analysisStoppedReason = useAtomValue(
    getFinancialStoppedReason(triggerId),
  );

  const [viewSumPerMW, toggleViewSumPerMW] = useBooleanState(false);

  if (analysisStoppedReason) {
    return (
      <CenterContainer>
        <ValidationAlert
          type={"error"}
          title="Analysis stopped"
          description={analysisStoppedText[analysisStoppedReason]}
        />
      </CenterContainer>
    );
  }

  return (
    <Stats>
      <Row alignCenter style={{ marginLeft: "auto" }}>
        <label>Show m€/MW</label>
        <Toggle
          size={ToggleSize.TINY}
          checked={viewSumPerMW}
          onChange={toggleViewSumPerMW}
        />
      </Row>
      <CAPEXCategoryPrinterV2
        title={"Turbines"}
        type={CostType.Turbine}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Inter array cabling"}
        type={CostType.Cable}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Substation"}
        type={CostType.Substation}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Export cable"}
        type={CostType.ExportCable}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
      <CAPEXCategoryPrinterV2
        title={"Other"}
        type={CostType.Other}
        id={triggerId}
        viewSumPerMW={viewSumPerMW}
      />
    </Stats>
  );
};
