import {
  _FolderOrResourceItem,
  FolderTreeItemOrResourceItem,
} from "types/folderStructures";
import { fetchSchemaWithToken } from "./utils";

export const createOrgFolder = async (
  orgId: string,
  libraryManageRole: string,
  name: string,
  folderItems: FolderTreeItemOrResourceItem[],
  parentId?: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        parentId: parentId,
        folderItems: folderItems,
      }),
    },
  );
};

export const getOrgFoldersForType = async (
  orgId: string,
  libraryManageRole: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}`,
    {
      method: "GET",
    },
  );
};

export const deleteOrgFolder = async (
  orgId: string,
  libraryManageRole: string,
  folderId: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}/${folderId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const deleteOrgResource = async (
  orgId: string,
  libraryManageRole: string,
  resourceId: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}/resource/${resourceId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const editOrgFolderName = async (
  orgId: string,
  libraryManageRole: string,
  folderId: string,
  name: string,
) => {
  return await fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}/${folderId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    },
  );
};

export const moveOrgItems = async (
  orgId: string,
  libraryManageRole: string,
  folderItems: Partial<FolderTreeItemOrResourceItem>[],
  parentId: string | undefined,
) => {
  return fetchSchemaWithToken(
    _FolderOrResourceItem.array(),
    `/api/folders/organisation/${orgId}/role/${libraryManageRole}/move`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        folderItems,
        parentId,
      }),
    },
  );
};
