import * as turf from "@turf/turf";
import { fetchEnhancer } from "services/utils";
import { projectIdAtom } from "state/pathParams";
import { Tile, tile2bbox } from "types/tile";
import { BBOX } from "utils/geojson/validate";
import { bbox } from "utils/geometry";
import { scream } from "utils/sentry";
import { Raster } from "../types/raster";
import {
  fetchSlopeInPolygon,
  fetchTile,
  fetchTileFromBBOX,
} from "../services/bathymertyService";
import {
  SlopeResponseError,
  SlopeResponseFinished,
  SlopeResponseWithRaster,
  TileResponseError,
  TileResponseFinished,
  TileResponseStarted,
  TileResponseWithRaster,
} from "../types/bathymetryTypes";
import { atom } from "jotai";
import { atomFamily } from "utils/jotai";
import { featureMapFamily } from "./jotai/features";
import { canvasLayerBathymetryFilenamesAtom } from "./jotai/bathymetry";
import { Feature, Polygon } from "geojson";
import { isPolygonFeature } from "utils/predicates";
import { geotiffResponseToFloat32Array } from "utils/image";

/**
 * Decodes a depth encoded RGB value to a depth in meters. Depths are negative.
 */
const decodeBathymetryRGB = (sample: number[]): number => {
  return -32768 + (sample[0] * 256 + sample[1] + sample[2] / 256);
};

export const pngResponseToDepthArray = async (
  response: Response,
): Promise<{ depth: Float32Array; width: number; height: number }> => {
  const blob = await response.blob();
  const img = new Image();
  const url = URL.createObjectURL(blob);
  img.src = url;
  await new Promise((resolve) => {
    img.onload = resolve;
  });
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Could not get 2d context");
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const data = new Float32Array(imageData.data.length / 4);
  for (let i = 0, k = 0; i < imageData.data.length; i += 4) {
    data[k++] = decodeBathymetryRGB([
      imageData.data[i + 0],
      imageData.data[i + 1],
      imageData.data[i + 2],
    ]);
  }
  return {
    depth: data,
    width: img.width,
    height: img.height,
  };
};

/**
 * New type so that we can stick it in a selector.
 */
type StrippedPolygon = {
  type: "Feature";
  geometry: {
    type: "Polygon";
    coordinates: number[][][];
  };
};

// Jotai bathymetry

/** Store for the actual responses. */
export const bathymetryFamily = atomFamily((_bathymetryId: string) =>
  atom(new Promise<TileResponseWithRaster | TileResponseError>(() => {})),
);

/** Store for the actual slope responses. */
export const bathymetrySlopeFamily = atomFamily((_bathymetryId: string) =>
  atom(new Promise<SlopeResponseWithRaster | SlopeResponseError>(() => {})),
);

export const bathymetryWithRasterFamily = atomFamily((bathymetryId: string) =>
  atom<Promise<TileResponseWithRaster>>(async (get) => {
    const res = await get(bathymetryFamily(bathymetryId));
    if (res.status === "finished") return res;
    throw new Error("Bathymetry failed");
  }),
);

/**
 * Async responses from tile requests set by Ably.
 * This will not resolve until we've gotten a response from ably for the given bathymetry id.
 */
export const bathymetryAblyResponse = atomFamily((_bathymetryId: string) =>
  atom(new Promise<TileResponseFinished | TileResponseError>(() => {})),
);

/**
 * Async responses from tile requests set by Ably.
 * This will not resolve until we've gotten a response from ably for the given bathymetry id.
 */
export const bathymetrySlopeAblyResponse = atomFamily((_bathymetryId: string) =>
  atom(new Promise<SlopeResponseFinished | SlopeResponseError>(() => {})),
);

const DEFAULT_BUFFER_KM = 1;
/**
 * Bounding box for the given feature id.
 */
const bboxFamily = atomFamily(
  ({
    featureId,
    branchId,
    bufferKm,
  }: {
    featureId: string;
    branchId: string | undefined;
    bufferKm: number | undefined;
  }) =>
    atom<Promise<BBOX>>(async (get) => {
      const fmap = await get(featureMapFamily({ branchId }));
      const feature = fmap.get(featureId);
      if (!feature) throw new Error(`No feature with id "${featureId}"`);
      const buffered = turf.buffer(feature, bufferKm ?? DEFAULT_BUFFER_KM, {
        units: "kilometers",
      });
      if (!buffered)
        throw scream("failed to buffer polygon", { feature, bufferKm });
      return bbox(buffered);
    }),
);

const tileFromBBOXFamily = atomFamily(
  ({
    bbox,
    nodeId,
    bathymetryIds,
  }: {
    bbox: BBOX;
    nodeId: string;
    bathymetryIds: string[] | undefined;
  }) =>
    atom(async () => {
      const [minLon, minLat, maxLon, maxLat] = bbox;
      if (minLon >= maxLon || minLat >= maxLat)
        throw new Error(
          `This is an illegal bbox, should not happen, nodeId: ${nodeId}`,
        );
      const res = await fetchTileFromBBOX(bbox, nodeId, bathymetryIds);
      return res;
    }),
);

const blobCacheFamily = atomFamily((url: string) =>
  atom<Promise<Blob>>(async () => {
    const response = await fetchEnhancer(url);
    const blob = await response.blob();
    return blob;
  }),
);

const bathymetryBBOXFamily = atomFamily(
  ({ projectId, bbox }: { projectId: string; bbox: BBOX }) =>
    atom(async (get) => {
      const bathymetryIds = await get(canvasLayerBathymetryFilenamesAtom);
      let tileResponse = await get(
        tileFromBBOXFamily({ bbox, nodeId: projectId, bathymetryIds }),
      );

      if (tileResponse.status === "started") {
        // Async response. Wait for it over Ably.
        const ablyResponse = await get(bathymetryAblyResponse(tileResponse.id));
        if (ablyResponse.status === "failed") return ablyResponse;
        tileResponse = ablyResponse;
      }

      const [minLon, minLat, maxLon, maxLat] = bbox;
      const blob = await get(blobCacheFamily(tileResponse.url));
      const {
        width,
        height,
        array: values,
      } = await geotiffResponseToFloat32Array(blob);
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const stepLat = sizeLatitude / height;
      const stepLon = sizeLongitude / width;

      return {
        ...tileResponse,
        raster: new Raster(
          Array.from(values),
          width,
          height,
          minLon,
          maxLat,
          stepLon,
          stepLat,
        ),
      };
    }),
);

/**
 * Get a single bahymetry tile.  This doesn **not** include custom bathymetry.
 *
 * Mainly used for debugging.
 */
export const bathymetryTileFamily = atomFamily((tile: Tile) =>
  atom(async () => {
    const TILE_SIZE = 256;
    const { x, y, z } = tile;
    const [minLon, minLat, maxLon, maxLat] = tile2bbox(tile);
    const res = await fetchTile({ x, y, z }, { tilesize: TILE_SIZE });
    const { depth, width, height } = await pngResponseToDepthArray(res);
    const sizeLatitude = maxLat - minLat;
    const sizeLongitude = maxLon - minLon;
    const stepLat = sizeLatitude / height;
    const stepLon = sizeLongitude / width;
    return new Raster(
      Array.from(depth),
      width,
      height,
      minLon,
      maxLat,
      stepLon,
      stepLat,
    );
  }),
);

/**
 * Gets a bathymetry raster around the feature with the given `feautreId`.
 *
 * # Usage
 * You probably don't want to use this in a selector because it is very easy to
 * accidentally ask for a lot of bathymetry tiles.  A better pattern is to use
 * `useBathymetry` to fetch the tile, and pass around the raster `id` to atoms.
 * The {@link Raster} object is is stored in `bathymetryFamily`. This makes it
 * easier to control the number of tiles requested, as well as ensuring that
 * different atoms use the same bathymetry tile, so that their depths are
 * consistent.
 */
export const bathymetryFetchFamily = atomFamily(
  ({
    projectId,
    branchId,
    featureId,
    bufferKm,
  }: {
    projectId: string | undefined;
    branchId: string | undefined;
    featureId: string;
    bufferKm: number | undefined;
  }) =>
    atom<Promise<TileResponseWithRaster | TileResponseError>>(async (get) => {
      const bbox = await get(bboxFamily({ featureId, branchId, bufferKm }));
      const _projectId = projectId ?? get(projectIdAtom);
      if (!_projectId)
        throw new Error("bathymetryBBOXFamily requires projectIdAtom");
      const response = get(
        bathymetryBBOXFamily({ projectId: _projectId, bbox }),
      );
      return response;
    }),
);

const slopeTileFromBBOX = atomFamily(
  ({
    polygon,
    nodeId,
    bathymetryIds,
  }: {
    polygon: StrippedPolygon;
    nodeId: string;
    bathymetryIds: string[];
  }) =>
    atom<Promise<SlopeResponseFinished | TileResponseStarted>>(() =>
      fetchSlopeInPolygon(
        { nodeId },
        {
          polygon,
          bathymetryIds,
        },
        {},
      ),
    ),
);

const slopeForPolygonFamily = atomFamily(
  ({
    projectId,
    polygon,
  }: {
    projectId: string | undefined;
    polygon: Feature<Polygon>;
  }) =>
    atom<Promise<SlopeResponseWithRaster | SlopeResponseError>>(async (get) => {
      const bathymetryIds = await get(canvasLayerBathymetryFilenamesAtom);

      const _projectId = projectId ?? get(projectIdAtom);
      if (!_projectId)
        throw new Error("slopeForPolygonFamily requires projectIdAtom");
      let tileResponse = await get(
        slopeTileFromBBOX({ polygon, nodeId: _projectId, bathymetryIds }),
      );

      if (tileResponse.status === "started") {
        const ablyResponse = await get(
          bathymetrySlopeAblyResponse(tileResponse.id),
        );
        if (ablyResponse.status === "failed") return ablyResponse;
        tileResponse = ablyResponse;
      }

      const [minLon, minLat, maxLon, maxLat] = turf.bbox(polygon);
      const blob = await get(blobCacheFamily(tileResponse.url));
      const {
        width,
        height,
        array: values,
      } = await geotiffResponseToFloat32Array(blob);
      const sizeLatitude = maxLat - minLat;
      const sizeLongitude = maxLon - minLon;
      const stepLat = sizeLatitude / height;
      const stepLon = sizeLongitude / width;
      return {
        ...tileResponse,
        raster: new Raster(
          Array.from(values),
          width,
          height,
          minLon,
          maxLat,
          stepLon,
          stepLat,
        ),
      };
    }),
);

export const slopeFetchFamily = atomFamily(
  ({
    projectId,
    featureId,
    bufferKm,
    branchId,
  }: {
    featureId: string;
    projectId: string | undefined;
    branchId: string | undefined;
    bufferKm: number | undefined;
  }) =>
    atom<Promise<SlopeResponseWithRaster | SlopeResponseError>>(async (get) => {
      const featureMap = await get(featureMapFamily({ branchId }));
      const feature = featureMap.get(featureId);
      if (!feature) throw new Error(`No feature with id ${featureId}`);
      if (!isPolygonFeature(feature))
        throw new Error(
          `Requested slope bathymetry for wrong feature type: ${feature.geometry.type}`,
        );

      const polygon =
        bufferKm === undefined
          ? feature
          : turf.buffer(feature, bufferKm, { units: "kilometers" });
      if (!polygon || polygon.geometry.type !== "Polygon")
        throw new Error("Can only find slope of a polygon");

      return get(slopeForPolygonFamily({ projectId, polygon }));
    }),
);
