import React, {
  PropsWithChildren,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
} from "react";
import { useBathymetry } from "hooks/bathymetry";
import { WindSourceConfiguration } from "services/windSourceConfigurationService";
import { AnalysisConfiguration } from "services/configurationService";
import { EnrichedSelectedParkCompare } from "components/CompareParksModal/state";
import { loggedInUserIsCheckly } from "state/user";
import { useDeep, useDeepMemo } from "hooks/useDeep";
import { useAtomValue, useSetAtom } from "jotai";
import {
  AnalysisOverrideInput,
  analysisOverrideInputFamily,
  ProdId,
} from "analysis/inputs";
import { financeOverrideInputFamily } from "finance/inputs";
import { FinanceId, FinanceOverrideInput } from "finance/types";
import { analysisConfigurationsFamily } from "state/jotai/analysisConfiguration";
import { windConfigurationsFamily } from "state/jotai/windConfiguration";
import { MaybePromise } from "types/utils";
import { maxNetPowerLimit } from "analysis/output";

export const currentParkTriggerId: FinanceId & ProdId =
  "current-park-finance" as FinanceId & ProdId;

export const TriggerCurrentFinance = ({
  projectId,
  branchId,
  parkId,
  children,
  fallback = null,
}: PropsWithChildren<{
  projectId: string;
  branchId: string;
  parkId: string;
  fallback: ReactNode | null;
}>) => {
  const setAnalysisArgs = useSetAtom(
    analysisOverrideInputFamily(currentParkTriggerId),
  );
  const setFinanceArgs = useSetAtom(
    financeOverrideInputFamily(currentParkTriggerId),
  );

  const [, raster, rasterId] = useBathymetry({
    projectId: projectId,
    branchId: branchId,
    featureId: parkId,
    bufferKm: undefined,
  });

  const argsJ = useDeep(
    useMemo(
      () =>
        raster
          ? {
              selectedSubAreas: undefined,
              projectId,
              branchId,
              parkId,
            }
          : undefined,
      [raster, branchId, parkId, projectId],
    ),
  );

  const financeArgsJ = useDeep(
    useMemo(
      () =>
        rasterId
          ? {
              rasterId,
            }
          : undefined,
      [rasterId],
    ),
  );

  useEffect(() => {
    if (!raster) {
      // If we don't have bathymetry yet we cannot set the finance input.
      // However, former inputs might still be set, for instance if you switch
      // parks. By resetting the atom we avoid having other components showing
      // data for the old arguments.
      setFinanceArgs(new Promise(() => {}));
      return;
    }

    if (argsJ) setAnalysisArgs(argsJ);
    if (financeArgsJ) setFinanceArgs(financeArgsJ);
  }, [argsJ, financeArgsJ, raster, setAnalysisArgs, setFinanceArgs]);

  return <Suspense fallback={fallback}>{children}</Suspense>;
};

export const TriggerDashboardFinanceAndProduction = ({
  costConfigurationId,
  analysisConfiguration,
  windConfiguration,
  operationsConfigurationId,
  parkId,
  projectId,
  branchId,
}: {
  costConfigurationId: string;
  analysisConfiguration: AnalysisConfiguration;
  windConfiguration: WindSourceConfiguration;
  operationsConfigurationId?: string;
  parkId: string;
  projectId: string;
  branchId: string;
}) => {
  const [, raster, rasterId] = useBathymetry({
    projectId: projectId,
    branchId: branchId,
    featureId: parkId,
    bufferKm: undefined,
  });

  const setAnalysisArgs = useSetAtom(
    analysisOverrideInputFamily(currentParkTriggerId),
  );
  const setFinanceArgs = useSetAtom(
    financeOverrideInputFamily(currentParkTriggerId),
  );

  const analysisArgs = useDeepMemo(
    () => ({
      parkId,
      branchId,
      configuration: analysisConfiguration,
      windConfiguration,
    }),
    [analysisConfiguration, branchId, parkId, windConfiguration],
  );

  const financeArgs = useDeepMemo(() => {
    if (!rasterId) return undefined;
    return {
      costConfigurationId,
      rasterId,
      operationsConfigurationId,
    };
  }, [rasterId, costConfigurationId, operationsConfigurationId]);

  useEffect(() => {
    if (!raster) {
      setFinanceArgs(new Promise(() => {})); // See comment in TriggerCurrentFinance
      return;
    }
    setAnalysisArgs(analysisArgs);
    if (financeArgs) setFinanceArgs(financeArgs);
  }, [analysisArgs, financeArgs, raster, setAnalysisArgs, setFinanceArgs]);

  return null;
};

export const TriggerCompareFinanceAndProduction = ({
  triggerId,
  selectedPark,
  projectId,
  children,
  fallback = null,
}: PropsWithChildren<{
  fallback: ReactNode | null;
  triggerId: ProdId & FinanceId;
  selectedPark: EnrichedSelectedParkCompare;
  projectId: string;
}>) => {
  const setAnalysisInput = useSetAtom(analysisOverrideInputFamily(triggerId));
  const setFinanceInput = useSetAtom(financeOverrideInputFamily(triggerId));

  const isCheckly = useAtomValue(loggedInUserIsCheckly);
  const analysisConfigurations = useAtomValue(
    analysisConfigurationsFamily({
      projectId,
    }),
  );
  const windConfigurations = useAtomValue(
    windConfigurationsFamily({
      projectId,
    }),
  );

  const productionInputs = useDeepMemo(() => {
    return {
      branchId: selectedPark.branchId,
      parkId: selectedPark.parkId,
      configuration: analysisConfigurations.get(
        selectedPark.selectedAnalysisConfigurationId ?? "",
      ),
      windConfiguration: windConfigurations.get(
        selectedPark.selectedWindConfigurationId ?? "",
      ),
      hubHeightOverride: selectedPark.selectedHubHeight,
      turbineTypeOverride: selectedPark.selectedTurbineType,
      exportCableTypeOverrideId: selectedPark.selectedExportCableType?.id,
      restart: isCheckly,
    } satisfies AnalysisOverrideInput;
  }, [
    analysisConfigurations,
    selectedPark.branchId,
    selectedPark.parkId,
    selectedPark.selectedAnalysisConfigurationId,
    selectedPark.selectedHubHeight,
    selectedPark.selectedTurbineType,
    selectedPark.selectedExportCableType,
    selectedPark.selectedWindConfigurationId,
    windConfigurations,
    isCheckly,
  ]);

  const setMaxPowerLimit = useSetAtom(maxNetPowerLimit(triggerId));
  useEffect(() => {
    if (selectedPark.selectedMaxPower) {
      setMaxPowerLimit(selectedPark.selectedMaxPower);
    } else {
      setMaxPowerLimit(undefined);
    }
  }, [selectedPark.selectedMaxPower, triggerId, setMaxPowerLimit]);

  useEffect(() => {
    setAnalysisInput(productionInputs);
  }, [productionInputs, setAnalysisInput, triggerId]);

  const [, , rasterId] = useBathymetry({
    projectId: projectId,
    branchId: selectedPark.branchId,
    featureId: selectedPark.parkId,
    bufferKm: undefined,
  });

  const financeInputs: MaybePromise<FinanceOverrideInput> = useDeepMemo(() => {
    if (!rasterId) return new Promise(() => {});

    return {
      costConfigurationId: selectedPark.selectedCostConfigurationId,
      rasterId,
      operationsConfigurationId: selectedPark.selectedOperationsConfigurationId,
    };
  }, [
    rasterId,
    selectedPark.selectedCostConfigurationId,
    selectedPark.selectedOperationsConfigurationId,
  ]);

  useEffect(() => {
    setFinanceInput(financeInputs);
  }, [financeInputs, setFinanceInput]);

  return <Suspense fallback={fallback}>{children}</Suspense>;
};
