import { useMemo } from "react";
import DropdownButton, {
  ButtonText,
  ButtonTextWrapper,
} from "../General/Dropdown/DropdownButton";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useAtomValue } from "jotai";
import VesselIcon from "@icons/24/Vessel.svg?react";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { operationsConfigurationsFamily } from "state/jotai/operationsConfiguration";

export const OperationsConfigurationSelector = ({
  configuration,
  setConfiguration,
}: {
  configuration?: OperationsConfiguration;
  setConfiguration: (cfg: OperationsConfiguration) => void;
}) => {
  const configurations = useAtomValue(
    operationsConfigurationsFamily({ projectId: undefined }),
  );

  const items = useMemo(() => {
    const items: DropDownItem[] = [];
    for (const cfg of configurations.values())
      items.push({
        value: cfg.id,
        name: cfg.name ?? "[no name]",
      });
    return items;
  }, [configurations]);

  const selected = items.find((i) => i.value === configuration?.id);

  return (
    <DropdownButton
      items={items}
      onSelectItem={(e) => {
        const cfg = configurations.get(e);
        if (!cfg) throw new Error("Configuration not found");
        setConfiguration(cfg);
      }}
      buttonText={selected?.name ?? "Select configuration"}
      className="dashboard-dropdown"
      selectedItemValue={selected?.value}
      renderText={(e) => (
        <ButtonTextWrapper>
          <VesselIcon />
          <ButtonText>{e}</ButtonText>
        </ButtonTextWrapper>
      )}
    />
  );
};
