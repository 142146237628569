import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import "nouislider/dist/nouislider.css";
import { depthFilterAtomFamily } from "../../state/filter";
import { RangeSlider } from "../General/Slider";
import { useAtom } from "jotai";

export const maxDepth = 500;

const DepthSlider = ({ disabled }: { disabled?: boolean }) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const [depth, setDepth] = useAtom(
    depthFilterAtomFamily({
      projectId,
    }),
  );
  return (
    <label>
      <RangeSlider
        labels
        min={0}
        step={5}
        max={maxDepth}
        values={depth as [number, number]}
        onChange={(range) =>
          setDepth(
            range.map((v) => (v >= maxDepth ? Number.MAX_SAFE_INTEGER : v)),
          )
        }
        inside
        disabled={disabled}
      />
    </label>
  );
};

export default DepthSlider;
