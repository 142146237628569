import { z } from "zod";
import {
  _OptimizeCostInput,
  _OptimizeMethod,
  _OptimizeObjective,
  _OptimizeRuntime,
  _WakeAnalysisConfiguration,
} from "../services/configurationService";
import { _OptimizeParameters, _TurbineType } from "../types/turbines";
import {
  fetchSchemaWithToken,
  fetchEnhancerWithToken,
} from "../services/utils";
import { Ids, Pids } from "../components/GenerateWindTurbines/state";
import { _RawWindRose } from "../state/jotai/windStatistics";
import { _WindSourceConfiguration } from "services/windSourceConfigurationService";
import { _Position } from "utils/geojson/geojson";

const _Turbine = z.object({
  lon: z.number(),
  lat: z.number(),
  type: z.string(),
  power: z.number().optional(),
});

const _RefLonLats = z.object({
  lon: z.number(),
  lat: z.number(),
});

const _OptProblemCost = z.object({
  foundationCostPerTon: z.number(),
  capexFixed: z.number(),
  devex: z.number(),
  decom: z.number(),
  opexPerYear: z.number(),
  lifetime: z.number(),
  discountRate: z.number(),
});

const _PostOptProblemRet = z.object({
  id: z.string(),
  createdAt: z.number(),
});
type PostOptProblemRet = z.infer<typeof _PostOptProblemRet>;

const _Constraint = z.object({
  type: z.string(),
  item: z.object({
    polygon: z
      .tuple([z.number(), z.number()])
      .array()
      .array()
      .describe("Polygon with holes"),
  }),
});

const _PostOptProblemArgs = z
  .object({
    polygons: _Position.array().array().array(),
    parkPolygon: z.tuple([z.number(), z.number()]).array(),
    constraints: z.array(_Constraint),
    windConfiguration: _WindSourceConfiguration.optional(),
    turbineId: z.string(),
    turbineTypes: z.record(z.string(), _TurbineType),
    wakeSettings: _WakeAnalysisConfiguration.optional(),
    neighbourTurbines: _Turbine.array().optional(),
    initialTurbines: _Turbine.array().optional(),
    useFlowersAep: z.boolean().default(false),
    refLonLat: _RefLonLats,
    method: _OptimizeMethod,
    runtime: _OptimizeRuntime,
    foundationWeightPerDepth: z
      .object({
        depth: z.number().array(),
        weight: z.number().array(),
      })
      .optional(),
  })
  .merge(_OptimizeParameters);

export type PostOptProblemArgs = z.infer<typeof _PostOptProblemArgs>;

export async function postOptProblem({
  ids: { nodeId, branchId, zoneId },
  args,
}: {
  ids: Ids;
  args: PostOptProblemArgs;
}): Promise<PostOptProblemRet> {
  return fetchSchemaWithToken(
    _PostOptProblemRet,
    `/api/octopus/optimize/${nodeId}/${branchId}/${zoneId}`,
    {
      method: "post",
      body: JSON.stringify(args),
      headers: { "Content-Type": "application/json" },
    },
  );
}

const _OptProblem = z.object({
  id: z.string(),
  version: z.string(),
  createdAt: z.number(),
  parkPolygon: z.tuple([z.number(), z.number()]).array(),
  polygons: _Position.array().array().array().nullish(),
  refLonLat: _RefLonLats,
  method: _OptimizeMethod.default("regular"),
  numberOfTurbines: z.number(),
  objective: _OptimizeObjective.default("aep"),
  costInput: _OptimizeCostInput.optional(),
  foundationTypeId: z.string().optional(),
  wakeSettings: _WakeAnalysisConfiguration.optional(),
  minNumberOfTurbines: z.number().optional().nullish(),
  turbineId: z.string(),
  minSpacing: z.number().optional(),
  windConfigurationId: z.string().optional().nullable(),
  runtime: _OptimizeRuntime.optional(),
});
export type OptProblem = z.infer<typeof _OptProblem>;

export async function getOptProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<OptProblem> {
  return fetchSchemaWithToken(
    _OptProblem,
    `/api/octopus/optimize/${nodeId}/${branchId}/${zoneId}/${id}`,
    {
      method: "get",
      headers: {},
    },
  );
}

export async function deleteOptProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<{ success: boolean; error?: string }> {
  try {
    await fetchEnhancerWithToken(
      `/api/octopus/optimize/${nodeId}/${branchId}/${zoneId}/${id}`,
      {
        method: "delete",
        headers: {},
      },
    );
    return { success: true };
  } catch (error) {
    return { success: false, error: "Failed to delete optimisation problem" };
  }
}

const _ListOptProblems = z
  .object({
    id: z.string(),
    createdAt: z.number(),
    numberOfTurbines: z.number(),
  })
  .array();
type ListOptProblems = z.infer<typeof _ListOptProblems>;

export async function listOptProblems({
  nodeId,
  branchId,
  zoneId,
}: Ids): Promise<ListOptProblems> {
  return fetchSchemaWithToken(
    _ListOptProblems,
    `/api/octopus/optimize/${nodeId}/${branchId}/${zoneId}`,
    {
      method: "get",
      headers: {},
    },
  );
}

const _RunningOptResult = z.object({
  task_status: z.literal("running"),
  version: z.string(),
  createdAt: z.number(),
});
export type RunningOptResult = z.infer<typeof _RunningOptResult>;

const _FailedOptResult = z.object({
  task_status: z.literal("failed"),
  version: z.string(),
  createdAt: z.number(),
});
export type FailedOptResult = z.infer<typeof _FailedOptResult>;

const _InvalidOptResult = z.object({
  task_status: z.literal("invalid"),
  version: z.string(),
  createdAt: z.number(),
});
export type InvalidOptResult = z.infer<typeof _InvalidOptResult>;

const _CompletedOptResult = z.object({
  version: z.string(),
  createdAt: z.number(),
  task_status: z.literal("completed"),
  aep: z.number(),
  grossAep: z.array(z.array(z.number())).nullish(),
  netAep: z.array(z.array(z.number())).nullish(),
  lcoe: z.number().nullish(),
  foundationTypeId: z.string().nullish(),
  turbines: _Turbine.array().optional(),
});
export type CompletedOptItem = z.infer<typeof _CompletedOptResult>;

const _OptResult = z.discriminatedUnion("task_status", [
  _RunningOptResult,
  _FailedOptResult,
  _InvalidOptResult,
  _CompletedOptResult,
]);
export type OptResult = z.infer<typeof _OptResult>;

const _ListResultsForProblem = z.object({
  id: z.string(),
  items: _OptResult.array(),
});
export type ListResultsForProblem = z.infer<typeof _ListResultsForProblem>;

export async function listResultsForProblem({
  nodeId,
  branchId,
  zoneId,
  id,
}: Pids): Promise<ListResultsForProblem> {
  return fetchSchemaWithToken(
    _ListResultsForProblem,
    `/api/octopus/optimize/${nodeId}/${branchId}/${zoneId}/${id}/results`,
    {
      method: "get",
      headers: {},
    },
  );
}
