import { turbineOverlapWarningFamily } from "components/ValidationWarnings/TurbineOverlap";
import { offshoreSubstationOutsideParkCableCorridorWarningFamily } from "components/ValidationWarnings/SubstationOutsideParkCableCorridor";
import { turbineOutsideParkWarningFamily } from "components/ValidationWarnings/TurbineOutsidePark";
import { zoneOverlapWarningFamily } from "components/ValidationWarnings/OverlappingZones";
import { differentExportVoltageTypesFamily } from "components/ValidationWarnings/DifferentExportVoltageTypes";
import { differentIAVoltageTypesFamily } from "components/ValidationWarnings/DifferentIAVoltageTypes";
import { turbineCableWrongVoltageTypeFamily } from "components/ValidationWarnings/TurbineCableWrongVoltageType";
import { turbinesNotConnectedFamily } from "components/ValidationWarnings/TurbineNotConnected";
import { foundationTypeInvalidWarningFamily } from "components/ValidationWarnings/FoundationTypeInvalid";
import { cableTypeInvalidWarningFamily } from "components/ValidationWarnings/CableTypeInvalid";
import { turbineTypeInvalidWarningFamily } from "components/ValidationWarnings/TurbineTypeInvalid";
import { overlappingParksWarningFamily } from "components/ValidationWarnings/OverlappingParks";
import { substationOverlapWarningFamily } from "components/ValidationWarnings/SubstationOverlap";
import { tooShortCablesWarningFamily } from "components/ValidationWarnings/TooShortCables";
import { substationNotConnectedWarningFamily } from "components/ValidationWarnings/SubstationNotConnected";
import { exportCableNotConnectedWarningFamily } from "components/ValidationWarnings/ExportCableNotConnected";
import { maxSizeExceededWarningFamily } from "components/ValidationWarnings/MaxSizeExceeded";
import { exportCableLandFallFailedWarningFamily } from "components/ValidationWarnings/ExportCableLandfallFailed";
import { cableCorridorNotConnectedToParkFamily } from "components/ValidationWarnings/CableCorridorNotConnectedToPark";
import { subAreaOutsideParkWarningFamily } from "components/ValidationWarnings/SubAreasOutsidePark";
import { turbineAndMonopileFamily } from "components/ValidationWarnings/TurbineAndMonopileTypeError";
import { substationInsideNoCableExclusionZoneWarningFamily } from "components/ValidationWarnings/SubstationInsideNoCableExclusionZone";
import {
  cablesInsideNoCablesExclusionZoneWarningFamily,
  substationInExclusionZoneFamily,
  turbinesInExclusionZoneFamily,
} from "components/ValidationWarnings/ExclusionZoneContainingExcludedFeature";
import { holeInParkWarningFamily } from "components/ValidationWarnings/HoleInPark";
import { ValidationWarningTypes } from "components/ValidationWarnings/utils";
import { cableIntersectsSectorFamily } from "components/ValidationWarnings/CableIntersectsSector";
import { cableLoopsFamily } from "components/ValidationWarnings/CableLoops";
import { existingTurbineOverlapWarningFamily } from "components/ValidationWarnings/ExistingTurbineOverlap";
import { atomFamily as AF } from "utils/jotai";
import { Atom, atom } from "jotai";
import { branchIdAtom, organisationIdAtom, projectIdAtom } from "./pathParams";
import { isDefined } from "utils/predicates";
import { unwrap } from "jotai/utils";
import {
  validationFatalAblyLost,
  validationFatalGeneral,
} from "components/ValidationWarnings/manualWarnings";
import { parksFamily } from "./jotai/park";
import { anchorsOutsideParkFamily } from "components/ValidationWarnings/AnchorOutsidePark";
import { exportSystemCapacityWarningFamily } from "components/ValidationWarnings/ExportSystemInsufficientCapacity";
import { interArrayCapacityWarningFamily } from "components/ValidationWarnings/InterArrayInsufficientCapacity";
import { exportCableTooLongWarningErrorFamily } from "components/ValidationWarnings/ExportCableTooLongWarningError";
import { substationMissingTypeWarningFamily } from "components/ValidationWarnings/SubstationMissingType";
import { existingTurbinesWithoutPowerWarningAtom } from "components/ValidationWarnings/ExistingTurbinesWithoutPower";
import { SplitAtLandfallPointErr } from "./jotai/landfall";
import { differentExportSystemTypesFamily } from "components/ValidationWarnings/DifferentExportSystemTypes";
import { differentExportSystemTypesInCableSelectorFamily } from "components/ValidationWarnings/DifferentExportSystemTypesInCable";
import { exportSystemWillNotConvergeFamily } from "components/ValidationWarnings/ExportSystemWillNotConverge";
import { cableNotConnectedWarningFamily } from "components/ValidationWarnings/CableNotConnected";
import { selfIntersectingPolygonsWarningFamily } from "components/ValidationWarnings/SelfIntersectingPolygon";
import { multiPolygonParksWarningFamily } from "components/ValidationWarnings/MultiPolygonParks";
import { substationTypeInvalidWarningFamily } from "components/ValidationWarnings/SubstationTypeInvalid";
import { invalidFeaturesWarningFamily } from "components/ValidationWarnings/InvalidFeatures";

export type ValidationWarningOrganisationStateType = {
  type: ValidationWarningTypes.FatalAblyLost;
  retryTimestamp?: number;
};

/** Convenience type to specify the {@link ValidationWarningStateType} of a particular `type`. */
export type ValidationWarning<T extends ValidationWarningTypes> =
  ValidationWarningStateType & { type: T };

export type ValidationWarningStateType =
  | { type: ValidationWarningTypes.ProjectSize }
  | {
      type: ValidationWarningTypes.TurbineOverlap;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SelfIntersectingPolygon;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.InvalidFeatures;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.ExistingTurbineOverlap;
      featureIds: string[];
    }
  | { type: ValidationWarningTypes.CableTypeInvalid; featureIds: string[] }
  | { type: ValidationWarningTypes.SubstationTypeInvalid; featureIds: string[] }
  | { type: ValidationWarningTypes.TurbineTypeInvalid; featureIds: string[] }
  | { type: ValidationWarningTypes.FoundationTypeInvalid; featureIds: string[] }
  | {
      type: ValidationWarningTypes.ParkMultiPolygons;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.TurbineOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbinesNotReachable;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationInsideNoCableExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableInsideNoCableExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineInsideNoTurbineExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationOverlap;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationInsideNoSubstationExclusionZone;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableCorridorNotConnectedToPark;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.OverlappingSubAreas;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubAreasOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.OverlappingParks;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.HoleInPark;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.CablesDifferentIAVoltageTypes;
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableDifferentExportVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbinesAndCablesErroneousIAVoltageTypes;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.SubstationMissingType;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.OffshoreSubstationOutsideParkCableCorridor;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableNotConnected;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableTooLongWarning;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableTooLongError;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TooShortCables;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.TurbineAndMonopileTypeError;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CablesCross;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableLandfallFailed;
      data: {
        exportCableId: string;
        error: SplitAtLandfallPointErr;
      }[];
      parkId: string;
    }
  | { type: ValidationWarningTypes.FatalGeneral }
  | {
      type: ValidationWarningTypes.AnchorsOutsidePark;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExistingTurbinesWithoutPower;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.ExportSystemInsufficientCapacity;
      detailsText: string;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.InterArrayInsufficientCapacity;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.CableIntersectsSector;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.CableLoops;
      featureIds: string[];
    }
  | {
      type: ValidationWarningTypes.ExportCableDifferentTypes;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportCableDifferentTypesInCable;
      featureIds: string[];
      parkId: string;
    }
  | {
      type: ValidationWarningTypes.ExportSystemWillNotConverge;
      featureIds: string[];
      parkId: string;
    };

const validationWarningsOrgFamily = AF(
  (input: { organisationId: string | undefined }) =>
    atom<ValidationWarningOrganisationStateType[]>((get) => {
      const organisationId = input.organisationId ?? get(organisationIdAtom);
      if (!organisationId) return [];
      const ret: ValidationWarningOrganisationStateType[] = [];
      const ably = get(validationFatalAblyLost);
      if (ably) ret.push(ably);
      return [];
    }),
);

const validationWarningsProjectFamily = AF(
  (input: { projectId: string | undefined }) =>
    atom<ValidationWarningStateType[]>((get) => {
      const projectId = input.projectId ?? get(projectIdAtom);
      const ret: ValidationWarningStateType[] = [];
      if (!projectId) return ret;
      const gen = get(validationFatalGeneral);
      if (gen) ret.push(gen);
      return ret;
    }),
);

const validationWarningsBranchFamily = AF(
  (input: { branchId: string | undefined }) =>
    atom<ValidationWarningStateType[]>((get) => {
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId) return [];

      const ids = { branchId };
      const atoms: Atom<ValidationWarningStateType | undefined>[] = [
        // async warnings: unwrap, so loading = no warning
        unwrap(overlappingParksWarningFamily(ids)),
        unwrap(multiPolygonParksWarningFamily(ids)),
        unwrap(turbineTypeInvalidWarningFamily(ids)),
        unwrap(maxSizeExceededWarningFamily(ids)),
        unwrap(existingTurbinesWithoutPowerWarningAtom),
        unwrap(existingTurbineOverlapWarningFamily(ids)),
        unwrap(selfIntersectingPolygonsWarningFamily(ids)),
        unwrap(invalidFeaturesWarningFamily(ids)),
        unwrap(foundationTypeInvalidWarningFamily(ids)),
        unwrap(cableTypeInvalidWarningFamily(ids)),
        unwrap(substationTypeInvalidWarningFamily(ids)),
      ];

      return atoms.map((a) => get(a)).filter(isDefined);
    }),
);

const validationWarningsParkFamily = AF(
  (input: { parkId: string; branchId: string | undefined }) =>
    atom<ValidationWarningStateType[]>((get) => {
      const branchId = input.branchId ?? get(branchIdAtom);
      if (!branchId) return [];
      const parkId = input.parkId;
      const ids = { parkId, branchId };

      const atoms: Atom<ValidationWarningStateType | undefined>[] = [
        // async warnings: unwrap, so loading = no warning
        unwrap(cableIntersectsSectorFamily(ids)),
        unwrap(cableLoopsFamily(ids)),
        unwrap(anchorsOutsideParkFamily(ids)),
        unwrap(cableCorridorNotConnectedToParkFamily(ids)),
        unwrap(turbinesNotConnectedFamily(ids)),
        unwrap(cablesInsideNoCablesExclusionZoneWarningFamily(ids)),
        unwrap(substationInExclusionZoneFamily(ids)),
        unwrap(turbinesInExclusionZoneFamily(ids)),
        unwrap(differentExportVoltageTypesFamily(ids)),
        unwrap(differentIAVoltageTypesFamily(ids)),
        unwrap(exportCableLandFallFailedWarningFamily(ids)),
        unwrap(exportCableNotConnectedWarningFamily(ids)),
        unwrap(exportSystemCapacityWarningFamily(ids)),
        unwrap(interArrayCapacityWarningFamily(ids)),
        unwrap(turbineAndMonopileFamily(ids)),
        unwrap(turbineCableWrongVoltageTypeFamily(ids)),
        unwrap(tooShortCablesWarningFamily(ids)),
        unwrap(turbineOutsideParkWarningFamily(ids)),
        unwrap(turbineOverlapWarningFamily(ids)),
        unwrap(offshoreSubstationOutsideParkCableCorridorWarningFamily(ids)),
        unwrap(substationNotConnectedWarningFamily(ids)),
        unwrap(cableNotConnectedWarningFamily(ids)),
        unwrap(exportCableTooLongWarningErrorFamily(ids)),
        unwrap(holeInParkWarningFamily(ids)),
        unwrap(subAreaOutsideParkWarningFamily(ids)),
        unwrap(substationInsideNoCableExclusionZoneWarningFamily(ids)),
        unwrap(substationMissingTypeWarningFamily(ids)),
        unwrap(substationOverlapWarningFamily(ids)),
        unwrap(zoneOverlapWarningFamily(ids)),
        unwrap(differentExportSystemTypesFamily(ids)),
        unwrap(differentExportSystemTypesInCableSelectorFamily(ids)),
        unwrap(exportSystemWillNotConvergeFamily(ids)),
      ];

      return atoms.map((a) => get(a)).filter(isDefined);
    }),
);

/**
 * All warnings in the context of a single park.
 * Kinda dumb, look into removing this (because we always show warnings for all parks).
 */
export const validationWarningsAllForParkFamily = AF(
  ({
    parkId,
    branchId: _branchId,
  }: {
    parkId: string;
    branchId: string | undefined;
  }) =>
    atom((get) => {
      const organisationId = get(organisationIdAtom);
      const projectId = get(projectIdAtom);
      const branchId = _branchId ?? get(branchIdAtom);
      let allWarnings: (
        | ValidationWarningStateType
        | ValidationWarningOrganisationStateType
      )[] = [];
      allWarnings = allWarnings.concat(
        get(validationWarningsOrgFamily({ organisationId })),
      );
      if (projectId) {
        allWarnings = allWarnings.concat(
          get(validationWarningsProjectFamily({ projectId })),
        );
        if (branchId) {
          allWarnings = allWarnings.concat(
            get(validationWarningsBranchFamily({ branchId })),
          );
          if (parkId)
            allWarnings = allWarnings.concat(
              get(validationWarningsParkFamily({ parkId, branchId })),
            );
        }
      }

      return allWarnings;
    }),
);

/**
 * All warnings for all parks.
 */
export const validationWarningsAllAtom = atom(async (get) => {
  const organisationId = get(organisationIdAtom);
  const projectId = get(projectIdAtom);
  const branchId = get(branchIdAtom);
  const parks = await get(parksFamily({ branchId }));

  let allWarnings: (
    | ValidationWarningStateType
    | ValidationWarningOrganisationStateType
  )[] = [];
  allWarnings = allWarnings.concat(
    get(validationWarningsOrgFamily({ organisationId })),
  );
  if (projectId) {
    allWarnings = allWarnings.concat(
      get(validationWarningsProjectFamily({ projectId })),
    );
    if (branchId) {
      allWarnings = allWarnings.concat(
        get(validationWarningsBranchFamily({ branchId })),
      );
      for (const park of parks) {
        const ws = get(
          validationWarningsParkFamily({ parkId: park.id, branchId }),
        );
        allWarnings = allWarnings.concat(ws);
      }
    }
  }

  return allWarnings;
});
