import { labelFromColorKey, labelsFromFeature } from "business/style/constants";
import { styleSelectorFamily } from "business/style/state";
import { _ParkLabel } from "business/style/feature/park";
import { _AnchorLabel } from "business/style/feature/anchor";
import { _CableLabel } from "business/style/feature/cable";
import { _TurbineLabel } from "business/style/feature/turbine";
import Dropdown from "components/Dropdown/Dropdown";
import { useAtom } from "jotai";
import { isNever } from "utils/utils";

export const LabelDropdown = ({
  styleId,
  disabled,
}: {
  styleId: string;
  disabled?: boolean;
}) => {
  const [style, setStyle] = useAtom(styleSelectorFamily(styleId));

  if (!style) return null;
  return (
    <Dropdown
      small
      disabled={disabled}
      value={style.label ?? "None"}
      onChange={(e) => {
        const v = e.target.value;
        if (style.feature === "turbines")
          setStyle({ ...style, label: _TurbineLabel.parse(v) });
        else if (style.feature === "anchors")
          setStyle({ ...style, label: _AnchorLabel.parse(v) });
        else if (style.feature === "cables")
          setStyle({ ...style, label: _CableLabel.parse(v) });
        else if (style.feature === "parks")
          setStyle({ ...style, label: _ParkLabel.parse(v) });
        else throw isNever(style);
      }}
    >
      {labelsFromFeature[style.feature].map((l) => (
        <option key={l} value={l}>
          {labelFromColorKey[l]}
        </option>
      ))}
    </Dropdown>
  );
};
