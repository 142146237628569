import * as turf from "@turf/turf";
import { useMemo } from "react";
import {
  TurbineFeatureWithFoundation,
  isTurbineFeatureWithFoundation,
} from "../../../../state/foundations";
import { TextSemi } from "../../../../styles/typography";
import { branchIdAtom } from "../../../../state/pathParams";
import { ParkFeature, TurbineFeature } from "../../../../types/feature";
import { Row } from "../InfoModal.style";
import { FixedFoundationGeneration } from "./fixed/FixedFoundationGeneration";
import { FloatingFoundationGeneration } from "./floating/FloatingFoundationGeneration";
import { pointInPolygon } from "utils/geometry";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { spacing3 } from "styles/space";
import { sum } from "utils/utils";
import { SubAreaTag } from "components/SiteLocator/Tags";
import { useAtomValue } from "jotai";
import { selectedAreaAtom, selectedTurbinesAtom } from "state/jotai/selection";
import { foundationTypesAtom } from "state/jotai/foundation";
import { isFixed, isFloater } from "utils/predicates";
import { invalidTypesInParkFamily } from "components/ValidationWarnings/InvalidTypes";
import { Tag } from "components/General/Tag";

const FoundationTab = ({ park }: { park: ParkFeature }) => {
  const branchId = useAtomValue(branchIdAtom) ?? "";

  const areaFeatures = useAtomValue(selectedAreaAtom) ?? park;
  const selectedArea = useMemo(() => {
    const area = Array.isArray(areaFeatures)
      ? sum(areaFeatures, (f) => turf.area(f))
      : turf.area(areaFeatures);
    return Math.round(area / 1e6);
  }, [areaFeatures]);

  const turbines = useAtomValue(selectedTurbinesAtom);
  const foundations = useAtomValue(foundationTypesAtom);
  const { fixed, floating, nothing } = useMemo(() => {
    const fixed: TurbineFeatureWithFoundation[] = [];
    const floating: TurbineFeatureWithFoundation[] = [];
    const nothing: TurbineFeature[] = [];

    for (const t of turbines) {
      if (!pointInPolygon(t.geometry, park.geometry)) continue;
      if (!isTurbineFeatureWithFoundation(t)) {
        nothing.push(t);
        continue;
      }
      const f = foundations.get(t.properties.foundationId);
      if (!f) nothing.push(t);
      else if (isFixed(f)) fixed.push(t);
      else if (isFloater(f)) floating.push(t);
      else nothing.push(t);
    }

    return { fixed, floating, nothing };
  }, [turbines, park.geometry, foundations]);

  const invalidTypes = useAtomValue(
    invalidTypesInParkFamily({ parkId: park.id, branchId }),
  );

  if (fixed.length === 0 && floating.length === 0)
    return (
      <div>
        <SimpleAlert
          text={"No foundations in the park."}
          type={"error"}
        ></SimpleAlert>
      </div>
    );

  if (invalidTypes.turbines)
    return (
      <SimpleAlert
        text={"Some turbines in the park have invalid turbine types."}
      />
    );
  if (invalidTypes.foundations)
    return (
      <SimpleAlert
        text={"Some turbines in the park have invalid foundation types."}
      />
    );

  if (0 < fixed.length && 0 < floating.length) {
    return (
      <TextSemi>
        Please select a park or zone with either only fixed or only floating
        foundations to see statistics.
      </TextSemi>
    );
  }
  return (
    <>
      <Row
        style={{
          justifyContent: "left",
          flexWrap: "wrap",
        }}
      >
        {Array.isArray(areaFeatures) ? (
          <SubAreaTag tooltip="These analysis numbers are only for the selected zone." />
        ) : (
          <Tag text="Park" />
        )}
        <Tag text={`${selectedArea} km²`} />
      </Row>
      {nothing.length > 0 && (
        <div style={{ padding: `${spacing3} 0` }}>
          <SimpleAlert
            text={`${nothing.length} turbine(s) are without foundation.`}
            type="info"
          />
        </div>
      )}
      {floating.length > 0 && (
        <FloatingFoundationGeneration tempLayoutFoundations={floating} />
      )}
      {fixed.length > 0 && (
        <FixedFoundationGeneration tempLayoutFoundations={fixed} park={park} />
      )}
    </>
  );
};

export default FoundationTab;
