import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { colors } from "styles/colors";
import Button from "components/General/Button";
import { Column, ModalFrame, Row } from "components/General/Layout";
import { spacing8 } from "styles/space";
import Spinner from "@icons/spinner/Spinner";
import { typography } from "styles/typography";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import Dropdown from "components/Dropdown/Dropdown";
import { _UserAccessRole, ProjectType, UserAccessRole } from "types/user";
import useUserNodeAccessCrud from "components/Organisation/Members/useUserNodeAccessCrud";
import { loggedInUserAtom } from "state/user";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { organisationIdAtom } from "state/pathParams";
import { useToast } from "hooks/useToast";
import { scream } from "utils/sentry";

const Subtext = styled.p`
  ${typography.caption};
  color: ${colors.textSecondary};
  margin-bottom: 1.2rem;
`;

const PortfolioAccessNeededModal = ({
  onClose,
  projectId,
  branchId,
  parkId,
  projectType,
}: {
  onClose: () => void;
  projectId: string;
  branchId: string;
  parkId: string;
  projectType?: ProjectType;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const { addOrUpdate } = useUserNodeAccessCrud();
  const [modalElem, setModalElem] = useState<HTMLDivElement | null>(null);
  const inputElem = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<UserAccessRole>("viewer");
  const { error } = useToast();
  const navigate = useNavigate();
  const user = useAtomValue(loggedInUserAtom);
  useEffect(() => {
    const newElement = document.createElement("div");
    document.body.append(newElement);
    setModalElem(newElement);
    return () => {
      newElement.remove();
    };
  }, []);

  useEffect(() => {
    // Hack needed to wait for next frame to select the input
    setTimeout(() => {
      inputElem.current?.select();
    }, 0);
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        onClose();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClose]);

  const handleGiveAccess = useCallback(async () => {
    if (!user?.user_id) return;
    try {
      await addOrUpdate(user.user_id, projectId, selectedRole);
      navigate(
        `/${projectType === "onshore" ? "onshore" : "design"}/project/${organisationId}/${projectId}/${branchId}/${parkId}`,
      );
    } catch (e) {
      error(
        "Something went wrong while giving you access, please get in touch with support if the issue persists",
      );
      scream("Error when trying to give access via portfolio modal", {
        error: e,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    addOrUpdate,
    projectId,
    selectedRole,
    user,
    setIsLoading,
    navigate,
    organisationId,
    branchId,
    parkId,
    error,
    projectType,
  ]);

  if (!modalElem) {
    return null;
  }

  return ReactDOM.createPortal(
    <FullScreenModal
      placeOnTopOfOtherModals={true}
      onEscape={onClose}
      closeOnWrapperClick={true}
      onClick={onClose}
    >
      <ModalFrame
        title={"Access needed"}
        style={{
          width: "40rem",
        }}
      >
        <Subtext>
          Your account does not have explicit access to this project, in order
          to proceed you must give yourself access.
        </Subtext>
        <Column
          style={{
            gap: spacing8,
          }}
        >
          <Dropdown
            value={selectedRole}
            onChange={async (e) => {
              const newRole = _UserAccessRole.parse(e.target.value);
              setSelectedRole(newRole);
            }}
          >
            <option value={"admin"}>Admin</option>
            <option value={"editor"}>Editor</option>
            <option value={"viewer"}>Viewer</option>
          </Dropdown>
          <SimpleAlert
            type="info"
            text={
              "You can give yourself access since you are an admin in the organisation."
            }
          />
          <Row
            style={{
              justifyContent: "flex-end",
              gap: "1.2rem",
            }}
          >
            <Button text="Cancel" buttonType="secondary" onClick={onClose} />
            <Button
              ref={submitButtonRef}
              text={"Give access and enter project"}
              disabled={isLoading}
              icon={isLoading ? <Spinner size="1rem" /> : undefined}
              onClick={handleGiveAccess}
            />
          </Row>
        </Column>
      </ModalFrame>
    </FullScreenModal>,
    modalElem,
  );
};

export default PortfolioAccessNeededModal;
