import { unwrap } from "jotai/utils";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import { useRef, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { currentMapStyleIdAtom } from "../../../../state/map";
import { colors } from "../../../../styles/colors";
import { ParkFeature } from "../../../../types/feature";
import { getBBOXArrayFromFeatures } from "../../../../utils/geojson/validate";
import MapLegend, { LegendItem } from "../MapLegend";
import { isPrintingAtom } from "../../state";
import { SafeCard } from "../Base";
import { useDashboardContext } from "../../Dashboard";
import { MapboxMoveLogoToUpperLeft } from "../Base.style";
import { RenderParks } from "components/Mapbox/Parks";
import { RenderTurbines } from "components/Mapbox/Turbines";
import { overrideUseDefaultStylesAtom } from "business/style/state";
import { RenderOtherPoints } from "components/Mapbox/OtherPoints";
import { getClosestWaveDataSelector } from "state/waveStatistics";
import { useAtomValue, useSetAtom } from "jotai";
import { getAnalysisWindStats } from "analysis/output";
import { turbinesInParkFamily } from "state/jotai/turbine";

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

const Map = ({ map, park }: { map: mapboxgl.Map; park: ParkFeature }) => {
  const { triggerId } = useDashboardContext();
  const windStats = useAtomValue(getAnalysisWindStats(triggerId));
  const waveData = useAtomValue(
    unwrap(
      getClosestWaveDataSelector({
        lon: windStats?.longitude,
        lat: windStats?.latitude,
        source: undefined,
      }),
    ),
  );
  const parkId = park.id;
  const turbines = useAtomValue(
    turbinesInParkFamily({
      parkId,
      branchId: undefined,
    }),
  );
  const parks = useMemo(() => [park], [park]);

  const setOverride = useSetAtom(overrideUseDefaultStylesAtom);
  useEffect(() => {
    setOverride(true);
    return () => {
      setOverride(false);
    };
  }, [setOverride]);

  return (
    <>
      <RenderParks map={map} parks={parks} />
      <RenderTurbines map={map} turbines={turbines} />
      {windStats && waveData && (
        <RenderOtherPoints
          map={map}
          features={[
            {
              type: "Feature",
              id: "wind-data-point",
              geometry: {
                coordinates: [windStats.longitude, windStats.latitude],
                type: "Point",
              },
              properties: {
                color: colors.red700,
                vind_hidden_property_opacity: 1,
              },
            },
            {
              type: "Feature",
              id: "wave-data-point",
              geometry: {
                coordinates: [waveData.longitude, waveData.latitude],
                type: "Point",
              },
              properties: {
                color: colors.green400,
                vind_hidden_property_opacity: 1,
              },
            },
          ]}
        />
      )}
    </>
  );
};

const legendItems: LegendItem[] = [
  {
    name: "Turbine",
    style: {
      backgroundColor: colors.turbine,
    },
  },
  {
    name: "Wind data",
    style: {
      backgroundColor: colors.red700,
    },
  },
  {
    name: "Wave data",
    style: {
      backgroundColor: colors.green400,
    },
  },
];

const ProjectMetoceanMap = () => {
  const { park } = useDashboardContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map | null>(null);
  const isPrinting = useAtomValue(isPrintingAtom);
  const activeMapStyleId = useAtomValue(currentMapStyleIdAtom);

  const [_boundsCount, refreshBounds] = useState(0);
  const bounds: LngLatBoundsLike = useMemo(() => {
    const bbox = getBBOXArrayFromFeatures([park]);
    const buffer = 0.1;
    return [
      [bbox[0] - buffer, bbox[1] - buffer],
      [bbox[2] + buffer, bbox[3] + buffer],
    ];
  }, [park]);

  useEffect(() => {
    if (map || !mapContainer.current) return;
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: activeMapStyleId,
      bounds,
      logoPosition: "top-right",
      preserveDrawingBuffer: true,
    });
    setMap(newMap);
    newMap.on("load", () => {
      newMap.resize();
      setMapLoaded(newMap);
    });
  }, [map, mapContainer, bounds, setMapLoaded, activeMapStyleId]);

  useEffect(() => {
    if (!bounds || !map) return;
    map.fitBounds(bounds, {
      padding: 50,
      animate: false,
      maxZoom: 9,
    });
  }, [bounds, map]);

  useEffect(() => {
    if (!map) return;
    const observer = new ResizeObserver(() => {
      refreshBounds((c) => c + 1);
      setTimeout(() => map.resize(), 0);
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => observer.disconnect();
  }, [map]);

  const [mapImageUrl, setImageUrl] = useState<string | undefined>(
    map ? map.getCanvas().toDataURL() : undefined,
  );

  useEffect(() => {
    if (!isPrinting || !map) return;
    setImageUrl(map.getCanvas().toDataURL());
    return () => {
      setImageUrl(undefined);
    };
  }, [isPrinting, map, setImageUrl]);

  return (
    <div
      ref={boxRef}
      style={{
        position: "relative",
        flex: 1,
        display: "flex",
      }}
    >
      <MapboxMoveLogoToUpperLeft
        ref={mapContainer}
        style={{
          borderRadius: "5px",
        }}
      />
      {!isPrinting && mapLoaded && <Map map={mapLoaded} park={park} />}
      {isPrinting && mapImageUrl && <Image alt={"Park"} src={mapImageUrl} />}
      <MapLegend items={legendItems} />
    </div>
  );
};

export const MetoceanMapWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Metocean map"
      id="Metocean map"
      fullscreen
      resetKeys={errorBoundaryResetKeys}
    >
      <ProjectMetoceanMap />
    </SafeCard>
  );
};
