import React, { useCallback, useRef, ReactNode } from "react";
import { useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import FoundationIcon from "@icons/24/Foundation.svg";
import WindTurbineIcon from "@icons/24/WindTurbine.svg";
import { Column } from "components/General/Layout";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { modalTypeOpenAtom } from "state/modal";
import { useHorizontalResize } from "components/ResizeBar/ResizeBarVertical";
import AddLayersFromAllDataListModal from "../../dataLibrary/modals/AddLayersFromAllDataListModal";
import UploadOrganisationLayerFromCoordinatesModal from "../../dataLibrary/modals/UploadOrganisationLayerFromCoordinatesModal";
import UploadOrganisationLayerModal from "../../dataLibrary/modals/UploadOrganisationLayerModal";
import ExportCableIcon from "@icons/24/ExportCable.svg";
import CablingIcon from "@icons/24/Cabling-2.svg";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { ProcurementCostComponentType } from "services/procurementCostService";
import { colors } from "styles/colors";
import { activeProcurementCostIdAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { orgFinanicalManageAccessSelector } from "state/user";
import { scream } from "utils/sentry";
import { MainRow, ResourceColumn, TabContainer } from "../../style";
import ProcurementCostItems from "./ProcurementCostItems";
import ProcurementCostsContent from "./ProcurementCostsContent";
import { spacing5 } from "styles/space";

export const ProcurementModalType = "ProcurementModal" as const;

type ProcurementCostItem = {
  id: ProcurementCostComponentType;
  title: string;
  description: string;
  icon: ReactNode;
};

export const PROCUREMENT_COST_ITEMS: ProcurementCostItem[] = [
  {
    id: "turbine",
    title: "Turbine costs",
    description: "Details about wind turbine procurement costs",
    icon: <WindTurbineIcon />,
  },
  {
    id: "interArrayCable",
    title: "Inter array cabling costs",
    description: "Details about inter-array cabling procurement costs",
    icon: <CablingIcon />,
  },
  {
    id: "foundation",
    title: "Foundation costs",
    description: "Details about foundation procurement costs",
    icon: <FoundationIcon />,
  },
  {
    id: "exportCable",
    title: "Export cable costs",
    description: "Details about export cable procurement costs",
    icon: <ExportCableIcon />,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const RightSideModalContainer = styled.div`
  width: 100%;
  padding: 3.6rem 2.4rem 2.4rem;
  border-left: 1px solid ${colors.dropdownBorder};

  transition: transform 0.5s;
  position: relative;
  display: flex;
`;

const LeftSideContainer = styled.div`
  padding: 2.4rem 2.4rem 0;
`;

const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing5};
  margin-right: ${spacing5};
  height: 100%;
`;

function ProcurementCostsModalInner({ onClose }: { onClose(): void }) {
  const elem = useRef<HTMLDivElement>(null);
  useHorizontalResize(elem, "--data-library-resource-list-width");

  const orgFinancialManageAccess = useAtomValue(
    orgFinanicalManageAccessSelector,
  );

  const [activeComponentId, setActiveComponentId] = useAtom(
    activeProcurementCostIdAtom,
  );

  const onClick = (id: ProcurementCostComponentType) => {
    setActiveComponentId(id);
  };

  if (!orgFinancialManageAccess) {
    scream(
      new Error(
        "User does not have financial manage access but still tries to access procurement costs modal",
      ),
      {
        activeComponentId,
      },
    );
    onClose();
    return null;
  }

  return (
    <TopBarModal>
      <TopBarModalHeader
        title="Procurement table"
        onClose={onClose}
        rightSide={
          <HeaderRightSide>
            <div
              style={{
                width: "1px",
                height: "2rem",
                backgroundColor: colors.borderDefault,
              }}
            />
          </HeaderRightSide>
        }
      />

      <Container>
        <LeftSideContainer>
          <UploadOrganisationLayerModal />
          <UploadOrganisationLayerFromCoordinatesModal />
          <AddLayersFromAllDataListModal />
          <TabContainer>
            <MainRow>
              <ResourceColumn>
                <React.Suspense
                  fallback={
                    <>
                      <SkeletonBlock style={{ height: "4rem" }} />
                      <SkeletonBlock style={{ height: "4rem" }} />
                      <SkeletonBlock style={{ height: "4rem" }} />
                      <SkeletonBlock style={{ height: "4rem" }} />
                    </>
                  }
                >
                  <ProcurementCostItems
                    activeComponentId={activeComponentId}
                    onClick={onClick}
                  />
                </React.Suspense>
              </ResourceColumn>

              <Column>
                <React.Suspense
                  fallback={
                    <SkeletonBlock
                      style={{
                        width: "100%",
                        height: "30rem",
                      }}
                    />
                  }
                ></React.Suspense>
              </Column>
            </MainRow>
          </TabContainer>
        </LeftSideContainer>

        <RightSideModalContainer>
          <ProcurementCostsContent componentId={activeComponentId} />
        </RightSideModalContainer>
      </Container>
    </TopBarModal>
  );
}

export default function ProcurementCostsModal() {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);

  const onClose = useCallback(() => {
    setModalTypeOpen(undefined);
  }, [setModalTypeOpen]);

  if (modalTypeOpen?.modalType !== ProcurementModalType) {
    return null;
  }

  return <ProcurementCostsModalInner onClose={onClose} />;
}
