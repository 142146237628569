import {
  analysisStoppedText,
  getStoppedReason,
  getAnalysisError,
} from "analysis/warnings";
import { formatDecimal } from "components/ProductionV2/format";
import SimpleAlert from "components/ValidationWarnings/SimpleAlert";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import { useDashboardContext } from "../Dashboard";
import { CenterContainer, SafeCard } from "./Base";
import { useAtomValue } from "jotai";
import {
  getInstallationCostStoppedReason,
  installationStoppedText,
} from "components/Installation/errors";

const InputDetails = () => {
  const { costConfiguration, triggerId } = useDashboardContext();

  const stoppedReason = useAtomValue(getStoppedReason(triggerId));
  const analysisStoppedReason = useAtomValue(getAnalysisError(triggerId));
  const installationStoppedReason = useAtomValue(
    getInstallationCostStoppedReason(triggerId),
  );

  const stopped = stoppedReason ?? analysisStoppedReason;

  if (stopped) {
    return (
      <CenterContainer>
        <SimpleAlert text={analysisStoppedText[stopped]} type={"error"} />
      </CenterContainer>
    );
  }
  if (installationStoppedReason) {
    return (
      <CenterContainer>
        <SimpleAlert
          text={installationStoppedText[installationStoppedReason]}
          type={"error"}
        />
      </CenterContainer>
    );
  }

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Other expenditures reference year</p>
        <p>{costConfiguration?.inflation.referenceYearOtherExpenditures}</p>
      </Row>

      <h4>Lifecycle expenditures</h4>
      {costConfiguration?.devex && (
        <Row>
          <p>DEVEX</p>
          <p>
            {costConfiguration
              ? formatDecimal(costConfiguration.devex.cost, 1)
              : "-"}{" "}
            {costConfiguration?.devex.unit}
          </p>
        </Row>
      )}

      {costConfiguration?.devex && (
        <Row>
          <p>DECOM</p>
          <p>
            {costConfiguration
              ? formatDecimal(costConfiguration.decom.cost, 1)
              : "-"}{" "}
            {costConfiguration?.decom.unit}
          </p>
        </Row>
      )}

      <h4>OPEX</h4>
      {costConfiguration?.opex &&
        costConfiguration.opex.custom.map((opex) => (
          <Row key={opex.id}>
            <p>{opex.name}</p>
            <p>
              {opex.cost} {opex.unit}
            </p>
          </Row>
        ))}
    </FlexGrid2>
  );
};

export const OtherExpendituresInputWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Other expenditures input"
      id="Other expenditures input"
      resetKeys={errorBoundaryResetKeys}
    >
      <InputDetails />
    </SafeCard>
  );
};
