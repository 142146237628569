import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import useTextInput from "../../hooks/useTextInput";
import BinIcon from "@icons/24/Bin.svg?react";
import FolderIcon from "@icons/40/FolderBig.svg?react";
import PersonalFolderIcon from "@icons/40/FolderPersonalBig.svg";
import BranchIcon from "@icons/24/Branch.svg";
import InfoIcon from "@icons/24/Information.svg?react";
import ShareIcon from "@icons/24/Share.svg?react";
import PencilIcon from "@icons/24/Pencil.svg?react";
import SinglePerson from "@icons/24/SinglePerson.svg?react";
import StarIcon from "@icons/24/Star.svg?react";
import AddIcon from "@icons/24/Add.svg";
import { projectBranchCountAtom } from "../../state/project";
import { followProjectsAtom } from "../../state/project";
import {
  memberInOrganisationSelectorFamily,
  userHaveEditorNodeAccess,
  userNodeAccessSelectorFamily,
} from "../../state/user";
import { colors } from "../../styles/colors";
import {
  SkeletonBlock,
  SkeletonRound,
  SkeletonText,
} from "../Loading/Skeleton";
import { spaceSmall } from "../../styles/space";
import { EditableTextInternalState } from "../General/EditableText";
import { Column, Row } from "../General/Layout";
import { MenuItem } from "../General/Menu";
import { DotMenu } from "../General/MenuButton";
import StaticMapboxProjectImage, {
  TutorialProjectImage,
} from "../StaticMapboxImage/StaticMapboxProjectImage";
import { ProjectDotMenu } from "./ProjectDotMenu";
import {
  AddIconWrapper,
  BlueCircleIcon,
  DotMenuWrapper,
  FolderCardGrid,
  FolderNameWrapper,
  FolderWrapper,
  NewProjectPlaceholder,
  ProjectGridWrapper,
  ProjectIconWrapper,
  ProjectNameWrapper,
  ProjectPictureWrapper,
  ProjectWrapper,
  SecondaryText,
  StarIconWrapper,
} from "./ProjectGrid.style";
import { VerticalDivider } from "./styles";
import { useFollowProjectCrud } from "./useFollowProjectCrud";
import {
  OrganisationNodeCrud,
  findTopLevelNode,
  getNodesWithMissingParents,
  useOrganisationNodeCrud,
} from "./useOrganisationFolderCrud";
import { Node, ProjectNodeInformation } from "../../services/customerAPI";
import useFolderId from "hooks/useFolderId";
import { allNodeUsersSelectorFamily, makeNodeCompare } from "./state";
import { organisationRightSideModal } from "components/Organisation/OrganisationRightSide/state";
import { Icon } from "components/General/Icons";
import DontRenderWhenCheckly from "components/DontRenderWhenCheckly/DontRenderWhenCheckly";
import {
  ONSHORE_SANDBOX_TUTORIAL_ID,
  SANDBOX_TUTORIAL_ID,
} from "components/OnboardingTours/Tour";
import LearnIcon from "@icons/24/Learn.svg?react";
import { getNodeName } from "components/Organisation/utils";
import MoveToFolderIcon from "@icons/14/MoveToFolder.svg";
import { moveFolderIdAtom } from "components/Organisation/MoveNodeModal/state";
import { getUsersAndAllAccessesForNode } from "components/Organisation/Groups/state";
import { getPathPrefix } from "utils/utils";
import { DesignToolMode } from "types/map";
import { lunwrap } from "utils/jotai";
import {
  offshoreAccessSelectorFamily,
  onshoreAccessSelectorFamily,
} from "state/featureAccess";
import { Tag } from "components/General/Tag";
import { useNodesInOrganisationState } from "./useNodesInOrganisationState";
import { useUserAccessState } from "./useUserAccessState";
import { RenderOnFirstVisibility } from "components/General/RenderOnFirstVisibility";
import { WithTooltip } from "components/General/Tooltip";
export const ProjectGrid = ({
  openNewProjectModal,
  canCreateProjectHere,
}: {
  openNewProjectModal(): void;
  canCreateProjectHere: boolean;
}) => {
  const folderId = useFolderId();
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const { loadedState: nodes, state: projectsState } =
    useNodesInOrganisationState(organisationId);
  const { data: loadedNodes } = projectsState;

  const missingParentsNodes = useAtomValue(
    getNodesWithMissingParents({
      organisationId,
    }),
  );

  const childrenNodes = useMemo(
    () =>
      folderId === organisationId
        ? missingParentsNodes
        : nodes.filter((n) => n.parent_id === folderId),
    [folderId, organisationId, missingParentsNodes, nodes],
  );

  const followsLoadable = useAtomValue(loadable(followProjectsAtom));

  const sortedChildren = useMemo(() => {
    const fis = [...childrenNodes];
    const followIds = new Set(
      (lunwrap(followsLoadable) ?? [])
        .filter((f) => f.follow)
        .map((f) => f.nodeId)
        .map((nodeId) => nodes.find((n) => n.id === nodeId)?.id)
        .filter((projectId) => projectId) as string[],
    );
    const cmp = makeNodeCompare(followIds);
    fis.sort(cmp);
    return fis;
  }, [childrenNodes, followsLoadable, nodes]);

  const loadedFolderNodes = loadedNodes.filter(
    (node) => node.value.type === "folder",
  );

  const loadedProjectNodes = loadedNodes.filter(
    (node) => node.value.type === "project",
  );

  const organisationCrud = useOrganisationNodeCrud();
  const somethingLoads = followsLoadable.state === "loading";

  if (somethingLoads) {
    return (
      <>
        <ProjectGridWrapper>
          <ProjectPlaceholder />
          <ProjectPlaceholder />
          <ProjectPlaceholder />
        </ProjectGridWrapper>
      </>
    );
  }

  return (
    <div>
      <ProjectGridWrapper>
        {sortedChildren.map((node) => {
          if (["personal_folder", "folder"].includes(node.type)) {
            return (
              <Suspense key={node.id} fallback={<ProjectPlaceholder />}>
                <FolderCard
                  node={node}
                  nodes={nodes}
                  organisationId={organisationId}
                />
              </Suspense>
            );
          }
          return null;
        })}
        {loadedFolderNodes.some(
          (n) => n.getLoading() && n.value.parent === folderId,
        ) && <FolderPlaceholder />}
      </ProjectGridWrapper>
      <ProjectGridWrapper
        style={{
          padding: "3.2rem 0",
        }}
      >
        {loadedProjectNodes.some(
          (n) => n.getLoading() && n.value.parent === folderId,
        ) && <ProjectPlaceholder />}
        {sortedChildren.map((node) => {
          if (node.type === "project") {
            return (
              <Suspense key={node.id} fallback={<ProjectPlaceholder />}>
                <ProjectCard
                  project={node as ProjectNodeInformation}
                  organisationId={organisationId}
                  crudNode={organisationCrud}
                />
              </Suspense>
            );
          }
          return null;
        })}
        {canCreateProjectHere && (
          <NewProjectCard openNewProjectModal={openNewProjectModal} />
        )}
      </ProjectGridWrapper>
    </div>
  );
};

const ProjectCard = ({
  project,
  organisationId,
  crudNode,
}: {
  project: ProjectNodeInformation;
  organisationId: string;
  crudNode: OrganisationNodeCrud;
}) => {
  const onshoreAccess = useAtomValue(
    onshoreAccessSelectorFamily({ organisationId }),
  );
  const offshoreAccess = useAtomValue(
    offshoreAccessSelectorFamily({ organisationId }),
  );
  const fullShoreAccess = onshoreAccess && offshoreAccess;
  const location = useLocation();

  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const { put: putFollow } = useFollowProjectCrud();
  const follows = useAtomValue(followProjectsAtom);

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);
  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === project?.id && f.follow),
    [follows, project],
  );

  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] = useTextInput(
    project.name ?? "",
  );

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setWrittenTitle(project.name);
  }, [project.name, setWrittenTitle]);

  const { update: updateNode } = useOrganisationNodeCrud();

  const currentFolder = useMemo(
    () => nodes.find((f) => f.id === project.parent_id),
    [project.parent_id, nodes],
  );

  const [, ref] = useDrag({
    type: "PROJECT",
    item: {
      id: project.id,
      currentFolder,
    },
  });
  const [isBigHovered, setIsBigHovered] = useState(false);
  const [activeDotMenu, setActiveDotMenu] = useState(false);

  let searchParams = new URLSearchParams(location.search);
  searchParams.delete("page");
  let newSearchString = searchParams.toString();

  return (
    <Link
      key={project.id}
      style={{
        textDecoration: "none",
      }}
      to={`/${getPathPrefix(project)}/project/${organisationId}/${project.id}?${newSearchString}`}
      ref={ref}
      draggable={!isEditing}
    >
      <ProjectWrapper
        onMouseEnter={() => setIsBigHovered(true)}
        onMouseLeave={() => setIsBigHovered(false)}
        isHovered={isBigHovered}
      >
        <ProjectPictureWrapper>
          {project.tutorial_id === SANDBOX_TUTORIAL_ID ||
          project.tutorial_id === ONSHORE_SANDBOX_TUTORIAL_ID ? (
            <>
              <TutorialProjectImage>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BlueCircleIcon>
                    <Icon size="3rem" fill="white">
                      <LearnIcon />
                    </Icon>
                  </BlueCircleIcon>
                </div>
              </TutorialProjectImage>
            </>
          ) : (
            <DontRenderWhenCheckly>
              <RenderOnFirstVisibility>
                <StaticMapboxProjectImage project={project} />
              </RenderOnFirstVisibility>
            </DontRenderWhenCheckly>
          )}

          {fullShoreAccess &&
            project?.project_type === DesignToolMode.Onshore && (
              <div
                style={{
                  position: "absolute",
                  bottom: "0.0rem",
                  right: "1.5rem",
                }}
              >
                <Tag
                  text="Onshore"
                  style="subtle"
                  background={colors.grey200}
                />
              </div>
            )}
          <StarIconWrapper
            active={isFollowed}
            onClick={(e) => {
              e.preventDefault();
              putFollow(project.id, !isFollowed);
            }}
          >
            <StarIcon />
          </StarIconWrapper>
        </ProjectPictureWrapper>

        <Column
          style={{
            gap: 0,
            padding: "0.8rem 0 0.4rem 0.4rem",
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <EditableTextInternalState
              type="text"
              value={writtenTitle}
              disabled={true}
              onChange={onWrittenTitleChange}
              onEnter={async (newTitle) => {
                await updateNode(project.id, {
                  name: newTitle,
                });
              }}
              onCancel={async (newTitle) => {
                await updateNode(project.id, {
                  name: newTitle,
                });
              }}
              isEditing={isEditing}
              onAfter={() => {
                setIsEditing(false);
              }}
              renderText={(title) => (
                <WithTooltip onlyOnOverflow text={title}>
                  <ProjectNameWrapper>{title}</ProjectNameWrapper>
                </WithTooltip>
              )}
            />
            <Row
              style={{
                gap: "0.4rem",
                padding: "0.4rem",
              }}
            >
              {isMemberInOrg && (
                <ProjectIconWrapper
                  isProjectHovered={isBigHovered}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setContent({
                      type: "project",
                      id: project.id,
                    });
                  }}
                >
                  <InfoIcon />
                </ProjectIconWrapper>
              )}
              <ProjectIconWrapper
                isActive={activeDotMenu}
                isProjectHovered={isBigHovered}
              >
                <ProjectDotMenu
                  onChange={() => {
                    setActiveDotMenu(!activeDotMenu);
                  }}
                  setIsEditingTitle={setIsEditing}
                  project={project}
                  currentFolder={currentFolder}
                  crudNode={crudNode}
                />
              </ProjectIconWrapper>
            </Row>
          </Row>
          <RenderOnFirstVisibility>
            <Row
              style={{
                minHeight: "3rem",
                alignItems: "center",
                padding: `0 ${spaceSmall}`,
                marginTop: "-1rem",
                gap: "0.6rem",
              }}
            >
              {project.id && <NumberOfBranches nodeId={project.id} />}
              <VerticalDivider />
              {isMemberInOrg ? (
                <Member_NumberOfCollaborators
                  organisationId={organisationId}
                  nodeId={project.id}
                />
              ) : (
                <Guest_NumberOfCollaborators
                  organisationId={organisationId}
                  nodeId={project.id}
                />
              )}
            </Row>
          </RenderOnFirstVisibility>
        </Column>
      </ProjectWrapper>
    </Link>
  );
};

const NewProjectCard = ({
  openNewProjectModal,
}: {
  openNewProjectModal(): void;
}) => {
  return (
    <NewProjectPlaceholder onClick={openNewProjectModal}>
      <AddIconWrapper>
        <Icon size={"16px"} stroke={colors.iconSelected}>
          <AddIcon />
        </Icon>
      </AddIconWrapper>
    </NewProjectPlaceholder>
  );
};

const NumberOfBranches = ({ nodeId }: { nodeId: string }) => {
  const numberLoadable = useAtomValue(
    loadable(
      projectBranchCountAtom({
        nodeId,
      }),
    ),
  );

  if (numberLoadable.state !== "hasData")
    return (
      <Row
        style={{
          alignItems: "center",
          gap: "0.4rem",
        }}
      >
        <Icon size={"1.4rem"} fill={colors.textSecondary}>
          <BranchIcon />
        </Icon>
        <SkeletonRound size={14} />
      </Row>
    );

  return (
    <Row
      style={{
        alignItems: "center",
        gap: "0.4rem",
      }}
    >
      <Icon size={"1.4rem"} fill={colors.textSecondary}>
        <BranchIcon />
      </Icon>
      <SecondaryText
        style={{
          justifyContent: "flex-end",
        }}
      >
        {numberLoadable.data}
      </SecondaryText>
    </Row>
  );
};

const Member_NumberOfCollaborators = ({
  nodeId,
  organisationId,
}: {
  nodeId: string;
  organisationId: string;
}) => {
  const allUsersWithAccessToNode = useAtomValue(
    loadable(
      getUsersAndAllAccessesForNode({
        organisationId,
        nodeId,
      }),
    ),
  );

  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  return (
    <Row
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setContent({
          type: "project",
          id: nodeId,
        });
      }}
      style={{
        alignItems: "center",
        gap: "0.4rem",
      }}
    >
      <Icon size={"1.4rem"} stroke={colors.iconSubtle}>
        <SinglePerson />
      </Icon>
      {allUsersWithAccessToNode.state !== "hasData" ? (
        <SkeletonRound size={14} />
      ) : (
        <SecondaryText
          style={{
            justifyContent: "flex-end",
          }}
        >
          {allUsersWithAccessToNode.data.length}
        </SecondaryText>
      )}
    </Row>
  );
};

const Guest_NumberOfCollaborators = ({
  nodeId,
  organisationId,
}: {
  nodeId: string;
  organisationId: string;
}) => {
  const projectMembersLoadable = useAtomValue(
    loadable(
      allNodeUsersSelectorFamily({
        organisationId,
        nodeId,
      }),
    ),
  );

  return (
    <Row
      style={{
        alignItems: "center",
        gap: "0.4rem",
      }}
    >
      <Icon size={"1.4rem"} stroke={colors.iconSubtle}>
        <SinglePerson />
      </Icon>
      {projectMembersLoadable.state !== "hasData" ? (
        <SkeletonRound size={14} />
      ) : (
        <SecondaryText
          style={{
            justifyContent: "flex-end",
          }}
        >
          {projectMembersLoadable.data.length}
        </SecondaryText>
      )}
    </Row>
  );
};

const FolderCard = ({
  node,
  nodes,
  organisationId,
}: {
  node: Node;
  nodes: Node[];
  organisationId: string;
}) => {
  const location = useLocation();
  const setMoveFolderId = useSetAtom(moveFolderIdAtom);

  const folderChildren = useMemo(
    () => nodes.filter((n) => n.parent_id === node.id),
    [node, nodes],
  );

  const {
    update: updateNode,
    move: moveNode,
    remove: removeNode,
  } = useOrganisationNodeCrud();

  const [{ isOver }, dropRef] = useDrop({
    accept: ["PROJECT", "FOLDER"],
    drop: (item: { id: string; currentNode?: Node }) => {
      if (item.currentNode?.id === node.id) return;
      if (item.id === node.id) return;
      moveNode(item.id, node.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [, dragRef] = useDrag(
    () => ({
      type: "FOLDER",
      item: {
        id: node.id,
        currentNode: node,
      },
      canDrag: () => !isEditing,
    }),
    [node, isEditing],
  );

  // Combine the drag and drop refs
  const combinedRef = (node: HTMLElement | null) => {
    if (!isEditing) {
      dragRef(node);
      dropRef(node);
    }
  };

  const { data: userAllNodesAccess } = useUserAccessState();
  const isCustomerEditor = useMemo(
    () => userHaveEditorNodeAccess(userAllNodesAccess, node.id),
    [userAllNodesAccess, node.id],
  );
  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId: node.id,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;

  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] = useTextInput(
    getNodeName(node),
  );

  const chosenFolderHasPersonalTopFolder = node
    ? findTopLevelNode(nodes, node.id, organisationId ?? "")?.type ===
      "personal_folder"
    : false;

  const isPersonalFolder = node.type === "personal_folder";

  useEffect(() => {
    setWrittenTitle(node.name);
  }, [node.name, setWrittenTitle]);

  const setContent = useSetAtom(organisationRightSideModal(organisationId));
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );

  const { put: putFollow } = useFollowProjectCrud();

  const [isFolderHovered, setIsFolderHovered] = useState(false);
  const [activeDotMenu, setActiveDotMenu] = useState(false);

  const follows = useAtomValue(followProjectsAtom);
  const isFollowed = useMemo(
    () => follows.some((f) => f.nodeId === node.id && f.follow),
    [follows, node],
  );

  let searchParams = new URLSearchParams(location.search);
  searchParams.delete("page");
  let newSearchString = searchParams.toString();

  return (
    <Link
      onClick={() => {
        setContent((curr) =>
          typeof curr !== "undefined"
            ? {
                type: "project",
                id: node.id,
              }
            : curr,
        );
      }}
      to={`/organisation/${organisationId}/projects/${node.id}?${newSearchString}`}
      style={{
        textDecoration: "none",
      }}
      ref={combinedRef}
    >
      <FolderWrapper
        onMouseEnter={() => setIsFolderHovered(true)}
        onMouseLeave={() => setIsFolderHovered(false)}
        onClick={() => {}}
        isHovered={isOver}
      >
        {(isMemberInOrg || isCustomerEditor) && (
          <DotMenuWrapper
            isActive={activeDotMenu}
            isFolderHovered={isFolderHovered}
          >
            <DotMenu
              onChange={() => {
                setActiveDotMenu(!activeDotMenu);
              }}
              size="medium"
              side="left"
              direction="down"
            >
              {isMemberInOrg && (
                <MenuItem
                  name="Folder information"
                  icon={<InfoIcon />}
                  onClick={() => {
                    setContent({
                      type: "project",
                      id: node.id,
                    });
                  }}
                />
              )}
              <MenuItem
                name={isFollowed ? "Unstar" : "Star"}
                icon={
                  <StarIcon
                    stroke={
                      isFollowed ? colors.surfaceBrand : colors.surfaceBrand
                    }
                    fill={isFollowed ? colors.surfaceBrand : "transparent"}
                  />
                }
                onClick={() => {
                  if (!node.id) return;
                  putFollow(node.id, !isFollowed);
                }}
              />
              {isNodeAdmin && !chosenFolderHasPersonalTopFolder && (
                <>
                  <MenuItem
                    name="Share folder"
                    icon={<ShareIcon />}
                    onClick={() => {
                      setContent({
                        type: "project",
                        id: node.id,
                        meta: {
                          openAddCollaborators: true,
                        },
                      });
                    }}
                  />
                </>
              )}
              {isNodeAdmin && !isPersonalFolder && (
                <MenuItem
                  name="Move folder"
                  icon={<MoveToFolderIcon />}
                  onClick={() => {
                    setMoveFolderId(node.id);
                  }}
                  fillOrStroke="fill"
                />
              )}
              {!isPersonalFolder && (
                <>
                  <MenuItem
                    name="Rename"
                    icon={<PencilIcon />}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    disabled={!isCustomerEditor}
                  />
                  <MenuItem
                    name="Delete folder"
                    disabled={folderChildren.length > 0 || !isCustomerEditor}
                    title={
                      folderChildren.length > 0
                        ? "Folder must be empty to delete"
                        : undefined
                    }
                    icon={<BinIcon />}
                    onClick={async () => {
                      await removeNode(node.id);
                      setContent((curr) =>
                        curr?.type === "project" && curr.id === node.id
                          ? undefined
                          : curr,
                      );
                    }}
                  />
                </>
              )}
            </DotMenu>
          </DotMenuWrapper>
        )}
        <FolderCardGrid>
          {isPersonalFolder ? <PersonalFolderIcon /> : <FolderIcon />}
          <Column
            style={{
              display: "flex",
              maxWidth: "100%",
              gap: 0,
              padding: "0 0 0 1.6rem",
            }}
          >
            <EditableTextInternalState
              style={{
                flex: 1,
              }}
              smallInput
              type="text"
              value={writtenTitle}
              disabled={true}
              onChange={onWrittenTitleChange}
              onEnter={async (newTitle) => {
                await updateNode(node.id, {
                  name: newTitle,
                });
              }}
              onCancel={async (newTitle) => {
                await updateNode(node.id, {
                  name: newTitle,
                });
              }}
              onAfter={() => {
                setIsEditing(false);
              }}
              isEditing={isEditing}
              renderText={(title) => (
                <WithTooltip onlyOnOverflow text={title}>
                  <FolderNameWrapper>{title}</FolderNameWrapper>
                </WithTooltip>
              )}
            />

            <SecondaryText>
              {folderChildren.length}{" "}
              {folderChildren.length === 1 ? "item" : "items"}
            </SecondaryText>
          </Column>
        </FolderCardGrid>
      </FolderWrapper>
    </Link>
  );
};

function ProjectPlaceholder({ text }: { text?: string }) {
  // NOTE: the project card is 21.2 rem
  // 16 + 0.9 + 2.2 + 0.9 + 1.2  == 21.2
  return (
    <ProjectWrapper
      nohover
      style={{
        gap: "0.9rem",
      }}
    >
      <SkeletonBlock
        style={{
          width: "30rem",
          height: "16rem",
        }}
      />
      {text ? (
        <SkeletonText
          text={text}
          style={{
            width: "30rem",
            height: "2.2rem",
          }}
        />
      ) : (
        <SkeletonBlock
          style={{
            width: "30rem",
            height: "2.0rem",
          }}
        />
      )}
      <SkeletonBlock
        style={{
          width: "30rem",
          height: "1.4rem",
        }}
      />
    </ProjectWrapper>
  );
}

function FolderPlaceholder() {
  return (
    <FolderWrapper
      style={{
        gap: "0.9rem",
      }}
    >
      <SkeletonBlock
        style={{
          width: "30rem",
          height: "8rem",
        }}
      />
    </FolderWrapper>
  );
}
