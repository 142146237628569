import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState, useCallback } from "react";
import { inReadOnlyModeSelector } from "../../state/project";
import { spacing16 } from "../../styles/space";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  FatalErrorBoundaryWrapper,
  ScreamOnError,
} from "../ErrorBoundaries/ErrorBoundaryLocal";
import {
  ContentWrapper,
  ModalContainer,
  SettingButton,
} from "../SettingsV2/Shared/styles";
import {
  ContentContainer,
  HeaderContainer,
  HeaderWrapper,
  Row,
} from "./shared";
import Tabs from "components/General/Tabs";
import { TransportInstallationVessels } from "./OperationsSettings/TI/TransportInstallationVessels";
import { localOperationsConfigurationAtom } from "./OperationsSettings/state";
import { useAtom, useSetAtom } from "jotai";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { toastMessagesAtom } from "state/toast";
import useEnterToSave from "./useEnterToSave";
import Button from "components/General/Button";
import { Column } from "components/General/Layout";
import { EditableText } from "components/General/EditableText";
import { OperationsConfiguration } from "services/operationsConfigurationService";
import { savingOperationsConfigurationInProgressAtom } from "state/operationsConfiguration";
import { TransportInstallationActivities } from "./OperationsSettings/TI/TransportInstallationActivities";
import { ARTICLE_TRANSPORT_INSTALLATION } from "components/HelpTooltip/HelpTooltip";
import { HelpLink } from "components/HelpTooltip/HelpTooltip";
import DescriptionModal from "./DescriptionModal";
import ProjectResourceUsage from "./SettingsUsage/ProjectResourceUsage";

export const OperationsSettings = ErrorBoundaryWrapper(
  ({
    configuration,
    nodeId,
    configurationName,
    onSave,
    onSaveName,
    onUnmount,
  }: {
    configuration: OperationsConfiguration;
    configurationName: string;
    nodeId: string;
    onSave: (newConfig: OperationsConfiguration) => void;
    onSaveName?: (id: string, name: string) => Promise<any>;
    onUnmount?: () => void;
  }) => {
    const isSaving = useAtomValue(savingOperationsConfigurationInProgressAtom);
    const isReadOnly = useAtomValue(inReadOnlyModeSelector);
    const [localConfig, setLocalConfig] = useAtom(
      localOperationsConfigurationAtom,
    );
    useEffect(() => {
      setLocalConfig(configuration);
    }, [configuration, setLocalConfig]);

    const setUnsavedSettings = useSetAtom(unsavedSettingsState);
    const setToastMessages = useSetAtom(toastMessagesAtom);
    const [title, setTitle] = useState(configurationName);
    const [description, setDescription] = useState(
      configuration.description ?? "",
    );

    useEffect(() => {
      setTitle(configurationName);
    }, [configurationName]);

    useEffect(() => {
      setDescription(configuration.description ?? "");
    }, [configuration.description]);

    const configurationId = configuration.id;

    const initializeOperationsConfiguration = useCallback(() => {
      if (configuration && configurationId && !localConfig) {
        setLocalConfig(configuration);
      }
    }, [configuration, configurationId, localConfig, setLocalConfig]);

    useEffect(() => {
      initializeOperationsConfiguration();
    }, [configurationId, initializeOperationsConfiguration]);

    const isLoading = localConfig?.id !== configuration.id;
    const allChangesSaved = isReadOnly || localConfig === configuration;

    useEffect(() => {
      setUnsavedSettings(!isLoading && !allChangesSaved);
    }, [allChangesSaved, isLoading, setUnsavedSettings]);

    const onEnterSaveCallback = useCallback(() => {
      if (!localConfig) return;
      onSave(localConfig);
    }, [localConfig, onSave]);
    useEnterToSave(onEnterSaveCallback, !allChangesSaved && !isSaving);

    const renameConfig = useCallback(() => {
      if (!onSaveName) return;

      const renameConfigAsync = async () => {
        if (!localConfig) return;
        await onSaveName(localConfig.id, title);
      };

      renameConfigAsync();
    }, [onSaveName, localConfig, title]);

    const tabs = useMemo(() => {
      return [
        {
          name: "T&I campaign",
          data: (
            <TransportInstallationVessels
              isReadOnly={isReadOnly}
              nodeId={nodeId}
            />
          ),
        },
        {
          name: "T&I activities",
          data: (
            <TransportInstallationActivities
              isReadOnly={isReadOnly}
              nodeId={nodeId}
            />
          ),
        },
      ];
    }, [isReadOnly, nodeId]);

    useEffect(() => {
      return () => {
        if (onUnmount) {
          onUnmount();
        }
      };
    }, [onUnmount]);

    const handleUpdateOperationsDescription = useCallback(
      (description: string) => {
        if (!configuration) return;
        setDescription(description);

        onSave({
          ...configuration,
          description: description,
        });
      },
      [configuration, onSave],
    );

    if (!localConfig) return null;

    return (
      <ModalContainer>
        <HeaderWrapper>
          <HeaderContainer>
            <Column
              style={{
                width: "100%",
              }}
            >
              <Row
                style={{
                  alignItems: "center",
                  gap: "0.4rem",
                  margin: "-0 0 0 -2.8rem",
                }}
              >
                <HelpLink article={ARTICLE_TRANSPORT_INSTALLATION} />
                <EditableText
                  type="text"
                  smallInput={true}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  onEnter={renameConfig}
                  onCancel={() => {
                    setTitle(configurationName);
                  }}
                  textContainerStyle={{
                    maxWidth: "20vw",
                    padding: 0,
                  }}
                  renderText={(title) => (
                    <h3
                      style={{
                        margin: 0,
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        maxWidth: "60rem",
                      }}
                      title={title}
                    >
                      {title}
                    </h3>
                  )}
                  disabled={isReadOnly}
                />
              </Row>
              <Row
                style={{
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <ProjectResourceUsage
                  resourceType={"OPERATIONS_CONFIGURATION"}
                  resourceId={configuration.id}
                />
              </Row>
            </Column>
            <DescriptionModal
              disabled={isReadOnly}
              defaultValue={description}
              updateDescription={handleUpdateOperationsDescription}
              subtitle={
                <div>
                  <p>
                    The description will be visible for Admins and Editors in
                    projects with access to this configuration.
                  </p>
                </div>
              }
            />
          </HeaderContainer>

          <SettingButton
            style={{
              justifyContent: "flex-end",
              position: "absolute",
              right: "2.4rem",
              top: "14.4rem",
            }}
          >
            {!allChangesSaved && (
              <>
                <Button
                  disabled={isLoading || isSaving || allChangesSaved}
                  text="Cancel"
                  buttonType="secondary"
                  onClick={() => {
                    setLocalConfig(configuration);
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                />
                <Button
                  disabled={isLoading || allChangesSaved || isSaving}
                  text="Save changes"
                  onClick={() => {
                    setToastMessages((tm) => [
                      ...tm,
                      {
                        text: "Saving...",
                        timeout: 1000,
                      },
                    ]);
                    onSave({
                      ...localConfig,
                      name: title,
                    });
                  }}
                />
              </>
            )}
          </SettingButton>
        </HeaderWrapper>
        <ContentWrapper
          style={{
            maxHeight: "calc(100% - 8rem)",
            boxSizing: "border-box",
            paddingBottom: 0,
          }}
        >
          <ContentContainer
            style={{
              maxHeight: "100%",
              boxSizing: "border-box",
              paddingBottom: spacing16,
            }}
          >
            <Tabs
              tabs={tabs}
              menuStyle={{
                justifyContent: "space-between",
                gap: "5rem",
              }}
              contentWrapperStyle={{
                minHeight: "40vh",
                overflow: "auto",
                height: "100%",
              }}
            />
          </ContentContainer>
        </ContentWrapper>
      </ModalContainer>
    );
  },
  () => {
    return (
      <>
        <FatalErrorBoundaryWrapper />
        <ErrorBoundaryWarningTriangle />
      </>
    );
  },
  ScreamOnError,
);
