import { atom, useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import {
  SubstationType,
  createNewOrgSubstation,
  updateOrgSubstation,
  deleteOrgSubstation,
} from "services/substationService";
import { organisationSubstationResourceState } from "../state";
import { substationIdentifierAtom } from "../financial/ProcurementCosts/state";
import { Mixpanel } from "mixpanel";
import { useCallback } from "react";
import {
  createLoadingMessage,
  createSuccessMessage,
} from "components/Toast/ToastMessages";
import { useToast } from "hooks/useToast";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { DuplicateComponentModalType } from "components/ConfigurationModal/Components/DuplicateComponentOrConfigModal";
import { midScreenModalTypeOpenAtom } from "state/modal";
import { libraryResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchLibraryResourceUsage } from "services/usageService";
import { LibraryManageRole } from "../types";

export const librarySubstationRefreshAtom = atom(0);
export const useRefreshLibrarySubstation = () => {
  const refresh = useSetAtom(librarySubstationRefreshAtom);
  return useCallback(() => {
    refresh((c) => c + 1);
  }, [refresh]);
};

const useOrgSubstationCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const refreshLibrarySubstation = useRefreshLibrarySubstation();
  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const { success: showSuccess, info: showInfo, error: showError } = useToast();
  const { showConfirm } = useConfirm();

  const create = useJotaiCallback(
    async (
      get,
      set,
      {
        substation,
        name,
        projectAccess,
      }: {
        substation: Partial<SubstationType>;
        name?: string;
        projectAccess?: string[];
      },
    ) => {
      const newSubstation = {
        ...substation,
        name: name ? name : `${substation.name}`,
      };

      const fallback = await get(
        organisationSubstationResourceState(organisationId),
      );

      try {
        const res = await createNewOrgSubstation(
          organisationId,
          newSubstation,
          projectAccess,
        );
        aset(
          get,
          set,
          organisationSubstationResourceState(organisationId),
          (cur) => [...cur, res],
        );

        set(substationIdentifierAtom(organisationId), RESET);
        refreshLibrarySubstation();

        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationSubstationResourceState(organisationId),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId, refreshLibrarySubstation],
  );

  const update = useJotaiCallback(
    async (get, set, substation: SubstationType) => {
      const fallback = await get(
        organisationSubstationResourceState(organisationId),
      );

      try {
        const res = await updateOrgSubstation(organisationId, substation);
        if (res) {
          aset(
            get,
            set,
            organisationSubstationResourceState(organisationId),
            (cur) => cur.map((s) => (s.id === substation.id ? res : s)),
          );

          refreshLibrarySubstation();
          set(substationIdentifierAtom(organisationId), RESET);
        }
        return res;
      } catch (e) {
        aset(
          get,
          set,
          organisationSubstationResourceState(organisationId),
          () => fallback,
        );
        throw e;
      }
    },
    [organisationId, refreshLibrarySubstation],
  );

  const remove = useJotaiCallback(
    async (get, set, substationId: string) => {
      const fallback = await get(
        organisationSubstationResourceState(organisationId),
      );

      const cachedUsage = await get(
        libraryResourceUsageAtomFamily({
          organisationId,
          libraryManageRole: LibraryManageRole.SUBSTATION,
          resourceId: substationId,
        }),
      );

      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchLibraryResourceUsage(
          organisationId,
          LibraryManageRole.SUBSTATION,
          substationId,
        );
      }

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Delete substation",
          message: `Substation type is currently being used in ${usage.length} substations, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        showInfo(createLoadingMessage("Deleting substation..."), {
          timeout: 2000,
        });

        try {
          const res = await deleteOrgSubstation(organisationId, substationId);
          if (res) {
            aset(
              get,
              set,
              organisationSubstationResourceState(organisationId),
              (curr) => curr.filter((s) => s.id !== substationId),
            );
            refreshLibrarySubstation();
          }
          showSuccess(createSuccessMessage("Substation deleted successfully"), {
            showCountdown: false,
          });
          return res;
        } catch (e) {
          aset(
            get,
            set,
            organisationSubstationResourceState(organisationId),
            () => fallback,
          );
          showError("Failed to delete substation", {
            timeout: 2000,
          });
          throw e;
        }
      }
    },
    [
      organisationId,
      showInfo,
      showConfirm,
      showSuccess,
      refreshLibrarySubstation,
      showError,
    ],
  );

  const duplicate = useJotaiCallback(
    async (
      _get,
      _set,
      {
        substation,
        projectAccess,
        onSuccess,
      }: {
        substation: SubstationType;
        projectAccess?: string[];
        onSuccess?: (newSubstation: SubstationType) => void;
      },
    ) => {
      setMidScreenModalTypeOpen({
        modalType: DuplicateComponentModalType,
        metadata: {
          componentType: "substation",
          defaultName: `${substation.name} (duplicate)`,
          onDuplicate: async (name: string) => {
            showInfo(createLoadingMessage("Duplicating substation..."), {
              timeout: 2000,
            });

            Mixpanel.track_old("Duplicate org substation", {
              substationId: substation.id,
              organisationId,
            });

            try {
              const result = await create({
                substation: {
                  ...substation,
                  name,
                },
                projectAccess,
              });
              showSuccess(
                createSuccessMessage("Substation duplicated successfully"),
                {
                  showCountdown: false,
                },
              );
              onSuccess?.(result);
              return result;
            } catch (e) {
              showError("Failed to duplicate substation", {
                timeout: 2000,
              });
              throw e;
            }
          },
        },
      });
    },
    [
      create,
      organisationId,
      showInfo,
      showSuccess,
      showError,
      setMidScreenModalTypeOpen,
    ],
  );

  const updateLocal = useJotaiCallback(
    async (get, set, substation: SubstationType) => {
      aset(
        get,
        set,
        organisationSubstationResourceState(organisationId),
        (currentSubstations) => {
          const substationIndex = currentSubstations.findIndex(
            (s) => s.id === substation.id,
          );
          if (substationIndex !== -1) {
            // Update existing substation
            return currentSubstations.map((s, index) =>
              index === substationIndex ? { ...s, ...substation } : s,
            );
          } else {
            // Add new substation
            return [...currentSubstations, substation];
          }
        },
      );
    },
    [organisationId],
  );

  const removeLocal = useJotaiCallback(
    async (get, set, substationId: string) => {
      aset(
        get,
        set,
        organisationSubstationResourceState(organisationId),
        (currentSubstations) => {
          return currentSubstations.filter((s) => s.id !== substationId);
        },
      );
    },
    [organisationId],
  );

  return {
    create,
    update,
    remove,
    duplicate,
    updateLocal,
    removeLocal,
  };
};

export default useOrgSubstationCrud;
