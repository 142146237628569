import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getTurbinesWithFloatingFoundations } from "analysis/inputs";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";

export const quaysideTotalInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<number>>(async (get) => {
    const turbinesInstalledAtPort = await get(
      getTurbinesWithFloatingFoundations(id),
    );

    const configuration = await get(getOperationsConfiguration(id));
    const { activities } = configuration.ti.foundations.floaters;

    const totalInstallTimePerTurbine = activities.assembleTurbineOnFloater;
    const totalInstallTime =
      totalInstallTimePerTurbine * turbinesInstalledAtPort.length;

    return totalInstallTime;
  }),
);
