import { isCoordinateString } from "components/UploadModal/components/NewUploadTab/UploadFeaturesFromCoordinates/utils";
import { _VindFeaturesParser } from "./utils";

export const getClipboardFeaturesIfContainingVindFeatures = async () => {
  try {
    const clipboardData = await navigator.clipboard.readText();
    const json = JSON.parse(clipboardData);

    const parse = _VindFeaturesParser.safeParse(json);
    if (!parse.success) {
      return;
    }

    return parse.data;
  } catch (e) {
    return;
  }
};

export const getClipboardCoordinatesIfContainingVindCoordinates = async () => {
  try {
    const clipboardData = await navigator.clipboard.readText();
    if (isCoordinateString(clipboardData)) {
      return clipboardData;
    }
    return;
  } catch (e) {
    return;
  }
};
