/**
 * This file is generated from spec.yaml.  Do not edit it directly.
 * To regenerate it, run `npm run gen`.
 */
import * as z from "zod";

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Components {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Schemas {
    export const CostUnit = z.union([
      z.literal("m€/unit"),
      z.literal("m€/OSS"),
      z.literal("m€/ONS"),
      z.literal("m€/MW"),
      z.literal("m€/km"),
      z.literal("m€/off.km"),
      z.literal("m€/ons.km"),
      z.literal("k€/MW"),
      z.literal("k€/MW/y"),
      z.literal("k€/day"),
      z.literal("€/km"),
      z.literal("m€/cable"),
      z.literal("m€/exp.cable"),
      z.literal("€/m³"),
      z.literal("€/t"),
      z.literal("€/MWh"),
      z.literal("m€/line"),
      z.literal("m€"),
      z.literal("GWh"),
      z.literal("%"),
      z.literal("years"),
    ]);
    export const regions = z.union([
      z.literal("USA"),
      z.literal("Europe"),
      z.literal("Asia"),
      z.literal("Other"),
    ]);
    export const shippingCost = z.array(
      z
        .object({
          region: regions,
          /** The cost associated with the shipping of the component */
          cost: z.number(),
          unit: CostUnit,
        })
        .passthrough(),
    );
    export const supplyCost = z
      .object({
        /** The cost associated with the component */
        cost: z.number(),
        unit: CostUnit,
      })
      .passthrough();
    export const componentType = z.union([
      z.literal("turbine"),
      z.literal("interArrayCable"),
      z.literal("foundation"),
      z.literal("exportCable"),
    ]);
    export const libraryResourceType = z.union([
      z.literal("turbine"),
      z.literal("cable"),
      z.literal("exportCable"),
      z.literal("foundation"),
    ]);
    export const jacketFoundation = z
      .object({
        material: z
          .object({
            primarySteel: supplyCost,
          })
          .strict(),
        shippingCost: shippingCost,
      })
      .strict();
    export const monopileFoundation = z
      .object({
        material: z
          .object({
            primarySteel: supplyCost,
          })
          .strict(),
        shippingCost: shippingCost,
      })
      .strict();
    export const floatingFoundation = z
      .object({
        material: z
          .object({
            primarySteel: supplyCost,
            concrete: supplyCost,
            solidBallast: supplyCost,
            reinforcement: supplyCost,
            postTensionCables: supplyCost,
          })
          .strict(),
        shippingCost: shippingCost,
      })
      .strict();
    export const foundationMaterial = z
      .object({
        monopile: monopileFoundation,
        jacket: jacketFoundation,
        floating: floatingFoundation,
      })
      .strict();
    export const turbineProcurementCost = z
      .object({
        componentType: componentType,
        /** Unique identifier for the component */
        componentId: z.string(),
        supplyCost: supplyCost.optional(),
        shippingCost: shippingCost,
      })
      .passthrough();
    export const interArrayCableProcurementCost = z
      .object({
        componentType: componentType,
        /** Unique identifier for the component */
        componentId: z.string(),
        supplyCost: supplyCost.optional(),
        shippingCost: shippingCost,
      })
      .passthrough();
    export const exportCableProcurementCost = z
      .object({
        componentType: componentType,
        /** Unique identifier for the component */
        componentId: z.string(),
        supplyCost: supplyCost.optional(),
        shippingCost: shippingCost,
      })
      .passthrough();
    export const foundationProcurementCost = z
      .object({
        componentType: componentType,
        /** The name of the custom table */
        tableName: z.string(),
        /** Unique identifier for the foundation procurement cost */
        id: z.string().optional(),
        costs: foundationMaterial,
      })
      .passthrough();
    export const foundationProcurementCostResponse = z
      .object({
        componentType: componentType,
        /** The name of the custom table */
        tableName: z.string(),
        /** Unique identifier for the foundation procurement cost */
        id: z.string(),
        costs: foundationMaterial,
      })
      .passthrough();
    export const procurementCost = z.union([
      turbineProcurementCost,
      foundationProcurementCost,
      interArrayCableProcurementCost,
      exportCableProcurementCost,
    ]);
    export const procurementCostResponse = z.union([
      turbineProcurementCost,
      foundationProcurementCostResponse,
      interArrayCableProcurementCost,
      exportCableProcurementCost,
    ]);
    export const CostType = z.union([
      z.literal("turbine"),
      z.literal("cable"),
      z.literal("mooring"),
      z.literal("substation"),
      z.literal("exportCable"),
      z.literal("foundation"),
      z.literal("other"),
    ]);
    export const OccuranceType = z.union([
      z.literal("yearly"),
      z.literal("recurring"),
      z.literal("singleEvent"),
    ]);
    export const ConfidenceLevel = z.union([
      z.literal("Not specified"),
      z.literal("Uncertain"),
      z.literal("Verified"),
    ]);
    export const FeatureCost = z.literal("feature_cost");
    export const CostWithUnit = z
      .object({
        cost: z.number(),
        unit: CostUnit,
        confidenceLevel: ConfidenceLevel.optional(),
        freeText: z.string().optional(),
      })
      .passthrough();
    export const FeatureOrOverrideCost = z.union([FeatureCost, CostWithUnit]);
    export const OffshoreOnshoreSubstationCost = z
      .object({
        offshore: z
          .object({
            cost: z.number(),
            unit: z.union([
              z.literal("m€/unit"),
              z.literal("k€/MW"),
              z.literal("m€/MW"),
            ]),
          })
          .passthrough(),
        onshore: z
          .object({
            cost: z.number(),
            unit: z.union([
              z.literal("m€/unit"),
              z.literal("k€/MW"),
              z.literal("m€/MW"),
            ]),
          })
          .passthrough(),
      })
      .strict();
    export const SubstationParameterCost = z
      .object({
        HVDC: OffshoreOnshoreSubstationCost,
        HVAC: OffshoreOnshoreSubstationCost,
      })
      .strict();
    export const SubstationCost = z.union([
      CostWithUnit,
      SubstationParameterCost,
    ]);
    export const libraryFoundationReferenceCost = z
      .object({
        floatingCostReference: z.string().optional(),
        monopileCostReference: z.string().optional(),
        jacketCostReference: z.string().optional(),
      })
      .passthrough();
    export const LCOE = z
      .object({
        discountRate: z.number(),
      })
      .passthrough();
    export const CustomCapexEntry = z
      .object({
        id: z.string(),
        name: z.string(),
        cost: z.number(),
        category: CostType,
        unit: CostUnit,
        confidenceLevel: ConfidenceLevel.optional(),
        freeText: z.string().optional(),
      })
      .passthrough();
    export const ComponentInstallationCost = z.union([
      CostWithUnit,
      z.literal("operations_cost"),
    ]);
    export const InstallationCosts = z
      .object({
        turbines: ComponentInstallationCost,
        turbinesFreeText: z.string().optional(),
        cables: ComponentInstallationCost,
        cablesFreeText: z.string().optional(),
        mooring: ComponentInstallationCost,
        mooringFreeText: z.string().optional(),
        foundations: ComponentInstallationCost,
        foundationsFreeText: z.string().optional(),
        substation: ComponentInstallationCost,
        substationFreeText: z.string().optional(),
        exportCable: ComponentInstallationCost,
        exportCableFreeText: z.string().optional(),
      })
      .passthrough();
    export const OpexEntry = z
      .object({
        id: z.string(),
        name: z.string(),
        cost: z.number(),
        unit: z.union([
          z.literal("€/MWh"),
          z.literal("k€/MW/y"),
          z.literal("m€"),
        ]),
        occurance: OccuranceType,
        occuranceYear: z.number().int().nullable().optional(),
      })
      .passthrough();
    export const ComponentCost = z
      .object({
        id: z.string(),
        cost: z.number(),
        unit: CostUnit,
      })
      .passthrough();
    export const ProjectComponentCosts = z
      .object({
        turbines: z.object({}).and(z.record(ComponentCost)).optional(),
        cables: z.object({}).and(z.record(ComponentCost)).optional(),
        exportCable: z.object({}).and(z.record(ComponentCost)).optional(),
      })
      .passthrough();
    export const CAPEX = z
      .object({
        projectComponentCosts: ProjectComponentCosts.optional(),
        fixed: z
          .object({
            turbines: z.union([CostWithUnit, z.literal("feature_cost")]),
            cables: CostWithUnit.optional(),
            mooring: z
              .object({
                lines: CostWithUnit.optional(),
                anchors: CostWithUnit,
                clumpWeights: CostWithUnit,
                buoys: CostWithUnit,
              })
              .passthrough()
              .optional(),
            foundations: z
              .union([
                CostWithUnit,
                foundationMaterial,
                libraryFoundationReferenceCost,
              ])
              .optional(),
            substation: SubstationCost,
            exportCable: FeatureOrOverrideCost,
            cablesFreeText: z.string().optional(),
            exportCableFreeText: z.string().optional(),
            turbinesFreeText: z.string().optional(),
            substationFreeText: z.string().optional(),
            mooringFreeText: z.string().optional(),
            foundationFreeText: z.string().optional(),
          })
          .passthrough(),
        custom: z.array(CustomCapexEntry),
        installation: InstallationCosts,
      })
      .passthrough();
    export const OPEX = z
      .object({
        custom: z.array(OpexEntry),
      })
      .passthrough();
    export const Inflation = z
      .object({
        referenceYearCapex: z.number().int(),
        referenceYearOtherExpenditures: z.number().int(),
        referenceYearRevenue: z.number().int(),
        inflationRate: z.number(),
      })
      .passthrough();
    export const LifeCycle = z
      .object({
        projectStart: z.number().int(),
        operationStart: z.number().int(),
        decomissioning: z.number().int(),
        phasing: z
          .object({
            devex: z.array(z.number()),
            capex: z.array(z.number()),
          })
          .passthrough(),
      })
      .passthrough();
    export const CashFlows = z
      .object({
        guaranteedPrice: z
          .object({
            cost: z.number(),
            unit: CostUnit,
          })
          .passthrough(),
        guaranteedYears: z.number().int(),
        marketPrice: z
          .object({
            cost: z.number(),
            unit: CostUnit,
          })
          .passthrough(),
        loanCapexFraction: z.number(),
        loanInterestRate: z.number(),
        loanRepaymentYears: z.number().int(),
      })
      .passthrough();
    export const ProjectType = z.union([
      z.literal("offshore"),
      z.literal("onshore"),
    ]);
    export const FractionContingency = z
      .object({
        type: z.literal("fraction"),
        value: z.number(),
      })
      .passthrough();
    export const Contingency = z
      .object({
        capex: FractionContingency,
        opex: FractionContingency.optional(),
      })
      .passthrough();
    export const CostConfiguration = z
      .object({
        id: z.string(),
        name: z.string(),
        description: z.string().nullable().optional(),
        type: ProjectType,
        devex: CostWithUnit,
        capex: CAPEX,
        opex: OPEX,
        decom: CostWithUnit,
        lcoe: LCOE,
        lifeCycle: LifeCycle,
        inflation: Inflation,
        contingency: Contingency.optional(),
        cashFlows: CashFlows,
        useConfidenceLevel: z.boolean().nullable().optional(),
        showFreeTextCapex: z.boolean().nullable().optional(),
        author: z.string().nullable().optional(),
        lastUpdatedAt: z.number().nullable().optional(),
      })
      .passthrough();
    export const CostConfigurationInput = z
      .object({
        name: z.string(),
        description: z.string().nullable().optional(),
        type: ProjectType,
        devex: CostWithUnit,
        capex: CAPEX,
        opex: OPEX,
        decom: CostWithUnit,
        lcoe: LCOE,
        lifeCycle: LifeCycle,
        inflation: Inflation,
        contingency: Contingency.optional(),
        cashFlows: CashFlows,
        useConfidenceLevel: z.boolean().nullable().optional(),
        showFreeTextCapex: z.boolean().nullable().optional(),
      })
      .passthrough();
    export const ErrorResponse = z
      .object({
        /** Error message */
        error: z.string().optional(),
      })
      .passthrough();
    export const CostConfigurationsResponse = z
      .object({
        items: z.array(CostConfiguration),
      })
      .passthrough();
  }
}
export const components = {
  schemas: {
    CostUnit: Components.Schemas.CostUnit,
    regions: Components.Schemas.regions,
    /** Regional costs */
    shippingCost: Components.Schemas.shippingCost,
    supplyCost: Components.Schemas.supplyCost,
    componentType: Components.Schemas.componentType,
    libraryResourceType: Components.Schemas.libraryResourceType,
    jacketFoundation: Components.Schemas.jacketFoundation,
    monopileFoundation: Components.Schemas.monopileFoundation,
    floatingFoundation: Components.Schemas.floatingFoundation,
    foundationMaterial: Components.Schemas.foundationMaterial,
    turbineProcurementCost: Components.Schemas.turbineProcurementCost,
    interArrayCableProcurementCost:
      Components.Schemas.interArrayCableProcurementCost,
    exportCableProcurementCost: Components.Schemas.exportCableProcurementCost,
    foundationProcurementCost: Components.Schemas.foundationProcurementCost,
    foundationProcurementCostResponse:
      Components.Schemas.foundationProcurementCostResponse,
    procurementCost: Components.Schemas.procurementCost,
    procurementCostResponse: Components.Schemas.procurementCostResponse,
    /** Types of costs. */
    CostType: Components.Schemas.CostType,
    /** Types of occurrence for costs. */
    OccuranceType: Components.Schemas.OccuranceType,
    /** Confidence levels for cost estimates. */
    ConfidenceLevel: Components.Schemas.ConfidenceLevel,
    FeatureCost: Components.Schemas.FeatureCost,
    /** Cost with associated units and optional confidence level. */
    CostWithUnit: Components.Schemas.CostWithUnit,
    FeatureOrOverrideCost: Components.Schemas.FeatureOrOverrideCost,
    OffshoreOnshoreSubstationCost:
      Components.Schemas.OffshoreOnshoreSubstationCost,
    SubstationParameterCost: Components.Schemas.SubstationParameterCost,
    SubstationCost: Components.Schemas.SubstationCost,
    libraryFoundationReferenceCost:
      Components.Schemas.libraryFoundationReferenceCost,
    /** Levelized cost of energy (LCOE) structure. */
    LCOE: Components.Schemas.LCOE,
    /** Custom capital expenditure entry. */
    CustomCapexEntry: Components.Schemas.CustomCapexEntry,
    ComponentInstallationCost: Components.Schemas.ComponentInstallationCost,
    InstallationCosts: Components.Schemas.InstallationCosts,
    /** Operational expenditure entry. */
    OpexEntry: Components.Schemas.OpexEntry,
    /** Cost of a project component. */
    ComponentCost: Components.Schemas.ComponentCost,
    /** Costs associated with different project components. */
    ProjectComponentCosts: Components.Schemas.ProjectComponentCosts,
    CAPEX: Components.Schemas.CAPEX,
    OPEX: Components.Schemas.OPEX,
    Inflation: Components.Schemas.Inflation,
    LifeCycle: Components.Schemas.LifeCycle,
    CashFlows: Components.Schemas.CashFlows,
    ProjectType: Components.Schemas.ProjectType,
    FractionContingency: Components.Schemas.FractionContingency,
    Contingency: Components.Schemas.Contingency,
    CostConfiguration: Components.Schemas.CostConfiguration,
    CostConfigurationInput: Components.Schemas.CostConfigurationInput,
    ErrorResponse: Components.Schemas.ErrorResponse,
    CostConfigurationsResponse: Components.Schemas.CostConfigurationsResponse,
  },
};

export const paths = {
  "/api/cost/organisation/{organisationId}/configurations/{configurationId}": {
    delete: {
      pathParams: z.object({
        organisationId: z.string(),
        configurationId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Invalid request */
          "application/json": z.string(),
        },
        500: {
          /** Failed to delete cost configuration */
          "application/json": z.string(),
        },
      },
    },
    put: {
      pathParams: z.object({
        configurationId: z.string(),
        organisationId: z.string(),
      }),
      requestBody: {
        "application/json": Components.Schemas.CostConfigurationInput,
      },
      responses: {
        200: {
          /** Successfully updated cost configuration */
          "application/json": Components.Schemas.CostConfiguration,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        404: {
          /** Cost configuration not found */
          "application/json": z.string(),
        },
        500: {
          /** Failed to update cost configuration */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/node/{nodeId}/configurations/{configurationId}": {
    delete: {
      pathParams: z.object({
        nodeId: z.string(),
        configurationId: z.string(),
      }),
      responses: {
        204: z.never(),
        400: {
          /** Invalid request */
          "application/json": z.string(),
        },
        500: {
          /** Failed to delete cost configuration */
          "application/json": z.string(),
        },
      },
    },
    put: {
      pathParams: z.object({
        configurationId: z.string(),
        nodeId: z.string(),
      }),
      requestBody: {
        "application/json": Components.Schemas.CostConfigurationInput,
      },
      responses: {
        200: {
          /** Successfully updated cost configuration */
          "application/json": Components.Schemas.CostConfiguration,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        404: {
          /** Cost configuration not found */
          "application/json": z.string(),
        },
        500: {
          /** Failed to update cost configuration */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/node/{nodeId}/configurations/default": {
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            projectType: Components.Schemas.ProjectType,
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Successfully listed all procurement costs */
          "application/json": Components.Schemas.CostConfiguration,
        },
        500: {
          /** Failed to list procurement costs */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/organisation/{organisationId}/configurations/default": {
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      /** New default config */
      requestBody: {
        "application/json": z
          .object({
            projectType: Components.Schemas.ProjectType,
            foundationProcurementCostTableReference: z.string().optional(),
            name: z.string(),
            projectAccess: z.array(z.string()).optional(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Successfully listed all procurement costs */
          "application/json": Components.Schemas.CostConfiguration,
        },
        500: {
          /** Failed to list procurement costs */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/organisation/{organisationId}/procurementCosts": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Successfully listed all procurement costs */
          "application/json": z.array(
            Components.Schemas.procurementCostResponse,
          ),
        },
        400: {
          /** Missing or invalid parameters */
          "application/json": z.string(),
        },
        500: {
          /** Failed to list procurement costs */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/organisation/{organisationId}/procurementCosts/update/{componentType}":
    {
      put: {
        pathParams: z.object({
          organisationId: z.string(),
          componentType: Components.Schemas.componentType,
        }),
        /** Procurement costs to be updated */
        requestBody: {
          "application/json": z.array(Components.Schemas.procurementCost),
        },
        responses: {
          200: {
            /** Updated procurement cost successfully */
            "application/json": z.array(Components.Schemas.procurementCost),
          },
          400: {
            /** Missing or invalid parameters */
            "application/json": z.string(),
          },
          404: {
            /** Procurement cost or organisation not found */
            "application/json": z.string(),
          },
          500: {
            /** Updating procurement cost failed */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/cost/organisation/{organisationId}/procurementCosts/{procurementCostId}/{componentType}":
    {
      delete: {
        pathParams: z.object({
          organisationId: z.string(),
          procurementCostId: z.string(),
          componentType: Components.Schemas.componentType,
        }),
        responses: {
          204: {
            /** Procurement cost deleted successfully, no content */
            "application/json": z.string(),
          },
          400: {
            /** Missing or invalid parameters */
            "application/json": z.string(),
          },
          404: {
            /** Procurement cost or organisation not found */
            "application/json": z.string(),
          },
          500: {
            /** Failed to delete procurement cost */
            "application/json": z.string(),
          },
        },
      },
    },
  "/api/cost/procurementCosts/libraryaccess/node/{nodeId}/{libraryResourceType}":
    {
      get: {
        pathParams: z.object({
          nodeId: z.string(),
          libraryResourceType: Components.Schemas.libraryResourceType,
        }),
        responses: {
          200: {
            /** Successfully listed all procurement costs on node */
            "application/json": z.array(
              Components.Schemas.procurementCostResponse,
            ),
          },
          400: {
            /** Missing or invalid parameters */
            "application/json": z.string(),
          },
          500: {
            /** List procurement costs for node failed */
            "application/json": z.string(),
          },
        },
      },
    },
  "/invoke/lcoe-api-service-prod-initializeCostConfigInvoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
            author: z.string(),
            projectType: z.union([z.literal("offshore"), z.literal("onshore")]),
          })
          .strict()
          .strict(),
      },
      responses: {
        201: {
          /** Created config */
          "application/json": z
            .object({
              id: z.string().optional(),
              createdAt: z.number().optional(),
              lastUpdatedAt: z.number().optional(),
              author: z.string().optional(),
            })
            .passthrough(),
        },
        500: {
          /** Default config already exists */
          "application/json": Components.Schemas.ErrorResponse,
        },
      },
    },
  },
  "/invoke/lcoe-api-service-prod-list_configurations_invoke2": {
    post: {
      requestBody: {
        "application/json": z
          .object({
            nodeId: z.string(),
          })
          .strict()
          .strict(),
      },
      responses: {
        200: {
          /** Cost configurations for nodeId */
          "application/json": z.array(Components.Schemas.CostConfiguration),
        },
      },
    },
  },
  "/api/cost/node/{nodeId}/configurations": {
    get: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      responses: {
        200: {
          /** Successfully listed cost configurations */
          "application/json": Components.Schemas.CostConfigurationsResponse,
        },
        400: {
          /** Invalid request */
          "application/json": z.string(),
        },
        500: {
          /** Failed to list cost configurations */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        nodeId: z.string(),
      }),
      requestBody: {
        "application/json": Components.Schemas.CostConfigurationInput,
      },
      responses: {
        201: {
          /** Successfully created cost configuration */
          "application/json": Components.Schemas.CostConfiguration,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Failed to create cost configuration */
          "application/json": z.string(),
        },
      },
    },
  },
  "/api/cost/organisation/{organisationId}/configurations": {
    get: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      responses: {
        200: {
          /** Successfully listed cost configurations */
          "application/json": Components.Schemas.CostConfigurationsResponse,
        },
        400: {
          /** Invalid request */
          "application/json": z.string(),
        },
        500: {
          /** Failed to list cost configurations */
          "application/json": z.string(),
        },
      },
    },
    post: {
      pathParams: z.object({
        organisationId: z.string(),
      }),
      requestBody: {
        "application/json": z
          .object({
            projectAccess: z.array(z.string()).optional(),
            config: Components.Schemas.CostConfigurationInput,
          })
          .passthrough(),
      },
      responses: {
        201: {
          /** Successfully created cost configuration */
          "application/json": Components.Schemas.CostConfiguration,
        },
        400: {
          /** Invalid request body */
          "application/json": z.string(),
        },
        500: {
          /** Failed to create cost configuration */
          "application/json": z.string(),
        },
      },
    },
  },
};

// Generated using openapi-to-zod v0.1.47
