import React from "react";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { useDrawMode } from "components/MapControls/useActivateDrawMode";
import { DrawCableCorridorMenuType } from "@constants/cabling";
import { useAtom } from "jotai";
import { keepCabelGenModalOpenAtom } from "state/cable";

const DrawCableCorridorHelp = () => {
  const [leftMenuActiveMode, setLeftMenuActiveMode] = useDrawMode();
  const [, setKeepCabelGenModalOpen] = useAtom(keepCabelGenModalOpenAtom);

  if (leftMenuActiveMode !== DrawCableCorridorMenuType) return null;

  return (
    <MenuFrame
      title="Draw cable corridor"
      onExit={() => {
        setLeftMenuActiveMode(undefined);
        setKeepCabelGenModalOpen(false);
      }}
    >
      <p>
        A cable corridor must connect with the park, and overlap one or more
        substations.
      </p>
    </MenuFrame>
  );
};

export default DrawCableCorridorHelp;
