import { Column, Frame, Row } from "components/General/Layout";
import { Suspense } from "react";
import { SelectWindConfiguration } from "components/ProductionV2/SelectWindConfiguration";
import { SelectAnalysisConfiguration } from "components/ProductionV2/SelectAnalysisConfiguration";
import styled from "styled-components";
import { colors } from "styles/colors";
import {
  SelectCostConfiguration,
  SelectOperationsConfiguration,
} from "components/FinancialAnalysis/FinancialAnalysis";
import { DarkTitle, HeaderRow } from "../style";
import { IconBtn } from "components/General/Icons";
import Close from "@icons/24/Close.svg?react";
import FilterIcon from "@icons/12/Filter.svg";
import { useAtomValue, useSetAtom } from "jotai";
import { configurationMenuActiveAtom, TopRightModeActiveAtom } from "./state";
import { modalTypeOpenAtom } from "state/modal";
import { ProjectConfigModalTypeV2 } from "state/configuration";
import { editorAccessProjectSelector } from "state/user";
import { isOnshoreAtom } from "state/onshore";
import { TopRightMenuOptions } from "@constants/canvas";

const StyledFrame = styled(Frame)`
  background-color: ${colors.blue700};
  gap: 1rem;
  width: 100%;
  min-width: 30rem;
  justify-self: end;
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 1.2rem;
  column-gap: 0.8rem;
  width: 100%;
`;

export const ConfigurationMenu = () => {
  const setModalType = useSetAtom(modalTypeOpenAtom);
  const setConfigurationMenuActive = useSetAtom(configurationMenuActiveAtom);
  const projectEditorAccess = useAtomValue(editorAccessProjectSelector);
  const isOnshore = useAtomValue(isOnshoreAtom);
  const topRightModeActive = useAtomValue(TopRightModeActiveAtom);

  const maxWidth =
    topRightModeActive === TopRightMenuOptions.view ? "30rem" : "100%";

  return (
    <StyledFrame style={{ maxWidth }}>
      <HeaderRow>
        <DarkTitle>Branch configurations</DarkTitle>
        <Row alignCenter>
          {projectEditorAccess && (
            <IconBtn
              hoverBackgroundColor={colors.blue800}
              iconColor={colors.iconNegative}
              size="1.2rem"
              onClick={() => {
                setModalType({
                  modalType: ProjectConfigModalTypeV2,
                });
              }}
            >
              <FilterIcon />
            </IconBtn>
          )}
          <IconBtn
            hoverBackgroundColor={colors.blue800}
            iconColor={colors.iconNegative}
            size="1.2rem"
            onClick={() => {
              setConfigurationMenuActive(false);
            }}
          >
            <Close />
          </IconBtn>
        </Row>
      </HeaderRow>

      <Column>
        <GridLayout>
          <Suspense fallback={null}>
            <SelectAnalysisConfiguration />
          </Suspense>
          <Suspense fallback={null}>
            <SelectCostConfiguration />
          </Suspense>
          <Suspense fallback={null}>
            <SelectWindConfiguration />
          </Suspense>
          {!isOnshore && (
            <Suspense fallback={null}>
              <SelectOperationsConfiguration />
            </Suspense>
          )}
        </GridLayout>
      </Column>
    </StyledFrame>
  );
};
