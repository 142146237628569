import { ProdId } from "analysis/inputs";
import { getAnalysisResponse } from "analysis/output";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { PropsWithChildren, ReactNode } from "react";

/**
 * Mount children if the analysis for the {@link ProdId} is **not** an Error.
 */
export default function AnalysisOk({
  pid,
  fallback,
  children,
}: PropsWithChildren<{ pid: ProdId; fallback: ReactNode }>) {
  const analysis = useAtomValue(loadable(getAnalysisResponse(pid)));
  const stop =
    analysis.state === "hasError" ||
    (analysis.state === "hasData" &&
      (analysis.data.status === "failed" ||
        analysis.data.status === "stopped"));
  if (stop) return fallback;
  return children;
}
