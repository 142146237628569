import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import SlideShowPlaceholder from "assets/slideshow-placeholder.jpg";

const Step1 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Financial insight</TopHeaderAccent>
          <SubHeader>Real-time LCoE calculations</SubHeader>
          <ContentText>
            Instantly evaluate how changes to park design and structures impact
            the Levelized Cost of Energy (LCoE) and cash flow.
          </ContentText>
          <ContentText>
            Share these insights with design decision-makers, empowering the
            project team to make informed choices by understanding the financial
            implications of their decisions.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

const Step2 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Smarter decisions</TopHeaderAccent>
          <SubHeader>Scenario modelling made easy</SubHeader>
          <ContentText>
            Set up and compare multiple financial scenarios in minutes. Analyze
            metrics such as LCoE, IRR, and other key indicators side by side to
            confidently assess the impact of various strategies and select the
            most viable path for your project.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="left"
    />
  );
};

const Step3 = ({ animation }: { animation: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Consistency and Accuracy</TopHeaderAccent>
          <SubHeader>From Rough Estimates to Confident projections</SubHeader>
          <ContentText>
            Standardize organizational default values and make them easily
            accessible for new projects, enabling quick, reliable initial
            estimates.
          </ContentText>
          <ContentText>
            As projects progress and more precise data is gathered, seamlessly
            update financial inputs to ensure the project teams works with the
            most accurate figures.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

export const FinancialSteps = [Step1, Step2, Step3];
