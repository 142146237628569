import { projectIdAtom } from "state/pathParams";
import { Suspense, useEffect } from "react";
import { FullScreenModalSkeleton } from "../../FullScreenModal/FullScreenModal";
import TopBarModal, {
  TopBarModalHeader,
} from "components/FullScreenModal/TopBarModal";
import { FeatureSettingsModalTypeV2 } from "../../../state/configuration";
import { modalTypeOpenAtom } from "../../../state/modal";
import { editorAccessProjectSelector } from "state/user";
import { ContentContainer } from "../Shared/styles";
import SettingsSubMenu from "../Shared/SettingsSubMenu";
import { selectedMenuItemState, unsavedSettingsState } from "../Shared/state";
import useFeatureSettings from "./Data/useFeatureSettings";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

const FeatureSettingsSubMenu = () => {
  const featureSettingsItems = useFeatureSettings();
  return (
    <SettingsSubMenu
      parentId={FeatureSettingsModalTypeV2}
      submenus={featureSettingsItems}
      depth={0}
    />
  );
};

const FeatureSettingsModalInner = () => {
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const unsavedSettings = useAtomValue(unsavedSettingsState);
  const { showConfirm } = useConfirm();

  return (
    <TopBarModal>
      <TopBarModalHeader
        title="Components"
        onClose={async () => {
          if (
            !unsavedSettings ||
            (await showConfirm({
              title: "Unsaved settings",
              message: "Are you sure you want to quit without saving?",
            }))
          ) {
            setModalTypeOpen(undefined);
          }
        }}
      />
      <ContentContainer>
        <Suspense fallback={<FullScreenModalSkeleton />}>
          <FeatureSettingsSubMenu />
        </Suspense>
      </ContentContainer>
    </TopBarModal>
  );
};

const FeatureSettingsModal = () => {
  const [modalTypeOpen, setModalTypeOpen] = useAtom(modalTypeOpenAtom);
  const editorAccessProject = useAtomValue(editorAccessProjectSelector);
  const noAccess = !editorAccessProject;
  const projectId = useAtomValue(projectIdAtom);
  const [selectedItemId, setSelectedItemId] = useAtom(
    selectedMenuItemState({
      menuId: FeatureSettingsModalTypeV2,
      projectId: projectId ?? "",
    }),
  );

  useEffect(() => {
    if (modalTypeOpen?.modalType === FeatureSettingsModalTypeV2) {
      const defaultSelection = modalTypeOpen.metadata?.selectedMenuId;
      if (defaultSelection) {
        setModalTypeOpen((curr) => {
          if (curr?.modalType === FeatureSettingsModalTypeV2) {
            return {
              ...curr,
              metadata: {
                ...curr.metadata,
                selectedMenuId: undefined,
              },
            };
          }
          return curr;
        });
        setSelectedItemId(defaultSelection);
      } else if (!selectedItemId) {
        setSelectedItemId("turbines");
      }
    }
  }, [modalTypeOpen, selectedItemId, setModalTypeOpen, setSelectedItemId]);

  if (
    noAccess ||
    projectId == null ||
    modalTypeOpen?.modalType !== FeatureSettingsModalTypeV2
  )
    return null;

  return <FeatureSettingsModalInner />;
};

export default FeatureSettingsModal;
