import styled from "styled-components";

export const FeatureCoordinateEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.2rem;
`;

export const ColorSelectorWrapper = styled.div`
  position: relative;
`;
