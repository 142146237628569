import { useAblyGeneric } from "hooks/useAblyGeneric";
import { useCallback } from "react";
import useCableTypeCrud from "hooks/useCableTypeCrud";
import { useMemo } from "react";
import { _CableType } from "services/cableTypeService";
import { InboundMessage } from "ably";
import { ABLY_CABLE_TYPE_UPDATE } from "state/ably";
import { ABLY_CABLE_TYPE_DELETE } from "state/ably";
import { z } from "zod";

export function useAblyProjectCable(projectId: string) {
  const { updateLocal, deleteLocal } = useCableTypeCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceivedUpdate = useCallback(
    (message: InboundMessage) => {
      const cable = _CableType.parse(message.data.meta);
      updateLocal(projectId, cable);
    },
    [updateLocal, projectId],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const cableId = z.string().parse(message.data.meta);
      deleteLocal(projectId, cableId);
    },
    [deleteLocal, projectId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_CABLE_TYPE_UPDATE,
        onMessageReceived: onMessageReceivedUpdate,
      },
      {
        eventName: ABLY_CABLE_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceivedUpdate, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, projectId);
}
