import FinancialIcon from "@icons/24/Financial.svg?react";
import { InnerContainer, Container, Title } from "../style";
import Tooltip from "components/General/Tooltip";
import { IconREMSize } from "styles/typography";
import { colors } from "styles/colors";
import ManageMyAccessButton from "../shared/ManageMyAccessButton";
import { tabToName } from "../shared/utils";
import { Row } from "components/General/Layout";
import { FinancialManagersPreview } from "components/Organisation/OrganisationRightSide/content/ResourceContent/tabs/Managers";

export default function FinancialImage({
  onClick,
  disabled,
  disabledText,
}: {
  onClick: () => void;
  disabled?: boolean;
  disabledText: string;
}) {
  return (
    <Container
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    >
      {disabled && <ManageMyAccessButton />}
      <Tooltip disabled={!disabled} text={disabledText}>
        <InnerContainer className="InnerContainer">
          <IconREMSize height={18} width={18} stroke={colors.blue600}>
            <FinancialIcon />
          </IconREMSize>
        </InnerContainer>
      </Tooltip>
      <Row style={{ justifyContent: "space-between" }}>
        <Title>{tabToName("financial")} </Title>
        <FinancialManagersPreview optionToAddManagers={false} />
      </Row>
    </Container>
  );
}
