import { FinanceId } from "finance/types";
import { getOperationStart, getProjectStart } from "finance/outputs";
import { GuaranteedRevenues, IRR, MarketRevenues } from "finance/outputs";
import { atom, useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { CostUnit } from "types/financial";
import { atomFamily } from "utils/jotai";
import { roundToDecimal } from "utils/utils";

const adjustment = "inflated";

export const useIRR = (id: FinanceId) => {
  const irr = useAtomValue(loadable(IRR.getIRR(id)));
  const totalRevenue = useAtomValue(loadable(IRR.getTotalRevenueMillion(id)));

  const totalGuaranteedRevenues = useAtomValue(
    loadable(GuaranteedRevenues.getTotalCostMillion({ id, adjustment })),
  );
  const guaranteedRevenuesYears = useAtomValue(
    loadable(GuaranteedRevenues.getYears(id)),
  );
  const totalMarketRevenues = useAtomValue(
    loadable(MarketRevenues.getTotalCostMillion({ id, adjustment })),
  );
  const marketRevenuesYears = useAtomValue(
    loadable(MarketRevenues.getYears(id)),
  );

  const totalCost = useAtomValue(loadable(IRR.getTotalCostMillion(id)));
  const totalCostDevex = useAtomValue(loadable(IRR.getDevexCostMillion(id)));
  const totalCostCapex = useAtomValue(loadable(IRR.getCapexCostMilion(id)));
  const totalCostOpex = useAtomValue(loadable(IRR.getOpexCostMillion(id)));
  const totalCostDecom = useAtomValue(loadable(IRR.getDecomCostMillion(id)));

  const projectStart = useAtomValue(loadable(getProjectStart(id)));
  const operationStart = useAtomValue(loadable(getOperationStart(id)));

  const exportData = useAtomValue(loadable(getExportData(id)));

  return {
    inputs: {
      projectStart,
      operationStart,
    },
    results: {
      irr,
      exportData,
      revenues: {
        total: totalRevenue,
        guaranteed: {
          total: totalGuaranteedRevenues,
          years: guaranteedRevenuesYears,
        },
        market: {
          total: totalMarketRevenues,
          years: marketRevenuesYears,
        },
      },
      costs: {
        total: totalCost,
        capex: totalCostCapex,
        opex: totalCostOpex,
        devex: totalCostDevex,
        decom: totalCostDecom,
      },
    },
  };
};

const getExportData = atomFamily((id: FinanceId) =>
  atom<
    Promise<
      {
        type: string;
        value: string;
        unit: string;
      }[]
    >
  >(async (get) => {
    const irr = await get(IRR.getIRR(id));

    const guaranteedYears = await get(GuaranteedRevenues.getYears(id));
    const marketYears = await get(MarketRevenues.getYears(id));

    const guaranteedTotal = await get(
      GuaranteedRevenues.getTotalCostMillion({ id, adjustment }),
    );
    const marketTotal = await get(
      MarketRevenues.getTotalCostMillion({ id, adjustment }),
    );

    const devex = await get(IRR.getDevexCostMillion(id));
    const capex = await get(IRR.getCapexCostMilion(id));
    const opex = await get(IRR.getOpexCostMillion(id));
    const decom = await get(IRR.getDecomCostMillion(id));

    return [
      {
        type: `Revenues ${guaranteedYears?.startYear} - ${guaranteedYears?.endYear}`,
        value: `${guaranteedTotal}`,
        unit: CostUnit.millionEuro,
      },
      {
        type: `Revenues ${marketYears?.startYear} - ${marketYears?.endYear}`,
        value: `${marketTotal}`,
        unit: CostUnit.millionEuro,
      },
      { type: "DEVEX", value: `${devex}`, unit: CostUnit.millionEuro },
      { type: "CAPEX", value: `${capex}`, unit: CostUnit.millionEuro },
      { type: "OPEX", value: `${opex}`, unit: CostUnit.millionEuro },
      {
        type: "DECOM",
        value: `${decom}`,
        unit: CostUnit.millionEuro,
      },
      {
        type: "IRR",
        value: "ok" in irr ? `${roundToDecimal(irr.ok * 100, 2)}` : "-",
        unit: "%",
      },
    ];
  }),
);
