import { useCallback, useMemo } from "react";
import { ABLY_ANALYSIS_DELETE, ABLY_ANALYSIS_UPDATE } from "../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../useAblyGeneric";
import { _AnalysisConfiguration } from "../../services/configurationService";
import useConfigurationCrud from "../useConfigurationCrud";
import { z } from "zod";

export function useAblyProjectAnalysisConfiguration(projectId: string) {
  const { updateLocal, deleteLocal } = useConfigurationCrud();

  const channelName = useMemo(() => `${projectId}:all`, [projectId]);

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const analysisConfiguration = _AnalysisConfiguration.parse(
        message.data.meta,
      );
      updateLocal(projectId, analysisConfiguration);
    },
    [updateLocal, projectId],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const analysisConfigurationId = z.string().parse(message.data.meta);
      deleteLocal(projectId, analysisConfigurationId);
    },
    [deleteLocal, projectId],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_ANALYSIS_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_ANALYSIS_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, projectId);
}
