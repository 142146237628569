import { useAtomValue } from "jotai";
import { validate as uuidValidate } from "uuid";
import { organisationIdAtom } from "state/pathParams";
import {
  Fragment,
  RefObject,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import FolderAdd from "@icons/16/FolderAdd.svg";
import { modalTypeOpenAtom } from "../../state/modal";
import { savingNewProjectAtom } from "../../state/project";
import useTextInput from "../../hooks/useTextInput";
import {
  userNodeAccessSelectorFamily,
  memberInOrganisationSelectorFamily,
} from "../../state/user";
import {
  TeamListItemPlaceholder,
  TeamsListWrapper,
  WrapperFullScreen,
} from "../Projects/styles";
import ListViewIcon from "@icons/16/ListView.svg";
import SectionIcon from "@icons/24/Section.svg";
import { nodeIdAtom } from "../../state/pathParams";
import { colors } from "../../styles/colors";
import { SkeletonBlock } from "../Loading/Skeleton";
import { spaceHuge, spacing4, spacing9 } from "../../styles/space";
import Button from "../General/Button";
import { Node } from "../../services/customerAPI";
import { Column, Row } from "../General/Layout";
import { MenuItem } from "../General/Menu";
import Tooltip, { WithTooltip } from "../General/Tooltip";
import { ProjectGrid } from "../Projects/ProjectGrid";
import { ProjectListView } from "../Projects/ProjectListView";
import { EditableTextInternalState } from "../General/EditableText";
import { range } from "../../utils/utils";
import { organisationsState } from "./state";
import ResizeBarVertical from "components/ResizeBar/ResizeBarVertical";
import { useLocalStorage } from "../../hooks/useBrowserStorage";
import OrgSettingsModal from "./OrgSettings/OrgSettingsModal";
import { useOrganisationNodeCrud } from "../Projects/useOrganisationFolderCrud";
import LowerRightHelpOnly from "../LowerRight/LowerRightHelp";
import FolderProjectList from "./FolderProjectList";
import { OrganisationPageBodyWrapper, TabHeader } from "./style";
import { UserNodesAccess } from "types/user";
import { useDndScrolling } from "react-dnd-scrolling";
import OpenMoreInfo from "components/General/MoreInfoButton";
import OrganisationRightSide from "./OrganisationRightSide/OrganisationRightSide";
import { organisationRightSideModal } from "./OrganisationRightSide/state";
import { ProjectPageTitleBar } from "./ProjectPageTitleBar";
import GoBack from "./Library/shared/GoBack";
import SplitButton from "components/General/SplitButton";
import useFolderId from "hooks/useFolderId";
import { DashText, FolderNavBar } from "components/Projects/ProjectGrid.style";
import { useDrop } from "react-dnd";
import { VerticalDivider } from "components/General/VerticalDivider";
import TwoPersonsIcon from "@icons/24/TwoPersons.svg";
import { IconREMSize, typography } from "styles/typography";
import { NodeAccessList } from "components/Projects/NodeAccessList";
import { FolderDotMenu } from "components/Projects/FolderDotMenu";
import { useAtom, useSetAtom } from "jotai";
import { getNodeName, getParentNodeIds } from "components/Organisation/utils";
import NewItemModal from "components/NewItemModal/NewItemModal";
import Spinner from "@icons/spinner/Spinner";
import { getFolderParentChain } from "business/customer/utils";
import eventEmitter from "utils/eventEmitter";
import { moveFolderIdAtom, moveProjectIdAtom } from "./MoveNodeModal/state";
import MoveFolderModal from "./MoveNodeModal/MoveFolderModal";
import MoveProjectModal from "./MoveNodeModal/MoveProjectModal";
import MoveNodeAccessWarningModal from "./MoveNodeAccessWarningModal/MoveNodeAccessWarningModal";
import { MOVE_NODE_ACCESS_WARNING_EVENT_CANCEL } from "./MoveNodeAccessWarningModal/MoveNodeAccessWarningModal";
import { unsavedSettingsState } from "components/SettingsV2/Shared/state";
import { DesignToolMode } from "types/map";
import NewItemProjectTypeModal from "components/NewItemProjectTypeModal/NewItemProjectTypeModal";
import {
  offshoreAccessSelectorFamily,
  onshoreAccessSelectorFamily,
} from "state/featureAccess";
import { useCheckAccessToken } from "hooks/useRefreshTokenBeforeExpiration";
import Loader from "@icons/full-screen-loader.svg";
import SearchBarOrganisation from "components/Search/SearchBar/SearchBarOrganisation";
import IconFlipSwitch from "components/General/IconFlipSwitch";
import { IconBtn } from "components/General/Icons";
import {
  NoProjectsInPersonalFolderMessage,
  NoProjectsInRootMessage_Guest,
  NoProjectsInRootMessage_Member,
} from "./empty-messages";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import { ORGANISATION_PAGE_PATHS } from "@constants/path";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { useUserAccessState } from "components/Projects/useUserAccessState";
import { ScrollBody } from "hooks/useShowScrollShadow";
import { selectedLibraryTabAtom } from "state/library";

const useCreateProject = (organisationId?: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createProject } = useOrganisationNodeCrud();
  const { data: userAccess, setState: setUserAccess } = useUserAccessState();
  const refreshAccessToken = useCheckAccessToken();

  const onCreateProject = useCallback(
    async (
      projectName: string,
      projectType: DesignToolMode,
      nodeId?: string,
    ) => {
      if (!nodeId) return;
      const res = await createProject({
        name: projectName,
        parent_id: nodeId,
        type: "project",
        project_type: projectType,
      });
      if (res) {
        const newAllNodesAccess = {
          organisation_access: userAccess["organisation_access"],
          node_access: {
            ...userAccess["node_access"],
            [res.id]: "admin",
          },
        } as UserNodesAccess;
        setUserAccess({
          data: newAllNodesAccess,
          initialised: true,
          loading: false,
        });
        await refreshAccessToken(true);
        navigate(
          `/${projectType === DesignToolMode.Offshore ? "design" : "onshore"}/project/${organisationId}/${res.id}${location.search}`,
        );
      }
    },
    [
      createProject,
      userAccess,
      setUserAccess,
      refreshAccessToken,
      navigate,
      organisationId,
      location.search,
    ],
  );
  return onCreateProject;
};

const FolderNavigation = ({ nodes }: { nodes: Node[] }) => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const folderId = useFolderId();
  const setContent = useSetAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const location = useLocation();

  const selectedFolder = useMemo(
    () => nodes.find((f) => f.id === folderId),
    [nodes, folderId],
  );

  const parentChain = useMemo(
    () => getFolderParentChain(nodes, selectedFolder),
    [nodes, selectedFolder],
  );

  return (
    <FolderNavBar>
      <Link
        to={`/organisation/${organisationId}/projects`}
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          setContent((curr) =>
            curr
              ? {
                  type: "no-item-selected",
                }
              : undefined,
          );
        }}
      >
        <Button
          size={"small"}
          style={{
            padding: 0,
          }}
          buttonType="text"
          text={"All projects"}
          onClick={() => {}}
        />
      </Link>
      {parentChain.map((node) => (
        <Fragment key={node.id}>
          <DashText>{" / "}</DashText>
          <Link
            key={node.id}
            style={{
              textDecoration: "none",
            }}
            to={`/organisation/${organisationId}/projects/${node.id}${location.search}`}
            onClick={() => {
              setContent((curr) =>
                typeof curr !== "undefined"
                  ? {
                      type: "project",
                      id: node.id,
                    }
                  : curr,
              );
            }}
          >
            <NavigationButton node={node} />
          </Link>
        </Fragment>
      ))}
    </FolderNavBar>
  );
};

function NavigationButton({ node }: { node: Node }) {
  const { move: moveNode } = useOrganisationNodeCrud();
  const [{ isOver }, dropRef] = useDrop({
    accept: ["PROJECT", "FOLDER"],
    drop: (item: { id: string; currentFolder?: Node }) => {
      if (item.currentFolder?.id === node.id) return;
      if (item.id === node.id) return;
      if (item.currentFolder) {
        moveNode(item.id, item.currentFolder.id);
      }
      moveNode(item.id, node.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={dropRef}
      style={
        isOver
          ? {
              backgroundColor: colors.hover,
            }
          : {}
      }
    >
      <Button
        size={"small"}
        style={{
          padding: 0,
        }}
        buttonType="text"
        text={getNodeName(node)}
        onClick={() => {}}
      />
    </div>
  );
}

const FullScreenNewProjectLoader = () => {
  const savingNewProject = useAtomValue(savingNewProjectAtom);

  if (!savingNewProject) return null;

  return (
    <WrapperFullScreen>
      <Loader />
      <h2>Creating new project...</h2>
    </WrapperFullScreen>
  );
};

const ProjectFolderListPlaceholder = () => (
  <>
    {range(0, 5).map((i) => (
      <TeamListItemPlaceholder key={i}>
        <SkeletonBlock
          style={{
            height: "2rem",
            width: "100%",
          }}
        />
      </TeamListItemPlaceholder>
    ))}
  </>
);

const TeamsList = ({ organisationId }: { organisationId: string }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  useDndScrolling(elementRef, {});

  return (
    <ScrollBody ref={elementRef}>
      <FolderProjectList organisationId={organisationId} />
    </ScrollBody>
  );
};

const ActiveViewSwitch = ({
  activeTabId,
  setActiveTabId,
}: {
  activeTabId: number | undefined;
  setActiveTabId: (tabId: number) => void;
}) => {
  return (
    <IconFlipSwitch>
      <WithTooltip text="Grid view">
        <IconBtn
          id="button-project-view-grid"
          data-active={activeTabId === 0}
          size="1.2rem"
          active={activeTabId === 0}
          onClick={() => setActiveTabId(0)}
        >
          <SectionIcon />
        </IconBtn>
      </WithTooltip>
      <WithTooltip text="List view">
        <IconBtn
          data-active={activeTabId === 1}
          id="button-project-view-list"
          size="1.2rem"
          active={activeTabId === 1}
          onClick={() => setActiveTabId(1)}
        >
          <ListViewIcon />
        </IconBtn>
      </WithTooltip>
    </IconFlipSwitch>
  );
};

const ProjectGridOrList = ({
  organisationId,
  createProject,
}: {
  organisationId: string;
  createProject: (
    projectName: string,
    projectType: DesignToolMode,
    nodeId?: string,
  ) => void;
}) => {
  const nodeId = useAtomValue(nodeIdAtom);
  const isOrganisationLevel = organisationId && !nodeId;
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId,
    }),
  );
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [moveFolderId, setMoveFolderId] = useAtom(moveFolderIdAtom);
  const [moveProjectId, setMoveProjectId] = useAtom(moveProjectIdAtom);

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId,
    }),
  );
  const isAdminInNode = nodeAccess >= 2;

  const canCreateProjectHere =
    isMemberInOrg &&
    (isOrganisationLevel || (!isOrganisationLevel && isAdminInNode));
  const canCreateFolderHere = isMemberInOrg && isOrganisationLevel;

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId),
  );

  const roleAsString = "admin";

  const { create: createNodeOrg, move: moveNode } = useOrganisationNodeCrud();

  const { data: userAccess, setState: setUserAccess } = useUserAccessState();
  const [folderLoading, setFolderLoading] = useState(false);

  const createFolder = useCallback(
    async (folderName: string) => {
      const parentId = isOrganisationLevel ? organisationId : nodeId;
      if (!parentId) {
        return;
      }
      setFolderLoading(true);
      return createNodeOrg({
        name: folderName,
        parent_id: parentId,
        type: "folder",
      })
        .then((res) => {
          if (res) {
            const newAllNodesAccess = {
              organisation_access: userAccess["organisation_access"],
              node_access: {
                ...userAccess["node_access"],
                [res.id]: roleAsString,
              },
            } as UserNodesAccess;
            setUserAccess({
              data: newAllNodesAccess,
              initialised: true,
              loading: false,
            });
            setContent({
              type: "project",
              id: res.id,
            });
          }
        })
        .finally(() => setFolderLoading(false));
    },
    [
      isOrganisationLevel,
      organisationId,
      nodeId,
      createNodeOrg,
      userAccess,
      setUserAccess,
      setContent,
    ],
  );

  const [tab, setTab] = useLocalStorage("vind:selected-project-tab", 0);

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId);

  const chosenNode = useMemo(
    () => nodes.find((n) => n.id === nodeId),
    [nodes, nodeId],
  );

  const isInRootAndHasNoOtherNodesThanPersonal = useMemo(() => {
    if (chosenNode) {
      return false;
    }

    const personalFolder = nodes.find(
      (node) => node.type === "personal_folder",
    );

    if (!personalFolder) {
      return nodes.length === 0;
    }

    const nodesOutsidePersonalFolder = nodes.filter(
      (node) =>
        personalFolder.id !== node.id &&
        !getParentNodeIds(nodes, node.id).some(
          (id) => personalFolder.id === id,
        ),
    );

    return nodesOutsidePersonalFolder.length === 0;
  }, [chosenNode, nodes]);

  const isInPersonalFolderAndHasOnlyTutorialProject = useMemo(
    () =>
      Boolean(
        chosenNode &&
          chosenNode.type === "personal_folder" &&
          nodes
            .filter((node) => node.parent_id === chosenNode.id)
            .every((node) => node.tutorial_id),
      ),
    [chosenNode, nodes],
  );

  useEffect(() => {
    setContent((cur) => (typeof cur !== "undefined" ? cur : undefined));
  }, [setContent]);

  useEffect(() => {
    const onPopState = () => {
      const nodeId = window.location.pathname.split("/").pop();

      if (nodeId === organisationId) {
        setContent((curr) =>
          typeof curr !== "undefined"
            ? {
                type: "no-item-selected",
              }
            : undefined,
        );
      } else if (nodeId && uuidValidate(nodeId)) {
        setContent((curr) =>
          typeof curr !== "undefined"
            ? {
                type: "project",
                id: nodeId,
              }
            : curr,
        );
      } else {
        setContent((curr) =>
          typeof curr !== "undefined"
            ? {
                type: "no-item-selected",
              }
            : curr,
        );
      }
    };

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [organisationId, setContent]);

  return (
    <>
      {moveFolderId && (
        <MoveFolderModal
          nodeId={moveFolderId}
          onClose={() => setMoveFolderId(undefined)}
          onMoveClick={async (toFolderNode) => {
            const didMove = await moveNode(moveFolderId, toFolderNode.id);
            setMoveFolderId(undefined);
            // If the access change warning modal is shown, we want to show this move modal again if the user doesnt accept
            // the access changes
            if (!didMove) {
              eventEmitter.once(MOVE_NODE_ACCESS_WARNING_EVENT_CANCEL, () => {
                setMoveFolderId(moveFolderId);
              });
            }
          }}
        />
      )}
      {moveProjectId && (
        <MoveProjectModal
          nodeId={moveProjectId}
          onClose={() => setMoveProjectId(undefined)}
          onMoveClick={async (toFolderNode) => {
            const didMove = await moveNode(moveProjectId, toFolderNode.id);
            setMoveProjectId(undefined);
            // // If the access change warning modal is shown, we want to show this move modal again if the user doesnt accept
            // // the access changes
            if (!didMove) {
              eventEmitter.once(MOVE_NODE_ACCESS_WARNING_EVENT_CANCEL, () => {
                setMoveProjectId(moveProjectId);
              });
            }
          }}
        />
      )}
      {showNewFolderModal && (
        <NewItemModal
          title="Create new folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onClose={() => {
            setShowNewFolderModal(false);
          }}
          onSubmit={createFolder}
        />
      )}
      {showNewProjectModal && (
        <NewItemProjectTypeModal
          title="Create new project"
          placeholder="Enter project name"
          defaultValue=""
          onSubmit={(newName, projectType) =>
            createProject(
              newName,
              projectType,
              isOrganisationLevel ? organisationId : nodeId,
            )
          }
          onClose={() => {
            setShowNewProjectModal(false);
          }}
        />
      )}
      {!chosenNode && (
        <>
          <Row
            style={{
              justifyContent: "space-between",
            }}
          >
            <TabHeader>All projects</TabHeader>
            <Row>
              {canCreateFolderHere && (
                <Tooltip text="Create a new folder">
                  <Button
                    buttonType="secondary"
                    icon={<FolderAdd />}
                    disabled={folderLoading}
                    onClick={() => setShowNewFolderModal(true)}
                  />
                </Tooltip>
              )}
              {canCreateProjectHere && (
                <Tooltip text={"Create new project"}>
                  <Button
                    text={"New project"}
                    onClick={() => setShowNewProjectModal(true)}
                  />
                </Tooltip>
              )}

              <VerticalDivider
                height={3}
                style={{
                  margin: `0 ${spacing4}`,
                }}
              />
              {isMemberInOrg && (
                <OpenMoreInfo
                  onClick={() => {
                    setContent({
                      type: "no-item-selected",
                    });
                  }}
                  isOpen={!!content}
                />
              )}
            </Row>
          </Row>
        </>
      )}
      <Column
        style={{
          position: "relative",
          gap: chosenNode ? "3.2rem" : "0.8rem",
        }}
      >
        {isInPersonalFolderAndHasOnlyTutorialProject && (
          <span style={{ marginTop: spacing4, width: "fit-content" }}>
            <NoProjectsInPersonalFolderMessage />
          </span>
        )}
        {isInRootAndHasNoOtherNodesThanPersonal && isMemberInOrg && (
          <span style={{ marginTop: spacing4, width: "fit-content" }}>
            <NoProjectsInRootMessage_Member />
          </span>
        )}

        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: "0.6rem",
          }}
        >
          <Row alignCenter>
            {nodeId && (
              <>
                {isMemberInOrg && (
                  <Suspense fallback={<Spinner size="1.2rem" />}>
                    <NodeAccessList
                      maxNumberOfUsers={6}
                      nodeId={nodeId}
                      organisationId={organisationId}
                    />
                  </Suspense>
                )}
                {chosenNode?.type === "personal_folder" ? (
                  <p
                    style={{
                      ...typography.caption,
                      color: colors.textSecondary,
                    }}
                  >
                    Only you
                  </p>
                ) : (
                  <Tooltip text="Add or remove collaborators">
                    <Button
                      buttonType="secondary"
                      size={"small"}
                      icon={
                        <IconREMSize height={1.4} width={1.4}>
                          <TwoPersonsIcon />
                        </IconREMSize>
                      }
                      onClick={
                        isMemberInOrg && nodeId
                          ? () =>
                              setContent({
                                type: "project",
                                id: nodeId,
                                meta: {
                                  openAddCollaborators: true,
                                },
                              })
                          : undefined
                      }
                    />
                  </Tooltip>
                )}
              </>
            )}
          </Row>
          <ActiveViewSwitch activeTabId={tab} setActiveTabId={setTab} />
        </Row>
        <Column
          style={{
            position: "relative",
            maxHeight: chosenNode ? "78vh" : "82vh",
            overflowY: "auto",
          }}
        >
          {tab === 0 ? (
            <ProjectGrid
              openNewProjectModal={() => setShowNewProjectModal(true)}
              canCreateProjectHere={canCreateProjectHere}
            />
          ) : (
            <ProjectListView />
          )}
        </Column>
        {isInRootAndHasNoOtherNodesThanPersonal && !isMemberInOrg && (
          <Row justifyCenter={true}>
            <NoProjectsInRootMessage_Guest />
          </Row>
        )}
      </Column>
    </>
  );
};

export const CustomerBodyPlaceholder = () => (
  <Column
    style={{
      minHeight: 0,
    }}
  >
    <Row
      style={{
        padding: spaceHuge,
      }}
    >
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "20rem",
        }}
      />
    </Row>
  </Column>
);

export const OrganisationBodyWrapperOldRedirect = () => {
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const organisationId = params.organisationId;
    if (!organisationId) return;
    const pagePath = ORGANISATION_PAGE_PATHS.find(
      (pg) => pg.toLocaleLowerCase() === page?.toLowerCase(),
    );
    const nodeId = params.nodeId;
    if (!pagePath && !nodeId) {
      navigate(`/organisation/${organisationId}/projects`, {
        replace: true,
      });
      return;
    }
    if (nodeId) {
      navigate(`/organisation/${organisationId}/projects/${nodeId}`);
      return;
    }
    navigate(`/organisation/${organisationId}/${pagePath}`);
  }, [navigate, page, params.nodeId, params.organisationId]);

  return null;
};

export const OrganisationBody = () => {
  const selectedTab = useAtomValue(selectedLibraryTabAtom);

  return (
    <Column
      style={{
        minHeight: 0,
        padding: `${spacing9} ${spacing9} 0`,
        gap: "0.4rem",
        overflow: "hidden",
      }}
    >
      {!!selectedTab && <LibraryHeader />}
      <div
        style={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Outlet />
      </div>
      <LowerRightHelpOnly />
    </Column>
  );
};

const LibraryHeader = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const navigate = useNavigate();
  const { showConfirm } = useConfirm();

  const setContent = useSetAtom(organisationRightSideModal(organisationId));

  const [unsavedSettings, setUnsavedSettings] = useAtom(unsavedSettingsState);

  return (
    <GoBack
      text={"Library"}
      onClick={async () => {
        if (
          !unsavedSettings ||
          (await showConfirm({
            title: "Unsaved settings",
            message: "Are you sure you want to quit without saving?",
          }))
        ) {
          setUnsavedSettings(false);
          setContent(undefined);
          navigate(`/organisation/${organisationId}/library`);
        } else {
          setContent(undefined);
          navigate(`/organisation/${organisationId}/library`);
        }
      }}
    />
  );
};

export const CustomerBody = () => {
  const organisationId = useAtomValue(organisationIdAtom);
  const offshoreAccess = useAtomValue(
    offshoreAccessSelectorFamily({ organisationId }),
  );
  const onshoreAccess = useAtomValue(
    onshoreAccessSelectorFamily({ organisationId }),
  );
  const nodeId = useAtomValue(nodeIdAtom);
  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId: organisationId,
    }),
  );

  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });

  const [content, setContent] = useAtom(
    organisationRightSideModal(organisationId ?? ""),
  );

  const { loadedState: nodes } = useNodesInOrganisationState(organisationId!);

  const chosenNode = useMemo(
    () => nodes.find((n) => n.id === nodeId),
    [nodes, nodeId],
  );

  const nodeAccess = useAtomValue(
    userNodeAccessSelectorFamily({
      nodeId,
    }),
  );
  const isNodeAdmin = nodeAccess >= 2;
  const navigate = useNavigate();
  const location = useLocation();
  const roleAsString = "admin";

  useEffect(() => {
    if (chosenNode && chosenNode.type === "project") {
      const url =
        chosenNode.parent_id === organisationId
          ? ""
          : `/${chosenNode.parent_id}`;
      navigate(`/organisation/${organisationId}/projects${url}`);
    }
  }, [chosenNode, navigate, organisationId]);

  const {
    create: createNodeOrg,
    createOffshoreTutorialProject,
    createOnshoreTutorialProject,
  } = useOrganisationNodeCrud();

  const [folderLoading, setFolderLoading] = useState(false);

  const { data: userAccess, setState: setUserAccess } = useUserAccessState();

  const isOrganisationLevel = organisationId && !nodeId;

  const canCreateFolderHere =
    isMemberInOrg &&
    (isOrganisationLevel || (!isOrganisationLevel && isNodeAdmin));
  const canCreateProjectHere =
    !isOrganisationLevel && isMemberInOrg && isNodeAdmin;

  const [didCreate, setDidCreate] = useState(false);

  const createAndEnterOffshoreTutorialProject = useCallback(async () => {
    if (!nodeId) return;
    setDidCreate(true);
    const res = await createOffshoreTutorialProject(nodeId);
    if (res) {
      const newAllNodesAccess = {
        organisation_access: userAccess["organisation_access"],
        node_access: {
          ...userAccess["node_access"],
          [res.id]: roleAsString,
        },
      } as UserNodesAccess;
      setUserAccess({
        data: newAllNodesAccess,
        initialised: true,
        loading: false,
      });
      navigate(`/design/project/${organisationId}/${res.id}${location.search}`);
    }
  }, [
    nodeId,
    createOffshoreTutorialProject,
    userAccess,
    roleAsString,
    setUserAccess,
    navigate,
    organisationId,
    location.search,
  ]);

  const createAndEnterOnshoreTutorialProject = useCallback(async () => {
    if (!nodeId) return;
    setDidCreate(true);
    const res = await createOnshoreTutorialProject(nodeId);
    if (res) {
      const newAllNodesAccess = {
        organisation_access: userAccess["organisation_access"],
        node_access: {
          ...userAccess["node_access"],
          [res.id]: roleAsString,
        },
      } as UserNodesAccess;
      setUserAccess({
        data: newAllNodesAccess,
        initialised: true,
        loading: false,
      });
      navigate(`/design/project/${organisationId}/${res.id}${location.search}`);
    }
  }, [
    nodeId,
    createOnshoreTutorialProject,
    userAccess,
    roleAsString,
    setUserAccess,
    navigate,
    organisationId,
    location.search,
  ]);

  const createProject = useCreateProject(organisationId);

  const createFolder = useCallback(
    async (folderName: string) => {
      const parentId = isOrganisationLevel ? organisationId : nodeId;
      if (!parentId) {
        return;
      }
      setFolderLoading(true);
      return createNodeOrg({
        name: folderName,
        parent_id: parentId,
        type: "folder",
      })
        .then((res) => {
          if (res) {
            const newAllNodesAccess = {
              organisation_access: userAccess["organisation_access"],
              node_access: {
                ...userAccess["node_access"],
                [res.id]: roleAsString,
              },
            } as UserNodesAccess;
            setUserAccess({
              data: newAllNodesAccess,
              initialised: true,
              loading: false,
            });
            setContent({
              type: "project",
              id: res.id,
            });
          }
        })
        .finally(() => setFolderLoading(false));
    },
    [
      isOrganisationLevel,
      organisationId,
      nodeId,
      createNodeOrg,
      userAccess,
      setUserAccess,
      setContent,
    ],
  );

  const { update } = useOrganisationNodeCrud();

  const isPersonalFolder = chosenNode?.type === "personal_folder";

  const crudNode = useOrganisationNodeCrud();
  const folderTitle = useMemo(() => getNodeName(chosenNode), [chosenNode]);

  const [writtenTitle, onWrittenTitleChange, setWrittenTitle] =
    useTextInput(folderTitle);

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);

  const updateFolderCallback = useCallback(
    async (newTitle: string) => {
      if (!chosenNode) return;
      await update(chosenNode.id, {
        name: newTitle,
      });
    },
    [chosenNode, update],
  );
  useEffect(() => {
    setWrittenTitle(folderTitle);
  }, [folderTitle, setWrittenTitle]);

  const NewProjectItem = () => (
    <MenuItem
      name={"New project"}
      onClick={() => {
        setShowNewProjectModal({
          visible: true,
        });
      }}
      disabled={didCreate}
      defaultButton
    />
  );

  return (
    <Column
      style={{
        minHeight: 0,
        paddingBottom: 0,
        gap: "0rem",
      }}
    >
      {showNewFolderModal && (
        <NewItemModal
          title="Create new folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onClose={() => {
            setShowNewFolderModal(false);
          }}
          onSubmit={createFolder}
        />
      )}
      {showNewProjectModal?.visible && organisationId && (
        <NewItemProjectTypeModal
          title="Create new project"
          placeholder="Enter project name"
          defaultValue=""
          onClose={() => {
            setShowNewProjectModal({ ...showNewProjectModal, visible: false });
          }}
          onSubmit={(newName, projectType) =>
            createProject(newName, projectType, nodeId)
          }
        />
      )}
      {chosenNode && <FolderNavigation nodes={nodes} />}
      {chosenNode && (
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
            }}
          >
            {isNodeAdmin ? (
              <EditableTextInternalState
                disabled={true}
                onChange={onWrittenTitleChange}
                value={writtenTitle}
                renderText={(text) => (
                  <h2
                    style={{
                      margin: 0,
                    }}
                  >
                    {text}
                  </h2>
                )}
                onEnter={async (newTitle) => {
                  if (!chosenNode) return;
                  await Promise.all([
                    update(chosenNode.id, {
                      name: newTitle,
                    }),
                    updateFolderCallback(newTitle),
                  ]);
                }}
                textContainerStyle={{
                  padding: 0,
                }}
                onCancel={async (newTitle) => {
                  if (!chosenNode) return;
                  await Promise.all([
                    update(chosenNode.id, {
                      name: newTitle,
                    }),
                    updateFolderCallback(newTitle),
                  ]);
                }}
                isEditing={isEditingTitle}
                onAfter={() => {
                  setIsEditingTitle(false);
                }}
              />
            ) : (
              <h2
                style={{
                  margin: 0,
                }}
              >
                {chosenNode?.name}
              </h2>
            )}
            {chosenNode && (
              <FolderDotMenu
                setIsEditingTitle={(isEditing) => {
                  setIsEditingTitle(isEditing);
                }}
                currentFolder={chosenNode}
                isPersonalFolder={isPersonalFolder}
                folderId={chosenNode.id}
                nodes={nodes}
                crudNode={crudNode}
                useArrowIcon={true}
                size="small"
              />
            )}
          </div>
          <Row
            style={{
              alignItems: "center",
            }}
          >
            <Tooltip
              text={
                canCreateFolderHere
                  ? "Create a new folder"
                  : "You don't have permission to create a new folder here"
              }
            >
              <Button
                buttonType="secondary"
                icon={<FolderAdd />}
                disabled={!canCreateFolderHere || folderLoading}
                onClick={() => setShowNewFolderModal(true)}
              />
            </Tooltip>
            {isPersonalFolder && (
              <SplitButton
                text={"New project"}
                onClick={() =>
                  setShowNewProjectModal({
                    visible: true,
                  })
                }
                disabled={didCreate}
                icon={didCreate ? <Spinner size="1rem" /> : undefined}
              >
                <NewProjectItem />
                {offshoreAccess && (
                  <MenuItem
                    name={"New tutorial project (offshore)"}
                    onClick={createAndEnterOffshoreTutorialProject}
                    disabled={didCreate}
                    description={
                      "Create a tutorial project with a guided tour."
                    }
                  />
                )}
                {onshoreAccess && (
                  <MenuItem
                    name={"New tutorial project (onshore)"}
                    onClick={createAndEnterOnshoreTutorialProject}
                    disabled={didCreate}
                    description={
                      "Create an onshore tutorial project with a guided tour."
                    }
                  />
                )}
              </SplitButton>
            )}
            {!isPersonalFolder && (
              <Tooltip
                text={
                  canCreateProjectHere
                    ? "Create new project"
                    : "You don't have permission to create a new project here"
                }
              >
                <Button
                  id={"main-new-project-button"}
                  text={"New project"}
                  onClick={() =>
                    setShowNewProjectModal({
                      visible: true,
                    })
                  }
                  disabled={!canCreateProjectHere || didCreate}
                  icon={didCreate ? <Spinner size="1rem" /> : undefined}
                />
              </Tooltip>
            )}
            <VerticalDivider
              height={3}
              style={{
                margin: `0 ${spacing4}`,
              }}
            />
          </Row>
          {isMemberInOrg && nodeId && (
            <OpenMoreInfo
              isOpen={!!content}
              onClick={() => {
                setContent((cur) => {
                  if (cur && cur.id === nodeId) return undefined;
                  return {
                    type: "project",
                    id: nodeId,
                  };
                });
              }}
            />
          )}
        </Row>
      )}
      {organisationId && (
        <ProjectGridOrList
          organisationId={organisationId}
          createProject={createProject}
        />
      )}
      <LowerRightHelpOnly />
    </Column>
  );
};

const OrganisationPageBody = () => {
  const gridRef = useRef<HTMLDivElement>(null);
  const organisationId = useAtomValue(organisationIdAtom);

  const content = useAtomValue(
    organisationRightSideModal(organisationId ?? ""),
  );

  const selectedLibraryTab = useAtomValue(selectedLibraryTabAtom);

  const isMemberInOrg = useAtomValue(
    memberInOrganisationSelectorFamily({
      organisationId: organisationId,
    }),
  );

  const gridTemplateColumnsLib = `1fr ${isMemberInOrg && content ? "calc(100vw - 45rem)" : "0rem"}`;

  const gridTemplateColumnsNormal = `${"var(--organisation-page-left-menu-width, 25rem)"} 1fr ${isMemberInOrg && content ? "45rem" : "0rem"}`;

  return (
    <>
      <OrganisationPageBodyWrapper
        ref={gridRef}
        style={{
          gridTemplateColumns: selectedLibraryTab
            ? gridTemplateColumnsLib
            : gridTemplateColumnsNormal,
        }}
      >
        {!selectedLibraryTab && <TeamList gridRef={gridRef} />}
        <Outlet />
        {organisationId && isMemberInOrg && <OrganisationRightSide />}
      </OrganisationPageBodyWrapper>
      <MoveNodeAccessWarningModal />
      <OrgSettingsModal />
      {organisationId && (
        <SearchBarOrganisation organisationId={organisationId} />
      )}
    </>
  );
};

const TeamList = ({ gridRef }: { gridRef: RefObject<HTMLDivElement> }) => {
  const organisations = useAtomValue(organisationsState);
  const elementRef = useRef<HTMLDivElement>(null);
  const organisationId = useAtomValue(organisationIdAtom);

  const currentOrg = organisations.find((org) => org.id === organisationId);

  const onBeforeResize = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.style.transition = "unset";
    }
  }, [gridRef]);

  const onAfterResize = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.style.transition = "";
    }
    eventEmitter.emit("organisation-left-menu-resize");
  }, [gridRef]);

  return (
    <TeamsListWrapper ref={elementRef}>
      {currentOrg && (
        <Suspense fallback={<ProjectFolderListPlaceholder />}>
          {organisationId && <TeamsList organisationId={currentOrg.id} />}
        </Suspense>
      )}
      <ResizeBarVertical
        resizeElemRef={elementRef}
        barSide="RIGHT"
        style={{
          position: "absolute",
          right: "-8px",
          top: 0,
        }}
        cssVariableToUpdate="--organisation-page-left-menu-width"
        onBeforeResize={onBeforeResize}
        onAfterResize={onAfterResize}
      />
    </TeamsListWrapper>
  );
};

const OrganisationPage = () => {
  const setOpenModal = useSetAtom(modalTypeOpenAtom);
  useEffect(() => {
    setOpenModal(undefined);
  }, [setOpenModal]);

  return (
    <>
      <Column
        style={{
          height: "100vh",
          gap: 0,
        }}
      >
        <ProjectPageTitleBar />
        <OrganisationPageBody />
      </Column>
      <FullScreenNewProjectLoader />
    </>
  );
};

export default OrganisationPage;
