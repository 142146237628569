import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { FoundationType } from "./../../../../../types/foundations";

import {
  addOrUpdateResourceOnNode,
  deleteResourceOnNode,
} from "components/Organisation/Library/service";
import {
  foundationResourceWithAccessOnNodeState,
  nodesWithAccessToFoundation,
} from "components/Organisation/Library/state";
import { userNodesAccessSyncAtom } from "state/user";
import { RESET } from "jotai/utils";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { fetchProjectResourceUsage } from "services/usageService";

const useNodeFoundationResourcesCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { showConfirm } = useConfirm();

  const addOrUpdate = useJotaiCallback(
    async (get, set, nodeId: string, foundation: FoundationType) => {
      const fallback = await get(
        foundationResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const userAccess = get(userNodesAccessSyncAtom).data;

      return addOrUpdateResourceOnNode(
        nodeId,
        foundation.id,
        "org_foundation_manage",
      )
        .then(() => {
          Object.keys(userAccess.node_access).map((nodeId) =>
            set(
              foundationResourceWithAccessOnNodeState({
                nodeId,
              }),
              RESET,
            ),
          );
          set(
            nodesWithAccessToFoundation({
              organisationId,
              resourceId: foundation.id,
            }),
          );
        })
        .catch((e) => {
          aset(
            get,
            set,
            foundationResourceWithAccessOnNodeState({
              nodeId,
            }),
            () => fallback,
          );
          throw e;
        });
    },
    [organisationId],
  );

  const remove = useJotaiCallback(
    async (get, set, nodeId: string, resourceId: string) => {
      const fallback = await get(
        foundationResourceWithAccessOnNodeState({
          nodeId,
        }),
      );
      const cachedUsage = await get(
        projectResourceUsageAtomFamily({
          nodeId,
          resourceType: "FOUNDATION",
          resourceId,
        }),
      );
      let usage = cachedUsage;
      if (usage.length === 0) {
        usage = await fetchProjectResourceUsage(
          nodeId,
          "FOUNDATION",
          resourceId,
        );
      }
      const userAccess = get(userNodesAccessSyncAtom).data;

      if (
        usage.length === 0 ||
        (await showConfirm({
          title: "Remove access",
          message: `Foundation type is currently being used in ${usage.length} foundations, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return deleteResourceOnNode(nodeId, resourceId, "org_foundation_manage")
          .then(() => {
            Object.keys(userAccess.node_access).map((nodeId) =>
              set(
                foundationResourceWithAccessOnNodeState({
                  nodeId,
                }),
                RESET,
              ),
            );
            set(
              nodesWithAccessToFoundation({
                organisationId,
                resourceId,
              }),
            );
          })
          .catch((e) => {
            aset(
              get,
              set,
              foundationResourceWithAccessOnNodeState({
                nodeId,
              }),
              () => fallback,
            );
            throw e;
          });
      }
    },
    [organisationId, showConfirm],
  );

  return {
    addOrUpdate,
    remove,
  };
};

export default useNodeFoundationResourcesCrud;
