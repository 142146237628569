import { useSearch } from "./useSearch";
import { organisationInterArrayCableResourceState } from "components/Organisation/Library/state";
import { orgCableManageAccessSelector } from "state/user";
import { useAtomValue } from "jotai";
import { SearchItem, SearchResultItem } from "../state";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CableType } from "services/cableTypeService";
import { getIcon } from "components/General/getIconFromRole";
import { LibraryManageRole } from "components/Organisation/Library/types";

const keys = ["name", "description"];

export function useSearchLibraryCables(
  organisationId: string,
  searchTerm: string,
) {
  const enabled = useAtomValue(orgCableManageAccessSelector);
  const navigate = useNavigate();

  const { filteredResults: filteredCables, rawData } = useSearch<CableType>(
    enabled,
    searchTerm,
    keys,
    organisationInterArrayCableResourceState({
      organisationId,
    }),
  );

  const filteredSearchItems: SearchResultItem<CableType>[] = useMemo(() => {
    return filteredCables.map((result) => ({
      id: result.item.id,
      name: result.item.name,
      score: result.score,
      item: result.item,
      icon: getIcon(LibraryManageRole.CABLE),
      onSelect: () => {
        navigate(
          `/organisation/${organisationId}/library/cable/${result.item.id}`,
        );
      },
    }));
  }, [filteredCables, navigate, organisationId]);

  const searchableItems: SearchItem<CableType>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.name,
      item: item,
      onSelect: () => {
        navigate(`/organisation/${organisationId}/library/cable/${item.id}`);
      },
      icon: getIcon(LibraryManageRole.CABLE),
    }));
  }, [rawData, navigate, organisationId]);

  return {
    filteredCables: filteredSearchItems,
    searchableItems,
  };
}
