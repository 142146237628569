import { z } from "zod";
import { _Consent } from "../types/user";
import { scream } from "./sentry";

/**
 * This is the data we expect to find in a token.
 */
const _Token = z.object({
  "https://app.vind.ai/picture": z.string().nullish(),
  "https://app.vind.ai/nickname": z.string().nullish(),
  "https://app.vind.ai/email": z.string().email(),
  "https://app.vind.ai/user_id": z.string(),
  "https://app.vind.ai/consents": _Consent.array().default([]),
  "https://app.vind.ai/interests": z.record(z.string(), z.any()).nullish(),
  "https://app.vind.ai/allow_news": z.boolean().default(false),
  "https://app.vind.ai/allow_interest_specific_news": z.boolean().optional(),
  "https://app.vind.ai/accept_terms_of_use": z.boolean().optional(),
  "https://app.vind.ai/internal": z.boolean().nullish(),
  "https://app.vind.ai/hmac": z.string(),
  "https://app.vind.ai/metadata/logins_count": z.number().nullish(),
  "https://app.vind.ai/temporary_token": z.boolean().nullish(),
  iss: z.string(),
  sub: z.string(),
  aud: z.string().array(),
  iat: z.number(),
  exp: z.number(),
  azp: z.string(),
  scope: z.string(),
});
type Token = z.infer<typeof _Token>;

export const parseJWT = (token: string): Token => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  const j = JSON.parse(jsonPayload);
  try {
    return _Token.parse(j);
  } catch (e) {
    scream("Failed to parse token", { e, j });
    throw e;
  }
};
