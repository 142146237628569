import mapboxgl, { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import GeoJsonFeature from "./GeoJsonFeature";
import { Feature } from "geojson";
import { fillOpacityWithCallback } from "./utils";
import { getZoomLevels } from "../../layers/ExternalLayers/utils";
import {
  HIDDEN_CLICK_LAYER_SUFFIX,
  lineHiddenClickWidth,
} from "components/Mapbox/constants";
import { useAtomValue } from "jotai";
import { defaultMapTextPaintAtom } from "state/map";

const DEFAULT_COLOR = "#BF93E4";

const LineString = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  color,
  opacity,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  filter,
  beforeLayer,
  zoomLevels,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.LinePaint;
  color?: string;
  opacity?: number;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean,
  ) => void;
  onDbClickCallback?: (features: MapboxGeoJSONFeature[]) => void;
  selectedIds?: (string | number)[];
  filter?: any[];
  beforeLayer?: string;
  zoomLevels?: [number, number];
}) => {
  const defaultMapTextPaint = useAtomValue(defaultMapTextPaintAtom);
  const layers = useMemo(() => {
    return [
      {
        ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
        id: layerId,
        type: "line",
        source: sourceId, // reference the data source
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: paint ?? {
          "line-color": "rgba(0,0,0,0)",
          "line-width": 5,
        },
      },
      ...(onClickCallback
        ? [
            {
              id: layerId + HIDDEN_CLICK_LAYER_SUFFIX,
              type: "line",
              source: sourceId, // reference the data source
              paint: {
                "line-color": "rgba(255,0,0,0)",
                "line-width": lineHiddenClickWidth,
              },
            },
          ]
        : []),
    ];
  }, [
    layerId,
    sourceId,
    paint,
    zoomLevels,
    onClickCallback,
  ]) as mapboxgl.AnyLayer[];

  const symbolsLayer = useMemo(() => {
    return symbols
      ? {
          ...(zoomLevels ? getZoomLevels(zoomLevels) : {}),
          ...symbols,
          id: `${layerId}-symbolid`,
          source: sourceId,
          layout: {
            ...symbols.layout,
            "text-offset":
              symbols.layout && "text-offset" in symbols.layout
                ? symbols.layout["text-offset"]
                : [0, 1],
          },
          paint: {
            ...defaultMapTextPaint,
            ...symbols.paint,
          },
        }
      : undefined;
  }, [layerId, sourceId, symbols, zoomLevels, defaultMapTextPaint]) as
    | mapboxgl.AnyLayer
    | undefined;

  useEffect(() => {
    if (!map || (paint && "line-color" in paint)) return;
    map.setPaintProperty(layerId, "line-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, layers, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(
        layerId,
        "line-opacity",
        fillOpacityWithCallback(opacity, opacity + 0.2),
      );
    }
  }, [layerId, map, opacity, layers, zoomLevels]);

  return (
    <GeoJsonFeature
      layers={layers}
      symbolsLayer={symbolsLayer}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      onClickCallback={onClickCallback}
      onDbClickCallback={onDbClickCallback}
      selectedIds={selectedIds}
      filter={filter}
      beforeLayer={beforeLayer}
    />
  );
};

export default LineString;
