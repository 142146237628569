import { isDefined } from "utils/predicates";
import { getDistanceFromPortToPark } from "../utils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import {
  getTurbines,
  getTurbinesWithFloatingFoundations,
} from "analysis/inputs";
import {
  InstallationAnalysisInput,
  InstallationSequence,
} from "state/jotai/windStatistics";
import {
  jackUpDownActivity,
  positionOnSiteActivity,
  transitActivity,
} from "../common";
import { getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { vesselTypesFamily } from "state/jotai/vesselType";
import {
  InstallationVesselType,
  isInstallationVessel,
} from "services/vesselService";
import { TurbineActivitiesConfig } from "services/operationsConfigurationService";

export const turbineInstallationTimeFamily = atomFamily((id: FinanceId) =>
  atom<Promise<InstallationAnalysisInput | undefined>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const turbinesInstalledAtPort = await get(
      getTurbinesWithFloatingFoundations(id),
    );
    const portParkDistance = await get(getDistanceFromPortToPark(id));

    const turbinesInstalledAtSite = turbines.filter(
      (turbine) =>
        !turbinesInstalledAtPort.some(
          (portTurbine) => portTurbine.id === turbine.id,
        ),
    );

    const configuration = await get(getOperationsConfiguration(id));
    const {
      installationVessel,
      installationSeason,
      weatherLimits,
      activities,
    } = configuration.ti.turbines;
    const vesselTypes = await get(vesselTypesFamily(undefined));

    if (!portParkDistance) return undefined;

    const vessel = vesselTypes.get(installationVessel.vesselId);

    if (!vessel || !isInstallationVessel(vessel)) return undefined;

    const {
      maxHsDuringInstallation: parkWaveLim,
      maxWindSpeedDuringInstallation: parkWindLim,
      maxHsDuringTransit: transitWaveLim,
      maxWindSpeedDuringTransit: transitWindLim,
    } = weatherLimits;

    const numTurbinesInstalledAtSite = turbinesInstalledAtSite.length;
    const numRounds = Math.ceil(
      numTurbinesInstalledAtSite / vessel.turbineCapacity,
    );
    const numTurbinesLastRound =
      numTurbinesInstalledAtSite - (numRounds - 1) * vessel.turbineCapacity;

    const transitTime = Math.ceil(portParkDistance / vessel.transitSpeed);

    const totalInstallTime: InstallationSequence = [];

    for (let i = 0; i < numRounds; i++) {
      const turbinesInRound =
        i === numRounds - 1 ? numTurbinesLastRound : vessel.turbineCapacity;

      for (let j = 0; j < turbinesInRound; j++) {
        totalInstallTime.push(loadTurbineOnVesselActivity(activities));
      }

      totalInstallTime.push(transitActivity(transitTime));

      for (let j = 0; j < turbinesInRound; j++) {
        parkOperations(vessel, activities).forEach((operation) => {
          totalInstallTime.push(operation);
        });
      }

      totalInstallTime.push(transitActivity(transitTime));
    }

    return {
      weatherLimits: {
        parkWaveLim,
        parkWindLim,
        transitWaveLim,
        transitWindLim,
      },
      ...installationSeason,
      installationSequence: totalInstallTime,
    };
  }),
);

const loadTurbineOnVesselActivity = (activities: TurbineActivitiesConfig) => ({
  id: "Load turbine on vessel",
  duration: activities.loadTurbineOnVessel,
  useWindLim: false,
  useWaveLim: false,
});

const liftAttachTowerActivity = (activities: TurbineActivitiesConfig) => ({
  id: "Lift and attach tower",
  duration: activities.liftAndAttachTower,
  useWindLim: true,
  useWaveLim: false,
});

const liftAttachNacelleActivity = (activities: TurbineActivitiesConfig) => ({
  id: "Lift and attach nacelle",
  duration: activities.liftAndAttachNacelle,
  useWindLim: true,
  useWaveLim: false,
});

const liftAttachBladeActivity = (
  activities: TurbineActivitiesConfig,
  mileStone?: boolean,
) => ({
  id: "Lift and attach blade",
  mileStone,
  duration: activities.liftAndAttachBlade,
  useWindLim: true,
  useWaveLim: false,
});

const parkOperations = (
  vessel: InstallationVesselType,
  activities: TurbineActivitiesConfig,
) =>
  [
    positionOnSiteActivity,
    jackUpDownActivity(vessel),
    liftAttachTowerActivity(activities),
    liftAttachNacelleActivity(activities),
    liftAttachBladeActivity(activities),
    liftAttachBladeActivity(activities),
    liftAttachBladeActivity(activities, true),
    jackUpDownActivity(vessel),
  ].filter(isDefined);
