import { Suspense, useCallback, useEffect, useState } from "react";
import { idToExportCableChangelogId } from "components/InputChangelog/const";
import { LibraryResourceHeader } from "./ResourceHeader";
import { TablePlaceholder } from "../shared/TablePlaceholder";
import { CableType } from "services/cableTypeService";
import useOrgExportCableCrud from "components/Organisation/Library/exportcable/useOrgExportCableCrud";
import { SingleExportCable } from "components/ConfigurationModal/CableSettings/ExportCableSettings";
import { ExportCableProjects } from "./availability/ExportCableProjects";
import { EXPORT_SYSTEM_TYPE_NAMES } from "components/ConfigurationModal/CableSettings/Components/Header";
import { Tag } from "components/General/Tag";
import { colors } from "styles/colors";
import { libraryTabToManageRole } from "components/Organisation/Library/shared/utils";
import { LibraryResourceUsage } from "components/ConfigurationModal/SettingsUsage/LibraryResourceUsage";
import ResourceContentTabs from "./shared/ResourceContentTabs";

export const ExportCableResourceContent = ({
  resource,
  organisationId,
}: {
  resource: CableType;
  organisationId: string;
}) => {
  const { update: updateCable } = useOrgExportCableCrud();

  const [tempCable, setTempCable] = useState(resource);

  useEffect(() => {
    setTempCable(resource);
  }, [resource, setTempCable]);

  const handleUpdate = useCallback(
    (updatedResource: Partial<CableType>) => {
      setTempCable((cur) => {
        return {
          ...(cur ?? resource),
          ...updatedResource,
        };
      });
    },
    [resource, setTempCable],
  );

  const onSaveNameOrDescription = useCallback(
    (cable: CableType) => {
      updateCable(cable, true);
    },
    [updateCable],
  );

  if (!tempCable) return null;

  return (
    <>
      <LibraryResourceHeader
        resourceType={"export cable"}
        description={tempCable.note ?? ""}
        name={tempCable.name}
        organisationId={organisationId}
        onUpdate={({ name, description }) =>
          handleUpdate({
            name: name ?? tempCable.name,
            note: description ?? tempCable.note,
          })
        }
        onSave={({ name, description }) => {
          onSaveNameOrDescription({
            ...tempCable,
            name: name ?? tempCable.name,
            note: description ?? tempCable.note,
          });
        }}
        onCancel={() => {
          handleUpdate({
            name: resource.name,
            note: resource.note,
          });
        }}
        changelogData={{
          id: idToExportCableChangelogId(resource.id),
          category: "org_export_cable_manage",
        }}
      >
        {resource.exportCableType && (
          <Tag
            background={colors.grey100}
            text={`Type: ${EXPORT_SYSTEM_TYPE_NAMES[resource.exportCableType]}`}
          />
        )}
        <LibraryResourceUsage
          resourceId={resource.id}
          libraryManageRole={libraryTabToManageRole("exportcable")}
          organisationId={organisationId}
        />
      </LibraryResourceHeader>
      <ResourceContentTabs
        tabs={exportCableTabs(resource, organisationId, updateCable)}
      />
    </>
  );
};

const exportCableTabs = (
  resource: CableType,
  organisationId: string,
  update: (cable: CableType) => Promise<any>,
) => [
  {
    name: "Cable details",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <SingleExportCable
          disabled={false}
          nodeId={organisationId}
          key={resource.id}
          exportCable={resource}
          onSave={update}
          isLoading={false}
          isLibraryExportCable={true}
        />
      </Suspense>
    ),
  },
  {
    name: "Usage and availability",
    data: (
      <Suspense fallback={<TablePlaceholder />}>
        <ExportCableProjects resource={resource} />
      </Suspense>
    ),
  },
];
