import React, { useState } from "react";
import styled from "styled-components";
import { MenuItemProps } from "./types";
import { FirstLevelItemContainer, ItemContainer, ItemText } from "./styles";
import { colors } from "../../../styles/colors";
import { typography } from "../../../styles/typography";
import { DotMenu } from "../../General/MenuButton";
import { MenuItem } from "../../General/Menu";
import DuplicateIcon from "@icons/24/Duplicate.svg?react";
import Tooltip from "../../General/Tooltip";
import { EditableTextInternalState } from "components/General/EditableText";

const NumberWrapper = styled.div`
  height: 2.2rem;
  min-height: 2.2rem;
  width: 2.2rem;
  min-width: 2.2rem;
  border-radius: 2rem;
  border: 1px solid ${colors.primaryText};
  text-transform: capitalize;

  display: flex;
  align-items: center;
  justify-content: center;

  ${typography.regular}
  color: ${colors.primaryText};
`;

const DuplicateIconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const IconContainer = styled.div<{
  isLoading?: boolean;
  isHovered?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;
  visibility: ${({ isHovered }) =>
    isHovered ? "visible" : "hidden !important"};
  ${({ isLoading }) => isLoading && "opacity: 0.4 !important"};
`;

const SettingsMenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    item,
    onClick,
    expanded,
    selected,
    index,
    isFirstLevel,
    onChangeName,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const firstLetter = item.label?.[0] ?? index;

  if (isFirstLevel) {
    return <FirstLevelItem {...props} />;
  }

  return (
    <ItemContainer
      style={{ width: "100%", justifyContent: "space-between" }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(item);
      }}
      selected={selected}
      expanded={expanded}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {item.icon && item.icon}
      {!item.icon && !expanded && <NumberWrapper>{firstLetter}</NumberWrapper>}
      <EditableTextInternalState
        value={item.name ?? item.label}
        disabled={!onChangeName}
        renderText={() => {
          return (
            <ItemText selected={selected} expanded={expanded}>
              {item.label}
            </ItemText>
          );
        }}
        onEnter={(newValue) => {
          onChangeName?.(newValue);
        }}
      />
      {isHovered && expanded && item.onDuplicate && (
        <Tooltip text={"Duplicate"}>
          <DuplicateIconWrapper
            onClick={(e) => {
              e.stopPropagation();
              if (item.loading || !item.onDuplicate) {
                return;
              }

              return item.onDuplicate(item.id);
            }}
          >
            <DuplicateIcon />
          </DuplicateIconWrapper>
        </Tooltip>
      )}
      {expanded && item.dotMenuOptions && (
        <DotMenu
          divStyle={{
            marginLeft: "auto",
            width: "2.5rem",
            opacity: isHovered ? 1 : 0,
          }}
        >
          {item.dotMenuOptions.map((option) => (
            <MenuItem
              key={`${item.id}-${option.title}`}
              name={option.title}
              onClick={() => option.onSelect(item.id)}
              icon={option.icon}
              disabled={item.loading}
            />
          ))}
          {item.onDuplicate && (
            <MenuItem
              name={"Duplicate"}
              key={`${item.id}-duplicate`}
              onClick={() => item.onDuplicate?.(item.id)}
              icon={<DuplicateIcon />}
            />
          )}
        </DotMenu>
      )}
    </ItemContainer>
  );
};

function FirstLevelItem({ item, onClick, expanded, selected }: MenuItemProps) {
  return (
    <FirstLevelItemContainer
      onClick={(e) => {
        e.stopPropagation();
        onClick(item);
      }}
      selected={selected}
      expanded={expanded}
    >
      {item.icon && item.icon}
      <ItemText selected={selected} expanded={expanded}>
        {item.label}
      </ItemText>
      {expanded && item.dotMenuOptions && (
        <DotMenu
          divStyle={{ marginLeft: "auto" }}
          color={selected ? "white" : colors.brand}
        >
          {item.dotMenuOptions.map((option) => (
            <MenuItem
              key={`${item.id}-${option.title}`}
              name={option.title}
              onClick={() => option.onSelect(item.id)}
              icon={option.icon}
              disabled={item.loading}
            />
          ))}
        </DotMenu>
      )}
    </FirstLevelItemContainer>
  );
}

export default SettingsMenuItem;
