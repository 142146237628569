import { atom, useSetAtom } from "jotai";
import {
  CostConfiguration,
  CostType,
  FoundationMaterialCost,
  LibraryFoundationReferenceCost,
  isFoundationMaterialCost,
  isOperationsCost,
  isOperationsOverrideCost,
  isFoundationOverrideCost,
  isLibraryFoundationReferenceCost,
} from "../../../../services/costService";
import {
  localCostConfigurationAtom,
  useLocalCostConfigurationCrud,
} from "../state";
import Foundations from "@icons/24/Foundation.svg?react";
import { GeneralCost } from "./General/GeneralCost";
import { FixedCost } from "./General/FixedCost";
import { ConfidenceLevel, CostUnit } from "../../../../types/financial";
import { FirstRowWrapper } from "../Capex";
import TableIcon from "@icons/24/Table.svg?react";
import {
  ConfidenceLevelDot,
  CostDivider,
  DividerWrapper,
  GridWithFiveColumns,
  Tag,
} from "../styles";
import WarningTriangle from "@icons/24/WarningTriangle.svg?react";
import { DefaultCosts } from "../constants";
import { Input, InputDimensioned, TextArea } from "components/General/Input";
import { isDefined } from "utils/predicates";
import Button from "components/General/Button";
import PencilIcon from "@icons/24/Pencil.svg";
import ComponentsIcon from "@icons/24/ComponentsIcon.svg";
import { Row } from "components/General/Layout";
import { colors } from "styles/colors";
import Tooltip from "components/General/Tooltip";
import { IconBtn } from "components/General/Icons";
import { ConfidenceLevelSelector } from "../ConfidenceLevelSelector";
import styled from "styled-components";
import { FoundationCategorySelector } from "../FoundationCategorySelector";
import { financialChangelogInfo } from "components/InputChangelog/const";
import { ChangelogCategory } from "components/InputChangelog/types";

import { useAtomValue } from "jotai";
import Dropdown from "components/Dropdown/Dropdown";
import { FoundationProcurementCost } from "services/procurementCostService";
import { FOUNDATION_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useFoundationSettings";
import { projectIdAtom } from "state/pathParams";
import { ComponentsPreviewType } from "components/ComponentsPreview/state";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import { useState, useEffect } from "react";
import { atomFamily } from "utils/jotai";
import { foundationProcurementCostsAtom } from "components/Organisation/Library/financial/ProcurementCosts/state";
import { listProcurementCostForLibraryFoundationsOnNodeAtomFamily } from "state/jotai/procurementCosts";
import { ButtonTextWrapper } from "components/General/Dropdown/DropdownButton";
import { IconREMSize, typography } from "styles/typography";
import { midScreenModalTypeOpenAtom } from "state/modal";

const FoundationCategory = styled.div`
  ${typography.caption}
  color: ${colors.textSecondary};
  padding: 0.8rem 0;
`;

export const foundationCategoriesToDisplayAtom = atomFamily<
  { _nodeId: string; _configurationId: string },
  string[]
>(({ _nodeId, _configurationId }) => atom(["Jacket", "Monopile", "Floating"]));

const FoundationSpecificCostsInProject = ({
  isReadOnly,
  configuration,
  nodeId,
  category,
  projectId,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
  projectId: string;
  isLibraryResource: boolean;
}) => {
  const foundationProcurementCostTables = useAtomValue(
    listProcurementCostForLibraryFoundationsOnNodeAtomFamily(projectId),
  );
  return (
    <FoundationSpecificCostsInner
      isReadOnly={isReadOnly}
      configuration={configuration}
      nodeId={nodeId}
      category={category}
      isLibraryResource={isLibraryResource}
      foundationProcurementCostTables={foundationProcurementCostTables}
    />
  );
};

const FoundationSpecificCostsInLibrary = ({
  isReadOnly,
  configuration,
  nodeId,
  category,
  isLibraryResource,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
  isLibraryResource: boolean;
}) => {
  const foundationProcurementCostTables = useAtomValue(
    foundationProcurementCostsAtom(nodeId),
  );

  return (
    <FoundationSpecificCostsInner
      isReadOnly={isReadOnly}
      configuration={configuration}
      nodeId={nodeId}
      category={category}
      isLibraryResource={isLibraryResource}
      foundationProcurementCostTables={foundationProcurementCostTables}
    />
  );
};

const FoundationSpecificCostsInner = ({
  isReadOnly,
  configuration,
  nodeId,
  category,
  isLibraryResource,
  foundationProcurementCostTables,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
  isLibraryResource: boolean;
  foundationProcurementCostTables: FoundationProcurementCost[];
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );
  const setFoundationCategoriesToDisplay = useSetAtom(
    foundationCategoriesToDisplayAtom({
      _nodeId: nodeId,
      _configurationId: configuration.id,
    }),
  );
  const [lastLibraryReference, setLastLibraryReference] =
    useState<LibraryFoundationReferenceCost | null>(null);

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;

  const { updateFixedCapex } = useLocalCostConfigurationCrud();

  const setMidScreenModalTypeOpen = useSetAtom(midScreenModalTypeOpenAtom);
  const projectId = useAtomValue(projectIdAtom)!;
  const inLibraryContext = !projectId;

  useEffect(() => {
    if (isLibraryFoundationReferenceCost(localConfig.capex.fixed.foundations)) {
      setLastLibraryReference(localConfig.capex.fixed.foundations);
    }
  }, [localConfig.capex.fixed.foundations]);

  const handleFoundationCostTypeChange = (
    type: "material" | "override" | "libraryReference",
  ) => {
    if (type === "material") {
      updateFixedCapex({
        foundations: {
          floating: {
            primarySteel: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].primarySteel[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
            concrete: {
              unit: CostUnit.euroPerM3,
              cost: DefaultCosts[CostType.Foundation].concrete[
                CostUnit.euroPerM3
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
            reinforcement: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].reinforcement[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
            postTensionCables: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].postTensionCables[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
            solidBallast: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].solidBallast[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
          },
          monopile: {
            primarySteel: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].primarySteel[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
          },
          jacket: {
            primarySteel: {
              unit: CostUnit.euroPerT,
              cost: DefaultCosts[CostType.Foundation].primarySteel[
                CostUnit.euroPerT
              ],
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
          },
        },
      });
    } else if (type === "override") {
      updateFixedCapex({
        foundations: {
          cost: DefaultCosts[CostType.Foundation].foundation[
            CostUnit.millionEuroPerUnit
          ],
          unit: CostUnit.millionEuroPerUnit,
          confidenceLevel: ConfidenceLevel.notSpecified,
        },
      });
    } else if (type === "libraryReference") {
      updateFixedCapex({
        foundations: lastLibraryReference || {
          jacketCostReference: foundationProcurementCostTables[0]?.id,
          monopileCostReference: foundationProcurementCostTables[0]?.id,
          floatingCostReference: foundationProcurementCostTables[0]?.id,
        },
      });
    }
  };

  const { foundations } = localConfig.capex.fixed;
  const originalFoundations = configuration.capex.fixed.foundations;

  if (!foundations) return null;

  const showUseTableIcon =
    isFoundationOverrideCost(foundations) && isLibraryResource;

  return (
    <>
      <FirstRowWrapper>
        <GridWithFiveColumns>
          {isFoundationOverrideCost(foundations) ? (
            <Input
              value={"Foundations"}
              disabled={true}
              locked={true}
              style={{
                width: "100%",
              }}
            />
          ) : (
            <FoundationCategorySelector
              nodeId={nodeId}
              configurationId={localConfig.id}
              onSelectionChange={(foundationCategory) => {
                setFoundationCategoriesToDisplay((prev) => {
                  if (prev.includes(foundationCategory)) {
                    return prev.filter((f) => f !== foundationCategory);
                  }
                  return [...prev, foundationCategory];
                });
              }}
            />
          )}
          {!isFoundationOverrideCost(foundations) && (
            <Row
              style={{
                width: "100%",
              }}
            >
              {isLibraryResource ? (
                <>
                  <Tag>From table</Tag>
                  <IconBtn
                    size={"1.4rem"}
                    onClick={() => {
                      setMidScreenModalTypeOpen({
                        modalType: ComponentsPreviewType,
                        metadata: {
                          componentType: FOUNDATION_MENU_ID,
                          isLibraryResource,
                          inLibraryContext,
                        },
                      });
                    }}
                  >
                    <TableIcon />
                  </IconBtn>
                  {inLibraryContext && (
                    <HelpTooltip
                      text={"Using cost specified in the procurement table"}
                    />
                  )}
                </>
              ) : (
                <Tag>From materials listed below</Tag>
              )}
            </Row>
          )}
          {isFoundationOverrideCost(foundations) && (
            <Row
              style={{
                width: "100%",
              }}
            >
              <InputDimensioned
                step="0.1"
                unit={foundations.unit}
                units={[CostUnit.millionEuroPerMW, CostUnit.millionEuroPerUnit]}
                type="number"
                value={foundations.cost}
                initialValue={
                  isFoundationOverrideCost(originalFoundations)
                    ? originalFoundations.cost
                    : undefined
                }
                disabled={isReadOnly}
                onUnitChange={(unit) => {
                  updateFixedCapex(
                    {
                      unit,
                      cost: (DefaultCosts.foundation.foundation as any)[unit],
                    },
                    "foundations",
                  );
                }}
                validate={(value) => isDefined(value) && value >= 0}
                validationMessage={"Must be greater than or equal to 0"}
                changelogInfo={financialChangelogInfo(
                  localConfig.id,
                  nodeId,
                  "capex.fixed.foundations.cost",
                  category,
                )}
                onChange={(cost, _, unit) => {
                  updateFixedCapex(
                    {
                      cost,
                      unit,
                    },
                    "foundations",
                  );
                }}
                style={{
                  width: "100%",
                }}
              />
            </Row>
          )}

          {useConfidenceLevel &&
            (isFoundationOverrideCost(foundations) ? (
              <ConfidenceLevelSelector
                disabled={isReadOnly}
                onConfidenceLevelChange={(confidenceLevel) => {
                  updateFixedCapex({
                    foundations: {
                      ...foundations,
                      confidenceLevel: confidenceLevel,
                    },
                  });
                }}
                confidenceLevel={foundations.confidenceLevel}
              />
            ) : (
              <div></div>
            ))}
          {showFreeTextCapex && (
            <div>
              <TextArea
                rows={1}
                style={{
                  resize: "vertical",
                  width: "100%",
                }}
                value={localConfig?.capex.fixed.foundationsFreeText ?? ""}
                disabled={isReadOnly}
                onChange={(e) => {
                  updateFixedCapex({
                    foundationsFreeText: e.target.value,
                  });
                }}
              />
            </div>
          )}
          {!useConfidenceLevel && <div></div>}
          {!showFreeTextCapex && <div></div>}

          <Button
            size="small"
            icon={showUseTableIcon ? <TableIcon /> : <PencilIcon />}
            buttonType="secondary"
            text={
              !isFoundationOverrideCost(foundations)
                ? "Override"
                : isLibraryResource
                  ? "Use table"
                  : "Use materials"
            }
            onClick={() => {
              if (isLibraryResource) {
                isLibraryFoundationReferenceCost(foundations)
                  ? handleFoundationCostTypeChange("override")
                  : handleFoundationCostTypeChange("libraryReference");
              } else {
                isFoundationMaterialCost(foundations)
                  ? handleFoundationCostTypeChange("override")
                  : handleFoundationCostTypeChange("material");
              }
            }}
            tooltip={
              isLibraryResource && foundationProcurementCostTables.length === 0
                ? "No tables available"
                : undefined
            }
            disabled={
              isReadOnly ||
              (isLibraryResource &&
                foundationProcurementCostTables.length === 0)
            }
          />
        </GridWithFiveColumns>
      </FirstRowWrapper>
      {isFoundationMaterialCost(foundations) && (
        <FoundationMaterialCosts
          foundations={foundations}
          isReadOnly={isReadOnly}
          nodeId={nodeId}
          category={category}
          configId={localConfig.id}
          originalFoundations={
            isFoundationMaterialCost(originalFoundations)
              ? originalFoundations
              : undefined
          }
        />
      )}
      {isLibraryFoundationReferenceCost(foundations) && (
        <FoundationLibraryReferenceCost
          nodeId={nodeId}
          configurationId={localConfig.id}
          foundations={foundations}
          isReadOnly={isReadOnly}
          foundationProcurementCostTables={foundationProcurementCostTables}
        />
      )}
    </>
  );
};

const ProcurementCostTablePicker = ({
  foundationCategory,
  onChange,
  tableReference,
  isReadOnly,
  title,
  options,
}: {
  foundationCategory: "jacket" | "monopile" | "floating";
  onChange: (id: string) => void;
  tableReference: string | undefined;
  isReadOnly?: boolean;
  title: string;
  options: FoundationProcurementCost[];
}) => {
  const selectedTableDoesNotExist =
    options.find((option) => option.id === tableReference) === undefined;
  return (
    <GridWithFiveColumns>
      <Input
        value={title}
        disabled={true}
        locked={true}
        style={{ width: "100%" }}
      />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Dropdown
          style={{
            width: "100%",
            marginRight: "0.4rem",
            border: selectedTableDoesNotExist
              ? `2px solid ${colors.red200}`
              : "1px solid #ccc",
          }}
          id={"prourement_cost_reference_foundation_" + foundationCategory}
          value={tableReference}
          disabled={isReadOnly}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((c) => (
            <option key={c.id} value={c.id}>
              {`${c.tableName}`}
            </option>
          ))}
          {selectedTableDoesNotExist && (
            <option key="missing" value={tableReference} disabled>
              Table was deleted
            </option>
          )}
        </Dropdown>
        {selectedTableDoesNotExist && (
          <IconREMSize height={1.4} width={1.4}>
            <WarningTriangle />
          </IconREMSize>
        )}
      </div>
    </GridWithFiveColumns>
  );
};

const FoundationLibraryReferenceCost = ({
  nodeId,
  configurationId,
  foundations,
  isReadOnly,
  foundationProcurementCostTables,
}: {
  nodeId: string;
  configurationId: string;
  foundations: LibraryFoundationReferenceCost;
  originalFoundations?: LibraryFoundationReferenceCost;
  isReadOnly?: boolean;
  foundationProcurementCostTables: FoundationProcurementCost[];
}) => {
  const { updateFixedCapex } = useLocalCostConfigurationCrud();
  const foundationCategoriesToDisplay = useAtomValue(
    foundationCategoriesToDisplayAtom({
      _nodeId: nodeId,
      _configurationId: configurationId,
    }),
  );

  return (
    <>
      {foundationCategoriesToDisplay.includes("Jacket") && (
        <ProcurementCostTablePicker
          tableReference={foundations.jacketCostReference}
          title="Jacket"
          foundationCategory="jacket"
          options={foundationProcurementCostTables}
          isReadOnly={isReadOnly}
          onChange={(id) => {
            updateFixedCapex(
              {
                jacketCostReference: id,
              },
              "foundations",
            );
          }}
        />
      )}
      {foundationCategoriesToDisplay.includes("Monopile") && (
        <ProcurementCostTablePicker
          tableReference={foundations.monopileCostReference}
          title="Monopile"
          foundationCategory="monopile"
          options={foundationProcurementCostTables}
          isReadOnly={isReadOnly}
          onChange={(id) => {
            updateFixedCapex(
              {
                monopileCostReference: id,
              },
              "foundations",
            );
          }}
        />
      )}
      {foundationCategoriesToDisplay.includes("Floating") && (
        <ProcurementCostTablePicker
          tableReference={foundations.floatingCostReference}
          title="Floating"
          foundationCategory="floating"
          options={foundationProcurementCostTables}
          isReadOnly={isReadOnly}
          onChange={(id) => {
            updateFixedCapex(
              {
                floatingCostReference: id,
              },
              "foundations",
            );
          }}
        />
      )}
    </>
  );
};

const FoundationMaterialCosts = ({
  foundations,
  isReadOnly,
  configId,
  originalFoundations,
  nodeId,
  category,
}: {
  foundations: FoundationMaterialCost;
  originalFoundations?: FoundationMaterialCost;
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configId: string;
}) => {
  const { updateFixedCapex } = useLocalCostConfigurationCrud();
  const foundationCategoriesToDisplay = useAtomValue(
    foundationCategoriesToDisplayAtom({
      _nodeId: nodeId,
      _configurationId: configId,
    }),
  );

  const { floating, jacket, monopile } = foundations;
  if (!floating || !jacket || !monopile) return null;

  return (
    <>
      {foundationCategoriesToDisplay.includes("Jacket") && (
        <>
          <FoundationCategory>Jacket</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={jacket.primarySteel.unit}
            value={jacket.primarySteel.cost}
            initialValue={originalFoundations?.jacket.primarySteel.cost}
            confidenceLevel={jacket.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.jacket.primarySteel.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  jacket: {
                    ...jacket,
                    primarySteel: jacket.primarySteel && {
                      ...jacket.primarySteel,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  jacket: {
                    ...jacket,
                    primarySteel: {
                      ...jacket.primarySteel,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Monopile") && (
        <>
          <FoundationCategory>Monopile</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={monopile.primarySteel.unit}
            value={monopile.primarySteel.cost}
            initialValue={originalFoundations?.monopile.primarySteel.cost}
            confidenceLevel={monopile.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.monopile.primarySteel.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  monopile: {
                    ...monopile,
                    primarySteel: monopile.primarySteel && {
                      ...monopile.primarySteel,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  monopile: {
                    ...monopile,
                    primarySteel: {
                      ...monopile.primarySteel,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Floating") && (
        <>
          <FoundationCategory>Floating</FoundationCategory>
          <FixedCost
            title={"Primary steel"}
            unit={floating.primarySteel.unit}
            value={floating.primarySteel.cost}
            initialValue={originalFoundations?.floating.primarySteel.cost}
            confidenceLevel={floating.primarySteel.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.floating.primarySteel.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: {
                    ...floating,
                    primarySteel: floating.primarySteel && {
                      ...floating.primarySteel,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: {
                    ...floating,
                    primarySteel: {
                      ...floating.primarySteel,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
          <FixedCost
            title={"Concrete"}
            unit={floating.concrete.unit}
            value={floating.concrete.cost}
            initialValue={originalFoundations?.floating.concrete.cost}
            confidenceLevel={floating.concrete.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.floating.concrete.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    concrete: {
                      ...floating.concrete,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    concrete: {
                      ...floating.concrete,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
          <FixedCost
            title={"Solid ballast"}
            unit={floating.solidBallast.unit}
            value={floating.solidBallast.cost}
            initialValue={originalFoundations?.floating.solidBallast.cost}
            confidenceLevel={floating.solidBallast.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.floating.solidBallast.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    solidBallast: {
                      ...floating.solidBallast,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    solidBallast: {
                      ...floating.solidBallast,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
          <FixedCost
            title={"Reinforcement"}
            unit={floating.reinforcement.unit}
            value={floating.reinforcement.cost}
            initialValue={originalFoundations?.floating.reinforcement.cost}
            confidenceLevel={floating.reinforcement.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.floating.reinforcement.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    reinforcement: {
                      ...floating.reinforcement,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    reinforcement: {
                      ...floating.reinforcement,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
          <FixedCost
            title={"Post tension cables"}
            unit={floating.postTensionCables.unit}
            value={floating.postTensionCables.cost}
            initialValue={originalFoundations?.floating.postTensionCables.cost}
            confidenceLevel={floating.postTensionCables.confidenceLevel}
            isReadOnly={isReadOnly}
            changelogInfo={financialChangelogInfo(
              configId,
              nodeId,
              "capex.fixed.foundations.floating.postTensionCables.cost",
              category,
            )}
            onValueChange={(cost) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    postTensionCables: {
                      ...floating.postTensionCables,
                      cost,
                    },
                  },
                },
                "foundations",
              );
            }}
            onConfidenceLevelChange={(confidenceLevel) => {
              updateFixedCapex(
                {
                  floating: floating && {
                    ...floating,
                    postTensionCables: {
                      ...floating.postTensionCables,
                      confidenceLevel,
                    },
                  },
                },
                "foundations",
              );
            }}
          />
        </>
      )}
    </>
  );
};

const FoundationInstallationCosts = ({
  isReadOnly,
  nodeId,
  category,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  category: ChangelogCategory;
  configuration: CostConfiguration;
}) => {
  const localConfig: CostConfiguration = useAtomValue(
    localCostConfigurationAtom,
  );

  const useConfidenceLevel = localConfig.useConfidenceLevel;
  const showFreeTextCapex = localConfig.showFreeTextCapex;
  const isOnshoreConfig = configuration.type === "onshore";

  const { updateInstallationCapex } = useLocalCostConfigurationCrud();

  const handleFoundationCostTypeChange =
    (type: "operations_cost" | "override") => () => {
      if (type === "operations_cost") {
        updateInstallationCapex({ foundations: type });
      } else {
        updateInstallationCapex({
          foundations: {
            unit: CostUnit.thousandEuroPerMW,
            cost: DefaultCosts[CostType.Foundation].installation["k€/MW"],
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
        });
      }
    };

  return (
    <FirstRowWrapper>
      <GridWithFiveColumns>
        <Input
          value={"Installation"}
          disabled={true}
          locked={true}
          style={{ width: "100%" }}
        />

        {isOperationsCost(localConfig?.capex.installation.foundations) ? (
          <Row style={{ width: "100%" }}>
            <Tag>
              <ComponentsIcon />
              From operations
            </Tag>
            <HelpTooltip text="Using the cost specified in the operations configuration" />
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
            }}
          >
            <InputDimensioned
              step="0.1"
              unit={
                (isOperationsOverrideCost(
                  localConfig?.capex.installation.foundations,
                ) &&
                  localConfig?.capex.installation.foundations.unit) ||
                CostUnit.millionEuroPerMW
              }
              units={[CostUnit.thousandEuroPerMW]}
              type="number"
              value={
                isOperationsOverrideCost(
                  localConfig?.capex.installation.foundations,
                )
                  ? localConfig.capex.installation.foundations.cost ??
                    DefaultCosts[CostType.Foundation].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              initialValue={
                isOperationsOverrideCost(
                  configuration?.capex.installation.foundations,
                )
                  ? configuration.capex.installation.foundations.cost ??
                    DefaultCosts[CostType.Foundation].installation[
                      CostUnit.thousandEuroPerMW
                    ]
                  : undefined
              }
              onChange={(cost) =>
                updateInstallationCapex({ cost }, "foundations")
              }
              validate={(value) => isDefined(value) && value >= 0}
              validationMessage={"Must be greater than or equal to 0"}
              style={{ width: "100%" }}
              changelogInfo={financialChangelogInfo(
                localConfig.id,
                nodeId,
                "capex.installation.foundations.cost",
                category,
              )}
              disabled={
                isReadOnly ||
                !(
                  localConfig?.capex.installation.foundations &&
                  isOperationsOverrideCost(
                    localConfig?.capex.installation.foundations,
                  )
                )
              }
            />
          </Row>
        )}

        {useConfidenceLevel &&
          (!(
            localConfig?.capex.installation.foundations === "operations_cost"
          ) ? (
            <ConfidenceLevelSelector
              disabled={isReadOnly}
              onConfidenceLevelChange={(confidenceLevel) => {
                if (
                  localConfig?.capex.installation.foundations ===
                  "operations_cost"
                )
                  return;
                updateInstallationCapex({
                  foundations: {
                    ...localConfig?.capex.installation.foundations,
                    confidenceLevel,
                  },
                });
              }}
              confidenceLevel={
                localConfig?.capex.installation.foundations.confidenceLevel
              }
            />
          ) : (
            <Tag>
              <Tooltip
                text={
                  "Not able to specify confidence on configuration level when the cost is from operations"
                }
              >
                <ButtonTextWrapper>
                  <ConfidenceLevelDot confidenceLevel={undefined} />
                  {ConfidenceLevel.notSpecified}
                </ButtonTextWrapper>
              </Tooltip>
            </Tag>
          ))}
        {showFreeTextCapex && (
          <div>
            <TextArea
              rows={1}
              style={{
                resize: "vertical",
                width: "100%",
              }}
              value={localConfig?.capex.installation.foundationsFreeText ?? ""}
              disabled={isReadOnly}
              onChange={(e) => {
                updateInstallationCapex({
                  foundationsFreeText: e.target.value,
                });
              }}
            />
          </div>
        )}
        {!useConfidenceLevel && <div></div>}
        {!showFreeTextCapex && <div></div>}
        {!isOnshoreConfig && (
          <Button
            size="small"
            icon={
              isOperationsCost(localConfig?.capex.installation.foundations) ? (
                <PencilIcon />
              ) : (
                <ComponentsIcon />
              )
            }
            buttonType="secondary"
            text={
              isOperationsCost(localConfig?.capex.installation.foundations)
                ? "Override"
                : "Use operations"
            }
            onClick={
              isOperationsCost(localConfig?.capex.installation.foundations)
                ? handleFoundationCostTypeChange("override")
                : handleFoundationCostTypeChange("operations_cost")
            }
            disabled={isReadOnly}
          />
        )}
      </GridWithFiveColumns>
    </FirstRowWrapper>
  );
};

export const FoundationCosts = ({
  isReadOnly,
  nodeId,
  isLibraryResource,
  configuration,
}: {
  isReadOnly?: boolean;
  nodeId: string;
  isLibraryResource: boolean;
  configuration: CostConfiguration;
}) => {
  const projectId = useAtomValue(projectIdAtom) ?? undefined;
  return (
    <GeneralCost
      title="Foundations"
      category={CostType.Foundation}
      configuration={configuration}
      units={[
        CostUnit.thousandEuroPerMW,
        CostUnit.millionEuroPerMW,
        CostUnit.millionEuroPerUnit,
        CostUnit.millionEuro,
      ]}
      nodeId={nodeId}
      changelogCategory={isLibraryResource ? "org_financial_manage" : "project"}
      icon={<Foundations />}
      isReadOnly={isReadOnly}
    >
      {projectId ? (
        <FoundationSpecificCostsInProject
          isReadOnly={isReadOnly}
          nodeId={nodeId}
          isLibraryResource={isLibraryResource}
          projectId={projectId}
          category={isLibraryResource ? "org_financial_manage" : "project"}
          configuration={configuration}
        />
      ) : (
        <FoundationSpecificCostsInLibrary
          isReadOnly={isReadOnly}
          nodeId={nodeId}
          isLibraryResource={isLibraryResource}
          category={isLibraryResource ? "org_financial_manage" : "project"}
          configuration={configuration}
        />
      )}
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
      <FoundationInstallationCosts
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={isLibraryResource ? "org_financial_manage" : "project"}
        configuration={configuration}
      />
      <DividerWrapper>
        <CostDivider />
      </DividerWrapper>
    </GeneralCost>
  );
};
