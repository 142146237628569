import { atom } from "jotai";
import { LineString } from "@turf/turf";
import { Point } from "geojson";
import { v4 as uuid4 } from "uuid";
import {
  CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/cabling";
import { colors } from "../styles/colors";
import { CableFeature, SubstationFeature } from "../types/feature";

export const HANG_OFF_ANGLE_LAZY_WAVE = (80 * Math.PI) / 180;
export const Z_SAG_DEPTH_FRACTION = 2 / 5;
export const Z_HOG_DEPTH_FRACTION = 3 / 5;
export const CABLE_SUBMERGED_WEIGHT = 40 * 9.81;
export const CABLE_BUOY_SUBMERGED_WEIGHT = CABLE_SUBMERGED_WEIGHT;
export const MIN_CABLE_LENGTH_KM = 0.001;
export const NUM_ELECTRICAL_POWER_BINS = 41;

export const substationFeature = (
  position: Point,
  id: string,
  parkId?: string,
  substationTypeId?: string,
): SubstationFeature => {
  return {
    type: "Feature",
    id,
    geometry: position,
    properties: {
      type: SUBSTATION_PROPERTY_TYPE,
      color: colors.substation,
      id,
      parentIds: parkId ? [parkId] : [],
      name: "Substation",
      substationTypeId,
    },
  };
};

export const cableFeature = (
  cable: {
    fromId: string;
    toId: string;
  },
  geometry: LineString,
  parkId?: string,
): CableFeature => {
  const id = uuid4();
  const properties: CableFeature["properties"] = {
    type: CABLE_PROPERTY_TYPE,
    id,
    parentIds: parkId ? [parkId] : [],
    name: "Cable",
    ...cable,
  };
  return {
    type: "Feature",
    id,
    geometry,
    properties,
  };
};

export const keepExportModalOpenAtom = atom<boolean>(false);
export const keepCabelGenModalOpenAtom = atom<boolean>(false);
