import styled from "styled-components";
import { colors } from "styles/colors";
import { MapLegend } from "./MapLegend";
import ViewshedLegend from "./ViewshedLegend";

const LegendContainer = styled.div`
  padding: 1rem;
  background-color: ${colors.surfacePrimary};
  border-radius: 0.5rem;
  min-width: 20rem;
  position: absolute;
  left: 1.6rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const Legend = ({
  showFeatureLegend,
  showViewshedLegend,
}: {
  showFeatureLegend: boolean;
  showViewshedLegend: boolean;
}) => {
  if (!showFeatureLegend && !showViewshedLegend) {
    return null;
  }

  return (
    <LegendContainer>
      {showViewshedLegend && <ViewshedLegend />}
      {showFeatureLegend && <MapLegend />}
    </LegendContainer>
  );
};

export default Legend;
