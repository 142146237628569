// Material constants
export const E_STEEL = 2.1e11;
export const CONCRETE_DENSITY = 2700; // kg/m3
export const STEEL_DENSITY = 7850; // kg/m3

//Constants for weight equation
export const scalingEquationTokens = [
  {
    type: 3,
    token: "p",
    show: "p",
    value: "p",
    precedence: 0,
  },
  {
    type: 3,
    token: "w",
    show: "w",
    value: "w",
    precedence: 0,
  },
  {
    type: 3,
    token: "d",
    show: "d",
    value: "d",
    precedence: 0,
  },
];
