import React from "react";
import { InputDimensioned } from "components/General/Input";
import styled from "styled-components";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import { TableData } from "./ProcurementCostsContent";
import Tooltip from "components/General/Tooltip";
import { spacing3, spacing4, spacing6 } from "styles/space";
import { EmptyState } from "components/ValidationWarnings/EmptyState";
import InformationIcon from "@icons/24/Information.svg?react";
import { Row } from "components/General/Layout";
import { procurementCostsChangelogInfo } from "components/InputChangelog/const";
import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { getCustomArrayChangeLogValue } from "components/InputChangelog/utils";

const TableContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  padding-bottom: 4rem;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: ${typography.body};
`;

const Thead = styled.thead`
  background-color: ${colors.surfaceInfo};
  border-spacing: 0;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const MainHeader = styled.th`
  text-align: left;
  color: ${colors.textPrimary};
  ${typography.sub2};
  white-space: normal;
  word-wrap: break-word;
  padding: ${spacing4} ${spacing6};
`;

const SubHeader = styled.th`
  ${typography.sub3};
  padding: ${spacing4} ${spacing6};
  color: ${colors.textPrimary};
  text-align: left;
  text-transform: uppercase;
  background-color: ${colors.surfacePrimary};
  white-space: nowrap;
  border-bottom: 1px solid ${colors.blue500};
`;

const SubHeaderRow = styled.tr`
  border-bottom: 1px solid ${colors.blue500};
`;

const Tr = styled.tr`
  &:nth-child(odd) {
    background-color: ${colors.surfaceSecondary};
  }

  &:hover {
    background-color: ${colors.surfaceHover};
  }
`;

const Td = styled.td<{ isName?: boolean }>`
  ${typography.caption};
  padding: ${spacing3} ${spacing6};
  text-align: left;
  color: ${colors.textPrimary};
  white-space: ${({ isName }) => (isName ? "normal" : "nowrap")};
  word-wrap: ${({ isName }) => (isName ? "break-word" : "normal")};
  max-width: ${({ isName }) => (isName ? "200px" : "none")};
`;

const Divider = styled.td`
  width: 1px;
  padding: 0;
  background-color: ${colors.borderDefault};
`;

interface TableProps {
  headers: string[];
  subHeaders: Record<string, string[]>;
  data: TableData[];
  onDataChange: (field: string, value: number | undefined, id: string) => void;
  emptyStateIcon: JSX.Element;
  emptyStateDescription: string;
}

const CostTable = ({
  headers,
  subHeaders,
  data,
  onDataChange,
  emptyStateIcon,
  emptyStateDescription,
}: TableProps) => {
  const dividerIndex = headers.findIndex((header) => header === "Shipping");
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  return (
    <TableContainer>
      <StyledTable>
        <Thead>
          <tr>
            {headers.map((header, index) => (
              <React.Fragment key={header}>
                <MainHeader
                  colSpan={subHeaders[header].length}
                  style={index === 0 ? { width: "30%" } : {}}
                >
                  {header === "Supply" || header === "Shipping" ? (
                    <Row>
                      {header}
                      <Tooltip
                        text={
                          header === "Supply"
                            ? "Cost from supplier"
                            : "Shipping from manufacturer to marshalling port"
                        }
                      >
                        <InformationIcon
                          style={{
                            height: "1.3rem",
                          }}
                        />
                      </Tooltip>
                    </Row>
                  ) : (
                    header
                  )}
                </MainHeader>
                {index === dividerIndex - 1 && <Divider rowSpan={2} />}
              </React.Fragment>
            ))}
          </tr>
          <SubHeaderRow>
            {headers.map((header) =>
              subHeaders[header].map((subHeader) => (
                <SubHeader key={`${header}-${subHeader}`}>
                  {subHeader === "Other" ? (
                    <Row>
                      {subHeader}
                      <Tooltip text="This cost is applied if the park location is outside the predefined regions">
                        <InformationIcon
                          style={{
                            height: "1.3rem",
                          }}
                        />
                      </Tooltip>
                    </Row>
                  ) : (
                    subHeader
                  )}
                </SubHeader>
              )),
            )}
          </SubHeaderRow>
        </Thead>
        <tbody>
          {data.length > 0 ? (
            data.map(({ id, name, extraValue, costs }: TableData) => (
              <Tr key={id}>
                <Td isName>{name.value}</Td>
                {typeof extraValue !== "undefined" ? (
                  <Td>{extraValue}</Td>
                ) : (
                  <Td />
                )}
                {Object.entries(costs).map(
                  ([field, { value, unit }], index) => (
                    <React.Fragment key={field}>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <InputDimensioned
                            value={Number(value)}
                            unit={unit}
                            changelogInfo={
                              field === "supplyCost"
                                ? procurementCostsChangelogInfo(
                                    id,
                                    organisationId,
                                    "supplyCost.cost",
                                    "org_financial_manage",
                                  )
                                : procurementCostsChangelogInfo(
                                    id,
                                    organisationId,
                                    field,
                                    "org_financial_manage",
                                    (obj: any) =>
                                      getCustomArrayChangeLogValue(
                                        obj,
                                        "shippingCost",
                                        `region.${field}`,
                                        ["cost", "unit"],
                                      ),
                                  )
                            }
                            onChange={(newValue) => {
                              onDataChange(
                                field,
                                typeof newValue === "number" && !isNaN(newValue)
                                  ? newValue
                                  : undefined,
                                id,
                              );
                            }}
                          />
                        </div>
                      </Td>
                      {index === dividerIndex - 2 && <Divider />}
                    </React.Fragment>
                  ),
                )}
              </Tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={
                  headers.reduce(
                    (acc, header) => acc + subHeaders[header].length,
                    0,
                  ) + 1
                }
              >
                <EmptyState
                  title=""
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  icon={emptyStateIcon}
                  description={emptyStateDescription}
                />
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default CostTable;
