import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import ArrowRightIcon from "@icons/24/ArrowRight.svg";
import CloseIcon from "@icons/24/Close.svg";
import FolderAddIcon from "@icons/16/FolderAdd.svg";
import FolderOutlineIcon from "@icons/24/Folder-outline.svg";
import FolderMenuClosedIcon from "@icons/20/FolderMenuClosed.svg";
import OpenFolderIcon from "@icons/14/OpenFolder.svg";
import ProjectGlobeIcon from "@icons/24/ProjectGlobe.svg";
import PersonalFolderClosedIcon from "@icons/20/PersonalFolderClosed.svg";
import type { Node } from "services/customerAPI";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { colors } from "styles/colors";
import { Column, Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { useOrganisationNodeCrud } from "components/Projects/useOrganisationFolderCrud";
import { IconREMSize } from "styles/typography";
import {
  spaceTiny,
  spacing2,
  spacing4,
  spacing6,
  spacing7,
  spacing8,
} from "styles/space";
import { HorizontalDivider } from "components/InputChangelog/style";
import Button from "components/General/Button";
import Tooltip from "components/General/Tooltip";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import NewItemModal from "components/NewItemModal/NewItemModal";
import { UserNodesAccess } from "types/user";
import { StandardBox } from "styles/boxes/Boxes";
import NodeBreadCrumbs from "./NodeBreadCrumbs";
import { getNodeName } from "components/Organisation/utils";
import { useNodesInOrganisationState } from "components/Projects/useNodesInOrganisationState";
import { useUserAccessState } from "components/Projects/useUserAccessState";

const Modal = styled(StandardBox)`
  color: ${colors.textPrimary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${spacing7} ${spacing8};
  width: 60rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
  min-height: 40rem;
  max-height: 60rem;
  overflow-x: hidden;
`;

const HoverableRow = styled(Row)<{
  disabled?: boolean;
}>`
  justify-content: space-between;
  padding: ${spacing2} ${spacing2} ${spacing2} 0;
  width: 100%;
  box-sizing: border-box;

  .arrow-icon {
    visibility: hidden;
  }

  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.5;
      cursor: not-allowed;
    `
      : `
      cursor: pointer;
      &:hover {
        background-color: ${colors.surfaceHover};

        .arrow-icon {
          visibility: visible;
        }
      }
      `})
`;

const EmptyFolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spaceTiny};
  padding: 2.4rem;
  border-radius: 0.4rem;
  flex-grow: 1;
  background-color: ${colors.blue50};
  color: ${colors.textPrimary};
`;

const RoundIconWrapper = styled.div`
  background-color: ${colors.blue900};
  border-radius: 50%;
  padding: ${spacing6};
  box-sizing: border-box;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MoveNodeModal = ({
  nodeId,
  title,
  type,
  onMoveClick,
  onClose,
}: {
  nodeId: string;
  title: React.ReactNode;
  type: "project" | "folder";
  onMoveClick(toFolderNode: Node): Promise<void>;
  onClose(): void;
}) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";

  const { loadedState: allNodes } = useNodesInOrganisationState(organisationId);

  const { data: userAccess, setState: setUserAccess } = useUserAccessState();
  const { create: createNodeOrg } = useOrganisationNodeCrud();

  const nodeToMove = useMemo(
    () => allNodes.find((node) => node.id === nodeId),
    [allNodes, nodeId],
  );

  const orgNode = useMemo<Node>(
    () =>
      ({
        name: "All projects",
        id: organisationId,
        parent_id: undefined,
      }) as Node,
    [organisationId],
  );

  const fromFolder = useMemo<Node>(() => {
    return (
      allNodes.find((node) => node.id === nodeToMove?.parent_id) ?? orgNode
    );
  }, [allNodes, nodeToMove?.parent_id, orgNode]);

  const [currentFolder, setCurrentFolder] = useState<Node>(fromFolder);
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);

  const nodeChildren = useMemo(() => {
    return allNodes
      .filter((child) => child.parent_id === currentFolder.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allNodes, currentFolder.id]);

  const childFolders = useMemo(() => {
    return nodeChildren
      .filter(
        (child) => child.type === "folder" || child.type === "personal_folder",
      )
      .sort((a, b) => {
        if (a.id === nodeId) {
          return -1;
        }
        if (b.id === nodeId) {
          return 1;
        }
        if (a.type === "personal_folder") {
          return -1;
        }
        if (b.type === "personal_folder") {
          return 1;
        }
        return 0;
      });
  }, [nodeId, nodeChildren]);

  const childProjects = useMemo(() => {
    return nodeChildren.filter((child) => child.type === "project");
  }, [nodeChildren]);

  const createFolder = useCallback(
    async (folderName: string) => {
      return createNodeOrg({
        name: folderName,
        parent_id: currentFolder.id,
        type: "folder",
      }).then((res) => {
        if (res) {
          const newAllNodesAccess = {
            organisation_access: userAccess["organisation_access"],
            node_access: {
              ...userAccess["node_access"],
              [res.id]: "admin",
            },
          } as UserNodesAccess;
          setUserAccess({
            data: newAllNodesAccess,
            initialised: true,
            loading: false,
          });
        }
      });
    },
    [createNodeOrg, currentFolder.id, userAccess, setUserAccess],
  );

  // The button should be disabled if the current folder is the same as the folder we are moving from
  const disabledButton = currentFolder.id === fromFolder?.id;

  const isEmptyFolder = childFolders.length === 0 && childProjects.length === 0;

  return (
    <FullScreenModal
      onEscape={onClose}
      closeOnWrapperClick={true}
      onClick={onClose}
    >
      {showNewFolderModal && (
        <NewItemModal
          title="Create new folder"
          placeholder="Enter folder name"
          defaultValue="New folder"
          onClose={() => {
            setShowNewFolderModal(false);
          }}
          onSubmit={createFolder}
        />
      )}
      <Modal>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>{title}</h4>
          <IconBtn size="1.5rem" onClick={onClose}>
            <CloseIcon />
          </IconBtn>
        </Row>
        <Row
          style={{
            justifyContent: "flex-start",
            gap: spacing4,
            alignItems: "center",
          }}
        >
          <p>From:</p>
          <Row
            onClick={() => setCurrentFolder(fromFolder!)}
            style={{
              backgroundColor: colors.surfaceSecondary,
              alignItems: "center",
              padding: `${spacing2} ${spacing4}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {fromFolder && (
              <IconREMSize height={1.2} width={1.2} stroke={colors.textBrand}>
                <FolderOutlineIcon />
              </IconREMSize>
            )}
            <p
              style={{
                color: colors.textBrand,
              }}
            >
              {getNodeName(fromFolder) ?? "All projects"}
            </p>
          </Row>
        </Row>
        <HorizontalDivider />
        <Column
          style={{
            overflowY: "hidden",
            flexGrow: 1,
          }}
        >
          <NodeBreadCrumbs
            currentFolder={currentFolder}
            allNodes={allNodes}
            orgNode={orgNode}
            onBreadcrumbClick={setCurrentFolder}
          />
          <Column
            ref={scrollBodyRef}
            style={{
              overflowY: "auto",
              flexGrow: 1,
            }}
          >
            {isEmptyFolder && (
              <EmptyFolderWrapper>
                <RoundIconWrapper>
                  <IconREMSize height={1.6} width={1.6} stroke="white">
                    <OpenFolderIcon />
                  </IconREMSize>
                </RoundIconWrapper>
                <p>This folder is empty</p>
              </EmptyFolderWrapper>
            )}
            {childFolders.map((child) => (
              <Tooltip
                text="Cannot move the folder to itself"
                key={child.id}
                disabled={type !== "folder" || nodeId !== child.id}
                outerDivStyle={{
                  boxSizing: "border-box",
                  display: "flex",
                  width: "100%",
                }}
                innerDivStyle={{
                  display: "flex",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <HoverableRow
                  alignCenter
                  disabled={nodeId === child.id}
                  onClick={() => {
                    if (nodeId !== child.id) {
                      setCurrentFolder(child);
                    }
                  }}
                >
                  <Row alignCenter>
                    <IconREMSize height={3.2} width={3.2}>
                      {child.type === "personal_folder" ? (
                        <PersonalFolderClosedIcon />
                      ) : (
                        <FolderMenuClosedIcon />
                      )}
                    </IconREMSize>
                    <p>{getNodeName(child)}</p>
                  </Row>
                  <IconREMSize
                    className="arrow-icon"
                    height={1.2}
                    width={1.2}
                    stroke={colors.iconDisabled}
                  >
                    <ArrowRightIcon />
                  </IconREMSize>
                </HoverableRow>
              </Tooltip>
            ))}
            {childProjects.map((child) => (
              <Row
                alignCenter
                key={child.id}
                style={{
                  padding: `${spacing4} 0 ${spacing4} ${spacing4}`,
                  width: "100%",
                  boxSizing: "border-box",
                  cursor: "not-allowed",
                }}
              >
                <IconREMSize
                  height={1.6}
                  width={1.6}
                  fill={colors.textDisabled}
                >
                  <ProjectGlobeIcon />
                </IconREMSize>
                <p
                  style={{
                    color: colors.textDisabled,
                  }}
                >
                  {child.name}
                </p>
              </Row>
            ))}
          </Column>
        </Column>
        <Column>
          <HorizontalDivider />
          <Row
            alignCenter
            style={{
              justifyContent: "space-between",
            }}
          >
            <Tooltip text="Create new folder here">
              <IconBtn
                size="1.6rem"
                onClick={() => setShowNewFolderModal(true)}
              >
                <FolderAddIcon />
              </IconBtn>
            </Tooltip>
            <Row
              style={{
                justifyContent: "flex-end",
                gap: spacing4,
              }}
            >
              <Button buttonType="secondary" onClick={onClose} text="Cancel" />
              <Tooltip
                text="Cannot move to current folder"
                disabled={!disabledButton}
              >
                <Button
                  buttonType="primary"
                  text="Move"
                  disabled={disabledButton}
                  onClick={() => {
                    onMoveClick(currentFolder);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>
        </Column>
      </Modal>
    </FullScreenModal>
  );
};

export default MoveNodeModal;
