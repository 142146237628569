import * as turf from "@turf/turf";
import { getParkCenter } from "utils/parkUtils";
import { atomFamily } from "utils/jotai";
import { atom } from "jotai";
import { getBranchId, getPark, getParkId } from "analysis/inputs";
import { exportCableSplitsOkFamily } from "state/jotai/landfall";
import { exportCablesInParkFamily } from "state/jotai/exportCable";
import { substationsInParkWithTypeFamily } from "state/jotai/substation";
import { portPointsFamily } from "state/jotai/ports";
import { PortPointFeature } from "types/feature";
import { DateTime } from "luxon";
import { dateToDayMonthShortFormat } from "utils/utils";
import { FinanceId } from "finance/types";
import { HOURS_PER_DAY } from "@constants/production";

export const getClosestPortToPark = atomFamily((id: FinanceId) =>
  atom<Promise<PortPointFeature | undefined>>(async (get) => {
    const branchId = await get(getBranchId(id));
    const park = await get(getPark(id));
    const parkCenter = getParkCenter(park, []);
    const ports = await get(portPointsFamily({ branchId }));

    if (ports.length === 0) return;

    const closestPort = ports.slice(1).reduce((closest, current) => {
      const currentDistance = turf.distance(parkCenter, current, {
        units: "kilometers",
      });
      const closestDistance = turf.distance(parkCenter, closest, {
        units: "kilometers",
      });

      return currentDistance < closestDistance ? current : closest;
    }, ports[0]);

    return closestPort;
  }),
);

export const getDistanceFromPortToPark = atomFamily((id: FinanceId) =>
  atom<Promise<number | undefined>>(async (get) => {
    const park = await get(getPark(id));
    const parkCenter = getParkCenter(park, []);
    const port = await get(getClosestPortToPark(id));

    if (!port) return;

    const distance = turf.distance(port, parkCenter, {
      units: "kilometers",
    });

    return distance;
  }),
);

export const getDistancesFromPortToLandfall = atomFamily((id: FinanceId) =>
  atom<Promise<Record<string, number> | undefined>>(async (get) => {
    const parkId = await get(getParkId(id));
    const branchId = await get(getBranchId(id));

    const port = await get(getClosestPortToPark(id));

    if (!port) return;

    const exportCables = await get(
      exportCablesInParkFamily({ parkId, branchId }),
    );
    const exportCableSegments = await get(
      exportCableSplitsOkFamily({ parkId, branchId }),
    );

    const substations = await get(
      substationsInParkWithTypeFamily({ parkId, branchId }),
    );

    const distance = exportCables.reduce<Record<string, number>>((acc, c) => {
      const segments = exportCableSegments.find(
        (seg) => seg.exportCable.id === c.id,
      );
      if (!segments) {
        const connectedSubs = substations.filter(
          ([s]) =>
            s.id === c.properties.fromSubstationId ||
            s.id === c.properties.toSubstationId,
        );
        const connectedOnshoreSub = connectedSubs.filter(
          ([_, subType]) => subType.type === "onshore",
        );
        if (connectedOnshoreSub.length === 0) {
          const cableEnd1 = c.geometry.coordinates[0];
          const cableEnd2 =
            c.geometry.coordinates[c.geometry.coordinates.length - 1];
          const distanceToEnd1 = turf.distance(port, cableEnd1, {
            units: "kilometers",
          });
          const distanceToEnd2 = turf.distance(port, cableEnd2, {
            units: "kilometers",
          });
          acc[c.id] = Math.min(distanceToEnd1, distanceToEnd2);
          return acc;
        }
        const distanceToOnshoreSub = turf.distance(
          port,
          connectedOnshoreSub[0][0].geometry.coordinates,
          {
            units: "kilometers",
          },
        );
        acc[c.id] = distanceToOnshoreSub;
        return acc;
      }
      const distanceToLandfall = turf.distance(
        port,
        segments.landfallPoint.geometry.coordinates,
        {
          units: "kilometers",
        },
      );
      acc[c.id] = distanceToLandfall;
      return acc;
    }, {});

    return distance;
  }),
);

export function countUnitsInEachRound(
  weights: number[],
  maxWeight: number,
): number[] {
  let currentGroupWeight = 0;
  let unitsInCurrentGroup = 0;
  let groupSizes: number[] = [];

  if (weights.length === 0) return groupSizes;

  for (let weight of weights) {
    if (currentGroupWeight + weight <= maxWeight) {
      currentGroupWeight += weight;
      unitsInCurrentGroup++;
    } else {
      groupSizes.push(unitsInCurrentGroup);

      currentGroupWeight = weight;
      unitsInCurrentGroup = 1;
    }
  }

  groupSizes.push(unitsInCurrentGroup);

  return groupSizes;
}

export function calculateCableSpliceFractions(
  carouselLength: number,
  cableLength: number,
): number[] {
  let fractions: number[] = [];

  if (cableLength > carouselLength) {
    let fractionCount = Math.floor(cableLength / carouselLength);

    for (let i = 1; i <= fractionCount; i++) {
      fractions.push((i * carouselLength) / cableLength);
    }
  }

  fractions.push(1.0);

  return fractions;
}

export function calculateInstallationDates({
  startMonth,
  endMonth,
  totalInstallationTime,
}: {
  startMonth: number;
  endMonth: number;
  totalInstallationTime: number;
}): {
  installationYears: number;
  installationStartDate: string;
  installationEndDate: string;
} {
  let seasonStartDate = DateTime.local()
    .set({ month: startMonth })
    .startOf("month");

  let seasonEndDate = DateTime.local().set({ month: endMonth }).endOf("month");

  if (startMonth > endMonth) {
    seasonEndDate = seasonEndDate.plus({ years: 1 });
  }

  let endDate = seasonStartDate.plus({
    hours: totalInstallationTime * HOURS_PER_DAY,
  });

  let installationYears = 0;
  while (endDate > seasonEndDate) {
    installationYears += 1;

    const remainingHours = endDate.diff(seasonEndDate, "hours").hours;

    seasonStartDate = seasonStartDate.plus({ years: 1 });
    seasonEndDate = seasonEndDate.plus({ years: 1 });

    endDate = seasonStartDate.plus({ hours: remainingHours });
  }

  const installationStartDate = dateToDayMonthShortFormat(
    seasonStartDate.toJSDate(),
  );
  const installationEndDate = dateToDayMonthShortFormat(endDate.toJSDate());

  return {
    installationYears,
    installationStartDate,
    installationEndDate,
  };
}
