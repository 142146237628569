import { atomFamily } from "utils/jotai";
import { atom } from "jotai";

import { SimpleTurbineType } from "types/turbines";
import {
  Cost,
  CostUnit,
  ComponentProcurementCostForPark,
  unitToAmountUnit,
  _CostUnit,
  ConfidenceLevel,
  CostWithUnit,
} from "types/financial";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import {
  CostType,
  isOperationsCost,
  isOperationsOverrideCost,
  isTurbineFeatureCost,
  isTurbineOverrideCost,
} from "services/costService";
import { costId } from "../amounts/costIds";
import { amountId } from "../amounts/amountIds";
import { turbineTypesInParkFamily } from "finance/inputs";
import { vesselTypesFamily } from "state/jotai/vesselType";
import {
  getProjectId,
  getParkOrSubareaCenter,
  getTurbines,
} from "analysis/inputs";
import { pickLocation } from "finance/amounts/pickLocation";
import { listProcurementCostForLibraryTurbinesOnNodeAtomFamily } from "state/jotai/procurementCosts";
import {
  projectTurbineTypesFamily,
  defaultTurbineTypesFamily,
  libraryTurbineTypesFamily,
} from "state/jotai/turbineType";
import { designToolTypeAtom } from "state/map";
import { projectIdAtom } from "state/pathParams";
import { isDefined } from "utils/predicates";
import { calculateTurbineDefaultCosts } from "utils/turbineCost";
import { partition, dedup } from "utils/utils";

const projectTurbineCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, CostWithUnit & { id: string }>>>(async (get) => {
    const projectIdPromise = get(getProjectId(id));
    const costConfig = await get(getConfiguration(id));
    const projectTurbineTypes = await get(
      projectTurbineTypesFamily(await projectIdPromise),
    );
    const mode = get(designToolTypeAtom);
    const defaultTurbineTypes = await get(defaultTurbineTypesFamily(mode));

    const projectComponentCosts =
      costConfig.capex.projectComponentCosts.turbines;

    const [customCostTurbines, defaultCostTurbines] = partition(
      [...projectTurbineTypes, ...defaultTurbineTypes],
      ([id]) => id in projectComponentCosts,
    );

    const customCosts = customCostTurbines.map(([id]) => ({
      ...projectComponentCosts[id],
      confidenceLevel: ConfidenceLevel.notSpecified,
    }));

    const defaultCosts = defaultCostTurbines.map(
      ([id, { diameter, ratedPower, hubHeight }]) => ({
        id,
        cost: calculateTurbineDefaultCosts({
          diameter,
          ratedPower,
          hubHeight,
        }),
        unit: CostUnit.millionEuroPerUnit,
        confidenceLevel: ConfidenceLevel.notSpecified,
      }),
    );

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.id, entry]));
  }),
);
const libraryTurbineCostsFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Map<string, ComponentProcurementCostForPark>>>(async (get) => {
    const projectId = await get(getProjectId(id));
    const libraryTurbineTypes = await get(libraryTurbineTypesFamily(projectId));
    const libraryTurbineCosts = await get(
      listProcurementCostForLibraryTurbinesOnNodeAtomFamily(projectId),
    );

    const [customCostTurbines, defaultCostTurbines] = partition(
      [...libraryTurbineTypes],
      ([id]) => !!libraryTurbineCosts.get(id),
    );

    const parkCenter = await get(getParkOrSubareaCenter(id));

    const customCosts = customCostTurbines.map(([id, turbine]) => {
      const cost = libraryTurbineCosts.get(id);
      if (!cost) throw new Error("Library turbine cost not found");

      return {
        componentTypeId: id,
        supplyCost: cost.supplyCost
          ? {
              cost: cost.supplyCost.cost,
              unit: _CostUnit.parse(cost.supplyCost.unit),
              confidenceLevel: ConfidenceLevel.notSpecified,
            }
          : {
              cost: calculateTurbineDefaultCosts({
                diameter: turbine.diameter,
                ratedPower: turbine.ratedPower,
                hubHeight: turbine.hubHeight,
              }),
              unit: CostUnit.millionEuroPerUnit,
              confidenceLevel: ConfidenceLevel.notSpecified,
            },
        shippingCost: pickLocation(cost.shippingCost, parkCenter),
      };
    });

    const defaultCosts = defaultCostTurbines.map(([id, turbine]) => ({
      componentTypeId: id,
      supplyCost: turbine.cost
        ? {
            cost: turbine.cost,
            unit: CostUnit.millionEuroPerUnit,
            confidenceLevel: ConfidenceLevel.notSpecified,
          }
        : {
            cost: calculateTurbineDefaultCosts({
              diameter: turbine.diameter,
              ratedPower: turbine.ratedPower,
              hubHeight: turbine.hubHeight,
            }),
            unit: CostUnit.millionEuroPerUnit,
            confidenceLevel: ConfidenceLevel.notSpecified,
          },
      shippingCost: {
        cost: 0,
        unit: CostUnit.millionEuroPerUnit,
        confidenceLevel: ConfidenceLevel.notSpecified,
      },
    }));

    const entries = [...customCosts, ...defaultCosts];

    return new Map(entries.map((entry) => [entry.componentTypeId, entry]));
  }),
);

const turbineCostsInParkFamily = atomFamily((id: FinanceId) =>
  atom<Promise<ComponentProcurementCostForPark[]>>(async (get) => {
    const turbines = await get(getTurbines(id));
    const projectId = get(projectIdAtom);
    if (!projectId) return [];
    const projectTurbineCosts = await get(projectTurbineCostsFamily(id));
    const libraryTurbineCosts = await get(libraryTurbineCostsFamily(id));

    const turbineTypeIdsInPark = dedup(
      turbines.map((t) => t.properties.turbineTypeId).filter(isDefined),
    );

    const libraryCosts: ComponentProcurementCostForPark[] = turbineTypeIdsInPark
      .map((id) => libraryTurbineCosts.get(id))
      .filter(isDefined);

    const projectCosts: ComponentProcurementCostForPark[] = turbineTypeIdsInPark
      .map((id) => projectTurbineCosts.get(id))
      .filter(isDefined)
      .map(({ id, cost, unit, confidenceLevel }) => ({
        componentTypeId: id,
        supplyCost: {
          cost,
          unit,
          confidenceLevel,
        },
        shippingCost: undefined,
      }));

    return [...libraryCosts, ...projectCosts];
  }),
);

export const turbineCapexEntriesSelectorFamily = atomFamily((id: FinanceId) =>
  atom<Promise<Cost[]>>(async (get) => {
    const configuration = await get(getConfiguration(id));

    const turbineTypes: SimpleTurbineType[] = await get(
      turbineTypesInParkFamily(id),
    );

    const turbineNames = new Map(turbineTypes.map((t) => [t.id, t.name]));
    const turbineCosts: ComponentProcurementCostForPark[] = await get(
      turbineCostsInParkFamily(id),
    );
    const {
      capex: { custom, fixed, installation },
    } = configuration;

    const flatCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Turbine)
      .filter((c) => c.unit === CostUnit.millionEuro)
      .map((c) => ({
        ...c,
        id: costId({
          category: CostType.Turbine,
          costId: c.id,
        }),
        amountId: amountId({
          unit: unitToAmountUnit[c.unit],
          category: c.category,
        }),
      }));

    const customCosts: Cost[] = custom
      .filter((c) => c.category === CostType.Turbine)
      .filter((c) => c.unit !== CostUnit.millionEuro)
      .flatMap((custom) =>
        turbineTypes.map((turbineType) => ({
          ...custom,
          name: `${custom.name} ${turbineType.name}`,
          id: costId({
            category: CostType.Turbine,
            costId: custom.id,
            featureTypeId: turbineType.id,
          }),
          amountId: amountId({
            unit: unitToAmountUnit[custom.unit],
            category: custom.category,
            featureTypeId: turbineType.id,
          }),
        })),
      );

    let installationCosts: Cost[] = [];

    if (isOperationsCost(installation.turbines)) {
      const operationsConfiguration = await get(getOperationsConfiguration(id));

      const { installationVessel } = operationsConfiguration.ti.turbines;

      const vesselTypes = await get(vesselTypesFamily(undefined));

      const { dayRate, vesselId } = installationVessel;
      const { cost, unit: costUnit } = dayRate;
      const unit = _CostUnit.parse(costUnit);
      const name = vesselTypes.get(vesselId)?.name ?? "";

      installationCosts.push({
        id: `turbine_installation_${vesselId}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Turbine,
          featureTypeId: vesselId,
        }),
        category: CostType.Turbine,
        name,
        cost,
        unit,
        confidenceLevel: undefined,
      });
    } else if (isOperationsOverrideCost(installation.turbines)) {
      const { cost, unit, confidenceLevel } = installation.turbines;
      const overrideInstallationCosts = turbineTypes.map(({ id }) => ({
        id: `turbine_installation`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Turbine,
          featureTypeId: id,
        }),
        category: CostType.Turbine,
        name: "Installation",
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      }));
      installationCosts.push(...overrideInstallationCosts);
    }

    let fixedCosts: Cost[] = [];
    if (isTurbineFeatureCost(fixed.turbines)) {
      turbineCosts.forEach(
        ({
          supplyCost,
          shippingCost,
          componentTypeId: turbineTypeId,
        }: ComponentProcurementCostForPark) => {
          const turbineName = turbineNames.get(turbineTypeId);
          if (!turbineName)
            throw new Error(`Turbine name not found for id ${turbineTypeId}`);

          fixedCosts.push({
            id: `turbines_${turbineTypeId}`,
            amountId: amountId({
              unit: unitToAmountUnit[supplyCost.unit],
              category: CostType.Turbine,
              featureTypeId: turbineTypeId,
            }),
            category: CostType.Turbine,
            name: turbineName,
            cost: supplyCost.cost,
            unit: supplyCost.unit,
            confidenceLevel: undefined,
          });

          shippingCost &&
            fixedCosts.push({
              id: `turbines_${turbineTypeId}_shipping`,
              amountId: amountId({
                unit: unitToAmountUnit[shippingCost.unit],
                category: CostType.Turbine,
                featureTypeId: turbineTypeId,
              }),
              category: CostType.Turbine,
              name: `Shipping ${turbineName}`,
              cost: shippingCost.cost,
              unit: shippingCost.unit,
              confidenceLevel: undefined,
            });
        },
      );
    }
    if (isTurbineOverrideCost(fixed.turbines)) {
      const { cost, unit, confidenceLevel } = fixed.turbines;

      fixedCosts = turbineTypes.map(({ id, name }) => ({
        id: `turbines_${id}`,
        amountId: amountId({
          unit: unitToAmountUnit[unit],
          category: CostType.Turbine,
          featureTypeId: id,
        }),
        category: CostType.Turbine,
        name,
        cost: cost,
        unit: unit,
        confidenceLevel: confidenceLevel,
      }));
    }
    return [...fixedCosts, ...customCosts, ...flatCosts, ...installationCosts];
  }),
);
