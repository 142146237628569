import Dropdown from "components/Dropdown/Dropdown";
import Button from "components/General/Button";
import { Label } from "components/General/Form";
import { useRef, useState } from "react";
import styled from "styled-components";
import { StandardBox } from "styles/boxes/Boxes";
import { spaceLarge, spaceMedium } from "styles/space";
import Close from "@icons/24/Close.svg?react";
import { Row } from "components/General/Layout";
import { IconBtn } from "components/General/Icons";
import { HeaderTitle } from "components/RightSide/InfoModal/style";
import { VesselType } from "services/vesselService";
import Tooltip from "components/General/Tooltip";
import { Anchor } from "components/General/Anchor";
import AddIcon from "@icons/24/Add.svg";

const VesselWizard = ({
  vessels,
  close,
  create,
  setLoading,
}: {
  vessels: VesselType[];
  close: () => void;
  setLoading: (v: boolean) => void;
  create: ({
    vessel,
    name,
  }: {
    vessel: VesselType;
    name?: string;
  }) => Promise<any>;
}) => {
  const [status, setStatus] = useState<"failed" | "loading" | null>(null);
  const [selected, setSelected] = useState("default_installation_vessel");
  return (
    <WizardContainer>
      <Row style={{ alignItems: "center" }}>
        <HeaderTitle>Create vessel</HeaderTitle>
        <IconBtn
          size="1.4rem"
          onClick={close}
          style={{ marginLeft: "auto" }}
          disabled={status === "loading"}
        >
          <Close />
        </IconBtn>
      </Row>
      <Label>
        <p>Create from</p>
        <Dropdown
          id="vessel-generation-options"
          value={selected}
          onChange={(e) => {
            const id = e.target.value;
            setSelected(id);
          }}
        >
          {vessels.map((v) => {
            return (
              <option key={v.id} value={v.id}>
                {v.name}
              </option>
            );
          })}
        </Dropdown>
      </Label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "baseline",
          gap: spaceMedium,
          paddingTop: spaceLarge,
        }}
      >
        <Button
          disabled={status === "loading"}
          style={{ alignSelf: "end" }}
          text="Create"
          onClick={() => {
            setLoading(true);
            setStatus("loading");
            const vessel = vessels.find((t) => t.id === selected)!;
            console.log(vessel);
            create({ vessel }).then(() => {
              setStatus(null);
              setLoading(false);
            });
          }}
        />
      </div>
    </WizardContainer>
  );
};

const WizardContainer = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  height: fit-content;
  gap: 1.2rem;
  background: white;
`;

export const VesselWizardModal = ({
  create,
  setLoading,
  isLoading,
  disabled,
  tooltipText,
  vessels,
}: {
  create: ({ vessel }: { vessel: VesselType }) => Promise<any>;
  setLoading: (v: boolean) => void;
  isLoading: boolean;
  disabled?: boolean;
  tooltipText?: string | undefined;
  vessels: VesselType[];
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <Tooltip text={tooltipText ?? ""} disabled={!tooltipText}>
        <Button
          buttonType="primary"
          onClick={() => setShow(true)}
          icon={<AddIcon />}
          disabled={disabled || isLoading}
        />
        {show && (
          <Anchor baseRef={ref} basePlace={"topRight"} floatPlace={"topLeft"}>
            <VesselWizard
              close={() => setShow(false)}
              create={(vessel) => create(vessel).then(() => setShow(false))}
              setLoading={setLoading}
              vessels={vessels}
            />
          </Anchor>
        )}
      </Tooltip>
    </div>
  );
};
