import React, { useMemo } from "react";
import { useAtomValue } from "jotai";
import { v4 as uuid } from "uuid";
import { BranchMeta } from "types/api";
import {
  branchIdAtom,
  organisationIdAtom,
  projectIdAtom,
} from "state/pathParams";
import { branchMetaFamily } from "state/jotai/branch";
import { spacing1 } from "styles/space";
import { useJotaiCallback } from "utils/jotai";
import { useShowScrollShadow } from "hooks/useShowScrollShadow";
import { useBranchFolderStructure } from "components/Design/BranchTabBar/components/BranchesTable";
import {
  findItemInTree,
  getFilteredFolderTree,
  isFolderItem,
} from "business/folderStructure/utils";
import { FolderListWrapper } from "components/FolderList/style";
import { _SelectedParkCompare, selectedParksAtom } from "../../state";
import { BranchFolder, BranchRow } from "./table-items";

const _TUTORIAL_PARK_ID = "b405da7b-cc4a-46e1-a59d-24516d4d0061";

const BranchesTable = ({
  isSearching,
  branchesSearchResult,
}: {
  isSearching: boolean;
  branchesSearchResult: BranchMeta[];
}) => {
  const { scrollBodyRef } = useShowScrollShadow(true);
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const nodeId = useAtomValue(projectIdAtom) ?? "";
  const activeBranchId = useAtomValue(branchIdAtom) ?? "";
  const { folders: folderTree, orphans: orphanBranches } =
    useBranchFolderStructure({ nodeId });

  const folderTreeAfterSearching = useMemo(() => {
    if (!isSearching) {
      return folderTree;
    }

    const filteredBranchIds = branchesSearchResult.map((b) => b.id);
    return getFilteredFolderTree(folderTree, filteredBranchIds);
  }, [branchesSearchResult, folderTree, isSearching]);

  const handleOnAddParkClick = useJotaiCallback(
    async (get, set, branchId: string, parkId: string) => {
      const branch = await get(
        branchMetaFamily({
          projectId: nodeId,
          branchId: branchId,
        }),
      );
      // @ts-ignore I dont know why this is not working
      set(selectedParksAtom({ projectId: nodeId }), (curr) => {
        const newItem = _SelectedParkCompare.parse({
          comparisonId: uuid(),
          parkId: parkId,
          branchId,
          selectedAnalysisConfigurationId: branch?.analysisConfigurationId,
          selectedCostConfigurationId: branch?.costConfigurationId,
          selectedWindConfigurationId: branch?.windConfigurationId,
          selectedOperationsConfigurationId: branch?.operationsConfigurationId,
        });

        return (curr ?? []).concat([newItem]);
      });
    },
    [nodeId],
  );

  return (
    <FolderListWrapper
      ref={scrollBodyRef}
      style={{
        gap: spacing1,
      }}
    >
      {folderTreeAfterSearching.filter(isFolderItem).map((item) => {
        const containsActiveBranch = Boolean(
          findItemInTree(activeBranchId, item.children),
        );
        return (
          <BranchFolder
            key={item.id}
            folder={item}
            shouldForceOpen={isSearching || containsActiveBranch}
            depth={0}
            folderTreeContainsActiveFolder={containsActiveBranch}
            activeBranchId={activeBranchId}
            showControls={false}
            addPark={(branchId: string, parkId: string) =>
              handleOnAddParkClick(branchId, parkId)
            }
          >
            {(resourceId, _parentFolder, _depth, _index) => {
              const branch = branchesSearchResult.find(
                (b) => b.id === resourceId,
              );
              if (!branch) {
                return null;
              }

              return (
                <BranchRow
                  key={branch.id}
                  branch={branch}
                  active={branch.id === activeBranchId}
                  onAddParkClick={handleOnAddParkClick}
                  nodeId={nodeId}
                  organisationId={organisationId}
                  depth={_depth + 1}
                />
              );
            }}
          </BranchFolder>
        );
      })}
      {orphanBranches.map((item, _index) => {
        const branch = branchesSearchResult.find((b) => b.id === item.id);
        if (!branch) {
          return null;
        }
        return (
          <BranchRow
            key={branch.id}
            branch={branch}
            active={branch.id === activeBranchId}
            onAddParkClick={handleOnAddParkClick}
            nodeId={nodeId}
            organisationId={organisationId}
            depth={0}
          />
        );
      })}
    </FolderListWrapper>
  );
};

export default BranchesTable;
