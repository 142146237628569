import * as turf from "@turf/turf";
import { getTurbineCapacity } from "analysis/inputs";
import {
  getAEP,
  getAnalysisProgress,
  getCapacityFactor,
  getGrossEnergy,
  getTotalLoss,
} from "analysis/output";
import { orTextLoader } from "components/Loading/Skeleton";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { undefMap } from "utils/utils";
import { spaceMedium } from "../../../styles/space";
import { FlexGrid2 } from "../../General/Form";
import { Row } from "../../General/Layout";
import {
  formatGWConditional,
  formatGWhConditional,
  formatLossToPercent,
  formatPercent,
} from "../../ProductionV2/format";
import { useDashboardContext } from "../Dashboard";
import { SafeCard } from "./Base";

const EnergyDetailsInner = () => {
  const { park, triggerId } = useDashboardContext();

  const capacity = useAtomValue(getTurbineCapacity(triggerId));
  const area = Math.round(turf.area(park) / (1000 * 1000));
  const density = (capacity / area).toPrecision(3);

  const aep = useAtomValue(loadable(getAEP(triggerId)));
  const gross = useAtomValue(loadable(getGrossEnergy(triggerId)));
  const totalLoss = useAtomValue(loadable(getTotalLoss(triggerId)));
  const capacityFactor = useAtomValue(loadable(getCapacityFactor(triggerId)));
  const analysisProgress = useAtomValue(getAnalysisProgress(triggerId));

  return (
    <FlexGrid2 style={{ padding: spaceMedium }}>
      <Row>
        <p>Capacity</p>
        <p>{formatGWConditional(capacity / 1e3)}</p>
      </Row>

      <Row>
        <p>Gross energy</p>
        {orTextLoader(
          gross,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (g) => (
            <p>{formatGWhConditional(g)}</p>
          ),
        )}
      </Row>

      <Row>
        <p>Net energy</p>
        {orTextLoader(
          aep,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (c) => (
            <p>{formatGWhConditional(c)}</p>
          ),
        )}
      </Row>

      <Row>
        <p>Total loss</p>
        {orTextLoader(
          totalLoss,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (l) => (
            <p>{formatLossToPercent(l)}%</p>
          ),
        )}
      </Row>

      <Row>
        <p>Capacity density</p>
        <p>{density} MW / km²</p>
      </Row>

      <Row>
        <p>Capacity factor</p>
        {orTextLoader(
          capacityFactor,
          undefMap(analysisProgress, (p) => `${Math.round(p * 100)}%`) ?? "",
          (cf) => (
            <p>{formatPercent(cf)}</p>
          ),
        )}
      </Row>
    </FlexGrid2>
  );
};

export const EnergyWidget = () => {
  const { errorBoundaryResetKeys } = useDashboardContext();

  return (
    <SafeCard
      title="Energy"
      id="Energy"
      resetKeys={errorBoundaryResetKeys}
      needAnalysis
    >
      <EnergyDetailsInner />
    </SafeCard>
  );
};
