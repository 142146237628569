import { useAtom } from "jotai";
import { existingTurbinesInputAtom } from "../../state/layer";
import { MenuFrame } from "../MenuPopup/CloseableMenuPopup";
import { Row } from "components/General/Layout";
import { TypeDot } from "components/General/Icons";
import { RangeSlider } from "components/General/Slider";
import { colors } from "styles/colors";
import {
  leftModalMenuOpenStateAtom,
  LeftModalMenuTypes,
} from "components/LowerLeftV2/state";

const ExistingTurbinesMenu = () => {
  const [leftModalMenuOpen, setLeftModalMenuOpen] = useAtom(
    leftModalMenuOpenStateAtom,
  );
  const [input, setInput] = useAtom(existingTurbinesInputAtom);
  if (leftModalMenuOpen !== LeftModalMenuTypes.ExistingTurbines) return null;

  return (
    <MenuFrame
      title="Existing turbines"
      onExit={() => setLeftModalMenuOpen(undefined)}
      icon={<TypeDot dotColor={colors.indigo600} />}
      style={{
        position: "absolute",
        bottom: `calc(var(--side-bars-width) + 0.8rem)`,
        right: 0,
        gap: "0.8rem",
      }}
    >
      <p>
        The existing turbines datalayer is an aggregation from{" "}
        <a
          href="https://openinframap.org/#2/26/12"
          rel="noreferrer"
          target="_blank"
        >
          Open Infrastructure Map
        </a>
      </p>
      <Row
        style={{ alignItems: "center", gap: "0.8rem", paddingTop: "1.6rem" }}
      >
        <p>Filter on power-rating (MW)</p>
      </Row>
      <Row style={{ padding: "1.6rem 3rem 1.6rem 3rem" }}>
        <RangeSlider
          min={0}
          max={15}
          values={[input.minPower, input.maxPower]}
          inside
          renderLabel={(f) => `${f === 0 ? "Unknown" : f}`}
          labels
          step={0.5}
          onChange={function (f: [number, number]): void {
            setInput({
              minPower: f[0],
              maxPower: f[1],
            });
          }}
          style={{ flex: 1 }}
        />
      </Row>
    </MenuFrame>
  );
};

export default ExistingTurbinesMenu;
