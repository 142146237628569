import { atomFamily } from "utils/jotai";
import { atomWithDefault } from "jotai/utils";
import { FolderOrResourceItem } from "types/folderStructures";
import { sortFolderTree } from "business/folderStructure/utils";
import { getFoldersForType } from "services/folderStructureService";

export const branchFolderStructureFamily = atomFamily(
  ({ nodeId }: { nodeId: string }) =>
    atomWithDefault<Promise<FolderOrResourceItem[]>>(async (_get) => {
      return getFoldersForType(nodeId, "BRANCH").then((folderOrResources) => {
        return folderOrResources.sort(sortFolderTree);
      });
    }),
);
