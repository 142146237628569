import { useState, useCallback, useEffect, useRef } from "react";
import { IntercomArticle, searchIntercomArticles } from "../service";
import { SearchResultItem } from "../state";
import ExpandIcon from "@icons/24/IntercomHelp.svg?react";

export function useSearchIntercom(searchTerm: string) {
  const [articles, setArticles] = useState<SearchResultItem<IntercomArticle>[]>(
    [],
  );
  const [searchIntercomInProgress, setSearchIntercomInProgress] =
    useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const searchIntercom = useCallback(async (phrase: string) => {
    if (phrase.length === 0) return [];

    const data = await searchIntercomArticles(phrase);

    if (isMounted.current) {
      const articlesWithOnSelect = data.articles.map((article) => ({
        id: article.id,
        item: article,
        name: article.title,
        score: 1,
        icon: <ExpandIcon />,
        onSelect: () => {
          window.Intercom("showArticle", parseInt(article.id));
        },
      }));
      setArticles(articlesWithOnSelect);
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setSearchIntercomInProgress(true);
    }
    const timeout = setTimeout(
      () =>
        searchIntercom(searchTerm).finally(() => {
          setSearchIntercomInProgress(false);
        }),
      400,
    );
    return () => {
      clearTimeout(timeout);
      if (isMounted.current) {
        setSearchIntercomInProgress(false);
      }
    };
  }, [searchTerm, searchIntercom]);

  return { articles, searchIntercomInProgress };
}
