import { useJotaiCallback } from "utils/jotai";
import { FoundationType } from "types/foundations";
import { libraryFoundationTypesFamily } from "state/jotai/foundation";
import { NodeFoundationAccess } from "components/Organisation/Library/types";

export function useLibraryNodeFoundations(projectId: string) {
  const updateLocal = useJotaiCallback(
    async (_, set, foundation: FoundationType) => {
      set(
        libraryFoundationTypesFamily(projectId),
        async (currentFoundationsPromise: Promise<NodeFoundationAccess[]>) => {
          const currentFoundations = await currentFoundationsPromise;
          const newFoundations = new Map<string, NodeFoundationAccess>(
            currentFoundations.map((node) => [node.foundation.id, node]),
          );

          const newNode: NodeFoundationAccess = {
            nodeId: projectId,
            foundation: foundation,
          };

          newFoundations.set(foundation.id, newNode);
          return Array.from(newFoundations.values());
        },
      );
    },
    [projectId],
  );

  return {
    updateLocal,
  };
}
