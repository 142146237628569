import { IControl, Map, SymbolLayerSpecification } from "mapbox-gl";
import { leftMenuModeActiveAtom } from "./filter";
import { imageToGeorefAtom } from "./georef";
import { inReadOnlyModeSelector } from "./project";
import {
  GenerateFoundationsMenuType,
  GenerateWindTurbinesAndFoundationsMenuType,
  SiteLocatorMenuType,
} from "../constants/projectMapView";
import { ProjectElementMenuType, UploadMenuParkType } from "../constants/park";
import { WindSourceMenuType } from "../components/WindWaveSource/utils";
import { SelectionType } from "../services/types";
import { AddFeatureOptions, DesignToolMode } from "../types/map";
import { GenerateCablesMenuType } from "../constants/cabling";
import { WaveDataSource } from "./waveStatistics";
import {
  BathymetryStyleId,
  getBathymetryStyle,
  getOtherMap,
  OtherMapId,
  MapStyle,
  mapStylesAtom,
  MapType,
  satelliteMapStyleId,
} from "../constants/availableMapStyles";
import { cableEditModeAtom } from "../components/Cabling/Generate/state";
import { z } from "zod";
import { Feature } from "geojson";
import { editorAccessProjectSelector } from "./user";
import { atom } from "jotai";
import { atomFamily, atomLocalStorage } from "utils/jotai";
import { modalTypeOpenAtom } from "./modal";
import { colors } from "styles/colors";
import { WindDataSource } from "types/metocean";

export const defaultDepthRasterMinMax: [number, number] = [0, 700];

const depthRasterMinMaxAtom = atom<[number, number]>(defaultDepthRasterMinMax);

export const designToolTypeAtom = atom<DesignToolMode>(DesignToolMode.Offshore);

export const depthRasterMinMaxSelector = atom(
  (get) => {
    const bathymetryStyle = get(activeBathymetryStyleIdAtom);
    return bathymetryStyle === "raster"
      ? get(depthRasterMinMaxAtom)
      : defaultDepthRasterMinMax;
  },
  (_, set, newValue: [number, number]) => {
    set(depthRasterMinMaxAtom, newValue);
  },
);

export const defaultWindSpeedRasterMinMax: [number, number] = [5, 13];

export const windSpeedRasterMinMaxAtom = atom<[number, number]>(
  defaultWindSpeedRasterMinMax,
);

export const mapAtom = atom<undefined | Map>(undefined);

export const mapboxTerrainDemTileUrlFamily = atomFamily(
  ({ x, y, z }: { x: number; y: number; z: number }) =>
    atom<string>((get) => {
      const map = get(mapAtom);
      if (!map) throw new Error("Map not found");
      return (map as any)._requestManager.normalizeTileURL(
        `mapbox://raster/mapbox.mapbox-terrain-dem-v1/${z}/${x}/${y}.webp`,
      ) as string;
    }),
);

export const mapTypeAtom = atomLocalStorage<MapType>(
  "vind:map:map-type",
  MapType.FLAT,
  z.nativeEnum(MapType),
);

export const mapControlsAtom = atom<undefined | (MapboxDraw & IControl)>(
  undefined,
);

type WindPoint = {
  source: WindDataSource;
  position: number[];
};

type WavePoint = {
  source: WaveDataSource;
  position: number[];
};

export const measureWindPointAtom = atom<undefined | WindPoint>(undefined);

export const windPointsSource = atom<WindDataSource>(WindDataSource.ERA5);

export const wavePointsSource = atom<WaveDataSource>(WaveDataSource.ERA5);

export const measureWavePointAtom = atom<undefined | WavePoint>(undefined);

export const windPointHeightAtom = atomLocalStorage(
  "vind:wind-measurement:height",
  150,
  z.number().min(0).max(1000),
);

export const editFeaturesAtom = atom<SelectionType[]>([]);

export const addFeatureAtom = atom<
  | undefined
  | {
      mode: MapboxDraw.DrawMode | "draw_circle" | "draw_rectangle";
      options?: AddFeatureOptions;
      continuePlacing?: boolean;
      getPropertiesFunc?: (
        features: Feature,
      ) => Record<string, unknown> | Error;
    }
>(undefined);

const leftMenuAllowedInteraction = [
  ProjectElementMenuType,
  UploadMenuParkType,
  GenerateWindTurbinesAndFoundationsMenuType,
  GenerateCablesMenuType,
  GenerateFoundationsMenuType,
  SiteLocatorMenuType,
];

export const mapInteractionSelector = atom(async (get) => {
  const leftMenuActive = get(leftMenuModeActiveAtom);
  const editInProgress = get(editFeaturesAtom).length > 0;
  const modalTypeOpen = get(modalTypeOpenAtom);
  return {
    select:
      !get(imageToGeorefAtom) &&
      (leftMenuActive == null ||
        leftMenuAllowedInteraction.includes(leftMenuActive)) &&
      !editInProgress,
    hover:
      !get(imageToGeorefAtom) &&
      (leftMenuActive == null ||
        leftMenuAllowedInteraction.includes(leftMenuActive)) &&
      !editInProgress,
    unselectOnClickOutside:
      !get(imageToGeorefAtom) &&
      leftMenuActive == null &&
      !editInProgress &&
      !get(cableEditModeAtom),
    createGeneratePolygon:
      !get(imageToGeorefAtom) &&
      leftMenuActive !== WindSourceMenuType &&
      !editInProgress &&
      !get(addFeatureAtom),
    canvasLayerPopupOptions:
      !get(imageToGeorefAtom) && !editInProgress && !get(addFeatureAtom),
    deleteFeatureKeyboardShortcut: !modalTypeOpen && !get(imageToGeorefAtom),
    leftSideMenuActive: !get(imageToGeorefAtom),
    projectControl: get(mapInteractionSelectorProjectControl),
    undoredo: !get(imageToGeorefAtom) && !modalTypeOpen,
    paste:
      get(editorAccessProjectSelector) &&
      !modalTypeOpen &&
      !get(imageToGeorefAtom) &&
      !get(inReadOnlyModeSelector),
    copy: !modalTypeOpen && !get(imageToGeorefAtom),
    selectTurbineInLayout:
      get(leftMenuModeActiveAtom) !==
      GenerateWindTurbinesAndFoundationsMenuType,
    rightClickInMap: !editInProgress,
  };
});

export const mapInteractionSelectorProjectControl = atom((get) => {
  return !get(imageToGeorefAtom);
});

export const currentMapStyleAtom = atom<MapStyle>((get) => {
  const styles = get(mapStylesAtom);
  const mapStyleId = get(_storedMapStyleIdAtom);
  return styles.find((s) => s.id === mapStyleId) ?? styles[0];
});

export const defaultMapTextPaintAtom = atom<SymbolLayerSpecification["paint"]>(
  (get) => {
    const currentMapStyle = get(currentMapStyleAtom);
    if (currentMapStyle.id === satelliteMapStyleId) {
      return {
        "text-color": colors.white,
        "text-halo-color": colors.black,
        "text-halo-width": 2,
      };
    }
    return {
      "text-color": colors.black,
      "text-halo-color": colors.white,
      "text-halo-width": 2,
    };
  },
);

// NOTE: store this in a separate atom so that we can guarantee that the id we
// read out from localstorage correspond to an available style, by going
// through {@link currentMapStyleAtom}.
const _storedMapStyleIdAtom = atomLocalStorage<string>(
  "vind:selected-map-style",
  "",
  z.string(),
);

export const currentMapStyleIdAtom = atom<string, [string], void>(
  (get) => get(currentMapStyleAtom).id,
  (_get, set, update) => {
    set(_storedMapStyleIdAtom, update);
  },
);

export const activeBathymetryStyleIdAtom = atomLocalStorage<
  BathymetryStyleId | undefined
>("vind:selected-bathymetry-style", "raster", z.string().optional());

export const activeBathymetryStyleSelector = atom((get) => {
  const id = get(activeBathymetryStyleIdAtom);
  return getBathymetryStyle(id);
});

export const contourStepSizeAtom = atomLocalStorage(
  "vind:map:contour-step-size",
  25,
  z.number(),
);

export const activeOtherMapIdAtom = atom<OtherMapId | undefined>(undefined);
export const getActiveOtherMapSelector = atom((get) => {
  const otherMapId = get(activeOtherMapIdAtom);
  return getOtherMap(otherMapId);
});
