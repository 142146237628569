import styled from "styled-components";
import { colors } from "../../styles/colors";
import { spaceDecent, spaceLarge, spaceTiny } from "../../styles/space";
import { typography } from "../../styles/typography";

export const ConfidenceBar = styled.div`
  display: flex;
  height: 4px;
  padding: 1rem 0;
`;

export const ConfidenceSegment = styled.div<{
  color: string;
  first: boolean;
  last: boolean;
}>`
  display: flex;
  height: 4px;
  width: 100%;

  background-color: ${(props) => props.color};
  border-top-left-radius: ${(p) => (p.first ? "4px" : "0")};
  border-bottom-left-radius: ${(p) => (p.first ? "4px" : "0")};
  border-top-right-radius: ${(p) => (p.last ? "4px" : "0")};
  border-bottom-right-radius: ${(p) => (p.last ? "4px" : "0")};
`;

export const ConfidenceSegmentWrapper = styled.div<{
  flex: number;
}>`
  flex: ${(p) => p.flex};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaceLarge} 2px;
  gap: ${spaceTiny};
`;
export const BottomTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.6rem;
  gap: ${spaceLarge};
`;

export const SumTotalValue = styled.div`
  ${typography.body}
  strong {
    ${typography.sub1}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: auto;
  border-radius: 50%;
  background-color: ${colors.blue100};

  svg {
    overflow: initial;
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;

export const RowKey = styled.label`
  ${typography.contentAndButtons}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-direction: row;
  gap: 1rem;
  h4 {
    margin: 0;
  }
`;

export const RowValue = styled.span`
  ${typography.label}
  display: flex;
  justify-content: flex-end;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  padding-bottom: 1.2rem;
`;

export const KeyValueGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: baseline;
  padding-bottom: ${spaceDecent};
`;

export const ResultWrapper = styled.div`
  ${typography.h4}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    path {
      stroke: ${colors.iconInfo};
    }
  }
`;
