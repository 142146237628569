import { useAtomValue } from "jotai";
import { mapAtom } from "state/map";
import { branchIdAtom, projectIdAtom } from "state/pathParams";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGoToFeatures } from "../../../hooks/map";
import { toastMessagesAtom } from "../../../state/toast";
import {
  selectedThreadAtomFamily,
  threadsAtomFamily,
} from "../../Comments/state";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { featureMapFamily } from "state/jotai/features";

export default function ZoomToComment({
  setHasZoomed,
}: {
  setHasZoomed: (hasZoomed: boolean) => void;
}) {
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const setToastMessagesAtom = useSetAtom(toastMessagesAtom);

  const setSelectedThread = useSetAtom(
    selectedThreadAtomFamily({
      nodeId: projectId,
    }),
  );
  const map = useAtomValue(mapAtom);
  const goToFeatures = useGoToFeatures(map);
  const zoomToComment = useAtomCallback(
    useCallback(
      async (get, set, branchId: string, threadId: string) => {
        const threads = await get(
          threadsAtomFamily({
            nodeId: projectId,
            branchId,
          }),
        );
        const thread = threads.find((t) => t.threadId === threadId);

        if (!thread) {
          setToastMessagesAtom((state) => [
            ...state,
            {
              text: "Comment thread not found",
              type: "error",
              timeout: 6000,
            },
          ]);
        } else if (thread.featureId) {
          const featureMap = await get(
            featureMapFamily({
              branchId,
            }),
          );
          const feature = featureMap.get(thread.featureId);
          if (feature) {
            setTimeout(() => goToFeatures([feature]), 10); // avoid race condition on initialization
          }
        }
      },
      [projectId, goToFeatures, setToastMessagesAtom],
    ),
  );

  useEffect(() => {
    const commentId = searchParams.get("commentId");
    if (commentId && branchId && map) {
      const func = async () => {
        setSelectedThread(commentId);
        await zoomToComment(branchId, commentId);
        setHasZoomed(true);
        searchParams.delete("commentId");
        setSearchParams(searchParams);
      };
      func();
    }
  }, [
    branchId,
    searchParams,
    setHasZoomed,
    setSearchParams,
    setSelectedThread,
    zoomToComment,
    map,
  ]);

  return null;
}
