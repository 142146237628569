import { atom, useAtom } from "jotai";
import { readVersionOfNewFeatureNotificationAtom } from "./state";
import { Column, Row } from "components/General/Layout";
import { Icon, IconBtn } from "components/General/Icons";
import Alarm from "@icons/24/Alarm.svg?react";
import OpenIcon from "@icons/24/NewWindow.svg?react";
import { colors } from "styles/colors";
import { useRef } from "react";
import { Anchor, AnchorClassName } from "components/General/Anchor";
import {
  NotificationItem,
  NotificationText,
  PopupContainer,
  FloatingUnreadNotification,
  UnreadDot,
} from "./style";
import { useClickOutside } from "hooks/useClickOutside";

// Increase this number to show the notification again.
const latestVersion = 6;

export const notificationOpenState = atom(false);

export default function Notifications() {
  const [notificationVersion, setNotificationVersion] = useAtom(
    readVersionOfNewFeatureNotificationAtom,
  );
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useAtom(notificationOpenState);

  const showNotification = notificationVersion < latestVersion;

  return (
    <Column ref={ref}>
      <IconBtn
        size="1.6rem"
        iconColor="#ffffff"
        hoverBackgroundColor={colors.grey900}
        hoverStroke={colors.blue300}
        style={{ cursor: "pointer", position: "relative" }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Alarm />
        <FloatingUnreadNotification active={showNotification} />
      </IconBtn>
      {open && (
        <Anchor baseRef={ref} basePlace="bottom" floatPlace="top">
          <ModalContainer close={() => setOpen(false)}>
            <Column
              style={{ gap: 0, padding: "0.4rem 0" }}
              onClick={() => setNotificationVersion(latestVersion)}
            >
              <NotificationItem
                onClick={() => {
                  window.Intercom("showArticle", 8586960);
                }}
              >
                <Row alignCenter style={{ gap: "0.4rem" }}>
                  <UnreadDot
                    className="test"
                    id="test"
                    onClick={(e) => {
                      e.stopPropagation();
                      setNotificationVersion(latestVersion);
                    }}
                    active={showNotification}
                  />
                  <NotificationText>New feature alert!</NotificationText>
                </Row>
                <Icon style={{ alignItems: "center" }} size="1.4rem">
                  <OpenIcon />
                </Icon>
              </NotificationItem>
            </Column>
          </ModalContainer>
        </Anchor>
      )}
    </Column>
  );
}

function ModalContainer({
  children,
  close,
}: {
  children: React.ReactNode;
  close: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(
    ref,
    (e) => {
      e.stopPropagation();
      close();
    },
    (target) =>
      target instanceof HTMLElement &&
      target.classList.contains(AnchorClassName),
    { ignoreDragClicks: true },
  );

  return <PopupContainer ref={ref}>{children}</PopupContainer>;
}
