import { useMemo } from "react";
import { useSetAtom } from "jotai";
import { featuresListAtom } from "state/jotai/features";
import { useGoToFeaturesIds } from "hooks/map";
import { ProjectFeature } from "types/feature";
import { currentSelectionArrayAtom } from "state/selection";
import { useSearch } from "./useSearch";
import { SearchItem, SearchResultItem } from "../state";

type SearchableFeature = ProjectFeature & {
  properties: {
    name?: string;
    [key: string]: any;
  };
};

const keys = ["properties.name"];

export function useSearchFeatures(searchTerm: string) {
  const { filteredResults, rawData } = useSearch<SearchableFeature>(
    true,
    searchTerm,
    keys,
    featuresListAtom,
  );

  const setCurrentSelection = useSetAtom(currentSelectionArrayAtom);
  const goToFeaturesIds = useGoToFeaturesIds();

  const filteredSearchItems: SearchResultItem<SearchableFeature>[] =
    useMemo(() => {
      return filteredResults.map((result) => ({
        id: result.item.id,
        name: result.item.properties.name,
        score: result.score,
        item: result.item,
        onSelect: () => {
          if (!result.item.id) return;
          setCurrentSelection([result.item.id]);
          goToFeaturesIds([result.item.id], undefined, {
            padding: 50,
            maxZoom: 16,
          });
        },
      }));
    }, [filteredResults, setCurrentSelection, goToFeaturesIds]);

  const searchableItems: SearchItem<SearchableFeature>[] = useMemo(() => {
    if (!rawData) return [];
    return rawData?.map((item) => ({
      id: item.id,
      name: item.properties.name,
      item: item,
      onSelect: () => {
        setCurrentSelection([item.id]);
        goToFeaturesIds([item.id], undefined, {
          padding: 50,
          maxZoom: 16,
        });
      },
    }));
  }, [rawData, setCurrentSelection, goToFeaturesIds]);

  return {
    filteredFeatures: filteredSearchItems,
    rawData,
    searchableItems,
  };
}
