import {
  ExternalDataSource,
  SourceTypes,
  SourceTypesLayer,
  _ExternalDataSource,
} from "../types/layers";
import {
  LayerForDataCleaning,
  SourceForDataCleaning,
} from "../state/internalDataCleaning";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { scream } from "../utils/sentry";

export const postNewPrivateDataSource = async (
  type: SourceTypes,
  source: string,
  url: string,
  nodeId: string,
) =>
  fetchEnhancerWithToken(`/api/gis-data/private_custom_data_layer/${nodeId}`, {
    method: "post",
    body: JSON.stringify({
      type,
      source,
      url,
    }),
  });

export const getPrivateDataSources = async (nodeId: string) =>
  fetchEnhancerWithToken(`/api/gis-data/private_custom_data_layer/${nodeId}`, {
    method: "get",
  });

export const deletePrivateDataSource = async (
  nodeId: string,
  sourceId: string,
) =>
  fetchEnhancerWithToken(
    `/api/gis-data/private_custom_data_layer/${nodeId}/${btoa(sourceId)}`,
    {
      method: "delete",
    },
  );

export const getExternalDataSources = async (): Promise<ExternalDataSource[]> =>
  fetchSchemaWithToken(
    _ExternalDataSource.array(),
    `/api/gis-data/external_data_layers`,
    {
      method: "get",
    },
  );

export const updateExternalDataLayer = async (
  layer: LayerForDataCleaning,
): Promise<any> =>
  fetchEnhancerWithToken(
    `/api/gis-data/external_data_layers/${layer.layerId}`,
    {
      method: "PUT",
      body: JSON.stringify(layer),
    },
  );

export const updateExternalDataSource = async (
  source: SourceForDataCleaning,
): Promise<any> =>
  fetchEnhancerWithToken(
    `/api/gis-data/external_data_sources/${source.sourceId}`,
    {
      method: "PUT",
      body: JSON.stringify(source),
    },
  );

export const addNewSource = async (
  sourceName: string,
): Promise<ExternalDataSource> => {
  const response = await fetchEnhancerWithToken(`/api/gis-data/source`, {
    method: "POST",
    body: JSON.stringify({ sourceName }),
  });

  const json = await response.json();

  if (!response.ok) {
    scream("Could not add new source", { json });
    if (response.status === 409) {
      throw new Error("There is already a source with this name.");
    }
    throw new Error("Could not add new source, see console");
  }

  return _ExternalDataSource.parse(json);
};

const sourceTypeToSourceTypesLayer = {
  [SourceTypes.wms]: SourceTypesLayer.wms,
  [SourceTypes.arcgis_rest_api]: SourceTypesLayer.arcgis,
  [SourceTypes.wfs]: SourceTypesLayer.wfs,
  [SourceTypes.wmts]: SourceTypesLayer.wmts,
} as Record<SourceTypes, SourceTypesLayer>;

export const addNewEndpointToSource = async (
  sourceId: string,
  sourceType: SourceTypes,
  url: string,
  licenseType?: string,
  urlWhereFound?: string,
  attribution?: string,
  licenseUrl?: string,
) => {
  const response = await fetchEnhancerWithToken(
    `/api/gis-data/source/${sourceId}/${sourceTypeToSourceTypesLayer[sourceType]}`,
    {
      method: "POST",
      body: JSON.stringify({
        url,
        licenseType,
        urlWhereFound,
        attribution,
        licenseUrl,
      }),
    },
  );
  const json = await response.json();

  if (!response.ok) {
    const text = await response.text();
    scream("Could not add new source endpoint", { json, text });
    throw new Error(text);
  }

  return _ExternalDataSource.parse(json);
};
