import { Link } from "react-router-dom";

export const EmptyStateDescription = ({
  organisationId,
  type,
  isOrgAdmin,
}: {
  organisationId: string;
  type: "tags" | "stages";
  isOrgAdmin: boolean;
}) => (
  <>
    {isOrgAdmin ? (
      <>
        Create {type} to help organize your parks. You can add {type} to your
        parks from the park menu. Use {type} to filter parks in{" "}
        <Link to={`/organisation/${organisationId}/portfolio`}>Portfolio</Link>.
      </>
    ) : (
      <>Contact your organisation admin to create {type}.</>
    )}
  </>
);
