import { TriggerCurrentFinance } from "components/Finance/Triggers";
import { Suspense } from "react";
import {
  branchIdAtom,
  branchIdAtomDef,
  organisationIdAtom,
  parkIdAtom,
  projectIdAtom,
  projectIdAtomDef,
} from "state/pathParams";
import { partition } from "utils/utils";
import useComponentsInUse from "./useComponentsInUse";
import { useBathymetry } from "hooks/bathymetry";
import useWindDataUrl from "./useWindDataUrl";
import { ParkFeature } from "types/feature";
import useProductionNumbers from "./useProductionNumbers";
import { ARCHIVE_DATA_VERSION } from "./utils";
import { atom, useAtomValue } from "jotai";
import { parkFamily } from "state/jotai/park";
import { featuresListAtom } from "state/jotai/features";
import { analysisConfigurationSelectedFamily } from "state/jotai/analysisConfiguration";
import { costConfigurationSelectedFamily } from "state/jotai/costConfiguration";
import { windConfigurationSelectedFamily } from "state/jotai/windConfiguration";
import { operationsConfigurationSelectedFamily } from "state/jotai/operationsConfiguration";

const currentConfigsAtom = atom(async (get) => {
  const projectId = get(projectIdAtomDef);
  const branchId = get(branchIdAtomDef);

  const analysis = get(
    analysisConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const wind = get(
    windConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const cost = get(
    costConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  const operations = get(
    operationsConfigurationSelectedFamily({
      projectId,
      branchId,
    }),
  );
  return {
    selectedAnalysisConfig: await analysis,
    selectedWindConfig: await wind,
    selectedCostConfig: await cost,
    selectedOperationsConfig: await operations,
  };
});

export default function ArchiveProjectVersion() {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectId = useAtomValue(projectIdAtom);
  const branchId = useAtomValue(branchIdAtom);
  const parkId = useAtomValue(parkIdAtom);
  const park = useAtomValue(parkFamily({ parkId: parkId ?? "", branchId }));

  if (!organisationId || !projectId || !branchId || !parkId || !park) {
    return <div>Invalid path</div>;
  }

  return (
    <Suspense fallback={<div>ArchiveProjectVersion loading...</div>}>
      <TriggerCurrentFinance
        fallback={<div>Loading...</div>}
        projectId={projectId}
        branchId={branchId}
        parkId={parkId}
      >
        <ArchiveProjectVersionInner
          organisationId={organisationId}
          projectId={projectId}
          branchId={branchId}
          parkId={parkId}
          park={park}
        />
      </TriggerCurrentFinance>
    </Suspense>
  );
}

function ArchiveProjectVersionInner({
  projectId,
  branchId,
  parkId,
  park,
}: {
  organisationId: string;
  projectId: string;
  branchId: string;
  parkId: string;
  park: ParkFeature;
}) {
  const allBranchFeatures = useAtomValue(featuresListAtom);
  const [selectedParkFeatures, remainingFeatures] = partition(
    allBranchFeatures,
    (f) => !!f.properties.parentIds?.includes(parkId),
  );

  const productionAndWindDataFromCompare = useProductionNumbers(
    projectId,
    branchId,
    parkId,
    park,
  );

  const {
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
  } = useComponentsInUse();

  const {
    selectedAnalysisConfig,
    selectedWindConfig,
    selectedCostConfig,
    selectedOperationsConfig,
  } = useAtomValue(currentConfigsAtom);

  const [bathymetry] = useBathymetry({
    projectId,
    featureId: parkId,
    bufferKm: 2,
    branchId: undefined,
  });

  // Wind data url
  const windDataUrl = useWindDataUrl(projectId);

  // Calcualte progress
  const windDataUrlComplete = windDataUrl !== null;
  const bathymetryComplete = bathymetry.state === "hasData";
  const compareDataComplete =
    productionAndWindDataFromCompare.status === "complete";

  // count number of steps complete
  const stepsComplete = [
    windDataUrlComplete,
    bathymetryComplete,
    compareDataComplete,
  ].filter((x) => x).length;

  console.log(`Progress: ${stepsComplete} / 3`);
  // Print the key of the steps that is not complete
  [
    windDataUrlComplete ? null : "windDataUrl",
    bathymetryComplete ? null : "bathymetry",
    compareDataComplete ? null : "compareData",
  ]
    .filter((x) => x)
    .forEach((x) => console.log(`Missing: ${x}`));

  if (
    windDataUrl === null ||
    bathymetry.state === "loading" ||
    productionAndWindDataFromCompare.status === "loading"
  ) {
    return <div>Loading...</div>;
  }

  const dataObject = {
    version: ARCHIVE_DATA_VERSION, // when the data structure changes, increment this number. If we ever create parsers for the data the version will be useful to have
    selectedParkFeatures,
    remainingFeatures,
    allCableTypesInUse,
    allTurbineTypesInUse,
    allFoundationTypesInUse,
    allMooringLinesInUse,
    allSubStationsInUse,
    allExportCablesInUse,
    selectedAnalysisConfig,
    selectedWindConfig,
    selectedCostConfig,
    selectedOperationsConfig,
    bathymetryUrl:
      bathymetry.state === "hasData" ? bathymetry.data?.url ?? null : null,
    windDataUrl,
    ...productionAndWindDataFromCompare.data,
  };

  return (
    <div>
      <p id="to-read">{JSON.stringify(dataObject)}</p>
    </div>
  );
}
