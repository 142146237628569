import { getBranchId, getParkId, getTurbineCapacity } from "analysis/inputs";
import { amountAddId, amountId } from "./amountIds";
import { atom } from "jotai";
import { DefaultMap } from "lib/DefaultMap";
import { CostType, isOperationsCost } from "services/costService";
import {
  exportCableLengthsFamily,
  exportCablesInParkWithTypeFamily,
} from "state/jotai/exportCable";
import { Amount, AmountUnit } from "types/financial";
import { atomFamily } from "utils/jotai";
import { roundToDecimal, sum } from "utils/utils";
import { exportCableTypesFamily } from "state/jotai/exportCableType";
import { getTotalInstallationTime } from "components/Installation/installation";
import { InstallationType } from "state/jotai/windStatistics";
import { getConfiguration, getOperationsConfiguration } from "finance/inputs";
import { FinanceId } from "finance/types";
import { vesselTypesFamily } from "state/jotai/vesselType";
import { sendWarning } from "utils/sentry";

export const exportCableAmountsSelectorFamily = atomFamily(
  (productionId: FinanceId) =>
    atom<Promise<Amount[]>>(async (get) => {
      const branchId = await get(getBranchId(productionId));
      const parkId = await get(getParkId(productionId));

      const exportCables = await get(
        exportCablesInParkWithTypeFamily({
          parkId,
          branchId,
        }),
      );

      const exportCableLengths = await get(
        exportCableLengthsFamily({
          parkId,
          branchId,
        }),
      );
      const lengthPerType = new DefaultMap<string, number>(() => 0);
      for (const [{ id }, offshoreType, onshoreType] of exportCables) {
        const lengths = exportCableLengths.get(id);
        if (!lengths) {
          sendWarning("Export cable length not found", { id });
          continue;
        }

        lengthPerType.update(
          offshoreType.id,
          (c) => c + (lengths.offshore ?? 0),
        );

        lengthPerType.update(onshoreType.id, (c) => c + (lengths.onshore ?? 0));
      }
      // Also add the cable types that are not in use
      const allExportCableTypes = await get(
        exportCableTypesFamily({ projectId: undefined }),
      );
      for (const [id] of allExportCableTypes)
        lengthPerType.update(id, (c) => c); // ensure all keys are in the map

      const capacity = await get(getTurbineCapacity(productionId));

      const exportLength = sum(
        Array.from(exportCableLengths.values()),
        (s) => s.straight,
      );

      //Note: assumes that if export cable splits are not found, it's because the whole length of the cable is offshore
      const exportOffshoreLength = sum(
        Array.from(exportCableLengths.values()),
        (s) => s.offshore ?? s.straight,
      );
      const exportOnshoreLength = sum(
        Array.from(exportCableLengths.values()),
        (s) => s.onshore ?? 0,
      );

      const exportCableLengthAmount = amountAddId({
        unit: AmountUnit.km,
        amount: roundToDecimal(exportLength, 1),
        category: CostType.ExportCable,
      });

      const exportCableOffshoreLengthAmount = amountAddId({
        unit: AmountUnit.offshorekm,
        amount: roundToDecimal(exportOffshoreLength, 1),
        category: CostType.ExportCable,
      });
      const exportCableOnshoreLengthAmount = amountAddId({
        unit: AmountUnit.onshorekm,
        amount: roundToDecimal(exportOnshoreLength, 1),
        category: CostType.ExportCable,
      });

      const exportCableTotal = amountAddId({
        unit: AmountUnit.exportcable,
        amount: exportCables.length,
        category: CostType.ExportCable,
      });

      const exportCableLengthAmountPerType = Array.from(
        lengthPerType.inner.entries(),
      ).map(([cableId, amount]) => {
        const unit = AmountUnit.km;
        const category = CostType.ExportCable;

        return {
          id: amountId({ unit, category, featureTypeId: cableId }),
          unit,
          amount,
          category,
        };
      });

      const perMwAmount = amountAddId({
        unit: AmountUnit.MW,
        amount: exportCables.length > 0 ? capacity ?? 0 : 0,
        category: CostType.ExportCable,
      });

      const fixedAmount = {
        id: amountId({
          unit: AmountUnit.fixed,
          category: CostType.ExportCable,
        }),
        unit: AmountUnit.fixed,
        amount: 1,
        category: CostType.ExportCable,
      };

      let perDayAmount: Amount[] = [];
      const configuration = await get(getConfiguration(productionId));

      if (isOperationsCost(configuration.capex.installation.exportCable)) {
        const installationTime =
          exportCables.length > 0
            ? await get(
                getTotalInstallationTime({
                  id: productionId,
                  type: InstallationType.ExportCable,
                }),
              )
            : 0;
        const operationsConfiguration = await get(
          getOperationsConfiguration(productionId),
        );

        const { installationVessel } = operationsConfiguration.ti.exportCable;

        const vesselTypes = await get(vesselTypesFamily(undefined));
        const mobilizationTime =
          vesselTypes.get(installationVessel.vesselId)?.mobilizationTime ?? 0;

        perDayAmount.push({
          id: amountId({
            unit: AmountUnit.day,
            category: CostType.ExportCable,
            featureTypeId: installationVessel.vesselId,
          }),
          unit: AmountUnit.day,
          amount:
            exportCables.length > 0
              ? (installationTime ?? 0) + mobilizationTime
              : 0,
          category: CostType.ExportCable,
        });
      }

      return [
        ...exportCableLengthAmountPerType,
        exportCableTotal,
        exportCableLengthAmount,
        exportCableOffshoreLengthAmount,
        exportCableOnshoreLengthAmount,
        perMwAmount,
        fixedAmount,
        ...perDayAmount,
      ];
    }),
);
