import { useAtomValue, useAtom } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { useRef, useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { filteredLayerListWithOverlapSelector } from "../../layer-filter-state";
import { IconREMSize, typography } from "../../../../styles/typography";
import {
  Layer,
  LayerWithSearchRelevance,
  PrivateDataSource,
} from "../../../../types/layers";
import { colors } from "../../../../styles/colors";
import DirectionDownIcon from "@icons/24/DirectionDown.svg?react";
import DirectionUpIcon from "@icons/24/DirectionUp.svg?react";
import { useDataLayerCellProps } from "../../../../hooks/useDataLayerCellProps";
import { Deprecated } from "components/Deprecated/Deprecated";
import { TranslatedLayerName } from "components/LayerList/LayerInformation/LayerInformation";
import { LayerIconMapper } from "components/LayerList/utils";
import SourceLogo from "../SourceLogo";
import AddIcon from "@icons/24/Add.svg?react";
import CheckCircle from "@icons/14/CheckCircle.svg?react";
import BinIcon from "@icons/24/Bin.svg?react";
import { spaceSmall } from "styles/space";
import LazyLayerPreviewImage from "../LazyLayerPreviewImage";
import Tooltip, { WithTooltip } from "components/General/Tooltip";
import { layerSortConfigAtom } from "../../layer-filter-state";
import { unwrap } from "jotai/utils";
import StarIcon from "@icons/24/Star.svg?react";
import { useFavoriteLayers } from "state/layer";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";
import { getLayerTypeTooltipText } from "utils/layer";
const ROW_HEIGHT_REM = 2.7;
const BUFFER_SIZE = 5;
const MIN_VIEWPORT_HEIGHT = 800; // Reasonable default for most screens

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TableAndSideModalWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

const TableWrapper = styled.div<{ isSideModalOpen: boolean }>`
  flex: ${(props) =>
    props.isSideModalOpen ? "1 0 calc(100% - 400px)" : "1 0 100%"};
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 2rem;
  transition: flex 0.3s ease-in-out;
  width: 100%;
  position: relative;

  * {
    box-sizing: border-box;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  td {
    width: auto;
  }
`;

const VirtualizedTableBody = styled.tbody`
  width: 100%;
`;

const StickyThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 4;
  width: 100%;

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${colors.blue500};
  }
`;

const StarIconWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;
  &:hover {
    svg { 
      path { 
        ${(p) => p.active && `stroke: none;`}
      }
    }
  }
  display: flex;
 
  opacity: ${(p) => (p.active ? "1 !important" : "inherit")};
  svg {
    width: 1.4rem;
    height: 1.4rem;
    path {
      stroke: ${(p) => (p.active ? colors.surfaceBrand : colors.surfaceBrand)};
      fill: ${(p) => (p.active ? colors.surfaceBrand : "transparent")};
    }
  }
}
`;

const HeaderCell = styled.th<{ isSortable: boolean }>`
  text-align: left;
  padding: 1rem 1.6rem;
  cursor: ${(props) => (props.isSortable ? "pointer" : "default")};
  position: relative;
`;

const IconWithBackground = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.surfacePrimary};
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: calc(1.6rem + (36px / 2));
  transform: translate(-50%, -50%);

  svg path[stroke] {
    stroke: ${colors.white};
  }
  svg path:not([stroke]) {
    fill: ${colors.white};
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const IconOverlay = styled(IconWithBackground)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SortIcon = styled(IconREMSize)<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  transition: opacity 0.2s ease-in-out;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 1.5rem;
  gap: 1rem;
  border-radius: 20px;
`;

export const TagPill = styled(Pill)`
  background-color: ${colors.purple};
  color: white;
`;

export const CountryPill = styled(Pill)`
  background-color: ${colors.port};
  color: white;
  text-transform: capitalize;
`;

export const SourcePill = styled(Pill)`
  background-color: ${colors.subArea};
  color: white;
`;

const TableRow = styled.tr<{
  isSelected?: boolean;
}>`
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.surfaceSelectedLight : "transparent"};

  /* Apply rounded corners to first and last cells */
  td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .show-on-hover {
    visibility: hidden;
  }
  &:hover {
    .add-button {
      opacity: 1;
    }
  }
`;

const SourceLink = styled.a`
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  padding: 0.6rem 0 0.6rem 1.6rem;
  text-decoration: inherit;
  color: blue;

  &:visited {
    color: blue;
  }

  &:not([href]) {
    color: unset;

    :hover {
      text-decoration: unset;
    }
  }

  :hover {
    text-decoration: underline;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceSmall};
  max-width: 80%;
`;

const StyledTranslatedLayerName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

const NameCell = styled.div`
  ${typography.caption}
  font-weight: bold;
  padding: 0.6rem 0 0.6rem 1.6rem;
  display: flex;
  gap: ${spaceSmall};
  width: 100%;
  position: relative;
  cursor: pointer;
`;

interface ListViewProps {
  layers?: Layer[];
  selectedLayer: Layer | null;
  setSelectedLayer: (layer: Layer | null) => void;
  setSelectedLayersToAdd: React.Dispatch<React.SetStateAction<string[]>>;
  selectedLayersToAdd: string[];
  handleToggleFavorite?: (layerId: string) => void;
}

const ListView = ({
  layers,
  selectedLayer,
  setSelectedLayer,
  setSelectedLayersToAdd,
  selectedLayersToAdd,
  handleToggleFavorite,
}: ListViewProps) => {
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const { isFavorite } = useFavoriteLayers();

  const filteredLayersResult = useAtomValue(
    unwrap(
      filteredLayerListWithOverlapSelector({
        projectId,
      }),
    ),
  );

  const [sortConfig, setSortConfig] = useAtom(layerSortConfigAtom);
  const { showConfirm } = useConfirm();
  const sortedLayers = useMemo(() => {
    const filteredLayerList = filteredLayersResult?.layers ?? [];
    const layersToSort = [
      ...(layers || filteredLayerList),
    ] as LayerWithSearchRelevance[];

    return layersToSort.sort((a, b) => {
      if (!layers) {
        // We're using filteredLayerList, which has searchRelevance
        const aRelevance = a.searchRelevance ?? -Infinity;
        const bRelevance = b.searchRelevance ?? -Infinity;

        if (aRelevance !== bRelevance) {
          return sortConfig.key === "searchRelevance" &&
            sortConfig.direction === "desc"
            ? bRelevance - aRelevance
            : aRelevance - bRelevance;
        }
      }

      // If searchRelevance is equal or we're using 'layers' prop, sort by name
      if (a.name < b.name) {
        return sortConfig.key === "name" && sortConfig.direction === "asc"
          ? -1
          : 1;
      }
      if (a.name > b.name) {
        return sortConfig.key === "name" && sortConfig.direction === "asc"
          ? 1
          : -1;
      }
      return 0;
    });
  }, [layers, filteredLayersResult, sortConfig]);

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  // Virtual scroll state
  const [scrollTop, setScrollTop] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(800);

  // Calculate visible rows
  const visibleRange = useMemo(() => {
    const rowHeightPx = remToPixels(ROW_HEIGHT_REM);

    const startIndex = Math.max(
      0,
      Math.floor(scrollTop / rowHeightPx) - BUFFER_SIZE,
    );
    const visibleCount =
      Math.ceil(viewportHeight / rowHeightPx) + 2 * BUFFER_SIZE;
    const endIndex = Math.min(startIndex + visibleCount, sortedLayers.length);

    return {
      startIndex,
      endIndex,
      totalHeight: sortedLayers.length * rowHeightPx,
    };
  }, [scrollTop, viewportHeight, sortedLayers.length]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  }, []);

  // Update viewport height on mount and resize
  useEffect(() => {
    const updateViewportHeight = () => {
      if (tableWrapperRef.current) {
        const height = Math.max(
          tableWrapperRef.current.clientHeight,
          MIN_VIEWPORT_HEIGHT,
        );
        setViewportHeight(height);
      }
    };

    // Initial update
    updateViewportHeight();

    // Add multiple checks to catch layout changes
    const timeoutIds = [100, 250, 500, 1000].map((delay) =>
      setTimeout(updateViewportHeight, delay),
    );

    // Create ResizeObserver to watch for container size changes
    const resizeObserver = new ResizeObserver(updateViewportHeight);
    if (tableWrapperRef.current) {
      resizeObserver.observe(tableWrapperRef.current);
    }

    // Clean up
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
      resizeObserver.disconnect();
    };
  }, []);

  const handleLayerSelection = useCallback(
    (layerId: string) => {
      setSelectedLayersToAdd((prev: string[]) => {
        if (prev.includes(layerId)) {
          return prev.filter((id) => id !== layerId);
        }
        return [...prev, layerId];
      });
    },
    [setSelectedLayersToAdd],
  );

  const cellProps = useDataLayerCellProps();

  const [hoveredLayerId, setHoveredLayerId] = useState<string | null>(null);

  const handleSort = useCallback(() => {
    setSortConfig((prevConfig) => ({
      key: layers
        ? "name"
        : prevConfig.key === "searchRelevance"
          ? "name"
          : "searchRelevance",
      direction:
        prevConfig.key === "name" && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  }, [layers, setSortConfig]);

  return (
    <TableContainer>
      <TableAndSideModalWrapper>
        <TableWrapper
          isSideModalOpen={!!selectedLayer}
          ref={tableWrapperRef}
          onScroll={handleScroll}
        >
          <table style={{ width: "100%" }}>
            <StickyThead>
              <tr>
                <HeaderCell isSortable={true} onClick={handleSort}>
                  <HeaderContent>
                    <span style={{ ...typography.sub2 }}>Name</span>
                    <SortIcon
                      height={1.5}
                      width={1.5}
                      isActive={
                        sortConfig.key === "name" ||
                        (!layers && sortConfig.key === "searchRelevance")
                      }
                    >
                      {sortConfig.direction === "desc" ? (
                        <DirectionDownIcon />
                      ) : (
                        <DirectionUpIcon />
                      )}
                    </SortIcon>
                  </HeaderContent>
                </HeaderCell>
                <HeaderCell isSortable={false}>
                  <span style={{ ...typography.sub2 }}>Source</span>
                </HeaderCell>
                <HeaderCell isSortable={false}>
                  <span style={{ ...typography.sub2 }}>Type</span>
                </HeaderCell>
              </tr>
            </StickyThead>
            <VirtualizedTableBody
              style={{
                height: `${visibleRange.totalHeight}px`,
                transform: `translateY(${visibleRange.startIndex * remToPixels(ROW_HEIGHT_REM)}px)`,
              }}
            >
              {sortedLayers
                .slice(visibleRange.startIndex, visibleRange.endIndex)
                .map((layer) => (
                  <TableRow
                    key={layer.id}
                    isSelected={selectedLayersToAdd.includes(layer.id)}
                    onClick={() => {
                      setSelectedLayer(layer);
                    }}
                  >
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StarIconWrapper
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleFavorite?.(layer.id);
                          }}
                          active={isFavorite(layer.id)}
                        >
                          <StarIcon />
                        </StarIconWrapper>
                        <NameCell
                          onMouseEnter={() => {
                            setHoveredLayerId(layer.id);
                          }}
                          onMouseLeave={() => {
                            setHoveredLayerId(null);
                          }}
                        >
                          <ImageContainer>
                            <LazyLayerPreviewImage
                              layer={layer}
                              clickToEnlarge={false}
                              width="36px"
                              height="36px"
                            />
                            {selectedLayersToAdd.includes(layer.id) ||
                            cellProps.getIsAdded(layer.id) ? (
                              <Tooltip
                                text="Layer already added"
                                disabled={!cellProps.getIsAdded(layer.id)}
                              >
                                <IconOverlay
                                  backgroundColor={colors.surfaceSelectedDark}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    !cellProps.getIsAdded(layer.id) &&
                                      handleLayerSelection(layer.id);
                                  }}
                                >
                                  <IconREMSize height={0.8} width={0.8}>
                                    <CheckCircle />
                                  </IconREMSize>
                                </IconOverlay>
                              </Tooltip>
                            ) : (
                              hoveredLayerId === layer.id && (
                                <IconOverlay
                                  backgroundColor={colors.surfaceButtonPrimary}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleLayerSelection(layer.id);
                                  }}
                                >
                                  <IconREMSize height={0.8} width={0.8}>
                                    <AddIcon />
                                  </IconREMSize>
                                </IconOverlay>
                              )
                            )}
                          </ImageContainer>
                          <NameWrapper>
                            <Deprecated
                              style={{ overflow: "hidden" }}
                              isDeprecated={
                                ("deprecated" in layer &&
                                  layer.deprecated != null) ||
                                false
                              }
                            >
                              <StyledTranslatedLayerName>
                                <TranslatedLayerName layer={layer} />
                              </StyledTranslatedLayerName>
                            </Deprecated>
                          </NameWrapper>
                        </NameCell>
                      </div>
                    </td>
                    <td>
                      <SourceLink
                        href={layer.sourceLink?.urlWhereFound ?? undefined}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) =>
                          layer.sourceLink?.urlWhereFound
                            ? e.stopPropagation()
                            : undefined
                        }
                      >
                        <SourceLogo
                          url={layer.sourceLink?.url ?? undefined}
                          style={{
                            width: "22px",
                            height: "22px",
                            marginRight: "1rem",
                          }}
                        />
                        <span style={{ ...typography.label }}>
                          {cellProps.getSourceName(layer.source)}
                        </span>
                        {layer.source?.private && (
                          <IconREMSize
                            height={1.5}
                            width={1.5}
                            style={{
                              marginLeft: "0.5rem",
                              cursor: "pointer",
                            }}
                            title="Delete private layer source"
                            onClick={async (e) => {
                              e.stopPropagation();
                              if (
                                await showConfirm({
                                  title: "Delete private layer source",
                                  message:
                                    "This will delete the source and all layers belonging to this source.",
                                  confirmButtonText: "Delete",
                                })
                              ) {
                                return cellProps.removePrivateSource(
                                  layer.source as PrivateDataSource,
                                );
                              }
                            }}
                          >
                            <BinIcon />
                          </IconREMSize>
                        )}
                      </SourceLink>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <WithTooltip text={getLayerTypeTooltipText(layer.type)}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.3rem",
                              padding: "0.6rem 0 0.6rem 1.6rem",
                            }}
                          >
                            <IconREMSize height={1.5} width={1.5}>
                              <LayerIconMapper layer={layer} />
                            </IconREMSize>
                            <p style={{ ...typography.label }}>
                              {layer.sourceType}
                            </p>
                          </div>
                        </WithTooltip>
                      </div>
                    </td>
                  </TableRow>
                ))}
            </VirtualizedTableBody>
          </table>
        </TableWrapper>
      </TableAndSideModalWrapper>
    </TableContainer>
  );
};

export default ListView;

const makeLinksClickable = (text: string): React.ReactNode => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }

    return <span key={index}>{part}</span>;
  });
};

export const removeHtmlAndMakeClickableLinks = (
  text: string | undefined,
): React.ReactNode => {
  if (!text) {
    return null;
  }

  const elem = document.createElement("span");
  elem.innerHTML = text;
  return makeLinksClickable(elem.innerText);
};

const remToPixels = (rem: number): number => {
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  );
  return rem * fontSize;
};
