import Foundations from "@icons/24/Foundation.svg?react";
import { InstallationCategoryEntry } from "./GeneralInstallationEntries";
import { atom, useAtom, useAtomValue } from "jotai";
import styled from "styled-components";
import { typography } from "styles/typography";
import { FoundationInstallationCategorySelector } from "./FoundationInstallationCategorySelector";
import { spaceLarge } from "styles/space";
import {
  localOperationsConfigurationAtom,
  useLocalOperationsConfigurationCrud,
} from "../state";
import { DividerWrapper, InstallationDivider } from "../styles";
import InstallationSeason from "./InstallationSeason";
import { SubHeader } from "components/ConfigurationModal/shared";
import Dropdown from "components/Dropdown/Dropdown";
import { Grid2 } from "components/General/Form";
import { Input, InputDimensioned } from "components/General/Input";
import { vesselTypesFamily } from "state/jotai/vesselType";
import {
  isAnchorHandlingVessel,
  isFeederBargeVessel,
  isInstallationVessel,
  isScourProtectionVessel,
} from "services/vesselService";
import { projectIdAtom } from "state/pathParams";
import WeatherLimits from "./WeatherLimits";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import { operationsChangelogInfo } from "components/InputChangelog/const";
import { getValue } from "components/InputChangelog/utils";
import { Row } from "components/General/Layout";

export const Title = styled.div`
  ${typography.sub3}
  padding: ${spaceLarge} 0;
`;

export const foundationInstallationCategoriesToDisplayAtom = atom<string[]>([
  "Jacket",
  "Monopile",
  "Floating",
]);

const JacketSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantInstallationVesselTypes = Array.from(
    vesselTypes.values(),
  ).filter(isInstallationVessel);
  const relevantTransportVesselTypes = Array.from(vesselTypes.values()).filter(
    isFeederBargeVessel,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;

  const { installationVessel, feederVessel } =
    localConfig.ti.foundations.jackets;

  return (
    <>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Installation vessel</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.jackets.installationVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.foundations.jackets.installationVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={installationVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  foundations: {
                    ...localConfig.ti.foundations,
                    jackets: {
                      ...localConfig.ti.foundations.jackets,
                      installationVessel: {
                        ...installationVessel,
                        vesselId: e.target.value,
                      },
                    },
                  },
                })
              }
            >
              {relevantInstallationVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.jackets.installationVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={installationVessel.dayRate.unit}
            value={installationVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    installationVessel: {
                      ...installationVessel,
                      dayRate: {
                        ...installationVessel.dayRate,
                        cost,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Transport vessel</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.jackets.feederVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.foundations.jackets.feederVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={feederVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  foundations: {
                    ...localConfig.ti.foundations,
                    jackets: {
                      ...localConfig.ti.foundations.jackets,
                      feederVessel: {
                        ...feederVessel,
                        vesselId: e.target.value,
                      },
                    },
                  },
                })
              }
            >
              {relevantTransportVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.jackets.feederVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={feederVessel.dayRate.unit}
            value={feederVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  jackets: {
                    ...localConfig.ti.foundations.jackets,
                    feederVessel: {
                      ...feederVessel,
                      dayRate: {
                        ...feederVessel.dayRate,
                        cost,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
    </>
  );
};

const MonopileSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantVesselTypes = Array.from(vesselTypes.values()).filter(
    isInstallationVessel,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;
  const { installationVessel } = localConfig.ti.foundations.monopiles;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Installation vessel</SubHeader>
        <SubHeader>Day rate</SubHeader>
        <ChangelogAndCommentWrapper
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            "ti.foundations.monopiles.installationVessel.vesselId",
            "project",
            (obj) => {
              const vesselId = getValue(
                obj,
                "ti.foundations.monopiles.installationVessel.vesselId",
              );
              const vesselName = vesselTypes.get(vesselId)?.name;
              return { "vessel type": vesselName };
            },
          )}
          disabled={isReadOnly}
        >
          <Dropdown
            style={{ width: "20rem" }}
            disabled={isReadOnly}
            value={installationVessel.vesselId}
            onChange={(e) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  monopiles: {
                    ...localConfig.ti.foundations.monopiles,
                    installationVessel: {
                      ...installationVessel,
                      vesselId: e.target.value,
                    },
                  },
                },
              })
            }
          >
            {relevantVesselTypes.map((vessel) => {
              return (
                <option key={vessel.id} value={vessel.id}>
                  {vessel.name}
                </option>
              );
            })}
          </Dropdown>
        </ChangelogAndCommentWrapper>
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            "ti.foundations.monopiles.installationVessel.dayRate.cost",
            "project",
          )}
          disabled={isReadOnly}
          unit={installationVessel.dayRate.unit}
          value={installationVessel.dayRate.cost}
          step={0.1}
          validate={(e) => 0 <= e}
          validationMessage={`Needs to be greater than 0`}
          initialValue={100}
          onChange={(cost) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                monopiles: {
                  ...localConfig.ti.foundations.monopiles,
                  installationVessel: {
                    ...installationVessel,
                    dayRate: {
                      ...installationVessel.dayRate,
                      cost,
                    },
                  },
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

const ScourProtectionSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantVesselTypes = Array.from(vesselTypes.values()).filter(
    isScourProtectionVessel,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;
  const { installationVessel } = localConfig.ti.foundations.scourProtection;

  return (
    <Row>
      <Grid2
        style={{
          gridTemplateColumns: "auto auto",
          justifyContent: "start",
          margin: "0 0 2rem 0rem",
        }}
      >
        <SubHeader>Scour protection vessel</SubHeader>
        <SubHeader>Day rate</SubHeader>
        <ChangelogAndCommentWrapper
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            "ti.foundations.scourProtection.installationVessel.vesselId",
            "project",
            (obj) => {
              const vesselId = getValue(
                obj,
                "ti.foundations.scourProtection.installationVessel.vesselId",
              );
              const vesselName = vesselTypes.get(vesselId)?.name;
              return { "vessel type": vesselName };
            },
          )}
          disabled={isReadOnly}
        >
          <Dropdown
            style={{ width: "20rem" }}
            disabled={isReadOnly}
            value={installationVessel.vesselId}
            onChange={(e) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  scourProtection: {
                    ...localConfig.ti.foundations.scourProtection,
                    installationVessel: {
                      ...installationVessel,
                      vesselId: e.target.value,
                    },
                  },
                },
              })
            }
          >
            {relevantVesselTypes.map((vessel) => {
              return (
                <option key={vessel.id} value={vessel.id}>
                  {vessel.name}
                </option>
              );
            })}
          </Dropdown>
        </ChangelogAndCommentWrapper>
        <InputDimensioned
          style={{ width: "20rem" }}
          changelogInfo={operationsChangelogInfo(
            localConfig.id,
            nodeId,
            "ti.foundations.scourProtection.installationVessel.dayRate.cost",
            "project",
          )}
          disabled={isReadOnly}
          unit={installationVessel.dayRate.unit}
          value={installationVessel.dayRate.cost}
          step={0.1}
          validate={(e) => 0 <= e}
          validationMessage={`Needs to be greater than 0`}
          initialValue={100}
          onChange={(cost) =>
            updateTIVessels({
              foundations: {
                ...localConfig.ti.foundations,
                scourProtection: {
                  ...localConfig.ti.foundations.scourProtection,
                  installationVessel: {
                    ...installationVessel,
                    dayRate: {
                      ...installationVessel.dayRate,
                      cost,
                    },
                  },
                },
              },
            })
          }
        />
      </Grid2>
    </Row>
  );
};

const FloaterSpecificVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const { updateTIVessels } = useLocalOperationsConfigurationCrud();

  const projectId = useAtomValue(projectIdAtom) ?? "";
  const vesselTypes = useAtomValue(vesselTypesFamily(projectId));

  const relevantVesselTypes = Array.from(vesselTypes.values()).filter(
    isAnchorHandlingVessel,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;
  const { installationVessel, towingVessel, portCrane } =
    localConfig.ti.foundations.floaters;

  return (
    <>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Installation vessel</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.floaters.installationVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.foundations.floaters.installationVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={installationVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  foundations: {
                    ...localConfig.ti.foundations,
                    floaters: {
                      ...localConfig.ti.foundations.floaters,
                      installationVessel: {
                        ...installationVessel,
                        vesselId: e.target.value,
                      },
                    },
                  },
                })
              }
            >
              {relevantVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.floaters.installationVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={installationVessel.dayRate.unit}
            value={installationVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    installationVessel: {
                      ...installationVessel,
                      dayRate: {
                        ...installationVessel.dayRate,
                        cost,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Towing vessel (x3)</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <ChangelogAndCommentWrapper
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.floaters.towingVessel.vesselId",
              "project",
              (obj) => {
                const vesselId = getValue(
                  obj,
                  "ti.foundations.floaters.towingVessel.vesselId",
                );
                const vesselName = vesselTypes.get(vesselId)?.name;
                return { "vessel type": vesselName };
              },
            )}
            disabled={isReadOnly}
          >
            <Dropdown
              style={{ width: "20rem" }}
              disabled={isReadOnly}
              value={towingVessel.vesselId}
              onChange={(e) =>
                updateTIVessels({
                  foundations: {
                    ...localConfig.ti.foundations,
                    floaters: {
                      ...localConfig.ti.foundations.floaters,
                      towingVessel: {
                        ...towingVessel,
                        vesselId: e.target.value,
                      },
                    },
                  },
                })
              }
            >
              {relevantVesselTypes.map((vessel) => {
                return (
                  <option key={vessel.id} value={vessel.id}>
                    {vessel.name}
                  </option>
                );
              })}
            </Dropdown>
          </ChangelogAndCommentWrapper>
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.floaters.towingVessel.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={towingVessel.dayRate.unit}
            value={towingVessel.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    towingVessel: {
                      ...towingVessel,
                      dayRate: {
                        ...towingVessel.dayRate,
                        cost,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
      <Row>
        <Grid2
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "start",
            margin: "0 0 2rem 0rem",
          }}
        >
          <SubHeader>Port crane</SubHeader>
          <SubHeader>Day rate</SubHeader>
          <Input
            style={{ width: "20rem" }}
            disabled={true}
            value={"Port crane"}
          />
          <InputDimensioned
            style={{ width: "20rem" }}
            changelogInfo={operationsChangelogInfo(
              localConfig.id,
              nodeId,
              "ti.foundations.floaters.portCrane.dayRate.cost",
              "project",
            )}
            disabled={isReadOnly}
            unit={portCrane.dayRate.unit}
            value={portCrane.dayRate.cost}
            step={0.1}
            validate={(e) => 0 <= e}
            validationMessage={`Needs to be greater than 0`}
            initialValue={100}
            onChange={(cost) =>
              updateTIVessels({
                foundations: {
                  ...localConfig.ti.foundations,
                  floaters: {
                    ...localConfig.ti.foundations.floaters,
                    portCrane: {
                      ...portCrane,
                      dayRate: {
                        ...portCrane.dayRate,
                        cost,
                      },
                    },
                  },
                },
              })
            }
          />
        </Grid2>
      </Row>
    </>
  );
};

const FoundationTypeVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const foundationCategoriesToDisplay = useAtomValue(
    foundationInstallationCategoriesToDisplayAtom,
  );

  return (
    <>
      {foundationCategoriesToDisplay.includes("Jacket") && (
        <>
          <Title>Jacket</Title>
          <JacketSpecificVessels isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Monopile") && (
        <>
          <Title>Monopile</Title>
          <MonopileSpecificVessels isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
      {(foundationCategoriesToDisplay.includes("Jacket") ||
        foundationCategoriesToDisplay.includes("Monopile")) && (
        <>
          <Title>Scour protection</Title>
          <ScourProtectionSpecificVessels
            isReadOnly={isReadOnly}
            nodeId={nodeId}
          />
        </>
      )}
      {foundationCategoriesToDisplay.includes("Floating") && (
        <>
          <Title>Floating</Title>
          <FloaterSpecificVessels isReadOnly={isReadOnly} nodeId={nodeId} />
        </>
      )}
    </>
  );
};

export const FoundationVessels = ({
  isReadOnly,
  nodeId,
}: {
  isReadOnly?: boolean;
  nodeId: string;
}) => {
  const [, setFoundationCategoriesToDisplay] = useAtom(
    foundationInstallationCategoriesToDisplayAtom,
  );

  const localConfig = useAtomValue(localOperationsConfigurationAtom);
  if (!localConfig) return null;
  const { startMonth, endMonth } =
    localConfig.ti.foundations.installationSeason;

  return (
    <InstallationCategoryEntry
      title="Foundations"
      icon={<Foundations />}
      defaultOpen={false}
    >
      <FoundationInstallationCategorySelector
        onSelectionChange={(foundationCategory) => {
          setFoundationCategoriesToDisplay((prev) => {
            if (prev.includes(foundationCategory)) {
              return prev.filter((f) => f !== foundationCategory);
            }
            return [...prev, foundationCategory];
          });
        }}
      />
      <FoundationTypeVessels isReadOnly={isReadOnly} nodeId={nodeId} />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <WeatherLimits
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        category={"foundations"}
        weatherLimits={localConfig.ti.foundations.weatherLimits}
      />
      <DividerWrapper>
        <InstallationDivider />
      </DividerWrapper>
      <InstallationSeason
        isReadOnly={isReadOnly}
        nodeId={nodeId}
        startMonth={startMonth}
        endMonth={endMonth}
        category={"foundations"}
      />
    </InstallationCategoryEntry>
  );
};
