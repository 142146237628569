import { Node } from "services/customerAPI";
import { sendWarning } from "utils/sentry";

// Recursively fetch child nodes
export const getFolderChildNodes = (
  allNodes: Node[],
  folderNode: Node,
): Node[] => {
  const children = allNodes.filter(
    (child) => child.parent_id === folderNode.id,
  );
  const projects = children.filter((child) => child.type === "project");
  const folders = children.filter((child) => child.type === "folder");

  return [
    ...projects,
    ...folders.flatMap((folder) => getFolderChildNodes(allNodes, folder)),
  ];
};

export const getFolderParentChain = (allNodes: Node[], folder?: Node) => {
  if (!folder) {
    return [];
  }

  const chain = [];
  let sel: Node = folder;
  while (sel) {
    const { parent_id } = sel;
    const parent = allNodes.find((f) => f.id === parent_id);
    if (parent === sel) {
      throw sendWarning("Loop in folder contains");
    }

    if (parent) {
      chain.push(parent);
    } else {
      break;
    }
    sel = parent;
  }
  return chain.reverse();
};
