import { useAtomValue } from "jotai";
import { projectIdAtom } from "state/pathParams";
import { editorAccessProjectSelector } from "state/user";
import { ExtraProjectUsageInfo } from "./common";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { loadable } from "jotai/utils";
import { useMemo } from "react";
import { projectResourceUsageAtomFamily } from "state/resourceUsageAtoms";
import { UsageContainerInner } from "./common";
import {
  UsageContainerPlacement,
  UsageContainerLeftRightPlacement,
} from "./style";
import { ResourceType } from "services/usageService";

export default function ProjectResourceUsage({
  resourceId,
  resourceType,
}: {
  resourceId: string;
  resourceType: ResourceType;
}) {
  const editorAccess = useAtomValue(editorAccessProjectSelector);
  const projectId = useAtomValue(projectIdAtom) ?? "";

  if (!editorAccess) return null;

  return (
    <ProjectResourceUsageInner
      nodeId={projectId}
      resourceType={resourceType}
      resourceId={resourceId}
    />
  );
}

function ProjectResourceUsageInner({
  nodeId,
  resourceType,
  resourceId,
}: {
  nodeId: string;
  resourceType: ResourceType;
  resourceId: string;
}) {
  const usageAtom = useMemo(
    () =>
      projectResourceUsageAtomFamily({
        nodeId,
        resourceType,
        resourceId,
      }),
    [nodeId, resourceType, resourceId],
  );

  const usageLoadable = useAtomValue(loadable(usageAtom));

  if (usageLoadable.state !== "hasData") {
    return (
      <SkeletonBlock
        style={{
          height: "2rem",
          width: "15rem",
          marginTop: "0.8rem",
        }}
      />
    );
  }

  return (
    <UsageContainerInner
      loadable={usageLoadable}
      text="Used in "
      typeName={getTypeNames(resourceType, usageLoadable.data.length)}
      placement={UsageContainerPlacement.BOTTOM}
      placementLeftRight={UsageContainerLeftRightPlacement.RIGHT}
    >
      <ExtraProjectUsageInfo usage={usageLoadable.data} />
    </UsageContainerInner>
  );
}

const getTypeNames = (resourceType: string, length: number) => {
  if (resourceType.includes("CONFIGURATION")) {
    if (length === 1) {
      return "branch in current project";
    }
    return "branches in current project";
  }
  if (length === 1) {
    return `${resourceType.toLowerCase()} in current project`;
  }
  return `${resourceType.toLowerCase()}s in current project`;
};
