import React from "react";
import {
  ContentText,
  SliderAnimation,
  SubHeader,
  TopHeaderAccent,
} from "components/SlideShow/style";
import { Step } from "components/SlideShow/SlideShow";
import SlideShowPlaceholder from "assets/slideshow-placeholder.jpg";

const Step1 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 1</TopHeaderAccent>
          <SubHeader>
            Perform highly precise feasibility studies to mitigate technical and
            financial risks early
          </SubHeader>
          <ContentText>
            Reduce uncertainty with precise, instant energy production estimates
            using embedded wind data and real-time production analysis.
          </ContentText>
          <ContentText>
            Gain accurate LCoE estimates based on optimal design layouts for
            confident decision-making.
          </ContentText>
          <ContentText>
            Account for factors such as wake effects from nearby wind farms and
            installation delays caused by local weather conditions.{" "}
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="right"
    />
  );
};

const Step2 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 2</TopHeaderAccent>
          <SubHeader>
            Minimize environmental and social risk while confidently excluding
            unsuitable areas
          </SubHeader>
          <ContentText>
            Identify and address environmental and social risks using more than
            3,000 always-up-to-date, built-in data sources.
          </ContentText>
          <ContentText>
            Use real-time analysis to investigate community impacts such as
            visibility, noise levels, and shadow flicker effects.
          </ContentText>
          <ContentText>
            Perform user-friendly GIS operations to perform constraint analysis.
          </ContentText>
          <ContentText>
            Upload your own data for customized insights and confidently exclude
            unsuitable areas.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="left"
    />
  );
};

const Step3 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 3</TopHeaderAccent>
          <SubHeader>
            Save significant time in searching for and analyzing new areas
          </SubHeader>
          <ContentText>
            Streamline your workflow with built-in metocean, environmental, and
            permitting data. Real-time analysis enables rapid iterations,
            allowing you to evaluate and inspect many more areas than ever
            before.
          </ContentText>
          <ContentText>
            Spend less time gathering data and more time identifying
            opportunities.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

const Step4 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 4</TopHeaderAccent>
          <SubHeader>
            Communicate insights with clear visualizations for decision-making
            and stakeholder engagement
          </SubHeader>
          <ContentText>
            Generate presentation-ready visualizations and reports to summarize
            findings and recommendations.
          </ContentText>
          <ContentText>
            Flexible access management and a user-friendly interface make it
            easy to engage with external stakeholders, including regulatory
            bodies, local communities, and investors.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

const Step5 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 5</TopHeaderAccent>
          <SubHeader>Track and compare your portfolio in one tool</SubHeader>
          <ContentText>
            Centralise all wind projects in one platform to streamline portfolio
            management.
          </ContentText>
          <ContentText>
            Easily compare projects side by side, including production analyses,
            LCoE estimates, and project status updates.{" "}
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

const Step6 = ({ animation }: { animation?: SliderAnimation }) => {
  return (
    <Step
      animation={animation}
      leftSide={
        <>
          <TopHeaderAccent>Header 6</TopHeaderAccent>
          <SubHeader>
            Collaborate seamlessly across disciplines for holistic
            decision-making
          </SubHeader>
          <ContentText>
            Unify technical, financial, and environmental teams in one
            collaborative interface.
          </ContentText>
          <ContentText>
            Access all project data in a single platform, with real-time updates
            ensuring a single source of truth.
          </ContentText>
          <ContentText>
            Built-in project history and commenting features simplify
            collaboration, making it easier to meet GIS, financial modelling,
            and environmental screening needs without switching tools.
          </ContentText>
        </>
      }
      image={SlideShowPlaceholder}
      imagePosition="center"
    />
  );
};

export const ScreeningAndBusinessSteps = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
];
