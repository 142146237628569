import { useAtomValue } from "jotai";
import React from "react";
import StackedUserImages from "components/UserImage/StackedUserImages";
import {
  getUsersAndAllAccessesForNode,
  organisationGroupsState,
} from "components/Organisation/Groups/state";
import { SecondaryText } from "components/AccessOverview/shared";
import { Row } from "components/Design/styles";
import GroupTag from "components/Organisation/OrganisationRightSide/content/UserContent/GroupTag";
import { spacing4 } from "styles/space";
import {
  ErrorBoundaryWarningTriangle,
  ErrorBoundaryWrapper,
  ScreamOnError,
} from "components/ErrorBoundaries/ErrorBoundaryLocal";

export const NodeAccessList = ErrorBoundaryWrapper(
  ({
    organisationId,
    nodeId,
    maxNumberOfUsers,
  }: {
    organisationId: string;
    nodeId: string;
    maxNumberOfUsers?: number;
  }) => {
    const groups = useAtomValue(
      organisationGroupsState({
        organisationId,
      }),
    );
    const allUserAccessesForNode = useAtomValue(
      getUsersAndAllAccessesForNode({
        organisationId,
        nodeId,
      }),
    );

    return (
      <StackedUserImages
        maxNumberOfUsers={maxNumberOfUsers}
        users={allUserAccessesForNode}
        size={2.5}
        renderPopupRowRightSide={(user) => {
          const access = user.accesses[0];
          const group = access.group_id
            ? groups.find((g) => g.id === access.group_id)
            : undefined;
          return (
            <Row
              style={{
                alignItems: "center",
                gap: spacing4,
              }}
            >
              <SecondaryText
                style={{
                  textTransform: "capitalize",
                }}
              >
                {access.resource_name}
              </SecondaryText>
              {group && (
                <GroupTag
                  organisationId={organisationId}
                  size="compact"
                  group={group}
                />
              )}
            </Row>
          );
        }}
      />
    );
  },
  ErrorBoundaryWarningTriangle,
  ScreamOnError,
);
