import { SubstationType } from "services/substationService";
import { Column, Row } from "components/General/Layout";
import styled from "styled-components";
import ComponentLastChanged from "components/ConfigurationModal/SettingsUsage/ComponentLastChanged";
import {
  HeaderWrapper,
  HeaderContainer,
} from "components/ConfigurationModal/shared";
import { idToSubstationChangelogId } from "components/InputChangelog/const";
import DescriptionModal from "components/ConfigurationModal/DescriptionModal";
import ProjectResourceUsage from "components/ConfigurationModal/SettingsUsage/ProjectResourceUsage";

const SubstationName = styled.h3`
  margin: 0;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 60rem;
`;

type SubstationHeaderProps = {
  substation: SubstationType;
  nodeId: string;
  description?: string;
  onUpdateDescription?: (description: string) => void;
  disabled?: boolean;
  isLibrary?: boolean;
};

export const SubstationHeader = ({
  substation,
  nodeId,
  description,
  onUpdateDescription,
  disabled,
  isLibrary,
}: SubstationHeaderProps) => {
  if (isLibrary) return null;

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Column style={{ width: "100%" }}>
          <SubstationName>{substation.name}</SubstationName>
          <Row style={{ alignItems: "start", flexDirection: "column" }}>
            <ComponentLastChanged
              resourceId={substation.id}
              changelogId={idToSubstationChangelogId(substation.id)}
              nodeId={nodeId}
              category="project"
            />
            <ProjectResourceUsage
              resourceType={"SUBSTATION"}
              resourceId={substation.id}
            />
          </Row>
        </Column>
        {onUpdateDescription && (
          <DescriptionModal
            disabled={disabled}
            defaultValue={description}
            updateDescription={onUpdateDescription}
            subtitle={
              <div>
                <p>
                  The description will be visible for Admins and Editors in
                  projects with access to this substation.
                </p>
              </div>
            }
          />
        )}
      </HeaderContainer>
    </HeaderWrapper>
  );
};
