import { useCallback, useMemo } from "react";
import {
  ABLY_TURBINE_TYPE_UPDATE,
  ABLY_TURBINE_TYPE_DELETE,
} from "../../../../../../state/ably";
import { InboundMessage } from "ably";
import { useAblyGeneric } from "../../../../../../hooks/useAblyGeneric";
import { _TurbineType } from "types/turbines";
import { z } from "zod";
import useOrgTurbineCrud from "components/Organisation/Library/turbine/useOrgTurbineCrud";

export function useAblyLibraryTurbine(organisationId: string) {
  const { updateLocal, removeLocal } = useOrgTurbineCrud();

  const channelName = useMemo(
    () => `${organisationId}:org_turbine_manage`,
    [organisationId],
  );

  const onMessageReceived = useCallback(
    (message: InboundMessage) => {
      const turbine = _TurbineType.parse(message.data.meta);
      updateLocal(turbine);
    },
    [updateLocal],
  );

  const onMessageReceivedDelete = useCallback(
    (message: InboundMessage) => {
      const turbineId = z.string().parse(message.data.meta);
      removeLocal(turbineId);
    },
    [removeLocal],
  );

  const events = useMemo(
    () => [
      {
        eventName: ABLY_TURBINE_TYPE_UPDATE,
        onMessageReceived: onMessageReceived,
      },
      {
        eventName: ABLY_TURBINE_TYPE_DELETE,
        onMessageReceived: onMessageReceivedDelete,
      },
    ],
    [onMessageReceived, onMessageReceivedDelete],
  );

  useAblyGeneric(channelName, events, organisationId);
}
