import { useEffect, useMemo } from "react";
import { SubstationFeature, TurbineFeature } from "types/feature";
import {
  MIN_DIVISION_VISIBLE_ZOOM,
  cableFreeSectorLayerId,
  cableFreeSectorOutlineLayerId,
  cableFreeSectorSourceId,
} from "./constants";
import { safeRemoveLayer } from "utils/map";
import { addLayer } from "./utils";
import { notUndefinedOrNull } from "utils/predicates";
import { colors } from "styles/colors";
import { CableFreeSector, makeSector } from "components/CableFreeSector/types";

const fillPaint: mapboxgl.FillPaint = {
  "fill-color": colors.exclusionZone,
  "fill-opacity": 0.4,
};

const linePaint: mapboxgl.LinePaint = {
  "line-color": colors.exclusionZone,
  "line-width": 4,
  "line-opacity": 1.0,
};

export const RenderTurbineCableZones = ({
  features,
  sectors,
  map,
}: {
  features: (TurbineFeature | SubstationFeature)[];
  sectors?: CableFreeSector[];
  map: mapboxgl.Map;
}) => {
  const sectorFeatures = useMemo(() => {
    if (sectors)
      return features.flatMap((f) => {
        return sectors
          .map(({ middle, span, distanceM }) =>
            makeSector({ feature: f, middle, span, distanceM }),
          )
          .filter(notUndefinedOrNull);
      });
    return features
      .flatMap((f) =>
        (f.properties.cableFreeSectors ?? []).map(
          ({ middle, span, distanceM }) =>
            makeSector({ feature: f, middle, span, distanceM }),
        ),
      )
      .filter(notUndefinedOrNull);
  }, [features, sectors]);

  useEffect(() => {
    map.addSource(cableFreeSectorSourceId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    return () => {
      safeRemoveLayer(map, cableFreeSectorOutlineLayerId);
      safeRemoveLayer(map, cableFreeSectorLayerId);
      map.removeSource(cableFreeSectorSourceId);
    };
  }, [map]);

  useEffect(() => {
    addLayer(map, {
      id: cableFreeSectorLayerId,
      type: "fill",
      source: cableFreeSectorSourceId,
      paint: fillPaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
    addLayer(map, {
      id: cableFreeSectorOutlineLayerId,
      type: "line",
      source: cableFreeSectorSourceId,
      paint: linePaint,
      minzoom: MIN_DIVISION_VISIBLE_ZOOM,
    });
  }, [map]);

  useEffect(() => {
    const source = map.getSource(cableFreeSectorSourceId);
    if (source?.type !== "geojson") return;
    source.setData({
      type: "FeatureCollection",
      features: sectorFeatures,
    });
  }, [map, sectorFeatures]);

  return null;
};
