import { isApprox } from "utils/geometry";
import { z, ZodError } from "zod";
import {
  ConfidenceLevel,
  CostUnit,
  CostWithUnit,
  OccuranceType,
} from "../types/financial";
import { isDefined } from "../utils/predicates";
import { scream } from "../utils/sentry";
import { fetchEnhancerWithToken, fetchSchemaWithToken } from "./utils";
import { ProjectType } from "types/user";

export enum CostType {
  Turbine = "turbine",
  Cable = "cable",
  Mooring = "mooring",
  Substation = "substation",
  ExportCable = "exportCable",
  Foundation = "foundation",
  Other = "other",
}

const _CostConfidenceLevel = z.nativeEnum(ConfidenceLevel);

const _CustomCapexEntry = z.object({
  id: z.string(),
  name: z.string(),
  cost: z.number().min(0),
  category: z.nativeEnum(CostType),
  unit: z.nativeEnum(CostUnit),
  confidenceLevel: _CostConfidenceLevel
    .default(ConfidenceLevel.notSpecified)
    .optional(),
  freeText: z.string().optional(),
});

export const _CostWithUnit = z.object({
  cost: z.number().min(0),
  unit: z.nativeEnum(CostUnit),
  confidenceLevel: _CostConfidenceLevel
    .default(ConfidenceLevel.notSpecified)
    .optional(),
  freeText: z.string().optional(),
});

const _LCOE = z.object({
  discountRate: z.number().min(0).max(1),
});

const _FEATURE_COST = z.literal("feature_cost");

const _ComponentTableCost = z.object({
  id: z.string(),
  cost: z.number().min(0),
  unit: z.nativeEnum(CostUnit),
});

const _FEATURE_OR_OVERRIDE_COST = z.union([_CostWithUnit, _FEATURE_COST]);

const _TURBINE_COST = z
  .union([_CostWithUnit, _FEATURE_COST])
  .default("feature_cost");

const _FloatingMaterialCost = z.object({
  primarySteel: _CostWithUnit,
  concrete: _CostWithUnit,
  reinforcement: _CostWithUnit,
  postTensionCables: _CostWithUnit,
  solidBallast: _CostWithUnit,
});

const _MonopileMaterialCost = z.object({
  primarySteel: _CostWithUnit,
});

const _JacketMaterialCost = z.object({
  primarySteel: _CostWithUnit,
});

const _FoundationMaterialCost = z.object({
  floating: _FloatingMaterialCost,
  monopile: _MonopileMaterialCost,
  jacket: _JacketMaterialCost,
});

const _OPERATIONS_COST = z.literal("operations_cost");
const _INSTALLATION_COST = z.union([_CostWithUnit, _OPERATIONS_COST]);
const _LibraryFoundationReferenceCost = z.object({
  floatingCostReference: z.string().optional(),
  monopileCostReference: z.string().optional(),
  jacketCostReference: z.string().optional(),
});

export type FoundationMaterialCost = z.infer<typeof _FoundationMaterialCost>;

export type LibraryFoundationReferenceCost = z.infer<
  typeof _LibraryFoundationReferenceCost
>;

const _FOUNDATION_COST = z.union([
  _CostWithUnit,
  _FoundationMaterialCost,
  _LibraryFoundationReferenceCost,
]);

type FeatureCost = z.infer<typeof _FEATURE_COST>;

type OperationsCost = z.infer<typeof _OPERATIONS_COST>;
type InstallationCost = z.infer<typeof _INSTALLATION_COST>;

type FeatureOrOverrideCost = z.infer<typeof _FEATURE_OR_OVERRIDE_COST>;

type TurbineCost = z.infer<typeof _TURBINE_COST>;
type FoundationCost = z.infer<typeof _FOUNDATION_COST>;

export const isTurbineFeatureCost = (cost?: TurbineCost): cost is FeatureCost =>
  cost === "feature_cost";

export const isTurbineOverrideCost = (
  cost?: TurbineCost,
): cost is CostWithUnit => !isTurbineFeatureCost(cost) && cost !== undefined;

export const isCableOverrideCost = (
  cost?: z.infer<typeof _CostWithUnit>,
): cost is CostWithUnit => cost !== undefined;

export const isCableFeatureCost = (
  cost?: z.infer<typeof _CostWithUnit>,
): cost is undefined => !isCableOverrideCost(cost);

export const isFeatureCost = (
  cost?: FeatureOrOverrideCost,
): cost is FeatureCost => cost === "feature_cost";

export const isOperationsCost = (
  cost?: InstallationCost,
): cost is OperationsCost => !!cost && cost === "operations_cost";

export const isOperationsOverrideCost = (
  cost?: InstallationCost,
): cost is CostWithUnit => !isOperationsCost(cost) && cost !== undefined;

export const isOverrideCost = (
  cost?: FeatureOrOverrideCost,
): cost is CostWithUnit => !isFeatureCost(cost) && cost !== undefined;

export const isFoundationMaterialCost = (
  cost?: FoundationCost,
): cost is FoundationMaterialCost =>
  cost !== undefined && cost.hasOwnProperty("floating");

export const isFoundationOverrideCost = (
  cost?: FoundationCost,
): cost is CostWithUnit => cost !== undefined && cost.hasOwnProperty("cost");

export const isLibraryFoundationReferenceCost = (
  cost?: FoundationCost,
): cost is LibraryFoundationReferenceCost =>
  cost !== undefined && cost.hasOwnProperty("floatingCostReference");

export const containsOperationsCost = (capex: CAPEX) =>
  Object.values(capex.installation)
    .filter((cost): cost is InstallationCost => cost !== null)
    .some(isOperationsCost);

export const isSubstationParameterCost = (
  cost?: SubstationCost,
): cost is SubstationParameterCost =>
  cost !== undefined && cost.hasOwnProperty("HVAC");

export const isSubstationOverrideCost = (
  cost?: SubstationCost,
): cost is CostWithUnit => cost !== undefined && cost.hasOwnProperty("cost");

const _Year = z.number().min(1900).max(2100);

const _ComponentCost = z.object({
  id: z.string(),
  cost: z.number().min(0),
  unit: z.nativeEnum(CostUnit),
});

export type ComponentCost = z.infer<typeof _ComponentCost>;

const _ProjectComponentCosts = z
  .object({
    turbines: z.record(z.string(), _ComponentCost).default({}),
    cables: z.record(z.string(), _ComponentCost).default({}),
    // Choosing to name this exportCable instead of exportCables to match the other keys
    exportCable: z.record(z.string(), _ComponentCost).default({}),
  })
  .optional()
  .default({
    turbines: {},
    cables: {},
    exportCable: {},
  });

const _OffshoreOnshoreSubstationCost = z.object({
  offshore: z.object({
    cost: z.number(),
    unit: z.union([
      z.literal("m€/unit"),
      z.literal("k€/MW"),
      z.literal("m€/MW"),
    ]),
  }),
  onshore: z.object({
    cost: z.number(),
    unit: z.union([
      z.literal("m€/unit"),
      z.literal("k€/MW"),
      z.literal("m€/MW"),
    ]),
  }),
});

const _SubstationParameterCost = z.object({
  HVDC: _OffshoreOnshoreSubstationCost,
  HVAC: _OffshoreOnshoreSubstationCost,
});

type SubstationParameterCost = z.infer<typeof _SubstationParameterCost>;

const _SubstationCost = z.union([_CostWithUnit, _SubstationParameterCost]);

export type SubstationCost = z.infer<typeof _SubstationCost>;

const _CAPEX = z.object({
  projectComponentCosts: _ProjectComponentCosts,
  fixed: z
    .object({
      turbines: _TURBINE_COST,
      turbinesFreeText: z.string().optional(),
      cables: _CostWithUnit,
      cablesFreeText: z.string().optional(),
      mooring: z
        .object({
          lines: _CostWithUnit,
          anchors: _CostWithUnit,
          clumpWeights: _CostWithUnit,
          buoys: _CostWithUnit,
        })
        .partial()
        .required({ anchors: true, clumpWeights: true, buoys: true }),
      mooringFreeText: z.string().optional(),
      foundations: z.union([
        _CostWithUnit,
        _FoundationMaterialCost,
        _LibraryFoundationReferenceCost,
      ]),
      foundationsFreeText: z.string().optional(),
      substation: _SubstationCost,
      substationFreeText: z.string().optional(),
      exportCable: _FEATURE_OR_OVERRIDE_COST,
      exportCableFreeText: z.string().optional(),
    })
    .partial()
    .required({
      turbines: true,
      substation: true,
      exportCable: true,
    }),
  custom: _CustomCapexEntry.array(),
  installation: z.object({
    turbines: _INSTALLATION_COST,
    turbinesFreeText: z.string().optional(),
    cables: _INSTALLATION_COST,
    cablesFreeText: z.string().optional(),
    mooring: _INSTALLATION_COST,
    mooringFreeText: z.string().optional(),
    foundations: _INSTALLATION_COST,
    foundationsFreeText: z.string().optional(),
    substation: _INSTALLATION_COST,
    substationFreeText: z.string().optional(),
    exportCable: _INSTALLATION_COST,
    exportCableFreeText: z.string().optional(),
  }),
});

const _OpexEntry = z.object({
  id: z.string(),
  name: z.string(),
  cost: z.number(),
  unit: z.union([
    z.literal(CostUnit.thousandEuroPerMWPerYear),
    z.literal(CostUnit.euroPerMWh),
    z.literal(CostUnit.millionEuro),
  ]),
  occurance: z.union([
    z.literal(OccuranceType.Yearly),
    z.literal(OccuranceType.Recurring),
    z.literal(OccuranceType.SingleEvent),
  ]),
  occuranceYear: z.number().optional(),
});
export type OpexEntry = z.infer<typeof _OpexEntry>;

const _OPEX = z.object({
  custom: _OpexEntry.array(),
});

const _PhasingRow = z.array(z.number()).refine(
  (numbers) => {
    const sum = numbers.reduce((acc, n) => acc + n);

    return isApprox(sum, 1.0, 0.00049);
  },
  (numbers) => {
    const sum = numbers.reduce((acc, n) => acc + n) * 100;
    return {
      message: `The row should sum to 100%, was ${sum.toFixed(1)}%`,
    };
  },
);

const _LifeCycle = z
  .object({
    projectStart: _Year,
    operationStart: _Year,
    decomissioning: _Year,
    phasing: z.object({
      devex: _PhasingRow,
      capex: _PhasingRow,
    }),
  })
  .refine(
    ({ projectStart, operationStart, decomissioning }) =>
      projectStart <= operationStart && operationStart < decomissioning,
    (lifeCycle) => {
      const { projectStart, operationStart, decomissioning } = lifeCycle;
      let message = undefined;

      if (projectStart > operationStart) {
        message = "operation start cannot be before project start";
      } else if (decomissioning <= operationStart) {
        message = "decomissioning must be after operation start";
      }

      return {
        message,
      };
    },
  );

export type LifeCycle = z.infer<typeof _LifeCycle>;

const _Inflation = z.object({
  referenceYearCapex: _Year.min(2010).max(2050),
  referenceYearOtherExpenditures: _Year.min(2010).max(2050),
  referenceYearRevenue: _Year.min(2010).max(2050),
  inflationRate: z.number().min(0).max(1),
});

const _FractionContingency = z.object({
  type: z.enum(["fraction"]),
  value: z.number().min(0).max(1),
});

const _Contingency = z.object({
  capex: _FractionContingency,
  opex: _FractionContingency.default({ type: "fraction", value: 0 }),
});
export type Contingency = z.infer<typeof _Contingency>;

export type Inflation = z.infer<typeof _Inflation>;

const _CashFlows = z.object({
  guaranteedPrice: _CostWithUnit,
  guaranteedYears: z.number().min(0),
  marketPrice: _CostWithUnit,
  loanCapexFraction: z.number().min(0).max(1),
  loanInterestRate: z.number().min(0).max(1),
  loanRepaymentYears: z.number().min(0),
});

export const _CostConfiguration = z.object({
  id: z.string(),
  name: z.string(),
  type: z.union([z.literal("offshore"), z.literal("onshore")]),
  description: z.string().optional(),
  devex: _CostWithUnit,
  capex: _CAPEX,
  opex: _OPEX,
  decom: _CostWithUnit,
  lcoe: _LCOE,
  lifeCycle: _LifeCycle,
  inflation: _Inflation,
  contingency: _Contingency.default({
    capex: { type: "fraction", value: 0 },
    opex: { type: "fraction", value: 0 },
  }),
  cashFlows: _CashFlows,
  useConfidenceLevel: z.boolean().optional(),
  showFreeTextCapex: z.boolean().optional(),
  author: z.string().nullish(),
  lastUpdatedAt: z.number().nullish(),
});

export type CostConfiguration = z.infer<typeof _CostConfiguration>;

export const _CostConfigurationInput = _CostConfiguration.omit({
  id: true,
  author: true,
  createdAt: true,
  lastUpdatedAt: true,
});

export type CostConfigurationInput = z.infer<typeof _CostConfigurationInput>;

const _CostConfigurationsResponse = z.object({
  items: z.array(_CostConfiguration),
});

type CostConfigurationsResponse = z.infer<typeof _CostConfigurationsResponse>;

const _CostConfigurationUsageType = z.object({
  costConfigurationId: z.string(),
  projectId: z.string(),
  branchId: z.string(),
});
export type CostConfigurationUsageType = z.infer<
  typeof _CostConfigurationUsageType
>;

const _AblyCostUpdate = z.object({
  branchId: z.string(),
  updatedCost: _CostConfiguration,
});

type CostResponse = z.infer<typeof _CostConfiguration>;
export type CapexEntry = z.infer<typeof _CustomCapexEntry>;
export type LCOE = z.infer<typeof _LCOE>;
export type CAPEX = z.infer<typeof _CAPEX>;
export type CashFlows = z.infer<typeof _CashFlows>;

export async function createDefaultNodeFinancialConfiguration(
  nodeId: string,
  projectType: ProjectType,
) {
  return await fetchSchemaWithToken(
    _CostConfiguration,
    `/api/cost/node/${nodeId}/configurations/default`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectType,
      }),
    },
  );
}

export const createConfiguration = async (
  nodeId: string,
  input: CostConfigurationInput,
): Promise<CostResponse> => {
  try {
    return await fetchSchemaWithToken(
      _CostConfiguration,
      `/api/cost/node/${nodeId}/configurations`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...input,
        }),
      },
    );
  } catch (err) {
    if (err instanceof Response) {
      scream("Failed to create cost config", {
        body: await err.text(),
        statusCode: `${err.status} ${err.statusText}`,
        url: err.url,
      });
    } else if (err instanceof Error) {
      scream(err);
    }

    throw err;
  }
};

export const updateConfiguration = async (
  nodeId: string,
  configurationId: string,
  input: Partial<CostConfigurationInput>,
): Promise<CostResponse> => {
  try {
    return await fetchSchemaWithToken(
      _CostConfiguration,
      `/api/cost/node/${nodeId}/configurations/${configurationId}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...input,
        }),
      },
    );
  } catch (err) {
    if (err instanceof Response) {
      scream("Failed to update cost config", {
        body: await err.text(),
        statusCode: `${err.status} ${err.statusText}`,
        url: err.url,
      });
    } else if (err instanceof Error) {
      scream(err);
    }

    throw err;
  }
};

export const deleteConfiguration = async (
  nodeId: string,
  configurationId: string,
): Promise<Response> => {
  try {
    return fetchEnhancerWithToken(
      `/api/cost/node/${nodeId}/configurations/${configurationId}`,
      {
        method: "delete",
      },
    );
  } catch (err) {
    if (err instanceof Response) {
      scream("Failed to delete cost config", {
        body: await err.text(),
        statusCode: `${err.status} ${err.statusText}`,
        url: err.url,
      });
    } else if (err instanceof Error) {
      scream(err);
    }

    throw err;
  }
};

export const listCostConfigurations = async (
  nodeId: string,
): Promise<CostConfigurationsResponse> => {
  try {
    const res = await fetchSchemaWithToken(
      z.object({ items: z.record(z.string(), z.unknown()).array() }),
      `/api/cost/node/${nodeId}/configurations`,
      {
        method: "get",
      },
    );

    const errors: ZodError[] = [];

    const items = res.items
      .map((item) => {
        const config = _CostConfiguration
          .extend({ createdAt: z.number() })
          .safeParse(item);
        if (config.success) {
          return config.data;
        }
        errors.push(config.error);
        return undefined;
      })
      .filter(isDefined)
      .sort((a, b) => a.createdAt - b.createdAt);

    if (errors.length > 0) {
      scream("Invalid cost configurations, nodeId: " + nodeId, { errors });
    }

    return { items };
  } catch (err) {
    if (err instanceof Response) {
      scream("Failed to fetch cost configs", {
        body: await err.text(),
        statusCode: `${err.status} ${err.statusText}`,
        url: err.url,
      });
    } else if (err instanceof Error) {
      scream(err);
    }

    throw err;
  }
};

// --------- Organisation cost configuration ------------------------

export const listOrgFinancialConfigurations = async (
  organisationId: string,
): Promise<CostConfigurationsResponse> => {
  try {
    const res = await fetchSchemaWithToken(
      z.object({ items: z.record(z.string(), z.unknown()).array() }),
      `/api/cost/organisation/${organisationId}/configurations`,
      {
        method: "get",
      },
    );

    const errors: ZodError[] = [];

    const items = res.items
      .map((item) => {
        const config = _CostConfiguration
          .extend({ createdAt: z.number() })
          .safeParse(item);
        if (config.success) {
          return config.data;
        }
        errors.push(config.error);
        return undefined;
      })
      .filter(isDefined)
      .sort((a, b) => a.createdAt - b.createdAt);

    if (errors.length > 0) {
      scream("Invalid library cost configurations", { errors });
    }

    return { items };
  } catch (err) {
    if (err instanceof Response) {
      scream("Failed to fetch org cost configs", {
        body: await err.text(),
        statusCode: `${err.status} ${err.statusText}`,
        url: err.url,
      });
    } else if (err instanceof Error) {
      scream(err);
    }

    throw err;
  }
};

export async function createDefaultOrgFinancialConfiguration(
  organisationId: string,
  projectType: ProjectType,
  name: string,
  foundationProcurementCostTableReference?: string,
  projectAccess?: string[],
) {
  return await fetchSchemaWithToken(
    _CostConfiguration,
    `/api/cost/organisation/${organisationId}/configurations/default`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        projectType,
        foundationProcurementCostTableReference,
        projectAccess,
      }),
    },
  );
}

export async function createOrgFinancialConfiguration(
  organisationId: string,
  config: CostConfigurationInput,
  projectAccess?: string[],
) {
  return await fetchSchemaWithToken(
    _CostConfiguration,
    `/api/cost/organisation/${organisationId}/configurations`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ config, projectAccess }),
    },
  );
}

export async function updateOrgFinancialConfiguration(
  organisationId: string,
  configurationId: string,
  update: CostConfigurationInput,
) {
  return fetchEnhancerWithToken(
    `/api/cost/organisation/${organisationId}/configurations/${configurationId}`,
    {
      method: "put",
      body: JSON.stringify(update),
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
    .then(async (response) => {
      const json = await response.json();
      return _CostConfiguration.parse(json);
    })
    .catch((err) => {
      scream("updateOrgFinancialConfig failed", { e: err });
      throw err;
    });
}

export async function deleteOrgFinancialConfiguration(
  organisationId: string,
  configId: string,
) {
  const headers = {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchEnhancerWithToken(
    `/api/cost/organisation/${organisationId}/configurations/${configId}`,
    headers,
  ).catch((err) => {
    scream("deleteOrgFinancialConfiguration failed", { e: err });
    throw err;
  });
}
