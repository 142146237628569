import styled from "styled-components";
import {
  borderRadiusMedium,
  spaceHuge,
  spaceLarge,
  spaceMedium,
  spaceSmall,
  spaceTiny,
} from "../../styles/space";
import { colors } from "../../styles/colors";
import { typography } from "../../styles/typography";
import { Column } from "../General/Layout";

export const TextIcon = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4rem;
  min-height: 1.4rem;
  border-radius: 1rem;
  border: 1px solid
    ${({ selected }) => (selected ? "white" : colors.primaryText)};
  ${({ selected }) => selected && `color: white;`}
`;

export const CenterGrayMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 10rem;
  ${typography.contentAndButtons};
  color: ${colors.primaryDisabled};
`;

export const DropdownList = styled.div`
  display: flex;
  flex-directin: row;
  margin: 0 ${spaceTiny};
  gap: ${spaceMedium};
  align-items: baseline;

  h3 {
    margin: 0;
    margin-bottom: ${spaceMedium};
  }

  .dashboard-dropdown {
    min-width: 18rem;
    max-width: 18rem;

    div {
      button {
        flex: 1;
        align-items: center;
        overflow-x: hidden;

        transition:
          border-color 0.1s ease-in-out,
          color 0.1s ease-in-out;

        border: 1px solid ${colors.dropdownBorder};
        p {
          transition: color 0.1s ease-in-out;
          color: ${colors.primaryText};
          flex: 1;
        }

        svg {
          width: 1.4rem;
          height: 1.4rem;

          path[stroke] {
            stroke: ${colors.primaryText};
          }
          path:not([stroke]) {
            fill: ${colors.primaryText};
          }
        }

        &:hover {
          border: 1px solid ${colors.dropdownBorderHover};
          p {
            color: ${colors.primaryText};
          }
          svg {
            path[stroke] {
              stroke: ${colors.primaryText};
            }
            path:not([stroke]) {
              fill: ${colors.primaryText};
            }
          }
        }
      }
    }
  }
`;

export const CollapseWithButton = styled.div<{ hidden?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${spaceMedium};
  align-items: center;

  & > :not(:first-child) {
    ${(p) => p.hidden && "display: none;"};
  }
`;

export const VDivider = styled.div`
  margin: ${spaceSmall} ${spaceSmall};
  width: 1px;
  align-self: stretch;
  background: ${colors.inputOutline};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${spaceTiny};
`;

export const WidgetNewPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceMedium};
  align-items: center;
  justify-content: center;

  width: 20rem;
  height: 20rem;

  margin: ${spaceLarge};

  border: 1px dashed ${colors.inputOutline};
  border-radius: ${borderRadiusMedium};

  p,
  path {
    color: ${colors.primary};
    stroke: ${colors.primary};
  }

  transition: background 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    background: ${colors.hover};
  }
`;

export const DashboardColumn = styled(Column)`
  flex: 1;
  padding: ${spaceHuge};
`;
