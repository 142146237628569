import { Label } from "components/General/Form";
import { InputDimensioned } from "components/General/Input";
import { Row } from "components/General/Layout";
import Toggle, { ToggleSize } from "components/General/Toggle";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import ChangelogAndCommentWrapper from "components/InputChangelog/ChangelogAndCommentWrapper";
import {
  HorizontalLine,
  Subtitle,
  SecondaryText,
  Text,
  TitleContainer,
} from "components/SettingsV2/Shared/styles";
import { spaceMedium } from "styles/space";
import { CableType } from "services/cableTypeService";
import { ChangelogCategory } from "components/InputChangelog/types";

const between = (min: number, max: number) => (n: number) =>
  min <= n && n <= max;

interface AdvancedSettingsProps {
  cable: CableType;
  setCable: (cable: CableType) => void;
  disabled: boolean;
  nodeId: string;
  changelogCategory: ChangelogCategory;
  getChangelogInfo: (
    id: string,
    nodeId: string,
    field: string,
    category: ChangelogCategory,
  ) => any;
}

export const AdvancedSettingsHeader = ({
  cable,
  setCable,
  disabled,
  nodeId,
  changelogCategory,
  getChangelogInfo,
}: AdvancedSettingsProps) => {
  return (
    <>
      <TitleContainer>
        <Subtitle>Advanced settings</Subtitle>
        <HorizontalLine />
      </TitleContainer>
      <ChangelogAndCommentWrapper
        changelogInfo={getChangelogInfo(
          cable.id,
          nodeId,
          "useAdvancedSettings",
          changelogCategory,
        )}
        disabled={disabled}
      >
        <Row
          style={{
            width: "22rem",
            justifyContent: "left",
            marginTop: spaceMedium,
          }}
        >
          <Toggle
            checked={cable.useAdvancedSettings}
            disabled={disabled}
            size={ToggleSize.SMALL}
            onChange={() =>
              setCable({
                ...cable,
                useAdvancedSettings: !cable.useAdvancedSettings,
              })
            }
          />
          <Text>Use advanced settings</Text>
          <HelpTooltip text="If toggled off, default values are used for these parameters." />
        </Row>
      </ChangelogAndCommentWrapper>
    </>
  );
};

const AdvancedSettings = ({
  cable,
  setCable,
  disabled,
  nodeId,
  changelogCategory,
  getChangelogInfo,
}: AdvancedSettingsProps) => {
  return (
    <>
      <Label>
        <SecondaryText>Sheat loss factor (λ1)</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          value={cable.lambda1}
          initialValue={cable.lambda1}
          validate={between(0, 1)}
          validationMessage="Must be between 0. and 1."
          type="number"
          changelogInfo={getChangelogInfo(
            cable.id,
            nodeId,
            "lambda1",
            changelogCategory,
          )}
          onChange={(e) => {
            if (typeof e !== "number") return;
            setCable({
              ...cable,
              lambda1: e,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
      <Label>
        <SecondaryText>Armour loss factor (λ2)</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          value={cable.lambda2}
          initialValue={cable.lambda2}
          validate={between(0, 1)}
          validationMessage="Must be between 0. and 1."
          type="number"
          changelogInfo={getChangelogInfo(
            cable.id,
            nodeId,
            "lambda2",
            changelogCategory,
          )}
          onChange={(e) => {
            setCable({
              ...cable,
              lambda2: e,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
      <Label>
        <SecondaryText>Dielectric loss factor (tan δ)</SecondaryText>
        <InputDimensioned
          disabled={disabled}
          value={cable.dielectricLossFactor}
          initialValue={cable.dielectricLossFactor}
          validate={between(0, 0.01)}
          validationMessage="Must be between 0. and 0.01"
          type="number"
          changelogInfo={getChangelogInfo(
            cable.id,
            nodeId,
            "dielectricLossFactor",
            changelogCategory,
          )}
          onChange={(e) => {
            setCable({
              ...cable,
              dielectricLossFactor: e,
            });
          }}
          style={{
            width: "20rem",
          }}
        />
      </Label>
    </>
  );
};

export default AdvancedSettings;
