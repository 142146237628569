import {
  NodeCableAccess,
  _NodeCableAccess,
  _NodeSubstationAccess,
  NodeSubstationAccess,
} from "components/Organisation/Library/types";
import { fetchSchemaWithToken } from "services/utils";

export const getCableResourcesOnNode = async (
  nodeId: string,
): Promise<NodeCableAccess[]> => {
  return fetchSchemaWithToken(
    _NodeCableAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/cable`,
    {
      method: "get",
    },
  );
};

export const getExportCableResourcesOnNode = async (
  nodeId: string,
): Promise<NodeCableAccess[]> => {
  return fetchSchemaWithToken(
    _NodeCableAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/exportcable`,
    {
      method: "get",
    },
  );
};

export async function getSubstationResourcesOnNode(
  nodeId: string,
): Promise<NodeSubstationAccess[]> {
  return fetchSchemaWithToken(
    _NodeSubstationAccess.array(),
    `/api/library/libraryaccess/node/${nodeId}/substation`,
    {
      method: "get",
    },
  );
}
