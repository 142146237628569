import * as z from "zod";
import { components as customerComponents } from "api/customer";
import * as timelineSpec from "../../api/timeline-api";

export enum EventActions {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CREATE = "CREATE",
}

export const _ChangelogComment = z.object({
  keyPath: z.string(),
  comment: z.string(),
  createdAt: z.number(),
  author: z.string(),
});

export type ChangelogComment = z.infer<typeof _ChangelogComment>;

const _ChangelogCategory = z.union([
  customerComponents.schemas.OrganisationResources,
  z.literal("project"),
]);

export type ChangelogCategory = z.infer<typeof _ChangelogCategory>;

const _ProtoChangelogEntry = z.object({
  id: z.string(),
  branchId: z.string().optional(),
  nodeId: z.string(),
  author: z.string(),
  action: z.nativeEnum(EventActions),
  version: z.number(),
  sqsMessageId: z.string(),
  meta: z.record(z.any()).optional(),
  requiredAccess: _ChangelogCategory.optional(),
  comments: _ChangelogComment.array().nullish(),
});

const _UpdateChangelogEntry = _ProtoChangelogEntry.extend({
  action: z.literal(EventActions.UPDATE),
  meta: z.record(z.any()),
});

const _CreateChangelogEntry = _ProtoChangelogEntry.extend({
  action: z.literal(EventActions.CREATE),
  meta: z.record(z.any()),
});

const _DeleteChangelogEntry = _ProtoChangelogEntry.extend({
  action: z.literal(EventActions.DELETE),
});

export const _ChangelogEntry = z.union([
  _CreateChangelogEntry,
  _UpdateChangelogEntry,
  _DeleteChangelogEntry,
  _ProtoChangelogEntry,
]);
export type ChangelogEntry = z.infer<typeof _ChangelogEntry>;

export const _UpdateCommentAblyMessage = z.object({
  comments: _ChangelogComment.array(),
  changelogId: z.string(),
  category: _ChangelogCategory.optional(),
});

export const _BasicChangelogEntry = _ProtoChangelogEntry.extend({
  meta: z.record(z.any()).optional(),
});
export type BasicChangelogEntry = z.infer<typeof _BasicChangelogEntry>;

const _ChangelogAddRenderEntry = z.object({
  id: z.string(),
  author: z.string(),
  action: z.literal(EventActions.CREATE),
  version: z.number(),
  value: z.any(),
  comment: _ChangelogComment.nullish(),
});
const _ChangelogUpdateRenderEntry = z.object({
  id: z.string(),
  author: z.string(),
  action: z.literal(EventActions.UPDATE),
  version: z.number(),
  oldValue: z.any(),
  value: z.any(),
  comment: _ChangelogComment.nullish(),
});
const _ChangelogDeleteRenderEntry = z.object({
  id: z.string(),
  author: z.string(),
  action: z.literal(EventActions.DELETE),
  version: z.number(),
  comment: _ChangelogComment.nullish(),
});
const _ChangelogRenderEntry = z.union([
  _ChangelogAddRenderEntry,
  _ChangelogUpdateRenderEntry,
  _ChangelogDeleteRenderEntry,
]);

export type ChangelogRenderEntry = z.infer<typeof _ChangelogRenderEntry>;

const _getChangeLogValue = z
  .function()
  .args(z.object({}), z.string())
  .returns(z.object({}))
  .optional();

export type GetChangeLogValue = z.infer<typeof _getChangeLogValue>;

const _InputChangelogInfo = z.object({
  changelogEntryKeyId: z.string(),
  changelogId: z.string(),
  nodeId: z.string(),
  getValue: _getChangeLogValue,
  category: _ChangelogCategory,
});

export type InputChangelogInfo = z.infer<typeof _InputChangelogInfo>;

export const _ChangelogMetadata =
  timelineSpec.components.schemas.ChangelogMetadataItem;
export type ChangelogMetadata = z.infer<typeof _ChangelogMetadata>;
