import { atom } from "jotai";
import { z } from "zod";
import {
  _MeanSpeedGrid,
  _MeanSpeedGridCustom,
  _RawWindDirection,
  _RawWindRose,
  _SimpleWindRose,
  _UploadedWindData,
  _WRG,
  _WRGPoint,
  _WRGSector,
  _WindData,
  _WindDataMultipleHeights,
  _WindDataVortex,
  _WindStats,
  _WindTimeseries,
  _YearlyMonthlyWindRoses,
} from "./jotai/windStatistics";

export const showWakeAtom = atom<boolean>(false);

export const showMeanSpeedGridAtom = atom<boolean>(false);

export const meanSpeedGridLimitsAtom = atom<[number, number] | undefined>(
  undefined,
);

export const wakeDirectionAtom = atom<number>(270);

export type WRGSector = z.infer<typeof _WRGSector>;
export type WRGPoint = z.infer<typeof _WRGPoint>;
export type WRG = z.infer<typeof _WRG>;
export type MeanSpeedGrid = z.infer<typeof _MeanSpeedGrid>;
export type WindTimeseries = z.infer<typeof _WindTimeseries>;
export type WindData = z.infer<typeof _WindData>;
type WindDataVortex = z.infer<typeof _WindDataVortex>;
export type WindDataMultipleHeights = z.infer<typeof _WindDataMultipleHeights>;
export type SimpleWindRose = z.infer<typeof _SimpleWindRose>;
export type UploadedWindData = z.infer<typeof _UploadedWindData>;

export const isWindDataMultipleHeights = (
  data: WindData | WindDataVortex | WindDataMultipleHeights,
): data is WindDataMultipleHeights => {
  return "heights" in data;
};
