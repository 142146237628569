import { useAtomValue } from "jotai";
import { organisationIdAtom } from "state/pathParams";
import { aset, useJotaiCallback } from "utils/jotai";
import { useToast } from "hooks/useToast";
import {
  deleteCustomOrganisationLayers,
  editCustomOrganisationLayerMetadata,
} from "services/customLayersAPIService";
import {
  getDataLayersUsage,
  getDataPackageUsage,
  organisationLibraryLayersAtomFamily,
} from "./state";
import {
  cloneOrganisationCustomLayer,
  deleteOrganisationCustomLayerResource,
  deleteOrganisationLibraryPackage,
  newOrganisationLibraryPackage,
  updateOrganisationLibraryPackage,
  updateOrganisationLibraryPackageLayerIds,
} from "./service";
import { dedup } from "utils/utils";
import { useState } from "react";
import { scream } from "utils/sentry";
import {
  DataLibraryLayer,
  DataLibraryPackage,
  NewLayersLibraryPackage,
} from "./types";
import { organisationLibraryDataPackageResourceState } from "../state";
import { RESET } from "jotai/utils";
import { useConfirm } from "components/ConfirmDialog/ConfirmDialog";

export const useDataLibraryLayersCrud = () => {
  const organisationId = useAtomValue(organisationIdAtom) ?? "";
  const { success: showSuccess, error: showError } = useToast();
  const [loading, setLoading] = useState(false);
  const [isDeletingPackage, setIsDeletingPackage] = useState(false);
  const [creatingNewPackage, setCreatingNewPackage] = useState(false);
  const [addingLayersToPackage, setAddingLayersToPackage] = useState(false);
  const { showConfirm } = useConfirm();

  const refreshLayers = useJotaiCallback(
    (_get, set) => {
      set(
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        RESET,
      );
    },
    [organisationId],
  );

  const deleteLayer = useJotaiCallback(
    async (get, set, layerId: string) => {
      const fallback = await get(
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
      );

      const usage = await get(
        getDataLayersUsage({
          nodeId: organisationId,
          layerIds: [layerId],
        }),
      );

      const totalUsage = Object.values(usage).flat();
      if (
        totalUsage.length !== 0 &&
        !(await showConfirm({
          title: "Delete layer",
          message: `Layer is currently being used in ${totalUsage.length} projects, are you sure you want to remove it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      } else if (
        totalUsage.length === 0 &&
        !(await showConfirm({
          title: "Delete layer",
          message: `Are you sure you want to delete this layer?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      }

      aset(
        get,
        set,
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        (curr) => {
          return curr.filter((layer) => layer.id !== layerId);
        },
      );
      try {
        await deleteOrganisationCustomLayerResource(organisationId, layerId);
        showSuccess("The layer was successfully deleted");
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryLayersAtomFamily({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when deleting the layer, please try again.",
        );
      }
    },
    [organisationId, showConfirm, showError, showSuccess],
  );

  const addLayerLocal = useJotaiCallback(
    async (get, set, addedLayer: DataLibraryLayer) => {
      return aset(
        get,
        set,
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        (curr) => [...curr, addedLayer],
      );
    },
    [organisationId],
  );

  const cloneOrgLayer = useJotaiCallback(
    async (_, __, layerId: string, name: string) => {
      try {
        const newLayer = await cloneOrganisationCustomLayer(
          organisationId,
          layerId,
          name,
        );
        showSuccess("The layer was successfully duplicated", {
          groupId: "clone-org-data-layer",
        });

        console.log({ newLayer });
        await addLayerLocal(newLayer);
      } catch (error) {
        if (error instanceof Error) {
          scream(error, {
            message: "Error cloning organisation layer",
            layerId,
            name,
          });
        } else {
          scream(new Error("Error cloning organisation layer"), {
            error,
            layerId,
            name,
          });
        }
        showError(
          "Something went wrong when duplicating the layer, please try again.",
          {
            groupId: "clone-org-data-layer",
          },
        );
      }
    },
    [addLayerLocal, organisationId, showError, showSuccess],
  );

  const updateLayerLocal = useJotaiCallback(
    async (get, set, updatedLayer: Partial<DataLibraryLayer>) => {
      return aset(
        get,
        set,
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        (curr) => {
          return curr.map((layer) =>
            layer.id !== updatedLayer.id
              ? layer
              : {
                  ...layer,
                  ...updatedLayer,
                },
          );
        },
      );
    },
    [organisationId],
  );

  const deleteLayerLocal = useJotaiCallback(
    async (get, set, deletedLayerId: string) => {
      return aset(
        get,
        set,
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        (curr) => {
          return curr.filter((layer) => layer.id !== deletedLayerId);
        },
      );
    },
    [organisationId],
  );

  const deleteLayers = useJotaiCallback(
    async (get, set, layerIds: string[]) => {
      const fallback = await get(
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
      );

      const usage = await get(
        getDataLayersUsage({
          nodeId: organisationId,
          layerIds,
        }),
      );

      const totalUsage = Object.values(usage).flat();
      if (
        totalUsage.length !== 0 &&
        !(await showConfirm({
          title: "Delete layers",
          message: `Layers are currently being used in ${totalUsage.length} projects, are you sure you want to delete them?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      } else if (
        totalUsage.length === 0 &&
        !(await showConfirm({
          title: "Delete layers",
          message: `Are you sure you want to delete these layers?`,
          confirmButtonText: "Delete",
        }))
      ) {
        return;
      }

      aset(
        get,
        set,
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
        (curr) => {
          return curr.filter((layer) => !layerIds.includes(layer.id));
        },
      );

      try {
        await deleteCustomOrganisationLayers(organisationId, layerIds);
        showSuccess(
          `${layerIds.length} ${layerIds.length !== 1 ? "layers were" : "layer was"} successfully deleted`,
        );
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryLayersAtomFamily({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when trying to delete the layers, please try again.",
        );
      }
    },
    [organisationId, showConfirm, showError, showSuccess],
  );

  const editLayerMetadata = useJotaiCallback(
    async (
      get,
      set,
      layerId: string,
      newData: {
        name?: string;
        description?: string;
      },
    ) => {
      const fallback = await get(
        organisationLibraryLayersAtomFamily({
          organisationId,
        }),
      );

      try {
        aset(
          get,
          set,
          organisationLibraryLayersAtomFamily({
            organisationId,
          }),
          (curr) => {
            return curr.map((layer) => {
              if (layer.id === layerId) {
                return {
                  ...layer,
                  ...newData,
                };
              }
              return layer;
            });
          },
        );
        await editCustomOrganisationLayerMetadata(
          organisationId,
          layerId,
          newData,
        );

        showSuccess("The layer was successfully updated");
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryLayersAtomFamily({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when updating the layer, please try again.",
        );
      }
    },
    [organisationId, showError, showSuccess],
  );

  const createPackage = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      newData: {
        name?: string;
      },
    ) => {
      setLoading(true);
      setCreatingNewPackage(true);
      const fallback = await get(
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
      );

      try {
        const newPackage = await newOrganisationLibraryPackage(organisationId, {
          name: newData.name || "Untitled",
          type: "package",
        });
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          (curr) => {
            return [...curr, newPackage];
          },
        );
        return newPackage;
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when adding new package, please try again.",
        );
        scream("Error posting new package", {
          error: err,
        });
      } finally {
        setLoading(false);
        setCreatingNewPackage(false);
      }
    },
    [setLoading, showError],
  );

  const deletePackage = useJotaiCallback(
    async (get, set, organisationId: string, packageId: string) => {
      setIsDeletingPackage(true);
      const fallback = await get(
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
      );

      const usage = await get(
        getDataPackageUsage({
          organisationId,
          packageId,
        }),
      );

      const totalUsage = Object.values(usage).flat();
      if (
        totalUsage.length !== 0 &&
        !(await showConfirm({
          title: "Delete package",
          message: `Data package configuration is currently being used in ${totalUsage.length} projects, are you sure you want to delete it?`,
          confirmButtonText: "Delete",
        }))
      ) {
        setIsDeletingPackage(false);
        return;
      } else if (
        totalUsage.length === 0 &&
        !(await showConfirm({
          title: "Delete package",
          message: "Are you sure you want to delete this package?",
          confirmButtonText: "Delete",
        }))
      ) {
        setIsDeletingPackage(false);
        return;
      }

      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) => curr.filter((p) => p.id !== packageId),
      );

      try {
        await deleteOrganisationLibraryPackage(organisationId, packageId);
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when deleting the package, please try again.",
        );
        scream("Error posting new package", {
          error: err,
        });
      } finally {
        setIsDeletingPackage(false);
      }
    },
    [showConfirm, showError],
  );

  const addLayersToPackage = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      packageId: string,
      layerIds: string[],
    ) => {
      setAddingLayersToPackage(true);
      const fallback = await get(
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
      );
      const currentLayerIds =
        fallback.find((p) => p.id === packageId)?.layerIds || [];
      const newLayers = dedup([...currentLayerIds, ...layerIds]);

      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) =>
          curr.map((p) => {
            if (p.id === packageId) {
              return {
                ...p,
                layerIds: newLayers,
              };
            }
            return p;
          }),
      );

      try {
        if (newLayers.length !== currentLayerIds.length) {
          await updateOrganisationLibraryPackageLayerIds(
            organisationId,
            packageId,
            newLayers,
          );
        }
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          `Something went wrong when adding the layer${layerIds.length !== 0 ? "s" : ""} to the package`,
        );
        if (err instanceof Error) {
          scream(err, {
            message: "Error when adding layers to package",
            layerIds,
            packageId,
          });
        } else {
          scream(new Error("Error when adding layers to package"), {
            layerIds,
            packageId,
            error: err,
          });
        }
      } finally {
        setAddingLayersToPackage(false);
      }
    },
    [showError],
  );

  const removeLayersFromPackage = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      packageId: string,
      layerIds: string[],
    ) => {
      // setLoading(true);
      const fallback = await get(
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
      );

      const usage = await get(
        getDataLayersUsage({
          nodeId: organisationId,
          layerIds: layerIds,
        }),
      );

      const totalUsage = Object.values(usage).flat();
      if (
        totalUsage.length !== 0 &&
        !(await showConfirm({
          title: "Remove access",
          message: `Layers are currently being used in ${totalUsage.length} projects, are you sure you want to remove access to it?`,
          confirmButtonText: "Remove",
        }))
      ) {
        return;
      } else if (
        totalUsage.length === 0 &&
        !(await showConfirm({
          title: "Remove access",
          message: "Are you sure you want to remove access to this layer?",
          confirmButtonText: "Remove",
        }))
      ) {
        return;
      }

      const currentLayerIds =
        fallback.find((p) => p.id === packageId)?.layerIds || [];
      const newLayers = currentLayerIds.filter((id) => !layerIds.includes(id));

      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) =>
          curr.map((p) => {
            if (p.id === packageId) {
              return {
                ...p,
                layerIds: newLayers,
              };
            }
            return p;
          }),
      );

      try {
        await updateOrganisationLibraryPackageLayerIds(
          organisationId,
          packageId,
          newLayers as any,
        );
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          `Something went wrong when removing the layer${layerIds.length !== 0 ? "s" : ""} from the package`,
        );
        if (err instanceof Error) {
          scream(err, {
            message: "Error when removing layers to package",
            layerIds,
            packageId,
          });
        } else {
          scream(new Error("Error when removing layers to package"), {
            layerIds,
            packageId,
            error: err,
          });
        }
      } finally {
        // setLoading(false);
      }
    },
    [showConfirm, showError],
  );

  const updatePackage = useJotaiCallback(
    async (
      get,
      set,
      organisationId: string,
      packageId: string,
      newData: NewLayersLibraryPackage,
    ) => {
      setLoading(true);
      const fallback = await get(
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
      );

      try {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          (curr) => {
            return curr.map((p) =>
              p.id === packageId
                ? {
                    ...p,
                    ...newData,
                  }
                : p,
            );
          },
        );
        await updateOrganisationLibraryPackage(
          organisationId,
          packageId,
          newData,
        );
      } catch (err) {
        aset(
          get,
          set,
          organisationLibraryDataPackageResourceState({
            organisationId,
          }),
          () => fallback,
        );
        showError(
          "Something went wrong when adding new package, the Vind team has been notified.",
        );
        scream("Error posting new package", {
          error: err,
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, showError],
  );

  const createPackageLocal = useJotaiCallback(
    async (get, set, newPackage: DataLibraryPackage) => {
      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) => [...curr, newPackage],
      );
    },
    [organisationId],
  );

  const updatePackageLocal = useJotaiCallback(
    async (get, set, updatedPackage: DataLibraryPackage) => {
      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) =>
          curr.map((p) => (p.id === updatedPackage.id ? updatedPackage : p)),
      );
    },
    [organisationId],
  );

  const deletePackageLocal = useJotaiCallback(
    async (get, set, deletedPackageId: string) => {
      aset(
        get,
        set,
        organisationLibraryDataPackageResourceState({
          organisationId,
        }),
        (curr) => curr.filter((p) => p.id !== deletedPackageId),
      );
    },
    [organisationId],
  );

  return {
    refreshLayers,
    deletePackage,
    deletePackageLocal,
    createPackage,
    createPackageLocal,
    creatingNewPackage,
    updatePackage,
    updatePackageLocal,
    editLayerMetadata,
    cloneOrgLayer,
    deleteLayer,
    deleteLayerLocal,
    addLayerLocal,
    updateLayerLocal,
    deleteLayers,
    addLayersToPackage,
    addingLayersToPackage,
    removeLayersFromPackage,
    loading,
    isDeletingPackage,
  };
};
