import { librarySubstationsFamily } from "state/jotai/substationType";
import { useJotaiCallback } from "utils/jotai";
import { SubstationType } from "services/substationService";

export function useLibraryNodeSubstations(projectId: string) {
  const updateLocal = useJotaiCallback(
    async (get, set, substation: SubstationType) => {
      set(
        librarySubstationsFamily(projectId),
        async (currentSubstationsPromise) => {
          const currentSubstations = await currentSubstationsPromise;
          const newSubstations = new Map(currentSubstations);
          newSubstations.set(substation.id, substation);
          return newSubstations;
        },
      );
    },
    [projectId],
  );

  return {
    updateLocal,
  };
}
