/** Feaure property to signal that the feature is being edited. */
export const editmodePropertyName = "vind-edit";

/**
 * Feature property to signal that the feature exists but is a "previous"
 * version. Used when generating turbines to show the position of the previous
 * turbines.
 */
export const ghostPropertyName = "vind-ghost";

export const cableSourceId = "vind:source:cable";
export const cableLayerId = "vind:layer:cable";
export const MIN_CABLE_VISIBLE_ZOOM = 7;
export const cableSymbolLayerId = "vind:layer:cable:symbol";

export const otherLineStringSourceId = "vind:source:otherlinestring";
export const otherLineStringLayerId = "vind:layer:otherlinestring";
export const otherLineStringSymbolLayerId = "vind:layer:otherlinestring:symbol";

export const otherPointSourceId = "vind:source:otherpoint";
export const otherPointLayerId = "vind:layer:otherpoint";
export const otherPointSymbolLayerId = "vind:layer:otherpoint:symbol";

export const divisionSourceId = "vind:source:division";
export const divisionLayerId = "vind:layer:division";
export const MIN_DIVISION_VISIBLE_ZOOM = 6;
export const divisionOutlineLayerId = "vind:layer:division:outline";
export const divisionSymbolLayerId = "vind:layer:division:symbol";

export const anchorSourceId = "vind:source:anchor";
export const anchorSymbolLayerId = "vind:layer:anchor:symbol";
export const anchorLayerId = "vind:layer:anchor";

export const existingTurbineSourceId = "vind:source:existing-turbine";
export const existingTurbineLayerId = "vind:layer:existing-turbine";
export const existingTurbineSymbolLayerId =
  "vind:layer:existing-turbine-symbol";
export const MIN_EXISTING_TURBINE_VISIBLE_ZOOM = 6;

export const cablePartitionSourceId = "vind:source:cablepartition";
export const cablePartitionLayerId = "vind:layer:cablepartition";

export const otherPolygonSourceId = "vind:source:otherpolygon";
export const otherPolygonLayerId = "vind:layer:otherpolygon";
export const otherPolygonOutlineLayerId = "vind:layer:otherpolygon:outline";
export const otherPolygonSymbolLayerId = "vind:layer:otherpolygon:symbol";

export const turbineSourceId = "vind:source:turbine";
export const turbineLayerId = "vind:layer:turbine";
export const MIN_TURBINES_VISIBLE_ZOOM = 6;
export const turbineBufferLayerId = "vind:layer:turbine:buffer";
export const turbineSymbolLayerId = "vind:layer:turbine:symbol";
export const turbineDiameterSourceId = "vind:source:turbine:diameter";
export const turbineDiameterLayerId = "vind:layer:turbine:diameter";

export const threedTurbineLayerId = "vind:layer:turbine:3d";
export const threedCableLayerId = "vind:layer:cable:3d";
export const threedMooringLayerId = "vind:layer:mooringline:3d";

export const threedBuildingsLayerId = "vind:layer:buildings:3d";

export const cableCorridorSourceId = "vind:source:cablecorridor";
export const cableCorridorLayerId = "vind:layer:cablecorridor";
export const cableCorridorOutlineLayerId = "vind:layer:cablecorridor:outline";

export const exportCableSourceId = "vind:source:exportcable";
export const exportCableLayerId = "vind:layer:exportcable";

export const exportCableLandfallSegmentSourceId =
  "vind:source:exportcable:landfall:segment";
export const exportCableLandfallSegmentLayerId =
  "vind:layer:exportcable:landfall:segment";
export const exportCableLandfallSourceId =
  "vind:source:exportcable:landfall:point";
export const exportCableLandfallLayerId =
  "vind:layer:exportcable:landfall:point";

export const substationSourceId = "vind:source:substation";
export const substationLayerId = "vind:layer:substation";
export const substationSymbolLayerId = "vind:layer:substation:symbol";

export const portfolioDotSourceId = "vind:source:portfolio:dot";
export const portfolioDotLayerId = "vind:layer:portfolio:dot";
export const portfolioDotSymbolLayerId = "vind:layer:portfolio:dot:symbol";
export const portfolioDotClusterLayerId = "vind:layer:portfolio:cluster";
export const portfolioDotClusterSymbolLayerId =
  "vind:layer:portfolio:cluster:symbol";

export const parkSourceId = "vind:source:park";
export const parkLayerFillId = "vind:layer:park:fill";
export const parkLayerOutlineId = "vind:layer:park:outline";
export const parkLayerSymbolId = "vind:layer:park:symbol";

export const mooringLineSourceId = "vind:source:mooringline";
export const mooringLineLayerId = "vind:layer:mooringline";
export const MIN_MOORING_VISIBLE_ZOOM = 7;

export const cableChainSourceId = "vind:source:cablechain";
export const cableChainLayerId = "vind:layer:cablechain";

export const viewPointSourceId = "vind:source:viewpoint";
export const viewPointLayerId = "vind:layer:viewpoint";
export const viewPointSymbolLayerId = "vind:layer:viewpoint:symbol";

export const sensorPointSourceId = "vind:source:sensorpoint";
export const sensorPointLayerId = "vind:layer:sensorpoint";
export const sensorPointSymbolLayerId = "vind:layer:sensorpoint:symbol";

export const portSourceId = "vind:source:port";
export const portLayerId = "vind:layer:port";
export const portSymbolLayerId = "vind:layer:port:symbol";

export const depthProfilePointSourcePrefix = "vind:source:depthprofile:point";
export const depthProfilePointLayerPrefix = "vind:layer:depthprofile:point";

export const measurePointSourceId = "vind:source:measure-distance:points";
export const measurePointLayerId = "vind:layer:measure-distance:points";
export const measureLineLayerId = "vind:layer:measure-distance:lines";
export const noiseLayerId = "vind:layer:noise-layer-id";
export const noiseBoundaryLayerId = "vind:layer:noise-boundary-layer";

export const viewshedLayerId = "vind:layer:viewshed-result";
export const geotiffNoiseLayerId = "vind:layer:noise-analysis";
export const geotiffShadowFlickerLayerId = "vind:layer:shadow-flicker-analysis";

export const generateFoundationWarningPropertyName =
  "generateFoundationWarning";

export const DEFAULT_ZOOM_THRESHOLD = 9;

export const cableFreeSectorLayerId = "vind:source:cable-free-sector";
export const cableFreeSectorOutlineLayerId =
  "vind:source:cable-free-sector:outline";
export const cableFreeSectorSourceId = "vind:layer:cable-free-sector";

// Hidden clicker constants
export const HIDDEN_CLICK_LAYER_SUFFIX = "-hidden-click-layer";
export const pointHiddenClickRadius = 9;
export const lineHiddenClickWidth = 15;

export const hiddenLineClickPaint: mapboxgl.LinePaint = {
  "line-color": "rgba(255,0,0,0.0)",
  "line-width": lineHiddenClickWidth,
};
export const hiddenPointClickPaint: mapboxgl.CirclePaint = {
  "circle-color": "rgba(255,0,0,0.0)",
  "circle-radius": pointHiddenClickRadius,
};
