import { colors } from "styles/colors";
import Button from "components/General/Button";
import AddIcon from "@icons/24/Add.svg";
import { typography } from "styles/typography";
import styled from "styled-components";
import { useAtom } from "jotai";
import { selectedLayersToAddAtom } from "components/LayerList/LayerSettings/state";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-top: 1px solid ${colors.borderSubtle};
  justify-content: space-between;
`;
const TotalDataSets = styled.p`
  ${typography.caption}
  margin-right: 1rem;
`;

type FooterProps = {
  handleAddSelectedToMap: () => void;
  currentLayersIds: string[];
};

const Footer = ({ handleAddSelectedToMap, currentLayersIds }: FooterProps) => {
  const [selectedLayersToAdd, setSelectedLayersToAdd] = useAtom(
    selectedLayersToAddAtom,
  );

  return (
    <Container>
      <div
        style={{
          display: "flex",
          gap: "1.2rem",
          alignItems: "center",
        }}
      >
        <Button
          buttonType="secondary"
          text="Select all filtered datasets"
          disabled={currentLayersIds.length > 30}
          tooltip={
            currentLayersIds.length > 30
              ? "Max 30 datasets to use select all"
              : undefined
          }
          onClick={() => {
            // Combine existing and new layers, removing duplicates
            setSelectedLayersToAdd([
              ...new Set([...selectedLayersToAdd, ...currentLayersIds]),
            ]);
          }}
        />
        <TotalDataSets>
          Total datasets: {currentLayersIds?.length}
        </TotalDataSets>
      </div>
      <div
        style={{
          padding: "1rem 2rem",
          gap: "1.2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...typography.caption,
            color: colors.textSecondary,
            whiteSpace: "nowrap",
          }}
        >
          {selectedLayersToAdd.length} selected
        </div>
        <Button
          buttonType="text"
          disabled={selectedLayersToAdd.length === 0}
          text="Cancel"
          onClick={() => setSelectedLayersToAdd([])}
        />
        <Button
          buttonType="primary"
          disabled={selectedLayersToAdd.length === 0}
          text="Add to map"
          icon={<AddIcon />}
          onClick={handleAddSelectedToMap}
        />
      </div>
    </Container>
  );
};

export default Footer;
