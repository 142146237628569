import { Row, Column } from "components/General/Layout";
import { TabHeader } from "components/Organisation/OrganisationRightSide/style";
import Button from "components/General/Button";
import Tooltip from "components/General/Tooltip";
import { Anchor } from "components/General/Anchor";
import { ContentTableColumn } from "components/Organisation/OrganisationRightSide/style";
import { SkeletonBlock } from "components/Loading/Skeleton";
import { useRef, useState } from "react";

type ResourceSectionProps<T> = {
  title: string;
  tooltipText: string;
  buttonText: string;
  hasAccess: boolean;
  resources: T[];
  onAdd: ((resourceId: string) => void) | ((resource: any) => void);
  renderResource: (resource: T) => React.ReactNode;
  Modal?: React.ComponentType<{
    onSave: (items: any[]) => Promise<void>;
    existingItems: string[];
  }>;
  existingItems: string[];
  useFullResource?: boolean;
};

export function ResourceSection<T>({
  title,
  tooltipText,
  buttonText,
  hasAccess,
  resources,
  onAdd,
  renderResource,
  Modal,
  existingItems,
  useFullResource = false,
}: ResourceSectionProps<T>) {
  const [showModal, setShowModal] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Column style={{ gap: "1.6rem" }}>
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <TabHeader>{title}</TabHeader>
        <div style={{ position: "relative" }} ref={ref}>
          <Tooltip text={tooltipText} disabled={hasAccess}>
            <Button
              size="small"
              text={buttonText}
              buttonType="secondary"
              onClick={() => setShowModal(true)}
              disabled={!hasAccess || saveInProgress > 0}
            />
          </Tooltip>
          {showModal && Modal && (
            <Anchor baseRef={ref} floatPlace="topRight" basePlace="bottom">
              <Modal
                onSave={async (items) => {
                  setShowModal(false);
                  setSaveInProgress(items.length);
                  try {
                    await Promise.all(
                      items.map(async (item) => {
                        await onAdd(useFullResource ? item : item.id);
                        setSaveInProgress((cur) => cur - 1);
                      }),
                    );
                  } finally {
                    setSaveInProgress(0);
                  }
                }}
                existingItems={existingItems}
              />
            </Anchor>
          )}
        </div>
      </Row>
      <ContentTableColumn>
        {resources.map(renderResource)}
        {Array.from({ length: saveInProgress }, (_, index) => (
          <SkeletonBlock
            key={index}
            style={{
              height: "4rem",
              marginTop: "0.8rem",
            }}
          />
        ))}
      </ContentTableColumn>
    </Column>
  );
}
