import { atomFamily } from "utils/jotai";
import { featuresFamily, featuresListAtom } from "./features";
import { ParkFeature } from "types/feature";
import { atom } from "jotai";
import { parkIdAtom } from "state/pathParams";
import { findParkChildren } from "state/projectLayers";
import { turbinesInParkFamily } from "./turbine";
import * as turf from "@turf/turf";
import { Feature, Polygon } from "geojson";

/**
 * All parks in the project (for the branch).
 */
export const parksFamily = atomFamily(
  ({ branchId }: { branchId: string | undefined }) =>
    atom<Promise<ParkFeature[]>>(
      async (get) => (await get(featuresFamily({ branchId }))).park,
    ),
);

/**
 * Get park by id (and branch).
 */
export const parkFamily = atomFamily(
  ({ parkId, branchId }: { parkId: string; branchId: string | undefined }) =>
    atom<Promise<ParkFeature | undefined>>(async (get) =>
      (await get(parksFamily({ branchId }))).find((p) => p.id === parkId),
    ),
);

/**
 * Get the currently selected park.
 */
export const currentParkAtom = atom((get) => {
  const parkId = get(parkIdAtom);
  if (!parkId) return undefined;
  return get(parkFamily({ parkId, branchId: undefined }));
});

/**
 * Get all features in a park, in the current branch.  Does not include the
 * park.
 */
export const parkChildrenFamily = atomFamily(
  ({ parkId }: { parkId: string; branchId: undefined }) =>
    atom(async (get) => {
      const features = await get(featuresListAtom);
      return findParkChildren(features, parkId);
    }),
);

export const parkPaddedPolygonFamily = atomFamily(
  ({
    parkId,
    branchId,
    maxDistanceKM,
  }: {
    parkId: string;
    branchId: string | undefined;
    maxDistanceKM: number | undefined;
  }) =>
    atom<Promise<Feature<Polygon> | undefined>>(async (get) => {
      const park = await get(parkFamily({ parkId, branchId }));
      if (!park) return undefined;
      const turbines = await get(turbinesInParkFamily({ parkId, branchId }));
      const hull = turf.buffer(
        turbines.length === 0
          ? park
          : turf.convex(turf.featureCollection(turbines)) || park,
        maxDistanceKM,
      );
      return hull ?? undefined;
    }),
);
