import { useAtomValue } from "jotai";
import { organisationIdAtom, projectIdAtom } from "state/pathParams";
import { useEffect, useMemo } from "react";
import Ably from "ably";
import { AblyProvider } from "ably/react";
import { ABLY_TIMEOUT } from "./utils/ablySizeLimit";
import { AblyMonitorSystem } from "./AblyMonitorSystem";
import { accessToken } from "state/global";
import { PROJECT_SERVICE_API_PATH } from "components/ProjectElements/service";
import { tokenAtom } from "state/user";
import usePrevious from "hooks/usePrevious";
import ProjectChannelProviders from "./ChannelProviders/Project";

export const ProjectNodeAblyProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const organisationId = useAtomValue(organisationIdAtom);
  const projectNodeId = useAtomValue(projectIdAtom);
  const token = useAtomValue(tokenAtom);
  const previousAccessToken = usePrevious(token);

  const client = useMemo(() => {
    if (!organisationId || !projectNodeId) return undefined;
    return new Ably.Realtime({
      autoConnect: true,
      disconnectedRetryTimeout: ABLY_TIMEOUT,
      suspendedRetryTimeout: ABLY_TIMEOUT,
      transportParams: {
        heartbeatInterval: ABLY_TIMEOUT,
      },
      queryTime: true, // Relying on local time might cause drift, invalidating login.
      authMethod: "GET",
      authUrl: `${PROJECT_SERVICE_API_PATH}/ably/v4/organisation/${organisationId}/node/${projectNodeId}`,
      authHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }, [organisationId, projectNodeId]);

  useEffect(() => {
    if (
      client &&
      previousAccessToken &&
      token &&
      token !== previousAccessToken
    ) {
      console.log("Reauthorizing Ably client with new token");
      client.auth.authorize(undefined, {
        authMethod: "GET",
        authUrl: `${PROJECT_SERVICE_API_PATH}/ably/v4/organisation/${organisationId}/node/${projectNodeId}`,
        authHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }, [previousAccessToken, client, organisationId, projectNodeId, token]);

  if (!client) return children;

  return (
    <AblyProvider client={client} ablyId={projectNodeId}>
      {projectNodeId && <AblyMonitorSystem clientId={projectNodeId} />}
      <ProjectChannelProviders />
      {children}
    </AblyProvider>
  );
};
