import styled from "styled-components";
import { Suspense, useCallback, useMemo } from "react";
import ArrowDownIcon from "@icons/24/ArrowDown.svg?react";
import ArrowRightIcon from "@icons/24/ArrowRight.svg?react";
import Tooltip from "../General/Tooltip";
import { DropDownItem } from "../General/Dropdown/DropdownItems";
import { useProjectElementsCrud } from "../ProjectElements/useProjectElementsCrud";
import {
  MooringLineFeature,
  MooringLineMultipleFeature,
  MooringLineSingleFeature,
} from "../../types/feature";
import {
  isDefined,
  isMooringLine,
  isMooringLineMultiple,
  isMooringLineSingle,
} from "../../utils/predicates";
import { ProjectFeature } from "../../types/feature";
import { MenuItem } from "../General/Menu";
import { MenuButton } from "../General/MenuButton";
import { useAtomValue } from "jotai";
import { mooringLineTypesAtom } from "state/jotai/mooringLineType";
import { MOORING_MENU_ID } from "components/SettingsV2/FeatureSettings/Data/useMooringSettings";
import { useOpenComponentsConfig } from "./useOpenConfig";
import ProjectIcon from "@icons/24/ProjectGlobe.svg?react";
import { projectIdAtom } from "state/pathParams";
import {
  NamedTooltipContainer,
  NamedTooltipWrapper,
} from "./CanvasSelectOption.style";
import { colors } from "styles/colors";
import { typography } from "styles/typography";
import ComponentsIcon from "@icons/24/Components.svg?react";
import { Divider } from "components/General/Icons";

const TypeSelectorWrapper = styled.div`
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
`;

const SubTitle = styled.div`
  ${typography.sub2}
  background-color: ${colors.surfacePrimary};
  padding: 1.6rem 1rem 1rem 1rem;
`;

const DropdownDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderDefault};
`;

const TypeSelector = ({
  selectedLines,
  editable,
  segmentIndex,
}: {
  selectedLines: MooringLineFeature[];
  editable: boolean;
  segmentIndex?: number;
}) => {
  const { update: updateFeatures } = useProjectElementsCrud();
  const projectId = useAtomValue(projectIdAtom) ?? "";
  const allLineTypes = useAtomValue(mooringLineTypesAtom);
  const openNewMooringConfig = useOpenComponentsConfig(
    projectId,
    MOORING_MENU_ID,
  );

  const onSelectItem = useCallback(
    (val: string, segmentIndex?: number) => {
      const updatedFeatures =
        segmentIndex !== undefined
          ? selectedLines.map((l) => {
              const lineTypes = l.properties.lineTypes as string[];
              return {
                ...l,
                properties: {
                  ...l.properties,
                  lineTypes: lineTypes.map((type, idx) =>
                    idx === segmentIndex ? val : type,
                  ),
                },
              };
            })
          : selectedLines.map((l) => ({
              ...l,
              properties: {
                ...l.properties,
                lineType: val,
              },
            }));

      updateFeatures({ update: updatedFeatures });
    },
    [selectedLines, updateFeatures],
  );

  const currentLineType = useMemo(() => {
    const selectedLineTypes = new Set(
      selectedLines
        .map((l) =>
          segmentIndex !== undefined
            ? (l as MooringLineMultipleFeature).properties.lineTypes[
                segmentIndex
              ]
            : (l as MooringLineSingleFeature).properties.lineType,
        )
        .filter(isDefined),
    );

    if (selectedLineTypes.size === 1) {
      const selectedLineTypeId = selectedLineTypes.values().next().value;
      return allLineTypes.get(selectedLineTypeId);
    } else return null;
  }, [allLineTypes, selectedLines, segmentIndex]);

  const items: DropDownItem[] = useMemo(() => {
    return Array.from(allLineTypes.values()).map((lt) => ({
      value: lt.id,
      name: lt.name,
      icon: <ProjectIcon />,
    }));
  }, [allLineTypes]);

  return (
    <TypeSelectorWrapper>
      <MenuButton
        side="right"
        offset={[-12, 0]}
        disabled={!editable}
        icon={<ArrowRightIcon />}
        iconOpen={<ArrowDownIcon />}
        buttonStyle={{
          border: "none",
          flexDirection: "row-reverse",
          height: "fit-content",
          justifyContent: "space-between",
          padding: 0,
          maxWidth: "20rem",
          gap: "1.2rem",
        }}
        buttonType="dropdown"
        buttonText={
          <div
            style={{
              maxWidth: "19rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentLineType ? currentLineType.name : "..."}
          </div>
        }
      >
        <>
          <SubTitle>Project specific components</SubTitle>
          {items.map((item) => (
            <MenuItem
              textStyle={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              selected={currentLineType?.id === item.value}
              key={item.value}
              name={item.name}
              onClick={() => onSelectItem(item.value, segmentIndex)}
              icon={item.icon}
            />
          ))}
          <div
            style={{
              backgroundColor: colors.surfacePrimary,
              paddingTop: "1rem",
            }}
          ></div>
          <DropdownDivider />
          <MenuItem
            name="Go to components to create new"
            onClick={() => openNewMooringConfig()}
            icon={<ComponentsIcon />}
          />
        </>
      </MenuButton>
    </TypeSelectorWrapper>
  );
};

export default function MooringLineTypeSelector({
  editable,
  selectedProjectFeatures,
}: {
  editable: boolean;
  selectedProjectFeatures: ProjectFeature[];
}) {
  const selectedMooringLineFeatures = useMemo(() => {
    const selectedLines = selectedProjectFeatures.filter(isMooringLine);

    return selectedLines.length >= 1 ? selectedLines : null;
  }, [selectedProjectFeatures]);

  if (!selectedMooringLineFeatures) return null;

  if (
    selectedMooringLineFeatures.some((f) => isMooringLineMultiple(f)) &&
    selectedMooringLineFeatures.some((f) => isMooringLineSingle(f))
  ) {
    return (
      <Suspense fallback={null}>
        <Tooltip
          position="top"
          text="Select only uniform or multisegmented lines to edit line type(s)"
          readonlyAware
        >
          <NamedTooltipContainer>
            <NamedTooltipWrapper>Mooring line type</NamedTooltipWrapper>
            <p>...</p>
          </NamedTooltipContainer>
        </Tooltip>
      </Suspense>
    );
  }

  const multipleSegments = isMooringLineMultiple(
    selectedMooringLineFeatures[0],
  );

  return (
    <Suspense fallback={null}>
      <Tooltip position="top" text="Change type" readonlyAware>
        <NamedTooltipContainer>
          <NamedTooltipWrapper>
            {multipleSegments ? "Segment 1 type" : "Mooring line type"}
          </NamedTooltipWrapper>
          <TypeSelector
            selectedLines={selectedMooringLineFeatures}
            editable={editable}
            segmentIndex={multipleSegments ? 0 : undefined}
          />
        </NamedTooltipContainer>
      </Tooltip>
      {multipleSegments && (
        <>
          <Divider />
          <Tooltip position="top" text="Change type" readonlyAware>
            <NamedTooltipContainer>
              <NamedTooltipWrapper>Segment 2 type</NamedTooltipWrapper>
              <TypeSelector
                selectedLines={selectedMooringLineFeatures}
                editable={editable}
                segmentIndex={1}
              />
            </NamedTooltipContainer>
          </Tooltip>
          <Divider />
          <Tooltip position="top" text="Change type" readonlyAware>
            <NamedTooltipContainer>
              <NamedTooltipWrapper>Segment 3 type</NamedTooltipWrapper>
              <TypeSelector
                selectedLines={selectedMooringLineFeatures}
                editable={editable}
                segmentIndex={2}
              />
            </NamedTooltipContainer>
          </Tooltip>
        </>
      )}
    </Suspense>
  );
}
