import { projectIdAtom } from "state/pathParams";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSetPropertyOnProjectFeatures } from "../../hooks/useSetPropertyOnProjectFeatures";
import { ProjectFeature } from "../../types/feature";
import { v4 as uuid } from "uuid";
import BinIcon from "@icons/24/Bin.svg";
import ChevronDownIcon from "@icons/14/ChevronDown.svg";
import CompareIcon from "@icons/24/Compare.svg";
import DnDIconSmall from "@icons/12/DnDsmall.svg";
import DownloadIcon from "@icons/24/Download.svg";
import FolderOutlineIcon from "@icons/24/Folder-outline.svg";
import LockIcon from "@icons/24/Lock.svg";
import RemoveIcon from "@icons/24/Remove.svg";
import SectionIcon from "@icons/24/Section.svg";
import SearchIcon from "@icons/24/Search.svg";
import ParkIcon from "@icons/24/SideBarIcons/Park.svg";
import UnlockIcon from "@icons/24/Unlock.svg";
import ViewIcon from "@icons/24/View.svg";
import ViewOffIcon from "@icons/24/ViewOff.svg";
import Spinner from "@icons/spinner/Spinner";
import { IconREMSize } from "../../styles/typography";
import { featureIsLocked, isPointFeature } from "../../utils/predicates";
import { HideIfNotHoverOrVisible } from "../LayerList/LayerList.style";
import useSystemSpecificUnicode from "../../hooks/useSystemSpecificUnicode";
import { modalTypeOpenAtom } from "../../state/modal";
import useTextInput from "../../hooks/useTextInput";
import { dedup, platformCtrlOrCommand } from "../../utils/utils";
import { EditableText } from "../General/EditableText";
import { DotMenu, MenuButtonRef } from "../General/MenuButton";
import { MenuItem } from "../General/Menu";
import { selectedParksAtom } from "../CompareParksModal/state";
import { CompareParksModalType } from "../CompareParksModal/CompareParksModalType";
import AddToPortfolioMenuItem from "components/Organisation/Portfolio/AddToPortfolioMenuItem";
import {
  featuresToDownloadText,
  generateSafeElementId,
  getParkChildrenTypes,
  PROJECT_ELEMENT_PARK,
  PROJECT_ELEMENT_ORPHAN_ITEM,
  PROJECT_ELEMENT_FOLDER_ITEM,
  PROJECT_ELEMENT_FOLDER,
  getTreeRoot,
  elementTreeFindAll,
} from "./utils";
import {
  ExpandArrowWrapper,
  ProjectElementFolderTopRow,
  ProjectElementFolderWrapper,
  Ui13RegularOverflow,
  DnDIconWrapper,
} from "./ProjectElementsV2.style";
import { useProjectElementsContext } from "./ProjectElementsContext";
import { colors } from "../../styles/colors";
import { spaceMedium, spacing2 } from "../../styles/space";
import { lockedPropertyName } from "../../constants/canvas";
import { _FeatureParser } from "../../types/feature";
import { ProjectElementFolderType } from "./service";
import {
  DropCollectedProps,
  DropOnFeatureResults,
  ElementTreeNode,
} from "./types";
import { getDropCollect, dragCollect } from "./shared-dnd-callbacks";
import { useDnDLeafHooks } from "./hooks";
import { DownloadCustomCRSModalType } from "components/DownloadCustomCRSModal/DownloadCustomCRSModal";
import { FolderTreeMenuItem } from "./FolderTreeMenuItem";
import { useAtomValue, useSetAtom } from "jotai";
import { parkChildrenFamily } from "state/jotai/park";
import { ParkFeatureElement } from "./ParkFeatureElement";
import { ExpandableFeatureElements } from "./ExpandableFeatureElements";
import { TypeDot } from "components/General/Icons";
import { unwrap } from "jotai/utils";

export const elementsToIconStyle: React.CSSProperties = {
  width: "1.4rem",
  height: "1.4rem",
};

export const lineIconStyle: React.CSSProperties = {
  padding: "0 0.5rem 0 0",
  width: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const openItemsWrapperStyling: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  transition: "all 0.2s",
  gap: spacing2,
};

const ParkElement = ({
  node,
  park,
  folder,
  onAddToFolder,
  onCreateFolder,
  onDeleteFeatures,
  depth,
}: {
  node: ElementTreeNode;
  park: ProjectFeature;
  folder?: ProjectElementFolderType;
  onAddToFolder?(folder: ProjectElementFolderType, featureIds: string[]): void;
  onCreateFolder?(featureIds: string[]): void;
  onDeleteFeatures?(featureIds: string[]): void;
  depth: number;
}) => {
  const projectId = useAtomValue(projectIdAtom)!;
  const [hoverState, setHoverState] = useState<undefined | "bottom" | "top">(
    undefined,
  );
  const elementRef = useRef<HTMLDivElement>(null);
  const {
    branchId,
    selectedParks,
    editorAccessProject,
    isReadOnly,
    getAreAllFeaturesVisible,
    toggleFeaturesHidden,
    currentSelectionArray,
    currentSelectionMap,
    goToFeatures,
    updateFeatures,
    setCurrentSelectionArray,
    navigateToPark,
    updateProjectElementsFolder,
    deselectAllFeatures,
    isDownloading,
    downloadMultipleFeaturesShape,
    downloadMultipleFeaturesGeojson,
    downloadMultipleFeaturesKML,
    downloadMultipleFeaturesDXF,
    downloadMultipleFeaturesCSV,
    toggleFeaturesSelected,
    shiftSelectFeatures,
    onOpenedChange,
    getIsOpened,
    reorderTopLevel,
    tree,
  } = useProjectElementsContext();
  const [isOpen, setIsOpen] = useState(getIsOpened(park.id));
  const parkChildren = useAtomValue(
    unwrap(
      parkChildrenFamily({
        parkId: park.id,
        branchId: undefined,
      }),
    ),
  );
  const [disableDrag, setDisableDrag] = useState(false);
  const stringToUnicode = useSystemSpecificUnicode();
  const [writtenName, onWrittenNameChange, setWrittenName] = useTextInput(
    park.properties.name,
  );
  const setModalTypeOpen = useSetAtom(modalTypeOpenAtom);
  const setSelectedCompareParks = useSetAtom(
    selectedParksAtom({
      projectId,
    }),
  );
  const updateFeatureProperties = useSetPropertyOnProjectFeatures();
  const dotMenuRef = useRef<MenuButtonRef>(null);

  useEffect(() => {
    if (park.properties.name) {
      setWrittenName(park.properties.name);
    }
  }, [park.properties.name, setWrittenName]);

  const children = useMemo(
    () => getParkChildrenTypes(parkChildren ?? []),
    [parkChildren],
  );

  const changeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);
      onOpenedChange(park.id, newIsOpen);
    },
    [onOpenedChange, park.id],
  );

  const childArray = useMemo(() => Object.values(children).flat(), [children]);
  const {
    subAreas,
    turbines,
    exportCables,
    cableCorridors,
    substations,
    anchoring,
    internalCabling,
  } = children;

  const featureIds = useMemo(
    () => childArray.map((feature) => feature.id).concat(park.id),
    [childArray, park.id],
  );

  const featureIdsAndParkIdMap = useMemo(
    () => new Map([park.id, ...featureIds].map((id) => [id, true])),
    [featureIds, park.id],
  );

  const isMarkedBecauseNotOpenedFolder = useMemo(() => {
    return (
      !isOpen &&
      currentSelectionArray.some((selection) =>
        featureIdsAndParkIdMap.has(selection),
      )
    );
  }, [currentSelectionArray, isOpen, featureIdsAndParkIdMap]);

  const handleOnClick = useCallback(() => {
    navigateToPark(park.id);
    goToFeatures([park]);
    setCurrentSelectionArray([park.id]);
  }, [navigateToPark, park, goToFeatures, setCurrentSelectionArray]);

  const dnd = useDnDLeafHooks({
    node,
    divRef: elementRef,
    setHoverState,
    reorderTopLevel,
    updateFolder: updateProjectElementsFolder,
  });

  const [dragCollection, dragRef] = useDrag<
    ElementTreeNode[],
    void,
    {
      isDragging: boolean;
    }
  >(
    () => ({
      type: PROJECT_ELEMENT_PARK,
      item: () => {
        const root = getTreeRoot(node);
        const ids = new Set(currentSelectionArray);
        const nodes = elementTreeFindAll(root, (n) => ids.has(n.id));
        nodes.push(node);
        return dedup(nodes, (n) => n.id);
      },
      canDrag: () => {
        return (
          editorAccessProject &&
          !isReadOnly &&
          !disableDrag &&
          !featureIsLocked(park)
        );
      },
      collect: dragCollect,
      end: deselectAllFeatures,
    }),
    [
      editorAccessProject,
      isReadOnly,
      disableDrag,
      park,
      node,
      deselectAllFeatures,
      currentSelectionArray,
    ],
  );

  const [dropCollection, dropRef] = useDrop<
    ElementTreeNode[],
    DropOnFeatureResults,
    DropCollectedProps
  >(
    () => ({
      accept: [
        PROJECT_ELEMENT_ORPHAN_ITEM,
        PROJECT_ELEMENT_FOLDER_ITEM,
        PROJECT_ELEMENT_FOLDER,
        PROJECT_ELEMENT_PARK,
      ],
      collect: getDropCollect(),
      drop: dnd.drop,
      hover: dnd.hover,
    }),
    [dnd.drop, dnd.hover],
  );

  const allFeaturesAreVisible = useMemo(
    () => getAreAllFeaturesVisible(featureIds),
    [getAreAllFeaturesVisible, featureIds],
  );

  const allFeaturesAreSelected = useMemo(() => {
    const featureIdsAndParkId = [...featureIds, park.id];
    return featureIdsAndParkId.every((featureId) =>
      currentSelectionMap.has(featureId),
    );
  }, [featureIds, currentSelectionMap, park.id]);

  const onChangeNameSubmit = useCallback(() => {
    return updateFeatures({
      update: [
        _FeatureParser.parse({
          ...park,
          properties: {
            ...park.properties,
            name: writtenName,
          },
        }),
      ],
    });
  }, [park, updateFeatures, writtenName]);

  const allIsLocked = useMemo(
    () => [...childArray, park].every(featureIsLocked),
    [childArray, park],
  );

  const onLockFeaturesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const featureIdsAndParkId = [...featureIds, park.id];
      updateFeatureProperties(featureIdsAndParkId, {
        [lockedPropertyName]: !allIsLocked,
      });
    },
    [featureIds, park.id, updateFeatureProperties, allIsLocked],
  );

  dropRef(dragRef(elementRef));
  return (
    <ProjectElementFolderWrapper
      className="park-element"
      ref={elementRef}
      isDragging={dragCollection.isDragging}
      enableDrag={editorAccessProject && !isReadOnly && !disableDrag}
      isHoveredTop={dropCollection.isHovered && hoverState === "top"}
      isHoveredBottom={dropCollection.isHovered && hoverState === "bottom"}
      isInFolder={Boolean(folder)}
      id={generateSafeElementId(park.id)}
      isSelected={isMarkedBecauseNotOpenedFolder}
      depth={depth}
      onClick={(e) => {
        if (e.shiftKey) {
          shiftSelectFeatures(park.id);
        }
      }}
      onMouseDown={(e) => {
        if (
          !currentSelectionArray ||
          currentSelectionArray.length === 0 ||
          !deselectAllFeatures ||
          platformCtrlOrCommand(e) ||
          e.shiftKey
        ) {
          return;
        }

        // Deselect elements if user has clicked on some other feature than the ones selected
        const isInSelected = featureIds.some((featureId) =>
          currentSelectionArray.includes(featureId),
        );
        if (!isInSelected) {
          deselectAllFeatures();
        }
      }}
    >
      <ProjectElementFolderTopRow
        allFeaturesVisible={allFeaturesAreVisible}
        allFeaturesSelected={allFeaturesAreSelected}
        clickable={true}
        depth={depth}
        isInFolder={Boolean(folder)}
        onClick={(e) => {
          if (e.shiftKey) {
            shiftSelectFeatures(park.id);
          } else if (platformCtrlOrCommand(e)) {
            const featureIdsAndParkId = [...featureIds, park.id];
            toggleFeaturesSelected(featureIdsAndParkId);
          } else {
            changeIsOpen(true);
            handleOnClick();
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          dotMenuRef.current?.setIsOpen(true);
        }}
        hoverable={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <DnDIconWrapper>
            <HideIfNotHoverOrVisible>
              <DnDIconSmall />
            </HideIfNotHoverOrVisible>
          </DnDIconWrapper>
          <ExpandArrowWrapper
            open={isOpen}
            onClick={(event) => {
              event.stopPropagation();
              changeIsOpen(!isOpen);
            }}
          >
            <ChevronDownIcon />
          </ExpandArrowWrapper>
          <IconREMSize height={1.2} width={1.2}>
            <ParkIcon />
          </IconREMSize>
          <EditableText
            type="text"
            disabled={
              !editorAccessProject || isReadOnly || featureIsLocked(park)
            }
            onEnter={onChangeNameSubmit}
            onCancel={onChangeNameSubmit}
            onChange={onWrittenNameChange}
            onEditChange={setDisableDrag}
            value={writtenName}
          >
            <Ui13RegularOverflow title={park.properties.name}>
              {park.properties.name}
            </Ui13RegularOverflow>
          </EditableText>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {editorAccessProject && (
            <HideIfNotHoverOrVisible
              className={allIsLocked ? "visible" : undefined}
              style={{
                marginRight: spaceMedium,
              }}
            >
              <IconREMSize
                height={1.6}
                width={1.6}
                onClick={onLockFeaturesClick}
              >
                {allIsLocked ? <LockIcon /> : <UnlockIcon />}
              </IconREMSize>
            </HideIfNotHoverOrVisible>
          )}

          <HideIfNotHoverOrVisible
            className={!allFeaturesAreVisible ? "visible" : undefined}
          >
            <IconREMSize
              title="Toggle park visibility"
              height={1.5}
              width={1.5}
              onClick={(e) => {
                e.stopPropagation();
                toggleFeaturesHidden(featureIds, false);
              }}
            >
              {!allFeaturesAreVisible ? <ViewOffIcon /> : <ViewIcon />}
            </IconREMSize>
          </HideIfNotHoverOrVisible>

          {isDownloading[park.id] ? (
            <Spinner
              style={{
                width: "1rem",
                height: "1rem",
                margin: "0 1rem",
              }}
            />
          ) : (
            <HideIfNotHoverOrVisible>
              <DotMenu ref={dotMenuRef}>
                <AddToPortfolioMenuItem feature={park} />
                <MenuItem name="Download" icon={<DownloadIcon />}>
                  <MenuItem
                    name={featuresToDownloadText([...childArray, park], ".shp")}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesShape(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name={featuresToDownloadText(
                      [...childArray, park],
                      ".geojson",
                    )}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesGeojson(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name={featuresToDownloadText([...childArray, park], ".kml")}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesKML(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  <MenuItem
                    name={featuresToDownloadText([...childArray, park], ".dxf")}
                    icon={<DownloadIcon />}
                    onClick={() =>
                      downloadMultipleFeaturesDXF(
                        [...childArray, park],
                        park.properties.name!,
                        park.id,
                      )
                    }
                  />
                  {[...childArray, park].some(isPointFeature) && (
                    <MenuItem
                      name={".csv (Points only)"}
                      icon={<DownloadIcon />}
                      onClick={() =>
                        downloadMultipleFeaturesCSV(
                          [...childArray, park],
                          park.properties.name!,
                          park.id,
                        )
                      }
                    />
                  )}
                  <MenuItem
                    name="Using custom coordinate system"
                    icon={<DownloadIcon />}
                    onClick={() => {
                      setModalTypeOpen({
                        modalType: DownloadCustomCRSModalType,
                        metadata: {
                          featureIds: [...childArray, park].map((d) => d.id),
                          name: park.properties.name ?? "unnamed park",
                          loadingId: park.id,
                        },
                      });
                    }}
                  />
                </MenuItem>
                <MenuItem
                  name={allFeaturesAreSelected ? "Deselect" : "Multiselect"}
                  icon={<SectionIcon />}
                  shortcut={`${stringToUnicode("command")} + Click`}
                  onClick={() => {
                    const featureIdsAndParkId = [...featureIds, park.id];
                    toggleFeaturesSelected(featureIdsAndParkId);
                  }}
                />
                {selectedParks.length > 0 && (
                  <MenuItem
                    name={"Compare selected parks"}
                    icon={<CompareIcon />}
                    onClick={() => {
                      if (!branchId) {
                        return;
                      }

                      setSelectedCompareParks(
                        selectedParks.map((selectedPark) => ({
                          parkId: selectedPark.id,
                          branchId,
                          comparisonId: uuid(),
                        })),
                      );
                      setModalTypeOpen({
                        modalType: CompareParksModalType,
                      });
                    }}
                  />
                )}
                <MenuItem
                  name="Zoom to feature"
                  icon={<SearchIcon />}
                  onClick={() => goToFeatures([park])}
                />
                {editorAccessProject && !isReadOnly && (
                  <MenuItem name="Add to folder" icon={<FolderOutlineIcon />}>
                    <FolderTreeMenuItem
                      node={tree}
                      onFolderClick={(f) =>
                        onAddToFolder?.(f.folder, [park.id])
                      }
                      onNewClick={() => onCreateFolder?.([park.id])}
                    />
                  </MenuItem>
                )}
                {editorAccessProject && !isReadOnly && (
                  <>
                    {folder && (
                      <MenuItem
                        name="Remove from folder"
                        icon={<RemoveIcon />}
                        onClick={() => {
                          const featureIdsToRemove =
                            currentSelectionArray &&
                            currentSelectionArray.length > 0
                              ? currentSelectionArray
                              : featureIds;

                          updateProjectElementsFolder?.({
                            ...folder,
                            featureIds: folder.featureIds.filter(
                              ({ id }) => !featureIdsToRemove.includes(id),
                            ),
                          });
                          deselectAllFeatures?.();
                        }}
                      />
                    )}
                    <MenuItem
                      name="Delete"
                      icon={<BinIcon />}
                      onClick={() => {
                        onDeleteFeatures?.([park.id]);
                      }}
                    />
                  </>
                )}
              </DotMenu>
            </HideIfNotHoverOrVisible>
          )}
        </div>
      </ProjectElementFolderTopRow>

      {isOpen && (
        <div style={openItemsWrapperStyling}>
          <ParkFeatureElement
            name="Area"
            features={[park]}
            parent={park}
            folder={folder}
            isSelected={currentSelectionArray.includes(park.id)}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={subAreas}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Sub areas"
            getFeatureName={(zone) => zone.properties.name ?? "Sub area"}
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={internalCabling}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Internal cabling"
            getFeatureName={(cable) =>
              cable.properties.name ?? "Internal cable"
            }
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={exportCables}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Export cables"
            getFeatureName={(cable) => cable.properties.name ?? "Export cable"}
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={cableCorridors}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Cable corridors"
            getFeatureName={(corridor) =>
              corridor.properties.name ?? "Cable corridor"
            }
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={substations}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Substations"
            icon={<TypeDot dotColor={colors.substation} />}
            getFeatureName={(substation) =>
              substation.properties.name ?? "Substation"
            }
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={turbines}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Turbines"
            icon={<TypeDot dotColor={colors.turbine} />}
            getFeatureName={(turbine) => turbine.properties.name ?? "Turbine"}
          />

          <ExpandableFeatureElements
            park={park}
            folder={folder}
            features={anchoring}
            onDeleteFeatures={onDeleteFeatures}
            depth={depth + 1}
            name="Mooring"
            getFeatureName={(anchor) => anchor.properties.name ?? "Mooring"}
          />
        </div>
      )}
    </ProjectElementFolderWrapper>
  );
};

export default ParkElement;
